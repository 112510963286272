import { Button, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import React from 'react'
import { TextBox } from 'shared/elements/FormItem/TextBox'
const AlertNoteField = (props) => {
    // console.log('alert', props)
    const [isEditable, setIsEditable] = React.useState(false)
    const [anchorDeleteStaffNote, setAnchorDeleteStaffNote] = React.useState(null)
    // const names = React.useMemo(() => {
    //     if (props?.alertNoteObj) {
    //         return props?.alertNoteObj?.createdBy?.split(' ')
    //     }
    //     return []
    // }, [props?.alertNoteObj])

    const handleNoteSave = React.useCallback(
        (event) => {
            // if (props?.alertCheck) {
            //     props.setAnchorAlert(event.currentTarget)
            // } else
            if (props?.check || props?.noteObj?.alert) {
                props.setAnchorAlert(event.currentTarget)
            } else {
                props.handleNotesSubmit(event, null)
            }
        },
        [
            // props?.alertCheck,
            props?.anchorAlert,
            props?.handleNotesSubmit,
            props?.check,
            props?.noteObj,
            // props?.alertNoteObj,
        ],
    )
    return (
        <div className="staff-notes-container" ref={props?.noteRef}>
            {props?.noteObj &&
                props?.noteObj?.user_id &&
                props?.noteObj?.user_id === parseInt(props.userId) && (
                    <div className="staff-note-alert-container">
                        <DeleteOutlinedIcon
                            style={{ fontSize: 20, color: '#667689', cursor: 'pointer' }}
                            onClick={(e) => setAnchorDeleteStaffNote(e?.target)}
                        />
                    </div>
                )}
            <div className="staff-notes-text-container" style={{ marginBottom: '5px' }}>
                <TextBox
                    name="Note title"
                    value={props?.noteTitle || props?.noteObj?.noteTitle}
                    onChange={(value) => {
                        props.handleNoteTitleChange(value)
                    }}
                    inputProps={{
                        readOnly: props?.noteObj?.id && !isEditable ? true : false,
                        maxLength: 40,
                    }}
                />
            </div>
            <div className="staff-notes-text-container">
                <TextBox
                    name="Enter note"
                    value={props?.note || props?.noteObj?.content}
                    multiline
                    minRows={13}
                    maxRows={13}
                    placeholder="Start Typing..."
                    onChange={(value) => {
                        props.handleNoteChange(value)
                    }}
                    style={{
                        position: 'relative',
                        border: 'none',
                        borderRadius: 'unset',
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root':{
                            height:'auto'
                        }
                    }}
                    inputProps={{
                        readOnly: props?.noteObj?.id && !isEditable ? true : false,
                    }}
                    InputProps={{
                        endAdornment: props?.noteObj &&
                            props?.noteObj?.user_id &&
                            props?.noteObj?.user_id === parseInt(props.userId) && (
                                <InputAdornment
                                    position="end"
                                    style={{
                                        position: 'absolute',
                                        bottom: 15,
                                        right: 5,
                                    }}>
                                    <EditOutlinedIcon
                                        style={{
                                            fontSize: 20,
                                            color: '#667689',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setIsEditable(!isEditable)}
                                    />
                                </InputAdornment>
                            ),
                    }}
                />
            </div>
            <div className="staff-notes-button-container">
                <div className="staff-notes-button-wraper">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props?.check}
                                onChange={() => {
                                    props?.handleOnCheck(!props?.check)
                                }}
                                name="Alert"
                                disabled={
                                    props?.noteObj &&
                                    props?.noteObj?.user_id &&
                                    props?.noteObj?.user_id !== parseInt(props.userId) &&
                                    !props?.noteObj?.alert
                                }
                            />
                        }
                        label="Alert"
                    />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        {props?.noteObj &&
                        props?.noteObj?.user_id &&
                        props?.noteObj?.user_id !== parseInt(props.userId) &&
                        !props?.noteObj?.alert ? (
                            <Button
                                type="button"
                                className="history-note-close-view-button"
                                onClick={() => props.handleCancelNote()}>
                                Close Node
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="button"
                                    className="history-note-close-button"
                                    onClick={() => props.handleCancelNote()}>
                                    Cancel
                                </Button>
                                <Button
                                    ref={props?.saveButtonRef}
                                    type="button"
                                    className="history-note-save-button"
                                    style={{
                                        backgroundColor: `${
                                            props?.note || props?.noteObj?.content
                                                ? '#5571C6'
                                                : '#c0c1c5'
                                        }`,
                                    }}
                                    disabled={
                                        props.loadingNoteSave || !props?.note || !props?.noteTitle
                                    }
                                    onClick={(e) => handleNoteSave(e)}>
                                    {/* {props?.noteObj?.content
                                            ? props.loadingNoteSave
                                                ? 'Editing...'
                                                : 'Edit'
                                            : props.loadingNoteSave
                                            ? 'Saving...'
                                            : 'Save'} */}
                                    {props.loadingNoteSave ? 'Saving...' : 'Save'}
                                </Button>
                                <CustomAlertPopOver
                                    anchorEl={props?.anchorAlert}
                                    handleClose={(e) => {
                                        // if (!props?.check) {
                                        //     props?.handleNotesSubmit(e, 'No')
                                        //     props?.setAnchorAlert(null)
                                        // }
                                        props?.setAnchorAlert(null)
                                    }}
                                    title="Save as Alert?"
                                    bodyText={`Are you sure you want to ${
                                        props?.noteObj?.alert ? 'update' : 'add'
                                    }  the alert?`}
                                    confirmButtonName={'Yes'}
                                    cancelButtonName={'No'}
                                    confirmButtonColor={'#5571c6'}
                                    type={'other'}
                                    onSuccess={(e) => {
                                        props?.handleNotesSubmit(e, 'Yes')
                                        props?.setAnchorAlert(null)
                                    }}
                                />
                                <CustomAlertPopOver
                                    anchorEl={props?.anchorNote}
                                    handleClose={(e) => {
                                        props?.setAnchorNote(null)
                                        if (!props?.check) {
                                            props.handleCancelNote()
                                            props.handleNoteChange()
                                        }
                                    }}
                                    title="Save Note"
                                    bodyText={`You are about navigate away,do you want to save this note?`}
                                    confirmButtonName={'Save'}
                                    cancelButtonName={`Don't Save`}
                                    confirmButtonColor={'#5571c6'}
                                    type={'default'}
                                    onSuccess={(e) => {
                                        props?.handleNotesSubmit(e, null)
                                        props?.setAnchorNote(null)
                                    }}
                                />
                                <CustomAlertPopOver
                                    anchorEl={anchorDeleteStaffNote}
                                    handleClose={() => {
                                        setAnchorDeleteStaffNote(null)
                                    }}
                                    title="Note Deletion"
                                    bodyText={`are you sure you want to delete this note?`}
                                    confirmButtonName={'Delete'}
                                    cancelButtonName={`Cancel`}
                                    confirmButtonColor={'#E81A1A'}
                                    type={'default'}
                                    onSuccess={() => {
                                        props.handleDeleteStaffNote()
                                        setAnchorDeleteStaffNote(null)
                                    }}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
            {/* {props?.alertNoteObj && (
                <div
                    className="history-list-note-alert-text"
                    onClick={() => props.handleNoteEdit(props?.alertNoteObj)}>
                    <div
                        className="history-list-note-alert-text-container"
                        style={{
                            backgroundColor: `${props?.alertCheck ? '#ccd9f9' : '#fff'}`,
                        }}>
                        <img
                            src={Alert_svg}
                            style={{
                                width: '15px',
                                marginRight: '8px',
                                marginLeft: '5px',
                            }}
                        />
                        <Typography style={{ fontSize: '14px' }}>
                            <strong style={{ fontSize: '14px' }}>
                                {' '}
                                {names && names.length >= 2 && `${names[0][0]}.${names[1][0]}`}
                            </strong>{' '}
                            created <strong style={{ fontSize: '14px' }}>note</strong>{' '}
                            {props?.alertNoteObj?.updatedAt && (
                                <>
                                    on{' '}
                                    <span>
                                        {(props?.alertNoteObj &&
                                            getCustomizedFormattedDate(
                                                props?.alertNoteObj?.updatedAt,
                                            )) ||
                                            'Invalid Date'}
                                    </span>{' '}
                                    at (
                                    <span>{getTimeFromDate(props?.alertNoteObj?.updatedAt)}</span>)
                                </>
                            )}
                        </Typography>
                    </div>
                </div>
            )}
            {props?.loadingNotes ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: 'white',
                    }}>
                    <CircularProgress color="inherit" size={20} />
                </div>
            ) : (
                <>
                    {props?.notes?.length > 0 && (
                        <>
                            <div className="divider-between-button-alert-note">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0px 4px',
                                        width: '100%',
                                        marginTop: '5px',
                                    }}>
                                    <Typography className={'heading1-title'}>History</Typography>
                                    <hr
                                        style={{
                                            width: '100%',
                                            height: '0px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="history-note-list-container">
                                <div className="history-note-list">
                                    {props?.notes?.length > 0 ? (
                                        props?.notes.map((item, index) => {
                                            const names = item?.createdBy?.split(' ')
                                            const activeKey =
                                                props?.noteObj && props?.noteObj?.id === item.id
                                            return (
                                                <div
                                                    className={`history-list-note-field ${
                                                        activeKey ? 'active' : ''
                                                    }`}
                                                    key={index}
                                                    onClick={() => props.handleNoteEdit(item)}>
                                                    <Typography style={{ fontSize: '14px' }}>
                                                        <strong style={{ fontSize: '15px' }}>
                                                            {names &&
                                                                names.length >= 2 &&
                                                                `${names[0][0]}.${names[1][0]}`}
                                                        </strong>{' '}
                                                        created{' '}
                                                        <strong style={{ fontSize: '14px' }}>
                                                            note
                                                        </strong>{' '}
                                                        {item?.updatedAt && (
                                                            <>
                                                                on{' '}
                                                                <span>
                                                                    {(item &&
                                                                        getCustomizedFormattedDate(
                                                                            item?.updatedAt,
                                                                        )) ||
                                                                        'Invalid Date'}
                                                                </span>{' '}
                                                                at (
                                                                <span>
                                                                    {getTimeFromDate(
                                                                        item?.updatedAt,
                                                                    )}
                                                                </span>
                                                                )
                                                            </>
                                                        )}
                                                    </Typography>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <Typography>No Notes Added Yet!</Typography>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {props?.totalNote > 3 && (
                        <div className="view-all-wrapper">
                            <Button
                                type="button"
                                className="view-all-button"
                                onClick={() => props?.getAllNotes(props?.limit === 3 ? 2 : 1)}>
                                {props?.limit === 3 ? 'View all' : 'View less'}
                            </Button>
                        </div>
                    )}
                </>
            )} */}
        </div>
    )
}

export default AlertNoteField
