//React
import React, { useEffect, useState } from 'react'

//Helper Packages
import moment from 'moment'

//Material UI
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

//SVG Icons
import blankImage from "assests/icons/blankImage.svg"
import Folder_svg_selected from 'assests/icons/Folder_svg_seleted.svg'
import { ReactComponent as PhoneIcon } from 'assests/icons/phone.svg'
import { requestPreviewBrandingBackGround } from 'services/Provider/provider'
import { GENDER_IDENTITY } from 'containers/PatientsSetting/PatientForm/schema'


const useStyles = makeStyles(() => ({
    patientName:{
        fontFamily: "Rubik !important",
        fontSize: "16px !important",
        fontWeight: '600 !important',
        color: "#303E4E !important",
    },
    patientInfo: {
        fontFamily: "Rubik !important",
        fontSize: "14px !important",
        fontWeight: '400 !important',
        color: "#444 !important",
    },
    avatar: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        background: '#D9D9D9',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        // marginRight: 24,
    },
}))

function PatientInfo({ patientDetails }) {
    const classes = useStyles()
    const [patientImage, setPatientImage] = useState('')

    const calculateAge = (dob) =>{
        const today = moment();
        const birthDate = moment(dob);
        const years = today.diff(birthDate, 'years');
        return years
    }

    useEffect(async() => {
        if(patientDetails?.avatar){
            const previewUrl = await requestPreviewBrandingBackGround({
                key: patientDetails?.avatar
            })
            if(previewUrl) setPatientImage(previewUrl?.data?.url)
        }
    }, [patientDetails])

    const getInitials = function (string) {
        const names = string.split(' ')
        let initials = names[0].substring(0, 1).toUpperCase()

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase()
        }
        return initials
    }

    return (
        <Box style={{ display: 'flex', padding: 10, gap: 10, width: '25%', alignItems: 'center'  }}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}>
                {!!patientImage ? (
                    <img className={classes.avatar} src={patientImage} alt="Patient photo" />
                ) : 
                    <Box className={classes.avatar}>
                        <Box
                            style={{
                                fontFamily: 'Rubik',
                                fontWeight: '500',
                                color: 'white',
                            }}>
                            {getInitials(`${patientDetails?.first_name} ${patientDetails?.last_name}`)}
                        </Box>
                    </Box>
                }
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <div style={{ display: 'flex' }}>
                    <span className={classes.patientName}>
                        {`${patientDetails?.first_name  } ${  patientDetails?.last_name}`}
                    </span>
                    <img
                      src={Folder_svg_selected}
                      alt=""
                      style={{ paddingLeft: '10px', cursor: 'pointer' }}
                      onClick={() => {
                          window.open(`/patient/${patientDetails?.id}/patient-record`)
                      }}
                  />
                </div>

                {!!patientDetails?.preferred_name && <Typography className={classes.patientInfo}>
                    {patientDetails?.preferred_name}
                </Typography>}

                <div style={{ display: 'flex', gap: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography className={classes.patientInfo}>
                            {patientDetails?.gender.charAt(0).toUpperCase() +
                            patientDetails?.gender.slice(1)}
                            {' '}
                            {`(${calculateAge(patientDetails?.dob)} y.o.)`}
                            {' - '}
                            {moment(patientDetails?.dob).format('MM/DD/YYYY')}
                        </Typography>
                        {patientDetails?.gender_identity?.code !== 'OTHER' && <Typography className={classes.patientInfo}>
                            {GENDER_IDENTITY?.find((item) => item?.code === patientDetails?.gender_identity?.code)?.pronouns}
                        </Typography>}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }} >
                        <PhoneIcon />
                        <Typography className={classes.patientInfo}>
                            {patientDetails?.phone_number}
                        </Typography>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default PatientInfo
