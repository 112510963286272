import { Box, Button, CircularProgress, FormLabel, Grid, makeStyles } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    createImmunization,
    getImmunizationById,
    updateImmunization,
} from 'services/Patient/patient'
import { ModalEl as Modal } from 'shared/elements'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import * as yup from 'yup'
import { INITIAL_VALUES } from './schema'
import { SearchVaccine } from './SearchVaccine'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
})

interface Props {
    patientId: string
    itemId?: string | null
    open?: boolean
    onClose: (forceReload?: boolean) => void
    refetchData: (forceReload?: boolean) => void
}

export const ImmunizationsCreateDialog = (props: Props) => {
    const classes = useStyles()

    const { id: patient_id } = useParams<{ id: string }>()
    const notification = useNotification()

    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(true)

        Promise.allSettled([
            props.itemId ? getAllergyDetail(patient_id, props.itemId) : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload) => {
        setIsSubmitting(true)

        try {
            if (props.itemId) {
                await updateImmunization(patient_id, props.itemId, {
                    ...payload,
                    patient_id,
                    administered_on: payload.administered_on.clone().utc(),
                })
                props.refetchData()
            } else {
                await createImmunization(patient_id, {
                    ...payload,
                    patient_id,
                    administered_on: payload.administered_on.clone().utc(),
                })
                props.refetchData()
            }
            setIsSubmitting(false)

            const msg = props.itemId
                ? `Immunization updated successfully!`
                : `Immunization created successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')

            props.onClose(true)
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                error?.data?.message ||
                (props.itemId
                    ? `Immunization updated unsuccessfully`
                    : `Immunization created unsuccessfully`)

            notification(errMsg, 'error')
        }
    }

    const getAllergyDetail = async (patientId: string, itemId: string) => {
        try {
            const resp = await getImmunizationById(patientId, itemId)

            if (resp && resp.data) {
                setInitData(resp.data)
            }
        } catch (error) {
            notification(`Cannot get medication vaccine ${itemId}`, 'error')
        }
    }

    const validationSchema = yup.object({
        vaccine: yup.object({
            code: yup.string().required('Vaccine is required!'),
            display: yup.string(),
        }),
        // administered_by: yup.object({
        //   id: yup.string().required('Administered By is required!'),
        // }),
        // administered_on: yup.string()
        // .required('Administered On is required!'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={800}
            title={props.itemId ? 'Update Immunization' : 'Add Immunization'}
            open={props.open}
            onClose={() => props.onClose(false)}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                        }}>
                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid container item alignItems="flex-end" spacing={2}>
                                <Grid item xs={12}>
                                    <SearchVaccine
                                        label="Vaccine Name"
                                        required
                                        name="vaccine"
                                        value={formik.values.vaccine}
                                        placeholder="Search Vaccine Name"
                                        error={
                                            formik.touched.vaccine?.code &&
                                            Boolean(formik.errors.vaccine?.code)
                                        }
                                        helperText={
                                            formik.touched.vaccine?.code &&
                                            formik.errors.vaccine?.code
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'vaccine',
                                                value || {
                                                    code: '',
                                                    display: '',
                                                },
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextBox
                                        disabled
                                        label="CVX Code"
                                        name="code"
                                        value={formik.values.vaccine.code}
                                        placeholder="Enter Code"
                                        // onChange={value => formik.setFieldValue('vaccine', value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                // required
                                label="Administered on"
                                name="administered_on"
                                value={formik.values.administered_on}
                                onChange={(value) => {
                                    formik.setFieldValue('administered_on', moment(value))
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => props.onClose(false)}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                className={classes.button_create}
                                loadingPosition="start"
                                startIcon={<></>}
                                onClick={() => formik.handleSubmit()}>
                                {props.itemId ? 'Update' : 'Submit'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
