import MomentUtils from '@date-io/moment'
import { Box, ClickAwayListener, makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import React from 'react'
import TeleCalendarIcon from '../../../assests/icons/TeleCalendarIcon'

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        top: 44,
        left: 0,
        background: '#FFFFFF',
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        padding: 16,
        zIndex: 1,
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
}))

function DatePickerInput({ onChange, disabled = false, appointmentModal, minDate, helperText, error, readOnly = true }) {
    const classes = useStyles()
    const [date, changeDate] = React.useState(moment())
    const [open, setOpen] = React.useState(false)

    const handleClickAway = () => {
        setOpen(false)
    }
    React.useEffect(() => {
        if (appointmentModal?.start) {
            changeDate(appointmentModal?.start)
        }
    }, [appointmentModal])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Box
                    sx={{
                        '& .MuiPickersCalendarHeader-transitionContainer': {
                            ' & p': {
                                color: '#303E4E',
                                fontWeight: 500,
                                fontSize: 16,
                            },
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#303E4E',
                            width: 30,
                            height: 30,
                        },
                        '& .MuiPickersCalendarHeader-dayLabel': {
                            color: '#9CA9B8',
                            fontWeight: 400,
                            fontSize: 14,
                            width: 47,
                        },
                        '& .MuiPickersCalendarHeader-daysHeader': {
                            marginTop: 24,
                            marginBottom: 8,
                        },
                        '& .MuiPickersDay-daySelected': {
                            background: '#5571C6',
                            borderRadius: '8px',
                            color: 'white !important',
                        },
                        '& .MuiPickersBasePicker-pickerView': {
                            maxWidth: 400,
                            width: 450,
                        },
                        '& .MuiPickersDay-day': {
                            width: 47,
                            height: 40,
                        },
                        '& .MuiIconButton-label': {
                            '& p': {
                                fontSize: 14,
                            },
                        },
                    }}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            InputProps={{ readOnly: readOnly }}
                            keyboardIcon={<TeleCalendarIcon color="rgba(102, 118, 137, 1)" />}
                            format="MM/DD/YYYY"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            minDate={minDate}
                            error={error}
                            helperText={helperText}
                            value={date}
                            onChange={(e) => {
                                changeDate(e)
                                onChange && onChange(e)
                            }}
                            allowKeyboardControl={false}
                            disabled={disabled}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </div>
        </ClickAwayListener>
    )
}

export default DatePickerInput
