import React, { useEffect } from 'react'
import MomentUtils from '@date-io/moment'
import { ClickAwayListener, makeStyles, Typography } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Box } from '@mui/material'
import moment, { Moment } from 'moment'
import TeleCalendarIcon from 'assests/icons/TeleCalendarIcon'
import { DATE_FORMAT } from './Constant'
import { FormikErrors } from 'formik'

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        top: 44,
        left: 0,
        background: '#FFFFFF',
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        padding: 16,
        zIndex: 1,
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
    label: {
        margin: '14px 0',
        fontWeight: 500,
        lineHeight: '1.5rem',
    },
    err: {
        color: '#f44336',
        margin: 0,
        fontSize: '11px',
        marginTop: '3px',
        textAlign: 'left',
        fontFamily: 'Rubik',
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: '0.4px',
        textTransform: 'capitalize',
    },
}))

interface PropsDatePickerInput {
    onChange?: (e: any) => void
    disabled?: boolean
    valueDate?: string | Date | null
    label?: string | number
    height?: string
    addDay?: number
    helperText?: FormikErrors<Moment>
    maxDate?: Moment
    placeholder?: string
}

export const DatePickerInput = ({
    onChange,
    disabled,
    valueDate,
    label,
    height = '32px',
    addDay,
    helperText,
    maxDate,
    placeholder,
}: PropsDatePickerInput) => {
    const classes = useStyles()
    const [date, changeDate] = React.useState<any>(moment())
    // const [date, changeDate] = React.useState<any>('15-01-2023')
    const [open, setOpen] = React.useState(false)

    const handleClickAway = () => {
        setOpen(false)
    }
    useEffect(() => {
        if (valueDate) changeDate(moment(valueDate, DATE_FORMAT))
        else if (valueDate === null || valueDate === '') changeDate(null)
    }, [valueDate])

    useEffect(() => {
        if (addDay) {
            changeDate(moment().add(addDay, 'days'))
        }
    }, [addDay])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Box
                    sx={{
                        '& .MuiPickersCalendarHeader-transitionContainer': {
                            ' & p': {
                                color: '#303E4E',
                                fontWeight: 500,
                                fontSize: 16,
                            },
                        },
                        '& .MuiOutlinedInput-root': {
                            height: height,
                            minHeight: '32px',
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#303E4E',
                            width: 30,
                            height: 30,
                        },
                        '& .MuiPickersCalendarHeader-dayLabel': {
                            color: '#9CA9B8',
                            fontWeight: 400,
                            fontSize: 14,
                            width: 47,
                        },
                        '& .MuiPickersCalendarHeader-daysHeader': {
                            marginTop: 24,
                            marginBottom: 8,
                        },
                        '& .MuiPickersDay-daySelected': {
                            background: '#5571C6',
                            borderRadius: '8px',
                            color: 'white !important',
                        },
                        '& .MuiPickersBasePicker-pickerView': {
                            maxWidth: 400,
                            width: 450,
                        },
                        '& .MuiPickersDay-day': {
                            width: 47,
                            height: 40,
                        },
                        '& .MuiIconButton-label': {
                            '& p': {
                                fontSize: 14,
                            },
                        },
                        '& .MuiInputBase-input': {
                            color: date ? '#305075' : '#b6bcc1',
                        },
                    }}>
                    {label && <Typography className={classes['label']}>{label}</Typography>}
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                        <KeyboardDatePicker
                            InputProps={{ readOnly: true }}
                            keyboardIcon={<TeleCalendarIcon color="rgba(102, 118, 137, 1)" />}
                            maxDate={maxDate}
                            disabled={disabled ? true : false}
                            format="MM/DD/YYYY"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            emptyLabel={placeholder ? placeholder : "Due to Schedule"}
                            value={date}
                            onChange={(e) => {
                                changeDate(e)
                                onChange && onChange(e)
                            }}
                            allowKeyboardControl={false}
                        />
                    </MuiPickersUtilsProvider>
                    {helperText && (
                        <Box className={classes.err} component={'p'}>
                            {helperText}
                        </Box>
                    )}
                </Box>
            </div>
        </ClickAwayListener>
    )
}

export default DatePickerInput
