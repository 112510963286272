import { makeStyles } from '@material-ui/core'

export const usePatientDashboardStyles = makeStyles(() => ({
    cariumHeading:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        paddingLeft: '5px'
    },
    cariumSubtext:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        paddingBottom: '5px'
    },
    headingText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontWeight: '500',
        paddingLeft: '10px'
    },
    content:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontWeight: '400',
        paddingLeft: '2px'
    },
    container_tab: {
        '& *': {
            boxSizing: 'border-box',
        },
    },
    group_item: {
        height: '50%',
        // marginBottom: '8.5%'
    },
    box_ct_health_record: {
        background: '#FFFFFF',
        border: '1px solid #E9EEEE',
        borderRadius: '10px',
        height: 321,
        width: 383
        // padding: '16px 5px 16px 24px !important',
    },
    box_ct: {
        background: '#FFFFFF',
        border: '1px solid #ECF0F0',
        borderRadius: '10px',
        height: '100%',
        padding: '16px 5px 16px 24px !important',
    },
    paper: {
        background: '#FFFFFF',
        border: '1px solid #ECF0F0',
        borderRadius: '10px',
        padding: '24px !important',
        height: '100%',
    },
    card: {
        padding: '16px 5px 16px 24px !important',
        height: 'calc(50% - 10px)',
        marginBottom: 10,
    },
    container: {
        // position: 'absolute',
        // top: 0,
        zIndex: 1,
        // left: 0,
        background: '#FAFAFA',
        height: '100%',
        padding: '16px 24px',
        overflowY: 'auto',
        position: 'relative',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    box: {
        width: 'calc(100% / 3 - 12px)',
        margin: '0 6px',
    },
    header_card: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
        // marginBottom: 20,
    },
    tag_type_deleted: {
        background: 'rgba(232, 18, 18, 0.05)',
        color: '#E81212',
        borderRadius: '5px',
        padding: '4px 6px',
        fontSize: 13,
        textTransform: 'capitalize',
    },
    tag_type_resolved: {
        background: '#ebf9ff',
        color: '#2685c9',
        borderRadius: '5px',
        padding: '4px 6px',
        fontSize: 13,
        textTransform: 'capitalize',
    },
    tag_type_inactive: {
        background: 'rgb(232 196 18 / 5%)',
        color: '#e89c12',
        borderRadius: '5px',
        padding: '4px 6px',
        fontSize: 13,
        textTransform: 'capitalize',
    },
    tag_type_active: {
        background: '#e7f9f0',
        color: '#098f48',
        borderRadius: '5px',
        padding: '4px 6px',
        fontSize: 13,
        textTransform: 'capitalize',
    },
    tag_type_red: {
        color: 'rgb(232 146 18)',
    },
    viewall: {
        color: '#303E4E',
        '&:hover': {
            color: '#4e52d9',
            // textDecoration: 'underline',
        },
    },
    title_clinical_reminder: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400" ,
    },
    appt_timer: {
        color: '#14AE5C',
    },
    line_hor: {
        width: 5,
        marginRight: 8,
        height: '100%',
        background: '#FEEFE0',
        borderRadius: '3px 0px 0px 3px',
    },
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    appointmentDatetext:{
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "500" ,
    },
    patientPortalIconColor: {
        '& path': {
            fill: '#5571C6 !important'
        }
    },
    headerCardRefreshBtn: {
        color: '#5571C6',
        width: '24px',
        height: '24px',
        marginRight: '8px'
    }
}))

export const usePatientSettingsStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    tab_bar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        padding:'5px'
    },
    tab_bar_container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding:'7px',
        width:'65%'
    },
    tab_button: {
        minWidth: '23px !important',
        width: '23px !important',
        height: '23px !important',
        color: '#303E4E !important',
        background: '#fff !important',
        fontSize: '16px',
        fontWeight: '400',
        borderRadius: '5px'
    },
})

export const sx = {
    datagrid: {
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                display: 'flex',
                alignItems: 'center',
                color: '#303E4E',
                fontWeight: 600,
                fontSize: '14px',
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(107vh - 300px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
        },
        '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            cursor: 'pointer',
            margin: '4px 0',
            borderRadius: '8px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
    
}
