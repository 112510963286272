import UserContainer from '../containers/Users';

import { authRequired } from '../utilities/guards';

import Routes from '../constants/routes';
import { UserRoles } from '../constants/user-roles';

const meta = {
    auth: true,
    guards: [authRequired],
};

const userRoutes = [
    {
        path: Routes.USERS,
        roles: [
            UserRoles.CLINIC_CORDINATION,
            UserRoles.SUPERVISOR,
            UserRoles.TM2U_ADMIN,
            UserRoles.TM2U_SUPER_ADMIN,
        ],
        component: UserContainer,
        meta,
    },
];

export default userRoutes;
