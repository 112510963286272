import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    makeStyles,
} from '@material-ui/core'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { patientContact } from 'services/Patient/apiPatientContacts'
import { ModalEl as Modal, SelectEl } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import * as yup from 'yup'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
// import { INITIAL_VALUES, RELATIONSHIP_TYPE } from './schema'
import './styles.scss'
import { UpdateAdminCode, createAdminCode, getAdmincodeById } from 'services/Calendar'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
        borderTop: '1px solid #DAD9D9 !important',
    },
    InputLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
    },
    required_text: {
        color: "#E81A1A"
    }
})


const INITIAL_VALUES = {
    id: '',
    title: '',
    code: '',
    duration: '',
    description: '',
    color_tag: ''
}



const AdminCodesCreate = ({ onClose, codeId, afterComplete }) => {
    const classes = useStyles()

    const { id: patient_id } = useParams()
    const notification = useNotification()
    const [initData, setInitData] = useState(INITIAL_VALUES)

    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isAppointmentNotificationChecked, setAppointmentNotificationIsChecked] = useState(false)
    const [patientsContacts, setpatientsContacs] = useState([])

    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(false)
        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload) => {
        setIsSubmitting(true)
        try {
            const payload = {
                "title": formik.values.title,
                "code": formik.values.code,
                "duration": formik.values.duration,
                "color": formik.values.color_tag,
                "description": formik.values.description

            }

            if (codeId) {
                const updateData = await UpdateAdminCode(codeId, payload)
                notification('Admin code updated Successfully', 'success')
                onClose()
                afterComplete()
            } else {
                const postData = await createAdminCode(payload)
                notification('Admin code created Successfully', 'success')
                onClose()
                afterComplete()
            }

        } catch (error) {
            console.log(error)
            notification('Something Went Wrong', 'error')
        }
        setIsSubmitting(false)
    }

    const validationSchema = yup.object({
        title: yup.string().required('Title is required!'),
        code: yup.string().required('Code is required!'),
        duration: yup.number().required('Duration is required!').typeError('Duration muste be a number'),
    })

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    })

    const styles = reactCSS({
        default: {
            color: {
                width: '39px',
                height: '39px',
                borderRadius: '20px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '20px',
                marginTop: 8,
                boxShadow: '0 0 0 1px #e9ecf1',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                top: 150,
                left: 90,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })


    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    const handleChangeColor = (color) => {
        formik.setFieldValue('color_tag', color.hex)
        setColor(color.rgb)
    }

    useEffect(() => {
        if (codeId)
            getSingleCode(codeId)
    }, [codeId])

    const getSingleCode = async (id) => {
        setIsSubmitting(true)
        const singleCode = await getAdmincodeById(id)
        // console.log(singleCode)
        // console.log(singleCode, "AIOP")
        formik.setFieldValue('id', singleCode.data.id)
        formik.setFieldValue('title', singleCode.data.title)
        formik.setFieldValue('code', singleCode.data.code)
        formik.setFieldValue('duration', singleCode.data.duration)
        formik.setFieldValue('description', singleCode.data.description)
        formik.setFieldValue('color_tag', singleCode.data.color)
        setIsSubmitting(false)
    }

    useEffect(() => {
        if (formik.values.color_tag) {
            const hex2rgba = (hex, alpha = 1) => {
                const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
                setColor({ r, g, b, a: alpha })
            }
            hex2rgba(formik.values.color_tag)
        }
    }, [formik.values.color_tag])

    return (
        <Modal
            width={680}
            height={378}
            title={codeId ? "Update Admin Code" : "Add New Admin Code"}
            open={true}
            // onClose={() => props.onClose(false)}
            style={{
                alignItems: 'left',
                justifyContent: 'left',
            }}
            className="calendar-appointment-modal"
            dynaClass="myheader">
            <CloseOutlinedIcon
                style={{
                    fontSize: 20,
                    color: 'black',
                    position: 'absolute',
                    top: '5%',
                    right: '3%',
                    cursor: 'pointer',
                }}
                onClick={() => { onClose() }}
            />
            <Grid container className="modal_padding">
                {isSubmitting && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            // marginTop: 24,
                            marginBottom: 10,
                        }}>
                        {/* <Grid
                            container
                            style={{
                                marginLeft: 7,
                                marginRight: 7,
                            }}
                            spacing={2}>
                            <Grid
                                container
                                item
                                alignItems="flex-end"
                                spacing={2}>
                                <Grid item xs={12}>

                                </Grid> */}

                        <Grid item xs={12}>
                            <FormLabel className="input-form-label">
                                Title<span className={classes.required_text}>*</span>
                            </FormLabel>
                            <TextBox
                                variant='outlined'
                                name="title"
                                placeholder="Enter Title"
                                value={formik.values?.title}
                                onChange={(value) =>
                                    formik.setFieldValue('title', value)
                                }
                                required
                                error={
                                    formik.touched.title &&
                                    Boolean(formik.errors.title)
                                }
                                helperText={
                                    formik.touched.title &&
                                    formik.errors.title
                                }
                            />
                        </Grid>

                        {/* </Grid>
                        </Grid> */}

                        <Grid item xs={6}>
                            <FormLabel
                                className={
                                    classes.InputLabel
                                }
                            >
                                Code <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <TextBox
                                name="code"
                                value={formik.values?.code}
                                placeholder="Enter Code"
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        'code',
                                        value
                                    )
                                }
                                error={
                                    formik.touched.code &&
                                    Boolean(formik.errors.code)
                                }
                                helperText={
                                    formik.touched.code &&
                                    formik.errors.code
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel
                                className={
                                    classes.InputLabel
                                }
                            >
                                Duration <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <TextBox
                                name="duration"
                                value={formik.values?.duration}
                                placeholder="Enter Duration"
                                onChange={(value) =>
                                    formik.setFieldValue('duration', value)
                                }
                                error={
                                    formik.touched.duration &&
                                    Boolean(formik.errors.duration)
                                }
                                helperText={
                                    formik.touched.duration &&
                                    formik.errors.duration
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormLabel
                                className={
                                    classes.InputLabel
                                }
                            >
                                Description
                            </FormLabel>
                            <TextBox
                                name="description"
                                value={formik.values.description}
                                multiline
                                minRows={4}
                                placeholder="Enter Description"
                                onChange={(value) => {
                                    formik.setFieldValue('description', value)
                                }}></TextBox>
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel
                                className={
                                    classes.InputLabel
                                }
                            >
                                Tags
                            </FormLabel>

                            <div className="fff-color-picker">
                                <div style={styles.swatch} onClick={handleClick}>
                                    <div style={styles.color} />
                                </div>
                                {displayColorPicker ? (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={handleClose} />
                                        <SketchPicker color={color} onChange={handleChangeColor} />
                                    </div>
                                ) : null}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                            borderTop: '1px solid #DAD9D9',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => onClose()}
                            >
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                className={classes.button_create}
                                loadingPosition="start"
                                startIcon={<></>}
                                onClick={(e) => formik.handleSubmit(e)}>
                                {codeId ? 'Update' : 'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default AdminCodesCreate