import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { MenstruationAndPregnancyHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { OutpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import MenstruationAndPregnancyHistoryForm from './MenstruationAndPregnancyHistoryForm'
import { apiMenstruationAndPregnancyHistory } from 'services/Patient/apiMenstruationAndPregnancyHistory'
import { useNotification } from 'shared/elements/Notification'

const MenstruationAndPregnancyHistory = ({
    isInClinicalNote,
    patient_Id,
    encounter_id,
    afterActionOk,
    nonEditable
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    // State
    // const [page, setPage] = useState(1)
    const [mapId, setMapId] = useState<string | false>(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [menstruationAndPregnancyList, setMenstruationAndPregnancyList] = useState<
        OutpatientPsychiatric[]
    >([])
    const [loading, setLoading] = useState(false)
    const [patientId, setPatientId] = useState<string | false>(false)
    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })
    const [sortModel, setSortModel] = useState<GridSortModel>([])

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleEdit = (id) => {
        setMapId(id)
        setIsAddingNew(true)
    }

    const getMenstruationAndPregnancyHistory = async () => {
        setLoading(true)
        try {
            const resp = await apiMenstruationAndPregnancyHistory.getAll(patientId, {
                page,
                size: limit,
            })
            if (resp.data?.data) {
                setMenstruationAndPregnancyList(resp.data.data)
                setTotal(resp.data.paging.total)
                // if (!resp.data.data.length && resp.data.paging.total > 0) {
                //     setPage(page - 1)
                // }
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    const hanldeDelete = async (mapId) => {
        if (patientId) {
            try {
                const res = await apiMenstruationAndPregnancyHistory.delete(patientId, mapId)
                if (res.data?.Status === 'SUCCESSED') {
                    notification(
                        'Menstruation And PregnancyHistory deleted successfully',
                        'success',
                    )
                    await getMenstruationAndPregnancyHistory()
                    afterActionOk?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            }
        }
    }

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    useEffect(() => {
        if (patientId) {
            getMenstruationAndPregnancyHistory()
        }
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{
                position: 'relative',
                overflow: 'initial',
                padding: !isInClinicalNote ? '16px 24px' : '0',
            }}>
            <Loading visible={loading} />

            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', id))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent="space-between">
                    {!isInClinicalNote && (
                        <Typography className={classes.header}>
                            Menstruation and Pregnancy History
                        </Typography>
                    )}

                    <ButtonCustom
                        tooltip="Add New Menstruation and Pregnancy History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setIsAddingNew(true)}
                        style={isInClinicalNote && { position: 'absolute', right: 20, top: -45 }}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <MenstruationAndPregnancyHistoryDashboard
                    page={page}
                    onPageChange={handleChangePage}
                    hanldeDelete={hanldeDelete}
                    handleEdit={handleEdit}
                    dataSource={menstruationAndPregnancyList}
                    pagination={{ start: page, total, limit }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    nonEditable={nonEditable}
                />

                {isAddingNew && (
                    <MenstruationAndPregnancyHistoryForm
                        MAPId={mapId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        open={isAddingNew}
                        onOke={() => {
                            setIsAddingNew(false)
                            setMapId(false)
                            getMenstruationAndPregnancyHistory()
                            afterActionOk?.()
                        }}
                        onClose={() => {
                            setIsAddingNew(false)
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(MenstruationAndPregnancyHistory)
