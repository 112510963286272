import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material'

interface TabPanelProps {
    children?: React.ReactNode
    index: number | string
    value: number | string
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '100%', overflowY: 'auto' }}
            {...other}>
            {value === index && <Box sx={{ p: 3, padding: '24px 0' }}>{children}</Box>}
        </div>
    )
}

interface Props {
    current?: number | string
    orientation?: string
    tabs?: {
        label?: string
        element?: () => React.ReactNode
    }[]
    style?: React.CSSProperties
    sx?: any
    tabBoxProps?: object
    onChange?: (value: number | string) => void
}

export const Tabs = (props: Props) => {
    const { current = 1, tabs = [], style, onChange, tabBoxProps={} } = props
    return (
        <>
            <Box sx={{ borderLeft: 1, borderColor: 'divider', ...tabBoxProps }}>
                <MuiTabs
                    onChange={(_, value) => onChange && onChange(value)}
                    value={current}
                    aria-label="Tabs where selection follows focus"
                    selectionFollowsFocus
                    orientation={props.orientation ? 'vertical' : 'horizontal'}
                    sx={props.sx && props.sx}>
                    {tabs &&
                        tabs.map((tab, index) => (
                            <MuiTab
                                key={index}
                                label={tab.label}
                                {...a11yProps(index)}
                                style={{ marginRight: 20, textTransform: 'none' }}
                            />
                        ))}
                </MuiTabs>
            </Box>

            <Grid item xs={12} style={style}>
                {tabs &&
                    tabs.map((tab, index) => (
                        <TabPanel key={index} value={current} index={index}>
                            {tab.element}
                        </TabPanel>
                    ))}
            </Grid>
        </>
    )
}
