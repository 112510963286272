import React from 'react'
import { Checkbox, FormControlLabel, FormLabel } from '@material-ui/core'
import { FormGroup, FormGroupProps, FormHelperText, SxProps } from '@mui/material'

type Option = {
    value?: string | number
    label?: string
}

type CheckboxGroupProps = {
    label?: string | React.ReactNode | React.ReactChildren
    name?: string
    required?: boolean
    selectedOptions?: React.Key[]
    layout?: 'vertical' | 'horizontal'
    disabled?: boolean
    sx?: SxProps
    options?: Option[]
    error?: boolean
    helperText?: string | false
    onChange?: (value: any, e?: any) => void
    optionsChecked?: {
        value: string
        label: string
        checked: boolean
    }[]
}

export const CheckboxGroup = (props: CheckboxGroupProps & FormGroupProps) => {
    const {
        label,
        required,
        layout = 'vertical',
        error,
        helperText,
        options = [],
        optionsChecked,
        ...rest
    } = props

    return (
        <>
            {!!label && (
                <FormLabel
                    component="p"
                    className="d-flex justify-space-between align-center"
                    style={{ fontSize: 14, fontWeight: 500 }}>
                    <span className={!!required ? 'required' : ''}>{label}</span>
                </FormLabel>
            )}

            <FormGroup {...rest}>
                {optionsChecked
                    ? optionsChecked.map(({ value, label, checked }, index) => (
                          <FormControlLabel
                              key={index}
                              control={<Checkbox color="primary" value={value} checked={checked} />}
                              label={label}
                          />
                      ))
                    : options.map(({ value, label }, index) => (
                          <FormControlLabel
                              key={index}
                              control={<Checkbox color="primary" value={value} />}
                              label={label}
                          />
                      ))}
            </FormGroup>

            {required && error && helperText && (
                <FormHelperText className="helper-text">{helperText}</FormHelperText>
            )}
        </>
    )
}

export default CheckboxGroup
