import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Grid, Button, Popover, IconButton } from '@material-ui/core'
import Divider from '@mui/material/Divider';

import { makeStyles } from '@material-ui/styles'

import copyNew from '../../../assests/icons/copyNew.svg'

import MedicalProblemListNew from 'containers/PatientsSetting/view/MedicalProblems/medicalProblemListNew'
import MedicalSurgicalHistory from 'containers/PatientsSetting/view/SurgicalHistory/medicalSurgicalHistory'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import PreviousCN from '../reusable/PreviousCN';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        marginLeft: 12,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    itemCard: {
        width: 400,
        height: 500,
    },
    gridContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: '730px',
    },
    gridSurgicalContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: 360,
    },
    gridItem: {
        width: '33.333%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    gridItemShow: {
        width: '50%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    // divider: {
    //     background: '#B0BCE4',
    // },
    subText: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },


    first_box:{
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        // height: "814px",
        // overflow: 'auto',
        padding: '10px',
    },
    second_box:{
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        padding: '10px',
        background: '#FFF',
    },
    copy_button:{
        borderRadius: "4px",
        background: "#FFF",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "26px",
        textAlign: "center",
        fontFamily: "Rubik",
        fontStyle: "normal",
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    box_type:{
        marginTop:"10px",
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        padding: '8px',
    },
    filter_btn: {
        width: '170px !important',
        height: '28px',
        fontWeight: 'normal !important',
        borderRadius: '5px !important',
        background: '#15A452 !important',
        color: 'white !important',
        padding: '0px !important',
        textTransform: 'capitalize !important',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '101px',
        borderRadius: '5px',
        border: '1px solid #E4E2E2',
        background: '#FFF',
        padding: '6px',
        marginBottom: '3px',
        marginRight: '6px',
        justifyContent: 'space-between',
        width: '207px',
    },
    displayInbox:{
        display:"flex",
        alignItem: "center",
        justifyContent: "center"
    },
    header:{
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        color: "#303E4E"
    },
    DividerText: {
        color: "var(--Light-Foreground-Foreground-2, #424242)",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    TopBar:{
        borderRadius: '5px',
        border: '1px solid #E0E0E0',
        background: '#F8F9FB',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        padding:'10px'
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
    },
    toggleButton:{
        display: 'inline-flex',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    toggleOption:{
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s, color 0.3s',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Rubik',
        backgroundColor: 'white',
        color: '#333',
    },
    MedicationAllergyButton: {
        width: "160px",
        height: "28px",
        borderRadius: "5px",
        background: "#15A452",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        cursor: "pointer",
        marginLeft: "5px"
    },
})

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]
const PROBLEM_STATUS = [
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'resolved', value: 'Resolved' },
    { key: 'Deleted', value: 'Deleted' },
]

const PastMedicalHistory = ({
    fieldName,
    values,
    onChange,
    patientId,
    nonEditable,
    encounter_id,
    lastNarrative,
    lastUpdateDate
}) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [copied,setCopied] = useState(false)
    const[active,setActive] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [openFormSurgical, setOpenFormSurgical] = useState(false)
    const ModalOpen = () =>{
        setOpen(true)
    }
    const handleClose = () =>{
        setOpen(false)
    }
    function myFunction() {
        const text = typeof lastNarrative === 'string' ? lastNarrative : null
        navigator.clipboard.writeText(text)
        let newNarrative = ''
        if (text) {
            newNarrative = values?.narrative.concat(` ${text}`)
            setCopied(true)
        }
        onChange(`${fieldName}.narrative`, newNarrative)
        handleClose()
    }
    return (
        <Box className={classes.container} style={{ minHeight: "800px", marginTop:'30px',marginBottom:'30px' }}>
            <Box className={classes.TopBar}>
                <Typography className={classes.HeadingColor}>Problems List</Typography>
                <div className={classes.toggleButton}>
                    <div className={classes.toggleOption} onClick={()=>{setActive(false)}} style={{background: !active ? '#5571C6' : '', color: !active ? '#fff' : '' }}>Active</div>
                    <div className={classes.toggleOption} onClick={()=>{setActive(true)}} style={{background: active ? '#5571C6' : '', color: active ? '#fff' : '' }}>Inactive</div>
                </div>
                <Box className={classes.MedicationAllergyButton} onClick={()=>{setOpenForm(true)}} >
                    <AddCircleOutlineIcon style={{
                        color: "#FFF",
                        fontSize: "large"
                    }} />
                    <Typography style={{
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Rubik",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "24px",
                    }}>Add medical problem</Typography>
                </Box>
            </Box>
            <Box className={classes.box_type}>
                <Grid item>
                    <MedicalProblemListNew
                        patientId={patientId}
                        encounter_id={encounter_id}
                        onChange={(list) => {
                            onChange(`${fieldName}.medical_problems`, list)
                        }}
                        openForm={openForm}
                        setCloseForm={(value)=>{setOpenForm(value)}}
                        active={active}
                        atTheEnd={(value)=>{
                            if(active){
                                onChange(`${fieldName}.inActiveRead`, value)
                            }else{
                                onChange(`${fieldName}.ActiveRead`, value)
                            }
                        }}
                        InactiveEnd={values?.inActiveRead ? values?.inActiveRead :false }
                        ActiveEnd={values?.ActiveRead ? values?.ActiveRead :false }
                    />
                </Grid>
            </Box>
            <Box className={classes.TopBar} style={{marginTop: '15px'}}>
                <Typography className={classes.HeadingColor}>Surgical History</Typography>
                    <Box className={classes.MedicationAllergyButton} onClick={()=>{setOpenFormSurgical(true)}} >
                        <AddCircleOutlineIcon style={{
                            color: "#FFF",
                            fontSize: "large"
                        }} />
                        <Typography style={{
                            color: "#FFF",
                            textAlign: "center",
                            fontFamily: "Rubik",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "24px",
                        }}>Add surgical history</Typography>
                    </Box>
            </Box>
            <Box className={classes.box_type}>
                <Grid item>
                    <MedicalSurgicalHistory
                        patientId={patientId}
                        encounter_id={encounter_id}
                        onChange={(list) => {
                            onChange(`${fieldName}.surgical_history`, list)
                        }}
                        openForm={openFormSurgical}
                        setCloseForm={(value)=>{setOpenFormSurgical(value)}}
                    />
                </Grid>
            </Box>
            <Grid item xs={12} style={{marginTop: '15px'}}>
                    <TextWithMacrosNew
                        name={`${fieldName}.narrative`}
                        onChange={onChange}
                        // // macros={macros}
                        isDisabled={nonEditable}
                        withoutMacro={false}
                        values={values?.narrative}
                        socialHistory={true}
                        // placeholder={placeholder}
                    />
            </Grid>
            
            {/* {open && (
                <PreviousCN
                    open={open}
                    handleClose={handleClose}
                    categoryName={'Medical History'}
                    lastNarrative={lastNarrative}
                    lastUpdateDate={lastUpdateDate}
                    handleChangecopy={myFunction}
                />
            )} */}
        </Box>
    )
}

export default PastMedicalHistory
