import * as actionType from '../actionType';

const initialState = {
    profile: {},
    succesMsg: '',
};

const tm2uAdmin = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_TM2UADMIN_PROFILE: {
            return {
                ...state,
                profile: action.value,
            };
        }
        case actionType.SET_EDIT_TM2UADMIN_PROFILE: {
            return {
                ...state,
                succesMsg: action.value,
            };
        }
        case actionType.RESET_TM2U_PROFILE: {
            return {
                ...state,
                profile: {},
            };
        }
        case actionType.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default tm2uAdmin;
