import React, { lazy, useState, useEffect } from 'react'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import AddIcon from '@mui/icons-material/Add'

import { ModalEl as Modal } from 'shared/elements'
import { SubstanceAbuseTreatment } from 'containers/PatientsSetting/interfaces'
import { Button as ButtonCustom } from 'components/custom'
import './style.scss'
import { apiSubstanceAbuseTreatmentHistory } from 'services/Patient/apiSubstanceAbuseTreatmentHistory'
import { useNotification } from 'shared/elements/Notification'
import { Box } from '@mui/material'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { getAge } from 'utilities/formats'
import { getPatient } from 'services/Patient/patient'

const InputNumber = lazy(() => import('shared/elements/FormItem/Input/InputNumber'))
const Select = lazy(() => import('shared/elements/FormItem/Select'))
const Footer = lazy(() => import('shared/elements/FormItem/Footer'))
const Loading = lazy(() => import('shared/elements/Loading'))
const TextBox = lazy(() => import('shared/elements/FormItem/TextBox'))

type Props = {
    substanceAbuseTreatmentId?: string | number | false
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const validationSchema = yup.object({
    treatment_id: yup.number().required('This field is required!'),
    age_of_first_treatment: yup
        .number()
        .min(0, 'incorrect age format!')
        .max(
            yup.ref('age_patient'),
            "The age of first treatment must be less than or equal to the patient's age!",
        ),
    age_of_last_treatment: yup
        .number()
        .min(
            yup.ref('age_of_first_treatment'),
            'The age of last treatment must be greater than or equal to the age of first treatment',
        )
        .max(
            yup.ref('age_patient'),
            "The age of last treatment must be less than or equal to the patient's age!",
        ),
})

const INITIAL_VALUES = {
    treatment_id: '',
    age_of_first_treatment: 0,
    age_of_last_treatment: 0,
    number_of_episodes: '',
    additional_info: '',
    age_patient: 100,
}

const SubstanceAbuseTreatmentForm = (props: Props) => {
    //hooks
    const notification = useNotification()

    // State
    const [initialValues, setInitialValues] = useState<SubstanceAbuseTreatment>(INITIAL_VALUES)

    const [loading, setLoading] = useState(false)
    const [addingNewSubstance, setAddingNewSubstanceTreatment] = useState(false)
    const [newSubstanceTreatment, setNewSubstanceTreatment] = useState('')

    const [loadingTreatments, setLoadingTreatments] = useState(false)
    const [loadingAddNewTreatment, setLoadingAddNewTreatment] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [treatmentTypes, setTreatmentTypes] = useState([])

    const formik = useFormik<SubstanceAbuseTreatment>({
        validationSchema,
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    async function handleSubmit(values) {
        if (!props.substanceAbuseTreatmentId && props.patientId) {
            setSubmitting(true)
            try {
                const res =
                    await apiSubstanceAbuseTreatmentHistory.createSubstanceAbuseTreatmentHistory(
                        props.patientId,
                        { ...values, encounter_id: props.encounter_id },
                    )
                if (res.data) {
                    notification(
                        'Substance Abuse Treatment History created successfully',
                        'success',
                    )
                    formik.resetForm()
                    setSubmitting(false)
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setSubmitting(false)
            }
        } else if (props.substanceAbuseTreatmentId && props.patientId) {
            setSubmitting(true)
            try {
                const res = await apiSubstanceAbuseTreatmentHistory.updateSubstanceAbuseTreatment(
                    props.patientId,
                    props.substanceAbuseTreatmentId,
                    { ...values, encounter_id: props.encounter_id },
                )
                if (res.data?.Status === 'SUCCESSED') {
                    notification(
                        'Substance Abuse Treatment History updated successfully',
                        'success',
                    )
                    formik.resetForm()
                    setSubmitting(false)
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setSubmitting(false)
            }
        }
    }

    const getTreatment = async () => {
        try {
            setLoadingTreatments(true)
            const res = await apiSubstanceAbuseTreatmentHistory.getTreatment({ page: 1, size: 100 })
            if (res.data?.data?.length >= 0) {
                const data = res.data.data
                const newData = data.map((item) => ({ key: item.id, value: item.name }))
                setTreatmentTypes(newData)
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong', 'error')
        } finally {
            setLoadingTreatments(false)
        }
    }

    const hanleAddNewTreatment = async () => {
        if (newSubstanceTreatment.length) {
            setLoadingAddNewTreatment(true)
            try {
                const res = await apiSubstanceAbuseTreatmentHistory.createTreatmentType({
                    name: newSubstanceTreatment,
                })
                if (res.data) {
                    notification('Treatment Type careated successfully!', 'success')
                    setNewSubstanceTreatment('')
                    setLoadingAddNewTreatment(false)
                    await getTreatment()
                }
            } catch (error) {
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingAddNewTreatment(false)
            }
        }
    }

    const getSubstanceAbuseTreatmentById = async (patientId, substanceAbuseTreatmentId) => {
        try {
            setLoading(true)
            const res = await apiSubstanceAbuseTreatmentHistory.getSubstanceAbuseTreatmentId(
                patientId,
                substanceAbuseTreatmentId,
            )
            const resPatient = await getPatient(patientId)
            let agePatient: number | null = null
            if (resPatient.data) {
                agePatient = getAge(resPatient.data.dob, 'YYYY-MM-DD')
                if (agePatient === 0) agePatient = 1
            }
            if (res.data) {
                const {
                    id,
                    treatment_id,
                    age_of_first_treatment,
                    age_of_last_treatment,
                    number_of_episodes,
                    additional_info,
                } = res.data
                setInitialValues({
                    id,
                    treatment_id,
                    age_of_first_treatment: +age_of_first_treatment,
                    age_of_last_treatment: +age_of_last_treatment,
                    number_of_episodes,
                    additional_info,
                    age_patient: agePatient ? agePatient : 100,
                })
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getTreatment()
        return () => abortController.abort()
    }, [])

    useEffect(() => {
        const abortController = new AbortController()
        if (props.substanceAbuseTreatmentId) {
            getSubstanceAbuseTreatmentById(props.patientId, props.substanceAbuseTreatmentId)
        }
        return () => abortController.abort()
    }, [props.substanceAbuseTreatmentId])

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                console.log(resp.data)
                let age = getAge(resp.data.dob, 'YYYY-MM-DD')
                if (age === 0) age = 1
                if (age) {
                    // formik.setFieldValue('age_patient', age)
                    setInitialValues({
                        ...initialValues,
                        age_patient: age,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && !props.substanceAbuseTreatmentId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    const modalTitle = props.substanceAbuseTreatmentId
        ? 'Update Substance Abuse Treatment History'
        : 'Add Substance Abuse Treatment History'

    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="substance-abuse-treatment-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12} container wrap="nowrap" alignItems="flex-end">
                        <Box flex={1} className="wrap-select-has-loading">
                            {loadingTreatments && (
                                <Box className={'loading-select'}>
                                    <CircularProgress size={'20px'} />
                                </Box>
                            )}
                            <Select
                                required
                                label="Treatment Type"
                                name="treatment_type"
                                value={formik.values.treatment_id}
                                placeholder="Substance"
                                options={treatmentTypes}
                                disabled={loadingTreatments}
                                error={
                                    Boolean(formik.touched.treatment_id) &&
                                    Boolean(formik.errors.treatment_id)
                                }
                                helperText={
                                    Boolean(formik.touched.treatment_id) &&
                                    (formik.errors.treatment_id as string)
                                }
                                onChange={(value) => {
                                    return formik.setFieldValue('treatment_id', value)
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                marginBottom:
                                    Boolean(formik.touched.treatment_id) &&
                                    Boolean(formik.errors.treatment_id)
                                        ? '29px'
                                        : 0,
                            }}>
                            <ButtonCustom
                                style={{ marginBottom: 4, marginRight: 0 }}
                                tooltip="Add New Treatment Type"
                                variant="contained"
                                color="primary"
                                onClick={() => setAddingNewSubstanceTreatment((state) => !state)}
                                fullWidth>
                                <AddIcon style={{ fontSize: 20 }} />
                            </ButtonCustom>
                        </Box>
                    </Grid>

                    {addingNewSubstance && (
                        <Grid item xs={12} container wrap="nowrap" alignItems="flex-end">
                            <Grid item style={{ flex: 1, marginRight: 10 }}>
                                <Box flex={1} className="wrap-select-has-loading">
                                    {loadingAddNewTreatment && (
                                        <Box className={'loading-select'}>
                                            <CircularProgress size={'20px'} />
                                        </Box>
                                    )}
                                    <TextBox
                                        label="New Treatment Type"
                                        placeholder="Input Treatment Type"
                                        layout="vertical"
                                        value={newSubstanceTreatment}
                                        disabled={loadingAddNewTreatment}
                                        onChange={(val) => setNewSubstanceTreatment(val)}
                                    />
                                </Box>
                            </Grid>

                            <Button variant="contained" onClick={hanleAddNewTreatment}>
                                Add New
                            </Button>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <InputNumber
                            label="Number of Episodes"
                            name="number_of_episodes"
                            value={formik.values.number_of_episodes}
                            min={0}
                            max={99}
                            error={
                                formik.touched.number_of_episodes &&
                                Boolean(formik.errors.number_of_episodes)
                            }
                            helperText={
                                formik.touched.number_of_episodes &&
                                formik.errors.number_of_episodes
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('number_of_episodes', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Age of First Treatment"
                            name="age_of_first_treatment"
                            value={formik.values.age_of_first_treatment}
                            placeholder="Age of First Use"
                            min={1}
                            max={99}
                            error={
                                formik.touched.age_of_first_treatment &&
                                Boolean(formik.errors.age_of_first_treatment)
                            }
                            helperText={
                                formik.touched.age_of_first_treatment &&
                                formik.errors.age_of_first_treatment
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('age_of_first_treatment', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Age of Last Treatment"
                            name="age_of_last_treatment"
                            min={1}
                            max={99}
                            value={formik.values.age_of_last_treatment}
                            placeholder="Age of Last Use"
                            error={
                                formik.touched.age_of_last_treatment &&
                                Boolean(formik.errors.age_of_last_treatment)
                            }
                            helperText={
                                formik.touched.age_of_last_treatment &&
                                formik.errors.age_of_last_treatment
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('age_of_last_treatment', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Additional Treatment Information"
                            name="additional_treatment_information"
                            minRows={4}
                            value={formik.values.additional_info}
                            onChange={(value) => formik.setFieldValue('additional_info', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={submitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => {
                            formik.handleSubmit()
                        }}
                        okBtnText={props.substanceAbuseTreatmentId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export { SubstanceAbuseTreatmentForm }
export default SubstanceAbuseTreatmentForm
