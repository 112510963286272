import React, { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    Divider,
    Typography,
    TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
    calculateBMI,
    convertHeightToFtAndInches,
    convertHeightToInches,
    convertWeight,
} from '../../../containers/ClinicalNotes/utils'
import BtnSwitchToggle from '../../shared/Elements/BtnSwitchToggle'

const vitalHeadings = [{ label: '' }, { label: 'Previous' }, { label: 'Current' }]

const useStyles = makeStyles({
    root: {
        '& .MuiTypography-subtitle1': {
            fontSize: '16px',
            lineHeight: '22.4px',
            color: '#303E4E',
            fontWeight: '500',
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '13px',
            lineHeight: '18.2px',
            color: '#9CA9B8',
        },
        '& .MuiTextField-root': {},
    },
    divider: {
        background: '#E9ECF1',
        height: 1,
        marginBottom: 24,
        marginTop: 14,
    },

    input: {
        width: '322.5px',
        borderRadius: '5px',
        border: '1px solid #E9ECF1',
        textAlign: 'center',
        background: '#E9ECF1',
    },
    inputHeight: {
        '& .MuiTextField-root': {
            width: '210px',
        },
    },
})

function sanitizeInput(value) {
    // Remove all characters except digits and decimal point
    let cleanedValue = value.replace(/[^0-9.]/g, '')
    // Ensure only one decimal point
    let parts = cleanedValue.split('.')
    cleanedValue = parts[0] + (parts.length > 1 ? '.' + parts[1] : '')
    return cleanedValue
}

const convertValues = (value, unit, sectionName) => {
    if (value !== undefined) {
        if (sectionName === 'weight') {
            return convertWeight(value, unit)
        }
        if (sectionName === 'height') {
            if (unit === 'ft/in') {
                const { ft, inches } = convertHeightToFtAndInches(value)
                return `${ft}/${inches}`
            }
            const valArr = value.split('/')
            const val = convertHeightToInches(valArr[0], valArr[1])
            return val
        }
    }
}

const getConvertedBMI = (height, heightUnit, weight, weightUnit) => {
    let updatedHeight = height
    let updatedWeight = weight
    if (heightUnit === 'ft/in') {
        const heightArr = height.split('/')
        updatedHeight = convertHeightToInches(heightArr[0], heightArr[1])
    }

    if (weightUnit === 'kg') {
        updatedWeight = convertWeight(weight)
    }
    return calculateBMI(updatedHeight, updatedWeight)
}

const Slash = () => {
    const classes = useStyles()
    return <Typography className={classes.slash}>/</Typography>
}

const RenderBloodPressure = ({ master, values, handleChange, nonEditable }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'blood pressure',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const valueArr = filteredEle?.value?.split('/')?.map((v) => v.trim()) || []

    const handleInput = (e) => {
        const { name, value } = e.target
        const obj = {
            value_0: valueArr[0] || '',
            value_1: valueArr[1] || '',
        }
        obj[name] = value.replace(/[^0-9]/g, '')
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: `${obj.value_0}/${obj.value_1}`,
        })
    }

    return (
        <Box
            sx={{
                border: '1px solid #E9ECF1',
                width: '322.5px',
                height: '40px',
                borderRadius: 5,
                alignItems: 'center',
                display: 'flex',
                backgroundColor: '#FBFCFC',
            }}>
            <TextField
                disabled={nonEditable}
                variant="standard"
                placeholder="Systolic"
                inputProps={{
                    maxLength: 3,
                    style: {
                        textAlign: 'center',
                    },
                }}
                InputProps={{ disableUnderline: true }}
                value={valueArr[0] === 'undefined' ? '' : valueArr[0]}
                name="value_0"
                onChange={handleInput}
            />
            <Slash />
            <TextField
                disabled={nonEditable}
                variant="standard"
                placeholder="Diastolic"
                inputProps={{
                    maxLength: 3,
                    style: {
                        textAlign: 'center',
                    },
                }}
                InputProps={{ disableUnderline: true }}
                value={valueArr[1] === 'undefined' ? '' : valueArr[1]}
                name="value_1"
                onChange={handleInput}
            />
        </Box>
    )
}

const RenderHeightFtIn = ({ master, values, handleChange, nonEditable }) => {
    const classes = useStyles()
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'height',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const valueArr = filteredEle?.value?.split('/')?.map((v) => v.trim()) || []

    const handleInput = (e) => {
        const { name, value } = e.target
        const obj = {
            value_0: valueArr[0] || '',
            value_1: valueArr[1] || '',
        }
        obj[name] = value.replace(/[^0-9]/g, '')
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: `${obj.value_0}/${obj.value_1}`,
        })
    }

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
            }}>
            <TextField
                variant="standard"
                placeholder="Feet"
                disabled={nonEditable}
                classes={{
                    root: classes.inputHeight,
                }}
                inputProps={{
                    maxLength: 3,
                    style: {
                        textAlign: 'center',
                    },
                }}
                InputProps={{ disableUnderline: true }}
                value={valueArr[0] === 'undefined' ? '' : valueArr[0]}
                name="value_0"
                onChange={handleInput}
            />
            <Slash />
            <TextField
                variant="standard"
                placeholder="Inches"
                disabled={nonEditable}
                inputProps={{
                    maxLength: 3,
                    style: {
                        textAlign: 'center',
                    },
                }}
                InputProps={{ disableUnderline: true }}
                value={valueArr[1] === 'undefined' ? '' : valueArr[1]}
                name="value_1"
                onChange={handleInput}
            />
        </Box>
    )
}

const RenderPulseOximetry = ({ master, values, handleChange, nonEditable }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'pulse ox',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { value } = e.target
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: value.replace(/[^0-9]/g, ''),
        })
    }
    return (
        <TextField
            sx={{
                border: '1px solid #B0BCE4',
            }}
            placeholder="Pulse Oximetry"
            fullWidth="true"
            disabled={nonEditable}
            InputProps={{
                maxLength: 3,
                style: {
                    width: '322.5px',
                    borderRadius: '5px',
                    border: '1px solid #E9ECF1',
                    textAlign: 'center',
                    background: '#FBFCFC',
                },
                disableUnderline: true,
            }}
            hiddenLabel
            value={filteredEle?.value}
            variant="filled"
            size="small"
            onChange={handleInput}
        />
    )
}

const RenderTemperature = ({ master, values, handleChange, nonEditable }) => {
    const classes = useStyles()
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'temperature',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { value } = e.target
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value,
        })
    }
    return (
        <TextField
            sx={{
                border: '1px solid #B0BCE4',
            }}
            disabled={nonEditable}
            placeholder="Temperature"
            fullWidth="true"
            InputProps={{
                style: {
                    width: '322.5px',
                    borderRadius: '5px',
                    border: '1px solid #E9ECF1',
                    textAlign: 'center',
                    background: '#FBFCFC',
                },
                disableUnderline: true,
            }}
            hiddenLabel
            value={filteredEle?.value}
            variant="filled"
            size="small"
            onChange={(e) => {
                const { value } = e.target
                if ((value >= -459.67 && value <= 1000) || value === '-') {
                    handleInput(e)
                }
            }}
        />
    )
}

const RenderHeartRate = ({ master, values, handleChange, nonEditable }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'heart rate',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (value) => {
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value,
        })
    }
    return (
        <TextField
            sx={{
                border: '1px solid #B0BCE4',
            }}
            disabled={nonEditable}
            placeholder="Heart Rate"
            fullWidth="true"
            InputProps={{
                style: {
                    width: '322.5px',
                    borderRadius: '5px',
                    border: '1px solid #E9ECF1',
                    textAlign: 'center',
                    background: '#FBFCFC',
                },
                disableUnderline: true,
            }}
            hiddenLabel
            value={filteredEle?.value}
            variant="filled"
            size="small"
            onChange={(e) => {
                if (/^[0-9]*$/.test(e.target.value)) {
                    handleInput(e.target.value)
                }
            }}
        />
    )
}
const RenderRespiratoryRate = ({ master, values, handleChange, nonEditable }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'respiratory rate',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { value } = e.target
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: value.replace(/[^0-9]/g, ''),
        })
    }
    return (
        <TextField
            sx={{
                border: '1px solid #B0BCE4',
            }}
            placeholder="Respiratory Rate"
            fullWidth="true"
            disabled={nonEditable}
            InputProps={{
                maxLength: 3,
                style: {
                    width: '322.5px',
                    borderRadius: '5px',
                    border: '1px solid #E9ECF1',
                    textAlign: 'center',
                    background: '#FBFCFC',
                },
                disableUnderline: true,
            }}
            hiddenLabel
            value={filteredEle?.value}
            variant="filled"
            size="small"
            onChange={handleInput}
        />
    )
}

const RenderHeight = ({ master, values, handleChange, nonEditable }) => {
    const classes = useStyles()
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'height',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    bmiHeight = filteredEle?.value
    bmiHeightUnit = filteredEle?.unit
    let valueArr = ['', '']
    if (filteredEle?.value) {
        valueArr = `${filteredEle?.value}`?.split('/')?.map((v) => v.trim()) || ['', '']
    }
    console.log('unit', filteredEle?.unit, filteredMaster?.units[0])
    const handleInput = (e) => {
        const { name, value } = e.target
        let updatedValue = {}
        if (name === 'value') {
            updatedValue = {
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: value.replace(/[^0-9]/g, ''),
            }
        } else {
            const obj = {
                value_0: valueArr[0] || '',
                value_1: valueArr[1] || '',
            }
            obj[name] = value.replace(/[^0-9]/g, '')
            updatedValue = {
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: `${obj.value_0}/${obj.value_1}`,
            }
        }
        handleChange(updatedValue)
        bmiHeight = updatedValue.value
        bmiHeightUnit = updatedValue.unit
    }

    const handleToggle = (updatedUnit) => {
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: convertValues(filteredEle?.value, updatedUnit, 'height'),
            unit: updatedUnit,
        })
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
                width: '322.5px',
                borderRadius: '5px',
                border: '1px solid #E9ECF1',
                background: '#FBFCFC',
            }}>
            <Grid xs={6}>
                {filteredEle?.unit === filteredMaster?.units[1] ? (
                    <TextField
                        classes={{
                            root: classes.inputHeight,
                        }}
                        placeholder="Height"
                        disabled={nonEditable}
                        onChange={handleInput}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                backgroundColor: 'unset',
                            },
                        }}
                        value={filteredEle?.value}
                        hiddenLabel
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                        name="value"
                    />
                ) : (
                    <RenderHeightFtIn
                        master={master}
                        values={values}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                )}
            </Grid>
            <Grid xs={6}>
                <BtnSwitchToggle
                    label1={filteredMaster?.units[0]}
                    label2={filteredMaster?.units[1]}
                    unit={filteredEle?.unit}
                    handleToggle={handleToggle}
                />
            </Grid>
        </Grid>
    )
}

const RenderWeight = ({ master, values, handleChange, nonEditable }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'weight',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    bmiWeight = filteredEle?.value
    bmiWeightUnit = filteredEle?.unit

    const handleInput = (e) => {
        const { value } = e.target
        const updatedValue = {
            vital_master_id: filteredMaster?._id,
            unit: filteredEle?.unit || filteredMaster?.units[0],
            value: sanitizeInput(value),
        }
        handleChange(updatedValue)
        bmiWeight = updatedValue.value
        bmiWeightUnit = updatedValue.unit
    }

    const handleToggle = (updatedUnit) => {
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: convertValues(filteredEle?.value, updatedUnit, 'weight'),
            unit: updatedUnit,
        })
    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
                width: '322.5px',
                borderRadius: '5px',
                border: '1px solid #E9ECF1',
                background: '#FBFCFC',
            }}>
            <Grid xs={6}>
                <TextField
                    placeholder="Weight"
                    onChange={handleInput}
                    InputProps={{
                        style: {
                            background: '#FBFCFC',
                        },
                        disableUnderline: true,
                    }}
                    disabled={nonEditable}
                    value={filteredEle?.value}
                    hiddenLabel
                    variant="filled"
                    size="small"
                />
            </Grid>
            <Grid xs={6}>
                <BtnSwitchToggle
                    handleToggle={handleToggle}
                    label1={filteredMaster?.units[0]}
                    label2={filteredMaster?.units[1]}
                    unit={filteredEle?.unit}
                />
            </Grid>
        </Grid>
    )
}

const RenderSmokingStatus = ({ master, values, handleChange }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'smoking status',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleToggle = (e) => {
        const checked = e.target.value
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: checked,
        })
    }
    return (
        <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="smokingStatus"
            onChange={(e) => {
                handleToggle(e)
            }}>
            <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
                checked={filteredEle?.value === 'yes'}
            />
            <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
                checked={filteredEle?.value === 'no'}
            />
        </RadioGroup>
    )
}

const RenderBMI = ({ master, values }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'bmi',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)
    return (
        <TextField
            sx={{
                border: '1px solid #B0BCE4',
            }}
            placeholder="BMI"
            fullWidth="true"
            disabled={true}
            InputProps={{
                style: {
                    width: '322.5px',
                    borderRadius: '5px',
                    border: '1px solid #E9ECF1',
                    textAlign: 'center',
                    background: '#FBFCFC',
                },
                disableUnderline: true,
            }}
            hiddenLabel
            id="filled-hidden-label-small"
            value={filteredEle?.value}
            variant="filled"
            size="small"
        />
    )
}

let bmiHeight = ''
let bmiWeight = ''
let bmiHeightUnit = ''
let bmiWeightUnit = ''

const Vitals = ({
    master,
    values,
    onChange,
    defaultValues,
    clinicalNoteData,
    nonEditable = false,
    previous_cn,
}) => {
    const classes = useStyles()
    const [previousHeight, setPreviousHeight] = useState(
        defaultValues?.current?.find((el) => el.label === 'Height'),
    )
    const [previousWeight, setPreviousWeight] = useState(
        defaultValues?.current?.find((el) => el.label === 'Weight'),
    )
    const [previousData, setPreviousData] = useState([])

    useEffect(() => {
        setPreviousData([...values.current])
    }, [])

    const prevData = useMemo(() => {
        const values = previous_cn?.vitals?.current?.reduce(function (map, el) {
            map[el.label] = el
            return map
        }, {})

        const get = (fieldName) => {
            return values?.[fieldName]?.value
                ? `${values[fieldName]?.value} ${values[fieldName].unit}`
                : ''
        }

        return {
            temperature: get('Temperature'),
            bmi: get('BMI'),
            blood_pressure: get('Blood Pressure'),
            height: get('Height'),
            weight: get('Weight'),
            respiratory_rate: get('Respiratory Rate'),
            pulse_ox: get('Pulse Ox'),
            heart_rate: get('Heart Rate'),
        }
    }, [previous_cn?.vitals?.current])

    useEffect(() => {
        if (previous_cn?.vitals?.current) {
            onChange('vitals.previous', previous_cn?.vitals?.current)
        }
    }, [previous_cn?.vitals?.current])

    useEffect(() => {
        if (bmiHeight && bmiHeightUnit && bmiWeight && bmiWeightUnit) {
            const bmi = getConvertedBMI(bmiHeight, bmiHeightUnit, bmiWeight, bmiWeightUnit)

            const filteredMaster = master?.vitalMasters?.find(
                (vm) => (vm.name || '').toLowerCase() === 'bmi',
            )

            const filteredEle = values.current?.find(
                (v) => v.vital_master_id === filteredMaster?._id,
            )

            handleChange({
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: bmi,
            })
        }
    }, [bmiHeight, bmiHeightUnit, bmiWeight, bmiWeightUnit])

    useEffect(() => {
        if (bmiHeightUnit === 'in' && previousHeight?.unit === 'ft/in') {
            const valArr = previousHeight.value.split('/')
            const val = convertHeightToInches(valArr[0], valArr[1])
            setPreviousHeight({
                ...previousHeight,
                value: val,
                unit: bmiHeightUnit,
            })
        } else {
            if (bmiHeightUnit === 'ft/in' && previousHeight?.unit === 'in') {
                const { ft, inches } = convertHeightToFtAndInches(previousHeight.value)
                setPreviousHeight({
                    ...previousHeight,
                    value: `${ft}/${inches}`,
                    unit: bmiHeightUnit,
                })
            }
        }
    }, [bmiHeightUnit])

    useEffect(() => {
        if (previousWeight?.unit && previousWeight?.unit !== bmiWeightUnit) {
            setPreviousWeight({
                ...previousWeight,
                value: convertWeight(previousWeight.value, bmiWeightUnit),
                unit: bmiWeightUnit,
            })
        }
    }, [bmiWeightUnit])

    const handleChange = (updatedValue) => {
        const updatedValues = [...values.current]
        const existingIndex = values.current?.findIndex(
            (v) => v.vital_master_id === updatedValue.vital_master_id,
        )
        if (existingIndex !== -1) {
            updatedValues[existingIndex] = {
                ...updatedValues[existingIndex],
                ...updatedValue,
            }
        } else {
            updatedValues.push(updatedValue)
        }
        onChange('vitals.current', updatedValues)
    }
    return (
        <Box
            sx={{
                width: '100%',
            }}
            classes={{
                root: classes.root,
            }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={1}>
                {vitalHeadings.map((vh, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Grid xs={4}>
                        <Typography variant="subtitle1">{vh.label}</Typography>
                    </Grid>
                ))}
            </Grid>
            <Divider
                classes={{
                    root: classes.divider,
                }}
            />

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={1}>
                <Grid xs={4}>
                    <Typography variant="subtitle1">Temperature</Typography>
                    <Typography variant="subtitle2">°F</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">{prevData.temperature}</Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderTemperature
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                <Grid xs={4}>
                    <Typography variant="subtitle1">Blood pressure</Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            textTransform: 'none',
                        }}>
                        mm[Hg]
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">{prevData.blood_pressure}</Typography>
                </Grid>
                <Grid
                    xs={4}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid xs={6}>
                        <RenderBloodPressure
                            master={master}
                            values={values.current}
                            handleChange={handleChange}
                            nonEditable={nonEditable}></RenderBloodPressure>
                    </Grid>
                    <Grid xs={6}></Grid>
                </Grid>

                <Grid xs={4}>
                    <Typography variant="subtitle1">Pulse OX</Typography>
                    <Typography variant="subtitle2">%</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">
                        <Typography variant="subtitle1">{prevData.pulse_ox}</Typography>
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderPulseOximetry
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                {/* added heart rate */}
                <Grid xs={4}>
                    <Typography variant="subtitle1">Heart Rate</Typography>
                    <Typography variant="subtitle2">Beats per minute</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">
                        <Typography variant="subtitle1">{prevData?.heart_rate}</Typography>
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderHeartRate
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                {/* End of heart rate */}
                <Grid xs={4}>
                    <Typography variant="subtitle1">Respiratory rate</Typography>
                    <Typography variant="subtitle2">Breaths/Min</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">
                        <Typography variant="subtitle1">{prevData.respiratory_rate}</Typography>
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderRespiratoryRate
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                <Grid xs={4}>
                    <Typography variant="subtitle1">Height</Typography>
                    <Typography variant="subtitle2">{bmiHeightUnit}</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">{prevData.height}</Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderHeight
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                <Grid xs={4}>
                    <Typography variant="subtitle1">Weight</Typography>
                    <Typography variant="subtitle2">{bmiWeightUnit}</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">{prevData.weight}</Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderWeight
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                        nonEditable={nonEditable}
                    />
                </Grid>

                <Grid xs={4}>
                    <Typography variant="subtitle1">BMI</Typography>
                    <Typography variant="subtitle2">Kg/m2</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">
                        <Typography variant="subtitle1">{prevData.bmi}</Typography>
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <RenderBMI
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                    />
                </Grid>
                {/* <Grid xs={4}>
                    <Typography variant="subtitle1">Smoking Status</Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography variant="subtitle1">
                        {defaultValues?.current?.find((el) => el.label === 'Smoking Status')?.value}
                    </Typography>
                </Grid> */}
                {/* <Grid xs={4}>
                    <RenderSmokingStatus
                        master={master}
                        values={values.current}
                        handleChange={handleChange}
                    />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default Vitals
