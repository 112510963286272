import moment,{ unitOfTime } from 'moment';

export const VIEW = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    YEAR: 'year',
}
export const initialState = {
    view: {
        selected: VIEW.MONTH,
        date: new Date(new Date(moment()).getFullYear(), new Date(moment()).getMonth(), 1),
        start_date: moment().startOf(VIEW.MONTH).format('YYYY-MM-DDT00:00:00.000') + 'Z',
        end_date: moment().endOf(VIEW.MONTH).format('YYYY-MM-DDT23:59:59.000') + 'Z',
    },
    events: {
        isLoading: false,
        data:[],
        availability: [],
    }, 
}
export function reducer(state, action) {
    switch (action.type) {

        case 'CHANGE_CALENDAR_VIEW': {
            return {
                ...state,
                view: {
                    ...state.view,
                    selected: action.view,
                    start_date: moment(state.view.date).startOf(
                        action.view === 'week' ? 'isoWeek' : action.view,
                    ).format('YYYY-MM-DDT00:00:00.000') + 'Z',
                    end_date: moment(state.view.date).endOf(
                        action.view === 'week' ? 'isoWeek' : action.view,
                    ).format('YYYY-MM-DDT23:59:59.000') + 'Z',
                },
                events: {
                    ...state.events,
                    data: [],
                    availability: [],
                },
            }
        }

        case 'NAVIGATE_TO_DATE': {
            return {
                ...state,
                view: {
                    ...state.view,
                    selected: action.view,
                    date: moment(action.date),
                    start_date: moment(action.date).startOf(action.view).format('YYYY-MM-DDT00:00:00.000') + 'Z',
                    end_date: moment(action.date).endOf(action.view).format('YYYY-MM-DDT23:59:59.000') + 'Z',
                },
                events: {
                    ...state.events,
                    data: [],
                    availability: [],
                },
            }
        }
            
        case 'CHANGE_DATA': {
            return {
                ...state,
                events: {
                    ...state.events,
                    data: action.data,
                },
            }
        }

        
        default:
            return state
    }
}