import { makeStyles, TextField } from '@material-ui/core'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import React, { useEffect, useRef, useState } from 'react'
import './index.scss'

const useStyles = makeStyles(() => ({
    text__field: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e9ecf1 !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#667689',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important',
        },
    },
}))

const TooltipTextField = ({ value, ...props }) => {
    const textElementRef = useRef()
    const classes = useStyles()
    const [hoverStatus, setHover] = useState(false)

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth

        setHover(compare)
    }

    useEffect(() => {
        compareSize()
    }, [value])

    useEffect(() => {
        compareSize()
        window.addEventListener('resize', compareSize)
    }, [])

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener(
                'resize',
                compareSize,
            )
        },
        [],
    )
    return (
        <HtmlTooltip
            title={!hoverStatus ? value : ''}
            arrow
            placement="top"
            style={{
                background: 'white !important',
                color: '#303E4E',
            }}>
            <TextField
                ref={textElementRef}
                className={classes.text__field}
                fullWidth
                variant="outlined"
                size="small"
                value={value}
                {...props}
            />
        </HtmlTooltip>
    )
}

export default TooltipTextField
