import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    Tab,
    Tabs,
    withStyles,
} from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Box } from '@mui/material'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import Routes from 'constants/routes'
import { clinicalPracticeChange, createPatient, getPatient, putPatient } from 'services/Patient/patient'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
import { formatNumber } from 'shared/utils'
import { emailRegex, USPhoneRegexp } from 'utilities/regex'
import '../styles.scss'
import { Demographics } from './Demographics'
import { ImportantInfo } from './ImportantInfo'
import {
    INITIAL_VALUES,
    INSURANCE_INITIAL_VALUES,
    paymentMethodOptions,
    patientsRelationship,
} from './schema'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import ReportIcon from '@mui/icons-material/Report'
import CloseIcon from '@mui/icons-material/Close'
import InsuranceForm from '../view/Insurances/components/InsuranceForm'
import { apiInsurrancePolicy } from 'services/Patient/apiInsurrancePolicy'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import { getListInsurancePlans } from 'services/Patient/patient'
import { useSelector } from 'react-redux'
import ErrorIcon from 'assests/icons/error-icon.svg'
import { getPatientName } from '../shared'
import { serializePatientData } from './helpers'
const { v4: uuidv4 } = require('uuid')

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => (
    <div
        className="tooltip-tab"
        title={
            !props?.patientId &&
            props?.label === 'Insurance information' &&
            'Patient details must be saved before entering Insurance Information.'
        }>
        <Tab {...props} />
    </div>
))
const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        marginBottom: '1rem',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },
    headingText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontWeight: '500',
        paddingLeft: '10px'
    },
    content:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontWeight: '400',
        paddingLeft: '2px'
    },
})

const add_emergency_contacts = {
    related_person_name: '',
    relation: '',
    related_person_phone: '',
    related_person_email: '',
    is_emergency_contact: true,
    appointment_notification: '0',
}
export const PatientForm = () => {
    const classes = useStyles()
    const history = useHistory()
    const containerRef = useRef(null)
    const notification = useNotification()
    const previousRoute = history.location.state?.previousRoute
    const isPatientRecordPage = history.location.state?.patientRecord
    const { id: patientId, insuranceId } = useParams()
    const [value, setValue] = useState(0)
    const importantInfoTab = useRef(null)
    const demographicsTab = useRef(null)
    const [activeRange, setActiveRange] = useState(true)
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [patientPortalAnchorEl, setPatientPortalAnchorEl] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [planOptions, setPlanOptions] = React.useState([])
    const [anchorAlert, setAnchorAlert] = useState(null)
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const insuranceType = history?.location?.state?.insuranceType
    const [previousData, setPreviousData] = useState({})
    const [practiceList, setPracticeList] = useState([])

    useEffect(() => {
        calculateContainerHeight()
    })

    useEffect(() => {
        getPlansOptions()
        if (patientId) fetchPatientDetails(patientId)
        if (
            !formik.values?.emergency_contacts.length ||
            formik.values?.emergency_contacts.length === 0
        ) {
            formik.setValues({
                ...formik.values,
                emergency_contacts: [...formik.values?.emergency_contacts, add_emergency_contacts],
            })
        }
    }, [patientId])

    const fetchPatientDetails = async (id) => {
        setDataLoading(true)

        try {
            const resp = await getPatient(id)
            setPreviousData({
                cariumAccess: resp?.data?.carium_id ? true : false,
                clinicalPracticeId: resp?.data?.clinical_practice_id
            })
            // const convertEmergency_contact = resp.data.emergency_contacts?.length > 0 &&
            // resp.data.emergency_contacts?.map(item => {
            //     return {
            //         ...item,
            //         related_person_phone: item.related_person_phone || "",
            //         relation: item.relation || "",
            //         related_person_name: item.related_person_name || ""
            //     };
            // });
            setInitData({
                ...initData,
                ...resp.data,
                phone_number: formatNumber(resp.data.phone_number),
                related_person_phone: formatNumber(resp.data.related_person_phone),
                emergency_contacts:
                    resp.data.emergency_contacts?.length === 0
                        ? [add_emergency_contacts]
                        : resp.data.emergency_contacts,
            })
            if (!resp.data.is_push_referral && !resp.data.is_test_patient) {
                setActiveRange(false)
            }
        } catch (error) {
            notification(error?.data?.message || `Cannot get patient details ${id}`)
        }

        setDataLoading(false)
    }

    async function getPlansOptions() {
        try {
            const response = await getListInsurancePlans(0, 10000)
            const data = response.data.data.map((item) => ({
                key: item.id,
                value: item.plan_name,
                phone_number: item.phone_number,
            }))
            setPlanOptions(data)
        } catch (error) {
            console.log({ error })
        }
    }

    const calculateContainerHeight = useCallback(() => {
        const boundingRect = containerRef.current?.getBoundingClientRect() || {}
        if (window.innerHeight < boundingRect.height && containerRef.current) {
            containerRef.current.style.height = `${window.innerHeight - boundingRect.top}px`
        }
    }, [window.innerHeight])

    const updatePatient = async (patient) => {
        await putPatient(patientId, serializePatientData(patient))
        notification(`Patient updated successfully!`, 'success')
    }
    
    const createNewPatient = async (patient) => {
        await createPatient(serializePatientData(patient))
        notification(`Patient created successfully!`, 'success')
    }
    
    const redirectToAppropriatePage = () => {
        const targetRoute = isPatientRecordPage
            ? Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(':id', patientId)
            : Routes.PATIENT.MAIN
        history.push(targetRoute)
    }
    
    const handleSubmissionError = (error) => {
        const errMsg = patientId ? 'Patient updated unsuccessfully' : 'Patient created unsuccessfully'
        setSubmitting(false)
    
        if (error?.data?.message === "Email already exists" && error?.data?.data?.users?.length) {
            const formattedNames = error.data.data.users.map(user => `${getPatientName({ ...user, suffix: null })}(mrn:${user.id})`)
            const value = formattedNames.join(', ')
            const errorMessage = `Email already exists with ${error.data.data.users.length > 1 ? 'these patients:' : 'this patient:'} ${value}`
            notification(errorMessage, 'error')
            return
        }
    
        notification(error?.data?.message || errMsg, 'error')
    }

    const handleSubmit = async (patient) => {
        try {
            setSubmitting(true)
            console.log("before :: ", patient.dob)
    
            patient.dob = moment(patient.dob.includes('-') ? patient.dob : new Date(patient.dob)).format('YYYY-MM-DD')
            console.log("after :: ", patient.dob)
            const previousClinicalPracticeName = previousData?.clinicalPracticeId ? practiceList.find((item)=>(item?.key == previousData?.clinicalPracticeId)) : ""
            const presentClinicalPracticeName = patient?.clinical_practice_id ? practiceList.find((item)=>(item?.key === patient?.clinical_practice_id)) : ""
            if(patientId){
                updatePatient(patient)?.then(async(res)=>{
                    if(previousData?.cariumAccess && (previousData?.clinicalPracticeId !== patient?.clinical_practice_id)){
                        const payload = {
                            prevPracticeName : previousClinicalPracticeName?.value ? previousClinicalPracticeName?.value : "",
                            currPracticeName : presentClinicalPracticeName?.value
                        }
                        await clinicalPracticeChange(patientId,payload)
                        setSubmitting(false)
                        redirectToAppropriatePage()
                    }else{
                        setSubmitting(false)
                        redirectToAppropriatePage()
                    }
                }).catch((err) => {
                    setSubmitting(false)
                    notification(err?.data?.message || `Couldn't get updated patient details`, 'error')
                })
            }else{
                await createNewPatient(patient)
                setSubmitting(false)
                redirectToAppropriatePage()
            }
    
            // patientId ? await updatePatient(patient) : await createNewPatient(patient)
    
        } catch (error) {
            handleSubmissionError(error)
        }
    }

    const validationSchema = yup.object({
        access_patient_portal: yup.boolean().default(false).required(),
        first_name: yup
            .string()
            .trim()
            .max(35, 'Max 35 Characters')
            .required('First Name is required!'),
        middle_name: yup.string().trim().max(35, 'Max 35 Characters'),
        last_name: yup
            .string()
            .trim()
            .max(35, 'Max 35 Characters')
            .required('Last Name is required!'),
        suffix: yup.string().trim().max(10, 'Max 10 Characters'),
        previous_last_name: yup.string().trim(),
        previous_first_name: yup.string().trim(),
        previous_middle_name: yup.string().trim(),
        dob: yup.string().nullable().required('Date of Birth is required!'),
        photo: yup.string(),
        gender: yup.string().required('Gender is required!'),
        clinical_practice_id: yup.string().required('Clinical practice is required!'),
        zip: yup.string().trim().required('Zip Code is required!'),
        address1: yup
            .string()
            .trim()
            .max(100, 'Max 100 Characters')
            .required('Address line 1 is required!'),
        address2: yup.string().trim().max(100, 'Max 100 Characters'),
        email: yup
            .string()
            .trim()
            .max(100, 'Max 100 Characters')
            .matches(emailRegex, 'Invalid email!')
            .when('access_patient_portal', {
                is: true,
                then: yup
                    .string()
                    .trim()
                    .max(100, 'Max 100 Characters')
                    .matches(emailRegex, 'Invalid email!')
                    .required(),
            }),
        city: yup.string().max(100, 'Max 100 Characters').required('City is required!'),
        state: yup.string().required('State is required!'),
        phone_number: yup
            .string()
            .matches(USPhoneRegexp, 'Invalid phone number!')
            .required('Phone is required!'),
        date_of_death: yup.string(),
        primary_language: yup.object({
            code: yup.string().required('Primary language is required!'),
            display: yup.string(),
        }),
        other_language: yup.string().when('primary_language', {
            is: (primary_language) => primary_language.code === 'ot',
            then: yup.string().required('Specify other language!'),
        }),

        external_id: yup.string(),
        rcopia_id: yup.string(),
        sexual_orientation: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        race: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        ethnicity: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        tribal_affiliation: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        gender_identity: yup.object({
            code: yup.string().required('Gender Identity is required!'),
            display: yup.string(),
        }),
        occupation: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        occupation_industry: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        language: yup.object({
            code: yup.string(),
            display: yup.string(),
        }),
        smoking_status: yup.string(),
        smoking_start_date: yup
            .date()
            .nullable()
            .typeError('Invalid date')
            .test({
                name: 'max-smoking_start_date',
                exclusive: false,
                params: {},
                test(value, context) {
                    if (
                        !value &&
                        context.parent.smoking_status &&
                        context.parent.smoking_status !== '266919005'
                    ) {
                        return this.createError({
                            message: 'Please enter the start time',
                            path: 'smoking_start_date',
                        })
                    }
                    if (context.parent.smoking_end_date) {
                        if (moment(value) > moment(context.parent.smoking_end_date)) {
                            return this.createError({
                                message: 'The start time must before the end time',
                                path: 'smoking_start_date',
                            })
                        }
                    }
                    if (moment(value) > moment()) {
                        return this.createError({
                            message: 'The start time must before the current time',
                            path: 'smoking_start_date',
                        })
                    }
                    return true
                },
            }),
        smoking_end_date: yup
            .date()
            .nullable()
            .typeError('Invalid date')
            .max(moment(), 'The end time must before the current time')
            .test({
                name: 'min-smoking_end_date',
                exclusive: false,
                params: {},
                test(value, context) {
                    if (!value && context.parent.smoking_status === '8517006') {
                        return this.createError({
                            message: 'Please enter the end time',
                            path: 'smoking_end_date',
                        })
                    }
                    if (context.parent.smoking_start_date) {
                        if (moment(value) < moment(context.parent.smoking_start_date)) {
                            return this.createError({
                                message: 'The end time must after the start time',
                                path: 'smoking_end_date',
                            })
                        }
                    }
                    return true
                },
            }),
        primary_care_physician: yup.string().trim(), // @TODO:
        patient_status: yup.string(), // @TODO
        timezone: yup.string().required('Time Zone is required!'),
        emergency_contacts: yup.array().of(
            yup.object().shape({
                relation: yup.string(),
                related_person_name: yup.string().trim(),
                related_person_phone: yup
                    .string()
                    .trim()
                    .matches(USPhoneRegexp, 'Invalid phone number!'),
                related_person_email: yup.string().trim().matches(emailRegex, 'Invalid email!'),
                appointment_notification: yup.string(),
            }),
        ),
    })
    /** Patient Formik */
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })
    /** Insurance Formik */
    const validationInsuranceSchema = yup.object({
        insurance: yup.object({
            plan: yup.object({
                code: yup.string().test({
                    name: 'plan_code',
                    test(value, context) {
                        if (context.from[2].value.payment_method.code === 'Insurance' && !value) {
                            return this.createError({
                                message: 'Enter health plan',
                                path: 'insurance.plan.code',
                            })
                        }
                        return true
                    },
                }),
                display: yup.string(),
            }),
            policy_no: yup.string().test({
                name: 'policy_no',
                test(value, context) {
                    if (context.from[1].value.payment_method.code === 'Insurance' && !value) {
                        return this.createError({
                            message: 'Enter policy number',
                            path: 'insurance.policy_no',
                        })
                    }
                    return true
                },
            }),
            policy_effective: yup.string().test({
                name: 'policy_effective',
                test(_, context) {
                    const { policy_effective, policy_end } = context.from[1].value.insurance

                    if (
                        context.from[1].value.payment_method.code === 'Insurance' &&
                        !policy_effective
                    ) {
                        return this.createError({
                            message: 'Enter effective start date',
                            path: 'insurance.policy_effective',
                        })
                    }

                    const date = moment(policy_effective)
                    if (!date.isValid()) {
                        return this.createError({
                            message: 'Date invalid',
                            path: 'insurance.policy_effective',
                        })
                    }

                    if (policy_end && moment(policy_end).isBefore(date)) {
                        return this.createError({
                            message: 'Effective start date must be before effective end date',
                            path: 'insurance.policy_effective',
                        })
                    }
                    return true
                },
            }),
            policy_end: yup.string().test({
                name: 'policy_end',
                test(_, context) {
                    const { policy_effective, policy_end } = context.from[1].value.insurance

                    const date = moment(policy_end)
                    if (!date.isValid()) {
                        return this.createError({
                            message: 'Date invalid',
                            path: 'insurance.policy_end',
                        })
                    }

                    if (policy_effective && moment(policy_effective).isAfter(date)) {
                        return this.createError({
                            message: 'Effective end date must be after effective start date',
                            path: 'insurance.policy_end',
                        })
                    }
                    return true
                },
            }),
            patient_relationship: yup.string().test({
                name: 'patient_relationship',
                test(value, context) {
                    if (context.from[1].value.payment_method.code === 'Insurance' && !value) {
                        return this.createError({
                            message: "Select patient's relationship",
                            path: 'insurance.patient_relationship',
                        })
                    }
                    return true
                },
            }),
            relation_info: yup.object({
                dob: yup.string().test({
                    name: 'relation_info_dob',
                    test(_, context) {
                        const { dob } = context.from[1].value.relation_info

                        if (!dob || context.from[1].value.patient_relationship === 'Self')
                            return true

                        const date = moment(dob)
                        if (!date.isValid()) {
                            return this.createError({
                                message: 'Date invalid',
                                path: 'insurance.relation_info.dob',
                            })
                        }

                        if (moment().isBefore(date)) {
                            return this.createError({
                                message: 'Date of birth must be before current date',
                                path: 'insurance.relation_info.dob',
                            })
                        }
                        return true
                    },
                }),
                ssn: yup.string().test({
                    name: 'relation_info_ssn',
                    test(_, context) {
                        const { ssn } = context.from[1].value.relation_info

                        if (!ssn || context.from[1].value.patient_relationship === 'Self')
                            return true

                        if (!/^(\d{3})-?(\d{2})-?(\d{4})$/.test(ssn)) {
                            return this.createError({
                                message: 'SSN invalid',
                                path: 'insurance.relation_info.ssn',
                            })
                        }
                        return true
                    },
                }),
            }),
            deductible: yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, 'Invalid number'),
            deductible_met: yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, 'Invalid number'),
        }),
    })
    const handleInsuranceSubmit = async (payload) => {
        try {
            if (
                formikInsurance?.values?.payment_method?.code === 'Insurance' &&
                formikInsurance?.values?.type === ''
            ) {
                notification('Please select an insurance-type!', 'error')
                return
            }

            setIsSubmitting(true)

            delete payload.patient_id
            delete payload.practitioner_id
            delete payload.insurance.is_policy_emp

            if (payload.insurance.patient_relationship === 'Self') {
                payload.insurance.relation_info = INSURANCE_INITIAL_VALUES.insurance.relation_info
            }

            if (payload.payment_method.code === 'Self Pay') {
                payload.insurance = {
                    ...INSURANCE_INITIAL_VALUES.insurance,
                    policy_effective: payload.insurance.policy_effective,
                    policy_end: payload.insurance.policy_end,
                    patient_relationship: payload.insurance.patient_relationship,
                    relation_info: payload.insurance.relation_info,
                }
                payload.status = ''
                payload.type = ''
            }

            const valueToSend = {
                ...payload,
                insurance: {
                    ...payload.insurance,
                    group_name: payload.insurance?.group_name.trim(),
                    policy_effective:
                        payload.insurance?.policy_effective &&
                        moment(payload.insurance?.policy_effective).utc(),
                    policy_end:
                        payload.insurance?.policy_end &&
                        moment(payload.insurance?.policy_end).utc(),
                },
            }
            let res
            if (insuranceId) {
                await apiInsurrancePolicy.update(patientId, insuranceId, valueToSend)
            } else {
                res = await apiInsurrancePolicy.create(patientId, valueToSend)
            }
            const msg = insuranceId
                ? `Insurance plan updated successfully!`
                : `Insurance plan created successfully!`

            notification(msg, 'success')
        } catch (error) {
            const errMsg =
                error?.data?.message ||
                (insuranceId
                    ? `Insurance plan updated unsuccessfully`
                    : `Insurance plan created unsuccessfully`)

            notification(errMsg, 'error')
        } finally {
            setIsSubmitting(false)
        }
    }
    const formikInsurance = useFormik({
        validationSchema: validationInsuranceSchema,
        enableReinitialize: true,
        initialValues: INSURANCE_INITIAL_VALUES,
        onSubmit: handleInsuranceSubmit,
    })
    /** Auto scroll into position of element whose validation does not meet */
    const validateForm = (errors) => {
        if (!isEmpty(errors)) {
            const field = Object.keys(errors)[0]

            const el = containerRef.current?.querySelector(`[name="${field}"]`)

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
    }
    /**
     * Add Emergecy Contact value items
     */
    const handleAddItemEmergencyContact = () => {
        formik.setValues({
            ...formik.values,
            emergency_contacts: [...formik.values?.emergency_contacts, add_emergency_contacts],
        })
    }
    /**
     * Set Emergengy line value items
     */
    const setEmergengyLine = (lineIndex, field, value) => {
        formik?.setFieldValue(
            'emergency_contacts',
            formik.values?.emergency_contacts.map((line, index) =>
                index === lineIndex ? { ...line, [field]: value } : line,
            ),
        )
    }
    const handleClickDeleteIcon = (event, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(index)
    }
    /**
     * Delete row in service line value items
     */
    const handleDeleteServiceLine = () => {
        if (selectedItemIndex !== null) {
            if (formik.values?.emergency_contacts?.length > 1) {
                const updatedItems = formik.values?.emergency_contacts.filter(
                    (_, i) => i !== selectedItemIndex,
                )
                formik.setValues({ ...formik.values, emergency_contacts: updatedItems })
                notification(`Delete the emergency contact successfully!`, 'success')
            } else {
                formik.setValues({
                    ...formik.values,
                    emergency_contacts: [add_emergency_contacts],
                })
                notification(
                    `You Can't delete the emergency contact row but filed empty sucessfully`,
                    'info',
                )
            }
        }
        setSelectedItemIndex(null)
        setAnchorEl(null)
    }

    const getFullName = () => {
        const { first_name, middle_name, last_name, id } = formik.values
        if (id !== '') {
            if (middle_name !== '') return `${first_name} ${middle_name} ${last_name}`
            return `${first_name} ${last_name}`
        }
        return ''
    }

    const patientPortalDialodContent = () => {
        if (
            formik?.values?.first_name === '' ||
            formik?.values?.last_name === '' ||
            formik?.values?.phone_number === '' ||
            formik?.values?.clinical_practice_id === ''
        ) {
            return (
                <div style={{ display: 'flex', gap: 5, alignItems: 'flex-start' }}>
                    <Typography>
                        Please fill all the mandatory fields in the patient information!
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setPatientPortalAnchorEl(false)
                        }}>
                        <CloseIcon
                            style={{ fontSize: '20px', marginleft: '50px', color: '#666' }}
                        />
                    </IconButton>
                </div>
            )
        }else if(formik?.values?.id && !formik?.values?.access_patient_portal && formik?.values?.shared_email_users?.length > 0){
            return (
                <div style={{ width: '350px',padding:"10px" }}>
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                        }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ErrorIcon} alt=""></img>
                            <Typography className={classes.headingText}>
                                Patient has a shared email 
                            </Typography>
                        </span>
                    </span>
                    <Typography className={classes.content}>
                        The patient <span style={{color: '#303E4E',fontWeight:"500"}}>{getFullName()}</span> has a shared email with:
                        <ul>
                            {formik?.values?.shared_email_users?.length > 0 && formik?.values?.shared_email_users.map((item, index)=>(
                                <li key={item.id} style={{color: '#303E4E',fontWeight:"500"}}>{index+1}{". "}{getPatientName({ ...item, suffix: null })}</li>
                            ))} 
                        </ul>
            
                    </Typography>     
                    <Typography className={classes.content}>
                        Allow <span style={{color: '#303E4E',fontWeight:"500"}}>{getFullName()}</span> access to the patient portal?
                    </Typography>
    
                    <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px', paddingTop: '15px' }}>
                        <Button
                            size="small"
                            className="cancel-btn"
                            onClick={() => setPatientPortalAnchorEl(false)}
                            style={{ marginRight: '10px', color: '#444',fontSize: '18px' }}>
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                background: '#5571C6',
                                borderRadius: '5px !important',
                                fontSize: '18px',
                            }}
                            onClick={() => {
                                        setPatientPortalAnchorEl(false)
                                        formik?.setFieldValue(
                                            'access_patient_portal',
                                            !formik.values?.access_patient_portal,
                                        )
                                    }}>
                            Allow
                        </Button>
                    </span>
                </div>
            )
        }
        return (
            <div style={{ maxWidth: '350px' }}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                    }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <ReportIcon
                            style={{
                                fontSize: '18px',
                                marginRight: '10px',
                                color: formik?.values?.access_patient_portal
                                    ? '#E81A1A'
                                    : '#5571C6',
                            }}
                        />
                        <Typography variant="h6" fontWeight="bold">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: formik?.values?.access_patient_portal
                                        ? 'Remove Patient Portal Access'
                                        : 'Allow Patient Portal Access',
                                }}></div>
                        </Typography>
                    </span>
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3,
                        }}>
                        {formik?.values?.access_patient_portal ? 'Unchecking the box will turn off access to the patient portal for' : 'Checking the box will allow'}
                        <Typography style={{ color: '#303E4E', fontWeight: 500 }}>{getFullName()}</Typography> {formik?.values?.access_patient_portal ? '.' : 'access to the patient portal.'}
                    </div>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        size="small"
                        className="cancel-btn"
                        onClick={() => setPatientPortalAnchorEl(false)}
                        style={{ marginRight: '10px', color: '#444' }}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            background: formik.values?.access_patient_portal
                                ? '#E81A1A'
                                : '#5571C6',
                            borderRadius: '5px !important',
                        }}
                        onClick={() => {
                            setPatientPortalAnchorEl(false)
                            formik?.setFieldValue(
                                'access_patient_portal',
                                !formik.values?.access_patient_portal,
                            )
                        }}>
                        {formik.values?.access_patient_portal ? 'Remove' : 'Allow'}
                    </Button>
                </span>
            </div>
        )
    }
    const handleChange = (e,newValue) => {
        setValue(newValue)
    }
    const isSelfPay = formikInsurance.values.payment_method?.code === 'Self Pay'
    const resetRelationshipObj = React.useCallback(() => {
        formikInsurance.setValues({
            ...formikInsurance.values,
            insurance: {
                ...formikInsurance.values.insurance,
                patient_relationship: 'Self',
                relation_info: {
                    first_name: '',
                    last_name: '',
                    insured_number: '',
                    ssn: '',
                    dob: '',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
            },
        })
    }, [])
    const isSelfInsured =
        formikInsurance.values.insurance.patient_relationship &&
        formikInsurance.values.insurance.patient_relationship !== 'Self'
    const handleNotesBlur = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (insuranceId === 0 || !insuranceId) {
                if (formikInsurance.values.note !== '') {
                    allNotesFinal = [
                        ...formikInsurance.values?.insurance?.notes,
                        {
                            id: uuidv4(),
                            content: formikInsurance.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note',
                        },
                    ]
                }
            } else {
                if (
                    formikInsurance.values?.noteView === 'no-view' &&
                    formikInsurance.values.note !== ''
                ) {
                    allNotesFinal = [
                        ...formikInsurance.values?.insurance?.notes,
                        {
                            id: uuidv4(),
                            content: formikInsurance.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note',
                        },
                    ]
                } else allNotesFinal = formikInsurance.values?.insurance?.notes
            }
            formikInsurance.setFieldValue('insurance.notes', allNotesFinal)
            formikInsurance.setFieldValue('note', '')
        },
        [formikInsurance],
    )
    const openAlert = React.useCallback((e) => {
        setAnchorAlert(e.currentTarget)
    }, [])

    const clinicalPracticeList = (value) =>{
        setPracticeList(value)
    }
    return (
        <Grid style={{ position: 'relative',overflow:"hidden" }}>
            <Loading visible={dataLoading} thickness={2} />

            <div ref={containerRef} className="patient-create">
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Grid
                            container
                            onClick={() => history.goBack()}
                            style={{
                                cursor: 'pointer',
                                width: 'fit-content',
                            }}>
                            <KeyboardBackspaceRoundedIcon
                                style={{
                                    color: '#5571C6',
                                    fontSize: 18,
                                }}
                            />
                            <Typography className={classes.text_back}>
                                {`Back To ${isPatientRecordPage
                                    ? 'Patient Record'
                                    : previousRoute
                                        ? 'Fax List'
                                        : 'Patient List'
                                    }`}
                            </Typography>
                        </Grid>

                        <Typography className={classes.header}>
                            {patientId ? 'Update patient' : 'Add New Patient'}
                        </Typography>
                        <Grid container>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ style: { display: 'none' } }}>
                                <CustomTab
                                    style={{ background: 'none', textTransform: 'capitalize' }}
                                    label="Patient Information"
                                />
                                <CustomTab
                                    style={{ background: 'none', textTransform: 'capitalize' }}
                                    label="Insurance information"
                                    disabled={!patientId}
                                />
                            </Tabs>
                        </Grid>
                        {value === 0 && (
                            <Grid container style={{height:"610px",overflow:"auto"}}>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Grid style={{ marginBottom: 16, marginTop: '1rem' }}>
                                        <fieldset className="divider-information">
                                            <legend>
                                                <Typography className={'heading1-title'}>
                                                    Options
                                                </Typography>
                                            </legend>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'start',
                                                    justifyContent: `${activeRange ? 'space-between' : 'flex-end'
                                                        }`,
                                                }}>
                                                {activeRange && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            gap: 100,
                                                        }}>
                                                        <div>
                                                            <Box>
                                                                <CheckboxOption
                                                                    option={{
                                                                        label: 'Create a referral',
                                                                        value: formik.values
                                                                            .is_push_referral,
                                                                    }}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            'is_push_referral',
                                                                            e.target.checked,
                                                                        )
                                                                    }}
                                                                    checked={
                                                                        formik.values
                                                                            .is_push_referral
                                                                    }
                                                                />
                                                            </Box>
                                                            <Box sx={{ marginBottom: '1.5rem' }}>
                                                                <CheckboxOption
                                                                    option={{
                                                                        label: 'Test Patient',
                                                                        value: formik.values
                                                                            .is_test_patient,
                                                                    }}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            'is_test_patient',
                                                                            e.target.checked,
                                                                        )
                                                                    }}
                                                                    checked={
                                                                        formik.values
                                                                            .is_test_patient
                                                                    }
                                                                />
                                                            </Box>
                                                        </div>
                                                        <div>
                                                            <HtmlTooltip
                                                                PopperProps={{
                                                                    disablePortal: true,
                                                                }}
                                                                title={patientPortalDialodContent()}
                                                                open={patientPortalAnchorEl}
                                                                disableHoverListener
                                                                arrow
                                                                placement="bottom-start">
                                                                <Box>
                                                                    <CheckboxOption
                                                                        option={{
                                                                            label: 'Patient Portal',
                                                                            value: formik.values
                                                                                ?.access_patient_portal,
                                                                        }}
                                                                        onChange={(e) => {
                                                                            setPatientPortalAnchorEl(
                                                                                true,
                                                                            )
                                                                        }}
                                                                        checked={
                                                                            formik.values
                                                                                ?.access_patient_portal
                                                                        }
                                                                    />
                                                                </Box>
                                                            </HtmlTooltip>
                                                        </div>
                                                    </div>
                                                )}

                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                    }}>
                                                    <span
                                                        style={{
                                                            fontSize: '12px',
                                                            marginRight: '5px',
                                                        }}>
                                                        Hide
                                                    </span>
                                                    <ToggleSwitch
                                                        onChange={() => {
                                                            setActiveRange(
                                                                activeRange ? false : true,
                                                            )
                                                        }}
                                                        checked={activeRange}
                                                    />
                                                    <span
                                                        style={{
                                                            fontSize: '12px',
                                                            marginLeft: '5px',
                                                        }}>
                                                        Show
                                                    </span>
                                                </Box>
                                            </div>
                                        </fieldset>
                                    </Grid>
                                    <ImportantInfo formik={formik} ref={importantInfoTab} id={patientId} clinicalPracticeList={clinicalPracticeList}/>

                                    <Demographics
                                        formik={formik}
                                        ref={demographicsTab}
                                        handleAddItemEmergencyContact={
                                            handleAddItemEmergencyContact
                                        }
                                        setEmergengyLine={setEmergengyLine}
                                        handleClickDeleteIcon={handleClickDeleteIcon}
                                        anchorEl={anchorEl}
                                        setAnchorEl={setAnchorEl}
                                        setSelectedItemIndex={setSelectedItemIndex}
                                        handleDeleteServiceLine={handleDeleteServiceLine}
                                    />
                                </Grid>
                                <Grid style={{position:"sticky",bottom:"0px",width:"99%"}}>
                                <Footer
                                    loading={submitting}
                                    onCancel={() => {
                                    if (isPatientRecordPage) {
                                        history.push(
                                        Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(
                                            ':id',
                                            patientId,
                                            ),
                                                )
                                        } else {
                                                history.push(Routes.PATIENT.MAIN)
                                            }
                                    }}
                                    onOk={() => {
                                            formik.handleSubmit()
                                            setTimeout(() => validateForm(formik.errors), 500)
                                    }}
                                    />
                                </Grid>

                            </Grid>
                        )}
                        {value === 1 && (
                            <InsuranceForm
                                classes={classes}
                                formik={formikInsurance}
                                paymentMethodOptions={paymentMethodOptions}
                                insuranceType={insuranceType}
                                planOptions={planOptions}
                                isSelfPay={isSelfPay}
                                patientsRelationship={patientsRelationship}
                                resetRelationshipObj={resetRelationshipObj}
                                isSelfInsured={isSelfInsured}
                                stateList={stateList}
                                handleNotesBlur={handleNotesBlur}
                                openAlert={openAlert}
                                anchorAlert={anchorAlert}
                                setAnchorAlert={setAnchorAlert}
                                handleCloseForm={() => setValue(1)}
                                isSubmitting={isSubmitting}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}