import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    TextField,
    Box,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    RemoveCircleOutlineOutlined,
    AddCircleOutlineOutlined,
} from '@material-ui/icons'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import TextWithMacro from '../reusable/TextWithMacro'
import GenerateNarrative from '../common/GenerateNarative'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import NarrativeAndMacro from '../common/NarrativeAndMacro'

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        height: 22,
        fontSize: 16,
        letterSpacing: 0,
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    boxContainer: {
        borderRadius: 4,
    },
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 5,
        width: '97%',
    },
    fullWidth: {
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: 4,
        width: 28,
    },
    sectionHeading: {
        fontSize: 14,
    },
})

const defaultValue = {
    allergy: '',
    reaction: '',
}

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

const PatientAllergiesNonBehavioural = ({
    fieldName,
    onChange,
    macros,
    values,
}) => {
    const classes = useStyles()

    const handleAddMore = (action, index) => {
        const allergies = values?.allergies || []
        if (action === 'add') {
            onChange(`${fieldName}.allergies`, [
                ...allergies,
                defaultValue,
            ])
        } else {
            const updatedValue = allergies.filter(
                (v, i) => i !== index,
            )
            onChange(
                `${fieldName}.allergies`,
                updatedValue.length > 0
                    ? updatedValue
                    : [defaultValue],
            )
        }
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        const [alertDeleteOpen, setAlertDeleteOpen] =
            React.useState(false)
        const allergies = values?.allergies || []
        return (
            <Box sx={{ width: 30 }}>
                {allergies.length >= 1 &&
                    (allergies.length === 1 && isEmpty
                        ? false
                        : true) && (
                        <IconButton
                            variant="outlined"
                            onClick={() =>
                                setAlertDeleteOpen(true)
                            }
                        // className={classes.show_header_btn}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const handleTextChange = (keyName, text, index) => {
        const allergies = values?.allergies || []
        const updatedValue = allergies.map((v, i) =>
            index === i ? { ...v, [keyName]: text } : v,
        )
        onChange(`${fieldName}.allergies`, updatedValue)
    }

    const isEmptyAllergy = (allergy) => {
        return !(
            allergy.allergy.trim() ||
            allergy.reaction.trim()
        )
    }

    return (
        <Box className={classes.container}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                    margin: 5,
                }}>
                <Typography
                    className={classes.sectionHeading}>
                    {
                        questions[
                        'have_any_allergy_to_medication'
                        ]
                    }
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.have_any_allergy`}
                        options={radioOptions}
                        value={
                            values['have_any_allergy']
                                ? 'Yes'
                                : 'No'
                        }
                        onChange={(e) => {
                            const { name, value } = e.target

                            onChange(name, value === 'Yes')
                        }}
                    />
                </Box>
            </Box>
            <Box className={classes.boxContainer}>
                <div style={{ margin: '0 0 20px 5px' }}>
                    <Typography>
                        {questions['have_any_allergy_sub']}
                    </Typography>
                </div>

                <Grid
                    container
                    className={classes.gridContainer}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            className={classes.labelText}>
                            Allergy
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography
                            className={classes.labelText}>
                            Allergic Reaction
                        </Typography>
                    </Grid>
                </Grid>

                {values?.allergies?.map((al, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: '#fafafa',
                            borderRadius: 8,
                            margin: '16px 0',
                            padding: '8px 0',
                        }}>
                        <Grid
                            container
                            className={
                                classes.gridContainer
                            }
                            spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled={
                                        !values[
                                        'have_any_allergy'
                                        ]
                                    }
                                    name="allergy"
                                    value={al.allergy}
                                    className={
                                        classes.textFieldWrap
                                    }
                                    variant="outlined"
                                    onChange={(e) => {
                                        handleTextChange(
                                            e.target.name,
                                            e.target.value,
                                            index,
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid
                                    container
                                    alignItems="center">
                                    <Grid
                                        item
                                        className={
                                            classes.fullWidth
                                        }>
                                        <TextField
                                            disabled={
                                                !values[
                                                'have_any_allergy'
                                                ]
                                            }
                                            name="reaction"
                                            value={
                                                al.reaction
                                            }
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                            onChange={(
                                                e,
                                            ) => {
                                                handleTextChange(
                                                    e.target
                                                        .name,
                                                    e.target
                                                        .value,
                                                    index,
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values['have_any_allergy'] && (
                            <RenderAddOrRemove
                                index={index}
                                isEmpty={isEmptyAllergy(al)}
                            />
                        )}
                    </Box>
                ))}

                {values['have_any_allergy'] && (
                    <Grid item>
                        <Grid
                            container
                            className={
                                classes.cover__add__new
                            }
                            style={{
                                cursor: 'pointer',
                                width: 'fit-content'
                            }}
                            onClick={() =>
                                handleAddMore('add')
                            }>
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add new
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Box>
            {/* <GenerateNarrative
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: {
                        // healthProblemMaster,
                        // surgicalAreaMaster,
                    },
                }}
            />
            <TextWithMacro
                name={`${fieldName}.narrative`}
                onChange={onChange}
                macros={macros}
                rows={12}
                value={values.narrative}
            /> */}

            <NarrativeAndMacro
                hideTextNarrative={true}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    // master: options,
                }}
            />
        </Box>
    )
}

export default PatientAllergiesNonBehavioural
