import React, { useMemo } from 'react'
import moment from 'moment'

//mui-components
import { Grid, SvgIcon, Typography } from '@mui/material'

//svg-icons
import { ReactComponent as ProviderIcon } from '../../icons/provider.svg'
import { ReactComponent as PatientIcon } from '../../icons/patient.svg'
import { ReactComponent as AppointmentTypeIcon } from '../../icons/telehealth.svg'
import user_add from '../../icons/userAddSelf.png'

//utility functions and components
import { appointmentStatus } from '../appointmentStatus'
import { shortenString } from 'containers/CalendarScheduler/utils'
import StaffCard from '../StaffCard'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import ReportIcon from '@mui/icons-material/Report'
import { Button, CircularProgress } from '@material-ui/core'
import { sendResetConfirmationLink } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { setReschedulePopup } from 'store/actions/schedule'

const eventHeightApproximation = {
    '45-mins': '110px',
    'an-hour': '170px',
    'more-than-an-hour': '170px',
    '15-mins': '32px',
    '10-mins': '18px',
    '5-mins': '6px',
}

export default function DayCard({ timeDuration, parentProps, classes, isSchedulerForm }) {
    const notification = useNotification()
    const dispatch = useDispatch()
    const isRescedulePopup = useSelector(state => state?.schedule?.isRescedulePopup)
    const [tooltipOpen, setTooltipOpen] = React.useState(true)
    const [confirmationLoader, setConfirmationLoader] = React.useState({
        ifYes: false,
        ifNo: false
    })

    const statusObject = appointmentStatus(parentProps?.event?.status)
    const isStaff = parentProps?.event?.admin_code_id
    const coPay = parentProps?.event?.insurance
        ? parentProps?.event?.insurance?.insurance?.copay_amount
        : null
    const getProviderFullName = (firstName, lastName) => {
        return `${firstName} ${lastName}`
    }

    const handleSendNotification = (decision=null) => {
        const send_reschedule_notification = decision === 'yes' ? '2' : '1'
        setConfirmationLoader(state => {
            if(decision === 'yes'){
                return {
                    ...state,
                    ifYes: true
                }
            }
            return {
                ...state,
                ifNo: true
            }
        })
        sendResetConfirmationLink(parentProps?.event?.id, send_reschedule_notification)?.then(() => {
            if(decision === 'yes') notification('Confirmation sent succesfully.', 'success')
        })?.catch(() => {
            notification('Something went wrong', 'error')
        })?.finally(() => {
            dispatch(setReschedulePopup(parentProps?.event?.id, false))
            setTooltipOpen(false)
            setConfirmationLoader({
                ifYes: false,
                ifNo: false
            })
        })
    }

    const tooltipContent = () => (
        <div style={{ maxWidth: '350px' }} onClick={(e)=>{
                e.stopPropagation()
        }}>
            <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <ReportIcon
                        style={{ fontSize: '18px', marginRight: '10px', color: "#F8B636" }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                        <div dangerouslySetInnerHTML={{ __html: 'Send Change Notification' }}></div>
                    </Typography>
                </span>

            </span>

            <span style={{ paddingRight: '10px', display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Typography style={{
                    color: '#303E4E',
                    fontFamily: 'Rubik',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px',
                }}>
                    You have made a change to the patient's appointment. Do you want to send the patient a change notification?
                </Typography>
                <div style={{
                    display: 'flex',
                    gap: 5,
                    color: '#303E4E',
                    fontFamily: 'Rubik',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px'
                }}>
                    Date: <div style={{ color: '#303E4E', fontWeight: 500 }}>{moment(parentProps?.event?.start).format('MM/DD/YYYY')}</div>
                </div>
                <div style={{
                    display: 'flex',
                    gap: 5,
                    color: '#303E4E',
                    fontFamily: 'Rubik',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '20px'
                }}>
                    Time:
                    <div style={{ color: '#303E4E', fontWeight: 500 }}>
                        {moment(parentProps?.event?.start).format('h:mm A')}{' - '}{moment(parentProps?.event?.end).format('h:mm A')}
                    </div>
                </div>
            </span>

            <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                <Button
                    size="small"
                    className="cancel-btn"
                    onClick={() => handleSendNotification()}
                    disabled={confirmationLoader.ifNo || confirmationLoader.ifYes}
                    style={{ marginRight: '10px', color: '#444' }}>
                    No {confirmationLoader.ifNo && <CircularProgress style={{ width: 15, height: 15, marginLeft: 5 }} />}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        background: "#5571C6",
                        borderRadius: "5px !important",
                    }}
                    disabled={confirmationLoader.ifNo || confirmationLoader.ifYes}
                    onClick={() => {
                        handleSendNotification('yes')
                    }}
                >
                    Yes {confirmationLoader.ifYes && <CircularProgress style={{ width: 15, height: 15, marginLeft: 5 }} />}
                </Button>
            </span>
        </div>
    )

    const selfScheduleTooltip = (
        <HtmlTooltip
            title={'Patient self scheduled'}
            placement="top"
            className="notification-tooltip"
            // open={tooltipOpen}
            arrow>
            <img
                src={user_add}
                width={30}
                height={20}
                style={{
                    marginRight:40
                }}
            />
        </HtmlTooltip>
    )

    if (isStaff) {
        return <StaffCard parentProps={parentProps} classes={classes} />
    }
    if (
        timeDuration() === '45-mins' ||
        timeDuration() === 'an-hour' ||
        timeDuration() === 'more-than-an-hour'
    ) {
        return (
            <div style={{ width: '340px' }}>
                <Grid>
                    <div
                        className={classes.item_header_container}
                        style={{
                            borderBottom: `2px solid ${
                                parentProps.event.color_tag ||
                                (parentProps.event?.category?.toLowerCase() !== 'patient'
                                    ? '#d9d9d9'
                                    : '#F1F8FF')
                            }`,
                        }}>
                        <span>
                            <Typography
                                noWrap
                                className={`${classes.item_header_text} ${classes.font_weight_500}`}>
                                {moment(new Date(parentProps.event.start).getTime()).format(
                                    'hh:mm A',
                                )}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format(
                                    'hh:mm A',
                                )}
                            </Typography>
                        </span>
                        {parentProps.event.self_schedule && selfScheduleTooltip}
                        {statusObject?.nativeName === 'waitlist' &&
                        isRescedulePopup[parentProps?.event?.id] &&
                        !isSchedulerForm ? (
                            <HtmlTooltip
                                title={tooltipContent()}
                                placement="bottom-start"
                                className="notification-tooltip"
                                open={tooltipOpen}
                                arrow>
                                <span
                                    className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                    style={{
                                        backgroundColor: statusObject?.bgColor,
                                        marginLeft: '50px',
                                    }}>
                                    <span className={classes.default_flex_view}>
                                        {statusObject?.icon}
                                    </span>

                                    <span className={classes.font_weight_400}>
                                        {statusObject?.displayName}
                                    </span>
                                </span>
                            </HtmlTooltip>
                        ) : (
                            <span
                                className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                style={{
                                    backgroundColor: statusObject?.bgColor,
                                    marginLeft: '50px',
                                }}>
                                <span className={classes.default_flex_view}>
                                    {statusObject?.icon}
                                </span>

                                <span className={classes.font_weight_400}>
                                    {statusObject?.displayName}
                                </span>
                            </span>
                        )}
                    </div>

                    <Grid
                        item
                        xs={12}
                        className={classes.text_wrapper}
                        style={{ paddingTop: '5px' }}>
                        <div
                            className={`${classes.item_text} ${classes.default_flex_view}`}
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                gap: 2,
                            }}>
                            <span
                                className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                                <PatientIcon
                                    className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                />
                                <HtmlTooltip
                                    title={`Clinical Practice: ${parentProps?.event?.clinical_practice_name}`}
                                    arrow
                                    placement='right'
                                    className='schedule-page-clinical-practice-tooltip'
                                >
                                    <Typography className={classes.font_weight_500}>
                                        {coPay
                                            ? shortenString(parentProps.event.patientName, 10)
                                            : shortenString(parentProps.event.patientName, 35)}
                                        <span className={`${classes.co_pay}`}>{coPay}</span>
                                    </Typography>
                                </HtmlTooltip>
                            </span>

                            <span
                                className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                                <ProviderIcon
                                    className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                />

                                <span
                                    className={`${classes.item_text} ${classes.default_flex_view}`}>
                                    {/* <Typography style={{ marginRight: '5px' }}>{parentProps.event.practitioner_first_name || ''}</Typography> */}
                                    <Typography className={classes.font_weight_400}>
                                        {shortenString(
                                            getProviderFullName(
                                                parentProps.event.practitioner_first_name,
                                                parentProps.event.practitioner_last_name,
                                            ),
                                            35,
                                        )}
                                    </Typography>
                                </span>
                            </span>

                            <span
                                className={`${classes.default_flex_view} ${classes.flex_justify_start} ${classes.full_width}`}>
                                <AppointmentTypeIcon
                                    className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                />
                                <Typography className={classes.font_weight_400}>
                                    {parentProps.event.appointmentType}
                                </Typography>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    } else if (timeDuration() === '30-mins') {
        return (
            <div style={{ width: '340px' }}>
                <Grid>
                    <div
                        className={classes.item_header_container}
                        style={{
                            borderBottom: `2px solid ${
                                parentProps.event.color_tag ||
                                (parentProps.event?.category?.toLowerCase() !== 'patient'
                                    ? '#d9d9d9'
                                    : '#F1F8FF')
                            }`,
                        }}>
                        <span>
                            <Typography
                                noWrap
                                className={`${classes.item_header_text} ${classes.font_weight_500}`}>
                                {moment(new Date(parentProps.event.start).getTime()).format(
                                    'hh:mm A',
                                )}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format(
                                    'hh:mm A',
                                )}
                            </Typography>
                        </span>
                        {parentProps.event.self_schedule && selfScheduleTooltip}
                        {statusObject?.nativeName === 'waitlist' &&
                        isRescedulePopup[parentProps?.event?.id] &&
                        !isSchedulerForm ? (
                            <HtmlTooltip
                                placement="bottom-start"
                                title={tooltipContent()}
                                className="notification-tooltip"
                                open={tooltipOpen}
                                arrow>
                                <span
                                    className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                    style={{
                                        backgroundColor: statusObject?.bgColor,
                                    }}>
                                    <span className={classes.default_flex_view}>
                                        {statusObject?.icon}
                                    </span>

                                    <span className={classes.font_weight_400}>
                                        {statusObject?.displayName}
                                    </span>
                                </span>
                            </HtmlTooltip>
                        ) : (
                            <span
                                className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                style={{
                                    backgroundColor: statusObject?.bgColor,
                                }}>
                                <span className={classes.default_flex_view}>
                                    {statusObject?.icon}
                                </span>

                                <span className={classes.font_weight_400}>
                                    {statusObject?.displayName}
                                </span>
                            </span>
                        )}
                    </div>

                    <Grid
                        item
                        xs={12}
                        className={classes.text_wrapper}
                        style={{ paddingTop: '5px' }}>
                        <span
                            className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}
                            style={{ flexDirection: 'column' }}>
                            <span
                                className={`${classes.default_flex_view} ${classes.flex_justify_between} ${classes.full_width}`}>
                                <span className={`${classes.default_flex_view}`}>
                                    <PatientIcon
                                        className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                    />
                                    <HtmlTooltip
                                        title={`Clinical Practice: ${parentProps?.event?.clinical_practice_name}`}
                                        arrow
                                        placement='right'
                                        className='schedule-page-clinical-practice-tooltip'
                                    >
                                        <Typography className={classes.font_weight_500}>
                                            {coPay
                                                ? shortenString(parentProps.event.patientName, 10)
                                                : shortenString(parentProps.event.patientName, 15)}
                                            <span className={`${classes.co_pay}`}>{coPay}</span>
                                        </Typography>
                                    </HtmlTooltip>
                                </span>

                                <span
                                    className={`${classes.item_text} ${classes.default_flex_view}`}>
                                    <ProviderIcon
                                        className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                    />

                                    <span
                                        className={`${classes.item_text} ${classes.default_flex_view}`}>
                                        {/* <Typography style={{ marginRight: '5px' }}>{parentProps.event.practitioner_first_name || ''}</Typography> */}
                                        <Typography className={classes.font_weight_400}>
                                            {shortenString(
                                                getProviderFullName(
                                                    parentProps.event.practitioner_first_name,
                                                    parentProps.event.practitioner_last_name,
                                                ),
                                                25,
                                            )}
                                        </Typography>
                                    </span>
                                </span>
                            </span>

                            <span className={classes.full_width}>
                                <span
                                    className={`${classes.default_flex_view} ${classes.flex_justify_start}`}>
                                    <AppointmentTypeIcon
                                        className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                    />
                                    <Typography className={classes.font_weight_400}>
                                        {parentProps.event.appointmentType}
                                    </Typography>
                                </span>
                            </span>
                        </span>
                    </Grid>
                </Grid>
            </div>
        )
    } else if (timeDuration() === '20-mins') {
        return (
            <div style={{ width: '340px' }}>
                <Grid>
                    <div
                        className={classes.item_header_container}
                        style={{
                            borderBottom: `2px solid ${
                                parentProps.event.color_tag ||
                                (parentProps.event?.category?.toLowerCase() !== 'patient'
                                    ? '#d9d9d9'
                                    : '#F1F8FF')
                            }`,
                        }}>
                        <span>
                            <Typography
                                noWrap
                                className={`${classes.item_header_text} ${classes.font_weight_500}`}>
                                {moment(new Date(parentProps.event.start).getTime()).format(
                                    'hh:mm A',
                                )}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format(
                                    'hh:mm A',
                                )}
                            </Typography>
                        </span>
                        {parentProps.event.self_schedule && selfScheduleTooltip}
                        {statusObject?.nativeName === 'waitlist' &&
                        isRescedulePopup[parentProps?.event?.id] &&
                        !isSchedulerForm ? (
                            <HtmlTooltip
                                title={tooltipContent()}
                                placement="bottom-start"
                                arrow
                                open={tooltipOpen}
                                className="notification-tooltip">
                                <span
                                    className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                    style={{
                                        backgroundColor: statusObject?.bgColor,
                                    }}>
                                    <span className={classes.default_flex_view}>
                                        {statusObject?.icon}
                                    </span>

                                    <span className={classes.font_weight_400}>
                                        {statusObject?.displayName}
                                    </span>
                                </span>
                            </HtmlTooltip>
                        ) : (
                            <span
                                className={`${classes.status_button} ${classes.item_header_text} ${classes.default_flex_view}`}
                                style={{
                                    backgroundColor: statusObject?.bgColor,
                                }}>
                                <span className={classes.default_flex_view}>
                                    {statusObject?.icon}
                                </span>

                                <span className={classes.font_weight_400}>
                                    {statusObject?.displayName}
                                </span>
                            </span>
                        )}
                    </div>

                    <div
                        item
                        xs={12}
                        className={classes.text_wrapper}
                        style={{ paddingTop: '5px', display: 'flex', alignItems: 'center' }}>
                        <span
                            className={`${classes.default_flex_view} ${classes.flex_justify_between} ${classes.full_width}`}>
                            <span className={`${classes.default_flex_view}`}>
                                <PatientIcon
                                    className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                />
                                <HtmlTooltip
                                    title={`Clinical Practice: ${parentProps?.event?.clinical_practice_name}`}
                                    arrow
                                    placement='right'
                                    className='schedule-page-clinical-practice-tooltip'
                                >
                                    <Typography className={classes.font_weight_500}>
                                        {coPay
                                            ? shortenString(parentProps.event.patientName, 10)
                                            : shortenString(parentProps.event.patientName, 15)}
                                        <span className={`${classes.co_pay}`}>{coPay}</span>
                                    </Typography>
                                </HtmlTooltip>
                            </span>

                            <span className={`${classes.item_text} ${classes.default_flex_view}`}>
                                <ProviderIcon
                                    className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                                />

                                <span
                                    className={`${classes.item_text} ${classes.default_flex_view}`}>
                                    {/* <Typography style={{ marginRight: '5px' }}>{parentProps.event.practitioner_first_name || ''}</Typography> */}
                                    <Typography className={classes.font_weight_400}>
                                        {shortenString(
                                            getProviderFullName(
                                                parentProps.event.practitioner_first_name,
                                                parentProps.event.practitioner_last_name,
                                            ),
                                            25,
                                        )}
                                    </Typography>
                                </span>
                            </span>
                        </span>
                    </div>
                </Grid>
            </div>
        )
    } else if (
        timeDuration() === '5-mins' ||
        timeDuration() === '10-mins' ||
        timeDuration() === '15-mins'
    ) {
        return (
            <div
                className={`${classes.default_flex_view} ${classes.flex_justify_between}`}
                style={{ width: '340px' }}>
                {/* <Grid> */}

                <span style={{ marginLeft: '10px' }}>
                    <Typography
                        noWrap
                        className={`${classes.item_header_text} ${classes.font_weight_500}`}>
                        {moment(new Date(parentProps.event.start).getTime()).format('hh:mm A')}
                        {` - `}
                        {moment(new Date(parentProps.event.end).getTime()).format('hh:mm A')}
                    </Typography>
                </span>
                {parentProps.event.self_schedule && selfScheduleTooltip}
                <span className={`${classes.default_flex_view}`}>
                    <PatientIcon
                        className={`${classes.card_icon} ${classes.card_icon_small_font}`}
                    />
                    <HtmlTooltip
                        title={`Clinical Practice: ${parentProps?.event?.clinical_practice_name}`}
                        arrow
                        placement='right'
                        className='schedule-page-clinical-practice-tooltip'
                    >
                        <Typography
                            className={`${classes.font_weight_500} ${classes.item_header_text}`}>
                            {coPay
                                ? shortenString(parentProps.event.patientName, 10)
                                : shortenString(parentProps.event.patientName, 22)}
                            <span className={`${classes.co_pay}`}>{coPay}</span>
                        </Typography>
                    </HtmlTooltip>
                </span>

                {statusObject?.nativeName === 'waitlist' &&
                isRescedulePopup[parentProps?.event?.id] &&
                !isSchedulerForm ? (
                    <HtmlTooltip
                        title={tooltipContent()}
                        placement="bottom-start"
                        open={tooltipOpen}
                        className="notification-tooltip"
                        arrow>
                        <span
                            style={{
                                marginRight: '10px',
                                height: '15px',
                                width:
                                    statusObject?.nativeName === 'entered-in-error'
                                        ? '23px'
                                        : '15px',
                                borderRadius: '50%',
                                background:
                                    statusObject?.nativeName === 'entered-in-error'
                                        ? 'transparent'
                                        : statusObject?.bgColor,
                                paddingLeft: '2px',
                            }}
                            className={classes.default_flex_view}>
                            {statusObject?.icon}
                        </span>
                    </HtmlTooltip>
                ) : (
                    <span
                        style={{
                            marginRight: '10px',
                            height: '15px',
                            width:
                                statusObject?.nativeName === 'entered-in-error' ? '23px' : '15px',
                            borderRadius: '50%',
                            background:
                                statusObject?.nativeName === 'entered-in-error'
                                    ? 'transparent'
                                    : statusObject?.bgColor,
                            paddingLeft: '2px',
                        }}
                        className={classes.default_flex_view}>
                        {statusObject?.icon}
                    </span>
                )}

                {/* <span>
                            <
                        </span> */}
                {/* </Grid> */}
            </div>
        )
    }
    return <></>
}
