import React, { useEffect, useState, useRef } from 'react'
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core'
import EmptyListIcon from '../../../components/Consultation/icons/EmptyListIcon'
import { usePageVisibility } from 'shared/hooks/usePageVisibility'
import { sync30seconds } from 'utilities/sync30seconds'

const useStyles = makeStyles({
    emptyListWrapper: {
        width: '100%',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
    },
    wrapText: {
        marginTop: '20px',
        textAlign: 'center',
    },
    title: {
        marginTop: '10px',
        fontSize: '22px',
        color: '#303E4E',
    },
    text: {
        marginTop: '10px',
        fontSize: '14px',
    },
})

const EmptyList = (props) => {
    const classes = useStyles()

    const isPageVisible = usePageVisibility();
    const timerIdRef = useRef([]);
    const [isPollingEnabled, setIsPollingEnabled] = useState(true);

    const debouncedRefetchEncountersRef = useRef(_.debounce(props.refetchEncounters, 2000));
    const intervalRef = useRef(null);
    const [firstTime, setFirstTime] = useState(true)
    const [isActiveTab, setIsActiveTab] = useState(true);
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            setIsActiveTab(true);
        } else {
            setIsActiveTab(false);
        }
    };

    useEffect(()=>{
        setFirstTime(false)
    },[])

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // We use the firstTime state to avoid triggering certain actions during the initial render of the page.
        if(isActiveTab && !firstTime){
            props.refetchEncounters()
        }
        if (isActiveTab && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                debouncedRefetchEncountersRef.current();
            }, 30000); // 30 seconds
        }
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [isActiveTab]);

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            className={classes.emptyListWrapper}>
            <div>
                <EmptyListIcon />
            </div>
            <div className={classes.wrapText}>
                <Typography className={classes.title}>Empty List</Typography>
            </div>
        </Grid>
    )
}

export default EmptyList
