import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import LinearProgress from '@mui/material/LinearProgress'
import { makeStyles, Typography, Button, Grid } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { signBatchClinicalNotes } from 'containers/ClinicalNotes/api/services'
import { useNotification } from 'shared/elements/Notification'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    header: {
        textAlign: 'center',
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_cancel: {
        background: '#303E4E',
        width: '230px',
        fontSize: 15,
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
}))

const normalise = (value, max, min = 0) => ((value - min) * 100) / (max - min)

export default function BatchSignDialog({ open, handleClose, clinicalNotes, refreshAfterSigning }) {
    const classes = useStyles()
    const notification = useNotification()
    const [signCompleted, setSignCompleted] = React.useState(0)

    React.useEffect(() => {
        handleSignBatch()
    }, [])

    const handleSignBatch = async () => {
        for await (const note of clinicalNotes) {
            try {
                await signBatchClinicalNotes(note.id)
                setSignCompleted((oldProgress) => {
                    return oldProgress + 1
                })
            } catch (error) {
                notification(
                    error?.data?.message ||
                        error?.message ||
                        `An error occurred when signing for note ${note.id}`,
                    'error',
                )
            }
        }
        notification('Signed batch of notes successfully!', 'success')
        refreshAfterSigning()
        handleClose(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>Batch signing in progress</Typography>

                <LinearProgress
                    variant="determinate"
                    value={normalise(signCompleted, clinicalNotes?.length || 0, 0)}
                    style={{ width: 400, margin: 16 }}
                />
                <Typography className={classes.header}>
                    Now signing note {signCompleted} of {clinicalNotes?.length || 0}...
                </Typography>
            </DialogTitle>
        </Dialog>
    )
}
