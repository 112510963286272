import moment from 'moment'

const INCHES_TO_CM = 2.54
const CM_TO_METERS = 0.01
const FEET_TO_INCHES = 12

const KG_TO_LBS = 2.20462
const LBS_TO_KG = 0.453592
const BMI_COFF = 703

const temparatureUnits = {
    CELSIUS: 'celsius',
    FAHRENHEIT: 'fahrenheit',
}

export const mapMultipleOptions = (options = []) => {
    return options.map((o) => ({
        label: o.name,
        value: o._id,
    }))
}

export const calculateBMI = (height, weight) => {
    if (height === 0) {
        return 0
    }
    return ((weight / (height * height)) * BMI_COFF).toFixed(1)
}

export const convertHeightToMeter = (ft = 0, inch = 0) => {
    return (Number(ft) * FEET_TO_INCHES + Number(inch)) * INCHES_TO_CM * CM_TO_METERS
}

export const convertHeightToInches = (ft = 0, inch = 0) => {
    return Number(ft) * FEET_TO_INCHES + Number(inch)
}

export const convertHeightToFtAndInches = (inches = 0) => {
    const ft = Math.floor(inches / 12)
    const remainingInches = inches % 12

    return { ft, inches: remainingInches || '' }
}

export const convertWeight = (weight = 0, convertTo = 'lb') => {
    const units = {
        lb: KG_TO_LBS,
        kg: LBS_TO_KG,
        lbs: KG_TO_LBS, //temporary addition
    }
    return (Number(weight) * units[convertTo]).toFixed(0)
}

export const convertTemprature = (value, unit = temparatureUnits.CELSIUS) => {
    if (unit === temparatureUnits.CELSIUS) {
        return (((value - 32) * 5) / 9).toFixed(0)
    }
    if (unit === temparatureUnits.FAHRENHEIT) {
        return ((value * 9) / 5 + 32).toFixed(0)
    }
}

export const sortValues = (arr, master) => {
    return arr.sort((first, second) => {
        const firstId = first.id || first
        const secondId = second.id || second
        const firstIndex = master.findIndex((m) => m._id === firstId)
        const secondIndex = master.findIndex((m) => m._id === secondId)
        return firstIndex - secondIndex
    })
}

export const validateDate = (date) => {
    return moment(date)?.isValid() ? date : null
}

export const noteCategories = [
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Cpt code',
        componentId: 'cpt_code',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Treatment approach',
        componentId: 'treatment_approach',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Present illness history',
        componentId: 'present_illness_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Session focus',
        componentId: 'session_focus',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Interven utilized',
        componentId: 'interven_utilized',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Family env strategy',
        componentId: 'family_env_strategy',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Suicide risk factors',
        componentId: 'suicide_risk_factors',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Suicide protective factors',
        componentId: 'suicide_protective_factors',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Suicide preventive plan',
        componentId: 'suicide_preventive_plan',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Clinical global impression',
        componentId: 'clinical_global_impression',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Homework',
        componentId: 'homework',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Present in sessions',
        componentId: 'present_in_sessions',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Chief complaint',
        componentId: 'chief_complaint',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Stressors',
        componentId: 'stressors',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'System reviews',
        componentId: 'system_reviews',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Subtance abuse history',
        componentId: 'subtance_abuse_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Subtance abuse treat history',
        componentId: 'subtance_abuse_treat_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Subtance use consequences',
        componentId: 'subtance_use_consequences',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Inpatient psychiatric history',
        componentId: 'inpatient_psychiatric_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Outpatient psychiatric history',
        componentId: 'outpatient_psychiatric_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Suicide history',
        componentId: 'suicide_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Violence history',
        componentId: 'violence_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Past medical history',
        componentId: 'past_medical_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Psychiatric medication history',
        componentId: 'psychiatric_medication_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Allergy',
        componentId: 'allergy',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Medication',
        componentId: 'medication',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Family history',
        componentId: 'family_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Dev and edu history',
        componentId: 'dev_and_edu_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'General social history',
        componentId: 'general_social_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Menstruation and pregnancy history',
        componentId: 'menstruation_and_pregnancy_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Vitals',
        componentId: 'vitals',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Exam',
        componentId: 'exam',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Review of measures',
        componentId: 'review_of_measures',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Lab results',
        componentId: 'lab_results',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Source of information',
        componentId: 'source_of_information',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Assessment sources of risk',
        componentId: 'assessment_sources_of_risk',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Icd code',
        componentId: 'icd_code',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Assessment impression',
        componentId: 'assessment_impression',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Assessment informed consent',
        componentId: 'assessment_informed_consent',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Counseling and coordination care',
        componentId: 'counselling_and_coordination_care',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Psychotherapy',
        componentId: 'psychotherapy',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Plan',
        componentId: 'plan',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Plan med changes',
        componentId: 'plan_med_changes',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Plan lab ordered',
        componentId: 'plan_lab_ordered',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Plan next appointment',
        componentId: 'plan_next_appointment',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Adherence',
        componentId: 'adherence',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Side effects',
        componentId: 'side_effects',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Past history',
        componentId: 'past_history',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Telemedication attestation',
        componentId: 'telemedication_attestation',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Physical examinations',
        componentId: 'physical_examinations',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Allergies',
        componentId: 'allergies',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Laboratory data',
        componentId: 'laboratory_data',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Radiology',
        componentId: 'radiology',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Assessment',
        componentId: 'assessment',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Recommendation for patient',
        componentId: 'recommendation_for_patient',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Recommendation for referring provider',
        componentId: 'recommendation_for_referring_provider',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Provider defined title',
        componentId: 'provider_defined_title',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Provider defined details',
        componentId: 'provider_defined_details',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Time spent statement',
        componentId: 'time_spent_statement',
    },
    {
        require: false,
        status: 'incomplete',
        isShow: false,
        header: 'Patient resources',
        componentId: 'patient_resources',
    },
]

export const clinical_note_master = {
    roleMasters: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61d89a71ae09aaff99dd9a42',
            name: 'Patient',
            seq: 1,
        },
        {
            _id: '61d89a71ae09aaff99dd9a43',
            name: 'Mother',
            seq: 2,
        },
        {
            _id: '61d89a71ae09aaff99dd9a44',
            name: 'Father',
            seq: 3,
        },
        {
            _id: '61d89a71ae09aaff99dd9a45',
            name: 'Teacher',
            seq: 4,
        },
        {
            _id: '61d89a71ae09aaff99dd9a46',
            name: 'Stepmother',
            seq: 5,
        },
        {
            _id: '61d89a71ae09aaff99dd9a47',
            name: 'Stepfather',
            seq: 6,
        },
        {
            _id: '61d89a71ae09aaff99dd9a48',
            name: 'Friend',
            seq: 7,
        },
        {
            _id: '61d89a71ae09aaff99dd9a49',
            name: 'Other Family',
            seq: 8,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4a',
            name: 'Other Caregiver',
            seq: 9,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4b',
            name: 'Other Provider',
            seq: 10,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4g',
            name: 'Other Sourcer',
            seq: 11,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4h',
            name: 'Other Records',
            seq: 12,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4c',
            name: 'Translator',
            seq: 13,
        },
        {
            _id: '61d89a71ae09aaff99dd9a4d',
            name: 'Spouse/Partner',
            seq: 14,
        },
        {
            _id: '61d89a72ae09aaff99dd9a4e',
            name: 'Daughter',
            seq: 15,
        },
        {
            _id: '61d89a72ae09aaff99dd9a4f',
            name: 'Son',
            seq: 16,
        },
    ],
    therapeuticEffect: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61d89a71ae09aaff99dd9a43',
            name: 'Feeling Worse or Negative Result',
            label: 'feeling worse or negative result',
            seq: 1,
        },
        {
            _id: '61d89a71ae09aaff99dd9a44',
            name: 'Minor Improvement or No Effect (0 - 24%)',
            label: 'a minor Improvement or No Effect',
            seq: 2,
        },
        {
            _id: '61d89a71ae09aaff99dd9a45',
            name: 'Partial Response (25% - 49%)',
            label: 'a partial Response',
            seq: 3,
        },
        {
            _id: '61d89a71ae09aaff99dd9a46',
            name: 'Significant Improvement (50% - 74%)',
            label: 'a Significant Improvement',
            seq: 4,
        },
        {
            _id: '61d89a71ae09aaff99dd9a47',
            name: 'Resolved or Nearly Resolved Problem ( 75% - 100%)',
            label: 'that it resolved or nearly resolved their problem',
            seq: 5,
        },
    ],
    therapeuticEffectOutPatient: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61d89a71ae09aaff99dd9a43',
            name: 'Feeling Worse or Negative Result',
            label: 'feeling worse or negative result',
            seq: 1,
        },
        {
            _id: '61d89a71ae09aaff99dd9a44',
            name: 'Minor Improvement or No Effect (0 - 24%)',
            label: 'a minor Improvement or No Effect',
            seq: 2,
        },
        {
            _id: '61d89a71ae09aaff99dd9a45',
            name: 'Partial Response (25% - 49%)',
            label: 'a partial Response',
            seq: 3,
        },
        {
            _id: '61d89a71ae09aaff99dd9a46',
            name: 'Significant Improvement (50% - 74%)',
            label: 'a Significant Improvement',
            seq: 4,
        },
        {
            _id: '61d89a71ae09aaff99dd9a47',
            name: 'Resolved or Nearly Resolved Problem ( 75% - 100%)',
            label: 'that it resolved or nearly resolved their problem',
            seq: 5,
        },
    ],
    participationMasters: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61d89aebae09aaff99dd9a50',
            name: 'Physically Present',
            seq: 1,
        },
        {
            _id: '61d89aebae09aaff99dd9a51',
            name: 'Tele-Conference',
            seq: 2,
        },
        {
            _id: '61d89aebae09aaff99dd9a52',
            name: 'Video-Conference',
            seq: 3,
        },
    ],
    systemReviewMasters: [
        {
            _id: '61db24056566413440d9ae75',
            header_name: 'Constitutional',
            is_horizontal: false,
            seq: 1,
            reviews_options: [
                {
                    name: 'Chronic pain',
                    _id: '61db24486566413440d9ae83',
                    seq: 1,
                },
                {
                    name: 'Loss of appetite',
                    _id: '61db24486566413440d9ae84',
                    seq: 2,
                },
                {
                    name: 'Increase in appetite',
                    _id: '61db24486566413440d9ae85',
                    seq: 3,
                },
                {
                    name: 'Unexplained weight loss',
                    _id: '61db24486566413440d9ae86',
                    seq: 4,
                },
                {
                    name: 'Weight gain',
                    _id: '61db24486566413440d9ae87',
                    seq: 5,
                },
                {
                    name: 'Fatigue/Lethargy',
                    _id: '61db24486566413440d9ae88',
                    seq: 6,
                },
                {
                    name: 'Unexplained fever',
                    _id: '61db24486566413440d9ae89',
                    seq: 7,
                },
                {
                    name: 'Hot or Cold spells',
                    _id: '61db24486566413440d9ae8a',
                    seq: 8,
                },
                {
                    name: 'Night sweats',
                    _id: '61db24486566413440d9ae8b',
                    seq: 9,
                },
                {
                    name: 'Sleeping pattern disruption',
                    _id: '61db24486566413440d9ae8c',
                    seq: 10,
                },
                {
                    name: 'Malaise (Flu-like or Vague sick feeling)',
                    _id: '61db24486566413440d9ae8d',
                    seq: 11,
                },
                {
                    name: 'None of the above constitutional issues',
                    _id: '61db24486566413440d9ae8e',
                    seq: 12,
                    is_none: true,
                },
            ],
        },
        {
            _id: '61db24876566413440d9ae90',
            header_name: 'Eyes',
            header_label: 'Eye',
            is_horizontal: false,
            seq: 2,
            reviews_options: [
                {
                    name: 'Eye pain',
                    _id: '61db24cc6566413440d9ae91',
                    seq: 1,
                },
                {
                    name: 'Eye discharge',
                    _id: '61db24cc6566413440d9ae92',
                    seq: 2,
                },
                {
                    name: 'Eye redness',
                    _id: '61db24cc6566413440d9ae93',
                    seq: 3,
                },
                {
                    name: 'Blurred or double vision',
                    _id: '61db24cc6566413440d9ae94',
                    seq: 4,
                },
                {
                    name: 'Visual change',
                    _id: '61db24cc6566413440d9ae95',
                    seq: 5,
                },
                {
                    name: 'History of eye surgery',
                    _id: '61db24cc6566413440d9ae96',
                    seq: 6,
                },
                {
                    name: 'Sensitivity to light',
                    _id: '61db24cc6566413440d9ae97',
                    seq: 7,
                },
                {
                    name: 'Scotomas (Blind spots)',
                    _id: '61db24cc6566413440d9ae98',
                    seq: 8,
                },
                {
                    name: 'Retinal hemorrhage (Floaters in vision)',
                    _id: '61db24cc6566413440d9ae99',
                    seq: 9,
                },
                {
                    name: ' Amaurosis fugax (Feeling like a curtain is pulled over vision)',
                    _id: '61db24cc6566413440d9ae100',
                    seq: 10,
                },
                {
                    name: 'None of the above eye issues',
                    _id: '61db24cd6566413440d9ae9a',
                    seq: 11,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ad4ae9797ddc2f84fd',
            header_name: 'Ears, Nose, Mouth, and Throat',
            header_label: 'Ear/Nose/Mouth/Throat',
            is_horizontal: false,
            seq: 3,
            reviews_options: [
                {
                    name: 'Earache',
                    _id: '6204174e4ae9797ddc2f850b',
                    seq: 1,
                },
                {
                    name: 'Tinnitus (Ringing in ears)',
                    _id: '6204174e4ae9797ddc2f850c',
                    seq: 2,
                },
                {
                    name: 'Decreased hearing or hearing loss',
                    _id: '6204174e4ae9797ddc2f850d',
                    seq: 3,
                },
                {
                    name: 'Frequent ear infections',
                    _id: '6204174e4ae9797ddc2f850e',
                    seq: 4,
                },
                {
                    name: 'Frequent nose bleeds',
                    _id: '6204174e4ae9797ddc2f850f',
                    seq: 5,
                },
                {
                    name: 'Sinus congestion',
                    _id: '6204174e4ae9797ddc2f8510',
                    seq: 6,
                },
                {
                    name: 'Runny nose/Post-nasal drip',
                    _id: '6204174e4ae9797ddc2f8511',
                    seq: 7,
                },
                {
                    name: 'Difficulty swallowing',
                    _id: '6204174e4ae9797ddc2f8512',
                    seq: 8,
                },
                {
                    name: 'Frequent sore throat',
                    _id: '6204174e4ae9797ddc2f8513',
                    seq: 9,
                },
                {
                    name: 'Prolonged hoarseness',
                    _id: '6204174f4ae9797ddc2f8514',
                    seq: 10,
                },
                {
                    name: 'Pain in jaw or tooth',
                    _id: '6204174f4ae9797ddc2f8515',
                    seq: 11,
                },
                {
                    name: 'Dry mouth',
                    _id: '6204174f4ae9797ddc2f8516',
                    seq: 12,
                },
                {
                    name: 'None of the above ear, nose, mouth or throat issues',
                    _id: '6204174f4ae9797ddc2f8517',
                    seq: 13,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ad4ae9797ddc2f84fe',
            header_name: 'Cardiovascular',
            is_horizontal: false,
            seq: 4,
            reviews_options: [
                {
                    name: 'Chest pain',
                    _id: '620417834ae9797ddc2f8518',
                    seq: 1,
                },
                {
                    name: 'Pacemaker',
                    _id: '620417834ae9797ddc2f8519',
                    seq: 2,
                },
                {
                    name: 'Palpitations (fast or irregular heartbeat)',
                    _id: '620417834ae9797ddc2f851a',
                    seq: 3,
                },
                {
                    name: 'Swollen feet or hands',
                    _id: '620417834ae9797ddc2f851b',
                    seq: 4,
                },
                {
                    name: 'Fainting spells',
                    _id: '620417834ae9797ddc2f851c',
                    seq: 5,
                },
                {
                    name: 'Shortness of breath with exercise',
                    _id: '620417834ae9797ddc2f851d',
                    seq: 6,
                },
                {
                    name: 'None of the above cardiovascular issues',
                    _id: '620417834ae9797ddc2f851e',
                    seq: 7,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f84ff',
            header_name: 'Respiratory',
            is_horizontal: false,
            seq: 5,
            reviews_options: [
                {
                    name: 'Pain with breathing',
                    _id: '620417d54ae9797ddc2f851f',
                    seq: 1,
                },
                {
                    name: 'Chronic cough',
                    _id: '620417d54ae9797ddc2f8520',
                    seq: 2,
                },
                {
                    name: 'Chronic shortness of breath',
                    _id: '620417d64ae9797ddc2f8521',
                    seq: 3,
                },
                {
                    name: 'Chronic wheezing/Asthma',
                    _id: '620417d64ae9797ddc2f8522',
                    seq: 4,
                },
                {
                    name: 'Excessive phlegm',
                    _id: '620417d64ae9797ddc2f8523',
                    seq: 5,
                },
                {
                    name: 'Coughing blood',
                    _id: '620417d64ae9797ddc2f8524',
                    seq: 6,
                },
                {
                    name: 'Nocturnal Dyspnea (Shortness of breath at night)',
                    _id: '620417d64ae9797ddc2f8525',
                    seq: 7,
                },
                {
                    name: 'None of the above respiratory issues',
                    _id: '620417d64ae9797ddc2f8526',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8500',
            header_name: 'Musculoskeletal',
            is_horizontal: false,
            seq: 6,
            reviews_options: [
                {
                    name: 'Swelling in joints',
                    _id: '620418094ae9797ddc2f8527',
                    seq: 1,
                },
                {
                    name: 'Redness of joints',
                    _id: '6204180a4ae9797ddc2f8528',
                    seq: 2,
                },
                {
                    name: 'Other joint pains or stiffness',
                    _id: '6204180a4ae9797ddc2f8529',
                    seq: 3,
                },
                {
                    name: 'Muscle pain or cramping',
                    _id: '6204180a4ae9797ddc2f852a',
                    seq: 4,
                },
                {
                    name: 'Muscle weakness',
                    _id: '6204180a4ae9797ddc2f852b',
                    seq: 5,
                },
                {
                    name: 'Muscle stiffness',
                    _id: '6204180a4ae9797ddc2f852c',
                    seq: 6,
                },
                {
                    name: 'Decreased range of motion',
                    _id: '6204180a4ae9797ddc2f852d',
                    seq: 7,
                },
                {
                    name: 'Back pain or stiffness',
                    _id: '6204180a4ae9797ddc2f852e',
                    seq: 8,
                },
                {
                    name: 'History of fractures',
                    _id: '6204180a4ae9797ddc2f852f',
                    seq: 9,
                },
                {
                    name: 'Past injury to spine or joints',
                    _id: '6204180a4ae9797ddc2f8530',
                    seq: 10,
                },
                {
                    name: 'None of the above musculoskeletal issues',
                    _id: '6204180a4ae9797ddc2f8531',
                    seq: 11,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8501',
            header_name: 'Gastrointestinal',
            is_horizontal: true,
            seq: 7,
            reviews_options: [
                {
                    name: 'Excessive flatulence or belching',
                    _id: '620418324ae9797ddc2f8532',
                    seq: 1,
                },
                {
                    name: 'Diarrhea',
                    _id: '620418324ae9797ddc2f8533',
                    seq: 2,
                },
                {
                    name: 'Constipation',
                    _id: '620418324ae9797ddc2f8534',
                    seq: 3,
                },
                {
                    name: 'Persistent nausea/vomiting',
                    _id: '620418324ae9797ddc2f8535',
                    seq: 4,
                },
                {
                    name: 'Abdominal Pain',
                    _id: '620418324ae9797ddc2f8536',
                    seq: 5,
                },
                {
                    name: 'Heartburn',
                    _id: '620418324ae9797ddc2f8537',
                    seq: 6,
                },
                {
                    name: 'Difficulty swallowing solids or liquids',
                    _id: '620418324ae9797ddc2f8538',
                    seq: 7,
                },
                {
                    name: 'Recent loss in appetite',
                    _id: '620418324ae9797ddc2f8539',
                    seq: 8,
                },
                {
                    name: 'Sensitivity to milk products',
                    _id: '620418334ae9797ddc2f853a',
                    seq: 9,
                },
                {
                    name: 'Jaundice (yellow skin)',
                    _id: '620418334ae9797ddc2f853b',
                    seq: 10,
                },
                {
                    name: 'Change in appearance of stool',
                    _id: '620418334ae9797ddc2f853c',
                    seq: 11,
                },
                {
                    name: 'Blood in stool',
                    _id: '620418334ae9797ddc2f853d',
                    seq: 12,
                },
                {
                    name: 'Dark/Tarry stool',
                    _id: '620418334ae9797ddc2f853e',
                    seq: 13,
                },
                {
                    name: 'Loss of bowel control/soiling',
                    _id: '620418334ae9797ddc2f853f',
                    seq: 14,
                },
                {
                    name: 'None of the above gastrointestinal issues',
                    _id: '620418334ae9797ddc2f8540',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8502',
            header_name: 'Allergic/Immunologic',
            is_horizontal: false,
            seq: 8,
            reviews_options: [
                {
                    name: 'Frequent infections',
                    _id: '620418554ae9797ddc2f8541',
                    seq: 1,
                },
                {
                    name: 'Hives',
                    _id: '620418554ae9797ddc2f8542',
                    seq: 2,
                },
                {
                    name: 'Anaphylaxic reaction',
                    _id: '620418564ae9797ddc2f8543',
                    seq: 3,
                },
                {
                    name: 'None of the above allergic or immunologic issues',
                    _id: '620418564ae9797ddc2f8544',
                    seq: 4,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8503',
            header_name: 'Endocrine',
            is_horizontal: false,
            seq: 9,
            reviews_options: [
                {
                    name: 'Severe menopausal symptoms',
                    label: 'Severe menopausal',
                    _id: '620418784ae9797ddc2f8545',
                    seq: 1,
                },
                {
                    name: 'Cold or heat intolerance',
                    _id: '620418784ae9797ddc2f8546',
                    seq: 2,
                },
                {
                    name: 'Excessive appetite',
                    _id: '620418784ae9797ddc2f8547',
                    seq: 3,
                },
                {
                    name: 'Excessive thirst or urination',
                    _id: '620418784ae9797ddc2f8548',
                    seq: 4,
                },
                {
                    name: 'Excessive sweating',
                    _id: '620418784ae9797ddc2f8549',
                    seq: 5,
                },
                {
                    name: 'None of the above endocrine issues',
                    _id: '620418794ae9797ddc2f854a',
                    seq: 6,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8504',
            header_name: 'Hematologic/Lymphatic',
            is_horizontal: false,
            seq: 10,
            reviews_options: [
                {
                    name: 'Blood Clots',
                    _id: '620418b44ae9797ddc2f854b',
                    seq: 1,
                },
                {
                    name: 'Excess/easy bleeding (surgery, dental work, brushing teeth, scrapes)',
                    label: 'easy bleeding after surgery or dental work',
                    _id: '620418b44ae9797ddc2f854c',
                    seq: 2,
                },
                {
                    name: 'History of blood transfusion',
                    _id: '620418b44ae9797ddc2f854d',
                    seq: 3,
                },
                {
                    name: 'Excessive bruising',
                    label: 'Excessive bruising or bleeding',
                    _id: '620418b44ae9797ddc2f854e',
                    seq: 4,
                },
                {
                    name: 'Swollen glands (neck, armpits, groin)',
                    label: 'Swollen glands (neck, armpits, groin)',
                    _id: '620418b44ae9797ddc2f854f',
                    seq: 5,
                },
                {
                    name: 'None of the above hematologic or lymphatic issues',
                    _id: '620418b54ae9797ddc2f8550',
                    seq: 6,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8505',
            header_name: 'Genitourinary (General)',
            header_label: 'general Genitourinary',
            is_horizontal: false,
            seq: 11,
            reviews_options: [
                {
                    name: 'Loss of urine control (including bed-wetting)',
                    label: 'Loss of urine control/bed-wetting',
                    _id: '620418d64ae9797ddc2f8551',
                    seq: 1,
                },
                {
                    name: 'Painful/Burning urination',
                    _id: '620418d64ae9797ddc2f8552',
                    seq: 2,
                },
                {
                    name: 'Blood in urine',
                    _id: '620418d74ae9797ddc2f8553',
                    seq: 3,
                },
                {
                    name: 'Increased frequency of urination',
                    _id: '620418d74ae9797ddc2f8554',
                    seq: 4,
                },
                {
                    name: 'Up more than twice/night to urinate',
                    label: 'Up more than twice a night to urinate',
                    _id: '620418d74ae9797ddc2f8555',
                    seq: 5,
                },
                {
                    name: 'Urine retention',
                    _id: '620418d74ae9797ddc2f8556',
                    seq: 6,
                },
                {
                    name: 'Frequent urine infections',
                    _id: '620418d74ae9797ddc2f8557',
                    seq: 7,
                },
                {
                    name: 'None of the above general genitourinary issues',
                    _id: '620418d74ae9797ddc2f8558',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8506',
            header_name: 'Genitourinary (Women)',
            header_label: 'sex-specific Genitourinary',
            is_horizontal: false,
            seq: 12,
            reviews_options: [
                {
                    name: 'Unusual vaginal discharge',
                    _id: '620419004ae9797ddc2f8559',
                    seq: 1,
                },
                {
                    name: 'Vaginal pain, bleeding, soreness, or dryness',
                    _id: '620419004ae9797ddc2f855a',
                    seq: 2,
                },
                {
                    name: 'Genital sores',
                    _id: '620419004ae9797ddc2f855b',
                    seq: 3,
                },
                {
                    name: 'Heavy or irregular periods',
                    _id: '620419004ae9797ddc2f855c',
                    seq: 4,
                },
                {
                    name: 'No menses (Periods stopped)',
                    _id: '620419004ae9797ddc2f855d',
                    seq: 5,
                },
                {
                    name: 'Currently pregnant',
                    _id: '620419004ae9797ddc2f855e',
                    seq: 6,
                },
                {
                    name: 'Sterility/Infertility',
                    _id: '620419014ae9797ddc2f855f',
                    seq: 7,
                },
                {
                    name: 'Any other sexual/sex organ concerns',
                    _id: '620419014ae9797ddc2f8560',
                    seq: 8,
                },
                {
                    name: 'None of the above sex-specific genitourinary issues',
                    _id: '620419014ae9797ddc2f8561',
                    seq: 9,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8507',
            header_name: 'Genitourinary (Men)',
            header_label: 'sex-specific Genitourinary',
            is_horizontal: false,
            seq: 13,
            reviews_options: [
                {
                    name: 'Slow urine stream',
                    _id: '620419274ae9797ddc2f8562',
                    seq: 1,
                },
                {
                    name: 'Scrotal pain',
                    _id: '620419274ae9797ddc2f8563',
                    seq: 2,
                },
                {
                    name: 'Lump or mass in the testicles',
                    _id: '620419274ae9797ddc2f8564',
                    seq: 3,
                },
                {
                    name: 'Abnormal penis discharge',
                    _id: '620419274ae9797ddc2f8565',
                    seq: 4,
                },
                {
                    name: 'Trouble getting/maintaining erections',
                    _id: '620419274ae9797ddc2f8566',
                    seq: 5,
                },
                {
                    name: 'Inability to ejaculate/orgasm',
                    _id: '620419274ae9797ddc2f8567',
                    seq: 6,
                },
                {
                    name: 'Any other sexual or sex organ concerns',
                    _id: '620419284ae9797ddc2f8568',
                    seq: 7,
                },
                {
                    name: 'None of the above sex-specific genitourinary issues',
                    _id: '620419284ae9797ddc2f8569',
                    seq: 8,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415ae4ae9797ddc2f8508',
            header_name: 'Neurological',
            is_horizontal: false,
            seq: 14,
            reviews_options: [
                {
                    name: 'Paralysis',
                    _id: '620419884ae9797ddc2f856a',
                    seq: 1,
                },
                {
                    name: 'Fainting spells or blackouts',
                    _id: '620419884ae9797ddc2f856b',
                    seq: 2,
                },
                {
                    name: 'Dizziness/Vertigo',
                    _id: '620419894ae9797ddc2f856c',
                    seq: 3,
                },
                {
                    name: 'Drowsiness',
                    _id: '620419894ae9797ddc2f856d',
                    seq: 4,
                },
                {
                    name: 'Slurred speech',
                    _id: '620419894ae9797ddc2f856e',
                    seq: 5,
                },
                {
                    name: 'Speech problems (other)',
                    _id: '620419894ae9797ddc2f856f',
                    seq: 6,
                },
                {
                    name: 'Short term memory trouble',
                    _id: '620419894ae9797ddc2f8570',
                    seq: 7,
                },
                {
                    name: 'Memory difficulties (loss)',
                    _id: '620419894ae9797ddc2f8571',
                    seq: 8,
                },
                {
                    name: 'Frequent headaches',
                    _id: '620419894ae9797ddc2f8572',
                    seq: 9,
                },
                {
                    name: 'Muscle weakness',
                    _id: '620419894ae9797ddc2f8573',
                    seq: 10,
                },
                {
                    name: 'Numbness/Tingling sensations',
                    _id: '620419894ae9797ddc2f8574',
                    seq: 11,
                },
                {
                    name: 'Neuropathy (numbness in feet)',
                    _id: '6204198a4ae9797ddc2f8575',
                    seq: 12,
                },
                {
                    name: 'Tremor in hands/shaking',
                    _id: '6204198a4ae9797ddc2f8576',
                    seq: 13,
                },
                {
                    name: 'Muscle spasms or tremors',
                    _id: '6204198a4ae9797ddc2f8577',
                    seq: 14,
                },
                {
                    name: 'None of the above neurological issues',
                    _id: '6204198a4ae9797ddc2f8578',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415af4ae9797ddc2f8509',
            header_name: 'Integumentary (Skin/Breast and Hair)',
            is_horizontal: false,
            seq: 15,
            reviews_options: [
                {
                    name: 'Lesions',
                    _id: '620419ce4ae9797ddc2f8579',
                    seq: 1,
                },
                {
                    name: 'Unusual mole',
                    _id: '620419ce4ae9797ddc2f857a',
                    seq: 2,
                },
                {
                    name: 'Easy bruising',
                    _id: '620419ce4ae9797ddc2f857b',
                    seq: 3,
                },
                {
                    name: 'Increased perspiration',
                    _id: '620419cf4ae9797ddc2f857c',
                    seq: 4,
                },
                {
                    name: 'Rashes',
                    _id: '620419cf4ae9797ddc2f857d',
                    seq: 5,
                },
                {
                    name: 'Chronic dry skin',
                    _id: '620419cf4ae9797ddc2f857e',
                    seq: 6,
                },
                {
                    name: 'Itchy skin or scalp',
                    _id: '620419cf4ae9797ddc2f857f',
                    seq: 7,
                },
                {
                    name: 'Hair or nail changes',
                    _id: '620419cf4ae9797ddc2f8580',
                    seq: 8,
                },
                {
                    name: 'Hair loss',
                    _id: '620419cf4ae9797ddc2f8581',
                    seq: 9,
                },
                {
                    name: 'Breast tenderness',
                    _id: '620419cf4ae9797ddc2f8582',
                    seq: 10,
                },
                {
                    name: 'Breast discharge',
                    _id: '620419cf4ae9797ddc2f8583',
                    seq: 11,
                },
                {
                    name: 'Breast lump or mass',
                    _id: '620419cf4ae9797ddc2f8584',
                    seq: 12,
                },
                {
                    name: 'None of the above integumentary issues',
                    _id: '620419cf4ae9797ddc2f8585',
                    seq: 13,
                    is_none: true,
                },
            ],
        },
        {
            _id: '620415af4ae9797ddc2f850a',
            header_name: 'Psychiatric',
            is_horizontal: false,
            seq: 16,
            reviews_options: [
                {
                    name: 'In-depth review of psychiatric system appears earlier in document (to be checked by clinician only)',
                    _id: '62041af84ae9797ddc2f8586',
                    seq: 1,
                },
                {
                    name: 'Feeling depressed',
                    _id: '62041af94ae9797ddc2f8587',
                    seq: 2,
                },
                {
                    name: 'Difficulty concentrating',
                    _id: '62041af94ae9797ddc2f8588',
                    seq: 3,
                },
                {
                    name: 'Phobias/Unexplained fears',
                    _id: '62041af94ae9797ddc2f8589',
                    seq: 4,
                },
                {
                    name: 'No pleasure from life anymore',
                    _id: '62041af94ae9797ddc2f858a',
                    seq: 5,
                },
                {
                    name: 'Anxiety',
                    _id: '62041af94ae9797ddc2f858b',
                    seq: 6,
                },
                {
                    name: 'Insomnia',
                    _id: '62041af94ae9797ddc2f858c',
                    seq: 7,
                },
                {
                    name: 'Excessive moodiness',
                    _id: '62041af94ae9797ddc2f858d',
                    seq: 8,
                },
                {
                    name: 'Stress',
                    _id: '62041af94ae9797ddc2f858e',
                    seq: 9,
                },
                {
                    name: 'Disturbing thoughts',
                    _id: '62041af94ae9797ddc2f858f',
                    seq: 10,
                },
                {
                    name: 'Manic episodes',
                    _id: '62041af94ae9797ddc2f8590',
                    seq: 11,
                },
                {
                    name: 'Confusion',
                    _id: '62041af94ae9797ddc2f8591',
                    seq: 12,
                },
                {
                    name: 'Memory loss',
                    _id: '62041afa4ae9797ddc2f8592',
                    seq: 13,
                },
                {
                    name: 'Nightmares',
                    _id: '62041afa4ae9797ddc2f8593',
                    seq: 14,
                },
                {
                    name: 'None of the above psychiatric issues',
                    _id: '62041afa4ae9797ddc2f8594',
                    seq: 15,
                    is_none: true,
                },
            ],
        },
    ],
    stressorMasters: [
        {
            _id: '61d89c60ae09aaff99dd9a54',
            name: 'Family',
            seq: 1,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a55',
            name: 'Friends',
            seq: 2,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a56',
            name: 'Relationships',
            seq: 3,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a57',
            name: 'Educational',
            seq: 4,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a58',
            name: 'Economic',
            seq: 5,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a59',
            name: 'Occupational',
            seq: 6,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5a',
            name: 'Housing',
            seq: 7,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5b',
            name: 'Legal',
            seq: 8,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5c',
            name: 'Health',
            seq: 9,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5d',
            name: 'None',
            seq: 1,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5e',
            name: 'Mild Stress',
            seq: 2,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5f',
            name: 'Moderate Stress',
            seq: 3,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaff99dd9a60',
            name: 'Severe Stress',
            seq: 4,
            section_name: 'level',
        },
    ],
    sideEffectMasters: [
        {
            _id: '61d89c60ae09aaff99dd9a541',
            name: 'Acne/Increased acne',
            seq: 1,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a552',
            name: 'Agitation/Anxiety',
            seq: 2,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a563',
            name: 'Akathisia/Motor restlessness',
            seq: 3,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a574',
            name: 'Apathy/Emotional numbing',
            seq: 4,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a585',
            name: 'Bruising/Bleeding',
            seq: 5,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5852332',
            name: 'Cognitive slowing',
            seq: 6,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a596',
            name: 'Constipation',
            seq: 7,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5a7',
            name: 'Coordination problems',
            seq: 8,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5b8',
            name: 'Decreased appetite',
            seq: 9,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5c9',
            name: 'Diarrhea',
            seq: 10,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a511d',
            name: 'Dizziness/Light headed',
            seq: 11,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5e12',
            name: 'Dry mouth',
            seq: 12,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5f13',
            name: 'Dyskinesia/Involuntary movement',
            seq: 13,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a6014',
            name: 'Edema/Swelling',
            seq: 14,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5c15',
            name: 'Fatigue',
            seq: 15,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5d16',
            name: 'Frequent urination',
            seq: 16,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5e17',
            name: 'Hair loss',
            seq: 17,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5f18',
            name: 'Headache',
            seq: 18,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a6019',
            name: 'Increased appetite',
            seq: 19,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5c20',
            name: 'Insomnia',
            seq: 20,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5d21',
            name: 'Memory problems',
            seq: 21,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5e22',
            name: 'Muscle rigidity',
            seq: 22,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5f23',
            name: 'Nausea',
            seq: 23,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a6024',
            name: 'Palpitations',
            seq: 24,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff99dd9a5c25',
            name: 'Rash',
            seq: 25,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd9a5d26',
            name: 'Sedation',
            seq: 26,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd279a5e',
            name: 'Sexual',
            seq: 27,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd289a5f',
            name: 'Sweating',
            seq: 28,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99d29d9a60',
            name: 'Thirst',
            seq: 29,
            section_name: 'category',
        },
        {
            _id: '61d89c60ae09aaff9931dd9a5c',
            name: 'Tremor',
            seq: 30,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99dd329a5d',
            name: 'Urinary retention/Difficulty urinating',
            seq: 31,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aa33ff99dd9a5e',
            name: 'Visual problems',
            seq: 32,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff99d34d9a5f',
            name: 'Weight gain',
            seq: 33,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff9935dd9a60',
            name: 'Weight loss',
            seq: 34,
            section_name: 'category',
        },
        {
            _id: '61d89c8aae09aaff9579dd9a5d',
            name: 'None',
            seq: 1,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaf36f99dd9a5e',
            name: 'Mild (noticeable)',
            seq: 2,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaff99d38d9a5f',
            name: 'Moderate (some interference)',
            seq: 3,
            section_name: 'level',
        },
        {
            _id: '61d89c8aae09aaff9939dd9a60',
            name: 'Severe (marked interference)',
            seq: 4,
            section_name: 'level',
        },
    ],

    familyMasters: [
        {
            _id: '61df67ef49fc9bdb7eab0bf5',
            seq: 1,
            name: 'Mother',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf6',
            seq: 2,
            name: 'Father',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf7',
            seq: 3,
            name: 'Grandmother',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf8',
            seq: 4,
            name: 'Grandfather',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf9',
            seq: 5,
            name: 'Sister',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfa',
            seq: 6,
            name: 'Brother',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfb',
            seq: 7,
            name: 'Daughter',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfc',
            seq: 8,
            name: 'Son',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfd',
            seq: 9,
            name: 'Aunt',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfe',
            seq: 10,
            name: 'Uncle',
        },
        {
            _id: '61df67f049fc9bdb7eab0bff',
            seq: 11,
            name: 'Cousin',
        },
    ],
    primaryReasonForHospitalizationMasters: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61df67ef49fc9bdb7eab0bf5',
            seq: 1,
            name: 'Depression',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf6',
            seq: 2,
            name: 'Suicidal thoughts',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf7',
            seq: 3,
            name: 'Suicide attempt',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf8',
            seq: 4,
            name: 'Manic episode',
        },
        {
            _id: '61df67f049fc9bdb7eab0bf9',
            seq: 5,
            name: 'Psychotic episode',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfa',
            seq: 6,
            name: 'Severe Anxiety',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfb',
            seq: 7,
            name: 'Drug/Alcohol related',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfc',
            seq: 8,
            name: 'Assault',
        },
        {
            _id: '61df67f049fc9bdb7eab0bfd',
            seq: 9,
            name: 'Violence',
        },
    ],
    psychiatricMasters: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61eeef8e27a89df5082861b9',
            name: 'Depression',
            group_type: 'psychiatric_problem',
            seq: 1,
        },
        {
            _id: '61eeef8f27a89df5082861ba',
            name: 'Panic Disorder',
            group_type: 'psychiatric_problem',
            seq: 2,
        },
        {
            _id: '61eeef8f27a89df5082861bb',
            name: 'Generalized Anxiety Disorder',
            group_type: 'psychiatric_problem',
            seq: 3,
        },
        {
            _id: '61eeef8f27a89df5082861bc',
            name: 'Social Anxiety Disorder',
            group_type: 'psychiatric_problem',
            seq: 4,
        },
        {
            _id: '61eeef8f27a89df5082861bd',
            name: 'Obsessive Compulsive Disorder',
            group_type: 'psychiatric_problem',
            seq: 5,
        },
        {
            _id: '61eeef8f27a89df5082861be',
            name: 'Post Traumatic Stress Disorder',
            group_type: 'psychiatric_problem',
            seq: 6,
        },
        {
            _id: '61eeef8f27a89df5082861bf',
            name: 'Bipolar Disorder',
            group_type: 'psychiatric_problem',
            seq: 7,
        },
        {
            _id: '61eeef8f27a89df5082861c0',
            name: 'Eating Disorder',
            group_type: 'psychiatric_problem',
            seq: 8,
        },
        {
            _id: '61eeef8f27a89df5082861c1',
            name: 'Alcoholism',
            group_type: 'psychiatric_problem',
            seq: 9,
        },
        {
            _id: '61eeef8f27a89df5082861c2',
            name: 'Drug Abuse',
            group_type: 'psychiatric_problem',
            seq: 10,
        },
        {
            _id: '61eeef9027a89df5082861c3',
            name: 'ADHD',
            group_type: 'psychiatric_problem',
            seq: 11,
        },
        {
            _id: '61eeef9027a89df5082861c323423',
            name: 'Personality Disorder',
            group_type: 'psychiatric_problem',
            seq: 12,
        },
        {
            _id: '61eeef9027a89df5082861c4',
            name: 'Schizophrenia/Psychosis',
            group_type: 'psychiatric_problem',
            seq: 13,
        },
        {
            _id: '61eeef9027a89df5082861c5',
            name: 'Psychiatric Hospitalization',
            group_type: 'psychiatric_problem',
            seq: 14,
        },
        {
            _id: '61eeef9027a89df5082861c6',
            name: 'Suicide Attempt',
            group_type: 'psychiatric_problem',
            seq: 15,
        },
        {
            _id: '61eeef9027a89df5082861c7',
            name: 'Suicide',
            group_type: 'psychiatric_problem',
            seq: 16,
        },
    ],
    outpatientPsychiatricMasters: [
        {
            _id: '',
            name: '- Select One -',
            label: '',
            seq: 0,
        },
        {
            _id: '61eeef8e27a89df5082861b9',
            name: 'Depression',
            group_type: 'psychiatric_problem',
            seq: 1,
        },
        {
            _id: '61eeef8f27a89df5082861ba',
            name: 'Panic Disorder',
            group_type: 'psychiatric_problem',
            seq: 2,
        },
        {
            _id: '61eeef8f27a89df5082861bb',
            name: 'Generalized Anxiety Disorder',
            group_type: 'psychiatric_problem',
            seq: 3,
        },
        {
            _id: '61eeef8f27a89df5082861bc',
            name: 'Social Anxiety Disorder',
            group_type: 'psychiatric_problem',
            seq: 4,
        },
        {
            _id: '61eeef8f27a89df5082861bd',
            name: 'Obsessive Compulsive Disorder',
            group_type: 'psychiatric_problem',
            seq: 5,
        },
        {
            _id: '61eeef8f27a89df5082861be',
            name: 'Post Traumatic Stress Disorder',
            group_type: 'psychiatric_problem',
            seq: 6,
        },
        {
            _id: '61eeef8f27a89df5082861bf',
            name: 'Bipolar Disorder',
            group_type: 'psychiatric_problem',
            seq: 7,
        },
        {
            _id: '61eeef8f27a89df5082861c0',
            name: 'Eating Disorder',
            group_type: 'psychiatric_problem',
            seq: 8,
        },
        {
            _id: '61eeef8f27a89df5082861c1',
            name: 'Alcoholism',
            group_type: 'psychiatric_problem',
            seq: 9,
        },
        {
            _id: '61eeef8f27a89df5082861c2',
            name: 'Drug Abuse',
            group_type: 'psychiatric_problem',
            seq: 10,
        },
        {
            _id: '61eeef9027a89df5082861c3',
            name: 'ADHD',
            group_type: 'psychiatric_problem',
            seq: 11,
        },
        {
            _id: '61eeef9027a89df5082861c323423',
            name: 'Personality Disorder',
            group_type: 'psychiatric_problem',
            seq: 12,
        },
        {
            _id: '61eeef9027a89df5082861c4',
            name: 'Schizophrenia/Psychosis',
            group_type: 'psychiatric_problem',
            seq: 13,
        },
        {
            _id: '61eeef9027a89df5082861c6',
            name: 'Suicide',
            group_type: 'psychiatric_problem',
            seq: 14,
        },
        {
            _id: '61eeef9027a89df5082861c5',
            name: 'Autism Spectrum Disorder',
            group_type: 'psychiatric_problem',
            seq: 15,
        },

        {
            _id: '61eeef9027a89df5082861c7',
            name: 'Disruptive Behavior Problems',
            group_type: 'psychiatric_problem',
            seq: 16,
        },
    ],
    healthProbMasters: [
        {
            _id: '61eeee5b27a89df508286171',
            name: 'No Problems',
            group_type: 'health_problems',
            seq: 1,
        },
        {
            _id: '61eeee5b27a89df508286172',
            name: 'Allergies',
            group_type: 'health_problems',
            seq: 2,
        },
        {
            _id: '61eeee5b27a89df508286173',
            name: 'Anemia(low blood count)',
            group_type: 'health_problems',
            seq: 3,
        },
        {
            _id: '61eeee5b27a89df508286174',
            name: 'Arthritis',
            group_type: 'health_problems',
            seq: 4,
        },
        {
            _id: '61eeee5b27a89df508286175',
            name: 'Asthma',
            group_type: 'health_problems',
            seq: 5,
        },
        {
            _id: '61eeee5c27a89df508286176',
            name: 'Back problems (including disk or spine)',
            group_type: 'health_problems',
            seq: 6,
        },
        {
            _id: '61eeee5c27a89df508286177',
            name: 'Cancer',
            group_type: 'health_problems',
            seq: 7,
        },
        {
            _id: '61eeee5c27a89df508286178',
            name: 'Cataracts',
            group_type: 'health_problems',
            seq: 8,
        },
        {
            _id: '61eeee5c27a89df508286179',
            name: 'Chickenpox(as a child)',
            group_type: 'health_problems',
            seq: 9,
        },
        {
            _id: '61eeee5c27a89df50828617a',
            name: 'Chronic Bronchitis',
            group_type: 'health_problems',
            seq: 10,
        },
        {
            _id: '61eeee5c27a89df50828617b',
            name: 'COPD(Emphysema)',
            noChangeNameStyle: true,
            group_type: 'health_problems',
            seq: 11,
        },
        {
            _id: '61eeee5c27a89df50828617c',
            name: 'Diabetes',
            group_type: 'health_problems',
            seq: 12,
        },
        {
            _id: '61eeee5c27a89df50828617d',
            name: 'Diverticulitis',
            group_type: 'health_problems',
            seq: 13,
        },
        {
            _id: '61eeee5c27a89df50828617e',
            name: 'Fainting spells/Passing out',
            group_type: 'health_problems',
            seq: 14,
        },
        {
            _id: '61eeee5d27a89df50828617f',
            name: 'High cholesterol',
            group_type: 'health_problems',
            seq: 15,
        },
        {
            _id: '61eeee5d27a89df508286180',
            name: 'Fibromyalgia',
            group_type: 'health_problems',
            seq: 16,
        },
        {
            _id: '61eeee5d27a89df508286181',
            name: 'Gall Bladder disease',
            group_type: 'health_problems',
            seq: 17,
        },
        {
            _id: '61eeee5d27a89df508286182',
            name: 'Gastritis or Ulcer',
            group_type: 'health_problems',
            seq: 18,
        },
        {
            _id: '61eeee5d27a89df508286183',
            name: 'Glaucoma',
            group_type: 'health_problems',
            seq: 19,
        },
        {
            _id: '61eeee5d27a89df508286184',
            name: 'Gout',
            group_type: 'health_problems',
            seq: 20,
        },
        {
            _id: '61eeee5d27a89df508286185',
            name: 'Hearing Loss',
            group_type: 'health_problems',
            seq: 21,
        },
        {
            _id: '61eeee5d27a89df508286186',
            name: 'Heart disease',
            group_type: 'health_problems',
            seq: 22,
        },
        {
            _id: '61eeee5e27a89df508286187',
            name: 'Heart defect from birth',
            group_type: 'health_problems',
            seq: 23,
        },
        {
            _id: '61eeee5e27a89df508286188',
            name: 'Heart valve problems',
            group_type: 'health_problems',
            seq: 24,
        },
        {
            _id: '61eeee5e27a89df508286189',
            name: 'Hemorrhoids',
            group_type: 'health_problems',
            seq: 25,
        },
        {
            _id: '61eeee5e27a89df50828618a',
            name: 'Hepatitis',
            group_type: 'health_problems',
            seq: 26,
        },
        {
            _id: '61eeee5e27a89df50828618b',
            name: 'Hernia',
            group_type: 'health_problems',
            seq: 27,
        },
        {
            _id: '61eeee5e27a89df50828618c',
            name: 'HIV',
            group_type: 'health_problems',
            seq: 28,
        },
        {
            _id: '61eeee5e27a89df50828618d',
            name: 'Hypertension(High blood pressure)',
            group_type: 'health_problems',
            seq: 29,
        },
        {
            _id: '61eeee5e27a89df50828618e',
            name: 'Hypotension(Low blood pressure)',
            group_type: 'health_problems',
            seq: 30,
        },
        {
            _id: '61eeee5e27a89df50828618f',
            name: 'Iron deficiency',
            group_type: 'health_problems',
            seq: 31,
        },
        {
            _id: '61eeee5f27a89df508286190',
            name: 'Inflammatory Bowel Disease',
            group_type: 'health_problems',
            seq: 32,
        },
        {
            _id: '61eeee5f27a89df508286191',
            name: 'Kidney Disease',
            group_type: 'health_problems',
            seq: 33,
        },
        {
            _id: '61eeee5f27a89df508286192',
            name: 'Kidney Stones',
            group_type: 'health_problems',
            seq: 34,
        },
        {
            _id: '61eeee5f27a89df508286193',
            name: 'Liver disease (other)',
            group_type: 'health_problems',
            seq: 35,
        },
        {
            _id: '61eeee5f27a89df508286194',
            name: 'Lupus',
            group_type: 'health_problems',
            seq: 36,
        },
        {
            _id: '61eeee5f27a89df508286195',
            name: 'Migraine Headaches',
            group_type: 'health_problems',
            seq: 37,
        },
        {
            _id: '61eeee5f27a89df508286196',
            name: 'Multiple Sclerosis',
            group_type: 'health_problems',
            seq: 38,
        },
        {
            _id: '61eeee5f27a89df508286197',
            name: 'Obesity /Overweight',
            group_type: 'health_problems',
            seq: 39,
        },
        {
            _id: '61eeee5f27a89df508286198',
            name: "Parkinson's Disease",
            group_type: 'health_problems',
            seq: 40,
        },
        {
            _id: '61eeee6027a89df508286199',
            name: 'Polyps',
            group_type: 'health_problems',
            seq: 41,
        },
        {
            _id: '61eeee6027a89df50828619a',
            name: 'Seizures',
            group_type: 'health_problems',
            seq: 42,
        },
        {
            _id: '61eeee6027a89df50828619b',
            name: 'Sexually Transmitted Disease (STD)',
            group_type: 'health_problems',
            seq: 43,
        },
        {
            _id: '61eeee6027a89df50828619c',
            name: 'Sleep apnea',
            group_type: 'health_problems',
            seq: 44,
        },
        {
            _id: '61eeee6027a89df50828619d',
            name: 'Stroke/TIA',
            group_type: 'health_problems',
            seq: 45,
        },
        {
            _id: '61eeee6027a89df50828619e',
            name: 'Testosterone(low)',
            group_type: 'health_problems',
            seq: 46,
        },
        {
            _id: '61eeee6027a89df50828619f',
            name: 'Thyroid problems(hypothyroid/hyperthyroid)',
            group_type: 'health_problems',
            seq: 47,
        },
        {
            _id: '61eeee6027a89df5082861a0',
            name: 'Tuberculosis or exposure to tuberculosis',
            group_type: 'health_problems',
            seq: 48,
        },
    ],
    surgicalAreaMasters: [
        {
            _id: '61eeeef527a89df5082861a1',
            name: 'No surgical history',
            group_type: 'surgery_area',
            seq: 1,
        },
        {
            _id: '61eeeef527a89df5082861a2',
            name: 'Back/Neck',
            group_type: 'surgery_area',
            seq: 2,
        },
        {
            _id: '61eeeef527a89df5082861a3',
            name: 'Brain',
            group_type: 'surgery_area',
            seq: 3,
        },
        {
            _id: '61eeeef527a89df5082861a4',
            name: 'Cardiac',
            group_type: 'surgery_area',
            seq: 4,
        },
        {
            _id: '61eeeef627a89df5082861a5',
            name: 'Ear/Nose/Throat',
            group_type: 'surgery_area',
            seq: 5,
        },
        {
            _id: '61eeeef627a89df5082861a6',
            name: 'Gall Bladder',
            group_type: 'surgery_area',
            seq: 6,
        },
        {
            _id: '61eeeef627a89df5082861a7',
            name: 'Hernia',
            group_type: 'surgery_area',
            seq: 7,
        },
        {
            _id: '61eeeef627a89df5082861a8',
            name: 'Hip/Knee/Ankle/Foot',
            group_type: 'surgery_area',
            seq: 8,
        },
        {
            _id: '61eeeef627a89df5082861a9',
            name: 'Hysterectomy (Ovaries Removed)',
            group_type: 'surgery_area',
            seq: 9,
        },
        {
            _id: '61eeeef627a89df5082861aa',
            name: 'Hysterectomy (Ovaries Retained)',
            group_type: 'surgery_area',
            seq: 10,
        },
        {
            _id: '61eeeef627a89df5082861ab',
            name: 'Intestine',
            group_type: 'surgery_area',
            seq: 11,
        },
        {
            _id: '61eeeef627a89df5082861ac',
            name: 'Kidney',
            group_type: 'surgery_area',
            seq: 12,
        },
        {
            _id: '61eeeef627a89df5082861ad',
            name: 'Liver',
            group_type: 'surgery_area',
            seq: 13,
        },
        {
            _id: '61eeeef627a89df5082861ae',
            name: 'Lung',
            group_type: 'surgery_area',
            seq: 14,
        },
        {
            _id: '61eeeef627a89df5082861af',
            name: 'Pancreas',
            group_type: 'surgery_area',
            seq: 15,
        },
        {
            _id: '61eeeef627a89df5082861b0',
            name: 'Pelvis',
            group_type: 'surgery_area',
            seq: 16,
        },
        {
            _id: '61eeeef727a89df5082861b1',
            name: 'Penis',
            group_type: 'surgery_area',
            seq: 17,
        },
        {
            _id: '61eeeef727a89df5082861b2',
            name: 'Prostate',
            group_type: 'surgery_area',
            seq: 18,
        },
        {
            _id: '61eeeef727a89df5082861b3',
            name: 'Sex Change',
            group_type: 'surgery_area',
            seq: 19,
        },
        {
            _id: '61eeeef727a89df5082861b4',
            name: 'Shoulder/Elbow/Wrist/Hand',
            group_type: 'surgery_area',
            seq: 20,
        },
        {
            _id: '61eeeef727a89df5082861b5',
            name: 'Stomach',
            group_type: 'surgery_area',
            seq: 21,
        },
        {
            _id: '61eeeef727a89df5082861b6',
            name: 'Tonsils',
            group_type: 'surgery_area',
            seq: 22,
        },
        {
            _id: '61eeeef727a89df5082861b7',
            name: 'Vagina',
            group_type: 'surgery_area',
            seq: 23,
        },
        {
            _id: '61eeeef727a89df5082861b8',
            name: 'Weight Loss',
            group_type: 'surgery_area',
            seq: 24,
        },
    ],
    devEduHistoryMasters: [
        {
            _id: '61ef858127a89df5082861c8',
            name: 'None of these',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 1,
            schema_field: 'during_preg_prob',
            is_none: true,
        },
        {
            _id: '61ef858127a89df5082861c9',
            name: 'Exposure to drugs or alcohol during pregnancy',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 2,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef858127a89df5082861ca',
            name: 'A difficult pregnancy',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 3,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef858127a89df5082861cb',
            name: 'Problems with delivery',
            section_name: 'during_preg_prob',
            section: 1,
            seq: 4,
            schema_field: 'during_preg_prob',
        },
        {
            _id: '61ef881427a89df5082861cc',
            name: 'None of these',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 1,
            schema_field: 'difficulties_in_reaching',
            is_none: true,
        },
        {
            _id: '61ef881427a89df5082861cd',
            name: 'Walking',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 2,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861ce',
            name: 'Talking',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 3,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861cf',
            name: 'Toilet training',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 4,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d0',
            name: 'Sleeping alone',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 5,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d1',
            name: 'Being away from parents',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 6,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef881427a89df5082861d2',
            name: 'Making friends',
            section_name: 'difficulties_in_reaching',
            section: 2,
            seq: 7,
            schema_field: 'difficulties_in_reaching',
        },
        {
            _id: '61ef887627a89df5082861d3',
            name: 'Normal',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 1,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d4',
            name: 'Supportive',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 2,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d5',
            name: 'Parental fighting',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 3,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d6',
            name: 'Parental violence',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 4,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d7',
            name: 'Financial difficulties',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 5,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef887627a89df5082861d8',
            name: 'Frequent moving',
            section_name: 'childhood_atmos',
            section: 3,
            seq: 6,
            schema_field: 'childhood_atmos',
        },
        {
            _id: '61ef899527a89df5082861d9',
            name: 'None of these',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 1,
            schema_field: 'childhood_challenges',
            is_none: true,
        },
        {
            _id: '61ef899527a89df5082861da',
            name: 'Tantrums',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 2,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861db',
            name: 'Enuresis(bed wetting)',
            label: 'Enuresis',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 3,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861dc',
            name: 'Encopresis (fecal incontinence)',
            label: 'Encopresis',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 4,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861dd',
            name: 'Running away from home',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 5,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861de',
            name: 'Fighting',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 6,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861df',
            name: 'Stealing',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 7,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e0',
            name: 'Property damage',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 8,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e1',
            name: 'Fire setting',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 9,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e2',
            name: 'Animal cruelty',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 10,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e3',
            name: 'Separation anxiety',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 11,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e4',
            name: 'Victim of bullying',
            label: 'having been the victim of bullying',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 12,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899527a89df5082861e5',
            name: 'Depression',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 13,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899627a89df5082861e6',
            name: 'Death of a parent/caregiver',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 14,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef899627a89df5082861e7',
            name: 'Parental divorce',
            section_name: 'childhood_challenges',
            section: 4,
            seq: 15,
            schema_field: 'childhood_challenges',
        },
        {
            _id: '61ef89e527a89df5082861e8',
            name: 'None of these',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 1,
            schema_field: 'have_prob_in_school',
            is_none: true,
        },
        {
            _id: '61ef89e627a89df5082861e9',
            name: 'Fighting',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 2,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ea',
            name: 'School phobia',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 3,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861eb',
            name: 'Truancy',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 4,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ec',
            name: 'Detentions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 5,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ed',
            name: 'Suspensions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 6,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ee',
            name: 'Expulsions',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 7,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861ef',
            name: 'School refusal',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 8,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f0',
            name: 'Class failures',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 9,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f1',
            name: 'Repetition of grades',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 10,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f2',
            name: 'Special education',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 11,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef89e627a89df5082861f3',
            name: 'Remedial classes',
            section_name: 'have_prob_in_school',
            section: 5,
            seq: 12,
            schema_field: 'have_prob_in_school',
        },
        {
            _id: '61ef8acc27a89df5082861f4',
            name: 'None of these',
            section_name: 'additional_schooling',
            section: 6,
            seq: 1,
            schema_field: 'additional_schooling',
            is_none: true,
        },
        {
            _id: '61ef8acc27a89df5082861f5',
            name: 'Speech classes',
            section_name: 'additional_schooling',
            section: 6,
            seq: 2,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8acd27a89df5082861f6',
            name: 'Tutoring',
            section_name: 'additional_schooling',
            section: 6,
            seq: 3,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8acd27a89df5082861f7',
            name: 'Accommodations',
            section_name: 'additional_schooling',
            section: 6,
            seq: 4,
            schema_field: 'additional_schooling',
        },
        {
            _id: '61ef8b0d27a89df5082861f8',
            name: 'Less than high school',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 1,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861f9',
            name: 'High school/GED',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 2,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861f349',
            name: ' Some college',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 3,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fa',
            name: 'Two-year degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 4,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fb',
            name: 'Four-year degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 5,
            schema_field: 'highest_edu_level',
        },
        {
            _id: '61ef8b0d27a89df5082861fc',
            name: 'Graduate/Professional degree',
            section_name: 'highest_edu_level',
            section: 7,
            seq: 6,
            schema_field: 'highest_edu_level',
        },
    ],
    substanceAbuseMasters: [
        {
            _id: '61d89dd5ae09aaff99dd9a61',
            name: 'Amphetamines/Speed',
            group_type: 'subtance_abuse_history',
            seq: 1,
        },
        {
            _id: '61d89dd5ae09aaff99dd9a62',
            name: 'Barbiturates/Downers',
            group_type: 'subtance_abuse_history',
            seq: 2,
        },
        {
            _id: '61d89dd5ae09aaff99dd9a63',
            name: 'Opiates',
            group_type: 'subtance_abuse_history',
            seq: 3,
        },
        {
            _id: '61d89dd5ae09aaff99dd9a64',
            name: 'Cocaine',
            group_type: 'subtance_abuse_history',
            seq: 4,
        },
        {
            _id: '61d89dd6ae09aaff99dd9a65',
            name: 'Psychedelice (e.g.LSD,Ecstasy,bath salts)',
            group_type: 'subtance_abuse_history',
            seq: 5,
        },
        {
            _id: '61d89dd6ae09aaff99dd9a66',
            name: 'Inhalants (e.g.glue, aerosols)',
            group_type: 'subtance_abuse_history',
            seq: 6,
        },
        {
            _id: '61d89dd6ae09aaff99dd9a67',
            name: 'Cannabis/Marijuana/Hashish',
            group_type: 'subtance_abuse_history',
            seq: 7,
        },
        {
            _id: '61d89dd6ae09aaff99dd9a68',
            name: 'Benzodiazepines',
            group_type: 'subtance_abuse_history',
            seq: 8,
        },
        {
            _id: '61d89dd6ae09aaff99dd9a69',
            name: 'PCP',
            group_type: 'subtance_abuse_history',
            seq: 9,
        },
    ],
    substanceTreatmentMasters: [
        {
            _id: '61d89e22ae09aaff99dd9a6a',
            name: 'Inpatient',
            group_type: 'subtance_abuse_treat_history',
            seq: 1,
        },
        {
            _id: '61d89e22ae09aaff99dd9a6b',
            name: 'Intensive Outpatient',
            group_type: 'subtance_abuse_treat_history',
            seq: 2,
        },
        {
            _id: '61d89e22ae09aaff99dd9a6c',
            name: 'Outpatient',
            group_type: 'subtance_abuse_treat_history',
            seq: 3,
        },
        {
            _id: '61d89e22ae09aaff99dd9a6d',
            name: '12-Step Program',
            group_type: 'subtance_abuse_treat_history',
            seq: 4,
        },
    ],
    substanceConsequenceMasters: [
        {
            _id: '61d89e88ae09aaff99dd9a6e',
            name: 'No consequences',
            label: 'No consequences',
            group_type: 'subtance_use_consequences',
            seq: 1,
        },
        {
            _id: '61d89e88ae09aaff99dd9a6f',
            name: 'Felt that you needed to cut down on your drinking',
            label: 'Feeling that they needed to cut down on their drinking',
            group_type: 'subtance_use_consequences',
            seq: 2,
        },
        {
            _id: '61d89e88ae09aaff99dd9a70',
            name: 'Been annoyed by others criticizing your drinking',
            label: 'Being annoyed by others criticizing their drinking',
            group_type: 'subtance_use_consequences',
            seq: 3,
        },
        {
            _id: '61d89e88ae09aaff99dd9a71',
            name: 'Felt guilty about drinking',
            label: 'Feeling guilty about their drinking',
            group_type: 'subtance_use_consequences',
            seq: 4,
        },
        {
            _id: '61d89e88ae09aaff99dd9a72',
            name: 'Needing a drink first thing in the morning',
            label: 'Needing a drink first thing in the morning',
            group_type: 'subtance_use_consequences',
            seq: 5,
        },
        {
            _id: '61d89e88ae09aaff99dd9a73',
            name: 'Increased tolerance',
            label: 'Increased tolerance',
            group_type: 'subtance_use_consequences',
            seq: 6,
        },
        {
            _id: '61d89e88ae09aaff99dd9a74',
            name: 'Withdrawal (shakes, sweating, nausea, rapid heart rate)',
            label: 'Withdrawal (shakes, sweating, nausea, rapid heart rate)',
            group_type: 'subtance_use_consequences',
            seq: 7,
        },
        {
            _id: '61d89e88ae09aaff99dd9a75',
            name: 'Seizures',
            label: 'Seizures',
            group_type: 'subtance_use_consequences',
            seq: 8,
        },
        {
            _id: '61d89e88ae09aaff99dd9a76',
            name: 'Blackouts',
            label: 'Blackouts',
            group_type: 'subtance_use_consequences',
            seq: 9,
        },
        {
            _id: '61d89e88ae09aaff99dd9a77',
            name: 'Effects on physical health',
            label: 'Effects on physical health',
            group_type: 'subtance_use_consequences',
            seq: 10,
        },
        {
            _id: '61d89e88ae09aaff99dd9a78',
            name: 'Using/consuming more than intended',
            label: 'Using/consuming more than intended',
            group_type: 'subtance_use_consequences',
            seq: 11,
        },
        {
            _id: '61d89e89ae09aaff99dd9a79',
            name: 'Unintentional overdose',
            label: 'Unintentional overdose',
            group_type: 'subtance_use_consequences',
            seq: 12,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7a',
            name: 'DUI',
            label: 'DUI',
            noChangeNameStyle: true,
            group_type: 'subtance_use_consequences',
            seq: 13,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7b',
            name: 'Arrests',
            label: 'Arrests',
            group_type: 'subtance_use_consequences',
            seq: 14,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7c',
            name: 'Physical fights or assaults',
            label: 'Physical fights or assaults',
            group_type: 'subtance_use_consequences',
            seq: 15,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7d',
            name: 'Relationship conflicts',
            label: 'Relationship conflicts',
            group_type: 'subtance_use_consequences',
            seq: 16,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7e',
            name: 'Problems with money',
            label: 'Problems with money',
            group_type: 'subtance_use_consequences',
            seq: 17,
        },
        {
            _id: '61d89e89ae09aaff99dd9a7f',
            name: 'Job loss or problems at work/school',
            label: 'Job loss or problems at work/school',
            group_type: 'subtance_use_consequences',
            seq: 18,
        },
    ],
    generalSocHisMasters: [
        {
            _id: '61ef8dcb27a89df5082861fd',
            name: 'Supportive social network',
            label: 'Supportive social network',
            section_name: 'social_situation',
            section: 1,
            seq: 1,
        },
        {
            _id: '61ef8dcb27a89df5082861fe',
            name: 'Few friends',
            label: 'Few friends',
            section_name: 'social_situation',
            section: 1,
            seq: 2,
        },
        {
            _id: '61ef8dcb27a89df5082861ff',
            name: 'Substance-use based friends',
            label: 'Substance-use based friends',
            section_name: 'social_situation',
            section: 1,
            seq: 3,
        },
        {
            _id: '61ef8dcb27a89df508286200',
            name: 'No friends',
            label: 'No friends',
            section_name: 'social_situation',
            section: 1,
            seq: 4,
        },
        {
            _id: '61ef8dcb27a89df508286201',
            name: 'Distant from family of origin',
            label: 'Distant from family of origin',
            section_name: 'social_situation',
            section: 1,
            seq: 5,
        },
        {
            _id: '61ef8dcb27a89df508286202',
            name: 'Family conflict',
            label: 'Family conflict',
            section_name: 'social_situation',
            section: 1,
            seq: 6,
        },
        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 0,
        },
        {
            _id: '61ef8e3127a89df508286209',
            name: 'Single/Never married',
            label: 'Single/Never married',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 1,
        },
        {
            _id: '61ef8e3127a89df50828620b',
            name: 'Married/Permanent partnership',
            label: 'Married/Permanent partnership',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 2,
        },
        {
            _id: '61ef8e3127a89df50828620c',
            name: 'Divorced',
            label: 'Divorced',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 3,
        },
        {
            _id: '61ef8e3127a89df50828620d',
            name: 'Separated or divorce in process',
            label: 'Separated or divorce in process',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 4,
        },
        {
            _id: '61ef8e3127a89df50828620e',
            name: 'Widowed',
            label: 'Widowed',
            section_name: 'current_matrial_status',
            section: 2,
            seq: 5,
        },
        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'intimate_relationship_status',
            section: 3,
            seq: 0,
        },
        {
            _id: '61ef8e7d27a89df50828620f',
            name: 'Never been in a serious relationship',
            label: 'Never been in a serious relationship',
            section_name: 'intimate_relationship_status',
            section: 3,
            seq: 1,
        },
        {
            _id: '61ef8e7d27a89df508286210',
            name: 'Not currently in a relationship',
            label: 'Not currently in a relationship',
            section_name: 'intimate_relationship_status',
            section: 3,
            seq: 2,
        },
        {
            _id: '61ef8e7d27a89df508286211',
            name: 'Currently in a serious relationship',
            label: 'Currently in a serious relationship',
            section_name: 'intimate_relationship_status',
            section: 3,
            seq: 3,
        },

        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 0,
        },
        {
            _id: '61ef8ec827a89df508286212',
            name: 'Very satisfied',
            label: 'Very satisfied',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 1,
        },
        {
            _id: '61ef8ec827a89df508286213',
            name: 'Satisfied',
            label: 'Satisfied',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 2,
        },
        {
            _id: '61ef8ec827a89df508286214',
            name: 'Somewhat satisfied',
            label: 'Somewhat satisfied',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 3,
        },
        {
            _id: '61ef8ec827a89df508286215',
            name: 'Dissatisfied',
            label: 'Dissatisfied',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 4,
        },
        {
            _id: '61ef8ec827a89df508286216',
            name: 'Not applicable',
            label: 'Not applicable',
            section_name: 'intimate_satisfaction_level',
            section: 4,
            seq: 5,
        },

        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'sexual_orientation',
            section: 5,
            seq: 0,
        },
        {
            _id: '61ef8f0b27a89df508286217',
            name: 'Heterosexual',
            label: 'Heterosexual',
            section_name: 'sexual_orientation',
            section: 5,
            seq: 1,
        },
        {
            _id: '61ef8f0b27a89df508286218',
            name: 'Homosexual ',
            label: 'Homosexual ',
            section_name: 'sexual_orientation',
            section: 5,
            seq: 2,
        },
        {
            _id: '61ef8f0b27a89df50828621843',
            name: 'Bisexual',
            label: 'Bisexual',
            section_name: 'sexual_orientation',
            section: 5,
            seq: 3,
        },
        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'current_living_situation',
            section: 6,
            seq: 0,
        },
        {
            _id: '61ef8f4c27a89df508286219',
            name: 'Rent (apartment/house)',
            label: 'are renting (house/apartment)',
            section_name: 'current_living_situation',
            section: 6,
            seq: 1,
        },
        {
            _id: '61ef8f4c27a89df50828621a',
            name: 'Own (house/condo)',
            label: 'own their own residence (house/condo)',
            section_name: 'current_living_situation',
            section: 6,
            seq: 2,
        },
        {
            _id: '61ef8f4c27a89df50828621b',
            name: 'Group Home',
            label: 'live in a group home',
            section_name: 'current_living_situation',
            section: 6,
            seq: 3,
        },
        {
            _id: '61ef8f4d27a89df50828621c',
            name: 'Homeless',
            label: 'are homeless',
            section_name: 'current_living_situation',
            section: 6,
            seq: 4,
        },
        {
            _id: '61ef8f4d27a89df50828621d',
            name: 'Foster care',
            label: 'are in foster care',
            section_name: 'current_living_situation',
            section: 6,
            seq: 5,
        },

        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'currently_live_with',
            section: 7,
            seq: 0,
        },
        {
            _id: '61ef8f9e27a89df50828621e',
            name: 'Live alone',
            label: 'Live alone',
            section_name: 'currently_live_with',
            section: 7,
            seq: 1,
        },
        {
            _id: '61ef8f9e27a89df50828621f',
            name: 'Roommates',
            label: 'Roommates',
            section_name: 'currently_live_with',
            section: 7,
            seq: 2,
        },
        {
            _id: '61ef8f9e27a89df508286220',
            name: 'Partner/Spouse',
            label: 'Partner/Spouse',
            section_name: 'currently_live_with',
            section: 7,
            seq: 3,
        },
        {
            _id: '61ef8f9e27a89df508286221',
            name: 'Parent(s)',
            label: 'Parent(s)',
            section_name: 'currently_live_with',
            section: 7,
            seq: 4,
        },
        {
            _id: '61ef8f9e27a89df508286222',
            name: 'Sibling(s)',
            label: 'Sibling(s)',
            section_name: 'currently_live_with',
            section: 7,
            seq: 5,
        },
        {
            _id: '61ef8f9e27a89df508286223',
            name: 'Children',
            label: 'Children',
            section_name: 'currently_live_with',
            section: 7,
            seq: 6,
        },

        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 0,
        },
        {
            _id: '61ef8fdc27a89df508286224',
            name: 'Employed full-time',
            label: 'Employed full-time',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 1,
        },
        {
            _id: '61ef8fdc27a89df508286225',
            name: 'Employed part-time',
            label: 'Employed part-time',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 2,
        },
        {
            _id: '61ef8fdc27a89df508286226',
            name: 'Temp/Seasonal employment',
            label: 'employed temporarily/seasonlly',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 3,
        },
        {
            _id: '61ef8fdc27a89df508286227',
            name: 'Full-time student',
            label: 'a Full-time student',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 4,
        },
        {
            _id: '61ef8fdc27a89df508286228',
            name: 'Part-time student',
            label: 'a Part-time student',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 5,
        },
        {
            _id: '61ef8fdc27a89df508286229',
            name: 'Homemaker',
            label: 'a Homemaker',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 6,
        },
        {
            _id: '61ef8fdc27a89df50828622a',
            name: 'Unemployed (seeking work)',
            label: 'Unemployed (seeking work)',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 7,
        },
        {
            _id: '61ef8fdd27a89df50828622b',
            name: 'Unemployed (not seeking work)',
            label: 'Unemployed (not seeking work)',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 8,
        },
        {
            _id: '61ef8fdd27a89df50828622c',
            name: 'Retired',
            label: 'Retired',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 9,
        },
        {
            _id: '61ef8fdd27a89df50828622c3434',
            name: 'Disability',
            label: 'Disability',
            section_name: 'current_occupation_status',
            section: 8,
            seq: 10,
        },

        {
            _id: '',
            name: '- Select One -',
            label: '- Select One -',
            section_name: 'current_yearly_income',
            section: 0,
            seq: 0,
        },
        {
            _id: '61ef902527a89df50828622d',
            name: 'Less than $11,000',
            label: 'Less than $11,000',
            section_name: 'current_yearly_income',
            section: 9,
            seq: 1,
        },
        {
            _id: '61ef902527a89df50828622e',
            name: '$11,000 - $25,999',
            label: 'between $11,000 and $25,999',
            section_name: 'current_yearly_income',
            section: 9,
            seq: 2,
        },
        {
            _id: '61ef902627a89df50828622f',
            name: '$26,000 - $75,999',
            label: 'between $26,000 and $75,999',
            section_name: 'current_yearly_income',
            section: 9,
            seq: 3,
        },
        {
            _id: '61ef902627a89df508286230',
            name: '$76,000 - $100,000',
            label: 'between $76,000 and $100,000',
            section_name: 'current_yearly_income',
            section: 9,
            seq: 4,
        },
        {
            _id: '61ef902627a89df508286231',
            name: 'More than $100,000',
            label: 'More than $100,000',
            section_name: 'current_yearly_income',
            section: 9,
            seq: 5,
        },
    ],
    mensPregHisMasters: [
        {
            _id: '61ef90cd27a89df508286232',
            name: 'None of these',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 1,
        },
        {
            _id: '61ef90cd27a89df508286233',
            name: 'Dysphoria',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 2,
        },
        {
            _id: '61ef90cd27a89df508286234',
            name: 'Cramps',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 3,
        },
        {
            _id: '61ef90cd27a89df508286235',
            name: 'Appetite change',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 4,
        },
        {
            _id: '61ef90cd27a89df508286236',
            name: 'Bloating',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 5,
        },
        {
            _id: '61ef90cd27a89df508286237',
            name: 'Sleep disturbance',
            section_name: 'premenstrual_symptoms',
            section: 1,
            seq: 6,
        },
        {
            _id: '61ef913527a89df508286238',
            name: 'No method of contraception',
            section_name: 'contraception',
            section: 2,
            seq: 1,
            group_type: 'contraception_methods',
        },
        {
            _id: '61ef913527a89df508286239',
            name: 'Intrauterine(e.g. IUD)',
            label: 'an intrauterine method',
            section_name: 'contraception',
            section: 2,
            seq: 2,
            group_type: 'contraception_methods',
        },
        {
            _id: '61ef913527a89df50828623a',
            name: 'Hormonal (e.g. implant, injection, the pill, patch, hormonal vaginal contracetive ring)',
            label: 'a Hormonal method',
            section_name: 'contraception',
            section: 2,
            seq: 3,
            group_type: 'contraception_methods',
        },
        {
            _id: '61ef913527a89df50828623b',
            name: 'Barrier (e.g. diaphragm, male/female condom, spermicide)',
            label: 'a Barrier method',
            section_name: 'contraception',
            section: 2,
            seq: 4,
            group_type: 'contraception_methods',
        },
        {
            _id: '61ef913627a89df50828623c',
            name: 'Fertility Awareness-based (e.g. natural family planning)',
            label: 'a Fertility Awareness-based method of contraception.',
            section_name: 'contraception',
            section: 2,
            seq: 5,
            group_type: 'contraception_methods',
        },
        {
            _id: '61ef913627a89df50828623d',
            name: 'Permanent (e.g. male/female sterilization, infertility)',
            label: 'Permanent',
            section_name: 'contraception',
            section: 2,
            seq: 6,
            group_type: 'contraception_methods',
        },
    ],
    examMasters: [
        {
            _id: '61de7cd837c29c698907ffaf',
            seq: 1,
            name: 'Appropriate dress',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd837c29c698907ffb0',
            seq: 2,
            name: 'Adequate grooming and hygiene',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd837c29c698907ffb1',
            seq: 3,
            name: 'Disheveled',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb2',
            seq: 4,
            name: 'Malodorous',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb3',
            seq: 5,
            name: 'Unusual/Eccentric dress',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb4',
            seq: 6,
            name: 'Obese',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb5',
            seq: 7,
            name: 'Cachectic',
            section_name: 'appearances',
            section: 1,
            is_free_text: false,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb6',
            seq: 8,
            name: 'Deformities',
            section_name: 'appearances',
            section: 1,
            is_free_text: true,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb7',
            seq: 9,
            name: 'Facial dysmorphisms',
            section_name: 'appearances',
            section: 1,
            is_free_text: true,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb8',
            seq: 10,
            name: 'Assistive devices',
            section_name: 'appearances',
            section: 1,
            is_free_text: true,
            section_text: 'Appearance',
        },
        {
            _id: '61de7cd937c29c698907ffb9',
            seq: 11,
            name: 'Other',
            section_name: 'appearances',
            section: 1,
            is_free_text: true,
            section_text: 'Appearance',
        },
        {
            _id: '61de7d4937c29c698907ffba',
            seq: 1,
            name: 'Cooperative',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffbb',
            seq: 2,
            name: 'Good eye contact',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffbc',
            seq: 3,
            name: 'Normal psychomotor activity',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffbd',
            seq: 4,
            name: 'Uncooperative',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffbe',
            seq: 5,
            name: 'Withdrawn',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffbf',
            seq: 6,
            name: 'Poor eye contact',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffc0',
            seq: 7,
            name: 'Intermittent eye contact',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffc1',
            seq: 8,
            name: 'Eye contact improved over appointment',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4937c29c698907ffc2',
            seq: 9,
            name: 'Psychomotor retarded',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4a37c29c698907ffc3',
            seq: 10,
            name: 'Elevated psychomotor activity',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4a37c29c698907ffc4',
            seq: 11,
            name: 'Involuntary movements',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4a37c29c698907ffc5',
            seq: 12,
            name: 'Tics',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4a37c29c698907ffc6',
            seq: 13,
            name: 'Unusual mannerisms',
            section_name: 'behavior',
            section: 2,
            is_free_text: false,
            section_text: 'Behavior',
        },
        {
            _id: '61de7d4a37c29c698907ffc7',
            seq: 14,
            name: 'Other',
            section_name: 'behavior',
            section: 2,
            is_free_text: true,
            section_text: 'Behavior',
        },
        {
            _id: '61de871037c29c698907ffd3',
            seq: 1,
            name: 'Normal rate, amplitude, and prosody',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd4',
            seq: 2,
            name: 'Rapid/Pressured',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd5',
            seq: 3,
            name: 'Slow',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd6',
            seq: 4,
            name: 'Loud',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd7',
            seq: 5,
            name: 'Soft',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd8',
            seq: 6,
            name: 'Slurred',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffd9',
            seq: 7,
            name: 'Abnormal prosody',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871037c29c698907ffda',
            seq: 8,
            name: 'Increased latency',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871137c29c698907ffdb',
            seq: 9,
            name: 'Dysarthria',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871137c29c698907ffdc',
            seq: 10,
            name: 'Poverty of speech',
            section_name: 'speech',
            section: 3,
            is_free_text: false,
            section_text: 'Speech',
        },
        {
            _id: '61de871137c29c698907ffdd',
            seq: 11,
            name: 'Other',
            section_name: 'speech',
            section: 3,
            is_free_text: true,
            section_text: 'Speech',
        },
        {
            _id: '61de877237c29c698907ffde',
            seq: 1,
            name: 'Euthymic',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffdf',
            seq: 2,
            name: 'Sad/Depressed',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe0',
            seq: 3,
            name: 'Elated/Euphoric',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe1',
            seq: 4,
            name: 'Anxious',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe2',
            seq: 5,
            name: 'Irritable',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe3',
            seq: 6,
            name: 'Labile',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe4',
            seq: 7,
            name: 'Apathy',
            section_name: 'mood',
            section: 4,
            is_free_text: false,
            section_text: 'Mood',
        },
        {
            _id: '61de877337c29c698907ffe5',
            seq: 8,
            name: 'Other',
            section_name: 'mood',
            section: 4,
            is_free_text: true,
            section_text: 'Mood',
        },
        {
            _id: '61de87d237c29c698907ffe6',
            seq: 1,
            name: 'Mood congruent',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffe7',
            seq: 2,
            name: 'Normal range and modulation',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffe8',
            seq: 3,
            name: 'Flat/Blunted',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffe9',
            seq: 4,
            name: 'Restricted range',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffea',
            seq: 5,
            name: 'Fearful/Anxious',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffeb',
            seq: 6,
            name: 'Sad/Depressed',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffec',
            seq: 7,
            name: 'Embarrassment/Shame',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d237c29c698907ffed',
            seq: 8,
            name: 'Hostile/Aggressive',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d337c29c698907ffee',
            seq: 9,
            name: 'Anger/Rage',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d337c29c698907ffef',
            seq: 10,
            name: 'Guilty',
            section_name: 'affect',
            section: 5,
            is_free_text: false,
            section_text: 'Affect',
        },
        {
            _id: '61de87d337c29c698907fff0',
            seq: 11,
            name: 'Other',
            section_name: 'affect',
            section: 5,
            is_free_text: true,
            section_text: 'Affect',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c08',
            seq: 1,
            name: 'Goal directed',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c09',
            seq: 2,
            name: 'Organized',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0a',
            seq: 3,
            name: 'Logical',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0b',
            seq: 4,
            name: 'Linear',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0c',
            seq: 5,
            name: 'Tangential',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0d',
            seq: 6,
            name: 'Circumstantial',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0e',
            seq: 7,
            name: 'Disorganized',
            section: 6,
            section_name: 'thought_process',
            is_free_text: false,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc7f949fc9bdb7eab0c0f',
            seq: 8,
            name: 'Other',
            section: 6,
            section_name: 'thought_process',
            is_free_text: true,
            section_text: 'Thought process',
        },
        {
            _id: '61dfc85549fc9bdb7eab0c10',
            seq: 1,
            name: 'No abnormal content',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c11',
            seq: 2,
            name: 'Future oriented',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c12',
            seq: 3,
            name: 'Hopeless',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c13',
            seq: 4,
            name: 'Helpless',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c14',
            seq: 5,
            name: 'Si',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c15',
            seq: 6,
            name: 'Hi',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c16',
            seq: 7,
            name: 'No si',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c17',
            seq: 8,
            name: 'No hi',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c18',
            seq: 9,
            name: 'Inflated confidence/Grandiose',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c19',
            seq: 10,
            name: 'Poor confidence/Esteem',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c1a',
            seq: 11,
            name: 'Obsessions/Compulsions',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c1b',
            seq: 12,
            name: 'Phobias',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85649fc9bdb7eab0c1c',
            seq: 13,
            name: 'Poor body image',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c1d',
            seq: 14,
            name: 'Delusional',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c1e',
            seq: 15,
            name: 'Hallucinations',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c1f',
            seq: 16,
            name: 'Paranoia/Suspicious',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c20',
            seq: 17,
            name: 'Ruminations',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c21',
            seq: 18,
            name: 'Perseverations',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c22',
            seq: 19,
            name: 'Poverty of content',
            section: 7,
            section_name: 'thought_content',
            is_free_text: false,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc85749fc9bdb7eab0c23',
            seq: 20,
            name: 'Other',
            section: 7,
            section_name: 'thought_content',
            is_free_text: true,
            section_text: 'Thought content',
        },
        {
            _id: '61dfc8b549fc9bdb7eab0c24',
            seq: 1,
            name: 'Intact',
            section: 8,
            section_name: 'insight_judgement',
            is_free_text: false,
            section_text: 'Insight/Judgment',
        },
        {
            _id: '61dfc8b549fc9bdb7eab0c25',
            seq: 2,
            name: 'Poor insight',
            section: 8,
            section_name: 'insight_judgement',
            is_free_text: false,
            section_text: 'Insight/Judgment',
        },
        {
            _id: '61dfc8b549fc9bdb7eab0c26',
            seq: 3,
            name: 'Impaired judgement',
            section: 8,
            section_name: 'insight_judgement',
            is_free_text: false,
            section_text: 'Insight/Judgment',
        },
        {
            _id: '61dfc8b549fc9bdb7eab0c27',
            seq: 4,
            name: 'Poor impulse control',
            section: 8,
            section_name: 'insight_judgement',
            is_free_text: false,
            section_text: 'Insight/Judgment',
        },
        {
            _id: '61dfc8b549fc9bdb7eab0c28',
            seq: 5,
            name: 'Other',
            section: 8,
            section_name: 'insight_judgement',
            is_free_text: true,
            section_text: 'Insight/Judgment',
        },
        {
            _id: '61dfc98649fc9bdb7eab0c29',
            seq: 1,
            name: 'Alert / awake',
            section: 9,
            section_name: 'consciousness',
            is_free_text: false,
            section_text: 'Consciousness',
        },
        {
            _id: '61dfc98649fc9bdb7eab0c2a',
            seq: 2,
            name: 'Drowsy',
            section: 9,
            section_name: 'consciousness',
            is_free_text: false,
            section_text: 'Consciousness',
        },
        {
            _id: '61dfc98649fc9bdb7eab0c2b',
            seq: 3,
            name: 'Fluctuating',
            section: 9,
            section_name: 'consciousness',
            is_free_text: false,
            section_text: 'Consciousness',
        },
        {
            _id: '61dfc98649fc9bdb7eab0c2c',
            seq: 4,
            name: 'Other',
            section: 9,
            section_name: 'consciousness',
            is_free_text: true,
            section_text: 'Consciousness',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c37',
            seq: 1,
            name: 'Ox4',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c38',
            seq: 2,
            name: 'Ox3',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c39',
            seq: 3,
            name: 'Time',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c3a',
            seq: 4,
            name: 'Place',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c3b',
            seq: 5,
            name: 'Person',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c3c',
            seq: 6,
            name: 'Situation',
            section: 10,
            section_name: 'orientation',
            is_free_text: false,
            section_text: 'Orientation',
        },
        {
            _id: '61dfca3149fc9bdb7eab0c3d',
            seq: 7,
            name: 'Impaired',
            section: 10,
            section_name: 'orientation',
            is_free_text: true,
            section_text: 'Orientation',
        },
        {
            _id: '61dfce1c49fc9bdb7eab0c42',
            seq: 1,
            name: 'Intact',
            section: 11,
            is_free_text: false,
            section_name: 'recent_memory',
            section_text: 'Recent Memory',
        },
        {
            _id: '61dfce1c49fc9bdb7eab0c43',
            seq: 2,
            name: '3/3 recall',
            section: 11,
            is_free_text: false,
            section_name: 'recent_memory',
            section_text: 'Recent Memory',
        },
        {
            _id: '61dfce1c49fc9bdb7eab0c44',
            seq: 3,
            name: '4/4 recall',
            section: 11,
            is_free_text: false,
            section_name: 'recent_memory',
            section_text: 'Recent Memory',
        },
        {
            _id: '61dfce1c49fc9bdb7eab0c45',
            seq: 4,
            name: 'Impaired recall',
            section: 11,
            is_free_text: true,
            section_name: 'recent_memory',
            section_text: 'Recent Memory',
        },
        {
            _id: '61dfce1c49fc9bdb7eab0c46',
            seq: 5,
            name: 'Other',
            section: 11,
            is_free_text: true,
            section_name: 'recent_memory',
            section_text: 'Recent Memory',
        },
        {
            _id: '61dfcf9b49fc9bdb7eab0c47',
            seq: 1,
            name: 'Intact',
            section: 12,
            is_free_text: false,
            section_name: 'remote_memory',
            section_text: 'Remote Memory',
        },
        {
            _id: '61dfcf9b49fc9bdb7eab0c48',
            seq: 2,
            name: 'Impaired',
            section: 12,
            is_free_text: true,
            section_name: 'remote_memory',
            section_text: 'Remote Memory',
        },
        {
            _id: '61dfcf9b49fc9bdb7eab0c49',
            seq: 3,
            name: 'Other',
            section: 12,
            is_free_text: true,
            section_name: 'remote_memory',
            section_text: 'Remote Memory',
        },
        {
            _id: '61dfcfe949fc9bdb7eab0c4a',
            seq: 1,
            name: 'Intact',
            section: 13,
            is_free_text: false,
            section_name: 'attention',
            section_text: 'Attention/Conc',
            schema_field: 'attention',
        },
        {
            _id: '61dfcfe949fc9bdb7eab0c4b',
            seq: 2,
            name: '3/3 registration',
            section: 13,
            is_free_text: false,
            section_name: 'attention',
            section_text: 'Attention/Conc',
            schema_field: 'attention',
        },
        {
            _id: '61dfcfe949fc9bdb7eab0c4c',
            seq: 3,
            name: '4/4 registration',
            section: 13,
            is_free_text: false,
            section_name: 'attention',
            section_text: 'Attention/Conc',
            schema_field: 'attention',
        },
        {
            _id: '61dfcfe949fc9bdb7eab0c4d',
            seq: 4,
            name: 'Impaired attention',
            section: 13,
            is_free_text: true,
            section_name: 'attention',
            section_text: 'Attention/Conc',
            schema_field: 'attention',
        },
        {
            _id: '61dfcfe949fc9bdb7eab0c4e',
            seq: 5,
            name: 'Other',
            section: 13,
            is_free_text: true,
            section_name: 'attention',
            section_text: 'Attention/Conc',
            schema_field: 'attention',
        },
        {
            _id: '61e667e2f47a16b8c37057be',
            name: 'Intact',
            section: 14,
            seq: 1,
            section_name: 'naming_language',
            is_free_text: false,
            section_text: 'Language (Naming)',
            schema_field: 'naming_language',
        },
        {
            _id: '61e667e2f47a16b8c37057bf',
            name: '3/3 objects',
            section: 14,
            seq: 2,
            section_name: 'naming_language',
            is_free_text: false,
            section_text: 'Language (Naming)',
            schema_field: 'naming_language',
        },
        {
            _id: '61e667e2f47a16b8c37057c0',
            name: 'Impaired',
            section: 14,
            seq: 3,
            section_name: 'naming_language',
            is_free_text: true,
            section_text: 'Language (Naming)',
            schema_field: 'naming_language',
        },
        {
            _id: '61e667e3f47a16b8c37057c1',
            name: 'Other',
            section: 14,
            seq: 4,
            section_name: 'naming_language',
            is_free_text: true,
            section_text: 'Language (Naming)',
            schema_field: 'naming_language',
        },
        {
            _id: '61e66864f47a16b8c37057c2',
            name: 'Intact:',
            section: 15,
            seq: 1,
            section_name: 'repeating_phrase_language',
            is_free_text: false,
            section_text: 'Language (Repeating Phrase)',
            schema_field: 'repeating_phrase_language',
        },
        {
            _id: '61e66864f47a16b8c37057c3',
            name: "Intact (no if's, and's, or but's)",
            section: 15,
            seq: 2,
            section_name: 'repeating_phrase_language',
            is_free_text: false,
            section_text: 'Language (Repeating Phrase)',
            schema_field: 'repeating_phrase_language',
        },
        {
            _id: '61e66864f47a16b8c37057c4',
            name: 'Impaired',
            section: 15,
            seq: 3,
            section_name: 'repeating_phrase_language',
            is_free_text: true,
            section_text: 'Language (Repeating Phrase)',
            schema_field: 'repeating_phrase_language',
        },
        {
            _id: '61e66864f47a16b8c37057c5',
            name: 'Other',
            section: 15,
            seq: 4,
            section_name: 'repeating_phrase_language',
            is_free_text: true,
            section_text: 'Language (Repeating Phrase)',
            schema_field: 'repeating_phrase_language',
        },
        {
            _id: '61e66a24f47a16b8c37057c9',
            name: 'Abstract',
            section: 16,
            seq: 1,
            section_name: 'abstraction_language',
            is_free_text: true,
            section_text: 'Language (Abstraction)',
            schema_field: 'abstraction_language',
        },
        {
            _id: '61e66a24f47a16b8c37057ca',
            name: 'Concrete',
            section: 16,
            seq: 2,
            section_name: 'abstraction_language',
            is_free_text: true,
            section_text: 'Language (Abstraction)',
            schema_field: 'abstraction_language',
        },
        {
            _id: '61e66a25f47a16b8c37057cb',
            name: 'Other',
            section: 16,
            seq: 3,
            section_name: 'abstraction_language',
            is_free_text: true,
            section_text: 'Language (Abstraction)',
            schema_field: 'abstraction_language',
        },
        {
            _id: '61e66b1cf47a16b8c37057cbc',
            name: 'Excellent',
            section: 17,
            seq: 1,
            section_name: 'fund_of_knowledge',
            is_free_text: false,
            section_text: 'Fund of Knowledge',
            schema_field: 'fund_of_knowledge',
        },
        {
            _id: '61e66b1cf47a16b8c37057cc',
            name: 'Average',
            section: 17,
            seq: 1,
            section_name: 'fund_of_knowledge',
            is_free_text: false,
            section_text: 'Fund of Knowledge',
            schema_field: 'fund_of_knowledge',
        },
        {
            _id: '61e66b1cf47a16b8c37057cd',
            name: 'Poor as evidenced by',
            section: 17,
            seq: 2,
            section_name: 'fund_of_knowledge',
            is_free_text: true,
            section_text: 'Fund of Knowledge',
            schema_field: 'fund_of_knowledge',
        },
        {
            _id: '61e66c60f47a16b8c37057ce',
            name: 'Nl',
            section: 18,
            seq: 1,
            section_name: 'station',
            is_free_text: false,
            section_text: 'Gait / Station',
            schema_field: 'station',
        },
        {
            _id: '61e66c60f47a16b8c37057cf',
            name: 'Impaired',
            section: 18,
            seq: 2,
            section_name: 'station',
            is_free_text: true,
            section_text: 'Gait / Station',
            schema_field: 'station',
        },
        {
            _id: '61e66cf7f47a16b8c37057d0',
            name: 'Nl',
            section: 19,
            seq: 1,
            section_name: 'muscle_strength',
            is_free_text: false,
            section_text: 'Muscle Strength',
        },
        {
            _id: '61e66cf7f47a16b8c37057d1',
            name: 'Weak',
            section: 19,
            seq: 2,
            section_name: 'muscle_strength',
            is_free_text: true,
            section_text: 'Muscle Strength',
        },
        {
            _id: '61e66cf7f47a16b8c37057d2',
            name: 'Other',
            section: 19,
            seq: 3,
            section_name: 'muscle_strength',
            is_free_text: true,
            section_text: 'Muscle Strength',
        },
        {
            _id: '61e66d2af47a16b8c37057d3',
            name: 'Nl',
            section: 20,
            seq: 1,
            section_name: 'muscle_tone',
            is_free_text: false,
            section_text: 'Muscle Tone',
        },
        {
            _id: '61e66d2af47a16b8c37057d4',
            name: 'Flaccid',
            section: 20,
            seq: 2,
            section_name: 'muscle_tone',
            is_free_text: false,
            section_text: 'Muscle Tone',
        },
        {
            _id: '61e66d2af47a16b8c37057d5',
            name: 'Cog wheel',
            section: 20,
            seq: 3,
            section_name: 'muscle_tone',
            is_free_text: false,
            section_text: 'Muscle Tone',
        },
        {
            _id: '61e66d2af47a16b8c37057d6',
            name: 'Spastic',
            section: 20,
            seq: 4,
            section_name: 'muscle_tone',
            is_free_text: false,
            section_text: 'Muscle Tone',
        },
        {
            _id: '61e66d2af47a16b8c37057d7',
            name: 'Other',
            section: 20,
            seq: 5,
            section_name: 'muscle_tone',
            is_free_text: true,
            section_text: 'Muscle Tone',
        },
    ],
    suicideRiskFactorMasters: [
        {
            _id: '61ded93937c29c6989080032',
            seq: 1,
            name: 'Prior attempt',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080033',
            seq: 2,
            name: 'Current attempt',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080034',
            seq: 3,
            name: 'History of medically serious attempt',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080035',
            seq: 4,
            name: 'Recent psychiatric hospital discharge',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080036',
            seq: 5,
            name: 'Recent Loss (particularly interpersonal or fall in social status)',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080037',
            seq: 6,
            name: 'Currently diagnosed with Major Depression',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080038',
            seq: 7,
            name: 'Currently diminished concentration or indecision [Cognitive Impairment]',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c6989080039',
            seq: 8,
            name: 'Current sleep problems',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c698908003a',
            seq: 9,
            name: 'Currently experiencing hopelessness',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c698908003b',
            seq: 10,
            name: 'Currently experiencing panic or significant anxiety',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93937c29c698908003c',
            seq: 12,
            name: 'Currently diagnosed with Borderline Personality Disorder',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c698908003d',
            seq: 13,
            name: 'Current ETOH or drug use',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c698908003e',
            seq: 14,
            name: 'History of impulsivity',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c698908003f',
            seq: 15,
            name: 'Intense level of agitation',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080040',
            seq: 15,
            name: 'Actively making death arrangements (updated will, suicide note, recently purchased life insurance, giving away possessions, etc)',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080041',
            seq: 16,
            name: 'Lethal methods available or easily obtained',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080042',
            seq: 17,
            name: 'Likely to be alone; currently socially isolated',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080043',
            seq: 18,
            name: 'Family member committed suicide',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080044',
            seq: 19,
            name: 'History of childhood sexual abuse',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080045',
            seq: 20,
            name: 'Unemployed',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080046',
            seq: 21,
            name: 'Financial Strain',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '61ded93a37c29c6989080047',
            seq: 22,
            name: 'Physical illness',
            group_type: 'suicide_risk_factors',
        },
        {
            _id: '620f8337b23e34ade64af530',
            seq: 11,
            name: 'Psychotic symptoms or underlying thought disorder or loss of rational thought (i.e. dementia)',
            group_type: 'suicide_risk_factors',
        },
    ],
    suicideProtectiveMasters: [
        {
            _id: '61ded99337c29c6989080048',
            seq: 1,
            name: 'Actively making future plans',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99337c29c6989080049',
            seq: 2,
            name: 'Verbalizes hope for the future',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004a',
            seq: 3,
            name: 'Displays self-efficacy in problem area',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004b',
            seq: 4,
            name: 'Shows attachment to life',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004c',
            seq: 5,
            name: 'Has responsibilities to kids, family, others',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004d',
            seq: 6,
            name: 'Embedded in protective social network/family',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004e',
            seq: 7,
            name: 'Attached to therapy and at least one therapist',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c698908004f',
            seq: 8,
            name: 'Belief that suicide is immoral or will be punished (is religious, particularly Catholic)',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c6989080050',
            seq: 9,
            name: 'Hopeful that current treatment direction will be effective',
            group_type: 'suicide_protective_factors',
        },
        {
            _id: '61ded99437c29c6989080051',
            seq: 10,
            name: 'Taking steps to engage in treatment',
            group_type: 'suicide_protective_factors',
        },
    ],
    suicidePreventationMasters: [
        {
            _id: '61dedc1937c29c6989080052',
            seq: 1,
            name: 'Remove lethal means',
            group_type: 'suicide_preventive_plan',
            section_name: 'address_immense_risk',
            section: 1,
            section_text: 'Addressing Imminent Risk',
            schema_field: 'address_immense_risk',
        },
        {
            _id: '61dedc1937c29c6989080053',
            seq: 2,
            name: 'Patient has someone living with them',
            group_type: 'suicide_preventive_plan',
            section_name: 'address_immense_risk',
            section: 1,
            section_text: 'Addressing Imminent Risk',
            schema_field: 'address_immense_risk',
        },
        {
            _id: '61dedc1937c29c6989080054',
            seq: 3,
            name: 'Patient has someone checking in with them regularly',
            group_type: 'suicide_preventive_plan',
            section_name: 'address_immense_risk',
            section: 1,
            section_text: 'Addressing Imminent Risk',
            schema_field: 'address_immense_risk',
        },
        {
            _id: '61dedc2d37c29c6989080055',
            seq: 1,
            name: 'Patient is receiving treatment for depression',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2d37c29c6989080056',
            seq: 2,
            name: 'Patient is receiving treatment for borderline personality',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2d37c29c6989080057',
            seq: 3,
            name: 'Refer patient to treatment for borderline personality',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2d37c29c6989080058',
            seq: 4,
            name: 'Reduced/Removed aggravating factors for alcohol/drug abuse',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2d37c29c6989080059',
            seq: 5,
            name: 'Patient has received medication prescription for alcohol/drug abuse',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2d37c29c698908005a',
            seq: 6,
            name: 'Patient has received behavior/cognitive/coping prescriptions for alcohol/drug abuse',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc2e37c29c698908005b',
            seq: 7,
            name: 'Refer patient to treatment for alcohol/drug abuse',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_diag',
            section: 2,
            section_text: 'Addressing Diagnoses',
            schema_field: 'addressing_diag',
        },
        {
            _id: '61dedc3b37c29c698908005c',
            seq: 1,
            name: 'Reduced/Removed aggravating factors for insomnia',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3b37c29c698908005d',
            seq: 2,
            name: 'Patient has received medication prescription for insomnia',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c698908005e',
            seq: 3,
            name: 'Patient has received behavior/cognitive/coping prescriptions for insomnia',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c698908005f',
            seq: 4,
            name: 'Reduced/Removed aggravating factors for anxiety/agitation/panic',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080060',
            seq: 5,
            name: 'Patient has received medication prescription for anxiety/agitation/panic',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080061',
            seq: 6,
            name: 'Patient has received behavior/cognitive/coping prescriptions for anxiety/agitation/panic',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080062',
            seq: 7,
            name: 'Reduced/Removed aggravating factors for impulsivity',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080063',
            seq: 8,
            name: 'Patient has received medication prescription for impulsivity',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080064',
            seq: 9,
            name: 'Patient has received behavior/cognitive/coping prescriptions for impulsivity',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080065',
            seq: 10,
            name: 'Reduced/Removed aggravating factors for psychosis',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080066',
            seq: 11,
            name: 'Patient has received medication prescription for psychosis',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080067',
            seq: 12,
            name: 'Patient has received behavior/cognitive/coping prescriptions for psychosis',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080068',
            seq: 13,
            name: 'Reduced/Removed aggravating factors for concentration',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c6989080069',
            seq: 14,
            name: 'Patient has received medication prescription for concentration',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3c37c29c698908006a',
            seq: 15,
            name: 'Patient has received behavior/cognitive/coping prescriptions for concentration',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc3d37c29c698908006b',
            seq: 16,
            name: 'Reduced/Removed aggravating factors for hopelessness',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_symptoms',
            section: 3,
            section_text: 'Addressing Symptoms',
        },
        {
            _id: '61dedc4b37c29c698908006c',
            seq: 1,
            name: 'Patient has received behavior/cognitive/coping prescriptions for loss',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_social_factors',
            section: 4,
            section_text: 'Addressing Social Factors',
            schema_field: 'addressing_social_factors',
        },
        {
            _id: '61dedc4b37c29c698908006d',
            seq: 2,
            name: 'Provided problem solving strategies for social isolation',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_social_factors',
            section: 4,
            section_text: 'Addressing Social Factors',
            schema_field: 'addressing_social_factors',
        },
        {
            _id: '61dedc4b37c29c698908006e',
            seq: 3,
            name: 'Provided problem solving strategies for financial strain',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_social_factors',
            section: 4,
            section_text: 'Addressing Social Factors',
            schema_field: 'addressing_social_factors',
        },
        {
            _id: '61dedc4b37c29c698908006f',
            seq: 4,
            name: 'Provided problem solving strategies for unemployment',
            group_type: 'suicide_preventive_plan',
            section_name: 'addressing_social_factors',
            section: 4,
            section_text: 'Addressing Social Factors',
            schema_field: 'addressing_social_factors',
        },
    ],
    assessmentSourceRiskMasters: [
        {
            _id: '61df566e49fc9bdb7eab0b8f',
            seq: 1,
            name: 'No evidence of acute risk of harm to self or others',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566e49fc9bdb7eab0b90',
            seq: 2,
            name: 'Suicidal ideation',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566e49fc9bdb7eab0b91',
            seq: 3,
            name: 'Risk of violence',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566e49fc9bdb7eab0b92',
            seq: 4,
            name: 'Risk of functional impairment with threat of job loss or school failure',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566e49fc9bdb7eab0b93',
            seq: 5,
            name: 'Risk of medical illness exacerbation due to psychiatric illness',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566f49fc9bdb7eab0b94',
            seq: 6,
            name: 'Risk of traumatic victimization due to psychiatric illness',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566f49fc9bdb7eab0b95',
            seq: 7,
            name: 'Risk of substance abuse relapse',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
        {
            _id: '61df566f49fc9bdb7eab0b96',
            seq: 8,
            name: 'Risk of family disruption or deterioration',
            group_type: 'assessment_sources_of_risk',
            section_name: '',
        },
    ],
    assInformedConsentMasters: [
        {
            _id: '61df57f749fc9bdb7eab0b97',
            seq: 1,
            name: 'Patient',
            is_free_text: false,
            section: 1,
            section_name: 'treat_consent_given_by',
            schema_field: 'treat_consent_given_by',
        },
        {
            _id: '61df57f749fc9bdb7eab0b98',
            seq: 2,
            name: 'Caregiver',
            is_free_text: true,
            section: 1,
            section_name: 'treat_consent_given_by',
            schema_field: 'treat_consent_given_by',
        },
        {
            _id: '61df57f749fc9bdb7eab0b99',
            seq: 3,
            name: 'Other',
            is_free_text: true,
            section: 1,
            section_name: 'treat_consent_given_by',
            schema_field: 'treat_consent_given_by',
        },
        {
            _id: '61df586b49fc9bdb7eab0b9a',
            seq: 1,
            name: 'A discussion of the limits of confidentiality',
            is_free_text: true,
            section: 2,
            section_name: 'confidentiality_disc',
            schema_field: 'confidentiality_disc',
        },
        {
            _id: '61df586b49fc9bdb7eab0b9b',
            seq: 2,
            name: 'Privacy policies',
            is_free_text: false,
            section: 2,
            section_name: 'confidentiality_disc',
            schema_field: 'confidentiality_disc',
        },
        {
            _id: '61df586b49fc9bdb7eab0b9c',
            seq: 3,
            name: 'Clinic policies, including cancellation policies',
            is_free_text: false,
            section: 2,
            section_name: 'confidentiality_disc',
            schema_field: 'confidentiality_disc',
        },
        {
            _id: '61df586b49fc9bdb7eab0b9d',
            seq: 4,
            name: 'A discussion of the benefits and risks of medication including precautions and potential side effects and/or adverse reactions:',
            is_free_text: false,
            section: 2,
            section_name: 'confidentiality_disc',
            schema_field: 'confidentiality_disc',
        },
        {
            _id: '61df594e49fc9bdb7eab0b9e',
            seq: 1,
            name: 'Common side effects',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0b9f',
            seq: 2,
            name: 'Allergic reactions',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba0',
            seq: 3,
            name: 'Bleeding',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba1',
            seq: 4,
            name: 'Cardiac arrhythmia',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba2',
            seq: 5,
            name: 'Contraception (importance, type, and medication interactions)',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba3',
            seq: 6,
            name: 'Effects on renal function',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba4',
            seq: 7,
            name: 'EPS',
            noChangeNameStyle: true,
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba5',
            seq: 8,
            name: 'Hepatic toxicity',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba6',
            seq: 9,
            name: 'Hypertension',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba7',
            seq: 10,
            name: 'Lithium toxicity',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba8',
            seq: 11,
            name: 'Medication-induced mania',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df594f49fc9bdb7eab0ba9',
            seq: 12,
            name: 'Medication risk to infant during breastfeeding',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0baa',
            seq: 13,
            name: 'Metabolic syndrome',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bab',
            seq: 14,
            name: 'Neutropenia',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bac',
            seq: 15,
            name: 'Orthostatic hypotension',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bad',
            seq: 16,
            name: 'Parathyroid effects',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bae',
            seq: 17,
            name: 'Polycystic ovary',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0baf',
            seq: 18,
            name: 'Priapism',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb0',
            seq: 19,
            name: 'Risks during lactation',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb1',
            seq: 20,
            name: 'Risks during pregnancy',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb2',
            seq: 21,
            name: 'Seizures',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb3',
            seq: 22,
            name: 'Sexual dysfunction',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb4',
            seq: 23,
            name: 'Stevens-Johnson syndrome',
            noChangeNameStyle: true,
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595049fc9bdb7eab0bb5',
            seq: 24,
            name: 'Suicidality',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595149fc9bdb7eab0bb6',
            seq: 25,
            name: 'Tardive dyskinesia',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595149fc9bdb7eab0bb7',
            seq: 26,
            name: 'Teratogenic side effects',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595149fc9bdb7eab0bb8',
            seq: 27,
            name: 'Thyroid problems',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },

        {
            _id: '61df595149fc9bdb7eab0bb9',
            seq: 28,
            name: 'Potential for abuse',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595149fc9bdb7eab0bc0',
            seq: 29,
            name: 'Dependents',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
        {
            _id: '61df595149fc9bdb7eab0bc1',
            seq: 30,
            name: 'Caution when driving',
            is_free_text: false,
            section: 3,
            section_name: 'informed_consent_opt',
            schema_field: 'informed_consent_opt',
        },
    ],
    counsellingMasters: [
        {
            _id: '6203b1644ae9797ddc2f84ed',
            name: 'Greater than 50% of session was spent on counseling and/or coordination of care',
            seq: 1,
            section: 1,
            is_free_text: false,
            section_name: 'counselling',
        },
        {
            _id: '6203b1644ae9797ddc2f84ee',
            name: 'Patient/Caregiver is aware of how to contact clinical providers if concerns arise',
            seq: 2,
            section: 1,
            is_free_text: false,
            section_name: 'counselling',
        },
        {
            _id: '61df5b7849fc9bdb7eab0bb9',
            seq: 1,
            name: 'Effectiveness',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7849fc9bdb7eab0bba',
            seq: 2,
            name: 'Side effects',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7949fc9bdb7eab0bbb',
            seq: 3,
            name: 'Dosing range',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7949fc9bdb7eab0bbc',
            seq: 4,
            name: 'Duration',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7949fc9bdb7eab0bbd',
            seq: 5,
            name: 'Drug interaction',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7949fc9bdb7eab0bbe',
            seq: 6,
            name: 'Adherence',
            is_free_text: false,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5b7949fc9bdb7eab0bbf',
            seq: 7,
            name: 'Other',
            is_free_input: true,
            is_free_text: true,
            section: 2,
            section_name: 'medication_management',
            section_text: 'Medication Management Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc0',
            seq: 1,
            name: 'Accuracy of diagnosis',
            is_free_text: false,
            section: 3,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc1',
            seq: 2,
            name: 'Prognosis over time',
            is_free_text: false,
            section: 3,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc2',
            seq: 3,
            name: 'Impact of diagnosis on life functions',
            is_free_text: false,
            section: 3,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc3',
            seq: 4,
            name: 'Impact of family relationship',
            is_free_text: false,
            section: 3,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc4',
            seq: 5,
            name: 'Problematic behaviors secondary to diagnosis',
            is_free_text: false,
            section: 2,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc5',
            seq: 6,
            name: 'Adequacy of current interventions',
            is_free_text: false,
            section: 2,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5bb249fc9bdb7eab0bc6',
            seq: 7,
            name: 'Other',
            is_free_input: true,
            is_free_text: true,
            section: 2,
            section_name: 'diagnosis_and_prog',
            section_text: 'Diagnosis & Prognosis Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bc7',
            seq: 1,
            name: '12 Step program',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bc8',
            seq: 2,
            name: 'Other self-help group',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bc9',
            seq: 3,
            name: 'Self-help workbook',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bca',
            seq: 4,
            name: 'Breathing',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bcb',
            seq: 5,
            name: 'Exercise',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bcc',
            seq: 6,
            name: 'Light exposure',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bcd',
            seq: 7,
            name: 'Maintenance of social rhythms',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0149fc9bdb7eab0bce',
            seq: 8,
            name: 'Medical illness treatment adherence',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bcf',
            seq: 9,
            name: 'Mindfulness',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd0',
            seq: 10,
            name: 'Moderation of caffeine and alcohol intake',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd1',
            seq: 11,
            name: 'Muscle relaxation',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd2',
            seq: 12,
            name: 'Nutrition education and improvement',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd3',
            seq: 13,
            name: 'Sleep hygiene',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd4',
            seq: 14,
            name: 'Substance abuse treatment adherence',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd5',
            seq: 15,
            name: 'Anger management',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd6',
            seq: 16,
            name: 'Behavioral activation',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd7',
            seq: 17,
            name: 'Cognitive restructuring',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd8',
            seq: 18,
            name: 'Exposure',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bd9',
            seq: 19,
            name: 'General coping skills',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0249fc9bdb7eab0bda',
            seq: 20,
            name: 'Grief counseling',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0349fc9bdb7eab0bdb',
            seq: 21,
            name: 'Organizational skills and time management training',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0349fc9bdb7eab0bdc',
            seq: 22,
            name: 'Parenting skills',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0349fc9bdb7eab0bdd',
            seq: 23,
            name: 'Problem solving',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0349fc9bdb7eab0bde',
            seq: 24,
            name: 'Social skills training',
            is_free_text: false,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c0349fc9bdb7eab0bdf',
            seq: 25,
            name: 'Other',
            is_free_input: true,
            is_free_text: true,
            section: 3,
            section_name: 'patient_self_mgmt',
            section_text: 'Patient Self-Management Counseling',
        },
        {
            _id: '61df5c2e49fc9bdb7eab0be0',
            seq: 1,
            name: 'Problems with primary support group',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be1',
            seq: 2,
            name: 'Problems related to the social environment',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be2',
            seq: 3,
            name: 'Educational problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be3',
            seq: 4,
            name: 'Occupational problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be4',
            seq: 5,
            name: 'Housing problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be5',
            seq: 6,
            name: 'Economic problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be6',
            seq: 7,
            name: 'Problems with access to health care services',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be7',
            seq: 8,
            name: 'Medical illness problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be8',
            seq: 9,
            name: 'Problems related interaction with the legal system/crime',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0be9',
            seq: 10,
            name: 'Other psychosocial and environmental problems',
            is_free_text: false,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c2f49fc9bdb7eab0bea',
            seq: 11,
            name: 'Other',
            is_free_input: true,
            is_free_text: true,
            section: 4,
            section_name: 'recent_stressors',
            section_text: 'Recent Stressors Counseling',
        },
        {
            _id: '61df5c5749fc9bdb7eab0beb',
            seq: 1,
            name: "Coordination of care with patient's psychotherapist",
            label: 'psychotherapist',
            is_free_text: false,
            section: 5,
            section_name: 'coordination_care',
            section_text: 'Coordination of Care',
        },
        {
            _id: '61df5c5749fc9bdb7eab0bec',
            seq: 2,
            name: "Coordination of care with patient's physician",
            label: 'physician',
            is_free_text: false,
            section: 5,
            section_name: 'coordination_care',
            section_text: 'Coordination of Care',
        },
        {
            _id: '61df5c5749fc9bdb7eab0bed',
            seq: 3,
            name: "Coordination of care with patient's chemical dependency counselor/program",
            label: 'chemical dependency counselor/program',
            is_free_text: false,
            section: 5,
            section_name: 'coordination_care',
            section_text: 'Coordination of Care',
        },
        {
            _id: '61df5c8a49fc9bdb7eab0bee',
            seq: 1,
            name: 'Individual or other therapy',
            is_free_text: true,
            section: 6,
            section_name: 'referred_patient',
            section_text: 'Referred patient/caregiver to',
        },
        {
            _id: '61df5c8a49fc9bdb7eab0bef',
            seq: 2,
            name: 'Other programs and/or activities',
            is_free_text: true,
            section: 6,
            section_name: 'referred_patient',
            section_text: 'Referred patient/caregiver to',
        },
        {
            _id: '61df5c8a49fc9bdb7eab0bf0',
            seq: 3,
            name: 'Medical provider for additional medical treatment and/or workup',
            is_free_text: true,
            section: 6,
            section_name: 'referred_patient',
            section_text: 'Referred patient/caregiver to',
        },
        {
            _id: '61df5c8a49fc9bdb7eab0bf1',
            seq: 4,
            name: 'Other resources',
            is_free_text: true,
            is_free_input: true,
            section: 6,
            section_name: 'referred_patient',
            section_text: 'Referred patient/caregiver to',
        },
        {
            _id: '61df5cfa49fc9bdb7eab0bf2',
            seq: 1,
            name: 'IEP',
            is_free_text: false,
            section: 7,
            section_name: 'academic_assesment',
            section_text: 'Additional academic assessment and/or supports',
        },
        {
            _id: '61df5cfa49fc9bdb7eab0bf3',
            seq: 2,
            name: '504',
            is_free_text: false,
            section: 7,
            section_name: 'academic_assesment',
            section_text: 'Additional academic assessment and/or supports',
        },
        {
            _id: '61df5cfa49fc9bdb7eab0bf4',
            seq: 3,
            name: 'Tutoring',
            is_free_text: false,
            section: 7,
            section_name: 'academic_assesment',
            section_text: 'Additional academic assessment and/or supports',
        },
        {
            _id: '61df5c8a49fc9bdb7eab0bf1433',
            seq: 4,
            name: '',
            is_free_text: true,
            is_free_input: true,
            section: 7,
            section_name: 'academic_assesment',
            section_text: 'Additional academic assessment and/or supports',
        },
    ],
    planMasters: [
        {
            _id: '61dec67a37c29c6989080017',
            seq: 1,
            name: 'Continue current medication regimen',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c6989080018',
            seq: 2,
            name: 'Optimize dosage (decrease)',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c6989080019',
            seq: 3,
            name: 'Optimize dosage (increase)',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001a',
            seq: 4,
            name: 'Add new medication',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001b',
            seq: 5,
            name: 'Taper medication',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001c',
            seq: 6,
            name: 'Taper and discontinue medication',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001d',
            seq: 7,
            name: 'Discontinue medication (no taper required)',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001e',
            seq: 8,
            name: 'Improve adherence',
            group_type: 'plan',
        },
        {
            _id: '61dec67a37c29c698908001f',
            seq: 9,
            name: 'Order labs',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080020',
            seq: 10,
            name: 'Continue current psychotherapy focus',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080021',
            seq: 11,
            name: 'Begin psychotherapy',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080022',
            seq: 12,
            name: 'Refer to psychotherapy',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080023',
            seq: 13,
            name: 'Change psychotherapy focus',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080024',
            seq: 14,
            name: 'Continue current counseling focus',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080025',
            seq: 15,
            name: 'Change counseling focus',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080026',
            seq: 16,
            name: 'Refer to PCP for medical evaluation',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080027',
            seq: 17,
            name: 'Contact/coordinate with outside provider',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080028',
            seq: 18,
            name: 'Obtain collateral information',
            group_type: 'plan',
        },
        {
            _id: '61dec67b37c29c6989080029',
            seq: 19,
            name: 'Refer to Psychiatrist',
            group_type: 'plan',
        },
    ],
    planMedChangesMasters: [
        {
            _id: '61dec6f037c29c698908002a',
            seq: 1,
            name: 'I-STOP Rx Search Required and Performed (NY only)',
            group_type: 'plan_med_changes',
        },
        {
            _id: '61dec6f037c29c698908002b',
            seq: 2,
            name: 'State drug registry reviewed and no evidence of misuse of controlled substances',
            group_type: 'plan_med_changes',
        },
    ],
    planNextAptMasters: [
        {
            _id: '61dec73837c29c698908002f',
            seq: 1,
            name: 'Patient will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
        {
            _id: '61dec73837c29c6989080030',
            seq: 2,
            name: 'Parent will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
        {
            _id: '61dec73837c29c6989080031',
            seq: 3,
            name: 'Caregiver will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
    ],
    psychotherapyMasters: [
        {
            _id: '61e7956af47a16b8c37057fe',
            name: 'Interpersonal Psychotherapy (IPT)',
            seq: 1,
        },
        {
            _id: '61e7956af47a16b8c37057ff',
            name: 'Family Therapy',
            seq: 2,
        },
        {
            _id: '61e7956af47a16b8c3705800',
            name: 'Supportive Therapy',
            seq: 3,
        },
        {
            _id: '61e7956af47a16b8c3705801',
            name: 'Psychodynamic Therapy',
            seq: 4,
        },
        {
            _id: '61e7956af47a16b8c3705802',
            name: 'Behavioral Activation',
            seq: 5,
        },
        {
            _id: '61e7956af47a16b8c3705803',
            name: 'Cognitive Behavioral Therapy (CBT)',
            seq: 6,
        },
        {
            _id: '61e7956af47a16b8c3705804',
            name: 'Dialectical Behavior Therapy (DBT)',
            seq: 7,
        },
        {
            _id: '61e7956af47a16b8c3705805',
            name: 'Mindfulness-based Therapy',
            seq: 8,
        },
        {
            _id: '61e7956af47a16b8c3705806',
            name: 'Problem-solving Therapy',
            seq: 9,
        },
        {
            _id: '61e7956bf47a16b8c3705807',
            name: 'Social skills training',
            seq: 10,
        },
        {
            _id: '61e7956bf47a16b8c3705808',
            name: 'Motivational interviewing',
            seq: 11,
        },
    ],
    cptCodeMasters: [
        {
            _id: '61ef9275066b6c663bc46781',
            cpt_code: '99202',
            description:
                'Office or other outpatient visit for the evaluation and management of a new patient',
        },
        {
            _id: '61ef92b4066b6c663bc46782',
            cpt_code: '99203',
            description:
                'Office or other outpatient visit for the evaluation and management of a new patient',
        },
        {
            _id: '61ef92e0066b6c663bc46784',
            cpt_code: '99212',
            description:
                'Office or other outpatient visit for the evaluation and management of a established patient',
        },
        {
            _id: '61ef92da066b6c663bc46783',
            cpt_code: '99204',
            description:
                'Office or other outpatient visit for the evaluation and management of a new patient',
        },
        {
            _id: '61ef9346066b6c663bc46786',
            cpt_code: '99214',
            description:
                'Office or other outpatient visit for the evaluation and management of a established patient',
        },
        {
            _id: '61ef9328066b6c663bc46785',
            cpt_code: '99213',
            description:
                'Office or other outpatient visit for the evaluation and management of a established patient',
        },
    ],
    vitalMasters: [
        {
            _id: '61d6c0f5ad3c29917ededf62',
            units: ['°F'],
            name: 'Temperature',
        },
        {
            _id: '61d7dc92279a8d665d11092a',
            units: ['mm[Hg]'],
            name: 'Blood pressure',
        },
        // {
        //     _id: '61d7dcb5279a8d665d11092f',
        //     units: ['BPM'],
        //     name: 'Heart rate',
        // },
        {
            _id: '61d7dce0279a8d665d110931',
            units: ['Breaths/Min'],
            name: 'Respiratory rate',
        },
        {
            _id: '61d7dcb5279a8d665d11092f',
            units: ['%'],
            name: 'Pulse OX',
        },
        {
            _id: '61d7dd3d279a8d665d110935',
            units: ['kg/m2'],
            name: 'BMI',
        },
        {
            _id: '61d7dd4e279a8d665d110937',
            units: ['lb', 'kg'],
            name: 'Weight',
        },
        {
            _id: '61d7dd60279a8d665d110939',
            units: ['ft/in', 'in'],
            name: 'Height',
        },
        {
            _id: '61d7dd60279a8d665d11093945543',
            units: [],
            name: 'Smoking status',
        },
        {
            _id: '61d7dd60279a8d665d11093946578',
            units: ['beats/minute'],
            name: 'Heart Rate',
        },
    ],
    icdCodeMasters: [
        {
            _id: '61eab1a22874f7cd18a0a7bc',
            icd_code: 'A00.0',
            description: 'Cholera due to Vibrio cholerae 01, biovar cholerae',
        },
        {
            _id: '61eab1a32874f7cd18a0a7bd',
            icd_code: 'A00.1',
            description: 'Cholera due to Vibrio cholerae 01, biovar eltor',
        },
        {
            _id: '61eab1a32874f7cd18a0a7be',
            icd_code: 'A00.9',
            description: 'Cholera, unspecified',
        },
        {
            _id: '61eab1a32874f7cd18a0a7bf',
            icd_code: 'A01.00',
            description: 'Typhoid fever, unspecified',
        },
        {
            _id: '61eab1a32874f7cd18a0a7c0',
            icd_code: 'A01.01',
            description: 'Typhoid meningitis',
        },
        {
            _id: '61eab1a42874f7cd18a0a7c1',
            icd_code: 'A01.02',
            description: 'Typhoid fever with heart involvement',
        },
        {
            _id: '61eab1a42874f7cd18a0a7c2',
            icd_code: 'A01.03',
            description: 'Typhoid pneumonia',
        },
        {
            _id: '61eab1a42874f7cd18a0a7c3',
            icd_code: 'A01.04',
            description: 'Typhoid arthritis',
        },
        {
            _id: '61eab1a42874f7cd18a0a7c4',
            icd_code: 'A01.05',
            description: 'Typhoid osteomyelitis',
        },
        {
            _id: '61eab1a42874f7cd18a0a7c5',
            icd_code: 'A01.09',
            description: 'Typhoid fever with other complications',
        },
        {
            _id: '61eab1e92874f7cd18a0a7c6',
            icd_code: 'A01.1',
            description: 'Paratyphoid fever A',
        },
        {
            _id: '61eab1e92874f7cd18a0a7c7',
            icd_code: 'A01.2',
            description: 'Paratyphoid fever B',
        },
        {
            _id: '61eab1e92874f7cd18a0a7c8',
            icd_code: 'A01.3',
            description: 'Paratyphoid fever C',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7c9',
            icd_code: 'A01.4',
            description: 'Paratyphoid fever, unspecified',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7ca',
            icd_code: 'A02.0',
            description: 'Salmonella enteritis',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7cb',
            icd_code: 'A02.1',
            description: 'Salmonella sepsis',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7cc',
            icd_code: 'A02.20',
            description: 'Localized salmonella infection, unspecified',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7cd',
            icd_code: 'A02.21',
            description: 'Salmonella meningitis',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7ce',
            icd_code: 'A02.22',
            description: 'Salmonella pneumonia',
        },
        {
            _id: '61eab1ea2874f7cd18a0a7cf',
            icd_code: 'A02.23',
            description: 'Salmonella arthritis',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d0',
            icd_code: 'A02.24',
            description: 'Salmonella osteomyelitis',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d1',
            icd_code: 'A02.25',
            description: 'Salmonella pyelonephritis',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d2',
            icd_code: 'A02.29',
            description: 'Salmonella with other localized infection',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d3',
            icd_code: 'A02.8',
            description: 'Other specified salmonella infections',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d4',
            icd_code: 'A02.9',
            description: 'Salmonella infection, unspecified',
        },
        {
            _id: '61eab1eb2874f7cd18a0a7d5',
            icd_code: 'A03.0',
            description: 'Shigellosis due to Shigella dysenteriae',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7d6',
            icd_code: 'A03.1',
            description: 'Shigellosis due to Shigella flexneri',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7d7',
            icd_code: 'A03.2',
            description: 'Shigellosis due to Shigella boydii',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7d8',
            icd_code: 'A03.3',
            description: 'Shigellosis due to Shigella sonnei',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7d9',
            icd_code: 'A03.8',
            description: 'Other shigellosis',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7da',
            icd_code: 'A03.9',
            description: 'Shigellosis, unspecified',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7db',
            icd_code: 'A04.0',
            description: 'Enteropathogenic Escherichia coli infection',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7dc',
            icd_code: 'A04.1',
            description: 'Enterotoxigenic Escherichia coli infection',
        },
        {
            _id: '61eab1ec2874f7cd18a0a7dd',
            icd_code: 'A04.2',
            description: 'Enteroinvasive Escherichia coli infection',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7de',
            icd_code: 'A04.3',
            description: 'Enterohemorrhagic Escherichia coli infection',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7df',
            icd_code: 'A04.4',
            description: 'Other intestinal Escherichia coli infections',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7e0',
            icd_code: 'A04.5',
            description: 'Campylobacter enteritis',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7e1',
            icd_code: 'A04.6',
            description: 'Enteritis due to Yersinia enterocolitica',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7e2',
            icd_code: 'A04.71',
            description: 'Enterocolitis due to Clostridium difficile, recurrent',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7e3',
            icd_code: 'A04.72',
            description: 'Enterocolitis due to Clostridium difficile, not specified as recurrent',
        },
        {
            _id: '61eab1ed2874f7cd18a0a7e4',
            icd_code: 'A04.8',
            description: 'Other specified bacterial intestinal infections',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7e5',
            icd_code: 'A04.9',
            description: 'Bacterial intestinal infection, unspecified',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7e6',
            icd_code: 'A05.0',
            description: 'Foodborne staphylococcal intoxication',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7e7',
            icd_code: 'A05.1',
            description: 'Botulism food poisoning',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7e8',
            icd_code: 'A05.2',
            description: 'Foodborne Clostridium perfringens [Clostridium welchii] intoxication',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7e9',
            icd_code: 'A05.3',
            description: 'Foodborne Vibrio parahaemolyticus intoxication',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7ea',
            icd_code: 'A05.4',
            description: 'Foodborne Bacillus cereus intoxication',
        },
        {
            _id: '61eab1ee2874f7cd18a0a7eb',
            icd_code: 'A05.5',
            description: 'Foodborne Vibrio vulnificus intoxication',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7ec',
            icd_code: 'A05.8',
            description: 'Other specified bacterial foodborne intoxications',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7ed',
            icd_code: 'A05.9',
            description: 'Bacterial foodborne intoxication, unspecified',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7ee',
            icd_code: 'A06.0',
            description: 'Acute amebic dysentery',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7ef',
            icd_code: 'A06.1',
            description: 'Chronic intestinal amebiasis',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7f0',
            icd_code: 'A06.2',
            description: 'Amebic nondysenteric colitis',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7f1',
            icd_code: 'A06.3',
            description: 'Ameboma of intestine',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7f2',
            icd_code: 'A06.4',
            description: 'Amebic liver abscess',
        },
        {
            _id: '61eab1ef2874f7cd18a0a7f3',
            icd_code: 'A06.5',
            description: 'Amebic lung abscess',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f4',
            icd_code: 'A06.6',
            description: 'Amebic brain abscess',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f5',
            icd_code: 'A06.7',
            description: 'Cutaneous amebiasis',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f6',
            icd_code: 'A06.81',
            description: 'Amebic cystitis',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f7',
            icd_code: 'A06.82',
            description: 'Other amebic genitourinary infections',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f8',
            icd_code: 'A06.89',
            description: 'Other amebic infections',
        },
        {
            _id: '61eab1f02874f7cd18a0a7f9',
            icd_code: 'A06.9',
            description: 'Amebiasis, unspecified',
        },
        {
            _id: '61eab1f02874f7cd18a0a7fa',
            icd_code: 'A07.0',
            description: 'Balantidiasis',
        },
        {
            _id: '61eab1f12874f7cd18a0a7fb',
            icd_code: 'A07.1',
            description: 'Giardiasis [lambliasis]',
        },
        {
            _id: '61eab1f12874f7cd18a0a7fc',
            icd_code: 'A07.2',
            description: 'Cryptosporidiosis',
        },
        {
            _id: '61eab1f12874f7cd18a0a7fd',
            icd_code: 'A07.3',
            description: 'Isosporiasis',
        },
        {
            _id: '61eab1f12874f7cd18a0a7fe',
            icd_code: 'A07.4',
            description: 'Cyclosporiasis',
        },
        {
            _id: '61eab1f12874f7cd18a0a7ff',
            icd_code: 'A07.8',
            description: 'Other specified protozoal intestinal diseases',
        },
        {
            _id: '61eab1f12874f7cd18a0a800',
            icd_code: 'A07.9',
            description: 'Protozoal intestinal disease, unspecified',
        },
        {
            _id: '61eab1f12874f7cd18a0a801',
            icd_code: 'A08.0',
            description: 'Rotaviral enteritis',
        },
        {
            _id: '61eab1f12874f7cd18a0a802',
            icd_code: 'A08.11',
            description: 'Acute gastroenteropathy due to Norwalk agent',
        },
        {
            _id: '61eab1f22874f7cd18a0a803',
            icd_code: 'A08.19',
            description: 'Acute gastroenteropathy due to other small round viruses',
        },
        {
            _id: '61eab1f22874f7cd18a0a804',
            icd_code: 'A08.2',
            description: 'Adenoviral enteritis',
        },
        {
            _id: '61eab1f22874f7cd18a0a805',
            icd_code: 'A08.31',
            description: 'Calicivirus enteritis',
        },
        {
            _id: '61eab1f22874f7cd18a0a806',
            icd_code: 'A08.32',
            description: 'Astrovirus enteritis',
        },
        {
            _id: '61eab1f22874f7cd18a0a807',
            icd_code: 'A08.39',
            description: 'Other viral enteritis',
        },
        {
            _id: '61eab1f22874f7cd18a0a808',
            icd_code: 'A08.4',
            description: 'Viral intestinal infection, unspecified',
        },
        {
            _id: '61eab1f32874f7cd18a0a809',
            icd_code: 'A08.8',
            description: 'Other specified intestinal infections',
        },
        {
            _id: '61eab1f32874f7cd18a0a80a',
            icd_code: 'A09',
            description: 'Infectious gastroenteritis and colitis, unspecified',
        },
        {
            _id: '61eab1f32874f7cd18a0a80b',
            icd_code: 'A15.0',
            description: 'Tuberculosis of lung',
        },
        {
            _id: '61eab1f32874f7cd18a0a80c',
            icd_code: 'A15.4',
            description: 'Tuberculosis of intrathoracic lymph nodes',
        },
        {
            _id: '61eab1f32874f7cd18a0a80d',
            icd_code: 'A15.5',
            description: 'Tuberculosis of larynx, trachea and bronchus',
        },
        {
            _id: '61eab1f42874f7cd18a0a80e',
            icd_code: 'A15.6',
            description: 'Tuberculous pleurisy',
        },
        {
            _id: '61eab1f42874f7cd18a0a80f',
            icd_code: 'A15.7',
            description: 'Primary respiratory tuberculosis',
        },
        {
            _id: '61eab1f42874f7cd18a0a810',
            icd_code: 'A15.8',
            description: 'Other respiratory tuberculosis',
        },
        {
            _id: '61eab1f42874f7cd18a0a811',
            icd_code: 'A15.9',
            description: 'Respiratory tuberculosis unspecified',
        },
        {
            _id: '61eab1f42874f7cd18a0a812',
            icd_code: 'A17.0',
            description: 'Tuberculous meningitis',
        },
        {
            _id: '61eab1f42874f7cd18a0a813',
            icd_code: 'A17.1',
            description: 'Meningeal tuberculoma',
        },
        {
            _id: '61eab1f42874f7cd18a0a814',
            icd_code: 'A17.81',
            description: 'Tuberculoma of brain and spinal cord',
        },
        {
            _id: '61eab1f52874f7cd18a0a815',
            icd_code: 'A17.82',
            description: 'Tuberculous meningoencephalitis',
        },
        {
            _id: '61eab1f52874f7cd18a0a816',
            icd_code: 'A17.83',
            description: 'Tuberculous neuritis',
        },
        {
            _id: '61eab1f52874f7cd18a0a817',
            icd_code: 'A17.89',
            description: 'Other tuberculosis of nervous system',
        },
        {
            _id: '61eab1f52874f7cd18a0a818',
            icd_code: 'A17.9',
            description: 'Tuberculosis of nervous system, unspecified',
        },
        {
            _id: '61eab1f52874f7cd18a0a819',
            icd_code: 'A18.01',
            description: 'Tuberculosis of spine',
        },
        {
            _id: '61eab1f52874f7cd18a0a81a',
            icd_code: 'A18.02',
            description: 'Tuberculous arthritis of other joints',
        },
        {
            _id: '61eab1f52874f7cd18a0a81b',
            icd_code: 'A18.03',
            description: 'Tuberculosis of other bones',
        },
        {
            _id: '61eab1f62874f7cd18a0a81c',
            icd_code: 'A18.09',
            description: 'Other musculoskeletal tuberculosis',
        },
        {
            _id: '61eab1f62874f7cd18a0a81d',
            icd_code: 'A18.10',
            description: 'Tuberculosis of genitourinary system, unspecified',
        },
        {
            _id: '61eab1f62874f7cd18a0a81e',
            icd_code: 'A18.11',
            description: 'Tuberculosis of kidney and ureter',
        },
        {
            _id: '61eab1f62874f7cd18a0a81f',
            icd_code: 'A18.12',
            description: 'Tuberculosis of bladder',
        },
        {
            _id: '61eab1f62874f7cd18a0a820',
            icd_code: 'A18.13',
            description: 'Tuberculosis of other urinary organs',
        },
        {
            _id: '61eab1f62874f7cd18a0a821',
            icd_code: 'A18.14',
            description: 'Tuberculosis of prostate',
        },
        {
            _id: '61eab1f62874f7cd18a0a822',
            icd_code: 'A18.15',
            description: 'Tuberculosis of other male genital organs',
        },
        {
            _id: '61eab1f72874f7cd18a0a823',
            icd_code: 'A18.16',
            description: 'Tuberculosis of cervix',
        },
        {
            _id: '61eab1f72874f7cd18a0a824',
            icd_code: 'A18.17',
            description: 'Tuberculous female pelvic inflammatory disease',
        },
        {
            _id: '61eab1f72874f7cd18a0a825',
            icd_code: 'A18.18',
            description: 'Tuberculosis of other female genital organs',
        },
        {
            _id: '61eab1f72874f7cd18a0a826',
            icd_code: 'A18.2',
            description: 'Tuberculous peripheral lymphadenopathy',
        },
        {
            _id: '61eab1f72874f7cd18a0a827',
            icd_code: 'A18.31',
            description: 'Tuberculous peritonitis',
        },
        {
            _id: '61eab1f72874f7cd18a0a828',
            icd_code: 'A18.32',
            description: 'Tuberculous enteritis',
        },
        {
            _id: '61eab1f72874f7cd18a0a829',
            icd_code: 'A18.39',
            description: 'Retroperitoneal tuberculosis',
        },
        {
            _id: '61eab1f72874f7cd18a0a82a',
            icd_code: 'A18.4',
            description: 'Tuberculosis of skin and subcutaneous tissue',
        },
        {
            _id: '61eab1f82874f7cd18a0a82b',
            icd_code: 'A18.50',
            description: 'Tuberculosis of eye, unspecified',
        },
        {
            _id: '61eab1f82874f7cd18a0a82c',
            icd_code: 'A18.51',
            description: 'Tuberculous episcleritis',
        },
        {
            _id: '61eab1f82874f7cd18a0a82d',
            icd_code: 'A18.52',
            description: 'Tuberculous keratitis',
        },
        {
            _id: '61eab1f82874f7cd18a0a82e',
            icd_code: 'A18.53',
            description: 'Tuberculous chorioretinitis',
        },
        {
            _id: '61eab1f82874f7cd18a0a82f',
            icd_code: 'A18.54',
            description: 'Tuberculous iridocyclitis',
        },
        {
            _id: '61eab1f92874f7cd18a0a830',
            icd_code: 'A18.59',
            description: 'Other tuberculosis of eye',
        },
        {
            _id: '61eab1f92874f7cd18a0a831',
            icd_code: 'A18.6',
            description: 'Tuberculosis of (inner) (middle) ear',
        },
        {
            _id: '61eab1f92874f7cd18a0a832',
            icd_code: 'A18.7',
            description: 'Tuberculosis of adrenal glands',
        },
        {
            _id: '61eab1f92874f7cd18a0a833',
            icd_code: 'A18.81',
            description: 'Tuberculosis of thyroid gland',
        },
        {
            _id: '61eab1f92874f7cd18a0a834',
            icd_code: 'A18.82',
            description: 'Tuberculosis of other endocrine glands',
        },
        {
            _id: '61eab1f92874f7cd18a0a835',
            icd_code: 'A18.83',
            description: 'Tuberculosis of digestive tract organs, not elsewhere classified',
        },
        {
            _id: '61eab1f92874f7cd18a0a836',
            icd_code: 'A18.84',
            description: 'Tuberculosis of heart',
        },
        {
            _id: '61eab1fa2874f7cd18a0a837',
            icd_code: 'A18.85',
            description: 'Tuberculosis of spleen',
        },
        {
            _id: '61eab1fa2874f7cd18a0a838',
            icd_code: 'A18.89',
            description: 'Tuberculosis of other sites',
        },
        {
            _id: '61eab1fa2874f7cd18a0a839',
            icd_code: 'A19.0',
            description: 'Acute miliary tuberculosis of a single specified site',
        },
        {
            _id: '61eab1fa2874f7cd18a0a83a',
            icd_code: 'A19.1',
            description: 'Acute miliary tuberculosis of multiple sites',
        },
        {
            _id: '61eab1fa2874f7cd18a0a83b',
            icd_code: 'A19.2',
            description: 'Acute miliary tuberculosis, unspecified',
        },
        {
            _id: '61eab1fa2874f7cd18a0a83c',
            icd_code: 'A19.8',
            description: 'Other miliary tuberculosis',
        },
        {
            _id: '61eab1fa2874f7cd18a0a83d',
            icd_code: 'A19.9',
            description: 'Miliary tuberculosis, unspecified',
        },
        {
            _id: '61eab1fb2874f7cd18a0a83e',
            icd_code: 'A20.0',
            description: 'Bubonic plague',
        },
        {
            _id: '61eab1fb2874f7cd18a0a83f',
            icd_code: 'A20.1',
            description: 'Cellulocutaneous plague',
        },
        {
            _id: '61eab1fb2874f7cd18a0a840',
            icd_code: 'A20.2',
            description: 'Pneumonic plague',
        },
        {
            _id: '61eab1fb2874f7cd18a0a841',
            icd_code: 'A20.3',
            description: 'Plague meningitis',
        },
        {
            _id: '61eab1fb2874f7cd18a0a842',
            icd_code: 'A20.7',
            description: 'Septicemic plague',
        },
        {
            _id: '61eab1fb2874f7cd18a0a843',
            icd_code: 'A20.8',
            description: 'Other forms of plague',
        },
        {
            _id: '61eab1fb2874f7cd18a0a844',
            icd_code: 'A20.9',
            description: 'Plague, unspecified',
        },
        {
            _id: '61eab1fc2874f7cd18a0a845',
            icd_code: 'A21.0',
            description: 'Ulceroglandular tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a846',
            icd_code: 'A21.1',
            description: 'Oculoglandular tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a847',
            icd_code: 'A21.2',
            description: 'Pulmonary tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a848',
            icd_code: 'A21.3',
            description: 'Gastrointestinal tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a849',
            icd_code: 'A21.7',
            description: 'Generalized tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a84a',
            icd_code: 'A21.8',
            description: 'Other forms of tularemia',
        },
        {
            _id: '61eab1fc2874f7cd18a0a84b',
            icd_code: 'A21.9',
            description: 'Tularemia, unspecified',
        },
        {
            _id: '61eab1fd2874f7cd18a0a84c',
            icd_code: 'A22.0',
            description: 'Cutaneous anthrax',
        },
        {
            _id: '61eab1fd2874f7cd18a0a84d',
            icd_code: 'A22.1',
            description: 'Pulmonary anthrax',
        },
        {
            _id: '61eab1fd2874f7cd18a0a84e',
            icd_code: 'A22.2',
            description: 'Gastrointestinal anthrax',
        },
        {
            _id: '61eab1fd2874f7cd18a0a84f',
            icd_code: 'A22.7',
            description: 'Anthrax sepsis',
        },
        {
            _id: '61eab1fd2874f7cd18a0a850',
            icd_code: 'A22.8',
            description: 'Other forms of anthrax',
        },
        {
            _id: '61eab1fe2874f7cd18a0a851',
            icd_code: 'A22.9',
            description: 'Anthrax, unspecified',
        },
        {
            _id: '61eab1fe2874f7cd18a0a852',
            icd_code: 'A23.0',
            description: 'Brucellosis due to Brucella melitensis',
        },
        {
            _id: '61eab1fe2874f7cd18a0a853',
            icd_code: 'A23.1',
            description: 'Brucellosis due to Brucella abortus',
        },
        {
            _id: '61eab1fe2874f7cd18a0a854',
            icd_code: 'A23.2',
            description: 'Brucellosis due to Brucella suis',
        },
        {
            _id: '61eab1fe2874f7cd18a0a855',
            icd_code: 'A23.3',
            description: 'Brucellosis due to Brucella canis',
        },
        {
            _id: '61eab1ff2874f7cd18a0a856',
            icd_code: 'A23.8',
            description: 'Other brucellosis',
        },
        {
            _id: '61eab1ff2874f7cd18a0a857',
            icd_code: 'A23.9',
            description: 'Brucellosis, unspecified',
        },
        {
            _id: '61eab1ff2874f7cd18a0a858',
            icd_code: 'A24.0',
            description: 'Glanders',
        },
        {
            _id: '61eab1ff2874f7cd18a0a859',
            icd_code: 'A24.1',
            description: 'Acute and fulminating melioidosis',
        },
        {
            _id: '61eab1ff2874f7cd18a0a85a',
            icd_code: 'A24.2',
            description: 'Subacute and chronic melioidosis',
        },
        {
            _id: '61eab1ff2874f7cd18a0a85b',
            icd_code: 'A24.3',
            description: 'Other melioidosis',
        },
        {
            _id: '61eab1ff2874f7cd18a0a85c',
            icd_code: 'A24.9',
            description: 'Melioidosis, unspecified',
        },
        {
            _id: '61eab2002874f7cd18a0a85d',
            icd_code: 'A25.0',
            description: 'Spirillosis',
        },
        {
            _id: '61eab2002874f7cd18a0a85e',
            icd_code: 'A25.1',
            description: 'Streptobacillosis',
        },
        {
            _id: '61eab2002874f7cd18a0a85f',
            icd_code: 'A25.9',
            description: 'Rat-bite fever, unspecified',
        },
        {
            _id: '61eab2002874f7cd18a0a860',
            icd_code: 'A26.0',
            description: 'Cutaneous erysipeloid',
        },
        {
            _id: '61eab2002874f7cd18a0a861',
            icd_code: 'A26.7',
            description: 'Erysipelothrix sepsis',
        },
        {
            _id: '61eab2002874f7cd18a0a862',
            icd_code: 'A26.8',
            description: 'Other forms of erysipeloid',
        },
        {
            _id: '61eab2002874f7cd18a0a863',
            icd_code: 'A26.9',
            description: 'Erysipeloid, unspecified',
        },
        {
            _id: '61eab2012874f7cd18a0a864',
            icd_code: 'A27.0',
            description: 'Leptospirosis icterohemorrhagica',
        },
        {
            _id: '61eab2012874f7cd18a0a865',
            icd_code: 'A27.81',
            description: 'Aseptic meningitis in leptospirosis',
        },
        {
            _id: '61eab2012874f7cd18a0a866',
            icd_code: 'A27.89',
            description: 'Other forms of leptospirosis',
        },
        {
            _id: '61eab2012874f7cd18a0a867',
            icd_code: 'A27.9',
            description: 'Leptospirosis, unspecified',
        },
        {
            _id: '61eab2012874f7cd18a0a868',
            icd_code: 'A28.0',
            description: 'Pasteurellosis',
        },
        {
            _id: '61eab2012874f7cd18a0a869',
            icd_code: 'A28.1',
            description: 'Cat-scratch disease',
        },
        {
            _id: '61eab2012874f7cd18a0a86a',
            icd_code: 'A28.2',
            description: 'Extraintestinal yersiniosis',
        },
        {
            _id: '61eab2022874f7cd18a0a86b',
            icd_code: 'A28.8',
            description: 'Other specified zoonotic bacterial diseases, not elsewhere classified',
        },
        {
            _id: '61eab2022874f7cd18a0a86c',
            icd_code: 'A28.9',
            description: 'Zoonotic bacterial disease, unspecified',
        },
        {
            _id: '61eab2022874f7cd18a0a86d',
            icd_code: 'A30.0',
            description: 'Indeterminate leprosy',
        },
        {
            _id: '61eab2022874f7cd18a0a86e',
            icd_code: 'A30.1',
            description: 'Tuberculoid leprosy',
        },
        {
            _id: '61eab2022874f7cd18a0a86f',
            icd_code: 'A30.2',
            description: 'Borderline tuberculoid leprosy',
        },
        {
            _id: '61eab2022874f7cd18a0a870',
            icd_code: 'A30.3',
            description: 'Borderline leprosy',
        },
        {
            _id: '61eab2022874f7cd18a0a871',
            icd_code: 'A30.4',
            description: 'Borderline lepromatous leprosy',
        },
        {
            _id: '61eab2032874f7cd18a0a872',
            icd_code: 'A30.5',
            description: 'Lepromatous leprosy',
        },
        {
            _id: '61eab2032874f7cd18a0a873',
            icd_code: 'A30.8',
            description: 'Other forms of leprosy',
        },
        {
            _id: '61eab2032874f7cd18a0a874',
            icd_code: 'A30.9',
            description: 'Leprosy, unspecified',
        },
        {
            _id: '61eab2032874f7cd18a0a875',
            icd_code: 'A31.0',
            description: 'Pulmonary mycobacterial infection',
        },
        {
            _id: '61eab2032874f7cd18a0a876',
            icd_code: 'A31.1',
            description: 'Cutaneous mycobacterial infection',
        },
        {
            _id: '61eab2032874f7cd18a0a877',
            icd_code: 'A31.2',
            description: 'Disseminated mycobacterium avium-intracellulare complex (DMAC)',
        },
        {
            _id: '61eab2032874f7cd18a0a878',
            icd_code: 'A31.8',
            description: 'Other mycobacterial infections',
        },
        {
            _id: '61eab2032874f7cd18a0a879',
            icd_code: 'A31.9',
            description: 'Mycobacterial infection, unspecified',
        },
        {
            _id: '61eab2042874f7cd18a0a87a',
            icd_code: 'A32.0',
            description: 'Cutaneous listeriosis',
        },
        {
            _id: '61eab2042874f7cd18a0a87b',
            icd_code: 'A32.11',
            description: 'Listerial meningitis',
        },
        {
            _id: '61eab2042874f7cd18a0a87c',
            icd_code: 'A32.12',
            description: 'Listerial meningoencephalitis',
        },
        {
            _id: '61eab2042874f7cd18a0a87d',
            icd_code: 'A32.7',
            description: 'Listerial sepsis',
        },
        {
            _id: '61eab2042874f7cd18a0a87e',
            icd_code: 'A32.81',
            description: 'Oculoglandular listeriosis',
        },
        {
            _id: '61eab2042874f7cd18a0a87f',
            icd_code: 'A32.82',
            description: 'Listerial endocarditis',
        },
        {
            _id: '61eab2042874f7cd18a0a880',
            icd_code: 'A32.89',
            description: 'Other forms of listeriosis',
        },
        {
            _id: '61eab2052874f7cd18a0a881',
            icd_code: 'A32.9',
            description: 'Listeriosis, unspecified',
        },
        {
            _id: '61eab2052874f7cd18a0a882',
            icd_code: 'A33',
            description: 'Tetanus neonatorum',
        },
        {
            _id: '61eab2052874f7cd18a0a883',
            icd_code: 'A34',
            description: 'Obstetrical tetanus',
        },
        {
            _id: '61eab2052874f7cd18a0a884',
            icd_code: 'A35',
            description: 'Other tetanus',
        },
        {
            _id: '61eab2062874f7cd18a0a885',
            icd_code: 'A36.0',
            description: 'Pharyngeal diphtheria',
        },
        {
            _id: '61eab2062874f7cd18a0a886',
            icd_code: 'A36.1',
            description: 'Nasopharyngeal diphtheria',
        },
        {
            _id: '61eab2062874f7cd18a0a887',
            icd_code: 'A36.2',
            description: 'Laryngeal diphtheria',
        },
        {
            _id: '61eab2062874f7cd18a0a888',
            icd_code: 'A36.3',
            description: 'Cutaneous diphtheria',
        },
        {
            _id: '61eab2062874f7cd18a0a889',
            icd_code: 'A36.81',
            description: 'Diphtheritic cardiomyopathy',
        },
        {
            _id: '61eab2062874f7cd18a0a88a',
            icd_code: 'A36.82',
            description: 'Diphtheritic radiculomyelitis',
        },
        {
            _id: '61eab2072874f7cd18a0a88b',
            icd_code: 'A36.83',
            description: 'Diphtheritic polyneuritis',
        },
        {
            _id: '61eab2072874f7cd18a0a88c',
            icd_code: 'A36.84',
            description: 'Diphtheritic tubulo-interstitial nephropathy',
        },
        {
            _id: '61eab2072874f7cd18a0a88d',
            icd_code: 'A36.85',
            description: 'Diphtheritic cystitis',
        },
        {
            _id: '61eab2072874f7cd18a0a88e',
            icd_code: 'A36.86',
            description: 'Diphtheritic conjunctivitis',
        },
        {
            _id: '61eab2072874f7cd18a0a88f',
            icd_code: 'A36.89',
            description: 'Other diphtheritic complications',
        },
        {
            _id: '61eab2072874f7cd18a0a890',
            icd_code: 'A36.9',
            description: 'Diphtheria, unspecified',
        },
        {
            _id: '61eab2072874f7cd18a0a891',
            icd_code: 'A37.00',
            description: 'Whooping cough due to Bordetella pertussis without pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a892',
            icd_code: 'A37.01',
            description: 'Whooping cough due to Bordetella pertussis with pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a893',
            icd_code: 'A37.10',
            description: 'Whooping cough due to Bordetella parapertussis without pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a894',
            icd_code: 'A37.11',
            description: 'Whooping cough due to Bordetella parapertussis with pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a895',
            icd_code: 'A37.80',
            description: 'Whooping cough due to other Bordetella species without pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a896',
            icd_code: 'A37.81',
            description: 'Whooping cough due to other Bordetella species with pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a897',
            icd_code: 'A37.90',
            description: 'Whooping cough, unspecified species without pneumonia',
        },
        {
            _id: '61eab2082874f7cd18a0a898',
            icd_code: 'A37.91',
            description: 'Whooping cough, unspecified species with pneumonia',
        },
        {
            _id: '61eab2092874f7cd18a0a899',
            icd_code: 'A38.0',
            description: 'Scarlet fever with otitis media',
        },
        {
            _id: '61eab2092874f7cd18a0a89a',
            icd_code: 'A38.1',
            description: 'Scarlet fever with myocarditis',
        },
        {
            _id: '61eab2092874f7cd18a0a89b',
            icd_code: 'A38.8',
            description: 'Scarlet fever with other complications',
        },
        {
            _id: '61eab2092874f7cd18a0a89c',
            icd_code: 'A38.9',
            description: 'Scarlet fever, uncomplicated',
        },
        {
            _id: '61eab2092874f7cd18a0a89d',
            icd_code: 'A39.0',
            description: 'Meningococcal meningitis',
        },
        {
            _id: '61eab2092874f7cd18a0a89e',
            icd_code: 'A39.1',
            description: 'Waterhouse-Friderichsen syndrome',
        },
        {
            _id: '61eab20a2874f7cd18a0a89f',
            icd_code: 'A39.2',
            description: 'Acute meningococcemia',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a0',
            icd_code: 'A39.3',
            description: 'Chronic meningococcemia',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a1',
            icd_code: 'A39.4',
            description: 'Meningococcemia, unspecified',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a2',
            icd_code: 'A39.50',
            description: 'Meningococcal carditis, unspecified',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a3',
            icd_code: 'A39.51',
            description: 'Meningococcal endocarditis',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a4',
            icd_code: 'A39.52',
            description: 'Meningococcal myocarditis',
        },
        {
            _id: '61eab20a2874f7cd18a0a8a5',
            icd_code: 'A39.53',
            description: 'Meningococcal pericarditis',
        },
        {
            _id: '61eab20b2874f7cd18a0a8a6',
            icd_code: 'A39.81',
            description: 'Meningococcal encephalitis',
        },
        {
            _id: '61eab20b2874f7cd18a0a8a7',
            icd_code: 'A39.82',
            description: 'Meningococcal retrobulbar neuritis',
        },
        {
            _id: '61eab20b2874f7cd18a0a8a8',
            icd_code: 'A39.83',
            description: 'Meningococcal arthritis',
        },
        {
            _id: '61eab20b2874f7cd18a0a8a9',
            icd_code: 'A39.84',
            description: 'Postmeningococcal arthritis',
        },
        {
            _id: '61eab20b2874f7cd18a0a8aa',
            icd_code: 'A39.89',
            description: 'Other meningococcal infections',
        },
        {
            _id: '61eab20b2874f7cd18a0a8ab',
            icd_code: 'A39.9',
            description: 'Meningococcal infection, unspecified',
        },
        {
            _id: '61eab20c2874f7cd18a0a8ac',
            icd_code: 'A40.0',
            description: 'Sepsis due to streptococcus, group A',
        },
        {
            _id: '61eab20c2874f7cd18a0a8ad',
            icd_code: 'A40.1',
            description: 'Sepsis due to streptococcus, group B',
        },
        {
            _id: '61eab20c2874f7cd18a0a8ae',
            icd_code: 'A40.3',
            description: 'Sepsis due to Streptococcus pneumoniae',
        },
        {
            _id: '61eab20c2874f7cd18a0a8af',
            icd_code: 'A40.8',
            description: 'Other streptococcal sepsis',
        },
        {
            _id: '61eab20c2874f7cd18a0a8b0',
            icd_code: 'A40.9',
            description: 'Streptococcal sepsis, unspecified',
        },
        {
            _id: '61eab20c2874f7cd18a0a8b1',
            icd_code: 'A41.01',
            description: 'Sepsis due to Methicillin susceptible Staphylococcus aureus',
        },
        {
            _id: '61eab20c2874f7cd18a0a8b2',
            icd_code: 'A41.02',
            description: 'Sepsis due to Methicillin resistant Staphylococcus aureus',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b3',
            icd_code: 'A41.1',
            description: 'Sepsis due to other specified staphylococcus',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b4',
            icd_code: 'A41.2',
            description: 'Sepsis due to unspecified staphylococcus',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b5',
            icd_code: 'A41.3',
            description: 'Sepsis due to Hemophilus influenzae',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b6',
            icd_code: 'A41.4',
            description: 'Sepsis due to anaerobes',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b7',
            icd_code: 'A41.50',
            description: 'Gram-negative sepsis, unspecified',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b8',
            icd_code: 'A41.51',
            description: 'Sepsis due to Escherichia coli [E. coli]',
        },
        {
            _id: '61eab20d2874f7cd18a0a8b9',
            icd_code: 'A41.52',
            description: 'Sepsis due to Pseudomonas',
        },
        {
            _id: '61eab20e2874f7cd18a0a8ba',
            icd_code: 'A41.53',
            description: 'Sepsis due to Serratia',
        },
        {
            _id: '61eab20e2874f7cd18a0a8bb',
            icd_code: 'A41.59',
            description: 'Other Gram-negative sepsis',
        },
        {
            _id: '61eab20e2874f7cd18a0a8bc',
            icd_code: 'A41.81',
            description: 'Sepsis due to Enterococcus',
        },
        {
            _id: '61eab20e2874f7cd18a0a8bd',
            icd_code: 'A41.89',
            description: 'Other specified sepsis',
        },
        {
            _id: '61eab20e2874f7cd18a0a8be',
            icd_code: 'A41.9',
            description: 'Sepsis, unspecified organism',
        },
        {
            _id: '61eab20e2874f7cd18a0a8bf',
            icd_code: 'A42.0',
            description: 'Pulmonary actinomycosis',
        },
        {
            _id: '61eab20e2874f7cd18a0a8c0',
            icd_code: 'A42.1',
            description: 'Abdominal actinomycosis',
        },
        {
            _id: '61eab20f2874f7cd18a0a8c1',
            icd_code: 'A42.2',
            description: 'Cervicofacial actinomycosis',
        },
        {
            _id: '61eab20f2874f7cd18a0a8c2',
            icd_code: 'A42.7',
            description: 'Actinomycotic sepsis',
        },
        {
            _id: '61eab2102874f7cd18a0a8c3',
            icd_code: 'A42.81',
            description: 'Actinomycotic meningitis',
        },
        {
            _id: '61eab2102874f7cd18a0a8c4',
            icd_code: 'A42.82',
            description: 'Actinomycotic encephalitis',
        },
        {
            _id: '61eab2102874f7cd18a0a8c5',
            icd_code: 'A42.89',
            description: 'Other forms of actinomycosis',
        },
        {
            _id: '61eab2102874f7cd18a0a8c6',
            icd_code: 'A42.9',
            description: 'Actinomycosis, unspecified',
        },
        {
            _id: '61eab2102874f7cd18a0a8c7',
            icd_code: 'A43.0',
            description: 'Pulmonary nocardiosis',
        },
        {
            _id: '61eab2112874f7cd18a0a8c8',
            icd_code: 'A43.1',
            description: 'Cutaneous nocardiosis',
        },
        {
            _id: '61eab2112874f7cd18a0a8c9',
            icd_code: 'A43.8',
            description: 'Other forms of nocardiosis',
        },
        {
            _id: '61eab2112874f7cd18a0a8ca',
            icd_code: 'A43.9',
            description: 'Nocardiosis, unspecified',
        },
        {
            _id: '61eab2112874f7cd18a0a8cb',
            icd_code: 'A44.0',
            description: 'Systemic bartonellosis',
        },
        {
            _id: '61eab2112874f7cd18a0a8cc',
            icd_code: 'A44.1',
            description: 'Cutaneous and mucocutaneous bartonellosis',
        },
        {
            _id: '61eab2112874f7cd18a0a8cd',
            icd_code: 'A44.8',
            description: 'Other forms of bartonellosis',
        },
        {
            _id: '61eab2122874f7cd18a0a8ce',
            icd_code: 'A44.9',
            description: 'Bartonellosis, unspecified',
        },
        {
            _id: '61eab2122874f7cd18a0a8cf',
            icd_code: 'A46',
            description: 'Erysipelas',
        },
        {
            _id: '61eab2122874f7cd18a0a8d0',
            icd_code: 'A48.0',
            description: 'Gas gangrene',
        },
        {
            _id: '61eab2122874f7cd18a0a8d1',
            icd_code: 'A48.1',
            description: "Legionnaires' disease",
        },
        {
            _id: '61eab2122874f7cd18a0a8d2',
            icd_code: 'A48.2',
            description: "Nonpneumonic Legionnaires' disease [Pontiac fever]",
        },
        {
            _id: '61eab2122874f7cd18a0a8d3',
            icd_code: 'A48.3',
            description: 'Toxic shock syndrome',
        },
        {
            _id: '61eab2122874f7cd18a0a8d4',
            icd_code: 'A48.4',
            description: 'Brazilian purpuric fever',
        },
        {
            _id: '61eab2122874f7cd18a0a8d5',
            icd_code: 'A48.51',
            description: 'Infant botulism',
        },
        {
            _id: '61eab2132874f7cd18a0a8d6',
            icd_code: 'A48.52',
            description: 'Wound botulism',
        },
        {
            _id: '61eab2132874f7cd18a0a8d7',
            icd_code: 'A48.8',
            description: 'Other specified bacterial diseases',
        },
        {
            _id: '61eab2132874f7cd18a0a8d8',
            icd_code: 'A49.01',
            description:
                'Methicillin susceptible Staphylococcus aureus infection, unspecified site',
        },
        {
            _id: '61eab2132874f7cd18a0a8d9',
            icd_code: 'A49.02',
            description: 'Methicillin resistant Staphylococcus aureus infection, unspecified site',
        },
        {
            _id: '61eab2132874f7cd18a0a8da',
            icd_code: 'A49.1',
            description: 'Streptococcal infection, unspecified site',
        },
        {
            _id: '61eab2132874f7cd18a0a8db',
            icd_code: 'A49.2',
            description: 'Hemophilus influenzae infection, unspecified site',
        },
        {
            _id: '61eab2142874f7cd18a0a8dc',
            icd_code: 'A49.3',
            description: 'Mycoplasma infection, unspecified site',
        },
        {
            _id: '61eab2142874f7cd18a0a8dd',
            icd_code: 'A49.8',
            description: 'Other bacterial infections of unspecified site',
        },
        {
            _id: '61eab2142874f7cd18a0a8de',
            icd_code: 'A49.9',
            description: 'Bacterial infection, unspecified',
        },
        {
            _id: '61eab2142874f7cd18a0a8df',
            icd_code: 'A50.01',
            description: 'Early congenital syphilitic oculopathy',
        },
        {
            _id: '61eab2142874f7cd18a0a8e0',
            icd_code: 'A50.02',
            description: 'Early congenital syphilitic osteochondropathy',
        },
        {
            _id: '61eab2142874f7cd18a0a8e1',
            icd_code: 'A50.03',
            description: 'Early congenital syphilitic pharyngitis',
        },
        {
            _id: '61eab2152874f7cd18a0a8e2',
            icd_code: 'A50.04',
            description: 'Early congenital syphilitic pneumonia',
        },
        {
            _id: '61eab2152874f7cd18a0a8e3',
            icd_code: 'A50.05',
            description: 'Early congenital syphilitic rhinitis',
        },
        {
            _id: '61eab2152874f7cd18a0a8e4',
            icd_code: 'A50.06',
            description: 'Early cutaneous congenital syphilis',
        },
        {
            _id: '61eab2152874f7cd18a0a8e5',
            icd_code: 'A50.07',
            description: 'Early mucocutaneous congenital syphilis',
        },
        {
            _id: '61eab2152874f7cd18a0a8e6',
            icd_code: 'A50.08',
            description: 'Early visceral congenital syphilis',
        },
        {
            _id: '61eab2162874f7cd18a0a8e7',
            icd_code: 'A50.09',
            description: 'Other early congenital syphilis, symptomatic',
        },
        {
            _id: '61eab2162874f7cd18a0a8e8',
            icd_code: 'A50.1',
            description: 'Early congenital syphilis, latent',
        },
        {
            _id: '61eab2162874f7cd18a0a8e9',
            icd_code: 'A50.2',
            description: 'Early congenital syphilis, unspecified',
        },
        {
            _id: '61eab2162874f7cd18a0a8ea',
            icd_code: 'A50.30',
            description: 'Late congenital syphilitic oculopathy, unspecified',
        },
        {
            _id: '61eab2162874f7cd18a0a8eb',
            icd_code: 'A50.31',
            description: 'Late congenital syphilitic interstitial keratitis',
        },
        {
            _id: '61eab2162874f7cd18a0a8ec',
            icd_code: 'A50.32',
            description: 'Late congenital syphilitic chorioretinitis',
        },
        {
            _id: '61eab2172874f7cd18a0a8ed',
            icd_code: 'A50.39',
            description: 'Other late congenital syphilitic oculopathy',
        },
        {
            _id: '61eab2172874f7cd18a0a8ee',
            icd_code: 'A50.40',
            description: 'Late congenital neurosyphilis, unspecified',
        },
        {
            _id: '61eab2172874f7cd18a0a8ef',
            icd_code: 'A50.41',
            description: 'Late congenital syphilitic meningitis',
        },
        {
            _id: '61eab2172874f7cd18a0a8f0',
            icd_code: 'A50.42',
            description: 'Late congenital syphilitic encephalitis',
        },
        {
            _id: '61eab2172874f7cd18a0a8f1',
            icd_code: 'A50.43',
            description: 'Late congenital syphilitic polyneuropathy',
        },
        {
            _id: '61eab2172874f7cd18a0a8f2',
            icd_code: 'A50.44',
            description: 'Late congenital syphilitic optic nerve atrophy',
        },
        {
            _id: '61eab2172874f7cd18a0a8f3',
            icd_code: 'A50.45',
            description: 'Juvenile general paresis',
        },
        {
            _id: '61eab2182874f7cd18a0a8f4',
            icd_code: 'A50.49',
            description: 'Other late congenital neurosyphilis',
        },
        {
            _id: '61eab2182874f7cd18a0a8f5',
            icd_code: 'A50.51',
            description: "Clutton's joints",
        },
        {
            _id: '61eab2182874f7cd18a0a8f6',
            icd_code: 'A50.52',
            description: "Hutchinson's teeth",
        },
        {
            _id: '61eab2182874f7cd18a0a8f7',
            icd_code: 'A50.53',
            description: "Hutchinson's triad",
        },
        {
            _id: '61eab2182874f7cd18a0a8f8',
            icd_code: 'A50.54',
            description: 'Late congenital cardiovascular syphilis',
        },
        {
            _id: '61eab2182874f7cd18a0a8f9',
            icd_code: 'A50.55',
            description: 'Late congenital syphilitic arthropathy',
        },
        {
            _id: '61eab2192874f7cd18a0a8fa',
            icd_code: 'A50.56',
            description: 'Late congenital syphilitic osteochondropathy',
        },
        {
            _id: '61eab2192874f7cd18a0a8fb',
            icd_code: 'A50.57',
            description: 'Syphilitic saddle nose',
        },
        {
            _id: '61eab2192874f7cd18a0a8fc',
            icd_code: 'A50.59',
            description: 'Other late congenital syphilis, symptomatic',
        },
        {
            _id: '61eab21a2874f7cd18a0a8fd',
            icd_code: 'A50.6',
            description: 'Late congenital syphilis, latent',
        },
        {
            _id: '61eab21a2874f7cd18a0a8fe',
            icd_code: 'A50.7',
            description: 'Late congenital syphilis, unspecified',
        },
        {
            _id: '61eab21a2874f7cd18a0a8ff',
            icd_code: 'A50.9',
            description: 'Congenital syphilis, unspecified',
        },
        {
            _id: '61eab21a2874f7cd18a0a900',
            icd_code: 'A51.0',
            description: 'Primary genital syphilis',
        },
        {
            _id: '61eab21a2874f7cd18a0a901',
            icd_code: 'A51.1',
            description: 'Primary anal syphilis',
        },
        {
            _id: '61eab21a2874f7cd18a0a902',
            icd_code: 'A51.2',
            description: 'Primary syphilis of other sites',
        },
        {
            _id: '61eab21a2874f7cd18a0a903',
            icd_code: 'A51.31',
            description: 'Condyloma latum',
        },
        {
            _id: '61eab21b2874f7cd18a0a904',
            icd_code: 'A51.32',
            description: 'Syphilitic alopecia',
        },
        {
            _id: '61eab21b2874f7cd18a0a905',
            icd_code: 'A51.39',
            description: 'Other secondary syphilis of skin',
        },
        {
            _id: '61eab21c2874f7cd18a0a906',
            icd_code: 'A51.41',
            description: 'Secondary syphilitic meningitis',
        },
        {
            _id: '61eab21c2874f7cd18a0a907',
            icd_code: 'A51.42',
            description: 'Secondary syphilitic female pelvic disease',
        },
        {
            _id: '61eab21c2874f7cd18a0a908',
            icd_code: 'A51.43',
            description: 'Secondary syphilitic oculopathy',
        },
        {
            _id: '61eab21c2874f7cd18a0a909',
            icd_code: 'A51.44',
            description: 'Secondary syphilitic nephritis',
        },
        {
            _id: '61eab21c2874f7cd18a0a90a',
            icd_code: 'A51.45',
            description: 'Secondary syphilitic hepatitis',
        },
        {
            _id: '61eab21c2874f7cd18a0a90b',
            icd_code: 'A51.46',
            description: 'Secondary syphilitic osteopathy',
        },
        {
            _id: '61eab21c2874f7cd18a0a90c',
            icd_code: 'A51.49',
            description: 'Other secondary syphilitic conditions',
        },
        {
            _id: '61eab21d2874f7cd18a0a90d',
            icd_code: 'A51.5',
            description: 'Early syphilis, latent',
        },
        {
            _id: '61eab21d2874f7cd18a0a90e',
            icd_code: 'A51.9',
            description: 'Early syphilis, unspecified',
        },
        {
            _id: '61eab21d2874f7cd18a0a90f',
            icd_code: 'A52.00',
            description: 'Cardiovascular syphilis, unspecified',
        },
        {
            _id: '61eab21d2874f7cd18a0a910',
            icd_code: 'A52.01',
            description: 'Syphilitic aneurysm of aorta',
        },
        {
            _id: '61eab21d2874f7cd18a0a911',
            icd_code: 'A52.02',
            description: 'Syphilitic aortitis',
        },
        {
            _id: '61eab21d2874f7cd18a0a912',
            icd_code: 'A52.03',
            description: 'Syphilitic endocarditis',
        },
        {
            _id: '61eab21d2874f7cd18a0a913',
            icd_code: 'A52.04',
            description: 'Syphilitic cerebral arteritis',
        },
        {
            _id: '61eab21e2874f7cd18a0a914',
            icd_code: 'A52.05',
            description: 'Other cerebrovascular syphilis',
        },
        {
            _id: '61eab21e2874f7cd18a0a915',
            icd_code: 'A52.06',
            description: 'Other syphilitic heart involvement',
        },
        {
            _id: '61eab21e2874f7cd18a0a916',
            icd_code: 'A52.09',
            description: 'Other cardiovascular syphilis',
        },
        {
            _id: '61eab21e2874f7cd18a0a917',
            icd_code: 'A52.10',
            description: 'Symptomatic neurosyphilis, unspecified',
        },
        {
            _id: '61eab21e2874f7cd18a0a918',
            icd_code: 'A52.11',
            description: 'Tabes dorsalis',
        },
        {
            _id: '61eab21e2874f7cd18a0a919',
            icd_code: 'A52.12',
            description: 'Other cerebrospinal syphilis',
        },
        {
            _id: '61eab21e2874f7cd18a0a91a',
            icd_code: 'A52.13',
            description: 'Late syphilitic meningitis',
        },
        {
            _id: '61eab21e2874f7cd18a0a91b',
            icd_code: 'A52.14',
            description: 'Late syphilitic encephalitis',
        },
        {
            _id: '61eab21f2874f7cd18a0a91c',
            icd_code: 'A52.15',
            description: 'Late syphilitic neuropathy',
        },
        {
            _id: '61eab21f2874f7cd18a0a91d',
            icd_code: 'A52.16',
            description: "Charcot's arthropathy (tabetic)",
        },
        {
            _id: '61eab21f2874f7cd18a0a91e',
            icd_code: 'A52.17',
            description: 'General paresis',
        },
        {
            _id: '61eab21f2874f7cd18a0a91f',
            icd_code: 'A52.19',
            description: 'Other symptomatic neurosyphilis',
        },
        {
            _id: '61eab21f2874f7cd18a0a920',
            icd_code: 'A52.2',
            description: 'Asymptomatic neurosyphilis',
        },
        {
            _id: '61eab21f2874f7cd18a0a921',
            icd_code: 'A52.3',
            description: 'Neurosyphilis, unspecified',
        },
        {
            _id: '61eab21f2874f7cd18a0a922',
            icd_code: 'A52.71',
            description: 'Late syphilitic oculopathy',
        },
        {
            _id: '61eab2202874f7cd18a0a923',
            icd_code: 'A52.72',
            description: 'Syphilis of lung and bronchus',
        },
        {
            _id: '61eab2202874f7cd18a0a924',
            icd_code: 'A52.73',
            description: 'Symptomatic late syphilis of other respiratory organs',
        },
        {
            _id: '61eab2202874f7cd18a0a925',
            icd_code: 'A52.74',
            description: 'Syphilis of liver and other viscera',
        },
        {
            _id: '61eab2202874f7cd18a0a926',
            icd_code: 'A52.75',
            description: 'Syphilis of kidney and ureter',
        },
        {
            _id: '61eab2212874f7cd18a0a927',
            icd_code: 'A52.76',
            description: 'Other genitourinary symptomatic late syphilis',
        },
        {
            _id: '61eab2212874f7cd18a0a928',
            icd_code: 'A52.77',
            description: 'Syphilis of bone and joint',
        },
        {
            _id: '61eab2212874f7cd18a0a929',
            icd_code: 'A52.78',
            description: 'Syphilis of other musculoskeletal tissue',
        },
        {
            _id: '61eab2212874f7cd18a0a92a',
            icd_code: 'A52.79',
            description: 'Other symptomatic late syphilis',
        },
        {
            _id: '61eab2212874f7cd18a0a92b',
            icd_code: 'A52.8',
            description: 'Late syphilis, latent',
        },
        {
            _id: '61eab2212874f7cd18a0a92c',
            icd_code: 'A52.9',
            description: 'Late syphilis, unspecified',
        },
        {
            _id: '61eab2212874f7cd18a0a92d',
            icd_code: 'A53.0',
            description: 'Latent syphilis, unspecified as early or late',
        },
        {
            _id: '61eab2222874f7cd18a0a92e',
            icd_code: 'A53.9',
            description: 'Syphilis, unspecified',
        },
        {
            _id: '61eab2222874f7cd18a0a92f',
            icd_code: 'A54.00',
            description: 'Gonococcal infection of lower genitourinary tract, unspecified',
        },
        {
            _id: '61eab2222874f7cd18a0a930',
            icd_code: 'A54.01',
            description: 'Gonococcal cystitis and urethritis, unspecified',
        },
        {
            _id: '61eab2222874f7cd18a0a931',
            icd_code: 'A54.02',
            description: 'Gonococcal vulvovaginitis, unspecified',
        },
        {
            _id: '61eab2222874f7cd18a0a932',
            icd_code: 'A54.03',
            description: 'Gonococcal cervicitis, unspecified',
        },
        {
            _id: '61eab2222874f7cd18a0a933',
            icd_code: 'A54.09',
            description: 'Other gonococcal infection of lower genitourinary tract',
        },
        {
            _id: '61eab2222874f7cd18a0a934',
            icd_code: 'A54.1',
            description:
                'Gonococcal infection of lower genitourinary tract with periurethral and accessory gland abscess',
        },
        {
            _id: '61eab2222874f7cd18a0a935',
            icd_code: 'A54.21',
            description: 'Gonococcal infection of kidney and ureter',
        },
        {
            _id: '61eab2232874f7cd18a0a936',
            icd_code: 'A54.22',
            description: 'Gonococcal prostatitis',
        },
        {
            _id: '61eab2232874f7cd18a0a937',
            icd_code: 'A54.23',
            description: 'Gonococcal infection of other male genital organs',
        },
        {
            _id: '61eab2232874f7cd18a0a938',
            icd_code: 'A54.24',
            description: 'Gonococcal female pelvic inflammatory disease',
        },
        {
            _id: '61eab2232874f7cd18a0a939',
            icd_code: 'A54.29',
            description: 'Other gonococcal genitourinary infections',
        },
        {
            _id: '61eab2232874f7cd18a0a93a',
            icd_code: 'A54.30',
            description: 'Gonococcal infection of eye, unspecified',
        },
        {
            _id: '61eab2242874f7cd18a0a93b',
            icd_code: 'A54.31',
            description: 'Gonococcal conjunctivitis',
        },
        {
            _id: '61eab2242874f7cd18a0a93c',
            icd_code: 'A54.32',
            description: 'Gonococcal iridocyclitis',
        },
        {
            _id: '61eab2242874f7cd18a0a93d',
            icd_code: 'A54.33',
            description: 'Gonococcal keratitis',
        },
        {
            _id: '61eab2242874f7cd18a0a93e',
            icd_code: 'A54.39',
            description: 'Other gonococcal eye infection',
        },
        {
            _id: '61eab2242874f7cd18a0a93f',
            icd_code: 'A54.40',
            description: 'Gonococcal infection of musculoskeletal system, unspecified',
        },
        {
            _id: '61eab2242874f7cd18a0a940',
            icd_code: 'A54.41',
            description: 'Gonococcal spondylopathy',
        },
        {
            _id: '61eab2252874f7cd18a0a941',
            icd_code: 'A54.42',
            description: 'Gonococcal arthritis',
        },
        {
            _id: '61eab2252874f7cd18a0a942',
            icd_code: 'A54.43',
            description: 'Gonococcal osteomyelitis',
        },
        {
            _id: '61eab2252874f7cd18a0a943',
            icd_code: 'A54.49',
            description: 'Gonococcal infection of other musculoskeletal tissue',
        },
        {
            _id: '61eab2252874f7cd18a0a944',
            icd_code: 'A54.5',
            description: 'Gonococcal pharyngitis',
        },
        {
            _id: '61eab2252874f7cd18a0a945',
            icd_code: 'A54.6',
            description: 'Gonococcal infection of anus and rectum',
        },
        {
            _id: '61eab2252874f7cd18a0a946',
            icd_code: 'A54.81',
            description: 'Gonococcal meningitis',
        },
        {
            _id: '61eab2252874f7cd18a0a947',
            icd_code: 'A54.82',
            description: 'Gonococcal brain abscess',
        },
        {
            _id: '61eab2262874f7cd18a0a948',
            icd_code: 'A54.83',
            description: 'Gonococcal heart infection',
        },
        {
            _id: '61eab2262874f7cd18a0a949',
            icd_code: 'A54.84',
            description: 'Gonococcal pneumonia',
        },
        {
            _id: '61eab2262874f7cd18a0a94a',
            icd_code: 'A54.85',
            description: 'Gonococcal peritonitis',
        },
        {
            _id: '61eab2262874f7cd18a0a94b',
            icd_code: 'A54.86',
            description: 'Gonococcal sepsis',
        },
        {
            _id: '61eab2262874f7cd18a0a94c',
            icd_code: 'A54.89',
            description: 'Other gonococcal infections',
        },
        {
            _id: '61eab2262874f7cd18a0a94d',
            icd_code: 'A54.9',
            description: 'Gonococcal infection, unspecified',
        },
        {
            _id: '61eab2262874f7cd18a0a94e',
            icd_code: 'A55',
            description: 'Chlamydial lymphogranuloma (venereum)',
        },
        {
            _id: '61eab2272874f7cd18a0a94f',
            icd_code: 'A56.00',
            description: 'Chlamydial infection of lower genitourinary tract, unspecified',
        },
        {
            _id: '61eab2272874f7cd18a0a950',
            icd_code: 'A56.01',
            description: 'Chlamydial cystitis and urethritis',
        },
        {
            _id: '61eab2272874f7cd18a0a951',
            icd_code: 'A56.02',
            description: 'Chlamydial vulvovaginitis',
        },
        {
            _id: '61eab2272874f7cd18a0a952',
            icd_code: 'A56.09',
            description: 'Other chlamydial infection of lower genitourinary tract',
        },
        {
            _id: '61eab2272874f7cd18a0a953',
            icd_code: 'A56.11',
            description: 'Chlamydial female pelvic inflammatory disease',
        },
        {
            _id: '61eab2272874f7cd18a0a954',
            icd_code: 'A56.19',
            description: 'Other chlamydial genitourinary infection',
        },
        {
            _id: '61eab2282874f7cd18a0a955',
            icd_code: 'A56.2',
            description: 'Chlamydial infection of genitourinary tract, unspecified',
        },
        {
            _id: '61eab2282874f7cd18a0a956',
            icd_code: 'A56.3',
            description: 'Chlamydial infection of anus and rectum',
        },
        {
            _id: '61eab2282874f7cd18a0a957',
            icd_code: 'A56.4',
            description: 'Chlamydial infection of pharynx',
        },
        {
            _id: '61eab2282874f7cd18a0a958',
            icd_code: 'A56.8',
            description: 'Sexually transmitted chlamydial infection of other sites',
        },
        {
            _id: '61eab2282874f7cd18a0a959',
            icd_code: 'A57',
            description: 'Chancroid',
        },
        {
            _id: '61eab2282874f7cd18a0a95a',
            icd_code: 'A58',
            description: 'Granuloma inguinale',
        },
        {
            _id: '61eab2282874f7cd18a0a95b',
            icd_code: 'A59.00',
            description: 'Urogenital trichomoniasis, unspecified',
        },
        {
            _id: '61eab2292874f7cd18a0a95c',
            icd_code: 'A59.01',
            description: 'Trichomonal vulvovaginitis',
        },
        {
            _id: '61eab2292874f7cd18a0a95d',
            icd_code: 'A59.02',
            description: 'Trichomonal prostatitis',
        },
        {
            _id: '61eab2292874f7cd18a0a95e',
            icd_code: 'A59.03',
            description: 'Trichomonal cystitis and urethritis',
        },
        {
            _id: '61eab2292874f7cd18a0a95f',
            icd_code: 'A59.09',
            description: 'Other urogenital trichomoniasis',
        },
        {
            _id: '61eab2292874f7cd18a0a960',
            icd_code: 'A59.8',
            description: 'Trichomoniasis of other sites',
        },
        {
            _id: '61eab2292874f7cd18a0a961',
            icd_code: 'A59.9',
            description: 'Trichomoniasis, unspecified',
        },
        {
            _id: '61eab2292874f7cd18a0a962',
            icd_code: 'A60.00',
            description: 'Herpesviral infection of urogenital system, unspecified',
        },
        {
            _id: '61eab2292874f7cd18a0a963',
            icd_code: 'A60.01',
            description: 'Herpesviral infection of penis',
        },
        {
            _id: '61eab22a2874f7cd18a0a964',
            icd_code: 'A60.02',
            description: 'Herpesviral infection of other male genital organs',
        },
        {
            _id: '61eab22a2874f7cd18a0a965',
            icd_code: 'A60.03',
            description: 'Herpesviral cervicitis',
        },
        {
            _id: '61eab22a2874f7cd18a0a966',
            icd_code: 'A60.04',
            description: 'Herpesviral vulvovaginitis',
        },
        {
            _id: '61eab22a2874f7cd18a0a967',
            icd_code: 'A60.09',
            description: 'Herpesviral infection of other urogenital tract',
        },
        {
            _id: '61eab22a2874f7cd18a0a968',
            icd_code: 'A60.1',
            description: 'Herpesviral infection of perianal skin and rectum',
        },
        {
            _id: '61eab22a2874f7cd18a0a969',
            icd_code: 'A60.9',
            description: 'Anogenital herpesviral infection, unspecified',
        },
        {
            _id: '61eab22a2874f7cd18a0a96a',
            icd_code: 'A63.0',
            description: 'Anogenital (venereal) warts',
        },
        {
            _id: '61eab22b2874f7cd18a0a96b',
            icd_code: 'A63.8',
            description: 'Other specified predominantly sexually transmitted diseases',
        },
        {
            _id: '61eab22b2874f7cd18a0a96c',
            icd_code: 'A64',
            description: 'Unspecified sexually transmitted disease',
        },
        {
            _id: '61eab22b2874f7cd18a0a96d',
            icd_code: 'A65',
            description: 'Nonvenereal syphilis',
        },
        {
            _id: '61eab22b2874f7cd18a0a96e',
            icd_code: 'A66.0',
            description: 'Initial lesions of yaws',
        },
        {
            _id: '61eab22b2874f7cd18a0a96f',
            icd_code: 'A66.1',
            description: 'Multiple papillomata and wet crab yaws',
        },
        {
            _id: '61eab22b2874f7cd18a0a970',
            icd_code: 'A66.2',
            description: 'Other early skin lesions of yaws',
        },
        {
            _id: '61eab22b2874f7cd18a0a971',
            icd_code: 'A66.3',
            description: 'Hyperkeratosis of yaws',
        },
        {
            _id: '61eab22b2874f7cd18a0a972',
            icd_code: 'A66.4',
            description: 'Gummata and ulcers of yaws',
        },
        {
            _id: '61eab22c2874f7cd18a0a973',
            icd_code: 'A66.5',
            description: 'Gangosa',
        },
        {
            _id: '61eab22c2874f7cd18a0a974',
            icd_code: 'A66.6',
            description: 'Bone and joint lesions of yaws',
        },
        {
            _id: '61eab22c2874f7cd18a0a975',
            icd_code: 'A66.7',
            description: 'Other manifestations of yaws',
        },
        {
            _id: '61eab22c2874f7cd18a0a976',
            icd_code: 'A66.8',
            description: 'Latent yaws',
        },
        {
            _id: '61eab22c2874f7cd18a0a977',
            icd_code: 'A66.9',
            description: 'Yaws, unspecified',
        },
        {
            _id: '61eab22c2874f7cd18a0a978',
            icd_code: 'A67.0',
            description: 'Primary lesions of pinta',
        },
        {
            _id: '61eab22c2874f7cd18a0a979',
            icd_code: 'A67.1',
            description: 'Intermediate lesions of pinta',
        },
        {
            _id: '61eab22c2874f7cd18a0a97a',
            icd_code: 'A67.2',
            description: 'Late lesions of pinta',
        },
        {
            _id: '61eab22d2874f7cd18a0a97b',
            icd_code: 'A67.3',
            description: 'Mixed lesions of pinta',
        },
        {
            _id: '61eab22d2874f7cd18a0a97c',
            icd_code: 'A67.9',
            description: 'Pinta, unspecified',
        },
        {
            _id: '61eab22d2874f7cd18a0a97d',
            icd_code: 'A68.0',
            description: 'Louse-borne relapsing fever',
        },
        {
            _id: '61eab22d2874f7cd18a0a97e',
            icd_code: 'A68.1',
            description: 'Tick-borne relapsing fever',
        },
        {
            _id: '61eab22d2874f7cd18a0a97f',
            icd_code: 'A68.9',
            description: 'Relapsing fever, unspecified',
        },
        {
            _id: '61eab22d2874f7cd18a0a980',
            icd_code: 'A69.0',
            description: 'Necrotizing ulcerative stomatitis',
        },
        {
            _id: '61eab22d2874f7cd18a0a981',
            icd_code: 'A69.1',
            description: "Other Vincent's infections",
        },
        {
            _id: '61eab22d2874f7cd18a0a982',
            icd_code: 'A69.20',
            description: 'Lyme disease, unspecified',
        },
        {
            _id: '61eab22e2874f7cd18a0a983',
            icd_code: 'A69.21',
            description: 'Meningitis due to Lyme disease',
        },
        {
            _id: '61eab22e2874f7cd18a0a984',
            icd_code: 'A69.22',
            description: 'Other neurologic disorders in Lyme disease',
        },
        {
            _id: '61eab22e2874f7cd18a0a985',
            icd_code: 'A69.23',
            description: 'Arthritis due to Lyme disease',
        },
        {
            _id: '61eab22e2874f7cd18a0a986',
            icd_code: 'A69.29',
            description: 'Other conditions associated with Lyme disease',
        },
        {
            _id: '61eab22e2874f7cd18a0a987',
            icd_code: 'A69.8',
            description: 'Other specified spirochetal infections',
        },
        {
            _id: '61eab22f2874f7cd18a0a988',
            icd_code: 'A69.9',
            description: 'Spirochetal infection, unspecified',
        },
        {
            _id: '61eab22f2874f7cd18a0a989',
            icd_code: 'A70',
            description: 'Chlamydia psittaci infections',
        },
        {
            _id: '61eab22f2874f7cd18a0a98a',
            icd_code: 'A71.0',
            description: 'Initial stage of trachoma',
        },
        {
            _id: '61eab22f2874f7cd18a0a98b',
            icd_code: 'A71.1',
            description: 'Active stage of trachoma',
        },
        {
            _id: '61eab22f2874f7cd18a0a98c',
            icd_code: 'A71.9',
            description: 'Trachoma, unspecified',
        },
        {
            _id: '61eab2302874f7cd18a0a98d',
            icd_code: 'A74.0',
            description: 'Chlamydial conjunctivitis',
        },
        {
            _id: '61eab2302874f7cd18a0a98e',
            icd_code: 'A74.81',
            description: 'Chlamydial peritonitis',
        },
        {
            _id: '61eab2302874f7cd18a0a98f',
            icd_code: 'A74.89',
            description: 'Other chlamydial diseases',
        },
        {
            _id: '61eab2302874f7cd18a0a990',
            icd_code: 'A74.9',
            description: 'Chlamydial infection, unspecified',
        },
        {
            _id: '61eab2302874f7cd18a0a991',
            icd_code: 'A75.0',
            description: 'Epidemic louse-borne typhus fever due to Rickettsia prowazekii',
        },
        {
            _id: '61eab2302874f7cd18a0a992',
            icd_code: 'A75.1',
            description: "Recrudescent typhus [Brill's disease]",
        },
        {
            _id: '61eab2302874f7cd18a0a993',
            icd_code: 'A75.2',
            description: 'Typhus fever due to Rickettsia typhi',
        },
        {
            _id: '61eab2312874f7cd18a0a994',
            icd_code: 'A75.3',
            description: 'Typhus fever due to Rickettsia tsutsugamushi',
        },
        {
            _id: '61eab2312874f7cd18a0a995',
            icd_code: 'A75.9',
            description: 'Typhus fever, unspecified',
        },
        {
            _id: '61eab2312874f7cd18a0a996',
            icd_code: 'A77.0',
            description: 'Spotted fever due to Rickettsia rickettsii',
        },
        {
            _id: '61eab2312874f7cd18a0a997',
            icd_code: 'A77.1',
            description: 'Spotted fever due to Rickettsia conorii',
        },
        {
            _id: '61eab2312874f7cd18a0a998',
            icd_code: 'A77.2',
            description: 'Spotted fever due to Rickettsia siberica',
        },
        {
            _id: '61eab2312874f7cd18a0a999',
            icd_code: 'A77.3',
            description: 'Spotted fever due to Rickettsia australis',
        },
        {
            _id: '61eab2312874f7cd18a0a99a',
            icd_code: 'A77.40',
            description: 'Ehrlichiosis, unspecified',
        },
        {
            _id: '61eab2312874f7cd18a0a99b',
            icd_code: 'A77.41',
            description: 'Ehrlichiosis chafeensis [E. chafeensis]',
        },
        {
            _id: '61eab2322874f7cd18a0a99c',
            icd_code: 'A77.49',
            description: 'Other ehrlichiosis',
        },
        {
            _id: '61eab2322874f7cd18a0a99d',
            icd_code: 'A77.8',
            description: 'Other spotted fevers',
        },
        {
            _id: '61eab2322874f7cd18a0a99e',
            icd_code: 'A77.9',
            description: 'Spotted fever, unspecified',
        },
        {
            _id: '61eab2322874f7cd18a0a99f',
            icd_code: 'A78',
            description: 'Q fever',
        },
        {
            _id: '61eab2322874f7cd18a0a9a0',
            icd_code: 'A79.0',
            description: 'Trench fever',
        },
        {
            _id: '61eab2322874f7cd18a0a9a1',
            icd_code: 'A79.1',
            description: 'Rickettsialpox due to Rickettsia akari',
        },
        {
            _id: '61eab2322874f7cd18a0a9a2',
            icd_code: 'A79.81',
            description: 'Rickettsiosis due to Ehrlichia sennetsu',
        },
        {
            _id: '61eab2332874f7cd18a0a9a3',
            icd_code: 'A79.82',
            description: 'Anaplasmosis [A. phagocytophilum]',
        },
        {
            _id: '61eab2332874f7cd18a0a9a4',
            icd_code: 'A79.89',
            description: 'Other specified rickettsioses',
        },
        {
            _id: '61eab2332874f7cd18a0a9a5',
            icd_code: 'A79.9',
            description: 'Rickettsiosis, unspecified',
        },
        {
            _id: '61eab2332874f7cd18a0a9a6',
            icd_code: 'A80.0',
            description: 'Acute paralytic poliomyelitis, vaccine-associated',
        },
        {
            _id: '61eab2332874f7cd18a0a9a7',
            icd_code: 'A80.1',
            description: 'Acute paralytic poliomyelitis, wild virus, imported',
        },
        {
            _id: '61eab2332874f7cd18a0a9a8',
            icd_code: 'A80.2',
            description: 'Acute paralytic poliomyelitis, wild virus, indigenous',
        },
        {
            _id: '61eab2332874f7cd18a0a9a9',
            icd_code: 'A80.30',
            description: 'Acute paralytic poliomyelitis, unspecified',
        },
        {
            _id: '61eab2332874f7cd18a0a9aa',
            icd_code: 'A80.39',
            description: 'Other acute paralytic poliomyelitis',
        },
        {
            _id: '61eab2342874f7cd18a0a9ab',
            icd_code: 'A80.4',
            description: 'Acute nonparalytic poliomyelitis',
        },
        {
            _id: '61eab2342874f7cd18a0a9ac',
            icd_code: 'A80.9',
            description: 'Acute poliomyelitis, unspecified',
        },
        {
            _id: '61eab2342874f7cd18a0a9ad',
            icd_code: 'A81.00',
            description: 'Creutzfeldt-Jakob disease, unspecified',
        },
        {
            _id: '61eab2342874f7cd18a0a9ae',
            icd_code: 'A81.01',
            description: 'Variant Creutzfeldt-Jakob disease',
        },
        {
            _id: '61eab2352874f7cd18a0a9af',
            icd_code: 'A81.09',
            description: 'Other Creutzfeldt-Jakob disease',
        },
    ],
    billingMasters: ['0', '1', '2', '3'],
    specialityDocumentMasters: [
        {
            _id: '6214c6095b3180cf58df0b56',
            document_url: 'https://sonora-s3-user-uploads.s3.amazonaws.com/1645528585106.png',
            document_name:
                'asdfasdfasdfasdfasiufbyaisydfia ysdifyaisyf asofasifyiayfiuyaiusydfuioayidufyuuuuuuuuuuuuuuuasdfdddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddsdfasdfasdfasdfassssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss',
            speciality_id: 1,
        },
        {
            _id: '6214c71e5b3180cf58df0bcb',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645528862785.png',
            document_name: 'asdfasdfasdf',
            speciality_id: 1,
        },
        {
            _id: '6214c78b5b3180cf58df0be0',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645528970911.png',
            document_name: 'Asdfasdfasdf',
            speciality_id: 2,
        },
        {
            _id: '6214c8105b3180cf58df0c4c',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645529104554.png',
            document_name: 'Asdfasdfasdf',
            speciality_id: 1,
        },
        {
            _id: '6214c8aa5b3180cf58df0c5b',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645529258662.png',
            document_name: 'Aasdf@asd45t/}--',
            speciality_id: 4,
        },
        {
            _id: '6214ca535b3180cf58df0c85',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645529682648.pdf',
            document_name: 'afa',
            speciality_id: 4,
        },
        {
            _id: '6214cd3e5b3180cf58df0d2e',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645530429985.jpg',
            document_name: 'adf',
            speciality_id: 6,
        },
        {
            _id: '6214cd5d5b3180cf58df0d38',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645530681248.xlsx',
            document_name: 'yyyyaaa',
            speciality_id: 4,
        },
        {
            _id: '6214cdb75b3180cf58df0d45',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645530551317.jpg',
            document_name: 'ooo',
            speciality_id: 8,
        },
        {
            _id: '6214cdd25b3180cf58df0d4e',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645530578043.jpg',
            document_name: 'ooo',
            speciality_id: 15,
        },
        {
            _id: '6214cde55b3180cf58df0d57',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645530597596.png',
            document_name: 'pfghd',
            speciality_id: 10,
        },
        {
            _id: '6214d07b5b3180cf58df0dcc',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645531259085.png',
            document_name: 'Yyyyaaa',
            speciality_id: 4,
        },
        {
            _id: '6214d57d5b3180cf58df0ebd',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645532540800.png',
            document_name: 'testO',
            speciality_id: 3,
        },
        {
            _id: '6214d5b95b3180cf58df0ec8',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645532601128.png',
            document_name: 'testG',
            speciality_id: 4,
        },
        {
            _id: '6214df515b3180cf58df1123',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645535057392.png',
            document_name: 'oto_test',
            speciality_id: 3,
        },
        {
            _id: '6214ffbe5b3180cf58df1c4f',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645543358206.jpg',
            document_name: 'Adf',
            speciality_id: 6,
        },
        {
            _id: '621500155b3180cf58df1c67',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645543445523.png',
            document_name: 'bbbb',
            speciality_id: 8,
        },
        {
            _id: '621500285b3180cf58df1c70',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645543464187.jpeg',
            document_name: 'Bbbb',
            speciality_id: 8,
        },
        {
            _id: '621500985b3180cf58df1c81',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645543576492.png',
            document_name: 'max',
            speciality_id: 9,
        },
        {
            _id: '621500b15b3180cf58df1c8a',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645543601533.png',
            document_name: 'Max',
            speciality_id: 9,
        },
        {
            _id: '6215bf87777fec29166e154f',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645592452929.pdf',
            document_name: 'rt',
            speciality_id: 10,
        },
        {
            _id: '6215c5f3777fec29166e1745',
            document_url:
                'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/1645594097031.pdf',
            document_name: 'rtyrt',
            speciality_id: 3,
        },
    ],
    therapyPlanMasters: [
        {
            _id: '61de7c73f6291371eda0d7d0',
            seq: 1,
            name: 'Continue established treatment plan',
        },
        {
            _id: '61de7c73f6291371eda0d7d1',
            seq: 2,
            name: 'Change in treatment plan (described below)',
        },
        {
            _id: '61de7c73f6291371eda0d7d2',
            seq: 3,
            name: 'Refer to Psychiatrist',
        },
        {
            _id: '61de7c73f6291371eda0d7d3',
            seq: 4,
            name: 'Interpersonal Psychotherapy (IPT)',
        },
        {
            _id: '61de7c73f6291371eda0d7d4',
            seq: 5,
            name: 'Family Therapy',
        },
        {
            _id: '61de7c73f6291371eda0d7d5',
            seq: 6,
            name: 'Supportive Therapy',
        },
        {
            _id: '61de7c73f6291371eda0d7d6',
            seq: 7,
            name: 'Psychodynamic Therapy',
        },
        {
            _id: '61de7c73f6291371eda0d7d7',
            seq: 8,
            name: 'Behavioral Activation',
        },
        {
            _id: '61de7c73f6291371eda0d7d8',
            seq: 9,
            name: 'Cognitive Behavioral Therapy (CBT)',
        },
        {
            _id: '61de7c73f6291371eda0d7d9',
            seq: 10,
            name: 'Dialectical Behavior Therapy (DBT)',
        },
        {
            _id: '61de7c73f6291371eda0d7da',
            seq: 11,
            name: 'Social skills training',
        },
        {
            _id: '61de7c73f6291371eda0d7db',
            seq: 12,
            name: 'Motivational interviewing',
        },
    ],
    sessionFocusMasters: [
        {
            _id: '61de5565e7b983cd1ef1dad2',
            seq: 1,
            name: 'Assessment',
            label: 'assessment session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad3',
            seq: 2,
            name: 'Treatment planning',
            label: 'treatment planning session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad4',
            seq: 3,
            name: 'Intervention targeting specific problem(s) (also complete "Treatment Approach" section)',
            label: 'intervention targeting specific problem(s)',
        },
        {
            _id: '61de5565e7b983cd1ef1dad5',
            seq: 4,
            name: 'Maintenance/Support (targeting improvement or maintenance of overall functioning)',
            label: 'maintenance or supportive session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad6',
            seq: 5,
            name: 'Booster session',
            label: 'Booster session',
        },
        {
            _id: '61de5565e7b983cd1ef1dad7',
            seq: 6,
            name: 'Termination session',
            label: 'Termination session',
        },
    ],
    planNextAppointmentMasters: [
        {
            _id: '61dec73837c29c698908002f',
            seq: 1,
            name: 'Patient will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
        {
            _id: '61dec73837c29c6989080030',
            seq: 2,
            name: 'Parent will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
        {
            _id: '61dec73837c29c6989080031',
            seq: 3,
            name: 'Caregiver will contact office for next appointment',
            group_type: 'plan_next_appointment',
        },
    ],
    interventionMasters: [
        {
            _id: '61de5565e7b983cd1ef1dada',
            seq: 1,
            name: 'Psychoeducation',
            label: 'Psychoeducation',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadb',
            seq: 2,
            name: 'Hierarchy',
            label: 'Hierarchy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadc',
            seq: 3,
            name: 'Breathing/Relaxation/Other physiological management',
            label: 'Breathing/Relaxation/Other physiological management',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadd',
            seq: 4,
            name: 'Cognitive modification strategies (direct work on thinking, behavioral experiments to modify thinking)',
            label: 'Cognitive modification strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dade',
            seq: 5,
            name: 'Mindfulness-based strategy',
            label: 'Mindfulness-based strategy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dadf',
            seq: 6,
            name: 'Acceptance-based strategy',
            label: 'Acceptance-based strategy',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae0',
            seq: 7,
            name: 'Self monitoring',
            label: 'Self monitoring',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae1',
            seq: 8,
            name: 'Exposure - In vivo (t. assisted)',
            label: 'Exposure - In vivo (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae2',
            seq: 9,
            name: 'Exposure - In vivo (c. independent)',
            label: 'Exposure - In vivo (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae3',
            seq: 10,
            name: 'Exposure - Imaginal (t. assisted)',
            label: 'Exposure - Imaginal (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae4',
            seq: 11,
            name: 'Exposure - Imaginal (c. independent)',
            label: 'Exposure - Imaginal (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae5',
            seq: 12,
            name: 'Exposure - Interoceptive (t. assisted)',
            label: 'Exposure - Interoceptive (t. assisted)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae6',
            seq: 13,
            name: 'Exposure - Interoceptive (c. independent)',
            label: 'Exposure - Interoceptive (c. independent)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae7',
            seq: 14,
            name: 'Response prevention / Safety behaviors',
            label: 'Response prevention / Safety behaviors',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae8',
            seq: 15,
            name: 'Stimulus control strategies',
            label: 'Stimulus control strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1dae9',
            seq: 16,
            name: 'Habit reversal strategies',
            label: 'Habit reversal strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daea',
            seq: 17,
            name: 'Contingency management (out of session or in session)',
            label: 'Contingency management (out of session or in session)',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daeb',
            seq: 18,
            name: 'Problem solving',
            label: 'Problem solving',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daec',
            seq: 19,
            name: 'Validation',
            label: 'Validation',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daed',
            seq: 20,
            name: 'Skill training (specific skill)',
            label: 'Skill training',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daee',
            seq: 21,
            name: 'Behavioral activation strategies',
            label: 'Behavioral activation strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daef',
            seq: 22,
            name: 'Emotion identification/regulation strategies',
            label: 'Emotion identification/regulation strategies',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf0',
            seq: 23,
            name: 'Functional analysis',
            label: 'Functional analysis',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf1',
            seq: 24,
            name: 'Relapse preventions',
            label: 'Relapse preventions',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf2',
            seq: 25,
            name: 'Motivation enhancement',
            label: 'Motivation enhancement',
            additional: false,
            description: '',
            main: false,
        },
        {
            _id: '61de5565e7b983cd1ef1daf3',
            seq: 26,
            name: 'FAP-based strategies',
            label: 'FAP-based strategies',
            additional: false,
            description: '',
            main: false,
        },
    ],
    envStrategyMasters: [
        {
            _id: '61de7c73f6291371eda0d7b7',
            seq: 1,
            section: 1,
            name: 'School/Teacher intervention',
        },
        {
            _id: '61de7c73f6291371eda0d7b8',
            seq: 2,
            section: 1,
            name: 'Brief family intervention',
        },
        {
            _id: '61de7c73f6291371eda0d7b9',
            seq: 3,
            section: 1,
            name: 'Behavioral Parent Training',
        },
        {
            _id: '61de7c73f6291371eda0d7ba',
            seq: 1,
            section: 2,
            name: 'Attending',
        },
        {
            _id: '61de7c73f6291371eda0d7bb',
            seq: 2,
            section: 2,
            name: 'Praise',
        },

        {
            _id: '61de7c73f6291371eda0d7bc',
            seq: 3,
            section: 2,
            name: 'Ignoring',
        },

        {
            _id: '61de7c73f6291371eda0d7bd',
            seq: 4,
            section: 2,
            name: 'Tangible rewards',
        },
        {
            _id: '61de7c73f6291371eda0d7be',
            seq: 5,
            section: 2,
            name: 'Time out',
        },
        {
            _id: '61de7c73f6291371eda0d7bf',
            seq: 6,
            section: 2,
            name: 'Parent emotion regulation',
        },
        {
            _id: '61de7c73f6291371eda0d7c0',
            seq: 7,
            section: 2,
            name: 'Consequences',
        },
        {
            _id: '61de7c73f6291371eda0d7c1',
            seq: 8,
            section: 2,
            name: 'Effective Directions',
        },
        {
            _id: '61de7c73f6291371eda0d7c2',
            seq: 9,
            section: 2,
            name: 'Family rules and routines',
        },
        {
            _id: '61de7c73f6291371eda0d7c3',
            seq: 10,
            section: 2,
            name: 'Coordinating parenting',
        },
        {
            _id: '61de7c73f6291371eda0d7c4',
            seq: 11,
            section: 2,
            name: 'Problem solving',
        },
    ],
    clinicalImpMasters: [
        {
            _id: '61de7c73f6291371eda0d7c7',
            seq: 1,
            name: 'Very much improved since the initiation of treatment',
            label: 'Very much improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7c8',
            seq: 2,
            name: 'Much improved',
            label: 'Much improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7c9',
            seq: 3,
            name: 'Minimally improved',
            label: 'Minimally improved since',
        },
        {
            _id: '61de7c73f6291371eda0d7ca',
            seq: 4,
            name: 'No change from initiation of treatment',
            label: 'unchanged from',
        },
        {
            _id: '61de7c73f6291371eda0d7cb',
            seq: 5,
            name: 'Minimally worse',
            label: 'Minimally worse since',
        },
        {
            _id: '61de7c73f6291371eda0d7cc',
            seq: 6,
            name: 'Much worse',
            label: 'Much worse since',
        },
        {
            _id: '61de7c73f6291371eda0d7cd',
            seq: 7,
            name: 'Very much worse since the initiation of treatment',
            label: 'Very much worse since',
        },
    ],
}

export const genrateButtonIdentification = (finalStatement, narrative) =>{
    const identifier = finalStatement + "\n\n -------Additional Provider comments and analysis below this line-------\n"
    const include = "-------Additional Provider comments and analysis below this line-------\n"
    if(finalStatement === ""){return}
    if (narrative?.includes(include)) {
        const placeholderIndex = (narrative).indexOf(include)
        const textAfterPlaceholder = (narrative).substring(placeholderIndex + include.length);
        return(`${finalStatement ? `${identifier}` : ''}${textAfterPlaceholder}`)
    } 
        return(`${finalStatement ? `${identifier}` : ''}${narrative ? narrative : "" }`)
}
