import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { Pagination } from 'shared/elements/Pagination'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { NoRowsOverlayEl } from 'shared/elements'
import { Box, Grid, Typography } from '@mui/material'
import { useStyles } from './styles'
import AddIcon from '@mui/icons-material/Add'
import { Button as ButtonCustom } from 'components/custom'
import { useNotification } from 'shared/elements/Notification'
import { _getClinicalNotesFilterPatient } from 'containers/ClinicalNotes/api/services'
import { useParams, useHistory } from 'react-router-dom'
import {
    allOutComeList,
    allSystemNotification,
    deleteOutcome,
    deleteSysNotification,
} from 'services/Patient/patient'
import moment from 'moment'
import AlertModal from './AlertModal'

function SystemNotification() {
    const notification = useNotification()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [systemNotificationData, setSystemNotificationData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const classes = useStyles()
    const history = useHistory()

    const handleOpen = (id) => {
        if (id) {
            history.push('/settings/system-announcements/edit/:id', { id })
        } else {
            history.push('/settings/system-announcements/new')
        }
    }

    const handleDelete = async (id) => {
        try {
            const res = await deleteSysNotification(id)
            if (res?.status === 200) {
                const success = 'System announcement  deleted successfully'
                notification(success, 'success')
                getNotifications()
            }
        } catch (err) {
            const errMsg = `Patient Outcome can't delete`
            notification(errMsg, 'error')
        }
    }

    const getNotifications = async () => {
        try {
            setisLoading(true)
            const res = await allSystemNotification({ page, limit })

            if (res) {
                setSystemNotificationData(res?.data?.data || [])
                setTotal(res?.data?.pagination?.totalItems)
            }
            setisLoading(false)
        } catch (err) {
            notification(err?.message, 'error')
            setisLoading(false)
        }
    }

    const columnsForPatientOutcomes = [
        {
            headerName: 'Announcement Title',
            headerClassName: 'super-app-theme--header',
            field: 'announcement_title',
            flex: 0.6,
            sortable: false,
            renderCell: ({ row }) => {
                return row?.announcement_title || '-'
            },
        },
        {
            headerName: 'Date(s)',
            headerClassName: 'super-app-theme--header',
            field: 'speciality',
            flex: 0.4,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    `${
                        row?.duration_start_date
                            ? moment(row?.duration_start_date?.split('T')[0]).format('MM/DD/YYYY')
                            : ''
                    } - ${
                        row?.duration_end_date
                            ? moment(row?.duration_end_date?.split('T')[0]).format('MM/DD/YYYY')
                            : ''
                    }` || '-'
                )
            },
        },
        {
            headerName: 'Created By',
            headerClassName: 'super-app-theme--header',
            field: 'created_by',
            flex: 1.0,
            sortable: false,
            renderCell: ({ row }) => {
                return row?.created_by || '-'
            },
        },

        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.2,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => {
                                handleOpen(row.id)
                            },
                        },
                        {
                            text: 'Delete',
                            handler: () => handleDelete(row.id),
                        },
                    ]}
                    deleteMessage="Are you sure you want to delete this announcement?"
                />
            ),
        },
    ]

    useEffect(() => {
        getNotifications()
    }, [page])

    return (
        <Grid container style={{ padding: '15px' }}>
            <Grid container style={{ borderBottom: '1px solid #CDCDCD', marginBottom: '15px' }}>
                <Typography className={classes.mainMenuName}>System Announcements</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between" style={{ paddingLeft: '5px' }}>
                    <Grid
                        container
                        flex={1}
                        justifyContent="end"
                        spacing={1}
                        className={classes?.subtabDiv}>
                        <Grid item>
                            <ButtonCustom
                                tooltip="Create System Announcements"
                                variant="contained"
                                color="primary"
                                onClick={() => handleOpen()}
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.form_list_container_new}`}>
                <Box className={classes.wrap_data_grid_new}>
                    <MuiDataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={systemNotificationData}
                        columns={columnsForPatientOutcomes}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={(e) => {
                                        setPage(e)
                                    }}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default SystemNotification
