import React, { useEffect, useState, useRef } from 'react'
import { makeStyles, Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@mui/material/IconButton';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { isEmpty } from 'lodash-es'
import SearchIcon from '../../../assests/icons/search_icon.svg'
import { Autocomplete, List } from '@mui/material'
import { getPatientByUserId } from 'services/Patient/patient'
import Divider from '@mui/material/Divider'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Folder_svg from '../../../assests/icons/Folder_svg.svg'
import Folder_svg_selected from '../../../assests/icons/Folder_svg_seleted.svg'
import { ButtonIconEl } from 'shared/elements'
import InfoPopOver from 'components/shared/Elements/InfoPopOver'
import { getCustomizedFormattedDate } from 'utilities/dateOperations'
import { useNotification } from 'shared/elements/Notification'
import { _getClinicalNotesFilterPatient } from 'containers/ClinicalNotes/api/services';
import { useLoadItems } from 'utilities/useLoadItems';
import { getPatientName } from 'containers/PatientsSetting/shared';

const PAGE_SIZE = 10;

const useStyles = makeStyles(() => ({
    textAreaGlobalSearch: {
        color: "#303E4E !important",
        fontFamily: "Rubik !important",
        fontSize: "14px !important" ,
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        lineHeight: "140% !important"
    },
    searchPatientGlobalTextField:{
        '& .MuiOutlinedInput-root':{
            padding: '0px 0px 0px 10px !important',
        },
        '& input': {
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='35px' width='300px'>` +
                `<text x='0' y='13' fill='%23999' font-size='14' font-family='sans-serif' letter-spacing='0.5'>Search Patient</text></svg>")`,
            backgroundRepeat: 'no-repeat',
        },
        '& input:hover': {
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='45px' width='300px'>` +
                `<text x='0' y='13' fill='%23999' font-size='14' font-family='sans-serif' letter-spacing='0.5'>Name, MRN, date of birth, phone number</text></svg>")`,
            backgroundRepeat: 'no-repeat',
        },
        '& input:focus': {
            backgroundImage: 'none',
        }
    },
    component:{
        display: 'flex',
        alignContent: 'center'
    },
    searchPatientGlobalItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        }
    },
    paperContainer: {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        maxHeight: '200px',
        overflowX: 'scroll'
    },
    searchPatientGlobalLoader: {
        color: "#303E4E !important",
        fontFamily: "Rubik !important",
        fontSize: "14px !important" ,
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        lineHeight: "140% !important",
        textAlign: 'center'
    }
}))

const renderTextField = ({ name, value, placeholder, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
};

const handleLoadMore = async (page, bundleId, query) => {
    if (isEmpty(query)) return { items: [], hasNextPage: false, bundleId: null, loading: false }

    const response = await _getClinicalNotesFilterPatient({
        page: page + 1,
        bundle_id: page !== 0 ? bundleId : undefined,
        query
    })
    const { data, bundle_id, totalCount } = response.data;
    return {
        items: data.map((item) => ({
            id: item?.id,
            name: getPatientName({ ...item, suffix: null }),
            birthDate: item?.dob
                ? getCustomizedFormattedDate(item?.dob)
                : '',
            phoneNumber: item?.phone_number,
        })),
        hasNextPage: PAGE_SIZE * (page + 1) < totalCount,
        bundleId: bundle_id,
        loading: false,
    }
};

const PatientItem = (props) => {
    const classes = useStyles();
    const {data} = props;
    return (<div className={classes.searchPatientGlobalItem} onClick={(e) => {
        e.preventDefault();
        props.setPatientId(data.id);
        props.setSearchValue(data?.name);
        props.handleAddRow(data);
        props.setValue(data.id);
    }}>
        <div style={{flex: 1}}>
            <span className={classes.textAreaGlobalSearch}>{data?.name}</span>
        </div>
        <div style={{width: '80px', margin: '0 15px'}}>
            <span className={classes.textAreaGlobalSearch}>{data?.birthDate}</span>
        </div>
        <div style={{width: '110px'}}>
            <span className={classes.textAreaGlobalSearch}>{data?.phoneNumber}</span>
        </div>
    </div>);
}

const DropdownPaper = ({
    query,
    setPatientId,
    setSearchValue,
    handleAddRow,
    setValue
}) => {
    const classes = useStyles();
    const notification = useNotification();
    const { loading, items, hasNextPage, error, loadMore } = useLoadItems(handleLoadMore, query, notification);
    const [loaderRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
        delayInMs: 800,
    });

    return (
        <List>
            {items && items.map((item) => (
                <PatientItem
                    setSearchValue={setSearchValue}
                    key={item.id}
                    data={item}
                    setPatientId={setPatientId}
                    handleAddRow={handleAddRow}
                    setValue={setValue}
                />
            ))}

            {(loading || hasNextPage) && (
                <div className={classes.searchPatientGlobalLoader} ref={loaderRef}>
                    Loading Patients List...
                </div>
            )}
            {(!loading && items && !items.length) && (
                <div className={classes.searchPatientGlobalLoader}>
                    Nothing Found.
                </div>
            )}
        </List>
    );
}

const SearchPatientInput = (props) => {
    const {
        disabled,
        handleAddRow,
        error,
        helperText,
        autoLoading,
        getDetailPatient,
        placeholder,
        isSuperBill = false,
        patientStoreIconStyle,
        setPracticeId='',
    } = props;

    const classes = useStyles()

    const [patientId, setPatientId] = useState(props.value);

    const [value, setValue] = useState()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)
    const toolTipDivRef = useRef(null)
    const notification = useNotification();
    
    const [searchResultList, setSearchResultList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const handleTitleClick = (event) => {
        // Toggle the visibility of the tooltip when the title is clicked
        console.log(event);
        event.preventDefault()
        event.stopPropagation()
    }

    function documentClick (e) {
        e.stopPropagation();
        if (e.target.id === 'patient-search-top') return;
        setOpen(false);
        setSearchResultList([])
    }

    useEffect(async () => {
        const patientIdValue = props.value || patientId;
        if (patientIdValue) {
            if (autoLoading) {
                setLoading(true)
            }
            try {
                const response = await getPatientByUserId(patientIdValue)
                if (response?.data) {
                    const { first_name, last_name, middle_name, suffix, clinical_practice_id } = response?.data

                    const namePatient = `${first_name || ''} ${middle_name || ''} ${
                        last_name || ''
                    } ${suffix ? `(${suffix})` : ''}`
                    setSearchResultList([
                        {
                            id: patientIdValue,
                            value: namePatient,
                            label: namePatient,
                            key: patientIdValue,
                        },
                    ])
                    setValue(patientIdValue)
                    setSearchValue(namePatient)
                    getDetailPatient?.(response?.data || null)
                    setPracticeId && setPracticeId(clinical_practice_id);
                }
            } catch (error) {
                notification('Patient Not Exist!', 'error')
                console.log(error)
            } finally {
                if (autoLoading) {
                    setLoading(false)
                }
            }
        }
        if (!patientIdValue) {
            setSearchValue('')
            setValue('')
            getDetailPatient?.(null)
            setPracticeId && setPracticeId(null)
        }
    }, [patientId, props.value]);

    useEffect(()=>{
        document.addEventListener('click',documentClick)
        return ()=> document.removeEventListener('click',documentClick)
    },[])

    /**
     * Patient Record Show in New Tab
     */
    const handleClickNewTabForPatientRecord = React.useCallback(() => {
        if (!value || value === 0) return
        window.open(`/patient/${value}/patient-record`, '_blank')
    }, [value])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                clearIcon={false}
                options={searchResultList}
                inputValue={searchValue}
                value={searchValue}
                open={open}
                style={{ width: isSuperBill ? 'unset' : '540px' }}
                filterOptions={() => {
                    return searchResultList
                }}
                onInputChange={(event, newInputValue) => {
                    if (patientId) {
                        setSearchResultList([]);
                    }

                    if (!event) {
                        return
                    }
                    if (!newInputValue || newInputValue === '') {
                        setSearchValue();
                        return
                    }
                    if (newInputValue === '[object Object]') {
                        return
                    }
                    setSearchValue(newInputValue);
                    setOpen(true);
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: placeholder || 'Search...',
                        props: {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small" disabled={disabled}>
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        <div
                                            style={
                                                patientStoreIconStyle
                                                    ? patientStoreIconStyle
                                                    : {
                                                          position: 'absolute',
                                                          bottom: props?.iconStye?.bottom?props?.iconStye?.bottom:8,
                                                          right: props?.iconStye?.right?props?.iconStye?.right:20,
                                                      }
                                            }>
                                            <ButtonIconEl size="small">
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    <ExpandMore
                                                        style={{
                                                            fontSize: 20,
                                                            color: '#9CA9B8',
                                                            paddingRight: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setOpen(!open)}
                                                    />
                                                )}
                                                <Divider
                                                    variant="middle"
                                                    flexItem
                                                    orientation="vertical"
                                                    className="divider_selectBox"
                                                />

                                                <InfoPopOver
                                                    title={
                                                        value
                                                            ? ' Open patient health record'
                                                            : ''
                                                    }>
                                                    <div
                                                        onMouseOver={(event) => {
                                                            handleTitleClick(event)
                                                        }}
                                                        onClick={handleClickNewTabForPatientRecord}
                                                        style={{
                                                            userSelect: 'none', // Disable text selection
                                                            cursor: value ? 'pointer' : 'none',
                                                            pointerEvents: !value && 'none',
                                                        }}
                                                        ref={toolTipDivRef}>
                                                        <img
                                                            src={
                                                                value
                                                                    ? Folder_svg_selected
                                                                    : Folder_svg
                                                            }
                                                            style={{
                                                                paddingLeft: '7px',
                                                            }}
                                                        />
                                                    </div>
                                                </InfoPopOver>
                                            </ButtonIconEl>
                                        </div>
                                    </>
                                ),
                            },
                            error,
                            helperText,
                        },
                    })
                }
                PaperComponent={() =>
                    <div className={classes.paperContainer}>
                        <DropdownPaper
                            setSearchValue={setSearchValue}
                            setPatientId={setPatientId}
                            query={searchValue}
                            handleAddRow={handleAddRow}
                            setValue={setValue}
                        />
                    </div>
                }
            />
        </Box>
    )
}

export default React.memo(SearchPatientInput)
