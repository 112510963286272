import { Box, Typography } from '@material-ui/core'
import PDFViewer from 'components/ClinicalNotes/reusable/PDFViewer'
import React from 'react'

function Preview({ fileUrl }) {
    return (
        <Box className='fax_pdf_preview_container'>
            {/* <Typography variant='h1'>Fax Preview Coming Soon...</Typography> */}
            <PDFViewer defaultScale={1.7} PDFFile={fileUrl} />
        </Box>
    )
}

export default Preview
