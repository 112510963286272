import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useRef, useState } from 'react'
import { headerNames } from './headerName'
import { toSnakeCase } from 'utilities/stringOperations'
import { TextField, Typography } from '@material-ui/core'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import TextWithMacrosNew from 'components/ClinicalNotes/reusable/TextWithMacrosNew'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'

const useStyles = makeStyles((theme) => ({
    outsideContainer: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        padding: 10,
    },
    insideContainer: {
        borderRadius: '5px',
        // border: '1px solid #EAECF0',
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '600px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 20px',
        background: '#F8F9FB',
        border: '1px solid #e0e0e0',
        borderRadius: '5px 5px 0px 0px',
    },
    copy: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    horizontalDivider: {
        width: '100%',
        borderTop: '1px solid #EAECF0',
    },
    formContainer: {
        // padding: '30px',
        display: 'flex',
        // gap: 20,
    },
    //---------------Left Bar----------------------
    leftBar: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '20%',
        paddingTop: 10,
        border: '1px solid #e0e0e0',
        borderRadius: '0px 0px 0px 5px',
        borderRightWidth: 0,
    },
    leftBarContent: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 10px 10px 0px',
        borderBottom: '1px solid #EDF1F6',
        // width: '80%',
    },
    leftBarAction: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        // padding: '0px 0px 10px 0px',
        // borderBottom: '1px solid #EDF1F6',
        // width: '80%',
    },
    selectedBar: {
        height: '20px',
        borderRadius: '5px',
        marginRight: '10px',
        width: '4px',
    },
    selectedColor: {
        background: '#5571C6',
    },
    nonSelectedColor: {
        background: 'transparent',
    },
    noKnownIssue: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
        '& .MuiFormControlLabel-root': {
            marginLeft: '10px !important',
            marginRight: '4px !important',
            '& .MuiIconButton-root ': {
                padding: '0 !important',
            },
        },
        '& .MuiSwitch-thumb': {
            background: '#303E4E !important'
        },
        '& .MuiSwitch-track': {
            background: '#fff',
            border: '0.5px solid black'
        },
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                background: '#fff !important'
            },
            '& .MuiSwitch-track': {
                background: '#5571C6 !important',
                border: '1px solid #5571C6 !important'
            }
        },
    },
    leftBarItem: {
        color: '#242424',
        fontWeight: 400,
        fontSize: 14,
        width: '100%',
        cursor: 'default',
    },
    leftBarItemNarrative: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 14,
        cursor: 'default',
    },
    sectionCheckCount: {
        borderRadius: '50%',
        background: '#F0AE5F',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            color: 'white',
            fontSize: 12,
            padding: '0px 2px',
            cursor: 'default'
        }
        
    },
    // ---------------Main Content---------------------
    mainContent: {
        maxHeight: '750px',
        // border: '1px solid #CFCFCF',
        borderRadius: '0px 0px 5px 0px',
        width: '80%',
        // padding: '20px 10px',
        display: 'flex',
        flexDirection: 'column',
        // gap: 20,
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid #e0e0e0',
        borderLeftWidth: 0,
        '& div div .MuiFormControlLabel-root': {
            marginLeft: '-1px'
        }
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        // gap: 10,
        padding: '10px 0px 0px 0px',
        '& div .MuiDivider-root': {
            marginBottom: 10,
        },
    },
    // sectionTitle: {
    //     padding: '0 10px'
    // },
    sectionParts: {
        padding: '0 20px 16px 20px',
        '& .MuiIconButton-root': {
            paddingRight: '0 !important',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
        },
        '& .MuiFormControlLabel-root .MuiFormControlLabel-label .MuiTypography-root span': {
            color: '#303E4E !important',
            fontSize: 16,
            width: '100%',
            display: 'block',
        },
        '& div .MuiBox-root .MuiGrid-root .MuiGrid-root': {
            borderRadius: '0px 5px 0px 0px !important',
        },
        '& div .MuiBox-root div .MuiFormControl-root': {
            borderRadius: '0px 0px 5px 0px !important',
        },
        '& div div .MuiFormControlLabel-root': {
            marginLeft: '0px',
            marginRight: '10px !important',
        }
    },
    sectionTitleText: {
        color: '#424242',
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 12
    },
    sectionSubtitleText: {
        color: '#667689',
        fontSize: 10,
    },
    checkboxGroup: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '100%',
        columnGap: 45,
        '& .MuiFormControlLabel-root': {
            width: '30% !important',
        },
        // marginRight: 1000
    },
    sectionNKI: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        '& .MuiSwitch-thumb': {
            background: '#303E4E !important'
        },
        '& .MuiSwitch-track': {
            background: '#fff',
            border: '0.5px solid black'
        },
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                background: '#fff !important'
            },
            '& .MuiSwitch-track': {
                background: '#5571C6 !important',
                border: '1px solid #5571C6 !important'
            }
        },
    },
    trashIcon: {
        '& path': {
            fill: '#E81A1A !important'
        }
    }
}))

function ROSCopy({ lastNarrative, fieldName, copySelectionFieldNames, handleSetSelectedValues }) {
    const classes = useStyles()
    const childSectionRef = useRef(null)

    const [systemHeaders, setSystemHeaders] = useState([])

    const handleChangeTab = (slectedHeader) => {
        setSystemHeaders((state) => {
            return state?.map((stateItem) => {
                if (stateItem?.nativeName === slectedHeader) {
                    return {
                        ...stateItem,
                        selected: true,
                    }
                }
                return {
                    ...stateItem,
                    selected: false,
                }
            })
        })
    }

    useEffect(() => {
        setSystemHeaders(headerNames(lastNarrative))
    }, [lastNarrative])

    return (
        <div className={classes.insideContainer} style={{ margin: 15 }}>
            <div className={classes.header}>
                <Typography
                    style={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: '#303E4E',
                        padding: '5px 0',
                    }}>
                    Review of Systems
                </Typography>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <div className={classes.formContainer}>
                    <div className={classes.leftBar}>
                        {systemHeaders?.map((leftBarItem) => {
                            if(leftBarItem?.nativeName !== 'narrative') {
                                return (
                                    <div
                                        style={{ borderColor: leftBarItem[systemHeaders?.length -1 ] ? '#fff' : '#E0E0E0' }}
                                        className={`${classes.leftBarContent}`} 
                                        key={leftBarItem.header} >
                                        <span
                                            className={`${classes.selectedBar} ${
                                                leftBarItem?.selected
                                                    ? classes.selectedColor
                                                    : classes.nonSelectedColor
                                            }`}></span>
                                        <div className={classes.leftBarAction}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    gap: 4,
                                                    justifyContent: 'flex-start',
                                                    width: '100%',
                                                }}>
                                                <Typography
                                                    style={{ width: 'fit-content' }}
                                                    onClick={() => {
                                                        childSectionRef.current = document?.getElementById(
                                                            `${leftBarItem.nativeName}-child`,
                                                        )
                                                        childSectionRef.current?.scrollIntoView({
                                                            top: childSectionRef.current.offsetTop,
                                                            behavior: 'smooth',
                                                        })
                                                        handleChangeTab(leftBarItem?.nativeName)
                                                    }}
                                                    className={
                                                        leftBarItem?.nativeName !== 'narrative'
                                                            ? classes.leftBarItem
                                                            : classes.leftBarItemNarrative
                                                    }>
                                                    {leftBarItem?.header}
                                                </Typography>
                                            </div>

                                            <span className={classes.noKnownIssue}>
                                                <Typography
                                                    className={classes.leftBarItem}
                                                    style={{ marginTop: 3 }}>
                                                    NKI
                                                </Typography>
                                                <AntSwitch
                                                    checked={
                                                        lastNarrative[leftBarItem?.nativeName]
                                                            ?.no_known_issues
                                                    }
                                                    onChange={() => {}}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>

                    <div className={classes.mainContent}>
                        {systemHeaders?.map((section, sectionIndex) => {
                            if (section?.nativeName !== 'narrative') {
                                return (
                                    <div
                                        style={{
                                            // gap: 10, 
                                            // border: '1px solid #E0E0E0',
                                            display:'flex', 
                                            alignItems:'center'
                                        }}
                                        key={section.nativeName}>
                                        <div 
                                            className={classes.selectionChecks}
                                            style={{
                                                border: '1px solid #E0E0E0',
                                                borderTopWidth: '0px !important',
                                                display:'flex',
                                                alignSelf: 'stretch', 
                                                alignItems:'center',
                                                background: copySelectionFieldNames?.includes(section?.nativeName) ? '#EAECF0' : '#F8F9FB'}}>
                                                <CheckboxOption
                                                    option={{
                                                        value: '',
                                                        label: '',
                                                    }}
                                                    checked={copySelectionFieldNames?.includes(section?.nativeName)}
                                                    onChange={(e) => {
                                                        const checked = e?.target?.checked
                                                        let currentSelections = copySelectionFieldNames
                                                        if(checked && !copySelectionFieldNames?.includes(section?.nativeName)) {
                                                            currentSelections?.push(section?.nativeName)
                                                        }
                                                        if(!checked) {
                                                            currentSelections = currentSelections?.filter(selection => selection !== section?.nativeName)
                                                        }
                                                        handleSetSelectedValues(currentSelections)
                                                    }}
                                                />
                                        </div>

                                        <div
                                            ref={childSectionRef}
                                            className={classes.section}
                                            key={section.nativeName}
                                            id={`${section.nativeName}-child`}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    margin: '0px 18px',
                                                }}>
                                                <Typography className={classes.sectionTitleText}>
                                                    {section?.header}
                                                </Typography>
                                                <div className={classes.sectionNKI}>
                                                    <AntSwitch
                                                        checked={
                                                            lastNarrative[section?.nativeName]?.no_known_issues
                                                        }
                                                        onChange={() => {}}
                                                    />
                                                    <Typography style={{ color: '#303E4E' }}>
                                                        No known issues
                                                    </Typography>
                                                </div>
                                            </div>

                                            {section?.nativeName === 'constitutional' && (
                                                <div className={classes.sectionParts}>
                                                    <Typography className={classes.sectionSubtitleText}>
                                                        Please look at the list of physical symptoms below and
                                                        check off any that you have experienced in the last
                                                        several days. If you have NOT experienced any symptoms
                                                        in an area, be sure to check "None of the above" for
                                                        that area. If you are filling this out on behalf of the
                                                        patient, please answer from the patient's perspective.
                                                    </Typography>
                                                </div>
                                            )}

                                            <div className={`${classes.sectionParts} ${classes.checkboxGroup}`}>
                                                {section?.checkboxOptions?.map((checkBox) => {
                                                    return (
                                                        <CheckboxOption
                                                            key={checkBox?.display}
                                                            option={{
                                                                value: false,
                                                                label: checkBox?.display,
                                                            }}
                                                            disabled={true}
                                                            checked={lastNarrative[
                                                                section?.nativeName
                                                            ]?.symptoms?.includes(
                                                                toSnakeCase(checkBox?.display),
                                                            )}
                                                            onChange={() => {}}
                                                        />
                                                    )
                                                })}
                                            </div>

                                            <div className={classes.sectionParts}>
                                                {lastNarrative[section?.nativeName]?.comments !== "" && (
                                                    <TextField
                                                        name={`${fieldName}.${section?.nativeName}.comments`}
                                                        variant="outlined"
                                                        style={{ marginTop: 10 }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                height: '60px !important',
                                                            },
                                                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                                {
                                                                    fontSize: 14,
                                                                    color: '#989CA8 !important',
                                                                    fontWeight: 400,
                                                                    opacity: 1,
                                                                },
                                                        }}
                                                        placeholder="Comment"
                                                        multiline
                                                        maxRows={2}
                                                        value={lastNarrative[section?.nativeName]?.comments}
                                                        onChange={(e) => {}}
                                                        InputProps={{
                                                            readOnly: true
                                                        }}
                                                    />
                                                )}
                                            </div>

                                            <hr
                                                style={{
                                                    margin: '1px 18px',
                                                    borderColor: section[systemHeaders?.length -1 ] ? '#fff' : '#E0E0E0',
                                                    borderStyle: 'dashed',
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>

                <div className={classes.section}>
                    <div className={`${classes.sectionParts}`} style={{ paddingLeft: 0 }}>
                        <div
                            style={{
                                // gap: 10, 
                                // border: '1px solid #E0E0E0',
                                display:'flex', 
                                alignItems:'center'
                            }}>
                            <div 
                                className={classes.selectionChecks}
                                style={{
                                    border: '1px solid #E0E0E0',
                                    borderTopWidth: '0px !important',
                                    display:'flex',
                                    alignSelf: 'stretch', 
                                    alignItems:'center',
                                    background: copySelectionFieldNames?.includes('narrative') ? '#EAECF0' : '#F8F9FB',
                                    borderRadius: '5px 0px 0px 5px'}}>
                                    <CheckboxOption
                                        option={{
                                            value: '',
                                            label: '',
                                        }}
                                        checked={copySelectionFieldNames?.includes('narrative')}
                                        onChange={(e) => {
                                            const checked = e?.target?.checked
                                            let currentSelections = copySelectionFieldNames
                                            if(checked && !copySelectionFieldNames?.includes('narrative')) {
                                                currentSelections?.push('narrative')
                                            }
                                            if(!checked) {
                                                currentSelections = currentSelections?.filter(selection => selection !== 'narrative')
                                            }
                                            handleSetSelectedValues(currentSelections)
                                        }}
                                    />
                            </div>
                            <TextWithMacrosNew
                                name={`${fieldName}.narrative`}
                                onChange={() => {}}
                                isDisabled={true}
                                rows={12}
                                withoutMacro={false}
                                values={lastNarrative?.narrative}
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ROSCopy
