import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Typography } from '@material-ui/core'

export const LoaderType = Object.freeze({
    LINEAR: 'linear',
    CIRCULAR: 'circular',
})

function CircularProgressWithLabel(props) {
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-flex',
            }}>
            <CircularProgress
                variant="determinate"
                {...props}
            />
            <Box
                sx={{
                    top: 10,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    )
}

const LoadingProgressPage = (props) => {
    return (
        <div className="root-loaderWrapper">
            <div className="loader">
                <CircularProgressWithLabel
                    value={props.progress}
                />
            </div>
        </div>
    )
}

export default LoadingProgressPage
