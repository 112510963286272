import {
    Box,
    Grid,
    Button,
    InputAdornment,
    styled,
    // Tab,
    // Tabs,
    TextField,
    Typography,
    TabScrollButton,
    Divider,
} from '@material-ui/core'

import IconButton from '@mui/material/IconButton'
import SearchIcon from '../../../assests/icons/search_icon.svg'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Category_Notes_TABS } from '../../../constants'
// import NoteItem from './NoteItem'
// import TemplateNoteSettings from './TemplateNoteSettings'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useLocation } from 'react-router-dom'
import TooltipText from './../../../components/shared/Elements/TooltipText'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import moment from 'moment'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ReactSimplyCarousel from 'react-simply-carousel'
import { Container, MenuItem, Popover } from '@mui/material'
import { useSelector } from 'react-redux'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { formatString } from 'containers/CalendarScheduler/utils'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles({
    root: {
        background: 'none',
    },
    container_list: {
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1,
        // height: 800
        zIndex: 1,
    },
    header: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '244px',
        whiteSpace: 'nowrap',
    },
    notchedOutline: {
        border: 'none',
    },
    search_input: {
        width: 'auto',
        height: 40,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    show_search: {
        height: 0,
    },
    iconSetting: {
        width: 25,
        height: 25,
    },
    container_category: {
        padding: '16px 16px 16px 20px',
        width: 350,
        height: 'calc(100vh - 108px)',
        borderRight: '1px solid #e8e8e8',
        transition: 'width 0.5s',
        lineHeight: '30.4px',
    },
    eye_icon: {
        marginRight: 8,
    },
    header_category: {
        marginBottom: 16,
    },
    preview_btn: {
        marginTop: 16,
        borderRadius: 8,
        height: '40px',
        fontWeight: 'normal',
        border: 'none',
    },
    text_back: {
        color: '#303E4E',
        fontSize: '15px',
    },
    date_back: {
        paddingLeft: '5px',
        color: '#5571C6',
        fontSize: '15px',
        cursor: 'pointer',
    },
    divider: {
        height: '17px !important',
        width: '1px !important',
        // marginTop: '0 !important',
        marginBottom: '0 !important',
        marginLeft: '8px',
        marginTop: '1px',
    },
    scrollButton: {
        '& svg': {
            border: '1px solid #667689',
            borderRadius: '50%'
        },
        '&.Mui-disabled': { opacity: 0.3 }
    }
})

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
    },
})
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        backgroundColor: 'unset',
        '&.Mui-selected': {
            color: 'black',
            // borderBottom: '3px solid #5571C6',
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} className='medical-note-categories'/>)
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 14,
    backgroundColor: 'none',
    // padding: '0 24px',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#5571C6',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#5571C6',
        fontWeight: 500,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#5571C6',
    },
}))

const MedicalCategoryNotes = ({
    onChangeActiveNote,
    clinicalNoteId,
    categoryList = [],
    EqStatus,
    isConsultant,
}) => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)
    const onHandleScroll = useSelector((state) => state.clinicalNotes.onHandleScrollChangeCategory)
    const handleChange = (event, newValue) => {
        onChangeActiveNote(newValue)
        setValue(newValue)
    }
    
    useEffect(()=>{
        if(Object.keys(onHandleScroll).length !== 0){
            handleChange("",onHandleScroll)
        }
    },[onHandleScroll])
    const [activeTab, setActiveTab] = useState('all')
    const [openSearch, setOpenSearch] = useState(false)
    // const [categoryList, setCategoryList] = useState([])
    const [textSearch, setTextSearch] = useState('')
    const [category, setCategory] = useState([])
    // const location = useLocation()

    const [rightHiddenCategories, setRightHiddenCategories] = useState([])
    const [leftHiddenCategories, setLeftHiddenCategories] = useState([])

    const [leftAnchorEl, setLeftAnchorEl] = useState(null)
    const [rightAnchorEl, setRightAnchorEl] = useState(null)
    const containerRef = useRef(null)
    const tabRef = useRef(null)
    const tooltipRef = useRef(null)

    const handleChangeTab = (_, val) => {
        setActiveTab(val)
    }

    const handleOpenSearch = () => {
        setOpenSearch(!openSearch)
    }

    useEffect(() => {
        setCategory(categoryList)
    }, [categoryList])

    const numIncomplete = (item) =>
        categoryList
            .filter((_item) => _item.isShow)
            .filter((_item) => _item.header.toLowerCase().includes(textSearch.toLowerCase()))
            .filter((_item) => _item.status === item.key || item.key === 'all').length

    const leftSidebarSatus = useSelector((state) => state.sidebar.visible)

    const CustomLeftScrollButton = (props) => {
        return (
            <>
                <IconButton {...props} className={classes.scrollButton}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton 
                    onClick={(event) => {
                        const currentTarget = document.getElementById('parent-tab')
                        setLeftAnchorEl(currentTarget)
                    }}
                    disabled={props?.disabled}>
                    <MoreHorizIcon id='more-icon-left'/>
                </IconButton>
            </>
        )
    }
    
    const CustomRightScrollButton = (props) => {
        return (
            <>
                <IconButton 
                    onClick={(event) => {
                        const currentTarget = document.getElementById('parent-tab')
                        setRightAnchorEl(currentTarget)
                    }}
                    disabled={props?.disabled}>
                    <MoreHorizIcon id='more-icon-right'/>
                </IconButton>

                <IconButton {...props} className={classes.scrollButton}>
                    <KeyboardArrowRightIcon />
                </IconButton>
            </>
        )
    }

    const handleFetchRightHiddenCategories = () => {
        if (containerRef.current) {
            const containerRect = containerRef.current?.querySelector('.MuiTabs-scroller')?.getBoundingClientRect();
            const items = Array.from(containerRef.current.querySelectorAll('.medical-note-categories'));

            const hiddenItems = items.filter(item => {
                const itemRect = item?.querySelector('p')?.getBoundingClientRect();
                return (
                (itemRect.left + 10) >= containerRect.right 
                // itemRect.right <= containerRect.right
                )
            })

            const hiddenItemsComponentIds = hiddenItems?.map(item => {
                return item?.dataset['componentId']
            })

            setRightHiddenCategories(hiddenItemsComponentIds)
        }
    }

    const handleFetchLeftHiddenCategories = () => {
        if (containerRef.current) {
            const containerRect = containerRef.current?.querySelector('.MuiTabs-scroller').getBoundingClientRect()
            const items = Array.from(containerRef.current.querySelectorAll('.medical-note-categories'))

            const hiddenItems = items.filter(item => {
                const itemRect = item?.querySelector('p')?.getBoundingClientRect()
                return (
                     containerRect.left >= itemRect.left
                    //  (((itemRect.left + itemRect.width)/2) - 10) <= containerRect.left
                )
            })

            const hiddenItemsComponentIds = hiddenItems?.map(item => {
                return item?.dataset['componentId']
            })

            setLeftHiddenCategories(hiddenItemsComponentIds)
        }
    }

    useEffect(() => {
        const scrollFunctions = () => {
            handleFetchLeftHiddenCategories()
            handleFetchRightHiddenCategories()
        }

        setTimeout(() => {
            scrollFunctions()
        }, 100)

        containerRef?.current?.querySelector('.MuiTabs-scroller')?.addEventListener('scroll', scrollFunctions)
        window.addEventListener('resize', scrollFunctions)

        return (() => {
            window.removeEventListener('resize', scrollFunctions)
        })
    }, [category])

    useEffect(() => {
        !Boolean(leftHiddenCategories?.length) && setLeftAnchorEl(null)
    }, [leftHiddenCategories?.length])

    useEffect(() => {
        !Boolean(rightHiddenCategories?.length) && setRightAnchorEl(null)
    }, [rightHiddenCategories?.length])

    const customTabName = useCallback((tabName, headerName) => {
        if(tabName?.toLowerCase() === 'med_codes') return 'Medical Coding'
        if(tabName?.toLowerCase() === 'review_of_systems') return 'Review of Systems'
        return headerName
    }, [category])

    return (
        <Grid container spacing={2} style={{ paddingTop: '7px' }}>
            <Popover
                id={Boolean(rightAnchorEl) ? 'simple-popover' : undefined}
                open={Boolean(rightAnchorEl)}
                anchorEl={rightAnchorEl}
                onClose={() => setRightAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {rightHiddenCategories?.map(rightHiddenCategoryItem => {
                    const newValue = category?.find((item) => item?.componentId === rightHiddenCategoryItem)
                    const headerName = newValue?.componentId === 'med_codes' ? 'Medical Coding' : newValue?.header
                    return (
                        <MenuItem key={rightHiddenCategoryItem} onClick={() => handleChange(_, newValue)}>
                            <Typography style={{ fontSize: 12, color: '#000' }}>{headerName}</Typography>
                        </MenuItem>
                    )
                })}
            </Popover>

            <Popover
                id={Boolean(leftAnchorEl) ? 'simple-popover' : undefined}
                open={Boolean(leftAnchorEl)}
                anchorEl={leftAnchorEl}
                onClose={() => setLeftAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {leftHiddenCategories?.map(leftHiddenCategoryItem => {
                    const newValue = category?.find((item) => item?.componentId === leftHiddenCategoryItem)
                    return (
                        <MenuItem key={leftHiddenCategoryItem} onClick={() => handleChange(_, newValue)}>
                            <Typography style={{ fontSize: 12, color: '#000' }}>{formatString(newValue?.header)}</Typography>
                        </MenuItem>
                    )
                })}
            </Popover>

            <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        border: '1px solid #EAECF0',
                        borderRadius: '5px',
                        background: '#F8F9FB',
                    }}
                    // className={
                    //     isConsultant
                    //         ? EqStatus && !leftSidebarSatus
                    //             ? 'categoryNotesEqModelCollapse'
                    //             : EqStatus && leftSidebarSatus
                    //             ? 'categoryNotesEqModelLSCollapse'
                    //             : !EqStatus && leftSidebarSatus
                    //             ? 'categoryNotesLSCollapse'
                    //             : 'categoryNotesEqModelExpand'
                    //         : leftSidebarSatus
                    //         ? 'categoryNotesLSCollapse'
                    //         : 'categoryNotesEqModelExpand'
                    // }
                >
                    <Tabs
                        id='parent-tab'
                        className='parent_tabs_holder'
                        ref={containerRef}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        ScrollButtonComponent={(props) => {
                            if (props.direction === 'left') {
                              return <CustomLeftScrollButton {...props} />;
                            }
                            return <CustomRightScrollButton {...props} />;
                        }}
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                        style={{
                            width: '100%',
                            boxSizing: 'border-box',
                            padding: '0px',
                            overflow: 'hidden',
                        }}>
                        {category.map((tab, index) => {
                            if(tab?.componentId !== "chief_complaint"){
                                return(
                                    <CustomTab
                                        data-component-id={tab?.componentId}
                                        ref={tabRef}
                                        key={index}
                                        value={tab}
                                        label={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                <Typography
                                                    style={{
                                                        textTransform: tab?.componentId === 'review_of_systems' ? 'none' : 'capitalize',
                                                        borderBottom:
                                                            tab.componentId  === value.componentId ? '3px solid #5571C6' : '',
                                                    }}>
                                                    {customTabName(tab?.componentId, tab?.header)}
                                                </Typography>
                                                {/* {category.length > index + 1 && (
                                                    <Divider
                                                        variant="middle"
                                                        flexItem
                                                        orientation="vertical"
                                                        className={classes.divider}
                                                    />
                                                )} */}
                                            </div>
                                        }
                                        style={{ margin: '0 10px', textTransform: tab?.componentId === 'review_of_systems' ? 'none' : 'capitalize', }}
                                    />
                                )
                            }
                        })}
                        {/* <TabScrollButton/> */}
                    </Tabs>
                </Box>
            </Grid>

        </Grid>
    )
}

export default MedicalCategoryNotes
