export const SCHEDULED_DRUG = [
    {
        key: 'yes',
        value: 'Yes',
    },
    { key: 'No', value: 'No' },
    { key: 'Not Sure', value: 'Not Sure' },
]
export const DRUG_TYPE = [
    {
        key: 'Compound',
        value: 'Compound',
    },
    { key: 'Supply/Equipment', value: 'Supply/Equipment' },
    { key: 'Neither', value: 'Neither' },
]
export const PATIENT_DIRECTIONS = [
    { key: 'Administer', value: 'Administer' },
    { key: 'Apply', value: 'Apply' },
    { key: 'Chew', value: 'Chew' },
    { key: 'Dissolve', value: 'Dissolve' },
    { key: 'Douche with', value: 'Douche with' },
    { key: 'Drink', value: 'Drink' },
    { key: 'Hold', value: 'Hold' },
    { key: 'Implant', value: 'Implant' },
    { key: 'Infuse', value: 'Infuse' },
    { key: 'Inhale', value: 'Inhale' },
    { key: 'Inject', value: 'Inject' },
    { key: 'Insert', value: 'Insert' },
    { key: 'Instill', value: 'Instill' },
    { key: 'Place', value: 'Place' },
    { key: 'Rinse With', value: 'Rinse With' },
    { key: 'Shampoo with', value: 'Shampoo with' },
    { key: 'Spray', value: 'Spray' },
    { key: 'Take', value: 'Take' },
    { key: 'Use', value: 'Use' },
]
// export const DOSE = [
//     { key: 1, value: '1' },
//     { key: 2, value: '2' },
//     { key: 3, value: '3' },
//     { key: 4, value: '4' },
//     { key: 0.25, value: '1/4' },
//     { key: 0.5, value: '1/2' },
//     { key: 0.75, value: '3/4' },
//     { key: 1.25, value: '1 1/4' },
//     { key: 1.5, value: '1 1/2' },
//     { key: 2.5, value: '2 1/2' },
//     { key: 5, value: '5' },
//     { key: 10, value: '10' },
//     { key: 15, value: '15' },
// ]
export const DOSE = [
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '1/4', value: '1/4' },
    { key: '1/2', value: '1/2' },
    { key: '3/4', value: '3/4' },
    { key: '1 1/4', value: '1 1/4' },
    { key: '1 1/2', value: '1 1/2' },
    { key: '2 1/2', value: '2 1/2' },
    { key: '5', value: '5' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
]
export const DOSE_UNIT = [
    { key: 'a small amount', value: 'a small amount' },
    { key: 'ampul', value: 'ampul' },
    { key: 'applicator', value: 'applicator' },
    { key: 'applicatorful', value: 'applicatorful' },
    { key: 'as directed', value: 'as directed' },
    { key: 'bag', value: 'bag' },
    { key: 'bandage', value: 'bandage' },
    { key: 'bead', value: 'bead' },
    { key: 'bottle', value: 'bottle' },
    { key: 'box', value: 'box' },
    { key: 'caplet', value: 'caplet' },
    { key: 'capsule', value: 'capsule' },
    { key: 'cartridge', value: 'cartridge' },
    { key: 'catheter', value: 'catheter' },
    { key: 'clicks', value: 'clicks' },
    { key: 'condoms', value: 'condoms' },
    { key: 'device', value: 'device' },
    { key: 'diaphragm', value: 'diaphragm' },
    { key: 'douche', value: 'douche' },
    { key: 'drop', value: 'drop' },
    { key: 'dropperful', value: 'dropperful' },
    { key: 'each', value: 'each' },
    { key: 'enema', value: 'enema' },
    { key: 'film', value: 'film' },
    { key: 'foam', value: 'foam' },
    { key: 'gelcap', value: 'gelcap' },
    { key: 'gram', value: 'gram' },
    { key: 'gum', value: 'gum' },
    { key: 'implant', value: 'implant' },
    { key: 'inch', value: 'inch' },
    { key: 'infusion set', value: 'infusion set' },
    { key: 'insert', value: 'insert' },
    { key: 'kit', value: 'kit' },
    { key: 'lancet', value: 'lancet' },
    { key: 'liberally', value: 'liberally' },
    { key: 'lollipop', value: 'lollipop' },
    { key: 'lozenge', value: 'lozenge' },
    { key: 'mask', value: 'mask' },
    { key: 'mcg', value: 'mcg' },
    { key: 'meq', value: 'meq' },
    { key: 'mg', value: 'mg' },
    { key: 'ml', value: 'ml' },
    { key: 'needle', value: 'needle' },
    { key: 'ounce', value: 'ounce' },
    { key: 'pack', value: 'pack' },
    { key: 'packet', value: 'packet' },
    { key: 'pad', value: 'pad' },
    { key: 'pastille', value: 'pastille' },
    { key: 'patch', value: 'patch' },
    { key: 'pen', value: 'pen' },
    { key: 'pen injector', value: 'pen injector' },
    { key: 'pen needle', value: 'pen needle' },
    { key: 'perle', value: 'perle' },
    { key: 'piece', value: 'piece' },
    { key: 'pledgette', value: 'pledgette' },
    { key: 'puff', value: 'puff' },
    { key: 'pump', value: 'pump' },
    { key: 'scoop', value: 'scoop' },
    { key: 'sheet', value: 'sheet' },
    { key: 'spacer', value: 'spacer' },
    { key: 'spansule', value: 'spansule' },
    { key: 'sponge', value: 'sponge' },
    { key: 'spray', value: 'spray' },
    { key: 'stick', value: 'stick' },
    { key: 'strip', value: 'strip' },
    { key: 'suppository', value: 'suppository' },
    { key: 'swab', value: 'swab' },
    { key: 'syringe', value: 'syringe' },
    { key: 'tablespoon', value: 'tablespoon' },
    { key: 'tablet', value: 'tablet' },
    { key: 'teaspoon', value: 'teaspoon' },
    { key: 'troche', value: 'troche' },
    { key: 'tube', value: 'tube' },
    { key: 'unit', value: 'unit' },
    { key: 'vial', value: 'vial' },
]
export const ROUTE = [
    { key: 'a small amount', value: 'a small amount' },
    { key: 'as directed', value: 'as directed' },
    { key: 'as enema', value: 'as enema' },
    { key: 'by irrigation', value: 'by irrigation' },
    { key: 'by mouth', value: 'by mouth' },
    { key: 'by perfusion', value: 'by perfusion' },
    {
        key: 'dissolved in water',
        value: 'dissolved in water',
    },
    { key: 'epidurally', value: 'epidurally' },
    { key: 'in both eyes', value: 'in both eyes' },
    { key: 'in both nostrils', value: 'in both nostrils' },
    { key: 'in ear', value: 'in ear' },
    { key: 'in eye', value: 'in eye' },
    { key: 'in left eye', value: 'in left eye' },
    { key: 'in mouth', value: 'in mouth' },
    { key: 'in right eye', value: 'in right eye' },
    {
        key: 'into affected ear',
        value: 'into affected ear',
    },
    {
        key: 'into affected eye',
        value: 'into affected eye',
    },
    { key: 'into bladder', value: 'into bladder' },
    { key: 'into both ears', value: 'into both ears' },
    { key: 'into both eyes', value: 'into both eyes' },
    {
        key: 'into both nostrils',
        value: 'into both nostrils',
    },
    { key: 'into catheter', value: 'into catheter' },
    {
        key: 'into each nostril',
        value: 'into each nostril',
    },
    { key: 'into left ear', value: 'into left ear' },
    { key: 'into left eye', value: 'into left eye' },
    { key: 'into mouth', value: 'into mouth' },
    { key: 'into one nostril', value: 'into one nostril' },
    { key: 'into rectum', value: 'into rectum' },
    { key: 'into right ear', value: 'into right ear' },
    { key: 'into right eye', value: 'into right eye' },
    { key: 'into urethra', value: 'into urethra' },
    { key: 'into uterus', value: 'into uterus' },
    {
        key: 'into uterus as directed',
        value: 'into uterus as directed',
    },
    { key: 'into vagina', value: 'into vagina' },
    { key: 'intraaterially', value: 'intraaterially' },
    { key: 'intraarticularly', value: 'intraarticularly' },
    {
        key: 'intracavernosally',
        value: 'intracavernosally',
    },
    { key: 'intradermally', value: 'intradermally' },
    { key: 'intramuscularly', value: 'intramuscularly' },
    { key: 'intranasally', value: 'intranasally' },
    {
        key: 'intraperitoneally',
        value: 'intraperitoneally',
    },
    { key: 'intrathecally', value: 'intrathecally' },
    { key: 'intravenously', value: 'intravenously' },
    { key: 'IVPB', value: 'IVPB' },
    { key: 'liberally', value: 'liberally' },
    { key: 'on tongue', value: 'on tongue' },
    { key: 'patch', value: 'patch' },
    { key: 'rectally', value: 'rectally' },
    { key: 'subcutaneously', value: 'subcutaneously' },
    { key: 'sublingually', value: 'sublingually' },
    { key: 'to affected area', value: 'to affected area' },
    { key: 'to face', value: 'to face' },
    {
        key: 'to inside of mouth (buccaal)',
        value: 'to inside of mouth (buccaal)',
    },
    { key: 'to mouth', value: 'to mouth' },
    { key: 'to scalp', value: 'to scalp' },
    { key: 'to skin', value: 'to skin' },
    { key: 'to teeth', value: 'to teeth' },
    { key: 'topical', value: 'topical' },
    { key: 'under skin', value: 'under skin' },
    { key: 'under tongue', value: 'under tongue' },
    { key: 'using inhaler', value: 'using inhaler' },
    { key: 'using nebulizer', value: 'using nebulizer' },
    { key: 'vaginally', value: 'vaginally' },
    { key: 'via applicator', value: 'via applicator' },
    { key: 'via device', value: 'via device' },
    { key: 'via g-j tube', value: 'via g-j tube' },
    { key: 'via g-tube', value: 'via g-tube' },
    {
        key: 'via intrathecal pump',
        value: 'via intrathecal pump',
    },
    { key: 'via j-tube', value: 'via j-tube' },
    { key: 'via meter', value: 'via meter' },
    { key: 'via nebulizer', value: 'via nebulizer' },
]
export const FREQUENCY = [
    { key: 'single dose', value: 'single dose' },
    { key: 'once a day', value: 'once a day' },
    { key: 'twice a day', value: 'twice a day' },
    {
        key: 'three times a day',
        value: 'three times a day',
    },
    { key: 'four times a day', value: 'four times a day' },
    { key: 'five times a day', value: 'five times a day' },
    { key: 'six times a day', value: 'six times a day' },
    { key: 'every morning', value: 'every morning' },
    { key: 'every evening', value: 'every evening' },
    { key: 'every night', value: 'every night' },
    { key: 'at bedtime', value: 'at bedtime' },
    { key: 'every other day', value: 'every other day' },
    { key: 'every three days', value: 'every three days' },
    { key: 'every hour', value: 'every hour' },
    { key: 'every two hours', value: 'every two hours' },
    {
        key: 'every two hours while awake',
        value: 'every two hours while awake',
    },
    {
        key: 'every three hours',
        value: 'every three hours',
    },
    {
        key: 'every three hours while awake',
        value: 'every three hours while awake',
    },
    { key: 'every four hours', value: 'every four hours' },
    {
        key: 'every four hours while awake',
        value: 'every four hours while awake',
    },
    { key: 'every six hours', value: 'every six hours' },
    {
        key: 'every six to eight hours',
        value: 'every six to eight hours',
    },
    {
        key: 'every eight hours',
        value: 'every eight hours',
    },
    {
        key: 'every twelve hours',
        value: 'every twelve hours',
    },
    { key: 'every 24 hours', value: 'every 24 hours' },
    { key: 'every 48 hours', value: 'every 48 hours' },
    { key: 'every 72 hours', value: 'every 72 hours' },
    {
        key: 'every three to four hours',
        value: 'every three to four hours',
    },
    {
        key: 'every three to four hours while awake',
        value: 'every three to four hours while awake',
    },
    {
        key: 'every four to six hours',
        value: 'every four to six hours',
    },
    {
        key: 'every four to six hours while awake',
        value: 'every four to six hours while awake',
    },
    {
        key: 'every eight to twelve hours',
        value: 'every eight to twelve hours',
    },
    { key: 'once a week', value: 'once a week' },
    { key: 'twice a week', value: 'twice a week' },
    {
        key: 'three times a week',
        value: 'three times a week',
    },
    {
        key: 'once every two weeks',
        value: 'once every two weeks',
    },
    { key: 'every two weeks', value: 'every two weeks' },
    { key: 'every four week', value: 'every four week' },
    { key: 'once a month', value: 'once a month' },
    {
        key: 'every three months',
        value: 'every three months',
    },
    { key: 'as directed', value: 'as directed' },
]
export const OTHER = [
    { key: 'as needed', value: 'as needed' },
    {
        key: 'as needed for pain',
        value: 'as needed for pain',
    },
    { key: 'as directed', value: 'as directed' },
    { key: 'with meals', value: 'with meals' },
    { key: 'between meals', value: 'between meals' },
    { key: 'after meals', value: 'after meals' },
    {
        key: 'one hour before meals',
        value: 'one hour before meals',
    },
    {
        key: 'with a glass of water',
        value: 'with a glass of water',
    },
    { key: 'before exercise', value: 'before exercise' },
]
export const DAY_SUPPLY = [
    { key: 1, value: 'One day' },
    { key: 2, value: 'Two day' },
    { key: 3, value: 'Three days' },
    { key: 4, value: 'Four days' },
    { key: 5, value: 'Five days' },
    { key: 6, value: 'Six days' },
    { key: 7, value: 'Seven days' },
    { key: 8, value: 'Eight days' },
    { key: 9, value: 'Nine days' },
    { key: 10, value: 'Ten days' },
    { key: 12, value: '12 days' },
    { key: 14, value: '14 days' },
    { key: 15, value: '15 days' },
    { key: 20, value: '20 days' },
    { key: 21, value: '21 days' },
    { key: 22, value: '22 days' },
    { key: 25, value: '25 days' },
    { key: 28, value: '28 days' },
    { key: 30, value: '30 days' },
    { key: 31, value: '31 days' },
    { key: 34, value: '34 days' },
    { key: 35, value: '35 days' },
    { key: 45, value: '45 days' },
    { key: 56, value: '56 days' },
    { key: 60, value: '60 days' },
    { key: 75, value: '75 days' },
    { key: 84, value: '84 days' },
    { key: 90, value: '90 days' },
    { key: 91, value: '91 days' },
    { key: 100, value: '100 days' },
    { key: 102, value: '102 days' },
    { key: 112, value: '112 days' },
    { key: 120, value: '120 days' },
    { key: 180, value: '180 days' },
]
export const UNIT = [
    {
        key: 'adhesive patch, medicated',
        value: 'adhesive patch, medicated',
    },
    { key: 'applicator', value: 'applicator' },
    { key: 'blister', value: 'blister' },
    { key: 'blisters', value: 'blisters' },
    { key: 'cap', value: 'cap' },
    { key: 'caplet', value: 'caplet' },
    { key: 'capsule', value: 'capsule' },
    { key: 'capsules', value: 'capsules' },
    { key: 'condom', value: 'condom' },
    { key: 'diaphragm', value: 'diaphragm' },
    { key: 'each', value: 'each' },
    { key: 'film', value: 'film' },
    { key: 'film strips', value: 'film strips' },
    { key: 'films', value: 'films' },
    { key: 'g', value: 'g' },
    { key: 'gelcap', value: 'gelcap' },
    { key: 'gram', value: 'gram' },
    { key: 'grams', value: 'grams' },
    { key: 'gum', value: 'gum' },
    { key: 'implant', value: 'implant' },
    { key: 'insert', value: 'insert' },
    { key: 'kit', value: 'kit' },
    { key: 'kits', value: 'kits' },
    { key: 'lancet', value: 'lancet' },
    { key: 'lollipop', value: 'lollipop' },
    { key: 'lozenge', value: 'lozenge' },
    { key: 'mask', value: 'mask' },
    { key: 'milliliter', value: 'milliliter' },
    { key: 'milliliters', value: 'milliliters' },
    { key: 'ml', value: 'ml' },
    { key: 'needle', value: 'needle' },
    { key: 'not specified', value: 'not specified' },
    { key: 'package', value: 'package' },
    { key: 'packages', value: 'packages' },
    { key: 'packet', value: 'packet' },
    { key: 'packets', value: 'packets' },
    { key: 'pad', value: 'pad' },
    { key: 'pads', value: 'pads' },
    { key: 'pastille', value: 'pastille' },
    { key: 'patch', value: 'patch' },
    { key: 'patch 24 hr', value: 'patch 24 hr' },
    { key: 'patch 72 hr', value: 'patch 72 hr' },
    {
        key: 'patch daily, sequential',
        value: 'patch daily, sequential',
    },
    { key: 'patch semiweekly', value: 'patch semiweekly' },
    { key: 'patch weekly', value: 'patch weekly' },
    { key: 'patches', value: 'patches' },
    { key: 'pen needle', value: 'pen needle' },
    { key: 'pen needle', value: 'pen needle' },
    { key: 'perle', value: 'perle' },
    { key: 'pill', value: 'pill' },
    { key: 'pills', value: 'pills' },
    { key: 'pledgette', value: 'pledgette' },
    { key: 'ring', value: 'ring' },
    { key: 'rings', value: 'rings' },
    { key: 'spansule', value: 'spansule' },
    { key: 'sponge', value: 'sponge' },
    { key: 'stick', value: 'stick' },
    { key: 'strip', value: 'strip' },
    { key: 'strips', value: 'strips' },
    { key: 'supporsitories', value: 'supporsitories' },
    { key: 'supporsitory', value: 'supporsitory' },
    { key: 'swab', value: 'swab' },
    { key: 'syringe', value: 'syringe' },
    { key: 'syringes', value: 'syringes' },
    { key: 'tab', value: 'tab' },
    { key: 'tablet', value: 'tablet' },
    { key: 'tablets', value: 'tablets' },
    {
        key: 'transdermal patch',
        value: 'transdermal patch',
    },
    { key: 'troche', value: 'troche' },
    { key: 'troches', value: 'troches' },
    { key: 'unspecified', value: 'unspecified' },
    { key: 'wafer', value: 'wafer' },
    { key: 'wafers', value: 'wafers' },
]
export const SUBSTITUTION_PERMITTED = [
    {
        key: 'Substitution Permitted',
        value: 'Substitution Permitted',
    },
    {
        key: 'Dispense As Written',
        value: 'Dispense As Written',
    },
]
