import {
    editTm2uAdminProfile,
    getTM2UAdminProfileById,
} from '../../services/TM2UAdmin/TM2UAdmin';
import * as actionType from '../actionType';
import {
    setLoading,
    setError,
    setErrorMessage,
} from './loader';

export const getTM2UAdminProfile = (userId) => {
    return (dispatch) => {
        setLoading(true);
        getTM2UAdminProfileById(userId)
            .then((res) => {
                dispatch(setErrorMessage(''));
                setLoading(false);
                dispatch(setTM2UAdminProfile(res.data));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(setError(true));
            });
    };
};
export const resetTm2uProfile = () => {
    return {
        type: actionType.RESET_TM2U_PROFILE,
    };
};

export const setTM2UAdminProfile = (value) => {
    return {
        type: actionType.SET_TM2UADMIN_PROFILE,
        value,
    };
};

export const handleEditTM2UAdminProfile = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setErrorMessage(''));
        setLoading(true);
        editTm2uAdminProfile(payload)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    dispatch(
                        setEditTM2UAdminProfileResult(res.data),
                    );
                    dispatch(
                        getTM2UAdminProfile(payload?.user_id),
                    );
                    onSuccess && onSuccess();
                }
                else {
                    setLoading(false);
                    dispatch(setError(true));
                    dispatch(
                        setErrorMessage(res?.data.message),
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                dispatch(setError(true));
                dispatch(
                    setErrorMessage(err?.data.message),
                );
            });
    };
};

export const setEditTM2UAdminProfileResult = (value) => {
    return {
        type: actionType.SET_EDIT_TM2UADMIN_PROFILE,
        value,
    };
};
export const setAddStaffProfile = (value) => ({
    type: actionType.SET_STAFF_PROFILE,
    value,
});