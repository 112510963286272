import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { Surgical } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import { formatDateTime } from 'utilities/formats'

type Props = {
    dataSource?: Surgical[]
    pagination?: Pagination
    sortModel?: GridSortModel
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    onClickEdit?: (surgicalId: string) => void
    onClickDelete?: (surgicalId: string) => void
    onPageChange?: React.Dispatch<React.SetStateAction<number>>
    nonEditable?: Boolean
}

export const SurgicalHistoryDashboard = (props: Props) => {
    const columns: GridColumns<Surgical> = [
        {
            headerName: 'Procedure',
            headerClassName: 'super-app-theme--header',
            field: 'procedure',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => (
                <DataGridCell
                    style={{
                        textTransform: 'capitalize',
                    }}
                    value={row.procedure?.replaceAll('_', ' ').toLowerCase()}
                />
            ),
        },
        {
            headerName: 'Date',
            headerClassName: 'super-app-theme--header',
            field: 'date',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => (
                <DataGridCell value={formatDateTime(row.date as string, 'MM/DD/YYYY')} />
            ),
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                !props.nonEditable
                  ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.onClickEdit?.(row.id as string),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.onClickDelete?.(row.id as string),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                ),
        },
    ]

    return (
        <DataGrid
            getRowId={(row) => row.id as string}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
            onPageChange={(page) => props.onPageChange?.(page)}
            page={props.pagination?.start}
        />
    )
}
