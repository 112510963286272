export const SET_USER = 'SET_USER'
export const SET_SESSION = 'SET_SESSION'
export const SET_LOADER = 'SET_LOADER'
export const SET_ROLE_LOADER = 'SET_ROLE_LOADER'
export const SET_PATIENT_DATA = 'SET_PATIENT_DATA'
export const SET_ERROR = 'SET_ERROR'
export const SET_ERROR_MSG = 'SET_ERROR_MSG'
export const SET_OTP_ERROR = 'SET_OTP_ERROR'
export const SET_IS_OTP_REQUIRED = 'SET_IS_OTP_REQUIRED'
export const SET_FORCE_TO_CHANGE_PASSWORD = 'SET_FORCE_TO_CHANGE_PASSWORD'
export const SET_CURRENT_SESSION = 'SET_CURRENT_SESSION'
export const LOAD_SPECIALITIES = 'LOAD_SPECIALITIES'
export const SET_NEW_USER = 'SET_NEW_USER'
export const HANDLE_BACK = 'HANDLE_BACK'
export const SET_RESET_DATA = 'SET_RESET_DATA'
export const SET_EMAIL_VALID = 'SET_EMAIL_VALID'
export const SET_IS_USER_EXSIST = 'SET_IS_USER_EXSIST'
export const SET_MAX_ATTEMPT = 'SET_MAX_ATTEMPT'
export const SET_ANOTHER_WAY_OTP_FIELD = 'SET_ANOTHER_WAY_OTP_FIELD'
export const LOGOUT = 'LOGOUT'
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'

//sidebar
export const SET_SIDEBAR_VISIBILITY = 'SET_SIDEBAR_VISIBILITY'

//appointments
export const SET_SPECIALITIES = 'SET_SPECIALITIES'
export const SET_SELECTED_PROVIDER_DETAILS = 'SET_SELECTED_PROVIDER_DETAILS'
export const SET_CLINIC_LIST = 'SET_CLINIC_LIST'
export const SET_SEARCH_PROVIDER_DATA = 'SET_SEARCH_PROVIDER_DATA'
export const SET_APPOINTMENT_FLOW_TYPE = 'SET_APPOINTMENT_FLOW_TYPE'
export const SET_BOOK_APPOINTMENT_DATA = 'SET_BOOK_APPOINTMENT_DATA'
export const SET_APPOINTMENT_LIST = 'SET_APPOINTMENT_LIST'
export const SET_APPOINTMENT_ERROR = 'SET_APPOINTMENT_ERROR'
export const SET_IS_RESCHEDULE_APPOINTMENT = 'SET_IS_RESCHEDULE_APPOINTMENT'
export const SET_SELECTED_APPOINTMENT_DATA = 'SET_SELECTED_APPOINTMENT_DATA'
export const RESET_APPOINTMENTS_DATA = 'RESET_APPOINTMENTS_DATA'
export const SET_APPOINTMENT_DETAILS = 'SET_APPOINTMENT_DETAILS'
export const SET_SELECTED_PATIENT_DETAILS = 'SET_SELECTED_PATIENT_DETAILS'
export const SET_SELECTED_MINOR_DETAILS = 'SET_SELECTED_MINOR_DETAILS'
export const SET_CLINIC_DETAILS = 'SET_CLINIC_DETAILS'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'

//Provider
export const SET_TIMEOFFS_LIST = 'SET_TIMEOFFS_LIST'
export const SET_PROVIDER_DETAILS = 'SET_PROVIDER_DETAILS'
export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST'
export const SET_CALENDAR_DATES = 'SET_CALENDAR_DATES'
export const SET_CALENDAR_SLOTS = 'SET_CALENDAR_SLOTS'
export const SET_SEARCH_PROVIDER_LIST = 'SET_SEARCH_PROVIDER_LIST'
export const UPDATE_SEARCH_PROVIDER_STATUS = 'UPDATE_SEARCH_PROVIDER_STATUS'
export const SET_PROVIDER_PROFILE = 'SET_PROVIDER_PROFILE'
export const SET_EDIT_PROVIDER_PROFILE = 'SET_EDIT_PROVIDER_PROFILE'
export const RESET_PROVIDER_PROFILE = 'RESET_PROVIDER_PROFILE'
export const SET_SPECIALITY_LIST = 'SET_SPECIALITY_LIST'
export const SET_CLIENT_LIST = 'SET_CLIENT_LIST'
export const SET_ACTIVE_PROVIDER_LIST = 'SET_ACTIVE_PROVIDER_LIST'
export const TIMEOFFS_SORT_DATA = 'TIMEOFFS_SORT_DATA'

//CLINIC
export const SET_SEARCH_CLINIC_LIST = 'SET_SEARCH_CLINIC_LIST'
export const UPDATE_SEARCH_CLINIC_STATUS = 'UPDATE_SEARCH_CLINIC_STATUS'
export const SET_CLINIC_DETAIL = 'SET_CLINIC_DETAIL'
export const RESET_CLINIC_DETAIL = 'RESET_CLINIC_DETAIL'
export const SET_EDIT_CLINIC_DETAIL = 'SET_EDIT_CLINIC_DETAIL'
export const SET_CATEGORY_PLAN = 'SET_CATEGORY_PLAN'

//Clinic Admin
export const SET_CLINIC_ADMIN_DETAIL = 'SET_CLINIC_ADMIN_DETAIL'
export const SET_CLINIC_ADMIN_PROFILE = 'SET_CLINIC_ADMIN_PROFILE'
export const SET_CLINIC_ADMIN_PROFILE_EDIT = 'SET_CLINIC_ADMIN_PROFILE_EDIT'

// Appointment list
export const SET_APPOINTMENTLIST = 'SET_APPOINTMENTLIST'
export const SET_APPOINTMENTLIST_COUNT = 'SET_APPOINTMENTLIST_COUNT'
export const SET_MARK_CONSULTATION_STATUS = 'SET_MARK_CONSULTATION_STATUS'

//menu
export const SET_ACTIVE_MENU = 'SET_ACTIVE_MENU'

//Patient
export const SET_PATIENT_DETAIL = 'SET_PATIENT_DETAIL'
export const SET_PATIENT_PROFILE = 'SET_PATIENT_PROFILE'
export const RESET_PATIENT_PROFILE = 'RESET_PATIENT_PROFILE'

export const SET_EDIT_PATIENT_PROFILE = 'SET_EDIT_PATIENT_PROFILE'
export const FRONT_INSURANCE_CARD = 'FRONT_INSURANCE_CARD'
export const BACK_INSURANCE_CARD = 'BACK_INSURANCE_CARD'
export const OPEN_BH_SCALE_UPLOAD_FORM = 'OPEN_BH_SCALE_UPLOAD_FORM'
//TM2UAdmin
export const SET_TM2UADMIN_PROFILE = 'SET_TM2UADMIN_PROFILE'
export const SET_EDIT_TM2UADMIN_PROFILE = 'SET_EDIT_TM2UADMIN_PROFILE'

export const SET_PATIENT_LIST = 'SET_PATIENT_LIST'

export const SET_SEARCH_PATIENT_LIST = 'SET_SEARCH_PATIENT_LIST'
export const UPDATE_SEARCH_PATIENT_STATUS = 'UPDATE_SEARCH_PATIENT_STATUS'
export const SET_PATIENTLIST = 'SET_PATIENTLIST'
export const SET_ADDPATIENTLIST = 'SET_ADDPATIENTLIST'
export const RESET_TM2U_PROFILE = 'RESET_TM2U_PROFILE'

//Fax
export const SET_FAX_NUMBERS = 'SET_FAX_NUMBERS'
export const SET_FAX_NUMBER = 'SET_FAX_NUMBER'
export const SET_FAX_LIST = 'SET_FAX_LIST'
export const SET_FAX_COUNT = 'SET_FAX_COUNT'
export const SET_FAX_PAGE_CHANGE = 'SET_FAX_PAGE_CHANGE'
export const SET_FAX_SEARCH_CRITERIA = 'SET_FAX_SEARCH_CRITERIA'
export const SET_FAX_LOADING = 'SET_FAX_LOADING'

//Notes
export const SET_NOTES_LIST = 'SET_NOTES_LIST'
export const SET_ADD_NOTES = 'SET_ADD_NOTES'
export const SET_NOTES_PAGE_CHANGE = 'SET_NOTES_PAGE_CHANGE'

//Forms
export const SET_FORMS_ACTIVE_TAB = 'SET_FORMS_ACTIVE_TAB'
export const SET_FORMS_LIST = 'SET_FORMS_LIST'
export const SET_FORMS_COUNT = 'SET_FORMS_COUNT'
export const SET_FORMS_PAGE_CHANGE = 'SET_FORMS_PAGE_CHANGE'
export const SET_FORMS_SEARCH_CRITERIA = 'SET_FORMS_SEARCH_CRITERIA'
export const SET_FORMS_LOADING = 'SET_FORMS_LOADING'
export const SET_FORMS_DETAIL_ID = 'SET_FORMS_DETAIL_ID'
export const SET_FORMS_SEARCH_DATE = 'SET_FORMS_SEARCH_DATE'
export const SET_FORMS_SEARCH_FILTER_STRING = 'SET_FORMS_SEARCH_FILTER_STRING'
export const SET_FORMS_DETAIL_PAGE_DATA = 'SET_FORMS_DETAIL_PAGE_DATA'
export const RESET_FORMS_STATE = 'RESET_FORMS_STATE'
export const UPDATE_TIMEOFFS_STATUS = 'UPDATE_TIMEOFFS_STATUS'
//Admin
export const SET_ADMIN_PROVIDER_LIST = 'SET_ADMIN_PROVIDER_LIST'

// staff
export const SET_STAFF_LIST = 'SET_STAFF_LIST'
export const SET_SEARCH_STAFF_LIST = 'SET_SEARCH_STAFF_LIST'

export const SET_STAFF_PROFILE = 'SET_STAFF_PROFILE'
export const SET_STAFF_EDIT_PROFILE = 'SET_STAFF_EDIT_PROFILE'
export const SET_UPDATE_STAFF_STATUS = 'SET_UPDATE_STAFF_STATUS'
export const SET_STAFF_RESULT_LIST = 'SET_STAFF_RESULT_LIST'
export const SET_STAFF_PROFILE_BY_ID = 'SET_STAFF_PROFILE_BY_ID'
//Form Builder
export const SET_FORM_TYPES = 'SET_FORM_TYPES'
export const SET_SUB_FORM_TYPES = 'SET_SUB_FORM_TYPES'
export const SET_FORM_LIST = 'SET_FORM_LIST'
export const SET_SELECTED_FORM_ID = 'SET_SELECTED_FORM_ID'
export const SET_FORM_DATA = 'SET_FORM_DATA'
export const RESET_FORM_BUILDER = 'RESET_FORM_BUILDER'
export const SET_FORM_TYPE = 'SET_FORM_TYPE'

//Documents

export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST'

export const SET_REFRESH_NOTF_BADGE_COUNT = 'SET_REFRESH_NOTF_BADGE_COUNT'

//Macros
export const SET_MACRO_LIST = 'SET_MACRO_LIST'
export const SET_TOTAL_MACRO_COUNT = 'SET_TOTAL_MACRO_COUNT'
export const SET_PRACTITIONER_MACRO_LIST = 'SET_PRACTITIONER_MACRO_LIST'

//Timezones
export const SET_COUNTRY = 'SET_COUNTRY'

//IsEncounter
export const SET_ENCOUNTER = 'SET_ENCOUNTER'

//IsEncounter
export const SET_TELEHEALTH_SCREEN = 'SET_TELEHEALTH_SCREEN'

//Clnical Notes
export const SET_CLINICAL_NOTES_LIST = 'SET_CLINICAL_NOTES_LIST'
export const SET_IS_UPDATING_MEDICAL_CODING = 'SET_IS_UPDATING_MEDICAL_CODING'
export const SET_MISSING_MEDICAL_CODING = 'SET_MISSING_MEDICAL_CODING'
export const SET_PAGE_MISSING_MEDICAL_CODING = 'SET_PAGE_MISSING_MEDICAL_CODING'
export const SET_CLINICAL_NOTES_COUNT = 'SET_CLINICAL_NOTES_COUNT'
export const UPDATING_MEDICATION_LIST = 'UPDATING_MEDICATION_LIST'
export const UPDATING_MEDICAL_CODING_FORCEFULLY = 'UPDATING_MEDICAL_CODING_FORCEFULLY'
export const PERMISSION_SAVE_MEDICAL_CODING = 'PERMISSION_SAVE_MEDICAL_CODING'
export const SYNC_APPOINTMENT_CLINICALNOTE = 'SYNC_APPOINTMENT_CLINICALNOTE'
export const SET_CATEGORY_ONHANDLESCROLL = 'SET_CATEGORY_ONHANDLESCROLL'

//common
export const COMMON_MASTER_DATA = 'COMMON_MASTER_DATA'
export const SHOW_CONSULTATION_LIST = 'SHOW_CONSULTATION_LIST'
export const SHOW_PROVIDER_LIST = 'SHOW_PROVIDER_LIST'
export const GET_LIST_ENCOUNTER_QUEUE = 'GET_LIST_ENCOUNTER_QUEUE'
export const SET_DATE_BASED_LIST_ENCOUNTER_QUEUE = 'SET_DATE_BASED_LIST_ENCOUNTER_QUEUE'

export const GET_TOKEN_VIDEO_PROVIDER = 'GET_TOKEN_VIDEO_PROVIDER'
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT'
export const ACTION_ADMIT = 'ACTION_ADMIT'
export const ACTION_END_ROOM = 'ACTION_END_ROOM'
export const ACTION_KICK_PARTICIPANT = 'ACTION_KICK_PARTICIPANT'
export const SET_ENCOUNTER_MEETING_ID = 'SET_ENCOUNTER_MEETING_ID'
export const SET_IS_SUCCESS_ADD_PARTICIPANT = 'SET_IS_SUCCESS_ADD_PARTICIPANT'
export const SET_ERROR_ADD_PARTICIPANT = 'SET_ERROR_ADD_PARTICIPANT'
export const SET_LOADING_ADMIT = 'SET_LOADING_ADMIT'
export const SET_LOADING_END_ROOM = 'SET_LOADING_END_ROOM'
export const SET_LOADING_ASSIGN = 'SET_LOADING_ASSIGN'
export const SET_LOADING_ENCOUNTER_QUE = 'SET_LOADING_ENCOUNTER_QUE'
export const STOP_CONSULTATION = 'STOP_CONSULTATION'
export const SAVE_OBJECT_ENCOUTER_START = 'SAVE_OBJECT_ENCOUTER_START'
export const SET_ASSISTANT_PRACTITIONER = 'SET_ASSISTANT_PRACTITIONER'
export const SET_ASSIGNED_PROVIDER = 'SET_ASSIGNED_PROVIDER'
export const SET_ADDING_PARTICIPANT = 'SET_ADDING_PARTICIPANT'
export const SET_LOADING_END_MEETING = 'SET_LOADING_END_MEETING'
export const SET_ENCOUNTER_ROOM = 'SET_ENCOUNTER_ROOM'
export const SET_ENCOUNTER_CLINICAL_NOTE_LOADED = 'SET_ENCOUNTER_CLINICAL_NOTE_LOADED'
export const GET_ALL_ADDENDUM_BY_CLINICAL_NOTE = 'GET_ALL_ADDENDUM_BY_CLINICAL_NOTE'
export const CREATE_ADDENDUM = 'CREATE_ADDENDUM'
export const SET_IS_SUCCESS_CREATE_ADDENDUM = 'SET_IS_SUCCESS_CREATE_ADDENDUM'
export const SET_ERROR_CREATE_ADDENDUM = 'SET_ERROR_CREATE_ADDENDUM'
export const SET_IS_LOADING_ADDENDUM = 'SET_IS_LOADING_ADDENDUM'
export const SET_IS_LOADING_GET_LIST_ADDENDUM = 'SET_IS_LOADING_GET_LIST_ADDENDUM'
export const SET_ACTIVE_NOTE = 'SET_ACTIVE_NOTE'
export const SET_CURRENT_NOTE = 'SET_CURRENT_NOTE'
export const SET_ENCOUNTER_QUEUE_LIST = 'SET_ENCOUNTER_QUEUE_LIST'
export const SET_SELECTED_ENCOUNTER = 'SET_SELECTED_ENCOUNTER'
export const SET_STATUS_PARTICIPANT = 'SET_STATUS_PARTICIPANT'
export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE'
export const SET_SHOW_SAVE_AND_CLOSE_BUTTON = 'SET_SHOW_SAVE_AND_CLOSE_BUTTON'
export const SET_SHOW_SAVE_AND_CLOSE_DIALOG = 'SET_SHOW_SAVE_AND_CLOSE_DIALOG'
export const SET_CURRENT_ENCOUNTER = 'SET_CURRENT_ENCOUNTER'
export const SET_ALLOW_ERROR = 'SET_ALLOW_ERROR'
export const SET_TAB_CHANGE = 'SET_TAB_CHANGE'
// setting

export const SET_APPOINTMENT_STATUS = 'SET_APPOINTMENT_STATUS'

export const SET_PERMISSION = 'SET_PERMISSION'
export const SET_ROLES = 'SET_ROLES'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SET_AUTH_QR_CODE = 'SET_AUTH_QR_CODE'
export const MFA = 'MFA'
export const SET_ACTIVE_LATEST = 'SET_ACTIVE_LATEST'

export const SCHEDULE_CHANGE_APPOINTMENT = 'SCHEDULE_CHANGE_APPOINTMENT'
export const SCHEDULE_CHANGE_SEARCH_VALUES = 'SCHEDULE_CHANGE_SEARCH_VALUES'
export const SCHEDULE_CHANGE_SEARCH_RESULT = 'SCHEDULE_CHANGE_SEARCH_RESULT'
export const SCHEDULE_RESET_STATE = 'SCHEDULE_RESET_STATE'
export const IS_RESCHEDULE_POPUP = 'IS_RESCHEDULE_POPUP'
export const PROVIDER_LOCATION = 'PROVIDER_LOCATION'

export const SET_PROVIDING_PROVIDER_LIST = 'SET_PROVIDING_PROVIDER_LIST'
export const SET_SUPERVISE_PROVIDER_LIST = 'SET_SUPERVISE_PROVIDER_LIST'

export const SET_STATUS_CODE = 'SET_STATUS_CODE'
/**
 * Referral
 */
export const SET_TOTAL_NUMBER_OF_FILTER_REFERRAL = 'SET_TOTAL_NUMBER_OF_FILTER_REFERRAL'
export const ADD_MULTI_FILTER_REFERRAL = 'ADD_MULTI_FILTER_REFERRAL'
export const CLEAR_SINGLE_FILTER_REFERRAL = 'CLEAR_SINGLE_FILTER_REFERRAL'
export const CLEAR_ALL_FILTER_REFERRAL = 'CLEAR_ALL_FILTER_REFERRAL'
/**
 * Waitlist
 */
export const ADD_WAITLIST_VALUES = 'ADD_WAITLIST_VALUES'

export const VONAGE_PROVIDER_SESSION = 'VONAGE_PROVIDER_SESSION'