import React, { useState, useEffect, lazy } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { makeStyles } from '@material-ui/styles'
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import { useNotification } from 'shared/elements/Notification'
import { clinicalNotelogGet } from '../api/services'
import clsx from 'clsx'

const Loading = lazy(() => import('shared/elements/Loading'))
const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1100px',
        },
    },
    header: {
        fontSize: '18px',
        color: '#303E4E',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 18,
    },
    tableHead: {
        borderRadius: '5px',
        border: '0.8px solid #E9E9E9',
        background: '#F8F9FB',
    },
    noRecord: {
        textAlign: 'center',
        border: '1px solid #CFCFCF',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        padding: '10px',
    },
    tbody:{
        color: "#000",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%"
    },
    tbodyInside:{
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Rubik',
    },
    dateTime:{
        color: "#000",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%",
        paddingLeft: "7px"
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approve: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_resend_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_in_progress: {
        background: '#D8D1FF',
        color: '#7340DF',
    },
    status_tag: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        height: 32,
        borderRadius: 4,
    },
    mainContainer:{
        marginBottom: '13px', flex: 1, border: "1px solid #EAECF0", padding: '10px', borderRadius: "5px" ,marginTop:"10px"
    }
})
function Dateformat(date){
    const classes = useStyles()
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    return (
        <div style={{display:"flex", alignItems: "center"}}>
            <Typography className={classes.tbody}>{formattedDate}</Typography>
            <Typography className={classes.dateTime}>{formattedTime}</Typography>
          
        </div>
      );
}
function Row(props) {
    const classes = useStyles()
    const { row } = props
    const [open, setOpen] = React.useState(false)
    function statusMapper(status) {
        switch (status) {
            case 'PENDING_APPROVE':
                return 'Pending Approve'
            case 'DRAFT':
                return 'Draft'
            case 'LOCKED':
                return 'Locked'
            case 'RESEND_EDIT':
                return 'Resend edit'
            case 'IN_PROGRESS':
                return 'In Progress'
            default:
                return ''
        }
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ marginTop: 1 }}>
                    {open ? (
                        <KeyboardArrowUpIcon
                            style={{
                                border: '1px solid #667689',
                                color: '#667689',
                                cursor: 'pointer',
                                borderRadius: '7px',
                                fontSize: '0.996rem',
                            }}
                            // onClick={() => setOpen(!open)}
                        />
                    ) : (
                        <KeyboardArrowDownIcon
                            style={{
                                border: '1px solid #667689',
                                color: '#667689',
                                cursor: 'pointer',
                                borderRadius: '7px',
                                fontSize: '0.996rem',
                            }}
                            // onClick={() => setOpen(!open)}
                        />
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    <Typography className={classes.tbody}>
                        {row?.provider_name || 'N/A'}
                    </Typography>
                </TableCell>
                <TableCell className={classes.tbody}>
                   { row?.createdAt ? Dateformat(row?.createdAt) : 'N/A'}
                </TableCell>
                <TableCell>
                    <Typography className={classes.tbody}>
                        {row?.category_changes[0] || 'N/A'}
                    </Typography>
                </TableCell>
                <TableCell>
                <Typography className={classes.tbody}>
                        <span className={clsx({
                            [classes.bgColor_draft]: row?.status === 'DRAFT',
                            [classes.bgColor_in_progress]: row?.status === 'IN_PROGRESS',
                            [classes.bgColor_pending_approve]: row?.status === 'PENDING_APPROVE',
                            [classes.bgColor_locked]:
                            row?.status === 'approved_lock' || row?.status=== 'LOCKED',
                            [classes.bgColor_resend_edit]: row?.status === 'RESEND_EDIT',
                        })} style={{padding:"3px 25px 3px 25px"}}>{statusMapper(row?.status) || row?.status}</span>
                    </Typography>
                </TableCell>
                
            </TableRow>
            <TableRow>
                {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                borderLeft: '1px solid #E9E9E9',
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            Field
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            Previous value
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderRight: '1px solid #E9E9E9',
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            New Value
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.field_changes.length > 0 && row?.field_changes.map((item, index) => (
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                {item?.field_name ||'N/A'}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                { item?.prev_value ? moment(item?.prev_value).format('MM/DD/YYYY') :'N/A'}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                { item?.new_value ? moment(item?.new_value).format('MM/DD/YYYY') :'N/A'}
                                            </TableCell>
                                        </TableRow>
                                     ))} 
                                </TableBody>
                            </Table>
                            {row?.field_changes.length === 0 && (<div className={classes.noRecord} style={{borderRadius:"0px"}}>No results found.</div>)}
                        </Box>
                    </Collapse>
                </TableCell> */}
            </TableRow>
        </React.Fragment>
    )
}
export default function ClinicalNoteUpdatelog({ open, close, id }) {
    const classes = useStyles()
    const notification = useNotification()
    const [clinicalNoteData, setClinicalLogData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
      const getCNUpdateLogData = async(id)=>{
        try{
            setIsLoading(true)
            const res = await clinicalNotelogGet(id)
            setClinicalLogData(res?.data?.data)
            if(res?.data){
                setIsLoading(false)
            }
        } catch(e){
            notification('Somting went wrong', 'error')
            setIsLoading(false)
        }
      }
    useEffect(() => {
        if(open && id){
            getCNUpdateLogData(id)
        }
    }, [open])
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1500px',
                },
            }}
            fullWidth={true}
            onClose={close}
            className={classes.root}>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #DEE2E6',
                    }}>
                    <Typography className={classes.header}>Clinical Note Log</Typography>
                    <IconButton style={{ marginBottom: 15 }} onClick={close}>
                        <CloseIcon style={{ color: '#303E4E' }} />
                    </IconButton>
                </div>
                <Grid container className={classes.mainContainer} direction="column">
                    <Loading visible={isLoading} />
                    <TableContainer sx={{ maxHeight: 560 }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderLeft: '1px solid #E9E9E9',
                                            borderTop: '1px solid #E9E9E9',
                                            borderTopLeftRadius: '5px',
                                            borderBottomLeftRadius: '5px',
                                            background: '#F8F9FB',
                                            width: '20px',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                        }}
                                    />
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        User
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Date/Time
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Category Edited 
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderRight: '1px solid #E9E9E9',
                                            borderTop: '1px solid #E9E9E9',
                                            borderTopRightRadius: '5px',
                                            borderBottomRightRadius: '5px',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Clinical Note Status 
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clinicalNoteData.length > 0 && clinicalNoteData.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                                
                            </TableBody>
                        </Table>
                        {!isLoading && clinicalNoteData.length === 0 && (
                            <div className={classes.noRecord}>No results found.</div>
                        )}
                    </TableContainer>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
