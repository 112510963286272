import {
    Button,
    Grid,
    Popover,
    Box,
    Typography,
} from '@material-ui/core'
import PopupState, {
    bindTrigger,
    bindPopover,
    bindToggle,
} from 'material-ui-popup-state'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import AddPersonButton from '../Buttons/AddPersonButton/AddPersonButton'
import ChatButton from '../Buttons/ChatButton/ChatButton'
import DisconnectButton from '../Buttons/DisconnectButton/DisconnectButton'
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton'
import DeviceSelectionDialog from '../DeviceSelectionDialog/DeviceSelectionDialog'
import LocalVideoPreview from './DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview'
import { deviceSelectionScreenStyles as useStyles } from './DeviceSelectionScreen/styles'
import useChatContext from '../../hooks/useChatContext/useChatContext'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import AvatarIcon from '../../icons/AvatarIcon'
import { useAppState } from '../../state'
import HelpIcon from '@material-ui/icons/Help'
import { useHistory, useParams } from 'react-router'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import erikaUser from '../../../../assests/images/erika_provider.png'
import logo_white from '../../../../assests/Logos/tm2u_logo_white.png'
import removeUserFromWaitingQueue from '../../apis/deleteFromWaitingQueue'
import { getCurrentApp } from '../../apis/setCurrentApp'
import updateWaitingQueue from '../../apis/updateWaitingQueue'
import AddParticipantDialog from '../AddParticipantDialog/AddParticipantDialog'
import listWaitingQueue from '../../apis/listWaitingQueue'
import Popper from '@material-ui/core/Popper'
import CloseIcon from '@material-ui/icons/Close'
import icon_wifi from '../../../../assests/images/icon_wifi.svg'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useMediaQuery, Theme } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import FlipCameraButton from '../Buttons/FlipCameraButton/FlipCameraButton'
import useFlipCameraToggle from '../../hooks/useFlipCameraToggle/useFlipCameraToggle'
import { ConnectivityModal } from './ConnectivityModal/ConnectivityModal'
import { submitConnectivityIssue } from '../../apis/closingConsultation'

export default function PreJoinScreen() {
    const classes = useStyles()
    let history = useHistory()
    const {
        name,
        roomName,
    }: { name: string; roomName: string } = useParams()
    const [deviceSettingsOpen, setDeviceSettingsOpen] =
        useState(false)
    const [remainingTime, setRemainingTime] = useState(1)
    const [issueDescription, setIssueDescription] =
        React.useState('')

    const [isConnectivityModal, setIsConnectivityModal] =
        useState(false)
    let hours: string | number
    let minutes: string | number
    let seconds: string | number
    const {
        socket,
        currentUser,
        appointmentInfo,
        setcurrentAppointment,
    } = useProviderContext()
    const { getToken, isFetching } = useAppState()
    const { connect: chatConnect } = useChatContext()
    const [
        addParticipantsDialogOpen,
        setAddParticipantsDialogOpen,
    ] = React.useState(false)
    const { flipCameraDisabled, toggleFacingMode } =
        useFlipCameraToggle()

    const {
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
    } = useVideoContext()
    const [arrowRef, setArrowRef] =
        useState<HTMLSpanElement | null>(null)
    const isSmallScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    )

    useEffect(() => {
        //Add room id check
        socket.on('admit_user', (data: any) => {
            console.log('Current User-------', currentUser)
            console.log(
                'data User-------',
                data['client-Message'],
            )
            let user_id = currentUser?.participant_id
                ? currentUser?.participant_id
                : currentUser?.paitent_id
            if (
                data['client-Message']['user_id'] ==
                    user_id &&
                data['client-Message']['room_id'] ==
                    currentUser?.room_id
            ) {
                console.log(
                    'called from Pre join screen ----->>>>',
                )
                getToken(name, roomName, user_id)
                    .then(async (token) => {
                        await videoConnect(token)
                        //Update the Status of the Waiting Que
                        updateStatus()
                        // process.env
                        //     .REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
                        //     'true' &&
                        //     (await chatConnect(token))
                        let currentApp =
                            await getCurrentApp(
                                data['client-Message'][
                                    'appointment_id'
                                ],
                            )
                        console.log(
                            'currentApp prejoin-----------',
                            currentApp,
                        )
                        setcurrentAppointment(
                            currentApp.data,
                        )
                        history.push(`/room/${roomName}`)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        })

        socket.on(
            'remove_from_waiting_list',
            (data: any) => {
                let user_id = currentUser?.participant_id
                    ? currentUser?.participant_id
                    : currentUser?.paitent_id
                if (
                    data['client-Message']['user_id'] ==
                        user_id &&
                    data['client-Message']['first_name'] ==
                        currentUser?.first_name
                ) {
                    window.location.href = '/'
                }
            },
        )
        // return () => {
        //   let user_id = currentUser?.participant_id ? currentUser?.participant_id : currentUser?.paitent_id
        //   removeUserFromWaitingQueue(Number(user_id));
        // };
    }, [])

    const disableButtons =
        isFetching || isAcquiringLocalTracks || isConnecting
    const GRADIENT_COLORS: any = [
        ['#FFCA28', 0.5],
        ['#E65100', 0.5],
    ]

    const currentDateTime = new Date()
    let futureDateTime = new Date()
    futureDateTime.setSeconds(
        currentDateTime.getSeconds() + 1000,
    )

    const getDurationInMinutes = (): number => {
        let startTime = moment(
            appointmentInfo.start,
            'HH:mm:ss a',
        )
        let endTime = moment(
            appointmentInfo.end,
            'HH:mm:ss a',
        )
        return moment(endTime).diff(
            moment(startTime),
            'minutes',
        )
    }

    const getDurationInSeconds = (): number => {
        let startTime = moment(moment(), 'HH:mm:ss a')
        let endTime = moment
            .utc(appointmentInfo.start, 'HH:mm:ss a')
            .local()
        if (startTime < endTime) {
            return moment(endTime).diff(
                moment(startTime),
                'seconds',
            )
        } else {
            return 0
        }
    }

    const doctorInfo = {
        name: `Dr ${appointmentInfo.first_name} ${appointmentInfo.last_name}`,
        duration: `${getDurationInMinutes()} Minutes`,
        startTime: moment
            .utc(appointmentInfo.start, 'HH:mm:ss a')
            .local()
            .format('hh:mm a'),
        endTime: moment
            .utc(appointmentInfo.end, 'HH:mm:ss a')
            .local()
            .format('hh:mm a'),
    }

    const getTimer = (): React.ReactElement => {
        hours = formatZeros(
            Math.floor(
                (remainingTime % (3600 * 24)) / 3600,
            ),
        )
        minutes = formatZeros(
            Math.floor((remainingTime % 3600) / 60),
        )
        seconds =
            remainingTime === 0
                ? '00'
                : formatZeros(remainingTime % 60)
        return (
            <div className={classes.timer}>
                <div style={{ textAlign: 'center' }}>
                    <p className={classes.captionStyling}>
                        Meeting starts in:
                    </p>
                    <span
                        className={
                            hours === '00'
                                ? ''
                                : classes.textOrange
                        }>
                        {hours}
                    </span>
                    :
                    <span
                        className={
                            minutes === '00'
                                ? ''
                                : classes.textOrange
                        }>
                        {minutes}
                    </span>
                    :
                    <span
                        className={
                            seconds === '00'
                                ? ''
                                : classes.textOrange
                        }>
                        {seconds}
                    </span>
                </div>
                {!isSmallScreen && (
                    <div className={classes.timerMiniText}>
                        <p className={classes.miniText}>
                            Hours
                        </p>
                        <p className={classes.miniText}>
                            Minutes
                        </p>
                        <p className={classes.miniText}>
                            Seconds
                        </p>
                    </div>
                )}
            </div>
        )
    }

    const handleConnectivityModal = () => {
        setIsConnectivityModal(true)
    }
    const handleIssueChangeValue = (val: any) => {
        setIssueDescription(val)
    }
    const handleonConnectivityIssueSubmit = async () => {
        const payload = {
            appointment_id: appointmentInfo.id,
            issue_description: issueDescription,
        }
        let res = await submitConnectivityIssue(payload)
        setIsConnectivityModal(false)
    }
    const fetchWaitingList = async () => {
        try {
            if (currentUser) {
                const res = await listWaitingQueue(
                    currentUser?.room_id,
                )
                let current_user_id =
                    currentUser?.participant_id
                        ? currentUser?.participant_id
                        : currentUser?.paitent_id
                let waitingUserData = res.data.filter(
                    (el: any) => {
                        return el.user_id == current_user_id
                    },
                )
                if (waitingUserData.length > 0) {
                    return waitingUserData[0].id
                } else {
                    return null
                }
            }
        } catch (error) {
            console.log({ Errorwaitinglist: error })
        }
    }

    const updateStatus = async () => {
        let user_id = await fetchWaitingList()
        if (user_id) {
            await updateWaitingQueue(user_id, {
                is_admitted: true,
            })
            socket.emit('get_waiting_list_user', {
                room_id: currentUser?.room_id,
            })
        }
    }

    const handleEnd = async (event: boolean) => {
        try {
            let user_id = await fetchWaitingList()
            if (user_id) {
                await removeUserFromWaitingQueue(user_id)
                console.log(
                    'After remove socket called',
                    user_id,
                    socket.connected,
                )
                socket.emit('get_waiting_list_user', {
                    room_id: currentUser?.room_id,
                })
            }
            if (event) {
                window.location.href = '/'
            }
        } catch (e) {
            console.log('error pre join', e)
        }
    }

    const formatZeros = (
        number: number,
    ): string | number => {
        return number < 10 ? '0' + number : number
    }

    const renderTimer = (): React.ReactElement => {
        return (
            <CountdownCircleTimer
                isPlaying
                duration={getDurationInSeconds()}
                strokeWidth={4}
                size={isSmallScreen ? 110 : 150}
                isLinearGradient
                colors={GRADIENT_COLORS as any}
                onComplete={() => {
                    setRemainingTime(0)
                }}>
                {({ remainingTime }) => {
                    remainingTime &&
                        setRemainingTime(remainingTime)
                    return (
                        <img
                            src={erikaUser}
                            className={
                                classes.profileImgWrap
                            }
                        />
                    )
                }}
            </CountdownCircleTimer>
        )
    }

    const renderActions = (): React.ReactElement => {
        return (
            <Grid container justifyContent="center">
                <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    style={{ height: '100%' }}>
                    <div
                        className={
                            classes.audioVideoTestBtnContainer
                        }>
                        <div style={{ marginRight: '1em' }}>
                            {isSmallScreen && (
                                <FlipCameraButton
                                    className={
                                        classes.testAudiVideoBtn
                                    }
                                    disabled={
                                        flipCameraDisabled
                                    }
                                    onClick={
                                        toggleFacingMode
                                    }
                                />
                            )}
                        </div>
                        <Grid
                            className={
                                classes.iconContainer
                            }>
                            <PopupState variant="popover">
                                {(popupState) => (
                                    <div>
                                        <HelpOutlineIcon
                                            color="inherit"
                                            fontSize="large"
                                            onClick={
                                                popupState.toggle
                                            }
                                        />
                                        <Popper
                                            className={
                                                classes.helpPopper
                                            }
                                            open={
                                                popupState.isOpen
                                            }
                                            anchorEl={
                                                popupState.anchorEl
                                            }
                                            placement="top"
                                            modifiers={{
                                                preventOverflow:
                                                    {
                                                        enabled:
                                                            true,
                                                        boundariesElement:
                                                            'scrollParent',
                                                    },
                                                arrow: {
                                                    enabled:
                                                        true,
                                                    element:
                                                        arrowRef,
                                                },
                                            }}>
                                            <Box
                                                p={2}
                                                bgcolor="white"
                                                borderRadius="0.5em"
                                                alignItems="center"
                                                overflow="visible">
                                                <Typography
                                                    component={
                                                        'div'
                                                    }>
                                                    <span
                                                        className={
                                                            classes.close
                                                        }>
                                                        <CloseIcon
                                                            style={{
                                                                color: 'black',
                                                                fontSize: 18,
                                                                cursor: 'pointer',
                                                            }}
                                                            {...bindToggle(
                                                                popupState,
                                                            )}
                                                        />
                                                    </span>
                                                    Having
                                                    connectivity
                                                    issues?{' '}
                                                    {/* <a
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        href="tel://+1 (617)431-2449"
                                                    >
                                                        Click
                                                        here
                                                    </a> */}
                                                    <span
                                                        onClick={
                                                            handleConnectivityModal
                                                        }
                                                        className={
                                                            classes.clickHereBtn
                                                        }>
                                                        Click
                                                        here
                                                    </span>
                                                    <br />
                                                    Provider
                                                    will try
                                                    to reach
                                                    you on
                                                    phone
                                                    <br />{' '}
                                                    number
                                                    or dial
                                                    in on
                                                    <a href="tel://+1 (617)431-2449">
                                                        +1
                                                        (617)431-2449
                                                    </a>
                                                </Typography>
                                                <span
                                                    className={
                                                        classes.popperArrow
                                                    }
                                                    ref={(
                                                        node,
                                                    ) =>
                                                        setArrowRef(
                                                            node,
                                                        )
                                                    }
                                                />
                                            </Box>
                                        </Popper>
                                    </div>
                                )}
                            </PopupState>
                        </Grid>

                        <AddParticipantDialog
                            open={addParticipantsDialogOpen}
                            onClose={() => {
                                setAddParticipantsDialogOpen(
                                    false,
                                )
                            }}
                        />
                        <Button
                            variant="outlined"
                            onClick={() =>
                                setDeviceSettingsOpen(true)
                            }
                            className={
                                classes.testAudiVideoBtn
                            }>
                            {isSmallScreen ? (
                                <SettingsIcon
                                    className={
                                        classes.settingIconSize
                                    }
                                />
                            ) : (
                                'Test your audio and video'
                            )}
                        </Button>
                    </div>

                    <div
                        className={
                            classes.buttonsContainer
                        }>
                        <ToggleAudioButton
                            className={classes.buttons}
                            disabled={disableButtons}
                            hideLabel
                        />
                        <ToggleVideoButton
                            className={classes.buttons}
                            disabled={disableButtons}
                            hideLabel
                        />
                        {/* <ChatButton className={classes.buttons} onClick={() => { }} /> */}
                        {Object.keys(appointmentInfo)
                            .length !== 0 &&
                        !currentUser?.participant_id ? (
                            <AddPersonButton
                                className={classes.buttons}
                                onClick={() =>
                                    setAddParticipantsDialogOpen(
                                        true,
                                    )
                                }
                            />
                        ) : null}
                        <DisconnectButton
                            className={
                                classes.disconnectButton
                            }
                            onClick={() => handleEnd(true)}
                        />
                    </div>
                </Grid>
            </Grid>
        )
    }

    const renderDurationInfo = (): React.ReactElement => {
        return (
            <>
                <img
                    src={logo_white}
                    className={classes.logoContainer}
                    alt="logo_white"
                />
                <div
                    className={
                        classes.durationInfoContainer
                    }>
                    <p className={classes.textStyling}>
                        Start time :{' '}
                        <strong>
                            {doctorInfo.startTime}
                        </strong>
                    </p>
                    <p className={classes.textStyling}>
                        Ends at :{' '}
                        <strong>
                            {doctorInfo.endTime}
                        </strong>
                    </p>
                </div>
            </>
        )
    }
    return (
        <>
            <div className={classes.background}>
                <div className={classes.bodyContainer}>
                    <div className={classes.startTime}>
                        {renderDurationInfo()}
                    </div>
                    <div
                        className={
                            classes.meetingContainer
                        }>
                        {renderTimer()}
                        <div
                            className={
                                classes.contentStyling
                            }>
                            <p
                                className={
                                    classes.doctorNameStyling
                                }>
                                {doctorInfo.name}
                            </p>
                            {isConnectivityModal && (
                                <ConnectivityModal
                                    opened={true}
                                    onClose={() =>
                                        setIsConnectivityModal(
                                            false,
                                        )
                                    }
                                    onConnectivityIssueSubmit={
                                        handleonConnectivityIssueSubmit
                                    }
                                    handleIssueChangeValue={
                                        handleIssueChangeValue
                                    }
                                />
                            )}

                            {!isSmallScreen ? (
                                <p
                                    className={
                                        classes.captionStyling
                                    }>
                                    Call Duration:{' '}
                                    <strong>
                                        {
                                            doctorInfo.duration
                                        }
                                    </strong>
                                </p>
                            ) : (
                                remainingTime !== 0 &&
                                getTimer()
                            )}
                            <p className={classes.content}>
                                Waiting for your physician
                                to start this meeting
                            </p>
                            {!isSmallScreen &&
                                remainingTime !== 0 &&
                                getTimer()}
                        </div>
                    </div>
                    <div
                        className={
                            classes.localPreviewContainer
                        }>
                        <LocalVideoPreview
                            identity={name}
                        />
                    </div>
                </div>
                {renderActions()}
            </div>
            <DeviceSelectionDialog
                open={deviceSettingsOpen}
                onClose={() => {
                    setDeviceSettingsOpen(false)
                }}
            />
        </>
    )
}
