import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { MenstruationAndPregnancyHistory } from 'containers/PatientsSetting/interfaces'
import { ModalEl as Modal } from 'shared/elements'
import './style.scss'

import { Grid } from '@mui/material'
import { Footer } from 'shared/elements/FormItem/Footer'
import { InputNumber } from 'shared/elements/FormItem/Input'
import { Loading } from 'shared/elements/Loading'
import { Checklist } from './Checklist'
import { MultipleSelect } from './MultipleSelect'
import { apiMenstruationAndPregnancyHistory } from 'services/Patient/apiMenstruationAndPregnancyHistory'
import { useNotification } from 'shared/elements/Notification'
import { uniqBy } from 'lodash'
import { getPatient } from 'services/Patient/patient'
import { getAge } from 'utilities/formats'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'

type Props = {
    MAPId?: string | false
    patientId?: string | false
    encounter_id?: string
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const validationSchema = yup.object({
    age_of_first_menstruation: yup
        .number()
        .min(0, 'incorrect age format!')
        .max(
            yup.ref('age_patient'),
            "The age of first menstruation must be less than or equal to the patient's age!",
        ),
})

const INNIT_DEFAULT_CONTRACEPTION = [
    // @TODO:
    { label: 'Not using contraception ', checked: false },
    { label: 'Diaphragm', checked: false },
    { label: 'Condom', checked: false },
    { label: 'Femal condom', checked: false },
    { label: 'Intrauterine device', checked: false },
    { label: 'Fertility awareness', checked: false },
    { label: 'Hormonal implant', checked: false },
    { label: 'Hormonal injection', checked: false },
    { label: 'Hormonal pill', checked: false },
    { label: 'Hormonal patch', checked: false },
    { label: 'Hormonal contraceptive ring', checked: false },
    { label: 'Permanent sterilization', checked: false },
    { label: 'Infertility ', checked: false },
]

const PremenstrualSymptoms = [
    'Change in appetite',
    'Dysphoria',
    'Bloating',
    'Cramps',
    'Sleep disturbance',
]

const InitialValues = {
    age_of_first_menstruation: '',
    premenstrual_symptoms: [],
    contraception: [],
    number_of_pregnancies: 0,
    number_of_births: 0,
    number_of_miscarriages: 0,
    number_of_abortions: 0,
    age_patient: 100,
}

const MenstruationAndPregnancyHistoryForm = (props: Props) => {
    // Hooks
    const notification = useNotification()

    // State
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)

    const [contraceptionChecklist, setContraceptionChecklist] = useState<
        { label: string; checked: boolean }[]
    >(INNIT_DEFAULT_CONTRACEPTION)

    const [listSelectedPremenstrualSymptoms, setListSelectedPremenstrualSymptoms] = useState<
        string[]
    >([])

    const formik = useFormik<MenstruationAndPregnancyHistory>({
        validationSchema,
        enableReinitialize: true,
        initialValues: InitialValues,
        onSubmit: handleSubmit,
    })

    function compareObject(obj1, obj2, dropKey?: string[]) {
        const a = { ...obj1 }
        const b = { ...obj2 }
        if (dropKey) {
            dropKey.forEach((key) => {
                delete a[key]
                delete b[key]
            })
        }

        return JSON.stringify(a) === JSON.stringify(b) ? true : false
    }

    async function handleSubmit(values) {
        const contraception = contraceptionChecklist
            .filter((item) => item.checked === true)
            .map((item) => item.label)
        const premenstrual_symptoms = [...listSelectedPremenstrualSymptoms]
        const payload = {
            ...values,
            contraception,
            encounter_id: props?.encounter_id,
            premenstrual_symptoms,
        }

        delete payload['age_patient']

        const checkValueForm = compareObject(InitialValues, payload, ['age_patient'])
        if (checkValueForm && !props.MAPId) {
            setAlerEmtyForm(true)
            return
        }

        try {
            setIsSubmitting(true)
            if (props.patientId && !props.MAPId) {
                const resp = await apiMenstruationAndPregnancyHistory.create(
                    props.patientId,
                    payload,
                )
                notification('Menstruation And Pregnancy History created successfully', 'success')
                props.onOke?.()
            } else if (props.patientId && props.MAPId) {
                const resp = await apiMenstruationAndPregnancyHistory.update(
                    props.patientId,
                    props.MAPId,
                    payload,
                )
                notification('Menstruation And Pregnancy History updated successfully', 'success')
                props.onOke?.()
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    async function getMenstruationAndPregnancyById() {
        try {
            setLoading(true)
            const resp = await apiMenstruationAndPregnancyHistory.getId(
                props.patientId,
                props.MAPId,
            )
            console.log(resp.data)
            if (resp.data) {
                let contraceptionMap = []
                if (resp.data.contraception?.length) {
                    contraceptionMap = resp.data.contraception.map((item) => ({
                        label: item,
                        checked: true,
                    }))
                }
                setContraceptionChecklist(
                    uniqBy([...contraceptionMap, ...contraceptionChecklist], function (e) {
                        return e.label
                    }),
                )

                if (resp.data.premenstrual_symptoms?.length) {
                    setListSelectedPremenstrualSymptoms(resp.data.premenstrual_symptoms)
                }

                const resPatient = await getPatient(props.patientId)
                let agePatient
                if (resPatient.data) {
                    agePatient = getAge(resPatient.data.dob, 'YYYY-MM-DD')
                    if (agePatient === 0) agePatient = 1
                }

                const {
                    age_of_first_menstruation,
                    premenstrual_symptoms,
                    contraception,
                    number_of_pregnancies,
                    number_of_births,
                    number_of_miscarriages,
                    number_of_abortions,
                } = resp.data

                formik.setValues({
                    age_of_first_menstruation,
                    premenstrual_symptoms,
                    contraception,
                    number_of_pregnancies,
                    number_of_births,
                    number_of_miscarriages,
                    number_of_abortions,
                    age_patient: agePatient,
                })
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && props.MAPId) {
            getMenstruationAndPregnancyById()
        }
    }, [props.patientId, props.MAPId])

    const handleAdNewContraception = (newText) => {
        if (newText) {
            setContraceptionChecklist([
                { label: newText, checked: false },
                ...contraceptionChecklist,
            ])
        }
    }

    const handleChangChekedContraception = (rowItem, checked) => {
        const record = [...contraceptionChecklist]
        const index = record.findIndex((element) => element.label === rowItem.label)
        record[index] = {
            ...record[index],
            checked,
        }
        setContraceptionChecklist(record)
    }

    const handleDeleteContraception = (rowItem) => {
        const newContraceptionChecklist = contraceptionChecklist.filter(
            (item) => item.label !== rowItem.label,
        )
        setContraceptionChecklist(newContraceptionChecklist)
    }

    const modalTitle = props.MAPId
        ? 'Update Menstruation and Pregnancy History'
        : 'Add New Menstruation and Pregnancy History'

    const handleChangePremenstrualSymptoms = (label, checked) => {
        let record = [...listSelectedPremenstrualSymptoms]
        const index = record.findIndex((item) => item === label)
        if (checked) {
            record.push(label)
        } else {
            record = record.filter((item) => item !== label)
        }

        setListSelectedPremenstrualSymptoms(record)
    }

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                console.log(resp.data)
                let age = getAge(resp.data.dob, 'YYYY-MM-DD')
                if (age === 0) age = 1
                if (age) {
                    formik.setFieldValue('age_patient', age)
                    // setInitialValues({
                    //     ...initialValues,
                    //     age_patient: age,
                    // })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && !props.MAPId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    return (
        <Modal
            width={800}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="outpatient-psychiatric-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12}>
                        <InputNumber
                            label="Age of first menstruation"
                            name="age_of_first_menstruation"
                            min={1}
                            max={99}
                            value={formik.values.age_of_first_menstruation}
                            error={
                                formik.touched.age_of_first_menstruation &&
                                Boolean(formik.errors.age_of_first_menstruation)
                            }
                            helperText={
                                formik.touched.age_of_first_menstruation &&
                                formik.errors.age_of_first_menstruation
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('age_of_first_menstruation', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MultipleSelect
                            label={'Premenstrual Symptoms'}
                            value={listSelectedPremenstrualSymptoms}
                            options={PremenstrualSymptoms}
                            handleChange={handleChangePremenstrualSymptoms}
                        />
                    </Grid>

                    <Checklist
                        label="Contraception"
                        checkList={contraceptionChecklist}
                        handleAddNew={handleAdNewContraception}
                        handleChangCheked={handleChangChekedContraception}
                        handleDelete={handleDeleteContraception}
                    />

                    <Grid item xs={12}>
                        <InputNumber
                            label="Number of pregnancies "
                            name="number_of_pregnancies"
                            min={0}
                            max={99}
                            value={formik.values.number_of_pregnancies}
                            error={
                                formik.touched.number_of_pregnancies &&
                                Boolean(formik.errors.number_of_pregnancies)
                            }
                            helperText={
                                formik.touched.number_of_pregnancies &&
                                formik.errors.number_of_pregnancies
                            }
                            onChangeValue={(value) => {
                                formik.setFieldValue('number_of_pregnancies', value)
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputNumber
                            label="Number of births"
                            name="number_of_births"
                            min={0}
                            max={99}
                            value={formik.values.number_of_births}
                            error={
                                formik.touched.number_of_births &&
                                Boolean(formik.errors.number_of_births)
                            }
                            helperText={
                                formik.touched.number_of_births && formik.errors.number_of_births
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('number_of_births', value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputNumber
                            label="Number of miscarriages"
                            name="number_of_miscarriages"
                            min={0}
                            max={99}
                            value={formik.values.number_of_miscarriages}
                            error={
                                formik.touched.number_of_miscarriages &&
                                Boolean(formik.errors.number_of_miscarriages)
                            }
                            helperText={
                                formik.touched.number_of_miscarriages &&
                                formik.errors.number_of_miscarriages
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('number_of_miscarriages', value)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputNumber
                            label="Number of abortions "
                            name="number_of_abortions"
                            min={0}
                            max={99}
                            value={formik.values.number_of_abortions}
                            error={
                                formik.touched.number_of_abortions &&
                                Boolean(formik.errors.number_of_abortions)
                            }
                            helperText={
                                formik.touched.number_of_abortions &&
                                formik.errors.number_of_abortions
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('number_of_abortions', value)
                            }
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={isSubmitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.MAPId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>

            <AlertEmtyForm
                title="Please input the value to form!"
                description={'Please input the value to form!'}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </Modal>
    )
}

// export { SocialForm }
export default MenstruationAndPregnancyHistoryForm
