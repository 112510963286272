import React, { useRef, useState, useEffect } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'

import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import Routes from 'constants/routes'
import PhqFormDetails from './PhqFormDetails'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    form: {
        position: 'relative',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
})

function PhqDetails() {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()

    const location = useLocation()
    const { id } = useParams()

    const goBack = () =>
        history.replace(Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(':id', id))
    //heading title
    const headingTitle = () => {
        const pageTitle = 'Behavior Rate Scale - PHQ9'

        return (
            <h1 className="page-title" style={{ fontFamily: 'Rubik' }}>
                {pageTitle}
            </h1>
        )
    }
    return (
        <div ref={formRef} style={{ height: '100%' }}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    onClick={() => {location.state.fromClinicalNote ? history.goBack() : goBack()}}
                    style={{
                        cursor: 'pointer',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>
                    {location.state.fromClinicalNote ? 'Back' : 'Back to patient health record'}
                    </Typography>
                </Grid>
                <Grid container className={`border-bottom`}>
                    {' '}
                    {headingTitle()}
                </Grid>

                <Grid container direction="column" className={classes.form}>
                    {location?.state?.backTitle && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}></div>
                    )}
                    <div style={{ overflow: 'auto', maxHeight: '550px', minHeight: '550px' }}>
                        <PhqFormDetails />
                    </div>
                    <Grid
                        container
                        item
                        style={{ marginTop: '16px', position: 'sticky', bottom: '0px' }}
                        direction="row"
                        spacing={2}
                        xs={12}>
                        <Grid item xs={12}>
                            <Button
                                onClick={()=>{location.state.fromClinicalNote ? history.goBack() : goBack()}}
                                style={{
                                    borderRadius: '10px',
                                    width: '100%',
                                    background: '#303E4E',
                                }}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default PhqDetails
