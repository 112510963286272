import { Box, TextField, Typography } from '@material-ui/core'
import React from 'react'

const RenderHeightFtIn = ({ master, values, handleChange, classes }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'height',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)
    const valueArr = filteredEle?.value?.split('/')?.map((v) => v.trim()) || []

    const handleInput = (e) => {
        const { name, value } = e.target
        const obj = {
            value_0: valueArr[0] || '',
            value_1: valueArr[1] || '',
        }
        obj[name] = value.replace(/[^0-9]/g, '')
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: `${obj.value_0}/${obj.value_1}`,
        })
    }

    return (
        <Box
            sx={{
                borderRadius: 3,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
                width: 170,
                gap: 10,
            }}>
            <Box
                sx={{
                    border: '1px solid #EAECF0',
                    width: '85px',
                    borderRadius: 3,
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#FFF',
                    '& .MuiInputBase-input': {
                        textAlign: 'center',
                    },
                }}>
                <TextField
                    variant="standard"
                    placeholder="Feet"
                    classes={{
                        root: classes.inputHeight,
                    }}
                    inputProps={{
                        maxLength: 3,
                        style: {
                            textAlign: 'center',
                        },
                    }}
                    style={{ width: 60 }}
                    InputProps={{ disableUnderline: true }}
                    value={valueArr[0] === 'undefined' ? '' : valueArr[0]}
                    name="value_0"
                    onChange={handleInput}
                />
            </Box>

            <Typography style={{ color: '#303e4e', fontWeight: 500 }}>/</Typography>

            <Box
                sx={{
                    border: '1px solid #EAECF0',
                    width: '85px',
                    borderRadius: 3,
                    alignItems: 'center',
                    display: 'flex',
                    backgroundColor: '#FFF',
                    '& .MuiInputBase-input': {
                        textAlign: 'center',
                    },
                }}>
                <TextField
                    variant="standard"
                    placeholder="Inches"
                    inputProps={{
                        maxLength: 3,
                        style: {
                            textAlign: 'center',
                        },
                    }}
                    InputProps={{ disableUnderline: true }}
                    value={valueArr[1] === 'undefined' ? '' : valueArr[1]}
                    name="value_1"
                    onChange={handleInput}
                />
            </Box>
        </Box>
    )
}

export default RenderHeightFtIn