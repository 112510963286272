import { Box, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import CollapsePaper from '../../shared/Elements/CollapsePaper'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionGridContainer: {
        // margin: '12px 0',
    },
    textFieldWrap: {
        height: 40,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})

const mapSections = (examMaster = [], property) => {
    return examMaster.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

const AssessmentInformedConsent = ({
    fieldName,
    master,
    values,
    onChange,
    lastNarrative,
    macros,
    nonEditable,
    copyAble,
    copyValues
}) => {
    const classes = useStyles()
    const sections = mapSections(
        master?.assInformedConsentMasters,
        'section_name',
    )

    const handleInputChange = (e, se) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name]
            ? [...values[name]]
            : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [
                    ...updatedValue,
                    {
                        id: value,
                        desc: '',
                        label: se.name,
                    },
                ]
                : updatedValue.filter(
                    (uv) => uv.id !== value,
                )
        }

        if (type === 'text') {
            updatedValue = updatedValue.map((uv) =>
                uv.id === se._id
                    ? { ...uv, desc: value }
                    : uv,
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }
    const handleSelectMacro = (value) => {
        if (nonEditable) { return }
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_values_informed_consent_opt = []
            const informed_consent_opt = copyValues?.informed_consent_opt?.length ? copyValues?.informed_consent_opt : []
            for (const element of informed_consent_opt) {
                if (typeof element === 'object' && element !== null) {
                    found_values_informed_consent_opt.push(element);
                }
            }

            const found_values_confidentiality_disc = []
            const confidentiality_disc = copyValues?.confidentiality_disc?.length ? copyValues?.confidentiality_disc : []
            for (const element of confidentiality_disc) {
                if (typeof element === 'object' && element !== null) {
                    found_values_confidentiality_disc.push(element);
                }
            }

            const found_values_treat_consent_given_by = []
            const treat_consent_given_by = copyValues?.treat_consent_given_by?.length ? copyValues?.treat_consent_given_by : []
            for (const element of treat_consent_given_by) {
                if (typeof element === 'object' && element !== null) {
                    found_values_treat_consent_given_by.push(element);
                }
            }

            const finalValues = {
                narrative: `${copyValues?.narrative} ${values?.narrative}`,
                informed_consent_opt: [...found_values_informed_consent_opt, ...(values?.informed_consent_opt || [])],
                confidentiality_disc: [...found_values_confidentiality_disc, ...(values?.confidentiality_disc || [])],
                treat_consent_given_by: [...found_values_treat_consent_given_by, ...(values?.treat_consent_given_by || [])]
            }
            if (!found_values_confidentiality_disc.length && !found_values_informed_consent_opt.length && !found_values_treat_consent_given_by.length && copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>

            {/* <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} /> */}

            {
                copyAble && !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )
            }

            {
                Object.keys(sections).map((key, index) => {
                    const sectionElements = sections[key]
                    return (
                        <CollapsePaper
                            key={index}
                            isCollapseDefault={!questions[key]}
                            header={questions[key] || 'Others'}
                            collapseComp={
                                <Grid
                                    container
                                    className={
                                        classes.sectionGridContainer
                                    }>
                                    {sectionElements.map(
                                        (se, index) => {
                                            const isSelected =
                                                values[
                                                    se
                                                        .section_name
                                                ]?.find(
                                                    (v) =>
                                                        v.id ===
                                                        se._id,
                                                )
                                            return (
                                                <Grid
                                                    container
                                                    key={index}>
                                                    <CheckboxOption
                                                        disabled={nonEditable}
                                                        name={
                                                            se.section_name
                                                        }
                                                        option={{
                                                            value: se._id,
                                                            label: se.name,
                                                        }}
                                                        onChange={(
                                                            e,
                                                        ) =>
                                                            handleInputChange(
                                                                e,
                                                                se,
                                                            )
                                                        }
                                                        checked={
                                                            isSelected
                                                                ? true
                                                                : false
                                                        }
                                                    />
                                                    {se.is_free_text && (
                                                        <TextField
                                                            disabled={
                                                                !isSelected || nonEditable
                                                            }
                                                            name={
                                                                se.section_name
                                                            }
                                                            variant="outlined"
                                                            className={
                                                                classes.textFieldWrap
                                                            }
                                                            placeholder={
                                                                'Place Holder'
                                                            }
                                                            size="small"
                                                            value={
                                                                isSelected?.desc ||
                                                                ''
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                handleInputChange(
                                                                    e,
                                                                    se,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                            )
                                        },
                                    )}
                                </Grid>
                            }
                        />
                    )
                })
            }
            <Grid item>
                <NarrativeAndMacro
                    hideClearBtn={true}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    macros={macros}
                    meta={{
                        values,
                        master: master?.assInformedConsentMasters,
                    }}
                    onChange={onChange}
                    narrativeValue={values.narrative}
                    values={values}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box >
    )
}

export default AssessmentInformedConsent
