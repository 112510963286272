import React from 'react'
import { Typography } from '@material-ui/core'

import { formatDateTime } from 'utilities/formats'
import { ErrorType, Patient } from '../interfaces'
import { getDocumentById } from 'services/Documents'
import moment from 'moment'

interface DataGridCellProps {
  value: React.ReactNode | React.ReactChildren | string | number | boolean | null | undefined
  highlight?: boolean
  style?: React.CSSProperties
}

export const DataGridCell = ({ value, highlight = false, style = {} }: DataGridCellProps) => {
  const textColor = value ? (highlight ? '#5571C6' : '') : '#D3D8E0'

  return (
    <Typography
      style={{ color: textColor, ...style }}>
      {value ? value : 'N/A'}
    </Typography>
  )
}

export const getPatientName = (patientInfo: Patient) => {
  return [
    patientInfo?.first_name,
    patientInfo?.middle_name,
    patientInfo?.last_name,
    patientInfo?.suffix && patientInfo.suffix !== 'undefined' ? `(${patientInfo.suffix})` : null
  ]
    .filter(_ => !!_ && _ !== 'undefined')
    .join(' ')
}

export const getPatientGenderAndBirthDate = (patientInfo: Patient) => {
  return [
    patientInfo.gender,
    moment(patientInfo.dob).format('MM/DD/YYYY')
  ]
    .filter(_ => !!_ && _ !== 'undefined')
    .join(', ')
}

export const downloadDocument = async (
  documentId: string | number | undefined,
  documentName: string | undefined,
  notification: (...params: any[]) => void) => {
  // Case: no file attached. Notify and do nothing
  if (!documentId || !documentName) return notification('There is no attachment file!', 'warning')

  try {
    notification('Preparing document...', 'info')

    const { data } = await getDocumentById(documentId)
    const uri = data.url

    if (uri) {
      const response = await fetch(uri)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('display', 'none')
      a.setAttribute('href', url)
      a.setAttribute('download', documentName)

      a.click()
      window.URL.revokeObjectURL(
        url,
      )
      a.remove()
    }
  } catch (exception) {
    notification((exception as ErrorType).data?.message || 'Something went wrong!', 'error')
  }
}

export const previewDocument = async (
  documentId: string | number | undefined,
  documentName: string | undefined,
  notification: (...params: any[]) => void) => {
  // Case: no file attached. Notify and do nothing
  if (!documentId || !documentName) return notification('There is no attachment file!', 'warning')

  try {
    notification('Preparing document...', 'info')

    const { data } = await getDocumentById(documentId)
    const uri = data.url

    if (uri) {
      const response = await fetch(uri)
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('display', 'none')
      a.setAttribute('href', url)
      a.setAttribute('target', '_blank')

      a.click()
      window.URL.revokeObjectURL(
        url,
      )
      a.remove()
    }
  } catch (exception) {
    notification((exception as ErrorType).data?.message || 'Something went wrong!', 'error')
  }
}
