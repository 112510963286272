import { Grid, makeStyles, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChatProvider } from '../../../components/Consultation/components/ChatProviderContext'
import { VideoProvider } from '../../../components/Consultation/components/VideoProvider'
import EmptyNoteIcon from '../../../components/Consultation/icons/EmptyNoteIcon'
import ConsultationList from '../../../components/TwilioVideo/ConsultationList'
import ProviderList from '../../../components/TwilioVideo/ProviderList'
import VideoChat from '../../../components/TwilioVideo/VideoChat'
import {
    handleGetEncounterQueueList,
    setEncounterMeetingId,
    updateEncounterQueueList,
    fetchAssistantPractitioner,
    addEncounterQueueList,
    actionsGetTokenVideoProvider,
    saveObjectEncouterStart,
} from '../../../store/actions/provider'
import LoadingPage from '../../../utilities/loading-telehealth'
import EncounterQueue from '../components/EncounterQueue'
import ClinicalNotesWrapper from '../../ClinicalNotes/ClinicalNotesWrapper'
import { ClinicalNoteProvider } from '../../ClinicalNotes/context/ClinicalNoteContext'
import { setInterval } from 'timers'
import { config } from '../../../constants'
import { TELE_HEALTH_CONSULTATION } from '../constants'
import LabOrder from '../LabOrder/LabOrder'
import HealthRecords from '../HealthRecords/HealthRecords'
import { ENCOUNTER_STATUS } from '../../CalendarScheduler/constant'
import { _getClinicalNotesDetail } from '../../ClinicalNotes/api/services'
import { PatientDashboard } from 'containers/PatientsSetting/view/PatientDashboard'
import { TAB_CLINICALNOTE, TAB_HEALTH_RECORD } from 'constants/menu'
import { useHistory } from 'react-router-dom'
import Routes from 'constants/routes'
import { SET_TELEHEALTH_SCREEN } from 'store/actionType'
import useVideoContext from 'components/Consultation/hooks/useVideoContext/useVideoContext'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    emptyNoteWrap: {
        height: 'calc(100vh - 108px)',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
    },
    wrapText: {
        maxWidth: '630px',
        textAlign: 'center',
        // marginTop: '10px'
    },
    title: {
        fontSize: '24px',
        color: '#303E4E',
        marginTop: 16,
    },
    text: {
        marginTop: '6px',
        fontSize: '14px',
    },
    encounterQueueWrap: {
        width: '320px',
        height: '100%',
    },
})

const EmptyNote = () => {
    // const { history } = props

    const history = useHistory()
    const classes = useStyles()
    const dispatch = useDispatch()
    // const { getAudioAndVideoTracks } = useVideoContext()
    // const [nodeDetailData, setNodeDetailData] = useState(null)

    const [noteId, setNoteId] = useState('')
    const [mediaError, setMediaError] = useState(false)
    const [chatError, setChatError] = useState(false)
    const [inProgressEncounter, setInProgressEncounter] = useState<{ patient?: { id?: string } }>()

    // const telehealthScreen = useSelector((state: any) => state.telehealthScreen)
    const encounterQueueList = useSelector((state: any) => state.provider.encounterQueueList)
    const encounterMeetingId = useSelector((state: any) => state.provider.encounterMeetingId)

    const openListConsultation = useSelector((state: any) => state.provider?.openListConsultation)
    const openListProvider = useSelector((state: any) => state.provider.openListProvider)

    const objectPatient = useSelector((state: any) => state.provider.objectEncouterStart)
    const encounterRoom = useSelector((state: any) => state.provider.encounterRoom)

    const exitEncounter = () => {
        dispatch({
            type: SET_TELEHEALTH_SCREEN,
            active: false,
            tabName: '',
        })
        history.push(Routes.SCHEDULE)
    }

    const getApiEncounterQueue = async () => {
        dispatch(handleGetEncounterQueueList())
    }

    useEffect(() => {
        if (encounterMeetingId) {
            const encounter = encounterQueueList?.find(
                (item: any) => item.encounter_id === encounterMeetingId,
            )
            if (!encounter) return exitEncounter()
            dispatch(actionsGetTokenVideoProvider(encounter))
            setInProgressEncounter(encounter)
            dispatch(saveObjectEncouterStart(encounter))
            setNoteId(encounter.clinical_note_id)
        } else {
            exitEncounter()
        }
    }, [encounterMeetingId])

    // useEffect(() => {
    //     if (encounterMeetingId) {
    //         const encounter = encounterQueueList?.find(
    //             (item: any) => item.encounter_id === encounterMeetingId,
    //         )
    //         if (!encounter) {
    //             return
    //         }
    //         dispatch(actionsGetTokenVideoProvider(encounter))
    //         setInProgressEncounter(encounter)
    //         dispatch(saveObjectEncouterStart(encounter))

    //         _getClinicalNotesDetail(encounter.clinical_note_id).then((response: any) => {
    //             if (response?.data?.data) {
    //                 try {
    //                     setNodeDetailData({
    //                         ...response?.data,
    //                         patient_first_name:
    //                             response?.data?.patient_first_name || encounter.patient.first_name,
    //                         patient_last_name:
    //                             response?.data?.patient_last_name || encounter.patient.last_name,
    //                         patient_id: response?.data?.patient_id || encounter.patient.id,
    //                     })
    //                 } catch (e) {
    //                     console.log(e, 'errr')
    //                 }
    //             }
    //         })
    //     } else {
    //         setNodeDetailData(null)
    //         history.push('/schedule')
    //     }
    // }, [encounterMeetingId])

    // const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())

    useEffect(() => {
        getApiEncounterQueue()
    }, [])

    useEffect(() => {
        if (encounterRoom && noteId) {
            history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, { id: noteId })
        }
    }, [encounterRoom, noteId])

    // useEffect(() => {
    //     if (telehealthScreen.tabName === TAB_HEALTH_RECORD && inProgressEncounter?.patient?.id) {
    //         history.push(
    //             Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(
    //                 ':id',
    //                 inProgressEncounter?.patient?.id,
    //             ),
    //         )
    //     } else if (telehealthScreen.tabName === TAB_CLINICALNOTE) {
    //         history.push(Routes.TELE_HEALTH.CONSULTATION)
    //     }
    // }, [telehealthScreen.tabName])

    // return (
    //     <div>
    //         <VideoProvider>
    //             <ChatProvider>
    //                 <VideoChat lastMessage={null} inProgressEncounter={inProgressEncounter} />
    //             </ChatProvider>
    //         </VideoProvider>

    //         {telehealthScreen.tabName === TAB_CLINICALNOTE && (
    //             <Grid className={classes.container} justifyContent="flex-start">
    //                 <Grid
    //                     alignItems="center"
    //                     justifyContent="center"
    //                     className={classes.emptyNoteWrap}>
    //                     {Boolean(nodeDetailData) && (
    //                         <ClinicalNoteProvider>
    //                             <ClinicalNotesWrapper
    //                                 nodeDetailData={nodeDetailData}
    //                                 isConsultant={!isEmpty(objectPatient)}
    //                             />
    //                         </ClinicalNoteProvider>
    //                     )}
    //                     {!Boolean(nodeDetailData) && (
    //                         <Grid
    //                             container
    //                             direction="column"
    //                             justifyContent="center"
    //                             alignItems="center">
    //                             <EmptyNoteIcon />
    //                         </Grid>
    //                     )}
    //                 </Grid>
    //                 {!openListConsultation && !openListProvider && isEmpty(objectPatient) && (
    //                     <Grid className={classes.encounterQueueWrap} justifyContent="flex-end">
    //                         <EncounterQueue />
    //                     </Grid>
    //                 )}
    //                 <ConsultationList encounterQueueList={encounterQueueList} />
    //                 <ProviderList />
    //                 {!encounterRoom && <LoadingPage />}
    //             </Grid>
    //         )}
    //     </div>
    // )

    // return (
    //     <Grid className={classes.container} justifyContent="flex-start">
    //         <Grid alignItems="center" justifyContent="center" className={classes.emptyNoteWrap}>
    //             <VideoProvider>
    //                 <ChatProvider>
    //                     <VideoChat lastMessage={null} inProgressEncounter={inProgressEncounter} />
    //                 </ChatProvider>
    //             </VideoProvider>

    //             {params.info === TELE_HEALTH_CONSULTATION.LAB_ORDER && <LabOrder />}
    //             {params.info === TELE_HEALTH_CONSULTATION.HEALTH_RECORDS &&
    //                 inProgressEncounter?.patient?.id && (
    //                     // <HealthRecords nodeDetailData={nodeDetailData} isConsultant={!isEmpty(objectPatient)} />
    //                     <PatientDashboard patientId={inProgressEncounter?.patient?.id} />
    //                 )}
    //             {params.info === TELE_HEALTH_CONSULTATION.CLINICAL_NOTE &&
    //                 Boolean(nodeDetailData) && (
    //                     <ClinicalNoteProvider>
    //                         <ClinicalNotesWrapper
    //                             nodeDetailData={nodeDetailData}
    //                             isConsultant={!isEmpty(objectPatient)}
    //                         />
    //                     </ClinicalNoteProvider>
    //                 )}
    //             {!params.info && !Boolean(nodeDetailData) && (
    //                 <Grid container direction="column" justifyContent="center" alignItems="center">
    //                     <EmptyNoteIcon />
    //                 </Grid>
    //             )}
    //         </Grid>
    //         {!openListConsultation && !openListProvider && isEmpty(objectPatient) && (
    //             <Grid className={classes.encounterQueueWrap} justifyContent="flex-end">
    //                 <EncounterQueue />
    //             </Grid>
    //         )}
    //         <ConsultationList encounterQueueList={encounterQueueList} />
    //         <ProviderList />
    //         {!encounterRoom && <LoadingPage />}
    //     </Grid>
    // )

    // useEffect(() => {
    //     if (!mediaError) {
    //         getAudioAndVideoTracks().catch((error) => {
    //             console.log('Error acquiring local media:')
    //             console.dir(error)
    //             setMediaError(true)
    //         })
    //     }
    // }, [getAudioAndVideoTracks, mediaError])
    return (
        <Grid className={classes.container} justifyContent="flex-start">
            <Grid alignItems="center" justifyContent="center" className={classes.emptyNoteWrap}>
                {/* <VideoProvider>
                    <ChatProvider>
                        <VideoChat
                            lastMessage={null}
                            inProgressEncounter={inProgressEncounter}
                            onError={setChatError}
                        />
                    </ChatProvider>
                </VideoProvider> */}
                <VideoChat
                    lastMessage={null}
                    inProgressEncounter={inProgressEncounter}
                    onError={setChatError}
                />
            </Grid>
            {!openListConsultation && !openListProvider && isEmpty(objectPatient) && (
                <Grid className={classes.encounterQueueWrap} justifyContent="flex-end">
                    <EncounterQueue />
                </Grid>
            )}
            <ConsultationList encounterQueueList={encounterQueueList} />
            <ProviderList />
            {!encounterRoom && <LoadingPage mediaError={mediaError || chatError} />}
        </Grid>
    )
}

export default React.memo(EmptyNote)
