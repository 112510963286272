import React, { useEffect } from 'react'
import {
    Box,
    Typography,
    Grid,
    FormControlLabel,
    Radio,
    TextField,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import { SelectEl } from '../../../shared/elements'
import DataGridCustom from '../reusable/DataGridCustom'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import AntSwitch from '../../shared/Elements/AntSwitch'
import SubstanceAbuseTreatmentHistory from 'containers/PatientsSetting/view/SubstanceAbuseTreatmentHistory'
import { apiSubstanceAbuseTreatmentHistory } from 'services/Patient/apiSubstanceAbuseTreatmentHistory'
import { useNotification } from 'shared/elements/Notification'



const useStyles = makeStyles({
    headerTxt: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
    },
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 41,
        width: 350,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})

const countOptions = [
    { key: 1, value: 1 },
    { key: 2, value: 2 },
    { key: 3, value: 3 },
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
    { key: 9, value: 9 },
    { key: 10, value: 10 },
    { key: 11, value: 11 },
    { key: 12, value: 12 },
    { key: 13, value: 13 },
    { key: 14, value: 14 },
    { key: 15, value: 15 },
    { key: 16, value: 16 },
    { key: 17, value: 17 },
    { key: 18, value: 18 },
    { key: 19, value: 19 },
    { key: 20, value: 20 },
    { key: 'more_20', value: 'more than 20' },
]

const SubstanceAbuseTreatmentHx = ({
    fieldName,
    master,
    values,
    onChange,
    patientId,
    encounter_id,
    macros,
    nonEditable = false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes',  disable: nonEditable },
        { label: 'No', value: 'No', disable: nonEditable },
    ]
    const classes = useStyles()
    const options = master?.substanceTreatmentMasters || []

    const notification = useNotification()

    const defaultCustomOption = {
        name: '',
        is_used: false,
        how_many: '',
        firstAge: '',
        lastAge: '',
        information: '',
        isBelongMaster: false,
        addNew: true,
    }
    const defaultList = options.map((item, index) => ({
        id: index,
        name: item.name,
        is_used: false,
        how_many: '',
        firstAge: '',
        lastAge: '',
        information: '',
        isBelongMaster: true,
    }))

    const handleAddMore = (action, index) => {
        const val = values['substance_opts'] || []
        if (action === 'add') {
            const updatedValue = [...val, defaultCustomOption]
            onChange(`${fieldName}.substance_opts`, updatedValue)
        } else {
            const updatedValue = val.find((v, i) => i === index)
            if (updatedValue.isBelongMaster) {
                onChange(
                    `${fieldName}.substance_opts`,
                    val.map((v, i) =>
                        i === index
                            ? {
                                  id: index,
                                  name: v.name,
                                  is_used: false,
                                  how_opts: [],
                                  firstAge: '',
                                  lastAge: '',
                                  amountPerDay: '',
                                  daysPerMonth: '',
                                  isBelongMaster: true,
                              }
                            : v,
                    ),
                )
            } else {
                onChange(
                    `${fieldName}.substance_opts`,
                    val.filter((v, i) => i !== index),
                )
            }
        }
    }

    const RenderAddOrRemove = ({ index }) => {
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    variant="outlined"
                    onClick={() => setAlertDeleteOpen(true)}
                    // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const mapValues = (values = []) => {
        return values.map((v, index) => {
            const itemIndex = defaultList.find((_, i) => i === index) || []
            return {
                ...itemIndex,
                ...v,
                id: index,
            }
        })
    }

    const handleChange = (e, keyName, id) => {
        const { name, value } = e.target
        const val = values[name] || defaultList
        const updatedValue = val?.map((v, index) =>
            index === id
                ? {
                      ...v,
                      [keyName]: value,
                  }
                : v,
        )
        onChange(`${fieldName}.${name}`, updatedValue)
    }
    const handleChangeSwitch = (e, keyName, id) => {
        const { name, checked } = e.target
        const val = values[name] || defaultList
        const updatedValue = val?.map((v, index) =>
            index === id ? { ...v, [keyName]: checked } : v,
        )
        onChange(`${fieldName}.${name}`, updatedValue)
    }

    console.log('values', values)

    const getSubstanceAbuseTreatmentHistoryList = async () => {
        try {
            const resp = await apiSubstanceAbuseTreatmentHistory.getAllbyPtientId(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data.data) {
                onChange(`${fieldName}.subtance_treat`, resp.data.data)
            }
        } catch (ex) {
            notification('Cannot get list Substance Abuse Treatment History', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.received_treat) {
            getSubstanceAbuseTreatmentHistoryList()
        }
    }, [patientId, values.received_treat])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                    width: '50%',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['received_treat']}
                </Typography>
                <RadioOptions
                    name={`${fieldName}.received_treat`}
                    options={radioOptions}
                    value={values['received_treat'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value === 'Yes')
                        if (value === 'No') {
                            onChange(`${fieldName}.subtance_treat`, [])
                            onChange(`${fieldName}.others`, [])
                        }
                        onChange(`${fieldName}.narrative`, '')
                    }}
                />
            </Box>
            {values['received_treat'] && (
                <SubstanceAbuseTreatmentHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getSubstanceAbuseTreatmentHistoryList}
                    nonEditable={nonEditable}
                />
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: options,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SubstanceAbuseTreatmentHx
