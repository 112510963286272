import {
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import React from 'react'
import NotificationPreferences from '../../../../containers/NotificationPreferences'
import { patientProfileFields } from '../../../../constants/patientProfileForm'

const Notification = ({ preferences }) => {
    const customPatientProfileFields =
        patientProfileFields()
    const notificationFieldItems =
        customPatientProfileFields.filter(
            (item) => item.sectionTitle === 'Notifications',
        )
    const { fieldItems } = notificationFieldItems[0]

    return (
        <Grid container>
            {' '}
            {fieldItems.map((item, index) => {
                return (
                    <Grid key={index} item md={2}>
                        {/* item xs={item.gridSize} lg={4} */}
                        <NotificationPreferences
                            label={item.label}
                            name={item.name}
                            value={preferences[item.key]}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default Notification
