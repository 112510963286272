import { Button, Dialog, DialogActions, DialogProps, DialogTitle, makeStyles, Typography } from "@material-ui/core"
import React from "react"

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      width: 600,
      alignItems: 'center',
      borderRadius: 10,
      padding: '16px 0',
    },
  },
  header: {
    color: '#303E4E',
    // fontWeight: 500,
    lineHeight: '32px',
    textAlign: 'center',
    fontSize: 22,
    padding: 8,
  },
  btn_cancel: {
    background: '#303E4E',
    width: '230px',
    fontSize: 15,
    borderRadius: 8,
    fontWeight: 'normal',
  }
})

interface Props {
  onOk?: () => void
}

export const InaccessibleConfirm = (props: Props & DialogProps) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableEnforceFocus
      {...props}>

      <DialogTitle>
        <Typography
          className={classes.header}>
          User can only access this site
          with a Provider Account!
        </Typography>
      </DialogTitle>

      <DialogActions>
        <Button
          onClick={props.onOk}
          variant="contained"
          className={classes.btn_cancel}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}