import { useState, useEffect } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { getLabCHCLink } from 'services/Patient/patient'
import LoadingPage from 'utilities/loading-page'
import { InaccessibleConfirm } from 'containers/ERX/InaccessibleConfirm'
import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
    },

    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
    header: {
        fontSize: '24px',
        color: '#303E4E',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 18,
    },
}))

export default function CHC({ open, handleClose, description, patientId, isLoadingButton, handleSuccess, from = '' }) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [chcLink, setCHCLink] = useState('')
    const [chcLinkError, setCHCLinkError] = useState(null)

    const fetchErxSsoLink = async () => {
        setLoading(true)
        await getLabCHCLink(patientId)
            .then((res) => {
                res?.data && setCHCLink(res?.data)
            })
            .catch((err) => {
                setCHCLinkError(err.data?.message)
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchErxSsoLink()
    }, [patientId, open])

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1100px',
                },
            }}
            fullWidth={true}
            onClose={handleClose}
            className={classes['root']}>
            <DialogContent>
                {description &&
                    from === "clinicalNote"
                    ?
                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                        <Typography className={classes.header} style={{ fontSize: '16px', fontWeight: '500', }}>Order Lab</Typography>
                        <IconButton style={{ marginBottom: 18 }} onClick={handleClose}>
                            <CloseIcon style={{ color: "#303E4E" }} />
                        </IconButton>
                    </div>
                    :
                    <Typography className={classes['header']}>CHC</Typography>}
                {loading ? (
                    <LoadingPage />
                ) : chcLink ? (
                    <iframe
                        src={chcLink}
                        title="description"
                        style={{
                            width: '100%',
                            height: '100%',
                        }}></iframe>
                ) : (
                    <Typography>
                        User can only access this site with a CHC Provider Account information!
                    </Typography>
                )}
            </DialogContent>

            <DialogActions>
                {from !== "clinicalNote" && <LoadingButton
                    loading={isLoadingButton}
                    loadingPosition="start"
                    startIcon={<></>}
                    onClick={handleSuccess}
                    disabled={isLoadingButton}
                    autoFocus
                    variant="contained"
                    className={classes['btn_delete']}>
                    OK
                </LoadingButton>}
            </DialogActions>
            <InaccessibleConfirm
                open={Boolean(chcLinkError)}
                onOk={() => {
                    handleClose()
                    setCHCLinkError(null)
                }}
            />
        </Dialog>
    )
}
