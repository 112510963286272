import { Box, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import InpationPsychiatricHistory from 'containers/PatientsSetting/view/InpationPsychiatricHistory'
import React, { useEffect } from 'react'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { SelectEl } from '../../../shared/elements'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import AntSwitch from '../../shared/Elements/AntSwitch'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import DataGridCustom from '../reusable/DataGridCustom'
import RadioOptions from '../reusable/RadioOption'
import { useNotification } from 'shared/elements/Notification'
import { inpatientPsychiatricHistoryApis } from 'services/Patient/inpatientPsychiatricHistoryApis'

const useStyles = makeStyles({
    headerTxt: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
    },
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 40,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})



const ImpatientPsychiatricHistory = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    patientId,
    encounter_id,
    nonEditable= false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable  },
        { label: 'No', value: 'No', disable: nonEditable  },
    ]
    const notification = useNotification()
    const classes = useStyles()
    const defaultInpatientOptions = {
        id: 0,
        hospital: '',
        treatment_voluntary: '',
        reasons: '',
        age: '',
        treatment_outcome: '',
        comment: '',
    }

    const handleAddMore = (action, index) => {
        const val = values['inpatient_psychiatric_opt'] || []
        if (action === 'add') {
            const updatedValue = [...val, defaultInpatientOptions]
            onChange(`${fieldName}.inpatient_psychiatric_opt`, updatedValue)
        } else {
            const updatedValue = val.filter((v, i) => i !== index)
            onChange(
                `${fieldName}.inpatient_psychiatric_opt`,
                updatedValue.length > 0 ? updatedValue : [defaultInpatientOptions],
            )
        }
    }

    const mapValues = (values = []) => {
        return values.map((v, index) => ({
            ...v,
            id: index,
        }))
    }

    const getOptions = (master = [], key) => {
        return master.map((im) => ({
            value: im[key],
            key: im._id,
        }))
    }

    const reasonOptions = getOptions(master?.primaryReasonForHospitalizationMasters, 'name')
    const treatmentOutcome = getOptions(master?.therapeuticEffect, 'name')

    const isEmptyRow = (row = []) => {
        return !(row.hospital || row.treatment_outcome || row.reasons || row.comment || row.age)
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        // const { inpatient_psychiatric_opt } = values
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box sx={{ width: 68, display: 'flex' }}>
                <IconButton
                    variant="outlined"
                    onClick={() => setAlertDeleteOpen(true)}
                    // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const handleChange = (e, keyName, id) => {
        const { name, value } = e.target

        const val = values[name].length !== 0 ? values[name] : [defaultInpatientOptions]

        const updatedValue = val?.map((v, index) => (index === id ? { ...v, [keyName]: value } : v))
        onChange(`${fieldName}.${name}`, updatedValue)
    }
    const handleChangeRadio = (e, keyName, id) => {
        const { name, checked } = e.target
        const val = values[name] || [defaultInpatientOptions]
        const updatedValue = val?.map((v, index) =>
            index === id ? { ...v, [keyName]: checked } : v,
        )
        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const getInpatientPsychiatricHistory = async () => {
        try {
            const resp = await inpatientPsychiatricHistoryApis.getAll(patientId, {
                page: 1,
                size: 100000,
            })
            onChange(`${fieldName}.inpatient_psychiatric_opt`, resp.data)
        } catch (ex) {
            notification('Cannot get list Inpatient Psychiatric', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.have_inpatient_psyc_treat) {
            getInpatientPsychiatricHistory()
        }
    }, [patientId, values.have_inpatient_psyc_treat])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['have_inpatient_psyc_treat']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.have_inpatient_psyc_treat`}
                        options={radioOptions}
                        value={
                            values?.have_inpatient_psyc_treat === undefined
                                ? ''
                                : values['have_inpatient_psyc_treat']
                                ? 'Yes'
                                : 'No'
                        }
                        onChange={(e) => {
                            const { name, value } = e.target

                            onChange(name, value === 'Yes')
                            onChange(`${fieldName}.narrative`, '')
                            if (value !== 'Yes') {
                                onChange(`${fieldName}.inpatient_psychiatric_opt`, [])
                            }
                        }}
                    />
                </Box>
            </Box>
            {values['have_inpatient_psyc_treat'] && (
                <InpationPsychiatricHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getInpatientPsychiatricHistory}
                    nonEditable={nonEditable}
                />
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default ImpatientPsychiatricHistory
