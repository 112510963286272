import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    mainMenuName:{
        color: '#424242 !important',
        fontFamily: 'Rubik !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        paddingBottom: '15px !important'
    },
    subtabDiv: {
        border: '1px solid #EAECF0 !important',
        borderRadius: '5px !important',
        padding: '3px !important',
        backgroundColor: '#F8F9FB !important',
        marginTop: '1px !important',
    },
    form_list_container_new: {
        marginTop: '10px !important',
        border: '1px solid #EAECF0 !important',
        borderRadius: '5px !important',
        backgroundColor: '#F8F9FB !important',
    },
    wrap_data_grid_new: {
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '16px',
                    fontFamily: "Rubik",
                    fontStyle: "normal",
                    lineHeight: "normal",
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 184px)',
            paddingLeft: "10px",
            paddingRight: "4px"
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
            // marginBottom: '35px',
            paddingLeft: '10px',
        },
        '& .MuiDataGrid-row': {
            background: '#FFF',
            margin: '4px 0',
            width: '98% !important',
            borderRadius: '5px',
            border: "1px solid #E9ECF1"
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
}))
