import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Theme from '../src/styles/themes/theme'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react"
import { SnackbarProvider } from 'notistack'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import AppStateProvider from './components/Consultation/state'
import { AppContainer } from './hoc'
import './index.scss'
import store from './store'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import LoadingPage from 'utilities/loading-page'
import { ModalProvider } from 'context/ModalContext'
import GlobalModal from 'shared/elements/Modal/GlobalModal'
Amplify.configure(awsExports)

const isProduction = process.env.REACT_APP_ENV === 'prod'
const sentryEnvironment = window.location.hostname === 'localhost' ? 'local' : process.env.REACT_APP_TEST_ENV

Sentry.init({
    dsn: "https://34df9bc44df4b89852d555cbe5fa5141@o4507780380033024.ingest.us.sentry.io/4507786597040128",
    tracesSampleRate: isProduction ? 0.1 : 1.0, 
    replaysOnErrorSampleRate: isProduction ? 0.1 : 1.0,
    environment: sentryEnvironment,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
});

// Create a client
const queryClient = new QueryClient()
console.log(process.env.REACT_APP_BASE_URL)
//Manual Middle Ware code

const App = (
    <QueryClientProvider client={queryClient}>
        <ModalProvider>
            <Provider store={store}>
                <AppStateProvider>
                    <MuiThemeProvider theme={Theme}>
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}>
                            <BrowserRouter>
                                <Suspense fallback={<LoadingPage />}>
                                    <AppContainer></AppContainer>
                                    <GlobalModal />
                                </Suspense>
                            </BrowserRouter>
                        </SnackbarProvider>
                    </MuiThemeProvider>
                </AppStateProvider>
            </Provider>
        </ModalProvider>

        {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
)

ReactDOM.render(App, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
