import React from 'react'

//components
import loginAnotherWay from '../containers/Login/LoginAnotherWay'
import StaffLogin from '../containers/Login/StaffLogin'
import LoginComponent from '../containers/Login/Login'
import RegisterComponent from '../containers/SignUp/Register'
import PatientDetail from '../containers/SignUp/PatientDetail'
import StaffLoginProvider from '../containers/Login/StaffLoginProvider'

import { authNotRequired, authRequired } from '../utilities/guards'
import Routes from '../constants/routes'

import { Redirect } from 'react-router-dom'
import MainIndex from '../components/Consultation/MainIndex'
import VerifyProfile from '../containers/SignUp/VerifyProfile'
import ResetPasswordForm from '../containers/SignUp/ResetPasswordForm'
import ChangePasswordForm from 'containers/SignUp/ChangePasswordForm'
import MFAForm from 'containers/SignUp/MFAForm'
import VerifyEmail from 'containers/SignUp/VerifyEmail'

const meta = {
    auth: false,
    guards: [authNotRequired],
}

const guestRoutes = [
    {
        path: [Routes.HOME, Routes.LOGIN.BASIC],
        component: StaffLoginProvider,
        meta,
    },
    // {
    //     path: [Routes.LOGIN.STAFF],
    //     component: StaffLogin,
    //     meta,
    // },
    {
        path: [Routes.LOGIN.STAFF],
        component: StaffLoginProvider,
        meta,
    },
    {
        path: [Routes.VERIFY_PROFILE],
        component: VerifyProfile,
        meta,
    },
    {
        path: [Routes.FORGET_PASSWORD],
        component: ResetPasswordForm,
        meta,
    },
    {
        path: [Routes.CHANGE_PASSWORD],
        component: ChangePasswordForm,
        meta,
    },
    {
        path: [Routes.MFA_SIGNIN],
        component: MFAForm,
        meta,
    },
    {
        path: [Routes.VERIFY_EMAIL],
        component: VerifyEmail,
        meta,
    },
    {
        path: Routes.PATIENT_DETAIL,
        component: PatientDetail,
        meta: { auth: true, guards: [authRequired] },
    },
    {
        path: [Routes.REGISTER, Routes.SIGNUP],
        component: RegisterComponent,
        meta,
    },
    {
        path: Routes.LOGIN.ALTERNATE,
        component: loginAnotherWay,
        meta,
    },
    {
        path: [Routes.CONSULTATION.URL, Routes.CONSULTATION.VIDEO],
        component: MainIndex,
        meta: { ...meta, isVideo: true },
    },
    {
        path: '*',
        // eslint-disable-next-line react/display-name
        component: () => <Redirect to={Routes.HOME}></Redirect>,
        meta,
    },
]

export default guestRoutes
