import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import ClinicalNotesWrapper from './ClinicalNotesWrapper'
import ClinicalNotesForView from './ClinicalNotesForView'
import { useParams } from 'react-router-dom'
import { ClinicalNoteProvider } from './context/ClinicalNoteContext'
import { checkTabChange } from 'store/actions/clinicalNotes'

const ClinicalNotes = () => {
    const objectPatient = useSelector((state) => state.provider.objectEncouterStart)
    const params = useParams()
    const dispatch = useDispatch()
    document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState == "visible") {
        //   console.log("tab is active")
            dispatch(checkTabChange(true))
        } else {
        //   console.log("tab is inactive")
            dispatch(checkTabChange(false))
        }
      });
    return (
        <ClinicalNoteProvider>
            {/* <ClinicalNotesWrapper isConsultant={!isEmpty(objectPatient)} /> */}
            {params?.id ?
                <ClinicalNotesForView isConsultant={!isEmpty(objectPatient)} />
                : <ClinicalNotesWrapper isConsultant={!isEmpty(objectPatient)} />
            }
        </ClinicalNoteProvider>
    )
}

export default ClinicalNotes
