import React from 'react'
import { Grid } from '@material-ui/core'
import { Box } from '@mui/material'

import { usePatientDashboardStyles } from '../style'
import { SubstanceAbuseHistory } from './history/SubstanceAbuseHistory'
import { SubstanceAbuseTreatmentHistory } from './history/SubstanceAbuseTreatmentHistory'
import { InpatientPsychiatricHistory } from './history/InpatientPsychiatricHistory'
import { OutpatientPsychiatricHistory } from './history/OutpatientPsychiatricHistory'
import { SurgicalHistory } from './history/SurgicalHistory'
import { FamilyPsychiatricHistory } from './history/FamilyPsychiatricHistory'
import { PsychiatricMedicationHistory } from './history/PsychiatricMedicationHistory'
import { SocialHistory } from './history/SocialHistory'
import { PregnancyHistory } from './history/PregnancyHistory'

export const HistoryCards = () => {
    const classes = usePatientDashboardStyles()

    return (
        <Grid
            xs={12}
            container
            spacing={2}
            // wrap="nowrap"
            className={classes.container_tab}
            style={{
                // marginBottom: 4,
                // height: '50%',
                height: 'calc(100% - 10px)',
                marginTop: 10,
            }}>
            {/* Substance Abuse History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <SubstanceAbuseHistory />
                </Box>
            </Grid>

            {/* Substance Abuse Treatment History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <SubstanceAbuseTreatmentHistory />
                </Box>
            </Grid>

            {/* Inpatient Psychiatric History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <InpatientPsychiatricHistory />
                </Box>
            </Grid>

            {/* Outpatient Psychiatric History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <OutpatientPsychiatricHistory />
                </Box>
            </Grid>

            {/* Surgical History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <SurgicalHistory />
                </Box>
            </Grid>

            {/* Family Psychiatric History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <FamilyPsychiatricHistory />
                </Box>
            </Grid>

            {/* Psychiatric Medication History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <PsychiatricMedicationHistory />
                </Box>
            </Grid>

            {/* Social History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <SocialHistory />
                </Box>
            </Grid>

            {/* Pregnancy History */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <PregnancyHistory />
                </Box>
            </Grid>
        </Grid>
    )
}
