import React from 'react'
import clsx from 'clsx'
import Participant from '../Participant/Participant'
import {
    makeStyles,
    createStyles,
} from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant'
import useParticipants from '../../hooks/useParticipants/useParticipants'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant'
import { styled, useMediaQuery } from '@material-ui/core'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'absolute',
             top: 0,
             right: 0,
            overflowY: 'auto',

            background: 'rgb(79, 83, 85)',
            gridArea: '1 / 2 / 1 / 3',
            zIndex: 5,
            // [theme.breakpoints.down('sm')]: {
            //     gridArea: '2 / 1 / 3 / 3',
            //     overflowY: 'initial',
            //     overflowX: 'auto',
            //     display: 'flex',
            // },
        },
        transparentBackground: {
            background: 'transparent',

            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                bottom: '14%',
            },
        },
        scrollContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            // paddingRight: '10%',
            // [theme.breakpoints.down('sm')]: {
            //   paddingRight: '50%',
            // }
        },
        innerScrollContainer: {
            width: `calc(${theme.sidebarWidth}px - 15em)`,
            padding: '2em 0',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                padding: `${theme.sidebarMobilePadding}px`,
                display: 'flex',
            },
        },
    }),
)

export default function ParticipantList() {
    const classes = useStyles()
    const { room } = useVideoContext()
    const localParticipant = room!.localParticipant
    const participants = useParticipants()
    const [selectedParticipant, setSelectedParticipant] =
        useSelectedParticipant()
    const screenShareParticipant =
        useScreenShareParticipant()
    const mainParticipant = useMainParticipant()
    const isRemoteParticipantScreenSharing =
        screenShareParticipant &&
        screenShareParticipant !== localParticipant
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    )
    const getPaddingRight = isMobile
        ? participants.length * 10 + 20
        : 10

    if (participants.length === 0) return null // Don't render this component if there are no remote participants.

    return (
        <aside
            className={clsx(classes.container, {
                [classes.transparentBackground]:
                    !isRemoteParticipantScreenSharing,
            })}>
            <div
            id="kjl"
                className={classes.scrollContainer}
                style={{
                    paddingRight: `${getPaddingRight}%`,
                }}>
                <div
                    className={
                        classes.innerScrollContainer
                    }>
                    <Participant
                        participant={localParticipant}
                        isLocalParticipant={true}
                    />
                    {participants.map((participant) => {
                        const isSelected =
                            participant ===
                            selectedParticipant
                        const hideParticipant =
                            participant ===
                                mainParticipant &&
                            participant !==
                                screenShareParticipant &&
                            !isSelected

                        return (
                            <Participant
                                key={participant.sid}
                                participant={participant}
                                isSelected={
                                    participant ===
                                    selectedParticipant
                                }
                                onClick={() =>
                                    setSelectedParticipant(
                                        participant,
                                    )
                                }
                                hideParticipant={
                                    hideParticipant
                                }
                            />
                        )
                    })}
                </div>
            </div>
        </aside>
    )
}
