export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm:ss'

export const VIEW = {
    MONTH: 'month',
    WEEK: 'week',
    DAY: 'day',
    YEAR: 'year',
}

export const EVENT_TYPE = {
    AVAILABILITY: 'availability',
    TIMEOFF: 'timeoff',
    BUSY_SLOT: 'busy_slot',
    FREE_SLOT: 'free_slot',
}

export const VIEWS = [
    {
        key: VIEW.DAY,
        value: VIEW.DAY,
    },
    {
        key: VIEW.WEEK,
        value: VIEW.WEEK,
    },
    {
        key: VIEW.MONTH,
        value: VIEW.MONTH,
    },
]

export const REPEAT_INTERVALS = [
    {
        key: VIEW.WEEK,
        value: VIEW.WEEK,
    },
    {
        key: VIEW.MONTH,
        value: VIEW.MONTH,
    },
]

export const TIMEOFF_STATUS = {
    PENDING: 'pending',
    REJECTED: 'rejected',
    ACCEPTED: 'approved',
}

export const SLOT_STATUS = {
    BUSY: 'busy',
    FREE: 'free',
    BUSY_TENTATIVE: 'busy-tentative',
    BUSY_UNAVAILABLE: 'busy-unavailable',
}

export const SLOT_TYPE = {
    OPEN: 'open',
    EXCLUSIVE: 'exclusive',
    SHARED: 'shared',
}

export const APPOINTMENT_TYPE = {
    TELE_HEALTH: 'tele-consultant',
    IN_CLINIC: 'in-clinic',
}

export const TIMEZONES = [
    {
        abbr: 'AST',
        value: 'Atlantic Standard Time (AST)',
        key: 'America/Montserrat',
    },
    {
        abbr: 'EST',
        value: 'Eastern Standard Time (EST)',
        key: 'America/New_York',
    },
    {
        abbr: 'CST',
        value: 'Central Standard Time (CST)',
        key: 'America/Chicago',
    },
    {
        abbr: 'MST',
        value: 'Mountain Standard Time (MST)',
        key: 'America/Phoenix',
    },
    {
        abbr: 'PST',
        value: 'Pacific Standard Time (PST)',
        key: 'America/Los_Angeles',
    },
    {
        abbr: 'SST',
        value: 'Samoa standard time (UTC-11)',
        key: 'Pacific/Pago_Pago',
    },
    {
        abbr: 'HST',
        value: 'Hawaii-Aleutian Standard Time (HST)',
        key: 'Pacific/Johnston',
    },
    {
        abbr: 'CHST',
        value: 'Chamorro Standard Time (CHST)',
        key: 'Pacific/Guam',
    },
]

export const SLOT_TYPES = [
    {
        key: SLOT_TYPE.EXCLUSIVE,
        value: SLOT_TYPE.EXCLUSIVE,
    },
    {
        value: SLOT_TYPE.SHARED,
        key: SLOT_TYPE.SHARED,
    },
    {
        value: SLOT_TYPE.OPEN,
        key: SLOT_TYPE.OPEN,
    },
]

export const ENCOUNTER_STATUS = {
    NOT_START: 'NOT_START',
    PRE_EXAM_WAITING: 'PRE_EXAM_WAITING',
    EXAM_WAITING: 'EXAM_WAITING',
    POST_EXAM_WAITING: 'POST_EXAM_WAITING',
    PRE_EXAM: 'PRE_EXAM',
    EXAM: 'EXAM',
    POST_EXAM: 'POST_EXAM',
    COMPLETED: 'COMPLETED',
    NO_SHOW: 'NO_SHOW',
}

export const PATIENT_STATUS = {
    IN_WAITING_ROOM: 'IN_WAITING_ROOM',
    DISCONNECT_WAITING_ROOM: 'DISCONNECT_WAITING_ROOM',
    DISCONNECT_EXAM: 'DISCONNECT_EXAM',
    PRE_EXAM: 'PRE_EXAM',
    EXAM: 'EXAM',
    POST_EXAM: 'POST_EXAM',
    COMPLETED: 'COMPLETED',
    NO_SHOW: 'NO_SHOW',
    NOT_START: 'NOT_START',
}

export const ENCOUNTER_QUEUE_PATIENT_STATUS = {
    ARRIVED: 'ARRIVED',
    IN_SESSION: 'IN_SESSION',
    NO_SHOW: 'NO_SHOW',
    NOT_START: 'NOT_START',
    COMPLETED: 'COMPLETED',
}

export const ENCOUNTER_QUEUE_PATIENT_STATUS_LIST = [
    {
        label: '------',
        value: ENCOUNTER_QUEUE_PATIENT_STATUS.NOT_START,
        key: 1,
    },
    {
        label: 'Arrived',
        value: ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED,
        key: 2,
    },
    {
        label: 'In Session',
        value: ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION,
        key: 3,
    },
    {
        label: 'No Show',
        value: ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW,
        key: 4,
    },
    // {
    //     label: 'Complete',
    //     value: ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED,
    //     key: 5,
    // },
]

export const statusNames = ['booked', 'cancelled', 'noshow', 'entered-in-error' 
// 'fulfilled'
]

export const DRAFT_APPOINTMENT_TOOLTIP = 'Appointment will be available soon...'