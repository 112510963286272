import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Autocomplete, createFilterOptions } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { debounce, isObject } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { allApiLabOrder, getOrderingProvider } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import SearchIcon from '../../assests/icons/search_icon.svg'

const filterData = createFilterOptions()

const renderTextField = ({ name, value, placeholder, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchCHCProviderInput = (props) => {
    const {
        disabled,
        handleAddRow,
        isProviderOnly,
        helperText,
        error,
        placeholder,
        getProviderInfo,
        handleSearch,
        hideSearchIcon,
        allowClear = false,
    } = props

    const providerId = props.value
    const notification = useNotification()

    const [value, setValue] = useState()
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(async () => {
        try {
            const dta = await getOrderingProvider()
            const res = await await allApiLabOrder.getAll({
                page: 1,
                size: 10,
            })
            console.log('data', res)
            const generatedProvider =
                dta.data?.map((item) => ({
                    key: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                    value: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                    id: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                    label: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                    provider_details: item,
                })) || []
            setOptions(generatedProvider)
            setOptions(generatedProvider)
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
        setValue(props.value)
    }, [])

    const search = useRef(
        debounce(async (val) => {
            try {
                setLoading(true)
                setOptions((prev) => {
                    const listResult = options.filter((item) => {
                        return item.value.toUpperCase().indexOf(val.toUpperCase()) >= 0
                    })
                    return listResult
                })
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }, 800),
    )

    useEffect(() => {
        let active = true
        if (!open) return
        ;(async () => {
            setLoading(true)

            // if (active) {
            //     await search.current(inputValue)
            // }
            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [open, value, inputValue])
    console.log(options)
    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                selectOnFocus
                clearIcon={true}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                inputValue={inputValue}
                value={value}
                open={open}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <td>
                                <span className="hightlight">{option.value}</span>
                                {' - '}
                                <span
                                    className="hightlight"
                                    style={{
                                        color: 'rgb(85, 113, 198)',
                                    }}>
                                    {option.id}
                                </span>
                            </td>
                        </li>
                    )
                }}
                // filterOptions={(optionDatas, params) => {
                //     const filtered = filterData(
                //         optionDatas,
                //         params,
                //     )
                //     filtered.push(...optionDatas)
                //     return optionDatas
                // }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    setOptions(isObject(newValue) ? [newValue, ...options] : options)
                    setOpen(false)
                    handleAddRow(newValue)
                    setValue(newValue?.id)
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return
                    }
                    setInputValue(newInputValue)
                    if (!newInputValue) getProviderInfo?.()
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: placeholder ? placeholder : 'Search...',
                        props: {
                            ...params,
                            helperText,
                            error,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: hideSearchIcon || (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : (
                                            <ExpandMore
                                                style={{
                                                    fontSize: 20,
                                                    color: '#9CA9B8',
                                                    paddingRight: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setOpen(!open)}
                                            />
                                        )}
                                    </React.Fragment>
                                ),
                            },
                        },
                    })
                }
            />
        </Box>
    )
}

export default React.memo(SearchCHCProviderInput)
