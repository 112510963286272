import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import EmptyList from './EmptyList';
// import EncounterList from './EncounterList';
import EncounterList from '../../CalendarScheduler/EncounterStack/EncounterList'
import ConsultantStatusDialog from '../../../components/shared/Elements/ConsultantStatusDialog';
import moment from 'moment'

const useStyles = makeStyles({
  encounterQueueWrapper: {
    width: '100%',
    overflow: 'hidden',
    // height: 'calc(100vh - 108px)',
    position: 'relative'
  },
  encounterHeader: {
    padding: '20px 10px 10px 20px',
    fontSize: 16,
    color: '#303E4E',
    fontWeight: 500
  },
  encounterSubHeader: {
    padding: '5px 5px 5px 20px',
    fontSize: 12,
    color: '#303E4E',
    fontWeight: 350,
  },
});
interface Props {

}

const EncounterQueue = (props: Props) => {
  const encounterQueueList = useSelector(
    (state) => state.provider.encounterQueueList,
  );

  const [openEndDialog, setOpenEndDialog] =
    useState(false)

  const classes = useStyles();

  return (
    <Grid alignItems="center" justifyContent="center" className={classes.encounterQueueWrapper}>
      <div className={classes.encounterHeader}>
        Encounter Queue ({encounterQueueList?.length || 0})
      </div>
      <div className={classes.encounterSubHeader}>{moment
        .tz()
        .format('MMMM DD, YYYY')}
      </div>
      <div>
        {encounterQueueList?.length > 0 ? (
          <EncounterList encounterQueueList={encounterQueueList} />
        ) : (
          <EmptyList />
        )}
      </div>
      <ConsultantStatusDialog
        open={openEndDialog}
        handleClose={() => {
          setOpenEndDialog(false)
        }}
        handleConfirm={() => {

        }}
      />
    </Grid>
  )
}

export default EncounterQueue;