import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Grid, Button, Popover, IconButton,FormLabel, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import copyNew from '../../../assests/icons/copyNew.svg'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
// import Select from 'shared/elements/FormItem/Select';

import { TextBox } from 'shared/elements/FormItem/TextBox'
// import TrashIcon from 'assests/icons/TrashIcon';
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { SelectEl } from 'shared/elements';
import { apiFamilyPsychiatric } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import Loading from 'shared/elements/Loading';
import ErrorIcon from 'assests/icons/error-icon.svg'
import { LoadingButton } from '@mui/lab';
import PreviousCN from '../reusable/PreviousCN';
import AddIcon from 'assests/icons/Add.svg';
import DocumentIcon from 'assests/icons/Document.svg'
import DeleteIcon from 'assests/icons/Delete.svg';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectCondition from './MultiSelectCondition';

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        marginLeft: 12,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    itemCard: {
        width: 400,
        height: 500,
    },
    gridContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: '730px',
    },
    gridSurgicalContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: 360,
    },
    gridItem: {
        width: '33.333%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    gridItemShow: {
        width: '50%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    // divider: {
    //     background: '#B0BCE4',
    // },
    subText: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },


    first_box:{
        // borderRadius: '5px',
        // border: '1px solid #EAECF0',
        // background: '#F8F9FB',
        // height: "814px",
        // overflow: 'auto',
        // padding: '10px',
        marginBottom: '30px',
        marginTop: '30px'
    },
    second_box:{
        borderRadius: '5px',
        border: '1px solid #EAECF0',
    },
    first_step_box:{
        display:'flex',
        justifyContent:"space-between",
        alignItems:"cetner",
        borderBottom:'1px solid #EAECF0',
        padding:'10px', 
        background: '#F8F9FB'
    },
    copy_button:{
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        background: "#FFF !important",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "24px"
    },
    addIcon:{
        background: "#15A452 !important",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        fontSize: "12px",
        color:"#fff",
        fontWeight: "400",
        height: "24px"
    },
    narrative: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        lineHeight: '22px',
    },
    emptyBox:{
        background: '#F8F9FB',
        height:"400px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center'
    },
    emptyDocuments:{
        borderRadius: '50%',
        padding: '20px',
        border: '1px solid #667689'
    },
    familyHistoryEmptyText:{
        color: '#303E4E',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '22px',
    },
    chip:{
        borderRadius:'5px',
        margin:'10px',
        paddingBottom:'2px'
    },
    ChosenCondition:{
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '12px !important',
        fontStyle: 'normal',
        fontWeight: '400 !important',
        lineHeight: '16px',
        marginRight: '5px'
    },
    box_type:{
        padding: '10px',
    },
    filter_btn: {
        width: '170px !important',
        height: '28px',
        fontWeight: 'normal !important',
        borderRadius: '5px !important',
        background: '#15A452 !important',
        color: 'white !important',
        padding: '0px !important',
        textTransform: 'capitalize !important',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '101px',
        borderRadius: '5px',
        border: '1px solid #E4E2E2',
        background: '#FFF',
        padding: '6px',
        marginBottom: '3px',
        marginRight: '6px',
        justifyContent: 'space-between',
        width: '207px',
    },
    displayInbox:{
        display:"flex",
        alignItem: "center",
        justifyContent: "center"
    },
    header:{
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        color: "#303E4E"
    },
    DividerText: {
        color: "var(--Light-Foreground-Foreground-2, #424242)",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500 !important",
        lineHeight: "16px",
        margin: "9px 0 3px 0",
        textTransform:'none !important'
    }
})

const condition = [{value:"High blood pressure", key:"High blood pressure"},{value:"Alzheimer's disease",key:"Alzheimer's disease"}
 ,{value:"Parkinson's disease",key:"Parkinson's disease"},{value:"Diabetes",key:"Diabetes"},{value:"Obesity",key:"Obesity"},{value:"Coronary artery disease",key:"Coronary artery disease"},
 {value:"Stroke",key:"Stroke"},{value:"Epilepsy",key:"Epilepsy"},{value:"Migraine(s)",key:"Migraine(s)"},{value:"Sickle cell", key:"Sickle cell"},{value:"Bleeding disorders", key:"Bleeding disorders"},
 {value:"Lipid disorder(Cholesterol)", key:"Lipid dorder(cholesterol)"},
 {value:"Thyroid disorder",key:"Thyroid disorder"},{value:"Mental illness",key:"Mental illness"},{value:"Asthma",key:"Asthma"},{value:"Alcoholism",key:"Alcoholism"},{value:"Anemia",key:"Anemia"},{value:"Osteoporosis",key:"Osteoporosis"},
 {value:"Arthritis", key:"Arthritis"},{value:"Multiple sclerosis", key:"Multiple sclerosis"},{value:"ADHD",key:"ADHD"},{value:"ADD",key:"ADD"},{value:"Hypertension",key:"Hypertension"},{value:"TB",key:"TB"},{value:"COPD",key:"COPD"},
 {value:"Cancer",key:"Cancer"},
//  { value: 'Depression', key: 'Depression'},{ value: 'Panic disorder', key: 'Panic disorder'},
//  {value: 'Generalized anxiety disorde',key: 'Generalized anxiety disorder'},
//  { value: 'Social anxiety disorder', key: 'Social anxiety disorder'},
//  { value: 'Obsessive compulsive disorder',key: 'Obsessive compulsive disorder'},
//  { value: 'Post-traumatic stress disorder',key: 'Post-traumatic stress disorder'},
//  { value: 'Bipolar disorder', key: 'Bipolar disorder'},
//  { value: 'Eating disorder', key: 'Eating disorder'},
//  { value: 'Drug abuse', key: 'Drug abuse'},
//  { value: 'Personality disorder', key: 'Personality disorder'},
//  { value: 'Schizophrenia/Psychosis', key: 'Schizophrenia/Psychosis'},
//  { value: 'Psychiatric hospitalization', key: 'Psychiatric hospitalization'},
//  { value: 'Suicide attempt', key: 'Suicide attempt'},
//  { value: 'Suicide', key: 'Suicide'},
 { value: 'Other', key: 'Other'}]

 const FamilyMembers = [
    // @TODO:
    { key: 'MOTHER', value: 'Mother ' },
    { key: 'FATHER', value: 'Father  ' },
    { key: 'BROTHER', value: 'Brother ' },
    { key: 'SISTER', value: 'Sister ' },
    { key: 'GRANDMOTHER', value: 'Grandmother ' },
    { key: 'GRANDFATHER', value: 'Grandfather ' },
    { key: 'AUNT', value: 'Aunt ' },
    { key: 'UNCLE', value: 'Uncle ' },
    { key: 'COUSIN', value: 'Cousin ' },
    { key: 'SON', value: 'Son  ' },
    { key: 'DAUGHTER', value: 'Daughter ' },
]

const FamilyHistory = ({
    fieldName,
    values,
    onChange,
    patientId,
    nonEditable,
    encounter_id,
    lastNarrative,
    lastUpdateDate
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const validationSchema = yup.array().of(
        yup.object({
        id: yup.string(),
        family_member: yup.string().required('This field is required!'),
        psychiatric_condition: yup.array()
            .test(
                'len',
                'Condition  must have at least one item',
                val => val && val.length > 0
            ),
    }))
    const [initData, setInitData] = useState([])
    const [conditionList,setConditionList] = useState(condition)
    const [addMore, setAddmore]= useState(false)
    const [ dataLoading, setDataLoading] = useState(false)
    const [ editData, setEditData] = useState({})
    const [editId, setEditId] = useState(null)
    const [anchorEl,setAnchorEl] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [indexDelete, setIndexDelete] = useState(null)
    const [open, setOpen] = useState(false)
    const [copied,setCopied] = useState(false)
    const [optionOpen,setOptionOpen] = useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleDelete = async() =>{
        setLoading(true)
        if (deleteId) {
            try {
                const resp = await apiFamilyPsychiatric.delete(patientId, deleteId)
                if (resp.data?.Status === 'SUCCESSED') {
                    notification('Family Psychiatric History deleted successfully', 'success')
                }
                getFamilyPsychiatric(true)
            } catch (ex) {
                notification('Something went wrong', 'error')
            }
        }else if(formik.values.length){
            if(formik.values.length === 1){
                formik.resetForm()
                formik.setValues([])            }else if (formik.values.length > 1){
                formik.setValues(
                    formik.values.filter((_, idx) => idx !== indexDelete),
                )
            }
            notification('Family Psychiatric History deleted successfully', 'success')
        }
        setLoading(false)
        setIndexDelete(null)
        setDeleteId(null)
        handleClose()
    }
    const openEnd = Boolean(anchorEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined
    useEffect(() => {
        if (patientId) {
            getFamilyPsychiatric()
        }
    }, [patientId])
    const handleSubmit = async(value)=>{
        if(addMore){
            formik.setValues([
                ...formik.values,
                {
                    family_member: '',
                    psychiatric_condition: [],
                    encounter_id: "",
                    comment: '',
                },
            ])
            return;
        }
        
        const addData = value?.filter(item => !item?.id).map(item => ({
            ...item,
            encounter_id,
            psychiatric_condition: item?.psychiatric_condition.map(condition => condition.value)
            }));
        if(addData.length > 0){
            setDataLoading(true)
            const res = await apiFamilyPsychiatric.create(patientId, addData)
            getFamilyPsychiatric()
            if(res?.data?.Status === "SUCCESSED"){
                notification('Family Psychiatric History created successfully', 'success') 
            }else{
                notification(res?.data?.message || 'Internal Error', 'error')
            }
        }
        let finalEditdata = {}
        if(editData){
            finalEditdata = {
                ...editData,
                psychiatric_condition: editData?.psychiatric_condition.length > 0 ? editData?.psychiatric_condition.map(condition => condition.value) : []
            }
        }
        if(editId){
            setDataLoading(true)
            const rest = await apiFamilyPsychiatric.update(
                patientId,
                editId,
                finalEditdata,
            )
            getFamilyPsychiatric()
            if (rest.data.Status === 'SUCCESSED') {
                notification('Family Psychiatric History updated successfully', 'success')
            }else{
                notification('Internal Error', 'error')
            }
        }
    }
    const getFamilyPsychiatric = async (fromDelete=false) => {
        try {
            setDataLoading(true)
            const resp = await apiFamilyPsychiatric.getAll(patientId, {
                page: 1,
                limit: 100000,
            })
            const Data = resp.data.listFamilyMedicalHistory.data.length > 0 ? 
                            resp.data.listFamilyMedicalHistory.data.map((item)=>(
                                {
                                    member: item?.family_member,
                                    conditions: item?.psychiatric_condition,
                                    comment: item?.comment
                                }
                            ))
                            :
                            [
                                {
                                  member: "",
                                  conditions: [],
                                  comment: ""
                                }
                              ]
            
                onChange(`${fieldName}.members`, Data)
                const check =   resp?.data?.listFamilyMedicalHistory?.data.map((item, index) => ({
                  family_member: item?.family_member,
                  id: item?.id,
                  psychiatric_condition: item?.psychiatric_condition.map((item,index)=>{
                    let flag = false
                    conditionList.map((item1)=>{
                        if(item1.key === item){
                            flag= true
                        }
                      })
                      if(!flag){
                        return({
                            key:'otherComment',
                            value: item
                        })
                      }else{
                        return({
                            key: item,
                            value: item,
                        })
                      }
                     }),
                  encounter_id: item?.encounter_id,
                  comment: item?.comment
                }))
                if(check.length){
                    setInitData(check)
                }else if(!fromDelete){
                    setInitData([])
                }
                if(fromDelete && resp.data.listFamilyMedicalHistory.data.length === 0){
                    setInitData([{
                        family_member: '',
                        psychiatric_condition: [],
                        encounter_id: "",
                        comment:''
                    }])
                }
            setDataLoading(false)   
        } catch (ex) {
            notification('Cannot get list Family Medical History', 'error')
        }
    }
    
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })
    function myFunction() {
        const text = typeof lastNarrative === 'string' ? lastNarrative : null
        navigator.clipboard.writeText(text)
        if (text) {
            handleSelectMacro(text)
            setCopied(true)
        }

        handleCloseModal()
    }
    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const ModalOpen = () =>{
        setOpen(true)
    }
    const handleCloseModal = () =>{
        setOpen(false)
    }
   
    const clickOutsideHandler = (e) => {
        if (e.keyCode === 13 || e.key === 'Enter') {
            const taskUserPopperContainer = document.querySelector('.task-user-popper-containerTwo')
            if (taskUserPopperContainer !== null && !taskUserPopperContainer.contains(e.target) && !optionOpen) {
                setAddmore(false)
                formik.handleSubmit()
            }
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', clickOutsideHandler)
        return () => document.removeEventListener('keydown', clickOutsideHandler)
    })
    return (
        <Grid item className={classes.first_box}>
            <Loading visible={dataLoading} />
            <Grid
                item
                className={classes.second_box}>
                    <div className={classes.first_step_box}>
                        <Typography className={classes.narrative}>Family History</Typography>
                        <div style={{display:'flex',alignItems:'center', gap: '10px'}}>
                            <Button
                                startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                                className={classes.copy_button}
                                onClick={() => {
                                    if(!nonEditable && lastNarrative){
                                        ModalOpen()
                                    }
                                }}
                                >
                                {' '}
                                {copied ? "Copied" : "Copy"} {lastUpdateDate}
                            </Button>
                            <Button
                                startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                                className={classes.addIcon}
                                onClick={() => {
                                    setAddmore(true)
                                    formik.handleSubmit()
                                }}
                                >
                                Add new family condition
                            </Button>
                        </div>
                    </div>
                    {formik.values.length === 0
                    ?
                        <Box className={classes.emptyBox}>
                            <div>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <div className={classes.emptyDocuments}>
                                        <img src={DocumentIcon} alt=''/>
                                    </div>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <Typography className={classes.familyHistoryEmptyText}>Family history tab is empty</Typography>
                                    <Typography className={classes.familyHistoryEmptyText} style={{fontWeight:400, marginTop: '10px'}}>
                                        Add new family condition or copy from the previous note
                                    </Typography>
                                    <div style={{display:'flex',alignItems:'center', justifyContent:'center',gap: '10px', marginTop: '25px'}}>
                                        <Button
                                            startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                                            className={classes.copy_button}
                                            onClick={() => {
                                                if(!nonEditable && lastNarrative){
                                                    ModalOpen()
                                                }
                                            }}
                                            style={{fontWeight: 500}}
                                            >
                                            {' '}
                                            {copied ? "Copied" : "Copy"} {lastUpdateDate}
                                        </Button>
                                        <Button
                                            startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                                            className={classes.addIcon}
                                            onClick={() => {
                                                setAddmore(true)
                                                formik.handleSubmit()
                                            }}
                                            style={{fontWeight: 500}}
                                            >
                                            Add new family condition
                                        </Button>
                                    </div>
                                </div>
                            
                            </div>
                        </Box>
                    :
                        <Box className={classes.box_type}>
                            {formik.values.length > 0 && formik.values.map((item, index) => (
                                <Grid
                                    item
                                    style={{
                                        padding: '0px 10px 10px 10px'
                                    }}
                                    className="task-user-popper-containerTwo">
                                    <Grid container item alignItems="flex-start" spacing={2}>
                                        <Grid item sm={2}>
                                            <FormLabel component="p" className={classes.formLabel}>
                                                Family Member <span style={{ color: '#E81A1A' }}>*</span>
                                            </FormLabel>
                                            <SelectEl
                                                name="family_member"
                                                placeholder="Not chosen"
                                                value={item?.family_member}
                                                error={
                                                    (formik.touched || [])[index]?.family_member &&
                                                    Boolean((formik.errors || [])[index]?.family_member)
                                                }
                                                helperText={
                                                    (formik.touched || [])[index]?.family_member &&
                                                    (formik.errors || [])[index]?.family_member
                                                }
                                                options={FamilyMembers}
                                                onChange={(value) => {
                                                    if (value) {
                                                        const updatedStates = formik.values.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                family_member:
                                                                    idx === index
                                                                        ? value.target.value
                                                                        : item.family_member,
                                                            }),
                                                        )
                                                        formik.setValues(updatedStates)
                                                        setEditData({
                                                            psychiatric_condition: item?.psychiatric_condition || [],
                                                            family_member:value.target.value,
                                                            comment: item?.comment || "",
                                                        })
                                                        setEditId(item?.id)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormLabel component="p" className={classes.formLabel}>
                                                Condition(s) <span style={{ color: '#E81A1A' }}>*</span>
                                            </FormLabel>
                                            <MultiSelectCondition
                                                name="condition"
                                                value={item?.psychiatric_condition}
                                                options={conditionList}
                                                onOpen={(e) => {setOptionOpen(e) }}
                                                otherCondition={(value)=>{
                                                    const newFormat = {"key": "otherComment", "value":value}
                                                    const newValue = []
                                                    let flag = false
                                                    item?.psychiatric_condition.map((item)=>{
                                                        if(item?.key === "otherComment"){
                                                            flag= true
                                                            item.value = value
                                                            newValue.push(item)
                                                        }else{
                                                            newValue.push(item)
                                                        }
                                                    })
                                                    if(!flag){
                                                        newValue.push(newFormat)
                                                    }
                                                    const updatedStates = formik.values.map(
                                                        (item, idx) => ({
                                                            ...item,
                                                            psychiatric_condition:
                                                                idx === index
                                                                    ? newValue
                                                                    : item.psychiatric_condition,
                                                        }),
                                                    )

                                                    formik.setValues(updatedStates)
                                                    setEditData({
                                                        psychiatric_condition: newValue || [],
                                                        family_member:item?.family_member,
                                                        comment: item?.comment || "",
                                                    })
                                                    setEditId(item?.id)
                                                }}
                                                handleChange={(option, checked) => {
                                                    const newValue = [...item?.psychiatric_condition]
                                                    if (checked) {
                                                        newValue.push(option)
                                                    } else {
                                                        newValue.splice(
                                                            newValue.findIndex((el) => el.value === option.value),
                                                            1,
                                                        )
                                                    }
                                                    const otherPresent = newValue.find(item => item?.key === 'Other')
                                                    const unmatched = newValue.find(item => item?.key === 'otherComment')
                                                    let updatedValue = []
                                                    if(!otherPresent && unmatched){
                                                        updatedValue = newValue.filter((item)=> item.key !== 'otherComment') 
                                                    }else{
                                                        updatedValue=[...newValue]
                                                    }
                                                    const updatedStates = formik.values.map(
                                                        (item, idx) => ({
                                                            ...item,
                                                            psychiatric_condition:
                                                                idx === index
                                                                    ? updatedValue
                                                                    : item.psychiatric_condition,
                                                        }),
                                                    )
                                                    formik.setValues(updatedStates)
                                                    setEditData({
                                                        psychiatric_condition: newValue || [],
                                                        family_member:item?.family_member,
                                                        comment: item?.comment || "",
                                                    })
                                                    setEditId(item?.id) 
                                                }}
                                                error={
                                                    (formik.touched || [])[index]?.psychiatric_condition &&
                                                    Boolean((formik.errors || [])[index]?.psychiatric_condition)
                                                }
                                                helperText={
                                                    (formik.touched || [])[index]?.psychiatric_condition &&
                                                    (formik.errors || [])[index]?.psychiatric_condition
                                                }
                                            />
                                            {/* <SelectSearchInputCustom
                                                placeholder="Select"
                                                options={condition}
                                                multiple={true}
                                                isChecBoxFlag
                                                value={item?.psychiatric_condition}
                                                onChange={(value) => {
                                                    if (value) {
                                                        const updatedStates = formik.values.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                psychiatric_condition:
                                                                    idx === index
                                                                        ? value
                                                                        : item.psychiatric_condition,
                                                            }),
                                                        )
                                                        formik.setValues(updatedStates)
                                                        setEditData({
                                                            psychiatric_condition: item?.psychiatric_condition || [],
                                                            family_member:item?.family_member,
                                                            comment: item?.comment || "",
                                                        })
                                                        setEditId(item?.id)  
                                                    }
                                                }}
                                                limitTags={1}
                                                error={
                                                    (formik.touched || [])[index]?.psychiatric_condition &&
                                                    Boolean((formik.errors || [])[index]?.psychiatric_condition)
                                                }
                                                helperText={
                                                    (formik.touched || [])[index]?.psychiatric_condition &&
                                                    (formik.errors || [])[index]?.psychiatric_condition
                                                }
                                                otherValue={(value)=>{console.log("value",value)}}
                                            /> */}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormLabel component="p" className={classes.formLabel}>
                                                Comment
                                            </FormLabel>
                                            <TextBox
                                                // label="Note"
                                                name="note"
                                                variant="outlined"
                                                minRows={4}
                                                placeholder="Empty"
                                                // style={{ marginTop: '32px' }}
                                                value={item?.comment}
                                                onChange={(value) => {
                                                    if (value) {
                                                        const updatedStates = formik.values.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                comment: idx === index ? value : item.comment,
                                                            }),
                                                        )
                                                        formik.setValues(updatedStates)
                                                        setEditData({
                                                            psychiatric_condition: item?.psychiatric_condition || [],
                                                            family_member: item?.family_member,
                                                            comment: value || "",
                                                        })
                                                        setEditId(item?.id) 
                                                    }else{
                                                        const updatedStates = formik.values.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                comment: idx === index ? '' : item.comment,
                                                            }),
                                                        )
                                                        formik.setValues(updatedStates)
                                                        setEditData({
                                                            psychiatric_condition: item?.psychiatric_condition || [],
                                                            family_member: item?.family_member,
                                                            comment:"",
                                                        })
                                                        setEditId(item?.id) 
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={1} className="d-flex align-flex-start justify-end">
                                            <IconButton
                                                style={{  marginLeft: '5px',padding: '5px', marginTop:'31px', borderRadius: '4px',border: '1px solid  #E0E0E0'}}
                                                onClick={(e) => {
                                                    setIndexDelete(index)
                                                    setDeleteId(item?.id)
                                                    handleClick(e)
                                                }}>
                                                    <img src={DeleteIcon} alt=""/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    {item?.psychiatric_condition.length > 0 && <div style={{marginTop:'15px',display:'flex',alignItems:'center', width:'100%'}}>
                                       <div style={{width:'10%'}}>
                                        <Typography className={classes.ChosenCondition}>
                                            Chosen conditions:
                                        </Typography>
                                        </div>
                                        <div style={{width:'90%'}}>
                                        {item?.psychiatric_condition?.map((item1) => {
                                            if(item1.key !== 'Other'){
                                                return (
                                                    <Chip
                                                        key={item1?.key}
                                                        label={item1.value}
                                                        deleteIcon={<CloseIcon/>}
                                                        onDelete={(e) => {
                                                            const updatedValue = item?.psychiatric_condition.filter((i)=> i.key !== item1.key)
                                                            const updatedStates = formik.values.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    psychiatric_condition:
                                                                        idx === index
                                                                            ? updatedValue
                                                                            : item.psychiatric_condition,
                                                                }),
                                                            )
                                                            formik.setValues(updatedStates)
                                                            setEditData({
                                                                psychiatric_condition: item?.psychiatric_condition || [],
                                                                family_member:item?.family_member,
                                                                comment: item?.comment || "",
                                                            })
                                                            setEditId(item?.id)  
                                                        }}
                                                        size="small"
                                                        variant="outlined"
                                                        className={classes.chip}
                                                    />
                                                )
                                            }
                                        })}
                                        </div>
                                    </div>
                                    }
                                </Grid>
                            ))}
                            <Popover
                                id={idEnd}
                                open={openEnd}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <div className="popover_container" style={{width: "342px",padding: "14px 14px 9px 14px"}}>
                                    <div className="heading_holder">
                                        <img src={ErrorIcon}></img>
                                        <h2 className="heading" style={{paddingLeft:"12px",color:"#303E4E", fontSize:"18px"}}>
                                        Record Deletion</h2>
                                    </div>
                                    <Typography className="content" style={{color:"#303E4E"}}>
                                        Are you sure you want to delete this family history record?
                                    </Typography>
                                    <div className="btn_holder">
                                        <Button className="no_bg" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <LoadingButton
                                            className="error"
                                            style={{width: "68px",
                                                height: "32px"}}
                                            loading={loading}
                                            onClick={() => {
                                                handleDelete()
                                            }}
                                        >
                                            Delete
                                        </LoadingButton>
                                    </div>
                                </div>
                            </Popover>
                        </Box>
                    }
            </Grid>
            
            <Grid item xs={12} style={{marginTop: "15px"}}>
                    <TextWithMacrosNew
                        name={`${fieldName}.narrative`}
                        onChange={onChange}
                        // // macros={macros}
                        isDisabled={nonEditable}
                        withoutMacro={false}
                        values={values?.narrative}
                        socialHistory={true}
                        // placeholder={placeholder}
                    />
            </Grid>
            {open && (
                <PreviousCN
                    open={open}
                    handleClose={handleCloseModal}
                    categoryName={'Family History'}
                    lastNarrative={lastNarrative}
                    lastUpdateDate={lastUpdateDate}
                    handleChangecopy={myFunction}
                />
            )}
        </Grid>
    )
}

export default FamilyHistory
