import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { Medications } from 'containers/PatientsSetting/demographic/Medications'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import MedicationPatient from 'containers/PatientsSetting/view/MedicationPatient'
import CopyBar from 'components/shared/Elements/CopyBar'
import { useNotification } from 'shared/elements/Notification'
import { getListMedicationsType } from 'services/Patient/patient'
import { dateFormat, stringConcatenation } from 'shared/utils'
import moment from 'moment'

const useStyles = makeStyles({
    subLabel: {
        fontSize: 12,
        color: '#9CA9B8',
    },
    itemCard: {
        width: 400,
        height: 500,
    },
})

const mapOptions = (data = []) => {
    return data.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const PlanMedChanges = ({
    fieldName,
    master,
    values,
    onChange,
    lastNarrative,
    macros,
    patientId,
    encounter_id,
    handleSetViewHealthyRecord,
    encounter_date,
    nonEditable
}) => {
    const classes = useStyles()
    const notification = useNotification()

    const classesCard = usePatientDashboardStyles()
    const options = mapOptions(master?.planMedChangesMasters)

    const handleCheckChange = (e, option) => {
        const { name, value, checked } = e.target
        const { plan_med_opt } = values
        if (checked) {
            onChange(name, [...plan_med_opt, { id: value, label: option.label }])
        } else {
            onChange(
                name,
                plan_med_opt.filter((uv) => uv.id !== value),
            )
        }
    }
    const handleSelectMacro = (value) => {
        if (nonEditable) { return }
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const statusOfMedicationList = (
        lastWrittenDate = null,
        stopDate = null,
        isPrescribe = false,
    ) => {
        if (stopDate && dateFormat(stopDate).isSame(moment(encounter_date), 'day')) {
            return 'Discontinue'
        } else if (isPrescribe && lastWrittenDate) {
            if (dateFormat(lastWrittenDate).isSameOrAfter(moment(encounter_date), 'day')) {
                return 'New Order'
            } else if (dateFormat(lastWrittenDate).isBefore(moment(encounter_date), 'day')) {
                return 'Continue'
            }
        } else if (!isPrescribe || !lastWrittenDate) {
            return 'Continue'
        }
    }
    const convert = (data) => {
        // if (data?.action_status === "Discontinue"  || !moment(data?.stop_date).isSameOrBefore(moment(), 'day')  ) {
        // return {
        //     drug_name: stringConcatenation(
        //         data.drug.brandName,
        //         data.drug.genericName,
        //         data.drug.strength,
        //         data.drug.form,
        //     ),
        //     strength: data.strength || '',
        //     provider_name: `${data.provider_first_name} ${data.provider_last_name}`,
        //     days_supply: data.day_supply?.display || '',
        //     quantity: data.quantity || '',
        //     refills: data.refills || '',
        //     action_status: statusOfMedicationList(data?.last_written_date, data?.stop_date, data?.is_prescribe),
        //     date_written: data.last_written_date ? dateFormat(data?.last_written_date).format('MM/DD/YYYY') : ''
        // }
        // }
        return {
            //----Addded SOI-370->Start----//
            drug_name: stringConcatenation(
                data.drug.brandName,
                data.drug.genericName,
                data.drug.strength,
                data.drug.form,
            ),
            strength: data.strength || '',
            days_supply: data.day_supply?.display || '',
            quantity: data.quantity || '',
            refills: data.refills || '',
            date_written: data.last_written_date ? dateFormat(data?.last_written_date).format('MM/DD/YYYY') : '',
            //----Addded SOI-370->END----//
            medication: stringConcatenation(data.drug.brandName, data.drug.genericName),
            dosage: ` ${data?.quantity ? `x${data?.quantity}: ` : ''}${data.drug.strength} ${data.drug.form
                }`,
            // sig:`${data?.dose || ""} ${data?.dose_unit || ""} ${data?.route?.display || ""} ${data?.frequency?.display || ""}`,
            sig: stringConcatenation(
                data?.dose,
                data?.dose_unit,
                data?.route?.display,
                data?.frequency?.display,
            ),
            prescriber:
                data.provider_first_name || data.provider_last_name
                    ? `${data.provider_first_name} ${data.provider_last_name}`
                    : '',
            action: statusOfMedicationList(
                data?.last_written_date,
                data?.stop_date,
                data?.is_prescribe,
            ),
        }
    }

    const getListMedication = async () => {
        try {
            if(!nonEditable){
            const resp = await getListMedicationsType('active', patientId, 1, 100000)
            const list = resp.data.data.filter((item) => {
                const itemStopDate = item?.stop_date ? dateFormat(item?.stop_date) : false;
                const encounterDate = moment(encounter_date);
                if (itemStopDate) {
                    return itemStopDate.isSameOrAfter(encounterDate, 'day');
                }
                return true;

            }).map((item) => convert(item))
            // const list = resp.data.data.map((item) => convert(item))
            onChange(`${fieldName}.medication_list`, list)
            }
        } catch (e) {
            notification('Cannot get list medication', 'error')
        }
    }

    useEffect(() => {
        ; (async () => {
            await getListMedication()
        })()
    }, [])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 5,
            }}>
            <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            {/* <Typography className={classes.subLabel}>
                (no patient medications found)
            </Typography> */}
            <Box>
                <MedicationPatient
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getListMedication}
                    encounter_date={encounter_date}
                    nonEditable={nonEditable}
                    clinicalNoteMedList = {values?.medication_list}
                />
            </Box>
            {/* <Grid
                item
                className={clsx(
                    classesCard.paper,
                    classesCard.box,
                    classesCard.card,
                    classes.itemCard,
                )}>
                
                <Medications
                    patientId={patientId}
                    encounter_id={encounter_id}
                    viewAllFunc={() => {
                        handleSetViewHealthyRecord('medications', 'Back to Plan - Med Changes')
                    }}
                /> */}
            {/* </Grid> */}

            {options.map((option, index) => (
                <CheckboxOption
                    disabled={nonEditable}
                    key={index}
                    name={`${fieldName}.plan_med_opt`}
                    option={option}
                    onChange={(e) => handleCheckChange(e, option)}
                    checked={
                        values?.plan_med_opt.some((item) => item.id === option.value) ? true : false
                    }
                />
            ))}
            <NarrativeAndMacro
                hideTextNarrative={false}
                hideGenerateBtn={false}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default PlanMedChanges
