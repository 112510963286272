import {
    postSearchService,
    getService,
    postService,
    putService,
    patchService,
    deleteService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'
import lodash from 'lodash'

export function getSchedule({ status, visitType, location, startTime, practitioner_id, endTime }) {
    let url = '/schedule/calendar?'
    if (status) {
        url += `appointmentStatus=${status}&`
    }
    if (visitType) {
        url += `visit-type=${visitType}&`
    }
    if (practitioner_id) {
        url += `practitioner_id=${practitioner_id}&`
    }
    if (location) {
        url += `location=${location}&`
    }
    if (startTime) {
        url += `start=${startTime}&`
    }
    if (endTime) {
        url += `end=${endTime}`
    }

    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}${url}`)
}

export const updateSchedule = ({ id, payload }) => {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${id}`, payload)
}

export function getScheduleById({ id }) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${id}`)
}

export function apiCheckSlotAppt(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/check`, payload)
}

export function createSchedule(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment`, payload)
}

export function getCPTCode(payload) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cptcode/_search`,
        payload,
    )
}

export function getEventCardInfo(encounter_id, patient_id) {
    const isEncounter = encounter_id ? { encounter_id } : {}
    const query = new URLSearchParams({
        patient_id,
        ...isEncounter,
    })
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/schedule/calendar/get?${query}`)
}

export function getLocations(payload) {
    const queryString = new URLSearchParams({
        asc: 'name',
        ...payload,
        type: 'location',
    }).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/location?${queryString}`)
}

export const apiRaferrals = {
    getReferralList(payload) {
        const queryString = new URLSearchParams({
            ...payload,
        }).toString()
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referral?${queryString}`)
    },
    getByID(id) {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referral/${id}`)
    },
    careate(payload) {
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referral`, payload)
    },
    update(payload, idReferral) {
        return putService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/referral/${idReferral}`,
            payload,
        )
    },
    delete(id, payload) {
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referral/${id}`, payload)
    },
}

export const searchAvaiAppoinment = (payload) => {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/free-slot`, payload)
}

export const apiFollowUp = {
    getAappointments(payload) {
        // const queryString = new URLSearchParams({
        //     ...payload,
        // }).toString()
        // return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment?${queryString}`)
        let url = `${process.env.REACT_APP_SONORA_V2_API_URL}/appointment?page=${payload?.page}&size=${payload?.size}`
        if (payload?._sort) url += `&_sort=${payload?._sort}`
        if (payload?.patient_id) url += `&patient=${payload?.patient_id}`
        if (payload?.provider_id) url += `&practitioner=${payload?.provider_id}`
        if (payload?.specialty_id) url += `&specialty=${payload?.specialty_id}`
        if (payload?.visit_id) url += `&visit-type=${payload?.visit_id}`
        if (payload?.chartStatus) url += `&chartStatus=${payload?.chartStatus}`
        if (payload?.start) url += `&start=${payload?.start}`
        if (payload?.end) url += `&end=${payload?.end}`
        return getService(url)
    },
}

export function getProivders(payload) {
    const queryString = new URLSearchParams({
        ...payload,
        type: 'location',
    }).toString()
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner?role=medical_provider,bh_provider,mid_medical_provider,mid_bh_provider,therapist&isAllProvider=true&_sort=given&size=1000000&page=0`,
    )
}

export const allApiLabOrder = {
    getAll(payload) {
        const queryString = new URLSearchParams({
            ...payload,
        }).toString()

        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/lab-order?${queryString}`)
    },
    getByIdPatient(idPatient, page = 0, size = 10) {
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${idPatient}/lab-order?page=${page}&size=${size}`,
        )
    },
    syncLabOrder() {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/chc/lab-order-sync`)
    },
}

export const getLabList = () => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/lab-order/lab-list`)
}

export const getOrderingProvider = () => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/lab-order/orderingProvider`)
}

export const getReportIDSuggestion = () => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/lab-order?page=1&size=1000&columns=clinicalReport`,
    )
}

export const allApiReffringProvider = {
    getAll(payload) {
        const queryString = new URLSearchParams({
            asc: 'first_name',
            ...payload,
            type: 'location',
        }).toString()

        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/referring-provider?${queryString}`,
        )
    },
    create(payload) {
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referring-provider`, payload)
    },
    update(payload, id) {
        return putService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/referring-provider/${id}`,
            payload,
        )
    },
    delete(id) {
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referring-provider/${id}`)
    },
    getById(id) {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/referring-provider/${id}`)
    },
}

export const getSpecialty = (payload) => {
    const queryString = new URLSearchParams({
        asc: 'specialty',
        ...payload,
    }).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/specialty?${queryString}`)
}

export const deleteLocation = (id) => {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/location/${id}`)
}

export function getLocationById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/location/${id}`)
}
export function updateLocationById({ id, payload }) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/location/${id}`, payload)
}

export function getAvailableTime() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner/me/available-time`)
}
export function getTimeOffs(fromDate, toDate) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner/me/off-time?fromDate=${fromDate}&toDate=${toDate}`,
    )
}

export function getLocationsByPratitioner(practitionerId) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner/me/location?page=&size=10&type=location&partof=${practitionerId}`,
    )
}
export function getRooms(locationId) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/location?partof=${locationId}&type=room`,
    )
}

export function getPatientAppointment() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient`)
}

export function searchPatientAppointment(keyword) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_patient/_search`,
        {
            size: 25,
            query: {
                // multi_match: {
                //     query: keyword,
                //     type: 'bool_prefix',
                //     fields: ['firstName', 'lastName', 'fullName'],
                // },
                bool: {
                    should: [
                        {
                            match_phrase_prefix: {
                                fullName: keyword,
                            },
                        },
                        {
                            match: {
                                fullName: keyword
                            }
                        }
                    ],
                },
            },
        },
    )
}

export function searchPatientGlobal(keyword, value) {
    if(keyword === 'birthDate'){
        return postSearchService(
            `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
            }_patient/_search`,
            {
                size: 25,
                query: {
                    bool: {
                        should: [
                            {
                                match: {
                                    [keyword]: value
                                }
                            }
                        ],
                    },
                },
            },
        )
    }else if(keyword === 'phoneNumber'){
        return postSearchService(
            `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
             }_patient/_search`,{
                size: 25,
                query: {
                  bool: {
                    must: [
                      {
                        match_phrase_prefix: {
                            [keyword]: value
                        }
                      },
                      {
                        match_phrase_prefix: {
                            [keyword]: value
                        }
                      }
                    ]
                  }
                }
              })
    }
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_patient/_search`,
        {
            size: 25,
            query: {
                bool: {
                    should: [
                        {
                            match_phrase_prefix: {
                                [keyword]: value
                            },
                        },
                        {
                            match: {
                                [keyword]: value
                            }
                        }
                    ],
                },
            },
        },
    )
}

export function searchLocationAppointment(keyword) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_location/_search`,
        {
            size: 25,
            query: {
                bool: {
                    must: {
                        multi_match: {
                            query: keyword,
                            type: 'bool_prefix',
                            fields: ['id', 'name'],
                        },
                    },
                    filter: {
                        bool: {
                            must_not: {
                                term: {
                                    is_external: true,
                                },
                            },
                        },
                    },
                },
            },
            highlight: {
                pre_tags: ['<em class="text-hightlight">'],
                post_tags: ['</em>'],
                fields: {
                    name: {
                        number_of_fragments: 1,
                        fragment_size: 50,
                    },
                    id: {
                        number_of_fragments: 1,
                        fragment_size: 50,
                    },
                },
            },
        },
    )
}

export function searchKeyword(keyword) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_keywords/_search`,
        {
            size: 25,
            query: {
                multi_match: {
                    query: keyword,
                    type: 'bool_prefix',
                    fields: ['keyword'],
                },
            },
        },
    )
}

export function searchVisitType(keyword) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_visit_types/_search`,
        {
            size: 25,
            query: {
                multi_match: {
                    query: keyword,
                    fields: [
                        'post_code',
                        // "cpt_arr.code"
                    ],
                    type: 'bool_prefix',
                },
            },
        },
    )
}

export function searchProvider(keyword = '') {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_${process.env.REACT_APP_ENV || 'dev'
        }_provider/_search`,
        {
            size: 25,
            query: {
                bool: {
                    must: [
                        {
                            match_phrase_prefix: {
                                fullName: keyword,
                            },
                        },
                    ],
                },
                // multi_match: {
                //     query: keyword,
                //     type: 'bool_prefix',
                //     fields: ['fullName'],
                // },
            },
        },
    )
}

export function getPractitionerByRole(role) {
    const rol = role === 'all' ? '' : role === 'Medical' ? 'medical_provider' : 'bh_provider'
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner?role=bh_medical_provider,medical_provider,bh_provider,therapist&isAllProvider=true`,
    )
}
export function getAllPractitioner(role) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner?size=10000`)
}

export function createLocations(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/location`, payload)
}

export function getVisitType(payload) {
    const query = new URLSearchParams({
        asc: 'post_code',
        ...payload,
    })
    if (payload?.desc === 'createdAt') query.delete('asc')

    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/visit-type?${query.toString()}`,
    )
}

export const deleteVisitType = (id) => {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/visit-type/${id}`)
}

export function getVisitById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/visit-type/${id}`)
}

export function updateVisitType({ id, payload }) {
    return putService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/visit-type/${id}`,
        payload,
    )
}

export function getTaxonomy() {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/valueset?name=Provider Taxonomy Codes`,
    )
}

export function createVisitType(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/visit-type`, payload)
}


export function createAdminCode(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/create-admin-code`, payload)
}

export function getAdmincodeById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/admin-code/${id}`)
}

export function UpdateAdminCode(id, payload) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/admin-code/${id}`, payload)
}

export function deleteAdminCode(id) {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/admin-code/${id}`)
}

export function getListAdminCodes(payload = {}) {
    const queryString = new URLSearchParams(payload).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/admin-code?${queryString}`)
}

export function getClinicalNoteType() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/clinical-note/type/list`)
}
export function getKeywordById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/media-keyword/${id}`)
}
export function getKeyword(payload = {}) {
    const queryString = new URLSearchParams(payload).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/media-keyword?${queryString}`)
}
export function createKeyword(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/media-keyword/`, payload)
}
export function updateKeyword({ id, payload }) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/media-keyword/${id}`, payload)
}
export function deleteKeyword(id) {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/media-keyword/${id}`)
}
// device
export function getDeviceList(payload) {
    const queryString = new URLSearchParams(payload).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/device?${queryString}`)
}

export function getDeviceById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/device/${id}`)
}

export function createDevice(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/device`, payload)
}

export function updateDevice({ id, payload }) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/device/${id}`, payload)
}

export function deleteDevice(id) {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/device/${id}`)
}

//departments
export function getDepartmentList({ page = 1, limit = 10, name = '', head_id = '' }) {
    let url = `${process.env.REACT_APP_SONORA_V2_API_URL}/organization?page=${page}&size=${limit}`

    if (name) url += `&name=${name}`
    if (head_id) url += `&head_id=${head_id}`

    return getService(url)
}

export function getDepartmentById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/organization/${id}`)
}

export function createDepartment(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/organization`, payload)
}

export function updateDepartment({ id, payload }) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/organization/${id}`, payload)
}

export function deleteDepartment(id) {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/organization/${id}`)
}

// room

export function createRoom(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/room`, payload)
}

export function updateRoom({ id, payload }) {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/room/${id}`, payload)
}

export function removeRoom(id) {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/room/${id}`)
}

export function getRoomList(payload) {
    const queryString = new URLSearchParams(payload).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/room?${queryString}`)
}

export function getRoomById(id) {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/room/${id}`)
}

export function getStatus(payload) {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/appointment-status?${queryString}`,
    )
}

export const deleteStatus = (id) => {
    return deleteService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/appointment-status/${id}`,
    )
}

export function getStatusById(id) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/appointment-status/${id}`,
    )
}

// export function updateStatus({ payload }) {
//     return patchService(
//         `${process.env.REACT_APP_SONORA_V2_API_URL}/status-code`,
//         payload,
//     )
// }

export function updateStatus({ id, payload }) {
    return putService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/appointment-status/${id}`,
        payload,
    )
}

export const apiStatusCodes = {
    getStatusCodes(payload) {
        const queryString = new URLSearchParams({
            key: 'createdAt',
            direction: 'DESC',
            ...payload,
        }).toString()
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code?${queryString}`)
    },
    createStatus(payload) {
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code`, payload)
    },
    deleteStatus(id) {
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code/${id}`)
    },
    updateStatus(id, payload) {
        return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code/${id}`, payload)
    },
    getStatusById(id) {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code/${id}`)
    },
    getCategory() {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/status-code/category`)
    },
}

export function updateStatusForAnAppointmentAPI({ id, appointmentStatusId }) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${id}/status`, {
        appointment_status_id: appointmentStatusId,
    })
}

export function createStatus(payload) {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/appointment-status`,
        payload,
    )
}

export function getAvailability(payload) {
    return postService(`${config.dev.baseURL}/availability/get-schedule`, payload)
}

export function createAvailability(payload) {
    return postService(`${config.dev.baseURL}/availability/create`, payload)
}

export function getBusySlot({ startTime, providerId }) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/slot/busy?start=${startTime}&provider=${providerId}`,
    )
}
export function getClinics(providerId) {
    return getService(`${config.dev.baseURL}/clinic/list?provider_id=${providerId}`)
}

export function setTimeoff(payload) {
    return postService(`${config.dev.baseURL}/time-off/create`, payload)
}

export function getAppointmentCount(payload) {
    return getService(
        `${config.dev.baseURL}/appointment/fetch-appointment-count?provider_id=${payload.provider_id}&start_date=${payload.start_date}&end_date=${payload.end_date}`,
    )
}

export function fetchSlotDetails(payload) {
    const query = payload.date
        ? `date=${payload.date}`
        : `start_date=${payload.start_date}&end_date=${payload.end_date}`
    const status = payload.status ? `&status=${payload.status}` : ''
    return getService(
        `${config.dev.baseURL}/providers/fetch-slot-details?provider_id=${payload.provider_id}&${query}${status}`,
    )
}

export function createAccount(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account`, payload)
}

export function createPractitioner(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/practitioner`, payload)
}

export function getAccountList({ page, size = 10, name, location_id, role, asc, desc }) {
    let url = '/account?'
    if (page) {
        url += `page=${page}&`
    }
    if (size) {
        url += `size=${size}&`
    }
    if (name) {
        url += `name=${name}&`
    }
    if (location_id) {
        url += `location_id=${location_id}&`
    }
    if (role) {
        url += `role=${role}&`
    }
    if (asc) {
        url += `asc=${asc}&`
    }
    if (desc) {
        url += `desc=${desc}&`
    }

    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}${url}`)
}

export const removeAccount = (id) => {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/${id}`)
}

export const resetPasswordAccount = (id) => {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/${id}/reset-password`)
}

export const updateStatusAccount = ({ id, payload }) => {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/${id}/status`, payload)
}

export const updateAccount = ({ id, payload, is_from_provider_directory = false }) => {
    const finalPayload = {
        ...payload,
        is_from_provider_directory
    }
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/${id}`, finalPayload)
}
export const updateMe = ({ payload, is_from_provider_directory = false }) => {
    const finalPayload = {
        ...payload,
        is_from_provider_directory
    }
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/self`, finalPayload)
}

export const getAccountById = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/account/${id}`)
}

export const getAllRole = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/role`)
}

export const getRoleBaseSecurity = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/role/${id}/permission`)
}

export const putRoleBaseSecurity = ({ id, payload }) => {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/role/${id}/permission`, payload)
}

export const postSecurityRole = (payload) =>
    postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/role`, payload)

export const sendResetConfirmationLink = (appointment_id, send_reschedule_notification = null) => {
    let mainPath = 'resendConfirmation'
    if (send_reschedule_notification !== null) {
        mainPath = `${mainPath}?type=Reschedule&send_reschedule_notification=${send_reschedule_notification}`
    }
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${appointment_id}/${mainPath}`,
    )
}

export const deleteAppointment = (appointment_id) => {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${appointment_id}`)
}

export const deleteAppointmentSeries = (appointment_id) => {
    return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/staff-series/${appointment_id}`)
}

export const postStatusChangeRequest = (status_name, appointment_id, dependables = null, timeZone = null, cancelationReason = null) => {
    const dependableInfo = {
        is_delete_note: false,
        delete_chart_record: false,
        add_recall: false,
        add_waitlist: false,
        add_clinical_note: false,
        add_chart_record: false,
    }

    if (dependables) {
        dependableInfo.is_delete_note = Boolean(dependables?.is_delete_note)
        dependableInfo.delete_chart_record = Boolean(dependables?.delete_chart_record)
        dependableInfo.add_recall = Boolean(dependables?.add_recall)
        dependableInfo.add_waitlist = Boolean(dependables?.add_waitlist)
        dependableInfo.add_clinical_note = Boolean(dependables?.add_clinical_note)
        dependableInfo.add_chart_record = Boolean(dependables?.add_chart_record)
    }

    const cancellationReasonInfo = cancelationReason ? { cancelationReason } : {}

    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/${appointment_id}/changeStatus`,
        {
            status: status_name,
            ...dependableInfo,
            time_zone: timeZone,
            ...cancellationReasonInfo
        },
    )
}
export const postRecall = (payload) => {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/recall`, { ...payload })
}

export const getRecallList = (payload = {}) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()

    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/recall?${queryString}`)
}
export const getRecallById = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/recall/${id}`)
}
export const updateRecall = ({ id, payload }) => {
    return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/recall/${id}`, payload)
}
export const updateAcknowledge = (patientId, labOrderId) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/lab-order/${labOrderId}/acknowledge`,
    )
}
export const updateLabOrderPreview = (patientId, labOrderId, payload) => {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/lab-order/${labOrderId}/review-report`,
        payload,
    )
}
export const labOrderMatch = (patientId, labOrderId, payload) => {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/lab-order/${labOrderId}/match`,
        payload,
    )
}
export const getAppointmentLog = (payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/appointment/activityLogs?${queryString}`)
}

export const bulkDeleteWaitlist = (payload) => {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/waitlist/delete`, payload)
}

export const bulkDeleteRecallList = (payload) => {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/recall/delete`, payload)
}


export const providerHealthIdPost = (id, payload) => {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/provider/${id}/health-plan`, payload)
}
export const providerHealthIdGet = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/provider/${id}/health-plan`)
}
export function insuranceMatching(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/provider/insurance-match`, payload)
}

export function staffNoteAdd(payload) {
    return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/note`, payload)
}
