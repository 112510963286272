import React from 'react'
import { Box, TextField } from '@material-ui/core'

const RenderBMI = ({ master, values }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'bmi',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)
    return (
        <Box
            sx={{
                border: '1px solid #EAECF0',
                width: '100px',
                borderRadius: 3,
                alignItems: 'center',
                display: 'flex',
                backgroundColor: '#FFF',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
            }}>
            <TextField
                placeholder="BMI"
                fullWidth="true"
                disabled={true}
                InputProps={{
                    style: {
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                    },
                    disableUnderline: true,
                }}
                hiddenLabel
                id="filled-hidden-label-small"
                value={filteredEle?.value}
                variant="filled"
                size="small"
                style={{ width: 100 }}
            />
        </Box>
    )
}

export default RenderBMI