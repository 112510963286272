/* eslint-disable default-case */
import {
    getService,
    postService,
    putService,
    deleteService,
} from '../../utilities/request/restClient'
// import { config } from '../../constants';
import { getLoggedInUserRole } from '../../utilities'
import { UserRoles } from '../../constants/user-roles'
import { config } from '../../constants'

// import {
//     postService
// } from '../../utilities/request/restClient';

export const getMacros = (payload) => postService(`/macros/list`, payload)

export const addMacro = (payload) => postService('/macros/add', payload)

export const updateMacro = (payload) => postService('/macros/update', payload)

export const inactiveMacro = (payload) => postService('/macros/inactive', payload)

export const searchMacros = (payload) => postService('/clinical-note/macro', payload)

export const getMacroList = (payload) => {
    const { page = 1, size = 10 } = payload
    return getService(`${config.dev.baseURLV2}/clinical-note/macro?page=${page}&size=${size}`)
}

export const getListMacros = (page = 1, pageSize = 10) => {
    const url = `${config.dev.baseURLV2}/clinical-note/macro?page=${page}&size=${pageSize}`
    return getService(url)
}

export const getMacroById = (id) => {
    const url = `${config.dev.baseURLV2}/clinical-note/macro/${id}`
    return getService(url)
}

export const createMacro = (payload) => {
    const url = `${config.dev.baseURLV2}/clinical-note/macro`
    return postService(url, payload)
}

export const updateMacroById = (id, payload) => {
    const url = `${config.dev.baseURLV2}/clinical-note/macro/${id}`
    return putService(url, payload)
}

export const deleteMacro = (id) => {
    const url = `${config.dev.baseURLV2}/clinical-note/macro/${id}`
    return deleteService(url)
}
export const createPractitionerMarco = (id,payload) =>{
    const url = `${config.dev.baseURLV2}/practitioners/${id}/macro`
    return postService(url, payload)
}
export const getPractitionerMarco = (id,payload={}) =>{
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    const url = `${config.dev.baseURLV2}/practitioners/${id}/macro?${queryString}`
    return getService(url)
}
export const getPractitionerMarcoID = (id,macrosID) =>{
    const url = `${config.dev.baseURLV2}/practitioners/${id}/macro/${macrosID}`
    return getService(url)
}
export const updatePractitionerMarco = (id,macrosID,payload) =>{
    const url = `${config.dev.baseURLV2}/practitioners/${id}/macro/${macrosID}`
    return putService(url,payload)
}
export const deletePractitionerMacro = (id,macrosID) => {
    const url = `${config.dev.baseURLV2}/practitioners/${id}/macro/${macrosID}`
    return deleteService(url)
}