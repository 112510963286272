import React, { useEffect, useState } from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { GridColumns } from '@mui/x-data-grid'
import { connect, useSelector } from 'react-redux'

import { Button as ButtonCustom } from 'components/custom'

import { useNotification } from 'shared/elements/Notification'

import { SpecialtyForm } from './SpecialtyForm'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { Specialty } from './interface'
import { DataGrid } from 'shared/elements/DataGrid'
import { ErrorType } from 'containers/PatientsSetting/interfaces'
import { SearchField } from 'shared/elements/FormItem/SearchComponents/SearchField'
import {
    createSpecialty,
    deleteSpecialty,
    getListSpecialties,
    updateSpecialty,
} from 'services/Setting'
import { useDebounce } from 'shared/hooks'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
})

const Specialties = () => {
    const classes = useStyles()
    const notification = useNotification()

    const [sortModel, setSortModel] = useState([])
    const [textSearch, setTextSearch] = useState<String>('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [clinicalPracticeForm, setClinicalPracticeForm] = useState<{
        practiceId: string | undefined
        status: boolean
    }>({
        practiceId: '',
        status: false,
    })

    const [isLoading, setLoading] = useState(false)
    const [clinicalPractices, setClinicalPractices] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const debouncedSearchText = useDebounce(textSearch, 500)

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const permissions = useSelector((state) => state?.permission?.specialty)

    const handleSearch = (val: string) => {
        setTextSearch(val)
    }

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const columns: GridColumns<Specialty> = [
        {
            headerName: 'Specialty',
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Conditions Treated',
            headerClassName: 'super-app-theme--header',
            field: 'conditions_treated',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value?.map((item) => item.code)?.join(', ') || '-'
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_specialty') &&
                !permissions.includes('delete_specialty'),
            renderCell: ({ row }) => {
                const menu = [] as any
                if (permissions.includes('modify_specialty')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleClickEdit(row.id as string),
                    })
                }
                if (permissions.includes('delete_specialty')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDelete(row.id as string),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Specialty?"
                    />
                )
            },
        },
    ]

    const handleSubmit = async (payload: Specialty) => {
        const successMsg = clinicalPracticeForm.practiceId
            ? 'A Specialty was successfully updated!'
            : 'A Specialty was successfully created!'

        const errorMsg = clinicalPracticeForm.practiceId
            ? 'A Specialty was not successfully updated!'
            : 'A Specialty was not successfully added!'

        try {
            setIsSubmitting(true)

            if (clinicalPracticeForm.practiceId) {
                await updateSpecialty(clinicalPracticeForm.practiceId, payload)
            } else {
                await createSpecialty(payload)
            }

            notification(successMsg, 'success')

            getListClinicalPractices()
            handleChangeForm()
        } catch (exception) {
            notification((exception as ErrorType).data?.message || errorMsg, 'error')
        }

        setIsSubmitting(false)
    }

    const handleDelete = async (practiceId: string) => {
        try {
            await deleteSpecialty(practiceId)
            getListClinicalPractices()
            notification('A Specialty was successfully deleted!', 'success')
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message || 'A Specialty was not successfully deleted!',
                'error',
            )
        }
    }

    const handleClickEdit = (practiceId: string) => {
        handleChangeForm(practiceId)
    }

    const handleChangeForm = (practiceId?: string) => {
        setClinicalPracticeForm({
            status: !clinicalPracticeForm.status,
            practiceId,
        })
    }

    useEffect(() => {
        const abortController = new AbortController()
        getListClinicalPractices()

        return () => abortController.abort()
    }, [page, sortModel])

    useEffect(() => {
        const abortController = new AbortController()

        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        getListClinicalPractices(1)
        return () => abortController.abort()
    }, [debouncedSearchText])

    const getListClinicalPractices = async (start = page) => {
        setLoading(true)
        try {
            const resp = await getListSpecialties(
                sortModel[0]?.sort
                    ? {
                          size: limit,
                          page: start,
                          search: textSearch.trim(),
                          [sortModel[0].sort]: sortModel[0].field,
                      }
                    : {
                          size: limit,
                          page: start,
                          search: textSearch.trim(),
                      },
            )

            setClinicalPractices(resp.data.data || [])
            setTotal(resp.data?.paging?.total || 0)
        } catch (exception) {
            notification(
                (exception as ErrorType).data?.message || 'Cannot get list clinical practices',
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Specialties</Typography>

                    <Grid item className="d-flex space-between align-center">
                        <SearchField onSearch={handleSearch} />
                        <RBACProvider permissions={permissions}>
                            <RBACWrapper requiredPermissions={['create_specialty']}>
                                <ButtonCustom
                                    tooltip="Add New Specialty"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        setClinicalPracticeForm({
                                            status: true,
                                            practiceId: undefined,
                                        })
                                    }
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </RBACWrapper>
                        </RBACProvider>
                    </Grid>
                </Grid>
            </Grid>

            <DataGrid
                sortingMode="server"
                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                height={'calc(100vh - 140px)'}
                rows={clinicalPractices}
                columns={columns}
                totalRecords={total}
                pageSize={limit}
                loading={isLoading}
                rowCount={total}
                page={page}
                onPageChange={handlePageChange}
            />

            {clinicalPracticeForm.status && (
                <SpecialtyForm
                    open={clinicalPracticeForm.status}
                    specialtyId={clinicalPracticeForm.practiceId}
                    loading={isSubmitting}
                    onCancel={handleChangeForm}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Specialties)
