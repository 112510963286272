const ForwardIcon = () => {
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.67747 14.115C7.81997 14.115 7.96247 14.0625 8.07497 13.95C8.29247 13.7325 8.29247 13.3725 8.07497 13.155L3.91997 8.99997L8.07497 4.84497C8.29247 4.62747 8.29247 4.26747 8.07497 4.04997C7.85747 3.83247 7.49747 3.83247 7.27997 4.04997L2.72747 8.60247C2.50997 8.81997 2.50997 9.17997 2.72747 9.39747L7.27997 13.95C7.39247 14.0625 7.53497 14.115 7.67747 14.115Z"
                fill="#303E4E"
            />
            <path
                d="M3.2525 9.5625H15.875C16.1825 9.5625 16.4375 9.3075 16.4375 9C16.4375 8.6925 16.1825 8.4375 15.875 8.4375H3.2525C2.945 8.4375 2.69 8.6925 2.69 9C2.69 9.3075 2.945 9.5625 3.2525 9.5625Z"
                fill="#303E4E"
            />
        </svg>
    )
}
export default ForwardIcon
