import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogContent, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import BhScale from  'assests/icons/BhScale.svg'
import BhScaleSelect from  'assests/icons/BhScaleSelect.svg'
import CloseIcon from '@mui/icons-material/Close'
import { Select } from 'shared/elements/FormItem/Select'
import { useFormik } from 'formik'
import * as yup from 'yup'
import BhFileUpload from './BhFileUpload'
import { LoadingButton } from '@mui/lab'
import { useNotification } from 'shared/elements/Notification'
import { createBhScale, getCariumSSOLink } from 'services/Provider/provider'
import { useDispatch, useSelector } from 'react-redux'
import { openBhScaleUploadForm } from 'store/actions/patient'

import CariumLink from  'assests/icons/CariumLink.svg'
import CariumAccessPatient from  'assests/icons/CariumAccessPatient.svg'

const useStyles = makeStyles(() => ({
    dividerTab:{
        height: '33px !important',
        width: '1px !important',
        marginRight: '17px !important',
        backgroundColor: '#7D8793 !important',
    },
    circle: {
        border:'1px solid #5571C6',
        borderRadius: '50%',
        padding: '5px'
    },
    root: {
        '& .MuiPaper-root': {
            maxHeight: '450px',
            overflow: 'auto !important',
            minHeight:'450px',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'auto!important',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '834px',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
    },
    headerText: {
        color: "#303E4E",
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },
    errorMessage:{
        color: '#f44336',
        fontSize: '11px',
        marginTop: '7px',
        textAlign: 'left',
        fontFamily: 'Rubik',
        fontWeight: '500',
    }
}))



const BhScaleUpload = () => {
    const classes = useStyles()
    const openBHForm = useSelector((state) => state?.patient?.openBHForm)
    const dispatch = useDispatch()
    const [showCircle, setShowCircle] = useState(false)
    const [showCircleCarium, setShowCircleCarium] = useState(false)
    const [onClickCarium, setOnClickCarium] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [fileUpdload, setFileUpload] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const notification = useNotification()
    const validationSchema = yup.object({
        score_type: yup.string().required('Scale type is required!'),
        file_name: yup.string().required('Upload form is required!'),
    })
    const handleSubmit = async(value) => {
        try{
            setIsSubmitting(true)
            let payload ={
                ...value,
                type : "BH_SCALES",
            }
            const res = await createBhScale(payload)
            if(res){
                notification('BH scale created successfully !', 'success')
                setFileUpload([])
                formik.setFieldValue('score_type', '')
                formik.setFieldValue('file_name', '')
                formik.resetForm()
                setOpenModal(false)
                setIsSubmitting(false)
            }
            
        }catch(e){
            setIsSubmitting(false)
            notification('Something went wrong !', 'error')
        }
    }
    const closeModal = () =>{
        setFileUpload([])
        formik.setFieldValue('score_type', '')
        formik.setFieldValue('file_name', '')
        formik.resetForm()
        setOpenModal(false)
        dispatch(openBhScaleUploadForm(false))
    }
    const formik = useFormik({
        initialValues: {score_type: '', file_name: ''},
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })

    useEffect(()=>{
        if(openBHForm){
            setOpenModal(true)
        }
    },[openBHForm])

    const handleCLickCarium = async() =>{
        try{
            const res = await getCariumSSOLink()
            if(res?.data?.url){
                window.open(res?.data?.url, '_blank')
            }
            setShowCircleCarium(false)
            setOnClickCarium(false)
        }catch(error){
            setShowCircleCarium(false)
            setOnClickCarium(false)
            notification(error?.data?.message || 'Something went wrong !', 'error')
        }
    }
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '17px',
                    }}>
                    <Divider
                        variant="middle"
                        orientation="vertical"
                        className={classes.dividerTab}
                    />
                    <div
                        style={{ cursor: 'pointer' }}
                        onMouseOver={() => {
                            setShowCircle(true)
                        }}
                        onMouseOut={() => {
                            setShowCircle(false)
                        }}
                        onClick={() => {
                            setOpenModal(true)
                        }}>
                        <img
                            src={ openModal ? BhScaleSelect : BhScale}
                            alt=""
                            style={{marginLeft: (showCircle||openModal) ? '0px' : '5px'}}
                            height={(showCircle||openModal) ? '23px' : '30px'}
                            className={(showCircle||openModal) ? classes.circle : ''}
                        />
                    </div>

                    {/* Carium SSO */}
                    <div
                        style={{ cursor: 'pointer', marginLeft:'15px' }}
                        onMouseOver={() => {
                            setShowCircleCarium(true)
                        }}
                        onMouseOut={() => {
                            setShowCircleCarium(false)
                        }}
                        onClick={() => {
                            setOnClickCarium(true)
                            handleCLickCarium()
                        }}>
                        <img
                            src={ onClickCarium ? CariumAccessPatient : CariumLink}
                            alt=""
                            style={{marginLeft: (showCircleCarium|| onClickCarium) ? '0px' : '5px'}}
                            height={(showCircleCarium|| onClickCarium) ? '23px' : '30px'}
                            className={(showCircleCarium|| onClickCarium) ? classes.circle : ''}
                        />
                    </div>
                    {openModal &&
                        <Dialog
                            open={openModal}
                            sx={{
                                '& .MuiDialog-paperFullWidth': {
                                    maxWidth: '1500px',
                                },
                            }}
                            fullWidth={true}
                            onClose={() => {
                                closeModal()
                            }}
                            className={classes.root}>
                            <DialogContent>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        borderBottom: '1px solid #DEE2E6',
                                    }}>
                                    <Typography className={classes.headerText}>
                                        Upload Behavior Rate Scale
                                    </Typography>
                                    <IconButton
                                        style={{ marginBottom: 18 }}
                                        onClick={() => {
                                            closeModal()
                                        }}>
                                        <CloseIcon style={{ color: '#303E4E' }} />
                                    </IconButton>
                                </div>
                                    <Grid container style={{ flex: 1 }}>
                                        <Grid item xs={4}>
                                            <Select
                                                resetData
                                                label="Scale Type"
                                                required
                                                name="role"
                                                placeholder="Select scale"
                                                value={formik.values.score_type}
                                                error={
                                                    formik.touched.score_type &&
                                                    Boolean(formik.errors.score_type)
                                                }
                                                helperText={
                                                    formik.touched.score_type && formik.errors.score_type
                                                }
                                                options={[{ key: 'GAD7', value: 'GAD7' },{ key: 'PHQ9', value: 'PHQ9' }]}
                                                selectProps={{
                                                    style: {
                                                        height: 40,
                                                    },
                                                }}
                                                onChange={(value) => {formik.setFieldValue('score_type', value)}}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{ flex: 1 }} direction="column">
                                        <BhFileUpload
                                         fileUpload={(file)=>{setFileUpload(file)}}
                                         fileId={(file)=>{formik.setFieldValue('file_name', file)}}
                                        />
                                        {formik.touched.file_name &&
                                         Boolean(formik.errors.file_name) &&
                                        <span className={classes.errorMessage}>Upload form is required!</span>}
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                        style={{padding: "2px", marginTop: fileUpdload.length > 0 ? "6px" : "60px",borderTop: '1px solid #DEE2E6',}}
                                        >
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                className={classes.button_cancel}
                                                onClick={() => {closeModal()}}
                                                >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LoadingButton
                                                loading={isSubmitting}
                                                variant="contained"
                                                className={classes.button_create}
                                                onClick={(e) => {
                                                    formik.handleSubmit(e)
                                                }}
                                                >
                                            Upload
                                            </LoadingButton>
                                        </Grid>
                                    </Grid>
                            </DialogContent>
                        </Dialog>
                    }
                </div>
            </>
        )
}

export default BhScaleUpload
