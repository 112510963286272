import {
    SET_LOADER,
    SET_ROLE_LOADER,
    SET_MASTER_DATA,
    SET_MACRO_DATA,
    SET_CLINICAL_NOTES_DETAILS,
    SET_PROVIDER_DETAIL,
    SET_LOGGED_IN_PROVIDER,
    SET_THEARPHY_MASTER,
    SET_ICD_CODES,
    RESET_NOTE,
    SET_MEDICATION_HISTORY,
    SET_ALLERGY_HISTORY,
    SET_DIRTY_SESSION,
    SET_CLINICAL_NOTES_TEMPLATE,
} from './actionTypes'
import { dirtySessions } from '../Behavioral/fields'

export const initialState = {
    dirtySessions,
    loading: false,
    master: {},
    macros: [],
    icdCodes: [],
    clinicalNotesDetail: null,
    providerDetail: {},
    loggedInProvider: {},
    thearphymaster: {},
    medicationHistory: [],
    allergyHistory: [],
    clinicalNoteTemplate: [],
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA:
            return { ...state, master: action.value }
        case SET_MACRO_DATA:
            return { ...state, macros: action.value }
        case SET_ICD_CODES:
            return { ...state, icdCodes: action.value }
        case SET_CLINICAL_NOTES_DETAILS:
            return {
                ...state,
                clinicalNotesDetail: action.value,
            }
        case SET_MEDICATION_HISTORY:
            return {
                ...state,
                medicationHistory: action.value,
            }
        case SET_ALLERGY_HISTORY:
            return {
                ...state,
                allergyHistory: action.value,
            }
        case SET_PROVIDER_DETAIL:
            return {
                ...state,
                providerDetail: action.value,
            }
        case SET_LOGGED_IN_PROVIDER:
            return {
                ...state,
                loggedInProvider: action.value,
            }
        case SET_LOADER:
            return { ...state, loading: action.value }
        case SET_ROLE_LOADER:
            return { ...state, loadingRole: action.value }
        case SET_THEARPHY_MASTER:
            return {
                ...state,
                thearphymaster: action.value,
            }
        case RESET_NOTE:
            return {
                ...initialState,
            }
        case SET_DIRTY_SESSION:
            const { key, value } = action.value
            const clinicalNoteTemplateClon = [...state.clinicalNoteTemplate]
            const index = clinicalNoteTemplateClon.findIndex((item) => item.componentId === key)
            if(index >= 0) clinicalNoteTemplateClon[index].status = value ? 'completed' : 'incomplete'
            return {
                ...state,
                clinicalNoteTemplate: clinicalNoteTemplateClon,
            }
        case SET_CLINICAL_NOTES_TEMPLATE:
            return {
                ...state,
                clinicalNoteTemplate: action.value,
            }
        default:
            return { ...state }
    }
}
