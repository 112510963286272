import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import React from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Routes from '../../../constants/routes'

const useStyles = makeStyles({
    root: { cursor: 'pointer' },
    card_cover: ({ firstItem, lastItem }) => ({
        marginLeft: 20,
        marginBottom: lastItem ? 0 : 8,
        marginTop: firstItem ? 0 : 8,
        padding: '16px 26px',
        border: '1px solid #E9ECF1',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        width: '-webkit-fill-available',
    }),
    header: {
        color: '#303E4E',
        marginBottom: 8,
    },
    description: {
        color: '#667689',
        fontSize: 13,
        margin: '16px 0',
    },
    date: {
        color: '#5571C6',
    },
    line: {
        borderRight: '1px solid #E9ECF1',
        position: 'relative',
        height: '100%',
    },
    circle: {
        fontSize: '12px !important',
        color: '#9CA9B8',
        position: 'absolute',
        top: 'calc(50% - 8px)',
        background: 'white',
        left: -6,
    },
})

const HistoryItem = ({
    firstItem,
    lastItem,
    type,
    date,
    id,
}) => {
    const classes = useStyles({ firstItem, lastItem })
    const history = useHistory()

    const handleOpenClinicalNote = () => {
        history.push(Routes.CLINICAL_NOTES.NEW_NOTE, { id })
    }

    return (
        <Grid
            className={classes.root}
            direction="column"
            container
            onClick={handleOpenClinicalNote}>
            <Grid item className={classes.line}>
                <CircleOutlinedIcon
                    className={classes.circle}
                />
            </Grid>
            <Grid className={classes.card_cover}>
                <Typography
                    variant="h6"
                    className={classes.header}>
                    {type}
                </Typography>
                {/* <Typography
                    variant="body1"
                    className={classes.description}>
                    Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard
                    dummy text ever since the 1500s, when an
                    unknown printer took a galley of type
                    and scrambled it to make a type specimen
                    book. It has survived not only five
                    centuries, but also the leap into
                    electronic typesetting, remaining
                    essentially unchanged. It was
                    popularised in the 1960s with the
                    release of Letraset sheets containing
                    Lorem Ipsum passages, and more recently
                    with desktop publishing software like
                    Aldus PageMaker including versions of
                    Lorem Ipsum.
                </Typography> */}

                <Grid container>
                    <CalendarMonthOutlinedIcon
                        style={{
                            marginRight: 8,
                            color: '#5571C6',
                            fontSize: '16px',
                        }}
                    />
                    <Typography
                        variant="body1"
                        className={classes.date}>
                        {moment(
                            new Date(date).toDateString(),
                        ).format('DD MMM YYYY') ||
                            '22 Jan 2022'}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HistoryItem
