import { Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useParams,useLocation } from 'react-router-dom'
import { fetchPDFData, fetchPDFMedicalData, _getClinicalNotesDetail } from '../api/services'
import { makeStyles } from '@material-ui/core/styles'
import PDFViewer from 'components/ClinicalNotes/reusable/PDFViewer'
import { clinical_note_master } from '../utils'
import { cgiMapper, devEduHistroyMapper, familyEnvMapper, interVationMapper, mapperFunction, sessionMapper } from '.'


const useStyles = makeStyles({
    root: {
        flex: 1,
        overflowY: 'auto',
        height: 'calc(100vh - 80px)',
    },
    icon_back: {
        marginRight: 8,
    },
    preview_container: {
        flex: 1,
        marginTop: 16,
        borderRadius: 4,
        border: '1px solid #e8e8e8',
    },
    status_tag: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        height: 32,
        borderRadius: 4,
    },
    preview_header: {
        padding: '14px 16px',
        borderBottom: '1px solid #e8e8e8',
    },
    preview_document: {
        background: '#E9ECF1',
        // padding: 24,
        flex: 1,
    },
    headerWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerLeft: {
        justifyContent: 'flex-start',
    },
})
const PreviousNote = () => {
    const classes = useStyles()
    const params = useParams()
    const location = useLocation()
    const [PDFFile, setPDFFile] = useState(null)
    const fetchClinicalNote = async () => {
        try {
            const clinicalNotesDetail = await _getClinicalNotesDetail(params.id)
            const previousClinicalNote = clinicalNotesDetail?.data?.previous_cn
            if (previousClinicalNote) {
                if(location?.pathname?.includes("/medical-clinical-note/")){
                    fetchPDFMedicalData(clinicalNotesDetail?.data?.previous_cn_id, {
                        clinicalNoteModel: previousClinicalNote.clinicalNoteModel,
                        clinicalNoteTemplate: previousClinicalNote.clinicalNoteTemplate,
                    }).then((response) => {
                        const url = URL.createObjectURL(response.data)
                        setPDFFile(url)
                    })
                }else{
                    const cloneCategoryNotes = _.cloneDeep(previousClinicalNote.clinicalNoteModel)
                    if (cloneCategoryNotes.system_reviews) {
                        const systemReview = mapperFunction(clinical_note_master.systemReviewMasters, cloneCategoryNotes.system_reviews.system_reviews_opt)
                        cloneCategoryNotes.system_reviews['system_reviews_opt'] = systemReview
                    }
                    if (cloneCategoryNotes?.dev_and_edu_history) {
                        const devEduHistry = devEduHistroyMapper(clinical_note_master.devEduHistoryMasters, cloneCategoryNotes.dev_and_edu_history)
                        cloneCategoryNotes.dev_and_edu_history = devEduHistry
                    }
                    if (cloneCategoryNotes?.family_history) {

                        if (!cloneCategoryNotes?.family_history?.psych_illness_history) {
                            cloneCategoryNotes.family_history.history_list = []
                        }
                    }
                    if (cloneCategoryNotes?.interven_utilized) {
                        const intervationMapperprepare = interVationMapper(clinical_note_master.interventionMasters, cloneCategoryNotes.interven_utilized.interventions)
                        cloneCategoryNotes.interven_utilized['interventions'] = intervationMapperprepare
                    }

                    if (cloneCategoryNotes?.session_focus) {
                        const sessionmapperPrepare = sessionMapper(clinical_note_master.sessionFocusMasters, cloneCategoryNotes.session_focus.session)
                        cloneCategoryNotes.session_focus['session'] = sessionmapperPrepare
                    }

                    if (cloneCategoryNotes?.clinical_global_impression) {
                        const cgi = cgiMapper(clinical_note_master.clinicalImpMasters, cloneCategoryNotes.clinical_global_impression)
                        cloneCategoryNotes.clinical_global_impression = cgi
                    }

                    if (cloneCategoryNotes?.family_env_strategy) {
                        const familyEnvMapperPrepare = familyEnvMapper(clinical_note_master.envStrategyMasters, cloneCategoryNotes?.family_env_strategy.fam_env_strategy)
                        cloneCategoryNotes.family_env_strategy['fam_env_strategy'] = familyEnvMapperPrepare
                    
                    }
                    fetchPDFData(clinicalNotesDetail?.data?.previous_cn_id, {
                        clinicalNoteModel: cloneCategoryNotes,
                        clinicalNoteTemplate: previousClinicalNote.clinicalNoteTemplate,
                    }).then((response) => {
                        const url = URL.createObjectURL(response.data)
                        setPDFFile(url)
                    })
                }
            }
        } catch (err) {
            console.log(err, 'ERROR')
        }
    }
    React.useEffect(() => {
        if (params.id) {
            fetchClinicalNote()
        }
    }, [params.id])
    return (
        <Grid className={classes.root} container direction="column">
            <Grid container className={classes.preview_container} direction="column">
                <Grid className={classes.headerWrap}>
                    <div className={classes.headerLeft}>
                        <Typography variant="h4" style={{ fontSize: 22 }}>
                            Previous Note
                        </Typography>
                    </div>
                    <div className={classes.headerRight}></div>
                </Grid>
                <Grid
                    container
                    className={classes.preview_document}
                    justifyContent="center"
                    alignItems="center">
                    <PDFViewer PDFFile={PDFFile} />
                </Grid>
            </Grid>
        </Grid>
    )
}
export default PreviousNote
