import { ClickAwayListener, Grid, makeStyles, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment from 'moment'
import React, { useEffect } from 'react'
import SelectEl from './Select'

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        top: 44,
        left: 0,
        background: '#FFFFFF',
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        padding: 16,
        zIndex: 1,
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
    label: {
        fontWeight: 500,
        marginBottom: 8,
        color: '#303E4E',
    },
}))

const hourOptions = [
    { key: '0', value: '12 AM' },
    { key: '1', value: '1 AM' },
    { key: '2', value: '2 AM' },
    { key: '3', value: '3 AM' },
    { key: '4', value: '4 AM' },
    { key: '5', value: '5 AM' },
    { key: '6', value: '6 AM' },
    { key: '7', value: '7 AM' },
    { key: '8', value: '8 AM' },
    { key: '9', value: '9 AM' },
    { key: '10', value: '10 AM' },
    { key: '11', value: '11 AM' },
    { key: '12', value: '12 PM' },
    { key: '13', value: '1 PM' },
    { key: '14', value: '2 PM' },
    { key: '15', value: '3 PM' },
    { key: '16', value: '4 PM' },
    { key: '17', value: '5 PM' },
    { key: '18', value: '6 PM' },
    { key: '19', value: '7 PM' },
    { key: '20', value: '8 PM' },
    { key: '21', value: '9 PM' },
    { key: '22', value: '10 PM' },
    { key: '23', value: '11 PM' },
]
const minuteOptions = [
    { key: '0', value: '00' },
    { key: '05', value: '05' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
    { key: '20', value: '20' },
    { key: '25', value: '25' },
    { key: '30', value: '30' },
    { key: '35', value: '35' },
    { key: '40', value: '40' },
    { key: '45', value: '45' },
    { key: '50', value: '50' },
    { key: '55', value: '55' },
]

function TimePickerInput({
    onChange,
    busySlots = [],
    appointmentModal,
    hiddenEndTime,
    values,
    error,
    helperText,
}) {
    const classes = useStyles()
    const [startTime, setStartTime] = React.useState({
        hour: '0',
        min: '0',
    })
    const [endTime, setEndTime] = React.useState({
        hour: '0',
        min: '0',
    })
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const loading = open && options.length === 0

    const onChangeStart = (e) => {
        const { name, value } = e.target
        setStartTime((startTimePrev) => ({
            ...startTimePrev,
            [name]: value,
        }))

        onChange &&
            onChange({
                startTime:
                    name === 'hour' ? `${value}:${startTime.min}` : `${startTime.hour}:${value}`,
                endTime: `${endTime.hour}:${endTime.min}`,
            })
    }

    const onChangeEnd = (e) => {
        const { name, value } = e.target
        setEndTime((startTimePrev) => ({
            ...startTimePrev,
            [name]: value,
        }))
        onChange &&
            onChange({
                startTime: `${startTime.hour}:${startTime.min}`,
                endTime: name === 'hour' ? `${value}:${endTime.min}` : `${endTime.hour}:${value}`,
            })
    }

    useEffect(() => {
        const { start, end } = values
        const startT = start.local()
        const endT = end.local()
        setStartTime({
            hour: startT.hours(),
            min: startT.minute() === 0 ? '0' : startT.minute() === 5 ? '05' : startT.minute(),
        })
        setEndTime({
            hour: endT.hours(),
            min: endT.minute() === 0 ? '0' : endT.minute() === 5 ? '05' : endT.minute(),
        })
    }, [values])

    const handleClickAway = () => {
        setOpen(false)
    }

    const onClickPreventDefault = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Grid container className={classes.container} onClick={onClickPreventDefault}>
                <Grid
                    container
                    className={classes.input_box}
                    justifyContent="space-between"
                    onClick={() => {
                        setOpen(!open)
                    }}
                    alignItems="center">
                    <Typography>{`${moment(`${startTime.hour}:${startTime.min}`, 'HH:mm').format(
                        'hh:mm A',
                    )} - ${moment(`${endTime.hour}:${endTime.min}`, 'HH:mm').format(
                        'hh:mm A',
                    )}`}</Typography>

                    {open ? (
                        <KeyboardArrowUpIcon
                            style={{
                                color: '#516983',
                                fontSize: 20,
                            }}
                        />
                    ) : (
                        <KeyboardArrowDownIcon
                            style={{
                                color: '#516983',
                                fontSize: 20,
                            }}
                        />
                    )}
                </Grid>
                {open && (
                    <Grid container className={classes.drop__down}>
                        <Typography className={classes.label}>Start time</Typography>
                        <Grid container item alignItems="center" spacing={4}>
                            <Grid item style={{ flex: 1 }}>
                                <SelectEl
                                    name="hour"
                                    placeholder="Select from below"
                                    value={startTime.hour}
                                    onChange={onChangeStart}
                                    // options={hourOptions.map(
                                    //     (item) => {
                                    //         busySlots.findIndex(
                                    //             (slot) => {
                                    //                 return (
                                    //                     slot.startHour ===
                                    //                     parseInt(
                                    //                         item.key,
                                    //                         10,
                                    //                     )
                                    //                 )
                                    //             },
                                    //         )

                                    //         return {
                                    //             ...item,
                                    //             isBusy:
                                    //                 busySlots.findIndex(
                                    //                     (
                                    //                         slot,
                                    //                     ) =>
                                    //                         slot.startHour ===
                                    //                         parseInt(
                                    //                             item.key,
                                    //                             10,
                                    //                         ),
                                    //                 ) >= 0,
                                    //         }
                                    //     },
                                    // )}
                                    options={hourOptions.map((item) => ({
                                        ...item,
                                        disabled:
                                            values.start.date() <= moment().date() &&
                                            parseInt(item.key, 10) < moment().hour(),
                                    }))}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                />
                            </Grid>
                            <Typography style={{ fontSize: 18 }}>:</Typography>
                            <Grid item style={{ flex: 1 }}>
                                <SelectEl
                                    name="min"
                                    placeholder="Select from below"
                                    value={startTime.min}
                                    options={minuteOptions.map((item) => ({
                                        ...item,
                                        disabled:
                                            parseInt(item.key, 10) <= moment().minute() &&
                                            values.start.date() <= moment().date() &&
                                            parseInt(startTime.hour, 10) <= moment().hour(),
                                        isBusy:
                                            busySlots.findIndex(
                                                (slot) =>
                                                    slot.startMinute === parseInt(item.key, 10),
                                            ) >= 0,
                                    }))}
                                    onChange={onChangeStart}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {!hiddenEndTime && (
                            <>
                                {' '}
                                <Typography
                                    className={classes.label}
                                    style={{
                                        marginTop: 16,
                                    }}>
                                    End time
                                </Typography>
                                <Grid container item alignItems="center" spacing={4}>
                                    <Grid item style={{ flex: 1 }}>
                                        <SelectEl
                                            name="hour"
                                            placeholder="Select from below"
                                            value={endTime.hour}
                                            options={hourOptions.map((item) => ({
                                                ...item,
                                                // disabled:
                                                //     values.end.date() <= moment().date() &&
                                                //     parseInt(item.key, 10) < moment().hour(),
                                            }))}
                                            onChange={onChangeEnd}
                                            selectProps={{
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Typography
                                        style={{
                                            fontSize: 18,
                                        }}>
                                        :
                                    </Typography>
                                    <Grid item style={{ flex: 1 }}>
                                        <SelectEl
                                            name="min"
                                            placeholder="Select from below"
                                            value={endTime.min}
                                            options={minuteOptions.map((item) => ({
                                                ...item,
                                                // disabled:
                                                //     parseInt(item.key, 10) <= moment().minute() &&
                                                //     values.end.date() <= moment().date() &&
                                                //     parseInt(endTime.hour, 10) <= moment().hour(),
                                                isBusy:
                                                    busySlots.findIndex(
                                                        (slot) =>
                                                            slot.endMinute ===
                                                            parseInt(item.key, 10),
                                                    ) >= 0,
                                            }))}
                                            onChange={onChangeEnd}
                                            selectProps={{
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
                {error && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                        {helperText}
                    </p>
                )}
            </Grid>
        </ClickAwayListener>
    )
}

export default TimePickerInput
