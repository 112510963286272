import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import SearchIcon from 'assests/icons/search_icon.svg'
import { Autocomplete } from '@mui/material'
import {getLocationById, getLocations } from 'services/Calendar'
import { useSelector } from 'react-redux'

const renderTextField = ({ name, value, placeholder, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchLocationInputSchedule = (props) => {
    const { disabled, handleAddRow, error, helperText, name, placeholder } = props
    const locationId = props.value || ''
    const isEdit = props.edit || ''
    const [value, setValue] = useState()
    const providerLocationIds = useSelector(
        (state) => state.schedule.providerLocation
    )
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false)
    const [firstTime, setFirstTime]= useState(false)

    useEffect(async () => {
        if (locationId && isEdit && !firstTime) {
            const response = await getLocationById(locationId)
            if (response?.data) {
                const { name } = response?.data
                setValue(locationId || '')
                setInputValue(name || '')
                setFirstTime(true)
            }
        }else if(!locationId){
            setValue('')
            setInputValue('')
        }
    }, [locationId])

    async function getLocationsData() {
        try {
            if(providerLocationIds.length){
                setLoading(true)
                setOptions([])
                const response = await getLocations({
                    page: 1,
                    size: 10000,
                })
                
                const details = providerLocationIds.map(id => {
                    const foundItem = response.data?.data.find(item => item.id === id);
                    if (foundItem) {
                        return {
                            id: foundItem.id,
                            value: foundItem.name,
                            label: foundItem.name,
                            key: foundItem.id
                        };
                    }
                    return null
                }).filter(item => item !== null)
                setOptions(details)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        const abortController = new AbortController()
        let active = true
        ;(async () => {
            if (active) {
                if(providerLocationIds.length > 0){
                    await getLocationsData()
                }else{
                    setOptions([])
                }
            }
        })()
        return () => {
            active = false
            abortController.abort()
        }
    }, [providerLocationIds])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
                '& .MuiFormHelperText-root': {
                    textTransform: 'inherit',
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                selectOnFocus
                clearIcon={false}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                inputValue={inputValue}
                value={value}
                open={open}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            <span
                                style={{
                                    marginRight: '3px',
                                }}
                                className="hightlight">
                                {option.label}
                            </span>
                            -
                            <span
                                className="hightlight"
                                style={{
                                    color: 'rgb(85, 113, 198)',
                                    marginLeft: '3px',
                                }}>
                                {option.id}
                            </span>
                        </li>
                    )
                }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    setOpen(false)
                    handleAddRow(newValue)
                    setValue(newValue?.id)
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return
                    }
                    setInputValue(newInputValue)
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: placeholder || 'Search...',
                        props: {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                    </>
                                ),
                            },
                            error,
                            helperText,
                            name,
                        },
                    })
                }
            />
        </Box>
    )
}

export default SearchLocationInputSchedule
