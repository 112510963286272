import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Grid,
    makeStyles,
    Typography,
    TextField,
    Button,
    IconButton,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import moment from 'moment'
import AddIcon from '@mui/icons-material/Add'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import AddendumItem from './AddendumItem'
import { materialTheme } from '../../../utilities/calendar/style'
import CreateAddendumModal from './CreateAddendumModal'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import {
    createAddendumByClinicalNote,
    getAllAddendumByClinicalNote,
} from '../../../store/actions/clinicalNotes'

import { Button as ButtonCustom } from 'components/custom'
import PlusIcon from '../../../assests/images/plus_icon.svg'
import { CircularProgress } from '@material-ui/core'
import { DateTimePickerEl } from '../../../shared/elements'
import './styles.scss'
import CalendarIcon from '../../../assests/icons/CalendarIcon'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'

const useStyles = makeStyles({
    root: {
        padding: '16px 24px',
        flex: 1,
    },
    iconBack: {
        marginRight: 8,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    addendumContainer: {},
    headerWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '28px',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '22px',
        fontWeight: 500,
        color: '#303E4E',
        marginTop: '10px',
    },
    selectDate: {
        width: '190px',
        height: '40px',
        marginRight: '8px',
        padding: '10.5px 14px',
    },
    addButtonWrapper: {
        marginLeft: '10px',
    },
    addButton: {
        width: '120px',
        height: '40px',
        fontWeight: '400',
        background: '#14AE5C',
        borderRadius: '8px',
        fontSize: '15px',
    },
    clearButton: {
        height: '40px',
        fontWeight: '400',
        background: '#a1a1a1',
        borderRadius: '8px',
        fontSize: '15px',
    },
    leftSide: {
        width: '180px',
        justifyContent: 'flex-start',
    },
    rightSide: {
        width: '320px',
        justifyContent: 'flex-end',
        display: 'flex',
        alignItems: 'center',
    },
    pickerWrapper: {
        width: '190px',
        height: '40px',
    },
    plusIcon: {
        marginRight: '8px',
    },
    themeDate: {},
})

const Addendum = ({
    clinicalNoteData,
    onChangeActiveView,
    value,
    categoryNotes
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [isOpenCreateModal, setIsOpenCreateModal] =
        useState(false)
    const [date, setDate] = useState(
        moment().format().split('T')[0],
    )
    const addendumList = useSelector(
        (state) => state.clinicalNotes.addendumList,
    )
    const isLoading = useSelector(
        (state) =>
            state.clinicalNotes.isLoadingGetListAddendum,
    )

    const onOpenModalCreate = () => {
        setIsOpenCreateModal(true)
    }

    const handleDateChange = (value) => {
        const dateFilter = moment(value)
            .format()
            .split('T')[0]
        setDate(dateFilter)

        dispatch(
            getAllAddendumByClinicalNote({
                clinicalNoteId: clinicalNoteData?.id,
                dateFilter,
            }),
        )
    }
    const handleClearFilterDate = () => {
        const dateFilter = moment().format().split('T')[0]
        setDate(dateFilter)

        dispatch(
            getAllAddendumByClinicalNote({
                clinicalNoteId: clinicalNoteData?.id,
                dateFilter,
            }),
        )
    }

    useEffect(() => {
        dispatch(
            getAllAddendumByClinicalNote({
                clinicalNoteId:
                    clinicalNoteData?.id || '422',
            }),
        )
    }, [])

    return (
        <>
            {isLoading && (
                <>
                    <div className="loading-addendum"></div>
                    <div className="loader">
                        <SpinnerIcon />
                    </div>
                </>
            )}
            <Grid
                className={classes.root}
                direction="column">
                <Grid
                    item
                    container
                    alignItems="center"
                    style={{ marginBottom: 12 }}>
                    <IconButton
                        onClick={() =>
                            onChangeActiveView('preview')
                        }
                        className={classes.iconBack}>
                        <KeyboardBackspaceOutlinedIcon
                            style={{ fontSize: 16 }}
                        />
                    </IconButton>
                    <Typography
                        variant="h4"
                        className={classes.back__btn}>
                        Back
                    </Typography>
                </Grid>
                <Grid className={classes.addendumContainer}>
                    <Grid className={classes.headerWrapper}>
                        <Grid className={classes.leftSide}>
                            <Typography
                                className={
                                    classes.headerText
                                }>
                                Addendum List
                            </Typography>
                        </Grid>
                        <Grid className={classes.rightSide}>
                            <DateTimePickerEl
                                className="select-date"
                                fullWidth
                                emptyLabel="Select Date"
                                name="date-picker"
                                format="DD-MM-YYYY"
                                placeholder="Select Date"
                                value={date}
                                onChange={handleDateChange}
                                views={['date']}
                                InputProps={{
                                    readOnly: true,
                                    classes: {
                                        notchedOutline:
                                            classes.notchedOutline,
                                        root: classes.date_input,
                                    },
                                }}
                                keyboardIcon={
                                    <CalendarIcon />
                                }
                            />
                            {date !==
                                moment()
                                    .format()
                                    .split('T')[0] && (
                                <div
                                    className={
                                        classes.addButtonWrapper
                                    }>
                                    <Button
                                        className={
                                            classes.clearButton
                                        }
                                        onClick={
                                            handleClearFilterDate
                                        }>
                                        Clear
                                    </Button>
                                </div>
                            )}
                            <div
                                className={
                                    classes.addButtonWrapper
                                }>
                                <ButtonCustom
                                    tooltip="Add New Addendum"
                                    className={
                                        classes.addButton
                                    }
                                    onClick={
                                        onOpenModalCreate
                                    }>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        {addendumList &&
                            addendumList.map(
                                (item, index) => (
                                    <AddendumItem
                                        key={index}
                                        dataInfo={item}
                                    />
                                ),
                            )}
                    </Grid>
                    <CreateAddendumModal
                        newValue={value}
                        isOpen={isOpenCreateModal}
                        setIsOpen={setIsOpenCreateModal}
                        clinicalNoteData={clinicalNoteData}
                        categoryNotes={categoryNotes}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Addendum
