import moment from 'moment';
import {
    PAGE_CHANGE,
    WORKLIST_DATE_FILTER,
    WORKLIST_FILTER_FAILURE,
    WORKLIST_FILTER_LOADING,
    WORKLIST_FILTER_SUCCESS,
    WORKLIST_RESET_FILTER,
    WORKLIST_SEARCH,
    WORKLIST_STATUS_FILTER,
    WORKLIST_TYPE_FILTER,
    WORKLIST_MODAL_OPEN,
    WORKLIST_MODAL_CLOSE,
    WORKLIST_MARK_STATUS,
    WORKLIST_FILTER_SORT,
} from './constant';
import { RequestTypeMapper } from './utils';
import {
    sortAscending,
    sortDescending,
} from '../../shared/utils';

export const initialState = {
    worklist: {
        data: [],
        isLoading: false,
        isLoaded: false,
        isError: false,
        totalRecords: 0,
        filters: {
            requestDate: null,
            requestType: '',
            status: '',
            searchQuery: null,
        },
        sort: {
            created_at: 'asc',
            reason: 'asc',
            user_name: 'asc',
            status: 'asc',
        },
        selected: null,
    },
    modal: {
        opened: false,
    },
    pagination: {
        page: 1,
        limit: 10,
    },
};

export function reducer(state, action) {
    switch (action.type) {
        case WORKLIST_FILTER_LOADING:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    isLoading: true,
                },
            };

        case WORKLIST_FILTER_SUCCESS:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data.adminWorkList.map(
                        (item) => {
                            return {
                                ...item,
                                reason: RequestTypeMapper(
                                    item.reason,
                                ),
                            };
                        },
                    ),
                    totalRecords: action.data.totalCount,
                },
            };

        case WORKLIST_FILTER_FAILURE:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    isError: true,
                    isLoaded: true,
                    isLoading: false,
                    data: [],
                    totalRecords: 0,
                },
            };

        case PAGE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            };

        case WORKLIST_SEARCH:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    filters: {
                        ...state.worklist.filters,
                        searchQuery: action.value,
                    },
                },
            };

        case WORKLIST_DATE_FILTER:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    filters: {
                        ...state.worklist.filters,
                        requestDate: moment(
                            action.value,
                        ).format('MM/DD/YYYY'),
                    },
                },
            };

        case WORKLIST_TYPE_FILTER:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    filters: {
                        ...state.worklist.filters,
                        requestType: action.value,
                    },
                },
            };

        case WORKLIST_STATUS_FILTER:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    filters: {
                        ...state.worklist.filters,
                        status: action.value,
                    },
                },
            };

        case WORKLIST_RESET_FILTER:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    filters: {
                        requestDate: null,
                        requestType: '',
                        status: '',
                        searchQuery: '',
                    },
                },
            };

        case WORKLIST_MODAL_OPEN:
            return {
                ...state,
                modal: { ...state.modal, opened: true },
                worklist: {
                    ...state.worklist,
                    selected: action.selected,
                },
            };

        case WORKLIST_MODAL_CLOSE:
            return {
                ...state,
                modal: { ...state.modal, opened: false },
                worklist: {
                    ...state.worklist,
                    selected: null,
                },
            };

        case WORKLIST_MARK_STATUS:
            return {
                ...state,
                modal: { ...state.modal, opened: false },
                worklist: {
                    ...state.worklist,
                    data: state.worklist.data.map(
                        (item) => {
                            if (
                                item.id ===
                                state.worklist.selected.id
                            ) {
                                return {
                                    ...item,
                                    status: action.data
                                        .status,
                                };
                            }
                            return item;
                        },
                    ),
                    selected: null,
                },
            };

        case WORKLIST_FILTER_SORT:
            return {
                ...state,
                worklist: {
                    ...state.worklist,
                    sort: {
                        ...state.worklist.sort,
                        [action.value.field]:
                            state.worklist.sort[
                                action.value.field
                            ] === 'asc'
                                ? 'desc'
                                : 'asc',
                    },
                    data:
                        state.worklist.sort[
                            action.value.field
                        ] === 'asc'
                            ? sortAscending(
                                  state.worklist.data,
                                  action.value.field,
                              )
                            : sortDescending(
                                  state.worklist.data,
                                  action.value.field,
                              ),
                },
            };

        default:
            return state;
    }
}
