import React from 'react'
import {
    Typography,
    Grid,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextWithMacro from '../reusable/TextWithMacro'

import { FieldArray } from 'formik'

const useStyles = makeStyles({
    subHeadingText: {
        fontSize: 14,
        marginBottom: 20,
    },
})

const PlanAppointment = ({
    master,
    values,
    onChange,
    macros,
    read_only,
}) => {
    const classes = useStyles()

    return (
        <Grid container justifyContent="space-between">
            <Grid item xs={12}>
                <Typography
                    className={classes.subHeadingText}>
                    This session would be considered?
                </Typography>
                <FieldArray
                    name="plan_next_appointment.plan_next_opt"
                    render={(helpers) => (
                        <FormGroup>
                            {master?.map((i, key) => (
                                <FormControlLabel
                                    key={key}
                                    label={i.name}
                                    name={i.name}
                                    value={i._id}
                                    control={
                                        <Checkbox
                                            checked={values.plan_next_opt.includes(
                                                i._id,
                                            )}
                                            color="primary"
                                            disabled={
                                                read_only
                                            }
                                        />
                                    }
                                    onChange={(e) => {
                                        if (
                                            e.target.checked
                                        ) {
                                            helpers.push(
                                                i._id,
                                            )
                                        } else {
                                            const idx =
                                                values.plan_next_opt.indexOf(
                                                    i._id,
                                                )
                                            helpers.remove(
                                                idx,
                                            )
                                        }
                                    }}
                                />
                            ))}
                        </FormGroup>
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <TextWithMacro
                    rows={12}
                    macros={macros}
                    onChange={onChange}
                    values={values?.narration}
                    name="plan_next_appointment.narration"
                    isDisabled={read_only}
                />
            </Grid>
        </Grid>
    )
}

export default PlanAppointment
