import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Grid, Button, Divider, Popover, IconButton, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close';
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import moment from 'moment'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { INITIAL_VALUES, NEW_PROBLEM_STATUS } from './schema'
import { deleteMedicalProblem, getMedicalProblem, postMedicalProblem, putMedicalProblem } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import { Loading } from 'shared/elements/Loading'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { formatDateTime } from 'utilities/formats'
import TrashIcon from 'assests/icons/TrashIcon';
import ErrorIcon from 'assests/icons/error-icon.svg'
const useStyles = makeStyles({
    header:{
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        color: "#303E4E"
    },
    loadingButton:{
        color:"white",
        width: "65px",
        height: "30px",
        padding: "6px 12px"
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        margin: "4px 0"
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
    },
    button_cancel:{
        background: '#fff !important',
        color: '#303E4E !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    button_create_form: {
        background: '#5571C6 !important',
        color: '#fff !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    }

})


const MedicalProblemForm = ({
    handleCloseStaffNote,
    patientId,
    problemId,
    onClose,
    afterActionOk,
    encounter_id
}) => {
    const { patientInfo } = usePatient(patientId)
    const classes = useStyles()
    const notification = useNotification()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const validationSchema = yup.object({
        encounter_id: yup.string(),
        time_diagnosis: yup.date(),
        time_onset: yup
            .date()
            .nullable()
            .typeError('Please input valid Onset of condition')
            .min(moment(patientInfo.dob), `Onset of condition must be after Patient's birth date`)
            .max(moment(), 'Onset of condition must be before current date'),
        time_changed: yup.date(),
        note: yup.string(),
        icd_code: yup.object({
            code: yup.string().required('ICD 10 Code is required!'),
        }),
        status: yup.object().shape({
            code: yup.string().required('Status is required!'),
        }),
    })
    const getProblemDetail = async (patientId, problemId) => {
        try {
            const resp = await getMedicalProblem(patientId, problemId)

            if (resp.data) {
                setInitData({
                    ...INITIAL_VALUES,
                    ...resp.data,
                })
            }
        } catch (error) {
            notification(
                error?.data?.message || 'Cannot get medical problem details',
                'error',
            )
        }
    }
    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(true)
        Promise.allSettled([
            problemId
                ? getProblemDetail(patientId, problemId)
                : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])
    const handleSubmit = async (payload) => {
        setIsSubmitting(true)
        const postData = {
            ...payload,
            encounter_id: encounter_id,
            time_onset: formatDateTime(payload.time_onset, 'YYYY-MM-DD'),
        }

        try {
            if (problemId) {
                await putMedicalProblem(patientId, problemId, postData)
            } else {
                await postMedicalProblem(patientId, postData)
            }
            setIsSubmitting(false)

            const msg = problemId
                ? `Problem updated successfully!`
                : `Problem created successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')
            onClose(true)
            afterActionOk()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                error?.data?.message ||
                (problemId
                    ? `Problem updated unsuccessfully`
                    : `Problem created unsuccessfully`)
            notification(errMsg, 'error')
        }
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })
    return (
        <Grid container>
            <Loading visible={dataLoading} />
            <Grid
                item
                style={{ overflowY: 'hidden', padding: ' 30px', width: '424px', height: '526px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px'
                    }}>
                    <Typography style={{ fontSize: "27px", fontWeight: 500, color: 'black' }}className={classes.HeadingColor}>
                        {problemId ? 'Update Problem' : 'Add Problem'}
                    </Typography>
                </div>
                <Grid item xs={12} style={{marginTop:'4px'}}>
                    <FormLabel component="p" className={classes.formLabel} >
                        Medical Problems<span style={{ color: '#E81A1A' }}>*</span>
                    </FormLabel>
                    <SearchICD10Code
                        required
                        name="icd_code"
                        placeholder="Search medical problem"
                        value={formik.values?.icd_code}
                        error={
                            formik.touched.icd_code?.code && Boolean(formik.errors.icd_code?.code)
                        }
                        helperText={formik.touched.icd_code?.code && formik.errors.icd_code?.code}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue('problem', value)
                                formik.setFieldValue('icd_code', value)
                            }else{
                                formik.setFieldValue('problem', '')
                                formik.setFieldValue('icd_code', '')
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop:'15px'}}>
                    <FormLabel component="p" className={classes.formLabel}>
                        ICD 10 Code<span style={{ color: '#E81A1A' }}>*</span>
                    </FormLabel>
                    <SearchICD10Code
                        name="icd_code_display"
                        disabled
                        placeholder="Search code"
                        showICDCode={true}
                        value={{
                            code: formik.values?.icd_code?.code,
                            display: formik.values?.icd_code?.code,
                            highlightText: formik.values?.icd_code?.code,
                            key: formik.values?.icd_code?.key,
                        }}
                        error={
                            formik.touched.icd_code?.code && Boolean(formik.errors.icd_code?.code)
                        }
                        helperText={formik.touched.icd_code?.code && formik.errors.icd_code?.code}
                        onChange={(value) => {
                            if (value) {
                                formik.setFieldValue('problem', value)
                                formik.setFieldValue('icd_code', value)
                            }else{
                                formik.setFieldValue('problem', '')
                                formik.setFieldValue('icd_code', '')
                            }
                        }}
                    />
                </Grid>
                <Grid container item alignItems="flex-start" spacing={2} style={{marginTop:'7px'}}>
                    <Grid item xs={6}>
                        <FormLabel component="p" className={classes.formLabel}>
                            Status<span style={{ color: '#E81A1A' }}>*</span>
                        </FormLabel>
                        <Select
                            // label="Status"
                            name="status"
                            required
                            placeholder="Select status"
                            value={formik.values.status.code}
                            error={
                                formik.touched.status?.code && Boolean(formik.errors.status?.code)
                            }
                            helperText={formik.touched.status?.code && formik.errors.status?.code}
                            options={NEW_PROBLEM_STATUS}
                            onChange={(value) => {
                                if (initData.status?.code !== 'Deleted')
                                    formik.setFieldValue('status', {
                                        code: value,
                                    })
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <FormLabel
                            component="p"
                            className={classes.formLabel}
                            style={{ textTransform: 'none' }}>
                            Onset of condition
                        </FormLabel>
                        <DatePicker
                            // label="Onset of Condition"
                            maxDate={moment()}
                            minDate={moment(patientInfo.dob)}
                            error={formik.touched.time_onset && Boolean(formik.errors.time_onset)}
                            helperText={formik.touched.time_onset && formik.errors.time_onset}
                            name="time_onset"
                            value={formik.values.time_onset}
                            onChange={(value) => {
                                formik.setFieldValue('time_onset', value)
                            }}
                            notDefaultValue
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginTop:'7px'}}>
                    <FormLabel component="p" className={classes.formLabel}>
                        Note
                    </FormLabel>
                    <TextBox
                        // label="Note"
                        name="note"
                        value={formik.values.note}
                        multiline
                        variant="outlined"
                        minRows={4}
                        placeholder="placeholder"
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '90px !important',
                            },
                            ' & .MuiInputBase-input': {
                                height: '60px !important',
                            },
                        }}
                        onChange={(value) => formik.setFieldValue('note', value)}
                    />
                </Grid>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                    }}>
                        
                        <div style={{ height: "", marginTop: "17px", marginBottom: "5px", display: "flex", justifyContent:'flex-end', gap: 10 }}>
                            <LoadingButton
                                loadingPosition="start"
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={(event) => {
                                    onClose(true)
                                }}>
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                variant="contained"
                                className={classes.button_create_form}
                                onClick={(event) => {
                                    formik.handleSubmit()
                                }}>
                                {problemId ? 'Update problem' : 'Add problem'}
                            </LoadingButton>
                        </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default MedicalProblemForm
