import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
} from '@mui/material'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles(() => ({
    wrapSelectMuntil: {
        // overflow: 'hidden',
        '& *': {
            boxSizing: 'border-box',
        },
        '& .MuiFormControl-root .MuiFormLabel-root': {
            top: '-10px !important'
        }
    },
    label: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#303E4E',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '15px',
        fontFamily:'Rubik',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: 'rgb(166, 172, 179)',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '40px',
        },
    },
    box_options: {
        padding: '15px 0',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    list_options: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
    chips: {
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 6,
    },
    chip: {
        background: '#E9ECEF',
        borderRadius: 99,
        margin: '4px 8px 4px 0',
    },
}))

const MultipleSelect = (props) => {
    const {
        options,
        value,
        handleChange,
        label,
        style,
        onOpen,
        sx,
        loadingOptions,
        disabled
    } = props
    const classes = useStyles()

    return (
        <Box sx={sx} className={classes.wrapSelectMuntil}>
            {label && (
                <Box component={'p'} className={classes.label}>
                    {label}
                </Box>
            )}
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                    <InputLabel shrink={false} id="demo-multiple-checkbox-label" className={classes.placeholder}>
                        {'Select outcome'}
                    </InputLabel>
                <Select
                    style={style}
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={[]}
                    placeholder=""
                    disabled={disabled}
                    // onChange={(e) => {
                    //     console.log(e)
                    // }}
                    onOpen={() => onOpen?.()}
                    IconComponent={KeyboardArrowDown}>
                    <Box className={classes.box_options}>
                        <Box className={classes.list_options}>
                            {loadingOptions ? (
                                <Box className={classes.box_option}>
                                    <Box>Loading Options ...</Box>
                                </Box>
                            ) : (
                                <>
                                    {!options.length ? (
                                        <Box className={classes.box_option}>
                                            <Box>No Options ...</Box>
                                        </Box>
                                    ) : (
                                        options.map((option) => {
                                            const check = value?.find(
                                                (item) => item.id === option.id,
                                            )
                                            return (
                                                <Box
                                                    key={option.id}
                                                    className={classes.box_option}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={check ? true : false}
                                                                onChange={(e) => {
                                                                    handleChange(
                                                                        option,
                                                                        e.target.checked,
                                                                    )
                                                                }}
                                                                name={option.name}
                                                            />
                                                        }
                                                        label={
                                                            <li>
                                                                <span
                                                                    style={{
                                                                        marginRight: '3px',
                                                                    }}>
                                                                    {option.name}
                                                                </span>
                                                            </li>
                                                        }
                                                    />
                                                </Box>
                                            )
                                        })
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    )
}

export default React.memo(MultipleSelect)
