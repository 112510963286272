import {
    Grid,
    Typography,
    Button,
    Box,
    FormControl,
    TextField,
    InputLabel,
    FormLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tms2uLogo from '../../assests/icons/Tms2uLogo'
import shutterstock from '../../assests/images/shutterstock_13160805981.png'
import GoogleAuthenticator from '../../assests/images/GoogleAuthenticator.png'
import OTPCode from '../../assests/images/OTPCode.png'
import clsx from 'clsx'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import TickCircle from '../../assests/icons/TickCircle'
import { Formik } from 'formik'
import IconPassword from '../../assests/images/iconPassword.png'
import IconDisablePassword from '../../assests/images/iconDisablePassword.png'
import './style.css'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${shutterstock})`,
        backgroundSize: 'cover',
    },
    title: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '140%',
        marginTop: 5,
    },
    selected: {
        border: '1px solid #5571C6',
    },
    otpInput: {
        marginTop: 32,
    },
    InputLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
    },
}))

const AuthenticationSteps = {
    AUTHENTICATION_TYPE: 'AUTHENTICATION_TYPE',
    PHONE_NUMBER: 'PHONE_NUMBER',
    OTP_CODE: 'OTP_CODE',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    COMPLETED: 'COMPLETED',
}

export default function VerifyProfile() {
    const classes = useStyles()
    const history = useHistory()

    const [showCurrentPass, setShowCurrentPass] =
        useState(false)
    const [showNewPass, setShowNewPass] = useState(false)
    const [showConfirmPass, setShowConfirmPass] =
        useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [otp, setOtp] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [currentStep, setCurrentStep] = useState(
        AuthenticationSteps.AUTHENTICATION_TYPE,
    )
    const [progress, setProgress] = React.useState(25)

    const handleNextStep = () => {
        if (
            currentStep ===
            AuthenticationSteps.AUTHENTICATION_TYPE
        ) {
            setCurrentStep(AuthenticationSteps.PHONE_NUMBER)
            setProgress(50)
            return
        }

        if (
            currentStep === AuthenticationSteps.PHONE_NUMBER
        ) {
            setCurrentStep(AuthenticationSteps.OTP_CODE)
            setProgress(75)
            return
        }

        if (currentStep === AuthenticationSteps.OTP_CODE) {
            setCurrentStep(
                AuthenticationSteps.CHANGE_PASSWORD,
            )
            setProgress(100)
            return
        }

        if (
            currentStep ===
            AuthenticationSteps.CHANGE_PASSWORD
        ) {
            setCurrentStep(AuthenticationSteps.COMPLETED)
        }
    }

    const renderCurrentStepText = () => {
        if (
            currentStep ===
            AuthenticationSteps.AUTHENTICATION_TYPE
        ) {
            return 'Authentication Type'
        }

        if (
            currentStep === AuthenticationSteps.PHONE_NUMBER
        ) {
            return 'Phone number'
        }

        if (currentStep === AuthenticationSteps.OTP_CODE) {
            return 'OTP Code'
        }

        if (
            currentStep ===
            AuthenticationSteps.CHANGE_PASSWORD
        ) {
            return 'Change Password'
        }

        return ''
    }

    const handleOtpChange = (newOtp) => {
        setOtp(newOtp)
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ minHeight: '100vh' }}>
            <Box container direction="column">
                {currentStep !==
                    AuthenticationSteps.COMPLETED && (
                    <Box sx={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                history.push('/')
                            }}
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            style={{
                                backgroundColor:
                                    'transparent',
                                marginBottom: 5,
                                padding: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: 'Rubik',
                                textTransform: 'none',
                            }}>
                            Go to main page
                        </Button>
                    </Box>
                )}

                {currentStep !==
                    AuthenticationSteps.COMPLETED && (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    </Box>
                )}

                <Box
                    style={{
                        padding: 50,
                    }}
                    container
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: 700,
                        backgroundColor: '#FFFFFF',
                    }}>
                    <Tms2uLogo> </Tms2uLogo>
                    <Typography className={classes.title}>
                        {renderCurrentStepText()}
                    </Typography>
                    {currentStep ===
                        AuthenticationSteps.AUTHENTICATION_TYPE && (
                        <Grid
                            container
                            direction="colum"
                            justifyContent="space-between"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Box
                                sx={{
                                    width: 338,
                                    background: '#F4F6F9',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                    borderRadius: 16,
                                    border: 'solid 1px rgb(244, 246, 249)',
                                }}
                                className={clsx({
                                    [classes.selected]:
                                        selectedItem === 1,
                                })}
                                onClick={() =>
                                    setSelectedItem(1)
                                }>
                                <img
                                    src={OTPCode}
                                    alt="OTPCode"
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 338,
                                    background: '#F4F6F9',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                    borderRadius: 16,
                                    border: 'solid 1px rgb(244, 246, 249)',
                                }}
                                className={clsx({
                                    [classes.selected]:
                                        selectedItem === 2,
                                })}
                                onClick={() =>
                                    setSelectedItem(2)
                                }>
                                <img
                                    src={
                                        GoogleAuthenticator
                                    }
                                    alt="GoogleAuthenticator"
                                />
                                <Typography
                                    style={{
                                        marginTop: 35,
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: 19,
                                        lineHeight: '160%',
                                        textAlign: 'center',
                                    }}>
                                    Google Authentication
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.PHONE_NUMBER && (
                        <Grid
                            direction="colum"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: '14px',
                                    lineHeight: '140%',
                                    color: '#303E4E',
                                }}>
                                Phone Number
                            </InputLabel>
                            <TextField
                                inputProps={{
                                    inputMode: 'numeric',
                                    pattern: '[0-9]*',
                                }}
                                placeholder={
                                    'Enter phone number'
                                }
                                onChange={(e) => {
                                    setPhoneNumber(
                                        e.target.value,
                                    )
                                }}
                            />
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.OTP_CODE && (
                        <Grid
                            direction="colum"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Typography>
                                {`The OTP verification code is
                                  sent to your phone number
                                  ${phoneNumber}. Please check
                                  and enter the OTP for
                                  authentication.`}
                            </Typography>
                            <OtpInput
                                className={classes.otpInput}
                                placeholder={'000000'}
                                inputStyle="inputStyle"
                                numInputs={6}
                                isDisabled={false}
                                hasErrored={false}
                                errorStyle="error"
                                onChange={handleOtpChange}
                                separator={<span> </span>}
                                isInputNum={true}
                                isInputSecure={false}
                                shouldAutoFocus={false}
                                value={otp}
                            />
                            <Typography
                                style={{
                                    marginTop: 16,
                                }}
                                color={'error'}>
                                Wrong authentication code.
                                Please try again.
                            </Typography>
                            <Box
                                style={{
                                    marginTop: 32,
                                    display: 'flex',
                                    direction: 'row',
                                    justifyContent:
                                        'space-between',
                                }}>
                                <Button
                                    onClick={() => {}}
                                    startIcon={
                                        <CachedIcon
                                            style={{
                                                animation:
                                                    'spin 2s linear infinite',
                                                animationIterationCount:
                                                    'infinite',
                                                animationDirection:
                                                    'reverse',
                                            }}
                                        />
                                    }
                                    size="small"
                                    style={{
                                        backgroundColor:
                                            'transparent',
                                        padding: 0,
                                        fontSize: 16,
                                        fontWeight: 400,
                                        fontFamily: 'Rubik',
                                        textTransform:
                                            'none',
                                        color: '#D9DDE3',
                                        lineHeight: '140%',
                                    }}>
                                    Resend OTP
                                </Button>
                                <Typography
                                    style={{
                                        fontSize: 16,
                                        color: '#303E4E',
                                    }}>
                                    120s
                                </Typography>
                            </Box>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.CHANGE_PASSWORD && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Formik
                                initialValues={{
                                    old_password: null,
                                    current_password: null,
                                    new_password: null,
                                }}>
                                {({
                                    values,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Grid
                                            container
                                            item
                                            justifyContent="space-between"
                                            direction="column">
                                            <Grid
                                                container
                                                item
                                                spacing={3}
                                                className="modal-spacer"
                                                style={{
                                                    marginTop: 24,
                                                }}>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <FormLabel
                                                        className={
                                                            classes.InputLabel
                                                        }
                                                        component="p">
                                                        Current
                                                        Password
                                                        *
                                                    </FormLabel>
                                                    <Box
                                                        sx={{
                                                            '& .MuiOutlinedInput-root':
                                                                {
                                                                    height: 'auto',
                                                                },
                                                        }}>
                                                        <TextField
                                                            id="current_password"
                                                            name="current_password"
                                                            variant="outlined"
                                                            value={
                                                                values.current_password
                                                            }
                                                            fullWidth
                                                            placeholder="Enter password"
                                                            type={
                                                                showCurrentPass
                                                                    ? 'text'
                                                                    : 'password'
                                                            }
                                                            InputProps={{
                                                                maxLength: 255,
                                                                endAdornment:
                                                                    (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    setShowCurrentPass(
                                                                                        !showCurrentPass,
                                                                                    )
                                                                                }>
                                                                                {showCurrentPass ? (
                                                                                    <img
                                                                                        src={
                                                                                            IconDisablePassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={
                                                                                            IconPassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                setFieldValue(
                                                                    'current_password',
                                                                    e
                                                                        .target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <FormLabel
                                                        className={
                                                            classes.InputLabel
                                                        }
                                                        component="p">
                                                        New
                                                        Password
                                                        *
                                                    </FormLabel>
                                                    <Box
                                                        sx={{
                                                            '& .MuiOutlinedInput-root':
                                                                {
                                                                    height: 'auto',
                                                                },
                                                        }}>
                                                        <TextField
                                                            id="new_password"
                                                            name="new_password"
                                                            variant="outlined"
                                                            value={
                                                                values.new_password
                                                            }
                                                            type={
                                                                showNewPass
                                                                    ? 'text'
                                                                    : 'password'
                                                            }
                                                            fullWidth
                                                            placeholder="Enter password"
                                                            InputProps={{
                                                                maxLength: 255,
                                                                endAdornment:
                                                                    (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    setShowNewPass(
                                                                                        !showNewPass,
                                                                                    )
                                                                                }>
                                                                                {showNewPass ? (
                                                                                    <img
                                                                                        src={
                                                                                            IconDisablePassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={
                                                                                            IconPassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                setFieldValue(
                                                                    'new_password',
                                                                    e
                                                                        .target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}>
                                                    <FormLabel
                                                        className={
                                                            classes.InputLabel
                                                        }
                                                        component="p">
                                                        Confirm
                                                        New
                                                        Password
                                                        *
                                                    </FormLabel>
                                                    <Box
                                                        sx={{
                                                            '& .MuiOutlinedInput-root':
                                                                {
                                                                    height: 'auto',
                                                                },
                                                        }}>
                                                        <TextField
                                                            id="confirm_password"
                                                            name="confirm_password"
                                                            variant="outlined"
                                                            value={
                                                                values.confirm_password
                                                            }
                                                            type={
                                                                showConfirmPass
                                                                    ? 'text'
                                                                    : 'password'
                                                            }
                                                            fullWidth
                                                            placeholder="Enter password"
                                                            InputProps={{
                                                                maxLength: 255,
                                                                endAdornment:
                                                                    (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    setShowConfirmPass(
                                                                                        !showConfirmPass,
                                                                                    )
                                                                                }>
                                                                                {showConfirmPass ? (
                                                                                    <img
                                                                                        src={
                                                                                            IconDisablePassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        src={
                                                                                            IconPassword
                                                                                        }
                                                                                        width={
                                                                                            16
                                                                                        }
                                                                                        height={
                                                                                            16
                                                                                        }
                                                                                        loading="lazy"
                                                                                        alt="App store"
                                                                                        className="app-btn"
                                                                                    />
                                                                                )}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                setFieldValue(
                                                                    'confirm_password',
                                                                    e
                                                                        .target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }}
                            </Formik>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.COMPLETED && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}>
                                <TickCircle />
                            </Box>
                            <Typography
                                style={{
                                    marginTop: 24,
                                    fontWeight: 500,
                                    fontSize: '32px',
                                    color: ' #303E4E',
                                    textAlign: 'center',
                                }}>
                                Change Password Successful
                            </Typography>
                        </Grid>
                    )}

                    {currentStep !==
                        AuthenticationSteps.COMPLETED && (
                        <Button
                            onClick={handleNextStep}
                            // disabled={!selectedItem}
                            style={{
                                marginTop: 62,
                                borderRadius: 20,
                            }}
                            variant="contained"
                            size="large"
                            fullWidth>
                            Next
                        </Button>
                    )}

                    {currentStep ===
                        AuthenticationSteps.COMPLETED && (
                        <Button
                            onClick={() => {
                                history.push('/')
                            }}
                            style={{
                                marginTop: 62,
                                textTransform: 'none',
                            }}
                            variant="contained"
                            size="large"
                            fullWidth>
                            Go to main page
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    )
}
