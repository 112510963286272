import React from 'react'

export default function IconAPerson() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.99998 7.00033C8.61081 7.00033 9.91665 5.69449 9.91665 4.08366C9.91665 2.47283 8.61081 1.16699 6.99998 1.16699C5.38915 1.16699 4.08331 2.47283 4.08331 4.08366C4.08331 5.69449 5.38915 7.00033 6.99998 7.00033Z"
                stroke="#667689"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.0109 12.8333C12.0109 10.5758 9.76503 8.75 7.00003 8.75C4.23503 8.75 1.9892 10.5758 1.9892 12.8333"
                stroke="#667689"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
