import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles, Typography } from '@material-ui/core'
import { Footer } from 'shared/elements/FormItem/Footer'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
}))

interface Props {
    open: boolean
    loading: boolean
    title?: string
    description?: string
    btnCancelText?: string
    btnOkText?: string
    handleDelete: () => void
    handleClose: () => void
}

const AlertDeleteDialog = ({
    open,
    handleClose,
    handleDelete,
    loading,
    title,
    description,
    btnCancelText,
    btnOkText,
}: Props) => {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>{title || 'Confirm Data Delete'}</Typography>
            </DialogTitle>

            <DialogContent>
                <DialogContentText>
                    <Typography className={classes.description}>
                        {description || 'Are you sure to delete this item?'}
                    </Typography>
                </DialogContentText>
            </DialogContent>

            <Footer
                style={{ padding: '0 24px', margin: 0, width: '100%' }}
                loading={loading}
                disabled={loading}
                cancelBtnText={btnCancelText || 'Cancel'}
                okBtnText={btnOkText || 'Delete'}
                onOk={handleDelete}
                onCancel={handleClose}
            />
        </Dialog>
    )
}

export { AlertDeleteDialog }

export default AlertDeleteDialog
