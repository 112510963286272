import { Box, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Adherence from '../../../components/ClinicalNotes/behavioural/Adherence'
import AssessmentInformedConsent from '../../../components/ClinicalNotes/behavioural/AssessmentInformedConsent'
import AssessMentSourceOfRisk from '../../../components/ClinicalNotes/behavioural/AssessmentSourceOfRisk'
import CounsellingandCoordinationofCare from '../../../components/ClinicalNotes/behavioural/CounsellingandCoordinationofCare'
import DevelopmentAndEducationalHistory from '../../../components/ClinicalNotes/behavioural/DevelopmentAndEducationHistory'
import Exam from '../../../components/ClinicalNotes/behavioural/Exam'
import FamilyHistory from '../../../components/ClinicalNotes/behavioural/FamilyHistory'
import GeneralSocialHistory from '../../../components/ClinicalNotes/behavioural/GeneralSocialHistory'
import InpatientPsychiatricHistory from '../../../components/ClinicalNotes/behavioural/InpatientPsychiatricHistory'
import Medication from '../../../components/ClinicalNotes/behavioural/Medication'
import MenstruationAndPregnancy from '../../../components/ClinicalNotes/behavioural/MenstruationAndPregnancy'
import OutpatientPsychiatricHistory from '../../../components/ClinicalNotes/behavioural/OutpatientPsychiatricHistory'
import PastHistory from '../../../components/ClinicalNotes/behavioural/PastHistory'
import PastMedicalHistory from '../../../components/ClinicalNotes/behavioural/PastMedicalHistory'
import PatientAllergies from '../../../components/ClinicalNotes/behavioural/PatientAllergies'
import PatientEducationResources from '../../../components/ClinicalNotes/behavioural/PatientEducationResources'
import PersonPresentInSession from '../../../components/ClinicalNotes/behavioural/PersonPresentedInSession'
import Plan from '../../../components/ClinicalNotes/behavioural/Plan'
import PlanMedChanges from '../../../components/ClinicalNotes/behavioural/PlanMedChanges'
import PlanNextAppointment from '../../../components/ClinicalNotes/behavioural/PlanNextAppointment'
import PshychiatricMedicalHistory from '../../../components/ClinicalNotes/behavioural/PsychiatricMedicalHistory'
import Psychotherapy from '../../../components/ClinicalNotes/behavioural/Psychotherapy'
import ReviewOfSystems from '../../../components/ClinicalNotes/behavioural/ReviewOfSystem'
import SideEffect from '../../../components/ClinicalNotes/behavioural/SideEffect'
import SourcesOfInformations from '../../../components/ClinicalNotes/behavioural/SourceOfInformations'
import Stressors from '../../../components/ClinicalNotes/behavioural/Stressors'
import SubstanceAbuseHx from '../../../components/ClinicalNotes/behavioural/SubstanceAbuseHx'
import SubstanceAbuseTreatmentHx from '../../../components/ClinicalNotes/behavioural/SubstanceAbuseTreatmentHx'
import SubstanceUseConsequences from '../../../components/ClinicalNotes/behavioural/SubstanceUseConsequences'
import SuicidePreventionPlan from '../../../components/ClinicalNotes/behavioural/SuicidePreventionPlan'
import SuicideProtectiveFactor from '../../../components/ClinicalNotes/behavioural/SuicideProtectiveFactor'
import SuicideRiskFactor from '../../../components/ClinicalNotes/behavioural/SuicideRiskFactor'
import SuicideSelfHarmHistory from '../../../components/ClinicalNotes/behavioural/SuicideSelfHarmHistory'
import ViolenceHistory from '../../../components/ClinicalNotes/behavioural/ViolenceHistory'
import Vitals from '../../../components/ClinicalNotes/behavioural/Vitals'
import ICDCode from '../../../components/ClinicalNotes/nonBehavioural/ICDCode'
import PatientAllergiesNonBehavioural from '../../../components/ClinicalNotes/nonBehavioural/PatientAllergiesNonBehavioural'
import CPTCode from '../../../components/ClinicalNotes/reusable/CPTCode'
import FreeTextField from '../../../components/ClinicalNotes/reusable/FreeTextField'
import InterventionUtilized from '../../../components/ClinicalNotes/therapy/InterventionUtilized'
import Routes from '../../../constants/routes'
import { NOTE_STATUS } from '../constants'
// import NarrativeAndMacro from '../common/NarrativeAndMacro'
import NarrativeAndMacro from '../../../components/ClinicalNotes/common/NarrativeAndMacro'

import ChiefComplaint from 'components/ClinicalNotes/behavioural/ChiefComplaint'
import LabResults from 'components/ClinicalNotes/behavioural/LabResults'
import PlanLabOrder from 'components/ClinicalNotes/behavioural/PlanLabOrder'
import PresentIllnessHistory from 'components/ClinicalNotes/behavioural/PresentIllnessHistory'
import PsychotherapyNote from 'components/ClinicalNotes/behavioural/PsychotherapyNote'
import { Formik } from 'formik'
import MedicalCoding from '../../../components/ClinicalNotes/behavioural/MedicalCoding/MedicalCoding'
import {
    ClinicalGlobal,
    FamilyEnviroment,
    SessionFocus,
    SessionHomework,
    SucidalIdeal,
    ThearphyPlan,
    TreatmentApproach,
} from '../../../components/ClinicalNotes/therapy'
import { UserRoles } from '../../../constants/user-roles'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import EncounterQueue from '../../CalendarScheduler/EncounterStack/EncounterQueue'
import { initialState, reducer } from '../../CalendarScheduler/reducer'
import * as actionTypes from '../../CalendarScheduler/actionTypes'
import moment from 'moment'
import { VIEW } from '../../CalendarScheduler/constant'
import { SetCheckMark } from './SetCheckMark'
import { useSelector } from 'react-redux'
import BRateScales from 'components/ClinicalNotes/behavioural/BRateScales'

const useStyles = makeStyles(() => ({
    icon_back: {
        marginRight: 8,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    uneditable_mark: {
        top: 46,
        left: 0,
        background: '#ffffff80',
        position: 'absolute',
        width: '100%',
        height: `calc(100% - 46px)`,
        zIndex: 10,
    },
}))

const BehaviouralNotes = ({
    noteDetails,
    values,
    status,
    activeNote,
    onChange,
    errors,
    fetchClinicalNote,
    initialValues,
    handlePreview,
    getICDCodes,
    submitFormRef,
    role,
    appointmentId,
    psycho_note,
    previous_cn,
    handleSetViewHealthyRecord,
    clinicalNoteData,
    appointmentDetails,
    isClinicalNoteNotPresesnt
}) => {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()
    const formikInstance = useRef()
    const userData = useSelector((state) => state?.login?.userData)
    const lastNarratives = previous_cn?.clinicalNoteModel || {}

    const { state, checkDirtySessions } = React.useContext(ClinicalNoteContext)
    const [state1, dispatch] = React.useReducer(reducer, initialState)
    function onSelectMonth(date) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date: moment(date),
            view: VIEW.MONTH,
        })
    }
    function onNavigate(date, view = state1.view.selected) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date,
            view,
        })
    }
    const checkEditOrNot = () =>{
        if(userData?.role_code === "medical_assistant"){
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(status) ||
            noteDetails?.name === 'view' ||
            (clinicalNoteData?.in_edit) ||
            (status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
            (isClinicalNoteNotPresesnt === true)
            return editOrNot 
        }
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(status) ||
                noteDetails?.name === 'view' ||
                !(clinicalNoteData?.isEditable) ||
                (clinicalNoteData?.in_edit) ||
                (status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
                (isClinicalNoteNotPresesnt === true)
            return editOrNot
    }
    // const nonEditable =
    //     [
    //         'pending',
    //         'locked',
    //         'approved_lock',
    //         'Pending Approval',
    //         'Approved & Locked',
    //         'LOCKED',
    //     ].includes(status) ||
    //         noteDetails?.name === 'view' ||
    //         !(clinicalNoteData?.isEditable) ||
    //         (status === 'resent_to_edit' && role === UserRoles.PROVIDER) 
    //         ? true
    //         : false
    const nonEditable = checkEditOrNot()
    const baseURL = process.env.REACT_APP_SONORA_V2_API_URL
    const token = localStorage.getItem('token')
    const EditedClinicalNotes = localStorage.getItem('editedClinicalNote') ? JSON.parse(localStorage.getItem('editedClinicalNote')) : []
    useEffect(() => {
        const handleUnload = (event) => { 
            const lastedValue = EditedClinicalNotes?.filter((id) => id !== noteDetails?.id)
            localStorage.setItem('editedClinicalNote',JSON.stringify(lastedValue))
            if(!nonEditable){
                fetch(`${baseURL}/clinical-note/clearEditMode/${noteDetails?.id}`, {
                    method:'post',
                    headers:{
                        Authorization: `Bearer ${token}`
                    },
                    keepalive: true
                })
            }
          };
        window.addEventListener('beforeunload', handleUnload);
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [])
    useEffect(()=>{
        const handleOuterClick = () => {
            submitFormRef.current && submitFormRef.current()
        }
        document.querySelector('.app__sidebar').onclick = handleOuterClick;
        document.querySelector('.app_header').onclick = handleOuterClick;
        // temporary fix for saving progress after clicking on callEnd button. should be removed when components will be refactored
        if (document.querySelector('.icon_holder')) {
            document.querySelector('.icon_holder').onclick = handleOuterClick;
        }
        return () => {
            document.querySelector('.app__sidebar').onclick = null;
            document.querySelector('.app_header').onclick = null;
            if (document.querySelector('.icon_holder')) {
                document.querySelector('.icon_holder').onclick = null;
            }
            const lastedValue = EditedClinicalNotes?.filter((id) => id !== noteDetails?.id)
            localStorage.setItem('editedClinicalNote',JSON.stringify(lastedValue))
            if(!nonEditable){
                fetch(`${baseURL}/clinical-note/clearEditMode/${noteDetails?.id}`, {
                    method:'post',
                    headers:{
                        Authorization: `Bearer ${token}`
                    },
                    keepalive: true
                })
            }
        }
    },[])
    useEffect(()=>{
        if(!nonEditable){
            const value = EditedClinicalNotes.length ? [...EditedClinicalNotes, noteDetails?.id] : [noteDetails?.id]
            localStorage.setItem('editedClinicalNote',JSON.stringify(value))
        }
    },[nonEditable])
    useEffect(() => {
        submitFormRef.current = () => {
            if (!activeNote.componentId || !formikInstance.current) {
                return
            }

            console.log(
                'submiit',
                activeNote.componentId,
                formikInstance.current.values[activeNote.componentId],
            )
            if (!nonEditable) {
                checkDirtySessions({
                    key: activeNote.componentId,
                    value: SetCheckMark(activeNote.componentId,
                        formikInstance.current.values[activeNote.componentId], nonEditable),
                })
             }
            // checkDirtySessions({
            //     key: activeNote.componentId,
            //     value: !_.isEqual(
            //         formikInstance.current.values[activeNote.componentId],
            //         initialValues[activeNote.componentId],
            //     ),
            // })
            if (!nonEditable) {
                onChange(activeNote.componentId, formikInstance.current.values[activeNote.componentId])
            }
        }
    }, [activeNote.componentId])

    const wrapNote = (Section) => (props) => {
        const Component = Section
        return (
            <Formik
                innerRef={formikInstance}
                initialValues={{
                    [props.fieldName]: props.values,
                }}>
                {({ values, setFieldValue }) => {
                    return (
                        <Component
                            {...props}
                            onChange={setFieldValue}
                            values={values[props.fieldName]}
                            fieldName={props.fieldName}
                            key={props.fieldName}
                        />
                    )
                }}
            </Formik>
        )
    }

    const backToClinicalNote = () => {
        submitFormRef.current && submitFormRef.current()
        history.push(Routes.CLINICAL_NOTES.LISTING, {
            searchData: location?.state?.searchData,
        })
    }

    const handleChange = (name, value) => {
        onChange(name, value)
    }

    return (
        <Box
            sx={{
                background: '#ffffff',
                padding: ' 0 32px 16px 32px',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: 16,
                flex: 1,
                position: 'relative',
                overflow: 'auto',
            }}
        >
            {/* {([
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(status) ||
                noteDetails?.name === 'view' ||
                (status === 'resent_to_edit' && role === UserRoles.PROVIDER)) && (
                    <div
                        className={
                            activeNote?.componentId !== 'medical_coding' ? classes.uneditable_mark : ''
                        }
                    />
                )} */}
            {clinicalNoteData?.in_edit && <Typography style={{marginBottom:"10px", fontWeight: '500'}}>The clinical note is currently being edited by another user: {clinicalNoteData?.editedUser?.first_name}{" "}{clinicalNoteData?.editedUser?.last_name} </Typography>}
            {!activeNote?.componentId ? (
                <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: 'center',
                            color: '#303E4E',
                        }}>
                        Please select a session
                    </Typography>
                </Grid>
            ) : (
                <Grid
                    item
                    container
                    alignItems="center"
                    style={{
                        marginBottom: 12,

                    }}>
                    <Box style={{ cursor: 'pointer', display: "flex" }} onClick={backToClinicalNote}>
                        <IconButton className={classes.icon_back}>
                            <KeyboardBackspaceOutlinedIcon style={{ fontSize: 16 }} />
                        </IconButton>
                        <Typography className={classes.back__btn}>
                            Back to Clinical Notes list
                        </Typography>
                    </Box>
                </Grid>
            )}

            <Typography
                variant="h4"
                style={{
                    textTransform: 'none',
                    paddingBottom: 16,
                    fontSize: 22,
                    opacity: nonEditable ? 0.6 : "",
                }}>
                {(activeNote.componentId === 'subjective_concerns' && noteDetails.templateId === 'therapy_progress_note') ? 'HPI' : activeNote.header}
            </Typography>

            {activeNote.componentId === 'cpt_code' &&
                wrapNote(CPTCode)({
                    values: values?.cpt_code,
                    fieldName: 'cpt_code',
                    name: 'cpt_code',
                    macros: state.macros,
                    master: state.master?.cptCodeMasters || [],
                    errors,
                    nonEditable,
                })}
            {activeNote.componentId === 'present_in_sessions' &&
                wrapNote(PersonPresentInSession)({
                    values: values?.present_in_sessions,
                    fieldName: 'present_in_sessions',
                    name: 'present_in_sessions',
                    macros: state.macros,
                    master: state.master,
                    noteDetails,
                    nonEditable
                })}
            {activeNote.componentId === 'chief_complaint' &&
                wrapNote(ChiefComplaint)({
                    values: values?.chief_complaint,
                    chief_complaint: psycho_note?.chief_complaint,
                    fieldName: 'chief_complaint',
                    name: 'chief_complaint',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    reasonForVisit: noteDetails?.reason_for_visit,
                    nonEditable
                })}
            {activeNote.componentId === 'medical_coding' &&
                wrapNote(MedicalCoding)({
                    values: values?.medical_coding,
                    chief_complaint: values?.chief_complaint,
                    fieldName: 'medical_coding',
                    name: 'medical_coding',
                    macros: state.macros,
                    master: state.master,
                    noteDetails,
                    appointmentId,
                    clinicalNoteData,
                    read_only: nonEditable,
                })}
            {activeNote.componentId === 'present_illness_history' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.present_illness_history,
                    fieldName: 'present_illness_history',
                    name: 'present_illness_history',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    last_HPI: psycho_note?.last_HPI,
                    copyAble: noteDetails.templateId === 'therapy_initial_note',
                    copyValues: lastNarratives['present_illness_history'],
                    isShowCopyButton: true,
                    lastNarrative: lastNarratives.present_illness_history?.narrative,
                    // textCharecterlimit: false,
                    nonEditable
                    // placeholder: 'Enter Telemedicine Attestation',
                })}
            {activeNote.componentId === 'treatment_notes' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.treatment_notes,
                    fieldName: 'treatment_notes',
                    name: 'treatment_notes',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    isShowCopyButton: false,
                    placeholder: 'Enter comments about treatment',
                    nonEditable
                })}
            {activeNote.componentId === 'telemedicine_attestation' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.telemedicine_attestation,
                    fieldName: 'telemedicine_attestation',
                    name: 'telemedicine_attestation',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    placeholder: 'Enter Telemedicine Attestation',
                    nonEditable
                })}
            {activeNote.componentId === 'verbal_consent_telemedicine' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.verbal_consent_telemedicine,
                    fieldName: 'verbal_consent_telemedicine',
                    name: 'verbal_consent_telemedicine',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    placeholder: 'Enter Verbal Consent for Telemedicine',
                    nonEditable
                })}
            {activeNote.componentId === 'time_spent' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.time_spent,
                    fieldName: 'time_spent',
                    name: 'time_spent',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'subjective_concerns' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.subjective_concerns,
                    fieldName: 'subjective_concerns',
                    name: 'subjective_concerns',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    description:
                        "Description: Your client's self-reported feelings, emotions, or actions",
                    // placeholder: 'Enter Telemedicine Attestation',
                    copyAble: noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['subjective_concerns'],
                    nonEditable
                })}
            {activeNote.componentId === 'stressors' &&
                wrapNote(Stressors)({
                    values: values?.stressors,
                    fieldName: 'stressors',
                    name: 'stressors',
                    macros: state.macros,
                    master: state.master,
                    state,
                    nonEditable
                })}
            {activeNote.componentId === 'side_effects' &&
                wrapNote(SideEffect)({
                    values: values?.side_effects,
                    fieldName: 'side_effects',
                    name: 'side_effects',
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.side_effects?.narrative,    
                    generateAble:
                        noteDetails.templateId === 'therapy_progress_note',
                    copyAble:noteDetails.templateId === 'psychiatry_progress_note',   
                    copyValues: lastNarratives['side_effects'],
                    nonEditable
                })}
            {activeNote.componentId === 'system_reviews' &&
                wrapNote(ReviewOfSystems)({
                    macros: state.macros,
                    master: state.master,
                    values: values?.system_reviews,
                    fieldName: 'system_reviews',
                    name: 'system_reviews',
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['system_reviews']
                })}
            {activeNote.componentId === 'subtance_abuse_history' &&
                wrapNote(SubstanceAbuseHx)({
                    values: values?.subtance_abuse_history,
                    fieldName: 'subtance_abuse_history',
                    name: 'subtance_abuse_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    state,
                    nonEditable
                })}
            {activeNote.componentId === 'subtance_abuse_treat_history' &&
                wrapNote(SubstanceAbuseTreatmentHx)({
                    values: values?.subtance_abuse_treat_history,
                    fieldName: 'subtance_abuse_treat_history',
                    name: 'subtance_abuse_treat_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    state,
                    nonEditable
                })}
            {activeNote.componentId === 'subtance_use_consequences' &&
                wrapNote(SubstanceUseConsequences)({
                    values: values?.subtance_use_consequences,
                    fieldName: 'subtance_use_consequences',
                    name: 'subtance_use_consequences',
                    macros: state.macros,
                    master: state.master,
                    state,
                    nonEditable
                })}
            {activeNote.componentId === 'adherence' &&
                wrapNote(Adherence)({
                    values: values?.adherence,
                    fieldName: 'adherence',
                    name: 'adherence',
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.adherence?.narrative,    
                    generateAble:
                        noteDetails.templateId === 'psychiatry_progress_note',
                    copyAble:noteDetails.templateId === 'psychiatry_progress_note',   
                    copyValues: lastNarratives['adherence'],
                    nonEditable
                })}
            {activeNote.componentId === 'past_history' &&
                wrapNote(PastHistory)({
                    values: values?.past_history,
                    fieldName: 'past_history',
                    name: 'past_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    copyAble: noteDetails.templateId === 'psychiatry_progress_note',
                    copyValues: lastNarratives['past_history'],
                    lastNarrative: lastNarratives.past_history?.narrative,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'inpatient_psychiatric_history' &&
                wrapNote(InpatientPsychiatricHistory)({
                    values: values?.inpatient_psychiatric_history,
                    fieldName: 'inpatient_psychiatric_history',
                    name: 'inpatient_psychiatric_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'outpatient_psychiatric_history' &&
                wrapNote(OutpatientPsychiatricHistory)({
                    values: values?.outpatient_psychiatric_history,
                    fieldName: 'outpatient_psychiatric_history',
                    name: 'outpatient_psychiatric_history',
                    macros: state.macros,
                    master: state.master,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    nonEditable
                })}
            {activeNote.componentId === 'suicide_history' &&
                wrapNote(SuicideSelfHarmHistory)({
                    values: values?.suicide_history,
                    fieldName: 'suicide_history',
                    name: 'suicide_history',
                    macros: state.macros,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'violence_history' &&
                wrapNote(ViolenceHistory)({
                    values: values?.violence_history,
                    fieldName: 'violence_history',
                    name: 'violence_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'past_medical_history' &&
                wrapNote(PastMedicalHistory)({
                    values: values?.past_medical_history,
                    fieldName: 'past_medical_history',
                    name: 'past_medical_history',
                    patientId: psycho_note?.patient_id,
                    primary_care_physician: psycho_note?.primary_care_physician,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'psychiatric_medication_history' &&
                wrapNote(PshychiatricMedicalHistory)({
                    values: values?.psychiatric_medication_history,
                    fieldName: 'psychiatric_medication_history',
                    name: 'psychiatric_medication_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'medication' &&
                wrapNote(Medication)({
                    master: state.master,
                    lastNarrative: lastNarratives.medication?.narrative,
                    values: values?.medication,
                    fieldName: 'medication',
                    name: 'medication',
                    macros: state.macros,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    medicationHistory: state.medicationHistory,
                    nonEditable
                })}
            {activeNote.componentId === 'family_history' &&
                wrapNote(FamilyHistory)({
                    values: values?.family_history,
                    fieldName: 'family_history',
                    name: 'family_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    nonEditable
                })}
            {activeNote.componentId === 'dev_and_edu_history' &&
                wrapNote(DevelopmentAndEducationalHistory)({
                    master: state.master,
                    values: values?.dev_and_edu_history,
                    fieldName: 'dev_and_edu_history',
                    name: 'dev_and_edu_history',
                    macros: state.macros,
                    nonEditable
                })}
            {activeNote.componentId === 'general_social_history' &&
                wrapNote(GeneralSocialHistory)({
                    master: state.master,
                    values: values?.general_social_history,
                    fieldName: 'general_social_history',
                    name: 'general_social_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    nonEditable
                })}
            {activeNote.componentId === 'vitals' &&
                wrapNote(Vitals)({
                    master: state.master,
                    values: values?.vitals,
                    fieldName: 'vitals',
                    defaultValues: lastNarratives.vitals,
                    name: 'vitals',
                    clinicalNoteData,
                    nonEditable,
                    previous_cn: previous_cn?.clinicalNoteModel
                })}
            {activeNote.componentId === 'behavior_rate_scales' &&
                wrapNote(BRateScales)({
                    fieldName: 'behavior_rate_scales',
                    name: 'behavior_rate_scales',
                    patientId: psycho_note?.patient_id,
                    nonEditable
            })}
            {activeNote.componentId === 'menstruation_and_pregnancy_history' &&
                wrapNote(MenstruationAndPregnancy)({
                    master: state.master,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    values: values?.menstruation_and_pregnancy_history,
                    fieldName: 'menstruation_and_pregnancy_history',
                    name: 'menstruation_and_pregnancy_history',
                    macros: state.macros,
                    nonEditable
                })}
            {activeNote.componentId === 'exam' &&
                wrapNote(Exam)({
                    lastNarrative: lastNarratives.exam?.narrative,
                    master: state.master,
                    rows: 12,
                    values: values?.exam,
                    fieldName: 'exam',
                    name: 'exam',
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'psychiatry_progress_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['exam'],
                    nonEditable
                })}
            {activeNote.componentId === 'physical_examinations' &&
                wrapNote(Exam)({
                    master: state.master,
                    values: values?.physical_examinations,
                    fieldName: 'physical_examinations',
                    name: 'physical_examinations',
                    nonEditable
                })}
            {activeNote.componentId === 'psychotherapy_note' &&
                wrapNote(PsychotherapyNote)({
                    values: values?.psychotherapy_note,
                    fieldName: 'psychotherapy_note',
                    name: 'psychotherapy_note',
                    macros: state.macros,
                    master: state.master,
                    noteDetails,
                    appointmentId,
                    fetchClinicalNote,
                    ...psycho_note,
                    nonEditable
                })}

            {activeNote.componentId === 'review_of_measures' &&
                wrapNote(NarrativeAndMacro)({
                    isShowCopyButton: true,
                    lastNarrative: lastNarratives.review_of_measures?.narrative,
                    values: values?.review_of_measures,
                    fieldName: 'review_of_measures',
                    name: 'review_of_measures',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}

            {activeNote.componentId === 'lab_results' &&
                wrapNote(LabResults)({
                    values: values?.lab_results,
                    fieldName: 'lab_results',
                    name: 'lab_results',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,

                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    nonEditable
                    // handleSetViewHealthyRecord,
                })}

            {activeNote.componentId === 'source_of_information' &&
                wrapNote(SourcesOfInformations)({
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.source_of_information?.narrative,
                    values: values?.source_of_information,
                    fieldName: 'source_of_information',
                    name: 'source_of_information',
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['source_of_information']
                })}

            {activeNote.componentId === 'assessment_sources_of_risk' &&
                wrapNote(AssessMentSourceOfRisk)({
                    macros: state.macros,
                    master: state.master,
                    values: values?.assessment_sources_of_risk,
                    fieldName: 'assessment_sources_of_risk',
                    name: 'assessment_sources_of_risk',
                    getICDCodes,
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['assessment_sources_of_risk']
                })}
            {activeNote.componentId === 'suicide_risk_factors' &&
                wrapNote(SuicideRiskFactor)({
                    master: state?.master,
                    macros: state.macros,
                    lastNarrative: lastNarratives.suicide_risk_factors?.narrative,
                    values: values?.suicide_risk_factors,
                    fieldName: 'suicide_risk_factors',
                    name: 'suicide_risk_factors',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    generateAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note' || noteDetails.templateId === 'psychiatry_progress_note',
                    copyValues: lastNarratives['suicide_risk_factors'],
                    nonEditable
                })}
            {activeNote.componentId === 'suicide_protective_factors' &&
                wrapNote(SuicideProtectiveFactor)({
                    master: state?.master,
                    macros: state.macros,
                    values: values?.suicide_protective_factors,
                    fieldName: 'suicide_protective_factors',
                    name: 'suicide_protective_factors',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    generateAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyAble: noteDetails.templateId === 'psychiatry_initial_note' ||
                              noteDetails.templateId === 'psychiatry_progress_note' ||
                              noteDetails.templateId === 'therapy_progress_note' ||
                              noteDetails.templateId === 'therapy_initial_note',
                    copyValues: lastNarratives['suicide_protective_factors'],
                    nonEditable
                })}
            {activeNote.componentId === 'suicide_preventive_plan' &&
                wrapNote(SuicidePreventionPlan)({
                    master: state?.master,
                    macros: state.macros,
                    values: values?.suicide_preventive_plan,
                    fieldName: 'suicide_preventive_plan',
                    name: 'suicide_preventive_plan',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble: true,
                    copyValues: lastNarratives['suicide_preventive_plan'],
                    nonEditable
                })}
            {activeNote.componentId === 'icd_code' &&
                wrapNote(ICDCode)({
                    appointmentId,
                    macros: state.macros,
                    master: {
                        icdCodeMasters: state?.icdCodes || [],
                        billingMasters: state?.master?.billingMasters || [],
                    },
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    value: values?.icd_code,
                    fieldName: 'icd_code',
                    name: 'icd_code',
                    getICDCodes,
                    nonEditable
                })}
            {activeNote.componentId === 'assessment_impression' &&
                wrapNote(NarrativeAndMacro)({
                    descriptionComponent: (
                        <Typography
                            variant="body1"
                            style={{
                                color: '#9CA9B8',
                                fontSize: 12,
                            }}>{`For E&M coding, discuss differential diagnoses (Rule Out Diagnoses). For established diagnoses, discuss whether the problem is: a) improved, well controlled, resolving or resolved; or, b) inadequately controlled, worsening, or failing to change as expected.`}</Typography>
                    ),
                    values: values?.assessment_impression,
                    lastNarrative: lastNarratives.assessment_impression?.narrative,
                    fieldName: 'assessment_impression',
                    name: 'assessment_impression',
                    hideTextNarrative: true,
                    isShowCopyButton: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'assessment_informed_consent' &&
                wrapNote(AssessmentInformedConsent)({
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.assessment_informed_consent?.narrative,
                    values: values?.assessment_informed_consent,
                    fieldName: 'assessment_informed_consent',
                    name: 'assessment_informed_consent',
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['assessment_informed_consent']
                })}
            {activeNote.componentId === 'counselling_and_coordination_care' &&
                wrapNote(CounsellingandCoordinationofCare)({
                    macros: state.macros,
                    master: state.master,
                    values: values?.counselling_and_coordination_care,
                    fieldName: 'counselling_and_coordination_care',
                    name: 'counselling_and_coordination_care',
                    nonEditable
                })}
            {activeNote.componentId === 'psychotherapy' &&
                wrapNote(Psychotherapy)({
                    macros: state.macros,
                    master: state.master,
                    values: values?.psychotherapy,
                    fieldName: 'psychotherapy',
                    name: 'psychotherapy',
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['psychotherapy']
                })}
            {activeNote.componentId === 'plan' &&
                wrapNote(Plan)({
                    lastNarrative: lastNarratives.plan?.narrative,
                    macros: state.macros,
                    master: state.master,
                    values: values?.plan,
                    fieldName: 'plan',
                    name: 'plan',
                    nonEditable,
                    copyAble: true,
                    copyValues: lastNarratives['plan'],
                })}
            {activeNote.componentId === 'plan_med_changes' &&
                wrapNote(PlanMedChanges)({
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.plan_med_changes?.narrative,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    values: values?.plan_med_changes,
                    fieldName: 'plan_med_changes',
                    name: 'plan_med_changes',
                    encounter_date: noteDetails?.service_date,
                    nonEditable
                })}
            {activeNote.componentId === 'plan_lab_ordered' &&
                wrapNote(PlanLabOrder)({
                    values: values?.plan_lab_ordered,
                    fieldName: 'plan_lab_ordered',
                    handleSetViewHealthyRecord,
                    name: 'plan_lab_ordered',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    nonEditable
                })}
            {activeNote.componentId === 'plan_next_appointment' &&
                wrapNote(PlanNextAppointment)({
                    master: state?.master,
                    macros: state.macros,
                    values: values?.plan_next_appointment,
                    clinicalNoteData,
                    fieldName: 'plan_next_appointment',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    providerId: psycho_note?.provider,
                    name: 'plan_next_appointment',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    appointmentDetails,
                    populate:
                        noteDetails.templateId === 'psychiatry_initial_note' ||
                        noteDetails.templateId === 'psychiatry_progress_note' ||
                        noteDetails.templateId === 'therapy_progress_note' ||
                        noteDetails.templateId === 'therapy_initial_note',
                    nonEditable
                })}
            {activeNote.componentId === 'allergies' &&
                wrapNote(PatientAllergiesNonBehavioural)({
                    macros: state.macros,
                    heading: 'Patient Allergies',
                    master: state.master,
                    values: values?.allergies,
                    fieldName: 'allergies',
                    name: 'allergies',
                    nonEditable
                })}
            {activeNote.componentId === 'allergy' &&
                wrapNote(PatientAllergies)({
                    withoutMacro: true,
                    macros: state.macros,
                    lastNarrative: lastNarratives.allergy?.narrative,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    master: state.master,
                    values: values?.allergy,
                    fieldName: 'allergy',
                    name: 'allergy',
                    allergyHistory: state.allergyHistory,
                    patient: clinicalNoteData?.patient,
                    nonEditable
                })}
            {activeNote.componentId === 'last_session_hmwrk' &&
                wrapNote(SessionHomework)({
                    macros: state.macros,
                    values: values?.last_session_hmwrk,
                    fieldName: 'last_session_hmwrk',
                    name: 'last_session_hmwrk',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    initialValue: initialValues?.last_session_hmwrk,
                    nonEditable
                })}
            {activeNote.componentId === 'assessment' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.assessment,
                    fieldName: 'assessment',
                    name: 'assessment',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'suicide_ideation' &&
                wrapNote(SucidalIdeal)({
                    macros: state.macros,
                    values: values?.suicide_ideation,
                    fieldName: 'suicide_ideation',
                    name: 'suicide_ideation',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    nonEditable
                })}
            {activeNote.componentId === 'clinical_global_impression' &&
                wrapNote(ClinicalGlobal)({
                    master: state?.master?.clinicalImpMasters,
                    macros: state.macros,
                    values: values?.clinical_global_impression,
                    fieldName: 'clinical_global_impression',
                    name: 'clinical_global_impression',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    nonEditable
                })}
            {activeNote.componentId === 'family_env_strategy' &&
                wrapNote(FamilyEnviroment)({
                    master: state?.master?.envStrategyMasters,
                    macros: state.macros,
                    values: values?.family_env_strategy,
                    fieldName: 'family_env_strategy',
                    name: 'family_env_strategy',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['family_env_strategy'],
                    nonEditable
                })}
            {activeNote.componentId === 'homework' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.homework,
                    fieldName: 'homework',
                    name: 'homework',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['homework'],
                    nonEditable: nonEditable
                })}
            {activeNote.componentId === 'interven_utilized' &&
                wrapNote(InterventionUtilized)({
                    master: state?.master?.interventionMasters,
                    macros: state.macros,
                    values: values?.interven_utilized,
                    fieldName: 'interven_utilized',
                    name: 'interven_utilized',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['interven_utilized'],
                    nonEditable
                })}
            {activeNote.componentId === 'laboratory_data' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.laboratory_data,
                    fieldName: 'laboratory_data',
                    name: 'laboratory_data',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}

            {activeNote.componentId === 'patient_resources' &&
                wrapNote(PatientEducationResources)({
                    withoutMacro: true,
                    macros: state.macros,
                    values: values?.patient_resources,
                    fieldName: 'patient_resources',
                    name: 'patient_resources',
                    nonEditable
                })}

            {activeNote.componentId === 'provider_defined_details' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.provider_defined_details,
                    fieldName: 'provider_defined_details',
                    name: 'provider_defined_details',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}

            {activeNote.componentId === 'provider_defined_title' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.provider_defined_title,
                    fieldName: 'provider_defined_title',
                    name: 'provider_defined_title',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'radiology' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.radiology,
                    fieldName: 'radiology',
                    name: 'radiology',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'recommendation_for_patient' &&
                wrapNote(FreeTextField)({
                    withoutMacro: true,
                    macros: state.macros,
                    rows: 12,
                    values: values?.recommendation_for_patient,
                    fieldName: 'recommendation_for_patient',
                    name: 'recommendation_for_patient',
                    lineNumber: 2,
                    nonEditable
                })}
            {activeNote.componentId === 'therapy_plan' &&
                wrapNote(ThearphyPlan)({
                    master: state?.master?.therapyPlanMasters,
                    macros: state.macros,
                    values: values?.therapy_plan,
                    fieldName: 'therapy_plan',
                    name: 'therapy_plan',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    generateAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['therapy_plan'],
                    nonEditable
                })}
            {activeNote.componentId === 'session_focus' &&
                wrapNote(SessionFocus)({
                    master: state?.master?.sessionFocusMasters,
                    macros: state.macros,
                    values: values?.session_focus,
                    fieldName: 'session_focus',
                    name: 'session_focus',
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['session_focus'],
                    nonEditable
                })}
            {activeNote.componentId === 'recommendation_for_referring_provider' &&
                wrapNote(FreeTextField)({
                    withoutMacro: true,
                    macros: state.macros,
                    rows: 12,
                    values: values?.recommendation_for_referring_provider,
                    fieldName: 'recommendation_for_referring_provider',
                    name: 'recommendation_for_referring_provider',
                    lineNumber: 2,
                    nonEditable
                })}
            {activeNote.componentId === 'telemedication_attestation' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.telemedication_attestation,
                    fieldName: 'telemedication_attestation',
                    name: 'telemedication_attestation',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'time_spent_statement' &&
                wrapNote(NarrativeAndMacro)({
                    values: values?.time_spent_statement,
                    fieldName: 'time_spent_statement',
                    name: 'time_spent_statement',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    nonEditable
                })}
            {activeNote.componentId === 'treatment_approach' &&
                wrapNote(TreatmentApproach)({
                    macros: state.macros,
                    values: values?.treatment_approach,
                    fieldName: 'treatment_approach',
                    name: 'treatment_approach',
                    master: state?.master?.cptCodeMasters || [],
                    read_only:
                        NOTE_STATUS.LOCKED === noteDetails?.status || noteDetails?.name === 'view'
                            ? true
                            : false || NOTE_STATUS.UNDER_APPROVAL === noteDetails?.status
                                ? true
                                : false || noteDetails?.is_locked === true,
                    copyAble:
                        noteDetails.templateId === 'therapy_initial_note' ||
                        noteDetails.templateId === 'therapy_progress_note',
                    copyValues: lastNarratives['treatment_approach'],
                    nonEditable
                })}
        </Box>
    )
}

export default BehaviouralNotes
