import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { FieldArray } from 'formik'
import React, { useEffect } from 'react'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'
import { genrateButtonIdentification } from 'containers/ClinicalNotes/utils'

const SessionFocus = ({
    master,
    macros,
    values,
    onChange,
    read_only,
    onClear,
    fieldName,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const [narrative, setNarrative] = React.useState('')
    function getNames(id) {
        const item = master.filter((c) => c._id === id)

        return item[0]?.label
    }
    function generatNarrative(macro) {
        const c = master
            .filter((c) => c._id)
            .map((x) => x.name)
            .join(', ')
        const names = []
        values?.session.map((c) => {
            const a_an_name = ['a', 'e', 'u', 'i', 'o'].includes(
                getNames(c).charAt(0).toLowerCase(),
            )
                ? ` an `
                : ` a `
            return names.push(a_an_name + getNames(c))
        })

        const generatedNam = names.toString().toLowerCase()
        const finalStatement = `This session would best be considered${generatedNam}${
            values?.other ? ` and ${values?.other}` : ''
        }.`

        setNarrative(finalStatement)
        const newNarrative = genrateButtonIdentification(finalStatement, values?.narrative)
        onChange('session_focus.narrative', `${newNarrative}`)
    }
    function handleChanges(name, value) {
        onChange(name, value)
    }

    const handleCopy = async () => {
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {copyAble && !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )}
                <Typography
                    style={{
                        fontSize: 12,
                        color: '#9CA9B8',
                    }}>
                    This session would best be considered:
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FieldArray
                    name="session_focus.session"
                    render={(helpers) => (
                        <FormGroup>
                            {master?.map((i, key) => (
                                <CheckboxOption
                                    key={key}
                                    name={i.name}
                                    option={{
                                        label: i.name,
                                        value: false,
                                    }}
                                    disabled={nonEditable}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            helpers.push(i._id)
                                        } else {
                                            const idx = values?.session.indexOf(i._id)
                                            helpers.remove(idx)
                                        }
                                    }}
                                    checked={values?.session.includes(i._id)}
                                />
                            ))}
                        </FormGroup>
                    )}
                />
            </Grid>
            <Grid container item direction="column">
                <Grid>
                    <Typography>Other</Typography>
                </Grid>
                <Box
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: 'fit-content',
                        },
                    }}>
                    <TextField
                        style={{ marginTop: 8 }}
                        minRows={4}
                        placeholder="Text area"
                        multiline
                        variant="outlined"
                        size="small"
                        value={values?.other}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        name="session_focus.other"
                        disabled={read_only || nonEditable }
                    />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleChanges}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default SessionFocus
