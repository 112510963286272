/* eslint-disable default-case */
import { config } from '../../constants'
import {
    deleteService,
    getService,
    postService,
    putService,
} from '../../utilities/request/restClient'

//Specialty
export const getListSpecialties = (payload) => {
    const params = new URLSearchParams(payload)
    return getService(`${config.dev.baseURLV2}/specialty?${params}`)
}

export const getSpecialtyById = (id) => {
    const url = `${config.dev.baseURLV2}/specialty/${id}`
    return getService(url)
}

export const createSpecialty = (payload) => {
    const url = `${config.dev.baseURLV2}/specialty`
    return postService(url, payload)
}

export const updateSpecialty = (id, payload) => {
    const url = `${config.dev.baseURLV2}/specialty/${id}`
    return putService(url, payload)
}

export const deleteSpecialty = (id) => {
    const url = `${config.dev.baseURLV2}/specialty/${id}`
    return deleteService(url)
}

//Clinical practice

export const getListPractices = (payload) => {
    const params = new URLSearchParams({
        asc: 'name',
        ...payload,
    })
    return getService(`${config.dev.baseURLV2}/practice?${params}`)
}

export const getPracticeById = (id) => {
    const url = `${config.dev.baseURLV2}/practice/${id}`
    return getService(url)
}

export const createPractice = (payload) => {
    const url = `${config.dev.baseURLV2}/practice`
    return postService(url, payload)
}

export const updatePractice = (id, payload) => {
    const url = `${config.dev.baseURLV2}/practice/${id}`
    return putService(url, payload)
}

export const deletePractice = (id) => {
    const url = `${config.dev.baseURLV2}/practice/${id}`
    return deleteService(url)
}

//Health Plans

export const getListHealthPlans = (payload) => {
    const params = new URLSearchParams({
        asc: 'name',
        ...payload,
    })
    return getService(`${config.dev.baseURLV2}/health-plan?${params}`)
}

export const getHealthPlanById = (id) => {
    const url = `${config.dev.baseURLV2}/health-plan/${id}`
    return getService(url)
}

export const createHealthPlan = (payload) => {
    const url = `${config.dev.baseURLV2}/health-plan`
    return postService(url, payload)
}

export const updateHealthPlan = (id, payload) => {
    const url = `${config.dev.baseURLV2}/health-plan/${id}`
    return putService(url, payload)
}

export const deleteHealthPlan = (id) => {
    const url = `${config.dev.baseURLV2}/health-plan/${id}`
    return deleteService(url)
}

// Custom procedure codes

export const getListCustomProcCodes = (payload) => {
    const params = new URLSearchParams(payload)
    return getService(`${config.dev.baseURLV2}/procedure-code?${params}`)
}

export const getCustomProcCodeById = (id) => {
    const url = `${config.dev.baseURLV2}/procedure-code/${id}`
    return getService(url)
}

export const createCustomProcCode = (payload) => {
    const url = `${config.dev.baseURLV2}/procedure-code`
    return postService(url, payload)
}

export const updateCustomProcCode = (id, payload) => {
    const url = `${config.dev.baseURLV2}/procedure-code/${id}`
    return putService(url, payload)
}

export const deleteCustomProcCode = (id) => {
    const url = `${config.dev.baseURLV2}/procedure-code/${id}`
    return deleteService(url)
}

export const getCheckList = (payload) => {
    const params = new URLSearchParams({
        ...payload,
    })
    return getService(`${config.dev.baseURLV2}/checklist?${params}`)
}

export const createCheckList =(payload)=>{
    const url = `${config.dev.baseURLV2}/checklist`
    return postService(url, payload)
}

export const getCheckListById = (id) => {
    const url = `${config.dev.baseURLV2}/checklist/${id}`
    return getService(url)
}

export const updateCheckList = (id, payload) => {
    const url = `${config.dev.baseURLV2}/checklist/${id}`
    return putService(url, payload)
}

export const deleteCheckList = (id) => {
    const url = `${config.dev.baseURLV2}/checklist/${id}`
    return deleteService(url)
}