import React, { useState, useEffect } from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { ErrorType } from 'containers/PatientsSetting/interfaces'
import { RadioGroup } from 'shared/elements/FormItem/RadioGroup'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { Loading } from 'shared/elements/Loading'

import { ICD10, Specialty } from './interface'
import { INITIAL_DATA } from './seed'
import DeleteIcon from 'assests/icons/DeleteIcon'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { getSpecialtyById } from 'services/Setting'

interface Props {
  loading?: boolean
  open?: boolean
  specialtyId?: string
  onCancel?: () => void
  onSubmit?: (payload: Specialty) => void
}

const createStyle = makeStyles({
  icd10_add_new: {
    display: 'flex',
    cursor: 'pointer'
  },
  icd10_delete_btn: {
    backgroundColor: '#ECF0F0',
    borderRadius: 8,
    padding: 12
  },
  error_text: {
    color: 'red'
  }
})

export const SpecialtyForm = ({ loading, ...props }: Props) => {
  const classes = createStyle()
  const notification = useNotification()

  const [data, setData] = useState(INITIAL_DATA)
  const [dataLoading, setDataLoading] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()

    if (props.specialtyId) {
      getPracticeDetails(props.specialtyId)
    }

    return () => abortController.abort()
  }, [props.specialtyId])

  yup.addMethod(yup.array, 'unique', function (message, mapper = a => a) {
    return this.test('unique', message, list => !list.some((v, i) => list.findLastIndex(l => v?.code === l?.code) > i))
  });


  const validationSchema = yup.object({
    specialty: yup
      .string()
      .required('Specialty is required!'),
    comment: yup.string(),
    type: yup
      .string(),
    conditions_treated: yup.array().of(
      yup.object().shape({
        code: yup.string().required('ICD 10 Code is required!'),
        description: yup.string(),
      })
    ).unique('ICD 10 code should be unique')
  })

  const getPracticeDetails = async (specialtyId: string) => {
    try {
      setDataLoading(true)
      const response = await getSpecialtyById(specialtyId)

      setData(response?.data ? {
        ...response?.data,
        conditions_treated: response?.data?.conditions_treated.length === 0
          ? [{
            code: '',
            description: ''
          }] : response?.data?.conditions_treated
      } : INITIAL_DATA)
    } catch (exception) {
      notification((exception as ErrorType).data?.message ||
        'Cannot get Specialty details', 'error')
    } finally {
      setDataLoading(false)
    }
  }

  const formik = useFormik({
    validationSchema,
    initialValues: data,
    enableReinitialize: true,
    onSubmit: values => props.onSubmit && props.onSubmit(values),
  })

  return (
    <Modal
      width={800}
      title={
        props.specialtyId
          ? 'Update Specialty'
          : 'Add New Specialty'
      }
      description="Specialty Details"
      open={props.open}
      onClose={props.onCancel}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      className="calendar-appointment-modal">
      <Grid
        container>
        {dataLoading && (
          <Loading visible={dataLoading} />
        )}

        <Grid container item>
          <Grid
            container
            item
            spacing={3}
            className="modal-spacer"
            style={{ marginBottom: 10, marginTop: 10 }}>

            <Grid
              container
              item
              spacing={2}>
              <Grid item xs={12}>

                <RadioGroup
                  value={formik.values.type}
                  name='type'
                  items={[
                    {
                      label: 'Medical',
                      value: 'medical', // FIXME:
                      // checked: true
                      onChange: checked => checked && formik.setFieldValue('type', 'medical')
                    },
                    {
                      label: 'BH Specialty',
                      value: 'bhSepcialty', // FIXME:
                      // checked: true
                      onChange: checked => checked && formik.setFieldValue('type', 'bhSepcialty')
                    }
                  ]}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}>
              <Grid item xs={12}>
                <TextBox
                  label="Specialty"
                  required
                  name="specialty"
                  placeholder="Enter Specialty"
                  value={
                    formik.values.specialty
                  }
                  error={
                    formik.touched.specialty &&
                    Boolean(
                      formik.errors.specialty,
                    )
                  }
                  helperText={
                    formik.touched.specialty &&
                    formik.errors.specialty
                  }
                  onChange={(value) =>
                    formik.setFieldValue(
                      'specialty',
                      value,
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}>
              <Grid item xs={12}>
                <TextBox
                  label="Comment"
                  name="comment"
                  value={
                    formik.values.comment
                  }
                  multiline
                  minRows={6}
                  placeholder="Enter Comment"
                  onChange={(value) =>
                    formik.setFieldValue(
                      'comment',
                      value,
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}>

              <Grid item container spacing={2}>
                <Grid item xs={5}>
                  <Typography style={{ fontWeight: 600 }} className='required'>ICD-10 Code</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography style={{ fontWeight: 600 }}>Description</Typography>
                </Grid>
              </Grid>
              {formik.values.conditions_treated.map((row: ICD10, index) =>
                <Grid item container spacing={2} key={index} wrap="nowrap">
                  <Grid item xs={5}>

                    <SearchICD10Code
                      required
                      name={`condition-treatment-code-${index}`}
                      placeholder='ICD-10 Code'
                      variant='ICD-10'
                      value={{
                        code: row.code,
                        display: row.description
                      }}
                      getOptionLabel={option => option.code || ''}
                      error={
                        formik.touched.conditions_treated &&
                        !!formik.errors.conditions_treated &&
                        formik.touched.conditions_treated[index]?.code &&
                        Boolean(
                          (formik.errors.conditions_treated as ICD10[])[index]?.code,
                        )
                      }
                      helperText={
                        formik.touched.conditions_treated &&
                        formik.errors.conditions_treated &&
                        formik.touched.conditions_treated[index]?.code &&
                        (formik.errors.conditions_treated as ICD10[])[index]?.code
                      }
                      onChange={value =>
                        formik.setFieldValue(
                          'conditions_treated',
                          formik.values.conditions_treated.map((record, idx) => {
                            if (idx === index) return { code: value?.code, description: value?.display }
                            return record
                          })
                        )
                      }
                    />
                  </Grid>

                  <Grid item xs={formik.values.conditions_treated.length === 1 ? 7 : 6}>
                    <TextBox
                      name={`condition-treatment-description-${index}`}
                      value={row.description}
                      placeholder="Enter Comment"
                    // disabled
                    />
                  </Grid>

                  {formik.values.conditions_treated.length > 0 && <Grid item xs={1}>
                    <IconButton
                      aria-label='Delete'
                      color='primary'
                      className={classes.icd10_delete_btn}
                      onClick={() => {
                        if (formik.values.conditions_treated.length === 1) {
                          formik.setFieldValue(
                            'conditions_treated',
                            [{
                              code: '',
                              description: ''
                            }]
                          )
                        } else {
                          formik.setFieldValue(
                            'conditions_treated',
                            formik.values.conditions_treated.filter((_, idx) => idx !== index)
                          )
                        }

                      }
                      }>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>}
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography className={classes.error_text}>{typeof formik?.errors?.conditions_treated === 'string' && formik?.errors?.conditions_treated}</Typography>
              </Grid>

              <Grid item>
                <span
                  className={classes.icd10_add_new}
                  onClick={() => formik.setFieldValue(
                    'conditions_treated',
                    [...formik.values.conditions_treated, { code: '', description: '' }]
                  )}>
                  <AddCircleOutlineOutlined
                    style={{
                      color: '#14AE5C',
                      fontSize: 18,
                    }}
                  />

                  <Typography
                    style={{
                      color: '#14AE5C',
                      marginLeft: 8,
                    }}
                  >
                    Add code
                  </Typography>
                </span>
              </Grid>

            </Grid>

            <Footer
              loading={loading}
              onCancel={props.onCancel}
              onOk={formik.handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}
