import * as yup from 'yup'

export const validationSchema = yup.object({
    due_schedule: yup
        .date()
        .nullable()
        .typeError('Invalid date')
        .min(
            yup.ref('received_date'),
            `'Due To Schedule' Cannot Be Smaller Than The 'Received Date'!`,
        ),
    referring_provider_id: yup.string().required('This field is required!'),
    patient_id: yup.string().required('This field is required!'),
    status: yup.string().required('This field is required!'),
    location_id: yup.string(),
    specialty_id: yup.string(),
    clinical_practice: yup.string().required('This field is required!'),
})
