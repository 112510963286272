import React, { useEffect, useState } from 'react'
import {
    Button,
    Grid,
    makeStyles,
    Typography,
    Box,
    CircularProgress,
    FormLabel,
    TextField,
} from '@material-ui/core'
import ReactFileUpload from 'react-butterfiles'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { HighlightOff } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'

import {
    getUploadAttachmentFilePresignedUrl,
    sendFax,
    getFaxById,
} from 'services/EFax'
import LoadingProgressPage from 'utilities/loading-progress-page'

import { USPhoneRegex } from 'utilities/regex'
import LoadingPage from 'utilities/loading-page'
import { useNotification } from 'shared/elements/Notification'
import TrashIcon from 'assests/icons/TrashIcon'
import AttachFileIcon from 'assests/icons/AttachFileIcon'
import { ButtonIconEl } from 'shared/elements'
import { SendFaxIcon } from 'assests/icons/SendFaxIcon'
import Routes from 'constants/routes'
import { useFaxMe } from '../hooks'
import { UserFaxInfo } from '../shared/UserFaxInfo'

import './styles.scss'

const useStyles = makeStyles(() => ({
    container: {
        zIndex: 1,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        marginBottom: '1rem',
    },
    show_more: {
        cursor: 'pointer',
        color: '#5571C6',
    },
    custom_btn: {
        height: 32,
        color: '#667689',
        background: '#E9ECF1',
        fontSize: 14,
        borderRadius: 30,
        fontWeight: 400,
        padding: '8px 32px',
        '&:hover': {
            background: '#E9ECF1',
        },
    },
    btn_send: {
        backgroundColor: '#5571C6',
        color: '#ffffff',
        '&:hover': {
            background: '#5571C6',
        },
    },
}))

const INITIAL_VALUES = {
    attachments_key: '',
    faxNumber: '',
    from: '',
    recipientName: '',
    senderName: '',
    subject: '',
    title: '',
    callerId: '',
    coverPage: '',
    coverPageId: '',
    tags: '',
    notes: '',
}

function FaxDialog(props) {
    const classes = useStyles()
    const history = useHistory()

    const { id: faxId } = useParams()
    const notification = useNotification()
    const { faxMe } = useFaxMe()

    // const [faxDetail] = useState({})
    // const [attachments, setAttachments] = useState([])

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [uploadedFiles, setUploadedFiles] = useState([])

    const loggedUser = useSelector(
        (state) => state?.login?.userData,
    )

    // const getListAttachment = async (faxId) => {
    //     try {
    //         const resp = await getFaxAttachmentFile(faxId)
    //         setAttachments(resp.data)
    //     } catch (error) {
    //         notification(
    //             error.data?.message ||
    //                 'Cannot get Fax attachment',
    //             'error',
    //         )
    //     }
    // }

    const getFaxDetails = async (faxId) => {
        try {
            const resp = await getFaxById(faxId)
            formik.setFieldValue(
                'title',
                resp.data?.title || '',
                false,
            )
            formik.setFieldValue(
                'notes',
                resp.data?.notes || '',
                false,
            )

            // TODO: Get attachments, attach to new forward file, and forward
            const files = JSON.parse(
                resp.data?.file_url || '[]',
            )
            if (files && files.length)
                setUploadedFiles(
                    files.map((file) => {
                        const paths = file.split('/') || []
                        const fileName =
                            paths[paths.length - 1] || file
                        return {
                            key: fileName,
                            name: fileName,
                            type: fileName.substring(
                                fileName.length - 3,
                            ),
                        }
                    }),
                )
        } catch (error) {
            notification(
                error.data?.message ||
                    'Cannot get Fax details',
                'error',
            )
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (faxId) {
            setDataLoading(true)

            Promise.allSettled([
                // getListAttachment(faxId),
                getFaxDetails(faxId),
            ]).finally(() => setDataLoading(false))
        }

        return () => abortController.abort()
    }, [faxId])

    const validationSchema = yup.object({
        from: yup
            .string()
            .matches(USPhoneRegex, 'Invalid fax number!')
            .required('Fax number is required!'),
        recipientName: yup.string(),
        // .required('Recipient Name is required!'),
        title: yup.string(),
        // .required('Subject is required!'),
        notes: yup.string(),
        // .required('Note is required!'),
        // attachments_key: yup.string().required('Attachment file is required!'),
    })

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        enableReinitialize: true,
        validationSchema,
        onSubmit: async (values) => {
            if (!faxMe) {
                return
            }
            try {
                setIsSubmitting(true)
                await sendFax({
                    attachments_key_list: uploadedFiles.map(
                        (item) => item.key,
                    ),
                    faxNumber: `+1${values.from}`,
                    recipientName: values.recipientName,
                    senderName: loggedUser?.name,
                    subject: values.title,
                    callerId: faxMe?.callerId,
                    tags: 'tag',
                    notes: values.notes,
                    coverPage: 'false',
                    coverPageId:
                        '5a4ab2d4-d358-4079-8afb-43323fd4fa43',
                })

                notification(
                    'Fax has been sent successfully',
                    'success',
                )

                redirectAfterSent()
            } catch (error) {
                notification(
                    'Error while sendind fax',
                    'error',
                )
            } finally {
                setIsSubmitting(false)
            }
        },
    })

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
                progressEvent.total,
        )
        setUploadProgress(percentCompleted)
    }

    const handleUploadAttachmentFile = async (
        files = [],
    ) => {
        for (let index = 0; index < files.length; index++) {
            try {
                const file = files[index]
                setIsUploading(true)
                setUploadProgress(0)
                const fileContext = await new Promise(
                    (resolve, reject) => {
                        const reader = new FileReader()
                        reader.addEventListener(
                            'loadend',
                            (event) => {
                                resolve(event.target.result)
                            },
                        )
                        reader.readAsArrayBuffer(
                            file?.src?.file,
                        )
                    },
                )
                const fileType = file?.src?.file?.type
                const res =
                    await getUploadAttachmentFilePresignedUrl(
                        file?.src?.file?.name,
                    )
                const { url, key } = res.data
                file.key = key
                await axios.put(url, fileContext, {
                    headers: {
                        'Content-Type': fileType,
                    },
                    onUploadProgress: onUploadProgress,
                })
            } finally {
                setIsUploading(false)
            }
        }
        setUploadedFiles([...uploadedFiles, ...files])
    }

    // console.log(uploadedFiles, 'uploadedFiles')

    const redirectAfterSent = () =>
        history.push(Routes.EFax.OUTGOING)

    const handleDiscard = () =>
        history.push(Routes.EFax.INCOMING)

    if (isLoading) {
        return <LoadingPage />
    }

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap">
            <Grid
                container
                onClick={handleDiscard}
                style={{
                    cursor: 'pointer',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>
                    Back to Fax list
                </Typography>
            </Grid>

            <Grid container>
                <Grid
                    container
                    direction="column"
                    item
                    xs={6}>
                    <Typography className={classes.header}>
                        {faxId ? 'Forward' : 'New Fax'}
                    </Typography>

                    <UserFaxInfo faxMe={faxMe} />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    xs={6}
                    justifyContent="flex-end">
                    <Grid item>
                        <ReactFileUpload
                            multipleMaxSize="10mb"
                            multiple={true}
                            maxSize="10mb"
                            Extension
                            MIME
                            Type
                            // .https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
                            accept={[
                                'image/jpg',
                                'image/jpeg',
                                'image/png',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-powerpoint',
                                'application/pdf',
                            ]}
                            onSuccess={(files) =>
                                handleUploadAttachmentFile(
                                    files,
                                )
                            }
                            onError={(errors) => {
                                formik.setFieldError(errors)
                                const multipleMaxSizeError =
                                    errors.find(
                                        (error) =>
                                            error.type ===
                                            'multipleMaxSizeExceeded',
                                    )
                                if (multipleMaxSizeError) {
                                    notification(
                                        'Please attach files no more than 10MB',
                                        'error',
                                        4000,
                                    )
                                }
                            }}>
                            {({
                                browseFiles,
                                getDropZoneProps,
                                getLabelProps,
                            }) => (
                                <Button
                                    variant="contained"
                                    className={
                                        classes.custom_btn
                                    }
                                    disabled={isSubmitting}
                                    onClick={browseFiles}
                                    startIcon={
                                        <AttachFileIcon />
                                    }>
                                    Attach
                                </Button>
                            )}
                        </ReactFileUpload>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={isSubmitting}
                            className={classes.custom_btn}
                            onClick={handleDiscard}
                            startIcon={<TrashIcon />}>
                            Discard
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={isSubmitting}
                            className={`${classes.custom_btn} ${classes.btn_send}`}
                            onClick={() =>
                                formik.handleSubmit()
                            }
                            startIcon={<SendFaxIcon />}
                            endIcon={
                                <>
                                    {isSubmitting ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={15}
                                        />
                                    ) : null}
                                </>
                            }>
                            {faxId ? 'Forward' : 'Send'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                item
                justifyContent="center">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel
                                component="p"
                                className="d-flex align-center required">
                                Fax Number
                            </FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    '& .MuiOutlinedInput-root':
                                        {
                                            height: 'auto',
                                        },
                                }}>
                                <TextField
                                    value={
                                        formik.values.from
                                    }
                                    placeholder="Enter Fax Number"
                                    variant="outlined"
                                    fullWidth
                                    {...props}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'from',
                                            e.target.value?.trim(),
                                        )
                                    }
                                    error={Boolean(
                                        formik.errors.from,
                                    )}
                                    helperText={
                                        formik.errors
                                            .from &&
                                        formik.errors.from
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel className="">
                                Recipient Name
                            </FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Box>
                                <TextField
                                    value={
                                        formik.values
                                            .recipientName
                                    }
                                    placeholder="Enter Recipient Name"
                                    variant="outlined"
                                    fullWidth
                                    {...props}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'recipientName',
                                            e.target.value,
                                        )
                                    }
                                    // error={Boolean(
                                    //     formik.errors
                                    //         .recipientName,
                                    // )}
                                    // helperText={
                                    //     formik.errors
                                    //         .recipientName &&
                                    //     formik.errors
                                    //         .recipientName
                                    // }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <FormLabel
                                component="p"
                                className="d-flex align-center">
                                Subject
                            </FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    '& .MuiOutlinedInput-root':
                                        {
                                            height: 'auto',
                                        },
                                }}>
                                <TextField
                                    value={
                                        formik.values.title
                                    }
                                    placeholder="Enter Subject"
                                    variant="outlined"
                                    fullWidth
                                    {...props}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'title',
                                            e.target.value,
                                        )
                                    }
                                    // error={Boolean(
                                    //     formik.errors.title,
                                    // )}
                                    // helperText={
                                    //     formik.errors
                                    //         .title &&
                                    //     formik.errors.title
                                    // }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs={2}>
                            <FormLabel
                                component="p"
                                className="d-flex justify-space-between align-center">
                                Note
                            </FormLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Box
                                sx={{
                                    '& .MuiOutlinedInput-root':
                                        {
                                            height: 'auto',
                                        },
                                }}>
                                <TextField
                                    value={
                                        formik.values.notes
                                    }
                                    multiline
                                    minRows={10}
                                    placeholder="Enter Note"
                                    variant="outlined"
                                    fullWidth
                                    {...props}
                                    onChange={(e) =>
                                        formik.setFieldValue(
                                            'notes',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                        <Grid container spacing={1}>
                            {uploadedFiles.map(
                                (item, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        container>
                                        <Box
                                            display="flex"
                                            alignItems="center">
                                            {item.type.includes(
                                                'image',
                                            ) && (
                                                <Grid item>
                                                    {/* <MicrosoftWordIcon /> */}
                                                    {/* <Image /> */}
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Typography
                                                    style={{
                                                        marginLeft: 6,
                                                    }}>
                                                    {
                                                        item.name
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Box>
                                        <ButtonIconEl
                                            onClick={() => {
                                                setUploadedFiles(
                                                    uploadedFiles.filter(
                                                        (
                                                            file,
                                                        ) =>
                                                            file.id !==
                                                            item.id,
                                                    ),
                                                )
                                            }}
                                            size="small"
                                            className={
                                                classes.act_iconbtn
                                            }>
                                            <HighlightOff
                                                style={{
                                                    fontSize: 18,
                                                    color: '#9CA9B8',
                                                }}
                                            />
                                        </ButtonIconEl>
                                    </Grid>
                                ),
                            )}
                            {/* <Typography className={classes.show_more}>Show More</Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {isUploading && (
                <LoadingProgressPage
                    progress={uploadProgress}
                />
            )}
        </Grid>
    )
}

export default FaxDialog
