import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { SubstanceAbuseTreatmentHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { SubstanceAbuse } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import SubstanceAbuseTreatmentForm from './SubstanceAbuseTreatmentForm'
import { apiSubstanceAbuseTreatmentHistory } from 'services/Patient/apiSubstanceAbuseTreatmentHistory'
import { useNotification } from 'shared/elements/Notification'

const SubstanceAbuseTreatmentHistory = ({
    isInClinicalNote,
    patient_Id,
    encounter_id,
    afterActionOk,
    nonEditable
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    // State
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState('')
    const [substanceAbuseTreatment, setSubstanceAbuseTreatment] = useState<SubstanceAbuse[]>([])
    const [substanceAbuseTreatmentId, setSubstanceAbuseTreatmentId] = useState<string | false>(
        false,
    )
    const [loading, setLoading] = useState(false)
    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })
    // const [page, setPage] = useState(1)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [sortModel, setSortModel] = useState<GridSortModel>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const getSubstanceAbuseTreatmentHistoryList = async (patientId, page = 1) => {
        try {
            setLoading(true)

            const resp = await apiSubstanceAbuseTreatmentHistory.getAllbyPtientId(patientId, {
                page,
                size: limit,
            })
            if (resp.data.data) {
                setSubstanceAbuseTreatment(resp.data.data)
                setTotal(resp.data.paging.total)
                // if (!resp.data.data.length && resp.data.paging.total > 0) {
                //     setPage(page - 1)
                // }
            }
        } catch (ex) {
            notification('Cannot get list Substance Abuse Treatment History', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleEdit = (id) => {
        setSubstanceAbuseTreatmentId(id)
        setIsAddingNew(true)
    }
    const handleDelete = async (substanceAbuseId) => {
        if (patientId) {
            try {
                const res = await apiSubstanceAbuseTreatmentHistory.deleteSubstanceAbuseTreatment(
                    patientId,
                    substanceAbuseId,
                )
                if (res.data?.Status === 'SUCCESSED') {
                    notification(
                        'Substance Abuse Treatment History deleted successfully',
                        'success',
                    )
                    await getSubstanceAbuseTreatmentHistoryList(patientId, page)
                    afterActionOk?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            }
        }
    }

    useEffect(() => {
        if (patientId) {
            getSubstanceAbuseTreatmentHistoryList(patientId, page)
        }
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{
                position: 'relative',
                overflow: 'initial',
                padding: isInClinicalNote ? 0 : '16px 24px',
            }}>
            <Loading visible={loading} />

            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}
            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent={isInClinicalNote ? 'flex-end' : 'space-between'}>
                    {!isInClinicalNote && (
                        <Typography className={classes.header}>
                            Substance Abuse Treatment History
                        </Typography>
                    )}

                    <ButtonCustom
                        tooltip="Add New Substance Abuse Treatment History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setIsAddingNew(true)}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <SubstanceAbuseTreatmentHistoryDashboard
                    page={page}
                    onPageChange={handleChangePage}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    dataSource={substanceAbuseTreatment}
                    pagination={{ start: page, limit, total }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    nonEditable={nonEditable}
                />
                {isAddingNew && (
                    <SubstanceAbuseTreatmentForm
                        substanceAbuseTreatmentId={substanceAbuseTreatmentId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        open={isAddingNew}
                        onClose={() => {
                            setIsAddingNew(false)
                            setSubstanceAbuseTreatmentId(false)
                        }}
                        onOke={async () => {
                            setIsAddingNew(false)
                            setSubstanceAbuseTreatmentId(false)
                            await getSubstanceAbuseTreatmentHistoryList(patientId, page)
                            afterActionOk?.()
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(SubstanceAbuseTreatmentHistory)
