import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    customTooltip: {
        backgroundColor: '#fff !important',
        color: '#516983 !important',
        fontSize: '14px !important',
        borderRadius: '4px !important',
        padding: '20px !important',
        maxWidth: '200px !important', // Limit the width of the tooltip
        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2) !important',
    },
}))

function InfoPopOver(props) {
    const classes = useStyles()

    return (
        <Tooltip title={props.title} classes={{ tooltip: classes.customTooltip }}>
            {props.children}
        </Tooltip>
    )
}

export default InfoPopOver
