import { USPhoneRegex } from './regex'
import moment from 'moment'
import { config } from '../constants'
import { createDecipheriv } from 'crypto'
import localforage from 'localforage'

const roleOptions = {
    tm2u_super_admin: 'Super Admin',
    tm2u_admin: 'Admin',
    billing_admin: 'Billing Admin',
}

export const resetLocalStorage = () => {
    localStorage.clear()
}

export const getIsLoggedIn = () =>
    !!localStorage.getItem('token')

export const getAuthToken = () =>
    localStorage.getItem('token')

export const getIsPatientDetailCompleted = () =>
    localStorage.getItem('isProfileComplete') === 'true'

export const getLoggedInUserRole = () =>
    localStorage.getItem('role')

export const getLoggedInUserId = () =>
    localStorage.getItem('userId')

export const getLoggedInUserName = () =>
    localStorage.getItem('name')

export const capitalizeFirstLetter = (str = '') => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}

export const maskPhoneNumber = (str = '') => {
    const splittedNumber = str
        .substr(str.length - 10)
        .match(USPhoneRegex)
    return splittedNumber?.length === 4
        ? `(${splittedNumber[1]}) ${splittedNumber[2]} ${splittedNumber[3]}`
        : ''
}

export const maskPhoneNumberAlt = (str = '') => {
    const splittedNumber = str
        .substr(str?.length - 10)
        .match(USPhoneRegex)
    return splittedNumber?.length === 4
        ? `${splittedNumber[1]} ${splittedNumber[2]} ${splittedNumber[3]}`
        : ''
}

export const formatSlotTime = (slot) => {
    return slot
        ? moment(slot, 'HH:mm:ss').format('hh:mm A')
        : ''
}

export const getLocalDateTime = (
    date,
    time = '',
    format = 'MM/DD/YYYY',
) => {
    return moment
        .utc(`${date} ${time}`.trim())
        .local()
        .format(format)
}

export const getQueryParams = (
    searchString,
    qsArr = [],
) => {
    const urlSearchParams = new URLSearchParams(
        searchString,
    )
    const queryStrings = {}
    qsArr.forEach((qsa) => {
        queryStrings[qsa] = urlSearchParams.get(qsa)
    })
    return queryStrings
}

export const decryptUrl = (encryptedIv, encryptedData) => {
    try {
        if (config.KEY) {
            const iv = Buffer.from(encryptedIv, 'hex')
            const encryptedText = Buffer.from(
                encryptedData,
                'hex',
            )
            const decipher = createDecipheriv(
                'aes-256-cbc',
                Buffer.from(config.KEY),
                iv,
            )
            let decrypted = decipher.update(encryptedText)
            decrypted = Buffer.concat([
                decrypted,
                decipher.final(),
            ])

            return decrypted.toString()
        }
    } catch (e) {
        console.log('error===>', e)
        return ''
    }
}

export const bytesToMB = (bytes, roundTo) =>
    roundTo
        ? (bytes / (1024 * 1024)).toFixed(roundTo)
        : bytes / (1024 * 1024)

export const validatePdfFile = (files) =>
    files.every((f) => f.type.includes('pdf'))

export const calculateAge = (dob) =>
    moment().diff(dob, 'years')

export const getTZoffset = () => {
    const pad = (number, length) => {
        let str = `${  number}`
        while (str.length < length) {
            str = `0${  str}`
        }
        return str
    }
    let offset = new Date().getTimezoneOffset()
    offset =
        `${(offset < 0 ? '+' : '-') + // Note the reversed sign!
        pad(parseInt(Math.abs(offset / 60)), 2) 
        }:${ 
        pad(Math.abs(offset % 60), 2)}`

    return offset
}

export const sortTimeSlot = (slots) => {
    const newArr = slots.map((s) => ({
        ...s,
        slot: getLocalDateTime(
            moment(s.date).format('YYYY-MM-DD'),
            s.slot,
            'HH:mm:ss',
        ),
    }))

    const sortedSlot = newArr.sort((slotA, slotB) => {
        const slotATime = moment(
            `${slotA.date} ${slotA.slot}`.trim(),
        )
        const slotBTime = moment(
            `${slotB.date} ${slotB.slot}`.trim(),
        )
        const diff = moment(slotATime).diff(
            moment(slotBTime),
        )
        return diff
    })

    return sortedSlot
}

export const formatDate = (date, format = 'MM/DD/YYYY') =>
    moment(date).format(format)

export const getNameFormat = (firstName, lastName) =>
    `${firstName} ${lastName}`
        .toString()
        .replace(
            /(^\w|\s\w)(\S*)/g,
            (_, m1, m2) =>
                m1.toUpperCase() + m2.toLowerCase(),
        )

// Need to move in constant file @anand
export const maxCharLength = 40
export const maxCharLengthZip = 5

export const formatPhone = (phone) => {
    if (!phone) return ''
    return phone
    // if (phone && phone !== '') {
    //     const char = {
    //         0: '(',
    //         3: ') ',
    //         6: ' ',
    //     }
    //     let number = ''
    //     phone = phone.replace(/\D/g, '')
    //     for (let i = 0; i < phone.length; i++) {
    //         number += (char[i] || '') + phone[i]
    //     }
    //     return number
    // }
    // return ''
}

// masking underscore '_' and replace with ' ' ,
export const formatRoleName = (role_name) => {
    if (role_name) {
        /* return role_name
            .split('_')
            .map(
                (word) =>
                    word.charAt(0).toUpperCase() +
                    word.slice(1),
            )
            .join(' '); */
        return roleOptions[role_name]
    }
    return ''
}

export const getCountryCode = (phone) => {
    //check dev qa and uat and return value
    //for now hardcoded for testing
    return process.env.REACT_APP_COUNTRY_CODE
}

export function returnLevelOf(value) {
    if (value === 0) return 'not at all'
    if (value >= 1 && value <= 3) return 'mildly'
    if (value >= 4 && value <= 6) return 'moderately'
    if (value >= 7 && value <= 9) return 'markedly'
    if (value === 10) return 'extremely'
}
export function gettingUpdated() {
    const value = localStorage.getItem('lastActive')
    const consultingPatient = localStorage.getItem('isConsultant') ? localStorage.getItem('isConsultant') : false
    const lastMove = value ? moment(value) : moment()
    const diff = moment().diff(lastMove, 'minutes')
    if(diff >= 60 && !consultingPatient){
        localStorage.clear()
        sessionStorage.clear()
        localforage.clear()
        return false
    }
    return true
}

export const getPaginatedData = (res)=> {
    const tempUserList = []
    res?.data?.data.map((item) =>
        tempUserList.push({
            label: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${item.last_name
            }`,
            value: item?.id,
            dob: `${moment(new Date(item.dob)).format('MM/DD/YYYY')}`,
        }),
    )
    const b = res?.data?.totalCount / 10
    const remainder = res?.data?.totalCount % 10
    let result
    if (remainder > 0) {
        result = Math.floor(b) + 1
    } else {
        result = b
    }
    return {tempUserList, bundle_id:res?.data?.bundle_id, result}
}

export const mapToFiltersFormat = (list)=> {
    return list.map(({ label, value, dob}) => ({
        key: value,
        value: label,
        dob
    }));
}
