import CircularProgress from '@mui/material/CircularProgress'
import { ErrorOutline } from '@material-ui/icons'
import InfoPopOver from 'components/shared/Elements/InfoPopOver'
import { TextField } from '@material-ui/core'
import React from 'react'
import type { BaseType, SearchBoxInputProps } from './types'


export const SearchBoxInput = <T extends BaseType>({
    inputValue,
    icd10Obj,
    loading,
    isHelpIcon,
    name,
    placeholder,
    error,
    helperText,
    handleOnChange,
    setVisibility,
    setWarningSearch,
    ...params
}: SearchBoxInputProps<T>) => {
    const renderProps = {
        ...params,
        InputProps: {
            ...params.InputProps,
            endAdornment: (
                <>
                    {loading ? (
                        <CircularProgress color="inherit" size={15} />
                    ) : params.inputProps?.value && isHelpIcon ? (
                        <InfoPopOver title={icd10Obj?.display || inputValue}>
                            <ErrorOutline
                                style={{
                                    fontSize: 15,
                                    color: '#9CA9B8',
                                    position: 'absolute',
                                    right: '1px',
                                    cursor: 'pointer',
                                }}
                            />
                        </InfoPopOver>
                    ) : null}
                </>
            ),
        },
    }

    return (
        <TextField
            variant="outlined"
            name={name || ''}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            {...renderProps}
            onBlur={() => {
                if (!inputValue) handleOnChange?.(null)
                setVisibility(false)
                setWarningSearch(false)
            }}
        />
    )
}