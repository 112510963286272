import * as actionType from '../actionType'
import { setLoading, setError } from './loader'
import {
    getSpecialities,
    getClinicsBySpeciality,
    getClinicsByZip,
    bookAppointment,
    getAppointmentlist,
    cancelAppointmentById,
    rescheduleAppointment,
    updateAppointmentStatusById,
    getIntakeFormData,
    saveIntakeFormData,
    getAppointmentDetails,
    getSearchPatientlist,
    addpatientdetail,
    getMarkConsultationStatusList,
    getClinicById,
} from '../../services/Appointments/appointments'

export const getAllSpecialities = (clinicId) => {
    return (dispatch) => {
        getSpecialities(clinicId)
            .then((res) => {
                dispatch(setSpecialities(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
            })
    }
}

export const fetchClinicById = (clinicId) => {
    return (dispatch) => {
        getClinicById(clinicId)
            .then((res) => {
                dispatch(setClinicDetail(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
            })
    }
}

export const getClinicListBySpeciality = (speciality) => {
    return (dispatch) => {
        dispatch(setClinicList([]))
        getClinicsBySpeciality(speciality)
            .then((res) => {
                dispatch(setClinicList(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
            })
    }
}

export const getClinicListByZip = (payload) => {
    return (dispatch) => {
        setLoading(true)
        dispatch(setClinicList([]))
        getClinicsByZip(payload)
            .then((res) => {
                setLoading(false)
                dispatch(setClinicList(res.data))
            })
            .catch((err) => {
                setLoading(false)
            })
    }
}

export const saveAppointmentData = (
    payload,
    onSuccess,
    isRescheduleAppointment = false,
) => {
    const func = { bookAppointment, rescheduleAppointment }
    return (dispatch) => {
        dispatch(setLoading(true))
        func[
            isRescheduleAppointment
                ? 'rescheduleAppointment'
                : 'bookAppointment'
        ](payload)
            .then((res) => {
                dispatch(setLoading(false))

                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                if (
                    err &&
                    err.data &&
                    err.data.status === false
                ) {
                    dispatch(
                        setAppointmentError(
                            err.data.message,
                        ),
                    )
                }
            })
    }
}

export const updateAppointmentStatus = (payload) => {
    return (dispatch) => {
        updateAppointmentStatusById(payload)
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }
}

export const Appointmentlist = (payload) => {
    return (dispatch) => {
        // dispatch(setLoading(true));
        // getAppointmentlist(payload)
        //     .then((res) => {
        //         dispatch(setLoading(false));
        //         const appointments = res.data.appointments;
        //         const totalcount = res.data.totalCount;
        //         dispatch(setAppointmentlist(appointments));
        //         dispatch(setAppointmentcount(totalcount));
        //     })
        //     .catch((err) => {
        //         dispatch(setLoading(false));
        //         dispatch(setError(true));
        //         dispatch(setAppointmentlist([]));
        //         dispatch(setAppointmentcount([]));
        //     });
    }
}

export const cancelAppointment = (
    appointmentId,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        cancelAppointmentById({
            appointment_id: appointmentId,
        })
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const fetchAppointmentDetails = (
    appointmentId,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getAppointmentDetails(appointmentId)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess(res.data)
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const fetchAppointmentIntakeData = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getIntakeFormData(payload)
            .then((res) => {
                if (
                    res &&
                    res.data &&
                    res.data.intake_form
                ) {
                    onSuccess && onSuccess()
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const saveAppointmentIntakeData = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        saveIntakeFormData(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const PatientSearchlist = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getSearchPatientlist(payload)
            .then((res) => {
                dispatch(setLoading(false))
                const searchlist = res.data
                console.log('====Data====', searchlist)
                dispatch(setSearchPatientlist(searchlist))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setSearchPatientlist([]))
            })
    }
}
export const AddPatient = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        addpatientdetail(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess(res.data)
                dispatch(setPatientlist(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setPatientlist([]))
            })
    }
}

export const setSpecialities = (value) => {
    return {
        type: actionType.SET_SPECIALITIES,
        value,
    }
}

export const setAppointmentlist = (value) => {
    return {
        type: actionType.SET_APPOINTMENTLIST,
        value,
    }
}
export const setAppointmentcount = (value) => {
    return {
        type: actionType.SET_APPOINTMENTLIST_COUNT,
        value,
    }
}

export const setClinicList = (value) => ({
    type: actionType.SET_CLINIC_LIST,
    value,
})

export const setSelectedProviderDetails = (value) => ({
    type: actionType.SET_SELECTED_PROVIDER_DETAILS,
    value,
})

export const setSearchProviderData = (value) => ({
    type: actionType.SET_SEARCH_PROVIDER_DATA,
    value,
})

export const setAppointmentFlowType = (value) => ({
    type: actionType.SET_APPOINTMENT_FLOW_TYPE,
    value,
})

export const setBookAppointmentData = (value) => ({
    type: actionType.SET_BOOK_APPOINTMENT_DATA,
    value,
})

export const setAppointmentError = (value) => ({
    type: actionType.SET_APPOINTMENT_ERROR,
    value,
})

export const setIsRescheduleAppointment = (value) => ({
    type: actionType.SET_IS_RESCHEDULE_APPOINTMENT,
    value,
})

export const setSelectedAppointmentData = (value) => ({
    type: actionType.SET_SELECTED_APPOINTMENT_DATA,
    value,
})

export const resetAppointmentsData = () => ({
    type: actionType.RESET_APPOINTMENTS_DATA,
})

export const setSearchPatientlist = (value) => {
    return {
        type: actionType.SET_PATIENTLIST,
        value,
    }
}
export const setPatientlist = (value) => {
    return {
        type: actionType.SET_ADDPATIENTLIST,
        value,
    }
}

export const setMarkConsultationStatusList = (value) => ({
    type: actionType.SET_MARK_CONSULTATION_STATUS,
    value,
})

export const markConsultationuStatusList = (
    providerId,
    onSuccess,
) => {
    return (dispatch) => {
        let result = []
        dispatch(setLoading(true))
        getMarkConsultationStatusList(providerId)
            .then((response) => {
                result = response.data

                dispatch(setLoading(false))

                onSuccess && onSuccess(response.data)
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
            .finally(() => {
                dispatch(
                    setMarkConsultationStatusList(result),
                )
            })
    }
}

export const setSelectedPatientDetails = (value) => ({
    type: actionType.SET_SELECTED_PATIENT_DETAILS,
    value,
})

export const setSelectedMinorDetails = (value) => ({
    type: actionType.SET_SELECTED_MINOR_DETAILS,
    value,
})

export const setClinicDetail = (value) => ({
    type: actionType.SET_CLINIC_DETAILS,
    value,
})

export const setUserDetails = (value) => ({
    type: actionType.SET_USER_DETAILS,
    value,
})
