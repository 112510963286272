import { Box, Paper, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Card from '@mui/material/Card';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import format from 'date-fns/format'

function DateRangeField(props) {
    const [data1, setData1] = useState(props.startDate || null)
    const [data2, setData2] = useState(props.endDate || null)
    const [error, setError] = useState(false)
    const [DataMaker, setDataMaker] = useState({ startDate: null, endDate: null })

    useEffect(() => {
        setData1(props.startDate)
        setData2(props.endDate)
    }, [props.startDate, props.endDate])

    const sendata = () => {
        const Data = {
            startDate: data1,
            endDate: data2,
            key: 'selection'
        }

        if (((data1 && data2) && ((data2 > data1))) || ((!data2 && !data1))) {
            setError(false)
            props.onChange(Data)
        } else {
            setError(true)
        }

    }
    return (
        <>
            <Paper elevation={3} style={{ width: "314px", position: "relative", backgroundColor: "#f4f4f4", zIndex: "10", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }}>

                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { minWidth: '0px' },

                    }}
                    noValidate
                    autoComplete="off"
                    onClick={props.onClick}
                    style={{ width: "100%", display: "flex", flexDirection: "column", padding: "12px" }}
                >
                    <div style={{ width: "100%", display: "flex", marginTop: "8px", padding: "2px" }}>
                        <div>From</div>
                        <div style={{ marginLeft: "132px" }}>To</div>
                    </div>
                    <div style={{ display: "flex" }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DateField', 'DateField']}>
                                <DateField
                                    sx={{
                                        '& .MuiInputBase-root': { width: '118px' },

                                    }}
                                    value={dayjs(data1)}
                                    placeholder={props.placeholder1}
                                    onChange={(value) => {
                                        if (value) {
                                            setData1(value.$d ?? '')
                                        }
                                        else {
                                            setData1('')
                                        }
                                    }}
                                    onError={(error) => {
                                        if (!error) {
                                            sendata()
                                        }
                                    }}
                                /><ArrowRightAltIcon style={{ marginTop: '5px', color: 'grey', marginLeft: '12px' }} />
                                <DateField
                                    name='inpt_2'
                                    id="outlined-size-small"
                                    size="small"
                                    variant='outlined'
                                    placeholder={props.placeholder2}
                                    onChange={(value) => {
                                        if (value) {
                                            setData2(value.$d ?? '')
                                        } else {
                                            setData2('')
                                        }


                                    }}
                                    value={dayjs(data2)}
                                    sx={{
                                        '& .MuiInputBase-root': { width: '118px' },

                                    }}
                                    onError={(error, errvalue) => {
                                        if (!error) {
                                            sendata()
                                        }
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>

                    {error && <p style={{ color: "blue", fontSize: "14px" }}>To-Date must be greater than From-Date</p>}
                </Box >
            </Paper >
        </>
    )
}

export default DateRangeField