import { Box, makeStyles, Typography } from '@material-ui/core'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Stack from '@mui/material/Stack'
import { DataGrid } from '@mui/x-data-grid'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { Button as ButtonCustom } from 'components/custom'
import { apiStatusCodes } from 'services/Calendar'
import { NoRowsOverlayEl, SelectEl } from 'shared/elements'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'

import { AppointmentStatusCreateDialog } from './AppointmentStatusCreateDialog'
import { SearchLabel } from './component/SearchLabel'
import { ToggleBtn } from './component/ToggleBtn.tsx'
import ButtonTextTooltip from 'components/custom/TooltipButton/ButtonTextTooltip'
import { Grid } from '@mui/material'
import { STATUS_CODE_CATEGORY } from './AppointmentStatusCreateDialog/constant'
import { useDebounce } from 'shared/hooks'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
})

const AppointmentStatusSettings = (props) => {
    const classes = useStyles()
    const notification = useNotification()

    // const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState({
        status: false,
    })
    const [optionCategory, setOptionCategory] = useState([{ key: '', value: 'All' }])
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const [isClear, setIsClear] = useState(false)

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const permissions = useSelector((state) => state?.permission?.status_code)

    const onPageChange = (value) => {
        setPage(value)
    }

    function appointmentModalToggle(itemId) {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId,
        })
    }

    const columns = [
        {
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return `${value}`
            },
        },
        {
            headerName: 'Code',
            headerClassName: 'super-app-theme--header',
            field: 'code',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },

        {
            headerName: 'Color',
            headerClassName: 'super-app-theme--header',
            field: 'color',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span
                        className={clsx({
                            [classes.status_box]: true,
                        })}
                        style={{
                            background: value,
                        }}></span>
                )
            },
        },
        {
            headerName: 'Category',
            headerClassName: 'super-app-theme--header',
            field: 'category',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return <Box>{value}</Box>
            },
        },
        {
            headerName: 'Description',
            headerClassName: 'super-app-theme--header',
            field: 'description',
            // width: 100,
            flex: 2.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_status_code') &&
                !permissions.includes('delete_status_code'),
            renderCell: ({ row }) => {
                const menu = []
                if (permissions.includes('modify_status_code')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => appointmentModalToggle(row.id),
                    })
                }
                if (permissions.includes('delete_status_code')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDeleteLocation(row.id),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Status Code?"
                    />
                )
            },
        },
    ]

    const handleDeleteLocation = (appointmentStatusId) =>
        apiStatusCodes
            .deleteStatus(appointmentStatusId)
            .then(() => {
                refetchStatus()
                notification('A Status was successfully deleted!', 'success')
            })
            .catch((error) => {
                notification(
                    error?.data?.message || 'A Status was not successfully deleted!',
                    'error',
                )
            })

    async function handleCreate(payload) {
        try {
            setIsSubmitting(true)

            if (appointmentModal?.itemId) {
                await apiStatusCodes
                    .updateStatus(appointmentModal.itemId, payload)
                    .then(() => {
                        appointmentModalToggle()
                        notification('A Status was successfully updated!', 'success')
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message || 'A Status was not successfully updated!',
                            'error',
                        )
                    })
            } else {
                await apiStatusCodes
                    .createStatus(payload)
                    .then(() => {
                        appointmentModalToggle()
                        notification('A Status was successfully added!', 'success')
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message || 'A Status was not successfully added!',
                            'error',
                        )
                    })
            }

            setIsSubmitting(false)
            refetchStatus()
        } catch (error) {
            console.log({ error })
        }

        setIsSubmitting(false)
    }

    const getCategoryStatusCode = async () => {
        try {
            // const dataOption = STATUS_CODE_CATEGORY.map((item) => ({
            //     key: item,
            //     value: item.replace('_', ' ').toLowerCase(),
            // }))
            setOptionCategory([{ key: '', value: 'All' }, ...STATUS_CODE_CATEGORY])
        } catch (error) {
            console.log(error)
        }
    }

    const refetchStatus = async (start = page, name = textSearchName, category = filedPecialty) => {
        setLoading(true)
        try {
            const res = await apiStatusCodes.getStatusCodes(
                sortModel[0]?.sort
                    ? {
                        page: start,
                        limit,
                        category,
                        direction: sortModel[0].sort.toUpperCase(),
                        key: sortModel[0].field,
                        name: name.trim(),
                    }
                    : {
                        page: start,
                        limit,
                        category,
                        name: name.trim(),
                    },
            )
            setLoading(false)
            setPatientList(res.data?.listStatusCode?.data || [])
            setTotal(res.data?.listStatusCode?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        getCategoryStatusCode()
    }, [])

    const [textSearchName, setTextSearchName] = useState('')
    const [textSearchCategory, setTextSearchCategory] = useState('')

    const [category, setCategory] = useState(false)
    const [filedPecialty, setFiledPecialty] = useState('')

    const debouncedTextSearchName = useDebounce(textSearchName, 500)

    const handleSearch = async () => {
        if (page !== 1) setPage(1)
        else await refetchStatus(1)
    }

    const handleResetPage = async () => {
        setIsClear(true)

        setTextSearchName('')
        setFiledPecialty('')

        if (page !== 1) setPage(1)
        else await refetchStatus(1, '', '')

        setTimeout(() => {
            setIsClear(false)
        }, 1000)
    }

    useEffect(() => {
        refetchStatus()
    }, [page, sortModel])

    useEffect(() => {
        if (isClear) return

        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        refetchStatus(1)
    }, [debouncedTextSearchName])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Grid item sx={{ width: '150px' }}>
                        <Typography className={classes.header}>Status Codes</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Stack spacing={1.2} direction="row">
                                    <Grid
                                        item
                                        style={{
                                            width: 160,
                                            flex: 1,
                                        }}>
                                        <SelectEl
                                            placeholder="Category"
                                            name="location"
                                            value={filedPecialty}
                                            options={optionCategory}
                                            onChange={(e) => {
                                                setFiledPecialty(e.target.value)

                                                if (page !== 1) return setPage(1)
                                                refetchStatus(1, undefined, e.target.value)
                                            }}
                                            style={{
                                                height: 32,
                                                minHeight: 32,
                                                maxWidth: 160,
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            width: 160,
                                            flex: 1,
                                        }}>
                                        <SearchLabel
                                            placeholder="Name"
                                            onSearch={(value) => setTextSearchName(value)}
                                            textSearch={textSearchName}
                                            clearText={() => {
                                                setTextSearchName('')
                                            }}
                                        />
                                    </Grid>
                                    <ToggleBtn onChangStt={handleSearch} label="" height={'31px'} />
                                    <ButtonTextTooltip
                                        tooltip="Clear filter"
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            '&.MuiButton-contained': {
                                                height: '31px !important',
                                                background: '#5686b5',
                                                width: '50px',
                                                minWidth: 'auto',
                                                color: 'white',
                                                fontSize: '12px',
                                                fontWeight: 400,
                                                textTransform: 'capitalize',
                                                borderRadius: '8px',
                                                '&:hover': {
                                                    backgroundColor: '#303f9f',
                                                },
                                                // marginRight: '15px',
                                            },
                                        }}
                                        onClick={handleResetPage}>
                                        Clear
                                    </ButtonTextTooltip>
                                    <RBACProvider permissions={permissions}>
                                        <RBACWrapper requiredPermissions={['create_status_code']}>
                                            <ButtonCustom
                                                tooltip="Add New Status"
                                                variant="contained"
                                                color="primary"
                                                onClick={() => appointmentModalToggle()}
                                                fullWidth>
                                                <AddIcon
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </ButtonCustom>
                                        </RBACWrapper>
                                    </RBACProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: '600',
                                color: '#303e4e',
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                        {
                            width: 'auto',
                            visibility: 'visible',
                        },
                    }}>
                    <DataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={onPageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        componentsProps={{
                            pagination: {
                                onPageChange,
                                total,
                                page,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                total,
                                numberRecord: limit,
                                page,
                                pageSize: limit,
                            },
                        }}
                    />
                </Box>
            </Grid>

            {appointmentModal.status && (
                <AppointmentStatusCreateDialog
                    open={appointmentModal.status}
                    optionCategory={optionCategory}
                    itemId={appointmentModal.itemId}
                    loading={isSubmitting}
                    onModalClose={appointmentModalToggle}
                    onSubmit={handleCreate}
                />
            )}
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(AppointmentStatusSettings)
