import React, { useState, lazy, useEffect } from 'react'
import {
    Grid,
    Typography,
    Box,
    FormLabel,
    Button,
    Popover,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { CircularProgress, Stack } from '@mui/material'
import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { _getClinicalNotesDetail, fetchPDFData } from 'containers/ClinicalNotes/api/services'
import { useSelector } from 'react-redux'
import Divider from '@mui/material/Divider'
import './chartprepstyle.scss'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import ReactSimplyCarousel from 'react-simply-carousel';
import LoadingButton from '@mui/lab/LoadingButton'
import { getCustomizedFormattedDate } from '../../../../utilities/dateOperations'
import { makeStyles } from '@material-ui/styles'
import Paper from '@mui/material/Paper';
import {
    apiFollowUp,
    apiStatusCodes,
    getProivders,
    getScheduleById,
    getSpecialty,
    getVisitType,
    updateSchedule,
} from 'services/Calendar'
import { getPatient } from 'services/Patient/patient'
import { SelectEl } from 'shared/elements'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import moment from 'moment'
import NoteField from 'containers/WorkQueues/component/NoteField'
import { ReactComponent as TrashIcon } from '../../../../assests/icons/trash.svg'
import Card from '@mui/material/Card';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
const TextBox = lazy(() => import('shared/elements/FormItem/TextBox'))
const Select = lazy(() => import('shared/elements/FormItem/Select'))

const { v4: uuidv4 } = require('uuid')

const useStyles = makeStyles({
    item_ric: {
        background: '#fbfcfc',
        padding: '5px 15px',
        borderRadius: '7px',
        '& .icon': {
            '& svg': {
                paddingTop: '5px',
                width: '22px',
                color: '#6c6c6c',
            },
        },
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
        // height: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },

    unselectedBoxtxtcolor: {
        color: "#989CA8 !important"
    },
    unselectedBox: {
        border: "1.5px solid #D7DBE4 "
    },
    readOnlyInput: {
        backgroundColor: '#989CA8 !important', // Set your desired background color here
    },
    customPaper: {
        background: "white !important"
    },
    inputFormLabel: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#667689',
    },
    textBoxWidth: {
        width: '20rem',
    },
    customCard: {
        width: "180px", // set your desired width
        height: "80px", // set your desired height
        backgroundColor: '#ffffff',
        padding: "10px",
        cursor: "pointer",
        marginRight: "8px",
        marginBottom: "8px"
    },

    slectedCardStyle: {
        padding: "10px",
        cursor: "pointer",
        border: "1.5px solid #5571C6",
        boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 0.25)"
    },

    cardContainerStyle: {
        display: "flex",
        scrollBehavior: "smooth",
        border: "1px solid grey",
        borderRadius: "3px",
        padding: "5px",
        overflowX: "hidden",


    },

    arrowBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    reducedFontSize: {
        fontSize: '0.9rem', // Adjust the font size as needed
        color: "#303E4E"
    },
    statusContainer: {
        fontSize: "x-small",
        backgroundColor: '#dadae0',
        color: '#959596',
        alignItems: "center",
        display: "flex",
        paddingLeft: "5px",
        paddingRight: "5px",
        border: "1px solid #e6e6ff",
        borderRadius: "5px"
    }

})

const status = [
    {
        key: 'in_progress',
        backgroundColor: '#e6e6ff',
        color: '#9999ff',
        value: 'In progress',
        className: 'inprogress_button',
    },
    {
        key: 'hold',
        backgroundColor: '#ffffe6',
        color: '#ffff4d',
        value: 'Hold',
        className: 'hold_button',
    },
    {
        key: 'completed',
        backgroundColor: '#d4f7e0',
        color: '#00e699',
        value: 'Completed',
        className: 'completed_button',
    },

]

const CartPrepForm = () => {
    // Hooks
    const history = useHistory()
    const { id = null } = useParams()
    const notification = useNotification()
    const classes = useStyles()
    const [activeSlide, setActiveSlide] = useState(0);

    const [noteView, setNoteView] = useState('no-view') //values: no-view | view-only | can-edit

    const [submitting, setSubmitting] = useState(false)

    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const chkopen = Boolean(anchorEl)
    const IdPopover = chkopen ? 'status-popover' : undefined
    const [selectedStatus, setSelectedStatus] = useState(status[0])
    const [newChecklistItem, setChecklist] = useState('')
    const [preChecklist, setpreChecklist] = useState([])
    const [followUpData, setFollowUpdata] = useState({})
    const [providerOption, setProviderOptions] = useState([])
    const [isComplete, setIsComplete] = useState()
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const [patientStatusList, setPatientStatusList] = useState([])
    const [appointmentOptions, setAppointmentOptions] = useState([])

    const [fetchedAppointments, setFetchedAppointments] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [selectedappointment, setSelectedAppointment] = useState({})
    const [selectedCard, setSelectedCard] = useState(0)

    const initial_data = {
        id: 0,
        patient_name: '',
        patient_custom_id: '',
        patient_status: '',
        speciality: '',
        number_attempt: '',
        notes: [],
        note: '',
        health_plan: '',
        visit_type: '',
        appointment_status: '',
        duration: '',
        rendering_provider: '',
        noteView: 'no-view',
        checklists: [],
        checklists_status: false,
        chart_status: 'in_progress',
    }

    const handleSubmit = async (e) => {
        setSubmitting(true)
        let payload;
        if (id) {
            payload = {
                ...followUpData,
                notes: formik.values.notes,
                checklists: [...preChecklist],
                checklist_status: formik.values.checklists_status,
                chart_status: formik.values.chart_status,
            }
        }
        else {
            payload = {
                ...followUpData,
                ...selectedappointment,
                notes: formik.values.notes,
                checklists: [...preChecklist],
                checklist_status: formik.values.checklists_status,
                chart_status: formik.values.chart_status,

            }

        }

        try {
            await updateSchedule({
                id: id ? id : formik.values.id,
                payload,
            })

            notification('Chatprep Update Succesfully', 'success')
            if (!id) {
                history.push(
                    Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.CHART_PREP.EDIT.replace(':id', formik.values.id)
                )
            }
        } catch (error) {
            notification('Something Went Wrong', 'error')
        }

        setSubmitting(false)
    }


    const formik = useFormik({
        // validationSchema,
        enableReinitialize: true,
        initialValues: initial_data,
        onSubmit: handleSubmit,
    })

    const checklistactivater = (data) => {
        const modifiedData = preChecklist.map((item, idx) => ({
            description: item.description,
            ischecked: data,
        }))
        setpreChecklist(modifiedData)
    }

    const handleClose2 = () => {
        setAnchorEl(null)
    }

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (id === 0 || !id) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = formik.values?.notes
            }
            formik.setFieldValue('notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )

    const handleDeleteChecklist = (itemIndex, rowItem) => {
        const newRefferralIntakeCheck = preChecklist.filter((item, idx) => idx !== itemIndex)
        setpreChecklist(newRefferralIntakeCheck)
    }

    const handlePopover = (event) => {
        if (formik.values.checklists_status) return false
        setAnchorEl(event.currentTarget)
    }



    const followUpPopulation = (data) => {
        if (data) {
            formik.setFieldValue('patient_name', data.participant[0]?.participant_id)
            formik.setFieldValue('patient_custom_id', data.patient_custom_id)
            formik.setFieldValue('patient_status', data.patient_status)
            formik.setFieldValue('speciality', data.specialty_id)

            const visitType = getListVisitType(data.specialty_id)
            if (visitType) formik.setFieldValue('visit_type', data.visit_type_id)

            formik.setFieldValue('appointment_status', data.appointment_status)
            formik.setFieldValue('duration', data.duration)
            formik.setFieldValue('rendering_provider', data.provider_id)
            formik.setFieldValue('supervising_provider', data.supervising_provider)
            formik.setFieldValue('notes', data.notes)

            formik.setFieldValue('date_of_service', getCustomizedFormattedDate(data.start))
            formik.setFieldValue('checklists', data.checklists ? [...data.checklists] : [])
            setpreChecklist([...data.checklists])
            statusfinder(data.chart_status)
            formik.setFieldValue('checklists_status', data.checklist_status)
        }
    }

    const statusfinder = (data) => {
        if (data) {
            let idex = 0
            const selectedStatus = status.map((item, idx) => {
                if (item.key === data) {
                    idex = idx
                }
            })
            setSelectedStatus(status[idex])
        }
        return false
    }

    const handleAddNew = () => {
        if (newChecklistItem) {
            setpreChecklist([...preChecklist, { description: newChecklistItem, ischecked: false }])
            setChecklist('')
        }
    }


    const getPatientDetails = async (patientid) => {

        if (!id) {
            const resp = await getPatient(patientid)
            const patID = resp.data.patient_status
            formik.setFieldValue('patient_status', patID)
        }
    }

    const handleChangCheked = (itemIndex, rowItem, checked) => {
        const record = [...preChecklist]
        const index = record.findIndex((element, idx) => idx === itemIndex)
        record[index] = {
            ...record[index],
            ischecked: checked,
        }
        setpreChecklist(record)
    }

    const getListSpecialty = async () => {
        try {
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }
    const getListVisitType = async (specialty_id) => {
        try {
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType({ page: 1, limit: 100000, specialty_id })
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        }
    }

    const getAppointmentStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'APPOINTMENT',
                limit: 100000,
            })
            setAppointmentOptions(
                res?.data?.listStatusCode?.data?.map((item) => ({
                    key: item.code,
                    value: item.name,
                })),
            )
        } catch (error) {
            console.log(error)
        }
    }

    async function getProvidersData() {
        try {
            const response = await getProivders({
                page: 1,
                size: 100000,
            })
            if (response) {
                const providerOptionsData = response.data.map((item) => ({
                    key: item.id,
                    value: `${item.first_name} ${item.last_name}`,
                }))
                setProviderOptions(providerOptionsData || [])
            }
        } catch (error) {
            console.log({ error })
        }
    }
    async function selectSpecialtype(value) {
        await getListVisitType(value)
    }

    useEffect(() => {
        getListSpecialty()
        getProvidersData()
        refetchStatus()
        getAppointmentStatus()
    }, [])

    useEffect(() => {
        if (id) getfollowUp(id)
    }, [id])

    useEffect(() => {
        if (isComplete) {
            setSelectedStatus(status[2])
            formik.setFieldValue(
                'chart_status',
                status[2].key
            )
        } else {
            setSelectedStatus(status[0])
        }
    }, [isComplete])

    useEffect(() => {
        if (formik.values.patient_name) {
            getPatientDetails(formik.values.patient_name)
        }

    }, [fetchedAppointments])


    const statusArry = [
        {
            value: 'Confirmed',
            key: 'booked',
        },
        {
            value: 'Completed',
            key: 'fulfilled',
        },
        {
            value: 'No Show',
            key: 'noshow',
        },
        {
            value: 'Rescheduled',
            key: 'waitlist',
        },
        {
            value: 'Cancelled',
            key: 'cancelled',
        },
        {
            value: 'Pending',
            key: 'pending',
        },
    ]

    // useEffect(() => {
    //     getListAppointments(page, formik.values.patient_name)
    // }, [formik.values.patient_name])

    const refetchStatus = async (start = 1) => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                page: 1,
                category: 'PATIENT',
            })
            if (res) {
                const prepareData = res.data?.listStatusCode?.data.map((item, index) => ({
                    key: item.code,
                    value: item.name,
                }))
                setPatientStatusList(prepareData || [])
            }
        } catch (e) {
            notification('error Getting patient status list', 'error')
        }
    }

    const getfollowUp = async (flwupid) => {
        setSubmitting(true)
        if (flwupid) {
            const data = await getScheduleById({
                id: flwupid,
            })
            if (data) {
                followUpPopulation({ ...data.data })

                setFollowUpdata({ ...data.data })
            }
        }
        setSubmitting(false)
    }

    const backPage = () => {
        history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN, {
            from: 'ChatPrep',
        })
    }



    const getListAppointments = async (page = 1, patient_id) => {
        setSubmitting(true)
        try {
            const res = await apiFollowUp.getAappointments({
                _sort: 'date',
                page,
                size: 10,
                patient_id,
                chartStatus: 'NA',
                start: moment.utc().format('YYYY-MM-DDTHH:mm:ss')  ///'2023-12-01T00:00:00'//moment().format('YYYY-MM-DD')                     //'2023-12-01T00:30:00Z'  //YYYY_MM_DD
            })
            if (res.data) {
                const dataAppointment = res.data.data
                const dataUi = dataAppointment?.map((item) => ({
                    id: item.id,
                    patient:
                        (item.participant[0]?.first_name || '') +
                        ' ' +
                        (item.participant[0]?.last_name || ''),
                    status: item.status,
                    patient_phone: item.participant[0]?.phone_number,
                    date: item.start,
                    startTime: item.start,
                    endTime: item.end,
                    specialty: item.specialty?.specialty,
                    visit_type: item.visit_type?.post_code,
                    provider_name:
                        item?.practitioner_first_name + ' ' + item?.practitioner_last_name,
                    chart_status: item?.chart_status,
                    ...item
                }))

                setTotal(res?.data?.totalCount)
                setFetchedAppointments([...fetchedAppointments, ...dataUi])
            }

        } catch (error) {
            console.log(error)
            notification('Something went wrong !', 'error')
        }
        setSubmitting(false)
    }

    const getAppointmentsData = async (idx) => {
        setSubmitting(true)
        const data = fetchedAppointments[idx]
        formik.setFieldValue('speciality', data?.specialty?.id)
        await getListVisitType(data?.specialty?.id)
        if (listVisitType) {
            formik.setFieldValue('visit_type', data?.visit_type?.id)
        }
        formik.setFieldValue('id', data?.id)
        formik.setFieldValue('appointment_status', data.appointment_status)
        formik.setFieldValue('duration', data.duration)
        formik.setFieldValue('rendering_provider', data.provider_id)
        formik.setFieldValue('supervising_provider', data.supervising_provider)
        formik.setFieldValue('date_of_service', getCustomizedFormattedDate(data.start))
        setSelectedAppointment({ ...data })
        setSubmitting(false)
    }


    const statusMapper = (status) => {
        for (let i = 0; i < statusArry.length; i++) {
            if (statusArry[i].key === status) {
                return statusArry[i].value

            }

        }
    }
    const CardElement = ({ item, selected = false, selectedItem = '' }) => {
        return (<Card variant="outlined" className={[classes.customCard, selected ? [classes.slectedCardStyle] : ""]} style={{ opacity: `${(selectedItem && item.id !== selectedItem) ? "0.7" : "1"}` }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}><span class="date" style={{ fontSize: "small" }}> <Typography style={{ color: "#667689" }}>{moment(item.date).format('MM/DD/YYYY')}</Typography></span> <span className={classes.statusContainer} style={{ opacity: `${(selectedItem && item.id !== selectedItem) ? "0.5" : "1"}` }}>{statusMapper(item.status)}</span></Box>

            <Box style={{ marginTop: "10px", fontSize: "medium" }}>
                <Typography variant="subtitle2" display="block" className={[classes.reducedFontSize, (selectedItem && item.id !== selectedItem) ? classes.unselectedBoxtxtcolor : ""]}>
                    {moment(item.startTime).format("HH:mm A")} - {moment(item.endTime).format("HH:mm A")}
                </Typography>

            </Box>
            <Box style={{ marginTop: "10px" }}>
                <Typography variant="body2" display="block" className={(selectedItem && item.id !== selectedItem) ? classes.unselectedBoxtxtcolor : ""} style={{ color: "#303E4E" }}>
                    {item.provider_name}
                </Typography>
            </Box>
        </Card>)
    }


    useEffect(() => {
        if (fetchedAppointments.length - 1 === activeSlide && fetchedAppointments.length !== total) {
            setPage(page + 1)
            getListAppointments(page + 1, formik.values.patient_name)
        }

    }, [activeSlide])
    return (
        // <Box style={{ overflowY: "auto" }}>
        <Grid
            container
            className="chartprep-form"
            style={{
                padding: 24,
                overflowX: 'scroll',
            }}>
            <Grid container>
                <Grid
                    item
                    style={{
                        marginBottom: 16,
                    }}>
                    <span
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                        }}
                        onClick={() => backPage()}>
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />

                        <span className="link-back" color="inherit" style={{ marginTop: '2px' }}>
                            Back to Chart Prep list
                        </span>
                    </span>
                </Grid>

                <Grid container item>
                    <Grid container alignContent="center" className="border-bottom">
                        <h4 className="page-title mb-1 page-RecordCall">
                            {id ? 'Chart Preparation' : 'Chart Preparation'}{' '}
                            <span
                                style={{
                                    marginLeft: 12,
                                    display: 'inline-flex',
                                    alignItems: 'flex-end',
                                }}></span>
                        </h4>
                    </Grid>
                    <Grid>
                        <p className="date-text">
                            Chart Status:{' '}
                            <span
                                className={`${selectedStatus.className}`}
                                onClick={handlePopover}
                                style={{
                                    backgroundColor: `${selectedStatus.color}`,
                                    cursor: formik?.values?.checklists_status
                                        ? 'not-allowed'
                                        : 'pointer',
                                }}>
                                {selectedStatus?.value}
                            </span>
                        </p>
                        <Popover
                            id={IdPopover}
                            open={chkopen}
                            anchorEl={anchorEl}
                            onClose={handleClose2}
                            style={{ top: '38px' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    border: '1px solid #F2F5F4',
                                    borderRadius: '12px',
                                    padding: '10px',
                                    cursor: 'pointer',
                                }}>
                                {status.map((item, i) => {
                                    return (
                                        <>
                                            <span
                                                key={i}
                                                style={{
                                                    padding: '5px',
                                                    color: `${item.color}`,
                                                    backgroundColor: `${item.backgroundColor}`,
                                                    borderRadius: '5px',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    cursor: 'pointer',
                                                }}
                                                className={item.className}>
                                                <span>
                                                    <span
                                                        style={{
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            cursor: 'pointer',
                                                            borderRadius: '15px',
                                                        }}
                                                        className={`${item.className}`}
                                                        onClick={(e) => {
                                                            setSelectedStatus(
                                                                status[
                                                                e.target.getAttribute('index')
                                                                ],
                                                            )
                                                            setAnchorEl(null)

                                                            formik.setFieldValue(
                                                                'chart_status',
                                                                status[
                                                                    e.target.getAttribute('index')
                                                                ]?.key,
                                                            )
                                                        }}
                                                        key={item.key}
                                                        index={i}>
                                                        {item.value}
                                                    </span>
                                                </span>
                                            </span>
                                            <Divider style={{ marginTop: '2px' }} />
                                        </>
                                    )
                                })}
                            </div>
                        </Popover>
                    </Grid>

                    <Grid container item spacing={3} className="modal-spacer mt-3" style={{ paddingRight: "30px" }}>
                        {submitting && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1,
                                    left: 0,
                                }}>
                                <CircularProgress />
                            </Box>
                        )}
                        <Grid item xs={12}>
                            <fieldset className="divider-information" style={{ width: '100%' }}>
                                <legend>
                                    <Typography className={'heading1-title'}>
                                        Patient Information
                                    </Typography>
                                </legend>
                                <Grid container item spacing={2}>
                                    <Grid item xs={4}>
                                        <FormLabel component="p" className={classes.inputFormLabel}>
                                            Patient
                                        </FormLabel>
                                        <SearchPatientInput
                                            className="position-button"
                                            readOnly={id ? true : false}
                                            disabled={id ? true : false}
                                            value={formik.values.patient_name}
                                            name="patient_name"
                                            handleAddRow={async (value) => {
                                                formik.setFieldValue('patient_name', value?.id)
                                                formik.setFieldValue('patient_custom_id', value?.custom_id)
                                                await getPatientDetails(value?.id)
                                                await getListAppointments(page, value?.id)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextBox
                                            label="Patient ID"
                                            name="patient_id"
                                            placeholder="Patient ID"
                                            readOnly
                                            value={formik.values?.patient_custom_id ?? ''}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Select
                                            label="Patient Status"
                                            name="patient_status"
                                            placeholder="Select Patient Status"
                                            readOnly
                                            value={
                                                formik.values?.patient_status
                                                    ? formik.values?.patient_status
                                                    : ''
                                            }
                                            options={patientStatusList}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        {/*------------------------Appointment Details---------------------------------*/}
                        <Grid item xs={12} >
                            <fieldset className="divider-information" style={{ width: '100%' }}>
                                <legend>
                                    <Typography className={'heading1-title'}>
                                        Appointment Details
                                    </Typography>
                                </legend>
                                <Grid container item xs={12} >
                                    <Box style={{ overflowX: "auto", display: "flex", width: "100%" }}>
                                        {/* {fetchedAppointments && fetchedAppointments.map((item, idx) => <Paper elevation={0} className={classes.customPaper} key={idx} onClick={() => getAppointmentsData(idx)}><CardElement key={idx} item={item} /></Paper>)} */}
                                        <ReactSimplyCarousel
                                            containerProps={{
                                                style: {
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                    userSelect: "none",
                                                    backgroundColor: "transparent"
                                                }
                                            }}
                                            activeSlideIndex={activeSlide}
                                            activeSlideProps={{
                                                style: {
                                                    background: "blue"
                                                }
                                            }}
                                            onRequestChange={setActiveSlide}
                                            forwardBtnProps={{
                                                children: <NavigateNextIcon fontSize='small' />,
                                                style: {
                                                    backgroundColor: "white",
                                                    border: "none",
                                                    fontWeight: 800,
                                                    color: "grey",
                                                    cursor: "pointer",
                                                    visibility: `${fetchedAppointments.length > 5 ? "visible" : "hidden"}`,
                                                    pointerEvents: `${(activeSlide === fetchedAppointments.length - 1 && fetchedAppointments.length !== total) ? "none" : ""}`

                                                }
                                            }}
                                            backwardBtnProps={{
                                                children: <NavigateBeforeIcon fontSize='small' />,
                                                style: {
                                                    backgroundColor: "white",
                                                    border: "none",
                                                    fontWeight: 800,
                                                    color: "grey",
                                                    cursor: "pointer",
                                                    visibility: `${fetchedAppointments.length > 5 ? "visible" : "hidden"}`,
                                                    pointerEvents: `${activeSlide === 0 ? "none" : ""}`
                                                }
                                            }}
                                            dotsNav={{
                                                show: false,
                                                activeItemBtnProps: {
                                                    style: {
                                                        height: 16,
                                                        width: 16,
                                                        borderRadius: "50%",
                                                        border: 0,
                                                        background: "black"
                                                    }
                                                }
                                            }}
                                            itemsToShow={5}
                                            speed={400}
                                            easing="linear"
                                            disableSwipeByMouse
                                            infinite={fetchedAppointments.length === total ? false : true}
                                        // infinite={false}
                                        // hideNavIfAllVisible={fetchedAppointments.length > 6 ? true : false}

                                        >
                                            {fetchedAppointments && fetchedAppointments.map((item, idx) => <div className={classes.customPaper} key={idx} onClick={(e) => { setSelectedCard(item.id); getAppointmentsData(idx) }}><CardElement selected={item.id === selectedCard} key={idx} item={item} selectedItem={selectedCard} /></div>)}

                                        </ReactSimplyCarousel>
                                    </Box>
                                </Grid>
                                <Grid container item spacing={2} xs={12} style={{ marginTop: "20px" }}>
                                    <Grid item xs={3}>
                                        <FormLabel component="p" className={classes.inputFormLabel}>
                                            Speciality
                                        </FormLabel>
                                        <SelectEl
                                            name="speciality"
                                            placeholder="Select Speciality Type"
                                            readOnly
                                            value={
                                                formik.values?.speciality
                                                    ? formik.values?.speciality
                                                    : ''
                                            }
                                            options={listSpecialty}
                                            onChange={(e) => {
                                                formik.setFieldValue('speciality', e.target.value)
                                                selectSpecialtype(e.target.value)
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormLabel component="p" className={classes.inputFormLabel}>
                                            Visit Type
                                        </FormLabel>
                                        <SelectEl
                                            name="visit_type"
                                            placeholder="Select Visit Type"
                                            readOnly
                                            value={
                                                formik.values?.visit_type
                                                    ? formik.values?.visit_type
                                                    : ''
                                            }
                                            options={listVisitType}
                                            onChange={(e) => {
                                                formik.setFieldValue('visit_type', e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            readOnly
                                            label="Date of Service"
                                            name="date_of_service"
                                            placeholder="Date of Service"
                                            value={formik.values?.date_of_service}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            readOnly
                                            label="Appointment Duration"
                                            name="appointment_duration"
                                            placeholder="Appointment Duration"
                                            value={formik.values?.duration}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* <TextBox
                                        readOnly
                                        label="Appointment Status"
                                        name="appointment_status"
                                        placeholder="Appointment Duration"
                                        value={formik.values?.appointment_status}

                                    /> */}
                                        <FormLabel component="p" className={classes.inputFormLabel}>
                                            Appointment Status
                                        </FormLabel>
                                        <SelectEl
                                            readOnly
                                            name="appointment_status"
                                            placeholder="Select Appointment Status"
                                            value={
                                                formik?.values?.appointment_status
                                                    ? formik?.values?.appointment_status
                                                    : ''
                                            }
                                            options={appointmentOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormLabel component="p" className={classes.inputFormLabel}>
                                            Rendering Provider
                                        </FormLabel>
                                        <SelectEl
                                            name="rendering_provider"
                                            placeholder="Select Rendering Provider"
                                            readOnly
                                            value={
                                                formik.values?.rendering_provider
                                                    ? formik.values?.rendering_provider
                                                    : ''
                                            }
                                            options={providerOption}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            readOnly
                                            label="Supervising Provider"
                                            name="supervising_provider"
                                            placeholder="N/A"
                                            value={formik.values?.supervising_provider}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid item xs={12}>
                            <fieldset className="divider-information" style={{ width: '100%' }}>
                                <legend>
                                    <Typography className={'heading1-title'}>
                                        Pre-Appointment Checklist
                                    </Typography>
                                </legend>
                                <Grid container flex={2} justifyContent="space-between">
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        spacing={2}
                                        style={{ padding: 10 }}>
                                        <Grid
                                            xs={2}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}>
                                            <span style={{ marginRight: '20px' }}>
                                                <AntSwitch
                                                    onChange={(e) => {
                                                        setIsComplete(e.target.checked)

                                                        checklistactivater(e.target.checked)
                                                        formik.setFieldValue(
                                                            'checklists_status',
                                                            e.target.checked,
                                                        )
                                                    }}
                                                    checked={
                                                        formik?.values?.checklists_status ? true : false
                                                    }
                                                />
                                            </span>
                                            <span>Complete</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Box className={classes['wrap_scroll_list']}>
                                            {preChecklist.map((item, index) => (
                                                <Box
                                                    key={index}
                                                    className={classes['item_ric']}
                                                    style={{
                                                        border: '2px solid #F5F5F5',
                                                        borderRadius: '5px',
                                                        paddingLeft: '3px',
                                                    }}>
                                                    <Stack
                                                        direction={'row'}
                                                        justifyContent={'space-between'}
                                                        alignItems={'center'}>
                                                        <FormControlLabel
                                                            label={`${index + 1}. ${item.description}`}
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={item.ischecked}
                                                                    onChange={(e) =>
                                                                        handleChangCheked(
                                                                            index,
                                                                            item,
                                                                            e.target.checked,
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        {/* {item.ic_delete && ( */}
                                                        <Box
                                                            className="icon"
                                                            onClick={() =>
                                                                handleDeleteChecklist(index, item)
                                                            }>
                                                            {/* <DeleteOutlineIcon
                                                            fontSize="small"
                                                            style={{ cursor: 'pointer' }}
                                                        /> */}
                                                            <TrashIcon
                                                                fontSize="small"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Box>
                                                        {/* )} */}
                                                    </Stack>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: '12px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                <TextBox
                                                    value={newChecklistItem}
                                                    placeholder="Enter New Checklist Item"
                                                    onChange={(value) => setChecklist(value)}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={2}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'end',
                                                }}>
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        cursor: 'pointer',
                                                        whiteSpace: 'nowrap',
                                                        marginTop: '5px',
                                                    }}
                                                    onClick={handleAddNew}>
                                                    <AddCircleOutlineOutlined
                                                        style={{
                                                            color: '#14AE5C',
                                                            fontSize: 18,
                                                        }}
                                                    />
                                                    <Typography
                                                        style={{
                                                            color: '#14AE5C',
                                                            marginLeft: 8,
                                                        }}>
                                                        Add New item
                                                    </Typography>
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid item xs={12}>
                            {/*------------------------Stuff Notes------------------------------------------*/}
                            <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                                <legend>
                                    <Typography className={'heading1-title'}>Staff Notes</Typography>
                                </legend>
                                <Grid item spacing={2} container>
                                    <Grid item xs={12}>
                                        <NoteField
                                            note={formik?.values?.note}
                                            noteView={formik?.values.noteView}
                                            notes={formik.values.notes}
                                            onChange={formik?.setFieldValue}
                                            // onBlur={handleNotesChange}
                                            handleNotesChange={handleNotesChange}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    top={20}
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}
                    className="cancel-button">
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={() => backPage()}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadingButton
                            loading={submitting}
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create}
                            onClick={(e) => {
                                formik.handleSubmit(e)
                            }}>
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid >
        </Grid >
        // </Box>
    )
}

export default CartPrepForm
