import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { useHistory } from 'react-router-dom'
import Routes from 'constants/routes'
import AppointmentBooking from 'containers/AppointmentBooking'
import { useDispatch, useSelector } from 'react-redux'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import { useNotification } from 'shared/elements/Notification'
import { createSchedule } from 'services/Calendar'
import SchedulerMenu from 'containers/SchedulerMenu'
import moment from 'moment'
import { _getClinicalNotesDetail } from 'containers/ClinicalNotes/api/services'
import LoadingPage from 'utilities/loading-page'
import { useTimezone } from 'shared/hooks/useTimezone'
import { changeAppointment } from 'store/actions/schedule'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'
import { SCHEDULE_POPULATE } from 'constants/index'

const useStyles = makeStyles({
    subLabel: {
        fontSize: 12,
        color: '#9CA9B8',
    },
    btn__generate: {
        borderRadius: 6,
        fontWeight: 'normal',
    },
    next_appt_header: {
        marginTop: 12,
    },
})

const mapOptions = (data = []) => {
    return data.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const optionTZ = [
    {
        key: 'US/Eastern',
        value: 'Eastern Time - EST',
    },
    {
        key: 'US/Central',
        value: 'Central Time - CST',
    },
    {
        key: 'US/Mountain',
        value: 'Mountain Time - MST',
    },
    {
        key: 'US/Pacific',
        value: 'Pacific Time - PST',
    },
    {
        key: 'US/Alaska',
        value: 'Alaskan Time - AKST',
    },
    {
        key: 'US/Hawaii',
        value: 'Hawaiian Time - HAST',
    },
]

const PlanNextAppointment = ({
    fieldName,
    master,
    values,
    onChange,
    patientId,
    providerId,
    encounter_id,
    macros,
    initialValue,
    clinicalNoteData,
    appointmentDetails,
    populate,
    nonEditable
}) => {
    const classes = useStyles()
    const history = useHistory()
    const options = mapOptions(master?.planNextAptMasters)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activeTab, setActiveTab] = useState(1)
    const notification = useNotification()
    const [appointmentModal, setAppointmentModal] = useState({ id: null, status: false })
    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [loading, setLoading] = useState(false)
    const [nextAppt, setNextAppt] = useState()

    const timezone = useTimezone()
    const dispatch = useDispatch()

    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    const syncAppointment = useSelector((state) => state.clinicalNotes.syncAptForCN)
    // const { locations, visitTypes, dataLoading } = useAppointmentBookingFormData(providerId)

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            await createSchedule({ ...values, encounter_id })

            setIsSubmitting(false)
            setAppointmentModal({ id: null, status: false })
            notification('An appointment was successfully created!', 'success')
        } catch (error) {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'An appointment was not successfully created!',
                'error',
            )
        }
    }

    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: clinicalNoteData.patient.id,
                        practitioner_id: clinicalNoteData.provider_id,
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }

    const handleCheckChange = (e, option) => {
        const { name, value, checked } = e.target
        const { plan_next_opt } = values
        if (checked) {
            onChange(name, [...plan_next_opt, { id: value, label: option.label }])
        } else {
            onChange(
                name,
                plan_next_opt.filter((uv) => uv.id !== value),
            )
        }
    }

    const getNextAppt = async () => {
        try {
            setLoading(true)
            if (!clinicalNoteData?.id) return
            const {
                data: { next_appt },
            } = await _getClinicalNotesDetail(clinicalNoteData.id)

            if (!next_appt?.id || clinicalNoteData?.appointment_id === next_appt?.id) {
                return onChange(`${fieldName}.next_appt`, null)
            }
            const start = moment(next_appt.start)
            const timezoneOffset = optionTZ.map((option) => ({
                ...option,
                offset: moment.tz(option.key).utcOffset(),
            }))
            const result = {
                id: next_appt.id,
                day: start.format('dddd'),
                date: start.format('MM/DD/YYYY'),
                start_time: start.format('hh:mm A'),
                end_time: moment(next_appt.end).format('hh:mm A'),
                timezone:
                    timezoneOffset.find((timezone) => timezone.offset === moment().utcOffset())
                        ?.value || moment.tz.guess(),
            }

            setNextAppt(result)
            onChange(`${fieldName}.next_appt`, result)
        } catch (error) {
            notification('An error occurred while get the next appointment!', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handlePopulate = () => {
        const { specialty_id, visit_type_id, duration, location_id } = appointmentDetails

        history.push(Routes.SCHEDULE, {
            populate: true,
            from: SCHEDULE_POPULATE.CLINICAL_NOTE,
            value: {
                specialty_id,
                visit_type_id, 
                duration, 
                location_id,
                practitioner_id: clinicalNoteData.provider_id,
                patient_id: clinicalNoteData.patient.id,
            },
        })
    }

    useEffect(() => {
        (async () => {
            setLoading(true)
            if(!syncAppointment && clinicalNoteData?.id){
                await getNextAppt()
            }
        })()
    }, [syncAppointment])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 20,
            }}>
            {loading && <LoadingPage />}
            <Grid container justifyContent="space-between" alignItems="center">
                {nextAppt?.id && (
                    <Grid>
                        <Typography className={classes.next_appt_header}>
                            Next appointment scheduled: {nextAppt.day} {nextAppt.date} at{' '}
                            {nextAppt.start_time} - {nextAppt.end_time} {nextAppt.timezone}
                        </Typography>
                    </Grid>
                )}
                <Grid item>
                    <Grid container direction="column">
                        <Button
                            disabled={nonEditable}
                            style={{ marginTop: 12 }}
                            onClick={populate ? handlePopulate : newAppointmentModalToggle}
                            className={classes.btn__generate}
                            variant="contained">
                            Next Appointment
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 18,
                }}>
                {options.map((option, index) => (
                    <CheckboxOption
                        key={index}
                        name={`${fieldName}.plan_next_opt`}
                        disabled={nonEditable}
                        option={option}
                        onChange={(e) => handleCheckChange(e, option)}
                        checked={
                            values?.plan_next_opt.some((item) => item.id === option.value)
                                ? true
                                : false
                        }
                    />
                ))}
            </Box>
            <NarrativeAndMacro
                hideTextNarrative={false}
                hideGenerateBtn={false}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
            {/* {appointmentModal.status && (
                <AppointmentBooking
                    isFromClinicalNote
                    open={appointmentModal.status}
                    appointmentModal={appointmentModal}
                    loading={isSubmitting}
                    dataLoading={dataLoading}
                    onModalClose={() =>
                        setAppointmentModal({
                            ...appointmentModal,
                            id: null,
                            status: false,
                        })
                    }
                    onSubmit={handleSubmit}
                    locationOptionsData={locations.map((item) => ({
                        key: item.id,
                        value: item.name,
                    }))}
                    visitTypeOptionsData={visitTypes.map((item) => ({
                        key: item.id,
                        value: item.post_code,
                        duration: item.duration,
                    }))}
                    statusOptionsData={statusOptions.map((item) => ({
                        id: item.id,
                        key: item.code,
                        value: item.name,
                    }))}
                />
            )} */}
            {newAppointmentModal.status && (
                <Dialog
                    open={newAppointmentModal.status}
                    sx={{
                        '& .MuiDialog-paperFullWidth': {
                            maxWidth: '1200px',
                            height: '100vh',
                            overflow: 'hidden',
                        },
                    }}
                    fullWidth={true}
                    onClose={newAppointmentModalToggle}
                    className={classes.root}>
                    <SchedulerMenu
                        isPopupType
                        tab={activeTab}
                        onChangeTab={(tab) => setActiveTab(tab)}
                        isFromClinicalNote
                        open={newAppointmentModal.status}
                        appointmentModal={newAppointmentModal}
                        loading={isSubmitting}
                        onModalClose={newAppointmentModalToggle}
                        setIsLoading={setIsSubmitting}
                        onSubmit={handleSubmit}
                        // locationOptionsData={locations.map((item) => ({
                        //     key: item.id,
                        //     value: item.name,
                        // }))}
                        // visitTypeOptionsData={visitTypes.map((item) => ({
                        //     key: item.id,
                        //     value: item.post_code,
                        //     duration: item.duration,
                        // }))}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                        onOk={getNextAppt}
                    />
                </Dialog>
            )}
        </Box>
    )
}

export default PlanNextAppointment
