import { makeStyles, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { PropsWithChildren } from 'react'

interface ErrorDialogProps {
    dismissError: Function
    open: Boolean
    error: string[]
}

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        fontSize: '15px !important',
        borderRadius: '25px !important',
        marginTop: 68,
        width: 300,
    },
    root: {
        '& .MuiDialog-paper': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 700,
            maxWidth: 700,
            height: 290,
            padding: '24px 48px',
            boxSizing: 'border-box',
            borderRadius: 10,
        },
    },
    header: {
        color: '#303E4E',
        textAlign: 'center',
        lineHeight: 'normal',
        fontSize: 22,
        fontWeight: 500,
    },
}))

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
    const classes = useStyles()
    return (
        <Dialog open={open} onClose={() => dismissError()} fullWidth className={classes.root}>
            <Typography className={classes.header}>
                {error.join(', ')} already exists on the care team!
            </Typography>

            <DialogActions>
                <Button
                    className={classes.button_cancel}
                    onClick={() => dismissError()}
                    color="primary"
                    autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorDialog
