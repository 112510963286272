import {
    getService,
    postService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'
import { getLoggedInUserRole } from '../../utilities'

import { UserRoles } from '../../constants/user-roles'

export const getTM2UAdminProfileById = (userId) => {
    // const userRole = getLoggedInUserRole();
    // let userType = '';
    // switch (userRole) {
    //     case UserRoles.TM2U_ADMIN:
    //         userType = 'admin';
    //         break;
    //     case UserRoles.TM2U_SUPER_ADMIN:
    //         userType = 'super-admin';
    //         break;
    // }
    return getService(
        `${config.dev.baseURLV2}/account/me`,
    ).then((response) => {
        // response.data = {
        //     ...response.data,
        //     name: `${response.data.fistName} ${response.data.lastName}`,
        //     photo: 'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/239/e5c6da23-b93f-4e44-b320-53f394292a28_ammarManju.jpeg',
        //     user: {
        //         mob: response.data.phone_number,
        //         email: response.data.email,
        //         id: response.data.sub,
        //     },
        //     id: response.data.sub,
        // }
        return response
    })
}

// export const editTm2uAdminProfile = (payload) => {

//   return postService(`${config.dev.baseURL}/admin/staff/admin-edit`, payload);
// }

export const editTm2uAdminProfile = (payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        default: break
    }
    return postService(
        `${userType}/staff/admin-edit`,
        payload,
    )
}
