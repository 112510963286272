import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { LightTooltip } from 'shared/elements/Tooltip/LightTooltip'

import { CPTCode } from './interfaces'

interface Props {
    cptArr: CPTCode[]
}

export const RenderCptCodeCell = ({ cptArr }: Props) => {
    const Title = () => (
        <List
            sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
            }}>
            {cptArr.map(({ code, description }, i) => (
                <ListItem key={i}>
                    <ListItemText
                        primary={`${code} - ${description}`}
                    />
                </ListItem>
            ))}
        </List>
    )

    const cptCodes = cptArr.map((cpt) => cpt.code).join(',')

    return (
        <LightTooltip title={<Title />}>
            <Typography>{cptCodes}</Typography>
        </LightTooltip>
    )
}
