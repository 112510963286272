import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import 'moment-timezone'

export const useTimezone = () => {
    const locationTimezone = useSelector((state) => state.timezones.tz.key)
    const valueTimezone = useSelector((state) => state.timezones.tz.value)
    const labelTimezone = useSelector((state) => state.timezones.tz.label)
    useMemo(() => {
        moment.tz.setDefault(locationTimezone)
    }, [locationTimezone])

    // useEffect(() => {
    //     return () => {
    //         moment.tz.setDefault() // reset to browser TZ on unmount
    //     }
    // }, [])

    return { locationTimezone, valueTimezone, labelTimezone }
}
