import React, { useEffect, useState, useRef } from 'react'
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core'
import { debounce } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'
import { Button as ButtonCustom } from 'components/custom'
import { connect, useSelector } from 'react-redux'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'

import SearchIcon from 'assests/icons/search_icon.svg'
import {
    getAccountList,
    getDepartmentList,
    removeAccount,
    resetPasswordAccount,
    updateStatusAccount,
} from 'services/Calendar/index'

import { SelectEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { DataGrid } from 'shared/elements/DataGrid'

import AlertConfirmDialog from 'components/shared/Elements/AlertConfirmDialog'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'
import AlertErrorDialog from 'components/shared/Elements/AlertErrorDialog'
import Routes from 'constants/routes'
import { useDebounce } from 'shared/hooks'

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_search: {
        background: '#5571C6',
        '&:hover': {
            background: '#5571C6',
        },
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    form_list_container: {
        marginTop: 16,
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
})

const renderEditAction = (params, actions, permissions, notification) => {
    const userMenus = []
    if (permissions.find((permission) => permission === 'modify_user_security_accounts')) {
        userMenus.push({
            name: 'Edit',
            action: 'onEditClick',
        })
    }
    if (permissions.find((permission) => permission === 'deactivate_user_security_accounts')) {
        userMenus.push({
            name: 'Disable',
            action: 'onDisableClick',
        })
        userMenus.push({
            name: 'Enable',
            action: 'onEnableClick',
        })
    }
    if (permissions.find((permission) => permission === 'reset_password_user_security_accounts')) {
        userMenus.push({
            name: 'Reset Password',
            action: 'onResetPasswordClick',
        })
    }
    if (permissions.find((permission) => permission === 'delete_user_security_accounts')) {
        userMenus.push({
            name: 'Delete',
            action: 'onDeleteClick',
        })
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const { refetchAccount, handleEditClick } = actions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const [loading, setLoading] = useState(false)
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

    const [alertConfirmOpen, setAlertConfirmOpen] = useState({
        status: false,
        type: '',
        name: '',
    })

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onEditClick: () => {
            setAnchorEl(null)
            handleEditClick(params)
        },
        onDeleteClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertDeleteOpen(true)
            }
        },
        onDisableClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertConfirmOpen({
                    status: true,
                    type: 'disable',
                })
            }
        },
        onEnableClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertConfirmOpen({
                    status: true,
                    type: 'enable',
                })
            }
        },
        onResetPasswordClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertConfirmOpen({
                    status: true,
                    type: 'reset_password',
                    name: 'reset password',
                })
            }
        },
    }

    const handleDeleteAccount = () => {
        setLoading(true)
        removeAccount(params.id)
            .then(() => {
                setLoading(false)
                refetchAccount()
                handleClose()
                setAlertDeleteOpen(false)
                notification("User's account deleted successfully!", 'success')
            })
            .catch((e) => {
                setLoading(false)
                setAlertDeleteOpen(false)
                notification('Cannot delete this account. Please try again!', 'error')
            })
    }

    const handleDisableOrEnableAccount = () => {
        setLoading(true)
        updateStatusAccount({
            id: params.id,
            payload: {
                active: alertConfirmOpen?.type === 'enable',
            },
        })
            .then(() => {
                setLoading(false)
                refetchAccount()
                handleClose()
                setAlertConfirmOpen({
                    status: false,
                    type: '',
                })
            })
            .catch((e) => {
                setLoading(false)
                setAlertConfirmOpen({
                    status: false,
                    type: '',
                })
            })
    }

    const handleResetPassword = async () => {
        try {
            setLoading(true)
            await resetPasswordAccount(params.id)
            notification("User's account reset password successfully!", 'success')
        } catch (error) {
            notification('Cannot reset password this account. Please try again!', 'error')
        } finally {
            setLoading(false)
            setAlertConfirmOpen({
                status: false,
                type: '',
                name: '',
            })
        }
    }

    return (
        <Grid item>
            <IconButton
                size="small"
                onClick={handleClick}
                style={{
                    background: '#FFFFFF',
                    border: '1px solid #E9ECF1',
                    borderRadius: '20px',
                }}>
                <MoreHorizIcon
                    style={{
                        color: '#3E465B',
                        fontSize: 18,
                    }}
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 142,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        key={index}
                        onClick={handleFunc[menu.action]}
                        style={{
                            color: menu.name === 'Delete' ? '#E81212' : '#667689',
                        }}>
                        {menu.name}
                    </MenuItem>
                ))}
            </Menu>
            <AlertDeleteDialog
                loading={loading}
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleDeleteAccount()
                }}
            />

            <AlertConfirmDialog
                loading={loading}
                open={alertConfirmOpen?.status}
                type={alertConfirmOpen?.type}
                handleClose={() => {
                    setAlertConfirmOpen({
                        status: false,
                        type: '',
                    })
                }}
                handleDelete={
                    alertConfirmOpen?.type === 'reset_password'
                        ? handleResetPassword
                        : handleDisableOrEnableAccount
                }
                customType={alertConfirmOpen?.type === 'reset_password'}
                name={alertConfirmOpen?.name}
            />
        </Grid>
    )
}

const renderActions = (params, actions, permissions, notification) => {
    return (
        <Grid container alignItems="center" direction="column" spacing={2}>
            {renderEditAction(params, actions, permissions, notification)}
        </Grid>
    )
}

export const dateTimeOption = [
    { key: 'day', value: 'Day' },
    { key: 'week', value: 'Week' },
    { key: 'month', value: 'Month' },
]

const UserSecuritySettings = () => {
    const classes = useStyles()

    const notification = useNotification()
    const history = useHistory()

    // const [page, setPage] = useState(1)
    const [departments, setDepartments] = useState([])

    const [sortModel, setSortModel] = useState([])
    const [filterData, setFilterData] = useState({
        role: '',
        location_id: '',
    })

    // const securityRoles = useSelector(
    //     (state) => state.permission.roles,
    // ).map((item) => {
    //     return {
    //         key: item.code,
    //         value: item.name,
    //     }
    // })

    // const fetchDepartments = async () => {
    //     // setLoading(true)
    //     try {
    //         const res = await getDepartmentList({
    //             limit: 100000,
    //         })
    //         // setLoading(false)
    //         setDepartments(res.data?.data || [])
    //     } catch (e) {
    //         // setLoading(false)
    //     }
    // }

    // useEffect(() => {
    //     fetchDepartments()
    // }, [])

    // const locations = useSelector(
    //     (state) => state.metaData.locations,
    // ).map((item) => {
    //     return {
    //         key: item.id,
    //         value: item.name,
    //     }
    // })
    const [isLoading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [alertErrorOpen, setAlertErrorOpen] = useState('')
    const [patientList, setPatientList] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const debouncedSearchText = useDebounce(textSearch, 500)

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const onPageChange = (value) => {
        setPage(value)
    }

    const permissions = useSelector((state) => state?.permission?.user_security_accounts)

    function onChangeFilter(e) {
        const { name, value } = e.target
        setFilterData({ ...filterData, [name]: value })
    }

    const columns = [
        {
            headerName: '',
            headerClassName: 'super-app-theme--header',
            field: '',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                return (
                    <div
                        style={{
                            width: 35,
                            height: 35,
                            background: 'gray',
                            borderRadius: 80,
                        }}>
                        {value}
                    </div>
                )
            },
        },
        {
            headerName: 'Full Name',
            headerClassName: 'super-app-theme--header',
            field: 'first_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return `${value} ${row.last_name}`
            },
        },
        {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            flex: 1.75,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value
            },
        },
        {
            headerName: 'Mobile Number',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return (
                    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '-'
                )
            },
        },
        {
            headerName: 'Security Role',
            headerClassName: 'super-app-theme--header',
            field: 'role_name',
            // width: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Department',
            headerClassName: 'super-app-theme--header',
            field: 'department_name',
            // width: 100,
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_user_security_accounts') &&
                !permissions.includes('deactivate_user_security_accounts') &&
                !permissions.includes('reset_password_user_security_accounts') &&
                !permissions.includes('delete_user_security_accounts'),
            renderCell: (params) => {
                return renderActions(
                    params,
                    {
                        handleEditClick,
                        refetchAccount,
                    },
                    permissions,
                    notification,
                )
            },
        },
    ]

    const handleEditClick = (params) =>
        history.push(Routes.SETTINGS.USER_SECURITY_EDIT.replace(':id', params.row?.id))

    const handleClearFilter = () => {
        refetchAccount()
        setFilterData({
            role: '',
            location_id: '',
        })
        setTextSearch('')
    }

    const refetchAccount = async (start = page, sort = sortModel, name = textSearch) => {
        setLoading(true)
        try {
            const res = await getAccountList(
                sort[0]?.sort
                    ? {
                          page: start,
                          size: limit,
                          name: name.trim(),
                          [sort[0].sort]: sort[0].field,
                      }
                    : {
                          page: start,
                          size: limit,
                          name: name.trim(),
                      },
            )
            setLoading(false)
            setPatientList(res.data?.result || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        refetchAccount()
    }, [page, sortModel])

    useEffect(() => {
        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        refetchAccount(1)
    }, [debouncedSearchText])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>User Accounts</Typography>

                    <Grid item>
                        <Grid container>
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                style={{ flex: 1 }}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        gap: 8,
                                        marginRight: 16,
                                        flex: 1,
                                    }}>
                                    <Grid
                                        item
                                        style={{
                                            maxWidth: 280,
                                            // flex: 1,
                                        }}>
                                        <TextField
                                            className={clsx({
                                                [classes.search_input]: true,
                                                // [classes.show_search]: !openSearch,
                                            })}
                                            placeholder="Search..."
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            value={textSearch}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton size="small">
                                                            <img src={SearchIcon} alt=""></img>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {textSearch && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setTextSearch('')}>
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                    root: classes.search_input_root,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        style={{
                                            flex: 1,
                                        }}>
                                        <SelectEl
                                            name="role"
                                            value={
                                                filterData.role
                                            }
                                            options={
                                                securityRoles
                                            }
                                            onChange={
                                                onChangeFilter
                                            }
                                            style={{
                                                height: 32,
                                                minHeight: 32,
                                            }}
                                            renderValue={(
                                                sel,
                                            ) => {
                                                return typeof sel ===
                                                    'string' &&
                                                    !sel ? (
                                                    <Typography
                                                        className={
                                                            classes.colorContent
                                                        }>
                                                        Security
                                                        Role
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        className={clsx(
                                                            classes.ellipsis__text,
                                                            classes.colorContent,
                                                        )}>
                                                        {
                                                            securityRoles.find(
                                                                (
                                                                    item,
                                                                ) =>
                                                                    item.key ===
                                                                    sel,
                                                            )
                                                                ?.value
                                                        }
                                                    </Typography>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            flex: 1,
                                        }}>
                                        <SelectEl
                                            name="location_id"
                                            value={
                                                filterData.location_id
                                            }
                                            options={
                                                locations
                                            }
                                            onChange={
                                                onChangeFilter
                                            }
                                            style={{
                                                height: 32,
                                                minHeight: 32,
                                                maxWidth: 160,
                                            }}
                                            renderValue={(
                                                sel,
                                            ) => {
                                                return typeof sel ===
                                                    'string' &&
                                                    !sel ? (
                                                    <Typography
                                                        className={
                                                            classes.colorContent
                                                        }>
                                                        Department
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        className={clsx(
                                                            classes.ellipsis__text,
                                                            classes.colorContent,
                                                        )}>
                                                        {
                                                            locations.find(
                                                                (
                                                                    item,
                                                                ) =>
                                                                    item.key ===
                                                                    sel,
                                                            )
                                                                ?.value
                                                        }
                                                    </Typography>
                                                )
                                            }}
                                        />
                                    </Grid> */}

                                    {/* {(textSearch ||
                                        filterData.role ||
                                        filterData.location_id) && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={clsx(
                                                    classes.btn_add,
                                                    classes.btn_search,
                                                )}
                                                onClick={
                                                    handleClearFilter
                                                }
                                                fullWidth>
                                                Clear
                                            </Button>
                                        </Grid>
                                    )} */}
                                </Grid>
                            </Grid>
                            <RBACProvider permissions={permissions}>
                                <RBACWrapper
                                    requiredPermissions={['create_user_security_accounts']}>
                                    <Grid item>
                                        <ButtonCustom
                                            tooltip="Add New Account"
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                history.push(Routes.SETTINGS.USER_SECURITY_NEW)
                                            }
                                            fullWidth>
                                            <AddIcon
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </ButtonCustom>
                                    </Grid>
                                </RBACWrapper>
                            </RBACProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                            backgroundColor: 'unset !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        loading={isLoading}
                        totalRecords={total}
                        page={page}
                    />
                </Box>
            </Grid>

            {Boolean(alertErrorOpen) && (
                <AlertErrorDialog
                    message={alertErrorOpen}
                    open={Boolean(alertErrorOpen)}
                    handleClose={() => {
                        setAlertErrorOpen(false)
                    }}
                />
            )}
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(UserSecuritySettings)
