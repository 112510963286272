import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { DataGrid, GridColumns, GridSortModel } from '@mui/x-data-grid'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'

import { Button as ButtonCustom } from 'components/custom'
import { connect, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import clsx from 'clsx'

import { NoRowsOverlayEl, SelectEl } from 'shared/elements'
import {
    createDevice,
    createRoom,
    deleteDevice,
    getDeviceList,
    getRoomList,
    removeRoom,
    updateDevice,
    updateRoom,
} from 'services/Calendar'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'

import { CreateResourceDialog } from './CreateResourceDialog'
import { Pagination } from 'shared/elements/Pagination'
import { useNotification } from 'shared/elements/Notification'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { ApiError, PostData, Resource } from './interface'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_search: {
        background: '#5571C6',
        '&:hover': {
            background: '#5571C6',
        },
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    text_clinical_note: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    text_show_more: {
        color: 'rgba(0, 118, 252, 1)',
        marginLeft: 4,
        cursor: 'pointer',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const resourceTypeOptions = [
    { key: 1, value: 'Room' },
    { key: 2, value: 'Equipment' },
]

const ResourceSettings = () => {
    const classes = useStyles()
    const notification = useNotification()

    // const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [deviceModal, setdeviceModal] = useState<{
        status: boolean
        itemId?: string
    }>({
        status: false,
        itemId: '',
    })
    const [bundleId, setBundleId] = useState('')
    const [filterValue, setFilterValue] = useState(1)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [isLoading, setLoading] = useState(false)
    const [resourceList, setResourceList] = useState<Resource[]>([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const onPageChange = (value: number) => {
        setPage(value)
    }

    const deviceModalToggle = (itemId?: string) => {
        setdeviceModal({
            status: !deviceModal.status,
            itemId,
        })
    }

    const permissions = useSelector((state) => state?.permission?.resource_management)

    const columns: GridColumns<Resource> = [
        {
            headerName: 'Type',
            headerClassName: 'super-app-theme--header',
            field: 'type1',
            flex: 1,
            sortable: false,
            renderCell: () => {
                return filterValue === 1 ? 'Room' : 'Equipment'
            },
        },
        {
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1.75,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.3,
            sortable: false,
            hide:
                !permissions.includes('delete_resource') &&
                !permissions.includes('modify_resource'),
            renderCell: (params) => {
                const menu = [] as any
                if (permissions.includes('modify_resource')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => deviceModalToggle(params.id.toString()),
                    })
                }
                if (permissions.includes('delete_resource')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => deleteDeviceOrRoom(params.id.toString()),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage={`Are you sure you want to delete this ${
                            filterValue === 2 ? 'Device' : 'Room'
                        }?`}
                    />
                )
            },
        },
    ]

    const deleteDeviceOrRoom = (id: string) => {
        let promise, successMsg: string, errorMsg: string

        if (filterValue === 2) {
            // Delete Device
            promise = deleteDevice
            successMsg = 'A Device was successfully deleted!'
            errorMsg = 'A Device was not successfully deleted!'
        } else {
            promise = removeRoom
            successMsg = 'A Room was successfully deleted!'
            errorMsg = 'A Room was not successfully deleted!'
        }

        return promise(id)
            .then(() => {
                getListDevicesOrRooms()

                notification(successMsg, 'success')
            })
            .catch((error: ApiError) => {
                setLoading(false)

                notification(error?.data?.message || errorMsg, 'error')
            })
    }

    const handleSubmit = async (payload: any) => {
        if (deviceModal?.itemId) {
            if (payload.resourceType === 2) {
                setIsSubmitting(true)
                updateDevice({
                    id: deviceModal?.itemId,
                    payload,
                })
                    .then(() => {
                        deviceModalToggle()
                        notification('A Equipment was successfully updated!', 'success')
                        getListDevicesOrRooms()
                    })
                    .catch((error: ApiError) => {
                        notification(
                            error?.data?.message || 'A Equipment was not successfully updated!',
                            'error',
                        )
                    })
                    .finally(() => {
                        setIsSubmitting(false)
                    })
            } else {
                setIsSubmitting(true)
                updateRoom({
                    id: deviceModal?.itemId,
                    payload: {
                        parent_id: payload.location_id,
                        name: payload.name,
                    },
                })
                    .then(() => {
                        deviceModalToggle()
                        notification('A Room was successfully updated!', 'success')
                        getListDevicesOrRooms()
                    })
                    .catch((error: ApiError) => {
                        notification(
                            error?.data?.message || 'A Room was not successfully updated!',
                            'error',
                        )
                    })
                    .finally(() => {
                        setIsSubmitting(false)
                    })
            }
        } else {
            // device
            if (payload.resourceType === 2) {
                setIsSubmitting(true)
                createDevice(payload)
                    .then(() => {
                        deviceModalToggle()
                        notification('A Equipment was successfully added!', 'success')
                        getListDevicesOrRooms()
                    })
                    .catch((error: ApiError) => {
                        notification(
                            error?.data?.message || 'A Equipment was not successfully added!',
                            'error',
                        )
                    })
                    .finally(() => {
                        setIsSubmitting(false)
                    })
            } else {
                setIsSubmitting(true)
                createRoom({
                    parent_id: payload.location_id,
                    name: payload.name,
                })
                    .then(() => {
                        deviceModalToggle()
                        notification('A Room was successfully added!', 'success')
                        getListDevicesOrRooms()
                    })
                    .catch((error: ApiError) => {
                        notification(
                            error?.data?.message || 'A Room was not successfully added!',
                            'error',
                        )
                    })
                    .finally(() => {
                        setIsSubmitting(false)
                    })
            }
        }
    }

    const getListDevicesOrRooms = async (start = page) => {
        let promise

        if (filterValue === 2) {
            // Filter out Devices
            promise = getDeviceList
        } else {
            // Filter out Rooms
            promise = getRoomList
        }

        setLoading(true)
        try {
            const res = await promise(
                sortModel[0]?.sort
                    ? {
                          page: start,
                          size: limit,
                          [sortModel[0].sort]: sortModel[0].field,
                          bundle_id: bundleId,
                      }
                    : { page: start, size: limit, bundle_id: bundleId },
            )

            setLoading(false)

            if (res?.data?.bundle_id) setBundleId(res?.data?.bundle_id)
            setResourceList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (ex) {
            setLoading(false)

            notification((ex as ApiError).data?.message || 'Cannot get list Resources', 'error')
        }
    }

    useEffect(() => {
        getListDevicesOrRooms()
    }, [page, sortModel])

    useEffect(() => {
        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        getListDevicesOrRooms(1)
    }, [filterValue])

    const onChangeFilter = async (e) => {
        setResourceList([])
        setFilterValue(e.target.value)
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Resource management</Typography>

                    <Grid item>
                        <Grid container>
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                style={{ flex: 1 }}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    justifyContent="flex-end"
                                    style={{
                                        gap: 8,
                                        flex: 1,
                                    }}>
                                    <Grid
                                        item
                                        style={{
                                            width: 120,
                                            flex: 1,
                                        }}>
                                        <SelectEl
                                            name="status"
                                            // value={props.status}
                                            options={resourceTypeOptions}
                                            value={filterValue}
                                            onChange={onChangeFilter}
                                            style={{
                                                height: 32,
                                                minHeight: 32,
                                            }}
                                            renderValue={(sel) => {
                                                return typeof sel === 'string' && !sel ? (
                                                    <Typography>Resource Type</Typography>
                                                ) : (
                                                    <Typography>
                                                        {
                                                            resourceTypeOptions.find(
                                                                (item) => item.key === sel,
                                                            )?.value
                                                        }
                                                    </Typography>
                                                )
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={clsx(classes.btn_add, classes.btn_search)}
                                            onClick={getListDevicesOrRooms}
                                            fullWidth>
                                            Search
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <RBACProvider permissions={permissions}>
                                <RBACWrapper requiredPermissions={['create_resource']}>
                                    <Grid item>
                                        <ButtonCustom
                                            tooltip="Add New Resource"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => deviceModalToggle()}
                                            fullWidth>
                                            <AddIcon
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </ButtonCustom>
                                    </Grid>
                                </RBACWrapper>
                            </RBACProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={resourceList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={onPageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {deviceModal.status && (
                <CreateResourceDialog
                    resourceType={filterValue}
                    open={deviceModal.status}
                    itemId={deviceModal.itemId}
                    loading={isSubmitting}
                    onModalClose={deviceModalToggle}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}
const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(ResourceSettings)
