
import {
    deleteService,
    getService,
    postService,
    putService
} from 'utilities/request/restClient'


export const apiVitalSigns = {
    create(params) {
        return postService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/vital-signs`,
            params,
        )
    },
    getAll(payload) {
        const queryString = new URLSearchParams({
            ...payload,
        }).toString()
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/vital-signs?${queryString}`,
        )
    },
    getId(socialHistoryId) {
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/vital-signs/${socialHistoryId}`,
        )
    },
    update(socialHistoryId: string | number, payload) {
        return putService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/vital-signs/${socialHistoryId}`,
            payload,
        )
    },
    delete(socialHistoryId: string | number) {
        return deleteService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/vital-signs/${socialHistoryId}`
        )
    },
}