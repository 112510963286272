import { createTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        sidebarWidth: number
        sidebarMobileHeight: number
        brand: string
        footerHeight: number
        mobileTopBarHeight: number
        mobileFooterHeight: number
        sidebarMobilePadding: number
        participantBorderWidth: number
        chatWindowWidth: number
    }

    // allow configuration using `createTheme`
    interface ThemeOptions {
        sidebarWidth?: number
        sidebarMobileHeight?: number
        brand: string
        footerHeight: number
        mobileTopBarHeight: number
        mobileFooterHeight: number
        sidebarMobilePadding: number
        participantBorderWidth: number
        chatWindowWidth?: number
    }
}

const defaultTheme = createTheme()

export default createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                'html, body, #root': {
                    height: '100%',
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: '4px',
                textTransform: 'none',
                color: 'rgb(40, 42, 43)',
                fontSize: '0.9rem',
                transition: defaultTheme.transitions.create(
                    [
                        'background-color',
                        'box-shadow',
                        'border',
                        'color',
                    ],
                    {
                        duration:
                            defaultTheme.transitions
                                .duration.short,
                    },
                ),
            },
            text: {
                padding: '6px 14px',
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none',
                },
            },
            outlinedPrimary: {
                border: '2px solid #027AC5',
                '&:hover': {
                    border: '2px solid rgb(1, 85, 137)',
                },
            },
            startIcon: {
                marginRight: '6px',
            },
        },
        MuiTypography: {
            body1: {
                color: '#667689',
                fontSize: '14px',
                lineHeight: '17px',
                // font-size will break in table
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '0.9rem',
                borderRadius: '6px',
            },
        },
        MuiSelect: {
            root: {
                padding: '0.85em',
            },
        },
        MuiDialogActions: {
            root: {
                padding: '16px',
            },
        },
        MuiTextField: {
            root: {
                color: 'rgb(40, 42, 43)',
            },
        },
        MuiInputLabel: {
            root: {
                color: 'rgb(40, 42, 43)',
                fontSize: '1.1rem',
                marginBottom: '0.2em',
                fontWeight: 500,
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: '6px',
            },
            notchedOutline: {
                borderColor: '889db6',
            },
        },
    },
    typography: {
        fontFamily: 'Rubik',
    },
    palette: {
        primary: {
            main: '#5571c6',
        },
    },
    brand: '#E22525',
    footerHeight: 72,
    mobileFooterHeight: 56,
    sidebarWidth: 455,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
    mobileTopBarHeight: 52,
    chatWindowWidth: 420,
})
