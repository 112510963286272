import moment from 'moment'

import {
  APPOINTMENT_STATUSES,
  CalendarAppointment,
  SerializedAppointment,
  ResourceMap,
  LOCAL_STORAGE_KEYS,
} from './types'
import LocalStorageAdapter from '../../adapters/LocalStorageAdapter'

const draftAppointmentsStore = new LocalStorageAdapter<CalendarAppointment[]>(LOCAL_STORAGE_KEYS.draftAppointments)
const updatingAppointmentStore = new LocalStorageAdapter<string>(LOCAL_STORAGE_KEYS.updatingAppointmentId)

export function getDraftAppointments(): CalendarAppointment[] {
  return draftAppointmentsStore.getItem() || []
}

export function clearDraftAppointments() {
  draftAppointmentsStore.clear()
}

export function replaceAllDraftAppointments(draftAppointments: CalendarAppointment[]): void {
  draftAppointmentsStore.setItem(draftAppointments)
}

export function addDraftAppointment(payload: CalendarAppointment): void {
  const draftAppointments = getDraftAppointments()

  draftAppointmentsStore.setItem([...draftAppointments, payload])
}

export function checkIfAppointmentsSame(currentEvent: CalendarAppointment, newEvent: CalendarAppointment): boolean {
  const areSame =
    moment(currentEvent.end).isSame(new Date(newEvent.end)) &&
    moment(currentEvent.start).isSame(new Date(newEvent.start)) &&
    currentEvent.patient_id === newEvent.patient_id

  return areSame
}

export function formatAppointmentItem(item: CalendarAppointment): SerializedAppointment {
  return {
    ...item,
    resourceId: item.practitioner_id,
    start: new Date(item.start),
    end: new Date(item.end),
  }
}

export function serializeAppointments(events: CalendarAppointment[] = []): SerializedAppointment[] {
  return events.map(formatAppointmentItem)
}

export function getUpdatingAppointmentId(): string {
  return updatingAppointmentStore.getItem() || ''
}

export function filterByStatus(
  data: SerializedAppointment[],
  statusToExclude: APPOINTMENT_STATUSES,
): SerializedAppointment[] {
  return data?.filter((item) => item.status !== statusToExclude)
}

export function filterDraftAppointments(
  draftAppointments: CalendarAppointment[] = [],
  stateEvents: CalendarAppointment[],
): CalendarAppointment[] {
  return draftAppointments.filter((draftAppointment) => {
    return !checkDraftAppointmentExistsInList(stateEvents, draftAppointment)
  })
}

export function excludeDraftAppointments(
  appointments: CalendarAppointment[] = [],
  draftAppointments: CalendarAppointment[] = [],
): CalendarAppointment[] {
  return appointments.filter((appointment) => {
    return !hasAppointmentById(draftAppointments, appointment)
  })
}

export function hasAppointmentById(
  comparisonList: CalendarAppointment[] = [],
  appointment: CalendarAppointment,
): boolean {
  return comparisonList.some((item) => item?.id === appointment.id)
}

export function checkDraftAppointmentExistsInList(
  appointmentsList: CalendarAppointment[] = [],
  appointment: CalendarAppointment,
): boolean {
  return appointmentsList.some((item) =>
    appointment?.id ? item.id === appointment.id : checkIfAppointmentsSame(item, appointment),
  )
}

export function checkMatchingAppointments(
  currentAppointment: CalendarAppointment,
  appointmentToExclude: CalendarAppointment,
): boolean {
  return currentAppointment.id
    ? currentAppointment.id !== appointmentToExclude.id
    : !checkIfAppointmentsSame(currentAppointment, appointmentToExclude)
}

export function mergeDraftAppointmentsWithState(
  stateEvents: CalendarAppointment[] = [],
  draftAppointments: CalendarAppointment[] = [],
) {
  const filteredAppointments = excludeDraftAppointments(stateEvents, draftAppointments)
  const filteredDraftAppointments = filterDraftAppointments(draftAppointments, filteredAppointments)

  replaceAllDraftAppointments(filteredDraftAppointments)

  return [...filteredAppointments, ...filteredDraftAppointments]
}

export function excludeMatchingAppointments(
  draftAppointments: CalendarAppointment[] = [],
  draftAppointmentToExclude: CalendarAppointment,
): CalendarAppointment[] {
  return draftAppointments?.filter((item) => checkMatchingAppointments(item, draftAppointmentToExclude))
}

export function deleteDraftAppointment(draftAppointmentToExclude: CalendarAppointment): void {
  const draftAppointments = getDraftAppointments()
  const draftAppointmentsWithoutExclude = excludeMatchingAppointments(draftAppointments, draftAppointmentToExclude)

  replaceAllDraftAppointments(draftAppointmentsWithoutExclude)
}

export function filterEventsByResources(
  events: CalendarAppointment[] = [],
  resources: ResourceMap[] = [],
): CalendarAppointment[] {
  const mapArrayIds = resources.map((item) => item.resourceId)

  return events.filter((item) => mapArrayIds.includes(item.practitioner_id))
}

export function checkAppointmentIsDraft(id: string): boolean {
  const updatingAppointmentId = getUpdatingAppointmentId()
  const draftAppointment = getDraftAppointments().some((item) => item.id === id)

  return draftAppointment || id === updatingAppointmentId
}

export function excludeCancelledAppointments(data: SerializedAppointment[]): SerializedAppointment[] {
  return data?.filter((item) => item.status !== APPOINTMENT_STATUSES.cancelled)
}
