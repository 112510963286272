import React, { useEffect, useRef, useState } from 'react'
import { Menu, MenuItem, IconButton, makeStyles, Grid, Badge, Popover, FormControlLabel, Divider, CircularProgress } from '@material-ui/core'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import NotificationIcon from '../../../assests/icons/notification.svg'
import TroubleShootIcon from '../../../assests/icons/trble.svg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SearchIcon from '@mui/icons-material/Search'
import { userMenus } from '../../../constants/menu'
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import DehazeIcon from '@mui/icons-material/Dehaze'
import './Header.scss'
import { getCharOfName } from '../../../utilities/profile'
import { capitalizeFirstLetter, getLoggedInUserName } from '../../../utilities'
import { useSelector, useDispatch } from 'react-redux'
import { getAllRoles, getPermissionByRole } from '../../../store/actions/permission'
import { getAllLocations, getStatusCodes } from '../../../store/actions/metaData'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import MenuIcon from '@material-ui/icons/Menu'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { SelectSearchOptionsTimezone } from 'shared/elements/FormItem/SelectSearchOptionsTimezone'
import Routes from 'constants/routes'
import { getProviders, getListSupervising } from 'store/actions/provider'
import { Close } from '@material-ui/icons'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import moment from 'moment'
import { ReactComponent as ReadNotification } from 'assests/icons/readNotification.svg'
import { ReactComponent as NewNotification } from 'assests/icons/newNotification.svg'
import { convertToBase64 } from 'utilities/stringOperations'
import { deleteNotification, getNotificationListV2, markNotificationAsRead, getNewNotificationCount } from 'services/Notification'
import { useNotification } from 'shared/elements/Notification'
import { useTimezone } from 'shared/hooks/useTimezone'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { allSystemNotification } from 'services/Patient/patient'
import AlertModal from 'containers/SystemNotification/AlertModal'
import { READ_STATUSES } from './constant';
import { _getClinicalNotesDetail } from 'containers/ClinicalNotes/api/services'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const useStyles = makeStyles(() => ({
    notchedOutline: {
        border: 'none',
    },
    search_input: {
        width: 'auto',
        height: 40,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    start_consultant_btn: {
        background: 'linear-gradient(180deg, #4C8080 -12.5%, #266262 153.75%)',
        borderRadius: 8,
        fontWeight: 400,
        fontSize: 15,
        marginRight: 16,
    },
    end_consultant_btn: {
        background: 'linear-gradient(180deg, #d53226 -12.5%, #d53226 153.75%)',
        borderRadius: 8,
        fontWeight: 400,
        fontSize: 15,
        marginRight: 16,
    },
    notificationBadge: {
        '& .MuiBadge-dot': {
            backgroundColor: '#EB5757 !important',
            height: '12px !important',
            width: '12px !important',
            borderRadius: '50% !important'
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            transform: 'scale(1) translate(115%, -120%) !important'
        }
    },
    notificationPopover: {
        '& .MuiPaper-root': {
            width: 500,
            height: 500,
            // background: 'yellow',
            top: '100px !important',
            // left: '450px !important',
            border: '1px solid rgba(233, 236, 241, 0.95)',
            borderRadius: 5
        },
        '& .notification-popover-container': {
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 10
        },
        '& .notification-popover-header': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .notification-popover-header-left': {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 20
        },
        '& .notification-popover-header-right': {
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        '& .notification-popoover-clinical-note-button': {
            padding: '4px 12px',
            borderRadius: 8,
            backgroundColor: '#5571C6',
            width: 'fit-content',
            cursor: 'default',
        },
        '& .notification-popover-section-header': {
            color: '#667689 !important',
            fontFamily: 'Rubik !important',
            fontSize: '13px !important',
            fontStyle: 'normal !important',
            fontWeight: '500 !important',
            lineHeight: '140% !important',
        },
        '& .notification-popover-body': {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            // backgroundColor: 'yellow',
            height: 395,
            overflow: 'hidden scroll'
        }
    },
    singleNotificationContainer: {
        borderRadius: 5, 
        paddingTop: 5,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F1F1'
        },
        '&:active': {
            border: '1px solid #5571C6',
        }
    }
}))

const renderHeaderTitle = (str) => {
    if (!str?.replace('/', '')) {
        return 'Dashboard'
    }
    const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())

    if (str?.replace('/', '').includes('consultation') && params.info === 'clinicalNote') {
        return 'Clinical Note'
    }

    const cutStr = str?.replace('/', '').replace('-', ' ') || ''
    return `${cutStr[0]?.toUpperCase() + cutStr.slice(1)}`
}

function NotificationListContent ({
    listData=[],
    handleDeleteNotification,
    handleMakeNotificationRead,
    notificationLoader
}) {
    if(listData?.length === 0) return <></>

    const classes = useStyles()
    const timezone = useTimezone()

    const formattedListData = React.useMemo(() => {
        return listData?.reverse()
    }, [listData])
    
    const onNotificationClick = (notification_id, clinical_note_id, is_read) => {
        if(!is_read) handleMakeNotificationRead(notification_id)
        window.open(`/work-queues/clinical-note/${convertToBase64(
            clinical_note_id
        )}/edit?notification=${true}`)
    }

    const formattedNotificationStartTime = (dateString) => {
        const dateTimeUTC = moment.utc(dateString)
        const dateTimeLocal = dateTimeUTC.clone().tz(timezone?.locationTimezone)
        const formattedDate = dateTimeLocal.format('MMMM D, YYYY hh:mm A')
        return formattedDate
    }

    return (
        <>
            {formattedListData?.map((todayItem) => {
                const readStatusText = Boolean(todayItem?.is_read) ? READ_STATUSES.UNREAD : READ_STATUSES.READ
                return (
                    <div 
                        key={todayItem?._id} 
                        className={classes.singleNotificationContainer}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 10px', alignItems: 'center'}}>
                            <div
                                onClick={() => onNotificationClick(todayItem?._id, todayItem?.additional_details[0]?.clinical_note_id?.toString(), todayItem?.is_read)}
                                style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                <Typography style={{ 
                                    color: '#667689', 
                                    fontWeight: 400, 
                                    fontSize: 14, 
                                    fontFamily: 'Rubik' 
                                }}>{formattedNotificationStartTime(todayItem?.created_at)}</Typography>

                                <Typography style={{ 
                                    color: '#303E4E', 
                                    fontWeight: 400, 
                                    fontSize: 16, 
                                    fontFamily: 'Rubik' 
                                }}>{todayItem?.description}</Typography>
                            </div>
                            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>

                                { notificationLoader?.readLoader[todayItem?._id] ?
                                    <CircularProgress style={{ height: 12, width: 12 }} />
                                    :
                                    <HtmlTooltip title={`Mark as ${readStatusText}`} placement='top' arrow>
                                        <IconButton onClick={() => handleMakeNotificationRead(todayItem?._id)} style={{ height: 25, width: 25 }}>
                                            {todayItem?.is_read ? <ReadNotification /> : <NewNotification />}
                                        </IconButton>
                                    </HtmlTooltip>
                                }

                                { notificationLoader?.deleteLoader[todayItem?._id] ?
                                    <CircularProgress style={{ height: 12, width: 12 }} />
                                    :
                                    <IconButton onClick={() => handleDeleteNotification(todayItem?._id)}>
                                        <Close style={{ width: 15, height: 15, color: '#667689' }} />
                                    </IconButton>
                                }

                            </div>
                        </div>

                        <Divider style={{ width: '96%', margin: '5px 10px 5px 10px' }}/>
                    </div>
                )
            })} 
        </>
    )
}

const Header = ({
    onLogout,
    onProfile,
    isShow,
    handleToggleShowSideBar,
    isShowMenuMobile,
    handleToggleShowMenuMobile,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedProvider, setSelectedProvider] = useState('')
    const [announcementData,setAnnouncementData]=useState([])
    const [viewModal,setViewMOdal]=useState([])
    const [mediaError, setMediaError] = useState(false)
    const [notificationBadgeVisible, setNotificationBadgeVisible] = useState(false)
    const [openNotificationPopup, setOpenNotificationPopup] = useState(null)
    const [showUnread, setShowUnread] = useState(false)
    const [notificationList, setNotificationList] = useState({
        today_notifications: [],
        yesterday_notifications: [],
        older_notifications: []
    })
    const announcementsRead = localStorage.getItem('announcements_is_read')
    const [isFirstNotification, setIsFirstNotification] = useState(false)
    const [isNewNotification, setIsNewNotification] = useState(false)

    const [hasMoreData, setHasMoreData] = useState(true)
    const [perPageData, setPerPageData] = useState(10)

    const [notificationLoader, setNotificationLoader] = useState({
        deleteLoader: {
            0: false
        },
        readLoader: {
            0: false
        }
    })
    const [checkIsMedicalClinicalNote, setCheckIsMedicalClinicalNote] = useState(false)
    const timeRef = useRef(null)
    const notificationScrollRef = useRef(null)
    const toast = useNotification()

    const activeMenu = useSelector((state) => state.menu.activeMenu)
    const loggedInUserDetail = useSelector((state) =>
        state.login.userData.isValidSecurityQus
            ? state.login.userData.response
            : state.login.userData,
    )

    const telehealthScreen = useSelector((state) => state.telehealthScreen)
    const clinicalNotes = useSelector((state) => state.clinicalNotes)
    const { objectEncouterStart } = useSelector((state) => state.provider)

    const handleOpenNotificationPopup = (event) => {
        setOpenNotificationPopup(event?.currentTarget)
    }

    const handleCloseNotificationPopup = () => {
        setOpenNotificationPopup(null)
        setPerPageData(10)
        setShowUnread(false)
        setHasMoreData(true)
    }

    const notificationPopupId = React.useMemo(() => {
        return Boolean(openNotificationPopup) ? 'simple-popover' : undefined
    }, [openNotificationPopup])

    const handleCheckMediaError = async () => {
        try {
            // get audio and video permissions then stop the tracks
            await navigator.mediaDevices
                .getUserMedia({ audio: true, video: true })
                .then(async (mediaStream) => {
                    mediaStream.getTracks().forEach((track) => {
                        track.stop()
                    })
                })
            setMediaError(false)
            // The devicechange event is not fired after permissions are granted, so we fire it
            // ourselves to update the useDevices hook. The 100 ms delay is needed so that device labels are available
            // when the useDevices hook updates.
        } catch (error) {
            if (error instanceof Error) setMediaError(true)
        }
    }
    useEffect(() => {
        if (!mediaError) {
            handleCheckMediaError()
        }
    }, [mediaError])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onLogoutClick: () => {
            onLogout()
            handleClose()
        },
        onProfileClick: () => {
            onProfile()
            handleClose()
        },
    }
    const backToCurrentNote = () => {
        history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, {
            id: objectEncouterStart.clinical_note_id,
        })
    }

    const userName = capitalizeFirstLetter(getLoggedInUserName())

    const getNotificationList = (show_unread = false, per_page = 10) => {
        const payload = {
            show_unread,
            page: 1,
            per_page
        }
        getNotificationListV2(payload)?.then(res => {
            const notificationResponseData = res?.data
            const mergedNotifications = [
                ...notificationResponseData.today_notifications ?? [],
                ...notificationResponseData.yesterday_notifications ?? [],
                ...notificationResponseData.other_notifications ?? []
            ];
            const hasNewNotification = mergedNotifications.some(({ is_read }) => !is_read);
            setIsNewNotification(hasNewNotification);
            setNotificationList((prevState) => ({
                today_notifications: notificationResponseData?.today_notifications,
                yesterday_notifications: notificationResponseData?.yesterday_notifications,
                older_notifications: notificationResponseData?.other_notifications
            }))
            if(notificationResponseData?.other_notifications?.length < per_page) {
                setHasMoreData(false)
            }
        })?.catch(() => {
            toast('Something went wrong!', 'error')
        })
    }

    const handleShowOnlyUnread = (checked) => {
        setShowUnread(checked)
        getNotificationList(checked, perPageData)
    }

    const handleDeleteNotification = (notification_id) => {
        setNotificationLoader(prevState => ({
            ...prevState,
            deleteLoader: {
                [notification_id]: true
            }
        }))
        deleteNotification(notification_id)?.then(() => {
            getNotificationList(showUnread, perPageData)
        })?.catch(() => {
            toast('Something went wrong!', 'error')
        })?.finally(() => {
            setNotificationLoader(prevState => ({
                ...prevState,
                deleteLoader: {
                    [notification_id]: false
                }
            }))
        })
    }

    const handleMakeNotificationRead = (notification_id) => {
        setNotificationLoader(prevState => ({
            ...prevState,
            readLoader: {
                [notification_id]: true
            }
        }))
        markNotificationAsRead(notification_id)?.then(() => {
            getNotificationList(showUnread, perPageData)
        })?.catch(() => {
            toast('Something went wrong!', 'error')
        })?.finally(() => {
            setNotificationLoader(prevState => ({
                ...prevState,
                readLoader: {
                    [notification_id]: false
                }
            }))
        })
    }

    const checkIfNewNotification = () => {
        getNewNotificationCount()?.then(res => {
            const newNotificationCount = res?.data?.NoificationCount
            setIsNewNotification(Boolean(newNotificationCount))
        })?.catch(() => {
            toast('Something went wrong!', 'error')
        })
    }

    useEffect(() => {
        console.log('Notification Polling...')
        if(!isFirstNotification){
            setIsFirstNotification(true)
            checkIfNewNotification()
        }
        if (timeRef.current) {
            clearInterval(timeRef.current);
        }
        timeRef.current = setInterval(() => {
            checkIfNewNotification()
        }, 60000); //1min minutes
        return () => {
            if (timeRef.current) {
                clearInterval(timeRef.current);
            }
        };
    })

    const handleScroll = () => {
        if(!hasMoreData) return
        const container = notificationScrollRef.current;
        if (container) {
          const { scrollTop, scrollHeight, clientHeight } = container
          if (scrollTop + clientHeight >= scrollHeight) {
            getNotificationList(showUnread, (perPageData+10))
            setPerPageData(perPageData+10)
          }
        }
      }

    useEffect(() => {
        if(Boolean(openNotificationPopup)) getNotificationList()
    }, [openNotificationPopup])


    const fetchAllAnnouncement=async()=>{
        const res = await allSystemNotification({start:moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),end:moment().format('YYYY-MM-DDTHH:mm:ss.SSS')});
        setAnnouncementData(res?.data?.data)
        const newEl = [];
        res?.data?.data?.forEach((e,i)=>{
            if(i===0){
                newEl.push(true)
            }
            else{
                newEl.push(false)
            }
        })
        setViewMOdal(newEl)
    }
    useEffect(() => {
        dispatch(getPermissionByRole())
        dispatch(getAllRoles())
        fetchAllAnnouncement()
        // dispatch(getAllLocations())
        // dispatch(getProviders())
        // dispatch(getListSupervising())
        // dispatch(
        //     getStatusCodes({
        //         category: 'SUPERBILL',
        //     }),
        // )
    }, [])
   
    const ShowModals = ()=>{
        return announcementData?.map((ele,ind)=><AlertModal key={ele?.id} ele={ele} ind={ind} 
        length={viewModal?.length} viewModal={viewModal} 
        setViewMOdal={setViewMOdal}
        isShow={viewModal[ind]}/>)
    }
    const checkRoute = () =>{
        const pathNamePart = location.pathname.split('/')
        if(pathNamePart.length == 5){
            if(pathNamePart[2] === 'clinical-note' && (pathNamePart[4] === 'edit' || pathNamePart[4] === 'view') ){
                const id = Buffer.from(pathNamePart[3], 'base64').toString('ascii')
                _getClinicalNotesDetail(id).then((res)=>{
                    if(res?.data?.template_type == 'Medical'){
                        return setCheckIsMedicalClinicalNote(true)
                    }
                    return setCheckIsMedicalClinicalNote(false)
                })
            }
            return setCheckIsMedicalClinicalNote(false)
        }
        return setCheckIsMedicalClinicalNote(false)
    }

    useEffect(()=>{
       checkRoute()
    },[location.pathname])
    return (
        <div className="app_header">
            
               {announcementsRead==='false' && <ShowModals/>}
            
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                    minHeight: 70,
                }}>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {isShow && (
                            <IconButton onClick={handleToggleShowSideBar}>
                                <DehazeIcon
                                    style={{
                                        fontSize: 20,
                                        color: '#667689',
                                        marginRight: 16,
                                    }}
                                />
                            </IconButton>
                        )}
                        <div style={{display: 'flex', alignItem:'center', gap:'8px'}}>
                            {checkIsMedicalClinicalNote &&
                                <IconButton onClick={()=>{history.push('/work-queues/clinical-notes')}}>
                                    <KeyboardBackspaceIcon style={{color: '#303E4E'}}/>
                                </IconButton>
                            }
                            <Typography
                                style={{
                                    fontWeight: '500',
                                    fontSize: '22px',
                                    color: '#303E4E',
                                    lineHeight: '30.8px',
                                }}>
                                {
                                    checkIsMedicalClinicalNote ?
                                        'Clinical Notes'
                                    :
                                        (activeMenu.actualName || activeMenu.name)
                                }    
                                {/* {activeMenu.actualName || activeMenu.name} */}
                            </Typography>
                        </div>
                        

                        {(activeMenu.actualName === 'Schedule' ||
                            activeMenu.name === 'Schedule') && (
                            <Box sx={{ width: '310px', marginLeft: '15px' }}>
                                <SelectSearchOptionsTimezone placeholder="Select timezone" />
                            </Box>
                        )}
                    </div>
                    {telehealthScreen.active &&
                        objectEncouterStart.clinical_note_id &&
                        clinicalNotes.currentNote !== objectEncouterStart.clinical_note_id && (
                            <Grid
                                onClick={backToCurrentNote}
                                container
                                alignItems="center"
                                style={{
                                    cursor: 'pointer',
                                    width: 'max-content',
                                    marginTop: 4,
                                }}>
                                <IconButton
                                    style={{
                                        marginRight: 8,
                                    }}>
                                    <KeyboardBackspaceOutlinedIcon style={{ fontSize: 16 }} />
                                </IconButton>
                                <Typography
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 13,
                                        color: '#5571C6',
                                    }}>
                                    {'Back to the current Clinical Note'}
                                </Typography>
                            </Grid>
                        )}
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 12
                    }}>
                    {mediaError && (
                        <IconButton
                            variant="outlined"
                            style={{
                                height: 40,
                                width: 40,
                                margin: '0 3px 0 12px',
                                background: '#F5F6F7',
                                borderRadius: 8,
                            }}
                            onClick={() => history.push('/video')}>
                            <img src={TroubleShootIcon} alt=""></img>
                        </IconButton>
                    )}
                    
                    <IconButton
                        variant="outlined"
                        style={{
                            height: 40,
                            width: 40,
                            // margin: '0 24px 0 12px',
                            background: '#F5F6F7',
                            borderRadius: 8,
                        }}
                        onClick={handleOpenNotificationPopup}
                    >
                        {isNewNotification ? 
                            <Badge className={classes.notificationBadge} variant="dot" invisible={notificationBadgeVisible}>
                                <img src={NotificationIcon} alt=""></img>
                            </Badge>
                            :
                            <img src={NotificationIcon} alt=""></img>
                        }
                    </IconButton>

                    <div
                        id="auto-header-profile-drp"
                        className="app_header_profile"
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        role="button"
                        onClick={handleClick}
                        onKeyPress={() => {}}
                        tabIndex="0">
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <span className="user_name">{userName}</span>
                        </div>

                        {loggedInUserDetail?.photo ? (
                            <img
                                className="user_image"
                                src={loggedInUserDetail?.photo}
                                alt="John Doe"
                            />
                        ) : (
                            <div className="user_name_char">
                                <span>{getCharOfName(userName)}</span>
                            </div>
                        )}

                        {Boolean(anchorEl) ? (
                            <KeyboardArrowUpIcon
                                style={{
                                    fontSize: 20,
                                    color: '#060D21',
                                    marginLeft: 8,
                                }}
                            />
                        ) : (
                            <KeyboardArrowDownIcon
                                style={{
                                    fontSize: 20,
                                    color: '#060D21',
                                    marginLeft: 8,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 239,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        style={{ color: '#516983' }}
                        key={index}
                        onClick={handleFunc[menu.action]}>
                        <img className="user-menu-icon" src={menu.icon} alt="user-profile-icon" />
                        {menu.name}
                    </MenuItem>
                ))}
            </Menu>
            <Popover
                id={notificationPopupId}
                open={Boolean(openNotificationPopup)}
                anchorEl={openNotificationPopup}
                // onClose={handleCloseNotificationPopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.notificationPopover}
            >
                <div className='notification-popover-container'>
                    <div className='notification-popover-header'>
                        <div className='notification-popover-header-left'>
                            <Typography style={{ 
                                color: '#303E4E', 
                                fontWeight: 500, 
                                fontFamily: 'Rubik', 
                                fontSize: 16 
                            }}>Notifications</Typography>

                            <FormControlLabel
                                control={
                                    <AntSwitch
                                        checked={showUnread}
                                        onChange={(e) => handleShowOnlyUnread(e?.target?.checked)}
                                    />
                                }
                                label="Only show unread"
                                style={{ margin: '0px', gap: '3px' }}
                            />
                        </div>
                        <div className='notification-popover-header-right'>
                            <IconButton onClick={handleCloseNotificationPopup}>
                                <Close style={{ color: '#667689', height: '20px', width: '20px' }}/>
                            </IconButton>
                        </div>
                    </div>

                    <Divider style={{ width: '100%' }} />

                    <span className='notification-popoover-clinical-note-button'>
                        <Typography style={{ 
                            color: '#fff', 
                            fontWeight: 400, 
                            fontFamily: 'Rubik', 
                            fontSize: 13 
                        }}>Clinical notes</Typography>
                    </span>

                    <div className='notification-popover-body' onScroll={handleScroll} ref={notificationScrollRef}>
                        {notificationList?.today_notifications?.length > 0 && <Typography className='notification-popover-section-header'>
                            Today
                        </Typography>}

                        <NotificationListContent 
                            listData={notificationList?.today_notifications}
                            handleDeleteNotification={handleDeleteNotification}
                            handleMakeNotificationRead={handleMakeNotificationRead}
                            notificationLoader={notificationLoader}
                         />                       

                        {notificationList?.yesterday_notifications?.length > 0 && <Typography className='notification-popover-section-header'>
                            Yesterday
                        </Typography>}

                        <NotificationListContent 
                            listData={notificationList?.yesterday_notifications}
                            handleDeleteNotification={handleDeleteNotification}
                            handleMakeNotificationRead={handleMakeNotificationRead}
                            notificationLoader={notificationLoader}
                        />

                        {notificationList?.older_notifications?.length > 0 && <Typography className='notification-popover-section-header'>
                            Older
                        </Typography>}

                        <NotificationListContent 
                            listData={notificationList?.older_notifications}
                            handleDeleteNotification={handleDeleteNotification}
                            handleMakeNotificationRead={handleMakeNotificationRead}
                            notificationLoader={notificationLoader}
                        />
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default Header
