import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { Loading } from 'shared/elements/Loading'
import { Card } from '../../view/Card'
import { NoData } from '../NoData'

import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { usePatientDashboardStyles } from '../../style'
import { OutpatientPsychiatric } from '../../interfaces'
import { OutpatientPsychiatricForm } from '../../view/OutpationPsychiatricHistory/OutpatientPsychiatricForm'
import { apiOutpatientPsychiatricHistory } from 'services/Patient/apiOutpatientPsychiatricHistory'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
}

export const OutpatientPsychiatricHistory = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [outpatientPsychiatric, setOutpatientPsychiatric] = useState<OutpatientPsychiatric[]>([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    const handleViewAll = () =>
        history.push(Routes.PATIENT.OUTPATIENT_PSYCHIATRIC_HISTORY.replace(':id', patientId))

    const Content = () => (
        <>
            {outpatientPsychiatric.map((item, key) => (
                <Grid
                    container
                    key={key}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #EFF2F5',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        direction="column"
                        style={{ width: 'fit-content' }}>
                        <Typography>{item.reason}</Typography>
                    </Grid>
                </Grid>
            ))}

            {!!outpatientPsychiatric.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    const getOutPatientPsychiatricHistoryList = async (patientId) => {
        try {
            setLoading(true)
            const res = await apiOutpatientPsychiatricHistory.getAllbyPtientId(patientId, {
                page: 1,
                size: 5,
            })
            if (res.data) {
                setOutpatientPsychiatric(res.data.data as OutpatientPsychiatric[])
            }
        } catch (error) {
            notification('Cannot get list outpatientPsychiatric', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getOutPatientPsychiatricHistoryList(patientId)
        }

        return () => abortController.abort()
    }, [])

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Outpatient Psychiatric History">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {outpatientPsychiatric.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>
            {isAddingNew && (
                <OutpatientPsychiatricForm
                    // outpatientPsychiatricId={}
                    patientId={patientId}
                    open={isAddingNew}
                    onClose={() => {
                        setIsAddingNew(false)
                    }}
                    onOke={async () => {
                        setIsAddingNew(false)
                        getOutPatientPsychiatricHistoryList(patientId)
                    }}
                />
            )}
        </Card>
    )
}
