import { Box, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { DatePicker as DatePickerCustom } from 'shared/elements/DatePicker/DatePicker'
import clsx from 'clsx'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { usePatient } from 'containers/PatientsSetting/hooks'
import {
    DAY_SUPPLY,
    DOSE,
    DOSE_UNIT,
    DRUG_TYPE,
    FREQUENCY,
    OTHER,
    PATIENT_DIRECTIONS,
    ROUTE,
    SCHEDULED_DRUG,
    SUBSTITUTION_PERMITTED,
    UNIT,
} from 'containers/PatientsSetting/MedicationsCreateDialog/constant'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getMedicalProblemList } from 'services/Patient/patient'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { SelectEl } from '../../../../shared/elements'
import { SearchDrug } from '../../../../shared/elements/FormItem/SearchComponents/SearchDrug'
import { SelectAndEnterText } from 'shared/elements/FormItem/SelectAndEnterText'

const useStyles = makeStyles(() => ({
    colorContent: {
        color: '#667689',
    },
    ellipsis__text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}))

const Dropdown = (props) => {
    const classes = useStyles()
    return (
        <SelectEl
            selectProps={{
                style: {
                    height: 40,
                },
            }}
            renderValue={(sel) => {
                return (typeof sel === 'string' && !sel) || !sel ? (
                    <Typography className={classes.colorContent}>{props.placeholder}</Typography>
                ) : (
                    <Typography className={clsx(classes.ellipsis__text, classes.colorContent)}>
                        {props.options.find((item) => item.key === sel)?.value}
                    </Typography>
                )
            }}
            {...props}
        />
    )
}

function Staff({ values, touched, errors, setFieldValue, setFieldError, patientId, patientInfo }) {
    const [problemOptions, setProblemOptions] = useState([])
    async function getProblemsData(params) {
        try {
            //@todo update correct params
            const response = await getMedicalProblemList(patientId, 1, 10000)

            setProblemOptions(
                response.data?.data?.map((item) => ({
                    // key: item.id,
                    // value: item?.icd_code?.code + ' - ' + item?.icd_code?.display,
                    // value: `${item?.icd_code?.code} - ${item?.icd_code?.display}`,
                    key: `${item?.icd_code?.code}`,
                    value: `${item?.icd_code?.code} - ${item?.icd_code?.display}`,
                })) || [],
            )
        } catch (error) {
            console.log({ error })
        }
    }

    const handleChangeDate = (value, key) => {
        if (!value) {
            return setFieldValue(key, null)
        }
        // mm/dd/yyyy
        const regex = /^\d{2}\/\d{2}\/\d{4}$/
        if (regex.test(value)) {
            setFieldValue(key, moment(value, 'MM/DD/YYYY'))
        }
    }

    useEffect(() => {
        if (patientId) {
            getProblemsData()
        }
    }, [patientId])

    return (
        <Grid
            container
            item
            spacing={3}
            className="modal-spacer"
            style={{ marginTop: 24, marginBottom: 16 }}>
            <Grid container item alignItems="flex-end" spacing={2}>
                <Grid container item alignItems="flex-start" spacing={2}>
                    <Grid item xs={6}>
                        <SearchDrug
                            label="Drug"
                            required
                            name="drug"
                            value={values.drug}
                            placeholder="Search drug"
                            error={touched.drug?.display && Boolean(errors.drug?.display)}
                            helperText={touched.drug?.display && errors.drug?.display}
                            onChange={async (value, form, strength) => {
                                console.log(value, 'z value')
                                await setFieldValue('drug', value)
                                await setFieldValue('form', form)
                                await setFieldValue('strength', strength)
                            }}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextBox
                            label="Strength"
                            required
                            readOnly={true}
                            variant="outlined"
                            value={values.strength}
                            fullWidth
                            error={touched.strength && Boolean(errors.strength)}
                            helperText={touched.strength && errors.strength}
                            placeholder="Enter strength"
                            onChange={(e) => setFieldValue('strength', e)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBox
                            label="Form"
                            required
                            readOnly={true}
                            variant="outlined"
                            value={values.form}
                            fullWidth
                            error={touched.form && Boolean(errors.form)}
                            helperText={touched.form && errors.form}
                            placeholder="Enter form"
                            onChange={(e) => setFieldValue('form', e)}
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Drug Type
                        </FormLabel>
                        <Dropdown
                            placeholder="Select"
                            value={values.drugType}
                            options={DRUG_TYPE}
                            onChange={(e) => {
                                setFieldValue('drugType', e.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Scheduled Drug
                        </FormLabel>
                        <Dropdown
                            placeholder="Unit"
                            value={values.schedule_drug}
                            options={SCHEDULED_DRUG}
                            onChange={(e) => setFieldValue('schedule_drug', e.target.value)}
                        />
                    </Grid> */}
                </Grid>
                <Grid container item alignItems="flex-end" spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel component="p" className="input-form-label">
                            Provider
                        </FormLabel>
                        <SearchProviderInput
                            placeholder="Search provider name"
                            value={values.practitioner_id}
                            handleInputChange={(e) => {
                                setFieldValue('practitioner_id', e.target.value)
                            }}
                            handleAddRow={(value) => {
                                setFieldValue('practitioner_id', value?.id)
                            }}
                            error={touched.practitioner_id && Boolean(errors.practitioner_id)}
                            helperText={touched.practitioner_id && errors.practitioner_id}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel
                        // component="p"
                        className="input-form-label">
                        Patient Directions
                    </FormLabel>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <Dropdown
                                placeholder="Action"
                                value={values.action}
                                options={PATIENT_DIRECTIONS}
                                onChange={(e) => setFieldValue('action', e.target.value)}
                                error={touched.action && Boolean(errors.action)}
                                helperText={touched.action && errors.action}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            {/* <Dropdown
                                placeholder="Dose"
                                value={`${values.dose}`}
                                options={DOSE}
                                onChange={(e) => setFieldValue('dose', e.target.value)}
                                error={touched.dose && Boolean(errors.dose)}
                                helperText={touched.dose && errors.dose}
                            /> */}
                            <SelectAndEnterText
                                options={DOSE}
                                placeholder="Dose"
                                error={touched.dose && Boolean(errors.dose)}
                                helperText={touched.dose && errors.dose}
                                inputValue={`${values.dose}`}
                                onInputChange={(value) => setFieldValue('dose', value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Dropdown
                                placeholder="Dose Unit"
                                value={values.dose_unit}
                                options={DOSE_UNIT}
                                onChange={(e) => setFieldValue('dose_unit', e.target.value)}
                                error={touched.dose_unit && Boolean(errors.dose_unit)}
                                helperText={touched.dose_unit && errors.dose_unit}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={4}>
                            <Dropdown
                                placeholder="Route"
                                value={values.route}
                                options={ROUTE}
                                onChange={(e) => setFieldValue('route', e.target.value)}
                                error={touched.route && Boolean(errors.route)}
                                helperText={touched.route && errors.route}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Dropdown
                                placeholder="Frequency"
                                value={values.frequency}
                                options={FREQUENCY}
                                onChange={(e) => setFieldValue('frequency', e.target.value)}
                                error={touched.frequency && Boolean(errors.frequency)}
                                helperText={touched.frequency && errors.frequency}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Dropdown
                                placeholder="Other"
                                value={values.other}
                                options={OTHER}
                                onChange={(e) => setFieldValue('other', e.target.value)}
                                error={touched.other && Boolean(errors.other)}
                                helperText={touched.other && errors.other}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel component="p" className="input-form-label">
                        Additional Directions to Patient
                    </FormLabel>
                    <Box
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: 'auto',
                            },
                        }}>
                        <TextField
                            variant="outlined"
                            value={values.direction_patient}
                            fullWidth
                            placeholder="Enter direction to patient"
                            onChange={(e) => setFieldValue('direction_patient', e.target.value)}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container item alignItems="flex-end" spacing={2}>
                <Grid item xs={12}>
                    <FormLabel component="p" className="input-form-label">
                        Days Supply
                    </FormLabel>
                    <Dropdown
                        placeholder="Select day supply"
                        value={+values.day_supply}
                        options={DAY_SUPPLY}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => setFieldValue('day_supply', e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <FormLabel
                    // component="p"
                    className="input-form-label">
                    Quantity
                </FormLabel>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: 'auto',
                            },
                        }}>
                        <TextField
                            variant="outlined"
                            value={values.quantity}
                            fullWidth
                            placeholder="Enter quantity"
                            onChange={(e) => setFieldValue('quantity', e.target.value)}
                            error={touched.quantity && Boolean(errors.quantity)}
                            helperText={touched.quantity && errors.quantity}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Dropdown
                        placeholder="Unit"
                        value={values.unit}
                        options={UNIT}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => setFieldValue('unit', e.target.value)}
                        error={touched.unit && Boolean(errors.unit)}
                        helperText={touched.unit && errors.unit}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormLabel
                    // component="p"
                    className="input-form-label">
                    Refills
                </FormLabel>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: 'auto',
                            },
                        }}>
                        <TextField
                            variant="outlined"
                            value={values.refills}
                            fullWidth
                            placeholder="Enter refills"
                            onChange={(e) => setFieldValue('refills', e.target.value)}
                            inputProps={{
                                maxLength: 2,
                            }}
                            error={touched.refills && Boolean(errors.refills)}
                            helperText={touched.refills && errors.refills}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Dropdown
                        placeholder="Select substitution permitted"
                        value={values.substitution}
                        options={SUBSTITUTION_PERMITTED}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => setFieldValue('substitution', e.target.value)}
                    />
                </Grid>
            </Grid>

            <Grid item xs={4}>
                <DatePickerCustom
                    label="Start Date"
                    name="start_date"
                    value={values.start_date}
                    onChange={(value) => handleChangeDate(value, 'start_date')}
                    maxDate={moment()}
                    minDate={moment(patientInfo.dob)}
                    notDefaultValue={true}
                    error={touched.start_date && Boolean(errors.start_date)}
                    helperText={touched.start_date && errors.start_date}
                />
            </Grid>
            <Grid item xs={4}>
                <DatePickerCustom
                    label="Stop Date"
                    name="stop_date"
                    value={values.stop_date}
                    onChange={(value) => handleChangeDate(value, 'stop_date')}
                    minDate={
                        values.start_date
                            ? moment.max(moment(patientInfo.dob), values.start_date)
                            : moment(patientInfo.dob)
                    }
                    notDefaultValue={true}
                    error={touched.stop_date && Boolean(errors.stop_date)}
                    helperText={touched.stop_date && errors.stop_date}
                />
            </Grid>
            <Grid item xs={4}>
                <DatePickerCustom
                    label="Last Written Date"
                    name="last_written_date"
                    value={values.last_written_date}
                    onChange={(value) => handleChangeDate(value, 'last_written_date')}
                    minDate={
                        values.start_date
                            ? moment.max(moment(patientInfo.dob), values.start_date)
                            : moment(patientInfo.dob)
                    }
                    maxDate={values.stop_date ? moment.min(moment(), values.stop_date) : moment()}
                    notDefaultValue={true}
                    error={touched.last_written_date && Boolean(errors.last_written_date)}
                    helperText={touched.last_written_date && errors.last_written_date}
                />
            </Grid>

            <Grid item xs={12}>
                <FormLabel component="p" className="input-form-label">
                    Directions to Pharmacist
                </FormLabel>
                <Box
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: 'auto',
                        },
                    }}>
                    <TextField
                        placeholder="Enter directions to pharmacist"
                        variant="outlined"
                        value={values.direction_pharmacist}
                        fullWidth
                        multiline
                        minRows={4}
                        onChange={(e) => setFieldValue('direction_pharmacist', e.target.value)}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <FormLabel component="p" className="input-form-label">
                    Primary Diagnosis
                </FormLabel>
                <Dropdown
                    placeholder="Select primary diagnosis"
                    value={values?.diagnosis_1?.code}
                    options={problemOptions.filter((item) => item.key !== values.diagnosis_2?.code)}
                    onChange={(e) => {
                        const problem = problemOptions.find((item) => item.key === e.target.value)
                        setFieldValue('diagnosis_1', {
                            code: problem?.key,
                            display:
                                problem?.value.split(' - ')?.[1] ||
                                problem?.value.split(' - ')?.[0],
                        })
                    }}
                    error={Boolean(errors.diagnosis_1)}
                    helperText={errors.diagnosis_1}
                />
            </Grid>
            <Grid item xs={12}>
                <FormLabel component="p" className="input-form-label">
                    Secondary Diagnosis
                </FormLabel>
                <Dropdown
                    placeholder="Select secondary diagnosis"
                    value={values.diagnosis_2?.code}
                    options={problemOptions.filter((item) => item.key !== values.diagnosis_1?.code)}
                    onChange={(e) => {
                        if (values.diagnosis_1) {
                            const problem = problemOptions.find(
                                (item) => item.key === e.target.value,
                            )
                            setFieldValue('diagnosis_2', {
                                code: problem?.key,
                                display:
                                    problem?.value.split(' - ')?.[1] ||
                                    problem?.value.split(' - ')?.[0],
                            })
                        } else {
                            setFieldError(
                                'diagnosis_1',
                                'You may not specify secondary diagnosis without primary diagnosis',
                            )
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Staff
