import { Grid, makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import React from 'react'

const useStyles = makeStyles(() => ({
    dasboard_container: {
        padding: '0',
    },
}))

const REACT_APP_BI_URL = process.env.REACT_APP_BI_URL
function ProviderDashboard() {
    const classes = useStyles()

    return (
        <Grid container className={classes.dasboard_container}>
            <Box
                className="checkPoit"
                sx={{
                    display: 'block',
                    width: '100%',
                    height: '100vh',
                    position: 'relative',
                    '& iframe': {
                        display: 'block',
                        width: '100%',
                        height: 'calc(100% - 70px)',
                    },
                }}>
                <iframe
                    title="sonora_ecw_dashboard"
                    src={REACT_APP_BI_URL}
                    frameBorder="0"
                    allowFullScreen={true}></iframe>
            </Box>
        </Grid>
    )
}

export default ProviderDashboard
