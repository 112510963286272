import { Button, makeStyles } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, CircularProgress, Grid } from '@mui/material'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { allApiReffringProvider } from 'services/Calendar'
import { ModalEl } from 'shared/elements'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { formatNumber } from 'shared/utils'
import { INITIAL_VALUES } from './Schema'
import { validationSchema } from './validationSchema'

interface Props {
    optionSpecialty?: {
        key: string | number
        value: string | number
    }[]
    [x: string]: any
}

const useStyles = makeStyles(
    (theme) =>
        ({
            button_cancel: {
                background: '#303E4E !important',
                color: 'white',
                fontWeight: 400,
                borderRadius: '8px !important',
                fontSize: '15px !important',
                width: '100%',
            },
            button_create: {
                background: '#5571C6 !important',
                color: 'white',
                fontWeight: 400,
                borderRadius: '8px !important',
                fontSize: '15px !important',
                width: '100%',
                textTransform: 'unset !important',
            },
        } as any),
)

export const ReferringProviderForm = ({
    loading,
    optionServiceLocation,
    optionSpecialty,
    ...props
}: Props) => {
    const classes = useStyles()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [specialOptions, setSpecialOptions] = useState([])
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)

    function compareObject(obj1, obj2, dropKey?: string[]) {
        const a = { ...obj1 }
        const b = { ...obj2 }
        if (dropKey) {
            dropKey.forEach((key) => {
                delete a[key]
                delete b[key]
            })
        }

        return JSON.stringify(a) === JSON.stringify(b) ? true : false
    }

    const handleSubmit = (values) => {
        const checkValueForm = compareObject(INITIAL_VALUES, values, ['age_patient'])
        if (checkValueForm) {
            setAlerEmtyForm(true)
            return
        }
        props.onSubmit(values)
    }

    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    const getRefferringProviderById = async (id) => {
        setLoadingSchedule(true)
        try {
            const res = await allApiReffringProvider.getById(id || '')
            if (res.data) {
                setInitData({
                    ...initData,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    specialty_id: res.data.specialty?.id,
                    npi: res.data.npi,
                    email: res.data.email,
                    location_id: res.data.location?.id,
                    phone: res.data.phone,
                    fax: res.data.fax,
                    comment: res.data.comment,
                })
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        if (props.refferringItem?.id) {
            const abortController = new AbortController()
            getRefferringProviderById(props.refferringItem?.id)
            return () => abortController.abort()
        }
    }, [props.refferringItem?.id])

    return (
        <ModalEl
            title={
                props.refferringItem?.id
                    ? 'Update Referring Provider'
                    : 'Add New Referring Provider'
            }
            description="Something..."
            open={props.open}
            onClose={() => props.onModalClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ marginTop: 16 }}>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    spacing={2}
                                    alignItems="flex-start">
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="First Name"
                                            name="name"
                                            value={formik.values.first_name}
                                            placeholder="Enter provider first name"
                                            onChange={(value) =>
                                                formik.setFieldValue('first_name', value)
                                            }
                                            error={
                                                formik.touched.first_name &&
                                                Boolean(formik.errors.first_name)
                                            }
                                            helperText={
                                                formik.touched.first_name &&
                                                formik.errors.first_name
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Last Name"
                                            name="last_name"
                                            value={formik.values.last_name}
                                            placeholder="Enter provider last name"
                                            onChange={(value) =>
                                                formik.setFieldValue('last_name', value)
                                            }
                                            error={
                                                formik.touched.last_name &&
                                                Boolean(formik.errors.last_name)
                                            }
                                            helperText={
                                                formik.touched.last_name && formik.errors.last_name
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="NPI"
                                            // type={'number'}
                                            name="npi"
                                            value={formik.values.npi}
                                            placeholder="Enter NPI number"
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    'npi',
                                                    value.replace(/[^0-9]+/g, ''),
                                                )
                                            }
                                            inputProps={{
                                                maxLength: 10,
                                            }}
                                            error={formik.touched.npi && Boolean(formik.errors.npi)}
                                            helperText={formik.touched.npi && formik.errors.npi}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={(value) =>
                                                formik.setFieldValue('email', value)
                                            }
                                            placeholder="Enter provider email"
                                            error={
                                                formik.touched.email && Boolean(formik.errors.email)
                                            }
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            label="Service Location"
                                            placeholder="Select location"
                                            name="location_id"
                                            value={formik.values.location_id}
                                            displayEmpty={!formik.values.location_id}
                                            style={{
                                                height: 40,
                                            }}
                                            multiple={false}
                                            error={
                                                formik.touched.location_id &&
                                                Boolean(formik.errors.location_id)
                                            }
                                            helperText={
                                                formik.touched.location_id &&
                                                formik.errors.location_id
                                            }
                                            options={optionServiceLocation || []}
                                            onChange={(value) =>
                                                formik.setFieldValue('location_id', value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            label="Specialty "
                                            placeholder="Select specialty"
                                            name="specialty_id"
                                            value={formik.values.specialty_id}
                                            displayEmpty={!formik.values.specialty_id}
                                            style={{
                                                height: 40,
                                            }}
                                            error={
                                                formik.touched.specialty_id &&
                                                Boolean(formik.errors.specialty_id)
                                            }
                                            multiple={false}
                                            helperText={
                                                formik.touched.specialty_id &&
                                                formik.errors.specialty_id
                                            }
                                            options={optionSpecialty || []}
                                            onChange={(value) => {
                                                formik.setFieldValue('specialty_id', value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Phone"
                                            name="phone"
                                            placeholder="Enter contact number"
                                            value={formatNumber(formik.values.phone)}
                                            onChange={(value) => {
                                                formik.setFieldValue(
                                                    'phone',
                                                    value.replace(/[^0-9]+/g, ''),
                                                )
                                            }}
                                            inputProps={{
                                                maxLength: 16,
                                            }}
                                            error={
                                                formik.touched.phone && Boolean(formik.errors.phone)
                                            }
                                            helperText={formik.touched.phone && formik.errors.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Fax"
                                            name="fax"
                                            placeholder="Enter fax number"
                                            value={formatNumber(formik.values.fax)}
                                            onChange={(value) => {
                                                formik.setFieldValue(
                                                    'fax',
                                                    value.replace(/[^0-9]+/g, ''),
                                                )
                                            }}
                                            inputProps={{
                                                maxLength: 16,
                                            }}
                                            error={formik.touched.fax && Boolean(formik.errors.fax)}
                                            helperText={formik.touched.fax && formik.errors.fax}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div className="border-top-inside" />

                        <Grid container item direction="row" spacing={2} alignItems="flex-start">
                            <Grid item xs={12}>
                                <TextBox
                                    label="Comment"
                                    name="comment"
                                    value={formik.values.comment}
                                    multiline
                                    rows={4}
                                    placeholder="Enter comment"
                                    onChange={(value) => formik.setFieldValue('comment', value)}
                                    error={formik.touched.comment && Boolean(formik.errors.comment)}
                                    helperText={formik.touched.comment && formik.errors.comment}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes['button_cancel']}
                                onClick={props.onModalClose}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                disabled={loading}
                                className={classes['button_create']}
                                onClick={() => formik.handleSubmit()}>
                                {!props.refferringItem?.id ? 'Save' : 'Update'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AlertEmtyForm
                title="Please input the values to form!"
                description={'Please input the values to form!'}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </ModalEl>
    )
}
