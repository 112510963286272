import React, { useState } from 'react'
import { Button, Grid, IconButton, Menu, MenuItem, Popover, Typography } from '@material-ui/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { LoadingButton } from '@mui/lab'
import { AlertDeleteDialog } from 'components/shared/Elements/AlertDeleteDialog'


type ActionType = 'Edit' | 'Delete' | string

type MenuItems = {
    text: ActionType
    handler: () => void | Promise<void>
    disable: Boolean

}[]

interface Props {
    menu: MenuItems
    deleteMain?: string
    deleteMessage?: string
}

export const EditNewAction = (props: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [loading, setLoading] = useState(false)
    const [anchorEndEl, setAnchorEndEl] = useState<HTMLButtonElement | null>(null)

    const handleEndClose = () => {
        setAnchorEndEl(null)
    }
    const openEnd = Boolean(anchorEndEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }
    const currentTarget = () => {
        setAnchorEndEl(anchorEl)
    }
    const handleClose = () =>
        setAnchorEl(null)

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item>
                <IconButton
                    size="small"
                    onClick={handleClick}
                    style={{
                        background: '#FFFFFF',
                        border: '1px solid #E9ECF1',
                        borderRadius: '20px',
                    }}>
                    <MoreHorizIcon
                        style={{
                            color: '#3E465B',
                            fontSize: 18,
                        }}
                    />
                </IconButton>

                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            width: 120,
                            marginTop: 8,
                        },
                    }}
                    getContentAnchorEl={null}>
                    {props.menu.map((menu, index) => {
                        if (menu?.disable) {
                            return (
                                <MenuItem
                                    id={`auto-header-${menu.text}`}
                                    key={index}
                                    onClick={() => {
                                        handleClose()
                                        if (menu.text !== 'Delete')
                                            menu.handler()
                                        else
                                            currentTarget()
                                    }}
                                    style={{
                                        color:
                                            menu.text === 'Delete'
                                                ? '#E81212'
                                                : '#667689',
                                    }}>
                                    {menu.text}
                                </MenuItem>
                            )
                        }
                        return null;
                    })}
                </Menu>
                <Popover
                    id={idEnd}
                    open={openEnd}
                    anchorEl={anchorEndEl}
                    onClose={handleEndClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div className="popover_container">
                        <div className="heading_holder">
                            <h2 className="heading">
                                {props.deleteMain ? props.deleteMain : "Referral Deletation"}
                            </h2>
                        </div>
                        <Typography className="content">
                       { props.deleteMessage ? props.deleteMessage :'Are you sure you want to delete this referral ? '}
                        </Typography>
                        <div className="btn_holder">
                            <Button
                                className="no_bg"
                                onClick={
                                    handleEndClose
                                }
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                className="error"
                                loading={loading}
                                onClick={async () => {
                                    setLoading(true)
                                    const deleteAction = Object.values(props.menu)
                                        .find(item => item.text === 'Delete')

                                    if (!deleteAction || !deleteAction.handler)
                                        throw new Error(
                                            'No implementation of delete handler() was provided!'
                                        )
                                    await deleteAction.handler()
                                    handleEndClose()
                                    setLoading(false)
                                }}
                            >
                                Delete
                            </LoadingButton>
                        </div>
                    </div>
                </Popover>
            </Grid>
        </Grid>
    )
}
