import React, { useEffect } from 'react'
import { Box, Typography, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import CheckboxOption from '../reusable/CheckboxOptions'
import TextWithMacro from '../reusable/TextWithMacro'
import RadioOptions from '../reusable/RadioOption'
import { SelectEl, DatePickerEl } from '../../../shared/elements'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import moment from 'moment'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import SocialHistoryView from 'containers/PatientsSetting/view/SocialHistoryView'
import { useNotification } from 'shared/elements/Notification'
import { apiSocialHistory } from 'services/Patient/apiSocialHistory'

const useStyles = makeStyles({
    container: {
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    sectionGridContainer: {
        margin: '10px 0 0 0',
    },
    textFieldWrap: {},
    divider: {
        background: '#E9ECF1',
        height: 1,
    },
})

const getDropdownOptions = (master = [], key) => {
    return master.filter((m) => m.section_name === key).map((m) => ({ key: m._id, value: m.name }))
}

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

const getSection = (master = [], key) => {
    return master.filter((m) => m.section_name === key)
}

const GeneralSocialHistory = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    patientId,
    encounter_id,
    nonEditable
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const currentlyLiveWithOptions = getSection(master?.generalSocHisMasters, 'currently_live_with')

    const maritalStatusOptions = getDropdownOptions(
        master?.generalSocHisMasters,
        'current_matrial_status',
    )

    const intimateRelationOptions = getDropdownOptions(
        master?.generalSocHisMasters,
        'intimate_relationship_status',
    )

    const intimateSatisfactionLevelOption = getDropdownOptions(
        master?.generalSocHisMasters,
        'intimate_satisfaction_level',
    )

    const sexualOrientationOption = getDropdownOptions(
        master?.generalSocHisMasters,
        'sexual_orientation',
    )

    const currentLivingStatusOption = getDropdownOptions(
        master?.generalSocHisMasters,
        'current_living_situation',
    )

    const currentOccupationStatusOption = getDropdownOptions(
        master?.generalSocHisMasters,
        'current_occupation_status',
    )

    const yearlyIncomeOptions = getDropdownOptions(
        master?.generalSocHisMasters,
        'current_yearly_income',
    )

    const socialSituationOptions = getSection(master?.generalSocHisMasters, 'social_situation')

    const handleChange = (e) => {
        const { name, value } = e.target
        onChange(name, value)
    }

    const handleInputChange = (e, id) => {
        const { name, value, checked, type } = e.target
        if (type === 'checkbox') {
            let updatedValue = values[name]?.social_situation_opt
                ? [...values[name].social_situation_opt]
                : []
            updatedValue = checked
                ? [...updatedValue, id]
                : updatedValue.filter((uv) => uv !== value)
            onChange(`${fieldName}.${name}.social_situation_opt`, updatedValue)
        }

        if (type === 'text') {
            onChange(`${fieldName}.${name}`, value)
        }
    }

    const handleInputChangeLiveWith = (e, id) => {
        const { name, value, checked, type } = e.target
        if (type === 'checkbox') {
            let updatedValue = values[name]?.live_with_opt ? [...values[name].live_with_opt] : []
            updatedValue = checked
                ? [...updatedValue, id]
                : updatedValue.filter((uv) => uv !== value)
            onChange(`${fieldName}.${name}.live_with_opt`, updatedValue)
        }

        if (type === 'text') {
            onChange(`${fieldName}.${name}`, value)
        }
    }

    const getSocialHistoryList = async () => {
        try {
            const resp = await apiSocialHistory.getAll(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data?.data) {
                onChange(`${fieldName}.social_history_list`, resp.data.data)
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    useEffect(() => {
        if (patientId) {
            getSocialHistoryList()
        }
    }, [patientId])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
            }}>
            <SocialHistoryView
                isInClinicalNote
                patient_Id={patientId}
                encounter_id={encounter_id}
                afterActionOk={getSocialHistoryList}
                nonEditable={nonEditable}
            />

            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: master?.generalSocHisMasters,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default GeneralSocialHistory
