import {
    sortAscending,
    sortDescending,
} from '../../shared/utils';
import * as actionTypes from './actionTypes';

export const initialState = {
    patients: {
        data: [],
        isLoading: false,
        isLoaded: false,
        isError: false,
        totalCount: 0,
        sort: {
            full_name: 'asc',
            dob: 'asc',
            mob: 'asc',
            email: 'asc',
        },
    },
    searchString: '',
    pagination: {
        page: 1,
        limit: 10,
    },
};

export function reducer(state, action) {
    switch (action.type) {
        
        case actionTypes.PATIENTS_LOADING:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    isLoading: true,
                },
            };

        case actionTypes.PATIENTS_SUCCESS:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data.patient.map(
                        (patient) => {
                            return {
                                ...patient,
                                full_name: `${patient.first_name} ${patient.last_name}`,
                                mob: patient.user.mob,
                                email: patient.user.email,
                                associated_clinic:
                                    patient.clinic
                                        ?.clinic_name ??
                                    '-',
                            };
                        },
                    ),
                    totalCount: action.data.totalCount,
                },
            };

        case actionTypes.PATIENTS_FAILURE:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    isError: true,
                    isLoaded: true,
                },
            };

        case actionTypes.PATIENTS_PAGE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            };

        case actionTypes.SEARCH_PATIENTS:
            return {
                ...state,
                searchString: action.value,
                pagination: {
                    ...state.pagination,
                    page: 1,
                },
            };

        case actionTypes.PATIENTS_SORT_DATA:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    sort: {
                        ...state.patients.sort,
                        [action.value.field]:
                            state.patients.sort[
                                action.value.field
                            ] === 'asc'
                                ? 'desc'
                                : 'asc',
                    },
                    data:
                        state.patients.sort[
                            action.value.field
                        ] === 'asc'
                            ? sortAscending(
                                  state.patients.data,
                                  action.value.field,
                              )
                            : sortDescending(
                                  state.patients.data,
                                  action.value.field,
                              ),
                },
            };

        default:
            return state;
    }
}
