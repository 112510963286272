import { useState, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent,IconButton } from '@mui/material'
import { getMedicationSSOLink, getPrescriptionSSOLink } from 'services/Patient/patient'
import LoadingPage from 'utilities/loading-page'
import { InaccessibleConfirm } from 'containers/ERX/InaccessibleConfirm'
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
    },

    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
    header: {
        fontSize: '16px',
        color: '#303E4E',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 18,
    },
}))

export default function NewErxDialog({ open, handleClose, description, patientId, prescription, isLoadingButton }) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [ssoLink, setSsoLink] = useState('')
    const [ssoLinkError, setSsoLinkError] = useState(null)

    const fetchErxSsoLink = async () => {
        setLoading(true)
        if (prescription) {
            await getPrescriptionSSOLink(patientId)
                .then((res) => {
                    res?.data?.ssoLink && setSsoLink(res?.data?.ssoLink)
                })
                .catch((err) => {
                    setSsoLinkError(err?.data?.message)
                })
        } else {
            await getMedicationSSOLink(patientId)
                .then((res) => {
                    res?.data?.ssoLink && setSsoLink(res?.data?.ssoLink)
                })
                .catch((err) => {
                    setSsoLinkError(err?.data?.message)
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchErxSsoLink()
    }, [patientId, open])

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1100px',
                },
            }}
            fullWidth={true}
            onClose={handleClose}
            className={classes.root}>
            <DialogContent>
                {description && 
                <div style={{display: "flex", alignItems:"center",justifyContent: 'space-between'}}>
                <Typography className={classes.header}>ePrescribe</Typography>
                <IconButton style={{marginBottom: 18}} onClick={handleClose}>
                  <CloseIcon style={{color: "#303E4E"}}/>
                </IconButton>
                </div>}
                {loading ? (
                    <LoadingPage />
                ) : ssoLink ? (
                    <iframe
                        src={ssoLink}
                        title="description"
                        style={{
                            width: '100%',
                            height: '100%',
                        }}></iframe>
                ) : (
                    <Typography>User can only access this site with a Provider Account!</Typography>
                )}
            </DialogContent>

            <DialogActions>
            </DialogActions>
            <InaccessibleConfirm
                open={Boolean(ssoLinkError)}
                onOk={() => {
                    handleClose()
                    setSsoLinkError(null)
                }}
            />
        </Dialog>
    )
}
