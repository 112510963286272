import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import StaffLoginForm from '../../components/Login/StaffLoginForm'
import * as actions from '../../store/actions/login'
import * as loaderActions from '../../store/actions/loader'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'

function StaffLogin(props) {
    const {
        staffLogin,
        error,
        isOTPRequired,
        verifyOTP,
        isLoading,
    } = props

    return (
        <div>
            <Header />
            <StaffLoginForm
                onSubmit={
                    !isOTPRequired ? staffLogin : verifyOTP
                }
                error={error}
                isOTPRequired={isOTPRequired}
                isLoading={isLoading}
            />
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loader.loading,
        error: state.loader.error,
        patientData: state.login.userData,
        isOTPRequired: state.login.isOTPRequired,
    }
}

const mapDispatchToProp = (dispatch) => {
    return {
        setError: (val) =>
            dispatch(loaderActions.setError(val)),
        staffLogin: (email, password) =>
            dispatch(actions.staffLogin(email, password)),
        verifyOTP: (otp) =>
            dispatch(actions.verifyOTP(otp)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProp,
)(StaffLogin)
