import { Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import Allergies from 'containers/PatientsSetting/view/Allergies'
import FamilyPsychiatricHistory from 'containers/PatientsSetting/view/FamilyPsychiatricHistory'
import { LabOrdersPatient } from 'containers/PatientsSetting/view/LabOrdersPatient'
import MedicalProblems from 'containers/PatientsSetting/view/MedicalProblems'
import MedicationPatient from 'containers/PatientsSetting/view/MedicationPatient'
import PsychiatricMedicationHistory from 'containers/PatientsSetting/view/PsychiatricMedicationHistory'
import SocialHistoryView from 'containers/PatientsSetting/view/SocialHistoryView'
import React, { useState } from 'react'
import LoadingPage from '../../../utilities/loading-page'

const useStyles = makeStyles({
    root: {
        padding: '16px 24px',
        flex: 1,
    },
    icon_back: {
        marginRight: 8,
    },
    header: {
        marginBottom: 16,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    no_results: {
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 6,
        margin: 'auto',
    },
})

const HealthyRecord = ({ onChangeActiveView, patientId, activeView, encounter_id, backToText }) => {
    const classes = useStyles()

    return (
        <Grid className={classes.root} wrap="nowrap" container direction="column">
            <Grid item container alignItems="center" style={{ marginBottom: 12 }}>
                <IconButton
                    onClick={() => onChangeActiveView('sessions')}
                    className={classes.icon_back}>
                    <KeyboardBackspaceOutlinedIcon style={{ fontSize: 16 }} />
                </IconButton>
                <Typography className={classes.back__btn}>
                    {backToText || 'Back to Clinical notes list'}
                </Typography>
            </Grid>
            {activeView === 'medication_allergy' && (
                <Allergies isInClinicalNote patient_Id={patientId} encounter_id={encounter_id} />
            )}

            {activeView === 'lab_order' && (
                <LabOrdersPatient
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                />
            )}

            {activeView === 'medication_problems' && (
                <MedicalProblems
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    isAddingFromClinicalNote
                />
            )}
            {activeView === 'psychiatric_medication_history' && (
                <PsychiatricMedicationHistory
                    isInClinicalNote
                    patientId={patientId}
                    encounter_id={encounter_id}
                />
            )}
            {activeView === 'family_psychiatric_history' && (
                <FamilyPsychiatricHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                />
            )}
            {activeView === 'social_history' && (
                <SocialHistoryView
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                />
            )}
            {activeView === 'medications' && (
                <MedicationPatient
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                />
            )}

            {/* {isLoading && <LoadingPage />} */}
        </Grid>
    )
}

export default HealthyRecord
