import { Grid, TextField, TextareaAutosize } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import TextBox from 'shared/elements/FormItem/TextBox'

const useStyles = makeStyles(() => ({
    fieldSet: {
        paddingBottom: "20px",
        width: "100%",
        border: '1px solid #666',
        borderRadius: "5px",
        marginBottom: "28px"
    },
    textAreaHeight: {
        '& .MuiOutlinedInput-root': {
            height: 'fit-content !important'
        }
    }
}))

function ReasonForVisit({formik, isAppointmentCompleted}) {

    const classes = useStyles()

    return (
        <Grid container spacing={2}>
            <fieldset className={classes.fieldSet} >
                <legend>Reason for visit</legend>
                <TextBox
                    className={classes.textAreaHeight}
                    name="reason_for_visit"
                    multiline
                    minRows={4}
                    value={formik.values.reason_for_visit}
                    onChange={(value) =>
                        formik.setFieldValue('reason_for_visit', value)
                    }
                    placeholder='Enter the reason for visit'
                    disabled={isAppointmentCompleted}
                />
            </fieldset>
        </Grid>
    )
}

export default ReasonForVisit
