/* eslint-disable default-case */
import {
    postSearchService,
    getSearchService,
    getService,
    postService,
    putService,
    deleteService,
    patchService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'
import { getLoggedInUserRole } from '../../utilities'
import { UserRoles } from '../../constants/user-roles'

export const getPatientByUserId = (userId) => {
    return getService(`${config.dev.baseURLV2}/patient/${userId}`)
}

export const getPatientListForUsers = (payload) => {
    const userRole = getLoggedInUserRole()
    const { filterString } = payload

    payload = {
        ...payload,
        name: payload.name?.replaceAll('/', '-'),
        limit: 10,
        start: 1,
    }
    delete payload.page

    const params = new URLSearchParams(payload)

    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin/tm2u-admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
    }
    return getService(
        `${config.dev.baseURLV2}/${userType}/patient?${params.toString()}`,
        payload,
    ).then((response) => {
        response.data.result = response.data.result.map((item) => {
            item.user_id = item.patientId
            return item
        })
        return response
    })
}

export const updatePatientStatus = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    const { userId, status } = payload

    return postService(`/${userType}/patient/change-status`, {
        user_id: userId,
        is_active: status,
    })
}

export const getPatientProfileById = (userId) => {
    return Promise.resolve({
        data: {},
    })
    // return getService(
    //     `${config.dev.baseURLV2}/patients/${userId}`,
    // )
}

export const getPatientList = (payload) => {
    const queryString = new URLSearchParams(payload).toString()
    const url = `${config.dev.baseURLV2}/patient?${queryString}`

    return getService(url)
}
export const OrderEntrySync = (id) => {
    let url = `${config.dev.baseURLV2}/patient/sync-data/${id}`
    return getService(url)
}
export const getDOBList = ({ page = 0, mm, dd, yy, totalFetched }) => {
    let url = `${config.dev.baseURLV2}/patient/dob-search?page=${page}`
    if (mm) url += `&mm=${mm}`
    if (dd) url += `&dd=${dd}`
    if (yy) url += `&yy=${yy}`
    if (totalFetched) url += `&totalFetched=${totalFetched}`
    return getService(url)
}
export const getPhoneNumberList = ({ page = 0, ph1, ph2, ph3 }) => {
    let url = `${config.dev.baseURLV2}/patient/ph-num-search?page=${page}`
    if (ph1) url += `&ph1=${ph1}`
    if (ph2) url += `&ph2=${ph2}`
    if (ph3) url += `&ph3=${ph3}`
    return getService(url)
}
export const getPatient = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}`
    return getService(url)
}


// https://api-dev.sonora.telemed2u.com/preview

export const createPatient = (payload) => {
    const url = `${config.dev.baseURLV2}/patient`
    return postService(url, payload)
}

export const searchPatientForProvider = (payload) =>
    postService('providers/patient/active-list', payload)

export const editPatientProfile = (payload) => {
    const userType = ''
    const userRole = getLoggedInUserRole()

    // switch (userRole) {
    //     case UserRoles.TM2U_ADMIN:
    //         userType = 'admin'
    //         break
    //     case UserRoles.TM2U_SUPER_ADMIN:
    //         userType = 'super-admin'
    //         break
    //     case UserRoles.CLINIC_CORDINATION:
    //         userType = 'clinic-admin'
    //         break
    // }
    // let personaUrl =
    //     userRole === UserRoles.PATIENT
    //         ? `${config.dev.baseURL}/patient/edit-profile`
    //         : `${config.dev.baseURL}/${userType}/patient/edit-profile`

    return putService(`${config.dev.baseURLV2}/patients/${payload.id}`, payload)
}

export const savePatientProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
    }

    return postService(`${config.dev.baseURLV2}/${userType}/patient`, payload)
}

export const selfHarmHistoryViolenceBehaviorPatient = (patientId, payload) => {
    return putService(`${config.dev.baseURLV2}/patient/${patientId}/flag`, payload)
}
export const setPrimaryCarePhysicianPatient = (patientId, payload) => {
    return putService(`${config.dev.baseURLV2}/patient/${patientId}/primary-care`, payload)
}

export const putPatient = (patientId, payload) => {
    return putService(`${config.dev.baseURLV2}/patient/${patientId}`, payload)
}

export const deactiveCariumAccess = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/deactive-carium-account/${patientId}`
    return postService(url)
} 
export const activeCariumAccess = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/create-carium/${patientId}`
    return postService(url)
} 

export const clinicalPracticeChange = (patientId, payload) =>{
    return postService(`${config.dev.baseURLV2}/patient/change-practice-carium/${patientId}`, payload)
}

/* Medical Problem apis */

export const getMedicalProblemList = (patientId, page = 1, pageSize = 10, bundleId = '') => {
    let url = `${config.dev.baseURLV2}/patient/${patientId}/condition?page=${page}&size=${pageSize}`
    if (bundleId) {
        url = `${config.dev.baseURLV2}/patient/${patientId}/condition?page=${page}&size=${pageSize}&bundle_id=${bundleId}`
    }

    return getService(url)
}

export const getBhList = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/bhscales`
    return getService(url)
}

export const deleteBhList = (patientId,id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/bhscales/${id}`
    return deleteService(url)
}
export const detailsOfBhScale = (patientId,id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/bhscales/${id}`
    return getService(url)
} 
export const getMedicalProblem = (patientId, conditionId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/condition/${conditionId}`
    return getService(url)
}

export const postMedicalProblem = (patientId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/condition`
    return postService(url, payload)
}

export const putMedicalProblem = (patientId, conditionId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/condition/${conditionId}`
    return putService(url, payload)
}

export const deleteMedicalProblem = (patientId, conditionId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/condition/${conditionId}`
    return deleteService(url)
}

/* Medications apis */
export const getListMedications = (patientId, page = 1, pageSize = 10) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication?page=${page}&size=${pageSize}`
    return getService(url)
}

export const sentErxUpatedTime = (patientId, time) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/sync_med_to_fhir?date=${time}`
    return getService(url)
}

export const getListMedicationsType = (type, patientId, page = 1, pageSize = 10, bundleId = '') => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication?type=${type}&page=${page}&size=${pageSize}&_bundle_id=${bundleId}`
    return getService(url)
}

export const getMedicationById = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication/${id}`
    return getService(url)
}
export const getMedicationSSOLink = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/erx/medical-sso-link`
    return getService(url)
}

export const getPrescriptionSSOLink = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/erx/prescription-sso-link`
    return getService(url)
}

export const getLabCHCLink = (patientId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/lab-orders`
    return postService(url)
}

export const createMedication = (patientId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication`
    return postService(url, payload)
}

export const updateMedication = (patientId, id, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication/${id}`
    return putService(url, payload)
}

export const deleteMedication = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/medication/${id}`
    return deleteService(url)
}

/* Medications apis */
export const getListImmunizations = (patientId, page = 1, pageSize = 10, bundleId = '') => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/immunization?page=${page}&size=${pageSize}&_bundle_id=${bundleId}`
    return getService(url)
}

export const getImmunizationById = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/immunization/${id}`
    return getService(url)
}

export const createImmunization = (patientId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/immunization`
    return postService(url, payload)
}

export const updateImmunization = (patientId, id, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/immunization/${id}`
    return putService(url, payload)
}

export const deleteImmunization = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/immunization/${id}`
    return deleteService(url)
}

export const getVaccines = (payload) => {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cvx/_search`,
        payload,
    )
}

/* Insurance apis */
export const getListInsurances = (patientId, page = 1, pageSize = 10) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/insurance?page=${page}&size=${pageSize}`
    return getService(url)
}

export const getInsuranceById = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/insurance/${id}`
    return getService(url)
}

export const createInsurance = (patientId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/insurance`
    return postService(url, payload)
}

export const updateInsurance = (patientId, id, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/insurance/${id}`
    return putService(url, payload)
}

export const deleteInsurance = (patientId, id) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/insurance/${id}`
    return deleteService(url)
}

/* Insurance Plan apis */
export const getListInsurancePlans = (page = 1, pageSize = 10) => {
    const url = `${config.dev.baseURLV2}/plan?page=${page}&size=${pageSize}`
    return getService(url)
}

export const getInsurancePlanById = (id) => {
    const url = `${config.dev.baseURLV2}/plan/${id}`
    return getService(url)
}

export const createInsurancePlan = (payload) => {
    const url = `${config.dev.baseURLV2}/plan`
    return postService(url, payload)
}

export const updateInsurancePlan = (id, payload) => {
    const url = `${config.dev.baseURLV2}/plan/${id}`
    return putService(url, payload)
}

export const deleteInsurancePlan = (id) => {
    const url = `${config.dev.baseURLV2}/plan/${id}`
    return deleteService(url)
}

/* Allergies apis */
export const getListAllergies = (patientId, page = 1, pageSize = 10, bundleId = '') => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/allergy?page=${page}&size=${pageSize}&_bundle_id=${bundleId}`
    return getService(url)
}

export const getAllergyById = (patientId, allergyId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/allergy/${allergyId}`
    return getService(url)
}

export const postAllergy = (patientId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/allergy`
    return postService(url, payload)
}

export const putAllergy = (patientId, allergyId, payload) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/allergy/${allergyId}`
    return putService(url, payload)
}

export const deleteAllergy = (patientId, allergyId) => {
    const url = `${config.dev.baseURLV2}/patient/${patientId}/allergy/${allergyId}`
    return deleteService(url)
}

/* Appointments apis */
export const getAppointmentsByPatientId = (patientId, { page = 1, pageSize = 10, date } = {}) => {
    let url = `${process.env.REACT_APP_SONORA_V2_API_URL}/appointment?patient=${patientId}&page=${page}&size=${pageSize}`
    if (date) url += `&date=${date}`

    return getService(url)
}

/* Patient utilities apis - valueset apis */

export const getICD10Code = (payload) => {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_icd10/_search`,
        payload,
    )
}

export const getOccupationCode = (payload) => {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_occupation/_search`,
        payload,
    )
}

export const getOccupationIndustryCode = (payload) => {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_occupation_industry/_search`,
        payload,
    )
}

export const getEncountersByPatientId = (payload) => {
    const params = new URLSearchParams(payload)
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/encounter?${params}`)
}

export const getEncountersById = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/telehealth/encounter/${id}`)
}

export const searchCode = (codeName) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/valueset?name=${codeName}`)
}

export const getAllergiesReaction = () => {
    return getSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_reaction/_search?size=1000&filter_path=hits.hits._source`,
    )
}

export const getAllergiesSeverity = () => {
    return getSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_severity/_search?size=1000&filter_path=hits.hits._source`,
    )
}

/**
 * This method intended to search drug allergies, begining with searchString.
 * Maximum 200 results are returned from the search
 * @param {string} searchString
 * @returns DrugAllery[]
 */
export const searchAllergen = (searchString) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/drug/allergy?search_string=${searchString}`,
    )
}

export const searchDrug = (searchString) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/drug?search_string=${searchString}`,
    )
}

export const apiFamilyPsychiatric = {
    create(patienrId, params) {
        return postService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/family-medical-history`,
            params,
        )
    },
    getAll(patienrId, payload) {
        const queryString = new URLSearchParams({
            ...payload,
        }).toString()
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/family-medical-history?${queryString}`,
        )
    },
    getId(patientId, familyMedicalId) {
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`,
        )
    },
    update(patientId, familyMedicalId, payload) {
        return patchService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`,
            payload,
        )
    },
    delete(patientId, familyMedicalId) {
        return deleteService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`,
        )
    },
}
export const getPatientAlert = (payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    // const url = `https://1652p9n9id.execute-api.us-east-1.amazonaws.com/dev/patient-alert?${queryString}`
    const url = `${process.env.REACT_APP_SONORA_V2_API_URL}/patient-alert?${queryString}`
    return getService(url)
}

export const createPatientAlert = (payload) => {
    // const url = `https://1652p9n9id.execute-api.us-east-1.amazonaws.com/dev/patient-alert`
    const url = `${process.env.REACT_APP_SONORA_V2_API_URL}/patient-alert`
    return postService(url, payload)
}

export const getPatientNextAppoinment = (id) => {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/recall?patient_id=${id}`)
}

export const getPatientNextAppointmentInWaitlist = (id) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient-next-appointment?patient_id=${id}`,
    )
}

export const drugAllergyStatus = (patientId, payload) => {
    return putService(`${config.dev.baseURLV2}/patient/${patientId}/flag`, payload)
}

export const deletePatientAlert = (id) => {
    // const url = `https://1652p9n9id.execute-api.us-east-1.amazonaws.com/dev/patient-alert`
    const url = `${process.env.REACT_APP_SONORA_V2_API_URL}/patient-alert/${id}`
    return deleteService(url)
}

export const sendCustomEvent = (payload) => {
    const url = `${config.dev.baseURLV2}/events/custom/save`
    return postService(url, payload)
}

//outcome list
export const allOutComeList = (payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    const url = `${config.dev.baseURLV2}/outcome/list?${queryString}`
    return getService(url)
}

export const allSystemNotification = (payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    const url = `${config.dev.baseURLV2}/system-announcement/get-announcement-list?${queryString}`
    return getService(url)
}
export const getOneNotification= (payload)=>{
    const url = `${config.dev.baseURLV2}/system-announcement/${payload}`
    return getService(url)
}
export const deleteSysNotification= (id)=>{
    const url = `${config.dev.baseURLV2}/system-announcement/${id}`
    return deleteService(url)
}
export const postSystemNotification = (payload) => {
    const url = `${config.dev.baseURLV2}/system-announcement`
    return postService(url,payload)
}
export const postEditSystemNotification=(payload,id)=>{
    const url = `${config.dev.baseURLV2}/system-announcement/${id}`
    return putService(url, payload)
}
export const createOutcomes = (payload) => {
    const url = `${config.dev.baseURLV2}/outcome/create`
    return postService(url, payload)
}
export const updateOutcomes = (payload) => {
    const url = `${config.dev.baseURLV2}/outcome/update`
    return putService(url, payload)
}
export const deleteOutcome = (id) => {
    const url = `${config.dev.baseURLV2}/outcome/delete?id=${id}`
    return deleteService(url)
}

export const getFaxDocuments = (patient_id) => {
    return getService(`${config.dev.baseURLV2}/patient/${patient_id}/fax-documents`)
}

export const previewFaxData = (payload) => {
    const options = payload?.is_send_fax ? {} : {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    }
    return postService(
        `${config.dev.baseURLV2}/patient/preview-fax`, 
        payload, 
        {...options}
    )
}
