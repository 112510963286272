import {
    Grid,
    Typography,
    Button,
    Box,
    FormControl,
    TextField,
    InputLabel,
    FormLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import Swal from 'sweetalert2'
import queryString from 'querystring'
import { Auth } from 'aws-amplify'
import LoadingButton from '@mui/lab/LoadingButton'
import * as yup from 'yup'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tms2uLogo from '../../assests/icons/Tms2uLogo'
import shutterstock from '../../assests/images/shutterstock_13160805981.png'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import TickCircle from '../../assests/icons/TickCircle'
import { Formik, useFormik } from 'formik'
import IconPassword from '../../assests/images/iconPassword.png'
import IconDisablePassword from '../../assests/images/iconDisablePassword.png'
import './style.css'
import SpinnerIcon from 'assests/icons/SpinnerIcon'
import { useDispatch, useSelector } from 'react-redux'
import { setForceToChangePassword, staffLogin } from 'store/actions/login'
import { PasswordValidator } from 'utilities/regex'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${shutterstock})`,
        backgroundSize: 'cover',
    },
    title: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '140%',
        marginTop: 5,
    },
    selected: {
        border: '1px solid #5571C6',
    },
    otpInput: {
        marginTop: 32,
    },
    InputLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
    },
    passwordSuccess: {
      color: '#52b202'
    },
    passwordError: {
      color: 'red'
    }
}))

export default function VerifyEmail() {
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()

    const forceToChangePassword = useSelector((state) => state.login.forceToChangePassword);

    const mfa = useSelector((state) => state.login.mfa);
    const error = useSelector((state) => state.loader.error);
    const params = queryString.parse(
      history?.location?.search?.replace('?', ''),
    )

    const email = params.email;
    const code = params.code;

    useEffect(() => {
      if (email && code) {
        dispatch(staffLogin(email, code))
      }
    }, [email, code])

    if (forceToChangePassword) {
      history.push('/change-password')
      return <></>
    }

    if (mfa) {
        history.push('/mfa-signin')
        return <></>
    }


    if (!email || !code) {
      history.push('/')
      return <></>;
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ minHeight: '100vh' }}>
            <Box container direction="column">
              {
                error && (
                  <Grid>
                    <Typography
                      style={{
                        fontSize: '40px'
                      }}
                    >
                      This link has been expired.
                    </Typography>
                  </Grid>
                )
              }
            </Box>
        </Grid>
    )
}
