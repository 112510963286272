import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Typography,
} from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import React from 'react'
import { useStyles } from './styles'
import Select from '@mui/material/Select'

function SelectEl(props) {
    const classes = useStyles(props)

    return (
        <FormControl
            disabled={props.disabled}
            fullWidth
            variant="outlined">
            {props.label && (
                <InputLabel>{props.label}</InputLabel>
            )}
            <Select
                fullWidth
                displayEmpty
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                input={
                    <OutlinedInput
                        margin="dense"
                        classes={classes}
                    />
                }
                classes={{
                    root: classes.root,
                    icon: classes.icon,
                }}
                style={{
                    height: props.height,
                    padding: 0,
                    backgroundColor: '#FFF',
                    ...props.style,
                }}
                IconComponent={KeyboardArrowDown}
                {...props}>
                {props.options.map((option, index) => (
                    <MenuItem
                        disabled={option?.disabled}
                        key={option?.id || option.key}
                        value={option.key}
                        style={{
                            background:
                                option.isBusy && '#b7b7b7 ',
                            cursor: option.isBusy
                                ? 'unset'
                                : 'pointer',
                            opacity:
                                option.isBusy ||
                                option?.disabled
                                    ? 0.5
                                    : 1,
                            pointerEvents:
                                option.isBusy && 'none',
                        }}>
                        <Typography
                            variant="body2"
                            noWrap
                            className={classes.text}>
                            {option.value}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

SelectEl.defaultProps = {
    border: '1px solid $secondaryGray',
    height: 40,
}

export default SelectEl
