import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'

import { deleteAllergy, getListAllergies } from 'services/Patient/patient'
import { sx, usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { formatDateTime } from 'utilities/formats'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'

import { Allergy, ErrorType } from '../../interfaces'
import { DataGridCell } from '../../shared'
import { DataGrid } from 'shared/elements/DataGrid'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { stringConcatenation } from 'shared/utils'

interface Props {
    cycleCount: number
    patient_Id: string
    onClickEdit: (allergyId: string) => void
    afterActionOk?: () => void
    activeAllergyCheck?: (data) => void,
    flag: boolean
    nonEditable:Boolean
}

export const CurrentAllergyList = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id } = useParams<{ id: string }>()
    const notification = useNotification()
    const [patientId, setPaitentId] = useState('')
    // const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [allergies, setListAllergies] = useState<Allergy[]>([])
    const [bundleId, setBundleId] = useState('')
    const [flag, setFlag] = useState(false)
    const [nkdaData, setnkdaData] = useState<any[]>([{
        "id": "",
        "allergy": {
            "text": "",
            "coding": [

            ],
            "id": "",
            "brandName": "NKDA",
            "genericName": "",
            "strength": "",
            "form": ""
        },
        "status": {
            "code": "active"
        },
        "patient_id": "",
        "reaction": {
            "code": "",
            "display": ""
        },
        "external_id": "",
        "rcopia_id": "",
        "severity": "",
        "encounter_id": ""
    }])

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'type',
            sort: 'desc',
        },
    ])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     page: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        setPaitentId(props?.patient_Id || id)
    }, [props.patient_Id, id])


    const columns: GridColumns<Allergy> = [
        {
            headerName: 'Allergy Type',
            headerClassName: 'super-app-theme--header',
            field: 'allergy',
            flex: 1.5,
            sortable: true,
            valueGetter: ({ row }) => row.allergy?.text,
            sortComparator: (p: string, q: string) => p?.localeCompare(q),
            renderCell: ({ row }) => {
                const value = {
                    brandName: row.allergy.brandName || '',
                    genericName: row.allergy.genericName || '',
                    strength: row.allergy.strength || '',
                    form: row.allergy.form || '',
                }
                return (
                    <HtmlTooltip
                        // title={`${value.brandName}, ${value.genericName}, ${value.strength}, ${value.form}`}
                        title={stringConcatenation(
                            value.brandName,
                            value.genericName,
                            value.strength,
                            value.form,
                        )}
                        arrow
                        placement="top">
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {stringConcatenation(
                                value.brandName,
                                value.genericName,
                                value.strength,
                                value.form,
                            )}
                        </Typography>
                    </HtmlTooltip>
                )
            },
        },
        {
            headerName: 'Reaction',
            headerClassName: 'super-app-theme--header',
            field: 'reaction',
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.reaction?.display,
            sortComparator: (p: string, q: string) => p?.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={row.reaction?.display} />
            },
        },
        {
            headerName: 'NDC-ID',
            headerClassName: 'super-app-theme--header',
            field: 'ndcId',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) =>
                row?.allergy?.coding?.find((item) => item.display === 'NCDID')?.code, // FIXME:
            sortComparator: (p: string, q: string) => p?.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    <DataGridCell
                        value={row?.allergy?.coding?.find((item) => item.display === 'NCDID')?.code}
                    />
                ) // FIXME:
            },
        },
        {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.status?.code || row.status?.display || '',
            sortComparator: (p: string, q: string) => p?.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    <Typography className={classes[`tag_type_${row.status?.code}`]}>
                        {row.status?.code || row.status?.display}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Notes',
            headerClassName: 'super-app-theme--header',
            field: 'note',
            // width: 100,
            flex: 1.5,
            // sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value} />
            },
        },
        {
            headerName: 'Updated',
            headerClassName: 'super-app-theme--header',
            field: 'time_onset',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.onset_date || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    <DataGridCell
                        value={row.onset_date && formatDateTime(row.onset_date, 'MMM DD, YYYY')}
                    />
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !props.nonEditable
                    ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.flag ? notification('You cannot perform this action', "error") : props.onClickEdit(params.id.toString()),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.flag ? notification('You cannot perform this action', "error") : handleDelete(patientId, params.id.toString()),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this allergy?"
                    />
                    :
                    ""
                ),
        },
    ]

    useEffect(() => {
        const abortController = new AbortController()
        if (!props.flag) {
            if (patientId) {
                fetchListAllergies()
            }
        } else {
            flagSetter()
        }
        return () => abortController.abort()
    }, [page, patientId, props.flag])

    useEffect(() => {
        const abortController = new AbortController()
        if (props.cycleCount > 0 && !props.flag) {
            // setTimeout(() => fetchListAllergies(), 1000)
            fetchListAllergies()
        } else {
            flagSetter()
        }

        return () => abortController.abort()
    }, [props.cycleCount])

    useEffect(() => {
        if (props.flag)
            flagSetter()

    }, [props.flag])

    const handleDelete = async (patientId: string, allergyId: string) => {
        try {
            await deleteAllergy(patientId, allergyId)

            notification(`Allergy Deleted Successfully!`, 'success')

            fetchListAllergies()
            props.afterActionOk?.()
        } catch (error) {
            const errMsg =
                (error as ErrorType)?.data?.message || `Something went wrong when deleting allergy!`

            notification(errMsg, 'error')
        }
    }

    const fetchListAllergies = async () => {
        setLoading(true)

        try {
            const resp = await getListAllergies(patientId, page, limit, bundleId)
            if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)

            setListAllergies(resp.data.data as Allergy[])
            setTotal(resp.data.paging.total)
            activeAllergyCheck(resp.data.data.length)

        } catch (ex) {
            notification(`Cannot get list allergies!`, 'error')
        }

        setLoading(false)
    }
    const activeAllergyCheck = (data) => {
        props?.activeAllergyCheck(data)
    }

    const flagSetter = () => {
        if (props.flag) {
            setListAllergies([...nkdaData])
            setTotal(1)
            props.afterActionOk()
        }

    }

    return (
        <>
            <Grid item xs={12}>
                <DataGrid
                    getRowId={(row) => row.id}
                    sx={sx.datagrid}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    rows={allergies}
                    columns={columns}
                    pageSize={limit}
                    // rowsPerPageOptions={[10]}
                    paginationMode="server"
                    disableColumnSelector={true}
                    disableSelectionOnClick
                    loading={loading}
                    rowCount={total}
                    pagination
                    disableColumnMenu={true}
                    page={page}
                    onPageChange={(page) => setPage(page)}
                // onRowClick={handleRowClick}
                />
            </Grid>
        </>
    )
}
