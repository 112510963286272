import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { getVisitById } from 'services/Calendar'
import { ModalEl } from 'shared/elements'

import { INITIAL_VALUES } from './schema'
import { FormContent } from './FormContent'
import { CPTCode, VisitType } from '../interfaces'
import { Footer } from 'shared/elements/FormItem/Footer'

import './styles.scss'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
})

interface Props {
    loading: boolean
    visitTypeId?: number | null
    open: boolean
    onModalClose: () => void
    onSubmit: (value: VisitType) => void
}

export const VisitTypesCreateDialog = ({ loading, ...props }: Props) => {
    const classes = useStyles()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [cptCodeErrors, setCptCodeErrors] = useState({})
    const [visitTypeTouched, setVisitTypeTouched] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (props.visitTypeId) {
            getVisitTypeData(props.visitTypeId)
        }

        return () => abortController.abort()
    }, [props.visitTypeId])

    const getVisitTypeData = async (visitTypeId: number) => {
        try {
            setDataLoading(true)
            const response = await getVisitById(visitTypeId)

            if (response?.data) {
                const {
                    post_code: postCode,
                    note_template_id: noteTemplateId,
                    comment,
                    cpt_arr: cptArr,
                    duration,
                    type: visitType,
                    specialty_id,
                    color_tag,
                } = response?.data

                setInitData({
                    ...INITIAL_VALUES,
                    postCode,
                    noteTemplateId,
                    comment,
                    cptArr,
                    color_tag: color_tag || INITIAL_VALUES.color_tag,
                    duration,
                    specialty_id,
                    visitType,
                })
            }

            setDataLoading(false)
        } catch (error) {
            console.log({ error })
            setDataLoading(false)
        }
    }

    const validationSchema = yup.object({
        specialty_id: yup.string().required('Specialty is required!'),
        postCode: yup
            .string()
            .min(5, 'Code only accept 5 characters!')
            .required('Post code is required!'),
        duration: yup
            .number()
            .max(999, 'Maximum 3 characters')
            .positive('Duration can not be 0!')
            .required('Duration is required!'),
        noteTemplateId: yup.string().required('Note Template ID is required!'),
        // cpt_arr: yup
        //     .array()
        //     .of(
        //         yup.object().shape({
        //             code: yup.string().required('CPT Code is required!'),
        //             description: yup.string()
        //         })
        //     )
        //     .required()
    })

    const validate = (cptArr: CPTCode[]) => {
        type Accumulator = {
            [index: string]: string
        }

        const preservedCptCodes = {} as Accumulator

        const err = cptArr.reduce((acc, { code, description }, i) => {
            if (!code.length) {
                acc[i as keyof Accumulator] = 'CPT Code is required!'
            } else if (preservedCptCodes[code] && preservedCptCodes[code] === description) {
                acc[i] = `Duplicated CPT code "${code}"!`
            }

            preservedCptCodes[code] = description || ''
            return acc
        }, {} as Accumulator)

        setCptCodeErrors(err)
        return Object.keys(err).length
    }

    const handleSubmit = (values) => {
        const {
            postCode: post_code,
            noteTemplateId: note_template_id,
            comment,
            cptArr: cpt_arr,
            duration,
            visitType: type,
            specialty_id,
            color_tag,
        } = values

        if (validate(values.cptArr)) return

        props.onSubmit({
            post_code,
            // long_code,
            color_tag,
            note_template_id,
            specialty_id,
            comment,
            // keyword,
            cpt_arr: JSON.stringify(cpt_arr),
            duration,
            type,
        })
        // setErrors({})
    }

    const formik = useFormik({
        initialValues: initData,
        validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    return (
        <ModalEl
            title={props.visitTypeId ? 'Update Visit Type' : 'Add New Visit Type'}
            description={'Booking Appointment Details'}
            open={props.open}
            onClose={() => props.onModalClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <div id="visit-type-settings">
                        <FormContent
                            visitTypeTouched={visitTypeTouched}
                            setVisitTypeTouched={setVisitTypeTouched}
                            cptCodeErrors={cptCodeErrors}
                            formik={formik}
                            setFieldValue={(field, data, initState) => {
                                formik.setFieldValue(field, data)
                                if (!initState && field === 'cptArr') validate(data)
                            }}
                        />

                        <Grid container item className="modal-spacer">
                            <Grid item xs={12}>
                                <Footer
                                    loading={loading}
                                    onCancel={props.onModalClose}
                                    onOk={() => {
                                        setVisitTypeTouched(!formik.values.visitType)
                                        formik.handleSubmit()
                                        validate(formik.values.cptArr)
                                    }}
                                    okBtnText={props.visitTypeId ? 'Update' : 'Save'}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </ModalEl>
    )
}
