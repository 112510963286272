import React, { useCallback, memo, useState, useEffect } from 'react'
import { FormLabel, TextField, TextFieldProps } from '@material-ui/core'
import { debounce } from 'lodash'
import { Box } from '@mui/material'

interface Props {
  label: string
  required?: boolean
  onChange?: (value: string) => void
}

const convertTax = (text: string) => {
  const [, part1 = '', part2 = ''] = /(\d{1,2})-*(\d{0,7})/.exec(text) || []
  let newValue = part1
  if (part2.length) newValue += '-' + part2
  return newValue 
}

export const Tax = memo(
  ({ label, required, ...props }: TextFieldProps & Props) => {
    const [text, setText] = useState(props.value as string)

    const dispatchChangeEvent = debounce((value: string) =>
      props.onChange && props.onChange(value), 0)

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      const value = props.value as string
      let text = e.target.value

      if (text.length > 11) return  /* + 2 dashes */
      if (text.length > value.length
        && (text[text.length - 1] < '0' || text[text.length - 1] > '9')) return

      const newValue = convertTax(text)

      setText(newValue)

      if (!newValue.length || newValue.length === 11) // Length of tax string
        dispatchChangeEvent(newValue)
    }, [props.value])

    useEffect(() => {
      const preservedValue = props.value as string
      if (preservedValue !== text) {
        setText(convertTax(preservedValue))
      }
    }, [props.value])

    return (
      <>
        <FormLabel
          component="p"
          className="input-form-label"
          style={{ fontSize: 14, fontWeight: 500 }}
        >
          <span
            className={!!required ? 'required' : ''}>
            {label || 'Tax ID'}
          </span>
        </FormLabel>

        <Box>
          <TextField
            variant="outlined"
            style={{ height: 'fit-content' }}
            fullWidth
            {...props}
            placeholder="00-0000000"
            value={text || ''}
            onChange={handleChange}
            error={true}
            helperText={props.helperText}
            onBlur={() => dispatchChangeEvent(text)}
          />
        </Box>
      </>
    )
  }
)
