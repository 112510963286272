import { Box, Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info'
import clsx from 'clsx'
import { capitalize, startCase, toLower, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import EncounterQueue from '../../../containers/CalendarScheduler/EncounterStack/EncounterQueue'
import { useDispatch, useSelector } from 'react-redux'
import AppointmentBooking from 'containers/AppointmentBooking'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import { createSchedule } from 'services/Calendar'
import ErxDialog from 'containers/PatientsSetting/view/MedicationPatient/ErxDialog'
import HealthyRecordTab from './HealthyRecordTab'
import { useNotification } from 'shared/elements/Notification'
import SchedulerMenu from 'containers/SchedulerMenu'
import { useTimezone } from 'shared/hooks/useTimezone'
import { changeAppointment } from 'store/actions/schedule'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { getListMedicationsType, sentErxUpatedTime } from 'services/Patient/patient';
import Confirm from 'components/shared/Elements/Confirm';
import { handleOpenSaveAndCloseDialog, handleVisibleSaveAndClose, setAllowError, updatingMedicationList } from 'store/actions/clinicalNotes'
import { dateFormat, stringConcatenation } from 'shared/utils'
import ClinicalNoteSave from '../../../assests/icons/clinicalNoteSave.svg'
import { LoadingButton } from '@mui/lab';
import { getLoggedInUserId, getLoggedInUserName } from 'utilities'
import { UserRoles } from 'constants/user-roles';
import ReportIcon from '@mui/icons-material/Report'
import { ENCOUNTER_QUEUE_PATIENT_STATUS, ENCOUNTER_STATUS, PATIENT_STATUS } from 'containers/CalendarScheduler/constant';
import { examAllowPatientJoinApi } from 'services/Provider/provider';
import { handleChangeEncounterStatus, handleGetEncounterQueueList, saveObjectEncouterStart, setEncounterMeetingId, setEncounterRoom, setSelectedEncounter } from 'store/actions/provider';
import { SET_ENCOUNTER, SET_TELEHEALTH_SCREEN } from 'store/actionType';
import { TAB_CLINICALNOTE } from 'constants/menu';
import Routes from 'constants/routes';
import { useHistory } from 'react-router-dom';

const ButtonHK = styled(Button)({
    height: 40,
    borderRadius: 8,
    borderColor: '#5571C6 !important',
    color: '#5571C6',
    // background: 'white',
    backgroundColor: 'white',
    fontSize: 15,
    fontWeight: '400',
    marginTop: 16,
    textTransform: 'unset',
    // '&:hover': {
    //     backgroundColor: '#0069d9',
    //     borderColor: '#0062cc',
    //     boxShadow: 'none',
    // },
    // '&:active': {
    //     boxShadow: 'none',
    //     backgroundColor: '#0062cc',
    //     borderColor: '#005cbf',
    // },
    // '&:focus': {
    //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
})

const useStyles = makeStyles({
    headerGridContainerEq: {
        width: 0,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        borderLeft: '1px solid #e8e8e8',
        transition: 'width 0.5s',
    },
    headerGridContainer: {
        width: 320,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        borderLeft: '1px solid #e8e8e8',
        transition: 'width 0.5s',
    },
    width0: {
        width: 0,
    },
    appLogo: {
        width: 150,
        height: 66,

        '@media (max-width: 1500px)': {
            width: '70%',
            height: 'auto',
        },
    },
    typo_line: {
        color: '#9CA9B8',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_tab: {
        // color: '#5571c6',
        color: '#303E4E',
        cursor: 'pointer',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        fontWeight: 500,
        padding: '6px 0',
        marginBottom: '8px',
        borderBottom: '1px solid #F3F6F9',
    },
    typo_line_gray: {
        color: '#303E4E',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_cover: {
        borderBottom: '1px solid #e8e8e8',
        margin: '8px 0',
    },
    typo_line_cover_tab: {
        margin: '8px 0',
    },
    separator: {
        borderLeft: '2px solid #ffffff',
        height: 55,
        width: 2,
        boxSizing: 'border-box',
        marginRight: 10,
        marginLeft: 10,
    },
    leftBorder: {
        borderLeft: '2px solid #ffffff',
        paddingLeft: 10,
    },
    dFlex: {
        display: 'flex',
    },
    oveflowDegree: {
        width: '235px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textTransformCapital: {
        textTransform: 'uppercase',
    },
    avatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
        marginTop: 16,
        background: 'gray',
        marginBottom: 16,
    },
    provider_tag: {
        padding: '4px 16px',
        background: '#3E465B',
        borderRadius: 5,
        color: 'white',
        margin: '12px 0',
    },
    cover_info: {
        padding: 16,
        marginTop: 8,
        borderTop: '1px solid #e8e8e8',
    },
    show_header_btn: {
        position: 'absolute !important',
        top: 10,
        left: -54,
    },
    show_save_btn: {
        position: 'absolute !important',
        color: "#FFF !important",
        borderRadius: "5px",
        width: "70px",
        height: "29px",
        fontWeight: 'normal',
        border: 'none',
        background: '#5571c6 !important'
    },
    hidden_cover: {
        // overflow: 'hidden',
        overflowY: 'scroll',
        overflowX: 'hidden',
        // height: 'calc(100vh - 90px)',
    },
    encounterQueue: {
        borderTop: '5px solid #E9ECF1',
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 310px)',
        height: 'fit-content',
    },
    btn_show_more: {
        cursor: 'pointer',
        color: '#5571C6',
        textAlign: 'center',
        marginTop: 16,
    },
    scroll_content: {
        maxHeight: "350px",
        overflow: "auto"
    }
})

const Header = ({
    isConsultant,
    noteDetails,
    activeView,
    providerDetail,
    patientDetails,
    isAppointmentDetails,
    clinicalNoteData,
    onChangeActiveView,
    appointmentDetails,
    handleSetViewHealthyRecord,
    onChange,
    activeNote,
    saveClinicalNote,
    loading,
    sendCustomEvent,
    isClinicalNoteNotPresesnt
}) => {

    const [openERX, setOpenERX] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [editTime, setEditTime] = useState('')
    const [isLoadingButton, setIsLoadingButton] = useState(false)
    const { patientName, gender, dob, chartId } = noteDetails || {}
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState({ id: null, status: false })
    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [activeTab, setActiveTab] = useState(1)

    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    const { saveAndCloseButtonVisible, saveAndCloseDialog, currentEncounter } = useSelector((state) => state.clinicalNotes)
    // const { locations, visitTypes, dataLoading } = useAppointmentBookingFormData(
    //     clinicalNoteData?.provider_id,
    // )
    const localState = JSON.parse(localStorage.getItem('calendar_state'))
    const timezone = useTimezone()
    const dispatch = useDispatch()
    const [EqStatus, setEqStatus] = React.useState(true)
    const history = useHistory()

    const {
        id: patientId,
        last_name: patient_last_name,
        first_name: patient_first_name,
        dob: patient_dob,
        preferred_name: patient_preferred_name
    } = clinicalNoteData?.patient
    const {
        appointment_date,
        appointment_start,
        appointment_end,
        appointment_duration,
        insurance_company,
        insurance_plan_name,
    } = clinicalNoteData
    const providerN = localStorage.getItem('name')

    const { first_name, last_name, education } = providerDetail || {}
    const [showHeader, setShowHeader] = useState(!isConsultant)
    const [isShowMore, setIsShowMore] = useState(!isConsultant)
    const [isShowMoreInfo, setIsShowMoreInfo] = useState(true)
    const [saveCount, setSaveCount] = useState(0)
    const providerEducation = education && education[0] ? education[0]?.education : ''
    const {
        first_name: patientFristName,
        last_name: patientLastName,
        dob: patientDob,
    } = patientDetails || {}

    const [eqStartLoading, setEqStartLoading] = useState(false)

    const classes = useStyles()
    const vLoggedInUserId = getLoggedInUserId()
    const vLoggedInUserName = getLoggedInUserName()

    const openConsultationPage = (item) => {
        let method = (id) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({})
                }, 800)
            })
        }

        if (
            item.status === ENCOUNTER_STATUS.EXAM_WAITING &&
            item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM
        ) {
            method = examAllowPatientJoinApi
        }

        setEqStartLoading(true)
        dispatch(setSelectedEncounter(item))
        dispatch(setEncounterRoom(null))

        method(item.encounter_id)
            .then(() => {
                dispatch(setEncounterMeetingId(item.encounter_id))
                dispatch(handleGetEncounterQueueList())
                dispatch({
                    type: SET_TELEHEALTH_SCREEN,
                    active: true,
                    tabName: TAB_CLINICALNOTE,
                })
                // history.push('/consultation')
                if (item?.clinical_note_id !== '') history.push('/consultation')
                else history.push('/schedule')
            })
            .catch((error) => {
                dispatch(setAllowError(true))
            })
            .finally(() => {
                setEqStartLoading(false)
                // dispatch(setSelectedEncounter(null))
            })
    }

    const openConsultationPageWithoutVideoCall = (item) => {
        try {
            setEqStartLoading(true)
            dispatch(setSelectedEncounter(item))
            dispatch(saveObjectEncouterStart(item))
            dispatch(setEncounterMeetingId(item.encounter_id))
            const payload = {
                id: item.encounter_id,
                status: ENCOUNTER_STATUS.EXAM,
                patient_status: PATIENT_STATUS.EXAM,
            }
            dispatch(handleChangeEncounterStatus(payload))
        } catch (error) {
            dispatch(setAllowError(true))
        } finally {
            setEqStartLoading(false)
            if (item?.clinical_note_id !== '') {
                history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, {
                    id: item?.clinical_note_id,
                })
            } else history.push(Routes.SCHEDULE)
        }
    }

    const handleStartEncounter = (encounter) => {
        if (isEmpty(encounter)) return

        if (encounter.appointment_type !== 'Office Visit') {
            dispatch({
                type: SET_ENCOUNTER,
                encounter_id: encounter.encounter_id,
            })
            openConsultationPage(encounter)

            return
        }

        dispatch({
            type: SET_ENCOUNTER,
            encounter_id: encounter.encounter_id,
        })
        openConsultationPageWithoutVideoCall(encounter)
    }

    const buildAndSendCustomEvent = async () => {
        const payload = {
            "seTrigger": "CN_SAVE_BTN",
            "seItem": "clinicalNote",
            "seItemId": clinicalNoteData?.id,
            "sePatientId": patientId,
            "seApptId": clinicalNoteData?.appointment_id,
            "seClinicalNoteSection": activeNote?.header,
            "seEventTime": moment().format(),
            "seSaveCount": saveCount,
            "seUserId": vLoggedInUserId,
            "seUserName": vLoggedInUserName?.trim()
        }
        sendCustomEvent(payload)
    }

    useEffect(() => {
        if (saveCount > 0) {
            buildAndSendCustomEvent()
        }
    }, [saveCount])

    const encounterQueueList = useSelector((state) => state.provider.encounterQueueList)
    const openListConsultation = useSelector((state) => state.provider?.openListConsultation)
    const openListProvider = useSelector((state) => state.provider.openListProvider)
    const userData = useSelector((state) => state.permission.erx)
    const userlogin = useSelector((state) => state?.login?.userData)
    const is_enable_eprescribe = useSelector((state) => state?.login?.userData?.practitioner?.is_enable_eprescribe)
    const checkEditOrNot = () =>{
        if(userlogin?.role_code === "medical_assistant"){
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(clinicalNoteData.status) ||
            noteDetails?.name === 'view' ||
            (clinicalNoteData?.in_edit) ||
            (clinicalNoteData.status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
            (isClinicalNoteNotPresesnt === true)
            return editOrNot 
        }
            const editOrNot = [
                'pending',
                'locked',
                'approved_lock',
                'Pending Approval',
                'Approved & Locked',
                'LOCKED',
            ].includes(clinicalNoteData.status) ||
                noteDetails?.name === 'view' ||
                !(clinicalNoteData?.isEditable) ||
                (clinicalNoteData?.in_edit) ||
                (clinicalNoteData.status === 'resent_to_edit' && role === UserRoles.PROVIDER) ||
                (isClinicalNoteNotPresesnt === true) 
            return editOrNot
    }
    const nonEditable = checkEditOrNot()

    const notification = useNotification()

    const handleShowHeader = () => {
        setShowHeader(!showHeader)
    }
    const handleToggleCollapseInfo = () => {
        setIsShowMore(!isShowMore)
    }

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            await createSchedule(values)

            setIsSubmitting(false)
            setAppointmentModal({ id: null, status: false })
            notification('An appointment was successfully created!', 'success')
        } catch (error) {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'An appointment was not successfully created!',
                'error',
            )
        }
    }

    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: clinicalNoteData.patient.id,
                        practitioner_id: clinicalNoteData.provider_id,
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                    },
                }),
            )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }
    const statusOfMedicationList = (lastWrittenDate = null, stopDate = null, isPrescribe = false) => {
        if (stopDate && dateFormat(stopDate).isSame(moment(noteDetails?.service_date), 'day')) {
            return 'Discontinue'
        } else if (isPrescribe && lastWrittenDate) {
            if (dateFormat(lastWrittenDate).isSameOrAfter(moment(noteDetails?.service_date), 'day')) {
                return 'New Order'
            } else if (dateFormat(lastWrittenDate).isBefore(moment(noteDetails?.service_date), 'day')) {
                return 'Continue'
            }
        } else if ((!isPrescribe || !lastWrittenDate)) {
            return 'Continue'
        }
    }
    const convert = (data) => {
        return {
            //----Addded SOI-370->Start----//
            drug_name: stringConcatenation(
                data.drug.brandName,
                data.drug.genericName,
                data.drug.strength,
                data.drug.form,
            ),
            strength: data.strength || '',
            days_supply: data.day_supply?.display || '',
            quantity: data.quantity || '',
            refills: data.refills || '',
            date_written: data.last_written_date ? dateFormat(data?.last_written_date).format('MM/DD/YYYY') : '',
            //----Addded SOI-370->END----//
            medication: stringConcatenation(
                data.drug.brandName,
                data.drug.genericName,
            ),
            dosage: ` ${data?.quantity ? `x${  data?.quantity  }: ` : ""}${data.drug.strength} ${data.drug.form}`,
            // sig:`${data?.dose || ""} ${data?.dose_unit || ""} ${data?.route?.display || ""} ${data?.frequency?.display || ""}`,
            sig: stringConcatenation(data?.dose, data?.dose_unit, data?.route?.display, data?.frequency?.display),
            prescriber: data.provider_first_name || data.provider_last_name ? `${data.provider_first_name} ${data.provider_last_name}` : "",
            action: statusOfMedicationList(data?.last_written_date, data?.stop_date, data?.is_prescribe),
        }
    }
    const sendErxUpdateTime = async () => {
        try {
            setIsLoadingButton(true)
            const res = await sentErxUpatedTime(patientId, editTime)
            if (activeNote?.componentId === 'plan_med_changes') {
                dispatch(updatingMedicationList(true))
            } else {
                if(!nonEditable){
                const resp = await getListMedicationsType('active', patientId, 1, 100000)
                const list = resp.data.data.filter((item) => {
                    const itemStopDate = item?.stop_date ? dateFormat(item?.stop_date) : false;
                    const encounterDate = moment(noteDetails?.service_date);
                    if (itemStopDate) {
                        return itemStopDate.isSameOrAfter(encounterDate, 'day');
                    } 
                        return true;
                    
                }).map((item) => convert(item))
                // const list = resp.data.data.map((item) => convert(item))
                if (clinicalNoteData.status !== 'LOCKED' || noteDetails?.name !== 'view') {
                    onChange('plan_med_changes.medication_list', list)
                }
                }
            }
            if (res) {
                setIsLoadingButton(false), setOpenERX(false)
            }
        } catch (error) {
            // console.error("error",error)
            notification(
                error?.data?.message || 'Somthing went wrong !',
                'error',
            )
            setOpenERX(false)
            setIsLoadingButton(false)
        }
    }
    const lastUpatedTime = () => {
        const startDate = moment().utc()
        if (moment().tz('America/New_York').isDST()) {
            setEditTime(startDate.subtract(240, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        } else {
            setEditTime(startDate.subtract(300, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        }
    }
    const checkAccess = () => {
        if (!userData.includes("access_erx") || !is_enable_eprescribe) {
            setOpenConfirm(true)
        } else if (userData.includes("access_erx") && is_enable_eprescribe) {
            setOpenERX(true)
            lastUpatedTime()
        }
    }

    const handleSaveAndClose = () => {
        dispatch(handleVisibleSaveAndClose({
            0: false
        }))
        setSaveCount(saveCount + 1)
        saveClinicalNote()
        handleStartEncounter(currentEncounter)
    }

    const handleSaveAndCloseButton = () => {
        window.location.assign('/schedule')
        handleSaveAndClose()
    }

    const handleSaveAndCloseDialog = () => {
        dispatch(handleOpenSaveAndCloseDialog({
            0: false
        }))
        handleSaveAndClose()
    }

    useEffect(() => {
        setShowHeader(!isConsultant || openListConsultation || openListProvider)
    }, [openListConsultation, openListProvider])

    return (
        <Grid
            style={{ borderLeft: !EqStatus ? "none" : '' }}
            className={clsx({
                [classes.headerGridContainer]: EqStatus,
                [classes.width0]: showHeader,
                [classes.headerGridContainerEq]: !EqStatus,
            })}
            direction={'column'}
            container
            alignItems={'center'}
            wrap="nowrap">
            {
                isConsultant && <div
                    style={{
                        width: "30px",
                        height: "30px",
                        background: "white",
                        position: "absolute",
                        right: EqStatus ? "316px" : "0px",
                        marginLeft: EqStatus ? '' : '270px',
                        color: "#3c8fe2",
                        top: "25px",
                        borderBottom: "2px #E9ECF1 solid",
                        borderLeft: "2px #E9ECF1 solid",
                        borderTop: "2px #E9ECF1 solid",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        paddingLeft: "3px",
                        zIndex: 10000,
                        cursor: "pointer"
                    }}
                    onClick={() => setEqStatus(EqStatus => !EqStatus)}
                >


                    {!EqStatus ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </div>}
            {isConsultant && EqStatus && (
                <Grid className={classes.encounterQueue}>
                    <EncounterQueue
                        view={
                            localState && localState?.view?.selected
                                ? localState?.view?.selected
                                : ""
                        }
                        daySelect={
                            localState && localState?.view?.date
                                ? localState?.view?.date
                                : ""
                        }
                    // daySelect={state.view.date}
                    />
                </Grid>
            )}
            {isConsultant && EqStatus && (<Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography className='acc_txt'>Patient Detail</Typography>

                </AccordionSummary>
                <AccordionDetails>
                    <div className={classes.scroll_content}  >
                        {/* <IconButton
                            variant="outlined"
                            onClick={handleShowHeader}
                            className={classes.show_header_btn}>
                            <InfoIcon color={showHeader ? 'primary' : 'disabled'} />
                        </IconButton> */}
                        <Grid
                            direction={'column'}
                            container
                            alignItems={'center'}
                            wrap="nowrap"
                            className={classes.hidden_cover}>
                            <img
                                src={
                                    'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                }
                                alt="Profile"
                                className={classes.avatar}
                            />
                            <Typography variant="h6" style={{ color: '#303E4E' }}>
                                {/* {isConsultant
                        ? startCase(
                              toLower(
                                  `${patient_first_name} ${
                                      patient_last_name
                                  }`,
                              ),
                          )
                        : providerN} */}
                                {startCase(toLower(`${patient_first_name} ${patient_last_name}`))}
                                <IconButton
                                    variant="outlined"
                                    onClick={() => {
                                        setIsShowMoreInfo(!isShowMoreInfo)
                                    }}
                                    style={{ marginLeft: 12 }}
                                // className={classes.show_header_btn}
                                >
                                    <InfoIcon color={showHeader ? 'primary' : 'disabled'} />
                                </IconButton>
                            </Typography>

                            <Grid container className={classes.cover_info} direction="column">
                                {isShowMoreInfo && (
                                    <>
                                        <Typography
                                            variant="h4"
                                            style={{
                                                paddingBottom: 8,
                                            }}>
                                            Patient Information
                                        </Typography>

                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>Patient:</Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {startCase(
                                                    toLower(`${patient_first_name} ${patient_last_name}`),
                                                )}
                                            </Typography>
                                        </Grid>
                                        {patient_preferred_name &&
                                            <Grid
                                                container
                                                justifyContent="space-between"
                                                className={classes.typo_line_cover}>
                                                <Typography className={classes.typo_line}>
                                                    Preferred Name:
                                                </Typography>
                                                <Typography className={classes.typo_line_gray}>
                                                    {patient_preferred_name}
                                                </Typography>
                                            </Grid>
                                        }

                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>DOB:</Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {moment(patient_dob, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>
                                                Appointment Date:
                                            </Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {appointment_date
                                                    ? moment
                                                        .utc(`${appointment_date}`)
                                                        .local()
                                                        .format('MM/DD/YYYY')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>
                                                Appointment Duration:
                                            </Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {appointment_duration ? `${appointment_duration} minutes` : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>Start Time:</Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {appointment_start
                                                    ? moment(appointment_start).format('hh:mm A')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>End Time:</Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {appointment_end
                                                    ? moment(appointment_end).format('hh:mm A')
                                                    : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>
                                                Insurance company:
                                            </Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {insurance_company}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            className={classes.typo_line_cover}>
                                            <Typography className={classes.typo_line}>Plan name:</Typography>
                                            <Typography className={classes.typo_line_gray}>
                                                {insurance_plan_name}
                                            </Typography>
                                        </Grid>
                                    </>
                                )}
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    direction="column"
                                    className={classes.typo_line_cover_tab}>
                                    <Typography
                                        className={classes.typo_line_tab}
                                        onClick={() => checkAccess()}>
                                        Prescribe
                                    </Typography>
                                    <Typography
                                        className={classes.typo_line_tab}
                                        onClick={() => {
                                            newAppointmentModalToggle()
                                        }}>
                                        Next Appointment
                                    </Typography>
                                    <HealthyRecordTab
                                        activeView={activeView}
                                        handleSetViewHealthyRecord={handleSetViewHealthyRecord}
                                    />
                                </Grid>
                                <ButtonHK
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        onChangeActiveView('history')
                                    }}>
                                    History
                                </ButtonHK>
                            </Grid>
                        </Grid>
                    </div>
                </AccordionDetails>
            </Accordion>
            )}

            <Dialog open={saveAndCloseDialog[clinicalNoteData?.encounter_id]}>
                <div style={{ padding: '10px 20px', maxWidth: '300px' }}>
                    <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <ReportIcon
                                style={{ fontSize: '18px', marginRight: '10px', color: 'rgb(85, 113, 198)' }}
                            />
                            <Typography variant="h6" fontWeight="bold">
                                Save and close note
                            </Typography>
                        </span>
                    </span>

                    <span style={{ paddingRight: '10px' }}>
                        <Typography>
                            You are about to start the new encounter, please save and close the current note
                        </Typography>
                    </span>

                    <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                        <LoadingButton
                            // disabled={loading}
                            size="small"
                            className="cancel-btn"
                            onClick={() => dispatch(handleOpenSaveAndCloseDialog({
                                [Object.keys(saveAndCloseDialog)[0]]: false
                            }))}
                            style={{ 
                                marginRight: '10px', 
                                color: '#444', 
                                height: 30
                            }}>
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            // disabled={loading}
                            onClick={handleSaveAndCloseDialog}
                            size="small"
                            variant="contained"
                            style={{
                                background: 'rgb(85, 113, 198)',  
                                padding: 5, 
                                color: '#fff', 
                                borderRadius: "5px !important",
                                height: 30
                            }}>
                            Save
                        </LoadingButton>
                    </span>
                </div>
            </Dialog>

            {saveAndCloseButtonVisible[clinicalNoteData?.encounter_id] &&   
                <span style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    width: 'fit-content',
                    left: -300,
                    top: isConsultant ? 33 : 22,
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center'
                }}
                onClick={() => handleSaveAndCloseButton()}>
                    <Close style={{ height: 18, width: 18, color: '#5571c6' }} />
                    <Typography style={{ color: '#5571c6' }}>Save and close note</Typography>
                </span>
            }

            {!nonEditable &&
                <LoadingButton
                    variant="outlined"
                    className={classes.show_save_btn}
                    onClick={() => {
                        setSaveCount(saveCount + 1)
                        saveClinicalNote()
                    }}
                    style={{
                        top: isConsultant ? "27px" : "16px",
                        left: isConsultant ? "-113px" : "-127px"
                    }}
                    disabled={!activeNote}
                    loading={loading}
                >
                    <img src={ClinicalNoteSave} alt="" style={{ height: "14px" }} />
                    <span
                        style={{
                            marginLeft: 5,
                        }}>
                        Save
                    </span>
                </LoadingButton>
            }

            {!isConsultant && (<IconButton
                variant="outlined"
                onClick={handleShowHeader}
                className={classes.show_header_btn}>
                <InfoIcon color={showHeader ? 'primary' : 'disabled'} />
            </IconButton>
            )}

            {!isConsultant && (<Grid
                direction={'column'}
                container
                alignItems={'center'}
                wrap="nowrap"
                className={classes.hidden_cover}>
                <img
                    src={
                        'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                    }
                    alt="Profile"
                    className={classes.avatar}
                />
                <Typography variant="h6" style={{ color: '#303E4E' }}>
                    {/* {isConsultant
                        ? startCase(
                              toLower(
                                  `${patient_first_name} ${
                                      patient_last_name
                                  }`,
                              ),
                          )
                        : providerN} */}
                    {startCase(toLower(`${patient_first_name} ${patient_last_name}`))}
                    <IconButton
                        variant="outlined"
                        onClick={() => {
                            setIsShowMoreInfo(!isShowMoreInfo)
                        }}
                        style={{ marginLeft: 12 }}
                    // className={classes.show_header_btn}
                    >
                        <InfoIcon color={showHeader ? 'primary' : 'disabled'} />
                    </IconButton>
                </Typography>

                <Grid container className={classes.cover_info} direction="column">
                    {isShowMoreInfo && (
                        <>
                            <Typography
                                variant="h4"
                                style={{
                                    paddingBottom: 8,
                                }}>
                                Patient Information
                            </Typography>

                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>Patient:</Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {startCase(
                                        toLower(`${patient_first_name} ${patient_last_name}`),
                                    )}
                                </Typography>
                            </Grid>
                            {patient_preferred_name &&
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    className={classes.typo_line_cover}>
                                    <Typography className={classes.typo_line}>
                                        Preferred Name:
                                    </Typography>
                                    <Typography className={classes.typo_line_gray}>
                                        {patient_preferred_name}
                                    </Typography>
                                </Grid>
                            }
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>DOB:</Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {moment(patient_dob, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>
                                    Appointment Date:
                                </Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {appointment_date
                                        ? moment
                                            .utc(`${appointment_date}`)
                                            .local()
                                            .format('MM/DD/YYYY')
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>
                                    Appointment Duration:
                                </Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {appointment_duration ? `${appointment_duration} minutes` : '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>Start Time:</Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {appointment_start
                                        ? moment(appointment_start).format('hh:mm A')
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>End Time:</Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {appointment_end
                                        ? moment(appointment_end).format('hh:mm A')
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>
                                    Insurance company:
                                </Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {insurance_company}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="space-between"
                                className={classes.typo_line_cover}>
                                <Typography className={classes.typo_line}>Plan name:</Typography>
                                <Typography className={classes.typo_line_gray}>
                                    {insurance_plan_name}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    <Grid
                        container
                        justifyContent="space-between"
                        direction="column"
                        className={classes.typo_line_cover_tab}>
                        <Typography
                            className={classes.typo_line_tab}
                            onClick={() => checkAccess()}>
                            Prescribe
                        </Typography>
                        <Typography
                            className={classes.typo_line_tab}
                            onClick={() => {
                                newAppointmentModalToggle()
                            }}>
                            Next Appointment
                        </Typography>
                        <HealthyRecordTab
                            activeView={activeView}
                            handleSetViewHealthyRecord={handleSetViewHealthyRecord}
                        />
                    </Grid>
                    <ButtonHK
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            onChangeActiveView('history')
                        }}>
                        History
                    </ButtonHK>
                </Grid>
            </Grid>)}

            {newAppointmentModal.status && (
                <Dialog
                    open={newAppointmentModal.status}
                    sx={{
                        '& .MuiDialog-paperFullWidth': {
                            maxWidth: '1200px',
                            height: '100vh',
                            overflow: 'hidden',
                        },
                    }}
                    fullWidth={true}
                    onClose={newAppointmentModalToggle}
                    className={classes.root}>
                    <SchedulerMenu
                        isPopupType
                        tab={activeTab}
                        onChangeTab={(tab) => setActiveTab(tab)}
                        isFromClinicalNote
                        open={newAppointmentModal.status}
                        appointmentModal={newAppointmentModal}
                        setIsLoading={setIsSubmitting}
                        loading={isSubmitting}
                        onModalClose={newAppointmentModalToggle}
                        onSubmit={handleSubmit}
                        // locationOptionsData={locations.map((item) => ({
                        //     key: item.id,
                        //     value: item.name,
                        // }))}
                        // visitTypeOptionsData={visitTypes.map((item) => ({
                        //     key: item.id,
                        //     value: item.post_code,
                        //     duration: item.duration,
                        // }))}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                    />
                </Dialog>
            )}

            {openERX && (
                <ErxDialog
                    open={openERX}
                    description
                    patientId={patientId}
                    handleClose={() => { sendErxUpdateTime() }}
                    prescription={true}
                    isLoadingButton={isLoadingButton}
                />
            )}
            {openConfirm && (<Confirm
                open={openConfirm}
                btnText="Confirm"
                message={'Sorry! You do not have permission to access this feature.'}
                onCancel={() => setOpenConfirm(false)}
                headerSize={15}
            />)
            }
        </Grid>
    )
}

export default Header