import React from 'react'
import './Login.scss'
import Container from '@material-ui/core/Container'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'

import backIcon from '../../assests/images/icon_back.png'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { ButtonSpinner } from '../custom'
import bg1 from 'assests/images/bg1.png'
import bg2 from 'assests/images/bg2.png'
import bg3 from 'assests/images/bg3.png'
import bg4 from 'assests/images/bg4.png'
import bg5 from 'assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const LoginForm = ({
    loading,
    handleSubmit,
    handleChange,
    validationError,
    errorMsg,
    isValidUser,
    handleBack,
    verifyLogin,
    setOtp,
    otpError,
    otpErrMsg,
    resendButtonDisabledTime,
    phoneNo,
    setOtpError,
    otp,
}) => {
    return (
        <Container sm={12} className="container login-wrapper">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6}>
                    <div
                        className="form-img"
                        style={{
                            background: `url(${randomBgImage})`,
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <form onSubmit={handleSubmit} className="form login" noValidate>
                        <div className="inner-wrapper-logo">
                            <img className="mobile-logo" src={logo} alt="TeleMed2U"></img>
                        </div>
                        <h1 className="align-item-center">
                            {isValidUser ? (
                                <img
                                    id="auto-login-back"
                                    onClick={() => handleBack()}
                                    className="ba-back-icon"
                                    src={backIcon}
                                    role="presentation"
                                    alt=""
                                />
                            ) : null}
                            Login to your account
                        </h1>
                        <FormLabel className="input-label" component="legend">
                            Cellphone
                            <span className="astrik">*</span>
                        </FormLabel>
                        <TextField
                            sm={12}
                            id="auto-login-phone"
                            required
                            error={validationError}
                            helperText={validationError ? errorMsg : null}
                            variant="outlined"
                            size="small"
                            inputProps={{ maxLength: 14 }}
                            className="text-input"
                            type="tel"
                            name="phone"
                            placeholder="Enter your cellphone"
                            maxLength="10"
                            value={phoneNo}
                            onChange={handleChange}
                            disabled={isValidUser}
                        />
                        {isValidUser ? (
                            <div className="form-inputs">
                                <FormLabel className="input-label" component="legend">
                                    Enter your one-time password sent to your phone number
                                </FormLabel>
                                <TextField
                                    id="auto-login-otp"
                                    required
                                    variant="outlined"
                                    size="small"
                                    className="text-input"
                                    inputProps={{
                                        maxLength: 6,
                                    }}
                                    onChange={(e) => {
                                        setOtp(e)
                                        setOtpError(false)
                                    }}
                                    value={otp}
                                    error={otpError}
                                    helperText={
                                        resendButtonDisabledTime > 0 ? (
                                            <>
                                                <div className="dbl-error">{otpErrMsg + ' '}</div>
                                                <div className="flex-center">
                                                    <span className="resendotp-grayed">
                                                        {`Resend one-time password (${resendButtonDisabledTime})`}
                                                    </span>{' '}
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="dbl-error">{otpErrMsg + ' '}</div>
                                                <div className="flex-center">
                                                    <span
                                                        id="auto-login-reset-otp"
                                                        className="link-blue"
                                                        role="presentation"
                                                        onClick={handleSubmit}>
                                                        Resend one-time password
                                                    </span>
                                                </div>
                                            </>
                                        )
                                    }
                                />
                            </div>
                        ) : null}
                        <div className="submit">
                            {isValidUser ? (
                                <Box
                                    sx={{
                                        m: 1,
                                        position: 'relative',
                                    }}>
                                    <Button
                                        disabled={loading || otp.length < 6}
                                        id="auto-login-btn"
                                        variant="contained"
                                        className="btn-primary"
                                        onClick={verifyLogin}>
                                        Login
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '20%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            ) : (
                                <ButtonSpinner
                                    disabled={
                                        !Boolean(phoneNo) ||
                                        Boolean(validationError) ||
                                        Boolean(loading)
                                    }
                                    isLoading={Boolean(loading)}
                                />
                            )}
                            {!isValidUser ? (
                                <h5>
                                    Don’t have an account?{' '}
                                    <Link id="auto-register-link" to="/Register">
                                        Register
                                    </Link>
                                </h5>
                            ) : null}
                        </div>
                        <h5 className="error-login">
                            Trouble logging in?{' '}
                            <Link id="auto-login-another-way-link" to="/LoginAnotherWay">
                                Try a different way
                            </Link>
                        </h5>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LoginForm
