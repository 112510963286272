import { Grid, makeStyles, Typography } from '@material-ui/core'
import { CircularProgress, Box } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import ErxDialog from 'containers/PatientsSetting/view/MedicationPatient/ErxDialog'
import { getListMedications } from 'services/Patient/patient'
import MedicationsCreateDialog from '../MedicationsCreateDialog'
import { Card } from '../view/CardHealthRecord'
import { NoData } from './NoData'
import { apiClinicalNotes } from 'services/Patient/apiCinicalnotes'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    patientId?: string
    encounter_id?: string
}

const useStyles = makeStyles(() => ({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    viewall: {
        color: '#667689',
        '&:hover': {
            color: '#4e52d9',
        },
    },
}))

const demoData = [1, 2, 3].map((item) => ({
    encounter_date: moment(),
    status: 'Siged',
    provider: 'Tuan Thanh',
    pdfUrl: '232323232',
}))

export const ClinicalNotes = (props: Props) => {
    //Hooks
    const history = useHistory()
    const classes = useStyles()

    //State
    const [patientId, setPatientId] = useState('')
    const { id } = useParams<{ id: string }>()
    const [clinicalnotes, setClinicalNotes] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            fetchClicalNotes()
        }

        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    //handle
    const fetchClicalNotes = async () => {
        try {
            setLoading(true)

            const res = await apiClinicalNotes.getAll(patientId, { page: '1', limit: '10' })

            console.log(res, 'log res')
            setClinicalNotes(res.data.data || [])
        } catch (ex) {
            console.error('d', ex)
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.CLINICAL_NOTES.replace(':id', patientId))

    const Content = () => (
        <>
            <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{
                    gap: 8,
                    height: '100%',
                    overflow: 'auto',
                    paddingRight: 15,
                    flexWrap: 'nowrap',
                }}>
                {clinicalnotes.map((item: any) => {
                    return (
                        <Grid
                            container
                            key={item?.id}
                            justifyContent="space-between"
                            wrap="nowrap"
                            alignItems="center"
                            style={{
                                borderBottom: '1px solid #E4E8EE',
                                paddingBottom: 8,
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                <Typography>
                                    {item?.encounter?.start ? moment(item?.encounter?.start).format('MM/DD/YYYY') : "N/A"}
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#9CA9B8',
                                        fontSize: 12,
                                        paddingLeft: '10px',
                                    }}>
                                    {item.status}
                                </Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>

            {!!clinicalnotes.length && (
                <Grid container item justifyContent="flex-end" style={{ padding: 15 }}>
                    <span className={classes.viewall} onClick={props?.viewAllFunc || handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            // menu={[
            //     {
            //         name: 'View all',
            //         handler: props?.viewAllFunc || handleViewAll,
            //     },
            // ]}
            viewAllHandler={props?.viewAllFunc || handleViewAll}
            onClick={props.onClick}
            header="Clinical Notes">
            {!!loading && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress color="inherit" thickness={1.5} />
                </Grid>
            )}

            {clinicalnotes.length ? <Content /> : <NoData />}
        </Card>
    )
}
