import { Grid, Typography, Button } from '@material-ui/core';
import React from "react";
import { makeStyles } from '@material-ui/styles';
import bgImage from './icons/bg_end_meeting.png'
import appLogo from '../../assests/Logos/tm2u_logo_white.png'
import avatarCrop from './icons/avatar_crop.png'
import TimerIcon from './icons/TimerIcon'
import AvatarEndmeetingIcon from './icons/AvatarEndmeetingIcon';

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'cover',
    height: '100%'
  },
  box: {
    margin: 'auto',
    marginTop: 100,
    width: 'fit-content',
  },
  name:{
    lineHeight: 'unset',
    fontSize: 32,
    fontWeight: 500,
    color: 'white',
    marginTop: 24,
  },
  end_text:{
    marginLeft: 8,
    lineHeight: 'unset',
   
    fontSize: 19,
    color: 'white'
  },
  appLogo:{
    width: 112,
    marginBottom: 62,
  },
  button:{
    background: '#5571c6',
    color: 'white',
    fontSize: 15,
    fontWeight: 400
  },
  avatar_crop:{
    position: 'relative'
  },
  avatar_crop_icon:{
    position: 'absolute',
    left: 32,
    top: 39,
    borderRadius:'50%',
  }
}))

export default function EndMeeting() {
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.box} container alignItems="center" direction="column">
          <img
            className={classes.appLogo}
            src={appLogo}
            alt="TeleMed2U"
        />
        <div className={classes.avatar_crop}>
        <AvatarEndmeetingIcon/>
        <img
            className={classes.avatar_crop_icon}
            src={avatarCrop}
            alt="TeleMed2U"
        />
        </div>
      <Typography className={classes.name}>Christopher</Typography>
      <Grid container style={{ marginTop: 16,
    marginBottom: 24,}} alignItems="center"><TimerIcon/> <Typography className={classes.end_text}>Your call has been ended</Typography></Grid>

      <Button href={"/"} variant="contained"  className={classes.button}>Go to Home page</Button>
      </Grid>

    </Grid>
  );
}
