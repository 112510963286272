import React from 'react'

export default function IconRequestSuccess() {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.0006 64.8541C18.5332 64.8541 5.14648 51.4673 5.14648 34.9999C5.14648 18.5325 18.5332 5.14575 35.0006 5.14575C51.4681 5.14575 64.8548 18.5325 64.8548 34.9999C64.8548 51.4673 51.4681 64.8541 35.0006 64.8541ZM35.0006 6.52075C19.2972 6.52075 6.52148 19.2965 6.52148 34.9999C6.52148 50.7033 19.2972 63.4791 35.0006 63.4791C50.7041 63.4791 63.4798 50.7033 63.4798 34.9999C63.4798 19.2965 50.7041 6.52075 35.0006 6.52075Z"
                fill="#14AE5C"
                stroke="#14AE5C"
                strokeWidth="3"
            />
            <path
                d="M29.7975 41.2231L30.8582 42.2837L31.9189 41.2231L46.9105 26.2314L45.8499 25.1708L46.9105 26.2314C47.1706 25.9714 47.6208 25.9714 47.8809 26.2314C48.1409 26.4915 48.1409 26.9417 47.8809 27.2018L31.3434 43.7393C31.2131 43.8695 31.0392 43.9416 30.8582 43.9416C30.6772 43.9416 30.5033 43.8695 30.373 43.7393L22.1189 35.4851C21.8588 35.225 21.8588 34.7748 22.1189 34.5147C22.3789 34.2547 22.8292 34.2547 23.0892 34.5147L29.7975 41.2231Z"
                fill="#14AE5C"
                stroke="#14AE5C"
                strokeWidth="3"
            />
        </svg>
    )
}
