import {
    getService,
    postService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'
import moment from 'moment'

export const getSpecialities = (clinicId) => {
    return getService(
        `${config.dev.baseURL}/speciality/list?clinic=${
            clinicId || ''
        }`,
    )
}
export const getSpecialitiesNonBehavioral = () => {
    return getService(
        `${config.dev.baseURL}/speciality/list?type=non-behavioral`,
    )
}

export const getClinicsBySpeciality = (speciality) => {
    return getService(
        `${config.dev.baseURL}/clinic/list?speciality=${speciality}`,
    )
}

export const getClinicsByZip = (payload) => {
    return postService(
        `${config.dev.baseURL}/clinic/getZipBasedClinic`,
        payload,
    )
}

export const bookAppointment = (payload) => {
    return postService(
        `${config.dev.baseURL}/appointment/book`,
        payload,
    )
}

export const rescheduleAppointment = (payload) => {
    return postService(
        `${config.dev.baseURL}/appointment/reschedule`,
        payload,
    )
}

export const getAppointmentlist = (payload) => {
    return Promise.resolve({
        data: [],
    })
    // return postService(
    //     `${config.dev.baseURL}/appointment/v2/list`,
    //     payload,
    // )
}

export const getAppointments = ({
    queryKey: [_, user_id, role, filters],
}) =>
    postService(
        `${config.dev.baseURL}/appointment/v2/list`,
        {
            user_id,
            appointment_list_param: role,
            ...filters,
        },
    ).then((response) => {
        return {
            data: response.data.appointments.map(
                (item) => ({
                    ...item,
                    time: `${moment
                        .utc(`${item.date} ${item.start}`)
                        .local()
                        .format('hh:mm A')} - ${moment
                        .utc(`${item.date} ${item.end}`)
                        .local()
                        .format('hh:mm A')}`,
                    speciality_name: item.speciality.name,
                    parent_user_id:
                        item.interact_with_dr === 'child'
                            ? item.parent.parent_id
                            : item.user_id,
                    patient_name:
                        item.interact_with_dr === 'child'
                            ? item.parent.parent_name
                            : item.patient.patient_name,
                    provider_name: `${item.provider?.first_name} ${item.provider?.last_name}`,
                    user_id: item.user_id,
                }),
            ),
            totalCount: response.data.totalCount,
        }
    })

export const cancelAppointmentById = (payload) => {
    return postService(
        `${config.dev.baseURL}/appointment/cancel`,
        payload,
    )
}

export const refundInitiate = (payload) => {
    return postService(
        `${config.dev.baseURL}/stripe/cancel-payment`,
        payload,
    )
}

export const refundInitiateFromModal = (payload) => {
    return postService(
        `${config.dev.baseURL}/stripe/refund-initiate`,
        payload,
    )
}

export const updateAppointmentStatusById = (payload) => {
    return postService(
        `${config.dev.baseURL}/appointment/update`,
        payload,
    )
}

export const getIntakeFormData = (payload) =>
    postService(
        `${config.dev.baseURL}/appointment-intake/getIntakeData`,
        payload,
    )

export const saveIntakeFormData = (payload) =>
    postService(
        `${config.dev.baseURL}/appointment-intake/save`,
        payload,
    )

export const getAppointmentDetails = (appointmentId) =>
    getService(
        `${config.dev.baseURL}/appointment/details/${appointmentId}`,
    )

////////////////////Tm2u Role /////////////////////

export const getSearchPatientlist = (payload) => {
    return postService(
        `${config.dev.baseURL}/patient/search`,
        payload,
    )
}

export const addpatientdetail = (payload) => {
    return postService(
        `${config.dev.baseURL}/clinic/register`,
        payload,
    )
}
export const getMarkConsultationStatusList = (
    providerId,
) => {
    return getService(
        `${config.dev.baseURL}/appointment/findByproviderId/${providerId}`,
    )
}

export const getClinicById = (clinicId) =>
    getService(`/clinic/${clinicId}`)

export const getConsultations = ({
    providerId,
    currentDate,
    currentTime,
    page,
    limit,
}) => {
    return getService(
        `${config.dev.baseURL}/appointment/findByproviderId/${providerId}?pagination=true&page=${page}&limit=${limit}&todayDate=${currentDate}&currentTime=${currentTime}`,
    )
}
