import React, { useState, useEffect } from 'react'
import {
    Button,
    CircularProgress,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { useHistory, useParams } from 'react-router-dom'

import {
    getFaxAttachmentFile,
    getFaxById,
    updateFaxById,
} from 'services/EFax'
import PDFViewer from 'components/ClinicalNotes/reusable/PDFViewer'
import StarCheckBox from 'components/shared/StarCheckBox'
import AssociateToPatientModal from './AssociateToPatientModal'
import AssignToProviderModal from './AssignToProviderModal'
import SuccessModal from 'components/SuccessModal'

import { useNotification } from 'shared/elements/Notification'
import Routes from 'constants/routes'

import { displayName } from '../shared/util'
import { formatDateTime } from 'utilities/formats'
import { SmsEditIcon } from 'assests/icons/SmsEditIcon'
import { GroupPeopleIcon } from 'assests/icons/GroupPeopleIcon'
import { UserInfo } from 'assests/icons/UserIcon'
import { SmsTrackingIcon } from 'assests/icons/SmsTrackingIcon'
import { Button as ButtonCustom } from 'components/custom'
import { Loading } from 'shared/elements/Loading'
import { UnarchiveIcon } from 'assests/icons/UnarchiveIcon'

import './styles.scss'
import ArchivedIcon from 'assests/icons/ArchivedIcon'

const useStyles = makeStyles(() => ({
    preview_document: {
        background: '#E9ECF1',
        padding: 24,
    },
    container: {
        // position: 'absolute',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    text_back: {
        color: '#5571C6',
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    customButton: {
        height: 32,
        color: '#667689',
        background: '#E9ECF1',
        fontSize: 14,
        borderRadius: 30,
        fontWeight: 400,
        padding: '12px 24px',
        marginLeft: 14,
        '&:hover': {
            background: '#E9ECF1',
        },
    },
    assign_btn: {
        backgroundColor: '#5571C6',
        color: '#ffffff',
        '&:hover': {
            background: '#5571C6',
        },
    },
    patient_info: {
        display: 'inline-block',
        color: '#5571C6',
        backgroundColor: '#EFF3FF',
        padding: '2px 8px',
        borderRadius: 5,
        marginLeft: 10,
        cursor: 'pointer',
    },
    unassociate_patient: {
        color: '#5571C6',
        display: 'inline-block',
        marginLeft: 10,
        cursor: 'pointer',
    },
}))

export const FaxDetailDialog = () => {
    const classes = useStyles()
    const { id: faxId } = useParams()
    const history = useHistory()

    const notification = useNotification()

    const [
        openAssocicateToPatientModal,
        setOpenAssocicateToPatientModal,
    ] = useState(false)

    const [faxDetail, setFaxDetail] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [actionInProgress, setActionInProgress] =
        useState(false)
    const [attachmentFiles, setAttachmentFiles] = useState(
        [],
    )

    const [
        openAssignToProviderModal,
        setOpenAssignToProviderModal,
    ] = useState(false)
    const [
        isAssignedToProviderSuccess,
        setIsAssignedToProviderSuccess,
    ] = useState(false)
    const [isAssociateSuccess, setIsAssociateSuccess] =
        useState(false)

    const getFaxDetails = async (faxId) => {
        try {
            const resp = await getFaxById(faxId)
            setFaxDetail(resp.data)

            // if (!resp.data.mark_read) {
            //     // TODO:Mark fax as read!
            //     updateFaxById(faxId, {
            //         mark_read: true,
            //     })
            // }
        } catch (error) {
            notification(
                error.data?.message ||
                    'Cannot get Fax details',
                'error',
            )
        }
    }

    const getFaxAttachment = async (faxId) => {
        try {
            const resp = await getFaxAttachmentFile(faxId)
            setAttachmentFiles(resp.data)
        } catch (error) {
            notification(
                error.data?.message ||
                    'Cannot get Fax attachment',
                'error',
            )
        }
    }

    const unassociatePatient = async () => {
        try {
            await updateFaxById(faxDetail.message_id, {
                associated_patient: null,
                associated_patient_first_name: null,
                associated_patient_last_name: null,
            })
            setFaxDetail({
                ...faxDetail,
                associated_patient: null,
            })
        } catch (exception) {
            notification(
                `Patient ${displayName(
                    faxDetail.associated_patient_first_name,
                    faxDetail.associated_patient_last_name,
                )} unassociated`,
                'success',
            )
        }
    }

    const archive = async () => {
        setActionInProgress(true)

        try {
            await updateFaxById(faxDetail.message_id, {
                is_archived: !faxDetail.is_archived,
            })

            setFaxDetail({
                ...faxDetail,
                is_archived: !faxDetail.is_archived,
            })
        } catch (exception) {
            notification(
                exception.data?.message ||
                    `Unable to ${
                        faxDetail.is_archived
                            ? 'Unarchive'
                            : 'Archive'
                    }`,
                'error',
            )
        }

        setActionInProgress(false)
    }

    useEffect(() => {
        const abortController = new AbortController()

        if (faxId) {
            setLoading(true)

            Promise.allSettled([
                getFaxDetails(faxId),
                getFaxAttachment(faxId),
            ]).finally(() => setLoading(false))
        }

        return () => abortController.abort()
    }, [faxId])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap">
            <Grid container>
                <Typography
                    className={classes.text_back}
                    onClick={() =>
                        history.push(Routes.EFax.INCOMING)
                    }>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                            marginRight: 12,
                        }}
                    />
                    Back to Fax list
                </Typography>
            </Grid>

            <Grid container>
                <Grid item className="d-flex column">
                    <Typography className={classes.header}>
                        {faxDetail.title}
                    </Typography>
                    <Typography
                        style={{
                            marginTop: '4px',
                            fontFamily: "'Rubik'",
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '140%',
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                        {faxDetail.fax_type === 'inbound'
                            ? 'From'
                            : 'To'}
                        :
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                margin: '0 5px',
                            }}>
                            {' '}
                            {faxDetail.fax_type ===
                            'inbound'
                                ? faxDetail.from
                                : faxDetail.to}
                        </Typography>
                        <Typography>
                            {' - '}
                            {formatDateTime(
                                faxDetail.updatedAt,
                                'MMM DD YYYY hh:mm A',
                            )}
                        </Typography>
                    </Typography>

                    {/* Associated patient */}
                    {faxDetail.associated_patient && (
                        <Typography>
                            {'Patient: '}
                            <Typography
                                onClick={(() => {
                                  history.push(`/patient/${faxDetail?.associated_patient}`)
                                })}
                                className={
                                    classes.patient_info
                                }>
                                <UserInfo />
                                <span
                                    style={{
                                        marginLeft: 5,
                                    }}>
                                    {displayName(
                                        faxDetail.associated_patient_first_name,
                                        faxDetail.associated_patient_lastt_name,
                                    )}
                                </span>
                            </Typography>

                            <Typography
                                className={
                                    classes.unassociate_patient
                                }
                                onClick={() =>
                                    unassociatePatient()
                                }>
                                Unassoicate Patient
                            </Typography>
                        </Typography>
                    )}
                </Grid>

                <Grid
                    item
                    style={{ flex: 1 }}
                    className="d-flex justify-flex-end align-center">
                    <StarCheckBox
                        value={faxDetail.favourite}
                        onChange={(value) => {
                            setFaxDetail({
                                ...faxDetail,
                                favourite: value,
                            })
                            return updateFaxById(
                                faxDetail.message_id,
                                {
                                    favourite: value,
                                },
                            )
                        }}
                    />

                    {/* Archive or Unarchive */}
                    <Button
                        variant="contained"
                        className={classes.customButton}
                        onClick={archive}
                        disabled={actionInProgress}
                        startIcon={
                            actionInProgress ? (
                                <CircularProgress
                                    size={15}
                                    style={{
                                        marginRight: 8,
                                    }}
                                />
                            ) : faxDetail.is_archived ? (
                                <UnarchiveIcon />
                            ) : (
                                <ArchivedIcon />
                            )
                        }>
                        {faxDetail.is_archived
                            ? 'Unarchive'
                            : 'Archive'}
                    </Button>

                    {/* Assign to Provider */}
                    <Button
                        variant="contained"
                        className={`${classes.customButton} ${classes.assign_btn}`}
                        onClick={() => {
                            setOpenAssignToProviderModal(
                                true,
                            )
                        }}
                        startIcon={<SmsEditIcon />}>
                        Assign
                    </Button>

                    {/* Associate to Patient */}
                    <Button
                        variant="contained"
                        className={classes.customButton}
                        onClick={() => {
                            setOpenAssocicateToPatientModal(
                                true,
                            )
                        }}
                        startIcon={<GroupPeopleIcon />}>
                        Associate Patient
                    </Button>

                    {/* Forward */}
                    <Button
                        variant="contained"
                        className={classes.customButton}
                        onClick={() =>
                            history.push(
                                Routes.EFax.FORWARD.replace(
                                    ':id',
                                    faxDetail.message_id,
                                ),
                            )
                        }
                        startIcon={<SmsTrackingIcon />}>
                        Forward
                    </Button>

                    {/* New Fax */}
                    <ButtonCustom
                        tooltip="Add New FAX"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                            history.push(
                                Routes.EFax.NEW_FAX,
                            )
                        }
                        fullWidth>
                        <AddIcon
                            style={{
                                fontSize: 20,
                            }}
                        />
                    </ButtonCustom>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={2}
                item
                justifyContent="center"
                style={{
                    position: 'relative',
                    height: 'calc(100vh - 200px)',
                }}>
                <Loading
                    visible={isLoading}
                    thickness={2}
                />

                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        {faxDetail.notes}
                    </Grid>
                </Grid>

                {attachmentFiles[0]?.url && (
                    <Grid
                        style={{
                            height: 'calc(100vh - 200px)',
                        }}
                        item
                        xs={8}
                        container
                        className={classes.preview_document}
                        justifyContent="center"
                        alignItems="center">
                        <PDFViewer
                            PDFFile={
                                attachmentFiles[0]?.url
                            }
                        />
                    </Grid>
                )}
                <Grid item xs={12}></Grid>
            </Grid>

            <AssociateToPatientModal
                open={openAssocicateToPatientModal}
                onModalClose={() => {
                    setOpenAssocicateToPatientModal(false)
                }}
                onSubmitSuccess={(patient) => {
                    // Update associated patinent name to faxDetail
                    setFaxDetail({
                        ...faxDetail,
                        associated_patient: patient.id,
                        associated_patient_first_name:
                            patient.first_name,
                        associated_patient_last_name:
                            patient.last_name,
                    })

                    setOpenAssocicateToPatientModal(false)
                    setIsAssociateSuccess(true)
                }}
                item={faxDetail}
            />

            <AssignToProviderModal
                open={openAssignToProviderModal}
                onModalClose={() => {
                    setOpenAssignToProviderModal(false)
                }}
                onSubmitSuccess={() => {
                    setOpenAssignToProviderModal(false)
                    setIsAssignedToProviderSuccess(true)
                }}
                selectedItems={[faxDetail]}
            />

            <SuccessModal
                isOpen={isAssignedToProviderSuccess}
                closeModal={() => {
                    setIsAssignedToProviderSuccess(false)
                }}
                title={'Completed'}
                message={'Fax has been assigned'}
                closeButtonText={'OK'}
            />

            <SuccessModal
                isOpen={isAssociateSuccess}
                closeModal={() => {
                    setIsAssociateSuccess(false)
                }}
                title={'Completed'}
                message={'Fax has been associated'}
                closeButtonText={'OK'}
            />
        </Grid>
    )
}
