import React, { useState } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TextareaAutosize,
    Typography,
    makeStyles,
} from '@material-ui/core'
import { useFormik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import * as yup from 'yup'

import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'

import './styles.scss'
import { TextError } from 'containers/ProviderDirectory/AvailabilityTab/AddNewAvailability/components'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
    description: {
        height: '170px !important',
        fontFamily: 'Rubik',
        padding: '10px 0 10px 10px',
        background: '#FBFCFC',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        resize: 'none',
        marginTop: '16px',
        fontSize: '13px',
        overflowY: 'auto !important',
    },
})

interface Props {
    open: boolean
    loading: boolean
    macros: object
    itemId: string
    onSubmit: (values) => void
    onClose: () => void
}

export const CreateMacrosDialog = (props: Props) => {
    const classes = useStyles()

    const [dataLoading, setDataLoading] = useState(false)

    const handleSubmit = (payload) => {
        props.onSubmit(payload)
    }

    const validationSchema = yup.object({
        name: yup.string().required('Name is required!'),
        description: yup.string().required('Description is required!'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            name: props.macros?.name || '',
            description: props.macros?.description || '',
            id: props.macros?.id || '',
        },
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            title={props.mediaKeyword?.id ? 'Update Macro' : 'Add New Macro'}
            width={600}
            description={'Macro Details'}
            open={props.open}
            onClose={() => props.onClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={12}>
                            <TextBox
                                label="Name"
                                required
                                name="Name"
                                value={formik.values.name}
                                placeholder="Enter name"
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                onChange={(value) => formik.setFieldValue('name', value)}
                            />
                        </Grid>
                        <Grid item xs={12} container direction="column">
                            <Typography
                                style={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: '#516983',
                                }}>
                                Description
                                <span
                                    style={{
                                        color: 'red',
                                    }}>
                                    *
                                </span>
                            </Typography>
                            <TextareaAutosize
                                className={classes.description}
                                name="description"
                                minRows={1}
                                maxRows={5}
                                required
                                value={formik.values.description}
                                onChange={(e) =>
                                    formik.setFieldValue('description', e.target.value)
                                }
                            />
                            <TextError
                                condition={
                                    formik.touched.description && Boolean(formik.errors.description)
                                }
                                error={formik.errors.description}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={props.onClose}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={props.loading}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                className={classes.button_create}
                                onClick={formik.handleSubmit}>
                                {props.mediaKeyword?.id ? 'Update' : 'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
