import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
    Popover,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormControlLabel,
    FilledInput,
    Checkbox,
} from '@material-ui/core'

import JSZip from 'jszip'

import CloseIcon from '@material-ui/icons/Close'
import CircularProgress from '@mui/material/CircularProgress'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import Routes from 'constants/routes'
import React, { useEffect, useState, useRef } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Pagination, PaginationItem, Stack } from '@mui/material'
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'

import { Storage } from 'aws-amplify'

import { Button as ButtonCustom } from 'components/custom'
import { connect, useSelector } from 'react-redux'

import ForwardIcon from 'assests/icons/ForwardIcon'
import NextIcon from 'assests/icons/NextIcon'
import SearchIcon from 'assests/icons/search_icon.svg'
import { removeAccount, updateStatusAccount } from 'services/Calendar/index'
import { createDocument, getDocumentList } from 'services/Documents/index'

import { NoRowsOverlayEl, SelectEl } from 'shared/elements'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import AlertConfirmDialog from 'components/shared/Elements/AlertConfirmDialog'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'
import AlertErrorDialog from 'components/shared/Elements/AlertErrorDialog'

import { DOCUMENT_TYPE } from './CreateDocumentDialog/constant'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useNotification } from 'shared/elements/Notification'
import { getDocumentById } from '../../services/Documents'
import CreateDocumentDialog from './CreateDocumentDialog'
// import Checkbox from '@mui/material/Checkbox';
import Download_img from '../../assests/images/download_gray.png'
import DownloadBlue_img from '../../assests/images/dowload_blue.png'
import { LoadingButton } from '@mui/lab'
import { ReactComponent as FilterIcon } from '../../assests/images/filter.svg'
import { _getClinicalNotesFilterPatient } from '../../containers/ClinicalNotes/api/services'
import { useDebounce } from 'shared/hooks'
import {getPaginatedData} from "../../utilities";

const regexPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_search: {
        background: '#5571C6',
        '&:hover': {
            background: '#5571C6',
        },
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    filter_btn: {
        width: '130px !important',
        fontWeight: 'normal !important',
        borderRadius: '10px !important',
        background: '#5571C6 !important',
        color: 'white !important',
        padding: '8px !important',
    },
})

export const userMenus = [
    {
        name: 'Download',
        action: 'downloadFile',
    },
    {
        name: 'Go to Patient Health Record',
        action: 'openHealthRecord',
    },
]

const renderEditAction = (params, actions, permissions, notification) => {
    const classes = useStyles()
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState(null)
    const [isDownload, setIsDownload] = useState(false)
    const { refetchAccount, handleEditClick } = actions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const [loading, setLoading] = useState(false)
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

    const [alertConfirmOpen, setAlertConfirmOpen] = useState({
        status: false,
        type: '',
    })

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onEditClick: () => {
            setAnchorEl(null)
            handleEditClick(params)
        },
        onDeleteClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertDeleteOpen(true)
            }
        },
        openHealthRecord: () => {
            setAnchorEl(null)
            if (params?.row?.associated_patient) {
                history.replace(
                    Routes.PATIENT.DOCUMENTS.replace(':id', params?.row?.associated_patient),
                )
            } else {
                notification('No patient_id!', 'error')
            }
        },
        downloadFile: () => {
            if (params?.row?.id) {
                setIsDownload(true)
                getDocumentById(params?.row?.id).then((res) => {
                    const uri = res?.data?.url
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.style = 'display: none'
                    if (uri) {
                        fetch(uri).then(async (res) => {
                            const response = await res.blob()
                            const url = window.URL.createObjectURL(response)
                            a.href = url
                            a.download = params?.row?.file_url
                            a.click()
                            window.URL.revokeObjectURL(url)
                            setAnchorEl(null)
                            setIsDownload(false)
                        })
                    }
                })
            }
        },
    }

    const handleDeleteAccount = () => {
        setLoading(true)
        removeAccount(params.id)
            .then(() => {
                setLoading(false)
                refetchAccount()
                handleClose()
                setAlertDeleteOpen(false)
                notification("User's account deleted successfully!", 'success')
            })
            .catch((e) => {
                setLoading(false)
                setAlertDeleteOpen(false)
                notification('Cannot delete this account. Please try again!', 'error')
            })
    }

    const handleDisableOrEnableAccount = () => {
        setLoading(true)
        updateStatusAccount({
            id: params.id,
            payload: {
                active: alertConfirmOpen?.type === 'enable',
            },
        })
            .then(() => {
                setLoading(false)
                refetchAccount()
                handleClose()
                setAlertConfirmOpen({
                    status: false,
                    type: '',
                })
            })
            .catch((e) => {
                setLoading(false)
                setAlertConfirmOpen({
                    status: false,
                    type: '',
                })
            })
    }

    return (
        <Grid item>
            {/* <RBACProvider permissions={permissions}>
                <RBACWrapper
                    requiredPermissions={[
                        'deactivate_user_security_accounts',
                        'delete_user_security_accounts',
                    ]}> */}
            <IconButton
                size="small"
                onClick={handleClick}
                style={{
                    background: '#FFFFFF',
                    border: '1px solid #E9ECF1',
                    borderRadius: '20px',
                }}>
                <MoreHorizIcon
                    style={{
                        color: '#3E465B',
                        fontSize: 18,
                    }}
                />
            </IconButton>
            {/* </RBACWrapper>
            </RBACProvider> */}

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 250,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        key={index}
                        onClick={handleFunc[menu.action]}
                        style={{
                            color: menu.name === 'Delete' ? '#E81212' : '#667689',
                        }}>
                        {menu.name}
                        {isDownload && menu.name === 'Download' && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginLeft: 100,
                                }}>
                                <CircularProgress
                                    style={{
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            </Box>
                        )}
                    </MenuItem>
                ))}
            </Menu>
            <AlertDeleteDialog
                loading={loading}
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleDeleteAccount()
                }}
            />

            <AlertConfirmDialog
                loading={loading}
                open={alertConfirmOpen?.status}
                type={alertConfirmOpen?.type}
                handleClose={() => {
                    setAlertConfirmOpen({
                        status: false,
                        type: '',
                    })
                }}
                handleDelete={handleDisableOrEnableAccount}
            />
        </Grid>
    )
}

const renderActions = (params, actions, permissions, notification) => {
    return (
        <Grid container alignItems="center" direction="column" spacing={2}>
            {renderEditAction(params, actions, permissions, notification)}
        </Grid>
    )
}

const CustomPagination = ({ onPageChange, total, numberRecord, currentPage }) => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const rowIndex = gridPaginationRowRangeSelector(apiRef)

    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show <strong>{rowIndex?.lastRowIndex + 1 || 0} records</strong> of{' '}
                <strong>{total}</strong>
            </Typography>
            <Pagination
                color="primary"
                count={pageCount}
                page={currentPage || page + 1}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1)
                    onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

export const dateTimeOption = [
    { key: 'day', value: 'Day' },
    { key: 'week', value: 'Week' },
    { key: 'month', value: 'Month' },
]

const Documents = () => {
    const classes = useStyles()
    // const [page, setPage] = useState(1)
    const notification = useNotification()

    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [openChangePassDialog, setOpenChangePassDialog] = React.useState(false)
    const [userSecurityModal, setUserSecurityModal] = React.useState({
        status: false,
    })
    const [sortModel, setSortModel] = useState([])
    const [filterData, setFilterData] = useState({
        role: '',
        // document_type: '',
    })

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [isLoading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [alertErrorOpen, setAlertErrorOpen] = useState('')
    const [patientList, setPatientList] = useState([])

    const [selectAll, setSelectAll] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const scrollableContentRef = useRef(null)
    const [checked, setChecked] = useState(false)
    const [patientData, setPatientData] = useState([])
    const [document, setDocument] = useState(DOCUMENT_TYPE)
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patientSearch: '',
        documentSearch: '',
    })
    const debouncedSearchFilterText = useDebounce(searchTextOffilter.patientSearch, 500)
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [selectedUser, setSelectedUser] = useState({
        selectedPatient: [],
        selectedDocument: [],
    })
    const [bundleId, setBundleId] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })
    const onPageChange = (value) => {
        setPage(value)
    }
    const [filterEl, setFilterEl] = React.useState(null)

    const handleFilterClick = (event) => {
        setFilterEl(filterEl ? null : event.currentTarget)
    }

    const handleFilterClose = () => {
        setFilterEl(null)
    }

    const openFilter = Boolean(filterEl)
    const id = openFilter ? 'simple-popper' : undefined
    const handleLoadData = async (name = '') => {
        try {
            const payload = {
                page: 1,
                query: searchTextOffilter.patientSearch,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const {tempUserList, bundle_id, result} = getPaginatedData(res)

            setPatientData(tempUserList)
            setCurrentPage(1)
            setBundleId(bundle_id)
            setTotalPageNoOfPatient(result)
        } catch (e) {
            // notification('An error occurred, please try again!', 'error');
        }
    }
    const handleScroll = async () => {
        if (totalPageNoOfPatient > currentPage) {
            const scrollableContent = scrollableContentRef?.current
            const tolerance = 2
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd) {
                try {
                    const nextPage = currentPage + 1
                    const payload = {
                        page: nextPage,
                        bundle_id: bundleId,
                        query: searchTextOffilter.patientSearch,
                    }
                    const res = await _getClinicalNotesFilterPatient(payload)
                    const tempUserList = []
                    res?.data?.data.map((item) =>
                        tempUserList.push({
                            label: `${item.first_name}${
                                item.middle_name ? ' ' + item.middle_name : ''
                            } ${item.last_name}`,
                            value: item?.id,
                        }),
                    )
                    // let sortedArray = tempUserList.sort((item1, item2) => item1.label.localeCompare(item2.label));
                    let sortedPatientData = [...patientData, ...tempUserList]
                    // sortedPatientData.sort((item1, item2) => item1.label.localeCompare(item2.label))
                    setPatientData(sortedPatientData)
                    setCurrentPage(nextPage)
                    setBundleId(res?.data?.bundle_id)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
            }
        }
    }
    const handleLoadDataNote = (event) => {
        const input = event
        setSearchTextOffilter((old) => ({
            ...old,
            documentSearch: input,
        }))
        if (input === '') {
            setDocument(DOCUMENT_TYPE)
        } else {
            const filtered = DOCUMENT_TYPE.filter((item) =>
                item.value.toLowerCase().includes(input.toLowerCase()),
            )
            setDocument(filtered)
        }
    }
    const handleChipDelete = (name) => {
        if (name === 'selectedPatient') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedDocument') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'document')
            setTotalNoOfFilterUsed(newData)
        }
        setSelectedUser((selectedUser) => ({
            ...selectedUser,
            [name]: [],
        }))
    }
    const handleChipDeleteAll = () => {
        setSelectedUser({
            selectedPatient: [],
            selectedDocument: [],
        })
        setTotalNoOfFilterUsed([])
    }
    useEffect(() => {
        handleLoadData()
    }, [debouncedSearchFilterText])
    const checkboxHandle = (event, user_id, name) => {
        if (name === 'selectedPatient') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedPatient.includes(user_id)) {
                    data = [...selectedUser.selectedPatient, user_id]
                }
            } else {
                const filtered = selectedUser.selectedPatient.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('patient')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'patient'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
                setTotalNoOfFilterUsed(newData)
            }
        } else if (name === 'selectedDocument') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedDocument.includes(user_id)) {
                    data = [...selectedUser.selectedDocument, user_id]
                }
            } else {
                const filtered = selectedUser.selectedDocument.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('document')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'document'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'document')
                setTotalNoOfFilterUsed(newData)
            }
        }
    }

    const permissions = useSelector((state) => state?.permission?.user_security_accounts)

    function userSecurityModalToggle() {
        setUserSecurityModal({
            status: !userSecurityModal.status,
            itemId: null,
        })
    }
    function changePassDialogToggle() {
        setOpenChangePassDialog(!openChangePassDialog)
    }

    function onChangeFilter(e) {
        const { name, value } = e.target
        setFilterData({ ...filterData, [name]: value })
    }

    useEffect(() => {
        if (patientList.length > 0 && selectedRows.length === patientList.length) {
            setSelectAll(true)
        }
    }, [selectedRows, setSelectedRows])

    function selectSingleRow(event, id) {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id))
            setSelectAll(false)
        } else {
            setSelectedRows([...selectedRows, id])
        }

        if (event.target.checked && selectedRows.length === patientList.length) {
            setSelectAll(true)
        }
    }

    function handleSelectAllCheckBoxChange() {
        if (selectAll) {
            setSelectedRows([])
        } else {
            setSelectedRows(patientList.map((patient) => patient.id))
        }

        setSelectAll(!selectAll)
    }

    async function downloadFileList() {
        await downloadFileListAll({
            selectedRows,
            setLoading,
            setSelectAll,
            setSelectedRows,
            patientList,
        })
        // const downloadRows = [...selectedRows]
        // setSelectedRows([])
        // setLoading(true)
        // setSelectAll(false)

        // const fileList = patientList.map(((row) => downloadRows.includes(row.id) ? row : null)).filter(row => row)
        // const fileListPromise = fileList.map(async (file) => getDocumentById(file.id))
        // const fileDocumentListResponse = await Promise.all(fileListPromise)
        // const fileDocumentUrlList = fileDocumentListResponse.map((file) => file?.data?.url)
        // const urlFetchListPromise = fileDocumentUrlList.map((urlFile) => fetch(urlFile))
        // const urlFetchList = await Promise.all(urlFetchListPromise)

        // const blobFileListPromise = urlFetchList.map(fetchList => fetchList.blob())
        // const blobFileList = await Promise.all(blobFileListPromise)
        // const zip = new JSZip()

        // let i = 0;
        // for (const blobInfo of blobFileList) {
        //     zip.file(fileDocumentListResponse[i]?.data?.key, blobInfo);
        //     i++;
        // }

        // const generatedZipBlob = await zip.generateAsync({ type: 'blob' })

        // const downloadLink = document.createElement('a')
        // downloadLink.href = URL.createObjectURL(generatedZipBlob)
        // downloadLink.download = 'output.zip'
        // downloadLink.click()

        // setLoading(false)
    }

    const columns = [
        {
            headerName: <Box fontWeight={'bold'}>Document Name</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'document_name',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value || 'N/a'
            },
        },
        {
            headerName: <Box fontWeight="bold">Document Type</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'document_type',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value || 'N/a'
            },
        },
        {
            headerName: <Box fontWeight="bold">Source Type</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'source_type',
            flex: 0.75,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value || 'N/a'
            },
        },
        {
            headerName: <Box fontWeight="bold">Associated Patient</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'first_name',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return `${row?.associated_patient_first_name || ''} ${
                    row?.associated_patient_last_name || ''
                }`
            },
        },
        // {
        //     headerName: <Box fontWeight="bold">Encounter</Box>,
        //     headerClassName: 'super-app-theme--header',
        //     field: 'email',
        //     flex: 1,
        //     sortable: true,
        //     renderCell: ({ value, row }) => {
        //         return row?.encounter?.start
        //             ? `${moment(row?.encounter?.start).format('DD MMM YYYY') || ''} -- ${
        //                   moment(row?.encounter?.start).format('hh:mm A') || ''
        //               } - ${moment(row?.encounter?.end).format('hh:mm A')}`
        //             : 'N/A'
        //     },
        // },
        {
            headerName: <Box fontWeight="bold">Description</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'description',
            flex: 1.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value || 'N/a'
            },
        },
        {
            headerName: <Box fontWeight="bold">Upload By</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'role',
            // width: 100,
            flex: 0.75,
            sortable: true,
            renderCell: ({ value, row }) => {
                return `${row?.upload_by_first_name || ''} ${row?.upload_by_last_name || ''}`
            },
        },
        {
            headerName: <Box fontWeight="bold">Upload Date</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'upload_date',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return moment(value).format('DD MMM YYYY hh:mm A')
            },
        },
        {
            headerName: <Box fontWeight="bold">Actions</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'id',
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return renderActions(
                    params,
                    {
                        handleEditClick,
                        refetchAccount,
                    },
                    permissions,
                    notification,
                )
            },
        },
        {
            headerName: (
                <Box fontWeight="bold">
                    <Checkbox
                        color="primary"
                        disabled={isLoading}
                        checked={selectAll}
                        onChange={() => {
                            handleSelectAllCheckBoxChange()
                        }}
                    />
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'checkbox',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <Checkbox
                    color="primary"
                    disabled={isLoading}
                    checked={selectedRows.includes(params.id)}
                    onChange={(event) => {
                        selectSingleRow(event, params.id)
                    }}
                />
            ),
        },
    ]
    const handleEditClick = (params) => {
        setUserSecurityModal({
            status: !userSecurityModal.status,
            itemId: params?.row?.id,
        })
    }

    async function handleCreateUser(payload) {
        setIsSubmitting(true)
        createDocument(payload)
            .then(() => {
                userSecurityModalToggle()
                notification('Document  created successfully!', 'success')
                refetchAccount()
            })
            .catch((error) => {
                notification(
                    error?.data?.message || "Document  didn't create successfully!",
                    'error',
                )
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    const handleClearFilter = () => {
        refetchAccount({
            page: 1,
            name: '',
            role: '',
            document_type: '',
        })
        setFilterData({
            role: '',
            document_type: '',
        })
        setTextSearch('')
    }

    const refetchAccount = async (data) => {
        setLoading(true)
        try {
            const res = await getDocumentList(
                data || {
                    page,
                    limit,
                    search: textSearch.trim(),
                    ...filterData,
                },
            )
            setLoading(false)
            setPatientList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        ;(async () => {
            if (!mounted) return setMounted(true)
            console.log('selectedUser', selectedUser.selectedDocument)
            if (page !== 1) return setPage(1)
            const abortController = new AbortController()
            await refetchAccount({
                page: 1,
                limit,
                search: textSearch.trim(),
                document_type: selectedUser.selectedDocument,
                associated_patient: selectedUser.selectedPatient,
                ...filterData,
            })
            return () => abortController.abort()
        })()
    }, [, textSearch, filterData, selectedUser])

    useEffect(() => {
        refetchAccount()
    }, [page])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Provider Document Management</Typography>
                    <Grid item>
                        <Grid container>
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                style={{ flex: 1 }}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        gap: 8,
                                        marginRight: 16,
                                        flex: 1,
                                    }}>
                                    <Grid
                                        item
                                        style={{
                                            maxWidth: 280,
                                            // flex: 1,
                                        }}>
                                        <TextField
                                            className={clsx({
                                                [classes.search_input]: true,
                                                // [classes.show_search]: !openSearch,
                                            })}
                                            placeholder="Search..."
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            value={textSearch}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton size="small">
                                                            <img src={SearchIcon} alt=""></img>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {textSearch && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setTextSearch('')}>
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                    root: classes.search_input_root,
                                                },
                                            }}
                                        />
                                    </Grid>

                                    {/* <Grid
                                        item
                                        style={{
                                            flex: 1,
                                        }}>
                                        <SelectEl
                                            name="document_type"
                                            value={filterData.document_type}
                                            options={DOCUMENT_TYPE}
                                            onChange={onChangeFilter}
                                            style={{
                                                height: 32,
                                                minHeight: 32,
                                                maxWidth: 160,
                                            }}
                                            renderValue={(sel) => {
                                                return typeof sel === 'string' && !sel ? (
                                                    <Typography className={classes.colorContent}>
                                                        Document Type
                                                    </Typography>
                                                ) : (
                                                    <Typography
                                                        className={clsx(
                                                            classes.ellipsis__text,
                                                            classes.colorContent,
                                                        )}>
                                                        {
                                                            DOCUMENT_TYPE.find(
                                                                (item) => item.key === sel,
                                                            )?.value
                                                        }
                                                    </Typography>
                                                )
                                            }}
                                        />
                                    </Grid> */}

                                    {(textSearch ||
                                        filterData.role ||
                                        filterData.document_type) && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={clsx(
                                                    classes.btn_add,
                                                    classes.btn_search,
                                                )}
                                                onClick={handleClearFilter}
                                                fullWidth>
                                                Clear
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    onClick={handleFilterClick}
                                    variant="contained"
                                    classes={{
                                        root: classes.filter_btn,
                                    }}>
                                    <FilterIcon />
                                    <span
                                        style={{
                                            marginLeft: 6,
                                        }}>
                                        Filters
                                    </span>
                                    {totalNoOfFilterUsed.length > 0 && (
                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                fontWeight: 'normal',
                                                borderRadius: '20px',
                                                background: 'white',
                                                color: '#5571C6',
                                                padding: '6px',
                                                marginLeft: 6,
                                                height: '10px',
                                            }}>
                                            {totalNoOfFilterUsed.length}
                                        </span>
                                    )}
                                </LoadingButton>

                                <Popover
                                    id={id}
                                    open={openFilter}
                                    anchorEl={filterEl}
                                    onClose={handleFilterClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <div className="popover_card_holder">
                                        <div className="heading_holder">
                                            <h2 className="heading">Filter By:</h2>
                                            <p
                                                className="clear_holder"
                                                onClick={handleChipDeleteAll}>
                                                Clear All Filters
                                            </p>
                                        </div>
                                        <div className="cstm_accordion_holder">
                                            <Accordion>
                                                <AccordionSummary
                                                    className="summary_holder"
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography className="acc_txt">
                                                        Patient
                                                    </Typography>
                                                    {selectedUser?.selectedPatient?.length > 0 ? (
                                                        <Chip
                                                            label={
                                                                selectedUser?.selectedPatient
                                                                    ?.length
                                                            }
                                                            onDelete={() =>
                                                                handleChipDelete('selectedPatient')
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails className="details_holder">
                                                    <div>
                                                        <div className="search_holder">
                                                            <FormControl
                                                                sx={{ m: 1, width: '25ch' }}
                                                                variant="filled">
                                                                <FilledInput
                                                                    id="filled-adornment-password"
                                                                    placeholder="Search..."
                                                                    value={
                                                                        searchTextOffilter?.patientSearch
                                                                    }
                                                                    onChange={(e) =>
                                                                        setSearchTextOffilter(
                                                                            (old) => ({
                                                                                ...old,
                                                                                patientSearch:
                                                                                    e.target.value,
                                                                            }),
                                                                        )
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton edge="end">
                                                                                <img
                                                                                    src={SearchIcon}
                                                                                    alt="icon"></img>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div
                                                            className="scrollable_content"
                                                            ref={(ref) =>
                                                                (scrollableContentRef.current = ref)
                                                            }
                                                            onScroll={handleScroll}>
                                                            {patientData.map((item, index) => {
                                                                return (
                                                                    <FormGroup key={index}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={(
                                                                                        event,
                                                                                    ) =>
                                                                                        checkboxHandle(
                                                                                            event,
                                                                                            item?.value,
                                                                                            'selectedPatient',
                                                                                        )
                                                                                    }
                                                                                    checked={selectedUser?.selectedPatient?.includes(
                                                                                        item?.value,
                                                                                    )}
                                                                                />
                                                                            }
                                                                            label={item?.label}
                                                                        />
                                                                    </FormGroup>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <div className="cstm_accordion_holder">
                                            <Accordion>
                                                <AccordionSummary
                                                    className="summary_holder"
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header">
                                                    <Typography className="acc_txt">
                                                        Document
                                                    </Typography>
                                                    {selectedUser?.selectedDocument?.length > 0 ? (
                                                        <Chip
                                                            label={
                                                                selectedUser?.selectedDocument
                                                                    ?.length
                                                            }
                                                            onDelete={() =>
                                                                handleChipDelete('selectedDocument')
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </AccordionSummary>
                                                <AccordionDetails className="details_holder">
                                                    <div>
                                                        <div className="search_holder">
                                                            <FormControl
                                                                sx={{ m: 1, width: '25ch' }}
                                                                variant="filled">
                                                                <FilledInput
                                                                    id="filled-adornment-password"
                                                                    placeholder="Search..."
                                                                    value={
                                                                        searchTextOffilter?.documentSearch
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleLoadDataNote(
                                                                            e.target.value,
                                                                        )
                                                                    }
                                                                    endAdornment={
                                                                        <InputAdornment position="end">
                                                                            <IconButton edge="end">
                                                                                <img
                                                                                    src={SearchIcon}
                                                                                    alt="icon"></img>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                />
                                                            </FormControl>
                                                        </div>
                                                        <div className="scrollable_content">
                                                            {document.map((item, index) => {
                                                                return (
                                                                    <FormGroup key={index}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    onChange={(
                                                                                        event,
                                                                                    ) =>
                                                                                        checkboxHandle(
                                                                                            event,
                                                                                            item?.key,
                                                                                            'selectedDocument',
                                                                                        )
                                                                                    }
                                                                                    checked={selectedUser?.selectedDocument?.includes(
                                                                                        item.key,
                                                                                    )}
                                                                                />
                                                                            }
                                                                            label={item?.value}
                                                                        />
                                                                    </FormGroup>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </div>
                                </Popover>
                            </Grid>
                            {/* <RBACProvider
                                permissions={permissions}>
                                <RBACWrapper
                                    requiredPermissions={[
                                        'create_user_security_accounts',
                                    ]}> */}
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Account"
                                    variant="contained"
                                    color="primary"
                                    onClick={userSecurityModalToggle}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    maxWidth: 160,
                                }}>
                                {selectedRows.length === 0 ? (
                                    <img
                                        src={Download_img}
                                        style={{ width: '36px', height: '36px', cursor: 'pointer' }}
                                    />
                                ) : (
                                    <img
                                        onClick={() => downloadFileList()}
                                        src={DownloadBlue_img}
                                        style={{ width: '36px', height: '36px', cursor: 'pointer' }}
                                    />
                                )}
                            </Grid>
                            {/* </RBACWrapper>
                            </RBACProvider> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                            backgroundColor: 'unset !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: CustomPagination,
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        componentsProps={{
                            pagination: {
                                currentPage: page,
                                onPageChange,
                                total,
                                numberRecord: limit,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                currentPage: page,
                                total,
                                numberRecord: limit,
                                pageSize: limit,
                            },
                        }}
                    />
                </Box>
            </Grid>
            {userSecurityModal.status && (
                <CreateDocumentDialog
                    open={userSecurityModal.status}
                    itemId={userSecurityModal.itemId}
                    loading={isSubmitting}
                    onModalClose={userSecurityModalToggle}
                    onSubmit={handleCreateUser}
                    changePassDialogToggle={changePassDialogToggle}
                />
            )}

            {Boolean(alertErrorOpen) && (
                <AlertErrorDialog
                    message={alertErrorOpen}
                    open={Boolean(alertErrorOpen)}
                    handleClose={() => {
                        setAlertErrorOpen(false)
                    }}
                />
            )}
        </Grid>
    )
}

async function downloadFileListAll({
    selectedRows,
    setSelectedRows,
    setLoading,
    setSelectAll,
    patientList,
}) {
    setLoading(true)
    const downloadRows = [...selectedRows]

    const fileList = patientList
        .map((row) => (downloadRows.includes(row.id) ? row : null))
        .filter((row) => row)
    const fileListPromise = fileList.map(async (file) => getDocumentById(file.id))
    const fileDocumentListResponse = await Promise.all(fileListPromise)
    const fileDocumentUrlList = fileDocumentListResponse.map((file) => file?.data?.url)
    const urlFetchListPromise = fileDocumentUrlList.map((urlFile) => fetch(urlFile))
    const urlFetchList = await Promise.all(urlFetchListPromise)

    const blobFileListPromise = urlFetchList.map((fetchList) => fetchList.blob())
    const blobFileList = await Promise.all(blobFileListPromise)
    const zip = new JSZip()

    let i = 0
    for (const blobInfo of blobFileList) {
        zip.file(fileDocumentListResponse[i]?.data?.key, blobInfo)
        i++
    }

    const generatedZipBlob = await zip.generateAsync({ type: 'blob' })

    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(generatedZipBlob)
    downloadLink.download = 'output.zip'
    downloadLink.click()

    setSelectedRows([])
    setSelectAll(false)
    setLoading(false)
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Documents)
