import React, { useMemo, useState, useEffect } from 'react'
import { ModalEl } from 'shared/elements'
import moment from 'moment'
import 'moment-timezone'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useStyles } from './styles'
import { getSchedule } from 'services/Calendar'
import { TIME_FORMAT, VIEW } from 'containers/CalendarScheduler/constant'
import { DayCalendarEvent } from 'containers/CalendarScheduler/EventCard/components'
import { WeekHeaderCellContent } from 'containers/CalendarScheduler/CalendarHeader'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { FormikProps } from 'formik'
import { Button } from '@material-ui/core'
import { uniqBy } from 'lodash'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'

interface PropsViewDayPicker {
    values?: any
    date?: Date
    open?: boolean
    onClose?: () => void
    onOke?: () => void
    setFieldValue?: any
    idProvider?: string | number
}

export const ViewDayPicker = (props: PropsViewDayPicker) => {
    const { date = moment(), open, onClose, onOke, setFieldValue, idProvider, values } = props
    const classes = useStyles()

    //State
    const [data, setData] = useState([])
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)
    const { defaultDate, localizer } = useMemo(
        () => ({
            defaultDate: moment(date),
            localizer: momentLocalizer(moment),
        }),
        [date],
    )

    async function getScheduledSlotsCount() {
        try {
            setLoadingSchedule(true)
            const resp_by_provider = await getSchedule({
                status: '',
                visitType: '',
                location: '',
                practitioner_id: idProvider ? idProvider : '',
                startTime: date
                    ? moment(date).startOf('day').utc().format()
                    : moment().startOf('day').utc().format(),
                endTime: date
                    ? moment(date).endOf('day').utc().format()
                    : moment().endOf('day').utc().format(),
            })
            if (values.appointment_type === 'Office Visit') {
                const resp_by_location = await getSchedule({
                    status: '',
                    visitType: '',
                    location: values.location ? values.location : '',
                    practitioner_id: '',
                    startTime: date
                        ? moment(date).startOf('day').utc().format()
                        : moment().startOf('day').utc().format(),
                    endTime: date
                        ? moment(date).endOf('day').utc().format()
                        : moment().endOf('day').utc().format(),
                })

                // let appointments_off = resp_by_location.data.filter(
                //     (appointment) => appointment.appointmentType === 'Office Visit',
                // )

                let appointments_off = resp_by_location?.data?.calendar

                //khi response data có office visit thì room_id === location_id
                if (values.room) {
                    appointments_off = appointments_off.filter(
                        (i) => `${i.location_id}` === `${values.room}`,
                    )
                }

                const listAppointmentByRoom = uniqBy(
                    [...resp_by_provider?.data?.calendar, ...appointments_off],
                    function (e) {
                        return e.id
                    },
                )
                const dataRoom = listAppointmentByRoom.map((appt) => ({
                    ...appt,
                    end: new Date(appt.end),
                    start: new Date(appt.start),
                }))

                setData(dataRoom as any)
            } else {
                setData((prev) => {
                    const data = resp_by_provider?.data?.calendar?.map((appt) => ({
                        ...appt,
                        end: new Date(appt.end),
                        start: new Date(appt.start),
                    }))
                    return data
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        getScheduledSlotsCount()
    }, [idProvider])

    return (
        <ModalEl
            width={'700'}
            title={'Choose a time for the appointment'}
            description="Booking Appointment Details"
            open={open || false}
            backdropClick
            onClose={() => onClose?.()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            {loadingSchedule && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                    <CircularProgress />
                </Box>
            )}
            <Box className={classes['wrapDay']}>
                <Box className={'close-viewday'} onClick={() => onClose?.()}>
                    <HighlightOffIcon />
                </Box>
                <Calendar
                    minDate={new Date()}
                    defaultDate={defaultDate}
                    events={data}
                    localizer={localizer}
                    selectable="ignoreEvents"
                    showMultiDayTimes
                    formats={{
                        eventTimeRangeFormat: () => null,
                        eventTimeRangeStartFormat: () => null,
                        eventTimeRangeEndFormat: () => null,
                        dayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'dddd', culture),
                        timeGutterFormat: (date, culture, localizer) =>
                            localizer.format(date, 'hh:mm a', culture),
                        dayHeaderFormat: (date, culture, localizer) =>
                            localizer.format(date, 'MM/DD/YYYY', culture),
                        monthHeaderFormat: (date, culture, localizer) =>
                            localizer.format(date, 'MMMM', culture),
                        weekdayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'dddd', culture),
                    }}
                    endAccessor={({ end }) => {
                        if (moment(end).format(TIME_FORMAT) === '00:00:00') {
                            return new Date(end.getTime() - 1)
                        }
                        return new Date(end.getTime())
                    }}
                    step={15}
                    popup={true}
                    onSelectSlot={(e) => {
                        console.log(e)
                        if (moment(e.start).valueOf() - moment().valueOf() > 0) {
                            const duration = Math.floor(
                                (moment(e.end).valueOf() - moment(e.start).valueOf()) / (1000 * 60),
                            )

                            setFieldValue('start', moment(e.start))
                            setFieldValue('end', moment(e.end))
                            setFieldValue('duration', duration)
                            onOke?.()
                        } else {
                            setAlerEmtyForm(true)
                        }
                    }}
                    views={{
                        day: true,
                    }}
                    view={'day'}
                    components={{
                        [VIEW.DAY]: {
                            header: WeekHeaderCellContent,
                            event: DayCalendarEvent,
                        },
                    }}
                />
            </Box>
            <AlertEmtyForm
                title="Can't make an appointment in the past!"
                description={"Can't make an appointment in the past!"}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </ModalEl>
    )
}
