import React, { Component, memo, useEffect, useState } from 'react'
import MomentUtils from '@date-io/moment'
import { ClickAwayListener, FormLabel, Grid, IconButton, makeStyles } from '@material-ui/core'
import { KeyboardDatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment, { Moment } from 'moment'

import './style.scss'

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        // top: 44,
        // left: 0,
        background: '#FFFFFF',
        // boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        padding: 16,
        zIndex: 1,
        display: 'block',
        width: 'fit-content',
        boxShadow: '1px 1px 3px 1px rgb(6 6 6 / 15%)',
        // top: '-60px',
        // left: '50%',
        // transform: 'translateX(-50%)'
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
    custom: {
        '.MuiButton-root': {
            backgroundColor: 'transparent',
        },
    },
}))

interface Props {
    value?: Moment | Date | string | undefined | null
    label?: string
    required?: boolean
    noAway?: boolean
    notDefaultValue?: boolean
    onChange?: (date: Moment | Date | string | undefined | null) => void,
    icon: Component
}

const DatePicker = memo(
    ({ value, label, required, notDefaultValue, icon, ...props }: Props & DatePickerProps) => {
        const [date, setDate] = useState<Moment | Date | string | undefined | null>(
            value || notDefaultValue ? null : new Date(),
        )

        const handleClickAway = () => {
            if (!props.noAway) {
                props.onChange(date as Date)
            }
        }
        useEffect(() => {
            value && setDate(value)
        }, [value])

        return (
            <div className="fff-datepicker">
                {label && (
                    <FormLabel
                        component="p"
                        className="input-form-label"
                        style={{ fontSize: 14, fontWeight: 500 }}>
                        <span className={!!required ? 'required' : ''}>{label}</span>
                    </FormLabel>
                )}

                <ClickAwayListener onClickAway={handleClickAway}>
                    <Grid container item xs={12}>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                            <KeyboardDatePicker
                                {...props}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                format={props.format || 'MM/DD/YYYY'}
                                // disableToolbar
                                // openTo="year"
                                value={date}
                                error={props.error}
                                helperText={
                                    <div
                                        style={{
                                            textTransform: 'initial',
                                        }}>
                                        {props.helperText}
                                    </div>
                                }
                                placeholder="MM/DD/YYYY"
                                onChange={(_, value) => {
                                    setDate(value)
                                    props.onChange && props.onChange(value)
                                }}
                                keyboardIcon={icon}
                            />
                        </MuiPickersUtilsProvider>

                        {/* {required && props.error && props.helperText &&
              <FormHelperText className='helper-text'>{props.helperText}</FormHelperText>} */}
                    </Grid>
                </ClickAwayListener>
            </div>
        )
    },
)

export { DatePicker }
export default DatePicker
