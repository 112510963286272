import React, { Suspense, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import { getMedicalProblemList } from 'services/Patient/patient'
import { useHistory, useParams } from 'react-router-dom'

import { formatDateTime } from 'utilities/formats'
import Routes from 'constants/routes'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'

import { MedicalProblem, Patient } from '../interfaces'
import { Card } from '../view/CardHealthRecord'
import { usePatientDashboardStyles } from '../style'
import { MedicalProblemForm } from '../view/MedicalProblems/NewProblem'
import { NoData } from './NoData'
import { usePatient } from '../hooks'

interface Props {
    patientInfo: Patient
    patientId?: string
    isAddingFromClinicalNote?: boolean
    encounter_id?: string
    onClick?: () => void
    viewAllFunc?: () => void
}

export const MedicalProblems = (props: Props) => {
    const { patientInfo } = usePatient(props.patientId)
    const [patientId, setPatientId] = useState('')

    const classes = usePatientDashboardStyles()
    const [bundleId, setBundleId] = useState('')

    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [medicalProblems, setMedicalProblems] = useState<MedicalProblem[]>([])
    const [page, setPage] = useState(1)
    const [pageSize] = useState(5)
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) fetchMedialProblems()

        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    const fetchMedialProblems = async () => {
        // @TODO: Consider to implement show more list or pagination
        try {
            setLoading(true)
            const resp = await getMedicalProblemList(patientId, page, pageSize, bundleId)
            if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)

            setMedicalProblems(resp.data.data as MedicalProblem[])
        } catch (ex) {
            console.error('[Medical Problem] cannot fetch medial problem list', ex)
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.MEDICAL_PROBLEMS.replace(':id', patientId))

    const Content = () => (
        <>
            {medicalProblems.map((mb) => (
                <Grid
                    container
                    key={mb.id}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #E4E8EE',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        direction="column"
                        alignItems="flex-start"
                        style={{
                            width: 'fit-content',
                        }}>
                        <Typography>
                            {/* <span
                      className={
                        classes.title_clinical_reminder
                      }>
                      Assessment:
                  </span>{' '} */}
                            {mb.icd_code?.code +
                                (mb.icd_code?.display ? ' - ' + mb.icd_code?.display : '')}
                        </Typography>

                        <Typography
                            style={{
                                color: '#9CA9B8',
                                fontSize: 12,
                            }}>
                            {formatDateTime(mb.time_onset, 'MMM DD, YYYY')}
                        </Typography>
                    </Grid>

                    <Typography className={classes[`tag_type_${mb.status?.code}`]}>
                        {mb.status?.code}
                    </Typography>
                </Grid>
            ))}

            {!!medicalProblems.length && (
                <Grid container item justifyContent="flex-end">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            viewAllHandler={props.viewAllFunc || handleViewAll}
            addHandler={() => setIsAddingNew(true)}
            onClick={props.onClick}
            header="Medical Problems">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        padding: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {medicalProblems.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            {!!isAddingNew && (
                <MedicalProblemForm
                    isAddingFromClinicalNote={props.isAddingFromClinicalNote}
                    encounter_id={props.encounter_id}
                    patientId={patientId}
                    open={isAddingNew}
                    onClose={(forceReload) => {
                        setIsAddingNew(false)

                        if (forceReload) setTimeout(() => fetchMedialProblems(), 800)
                    }}
                />
            )}
        </Card>
    )
}
