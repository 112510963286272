import React from 'react'
import {
    getCPTCodeMedicalCoding,
    getHCPCSCodeMedicalCoding,
} from 'containers/ClinicalNotes/api/services'
import { getICD10Code } from 'services/Patient/patient'
import { SearchBox } from 'shared/elements/FormItem/SearchBox'

export interface ICD10 {
    _index?: string
    _type?: string
    _id?: string
    _score?: number
    _source?: {
        code?: string
        sort_desc?: string
        long_desc?: string
    }
    highlight?: {
        sort_desc?: string[]
        long_desc?: string[]
        code?: string
    }
}

export interface CPT {
    _index?: string
    _type?: string
    _id?: string
    _score?: number
    _source?: {
        conceptId?: string
        cptCode?: string
        clinicianDescriptorId?: string
        clinicianDescriptor?: string
    }
    highlight?: {
        clinicianDescriptor?: string[]
    }
}

export interface HCPCS {
    _index?: string
    _type?: string
    _id?: string
    _score?: number
    _source?: {
        code?: string
        sort_desc?: string
        long_desc?: string
    }
    highlight?: {
        sort_desc?: string[]
        code?: string[]
    }
}

async function searchICD10(text): Promise<ResponseDTO[]> {
    const payload = {
        size: 25,
        sort: [
            "_score",
            {
              _id: {
                order: "asc"
              }
            }
        ],
        query: {
            multi_match: {
                query: text,
                type: 'bool_prefix',
                fields: [
                    'code',
                    'code._2gram',
                    'code._3gram',
                    'sort_desc',
                    'sort_desc._2gram',
                    'sort_desc._3gram',
                    'long_desc',
                    'long_desc._2gram',
                    'long_desc._3gram',
                ],
            },
        },
        highlight: {
            pre_tags: ['<em>'],
            post_tags: ['</em>'],
            fields: {
                long_desc: {
                    number_of_fragments: 1,
                    fragment_size: 100,
                },
                code: {
                    number_of_fragments: 1,
                    fragment_size: 6,
                },
                sort_desc: {
                    number_of_fragments: 1,
                    fragment_size: 100,
                },
            },
        },
    }

    const resp = await getICD10Code(payload)
    const data = resp.data.hits.hits as ICD10[]

    return data.map((item) => ({
        code: item._source?.code,
        display: item._source?.long_desc || item._source?.sort_desc,
        highlightText: item.highlight?.code
            ? `${item._source?.long_desc || item._source?.sort_desc}`
            : `${item.highlight?.long_desc?.join(' ') || ''}`,
    }))
}

async function searchCPT(text): Promise<ResponseDTO[]> {
    const payload = {
        size: 25,
        query: {
            multi_match: {
                query: text,
                type: 'bool_prefix',
                fields: [
                    'cptCode',
                    'cptCode._2gram',
                    'cptCode._3gram',
                    'clinicianDescriptor',
                    'clinicianDescriptor._2gram',
                    'clinicianDescriptor._3gram',
                ],
            },
        },
        highlight: {
            pre_tags: ['<em class="text-hightlight">'],
            post_tags: ['</em>'],
            fields: {
                cptCode: {
                    number_of_fragments: 1,
                    fragment_size: 20,
                },
                clinicianDescriptor: {
                    number_of_fragments: 3,
                    fragment_size: 50,
                },
            },
        },
    }

    const resp = await getCPTCodeMedicalCoding(payload)
    const data = resp.data.hits.hits as CPT[]

    return data.map((item) => ({
        code: item._source?.cptCode,
        display: item.highlight?.clinicianDescriptor?.[0] || item._source?.clinicianDescriptor,
        highlightText:
            item.highlight?.clinicianDescriptor?.[0] || item._source?.clinicianDescriptor,
    }))
}

async function searchHCPCS(text): Promise<ResponseDTO[]> {
    const payload = {
        size: 25,
        query: {
            multi_match: {
                query: text,
                type: 'bool_prefix',
                fields: [
                    'code',
                    'code._2gram',
                    'code._3gram',
                    'sort_desc',
                    'sort_desc._2gram',
                    'sort_desc._3gram',
                ],
            },
        },
        highlight: {
            pre_tags: ['<em class="text-hightlight">'],
            post_tags: ['</em>'],
            fields: {
                code: {
                    number_of_fragments: 1,
                    fragment_size: 5,
                },
                sort_desc: {
                    number_of_fragments: 2,
                    fragment_size: 20,
                },
            },
        },
    }

    const resp = await getHCPCSCodeMedicalCoding(payload)
    const data = resp.data.hits.hits as HCPCS[]

    return data.map((item) => ({
        code: item._source?.code,
        display: item._source?.sort_desc || item._source?.long_desc,
        highlightText:
            item.highlight?.sort_desc?.[0] ||
            item.highlight?.code?.[0] ||
            item._source?.sort_desc ||
            item._source?.long_desc,
    }))
}

type ResponseDTO = {
    code?: string
    display?: string
    highlightText?: string
    key?: React.Key
}

interface Props {
    label?: string
    required?: boolean
    variant?: 'ICD-10' | 'CPT' | 'HCPCS'
    name?: string
    value?: {
        code?: string
        display?: string
    }
    placeholder?: string
    error?: boolean
    notWarningSearch?: boolean
    helperText?: string | boolean
    getOptionLabel?: (option: ResponseDTO) => string
    onChange: (value: ResponseDTO | null) => void
    isHelpIcon?: boolean
    icd10Arr?: []
}

const SearchICD10Code = (props: Props) => {
    const { value, variant = 'ICD-10', onChange, notWarningSearch, ...rest } = props

    const getSearchHandler = () =>
        variant === 'CPT' ? searchCPT : variant === 'HCPCS' ? searchHCPCS : searchICD10

    return (
        <SearchBox<ResponseDTO>
            key={variant}
            onChange={(option) => onChange && onChange(option)}
            value={value}
            getOptionLabel={(option) => (option?.display as string) || ''}
            renderKey="code"
            searchHandler={getSearchHandler()}
            isOptionEqualToValue={(option, value) =>
                value?.code === option?.code || value?.display === option?.display
            }
            renderOption={(option) => `${option?.code} - ${option?.highlightText}`}
            notWarningSearch={notWarningSearch}
            {...rest}
        />
    )
}

export { SearchICD10Code }
export default React.memo(SearchICD10Code)
