import { Box, Button, Dialog, Divider, Grid, createStyles, makeStyles } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import cross_icon from '../../../../assests/icons/cross_icon.svg'
import peopleGroup from '../../../../assests/icons/people.svg'
import { useNotification } from 'shared/elements/Notification'
// import { useNotification } from '../../../Appointments/MaterialUi/Notification'
const useStyles = makeStyles((theme) =>
    createStyles({
        root1: {
            '& .MuiPaper-root': {
                // height: '336px',
                position: 'relative',
            },
            '& .MuiDialogContent-root': {
                overflow: 'auto',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '481px',
            },
        },
        modalBody: {
            // width: '481px',
            // height: '481px',
            background: 'white',
            borderColor: '#FFF',
            // paddingLeft: '26px',
            paddingRight: '17px',
            borderRadius: '8px',
            overflowY: 'auto',
            '&:focus': {
                outline: 'none', // Remove the focus outline
            },
        },
        label2: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        labelStyle: {
            color: ' #303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%' /* 22.4px */,
            marginBottom: '10px',
        },
        typoGraphy: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        select: {
            '& .MuiTypography-root': {
                color: '#303E4E !important',
            },
        },
        labletxt: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '20px' /* 125% */,
        },
    }),
)

const UncheckSharedModal = ({
    isOpenModalContact,
    contactModalClose,
    patientInfo,
    SubmitFunction,
    isLoading,
    heading,
    text = '',
    height,
}) => {
    const notification = useNotification()
    const classes = useStyles()

    const handleSubmit = () => {
        SubmitFunction()
    }

    const cancelModal = () => {
        contactModalClose()
    }
    return (
        <Dialog
            style={{ pointerEvents: isLoading ? 'none' : '' }}
            open={isOpenModalContact}
            fullWidth
            className={classes.root1}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '780px',
                },
            }}>
            <Box className={classes.modalBody} style={{ height: height || '481px' }}>
                {' '}
                {/* Set width */}
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <Box style={{ marginTop: '15px' }}>
                        <img
                            src={cross_icon}
                            style={{ cursor: 'pointer' }}
                            onClick={contactModalClose}
                        />
                    </Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <Box className={classes.typoGraphy}>{heading}</Box>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // border:"2px solid green"
                        // alignItems: 'center',
                    }}>
                    <img src={peopleGroup} />
                </Box>
                <Grid container style={{ marginTop: '7px' }}>
                    <Grid style={{ marginLeft: '44px', marginRight: '12px' }}>
                        <span className={classes.labletxt}>{text}</span>
                        <br />
                        <br />
                        <span className={classes.labletxt}>Are you sure you want to do that?</span>
                    </Grid>

                    <Grid item xs={12} style={{ paddingLeft: '15px' }}>
                        <Divider style={{ marginTop: '8px', color: '#EAECF0' }} />
                    </Grid>

                    <Grid
                        container
                        item
                        spacing={2}
                        style={{ marginTop: '7px', marginLeft: '44px', marginRight: '12px' }}>
                        <Grid item xs={6} style={{ marginTop: '3px' }}>
                            <Button
                                variant="contained"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '192px',
                                    height: '34px',
                                    background: '#FFF',
                                    borderRadius: '5px',
                                    color: '#5571C6',
                                    border: '1px solid #5571C6',
                                    textTransform: 'none',
                                    pointerEvents: isLoading ? 'none' : '',
                                }}
                                onClick={cancelModal}>
                                {'No'}
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            style={{ display: 'flex', justifyContent: 'right', marginTop: '2px' }}>
                            <LoadingButton
                                onClick={() => handleSubmit()}
                                loading={isLoading}
                                type="submit"
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    width: '192px',
                                    height: '34px',
                                    background: '#5571C6',
                                    borderRadius: '5px',
                                    color: '#FFF',
                                    textTransform: 'none',
                                }}>
                                {'Yes,uncheck'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Button onClick={closeModal} sx={{ mt: 2 }}>
Close Modal
</Button> */}
            </Box>
        </Dialog>
    )
}
export default UncheckSharedModal
