import * as yup from 'yup'

import { USPhoneRegex, zipCodeRegex } from 'utilities/regex'
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const validationSchema = yup.object({
    first_name: yup.string(),
    last_name: yup.string(),
    specialty_id: yup.string(),
    location_id: yup.string(),
    npi: yup.number(),
    email: yup.string().email('Invalid email format'),
    phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    fax: yup.string().matches(phoneRegExp, 'Fax number is not valid'),
})
