import moment from 'moment'
import { SCREEN } from './constant'

export const INITIAL_VALUES = {
    appointment_status: '',
    priority: '',
    patient_status: '',
    categoryOption: [
        {
            key: 'Vacation',
            value: 'Vacation',
        },
        {
            key: 'Meeting',
            value: 'Meeting',
        },
    ],
    bhMedOption: [
        {
            key: 'Medical',
            value: 'Medical',
        },
        {
            key: 'Behavioral Health',
            value: 'Behavioral Health',
        },
    ],
    selectedTab: SCREEN.PATIENT_DETAILS,
    selectedScreen: SCREEN.PATIENT_DETAILS,
    isNewPatient: false,
    patient_type: null,
    minor: '',
    questions: {
        1: null,
        2: null,
        3: null,
    },

    appointment_for: 'patient',
    appointment_type: 'Telehealth Visit',
    specialty_id: '',
    visitType: '',
    duration: 10,
    patient: '',
    appointment_reason: '',
    location: '',
    room: '',
    category: '',
    provider: '',
    staff: '',
    start: moment(),
    end: moment().endOf('day'),
    isRequireTranslator: false,
    additional_info: '',
    isSendReminder: false,
    isCompleted: false,
    checklists: undefined,
}
