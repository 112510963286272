import * as actionType from '../actionType';

const initialState = {
    clinicAdminDetail: {},
    clinicAdminProfile: {},
    succesMsg: '',
    clinicSearchList: [],
    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
};

const clinicAdmin = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_CLINIC_ADMIN_DETAIL:
            return {
                ...state,
                clinicAdminDetail: action.value,
            };
        case actionType.SET_CLINIC_ADMIN_PROFILE:
            return {
                ...state,
                clinicAdminProfile: action.value,
            };
        case actionType.SET_CLINIC_ADMIN_PROFILE_EDIT:
            return {
                ...state,
                succesMsg: action.value,
            };
        case actionType.SET_CLINIC_LIST: {
            const { page, totalCount, clinics } =
                action.value;
            const clinicSearchList = clinics?.length
                ? formatClinicListSearchResult(clinics)
                : [];
            // const totalCount =
            //     Math.ceil(totalClinicRecord / 10) ?? 0;
            return {
                ...state,
                clinicSearchList,
                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            };
        }
        case actionType.UPDATE_SEARCH_CLINIC_STATUS: {
            const { id, status } = action.value;

            return {
                ...state,
                clinicSearchList:
                    state.clinicSearchList.map((clinic) => {
                        const { user_id, is_active } =
                            clinic;

                        return {
                            ...clinic,
                            is_active:
                                id === user_id
                                    ? status
                                    : is_active,
                        };
                    }),
            };
        }
        case actionType.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

const formatClinicListSearchResult = (clinicList) =>
    clinicList.map((clinic) => {
        const {
            clinic_name,
            add1,
            add2,
            city,
            state,
            zip,
            id: user_id, //user_id because tablerow need user_id
            is_active,
        } = clinic;

        return {
            clinic_name,
            add1,
            add2,
            city,
            state,
            zip,
            user_id,
            is_active,
        };
    }) ?? [];

export default clinicAdmin;
