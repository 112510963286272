/* eslint-disable no-use-before-define */
import * as actionType from '../actionType'

import { formatPhone, getNameFormat } from '../../utilities'
import { sortAscending, sortDescending } from '../../shared/utils'
import moment from 'moment'

const getCalendarLocalState = () => {
    const getLocalStoreFilterState =
        localStorage.getItem('calendar_state') && JSON.parse(localStorage.getItem('calendar_state'))
    return getLocalStoreFilterState
}

const initialState = {
    providerDetails: null,
    providerList: [],
    calendarDates: [],
    calendarSlots: [],
    providerSearchList: [],
    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
    providerProfile: [],
    specialityList: [],
    clinicList: [],
    encounterRoom: null,
    timeoffList: [],
    timeoffsListsort: {
        start_date: 'asc',
        start: 'asc',
        appointments: 'asc',
        status: 'asc',
    },
    encounterQueueListDate: getCalendarLocalState() ? getCalendarLocalState()?.view?.date : moment(new Date()).format('YYYY-MM-DD'),
    encounterQueueList: [],
    openListConsultation: false,
    openListProvider: false,
    tokenVideoProvider: '',
    statusAdmit: '',
    isEndMeetingRoomWithParticipant: false,
    statusKickParticipant: '',
    encounterMeetingId: '',
    isSuccessAddParticipant: false,
    errorAddParticipant: '',
    objectEncouterStart: {},
    assistantPractitioner: [],
    assignedProvider: null,
    encounterClinicalNoteLoaded: false,
    statusParticipant: [],
    lastMessage: null,
    selectedEncounter: null,
    providingProvidersList: [],
    supervisingProvidersList: [],
    vonageProviderSession: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PROVIDER_DETAILS: {
            return {
                ...state,
                providerDetails: action.value,
            }
        }
        case actionType.SET_PROVIDER_LIST:
            return {
                ...state,
                providerList: action.value,
            }
        case actionType.SET_ASSISTANT_PRACTITIONER:
            return {
                ...state,
                assistantPractitioner: action.value,
            }
        case actionType.SET_ASSIGNED_PROVIDER:
            return {
                ...state,
                assignedProvider: action.value,
            }
        case actionType.SET_CALENDAR_DATES:
            return {
                ...state,
                calendarDates: action.value,
            }
        case actionType.SET_CALENDAR_SLOTS:
            return {
                ...state,
                calendarSlots: action.value,
            }
        case actionType.SET_SPECIALITY_LIST:
            return {
                ...state,
                specialityList: action.value,
            }
        case actionType.SET_CLIENT_LIST:
            return {
                ...state,
                clinicList: action.value,
            }
        case actionType.SET_ACTIVE_PROVIDER_LIST:
            return {
                ...state,
                providerList: action.value,
            }
        case actionType.SET_SEARCH_PROVIDER_LIST: {
            const { page, totalCount, provider } = action.value

            const providerSearchList = provider?.length ? formatProviderSearchResult(provider) : []
            // const totalCount =
            //     Math.ceil(totalRecord / 10) ?? 0;

            return {
                ...state,
                providerSearchList,
                searchMeta: {
                    currentPage: page ?? 1,
                    totalCount,
                },
            }
        }
        case actionType.RESET_PROVIDER_PROFILE: {
            return {
                ...state,
                providerProfile: [],
            }
        }
        case actionType.UPDATE_SEARCH_PROVIDER_STATUS: {
            const { userId, status } = action.value

            return {
                ...state,
                providerSearchList: state.providerSearchList.map((provider) => {
                    const { user_id, is_active } = provider

                    return {
                        ...provider,
                        is_active: user_id === userId ? status : is_active,
                    }
                }),
            }
        }
        case actionType.SET_PROVIDER_PROFILE: {
            return {
                ...state,
                providerProfile: action.value,
            }
        }
        case actionType.SET_TIMEOFFS_LIST: {
            return {
                ...state,
                timeoffList: action.value,
            }
        }
        case actionType.GET_LIST_ENCOUNTER_QUEUE: {
            return {
                ...state,
                encounterQueueList: action.value,
            }
        }
        case actionType.SET_DATE_BASED_LIST_ENCOUNTER_QUEUE: {
            return {
                ...state,
                encounterQueueListDate: action.value,
            }
        }
        case actionType.SET_SELECTED_ENCOUNTER: {
            return {
                ...state,
                selectedEncounter: action.value,
            }
        }
        case actionType.SHOW_CONSULTATION_LIST: {
            const { open } = action.value
            return {
                ...state,
                openListConsultation: open,
                openListProvider: false,
            }
        }
        case actionType.SHOW_PROVIDER_LIST: {
            const { open } = action.value
            return {
                ...state,
                openListProvider: open,
                openListConsultation: false,
            }
        }
        case actionType.GET_TOKEN_VIDEO_PROVIDER: {
            return {
                ...state,
                tokenVideoProvider: action.value,
            }
        }
        case actionType.ACTION_ADMIT: {
            return {
                ...state,
                statusAdmit: action.value,
            }
        }
        case actionType.ACTION_END_ROOM: {
            return {
                ...state,
                isEndMeetingRoomWithParticipant: action.value,
            }
        }
        case actionType.ACTION_KICK_PARTICIPANT: {
            return {
                ...state,
                statusKickParticipant: action.value,
            }
        }
        case actionType.SET_ENCOUNTER_MEETING_ID: {
            return {
                ...state,
                encounterMeetingId: action.value,
            }
        }
        case actionType.SET_IS_SUCCESS_ADD_PARTICIPANT: {
            return {
                ...state,
                isSuccessAddParticipant: action.value,
            }
        }
        case actionType.SET_ERROR_ADD_PARTICIPANT: {
            return {
                ...state,
                errorAddParticipant: action.value,
            }
        }
        case actionType.SAVE_OBJECT_ENCOUTER_START: {
            return {
                ...state,
                objectEncouterStart: action.value,
            }
        }
        case actionType.TIMEOFFS_SORT_DATA:
            return {
                ...state,
                timeoffsListsort: {
                    ...state.timeoffsListsort,
                    [action.value]: state.timeoffsListsort[action.value] === 'asc' ? 'desc' : 'asc',
                },
                timeoffList:
                    state.timeoffsListsort[action.value] === 'asc'
                        ? sortAscending(state.timeoffList, action.value)
                        : sortDescending(state.timeoffList, action.value),
            }

        case actionType.LOGOUT: {
            return initialState
        }
        case actionType.STOP_CONSULTATION: {
            return {
                ...state,
                encounterMeetingId: null,
            }
        }
        case actionType.SET_ENCOUNTER_ROOM: {
            return {
                ...state,
                encounterRoom: action.value,
            }
        }
        case actionType.SET_ENCOUNTER_CLINICAL_NOTE_LOADED: {
            return {
                ...state,
                encounterClinicalNoteLoaded: action.value,
            }
        }

        case actionType.SET_ENCOUNTER_QUEUE_LIST: {
            return {
                ...state,
                encounterQueueList: action.value,
            }
        }

        case actionType.SET_STATUS_PARTICIPANT: {
            return {
                ...state,
                statusParticipant: action.value,
            }
        }

        case actionType.SET_LAST_MESSAGE: {
            return {
                ...state,
                lastMessage: action.value,
            }
        }
        case actionType.SET_PROVIDING_PROVIDER_LIST: {
            return {
                ...state,
                providingProvidersList: action.value,
            }
        }
        case actionType.SET_SUPERVISE_PROVIDER_LIST: {
            return {
                ...state,
                supervisingProvidersList: action.value,
            }
        }
        case actionType.VONAGE_PROVIDER_SESSION: {
            return {
                ...state,
                vonageProviderSession: action.value,
            }
        }
        default:
            return state
    }
}

const formatProviderSearchResult = (providerList) =>
    providerList.map((provider) => {
        const {
            first_name: firstName,
            last_name: lastName,
            npi_number: npiNumber,
            user_id,
            associated_clinic: clinics,
            user: { mob, email, is_active },
            speciality,
        } = provider

        const associatedClinics = (clinics || []).map((item) => item?.clinic_name).join(',')

        return {
            providerName: getNameFormat(firstName, lastName),
            speciality: speciality?.name ?? '',
            phoneNumber: mob,
            // phoneNumber: formatPhone(
            //     mob?.replace(/\D/g, '')?.slice(-10),
            // ),
            email: email.toLowerCase(),
            npiNumber,
            associatedClinics,
            is_active,
            user_id,
        }
    }) ?? []
