import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import moment from 'moment'

import CustomTabs from 'components/shared/Elements/CustomTabs'
import Routes from 'constants/routes'

import PatientInfor from '../PatientInfor'
import { DataAppointments } from './DataAppointments'
import { useStyles } from './style'

const sectionsTabs = [
    {
        key: 234234,
        label: 'Future Appointments',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Past Appointments',
        _id: 2,
    },
]

const Appointments = () => {
    const classes = useStyles()

    const history = useHistory()
    const { id: patientId } = useParams<{ id: string }>()

    const [selectedTab, setSelectedTab] = useState(0)
    const [appointmentModal, setAppointmentModal] = useState<{
        status: boolean
        itemId: string | null | undefined
    }>({
        status: false,
        itemId: null,
    })
    const [cycleCount, setCycleCount] = useState(0)

    const appointmentModalToggle = (forceReload: boolean = false, itemId?: string) => {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId,
        })

        if (forceReload) setCycleCount(cycleCount + 1)
    }

    const handleClickEdit = (appointmentId: string) => {
        appointmentModalToggle(false, appointmentId)
    }

    const now = new Date()
    const filterBy = moment(now)
        .set({ hour: now.getHours(), minute: 0, second: 0, millisecond: 0 })
        .utc()
        .toISOString()

    return (
        <Grid container className={classes.container} direction="column" wrap="nowrap">
            <Grid
                container
                onClick={() =>
                    history.replace(
                        Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(':id', patientId),
                    )
                }
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
            </Grid>

            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Appointments</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>
                <Grid item xs={12}>
                    <CustomTabs
                        contents={sectionsTabs}
                        selectedTab={selectedTab}
                        onChangeTab={(tabId: number) => {
                            setSelectedTab(tabId)
                        }}
                    />
                </Grid>

                {selectedTab === 0 ? (
                    <DataAppointments
                        cycleCount={cycleCount}
                        filterByDate={`ge${filterBy}`}
                        hasActions
                        onClickEdit={handleClickEdit}
                        selectedTab={selectedTab}
                    />
                ) : (
                    <DataAppointments
                        cycleCount={cycleCount}
                        filterByDate={`lt${filterBy}`}
                        onClickEdit={handleClickEdit}
                        selectedTab={selectedTab}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Appointments)
