import moment from 'moment'
import { sortValues } from '../utils'
import defaultNarrativeStrings from './defaultNarrativeStrings'
import { stringConcatenation } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'
import {
    convertValueText,
    convertValueTextFamilyMembers,
} from 'containers/PatientsSetting/view/FamilyPsychiatricHistory/utils'

const present_in_sessions = ({ values, master }) => {
    const { selfRows, generalRows, customRows } = values
    const { roleOptions, participationOptions } = master
    let str = ''

    const generate = ({
        role,
        name,
        custom_role,
        was_available,
        participation,
        decision_maker,
        comment,
    }) => {
        const participationMaster =
            participationOptions.find((option) => option.key === participation) || {}
        const roleMaster = roleOptions.find((option) => option.key === role) || {}

        if (was_available !== undefined) {
            str = `${str} ${defaultNarrativeStrings['was_available']?.(was_available)}`

            if (decision_maker) {
                str = `${str} ${defaultNarrativeStrings['decision_maker']?.(decision_maker)}`

                if (participation) {
                    str = `${str} and`
                    str = `${str} ${defaultNarrativeStrings['participation']?.(
                        participationMaster.value,
                    )}`
                }

                str = `${str}.`
            } else if (participation) {
                str = `${str} The patient`
                str = `${str} ${defaultNarrativeStrings['participation']?.(
                    participationMaster.value,
                )}`
                str = `${str}.`
            }
        } else if (role !== undefined && role !== '') {
            str = `${str} ${defaultNarrativeStrings['role']?.(
                roleMaster.value,
                name,
                decision_maker,
                participationMaster.value,
            )}`
        } else if (custom_role !== undefined && custom_role !== '') {
            str = `${str} ${defaultNarrativeStrings['role']?.(
                custom_role,
                name,
                decision_maker,
                participationMaster.value,
            )}`
        }

        if (comment) {
            str = `${str} Additionally, ${comment}.`
        }
        str = `${str} \n\n`
    }

    if (selfRows?.length > 0) {
        selfRows.forEach((row) => {
            generate({ ...row })
        })
    }

    if (generalRows?.length > 0) {
        generalRows.forEach((row) => {
            generate({ ...row })
        })
    }

    if (customRows?.length > 0) {
        customRows.forEach((row) => {
            generate({ ...row })
        })
    }

    return str.trim()
}
const allergy = ({ values }) => {
    const { have_any_allergy, allergies } = values
    let str = ''

    if (!have_any_allergy) {
        str = defaultNarrativeStrings['patient_allergies']
    } else {
        str = `The patient has known medication allergies: \n`
        str = allergies.reduce((init, item, index) => {
            const allergy = stringConcatenation(
                item.allergy.brandName || '',
                item.allergy.genericName || '',
                item.allergy.strength || '',
                item.allergy.form || '',
            )
            const ndcId = item.allergy?.coding.find((item) => item.display === 'NCDID')?.code || ''
            const reaction = item.reaction?.display || ''
            const status = item.status?.code || item.status?.display
            const time_onset =
                (item.onset_date && formatDateTime(item.onset_date, 'MMM DD, YYYY')) || ''
            const note = item.note || ''
            const row = `${index + 1
                }. Allergy Type: ${allergy}; Reaction: ${reaction}; NDC-ID: ${ndcId}; Status: ${status}; Notes: ${note}; Updated: ${time_onset} \n`
            return init + row
        }, str)
    }

    return str.trim()
}
const allergies = ({ values }) => {
    const { have_any_allergy, allergies } = values
    let str = ''

    if (!have_any_allergy) {
        str = defaultNarrativeStrings['patient_allergies']
    } else {
        allergies.forEach((ele) => {
            str = `${str} The patient is allergic to ${ele.allergy}. The allergic reaction they suffer as a result of this medication is ${ele.reaction}. \n`
        })
    }

    return str.trim()
}
const psychiatric_medication_history = ({ values, master }) => {
    const { psychiatric_treat_desc, med_psychiatric_treat } = values
    let str = ''

    if (!med_psychiatric_treat) {
        str = defaultNarrativeStrings['psychiatric_medication_history']
    } else {
        // psychiatric_treat_desc.forEach((ele, index) => {
        //     const therapeuticEffect = master.find((m) => m._id === ele.therapeuticEffect)

        //     const { medicationName, dose, howLong, endDate, sideEffects, reason } = ele
        //     if (index === 3) {
        //         const nextItem = psychiatric_treat_desc[index + 1]
        //         str = `${str} ${
        //             !!medicationName || !!dose || !!howLong || !!endDate
        //                 ? `The patient was prescribed ${medicationName} at ${dose} for ${howLong} month(s) until ${endDate}. `
        //                 : ''
        //         }The reported side effects were "${
        //             nextItem.sideEffects
        //         }" and ${sideEffects}.The therapeutic effect of the medication was a significant improvement.  The patient reported that the reason for stopping the medication was: "${reason}".\n`
        //     } else if (index === 4) {
        //         str = `${str} ${
        //             !!medicationName || !!dose || !!howLong || !!endDate
        //                 ? `The patient was prescribed ${medicationName} at ${dose} for ${howLong} month(s) until ${endDate}. `
        //                 : ''
        //         } ${
        //             !!therapeuticEffect?.label
        //                 ? `The therapeutic effect of the medication was ${therapeuticEffect?.label.toLowerCase()}. `
        //                 : ''
        //         } ${!!sideEffects ? `The reported side effects were ${sideEffects}. ` : ''}\n`
        //     } else {
        //         str = `${str} ${
        //             !!medicationName || !!dose || !!howLong || !!endDate
        //                 ? `The patient was prescribed ${medicationName} at ${dose} for ${howLong} month(s) until ${endDate}. `
        //                 : ''
        //         } ${
        //             !!therapeuticEffect?.label
        //                 ? `The therapeutic effect of the medication was ${therapeuticEffect?.label.toLowerCase()}. `
        //                 : ''
        //         } ${!!sideEffects ? `The reported side effects were ${sideEffects}. ` : ''} ${
        //             !!reason
        //                 ? `The patient reported that the reason for stopping the medication was: "${reason}". `
        //                 : ''
        //         }\n`
        //     }
        // })

        str = `The patient has a recent history of taking medications for psychiatric treatment: \n`
        str = psychiatric_treat_desc.reduce((init, item, index) => {
            const {
                end_date,
                strength,
                how_long,
                therapeutic_effect,
                side_effects,
                reason_for_stopping,
                medication,
            } = item
            const row = `${index + 1
                }. Medication: ${medication}; Strength: ${strength}; How Long: ${how_long}; End Date: ${end_date}; Therapeutic Effect: ${therapeutic_effect}; Side Effects: ${side_effects}; Reason For Stopping: ${reason_for_stopping} \n`
            return init + row
        }, str)
    }
    return str.trim()
}
const past_history = ({ values }) => {
    const {
        capture_intake,
        capture_intake_excep,
        medication_problems,
        family_history,
        past_history,
        social_history,
    } = values
    let str = ''

    if (capture_intake) {
        if (capture_intake_excep) {
            str = `${defaultNarrativeStrings['past_history']} and Past, Family, and Social History remain unchanged as captured on intake and former session documents, with the following exceptions.`
        } else {
            str = `${defaultNarrativeStrings['past_history']}.`
        }
    } else {
        if (capture_intake_excep) {
            str = `Past, Family, and Social History remain unchanged as captured on intake and former session documents, with the following exceptions.`
        }
    }

    if (past_history) {
        str = `${str} With regard to past (psychiatric/medical) history: ${past_history}. `
    }
    if (medication_problems) {
        str = `${str} With regard to Medication Problems: ${medication_problems}. `
    }
    if (family_history) {
        str = `${str} With regard to family history: ${family_history}. `
    }
    if (social_history) {
        str = `${str} With regard to social history: ${social_history}. `
    }

    return str.trim()
}

const side_effects = ({ values, master = [] }) => {
    let str = ''
    const { no_known, side_effect_opt } = values

    if (no_known) {
        str = defaultNarrativeStrings['side_effects']
    } else {
        const noneMaster = master.find((m) => m.section_name === 'level' && m.seq === 1)
        const mildMaster = master.find((m) => m.section_name === 'level' && m.seq === 2)
        const moderateMaster = master.find((m) => m.section_name === 'level' && m.seq === 3)
        const severeMaster = master.find((m) => m.section_name === 'level' && m.seq === 4)
        if (side_effect_opt?.length > 0) {
            const noneOptions = side_effect_opt.filter(
                (option) => option.sideEffect_level === noneMaster?._id,
            )
            const mildOptions = side_effect_opt.filter(
                (option) => option.sideEffect_level === mildMaster?._id,
            )
            const moderateOptions = side_effect_opt.filter(
                (option) => option.sideEffect_level === moderateMaster?._id,
            )
            const severeOptions = side_effect_opt.filter(
                (option) => option.sideEffect_level === severeMaster?._id,
            )

            const defaultStrings = (nameSubsection = '', nameSubsection2 = '') => ({
                none: `The patient did not ${nameSubsection} due to their medications.`,
                moderate_mild: `The patient experienced moderate problems with  ${nameSubsection} and  mild problems with ${nameSubsection2} due to their medications`,
                severe: `The patient experienced severe problems with  ${nameSubsection} due to their medications`,
            })

            const otherOptions = []
            if (severeOptions?.length > 0) {
                otherOptions.push({
                    type: 'severe',
                    options: severeOptions,
                })
            }
            // if (moderateOptions?.length > 0) {
            otherOptions.push([
                {
                    type: 'moderate',

                    options: moderateOptions,
                },
                {
                    type: 'mild',

                    options: mildOptions,
                },
            ])
            // }
            // if (mildOptions?.length > 0) {
            //     otherOptions.push({
            //         type: 'mild',

            //         options: mildOptions,
            //     })
            // }
            if (noneOptions?.length > 0) {
                otherOptions.push({
                    type: 'none',
                    options: noneOptions,
                })
            }
            if (otherOptions?.length > 0) {
                otherOptions.forEach((option) => {
                    const listName = []
                    if (option.type === 'none' || option.type === 'severe') {
                        option.options.forEach((categoryOption) => {
                            let sideEffectName = ''

                            const filteredCategory = master.find(
                                (m) => m._id === categoryOption.sideEffect_category,
                            )
                            sideEffectName = filteredCategory?.name?.toLowerCase() || ''
                            listName.push(sideEffectName)
                        })
                        str = `${str} ${defaultStrings(listName.join(', ').replace('problems', ''))[option.type]
                            }.`
                    } else {
                        const listNameMildnModerate = option.reduce(
                            (acc, cur) => {
                                cur.options.forEach((categoryOption) => {
                                    let sideEffectName = ''

                                    const filteredCategory = master.find(
                                        (m) => m._id === categoryOption.sideEffect_category,
                                    )
                                    sideEffectName = filteredCategory?.name?.toLowerCase() || ''

                                    console.log('curr', acc, sideEffectName)

                                    if (cur.type === 'moderate') {
                                        acc[0] = [...acc[0], sideEffectName]
                                    } else {
                                        acc[1] = [...acc[1], sideEffectName]
                                    }
                                })
                                return acc
                            },
                            [[], []],
                        )

                        str = `${str} ${defaultStrings(
                            listNameMildnModerate[0].join(', '),
                            listNameMildnModerate[1].join(', '),
                        )['moderate_mild']
                            }.`
                    }
                })
            }
        }
    }
    return str.trim()
}

const adherence = ({ values }) => {
    const { no_concern, past_30_medic } = values
    let str = ''

    if (no_concern) {
        str = defaultNarrativeStrings['adherence']
    }
    if (past_30_medic) {
        str = `${str} The patient endorsed taking their medications on ${past_30_medic} of the past 30 days.`
    }

    return str.trim()
}

const assessment_informed_consent = ({ values, master }) => {
    const { treat_consent_given_by, confidentiality_disc, informed_consent_opt } = values
    let str = ''

    if (treat_consent_given_by?.length > 0) {
        const defaultStr1 = `The patient gave informed consent for the treatment documented in this clinical note.`
        const defaultStr2 = (name) =>
            `Informed consent was given by ${name}, the patient's caregiver, for the treatment documented in this clinical note`

        const sortedValues = sortValues(treat_consent_given_by, master)
        sortedValues.forEach((ele, index, arr) => {
            const filtered = master.find((m) => m._id === ele.id)
            if (filtered?.seq === 1) {
                str = `${str} ${defaultStr1}`
            }
            if (filtered?.seq === 2 && ele.desc) {
                str = `${str} ${defaultStr2(ele.desc)}`
            }
            if (filtered?.seq === 3 && ele.desc) {
                const seq2 = master.find((m) => m.seq === 2 && m.section === 1)
                const isSeq2Selected = arr.find((v) => v.id === seq2?._id)

                if (ele.desc) {
                    if (isSeq2Selected && isSeq2Selected?.desc) {
                        str = `${str} and ${ele.desc}`
                    } else {
                        str = `${str} Informed consent was given by ${ele.desc}`
                    }
                }
            }
        })
    }

    if (confidentiality_disc?.length > 0) {
        const defaultStr1 = `We discussed`
        const sortedValues = sortValues(confidentiality_disc, master)
        sortedValues.forEach((ele, index, arr) => {
            const filtered = master.find((m) => m._id === ele.id)

            if (filtered?.seq === 1) {
                str = `${str} ${defaultStr1} the limits of confidentiality`

                if (ele.desc) {
                    str = `${str}, including ${ele.desc}`
                } else {
                    str = `${str}`
                }
            }
            if (filtered?.seq === 2) {
                str = `${str} ${defaultStr1} ${filtered?.name?.toLowerCase()}`
            }
            if (filtered?.seq === 3) {
                const seq2 = master.find((m) => m.seq === 2 && m.section === 2)
                const isSeq2Selected = arr.find((v) => v.id === seq2?._id)
                str = `${str} ${isSeq2Selected ? 'and' : defaultStr1
                    } ${filtered?.name?.toLowerCase()}`
            }
            if (filtered?.seq === 4) {
                str = `${str} ${filtered?.name?.replace('A discussion of', defaultStr1)}`
            }
            str = `${str.replace(':', '')}.`
        })
    }

    if (informed_consent_opt?.length > 0) {
        const defaultStr = `Precautions and potential risks discussed include, but are not limited to:`
        str = `${str} ${defaultStr}`
        const sortedValues = sortValues(informed_consent_opt, master)
        sortedValues.forEach((ele, index, arr) => {
            const filtered = master.find((m) => m._id === ele.id)
            if (arr?.length > 1 && arr?.length - 1 === index) {
                str = `${str}, and`
            } else if (index > 0) {
                str = `${str}, `
            }
            str = `${str} ${(filtered.noChangeNameStyle ? filtered?.name : filtered?.name?.toLowerCase()) || ''
                }`
        })
        str = `${str}. \n`
    }

    str = `${str}`.replace('..', '.')

    return str.trim()
}

const inpatient_psychiatric_history = ({ values, master }) => {
    // const { primaryReasonForHospitalizationMasters, therapeuticEffect } = master

    // const getDetail = (data, id) => {
    //     return data.find((el) => el._id === id)
    // }

    const { have_inpatient_psyc_treat, inpatient_psychiatric_opt } = values

    let str = ''
    if (!have_inpatient_psyc_treat) {
        return (str = `The patient does not have a history of inpatient psychiatric treatment.\n\n`)
    }
    // if (inpatient_psychiatric_opt?.length > 0) {
    //     inpatient_psychiatric_opt.forEach((el, index, arr) => {
    //         const { hospital, treatment_voluntary, reasons, age, treatment_outcome, comment } = el

    //         if (hospital || reasons || age) {
    //             str = `${str}The patient received inpatient treatment at ${hospital} for ${
    //                 !!reasons
    //                     ? getDetail(
    //                           primaryReasonForHospitalizationMasters,
    //                           reasons,
    //                       ).name?.toLowerCase()
    //                     : ''
    //             } at age ${age}.`
    //         }
    //         if (treatment_voluntary === true) {
    //             str = `${str} The treatment was voluntary.`
    //         } else {
    //             if (treatment_voluntary === false) {
    //                 str = `${str} The treatment was voluntary.`
    //             }
    //         }
    //         if (treatment_outcome) {
    //             str = `${str} The outcome of the treatment was ${
    //                 !!treatment_outcome
    //                     ? getDetail(therapeuticEffect, treatment_outcome).label?.toLowerCase()
    //                     : ''
    //             }.`
    //         }
    //         if (comment) {
    //             str = `${str} The patient further reported: "${comment}".`
    //         }
    //         str = str = str ? `${str} \n\n` : ''
    //     })
    // }

    str = `The patient has a history of inpatient psychiatric treatment of the following: \n`
    str = inpatient_psychiatric_opt.reduce((init, item, index) => {
        const { age = '', voluntary, reason = '', outcome = '', comment, facility = '' } = item
        const row = `${index + 1}. Facility: ${facility}; Voluntary: ${voluntary === true ? 'Yes' : voluntary === false ? 'No' : ''
            }; Reason: ${reason}; Age: ${age}; Outcome: ${outcome}; Comments: ${comment || ''} \n`
        return init + row
    }, str)

    return str.trim()
}

const outpatient_psychiatric_history = ({ values, master }) => {
    const { outpatientPsychiatricMasters, therapeuticEffectOutPatient } = master

    const getDetail = (data, id) => {
        return data.find((el) => el._id === id)
    }

    const { have_outpatient_psyc_treat, outpatient_psychiatric_opt } = values

    let str = ''
    if (!have_outpatient_psyc_treat) {
        return (str = `The patient does not have a history of outpatient psychiatric treatment.\n\n`)
    }
    // if (outpatient_psychiatric_opt?.length > 0) {
    //     outpatient_psychiatric_opt.forEach((el, index, arr) => {
    //         const { provider, reasons, firstAge, lastAge, treatment_outcome, comment } = el

    //         if (provider || reasons || firstAge) {
    //             str = `${str}The patient saw ${provider} for treatment concerning ${
    //                 !!reasons
    //                     ? getDetail(outpatientPsychiatricMasters, reasons)?.name?.toLowerCase()
    //                     : ''
    //             } beginning at age ${firstAge} and ending at age ${lastAge}.`
    //         }
    //         if (treatment_outcome) {
    //             str = `${str} The outcome of their treatment with this provider was ${
    //                 !!treatment_outcome
    //                     ? getDetail(
    //                           therapeuticEffectOutPatient,
    //                           treatment_outcome,
    //                       ).label?.toLowerCase()
    //                     : ''
    //             }.`
    //         }
    //         if (comment) {
    //             str = `${str} The patient also reported that: "${comment}".`
    //         }
    //         str = str = str ? `${str} \n\n` : ''
    //     })
    // }

    // return `${str}\n`

    str = `The patient has a history of outpatient psychiatric treatment of the following: \n`
    str = outpatient_psychiatric_opt.reduce((init, item, index) => {
        const {
            provider_id,
            reason,
            age_of_first_treatment,
            age_of_last_treatment,
            outcome,
            comment,
        } = item
        const row = `${index + 1
            }. Provider: ${provider_id}; Reason: ${reason}; Age of First Treatment: ${age_of_first_treatment}; Age of Last Treatment: ${age_of_last_treatment}; Outcome: ${outcome}; Comments: ${comment} \n`
        return init + row
    }, str)

    return str.trim()
}

const family_history = ({ values, master }) => {
    const convert_family_history = (data) => {
        return {
            family_member: convertValueTextFamilyMembers(data.family_member),
            psychiatric_condition: data.psychiatric_condition
                ?.map((item) => convertValueText(item))
                .join(', '),
        }
    }
    let str = ''
    const { additional_med_desc, psych_illness_history, history_list = [] } = values

    if (!psych_illness_history) {
        str = `${str} The patient's family does not have a history of psychiatric illness. \n`
    } else {
        if (history_list?.length) {
            str += `Family Psychiatric History: \n`
            str = history_list.reduce((init, item, index) => {
                const { family_member, psychiatric_condition } = convert_family_history(item)
                const row = `${index + 1
                    }. Family Member: ${family_member}; Psychiatric Condition: ${psychiatric_condition} \n${index === history_list?.length - 1 ? '\n' : ''
                    }`
                return init + row
            }, str)
        }
    }

    // if (history_opt?.length > 0) {
    //     history_opt.forEach((ele, index, arr) => {
    //         if (ele.family_member && ele.psychiatric_problem?.length > 0) {
    //             const filtered = familyOptions.find((fo) => fo.key === ele.family_member)
    //             str = `${str} The patient's ${filtered?.value?.toLowerCase()} has a history of`

    //             const filteredProblems = ele?.psychiatric_problem?.filter((prob) => prob)
    //             filteredProblems.forEach((prob, index, arr) => {
    //                 const filtered = psychiatricOptions.find((fo) => fo.key === prob)
    //                 if (arr?.length > 1 && arr?.length - 1 === index) {
    //                     str = `${str} and`
    //                 } else if (index > 0) {
    //                     str = `${str}, `
    //                 }
    //                 str = `${str} ${filtered?.value?.toLowerCase() || ''}`
    //             })
    //             str = `${str}.`
    //         }
    //     })
    // }

    if (additional_med_desc) {
        str = `${str} The patient's family medical history is as follows: ${additional_med_desc}`
    }
    return str.trim()
}

const violence_history = ({ values }) => {
    const { have_violent_behavior, violent_behavior_desc } = values
    let str = ''
    if (have_violent_behavior === false) {
        str = `${str} The patient does not have a history of violent behavior.`
    } else if (have_violent_behavior === true) {
        str = `${str} The patient has a history of violent behavior.`

        if (violent_behavior_desc) {
            str = `${str} and elaborated as follows: "${violent_behavior_desc}".`
        }
    }
    if (have_violent_behavior === false && violent_behavior_desc) {
        str = `${str} The patient has a history of violent behavior and elaborated as follows: "${violent_behavior_desc}".`
    }

    return str.trim()
}

const suicide_history = ({ values }) => {
    let str = ''
    const {
        tried_to_harm,
        intent_to_die,
        intent_to_die_desc,
        suicide_occurance,
        most_severe_episode,
        most_recent_episode,
    } = values
    if (tried_to_harm === true) {
        if (intent_to_die === true) {
            str = `${str}The patient positively reported that they had a history of suicide or self harm and that their intention was to die from their attempt(s).`
        }

        if (intent_to_die === false) {
            str = `${str}The patient positively reported that they had a history of suicide or self harm but their intention was not to die from their attempt(s).`
        }

        if (suicide_occurance > 0) {
            str = `${str} The patient has a reported history of ${suicide_occurance} attempt(s) of suicide or self harm.`
        }
        if (intent_to_die_desc) {
            str = `${str} The patient further elaborated as follows: "${intent_to_die_desc}".`
        }
    } else if (tried_to_harm === false) {
        str = `${str} The patient denied a history of suicide or self harm.`
    }
    if (most_severe_episode) {
        str = `${str} The most severe episode of suicide or self harm occurred in "${most_severe_episode}".`
    }
    if (most_recent_episode) {
        str = `${str} The most recent episode of suicide or self harm occurred in: "${most_recent_episode}".`
    }

    return str.trim()
}

const subtance_abuse_history = ({ values, master }) => {
    const { is_recreational_drug, substance_opts, subtance_used } = values
    let str = ''
    if (is_recreational_drug === false) {
        str = `${str}The patient does not have a history of substance abuse of any recreational drugs.\n`
        return str.trim()
    }

    // if (is_recreational_drug == true) {
    // const filteredTrueSubtance = substance_opts?.filter((ele) => ele.is_used === true)

    // const filteredFalseSubtance = substance_opts?.filter((ele) => ele.is_used === false)

    // const filteredOthers = substance_opts?.filter((ele) => ele.addNew === true)

    // const NameNotActive = []
    // substance_opts?.filter((ele) => {
    //     ele.is_used === false ? NameNotActive.push(ele.name) : []
    // })

    // const nameSplit = []
    // NameNotActive.forEach((name) => {
    //     const nameConver = name?.split(' ')
    //     if (nameConver) {
    //         name = nameConver[0]
    //         nameSplit.push(name)
    //     }
    // })

    // const sections = [
    //     {
    //         sectionValue: filteredTrueSubtance,
    //         key: 'default',
    //         defaultStr: `The patient has a history of using`,
    //     },
    //     {
    //         sectionValue: filteredFalseSubtance,
    //         defaultStr: `The patient does not have a history of substance abuse of the following:`,
    //     },
    //     {
    //         sectionValue: filteredOthers,
    //         defaultStr: `The patient has a history of using`,
    //         key: 'other',
    //     },
    // ]

    // sections?.forEach((section) => {
    //     section?.sectionValue?.forEach((ele, index, arr) => {
    //         const {
    //             firstAge,
    //             lastAge,
    //             amountPerDay,
    //             daysPerMonth,
    //             is_used,
    //             how_many,
    //             information,
    //             name,
    //             addNew,
    //             how_opts,
    //         } = ele

    //         if (is_used && section.key === 'default') {
    //             str = `${str}${section.defaultStr}`
    //             str = `${str} ${name} between the ages of ${firstAge ? firstAge : ''} and ${
    //                 lastAge ? lastAge : ''
    //             }. ${
    //                 !!how_opts.toString()
    //                     ? `The route of administration was ${how_opts
    //                           .toString()
    //                           .replace(/[,_]/g, ', ')}.`
    //                     : ''
    //             } ${!!amountPerDay ? `The amount per day was ${amountPerDay}.` : ''} ${
    //                 !!daysPerMonth ? `The frequency was ${daysPerMonth} days per month.` : ''
    //             }\n`
    //         } else if (addNew && section.key === 'other' && !!name) {
    //             str = `${str.trim()}\n${section.defaultStr}`

    //             str = `${str} ${name} between the ages of ${firstAge ? firstAge : ''} and ${
    //                 lastAge ? lastAge : ''
    //             }.${!!how_opts ? `The route of administration was ${how_opts}.` : ''}${
    //                 !!amountPerDay ? `The amount per day was ${amountPerDay}.` : ''
    //             }${!!daysPerMonth ? `The frequency was ${daysPerMonth} days per month.` : ''} \n \n`
    //         } else {
    //             if (!addNew) {
    //                 if (!!how_opts.toString() || !amountPerDay || !!daysPerMonth) {
    //                     str = `${str}${
    //                         !!how_opts.toString()
    //                             ? `The route of administration was ${how_opts
    //                                   .toString()
    //                                   .replace(/[,_]/g, ', ')}.`
    //                             : ''
    //                     } ${!!amountPerDay ? `The amount per day was ${amountPerDay}.` : ''} ${
    //                         !!daysPerMonth
    //                             ? `The frequency was ${daysPerMonth} days per month.`
    //                             : ''
    //                     }${!!how_opts.toString() || !!amountPerDay || !!daysPerMonth ? `\n` : ''}`
    //                 }
    //             }
    //         }
    //     })
    //     str = `${str}`
    // })

    str = `The patient has a history of substance abuse of the following: \n`
    str = subtance_used.reduce((init, item, index) => {
        const {
            substance_name,
            age_of_first_use,
            age_of_last_use,
            how_taken,
            amount_per_day,
            day_per_month,
        } = item
        const row = `${index + 1
            }. Substance: ${substance_name}; Age Of First Use: ${age_of_first_use}; Age of Last Use: ${age_of_last_use}; How It Was Taken: ${how_taken}; Amount Per Day: ${amount_per_day}; Days Per Month: ${day_per_month} \n`
        return init + row
    }, str)

    return str.trim()
}

// const past_medical_history = ({ values, master }) => {
//     const {
//         primary_care_physician,
//         taking_med_currently,
//         medication_details,
//         health_problems,
//         other_health_problem,
//         surgery_area,
//         other_surgery_area,
//     } = values
//     const { healthProblemMaster, surgicalAreaMaster } = master
//     let str = ''
//     if (primary_care_physician) {
//         str = `${str} The patient's primary care physician is ${primary_care_physician}.`
//     }
//     if (taking_med_currently === true) {
//         str = `${str} The patient reported that they are currently taking the following non-psychiatric medications: ${medication_details}. \n\n`
//     }

//     const sections = [
//         {
//             sectionMaster: healthProblemMaster,
//             sectionValues: health_problems,
//             otherValue: other_health_problem,
//             defaultStr1: `The patient does not have a history of the health problems.`,
//             defaultStr2: `The patient has a history of the following health problem(s):`,
//         },
//         {
//             sectionMaster: surgicalAreaMaster,
//             sectionValues: surgery_area,
//             otherValue: other_surgery_area,
//             defaultStr1: `The patient does not have a history of surgery.`,
//             defaultStr2: `The patient has a history of surgery in the following area(s):`,
//         },
//     ]

//     sections.forEach((section) => {
//         const { sectionMaster, sectionValues, otherValue, defaultStr1, defaultStr2 } = section
//         if (sectionValues?.length > 0) {
//             const seq1Master = sectionMaster?.find((hp) => hp.seq === 1)

//             const seq1Ele = sectionValues.find((hp) => hp === seq1Master?._id)
//             if (seq1Ele) {
//                 str = `${str} ${defaultStr1}`
//             }

//             const filteredProblems = sectionValues.filter((hp) => hp !== seq1Ele)

//             if (otherValue) filteredProblems.push(otherValue)
//             console.log('filteredProblems', filteredProblems)
//             if (filteredProblems?.length > 0) {
//                 str = `${str} ${defaultStr2}`
//                 filteredProblems.forEach((ele, index, arr) => {
//                     const filtered = sectionMaster?.find((hp) => hp._id === ele)

//                     if (arr?.length > 1 && arr?.length - 1 === index) {
//                         str = `${str} and`
//                     } else if (index > 0) {
//                         str = `${str}, `
//                     }
//                     let problemName = ''
//                     if (ele === otherValue) {
//                         problemName = otherValue
//                     } else {
//                         problemName = filtered?.noChangeNameStyle
//                             ? filtered?.name?.split('(')[0]
//                             : filtered?.name?.split('(')[0].toLowerCase()
//                     }
//                     str = `${str} ${problemName}`
//                 })
//                 str = `${str}.`
//             }
//         }
//     })

//     return str.trim()
// }

const past_medical_history = ({ values, master }) => {
    // const convert_medication = (data) => {
    //     return {
    //         drug_name: stringConcatenation(
    //             data.drug.brandName,
    //             data.drug.genericName,
    //             data.drug.strength,
    //             data.drug.form,
    //         ),
    //         strength: data.strength || '',
    //         provider_name: `${data.provider_first_name} ${data.provider_last_name}`,
    //         days_supply: data.day_supply?.display || '',
    //         quantity: data.quantity || '',
    //         refills: data.refills || '',
    //     }
    // }

    // const convert_medical_problems = (data) => {
    //     return {
    //         problem: data.icd_code?.display || '',
    //         code: data.icd_code?.code || '',
    //         date_diagnosed: formatDateTime(data.time_onset, 'MMM DD, YYYY'),
    //         date_changed: formatDateTime(data.time_changed, 'MMM DD, YYYY'),
    //         status: data.status?.code || '',
    //         note: data.note || '',
    //     }
    // }

    const convert_surgical_history = (data) => {
        return {
            procedure: (() => {
                return data.procedure
                    .replaceAll('_', ' ')
                    .toLowerCase()
                    .replace(/\b\w/g, function (txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1)
                    })
            })(),
            date: formatDateTime(data.date, 'MM/DD/YYYY'),
        }
    }

    const {
        primary_care_physician,
        taking_med_currently,
        medication_list = [],
        medical_problems = [],
        surgical_history = [],
        has_medical_problem,
        has_surgical_history,
    } = values

    let str = ''

    if (primary_care_physician) {
        str = `${str} The patient's primary care physician is ${primary_care_physician}. \n \n`
    }

    if (taking_med_currently === true) {
        if (medication_list?.length) {
            str = `${str}Patient reports that they are currently taking the following non-psychotic medications: \n`
            str = medication_list.reduce((init, item, index) => {
                const { drug_name, strength, provider_name, days_supply, quantity, refills, action_status } = item
                const row = `${index + 1
                    }. Drug name: ${drug_name}; Strength: ${strength}; Provider: ${provider_name}; Days Supply: ${days_supply}; Quantity: ${quantity}; Refills: ${refills};Action Status:${action_status} \n${index === medication_list?.length - 1 ? '\n' : ''
                    }`
                return init + row
            }, str)
        }
    } else {
        str = `${str}The patient does not take any medications currently. \n`
    }

    if (has_medical_problem === true) {
        if (medical_problems?.length) {
            str = `${str}The patient has the following medical problems: \n`
            str = medical_problems.reduce((init, item, index) => {
                const { problem, code, date_diagnosed, date_changed, status, note } = item
                const row = `${index + 1
                    }. Problem: ${problem}; ICD-10 Code: ${code}; Date Diagnosed: ${date_diagnosed}; Date Changed: ${date_changed}; Status: ${status}; Notes: ${note} \n${index === medical_problems?.length - 1 ? '\n' : ''
                    }`
                return init + row
            }, str)
        }
    } else {
        str = `${str}The patient has no medical problems. \n`
    }

    if (has_surgical_history === true) {
        if (surgical_history?.length) {
            str = `${str}The patient has the following surgical history: \n`
            str = surgical_history.reduce((init, item, index) => {
                const { procedure, date } = item
                const row = `${index + 1}. Procedure: ${procedure}; Date: ${date} \n`
                return init + row
            }, str)
        }
    } else {
        str = `${str}The patient has no surgical history. \n`
    }

    return str.trim()
}

const subtance_abuse_treat_history = ({ values, master }) => {
    const { substance_opts, received_treat, others, subtance_treat } = values
    let str = ''
    if (received_treat === false) {
        return (str = `${str} The patient does not have a history of any treatment for substance abuse. \n \n`)
    }

    // const filteredTrueSubtance = substance_opts?.filter((ele) => ele.is_used === true)

    // const filteredFalseSubtance = substance_opts?.filter((ele) => ele.is_used === false)

    // const NameNotActive = []
    // substance_opts?.filter((ele) => {
    //     ele.is_used === false && !ele?.addNew ? NameNotActive.push(ele.name) : []
    // })

    // const filteredOthers = substance_opts?.filter((ele) => ele.addNew === true)

    // const sections = [
    //     {
    //         sectionValue: filteredTrueSubtance,
    //         defaultStr: `The patient has a history of`,
    //         key: 'default',
    //         trailStr: 'treatment. \n \n',
    //     },
    //     {
    //         sectionValue: filteredFalseSubtance,
    //         defaultStr: `The patient does not have a history of`,
    //         trailStr: 'treatment. \n',
    //     },
    //     {
    //         sectionValue: filteredOthers,
    //         defaultStr: `The patient has a history of`,
    //         key: 'other',
    //         trailStr: 'treatment. \n \n',
    //     },
    // ]
    // sections?.forEach((section) => {
    //     section?.sectionValue?.forEach((ele, index, arr) => {
    //         const { is_used, firstAge, lastAge, how_many, information, name, addNew } = ele
    //         if (is_used && section.key === 'default') {
    //             str = `${str}${section.defaultStr}`
    //             str = `${str}${
    //                 how_many ? (how_many === 'more_20' ? 'more than 20' : how_many) : ''
    //             } episode(s) of ${name} treatment starting at age ${
    //                 firstAge ? firstAge : ''
    //             } and ending at age ${lastAge ? lastAge : ''}${
    //                 information
    //                     ? `. Additionally, the patient reported: ${
    //                           information ? `"${information.toString()}"` : ''
    //                       }`
    //                     : ''
    //             }.\n \n`
    //         } else if (addNew && section.key === 'other' && !!name) {
    //             str = `${str}${section.defaultStr}`
    //             str = `${str}${
    //                 firstAge ? firstAge : ''
    //             } episode(s) of ${name} treatment starting at age ${
    //                 lastAge ? lastAge : ''
    //             } and ending at age ${how_many ? how_many : ''}${
    //                 information
    //                     ? `. Additionally, the patient reported: ${
    //                           information ? `"${information.toString()}"` : ''
    //                       }`
    //                     : ''
    //             }.\n \n`
    //         } else {
    //             if (!!information && !addNew) {
    //                 str = `${str}${
    //                     information
    //                         ? `Additionally, the patient reported: ${
    //                               information ? `"${information.toString()}"` : ''
    //                           }`
    //                         : ''
    //                 }.\n \n`
    //             }
    //         }
    //     })
    //     str = `${str}`
    // })

    // if (NameNotActive?.length > 0) {
    //     str = `${str}The patient does not have a history of ${NameNotActive.toString().replace(
    //         /[,_]/g,
    //         ' treatment and ',
    //     )}`
    // }

    str = `The patient has a history of substance abuse treatment of the following: \n`
    str = subtance_treat.reduce((init, item, index) => {
        const {
            treatment_name,
            number_of_episodes,
            age_of_first_treatment,
            age_of_last_treatment,
            additional_info,
        } = item
        const row = `${index + 1
            }. Treatment Type: ${treatment_name}; Number of Episodes: ${number_of_episodes}; Age of First Treatment: ${age_of_first_treatment}; Age of Last Treatment: ${age_of_last_treatment}; Additional Treatment Information: ${additional_info} \n`
        return init + row
    }, str)

    return str.trim()
}

const subtance_use_consequences = ({ values, master }) => {
    let str = ''
    const { consequences, others } = values

    if (consequences?.length > 0) {
        const sectionMaster_1 = master.find((m) => m.seq === 1)
        const sectionValue_1 = consequences.find((c) => c === sectionMaster_1?._id)

        if (sectionValue_1) {
            const defaultStr = `The patient reported having experienced no consequences as a result of their substance abuse.`
            str = `${str} ${defaultStr}`
        }

        const sectionMaster_2 = master.filter((m) => m.seq > 1 && m.seq < 6).map((m) => m._id)
        const sectionValues_2 = consequences.filter((c) => sectionMaster_2.includes(c))

        if (sectionValues_2?.length > 0) {
            const defaultStr = 'Of the four C.A.G.E. questions, the patient has a history of'
            str = `${str} ${defaultStr}`

            sectionValues_2.forEach((section, index, arr) => {
                const filtered = master.find((m) => m._id === section)

                if (arr?.length > 1 && arr?.length - 1 === index) {
                    str = `${str} and`
                } else if (index > 0) {
                    str = `${str}, `
                }
                const problemName = filtered?.noChangeNameStyle
                    ? filtered?.label
                    : filtered?.label?.toLowerCase()
                str = `${str} ${problemName}`
            })
            str = `${str}.`
        }

        const sectionMaster_3 = master.filter((m) => m.seq > 5).map((m) => m._id)
        const sectionValues_3 = consequences.filter((c) => sectionMaster_3.includes(c))

        if (others) {
            sectionValues_3.push({
                key: 'others',
                value: others,
            })
        }

        if (sectionValues_3?.length > 0) {
            const defaultStr =
                'The patient has a history of experiencing the following consequences of their substance abuse:'
            str = `${str} ${defaultStr}`

            sectionValues_3.forEach((section, index, arr) => {
                let problemName = ''

                const filtered = master.find((m) => m._id === section)

                if (arr?.length > 1 && arr?.length - 1 === index) {
                    str = `${str} and`
                } else if (index > 0) {
                    str = `${str}, `
                }

                if (section.key === 'others') {
                    problemName = section.value
                } else {
                    problemName = filtered?.noChangeNameStyle
                        ? filtered?.label
                        : filtered?.label?.toLowerCase()
                }
                str = `${str} ${problemName}`
            })
            str = `${str}.`
        }
    }
    return str.trim()
}

// const general_social_history = ({ values, master }) => {
//     const {
//         desc_social_situation,
//         currently_live_with_opt,
//         current_matrial_status,
//         intimate_relationship_status,
//         intimate_satisfaction_level,
//         sexual_orientation,
//         current_living_situation,
//         currently_live_with,
//         currently_live_with_other,
//         participate_in_spritual_act,
//         current_occupation_status,
//         current_yearly_income,
//         longest_period_employment,
//         longest_period_unemployment,
//     } = values
//     let str = ''

//     const getNarrative = (value, key, defaultStr = '') => {
//         const options = [...value[key]]
//         if (value['other']) {
//             options.push({
//                 key: 'other',
//                 val: value['other'],
//             })
//         }
//         if (options?.length > 0) {
//             str = `${str} ${defaultStr}`
//             options.forEach((option, index, arr) => {
//                 let problemName = ''

//                 const filtered = master.find((m) => m._id === option)

//                 if (arr?.length > 1 && arr?.length - 1 === index) {
//                     str = `${str} and`
//                 } else if (index > 0) {
//                     str = `${str}, `
//                 }

//                 if (option.key === 'other') {
//                     problemName = option.val
//                 } else {
//                     problemName = filtered?.label?.toLowerCase() || ''
//                 }
//                 str = `${str} ${problemName}`
//             })
//             str = `${str}.`
//         }
//     }

//     const getNarrative_live_with = (value, key, defaultStr = '') => {
//         const options = [...value[key]]
//         if (value) {
//             if (value['other']) {
//                 options.push({
//                     key: 'other',
//                     val: value['other'],
//                 })
//             }
//             if (options?.length > 0) {
//                 str = `${str} ${defaultStr}`
//                 options?.forEach((option, index, arr) => {
//                     let problemName = ''

//                     const filtered = master.find((m) => m._id === option)

//                     if (arr?.length > 1 && arr?.length - 1 === index) {
//                         str = `${str} and`
//                     } else if (index > 0) {
//                         str = `${str}, `
//                     }

//                     if (option.key === 'other') {
//                         problemName = option.val
//                     } else {
//                         if (filtered.name === 'Live alone') {
//                             str = `${str} no one (live alone)`
//                         } else {
//                             problemName = filtered?.label?.toLowerCase() || ''
//                         }
//                     }
//                     str = `${str} ${problemName}`
//                 })
//                 str = `${str}.`
//             }
//         }
//     }
//     getNarrative(
//         desc_social_situation,
//         'social_situation_opt',
//         defaultNarrativeStrings['desc_social_situation'],
//     )

//     const generateNarrativeFromDropDown = (value, key) => {
//         const filteredMaster = master.find((m) => m._id === value)
//         if (filteredMaster?.label) {
//             const result = defaultNarrativeStrings[key]?.(filteredMaster?.label) || ''
//             str = `${str} ${result}`
//         }
//     }

//     if (currently_live_with_opt) {
//         generateNarrativeFromDropDown(currently_live_with_opt, 'currently_live_with_opt')
//     }

//     if (current_matrial_status) {
//         generateNarrativeFromDropDown(current_matrial_status, 'current_matrial_status')
//     }

//     if (intimate_relationship_status) {
//         generateNarrativeFromDropDown(intimate_relationship_status, 'intimate_relationship_status')
//     }
//     if (intimate_satisfaction_level || intimate_relationship_status) {
//         if (intimate_relationship_status) {
//             if (!intimate_satisfaction_level) {
//                 str = `${str} and their satisfaction level of the relationship is`
//             } else {
//                 str = `${str} and`
//             }
//         }
//         generateNarrativeFromDropDown(intimate_satisfaction_level, 'intimate_satisfaction_level')
//     }
//     if (intimate_relationship_status || intimate_satisfaction_level) {
//         str = `${str}.`
//     }

//     if (sexual_orientation) {
//         generateNarrativeFromDropDown(sexual_orientation, 'sexual_orientation')
//     }

//     if (current_living_situation) {
//         str = `${str} \n\n`
//         generateNarrativeFromDropDown(current_living_situation, 'current_living_situation')
//     }

//     if (currently_live_with_opt) {
//         getNarrative_live_with(
//             currently_live_with_opt,
//             'live_with_opt',
//             defaultNarrativeStrings['currently_live_with_opt'],
//         )
//     }

//     if (participate_in_spritual_act) {
//         if (participate_in_spritual_act === 'yes') {
//             str = `${str} The patient currently participates in spiritual activities.`
//         } else {
//             str = `${str} The patient does not currently participate in spiritual activities.`
//         }
//         str = `${str} \n\n`
//     }

//     if (current_occupation_status) {
//         generateNarrativeFromDropDown(current_occupation_status, 'current_occupation_status')
//     }

//     if (current_yearly_income) {
//         generateNarrativeFromDropDown(current_yearly_income, 'current_yearly_income')
//     }

//     if (longest_period_employment?.employment_start && longest_period_employment.employment_end) {
//         const { employment_start, employment_end, desc } = longest_period_employment
//         const result = defaultNarrativeStrings['longest_period_employmentment']?.(
//             moment(employment_start).format('MM/DD/YYYY'),
//             moment(employment_end).format('MM/DD/YYYY'),
//             desc,
//         )
//         str = `${str} ${result}`
//     }

//     if (
//         longest_period_unemployment?.unemployment_start &&
//         longest_period_unemployment.unemployment_end
//     ) {
//         const { unemployment_start, unemployment_end, desc } = longest_period_unemployment

//         const result = defaultNarrativeStrings['longest_period_employmentment']?.(
//             moment(unemployment_start).format('MM/DD/YYYY'),
//             moment(unemployment_end).format('MM/DD/YYYY'),
//             desc,
//             true,
//         )
//         str = `${str} ${result}`
//     }

//     return str.trim()
// }

const general_social_history = ({ values, master }) => {
    const convert = (data) => {
        const {
            social_situation,
            current_marital_status = '',
            relationship_status = '',
            relationship_satisfactions = '',
            sexual_orientation = '',
            living_situation = '',
            housemates,
            spiritually_active,
            employment_status = '',
            annual_income = '',
            start_unemployment = '',
            end_unemployment = '',
        } = data

        return {
            social_situation: (() => {
                if (social_situation?.length) {
                    const hashText = social_situation.map((text, index) => {
                        return `${index === 0 ? '' : ', '}${text}`
                    })
                    return hashText
                }
                return ''
            })(),
            current_marital_status,
            relationship_status,
            relationship_satisfactions,
            sexual_orientation,
            living_situation,
            housemates: (() => {
                if (housemates?.length) {
                    const hashText = housemates.map((text, index) => {
                        return `${index === 0 ? '' : ', '}${text}`
                    })
                    return hashText
                }
                return ''
            })(),
            spiritually_active: spiritually_active ? 'Yes' : 'No',
            employment_status,
            annual_income,
            longest_period_of_unemployment: (() => {
                const start = start_unemployment
                    ? moment(start_unemployment).format('MM/DD/YYYY')
                    : ''
                const end = end_unemployment ? moment(end_unemployment).format('MM/DD/YYYY') : ''
                return `${start}${start ? ' - ' : ''}${end}`
            })(),
        }
    }

    const { social_history_list = [] } = values
    let str = ''

    str = `The patient has a general social history: \n`
    str = social_history_list.reduce((init, item, index) => {
        const {
            social_situation,
            current_marital_status,
            relationship_status,
            relationship_satisfactions,
            sexual_orientation,
            living_situation,
            housemates,
            spiritually_active,
            employment_status,
            annual_income,
            longest_period_of_unemployment,
        } = convert(item)
        const row = `${index + 1
            }. Social Situation: ${social_situation}; Current marital status: ${current_marital_status}; Relationship status: ${relationship_status}; \
Relationship satisfactions: ${relationship_satisfactions}; Sexual orientation: ${sexual_orientation}; Living situation: ${living_situation}; \
Housemates: ${housemates}; Spiritually active: ${spiritually_active}; Employment status: ${employment_status}; Annual income: ${annual_income}; \
Longest period of unemployment: ${longest_period_of_unemployment} \n`
        return init + row
    }, str)

    return str.trim()
}

const stressors = ({ values, master = [] }) => {
    let str = ''
    const { stressor_opt } = values

    const noneMaster = master.find((m) => m.section_name === 'level' && m.seq === 1)
    const mildMaster = master.find((m) => m.section_name === 'level' && m.seq === 2)
    const moderateMaster = master.find((m) => m.section_name === 'level' && m.seq === 3)
    const severeMaster = master.find((m) => m.section_name === 'level' && m.seq === 4)

    if (stressor_opt?.length > 0) {
        const noneOptions = stressor_opt.filter(
            (option) => option.stressor_level === noneMaster?._id,
        )
        const mildOptions = stressor_opt.filter(
            (option) => option.stressor_level === mildMaster?._id,
        )
        const moderateOptions = stressor_opt.filter(
            (option) => option.stressor_level === moderateMaster?._id,
        )
        const severeOptions = stressor_opt.filter(
            (option) => option.stressor_level === severeMaster?._id,
        )

        const defaultStrings = {
            none: `There was no stress reported in the following areas:`,
            other: `Areas of stress included the following:`,
        }

        const otherOptions = []

        if (severeOptions?.length > 0) {
            otherOptions.push({
                type: 'severe',
                options: severeOptions,
            })
        }
        if (moderateOptions?.length > 0) {
            otherOptions.push({
                type: 'moderate',
                options: moderateOptions,
            })
        }
        if (mildOptions?.length > 0) {
            otherOptions.push({
                type: 'mild',
                options: mildOptions,
            })
        }

        if (otherOptions?.length > 0) {
            str = `${str} ${defaultStrings.other}`
            otherOptions.forEach((option, index, arr) => {
                str = `${str} ${option.type} stress due to`

                option.options.forEach((categoryOption, innerIndex, innerArr) => {
                    let stressName = ''

                    const filteredCategory = master.find(
                        (m) => m._id === categoryOption.stressor_category,
                    )

                    if (innerArr?.length > 1 && innerArr?.length - 1 === innerIndex) {
                        str = `${str} and`
                    } else if (innerIndex > 0) {
                        str = `${str}, `
                    }

                    stressName = filteredCategory?.name?.toLowerCase() || ''
                    str = `${str} ${stressName}`
                })

                if (arr?.length > 1 && arr?.length - 2 === index) {
                    str = `${str} concerns, and`
                } else if (index >= 0) {
                    str = `${str}`

                    if (arr?.length - 1 !== index) {
                        str = `${str} concerns,`
                    }
                }
            })
            str = `${str} concerns.`
        }

        if (noneOptions?.length > 0) {
            str = `${str} ${defaultStrings.none}`
            noneOptions.forEach((option, index, arr) => {
                let stressName = ''

                const filteredCategory = master.find((m) => m._id === option.stressor_category)

                if (arr?.length > 1 && arr?.length - 1 === index) {
                    str = `${str} and`
                } else if (index > 0) {
                    str = `${str}, `
                }
                stressName = filteredCategory?.name?.toLowerCase() || ''
                str = `${str} ${stressName} `
            })
            str = `${str} concerns.`
        }
    }
    return str.trim()
}

const dev_and_edu_history = ({ values, master }) => {
    const {
        during_preg_prob,
        complic_after_your_birth,
        difficulties_in_reaching,
        childhood_atmos,
        childhood_challenges,
        have_prob_in_school,
        additional_schooling,
        highest_edu_level,
        comment_on_dev_and_edu,
    } = values
    let str = ''

    const getNarrative = (value, key, sectionName, defaultStr = '') => {
        const options = value?.hasOwnProperty('options') ? [...value[key]] : []

        const noneMaster = master.find(
            (m) => m.name === 'None of these' && m.section_name === sectionName && m.seq === 1,
        )

        if (value['other']) {
            options.push({
                key: 'other',
                val: value['other'],
            })
        }

        const noneOption = options.find((o) => o === noneMaster?._id)

        if (noneOption) {
            str = `${str} ${defaultNarrativeStrings[`${sectionName}_none`]}`
        }

        const filteredOptions = options.filter((o) => o !== noneMaster?._id)
        if (filteredOptions?.length > 0) {
            str = `${str} ${defaultStr}`
            filteredOptions.forEach((option, index, arr) => {
                let problemName = ''

                const filtered = master.find((m) => m._id === option)

                if (arr?.length > 1 && arr?.length - 1 === index) {
                    str = `${str} and`
                } else if (index > 0) {
                    str = `${str}, `
                }

                if (option.key === 'other') {
                    problemName = option.val
                } else {
                    problemName = (filtered?.label || filtered?.name)?.toLowerCase() || ''
                }
                str = `${str} ${problemName}`
            })
            str = `${str}.`
        }
    }

    const generateNarrativeFromDropDown = (value, key) => {
        const filteredMaster = master.find((m) => m._id === value)
        if (filteredMaster?.name) {
            const result = defaultNarrativeStrings[key]?.(filteredMaster?.name) || ''
            str = `${str} ${result}`
        }
    }

    const generateNarrativeFromTextBox = (value, key) => {
        const result = defaultNarrativeStrings[key]?.(value)
        if (result) {
            str = `${str} ${result}`
        }
    }

    if (during_preg_prob) {
        getNarrative(
            during_preg_prob,
            'options',
            'during_preg_prob',
            defaultNarrativeStrings['during_preg_prob'],
        )

        str = `${str} \n\n`
    }

    if (complic_after_your_birth !== '') {
        const result =
            defaultNarrativeStrings['complic_after_your_birth']?.(complic_after_your_birth) || ''
        if (result) {
            str = `${str} ${result}`
        }
        str = `${str} \n\n`
    }

    if (difficulties_in_reaching) {
        getNarrative(
            difficulties_in_reaching,
            'options',
            'difficulties_in_reaching',
            defaultNarrativeStrings['difficulties_in_reaching'],
        )
        str = `${str} \n\n`
    }

    if (childhood_atmos) {
        getNarrative(
            childhood_atmos,
            'options',
            'childhood_atmos',
            defaultNarrativeStrings['childhood_atmos'],
        )
        str = `${str} \n\n`
    }

    if (childhood_challenges) {
        getNarrative(
            childhood_challenges,
            'options',
            'childhood_challenges',
            defaultNarrativeStrings['childhood_challenges'],
        )
        str = `${str} \n\n`
    }

    if (have_prob_in_school) {
        getNarrative(
            have_prob_in_school,
            'options',
            'have_prob_in_school',
            defaultNarrativeStrings['have_prob_in_school'],
        )
        str = `${str} \n\n`
    }

    if (additional_schooling) {
        getNarrative(
            additional_schooling,
            'options',
            'additional_schooling',
            defaultNarrativeStrings['additional_schooling'],
        )
        str = `${str} \n\n`
    }

    if (highest_edu_level) {
        generateNarrativeFromDropDown(highest_edu_level, 'highest_edu_level')
        str = `${str} \n\n`
    }

    if (comment_on_dev_and_edu) {
        generateNarrativeFromTextBox(comment_on_dev_and_edu, 'comment_on_dev_and_edu')
        str = `${str} \n\n`
    }

    return str.trim()
}

const counselling_and_coordination_care = ({ values, master }) => {
    const {
        counselling,
        medication_management,
        diagnosis_and_prog,
        patient_self_mgmt,
        recent_stressors,
        referred_patient,
        academic_assesment,
        coordination_care,
    } = values
    console.log('valuessss', values)
    let str = ''

    const getNarrative = (value, sectionName, defaultStr = '', descInBracket) => {
        const options = value

        const noneMaster = master.find(
            (m) => m.name === 'None of these' && m.section_name === sectionName && m.seq === 1,
        )

        if (value['other']) {
            options.push({
                key: 'other',
                val: value['other'],
            })
        }

        const noneOption = options.find((o) => o === noneMaster?._id)

        if (noneOption) {
            str = `${str} ${defaultNarrativeStrings[`${sectionName}_none`]}`
        }

        const filteredOptions = options.filter((o) => o !== noneMaster?._id)
        if (filteredOptions?.length > 0) {
            str = `${str} ${defaultStr}`
            filteredOptions.forEach((option, index, arr) => {
                let problemName = ''

                const filtered = master.find((m) => m._id === option.id)

                if (arr?.length > 1 && arr?.length - 1 === index) {
                    str = `${str} and`
                } else if (index > 0) {
                    str = `${str}, `
                }

                if (descInBracket) {
                    problemName = `${filtered?.section === 5
                        ? filtered?.label?.toLowerCase()
                        : filtered?.name?.toLowerCase() || ''
                        }${option.desc ? ` [${option.desc}]` : ''}`
                } else {
                    if (option.desc) {
                        problemName = option.desc
                    } else {
                        problemName = filtered?.name?.toLowerCase() || ''
                    }
                }

                str = `${str} ${problemName}`
            })
            str = `${str}.`
        }
    }

    if (counselling?.length > 0) {
        counselling.forEach((option, index, arr) => {
            const filteredOption = master.find((m) => m._id === option.id)
            if (filteredOption) {
                str = `${str} ${filteredOption.name}.`
            }
        })
    }

    if (medication_management?.length > 0) {
        getNarrative(
            medication_management,
            'medication_management',
            defaultNarrativeStrings['medication_management'],
        )
    }

    const checkIncludes = (strNrrative, defaulString) => {
        if (
            strNrrative.includes('Counseling provided to the patient/caregiver as outlined below.')
        ) {
            return defaulString
        }
        return `Counseling provided to the patient/caregiver as outlined below. ${defaulString}`
    }

    if (diagnosis_and_prog?.length > 0) {
        getNarrative(
            diagnosis_and_prog,
            'diagnosis_and_prog',
            checkIncludes(str, defaultNarrativeStrings['diagnosis_and_prog']),
        )
    }

    if (patient_self_mgmt?.length > 0) {
        getNarrative(
            patient_self_mgmt,
            'patient_self_mgmt',
            checkIncludes(str, defaultNarrativeStrings['patient_self_mgmt']),
        )
    }

    if (recent_stressors?.length > 0) {
        getNarrative(
            recent_stressors,
            'recent_stressors',
            checkIncludes(str, defaultNarrativeStrings['recent_stressors']),
        )
        str = `${str} \n\n`
    }

    if (coordination_care?.length > 0) {
        const descInBracket = true
        getNarrative(
            coordination_care,
            'coordination_care',
            defaultNarrativeStrings['coordination_care'],
            descInBracket,
        )
        str = `${str} \n\n`
    }
    if (referred_patient?.length > 0) {
        const descInBracket = true
        getNarrative(
            referred_patient,
            'referred_patient',
            defaultNarrativeStrings['referred_patient'],
            descInBracket,
        )
        str = `${str} \n\n`
    }

    if (academic_assesment?.length > 0) {
        getNarrative(
            academic_assesment,
            'academic_assesment',
            defaultNarrativeStrings['academic_assesment'],
        )
        str = `${str.replace('iep', 'IEP')} \n\n`
    }

    return str.trim()
}

const source_of_information = ({ values, master }) => {
    const { informations } = values
    let str = ''

    if (informations?.length > 0) {
        informations.forEach((information, index, arr) => {
            const { role, custom_role, name, comment } = information
            console.log('roleMaster role', role)
            if (role) {
                const roleMaster = master.find((m) => m._id === role)
                const isPatient = roleMaster?.name?.toLowerCase() === 'patient'

                if (roleMaster?.name?.toLowerCase() === 'other provider') {
                    str = `${str}Another of the patient's providers${name ? `, ${name},` : ''
                        } was a source of information used to complete the history documented in this note. ${comment ? `Additionally, ${comment}` : ''
                        }`
                } else if (roleMaster?.name?.toLowerCase() === 'other family') {
                    str = `${str}Another family member${name ? `, ${name},` : ''
                        } was a source of information used to complete the history documented in this note. ${comment ? `Additionally, ${comment}` : ''
                        }`
                } else {
                    str = `${str}The patient${isPatient ? '' : `'s ${roleMaster?.name.toLowerCase() ?? ''}`
                        } ${name ? `, ${name},` : ''
                        } was a source of information used to complete the history documented in this note. ${comment ? `Additionally, ${comment}` : ''
                        }`
                }
            } else if (custom_role) {
                str = `${str} ${custom_role} ${name ? `, ${name},` : ''
                    } was a source of information used to complete the history documented in this note. ${comment ? `Additionally, ${comment}` : ''
                    }`
            }
            str = `${str}. \n\n`
        })
    }
    return str.trim()
}

// const menstruation_and_pregnancy_history = ({ values, master }) => {
//     const {
//         begin_menstruation_age,
//         premenstrual_symptoms,
//         contraception,
//         ever_been_pregnent,
//         ever_given_birth,
//         had_any_miscarriage,
//         had_any_abortion,
//         how_many_times_pregnent,
//         no_of_given_birth,
//         no_of_miscarriage,
//         no_of_abortion,
//     } = values
//     let str = ''

//     const getNarrative = (value, sectionName, defaultStr = '') => {
//         const options = [...value]

//         const noneMaster = master.find(
//             (m) => m.name === 'None of these' && m.section_name === sectionName && m.seq === 1,
//         )

//         if (value['other']) {
//             options.push({
//                 key: 'other',
//                 val: value['other'],
//             })
//         }

//         const noneOption = options.find((o) => o === noneMaster?._id)

//         if (noneOption) {
//             str = `${str} ${defaultNarrativeStrings[`${sectionName}_none`]}`
//         }

//         const filteredOptions = options.filter((o) => o !== noneMaster?._id)
//         if (filteredOptions?.length > 0) {
//             str = `${str} ${defaultStr}`
//             filteredOptions.forEach((option, index, arr) => {
//                 let problemName = ''

//                 const filtered = master.find((m) => m._id === option)
//                 console.log('filtered', filtered)

//                 if (filtered.seq === 6 && filtered.section === 2) {
//                     problemName = 'The patient reports being permanently unable to conceive.'
//                 } else {
//                     if (option.key === 'other') {
//                         problemName = option.val
//                     } else {
//                         problemName =
//                             filtered?.label?.toLowerCase() || filtered?.name?.toLowerCase() || ''
//                     }
//                 }
//                 str = `${str} ${problemName.replace('of contraception', '')}`
//                 if (arr?.length > 1 && arr?.length - 2 === index) {
//                     str = `${str} and`
//                 } else if (index < arr?.length - 1) {
//                     str = `${str},`
//                 }
//             })
//         }
//     }

//     if (begin_menstruation_age) {
//         str = `${str} The patient first began menstruation at age ${begin_menstruation_age}`
//     }

//     if (premenstrual_symptoms) {
//         getNarrative(
//             premenstrual_symptoms,
//             'premenstrual_symptoms',
//             defaultNarrativeStrings['premenstrual_symptoms'],
//         )
//         str = `${str.trim()}.`
//     }

//     if (contraception) {
//         const { other, contraception_opt } = contraception
//         if (contraception_opt?.length > 0) {
//             getNarrative(
//                 contraception_opt,
//                 'contraception_opt',
//                 defaultNarrativeStrings['contraception_opt'],
//             )
//             // str = `${str.replace('of contraception', '')}`
//             str = `${str} of contraception.`
//         }
//         if (other) {
//             str = `${str} ${defaultNarrativeStrings.contraception_methods_other(other)}`
//         }
//     }

//     str = `${str} \n\n`

//     const radioOptions = [
//         {
//             name: 'ever_been_pregnent',
//             value: ever_been_pregnent,
//             number: how_many_times_pregnent,
//         },
//         {
//             name: 'ever_given_birth',
//             value: ever_given_birth,
//             number: no_of_given_birth,
//         },
//         {
//             name: 'had_any_miscarriage',
//             value: had_any_miscarriage,
//             number: no_of_miscarriage,
//         },
//         {
//             name: 'had_any_abortion',
//             value: had_any_abortion,
//             number: no_of_abortion,
//         },
//     ]

//     const trueOptions = radioOptions.filter((option) => option.value === true && option.number)
//     const falseOptions = radioOptions.filter((option) => option.value === false)

//     if (trueOptions?.length > 0) {
//         str = `${str} The patient has`
//         trueOptions.forEach((option, index, arr) => {
//             const optionText = defaultNarrativeStrings[option.name]?.(option.number)

//             str = index === arr?.length - 1 ? `${str} ${optionText}` : `${str} ${optionText},`
//             if (arr?.length > 1 && arr?.length - 2 === index) {
//                 str = `${str} and`
//             } else if (index > 0 && index < arr?.length - 2) {
//                 str = `${str}`
//             }
//         })
//         str = `${str}.`
//     }

//     if (falseOptions?.length > 0) {
//         str = `${str} The patient`
//         falseOptions.forEach((option, index, arr) => {
//             const optionText = defaultNarrativeStrings[`${option.name}_not`]

//             str = `${str} ${optionText}`
//             if (arr?.length > 1 && arr?.length - 2 === index) {
//                 str = `${str} and`
//             } else if (index > 0 && index < arr?.length - 2) {
//                 str = `${str}, `
//             }
//         })
//         str = `${str}.`
//     }

//     return str.trim()
// }

const menstruation_and_pregnancy_history = ({ values, master }) => {
    const convert = (data) => {
        const {
            age_of_first_menstruation = '',
            premenstrual_symptoms,
            contraception,
            number_of_pregnancies = '',
            number_of_births = '',
            number_of_miscarriages = '',
            number_of_abortions = '',
        } = data

        return {
            premenstrual_symptoms: (() => {
                if (premenstrual_symptoms?.length) {
                    const hashText = premenstrual_symptoms.map((text, index) => {
                        return `${index === 0 ? '' : ', '}${text}`
                    })
                    return hashText
                }
                return ''
            })(),
            age_of_first_menstruation,
            number_of_pregnancies,
            number_of_births,
            number_of_miscarriages,
            number_of_abortions,
            contraception: (() => {
                if (contraception?.length) {
                    const hashText = contraception.map((text, index) => {
                        return `${index === 0 ? '' : ', '}${text}`
                    })
                    return hashText
                }
                return ''
            })(),
        }
    }

    const { menstruation_pregnancy_history_list = [] } = values
    let str = ''

    str = `The patient has a gmenstruation and pregnancy history: \n`
    str = menstruation_pregnancy_history_list.reduce((init, item, index) => {
        const {
            age_of_first_menstruation,
            premenstrual_symptoms,
            contraception,
            number_of_pregnancies,
            number_of_births,
            number_of_miscarriages,
            number_of_abortions,
        } = convert(item)
        const row = `${index + 1
            }. Age of first menstruation: ${age_of_first_menstruation}; Premenstrual symptoms: ${premenstrual_symptoms}; Contraception: ${contraception}; \
Number of pregnancies: ${number_of_pregnancies}; Number of births: ${number_of_births}; Number of miscarriages: ${number_of_miscarriages}; \
Number of abortions: ${number_of_abortions} \n`
        return init + row
    }, str)

    return str.trim()
}

const system_reviews = ({ values, master }) => {
    const { system_reviews_opt } = values
    let str = ''

    const arr = []
    master.forEach((el) => {
        const item = system_reviews_opt.find((sro) => sro.header === el._id)
        if (item) {
            const reviews_options = item.reviews_options
                .map((ro) => el.reviews_options.find((item) => item._id === ro))
                .sort((a, b) => a.seq - b.seq)
            arr.push({
                ...item,
                header_name: el.header_name,
                reviews_options,
            })
        }
    })

    const contributory = arr.filter(
        (sro) =>
            !!sro.other ||
            (!!sro.reviews_options?.length && sro.reviews_options.some((ro) => !ro.is_none)),
    )
    if (contributory?.length) {
        str +=
            'The list of physical symptoms that the patient has experienced in the last several days: \n'
        contributory.forEach((sro) => {
            const text = sro.reviews_options.reduce((init, ro) => {
                return `${init}${ro.is_none ? '' : ` ${ro.name};`}`
            }, `${sro.header_name}:`)
            str += `  • ${text}${sro.other ? ` ${sro.other};` : ''} \n`
        })
    }

    const noncontributory = arr.filter(
        (sro) =>
            !!sro.reviews_options?.length &&
            sro.reviews_options.some((ro) => ro.is_none) &&
            !sro.other,
    )
    if (noncontributory?.length) {
        const text = noncontributory.reduce((init, sro, index) => {
            return `${init} ${sro.header_name}${index === noncontributory?.length - 1 ? '.' : ', '}`
        }, 'Other than previously stated, the review of systems and organs is noncontributory for')
        str += text
    }

    // const noneOptions = []

    // const generateNarrative = (systemReviewOption) => {
    //     const selectedMaster =
    //         master.find((review) => review._id === systemReviewOption.header) || {}
    //     const headerName = (selectedMaster.header_label || selectedMaster.header_name || '')
    //         .toLowerCase()
    //         .split('(')[0]
    //     let reviewsOptions = [...systemReviewOption.reviews_options]

    //     const filteredNonemaster = selectedMaster?.reviews_options?.find((option) =>
    //         option.name?.includes('None of the above'),
    //     )

    //     const filteredNoneOption = systemReviewOption?.reviews_options?.find(
    //         (option) => option === filteredNonemaster?._id,
    //     )
    //     if (filteredNoneOption) {
    //         noneOptions.push(headerName)
    //     }

    //     if (systemReviewOption.other) {
    //         reviewsOptions.push({
    //             key: 'other',
    //             value: systemReviewOption.other,
    //         })
    //     }

    //     reviewsOptions = reviewsOptions.filter((option) => option !== filteredNoneOption)
    //     const isChecked = reviewsOptions.includes('62041af84ae9797ddc2f8586') // returns true

    //     if (isChecked) {
    //         str = `${str} An in-depth review of psychiatric system appears earlier in document.`
    //         reviewsOptions.splice(reviewsOptions.indexOf('62041af84ae9797ddc2f8586'), 1)
    //     }

    //     if (reviewsOptions?.length > 0) {
    //         str = `${str} The patient reported`

    //         const sortedValues = sortValues(reviewsOptions, selectedMaster?.reviews_options || [])
    //         sortedValues.forEach((option, index, arr) => {
    //             let problemName = ''

    //             const filtered = selectedMaster?.reviews_options?.find((m) => m._id === option)

    //             if (arr?.length > 1 && arr?.length - 1 === index) {
    //                 str = `${str} and`
    //             } else if (index > 0) {
    //                 str = `${str}, `
    //             }

    //             if (option.key === 'other') {
    //                 problemName = option.value
    //             } else {
    //                 problemName =
    //                     (filtered?.label || filtered?.name)?.toLowerCase().split('(')[0] || ''
    //             }
    //             str = `${str} ${problemName}`
    //         })
    //         str = `${str} but no other ${headerName} issues.`
    //     }
    // }

    // if (system_reviews_opt?.length > 0) {
    //     system_reviews_opt.forEach((systemReviewOption) => {
    //         generateNarrative(systemReviewOption)
    //     })
    //     str = `${str} \n\n`
    // }

    // if (noneOptions?.length > 0) {
    //     str = `${str} Other than previously stated, the review of systems and organs is noncontributory for`

    //     noneOptions.forEach((option, index, arr) => {
    //         if (arr?.length > 1 && arr?.length - 1 === index) {
    //             str = `${str} and`
    //         } else if (index > 0) {
    //             str = `${str}, `
    //         }

    //         str = `${str} ${option}`
    //     })
    //     str = `${str} issues.`
    // }

    return str.trim()
}

const plan_med_changes = ({ values, master }) => {
    const { medication_list, plan_med_opt } = values

    let str = ''

    if (medication_list?.length) {
        str = medication_list.reduce((init, item, index) => {
            // const { drug_name, strength, provider_name, days_supply, quantity, refills, action_status, date_written } = item
            const { drug_name, strength, prescriber, days_supply, quantity, refills, action, date_written } = item
            const row = `${index + 1
                }. Drug name: ${drug_name}; Strength: ${strength}; Provider: ${prescriber}; Days Supply: ${days_supply}; Quantity: ${quantity}; Refills: ${refills};Action Status:${action}; Date Written:${date_written} \n${index === medication_list?.length - 1 ? '\n' : ''
                }`
            return init + row
        }, str)
    }

    if (plan_med_opt?.length) {
        str = plan_med_opt.reduce((init, item, index) => {
            if (!item?.label) return init
            const row = `${item.label} \n${index === plan_med_opt?.length - 1 ? '\n' : ''}`
            return init + row
        }, str)
    }

    return str.trim()
}

const suicide_preventive_plan = ({ values, master }) => {
    const {
        address_immense_risk = [],
        addressing_diag = [],
        addressing_symptoms = [],
        addressing_social_factors = [],
    } = values
    let str = ''

    if (address_immense_risk?.length) {
        str += 'Addressing Imminent Risk: \n'
        str = address_immense_risk.reduce((init, item, index) => {
            if (!item?.label) return init
            const row = `  • ${item.label} \n`
            return init + row
        }, str)
        str += '\n'
    }
    if (addressing_diag?.length) {
        str += 'Addressing Diagnoses: \n'
        str = addressing_diag.reduce((init, item, index) => {
            if (!item?.label) return init
            const row = `  • ${item.label} \n`
            return init + row
        }, str)
        str += '\n'
    }
    if (addressing_symptoms?.length) {
        str += 'Addressing Symptoms: \n'
        str = addressing_symptoms.reduce((init, item, index) => {
            if (!item?.label) return init
            const row = `  • ${item.label} \n`
            return init + row
        }, str)
        str += '\n'
    }
    if (addressing_social_factors?.length) {
        str += 'Addressing Social Factors: \n'
        str = addressing_social_factors.reduce((init, item, index) => {
            if (!item?.label) return init
            const row = `  • ${item.label} \n`
            return init + row
        }, str)
        str += '\n'
    }

    return str.trim()
}

const suicide_risk_factors = ({ values, master }) => {
    const { is_patient_screened, suicide_risk_factors = [] } = values
    let str = ''

    if (is_patient_screened?.check) {
        str += is_patient_screened.label
    }
    if (suicide_risk_factors?.length) {
        str += 'The following risk factors for suicide/self-harm exist for this patient:'
        str = suicide_risk_factors.reduce((init, item, index) => {
            const row = ` ${item.label}${index !== suicide_risk_factors?.length - 1 ? ';' : '. \n'}`
            return init + row
        }, str)
    }

    return str.trim()
}

const suicide_protective_factors = ({ values, master }) => {
    const { protective_factors = [] } = values
    let str = ''

    if (protective_factors?.length) {
        str += ' The following protective factors from suicide/self-harm exist for this patient:'
        str = protective_factors.reduce((init, item, index) => {
            const row = ` ${item.label}${index !== protective_factors?.length - 1 ? ';' : '. \n'}`
            return init + row
        }, str)
    }

    return str.trim()
}

const plan_next_appointment = ({ values, master }) => {
    let str = ''

    const { next_appt, plan_next_opt = [] } = values

    if (next_appt) {
        str += `Next appointment scheduled: ${next_appt.day} ${next_appt.date} at ${next_appt.start_time} - ${next_appt.end_time} ${next_appt.timezone} \n\n`
    }

    if (plan_next_opt?.length) {
        str = plan_next_opt.reduce((init, item) => {
            const row = `• ${item.label} \n`
            return init + row
        }, str)
    }

    return str.trim()
}

const therapy_plan = ({ values, master }) => {
    let str = ''

    const { therapy = [] } = values

    if (therapy?.length) {
        str = therapy.reduce((init, item) => {
            const row = `- ${item.label} \n`
            return init + row
        }, str)
    }

    return str.trim()
}

const exam = ({ values, master }) => {
    let str = ''

    const keys = []
    Object.keys(values).forEach((key) => {
        if (values[key]?.length) keys.push(key)
    })

    if (keys?.length) {
        const arr = []
        Object.keys(master).forEach((key) => {
            if (keys.find((k) => k === master[key][0].section_name)) {
                const title = master[key][0].section_text
                const data = values[master[key][0].section_name]
                arr.push({
                    title,
                    data,
                })
            }
        })

        if (arr?.length) {
            str += 'Observed during exam: \n'
            arr.forEach((el) => {
                const row = el.data.reduce((init, option, index) => {
                    return `${init}${option.desc ? `${option.label} ( ${option.desc} )` : option.label
                        }${index === el.data?.length - 1 ? '.' : '; '}`
                }, `  • ${el.title}: `)
                str += `${row} \n`
            })
        }
    }

    if (values.other) {
        str += `${values.other} \n`
    }

    return str.trim()
}

export default {
    present_in_sessions,
    inpatient_psychiatric_history,
    outpatient_psychiatric_history,
    family_history,
    assessment_informed_consent,
    violence_history,
    suicide_history,
    subtance_abuse_history,
    past_medical_history,
    subtance_abuse_treat_history,
    subtance_use_consequences,
    general_social_history,
    stressors,
    dev_and_edu_history,
    counselling_and_coordination_care,
    source_of_information,
    menstruation_and_pregnancy_history,
    system_reviews,
    allergy,
    past_history,
    side_effects,
    adherence,
    psychiatric_medication_history,
    allergies,
    plan_med_changes,
    suicide_preventive_plan,
    plan_next_appointment,
    suicide_risk_factors,
    suicide_protective_factors,
    exam,
    therapy_plan,
}
