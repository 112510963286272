import {
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    makeStyles,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import FlagIcon from '@mui/icons-material/Flag'
import { Box } from '@mui/material'
import clsx from 'clsx'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { OPTIONS_TIMEZONES } from 'constants/states'
import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
    createPatientAlert,
    getPatientAlert,
    putPatient,
    deletePatientAlert,
    getPatient,
} from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import { capitalizeFirstLetter } from 'utilities/stringOperations'
import { getPatientGenderAndBirthDate, getPatientName } from '../shared'
import { PHARMACY_CONDITION_TYPE } from './constant'
import { Popover } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import AlertNoteField from 'shared/elements/AlertNoteField'
import Skeleton from '@mui/material/Skeleton'
import Alert_svg from '../../../assests/icons/alert.svg'
import { getCustomizedFormattedDate, getTimeFromDate } from 'utilities/dateOperations'
import TourIcon from '@mui/icons-material/Tour'
import { shortenString } from 'utilities/stringOperations'
import { ReactComponent as DemographicIcon } from '../../../assests/icons/demographics.svg'
import { ReactComponent as ChangeIcon } from '../../../assests/icons/change_icon.svg'
import { ReactComponent as TelephoneIcon } from '../../../assests/icons/telephone_icon.svg'
import { ReactComponent as PhoneIcon } from '../../../assests/icons/phone.svg'
import { ReactComponent as EmailIcon } from '../../../assests/icons/email_icon.svg'
import { ReactComponent as ShareContact } from '../../../assests/icons/share_contact.svg'
import { ReactComponent as ReportIcon1 } from '../../../assests/images/report_icon_1.svg'
import '../styles.scss'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { allApiReffringProvider } from 'services/Calendar'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import Routes from 'constants/routes'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import ReportIcon from '@mui/icons-material/Report'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { ReactComponent as BlueWarningIcon } from '../../../assests/icons/blue_warning_icon.svg'
import SharedContactAlert from './sharedcontactcomponent/SharedContactAlert'
import { ReactComponent as PatientTranslatorIcon } from 'assests/icons/patient_translator.svg'

const useStyles = makeStyles(() => ({
    capitalized: {
        textTransform: 'capitalize',
    },
    group: {
        // borderBottom: '1px solid #DCE7F1',
        width: '64%',
        paddingBottom: 8,
        margin: 0,
        marginTop: 12,
        transition: 'height 0.2s',
    },
    group_title: {
        fontWeight: 500,
        fontSize: 14,
        padding: '10px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    group_title_icon: {
        fontSize: 20,
        marginTop: -3,
        color: '#707070',
        cursor: 'pointer',
        transition: 'transform 0.2s',
    },
    group_title_icon_active: {
        transform: 'rotate(180deg)',
        transition: 'transform 0.2s',
    },
    group_items: {
        overflow: 'hidden',
        padding: '10px 0',
    },
    group_items_hidden: {
        display: 'none',
    },
    header_card: {
        fontWeight: 500,
        fontSize: 19,
        color: '#303E4E',
        // marginBottom: 7,
        padding: '5px 0px',
        width: 'max-content',
    },
    header_description: {
        fontWeight: 400,
        fontSize: 13,
        color: '#667689',
        display: 'flex',
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        background: '#c0c1c5',
        // marginRight: 24,
    },
    black_color: {
        color: '#060D21',
        fontWeight: 500,
    },
    tag_cds: {
        padding: '4px 8px',
        background: '#EFF2F5',
        borderRadius: '5px',
    },
    blue_color: {
        color: '#5571C6',
    },
    text_highlight: {
        color: '#303E4E',
    },
    text_bold: {
        fontWeight: 600,
    },
    text_break: {
        overflowWrap: 'anywhere',
        // textAlign: 'end'
    },
    btnSchedule: {
        background: '#5571c6',
        color: '#fff',
        padding: '6px 10px',
        borderRadius: '5px',
        fontSize: '14px',
        cursor: 'pointer',
        width: 'max-content',
    },
    wrapTz: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '15px',
    },
    titleTz: {
        width: '100%',
        marginBottom: '5px',
    },
    rightTz: {
        width: '100%',
        fontWeight: 500,
    },
    noteWrap: {
        display: 'flex',
        flexDirection: 'column',
        // borderBottom: '1px solid #DCE7F1',
        width: '34%',
        paddingBottom: 8,
        // margin: 0,
        // marginTop: 12,
        transition: 'height 0.2s',
    },
    topContainer: {
        height: '230px',
        // borderRadius: '5px',
        border: '1px solid #E4E8EE',
        background: '#FFF',
        overflow: 'scroll',
        borderRadius: '5px 5px 0px 0px',
        // borderBottom: '0px !important',
    },
    topContainerExpand: {
        height: '321px !important',
    },
    patientInfoVerticalDivider: {
        marginTop: '5px',
        height: '89%',
        borderRight: '1px solid #E2E3E5',
    },
    patientInfoVerticalDividerExpand: {
        height: '88% !important',
    },
    staffNoteCardContainer: {
        display: 'flex',
        // justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        padding: '2px',
    },
    staffNoteCardSubContainer: {
        display: 'flex',
        width: 'auto',
        flexWrap: 'wrap',
        maxHeight: '250px',
        overflowY: 'scroll',
        gap: '0.5px',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '103px',
        borderRadius: '5px',
        border: '1px solid #C0C1C5',
        background: '#FCFCFC',
        padding: '3px',
        marginBottom: '3px',
        marginRight: '6px',
        justifyContent: 'space-between',
        width: '124px',
    },
    patient_portal: {
        '& .MuiFormControlLabel-root .MuiCheckbox-root': {
            padding: '0 0 0 9px !important'
        }
    },
    fontStyle1: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px"
    },
    fontStyle2: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px"
    },
    confirmButton: {
        cursor: "pointer",
        display: "flex",
        width: "73px",
        height: "32px",
        // padding: "8px 20px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: "0",
        borderRadius: "5px",
        background: "#5571C6",
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
    },
    cancelButton: {
        display: "flex",
        height: "32px",
        padding: "0px 12px",
        alignItems: "center",
        cursor: "pointer",
    },
    share_contact_active: {
        "& path": {
            fill: "#5571C6"
        }
    },
    share_contact_deactive: {
        "& path": {
            fill: "#CBCBCC"
        }
    },
    patientTranslator: {
        '& g path': {
            fill: '#CACBCC !important'
        }
    }

    
}))

const CardList = ({ title, isCollapOpend, dataSource = [], children }) => {
    const classes = useStyles()

    const [isCollaped, setIsCollapsed] = useState(isCollapOpend)

    return (
        <Grid container className={classes.group}>
            <Grid item xs={12} className={classes.group_title}>
                {title}
                <KeyboardArrowDown
                    className={
                        classes.group_title_icon +
                        (!isCollaped ? ` ${classes.group_title_icon_active}` : '')
                    }
                    onClick={() => setIsCollapsed(!isCollaped)}
                />
            </Grid>

            <Grid
                container
                spacing={2}
                className={
                    classes.group_items + (!isCollaped ? ` ${classes.group_items_hidden}` : '')
                }>
                <>
                    {dataSource.map(({ value, label }, index) => (
                        <Grid container item xs={12} key={index}>
                            <Grid item xs={5} className={classes.header_description}>
                                {label}
                            </Grid>

                            <Grid
                                item
                                xs={7}
                                className={clsx(
                                    classes.header_description,
                                    classes.black_color,
                                    classes.text_break,
                                )}>
                                {value || ''}
                            </Grid>
                        </Grid>
                    ))}
                    {children}
                </>
            </Grid>
        </Grid>
    )
}

const ListOptionsTZ = [
    'US/Eastern',
    'US/Central',
    'US/Mountain',
    'US/Pacific',
    'US/Alaska',
    'US/Hawaii',
]
const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

const USPhoneRegexpRep = /(\d{1,3})(\d{0,3})(\d{0,4})/
const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

export const PatientInformation = (props) => {
    const dummyNotes = [1, 2, 3, 4, 5, 6]
    const {
        patientInfo: patient,
        fetchPatient,
        loadingPatient: isLoading,
        setLoadingPatient,
    } = props
    const history = useHistory()
    const notification = useNotification()
    const { idPatient } = useParams()
    const [loadingChageTimezone, setLoadingChageTimezone] = useState(false)
    const [timezonePatient, setTimezonePatient] = useState('')
    const [optionTz, setOptionTz] = useState([])
    const [selectTzPatient, setSelectTzPatient] = useState()

    const [createdOn, setCreatedOn] = useState('')
    const [note, setNote] = React.useState('')
    const [noteObj, setNoteObj] = React.useState(null)
    // const [alertNoteObj, setAlertNoteObj] = React.useState(null)
    const [noteTitle, setNoteTitle] = React.useState('')
    const [notes, setNotes] = React.useState([])
    const [loadingNoteSave, setLoadingNoteSave] = React.useState(false)
    const [loadingNotes, setLoadingNotes] = React.useState(false)
    const [check, setCheck] = React.useState(false)
    // const [anchorEl, setAnchorEl] = React.useState(null)
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(3)
    const [totalNote, setTotalNote] = React.useState(0)
    const [anchorAlert, setAnchorAlert] = useState(null)
    const [anchorNote, setAnchorNote] = useState(null)
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [anchorElStaffNote, setAnchorElStaffNote] = React.useState(null)
    const [patientPortalAnchorEl, setPatientPortalAnchorEl] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorEl1, setAnchorEl1] = React.useState(null)
    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const [anchorEl3, setAnchorEl3] = React.useState(null)
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const id = open ? 'simple-popover' : undefined;
    const id1 = open1 ? 'simple-popover1' : undefined;
    const id2 = open2 ? 'simple-popover2' : undefined;
    const id3 = open3 ? 'simple-popover3' : undefined;
    const noteRef = React.useRef(null)
    const saveButtonRef = React.useRef(null)
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const scrollableDiv = document.getElementById('scroll-patient')
    const [aletrTargetData, setAletrTargetData] = useState({})

    const mandatoryFields = [
        'first_name',
        'last_name',
        'dob',
        'phone_number',
        'related_person_phone',
        'address1',
        'city',
        'state',
        'zip',
    ]

    const defaultEditState = {
        patient_name: false,
        preferred_name: false,
        date_of_birth: false,
        email: false,
        primary_number: false,
        secondary_number: false,
        primary_care_physician: false,
        referring_provider: false,
        address: false,
        primary_care_physician_phone: false,
        primary_care_physician_fax: false
    }

    const defaultErrorState = {
        first_name: false,
        last_name: false,
        dob: false,
        phone_number: false,
        address1: false,
        city: false,
        state: false,
        zip: false,
    }

    const [isError, setIsError] = useState(defaultErrorState)

    const [isEdit, setIsEdit] = useState(defaultEditState)

    const [referringProviderList, setReferringProviderList] = useState([])

    const [patientEditPayload, setPatientEditPayload] = useState({
        ...patient,
        dob: moment(patient?.dob).format('MM/DD/YYYY'),
    })

    const refetchReferringProvider = async () => {
        try {
            const res = await allApiReffringProvider.getAll({
                size: 1000,
            })
            setReferringProviderList(
                res.data?.data.map((item) => ({
                    key: `${item.first_name} ${item.last_name}`,
                    value: `${item.first_name} ${item.last_name}`,
                })) || [],
            )
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }

    const returnToDefaultState = () => {
        setIsEdit(defaultEditState)
        setPatientEditPayload({ ...patient, dob: moment(patient?.dob).format('MM/DD/YYYY') })
    }

    const enterEditState = (fieldName) => {
        setIsEdit(() => {
            return {
                ...defaultEditState,
                [fieldName]: true,
            }
        })
    }

    const onEditFieldChange = (inputValue, fieldName) => {
        const value = inputValue?.trim()

        if (mandatoryFields.includes(fieldName)) {
            if (value === '' || !value) {
                setIsError((state) => ({
                    ...state,
                    [fieldName]: true,
                }))
            } else {
                setIsError((state) => ({
                    ...state,
                    [fieldName]: false,
                }))
            }
        }

        if (fieldName === 'phone_number' || fieldName === 'related_person_phone') {
            setPatientEditPayload((state) => {
                return {
                    ...state,
                    [fieldName]: formatPhoneNumber(value),
                }
            })
        }

        setPatientEditPayload((state) => {
            return {
                ...state,
                [fieldName]: value,
            }
        })
    }

    const handleSaveInlineEdit = (params = {}) => {
        if (Object.values(isError).includes(true)) {
            notification(`This field can't left blank!`, 'error')
            return true
        }
        if (
            patient?.phone_number?.length !== 14 ||
            (patient?.related_person_phone && patient?.related_person_phone?.length !== 14)
        ) {
            notification(`Invalid phone number!`, 'error')
            return true
        }
        if (
            patient?.email !== '' &&
            (patient?.email?.match(EmailRegex) === '' || !patient?.email?.match(EmailRegex))
        ) {
            notification(`Invalid email!`, 'error')
            return true
        }

        const editDob = patientEditPayload?.dob?.includes('-') ? moment(patientEditPayload?.dob)?.format('YYYY-MM-DD') : moment(new Date(patientEditPayload?.dob))?.format('YYYY-MM-DD')
        console.log("editDob :: ", editDob, patientEditPayload?.dob)

        const finalPayload = {
            ...patientEditPayload,
            dob: editDob,
            ...params
        }

        // console.log('338=>', finalPayload)
        // return
        returnToDefaultState()
        setLoadingPatient(true)
        notification(`Patient is being updated...`, 'warning')
        putPatient(patient?.id, finalPayload)
            ?.then((res) => {
                notification(`Patient updated successfully!`, 'success')
                setLoadingPatient(false)
                fetchPatient(patient?.id)
                // refetchPatient()
            })
            .catch((err) => {
                console.log(err)
                setLoadingPatient(false)
                if(err?.data?.message === "Email already exists" && err?.data?.data?.users?.length){
                    const formattedNames = err?.data?.data?.users?.length > 0 && err?.data?.data?.users.map(user => `${getPatientName({ ...user, suffix: null })}(mrn:${user.id})`);
                    const value = formattedNames.join(', ')
                    const errorMessage = `Email already exists with ${err?.data?.data?.users?.length > 1 ? 'these patients:' : 'this patient:'} ${value}`
                    notification(errorMessage, 'error')
                }else{
                    notification(err?.data?.message || `Cannot get update patient details`, 'error')
                }
            })
    }


    const formatPhoneNumber = (value) => {
        let val = value.replace(/\D/g, '')

        if (val?.length > 0) {
            if (val?.length < 4) {
                val = `(${val}`
            } else if (val?.length >= 4 && val?.length < 7) {
                val = `(${val?.substring(0, 3)}) ${val?.substring(3)}`
            } else if (val?.length >= 7) {
                val = `(${val?.substring(0, 3)}) ${val?.substring(3, 6)}-${val?.substring(6)}`
            }
        }

        return val
    }

    const formatDate = (value) => {
        const maxLength = 8
        let val = value.replace(/\D/g, '')

        if (val?.length > 0) {
            if (val?.length > 2 && val?.length < 5) {
                if (val.length === 3) val = `${val.substring(0, 2)}/${val.substring(2)}`
                else val = `${val.substring(0, 2)}/${val.substring(2)}`
            } else if (val?.length >= 5) {
                val = `${val?.substring(0, 2)}/${val?.substring(2, 4)}/${val?.substring(4)}`
            }
        }

        return val
    }

    const changeCreatedOn = (patient_timezone = patient.timezone) => {
        setCreatedOn(() => {
            if (!patient.created_date) return ''
            if (patient.created_date && patient_timezone) {
                const timezone = OPTIONS_TIMEZONES.find(
                    (item) => item.key === patient_timezone,
                )?.acronym
                return `${moment(patient.created_date)
                    .tz(patient_timezone)
                    .format('MM/DD/YYYY, hh:mm A')} ${timezone}`
            }
            return `${moment(patient.created_date).format('MM/DD/YYYY, hh:mm A')} UTC`
        })
    }
    const getAgeCalculate = (dateOfBirth) => {
        // Convert dateOfBirth string to Date object
        const dob = new Date(dateOfBirth)
        const now = new Date()

        // // Calculate the difference in milliseconds
        // const diff = now - dob

        // // Convert the difference from milliseconds to years
        // const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))


        let years = now.getFullYear() - dob.getFullYear()

        const currentMonth = now.getMonth()
        const birthMonth = dob.getMonth()

        if (currentMonth < birthMonth) {
            years--
        } else if (currentMonth === birthMonth) {
            const currentDay = now.getDate()
            const birthDay = dob.getDate()
            if (currentDay < birthDay) {
                years--
            }
        }
        return years;
    }

    useEffect(() => {
        changeCreatedOn()
    }, [patient.created_date, patient.timezone])

    useEffect(() => {
        refetchReferringProvider()
        setPatientEditPayload({ ...patient, dob: moment(patient?.dob).format('MM/DD/YYYY') })
    }, [patient])

    useEffect(() => {
        if (patient.next_appointment) {
            const start = patient.next_appointment['start']
            const end = patient.next_appointment['end']
            const options = ListOptionsTZ.map((nameTz) => ({
                tz: nameTz,
                date: moment(start).tz(nameTz),
                start: moment(start).tz(nameTz),
                end: moment(end).tz(nameTz),
            }))
            setOptionTz(options)
            setSelectTzPatient({
                tz: 'US/Eastern',
                date: moment(start).tz('US/Eastern'),
                start: moment(start).tz('US/Eastern'),
                end: moment(end).tz('US/Eastern'),
            })
        } else {
            setOptionTz([])
        }
    }, [patient?.next_appointment])

    const getPharmacyDisplay = () => {
        const { pharmacy } = patient
        if (!pharmacy || !Object.keys(pharmacy).length) return ''

        const conditiontTypeText = PHARMACY_CONDITION_TYPE.reduce((init, el, index) => {
            let value = ''
            if (pharmacy?.[el.key] === 'y') value = `(${el.value}) `
            return init + value
        }, '')

        const { Name, Address1, City, State, Zip } = pharmacy
        return `${Name} ${conditiontTypeText}- ${Address1}, ${City} ${State} ${Zip}`
    }

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget)
    // }

    // const handleClose = () => {
    //     setAnchorEl(null)
    // }

    const handleSubmitTimezone = async (timezone) => {
        const payload = { ...patient, timezone }
        delete payload['insurance']
        try {
            setLoadingChageTimezone(true)
            changeCreatedOn(timezone)
            await putPatient(patient.id, payload)
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingChageTimezone(false)
        }
    }

    useEffect(() => {
        if (timezonePatient && timezonePatient !== patient?.timezone) {
            handleSubmitTimezone(timezonePatient)
        }
    }, [timezonePatient])

    useEffect(() => {
        if (patient.timezone) {
            setTimezonePatient(patient.timezone)
        }
    }, [patient.timezone])

    const classes = useStyles()

    const shareContactConfirm = (e, data) => {

        setAletrTargetData({ ...data })
        if ((data.number_type === 'primary' && data.shared_phone) || (data.number_type === 'secondary' && data.shared_secondary_phone)) {
            setAnchorEl1(e.currentTarget)
            return
        }
        setAnchorEl(e.currentTarget)
    }

    const shareContactEmailConfirm = (e, data) => {
        setAletrTargetData({ ...data })
        if (data.shared_email) {
            setAnchorEl3(e.currentTarget)
            return
        }

        setAnchorEl2(e.currentTarget)
    }


    const handleClose4 = () => {
        returnToDefaultState()
        setAnchorEl(null)
        setAletrTargetData({})
    }

    const handleCloseEmailAlert = () => {
        returnToDefaultState()
        setAnchorEl2(null)
        setAletrTargetData({})
    }

    const handleCloseEmailAlert3 = () => {
        setAnchorEl3(null)
        setAletrTargetData({})
    }

    const cancelClick = () => {
        if ((aletrTargetData.number_type === 'primary' && aletrTargetData.shared_phone) || (aletrTargetData.number_type === 'secondary' && aletrTargetData.shared_secondary_phone)) {
            setAnchorEl1(null)
            setAletrTargetData({})
            return
        }
        setAnchorEl(null)
        setAletrTargetData({})
    }
    const successConfirm = async () => {
        if (aletrTargetData?.number_type === 'primary') {
            handleSaveInlineEdit({ shared_phone: !aletrTargetData?.shared_phone })
            setAnchorEl1(null)
            setAnchorEl(null)
            setAletrTargetData({})
            return
        }
        if (aletrTargetData?.number_type === 'secondary') {
            handleSaveInlineEdit({ shared_secondary_phone: !aletrTargetData?.shared_secondary_phone })
            setAnchorEl1(null)
            setAnchorEl(null)
            setAletrTargetData({})
            return
        }

    }

    const succesConfirmEmail = () => {
        handleSaveInlineEdit({ shared_email: !aletrTargetData.shared_email })
        setAnchorEl3(null)
        setAnchorEl2(null)
        setAletrTargetData({})
    }

    const cancelEmail = () => {
        setAnchorEl3(null)
        setAnchorEl2(null)
        setAletrTargetData({})
    }
    const handleClose1 = () => {
        returnToDefaultState()
        setAnchorEl1(null)
        setAletrTargetData({})
    }

    const generateAddress = (patient) => {
        const { address1, address2, city, state, zip } = patient
        const thirdLine = [city, state, zip]?.join(' '),
            fullAddressLine = [address1, address2, thirdLine]?.join(' ')

        return (
            <>
                {address1?.length <= 20 && address2?.length <= 20 && thirdLine?.length <= 20 ? (
                    <span
                        onClick={() => enterEditState('address')}
                        style={{ display: 'flex', flexDirection: 'column' }}
                        className="editable">
                        <Typography className={classes.text_highlight}>{`${address1}`}</Typography>
                        <Typography className={classes.text_highlight}>{`${address2}`}</Typography>
                        <Typography className={classes.text_highlight}>{`${thirdLine}`}</Typography>
                    </span>
                ) : (
                    <HtmlTooltip placement="top" title={fullAddressLine}>
                        <span
                            onClick={() => enterEditState('address')}
                            style={{ display: 'flex', flexDirection: 'column' }}
                            className="editable">
                            <Typography className={classes.text_highlight}>{`${shortenString(
                                address1,
                                16,
                            )}`}</Typography>
                            <Typography className={classes.text_highlight}>{`${shortenString(
                                address2,
                                16,
                            )}`}</Typography>
                            <Typography className={classes.text_highlight}>{`${shortenString(
                                thirdLine,
                                16,
                            )}`}</Typography>
                        </span>
                    </HtmlTooltip>
                )}
            </>
        )
    }

    const generateParmacyAddress = (pharmacy) => {
        const { Address1, City, State, Zip } = pharmacy
        const thirdLine = [City, State, Zip]?.join(' '),
            fullAddressLine = [Address1, thirdLine]?.join(' ')

        return (
            <>
                {Address1 ? (
                    Address1?.length <= 20 && thirdLine?.length <= 20 ? (
                        <span
                            onClick={() => enterEditState('address')}
                            style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                className={classes.text_highlight}>{`${Address1}`}</Typography>
                            {/* {address2 && <Typography className={classes.text_highlight}>{`${address2}`}</Typography>} */}
                            <Typography
                                className={classes.text_highlight}>{`${thirdLine}`}</Typography>
                        </span>
                    ) : (
                        <HtmlTooltip placement="top" title={fullAddressLine}>
                            <span
                                onClick={() => enterEditState('address')}
                                style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography className={classes.text_highlight}>{`${shortenString(
                                    Address1,
                                    16,
                                )}`}</Typography>
                                {/* { address2 && <Typography className={classes.text_highlight}>{`${shortenString(
                                address2,
                                16,
                            )}`}</Typography>} */}
                                <Typography className={classes.text_highlight}>{`${shortenString(
                                    thirdLine,
                                    16,
                                )}`}</Typography>
                            </span>
                        </HtmlTooltip>
                    )
                ) : (
                    '----'
                )}
            </>
        )
    }

    /**
     * Staff Note Functionlity
     */
    const getPatientAlertStaffNotes = React.useCallback(
        async (pageNote, limitData) => {
            setLoadingNotes(true)
            const page = pageNote
            const patient_id = patient?.id
            const limit = limitData
            if (patient && patient?.id) {
                const payload = {
                    patient_id,
                    page,
                    limit,
                }
                try {
                    const resp = await getPatientAlert(payload)
                    // console.log('resp', resp)
                    if (resp && resp?.data) {
                        // let extra = 0

                        setNotes(resp?.data?.data)
                        // if (resp?.data?.data[0]?.alert) {
                        //     setAlertNoteObj(resp?.data?.data[0])
                        // }
                        // if (resp?.data?.alert_notes && !isObjectEmpty(resp?.data?.alert_notes)) {
                        //     // setAlertNoteObj(resp?.data?.alert_notes)
                        //     // setNoteObj(resp?.data?.alert_notes)
                        //     // setNote(resp?.data?.alert_notes?.content)
                        //     // setNoteTitle(resp?.data?.alert_notes?.title)
                        //     // setCheck(true)
                        //     extra = 1
                        // }
                        setTotalNote(resp?.data?.paging?.total)
                    }
                } catch (ex) {
                    notification(`Cannot get list notes!`, 'error')
                } finally {
                    setLoadingNotes(false)
                }
            }
        },
        [page, patient],
    )
    // const alertCheck = React.useMemo(() => {
    //     if (
    //         alertNoteObj &&
    //         noteObj &&
    //         alertNoteObj?.id === noteObj?.id &&
    //         alertNoteObj?.alert === noteObj?.alert
    //     ) {
    //         return true
    //     }
    //     return false
    // }, [alertNoteObj, noteObj])
    React.useEffect(() => {
        getPatientAlertStaffNotes(page, isCollapsed ? totalNote : 3)
    }, [page, patient, isCollapsed])
    // const handleNavigation = React.useCallback(() => {
    //     if (!anchorAlert && note && note !== noteObj?.content) {
    //         setAnchorNote(saveButtonRef.current)
    //         if (scrollableDiv && saveButtonRef) {
    //             const targetPosition = saveButtonRef?.current?.offsetTop - 350
    //             if (targetPosition) {
    //                 scrollableDiv.scrollTo({
    //                     top: targetPosition,
    //                     behavior: 'smooth',
    //                 })
    //             }
    //             return false
    //         }
    //     }
    //     return true
    // }, [anchorAlert, note, noteObj, alertCheck])

    // Set up the navigation block
    // history.block(handleNavigation)
    // const getAllNotes = React.useCallback(
    //     (value) => {
    //         if (value === 2) {
    //             setPage(1)
    //             setLimit(totalNote)
    //         } else {
    //             setPage(1)
    //             setLimit(3)
    //         }
    //     },
    //     [page, limit, totalNote],
    // )

    const handleNotesSubmit = React.useCallback(
        async (e, type) => {
            try {
                e?.preventDefault()
                setLoadingNoteSave(true)
                if (patient && patient?.id) {
                    const notePayload = {
                        id: noteObj?.id || null,
                        title: noteTitle,
                        content: note,
                        createdBy: loggedInUser['name'],
                        user_id: parseInt(loggedInUser['custom:user_id']),
                        alert: check,
                    }

                    // if (type) {
                    //     if (type === 'No') {
                    //         notePayload = {
                    //             ...notePayload,
                    //             alert: false,
                    //         }
                    //     }
                    // }
                    const payload = {
                        ...notePayload,
                        patient_id: parseInt(patient?.id),
                    }

                    if (payload) {
                        const addNote = await createPatientAlert(payload)
                        if (addNote) {
                            setLoadingNoteSave(false)
                            // setNote('')
                            // setNoteTitle('')
                            // setCheck(false)
                            // setAnchorAlert(null)
                            // setAnchorElStaffNote(null)
                            // }
                            // if (addNote && addNote?.data && addNote?.data?.Status === 'SUCCESSED') {
                            //     let extra = 0
                            //     if (addNote?.data?.notes) {
                            //         setNotes(addNote?.data?.notes)
                            //     }
                            // if (
                            //     addNote?.data?.alert_notes &&
                            //     !isObjectEmpty(addNote?.data?.alert_notes)
                            // ) {
                            //     extra = 1
                            //     // setAlertNoteObj(addNote?.data?.alert_notes)
                            //     // setNoteObj(addNote?.data?.alert_notes)
                            //     // setNote(addNote?.data?.alert_notes?.content)
                            //     // setNoteTitle(addNote?.data?.alert_notes?.title)
                            //     // setCheck(true)
                            // }
                            // setTotalNote(addNote?.data?.count - extra)
                            // setLimit(2)
                            getPatientAlertStaffNotes(1, isCollapsed ? totalNote + 1 : 3)
                            if (noteObj?.id && !type) {
                                notification('Note updated sucessfully', 'success')
                            } else {
                                if (type === 'Yes') {
                                    notification('Note updated sucessfully', 'success')
                                } else {
                                    notification('Note added sucessfully', 'success')
                                }
                            }
                        } else {
                            notification('Somthing is wrong', 'error')
                        }
                    }
                }
            } catch {
                notification('Somthing is wrong', 'error')
            } finally {
                handleCancelNote()
            }
        },
        [
            notes,
            note,
            patient,
            loggedInUser,
            check,
            // alertNoteObj,
            anchorAlert,
            // alertCheck,
            isCollapsed,
            totalNote,
            noteTitle,
        ],
    )
    const handleCancelNote = React.useCallback(() => {
        setNoteObj(null)
        setNote('')
        setNoteTitle('')
        setCheck(false)
        setAnchorAlert(null)
        setAnchorElStaffNote(null)
    }, [note, noteObj, check])
    const handleNoteEdit = React.useCallback(
        (e, item) => {
            setNote(item?.content)
            setNoteTitle(item?.title)
            setNoteObj(item)
            setCheck(item?.alert)
            setAnchorElStaffNote(e?.currentTarget)
            // if (scrollableDiv) {
            //     const scrollHeight = scrollableDiv.scrollHeight
            //     scrollableDiv.scrollTo({
            //         top: scrollHeight / 2 - 200,
            //         behavior: 'smooth',
            //     })
            // }
        },
        [note, noteObj, check],
    )
    const getPreferredName = (name) => {
        let variableName = name
        variableName = `${name?.slice(0, 6)}...`
        return variableName
    }
    const staffNotesArr = React.useMemo(() => {
        if (!isCollapsed) {
            return dummyNotes.slice(0, 3)
        }
        return dummyNotes
    }, [isCollapsed, dummyNotes])
    const handleClickStaffNote = (event) => {
        setAnchorElStaffNote(event.currentTarget)
    }
    const handleCloseStaffNote = () => {
        if ((note && note !== noteObj?.content) || (noteTitle && noteTitle !== noteObj?.title)) {
            setAnchorNote(saveButtonRef.current)
        } else {
            setAnchorElStaffNote(null)
            handleCancelNote()
        }
    }
    const handleDeleteStaffNote = async () => {
        try {
            if (noteObj?.id) {
                const deleteNote = await deletePatientAlert(noteObj?.id)
                if (deleteNote) {
                    getPatientAlertStaffNotes(1, isCollapsed ? totalNote : 3)
                    notification('Staff note deleted successfully!', 'success')
                }
            } else {
                notification('Note id not found!', 'error')
            }
        } catch (e) {
            notification('Somthing is wrong', 'error')
        } finally {
            handleCancelNote()
        }
    }

    const getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
        
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    return (
        <Grid
            container
            direction="row"
            spacing={2}
            className={`${classes.topContainer} ${isCollapsed ? classes.topContainerExpand : ''}`}>
            <Grid
                container
                spacing={2}
                wrap="nowrap"
                className={classes.group}
                // alignItems="center"
                style={{ marginTop: 0, height: '100%' }}>
                {/* <div style={{ display: 'flex', alignItems: 'flex-end', marginLeft: '5px' }}>
                    <HtmlTooltip title="Open demographics form" placement="top-end">
                        <IconButton
                            onClick={() =>
                                history.push(Routes.PATIENT.EDIT.replace(':id', patient?.id), {
                                    patientRecord: true,
                                })
                            }>
                            <DemographicIcon />
                        </IconButton>
                    </HtmlTooltip>
                </div> */}
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        margin: '40px 13px 25px 20px',
                    }}>
                    {!!patient.photo ? (
                        <img className={classes.avatar} src={patient.photo} alt="Patient photo" />
                    ) : (
                        <>
                        {/* <Box style={{
                        position:"absolute",
                        marginLeft:"15px",
                        marginTop:"18px",
                        fontFamily:"Rubik"
                        }}>SG</Box> */}
                        <Box className={classes.avatar}>
                        <Box style={{
                        position:"absolute",
                        marginLeft:"15px",
                        marginTop:"18px",
                        fontFamily:"Rubik",
                        fontWeight:"500",
                        color:"white"
                        }}>{getInitials(`${patient.first_name} ${patient.last_name}`)}</Box>
                        </Box>

                        </>
                        
                    )}
                </div>
                {Object.keys(patient).length && !isLoading ? (
                    <Grid
                        item
                        container
                        direction="column"
                        style={{ width: '80%', flexWrap: 'nowrap' }}>
                        {isEdit.patient_name ? (
                            <span style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                    <input
                                        className={`on_edit_mode header_edit${isError.first_name ? ' error_field' : ''
                                            }`}
                                        type="text"
                                        value={patientEditPayload?.first_name}
                                        style={{ width: '33%' }}
                                        onChange={(event) =>
                                            onEditFieldChange(event.target.value, 'first_name')
                                        }
                                    />
                                    <input
                                        className="on_edit_mode header_edit"
                                        type="text"
                                        value={patientEditPayload?.middle_name}
                                        style={{ width: '33%' }}
                                        onChange={(event) =>
                                            onEditFieldChange(event.target.value, 'middle_name')
                                        }
                                    />
                                    <input
                                        className={`on_edit_mode header_edit${isError.last_name ? ' error_field' : ''
                                            }`}
                                        type="text"
                                        value={patientEditPayload?.last_name}
                                        style={{ width: '33%' }}
                                        onChange={(event) =>
                                            onEditFieldChange(event.target.value, 'last_name')
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 5,
                                        margin: '3px 0',
                                        justifyContent: 'flex-end',
                                    }}>
                                    <IconButton
                                        className="on_edit_icon_container"
                                        onClick={() => returnToDefaultState()}>
                                        <CloseIcon className="on_edit_icon" />
                                    </IconButton>
                                    <IconButton
                                        className="on_edit_icon_container"
                                        onClick={() => handleSaveInlineEdit()}>
                                        <CheckIcon className="on_edit_icon" />
                                    </IconButton>
                                </div>
                            </span>
                        ) : getPatientName(patient)?.length <= 30 ? (
                            <Typography
                                onClick={() => enterEditState('patient_name')}
                                className={`${clsx(
                                    classes.header_card,
                                    classes.text_break,
                                )} editable`}>
                                {getPatientName(patient)}
                            </Typography>
                        ) : (
                            <HtmlTooltip title={getPatientName(patient)} placement="top">
                                <Typography
                                    onClick={() => enterEditState('patient_name')}
                                    className={`${clsx(
                                        classes.header_card,
                                        classes.text_break,
                                    )} editable`}>
                                    {shortenString(getPatientName(patient), 27)}
                                </Typography>
                            </HtmlTooltip>
                        )}

                        <Typography
                            className={`${classes.text_break}`}
                            style={{
                                marginBottom: '3px',
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}>
                            Preferred name:
                            {isEdit?.preferred_name ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '50%',
                                        marginLeft: 5,
                                    }}>
                                    <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                        <input
                                            className="on_edit_mode preferred_name_edit"
                                            type="text"
                                            style={{ width: '100%' }}
                                            value={patientEditPayload?.preferred_name}
                                            onChange={(event) =>
                                                onEditFieldChange(
                                                    event.target.value,
                                                    'preferred_name',
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 5,
                                            margin: '3px 0',
                                            justifyContent: 'flex-end',
                                        }}>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => returnToDefaultState()}>
                                            <CloseIcon className="on_edit_icon" />
                                        </IconButton>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => handleSaveInlineEdit()}>
                                            <CheckIcon className="on_edit_icon" />
                                        </IconButton>
                                    </div>
                                </span>
                            ) : patient?.preferred_name?.length <= 15 ? (
                                <span
                                    style={{
                                        fontWeight: 600,
                                        marginLeft: 3,
                                        fontSize: '15px',
                                        // padding: '5px'
                                    }}
                                    onClick={() => enterEditState('preferred_name')}
                                    className="editable">
                                    {patient?.preferred_name !== ''
                                        ? patient?.preferred_name
                                        : '----'}
                                </span>
                            ) : (
                                <HtmlTooltip title={patient?.preferred_name} arrow placement="top">
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            marginLeft: 3,
                                            fontSize: '15px',
                                            padding: '5px',
                                        }}
                                        onClick={() => enterEditState('preferred_name')}
                                        className="editable">
                                        {getPreferredName(patient?.preferred_name)}
                                    </span>
                                </HtmlTooltip>
                            )}
                        </Typography>

                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: 10, alignItems: 'center' }}>
                            <Typography className={`${classes.text_break}`}>
                                {patient?.custom_id?.length <= 9 ? (
                                    <span
                                        // style={{
                                        //     marginLeft: 3,
                                        // }}
                                        className={`${classes.blue_color} ${classes.text_break}`}>
                                        {patient?.custom_id}
                                    </span>
                                ) : (
                                    <HtmlTooltip title={patient?.custom_id} placement="top">
                                        <span
                                            // style={{
                                            //     marginLeft: 3,
                                            // }}
                                            className={`${classes.blue_color} ${classes.text_break}`}>
                                            {shortenString(patient?.custom_id, 6)}
                                        </span>
                                    </HtmlTooltip>
                                )}
                            </Typography>

                            {patient?.status_code && (
                                <span
                                    style={{
                                        borderRadius: '5px',
                                        backgroundColor: '#E9ECF1',
                                        padding: '2px 10px',
                                        width: 'max-content',
                                    }}>
                                    <Typography>{patient?.status_code?.name}</Typography>
                                </span>
                            )}
                            <Typography className={`${classes.text_break}`}>
                                MRN:{' '}
                                <span className={`${classes.text_break} ${classes.text_highlight}`}>
                                    {patient?.id}
                                </span>
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: 5 }}>
                            <Typography
                                className={`${classes.text_break}`}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: isEdit.date_of_birth ? 'flex-start' : 'center',
                                    // width: '30%',
                                }}>
                                DOB:{' '}
                                {isEdit?.date_of_birth ? (
                                    <span
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: '70%',
                                            marginLeft: 5,
                                        }}>
                                        <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                            <input
                                                className={`on_edit_mode${isError.dob ? ' error_field' : ''
                                                    }`}
                                                type="date"
                                                max={new Date()}
                                                style={{ width: '100%' }}
                                                value={moment(patientEditPayload?.dob).format(
                                                    'YYYY-MM-DD',
                                                )}
                                                // maxLength={10}
                                                onChange={(event) =>
                                                    onEditFieldChange(event.target.value, 'dob')
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                                margin: '3px 0',
                                                justifyContent: 'flex-end',
                                            }}>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => returnToDefaultState()}>
                                                <CloseIcon className="on_edit_icon" />
                                            </IconButton>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => handleSaveInlineEdit()}>
                                                <CheckIcon className="on_edit_icon" />
                                            </IconButton>
                                        </div>
                                    </span>
                                ) : (
                                    <span
                                        style={{
                                            marginLeft: 3,
                                        }}
                                        onClick={() => enterEditState('date_of_birth')}
                                        className={`${classes.text_break} ${classes.text_highlight} editable`}>
                                        {moment(patient?.dob).format('MM/DD/YYYY')}
                                    </span>
                                )}
                            </Typography>
                            <Typography className={`${classes.text_break}`}>
                                Age:{' '}
                                <span className={`${classes.text_break} ${classes.text_highlight}`}>
                                    {/* {patient?.gender} */}
                                    {getAgeCalculate(patient?.dob)} years
                                </span>
                            </Typography>
                            <Typography className={`${classes.text_break}`}>
                                Sex:{' '}
                                <span
                                    style={{
                                        marginLeft: 3,
                                    }}
                                    className={`${classes.text_break} ${classes.text_highlight}`}>
                                    {/* {patient?.gender} */}
                                    {capitalizeFirstLetter(patient?.gender)}
                                </span>
                            </Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: isEdit.primary_number ? 'flex-start' : 'center',
                                marginTop: 5
                            }}>
                            <PhoneIcon
                                style={{
                                    height: '14px !important',
                                    color: '#667689',
                                    marginRight: '5px',
                                }}
                            />
                            <Typography className={`${classes.text_highlight}`} style={{ marginRight: 3 }}>
                                Primary:
                            </Typography>
                            {isEdit.primary_number ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: 5,
                                        width: '85%',
                                    }}>
                                    <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                        <input
                                            className={`on_edit_mode${isError.phone_number ? ' error_field' : ''
                                                }`}
                                            type="text"
                                            style={{ width: '100%' }}
                                            maxLength={14}
                                            value={formatPhoneNumber(
                                                patientEditPayload?.phone_number,
                                            )}
                                            onChange={(event) =>
                                                onEditFieldChange(
                                                    event.target.value,
                                                    'phone_number',
                                                )
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // gap: 5,
                                            // margin: '3px 0',
                                            justifyContent: 'flex-end',
                                        }}>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => returnToDefaultState()}>
                                            <CloseIcon className="on_edit_icon" />
                                        </IconButton>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => handleSaveInlineEdit()}>
                                            <CheckIcon className="on_edit_icon" />
                                        </IconButton>
                                    </div>
                                </span>
                            ) : (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        onClick={() => enterEditState('primary_number')}
                                        className={`${classes.text_highlight} editable`}>
                                        {patient?.phone_number !== ''
                                            ? patient?.phone_number
                                            : '----'}

                                    </Typography>
                                    <HtmlTooltip
                                        placement="top-start"
                                        title={patient?.shared_phone ? `Patient has shared phone` : `Patient does not have shared phone`}
                                        className={classes.calendar_arrow_tooltip}
                                        arrow>
                                        <div style={{ width: "21px", height: "26px", cursor: "pointer", marginLeft: "5px" }} onClick={(e) => shareContactConfirm(e, { number_type: "primary", phone_number: patient?.phone_number, shared_phone: patient?.shared_phone })}>
                                            <ShareContact className={aletrTargetData?.number_type === 'primary' || patient?.shared_phone ? classes.share_contact_active : classes.share_contact_deactive} />
                                        </div>
                                    </HtmlTooltip>
                                </div>
                            )}
                        </div>

                        {patient?.secondary_phone_number && (
                            <div style={{ display: 'flex', gap: '5px', marginTop: 5 }}>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: isEdit.secondary_number
                                            ? 'flex-start'
                                            : 'center',
                                    }}>
                                    <PhoneIcon
                                        style={{
                                            height: '14px',
                                            color: '#667689',
                                            marginRight: '5px',
                                            marginTop: isEdit.secondary_number ? 10 : 0,
                                        }}
                                    />
                                    <Typography className={`${classes.text_highlight}`} style={{ marginRight: 3 }}>
                                        Secondary:
                                    </Typography>
                                    <span style={{ display: 'flex', gap: 2 }}>
                                        {isEdit.secondary_number ? (
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    marginLeft: 5,
                                                    width: '85%',

                                                }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: 2,
                                                        width: '100%',
                                                    }}>
                                                    <input
                                                        className="on_edit_mode"
                                                        type="text"
                                                        style={{ width: '100%' }}
                                                        maxLength={14}
                                                        value={formatPhoneNumber(
                                                            patientEditPayload?.secondary_phone_number,
                                                        )}
                                                        onChange={(event) =>
                                                            onEditFieldChange(
                                                                event.target.value,
                                                                'secondary_phone_number',
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 5,
                                                        margin: '3px 0',
                                                        justifyContent: 'flex-end',
                                                    }}>
                                                    <IconButton
                                                        className="on_edit_icon_container"
                                                        onClick={() => returnToDefaultState()}>
                                                        <CloseIcon className="on_edit_icon" />
                                                    </IconButton>
                                                    <IconButton
                                                        className="on_edit_icon_container"
                                                        onClick={() => handleSaveInlineEdit()}>
                                                        <CheckIcon className="on_edit_icon" />
                                                    </IconButton>
                                                </div>
                                            </span>
                                        ) : (
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                {/* <Typography
                                                    onClick={() => enterEditState('secondary_number')}
                                                    className={`${classes.text_highlight} editable`}>
                                                    {patient?.secondary_phone_number}
                                                </Typography> */}

                                                <Typography
                                                    onClick={() => enterEditState('secondary_number')}
                                                    className={`${classes.text_highlight} editable`}>
                                                    {patient?.secondary_phone_number !== ''
                                                        ? patient?.secondary_phone_number
                                                        : '----'}
                                                </Typography>
                                                {patient?.secondary_phone_number && <HtmlTooltip
                                                    placement="top-start"
                                                    title={patient?.shared_secondary_phone ? `Patient has shared phone` : `Patient does not have shared phone`}
                                                    className={classes.calendar_arrow_tooltip}
                                                    arrow>
                                                    <div style={{ width: "21px", height: "26px", cursor: "pointer", marginLeft: "5px" }} onClick={(e) => shareContactConfirm(e, { number_type: "secondary", phone_number: patient?.secondary_number, shared_secondary_phone: patient?.shared_secondary_phone })} >
                                                        <ShareContact className={aletrTargetData?.number_type === 'secondary' || patient?.shared_secondary_phone ? classes.share_contact_active : classes.share_contact_deactive} />
                                                    </div>
                                                </HtmlTooltip>}
                                            </div>
                                        )}
                                        {/* <Typography
                                            className={`${classes.text_highlight}`}
                                            style={{ marginTop: isEdit.secondary_number ? 8 : 0 }}>
                                            {patient?.relation}
                                        </Typography> */}

                                    </span>
                                </span>
                            </div>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                alignItems: isEdit?.email ? 'flex-start' : 'center',
                            }}>
                            <EmailIcon
                                style={{
                                    height: '25px',
                                    color: '#667689',
                                    marginRight: 5
                                }}
                            />
                            {isEdit?.email ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: 5,
                                        width: '85%',
                                    }}>
                                    <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                        <input
                                            className={`on_edit_mode`}
                                            type="text"
                                            style={{ width: '100%' }}
                                            value={patientEditPayload?.email}
                                            onChange={(event) =>
                                                onEditFieldChange(event.target.value, 'email')
                                            }
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 5,
                                            margin: '3px 0',
                                            justifyContent: 'flex-end',
                                        }}>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => returnToDefaultState()}>
                                            <CloseIcon className="on_edit_icon" />
                                        </IconButton>
                                        <IconButton
                                            className="on_edit_icon_container"
                                            onClick={() => handleSaveInlineEdit()}>
                                            <CheckIcon className="on_edit_icon" />
                                        </IconButton>
                                    </div>
                                </span>
                            ) : patient?.email?.length <= 20 ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Typography
                                        onClick={() => enterEditState('email')}
                                        className={`${classes.text_highlight} editable`}>
                                        {patient?.email !== '' ? patient?.email : '----'}
                                    </Typography>
                                </div>
                            ) : (
                                <HtmlTooltip placement="top" title={patient?.email}>
                                    <Typography
                                        onClick={() => enterEditState('email')}
                                        className={`${classes.text_highlight} editable`}>
                                        {shortenString(patient?.email, 17)}
                                    </Typography>
                                </HtmlTooltip>
                            )}
                            {!isEdit.email && patient?.email && <HtmlTooltip
                                placement="top-start"
                                title={patient?.shared_email ? `Patient has shared email` : `Patient does not have shared email`}
                                className={classes.calendar_arrow_tooltip}
                                arrow>
                                <div style={{ width: "21px", height: "26px", cursor: "pointer", marginLeft: "5px" }} onClick={(e) => shareContactEmailConfirm(e, { contact_type: "email", email: patient?.email, shared_email: patient?.shared_email })} >
                                    <ShareContact style={{ marginTop: "2px" }} className={aletrTargetData?.contact_type === 'email' || patient?.shared_email ? classes.share_contact_active : classes.share_contact_deactive} />
                                </div>
                            </HtmlTooltip>}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                // justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 5
                            }}>
                            <Typography className={`${classes.text_break}`}>
                                Primary Language:{' '}
                                <span className={`${classes.text_break} ${classes.text_highlight}`}>
                                    {patient?.primary_language?.display} {patient?.primary_language?.code === 'ot' ? ` (${patient?.other_language})` : ''}
                                </span>
                            </Typography>
                            <HtmlTooltip 
                                title={patient?.require_translator ? 'Patient requires translator' : 'Patient does not require translator'}
                                placement='bottom'
                                arrow
                            >
                                <IconButton 
                                    style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}
                                    onClick={() => handleSaveInlineEdit({require_translator: !patient?.require_translator})}
                                >
                                    <PatientTranslatorIcon className={patient?.require_translator ? '' : classes.patientTranslator}/>
                                </IconButton>
                            </HtmlTooltip>
                        </div>


                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            {
                                patient.is_self_harm && (
                                    <span
                                        style={{
                                            display: 'inline-flex',
                                            fontSize: 14,
                                            color: '#667689',
                                            marginTop: '5px',
                                        }}>
                                        <FlagIcon style={{ color: 'red', fontSize: 14 }} />
                                        Suicide/self-harm
                                    </span>
                                )
                            }
                            {
                                patient.is_violence_behavior && (
                                    <span
                                        style={{
                                            display: 'inline-flex',
                                            fontSize: 14,
                                            color: '#667689',
                                            marginTop: '5px',
                                        }}>
                                        <FlagIcon style={{ color: 'red', fontSize: 14 }} />
                                        Violence
                                    </span>
                                )
                            }
                        </div >

                        {isCollapsed && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: '10px',
                                }}>
                                <Typography className={`${classes.text_break}`}>
                                    Primary Care Physician
                                </Typography>
                                {isEdit.primary_care_physician ? (
                                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                            <input
                                                className="on_edit_mode"
                                                type="text"
                                                style={{ width: '100%' }}
                                                value={patientEditPayload?.primary_care_physician}
                                                onChange={(event) =>
                                                    onEditFieldChange(
                                                        event.target.value,
                                                        'primary_care_physician',
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                                margin: '3px 0',
                                                justifyContent: 'flex-end',
                                            }}>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => returnToDefaultState()}>
                                                <CloseIcon className="on_edit_icon" />
                                            </IconButton>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => handleSaveInlineEdit()}>
                                                <CheckIcon className="on_edit_icon" />
                                            </IconButton>
                                        </div>
                                    </span>
                                ) : (

                                    <Typography
                                        onClick={() => enterEditState('primary_care_physician')}
                                        className={`${classes.text_break} ${classes.text_highlight} editable`}
                                        style={{ width: 'max-content', padding: '2px' }}>
                                        {patient?.primary_care_physician || '----'}
                                    </Typography>
                                )}

                                <div style={{ display: "flex" }}>
                                    <Typography className={`${classes.text_break}`} style={{ marginRight: "5px" }}>
                                        Phone
                                    </Typography>
                                    {isEdit.primary_care_physician_phone ? (
                                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                                <input
                                                    className="on_edit_mode"
                                                    type="text"
                                                    style={{ width: '100%' }}
                                                    maxLength={14}
                                                    value={formatPhoneNumber(patientEditPayload?.primary_care_physician_phone)}
                                                    onChange={(event) =>
                                                        onEditFieldChange(
                                                            event.target.value,
                                                            'primary_care_physician_phone',
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: 5,
                                                    margin: '3px 0',
                                                    justifyContent: 'flex-end',
                                                }}>
                                                <IconButton
                                                    className="on_edit_icon_container"
                                                    onClick={() => returnToDefaultState()}>
                                                    <CloseIcon className="on_edit_icon" />
                                                </IconButton>
                                                <IconButton
                                                    className="on_edit_icon_container"
                                                    onClick={() => handleSaveInlineEdit()}>
                                                    <CheckIcon className="on_edit_icon" />
                                                </IconButton>
                                            </div>
                                        </span>
                                    ) : (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <Typography
                                                onClick={() => enterEditState('primary_care_physician_phone')}
                                                className={`${classes.text_break} ${classes.text_highlight} editable`}
                                                style={{ width: 'max-content', padding: '2px' }}>
                                                {patient?.primary_care_physician_phone || '----'}
                                            </Typography>
                                        </div>
                                    )}
                                    <Typography className={`${classes.text_break}`} style={{ marginLeft: "30px", marginRight: "5px" }}>
                                        Fax
                                    </Typography>
                                    {isEdit.primary_care_physician_fax ? (
                                        <span style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                                <input
                                                    className="on_edit_mode"
                                                    type="text"
                                                    style={{ width: '100%' }}
                                                    maxLength={14}
                                                    value={formatPhoneNumber(patientEditPayload?.primary_care_physician_fax)}
                                                    onChange={(event) =>
                                                        onEditFieldChange(
                                                            event.target.value,
                                                            'primary_care_physician_fax',
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    gap: 5,
                                                    margin: '3px 0',
                                                    justifyContent: 'flex-end',
                                                }}>
                                                <IconButton
                                                    className="on_edit_icon_container"
                                                    onClick={() => returnToDefaultState()}>
                                                    <CloseIcon className="on_edit_icon" />
                                                </IconButton>
                                                <IconButton
                                                    className="on_edit_icon_container"
                                                    onClick={() => handleSaveInlineEdit()}>
                                                    <CheckIcon className="on_edit_icon" />
                                                </IconButton>
                                            </div>
                                        </span>
                                    ) : (
                                        <Typography
                                            onClick={() => enterEditState('primary_care_physician_fax')}
                                            className={`${classes.text_break} ${classes.text_highlight} editable`}
                                            style={{ width: 'max-content', padding: '2px' }}>
                                            {patient?.primary_care_physician_fax || '----'}
                                        </Typography>
                                    )}
                                </div>
                                <Typography className={`${classes.text_break}`}>
                                    Referring Provider
                                </Typography>
                                {isEdit.referring_provider ? (
                                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="edit_select">
                                            <select
                                                className="on_edit_mode select"
                                                style={{ width: '100%' }}
                                                value={patientEditPayload?.referring_provider}
                                                onChange={(event) =>
                                                    onEditFieldChange(
                                                        event.target.value,
                                                        'referring_provider',
                                                    )
                                                }>
                                                {referringProviderList?.map((provider) => {
                                                    return (
                                                        <option
                                                            className="select_option"
                                                            key={provider?.key}>
                                                            {provider?.value}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                                margin: '3px 0',
                                                justifyContent: 'flex-end',
                                            }}>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => returnToDefaultState()}>
                                                <CloseIcon className="on_edit_icon" />
                                            </IconButton>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => handleSaveInlineEdit()}>
                                                <CheckIcon className="on_edit_icon" />
                                            </IconButton>
                                        </div>
                                    </span>
                                ) : (
                                    <Typography
                                        onClick={() => enterEditState('referring_provider')}
                                        className={`${classes.text_break} ${classes.text_highlight} editable`}
                                        style={{ width: 'max-content', padding: '2px' }}>
                                        {patient?.referring_provider || '----'}
                                    </Typography>
                                )}
                            </div>
                        )}
                    </Grid >
                ) : (
                    <Grid
                        item
                        container
                        direction="column"
                        style={{
                            width: '90%',
                            gap: 5,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <CircularProgress size={20} />
                    </Grid>
                )}

                <div
                    style={{
                        height: '92%',
                        margin: '5px 0px',
                        borderRight: '1px solid #E2E3E5',
                    }}></div>

                <Grid
                    item
                    container
                    direction="column"
                    spacing={2}
                    style={{ padding: '14px', flexWrap: 'nowrap' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '39%' }}>
                            <Typography style={{ marginBottom: '8px' }}>Address</Typography>
                            {Object.keys(patient).length && !isLoading ? (
                                isEdit.address ? (
                                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 2,
                                                width: '100%',
                                            }}>
                                            <input
                                                className={`on_edit_mode${isError.address1 ? ' error_field' : ''
                                                    }`}
                                                type="text"
                                                value={patientEditPayload?.address1}
                                                // style={{ width: '33%' }}
                                                onChange={(event) =>
                                                    onEditFieldChange(
                                                        event.target.value,
                                                        'address1',
                                                    )
                                                }
                                            />
                                            <input
                                                className="on_edit_mode"
                                                type="text"
                                                value={patientEditPayload?.address2}
                                                // style={{ width: '33%' }}
                                                onChange={(event) =>
                                                    onEditFieldChange(
                                                        event.target.value,
                                                        'address2',
                                                    )
                                                }
                                            />
                                            <div style={{ display: 'flex', gap: 2, width: '100%' }}>
                                                <input
                                                    className={`on_edit_mode${isError.city ? ' error_field' : ''
                                                        }`}
                                                    type="text"
                                                    value={patientEditPayload?.city}
                                                    style={{ width: '33%' }}
                                                    onChange={(event) =>
                                                        onEditFieldChange(
                                                            event.target.value,
                                                            'city',
                                                        )
                                                    }
                                                />

                                                <select
                                                    className={`on_edit_mode select${isError.state ? ' error_field' : ''
                                                        }`}
                                                    style={{ width: '33%' }}
                                                    value={patientEditPayload?.state}
                                                    onChange={(event) =>
                                                        onEditFieldChange(
                                                            event.target.value,
                                                            'state',
                                                        )
                                                    }>
                                                    {stateList?.map((state) => {
                                                        return (
                                                            <option
                                                                className="select_option"
                                                                key={state?.state}>
                                                                {state?.state}
                                                            </option>
                                                        )
                                                    })}
                                                </select>

                                                <input
                                                    className={`on_edit_mode${isError.zip ? ' error_field' : ''
                                                        }`}
                                                    type="text"
                                                    value={patientEditPayload?.zip}
                                                    style={{ width: '33%' }}
                                                    onChange={(event) =>
                                                        onEditFieldChange(event.target.value, 'zip')
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: 5,
                                                margin: '3px 0',
                                                justifyContent: 'flex-end',
                                            }}>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => returnToDefaultState()}>
                                                <CloseIcon className="on_edit_icon" />
                                            </IconButton>
                                            <IconButton
                                                className="on_edit_icon_container"
                                                onClick={() => handleSaveInlineEdit()}>
                                                <CheckIcon className="on_edit_icon" />
                                            </IconButton>
                                        </div>
                                    </span>
                                ) : (
                                    generateAddress(patient)
                                )
                            ) : (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <CircularProgress size={20} />
                                </span>
                            )}
                        </div>
                        <div style={{ width: '61%', marginLeft: '2px' }}>
                            <Typography style={{ marginBottom: '8px' }}>Insurance</Typography>
                            {Object.keys(patient).length ? (
                                <span style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        {patient?.insurance?.insurance?.plan?.display?.length <=
                                            10 ? (
                                            <Typography className={classes.text_highlight}>
                                                Health Plan:{' '}
                                                {`${patient?.insurance?.insurance?.plan?.display}` ||
                                                    '----'}
                                            </Typography>
                                        ) : (
                                            <HtmlTooltip
                                                title={patient?.insurance?.insurance?.plan?.display}
                                                placement="top-start">
                                                <Typography className={classes.text_highlight}>
                                                    Health Plan:{' '}
                                                    {shortenString(
                                                        patient?.insurance?.insurance?.plan
                                                            ?.display,
                                                        7,
                                                    ) || '----'}
                                                </Typography>
                                            </HtmlTooltip>
                                        )}
                                        {patient?.insurance && (
                                            <span
                                                style={{
                                                    borderRadius: '5px',
                                                    backgroundColor: '#E9ECF1',
                                                    padding: '2px 10px',
                                                    width: 'max-content',
                                                    height: 'max-content',
                                                    marginLeft: '5px',
                                                }}>
                                                <Typography>Primary</Typography>
                                            </span>
                                        )}
                                    </span>
                                    <Typography className={classes.text_highlight}>
                                        Deductible:{' '}
                                        {patient?.insurance?.insurance?.deductible_amount || '----'}
                                    </Typography>
                                    <Typography className={classes.text_highlight}>
                                        Copay:{' '}
                                        {patient?.insurance?.insurance?.copay_amount || '----'}
                                    </Typography>
                                </span>
                            ) : (
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <CircularProgress size={20} />
                                </span>
                            )}
                        </div>
                    </div>

                    {isCollapsed && (
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                            <Typography>Preferred Pharmacy</Typography>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '1px solid #C0C1C5',
                                    borderRadius: '5px',
                                    padding: '10px 0 10px 10px',
                                    minHeight: '65px',
                                }}>
                                <Typography
                                    className={`${Object.keys(patient?.pharmacy || {})?.length
                                        ? classes.text_highlight
                                        : ''
                                        } ${classes.text_bold}`}>
                                    {Object.keys(patient?.pharmacy || {})?.length
                                        ? patient?.pharmacy?.Name
                                        : 'No Preferred Pharmacy'}
                                </Typography>

                                {Object.keys(patient?.pharmacy || {})?.length ? (
                                    <div style={{ display: 'flex', gap: 10, marginTop: '10px' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: 10,
                                                minWidth: 145,
                                            }}>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <PhoneIcon
                                                    style={{
                                                        height: '14px',
                                                        color: '#667689',
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                <Typography className={classes.text_highlight}>
                                                    {patient?.pharmacy?.Phone &&
                                                        patient?.pharmacy?.Phone !== ''
                                                        ? patient?.pharmacy?.Phone
                                                        : '----'}
                                                </Typography>
                                            </span>
                                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                                <TelephoneIcon
                                                    style={{
                                                        height: '14px',
                                                        color: '#667689',
                                                        marginRight: '5px',
                                                    }}
                                                />
                                                <Typography className={classes.text_highlight}>
                                                    {patient?.pharmacy?.Fax &&
                                                        patient?.pharmacy?.Fax !== ''
                                                        ? patient?.pharmacy?.Fax
                                                        : '----'}
                                                </Typography>
                                            </span>
                                        </div>

                                        {generateParmacyAddress(patient?.pharmacy)}

                                        <span
                                            style={{
                                                display: 'flex',
                                                alignItems: 'flex-end',
                                                justifyContent: 'flex-end',
                                            }}>
                                            <span
                                                style={{
                                                    borderRadius: '5px',
                                                    backgroundColor: '#E9ECF1',
                                                    padding: '2px 10px',
                                                    width: 'max-content',
                                                }}>
                                                <Typography style={{ color: '#C0C3CB' }}>
                                                    Change
                                                    <ChangeIcon />
                                                </Typography>
                                            </span>
                                        </span>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    )}
                </Grid>
            </Grid >

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div
                    className={`${classes.patientInfoVerticalDivider} ${isCollapsed ? classes.patientInfoVerticalDividerExpand : ''
                        }`}></div>
                <IconButton
                    style={{
                        border: '1px solid rgba(0,0,0,0.38)',
                        borderRadius: 'inherit',
                        height: 12,
                        width: 18,
                        marginTop: 5,
                    }}
                    onClick={() => setIsCollapsed(!isCollapsed)}>
                    <KeyboardArrowDown
                        style={{
                            color: 'rgba(0,0,0,0.38)',
                            rotate: isCollapsed ? '180deg' : '',
                            fontSize: 11,
                        }}
                    />
                </IconButton>
            </div>
            {/* Staff Note */}
            <div className={classes.noteWrap}>
                <div style={{ width: '100%' }}>
                    <span
                        style={{
                            color: '#303E4E',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                        }}>
                        {loadingNotes ? (
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
                        ) : (
                            `${notes?.length}/${totalNote} notes`
                        )}
                    </span>
                </div>
                <div className={classes.staffNoteCardContainer}>
                    <div className={classes.staffNoteCardSubContainer}>
                        {loadingNotes ? (
                            staffNotesArr?.map((item) => (
                                <div className={classes.staffNoteCard} key={item}>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                        }}>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </div>
                                    <Skeleton variant="rectangular" width={124} height={60} />
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                </div>
                            ))
                        ) : notes?.length > 0 ? (
                            notes?.map((item) => {
                                return (
                                    <div
                                        className={classes.staffNoteCard}
                                        key={item.id}
                                        style={{
                                            border: item?.alert
                                                ? '1px solid #E81A1A'
                                                : '1px solid #C0C1C5',
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => handleNoteEdit(e, item)}>
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                            }}>
                                            {item?.alert && (
                                                <img
                                                    src={Alert_svg}
                                                    style={{
                                                        width: '15px',
                                                        marginRight: '8px',
                                                        marginLeft: '5px',
                                                    }}
                                                />
                                            )}
                                            <HtmlTooltip title={item.title} arrow placement="top">
                                                <span
                                                    style={{
                                                        color: '#303E4E',
                                                        fontSize: '12px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '500',
                                                    }}>
                                                    {shortenString(item.title, 12)}
                                                </span>
                                            </HtmlTooltip>
                                        </div>
                                        <p
                                            style={{
                                                color: '#303E4E',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                            }}>
                                            {shortenString(item.content, 40)}
                                        </p>
                                        <div
                                            style={{
                                                display: 'inline-flex',
                                                padding: '2px',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }}>
                                            <span
                                                style={{
                                                    color: '#303E4E',
                                                    fontSize: '9px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                }}>
                                                {(item &&
                                                    getCustomizedFormattedDate(item?.updatedAt)) ||
                                                    'Invalid Date'}
                                            </span>{' '}
                                            <HtmlTooltip
                                                title={item.createdBy}
                                                arrow
                                                placement="top">
                                                <span
                                                    style={{
                                                        color: '#303E4E',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                    }}>
                                                    {shortenString(item.createdBy, 10)}
                                                </span>
                                            </HtmlTooltip>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className={classes.staffNoteCard}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                    }}>
                                    <div style={{
                                        height: '10px',
                                        backgroundColor: '#F5F7F9'
                                    }} />
                                </div>
                                <div style={{
                                    height: '60px',
                                    backgroundColor: '#F5F7F9'
                                }} />
                                <div style={{
                                    height: '10px',
                                    backgroundColor: '#F5F7F9'
                                }} />
                            </div>
                        )}
                    </div>
                    {loadingNotes ? (
                        <Skeleton variant="circular" width={20} height={20} />
                    ) : (
                        <IconButton
                            onClick={handleClickStaffNote}
                            style={{
                                width: '25px',
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }}>
                            <AddCircleRoundedIcon style={{ color: '#5571C6', fontSize: '18px' }} />
                        </IconButton>
                    )}

                    <Popover
                        open={Boolean(anchorElStaffNote)}
                        anchorEl={anchorElStaffNote}
                        onClose={handleCloseStaffNote}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                                // backgroundColor: 'transparent',
                                boxShadow: '0px 0px 10px #888888',
                            },
                        }}>
                        {/* <span
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                backgroundColor: 'transparent',
                                position: 'relative',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                padding: '5px 5px 5px 0',
                            }}> */}
                        {/* <Box
                                sx={{
                                    position: 'absolute',
                                    mt: '10px',
                                    backgroundColor: 'white',
                                    content: '""',
                                    display: 'block',
                                    width: 15,
                                    height: 15,
                                    transform: 'rotate(45deg)',
                                    left: 8,
                                    top: '55%',
                                    boxShadow: '-4px 4px 6px -3px #888888',
                                }}
                            /> */}
                        {/* <div
                            className="tooltip-wrapper"
                            style={{ marginLeft: '14px', background: 'white !important' }}> */}
                        <AlertNoteField
                            noteRef={noteRef}
                            saveButtonRef={saveButtonRef}
                            userId={loggedInUser['custom:user_id']}
                            totalNote={totalNote}
                            notes={notes}
                            note={note}
                            noteTitle={noteTitle}
                            limit={limit}
                            check={check}
                            noteObj={noteObj}
                            // alertCheck={alertCheck}
                            anchorAlert={anchorAlert}
                            anchorNote={anchorNote}
                            loadingNotes={loadingNotes}
                            setAnchorAlert={setAnchorAlert}
                            setAnchorNote={setAnchorNote}
                            // alertNoteObj={alertNoteObj}
                            loadingNoteSave={loadingNoteSave}
                            handleNoteChange={setNote}
                            handleNotesSubmit={handleNotesSubmit}
                            // handleNoteEdit={handleNoteEdit}
                            handleCancelNote={handleCancelNote}
                            handleOnCheck={setCheck}
                            // getAllNotes={getAllNotes}
                            handleNoteTitleChange={setNoteTitle}
                            handleDeleteStaffNote={handleDeleteStaffNote}
                        />
                        {/* </div> */}
                        {/* </span> */}
                    </Popover>

                    {/* <Popover
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        onClose={handleClose1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            // Custom styles
                            ".MuiPopover-paper": {
                                width: "351px",
                                height: "187px",
                                paddingLeft: "10px",
                                paddingTop: "14px",
                                paddingRight: "13px",
                                paddingBottom: "10px"

                            }
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <BlueWarningIcon /> <Box className={classes.fontStyle1} style={{ marginLeft: "9px" }}>Shared Phone Number</Box>
                        </div>
                        <Box className={classes.fontStyle2} style={{ marginTop: "15px" }}>
                            You have indicated a shared phone number.
                            <br />
                            <br />
                            Patient has agreed to allow information about their care to be left at this phone number.
                        </Box>
                        <Box style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "33px" }}>
                            <Box className={classes.cancelButton} onClick={cancelClickSecondary}>Cancel</Box>
                            <Box className={classes.confirmButton} onClick={onSuccessSecondary}>Confirm</Box>
                        </Box>
                    </Popover> */}

                    {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose4}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            // Custom styles
                            ".MuiPopover-paper": {
                                width: "351px",
                                height: "187px",
                                paddingLeft: "10px",
                                paddingTop: "14px",
                                paddingRight: "13px",
                                paddingBottom: "10px"

                            }
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <BlueWarningIcon /> <Box className={classes.fontStyle1} style={{ marginLeft: "9px" }}>Shared Phone Number</Box>
                        </div>
                        <Box className={classes.fontStyle2} style={{ marginTop: "15px" }}>
                            You have indicated a shared phone number.
                            <br />
                            <br />
                            Patient has agreed to allow information about their care to be left at this phone number.
                        </Box>
                        <Box style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "33px" }}>
                            <Box className={classes.cancelButton} onClick={cancelClick}>Cancel</Box>
                            <Box className={classes.confirmButton} onClick={successConfirm}>Confirm</Box>
                        </Box>
                    </Popover> */}
                    <SharedContactAlert
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        closeFunction={handleClose4}
                        submitFunc={successConfirm}
                        cancelFunc={cancelClick}
                        text1="You have indicated a shared phone number."
                        text2="Patient has agreed to allow information about their care to be left at this phone number."
                        heading="Shared Phone Number"

                    />
                    {/* <Popover
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        onClose={handleClose1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        sx={{
                            // Custom styles
                            ".MuiPopover-paper": {
                                width: "351px",
                                height: "147px",
                                paddingLeft: "10px",
                                paddingTop: "14px",
                                paddingRight: "13px",
                                paddingBottom: "10px"

                            }
                        }}
                    >
                        <div style={{ display: "flex" }}>
                            <BlueWarningIcon /> <Box className={classes.fontStyle1} style={{ marginLeft: "9px" }}>Shared Phone Number</Box>
                        </div>
                        <Box className={classes.fontStyle2} style={{ marginTop: "15px" }}>
                            You have indicated this is <b>not</b> shared phone number.
                        </Box>
                        <Box style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "33px" }}>
                            <Box className={classes.cancelButton} onClick={cancelClick}>Cancel</Box>
                            <Box className={classes.confirmButton} onClick={successConfirm}>Confirm</Box>
                        </Box>
                    </Popover> */}

                    <SharedContactAlert
                        id={id1}
                        open={open1}
                        anchorEl={anchorEl1}
                        closeFunction={handleClose1}
                        submitFunc={successConfirm}
                        cancelFunc={cancelClick}
                        textOpposite={"You have indicated this is <b>not</b> shared phone number."}
                        heading={"Shared Phone Number"}
                        height={"147px"}

                    />
                    <SharedContactAlert
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        closeFunction={handleCloseEmailAlert}
                        submitFunc={succesConfirmEmail}
                        cancelFunc={cancelEmail}
                        text1="You have indicated a shared email"
                        text2="Patient has agreed to allow information about their care to be left at this email address."
                        heading={"Shared Email Inbox"}

                    />
                    <SharedContactAlert
                        id={id3}
                        open={open3}
                        anchorEl={anchorEl3}
                        closeFunction={handleCloseEmailAlert3}
                        submitFunc={succesConfirmEmail}
                        cancelFunc={cancelEmail}
                        heading={"Shared Email Inbox"}
                        textOpposite={"You have indicated this is <b>not</b> shared email."}
                        height={"127px"}

                    />
                </div>
            </div>
        </Grid >
    )
}
