import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import { useNotification } from 'shared/elements/Notification'
import { getBhList } from 'services/Patient/patient'
import { formatDateTime } from 'utilities/formats'
import Loading from 'shared/elements/Loading'
import BehaviorRateScaleList from 'containers/PatientsSetting/view/BehaviorRateScaleList'

const useStyles = makeStyles({
})

const BRateScales = ({
    onChange,
    fieldName,
    patientId
}) => {
    const classes = useStyles()
    const [bhList, setBhList] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const fetchBhScaleList = async () => {
        try {
            setDataLoading(true)
            const resp = await getBhList(patientId)
            const data = resp.data.length > 0 ? resp.data.map((mb)=>{
                return{
                    ...mb,
                    created_at: formatDateTime(mb.created_at, 'MM/DD/YYYY') 
                }
            }) : []
            onChange(`${fieldName}`, data)
            setBhList(resp.data)
            setDataLoading(false)
        } catch (ex) {
            setDataLoading(false)
            console.error('[BH scale] cannot fetch BH scale list', ex)
        }
    }
    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) fetchBhScaleList()

        return () => abortController.abort()
    }, [patientId])
    const notification = useNotification()
    return (
        <Grid item >
            {/* <Loading visible={dataLoading} /> */}
            <BehaviorRateScaleList patient_Id={patientId} fromClinicalNote={true}/>
            {/* {bhList.map((mb, index) => (
                <Grid
                    container
                    key={mb._id}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #E4E8EE',
                        paddingBottom: 5,
                        paddingTop: 5,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                    >
                        <Typography
                            style={{
                                color: '#303E4E',
                                fontFamily: 'Rubik',
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>
                            {mb.scale_type}
                        </Typography>

                        <Typography
                            style={{
                                color: '#303E4E',
                                fontFamily: 'Rubik',
                                fontSize: '14px',
                                fontWeight: '400',
                            }}>
                            {formatDateTime(mb.created_at, 'MM/DD/YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
            ))} */}
        </Grid>
    )
}

export default BRateScales
