import React from 'react'

export default function IconEditAPerson() {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.00004 7.00033C8.61087 7.00033 9.91671 5.69449 9.91671 4.08366C9.91671 2.47283 8.61087 1.16699 7.00004 1.16699C5.38921 1.16699 4.08337 2.47283 4.08337 4.08366C4.08337 5.69449 5.38921 7.00033 7.00004 7.00033Z"
                stroke="#667689"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M11.2058 9.18192L9.14076 11.2469C9.0591 11.3286 8.98327 11.4803 8.96577 11.5911L8.85494 12.3786C8.8141 12.6644 9.01244 12.8628 9.29828 12.8219L10.0858 12.7111C10.1966 12.6936 10.3541 12.6178 10.4299 12.5361L12.4949 10.4711C12.8508 10.1153 13.0199 9.70108 12.4949 9.17608C11.9758 8.65692 11.5616 8.82609 11.2058 9.18192Z"
                stroke="#667689"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.9083 9.47949C11.0833 10.1095 11.5733 10.5995 12.2033 10.7745"
                stroke="#667689"
                stroke-width="1.2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M1.98914 12.8333C1.98914 10.5758 4.23498 8.75 6.99998 8.75C7.60665 8.75 8.18997 8.8375 8.73247 9.00083"
                stroke="#667689"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
