import _ from 'lodash';
import * as actionType from '../actionType';

const initialState = {
    list: [],
    activeTab: 0,
    totalCount: 0,
    isLoading: false,
    selectedDetailId: null,
    formsDetailPageData: null,
    search: {
        filterString: null,
        type: null,
        from_date: null,
        to_date: null,
    },
    pagination: {
        page: 1,
        limit: 10,
    },
};

const forms = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_FORMS_ACTIVE_TAB: {
            return {
                ...initialState,
                activeTab: action.value,                
            };
        }
        case actionType.SET_FORMS_LIST: {
            return {
                ...state,
                list: action.value,
            };
        }

        case actionType.SET_FORMS_COUNT: {
            return {
                ...state,
                totalCount: action.value,
            };
        }
        case actionType.LOGOUT: {
            return initialState;
        }

        case actionType.SET_FORMS_PAGE_CHANGE: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            };
        }
        case actionType.SET_FORMS_SEARCH_DATE: {
            return {
                ...state,
                search: {
                    ...state.search,
                    from_date: action.value[0],
                    to_date: action.value[1],
                },
            };
        }
        case actionType.SET_FORMS_SEARCH_FILTER_STRING: {
            return {
                ...state,
                search: {
                    ...state.search,
                    filterString: action.value,
                },
                pagination: {
                    ...initialState.pagination
                },
            };
        }
        case actionType.SET_FORMS_SEARCH_CRITERIA: {
            return {
                ...state,
                search: action.value,
                pagination: {
                    ...initialState.pagination
                },
            };
        }
        
        case actionType.SET_FORMS_LOADING: {
            return {
                ...state,
                isLoading: action.value,
            };
        }
        case actionType.SET_FORMS_DETAIL_PAGE_DATA: {
            return {
                ...state,
                formsDetailPageData: action.value,
            };
        }
        case actionType.SET_FORMS_DETAIL_ID:
            return {
                ...state,
                selectedDetailId: action.value,
            };
        case actionType.RESET_FORMS_STATE:
            return {
                ...initialState
            }
        default:
            return state;
    }
};

export default forms;
