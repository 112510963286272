class LocalStorageAdapter<T> {
    private key: string
  
    constructor(key: string) {
      this.key = key
    }
  
    public setItem(value: T): void {
      try {
        const serializedValue = JSON.stringify(value)
        localStorage.setItem(this.key, serializedValue)
      } catch (error) {
        console.error(`Error setting item '${this.key}' in localStorage:`, error)
      }
    }
  
    public getItem(): T | null {
      try {
        const serializedValue = localStorage.getItem(this.key)
  
        return serializedValue ? (JSON.parse(serializedValue) as T) : null
      } catch (error) {
        console.error(`Error getting item '${this.key}' from localStorage:`, error)
  
        return null
      }
    }
  
    public clear(): void {
      try {
        localStorage.removeItem(this.key)
      } catch (error) {
        console.error(`Error removing item '${this.key}' from localStorage:`, error)
      }
    }
  
    public hasKey(): boolean {
      return localStorage.getItem(this.key) !== null
    }
  }
  
  export default LocalStorageAdapter