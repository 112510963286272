import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, FormLabel, Grid, makeStyles } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { useFormik } from 'formik'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import {
    createImmunization,
    getImmunizationById,
    updateImmunization,
} from 'services/Patient/patient'
import { ModalEl as Modal } from 'shared/elements'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import * as yup from 'yup'
import { INITIAL_VALUES } from './schema'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
})

interface Props {
    patientId: string
    itemId?: string | null
    open?: boolean
    onClose: (forceReload?: boolean) => void
    refetchData: (forceReload?: boolean) => void
}

export const LabCreateNew = (props: Props) => {
    const classes = useStyles()

    const { id: patient_id } = useParams<{ id: string }>()
    const notification = useNotification()

    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        //something here
        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload) => {
        console.log(payload)
    }

    const validationSchema = yup.object({
        vaccine: yup.object({
            code: yup.string().required('Vaccine is required!'),
            display: yup.string(),
        }),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={800}
            title={'Add Lab Order ...'}
            open={props.open}
            onClose={() => props.onClose(false)}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                        }}>
                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid container item alignItems="flex-end" spacing={2}>
                                <Grid item xs={12}>
                                    <TextBox
                                        disabled
                                        label="Filed Demo @_1"
                                        name="code"
                                        value={formik.values.vaccine.code}
                                        placeholder="Enter Code"
                                        // onChange={value => formik.setFieldValue('vaccine', value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                // required
                                label="Administered on"
                                name="administered_on"
                                value={formik.values.administered_on}
                                onChange={(value) => {
                                    formik.setFieldValue('administered_on', moment(value))
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => props.onClose(false)}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                className={classes.button_create}
                                loadingPosition="start"
                                startIcon={<></>}
                                onClick={() => formik.handleSubmit()}>
                                {props.itemId ? 'Update' : 'Submit'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
