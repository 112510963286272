import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    Popper,
    Chip,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    FilledInput,
    Tabs,
    Tab,
    Paper,
    Box,
    Switch,
} from '@material-ui/core'
import format from 'date-fns/format'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import clsx from 'clsx'
import React, { useEffect, useState, useRef, useCallback } from 'react'
import SearchIcon from '../../assests/icons/search_icon.svg'
import CalendarIcon from '../../assests/icons/CalendarIcon'
import { UserRoles } from '../../constants/user-roles'
import { ReactComponent as SignBatchIcon } from '../../assests/images/icon_sign_batch.svg'
import { ReactComponent as FilterIcon } from '../../assests/images/filter.svg'
import { DateTimePickerEl, DropdownEl } from '../../shared/elements'
import {getLoggedInUserRole, getLoggedInUserId, getPaginatedData} from '../../utilities'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    _getClinicalNotesFilterPatient,
    _getClinicalNotesFilterProvider,
} from '../../containers/ClinicalNotes/api/services'
import Routes from 'constants/routes'
import { useDebounce } from 'shared/hooks'
import { useNotification } from 'shared/elements/Notification'
import { getSpecialty } from '../../services/Calendar'
import CustomizedSwitches from 'components/shared/Elements/CustomSwitch'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import { DateRange } from '@material-ui/icons'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { endDateFilterToUTC, startDateFilterToUTC } from 'shared/utils'
import localforage from 'localforage'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)

const useStyles = makeStyles({
    icon_back: {
        marginRight: 8,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    notchedOutline: {
        borderColor: `#E9ECF1 !important`,
    },
    sign_btn: {
        width: '110px !important',
        fontWeight: 'normal !important',
        borderRadius: '50px !important',
        background: '#667689 !important',
        color: 'white !important',
        padding: '8px !important',
    },
    filter_btn: {
        width: '130px !important',
        fontWeight: 'normal !important',
        borderRadius: '10px !important',
        background: '#5571C6 !important',
        color: 'white !important',
        padding: '8px !important',
        textTransform: 'capitalize !important',
    },
    search_input_root: {
        width: 320,
        background: '#FBFCFC',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '& .MuiOutlinedInput-inputAdornedEnd': {
            fontSize: 16,
            color: '#303E4E !important',
        },
    },
    date_input: {
        background: '#FBFCFC',
        height: 40,
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    heading: {
        fontSize: '24px',
        color: '#303E4E',
    },
})

const userGenClass = makeStyles({
    scrollable_content: {
        maxHeight: '150px',
        overflow: 'auto',
        flexDirection: 'row',
        padding: '2px',
        maxWidth: '270px',
        '& .MuiFormControlLabel - root': {
            width: '100%',
        },
    },
    widthSearch: {
        width: '17rem',
    },
    content_label: {
        '& .MuiFormControlLabel - root': {
            fontWeight: 400,
        },
    },
})

const useStylesSwitch = makeStyles((theme) => ({
    switch_track: {
        backgroundColor: '#494748',
    },
    switch_base: {
        color: '#f6faf6',
        '&.Mui-disabled': {
            color: '#3d91ff',
        },
        '&.Mui-checked': {
            color: '#3d91ff',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#3d91ff',
        },
    },
    switch_primary: {
        '&.Mui-checked': {
            color: '#d9ddd9',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#9ea39e',
        },
    },
}))
const useAutoCompleteStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiAutocomplete-listbox': {
                '& li': {
                    marginLeft: '8px',
                    marginRight: '8px',
                    marginTop: '10px',
                    marginBottom: '10px',
                },
            },
        },
    }),
)

const CustomPopper = function (props) {
    const classes = useAutoCompleteStyles()
    return <Popper {...props} className={classes.root} placement="bottom" />
}

const clinicalNoteStatus = [
    { key: 'all', name: 'All', value: 'All', amount: 5 },
    {
        key: 'Pending',
        name: 'Pending Approval',
        value: 'Pending Approval',
        amount: 5,
    },
    {
        key: 'draft',
        name: 'Draft',
        value: 'Draft',
        amount: 5,
    },
    {
        key: 'locked',
        name: 'Locked',
        value: 'Lock',
        amount: 5,
    },
    {
        key: 'resent_to_edit',
        name: 'Resent to Edit',
        value: 'resent_to_edit',
        amount: 5,
    },
    {
        key: 'approved_lock',
        name: 'Approved & Locked',
        value: 'approved_lock',
        amount: 5,
    },
]
const data = [
    { value: 'DRAFT', label: 'DRAFT' },
    { value: 'IN_PROGRESS', label: 'IN_PROGRESS' },
    { value: 'PENDING_APPROVE', label: 'PENDING_APPROVE' },
    { value: 'RESEND_EDIT', label: 'RESEND_EDIT' },
    { value: 'LOCKED', label: 'LOCKED' },
]
const dataNote = [
    { value: 'medical_initial_note', label: 'Medical-Initial Note' },
    { value: 'medical_progress_note', label: 'Medical-Progress Note' },
    { value: 'psychiatry_initial_note', label: 'Psychiatry-Initial Note' },
    { value: 'psychiatry_progress_note', label: 'Psychiatry-Progress Note' },
    { value: 'therapy_initial_note', label: 'Therapy-Initial Note' },
    { value: 'therapy_progress_note', label: 'Therapy-Progress Note' },
    { value: 'injection_note', label: 'Injection-Note' },
]
const ClinicalNotesFilter = ({
    userId,
    searchData,
    onSearch,
    handleSignBatch,
    loading,
    filterSerach,
    tabContainer,
    disabled,
    refreshTheList,
    setRefreshDataWithDateList,
}) => {
    const classes = useStyles()
    const userclass = userGenClass()
    const history = useHistory()
    const notification = useNotification()
    const [isLoading, setLoading] = useState(false)
    const [activeRange, setActiveRange] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [filterData, setFilterData] = useState({
        text:"",
        createdAt: null,
        status: 'All',
    })

    const [mounted, setMounted] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [lVSetInVariable, setLVInVariable] = useState(false)

    const debouncedTextSearch = useDebounce(textSearch, 500)

    const scrollableContentRef = useRef(null)
    const scrollableContentRefProvider = useRef(null)

    const [patientData, setPatientData] = useState([])
    const [selectedUser, setSelectedUser] = useState({
        selectedPatient: [],
        selectedSpecialty: [],
        selectedStatus: [],
        selectedNote: [],
        selectedProvider: [],
    })

    const [currentPage, setCurrentPage] = useState(1)
    const [currentPageProvider, setCurrentPageProvider] = useState(1)
    const [rawSpecialtyData, setRawSpecialtyData] = useState([])
    const [specialtyData, setSpecialtyData] = useState([])
    const [statusData, setStatusData] = useState(data)
    const [noteData, setNoteData] = useState(dataNote)
    const [providerData, setProviderData] = useState([])
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patientSearch: '',
        specialtySearch: '',
        statusSearch: '',
        noteSearch: '',
        providerSearch: '',
    })
    const debouncedSearchFilterText = useDebounce(searchTextOffilter.patientSearch, 500)
    const debouncedSearchFilterTextProvider = useDebounce(searchTextOffilter.providerSearch, 500)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = useState(null)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        patientExpanded: false,
        specialtyExpanded: false,
        statusExpanded: false,
        noteExpanded: false,
        providerExpanded: false,
    })
    const [scrollPosition, setScrollPosition] = useState(0)
    const [scrollPositionProvider, setScrollPositionProvider] = useState(0)
    const ClinicalNotePermission = useSelector((state) => state?.permission.clinical_notes)
    const telehealthScreen = useSelector((state) => state.telehealthScreen)
    const { objectEncouterStart } = useSelector((state) => state.provider)
    const [bundleId, setBundleId] = useState('')
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])

    const userRole = getLoggedInUserRole()
    const userID = getLoggedInUserId()
    const { practitioner} = useSelector((state) => state?.login?.userData)

    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)

    const missingMedicalCoding = useSelector((state) => state.clinicalNotes.missingMedicalCoding)

    const pageMissingMedicalCoding = useSelector(
        (state) => state.clinicalNotes.pageMissingMedicalCoding,
    )

    const onChangeFilterData = (key, value) => {
        setFilterData((prev) => ({
            ...prev,
            [key]: value,
        }))
        onSearch({
            ...filterData,
            [key]: value,
        })
    }

    const [singleDate, setSingleDate] = useState(moment().format('MM/DD/YYYY'))
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false)
    const [mode, setMode] = useState('')
    const backToCurrentNote = () => {
        history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, {
            id: objectEncouterStart.clinical_note_id,
        })
    }
    const [value, setValue] = React.useState(0)
    const debouncedTabContainer = useDebounce(value, 500)
    const handleChange = (event, newValue) => {
        // tabContainer(newValue)
        setValue(newValue)
    }

    const [filterEl, setFilterEl] = React.useState(null)

    const handleFilterClick = (event) => {
        setFilterEl(filterEl ? null : event.currentTarget)
    }

    const handleFilterClose = () => {
        setFilterEl(null)
    }

    const openFilter = Boolean(filterEl)
    const id = openFilter ? 'simple-popper' : undefined

    const handleAccordionExpand = (name) => {
        if (name === 'patientExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.patientExpanded,
                }
            })
        } else if (name === 'specialtyExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.specialtyExpanded,
                }
            })
        } else if (name === 'statusExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.statusExpanded,
                }
            })
        } else if (name === 'noteExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.noteExpanded,
                }
            })
        } else if (name === 'providerExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.providerExpanded,
                }
            })
        }
    }

    const handleChipDelete = (name) => {
        if (name === 'selectedPatient') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedSpecialty') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'specialty')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedStatus') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'status')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedNote') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'note')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedProvider') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'provider')
            setTotalNoOfFilterUsed(newData)
        }
        setSelectedUser((selectedUser) => ({
            ...selectedUser,
            [name]: [],
        }))
        filterSerach({
            ...selectedUser,
            [name]: [],
        })
    }

    const handleChipDeleteAll = () => {
        setSelectedUser({
            selectedPatient: [],
            selectedSpecialty: [],
            selectedStatus: [],
            selectedNote: [],
            selectedProvider: [],
        })
        setTotalNoOfFilterUsed([])
        filterSerach({
            selectedPatient: [],
            selectedSpecialty: [],
            selectedStatus: [],
            selectedNote: [],
            selectedProvider: [],
        })
    }



    const handleLoadData = async (name = '')=> {
        try {
            const payload = {
                page: 1,
                query: searchTextOffilter.patientSearch,
            }

            const res = await _getClinicalNotesFilterPatient(payload)
            const {tempUserList, bundle_id, result} = getPaginatedData(res)

            setPatientData(tempUserList)
            setCurrentPage(1)
            setBundleId(bundle_id)
            setTotalPageNoOfPatient(result)
        } catch (e) {
            // notification('An error occurred, please try again!', 'error');
        }
    }

    const handleScroll = async () => {
        setScrollPosition(scrollableContentRef?.current?.scrollTop)
        if (totalPageNoOfPatient > currentPage) {
            const scrollableContent = scrollableContentRef?.current
            const tolerance = 2
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd) {
                try {
                    const nextPage = currentPage + 1
                    let payload = {
                        page: nextPage,
                        bundle_id: bundleId,
                        query: searchTextOffilter.patientSearch,
                    }
                    const res = await _getClinicalNotesFilterPatient(payload)
                    let tempUserList = []
                    res?.data?.data.map((item) =>
                        tempUserList.push({
                            label: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''
                                } ${item.last_name}`,
                            value: item?.id,
                            dob: `${moment(new Date(item.dob)).format('MM/DD/YYYY')}`,
                        }),
                    )
                    // let sortedArray = tempUserList.sort((item1, item2) => item1.label.localeCompare(item2.label));
                    let sortedPatientData = [...patientData, ...tempUserList]
                    // sortedPatientData.sort((item1, item2) => item1.label.localeCompare(item2.label))
                    setPatientData(sortedPatientData)
                    setCurrentPage(nextPage)
                    setBundleId(res?.data?.bundle_id)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
            }
        }
    }
    const handleLoadDataProvider = async (name = '') => {
        try {
            let payload = {
                page: 1,
                limit: 20,
                name: searchTextOffilter.providerSearch,
            }
            const res = await _getClinicalNotesFilterProvider(payload)
            let tempUserList = []
            res?.data?.data?.data?.map((item) =>
                tempUserList.push({ label: item?.name, value: item?.id }),
            )
            setProviderData(tempUserList)
            setCurrentPageProvider(1)
            const b = res?.data?.data?.total / 20
            const remainder = res?.data?.data?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = Math.floor(b)
            }
            setTotalPageNoOfProvider(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        }
    }

    const handleScrollProvider = async () => {
        setScrollPositionProvider(scrollableContentRefProvider?.current?.scrollTop)
        if (totalPageNoOfProvider > currentPageProvider) {
            const scrollableContent = scrollableContentRefProvider?.current
            const tolerance = 2
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd) {
                try {
                    const nextPage = currentPageProvider + 1
                    let payload = {
                        page: nextPage,
                        limit: 20,
                        name: searchTextOffilter.providerSearch,
                    }
                    const res = await _getClinicalNotesFilterProvider(payload)
                    let tempUserList = []
                    res?.data?.data?.data?.map((item) =>
                        tempUserList.push({ label: item?.name, value: item?.id }),
                    )
                    let sortedProviderData = [...providerData, ...tempUserList]
                    setProviderData(sortedProviderData)
                    setCurrentPageProvider(nextPage)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
            }
        }
    }

    const handleLoadDataSpecialty = async (name = '') => {
        try {
            let payload = {
                page: 1,
                size: 20,
                search: name,
            }
            const res = await getSpecialty(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({ label: item?.specialty, value: item?.id }),
            )
            setRawSpecialtyData(tempUserList)
            setSpecialtyData(tempUserList)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        }
    }

    const filterDataSpeciality = (event) => {
        const input = event
        setSearchTextOffilter((old) => ({
            ...old,
            specialtySearch: input,
        }))

        delayedRunHook(input.toLowerCase())
        setRawSpecialtyData([])
        if (input === '') {
            setSpecialtyData(rawSpecialtyData)
        } else {
            const filtered = rawSpecialtyData.filter((item) =>
                item.label.toLowerCase().includes(input.toLowerCase()),
            )
            setSpecialtyData(filtered)
        }
    }

    //Hook created for debouncing
    const delayedRunHook = useCallback(
        debounce((val) => {
            handleLoadDataSpecialty(val)
        }, 1000),
        [],
    )

    //Debouncing function for stop API hitting every key stroke
    function debounce(fn, delay) {
        let timeoutID
        return function (...args) {
            if (timeoutID) clearTimeout(timeoutID)
            timeoutID = setTimeout(() => {
                fn(...args)
            }, delay || 200)
        }
    }

    const handleLoadDataStatus = (event) => {
        const input = event
        setSearchTextOffilter((old) => ({
            ...old,
            statusSearch: input,
        }))
        if (input === '') {
            setStatusData(data)
        } else {
            const filtered = data.filter((item) =>
                item.label.toLowerCase().includes(input.toLowerCase()),
            )
            setStatusData(filtered)
        }
    }
    const handleLoadDataNote = (event) => {
        const input = event
        setSearchTextOffilter((old) => ({
            ...old,
            noteSearch: input,
        }))
        if (input === '') {
            setNoteData(dataNote)
        } else {
            const filtered = dataNote.filter((item) =>
                item.label.toLowerCase().includes(input.toLowerCase()),
            )
            setNoteData(filtered)
        }
    }

    const checkboxHandle = (event, user_id, name) => {
        if (name === 'selectedPatient') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedPatient?.includes(user_id)) {
                    data = [...selectedUser.selectedPatient, user_id]
                }
            } else {
                const filtered = selectedUser.selectedPatient?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('patient')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'patient'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
                setTotalNoOfFilterUsed(newData)
            }
            filterSerach({
                ...selectedUser,
                [name]: data,
            })
        } else if (name === 'selectedSpecialty') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedSpecialty?.includes(user_id)) {
                    data = [...selectedUser.selectedSpecialty, user_id]
                }
            } else {
                const filtered = selectedUser.selectedSpecialty?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('specialty')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'specialty'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'specialty')
                setTotalNoOfFilterUsed(newData)
            }
            filterSerach({
                ...selectedUser,
                [name]: data,
            })
        } else if (name === 'selectedStatus') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedStatus?.includes(user_id)) {
                    data = [...selectedUser.selectedStatus, user_id]
                }
            } else {
                const filtered = selectedUser.selectedStatus?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('status')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'status'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'status')
                setTotalNoOfFilterUsed(newData)
            }
            filterSerach({
                ...selectedUser,
                [name]: data,
            })
        } else if (name === 'selectedNote') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedNote?.includes(user_id)) {
                    data = [...selectedUser.selectedNote, user_id]
                }
            } else {
                const filtered = selectedUser.selectedNote?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('note')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'note'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'note')
                setTotalNoOfFilterUsed(newData)
            }
            filterSerach({
                ...selectedUser,
                [name]: data,
            })
        } else if (name === 'selectedProvider') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedProvider?.includes(user_id)) {
                    data = [...selectedUser.selectedProvider, user_id]
                }
            } else {
                const filtered = selectedUser.selectedProvider?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('provider')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'provider'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'provider')
                setTotalNoOfFilterUsed(newData)
            }
            filterSerach({
                ...selectedUser,
                [name]: data,
            })
        }
    }
    const userIsProvider = practitioner?.provider_account
    const setStoreTotalFilterValue = (value) => {
        if (userIsProvider) {
            if(value && !value?.includes('provider')){
                return [...value, 'provider'];
            }else if(value && value?.includes('provider')){
                return [...value]
            }
            return ['provider']
        }
        return value ? value : [];
    }
    const setStoreFilterStateValue = (value) => {
        if(userIsProvider){
            if(value && !value?.selectedProvider.includes(userID)){
                return {
                    selectedPatient: value?.selectedPatient?.length ? value?.selectedPatient : [],
                    selectedSpecialty: value?.selectedSpecialty?.length ? value?.selectedSpecialty : [],
                    selectedStatus: value?.selectedStatus?.length ? value?.selectedStatus : [],
                    selectedNote: value?.selectedNote?.length ? value?.selectedNote : [],
                    selectedProvider:[...value.selectedProvider,userID]
                }
                
            }else if(value && value?.selectedProvider.includes(userID)){
                return {
                    selectedPatient: value?.selectedPatient?.length ? value?.selectedPatient : [],
                    selectedSpecialty: value?.selectedSpecialty?.length ? value?.selectedSpecialty : [],
                    selectedStatus: value?.selectedStatus?.length ? value?.selectedStatus : [],
                    selectedNote: value?.selectedNote?.length ? value?.selectedNote : [],
                    selectedProvider:[...value.selectedProvider]
                }
            }
            return {
                selectedPatient: [],
                selectedSpecialty: [],
                selectedStatus: [],
                selectedNote: [],
                selectedProvider:[userID]
            }
        }
        return value ? value : null
    }
    const setDefaultFilterValue = async () => { 
        setDateRangeModalOpen(false)
        const storedFilter = await localforage.getItem('setClinicalFilter')
        const filteredStoredValue = await localforage.getItem('totalClinicalFilter')
        const activeRangeNew = await localforage.getItem('clinicalActiveRange')
        activeRangeNew ? setActiveRange(true) : setActiveRange(false)
        const startDateNew = await localforage.getItem('startDate')
        const endDateNew = await localforage.getItem('endDate')
        const singleDateNew = await localforage.getItem('singleDate')
        // console.log('storedFilter', storedFilter)
        // console.log('filteredStoredValue', filteredStoredValue)
        
        // const getlocalStoreFilterState = storedFilter ? storedFilter : null
        // const getLocalStoreTotalFilter = filteredStoredValue ? filteredStoredValue : []
        const getlocalStoreFilterState = setStoreFilterStateValue(storedFilter)
        const getLocalStoreTotalFilter = setStoreTotalFilterValue(filteredStoredValue)
        if (!activeRangeNew) {
            setRefreshDataWithDateList(false)
            if (singleDateNew) {
                setSingleDate(singleDateNew)
            } else {
                setSingleDate(moment().format('MM/DD/YYYY'))
            }
        } else {
            setRefreshDataWithDateList(false)
            if (startDateNew && endDateNew) {
                setSingleDate('')
                setStartDate(startDateNew)
                setEndDate(endDateNew)
                onChangeFilterData('createdAt', [
                    startDateFilterToUTC(startDateNew),
                    endDateFilterToUTC(endDateNew),
                ])
            } else {
                setSingleDate(moment().format('MM/DD/YYYY'))
            }
        }
        if (getLocalStoreTotalFilter.length) {
            setRefreshDataWithDateList(true)
            refreshTheList(true)
            setSelectedUser(getlocalStoreFilterState)
            setTotalNoOfFilterUsed(getLocalStoreTotalFilter)
            filterSerach(getlocalStoreFilterState)
        } else {
            setRefreshDataWithDateList(true)
            refreshTheList(true)
            filterSerach(selectedUser)
        }
        setDateRangeModalOpen(true)
        setLVInVariable(true)
    }
    const handlePopoverOpened = () => {
        if (scrollableContentRef.current) {
            scrollableContentRef.current.scrollTop = scrollPosition
        }
        if (scrollableContentRefProvider.current) {
            scrollableContentRefProvider.current.scrollTop = scrollPositionProvider
        }
    }

    const filterDataSetInLocal = () => {
        localforage.setItem('setClinicalFilter', {
            ...selectedUser,
        })
        localforage.setItem('totalClinicalFilter', [...totalNoOfFilterUsed])
    }

    const classesSwitch = useStylesSwitch()
    useEffect(() => {
        if (lVSetInVariable) {
            localforage.setItem('startDate', startDate)
            localforage.setItem('endDate', endDate)
            localforage.setItem('singleDate', singleDate)
        }
    }, [startDate, endDate, singleDate])
    useEffect(() => {
        setDefaultFilterValue()
    }, [])
    useEffect(() => {
        if (lVSetInVariable) {
            filterDataSetInLocal()
        }
    }, [selectedUser])

    useEffect(() => {
        setFilterData(searchData)
    }, [searchData])

    useEffect(() => {
        tabContainer(value)
    }, [debouncedTabContainer])

    useEffect(() => {
        handleLoadData()
    }, [debouncedSearchFilterText])

    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedSearchFilterTextProvider])

    useEffect(() => {
        handleLoadDataSpecialty()
    }, [])

    useEffect(() => {
        if (!mounted) return setMounted(true)
        onChangeFilterData('text', textSearch)
    }, [debouncedTextSearch])

    return (
        <Grid
            style={{ pointerEvents: disabled ? 'none' : '' }}
            container
            // className={classes.clinical_notes_container}
            alignItems="center">
            {/* {telehealthScreen.active && objectEncouterStart.clinical_note_id && (
                <Grid container>
                    <Grid
                        onClick={backToCurrentNote}
                        container
                        item
                        alignItems="center"
                        style={{
                            cursor: 'pointer',
                            width: 'max-content',
                        }}>
                        <IconButton className={classes.icon_back}>
                            <KeyboardBackspaceOutlinedIcon style={{ fontSize: 16 }} />
                        </IconButton>
                        <Typography className={classes.back__btn}>
                            {'Back to current note'}
                        </Typography>
                    </Grid>
                </Grid>
            )} */}
            <Grid item style={{ flex: 1 }}>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{
                    marginLeft: 8,
                    width: 'fit-content',
                }}>
                <Grid item>
                    {' '}
                    <TextField
                        placeholder="Search..."
                        onChange={(e) => setTextSearch(e.target.value)}
                        value={textSearch}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton size="small">
                                        <img src={SearchIcon} alt=""></img>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    {textSearch && (
                                        <IconButton size="small" onClick={() => setTextSearch('')}>
                                            <CloseIcon
                                                style={{
                                                    fontSize: 16,
                                                    color: '#303E4E',
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                            disableUnderline: true,
                            classes: {
                                notchedOutline: classes.notchedOutline,
                                root: classes.search_input_root,
                            },
                        }}
                    />
                </Grid>
                {/* <Grid item style={{ minWidth: 240 }}>
                    <DropdownEl
                        value={filterData.status}
                        options={clinicalNoteStatus}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        renderValue={(selected) =>
                            `Status: ${
                                clinicalNoteStatus.find(
                                    (item) =>
                                        item.key ===
                                        (selected ||
                                            filterData.status),
                                )?.value || 'All'
                            }`
                        }
                        onChange={(e) => {
                            onChangeFilterData(
                                'status',
                                e.value,
                            )
                        }}
                    />
                </Grid> */}

                {
                    <Grid item style={{ width: 300 }}>
                        {!activeRange && (
                            <SingleCalenderComp
                                onChange={(e) => {
                                    setSingleDate(e ? format(new Date(e), 'MM/dd/yyyy') : '')
                                    onChangeFilterData(
                                        'createdAt',
                                        e ? [startDateFilterToUTC(e), endDateFilterToUTC(e)] : [],
                                    )
                                }}
                                HideShowIcon={activeRange}
                                value={singleDate}
                                onClear={(e) => {
                                    onChangeFilterData('createdAt', [])
                                    setSingleDate('')
                                }}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'absolute',
                                backgroundColor: 'primary.dark',
                                '&:hover': {
                                    backgroundColor: 'primary.main',
                                    opacity: [0.9, 0.8, 0.7],
                                },
                            }}>
                            <Paper>
                                {activeRange && (
                                    <DateRangeComp
                                        style={{ marginTop: '20px' }}
                                        onChange={(e) => {
                                            if (e?.startDate && e?.endDate) {
                                                setSingleDate('')
                                                setStartDate(format(e?.startDate, 'MM/dd/yyyy'))
                                                setEndDate(format(e?.endDate, 'MM/dd/yyyy'))
                                                onChangeFilterData('createdAt', [
                                                    startDateFilterToUTC(e?.startDate),
                                                    endDateFilterToUTC(e?.endDate),
                                                ])
                                            }
                                        }}
                                        activeRange={activeRange}
                                        defaultRange={singleDate || null}
                                        dateSeparator="-"
                                        startDate={startDate}
                                        endDate={endDate}
                                        onClear={() => {
                                            setSingleDate('')
                                            setStartDate('')
                                            setEndDate('')
                                            onChangeFilterData('createdAt', [])
                                        }}
                                        dateRangeModalOpen={dateRangeModalOpen}
                                    />
                                )}
                            </Paper>
                        </Box>
                    </Grid>
                }

                <Grid item>
                    <HtmlTooltip
                        title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                        arrow
                        placement="bottom"
                        style={{
                            background: 'white !important',
                            color: '#303E4E',
                        }}>
                        <Box style={{ marginTop: '12px' }}>
                            <AntSwitch
                                onChange={() => {
                                    localforage.setItem('clinicalActiveRange', !activeRange)
                                    setActiveRange(!activeRange)
                                    // onChangeFilterData('createdAt', [])
                                    if (!singleDate && activeRange) {
                                        setStartDate('')
                                        setEndDate('')
                                        setSingleDate(format(new Date(), 'MM/dd/yyyy'))
                                        onChangeFilterData('createdAt', [
                                            startDateFilterToUTC(moment()),
                                            endDateFilterToUTC(moment()),
                                        ])
                                    }
                                }}
                                checked={activeRange}
                            />
                        </Box>
                    </HtmlTooltip>
                </Grid>

                <Grid item>
                    <LoadingButton
                        onClick={handleFilterClick}
                        variant="contained"
                        className={classes.filter_btn}>
                        {loading || <FilterIcon />}
                        <span
                            style={{
                                marginLeft: 6,
                                fontSize: '16px',
                            }}>
                            Filters
                        </span>
                        {totalNoOfFilterUsed.length > 0 && (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    fontWeight: 'normal',
                                    borderRadius: '20px',
                                    background: 'white',
                                    color: '#5571C6',
                                    padding: '6px',
                                    marginLeft: 6,
                                    height: '10px',
                                }}>
                                {totalNoOfFilterUsed.length}
                            </span>
                        )}
                    </LoadingButton>

                    <Popover
                        id={id}
                        open={openFilter}
                        anchorEl={filterEl}
                        onClose={handleFilterClose}
                        TransitionProps={{
                            onEntered: handlePopoverOpened,
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                        <div className="popover_card_holder">
                            <div className="heading_holder">
                                <h2 className="heading">Filter By:</h2>
                                <p className="clear_holder" onClick={handleChipDeleteAll}>
                                    Clear All Filters
                                </p>
                            </div>
                            <div className="cstm_accordion_holder">
                                <Accordion expanded={isAccordionExpanded.patientExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => handleAccordionExpand('patientExpanded')}>
                                        <Typography
                                            className="acc_txt"
                                            style={{ fontWeight: '500' }}>
                                            Patient
                                        </Typography>
                                        {selectedUser?.selectedPatient?.length > 0 ? (
                                            <Chip
                                                label={selectedUser?.selectedPatient?.length}
                                                onDelete={() => handleChipDelete('selectedPatient')}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        <div>
                                            <div className="search_holder">
                                                <FormControl
                                                    className={userclass.widthSearch}
                                                    variant="filled">
                                                    <FilledInput
                                                        id="filled-adornment-password"
                                                        placeholder="Search..."
                                                        value={searchTextOffilter?.patientSearch}
                                                        onChange={(e) =>
                                                            setSearchTextOffilter((old) => ({
                                                                ...old,
                                                                patientSearch: e.target.value,
                                                            }))
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        alt="icon"></img>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div
                                                className={userclass.scrollable_content}
                                                ref={(ref) => (scrollableContentRef.current = ref)}
                                                onScroll={handleScroll}>
                                                {patientData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.label,
                                                                                'selectedPatient',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedPatient?.includes(
                                                                            item.label,
                                                                        )}
                                                                    />
                                                                }
                                                                label={
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent:
                                                                                'center',
                                                                        }}>
                                                                        <span>{item.label}</span>

                                                                        {item?.dob && (
                                                                            <span
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '5px',
                                                                                    fontSize:
                                                                                        '14px',
                                                                                }}>
                                                                                {item.dob}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                }
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="cstm_accordion_holder">
                                <Accordion expanded={isAccordionExpanded.specialtyExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => handleAccordionExpand('specialtyExpanded')}>
                                        <Typography
                                            className="acc_txt"
                                            style={{ fontWeight: '500' }}>
                                            Specialty
                                        </Typography>
                                        {selectedUser?.selectedSpecialty?.length > 0 ? (
                                            <Chip
                                                label={selectedUser?.selectedSpecialty?.length}
                                                onDelete={() =>
                                                    handleChipDelete('selectedSpecialty')
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        <div>
                                            <div className="search_holder">
                                                <FormControl
                                                    className={userclass.widthSearch}
                                                    variant="filled">
                                                    <FilledInput
                                                        id="filled-adornment-password"
                                                        value={searchTextOffilter?.specialtySearch}
                                                        placeholder="Search..."
                                                        onChange={(e) =>
                                                            filterDataSpeciality(e.target.value)
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        alt="icon"></img>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={userclass.scrollable_content}>
                                                {specialtyData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.label,
                                                                                'selectedSpecialty',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedSpecialty?.includes(
                                                                            item.label,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="cstm_accordion_holder">
                                <Accordion expanded={isAccordionExpanded.statusExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => handleAccordionExpand('statusExpanded')}>
                                        <Typography
                                            className="acc_txt"
                                            style={{ fontWeight: '500' }}>
                                            Status
                                        </Typography>
                                        {selectedUser?.selectedStatus?.length > 0 ? (
                                            <Chip
                                                label={selectedUser?.selectedStatus?.length}
                                                onDelete={() => handleChipDelete('selectedStatus')}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        <div>
                                            <div className="search_holder">
                                                <FormControl
                                                    className={userclass.widthSearch}
                                                    variant="filled">
                                                    <FilledInput
                                                        id="filled-adornment-password"
                                                        placeholder="Search..."
                                                        value={searchTextOffilter?.statusSearch}
                                                        onChange={(e) =>
                                                            handleLoadDataStatus(e.target.value)
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        alt="icon"></img>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={userclass.scrollable_content}>
                                                {statusData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.value,
                                                                                'selectedStatus',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedStatus?.includes(
                                                                            item.value,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="cstm_accordion_holder">
                                <Accordion expanded={isAccordionExpanded.noteExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => handleAccordionExpand('noteExpanded')}>
                                        <Typography
                                            className="acc_txt"
                                            style={{ fontWeight: '500' }}>
                                            Note
                                        </Typography>
                                        {selectedUser?.selectedNote?.length > 0 ? (
                                            <Chip
                                                label={selectedUser?.selectedNote?.length}
                                                onDelete={() => handleChipDelete('selectedNote')}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        <div>
                                            <div className="search_holder">
                                                <FormControl
                                                    className={userclass.widthSearch}
                                                    variant="filled">
                                                    <FilledInput
                                                        id="filled-adornment-password"
                                                        placeholder="Search..."
                                                        value={searchTextOffilter?.noteSearch}
                                                        onChange={(e) =>
                                                            handleLoadDataNote(e.target.value)
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        alt="icon"></img>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className={userclass.scrollable_content}>
                                                {noteData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.value,
                                                                                'selectedNote',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedNote?.includes(
                                                                            item.value,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div className="cstm_accordion_holder">
                                <Accordion expanded={isAccordionExpanded.providerExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => handleAccordionExpand('providerExpanded')}>
                                        <Typography
                                            className="acc_txt"
                                            style={{ fontWeight: '500' }}>
                                            Provider
                                        </Typography>
                                        {selectedUser?.selectedProvider?.length > 0 ? (
                                            <Chip
                                                label={selectedUser?.selectedProvider?.length}
                                                onDelete={() =>
                                                    handleChipDelete('selectedProvider')
                                                }
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        <div>
                                            <div className="search_holder">
                                                <FormControl
                                                    className={userclass.widthSearch}
                                                    variant="filled">
                                                    <FilledInput
                                                        id="filled-adornment-password"
                                                        placeholder="Search..."
                                                        value={searchTextOffilter?.providerSearch}
                                                        onChange={(e) =>
                                                            setSearchTextOffilter((old) => ({
                                                                ...old,
                                                                providerSearch: e.target.value,
                                                            }))
                                                        }
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end">
                                                                    <img
                                                                        src={SearchIcon}
                                                                        alt="icon"></img>
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div
                                                className={userclass.scrollable_content}
                                                ref={(ref) =>
                                                    (scrollableContentRefProvider.current = ref)
                                                }
                                                onScroll={handleScrollProvider}>
                                                {providerData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.value,
                                                                                'selectedProvider',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedProvider?.includes(
                                                                            item.value,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        </div>
                    </Popover>
                </Grid>
                {ClinicalNotePermission.includes('batch_sign_clinical_notes') && (
                    <Grid item>
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            variant="contained"
                            className={classes.sign_btn}
                            onClick={handleSignBatch}>
                            {loading || <SignBatchIcon />}
                            <span
                                style={{
                                    marginLeft: 6,
                                }}>
                                Sign
                            </span>
                        </LoadingButton>
                    </Grid>
                )}

                {/* <TextInput clinicalNoteStatus={clinicalNoteStatus} /> */}

                {/* <Autocomplete
                    freeSolo
                    id="search"
                    options={searchData}
                    getOptionLabel={(option) =>
                        option?.first_name
                            ? `${option.first_name} ${option.last_name}`
                            : ''
                    }
                    disableClearable
                    onInputChange={onTextChange}
                    onChange={onOptionSelect}
                    PopperComponent={CustomPopper}
                    renderOption={(option) => {
                        return (
                            <Grid
                                container
                                direction="column">
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        className={
                                            classes.capitalize
                                        }>
                                        <strong>{`${option.first_name} ${option.last_name}`}</strong>
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        className={
                                            classes.capitalize
                                        }>
                                        {moment(
                                            option.dob,
                                        ).format(
                                            'MM/DD/YYYY',
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={
                                'Search by Patient Name'
                            }
                            onChange={(e) =>
                                onSearch(e.target.value)
                            }
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                startAdornment: (
                                    <InputAdornment>
                                        <IconButton size="small">
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                classes: {
                                    root: classes.cssOutlinedInput,
                                    focused:
                                        classes.cssFocused,
                                    notchedOutline:
                                        classes.notchedOutline,
                                },
                            }}
                            variant="outlined"
                            autoFocus
                            autoComplete={false}
                        />
                    )}
                /> */}
            </Grid>
            <Grid container>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Open Notes"
                    />
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Signed & Locked"
                    />
                </Tabs>
            </Grid>
        </Grid>
    )
}

export default ClinicalNotesFilter
