import styled from '@emotion/styled'
import { Button, FormLabel, ClickAwayListener, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, FormControlLabel, Grid, Radio, Stack, Switch, Typography } from '@mui/material'
import moment, { Moment } from 'moment'
import React, { useState, useEffect, memo } from 'react'
import { KeyboardDatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import TextBox from 'shared/elements/FormItem/TextBox'
import { OPTIONS_TZ, days } from './constants'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import './styles.scss'
import { OPTIONS_TIMEZONES } from 'constants/states'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

export const DatePicker = memo(({ value, label, required, notDefaultValue, ...props }) => {
    const [date, setDate] = useState(value || notDefaultValue ? null : new Date())

    const handleClickAway = () => {
        if (!props.noAway) {
            props.onChange(date)
        }
    }
    useEffect(() => {
        value && setDate(value)
    }, [value])

    return (
        <div className="fff-datepicker">
            {label && (
                <FormLabel
                    component="p"
                    className="input-form-label"
                    style={{ fontSize: 14, fontWeight: 500 }}>
                    <span className={!!required ? 'required' : ''}>{label}</span>
                </FormLabel>
            )}

            <ClickAwayListener onClickAway={handleClickAway}>
                <Grid container item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            {...props}
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            format={props.format || 'MM/dd/yyyy'}
                            value={date}
                            error={props.error}
                            helperText={props.helperText}
                            placeholder="MM/DD/YYYY"
                            onChange={(_, value) => {
                                setDate(value)
                                props.onChange && props.onChange(value)
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </ClickAwayListener>
        </div>
    )
})

export function EndRepeat({ type, onChangeType, onChangeValues, end, condition, error }) {
    return (
        <Box className="ends-repeat">
            <Typography
                style={{
                    fontWeight: 500,
                }}>
                Ends <span className="required_text">*</span>
            </Typography>
            <Box>
                <FormControlLabel
                    sx={{
                        paddingLeft: '10px',
                        '& .MuiRadio-root': {
                            paddingRight: '5px',
                        },
                    }}
                    value="ON_YEAR"
                    control={
                        <Radio
                            checked={type === 'ON_YEAR'}
                            onChange={(e) => onChangeType(e.target.value)}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 18,
                                },
                            }}
                        />
                    }
                    label="In one year"
                />
            </Box>
            <Box className="group-radio-datePicker">
                <Box className="left-radio">
                    <FormControlLabel
                        sx={{
                            paddingLeft: '10px',
                            '& .MuiRadio-root': {
                                paddingRight: '5px',
                            },
                        }}
                        value="ON"
                        control={
                            <Radio
                                checked={type === 'ON'}
                                onChange={(e) => onChangeType(e.target.value)}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                }}
                            />
                        }
                        label="On"
                    />
                </Box>
                <Box className="right-picker">
                    <DatePicker
                        noAway
                        disabled={type !== 'ON'}
                        value={end}
                        onChange={onChangeValues}
                    />
                </Box>
            </Box>
            <TextError condition={condition} error={error} />
        </Box>
    )
}

export function RepeatOne({ repeatOne, onChangeDay, isRecur, disabled }) {
    return (
        <Box className={`wrap-repeat-one ${disabled ? 'disabled' : ''}`}>
            <Typography
                style={{
                    marginBottom: 8,
                    fontWeight: 500,
                }}>
                Repeat on <span className="required_text">*</span>
            </Typography>
            <Box className="list-btn-days">
                {days.map((day, index) => {
                    const selected = repeatOne.find((item) => item === day.key)
                    return (
                        <Box
                            key={index}
                            className={`item-day ${!!selected && isRecur ? 'active' : ''}`}
                            onClick={() => onChangeDay(day.key)}>
                            {day.value}
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export function TimeLabel({
    label,
    required,
    allDay,
    date,
    time,
    onChangeDate,
    onChangeTime,
    dateCondition,
    dateError,
    timeCondition,
    timeError,
    disabled,
    maxDate,
    minDate,
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ paddingBottom: '0 !important' }}>
                {label && (
                    <Typography
                        sx={{ fontWeight: '500 !important', marginBottom: '-6px !important' }}>
                        {label}
                        {!!required && (
                            <Typography sx={{ color: 'red !important' }} component={'span'}>
                                *
                            </Typography>
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    onChange={(value) => {
                        onChangeDate?.(value)
                    }}
                    value={date}
                    minDate={minDate}
                    error={dateCondition}
                    helperText={
                        <div
                            style={{
                                marginTop: 4,
                                textTransform: 'initial',
                            }}>
                            {dateError}
                        </div>
                    }
                    notDefaultValue
                    maxDate={maxDate}
                />
            </Grid>
            <Grid item xs={6}>
                <Box
                    sx={{
                        '& input[type="time"]::-webkit-calendar-picker-indicator': {},
                    }}>
                    <TextBox
                        id="time"
                        type="time"
                        value={allDay ? '' : time}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(value) => {
                            onChangeTime?.(value)
                        }}
                        error={timeCondition}
                        helperText={
                            <div
                                style={{
                                    marginTop: 4,
                                    textTransform: 'initial',
                                }}>
                                {timeError}
                            </div>
                        }
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export function SubmitFooter(props) {
    const useStyles = makeStyles(() => ({
        button_cancel: {
            background: '#303E4E !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
        },
        button_create: {
            background: '#5571C6 !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
            textTransform: 'unset !important',
        },
        button_delete: {
            background: '#d31e3f !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
            textTransform: 'unset !important',
        },
    }))
    const classes = useStyles()

    const [openCancelModal, setOpenCancelModal] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const patientPortalDialogContent = () => {
        return(
            <div style={{ maxWidth: '350px' }}>
                <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7667 3.98257C13.0097 4.08325 13.2304 4.23081 13.4163 4.41682L19.5843 10.5838C19.7705 10.7698 19.9182 10.9906 20.019 11.2336C20.1197 11.4767 20.1716 11.7372 20.1716 12.0003C20.1716 12.2634 20.1197 12.524 20.019 12.767C19.9182 13.0101 19.7705 13.2309 19.5843 13.4168L13.4163 19.5838C13.2304 19.7698 13.0097 19.9174 12.7667 20.0181C12.5238 20.1187 12.2633 20.1706 12.0003 20.1706C11.7374 20.1706 11.4769 20.1187 11.234 20.0181C10.991 19.9174 10.7703 19.7698 10.5843 19.5838L4.41635 13.4168C4.23018 13.2309 4.0825 13.0101 3.98173 12.767C3.88097 12.524 3.8291 12.2634 3.8291 12.0003C3.8291 11.7372 3.88097 11.4767 3.98173 11.2336C4.0825 10.9906 4.23018 10.7698 4.41635 10.5838L10.5843 4.41682C10.7703 4.23081 10.991 4.08325 11.234 3.98257C11.4769 3.8819 11.7374 3.83008 12.0003 3.83008C12.2633 3.83008 12.5238 3.8819 12.7667 3.98257ZM11.2932 13.7069C11.4808 13.8945 11.7351 13.9998 12.0003 13.9998C12.2656 13.9998 12.5199 13.8945 12.7074 13.7069C12.895 13.5194 13.0003 13.265 13.0003 12.9998V7.99982C13.0003 7.7346 12.895 7.48025 12.7074 7.29271C12.5199 7.10517 12.2656 6.99982 12.0003 6.99982C11.7351 6.99982 11.4808 7.10517 11.2932 7.29271C11.1057 7.48025 11.0003 7.7346 11.0003 7.99982V12.9998C11.0003 13.265 11.1057 13.5194 11.2932 13.7069ZM11.2932 16.7069C11.4808 16.8945 11.7351 16.9998 12.0003 16.9998C12.2656 16.9998 12.5199 16.8945 12.7074 16.7069C12.895 16.5194 13.0003 16.265 13.0003 15.9998C13.0003 15.7346 12.895 15.4802 12.7074 15.2927C12.5199 15.1052 12.2656 14.9998 12.0003 14.9998C11.7351 14.9998 11.4808 15.1052 11.2932 15.2927C11.1057 15.4802 11.0003 15.7346 11.0003 15.9998C11.0003 16.265 11.1057 16.5194 11.2932 16.7069Z" fill="#D80404"/>
                        </svg>
                        <Typography variant="h6" fontWeight="bold">
                            Are you sure?
                        </Typography>
                    </span>
                    
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <div 
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3
                        }}>
                        Are you sure you want to discard your changes?
                    </div>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            color: '#42526E', 
                            background: '#fff',
                            borderRadius: "5px !important",
                            marginRight: '10px', 
                            height: 32
                        }}
                        onClick={() => {
                            props.onClose?.()
                        }}>
                        Yes
                    </Button>
                    <Button
                        size="small"
                        className="no-button"
                        onClick={() => setOpenCancelModal(false)}>
                        No
                    </Button>
                </span>
            </div>
        )
    }

    const DeleteDialogContent = () => {
        return(
            <div style={{ maxWidth: '350px' }}>
                <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect width="24" height="24" fill="white" fill-opacity="0.01"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7667 3.98257C13.0097 4.08325 13.2304 4.23081 13.4163 4.41682L19.5843 10.5838C19.7705 10.7698 19.9182 10.9906 20.019 11.2336C20.1197 11.4767 20.1716 11.7372 20.1716 12.0003C20.1716 12.2634 20.1197 12.524 20.019 12.767C19.9182 13.0101 19.7705 13.2309 19.5843 13.4168L13.4163 19.5838C13.2304 19.7698 13.0097 19.9174 12.7667 20.0181C12.5238 20.1187 12.2633 20.1706 12.0003 20.1706C11.7374 20.1706 11.4769 20.1187 11.234 20.0181C10.991 19.9174 10.7703 19.7698 10.5843 19.5838L4.41635 13.4168C4.23018 13.2309 4.0825 13.0101 3.98173 12.767C3.88097 12.524 3.8291 12.2634 3.8291 12.0003C3.8291 11.7372 3.88097 11.4767 3.98173 11.2336C4.0825 10.9906 4.23018 10.7698 4.41635 10.5838L10.5843 4.41682C10.7703 4.23081 10.991 4.08325 11.234 3.98257C11.4769 3.8819 11.7374 3.83008 12.0003 3.83008C12.2633 3.83008 12.5238 3.8819 12.7667 3.98257ZM11.2932 13.7069C11.4808 13.8945 11.7351 13.9998 12.0003 13.9998C12.2656 13.9998 12.5199 13.8945 12.7074 13.7069C12.895 13.5194 13.0003 13.265 13.0003 12.9998V7.99982C13.0003 7.7346 12.895 7.48025 12.7074 7.29271C12.5199 7.10517 12.2656 6.99982 12.0003 6.99982C11.7351 6.99982 11.4808 7.10517 11.2932 7.29271C11.1057 7.48025 11.0003 7.7346 11.0003 7.99982V12.9998C11.0003 13.265 11.1057 13.5194 11.2932 13.7069ZM11.2932 16.7069C11.4808 16.8945 11.7351 16.9998 12.0003 16.9998C12.2656 16.9998 12.5199 16.8945 12.7074 16.7069C12.895 16.5194 13.0003 16.265 13.0003 15.9998C13.0003 15.7346 12.895 15.4802 12.7074 15.2927C12.5199 15.1052 12.2656 14.9998 12.0003 14.9998C11.7351 14.9998 11.4808 15.1052 11.2932 15.2927C11.1057 15.4802 11.0003 15.7346 11.0003 15.9998C11.0003 16.265 11.1057 16.5194 11.2932 16.7069Z" fill="#D80404"/>
                        </svg>
                        <Typography variant="h6" fontWeight="bold">
                            Delete availability
                        </Typography>
                    </span>
                    
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <div 
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3
                        }}>
                        Are you sure you want to delete this {props.editMode}?
                    </div>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            color: '#42526E', 
                            background: '#fff',
                            borderRadius: "5px !important",
                            marginRight: '10px', 
                            height: 32
                        }}
                        onClick={() => {
                            props.onDelete?.()
                            setDeleteDialogOpen(false)
                        }}>
                        Yes
                    </Button>
                    <Button
                        size="small"
                        className="no-button"
                        onClick={() => setDeleteDialogOpen(false)}>
                        No
                    </Button>
                </span>
            </div>
        )
    }

    return (
        <Grid container justifyContent={props.id ? 'space-between' : 'normal'}>
            {!!props.id && (
                <Grid xs={3}>
                    <HtmlTooltip 
                        title={DeleteDialogContent()}
                        placement='top-start'
                        arrow
                        disableHoverListener
                        open={deleteDialogOpen}
                    >
                        <LoadingButton
                            loading={props.deleting}
                            disabled={props.disabled}
                            variant="contained"
                            loadingPosition="start"
                            startIcon={<></>}
                            className={classes['button_delete']}
                            onClick={() => setDeleteDialogOpen(true)}>
                            Delete Availability
                        </LoadingButton>
                    </HtmlTooltip>
                </Grid>
            )}
            <Grid container item xs={props.id ? 6 : 12} spacing={2}>
                <Grid item xs={6}>
                    <HtmlTooltip
                        placement='top-start'
                        disableHoverListener
                        arrow
                        PopperProps={{
                            disablePortal: true,
                        }}
                        title={patientPortalDialogContent()}
                        open={openCancelModal}
                    >
                        <Button
                            variant="contained"
                            className={classes['button_cancel']}
                            onClick={() => setOpenCancelModal(true)}>
                            Cancel
                        </Button>
                    </HtmlTooltip>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        loading={props.loading}
                        disabled={props.disabled}
                        variant="contained"
                        className={classes['button_create']}
                        loadingPosition="start"
                        startIcon={<></>}
                        onClick={props.onSubmit}>
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    )
}
export function SwitchLable({ label, value, onChange, disabled=false }) {
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            position: 'absolute !important',
            color: '#fff',
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(12px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: '#1890ff',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: 'all 0.2s',
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }))
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
                checked={value}
                inputProps={{ 'aria-label': 'ant design' }}
                onChange={onChange}
                disabled={disabled}
            />
            <Typography>{label}</Typography>
        </Stack>
    )
}

export const TextError = (props) => {
    return props.condition ? (
        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
            {props.error}
        </p>
    ) : (
        <></>
    )
}

export const SelectTimezone = (props) => {
    const classes = makeStyles({
        autocomplete: {
            '& .MuiIconButton-root': {
                color: '#303e4e',
            },
        },
    })()

    const {
        placeholder,
        onOpen,
        onClose,
        onChange,
        error,
        helperText,
        value,
        height = '35px',
    } = props

    const [open, setOpen] = useState(false)

    const [optionTZ, setOptionTZ] = useState([
        {
            key: 'US/Eastern',
            value: '(UTC-05:00) US/Eastern',
        },
        {
            key: 'US/Central',
            value: '(UTC-06:00) US/Central',
        },
        {
            key: 'US/Mountain',
            value: '(UTC-07:00) US/Mountain',
        },
        {
            key: 'US/Pacific',
            value: '(UTC-08:00) US/Pacific',
        },
        {
            key: 'US/Alaska',
            value: '(UTC-09:00) US/Alaska',
        },
        {
            key: 'US/Hawaii',
            value: '(UTC-10:00) US/Hawaii',
        },
    ])

    return (
        <Box
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: height,
                    minHeight: height,
                    padding: '5px 12px !important',
                },
                '& .MuiAutocomplete-paper': {
                    backgroundColor: '#fff !important',
                },
            }}>
            <Autocomplete
                className={classes['autocomplete']}
                loading={true}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                onClose={() => {
                    setOpen(false)
                    onClose?.()
                }}
                value={OPTIONS_TIMEZONES.find((item) => item.key === value) || null}
                options={OPTIONS_TIMEZONES}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>{option.label}</span>
                        </li>
                    )
                }}
                autoComplete
                getOptionLabel={(option) => option.label}
                clearIcon={false}
                autoSelect
                disablePortal
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0px 36px 0px 0px',
                            },
                        }}
                        size="small"
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                    />
                )}
                onInputChange={(e, value) => {}}
                onChange={(e, option) => {
                    onChange?.(option?.key)
                }}
            />
        </Box>
    )
}
