import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { ModalEl as Modal } from 'shared/elements'
import './style.scss'
import { Loading } from 'shared/elements/Loading'
import FamilyHistoryCreateForm from './FamilyHistoryCreateForm'
// import { apiFamilyPsychiatric } from 'services/Patient/apiFamilyPsychiatric'



const FamilyPsychiatricFormNew = (props) => {
    
    const closeModal = (value) => {
        if(value){
            props.onClose()
        }
    }
    const onSubmitting = (value) =>{
        if(value){
            props.onOke()
        }
    }
    

    const modalTitle = props.familyMedicalId
        ? 'Update Family History'
        : 'Add Family  History'
    return (
        <Modal
            width={1200}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="family-medical-history-form">
            <Grid container>
                <FamilyHistoryCreateForm familyMedicalId={props.familyMedicalId} patientId={props.patientId} forModalOpen={true} closeModal={closeModal} onSubmitting={onSubmitting} encounter_id={props.encounter_id}/>
            </Grid>
        </Modal>
    )
}

export { FamilyPsychiatricFormNew }
export default FamilyPsychiatricFormNew
