import { Grid,  } from '@mui/material'
import React, {  useEffect, useState } from 'react'
import { Tab, Tabs, withStyles } from '@material-ui/core'
import Background from "./Background"
import { useLocation} from 'react-router-dom'
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid #5571C6`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)


export const Branding = () => {
    const location = useLocation()
    const [value, setValue] = useState(0)
    
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    useEffect(() => {
        if (location?.state?.tab) {
            setValue(location.state.tab)
        }
    }, [])
    return (
        <Grid container >
            <Grid container>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Backgrounds"
                    />
                    {/* <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Logo"
                    /> */}
                </Tabs>
                <Grid
                    container
                    style={{
                        marginTop: "7px",
                        display: 'inline-flex',
                        alignItems: 'flex-end',
                        borderTop: '1px solid #E5E9E8 ',
                    }}></Grid>
            </Grid> 
            {value === 0 && <Background/> }
        </Grid>
    )
}
