export const patientProfileSecQues = [
    {
        name: 'What is title of your favorite book?',
        value: 'What is title of your favorite book?',
    },
    {
        name:
            'What is the name of the street you grew up on?',
        value:
            'What is the name of the street you grew up on?',
    },
    {
        name: 'What is your mother’s maiden name?',
        value: 'What is your mother’s maiden name?',
    },
    {
        name: 'What was the name of your first pet?',
        value: 'What was the name of your first pet?',
    },
    {
        name:
            'What was the name of the first company that you worked for?',
        value:
            'What was the name of the first company that you worked for?',
    },
    {
        name: 'Where did you meet your spouse?',
        value: 'Where did you meet your spouse?',
    },
    {
        name: 'What was the name of your high school?',
        value: 'What was the name of your high school?',
    },
    {
        name: 'What city were you born in?',
        value: 'What city were you born in?',
    },
    {
        name: 'What was the color of your first car?',
        value: 'What was the color of your first car?',
    },
];
