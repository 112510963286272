import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { SocialHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { OutpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import SocialForm from './SocialForm'
import { apiSocialHistory } from 'services/Patient/apiSocialHistory'
import { useNotification } from 'shared/elements/Notification'

const SocialHistoryView = ({ isInClinicalNote, patient_Id, encounter_id, afterActionOk, nonEditable=false }) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const { id } = useParams<{ id: string }>()

    // State
    const [socialHistoryId, setSocialHistoryId] = useState<string | false | number>(false)
    // const [page, setPage] = useState(1)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState('')
    const [socialHistory, setSocialHistory] = useState<OutpatientPsychiatric[]>([])
    const [loading, setLoading] = useState(false)

    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [sortModel, setSortModel] = useState<GridSortModel>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const getSocialHistoryList = async () => {
        setLoading(true)
        try {
            const resp = await apiSocialHistory.getAll(patientId, {
                page,
                size: limit,
            })
            if (resp.data?.data) {
                const dataSocial = resp.data.data
                const formatData = dataSocial.map((social) => ({
                    ...social,
                    longest_period_of_unemployment: {
                        start: social.start_unemployment || undefined,
                        end: social.end_unemployment || undefined,
                    },
                }))
                setSocialHistory(formatData)
                setTotal(resp.data.paging.total)
                // if (!resp.data.data.length && resp.data.paging.total > 0) {
                //     setPage(page - 1)
                // }
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }
    const handleChangePage = (page) => {
        setPage(page)
    }

    const hanldeDelete = async (socialId) => {
        if (patientId) {
            try {
                const res = await apiSocialHistory.delete(patientId, socialId)
                if (res.data?.Status === 'SUCCESSED') {
                    notification('Social History deleted successfully', 'success')
                    await getSocialHistoryList()
                    afterActionOk?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            }
        }
    }

    const handleEdit = (id) => {
        setSocialHistoryId(id)
        setIsAddingNew(true)
    }

    useEffect(() => {
        if (patientId) {
            getSocialHistoryList()
        }
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: isInClinicalNote ? 0 : '16px 24pxs' }}>
            <Loading visible={loading} />
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent={'space-between'}>
                    <Typography className={classes.header}>Social History</Typography>

                    <ButtonCustom
                        tooltip="Add New Social History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setIsAddingNew(true)}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <SocialHistoryDashboard
                    page={page}
                    onPageChange={handleChangePage}
                    hanldeDelete={hanldeDelete}
                    handleEdit={handleEdit}
                    dataSource={socialHistory}
                    pagination={{ start: page, total, limit }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    nonEditable={nonEditable}
                />
                {isAddingNew && (
                    <SocialForm
                        socialId={socialHistoryId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        open={isAddingNew}
                        onOke={() => {
                            setIsAddingNew(false)
                            setSocialHistoryId(false)
                            getSocialHistoryList()
                            afterActionOk?.()
                        }}
                        onClose={() => {
                            setSocialHistoryId(false)
                            setIsAddingNew(false)
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(SocialHistoryView)
