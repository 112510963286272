import {
    postSearchService,
    getService,
    postService,
    putService,
    deleteService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'

//Documents
export function getDocumentList({
    page = 1,
    limit = 10,
    search = '',
    document_type = '',
    associated_patient = '',
    encounter_id = '',
    upload_by = '',
    source_type = '',
}) {
    let url = `${process.env.REACT_APP_SONORA_V2_API_URL}/document?page=${page}&size=${limit}`

    if (search) url += `&search=${search}`
    if (document_type)
        url += `&document_type=${document_type}`
    if (associated_patient)
        url += `&associated_patient=${associated_patient}`
    if (encounter_id) url += `&encounter_id=${encounter_id}`
    if (upload_by) url += `&upload_by=${upload_by}`
    if (source_type) url += `&source_type=${source_type}`

    return getService(url)
}

export function getDocumentById(id) {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/document/${id}`,
    )
}

export function createDocument(payload) {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/document`,
        payload,
    )
}

export function uploadFileToS3(payload) {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/document/attachment-url`,
        payload,
    )
}

export function deleteDocumentById(id) {
    return deleteService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patdocument/${id}`,
    )
}
