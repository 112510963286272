import { lazy } from 'react'
import Dashboard from '../containers/Dashboard'
import { authRequired } from '../utilities/guards'

//Patient Survey

//profiles

//Forms

//LocationSettings
import ClinicalPractices from 'containers/ClinicalPractices'
import Specialties from 'containers/Specialties'
import AppointmentStatusSettings from '../containers/AppointmentStatusSettings'
import LocationSettings from '../containers/LocationSettings/index'
import PatientsSetting from '../containers/PatientsSetting/index'
import VisitTypesSettings from '../containers/VisitTypesSettings'
import LocationCreateDialog from 'containers/LocationSettings/LocationCreateDialog'
//Settings

import { Redirect } from 'react-router-dom'
import TM2UAdminProfile from '../components/Profiles/TM2UAdmin/TM2UAdminProfile'
import Routes from '../constants/routes'
import { getAllAdmin, getAllUserRoles, UserRoles } from '../constants/user-roles'

import ProfileContainer from '../containers/Profile/ProfileContainer'
import userRoutes from './users-routes'

// Consultation Tracking

//Consultation
import accessClenderScheduler from '../containers/CalendarScheduler/accessCalenderScheduler'
import ERX from '../containers/ERX'
import ProviderPatientList from '../containers/ProviderPatientList'

//Clinical Notes
import ClinicalNotes from '../containers/ClinicalNotes'
import ClinicalNotesList from '../containers/ClinicalNotes/ClinicalNotesListWrapper'

import UserSecurityDialog from 'containers/UserSecuritySettings/UserSecuritySettingsDialog'
import EditPermissionSettings from '../containers/EditPermissionSettings'
import MediaKeyword from '../containers/MediaKeyword'
import ResourceManagement from '../containers/ResourceManagement'
import RolesManagement from '../containers/RolesManagement'
import SecurityRolePermissions from '../containers/RolesManagement/SecurityRolePermissions'
import UserSecuritySettings from '../containers/UserSecuritySettings'

/* Patient Components */
import { PatientForm } from 'containers/PatientsSetting/PatientForm'
import { PatientDashboard } from 'containers/PatientsSetting/view/PatientDashboard'

import Departments from 'containers/Departments'
import Allergies from 'containers/PatientsSetting/view/Allergies'
import Appointments from 'containers/PatientsSetting/view/Appointments'
import PatientDocuments from 'containers/PatientsSetting/view/Documents'
import Immunizations from 'containers/PatientsSetting/view/Immunizations'
import Insurance from 'containers/PatientsSetting/view/Insurances/'
import InsuranceNewForm from 'containers/PatientsSetting/view/Insurances/InsuranceNewForm'
import MedicalProblems from 'containers/PatientsSetting/view/MedicalProblems'
import MedicationPatient from 'containers/PatientsSetting/view/MedicationPatient'

import Macros from 'containers/Macros'

/* Fax Components */
import EFax from 'containers/EFax'
import { ArchivedFax } from 'containers/EFax/components/ArchivedFax'
import { FaxDetailDialog } from 'containers/EFax/components/FaxDetailDialog'

import { EmpyConsultation } from 'containers/Consultation/EmptyNote/EmpyConsultation'
import Documents from 'containers/Documents'
import FaxDialog from 'containers/EFax/components/FaxDialog'
import MessageERX from 'containers/ERX/MessageERX'
import PrecriptionERX from 'containers/ERX/PrecriptionERX'
import HealthPlans from 'containers/HealthPlans'
import { LabOrders } from 'containers/LabOrders'
import { LabOrdersPatient } from 'containers/PatientsSetting/view/LabOrdersPatient'
import MenstruationAndPregnancyHistory from 'containers/PatientsSetting/view/MenstruationAndPregnancyHistory'
import PatientsContactsView from 'containers/PatientsSetting/view/PatientsContactsView'
import PsychotherapyNote from 'containers/PatientsSetting/view/PsychotherapyNote'
import SocialHistoryView from 'containers/PatientsSetting/view/SocialHistoryView'
import { ReferringProviders } from 'containers/ReferringProviders'
import { FollowUp } from 'containers/WorkQueues/FollowUp'
import ChartPrepFrom from 'containers/WorkQueues/FollowUp/ChartPrep/ChartPrepFrom'
import RecallForm from 'containers/WorkQueues/FollowUp/Recall/RecallForm'
import { Referrals } from 'containers/WorkQueues/Referrals'
import { ReferralDashboard } from 'containers/WorkQueues/Referrals/ReferralDashboard'
import { ReferralsForm } from 'containers/WorkQueues/Referrals/View/ReferralsForm'
import WaitlistFormNew from 'containers/WorkQueues/Waitlist/Form/WaitlistFormNew'
import { withAuth } from 'hoc/withAuth'
import PreviousNote from 'containers/ClinicalNotes/Behavioral/PreviousNote'
import { Branding } from 'containers/Branding'
import { BackgroundForm } from 'containers/Branding/Background/BackgroundForm'
import FamilyHistoryCreateForm from 'containers/PatientsSetting/view/FamilyPsychiatricHistory/FamilyHistoryCreateForm'
import PatientOutcomes from 'containers/PatientOutcomes'
import createPatientOutcomes from 'containers/PatientOutcomes/createPatientOutcomes'
import { ReferringCheckList } from 'containers/ReferringCheckList' 
import {ReferringCheckListNew} from 'containers/ReferringCheckList/New/ReferringCheckListNew'
import SystemNotification from 'containers/SystemNotification'
import createSystemNotification from 'containers/SystemNotification/createSystemNotification'
import BehaviorRateScaleList from 'containers/PatientsSetting/view/BehaviorRateScaleList'
import GadDetails from 'containers/PatientsSetting/view/BehaviorRateScaleList/GadDetails'
import PhqDetails from 'containers/PatientsSetting/view/BehaviorRateScaleList/PhqDetails'

const SubstanceAbuseHistory = lazy(() =>
    import('containers/PatientsSetting/view/SubstanceAbuseHistory'),
)
const ClinicalNotesHealthRecord = lazy(() =>
    import('containers/PatientsSetting/view/ClinicalNotesHealthRecord'),
)
const VitalSignsView = lazy(() => import('containers/PatientsSetting/view/VitalSignsView'))
const SubstanceAbuseTreatmentHistory = lazy(() =>
    import('containers/PatientsSetting/view/SubstanceAbuseTreatmentHistory'),
)
const InpationPsychiatricHistory = lazy(() =>
    import('containers/PatientsSetting/view/InpationPsychiatricHistory'),
)
const OutpationPsychiatricHistory = lazy(() =>
    import('containers/PatientsSetting/view/OutpationPsychiatricHistory'),
)
const SurgicalHistory = lazy(() => import('containers/PatientsSetting/view/SurgicalHistory'))
const FamilyPsychiatricHistory = lazy(() =>
    import('containers/PatientsSetting/view/FamilyPsychiatricHistory'),
)
const CareTeam = lazy(() => import('containers/PatientsSetting/view/CareTeam'))
const PsychiatricMedicationHistory = lazy(() =>
    import('containers/PatientsSetting/view/PsychiatricMedicationHistory'),
)
const SuperBillForm = lazy(() => import('containers/WorkQueues/Superbills/SuperbillForm'))
const Superbills = lazy(() => import('containers/WorkQueues/Superbills'))

const CustomProcCodes = lazy(() => import('containers/CustomProcCodes'))

const ProviderDirectory = lazy(() => import('containers/ProviderDirectory'))
const ProviderDirectoryInfo = lazy(() => import('containers/ProviderDirectory/Info'))

const meta = {
    auth: true,
    guards: [authRequired],
}

const Comingsoon = () => {
    return (
        <h3
            style={{
                padding: '40px',
                textAlign: 'center',
            }}>
            Coming soon !
        </h3>
    )
}
const authRoutes = [
    {
        path: [Routes.HOME, Routes.DASHBOARD.BI_STUDIO],
        roles: getAllUserRoles(),
        component: Dashboard,
        meta,
    },
    {
        path: [Routes.DASHBOARD.REPORT_REPOSITORY],
        roles: getAllUserRoles(),
        component: Comingsoon,
        meta,
    },
    {
        path: Routes.PATIENT.MAIN,
        roles: getAllUserRoles(),
        component: PatientsSetting,
        meta,
    },
    {
        path: Routes.PATIENT.CREATE,
        roles: getAllUserRoles(),
        component: PatientForm,
        meta,
    },
    {
        path: Routes.PATIENT.EDIT,
        roles: getAllUserRoles(),
        component: PatientForm,
        meta,
    },
    {
        path: Routes.PATIENT.DASHBOARD,
        roles: getAllUserRoles(),
        component: PatientDashboard,
        meta,
    },
    {
        path: Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD,
        roles: getAllUserRoles(),
        component: PatientDashboard,
        meta,
    },
    {
        path: Routes.PATIENT.DASHBOARD_PATIENT_ACCOUNT,
        roles: getAllUserRoles(),
        component: PatientDashboard,
        meta,
    },
    {
        path: Routes.PATIENT.DASHBOARD_HISTORY,
        roles: getAllUserRoles(),
        component: PatientDashboard,
        meta,
    },
    {
        path: Routes.PATIENT.DASHBOARD_ORDER,
        roles: getAllUserRoles(),
        component: PatientDashboard,
        meta,
    },
    {
        path: Routes.PATIENT.MEDICAL_PROBLEMS,
        roles: getAllUserRoles(),
        component: MedicalProblems,
        meta,
    },
    {
        path: Routes.PATIENT.BEHAVIOR_RATE_SCALE,
        roles: getAllUserRoles(),
        component: BehaviorRateScaleList,
        meta,
    },
    {
        path: Routes.PATIENT.GAD_7_DETAILS,
        roles: getAllUserRoles(),
        component: GadDetails,
        meta,
    },
    {
        path: Routes.PATIENT.PHQ_9_DETAILS,
        roles: getAllUserRoles(),
        component: PhqDetails,
        meta,
    },
    {
        path: Routes.PATIENT.ALLERGIES,
        roles: getAllUserRoles(),
        component: Allergies,
        meta,
    },
    {
        path: Routes.PATIENT.APPOINTMENTS,
        roles: getAllUserRoles(),
        component: Appointments,
        meta,
    },
    {
        path: Routes.PATIENT.MEDICATIONS,
        roles: getAllUserRoles(),
        component: MedicationPatient,
        meta,
    },
    {
        path: Routes.PATIENT.CLINICAL_NOTES,
        roles: getAllUserRoles(),
        component: ClinicalNotesHealthRecord,
        meta,
    },
    {
        path: Routes.PATIENT.VITAL_SIGNS,
        roles: getAllUserRoles(),
        component: VitalSignsView,
        meta,
    },
    {
        path: Routes.PATIENT.PATIENT_CONTACT,
        roles: getAllUserRoles(),
        component: PatientsContactsView,
        meta,
    },
    {
        path: Routes.PATIENT.LAB_ORDERS,
        roles: getAllUserRoles(),
        component: LabOrdersPatient,
        meta,
    },
    {
        path: Routes.PATIENT.IMMUNIZATIONS,
        roles: getAllUserRoles(),
        component: Immunizations,
        meta,
    },
    {
        path: Routes.PATIENT.INSURANCES.MAIN,
        roles: getAllUserRoles(),
        component: Insurance,
        meta,
    },
    {
        path: Routes.PATIENT.INSURANCES.CREATE,
        roles: getAllUserRoles(),
        component: InsuranceNewForm,
        meta,
    },
    {
        path: Routes.PATIENT.INSURANCES.EDIT,
        roles: getAllUserRoles(),
        component: InsuranceNewForm,
        meta,
    },
    {
        path: Routes.PATIENT.DOCUMENTS,
        roles: getAllUserRoles(),
        component: PatientDocuments,
        meta,
    },
    {
        path: Routes.PATIENT.SUBSTANCE_ABUSE_HISTORY,
        roles: getAllUserRoles(),
        component: SubstanceAbuseHistory,
        meta,
    },
    {
        path: Routes.PATIENT.SUBSTANCE_ABUSE_TREATMENT_HISTORY,
        roles: getAllUserRoles(),
        component: SubstanceAbuseTreatmentHistory,
        meta,
    },
    {
        path: Routes.PATIENT.INPATIENT_PSYCHIATRIC_HISTORY,
        roles: getAllUserRoles(),
        component: InpationPsychiatricHistory,
        meta,
    },
    {
        path: Routes.PATIENT.OUTPATIENT_PSYCHIATRIC_HISTORY,
        roles: getAllUserRoles(),
        component: OutpationPsychiatricHistory,
        meta,
    },
    {
        path: Routes.PATIENT.SOCIAL_HISTORY,
        roles: getAllUserRoles(),
        component: SocialHistoryView,
        meta,
    },
    {
        path: Routes.PATIENT.MENSTRUATION_AND_PRAGNANCY_HISTORY,
        roles: getAllUserRoles(),
        component: MenstruationAndPregnancyHistory,
        meta,
    },
    {
        path: Routes.PATIENT.SURGICAL_HISTORY,
        roles: getAllUserRoles(),
        component: SurgicalHistory,
        meta,
    },
    {
        path: Routes.PATIENT.FAMILY_PSYCHIATRIC_HISTORY,
        roles: getAllUserRoles(),
        component: FamilyPsychiatricHistory,
        meta,
    },
    {
        path: Routes.PATIENT.FAMILY_HISTORY_CREATE,
        roles: getAllUserRoles(),
        component: FamilyHistoryCreateForm,
        meta,
    },
    {
        path: Routes.PATIENT.FAMILY_HISTORY_UPDATE,
        roles: getAllUserRoles(),
        component: FamilyHistoryCreateForm,
        meta,
    },
    {
        path: Routes.PATIENT.CARE_TEAM,
        roles: getAllUserRoles(),
        component: CareTeam,
        meta,
    },
    {
        path: Routes.PATIENT.PSYCHIATRIC_MEDICAL_HISTORY,
        roles: getAllUserRoles(),
        component: PsychiatricMedicationHistory,
        meta,
    },
    // {
    //     path: Routes.SETTINGS.MAIN,
    //     roles: getAllUserRoles(),
    //     component: LocationSettings,
    //     meta,
    // },
    {
        path: Routes.SETTINGS.CLINICAL_PRACTICES,
        roles: getAllUserRoles(),
        component: ClinicalPractices,
        meta,
    },
    {
        path: Routes.SETTINGS.HEALTH_PLAN,
        roles: getAllUserRoles(),
        component: HealthPlans,
        meta,
    },
    {
        path: Routes.SETTINGS.LOCATIONS,
        roles: getAllUserRoles(),
        component: LocationSettings,
        meta,
    },
    {
        path: Routes.SETTINGS.LOCATIONS_NEW,
        roles: getAllUserRoles(),
        component: LocationCreateDialog,
        meta,
    },
    {
        path: Routes.SETTINGS.LOCATIONS_EDIT,
        roles: getAllUserRoles(),
        component: LocationCreateDialog,
        meta,
    },
    {
        path: Routes.SETTINGS.VISIT_TYPES,
        roles: getAllUserRoles(),
        component: VisitTypesSettings,
        meta,
    },
    // {
    //     path: Routes.SETTINGS.APPOINTMENT_STATUS,
    //     roles: getAllUserRoles(),
    //     component: AppointmentStatusSettings,
    //     meta,
    // },
    {
        path: Routes.SETTINGS.STATUS_CODES,
        roles: getAllUserRoles(),
        component: AppointmentStatusSettings,
        meta,
    },
    {
        path: Routes.SETTINGS.CUSTOM_PROC_CODES,
        roles: getAllUserRoles(),
        component: CustomProcCodes,
        meta,
    },
    {
        path: Routes.SETTINGS.USER_SECURITY,
        roles: getAllUserRoles(),
        component: UserSecuritySettings,
        meta,
    },
    {
        path: Routes.SETTINGS.USER_SECURITY_NEW,
        roles: getAllUserRoles(),
        component: UserSecurityDialog,
        meta,
    },
    {
        path: Routes.SETTINGS.USER_SECURITY_EDIT,
        roles: getAllUserRoles(),
        component: UserSecurityDialog,
        meta,
    },
    {
        path: Routes.SETTINGS.DEPARTMENTS,
        roles: getAllUserRoles(),
        component: Departments,
        meta,
    },
    {
        path: Routes.SETTINGS.ROLES_MANAGEMENT,
        roles: getAllUserRoles(),
        component: RolesManagement,
        meta,
    },
    {
        path: Routes.SETTINGS.SECURITY_ROLE_PERMISSIONS,
        roles: getAllUserRoles(),
        component: SecurityRolePermissions,
        meta,
    },
    {
        path: Routes.SETTINGS.EDIT_PERMISSIONS,
        roles: getAllUserRoles(),
        component: EditPermissionSettings,
        meta,
    },
    {
        path: Routes.SETTINGS.MEDIA_KEYWORD,
        roles: getAllUserRoles(),
        component: MediaKeyword,
        meta,
    },
    {
        path: Routes.SETTINGS.SPECIALTIES,
        roles: getAllUserRoles(),
        component: Specialties,
        meta,
    },
    {
        path: Routes.SETTINGS.MACRO,
        roles: getAllUserRoles(),
        component: Macros,
        meta,
    },
    {
        path: Routes.SETTINGS.RESOURCE_MANAGEMENT,
        roles: getAllUserRoles(),
        component: ResourceManagement,
        meta,
    },
    {
        path: Routes.SETTINGS.REFERRING_PROVIDER,
        roles: getAllUserRoles(),
        component: ReferringProviders,
        meta,
    },
    {
        path: Routes.SETTINGS.BRANDING,
        roles: getAllUserRoles(),
        component: Branding,
        meta,
        hoc: withAuth.branding,
    },
    {
        path: Routes.SETTINGS.BRANDING + Routes.SETTINGS.BACKGROUND_NEW,
        roles: getAllUserRoles(),
        component: BackgroundForm,
        meta,
        hoc: withAuth.branding,
    },
    {
        path: Routes.SETTINGS.PATIENT_OUTCOMES,
        roles: getAllUserRoles(),
        component: PatientOutcomes,
        meta,
    },
    {
        path: Routes.SETTINGS.PATIENT_OUTCOMES_CREATE,
        roles: getAllUserRoles(),
        component: createPatientOutcomes,
        meta,
    },
    {
        path: Routes.SETTINGS.SYSTEM_ANNOUNCEMENT,
        roles: getAllUserRoles(),
        component: SystemNotification,
        meta,
        hoc: withAuth.branding,
    },
    {
        path: Routes.SETTINGS.SYSTEM_ANNOUNCEMENT_NEW,
        roles: getAllUserRoles(),
        component: createSystemNotification,
        meta,
        hoc: withAuth.branding,
    },
    {
        path: Routes.SETTINGS.SYSTEM_ANNOUNCEMENT_EDIT,
        roles: getAllUserRoles(),
        component: createSystemNotification,
        meta,
        hoc: withAuth.branding,
    },
    {
        path: Routes.SETTINGS.REFERRAL_CHECKLIST,
        roles: getAllUserRoles(),
        component: ReferringCheckList,
        meta,
    },
    {
        path: Routes.SETTINGS.REFERRAL_CHECKLIST_NEW,
        roles: getAllUserRoles(),
        component: ReferringCheckListNew,
        meta,
    },
    {
        path: Routes.SETTINGS.REFERRAL_CHECKLIST_EDIT,
        roles: getAllUserRoles(),
        component: ReferringCheckListNew,
        meta,
    },
    {
        path: Routes.PATIENT,
        roles: [UserRoles.PROVIDER],
        component: ProviderPatientList,
        meta,
    },
    {
        path: Routes.DOCUMENTS,
        roles: getAllUserRoles(),
        component: Documents,
        meta,
        // hoc: withAuth.document,
    },
    {
        path: Routes.PATIENT.PSYCHOTHERAPY,
        roles: getAllUserRoles(),
        component: PsychotherapyNote,
        meta,
    },
    {
        path: Routes.PROFILE,
        roles: getAllUserRoles(),
        component: ProfileContainer,
        meta,
    },
    {
        path: Routes.SCHEDULE,
        roles: getAllUserRoles(),
        component: accessClenderScheduler,
        meta,
    },
    {
        path: Routes.ERX,
        roles: getAllUserRoles(),
        component: ERX,
        meta,
        hoc: withAuth.erx,
    },
    {
        path: Routes.ERX_MESSAGE,
        roles: getAllUserRoles(),
        component: MessageERX,
        meta,
        hoc: withAuth.erx,
    },
    {
        path: Routes.ERX_PRESCRIPTION,
        roles: getAllUserRoles(),
        component: PrecriptionERX,
        meta,
        hoc: withAuth.erx,
    },
    {
        path: Routes.LABS_ORDER.MAIN,
        roles: getAllUserRoles(),
        component: LabOrders,
        meta,
        hoc: withAuth.lab_order,
    },
    {
        path: Routes.EFax.MAIN,
        roles: getAllUserRoles(),
        component: () => <Redirect to={Routes.EFax.INCOMING} />,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.EFax.INCOMING,
        roles: getAllUserRoles(),
        component: EFax,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.EFax.OUTGOING,
        roles: getAllUserRoles(),
        component: EFax,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.EFax.DETAILS,
        roles: getAllUserRoles(),
        component: FaxDetailDialog,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.EFax.ARCHIVED,
        roles: getAllUserRoles(),
        component: ArchivedFax,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.EFax.NEW_FAX,
        roles: getAllUserRoles(),
        component: FaxDialog,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.PROVIDER_DIRECTORY.MAIN,
        roles: getAllUserRoles(),
        component: ProviderDirectory,
        meta,
        hoc: withAuth.provider_directory,
    },
    {
        path: Routes.PROVIDER_DIRECTORY.INFO,
        roles: getAllUserRoles(),
        component: ProviderDirectoryInfo,
        meta,
        hoc: withAuth.provider_directory,
    },
    {
        path: Routes.EFax.FORWARD,
        roles: getAllUserRoles(),
        component: FaxDialog,
        meta,
        hoc: withAuth.efax,
    },
    {
        path: Routes.TM2UAdmin,
        roles: getAllUserRoles(),
        component: TM2UAdminProfile,
        meta,
    },
    ...userRoutes,
    {
        path: Routes.CLINICAL_NOTES.LISTING,
        roles: getAllUserRoles(),
        component: ClinicalNotesList,
        meta,
        hoc: withAuth.clinical_note,
    },
    {
        path: `${Routes.CLINICAL_NOTES.NEW_NOTE}`,
        roles: getAllUserRoles(),
        component: ClinicalNotes,
        meta,
        hoc: withAuth.clinical_note,
    },
    {
        path: Routes.CLINICAL_NOTES.VIEW_PREVIOUS_NOTE,
        roles: getAllUserRoles(),
        component: PreviousNote,
        meta,
        hoc: withAuth.clinical_note_preview,
    },
    {
        path: Routes.CLINICAL_NOTES.VIEW_NOTE,
        roles: getAllUserRoles(),
        component: ClinicalNotes,
        meta,
        hoc: withAuth.clinical_note_preview,
    },
    // Superbills
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.SUPERBILLS.MAIN,
        roles: getAllAdmin(),
        component: Superbills,
        meta,
        hoc: withAuth.superbill,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.SUPERBILLS.CREATE,
        roles: getAllAdmin(),
        component: SuperBillForm,
        meta,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.SUPERBILLS.EDIT,
        roles: getAllAdmin(),
        component: SuperBillForm,
        meta,
        hoc: withAuth.superbill,
    },
    // Referrals
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN,
        roles: getAllUserRoles(),
        component: Referrals,
        meta,
        hoc: withAuth.referrals,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.CREATE,
        roles: getAllUserRoles(),
        component: ReferralsForm,
        meta,
        hoc: withAuth.referralsCreate,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION,
        roles: getAllUserRoles(),
        component: ReferralDashboard,
        meta,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.PATIENT_ACCOUNT,
        roles: getAllUserRoles(),
        component: ReferralDashboard,
        meta,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.EDIT,
        roles: getAllUserRoles(),
        component: ReferralsForm,
        meta,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN,
        roles: getAllUserRoles(),
        component: FollowUp,
        meta,
    },
    {
        path:
            Routes.WORK_QUEUES.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.RECALL.CREATE,
        roles: getAllUserRoles(),
        component: RecallForm,
        meta,
    },
    {
        path:
            Routes.WORK_QUEUES.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.RECALL.EDIT,
        roles: getAllUserRoles(),
        component: RecallForm,
        meta,
    },

    {
        path:
            Routes.WORK_QUEUES.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.CHART_PREP.EDIT,
        roles: getAllUserRoles(),
        component: ChartPrepFrom,
        meta,
    },
    {
        path:
            Routes.WORK_QUEUES.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.MAIN +
            Routes.WORK_QUEUES.FOLLOW_UP.CHART_PREP.ADD,
        roles: getAllUserRoles(),
        component: ChartPrepFrom,
        meta,
    },
    // {
    //     path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.WAITLIST.MAIN,
    //     roles: getAllUserRoles(),
    //     component: Waitlist,
    //     meta,
    // },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.WAITLIST.CREATE,
        roles: getAllUserRoles(),
        component: WaitlistFormNew,
        meta,
    },
    {
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.WAITLIST.EDIT,
        roles: getAllUserRoles(),
        component: WaitlistFormNew,
        meta,
    },
    {
        path: Routes.TELE_HEALTH.CONSULTATION,
        roles: [UserRoles.PROVIDER, UserRoles.PATIENT, UserRoles.MID_PROVIDER],
        // component: EmptyNote,
        component: EmpyConsultation,
        meta,
    },
    {
        path: '*',
        roles: getAllUserRoles(),
        // eslint-disable-next-line react/display-name
        component: () => (
            // eslint-disable-next-line react/react-in-jsx-scope
            <Redirect to={Routes.DASHBOARD.BI_STUDIO}></Redirect>
        ),
        meta,
    },
]

export default authRoutes
