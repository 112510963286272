
import {
    deleteService, getService,
    postService, putService
} from 'utilities/request/restClient'


export const apiInsurrancePolicy = {
    create(patienrId, params) {
        return postService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/insurance-policy`,
            params,
        )
    },
    getAll(patienrId, payload) {
        const queryString = new URLSearchParams({
            ...payload,
        }).toString()
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/insurance-policy?${queryString}`,
        )
    },
    getId(patientId, insurranceId) {
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/insurance-policy/${insurranceId}`,
        )
    },
    update(patientId: string | number, insurranceId: string | number, payload) {
        return putService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/insurance-policy/${insurranceId}`,
            payload,
        )
    },
    delete(patientId: string | number, insurranceId: string | number) {
        return deleteService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/insurance-policy/${insurranceId}`
        )
    },
}