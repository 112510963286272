import { FormControl, InputLabel, MenuItem, OutlinedInput, Typography } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import React from 'react'
import { useStyles } from './styles'
import Select from '@mui/material/Select'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { ButtonIconEl } from 'shared/elements'

function SelectEl(props) {
    const classes = useStyles(props)
    return (
        <FormControl disabled={props.disabled} fullWidth variant="outlined">
            {props.label && <InputLabel>{props.label}</InputLabel>}
            <Select
                fullWidth
                displayEmpty
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                input={<OutlinedInput margin="dense" classes={classes} />}
                classes={{
                    root: classes.root,
                    icon: classes.icon,
                }}
                style={{
                    height: props.height,
                    padding: 0,
                    backgroundColor: '#FFF',
                    ...props.style,
                }}
                IconComponent={KeyboardArrowDown}
                {...props}>
                {props.options.map((option, index) => (
                    <MenuItem
                        style={{ maxWidth: '300px' }}
                        key={option?.id || option.key}
                        value={option.code}>
                        <Typography variant="body2" noWrap className={classes.text}>
                            {option.display}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
            {props.clearAble && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        right: 30,
                        transform: 'translateY(-50%)',
                    }}
                    onClick={() => {
                        props?.onChange()
                    }}>
                    <ButtonIconEl size="small">
                        <HighlightOffIcon
                            style={{
                                fontSize: 18,
                                color: '#9CA9B8',
                            }}
                        />
                    </ButtonIconEl>
                </div>
            )}
        </FormControl>
    )
}

SelectEl.defaultProps = {
    border: '1px solid $secondaryGray',
    height: 40,
}

export default SelectEl
