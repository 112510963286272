import { Box, Grid, IconButton, Typography, makeStyles } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Formik } from 'formik'

import { UserRoles } from '../../../constants/user-roles'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import { initialState, reducer } from '../../CalendarScheduler/reducer'
import * as actionTypes from '../../CalendarScheduler/actionTypes'
import moment from 'moment'
import { VIEW } from '../../CalendarScheduler/constant'
import HPI from 'components/ClinicalNotes/medical/HPI'
import Plan from 'components/ClinicalNotes/medical/Plan'
import AssessmentDiagnosis from 'components/ClinicalNotes/medical/AssessmentDiagnosis'
import PatientAllergiesMedical from 'components/ClinicalNotes/medical/PatientAllergiesMedical'
import SocialHistory from 'components/ClinicalNotes/medical/SocialHistory'
import { useSelector } from 'react-redux'
import PastMedicalHistory from 'components/ClinicalNotes/medical/PastMedicalHistory'
import MedicationPatient from 'components/ClinicalNotes/medical/Medication/MedicationPatient'

import MedCodes from 'components/ClinicalNotes/medical/MedCodesV2'
import FamilyHistory from 'components/ClinicalNotes/medical/FamilyHistory'
import PhysicalExam from 'components/ClinicalNotes/medical/physicalExam'
import ReviewOfSystems from 'components/ClinicalNotes/medical/ReviewOfSystems/index'
import NewAssessmentDiagnosis from 'components/ClinicalNotes/medical/NewAssessmentDiagnosis'
import NewPlan from 'components/ClinicalNotes/medical/NewPlan'

const useStyles = makeStyles(() => ({
    icon_back: {
        marginRight: 8,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    uneditable_mark: {
        top: 46,
        left: 0,
        background: '#ffffff80',
        position: 'absolute',
        width: '100%',
        height: `calc(100% - 46px)`,
        zIndex: 10,
    },
}))

const MedicalNotesView = ({
    EqStatus,
    isConsultant,
    noteDetails,
    values,
    status,
    activeNote,
    onChange,
    errors,
    fetchClinicalNote,
    initialValues,
    handlePreview,
    getICDCodes,
    submitFormRef,
    role,
    appointmentId,
    psycho_note,
    previous_cn,
    handleSetViewHealthyRecord,
    clinicalNoteData,
    handleSignAndLock,
    appointmentDetails,
    handleGotoSession
}) => {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()
    const formikInstance = useRef()
    const copyDate = noteDetails?.previous_cn_service_date ? noteDetails?.previous_cn_service_date : ''

    const nonEditable =
        [
            'pending',
            'locked',
            'approved_lock',
            'Pending Approval',
            'Approved & Locked',
            'LOCKED',
        ].includes(status) ||
        noteDetails?.name === 'view' ||
        (status === 'resent_to_edit' && role === UserRoles.PROVIDER)
            ? true
            : false
    const lastNarratives = previous_cn?.clinicalNoteModel || {}
    const lastUpdateDate = noteDetails?.previous_cn_sign_lock_date
        ? moment(noteDetails?.previous_cn_sign_lock_date).format('MM/DD/YYYY')
        : ''
    const leftSidebarSatus = useSelector((state) => state.sidebar.visible)

    const defaultIcd10ValuesForMedCodes = values?.med_codes?.map(
        (serviceLine) => serviceLine?.icd10_code,
    )

    const { state, checkDirtySessions } = React.useContext(ClinicalNoteContext)
    const [state1, dispatch] = React.useReducer(reducer, initialState)
    function onSelectMonth(date) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date: moment(date),
            view: VIEW.MONTH,
        })
    }
    function onNavigate(date, view = state1.view.selected) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date,
            view,
        })
    }

    useEffect(() => {
        submitFormRef.current = () => {
            if (!activeNote.componentId || !formikInstance.current) {
                return
            }

            checkDirtySessions({
                key: activeNote.componentId,
                value: !_.isEqual(
                    formikInstance.current.values[activeNote.componentId],
                    initialValues[activeNote.componentId],
                ),
            })

            onChange(activeNote.componentId, formikInstance.current.values[activeNote.componentId])
        }
    }, [activeNote.componentId])

    const wrapNote = (Section) => (props) => {
        const Component = Section
        return (
            <Formik
                innerRef={formikInstance}
                initialValues={{
                    [props.fieldName]: props.values,
                }}>
                {({ values, setFieldValue }) => {
                    return (
                        <Component
                            {...props}
                            onChange={setFieldValue}
                            values={values[props.fieldName]}
                            fieldName={props.fieldName}
                        />
                    )
                }}
            </Formik>
        )
    }

    const scrollableContentRef = useRef(null)
    const handleScroll = () =>{
        const scrollableContent = scrollableContentRef?.current
        const tolerance = 1
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + 2
            const isAtStart = scrollableContent.scrollTop <= tolerance;
            if(isAtStart){
                handleGotoSession("previous")
            }else if(isAtEnd){
                handleGotoSession("next")
            }
    }
    useEffect(()=>{
        scrollableContentRef.current.scrollTop = 10
    },[activeNote])
    const [height, setheight]= useState(window.innerHeight)
    useEffect(() => {
        const handleResize = () => {
            setheight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [])
    //   console.log("height",height)

    return (
        <Box
            // onScroll={handleScroll}
            ref={(ref) => (scrollableContentRef.current = ref)}
            sx={{
                maxHeight: height>= 700 && (activeNote.componentId === 'present_illness_history' || activeNote.componentId === 'med_codes') ? '485px !important' : activeNote.componentId === 'family_history' && height>= 700 ?'470px !important': '900px !important',
                background: '#ffffff',
                padding: '18px 5px 18px 13px',
                display: 'flex',
                flexDirection: 'column',
                // paddingTop: 16,
                flex: 1,
                position: 'relative',
                overflow: 'scroll',
                width: isConsultant && (EqStatus || leftSidebarSatus) ? 'auto' : '92vw',
            }}>
            {!activeNote?.componentId && (
                <Grid container alignItems="center" justifyContent="center" style={{ flex: 1 }}>
                    <Typography
                        variant="h5"
                        style={{
                            textAlign: 'center',
                            color: '#303E4E',
                        }}>
                        Please select a session
                    </Typography>
                </Grid>
            )}
            {activeNote.componentId === 'present_illness_history' &&
                wrapNote(HPI)({
                    values: values?.present_illness_history,
                    fieldName: 'present_illness_history',
                    name: 'present_illness_history',
                    hideTextNarrative: true,
                    hideGenerateBtn: true,
                    last_HPI: psycho_note?.last_HPI,
                    copyAble: noteDetails.templateId === 'therapy_initial_note',
                    copyValues: lastNarratives['present_illness_history'] || '',
                    isShowCopyButton: true,
                    lastNarrative: lastNarratives.present_illness_history?.narrative,
                    nonEditable,
                    lastUpdateDate,
                    // placeholder: 'Enter Telemedicine Attestation',
                    copyDate
                })}

            {activeNote.componentId === 'social_history' &&
                wrapNote(SocialHistory)({
                    values: values?.social_history,
                    fieldName: 'social_history',
                    name: 'social_history',
                    nonEditable,
                    copyValues: lastNarratives['social_history'],
                    copyDate
                })}

            {activeNote.componentId === 'plan' &&
                wrapNote(NewPlan)({
                    lastUpdateDate,
                    previousNoteData: lastNarratives?.plan,
                    macros: state.macros,
                    master: state.master,
                    values: values?.plan,
                    fieldName: 'plan',
                    name: 'plan',
                    patient: clinicalNoteData?.patient,
                    planList: values?.assessment_diagnosis?.plan_list || [],
                    appointmentDetails: appointmentDetails,
                    clinicalNoteData: clinicalNoteData
                })}
            {activeNote.componentId === 'medical_history' &&
                wrapNote(PastMedicalHistory)({
                    values: values?.medical_history,
                    fieldName: 'medical_history',
                    name: 'medical_history',
                    patientId: psycho_note?.patient_id,
                    primary_care_physician: psycho_note?.primary_care_physician,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    macros: state.macros,
                    master: state.master,
                    lastNarrative: lastNarratives.medical_history?.narrative,
                    nonEditable,
                    lastUpdateDate,
                })}
            {activeNote.componentId === 'med_codes' &&
                wrapNote(MedCodes)({
                    values: values?.med_codes,
                    fieldName: 'med_codes',
                    name: 'med_codes',
                    nonEditable,
                    handlePreview,
                    handleSignAndLock,
                    // formik: formikInstance,
                    appointmentId: clinicalNoteData?.appointment_id,
                    defaultIcd10Values: defaultIcd10ValuesForMedCodes,
                })}
            {activeNote.componentId === 'assessment_diagnosis' &&
                wrapNote(NewAssessmentDiagnosis)({
                    lastNarrative: lastNarratives.assessment_diagnosis?.narrative,
                    macros: state.macros,
                    master: state.master,
                    values: values?.assessment_diagnosis,
                    fieldName: 'assessment_diagnosis',
                    name: 'assessment_diagnosis',
                    patientId: psycho_note?.patient_id,
                    primary_care_physician: psycho_note?.primary_care_physician,
                    med_codes: values?.med_codes,
                    appointmentId: clinicalNoteData?.appointment_id,
                })}
            {activeNote.componentId === 'allergy' &&
                wrapNote(PatientAllergiesMedical)({
                    withoutMacro: true,
                    macros: state.macros,
                    lastNarrative: lastNarratives.allergy?.narrative,
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    master: state.master,
                    values: values?.allergy,
                    fieldName: 'allergy',
                    name: 'allergy',
                    allergyHistory: state.allergyHistory,
                    patient: clinicalNoteData?.patient,
                    nonEditable,
                    lastUpdateDate,
                    // formik: formikInstance,
                })}

            {activeNote.componentId === 'medications' &&
                wrapNote(MedicationPatient)({
                    master: state.master,
                    lastNarrative: lastNarratives.medications?.narrative,
                    values: values.medications,
                    fieldName: 'medications',
                    name: 'medications',
                    macros: state.macros,
                    patient_Id: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    handleSetViewHealthyRecord,
                    medicationHistory: state.medicationHistory,
                    formik: formikInstance,
                    nonEditable,
                    lastUpdateDate,
                })}
            {activeNote.componentId === 'family_history' &&
                wrapNote(FamilyHistory)({
                    values: values?.family_history,
                    fieldName: 'family_history',
                    name: 'family_history',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    primary_care_physician: psycho_note?.primary_care_physician,
                    handleSetViewHealthyRecord,
                    macros: state.macros,
                    master: state.master,
                    nonEditable: nonEditable,
                    lastNarrative: lastNarratives?.family_history?.narrative,
                    lastUpdateDate,
                })}
            {activeNote.componentId === 'physical_exam' &&
                wrapNote(PhysicalExam)({
                    values: values?.physical_exam,
                    fieldName: 'physical_exam',
                    name: 'physical_exam',
                    patientId: psycho_note?.patient_id,
                    encounter_id: psycho_note?.encounter_id,
                    macros: state.macros,
                    master: state.master,
                    nonEditable,
                    clinicalNoteData,
                    copyDate,
                    lastNarrative: lastNarratives?.physical_exam?.physical_exam
                })}
            {activeNote?.componentId === 'review_of_systems' &&
                wrapNote(ReviewOfSystems)({
                    values: values?.review_of_systems,
                    fieldName: 'review_of_systems',
                    name: 'review_of_systems',
                    nonEditable,
                    lastNarrative: lastNarratives?.review_of_systems,
                    copyDate: copyDate !== "" ? copyDate : null
                })}
        </Box>
    )
}

export default MedicalNotesView
