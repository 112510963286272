import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
    item_event: {
        position: 'relative',
        backgroundColor: '#F1F8FF',
        boxShadow: '0px 0px 2px 0px rgb(85 113 198 / 50%)',
        borderRadius: 5,
        // padding: '6px 10px', // FIXME:
        margin: '2px 0px',
        overflow: 'hidden',
        alignItems: 'flex-start !important',
        // padding: '0 10px', // FIXME:
    },
    item_text: {
        fontSize: 11,
    },
    line: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 2,
        height: '100%',
        background: '#0076FC',
    },
    item_header_container: {
        backgroundColor: '#fff',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 5px',
    },
    item_header_text: {
        fontSize: 11,
    },
    text_bold: {
        fontWeight: 'bold',
    },
    card_item_padding: {
        padding: 10,
        paddingLeft: 20,
    },
    status_button: {
        padding: '2px 8px',
        margin: 0,
        borderRadius: 5,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
    },
    card_icon: {
        padding: 0,
        margin: 0,
        marginRight: '5px',
        color: '#999',
    },
    card_icon_small_font: {
        fontSize: '12px !important',
    },
    card_icon_button: {
        padding: '0 !important',
        width: 43,
        height: 43,
    },
    card_action_icon: {
        padding: 0,
        margin: 0,
        color: '#999',
    },
    card_icon_large_font: {
        fontSize: '20px !important',
    },
    text_wrapper: {
        // FIXME:
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
    },
    default_flex_view: {
        display: 'flex',
        alignItems: 'center',
    },
    flex_justify_center: {
        justifyContent: 'center',
    },
    flex_justify_start: {
        justifyContent: 'start',
    },
    flex_justify_between: {
        justifyContent: 'space-between',
    },
    default_margin_top: {
        marginTop: '10px',
    },
    full_width: {
        width: '100%',
    },
    font_weight_500: {
        fontWeight: 500,
    },
    font_weight_400: {
        fontWeight: 400,
    },
    default_text_color: {
        color: '#303E4E',
    },
    font_14: {
        fontSize: '14px',
    },
    font_16: {
        fontSize: '16px',
    },
    co_pay: {
        paddingLeft: '3px',
        fontSize: '14px',
        fontWeight: 400,
    },
}))