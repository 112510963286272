import { getPermissionByRoleApi, getAllRolesApi } from '../../services/Permission'
import * as actionType from '../actionType'

export const setPermission = (value) => {
    return {
        type: actionType.SET_PERMISSION,
        value,
    }
}

export const setRoles = (value) => {
    return {
        type: actionType.SET_ROLES,
        value,
    }
}

export const getPermissionByRole = (roleId) => {
    return async (dispatch, getState) => {
        const state = getState()
        // if (roleId) {
        if (!state?.login?.userData?.role_id) {
            return
        }
        const response = await getPermissionByRoleApi(state?.login?.userData?.role_id)
        response.data.isPermissionAvailable = true
        dispatch(setPermission(response.data))
        // return
        // }

        // const response = await getPermissionByRoleApi(state?.login?.userData?.role_id)
        // dispatch(setPermission(response.data))
    }
}

export const getAllRoles = (roleId) => {
    return async (dispatch) => {
        const response = await getAllRolesApi(roleId)
        dispatch(setRoles(response.data))
    }
}
