import { FormLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { createDocument } from 'services/Documents'
import { ModalEl as Modal } from 'shared/elements'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { Document, ErrorType } from '../../interfaces'

import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useSelector } from 'react-redux'
import { Auth } from 'aws-amplify'
import { createLogPsychotherapy } from 'containers/ClinicalNotes/api/services'

export const INITIAL_VALUES = {
  description: '',
  username: '', 
  password: '',
}


interface Props {
  open?: boolean,
  id?: string
  onCancel?: () => void
  onEditPsycho?: () => void
}

const useStyles = makeStyles(() => ({
  text: {
      textAlign: 'center',
      color: '#303E4E',
      fontSize: 19,
      fontWeight: 500,
      marginBottom: 25,
  },
  reset_pass: {
      color: '#5571C6',
      fontSize: 14,
      textAlign: 'end',
      cursor: 'pointer',
      marginTop: 4,
  },
  container: {
      width: "100%",
      background: '#FBFCFC',
      border: '1px solid #F3F6F9',
      borderRadius: 10,
      padding: '32px 20px',
  },
  required_text: {
      color: 'red',
  },
}))


export const BreakTheGlassForm = (props: Props) => {
  const notification = useNotification()
  const classes = useStyles()

  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState(INITIAL_VALUES)

  const email = useSelector(
    (state) => {
      return state.login.userData.email
    },
)


  const handleCancel = (forceReload?: boolean) => {
    setData({ ...INITIAL_VALUES})
    props.onCancel && props.onCancel()
  }

  useEffect(() => {
    if (email) {
      setData(prev => ({
      ...prev,
      username: email,
    }))}
  }, [email])

  const checkLogin = (payload) => {
    setSubmitting(true)
    Auth.signIn(payload.username, payload.password).then(async (data) => {
      props?.onEditPsycho && props?.onEditPsycho()
      writeLog("successed", payload.description)
    })
      .catch((error) => {      
        writeLog("failed", payload.description)
      notification(
        error?.message || 'Something went wrong!',
        'error',
      )
    })
      .finally(() => {
        setSubmitting(false)
    })
  }

  const writeLog = (status, description) => {
    try {
      createLogPsychotherapy({status, description})

    } catch(error) {
      notification('Something went wrong!',
        'error',
      )
     }
  }

  const validationSchema = yup.object({
    description: yup.string()
  })
  
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: checkLogin,
  })



  return (
    <Modal
      width={680}
      open={props.open}
      onClose={handleCancel}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden'
      }}>

      <Grid container item className={classes.container}>
        <Grid item xs={12}>
            <Typography className={classes.text}>
            Break The Glass
            </Typography>
        </Grid>
        <Grid item xs={12} style={{textAlign: "center"}}>
            <Typography >
            Psychotherapy Process Note you are attempting to access is restricted. If you have a need to access this providers note regarding patient. Please enter a reason and your password.
            </Typography>
        </Grid>
            
        <Grid item xs={12}>
                <TextBox
                    label="Username"
                    name="username"
                    value={formik.values.username}
                    placeholder="Enter your last name"
                    error={
                      formik.touched.username &&
                        Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username &&
                      formik.errors.username
                    }
                    onChange={(value) =>
                      formik.setFieldValue('username', value)
                    }
                />
            </Grid>      
        <Grid item xs={12}>
                <TextBox
                    label="Password"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    placeholder="Enter your last name"
                    error={
                      formik.touched.password &&
                        Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password &&
                      formik.errors.password
                    }
                    onChange={(value) =>
                      formik.setFieldValue('password', value)
                    }
                />
            </Grid>      
               

            <Grid item xs={12}>
                <TextBox
                    label="Please provide your reason for accessing this Pyschotherapy Process Note"
                    name="description"
                    value={formik.values.description}
                    multiline
                    minRows={6}
                    placeholder="Enter your last name"
                    error={
                      formik.touched.description &&
                        Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description &&
                      formik.errors.description
                    }
                    onChange={(value) =>
                      formik.setFieldValue('description', value)
                    }
                />
            </Grid>           
        </Grid>

      <Grid container style={{ padding: '20px 20px', width: "50%", margin:'auto' }}>
        <Footer
          loading={submitting}
          onCancel={() => {
            writeLog("cancelled", formik.values.description)
            handleCancel(false)
          }}
          onOk={formik.handleSubmit}
        />
      </Grid>
    </Modal>
  )
}
