import * as Yup from 'yup'
import moment from 'moment'

export const validationSchema = Yup.object().shape({
    // start_date: Yup.string().test({
    //     name: 'start_date',
    //     test(value) {
    //         if (moment(value, 'MM/DD/YYYY').diff(moment(), 'days') < 0) {
    //             return this.createError({
    //                 message: 'Start date must be after current date',
    //                 path: 'start_date',
    //             })
    //         }
    //         return true
    //     },
    // }),
    start_time: Yup.string().test({
        name: 'start_time',
        test(value, context) {
            const end = moment(context.parent.end_time, 'HH:mm')
            const start = moment(value, 'HH:mm')
            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'Start time must be before end time',
                    path: 'start_time',
                })
            }
            return true
        },
    }),
    end_time: Yup.string().test({
        name: 'end_time',
        test(value, context) {
            const start = moment(context.parent.start_time, 'HH:mm')
            const end = moment(value, 'HH:mm')
            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'End time must be after start time',
                    path: 'end_time',
                })
            }
            return true
        },
    }),
    repeat_gap: Yup.number().when('is_recur', {
        is: true,
        then: Yup.number().min(1, 'Minimum 1').required('Repeat every is required!'),
    }),
    repeat_by: Yup.string().when('is_recur', {
        is: true,
        then: Yup.string().required('Repeat every is required!'),
    }),
    days: Yup.array().when(['is_recur', 'repeat_by'], {
        is: (is_recur, repeat_by) => is_recur && repeat_by !== 'DAY',
        then: Yup.array().min(1, 'Minimum 1 day'),
    }),
    end_date: Yup.date().when('is_recur', {
        is: true,
        then: Yup.date().test({
            name: 'end_date',
            test(value, context) {
                const start = moment(context.parent.start_date, 'MM/DD/YYYY')
                const end = moment(context.parent.end_date)
                if (end.isBefore(start)) {
                    return this.createError({
                        message: 'End date must be after start date',
                        path: 'end_date',
                    })
                }
                return true
            },
        }),
    }),
    end_type: Yup.string().when('is_recur', {
        is: true,
        then: Yup.string().required('This field is required!'),
    }),
})

export const validationSchemaV2 = Yup.object().shape({
    // provider_id: Yup.string().required(),
    start_date: Yup.string().test({
        name: 'start_date',
        test(value, context) {
            if(!value || value === ''){
                return this.createError({
                    message: 'Start date is required',
                    path: 'start_date',
                })
            }
            if (!context?.parent?.is_recur && moment(value, 'MM/DD/YYYY').diff(moment(), 'days') < 0) {
                return this.createError({
                    message: 'Start date must be after current date',
                    path: 'start_date',
                })
            }
            return true
        },
    }).required(),
    end_date: Yup.date().when('is_recur', {
        is: true,
        then: Yup.date().test({
            name: 'end_date',
            test(value, context) {
                const start = moment(context.parent.start_date, 'MM/DD/YYYY')
                const end = moment(context.parent.end_date)
                if(context.parent?.is_recur && (value === '' || !value)){
                    return this.createError({
                        message: 'End date is required',
                        path: 'end_date',
                    })
                }
                if (end.isBefore(start)) {
                    return this.createError({
                        message: 'End date must be after start date',
                        path: 'end_date',
                    })
                }
                return true
            },
        }),
    }),
    timezone: Yup.string().required(),
    is_recur: Yup.boolean().required(),
    days: Yup.array()
        .min(1, 'Minimum 1 day')
        .of(
            Yup.object().shape({
                day: Yup.string().oneOf(['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']),
                available: Yup.boolean(),
                options: Yup.array().when('available', {
                    is: true,
                    then: Yup.array().of(
                        Yup.object().shape({
                            start_time: Yup.string().test({
                                name: 'start_time',
                                test (value, context) {
                                    const end = context.parent?.end_time
                                    if (!end || !value) return true            
                                    return moment(value, 'HH:mm').isBefore(moment(end, 'HH:mm')) 
                                        ? true 
                                        : this.createError({ 
                                            message: 'Start time must be before end time', 
                                            path: context.path 
                                        });                 
                                    },            
                                }),               
                                
                            end_time: Yup.string().test({                 
                                name: 'end_time',                 
                                test (value, context) { 
                                    const start = parent?.start_time                  
                                    if (!start || !value) return true
                                    return moment(value, 'HH:mm').isAfter(moment(start, 'HH:mm')) 
                                    ? true                    
                                    : this.createError({ 
                                        message: 'End time must be after start time', 
                                        path: context.path 
                                    });
                                },               
                            }),             
                        })           
                        ),          
                         otherwise: Yup.array(),
                        }),       
                    })
        ).required()
    })
