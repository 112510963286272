import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import { CircularProgress } from '@mui/material'
import Routes from 'constants/routes'
import { ImmunizationsCreateDialog } from 'containers/PatientsSetting/view/Immunizations/ImmunizationsCreateDialog'
import { useHistory, useParams } from 'react-router-dom'
import { getListImmunizations } from 'services/Patient/patient'
import { Card } from '../view/CardHealthRecord'
import moment from 'moment'
import { NoData } from './NoData'

interface Props {
  onClick?: () => void
}


const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'transparent'
  },
  viewall: {
    color: '#667689',
    '&:hover': {
      color: '#4e52d9',
    }
  },
}))

export const Immunizations = (props: Props) => {
  const history = useHistory()
  const classes = useStyles()
  const [isAddingNew, setIsAddingNew] = useState(false)
  const { id: patientId } = useParams<{ id: string }>()

  const [immunizations, setImmunizations] = useState([])

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const abortController = new AbortController()
    fetchMedications()

    return () => abortController.abort()
  }, [])

  const fetchMedications = async () => {
    try {
      setLoading(true)
      const resp = await getListImmunizations(patientId, 1, 10)
      setImmunizations(resp.data.data)
    } catch (ex) {
      console.error('[Immunizations] cannot fetch medial problem list', ex)
    }

    setLoading(false)
  }

  const handleViewAll = () => history.push(Routes.PATIENT.IMMUNIZATIONS.replace(':id', patientId))

  const Content = () => (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{
          gap: 8,
          height: '100%',
          overflow: 'auto',
          padding: 15,
          flexWrap: 'nowrap'
        }}>
        {immunizations.map((item: any) => (
          <Grid
            container
            key={item}
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
            style={{
              borderBottom: '1px solid #E4E8EE',
              paddingBottom: 8,
            }}>
            <Grid
              item
              container
              style={{
                width: 'fit-content',
                height: '100%',
              }}>
              <div
                className={
                  classes.line_hor
                }></div>
              <Grid
                item
                container
                alignItems="flex-start"
                direction="column"
                style={{
                  width: 'fit-content',
                }}>
                <Typography>
                  {/* <span
                       className={
                           classes.title_clinical_reminder
                       }>
                       Assessment:
                   </span>{' '} */}
                  {item?.vaccine?.display}
                </Typography>
                <Typography
                  style={{
                    color: '#9CA9B8',
                    fontSize: 12,
                  }}>
                  {item?.administered_on ? moment(item?.administered_on).format('MMM DD,YYYY') : moment().format('MMM DD,YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              className={
                classes.tag_type_red
              }>
              {item?.status}
            </Typography>
          </Grid>
        ))}
      </Grid>

      {!!immunizations.length &&
        <Grid container item justifyContent='flex-end' style={{ padding: 15 }}>
          <span className={classes.viewall}
            onClick={handleViewAll}>View all</span>
        </Grid>}
    </>
  )

  return (
    <Card
      // menu={[
      //   {
      //     name: 'Add',
      //     handler: () => { setIsAddingNew(true) }
      //   },
      //   {
      //     name: 'View all',
      //     handler: handleViewAll
      //   }
      // ]}
      viewAllHandler={handleViewAll}
      addHandler={() => setIsAddingNew(true)}
      onClick={props.onClick}
      header="Immunizations">
      {!!loading &&
        <Grid container className={classes.loader}
          alignItems='center'
          justifyContent='center'>
          <CircularProgress color='inherit' thickness={1.5} />
        </Grid>}

      {immunizations.length ? <Content /> : <NoData />}

      {!!isAddingNew &&
        <ImmunizationsCreateDialog
          open={isAddingNew}
          itemId=""
          patientId={patientId}
          refetchData={fetchMedications}
          onClose={() => { setIsAddingNew(false) }
          }
        />
      }
    </Card>
  )
}
