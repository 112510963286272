import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
// import { _getClinicalNotes } from '../../store/actions/clinicalNotes'
import moment from 'moment'
import { NoRowsOverlayEl } from '../../shared/elements'
import { UserRoles } from '../../constants/user-roles'
import {
    capitalizeFirstLetter,
    getLoggedInUserRole,
} from '../../utilities'
import { _getClinicalNotes } from '../../containers/ClinicalNotes/api/services'
const LIMIT = 10

const renderViewAction = (params) => {
    return (
        <Grid item>
            <Typography
                variant="body1"
                color="grey"
                onClick={() => {}}
                style={{
                    cursor: 'not-allowed',
                }}
                disabled={true}>
                View
            </Typography>
        </Grid>
    )
}

const renderEditAction = (params) => {
    return (
        <Grid item>
            <Typography
                variant="body1"
                color="grey"
                onClick={() => {}}
                style={{
                    cursor: 'not-allowed',
                }}
                disabled={true}>
                Edit
            </Typography>
        </Grid>
    )
}

const renderActions = (params) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            {renderViewAction(params)}
            {renderEditAction(params)}
        </Grid>
    )
}

const mapList = (notesList = []) => {
    // if (getLoggedInUserRole === UserRoles.PATIENT) {

    // }
    return notesList.map((list) => ({
        id: list._id,
        providerName: `${list.provider?.first_name} ${list.provider?.last_name}`,
        patientId: list.patient?.id,
        date: list.appointment?.date,
        time: list.appointment?.start,
        patientName: `${list.patient?.first_name} ${list.patient?.last_name}`,
        speciality: list.appointment.speciality?.name,
    }))
}

const ClinicalNotesListPatient = ({
    userId,
    checkprovider,
}) => {
    const [page, setPage] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    // const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const onPageChange = (value) => {
        setPage(value + 1)
    }
    // const clinicalNotesList = useSelector(
    //     (state) => state.clinicalNotes.clinicalNotesList,
    // )
    // const mappedList = mapList(clinicalNotesList)
    // const totalCount = useSelector(
    //     (state) => state.clinicalNotes.totalCount,
    // )
    // const isLoading = useSelector(
    //     (state) => state.loader.loading,
    // )
    const userRole = getLoggedInUserRole()

    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)
    useEffect(() => {
        // dispatch(
        //     getClinicalNotesList({
        //         page,
        //         limit: LIMIT,
        //         patient_id:
        //             isDisabled || checkprovider
        //                 ? userId
        //                 : null,
        //     }),
        // )
        _getClinicalNotes()
            .then((res) => {
                setLoading(false)
                setPatientList(res.data)
            })
            .catch((err) => {
                setLoading(false)
            })
    }, [])

    const columns = [
        {
            headerName: (
                <Typography>Provider Name</Typography>
            ),
            field: 'providerName',
            flex: 1.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Date
                </Typography>
            ),
            field: 'date',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return moment
                    .utc(`${value} ${row.time}`)
                    .local()
                    .format('MM/DD/YYYY')
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Time
                </Typography>
            ),
            field: 'time',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return moment
                    .utc(`${row.date} ${value}`)
                    .local()
                    .format('hh:mm A')
            },
        },

        {
            headerName: (
                <Typography variant="body2">
                    Speciality
                </Typography>
            ),
            field: 'speciality',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Actions
                </Typography>
            ),
            field: 'id',
            width: 142,
            sortable: false,
            renderCell: renderActions,
        },
    ]

    return (
        <Grid
            container
            className="MuiDataGrid-table-wrapper form-list-container bg-white"
            xs={12}>
            <DataGrid
                getRowId={(row) => row.id}
                rows={patientList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                paginationMode="server"
                onPageChange={onPageChange}
                disableColumnSelector={true}
                autoHeight
                disableSelectionOnClick
                rowHeight={60}
                loading={isLoading}
                rowCount={totalCount}
                pagination
                components={{
                    NoRowsOverlay: () => (
                        <NoRowsOverlayEl title="No Data Found." />
                    ),
                }}
            />
        </Grid>
    )
}

export default ClinicalNotesListPatient
