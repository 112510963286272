import { Box, Grid, Typography } from '@material-ui/core'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { useParams } from 'react-router-dom'
import { deleteInsurance, getListInsurances } from 'services/Patient/patient'
import { NoRowsOverlayEl } from 'shared/elements'
import { Pagination } from 'shared/elements/Pagination'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { sx, usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'

import { ErrorType, Insurance } from '../../interfaces'
import { DataGridCell } from '../../shared'
import { apiSocialHistory } from 'services/Patient/apiSocialHistory'
import { apiInsurrancePolicy } from 'services/Patient/apiInsurrancePolicy'


const InsuranceBrand = ({ insurance }) => (
    <Grid container>
        <Grid item xs={12}>
            {insurance?.case_name}
        </Grid>
    </Grid>
)

export const InsuranceList = (props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams()
    const notification = useNotification()

    // const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [listInsurance, setListInsurance] = useState([])
    const [activeList, setActiveList] = useState([])
    const [inactiveList, setInactiveList] = useState([])

    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     page: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const columns = [
        {
            headerName: 'Payment Method',
            headerClassName: 'super-app-theme--header',
            field: 'payment_method',
            flex: 0.8,
            sortable: true,
            renderCell: ({ row }) => {
                return <DataGridCell value={row?.payment_method?.display} />
            },
        },
        {
            headerName: 'Policy Number',
            headerClassName: 'super-app-theme--header',
            field: 'policy_no',
            flex: 0.8,
            sortable: true,
            valueGetter: ({ row }) => row?.insurance?.policy_no,
            sortComparator: (p, q) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={row?.insurance?.policy_no} />
            },
        },
        {
            headerName: 'Type',
            headerClassName: 'super-app-theme--header',
            field: 'type',
            flex: 0.5,
            sortable: true,
            valueGetter: ({ row }) => row?.type,
            sortComparator: (p, q) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={row?.type} />
            },
        },
        {
            headerName: 'Health Plan',
            headerClassName: 'super-app-theme--header',
            field: 'planName',
            flex: 0.7,
            sortable: true,
            renderCell: ({ value, row }) => {
                return <DataGridCell value={row?.insurance?.plan?.display} />
            },
        },
        {
            headerName: 'Effective Dates',
            headerClassName: 'super-app-theme--header',
            field: 'effectiveDates',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ row }) => {
                return (
                    <DataGridCell
                        value={`${formatDateTime(
                            row.insurance?.policy_effective,
                            'MM/DD/YYYY',
                        )} - ${formatDateTime(row.insurance?.policy_end, 'MM/DD/YYYY')}`}
                    />
                )
            },
        },
        {
            headerName: 'Active Policy',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            // width: 100,
            flex: 0.4,
            sortable: true,
            renderCell: ({ row }) => {
                // function capitalize(s) {
                //     return s[0].toUpperCase() + s.slice(1)
                // }
                return (
                    <Typography
                        style={{
                            textTransform: 'capitalize',
                        }}
                        className={classes[`tag_type_${row.status?.code}`]}>
                        {row.status || ''}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Front Insurance Card',
            headerClassName: 'super-app-theme--header',
            field: 'front_card',
            flex: 0.4,
            sortable: true,
            valueGetter: ({ row }) => row?.type,
            sortComparator: (p, q) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    row?.front_card ?
                        <span style={{color:'#5571C6', cursor:"pointer"}} onClick={()=>{
                            window.open(row?.front_card)
                        }}>IMG
                        </span>
                    :
                        'N/A'
                )
            },
        },
        {
            headerName: 'Back Insurance card',
            headerClassName: 'super-app-theme--header',
            field: 'back_card',
            flex: 0.4,
            sortable: true,
            valueGetter: ({ row }) => row?.type,
            sortComparator: (p, q) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    row?.front_card ?
                        <span style={{color:'#5571C6', cursor:"pointer"}} onClick={()=>{
                            window.open(row?.back_card)
                        }}>IMG
                        </span>
                    :
                        'N/A'
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
                return (
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.onClickEdit(params.id.toString()),
                            },
                            {
                                text: 'Delete',
                                handler: () => handleDelete(patientId, params.id.toString()),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this insurance plan?"
                    />
                )
            },
        },
    ]

    useEffect(() => {
        const abortController = new AbortController()
        fetchListInsurance()

        return () => abortController.abort()
    }, [page])

    useEffect(() => {
        const abortController = new AbortController()
        if (props.cycleCount > 0) {
            setTimeout(() => fetchListInsurance(), 1000)
        }

        return () => abortController.abort()
    }, [props.cycleCount, page])

    const handleDelete = async (patientId, allergyId) => {
        try {
            await deleteInsurance(patientId, allergyId)

            notification(`Insurance Plan Deleted Successfully!`, 'success')

            fetchListInsurance()
        } catch (error) {
            const errMsg =
                error?.data?.message ||
                `Something went wrong when deleting Insurance plan!`

            notification(errMsg, 'error')
        }
    }

    const onPageChange = (value) => {
        setPage(value)
    }

    const fetchListInsurance = async () => {
        setLoading(true)

        try {
            // const resp = await getListInsurances(patientId, page, paginationData.limit)
            const resp = await apiInsurrancePolicy.getAll(patientId, {
                page,
                size: limit,
            })

            const sortedTableList = resp?.data?.data?.sort((a, b) => {
                const typeOrder = { Primary: 1, Secondary: 2, Tertiary: 3 };
                return typeOrder[a.type] - typeOrder[b.type];
              });
            let isPrimary = false, isSecondary=false, isTertiary=false
            resp?.data?.data?.map((item) => {
                if(item.type === "Primary" && item.status === 'active')
                {
                    isPrimary= true
                }
                if(item.type === "Secondary")
                {
                    isSecondary=true
                }
                if(item.type === "Tertiary")
                {
                    isTertiary=true
                }
            })
            // console.log('insurance type @@: ',{isPrimary, isSecondary, isTertiary})
            setListInsurance(sortedTableList)

            setActiveList(() => {
                return sortedTableList?.filter(item => item?.status === 'active')
            })

            setInactiveList(() => {
                return sortedTableList?.filter(item => item?.status === 'inactive')
            })

            const InsuranceData = {isPrimary, isSecondary, isTertiary}
            props.insuranceType(InsuranceData)

            setTotal(resp.data.paging.total)
        } catch (ex) {
            notification(`Cannot get list Insurance!`, 'error')
        }

        setLoading(false)
    }

    return (
        <>
            <Grid item xs={12}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 360px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            // cursor: 'pointer',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                        '& .MuiDataGrid-virtualScroller': {
                            overflowX: 'hidden',
                            overflowY: 'auto',
                        },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        sx={sx.datagrid}
                        rows={props?.tabValue === 'active' ? activeList : inactiveList}
                        columns={columns}
                        onPageChange={onPageChange}
                        pageSize={limit}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={loading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={onPageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>
        </>
    )
}
