import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { getPatientCount, submitConsulationTracking } from '../../apis/closingConsultation';
import listWaitingQueue from '../../apis/listWaitingQueue';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useProviderContext from '../useProviderContext/useProviderContext';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useParticipants() {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const { currentUser } = useProviderContext();
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));
  const [listWaiting, setListWaiting] = useState([])
  const currId = window.localStorage.getItem('currAppId')

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants(prevParticipants => [
        dominantSpeaker,
        ...prevParticipants.filter(participant => participant !== dominantSpeaker),
      ]);
    }
  }, [dominantSpeaker]);

  const getWaitingList = async () => {
    let currId = window.localStorage.getItem('currAppId')
    const res = await listWaitingQueue(
      currentUser?.room_id!
    );
    let count = 0;
    res?.data.map((el: any) => {
      if (el.appointment_id === Number(currId) && el.is_admitted) {
        count++
      }
    })
    if (count === 0) {
      Number(currId) && handleConsulationTracking(Number(currId), true)
    }
    else {
      Number(currId) && handleConsulationTracking(Number(currId), false)
    }
  }
  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => [...prevParticipants, participant]);
        Number(currId) && handleConsulationTracking(Number(currId), false)
      }
      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
        getWaitingList()

      }
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room, currId]);


  const handleConsulationTracking = async (
    appointment_id: any,
    consultation_status: any
  ) => {

    let data = localStorage.getItem('roomName');
    let countPayload = { roomName: data };
    let countData = await getPatientCount(countPayload);
    if (countData) {
      let payload = {
        is_consultation_end: consultation_status,
        provider_id: currentUser?.provider_id,
        participants_count: countData.data.length,
        appointment_id: appointment_id,
      };
      let res = await submitConsulationTracking(payload);
    }
  };


  return participants;
}
