import moment, { Moment } from "moment-timezone"

export type DATE_TIME_FORMAT = |
  'YYYY-MM-DD' |
  'MM/DD/YYYY' |
  'MM/DD/YYYY, hh:mm A'|
  'MMM DD, YYYY' |
  'MMM DD YYYY' |
  'MMM DD, YYYY - hh:mm A' |
  'MMM DD, YYYY, HH:mm' |
  'MMM DD, YYYY HH:mm' |
  'MMM DD, YYYY hh:mm A' |
  'MMM DD, YYYY -- hh:mm A' |
  'h[h]mm' |
  'hh:mm A' |
  'MMMM DD YYYY' |
  'MMM DD YYYY hh:mm A' |
  'MMM DD YYYY, hh:mm a' |
  'MMM DD YYYY, hh:mm A' |
  'MM-DD-YYYY'

export const formatDateTime = (
  datetime?: Moment | Date | string,
  format: DATE_TIME_FORMAT = 'MM/DD/YYYY') => {
    return  datetime ?
    moment(datetime).utcOffset(-(new Date().getTimezoneOffset())).format(format) : ''}

export const getAge = (datetime, format='YYYY-MM-DD') => {
  return moment().diff(moment(datetime, format), 'years') 
}

export function getFullName (firstName?: string, lastName?: string, middleName?: string, separator = ' ') {
  return [firstName, middleName, lastName].filter(part => part && part.length).join(separator)
}
