import moment from 'moment'
import { SCREEN } from './Constant'

export const INITIAL_VALUES = {
    first_name: '',
    last_name: '',
    specialty_id: '',
    npi: '',
    email: '',
    location_id: '',
    phone: '',
    fax: '',
    comment: '',
}
