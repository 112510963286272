import React from 'react'
import {
    Select as MuiSelect,
    FormLabel,
    FormControl,
    OutlinedInput,
    MenuItem,
    Typography,
} from '@material-ui/core'
import { SelectChangeEvent, SelectProps } from '@mui/material'
import { makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import Divider from '@mui/material/Divider'
import { ButtonIconEl } from '..'
import InfoPopOver from 'components/shared/Elements/InfoPopOver'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
        anchororigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformorigin: {
            vertical: 'top',
            horizontal: 'left',
        },
    },
}
const Select = (props) => {
    const useStyles = makeStyles((theme) => ({
        notchedOutline: {
            borderColor: `#E9ECF1 !important`,
        },
        menuItem: {
            '&:hover': {
                backgroundColor: 'red !important',
            },
        },
        root: {
            background: '#FBFCFC',
            '& .MuiFormLabel-root.Mui-disabled': {
                color: 'black',
            },
            '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `#E9ECF1 !important`,
                },
            },
            '&:focus': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: `#E9ECF1 !important`,
                },
            },
            '& .MuiSelect-select': {
                backgroundColor: 'unset',
            },

            '& .MuiInputBase-input': {
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                paddingRight: '36px !important',
            },
            '& .MuiMenu-paper': {
                boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15) !important',
                borderRadius: 8,
                padding: '8px 0',
                width: 224,
                marginTop: 46,
            },
            '& .MuiSvgIcon-root': {
                fontSize: 20,
                // marginTop: -2,
                color: '#303E4E',
            },
            '& .MuiOutlinedInput-root': {
                padding: '12px 30px 12px 12px !important',
            },
            '& .paper': {
                '& ul': {
                    maxWidth: 400,
                    maxHeight: 460,
                },
            },
            '& .MuiSelect-icon': {
                right: props.isSuperBill && props.value ? '45px !important' : '',
            },
        },
        // paper: {
        //     '& ul': {
        //         maxWidth: 400,
        //         maxHeight: 460
        //     }
        // }
        checkboxLabel: {
            '& .MuiFormControlLabel-root': {
                marginRight: '0 !important',
                '& .MuiIconButton-root': {
                    paddingBottom: '0 !important',
                    paddingTop: '0 !important'
                }
            }
        },
        placeHolderBorderRemove:{
            color: '#667689',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400
        },
        placeHolderNormal:{
            color: 'lightgray',
            fontWeight: 300
        }

    }))

    const classes = useStyles(props)

    const {
        label,
        required,
        dropdownliststyles = {},
        helperText,
        resetData,
        allowClear,
        isSuperBill,
        ...rest
    } = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClickInfoIcon = (event) => {
        if (anchorEl) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget)
        }
    }
    const renderPlaceholder = () => (
        <span className={rest.input ? classes.placeHolderBorderRemove:classes.placeHolderNormal}>
            {rest.placeholder || 'Select data'}
        </span>
    )
    const modifier = React.useMemo(() => {
        return rest?.options?.find((mod) => mod?.key === rest.value)
    }, [rest.value])
    // console.log('value', rest.value)
    // console.log('149=>',props?.value, props?.name)
    return (
        <FormControl fullWidth disabled={props.disabled}>
            {!!label && (
                <FormLabel
                    component="p"
                    className="input-form-label d-flex justify-space-between align-center"
                    style={{ fontSize: 14, fontWeight: 500 }}>
                    <span className={`${classes.checkboxLabel} ${!!required ? 'required' : ''}`} style={{ color: '#303E4E' }}>
                        {props?.labelComponent}{label}
                    </span>
                    {props.slot}
                </FormLabel>
            )}

            {!props?.uncontrolled && (
                <>
                    <MuiSelect
                        fullWidth
                        displayEmpty={true}
                        input={rest.input||<OutlinedInput margin="dense" classes={{ root: classes?.root }} />}
                        classes={{ root: classes.root }}
                        style={{
                            height: props.height,
                            padding: 0,
                            backgroundColor: '#FFF',
                            ...props?.style,
                            ...props?.selectprops?.style,
                        }}
                        IconComponent={KeyboardArrowDown}
                        {...rest}
                        MenuProps={MenuProps}
                        inputProps={{ classes: {} }}
                        renderValue={
                            rest.value === undefined || rest.value === ''
                                ? renderPlaceholder
                                : rest.renderValue
                        }
                        onChange={(event) => props.onChange && props.onChange(event.target.value)}>
                        {props?.options?.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option ? option.key : ''}
                            // className={option?.color ? classes['menuItem'] : ''}
                            // style={{
                            //     background: option?.color ? option.color : '',
                            // }}
                            >
                                <Typography
                                    // variant="body2"
                                    noWrap
                                    style={{
                                        ...dropdownliststyles,
                                        color: '#667689',
                                        fontSize: 14,
                                    }}>
                                    {option.value}
                                </Typography>
                            </MenuItem>
                        ))}

                        {props.createSlot}
                    </MuiSelect>

                    {required && props.error && (
                        <p
                            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text"
                            id={`${props.name}-helper-text`}>
                            {helperText || 'This field is required!'}
                        </p>
                    )}
                    {rest.value && (resetData || allowClear) && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 8,
                                right: isSuperBill ? 0 : 30,
                            }}>
                            <ButtonIconEl size="small" icon={undefined} iconHover={undefined}>
                                {isSuperBill && (
                                    <Divider
                                        variant="middle"
                                        flexItem
                                        orientation="vertical"
                                        className="divider_selectBox"
                                    />
                                )}

                                <HighlightOffIcon
                                    style={{
                                        fontSize: 18,
                                        color: '#9CA9B8',
                                        paddingLeft: '3px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        props.onChange && props.onChange('')
                                    }}
                                />
                                {isSuperBill && (
                                    <InfoPopOver title={modifier && modifier.value}>
                                        <ErrorOutline
                                            style={{
                                                fontSize: 18,
                                                color: '#9CA9B8',
                                                paddingLeft: '2px',
                                            }}
                                            onClick={handleClickInfoIcon}
                                        />
                                    </InfoPopOver>
                                )}
                            </ButtonIconEl>
                            {/* <InfoPopOver
                                anchorEl={anchorEl}
                                bodyText={`Are you sure you want to delete the service line?`}
                            /> */}
                        </div>
                    )}
                </>
            )}
        </FormControl>
    )
}

export { Select }
export default Select
