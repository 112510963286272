import { Box, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import OutpationPsychiatricHistory from 'containers/PatientsSetting/view/OutpationPsychiatricHistory'
import React, { useEffect } from 'react'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { SelectEl } from '../../../shared/elements'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import DataGridCustom from '../reusable/DataGridCustom'
import RadioOptions from '../reusable/RadioOption'
import { apiOutpatientPsychiatricHistory } from 'services/Patient/apiOutpatientPsychiatricHistory'
import { useNotification } from 'shared/elements/Notification'

const useStyles = makeStyles({
    headerTxt: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
    },
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        textTransform: 'none',
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 40,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})



const OutpatientPsychiatricHistory = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    patientId,
    encounter_id,
    nonEditable = false,
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable },
        { label: 'No', value: 'No', disable: nonEditable },
    ]
    const classes = useStyles()
    const notification = useNotification()
    const defaultInpatientOptions = {
        id: 0,
        provider: '',
        reasons: '',
        lastAge: '',
        firstAge: '',
        treatment_outcome: '',
        comment: '',
    }

    const handleAddMore = (action, index) => {
        const val = values['outpatient_psychiatric_opt'] || []
        if (action === 'add') {
            const updatedValue = [...val, defaultInpatientOptions]
            onChange(`${fieldName}.outpatient_psychiatric_opt`, updatedValue)
        } else {
            const updatedValue = val.filter((v, i) => i !== index)
            onChange(
                `${fieldName}.outpatient_psychiatric_opt`,
                updatedValue.length > 0 ? updatedValue : [defaultInpatientOptions],
            )
        }
    }

    const mapValues = (values = []) => {
        return values.map((v, index) => ({
            ...v,
            id: index,
        }))
    }

    const getOptions = (master = [], key) => {
        return master.map((im) => ({
            value: im[key],
            key: im._id,
        }))
    }

    const reasonOptions = getOptions(master?.outpatientPsychiatricMasters, 'name')
    const treatmentOutcome = getOptions(master?.therapeuticEffectOutPatient, 'name')

    const isEmptyRow = (row = []) => {
        return !(row.provider || row.treatment_outcome || row.reasons || row.comment || row.age)
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        // const { outpatient_psychiatric_opt } = values
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box sx={{ width: 68, display: 'flex' }}>
                <IconButton
                    variant="outlined"
                    onClick={() => setAlertDeleteOpen(true)}
                    // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const handleChange = (e, keyName, id) => {
        const { name, value } = e.target

        const val = values[name].length !== 0 ? values[name] : [defaultInpatientOptions]

        const updatedValue = val?.map((v, index) => (index === id ? { ...v, [keyName]: value } : v))
        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const columns = [
        {
            headerName: <Typography className={classes.headerTxt}>Provider</Typography>,
            field: 'provider',
            headerClassName: 'super-app-theme--header',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, id, row }) => {
                return (
                    <TextField
                        disabled={!values['have_outpatient_psyc_treat']}
                        className={classes.text__field}
                        fullWidth
                        variant="outlined"
                        name={'outpatient_psychiatric_opt'}
                        placeholder={'Placeholder'}
                        size="small"
                        value={row.provider}
                        onChange={(e) => handleChange(e, 'provider', id)}
                    />
                )
            },
        },
        {
            headerName: (
                <Typography className={classes.headerTxt}>
                    Primary reason for seeking treatment
                </Typography>
            ),
            field: 'reasons',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['have_outpatient_psyc_treat']}
                    name={`outpatient_psychiatric_opt`}
                    value={row.reasons || ''}
                    onChange={(e) => handleChange(e, 'reasons', row.id)}
                    options={reasonOptions}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: (
                <Typography className={classes.headerTxt}>Age of first treatment</Typography>
            ),
            field: 'firstAge',
            headerClassName: 'super-app-theme--header',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <TextField
                    fullWidth
                    variant="outlined"
                    name={'outpatient_psychiatric_opt'}
                    disabled={!values['have_outpatient_psyc_treat']}
                    placeholder={'Placeholder'}
                    size="small"
                    value={row.firstAge}
                    onChange={(e) => handleChange(e, 'firstAge', id)}
                />
            ),
        },
        {
            headerName: (
                <Typography className={classes.headerTxt}>Age of last treatment</Typography>
            ),
            field: 'lastAge',
            headerClassName: 'super-app-theme--header',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <TextField
                    fullWidth
                    variant="outlined"
                    name={'outpatient_psychiatric_opt'}
                    disabled={!values['have_outpatient_psyc_treat']}
                    placeholder={'Placeholder'}
                    size="small"
                    value={row.lastAge}
                    onChange={(e) => handleChange(e, 'lastAge', id)}
                />
            ),
        },
        {
            headerName: <Typography className={classes.headerTxt}>Outcome</Typography>,
            field: 'treatment_outcome',
            headerClassName: 'super-app-theme--header',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['have_outpatient_psyc_treat']}
                    name={`outpatient_psychiatric_opt`}
                    value={row.treatment_outcome || ''}
                    onChange={(e) => handleChange(e, 'treatment_outcome', row.id)}
                    options={treatmentOutcome}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: <Typography className={classes.headerTxt}>Additional Comments</Typography>,
            field: 'comment',
            headerClassName: 'super-app-theme--header',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <TextField
                    fullWidth
                    disabled={!values['have_outpatient_psyc_treat']}
                    variant="outlined"
                    name={'outpatient_psychiatric_opt'}
                    placeholder={'Placeholder'}
                    size="small"
                    value={row.comment}
                    onChange={(e) => handleChange(e, 'comment', id)}
                />
            ),
        },
        {
            headerName: <></>,
            field: 'id',
            headerClassName: 'super-app-theme--header',
            width: 90,
            sortable: false,
            renderCell: ({ value, id, row }) =>
                values['have_outpatient_psyc_treat'] && (
                    <RenderAddOrRemove index={id} isEmpty={isEmptyRow(row)} />
                ),
        },
    ]

    const getOutPatientPsychiatricHistoryList = async () => {
        try {
            const resp = await apiOutpatientPsychiatricHistory.getAllbyPtientId(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data.data) {
                onChange(`${fieldName}.outpatient_psychiatric_opt`, resp.data.data)
            }
        } catch (ex) {
            notification('Cannot get list Out Patient Psychiatric', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.have_outpatient_psyc_treat) {
            getOutPatientPsychiatricHistoryList()
        }
    }, [patientId, values.have_outpatient_psyc_treat])

    console.log('values', values)
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography variant="h6" className={classes.sectionHeading}>
                    {questions['have_outpatient_psyc_treat']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.have_outpatient_psyc_treat`}
                        options={radioOptions}
                        value={
                            values?.have_outpatient_psyc_treat === undefined
                                ? ''
                                : values['have_outpatient_psyc_treat']
                                ? 'Yes'
                                : 'No'
                        }
                        onChange={(e) => {
                            const { name, value } = e.target

                            onChange(name, value === 'Yes')
                            onChange(`${fieldName}.narrative`, '')
                        }}
                    />
                </Box>
            </Box>
            {values['have_outpatient_psyc_treat'] && (
                <OutpationPsychiatricHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getOutPatientPsychiatricHistoryList}
                    nonEditable={nonEditable}
                />
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default OutpatientPsychiatricHistory
