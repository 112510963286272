import _ from "lodash";

const cleanUp = (value) => value ? value[0].trim() : undefined;

const extractFullName = (input) => {
  const fullName = input.replaceAll(`"`, `'`).match(/[A-Za-z "']{2,}/g)
  return cleanUp(fullName);
}

const extractYear = (input) => {
  const year = input.match(/(^|[ ])(19|20)\d{2}([ ]|$)/ig);
  return cleanUp(year);
}

const extractDate = (input) => {
  const date = input.match(/\d{2}\/\d{2}\/\d{4}/);
  if (!date) return;
  const parts = cleanUp(date).split("/");
  return `${parts[2]}-${parts[0]}-${parts[1]}`;
}

const extractPhone = (input) => {
  const phone = input.match(/\(\d{3}\) \d{3}-\d{4}/);
  if (!phone) return;
  return cleanUp(phone).replace(/\D/g, '');
}

const extractMRN = (input) => {
  const mrn = input.match(/\d{5,}/g);
  return cleanUp(mrn);
}

export const extractPatientSearchQuery = (input) => {
  const searchQuery = {
    ids: extractMRN(input),
  	year: extractYear(input),
    name: extractFullName(input),
    birthdate: extractDate(input),
    phone: extractPhone(input),
  }
  return _.pickBy(searchQuery, _.identity);
}
