const ArchivedWhiteIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                y="0.5"
                width="24"
                height="24"
                rx="5"
                fill="white"
            />
            <path
                d="M9 19.7168H15C16.1087 19.7168 16.7519 19.4142 17.1149 18.9345C17.4755 18.458 17.55 17.8182 17.55 17.1668V11.3135C17.55 11.0125 17.3009 10.7635 17 10.7635C16.6991 10.7635 16.45 11.0125 16.45 11.3135V17.1668C16.45 17.456 16.4375 17.6908 16.3994 17.8807C16.3615 18.0702 16.2987 18.2119 16.2008 18.3179C16.0044 18.5304 15.6507 18.6168 15 18.6168H9C8.34931 18.6168 7.99555 18.5304 7.79922 18.3179C7.70126 18.2119 7.63855 18.0702 7.60059 17.8807C7.56253 17.6908 7.55 17.456 7.55 17.1668V11.3135C7.55 11.0125 7.30095 10.7635 7 10.7635C6.69905 10.7635 6.45 11.0125 6.45 11.3135V17.1668C6.45 17.8182 6.52451 18.458 6.88513 18.9345C7.24813 19.4142 7.89134 19.7168 9 19.7168Z"
                fill="#667689"
                stroke="#667689"
                strokeWidth="0.1"
            />
            <path
                d="M7.33337 11.7168H16.6667C17.4823 11.7168 18.1217 11.4926 18.5571 11.0572C18.9925 10.6218 19.2167 9.9824 19.2167 9.16683V7.8335C19.2167 7.01792 18.9925 6.37854 18.5571 5.94314C18.1217 5.50775 17.4823 5.2835 16.6667 5.2835H7.33337C6.5178 5.2835 5.87841 5.50775 5.44302 5.94314C5.00762 6.37854 4.78337 7.01792 4.78337 7.8335V9.16683C4.78337 9.9824 5.00762 10.6218 5.44302 11.0572C5.87841 11.4926 6.5178 11.7168 7.33337 11.7168ZM5.88337 7.8335C5.88337 7.31248 5.99405 6.95353 6.22373 6.72385C6.45341 6.49417 6.81235 6.3835 7.33337 6.3835H16.6667C17.1877 6.3835 17.5467 6.49417 17.7764 6.72385C18.006 6.95353 18.1167 7.31248 18.1167 7.8335V9.16683C18.1167 9.68785 18.006 10.0468 17.7764 10.2765C17.5467 10.5062 17.1877 10.6168 16.6667 10.6168H7.33337C6.81235 10.6168 6.45341 10.5062 6.22373 10.2765C5.99405 10.0468 5.88337 9.68785 5.88337 9.16683V7.8335Z"
                fill="#667689"
                stroke="#667689"
                strokeWidth="0.1"
            />
            <path
                d="M10.7866 14.3835H13.2133C13.5142 14.3835 13.7633 14.1344 13.7633 13.8335C13.7633 13.5325 13.5142 13.2835 13.2133 13.2835H10.7866C10.4857 13.2835 10.2366 13.5325 10.2366 13.8335C10.2366 14.1344 10.4857 14.3835 10.7866 14.3835Z"
                fill="#667689"
                stroke="#667689"
                strokeWidth="0.1"
            />
        </svg>
    )
}

export default ArchivedWhiteIcon
