import * as React from 'react';

import { Card, Grid } from '@material-ui/core';
import './Card.scss';

export default function BasicCard(props) {
    const {
        icon,
        width,
        height,
        paddingLeft,
        paddingRight,
        name,
        headerActionView,
        children,
    } = props;
    return (
        <Card
            className="card crd-sx"
            style={{
                width: `${width}`,
                height: `${height}`,
                paddingLeft: `${paddingLeft}`,
                paddingRight: `${paddingRight}`,
            }}
        >
            <div className="card__header">
                <div className="card__header__title">
                    <img
                        src={icon}
                        className="card__header__title-icon"
                        alt={icon}
                    />
                    <span className="card__header__title-label">
                        {name}
                    </span>
                </div>

                <div className="card__header__action">
                    {headerActionView}
                </div>
            </div>

            <div className="card__body">{children}</div>
        </Card>
    );
}
