import { CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { Suspense, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'

import { getListInsurances } from 'services/Patient/patient'
import { usePatientDashboardStyles } from '../style'
import { Card } from '../view/Card'
import { NoData } from './NoData'
import { apiInsurrancePolicy } from 'services/Patient/apiInsurrancePolicy'
import { InsurancePlanForm } from '../view/Insurances/InsurancePlanForm'
import { InsuranceForm } from '../view/Insurances/InsuranceForm'
import { EditAction } from 'shared/elements/FormItem/EditAction'

interface Props {
    onClick?: () => void
}

const useStyles = makeStyles(() => ({
    type_box: {
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'gray',
        borderRadius: '50%',
        color: 'white',
        fontSize: 12,
        border: '1px solid gray',
    },
    viewall: {
        color: '#667689',
        '&:hover': {
            color: '#4e52d9',
            textDecoration: 'underline',
        },
    },
}))

export const Insurances = (props: Props) => {
    const classes = useStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const { idPatient: patientIdByRef } = useParams<{ idPatient: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [insurances, setInsurances] = useState([])
    const [loading, setLoading] = useState(false)
    const [paging] = useState<{
        page: number
        pageSize: number
    }>({
        page: 1,
        pageSize: 10,
    })

    const formattedMoney = (value) => {
        if (value) {
            if (value[0] === '$') return value
            return '$' + value
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        fetchListAllergies()

        return () => abortController.abort()
    }, [])

    const fetchListAllergies = async () => {
        try {
            setLoading(true)
            const resp = await apiInsurrancePolicy.getAll(patientId || patientIdByRef, {
                page: 1,
                size: 10,
            })
            const sortedTableList = resp?.data?.data?.sort((a, b) => {
                const typeOrder = { Primary: 1, Secondary: 2, Tertiary: 3 };
                return typeOrder[a.type] - typeOrder[b.type];
            });

            const formattedInsuranceList = sortedTableList?.filter((tableListItem) => tableListItem?.status === 'active')

            setInsurances(formattedInsuranceList)
        } catch (ex) {
            notification('Cannot get list insurance', 'error')
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.INSURANCES.MAIN.replace(':id', patientId || patientIdByRef))

    const Content = () => (
        <>
            {insurances.map((item, key) => (
                <Grid
                    container
                    key={key}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #EFF2F5',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        alignItems="center"
                        style={{
                            width: '100%',
                            minHeight: '24px',
                            display: 'flex',
                        }}>
                        {item?.payment_method?.code === 'Insurance' && (
                            <Typography
                                className={classes.type_box}
                                style={{
                                    background:
                                        item?.type === 'Primary'
                                            ? '#00c63d'
                                            : item?.type === 'Tertiary'
                                            ? '#ffcb1d'
                                            : '#5571c6',
                                }}>
                                {item?.type === 'Primary'
                                    ? 'P'
                                    : item?.type === 'Tertiary'
                                    ? 'T'
                                    : 'S'}
                            </Typography>
                        )}
                        {item?.payment_method?.code !== 'Insurance' && (
                            <Typography
                                style={{
                                    width: 20,
                                    height: 20,
                                    background: 'transparent',
                                }}></Typography>
                        )}
                        <Typography
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                marginLeft: item?.payment_method?.code ? 12 : 0,
                            }}>
                            {item?.payment_method?.display}
                        </Typography>
                        <Typography
                            style={{
                                color: '#9CA9B8',
                                fontSize: 12,
                                flex: 1,
                                marginLeft: 12,
                                marginRight: 12,
                                flexGrow: 1,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {item?.insurance?.plan?.display}
                        </Typography>
                        <Typography
                            style={{
                                color: '#9CA9B8',
                                fontSize: 12,
                                marginRight: 8,
                            }}>
                            {formattedMoney(item?.insurance?.copay_amount)}
                        </Typography>
                        <Grid item>
                            <EditAction
                                menu={[
                                    {
                                        text: 'Edit',
                                        handler: () => {
                                            history.push(
                                                Routes.PATIENT.INSURANCES.EDIT.replace(
                                                    ':id',
                                                    patientId || patientIdByRef,
                                                ).replace(':insuranceId', item?.id),
                                            )
                                        },
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}

            {!!insurances.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => {
                        history.push(
                            Routes.PATIENT.INSURANCES.CREATE.replace(
                                ':id',
                                patientId || patientIdByRef,
                            ),
                        )
                    },
                },
                {
                    name: 'View all',
                    handler: handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Insurance">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {insurances.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>
        </Card>
    )
}
