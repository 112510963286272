import React from 'react'
import { useHistory } from 'react-router-dom'
import UserSecurityDialog from 'containers/UserSecuritySettings/UserSecuritySettingsDialog'
import { useSelector } from 'react-redux'
import { Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
})

function ProfileContainer(props) {
    const history = useHistory()
    const classes = useStyles()
    const me = useSelector(
        (state) => state?.login?.userData,
    )

    return (
        <Grid item>
            <UserSecurityDialog
                isViewOnly={true}
                itemId={me?.['custom:user_id']}
            />
        </Grid>
    )
}
export default ProfileContainer
