import React from 'react'
import { Search } from '@material-ui/icons'
import { TextField } from '@material-ui/core'
import './search.scss'
const SearchBar = (props) => {
    return (
        <React.Fragment>
            <div className="con">
                <div className="serc-input">
                    <Search className="search-box" />
                    <TextField
                        id="MuiOutlinedInput-input-1"
                        spacing={2}
                        className="search-sx"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                color: '#516983 !important',
                            },
                        }}
                        placeholder="search"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default SearchBar
