import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
    Modal,
    Grid,
    makeStyles,
    Typography,
    TextField,
    Button,
} from '@material-ui/core'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import MomentUtils from '@date-io/moment'
import LoadingButton from '@mui/lab/LoadingButton'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'
import SuccessModal from '../../../components/SuccessModal'
import ErrorModal from '../../../components/ErrorModal'
import {
    setIsSuccessCreateAddendum,
    setErrorCreateAddendum,
} from '../../../store/actions/clinicalNotes'
import {
    createAddendumByClinicalNote,
    getAllAddendumByClinicalNote,
} from '../../../store/actions/clinicalNotes'
import { clinical_note_master } from '../utils'
import { cgiMapper, devEduHistroyMapper, familyEnvMapper, interVationMapper, mapperFunction, sessionMapper } from '../Behavioral'

const useStyles = makeStyles({
    createAddendumContainer: {
        width: '450px',
        height: '251px',
        borderRadius: '8px',
        backgroundColor: '#ffff',
        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 10%)',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        outline: '0',
        fontFamily: 'Rubik',
    },
    createAddendumWrapper: {
        margin: '24px',
    },
    header: {
        fontSize: '16px',
        width: '100%',
        justifyContent: 'center',
        fontWeight: 500,
        color: '#303E4E',
        textAlign: 'center',
    },
    buttonWrapper: {
        marginTop: '24px',
    },
    btnCancel: {
        width: '196px',
        height: '40px',
        fontSize: '15px',
        backgroundColor: '#303E4E',
        color: '#FFFFFF',
        marginRight: '10px',
        borderRadius: '8px',
        fontWeight: '400 !important',
    },
    btnSave: {
        width: '196px',
        height: '40px',
        fontSize: '15px !important',
        fontFamily: 'Rubik !important',
        backgroundColor: '#5571C6 !important',
        color: '#FFFFFF',
        borderRadius: '8px !important',
        textTransform: 'none !important',
        fontWeight: '400 !important',
    },
    description: {
        width: '97%',
        height: '85px !important',
        fontFamily: 'Rubik',
        padding: '10px 0 10px 10px',
        background: '#FBFCFC',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        resize: 'none',
        marginTop: '16px',
        fontSize: '13px',
    },
    dateWrapper: {
        width: '100%',
        height: '40px',
    },
})

const CreateAddendumModal = ({
    isOpen,
    setIsOpen,
    // createAddendum,
    clinicalNoteData,
    newValue,
    categoryNotes
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [description, setDescription] = useState('')
    const [isOpenSuccessModal, setIsOpenSuccessModal] =
        useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [isOpenErrorModal, setIsOpenErrorModal] =
        useState(false)

    const isSuccessCreateAddendum = useSelector(
        (state) =>
            state.clinicalNotes.isSuccessCreateAddendum,
    )
    const isLoadingAddendum = useSelector(
        (state) => state.clinicalNotes.isLoadingAddendum,
    )
    const errorCreateAddendum = useSelector(
        (state) => state.clinicalNotes.errorCreateAddendum,
    )

    const handleChangeDescription = (event) => [
        setDescription(event.target.value),
    ]

    const closeSuccessModal = () => {
        setIsOpenSuccessModal(false)
        setIsOpen(false)
        dispatch(
            getAllAddendumByClinicalNote({
                clinicalNoteId:
                    clinicalNoteData?.id || '422',
            }),
        )
    }

    useEffect(() => {
        if (isSuccessCreateAddendum) {
            setIsOpenSuccessModal(true)
            setSuccessMessage(
                'You have created addendum successfully!',
            )
            dispatch(setIsSuccessCreateAddendum(false))
        }
    }, [isSuccessCreateAddendum])

    useEffect(() => {
        if (errorCreateAddendum) {
            setIsOpenErrorModal(true)
        }
    }, [errorCreateAddendum])

    const onClose = () => {
        setIsOpen(false)
    }
    const onSave = () => {
        const cloneCategoryNotes = _.cloneDeep(newValue)
        if (cloneCategoryNotes.system_reviews) {
            const systemReview = mapperFunction(clinical_note_master.systemReviewMasters, cloneCategoryNotes.system_reviews.system_reviews_opt)
            cloneCategoryNotes.system_reviews['system_reviews_opt'] = systemReview
        }
        if (cloneCategoryNotes?.dev_and_edu_history) {
            const devEduHistry = devEduHistroyMapper(clinical_note_master.devEduHistoryMasters, cloneCategoryNotes.dev_and_edu_history)
            cloneCategoryNotes.dev_and_edu_history = devEduHistry
        }
        if (cloneCategoryNotes?.family_history) {

            if (!cloneCategoryNotes?.family_history?.psych_illness_history) {
                cloneCategoryNotes.family_history.history_list = []
            }
        }
        if (cloneCategoryNotes?.interven_utilized) {
            const intervationMapperprepare = interVationMapper(clinical_note_master.interventionMasters, cloneCategoryNotes.interven_utilized.interventions)
            cloneCategoryNotes.interven_utilized['interventions'] = intervationMapperprepare
        }

        if (cloneCategoryNotes?.session_focus) {
            const sessionmapperPrepare = sessionMapper(clinical_note_master.sessionFocusMasters, cloneCategoryNotes.session_focus.session)
            cloneCategoryNotes.session_focus['session'] = sessionmapperPrepare
        }

        if (cloneCategoryNotes?.clinical_global_impression) {
            const cgi = cgiMapper(clinical_note_master.clinicalImpMasters, cloneCategoryNotes.clinical_global_impression)
            cloneCategoryNotes.clinical_global_impression = cgi
        }

        if (cloneCategoryNotes?.family_env_strategy) {
            const familyEnvMapperPrepare = familyEnvMapper(clinical_note_master.envStrategyMasters, cloneCategoryNotes?.family_env_strategy.fam_env_strategy)
            cloneCategoryNotes.family_env_strategy['fam_env_strategy'] = familyEnvMapperPrepare
        
        }
        const payload = {
            description,
            date: moment().format().split('T')[0],
            clinicalNoteId: clinicalNoteData?.id || '422',
            first_name: '',
            last_name: localStorage.getItem('name'),
            clinicalNoteData: {
                ...categoryNotes,
                clinicalNoteModel: cloneCategoryNotes,
            }
        }
        dispatch(createAddendumByClinicalNote(payload))
    }
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                setIsOpen(false)
            }}>
            <Grid
                className={classes.createAddendumContainer}>
                <Grid
                    className={
                        classes.createAddendumWrapper
                    }>
                    <Grid className={classes.headerWrapper}>
                        <Typography
                            className={classes.header}>
                            New Addendum
                        </Typography>
                    </Grid>
                    <Grid
                        className={
                            classes.descriptionWrapper
                        }>
                        <TextareaAutosize
                            className={classes.description}
                            name="description"
                            minRows={1}
                            maxRows={5}
                            onChange={
                                handleChangeDescription
                            }
                            placeholder="Description"
                        />
                    </Grid>
                    <Grid className={classes.buttonWrapper}>
                        <Button
                            onClick={onClose}
                            className={classes.btnCancel}>
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={onSave}
                            loading={isLoadingAddendum}
                            variant="contained"
                            loadingIndicator={
                                <SpinnerIcon />
                            }
                            className={classes.btnSave}>
                            Save
                        </LoadingButton>
                    </Grid>
                    <SuccessModal
                        isOpen={isOpenSuccessModal}
                        closeModal={closeSuccessModal}
                        message={successMessage}
                    />
                    {errorCreateAddendum && (
                        <ErrorModal
                            isOpen={isOpenErrorModal}
                            setIsOpen={setIsOpenErrorModal}
                            errorMessage={
                                errorCreateAddendum?.data
                            }
                        />
                    )}
                </Grid>
            </Grid>
        </Modal>
    )
}

export default CreateAddendumModal
