import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Menu,
} from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as WarningIcon } from 'assests/icons/warningIcon.svg'
import moment from 'moment'
import { convertValues } from './utils/unitConvertions'
import { MenuItem } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    vitalsTable: {
        '& .MuiTableHead-root .MuiTableCell-root': {
            fontFamily: 'Rubik',
            fontSize: 14,
            fontWeight: 500,
            color: '#000'
        },
        '& .MuiTableBody-root .MuiTableCell-root': {
            fontFamily: 'Rubik',
            fontSize: 14,
            fontWeight: 400
        }
    }
}))

const data = [
    {
        date: '06/01/2023',
        temp: '103.4',
        bp: '120/80',
        ox: '95%',
        respRate: '16 per min.',
        height: '68 in.',
        weight: '168 lbs',
        bmi: '18.5',
    },
    {
        date: '05/01/2023',
        temp: '103.4',
        bp: '120/80',
        ox: '95%',
        respRate: '16 per min.',
        height: '68 in.',
        weight: '168 lbs',
        bmi: '18.5',
    },
    {
        date: '03/01/2023',
        temp: '103.4',
        bp: '120/80',
        ox: '95%',
        respRate: '16 per min.',
        height: '68 in.',
        weight: '168 lbs',
        bmi: '18.5',
        alert: true,
    },
    {
        date: '05/01/2023',
        temp: '103.4',
        bp: '120/80',
        ox: '95%',
        respRate: '16 per min.',
        height: '68 in.',
        weight: '168 lbs',
        bmi: '18.5',
    },
]

const VitalSignsTable = ({ 
    vitalRecords, 
    tempUnit, 
    heightUnit, 
    weightUnit, 
    handleToggleTemparature, 
    handleToggleHeight, 
    handleToggleWeight 
}) => {
    const classes = useStyles()
    const [tableData, setTableData] = React.useState([])
    const [openToggleMenu, setOpenToggleMenu] = React.useState({
        temperature: null,
        height: null,
        weight: null
    })

    const handleToggleMenuOpen = (fieldName, value = null) => {
        setOpenToggleMenu((prevValue) => ({
            ...prevValue,
            [fieldName]: value
        }))
    }

    const flattenRecords = () => {
        return vitalRecords?.map((item) => {
            return {
                date: moment(item?.encounter?.start)?.format('MM/DD/YYYY'),
                temp: convertValues(item?.temperature?.value, tempUnit === 'F' ? '°F' : '°C', 'temperature') || "----",
                bp: `${item?.blood_pressure?.systolicValue || "----"}/${item?.blood_pressure?.diastolicValue || "----"}`,
                ox: item?.pulse_oximetry?.value,
                respRate: item?.respiratory_rate?.value,
                height: `${convertValues(item?.height?.value, heightUnit, 'height') || '----'} ${heightUnit}.`,
                weight: `${convertValues(item?.weight?.value, weightUnit, 'weight') || '----'} ${weightUnit}`,
                bmi: item?.bmi?.value || '----',
                bp_systolic: item?.blood_pressure?.systolicValue,
                bp_diastolic: item?.blood_pressure?.diastolicValue,
            }
        })
    }

    React.useEffect(() => {
        setTableData(flattenRecords())
    }, [
        vitalRecords,
        tempUnit,
        weightUnit,
        heightUnit
    ])

    return (
        <TableContainer
            style={{
                maxHeight: 230,
                marginTop: 20,
                boxShadow: 'none',
                border: '1px solid #e0e0e0',
            }}
            component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="health table" stickyHeader className={classes.vitalsTable}>
                <TableHead
                    style={{ background: '#f8f9fb', position: 'relative', zIndex: 1, top: 0 }}>
                    <TableRow>
                        <TableCell style={{ background: '#f8f9fb', fontWeight: 'bold' }}>Date</TableCell>
                        <TableCell  onClick={(event) => handleToggleMenuOpen('temperature', event?.currentTarget)} style={{ background: '#f8f9fb', fontWeight: 'bold' }}>
                            Temperature, {tempUnit}
                        </TableCell>
                        <TableCell style={{ background: '#f8f9fb', fontWeight: 'bold' }}>Blood pressure</TableCell>
                        <TableCell style={{ background: '#f8f9fb', fontWeight: 'bold' }}>Pulse OX</TableCell>
                        <TableCell style={{ background: '#f8f9fb', fontWeight: 'bold' }}>Respiratory rate</TableCell>
                        <TableCell onClick={(event) => handleToggleMenuOpen('height', event?.currentTarget)} style={{ background: '#f8f9fb', fontWeight: 'bold' }}>
                            Height, {heightUnit}
                        </TableCell>
                        <TableCell onClick={(event) => handleToggleMenuOpen('weight', event?.currentTarget)} style={{ background: '#f8f9fb', fontWeight: 'bold' }}>
                            Weight, {weightUnit}
                        </TableCell>
                        <TableCell style={{ background: '#f8f9fb', fontWeight: 'bold' }}>BMI</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData?.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: row?.alert ? '#E81A1A' : '#000' }}>
                                    {row.temp}
                                    {row.alert && (
                                        <WarningIcon style={{ color: 'red', marginLeft: 5 }} />
                                    )}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: (row?.bp_systolic < 90 || row?.bp_systolic > 140 || row?.bp_diastolic < 60 || row?.bp_diastolic > 90) ? '#E81A1A' : '#000'  }}>
                                    {row.bp}
                                    {(row?.bp_systolic < 90 || row?.bp_systolic > 140 || row?.bp_diastolic < 60 || row?.bp_diastolic > 90) && (
                                        <WarningIcon style={{ color: 'red', marginLeft: 5 }} />
                                    )}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: row?.ox <= 85 ? '#E81A1A' : '#000'  }}>
                                    {row.ox || '----'}%
                                    {row?.ox <= 85 && (
                                        <WarningIcon style={{ color: 'red', marginLeft: 5 }} />
                                    )}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: (row.respRate > 20 || row.respRate < 12) ? '#E81A1A' : '#000'  }}>
                                    {row.respRate || '----'} per min.
                                    {(row.respRate > 20 || row.respRate < 12) && (
                                        <WarningIcon style={{ color: 'red', marginLeft: 5 }} />
                                    )}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: row?.alert ? '#E81A1A' : '#000'  }}>
                                    {row.height}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: row?.alert ? '#E81A1A' : '#000'  }}>
                                    {row.weight}
                                </div>
                            </TableCell>

                            <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', color: (row.bmi < 18.5 || row.bmi > 24.9) ? '#E81A1A' : '#000'  }}>
                                    {row.bmi}
                                    {(row.bmi < 18.5 || row.bmi > 24.9) && (
                                        <WarningIcon style={{ color: 'red', marginLeft: 5 }} />
                                    )}
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={openToggleMenu?.temperature}
                open={Boolean(openToggleMenu?.temperature)}
                onClose={() => handleToggleMenuOpen('temperature')}
            >
                <MenuItem
                    onClick={() => {
                        handleToggleTemparature('C')
                        handleToggleMenuOpen('temperature')
                    }} 
                    disableRipple
                >C</MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleToggleTemparature('F')
                        handleToggleMenuOpen('temperature')
                    }} 
                    disableRipple
                >F</MenuItem>
            </Menu>

            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={openToggleMenu?.height}
                open={Boolean(openToggleMenu?.height)}
                onClose={() => handleToggleMenuOpen('height')}
            >
                <MenuItem 
                    onClick={() => {
                        handleToggleHeight('ft/in')
                        handleToggleMenuOpen('height')
                    }} 
                    disableRipple
                >Ft/In</MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleToggleHeight('in')
                        handleToggleMenuOpen('height')
                    }} 
                    disableRipple
                >In</MenuItem>
            </Menu>

            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={openToggleMenu?.weight}
                open={Boolean(openToggleMenu?.weight)}
                onClose={() => handleToggleMenuOpen('weight')}
            >
                <MenuItem 
                    onClick={() => {
                        console.log('283=>','passing')
                        handleToggleWeight('lbs')
                        handleToggleMenuOpen('weight')
                    }} 
                    disableRipple
                >Lbs</MenuItem>
                <MenuItem 
                    onClick={() => {
                        handleToggleWeight('kg')
                        handleToggleMenuOpen('weight')
                    }} 
                    disableRipple
                >Kg</MenuItem>
            </Menu>
        </TableContainer>
    )
}

export default VitalSignsTable
