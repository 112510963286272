import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useState } from 'react'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'

const useStyles = makeStyles({
    typo_line_tab: {
        color: '#303E4E',
        cursor: 'pointer',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        fontWeight: 500,
    },
    line_tab: {
        color: '#9CA9B8',
        cursor: 'pointer',
        fontSize: '14px !important',
        marginBottom: '8px',
        padding: '6px 12px',
        borderBottom: '1px solid #F3F6F9',
    },
    active: {
        color: '#5571c6',
    },
    box: {
        height: 120,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
})

const HealthyRecordTab = ({ activeView, handleSetViewHealthyRecord }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    return (
        <Grid
            // className={}
            direction={'column'}
            container
            wrap="nowrap"
            style={{ marginBottom: 12 }}>
            <Grid
                container
                justifyContent="space-between"
                alignItem="center"
                style={{
                    padding: '6px 0',
                    marginBottom: '8px',
                    borderBottom: '1px solid #F3F6F9',
                }}
                onClick={() => setOpen(!open)}>
                <Typography className={classes.typo_line_tab}>Health record</Typography>
                {open ? (
                    <KeyboardArrowDownRoundedIcon
                        style={{
                            color: '#516983',
                        }}
                    />
                ) : (
                    <KeyboardArrowUpRoundedIcon
                        style={{
                            color: '#516983',
                        }}
                    />
                )}
            </Grid>

            {open && (
                <Grid container className={classes.box} direction={'column'} wrap="nowrap">
                    {[
                        { header: 'Medication Allergy', id: 'medication_allergy' },
                        { header: 'Medical Problems', id: 'medication_problems' },
                        { header: 'Medications', id: 'medications' },
                    ].map((item) => (
                        <Grid key={item?.id} onClick={() => handleSetViewHealthyRecord(item.id)}>
                            <Typography
                                className={clsx({
                                    [classes.active]: activeView?.includes(item.id),
                                    [classes.line_tab]: true,
                                })}>
                                {item?.header}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    )
}

export default HealthyRecordTab
