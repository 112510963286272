import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import {
    DAY_SUPPLY,
    DRUG_TYPE,
    FREQUENCY,
    OTHER,
    PATIENT_DIRECTIONS,
    ROUTE,
    SCHEDULED_DRUG,
    SUBSTITUTION_PERMITTED,
} from 'containers/PatientsSetting/MedicationsCreateDialog/constant'
import { Formik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { createMedication, getMedicationById, updateMedication } from 'services/Patient/patient'
import { ModalEl } from 'shared/elements'
import { diagnosisOptions, INITIAL_VALUES } from './schema'
import './styles.scss'

import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'

import { convertToFixedDate } from 'shared/utils'
import { usePatient } from 'containers/PatientsSetting/hooks'
import Staff from 'containers/AppointmentBooking/Views/Staff'
import FormMedication from './FormMedication'

const getValidateSchema = ({ patientInfo }) => {
    const validateSchema = Yup.object().shape({
        strength: Yup.string().required('Strength is required'),
        drug: Yup.object({
            display: Yup.string().required('Medication is required'),
            branchName: Yup.string(),
        }),
        form: Yup.string().required('Form is required'),
        start_date: Yup.date()
            .nullable()
            .typeError('Invalid date')
            .min(moment(patientInfo.dob), "Start date must be after the patient's date of birth")
            .max(moment(), 'Start date must be before current date'),
        stop_date: Yup.date()
            .nullable()
            .typeError('Invalid date')
            .test({
                name: 'min-stop_date',
                exclusive: false,
                params: {},
                test(value, context) {
                    if (!value) return true
                    const stopDate = moment(value)
                    if (!context.parent.start_date) {
                        if (stopDate < moment(patientInfo.dob)) {
                            return this.createError({
                                message: "Stop date must be after the patient's date of birth",
                                path: 'stop_date',
                            })
                        }
                        return true
                    }
                    if (stopDate < moment(context.parent.start_date)) {
                        return this.createError({
                            message: 'Stop date must be after start date',
                            path: 'stop_date',
                        })
                    }
                    return true
                },
            }),
        last_written_date: Yup.date()
            .nullable()
            .typeError('Invalid date')
            .test({
                name: 'min-last_written_date',
                exclusive: false,
                params: {},
                test(value, context) {
                    if (!value) return true
                    const lastWrittentDate = moment(value)
                    if (!context.parent.start_date) {
                        if (lastWrittentDate < moment(patientInfo.dob)) {
                            return this.createError({
                                message:
                                    "Last written date must be after the patient's date of birth",
                                path: 'last_written_date',
                            })
                        }
                        return true
                    }
                    if (lastWrittentDate < moment(context.parent.start_date)) {
                        return this.createError({
                            message: 'Last written date must be after start date',
                            path: 'last_written_date',
                        })
                    }
                    return true
                },
            })
            .test({
                name: 'max-last_written_date',
                exclusive: false,
                params: {},
                test(value, context) {
                    if (!value) return true
                    const lastWrittenDate = moment(value)
                    if (!context.parent.stop_date) {
                        if (lastWrittenDate > moment()) {
                            return this.createError({
                                message: 'Last written date must be before current date',
                                path: 'last_written_date',
                            })
                        }
                        return true
                    }
                    if (lastWrittenDate > moment(context.parent.stop_date)) {
                        return this.createError({
                            message: 'Last written date must be before stop date',
                            path: 'last_written_date',
                        })
                    }
                    return true
                },
            }),
    })

    return validateSchema
}

function MedicationsCreateDialog({
    patientId,
    itemId,
    refetchData,
    encounter_id,
    afterActionOk,
    completeAction,
    handleClose,
    ...props
}) {
    const notification = useNotification()

    const { patientInfo } = usePatient(patientId)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [initData, setInitData] = useState({
        ...INITIAL_VALUES,
        start_date: null,
        stop_date: null,
        last_written_date: null,
    })
    const [loadingSchedule, setLoadingSchedule] = useState(false)

    useEffect(() => {
        if (itemId) {
            getAMedication(itemId)
        }
    }, [itemId])

    async function getAMedication(idEvent) {
        try {
            setLoadingSchedule(true)
            const response = await getMedicationById(patientId, idEvent)
            if (response?.data) {
                const {
                    other,
                    route,
                    method,
                    frequency,
                    day_supply,
                    schedule_drug,
                    substitution,
                    drugType,
                    start_date,
                    stop_date,
                    last_written_date,
                    action,
                } = response?.data
                setInitData({
                    ...INITIAL_VALUES,
                    ...response?.data,
                    other: other?.code,
                    route: route?.code,
                    method: method?.code,
                    frequency: frequency?.code,
                    day_supply: day_supply?.code,
                    substitution: substitution?.code,
                    schedule_drug: schedule_drug?.code,
                    drugType: drugType?.code,
                    action: action?.code,
                    start_date: start_date ? moment(start_date) : null,
                    stop_date: stop_date ? moment(stop_date) : null,
                    last_written_date: last_written_date ? moment(last_written_date) : null,
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }

    async function handleCreate(values) {
        const {
            drugType,
            schedule_drug,
            action,
            route,
            frequency,
            other,
            day_supply,
            substitution,
            start_date,
            stop_date,
            last_written_date,
            quantity,
            refills,
        } = values
        const startDate = convertToFixedDate(moment(start_date))
        const stopDate = convertToFixedDate(moment(stop_date))
        const lastWritttenDate = convertToFixedDate(moment(last_written_date))
        const payload = {
            ...values,
            patient_id: patientId,
            encounter_id,
            drugType: convertDataToPayload(DRUG_TYPE, drugType),
            schedule_drug: convertDataToPayload(SCHEDULED_DRUG, schedule_drug),
            action: convertDataToPayload(PATIENT_DIRECTIONS, action),
            route: convertDataToPayload(ROUTE, route),
            frequency: convertDataToPayload(FREQUENCY, frequency),
            other: convertDataToPayload(OTHER, other),
            day_supply: convertDataToPayload(DAY_SUPPLY, day_supply, 'number'),
            substitution: convertDataToPayload(SUBSTITUTION_PERMITTED, substitution),
            start_date: start_date && startDate,
            stop_date: stop_date && stopDate,
            last_written_date: last_written_date && lastWritttenDate,
            quantity: parseInt(quantity || 0, 10),
            refills: parseInt(refills || 0, 10),
        }

        if (itemId) {
            setIsSubmitting(true)
            updateMedication(patientId, itemId, payload)
                .then(() => {
                    setIsSubmitting(false)
                    refetchData()
                    afterActionOk?.()
                    completeAction?.()
                    notification('A medication was successfully updated!', 'success')
                })
                .catch((error) => {
                    setIsSubmitting(false)
                    notification(
                        error?.data?.message || 'A medication was not successfully updated!',
                        'error',
                    )
                })
        } else {
            setIsSubmitting(true)
            createMedication(patientId, payload)
                .then(() => {
                    setIsSubmitting(false)
                    refetchData()
                    afterActionOk?.()
                    completeAction?.()
                    notification('A medication was successfully created!', 'success')
                })
                .catch((error) => {
                    setIsSubmitting(false)
                    notification(
                        error?.data?.message || 'A medication was not successfully created!',
                        'error',
                    )
                })
        }
    }

    return (
        <Grid container style={{marginTop:'10px'}}>
            <Loading visible={loadingSchedule} />
            <Formik
                onSubmit={handleCreate}
                validationSchema={getValidateSchema({ patientInfo })}
                initialValues={initData}
                enableReinitialize>
                {({ values, setFieldValue, errors, touched, setFieldError, handleSubmit }) => {
                    return (
                        <div>
                            <Grid container item>
                                <FormMedication
                                    itemId={itemId}
                                    patientId={patientId}
                                    values={values}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    setFieldError={setFieldError}
                                    patientInfo={patientInfo}
                                    loadingSchedule={loadingSchedule}
                                    isSubmitting={isSubmitting}
                                    handleSubmit={handleSubmit}
                                    handleClose={handleClose}
                                />
                            </Grid>
                        </div>
                    )
                }}
            </Formik>
        </Grid>

    )
}

export default MedicationsCreateDialog

const convertDataToPayload = (options, value, newKey) => {
    const item = options?.find((i) => i.key === value)
    if (item?.key) {
        if (newKey) {
            return {
                code: item.key,
                display: item.value,
                [newKey]: parseInt(item.key, 10),
            }
        }
        return {
            code: item.key,
            display: item.value,
        }
    }
    return ''
}
