import React, { useState } from 'react';
import Draggable from 'react-draggable';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { chatWindowStyle as useStyles } from './styles';
import SendIcon from '../../assets/submitchat.svg';
import ChatInput from './ChatInput/ChatInput';
import Chats from './Chats/Chats';
import MessageList from './MessageList/MessageList';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import close from '../../assets/close.png';
import {
    Dialog,
    DialogContent,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
export default function ChatWindow() {
    const classes = useStyles();
    const {
        isChatWindowOpen,
        messages,
        conversation,
        setIsChatWindowOpen,
    } = useChatContext();
    const [text, setText] = useState('');
    const patientImage =
        'https://i.pinimg.com/564x/36/60/58/366058cd421e6a981e50c6f800abbbd0.jpg';
    const doctorImage =
        'https://i.pinimg.com/736x/b4/ea/c6/b4eac6d67645f2b6e1d1a440e42cca57.jpg';
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );
    return (
        <>

            <div className={classes.chatBox}>
                <div
                    className={
                        classes.chatWindowContainer
                    }
                >
                    <div
                        className={
                            classes.chatWindowContainerTop
                        }
                    >
                        <Typography
                            className={
                                classes.chatWindowContainerTopName
                            }
                        >
                            Message
                        </Typography>
                        <button
                            onClick={() =>
                                setIsChatWindowOpen(
                                    false,
                                )
                            }
                            className={
                                classes.chatWindowContainerTopToggle
                            }
                        >
                            {
                                //@ts-ignore
                            }
                        <HighlightOffRoundedIcon style={{
                            color: 'rgba(48, 62, 78, 1)', fontSize: 15,
                        }}/>
                        </button>
                    </div>

                    <div
                        className={
                            classes.chatWindowBottomChats
                        }
                    >
                        <MessageList
                            messages={messages}
                        />
                    </div>

                    <ChatInput
                        conversation={conversation!}
                        isChatWindowOpen={
                            isChatWindowOpen
                        }
                    />
                </div>
            </div>
               
        </>
    );
}
