import React, { useEffect } from 'react'
import { Box, Typography, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import CheckboxOption from '../reusable/CheckboxOptions'
import TextWithMacro from '../reusable/TextWithMacro'
import RadioOptions from '../reusable/RadioOption'
import { SelectEl } from '../../../shared/elements'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'

const useStyles = makeStyles({
    container: {
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
        marginBottom: 10,
        marginTop: 16,
    },
    sectionGridContainer: {},
    textFieldWrap: {
        marginLeft: 16,
    },
    divider: {
        background: '#E9ECF1',
        height: 1,
    },
})

const mapSections = (examMaster = [], property) => {
    return examMaster.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}



const getEducationOptions = (master = []) => {
    return master
        .filter((m) => m.section_name === 'highest_edu_level')
        .map((m) => ({ key: m._id, value: m.name }))
}

const DevelopmentAndEducationalHistory = ({ fieldName, master, values, onChange, macros, nonEditable=false }) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable },
        { label: 'No', value: 'No', disable: nonEditable },
    ]
    const classes = useStyles()
    const sections = mapSections(master?.devEduHistoryMasters, 'section_name')

    const educationOptions = getEducationOptions(master?.devEduHistoryMasters)

    const handleInputChange = (e, id, se, key) => {
        const { name, value, checked, type } = e.target

        if (type === 'checkbox') {
            let updatedValue = values[name]?.options ? [...values[name].options] : []
            if (checked) {
                if (se.is_none) {
                    updatedValue = [se._id]
                } else {
                    const isNone = sections[key].find((item) => item.is_none)
                    if (isNone) {
                        updatedValue = [...updatedValue, id].filter((id) => id !== isNone._id)
                    } else {
                        updatedValue = [...updatedValue, id]
                    }
                }
            } else {
                updatedValue = updatedValue.filter((uv) => uv !== value)
            }

            onChange(`${fieldName}.${name}.options`, updatedValue)
        }

        if (type === 'text') {
            onChange(`${fieldName}.${name}.other`, value)
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            {Object.keys(sections).map((key, keyIndex) => {
                const sectionElements = sections[key]
                return (
                    <Box key={keyIndex}>
                        {keyIndex === 1 && (
                            <Box
                                sx={{
                                    display: 'grid',
                                    gap: 5,
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}>
                                <Box className={classes.divider} />
                                <Typography className={classes.sectionHeading}>
                                    {questions['complic_after_your_birth']}
                                </Typography>
                                <Box>
                                    <RadioOptions
                                        name={`${fieldName}.complic_after_your_birth`}
                                        options={radioOptions}
                                        value={values['complic_after_your_birth'] === "" ? '' : values['complic_after_your_birth'] ? 'Yes' : 'No'}
                                        onChange={(e) => {
                                            const { name, value } = e.target

                                            onChange(name, value === 'Yes')
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                        {keyIndex !== 0 && <Box className={classes.divider} />}
                        <Typography className={classes.sectionHeading}>{questions[key]}</Typography>
                        {key === 'highest_edu_level' ? (
                            <Box
                                sx={{
                                    marginBottom: 20,
                                    marginTop: 20,
                                }}>
                                <SelectEl
                                    name={`${fieldName}.highest_edu_level`}
                                    value={values?.highest_edu_level || ''}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        onChange(name, value)
                                    }}
                                    disabled={nonEditable}
                                    options={educationOptions}
                                    placeholder="Select One"
                                />
                            </Box>
                        ) : (
                            <Grid container className={classes.sectionGridContainer}>
                                {sectionElements.map((se, index) => {
                                    const iseSelected = values[key]?.options?.includes(se._id)
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                minWidth: '33%',
                                            }}>
                                            <CheckboxOption
                                                name={`${se.section_name}`}
                                                option={{
                                                    value: se._id,
                                                    label: se.name,
                                                }}
                                                disabled={nonEditable}
                                                onChange={(e) => {
                                                    handleInputChange(e, se._id, se, key)
                                                }}
                                                checked={iseSelected ? true : false}
                                            />
                                        </Box>
                                    )
                                })}
                                {!['childhood_challenges', 'have_prob_in_school'].includes(key) && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}>
                                        <Typography>Other:</Typography>
                                        <TextFieldDebounce
                                            placeholder=""
                                            variant="outlined"
                                            name={`${key}`}
                                            className={classes.textFieldWrap}
                                            size="small"
                                            value={values[key]?.other}
                                            onChange={(e) => {
                                                handleInputChange(e)
                                            }}
                                            disabled={nonEditable}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        )}
                    </Box>
                )
            })}
            <Box
                sx={{
                    display: 'flex',
                    gap: 20,
                    flexDirection: 'column',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['comment_on_dev_and_edu']}
                </Typography>
                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.comment_on_dev_and_edu`}
                    onChange={onChange}
                    macros={macros}
                    values={values.comment_on_dev_and_edu}
                    isDisabled={nonEditable}
                />
            </Box>
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: master?.devEduHistoryMasters,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default DevelopmentAndEducationalHistory
