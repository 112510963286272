import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { OutpatientPsychiatricHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { OutpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import { OutpatientPsychiatricForm } from './OutpatientPsychiatricForm'
import { apiOutpatientPsychiatricHistory } from 'services/Patient/apiOutpatientPsychiatricHistory'
import { useNotification } from 'shared/elements/Notification'

const OutpationPsychiatricHistory = ({
    isInClinicalNote,
    patient_Id,
    encounter_id,
    afterActionOk,
    nonEditable
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    // State
    const [outpatientPsychiatricId, setOutpatientPsychiatricId] = useState<string | number | false>(
        false,
    )
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState(false)
    const [outpatientPsychiatric, setOutpatientPsychiatric] = useState<OutpatientPsychiatric[]>([])
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })

    const [sortModel, setSortModel] = useState<GridSortModel>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const getOutPatientPsychiatricHistoryList = async (patientId) => {
        try {
            setLoading(true)
            const res = await apiOutpatientPsychiatricHistory.getAllbyPtientId(patientId, {
                page,
                size: limit,
            })
            if (res.data) {
                setOutpatientPsychiatric(res.data.data as OutpatientPsychiatric[])
                setTotal(res.data.paging.total)
                // if (!res.data.data.length && res.data.paging.total > 0 && page > 0) {
                //     setPage(page - 1)
                // }
            }
        } catch (error) {
            notification('Cannot get list outpatientPsychiatric', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleChangPage = (page) => {
        setPage(page)
    }

    const handleDelete = async (outpatientPsychiatricId) => {
        try {
            const res = await apiOutpatientPsychiatricHistory.deleteOutpatientPsychiatric(
                patientId,
                outpatientPsychiatricId,
            )
            if (res.data?.Status === 'SUCCESSED') {
                notification('Outpatient Psychiatric History deleted successfully', 'success')
                await getOutPatientPsychiatricHistoryList(patientId)
                afterActionOk?.()
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    const handleEdit = (id) => {
        setIsAddingNew(true)
        setOutpatientPsychiatricId(id)
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getOutPatientPsychiatricHistoryList(patientId)
        }

        return () => abortController.abort()
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: isInClinicalNote ? 0 : '16px 24px' }}>
            <Loading visible={loading} />

            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}
            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent={isInClinicalNote ? 'flex-end' : 'space-between'}>
                    {!isInClinicalNote && (
                        <Typography className={classes.header}>
                            Outpatient Psychiatric History
                        </Typography>
                    )}

                    <ButtonCustom
                        tooltip="Add New Outpatient Psychiatric History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setIsAddingNew(true)}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <OutpatientPsychiatricHistoryDashboard
                    onPageChange={handleChangPage}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    page={page}
                    dataSource={outpatientPsychiatric}
                    pagination={{ start: page, limit, total }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    nonEditable={nonEditable}
                />
                {isAddingNew && (
                    <OutpatientPsychiatricForm
                        outpatientPsychiatricId={outpatientPsychiatricId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        open={isAddingNew}
                        onClose={() => {
                            setIsAddingNew(false)
                        }}
                        onOke={async () => {
                            setIsAddingNew(false)
                            setOutpatientPsychiatricId(false)
                            await getOutPatientPsychiatricHistoryList(patientId)
                            afterActionOk?.()
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(OutpationPsychiatricHistory)
