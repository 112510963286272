import React from 'react';

export default function AvatarEndmeetingIcon() {
  return (
    <svg width="273" height="265" viewBox="0 0 273 265" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="137" cy="136" r="116" fill="url(#paint0_linear_2320_22520)"/>
    <circle cx="148" cy="133" r="124.5" stroke="url(#paint1_linear_2320_22520)"/>
    <circle cx="134" cy="127" r="124.5" stroke="url(#paint2_linear_2320_22520)"/>
    <circle cx="132.5" cy="132.5" r="132" stroke="url(#paint3_linear_2320_22520)"/>
    <g clipPath="url(#clip0_2320_22520)">
    <rect x="32" y="39" width="200" height="200" rx="100" fill="white"/>
    <rect x="21" y="12" width="350" height="254" fill="url(#pattern0)"/>
    </g>
    <defs>
    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
    <use transform="translate(-0.0442857) scale(0.00145143 0.002)"/>
    </pattern>
    <linearGradient id="paint0_linear_2320_22520" x1="253" y1="70.2667" x2="132.449" y2="308.132" gradientUnits="userSpaceOnUse">
    <stop stop-color="#14AE5C"/>
    <stop offset="1" stop-color="#14AE5C" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_2320_22520" x1="190.5" y1="38" x2="-3.99999" y2="212" gradientUnits="userSpaceOnUse">
    <stop stop-color="#15D06C"/>
    <stop offset="1" stop-color="#15D06C" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_2320_22520" x1="245.5" y1="185" x2="-18" y2="95.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#B0FF61"/>
    <stop offset="1" stop-color="#B0FF61" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint3_linear_2320_22520" x1="94.5" y1="137" x2="273" y2="235.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#25C3F5"/>
    <stop offset="1" stop-color="#25C3F5" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0_2320_22520">
    <rect x="32" y="39" width="200" height="200" rx="100" fill="white"/>
    </clipPath>
    
    </defs>
    </svg>
    
  );
}
