import { DiagnosticError, testVideoInputDevice, VideoInputTest } from '@twilio/rtc-diagnostics'
import { useState, useRef, useCallback } from 'react'

export function useCameraTest() {
    //const { dispatch } = useAppStateContext();
    const [videoTest, setVideoTest] = useState()
    const videoElementRef = useRef(null)
    const [videoTestError, setVideoTestError] = useState()

    const stopVideoTest = useCallback(() => {
        setVideoTest(undefined)
        videoTest?.stop()
    }, [videoTest])

    const startVideoTest = useCallback(
        (deviceId) => {
            stopVideoTest()
            const test = testVideoInputDevice({ element: videoElementRef.current, deviceId })
            setVideoTest(test)
            test.on(VideoInputTest.Events.Error, (err) => setVideoTestError(err))
            //test.on(VideoInputTest.Events.End, (report) => dispatch({ type: 'set-video-test-report', report }));
        },
        [stopVideoTest],
    )

    return { startVideoTest, stopVideoTest, videoElementRef, videoTest, videoTestError }
}
