import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { SurgicalHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { Surgical } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import { SurgicalForm } from './SurgicalForm'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'
import { useNotification } from 'shared/elements/Notification'

const SurgicalHistory = ({ isInClinicalNote, patient_Id, encounter_id, afterActionOk,nonEditable }) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    // State
    const [surgicalFormId, setSurgicalFormId] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [patientId, setPatientId] = useState('')

    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [listSurgical, setListSurgical] = useState<Surgical[]>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id])

    const getSurgicalHistory = async () => {
        try {
            setLoading(true)

            const resp = await surgicalHistoryApis.getAll(patientId, page, limit)
            setListSurgical(resp.data)
            setTotal(resp.paging.total)
        } catch (ex) {
            console.error(ex)
            notification('Cannot get list surgical', 'error')
        }

        setLoading(false)
    }

    const handleDelete = async (surgicalId: string) => {
        try {
            await surgicalHistoryApis.deleteSurgical(patientId, surgicalId)

            notification('Surgical deleted', 'success')
            getSurgicalHistory()
            afterActionOk?.()
        } catch (ex) {
            console.error(ex)
            notification('Cannot delete surgical history', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getSurgicalHistory()
        }

        return () => abortController.abort()
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: isInClinicalNote ? 0 : '16px 24px' }}>
            <Loading visible={loading} />

            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Surgical History</Typography>

                    <ButtonCustom
                        tooltip="Add New Surgical History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setSurgicalFormId('')}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <SurgicalHistoryDashboard
                    dataSource={listSurgical}
                    pagination={{ limit, total, start: page }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    onClickEdit={(surgicalFormId) => setSurgicalFormId(surgicalFormId)}
                    onClickDelete={handleDelete}
                    nonEditable={nonEditable}
                    onPageChange={(page) => setPage(page)}
                />
                {surgicalFormId !== null && (
                    <SurgicalForm
                        surgicalId={surgicalFormId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        open={surgicalFormId !== null}
                        afterActionOk={afterActionOk}
                        onClose={(forceReload) => {
                            setSurgicalFormId(null)

                            if (forceReload) getSurgicalHistory()
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(SurgicalHistory)
