import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { Social } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import moment from 'moment'

type Props = {
    page: number
    dataSource?: Social[]
    pagination?: Pagination
    sortModel?: GridSortModel
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    onPageChange?: (page: number) => void
    handleEdit?: (id: string | number) => void
    hanldeDelete?: (id: string | number) => void
    nonEditable?: Boolean
}

export const SocialHistoryDashboard = (props: Props) => {
    // Hooks
    const notification = useNotification()

    const columns: GridColumns<Social> = [
        {
            headerName: 'Social Situation',
            headerClassName: 'super-app-theme--header',
            field: 'social_situation',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                if (row.social_situation?.length) {
                    const hashText = (row.social_situation as string[]).map((text, index) => {
                        return (
                            <span key={index}>
                                {index === 0 ? '' : ', '}
                                {text}
                            </span>
                        )
                    })
                    return hashText
                } else {
                    return <DataGridCell value={undefined} />
                }
            },
        },
        {
            headerName: 'Current marital status',
            headerClassName: 'super-app-theme--header',
            field: 'current_marital_status',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.current_marital_status} />,
        },
        {
            headerName: 'Relationship status',
            headerClassName: 'super-app-theme--header',
            field: 'relationship_status',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.relationship_status} />,
        },
        {
            headerName: 'Relationship satisfactions',
            headerClassName: 'super-app-theme--header',
            field: 'relationship_satisfactions',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.relationship_satisfactions} />,
        },
        {
            headerName: 'Sexual orientation ',
            headerClassName: 'super-app-theme--header',
            field: 'sexual_orientation',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.sexual_orientation} />,
        },
        {
            headerName: 'Living situation',
            headerClassName: 'super-app-theme--header',
            field: 'living_situation',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.living_situation} />,
        },
        {
            headerName: 'Housemates',
            headerClassName: 'super-app-theme--header',
            field: 'housemates',
            flex: 1,
            renderCell: ({ row }) => {
                if (row.housemates?.length) {
                    const hashText = (row.housemates as unknown as string[]).map((text, index) => {
                        return (
                            <span key={index}>
                                {index === 0 ? '' : ', '}
                                {text}
                            </span>
                        )
                    })
                    return hashText
                } else {
                    return <DataGridCell value={undefined} />
                }
            },
        },
        {
            headerName: 'Spiritually active',
            headerClassName: 'super-app-theme--header',
            field: 'spiritually_active',
            flex: 1,
            renderCell: ({ row }) => {
                return row.spiritually_active === true ? (
                    'Yes'
                ) : row.spiritually_active === false ? (
                    'No'
                ) : (
                    <DataGridCell value={undefined} />
                )
            },
        },
        {
            headerName: 'Employment status',
            headerClassName: 'super-app-theme--header',
            field: 'employment_status',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.employment_status} />,
        },
        {
            headerName: 'Annual income',
            headerClassName: 'super-app-theme--header',
            field: 'annual_income',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.annual_income} />,
        },
        {
            headerName: 'Longest period of unemployment',
            headerClassName: 'super-app-theme--header',
            field: 'longest_period_of_unemployment',
            flex: 1,
            renderCell: ({ row }) => {
                const start = row.longest_period_of_unemployment?.start
                const end = row.longest_period_of_unemployment?.end
                return (
                    <>
                        <DataGridCell
                            value={start ? moment(start).format('MM/DD/YYYY') : undefined}
                        />{' '}
                        -{' '}
                        <DataGridCell value={end ? moment(end).format('MM/DD/YYYY') : undefined} />
                    </>
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !props.nonEditable
                    ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.handleEdit?.(params.id),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.hanldeDelete?.(params.id),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                ),
        },
    ]

    return (
        <DataGrid
            page={props.page}
            onPageChange={props.onPageChange}
            getRowId={(row) => row.id as string | number}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
        />
    )
}
