import { Button, makeStyles, Box } from '@material-ui/core'
import React from 'react'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'

const useStyles = makeStyles({
    button: {
        width: '68px',
        height: '32px',
        backgroundColor: '#5571C6',
        textAlign: 'center',
        color: '#fff',
        position: 'relative',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
    },
})

const ButtonSpinner = (props) => {
    const classes = useStyles()

    const {
        isLoading,
        disabled,
        label = 'Submit',
        onClick,
        type = 'Allow',
    } = props

    return (
        <Box
            sx={{
                m: 1,
                position: 'relative',
                '&.MuiBox-root': {
                    margin: '0 !important',
                },
            }}>
            <Button
                disabled={disabled || isLoading}
                style={
                    isLoading
                        ? {
                              background: '#E9ECF1',
                              borderRadius: '6px',
                          }
                        : {
                              background:
                                  type === 'Allow'
                                      ? '#5571C6'
                                      : '#E81212',
                          }
                }
                className={classes.button}
                onClick={onClick}>
                {isLoading ? '' : label}
            </Button>
            {isLoading && (
                <SpinnerIcon
                    style={{
                        position: 'absolute',
                        top: '24%',
                        left: '40%',
                    }}
                    size={16}
                    className={classes.iconLoading}
                />
            )}
        </Box>
    )
}

export default ButtonSpinner
