import { Grid, Typography } from '@mui/material'
import React from 'react'
import moment from 'moment'
import user_add from '../../icons/userAddSelf.png'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

function MonthCard({ event, classes }) {
  return (
      <Grid item xs={12}>
          <Typography
              className={`${classes.item_text} ${classes.text_bold}`}
              style={{ margin: 0, padding: 0 }}>
              {moment(new Date(event.start).getTime()).format('hh:mm A')}
              {` - `}
              {moment(new Date(event.end).getTime()).format('hh:mm A')}
          </Typography>
          {event.self_schedule && (
              <HtmlTooltip
                  title={'Patient self scheduled'}
                  placement="top"
                  className="notification-tooltip"
                  // open={tooltipOpen}
                  arrow>
                  <img
                      src={user_add}
                      width={30}
                      height={20}
                      // title="Patient self scheduled"
                  />
              </HtmlTooltip>
          )}
      </Grid>
  )
}

export default MonthCard
