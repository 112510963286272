import React, { useEffect, useRef, useState } from 'react'
import { Box, makeStyles, TextField } from '@material-ui/core'
import SearchIcon from '@mui/icons-material/Search';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import _, { debounce } from 'lodash'
import { Autocomplete, List } from '@mui/material'
import { getCustomizedFormattedDate } from 'utilities/dateOperations';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { _getClinicalNotesFilterPatient } from 'containers/ClinicalNotes/api/services'
import { getPatientName } from 'containers/PatientsSetting/shared';
import BhScaleUpload from './BhScaleUpload';
import { useLoadItems } from 'utilities/useLoadItems';
import { useNotification } from 'shared/elements/Notification';
import { Button } from './Button';
import { extractPatientSearchQuery } from './searchPatientUtils';

const PAGE_SIZE = 10;

const useStyles = makeStyles(() => ({
    textAreaGlobalSearch: {
        color: "#303E4E !important",
        fontFamily: "Rubik !important",
        fontSize: "14px !important" ,
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        lineHeight: "140% !important"
    },
    searchPatientGlobalTextField:{
        '& .MuiOutlinedInput-root':{
            padding: '0px 0px 0px 10px !important',
        },
        '& input': {
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='35px' width='300px'>` +
                `<text x='0' y='13' fill='%23999' font-size='14' font-family='sans-serif' letter-spacing='0.5'>Search Patient</text></svg>")`,
            backgroundRepeat: 'no-repeat',
        },
        '& input:hover': {
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='45px' width='300px'>` +
                `<text x='0' y='13' fill='%23999' font-size='14' font-family='sans-serif' letter-spacing='0.5'>Name, MRN, date of birth, phone number</text></svg>")`,
            backgroundRepeat: 'no-repeat',
        },
        '& input:focus': {
            backgroundImage: 'none',
        }
    },
    component:{
        display: 'flex',
        alignContent: 'center'
    },
    searchPatientGlobalItem: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 10px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f8f8f8',
        }
    },
    paperContainer: {
        backgroundColor: 'white',
        border: '1px solid #ccc',
        maxHeight: '200px',
        overflowX: 'scroll'
    },
    searchPatientGlobalLoader: {
        color: "#303E4E !important",
        fontFamily: "Rubik !important",
        fontSize: "14px !important" ,
        fontStyle: "normal !important",
        fontWeight: "400 !important",
        lineHeight: "140% !important",
        textAlign: 'center'
    }
}))

const findSearchKey = value =>{
    const startsWithAlphabet = /^[a-zA-Z]/;
    const startsWithDigits = /^\d+/;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    if (startsWithAlphabet.test(value)) {
        return 'name';
    }
    if (dateFormat.test(value)) {
        return 'birthdate';
    }
    if (startsWithDigits.test(value)) {
        return 'ids';
    }
    if (value.startsWith("(")) {
        return 'phone';
    }
    return 'name';
};
const processQuery = (value, key) => {
    switch (key) {
        case 'phone':
            return value.replace(/\D/g, '');
        case 'birthdate':
            const parts = value.split("/");
            return `${parts[2]}-${parts[0]}-${parts[1]}`;
        default:
            return value;
    }
};

const handleLoadMore = async (page, bundleId, query) => {
    const response = await _getClinicalNotesFilterPatient({
        page: page + 1,
        bundle_id: page !== 0 ? bundleId : undefined,
        query,
    })
    const { data, bundle_id, totalCount:total} = response.data;
    return {
        items: data.map((item) => ({
            id: item?.id,
            name: getPatientName({ ...item, suffix: null }),
            birthDate: item?.dob
                ? getCustomizedFormattedDate(item?.dob)
                : '',
            phoneNumber: item?.phone_number,
        })),
        hasNextPage: PAGE_SIZE * (page + 1) < total,
        bundleId: bundle_id,
        loading: false,
    }
};

const DropdownPaper = ({query}) => {
    const classes = useStyles();
    const notification = useNotification();
    const { loading, items, hasNextPage, error, loadMore } = useLoadItems(handleLoadMore, query, notification);
    const [loaderRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <List>
            {items && items.map((item) => (
                <PatientItem key={item.id} data={item} />
            ))}

            {(loading || hasNextPage) && (
                <div className={classes.searchPatientGlobalLoader} ref={loaderRef}>
                    Loading Patients List...
                </div>
            )}
            {(!loading && items && !items.length) && (
                <div className={classes.searchPatientGlobalLoader}>
                    Nothing Found.
                </div>
            )}
        </List>
    );
}

const PatientItem = (props) => {
    const classes = useStyles();
    const {data} = props;
    return (<div className={classes.searchPatientGlobalItem} onClick={(e) => {
        e.preventDefault();
        if(data?.id){
            window.open(`/patient/${data.id}/patient-record`, '_blank')
        }
    }}>
        <div style={{flex: 1}}>
            <span className={classes.textAreaGlobalSearch}>{data?.name}</span>
        </div>
        <div style={{width: '80px', margin: '0 15px'}}>
            <span className={classes.textAreaGlobalSearch}>{data?.birthDate}</span>
        </div>
        <div style={{width: '110px'}}>
            <span className={classes.textAreaGlobalSearch}>{data?.phoneNumber}</span>
        </div>
    </div>);
}

const SearchPatientGlobal = () => {
    const classes = useStyles()
    const autoCompleteRef= useRef(null);
    const [open, setOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [queryValue, setQueryValue] = useState('')
    const [searchResultList, setSearchResultList] = useState([])
    const renderTextField = ({ name, value, props }) => {
        return (
            <TextField
                fullWidth
                variant="outlined"
                className={classes.searchPatientGlobalTextField}
                name={name}
                size="small"
                value={value}
                {...props}
            />
        )
    }
    function documentClick (e) {
        e.stopPropagation();
        if (e.target.id === 'patient-search-top') return;
        setOpen(false);
        setSearchResultList([])
        setSearchValue('')
        setQueryValue('')
    }
    useEffect(()=>{
        document.addEventListener('click',documentClick)
        return ()=> document.removeEventListener('click',documentClick)
    },[])
    const handleNewQuery = useRef(
        debounce(async (newInputValue) => {
            setQueryValue(newInputValue);
            setOpen(true);
        }, 800),
    )

    const handleInputChange = (event, newInputValue) => {
        setOpen(false)
        if (!event) {
            return
        }
        if (!newInputValue) {
            setSearchValue();
            return
        }
        if (newInputValue === '[object Object]') {
            return
        }
        setSearchValue(newInputValue);
        handleNewQuery.current(newInputValue)
    }
    

    return (
        <>
            <div className={classes.component} style={{marginLeft: '15px',width: '580px'}}>
                <Box
                ref={autoCompleteRef}
                sx={{
                    width: '100%',
                    '& .MuiIconButton-root': {
                        transform: 'none !important',
                    },
                    '& .MuiAutocomplete-root': {
                        width: '100%',
                    },
                    '& .MuiInputBase-input': {
                        marginRight: 24,
                    },
                }}>
                    <Autocomplete
                        disablePortal
                        id="patient-search-top"
                        options={searchResultList}
                        clearIcon={false}
                        open={open}
                        value={searchValue}
                        inputValue={searchValue}
                        filterOptions={() => {
                            return searchResultList
                        }}
                        onInputChange={handleInputChange}
                        renderInput={(params) =>
                            renderTextField({
                                name: 'patient',
                                props: {
                                    ...params,
                                    InputProps: {
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                               <CancelOutlinedIcon
                                                    onClick={()=>{
                                                        setSearchValue('')
                                                        setOpen(false)
                                                    }}
                                                    style={{
                                                        fontSize:'1rem',
                                                        fontWeight:'1px',
                                                        marginRight:'0.5em',
                                                        cursor:'pointer',
                                                        visibility: searchValue ? 'visible' : 'hidden'
                                                    }}
                                                />
                                                <div>
                                                    <Button style={{borderRadius: '0 6px 6px 0', height: '2.4rem', minWidth: '3rem'}}>
                                                        <SearchIcon />
                                                    </Button>
                                                </div>
                                            </>
                                        ),
                                    },
                                },
                            })
                        }
                        PaperComponent={() =>
                            <div className={classes.paperContainer}>
                                <DropdownPaper query={queryValue} />
                            </div>
                        }
                    />
                </Box>
                <BhScaleUpload/>
            </div>
        </>
    )
}

export default SearchPatientGlobal
