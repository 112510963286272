export const INITIAL_VALUES = {
    id: '',
    patient_id: '',
    encounter_id: '', // appointment
    time_diagnosis: '',
    time_onset: '',
    time_changed: '',
    note: '',
    external_id: '',
    rcopia_id: '',
    problem: {
        code: '',
        display: '',
    },
    icd_code: {
        code: '',
        display: '',
    },
    // snomed: {
    //     code: '',
    // },
    status: {
        code: '',
        display: '',
    },
}

export const PROBLEM_STATUS = [
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'resolved', value: 'Resolved' },
    { key: 'Deleted', value: 'Deleted' },
]
export const NEW_PROBLEM_STATUS = [
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'resolved', value: 'Resolved' },
]

