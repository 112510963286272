import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
    makeStyles,
    Typography,
    Button,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import AntSwitch from './AntSwitch'
import { SelectEl } from '../../../shared/elements'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#303E4E',
        fontSize: 14,
        fontWeight: 500,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_cancel: {
        background: '#303E4E',
        width: '230px',
        fontSize: 15,
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_delete: {
        fontSize: 15,
        width: '230px',
        borderRadius: 8,
        fontWeight: 'normal',
    },
    dialog_content: {
        width: '-webkit-fill-available',
    },
}))
const radioOptions = [
    { label: 'Completed', value: 'completed' },
    { label: 'No show', value: 'No' },
]
const selectOptions = [
    { key: 'Completed', value: 'completed' },
    { key: 'No show', value: 'No' },
]

export default function ConsultantStatusDialog({
    open,
    handleClose,
    handleConfirm,
}) {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>
                    Consultation Status
                </Typography>
            </DialogTitle>
            <DialogContent
                className={classes.dialog_content}>
                <Grid
                    container
                    style={{ width: '100%' }}
                    direction="column">
                    <Typography
                        className={classes.description}
                        style={{ marginBottom: 16 }}>
                        Appointment status
                    </Typography>
                    <RadioGroup
                        name={name}
                        row={true}
                        onChange={(e) => {
                            console.log('werwer', e)
                        }}
                        style={{ marginBottom: 16 }}>
                        <Grid container>
                            {radioOptions.map((el, i) => (
                                <Grid item key={i}>
                                    <FormControlLabel
                                        value={el.value}
                                        control={
                                            <Radio color="primary" />
                                        }
                                        label={
                                            <Typography
                                                className={
                                                    classes.labelText
                                                }>
                                                {el.label}
                                            </Typography>
                                        }
                                        // checked={value === el.value}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>

                    <Typography
                        className={classes.description}
                        style={{
                            borderTop: '1px solid #E9ECF1',
                            paddingTop: 20,
                            width: '100%',
                        }}>
                        Follow up in
                    </Typography>
                    <Grid
                        container
                        style={{ margin: '20px 0' }}
                        alignItems="center">
                        <Typography
                            style={{ marginRight: 16 }}>
                            Week
                        </Typography>
                        <AntSwitch
                            onChange={(e) => {
                                console.log('erer', e)
                            }}
                            // defaultChecked
                            // checked={row.is_used}
                        />
                        <Typography
                            style={{ marginLeft: 16 }}>
                            Month
                        </Typography>
                    </Grid>
                    <SelectEl
                        // name={`${fieldName}.highest_edu_level`}
                        // value={
                        //     values?.highest_edu_level ||
                        //     ''
                        // }
                        onChange={(e) => {
                            console.log(
                                'onchange select',
                                e,
                            )
                        }}
                        options={selectOptions}
                        placeholder="Select One"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    className={classes.btn_cancel}>
                    Cancel
                </Button>
                <Button
                    onClick={handleConfirm}
                    autoFocus
                    variant="contained"
                    className={classes.btn_delete}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
}
