import { useState } from "react";

export function useLoadItems(loadItems, query, notification) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(0);
  const [bundleId, setBundleId] = useState();

  async function loadMore() {
    setLoading(true);
    try {
      const { items, hasNextPage: newHasNextPage, bundleId: bundleIdValue } = await loadItems(
        page,
        bundleId,
        query
      );
      setItems((current) => [...(current ?? []), ...items]);
      setHasNextPage(newHasNextPage);
      setPage(page + 1);
      setBundleId(bundleIdValue);
    } catch (err) {
      notification('Something went wrong!', 'error');
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { loading, items, hasNextPage, error, loadMore };
}