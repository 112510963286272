import React from 'react'
import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'

export function MonthHeaderCellContent(props) {
    return (
        <Grid container direction="column">
            <Grid item>
                <Box
                    sx={{
                        padding: 20,
                        fontSize: '16px',
                        fontWeight: 500,
                    }}>
                    {props.label}
                </Box>
            </Grid>
        </Grid>
    )
}
