import { makeStyles } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import React, { useEffect, useRef, useState } from 'react'
import Chip from '@mui/material/Chip'
import './style.scss'
import Divider from '@mui/material/Divider';
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiIconButton-root': {
            color: '#303e4e',
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
            padding: '0px 7px !important',
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: '50px !important',
            overflow: 'hidden !important',
        },
    },

    triangle: {
        position: 'absolute',
        marginBottom: '-15px',
        width: '0px',
        height: '0px',
        borderStyle: 'solid',
        borderWidth: '40px 20px 0 20px',
        borderColor: 'red transparent transparent transparent; transform: rotate(0deg)',
    },
    //hidden !important
})
const removeDuplicates = (array, property) => {
    // Create an empty object to store unique values based on the specified property
    const uniqueValues = {}

    // Use the filter method to create a new array with unique objects
    const uniqueArray = array.filter((obj) => {
        // Extract the property value of the current object
        const value = obj[property]
        // Check if the property value is already present in the uniqueValues object
        // If not present, add it to the uniqueValues object and return true
        if (!uniqueValues[value]) {
            uniqueValues[value] = true
            return true
        }
        // If the property value is already present, return false to filter out the duplicate object
        return false
    })

    return uniqueArray
}
export const SelectSearchInputCustom = (props) => {
    const classes = useStyles()

    const {
        placeholder,
        options,
        onOpen,
        onClose,
        onInputChange,
        onChange,
        error,
        helperText,
        loading,
        limitTags,
        titleModal,
        styleModal,
        isChecBoxFlag = false,
        ...rest
    } = props
    const isReadOnly = props?.readonly ? {InputProps:{
        readOnly: props?.readonly 
    }} : {}
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [value, setValue] = useState([])
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const handlePopoverOpen = (event) => {
        event.preventDefault()
        setIsPopoverOpen(true)
    }
    const popTargetRef = React.useRef(null)
    const ref = useRef(null);
    const handlePopoverClose = (event) => {
        event.preventDefault()
        setIsPopoverOpen(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false)
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleChange = (event, newValue) => {
        event.preventDefault()
        if (newValue?.length > 0 || props?.value?.length === 1) {
            const uniqueArray = removeDuplicates(newValue, 'key')
            setValue(uniqueArray)
            onChange?.(uniqueArray)

        }
    }
    const handleTagClick = (event, index) => {
        event.preventDefault()
        const updatedTags = [...value]
        updatedTags.splice(index, 1)
        onChange?.(updatedTags)
        setValue(updatedTags)
        setIsPopoverOpen(updatedTags.length > limitTags)
    }

    return (
        <Box ref={ref}>
            <Autocomplete
                className={classes['autocomplete']}
                loading={loading}
                noOptionsText={!loading && search ? 'No results found' : undefined}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                disabled={props?.disabled}
                onClose={() => {
                    // setOpen(false)
                    !isChecBoxFlag && setOpen(false)
                    onClose?.()
                }}
                {...isReadOnly}
                options={options}
                // value={props?.value}
                multiple={props?.multiple ? true : false}
                renderOption={(props, option, value) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>
                                {isChecBoxFlag && <CheckboxOption
                                    option={{
                                        label: option.value,
                                        value: option.value,
                                    }}
                                    checked={rest?.value?.some(item => item.key === option.key)}
                                />
                                }
                                {/* {option.value} {option.custom_id ? ' - ' + option.custom_id : ''} */}
                            </span>
                        </li>
                    )
                }}
                autoComplete
                getOptionLabel={(option) => option.value}
                clearIcon={false}
                limitTags={limitTags}
                // autoSelect
                disablePortal
                renderInput={(params) => {
                    return (
                        <div style={{ position: 'relative' }}>
                            <TextField
                                {...params}
                                placeholder={placeholder}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        overflow: 'hidden !important',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        padding: props?.multiple && '0px 2px !important',
                                        overflow: 'hidden !important',
                                    },
                                    '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                        overflow: 'hidden !important',
                                        textOverflow: 'ellipsis',
                                        padding: '0px 36px 0px 0px',
                                    },
                                }}
                                {...isReadOnly}
                                size="small"
                                variant="outlined"
                                error={error}
                                helperText={helperText}
                                disabled={props?.disabled}
                            />
                            {props?.multiple && value?.length > limitTags && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '36px',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: (props?.disabled || props?.readonly) ? 'none' : 'auto'
                                    }}
                                    onClick={(e) => {props?.readonly && handlePopoverOpen(e)}}
                                    ref={popTargetRef}>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        style={{ marginRight: '-8px' }}>
                                        <MoreHoriz />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    )
                }}
                onInputChange={(e, value) => {
                    if(!props?.readonly) {
                        setSearch(value)
                        onInputChange?.(value)
                    }
                }}
                onChange={(e, option) => {
                    if(!props?.readonly)
                        props?.multiple ? handleChange?.(e, option) : onChange?.(option?.value)
                }}

                // renderTags={(value, getTagProps) =>
                //     value?.length < 5 &&
                //     value.map((option, index) => (
                //         <Chip
                //             label={option.value}
                //             {...getTagProps({ index })}
                //             key={index}
                //             // disabled
                //         />
                //     ))
                // }
                {...rest}
            />
            <Popover
                open={isPopoverOpen}
                anchorEl={popTargetRef.current}
                onClose={handlePopoverClose}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow:
                            '0px 0px 2px 0px rgba(0, 0, 0, 0.35), 0px 8px 16px 0px rgba(0, 0, 0, 0.35)',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top', // Always open on top of the Chip
                    horizontal: 'right', // Always open on the right side of the Chip
                }}
                transformOrigin={{
                    vertical: 'bottom', // Align with the top of the Chip
                    horizontal: 'right', // Align with the left of the Chip
                }}>
                <div
                    style={{
                        borderBottom: '1px solid grey',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px',
                    }}>
                    <span>Selected {titleModal||placeholder}</span>
                    <div
                        onClick={(e) => {
                            handlePopoverClose(e)
                        }}
                        style={{ cursor: 'pointer' }}>
                        <span>
                            <CloseIcon fontSize="small" />
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        padding: '10px',
                        maxWidth: '350px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        borderRadius: '4px',
                    }}>
                    {value?.map((tag, index) => (
                        <div className="chip-popover" key={index} style={{...styleModal}}>
                            <span style={{ color: '#212529', fontSize: '14px' }}>{tag.value}</span>
                            <IconButton
                                size="small"
                                onClick={(event) => handleTagClick(event, index)}
                                style={{ color: '#989CA8' }}>
                                <CloseIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                        </div>
                    ))}
                </div>
            </Popover>
        </Box>
    )
}
