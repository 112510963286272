import { useParams } from "react-router";
import { ListItem, makeStyles, Badge, Menu, MenuItem } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import Draggable from 'react-draggable'
import { Resizable } from 're-resizable'
import { useHistory, useLocation } from 'react-router-dom'
// import { getCredentials } from "../../api/fetchCreds";
import styles from "./styles.js";
import useRoom from "../hooks/useRoom";
import { useDispatch, useSelector } from 'react-redux'
import { UserContext } from "../context/UserContext";
import AddParticipant from "components/TwilioVideo/AddParticipant";
import ChatWindowProvider from 'components/Consultation/components/ChatWindowProvider/ChatWindow'
import BtnIconTop from "components/TwilioVideo/compoment/BtnIconTop";
import ToolBar from "../ToolBar";
import { isEmpty, transform } from 'lodash'
import NetworkToast from "../NetworkToast";
import useScreenSharing from "../hooks/useScreenSharing";
import { setEncounterRoom } from '../../../store/actions/provider'
import {
  IconAddParticipant,
  IconChat,
  IconDisableVideo,
  IconEndCall,
  IconListParticipant,
  IconPerson,
  IconMicSvg,
  IconMinisize,
  IconMuteMic,
  IconSound,
  IconVideoSvg,
  IconMore,
  IconShare,
  IconChangeBackground,
} from 'components/TwilioVideo/icon'
import axios from 'axios';
// import EarlyArrival from "../EarlyArrival"
import usePreviewPublisher from 'components/Telehealth/hooks/usePreviewPublisher'
import DeviceAccessAlert from 'components/Telehealth/DeviceAccessAlert'

const DEVICE_ACCESS_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
}


export default function VideoRoom({ vonageProviderSession, prvName }) {
  // const { user } = useContext(UserContext);
  const { push } = useHistory();
  const location = useLocation()
  const [credentials, setCredentials] = useState(null);
  const [error, setError] = useState(null);
  const { createCall, room, participants, connected, networkStatus, cameraPublishing, localParticipant, accessAllowed } = useRoom();
  const { isScreenSharing, startScreenSharing, stopScreenSharing } = useScreenSharing({ room });
  const roomContainer = useRef();
  const classes = styles();
  let { roomName } = useParams();
  const [isEarlyArrival, setIsEarlyArrival] = useState(true)
  const dispatch = useDispatch()
  const [isActive, setActive] = useState(null)
  const [hasAudio, setHasAudio] = useState(true);
  const [hasVideo, setHasVideo] = useState(true);

  const [isDisplayAddParticipant, setIsDisplayAddParticipant] = useState(false)
  const [isDisplayChatProvider, setIsDisplayChatProvider] = useState(false)
  const objectPatient = useSelector((state) => state.provider.objectEncouterStart)
  // const {
  //   accessAllowed,
  // } = usePreviewPublisher()

  console.log("accessAllowed :: ", accessAllowed)

  const [defaultSizeVideo, setDefaultSizeVideo] = useState({
    width: 550,
    height: 400,
  })
  const [defaultSizeVideoSmall, setDefaultSizeVideoSmall] = useState({
    width: 120,
    height: 120,
  })
  const [sizeResize, setSizeResize] = useState({
    width: defaultSizeVideo.width,
    height: defaultSizeVideo.height,
  })
  const [positionVideo, setPositionVideo] = useState({
    x: 0,
    y: 0,
  })
  console.log(positionVideo)

  console.log("participants :: ", JSON.stringify(participants))

  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 20,
      top: 13,
      padding: '0 4px',
    },
  }))(Badge)

  // useEffect(() => {
  //   if (location?.state?.infoRoom && location?.state?.infoRoom?.session_key && location?.state?.infoRoom?.session_id && location?.state?.infoRoom?.session_token) {

  //     const targetDatetime = new Date(location?.state?.infoRoom?.start)
  //     targetDatetime.setMinutes(targetDatetime.getMinutes())

  //     const currentTime = new Date()

  //     console.log(targetDatetime)
  //     console.log(currentTime)

  //     if (currentTime < targetDatetime) {
  //       setIsEarlyArrival(true)
  //     } else {
  //       setIsEarlyArrival(false)
  //     }
  //   }
  // }, [location?.state?.infoRoom]);

  useEffect(() => {
    if (vonageProviderSession?.token && vonageProviderSession?.vonageApiKey && vonageProviderSession?.vonageSessionId && prvName) {
      setCredentials({
        apikey: vonageProviderSession?.vonageApiKey,
        sessionId: vonageProviderSession?.vonageSessionId,
        token: vonageProviderSession?.token,
        userName: `${prvName}`
      });
      // getCredentials(roomName)
      //   .then(({ data }) => {
      //     setCredentials({
      //       apikey: data.session_key,
      //       sessionId: data.session_id,
      //       token: data.session_token,
      //     });
      //   })
      //   .catch((err) => {
      //     setError(err);
      //     console.log(err);
      //   });
    }
  }, [vonageProviderSession, prvName])

  useEffect(() => {
    if (credentials) {
      createCall(credentials, roomContainer.current, prvName, null);
    }
  }, [createCall, credentials]);

  useEffect(() => {
    console.log("room :: ", room)
    dispatch(setEncounterRoom(room))
  }, [room])

  const _addClass = (e) => {
    setActive(e.type === 'click' ? true : false)
    setIsDisplayAddParticipant(false)
    setIsDisplayChatProvider(false)
  }

  const _removeClass = (e) => {
    setActive(e.type !== 'click' ? true : false)
  }

  const handleMinimize = () => {
    setSizeResize({
      width: 120,
      height: 120,
    })
    setIsDisplayAddParticipant(false)
    setIsDisplayChatProvider(false)
  }

  const handleMaximize = (e) => {
    if (e.detail === 2) {
      setActive(false)
      setSizeResize({
        width: 550,
        height: 400,
      })
      setIsDisplayAddParticipant(false)
      setIsDisplayChatProvider(false)
    }
  }

  const handleDoubleClickRemoveClass = (e) => {
    if (e.detail === 2) {
      setActive(false)
      const widthBody = document.querySelector('body').offsetWidth
      const heightBody = document.querySelector('body').offsetHeight

      const translateX = positionVideo.x + sizeResize.width
      const translateY = positionVideo.y + sizeResize.height

      if (translateX > widthBody) {
        setPositionVideo({
          ...positionVideo,
          x: widthBody - sizeResize.width,
        })
      }

      if (positionVideo.x < 0) {
        setPositionVideo({
          ...positionVideo,
          x: 0,
        })
      }
      if (positionVideo.y < 0) {
        setPositionVideo({
          ...positionVideo,
          y: 0,
        })
      }
      if (positionVideo.y < 0 && positionVideo.x < 0) {
        setPositionVideo({
          ...positionVideo,
          x: 0,
          y: 0,
        })
      }

      if (translateY > heightBody) {
        setPositionVideo({
          ...positionVideo,
          y: heightBody - sizeResize.height,
        })
      }
      if (translateY > heightBody && translateX > widthBody) {
        setPositionVideo({
          ...positionVideo,
          x: widthBody - sizeResize.width,
          y: heightBody - sizeResize.height,
        })
      }
      if (positionVideo.y < 0 && translateX > widthBody) {
        setPositionVideo({
          ...positionVideo,
          x: widthBody - sizeResize.width,
          y: 0,
        })
      }
      if (positionVideo.x < 0 && translateY > heightBody) {
        setPositionVideo({
          ...positionVideo,
          x: 0,
          y: heightBody - sizeResize.height,
        })
      }
    }
  }

  const toggleAddParticipantPanel = () => {
    console.log("VR_isDisplayAddParticipant :: ", isDisplayAddParticipant)
    setIsDisplayAddParticipant(!isDisplayAddParticipant)
    setIsDisplayChatProvider(false)
    // setDisplayChangeBackground(false)
  }

  const toggleChatProviderPanel = () => {
    console.log("VR_isDisplayChatProvider :: ", isDisplayChatProvider)
    setIsDisplayChatProvider(!isDisplayChatProvider)
    setIsDisplayAddParticipant(false)
    // setDisplayChangeBackground(false)
    // setIsChatWindowOpen(!isChatWindowOpen)
    // setUnreadCount(0)
  }

  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      console.log("camera :: ", camera)
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };
  const toggleAudio = () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
      }
    }
  };

  useEffect(() => {
    if (connected) {
      const isAudioEnabled = room && room.camera && room.camera.isAudioEnabled() ? true : false;
      const isVideoEnabled = room && room.camera && room.camera.isVideoEnabled() ? true : false;
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
    // if (room) console.log(getParticipantsList());
  }, [connected, room]);

  if (error) return <div className={classes.errorContainer}>There was an error fetching the data from the server</div>;

  return (
    <>
      <div className="room video-call">
        <div className="local-participant">

          <div
            id="div-room"
            style={{
              height: '100vh',
              width: '100vw',
            }}>
            <Draggable
              positionOffset={{ x: '-50%', y: '-50%' }}
              position={positionVideo}
              onStop={(e, b) => {
                setPositionVideo((prev) => {
                  return { ...prev, x: b.x, y: b.y }
                })
              }}>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1300,
                  top: '50%',
                  left: '50%'
                }}>
                <Resizable
                  minWidth={
                    isActive
                      ? defaultSizeVideoSmall.width
                      : defaultSizeVideo.width
                  }
                  minHeight={
                    isActive
                      ? defaultSizeVideoSmall.height
                      : defaultSizeVideo.height
                  }
                  defaultSize={{
                    width: isActive
                      ? `${defaultSizeVideoSmall.width}px`
                      : sizeResize.width,
                    height: isActive
                      ? `${defaultSizeVideoSmall.height}px`
                      : sizeResize.height,
                  }}
                  size={{
                    width: isActive
                      ? `${defaultSizeVideoSmall.width}px`
                      : sizeResize.width,
                    height: isActive
                      ? `${defaultSizeVideoSmall.height}px`
                      : sizeResize.height,
                  }}
                  style={{
                    zIndex: 99999,
                  }}
                  // className={clsx({
                  //     [classes.notransform]: isActive,
                  // })}
                  // onResize={(e, direction, ref, d) => {
                  //     if (sizeResize.width + d.width <= 550) {
                  //         e.preventDefault()
                  //         e.stopPropagation()
                  //     }
                  // }}
                  onResizeStart={(e, direction, ref, d) => {
                    if (
                      // direction !== "bottomLeft" &&
                      direction !== 'topLeft' &&
                      direction !== 'top' &&
                      direction !== 'left'
                    ) {
                      e.stopPropagation()
                    }
                    // e.preventDefault()
                    // e.stopPropagation()
                  }}
                  onResizeStop={(e, direction, ref, d) => {
                    setSizeResize({
                      width:
                        sizeResize.width + d.width < defaultSizeVideo.width
                          ? defaultSizeVideo.width
                          : sizeResize.width + d.width,
                      height:
                        sizeResize.height + d.height <
                          defaultSizeVideo.height
                          ? defaultSizeVideo.height
                          : sizeResize.height + d.height,
                    })
                  }}
                  enable={
                    isActive
                      ? {
                        top: false,
                        right: false,
                        bottom: false,
                        left: false,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: false,
                        topLeft: false,
                      }
                      : {
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        topRight: false,
                        bottomRight: false,
                        bottomLeft: true,
                        topLeft: true,
                      }
                  }
                  resizeRatio={1}>
                  <div className={`participant ${isActive ? 'active' : ''}`}>
                    <div id="callContainer" className={classes.callContainer}>
                      <div
                        className={`video-participant ${isActive ? 'localParticipant-minisize remote-participants active' : ''
                          } ${isDisplayAddParticipant ? 'open-side' : ''}`}>
                        <div className={`view-top-bar ${isActive ? 'active' : null}`}>
                          <div className="minimize view-background-top-bar">
                            <BtnIconTop
                              Icon={<IconMinisize />}
                              onPress={(e) => _addClass(e)}
                            />
                          </div>
                        </div>
                        <div id="roomContainer" className={classes.roomContainer} ref={roomContainer} onClick={(e) => handleMaximize(e)}>
                          <NetworkToast networkStatus={networkStatus} />
                          <div id="cameraPublisherContainerDiv" className={classes.publisherContainer} style={{ height: '25%', width: '25%', display: `${isActive ? 'none' : 'block'}` }}>

                          </div>
                          <div id="screenSharingContainer" className={classes.screenSharingContainer}>
                            {isScreenSharing && <div className={classes.screenSharingOverlay}>You Are Screensharing</div>}
                          </div>
                        </div>
                        <div
                          className={`view-button-bar ${isActive ? 'active' : null
                            }`}>
                          <div className="micro view-background-button-bar">
                            <button
                              className="button-bar"
                              onClick={() => toggleAudio()}>
                              {hasAudio ? <IconMicSvg /> : <IconMuteMic />}
                            </button>
                          </div>
                          <div className="video view-background-button-bar">
                            <button
                              className={`button-bar ${isScreenSharing ? 'opacity-50' : ''
                                }`}
                              disabled={isScreenSharing}
                              onClick={() => toggleVideo()}>
                              {hasVideo ? (
                                <IconVideoSvg />
                              ) : (
                                <IconDisableVideo />
                              )}
                            </button>
                          </div>
                          {participants && (
                            <div className="addParticipant view-background-button-bar">
                              <button
                                className="button-bar"
                                onClick={() =>
                                  toggleAddParticipantPanel()
                                }>
                                <IconAddParticipant />
                              </button>
                            </div>
                          )}
                          {participants &&
                            (
                              <div className="chat view-background-button-bar">
                                <button
                                  className="button-bar"
                                  onClick={() =>
                                    toggleChatProviderPanel()
                                  }>
                                  <IconChat />
                                </button>
                              </div>
                            )}
                        </div>

                      </div>
                      {/* <ToolBar
                        room={room}
                        participants={participants}
                        localParticipant={localParticipant}
                        connected={connected}
                        cameraPublishing={cameraPublishing}
                        isScreenSharing={isScreenSharing}
                        startScreenSharing={startScreenSharing}
                        stopScreenSharing={stopScreenSharing}
                        hasAudio={hasAudio}
                        hasVideo={hasVideo}
                        toggleAudio={toggleAudio}
                        toggleVideo={toggleVideo}
                      ></ToolBar> */}
                    </div>
                    <AddParticipant
                      onClose={toggleAddParticipantPanel}
                      isDisplayAddParticipant={isDisplayAddParticipant}
                    />
                    <ChatWindowProvider
                      onClose={toggleChatProviderPanel}
                      isDisplayChatProvider={isDisplayChatProvider}
                      room={room}
                      objectPatient={objectPatient}
                    />
                  </div>
                </Resizable>

              </div>
            </Draggable>
          </div>

        </div>
      </div >

      {
        accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && (
          <DeviceAccessAlert accessStatus={accessAllowed}></DeviceAccessAlert>
        )
      }
    </>
  );
}
