import moment from 'moment'

export const INITIAL_VALUES = {
    status: 'active',
    type: '',
    insurance: {
        plan: {
            code: '',
            display: '',
        },
        policy_no: '',
        group_name: '',
        group_number: '',
        phone: '',
        copay_amount: '',
        deductible_amount: '',
        policy_effective: '',
        policy_end: '',
        patient_relationship: '',
        policy_emp: '',
        notes: [],
        relation_info: {
            first_name: '',
            last_name: '',
            insured_number: '',
            ssn: '',
            dob: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zip: '',
        },
        deductible: '',
        deductible_met: '',
        front_key: '',
        back_key:'',
    },
    payment_method: {
        code: 'Insurance',
        display: 'Insurance',
    },
    note: '',
    noteView: 'no-view',
}
export const INITIAL_PLAN_VALUES = {
    company_name: '',
    plan_name: '',
    phone_number: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
}

export const patientsRelationship = [
    {
        key: 'Self',
        value: 'Self',
    },
    {
        key: 'Child',
        value: 'Child',
    },
    {
        key: 'Spouse',
        value: 'Spouse',
    },
    {
        key: 'Other',
        value: 'Other',
    },
]

export const insuranceType = [
    {
        key: 'Primary',
        value: 'Primary',
    },
    {
        key: 'Secondary',
        value: 'Secondary',
    },
    {
        key: 'Tertiary',
        value: 'Tertiary',
    },
]

export const paymentMethodOptions = [
    { label: 'Insurance', value: 'Insurance', name: 'Insurance' },
    { label: 'Self Pay', value: 'Self Pay', name: 'Self Pay' },
]

export const statusOptions = [
    { label: 'Active', value: 'active', name: 'Active' },
    { label: 'Inactive', value: 'inactive', name: 'Inactive' },
]
