import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    selected: {
        color: '#FFFFFF',
        bgcolor: '#667689',
        fontSize: '12px',
    },
    notSelected: {
        color: '#667689',
        bgcolor: '#FFFFFF',
        fontSize: '12px',
    },
})
const BtnSwitchToggle = ({ label1, label2, unit, handleToggle, style }) => {
    const [selected, setSelected] = useState(unit)
    React,useEffect(()=>{
        setSelected(unit)
    },[unit])
    const handleChange = (updateUnit) => {
        if (updateUnit === selected) {
            return
        }
        setSelected(updateUnit)
        handleToggle(updateUnit)
    }
    const classes = useStyles()
    return (
        <Grid
            direction="row"
            justifyContent="center"
            alignItems="center"
            container
            style={{
                width: '221px',
                ...style,
            }}>
            <Grid>
                <Box
                    onClick={() => handleChange(label1)}
                    style={{
                        backgroundColor: selected === label1 ? '#667689' : '#FFFFFF',
                    }}
                    sx={{
                        width: '42px',
                        height: '21px',
                        textAlign: 'center',
                        borderRadius: '3px 0px 0px 3px',
                        border: '1px solid #667689',
                        boxSizing: 'border-box',
                    }}>
                    <Typography
                        className={selected === label1 ? classes.selected : classes.notSelected}>
                        {label1}
                    </Typography>
                </Box>
            </Grid>
            <Grid>
                <Box
                    style={{
                        backgroundColor: selected === label2 ? '#667689' : '#FFFFFF',
                    }}
                    onClick={() => handleChange(label2)}
                    sx={{
                        display: 'flex',
                        width: '42px',
                        height: '21px',
                        textAlign: 'center',
                        border: '1px solid #667689',
                        borderRadius: '0px 3px 3px 0px',
                        justifyContent: 'center',
                        boxSizing: 'border-box',
                    }}>
                    <Typography
                        className={selected === label2 ? classes.selected : classes.notSelected}>
                        {label2}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default BtnSwitchToggle
