import React, { useRef, useState, useEffect } from 'react'
import { Button, FormLabel, Grid, makeStyles, Typography } from '@material-ui/core'

import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import LoadingPage from 'utilities/loading-page'

import { getListSpecialties } from 'services/Setting'
import TextBox from 'shared/elements/FormItem/TextBox'
import { PhoneNumberInput } from 'shared/elements/FormItem/Input'
import { usePatient } from 'containers/PatientsSetting/hooks'
import moment from 'moment'
import { detailsOfBhScale } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'

const useStyles = makeStyles({
    container: {
        width: '100%',
        border: '1px solid #CFCFCF',
        borderRadius: 10,
        padding: '22px 10px',
    },
    form: {
        position: 'relative',
        flexWrap: 'nowrap',

        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    headingText: {
        color: '#303E4E',
        fontfamily: 'Rubik',
        fontSize: '18px',
        fontWeight: '500',
    },
    headingTextSide: {
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '15px',
        fontStyle: 'italic',
        fontWeight: '400',
        paddingLeft: '10px',
        paddingBottom: '2px',
    },
    detailstext: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'italic',
        fontWeight: '400',
    },
    detailsQuesting: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
        marginTop: '12px',
    },
    formLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: '500',
    },
})

function PhqFormDetails() {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()
    const notification = useNotification()

    const location = useLocation()
    const { id } = useParams()

    const { patientInfo } = usePatient(id)
    const [isLoading, setIsLoading] = useState(false)
    const [patientDetails, setPatientDetails] = useState(false)
    const [bhDetails, setBhDetails] = useState({})

    useEffect(() => {
        if (patientInfo) {
            setPatientDetails(patientInfo)
        }
    }, [patientInfo])
    const fetchDetails = async () => {
        try {
            setIsLoading(true)
            setBhDetails({})
            const res = await detailsOfBhScale(id, history.location.state.id)
            setBhDetails(res?.data)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            notification('Something went wrong!', 'error')
        }
    }

    useEffect(() => {
        fetchDetails()
    }, [])
    if (!patientDetails.first_name || isLoading) {
        return <LoadingPage />
    }

    return (
        <Grid container item className={classes.container} spacing={2} xs={12}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '15px',
                    paddingBottom: '15px',
                }}>
                <Typography className={classes.headingText}>
                    Upload date:{formatDateTime(history.location.state.time, 'MM/DD/YYYY')}
                </Typography>
                <Typography className={classes.headingTextSide}>Results are read only </Typography>
            </div>
            <Grid xs={12}>
                <fieldset className="divider-information">
                    <legend>
                        <Typography className={'heading1-title'}>Scale Results </Typography>
                    </legend>
                    <Grid container item alignItems="flex-start" spacing={2}>
                        <Grid item xs={4}>
                            <FormLabel className={classes.formLabel}>Patient Name</FormLabel>
                            <div style={{ marginTop: '5px' }}>
                                <TextBox
                                    placeholder=" "
                                    value={`${
                                        patientDetails?.first_name +
                                        ' ' +
                                        patientDetails?.middle_name +
                                        ' ' +
                                        patientDetails?.last_name
                                    }`}
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel className={classes.formLabel}>Date of Birth </FormLabel>
                            <div style={{ marginTop: '5px' }}>
                                <TextBox
                                    name="first_name"
                                    value={moment(patientDetails?.dob).format('MM/DD/YYYY')}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel className={classes.formLabel}>Phone Number </FormLabel>
                            <div style={{ marginTop: '5px' }}>
                                <PhoneNumberInput
                                    name="phone_number"
                                    value={patientDetails?.phone_number}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Typography className={classes.detailstext} style={{ marginTop: '20px' }}>
                        Please rate the following questions on a scale of 0 to 3:
                    </Typography>

                    <Typography className={classes.detailstext} style={{ marginTop: '10px' }}>
                        Not at all=0:
                    </Typography>
                    <Typography className={classes.detailstext} style={{ marginTop: '5px' }}>
                        Several Days=1
                    </Typography>
                    <Typography className={classes.detailstext} style={{ marginTop: '5px' }}>
                        More than Half the Days=2
                    </Typography>
                    <Typography className={classes.detailstext} style={{ marginTop: '5px' }}>
                        Nearly Every Day=3
                    </Typography>

                    <Typography className={classes.detailsQuesting}>
                        Over the last 2 weeks, how often have you been bothered by any of the
                        following problems?
                    </Typography>
                    <div style={{ marginTop: '12px' }}>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Little interest or pleasure in doing things
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t1}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Feeling down, depressed, or hopeless
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t2}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Worrying too much about different things
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t3}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Feeling tired or having little energy
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t4}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Poor appetite or overeating
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t5}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Feeling bad about yourself – or that you are a failure or have let
                                yourself or your family down
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t6}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Trouble concentrating on things, such as reading the newspaper or
                                watching television
                            </FormLabel>
                            <br />
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t7}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Moving or speaking so slowly that other people could have noticed?
                                Or the opposite - being so fidgety or restless that you have been
                                moving around a lot more than usual
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t8}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                Thoughts that you would be better off dead or of hurting yourself in
                                some way
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.scores?.t9}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>
                                If you checked off any problems, how difficult have these problems
                                made it for you to do your work, take care of things at home, or get
                                along with other people?
                            </FormLabel>
                            <Grid item xs={6} style={{ marginTop: '5px' }}>
                                <TextBox
                                    value={bhDetails?.checked_problems_result}
                                    placeholder=" "
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item alignItems="flex-start" spacing={2}>
                            <FormLabel className={classes.formLabel}>General Comments</FormLabel>
                            <Grid item xs={12} style={{ marginTop: '5px' }}>
                                {/* <TextBox
                                    multiline
                                    name="first_name"
                                    minRows={4}
                                    value={bhDetails?.comments}
                                    placeholder=" "
                                    disabled={true}
                                /> */}
                                <TextBox
                                    // label="Note"
                                    name="note"
                                    value={bhDetails?.comments}
                                    disabled={true}
                                    multiline
                                    variant="outlined"
                                    minRows={4}
                                    placeholder="Note"
                                    style={{ marginTop: 10 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '90px !important',
                                        },
                                        ' & .MuiInputBase-input': {
                                            height: '60px !important',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </fieldset>
            </Grid>
        </Grid>
    )
}

export default PhqFormDetails
