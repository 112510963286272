import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    OutlinedInput,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Input,
    FormControlLabel,
    TextField,
    InputAdornment,
    SxProps,
    CircularProgress,
    FilledInput,
    IconButton,
} from '@mui/material'
import {
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Chip,
} from '@material-ui/core'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Placeholder from 'shared/elements/Placeholder'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
// import SearchIcon from '@mui/icons-material/Search'
import SearchIconLabel from 'assests/icons/SearchIconLabel'
import SearchIcon from 'assests/icons/search_icon.svg'
import { useNotification } from '../Notification'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { formatNumber, formatDOB } from 'shared/utils'
// import { Input } from '@material-ui/core'
// import { makeStyles } from '@mui/material/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 3
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    sx?: SxProps
    options: {
        key: string | number
        value: string
        dob?: string
    }[]
    value?:
        | {
              key: string | number
              value: string
              dob?: string
          }[]
        | null

    label?: string
    handleChange: (option: { key: string | number; value: string }, checked: boolean) => void
    placeholder?: string
    placeholderSearch?: string
    style?: React.CSSProperties
    onOpen?: () => void
    loadingOptions?: boolean
    disable?: boolean
    filterSearch?: (value: string) => void
    handleChangePage?: (value: number) => void
    name?: string
    scrollBarPosition?: (value: number) => void
    setScrollBarPosition?: number
    searchInputType?: string
    setFilterValueField?: (value: any) => void
    filterValue?: any
}

const useStyles = makeStyles(() => ({
    wrapSelectMuntil: {
        overflow: 'hidden',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    label: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '0',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '32px',
        },
    },
    box_options: {
        padding: '15px 0',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    list_options: {
        maxHeight: '150px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        width: '100%',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
}))

const AccordionNewFilter = (props: Props) => {
    const {
        options,
        value,
        handleChange,
        label,
        placeholder,
        placeholderSearch,
        style,
        onOpen,
        sx,
        loadingOptions,
        disable,
        filterSearch,
        handleChangePage,
        name,
        scrollBarPosition,
        setScrollBarPosition,
        searchInputType,
        setFilterValueField,
        filterValue,
    } = props
    const [searchOptions, setSearchOptions] = useState<
        {
            key: string | number
            value: string
            dob?: string
        }[]
    >(options || [])
    const notification = useNotification()
    const scrollableContentRef = useRef<any>(null)
    const scrollcheck = () => {
        const scrollableContent: any = scrollableContentRef.current
        if (setScrollBarPosition && setScrollBarPosition > 0 && scrollableContent.scrollTop === 0) {
            scrollableContentRef.current.scrollTop = setScrollBarPosition
        }
    }
    const getValue = (value: string) => {
        let variableName = value
        variableName = value?.slice(0, 17) + '...'
        return variableName
    }
    useEffect(() => {
        scrollcheck()
    }, [scrollableContentRef])

    const handleScroll = () => {
        const scrollableContent: any = scrollableContentRef.current
        scrollBarPosition && scrollBarPosition(scrollableContent.scrollTop)
        const tolerance: Number = 1
        const isAtEnd =
            scrollableContent.scrollHeight - scrollableContent.scrollTop <=
            scrollableContent.clientHeight + tolerance
        if (isAtEnd) {
            handleChangePage && handleChangePage(1)
        }
    }
    useEffect(() => {
        setSearchOptions(options)
    }, [options])

    const handleSearch = React.useCallback(
        (e) => {
            setFilterValueField && setFilterValueField(e)
            if (filterSearch) {
                filterSearch(e)
            } else {
                setSearchOptions((prev) => {
                    const listResult = options.filter((item) => {
                        return item.value.toUpperCase().indexOf(e.toUpperCase()) >= 0
                    })
                    return listResult
                })
            }
        },
        [filterSearch, setFilterValueField, setSearchOptions, options],
    )

    const classes = useStyles()
    return (
        <Box sx={sx} className={classes.wrapSelectMuntil}>
            <Box className={classes.box_options}>
                <div className="search_holder" style={{ marginTop: '-10px', paddingLeft: '2px' }}>
                    <FormControl sx={{ m: 1, width: '17rem' }} variant="filled">
                        {searchInputType === 'date' ? (
                            <FilledInput
                                sx={{ paddingTop: '8px', paddingBottom: '8px' }}
                                id="filled-adornment-password"
                                placeholder="MM/DD/YYYY"
                                value={filterValue}
                                onChange={(e) => {
                                    const formatText = formatDOB(
                                        e.target.value.replace(/[^0-9]+/g, '').substring(0, 10),
                                    )
                                    handleSearch(formatText)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <img src={SearchIcon} alt="icon"></img>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        ) : searchInputType === 'phoneNumber' ? (
                            <FilledInput
                                sx={{ paddingTop: '8px', paddingBottom: '8px' }}
                                id="filled-adornment-password"
                                placeholder="Search..."
                                value={filterValue}
                                onChange={(e) => {
                                    const formatText = formatNumber(
                                        e.target.value.replace(/[^0-9]+/g, '').substring(0, 10),
                                    )
                                    handleSearch(formatText)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <img src={SearchIcon} alt="icon"></img>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        ) : (
                            <FilledInput
                                sx={{ paddingTop: '8px', paddingBottom: '8px' }}
                                id="filled-adornment-password"
                                placeholder="Search..."
                                value={filterValue}
                                onChange={(e) => {
                                    handleSearch(e?.target?.value)
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton edge="end">
                                            <img src={SearchIcon} alt="icon"></img>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        )}
                    </FormControl>
                </div>
                <Box
                    className={classes.list_options}
                    ref={scrollableContentRef}
                    onScroll={handleScroll}>
                    {loadingOptions ? (
                        <Box className={classes.box_option}>
                            <Box>Loading Options...</Box>
                        </Box>
                    ) : (
                        <>
                            {!searchOptions.length ? (
                                <Box className={classes.box_option}>
                                    <Box>No Options...</Box>
                                </Box>
                            ) : (
                                searchOptions?.map((option, index) => {
                                    const check = value?.find((item) => item.key === option.key)
                                    return (
                                        <Box key={index} className={classes.box_option}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={check ? true : false}
                                                        onChange={(e) => {
                                                            handleChange(option, e.target.checked)
                                                        }}
                                                        name={option.value}
                                                    />
                                                }
                                                label={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                        {option?.value?.length <= 17 ? (
                                                            <span>{option.value}</span>
                                                        ) : (
                                                            <HtmlTooltip
                                                                title={option.value || ''}
                                                                arrow
                                                                placement="top">
                                                                <span>
                                                                    {getValue(option.value)}
                                                                </span>
                                                            </HtmlTooltip>
                                                        )}
                                                        {option?.dob && (
                                                            <span
                                                                style={{
                                                                    marginLeft: '5px',
                                                                    fontSize: '14px',
                                                                }}>
                                                                {option.dob}
                                                            </span>
                                                        )}
                                                    </div>
                                                }
                                            />
                                        </Box>
                                    )
                                })
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default React.memo(AccordionNewFilter)
