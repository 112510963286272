import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const mapOptions = (assessment = []) => {
    return assessment.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const AssessMentSourceOfRisk = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    nonEditable,
    copyAble,
    copyValues
}) => {
    const options = mapOptions(
        master?.assessmentSourceRiskMasters,
    )
    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_source_risk_opt = []
            const source_risk_opt = copyValues?.source_risk_opt?.length ? copyValues?.source_risk_opt : []
            for (const element of source_risk_opt) {
                if (typeof element === 'object' && element !== null) {
                    found_source_risk_opt.push(element);
                }
            }

            const finalValues = {
                narrative: `${copyValues?.narrative} ${values?.narrative}`,
                source_risk_opt: [...found_source_risk_opt, ...(values?.source_risk_opt || [])],
            }
            if (!found_source_risk_opt.length  && copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }

    const handleInputChange = (e, se) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name]
            ? [...values[name]]
            : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [
                      ...updatedValue,
                      { id: value, label: se.label },
                  ]
                : updatedValue.filter(
                      (uv) => uv.id !== value,
                  )
        }

        if (type === 'text') {
            updatedValue = updatedValue.map((uv) =>
                uv.id === se._id
                    ? { ...uv, desc: value }
                    : uv,
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 5,
            }}>
            {
                copyAble && !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )
            }
            {options.map((option) => {
                const isSelected = values[
                    'source_risk_opt'
                ].some((item) => item.id === option.value)

                return (
                    <CheckboxOption
                        key={option.value}
                        name="source_risk_opt"
                        option={option}
                        disabled={nonEditable}
                        onChange={(e) =>
                            handleInputChange(e, option)
                        }
                        checked={isSelected ? true : false}
                    />
                )
            })}
            <NarrativeAndMacro
                hideTextNarrative={true}
                hideGenerateBtn={true}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default AssessMentSourceOfRisk
