export const INITIAL_VALUES = {
    id: '',
    severity: '',
    allergy: {
        coding: [],
        text: '',
    },
    status: {
        code: '',
        display: '',
    },
    patient_id: '',
    note: '',
    reaction: {
        code: '',
        display: '',
    },
    // external_id: '',
    // rcopia_id: '',
    onset_date: '',
    encounter_id: '',
}

export const ALLERGY_STATUS = [
    {
        _source: {
            code: 'active',
            display: 'Active',
        },
    },
    {
        _source: {
            code: 'inactive',
            display: 'Inactive',
        },
    },
    {
        _source: {
            code: 'deleted',
            display: 'Deleted',
        },
    },
]

export const ALLERGY_STATUS_MEDICAL = [
    {
        _source: {
            code: 'active',
            display: 'Active',
        },
    },
    {
        _source: {
            code: 'inactive',
            display: 'Inactive',
        },
    },

]