import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Grid, makeStyles, Box } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import LoadingPage from 'utilities/loading-page'
import { NOTE_STATUS } from '../constants'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import {
    // fetchPDFData,
    getNotePreview,
    _updateClinicalNotes,
    getMedicalCoding,
    postMedicalCoding,
    signAndLock,
    requestApprove,
    resendEdit,
    fetchPDFMedicalData,
    getProvidersNextEncounter,
} from '../api/services'
// import BehavioralNotesView from './BehaviouralNotes'
// import CategoryNotes from './CategoryNotes'
// import History from './History'
// import PreviewNote from './PreviewNote'
import NewPreviewNote from './newPreviewNote'
import Addendum from '../Addendum/Addendum'
import { SET_CLINICAL_NOTES_TEMPLATE } from '../store/actionTypes'
import { useNotification } from 'shared/elements/Notification'
import { getAccountById, getScheduleById } from 'services/Calendar'
// import HealthyRecord from './HealthyRecord'
import { apiVitalSigns } from 'services/Patient/apiVitalSigns'
import { useTimezone } from 'shared/hooks/useTimezone'
import * as clinicalNoteActions from '../../../store/actions/clinicalNotes'
import MultiSpecialityClinicalNote from './multiSpecialityClinicalNote'
import PatientDetails from './PatientDetails'
import MedicalNotesView from './MedicalNotesView'
import MedicalCategoryNotes from './MedicalCategoryNotes'
import MedicalHeader from 'components/ClinicalNotes/medical/MedicalHeader'
import PatientInfoHeader from './patientInformationHeader'
import { UserRoles } from 'constants/user-roles'

const useStyles = makeStyles({
    sessions: {
        flex: 1,
        width: 0,
        height: 'calc(100vh - 108px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'relative',
    },
    clinical_note_container: {
        // padding: '16px 20px',
        flex: 1,
    },
})
const convertToFahrenheit = (celsius) => celsius * (9 / 5) + 32

const MedicalNotes = (
    {
        isConsultant,
        noteDetails,
        providerType,
        state,
        setLoader,
        categoryNotes,
        clinicalNoteData,
        configureTemplateDetails,
        psycho_note,
        previous_cn,
        clinicalNoteInitialData,
        onUpdate,
    },
    props,
) => {
    const classes = useStyles()
    const {
        system_reviews,
        medication,
        exam,
        review_of_measures,
        source_of_information,
        suicide_risk_factors,
        assessment_impression,
        assessment_informed_consent,
        plan,
        plan_med_changes,
        allergy,
    } = previous_cn?.clinicalNoteModel || {}

    const notification = useNotification()
    const dispatchRedux = useDispatch()
    const setActiveNoteToPlanNew = useSelector((state) => state.clinicalNotes.setActivePlan)

    const clinicalNotes = useSelector((state) => state.clinicalNotes)

    const [sigingAndLocking, setSigingAndLocking] = useState(false)
    const [requestingApproval, setRequestingApproval] = useState(false)
    const [resendingEdit, setResendingEdit] = useState(false)

    const { dispatch } = React.useContext(ClinicalNoteContext)
    const submitFormRef = useRef(null)

    const defaultActiveView = 'sessions'
    const [activeNote, setActiveNote] = useState({ defaultActiveView })
    const [activeView, setActiveView] = useState(defaultActiveView)
    const [backToText, setBackToText] = useState('')
    const [categoryList, setCategoryList] = useState([])
    const [PDFFile, setPDFFile] = useState(null)
    const [openPreviewModal, setOpenpreviewModal] = useState(false)
    const [role, setRole] = useState('')
    const [isCreatorNote, setIsCreatorNote] = useState(false)
    const [EqStatus, setEqStatus] = useState(true)
    const [appointment, setAppointment] = useState({})
    const [nextEncounterDetails, setNextEncounterDetails] = useState({})

    const userData = useSelector((state) => state.login.userData)
    const timezone = useTimezone()

    const nonEditable = useMemo(() => {
        return [
            'pending',
            'locked',
            'approved_lock',
            'Pending Approval',
            'Approved & Locked',
            'LOCKED',
        ].includes(clinicalNoteData.status) ||
        noteDetails?.name === 'view' ||
        (clinicalNoteData.status === 'resent_to_edit' && role === UserRoles.PROVIDER) ? true : false
}, [clinicalNoteData.status])

    useEffect(() => {
        (async () => {
            try {
                if (noteDetails?.appointment_id) {
                    const res = await getScheduleById({
                        id: noteDetails.appointment_id,
                    })
                    setAppointment(res.data)
                }

                if(noteDetails?.id) {
                    const res = await getProvidersNextEncounter({
                      resource: "note",
                      id: noteDetails?.id
                    })
                    setNextEncounterDetails(res?.data?.session)
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    useEffect(() => {
        ;(async () => {
            try {
                const res = await getAccountById(userData['custom:user_id'])
                setRole(res.data.role)
                setIsCreatorNote(res.data.id == clinicalNoteData.provider_id)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [userData])

    useEffect(() => {
        if (categoryNotes?.clinicalNoteTemplate)
            setCategoryList(categoryNotes.clinicalNoteTemplate.components)
    }, [categoryNotes])

    useEffect(() => {
        dispatchRedux(clinicalNoteActions.setActiveNote(activeNote))
    }, [activeNote])

    const changeActiveNote = (val) => {
        if (
            noteDetails?.status !== 'LOCKED' &&
            noteDetails?.name !== 'view'
            // noteDetails?.status === 'RESEND_EDIT' ||
            // noteDetails?.status === 'PENDING_APPROVE' ||
            // noteDetails?.status === 'DRAFT'
        )
            submitFormRef.current()
        setActiveNote(val)
        setActiveView(defaultActiveView)
    }
    useEffect(()=>{
        if(setActiveNoteToPlanNew){
            setActiveNote({
                "order": 11,
                "require": true,
                "status": "completed",
                "isShow": true,
                "componentId": "plan",
                "header": "Plan"
            })
            dispatchRedux(clinicalNoteActions.setActiveNoteToPlan(false))
        }
    },[setActiveNoteToPlanNew])
    const changeActiveView = (val) => {
        submitFormRef.current()
        setActiveView(val)
        if (val === 'preview') {
            setOpenpreviewModal(true)
        }
    }
    const handleClosePreviewModal = () => {
        setActiveView(defaultActiveView)
        setOpenpreviewModal(false)
    }
    const checkMedicalCoding = async (appointmentId) => {
        try {
            const resp = await getMedicalCoding(appointmentId)
            const { cpt, hcpcs, icd10 } = resp.data

            if (!icd10.length)
                throw new Error('Medical Coding: Must have at least one ICD 10 Code!')
            if (hcpcs.length + cpt.length === 0)
                throw new Error('Medical Coding: Must have at least one CPT Code or HCPCS Code')
        } catch (exception) {
            setSigingAndLocking(false)
            return exception.message
        }
    }

    const handleSignAndLock = async () => {
        // Basecase SUAT-66
        // Medical Coding requires at least 1 ICD-10 code, and either at least one CPT code or HCPCS Code

        try {
            setLoader(true)
            setSigingAndLocking(true)

            const errorMsg = await checkMedicalCoding(clinicalNoteData.appointment_id)
            if (errorMsg) {
                return notification(errorMsg, 'error')
            }

            await signAndLock(clinicalNoteData.id, {
                timezone: timezone.locationTimezone,
                label_timezone: timezone.labelTimezone,
            })
            Swal.fire('', 'Note has been successfully signed and locked')
            await onUpdate()
        } catch (error) {
            notification('Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setSigingAndLocking(false)
        }
    }

    const handleRequestApprove = async (id) => {
        try {
            setLoader(true)
            setRequestingApproval(true)

            await requestApprove(clinicalNoteData.id, {
                timezone: timezone.locationTimezone,
                label_timezone: timezone.labelTimezone,
                supervisor_id: id,
            })
            Swal.fire('', 'Note has been successfully requested for approval')
            await onUpdate()
        } catch (err) {
            notification(err?.data?.message || 'Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setRequestingApproval(false)
        }
    }

    const handleResendEdit = async () => {
        try {
            setLoader(true)
            setResendingEdit(true)

            await resendEdit(clinicalNoteData.id)
            Swal.fire('', 'Resend note to edit successfully')
            await onUpdate()
        } catch (err) {
            notification('Something went wrong. Please try again.', 'error')
        } finally {
            setLoader(false)
            setResendingEdit(false)
        }
    }

    const handleRenderPDF = (values) => {
        if (values) {
            setPDFFile(null)
            const filterByTemplate = { ...values }
            state.clinicalNoteTemplate.forEach((item) => {
                if (!item.isShow) {
                    delete filterByTemplate[item.componentId]
                }
            })

            // _updateClinicalNotes(clinicalNoteData.id, {
            //     ...clinicalNoteData,
            //     data: JSON.stringify({
            //         ...categoryNotes,
            //         clinicalNoteModel: values,
            //     }),
            // })

            fetchPDFMedicalData(clinicalNoteData.id, {
                ...categoryNotes,
                clinicalNoteModel: filterByTemplate,
            }).then((response) => {
                const url = URL.createObjectURL(response.data)
                setPDFFile(url)
            })
        }
    }

    useEffect(() => {
        if (activeView === 'preview') {
            handleRenderPDF()
        }
    }, [activeView])

    const handlePreview = () => {
        const onPreview = async () => {
            try {
                setLoader(true)
                const result = await getNotePreview({
                    note_type: noteDetails?.note_type,
                    note_id: noteDetails?.note_id,
                })
                const blob = new Blob([result.data], {
                    type: 'application/pdf',
                })
                setLoader(false)
                const link = window.URL.createObjectURL(blob)
                window.open(link)
            } catch (err) {
                setLoader(false)
                notification('Something went wrong. Please try again.', 'error')
            }
        }
        onPreview()
    }
    const handleChangeTemplate = (newTemplates) => {
        setCategoryList([])

        dispatch({
            type: SET_CLINICAL_NOTES_TEMPLATE,
            value: state.clinicalNoteTemplate.map((item) => {
                const sessionCompare = newTemplates.find(
                    (session) => session.componentId === item.componentId,
                )
                return {
                    ...item,
                    isShow: sessionCompare ? sessionCompare.isShow : item.isShow,
                }
            }),
        })
    }
    const getData = (key, value) => {
        return state.clinicalNoteTemplate.find((item) => item.componentId === key)?.status ===
            'completed' || !Boolean(value)
            ? categoryNotes.clinicalNoteModel?.[key]
            : { ...value, narrative: '' }
    }

    const getDxPointersArray = (apiArray, noteArray) => {
        const dxPointersArray = []
        noteArray?.map((item) => {
            const findIndexValue = apiArray?.findIndex(icd10Item => icd10Item?.code === item?.code) + 1
            dxPointersArray.push(findIndexValue)
        })
        if(noteArray?.length < 4){
            for (let i=0; i < 4-noteArray?.length; i++ ){
                dxPointersArray.push(0)
            }
        }
        return dxPointersArray
    }

    const updatePatientMedicalCoding = async (values) => {
        const res = await getMedicalCoding(clinicalNoteData?.appointment_id)
        const objectData = { cpt: [], hcpcs: [], icd10: res?.data?.icd10 }

        values?.med_codes?.forEach((medCode) => {
            if(medCode?.code?.type === "CPT"){
                const dxPointers = getDxPointersArray(objectData?.icd10, medCode?.icd10_code)?.join(':')
                objectData.cpt.push({
                    code: medCode?.code?.value,
                    description: medCode?.code?.description,
                    extension: {
                        modifiers: [],
                        dxPointers
                    }
                })
            }

            if(medCode?.code?.type === "HCPCS"){
                const dxPointers = getDxPointersArray(objectData?.icd10, medCode?.icd10_code)?.join(':')
                objectData.hcpcs.push({
                    code: medCode?.code?.value,
                    description: medCode?.code?.description,
                    extension: {
                        modifiers: [],
                        dxPointers
                    }
                })
            }
            
        })
        await postMedicalCoding(clinicalNoteData?.appointment_id, objectData)
    }
    const newCategoryList = categoryList.filter((item)=> item.componentId !== "chief_complaint")
   const handleGotoSession = (value) => {
    const findIndex = newCategoryList.findIndex(item => item.header === activeNote.header)
    if(value == 'next' && (newCategoryList.length   > findIndex+1)){
        dispatchRedux(clinicalNoteActions.checkHandleScroll(newCategoryList[findIndex+1]))
    }else if( value == 'previous' && (findIndex > 0)){
        // setActiveNote(newCategoryList[findIndex-1])
        dispatchRedux(clinicalNoteActions.checkHandleScroll(newCategoryList[findIndex-1]))
    }
   }

    return (
        <>
            <Formik
                initialValues={{
                    ...categoryNotes.clinicalNoteModel,
                    system_reviews: getData('system_reviews', system_reviews),
                    medication: getData('medication', medication),
                    // exam: getData('exam', exam),
                    review_of_measures: getData('review_of_measures', review_of_measures),
                    source_of_information: getData('source_of_information', source_of_information),
                    // suicide_risk_factors: getData('suicide_risk_factors', suicide_risk_factors),
                    assessment_impression: getData('assessment_impression', assessment_impression),
                    assessment_informed_consent: getData(
                        'assessment_informed_consent',
                        assessment_informed_consent,
                    ),
                    plan: getData('plan', plan),
                    plan_med_changes: getData('plan_med_changes', plan_med_changes),
                    allergy: getData('allergy', allergy),
                }}
                validate={async (values) => {
                    if (clinicalNoteData.status === 'LOCKED' || noteDetails?.name === 'view') return
                    if (
                        clinicalNoteData.status === 'DRAFT' ||
                        clinicalNoteData.status === 'RESEND_EDIT'
                    ) {
                        clinicalNoteData.status = 'IN_PROGRESS'
                    }
                    if(activeNote.componentId === 'med_codes'){
                        updatePatientMedicalCoding(values)
                    }
                    await _updateClinicalNotes(clinicalNoteData.id, {
                        ...clinicalNoteData,
                        data: JSON.stringify({
                            ...categoryNotes,
                            clinicalNoteModel: values,
                            clinicalNoteTemplate: {
                                ...categoryNotes.clinicalNoteTemplate,
                                components: state.clinicalNoteTemplate,
                            },
                        }),
                    })

                    if (values?.physical_exam?.vitals?.vital?.length > 0) {
                        const data = values?.physical_exam?.vitals?.vital.map(({ value = '', unit, label }) => {
                            const converter = {
                                Temperature: {
                                    unit: '[degF]',
                                    code: '[degF]',
                                    value:(() => {
                                        switch (unit) {
                                            case '°C':
                                                return convertToFahrenheit(value).toFixed(0)
                                            default:
                                                return value 
                                        }
                                    })(),
                                },
                                'Blood Pressure': {
                                    unit: 'mm[Hg]',
                                    code: 'mm[Hg]',
                                    systolicValue: value?.split?.('/')?.[0] || '',
                                    diastolicValue: value?.split?.('/')?.[1] || '',
                                },
                                'Pulse Ox': {
                                    unit: '%',
                                    code: '%',
                                    value,
                                },
                                'Respiratory Rate': {
                                    unit: '/min',
                                    code: '/min',
                                    value,
                                },
                                Height: {
                                    unit: 'cm',
                                    code: 'cm',
                                    value: (() => {
                                        switch (unit) {
                                            case 'ft/in':
                                                const values = value?.split?.('/')
                                                const ftValue = values?.[0]
                                                    ? values?.[0] * 30.48
                                                    : ''
                                                const inValue = values?.[1]
                                                    ? values?.[1] * 2.54
                                                    : ''

                                                let sum = 0
                                                if (ftValue && inValue) {
                                                    sum = ftValue + inValue
                                                } else if (ftValue) {
                                                    sum = ftValue
                                                } else if (inValue) {
                                                    sum = inValue
                                                }
                                                return sum ? parseFloat(sum).toFixed(2) : ''
                                            default:
                                                return value && (value * 2.54).toFixed(2)
                                        }
                                    })(),
                                },
                                Weight: {
                                    unit: 'kg',
                                    code: 'kg',
                                    value: (() => {
                                        switch (unit) {
                                            case 'lb':
                                                return value && (value * 0.45359237).toFixed(2)
                                            default:
                                                return value
                                        }
                                    })(),
                                },
                                BMI: {
                                    unit: 'kg/m2',
                                    code: 'kg/m2',
                                    value: value || '',
                                },
                            }

                            return converter[label]
                        })
                        const isEmpty = (obj, otherLabel) =>
                            !Boolean(obj?.value === undefined ? obj?.[otherLabel] : obj?.value)
                        if (
                            isEmpty(data[1], 'diastolicValue') &&
                            isEmpty(data[1], 'systolicValue') &&
                            isEmpty(data[0]) &&
                            isEmpty(data[2]) &&
                            isEmpty(data[3]) &&
                            isEmpty(data[4]) &&
                            isEmpty(data[5]) &&
                            isEmpty(data[6])
                        )
                            return
                        const vitalSigns = {
                            patient_id: clinicalNoteData?.patient?.id,
                            encounter_id: clinicalNoteData?.encounter_id,
                            temperature: data[0],
                            blood_pressure: data[1],
                            pulse_oximetry: data[2],
                            respiratory_rate: data[3],
                            height: data[4],
                            weight: data[5],
                            bmi: data[6],
                        }
                        await apiVitalSigns.create(vitalSigns)
                    }
                }}>
                {({ values, setFieldValue, actions, isValid }) => {
                    return (
                        <Form
                            style={{
                                width: '100%',
                                flex: 1,
                            }}>
                            {state.loading ? (
                                <LoadingPage />
                            ) : (
                                <>
                                    {/* <MultiSpecialityClinicalNote
                                        clinicalNoteData={clinicalNoteData}
                                        onChangeActiveView={changeActiveView}
                                    /> */}
                                    <Grid
                                        container
                                        wrap={'nowrap'}
                                        className={classes.clinical_note_container}
                                        style={{
                                            // height:"100%",
                                            height: 'calc(100vh - 100px)',
                                            overflowY: 'auto',
                                        }}>
                                        <Box
                                            sx={{
                                                background: '#ffffff',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                flex: 1,
                                                position: 'relative',
                                                overflow: 'hidden',
                                            }}>
                                            {/* <PatientDetails
                                                clinicalNoteData={clinicalNoteData}
                                                isConsultant={true}
                                                isConsultant={isConsultant}
                                                values={values}
                                                onChange={setFieldValue}
                                                submitFormRef={submitFormRef}
                                                initialValues={clinicalNoteInitialData}
                                                status={clinicalNoteData.status}
                                                role={role}
                                                noteDetails={noteDetails}
                                                categoryList={state.clinicalNoteTemplate}
                                            /> */}
                                            <PatientInfoHeader 
                                                clinicalNoteData={clinicalNoteData}
                                                values={values}
                                                onChange={setFieldValue}
                                                submitFormRef={submitFormRef}
                                                initialValues={clinicalNoteInitialData}
                                                nextEncounterDetails={nextEncounterDetails}
                                                onChangeActiveNote={changeActiveNote}
                                                categoryList={state.clinicalNoteTemplate}
                                                nonEditable={nonEditable}
                                            />
                                            <MedicalCategoryNotes
                                                clinicalNoteData={clinicalNoteData}
                                                configureTemplateDetails={configureTemplateDetails}
                                                handleChangeTemplate={handleChangeTemplate}
                                                categoryList={state.clinicalNoteTemplate}
                                                categoryNotes={categoryNotes}
                                                activeNote={activeNote}
                                                EqStatus={EqStatus}
                                                onChangeActiveNote={changeActiveNote}
                                                onChangeActiveView={changeActiveView}
                                                clinicalNoteId={clinicalNoteData.id}
                                                isConsultant={isConsultant}
                                            />
                                            <MedicalNotesView
                                                handleSetViewHealthyRecord={(value, text) => {
                                                    setActiveView('healthy_record.' + value)
                                                    setBackToText(text)
                                                }}
                                                EqStatus={EqStatus}
                                                isConsultant={isConsultant}
                                                status={clinicalNoteData.status}
                                                role={role}
                                                noteDetails={noteDetails}
                                                state={state}
                                                values={values}
                                                initialValues={clinicalNoteInitialData}
                                                activeNote={activeNote}
                                                onChange={setFieldValue}
                                                handlePreview={handlePreview}
                                                submitFormRef={submitFormRef}
                                                psycho_note={psycho_note}
                                                previous_cn={previous_cn}
                                                appointmentId={clinicalNoteData.appointment_id}
                                                fetchClinicalNote={onUpdate}
                                                clinicalNoteData={clinicalNoteData}
                                                appointmentDetails={appointment}
                                                handleSignAndLock={handleSignAndLock}
                                                handleGotoSession={handleGotoSession}
                                            />
                                            {activeView === 'preview' && (
                                                <NewPreviewNote
                                                    role={role}
                                                    status={clinicalNoteData.status}
                                                    PDFFile={PDFFile}
                                                    sigingAndLocking={sigingAndLocking}
                                                    requestingApproval={requestingApproval}
                                                    resendingEdit={resendingEdit}
                                                    handleRenderPDF={() => {
                                                        handleRenderPDF(values)
                                                    }}
                                                    handleSignAndLock={handleSignAndLock}
                                                    onChangeActiveView={changeActiveView}
                                                    handleRequestApprove={handleRequestApprove}
                                                    handleResendEdit={handleResendEdit}
                                                    isCreatorNote={isCreatorNote}
                                                    approveRequestCount={
                                                        clinicalNoteData?.approveRequestCount
                                                    }
                                                    supervisor={clinicalNoteData?.supervisor}
                                                    open={openPreviewModal}
                                                    handleClose={handleClosePreviewModal}
                                                />
                                            )}
                                        </Box>
                                        {!isEmpty(clinicalNoteData.patient) && (
                                            <MedicalHeader
                                                noteDetails={noteDetails}
                                                isConsultant={isConsultant}
                                                EqStatus={EqStatus}
                                                setEqStatus={setEqStatus}
                                                clinicalNoteData={clinicalNoteData}
                                                providerDetail={state.providerDetail}
                                                onChangeActiveView={changeActiveView}
                                                appointmentDetails={appointment}
                                                activeView={activeView}
                                                handleSetViewHealthyRecord={(value) =>
                                                    setActiveView('healthy_record.' + value)
                                                }
                                            />
                                        )}

                                        {/* <ConsultationList /> */}
                                    </Grid>
                                </>
                            )}
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default MedicalNotes
