import React from 'react'
import { IconButton as ButtonIconComponent } from '@material-ui/core'
import './index.scss'

const ButtonIcon = ({ icon, iconHover, ...props }) => {
    const [isHover, setIsHover] = React.useState(false)
    return (
        <ButtonIconComponent
            {...props}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            {isHover ? iconHover : icon}
            {props.children}
        </ButtonIconComponent>
    )
}

export default ButtonIcon
