import * as React from 'react'
import PropTypes from 'prop-types'
import SelectUnstyled, {
    selectUnstyledClasses,
} from '@mui/base/SelectUnstyled'
import OptionUnstyled, {
    optionUnstyledClasses,
} from '@mui/base/OptionUnstyled'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { styled } from '@mui/system'
import Typography from '@material-ui/core/Typography'
import { PopperUnstyled } from '@mui/base'

const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
}

const grey = {
    100: '#E7EBF0',
    200: '#E0E3E7',
    300: '#CDD2D7',
    400: '#B2BAC2',
    500: '#A0AAB4',
    600: '#6F7E8C',
    700: '#3E5060',
    800: '#2D3843',
    900: '#1A2027',
}

const StyledButton = styled('button')(
    ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: 40px;
  display:flex;
  width: 240px;
  background: ${
      theme.palette.mode === 'dark' ? grey[900] : '#fff'
  };
  border: 1px solid #E9ECF1;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  color: ${
      theme.palette.mode === 'dark' ? grey[300] : grey[900]
  };

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${
        theme.palette.mode === 'dark'
            ? blue[600]
            : blue[100]
    };
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }

  & img {
    margin-right: 10px;
  }
  `,
)

const TypographyEllipsis = styled(Typography)(
    ({ theme }) => `
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #303E4E;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,
)
const StyledListbox = styled('ul')(
    ({ theme }) => `
  box-sizing: border-box;
  margin: 10px 0;
  min-width: 240px;
  overflow: auto;
  outline: 0px;
  box-shadow: 0px 5px 20px rgba(165, 172, 181, 0.15);
  border-radius: 8px;
  background: #FFFFFF;
  padding: 8px 0;
  z-index: 100;
  position: relative;

  & .MuiSelectUnstyled-popper{
      z-index: 100;
  }
  `,
)

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
  list-style: none;
  padding: 8px 16px;
  border-radius: 0.45em;
  cursor: pointer;
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    color: ${
        theme.palette.mode === 'dark'
            ? blue[100]
            : blue[900]
    };
    font-weight: 500
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${
        theme.palette.mode === 'dark'
            ? grey[800]
            : grey[100]
    };
    color: ${
        theme.palette.mode === 'dark'
            ? grey[300]
            : grey[900]
    };
  }

  &.${optionUnstyledClasses.highlighted}.${
        optionUnstyledClasses.selected
    } {
   font-weight: 500
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${
        theme.palette.mode === 'dark'
            ? grey[700]
            : grey[400]
    };
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${
        theme.palette.mode === 'dark'
            ? grey[800]
            : grey[100]
    };
    color: ${
        theme.palette.mode === 'dark'
            ? grey[300]
            : grey[900]
    };
  }

  & img {
    margin-right: 10px;
  }
  `,
)

const StyledPopper = styled(PopperUnstyled)`
    z-index: 100;
`

const CustomSelect = React.forwardRef(function CustomSelect(
    props,
    ref,
) {
    const components = {
        Root: StyledButton,
        Listbox: StyledListbox,
        Popper: StyledPopper,
        ...props.components,
    }

    return (
        <SelectUnstyled
            {...props}
            ref={ref}
            components={components}
        />
    )
})

CustomSelect.propTypes = {
    /**
     * The components used for each slot inside the Select.
     * Either a string to use a HTML element or a component.
     * @default {}
     */
    components: PropTypes.shape({
        Listbox: PropTypes.elementType,
        Popper: PropTypes.func,
        Root: PropTypes.elementType,
    }),
}

function renderValue(option) {
    if (option === null) {
        return (
            <TypographyEllipsis>Status</TypographyEllipsis>
        )
    }

    return (
        <TypographyEllipsis>
            Status:{' '}
            <span style={{ fontWeight: 500 }}>
                {option?.label} ({option?.value?.amount})
            </span>
        </TypographyEllipsis>
    )
}

export default function Dropdown({
    options,
    onChange,
    value = 'All',
}) {
    const mappedValues = options.find(
        (option) => option.value === value,
    )

    return (
        <CustomSelect
            value={mappedValues}
            onChange={onChange}
            // listboxOpen={true}
            renderValue={renderValue}>
            {options.map((c) => (
                <StyledOption
                    key={c.key}
                    value={c}
                    label={c.name}>
                    <Typography>
                        {c.name} ({c.amount})
                    </Typography>
                    {c.key === value.key && (
                        <CheckRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 20,
                            }}
                        />
                    )}
                </StyledOption>
            ))}
        </CustomSelect>
    )
}
