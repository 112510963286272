import { Box, Dialog, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { styled } from '@material-ui/styles'
import BtnSwitchToggle from 'components/shared/Elements/BtnSwitchToggle'
import React from 'react'
import TemperatureChart from './chart/TemperatureChart'
import BloodPressureChart from './chart/BloodPressureChart'
import PulseOxChart from './chart/PulseOxChart'
import RespiratoryRateChart from './chart/RespiratoryRateChart'
import HeightChart from './chart/HeightChart'
import WeightChart from './chart/WeightChart'
import BMIChart from './chart/BMIChart'

const ChartDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        maxWidth: '2000px !important',
        width: '1500px !important',
        height: '650px !important',
    },
})

const VitalSignsTabs = styled(Tabs)({
    // borderBottom: '1px solid #e8e8e8',
    // margin: '0',
    minHeight: '40px',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
        left: 0,
    },
    '& .MuiTabs-flexContainer': {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    '& .MuiTab-root': {
        minHeight: '32px !important',
    },
})

const VitalSignsTab = styled(Tab)(({ theme }) => ({
    textTransform: 'unset !important',
    fontFamily: 'Rubik',
    fontSize: 14,
    backgroundColor: 'none',
    minWidth: 0,
    // minHeight: '400px',
    fontWeight: '400',
    // marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    flex: 1,
    '&:hover': {
        color: '#242424',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#242424',
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function ChartModal({
    openChart,
    setOpenChart,
    value,
    handleTabChange,
    a11yProps,
    temUnit,
    handleToggleTemparature,
    heightUnit,
    handleToggleHeight,
    weightUnit,
    handleToggleWeight,
    total,
    handleTotal,
    chartData,
}) {
    return (
        <ChartDialog open={openChart} onClose={() => setOpenChart(false)}>
            <Box style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Typography style={{ fontSize: 32, color: '#000' }}>Vital Diagrams</Typography>
                    <IconButton onClick={() => setOpenChart(false)}>
                        <Close />
                    </IconButton>
                </div>

                {/* chart display */}
                <Box sx={{ width: '100%', padding: '5px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'center',
                        }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '75%' }}>
                            <VitalSignsTabs
                                value={value}
                                onChange={handleTabChange}
                                aria-label="basic tabs example">
                                <VitalSignsTab
                                    label="Temperature"
                                    {...a11yProps(0)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="Blood Pressure"
                                    {...a11yProps(1)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="Pulse OX"
                                    {...a11yProps(2)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="Respiratory Rate"
                                    {...a11yProps(3)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="Height"
                                    {...a11yProps(4)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="Weight"
                                    {...a11yProps(5)}
                                    style={{ padding: 0 }}
                                />
                                <VitalSignsTab
                                    label="BMI"
                                    {...a11yProps(6)}
                                    style={{ padding: 0 }}
                                />
                            </VitalSignsTabs>
                        </Box>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <span
                                style={{
                                    // border: '1px solid #e0e0e0',
                                    height: '16px',
                                    padding: '4px',
                                    fontSize: '13px',
                                    marginRight: '10px',
                                    borderRadius: '3px',
                                    // boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                {value === 0 && (
                                    <BtnSwitchToggle
                                        label1={'F'}
                                        label2={'C'}
                                        unit={temUnit}
                                        handleToggle={handleToggleTemparature}
                                        style={{ width: 'unset' }}
                                    />
                                )}
                                {value === 4 && (
                                    <BtnSwitchToggle
                                        label1={'Ft'}
                                        label2={'in'}
                                        unit={heightUnit}
                                        handleToggle={handleToggleHeight}
                                        style={{ width: 'unset' }}
                                    />
                                )}
                                {value === 5 && (
                                    <BtnSwitchToggle
                                        label1={'lbs'}
                                        label2={'kg'}
                                        unit={weightUnit}
                                        handleToggle={handleToggleWeight}
                                        style={{ width: 'unset' }}
                                    />
                                )}
                            </span>
                            <div>
                                <select
                                    style={{
                                        border: '1px solid #e0e0e0',
                                        padding: '4px',
                                        fontSize: '13px',
                                        marginRight: '6px',
                                        borderRadius: '3px',
                                        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: 'white',
                                    }}
                                    value={total}
                                    onChange={(e) => {
                                        handleTotal(e.target.value)
                                    }}>
                                    <option value={30}>7 days</option>
                                    <option value={30}>14 days</option>
                                    <option value={30}>30 days</option>
                                    <option value={60}>60 days</option>
                                    <option value={90}>30 days</option>
                                    <option value={365}>12 months</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <CustomTabPanel value={value} index={0}>
                        <TemperatureChart
                            data={chartData?.temperature_data || []}
                            unit={temUnit}
                            openChart={openChart}
                            setOpenChart={setOpenChart}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <BloodPressureChart
                            data={chartData?.blood_pressure_data || []}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <PulseOxChart
                            data={chartData?.pulse_oximetry_data || []}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <RespiratoryRateChart
                            data={chartData?.respiratory_rate_data || []}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <HeightChart
                            data={chartData?.height_data || []}
                            unit={heightUnit}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                        <WeightChart
                            data={chartData?.weight_data || []}
                            unit={weightUnit}
                            model="large"
                            total={total}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={6}>
                        <BMIChart data={chartData?.bmi_data || []} model="large" total={total} />
                    </CustomTabPanel>
                </Box>
            </Box>
        </ChartDialog>
    )
}

export default ChartModal
