export const INITIAL_VALUES = {
    postCode: '',
    longCode: '',
    keywordId: '',
    noteTemplateId: '',
    specialty_id: '',
    comment: '',
    cptArr: [{ code: '', description: '' }],
    duration: '',
    type: '',
    color_tag: "#000000",
}
