import React, { useEffect, useState } from 'react'
import { Grid, Typography, TextField, Box, IconButton } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from '@material-ui/icons'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import Allergies from 'containers/PatientsSetting/view/Allergies'
import CopyBar from 'components/shared/Elements/CopyBar'
import { useNotification } from 'shared/elements/Notification'
import { drugAllergyStatus, getListAllergies } from 'services/Patient/patient'

const useStyles = makeStyles({
    itemCard: {
        width: 400,
        height: 500,
    },
    container: {
        minWidth: '60%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        color: '#303E4E',
        fontWeight: '500',
        fontSize: 14,
        letterSpacing: 0,
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    boxContainer: {
        borderRadius: 4,
    },
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 5,
        width: '97%',
    },
    fullWidth: {
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: 4,
        width: 28,
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionHeading_instruction: {
        marginBottom: 16,
        fontSize: 12,
        color: '#9CA9B8',
    },
})

const defaultValue = {
    allergy: '',
    reaction: '',
}

// const radioOptions = [
//     { label: 'Yes', value: 'Yes',disable:false },
//     { label: 'No known medication allergies', value: 'No', disable:false },
// ]

const PatientAllergies = ({
    fieldName,
    values,
    onChange,
    lastNarrative,
    allergyHistory,
    macros,
    patientId,
    encounter_id,
    handleSetViewHealthyRecord,
    patient,
    nonEditable = false
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const [radioOptions, setRadioOptions] = useState([
        { label: 'Yes', value: 'Yes', disable: nonEditable },
        { label: 'No known medication allergies', value: 'No', disable: nonEditable },
    ])
    const [valueData, setValueData] = useState(false)
    const classesCard = usePatientDashboardStyles()
    const handleAddMore = (action, index) => {
        const allergies = values?.allergies || []
        if (action === 'add') {
            onChange(`${fieldName}.allergies`, [...allergies, defaultValue])
        } else {
            const updatedValue = allergies.filter((v, i) => i !== index)
            onChange(
                `${fieldName}.allergies`,
                updatedValue.length > 0 ? updatedValue : [defaultValue],
            )
        }
    }

    const isEmptyAllergy = (allergy) => {
        return !(allergy.allergy.trim() || allergy.reaction.trim())
    }

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const fetchListAllergies = async () => {
        try {
            const resp = await getListAllergies(patientId, 1, 100000)
            onChange(`${fieldName}.allergies`, resp.data.data)
            if (resp.data.data.length) {
                setRadioOptions([
                    { label: 'Yes', value: 'Yes', disable: false },
                    { label: 'No known medication allergies', value: 'No', disable: true },
                ])
            } else {
                setRadioOptions([
                    { label: 'Yes', value: 'Yes', disable: false },
                    { label: 'No known medication allergies', value: 'No', disable: false },
                ])
            }
        } catch (ex) {
            notification(`Cannot get list allergies!`, 'error')
        }
    }
    const drugAllergyStatusChanged = async (value) => {
        if (value === 'Yes') {
            setValueData(false)
            await drugAllergyStatus(patientId, { no_allergy: false })
        } else {
            setValueData(true)
            await drugAllergyStatus(patientId, { no_allergy: true })
        }
    }

    // const drugAllergyStatusChanged = async (value) => {
    //     if (value === 'Yes') {
    //         await drugAllergyStatus(patientId, { no_allergy: false })
    //     } else {
    //         await drugAllergyStatus(patientId, { no_allergy: true })
    //     }
    // }

    // const drugAllergyStatusChanged = async (value) => {
    //     if (value === 'Yes') {
    //         await drugAllergyStatus(patientId, { no_allergy: false })
    //     } else {
    //         await drugAllergyStatus(patientId, { no_allergy: true })
    //     }
    // }

    useEffect(() => {
        fetchListAllergies()
    }, [patientId, patient.no_allergy])

    useState(() => {
        if (patientId && patient.no_allergy) {
            setValueData(true)
        }
    }, [])

    const afterComplete = () => {
        fetchListAllergies()
    }

    return (
        <Box className={classes.container}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                    margin: 5,
                    xs: 12,
                }}>
                <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
                <Typography className={classes.sectionHeading_instruction}>
                    Instruction: If you are filling this out on behalf of the patient, please answer
                    from the patient's perspective.
                </Typography>
                <Typography className={classes.sectionHeading}>
                    {questions['have_any_allergy']}
                </Typography>
                <Box>
                    <RadioOptions
                        md={6}
                        name={`${fieldName}.have_any_allergy`}
                        options={radioOptions}
                        value={valueData ? 'No' : 'Yes'}
                        onChange={(e) => {
                            const { name, value } = e.target
                            drugAllergyStatusChanged(e?.target?.value)
                            onChange(name, value === 'Yes')
                            onChange(`${fieldName}.narrative`, '')
                            if (value !== 'Yes') {
                                onChange(`${fieldName}.allergies`, [])
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box className={classes.boxContainer}>
                {/* <div style={{ margin: '0 0 20px 5px' }}>
                    <Typography>
                        {questions['have_any_allergy_sub']}
                    </Typography>
                </div>

                <Grid
                    container
                    className={classes.gridContainer}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            className={classes.labelText}>
                            Allergy
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography
                            className={classes.labelText}>
                            Allergic Reaction
                        </Typography>
                    </Grid>
                </Grid> */}
                {/* {allergyHistory.map((al, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: '#fafafa',
                            borderRadius: 8,
                            margin: '16px 0',
                            padding: '8px 0',
                        }}>
                        <Grid
                            container
                            className={
                                classes.gridContainer
                            }
                            spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled={
                                        !values[
                                            'have_any_allergy'
                                        ]
                                    }
                                    value={al.allergy}
                                    className={
                                        classes.textFieldWrap
                                    }
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid
                                    container
                                    alignItems="center">
                                    <Grid
                                        item
                                        className={
                                            classes.fullWidth
                                        }>
                                        <TextField
                                            disabled={
                                                !values[
                                                    'have_any_allergy'
                                                ]
                                            }
                                            value={
                                                al.reaction
                                            }
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values['have_any_allergy'] && (
                            <Box sx={{ width: 68 }} />
                        )}
                    </Box>
                ))}
                {values?.allergies?.map((al, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: '#fafafa',
                            borderRadius: 8,
                            margin: '16px 0',
                            padding: '8px 0',
                        }}>
                        <Grid
                            container
                            className={
                                classes.gridContainer
                            }
                            spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    disabled={
                                        !values[
                                            'have_any_allergy'
                                        ]
                                    }
                                    name="allergy"
                                    value={al.allergy}
                                    className={
                                        classes.textFieldWrap
                                    }
                                    variant="outlined"
                                    onChange={(e) => {
                                        handleTextChange(
                                            e.target.name,
                                            e.target.value,
                                            index,
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid
                                    container
                                    alignItems="center">
                                    <Grid
                                        item
                                        className={
                                            classes.fullWidth
                                        }>
                                        <TextField
                                            disabled={
                                                !values[
                                                    'have_any_allergy'
                                                ]
                                            }
                                            name="reaction"
                                            value={
                                                al.reaction
                                            }
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                            onChange={(
                                                e,
                                            ) => {
                                                handleTextChange(
                                                    e.target
                                                        .name,
                                                    e.target
                                                        .value,
                                                    index,
                                                )
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {values['have_any_allergy'] && (
                            <RenderAddOrRemove
                                index={index}
                                isEmpty={isEmptyAllergy(al)}
                            />
                        )}
                    </Box>
                ))}

                {values['have_any_allergy'] && (
                    <Grid item>
                        <Grid
                            container
                            className={
                                classes.cover__add__new
                            }
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                handleAddMore('add')
                            }>
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add new
                            </Typography>
                        </Grid>
                    </Grid>
                )} */}
                {!valueData && (
                    <Allergies
                        isInClinicalNote
                        patient_Id={patientId}
                        encounter_id={encounter_id}
                        afterActionOk={fetchListAllergies}
                        afterComplete={afterComplete}
                        nonEditable={nonEditable}
                    />
                )}
            </Box>
            {valueData && (
                <NarrativeAndMacro
                    hideClearBtn={true}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    meta={{
                        values,
                        master: {},
                    }}
                    onChange={onChange}
                    values={values}
                    nonEditable={nonEditable}
                />
            )}
        </Box>
    )
}

export default PatientAllergies
