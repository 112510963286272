import React from 'react'
import { getLoggedInUserRole } from '../../../utilities'
import MainProfile from '../MainProfile/MainProfile'
import PatientProfileDetails from './PatientProfileDetails'

const PatientProfile = (props) => {
    const userRole = getLoggedInUserRole()
    const tabsArr = [
        {
            label: 'profile',
            components: (
                <PatientProfileDetails {...props} />
            ),
        },
        // {
        //     label: 'appointments',
        //     components: (
        //         <AppointmentListCard
        //             userId={props.userId}
        //             role={UserRoles.PATIENT}
        //         />
        //     ),
        // },
        // { label: 'Health summary', components: null },
        // {
        //     label: 'clinical notes',
        //     components: (
        //         <ClinicalNotesListWrapper
        //             userId={props.userId}
        //             checkprovider={
        //                 userRole === UserRoles.PROVIDER
        //             }
        //         />
        //     ),
        // },
        // {
        //     label: 'Documents',
        //     components: (
        //         <Documents
        //             userid={props.userId}
        //             checkprovider={
        //                 userRole === UserRoles.PROVIDER
        //             }
        //         />
        //     ),
        // },
        // { label: 'labs', components: null },
        // { label: 'imaging', components: null },
        // { label: 'eRx', components: null },
    ]
    const { isCreateMode } = props

    return (
        <MainProfile
            tabsList={tabsArr}
            isCreateMode={isCreateMode}
        />
    )
}
export default PatientProfile
