import React from 'react'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Grid, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import moment from 'moment'
import { getCustomizedFormattedDate, getTimeFromDate } from 'utilities/dateOperations'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { ReactComponent as NoteCreateIcon } from 'assests/icons/NoteCreate.svg'
import { ReactComponent as NoteCreateCloseIcon } from 'assests/icons/NoteCreateClose.svg'
import { useSelector } from 'react-redux'
import { ReactComponent as NoteEditIcon } from 'assests/icons/NoteEditIcon.svg'
import './noteStyles.scss'

const NoteField = (props) => {
    const [currentNote, setCurrentNote] = React.useState(null)
    const userData = useSelector((state) => state?.login?.userData)

    const handleCloseEditNoteView = () => {
        props.onChange('noteView', 'no-view')
        setCurrentNote(null)
        props.onChange('note', '')
    }
    const handleEditNote = () => {
        const updatedNotes = props?.notes.map((item) =>
            item.id === currentNote.id
                ? {
                    ...item,
                    content: props.note,
                    created_at: moment(new Date()),
                    state: item?.content?.trim() !== props?.note ? 'Updated note' : 'Created note'
                }
                : item,
        )
        if (props?.handleNotesEditChange) {
            props?.handleNotesEditChange(updatedNotes)
        } else {
            props.onChange('notes', updatedNotes)
        }
        handleCloseEditNoteView()
    }


    const noteSort = (notes) => {
        const sortedNotes = notes.map(obj => obj).sort((a, b) => b.created_at - a.created_at);
        return sortedNotes
    }


    const handleViewNote = (noteDetails) => {
        props.onChange('noteView', 'view-only')

        setCurrentNote(noteDetails)
        props.onChange('note', noteDetails?.content)
    }

    const noteEndAdornments = () => {
        switch (props?.noteView) {

            case 'view-only':
                return (
                    <InputAdornment
                        position="end"
                        style={{
                            position: 'absolute',
                            left: '97%',
                            top: 20,
                        }}>
                        {/* <LoadingButton
                            type="submit"
                            loadingPosition="start"
                            style={{
                                height: '25px',
                                fontSize: '14px',
                                fontWeight: '400',
                                color: 'white',
                                backgroundColor: '#3f51b5',
                            }}
                            startIcon={<></>}
                            onClick={handleCloseEditNoteView}>
                            Close Note
                        </LoadingButton> */}
                        {userData['custom:user_id'] === currentNote?.created_by && <IconButton onClick={() => props.onChange('noteView', 'can-edit')}>
                            <NoteEditIcon style={{ marginLeft: 3 }} />
                        </IconButton>}
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: 45
                            }}
                            onClick={handleCloseEditNoteView}>
                            <NoteCreateCloseIcon />
                        </IconButton>
                    </InputAdornment>
                )

            case 'can-edit':
                return (
                    <InputAdornment
                        position="end"
                        style={{
                            position: 'absolute',
                            // left: '86%',
                            right: "10px",
                            top: '80%',
                        }}>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <IconButton onClick={handleCloseEditNoteView}>
                                <NoteCreateCloseIcon />
                            </IconButton>

                            <IconButton onClick={handleEditNote}>
                                <NoteCreateIcon />
                            </IconButton>
                        </span>
                    </InputAdornment>
                )

            case 'no-view':
                return (
                    props?.note && <InputAdornment
                        position="end"
                        style={{
                            position: 'absolute',
                            // left: '86%',
                            right: "10px",
                            top: '80%',
                        }}>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <IconButton onClick={handleCloseEditNoteView}>
                                <NoteCreateCloseIcon />
                            </IconButton>

                            <IconButton onClick={(event) => props?.handleNotesChange(event)}>
                                <NoteCreateIcon />
                            </IconButton>
                        </span>
                    </InputAdornment>
                )

            default: return <></>
        }
    }

    const noteStartAdornments = () => {
        switch (props?.noteView) {

            case 'no-view':
                return (
                    props?.note && <InputAdornment
                        position="start"
                        style={{
                            marginRight: '10px',
                            position: 'absolute',
                            top: '15%',
                            marginBottom: '20px',
                        }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12 }}>
                            User is now typing a new note
                        </Typography>
                    </InputAdornment>
                )

            case 'view-only':
            case 'can-edit':
                return (
                    <InputAdornment
                        position="start"
                        style={{
                            marginRight: '10px',
                            position: 'absolute',
                            top: '15%',
                            marginBottom: '20px',
                        }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12 }}>
                            Note written by {currentNote?.name}
                        </Typography>
                    </InputAdornment>
                )

            default: return <></>
        }
    }

    return (
        <Grid item spacing={2} container>
            <Grid item xs={12}>
                <TextBox
                    // label={`Notes ${props?.note && `(Outside Click for Save)`}`}
                    name="note"
                    value={props?.note}
                    sx={{
                        '& .MuiOutlinedInput-multiline': {
                            height: "auto"
                        },
                        '& .MuiOutlinedInput-inputMultiline': {
                            // position: props?.note ? 'relative' : 'static', // hidden because of Kavya's requirement for deployment of sprint-19 on May 2, 2024 (for SEHR-712, SEHR-711, SEHR-669, SEHR-670)
                            // top: 12
                        },
                    }}
                    multiline
                    minRows={4}
                    placeholder="Enter a note"
                    onChange={(value) => {
                        // setAutoSave(false)
                        props?.onChange('note', value)
                    }}
                    // onBlur={(e) => !currentNote && props?.onBlur(e)}
                    style={{ position: 'relative' }}
                    className={`${props?.noteView !== 'no-view' ? 'textFieldTop' : ''}`}
                    inputProps={{ readOnly: props?.noteView === 'view-only' }}
                    InputProps={{
                        // startAdornment: noteStartAdornments(), // hidden because of Kavya's requirement for deployment of sprint-19 on May 2, 2024 (for SEHR-712, SEHR-711, SEHR-669, SEHR-670)

                        endAdornment: props?.loading ? 
                            <CircularProgress style={{
                                width: 20,
                                height: 20,
                                bottom: 8,
                                position: 'absolute',
                                right: 10,
                            }}/> 
                            : noteEndAdornments()
                    }}
                />
            </Grid>

            {
                <fieldset
                    className="divider"
                    style={{ maxHeight: '180px', overflow: 'hidden scroll', width: '100%', margin: '8px 8px 8px 16px' }}>
                    <legend>
                        <Typography className={'heading1-title'}>History</Typography>
                    </legend>

                    {props?.notes?.length > 0 ? (
                        noteSort(props?.notes)?.map((item, index) => {
                            const isCurrentNote = currentNote?.id === item?.id
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%',
                                        marginBottom: 15,
                                        paddingLeft: 10,
                                        borderRadius: isCurrentNote ? 5 : 0,
                                        border: isCurrentNote ? '0.4px solid #D8D9DC' : 'none',
                                        background: isCurrentNote ? '#F5F5F5' : '#fff'
                                    }}
                                    key={index}>
                                    {/* <Typography>
                                        <strong>{item?.name}</strong> created note on{' '}
                                        <strong>
                                            {getCustomizedFormattedDate(item?.created_at) ||
                                                'Invalid Date'}
                                        </strong>{' '}
                                        at (<strong>{getTimeFromDate(item?.created_at)}</strong>)
                                    </Typography> */}
                                    <Typography>
                                        <strong>{item?.name}</strong>-{' '}
                                        {getCustomizedFormattedDate(item?.created_at) ||
                                            'Invalid Date'}{' '}
                                        at{' '}{getTimeFromDate(item?.created_at)}:{' '}
                                        {item?.state}
                                    </Typography>

                                    {/* <LoadingButton
                                        type="submit"
                                        loadingPosition="start"
                                        style={{
                                            height: '25px',
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#999',
                                            backgroundColor: '#fff',
                                        }}
                                        startIcon={<></>}
                                        endIcon={<KeyboardArrowDownIcon />}
                                        onClick={() => handleViewNote(item)}
                                        disabled={props?.noteView !== 'no-view'}>
                                        View Note
                                    </LoadingButton> */}
                                    <Typography
                                        style={{ marginRight: 10, cursor: 'pointer' }}
                                        onClick={() => handleViewNote(item)}>
                                        View
                                    </Typography>
                                </div>
                            )
                        })
                    ) : (
                        <Typography>No Notes Added Yet!</Typography>
                    )}
                </fieldset>
            }
        </Grid>
    )
}

export default NoteField
