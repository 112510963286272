import {
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    InputBase,
    makeStyles
} from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import Routes from 'constants/routes'
import NoteField from 'containers/WorkQueues/component/NoteField'
import { useFormik } from 'formik'
import { uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
    allApiReffringProvider,
    apiRaferrals,
    apiStatusCodes,
    getLocations,
    getSpecialty,
} from 'services/Calendar'
import { getCheckList, getPracticeById } from 'services/Setting'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { DATE_FORMAT } from './Constant'
import DatePickerInput from './DatePickerInput'
import { INITIAL_VALUES } from './Schema'
import './style.scss'
import { validationSchema } from './validationSchema'
const { v4: uuidv4 } = require('uuid')

const useStyles = makeStyles((theme) => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
    container: {
        height: '100%',
        padding: 24,
        position: 'relative',
        overflowY: 'auto',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        // marginBottom: '1rem',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    sub_header: {
        fontSize: 16,
        fontWeight: 500,
        color: '#303E4E',
    },
    border_text_grid: {
        background: '#ffffff',
        marginTop: '-24px',
        display: 'table',
        marginBottom: '20px',
    },
    required_text: {
        color: 'red',
    },
    item_ric: {
        background: '#fbfcfc',
        padding: '5px 15px',
        marginBottom: '13px',
        borderRadius: '7px',
        '& .icon': {
            '& svg': {
                width: '22px',
                color: '#6c6c6c',
            },
        },
    },
    btn_anew: {
        fontSize: '14px',
        fontWeight: '400',
    },
    wrap_scroll_list: {
        maxHeight: '469px',
        overflowY: 'auto',
    },
    input: {
        border: 'none',
        outline: 'none',
        backgroundColor:'#fff'
    },
}))

// const INNIT_DEFAULT_R_I_C = [
//     {
//         label: 'Consent form collected',
//         checked: false,
//     },
//     {
//         label: 'Medical intake forms collected',
//         checked: false,
//     },
//     {
//         label: 'Medical records collected',
//         checked: false,
//     },
//     {
//         label: 'Eligibility confirmed',
//         checked: false,
//     },
//     {
//         label: 'Payment guarantor confirmed',
//         checked: false,
//     },
//     {
//         label: 'Prior authorization obtained',
//         checked: false,
//     },
// ]

const SquareChip = styled('div')({
    backgroundColor: 'rgb(233, 236, 241)',
    borderRadius: '4px',
    gridGap: '10px',
    color: 'rgb(102, 118, 137)',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '18.2px',
    padding: '3px 16px',
    marginLeft: '3px',
    border: 'none',
})
export const ReferralsForm = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const { id: referralsID } = useParams()
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [loaddingBtnSubmit, setLoadingBtnSubmit] = useState(false)
    const [specialOptions, setSpecialOptions] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [clinicalPracticeData,setClinicalPracticeData] = useState({});
    const [practiceId,setPracticeId] = useState(null);
    const [refferralIntakeChecklist, setRefferralIntakeChecklist] = useState([])
    const [newTextRIC, setNewTextRIC] = useState('')
    const [isLoadingPractice,setIsLoadingPractice]=useState(false);
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const [checkListOptions, setCheckListOptions] = useState([])
    const [checkList, setCheckList] = useState('')

    const handleSubmit = async (values) => {
        if (!values.location_id) delete values.location_id
        if (!values.referring_provider_id) delete values.referring_provider_id
        if (!values.specialty_id) delete values.specialty_id
        delete values.clinical_practice
        const payload = {
            ...values,
            received_date: values.received_date.format(DATE_FORMAT),
            due_schedule: values.due_schedule.format(DATE_FORMAT),
        }
        const listChecked = refferralIntakeChecklist.map((item) => ({
            label: item.label,
            checked: item.checked,
        }))
        if (!referralsID) {
            try {
                setLoadingBtnSubmit(true)
                const res = await apiRaferrals.careate({
                    input: {
                        type: 'CREATE_PATIENT',
                        payload: { ...payload, checklist: listChecked },
                    },
                })
                notification('Create Referral successfully !', 'success')
                if (res?.status === 200) {
                    history.push(
                        (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                            .replace(':idReferral', res?.data?.referral?.id)
                            .replace(':idPatient', res?.data?.referral?.patient_id)
                            .replace(':viewType', 'edit'),
                    )
                }
                // history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN)
            } catch (error) {
                notification(error?.data ? error.data.message : 'Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
            }
        } else {
            try {
                setLoadingBtnSubmit(true)
                await apiRaferrals.update({
                    ...payload,
                    checklist: listChecked,
                })
                notification('Update Referral successfully !', 'success')
                history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN)
            } catch (error) {
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
            }
        }
    }
    const refetchCheckList = async () => {
        try {
            const res = await getCheckList({ page: 1, limit: 100000 })
            const convertData = res?.data?.data?.checklist?.map((item) => ({
                key: item.id,
                value: item.title,
                items: item.items
            }))
            setCheckListOptions(convertData || [])
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }
    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })
    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (referralsID === 0 || !referralsID) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = formik.values?.notes
            }
            formik.setFieldValue('notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )
    const [refferingLocationList, setRefferingLocationList] = useState([])

    const [optionRefarralStatus, setOptionRefarralStatus] = useState()

    const refetchRefferringProvider = async () => {
        try {
            const res = await allApiReffringProvider.getAll({ size: 1000 })
            setRefferingLocationList(
                res.data?.data.map((item) => ({
                    key: item.id,
                    value: `${item.first_name} ${item.last_name}`,
                    specialty: item.specialty?.specialty,
                    location: item.location?.name,
                })) || [],
            )
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }

    const getLocationExternal = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            if (locationExternal) {
                setLocationOptions(
                    locationExternal.map((item) => ({
                        key: item.id,
                        value: item.name,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    const getSpecialtyAll = async () => {
        try {
            const res = await getSpecialty({ size: 1000 })

            if (res.data?.data) {
                setSpecialOptions(
                    res.data?.data?.map((item) => ({
                        key: item.id,
                        value: item.specialty,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    const getRefarralStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'REFERRAL',
                limit: 100000,
            })
            setOptionRefarralStatus(
                res.data?.listStatusCode?.data.map((item) => ({
                    key: item.code,
                    value: item.name,
                })),
            )
            const statusDefault = res?.data?.listStatusCode?.data?.find((el) => el.is_default)
            if (statusDefault && !formik.values.status) {
                formik.setFieldValue('status', statusDefault.code)
            }
        } catch (error) {
            notification('Status not fetching !', 'error')
        }
    }
    const getPracticeData= async(id)=>{
        try{
            setIsLoadingPractice(true);
            const practiceData = await getPracticeById(id)
            setClinicalPracticeData(practiceData?.data)
        }catch(e){
            // console.log(e.message);
        }finally{
            setIsLoadingPractice(false)
        }
    }

    // useEffect(() => {
    //     if (!referralsID) {
    //         setRefferralIntakeChecklist(INNIT_DEFAULT_R_I_C)
    //     }
    // }, [])

    const handleDeleteRIC = (rowItem) => {
        const newRefferralIntakeCheck = refferralIntakeChecklist.filter(
            (item) => item.label !== rowItem.label,
        )
        setRefferralIntakeChecklist(newRefferralIntakeCheck)
    }
    const handleChangCheked = (rowItem, checked) => {
        const record = [...refferralIntakeChecklist]
        const index = record.findIndex((element) => element.label === rowItem.label)
        record[index] = {
            ...record[index],
            checked,
        }
        setRefferralIntakeChecklist(record)
    }
    const handleAddNew = () => {
        if (newTextRIC) {
            setRefferralIntakeChecklist([
                { label: newTextRIC, checked: false, ic_delete: true },
                ...refferralIntakeChecklist,
            ])
            setNewTextRIC('')
        }
    }

    const getRefferralById = async (id) => {
        setLoadingSchedule(true)
        try {
            const res = await apiRaferrals.getByID(id)
            const resData = res.data?.referral
            setInitData({
                referring_provider_id: resData?.referring_provider?.id,
                status: resData?.status,
                patient_id: resData?.patient?.id,
                location_id: resData?.location?.id,
                specialty_id: resData?.specialty?.id,
                checklist: resData?.checklist,
                notes: resData?.notes,
                received_date: moment(resData?.received_date, 'MM-DD-YYYY'),
                due_schedule: moment(resData?.due_schedule, 'MM-DD-YYYY'),
            })

            const concatCheckList = [...resData?.checklist]
            const uniqCheckList = uniqBy(concatCheckList, (e) => e.label)
            setRefferralIntakeChecklist(uniqCheckList || [])
        } catch (error) {
            if (error) {
                notification(error, 'error')
            }
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        let isMounted = true // Flag to track component's mounting status
        if (referralsID && isMounted) {
            getRefferralById(referralsID)
        }
        return () => {
            isMounted = false // Component is unmounted, update the flag
            abortController.abort()
        }
    }, [referralsID])

    useEffect(() => {
        let isMounted = true // Flag to track component's mounting status
        const abortController = new AbortController()
        if (isMounted) {
            Promise.allSettled([
                refetchRefferringProvider(),
                getLocationExternal(),
                getSpecialtyAll(),
                getRefarralStatus(),
                refetchCheckList()
            ])
        }
        return () => {
            isMounted = false // Component is unmounted, update the flag
            abortController.abort()
        }
    }, [])

    useEffect(()=>{
        if(practiceId){
            formik.setFieldValue('clinical_practice',practiceId)
            getPracticeData(practiceId)
        }else{
            formik.setFieldValue('clinical_practice','')
            setClinicalPracticeData({})
        }
    },[practiceId])


    return (
        <Grid container className={classes['container']}>
            <Grid container style={{ marginBottom: 16 }} className="Header-referrals">
                <Grid
                    item
                    xs={12}
                    style={{
                        width: 'fit-content',
                    }}>
                    <Box
                        style={{ cursor: 'pointer', display: 'inline-flex', marginBottom: '1rem' }}
                        onClick={() =>
                            history.push(
                                Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN,
                            )
                        }>
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />
                        <Typography className={classes['text_back']}>
                            Back to referrals list
                        </Typography>
                    </Box>
                </Grid>
                <Typography className={classes['header']}>
                    {referralsID ? 'Update Referrals' : 'Add Referrals'}
                </Typography>
            </Grid>
            <Grid
                container
                py={2}
                pb={4}
                sx={{ borderTop: '1px solid #ddd' }}
                style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes['sub_header']}>Referral Status:</Typography>
                <SquareChip>Draft</SquareChip>
            </Grid>
            <Grid container className="appointment-booking-container">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            left: 0,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item className="referrals-form">
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        {/*------------------------------------------------Referral Information----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset style={{ marginBottom: '28px', borderRadius: '5px' }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Referral Information:
                                    </Typography>
                                </legend>
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                        alignItems="flex-start">
                                        <Grid item xs={4}>
                                            <FormLabel
                                                component="p"
                                                className="input-form-label"
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                }}>
                                                Patient Name
                                                <span className={classes['required_text']}>*</span>
                                            </FormLabel>
                                            <SearchPatientInput
                                                autoLoading={referralsID ? true : false}
                                                disabled={false}
                                                setPracticeId={setPracticeId}
                                                value={formik.values.patient_id}
                                                handleAddRow={(value) => {
                                                    setIsLoadingPractice(true)
                                                    formik.setFieldValue(
                                                        'patient_id',
                                                        value ? value.id : '',
                                                    )
                                                }}
                                                inputChange={(value) => {
                                                    formik.setFieldValue('patient_id', value)
                                                }}
                                                error={
                                                    formik.touched.patient_id &&
                                                    Boolean(formik.errors.patient_id)
                                                }
                                                helperText={
                                                    formik.touched.patient_id &&
                                                    formik.errors.patient_id
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Referring Provider "
                                                required
                                                placeholder="Select..."
                                                name="provider"
                                                multiple={false}
                                                value={formik.values.referring_provider_id}
                                                displayEmpty={!formik.values.referring_provider_id}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.referring_provider_id &&
                                                    Boolean(formik.errors.referring_provider_id)
                                                }
                                                helperText={
                                                    formik.touched.referring_provider_id &&
                                                    formik.errors.referring_provider_id
                                                }
                                                options={refferingLocationList || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue(
                                                        'referring_provider_id',
                                                        value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            style={{
                                                position: 'relative',
                                            }}>
                                            <TextBox
                                                required
                                                label="Clinical Practice "
                                                placeholder="Clinical practice"
                                                name="practice"
                                                value={clinicalPracticeData.name}
                                                error={
                                                    formik.touched.clinical_practice &&
                                                    Boolean(formik.errors.clinical_practice)
                                                }
                                                helperText={
                                                    formik.touched.clinical_practice &&
                                                    formik.errors.clinical_practice
                                                }
                                                inputProps={{
                                                    maxLength: 35,
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                            {isLoadingPractice ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        position: 'absolute',
                                                        bottom: '1.3em',
                                                        right: '2em',
                                                    }}>
                                                    <CircularProgress size={'17px'} />
                                                </Box>
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Referral Status "
                                                placeholder="Select..."
                                                name="status"
                                                required
                                                multiple={false}
                                                value={formik.values.status}
                                                displayEmpty={!formik.values.status}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.status &&
                                                    Boolean(formik.errors.status)
                                                }
                                                helperText={
                                                    formik.touched.status && formik.errors.status
                                                }
                                                options={optionRefarralStatus || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue('status', value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Specialty"
                                                placeholder="Select..."
                                                name="specialty"
                                                multiple={false}
                                                value={formik.values.specialty_id}
                                                displayEmpty={!formik.values.specialty_id}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.specialty_id &&
                                                    Boolean(formik.errors.specialty_id)
                                                }
                                                helperText={
                                                    formik.touched.specialty_id &&
                                                    formik.errors.specialty_id
                                                }
                                                options={specialOptions || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue('specialty_id', value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Referring Location"
                                                placeholder="Select..."
                                                name="location"
                                                multiple={false}
                                                value={formik.values.location_id}
                                                displayEmpty={!formik.values.location_id}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.location_id &&
                                                    Boolean(formik.errors.location_id)
                                                }
                                                helperText={
                                                    formik.touched.location_id &&
                                                    formik.errors.location_id
                                                }
                                                options={locationOptions || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue('location_id', value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DatePickerInput
                                                height="40px"
                                                label={'Received Date'}
                                                valueDate={formik.values.received_date}
                                                onChange={(e) => {
                                                    formik.setFieldValue('received_date', e)
                                                    formik.setFieldValue(
                                                        'due_schedule',
                                                        moment(e).add(3, 'days'),
                                                    )
                                                }}
                                                maxDate={moment()}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <DatePickerInput
                                                height="40px"
                                                label={'Created Date'}
                                                valueDate={formik.values.created_date}
                                                onChange={(e) => {
                                                    formik.setFieldValue('created_date', e)
                                                    formik.setFieldValue(
                                                        'due_schedule',
                                                        moment(e).add(3, 'days'),
                                                    )
                                                }}
                                                maxDate={moment()}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <DatePickerInput
                                                height="40px"
                                                label={'Due To Date'}
                                                valueDate={formik.values.due_schedule}
                                                // onChange={(e) => {
                                                //     formik.setFieldValue('due_schedule', e)
                                                // }}
                                                addDay={3}
                                                helperText={
                                                    formik.touched.due_schedule &&
                                                    formik.errors.due_schedule
                                                }
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <DatePickerInput
                                                height="40px"
                                                label={'Date appointment first offered'}
                                                valueDate={
                                                    formik.values.date_appointment_first_offered
                                                }
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'date_appointment_first_offered',
                                                        e,
                                                    )
                                                }}
                                                helperText={
                                                    formik.touched.date_appointment_first_offered &&
                                                    formik.errors.date_appointment_first_offered
                                                }
                                                placeholder="MM/DD/YYYY"
                                            />
                                            <CheckboxOption
                                                // disabled={isUpdating && !editable}
                                                // name={`${fieldName}.no_concern`}
                                                option={{
                                                    label: 'Patient declined first appointment date offered',
                                                    value: false,
                                                }}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'patient_declined_first_appointment',
                                                        e.target.checked,
                                                    )
                                                }}
                                                // checked={values?.isRequireTranslator}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                            <CheckboxOption
                                                // disabled={isUpdating && !editable}
                                                // name={`${fieldName}.no_concern`}
                                                option={{
                                                    label: 'Require translator',
                                                    value: false,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('isRequireTranslator', e.target.checked)
                                                }}
                                            // checked={values?.isRequireTranslator}
                                            />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        {/*------------------------------------------------Reason for visit----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset style={{ marginBottom: '28px', borderRadius: '5px' }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Reason for visit:
                                    </Typography>
                                </legend>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    spacing={2}
                                    alignItems="flex-start">
                                    <Grid item xs={12}>
                                        <TextBox
                                            name="reason"
                                            value={formik.values.reason}
                                            multiline
                                            minRows={4}
                                            placeholder="Enter a reason"
                                            onChange={(value) =>
                                                formik.setFieldValue('reason', value)
                                            }
                                            error={
                                                formik.touched.reason &&
                                                Boolean(formik.errors.reason)
                                            }
                                            helperText={
                                                formik.touched.reason && formik.errors.reason
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        {/*------------------------------------------------Referral Intake Checklist----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset style={{ marginBottom: '28px', borderRadius: '5px' }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Referral Intake Checklist:
                                    </Typography>
                                </legend>
                                <Grid item xs={3}>
                                    <Select
                                        placeholder="Checklist Template"
                                        name="checklist"
                                        multiple={false}
                                        value={checkList}
                                        displayEmpty
                                        disabled={props.viewType === 'view'}
                                        style={{
                                            height: 40,
                                            marginBottom:10,
                                            width:205
                                        }}
                                        options={checkListOptions || []}
                                        input={<InputBase classes={{ input: classes.input }} />}
                                        onChange={(value) => {
                                            setCheckList(value)
                                            const checkListFilter = checkListOptions?.find(
                                                (obj) => obj.key === value,
                                            )

                                            const options = checkListFilter ? checkListFilter?.items.map((item) => ({label: item.item, checked: false, ic_delete: true})) : []

                                            setRefferralIntakeChecklist([
                                            //     {
                                            //         label: checkListFilter?.value,
                                            //         checked: false,
                                            //         ic_delete: true,
                                            //     },
                                                ...options,
                                                ...refferralIntakeChecklist,
                                            ])
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {refferralIntakeChecklist.length > 0 && (
                                        <Box className={classes['wrap_scroll_list']}>
                                            {refferralIntakeChecklist.map((item, index) => (
                                                <Box key={index} className={classes['item_ric']}>
                                                    <Stack
                                                        direction={'row'}
                                                        justifyContent={'space-between'}
                                                        alignItems={'center'}>
                                                        <FormControlLabel
                                                            label={item.label}
                                                            control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={item.checked}
                                                                    onChange={(e) =>
                                                                        handleChangCheked(
                                                                            item,
                                                                            e.target.checked,
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        {/* {item.ic_delete && ( */}
                                                        <Box
                                                            className="icon"
                                                            onClick={() => handleDeleteRIC(item)}>
                                                            <DeleteOutlineIcon
                                                                fontSize="small"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Box>
                                                        {/* )} */}
                                                    </Stack>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: '12px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={10}>
                                            <TextBox
                                                value={newTextRIC}
                                                placeholder="Enter Comment"
                                                onChange={(value) => setNewTextRIC(value)}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'end',
                                            }}>
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    cursor: 'pointer',
                                                    whiteSpace: 'nowrap',
                                                    marginTop: '5px',
                                                }}
                                                onClick={handleAddNew}>
                                                <AddCircleOutlineOutlined
                                                    style={{
                                                        color: '#14AE5C',
                                                        fontSize: 18,
                                                    }}
                                                />
                                                <Typography
                                                    style={{
                                                        color: '#14AE5C',
                                                        marginLeft: 8,
                                                    }}>
                                                    Add New item
                                                </Typography>
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        {/*------------------------------------------------Staff Notes----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset className="divider-information">
                                <legend>
                                    <Typography className={'heading1-title'}>
                                        Staff Notes
                                    </Typography>
                                </legend>
                                <NoteField
                                    note={formik?.values?.note}
                                    noteView={formik?.values?.noteView}
                                    notes={formik?.values?.notes}
                                    onChange={formik?.setFieldValue}
                                    // onBlur={handleNotesChange}
                                    handleNotesChange={handleNotesChange}
                                />
                            </fieldset>
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="flex-end" direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes['button_cancel']}
                                onClick={() =>
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN,
                                    )
                                }>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={loaddingBtnSubmit}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                disabled={false}
                                className={classes['button_create']}
                                onClick={(e) => formik.handleSubmit(e)}>
                                {!referralsID ? 'Save' : 'Update'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
