import React, { useState, useEffect } from 'react'
import {
    makeStyles,
    createStyles,
} from '@material-ui/styles'
import useChatContext from '../../../hooks/useChatContext/useChatContext'
import Grid from '@material-ui/core/Grid'
import {
    Badge,
    Card,
    Button,
    Box,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    TextField,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    Snackbar,
    useMediaQuery,
    Theme,
    FormHelperText,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ClearIcon from '@material-ui/icons/Clear'
import { config } from '../../../constants'
import { createDecipheriv } from 'crypto'

import clsx from 'clsx'
import CloseSmallIcon from '../../../icons/CloseSmallIcon'
import { ScheduleWindowHeader as useStyles } from './styles'
import listWaitingQueue from '../../../apis/listWaitingQueue'
import { WaitingQueue } from '../../../apis/createWaitingQueue'
import useProviderContext from '../../../hooks/useProviderContext/useProviderContext'
import removeUserFromWaitingQueue from '../../../apis/deleteFromWaitingQueue'
import getCurrentupcoming from '../../../apis/getCurrentUpcoming'
import { setCurrentApp } from '../../../apis/setCurrentApp'
import moment from 'moment'
import { getAppointmentDetails } from '../../../apis/fetchDetails'
import { number } from 'yup/lib/locale'
import _ from 'lodash'
import { set } from 'date-fns/esm'
import Alert from '@material-ui/lab/Alert'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    getPatientCount,
    getProviderAppointmentList,
    submitConsulationTracking,
    submitConsultationStatus,
    submitSurveyForm,
    deleteChatMessages,
} from '../../../apis/closingConsultation'
import isValid from 'date-fns/isValid'
import { useHistory, useParams } from 'react-router'
import { getUserDetailsForForm } from '../../../../../utilities/appointments'
import { useDispatch } from 'react-redux'
import {
    setBookAppointmentData,
    setSearchProviderData,
    setUserDetails,
} from '../../../../../store/actions/appointments'
import {
    STATUS_CODE,
    BOOKING_TYPE,
    INTERECT_WITH_DR,
} from '../../../../../constants/appointments'
import Routes from '../../../../../constants/routes'
import { useAppState } from '../../../state'
import { AddClinicalNotes } from '../../../../../containers/ClinicalNotes/api/services'

export default function ScheduleWindowHeader() {
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles()
    const {
        setIsScheduleWindowOpen,
        conversation,
        connect: chatConnect,
    } = useChatContext()
    const [
        isConsultationStatusFormModal,
        setIsConsultationStatusFormModal,
    ] = useState(false)
    const [consultationStatus, setConsultationStatus] =
        useState('')
    const [maxValue, setMaxValue] = useState(0)
    const [allMaxValue, setAllMaxValue] = useState(0)
    const [isAppointmentStatus, setIsAppointmentStatus] =
        useState(false)
    const [participantCount, setParticipantCount] =
        useState(0)
    const [followupDate, setFollowupDate] = useState<any>(2)
    const [dateType, setDateType] = useState('week')
    const [consultationAppid, setConsultationAppid] =
        useState('')
    const [selectedUserId, setSelectedUserId] = useState('')
    const [
        isAllConsultationStatusFormTable,
        setIsisAllConsultationStatusFormTable,
    ] = useState(false)
    const [
        allPendingAppointments,
        setAllPendingAppointments,
    ] = useState<any[]>([])
    const [allWaitingList, setAllWaitingList] = useState<
        any[]
    >([])
    const [invidualAppStatus, setInvidualAppStatus] =
        useState<any>('')
    const [
        allConsultationStatusFollowupIn,
        setAllConsultationStatusFollowupIn,
    ] = useState<any>(1)
    const [
        allConsultationDateType,
        setAllConsultationDateType,
    ] = useState<any>('weeks')
    const [isSnackBarOpen, setIsSnackBarOpen] =
        useState(false)
    const [errMsg, setErrMsg] = useState<any>('')
    const { user, getToken, isFetching } = useAppState()
    const {
        socket,
        currentUser,
        setCountWaitingUser,
        setListWaiting,
        waitingCount,
        listWaiting,
        upcoming,
        setupcoming,
        waitingList,
        setWaitingGroup,
        setcurrentAppointment,
    } = useProviderContext()
    const fetchWaitingList = async () => {
        try {
            if (currentUser) {
                let count = 0
                const res = await listWaitingQueue(
                    currentUser?.room_id,
                )
                setListWaiting(res.data)
                if (res.data && res.data.length > 0) {
                    res.data.map((el: any) => {
                        if (!el.is_admitted) {
                            count = count + 1
                        }
                    })
                    setCountWaitingUser(count)
                } else {
                    setCountWaitingUser(0)
                }
            }
        } catch (error) {
            console.log({ Errorwaitinglist: error })
        }
    }

    const getDurationInMinutes = (appointmentInfo: any) => {
        let startTime = moment(
            appointmentInfo.start,
            'HH:mm:ss a',
        )
        let endTime = moment(
            appointmentInfo.end,
            'HH:mm:ss a',
        )
        return moment(endTime).diff(
            moment(startTime),
            'minutes',
        )
    }

    const updateAppointment = async (
        appointment_id: any,
    ) => {
        // if (upcomingAppDetails && upcomingAppDetails.status == 200) {
        //   setupcoming(upcomingAppDetails.data);
        // }
        let provider = currentUser?.provider_id
        let appointmentDetails: any =
            await getAppointmentDetails(appointment_id)
        let upcomingAppDetails: any =
            await getCurrentupcoming(
                provider,
                appointmentDetails.data.end,
            )
        let appointmentDiff = getDurationInMinutes(
            appointmentDetails.data,
        )
        if (appointmentDetails && appointmentDetails.data) {
            let currentAppointment = {
                appointment_id: appointmentDetails.data.id,
                start: moment().utc().format('HH:mm:ss'),
                end: moment()
                    .add(appointmentDiff, 'minutes')
                    .utc()
                    .format('HH:mm:ss'),
            }

            let currentApp = await setCurrentApp(
                currentAppointment,
            )
            setcurrentAppointment(currentApp.data)
        }
        let upcoming = {
            Current:
                appointmentDetails &&
                appointmentDetails.data
                    ? appointmentDetails.data
                    : 'undefined',
            Upcoming:
                upcomingAppDetails &&
                upcomingAppDetails.data
                    ? upcomingAppDetails.data.Upcoming
                    : 'undefined',
        }
        setupcoming(upcoming)
    }

    const handleAdmitUser = (patient: WaitingQueue) => {
        const appointmentId = patient.appointment_id
        window.localStorage.setItem(
            'currAppId',
            appointmentId.toString(),
        )
        setWaitingGroup(
            waitingList.map((el: any) => {
                if (
                    patient.appointment_id ==
                    el.appointment_id
                ) {
                    el.list = el.list.map((pat: any) => {
                        if (pat.id == patient.id) {
                            pat.isLoading = true
                        }
                        return pat
                    })
                }
                return el
            }),
        )
        emitAdmit(patient)
    }

    const emitAdmit = async (patient: any) => {
        const message = patient
        let deleteStatus = true

        if (
            !(upcoming && upcoming.Current) ||
            patient.appointment_id !== upcoming.Current.id
        ) {
            if (
                window?.localStorage?.getItem(
                    'currentAppointment',
                ) &&
                window?.localStorage?.getItem(
                    'currentAppointment',
                ) != patient.appointment_id
            ) {
                setWaitingGroup(
                    waitingList.map((el: any) => {
                        if (
                            window?.localStorage?.getItem(
                                'currentAppointment',
                            ) == el.appointment_id
                        ) {
                            removeAll(el.list)
                        }
                        return el
                    }),
                )
                deleteStatus = await deleteChat(message)
            }
            updateAppointment(patient.appointment_id)
            clinicalNoteCration(patient.appointment_id)
        }
        if (socket.connected && deleteStatus) {
            socket.emit('admit_user', message)
            // handleConsulationTracking(patient.appointment_id, false);
        }
        localStorage.setItem(
            'currentAppointment',
            patient.appointment_id,
        )
    }

    const deleteChat = async (message: any) => {
        let deleteChatCH = await deleteChatMessages(
            conversation?.sid,
        )
        getToken(
            `${currentUser?.first_name} ${currentUser?.last_name}`,
            currentUser?.room_name,
            currentUser?.provider_id,
        )
            .then(async (token) => {
                // process.env
                //     .REACT_APP_DISABLE_TWILIO_CONVERSATIONS !==
                //     'true' && (await chatConnect(token))
                if (socket.connected) {
                    socket.emit('admit_user', message)
                }
                return true
            })
            .catch((err) => {
                console.log(err)
                return false
            })
        return false
    }

    const admitAll = (list: any) => {
        list.forEach(async (patient: any) => {
            if (socket.connected && !patient.is_admitted) {
                handleAdmitUser(patient)
            }
        })
    }

    const removeAll = (list: any) => {
        list.forEach(async (patient: any) => {
            if (socket.connected) {
                handleRemoveUser(patient)
            }
        })
    }

    const handleRemoveUser = async (
        patient: WaitingQueue,
    ) => {
        setWaitingGroup(
            waitingList.map((el: any) => {
                if (
                    patient.appointment_id ==
                    el.appointment_id
                ) {
                    el.list = el.list.map((pat: any) => {
                        if (pat.id == patient.id) {
                            pat.isLoading = true
                        }
                        return pat
                    })
                }
                return el
            }),
        )

        if (patient.id)
            await removeUserFromWaitingQueue(patient.id)
        socket.emit('remove_from_waiting_list', patient)
        if (
            patient.appointment_id ===
                upcoming?.Current.id &&
            patient.is_patient
        ) {
            let upcoming = {
                Current: 'undefined',
                Upcoming: 'undefined',
            }

            setupcoming(upcoming)
            setcurrentAppointment(undefined)
        }
        await fetchWaitingList()
        // handleConsulationTracking(patient.appointment_id, consultationStatus);
    }

    const handleStatusChange = (e: any) => {
        setConsultationStatus(e.target.value)
        setIsAppointmentStatus(false)
    }
    const handleFollowUpInDateType = (e: any) => {
        setDateType(e)
    }
    const handleFollowUpInDate = (e: any) => {
        const newValue = Math.min(Math.max(e, 0), maxValue)
        setFollowupDate(newValue)
    }

    useEffect(() => {
        if (dateType === 'week') {
            if (followupDate > 4) {
                setMaxValue(4)
                setFollowupDate(4)
            } else {
                setMaxValue(4)
            }
        } else {
            if (followupDate > 12) {
                setFollowupDate(12)
                setMaxValue(12)
            } else {
                setMaxValue(12)
            }
        }
    }, [dateType])

    const handleSurveyForm = async (
        appointment_id: any,
        user_id: any,
    ) => {
        let payload = {
            user_id: user_id,
            type: 'survey-form',
            provider_id: currentUser?.provider_id,
            appointment_id: Number(appointment_id),
        }
        let res = await submitSurveyForm(payload)
    }

    useEffect(() => {
        if (allConsultationDateType === 'weeks') {
            if (allConsultationStatusFollowupIn > 4) {
                setAllMaxValue(4)
                setAllConsultationStatusFollowupIn(4)
            } else {
                setAllMaxValue(4)
            }
        } else {
            if (allConsultationStatusFollowupIn > 12) {
                setAllConsultationStatusFollowupIn(12)
                setAllMaxValue(12)
            } else {
                setAllMaxValue(12)
            }
        }
    }, [allConsultationDateType])

    useEffect(() => {
        handleUpdateAllConsultationStats()
    }, [waitingList])

    const handleCMark = (val: any) => {
        setIsConsultationStatusFormModal(true)
        setConsultationAppid(val.appointment_id)
        setAllWaitingList(val.list)
        setSelectedUserId(val.user_id)
    }
    const handleConsulationTracking = async (
        appointment_id: any,
        consultation_status: any,
    ) => {
        let data = localStorage.getItem('roomName')
        let countPayload = { roomName: data }
        let countData = await getPatientCount(countPayload)
        if (countData) {
            let payload = {
                is_consultation_end: consultation_status,
                provider_id: currentUser?.provider_id,
                participants_count: countData.data.length,
                appointment_id: appointment_id,
            }
            let res = await submitConsulationTracking(
                payload,
            )
        }
    }
    const clinicalNoteCration = async (
        appointment_id: any,
    ) => {
        let clinicalNotePayload = {
            appointment_id: appointment_id,
        }
        await AddClinicalNotes(clinicalNotePayload)
    }

    const handleConsultationSubmit = async () => {
        if (consultationStatus === '') {
            setIsAppointmentStatus(true)
            return
        }
        let consultationFollowup = followupDate
        let followupInDays =
            dateType === 'week'
                ? consultationFollowup * 7
                : consultationFollowup * 30
        const data = [
            {
                appointment_id: consultationAppid,
                appointment_status: consultationStatus,
                followup_days: followupInDays,
                send_notification:
                    consultationStatus === 'fulfilled'
                        ? true
                        : false,
            },
        ]
        let res = await submitConsultationStatus(data)
        if (consultationStatus === 'fulfilled') {
            handleSurveyForm(
                consultationAppid,
                selectedUserId,
            )
        }
        Number(consultationAppid) &&
            handleConsulationTracking(
                Number(consultationAppid),
                true,
            )
        setIsConsultationStatusFormModal(false)
        // removeAll(allWaitingList);
    }

    const handleAllConsultationStats = async () => {
        let providerId = currentUser?.provider_id
        try {
            let res = await getProviderAppointmentList(
                providerId,
            )
            setIsisAllConsultationStatusFormTable(true)
            const list = res.data
            list.map(
                (data: any) => (
                    (data['dateType'] = 'weeks'),
                    (data['followup_days'] = 2)
                ),
            )
            setAllPendingAppointments(list)
        } catch (error) {
            console.log(error)
            setErrMsg('No appointment found')
            setIsSnackBarOpen(true)
        }
    }
    const handleUpdateAllConsultationStats = async () => {
        let providerId = currentUser?.provider_id
        try {
            let res = await getProviderAppointmentList(
                providerId,
            )
            const list = res.data
            list.map(
                (data: any) => (data['dateType'] = 'weeks'),
            )
            setAllPendingAppointments(list)
        } catch (error) {
            console.log(error)
        }
    }

    const handleCloseAllConsulStatus = () => {
        setIsisAllConsultationStatusFormTable(false)
        setInvidualAppStatus('')
        setAllConsultationStatusFollowupIn(1)
        setAllConsultationDateType('weeks')
    }

    const handleAllConsSubmit = async (
        val: any,
        i: any,
        isAccordion: any,
    ) => {
        let followupDays =
            val.dateType === 'weeks'
                ? val.followup_days * 7
                : val.followup_days * 30
        let appointmentStatus
        if (val.appointment_status === 'pending') {
            if (isAccordion === 'isAccordion') {
                appointmentStatus = 'fulfilled'
            } else {
                const list = [...allPendingAppointments]
                list[i]['isAppointmentStatusPending'] = true
                setAllPendingAppointments(list)
                appointmentStatus = val.appointment_status
                return
            }
        } else {
            appointmentStatus = val.appointment_status
        }
        const data = [
            {
                appointment_id: val.id,
                appointment_status: appointmentStatus,
                followup_days: followupDays,
                send_notification:
                    consultationStatus === 'fulfilled'
                        ? true
                        : false,
            },
        ]
        let currentAppointmentId = localStorage.getItem(
            'currentAppointment',
        )
        let res = await submitConsultationStatus(data)
        Number(val.id) &&
            handleConsulationTracking(Number(val.id), true)
        if (res) {
            if (val.appointment_status === 'fulfilled') {
                handleSurveyForm(val.id, val.user_id)
            }
        }
        handleAllConsultationStats()

        // if (val.id === currentAppointmentId) {
        //     removeAll(allWaitingList);

        // }
    }
    const handleSubmitAll = async () => {
        let data: any = []
        let currentAppointmentId = localStorage.getItem(
            'currentAppointment',
        )
        allPendingAppointments.map((item) => {
            if (
                item.appointment_status !== 'pending' &&
                item.isEdited
            ) {
                if (
                    item.appointment_status === 'fulfilled'
                ) {
                    handleSurveyForm(item.id, item.user_id)
                }
                Number(item.id) &&
                    handleConsulationTracking(
                        Number(item.id),
                        true,
                    )
                let followupInDays =
                    item.dateType === 'week'
                        ? item.followup_days * 7
                        : item.followup_days * 30
                data.push({
                    appointment_id: item.id,
                    appointment_status:
                        item.appointment_status,
                    followup_days: followupInDays,
                    send_notification:
                        consultationStatus === 'fulfilled'
                            ? true
                            : false,
                })
            }
        })
        console.log(data)
        if (data.length) {
            let onGoingAppointment = data.filter(
                (item: any) =>
                    item.appointment_id ===
                    currentAppointmentId,
            )
            let res = await submitConsultationStatus(data)
            if (res) {
                if (onGoingAppointment.length > 0) {
                    let onGoingAppointmentList =
                        waitingList &&
                        waitingList.filter(
                            (item: any) =>
                                item.appointment_id ===
                                currentAppointmentId,
                        )
                    // removeAll(onGoingAppointmentList);
                }
                handleAllConsultationStats()
            }
        }
        setIsisAllConsultationStatusFormTable(false)
        setInvidualAppStatus('')
        setAllConsultationStatusFollowupIn(1)
        setAllConsultationDateType('weeks')
    }

    const handleStatus = (val: any, i: any) => {
        setInvidualAppStatus(val)
        const list = [...allPendingAppointments]
        list[i]['appointment_status'] = val
        list[i]['isAppointmentStatusPending'] = false
        list[i]['isEdited'] = 'true'
        setAllPendingAppointments(list)
    }

    const handleFollowUp = (val: any, i: any) => {
        const newValue = Math.min(
            Math.max(val, 0),
            allMaxValue,
        )
        setAllConsultationStatusFollowupIn(newValue)
        const list = [...allPendingAppointments]
        list[i]['followup_days'] = newValue
        list[i]['isEdited'] = 'true'
        setAllPendingAppointments(list)
    }

    const handleDataType = (val: any, i: any) => {
        setAllConsultationDateType(val)
        const list = [...allPendingAppointments]
        list[i]['isEdited'] = 'true'
        if (val === 'weeks') {
            if (list[i]['followup_days'] > 4) {
                list[i]['followup_days'] = 4
                setAllPendingAppointments(list)
            } else {
                if (list[i]['followup_days'] > 12) {
                    list[i]['followup_days'] = 12
                    setAllPendingAppointments(list)
                }
            }
        }
        list[i]['dateType'] = val
        setAllPendingAppointments(list)
    }

    const handleFollowupClick = async (val: any) => {
        const { id } = val

        let currentUrl = window.location.origin.toString()
        let url = `${currentUrl}${Routes.APPOINTMENTS.PROVIDER_LIST}/${id}`
        window.open(url, 'blank')
    }

    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    )

    return (
        <>
            <div className={classes.container}>
                <Grid
                    container
                    className={
                        classes.mainProfileContainer
                    }>
                    <Grid
                        item
                        xs={6}
                        className={classes.mt4}>
                        {/* <span className={classes.font18}>{waitingCount} Waiting</span> */}
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        className={[
                            classes.textRight,
                            classes.mt4,
                        ].join(' ')}>
                        {/* <Button onClick={() => admitAll()} className={[classes.font18, classes.primaryColor].join(" ")}>
            Admit all
          </Button> */}
                    </Grid>
                    <Grid item xs={1}>
                        <button
                            className={
                                classes.closeChatWindow
                            }
                            onClick={() =>
                                setIsScheduleWindowOpen(
                                    false,
                                )
                            }>
                            <CloseSmallIcon />
                        </button>
                    </Grid>
                </Grid>

                <hr className={classes.horizontalLine} />
                <>
                    {waitingList?.length > 0 ? (
                        <>
                            {waitingList.length > 0 ? (
                                <>
                                    {waitingList.map(
                                        (val: any) => {
                                            return (
                                                <>
                                                    <Card
                                                        className={
                                                            classes.mainProfileContainer
                                                        }>
                                                        <Grid
                                                            item
                                                            xs={
                                                                12
                                                            }
                                                            className={
                                                                classes.m4
                                                            }>
                                                            <Grid
                                                                container
                                                                className={[
                                                                    classes.mainProfileContainer,
                                                                ].join(
                                                                    ' ',
                                                                )}>
                                                                <Grid
                                                                    item
                                                                    xs={
                                                                        8
                                                                    }
                                                                    className={
                                                                        classes.mt4
                                                                    }>
                                                                    <span
                                                                        className={
                                                                            classes.font18
                                                                        }>
                                                                        {
                                                                            val.status
                                                                        }{' '}
                                                                        {val.status !==
                                                                        'Ongoing Event'
                                                                            ? moment
                                                                                  .utc(
                                                                                      `${val.date} ${val.start}`,
                                                                                  )
                                                                                  .local()
                                                                                  .format(
                                                                                      'hh:mm A',
                                                                                  )
                                                                            : null}
                                                                    </span>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={
                                                                        4
                                                                    }
                                                                    className={[
                                                                        classes.textRight,
                                                                        classes.mt4,
                                                                    ].join(
                                                                        ' ',
                                                                    )}>
                                                                    <Button
                                                                        onClick={() =>
                                                                            admitAll(
                                                                                val.list,
                                                                            )
                                                                        }
                                                                        className={[
                                                                            classes.font18,
                                                                            classes.primaryColor,
                                                                        ].join(
                                                                            ' ',
                                                                        )}>
                                                                        Admit
                                                                        all
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>

                                                            <hr
                                                                className={
                                                                    classes.horizontalLine
                                                                }
                                                            />
                                                            {val.list.map(
                                                                (
                                                                    pat: any,
                                                                    index: any,
                                                                ) => {
                                                                    return (
                                                                        <Grid
                                                                            key={
                                                                                index
                                                                            }
                                                                            container
                                                                            className={[
                                                                                classes.mainProfileContainer,
                                                                                classes.mt8,
                                                                                classes.pr8,
                                                                            ].join(
                                                                                ' ',
                                                                            )}>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    6
                                                                                }>
                                                                                <Typography
                                                                                    className={[
                                                                                        classes.font16,
                                                                                        classes.wordTextWrap,
                                                                                    ].join(
                                                                                        ' ',
                                                                                    )}>
                                                                                    <strong>{`${pat.first_name} ${pat.last_name}`}</strong>
                                                                                </Typography>
                                                                                <Badge
                                                                                    className={[
                                                                                        classes.greyColor,
                                                                                        classes.pl2,
                                                                                        classes.pr2,
                                                                                        classes.font11,
                                                                                        classes.badge,
                                                                                        classes.p1,
                                                                                        classes.borderR5,
                                                                                    ].join(
                                                                                        ' ',
                                                                                    )}>
                                                                                    {pat.relationship ===
                                                                                        'self' &&
                                                                                    pat.is_patient
                                                                                        ? 'Patient'
                                                                                        : pat.relationship}
                                                                                </Badge>
                                                                            </Grid>
                                                                            {pat.isLoading ? (
                                                                                <CircularProgress />
                                                                            ) : (
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    container
                                                                                    className={[
                                                                                        classes.mt2,
                                                                                        classes.buttonWrapper,
                                                                                    ].join(
                                                                                        ' ',
                                                                                    )}>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            5
                                                                                        }>
                                                                                        {!pat.is_admitted ? (
                                                                                            <Button
                                                                                                onClick={() =>
                                                                                                    handleAdmitUser(
                                                                                                        pat,
                                                                                                    )
                                                                                                }
                                                                                                className={[
                                                                                                    classes.AdmitButton,
                                                                                                    classes.w100,
                                                                                                ].join(
                                                                                                    ' ',
                                                                                                )}>
                                                                                                Admit
                                                                                            </Button>
                                                                                        ) : null}
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            5
                                                                                        }>
                                                                                        <Button
                                                                                            onClick={() =>
                                                                                                handleRemoveUser(
                                                                                                    pat,
                                                                                                )
                                                                                            }
                                                                                            className={[
                                                                                                classes.removeButton,
                                                                                                classes.w100,
                                                                                            ].join(
                                                                                                ' ',
                                                                                            )}>
                                                                                            Remove
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        </Grid>
                                                                    )
                                                                },
                                                            )}
                                                            {val.list.some(
                                                                (
                                                                    li: any,
                                                                ) =>
                                                                    li.is_admitted,
                                                            ) && (
                                                                <Button
                                                                    className={
                                                                        classes.followupBtn
                                                                    }
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        handleFollowupClick(
                                                                            val,
                                                                        )
                                                                    }>
                                                                    Book
                                                                    follow-up
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                        {val.list.some(
                                                            (
                                                                li: any,
                                                            ) =>
                                                                li.is_admitted,
                                                        ) && (
                                                            <Grid
                                                                container
                                                                className={[
                                                                    classes.consultationStatusBg,
                                                                    ,
                                                                    classes.mt20,
                                                                ].join(
                                                                    ' ',
                                                                )}>
                                                                <Grid
                                                                    item
                                                                    xs={
                                                                        12
                                                                    }
                                                                    className={[
                                                                        classes.consultationStatusWrap,
                                                                        classes.pr8,
                                                                        classes.dFlex,
                                                                    ].join(
                                                                        ' ',
                                                                    )}>
                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            9
                                                                        }
                                                                        className={
                                                                            classes.font20
                                                                        }>
                                                                        Consultation
                                                                        Status
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            3
                                                                        }
                                                                        className={
                                                                            classes.ml19
                                                                        }>
                                                                        <Button
                                                                            className={[
                                                                                classes.markBtn,
                                                                            ].join(
                                                                                ' ',
                                                                            )}
                                                                            onClick={() =>
                                                                                handleCMark(
                                                                                    val,
                                                                                )
                                                                            }>
                                                                            Mark
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Card>
                                                </>
                                            )
                                        },
                                    )}
                                </>
                            ) : (
                                <>
                                    <div
                                        className={
                                            classes.noPatient
                                        }>
                                        {' '}
                                        <strong></strong>No
                                        Patients{' '}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <div
                                className={
                                    classes.noPatient
                                }>
                                {' '}
                                <strong></strong>No Patients{' '}
                            </div>
                        </>
                    )}
                </>

                <Dialog
                    open={isConsultationStatusFormModal}
                    onClose={() => {
                        setIsConsultationStatusFormModal(
                            false,
                        )
                        setFollowupDate(2)
                        setConsultationStatus('')
                        setIsAppointmentStatus(false)
                    }}
                    fullWidth={isMobile ? false : true}
                    maxWidth="sm"
                    className={classes.dialogPaperMain}>
                    <div
                        className={
                            classes.consultationFormDialogModal
                        }>
                        {!isMobile ? (
                            <>
                                <ClearIcon
                                    className={
                                        classes.closeIcon
                                    }
                                    onClick={() => {
                                        setIsConsultationStatusFormModal(
                                            false,
                                        )
                                        setFollowupDate(2)
                                        setConsultationStatus(
                                            '',
                                        )
                                        setIsAppointmentStatus(
                                            false,
                                        )
                                    }}
                                />
                                <DialogTitle
                                    id="alert-modal-title"
                                    className={
                                        classes.textCenter
                                    }>
                                    <span
                                        className={
                                            classes.consultationHead
                                        }>
                                        Consultation Status
                                    </span>
                                </DialogTitle>
                            </>
                        ) : (
                            <DialogTitle
                                id="alert-modal-title"
                                style={{
                                    background: '#132044',
                                }}>
                                <div
                                    className={[
                                        classes.consultationHead,
                                        classes.dFlexSB,
                                    ].join(' ')}>
                                    Consultation Status
                                    <ClearIcon
                                        onClick={() => {
                                            setIsConsultationStatusFormModal(
                                                false,
                                            )
                                            setFollowupDate(
                                                2,
                                            )
                                        }}
                                    />
                                </div>
                            </DialogTitle>
                        )}
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        className={[
                                            classes.consultationLabel,
                                            classes.ptM1,
                                        ].join(' ')}>
                                        Appointment status
                                    </Typography>
                                </Grid>
                                <RadioGroup
                                    row
                                    aria-label="consultation_status"
                                    // defaultValue="fulfilled"
                                    name="radio-buttons-group"
                                    className={
                                        classes.radioGrpWrap
                                    }
                                    onChange={(e) =>
                                        handleStatusChange(
                                            e,
                                        )
                                    }>
                                    <FormControlLabel
                                        className={[
                                            classes.width50,
                                            classes.consultationLabel,
                                        ].join(' ')}
                                        value="fulfilled"
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                        label="Complete"
                                    />
                                    <FormControlLabel
                                        className={[
                                            classes.width50,
                                            classes.consultationLabel,
                                        ].join(' ')}
                                        value="noshow"
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                        label="No Show"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                {isAppointmentStatus && (
                                    <FormHelperText error>
                                        Please select
                                        Appoinment status
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.mt40}>
                                <Typography
                                    className={
                                        classes.consultationLabel
                                    }>
                                    Follow-up in
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.mt20}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <TextField
                                    id="input-passcode"
                                    onChange={(e) =>
                                        handleFollowUpInDate(
                                            e.target.value,
                                        )
                                    }
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    value={followupDate}
                                    style={{
                                        width: '80px',
                                        marginRight: '1em',
                                    }}
                                    InputProps={{
                                        inputProps: {
                                            max: maxValue,
                                            min: 0,
                                        },
                                    }}
                                />
                                <RadioGroup
                                    row
                                    aria-label="followup_after"
                                    defaultValue="week"
                                    name="radio-buttons-group"
                                    className={
                                        classes.radioGrpWrap
                                    }
                                    onChange={(e) =>
                                        handleFollowUpInDateType(
                                            e.target.value,
                                        )
                                    }>
                                    <FormControlLabel
                                        className={[
                                            classes.width50,
                                            classes.consultationLabel,
                                        ].join(' ')}
                                        value="week"
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                        label="Week(s)"
                                    />

                                    <FormControlLabel
                                        className={[
                                            classes.width50,
                                            classes.consultationLabel,
                                        ].join(' ')}
                                        value="month"
                                        control={
                                            <Radio
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                        label="Month(s)"
                                    />
                                </RadioGroup>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            style={{
                                justifyContent: 'center',
                            }}>
                            <Button
                                onClick={() => {
                                    setIsConsultationStatusFormModal(
                                        false,
                                    )
                                    setFollowupDate(2)
                                    setConsultationStatus(
                                        '',
                                    )
                                    setIsAppointmentStatus(
                                        false,
                                    )
                                }}
                                className={clsx(
                                    classes.btnBorder,
                                    classes.mr30,
                                )}
                                color="primary">
                                Discard
                            </Button>
                            <Button
                                onClick={
                                    handleConsultationSubmit
                                }
                                className={
                                    classes.submitBtn
                                }
                                color="primary">
                                Submit
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>

                <Dialog
                    open={isAllConsultationStatusFormTable}
                    onClose={handleCloseAllConsulStatus}
                    fullWidth={isMobile ? false : true}
                    maxWidth="md"
                    className={classes.dialogPaperMain}>
                    <div
                        className={
                            classes.consultationFormDialogModal
                        }>
                        {!isMobile ? (
                            <>
                                <ClearIcon
                                    className={
                                        classes.closeIcon
                                    }
                                    onClick={
                                        handleCloseAllConsulStatus
                                    }
                                />
                                <DialogTitle
                                    id="alert-modal-title"
                                    className={
                                        classes.textCenter
                                    }>
                                    <span
                                        className={
                                            classes.consultationHead
                                        }>
                                        All Consultations
                                    </span>
                                </DialogTitle>
                            </>
                        ) : (
                            <DialogTitle
                                id="alert-modal-title"
                                style={{
                                    background: '#132044',
                                }}>
                                <div
                                    className={[
                                        classes.consultationHead,
                                        classes.dFlexSB,
                                    ].join(' ')}>
                                    All Consultations
                                    <ClearIcon
                                        onClick={
                                            handleCloseAllConsulStatus
                                        }
                                    />
                                </div>
                            </DialogTitle>
                        )}
                        <DialogContent>
                            {!isMobile ? (
                                <TableContainer>
                                    <Table
                                        className={
                                            classes.tableRoot
                                        }>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    className={clsx(
                                                        classes.trBorderRight,
                                                        classes.fontWeight,
                                                    )}>
                                                    Appointment
                                                    Date &
                                                    Time
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(
                                                        classes.trBorderRight,
                                                        classes.fontWeight,
                                                    )}>
                                                    Patient
                                                    Name
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(
                                                        classes.trBorderRight,
                                                        classes.fontWeight,
                                                    )}>
                                                    Appointment
                                                    Status
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(
                                                        classes.trBorderRight,
                                                        classes.fontWeight,
                                                    )}>
                                                    Follow-up
                                                    in
                                                </TableCell>
                                                <TableCell
                                                    className={clsx(
                                                        classes.trBorderRight,
                                                        classes.fontWeight,
                                                    )}>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allPendingAppointments.map(
                                                (
                                                    item,
                                                    i,
                                                ) => (
                                                    <TableRow
                                                        key={
                                                            item.id
                                                        }>
                                                        <TableCell
                                                            className={
                                                                classes.trBorderRight
                                                            }>
                                                            {' '}
                                                            {moment
                                                                .utc(
                                                                    `${item.date} ${item.start}`,
                                                                )
                                                                .local()
                                                                .format(
                                                                    'MM/DD/YYYY',
                                                                )}{' '}
                                                            &nbsp;
                                                            &nbsp;&nbsp;
                                                            {moment
                                                                .utc(
                                                                    `${item.date} ${item.start}`,
                                                                )
                                                                .local()
                                                                .format(
                                                                    'hh:mm A',
                                                                )}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.trBorderRight
                                                            }>
                                                            <p>
                                                                {
                                                                    item.first_name
                                                                }{' '}
                                                                &nbsp;
                                                                {
                                                                    item.last_name
                                                                }
                                                            </p>
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.trBorderRight
                                                            }>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={
                                                                    item.appointment_status
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) =>
                                                                    handleStatus(
                                                                        e
                                                                            .target
                                                                            .value,
                                                                        i,
                                                                    )
                                                                }
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                disableUnderline
                                                                variant="outlined"
                                                                placeholder="Select">
                                                                <MenuItem
                                                                    className={[
                                                                        classes.placeholderText,
                                                                    ].join(
                                                                        ' ',
                                                                    )}
                                                                    disabled
                                                                    value="pending">
                                                                    Select
                                                                </MenuItem>

                                                                <MenuItem value="fulfilled">
                                                                    Complete
                                                                </MenuItem>
                                                                <MenuItem value="noshow">
                                                                    No
                                                                    Show
                                                                </MenuItem>
                                                            </Select>
                                                            {item.isAppointmentStatusPending && (
                                                                <FormHelperText
                                                                    error>
                                                                    Please
                                                                    select
                                                                    Appoinment
                                                                    status
                                                                </FormHelperText>
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.trBorderRight
                                                            }>
                                                            <Grid
                                                                item
                                                                xs={
                                                                    12
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                }}>
                                                                <TextField
                                                                    onChange={(
                                                                        e,
                                                                    ) =>
                                                                        handleFollowUp(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            i,
                                                                        )
                                                                    }
                                                                    type="number"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    value={
                                                                        item.followup_days
                                                                    }
                                                                    style={{
                                                                        width: '120px',
                                                                        marginRight:
                                                                            '1em',
                                                                    }}
                                                                    InputProps={{
                                                                        inputProps:
                                                                            {
                                                                                max: allMaxValue,
                                                                                min: 0,
                                                                                style: {
                                                                                    paddingTop:
                                                                                        '12px',
                                                                                    paddingBottom:
                                                                                        '12px',
                                                                                },
                                                                            },
                                                                    }}
                                                                />
                                                                <Select
                                                                    value={
                                                                        item.dateType
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                    ) =>
                                                                        handleDataType(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            i,
                                                                        )
                                                                    }
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    disableUnderline
                                                                    variant="outlined">
                                                                    <MenuItem value="weeks">
                                                                        Week(s)
                                                                    </MenuItem>
                                                                    <MenuItem value="months">
                                                                        Month(s)
                                                                    </MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.trBorderRight
                                                            }>
                                                            <p
                                                                className={
                                                                    classes.tableSubmit
                                                                }
                                                                onClick={() =>
                                                                    handleAllConsSubmit(
                                                                        item,
                                                                        i,
                                                                        '',
                                                                    )
                                                                }>
                                                                Submit
                                                            </p>
                                                        </TableCell>
                                                    </TableRow>
                                                ),
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                allPendingAppointments.map(
                                    (item, i) => (
                                        <Accordion
                                            style={{
                                                margin: '1em 0',
                                            }}>
                                            <AccordionSummary
                                                expandIcon={
                                                    <ExpandMoreIcon />
                                                }
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{
                                                    alignItems:
                                                        'flex-start',
                                                }}>
                                                <div>
                                                    <p
                                                        className={
                                                            classes.accordionLabel
                                                        }>
                                                        Patient
                                                        Name
                                                    </p>
                                                    <p
                                                        className={
                                                            classes.accordionValue
                                                        }>{`${item.first_name} ${item.last_name}`}</p>

                                                    <p
                                                        className={
                                                            classes.accordionLabel
                                                        }>
                                                        Appointment
                                                        Date
                                                        &
                                                        Time
                                                    </p>
                                                    <p
                                                        className={
                                                            classes.accordionValue
                                                        }>
                                                        {/* {
                                                            moment(
                                                                item.date
                                                            ).format(
                                                                'MM/DD/YYYY',
                                                            )

                                                        }
                                                        &nbsp;
                                                        &nbsp;&nbsp;
                                                        {
                                                            item.start
                                                        } */}
                                                        {moment
                                                            .utc(
                                                                `${item.date} ${item.start}`,
                                                            )
                                                            .local()
                                                            .format(
                                                                'MM/DD/YYYY',
                                                            )}{' '}
                                                        &nbsp;
                                                        &nbsp;&nbsp;
                                                        {moment
                                                            .utc(
                                                                `${item.date} ${item.start}`,
                                                            )
                                                            .local()
                                                            .format(
                                                                'hh:mm A',
                                                            )}
                                                    </p>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{
                                                    flexDirection:
                                                        'column',
                                                }}>
                                                <Grid
                                                    container>
                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }>
                                                        <Typography
                                                            className={[
                                                                classes.accordionLabel,
                                                            ].join(
                                                                ' ',
                                                            )}>
                                                            Appointment
                                                            status
                                                        </Typography>
                                                    </Grid>
                                                    <RadioGroup
                                                        row
                                                        aria-label="consultation_status"
                                                        // defaultValue="fulfilled"
                                                        name="radio-buttons-group"
                                                        className={
                                                            classes.radioGrpWrap
                                                        }
                                                        onChange={(
                                                            e,
                                                        ) =>
                                                            handleStatus(
                                                                e
                                                                    .target
                                                                    .value,
                                                                i,
                                                            )
                                                        }>
                                                        <FormControlLabel
                                                            className={[
                                                                classes.width50,
                                                                classes.consultationLabel,
                                                            ].join(
                                                                ' ',
                                                            )}
                                                            value="fulfilled"
                                                            control={
                                                                <Radio
                                                                    size="small"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Complete"
                                                        />
                                                        <FormControlLabel
                                                            className={[
                                                                classes.consultationLabel,
                                                            ].join(
                                                                ' ',
                                                            )}
                                                            value="noshow"
                                                            control={
                                                                <Radio
                                                                    size="small"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="No Show"
                                                        />
                                                    </RadioGroup>
                                                </Grid>
                                                <Grid
                                                    container>
                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }
                                                        className={
                                                            classes.mt1em
                                                        }>
                                                        <Typography
                                                            className={
                                                                classes.accordionLabel
                                                            }>
                                                            Follow-up
                                                            in
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            12
                                                        }
                                                        className={
                                                            classes.mt1em
                                                        }
                                                        style={{
                                                            display:
                                                                'flex',
                                                            alignItems:
                                                                'center',
                                                        }}>
                                                        <TextField
                                                            id="input-passcode"
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                handleFollowUp(
                                                                    e
                                                                        .target
                                                                        .value,
                                                                    i,
                                                                )
                                                            }
                                                            type="number"
                                                            variant="outlined"
                                                            size="small"
                                                            value={
                                                                item.followup_days
                                                            }
                                                            style={{
                                                                width: '80px',
                                                                marginRight:
                                                                    '1em',
                                                            }}
                                                            InputProps={{
                                                                inputProps:
                                                                    {
                                                                        max: maxValue,
                                                                        min: 0,
                                                                    },
                                                            }}
                                                        />
                                                        <RadioGroup
                                                            row
                                                            aria-label="followup_after"
                                                            defaultValue="weeks"
                                                            name="radio-buttons-group"
                                                            className={
                                                                classes.radioGrpWrap
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                handleDataType(
                                                                    e
                                                                        .target
                                                                        .value,
                                                                    i,
                                                                )
                                                            }>
                                                            <FormControlLabel
                                                                className={[
                                                                    classes.width50,
                                                                    classes.consultationLabel,
                                                                ].join(
                                                                    ' ',
                                                                )}
                                                                value="weeks"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Week(s)"
                                                            />

                                                            <FormControlLabel
                                                                className={[
                                                                    classes.width50,
                                                                    classes.consultationLabel,
                                                                ].join(
                                                                    ' ',
                                                                )}
                                                                value="months"
                                                                control={
                                                                    <Radio
                                                                        size="small"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Month(s)"
                                                            />
                                                        </RadioGroup>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    style={{
                                                        padding:
                                                            '1em 0',
                                                    }}>
                                                    <Button
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        className={
                                                            classes.submitBtn
                                                        }
                                                        color="primary"
                                                        onClick={() =>
                                                            handleAllConsSubmit(
                                                                item,
                                                                i,
                                                                'isAccordion',
                                                            )
                                                        }>
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    ),
                                )
                            )}
                        </DialogContent>
                        <DialogActions
                            style={{
                                justifyContent: 'center',
                            }}>
                            <Button
                                onClick={handleSubmitAll}
                                className={
                                    classes.submitBtn
                                }
                                color="primary">
                                Submit all
                            </Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
            {allPendingAppointments.length > 0 && (
                <Grid
                    container
                    className={[
                        classes.consultationStatusBg,
                        ,
                        classes.allConsultationMarkWrap,
                    ].join(' ')}>
                    <Grid
                        item
                        xs={12}
                        className={[
                            classes.consultationStatusWrap,
                            classes.pr8,
                            classes.dFlex,
                        ].join(' ')}>
                        <Grid
                            item
                            xs={9}
                            className={classes.font20}>
                            All consultation Status
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            className={classes.ml19}>
                            <Button
                                className={[
                                    classes.markBtn,
                                ].join(' ')}
                                onClick={
                                    handleAllConsultationStats
                                }>
                                Mark
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={isSnackBarOpen}
                onClose={() => setIsSnackBarOpen(false)}
                autoHideDuration={1000}
                message={errMsg}
            />
        </>
    )
}
