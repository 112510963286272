import React, { Suspense, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import { getBhList } from 'services/Patient/patient'
import { useHistory, useParams } from 'react-router-dom'

import { formatDateTime } from 'utilities/formats'
import Routes from 'constants/routes'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
import { Card } from '../view/CardHealthRecord'
import { usePatientDashboardStyles } from '../style'
import { NoData } from './NoData'
import { usePatient } from '../hooks'
import { useDispatch } from 'react-redux'
import { openBhScaleUploadForm } from 'store/actions/patient'
const BehaviorRateScale = (props)=>{
    const dispatch = useDispatch()
    const { patientInfo } = usePatient(props.patientId)
    const [patientId, setPatientId] = useState('')

    const classes = usePatientDashboardStyles()

    const { id } = useParams()
    const history = useHistory()
    const notification = useNotification()

    const [bhList, setBhList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) fetchBhScaleList()

        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    const fetchBhScaleList = async () => {
        // @TODO: Consider to implement show more list or pagination
        try {
            setLoading(true)
            const resp = await getBhList(patientId)
            setBhList(resp.data)
        } catch (ex) {
            console.error('[BH scale] cannot fetch BH scale list', ex)
        }
        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.BEHAVIOR_RATE_SCALE.replace(':id', patientId))

    const Content = () => (
        <>
            {bhList.map((mb, index) => {
                // if (index <= 4) {
                    return (
                        <Grid
                            container
                            key={mb.id}
                            justifyContent="space-between"
                            wrap="nowrap"
                            alignItems="center"
                            style={{
                                borderBottom: '1px solid #E4E8EE',
                                paddingBottom: 8,
                                height: 'fit-content',
                            }}>
                            <Grid
                                item
                                container
                                // direction="column"
                                // alignItems="flex-start"
                                justifyContent="space-between"
                                style={
                                    {
                                        // display:'flex',
                                        // alignItems: 'space-between',
                                        // justifyContent: 'space-between',
                                        // width: 'fit-content',
                                    }
                                }>
                                <Typography
                                    style={{
                                        color: '#303E4E',
                                        fontFamily: 'Rubik',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}>
                                    {mb.scale_type}
                                </Typography>

                                <Typography
                                    style={{
                                        color: '#303E4E',
                                        fontFamily: 'Rubik',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                    }}>
                                    {formatDateTime(mb.created_at, 'MM/DD/YYYY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                // }
            })}

            
        </>
    )
    return(
        <Card
            viewAllHandler={handleViewAll}
            addHandler={() => {dispatch(openBhScaleUploadForm(true))}}
            onClick={props.onClick}
            header="Behavior Rate Scales">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        padding: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />
                    {bhList.length ? <Content /> : <NoData />}
                </Grid>
                {!!bhList.length && (
                <Grid container item justifyContent="flex-end" style={{padding: "5px"}}>
                    <span className={classes.viewall} onClick={handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}
            </Suspense>
        </Card>
    )
}
export default BehaviorRateScale