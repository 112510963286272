
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService,
  putService
} from 'utilities/request/restClient'

interface Payload {
    page?: string | number,
    size?: string | number
}
export const apiSubstanceAbuseHistory = {
    getAllbyPtientId(patientId: string|number,payload: Payload){
        const queryString = new URLSearchParams({
            ...(payload as any),
        }).toString()
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-abuse?${queryString}`)
    },
        
    getSubstanceAbuseId(patientId, substanceAbuseId){
            return getService(
                `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-abuse/${substanceAbuseId}`,
        )
    },

    getSubstance(payload?: Payload) {
        const queryString = new URLSearchParams({
            ...(payload as any),
        }).toString()
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/substance?${queryString}`,)
    },
        
    updateubstanceAbuse(patientId, substanceAbuseId,  payload){
        return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-abuse/${substanceAbuseId}`, payload)
    },
        
    deleteSubstanceAbuse(patientId, substanceAbuseId){
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-abuse/${substanceAbuseId}`)
    },
        
    createSubstance(payload: {name: string}){
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/substance`, payload)
    },
        
    createSubstanceAbuseHistory(patientId, payload){
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-abuse`, payload)
    }
}
    