import * as actionTypes from '../actionType'
const initialState = {
    filterOptions: {
        status: null,
        provider: null,
        location: null,
        specialty: null,
        patient: null,
        clinicalPractice: null,
        source: null,
    },
    totalNoOfFilterUsed: [],
}

const referral = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MULTI_FILTER_REFERRAL: {
            let setTotalNoOfFilterUsed = state.totalNoOfFilterUsed
            if ((action.value)?.length > 0) {
                if (!setTotalNoOfFilterUsed.includes(action.field)) {
                    setTotalNoOfFilterUsed = [...state.totalNoOfFilterUsed, action.field]
                }
            } else if ((action.value)?.length === 0) {
                setTotalNoOfFilterUsed = state.totalNoOfFilterUsed.filter((item) => item !== action.field)
            }
            return {
                ...state,
                filterOptions: { ...state.filterOptions, [action.field]: action.value },
                totalNoOfFilterUsed: setTotalNoOfFilterUsed,
            }
        }

        case actionTypes.CLEAR_SINGLE_FILTER_REFERRAL: {
            const newData = state.totalNoOfFilterUsed.filter((item) => item !== action.field)
            return {
                ...state,
                filterOptions: { ...state.filterOptions, [action.field]: null },
                totalNoOfFilterUsed: newData,
            }
        }

        case actionTypes.CLEAR_ALL_FILTER_REFERRAL: {
            return {
                ...state,
                filterOptions: {
                    status: null,
                    provider: null,
                    location: null,
                    specialty: null,
                    parent: null,
                    clinicalPractice: null,
                    source: null,
                },
                totalNoOfFilterUsed: [],
            }
        }
        default:
            return state
    }
}

export default referral
