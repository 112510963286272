import { IconButton } from '@material-ui/core'

const ActiveIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                y="0.200195"
                width="16"
                height="16"
                rx="5"
                fill="#5571C6"
            />
            <path
                d="M12.1917 4.59809C12.0172 4.60382 11.8519 4.67864 11.7307 4.80689C10.0669 6.51143 8.28583 8.4646 6.62373 10.1885L4.25095 8.19127C4.11673 8.0568 3.93341 7.98605 3.74562 7.99632C3.55796 8.00672 3.38306 8.09708 3.26366 8.24541C3.14428 8.39386 3.09132 8.58676 3.11774 8.77693C3.14405 8.96722 3.24729 9.13741 3.40233 9.24619L6.25179 11.645C6.38029 11.7537 6.54386 11.8095 6.71034 11.8016C6.87696 11.7937 7.03468 11.7226 7.15276 11.6022C8.95438 9.75655 10.9098 7.59387 12.6841 5.77634V5.77622C12.8152 5.64677 12.8892 5.46843 12.8889 5.28212C12.8886 5.09578 12.8141 4.91757 12.6826 4.7886C12.5511 4.65951 12.3738 4.59079 12.1918 4.5982L12.1917 4.59809Z"
                fill="white"
            />
        </svg>
    )
}

const DeactiveIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.6"
                y="1.2001"
                width="14.8"
                height="14.8"
                rx="4.4"
                stroke="#667689"
                strokeWidth="1.2"
            />
        </svg>
    )
}

const TouchedIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                y="0.733398"
                width="16"
                height="16"
                rx="5"
                fill="#5571C6"
            />
            <path
                d="M4 8.7334H12"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

const DataGridSelectionCheckBox = ({
    value,
    onChange,
    activeIcon,
    deactiveIcon,
    type,
}) => {
    return (
        <IconButton
            onClick={() => {
                onChange(!value)
            }}>
            {type === 'header' &&
                (value === 0 ? (
                    <DeactiveIcon />
                ) : value === 1 ? (
                    <ActiveIcon />
                ) : (
                    <TouchedIcon />
                ))}
            {type !== 'header' &&
                (value ? (
                    activeIcon || type !== 'header' ? (
                        <ActiveIcon />
                    ) : (
                        <TouchedIcon />
                    )
                ) : (
                    deactiveIcon || <DeactiveIcon />
                ))}
        </IconButton>
    )
}

export default DataGridSelectionCheckBox
