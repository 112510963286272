/* eslint-disable react/button-has-type */
import { Box, Button, CircularProgress, Grid, ListItem, Typography } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { filterDataEncouter } from '../../../../containers/Consultation/function'
import ButtonSpinner from '../../../../containers/Consultation/components/ButtonSpinner'

const statusColor = {
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    IN_WAITING_ROOM: 'IN_WAITING_ROOM',
    CANCELLED: 'CANCELLED',
    NO_SHOW: 'NO_SHOW',
    IS_LATE: 'IS_LATE',
    ASSIGNED_TO_PROVIDER: 'ASSIGNED_TO_PROVIDER',
    IS_KICKED: 'IS_KICKED',
    EXAM: 'EXAM',
    POST_EXAM: 'POST_EXAM',
    ConsultationList: 'ConsultationList',
}

const ViewParticipant = ({
    encounterQueueList,
    messageSocket,
    handleStartMeeting,
    handleEndMeeting,
}) => {
    const useStyles = makeStyles({
        avatar: {
            width: 45,
            height: 45,
            borderRadius: 45,
            background: 'gray',
        },
        listItem: {
            width: '100%',
            padding: 16,
            // height: '77px',
            // float: 'left',
            // borderBottom: '1px solid #E9ECF1',
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F5F6F7',
            borderRadius: 10,
            marginBottom: 12,
        },
        iconLoading: {
            position: 'absolute',
            top: 8,
            left: 5,
        },
        relationship: {
            color: '#9CA9B8',
            fontSize: 13,
        },
        name: {
            color: '#303E4E',
            fontSize: 16,
        },
        information: {
            marginLeft: 16,
        },
        btnAllow: {
            width: '68px',
            height: '32px',
            backgroundColor: '#5571C6',
            textAlign: 'center',
            color: '#fff',
            position: 'relative',
            borderRadius: 6,
            fontWeight: 400,
            fontSize: 14,
            border: 'none',
            outline: 'none',
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.04)',
            },
            cursor: 'pointer',
        },
        btnEnd: {
            width: '68px',
            height: '32px',
            backgroundColor: '#E81212',
            textAlign: 'center',
            color: '#fff',
            position: 'relative',
            borderRadius: 6,
            fontWeight: 400,
            fontSize: 14,
            '&:hover': {
                background: 'rgba(0, 0, 0, 0.04)',
            },
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
        },
        formAllow: {
            display: 'flex',
            gap: 10,
        },
    })

    const [participantData, setParticipantList] = useState([])
    const [indexClickBtn, setIndexClickBtn] = useState('')

    const encounterQueueListEncouter = useSelector((state) => state.provider.encounterQueueList)

    const startMeeting = (encounter_id, item, index) => {
        handleStartMeeting(encounter_id, item)
        setIndexClickBtn(index)
    }

    const endMeeting = (encounter_id, item, index) => {
        handleEndMeeting(encounter_id, item)
        setIndexClickBtn(index)
    }

    const loadingAdmit = useSelector((state) => state.loader.loadingAdmit)

    const loadingEnd = useSelector((state) => state.loader.loadingEndRoom)

    const objectPatient = useSelector((state) => state.provider.objectEncouterStart)

    useEffect(() => {
        const listParticipantEncouter = encounterQueueListEncouter.filter(
            (option) => option.encounter_id === objectPatient.encounter_id,
        )

        setParticipantList(listParticipantEncouter[0]?.participants)
    }, [encounterQueueListEncouter])

    const statusParticipant = useSelector((state) => state.provider.statusParticipant || [])

    console.log(participantData, 'log participantData')
    const classes = useStyles()

    return (
        <div className="view-list-consultation">
            {participantData.map((item, index) => {
                const admitLoading =
                    loadingAdmit.loading && loadingAdmit.screen === statusColor.ConsultationList
                const endLoading =
                    loadingEnd.loading && loadingEnd.screen === statusColor.ConsultationList

                const isCheckAllow =
                    statusParticipant.length > 0 &&
                    statusParticipant[0].phone_number === item.phone_number

                return (
                    <ListItem key={index} className={classes.listItem}>
                        <Grid container alignItems="center">
                            <img
                                src={
                                    'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                }
                                alt="Profile"
                                className={classes.avatar}
                            />
                            <div className={classes.information}>
                                <Typography className={classes.name}>
                                    {`${item?.first_name} ${item?.last_name}`}
                                </Typography>
                                <Typography className={classes.relationship}>
                                    {item?.title}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid className={classes.formAllow}>
                            {(item.status === statusColor.IN_WAITING_ROOM || isCheckAllow) && (
                                <ButtonSpinner
                                    type="Allow"
                                    label="Allow"
                                    className={classes.btnAllow}
                                    disabled={admitLoading && indexClickBtn === index}
                                    isLoading={admitLoading && indexClickBtn === index}
                                    onClick={() =>
                                        startMeeting(objectPatient.encounter_id, item, index)
                                    }
                                />
                            )}
                            {(item.status === statusColor.EXAM ||
                                item.status === statusColor.POST_EXAM) && (
                                <ButtonSpinner
                                    type="End"
                                    label="End"
                                    className={classes.btnEnd}
                                    disabled={endLoading && indexClickBtn === index}
                                    isLoading={endLoading && indexClickBtn === index}
                                    onClick={() =>
                                        endMeeting(objectPatient.encounter_id, item, index)
                                    }
                                />
                            )}
                        </Grid>
                    </ListItem>
                )
            })}
        </div>
    )
}

export default ViewParticipant
