import { Paper, Typography, makeStyles, useMediaQuery } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'
import { useModal } from 'context/ModalContext'
import moment from 'moment'
import { isEmpty, debounce } from 'lodash'
import 'moment-timezone'
import { useEffect, useState, useCallback, useMemo, useReducer, useRef } from 'react'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import { Box, Grid, IconButton } from '@mui/material'
import { SCHEDULE_POPULATE } from 'constants/index'
import SchedulerMenu from 'containers/SchedulerMenu'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotification } from 'shared/elements/Notification'
import { useTimezone } from 'shared/hooks/useTimezone'
import { changeAppointment, changeSearchValues, resetState, setReschedulePopup } from 'store/actions/schedule'
import Swal from 'sweetalert2'
import { ReactComponent as DragIcon } from '../../assests/icons/dot.svg'
import AlertErrorDialog from '../../components/shared/Elements/AlertErrorDialog'
import {
    apiStatusCodes,
    createSchedule,
    deleteAppointment,
    fetchSlotDetails,
    getLocations,
    getProivders,
    getSchedule,
    getVisitType,
    postStatusChangeRequest,
    sendResetConfirmationLink,
    setTimeoff,
    updateSchedule,
} from '../../services/Calendar'
import { getProviderProfile } from '../../services/Provider/provider'
import { handleGetEncounterQueueList } from '../../store/actions/provider'
import AppointmentsModalWrapped from './AppointmentsModal'
import { MonthHeaderCellContent, WeekHeaderCellContent } from './CalendarHeader'
import { CustomDateHeader } from './DateCellHeader'
import EncounterQueue from './EncounterStack/EncounterQueue'
import {
    DayCalendarEvent,
    MonthCalendarEvent,
    WeekCalendarEvent,
    eventStyleGetter,
} from './EventCard/components'
import RequestTimeoffModal from './RequestTimeoffModal'
import TopPanel from './TopPanel'
import * as actionTypes from './actionTypes'
import { DATE_FORMAT, TIME_FORMAT, VIEW } from './constant'
import { initialState, reducer } from './reducer'
import './styles.scss'
import { getDate, isObjectNull } from './utils'
import { syncAppointmentForCN } from 'store/actions/clinicalNotes'
import { checkProviderAvailability } from 'services/ProviderDirectory'
import { APPOINTMENT_STATUSES } from './types'
import { sync30seconds } from 'utilities/sync30seconds'
import {
    filterEventsByResources,
    mergeDraftAppointmentsWithState,
    getDraftAppointments,
    checkAppointmentIsDraft,
    serializeAppointments,
    excludeCancelledAppointments,
    deleteDraftAppointment,
    clearDraftAppointments,
    addDraftAppointment,
  } from './helpers'

const styles = makeStyles({
    scheduleLeft: {
        width: '100%',
        position: 'relative',
    },
    widthOpenSidebar: {
        maxWidth: '62vw',
    },
    widthCloseSidebar: {
        maxWidth: '82vw',
    },
    top_panel: {
        border: '1px solid #EAECF0',
        margin: '5px 30px 5px 20px',
        backgroundColor: '#F8F9FB',
        borderRadius: '5px',
    },
    wrap_calendar_side_padding: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    wrap_calendar_top_padding: {
        paddingTop: '40px',
    },
})

const DnDCalendar = withDragAndDrop(Calendar)
export const paramsConstruction = (data, forceUpdate) => {
    let genratedParams = {}
    if (data?.selectedPatient?.length) {
        genratedParams = {
            patient_id: data?.selectedPatient?.toString(),
        }
    }
    if (data?.selectedSpecialty?.length) {
        genratedParams = {
            ...genratedParams,
            specialty_id: data?.selectedSpecialty?.toString(),
        }
    }
    if (forceUpdate) {
        genratedParams = {
            ...genratedParams,
            force_update: true,
        }
    }
    return genratedParams
}

function CalendarScheduler(props) {
    const userName = localStorage.getItem('name')
    const { locationTimezone, valueTimezone } = useTimezone()
    const [firstTime, setFirstTime] = useState(true)
    const leftSidebarSatus = useSelector((state) => state.sidebar.visible)
    const { role_code, practitioner} = useSelector((state) => state?.login?.userData)

    const notification = useNotification()

    const userInfo = useMemo(() => {
        return {
            id: localStorage.getItem('userId'),
            role: localStorage.getItem('role'),
            name: localStorage.getItem('name'),
            role_code,
        }
    }, [])
    const location = useLocation()

    const encounterQueueState = localStorage?.getItem('setEncounterFilter'),
        parsedEncounterQueueState = encounterQueueState && JSON.parse(encounterQueueState)

    const history = useHistory()
    const classes = styles()
    const { openModal } = useModal()
    const schedulePermission = useSelector((state) => state.permission?.scheduling)
    const { enqueueSnackbar } = useSnackbar()
    const dispatchRedux = useDispatch()
    const [statusOptions, setStatusOption] = useState([])
    const role = props.role || userInfo.role
    const isProvider = practitioner?.provider_account
    const formattedInitialProviderMulti = isProvider
        ? [{ key: userInfo?.id, value: userInfo?.name }]
        : []
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        provider_multi: formattedInitialProviderMulti,
    })

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [locationOptions, setLocationOptions] = useState([])
    const [providerOptions, setProviderOptions] = useState([])
    const [visitTypeOptions, setVisitTypeOptions] = useState([])
    const matchespc = useMediaQuery('(min-width:1280px)')
    const [EqStatus, setEqStatus] = useState(true)
    const getEqDate = useSelector((state) => state.provider.encounterQueueListDate)
    const [apptModal, setApptModal] = useState({
        status: false,
        date: null,
    })
    const [openShowMoreDialog, setOpenShowMoreDialog] = useState({
        open: false,
        events: null,
        date: null,
    })

    const [timeoffModal, setTimeoffModal] = useState({
        status: false,
    })

    const [appointmentModal, setAppointmentModal] = useState({
        status: false,
    })
    const [newAppointmentModal, setNewAppointmentModal] = useState({
        status: false,
    })

    const [addScheduleModal, setAddScheduleModal] = useState({
        status: false,
        slot: null,
    })

    /*Toppanel Toggle button*/
    const [toggleCancelledAppointment, setToggleCancelledAppointment] = useState(false)

    const [resourceView, setResourceView] = useState({})
    const [isDraggingProvider, setIsDraggingProvider] = useState(false)
    const [isFirstClick, setIsFirstClick] = useState(false)
    const localState = JSON.parse(localStorage.getItem('calendar_state'))

    const [previousPatientId, setPreviousPatientId] = useState<string | null>()

    const [slotAvailibilityProps, setSlotAvailibilityProps] = useState({
        slot_check: false,
        unavailableSlots: []
    })

    let resourceMap = useMemo(() => {
        return localState?.provider_multi?.length > 0
            ? localState?.provider_multi?.map((item, index) => {
                return {
                    resourceId: item.key,
                    value: item.value,
                    arrowIndex: index > 0 ? true : false,
                }
            })
            : state.provider_multi?.map((item, index) => {
                return {
                    resourceId: item.key,
                    value: item.value,
                    arrowIndex: index > 0 ? true : false,
                }
            })
    }, [state.provider_multi])

    const checkDisable = (data) => {
        if (!data) return
        if (role_code.includes('provider') && data === userInfo.id) return true
    }

    const reorder = (list, startIndex, endIndex) => {
        const formatted = Array.from(list)
        const [removed] = formatted.splice(startIndex, 1)
        formatted.splice(endIndex, 0, removed)

        const result = formatted.map((item, index) => {
            return {
                resourceId: item.resourceId,
                value: item.value,
                arrowIndex: index > 0 ? true : false,
            }
        })
        return result
    }

    const addResourceView = (resourceList) => {
        setResourceView({
            resourceIdAccessor: 'resourceId',
            resources: resourceList,
            resourceTitleAccessor: (e) => {
                return (
                    <div
                        draggable={true}
                        className="custom-resource-title"
                        data-key={e.resourceId}
                        onDragStart={() => setIsDraggingProvider(!isDraggingProvider)}>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                width: '100%',
                            }}>
                            {e.arrowIndex === true && (
                                <IconButton>
                                    <TrendingFlatIcon
                                        style={{
                                            transform: 'rotate(180deg)',
                                            color: '#aaa',
                                            marginRight: '5px',
                                        }}
                                    />
                                </IconButton>
                            )}
                            <Typography
                                style={{ padding: '5px 0', width: '100%', cursor: 'default' }}>
                                {e.value}
                            </Typography>
                        </span>

                        <IconButton
                            onClick={() =>
                                onChangeFilterMulti(
                                    'provider_multi',
                                    { value: e.value, key: e.resourceId, arrowIndex: e.arrowIndex },
                                    false,
                                )
                            }
                            disabled={checkDisable(e.resourceId)}>
                            <HighlightOffOutlinedIcon
                                style={{
                                    fontSize: '18px',
                                    color:
                                        role === 'provider' && e.resourceId === userInfo.id
                                            ? '#aaa'
                                            : '#888',
                                }}
                            />
                        </IconButton>
                        <DragIcon className="drag-icon" />
                    </div>
                )
            },
        })
    }

    const rearrangeProviderMulti = (data, action = 'provider_reorder') => {
        let newProviderData = []

        if (action === 'provider_reorder') {
            newProviderData = data.map((item) => {
                return {
                    key: item.resourceId,
                    value: item.value,
                }
            })
        } else {
            newProviderData = data
        }

        dispatch({
            type: actionTypes.REASSIGN_PROVIDER_MULTI,
            new_provider_multi: newProviderData,
        })
    }

    const dragOperation = () => {
        if (state?.view?.selected === 'day' && resourceMap.length > 0) {
            const singleResources = document.getElementsByClassName('custom-resource-title')
            const parentResources = document.getElementsByClassName('rbc-header')

            let currentDragResourceId = ''

            for (const singleResource of singleResources) {
                singleResource?.addEventListener('drag', (event) => {
                    currentDragResourceId = event.target.getAttribute('data-key')
                })

                for (const parentResource of parentResources) {
                    parentResource?.addEventListener('dragover', (event) => {
                        event.preventDefault()
                    })

                    parentResource?.addEventListener('drop', (event) => {
                        const dropParentResource = event.target.closest('[data-key]')

                        if (dropParentResource) {
                            const dropResourceId = dropParentResource.getAttribute('data-key')

                            const startIndex = resourceMap.findIndex(
                                (item) => item.resourceId === currentDragResourceId,
                            )
                            const endIndex = resourceMap.findIndex(
                                (item) => item.resourceId === dropResourceId,
                            )

                            const reorderedResourceMap = reorder(resourceMap, startIndex, endIndex)

                            rearrangeProviderMulti(reorderedResourceMap)
                            addResourceView(reorderedResourceMap)
                        }
                    })
                }
            }
        }
    }

    useEffect(() => {
        dragOperation()
    }, [isDraggingProvider])

    const manageToggleCancelledAppointment = () => {
        setToggleCancelledAppointment(!toggleCancelledAppointment)
    }

    const { localizer, getNow } = useMemo(() => {
        moment.locale(moment().locale(), {
            week: {
                dow: 0,
            },
        })
        return {
            localizer: momentLocalizer(moment),
            getNow: () => moment().toDate(),
            startOfWeek: () => 1,
        }
    }, [state.timezone, locationTimezone])

    useEffect(() => {
        if (!!props.date) {
            console.log('dddd', props.date)
            onNavigate(props.date)
        }
    }, [props.date])

    useEffect(() => {
        if (props.providerId) {
            getProviderDetails(props.providerId)
        }
    }, [props.providerId])

    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        refetchSchedule(getEqDate)
        return () => abortController.abort()
    }, [
        localState?.view?.selected,
        getEqDate,
        state.provider_multi,
        state.status_multi,
        state.location_multi,
        state.visitType_multi,
        locationTimezone,
    ])

    const handleCovertArr = (arr) => {
        if (!arr?.length) return ''
        const newText = arr.map((item) => item.key).join(',')
        return newText
    }

    const refetchSchedule = (eqDate = null) => {
        console.log('[CALENDAR] Polling from Calendar...')
        const { view, provider_multi, status_multi, location_multi, visitType_multi } = state
        const localStorageData =  JSON.parse(localStorage.getItem('calendar_state'))
        const getEqDate = eqDate || localStorageData?.view?.start_date;
        getScheduledSlotsCount({
            practitioner_id: handleCovertArr(localStorageData?.provider_multi || provider_multi),
            status: handleCovertArr(localStorageData?.status_multi || status_multi),
            visitType: handleCovertArr(localStorageData?.visitType_multi || visitType_multi),
            location: handleCovertArr(localStorageData?.location_multi || location_multi),
            startTime: getEqDate
                ? moment(getEqDate).startOf(localStorageData?.view?.selected || view?.selected).utc().format()
                : moment().startOf(localStorageData?.view?.selected || view?.selected).utc().format(),
            endTime: getEqDate
                ? moment(getEqDate).endOf(localStorageData?.view?.selected || view?.selected).utc().format()
                : moment().endOf(localStorageData?.view?.selected || view?.selected).utc().format(),
        })
    }

    useEffect(() => {
        if (location?.state?.openNewDialog) {
            setAppointmentModal({
                status: !appointmentModal.status,
                id: '',
                patientId: location?.state?.patientId,
                providerId: location?.state?.providerId,
            })
            delete location.state.openNewDialog
            delete location.state.patientId
            delete location.state.providerId

            history.replace({
                ...history.location,
                state: location.state,
            })
        }
    }, [location])

    useEffect(() => {
        if (apptModal.status && state.provider.data.id) {
            getProviderAppointments({
                provider_id: state.provider.data.id,
                start_date: moment(apptModal.date).format(DATE_FORMAT),
                end_date: moment(apptModal.date).format(DATE_FORMAT),
            })
        }
    }, [apptModal.status, state.timezone, locationTimezone, state.provider])

    async function getProviderDetails(id) {
        try {
            dispatch({
                type: actionTypes.PROVIDER_LOADING,
            })
            const response = await getProviderProfile(id)

            dispatch({
                type: actionTypes.PROVIDER_SUCCESS,
                data: response.data,
            })
        } catch (error) {
            dispatch({
                type: actionTypes.PROVIDER_FAILURE,
            })
        }
    }

    async function getProviderAppointments(params) {
        try {
            dispatch({
                type: actionTypes.APPOINTMENTS_LOADING,
            })
            const response = await fetchSlotDetails({
                ...params,
                status: 'busy',
            })
            dispatch({
                type: actionTypes.APPOINTMENTS_SUCCESS,
                data: response.data,
            })
        } catch (error) {
            dispatch({
                type: actionTypes.APPOINTMENTS_FAILURE,
            })
        }
    }
    // Function to add a key from the second array to the first array conditionally
    function addKeyConditionally(arr1, arr2) {
        for (const obj1 of arr1) {
            // Find the corresponding object in the second array based on the 'id'
            const obj2 = arr2.find((item) => item.id === parseInt(obj1.visit_type_id))

            // If a corresponding object is found, add the key
            if (obj2) {
                obj1.color_tag = obj2.color_tag
            } else {
                obj1.color_tag = obj1?.admin_color || ''
            }
        }
        return arr1
    }

    async function getScheduledSlotsCount(params) {
        try {
            let response = []
            
            const res = await Promise.all([
                getSchedule(params),
                getVisitType({ page: 1, limit: 1000, desc: 'createdAt' }),
            ])

            const calendarData = res[0]?.data
            const visitTypeData = res[1]?.data

            setSlotAvailibilityProps(() => ({
                slot_check: calendarData?.slot_check,
                unavailableSlots: calendarData?.slots
            }))

            const calendarEvents = calendarData.calendar ? calendarData.calendar : calendarData
            response = addKeyConditionally(calendarEvents, visitTypeData?.visitTypeList?.data)
            
            setVisitTypeOptions(visitTypeData.visitTypeList.data)

            dispatch({
                type: actionTypes.CHANGE_DATA,
                data: response?.map((appt) => ({
                    ...appt,
                    end: new Date(appt.end),
                    start: new Date(appt.start),
                })),
            })
        } catch (error) {
            console.error(error)
        }
    }

    async function getLocationsData(params) {
        try {
            //@todo update correct params
            const response = await getLocations({
                ...params,
                page: 1,
                size: 10000,
            })
            setLocationOptions(response.data?.data || [])
        } catch (error) {
            console.error(error)
        }
    }

    async function getProvidersData(params) {
        try {
            const response = await getProivders({
                ...params,
                page: 1,
                size: 100000,
            })
            const providerData = response?.data?.map((item) => ({
                key: item.id,
                value: `${item.first_name} ${item.last_name}`,
                speciality: (item?.specialities?.length > 0) ? `${item?.specialities[0]?.id}` : ''
            }))
            setProviderOptions(providerData || [])
        } catch (error) {
            console.log(error)
        }
    }

    const onDropChangeTime = ({ event, start, end, resourceId }) => {
        const newProvider = resourceMap?.find((item) => item.resourceId === resourceId)
        const practitioner_first_name = newProvider && newProvider?.value?.split(' ')[0],
            practitioner_last_name = newProvider && newProvider?.value?.split(' ')[1]
        const payloadValues = {
            ...event,
            start,
            end,
            practitioner_first_name,
            practitioner_id: newProvider?.resourceId,
            practitioner_last_name,
            status: event.status === APPOINTMENT_STATUSES.pending ? APPOINTMENT_STATUSES.pending : APPOINTMENT_STATUSES.waitlist,
        }
        addDraftAppointment(payloadValues)
        submitAppointment(payloadValues, event?.status, null, true)
    }

    async function onRequestTimeoff(values) {
        try {
            setIsSubmitting(true)
            const start_date = moment(
                `${moment(values.start_date).format(DATE_FORMAT)} ${values.start_at.format(
                    'HH:mm:ss',
                )}`,
            )
            const end_date = moment(
                `${moment(values.end_date).format(DATE_FORMAT)} ${values.end_at.format(
                    'HH:mm:ss',
                )}`,
            )

            onTimeoffModalToggle()

            await setTimeoff({
                provider_id: state.provider.data.id,
                start_date: start_date.utc().format(DATE_FORMAT),
                end_date: end_date.utc().format(DATE_FORMAT),
                start: start_date.utc().format('HH:mm'),
                end: end_date.utc().format('HH:mm'),
            })
            Swal.fire({
                icon: 'success',
                title: '',
                text: 'Your Request for Time Off has been submitted',
            })
            setIsSubmitting(false)
            onNavigate(values.start_date, VIEW.WEEK)
        } catch (error) {
            if (error.status === 406) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Already a request for the same Time Off duration exists. Please select a different date range.',
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Your Request for Time Off was unsuccessful. Please try once again.',
                })
            }

            setIsSubmitting(false)
            onTimeoffModalToggle()
        }
    }

    const handleDragOperation = async (values, dependables, status): Promise<void> => {
        if(status === APPOINTMENT_STATUSES.fulfilled) {
            notification("Completed appointments can't be modified", 'error')
            return
        }

        optimisticAppointmentUpdate(values.id, {
            start: values.start,
            end: values.end,
        })

        const res = await checkProviderAvailability(values.practitioner_id, {
            appointment_id: values.id,
            start: moment.utc(values.start, 'YYYY-MM-DD HH:mm').format(),
            end: moment.utc(values.end, 'YYYY-MM-DD HH:mm').format(),
        })

        if (!res.data.status) {
            enqueueSnackbar(res.data.error, {
                variant: 'error',
            })

            return
        }

        try {
            const res = await updateSchedule({
                id: values?.id,
                payload: { ...values, appointment_status: 'Appt_Not_Start', status, send_reschedule_notification: '0' },
            })

            if (!res || !res.data) {
                throw new Error('No response from server')
            }

            enqueueSnackbar('An appointment was successfully updated!', {
                variant: 'success',
            })

            dispatchRedux(setReschedulePopup(values?.id, true))
        } catch (error) {
            openModalCancelApt(values, status, dependables)
        }
    }

    const handleUpdateAppointmentPatient = async (values, dependables, status): Promise<void> => {
        try {
            const statusChangeRes = await postStatusChangeRequest(status, values?.id, dependables, locationTimezone)
    
            if (!statusChangeRes || !statusChangeRes.data) {
                throw new Error('No response from server during status change')
            }

            const updateRes = await updateSchedule({
                id: values?.id,
                payload: {
                    ...values,
                    appointment_status: 'Appt_Not_Start',
                    status,
                    visit_type_id: `${values.visit_type_id}`,
                },
            })

            if (!updateRes || !updateRes.data) {
                throw new Error('No response from server during schedule update')
            }
    
            if (values?.send_reschedule_notification !== '0') {
                await sendResetConfirmationLink(values?.id, values?.send_reschedule_notification);
            }
    
            enqueueSnackbar('An appointment was successfully updated!', {
                variant: 'success',
            });
        } catch (error) {
            console.error('Error updating appointment:', error);
            enqueueSnackbar('Failed to update appointment. Please try again.', {
                variant: 'error',
            });
        }
    };
    

    const handleUpdateAppointmentForNonStaff = async (status, values, dependables): Promise<void> => {
        dispatchRedux(syncAppointmentForCN(true))

        try {
            if (previousPatientId === values.patient_id) {
                return await handleUpdateAppointmentPatient(values, dependables, status)
            }

            optimisticAppointmentDelete(values.id)

            const deleteRes = await deleteAppointment(values.id)

            if (!deleteRes || !deleteRes.data) {
                throw new Error('No response from server')
            }

            if (!deleteRes.data['message']) {
                throw new Error (`FAILED: ${JSON.stringify(deleteRes.data, null, 1)}`)
            }

            const res = await createSchedule({ ...values, id: '', appointment_status: 'Appt_Not_Start', status, user_name: userName })

            if (!res || !res.data) {
                throw new Error('No response from server')
            }

            enqueueSnackbar('An appointment was successfully updated!', {
                variant: 'success',
            })
        } catch (error) {
            openModalCancelApt(values, status, dependables)
            console.log('FAILED: ', error)
        } finally {
            dispatchRedux(syncAppointmentForCN(false))
        }
    }

    const handleAppointmentForStaff = async (status, values, dependables): Promise<void> => {
        try {
            const res = await updateSchedule({
                id: values?.id,
                payload: { ...values, appointment_status: 'Appt_Not_Start', status },
            })

            if (!res || !res.data) {
                throw new Error('No response from server')
            }

            enqueueSnackbar('An appointment was successfully updated!', {
                variant: 'success',
            })
        } catch (error) {
            openModalCancelApt(values, status, dependables)
        }
    }

    const handleNonDragOperation = async (values, dependables, status): Promise<void> => {
        if (values.appointment_for === 'staff') {
            return await handleAppointmentForStaff(status, values, dependables)
        }

        return await handleUpdateAppointmentForNonStaff(status, values, dependables)
    }

    const handleCreateNewAppointment = async (values, status, dependables): Promise<void> => {
        dispatchRedux(syncAppointmentForCN(true))

        try {
            const res = await createSchedule({ ...values, appointment_status: 'Appt_Not_Start', status, user_name: userName })

            if (!res || !res.data) {
                throw new Error('No response from server')
            }

            enqueueSnackbar('An appointment was successfully created!', {
                variant: 'success',
            })
        } catch (error) {
            console.log('FAILED: ', error)
            openModalCancelApt(values, status, dependables)
        } finally {
            dispatchRedux(syncAppointmentForCN(false))
        }
    }

    const handleUpdateAppointment = async (dragOperation, values, dependables, status): Promise<void> => {
        if (dragOperation) {
            return await handleDragOperation(values, dependables, status)
        }
        
        await handleNonDragOperation(values, dependables, status)
    }

    async function submitAppointment(values, status, dependables = null, dragOperation = false) {
        try {
            if (values?.id) {
                return await handleUpdateAppointment(dragOperation, values, dependables, status)
            }

            await handleCreateNewAppointment(values, status, dependables)
        } catch {
            appointmentModalToggle()
            deleteDraftAppointment(values)
        } finally {
            setIsSubmitting(false)
            refetchSchedule(getEqDate)
            getApiEncounterQueue()
            if(values?.id){
                deleteDraftAppointment(values)
              }
        }
    }

    const optimisticAppointmentDelete = (id: string): void => {
        dispatch({
            type: actionTypes.APPOINTMENT_DELETE,
            data: id,
        })
    }

    const optimisticAppointmentUpdate = (id: string, payload: any): void => {
        dispatch({
            type: actionTypes.APPOINTMENT_UPDATE,
            data: {
                id,
                payload,
            },
        })
    }

    function onApptModalToggle(date) {
        setApptModal({
            status: !apptModal.status,
            date,
        })
    }

    function onTimeoffModalToggle() {
        setTimeoffModal({
            status: !timeoffModal.status,
        })
    }

    function appointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            setAppointmentModal({
                status: !appointmentModal.status,
                id,
            })
        } else {
            setAppointmentModal({
                status: !appointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }
    function newAppointmentModalToggle(start, end, duration, resourceId, id) {
        if (!start && !end) {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            const startDate = moment(start)
            const endDate = moment(end)
            const customDuration =
                endDate.diff(startDate, 'minute') < 0
                    ? {}
                    : { duration: endDate.diff(startDate, 'minute') }
            const specialty_id = providerOptions?.find(item => item.key === resourceId)?.speciality || ''
            dispatchRedux(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: startDate.format('YYYY-MM-DD'),
                        end_time: endDate.format('HH:mm'),
                        start_time: startDate.format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id,
                        location_id: '',
                        ...customDuration,
                        visit_type_id: '',
                        patient_id: '',
                        practitioner_id: resourceId ? resourceId : '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            onChangeTab(1)
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }

    const onViewChange = useCallback(
        (selected, newDate) => {
            dispatch({
                type: actionTypes.CHANGE_CALENDAR_VIEW,
                view: selected,
                date: newDate,
            })
        },
        [resourceMap],
    )

    useEffect(() => {
        setFirstTime(false)
        const currentView = localState ? localState?.view?.selected : 'day',
            currentDate = localState ? localState?.view?.date : state?.view?.date
        onViewChange(currentView, currentDate)
    }, [])

    function onSelectEvent(event) {
        if (!schedulePermission.includes('modify_appointments')) return

        dispatchRedux(
            changeAppointment({
                id: event.id,
                values: null,
            }),
        )
        onChangeTab(1)
        onChangeApptAction('update')
        setNewAppointmentModal({
            status: !newAppointmentModal.status,
        })
    }

    function onSelectEventStaff(event) {
        if (!schedulePermission.includes('modify_appointments')) return
        dispatchRedux(
            changeAppointment({
                id: event.id,
                values: null,
                series_edit: event.series_edit

            }),
        )
        onChangeTab(4)
        onChangeApptAction('update')
        setNewAppointmentModal({
            status: !newAppointmentModal.status,
            series_edit: event.series_edit
        })
    }
    function onSelectMonth(date) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date: moment(date),
            view: VIEW.MONTH,
        })
    }

    function onSelectSlot() {
        setAddScheduleModal({
            status: !addScheduleModal.status,
            view: state.view.selected,
            provider: state.provider.data,
        })
    }

    function onNavigate(date, view = state.view.selected) {
        dispatch({
            type: actionTypes.NAVIGATE_TO_DATE,
            date,
            view,
        })
    }
    function onChangeFilter(data) {
        dispatch({
            type: actionTypes.CHANGE_FILTER,
            data,
        })
    }
    const handleChangeSelect = useCallback(
        (option, checked, value, field) => {
            if (field === 'provider_multi' && checkDisable(option.key)) {
                notification("Default Provider can't be deselected!", 'warning')
                return
            }
            if (checked && localState?.provider_multi?.length === 25) {
                notification('Cannot filter more than 25 providers!', 'warning')
                return
            }
            onChangeFilterMulti(field, option, checked)
        },
        [localState?.provider_multi],
    )

    function onChangeFilterMulti(name, option, checked, view = 'day') {
        if (view === 'day' && name === 'provider_multi') {
            if (!isObjectNull(option)) {
                if (checked) {
                    resourceMap.push({
                        resourceId: option.key,
                        value: option.value,
                        arrowIndex: resourceMap.length > 0 ? true : false,
                    })
                } else {
                    resourceMap = resourceMap
                        .filter((item) => item.resourceId !== option.key)
                        .map((item, index) => {
                            return {
                                resourceId: item.resourceId,
                                value: item.value,
                                arrowIndex: index > 0 ? true : false,
                            }
                        })
                }
            }
        }

        dispatch({
            type: actionTypes.CHANGE_FILTER_MULTI,
            option,
            checked,
            name,
        })
    }

    const handleClearFilter = () => {
        dispatch({
            type: actionTypes.CLEAR_FILTER_MULTI,
            userInfo,
        })
        notification('Default Provider is always auto-filtered!', 'warning')
    }

    function handleClearSingleFilter(name) {
        switch (name) {
            case 'provider_multi':
                setResourceView({})
                resourceMap = []
                dispatch({
                    type: actionTypes.CLEAR_FILTER_MULTI_PROVIDER,
                    userInfo,
                })
                notification('Default Provider is always auto-filtered!', 'warning')
                break
            case 'location_multi':
                dispatch({
                    type: actionTypes.CLEAR_FILTER_MULTI_LOCATION,
                })
                break
            case 'visitType_multi':
                dispatch({
                    type: actionTypes.CLEAR_FILTER_MULTI_VISITTYPE,
                })
                break
            case 'status_multi':
                dispatch({
                    type: actionTypes.CLEAR_FILTER_MULTI_STATUS,
                })
                break
            default:
                ''
        }
    }

    function onTimezoneChange(tz) {
        dispatch({
            type: actionTypes.CHANGE_TIMEZONE,
            tz,
        })
    }

    function onChangeTab(tab) {
        dispatch({
            type: actionTypes.CHANGE_TAB,
            tab,
        })
    }

    function onChangeApptAction(action) {
        dispatch({
            type: actionTypes.CHANGE_APPT_ACTION,
            action,
        })
    }

    const getApiEncounterQueue = async () => {
        const getParams = paramsConstruction(parsedEncounterQueueState)
        dispatchRedux(handleGetEncounterQueueList(getParams))
    }

    const locationOptionsData = locationOptions
        .filter((item) => item.is_external !== true)
        .map((item) => ({
            key: item.id,
            value: item.name,
        }))

    const visitTypeOptionsData = visitTypeOptions.map((item) => ({
        key: item.id,
        value: item.post_code,
        duration: item.duration,
    }))

    const statusOptionsData = statusOptions.map((item) => ({
        id: item.id,
        key: item.code,
        value: item.name,
    }))

    async function getStatusAppointment() {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                key: 'name',
                direction: 'ASC',
                page: 1,
                limit: 1000,
                category: 'APPOINTMENT',
            })
            if (res.data.listStatusCode?.data) {
                setStatusOption(res.data.listStatusCode?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const customSlotPropGetter = (date, resourceId) => { 
        if(slotAvailibilityProps?.unavailableSlots?.length === 0) {
            const className = moment(date).hour() < 8 || moment(date).hour() > 16 ? 'customSlot' : ''
            return { className }
        }

        const currentResource = slotAvailibilityProps?.unavailableSlots?.find(item => resourceId === item?.provider_id)

        if(currentResource?.slot?.length === 0) {
            return { className: '' }
        }

        if(slotAvailibilityProps?.slot_check){
            const isNotAvailableSlots = currentResource?.slot?.some(slot => 
                moment(date)?.isSameOrAfter(moment.utc(slot?.startTime).tz(locationTimezone))
                && moment(date)?.isSameOrBefore(moment.utc(slot?.endTime).tz(locationTimezone))
             )
             
             const className = !isNotAvailableSlots ? 'customSlot' : ''
             return { className }
        }

        return { className: '' }
    }

    useEffect(() => {
        if (!newAppointmentModal.status) {
            dispatchRedux(resetState())
            onChangeApptAction('create')
        }
    }, [newAppointmentModal.status])



    useEffect(() => {
        const { value = {}, from } = location.state || {}

        if (!from) return

        dispatchRedux(
            changeAppointment({
                id: -1,
                values: {
                    id: '',
                    date: moment().format('YYYY-MM-DD'),
                    end_time: moment().format('HH:mm'),
                    start_time: moment().format('HH:mm'),
                    appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                    reason_for_visit: '',
                    internal_note: '',
                    specialty_id: '',
                    location_id: '',
                    duration: '',
                    visit_type_id: '',
                    patient_id: '',
                    practitioner_id: '',
                    supervising_provider: '',
                    is_require_translator: false,
                    appointment_status: '',
                    is_reoccurring_appointment: false,
                    days: [],
                    end_date: '',
                    unit: '',
                    once_every: '',
                    end_type: '',
                    timezone: locationTimezone,
                    populate: true,
                    notes: [],
                    note: '',
                    noteView: 'no-view',
                    ...value,
                },
            }),
        )

        switch (location.state.from.name) {
            case SCHEDULE_POPULATE.CLINICAL_NOTE.name:
                dispatchRedux(
                    changeSearchValues({
                        timezone: '',
                        visit_type_id: '',
                        start_date: '',
                        start_time: '00:00',
                        end_date: '',
                        end_time: '23:59',
                        all_day: true,
                        location_ids: [],
                        specialty: parseInt(value.specialty_id),
                        practitioner_ids: [{ key: value.practitioner_id }],
                        days: [],
                    }),
                )
                break
            default:
        }

        onChangeTab(1)
        setNewAppointmentModal({
            status: !newAppointmentModal.status,
        })
    }, [location.state?.from?.name])

    useEffect(() => {
        if (state?.view.selected !== 'day') {
            setResourceView({})
        } else {
            if (resourceMap.length > 0) {
                addResourceView(resourceMap)
            } else {
                setResourceView({})
            }
        }
    }, [resourceMap, state?.view.selected])

    useEffect(() => {
        clearDraftAppointments()
    }, [])

    const formatEvents = (data, mapArray) => {
        if (mapArray.length === 0) {
            return []
        }
        const draftAppointments = getDraftAppointments()
        const modyfyData = isEmpty(draftAppointments) ? data : mergeDraftAppointmentsWithState(data, draftAppointments)
       
        const filteredData = filterEventsByResources(modyfyData, mapArray)
        const formattedData = serializeAppointments(filteredData)

        if (toggleCancelledAppointment) return formattedData

        const formattedEventsWithoutCancelledStatus = excludeCancelledAppointments(formattedData)

        return formattedEventsWithoutCancelledStatus
    }
    
    useEffect(() => {
        const notificationClasses = document.getElementsByClassName('notification-tooltip')
        if(newAppointmentModal.status){
            for(const notificationClass of notificationClasses){
                notificationClass?.classList?.add('notification-tooltip-display-none')
                notificationClass?.classList?.remove('notification-tooltip-display-on')
            }
        }
        else {
            for(const notificationClass of notificationClasses){
                notificationClass?.classList?.remove('notification-tooltip-display-none')
                notificationClass?.classList?.add('notification-tooltip-display-on')
            } 
        }
    }, [newAppointmentModal.status])

    const calendarProps = {
        style: {
            backgroundColor: '#fff',
            Width: leftSidebarSatus && EqStatus ? '61vw' : '81vw',
            overflowX: 'scroll',
        },
        onShowMore: (events, date) => {
            setOpenShowMoreDialog({
                events,
                date,
                open: true,
            })
        },
        slotPropGetter: customSlotPropGetter,
        messages: {
            showMore: (total) => `+${total} view more`,
        },
        events: formatEvents(state?.events?.data, resourceMap),
        endAccessor: ({ end }) => {
            if (moment(end).format(TIME_FORMAT) === '00:00:00') {
                return new Date(end.getTime() - 1)
            }
            return new Date(end.getTime())
        },
        views: {
            month: true,
            week: true,
            day: true,
        },
        formats: {
            eventTimeRangeFormat: () => null,
            eventTimeRangeStartFormat: () => null,
            eventTimeRangeEndFormat: () => null,
            dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
            timeGutterFormat: (date, culture, localizer) =>
                localizer.format(date, 'hh:mm a', culture),
            dayHeaderFormat: (date, culture, localizer) =>
                localizer.format(date, 'MMMM D', culture),
            monthHeaderFormat: (date, culture, localizer) =>
                localizer.format(date, 'MMMM', culture),
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
        },
        eventPropGetter: (props) => eventStyleGetter(props, state.view.selected),
        scrollToTime: (() => {
            const current = moment()
            const minute = current.get('minute')
            if (minute >= 30) {
                return current.set('minute', 30)
            }
            return current.set('minute', 0)
        })(),
        selectable: 'ignoreEvents',
        onSelectSlot: (e) => {
            if (state.view.selected === VIEW.MONTH) {
                dispatch({
                    type: actionTypes.NAVIGATE_TO_DATE,
                    date: moment(e.start),
                    view: VIEW.DAY,
                })
            }
            if (state.view.selected === VIEW.WEEK) {
                dispatch({
                    type: actionTypes.NAVIGATE_TO_DATE,
                    date: moment(e.start),
                    view: VIEW.DAY,
                })
            }
            if (
                !schedulePermission.includes(
                    'create_appointments',
                )
            ) {
                return true
            }

            const currentResource = slotAvailibilityProps?.unavailableSlots?.find(item => e?.resourceId === item?.provider_id)
        
            let isNotAvailableSlots = true
            
            if(currentResource?.slot?.length > 0) {
                isNotAvailableSlots = currentResource?.slot?.some(slot => 
                    moment(e?.end)?.isSameOrAfter(moment.utc(slot?.startTime).tz(locationTimezone))
                    && moment(e?.end)?.isSameOrBefore(moment.utc(slot?.endTime).tz(locationTimezone))
                )
            }
            
            if(isNotAvailableSlots) {
                const timeSlots = document.getElementsByClassName('rbc-time-slot')

                for (const timeSlot of timeSlots) {
                    timeSlot?.classList?.remove('timeSlotSelect')
                }
                if (state.view.selected === 'day' && e?.action === 'click') {
                    const currentTimeSLot = document.elementFromPoint(e?.box?.x, e?.box?.y)
                    currentTimeSLot?.classList?.add('timeSlotSelect')
                }

                if (state.view.selected === 'day' && e?.action === 'doubleClick') {
                    for (const timeSlot of timeSlots) {
                        timeSlot?.classList?.remove('timeSlotSelect')
                    }
                    newAppointmentModalToggle(
                        e.start,
                        moment(e.end)?.startOf('date'),
                        e.slots.length - 1,
                        e?.resourceId,
                    )
                }
            }
        },
        step: 15,
        timeslots: 1,
        view: state?.view?.selected,
        onView: onViewChange,
        date: localState?.view?.date || getDate(state.view.date.format(DATE_FORMAT), moment),
        onNavigate,
        localizer,
        getNow,
        popup: true,
        ...resourceView,
        components: {
            [VIEW.MONTH]: {
                dateHeader: (props) => (
                    <CustomDateHeader
                        appointments={state.appointments.count}
                        onViewAppt={onApptModalToggle}
                        {...props}
                    />
                ),
                header: (props) => <MonthHeaderCellContent {...props} selectedView={state.view} />,
                event: (props) => (
                    <MonthCalendarEvent
                        {...props}
                        optimisticDelete={optimisticAppointmentDelete}
                        optimisticUpdate={optimisticAppointmentUpdate}
                        timezone={valueTimezone}
                        onSelectEvent={onSelectEvent}
                        refetchSchedule={refetchSchedule}
                        getApiEncounterQueue={getApiEncounterQueue}
                        onSelectEventStaff={onSelectEventStaff}
                        isDraft={checkAppointmentIsDraft(props.event.id)}
                    />
                )
            },
            [VIEW.WEEK]: {
                header: (props) => <WeekHeaderCellContent {...props} />,
                event: (props) => (
                    <WeekCalendarEvent
                        {...props}
                        optimisticDelete={optimisticAppointmentDelete}
                        optimisticUpdate={optimisticAppointmentUpdate}
                        timezone={valueTimezone}
                        onSelectEvent={onSelectEvent}
                        refetchSchedule={refetchSchedule}
                        getApiEncounterQueue={getApiEncounterQueue}
                        onSelectEventStaff={onSelectEventStaff}
                        isDraft={checkAppointmentIsDraft(props.event.id)}
                    />
                )
            },
            [VIEW.DAY]: {
                event: (props) => (
                    <DayCalendarEvent
                        {...props}
                        optimisticDelete={optimisticAppointmentDelete}
                        optimisticUpdate={optimisticAppointmentUpdate}
                        timezone={valueTimezone}
                        onSelectEvent={onSelectEvent}
                        onSelectEventStaff={onSelectEventStaff}
                        refetchSchedule={refetchSchedule}
                        getApiEncounterQueue={getApiEncounterQueue}
                        isDraft={checkAppointmentIsDraft(props.event.id)}
                    />
                )
            },
        },
    }

  const [isActiveTab, setIsActiveTab] = useState(true); 
    const intervalRef = useRef(null);
    // const debouncedRefetchScheduleRef = useRef(_.debounce(refetchSchedule, 2000));
    
    const debouncedFetchData = useRef(debounce(refetchSchedule, 2000))
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            setIsActiveTab(true);
        } else {
            setIsActiveTab(false);
        }
    };
   //AutoRefresh the calender appoinetment list every 30 seconds
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // We use the firstTime state to avoid triggering certain actions during the initial render of the page.
        if(isActiveTab && !firstTime){
            refetchSchedule()
        }
        if (isActiveTab && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                debouncedFetchData.current();
            }, 30000); // 30 seconds
        }
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [isActiveTab]);

    

    //Refresh page
    const handleRefresh = async (customDate = getEqDate) => {
        refetchSchedule(customDate)
        getApiEncounterQueue()
    }
    const openModalCancelApt = (values, status, dependables) => {
        const data = {
            title: 'Appointment Alert ',
            yes: 'Retry',
            no: 'Cancel',
            content: 'Sorry! Your previous appointment was not successfully created!',
            onConfirm: () => submitAppointment(values, status, dependables),
            onClose: clearDraftAppointments,
        }
        openModal(data)
    }
    const handleFilterClick = () => {
        if (!isFirstClick) {
            setIsFirstClick(true)
            getLocationsData()
            getProvidersData()
            getStatusAppointment()
        }
    }
    useEffect(() => {
        getProvidersData()
    }, [])

    return (
        <Box className="main-schedule">
            <Box className={EqStatus ? 'schedule-left' : classes.scheduleLeft}>
                <Paper>
                    <div className="examples">
                        <div
                            className="example"
                            style={{
                                position: 'relative',
                            }}>

                            {location.state?.populate && (
                                <Grid
                                    container
                                    onClick={history.goBack}
                                    style={{
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                    }}>
                                    <KeyboardBackspaceRoundedIcon
                                        style={{
                                            color: '#5571C6',
                                            fontSize: 18,
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: '#5571C6',
                                            marginLeft: 8,
                                        }}>
                                        Back to {location.state.from.text_back}
                                    </Typography>
                                </Grid>
                            )}

                            <Box className={classes.top_panel}>
                                <TopPanel
                                    toggleCancelledAppointment={toggleCancelledAppointment}
                                    manageToggleCancelledAppointment={
                                        manageToggleCancelledAppointment
                                    }
                                    onView={onViewChange}
                                    date={localState?.view?.date || state.view.date}
                                    provider={state.provider.data}
                                    onSelectEvent={onSelectEvent}
                                    timezone={state.timezone}
                                    locationTimezone={locationTimezone}
                                    status={state.status}
                                    events={state.events}
                                    location={state.location}
                                    visitType={state.visitType}
                                    practitioner_id={state.practitioner_id}
                                    provider_multi={
                                        localState?.provider_multi?.length > 0
                                            ? localState?.provider_multi
                                            : state.provider_multi
                                    }
                                    location_multi={
                                        localState?.location_multi?.length > 0
                                            ? localState?.location_multi
                                            : state.location_multi
                                    }
                                    visitType_multi={
                                        localState?.visitType_multi?.length > 0
                                            ? localState?.visitType_multi
                                            : state.visitType_multi
                                    }
                                    status_multi={
                                        localState?.status_multi?.length > 0
                                            ? localState?.status_multi
                                            : state.status_multi
                                    }
                                    onChangeFilterMulti={handleChangeSelect}
                                    onClearFilter={handleClearFilter}
                                    onChangeFilter={onChangeFilter}
                                    onSelectSlot={onSelectSlot}
                                    onTimezoneChange={onTimezoneChange}
                                    onTimeoffModalToggle={onTimeoffModalToggle}
                                    appointmentModalToggle={appointmentModalToggle}
                                    newAppointmentModalToggle={newAppointmentModalToggle}
                                    checkDisable={checkDisable}
                                    role={role}
                                    locationOptions={locationOptionsData}
                                    providerOptions={providerOptions}
                                    visitTypeOptions={visitTypeOptionsData}
                                    statusOptions={statusOptionsData}
                                    loggedInRole={userInfo.role}
                                    view={localState?.view?.selected || state.view.selected}
                                    onNavigate={onNavigate}
                                    handleClearSingleFilter={handleClearSingleFilter}
                                    handleRefresh={handleRefresh}
                                    getProvidersData={getProvidersData}
                                    handleFilterClick={handleFilterClick}
                                />
                            </Box>
                            <div
                                className={`wrap-calendar ${classes.wrap_calendar_side_padding} ${resourceMap.length > 0
                                    ? ''
                                    : state?.view?.selected !== 'day'
                                        ? ''
                                        : classes.wrap_calendar_top_padding
                                    }`}
                                style={{
                                    maxWidth:
                                        leftSidebarSatus &&
                                            EqStatus &&
                                            (state.view.selected === VIEW.DAY ||
                                                state.view.selected === VIEW.WEEK)
                                            ? '62vw'
                                            : leftSidebarSatus &&
                                                !EqStatus &&
                                                (state.view.selected === VIEW.DAY ||
                                                    state.view.selected === VIEW.WEEK)
                                                ? '79vw'
                                                : '',
                                }}>
                                <div className="block-calendar">
                                    <DnDCalendar
                                        style={{ width: '100%', display: 'grid' }}
                                        {...calendarProps}
                                        onEventDrop={onDropChangeTime}
                                    />
                                </div>
                            </div>

                            {newAppointmentModal.status && (
                                <SchedulerMenu
                                    tab={state.tab}
                                    action={state.action}
                                    date={state.view.date}
                                    label={props.label}
                                    events={state.events}
                                    open={newAppointmentModal.status}
                                    appointmentModal={newAppointmentModal}
                                    loading={isSubmitting}
                                    setIsLoading={setIsSubmitting}
                                    onModalClose={newAppointmentModalToggle}
                                    onSubmit={submitAppointment}
                                    locationOptionsData={locationOptionsData}
                                    visitTypeOptionsData={visitTypeOptionsData}
                                    statusOptionsData={statusOptionsData}
                                    onChangeTab={onChangeTab}
                                    isScheduler={true}
                                    sidebarOpen={EqStatus}
                                    previousPatientId={previousPatientId}
                                    setPreviousPatientId={setPreviousPatientId}
                                />
                            )}
                        </div>
                    </div>
                </Paper>
            </Box>

            <Box className="schedule-right">
                {matchespc && (
                    <div
                        style={{
                            width: '30px',
                            height: '30px',
                            background: 'white',
                            position: 'absolute',
                            right: EqStatus ? '312px' : '-2px',
                            marginLeft: EqStatus ? '' : '270px',
                            color: '#3c8fe2',
                            top: '17px',
                            borderBottom: '2px #E9ECF1 solid',
                            borderLeft: '2px #E9ECF1 solid',
                            borderTop: '2px #E9ECF1 solid',
                            borderTopLeftRadius: '5px',
                            borderBottomLeftRadius: '5px',
                            paddingLeft: '3px',
                            zIndex: 1000,
                            cursor: 'pointer',
                        }}
                        onClick={() => setEqStatus((EqStatus) => !EqStatus)}>
                        {!EqStatus ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </div>
                )}

                {!matchespc && (
                    <EncounterQueue
                        onNavigate={onNavigate}
                        onSelectMonth={onSelectMonth}
                        view={
                            localState && localState?.view?.selected
                                ? localState?.view?.selected
                                : state.view.selected
                        }
                        daySelect={
                            localState && localState?.view?.date
                                ? localState?.view?.date
                                : state.view.date
                        }
                        refetchSchedule={refetchSchedule}
                    />
                )}
                {matchespc && (
                    <span style={{ display: !EqStatus ? 'none' : '' }}>
                        <EncounterQueue
                            onNavigate={onNavigate}
                            onSelectMonth={onSelectMonth}
                            view={
                                localState && localState?.view?.selected
                                    ? localState?.view?.selected
                                    : state.view.selected
                            }
                            daySelect={
                                localState && localState?.view?.date
                                    ? localState?.view?.date
                                    : state.view.date
                            }
                            refetchSchedule={refetchSchedule}
                        />
                    </span>
                )}
            </Box>

            <AppointmentsModalWrapped
                open={apptModal.status}
                onModalClose={onApptModalToggle}
                data={state.appointments}
            />

            <RequestTimeoffModal
                open={timeoffModal.status}
                loading={isSubmitting}
                onModalClose={onTimeoffModalToggle}
                onSubmit={onRequestTimeoff}
            />

            {Boolean(alertDeleteOpen) && (
                <AlertErrorDialog
                    message={alertDeleteOpen}
                    open={Boolean(alertDeleteOpen)}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                />
            )}
        </Box>
    )
}
export default CalendarScheduler