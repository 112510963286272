import {
    Box,
    Grid,
    InputAdornment,
    styled,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core'
import { Button } from 'components/custom/Button'

import IconButton from '@mui/material/IconButton'
import SearchIcon from '../../../assests/icons/search_icon.svg'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { Category_Notes_TABS } from '../../../constants'
import NoteItem from './NoteItem'
import TemplateNoteSettings from './TemplateNoteSettings'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useLocation } from 'react-router-dom'
import TooltipText from './../../../components/shared/Elements/TooltipText'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'
import moment from 'moment'

const useStyles = makeStyles({
    root: {
        background: 'none',
    },
    container_list: {
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 1,
        // height: 800
        zIndex: 1,
    },
    header: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '244px',
        whiteSpace: 'nowrap',
    },
    notchedOutline: {
        border: 'none',
    },
    search_input: {
        width: 'auto',
        height: 40,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    show_search: {
        height: 0,
    },
    iconSetting: {
        width: 25,
        height: 25,
    },
    container_category: {
        padding: '16px 16px 16px 20px',
        width: 350,
        height: 'calc(100vh - 108px)',
        borderRight: '1px solid #e8e8e8',
        transition: 'width 0.5s',
        lineHeight: '30.4px',
    },
    eye_icon: {
        marginRight: 8,
    },
    header_category: {
        marginBottom: 16,
    },
    preview_btn: {
        marginTop: 16,
        borderRadius: 8,
        height: '40px',
        fontWeight: 'normal',
        border: 'none',
        background: '#5571c6 !important'
    },
    text_back: {
        color: "#303E4E",
        fontSize: "15px"
    },
    date_back: {
        paddingLeft: "5px",
        color: "#5571C6",
        fontSize: "15px",
        cursor: 'pointer',
    }
})

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
    },
})

const AntTab = styled((props) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontSize: 14,
    backgroundColor: 'none',
    // padding: '0 24px',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#5571C6',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#5571C6',
        fontWeight: 500,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#5571C6',
    },
}))

const CategoryNotes = ({
    activeNote,
    onChangeActiveNote,
    onChangeActiveView,
    categoryNotes,
    clinicalNoteId,
    categoryList = [],
    handleChangeTemplate,
    clinicalNoteData,
    configureTemplateDetails
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('all')
    const [openSearch, setOpenSearch] = useState(false)
    // const [categoryList, setCategoryList] = useState([])
    const [textSearch, setTextSearch] = useState('')
    const [category, setCategory] = useState([])
    // const location = useLocation()

    const handleChangeTab = (_, val) => {
        setActiveTab(val)
    }

    const handleOpenSearch = () => {
        setOpenSearch(!openSearch)
    }
    const viewPreviousNote = () => {
        window.open(`/work-queues/previous-clinical-note/${clinicalNoteId}`)
    }

    useEffect(() => {
        setCategory(categoryList)
    }, [categoryList])

    const numIncomplete = (item) =>
        categoryList
            .filter((_item) => _item.isShow)
            .filter((_item) =>
                _item.header
                    .toLowerCase()
                    .includes(textSearch.toLowerCase()),
            )
            .filter(
                (_item) =>
                    _item.status === item.key ||
                    item.key === 'all',
            ).length

    return (
        <Grid
            className={clsx({
                [classes.container_category]: true,
            })}
            direction={'column'}
            container
            wrap="nowrap">
            <Grid
                container
                className={clsx({
                    [classes.header_category]: openSearch,
                })}>
                <div style={{ width: 244 }}>
                    <TooltipText
                        value={
                            categoryNotes
                                ?.clinicalNoteTemplate
                                ?.templateName || '-'
                        }></TooltipText>
                </div>

                <Grid item>
                    <Grid container>
                        <IconButton
                            variant="outlined"
                            style={{
                                height: 30,
                                width: 30,
                                marginRight: 8,
                                background: '#F5F6F7',
                            }}
                            onClick={handleOpenSearch}>
                            <img
                                src={SearchIcon}
                                alt=""></img>
                        </IconButton>

                        <TemplateNoteSettings
                            clinicalNoteId={clinicalNoteId}
                            configureTemplateDetails={configureTemplateDetails}
                            clinicalName={
                                categoryNotes
                                    ?.clinicalNoteTemplate
                                    ?.templateName
                            }
                            template={
                                categoryNotes
                                    ?.clinicalNoteTemplate
                                    ?.components
                            }
                            handleChangeTemplate={
                                handleChangeTemplate
                            }
                            categoryList={categoryList}
                        // handleChangeTemplate={(cat) => {
                        //     setCategory(cat)
                        //     const activeNoteChanged =
                        //         cat.find(
                        //             (item) =>
                        //                 item.componentId ===
                        //                 activeNote.componentId,
                        //         )
                        //     if (
                        //         !activeNoteChanged?.isShow
                        //     )
                        //         onChangeActiveNote('')
                        // }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <TextField
                className={clsx({
                    [classes.search_input]: true,
                    [classes.show_search]: !openSearch,
                })}
                placeholder="Search..."
                onChange={(e) =>
                    setTextSearch(e.target.value)
                }
                value={textSearch}
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton size="small">
                                <img
                                    src={SearchIcon}
                                    alt=""></img>
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="start">
                            {textSearch && (
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setTextSearch('')
                                    }>
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    classes: {
                        notchedOutline:
                            classes.notchedOutline,
                        root: classes.search_input_root,
                    },
                }}
            />
            {clinicalNoteData?.previous_cn && (
                <Grid
                    container
                    style={{
                        width: 'fit-content',
                        paddingTop: '3%',
                    }}
                >
                    <Typography className={classes.text_back}>View previous note: </Typography>
                    <Typography className={classes.date_back} onClick={() => {
                        viewPreviousNote()
                    }}>
                        {moment(clinicalNoteData?.previous_cn_service_date).format('MM/DD/YYYY')}{' '}
                    </Typography>
                </Grid>
            )}

            <Button
                sx={{ mt: 2, borderRadius: 2}}
                fullWidth
                size='large'
                onClick={() => {
                    onChangeActiveView('preview')
                }}>
                <VisibilityOutlinedIcon
                    fontSize="small"
                    className={classes.eye_icon}
                />
                Preview note
            </Button>

            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: 16,
                }}>
                <AntTabs
                    value={activeTab}
                    onChange={handleChangeTab}
                    centered>
                    {Category_Notes_TABS.map((item) => (
                        <AntTab
                            className={classes.root}
                            key={item.key}
                            label={
                                <Typography
                                    style={{
                                        color:
                                            numIncomplete(
                                                item,
                                            ) > 0 &&
                                            item.key ===
                                            'incomplete' &&
                                            'red',
                                    }}>
                                    {`${item.label
                                        } (${numIncomplete(
                                            item,
                                        )})`}
                                </Typography>
                            }
                            value={item.key}
                        />
                    ))}
                </AntTabs>
            </Box>
            {category.length > 0 && (
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    className={classes.container_list}>
                    {category
                        .filter((item) => item.isShow)
                        .filter((item) =>
                            item.header
                                .toLowerCase()
                                .includes(
                                    textSearch.toLowerCase(),
                                ),
                        )
                        .filter(
                            (item) =>
                                activeTab === 'all' ||
                                item.status === activeTab,
                        )
                        .map((item, index) => (
                            <NoteItem
                                key={item.componentId}
                                fieldName={item.componentId}
                                header={item.componentId === "subjective_concerns" ?
                                    `${index + 1}. HPI` : `${index + 1}. ${item.header}`}
                                selected={
                                    activeNote.componentId ===
                                    item.componentId
                                }
                                checked={
                                    item.status ===
                                    'completed'
                                }
                                onClick={() => {
                                    if (
                                        activeNote.componentId !==
                                        item.componentId
                                    ) {
                                        onChangeActiveNote(
                                            item,
                                        )
                                    }
                                }}
                            />
                        ))}
                </Grid>
            )}
        </Grid>
    )
}

export default CategoryNotes
