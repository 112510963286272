export const MEDICAL_INITIAL_CLINICAL_NOTE = {
    plan: {
        radiology: [

            {
                key: 'Open MRI',
                value: 'Open MRI',
            },
            {
                key: 'Upright MRI',
                value: 'Upright MRI',
            },
            {
                key: 'MRI brain with and without contrast',
                value: 'MRI brain with and without contrast',
            },
            {
                key: 'MRI brain without contrast',
                value: 'MRI brain without contrast',
            },
            {
                key: 'MRI brain 3.0T with and without contrast',
                value: 'MRI brain 3.0T with and without contrast',
            },
            {
                key: 'MRI brain 3.0T without contrast',
                value: 'MRI brain 3.0T without contrast',
            },
            {
                key: 'MRA brain with and without contrast',
                value: 'MRA brain with and without contrast',
            },
            {
                key: 'MRA brain without contrast',
                value: 'MRA brain without contrast',
            },
            {
                key: 'CT scan brain with and without contrast',
                value: 'CT scan brain with and without contrast',
            },
            {
                key: 'CT scan brain without contrast',
                value: 'CT scan brain without contrast',
            },
            {
                key: 'CTA head and neck',
                value: 'CTA head and neck',
            },
            {
                key: 'SPECT/CT brain',
                value: 'SPECT/CT brain',
            },
            {
                key: 'MRI: MS protocol',
                value: 'MRI: MS protocol',
            },
            {
                key: 'MRI: Head Trauma protocol',
                value: 'MRI: Head Trauma protocol',
            },
            {
                key: 'MRI 3T CSF flow / velocity:',
                value: 'MRI 3T CSF flow / velocity:',
            },
            {
                key: 'MRI: NPH (Aqueduct)',
                value: 'MRI: NPH (Aqueduct)',
            },
            {
                key: 'LP',
                value: 'LP',
            },
            {
                key: 'MRI: Cisternography',
                value: 'MRI: Cisternography',
            },
            {
                key: 'MRI: Foramen Magnum (Chiari)',
                value: 'MRI: Foramen Magnum (Chiari)',
            },
            {
                key: 'Functional MRI:',
                value: 'Functional MRI:',
            },
            {
                key: 'Motor sequences',
                value: 'Motor sequences',
            },
            {
                key: 'Auditory sequences',
                value: 'Auditory sequences',
            },
            {
                key: ' Visual sequences',
                value: 'Visual sequences',
            },
            {
                key: 'PET scan',
                value: 'PET scan',
            },
            {
                key: 'MRI cervical spine with and without contrast',
                value: 'MRI cervical spine with and without contrast',
            },
            {
                key: 'MRI cervical spine without contrast',
                value: 'MRI cervical spine without contrast',
            },

            {
                key: 'MRA neck / carotids',
                value: 'MRA neck / carotids',
            },
            {
                key: 'CTA neck',
                value: 'CTA neck',
            },
            {
                key: 'CT cervical spine with and without contrast',
                value: 'CT cervical spine with and without contrast',
            },
            {
                key: 'CT cervical spine without contrast',
                value: 'CT cervical spine without contrast',
            },
            {
                key: 'Chest X-ray AP and lateral',
                value: 'Chest X-ray AP and lateral',
            },
            {
                key: 'X-rays cervical spine',
                value: 'X-rays cervical spine',
            },
            {
                key: 'Carotid Ultrasound',
                value: 'Carotid Ultrasound',
            },
            {
                key: 'MRI thoracic spine with and without contrast',
                value: 'MRI thoracic spine with and without contrast',
            },
            {
                key: 'MRI thoracic spine without contrast',
                value: 'MRI thoracic spine without contrast',
            },

            {
                key: 'CT thoracic spine with and without contrast',
                value: 'CT thoracic spine with and without contrast',
            },
            {
                key: 'CT thoracic spine without contrast',
                value: 'CT thoracic spine without contrast',
            },
            {
                key: 'X-rays thoracic spine',
                value: 'X-rays thoracic spine',
            },
            {
                key: 'MRI lumbosacral spine with and without contrast',
                value: 'MRI lumbosacral spine with and without contrast',
            },
            {
                key: 'MRI lumbosacral spine without contrast',
                value: 'MRI lumbosacral spine without contrast',
            },
            {
                key: 'CT lumbosacral spine with and without contrast',
                value: 'CT lumbosacral spine with and without contrast',
            },
            {
                key: 'CT lumbosacral spine without contrast',
                value: 'CT lumbosacral spine without contrast',
            },
            {
                key: 'X-rays lumbosacral spine',
                value: 'X-rays lumbosacral spine',
            },
            {
                key: 'DEXA scan',
                value: 'DEXA scan',
            },

            {
                key: 'CTA chest',
                value: 'CTA chest',
            },
        ],

        physical_therapy_recommendations: [
           
            {
                key: 'Evaluate and Teat',
                value: 'Evaluate and Teat',
            },
            {
                key: 'Exercise',
                value: 'Exercise',
            },
            {
                key: 'Manual Therapy',
                value: 'Manual Therapy',
            },
            {
                key: 'Chronic Pain Program',
                value: 'Chronic Pain Program',
            },
            {
                key: 'Posture/Position/Body Mechanics',
                value: 'Posture/Position/Body Mechanics',
            },
            {
                key: 'AROM',
                value: 'AROM',
            },
            {
                key: 'PROM',
                value: 'PROM',
            },
            {
                key: 'Back school',
                value: 'Back school',
            },

            {
                key: 'Gait training McKenzie Program',
                value: 'Gait training McKenzie Program',
            },
            {
                key: 'Aquatic Therapy',
                value: 'Aquatic Therapy',
            },
            {
                key: 'Neuro Re-education',
                value: 'Neuro Re-education',
            },
            {
                key: 'Home Program',
                value: 'Home Program',
            },
            {
                key: 'Orthotics',
                value: 'Orthotics',
            },
            {
                key: 'Cold Packs',
                value: 'Cold Packs',
            },
            {
                key: 'Hot Packs',
                value: 'Hot Packs',
            },
            {
                key: 'Electrical Stimulation',
                value: 'Electrical Stimulation',
            },
            {
                key: 'Ultrasound',
                value: 'Ultrasound',
            },

            {
                key: 'Massage/Soft tissue mobilization',
                value: 'Massage/Soft tissue mobilization',
            },
            {
                key: 'Traction',
                value: 'Traction',
            },
            {
                key: 'Cryotherapy',
                value: 'Cryotherapy',
            },
            {
                key: 'Paraffin',
                value: 'Paraffin',
            },
            {
                key: 'Iontophoresis',
                value: 'Iontophoresis',
            },
            {
                key: 'IFC',
                value: 'IFC',
            },
            {
                key: 'TENS',
                value: 'TENS',
            },
            {
                key: 'Phonophoresis',
                value: 'Phonophoresis',
            },
            {
                key: 'Whirlpool',
                value: 'Whirlpool',
            },
            {
                key: 'Functional Capacity Evaluation (FCE)',
                value: 'Functional Capacity Evaluation (FCE)',
            },

            {
                key: 'Ergonomic Analysis',
                value: 'Ergonomic Analysis',
            },
            {
                key: 'Work Simulation',
                value: 'Work Simulation',
            },
            {
                key: 'Post Offer Employment Testing (POET)',
                value: 'Post Offer Employment Testing (POET)',
            },
            {
                key: 'Job Site Analysis',
                value: 'Job Site Analysis',
            },
            {
                key: 'Work Capacity Evaluation',
                value: 'Work Capacity Evaluation',
            },
            {
                key: 'Work Conditioning Program',
                value: 'Work Conditioning Program',
            },
            {
                key: 'Work hardening',
                value: 'Work hardening',
            },
            {
                key: '3x/week/4 weeks',
                value: '3x/week/4 weeks',
            },
            {
                key: '3x/week/6 weeks',
                value: '3x/week/6 weeks',
            },
        ],

        home_health: [
            {
                key: 'Skilled Nursing:',
                value: 'Skilled Nursing:',
            },
            {
                key: 'Eval & Instruct for:',
                value: 'Eval & Instruct for:',
            },
            {
                key: 'Medication',
                value: 'Medication',
            },
            {
                key: 'Pain',
                value: 'Pain',
            },
            {
                key: 'Cardiac',
                value: 'Cardiac',
            },
            {
                key: 'Diabetic Management',
                value: 'Diabetic Management',
            },
            {
                key: 'Respiratory',
                value: 'Respiratory',
            },
            {
                key: 'Wound',
                value: 'Wound',
            },
            {
                key: 'Physical Therapy: Eval & Instruct for:',
                value: 'Physical Therapy: Eval & Instruct for:',
            },
            {
                key: 'Weakness',
                value: 'Weakness',
            },
            {
                key: 'Ambulation/Gait',
                value: 'Ambulation/Gait',
            },
            {
                key: 'Balance',
                value: 'Balance',
            },
            {
                key: 'Transfers',
                value: 'Transfers',
            },
            {
                key: 'Bed Mobility',
                value: 'Bed Mobility',
            },
            {
                key: 'Fall Risk',
                value: 'Fall Risk',
            },
            {
                key: 'Range of Motion',
                value: 'Range of Motion',
            },

            {
                key: 'Occupational Therapy:',
                value: 'Occupational Therapy:',
            },
            {
                key: 'Eval & Instruct for:',
                value: 'Eval & Instruct for:',
            },
            {
                key: 'ADLs',
                value: 'ADLs',
            },
            {
                key: 'Energy Conservation',
                value: 'Energy Conservation',
            },
            {
                key: 'Sensory Dysfunction',
                value: 'Sensory Dysfunction',
            },
            {
                key: 'Orthodics',
                value: 'Orthodics',
            },
            {
                key: 'Speech Therapy:',
                value: 'Speech Therapy:',
            },
            {
                key: 'Eval & Instruct for:',
                value: 'Eval & Instruct for:',
            },
            {
                key: 'Swallowing',
                value: 'Swallowing',
            },
            {
                key: 'Voice Intelligibility',
                value: 'Voice Intelligibility',
            },

            { key: 'Stress/Coping / Grief', value: 'Stress/Coping / Grief' },
            { key: 'Alternate living', value: 'Alternate living' },
            { key: 'In-home assistance', value: 'In-home assistance' },
            { key: 'Registered Dietician:', value: 'Registered Dietician:' },
            { key: 'Eval & Instruct for:', value: 'Eval & Instruct for:' },
            { key: 'Diet recommendation', value: 'Diet recommendation' },
            { key: 'Diet teaching', value: 'Diet teaching' },

            {
                key: 'Hearing',
                value: 'Hearing',
            },
            {
                key: 'Language Processing',
                value: 'Language Processing',
            },
            {
                key: 'Cognition',
                value: 'Cognition',
            },
            {
                key: 'Medical Social Worker:',
                value: 'Medical Social Worker:',
            },
            {
                key: 'Eval & Instruct for:',
                value: 'Eval & Instruct for:',
            },
            {
                key: 'Family support system',
                value: 'Family support system',
            },
            {
                key: 'Counseling referrals',
                value: 'Counseling referrals',
            },
            {
                key: 'Crisis intervention',
                value: 'Crisis intervention',
            },
            {
                key: 'Unsafe environment',
                value: 'Unsafe environment',
            },

            {
                key: 'Stress / Coping / Grief',
                value: 'Stress / Coping / Grief',
            },
            {
                key: 'Alternate living',
                value: 'Alternate living',
            },
            {
                key: 'In-home assistance',
                value: 'In-home assistance',
            },
            {
                key: 'Registered Dietician:',
                value: 'Registered Dietician:',
            },
            {
                key: 'Eval & Instruct for:',
                value: 'Eval & Instruct for:',
            },
            {
                key: 'Diet recommendation',
                value: 'Diet recommendation',
            },
            {
                key: 'Diet teaching',
                value: 'Diet teaching',
            },
        ],
        educations: [
            {
                key: 'Alternative treatments Drug interactions',
                value: 'Alternative treatments Drug interactions',
            },
            {
                key: 'Handouts provided',
                value: 'Handouts provided',
            },
            {
                key: 'Medication side effects',
                value: 'Medication side effects',
            },
            {
                key: 'Pain management',
                value: 'Pain management',
            },
            {
                key: 'Potential adverse consequences if pregnant',
                value: 'Potential adverse consequences if pregnant',
            },
            {
                key: 'Recognizing stroke-like symptoms',
                value: 'Recognizing stroke-like symptoms',
            },
            {
                key: 'Risks of declining treatment',
                value: 'Risks of declining treatment',
            },
            {
                key: 'Risks/benefits of treatment plan',
                value: 'Risks/benefits of treatment plan',
            },
        ],
        diet: [
            {
                key: 'Diet plan discussed',
                value: 'Diet plan discussed',
            },
            {
                key: '1800 cal ADA diet',
                value: '1800 cal ADA diet',
            },
            {
                key: '2000 cal ADA diet',
                value: '2000 cal ADA diet',
            },
            {
                key: '1500 cal ADA diet',
                value: '1500 cal ADA diet',
            },
            {
                key: 'Low fat diet',
                value: 'Low fat diet',
            },
            {
                key: 'Low sodium diet',
                value: 'Low sodium diet',
            },
            {
                key: 'Low cholesterol diet',
                value: 'Low cholesterol diet',
            },
            {
                key: 'Adequate nutritional intake',
                value: 'Adequate nutritional intake',
            },
            {
                key: 'Vitamin supplementation',
                value: 'Vitamin supplementation',
            },

            {
                key: 'Avoid alcohol',
                value: 'Avoid alcohol',
            },
            {
                key: 'Decrease alcohol consumption',
                value: 'Decrease alcohol consumption',
            },
            {
                key: 'Avoid caffeine',
                value: 'Avoid caffeine',
            },
            {
                key: 'Decrease caffeine consumption',
                value: 'Decrease caffeine consumption',
            },
            {
                key: 'Nutritional counseling',
                value: 'Nutritional counseling',
            },
            {
                key: 'Avoid specific foods such as cheese / chocolates / spicy foods or Chinese foods/ caffeinated beverages',
                value: 'Avoid specific foods such as cheese / chocolates / spicy foods or Chinese foods/ caffeinated beverages',
            },
        ],
        general_instructions: [
            {
                key: 'Activity as tolerated Avoid crowds',
                value: 'Activity as tolerated Avoid crowds',
            },
            {
                key: 'Avoid driving',
                value: 'Avoid driving',
            },
            {
                key: 'Avoid high risk activity due to seizures/LOC',
                value: 'Avoid high risk activity due to seizures/LOC',
            },
            {
                key: 'Follow exercise program',
                value: 'Follow exercise program',
            },
            {
                key: 'Follow facial exercise program',
                value: 'Follow facial exercise program',
            },
            {
                key: 'Increase activity level',
                value: 'Increase activity level',
            },
            {
                key: 'No heavy lifting',
                value: 'No heavy lifting',
            },
            {
                key: 'No heavy lifting more than 5 lbs',
                value: 'No heavy lifting more than 5 lbs',
            },
            {
                key: 'No heavy lifting more than 10 lbs',
                value: 'No heavy lifting more than 10 lbs',
            },
            {
                key: 'Will inform DMV',
                value: 'Will inform DMV',
            },
            {
                key: 'Driving for 3 months',
                value: 'Driving for 3 months',
            },
            {
                key: 'No driving until further notice',
                value: 'No driving until further notice',
            },
            {
                key: 'No driving until next follow-up',
                value: 'No driving until next follow-up',
            },

            {
                key: 'No heavy lifting more than 20 lbs Avoid prolonged sitting',
                value: 'No heavy lifting more than 20 lbs Avoid prolonged sitting',
            },
            {
                key: 'Avoid prolonged standing',
                value: 'Avoid prolonged standing',
            },
            {
                key: 'Avoid frequent bending / pulling / pushing',
                value: 'Avoid frequent bending / pulling / pushing',
            },
            {
                key: 'Increase rest',
                value: 'Increase rest',
            },
            {
                key: 'Maintain diary',
                value: 'Maintain diary',
            },
            {
                key: 'Notify DMV',
                value: 'Notify DMV',
            },
            {
                key: 'Protective activity',
                value: 'Protective activity',
            },
            {
                key: 'Seek help for addiction',
                value: 'Seek help for addiction',
            },

            {
                key: 'Wear wrist splint',
                value: 'Wear wrist splint',
            },
            {
                key: 'Will write letter for work release',
                value: 'Will write letter for work release',
            },
            {
                key: 'No work for 3 days',
                value: 'No work for 3 days',
            },
            {
                key: 'No work for 7 days',
                value: 'No work for 7 days',
            },
            {
                key: 'No work for 2 weeks',
                value: 'No work for 2 weeks',
            },
            {
                key: 'No work for 1 month',
                value: 'No work for 1 month',
            },
            {
                key: 'No work for 6 weeks',
                value: 'No work for 6 weeks',
            },
            {
                key: 'No work for 2 months',
                value: 'No work for 2 months',
            },
            {
                key: 'No work for 3 months',
                value: 'No work for 3 months',
            },
            {
                key: 'No work until further notice',
                value: 'No work until further notice',
            },
            {
                key: 'Will inform DMV',
                value: 'Will inform DMV',
            },
            {
                key: 'No driving for 3 months',
                value: 'No driving for 3 months',
            },
            {
                key: 'Driving until further notice',
                value: 'Driving until further notice',
            },
            {
                key: 'No driving until next follow-up',
                value: 'No driving until next follow-up',
            },
        ],
    },
    physical_exam: {
        general: [
            {
                key: 'Patient in distress',
                value: 'Patient in distress',
            },
            {
                key: 'Cachectic appearance',
                value: 'Cachectic appearance',
            },
            {
                key: 'Obese',
                value: 'Obese',
            },
            {
                key: 'Altered mental status',
                value: 'Altered mental status',
            },
            {
                key: 'sad today',
                value: 'sad today',
            },
        ],
        heent: [
            {
                key: 'traumatic lesion to head',
                value: 'traumatic lesion to head',
            },
            {
                key: 'coarse hair',
                value: 'coarse hair',
            },
            {
                key: 'thin hair',
                value: 'thin hair',
            },
            {
                key: 'visual acuity not intact',
                value: 'visual acuity not intact',
            },
            {
                key: 'A conjunctivae pale',
                value: 'A conjunctivae pale',
            },
            {
                key: 'conjunctivae cyanotic',
                value: 'conjunctivae cyanotic',
            },
            {
                key: 'sclerae icteric',
                value: 'sclerae icteric',
            },
            {
                key: 'pinpoint pupils',
                value: 'pinpoint pupils',
            },
            {
                key: 'enlarged pupils',
                value: 'enlarged pupils',
            },
            {
                key: 'extraocular movements not intact',
                value: 'extraocular movements not intact',
            },

            {
                key: 'TM erythematous/bulging',
                value: 'TM erythematous/bulging',
            },

            {
                key: 'rhinorrhea',
                value: 'rhinorrhea',
            },

            {
                key: 'sinus tenderness',
                value: 'sinus tenderness',
            },

            {
                key: 'pharyngeal erythema',
                value: 'pharyngeal erythema',
            },

            {
                key: 'pharyngeal exudate',
                value: 'pharyngeal exudate',
            },

            {
                key: 'pharyngeal lesion',
                value: 'pharyngeal lesion',
            },

            {
                key: 'tonsillar erythema',
                value: 'tonsillar erythema',
            },

            {
                key: 'tonsillar exudate',
                value: 'tonsillar exudate',
            },
            {
                key: 'tonsillar enlargement',
                value: 'tonsillar enlargement',
            },
        ],
        neck: [
            {
                key: 'lymphadenopathy located-',
                value: 'lymphadenopathy located-',
            },

            {
                key: 'goiter present',
                value: 'goiter present',
            },

            {
                key: 'other mass located-',
                value: 'other mass located-',
            },

            {
                key: 'carotid bruit-',
                value: 'carotid bruit-',
            },

            {
                key: 'trachea deviation',
                value: 'trachea deviation',
            },
        ],
        cardiovascular: [
            {
                key: 'JVP present-',
                value: 'JVP present-',
            },

            {
                key: 'carotid bruit present-',
                value: 'carotid bruit present-',
            },

            {
                key: 'murmur present-',
                value: 'murmur present-',
            },

            {
                key: 'rub present-',
                value: 'rub present-',
            },

            {
                key: 'gallop present-',
                value: 'gallop present-',
            },

            {
                key: 'S3 present',
                value: 'S3 present',
            },

            {
                key: 'S4 present',
                value: 'S4 present',
            },
        ],
        lungs: [
            {
                key: 'respiratory distress',
                value: 'respiratory distress',
            },

            {
                key: 'increased work of breathing',
                value: 'increased work of breathing',
            },

            {
                key: 'accessory muscle use',
                value: 'accessory muscle use',
            },

            {
                key: 'audible wheezing',
                value: 'audible wheezing',
            },

            {
                key: 'wheezing in all lungs fields on auscultation',
                value: 'wheezing in all lungs fields on auscultation',
            },

            {
                key: 'rhonchi in all lung fields on auscultation',
                value: 'rhonchi in all lung fields on auscultation',
            },

            {
                key: 'rales in all lung fields on auscultation',
                value: 'rales in all lung fields on auscultation',
            },

            {
                key: 'decreased breath sounds',
                value: 'decreased breath sounds',
            },

            {
                key: 'absent breath sounds',
                value: 'absent breath sounds',
            },
        ],
        abdomen: [
            {
                key: 'bowel sounds hypoactive',
                value: 'bowel sounds hypoactive',
            },

            {
                key: 'bowel sounds hyperactive surgical scar-',
                value: 'bowel sounds hyperactive surgical scar-',
            },

            {
                key: 'mass palpated RUQ-',
                value: 'mass palpated RUQ-',
            },

            {
                key: 'mass palpated RLQ-',
                value: 'mass palpated RLQ-',
            },

            {
                key: 'mass palpated LUQ-',
                value: 'mass palpated LUQ-',
            },

            {
                key: 'mass palpated LLQ-',
                value: 'mass palpated LLQ-',
            },

            {
                key: 'tenderness RUQ-',
                value: 'tenderness RUQ-',
            },

            {
                key: 'tenderness RLQ-',
                value: 'tenderness RLQ-',
            },

            {
                key: 'tenderness LUQ-',
                value: 'tenderness LUQ-',
            },

            {
                key: 'tenderness LLQ-',
                value: 'tenderness LLQ-',
            },

            {
                key: 'splenomegaly',
                value: 'splenomegaly',
            },

            {
                key: 'hepatomegaly',
                value: 'hepatomegaly',
            },

            {
                key: 'distended abdomen',
                value: 'distended abdomen',
            },

            {
                key: 'abdominal rigidity',
                value: 'abdominal rigidity',
            },

            {
                key: 'rebound tenderness',
                value: 'rebound tenderness',
            },

            {
                key: 'voluntary guarding',
                value: 'voluntary guarding',
            },

            {
                key: 'involuntary guarding',
                value: 'involuntary guarding',
            },
        ],
        msk: [
            {
                key: 'abnormal bulk/tone-',
                value: 'abnormal bulk/tone-',
            },

            {
                key: 'abnormal range of motion-',
                value: 'abnormal range of motion-',
            },

            {
                key: 'scoliosis-',
                value: 'scoliosis-',
            },

            {
                key: 'joint deformities-',
                value: 'joint deformities-',
            },

            {
                key: 'joint tenderness-',
                value: 'joint tenderness-',
            },
        ],
        breasts: [
            {
                key: 'nipple discharge-',
                value: 'nipple discharge-',
            },

            {
                key: 'mass palpated-location-',
                value: 'mass palpated-location-',
            },

            {
                key: 'tenderness-',
                value: 'tenderness-',
            },

            {
                key: 'lymphadenopathy-',
                value: 'lymphadenopathy-',
            },
        ],
        neuro: [
            {
                key: 'cranial nerve(s) dysfunction-',
                value: 'cranial nerve(s) dysfunction-',
            },

            {
                key: 'rapid alternating movements dysfunctional',
                value: 'rapid alternating movements dysfunctional',
            },

            {
                key: 'point-to-point movements dysfunctional-',
                value: 'point-to-point movements dysfunctional-',
            },

            {
                key: 'pain/temperature/light touch/vibration /discrimination dysfunctional-',
                value: 'pain/temperature/light touch/vibration /discrimination dysfunctional-',
            },

            {
                key: 'DTR abnormal-',
                value: 'DTR abnormal-',
            },
        ],
        exterminates: [
            {
                key: 'varicose veins present-',
                value: 'varicose veins present-',
            },

            {
                key: 'edema present-',
                value: 'edema present-',
            },
        ],
    },
}
