import React, { useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    makeStyles,
    InputAdornment,
} from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import Routes from 'constants/routes'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import {
    allApiReffringProvider,
    apiRaferrals,
    apiStatusCodes,
    getLocations,
    getSpecialty,
} from 'services/Calendar'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { INITIAL_VALUES } from './Schema'
import { validationSchema } from './validationSchema'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { uniqBy } from 'lodash'
import moment from 'moment'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import NoteField from 'containers/WorkQueues/component/NoteField'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import Divider from '@mui/material/Divider'
import TrashIcon from '../../../assests/icons/trash.svg'
import { getListPractices } from 'services/Setting'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import {
    getCheckListById,
    createCheckList,
    updateCheckList,
} from 'services/Setting'
const { v4: uuidv4 } = require('uuid')

const useStyles = makeStyles((theme) => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
    container: {
        padding: 24,
        position: 'relative',
        // height: 'calc(100vh - 70px)',
        // overflowY: 'auto',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        // marginBottom: '1rem',
    },
    header: {
        fontSize: '22px !important',
        fontWeight: '500 !important',
        color: '#303E4E',
        fontFamily: 'Rubik !important',
    },
    sub_header: {
        fontSize: 16,
        fontWeight: 500,
        color: '#303E4E',
    },
    border_text_grid: {
        background: '#ffffff',
        marginTop: '-24px',
        display: 'table',
        marginBottom: '20px',
    },
    required_text: {
        color: 'red',
    },
    item_ric: {
        background: '#fbfcfc',
        padding: '5px 15px',
        marginBottom: '13px',
        borderRadius: '7px',
        '& .icon': {
            '& svg': {
                width: '22px',
                color: '#6c6c6c',
            },
        },
    },
    btn_anew: {
        fontSize: '14px',
        fontWeight: '400',
    },
    wrap_scroll_list: {
        maxHeight: '469px',
        overflowY: 'auto',
    },
    divider_height_check: {
        height: 'auto !important',
        margin: '0px !important',
        background: '#667689'
    },
}))

const checkListIniArr = [
    {
        id: null,
        item: '',
    },
    {
        id: null,
        item: '',
    },
    {
        id: null,
        item: '',
    },
    {
        id: null,
        item: '',
    },
]
const removeEmptyObjects = (formData) => {
    return formData.filter((obj) => {
        // Check if any key is not empty in the current object
        const hasNonEmptyKey = Object.values(obj).some((value) => value !== '' && value !== null)
        // If any key is not empty, keep the object in the array
        return hasNonEmptyKey
    })
}
export const ReferringCheckListNew = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const { id: referralsID } = useParams()
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState(false)
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [specialOptions, setSpecialOptions] = useState([])
    const [clinicalOptions, setClinicalOptions] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(null)
    const handleSubmit = async (values) => {
        const payload = {
            ...values,
            items: removeEmptyObjects(values.items),
        }
        if (!referralsID) {
            try {
                setLoadingBtnSubmit(true)
                const res = await createCheckList(payload)
                if (res?.status === 200) {
                    history.push(
                        Routes.SETTINGS.REFERRAL_CHECKLIST_EDIT.replace(':id', res?.data?.data?.id),
                    )
                    notification(res?.data ? res?.data?.message : 'Create checklist successfully!', 'success')
                }
            } catch (error) {
                notification(error?.data ? error.data.message : 'Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
            }
        } else {
            try {
                setLoadingBtnSubmit(true)
                await updateCheckList(referralsID,payload)
                notification('Update Referral successfully !', 'success')
                history.push(Routes.SETTINGS.REFERRAL_CHECKLIST_EDIT.replace(':id', referralsID))

            } catch (error) {
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
            }
        }
    }
    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })
    const getSpecialtyAll = async () => {
        try {
            const res = await getSpecialty({ size: 1000 })

            if (res.data?.data) {
                setSpecialOptions(
                    res.data?.data?.map((item) => ({
                        key: item.id,
                        value: item.specialty,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const getSpecialtiesList = async () => {
        const response = await getListPractices({
            size: 10000,
        })
        setClinicalOptions(
            response?.data?.data.map((item) => ({
                key: item.id,
                value: item.name,
            })) || [],
        )
    }
    useEffect(() => {
        if (!referralsID) {
            formik.setFieldValue('items', [...checkListIniArr])
        }
    }, [checkListIniArr])

    const handleAddNew = () => {
        formik.setFieldValue('items', [...formik?.values?.items, { id: null, item: '' }])
    }
    /**
     * Set value items
     */
    const addItem = (lineIndex, field, value) => {
        formik?.setFieldValue(
            'items',
            formik.values?.items.map((line, index) =>
                index === lineIndex ? { ...line, [field]: value } : line,
            ),
        )
    }

    const getCheckList = async (id) => {
        setLoadingSchedule(true)
        try {
            const res = await getCheckListById(id)
            const resData = res?.data?.data
            setInitData({
                title: resData?.title,
                clinical_practice_id: resData?.clinical_practice_id,
                speciality_id: resData?.speciality_id,
                items:
                    resData?.items?.length <= 4
                        ? [...resData?.items, ...checkListIniArr.slice(0, 4 - resData?.items?.length)]
                        : resData?.items,
            })

        } catch (error) {
            if (error) {
                notification(error, 'error')
            }
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        let isMounted = true // Flag to track component's mounting status
        if (referralsID && isMounted) {
            getCheckList(referralsID)
        }
        return () => {
            isMounted = false // Component is unmounted, update the flag
            abortController.abort()
        }
    }, [referralsID])

    useEffect(() => {
        getSpecialtyAll()
        getSpecialtiesList()
    }, [])

    const handleClickDeleteIcon = (event, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(index)
    }

    const handleDeleteItem = () => {
        if (selectedItemIndex !== null) {
            if (
                formik?.values?.items?.length > 4 &&
                !(selectedItemIndex >= 0 && selectedItemIndex <= 3)
            ) {
                const updatedItems = formik?.values?.items.filter((_, i) => i !== selectedItemIndex)
                formik.setFieldValue('items', updatedItems)
            } else {
                const updatedItemData = [...formik?.values?.items]
                updatedItemData[selectedItemIndex] = {
                    item: '',
                    id: null
                }
                formik.setFieldValue('items', updatedItemData)
            }
        }
        setSelectedItemIndex(null)
        setAnchorEl(null)
    }
    return (
        <Grid container className={classes['container']}>
            <Grid container style={{ marginBottom: 16 }} className="Header-referrals">
                <Grid
                    item
                    xs={12}
                    style={{
                        width: 'fit-content',
                    }}>
                    <Box
                        style={{ cursor: 'pointer', display: 'inline-flex', marginBottom: '1rem' }}
                        onClick={() => history.push(Routes.SETTINGS.REFERRAL_CHECKLIST)}>
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />
                        <Typography className={classes['text_back']}>
                            Back to referral checklist list
                        </Typography>
                    </Box>
                </Grid>
                <div style={{ borderBottom: '1px solid #CFCFCF',width:'100%',padding: '10px 20px' }}>
                    <Typography className={classes.header}>
                        {referralsID ? 'Update Checklist' : 'Create Checklist'}
                    </Typography>
                </div>
            </Grid>
            <Grid container className="appointment-booking-container">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            left: 0,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        {/*------------------------------------------------Referral Information----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset
                                style={{
                                    marginBottom: '28px',
                                    borderRadius: '5px',
                                    height: '463px',
                                    overflowY: 'scroll',
                                }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Create Referral Checklist Template
                                    </Typography>
                                </legend>
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                        alignItems="flex-start">
                                        <Grid item xs={8}>
                                            <TextBox
                                                value={formik?.values?.title}
                                                label="Checklist Title"
                                                required
                                                placeholder="Enter title of checklist"
                                                onChange={(value) =>
                                                    formik?.setFieldValue('title', value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Specialty"
                                                placeholder="Select specialty"
                                                name="specialty"
                                                multiple={false}
                                                value={formik.values.speciality_id}
                                                displayEmpty={!formik.values.speciality_id}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.speciality_id &&
                                                    Boolean(formik.errors.speciality_id)
                                                }
                                                helperText={
                                                    formik.touched.speciality_id &&
                                                    formik.errors.speciality_id
                                                }
                                                options={specialOptions || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue('speciality_id', value)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Select
                                                label="Clinical Practice"
                                                placeholder="Select clinical practice."
                                                name="specialty"
                                                multiple={false}
                                                value={formik.values.clinical_practice_id}
                                                displayEmpty={!formik.values.clinical_practice_id}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.clinical_practice_id &&
                                                    Boolean(formik.errors.clinical_practice_id)
                                                }
                                                helperText={
                                                    formik.touched.clinical_practice_id &&
                                                    formik.errors.clinical_practice_id
                                                }
                                                options={clinicalOptions || []}
                                                onChange={(value) =>
                                                    formik.setFieldValue(
                                                        'clinical_practice_id',
                                                        value,
                                                    )
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginBottom: '12px' }}>
                                            <Grid container spacing={2}>
                                                {formik?.values?.items?.map((item, i) => (
                                                    <Grid item xs={12} key={i}>
                                                        <div
                                                            style={{
                                                                borderRadius: '5px',
                                                                border: '1px solid #D0D0D0',
                                                                background:
                                                                    'rgba(192, 193, 197, 0.10)',
                                                                padding: '10px',
                                                                width: '99%',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                            }}>
                                                            <div style={{ width: '96%' }}>
                                                                <TextBox
                                                                    value={item.item}
                                                                    placeholder={`Create checklist item ${
                                                                        i + 1
                                                                    }`}
                                                                    onChange={(value) =>
                                                                        addItem(i, 'item', value)
                                                                    }
                                                                />
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: '3%',
                                                                    display: 'flex',
                                                                }}>
                                                                <Divider
                                                                    orientation="vertical"
                                                                    variant="middle"
                                                                    className={
                                                                        classes.divider_height_check
                                                                    }
                                                                    flexItem
                                                                />
                                                                <div
                                                                    className="delete_icon"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={(event) => {
                                                                        handleClickDeleteIcon(
                                                                            event,
                                                                            i,
                                                                        )
                                                                    }}>
                                                                    {' '}
                                                                    <img src={TrashIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                ))}
                                                <CustomAlertPopOver
                                                    anchorEl={anchorEl}
                                                    handleClose={() => {
                                                        setAnchorEl(null)
                                                        setSelectedItemIndex(null)
                                                    }}
                                                    title="Delete Item"
                                                    bodyText={`Are you sure you want to delete the item?`}
                                                    confirmButtonName={'Delete'}
                                                    confirmButtonColor={'red'}
                                                    onSuccess={handleDeleteItem}
                                                />
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'end',
                                                        padding: 0,
                                                    }}>
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            cursor: 'pointer',
                                                            whiteSpace: 'nowrap',
                                                            marginTop: '5px',
                                                            alignItems: 'center',
                                                        }}
                                                        onClick={handleAddNew}>
                                                        <AddCircleOutlineOutlined
                                                            style={{
                                                                color: '#15A452',
                                                                fontSize: 18,
                                                            }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                color: '#15A452',
                                                                marginLeft: 8,
                                                                fontFamily: 'rubik',
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                            }}>
                                                            Add New Entry
                                                        </Typography>
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="flex-end" direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes['button_cancel']}
                                onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={loadingBtnSubmit}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                disabled={false}
                                className={classes['button_create']}
                                onClick={(e) => formik.handleSubmit(e)}>
                                {!referralsID ? 'Save' : 'Update'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
