import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { Pagination } from 'shared/elements/Pagination'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { NoRowsOverlayEl } from 'shared/elements'
import { bulkDeleteRecallList, createSchedule, getLocations, getRecallList, getSpecialty, getVisitType, updateRecall } from 'services/Calendar'
import Routes from 'constants/routes'
import { Box, Grid, Typography } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import patient from 'store/reducer/patient'
import { useStyles } from '../styles'
import { getSpecialityList } from 'store/actions/provider'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { formatDateTime } from 'utilities/formats'
import { Dialog, makeStyles } from '@material-ui/core'
import { ReactComponent as FilterIcon } from '../../../../assests/images/filter.svg'
import { LoadingButton } from '@mui/lab'
import { Button as ButtonCustom } from 'components/custom'
import { Button } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { IconButton, InputAdornment, Paper, TextField } from '@material-ui/core'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import TextBox from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { _getClinicalNotesFilterPatient } from 'containers/ClinicalNotes/api/services'
import { useDebounce } from 'shared/hooks'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import TrashIcon from 'assests/icons/TrashIcon'
import ReportIcon from '@mui/icons-material/Report'
import { ReactComponent as BulkDeleteIcon } from '../../../../assests/icons/BulkDeleteButton.svg'
import { ReactComponent as ReportIcon1 } from '../../../../assests/images/report_icon_1.svg'
import { PATIENT_VISIT_TYPE } from 'containers/AppointmentBooking/constant'
import { changeAppointment } from 'store/actions/schedule'
import SchedulerMenu from 'containers/SchedulerMenu'
import { getPatientNextAppointmentInWaitlist } from 'services/Patient/patient'
import Loading from 'shared/elements/Loading'

const UserStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    scheduleFormDialog: {
        '& .MuiDialog-container .MuiPaper-root': {
            maxWidth: '1150px !important',
            height: '100% !important'
        },
        '& .appointment-booking-container': {
            overflowY: 'hidden'
        }
    },
    scheduleModalCloseIconButton: {
        '& .MuiIconButton-label svg': {
            height: '12px !important',
            width: '12px !important',
            color: '#303e4e !important'
        }
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
}))

function RecallList() {
    const notification = useNotification()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [recallData, setRecallData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [visitType, setVisitType] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [loadingForm, setLoadingForm] = useState(false)
    const [loading,setLoading]= useState(false)
    const history = useHistory()
    const classes = useStyles()
    const definedStyles = UserStyles()
    const [activeRange, setActiveRange] = useState(false)
    const [onEdit, setOnEdit] = useState(false)
    const [onEditId, setOnEditId] = useState(false)
    const [locationOptions, setLocationOptions] = useState([])
    // const [listVisitType, setListVisitType] = useState([])
    const [contactAttempValue, setContactAttempValue] = useState(0)
    const [EditData, setEditData] = useState(null)
    const [allRowCheckbox, setAllRowCheckbox] = useState(false)
    const openEditOfContactAttemp = (row) => {
        if (row?.contact_attempt) {
            setContactAttempValue(row?.contact_attempt)
        } else {
            setContactAttempValue(0)
        }
        setEditData(row)
        setOnEditId(row?.id)
        setOnEdit(true)
    }
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [searchQueries, setSearchQueries] = useState({
        patient_id: null,
        speciality: null,
        visit_type: null
    })
    const [newAppointmentFormOpen, setNewAppointmentFormOpen] = useState({
        status: false
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient_id: '',
    })
    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        patient_id: false,
        speciality: false,
        visit_type: false
    })
    const [scrollPosition, setScrollPosition] = useState({
        patient_id: null,
        speciality: null,
        visit_type: null
    })
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient_id, 500)
    const [patientData, setPatientData] = useState([])
    const [listOptionSpecialty, setListOptionSpecialty] = useState([])
    const [listOptionVisitType, setListOptionVisitType] = useState([])
    const [currentPage, setCurrentPage] = useState(null)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    const [bundleId, setBundleId] = useState(null)
    const [patientID,setPatientID] = useState('');
    const [loadingPatientOption, setLoadingPatientOption] = useState(false)
    const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const timezone = useSelector((state) => state?.timezones?.tz?.key)
    const { idReferral: referralsID, ...restParams } = useParams()
    const dispatch = useDispatch()

    const setTheContactAttempt = async (e = '') => {
        if ((e === 'mousedown' || e?.key === 'Enter')) {
            setOnEdit(false)
            try {
                if (contactAttempValue != EditData.contact_attempt) {
                    setisLoading(true)
                    const id = EditData?.id
                    const payload = {
                        patient_id: EditData?.patient_id,
                        health_plan: EditData?.health_plan,
                        visit_type: EditData.visit_type,
                        contact_attempt: parseInt(contactAttempValue, 10),
                        speciality: EditData?.speciality,
                        notes: EditData?.notes,
                    }
                    const res = await updateRecall({ id, payload })
                    if (res) {
                        getRecall()
                    }
                }
            } catch (error) {
                notification('Something Went Wrong', 'error')
            }
        }
    }
    const clickOutsideHandler = (e) => {
        const taskUserPopperContainer = document.querySelector('.task-user-popper-containerTwo')
        if (taskUserPopperContainer !== null && !taskUserPopperContainer.contains(e.target)) {
            setTheContactAttempt("mousedown")
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', clickOutsideHandler)
        return () => document.removeEventListener('mousedown', clickOutsideHandler)
    })

    const getLocationExternal = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            if (locationExternal) {
                setLocationOptions(
                    locationExternal.map((item) => ({
                        key: item.id,
                        value: item.name,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        Promise.allSettled([
            getLocationExternal(),
        ])
        return () => abortController.abort()
    }, [])

    const handleScheduleFormSubmit = (values) => {
        if (values?.is_reoccurring_appointment) {
            values.reoccurring_appointment = {
                days: values.days,
                end_date: moment
                    .tz(values?.end_date || values?.date, 'YYYY-MM-DD HH:mm', timezone)
                    .utc()
                    .format(),
                unit: values?.unit === 'weekday' ? 'week' : values?.unit,
                once_every: values?.once_every,
            }
        }
        values.start = moment
            .tz(`${values?.date} ${values?.start_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.end = moment
            .tz(`${values?.date} ${values?.end_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.appointment_for = 'patient'
        values.participants = [
            {
                status: 'accepted',
                role: 'Patient',
                participant_id: values?.patient_id,
            },
        ]

        const payload = {
            start: values?.start,
            end: values?.end,
            practitioner_id: values?.practitioner_id,
            timezone,
        }

        if (values.is_reoccurring_appointment) {
            payload.reoccurring_appointment = {
                ...values.reoccurring_appointment,
                unit: values.unit,
            }
        }
        notification('Don\'t refresh the page while the appointment is being created!', 'warning')
        createSchedule(values).then((res) => {
            // formik.setFieldValue('reason', values?.reason_for_visit)
            setNewAppointmentFormOpen({ id: null, status: false })
            notification('Appointment successfully created!', 'success')
        }).catch((error) => {
            notification(
                error?.data?.message || 'Appointment was not successfully created!',
                'error',
            )
        })
    }

    useEffect(() => {
        listSpecialty()
        listVisitType()
    }, [])
    useEffect(() => {
        getPatientlist()
    }, [debouncedSearchFilterPatientText])

    const listSpecialty = async () => {
        const specialty = await getSpecialty({ size: 100000 })
        setSpeciality(specialty.data.data)
        if (specialty.data?.data) {
            let allOption = specialty.data?.data?.map((item) => ({
                key: item.id,
                value: item.specialty,
            }))
            setListOptionSpecialty(allOption)
        }
    }

    const handleClickRow = (row) => {
        window.open(
            Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.RECALL.EDIT.replace(':id', row.id),
            '_blank'
        )
    }

    // Function to add an item to the array
    const addItem = (item) => {
        setSelectedRows([...selectedRows, item])
    };

    // Function to remove an item from the array by its value
    const removeItem = (itemToRemove) => {
        setSelectedRows(selectedRows.filter(item => item !== itemToRemove));
    };

    const listVisitType = async () => {
        const visit = await getVisitType({ page: 1, limit: 100000 })
        setVisitType(visit.data.visitTypeList.data)
        if (visit?.data?.visitTypeList?.data) {
            let allOption = visit?.data?.visitTypeList?.data?.map((item) => ({
                key: item.id,
                value: item.post_code,
                duration: item.duration,
                color_tag: item.color_tag,
            }))
            setListOptionVisitType(allOption)
        }
    }
    const getRecall = async () => {
        setisLoading(true)
        const joinArrString = (arr) => {
            return arr.map((item) => item.key).join()
        }
        let {
            patient_id,
            speciality,
            visit_type
        } = searchQueries
        patient_id = patient_id?.length ? joinArrString(patient_id) : ''
        speciality = speciality?.length ? joinArrString(speciality) : ''
        visit_type = visit_type?.length ? joinArrString(visit_type) : ''
        const res = await getRecallList({ page, size: limit, patient_id, speciality, visit_type })
        if (res.data.data) {
            const dataArrenged = res.data.data.map((item, i) => ({

                "id": item.id,
                "patient_name": `${item.first_name} ${item.last_name}`,
                "custom_patient_id": item.custom_patient_id,
                "phone_number": item.phone_number,
                "speciality": item.speciality,
                "visit_type": item.visit_type,
                "contact_attempt": item.contact_attempt,
                "last_appointment_start": item.last_appointment_start,
                "patient_status": item.patient_status,
                "health_plan": item.health_plan,
                "notes": item.notes,
                "patient_id": item.patient_id,
                "clinical_practice":item.patient_clinical_practice_name

            }))
            setRecallData(dataArrenged || [])
        }
        setTotal((res?.data?.paging?.total))
        setisLoading(false)


    }

    const visitTypeFinder = (visitId) => {
        const visitName = visitType.find((data) => data.id === visitId)
        const findVal = visitName?.post_code
        return findVal
    }
    const specialityTypeFinder = (splId) => {
        const specialityName = speciality.find((data) => data.id === splId)
        const findsplty = specialityName?.specialty
        return findsplty
    }
    const handleChangeSelect = React.useCallback(
        (option, checked, state, field) => {
            let arr = [...(state ? state : [])]
            if (checked) {
                arr.push(option)
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            setSearchQueries({ ...searchQueries, [field]: arr })
            if (arr?.length > 0) {
                if (!totalNoOfFilterUsed.includes(field)) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, field])
                }
            } else if (arr?.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== field)
                setTotalNoOfFilterUsed(newData)
            }
            setPage(1)
        },
        [setSearchQueries, searchQueries, setTotalNoOfFilterUsed, totalNoOfFilterUsed, page],
    )
    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setSearchQueries({
                ...searchQueries,
                [name]: null,
            })
            const newData = totalNoOfFilterUsed.filter((item) => item !== name)
            setTotalNoOfFilterUsed(newData)
            setPage(1)
        },
        [setSearchQueries, searchQueries, setTotalNoOfFilterUsed, totalNoOfFilterUsed, page],
    )
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [setIsAccordionExpanded, isAccordionExpanded],
    )
    const scrollBarPosition = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        [setScrollPosition, scrollPosition],
    )
    const getPatientlist = async (name = '') => {
        try {
            setLoadingPatientOption(true)
            let payload = {
                page: 1,
                size: 20,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            setPatientData(tempUserList)
            setCurrentPage(1)
            setBundleId(res?.data?.bundle_id)
            const b = res?.data?.paging?.total / 20

            const remainder = res?.data?.paging?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPatient(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPatientOption(false)
        }
    }
    const filterSearch1 = (value, name) => {
        setSearchTextOffilter({
            ...searchTextOffilter,
            [name]: value,
        })
    }
    function CloseIcon() {
        return (
          <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.64645 2.64645C2.84171 2.45118 3.15829 2.45118 3.35355 2.64645L21.3536 20.6464C21.5488 20.8417 21.5488 21.1583 21.3536 21.3536C21.1583 21.5488 20.8417 21.5488 20.6464 21.3536L2.64645 3.35355C2.45118 3.15829 2.45118 2.84171 2.64645 2.64645Z"
              fill="#707578"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.3536 2.64645C21.5488 2.84171 21.5488 3.15829 21.3536 3.35355L3.35355 21.3536C3.15829 21.5488 2.84171 21.5488 2.64645 21.3536C2.45118 21.1583 2.45118 20.8417 2.64645 20.6464L20.6464 2.64645C20.8417 2.45118 21.1583 2.45118 21.3536 2.64645Z"
              fill="#707578"
            />
          </svg>
        );
    }
    const handleChangePage = async (name) => {
        if (totalPageNoOfPatient > currentPage) {
            const nextPage = currentPage + 1
            let payload = {
                page: nextPage,
                size: 20,
                bundle_id: bundleId,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            let sortedPatientData = [...patientData, ...tempUserList]
            setPatientData(sortedPatientData)
            setCurrentPage(nextPage)
            setBundleId(res?.data?.bundle_id)
        }
    }
    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'patient_id',
                name: 'Patient',
                value: searchQueries.patient_id,
                options: patientData,
                loading: loadingPatientOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.patient_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient_id,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'speciality',
                name: 'Specialty',
                value: searchQueries.speciality,
                options: listOptionSpecialty,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.speciality,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.speciality,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'visit_type',
                name: 'Visit Type',
                value: searchQueries.visit_type,
                options: listOptionVisitType,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.visit_type,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.visit_type,
                scrollBarPosition: scrollBarPosition,
            },
        ]
    }, [
        searchQueries,
        patientData,
        filterSearch1,
        loadingPatientOption,
        isAccordionExpanded,
        handleAccordionExpandNew,
        scrollPosition,
        scrollBarPosition,
        handleChangePage,
        listOptionSpecialty,
        listOptionVisitType
    ])
    const clearSearch = () => {
        setSearchQueries({
            patient_id: null,
        })
        setTotalNoOfFilterUsed([])
        setPage(1)
    }

    const columnsForRecall = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient_name',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Patient Status',
            headerClassName: 'super-app-theme--header',
            field: 'patient_status',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ||
                    '-'
                )
            },
        },
        {
            headerName: 'Clinical Practice',
            headerClassName: 'super-app-theme--header',
            field: 'clinical_practice',
            flex: 0.7,
            sortable: false,
        },
        {
            headerName: 'Last Appointment',
            headerClassName: 'super-app-theme--header',
            field: 'last_appointment_start',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {

                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Speciality',
            headerClassName: 'super-app-theme--header',
            field: 'speciality',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return specialityTypeFinder(value) || '-'
            },
        },
        {
            headerName: 'Visit Type',
            headerClassName: 'super-app-theme--header',
            field: 'visit_type',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return visitTypeFinder(value) || '-'
            },
        },
        {
            headerName: 'Contact of Attempts',
            headerClassName: 'super-app-theme--header',
            field: 'contact_attempt',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (onEdit && onEditId === row?.id
                ?
                <TextBox
                    className='task-user-popper-containerTwo'
                    name="contact_attempt"
                    value={contactAttempValue}
                    type="number"
                    onChange={(e) => {
                        if (e >= 0)
                            setContactAttempValue(e)
                    }}
                    onKeyDown={setTheContactAttempt}
                />
                :
                <Box sx={{ cursor: 'pointer', width: "100%" }} onClick={() => openEditOfContactAttemp(row)}>{row.contact_attempt ? row.contact_attempt : 0}</Box>
            ),
        },

        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => {
                                history.push(
                                    Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.RECALL.EDIT.replace(':id', row.id)
                                )
                            },
                        },
                        {
                            text: 'Schedule',
                            handler: ()=>{
                                setPatientID(row?.patient_id)
                                setLoading(true);
                            }
                        }
                    ]}
                />
            ),
        },
        {
            headerName: <CheckboxOption
                style={{ marginLeft: "34px" }}
                option={{
                    label: '',
                    value: false
                }}
                checked={allRowCheckbox}
                onChange={(event) => {
                    handleSelectAllRows(event?.target?.checked)
                }}
            />,
            headerClassName: 'super-app-theme--header',
            flex: 0.3,
            sortable: false,
            field: 'checkBox',
            renderCell: ({ row }) => {
                return (<CheckboxOption
                    style={{ marginLeft: "34px" }}
                    option={{
                        label: '',
                        value: row.id
                    }}
                    checked={selectedRows.includes(row?.id)}
                    onClick={(event) => event?.stopPropagation()}
                    onChange={(event) => handleSelectRows(row?.id, event.target.checked)}
                />)
            },
        },
    ]

    const handleSelectRows = (recallId, checkedValue) => {
        if (checkedValue) {
            let check = []
            setSelectedRows((state) => {
                check = [
                    ...state,
                    recallId
                ]
                return check
            })

            if (check?.length === recallData?.length) setAllRowCheckbox(checkedValue)
        }
        else {
            setSelectedRows(selectedRows?.filter(selectedRowItem => selectedRowItem !== recallId))
            setAllRowCheckbox(false)
        }
    }

    const handleSelectAllRows = (checkedValue) => {
        setAllRowCheckbox(checkedValue)
        if (checkedValue) {
            setSelectedRows(() => recallData?.map(recallDataSingle => recallDataSingle?.id))
        }
        else setSelectedRows([])
    }

    const handleBulkDelete = () => {
        const payload = {
            recall_ids: selectedRows
        }
        bulkDeleteRecallList(payload)?.then(() => {
            notification('Recall list(s) deleted successfully!', 'success')
            getRecall()
            setSelectedRows([])
            setAllRowCheckbox(false)
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => setOpenBulkDeleteDialog(false))
    }
    const removeRecalllistDialogContent = () => {

        return (
            <div style={{ maxWidth: '350px' }}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                    }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <ReportIcon1
                            style={{
                                fontSize: '18px',
                                marginRight: '10px',
                                color: '#D22121',
                            }}
                        />
                        <Typography variant="h6" fontWeight="bold">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: 'Remove From Recall List'
                                }}></div>
                        </Typography>
                    </span>
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <p
                        style={{
                            color: '#303E4E',
                            fontSize: 16,
                            fontWeight: 400,
                        }}>
                        Are you sure you want to remove the selected record(s) from the Recall list queue?
                    </p>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        size="small"
                        className="cancel-btn"
                        onClick={() => setOpenBulkDeleteDialog(false)}
                        style={{ marginRight: '10px', color: '#444' }}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            background: '#D22121',
                            borderRadius: '5px !important',
                        }}
                        onClick={handleBulkDelete}>
                        Remove
                    </Button>
                </span>
            </div>
        )
    }

    const handleDeleteDialogBox = () => {
        if (selectedRows?.length > 0) {
            setOpenBulkDeleteDialog(true)
        }
    }

    useEffect(() => {
        setSelectedRows([])
        setAllRowCheckbox(false)
        getRecall()
    }, [page, searchQueries])

    const getAppoinmentDetails = async (id) => {
        setLoadingForm(true)
        const nextAppointmentDetails = await getPatientNextAppointmentInWaitlist(id)
        if (nextAppointmentDetails?.data?.lastAppointment?.resource) {
            const nextAppointmentProps = nextAppointmentDetails?.data?.lastAppointment?.resource

            const nextAppointmentExtensions = nextAppointmentProps?.extension[0]?.extension

            const appointmentRequiredExtensionFields = {}
            nextAppointmentExtensions?.forEach(nextAppointmentExtensionsItem => {
                const objValues = Object.values(nextAppointmentExtensionsItem)
                appointmentRequiredExtensionFields[objValues[0]] = objValues[1]
            })

            const appointmentParticipants = {}
            nextAppointmentProps?.participant?.forEach(participantItem => {
                const actor = participantItem?.actor?.reference?.split("/")[0]?.toLowerCase(),
                value = participantItem?.actor?.reference?.split("/")[1]
                appointmentParticipants[actor] = value
            })
            const result = {
                id: '',
                day: moment().format('dddd'),
                specialty_id: appointmentRequiredExtensionFields?.specialty_id || '',
                visit_type_id: appointmentRequiredExtensionFields?.visit_type_id || '',
                date: moment().format('YYYY-MM-DD'),
                start_time: moment().format('HH:mm'),
                // end_time: moment().format('HH:mm'),
                timezone: appointmentRequiredExtensionFields?.timezone || '',
                appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                reason_for_visit: '',
                internal_note: '',
                location_id: appointmentParticipants?.location || '',
                duration: '',
                supervising_provider: '',
                is_require_translator: false,
                appointment_status: '',
                is_reoccurring_appointment: false,
                days: [],
                end_date: '',
                unit: '',
                once_every: '',
                end_type: '',
                practitioner_id: appointmentParticipants?.practitioner,
                patient_id: appointmentParticipants?.patient,
                note: '',
                noteView: 'no-view',
                notes: []
            }
            
            dispatch(
                changeAppointment({
                    id: -1,
                    values: result
                }),
            )
            
        } else {
            
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: patientID,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
        }
        setLoadingForm(false)
        setLoading(false)
    }

    function newAppointmentModalToggle(start, end, duration, id) {
        setPatientID('');
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: patientID,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                id,
            })
        } else {
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                start,
                end,
                duration,
                id,
            })
        }
        if (patientID) {
            getAppoinmentDetails(patientID)
        }
    }
    useEffect(()=>{
        if(patientID) {
            getAppoinmentDetails(patientID).then(()=>setNewAppointmentFormOpen({status: true}))
        }
    },[patientID])
    return (
        <>
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
            <Loading
                visible={
                    loading
                }
            />
                <Grid container justifyContent="space-between">
                    <Grid
                        container
                        flex={1}
                        justifyContent="end"
                        spacing={1}
                        style={{ marginTop: 5, marginBottom: 15 }}
                        className={classes?.subtabDiv}>
                        <Grid item style={{ width: 300 }}>
                            {!activeRange
                                // <SingleCalenderComp
                                //     onChange={(e) => {
                                //         setSingleDate(e ? format(e, 'MM/dd/yyyy') : '')
                                //         onChangeFilterData(
                                //             'service_date',
                                //             e
                                //                 ? [startDateFilterToUTC(e), endDateFilterToUTC(e)]
                                //                 : [],
                                //         )
                                //     }}
                                //     HideShowIcon={activeRange}
                                //     value={SingleDate}
                                //     onClear={() => {
                                //         setSingleDate('')
                                //     }}
                                // />
                            }
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'absolute',
                                    // backgroundColor: 'primary.dark',
                                    // '&:hover': {
                                    //     backgroundColor: 'primary.main',
                                    //     opacity: [0.9, 0.8, 0.7],
                                    // },
                                }}>
                                    
                                <Paper>
                                    {activeRange
                                        // <DateRangeComp
                                        //     style={{ marginTop: '20px' }}
                                        //     onChange={(e) => {
                                        //         if (e?.startDate && e?.endDate) {
                                        //             setStartDate(format(e?.startDate, 'MM/dd/yyyy'))
                                        //             setEndDate(format(e?.endDate, 'MM/dd/yyyy'))
                                        //             setSingleDate('')
                                        //             onChangeFilterData('service_date', [
                                        //                 startDateFilterToUTC(e?.startDate),
                                        //                 endDateFilterToUTC(e?.endDate),
                                        //             ])
                                        //         }
                                        //     }}
                                        //     activeRange={activeRange}
                                        //     dateSeparator="-"
                                        //     startDate={startDate}
                                        //     defaultRange={SingleDate || null}
                                        //     endDate={endDate}
                                        //     onClear={() => {
                                        //         setSingleDate('')
                                        //         setStartDate('')
                                        //         setEndDate('')
                                        //         onChangeFilterData('service_date', [])
                                        //     }}
                                        // />
                                    }
                                </Paper>
                            </Box>
                        </Grid>

                        <Grid item>
                            <HtmlTooltip
                                title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                                arrow
                                placement="bottom"
                                style={{
                                    background: 'white !important',
                                    color: '#303E4E',
                                }}>
                                <Box style={{ marginTop: '12px' }}>
                                    {/* <AntSwitch
                                        onChange={() => {
                                            setActiveRange(activeRange ? false : true)
                                            if (startDate && endDate && activeRange) {
                                                onChangeFilterData('service_date', [])
                                                setStartDate('')
                                                setEndDate('')
                                            }
                                        }}
                                        checked={activeRange}
                                    /> */}
                                </Box>
                            </HtmlTooltip>
                        </Grid>
                        <Grid item style={{ marginTop: '3px', display: "flex", marginRight: "32px" }}>
                            <MultiFilter
                                filterOptionsArrayObject={filterOptionsArrayObject}
                                handleChange={handleChangeSelect}
                                handleClearSingleFilter={handleClearSingleFilter}
                                totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                                handleClearFilter={clearSearch}
                            />
                            <ButtonCustom
                                style={{ marginTop: "4px" }}
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.RECALL.CREATE
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                            <HtmlTooltip
                                disableHoverListener
                                title={removeRecalllistDialogContent()}
                                placement='bottom'
                                open={openBulkDeleteDialog}
                            // onClose={() => setOpenBulkDeleteDialog(false)}
                            >
                                <ButtonCustom
                                    tooltip="Remove from Recall list"
                                    tooltip_placement="bottom"
                                    variant="contained"
                                    // color="primary"
                                    style={{
                                        cursor: selectedRows?.length > 0 ? 'pointer' : 'default',
                                        backgroundColor: selectedRows?.length > 0 ? '#D22121' : '#DCDCDC',
                                        margin: 0,
                                        minWidth: 32,
                                        width: 32,
                                        height: 32,
                                        borderRadius: 50,
                                        padding: 7,
                                        marginTop: "4px",
                                    }}
                                    onClick={handleDeleteDialogBox}
                                >
                                    <BulkDeleteIcon />
                                </ButtonCustom>
                            </HtmlTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.form_list_container}`}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        className='custom_data_grid'
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={recallData}
                        columns={columnsForRecall}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={(e) => {
                                        setPage(e)
                                    }}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        onRowClick={(row) => handleClickRow(row)}
                    />
                </Box>
            </Grid>
        </Grid>
        <Dialog
              open={newAppointmentFormOpen.status}
                className='recall_list_schedule'
                fullWidth={true}
                onClose={newAppointmentModalToggle}
                >
                
                <div>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid #dddd'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '58%'
                        }}>
                            <Typography style={{
                                fontSize: 18,
                                padding: '10px 0px',
                                fontWeight: 900,
                                color: '#303e4e',
                            }}>Schedule Appointment</Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '40%'
                        }}>
                            <IconButton
                                size='5px'
                                onClick={newAppointmentModalToggle}
                                className={classes.scheduleModalCloseIconButton}>
                                <CloseIcon style={{ height: '10px !important', width: '10px !important', color: '#303e4e' }} />
                            </IconButton>
                        </div>
                        
                    </div>
                    <SchedulerMenu
                        isPopupType={true}
                        tab={1}
                        isScheduler={true}
                        open={newAppointmentFormOpen.status}
                        appointmentModal={newAppointmentFormOpen}
                        loading={loadingForm}
                        onModalClose={newAppointmentModalToggle}
                        onSubmit={handleScheduleFormSubmit}
                        locationOptionsData={locationOptions.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={listOptionVisitType.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                    />
                </div>
                
        </Dialog>
        </>
    )
}

export default RecallList
