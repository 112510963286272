import { Grid, Typography } from '@material-ui/core'
import { Popover } from '@mui/material'
import { hexToRgb } from 'containers/CalendarScheduler/utils'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import Routes from '../../../../../constants/routes'
import { UserRoles } from '../../../../../constants/user-roles'
import { APPOINTMENT_TYPE, EVENT_TYPE, DRAFT_APPOINTMENT_TOOLTIP } from '../../../constant'
import { EventContentBusySlotWeek, EventContentGeneral } from '../eventContent'
import WeekCard from './weekCard'

import { useStyles } from '../../useStyles'

function WeekCalendarEvent(props) {
    const history = useHistory()
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        if (!props.isSchedulerForm) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined

    const timeDuration = () => {
        if (!props?.event?.start || !props?.event?.end) return ''

        const startTime = props.event.start,
            endTime = props.event.end

        const duration = endTime.getTime() - startTime.getTime()

        const oneMinute = 1000 * 60,
            oneHour = 1000 * 60 * 60

        //greater than a hour
        if (duration > oneHour) {
            return 'more-than-an-hour'
        }

        //less than or equal to a hour and greater than 45 mins
        if (duration <= oneMinute * 60 && duration > oneMinute * 45) {
            return 'an-hour'
        }

        //less than or equal to 45 mins and greater than 30 mins
        if (duration <= oneMinute * 45 && duration > oneMinute * 30) {
            return '45-mins'
        }

        //less than or equal to 30 mins and greater than 20 mins
        if (duration <= oneMinute * 30 && duration > oneMinute * 20) {
            return '30-mins'
        }

        //less than or equal to 20 mins and greater than 15 mins
        if (duration <= oneMinute * 20 && duration > oneMinute * 15) {
            return '20-mins'
        }

        //less than or equal to 15 mins and greater than 10 mins
        if (duration <= oneMinute * 15 && duration > oneMinute * 10) {
            return '15-mins'
        }

        //less than or equal to 10 mins
        if (duration <= 1000 * 60 * 10) {
            return '10-mins'
        }
    }

    function handlePatientNameClick(id) {
        history.push(Routes.PROFILE, {
            userId: id,
            role: UserRoles.PATIENT,
        })
    }

    if (props.event.type === EVENT_TYPE.TIMEOFF) {
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Typography noWrap>
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
        )
    }

    if (props.event.type === EVENT_TYPE.BUSY_SLOT) {
        return (
            <>
                <Popover
                    id={endId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: '0px 0px 10px #888888',
                        },
                    }}>
                    <EventContentBusySlotWeek {...props.event} classes={classes} />
                </Popover>

                <span style={{ cursor: 'pointer' }} onClick={handleClick}>
                    <Grid container alignItems="center" justifyContent="flex-start">
                        <Grid item>
                            <Typography noWrap className="black">
                                <strong>{props.title}</strong>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="inherit" display="inline" className="capitalize">
                                <a
                                    href="#"
                                    style={{ color: 'black' }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handlePatientNameClick(props.event.appointment.patient.id)
                                    }}>
                                    {props.event.appointment.patient?.name ?? ''}
                                </a>
                            </Typography>{' '}
                            |{' '}
                            <Typography color="inherit" display="inline">
                                {props.event.appointment.type === APPOINTMENT_TYPE.TELE_HEALTH
                                    ? 'Tele-health'
                                    : 'In-clinic'}
                            </Typography>
                        </Grid>
                    </Grid>
                </span>
            </>
        )
    }

    if (props.event.type === EVENT_TYPE.FREE_SLOT) {
        return <Grid container alignItems="center" justifyContent="flex-start"></Grid>
    }

    return (
        <>
            {!props.isSchedulerForm && <Popover
                id={endId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                }}>
                <EventContentGeneral
                    {...props.event}
                    classes={classes}
                    timezone={props.timezone}
                    onSelectEvent={props.onSelectEvent}
                    handleClose={handleClose}
                    optimisticDelete={props.optimisticDelete}
                    optimisticUpdate={props.optimisticUpdate}
                    onSelectEventStaff={props.onSelectEventStaff}
                    refetchSchedule={props.refetchSchedule}
                    handlePatientNameClick={handlePatientNameClick}
                    getApiEncounterQueue={props.getApiEncounterQueue}
                />
            </Popover>}
            <HtmlTooltip  title={props.isDraft ? DRAFT_APPOINTMENT_TOOLTIP : ''}
                arrow
                placement="top"
                style={{
                    background: 'white !important',
                    color: '#303E4E',
                }}>
            <span
                style={{ 
                    cursor: props.isDraft ? 'not-allowed' : !props.isSchedulerForm && 'pointer',
                    width: '100%',
                    opacity: props.isDraft ? 0.6 : 1
                }}
                onClick={props?.event?.id && !props.isDraft && handleClick}
            >
                <Grid
                    container
                    className={classes.item_event}
                    style={{
                        height: '100%',
                        borderTop: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderBottom: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderRight: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderLeft: `4px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        background: hexToRgb(
                            props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF'),
                        ),
                    }}
                >

                    <WeekCard timeDuration={timeDuration} classes={classes} parentProps={props} />

                </Grid>
            </span>
            </HtmlTooltip>
        </>
    )
}

export default WeekCalendarEvent
