import React, { useRef, useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import LoadingPage from 'utilities/loading-page'
import Routes from 'constants/routes'
import { Footer } from 'shared/elements/FormItem/Footer'
import { INITIAL_VALUES } from './schema'
import { useNotification } from 'shared/elements/Notification'
import '../style.scss'
import TextBox from 'shared/elements/FormItem/TextBox'
import FileUpload from '../FileUpload'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { useSelector } from 'react-redux'
import { addBrandingBackGround } from 'services/Provider/provider'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        fontSize: "16px",
        fontWeight:400,
        cursor:"pointer"
    },
    border_bottom: {
        borderBottom: "1px solid #CFCFCF",
        marginBottom: "7px",
    },
    page_title: {
        paddingLeft: "30px",
        fontSize: "22px",
        fontWeight: 500,
        color: "#303E4E"
    },
    containerNew: {
        width: "100%",
        border: '1px solid #CFCFCF',
        borderRadius: 5,
        padding: '18px 22px 22px 22px',
    },
    heading1_title: {
        fontFamily: "Rubik",
        fontWeight: 400,
        fontSize: "18px",
        color: "#303E4E" 
    }
    
})

export const BackgroundForm = () => {
    const classes = useStyles()
    const history = useHistory()
    const formRef = useRef(null)
    const location = useLocation()
    const notification = useNotification()
    const validationSchema = yup.object({
        filename: yup.string().required('Background Name is required!'),
        
    })
    const validateForm = (errors) => {
        if (!isEmpty(errors)) {
            const field = Object.keys(errors)[0]
            let el

            if (Array.isArray(errors[field])) {
                const errorIndex = errors[field].findIndex((error) => !!error)

                el = formRef.current.querySelector(`[name="${field}_${errorIndex}"]`)
            } else el = formRef.current.querySelector(`[name="${field}"]`)

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
    }
    const userData = useSelector((state) => state?.login?.userData)
    const goBack = () => history.push(Routes.SETTINGS.BRANDING)
    const handleSubmit = (value) =>{
            const payload = {
                ...value,
                filetype: "BACKGROUND_IMAGE",
                created_by: userData?.practitioner?.id,
                created_by_name: userData?.practitioner?.first_name + " " + userData?.practitioner?.last_name 
            }
            setIsSubmitting(true)
            addBrandingBackGround(payload)
                .then(() => {
                    notification("Branding Background Image created successfully!", 'success')
                    setIsSubmitting(false)
                    goBack()
                })
                .catch((error) => {
                    setIsSubmitting(false)
                            notification( "Branding Background Image didn't create successfully!",
                                'error',
                            )
                        
                })
       
    }
    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })
    
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    
    //heading title
    if (isLoading) {
        return <LoadingPage />
    }

    return (
        <div ref={formRef} style={{ height: '100%' }}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    >
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back} onClick={() => history.push(Routes.SETTINGS.BRANDING, { tab: 0 })}>{'Back to Background'}</Typography>
                </Grid>
                <Grid container className={classes.border_bottom}>
                    {' '}
                    <h1 className={classes.page_title}>{'Create New Background'}</h1>
                </Grid>
                <Grid container item className={classes.containerNew} xs={12}>
                    <Grid item xs={12} style={{ marginBottom: '18%' }}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography className={classes.heading1_title}>
                                    Upload Background
                                </Typography>
                            </legend>
                            <Grid
                                container
                                item
                                alignItems="flex-start"
                                spacing={2}
                                style={{ marginTop: '2px' }}>
                                <Grid item xs={5}>
                                    <TextBox
                                        label="Background Name"
                                        required
                                        name="filename"
                                        placeholder="Enter background name"
                                        value={formik.values.filename}
                                        error={
                                            formik.touched.filename &&
                                            Boolean(formik.errors.filename)
                                        }
                                        helperText={
                                            formik.touched.filename && formik.errors.filename
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'filename',
                                                value.replace(/[^a-zA-Z ]+/g, ''),
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileUpload
                                        fileId={(value) => {
                                            formik.setFieldValue('file_id', value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ paddingLeft: '9px' }}>
                                    <CheckboxOption
                                        option={{
                                            label: 'Set as default background',
                                            value: true,
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldValue('is_default', e.target.checked)
                                        }}
                                        checked={formik?.values?.is_default}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
                <Footer
                    style={{
                        marginTop: '1.5rem',
                    }}
                    okBtnText="Save"
                    loading={isSubmitting}
                    onCancel={goBack}
                    onOk={() => {
                        formik.handleSubmit()
                        validateForm(formik.errors)
                    }}
                />
            </Grid>
        </div>
    )
}


