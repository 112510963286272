import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { Loading } from 'shared/elements/Loading'
import { Card } from '../../view/Card'
import { NoData } from '../NoData'

import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { usePatientDashboardStyles } from '../../style'
import { PsychiatricMedication } from '../../interfaces'
import { PsychiatricMedicationForm } from '../../view/PsychiatricMedicationHistory/PsychiatricMedicationForm'
import { formatDateTime } from 'utilities/formats'
import { apiPsychiatricMedicalHistory } from 'services/Patient/apiPsychiatricMedicalHistory'

interface Props {
    onClick?: () => void
    patientId?: string
    encounter_id?: string
    viewAllFunc?: () => void
}

export const PsychiatricMedicationHistory = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [surgical, setSurgical] = useState<PsychiatricMedication[]>([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState('')

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getPsychiatricMedicationHistory()
        }

        return () => abortController.abort()
    }, [patientId])

    async function getPsychiatricMedicationHistory() {
        try {
            setLoading(true)

            const resp = await apiPsychiatricMedicalHistory.getPsychiatricMedication(patientId, { page: 1, size: 5 })
            if (resp.data.message.data) {
                setSurgical(resp.data.message.data)
            }
        } catch (ex) {
            notification('Cannot get list surgical', 'error')
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.PSYCHIATRIC_MEDICAL_HISTORY.replace(':id', patientId))

    const Content = () => (
        <>
            {surgical.map((item, key) => {
                const brandName = item?.drug?.brandName
                const genericName = item?.drug?.genericName
                const strength = item?.drug?.strength
                const form = item?.drug?.form
                const showText = `${brandName} ${genericName && genericName}, ${
                    strength && strength
                }, ${form && form}`
                return (
                    <Grid
                        container
                        key={key}
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #EFF2F5',
                            paddingBottom: 8,
                            height: 'fit-content',
                        }}>
                        <Grid
                            item
                            container
                            alignItems="flex-start"
                            justifyContent="space-between"
                            direction="column"
                            style={{ width: 'fit-content' }}>
                            <Typography>{showText}</Typography>
                            <Typography>{formatDateTime(item.end_date, 'MMM DD, YYYY')}</Typography>
                        </Grid>
                    </Grid>
                )
            })}

            {!!surgical.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Psychiatric Medication History">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {surgical.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            {isAddingNew && (
                <PsychiatricMedicationForm
                    // psychiatricMedicalId={}
                    patientId={patientId}
                    encounter_id={props.encounter_id}
                    open={isAddingNew}
                    onOke={() => {
                        setIsAddingNew(false)
                        getPsychiatricMedicationHistory()
                    }}
                    onClose={() => {
                        setIsAddingNew(false)
                    }}
                />
            )}
        </Card>
    )
}
