import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { InpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'

type Props = {
    dataSource?: InpatientPsychiatric[]
    pagination?: Pagination
    sortModel?: GridSortModel
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    onPageChange?: React.Dispatch<React.SetStateAction<number>>
    onClickEdit?: (id: string) => void
    onClickDelete?: (id: string) => void
    nonEditable?: Boolean
}

export const InpatientPsychiatricHistoryDashboard = (props: Props) => {
    const { pagination } = props

    // Hooks
    const notification = useNotification()

    const columns: GridColumns<InpatientPsychiatric> = [
        {
            headerName: 'Facility',
            headerClassName: 'super-app-theme--header',
            field: 'facility',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.facility} />,
        },
        {
            headerName: 'Voluntary',
            headerClassName: 'super-app-theme--header',
            field: 'voluntary',
            flex: 0.5,
            sortable: true,
            renderCell: ({ row }) => (
                <DataGridCell
                    value={row.voluntary ? 'Yes' : row.voluntary === false ? 'No' : 'N/A'}
                />
            ),
        },
        {
            headerName: 'Reason',
            headerClassName: 'super-app-theme--header',
            field: 'reason',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.reason} />,
        },
        {
            headerName: 'Age',
            headerClassName: 'super-app-theme--header',
            field: 'age',
            flex: 0.5,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.age} />,
        },
        {
            headerName: 'Outcome',
            headerClassName: 'super-app-theme--header',
            field: 'outcome',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.outcome} />,
        },
        {
            headerName: 'Comments',
            headerClassName: 'super-app-theme--header',
            field: 'comment',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.comment} />,
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                !props.nonEditable
                   ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.onClickEdit?.(row.id as string),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.onClickDelete?.(row.id as string),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                ),
        },
    ]

    return (
        <DataGrid
            getRowId={(row) => row.id as string}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={pagination?.limit || 10}
            paginationMode="server"
            rowCount={pagination?.total || 0}
            onPageChange={(start) => props.onPageChange?.(start)}
            page={pagination?.start}
        />
    )
}
