export const mobileRegex = new RegExp(
    /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/i,
)
export const emailRegex = new RegExp(
    /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
)
export const charRegex = new RegExp(/^[A-Za-z ]+$/i)
export const numberRegex = new RegExp(/^\d+$/)
export const USPhoneRegex = new RegExp(
    /(\d{3})(\d{3})(\d{4})/,
)
export const usNumberRegex = new RegExp(
    /^(\()?[2-9]{1}\d{2}(\))?(\s)?\d{3}(\s)\d{4}$/,
)

export const zipCodeRegex =
    /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/

export const taxIdRegex =
    /^(\d{3})([ \-]?)(\d{2})([ \-]?)(\d{4})$/

// US Phone format: +1 - 650 - 513 - 0514
// ^((\(\+{0,1}1\)|\+{0,1}1)\s{0,1})?(\d{3}-\d{3}-\d{4}|\d{10})$
// /^((\(\+{0,1}[0-9]{1,3}\)|\+{0,1}[0-9]{1,3})\s{0,1})?(\d{3}-\d{3}-\d{4}|\d{10})$/
export const phoneNumberRegex =
    /^((\(\+{0,1}1\)|\+{0,1}1)\s{0,1})?(\d{3}-\d{3}-\d{4}|\d{10})$/

export const USPhoneRegexp = /^\(\d{3}\)\s?\d{3}\-?\d{4}$/

export const DEANumberRegex = /^[a-zA-Z]{2}[0-9]{7}$/


export const PasswordValidator = {
  isAtLeastEightCharactors: (password = '') => {
    return /.{8,}/.test(password);
  },
  isAtLeastOneSpecialCharacter: (password = '') => {
    return /(?=.*?[#?!@$%^&*-])/.test(password);
  },
  isAtLeastOneDigit: (password = '') => {
    return /(?=.*?[0-9])/.test(password);
  },
  isAtLeastOneLowerCaseLetter: (password) => {
    return /(?=.*?[a-z])/.test(password)
  },
  isAtLeastOneUpperCaseLetter: (password) => {
    return /(?=.*?[A-Z])/.test(password)
  }
} 