
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService
} from 'utilities/request/restClient'

interface Payload {
    page?: string
    limit?: string 
}
export const apiFamilyPsychiatric = {
  create(patienrId: string | number, params: FamilyPsychiatric) {
      return postService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/family-medical-history`,
          params,
      )
  },
  getAll(patienrId, payload) {
      const queryString = new URLSearchParams({
          ...payload,
      }).toString()
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/family-medical-history?${queryString}`,
      )
  },
  getId(patientId, familyMedicalId) {
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`,
      )
  },
  update(patientId: string | number, familyMedicalId: string | number , payload: FamilyPsychiatric) {
      return patchService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`,
          payload,
      )
  },
  delete(patientId: string | number, familyMedicalId: string | number ) {
      return deleteService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/family-medical-history/${familyMedicalId}`
      )
  },
}