import { Button, Grid, makeStyles, styled, Typography } from '@material-ui/core'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import urlLoginVideoScreen from '../../assests/images/loginVideoScreen_bg.png'
import appLogo from '../../assests/Logos/tm2u_logo_white.png'
import validateLink from './apis/validateLink'
import useProviderContext from './hooks/useProviderContext/useProviderContext'
import useVideoContext from './hooks/useVideoContext/useVideoContext'
import { useAppState } from './state'
import { CameraTest } from './components/CameraTest/CameraTest'
interface DecryptedData {
    firstName: string
    lastName: string
    isProvider: boolean
    roomId: string
    patient: string
}

function useQuery() {
    return new URLSearchParams(useLocation().search)
}
const useStyles = makeStyles((theme) => ({
    container: {
        height: 'auto',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: `#889BD7`,
        flexDirection: 'column',
        flexWrap: 'nowrap',
        padding: '30px',
        [theme.breakpoints.between('xs', 'sm')]: {
            height: 'auto !important',
        },
        [theme.breakpoints.down('md')]: {
            height: 'auto !important',
        },
    },
    appLogo: {
        width: 250,
        [theme.breakpoints.between('xs', 'sm')]: {
            width: 150,
        },
    },
    button: {
        background: '#5571c6',
        color: 'white',
        fontSize: 15,
        fontWeight: 400,
    },
    logoContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center',
        },
    },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    width: 350,
    marginTop: 32,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'gray',
        borderRadius: 5,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}))

export default function LoginVideoScreen(props: any) {
    let query = useQuery()

    let iv = query.get('iv')
    let data = query.get('data')
    const dataEncode = encodeURIComponent(data)
    // let {iv, data}:{iv:any, data:any} = useParams();

    const { user, getToken, isFetching } = useAppState()
    const {
        room,
        getAudioAndVideoTracks,
        connect: videoConnect,
        isAcquiringLocalTracks,
        isConnecting,
        localTracks,
    } = useVideoContext()
    const { socket, setAccessAdmit, setCurrentUser, setListWaiting, setCountWaitingUser } =
        useProviderContext()
    const classes = useStyles()
    const [loading, setLoading] = useState<boolean>(true)
    const [isProvider, setIsProvider] = useState<boolean>(false)
    const [name, setName] = useState<string>(user?.displayName || '')
    const [roomName, setRoomName] = useState<string>('')
    const [userId, setUserId] = useState<string>('')
    const [roomId, setRoomId] = useState<string>('')
    const [mediaError, setMediaError] = useState<boolean>(false)

    let history = useHistory()
    const [progress, setProgress] = React.useState(10)

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5))
        }, 500)
        return () => {
            clearInterval(timer)
        }
    }, [])
    useEffect(() => {
        if (!mediaError) {
            getAudioAndVideoTracks().catch((error) => {
                console.log('Error acquiring local media:')
                console.dir(error)
                setMediaError(true)
            })
        }

        // if (localTracks.length > 0) {

        //   validateLink({ data })
        //   .then((res) => {
        //     if (res?.data && Object.keys(res.data).length === 0) {
        //       history.push('/invalid-link')
        //     } else {
        //           history.push(`/join?data=${dataEncode}`,{infoRoom: res.data.validateLink})

        //     }
        //  })
        //   .catch((err) => {
        //     // setLoading(false)
        //     history.push('/invalid-link')
        // })
        // }
    }, [getAudioAndVideoTracks, mediaError])

    return (
        <>
            <Grid
                container
                item
                alignItems="center"
                direction="column"
                className={classes.container}>
                <div className={classes.logoContainer}>
                    <img className={classes.appLogo} src={appLogo} alt="TeleMed2U" />
                </div>
                <CameraTest mediaError={mediaError} openLoading={setMediaError} />
                {/* {mediaError ? <>
          <Typography
            variant="body2"
            style={{ color: "white", fontSize: "19px", marginTop: 80, marginBottom: 32 }}
          >
            Oops! Check your devices, please.
          </Typography>
          
      <Button href={"/"} variant="contained"  className={classes.button}>Go to Home page</Button>
          
        </> : <>
          <Typography
            variant="body2"
            style={{ color: "white", fontSize: "19px", marginTop: 80 }}
          >
            Connecting to Telehealth...
          </Typography>
          
            <BorderLinearProgress variant="determinate" value={progress} />
            <Typography style={{fontSize: 14, color: 'white', marginTop: 32}}>{progress} %</Typography>
          </>
          } */}
            </Grid>
        </>
    )
}
