import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Select } from 'shared/elements/FormItem/Select'

import { taxIdRegex } from 'utilities/regex'
import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Tax, ZipCode } from 'shared/elements/FormItem/Input'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { ErrorType } from 'containers/PatientsSetting/interfaces'

import { HealthPlan } from './interface'
import { INITIAL_DATA } from './seed'
import { getHealthPlanById } from 'services/Setting'
import { Loading } from 'shared/elements/Loading'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import { TextMaskCustom } from 'containers/PatientsSetting/view/Insurances/InsurancePlanForm'

interface Props {
    loading?: boolean
    open?: boolean
    healthPlan_id?: string
    type?: string
    onCancel?: () => void
    onSubmit?: (payload: HealthPlan) => void
}

export const HealthPlanForm = ({ loading, ...props }: Props) => {
    const notification = useNotification()

    const [data, setData] = useState(INITIAL_DATA)
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()

        if (props.healthPlan_id) {
            getPracticeDetails(props.healthPlan_id)
        }

        return () => abortController.abort()
    }, [props.healthPlan_id])

    const validationSchema = yup.object({
        company_name: yup.string().required('Insurance Company is required!'),
        plan_name: yup.string().required('Plan Name is required!'),
        address1: yup.string().required('Address 1 is required!'),
        city: yup.string().required('City is required!'),
        state: yup.string().required('State is required!'),
        zip: yup.string().required('Zip is required!'),
        // tax_id: yup
        //     .string()
        //     .matches(taxIdRegex, 'Please enter the correct format')
        //     .required('Tax id is required!'),
        phone_number: yup.string().required('Phone number is required!'),
    })

    const getPracticeDetails = async (healthPlan_id: string) => {
        try {
            setDataLoading(true)
            const response = await getHealthPlanById(healthPlan_id)

            setData(response?.data || INITIAL_DATA)
        } catch (exception) {
            notification(
                (exception as ErrorType).data?.message || 'Cannot get Health Plan details',
                'error',
            )
        } finally {
            setDataLoading(false)
        }
    }

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        enableReinitialize: true,
        onSubmit: (values) => props.onSubmit && props.onSubmit(values),
    })

    return (
        <Modal
            width={700}
            title={props.type === "view" ? "Health Plan" : (props.healthPlan_id ? 'Update Health Plan' : 'Add New Health Plan')}
            description="Health Plan Details"
            open={props.open}
            onClose={props.onCancel}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container style={{ position: 'relative' }}>
                <Loading visible={dataLoading} />

                <Grid container item>
                    <Grid
                        container
                        item
                        className="modal-spacer"
                        style={{ marginBottom: 10, marginTop: 10 }}>

                        <Grid item xs={12}>
                            <TextBox
                                disabled={props.type === "view"}
                                label="Insurance Company"
                                required
                                name="company_name"
                                value={formik.values.company_name}
                                placeholder=" Enter Insurance Company"
                                onChange={(value) => formik.setFieldValue('company_name', value)}
                                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                helperText={formik.touched.company_name && formik.errors.company_name}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                disabled={props.type === "view"}
                                label="Plan Name"
                                required
                                name="plan_name"
                                value={formik.values.plan_name}
                                placeholder=" Enter Plan Name"
                                onChange={(value) => formik.setFieldValue('plan_name', value)}
                                error={formik.touched.plan_name && Boolean(formik.errors.plan_name)}
                                helperText={formik.touched.plan_name && formik.errors.plan_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox
                                disabled={props.type === "view"}
                                label="Address Line 1"
                                required
                                name="address1"
                                value={formik.values.address1}
                                placeholder=" Enter Address Line 1"
                                onChange={(value) => formik.setFieldValue('address1', value)}
                                error={formik.touched.address1 && Boolean(formik.errors.address1)}
                                helperText={formik.touched.address1 && formik.errors.address1}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextBox
                                disabled={props.type === "view"}
                                label="Address Line 2"
                                name="address2"
                                value={formik.values.address2}
                                placeholder=" Enter Address Line 2"
                                onChange={(value) => formik.setFieldValue('address2', value)}
                                error={formik.touched.address2 && Boolean(formik.errors.address2)}
                                helperText={formik.touched.address2 && formik.errors.address2}
                            />
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={4}>
                                <TextBox
                                    disabled={props.type === "view"}
                                    label='City'
                                    required
                                    value={formik.values.city}
                                    onChange={(e) => formik.setFieldValue("city", e)}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                    placeholder='Enter City'
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <Select
                                    disabled={props.type === "view"}
                                    label='State'
                                    required
                                    placeholder='Select state'
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                    helperText={formik.touched.state && formik.errors.state}
                                    options={stateList.map(
                                        (item) => ({
                                            key: item.state,
                                            value: item.stateLabel,
                                        }),
                                    )}
                                    value={formik.values.state}
                                    onChange={(e) => formik.setFieldValue("state", e)}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <ZipCode
                                    disabled={props.type === "view"}
                                    label='Zip Code'
                                    required
                                    format={9}
                                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                                    helperText={formik.touched.zip && formik.errors.zip}
                                    value={formik.values.zip}
                                    onChange={(e) => formik.setFieldValue("zip", e)}
                                />
                            </Grid>
                        </Grid>


                        <Grid item xs={12} style={{ marginBottom: 16 }}>
                            <TextBox
                                disabled={props.type === "view"}
                                required
                                label='Phone'
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                                value={formik.values.phone_number}
                                onChange={(e) => formik.setFieldValue("phone_number", e)}
                                placeholder='Enter phone number'
                                InputProps={{
                                    inputComponent: TextMaskCustom,
                                }}

                            />
                        </Grid>

                        <Footer
                            loading={loading}
                            disabled={props.type === "view"}
                            onCancel={props.onCancel}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
