import { Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import moment from 'moment'
import React, { useState } from 'react'
import { formatDateTime } from 'utilities/formats'

import { usePatient } from '../hooks'
import { Patient } from '../interfaces'
import { getPatientGenderAndBirthDate, getPatientName } from '../shared'

const useStyles = makeStyles(() => ({
    header_bar: {
        background: '#FBFCFC',
        border: '1px solid #ECF0F0',
        borderRadius: '12px',
        padding: '12px 24px',
        marginTop: '12px',
        marginBottom: '12px',
        flexWrap: 'nowrap',
    },
    black_color: {
        color: '#060D21 !important',
    },
    avatar_big: {
        width: 60,
        height: 60,
        background: 'gray',
        borderRadius: 80,
    },
    avatar: {
        width: 35,
        height: 35,
        background: 'gray',
        borderRadius: 80,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    text_description: {
        fontSize: 12,
        color: '#667689',
        textTransform: 'capitalize',
    },
}))

interface Props {
    patientInfo?: Patient
}

const PatientInfor = () => {
    const classes = useStyles()
    const { patientInfo } = usePatient()
    const [showMore, setShowMore] = useState(false)

    return showMore ? (
        <Grid
            container
            justifyContent="space-between"
            className={classes.header_bar}
            alignItems="center">
            <Grid
                item
                xs={3}
                style={{
                    paddingRight: 20,
                    marginRight: 20,
                    borderRight: '1px solid #E9ECF1',
                }}>
                <Grid container spacing={2} wrap={'nowrap'} alignItems="center">
                    <Grid item>
                        <div className={classes.avatar_big} />
                    </Grid>
                    <Grid item>
                        <Grid container direction="column">
                            <Typography
                                className={classes.header}
                                style={{
                                    fontSize: 16,
                                }}>
                                {patientInfo ? getPatientName(patientInfo) : 'Robert Fox'}
                            </Typography>
                            <Typography className={classes.text_description}>
                                {patientInfo
                                    ? getPatientGenderAndBirthDate(patientInfo)
                                    : 'Male, May 21, 1960'}
                            </Typography>
                            <Typography className={classes.text_description}>
                                Patient ID:{' '}
                                <span
                                    style={{
                                        color: '#5571C6',
                                    }}>
                                    {patientInfo?.custom_id || 'A0B1C01200013'}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={3}
                style={{
                    paddingRight: 20,
                    marginRight: 20,
                    borderRight: '1px solid #E9ECF1',
                }}>
                <Grid container wrap={'nowrap'} alignItems="center" direction="column">
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Created At</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.created_date
                                ? moment(patientInfo?.created_date).format('DD MMM YYYY, hh:mm a')
                                : '-'}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Last Appt</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.last_appointment
                                ? `${formatDateTime(
                                      patientInfo.last_appointment.start,
                                      'MM/DD/YYYY',
                                  )}  --  ${formatDateTime(
                                      patientInfo.last_appointment.start,
                                      'HH:mm A',
                                  )} - ${formatDateTime(
                                      patientInfo.last_appointment.end,
                                      'HH:mm A',
                                  )} `
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Next Appt</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.next_appointment
                                ? `${formatDateTime(
                                      patientInfo.next_appointment.start,
                                      'MM/DD/YYYY',
                                  )}  --  ${formatDateTime(
                                      patientInfo.next_appointment.start,
                                      'HH:mm A',
                                  )} - ${formatDateTime(
                                      patientInfo.next_appointment.end,
                                      'HH:mm A',
                                  )} `
                                : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs={3}
                style={{
                    paddingRight: 20,
                    marginRight: 20,
                    borderRight: '1px solid #E9ECF1',
                }}>
                <Grid container wrap={'nowrap'} alignItems="center" direction="column">
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Phone</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.phone_number || '603 555-0123'}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Address</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.address1 || '3131 94th St, East Elmhurst, NY'}
                        </Typography>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <Typography className={classes.text_description}>Email</Typography>
                        <Typography className={clsx(classes.text_description, classes.black_color)}>
                            {patientInfo?.email || 'robertfox@gmail.com'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={3}>
                <Grid justifyContent="flex-end" container wrap={'nowrap'} alignItems="center">
                    <Typography
                        style={{
                            marginRight: 16,
                        }}>
                        Hide
                    </Typography>
                    <AntSwitch onChange={(e) => setShowMore(e.target.checked)} checked={showMore} />
                    <Typography
                        style={{
                            marginLeft: 16,
                        }}>
                        Show
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            justifyContent="space-between"
            className={classes.header_bar}
            alignItems="center">
            <Grid item>
                <Grid container spacing={2} wrap={'nowrap'} alignItems="center">
                    <Grid item>
                        <div className={classes.avatar} />
                    </Grid>
                    <Grid item>
                        <Typography
                            className={classes.header}
                            style={{
                                fontSize: 16,
                            }}>
                            {patientInfo ? getPatientName(patientInfo) : 'Robert Fox'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={2} wrap={'nowrap'} alignItems="center">
                    <Typography
                        style={{
                            marginRight: 16,
                        }}>
                        Hide
                    </Typography>
                    <AntSwitch onChange={(e) => setShowMore(e.target.checked)} checked={showMore} />
                    <Typography
                        style={{
                            marginLeft: 16,
                        }}>
                        Show
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { PatientInfor }

export default PatientInfor
