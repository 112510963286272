import React, { useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Typography,
    TextField,
} from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { makeStyles } from '@material-ui/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'

const useStyles = makeStyles({
    subLabel: {
        fontSize: 12,
        color: '#9CA9B8',
    },
    sectionHeading: {
        marginRight: 16,
        fontSize: 14,
    },
    textFieldWrap: {
        height: 40,
        width: 300,
    },
})

const mapOptions = (data = []) => {
    return data.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const SubstanceUseConsequences = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    nonEditable
}) => {
    const classes = useStyles()
    const options = mapOptions(
        master?.substanceConsequenceMasters,
    )

    const handleCheckChange = (e) => {
        const { name, value, checked } = e.target
        const { consequences } = values
        if (checked) {
            onChange(name, [...consequences, value])
        } else {
            onChange(
                name,
                consequences.filter((id) => id !== value),
            )
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 20,
            }}>
            <Typography className={classes.subLabel}>
                {questions['consequences']}
            </Typography>
            <Typography className={classes.subLabel}>
                {questions['consequences_sub']}
            </Typography>
            <Grid
                container
                direction="column"
                wrap="nowrap">
                {options.map((option, index) => (
                    <CheckboxOption
                        key={index}
                        name={`${fieldName}.consequences`}
                        option={option}
                        onChange={handleCheckChange}
                        disabled={nonEditable}
                        checked={
                            values?.consequences?.includes(
                                option.value,
                            )
                                ? true
                                : false
                        }
                    />
                ))}
            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <Typography
                    className={classes.sectionHeading}>
                    Other:
                </Typography>
                <TextField
                    variant="outlined"
                    name={`${fieldName}.others`}
                    className={classes.textFieldWrap}
                    size="small"
                    value={values?.others || ''}
                    disabled={nonEditable}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                    }}
                />
            </Box>
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: master?.substanceConsequenceMasters,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SubstanceUseConsequences
