import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: '#ff4f4f',
    },
    tooltip: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: '#ff4f4f',
        color: '#fff',
        fontSize: 12,
        boxShadow: '0 0 3px #000000ad',
        borderRadius: '5px',
    },
}))

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap()

    return <Tooltip arrow classes={classes} {...props} />
}

export const TooltipNav = ({ children, ...props }) => {
    return <BootstrapTooltip {...props}>{children}</BootstrapTooltip>
}
