import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import React, { useEffect, useState } from 'react'
import { apiStatusCodes, getVisitType } from 'services/Calendar'
import { getListSpecialties } from 'services/Setting'
import { SelectEl } from '../../../shared/elements'
import { PATIENT_VISIT_TYPE } from '../constant'

function BookingHeader({
    visitTypeOptionsData,
    setFieldValue,
    duration = 0,
    isUpdating,
    editable,
    appointmentId,
    classes,
    values,
    addFormCardAppointment,
    errors,
    touched,
    ...props
}) {
    const [specialtyOptions, setSpecialtyOptions] = useState([])
    const [visitTypeOptions, setVisitTypeOptions] = useState([])
    const [appointmentOptions, setAppointmentOptions] = useState([])
    const [patient, setPatient] = useState()
    const [priorityOptions, setPriorityOptions] = useState([
        {
            key: 'urgent',
            value: 'Urgent',
        },
        {
            key: 'routine',
            value: 'Routine',
        },
    ])

    useEffect(() => {
        getListSpecialtiesOptions()
        getAppointmentStatus()
    }, [])

    useEffect(() => {
        getListVisitTypeOptions()
    }, [values.specialty_id])

    useEffect(() => {
        setFieldValue('duration', duration * 15)
    }, [duration])

    async function getListSpecialtiesOptions() {
        try {
            const response = await getListSpecialties({
                page: 1,
                size: 100000,
            })
            setSpecialtyOptions(
                response?.data?.data?.map((item) => ({
                    key: item.id,
                    value: item.specialty,
                })),
            )
        } catch (error) {
            console.log({ error })
        }
    }

    const getAppointmentStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'APPOINTMENT',
                limit: 100000,
            })
            setAppointmentOptions(
                res?.data?.listStatusCode?.data?.map((item) => ({
                    key: item.code,
                    value: item.name,
                })),
            )
            const statusDefault = res?.data?.listStatusCode?.data?.find((el) => el.is_default)
            if (statusDefault && !values.appointment_status) {
                setFieldValue('appointment_status', statusDefault.code)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function getListVisitTypeOptions() {
        try {
            const response = await getVisitType({
                page: 1,
                limit: 100000,
                specialty_id: values.specialty_id,
            })

            setVisitTypeOptions(
                response?.data?.visitTypeList?.data?.map((item) => ({
                    key: item.id,
                    value: item.post_code + ' - ' + item.id,
                    duration: item.duration,
                })) || [],
            )
        } catch (error) {
            console.log({ error })
        }
    }

    return (
        <Grid container spacing={2} style={{ marginTop: 16 }}>
            <Grid item>
                <RadioGroup
                    row
                    value={values.appointment_type}
                    onChange={(event) => {
                        setFieldValue('appointment_type', event.target.value)
                    }}
                    name="patient_type"
                    aria-label="patient_type">
                    <FormControlLabel
                        disabled={!editable && isUpdating}
                        value={PATIENT_VISIT_TYPE.OFFICE}
                        control={<Radio color="primary" />}
                        label="Office Visit"
                    />
                    <FormControlLabel
                        value={PATIENT_VISIT_TYPE.TELEHEALTH}
                        disabled={!editable && isUpdating}
                        control={<Radio color="primary" />}
                        label="Telehealth Visit"
                    />
                </RadioGroup>
            </Grid>

            <Grid container item direction="row" spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Appointment Status
                    </FormLabel>
                    <SelectEl
                        disabled={!editable && isUpdating}
                        name="appointment_status"
                        placeholder="Select Appointment Status"
                        value={values.appointment_status}
                        options={appointmentOptions}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => {
                            setFieldValue('appointment_status', e.target.value)
                        }}
                    />
                </Grid>

                {/* <Grid item xs={4}>
                    <FormLabel component="p" className="input-form-label">
                        Priority
                    </FormLabel>
                    <SelectEl
                        name="priority"
                        placeholder="Select Priority"
                        value={values.priority}
                        options={priorityOptions}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => {
                            setFieldValue('priority', e.target.value)
                        }}
                    />
                </Grid> */}

                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Specialty
                    </FormLabel>
                    <SelectEl
                        // disabled={!editable && appointmentId}
                        disabled={isUpdating}
                        name="specialty_id"
                        placeholder="Select Specialty"
                        value={values.specialty_id}
                        options={specialtyOptions}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => {
                            setFieldValue('specialty_id', e.target.value)
                            setFieldValue('visitType', '')
                            setFieldValue('duration', '')
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Visit Type <span className={classes.required_text}>*</span>
                    </FormLabel>
                    <SelectEl
                        // disabled={!editable && appointmentId}
                        disabled={!editable && isUpdating}
                        name="visitType"
                        placeholder=" Visit Type"
                        value={values.visitType}
                        options={visitTypeOptions}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) => {
                            setFieldValue('visitType', e.target.value)
                            setFieldValue(
                                'duration',
                                visitTypeOptions.find((item) => item.key === e.target.value)
                                    ?.duration,
                            )
                        }}
                        error={touched?.visitType && Boolean(errors?.visitType)}
                        helperText={touched?.visitType && errors?.visitType}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Duration <span className={classes.required_text}>*</span>
                    </FormLabel>
                    <TextField
                        disabled={!editable && isUpdating}
                        variant="outlined"
                        value={values.duration}
                        style={{
                            height: 'fit-content',
                        }}
                        placeholder="Text area"
                        fullWidth
                        inputProps={{ maxLength: 3 }}
                        onChange={(e) =>
                            setFieldValue('duration', e.target.value.replace(/[^0-9]+/g, ''))
                        }
                        error={touched?.duration && Boolean(errors?.duration)}
                        helperText={touched?.duration && errors?.duration}
                    />
                    {/* <SelectEl
                        name="clinic_id"
                        placeholder="Select Category"
                        value={values.duration}
                        options={durationOption}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(e) =>
                            setFieldValue(
                                'duration',
                                e.target.value,
                            )
                        }
                    /> */}
                </Grid>
                {/* <Grid item xs={3}>
                    <FormLabel
                        component="p"
                        className="input-form-label">
                        Keyword
                    </FormLabel>
                    {(values.keyword || !isUpdating) && (
                        <SearchKeywordInput
                            value={values.keyword}
                            handleInputChange={(e) => {
                                setFieldValue(
                                    'keyword',
                                    e.target.value,
                                )
                            }}
                            handleAddRow={(value) => {
                                setFieldValue(
                                    'keyword',
                                    value?.id,
                                )
                            }}
                        />
                    )}
                </Grid> */}
            </Grid>

            <Grid container item alignItems="unset" spacing={2}>
                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Patient <span className={classes.required_text}>*</span>
                    </FormLabel>
                    <SearchPatientInput
                        // disabled={addFormCardAppointment}
                        // disabled={!editable && appointmentId}
                        disabled={isUpdating}
                        value={values.patient}
                        handleInputChange={(e) => {
                            setFieldValue('patient', e.target.value)
                        }}
                        handleAddRow={(value) => {
                            setFieldValue('patient', value?.id)
                        }}
                        error={touched?.patient && Boolean(errors?.patient)}
                        helperText={touched?.patient && errors?.patient}
                        getDetailPatient={(patient) => setPatient(patient)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormLabel component="p" className="input-form-label">
                        Patient Status
                    </FormLabel>
                    <TextField
                        variant="outlined"
                        value={patient?.status_code?.name}
                        style={{
                            height: 'fit-content',
                        }}
                        fullWidth
                        disabled
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BookingHeader
