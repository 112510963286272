import { FormControlLabel, FormLabel, Grid, Typography } from '@material-ui/core'
import Divider from '@mui/material/Divider'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { MEDICAL_INITIAL_CLINICAL_NOTE } from 'constants/clinicalNote'
import React from 'react'
import { SelectSearchInputCustom } from 'shared/elements/FormItem/SelectSearchInputCustom'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import CheckboxOption from '../../reusable/CheckboxOptions'
import TextWithMacrosNew from 'components/ClinicalNotes/reusable/TextWithMacrosNew'
import { makeStyles } from '@material-ui/styles'
import CommentIcon from 'assests/icons/Comment.svg'
import DeleteCommentIcon from 'assests/icons/DeleteComment.svg'

const useStyles = makeStyles(() => ({
    commentSection:{
        marginTop: '8px',
        display: 'flex',
        gap: 10,
        alignItems: 'center',
    },
    addCommentText:{
        color: '#5571C6',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px', 
        cursor: 'pointer',
    },
    removeCommentText:{
        color: '#E81A1A',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px', 
        cursor: 'pointer',
        paddingTop: '1px',
    },
}))

const PhysicalForm = (props) => {
    const classes = useStyles()

    const [showcomment, setShowComment] = React.useState({})

    React.useEffect(() => {
        setShowComment({
            general: Boolean(props?.values?.physical_exam?.general?.comment),
            abdomen: Boolean(props?.values?.physical_exam?.abdomen?.comment),
            breasts: Boolean(props?.values?.physical_exam?.breasts?.comment),
            cardiovascular: Boolean(props?.values?.physical_exam?.cardiovascular?.comment),
            exterminates: Boolean(props?.values?.physical_exam?.exterminates?.comment),
            heent: Boolean(props?.values?.physical_exam?.heent?.comment),
            lungs: Boolean(props?.values?.physical_exam?.lungs?.comment),
            msk: Boolean(props?.values?.physical_exam?.msk?.comment),
            neck: Boolean(props?.values?.physical_exam?.neck?.comment),
            neuro: Boolean(props?.values?.physical_exam?.neuro?.comment),
            skin: Boolean(props?.values?.physical_exam?.skin?.comment),
        })
    }, [props?.values?.physical_exam])

    const onHandleChange = (key) =>{
        setShowComment(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    }

    return (
        <Grid container item xs={7}>
            <Grid container item direction="row" spacing={10} alignItems="flex-start">
                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            General
                        </FormLabel>
                        <CheckboxOption
                            name={`${props?.fieldName}.physical_exam.general.wnl`}
                            option={{
                                value: props?.values?.physical_exam?.general.wnl,
                                label: 'WNL',
                            }}
                            checked={props?.values?.physical_exam?.general.wnl}
                            onChange={(e) => {
                                props?.setCopiedText()
                                props?.onChange(
                                    `${props?.fieldName}.physical_exam.general.wnl`,
                                    e.target.checked,
                                )
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                        <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}.physical_exam.general.selections`}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.general}
                                multiple={true}
                                value={props?.values?.physical_exam?.general.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.general.selections`,
                                        value,
                                    )
                                }}
                                // limitTags={sidebarOpen ? 1 : 2}
                                limitTags={2}
                                // error={touched.practitioner_ids && Boolean(errors.practitioner_ids)}
                                // helperText={touched.practitioner_ids && errors.practitioner_ids}
                                titleModal={'General'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                        </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.general ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('general')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('general'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.general && 
                        <TextBox
                            name={`${props?.fieldName}.physical_exam.general.comment`}
                            value={props?.values?.physical_exam?.general?.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props?.setCopiedText()
                                props.onChange(
                                    `${props?.fieldName}.physical_exam.general.comment`,
                                    value,
                                )
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                    color: '#303E4E !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                        />
                    }
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            HEENT
                        </FormLabel>
                        <CheckboxOption
                            name={`${props?.fieldName}physical_exam.heent.wnl`}
                            option={{
                                value: props?.values?.physical_exam?.heent.wnl,
                                label: 'WNL',
                            }}
                            checked={props?.values?.physical_exam?.heent.wnl}
                            onChange={(e) => {
                                props?.setCopiedText()
                                props?.onChange(
                                    `${props?.fieldName}.physical_exam.heent.wnl`,
                                    e.target.checked,
                                )
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                        <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                        <SelectSearchInputCustom
                            isChecBoxFlag={true}
                            name={`${props?.fieldName}physical_exam.heent.selections`}
                            value={props?.values?.physical_exam?.heent.selections}
                            onChange={(value) => {
                                props?.setCopiedText()
                                props?.onChange(
                                    `${props?.fieldName}.physical_exam.heent.selections`,
                                    value,
                                )
                            }}
                            placeholder="Select"
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.heent}
                            multiple={true}
                            limitTags={2}
                            titleModal={'HEENT'}
                            styleModal={{
                                backgroundColor:'#FFF',
                                border:'1px solid #EAECF0'
                            }}
                        />
                        </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.heent ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('heent')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('heent'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.heent && <TextBox
                        name={`${props?.fieldName}.physical_exam.heent.comment`}
                        value={props?.values?.physical_exam?.heent?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.heent.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6}  style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            Skin
                        </FormLabel>
                        <CheckboxOption
                            name={`${props?.fieldName}physical_exam.skin.wnl`}
                            option={{
                                value: props?.values?.physical_exam?.skin.wnl,
                                label: 'WNL',
                            }}
                            checked={props?.values?.physical_exam?.skin.wnl}
                            onChange={(e) => {
                                props?.setCopiedText()
                                props?.onChange(
                                    `${props?.fieldName}.physical_exam.skin.wnl`,
                                    e.target.checked,
                                )
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '10px 0px 10px 5px',
                            background: '#f8f9fb',
                            borderRadius: 5
                        }}>
                            <FormControlLabel
                                control={
                                    <AntSwitch
                                        name={`${props?.fieldName}physical_exam.skin.skin_lesion`}
                                        checked={props?.values?.physical_exam?.skin.skin_lesion}
                                        onChange={(e) => {
                                            props?.setCopiedText()
                                            props?.onChange(
                                                `${props?.fieldName}.physical_exam.skin.skin_lesion`,
                                                e.target.checked,
                                            )
                                        }}
                                    />
                                }
                                label="Skin Lesion"
                                style={{ margin: '0px', gap: '10px' }}
                                sx={{
                                    '& .MuiTypography-body1': {
                                        color: '#303E4E !important',
                                    },
                                }}
                            />
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.skin ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('skin')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('skin'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.skin && <TextBox
                        name={`${props?.fieldName}.physical_exam.skin.comment`}
                        value={props?.values?.physical_exam?.skin?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.skin.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />
}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            Neck
                        </FormLabel>
                        <CheckboxOption
                                name={`${props?.fieldName}physical_exam.neck.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.neck.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.neck.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.neck.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.neck.selections`}
                                value={props?.values?.physical_exam?.neck.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.neck.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.neck}
                                multiple={true}
                                limitTags={2}
                                titleModal={'Neck'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.neck ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('neck')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('neck'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.neck && <TextBox
                        name={`${props?.fieldName}.physical_exam.neck.comment`}
                        value={props?.values?.physical_exam?.neck?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.neck.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            Cardiovascular
                        </FormLabel>
                        <CheckboxOption
                                name={`${props?.fieldName}physical_exam.cardiovascular.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.cardiovascular.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.cardiovascular.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.cardiovascular.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.cardiovascular.selections`}
                                value={props?.values?.physical_exam?.cardiovascular.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.cardiovascular.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={
                                    MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.cardiovascular
                                }
                                multiple={true}
                                limitTags={2}
                                titleModal={'Cardiovascular'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.cardiovascular ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('cardiovascular')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('cardiovascular'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.cardiovascular && <TextBox
                        name={`${props?.fieldName}.physical_exam.cardiovascular.comment`}
                        value={props?.values?.physical_exam?.cardiovascular?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.cardiovascular.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                    <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormLabel
                            component="p"
                            style={{ color: '#303E4E', margin: 0 }}>
                            Lungs
                        </FormLabel>
                        <CheckboxOption
                                name={`${props?.fieldName}physical_exam.lungs.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.lungs.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.lungs.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.lungs.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                        <SelectSearchInputCustom
                            isChecBoxFlag={true}
                            name={`${props?.fieldName}physical_exam.lungs.selections`}
                            value={props?.values?.physical_exam?.lungs.selections}
                            onChange={(value) => {
                                props?.setCopiedText()
                                props?.onChange(
                                    `${props?.fieldName}.physical_exam.lungs.selections`,
                                    value,
                                )
                            }}
                            placeholder="Select"
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.lungs}
                            multiple={true}
                            limitTags={1}
                            titleModal={'Lungs'}
                            styleModal={{
                                backgroundColor:'#FFF',
                                border:'1px solid #EAECF0'
                            }}
                        />
                        </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.lungs ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('lungs')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('lungs'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.lungs && <TextBox
                        name={`${props?.fieldName}.physical_exam.lungs.comment`}
                        value={props?.values?.physical_exam?.lungs?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.lungs.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel
                        component="p"
                        style={{ color: '#303E4E', margin: 0 }}>
                        Abdomen
                    </FormLabel>

                    <CheckboxOption
                                name={`${props?.fieldName}physical_exam.abdomen.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.abdomen.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.abdomen.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.abdomen.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                            </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                        <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.abdomen.selections`}
                                value={props?.values?.physical_exam?.abdomen.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.abdomen.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.abdomen}
                                multiple={true}
                                limitTags={2}
                                titleModal={'Abdomen'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.abdomen ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('abdomen')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('abdomen'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.abdomen && <TextBox
                        name={`${props?.fieldName}.physical_exam.abdomen.comment`}
                        value={props?.values?.physical_exam?.abdomen?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.abdomen.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel
                        component="p"
                        style={{ color: '#303E4E', margin: 0 }}>
                        MSK
                    </FormLabel>

                    <CheckboxOption
                                name={`${props?.fieldName}physical_exam.msk.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.msk.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.msk.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.msk.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.msk.selections`}
                                value={props?.values?.physical_exam?.msk.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.msk.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.msk}
                                multiple={true}
                                limitTags={2}
                                titleModal={'MSK'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.msk ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('msk')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('msk'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.msk && <TextBox
                        name={`${props?.fieldName}.physical_exam.msk.comment`}
                        value={props?.values?.physical_exam?.msk?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.msk.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel
                        component="p"
                        style={{ color: '#303E4E', margin: 0 }}>
                        Breasts
                    </FormLabel>
                    <CheckboxOption
                                name={`${props?.fieldName}physical_exam.breasts.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.breasts.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.breasts.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.breasts.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.breasts.selections`}
                                value={props?.values?.physical_exam?.breasts.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.breasts.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.breasts}
                                multiple={true}
                                limitTags={2}
                                titleModal={'Breasts'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.breasts ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('breasts')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('breasts'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.breasts && <TextBox
                        name={`${props?.fieldName}.physical_exam.breasts.comment`}
                        value={props?.values?.physical_exam?.breasts?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.breasts.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel
                        component="p"
                        style={{ color: '#303E4E', margin: 0 }}>
                        Neuro
                    </FormLabel>
                    <CheckboxOption
                                name={`${props?.fieldName}physical_exam.neuro.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.neuro.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.neuro.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.neuro.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.neuro.selections`}
                                value={props?.values?.physical_exam?.neuro.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.neuro.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.neuro}
                                multiple={true}
                                limitTags={1}
                                titleModal={'Neuro'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.neuro ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('neuro')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('neuro'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.neuro && <TextBox
                        name={`${props?.fieldName}.physical_exam.neuro.comment`}
                        value={props?.values?.physical_exam?.neuro?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.neuro.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                <Grid item xs={6} style={{ paddingTop: 0, paddingBottom: 26 }}>
                <div style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel
                        component="p"
                        style={{ color: '#303E4E', margin: 0 }}>
                        Exterminates
                    </FormLabel>
                    <CheckboxOption
                                name={`${props?.fieldName}physical_exam.exterminates.wnl`}
                                option={{
                                    value: props?.values?.physical_exam?.exterminates.wnl,
                                    label: 'WNL',
                                }}
                                checked={props?.values?.physical_exam?.exterminates.wnl}
                                onChange={(e) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.exterminates.wnl`,
                                        e.target.checked,
                                    )
                                }}
                            />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            gap: '10px',
                        }}>
                            <div style={{ width: '100%' }} className={props?.classes.SelectInputWapper}>
                            <SelectSearchInputCustom
                                isChecBoxFlag={true}
                                name={`${props?.fieldName}physical_exam.exterminates.selections`}
                                value={props?.values?.physical_exam?.exterminates.selections}
                                onChange={(value) => {
                                    props?.setCopiedText()
                                    props?.onChange(
                                        `${props?.fieldName}.physical_exam.exterminates.selections`,
                                        value,
                                    )
                                }}
                                placeholder="Select"
                                options={
                                    MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam.exterminates
                                }
                                multiple={true}
                                limitTags={2}
                                titleModal={'Exterminates'}
                                styleModal={{
                                    backgroundColor:'#FFF',
                                    border:'1px solid #EAECF0'
                                }}
                            />
                            </div>
                    </div>

                    <div className={classes.commentSection} >
                        {!showcomment.exterminates ?
                        <>
                            <img src={CommentIcon} alt="" />
                            <span className={classes.addCommentText} onClick={()=>{onHandleChange('exterminates')}}>Add comment</span>
                        </>
                        :
                        <>
                            <img src={DeleteCommentIcon} alt="" />
                            <span className={classes.removeCommentText} onClick={()=>{onHandleChange('exterminates'),props?.onChange('social_history.marital_status.comment', '')}}>Remove comment</span>
                        </>
                        }
                    </div>

                    {showcomment?.exterminates && <TextBox
                        name={`${props?.fieldName}.physical_exam.exterminates.comment`}
                        value={props?.values?.physical_exam?.exterminates?.comment}
                        placeholder="Comment"
                        onChange={(value) => {
                            props?.setCopiedText()
                            props.onChange(
                                `${props?.fieldName}.physical_exam.exterminates.comment`,
                                value,
                            )
                        }}
                        style={{ marginTop: 10 }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '50px !important',
                                color: '#303E4E !important',
                            },
                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                fontSize: 14,
                                color: '#989CA8 !important',
                                fontWeight: 400,
                                opacity: 1,
                            },
                        }}
                    />}
                </Grid>

                {/* <Grid item xs={12}>
                    <TextWithMacrosNew
                        name={`${props?.fieldName}.physical_exam.narrative`}
                        onChange={props?.onChange}
                        // // macros={macros}
                        isDisabled={props?.nonEditable}
                        rows={12}
                        withoutMacro={false}
                        values={props?.values?.physical_exam?.narrative}
                        // placeholder={placeholder}
                    />
                </Grid> */}
            </Grid>
        </Grid>
    )
}

export default PhysicalForm
