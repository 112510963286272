import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import CircleIcon from '@mui/icons-material/Circle'

import { Card } from '../view/Card'
import { usePatientDashboardStyles } from '../style'

interface Props {
    onClick?: () => void
}

export const ClinicalReminder = (props: Props) => {
    const classes = usePatientDashboardStyles()

    return (
        <Card onClick={props.onClick} header="Clinical Reminders">
            Coming Soon
            {/* <Grid
        container
        direction='column'
        alignItems='flex-start'
        style={{
          gap: 8,
          height: '100%',
          overflow: 'auto',
          paddingRight: 15
        }}>

          <Grid
            container
            justifyContent="space-between"
            wrap="nowrap"
            alignItems="center"
            style={{
              borderBottom:
                '1px solid #EFF2F5',
              paddingBottom: 8,
            }}>
            <Grid
              item
              container
              alignItems="center"
              wrap="nowrap"
              style={{
                width: 'fit-content',
              }}>
              <CircleIcon
                style={{
                  fontSize: 6,
                  color: '#D3D8E0',
                  marginRight: 6,
                }}
              />
              <Typography>
                <span
                  className={
                    classes.title_clinical_reminder
                  }>
                  Assessment:
                </span>{' '}
                Colon
                Cancer
                Screening
              </Typography>
            </Grid>
            <Typography
              className={
                classes.tag_type_red
              }>
              Past Due
            </Typography>
          </Grid>

      </Grid> */}
        </Card>
    )
}
