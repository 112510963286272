import React from "react";
import {
  Grid,
  TextField,
  FormLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Snackbar,
} from "@material-ui/core";

import { useStyles } from "./styles";
import ClearIcon from "@material-ui/icons/Clear";
import { Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";
import addParticipantInfo, { Participant } from "../../apis/addParticipant";
import useProviderContext from "../../hooks/useProviderContext/useProviderContext";
import LoadingButton from '@mui/lab/LoadingButton';
import SpinnerIcon from "../../../../assests/icons/SpinnerIcon";

const usNumberRegex = new RegExp(/^(\()?[2-9]{1}\d{2}(\))?(\s)?\d{3}(\s)\d{4}$/)

const formatPhone = (phone: any) => {
  if (phone && phone !== "") {
      const char: any = {
          0: "(",
          3: ") ",
          6: " "
      };
      let number = "";
      phone = phone.replace(/\D/g, "");
      for (let i = 0; i < phone.length; i++) {
          number += (char[i] || "") + phone[i];
      }
      return number;
  }
  return null;
};

interface IParticipantData {
  firstName: string;
  lastName: string;
  cellphone: string;
  email: string;
  relationship: string;
}
interface AddParticipantFormProps {
  open: boolean;
  onClose: () => void;
  handleOpenAddParticipant: () => void;

}

const AddParticipantForm: React.FunctionComponent<AddParticipantFormProps> = ({
  open,
  handleOpenAddParticipant,
  onClose,
}) => {
  const classes = useStyles();
  const [isSnackBarOpen, setSnackBarOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [isSubmitting, setAddButton] = React.useState(false);
  const { upcoming, appointmentInfo } = useProviderContext();
  const addParticipant = async (
    data: IParticipantData,
    resetForm: Function
  ) => {
    try {
      // TODO: Redux or API call integration
      // On Success, show notification
      setAddButton(true);
      const participantInfo: Participant = {
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.cellphone,
        email: data.email,
        relation_ship: data.relationship,
        title: 'invited_provider',
        // encounter_id: upcoming && upcoming.Current.id ? Number(upcoming.Current.id) : Object.keys(appointmentInfo).length !== 0 ? appointmentInfo.id : null,
        encounter_id: appointmentInfo.appointment_id.toString(),
      };
      let isError = false;
      let errMsg = "";
      let res = await addParticipantInfo(participantInfo).catch(e => {
        isError = e && e.response && e.response.data && (e.response.data.isMaxParticipantsReached || e.response.data.isMobileAlreadyExist) ? true : false;
        errMsg = e && e.response && e.response.data && e.response.data.message ? e.response.data.message : "";
      });
      if (isError) {
        setErrMsg(errMsg);
        setSnackBarOpen(true);
        setAddButton(false);
      } else {
        setErrMsg("Link has been sent to the participant successfully.");
        setSnackBarOpen(true);
        setAddButton(false);
        resetForm()
        onClose();
      }
    } catch (error) {
      console.log(error);
      setAddButton(false);
      setErrMsg("Something went wrong please try again!!");
      setSnackBarOpen(true);
    }
  };

  const renderLabel = (label: string, isMandatory: boolean): React.ReactElement => (
    <FormLabel className={classes.label} component="legend">
      {label}&nbsp;{isMandatory && <span className={classes.redText}>*</span>}
    </FormLabel>
  );

  return (
    <Grid container style={{padding: 24}} direction="column">
    <Grid container justifyContent="space-between" alignItems="center" style={{marginBottom: 24}}>
        <Typography className={classes.header}>Add Participants</Typography>
    <ClearIcon onClick={onClose} className={classes.closeIcon} />

    </Grid>
       
        <Grid container>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              cellphone: "",
              email: "",
              relationship: "",
            }}
            onSubmit={(values: IParticipantData, actions) => {
              addParticipant(values, actions.resetForm);
            }}
          validationSchema={Yup.object().shape({
            email: Yup.string().required("Please enter email").email('Please enter valid email'),
            firstName: Yup.string().required("Please enter first name"),
              // .test('alphabets', 'Please enter valid first name', (value = "") => {
              //   return /^[A-Za-z]+$/.test(value);
              // }),
            lastName: Yup.string().required("Please enter last name"),
              // .test('alphabets', 'Please enter valid last name', (value = "") => {
              //   return /^[A-Za-z]+$/.test(value);
              // }),
            cellphone: Yup.string().required("Please enter cellphone"),
              // .test('alphabets', 'Please enter valid cellphone', (value = "") => {
              //   return usNumberRegex.test(value);
              // }),
            relationship: Yup.string().required("Please enter your relationship"),
              // .test('alphabets', 'Please enter valid relationship', (value = "") => {
              //   return /^[A-Za-z]+$/.test(value);
              // }),
          })}
          >
            {(props: FormikProps<IParticipantData>) => {
              const {
                values,
                touched,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
            } = props;
              return (
                <form className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <Grid container justifyContent="space-around" direction="row">
                    {renderLabel("First name", true)}
                    <TextField
                      name="firstName"
                      id="firstName"
                      variant="outlined"
                      className={classes.textInput}
                      placeholder="Enter first name"
                      size="small"
                      value={values.firstName}
                      error={
                        errors.firstName && touched.firstName ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // inputProps={{ maxLength: 20 }}
                      helperText={
                          errors.firstName && touched.firstName
                            ? errors.firstName
                            : ""
                        }
                    />

                      {renderLabel("Last name", true)}
                      <TextField
                        name="lastName"
                        id="lastName"
                        variant="outlined"
                        className={classes.textInput}
                        placeholder="Enter last name"
                        size="small"
                        value={values.lastName}
                        error={errors.lastName && touched.lastName ? true : false}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // inputProps={{ maxLength: 20 }}
                        helperText={
                          errors.lastName && touched.lastName
                            ? errors.lastName
                            : ""
                        }
                      />

                      {renderLabel("Email", false)}
                      <TextField
                        name="email"
                        id="email"
                        variant="outlined"
                        className={classes.textInput}
                        placeholder="Enter email"
                        size="small"
                        value={values.email}
                        type="email"
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email
                            ? errors.email
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                    {renderLabel("Cellphone", true)}
                    <TextField
                      name="cellphone"
                      id="cellphone"
                      variant="outlined"
                      className={classes.textInput}
                      placeholder="Enter cellphone"
                      size="small"
                      value={values.cellphone}
                      error={
                        errors.cellphone && touched.cellphone ? true : false
                      }
                      onChange={e => {
                        // e.target.value = formatPhone(e.target.value) || ''
                        e.target.value = e.target.value.replace(/\D/g, "") || ''
                        handleChange(e)}
                      }
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 14 }}
                      helperText={
                          errors.cellphone && touched.cellphone
                            ? errors.cellphone
                            : ""
                        }
                    />

                      {renderLabel("Relationship", true)}
                      <TextField
                        name="relationship"
                        id="relationship"
                        variant="outlined"
                        className={classes.textInput}
                        placeholder="Enter relationship"
                        size="small"
                        value={values.relationship}
                        type="relationship"
                        error={
                          errors.relationship && touched.relationship
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        inputProps={{ maxLength: 20 }}
                      helperText={
                        errors.relationship && touched.relationship
                            ? errors.relationship
                            : ""
                        }
                      />
                       <LoadingButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            variant="contained"
                            loadingIndicator={<SpinnerIcon />}
                            className={classes.button}
                        >
                        Add
                        </LoadingButton>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </Grid>

      <Snackbar
        open={isSnackBarOpen}
        onClose={() => setSnackBarOpen(false)}
        autoHideDuration={1000}
        message={errMsg}
      />
    </Grid>
  );
};

export default AddParticipantForm;
