import { useState, useEffect } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { getMedicationSSOLink } from 'services/Patient/patient'
import LoadingPage from 'utilities/loading-page'
import { InaccessibleConfirm } from 'containers/ERX/InaccessibleConfirm'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
    },

    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        height: 36,
        borderRadius: '8px !important',
        fontWeight: 'normal',
        background: 'white !important',
    },
    btn_close: {
        fontSize: '15px !important',
        width: '230px',
        height: 36,
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
    header: {
        fontSize: '24px',
        color: '#303E4E',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 18,
    },
}))

export default function ViewDetailDialog({ open, link, handleClose, btnFromLabOrder }) {
    const classes = useStyles()
    // const [loading, setLoading] = useState(false)
    // const [ssoLink, setSsoLink] = useState('')
    // const [ssoLinkError, setSsoLinkError] = useState(null)

    // const fetchErxSsoLink = async () => {
    //     setLoading(true)
    //     await getMedicationSSOLink(patientId)
    //         .then((res) => {
    //             res?.data?.ssoLink && setSsoLink(res?.data?.ssoLink)
    //         })
    //         .catch((err) => {
    //             setSsoLinkError(err.data?.message)
    //         })
    //     setLoading(false)
    // }

    // useEffect(() => {
    //     fetchErxSsoLink()
    // }, [patientId])

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1100px',
                },
            }}
            fullWidth={true}
            onClose={handleClose}
            className={classes.root}>
            <DialogContent>
                {/* {description && <Typography className={classes.header}>Prescription</Typography>} */}
                {/* {loading ? (
                    <LoadingPage />
                ) : ssoLink ? ( */}
                <iframe
                    src={link}
                    title="description"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}></iframe>
                {/* ) : (
                    <Typography>User can only access this site with a Provider Account!</Typography>
                )} */}
            </DialogContent>

            {btnFromLabOrder ? (
                <DialogActions>
                    <LoadingButton
                        // loading={loading}
                        // loadingPosition="start"
                        startIcon={<></>}
                        onClick={handleClose}
                        // disabled={loading}
                        // autoFocus
                        variant="contained"
                        className={classes.btn_close}>
                        OK
                    </LoadingButton>
                </DialogActions>
            ) : (
                <DialogActions>
                    <LoadingButton
                        // loading={loading}
                        // loadingPosition="start"
                        startIcon={<></>}
                        onClick={handleClose}
                        // disabled={loading}
                        // autoFocus
                        variant="contained"
                        className={classes.btn_delete}>
                        OK
                    </LoadingButton>
                </DialogActions>
            )}

            {/* <InaccessibleConfirm
                open={Boolean(ssoLinkError)}
                onOk={() => {
                    handleClose()
                    setSsoLinkError(null)
                }}
            /> */}
        </Dialog>
    )
}
