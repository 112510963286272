import React from 'react'
import {
    Modal,
    makeStyles,
    Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import errorIcon from '../../assests/images/error_icon.svg'

const useStyles = makeStyles({
    successModalWrapper: {
        width: '508px',
        borderRadius: '10px',
        height: '270px',
        backgroundColor: '#ffff',
        boxShadow: '0 4px 8px 0 rgb(0 0 0 / 10%)',
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        outline: '0',
        justifyContent: 'center',
    },
    closeIcon: {
        fontSize: '20px',
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: '$primaryGray',
    },
    closeWrapper: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '50px',
        cursor: 'pointer',
    },
    contentWrapper: {
        width: '460px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    icon: {
        marginTop: '24px',
        marginBottom: '16px',
    },
    title: {
        fontSize: '22px',
        height: '31px',
        textAlign: 'center',
        fontWeight: '500',
        color: '#303E4E',
    },
    messageWrapper: {
        fontSize: '14px',
        fontWeight: '400',
        marginTop: '24px',
        marginBottom: '24px',
        color: '#667689',
    },
    btnWrapper: {
        width: '460px',
        margin: '0 auto',
    },
    btnClose: {
        width: '100%',
        height: '40px',
        fontSize: '15px',
        fontWeight: '400',
        borderRadius: '8px',
        background: '#5571C6',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '24px',
    },
})
const ErrorModal = ({
    isOpen,
    setIsOpen,
    title,
    errorMessage,
}) => {
    const classes = useStyles()
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                setIsOpen(false)
            }}>
            <div className={classes.successModalWrapper}>
                <div className={classes.contentWrapper}>
                    <img
                        className={classes.icon}
                        src={errorIcon}
                    />
                    <span className={classes.title}>
                        {title || 'Create Addendum Failed'}
                    </span>
                    <span
                        className={classes.messageWrapper}>
                        {errorMessage?.message ||
                            'You have created addendum failed'}
                    </span>
                </div>
                <div className={classes.btnWrapper}>
                    <Button
                        onClick={() => setIsOpen(false)}
                        className={classes.btnClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorModal
