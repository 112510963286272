import { Grid, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { ReactComponent as RecallIcon } from '../../assests/icons/recall.svg'
import { ReactComponent as WaitListIcon } from '../../assests/icons/waitlist.svg'
import { ReactComponent as PatientTranslatorIcon } from 'assests/icons/patient_translator.svg'

const useStyles = makeStyles(() => ({
    header_bar: {
        background: '#FBFCFC',
        border: '1px solid #ECF0F0',
        borderRadius: '12px',
        padding: '12px 24px',
        flexWrap: 'nowrap',
    },

    inputformlabel: {
        fontWeight: 400,
        fontSize: "14px",
        color: "#303e4e",
        display: "flex",
    },

    breakWord: {
        display: "flex",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        paddingLeft: "5px"
    }
}))


const PatientInformation = ({ patient }) => {
    const classes = useStyles()
    return (
        <Grid
            container
            className={classes.header_bar}
            alignItems="center">
            <Grid
                item
                xs={8}
                style={{
                    paddingRight: 10,
                    marginRight: 20,
                    borderRight: '1px solid #E9ECF1',
                }}>
                <Grid container spacing={2} wrap={'nowrap'} alignItems="center">
                    <Grid item xs>
                        {patient?.preferred_name && <Grid item xs>
                            <span className={classes.inputformlabel}>
                                <span style={{ fontSize: "12px", fontWeight: 400 }}>Preferred Name: </span> <div className={classes.breakWord} style={{ fontWeight: 600, fontSize: "13.5px" }}>{patient?.preferred_name}</div>
                            </span>
                        </Grid>}
                        <Grid container direction="column" style={{ marginTop: "12px" }}>
                            <span className={classes.inputformlabel}>
                                <CallIcon style={{ color: "gray", fontSize: "small", display: "inline", marginTop: "2px" }} />  <div className={classes.breakWord}>{patient?.phone_number}</div>
                            </span>
                        </Grid>
                        <Grid item xs style={{ marginTop: "15px" }}>
                            <span className={classes.inputformlabel}>
                                <EmailIcon style={{ color: "gray", fontSize: "small", display: "inline", marginTop: "2px" }} />  <div className={classes.breakWord}>{patient?.email}</div>
                            </span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid item xs>
                    <span className={classes.inputformlabel}>
                        Co pay: {patient?.insurance ? patient?.insurance?.insurance?.copay_amount : "$0"}
                    </span>
                </Grid>
                <div style={{ marginTop: "5px", display: "flex", paddingBottom: "10px", alignItems: 'center' }}>
                    <span style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        {patient?.is_require_translator && <PatientTranslatorIcon />}
                    </span>
                    <span style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        {patient.recall_present && <RecallIcon style={{ height: 25, width: 25 }}/>}
                    </span>
                    <span style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginLeft: 5 }}>
                        {patient.waitlist_present && <WaitListIcon  style={{ height: 25, width: 25 }}/>}
                    </span>
                </div>
            </Grid>
        </Grid>
    )
}

export default PatientInformation
