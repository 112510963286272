import {
    Box,
    styled,
    Tab,
    Grid,
    Tabs,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'
import React, { useState, useRef, useCallback } from 'react'
import { useResizeDetector } from 'react-resize-detector'

const useStyles = makeStyles({
    btn__macro: {
        marginRight: 8,
        color: '#132044',
    },
})

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
    },
})

const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 14,
    backgroundColor: 'none',
    minWidth: 0,
    fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    maxWidth: 'unset',
    '&:hover': {
        color: '#5571C6',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#5571C6',
        fontWeight: 500,
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

const CustomTabs = ({
    contents,
    onChangeTab,
    selectedTab,
}) => {
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState('')
    const [itemsMore, setItemsMore] = useState([])
    const tabElList = Array.apply(
        null,
        Array(contents.length),
    ).map(() => useRef(null))
    const tabsEl = useRef(null)
    const onResize = useCallback(() => {
        const tabsElRect =
            tabsEl.current?.getBoundingClientRect()
        const tabElListRect = tabElList.map((item) => ({
            left: item.current?.getBoundingClientRect().x,
            width: item.current?.getBoundingClientRect()
                .width,
        }))
        const btnInOut = tabElListRect.map((item) => {
            if (
                item.left >= tabsElRect.left &&
                item.left + item.width <=
                    tabsElRect.left + tabsElRect.width + 10
            ) {
                return 'in'
            }
            return 'out'
        })

        setItemsMore(
            contents.filter((item, index) => {
                if (btnInOut[index] === 'out') return item
            }),
        )
    }, [tabElList, tabsEl])

    const { ref } = useResizeDetector({
        onResize,
        refreshRate: 1000,
    })

    const handleChangeTab = (_, val) => {
        setActiveTab(val)
        onChangeTab(val)
    }

    React.useEffect(() => {
        setActiveTab(selectedTab)
    }, [selectedTab])

    React.useEffect(() => {
        onResize()
    }, [activeTab])

    return (
        <Grid container alignItems="center" wrap="nowrap">
            <Box
                ref={ref}
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    marginBottom: 16,
                    width: itemsMore?.length
                        ? 'calc(100% - 80px)'
                        : '100%',
                }}>
                <AntTabs
                    ref={tabsEl}
                    scrollButtons="off"
                    value={activeTab === '' ? 0 : activeTab}
                    variant="scrollable"
                    onChange={handleChangeTab}>
                    {contents.map((item, index) => (
                        <AntTab
                            ref={tabElList[index]}
                            key={index} // item.key || item.id}
                            id={item.key}
                            label={
                                item.label ||
                                item.header_name
                            }
                            value={index}
                        />
                    ))}
                </AntTabs>
            </Box>

            <Box
                sx={{
                    '& .MuiSelect-selectMenu': {
                        borderBottom: '1px solid #e8e8e8',
                        padding: '7px 30px 7px 16px',
                        borderRadius: '0px',
                        height: '34px',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiInput-root': {
                        marginBottom: 16,
                    },
                    '& .MuiMenuItem-root': {
                        whiteSpace:
                            'break-spaces !important',
                        background: 'red',
                    },
                }}>
                {itemsMore?.length > 0 && (
                    <Select
                        className={classes.selectMore}
                        disableUnderline
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            PaperProps: {
                                style: {
                                    maxHeight: 505,
                                    maxWidth: 300,
                                    marginTop: 8,
                                },
                            },
                        }}
                        IconComponent={ExpandMore}
                        name="macro"
                        onChange={(e) => {
                            handleChangeTab(
                                null,
                                contents.findIndex(
                                    (item) =>
                                        item._id ===
                                        itemsMore[
                                            e.target.value
                                        ]._id,
                                ),
                            )
                        }}
                        value={activeTab}
                        displayEmpty
                        renderValue={() => (
                            <span
                                className={
                                    classes.btn__macro
                                }>
                                More
                            </span>
                        )}>
                        {itemsMore.map((m, index) => (
                            <MenuItem
                                className={
                                    classes.menu__item
                                }
                                key={index} // {m.id || m.key}
                                value={index}>
                                <Typography
                                    className={
                                        classes.menuItem
                                    }>
                                    {m.header_name ||
                                        m.label}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                )}
            </Box>
        </Grid>
    )
}

export default CustomTabs
