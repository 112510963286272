export const CONSULTATION_LOADING =
    '[CONSULTATION_TRACKING] CONSULTATION_LOADING';
export const CONSULTATION_SUCCESS =
    '[CONSULTATION_TRACKING] CONSULTATION_SUCCESS';
export const CONSULTATION_FAILURE =
    '[CONSULTATION_TRACKING] CONSULTATION_FAILURE';
export const SEARCH_CONSULTATION =
    '[CONSULTATION_TRACKING] CONSULTATION_SEARCH';
export const PAGE_CHANGE =
    '[CONSULTATION_TRACKING] CONSULTATION_PAGE_CHANGE';
export const CONSULTATION_FILTER_SORT =
           '[CONSULTATION_TRACKING] CONSULTATION_FILTER_SORT';