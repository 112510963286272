import { v4 as uuidv4 } from 'uuid';
export const INITIAL_VALUES = {
    id: uuidv4(),
    related_person_email: '',
    related_person_name: '',
    related_person_phone: '',
    is_emergency_contact: '0',
    relation: ''

}

export const RELATIONSHIP_TYPE = [
    {
        key: 'Father',
        value: "Father",
    },
    {
        key: 'Mother',
        value: "Mother",
    },
    {
        key: 'Friend',
        value: "Friend",
    },
    {
        key: "Brother",
        value: "Brother",
    }
]