import React, { useState } from 'react'
import {
    Typography,
    Box,
    Grid,
    TextField,
    Divider,
} from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CustomTabs from '../../shared/Elements/CustomTabs'

const useStyles = makeStyles({
    container: {
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    sectionGridContainer: {
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 40,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})

const mapSections = (examMaster = [], property) => {
    return examMaster.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

const CounsellingandCoordinationofCare = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    initialValue,
    nonEditable
}) => {
    const classes = useStyles()
    const sections = mapSections(
        master?.counsellingMasters,
        'section_text',
    )

    const sectionsTabs = Object.keys(sections).map(
        (item, index) => ({
            key: item === 'undefined' ? 'Default' : item,
            label: item === 'undefined' ? 'Default' : item,
            _id: index,
        }),
    )

    const [selectedTab, setSelectedTab] = useState(0)
    const handleInputChange = (e, se) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name]
            ? [...values[name]]
            : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [
                      ...updatedValue,
                      {
                          id: value,
                          desc: '',
                          label: se.name,
                      },
                  ]
                : updatedValue.filter(
                      (uv) => uv.id !== value,
                  )
        }

        if (type === 'text') {
            const isFound = updatedValue.find(
                (item) => item.id === se._id,
            )

            if (isFound) {
                updatedValue = updatedValue.map((uv) =>
                    uv.id === se._id
                        ? { ...uv, desc: value }
                        : uv,
                )
            } else {
                updatedValue = [
                    ...updatedValue,
                    {
                        id: se._id,
                        desc: value,
                        label: se.name,
                    },
                ]
            }
            updatedValue = updatedValue.filter(
                (item) =>
                    !(
                        item.label === 'Other' &&
                        item.desc === ''
                    ),
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }

    console.log('values', values)
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
                width: '100%',
            }}>
            <Grid item xs={12}>
                <CustomTabs
                    contents={sectionsTabs}
                    selectedTab={selectedTab}
                    onChangeTab={(e) => {
                        setSelectedTab(e)
                    }}
                />
            </Grid>
            {Object.keys(sections)
                .filter((_, index) => index === selectedTab)
                .map((key, index) => {
                    const sectionElements = sections[key]
                    return (
                        <Box key={key}>
                            <Grid
                                container
                                className={
                                    classes.sectionGridContainer
                                }>
                                {sectionElements.map(
                                    (se, index) => {
                                        const isSelected =
                                            values[
                                                se
                                                    .section_name
                                            ]?.some(
                                                (item) =>
                                                    item.id ===
                                                    se._id,
                                            )

                                        const getValue = (
                                            id,
                                        ) => {
                                            return values[
                                                se
                                                    .section_name
                                            ]?.find(
                                                (item) =>
                                                    item.id ===
                                                    id,
                                            )?.desc
                                        }

                                        return (
                                            <>
                                                <Box
                                                    key={
                                                        index
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        display:
                                                            'flex',
                                                        alignItems:
                                                            'center',
                                                        // margin: '3px 0',
                                                    }}>
                                                    {!se.is_free_input ? (
                                                        <CheckboxOption
                                                            disabled={nonEditable}
                                                            option={{
                                                                value: se._id,
                                                                label: `${
                                                                    se.name
                                                                }${
                                                                    se.is_free_text
                                                                        ? ':'
                                                                        : ''
                                                                }`,
                                                            }}
                                                            name={
                                                                se.section_name
                                                            }
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                handleInputChange(
                                                                    e,
                                                                    se,
                                                                )
                                                            }
                                                            checked={
                                                                isSelected
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    ) : (
                                                        se.name && (
                                                            <Typography
                                                                style={{
                                                                    color: '#111111',
                                                                    marginRight: 16,
                                                                }}>
                                                                {
                                                                    se.name
                                                                }
                                                            </Typography>
                                                        )
                                                    )}
                                                    {se.is_free_text && (
                                                        <TextField
                                                            disabled={
                                                                !isSelected &&
                                                                !se.is_free_input ||
                                                                nonEditable
                                                            }
                                                            variant="outlined"
                                                            className={
                                                                classes.textFieldWrap
                                                            }
                                                            name={
                                                                se.section_name
                                                            }
                                                            size="small"
                                                            value={getValue(
                                                                se._id,
                                                            )}
                                                            onChange={(
                                                                e,
                                                            ) =>
                                                                handleInputChange(
                                                                    e,
                                                                    se,
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                                {se.section_name ===
                                                    'patient_self_mgmt' &&
                                                    [
                                                        3,
                                                        14,
                                                    ].includes(
                                                        se.seq,
                                                    ) && (
                                                        <Box
                                                            sx={{
                                                                marginBottom: 35,
                                                            }}></Box>
                                                    )}
                                            </>
                                        )
                                    },
                                )}
                            </Grid>
                        </Box>
                    )
                })}
            <Grid item>
                <NarrativeAndMacro
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    macros={macros}
                    meta={{
                        values,
                        master: master?.counsellingMasters,
                    }}
                    onChange={onChange}
                    values={values}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box>
    )
}

export default CounsellingandCoordinationofCare
