import React from 'react'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'

export function CustomDateHeader(props) {
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="#">{props.label}</a>
            </Grid>
        </Grid>
    )
}
