import { postService } from '../../utilities/request/restClient';

export const getWorkList = (params) =>
    postService(`/admin-queue/list`, params);

export const getFilteredWorkList = (params) =>
    postService(`admin-queue/filter-list`, params);

export const markWorkList = (params) =>
    postService(`admin-queue/mark-status`, params);
