import React, {
  useState,
  useEffect,
  useCallback,
} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from 'lodash'
import { Box, FormLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material'

import { ReactComponent as SearchIcon } from 'assests/images/icon_search.svg'
import { getVaccines } from 'services/Patient/patient'

import '../../styles.scss'

interface ICD10 {
  code: string | undefined | null
  display: string | undefined | null
}

interface ResponseDTO {
  id: string
  rcopia_id: string
  ndc_id: string
  brand_name: string
  name: string
  strength: string
  drug_group: {
    group_id: string
    group_name: string
  }[]
}

interface Props {
  label?: string
  required?: boolean
  name?: string
  value?: {
    code: string
    display: string
  }
  placeholder?: string
  error?: boolean
  helperText?: string | boolean
  onChange: (value) => void
}

export const SearchVaccine = (props: Props) => {
  const [inputValue, setInputValue] = useState(props.value?.code || '')
  const [cacheValue, setCacheValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<ResponseDTO[]>([])
  const [autoCompleteVisible, setAutoCompleteVisibility] =
    useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    if (inputValue.length && inputValue !== cacheValue) {
      searchMedicalProblem(inputValue)
    }

    return () => abortController.abort()
  }, [inputValue])

  useEffect(() => {
    if (props.value?.code) {
      setInputValue(props.value.display || props.value.code)
    }
  }, [props.value?.code])

  const searchMedicalProblem = useCallback(
    debounce(async (val) => {
      try {
        setLoading(true)
        const payload = {
          "size": 1000,
          "query": {
            "multi_match": {
              "query": val,
              "type": "bool_prefix",
              "fields": [
                "code",
                "code._2gram",
                "code._3gram",
                "display",
                "display._2gram",
                "display._3gram"
              ]
            }
          },
          "highlight": {
            "pre_tags": [
              "<em>"
            ],
            "post_tags": [
              "</em>"
            ],
            "fields": {
              "cptCode": {
                "number_of_fragments": 1,
                "fragment_size": 20
              },
              "clinicianDescriptor": {
                "number_of_fragments": 3,
                "fragment_size": 50
              }
            }
          }
        }

        const resp = await getVaccines(payload)

        const data = resp.data.hits.hits.map(item => ({ ...item._source }))
        setOptions(data)

        setLoading(false)
      } catch (error) {
        console.log({ error })
        setLoading(false)
      }
    }, 500),
    [],
  )

  const filterAutoComplete = () => {
    return options
  }

  return (
    <>
      <FormLabel
        component="p"
        className="input-form-label">
        <span className={!!props.required ? 'required' : ''}>{props.label}</span>
      </FormLabel>
      <Box
        sx={{
          width: '100%',
          '& .MuiIconButton-root': {
            transform: 'none !important',
          },
          '& .MuiAutocomplete-root': {
            width: '100%',
          },
          '& .MuiInputBase-input': {
            marginRight: 24,
          },
        }}>
        <Autocomplete
          popupIcon={
            <SearchIcon style={{ marginTop: 4 }} />
          }
          selectOnFocus
          clearIcon={false}
          forcePopupIcon={!loading}
          autoComplete
          loading={loading}
          options={options}
          value={{ id: inputValue, name: inputValue }}
          isOptionEqualToValue={(option, value) => value.id === option.id}
          open={autoCompleteVisible}
          renderOption={(
            props,
            option
          ) => {
            return (
              <li {...props} key={option.code}>
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      `<span class='highlight'>${option.code} - ${option.display}</span>`
                  }} />
              </li>
            )
          }}
          filterOptions={filterAutoComplete}
          onOpen={() =>
            setAutoCompleteVisibility(true)
          }
          onClose={() =>
            setAutoCompleteVisibility(false)
          }
          onChange={(event, newValue) => {
            setCacheValue(inputValue)

            props.onChange(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          getOptionLabel={option => option.name || ''}
          sx={{ width: 300 }}
          renderInput={(params) => {
            const renderProps = {
              ...params,
              InputProps: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                      />
                    ) : null}
                    {
                      params
                        .InputProps
                        .endAdornment
                    }
                  </>
                ),
              },
            }

            return <TextField
              variant='outlined'
              name={props.name ? props.name : ''}
              placeholder={props.placeholder}
              error={props.error}
              helperText={props.helperText}
              {...renderProps}
            />
          }}
        />
      </Box>
    </>
  )
}
