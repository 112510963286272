import React from 'react'
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'
import TextBox from 'shared/elements/FormItem/TextBox'
const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            height: '370px',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
    },

    btn_delete: {
        fontSize: '15px !important',
        width: '150px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
        height: '33px',
    },
    header: {
        fontSize: '20px',
        color: '#303E4E',
        fontWeight: 500,
        textAlign: 'center',
        margin: '10px 0',
    },
}))

export const HistoryNote = ({ open, handleClose, content, isProvider, onChangContent }) => {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '500px',
                },
            }}
            fullWidth={true}
            onClose={handleClose}
            className={classes['root']}>
            <DialogContent sx={{ padding: '8px' }}>
                <Typography className={classes['header']}>
                    Note{' '}
                    <Typography sx={{ fontWeight: 300, fontSize: '17px' }} component={'span'}>
                        {isProvider ? '(can edit)' : '(can view)'}
                    </Typography>
                </Typography>
                <Box>
                    <TextBox
                        // label="Notes"
                        disabled={!isProvider}
                        name="note"
                        value={content || ''}
                        multiline
                        minRows={13}
                        placeholder="Enter Comment"
                        onChange={(value) => onChangContent(value)}
                        // error={formik.touched.note && Boolean(formik.errors.note)}
                        // helperText={formik.touched.note && formik.errors.note}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <LoadingButton
                    // loading={loading}
                    loadingPosition="start"
                    startIcon={<></>}
                    onClick={handleClose}
                    // disabled={loading}
                    autoFocus
                    variant="contained"
                    className={classes['btn_delete']}>
                    OK
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
