import React from 'react'
import clsx from 'clsx'
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CustomeCheckBoxChecked from '../../../assests/icons/CustomeCheckBoxChecked'
import CustomeCheckBoxUnCheck from '../../../assests/icons/CustomeCheckBoxUnCheck'

const useStyles = makeStyles({
    container: {
        margin: '30px 0',
        minWidth: '30%',
    },
    labelText: {
        height: 22,
        fontSize: 14,
        letterSpacing: 0,
    },
    checkLabel: {
        color: '#667689',
        fontWeight: 'normal',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
        fontSize: 14,
        padding: 7,
        textTransform: 'initial',
    },
    checkbox: {
        '&$checked': {
            color: '#546FC9',
        },
        '&$disabled': {
            color: '#ccc',
        },
    },
    checked: {},
    disabled: {
        opacity: '0.6'
    },
    controlWrap: {
        alignItems: 'center',
        marginRight: '0px',
    },
})

const CheckboxOption = ({
    name,
    option,
    onChange,
    checked,
    disabled,
    defaultChecked,
    classRoot,
    required,
    FormControlProps = {},
    customStyle = {},
    ...props
}) => {
    const classes = useStyles()

    return (
        <FormControlLabel
            className={clsx(classes.controlWrap, classRoot)}
            value={option.value}
            onChange={onChange}
            {...FormControlProps}
            control={
                <Checkbox
                    // icon={<UncheckIcon />}
                    name={name}
                    defaultChecked={defaultChecked}
                    checkedIcon={<CustomeCheckBoxChecked />}
                    icon={<CustomeCheckBoxUnCheck />}
                    classes={{
                        root: classes.checkbox,
                        disabled: classes.disabled,
                        checked: classes.checked,
                    }}
                    size="medium"
                    disabled={disabled ? disabled : false}
                    {...props}
                    style={customStyle}
                />
            }
            label={
                option.label && (
                    <Typography variant="h6">
                        <span
                            style={{
                                opacity: disabled ? 0.6 : 1,
                            }}
                            className={classes.checkLabel}>
                            {option.label}
                        </span>
                    </Typography>
                )
            }
            checked={checked}
        />
    )
}

export default CheckboxOption
