import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { PsychiatricMedicationHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { PsychiatricMedication } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import { PsychiatricMedicationForm } from './PsychiatricMedicationForm'
import { apiPsychiatricMedicalHistory } from 'services/Patient/apiPsychiatricMedicalHistory'
import { useNotification } from 'shared/elements/Notification'
import { boolean } from 'yup'

const PsychiatricMedicationHistory = ({
    isInClinicalNote,
    patientId: patient_Id,
    encounter_id,
    afterActionOk,
    disabled = false
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const { id } = useParams<{ id: string }>()

    // State
    const [patientId, setPatientId] = useState(false)
    const [bundleId, setBundleId] = useState('')
    const [psychiatricMedicalId, setPsychiatricMedicalId] = useState<string | false>(false)
    // const [page, setPage] = useState<number>(1)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [psychiatricMedicationList, setPsychiatricMedicationList] = useState<
        PsychiatricMedication[]
    >([])
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const handleChangePage = (page) => {
        setPage(page)
    }
    const getPsychiatricMedicationList = async () => {
        setLoading(true)
        try {
            const resp = await apiPsychiatricMedicalHistory.getPsychiatricMedication(patientId, {
                page,
                size: limit,
                // bundle_id: bundleId,
            })
            if (resp.data.message.data) {
                setPsychiatricMedicationList(resp.data.message.data)
                setTotal(resp.data.message.total)
                // if (!resp.data.data.length && resp.data.paging.total > 0) {
                //     setPage(page - 1)
                // }
            }
            // if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)
        } catch (error) {
            notification('Cannot get list Psychiatric Medication History', 'error')
        } finally {
            setLoading(false)
        }
    }
    const hanldeDelete = async (psychiatricMedicalId) => {
        if (patientId) {
            try {
                const res = await apiPsychiatricMedicalHistory.delete(
                    patientId,
                    psychiatricMedicalId,
                )
                if (res.data?.Status === 'SUCCESSED') {
                    notification('Psychiatric Medication History deleted successfully', 'success')
                    await getPsychiatricMedicationList()
                    afterActionOk?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            }
        }
    }

    const handleEdit = (id) => {
        setPsychiatricMedicalId(id)
        setIsAddingNew(true)
    }
    useEffect(() => {
        if (patientId) {
            getPsychiatricMedicationList()
        }
    }, [patientId, page])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{
                position: 'relative',
                overflow: 'initial',
                padding: isInClinicalNote ? 0 : '16px 24px',
            }}>
            <Loading visible={loading} />
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', id))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>
                        Psychiatric Medication History
                    </Typography>

                    <ButtonCustom
                        tooltip="Add New Psychiatric Medication History"
                        variant="contained"
                        color="primary"
                        onClick={() => setIsAddingNew(true)}
                        disabled={disabled}
                        // style={isInClinicalNote && { position: 'absolute', right: 0, top: -40 }}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <PsychiatricMedicationHistoryDashboard
                    page={page}
                    onPageChange={handleChangePage}
                    hanldeDelete={hanldeDelete}
                    handleEdit={handleEdit}
                    dataSource={psychiatricMedicationList}
                    pagination={{ start: page, total, limit }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    disabled={disabled}
                />

                {isAddingNew && (
                    <PsychiatricMedicationForm
                        psychiatricMedicalId={psychiatricMedicalId}
                        patientId={isInClinicalNote ? patientId : id}
                        encounter_id={encounter_id}
                        open={isAddingNew}
                        onOke={() => {
                            setIsAddingNew(false)
                            setPsychiatricMedicalId(false)
                            if (patientId) {
                                getPsychiatricMedicationList()
                            }
                            afterActionOk?.()
                        }}
                        onClose={() => {
                            setIsAddingNew(false)
                            setPsychiatricMedicalId(false)
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(PsychiatricMedicationHistory)
