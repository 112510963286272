import {
    postSearchService,
    getService,
    postService,
    putService,
    deleteService,
} from '../../utilities/request/restClient'

export const getFaxList = (params = {}) => {
    const searchParams = new URLSearchParams({
        ...params,
        is_archived: params?.is_archived ? 1 : 0,
    })
    return getService(
        `${
            process.env.REACT_APP_SONORA_V2_API_URL
        }/e-fax/faxs/history?${searchParams.toString()}`,
    )
}

export const sendFax = (payload) => {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs`,
        payload,
    )
}

export const forwardFax = (messageId, to) =>
    // FIXME: Prepend '+1' to 'to' number
    postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs/${messageId}/foward`, { to: `+1${to}` })

export const getFaxMe = () => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/me`,
    )
}

export const updateFaxList = (payload) => {
    return putService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs`,
        payload,
    )
}

export const updateFaxById = (id, payload) => {
    return putService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs/${id}`,
        payload,
    )
}

export const getFaxAttachmentFile = (id) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs/attachment/${id}`,
    )
}

export const getUploadAttachmentFilePresignedUrl = (id) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs/get-attachment-url/${id}`,
    )
}

export const getFaxById = (faxId) => {
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/e-fax/faxs/${faxId}`,
    )
}
