import { Box, Button, Grid, Typography, Checkbox, CircularProgress } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { AddCircleOutlineOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Divider from '@mui/material/Divider'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    postMedicalProblem,
    getMedicalProblemList,
    deleteMedicalProblem,
} from 'services/Patient/patient'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { postMedicalCoding, getMedicalCoding } from '../../../containers/ClinicalNotes/api/services'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'
import MedicalHistory from '../../../assests/icons/medicalHistory.svg'
import copyNew from '../../../assests/icons/copyNew.svg'
import AddIcon from 'assests/icons/Add.svg';
import TrashIcon from '../../../assests/icons/trash.svg'
import {ReactComponent as DoctorLogo} from '../../../assests/icons/medicalHistory.svg'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { shortenString } from 'utilities/stringOperations'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { LoadingButton } from '@mui/lab'
import Loading from 'shared/elements/Loading'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const PROBLEM_STATUS = [
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'resolved', value: 'Resolved' },
    { key: 'Deleted', value: 'Deleted' },
]
const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
        marginLeft: 21,
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
    },
    first_box: {
        
        marginTop:'30px',
        marginBottom:'30px', 
    },
    second_box:{
        borderRadius: '5px',
        border: '1px solid #EAECF0',
    },
    first_step_box:{
        width: '100%',
        background: '#F8F9FB',
        display:'flex',
        justifyContent:"space-between !important",
        alignItems:"cetner",
        borderBottom:'1px solid #EAECF0',
        padding:'10px', 
        
    },
    copy_button:{
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        background: "#FFF !important",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "24px"
    },
    addIcon:{
        background: "#15A452 !important",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        fontSize: "12px",
        color:"#fff",
        fontWeight: "400",
        height: "24px"
    },
    narrative: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        lineHeight: '22px',
    },
    assessmentListContainer:{
        display: "flex", 
        alignItems: "center",
        flexWrap: "wrap",
        gap: "10px",
        marginTop:'15px',
        marginBottom:'8px'
    },
    box_type: {
        // borderRadius: '0px 0px 5px 5px',
        // borderRight: '1px solid #EAECF0',
        // borderBottom: '1px solid #EAECF0',
        // borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        marginTop: '13px'
        // padding: '13px 15px 13px 8px',
    },
    active_medication: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        minHeight: '42px',
        overflow: 'scroll',
    },
    active_medication_box: {
        width: '100%',
        padding: '12px 10px 10px 7px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '20px',
    },
    active_diagonsis_divider_box: {
        width: '100%',
        padding: '12px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    active_diagonsis_button_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        whiteSpace: 'nowrap',
        padding: '12px 0px',
    },
    active_diagonsis_container: {
        width: '100%',
        padding: '15px 8px',
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    active_diagonsis_box: {
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        minHeight: '130px',
        width: '100%',
        padding: 10,
    },
    divider: {
        background: 'transparent',
        width: '97%',
        // height: '1px',
    },
    DividerText: {
        color: 'var(--Light-Foreground-Foreground-2, #424242)',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    dividerScnd: {
        background: 'transparent',
        width: '100%',
        // height: '1px',
    },
    divider_height: {
        height: '25px !important',
        width: '2PX',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '82px',
        borderRadius: '5px',
        border: '1px solid #E4E2E2',
        background: '#FFF',
        padding: '15px 6px 15px 6px',
        marginBottom: '7px',
        marginRight: '10px',
        justifyContent: 'space-between',
        width: '207px',
    },
    text: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: '400',
        color: '#303E4E',
    },
    status: {
        background: '#EBFFF3',
        color: '#14AE5C',
        padding: '1px 10px 1px 10px',
        marginLeft: '2px',
        fontSize: '13px',
        fontWeight: '400',
        borderRadius: '5px',
    },
    MedicationCard: {
        width: "321px",
        borderRadius: "5px",
        border: "1px solid #E4E2E2",
        margin:'0px 10px 10px 10px',
        cursor: "pointer"

    },
    cardText: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
    },
    antiSwitch:{
        '& .MuiSwitch-thumb': {
            background: '#303E4E !important'
        },
        '& .MuiSwitch-track': {
            background: '#fff',
            border: '0.5px solid black'
        },
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                background: '#fff !important'
            },
            '& .MuiSwitch-track': {
                background: '#5571C6 !important',
                border: '1px solid #5571C6 !important'
            }
        },
    },
    cardTextTypography:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
    },
    activeStatus:{
        display: 'flex',
        height: '20px',
        padding: '6px',
        alignItems: 'center',
        gap: '4px',
        color: '#15A452',
        borderRadius:  '4px',
        border: '1px solid  #15A452',
        background: '#EBFFF3'
    },
    headingText:{
        padding: '7px',
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
    },
    PopOver: {
        width: "588px",
        height: "510px",
        padding:"20px",
        borderRadius: "5px",
        background: "#FFF",
        overflowY: "auto",
    },
    button_create_form: {
        background: '#5571C6 !important',
        color: '#fff !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    button_cancel:{
        background: '#fff !important',
        color: '#303E4E !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    }
})
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const NewAssessmentDiagnosis = (props) => {
    const { patientInfo } = usePatient(props.patientId)
    const classes = useStyles()
    const notification = useNotification()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [medCodes, setMedCodes] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const validationSchema = yup.object().shape({
        icd_code: yup.object().shape({
            code: yup.string().required('ICD code is required'),
            display: yup.string()
        }),
        medical_problem: yup.object().shape({
            code: yup.string().required('Medical problem is required'),
            display: yup.string()
        }),
    })

    const INITIAL_VALUES = {
        medical_problem: {
            code: '',
            display: '',
        },
        icd_code: {
            code: '',
            display: ''
        },
        status: {
            code: 'active',
            display: 'Active',
        },
        comment: '',
    }
    const getMedialProblemList = async () => {
        try {
            setIsLoading(true)
            const resp = await getMedicalProblemList(props?.patientId, 1, 1000)
            const list = resp.data.data.reduce((acc, item) => {
                if (item.status?.code === 'active') {
                    acc.push({
                        problem: item.icd_code?.display || '',
                        code: item.icd_code?.code || '',
                        date_diagnosed: formatDateTime(item.time_onset, 'MM/DD/YYYY'),
                        date_changed: formatDateTime(item.time_changed, 'MM/DD/YYYY'),
                        status: item.status?.code || '',
                        note: item.note || '',
                        id: item.id,
                        patient_id: item.patient_id,
                    });
                }
                return acc;
            }, [])
            props.onChange(`${props.fieldName}.active_medical_problems`, list)
            if(!props.values?.hasOwnProperty('plan_list')){
                props.onChange(`${props.fieldName}.plan_list`, [])
            }
            setIsLoading(false)
        } catch (ex) {
            setIsLoading(false)
            notification(`Cannot get list problem!`, 'error')
        }
    }
    const handleClose = () =>{
        setOpen(false)
        formik.resetForm();
    }
    const handleSubmit = async (payload) => {
        try {
            setIsSubmitting(true)
            const data = payload
            const postData = {
                id: '',
                patient_id: '',
                encounter_id: '', // appointment
                time_diagnosis: '',
                time_changed: '',
                external_id: '',
                rcopia_id: '',
                problem: data?.medical_problem,
                icd_code: data?.icd_code,
                status: data?.status,
                time_onset: '',
                note: data?.comment,
            }
            const add_med = await postMedicalProblem(patientInfo.id, postData)
            const addLastDiagnosisData = {
                id: add_med?.data?.id,
                medical_problem: data?.icd_code,
                icd_code: data?.icd_code,
                status: data?.status,
                comment: data?.comment,
            }
            const arrWithAdd = [...props.values.new_diagnosis, addLastDiagnosisData]
            if (add_med) {
                props.onChange(`${props.fieldName}.new_diagnosis`, [...arrWithAdd])
                notification('Problem created successfully!', 'success')
            }
            getMedialProblemList()
            handleClose()
        } catch (error) {
            console.log('error',error)
            notification('Problem created Unsuccessfully!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    })
    
    const getMedCode = async() =>{
        const res = await getMedicalCoding(props?.appointmentId)
        setMedCodes(res?.data)
    }
    useEffect(()=>{
        getMedialProblemList()
        getMedCode()
    },[])
    const handleAddMedicalCodeAndPlanlist = (item,checked) => {
        const { cpt, hcpcs, icd10 } = medCodes
        const exists = icd10.some((obj) => obj.code === item?.code)
        if (checked && !exists) {
            const payload = {
                icd10: [
                    ...icd10,
                    {
                        code: item?.code,
                        description: item?.problem,
                        fromMedicalProblems: true,
                        id: item?.id,
                        origin_type: 'from_medical_problems',
                    },
                ],
                cpt,
                hcpcs,
            }
            postMedicalCoding(props?.appointmentId, payload).then(res=>{
                getMedCode()
            })
            
        }else if(!checked && exists){
            const newICD10 = icd10.filter((obj) => obj.code !== item?.code)
            const payload = {
                icd10: newICD10,
                cpt,
                hcpcs,
            }
            postMedicalCoding(props?.appointmentId, payload).then(res=>{
                getMedCode()
            })
        }
        const existsInPlan = props.values?.plan_list?.length > 0 ? props.values?.plan_list?.some((obj) => obj.code === item?.code && obj.id === item?.id) : false
        if(checked && !existsInPlan){
            props.onChange(`${props.fieldName}.plan_list`, [...props.values.plan_list, {'code': item?.code, 'id':item?.id, 'problem':item?.problem, 'status':item?.status}])
        }else if(!checked && existsInPlan){
            const newPlanList = props?.values.plan_list.filter((obj) => obj.code !== item?.code)
            props.onChange(`${props.fieldName}.plan_list`, newPlanList)
        }
    }
    const CardComponent = ({value, index}) => {
        const classes = useStyles()
        return (
            <Box className={classes.MedicationCard} key={index}>
                <div style={{ 
                    display: "flex", 
                    alignItems: 'center', 
                    borderBottom: '1px solid #e0e0e0', 
                    justifyContent: 'space-between' ,
                    padding:'12px',
                    background:'#F8F9FB'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <DoctorLogo />  
                        <Typography className={classes.cardText}>{capitalizeFirstLetter(shortenString(value?.code,15))}</Typography>
                    </div>
                    <div className={classes.antiSwitch}>
                        <AntSwitch
                            checked={props.values?.plan_list?.length > 0 ? props.values?.plan_list?.some((obj) => obj.code === value?.code && obj.id === value?.id) : false}
                            onChange={(event) => {
                                const { checked } = event?.target
                                handleAddMedicalCodeAndPlanlist(value,checked)
                            }}
                        />
                    </div>
                </div>
                <div style={{ 
                    padding: '12px', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 10,
                }}>
                    <div style={{ display: "flex",gap: 5 }}>
                        <Typography
                            className={classes.cardTextTypography}
                        >Description-</Typography>
                        {value?.problem?.length > 28
                        ?
                            <HtmlTooltip
                                arrow
                                title={value.problem}
                                placement="bottom">
                                <Typography
                                    className={classes.cardTextTypography}
                                >{shortenString(value.problem, 27)}</Typography>
                            </HtmlTooltip>
                        :
                            <Typography
                                    className={classes.cardTextTypography}
                                >{value.problem}</Typography>
                        }
                    </div>
                    <div style={{ display: "flex",gap: 5,alignItems: 'center', }}>
                        <Typography
                            className={classes.cardTextTypography}
                        >Status -</Typography>
                        <Typography className={classes.activeStatus}>{capitalizeFirstLetter(value.status)}</Typography>
                    </div>
                </div>
    
            </Box>
        )
    }
    
    return (
        <Grid item className={classes.first_box} style={{ minHeight: "800px"}}>
            <Grid container className={classes.second_box}>
                <div className={classes.first_step_box}>
                    <Typography className={classes.narrative}>Assessment Diagnosis</Typography>
                    <div style={{display:'flex',alignItems:'center', gap: '10px'}}>
                        <Button
                            startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                            className={classes.copy_button}
                            onClick={() => {
                                
                            }}
                            >
                            {' '}
                            {"Copy: 01/01/2023"}
                        </Button>
                        <Button
                            startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                            className={classes.addIcon}
                            disabled={isLoading}
                            onClick={() => {
                                setOpen(true)
                            }}
                            >
                            Add New Diagnosis
                        </Button>
                    </div>
                </div>
                <>
                {props?.values?.active_medical_problems.length > 0 &&
                            <Grid container justifyContent='space-between'>
                                <Typography className={classes.headingText}>
                                    Select the medical problems you would like to include in plan and medical coding.
                                </Typography>
                                <Typography className={classes.headingText}>
                                    Total: {props?.values?.active_medical_problems.length}
                                </Typography>
                            </Grid>
                        }
                <Loading visible={isLoading} />
                    <Box className={classes.assessmentListContainer}>
                        <Box style={{ 
                                        display: "flex", 
                                        flexWrap: "wrap", 
                                        alignItems: "center", 
                                        gap: "12px", 
                                        // paddingTop: '12px',
                                        overflowY: "auto" }}>
                            
                            {props?.values?.active_medical_problems.length > 0 && props?.values?.active_medical_problems.map((item,index)=>(
                                <CardComponent value={item} index={index} />
                            ))}
                            
                        </Box>
                    </Box>
                </>
            </Grid>
            <Box className={classes.box_type}>
                <Grid container item>
                    <Grid item xs={12}>
                        <TextWithMacrosNew
                            name={`${props?.fieldName}.plan.narrative`}
                            onChange={props?.onChange}
                            // // macros={macros}
                            isDisabled={props?.nonEditable}
                            rows={12}
                            withoutMacro={false}
                            values={props?.values?.plan?.narrative}
                            socialHistory={true}
                            // placeholder={placeholder}
                        />
                    </Grid>
                </Grid>
            </Box>


            <Dialog
                open={open}
                onClose={handleClose}
                style={{ marginLeft: "-5px"}}
                sx={{
                    
                    '& .MuiPaper-root':{
                        maxWidth: '700px !important',
                    }
                }}
            >
                <Box className={classes.PopOver}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "8px",
                        paddingTop: "12px",
                        marginTop: '8px'
                    }}>
                        <Typography style={{ fontSize: "24px", fontWeight: 500, color: '#000' }}className={classes.cardText}> Create New Diagnosis</Typography>
                    </Box>
                    <Grid container item style={{marginTop:'10px'}}>
                    <Grid container item>
                        <Grid container item alignItems="flex-end">
                            <Grid item xs={12} className={classes.heightSetter}>
                                <SearchICD10Code
                                    label="Medical Problems"
                                    required
                                    name="medical_problem"
                                    placeholder="Search medical problem"
                                    value={formik.values.medical_problem}
                                    error={
                                        formik.touched.medical_problem?.code &&
                                        Boolean(formik.errors.medical_problem?.code)
                                    }
                                    helperText={
                                        formik.touched.medical_problem?.code &&
                                        formik.errors.medical_problem?.code
                                    }

                                    onChange={(value) => {
                                        if (value) {
                                            formik.setFieldValue(
                                                'icd_code',
                                                {
                                                    code: value.code,
                                                    display: value.display,
                                                },
                                            )
                                            formik.setFieldValue(
                                                'medical_problem',
                                                {
                                                    code: value.code,
                                                    display: value.display,
                                                },
                                            )
                                        }else{
                                            formik.setFieldValue(
                                                'icd_code',
                                                {
                                                 code: '',
                                                 display: ''   
                                                },
                                            ) 
                                            formik.setFieldValue(
                                                'medical_problem',
                                                {
                                                 code: '',
                                                 display: ''   
                                                },
                                            ) 
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={2} style={{ marginTop: "4px" }}>
                            <Grid item xs={6}>
                                <SearchICD10Code
                                    label="ICD10 Code"
                                    required
                                    name="icd_code​​​​"
                                    placeholder="Search Code"
                                    value={{
                                        code: formik.values.icd_code?.code,
                                        display: formik.values.icd_code?.code}}
                                    error={
                                        formik.touched.icd_code?.code &&
                                        Boolean(formik.errors.icd_code?.code)
                                    }
                                    helperText={
                                        formik.touched.icd_code?.code &&
                                        formik.errors.icd_code?.code
                                    }

                                    onChange={(value) => {
                                        if (value) {
                                            formik.setFieldValue(
                                                'icd_code',
                                                {
                                                    code: value.code,
                                                    display: value.display,
                                                },
                                            )
                                            formik.setFieldValue(
                                                'medical_problem',
                                                {
                                                    code: value.code,
                                                    display: value.display,
                                                },
                                            )
                                        }else{
                                            formik.setFieldValue(
                                                'icd_code',
                                                {
                                                    code: '',
                                                    display: ''   
                                                },
                                            ) 
                                            formik.setFieldValue(
                                                'medical_problem',
                                                {
                                                    code: '',
                                                    display: ''   
                                                },
                                            ) 
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{marginTop:'49px'}}>
                                <Select
                                    name="status"
                                    required
                                    placeholder="Select status"
                                    value={formik.values.status.code}
                                    options={PROBLEM_STATUS}
                                    onChange={(value) => {
                                        if (item.status?.code !== 'Deleted')
                                        formik.setFieldValue(
                                            'status',
                                            {
                                                code: value,
                                                display:
                                                    value
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    value.slice(1),
                                            },
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginBottom: "11px", marginTop: "4px" }}>
                            <TextBox
                                label='Comment'
                                variant='outlined'
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '79px !important'
                                    },
                                    ' & .MuiInputBase-input': {
                                        height: "60px !important"
                                    }
                                }}
                                placeholder=''
                                multiline
                                maxRows={4}
                                value={formik.values.comment}
                                onChange={(e) =>{
                                    formik.setFieldValue('comment', e)
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} justifyContent='flex-end' >
                        <div style={{ height: "", marginTop: "17px", marginBottom: "5px", display: "flex", justifyContent:'flex-end', gap: 10 }}>
                            <LoadingButton
                                loadingPosition="start"
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={(event) => {
                                    handleClose()
                                }}>
                                Cancel
                            </LoadingButton>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                variant="contained"
                                className={classes.button_create_form}
                                onClick={(event) => {
                                    formik.handleSubmit()
                                }}>
                                {'Save medication'}
                            </LoadingButton>
                        </div>
                    </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Grid>
    )
}

export default NewAssessmentDiagnosis


