import {
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import React from 'react'

const Notification = ({ preferences }) => {
    return (
        <Grid container className="borderBottom">
            <Grid item xs={6} lg={6} className="pTB3">
                <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4}
                    className="card-title-align">
                    <Typography
                        className="card-title"
                        variant="h3">
                        Notifications
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={12}
                    className="dFlex p5">
                    <Grid item xs={6} md={5} lg={4}>
                        <Typography className="detail-item-title ">
                            SMS:
                            <strong>
                                {preferences.sms
                                    ? 'On'
                                    : 'Off'}
                            </strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={12}
                    className="dFlex p5">
                    <Grid item xs={6} md={5} lg={4}>
                        <Typography className="detail-item-title ">
                            Email:
                            <strong>
                                {preferences.email
                                    ? 'On'
                                    : 'Off'}
                            </strong>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    lg={12}
                    className="dFlex p5">
                    <Grid item xs={6} md={5} lg={4}>
                        <Typography className="detail-item-title ">
                            In-App:
                            <strong>
                                {preferences.app_notification
                                    ? 'On'
                                    : 'Off'}
                            </strong>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Notification
