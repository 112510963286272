import React, { useState, useEffect, lazy } from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { makeStyles } from '@material-ui/styles'
// import submitted from '../../../assests/icons/checkSubmitted.svg'
import { Dialog, DialogContent, Grid, IconButton, Typography } from '@material-ui/core'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import { getAppointmentLog } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { appointmentStatus } from './appointmentStatus'
const Loading = lazy(() => import('shared/elements/Loading'))
const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1100px',
            overflow: 'auto'
        },
    },
    header: {
        fontSize: '18px',
        color: '#303E4E',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 18,
    },
    tableHead: {
        borderRadius: '5px',
        border: '0.8px solid #E9E9E9',
        background: '#F8F9FB',
    },
    bgColor_in_progress: {
        background: '#D3DEFF',
        color: '#5571C6',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    bgColor_complete: {
        background: '#EBFFF3',
        color: '#15A452',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    bgColor_draft: {
        color: '#FFF',
        // backgroundColor: '#DEE2E6',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    noRecord: {
        textAlign: 'center',
        border: '1px solid #CFCFCF',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        padding: '10px',
    },
    tbody:{
        color: "#000",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%"
    },
    tbodyInside:{
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Rubik',
    },
    dateTime:{
        color: "#000",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%",
        paddingLeft: "7px"
    }
})
function Dateformat(date,timeZone){
    const classes = useStyles()
    const dateObj = new Date(date);
    const formattedDate = dateObj.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    const formattedTime = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true })
    return (
        <div style={{display:"flex", alignItems: "center"}}>
            <Typography className={classes.tbody}>{formattedDate}</Typography>
            <Typography className={classes.dateTime}>{formattedTime}</Typography>
            <Typography className={classes.dateTime}>{timeZone ? timeZone :"N/A"}</Typography>
          
        </div>
      );
}
function Row(props) {
    const classes = useStyles()
    const { row } = props
    const [open, setOpen] = React.useState(false)
    const status = appointmentStatus(row?.appointment_status)
    const statusUpdate =(fieldName, value) =>{
        if(fieldName === 'Start' || fieldName === 'End' ){
            return moment(value).format('MM/DD/YYYY hh:mm:SS A')
        }else if(fieldName === 'Status'){
            return appointmentStatus(value).displayName
        }else if(value){
            return value
        }
        return 'N/A'
    }
    
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ marginTop: 1 }}>
                    {open ? (
                        <KeyboardArrowUpIcon
                            style={{
                                border: '1px solid #667689',
                                color: '#667689',
                                cursor: 'pointer',
                                borderRadius: '7px',
                                fontSize: '0.996rem',
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    ) : (
                        <KeyboardArrowDownIcon
                            style={{
                                border: '1px solid #667689',
                                color: '#667689',
                                cursor: 'pointer',
                                borderRadius: '7px',
                                fontSize: '0.996rem',
                            }}
                            onClick={() => setOpen(!open)}
                        />
                    )}
                </TableCell>
                <TableCell component="th" scope="row" className={classes.tbody}>
                    {row?.user_name || 'N/A'}
                </TableCell>
                <TableCell className={classes.tbody}>
                   { row?.createdAt ? Dateformat(row?.createdAt, row?.time_zone) : 'N/A'}
                </TableCell>
                <TableCell className={classes.tbody}>{row?.description ||'N/A'}</TableCell>
                <TableCell className={classes.tbody}>
                    <span className={classes.bgColor_draft} style={{backgroundColor: status?.bgColor}}>{status?.displayName}</span>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                borderLeft: '1px solid #E9E9E9',
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            Field
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            Previous value
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                borderRight: '1px solid #E9E9E9',
                                                borderTop: '1px solid #E9E9E9',
                                                background: '#F8F9FB',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                fontFamily: 'Rubik',
                                                width: '25%',
                                            }}>
                                            New Value
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.field_changes.length > 0 && row?.field_changes.map((item, index) => (
                                        <TableRow>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                {item?.field_name ||'N/A'}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                {statusUpdate(item?.field_name,item?.prev_value)}
                                                {/* { item?.field_name==='Start' || item?.field_name==='End' ? moment(item?.prev_value).format('MM/DD/YYYY HH:MM:SS') : item?.prev_value ? item?.prev_value :'N/A'} */}
                                            </TableCell>
                                            <TableCell
                                                className={classes.tbodyInside}
                                                sx={{
                                                    borderBottom: '1px solid white',
                                                }}>
                                                {statusUpdate(item?.field_name,item?.new_value)}
                                                {/* {item?.field_name==='Start' || item?.field_name==='End' ? moment(item?.new_value).format('MM/DD/YYYY HH:MM:SS') : item?.new_value ? item?.new_value :'N/A'} */}
                                            </TableCell>
                                        </TableRow>
                                     ))} 
                                </TableBody>
                            </Table>
                            {row?.field_changes.length === 0 && (<div className={classes.noRecord} style={{borderRadius:"0px"}}>No results found.</div>)}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
export default function AppointmentLog({ open, close, id }) {
    const classes = useStyles()
    const notification = useNotification()
    const [appointmentData, setAppointmentData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
      const getAppointmentLogData = async(id)=>{
        try{
            setIsLoading(true)
            const data = await getAppointmentLog({appointment_id:id})
            const newData = data?.data?.data.map((item)=>{
                return{
                    ...item,
                    field_changes: item?.field_changes ? JSON.parse(item?.field_changes) : ""
                }
            })
            setAppointmentData(newData)
            if(data?.data){
            setIsLoading(false)
            }
        } catch(e){
            notification('Somting went wrong', 'error')
            setIsLoading(false)
        }
      }
    useEffect(() => {
        if(open && id){
            getAppointmentLogData(id)
        }
    }, [open])
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1500px',
                },
            }}
            fullWidth={true}
            onClose={close}
            className={classes.root}>
            <DialogContent>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #DEE2E6;',
                    }}>
                    <Typography className={classes.header}>Appointment Log</Typography>
                    <IconButton style={{ marginBottom: 18 }} onClick={close}>
                        <CloseIcon style={{ color: '#303E4E' }} />
                    </IconButton>
                </div>
                <Grid container style={{ marginBottom: '13px', flex: 1, border: "1px solid #EAECF0", padding: '10px', borderRadius: "5px" }} direction="column">
                    <Loading visible={isLoading} />
                    <TableContainer sx={{ maxHeight: 560 }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            borderLeft: '1px solid #E9E9E9',
                                            borderTop: '1px solid #E9E9E9',
                                            borderTopLeftRadius: '5px',
                                            borderBottomLeftRadius: '5px',
                                            background: '#F8F9FB',
                                            width: '20px',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                        }}
                                    />
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        User
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Date/Time
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderTop: '1px solid #E9E9E9',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Description
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            borderRight: '1px solid #E9E9E9',
                                            borderTop: '1px solid #E9E9E9',
                                            borderTopRightRadius: '5px',
                                            borderBottomRightRadius: '5px',
                                            background: '#F8F9FB',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            fontFamily: 'Rubik',
                                        }}>
                                        Appointment Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {appointmentData.length > 0 && appointmentData.map((row) => (
                                    <Row key={row.id} row={row} />
                                ))}
                                
                            </TableBody>
                        </Table>
                        {!isLoading && appointmentData.length === 0 && (
                            <div className={classes.noRecord}>No results found.</div>
                        )}
                    </TableContainer>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
