import { useEffect, useState, useRef } from "react"
import _, { debounce } from "lodash"
import { useSelector } from "react-redux"

import { ErrorType } from "containers/PatientsSetting/interfaces"
import { forwardFax, getFaxList, getFaxMe, sendFax, updateFaxById, updateFaxList } from "services/EFax"
import { useNotification } from "shared/elements/Notification"
import { Fax, FaxMe } from "../shared/interface"


type FAX_TYPE = 'inbound' | 'outbound' | 'all'

type SEARCH_CREITERIA = { // TODO:
  page?: number
  size?: number
  fax_number?: string
  favourite?: boolean
  asc?: string
  userId?: string
  accountId?: string
  status?: 'all',
  isArchived?: boolean
  query?: string
  textSearch?: string // FIXME:?
}

export const useFaxData = (type: FAX_TYPE = 'inbound', searchCriteria: SEARCH_CREITERIA) => {
  const notification = useNotification()

  const [eFaxList, setEFaxList] = useState<Fax[]>([])
  const [loading, setLoading] = useState(false)
  const [faxSending, setFaxSending] = useState(false)

  // const [paginationData, setPaginationData] = useState({
  //   limit: 10,
  //   total: 0,
  //   start: 1
  // })

  const [mounted, setMounted] = useState(false)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  const getAll = async (start = page) => {
    setLoading(true)
    try {
      const res = await getFaxList(_.omitBy({
        fax_type: type,
        page: start,
        size: limit,
        search: searchCriteria?.textSearch?.trim() || null,
        // "fax_number": "9162373463",
        // "page": "0",
        // "size": "20",
        // "is_archived": false,
        // "favourite": false,
        // "asc": "title",
        // userId: me.userId,
        // accountId: me.accountId,
        // status: 'all',
        // search: searchCriteria.textSearch,
        is_archived: type === 'all'
          ? 1
          : searchCriteria.isArchived ? 1 : 0 // FIXME:
      }, _.isNull))

      setEFaxList(
        res.data?.data?.map((item: Fax) => {
          item.id = item.message_id
          return item
        }) || []
      )
      setTotal(res.data?.paging?.total)
    } catch (exception) {
      notification(
        (exception as ErrorType).data?.message || 'Cannot get list eFax',
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  const markFavorite = async (faxId: string, favorite: boolean) => {
    try {
      // Update list
      setEFaxList(
        eFaxList.map((faxItem) => {
          if (faxId === faxItem.id)
            return {
              ...faxItem,
              favourite: favorite
            }

          return faxItem
        }),
      )

      await updateFaxById(faxId, {
        favourite: favorite,
      })
    } catch (exception) {
      // Fallback
      setEFaxList(
        eFaxList.map((faxItem) => {
          if (faxId === faxItem.id)
            return {
              ...faxItem,
              favourite: !favorite
            }

          return faxItem
        })
      )

      notification(
        (exception as ErrorType).data?.message ||
        (favorite
          ? 'Unable to mark as Favorite'
          : 'Unable to unmark favorite'),
        'error'
      )
    }
  }

  const markFavouriteAll = async (favorite: boolean) => {
    try {
      setEFaxList(
        eFaxList.map((item) => {
          item.favourite = favorite
          return item
        })
      )

      await updateFaxList(
        eFaxList.map((item) => {
          item.favourite = favorite
          return item
        })
      )
    } catch (exception) {
      // Fallback
      setEFaxList(
        eFaxList.map((item) => {
          item.favourite = !favorite
          return item
        }),
      )

      notification(
        (exception as ErrorType).data?.message ||
        (favorite
          ? 'Unable to mark all as Favorite'
          : 'Unable to unmark favorite'),
        'error',
      )
    }
  }

  const selectItem = (faxId: string) => {
    setEFaxList(
      eFaxList.map((faxItem) => {
        if (faxId === faxItem.id) {
          faxItem.isSelected = !faxItem.isSelected
        }
        return faxItem
      }),
    )
  }

  const selectAll = () => {
    setEFaxList(
      eFaxList.map((item) => {
        item.isSelected = true
        return item
      })
    )
  }

  const deselectAll = () => {
    setEFaxList(
      eFaxList.map((item) => {
        item.isSelected = false
        return item
      })
    )
  }

  const markAsRead = async (rows: Fax[], read: boolean) => {
    try {
      await updateFaxList(
        rows.map((item) => {
          item.mark_read = read
          return item
        }),
      )

      // Update list
      updateList(rows, { 'mark_read': read })
    } catch (exception) {
      notification(
        (exception as ErrorType).data?.message ||
        'Cannot mark selection as read',
        'error',
      )
    }
  }

  const archive = async (rows: Fax[], isArchived = true) => {
    try {
      setLoading(true)

      await updateFaxList(
        rows.map((item) => ({
          ...item,
          is_archived: isArchived
        })),
      )

      // Refresh list
      getAll()
    } catch (exception) {
      setLoading(false)
      notification(
        (exception as ErrorType).data?.message ||
        'Cannot archive fax',
        'error',
      )
    }
  }

  const unarchive = (rows: Fax[]) => archive(rows, false)

  const updateList = (rows: Fax[], values: { [field: string]: any }) => {
    const selectionHashMap = rows.reduce(
      (acc, row) => {
        acc[row.id as string] = row.id
        return acc
      },
      {} as { [id: string]: string | undefined },
    )

    setEFaxList(
      eFaxList.map((item) => {
        if (selectionHashMap[item.id as string as keyof Fax])
          return {
            ...item,
            ...values
          }

        return item
      }),
    )
  }

  const extractAttachments = (fax: Fax) => {
    const { file_url = '[]' } = fax
    const files = JSON.parse(file_url)

    return files
  }

  const loggedUser = useSelector(
    (state: { login: { userData: any } }) => state?.login?.userData,
  )

  const send = (fax: Fax, faxMeCallerId: string) => {
    return sendFax({
      attachments_key_list: extractAttachments(fax),
      faxNumber: fax.to,
      // recipientName: fax.recipientName,
      senderName: loggedUser?.name,
      subject: fax.title,
      callerId: faxMeCallerId, // faxMe?.callerId,
      tags: 'tag',
      notes: fax.notes,
      coverPage: 'false',
      coverPageId:
        '5a4ab2d4-d358-4079-8afb-43323fd4fa43',
    })
      .then(() =>
        notification(
          'Fax has been sent successfully',
          'success',
        ))
      .catch(() => notification(
        'Error while sendind fax',
        'error',
      ))
  }

  const forward = (fax: Fax) => forwardFax(fax.message_id, fax.to)
    .catch((exception: ErrorType) =>
      notification(exception.data?.message || 'Error while resending fax', 'error')
    )

  const resend = (rows: Fax[]) => {
    return Promise.allSettled(
      rows.map(forward)
    )
      .then(getAll)
  }

  const refresh = useRef(
    debounce(getAll, 500)
  )

  useEffect(() => {
    const abortController = new AbortController()
    getAll()

    return () => abortController.abort()
  }, [page])

  useEffect(() => {
    if (!mounted) return setMounted(true)

    if (page !== 1) return setPage(1)

    const abortController = new AbortController()
    getAll(1)

    return () => abortController.abort()
  }, [searchCriteria.textSearch])

  console.log(page, "aaaa");
  

  return {
    loading,
    eFaxList,
    paginationData: {start: page, total, limit},
    setPage,
    markFavorite,
    markFavouriteAll,
    selectItem,
    selectAll,
    deselectAll,
    markAsRead,
    archive,
    unarchive,
    refresh: refresh.current,
    resend
  }
}

export const useFaxMe = () => {
  const [loading, setLoading] = useState(false)
  const [faxMe, setFaxMe] = useState<FaxMe>({} as FaxMe)

  const getMe = async () => {
    setLoading(true)
    try {
      const response = await getFaxMe()
      setFaxMe(response.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getMe()
  }, [])

  return { loading, faxMe }
}
