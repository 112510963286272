import { ICD10 } from "./interface";

export const INITIAL_DATA = {
  specialty: '',
  conditions_treated: [{
    code: '',
    description: ''
  }] as ICD10[],
  comment: '',
  type: 'medical',
}