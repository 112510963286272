import {
    Grid,
    Typography,
    Button,
    Box,
    FormControl,
    TextField,
    InputLabel,
    FormLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import QRCode from 'react-qr-code'
import { Auth } from 'aws-amplify'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tms2uLogo from '../../assests/icons/Tms2uLogo'
import shutterstock from '../../assests/images/shutterstock_13160805981.png'
import GoogleAuthenticator from '../../assests/images/GoogleAuthenticator.png'
import OTPCode from '../../assests/images/OTPCode.png'
import clsx from 'clsx'
import LinearProgress from '@mui/material/LinearProgress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CachedIcon from '@mui/icons-material/Cached'
import { useHistory } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import TickCircle from '../../assests/icons/TickCircle'
import { Formik } from 'formik'
import IconPassword from '../../assests/images/iconPassword.png'
import IconDisablePassword from '../../assests/images/iconDisablePassword.png'
import './style.css'
import { useDispatch, useSelector } from 'react-redux'
import { logInSuccess, setMFA } from 'store/actions/login'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${shutterstock})`,
        backgroundSize: 'cover',
    },
    title: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '140%',
        marginTop: 5,
    },
    selected: {
        border: '1px solid #5571C6',
    },
    otpInput: {
        marginTop: 32,
    },
    InputLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
    },
}))

const AuthenticationSteps = {
    QR_CODE: 'QR_CODE',
    OTP_CODE: 'OTP_CODE',
    COMPLETED: 'COMPLETED',
}

export default function MFAForm() {
    const classes = useStyles()
    const history = useHistory()
    const mfa = useSelector((state) => state.login.mfa)
    const dispatch = useDispatch()

    if (!mfa) {
        history.push('/login/staff')
    }
    const [code, setCode] = useState('defaultCode')
    const [isInitial, setIsInitial] = useState(false)
    const [otp, setOtp] = useState(null)
    const [otpError, setOtpError] = useState(null)
    const [currentStep, setCurrentStep] = useState(
        AuthenticationSteps.QR_CODE,
    )

    const submitFMA = () => {
      Auth.confirmSignIn(
        mfa,
        otp,
        'SOFTWARE_TOKEN_MFA',
      )
      .then(() => {
          Auth.currentAuthenticatedUser().then(
              (data) => {
                  dispatch(logInSuccess(data))
                  history.push('/dashboard')
              },
          )
      })
      .catch((e) => {
          setOtpError(e)
      })
    }

    useEffect(() => {
        if (
            mfa &&
            mfa.challengeName !== 'SOFTWARE_TOKEN_MFA'
        ) {
            Auth.setupTOTP(mfa).then((code) => {
                setCode(
                    `otpauth://totp/Sonora:${mfa.username}?secret=${code}`,
                )
                setIsInitial(true)
            })
        }

        if (
            mfa &&
            mfa.challengeName === 'SOFTWARE_TOKEN_MFA'
        ) {
            setCurrentStep(AuthenticationSteps.OTP_CODE)
        }
    }, [mfa])

    const handleNextStep = () => {
        if (currentStep === AuthenticationSteps.QR_CODE) {
            setCurrentStep(AuthenticationSteps.OTP_CODE)
            return
        }

        if (
            currentStep === AuthenticationSteps.OTP_CODE &&
            !isInitial
        ) {
          submitFMA()
          return
        }

        if (
            currentStep === AuthenticationSteps.OTP_CODE &&
            isInitial
        ) {
            Auth.verifyTotpToken(mfa, otp)
                .then(() => {
                    Auth.currentAuthenticatedUser().then(
                        (data) => {
                            Auth.setPreferredMFA(
                                mfa,
                                'SOFTWARE_TOKEN_MFA',
                            )
                            dispatch(logInSuccess(data))
                            history.push('/dashboard')
                        },
                    )
                })
                .catch((e) => {
                    setOtpError(e)
                })
            return
        }
    }

    const renderCurrentStepText = () => {
        if (currentStep === AuthenticationSteps.QR_CODE) {
            return 'Authentication'
        }

        if (currentStep === AuthenticationSteps.OTP_CODE) {
            return 'Authentication'
        }

        return ''
    }

    const handleOtpChange = (newOtp) => {
        setOtp(newOtp)
    }

    useEffect(() => {
      if (!!otp && otp.length === 6) {
        submitFMA();
      }
    }, [otp])

    return (
        <Grid
            onKeyPress={(event) => {
              if(event.key === 'Enter'){
                submitFMA();
              }
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ minHeight: '100vh' }}>
            <Box container direction="column">
                {currentStep !==
                    AuthenticationSteps.COMPLETED && (
                    <Box sx={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                dispatch(setMFA(null))
                                history.push('/login/staff')
                            }}
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            style={{
                                backgroundColor:
                                    'transparent',
                                marginBottom: 5,
                                padding: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: 'Rubik',
                                textTransform: 'none',
                            }}>
                            Go to main page
                        </Button>
                    </Box>
                )}

                <Box
                    style={{
                        padding: 50,
                    }}
                    container
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: 700,
                        backgroundColor: '#FFFFFF',
                    }}>
                    <Tms2uLogo> </Tms2uLogo>
                    <Typography className={classes.title}>
                        {renderCurrentStepText()}
                    </Typography>
                    {currentStep ===
                        AuthenticationSteps.QR_CODE && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignContent="center">
                            <Grid
                                xs="12"
                                item
                                direction="column">
                                <Typography>
                                    Scan the QR Code your
                                    authentication app
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs="12"
                                justifyContent="center"
                                container
                                style={{
                                    padding: '15px',
                                }}>
                                {code && (
                                    <QRCode value={code} />
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.OTP_CODE && (
                        <Grid
                            direction="colum"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Typography>
                                {`Enter Multi-factor Authenticator (TOTP) code on your mobile device`}
                            </Typography>
                            <OtpInput
                                className={classes.otpInput}
                                placeholder={'000000'}
                                inputStyle="inputStyle"
                                numInputs={6}
                                isDisabled={false}
                                hasErrored={false}
                                errorStyle="error"
                                onChange={handleOtpChange}
                                separator={<span> </span>}
                                isInputNum={true}
                                isInputSecure={false}
                                shouldAutoFocus={false}
                                value={otp}
                            />

                            {otpError && (
                                <Typography
                                    style={{
                                        marginTop: 16,
                                    }}
                                    color={'error'}>
                                    Wrong authentication
                                    code. Please try again.
                                </Typography>
                            )}
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.COMPLETED && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}>
                                <TickCircle />
                            </Box>
                            <Typography
                                style={{
                                    marginTop: 24,
                                    fontWeight: 500,
                                    fontSize: '32px',
                                    color: ' #303E4E',
                                    textAlign: 'center',
                                }}>
                                Change Password Successful
                            </Typography>
                        </Grid>
                    )}

                    {currentStep !==
                        AuthenticationSteps.COMPLETED && (
                        <Button
                            onClick={handleNextStep}
                            // disabled={!selectedItem}
                            style={{
                                marginTop: 62,
                                borderRadius: 20,
                            }}
                            variant="contained"
                            size="large"s
                            fullWidth>
                            Next
                        </Button>
                    )}

                    {currentStep ===
                        AuthenticationSteps.COMPLETED && (
                        <Button
                            onClick={() => {
                                history.push('/')
                            }}
                            style={{
                                marginTop: 62,
                                textTransform: 'none',
                            }}
                            variant="contained"
                            size="large"
                            fullWidth>
                            Go to main page
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    )
}
