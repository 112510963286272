import React from 'react'
import { Button as ButtonComponent, makeStyles } from '@material-ui/core'
import './Button.scss'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles({
    btn_add: {
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '0px',
        margin: '0 10px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_free: {
        height: 35,
        minWidth: 32,
        background: '#5686b5',
    },
    btn_small: {
        width: 20,
        height: 20,
        minWidth: 20
    },
    btn_default: {
        height: 32,
        width: 32,
        minWidth: 32,
    }

})

export default function Button(props) {
    const { children, tooltip, free, btnSize='default', tooltip_placement='top' } = props
    const classes = useStyles()

    return (
        <HtmlTooltip title={tooltip || ''} arrow placement={tooltip_placement}>
            <ButtonComponent
                className={`${free ? classes.btn_free : classes.btn_add} button ${btnSize === 'default' ? classes.btn_default : classes.btn_small }`}
                variant="contained"
                {...props}
            >
                {children}
            </ButtonComponent>
        </HtmlTooltip>
    )
}
