import React from 'react'
import {
    Typography,
    Box,
    Divider,
    TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import LoadingPage from '../../../utilities/loading-page'
const useStyles = makeStyles({
    headingText: {
        color: '#516983',
        fontSize: 18,
        fontWeight: 'bold',
    },
    ammendBox: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
        marginBottom: 24,
        marginTop: 10,
    },
    ammendSection: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    ammendSectiontime: {
        display: 'flex',
    },
    ammendText: {
        fontSize: 18,
        color: '#516983',
        fontFamily: 'Rubik',
    },
    ammendDr: {
        color: '#1C2A54',
        fontSize: 14,
        fontFamily: 'Rubik',
    },
    ammendTimeutc: {
        fontSize: 14,
        color: '#516983',
        paddingLeft: 10,
        fontFamily: 'Rubik',
    },
    ammendTimedate: {
        fontSize: 14,
        color: '#516983',
        fontFamily: 'Rubik',
    },
})
const AmmendSection = ({
    ammendObject,
    onChange,
    values,
    checkStatus,
    ammendClick,
}) => {
    const classes = useStyles()
    return (
        <>
            <Box sx={{ marginTop: 20 }}>
                {ammendObject?.length > 1 ? (
                    <Typography
                        className={classes.headingText}>
                        Amendment
                    </Typography>
                ) : null}

                {ammendObject
                    ?.filter((e) => e.status === 'locked')
                    .map((value, index) => {
                        return (
                            <>
                                <Divider
                                    className={
                                        classes.divider
                                    }
                                />
                                <Typography
                                    className={
                                        classes.ammendText
                                    }
                                    key={`${value.comments}-${index}`}>
                                    {value?.comments}
                                </Typography>
                                <div
                                    className={
                                        classes.ammendSection
                                    }>
                                    <Typography
                                        className={
                                            classes.ammendDr
                                        }>
                                        Amend by: DR.{' '}
                                        <strong>
                                            {' '}
                                            {
                                                value
                                                    ?.provider
                                                    ?.first_name
                                            }{' '}
                                            {
                                                value
                                                    ?.provider
                                                    ?.last_name
                                            }
                                        </strong>
                                    </Typography>

                                    <div
                                        className={
                                            classes.ammendSectiontime
                                        }>
                                        <Typography
                                            className={
                                                classes.ammendTimedate
                                            }>
                                            Date:{' '}
                                            {moment
                                                .utc(
                                                    value?.added_on,
                                                )
                                                .format(
                                                    'MM/DD/YYYY',
                                                )}
                                        </Typography>
                                        <Typography
                                            className={
                                                classes.ammendTimeutc
                                            }>
                                            Time
                                            {moment
                                                .utc(
                                                    value?.added_on,
                                                )
                                                .format(
                                                    'hh:mm A',
                                                )}
                                        </Typography>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                <Divider className={classes.divider} />
            </Box>
            {!ammendObject && <LoadingPage />}
            {checkStatus ? (
                <TextField
                    className={classes.textFieldWrap}
                    name="ammendment"
                    variant="outlined"
                    size="small"
                    multiline
                    disabled={true}
                    minRows={8}
                    // placeholder="Something"
                    value={values}
                    onChange={onChange}
                    InputProps={{
                        classes: classes.root,
                    }}
                    inputProps={{
                        maxLength: 10000,
                    }}
                />
            ) : null}
            {ammendClick ? (
                <Box>
                    <TextField
                        className={classes.textFieldWrap}
                        name="ammendment"
                        variant="outlined"
                        size="small"
                        multiline
                        minRows={8}
                        placeholder="Please Enter Some Text"
                        value={values}
                        onChange={onChange}
                        InputProps={{
                            classes: classes.root,
                        }}
                        inputProps={{
                            maxLength: 10000,
                        }}
                    />
                </Box>
            ) : null}
        </>
    )
}
export default AmmendSection
