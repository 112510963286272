import {
    Avatar,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    makeStyles,
    Typography,
} from '@material-ui/core'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import classNames from 'classnames/bind'
import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TeleHealthIcon from '../../../assests/icons/telehealth-icon.svg'
import ProviderIcon from '../../../assests/icons/provider-icon.svg'
import BuildingIcon from '../../../assests/icons/building-icon.svg'
import PlayIcon from '../../../assests/icons/play-icon.svg'
import JoinEncIcon from '../../../assests/icons/join-enc-icon.svg'
import EndEncIcon from '../../../assests/icons/end-enc-icon.svg'
import LeaveEncIcon from '../../../assests/icons/leave-enc-icon.svg'
import ErrorIcon from '../../../assests/icons/error-icon.svg'
import TargetIcon from '../../../assests/images/target_icon.svg'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import localforage from 'localforage'
import {
    setSelectedEncounter,
    handleGetEncounterQueueList,
    handleChangeEncounterStatus,
    saveObjectEncouterStart,
    setEncounterMeetingId,
    setEncounterRoom,
    updateEncounterQueueList,
    removeEncounterQueueList,
    updateEncounterAppointmentStatus,
    addEncounterQueueList,
    updateStatusEncounterQueueList,
    updateStatusParticipant,
    setLastMessage,
    setVonageProviderSession,
} from '../../../store/actions/provider'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'
import { ButtonSpinnerEncounter } from '../../../components/custom'
import { examEndApi } from '../../../services/Provider/provider'
import {
    ENCOUNTER_STATUS,
    ENCOUNTER_QUEUE_PATIENT_STATUS,
    ENCOUNTER_QUEUE_PATIENT_STATUS_LIST,
    PATIENT_STATUS,
} from '../constant'
import { examAllowPatientJoinApi } from '../../../services/Provider/provider'
import ErrorDialog from '../../../components/custom/ErrorModal'
import { SET_ENCOUNTER, SET_TELEHEALTH_SCREEN } from 'store/actionType'
import { TAB_CLINICALNOTE } from 'constants/menu'
import { ReactComponent as NoClinicalNoteIcon } from '../../../assests/icons/NoClinicalNote.svg'
import MapIcon from '../../../assests/icons/map-icon.svg'
import { isObjectNull, shortenString } from '../utils'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { postStatusChangeRequest } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import Routes from 'constants/routes'
import { isEmpty, set, debounce } from 'lodash'
import { config } from '../../../constants'
import useRoom from '../../../components/Telehealth/hooks/useRoom'
import { usePageVisibility } from 'shared/hooks/usePageVisibility'
import { useTimezone } from 'shared/hooks/useTimezone'
import {
    handleOpenSaveAndCloseDialog,
    handleSetCurrentEncounter,
    handleVisibleSaveAndClose,
    setAllowError,
} from 'store/actions/clinicalNotes'
import ProviderLate from '../../../assests/icons/ProviderLate.svg'
import ProviderLateActive from '../../../assests/icons/ProviderLateActive.svg'
import { providerRunninglate } from 'services/ProviderDirectory'
import { sync30seconds } from 'utilities/sync30seconds'

const useStyles = makeStyles({
    encounterListWrapper: {
        width: '100%',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
    },
    wrapList: {
        width: '85%',
    },
    listItem: {
        width: '105%',
        float: 'left',
        padding: '8px 0',
        border: '1px solid #C0C1C5',
        borderRadius: '5px',
        alignItems: 'center',
        margin: '8px 0',
    },
    avatarWrap: {
        marginTop: '-33px',
        position: 'relative',
        paddingLeft: '10px',
    },
    avatar: {
        borderRadius: '40px',
        width: '25px',
        height: '25px',
    },
    colorStatus: {
        width: '8px',
        height: '8px',
        borderRadius: '8px',
        position: 'absolute',
        border: '4px solid white',
        top: -2,
        right: 13,
    },
    infoWrap: {
        width: '100%',
    },
    btn_start: {
        background: '#397171',
        borderRadius: '8px',
        width: 'fit-content',
        fontWeight: 400,
        '&:hover': {
            background: '#397171',
        },
    },
    name: {
        color: '#303E4E',
        fontSize: 14,
        fontWeight: 500,
    },
    time: {
        color: '#303E4E',
        fontSize: '14px',
    },
    allowButton: {
        width: '68px',
        height: '32px',
        backgroundColor: '#5571C6',
        textAlign: 'center',
        color: '#fff',
        position: 'relative',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
    },
    iconLoading: {
        position: 'absolute',
        top: 8,
        left: 4,
    },
    endButton: {
        width: '68px',
        height: '32px',
        backgroundColor: '#E81212',
        textAlign: 'center',
        color: '#fff',
        borderRadius: 6,
        fontWeight: 400,
        fontSize: 14,
    },
    inprogressColor: {
        backgroundColor: '#C21807',
    },
    completedColor: {
        backgroundColor: 'blue',
    },
    inWaitingRoomColor: {
        backgroundColor: 'green',
    },
    cancelledColor: {
        backgroundColor: 'gray',
    },
    noShowColor: {
        backgroundColor: 'orange',
    },
    isLateColor: {
        backgroundColor: '#81007F',
    },
    assignedToProviderColor: {
        backgroundColor: '#98FB98',
    },
    isKickedColor: {
        backgroundColor: '#4B3619',
    },
    waitingAbnomalDisconnected: {
        backgroundColor: '#808588',
    },
    examAbnomalDisconnected: {
        backgroundColor: '#808588',
    },
})

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement="top-end" />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#fff',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '5px 16px',
        backgroundColor: '#fff',
        color: '#242424',
        boxShadow: '0px 4px 8px 0px #00000024',
        fontSize: 12,
    },
}))

const EncounterList = (props) => {
    const dispatch = useDispatch()
    const dateFilterEq = useSelector((state) => state.provider.encounterQueueListDate)
    const encounterRoom = useSelector((state) => state.provider.encounterRoom)
    const { room, leaveRoom } = useRoom()
    const history = useHistory()
    const notification = useNotification()
    const me = useSelector((state) => state.login.userData)
    let { encounterQueueList } = props
    const providerId = localStorage.getItem('userId')
    const link = `${config.dev.baseUrlSocket}?type=practitioner????${providerId}`
    const socketPage = ['/schedule', '/consultation']
    const [encounterQueueListData, setencounterQueueList] = useState([...encounterQueueList])

    const isPageVisible = usePageVisibility()
    const timerIdRef = useRef([])
    const [isPollingEnabled, setIsPollingEnabled] = useState(true)
    const [loadingApi, setLoadingApi] = useState(false)
    const [encounterId, setEncounterId] = useState(null)

    const { locationTimezone } = useTimezone()

    // const isConsultant = useSelector((state) => state?.provider?.objectEncouterStart)
    const telehealthScreen = useSelector((state) => state.telehealthScreen)

    const lastMessage = null
    const connectionStatus = 'Uninstantiated'
    const [firstTime, setFirstTime] = useState(true)

    const { saveAndCloseDialog, allowError } = useSelector((state) => state.clinicalNotes)

    useEffect(()=>{
        setFirstTime(false)
    },[])
    const debouncedRefetchEncountersRef = useRef(debounce(() => props.refetchEncounters(), 2000))
    const [isActiveTab, setIsActiveTab] = useState(true);
    const intervalRef = useRef(null);
    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            setIsActiveTab(true);
        } else {
            setIsActiveTab(false);
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // We use the firstTime state to avoid triggering certain actions during the initial render of the page.
        if(isActiveTab && !firstTime){
            props.refetchEncounters()
        }
        if (isActiveTab && !intervalRef.current) {
            intervalRef.current = setInterval(() => {
                debouncedRefetchEncountersRef.current();
            }, 30000); // 30 seconds
        }
        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, [isActiveTab]);

    const markedProviderGettingLate = async (id, encounterid) => {
        try {
            setLoadingApi(true)
            setEncounterId(encounterid)
            const res = await providerRunninglate(id)
            if (res) {
                props.refetchEncounters({ forceUpdate: true })
            }
            setTimeout(() => {
                setLoadingApi(false)
                setEncounterId(null)
            }, 2000)
        } catch (err) {
            setEncounterId(null)
            setLoadingApi(false)
            console.error('Error data:', err)
        }
    }

    const encounterQueueListEncouter = useSelector((state) => state.provider.encounterQueueList)

    useEffect(() => {
        console.log('71=>last Message: ', lastMessage)
        if (!isEmpty(lastMessage) && lastMessage?.data !== 'check') {
            const messageSocket = JSON.parse(lastMessage?.data)
            dispatch(setLastMessage(messageSocket))
            switch (messageSocket.action) {
                case 'waiting_room_member':
                    dispatch(updateStatusParticipant(messageSocket, encounterQueueListEncouter))
                    dispatch(updateStatusEncounterQueueList(messageSocket))
                    break
                case 'exam_room_member':
                case 'waiting_room_disconnected':
                case 'exam_room_disconnected':
                case 'encounter_status':
                    dispatch(updateStatusEncounterQueueList(messageSocket))
                    break
                case 'encounter_add':
                    dispatch(addEncounterQueueList(messageSocket.data))
                    break
                case 'appointment_status':
                    dispatch(updateEncounterAppointmentStatus(messageSocket))
                    break
                case 'encounter_remove':
                    dispatch(removeEncounterQueueList(messageSocket))
                    break
                case 'encounter_update':
                    dispatch(updateEncounterQueueList(messageSocket))
                    break
                default:
                    break
            }
        }
    }, [lastMessage?.data])
    if (encounterQueueList !== undefined) {
        encounterQueueList = encounterQueueList.map((encounter) => {
            // CARD STATUS

            // If the patient left the call because of disconnection. Set card status to ''.
            if (
                encounter.status === ENCOUNTER_STATUS.NOT_START ||
                (encounter?.patient_status === PATIENT_STATUS.DISCONNECT_WAITING_ROOM &&
                    encounter.status === ENCOUNTER_STATUS.EXAM_WAITING)
            ) {
                encounter.CARD_STATUS = PATIENT_STATUS.NOT_START
            } else if (
                (encounter.status === ENCOUNTER_STATUS.PRE_EXAM_WAITING &&
                    encounter.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
                (encounter.status === ENCOUNTER_STATUS.EXAM_WAITING &&
                    encounter.patient_status === PATIENT_STATUS.IN_WAITING_ROOM)
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
            } else if (
                encounter.status === ENCOUNTER_STATUS.NO_SHOW &&
                encounter.patient_status === PATIENT_STATUS.NO_SHOW
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW
            } else if (
                encounter.status === ENCOUNTER_STATUS.COMPLETED &&
                encounter.patient_status === PATIENT_STATUS.COMPLETED
            ) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED
            } else {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION
            }

            // refactored status check
            if (encounter.status === ENCOUNTER_STATUS.EXAM_WAITING) {
                encounter.CARD_STATUS = ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
            }

            // // LOGGED IN USER ACTIVE STATUS
            // if ('log_entries' in encounter && encounter.log_entries.length !== 0) {
            //     encounter.log_entries.forEach((logInfo) => {
            //         if (logInfo.created_by === me['custom:user_id']) {
            //             encounter.USER_ACTIVE_STATUS = true
            //             return false
            //         }
            //         encounter.USER_ACTIVE_STATUS = false
            //         return true
            //     })
            // } else {
            //     encounter.USER_ACTIVE_STATUS = false
            // }
            // if (encounter.USER_ACTIVE_STATUS) {
            //     setUserLoggedInEncounter(encounter.encounter_id)
            // }
            return encounter
        })
    }
    const [loading, setLoading] = useState(false)
    const encounterMeetingId = useSelector((state) => state.provider.encounterMeetingId)
    const selectedEncounter = useSelector((state) => state.provider.selectedEncounter)
    const shedulePermission = useSelector((state) => state.permission?.scheduling)
    // console.log('selectedEncounter', selectedEncounter)
    const openConsultationPage = (item) => {
        let method = (id) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({})
                }, 800)
            })
        }

        if (
            item.status === ENCOUNTER_STATUS.EXAM_WAITING &&
            item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM
        ) {
            method = examAllowPatientJoinApi
        }

        setLoading(true)
        dispatch(setSelectedEncounter(item))
        dispatch(setEncounterRoom(null))

        method(item.encounter_id)
            .then(() => {
                dispatch(setEncounterMeetingId(item.encounter_id))
                dispatch(handleGetEncounterQueueList())
                dispatch({
                    type: SET_TELEHEALTH_SCREEN,
                    active: true,
                    tabName: TAB_CLINICALNOTE,
                })
                // history.push('/consultation')
                if (item?.clinical_note_id !== '') history.push('/consultation')
                else history.push('/schedule')
            })
            .catch((error) => {
                dispatch(setAllowError(true))
            })
            .finally(() => {
                setLoading(false)
                // dispatch(setSelectedEncounter(null))
            })
    }
    const openConsultaionPageWioutVideoCall = (item) => {
        try {
            setLoading(true)
            dispatch(setSelectedEncounter(item))
            dispatch(saveObjectEncouterStart(item))
            dispatch(setEncounterMeetingId(item.encounter_id))
            const payload = {
                id: item.encounter_id,
                status: ENCOUNTER_STATUS.EXAM,
                patient_status: PATIENT_STATUS.EXAM,
            }
            dispatch(handleChangeEncounterStatus(payload))
        } catch (error) {
            dispatch(setAllowError(true))
        } finally {
            setLoading(false)
            if (item?.clinical_note_id !== '') {
                history.push(Routes.WORK_QUEUES.CLINICAL_NOTES.NEW_NOTE, {
                    id: item?.clinical_note_id,
                })
            } else history.push(Routes.SCHEDULE)
        }
    }

    const classes = useStyles()
    const cx = classNames.bind(classes)

    const Placeholder = ({ children }) => {
        // const classes = usePlaceholderStyles()
        return <div className={classes.placeholder}>{children}</div>
    }

    const encounterUserActiveStatus = () => {}
    const renderText = (status) => {
        switch (status) {
            case ENCOUNTER_STATUS.EXAM_WAITING:
                return 'Start'
            case ENCOUNTER_STATUS.EXAM:
                return 'Join'
            default:
                return 'Start'
        }
    }

    const renderEncounterQueuePatientStatus = (status) => {
        // console.log('316=>render value function',status)
        if (status === '' || !status) {
            return <Placeholder> ----- </Placeholder>
        }
        return ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.find((element) => element.value === status)
            ?.label
    }

    const canShowEncounter = (encounter, user) => {
        if (encounter.status === ENCOUNTER_STATUS.COMPLETED) {
            return false
        }
        // console.log('encounter details', encounter)
        const cx = isShowStartBtn(encounter)
        return cx
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [encounterListItem, setEncounterListItem] = useState({})

    const handleOpenAlertPopup = (event, item, index) => {
        const selectElement = document.getElementById(`${index}`)
        setAnchorEl(selectElement)
        setEncounterListItem(item)
    }

    const handleCloseAlertPopup = () => {
        setAnchorEl(null)
        setEncounterListItem({})
    }

    const handleCloseAlertPopupCustom = (closeEvent = '') => {
        handleEncounterCardStatus(encounterListItem, 'NO_SHOW', closeEvent)
        handleCloseAlertPopup()
    }

    // const setStatusLoadingFalse = () => setStatusLoading(false)
    // const setStatusLoadingTrue = () => setStatusLoading(true)

    const onSuccess = () => {
        postStatusChangeRequest('noshow', encounterListItem.appointment_id, null, locationTimezone)
        handleCloseAlertPopupCustom('success')
    }

    const handleEncounterCardStatus = (encounter, status, closeEvent = '') => {
        setTimeout(() => {
            props?.refetchSchedule && props?.refetchSchedule(dateFilterEq)
        }, 10000)
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.EXAM_WAITING,
                patient_status: PATIENT_STATUS.IN_WAITING_ROOM,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.NO_SHOW,
                patient_status: PATIENT_STATUS.NO_SHOW,
                is_delete_note: closeEvent === 'success' ? true : false,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(
                // handleChangeEncounterStatus(payload, setStatusLoadingTrue, setStatusLoadingFalse),
                handleChangeEncounterStatus(payload),
            )
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.NOT_START) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.NOT_START,
                patient_status: PATIENT_STATUS.NOT_START,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
        if (status === ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED) {
            const payload = {
                id: encounter.encounter_id,
                status: ENCOUNTER_STATUS.COMPLETED,
                patient_status: PATIENT_STATUS.COMPLETED,
            }
            notification("Don't refresh the page while the status is being changed", 'warning')
            dispatch(handleChangeEncounterStatus(payload))
        }
    }

    const handleStartEncounter = (encounter) => {
        if (encounter.appointment_type !== 'Office Visit') {
            dispatch({
                type: SET_ENCOUNTER,
                encounter_id: encounter.encounter_id,
            })
            handleEncounterCardStatus(encounter, ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION)
            openConsultationPage(encounter)
        } else {
            dispatch({
                type: SET_ENCOUNTER,
                encounter_id: encounter.encounter_id,
            })
            handleEncounterCardStatus(encounter, ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION)
            openConsultaionPageWioutVideoCall(encounter)
        }
    }

    const handleJoinEncounter = (encounter) => {
        handleStartEncounter(encounter)
    }

    const endMeeting = async (item, cb) => {
        setLoading('end')
        dispatch(setSelectedEncounter(item))
        const sessionData = {
            participants: encounterRoom?.participants,
            roomId: encounterRoom?.roomId,
        }
        examEndApi(item?.encounter_id, sessionData)
            .then((response) => {
                if (response) {
                    handleEndClose()
                    dispatch(setEncounterMeetingId(null))
                    dispatch(setEncounterRoom(null))
                    setLoading('')
                    dispatch(setSelectedEncounter(null))
                    dispatch(
                        handleVisibleSaveAndClose({
                            [item?.encounter_id]: true,
                        }),
                    )
                    dispatch(
                        handleOpenSaveAndCloseDialog({
                            [item?.encounter_id]: false,
                        }),
                    )
                    dispatch(handleSetCurrentEncounter({}))
                    dispatch(setVonageProviderSession({}))
                }
            })
            .catch((excp) => {
                console.log(excp)
            })
            .finally(async () => {
                cb && cb()
            })
    }

    const quit = (item) => {
        setLoading('quit')
        dispatch(setSelectedEncounter(item))
        encounterRoom?.leave()
        setEncounterRoom(null)
        setLoading('')
        dispatch(setSelectedEncounter(null))
        // history.push('/schedule')
        window.location.assign('/schedule')
    }

    const handleEndMeeting = (encounter, cb) => {
        endMeeting(encounter, cb)
        dispatch({
            type: SET_TELEHEALTH_SCREEN,
            active: false,
            tabName: '',
        })
    }

    const isEndBtn = (item) => {
        // return [ENCOUNTER_STATUS.EXAM, ENCOUNTER_STATUS.POST_EXAM, ENCOUNTER_STATUS.PRE_EXAM].includes(item.status) &&
        return !item.encounter_id === encounterMeetingId
    }

    const isQuitBtn = (item) => {
        // return [ENCOUNTER_STATUS.EXAM].includes(item.status) &&
        // [PATIENT_STATUS.PRE_EXAM, PATIENT_STATUS.IN_WAITING_ROOM].includes(item.patient_status)
        // && (item.encounter_id === encounterMeetingId)
        return item.encounter_id === encounterMeetingId
    }

    const handleDisableStatusForEndEncounter = (encounter) => {
        if (encounter.appointment_type === 'Office') {
            return true
        }
        if (me.role_code === 'medical_provider') {
            return true
        }
        return true
    }

    const isShowStartBtn = (item) => {
        return (
            (item.status === ENCOUNTER_STATUS.EXAM_WAITING &&
                item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
            (item.status === ENCOUNTER_STATUS.EXAM &&
                item.patient_status === PATIENT_STATUS.IN_WAITING_ROOM) ||
            (item.status === ENCOUNTER_STATUS.EXAM && item.patient_status === PATIENT_STATUS.EXAM)
        )
    }

    const handleEndEncounter = (encounter) => {}

    const handleLeaveEncounter = (encounter) => {
        quit(encounter)
        dispatch({
            type: SET_TELEHEALTH_SCREEN,
            active: false,
            tabName: '',
        })
    }

    const [anchorEndEl, setAnchorEndEl] = React.useState(null)

    const [encounterToDelete, setEncounterToDelete] = useState(null)

    const handleEndClick = (event, item) => {
        console.log(event.currentTarget)
        console.log(item)
        setEncounterToDelete(item)
        setAnchorEndEl(event.currentTarget)
    }

    const handleEndClose = () => {
        setAnchorEndEl(null)
    }

    const openEnd = Boolean(anchorEndEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined
    // if (statusLoading) {
    //     notification("Don't refresh the page while the status is being changed", 'warning')
    // }

    const timestampDecreser = (timestamp) => {
        const genTimestamp = `${timestamp}:00`
        let [minutes, seconds] = genTimestamp.split(':').map(Number)
        if (seconds === 0) {
            minutes = minutes - 1
            seconds = 59
        } else {
            seconds = seconds - 1
        }

        const totalminutes = minutes + seconds / 60

        return totalminutes
    }

    useEffect(() => {
        localforage.config({
            driver: localforage.INDEXEDDB, // Use IndexedDB as the storage driver
            name: 'myApp', // Name of the database
            version: 1, // Database version
            storeName: 'InsessionEncounter', // Name of the object store
        })

        const addDataToIndexedDB = async (key, value) => {
            try {
                await localforage.setItem(key, value)
            } catch (error) {
                console.error('Error adding data:', error)
            }
        }

        const getDataFromIndexedDB = async (key) => {
            try {
                const data = await localforage.getItem(key)
                return data
            } catch (error) {
                console.error('Error retrieving data:', error)
            }
        }

        const deleteAllDataFromIndexedDB = async () => {
            try {
                await localforage.clear()
            } catch (error) {
                console.error('Error deleting all data:', error)
            }
        }

        const deleteDataFromIndexedDB = async (key) => {
            try {
                await localforage.removeItem(key)
            } catch (error) {
                console.error('Error deleting data:', error)
            }
        }
        let intervalId

        intervalId = setInterval(async () => {
            const startedCall = []
            const cloned = [...encounterQueueList]

            const data = cloned.map((item, idx) => {
                if (item.CARD_STATUS === 'IN_SESSION') {
                    const setterItem = {
                        ...item,
                        duration: timestampDecreser((moment(item.end).valueOf() - Date.now()) / 60000),
                    }
                    item = setterItem
                }
                return item
            })

            if (data.length) {
                await deleteDataFromIndexedDB('InsessionEncounter')
                await addDataToIndexedDB('InsessionEncounter', JSON.stringify(data))

                setencounterQueueList(data)
            }
        }, 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [encounterQueueList])

    const formatTime = (decimalMinutes) => {
        if (decimalMinutes) {
            const min = Math.abs(decimalMinutes)
            const fractionalPart = min - Math.floor(min)
            const seconds = fractionalPart < 0.99 ? Math.round(fractionalPart * 60) : 0 // Convert fraction to seconds and round
            const minutes = fractionalPart < 0.99 ? Math.floor(min) : Math.floor(min) + 1
            const formattedMinutes = minutes.toString().padStart(2, '0')
            const formattedSeconds = seconds.toString().padStart(2, '0')

            return `${formattedMinutes}:${formattedSeconds}`
        }
        return '00:00'
    }

    return (
        <Grid
            alignItems="center"
            justifyContent="center"
            className={classes.encounterListWrapper}
            container>
            <div className={classes.wrapList}>
                <List>
                    {encounterQueueListData &&
                        encounterQueueListData
                            .sort((encounter1, encounter2) => {
                                return moment(encounter1.start) - moment(encounter2.start)
                            })
                            .map((item, index) => {
                                return (
                                    <>
                                        <ListItem
                                            key={index}
                                            id={`${index}`}
                                            className={classes.listItem}>
                                            <div
                                                className="list_holder"
                                                key={`list_holder_${index}`}>
                                                <ListItemAvatar
                                                    className={classes.avatarWrap}
                                                    key={`avatarWrap_${index}`}>
                                                    <div key={`avatarWrap_indiv_${index}`}>
                                                        <Avatar
                                                            variant="square"
                                                            className={classes.avatar}
                                                            key={`avatar_wrap_1_${index}`}></Avatar>
                                                        {/* <StatusBadge
                                                            appointmentStatusId={
                                                                item.appointment_status_id
                                                            }
                                                            appointmentId={item.appointment_id}
                                                        /> */}
                                                        <div
                                                            className={`status_dot ${
                                                                item.CARD_STATUS ===
                                                                ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED
                                                                    ? 'arrived'
                                                                    : item.CARD_STATUS ===
                                                                      ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION
                                                                    ? 'session'
                                                                    : item.CARD_STATUS ===
                                                                      ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW
                                                                    ? 'no_show'
                                                                    : item.CARD_STATUS ===
                                                                      ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED
                                                                    ? 'complete'
                                                                    : ''
                                                            }`}
                                                            key={`status_dot_1_${index}`}>
                                                            {item.CARD_STATUS ===
                                                            ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED ? (
                                                                <img src={MapIcon}></img>
                                                            ) : item.CARD_STATUS ===
                                                              ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION ? (
                                                                <span>&#8722;</span>
                                                            ) : item.CARD_STATUS ===
                                                              ENCOUNTER_QUEUE_PATIENT_STATUS.NO_SHOW ? (
                                                                <span>&#10005;</span>
                                                            ) : item.CARD_STATUS ===
                                                              ENCOUNTER_QUEUE_PATIENT_STATUS.COMPLETED ? (
                                                                <img src={TargetIcon}></img>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </div>
                                                </ListItemAvatar>
                                                {item.CARD_STATUS ===
                                                    ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '7px',
                                                            left: '15px',
                                                            fontSize: 'small',
                                                            color: `${
                                                                item.duration > 0
                                                                    ? '#15A452'
                                                                    : '#E81A1A'
                                                            }`,
                                                        }}
                                                        key={`in_session_time_div_${index}`}>
                                                        {formatTime(item.duration)}
                                                    </div>
                                                )}
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: '2px',
                                                        left: '23px',
                                                        fontSize: 'small',
                                                    }}>
                                                    <HtmlTooltip
                                                        arrow
                                                        title="Tell patient provider is running behind"
                                                        placement="bottom-start">
                                                        <div style={{ position: 'relative' }}>
                                                            {(item?.CARD_STATUS === 'NOT_START' ||
                                                                item?.CARD_STATUS === 'ARRIVED') &&
                                                                !Boolean(
                                                                    item?.is_provider_late,
                                                                ) && (
                                                                    <img
                                                                        src={ProviderLate}
                                                                        alt=""
                                                                        onClick={() => {
                                                                            markedProviderGettingLate(
                                                                                item?.appointment_id,
                                                                                item.encounter_id,
                                                                            )
                                                                        }}
                                                                        style={{ height: '19px' }}
                                                                    />
                                                                )}
                                                            {loadingApi &&
                                                                encounterId ===
                                                                    item.encounter_id && (
                                                                    <SpinnerIcon
                                                                        style={{
                                                                            position: 'absolute',
                                                                            bottom: '2px',
                                                                            left: '-1px',
                                                                        }}
                                                                    />
                                                                )}
                                                        </div>
                                                    </HtmlTooltip>
                                                    {(item?.CARD_STATUS === 'NOT_START' ||
                                                        item?.CARD_STATUS === 'ARRIVED') &&
                                                        Boolean(item?.is_provider_late) && (
                                                            <img
                                                                src={ProviderLateActive}
                                                                alt=""
                                                                style={{ height: '19px' }}
                                                            />
                                                        )}
                                                </div>

                                                <div
                                                    className={classes.infoWrap}
                                                    key={`div_infoWrap_1_${index}`}>
                                                    <Typography
                                                        className={classes.name}
                                                        key={`enc_typo_1_${index}`}>{`${
                                                        item.patient?.first_name || ''
                                                    } ${
                                                        item.patient?.last_name || ''
                                                    }`}</Typography>
                                                    <div className="inline_line_holder">
                                                        <Typography
                                                            className={classes.time}
                                                            key={`enc_typo_2_${index}`}
                                                            style={{ paddingRight: '3px' }}>
                                                            {moment(item.start).format('hh:mm A')}
                                                        </Typography>
                                                        <div
                                                            className="onboard_status"
                                                            key={`div_onboard_status_1_${index}`}>
                                                            <p
                                                                className="each_holder"
                                                                key={`onbstatus_each_holder_1_${index}`}>
                                                                {item.practitioner_last_name && (
                                                                    <>
                                                                        <img
                                                                            src={ProviderIcon}
                                                                            height="12px"
                                                                            width="11px"
                                                                        />
                                                                        {item.practitioner_last_name
                                                                            .length < 12 && (
                                                                            <span className="name">
                                                                                {
                                                                                    item.practitioner_last_name
                                                                                }
                                                                            </span>
                                                                        )}
                                                                        {item.practitioner_last_name
                                                                            .length > 12 && (
                                                                            <HtmlTooltip
                                                                                title={`${item.practitioner_first_name} ${item.practitioner_last_name} `}
                                                                                arrow
                                                                                placement="top-start"
                                                                                style={{
                                                                                    background:
                                                                                        'white !important',
                                                                                }}>
                                                                                <span className="name">
                                                                                    {shortenString(
                                                                                        item.practitioner_last_name,
                                                                                        10,
                                                                                    )}
                                                                                </span>
                                                                            </HtmlTooltip>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="inline_line_holder"
                                                        key={`inline_line_holder_1_${index}`}>
                                                        <Select
                                                            id="request-type"
                                                            className="ba-input-fields select_without_pd"
                                                            name={'request-type'}
                                                            disableUnderline
                                                            placeholder={'status'}
                                                            value={renderEncounterQueuePatientStatus(
                                                                item.CARD_STATUS,
                                                            )}
                                                            displayEmpty
                                                            disabled={
                                                                shedulePermission.includes(
                                                                    'modify_encounter_status',
                                                                )
                                                                    ? item.CARD_STATUS === 'NO_SHOW'
                                                                        ? true
                                                                        : false
                                                                    : true
                                                            }
                                                            renderValue={() => {
                                                                return item.CARD_STATUS ? (
                                                                    renderEncounterQueuePatientStatus(
                                                                        item.CARD_STATUS,
                                                                    )
                                                                ) : (
                                                                    <Placeholder>
                                                                        {' '}
                                                                        -----{' '}
                                                                    </Placeholder>
                                                                )
                                                            }}
                                                            // value={renderEncounterQueuePatientStatus(item.CARD_STATUS)}
                                                            onChange={(event) => {
                                                                event.target.value === 'NO_SHOW'
                                                                    ? handleOpenAlertPopup(
                                                                          event,
                                                                          item,
                                                                          index,
                                                                      )
                                                                    : item?.appointment_type !==
                                                                      'Telehealth Visit'
                                                                    ? handleEncounterCardStatus(
                                                                          item,
                                                                          event.target.value,
                                                                      )
                                                                    : ''
                                                            }}
                                                            key={`request_type_select_1_${index}`}>
                                                            {ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.map(
                                                                (el) => {
                                                                    if (
                                                                        item?.appointment_type ===
                                                                        'Telehealth Visit'
                                                                    ) {
                                                                        // if (
                                                                        //     el?.label !==
                                                                        //     'In Session' &&
                                                                        //     el?.label !== 'Arrived'
                                                                        // )
                                                                        return (
                                                                            <MenuItem
                                                                                value={el.value}
                                                                                key={el.value}
                                                                                className="pl-select-option icon_list">
                                                                                <div
                                                                                    className={`status_holder ${
                                                                                        el?.label ===
                                                                                        'Arrived'
                                                                                            ? 'arrived'
                                                                                            : el?.label ===
                                                                                              'In Session'
                                                                                            ? 'session'
                                                                                            : el?.label ===
                                                                                              'No Show'
                                                                                            ? 'no_show'
                                                                                            : el?.label ===
                                                                                              '------'
                                                                                            ? 'no_start'
                                                                                            : el?.label ===
                                                                                              'Complete'
                                                                                            ? 'complete'
                                                                                            : ''
                                                                                    }`}>
                                                                                    {el?.label ===
                                                                                    'Arrived' ? (
                                                                                        <img
                                                                                            src={
                                                                                                MapIcon
                                                                                            }></img>
                                                                                    ) : el?.label ===
                                                                                      'In Session' ? (
                                                                                        <span>
                                                                                            &#8722;
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      'No Show' ? (
                                                                                        <span>
                                                                                            &#10005;
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      '------' ? (
                                                                                        <span>
                                                                                            --------
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      'Complete' ? (
                                                                                        <img
                                                                                            src={
                                                                                                TargetIcon
                                                                                            }></img>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                                {el?.label !==
                                                                                    '------' &&
                                                                                    el?.label}
                                                                            </MenuItem>
                                                                        )
                                                                    }
                                                                    if (el?.label !== 'In Session')
                                                                        return (
                                                                            <MenuItem
                                                                                value={el.value}
                                                                                key={el.value}
                                                                                className="pl-select-option icon_list">
                                                                                <div
                                                                                    className={`status_holder ${
                                                                                        el?.label ===
                                                                                        'Arrived'
                                                                                            ? 'arrived'
                                                                                            : el?.label ===
                                                                                              'In Session'
                                                                                            ? 'session'
                                                                                            : el?.label ===
                                                                                              'No Show'
                                                                                            ? 'no_show'
                                                                                            : el?.label ===
                                                                                              '------'
                                                                                            ? 'no_start'
                                                                                            : el?.label ===
                                                                                              'Complete'
                                                                                            ? 'complete'
                                                                                            : ''
                                                                                    }`}>
                                                                                    {el?.label ===
                                                                                    'Arrived' ? (
                                                                                        <img
                                                                                            src={
                                                                                                MapIcon
                                                                                            }></img>
                                                                                    ) : el?.label ===
                                                                                      'In Session' ? (
                                                                                        <span>
                                                                                            &#8722;
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      'No Show' ? (
                                                                                        <span>
                                                                                            &#10005;
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      '------' ? (
                                                                                        <span>
                                                                                            --------
                                                                                        </span>
                                                                                    ) : el?.label ===
                                                                                      'Complete' ? (
                                                                                        <img
                                                                                            src={
                                                                                                TargetIcon
                                                                                            }></img>
                                                                                    ) : (
                                                                                        ''
                                                                                    )}
                                                                                </div>
                                                                                {el?.label !==
                                                                                    '------' &&
                                                                                    el?.label}
                                                                            </MenuItem>
                                                                        )
                                                                },
                                                            )}
                                                        </Select>

                                                        <div
                                                            className="onboard_status"
                                                            key={`div_onboard_status_1_${index}`}>
                                                            {/* <p className="each_holder" key={`onbstatus_each_holder_1_${index}`}>
                                                                {item.practitioner_last_name && (
                                                                    <>
                                                                        <img
                                                                            src={ProviderIcon}
                                                                            height="12px"
                                                                            width="11px"
                                                                        />
                                                                        {item.practitioner_last_name
                                                                            .length < 12 && (
                                                                                <span className="name">
                                                                                    {
                                                                                        item.practitioner_last_name
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        {item.practitioner_last_name
                                                                            .length > 12 && (
                                                                                <HtmlTooltip
                                                                                    title={`${item.practitioner_first_name} ${item.practitioner_last_name} `}
                                                                                    arrow
                                                                                    placement="top-start"
                                                                                    style={{
                                                                                        background:
                                                                                            'white !important',
                                                                                    }}>
                                                                                    <span className="name">
                                                                                        {shortenString(
                                                                                            item.practitioner_last_name,
                                                                                            10,
                                                                                        )}
                                                                                    </span>
                                                                                </HtmlTooltip>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </p> */}
                                                            <p
                                                                className="each_holder"
                                                                key={`onbstatus_each_holder_2_${index}`}>
                                                                {item.appointment_type ===
                                                                    'Telehealth Visit' && (
                                                                    <>
                                                                        <img
                                                                            src={
                                                                                TeleHealthIcon
                                                                            }></img>
                                                                        <span className="name">
                                                                            Telehealth
                                                                        </span>
                                                                    </>
                                                                )}
                                                                {item.appointment_type ===
                                                                    'Office Visit' && (
                                                                    <>
                                                                        <img
                                                                            src={
                                                                                BuildingIcon
                                                                            }></img>
                                                                        <span className="name">
                                                                            In Office
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="icon_holder"
                                                key={`div_icon_holder_1_${index}`}>
                                                {item.CARD_STATUS ===
                                                    ENCOUNTER_QUEUE_PATIENT_STATUS.ARRIVED && (
                                                    <LightTooltip
                                                        title={
                                                            !telehealthScreen.active
                                                                ? 'Start encounter'
                                                                : 'Please leave the current session to join'
                                                        }
                                                        key={`enc_tooltip_1_${index}`}>
                                                        <div
                                                            className="each_icon_holder play"
                                                            key={`enc_icon_holder_1_${index}`}>
                                                            <ButtonSpinnerEncounter
                                                                label={
                                                                    <>
                                                                        <img src={PlayIcon}></img>
                                                                    </>
                                                                }
                                                                className="each_icon_holder play"
                                                                onclick={() => {
                                                                    if (
                                                                        Object.keys(
                                                                            saveAndCloseDialog,
                                                                        )[0] !== '0'
                                                                    ) {
                                                                        dispatch(
                                                                            handleOpenSaveAndCloseDialog(
                                                                                {
                                                                                    [Object.keys(
                                                                                        saveAndCloseDialog,
                                                                                    )[0]]: true,
                                                                                },
                                                                            ),
                                                                        )
                                                                        dispatch(
                                                                            handleSetCurrentEncounter(
                                                                                item,
                                                                            ),
                                                                        )
                                                                    } else {
                                                                        handleStartEncounter(item)
                                                                    }
                                                                    setTimeout(
                                                                        () =>
                                                                            props.refetchEncounters(
                                                                                {
                                                                                    forceUpdate: true,
                                                                                },
                                                                            ),
                                                                        1000,
                                                                    )
                                                                }}
                                                                disabled={
                                                                    !shedulePermission.includes(
                                                                        'start_encounter',
                                                                    ) || telehealthScreen.active
                                                                }
                                                                isLoading={
                                                                    loading &&
                                                                    selectedEncounter &&
                                                                    selectedEncounter.encounter_id ===
                                                                        item.encounter_id
                                                                }
                                                                key={`enc_spinner_1_${index}`}
                                                            />
                                                        </div>
                                                    </LightTooltip>
                                                )}
                                                {item.CARD_STATUS ===
                                                    ENCOUNTER_QUEUE_PATIENT_STATUS.IN_SESSION && (
                                                    <>
                                                        {selectedEncounter === null &&
                                                            item.appointment_type ===
                                                                'Telehealth Visit' && (
                                                                <LightTooltip
                                                                    title="Join encounter"
                                                                    key={`enc_tooltip_2_${index}`}>
                                                                    <div
                                                                        className="each_icon_holder join"
                                                                        key={`enc_icon_holder_2_${index}`}>
                                                                        <Button
                                                                            className="each_icon_holder join"
                                                                            onClick={() => {
                                                                                handleJoinEncounter(
                                                                                    item,
                                                                                )
                                                                                setTimeout(
                                                                                    () =>
                                                                                        props.refetchEncounters(
                                                                                            {
                                                                                                forceUpdate: true,
                                                                                            },
                                                                                        ),
                                                                                    1000,
                                                                                )
                                                                            }}
                                                                            key={`enc_joiner_1_${index}`}
                                                                            disabled={
                                                                                item.appointment_type ===
                                                                                    'Office Visit' ||
                                                                                !shedulePermission.includes(
                                                                                    'join_encounter',
                                                                                ) ||
                                                                                telehealthScreen.active
                                                                            }>
                                                                            <img
                                                                                src={JoinEncIcon}
                                                                            />
                                                                        </Button>
                                                                    </div>
                                                                </LightTooltip>
                                                            )}

                                                        {selectedEncounter &&
                                                            selectedEncounter.encounter_id ===
                                                                item.encounter_id &&
                                                            item?.appointment_type ===
                                                                'Telehealth Visit' && (
                                                                <>
                                                                    <LightTooltip
                                                                        title="Leave encounter"
                                                                        key={`enc_tooltip_3_${index}`}>
                                                                        <div
                                                                            className="each_icon_holder leave"
                                                                            key={`enc_icon_holder_3_${index}`}>
                                                                            <Button
                                                                                className="each_icon_holder leave"
                                                                                onClick={() => {
                                                                                    handleLeaveEncounter(
                                                                                        item,
                                                                                    )
                                                                                    setTimeout(
                                                                                        () =>
                                                                                            props.refetchEncounters(
                                                                                                {
                                                                                                    forceUpdate: true,
                                                                                                },
                                                                                            ),
                                                                                        1000,
                                                                                    )
                                                                                }}
                                                                                key={`enc_btn_leave_1_${index}`}
                                                                                disabled={
                                                                                    (item.appointment_type ===
                                                                                        'Office Visit' &&
                                                                                        isQuitBtn(
                                                                                            item,
                                                                                        )) ||
                                                                                    !shedulePermission.includes(
                                                                                        'leave_encounter',
                                                                                    )
                                                                                }>
                                                                                <img
                                                                                    src={
                                                                                        LeaveEncIcon
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    </LightTooltip>
                                                                </>
                                                            )}

                                                        {/* {selectedEncounter &&
                                                                selectedEncounter.encounter_id ===
                                                                item.encounter_id && ( */}
                                                        {((item?.appointment_status ===
                                                            'checked-in' &&
                                                            item.appointment_type ===
                                                                'Telehealth Visit') ||
                                                            (item?.status === 'EXAM' &&
                                                                item.appointment_type ===
                                                                    'Office Visit')) && (
                                                            <>
                                                                <LightTooltip
                                                                    title="End encounter"
                                                                    key={`enc_tooltip_4_${index}`}>
                                                                    <div
                                                                        className="each_icon_holder end"
                                                                        key={`enc_icon_holder_4_${index}`}>
                                                                        <Button
                                                                            className="each_icon_holder end"
                                                                            key={`enc_btn_end_1_${index}`}
                                                                            disabled={
                                                                                (selectedEncounter &&
                                                                                    selectedEncounter.encounter_id ===
                                                                                        item.encounter_id &&
                                                                                    isEndBtn(
                                                                                        item,
                                                                                    )) ||
                                                                                !shedulePermission.includes(
                                                                                    'end_encounter',
                                                                                )
                                                                            }
                                                                            onClick={(e) =>
                                                                                handleEndClick(
                                                                                    e,
                                                                                    item,
                                                                                )
                                                                            }>
                                                                            <img
                                                                                src={
                                                                                    EndEncIcon
                                                                                }></img>
                                                                        </Button>
                                                                    </div>
                                                                </LightTooltip>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>

                                            {(!item?.clinical_note_id ||
                                                item?.clinical_note_id === '') && (
                                                <div style={{ position: 'relative' }}>
                                                    <span
                                                        style={{
                                                            position: 'absolute',
                                                            top: 30,
                                                            left: '-16px',
                                                        }}>
                                                        <NoClinicalNoteIcon
                                                            style={{ height: 15, width: 15 }}
                                                        />
                                                    </span>
                                                </div>
                                            )}
                                        </ListItem>
                                    </>
                                )
                            })}
                </List>
            </div>
            <ErrorDialog open={allowError} handleClose={() => dispatch(setAllowError(false))} />
            <Popover
                id={idEnd}
                open={openEnd}
                anchorEl={anchorEndEl}
                onClose={handleEndClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="popover_container">
                    <div className="heading_holder">
                        <img src={ErrorIcon}></img>
                        <h2 className="heading">End Encounter</h2>
                    </div>
                    <Typography className="content">
                        Are you sure to end this encounter ?
                    </Typography>
                    <div className="btn_holder">
                        <Button className="no_bg" onClick={handleEndClose}>
                            Cancel
                        </Button>
                        <Button
                            className="error"
                            onClick={() => {
                                handleEndMeeting(encounterToDelete, () =>
                                    props.refetchEncounters({ forceUpdate: true }),
                                )
                            }}>
                            End
                        </Button>
                    </div>
                </div>
            </Popover>
            <CustomAlertPopOver
                anchorEl={anchorEl}
                handleClose={handleCloseAlertPopup}
                disableOutsideClose={true}
                customHandleClose={handleCloseAlertPopupCustom}
                title={'Clinical Note Deletion'}
                bodyText={`Are you sure you want to delete the clinical note associated with this appointment?`}
                confirmButtonName={'Delete'}
                cancelButtonName={'Keep'}
                confirmButtonColor={'red'}
                onSuccess={onSuccess}
            />
        </Grid>
    )
}

export default EncounterList
