import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import { useSelector } from 'react-redux'
import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { useStyles } from '../MedicalProblems/style'
import { useHistory, useParams } from 'react-router-dom'
import PatientInfor from '../PatientInfor'
import { DataGrid } from 'shared/elements/DataGrid'

import { deleteBhList, getBhList } from 'services/Patient/patient'
import { formatDateTime } from 'utilities/formats'
import { EditNewAction } from 'shared/elements/FormItem/EditNewAction'

const BehaviorRateScaleList = ({ patient_Id, fromClinicalNote=false }) => {
    const classes = useStyles()
    const { id } = useParams()
    const isTanentAdmin =
        useSelector((state) => state?.login?.userData)?.role_code === 'tenant_admin'
    const isSuperuser = useSelector((state) => state?.login?.userData)?.role_code === 'super_user'
    const [patientId, setPaitentId] = useState('')
    const [loading, setLoading] = useState(false)
    const [bhList, setBhList] = useState([])
    const [problemsFilterType, setProblemsFilterType] = useState(null)

    const [sortModel, setSortModel] = useState([
        {
            field: 'type',
            sort: 'desc',
        },
    ])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const getBhScaleList = async (start = page) => {
        try {
            setLoading(true)
            const resp = await getBhList(patientId)
            const newdata = () =>
                resp?.data.length > 0 &&
                resp?.data.map((item, index) => ({
                    id: index,
                    ...item,
                }))
            setBhList(newdata)
            setTotal(resp?.data.length)
            setLoading(false)
        } catch (ex) {
            setLoading(false)
            notification(`Cannot get list problem!`, 'error')
        }

        setLoading(false)
    }
    const handleDeleteBhList = async (row) => {
        try {
            const res = await deleteBhList(patientId, row?._id)
            notification('Bh Scale List is deleted successfully !', 'success')
            getBhScaleList()
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const handleViewClick = (row) => {
        if(row.scale_type === 'GAD7'){
            const newPath = Routes.PATIENT.GAD_7_DETAILS.replace(':id', patientId);
            history.push(newPath, { id: row._id ,time: row.created_at,fromClinicalNote});
        }else{
            const newPath = Routes.PATIENT.PHQ_9_DETAILS.replace(':id', patientId);
            history.push(newPath, { id: row._id ,time: row.created_at,fromClinicalNote});
        }
    }
    const handleClickRow = (row) => {
        const newRow= row.row
        if(!fromClinicalNote){
            if(newRow.scale_type === 'GAD7'){
                const newPath = Routes.PATIENT.GAD_7_DETAILS.replace(':id', patientId);
                history.replace(newPath, { id: newRow._id ,time: newRow.created_at});
            }else{
                const newPath = Routes.PATIENT.PHQ_9_DETAILS.replace(':id', patientId);
                history.replace(newPath, { id: newRow._id ,time: newRow.created_at});
            }
        }
    }
    useEffect(() => {
        setPaitentId(id)
    }, [id])
    const columns = [
        {
            headerName: 'Scale Type',
            headerClassName: 'super-app-theme--header',
            field: 'scale_type',
            flex: 1.5,
            sortable: true,
        },
        {
            headerName: 'Upload Date',
            headerClassName: 'super-app-theme--header',
            field: 'upload_date',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return formatDateTime(row?.created_at, 'MM/DD/YYYY')
            },
        },

        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                <EditNewAction
                    menu={[
                        {
                            text: 'View',
                            handler: () => handleViewClick(row),
                            disable: true,
                        },

                        {
                            text: 'Delete',
                            handler: () => handleDeleteBhList(row),
                            disable: isTanentAdmin || isSuperuser,
                        },
                    ]}
                    deleteMessage="Are you sure you want to delete this BH Rate Scale?"
                    deleteMain="Behavior Rate Scale Deletation"
                />
            ),
        },
    ]

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getBhScaleList()
        }

        return () => abortController.abort()
    }, [patientId])

    const history = useHistory()
    const notification = useNotification()

    useEffect(() => {
        if(fromClinicalNote){
            setPaitentId(patient_Id)
        }else{
            setPaitentId(patient_Id || id)
        } 
    }, [patient_Id, id])


    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ padding: '16px 24px' }}>
            {!fromClinicalNote &&
            <Grid
                container
                onClick={() =>
                    history.replace(
                        Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(':id', patientId),
                    )
                }
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
            </Grid>}
            
            <Grid container style={{ marginTop: 12 }}>
               {!fromClinicalNote &&
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Behavior Rate Scale</Typography>
                    </Grid>
                </Grid>}
                {!fromClinicalNote &&
                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>}

                <Grid item xs={12}>
                    <DataGrid
                        // sx={sx.datagrid}
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={bhList}
                        columns={columns}
                        pageSize={limit}
                        rowsPerPageOptions={[10]}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={loading}
                        rowCount={total}
                        pagination
                        disableColumnMenu={true}
                        page={page}
                        onPageChange={(page) => setPage(page)}
                        onRowClick={handleClickRow}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BehaviorRateScaleList
