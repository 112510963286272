import React from 'react'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
)
const PulseOxChart = ({ data, setOpenChart=()=>{}, model='small', total = 60 }) => {
    const chartData = {
        labels: data.map((entry) => entry.date),
        datasets: [
            {
                label: 'Oxygen Saturation',
                borderColor: '#5571C6',
                borderWidth: 1,
                data: data.map((entry) => entry.oxygenSatuaration),
            },
        ],
    }

    /* total days -> step size
        365 -> 100
        90, 30, 60 -> 45, 15, 30
        7 -> 1
        14 -> 2
    */
        const stepSizeX = React.useMemo(() => {
            return total === 365 ? 
                100 : ( total === 7 ? 
                    1 : ( total === 14 ? 
                        2 : (total/2) ) )
        }, [total])

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                title: {
                    display: true,
                    text: 'Time (Days)',
                },
                grid: { display: false },
                // offset: true,
                // min: 0,
                // max: total,
                // stepSize: stepSizeX
            },
            y: {
                title: {
                    display: true,
                    text: 'Pulse Oximetry',
                },

                grid: { display: false },
                // offset: true,
                min: 0,
                max: 100,
                stepSize: 10,
            },
        },
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    boxHeight: 1,
                },
            },
        },
    }

    const smallChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                position: 'center',
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    display: false,
                },
                // min: 0,
                // max: total,
                // stepSize: stepSizeX
            },
            y: {
                display: false,
                position: 'center',
                grid: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    display: false,
                },
                min: 0,
                max: 100,
                stepSize: 10,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0
            },
            line: {
                tension: 0.1,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false
            },
            hover: {
                mode: null
            }
        },
    }

    return (
        <>
            {model === 'small' ?
                <div
                    onClick={() => setOpenChart(true)}
                    style={{
                        height: 100,
                        width: 200,
                        border: '1px solid #e0e0e0',
                        borderRadius: 4,
                    }}>
                    <Line data={chartData} options={smallChartOptions} />
                </div>
                :
                <div style={{ height: 460, width: 1400 }}>
                    <Line data={chartData} options={options} />
                </div>
            }
            
        </>
    )
}

export default PulseOxChart
