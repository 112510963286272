import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DataGrid, GridColumns } from '@mui/x-data-grid'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AddIcon from '@mui/icons-material/Add'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import penIcon from 'assests/icons/pen_icon.svg'
import { Button as ButtonCustom } from 'components/custom'
import { getAllRole } from 'services/Calendar/index'
import { NoRowsOverlayEl } from 'shared/elements'
import { SecurityRoleForm } from './SecurityRoleForm'
import { SecurityRole } from './interfaces'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import { useNotification } from 'shared/elements/Notification'

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    form_list_container: {
        marginTop: 16,
    },
    colorAddNew: {
        color: 'white',
        fontSize: 14,
    },
})

const renderActions = (params, permissions) => {
    const history = useHistory()
    const handleClick = () => {
        history.push('/settings/security-role-permissions', { id: params.id })
    }

    if (!permissions.includes('modify_custom_security_roles')) {
        return <></>
    }

    return (
        <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid item>
                <IconButton
                    size="small"
                    onClick={handleClick}
                    style={{
                        background: '#FFFFFF',
                        border: '1px solid #E9ECF1',
                        borderRadius: '20px',
                    }}>
                    <img src={penIcon} alt="" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

const RolesManagement = () => {
    const classes = useStyles()
    const notification = useNotification()

    const [secuirtyRoleForm, setSecurityRoleForm] = useState({ open: false })
    const [isLoading, setLoading] = useState(false)
    const [roles, setRoles] = useState([])

    const permissions = useSelector((state) => state.permission?.security_roles)

    const columns: GridColumns<SecurityRole> = [
        {
            headerName: 'Role Name',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return `${row.name}`
            },
        },
        {
            headerName: 'Description',
            headerClassName: 'super-app-theme--header',
            field: 'description',
            flex: 2,
            sortable: true,
            renderCell: ({ value, row }) => {
                return row.description
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide: !permissions.includes('modify_custom_security_roles'),
            renderCell: (params) => {
                return renderActions(params, permissions)
            },
        },
    ]

    const handleGetAllRole = async () => {
        setLoading(true)
        try {
            const res = await getAllRole()
            setRoles(res.data || [])
        } catch (e) {
            notification('Cannot fetch list roles', 'error')
        }

        setLoading(false)
    }

    useEffect(() => {
        handleGetAllRole()
    }, [])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Roles Management</Typography>

                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_new_security_roles']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Security Role"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setSecurityRoleForm({ open: true })}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                display: 'flex',
                                alignItems: 'center',
                                color: '#303E4E',
                                fontWeight: 600,
                                fontSize: '14px',
                            },
                            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                                display: 'contents',
                            },
                            '& .MuiDataGrid-iconButtonContainer': {
                                marginLeft: '5px',
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id as number}
                        rows={roles}
                        columns={columns}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        hideFooterPagination={true}
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {!!secuirtyRoleForm.open && (
                <SecurityRoleForm
                    open={secuirtyRoleForm.open}
                    roles={roles}
                    onClose={(forceReload) => {
                        if (forceReload) setTimeout(handleGetAllRole, 800)

                        setSecurityRoleForm({ open: false })
                    }}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(RolesManagement)
