import React from 'react'
import {
    Grid,
    Typography,
    FormLabel,
    FormControlLabel,
    Tab,
    Tabs,
    Box,
    Radio,
    RadioGroup,
    TextField,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { makeStyles, styled } from '@material-ui/styles'
import Divider from '@mui/material/Divider'
import { MEDICAL_INITIAL_CLINICAL_NOTE } from 'constants/clinicalNote'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { shortenString } from 'utilities/stringOperations'
import TextWithMacrosNew from '../../reusable/TextWithMacrosNew'
import { SelectSearchInputCustom } from 'shared/elements/FormItem/SelectSearchInputCustom'
import CheckboxOption from '../../reusable/CheckboxOptions'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import BloodPressureChart from './chart/BloodPressureChart'
import PulseOxChart from './chart/PulseOxChart'
import RespiratoryRateChart from './chart/RespiratoryRateChart'
import HeightChart from './chart/HeightChart'
import WeightChart from './chart/WeightChart'
import BMIChart from './chart/BMIChart'
import TemperatureChart from './chart/TemperatureChart'
import { apiVitalSigns } from 'services/Patient/apiVitalSigns'
import moment from 'moment'
import VitalRecords from './VitalSignsTable'
import ChartModal from './ChartModal'
import { ExpandMore } from '@material-ui/icons'
import { getConvertedBMI, convertValues } from './utils/unitConvertions.js'
import RenderBloodPressure from './utils/RenderBloodPressure'
import RenderTemperature from './utils/RenderTemperature'
import RenderPulseOximetry from './utils/RenderPulseOximetry'
import RenderRespiratoryRate from './utils/RenderRespiratoryRate'
import RenderHeight from './utils/RenderHeight'
import RenderWeight from './utils/RenderWeight'
import RenderBMI from './utils/RenderBMI'

const useStyles = makeStyles({
    root: {
        '& .MuiTypography-subtitle1': {
            fontSize: '16px',
            lineHeight: '22.4px',
            color: '#303E4E',
            fontWeight: '500',
        },
        '& .MuiTypography-subtitle2': {
            fontSize: '13px',
            lineHeight: '18.2px',
            color: '#9CA9B8',
        },
        '& .MuiTextField-root': {},
    },
    divider: {
        background: '#E9ECF1',
        height: 1,
        marginBottom: 24,
        marginTop: 14,
    },

    input: {
        width: '322.5px',
        borderRadius: '5px',
        border: '1px solid #E9ECF1',
        textAlign: 'center',
        background: '#E9ECF1',
    },
    inputHeight: {
        '& .MuiTextField-root': {
            width: '210px',
        },
    },
    mainContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'relative',
        marginTop: '10px !important',
        background: 'yellow',
    },
    scrollableContent: {
        display: 'flex !important',
        width: '47%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    leftSideContainer: {
        position: 'relative',
        width: '25%',
    },
    rightSideContainer: {
        position: 'relative',
        marginLeft: '10px !important',
        width: '27%',
    },
    scrollableContainer: {
        width: '100%',
    },
})

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const VitalSigns = (props) => {
    const classes = useStyles()
    const startDate = moment().format('YYYY-MM-DD')
    const dateFrom = moment(startDate).subtract(2, 'months').format('YYYY-MM-DD')
    const [value, setValue] = React.useState(0)
    const [temUnit, setTemUnit] = React.useState('')
    const [heightUnit, setHeightUnit] = React.useState('')
    const [weightUnit, setWeightUnit] = React.useState('')
    const [endDate, setEndDate] = React.useState(dateFrom)
    const [chartData, setChartData] = React.useState(null)
    const [prevVitalSignData, setPrevVitalSignData] = React.useState([])
    const [bmiMeasures, setBmiMeasures] = React.useState({
        bmiHeight: '',
        bmiHeightUnit: '',
        bmiWeight: '',
        bmiWeightUnit: ''
    })
    // const [openChart, setOpenChart] = React.useState({
    //     temp: false,
    //     bp: false,
    //     pulse: false,
    //     weight: false,
    //     height: false,
    //     resp_rate: false,
    //     bmi: false
    // })
    const [openChart, setOpenChart] = React.useState(false)
    const [openToggleMenu, setOpenToggleMenu] = React.useState({
        temperature: null,
        height: null,
        weight: null
    })

    const handleToggleMenuOpen = (fieldName, value = null) => {
        setOpenToggleMenu((prevValue) => ({
            ...prevValue,
            [fieldName]: value
        }))
    }

    const handleSetBmiHeightMeasures = (heightValue, heightUnitValue) => {
        setBmiMeasures((prevValue) => ({
            ...prevValue,
            bmiHeight: heightValue,
            bmiHeightUnit: heightUnitValue
        }))
    }

    const handleSetBmiWeightMeasures = (weightValue, weightUnitValue) => {
        setBmiMeasures((prevValue) => ({
            ...prevValue,
            bmiWeight: weightValue,
            bmiWeightUnit: weightUnitValue
        }))
    }

    const handleTabChange = (event, value) => {
        setValue(value)
    }
    const total = React.useMemo(() => {
        return props?.total
    }, [props?.total])

    const handleChange = (updatedValue) => {
        const updatedValues = [...props?.values?.vitals?.vital]
        const existingIndex = props?.values?.vitals?.vital?.findIndex(
            (v) => v.vital_master_id === updatedValue.vital_master_id,
        )
        if (existingIndex !== -1) {
            updatedValues[existingIndex] = {
                ...updatedValues[existingIndex],
                ...updatedValue,
            }
        } else {
            updatedValues.push(updatedValue)
        }
        props?.onChange(`${props?.fieldName}.vitals`, {
            date: moment(new Date()).format('YYYY-MM-DD'),
            vital: updatedValues,
        })
    }
    const fetchClicalNotes = async (newTotal) => {
        try {
            const res = await apiVitalSigns.getAll({
                page: '1',
                size: newTotal,
                patient_id: props?.patientId,
                startDate: endDate,
                endDate: startDate,
            })
            setChartData(res?.data?.chartData)
            setPrevVitalSignData(res?.data?.data)
        } catch (ex) {
            console.error('269=>', ex)
        }
    }

    const handleTotal = (size) => {
        setEndDate(moment(startDate).subtract(size, 'days').format('YY-MM-DD'))
    }

    React.useEffect(() => {
        fetchClicalNotes(total)
        handleTotal(total)
    }, [total])
    /**
     * Temparature Toggele
     */
    const handleToggleTemparature = (val) => {
        setTemUnit(val)
        const filteredMaster = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'temperature',
        )

        const filteredEle = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMaster?._id,
        )
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: convertValues(filteredEle?.value, val === 'F' ? '°F' : '°C', 'temperature'),
            unit: val === 'F' ? '°F' : '°C',
        })
    }
    /**
     * Height Toggele
     */
    const handleToggleHeight = (val) => {
        setHeightUnit(val)
        const filteredMaster = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'height',
        )

        const filteredEle = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMaster?._id,
        )
        let updatedUnit = 'ft/in'
        if (val === 'in') {
            updatedUnit = val
        }
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: convertValues(filteredEle?.value, updatedUnit, 'height'),
            unit: updatedUnit,
        })
    }
    /**
     * Weight Toggele
     */
    const handleToggleWeight = (val) => {
        setWeightUnit(val)
        const filteredMaster = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'weight',
        )

        const filteredEle = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMaster?._id,
        )
        let updatedUnit = 'kg'
        if (val === 'lbs') {
            updatedUnit = 'lb'
        }
        handleChange({
            vital_master_id: filteredMaster?._id,
            value: convertValues(filteredEle?.value, updatedUnit, 'weight'),
            unit: updatedUnit,
        })
    }

    React.useEffect(() => {
        if (bmiMeasures?.bmiHeight && bmiMeasures?.bmiHeightUni && bmiMeasures?.bmiWeight && bmiMeasures?.bmiWeightUnit) {
            const bmi = getConvertedBMI(bmiMeasures?.bmiHeight, bmiMeasures?.bmiHeightUni, bmiMeasures?.bmiWeight, bmiMeasures?.bmiWeightUnit)

            const filteredMaster = props.master?.vitalMasters?.find(
                (vm) => (vm.name || '').toLowerCase() === 'bmi',
            )

            const filteredEle = props.values.vitals?.vital?.find(
                (v) => v.vital_master_id === filteredMaster?._id,
            )

            handleChange({
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: bmi,
            })
        }
    }, [bmiMeasures?.bmiHeight, bmiMeasures?.bmiHeightUni, bmiMeasures?.bmiWeight, bmiMeasures?.bmiWeightUnit])

    React.useEffect(() => {
        if (props?.clinicalNoteData?.previous_cn?.clinicalNoteModel?.physical_exam?.vitals) {
            props?.onChange(
                `${props?.fieldName}.previous_vitals`,
                props?.clinicalNoteData?.previous_cn?.clinicalNoteModel?.physical_exam?.vitals,
            )
        }
    }, [props?.clinicalNoteData?.previous_cn?.clinicalNoteModel?.physical_exam?.vitals])

    React.useEffect(() => {
        const filteredMasterW = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'weight',
        )
        const filteredEleW = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMasterW?._id,
        )
        if (filteredEleW?.unit) {
            if (filteredEleW?.unit === 'lb') {
                setWeightUnit('lbs')
            } else {
                setWeightUnit('kg')
            }
        }
        const filteredMasterH = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'height',
        )
        const filteredEleH = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMasterH?._id,
        )
        if (filteredEleH?.unit) {
            if (filteredEleH?.unit === 'in') {
                setHeightUnit('in')
            } else {
                setHeightUnit('ft/in')
            }
        }
        const filteredMasterT = props.master?.vitalMasters?.find(
            (vm) => (vm.name || '').toLowerCase() === 'temperature',
        )
        const filteredEleT = props.values.vitals?.vital?.find(
            (v) => v.vital_master_id === filteredMasterT?._id,
        )
        if (filteredEleT?.unit) {
            if (filteredEleT?.unit === '°C') {
                setTemUnit('C')
            } else {
                setTemUnit('F')
            }
        }
    }, [])

    return (
        <Grid container item xs={12}>
            {/* Graph Section */}
            <Box
                style={{
                    marginTop: '10px',
                    display: 'flex',
                    gap: 12,
                    width: '100%',
                    overflowX: 'scroll',
                }}>
                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}>
                        <Typography style={{ color: '#000' }}>Temperature</Typography>
                        <Button 
                            style={{
                                color: '#5571c6',
                                padding: '0px',
                                height: '5px',
                                width: 'max-content',
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={(event) => handleToggleMenuOpen('temperature', event?.currentTarget)}>
                            {temUnit}
                            <ExpandMore style={{ color: '#5571c6', }} />
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={openToggleMenu?.temperature}
                            open={Boolean(openToggleMenu?.temperature)}
                            onClose={() => handleToggleMenuOpen('temperature')}
                        >
                            <MenuItem 
                                onClick={() => {
                                    handleToggleTemparature('C')
                                    handleToggleMenuOpen('temperature')
                                }} 
                                disableRipple
                            >C</MenuItem>
                            <MenuItem 
                                onClick={() => {
                                    handleToggleTemparature('F')
                                    handleToggleMenuOpen('temperature')
                                }} 
                                disableRipple
                            >F</MenuItem>
                        </Menu>
                    </div>
                    
                    <TemperatureChart
                        data={chartData?.temperature_data || []}
                        unit={temUnit}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography style={{ color: '#000' }}>Blood Pressure</Typography>
                    </div>
                    <BloodPressureChart 
                        data={chartData?.blood_pressure_data || []}
                        setOpenChart={setOpenChart}
                        model='small'
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography style={{ color: '#000' }}>Pulse OX</Typography>
                    </div>
                    <PulseOxChart
                        data={chartData?.pulse_oximetry_data || []}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography style={{ color: '#000' }}>Respiratory Rate</Typography>
                    </div>
                    <RespiratoryRateChart
                        data={chartData?.respiratory_rate_data || []}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}>
                        <Typography style={{ color: '#000' }}>Height</Typography>
                        <Button 
                            style={{
                                color: '#5571c6',
                                padding: '0px',
                                height: '5px',
                                width: 'max-content',
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={(event) => handleToggleMenuOpen('height', event?.currentTarget)}>
                            {heightUnit}
                            <ExpandMore style={{ color: '#5571c6', }} />
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={openToggleMenu?.height}
                            open={Boolean(openToggleMenu?.height)}
                            onClose={() => handleToggleMenuOpen('height')}
                        >
                            <MenuItem 
                                onClick={() => {
                                    handleToggleHeight('ft/in')
                                    handleToggleMenuOpen('height')
                                }} 
                                disableRipple
                            >Ft/In</MenuItem>
                            <MenuItem 
                                onClick={() => {
                                    handleToggleHeight('in')
                                    handleToggleMenuOpen('height')
                                }} 
                                disableRipple
                            >In</MenuItem>
                        </Menu>
                    </div>
                    <HeightChart
                        data={chartData?.height_data || []}
                        unit={heightUnit}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}>
                        <Typography style={{ color: '#000' }}>Weight</Typography>
                        <Button 
                            style={{
                                color: '#5571c6',
                                padding: '0px',
                                height: '5px',
                                width: 'max-content',
                                background: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={(event) => handleToggleMenuOpen('weight', event?.currentTarget)}>
                            {weightUnit}
                            <ExpandMore style={{ color: '#5571c6', }} />
                        </Button>
                        <Menu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={openToggleMenu?.weight}
                            open={Boolean(openToggleMenu?.weight)}
                            onClose={() => handleToggleMenuOpen('weight')}
                        >
                            <MenuItem 
                                onClick={() => {
                                    handleToggleWeight('lbs')
                                    handleToggleMenuOpen('weight')
                                }} 
                                disableRipple
                            >Lbs</MenuItem>
                            <MenuItem 
                                onClick={() => {
                                    handleToggleWeight('kg')
                                    handleToggleMenuOpen('weight')
                                }} 
                                disableRipple
                            >Kg</MenuItem>
                        </Menu>
                    </div>
                    <WeightChart
                        data={chartData?.weight_data || []}
                        unit={weightUnit}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>

                <Box
                    style={{
                        display: 'flex',
                        width: '100%',
                        gap: 12,
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <Typography style={{ color: '#000' }}>BMI</Typography>
                    </div>
                    <BMIChart
                        data={chartData?.bmi_data || []}
                        openChart={openChart}
                        setOpenChart={setOpenChart}
                        model="small"
                    />
                </Box>
            </Box>

            {/* Last Records Section */}
            <VitalRecords 
                vitalRecords={prevVitalSignData} 
                tempUnit={temUnit}
                heightUnit={heightUnit}
                weightUnit={weightUnit}
                handleToggleHeight={handleToggleHeight}
                handleToggleTemparature={handleToggleTemparature}
                handleToggleWeight={handleToggleWeight}
            />

            {/* Today's Viral Add Section */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: 20 }}>
                <Typography style={{ color: '#000', fontWeight: 500 }}>Today's vitals</Typography>
                <Divider style={{ margin: '10px 0px' }} />
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        width: '100%',
                        justifyContent: 'space-between',
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Temperature, {temUnit}
                        </Typography>
                        <RenderTemperature
                            master={props.master}
                            values={props.values.vitals.vital}
                            unit={temUnit}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Blood Pressure
                        </Typography>
                        <RenderBloodPressure
                            master={props?.master}
                            values={props.values.vitals.vital}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Pulse OX, %
                        </Typography>
                        <RenderPulseOximetry
                            master={props?.master}
                            values={props.values.vitals.vital}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Respiretory Rate
                        </Typography>
                        <RenderRespiratoryRate
                            master={props?.master}
                            values={props.values.vitals.vital}
                            handleChange={handleChange}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Height, {heightUnit}
                        </Typography>
                        <RenderHeight
                            master={props?.master}
                            values={props.values.vitals.vital}
                            unit={heightUnit}
                            handleChange={handleChange}
                            setBmiMeasures={handleSetBmiHeightMeasures}
                            classes={classes}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            Weight, {weightUnit}
                        </Typography>
                        <RenderWeight
                            master={props?.master}
                            values={props.values.vitals.vital}
                            handleChange={handleChange}
                            setBmiMeasures={handleSetBmiWeightMeasures}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography style={{ color: '#303E4E', fontSize: 12, fontWeight: 500 }}>
                            BMI
                        </Typography>
                        <RenderBMI
                            master={props?.master}
                            values={props.values.vitals.vital}
                            handleChange={handleChange}
                        />
                    </div>
                </div>
                <Divider style={{ margin: '10px 0px' }} />
            </div>

            {/* Chart Dialog */}
            <ChartModal
                openChart={openChart}
                setOpenChart={setOpenChart}
                value={value}
                handleTabChange={handleTabChange}
                a11yProps={a11yProps}
                temUnit={temUnit}
                handleToggleTemparature={handleToggleTemparature}
                heightUnit={heightUnit}
                handleToggleHeight={handleToggleHeight}
                weightUnit={weightUnit}
                handleToggleWeight={handleToggleWeight}
                total={total}
                handleTotal={handleTotal}
                chartData={chartData}
            />
        </Grid>
    )
}

export default VitalSigns
