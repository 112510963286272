import React, { useEffect } from 'react'
import { Typography, Grid, FormGroup, FormControlLabel, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextWithMacro from '../reusable/TextWithMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'

const useStyles = makeStyles({
    actioneGrid: {
        marginBottom: 20,
    },
})

const Sucidalideal = ({
    macros,
    values,
    onChange,
    read_only,
    onClear,
    initialValue,
    fieldName,
    nonEditable
}) => {
    const classes = useStyles()
    function generatNarrative(macro) {
        let text
        switch (values?.answer) {
            case 'No':
                text = `There was no communication by the patient (explicit or implicit) of suicide ideation or urges to self-injure (suicidal, nonsuicidal, or ambivalent) that represents an increase in previously documented risk. `
                break

            case 'Yes':
                text = `There was communication by the patient (explicit or implicit) of suicide ideation or urges to self-injure (suicidal, nonsuicidal, or ambivalent) that represents an increase in previously documented risk. Suicide Risk Assessment was updated as a result. `
                break
            default:
                break
        }
        const identifier = text + "\n\n -------Additional Provider comments and analysis below this line-------\n"
        const include = "-------Additional Provider comments and analysis below this line-------\n"
        if(text === ""){return}
        if (values?.narrative?.includes(include)) {
            const placeholderIndex = (values?.narrative).indexOf(include)
            const textAfterPlaceholder = (values?.narrative).substring(placeholderIndex + include.length);
            onChange('suicide_ideation.narrative', `${text ? `${identifier}` : ''}${textAfterPlaceholder}`)
        } else {
            onChange('suicide_ideation.narrative', `${text ? `${identifier}` : ''}${values?.narrative ? values?.narrative : "" }`)
        }
        // onChange('suicide_ideation.narrative', `${text} \n${macro}`)
    }
    function handleChange(name, value) {
        onChange(name, value)
    }
    function handleGenMacro(_, value) {
        onChange('suicide_ideation.narrative', value)
    }

    console.log('values==>', values)
    return (
        <Grid container>
            <Grid className={classes.actioneGrid} container justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography className={classes.subHeadingText}>
                        Was there current communication (explicit or implicit) of suicide ideation
                        or urges to self-injure (suicidal, nonsuicidal, or ambivalent) that
                        represents an increase in previously documented risk?
                    </Typography>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={values?.answer === 'No'}
                                    onChange={(event) =>
                                        onChange('suicide_ideation.answer', event.target.value)
                                    }
                                    value={'No'}
                                    color="primary"
                                    disabled={read_only || nonEditable}
                                />
                            }
                            label="No"
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    checked={values?.answer === 'Yes'}
                                    onChange={(event) =>
                                        onChange('suicide_ideation.answer', event.target.value)
                                    }
                                    value={'Yes'}
                                    color="primary"
                                    disabled={read_only || nonEditable}
                                />
                            }
                            label=" Yes (If yes, please update the risk assessment)"
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleGenMacro}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default Sucidalideal
