import {
    Box,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import Files from 'react-butterfiles'
import IconDragnDrop from 'assests/sidebar/imageUpload.svg'
import axios from 'axios'
import { requestPostBrandingBackGround } from 'services/Provider/provider'
import {  styled } from "@material-ui/core";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import completSign from 'assests/sidebar/Vector.svg'
import { backInsuranceCard, frontInsuranceCard } from 'store/actions/patient'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(() => ({
    download_text: {
        color: '#5571C6',
        cursor: 'pointer',
        padding: '8px !important',
    },
    open_browser_file: {
        color: '#5571C6',
        cursor: 'pointer',
    },
    box_files: {
        marginLeft: -6,
        paddingTop: 12,
    },
    drop_text: {
        pointerEvents: 'none',
    },
    avatar: {
        overflow: 'hidden',
        position: 'relative',
        width: "770px",
        height: "200px",
        borderRadius: "10px",
        background: 'rgba(102, 118, 137, 0.10)',
        border: "1px solid #667689"
    },
    drag_upload_text:{
        display:"flex", 
        alignItems: "center", 
        justifyItems: "center" ,
        paddingLeft: '15px'
    },
    fileName:{
        fontFamily: "Rubik",
        fontSize: "16px",
        color:"#303E4E"
    }
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    width: "90%",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#D9D9D9',
      borderRadius: 5,
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#15A452',
    },
  }));

const FileUploadInsusrance = ({ fileId, placeholder='', url}) => {
    const classes = useStyles()
    const [isHover, setIsHover] = useState(false)
    const [files, setFiles] = useState([])
    const [errors, setErrors] = useState([])
    const [uploadProgress, setUploadProgress] =
        React.useState(0)
    const [isUploading, setIsUploading] =
        React.useState(false)

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
            progressEvent.total,
        )
        setUploadProgress(percentCompleted)
    }
    const dispatch = useDispatch()
    const handleUploadAttachmentFile = async (
        files = [],
    ) => { 
            try {
                    setFiles(files)
                    if(placeholder==='Upload Front of Insurance Card'){
                        dispatch(frontInsuranceCard(''))
                    }else if(placeholder==='Upload Back of Insurance Card'){
                        dispatch(backInsuranceCard(''))
                    }
                    setIsHover(false)
                    const file = files[0]
                    setIsUploading(true)
                    setUploadProgress(0)
                    const fileContext = await new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.addEventListener('loadend', (event) => {
                            resolve(event.target.result)
                        })
                        reader.readAsArrayBuffer(file?.src?.file)
                    })
                    const extensionParts = files[0].type.split('/')
                    let payload = {
                        original_filename: files[0].name,
                        size_in_kb: files[0].size / 1024,
                        filetype: files[0].type,
                        extension: extensionParts[1],
                        filepath : 'insurance'
                    }
                    const res = await requestPostBrandingBackGround(payload)
                    const url = res.data.url
                    await axios.put(url, fileContext, {
                        headers: {
                            'Content-Type': file.type,
                        },
                        onUploadProgress,
                    })
                    fileId(res?.data?.file?.filepath)
                
            } finally {
                setIsUploading(false)
            }
        // setFiles(files)
        // onChange &&
        //     onChange({
        //         attachment_key: files[0].key,
        //         document_name: files[0].name,
        //     })
        // setIsHover(false)
    }

    const handleErrors = (errors) => {
        console.log("errors",errors)
        setErrors(errors)
        setIsHover(false)
    }

    return (
        <Grid container>
            <Grid item>
                <FormLabel
                    component="p"
                    className="d-flex justify-space-between align-center"
                    style={{ fontSize: 14, fontWeight: 500, textTransform:'none' }}>
                    {placeholder ||'Upload file'}
                </FormLabel>
            </Grid>
            <Grid item xs={12}>
                <Files
                    multiple={false}
                    multipleMaxSize="10mb"
                    maxSize="10mb"
                    Extension
                    accept={['image/jpg', 'image/jpeg', 'image/png']}
                    onSuccess={handleUploadAttachmentFile}
                    onError={handleErrors}>
                    {({ browseFiles, getDropZoneProps }) => {
                        return !isUploading && files.length === 0 && !url ? (
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                {...getDropZoneProps({
                                    style: {
                                        height: 100,
                                        borderRadius: 4,
                                        border: !isHover
                                            ? '2px #989CA8 dashed'
                                            : '2px #667689 dashed',
                                    },
                                })}
                                onDragEnter={(e) => {
                                    setIsHover(true)
                                }}
                                onDragLeave={(e) => {
                                    setIsHover(false)
                                }}>
                                {!isHover ? (
                                    <>
                                        <img
                                            src={IconDragnDrop}
                                            alt=""
                                            height="35"
                                            style={{ marginTop: '12px' }}
                                        />
                                        <Typography
                                            style={{
                                                marginLeft: 6,
                                            }}>
                                            Drag and drop to upload or{' '}
                                            <span
                                                className={classes.open_browser_file}
                                                onClick={() => {
                                                    if (!isUploading) {
                                                        browseFiles()
                                                    }
                                                }}>
                                                browse for a file
                                            </span>{' '}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography className={classes.drop_text}>Drop here</Typography>
                                )}
                            </Grid>
                        ) : (
                            <Grid
                                container
                                {...getDropZoneProps({
                                    style: {
                                        height: url ? 245 : 100,
                                        borderRadius: 4,
                                        border: !isHover
                                            ? '2px #989CA8 dashed'
                                            : '2px #667689 dashed',
                                    },
                                })}
                                onDragEnter={(e) => {
                                    setIsHover(true)
                                }}
                                onDragLeave={(e) => {
                                    setIsHover(false)
                                }}
                                xs={12}>
                                {isHover ? (
                                    <Typography
                                        className={classes.drop_text}
                                        style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        Drop here
                                   </Typography>
                                ) : !url ? (
                                    <>
                                        <Grid
                                            container
                                            xs={12}
                                            style={{ padding: '20px 20px 20px 3px' }}>
                                            <Grid container xs={12} style={{ paddingLeft: '8px' }}>
                                                <span
                                                    className={classes.fileName}
                                                    style={{ fontWeight: '400' }}>
                                                    File:
                                                </span>{' '}
                                                <span
                                                    className={classes.fileName}
                                                    style={{
                                                        fontWeight: '500',
                                                        paddingLeft: '5px',
                                                    }}>
                                                    {files[0].name}
                                                </span>
                                            </Grid>
                                            <Grid
                                            container xs={12}>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        paddingLeft: '8px'
                                                    }}>
                                                    <BorderLinearProgress
                                                        variant="determinate"
                                                        value={uploadProgress}
                                                    />
                                                    {uploadProgress === 100 ? (
                                                        <img
                                                            src={completSign}
                                                            alt=""
                                                            height="20"
                                                            style={{ paddingLeft: '8px' }}
                                                        />
                                                    ) : (
                                                        <span style={{ paddingLeft: '8px' }}>
                                                            {uploadProgress}%
                                                        </span>
                                                    )}
                                                </Grid>
                                                <Grid item xs={6} className={classes.drag_upload_text}>
                                                    <>
                                                        <img
                                                            src={IconDragnDrop}
                                                            alt=""
                                                            height="35"
                                                            style={{ marginTop: '12px' }}
                                                        />
                                                        <Typography
                                                            style={{
                                                                marginLeft: 6,
                                                            }}>
                                                            Drag and drop to upload or{' '}
                                                            <span
                                                                className={classes.open_browser_file}
                                                                onClick={() => {
                                                                    if (!isUploading) {
                                                                        browseFiles()
                                                                    }
                                                                }}>
                                                                browse for a file
                                                            </span>{' '}
                                                        </Typography>
                                                    </>
                                                </Grid>
                                            </Grid>
                                            {/* </div> */}
                                        </Grid>
                                    </>
                                ): 
                                <>
                                    <Grid
                                        container
                                        xs={8}
                                        style={{ padding: '20px 0px 30px 20px' }}>
                                            <img src={`${url}`} className={classes.avatar}/>
                                    </Grid>
                                    <Grid
                                        container
                                        xs={4}
                                        style={{ padding: '20px 20px 20px 3px' }}>
                                        <Grid item xs={12} className={classes.drag_upload_text}>
                                            <>
                                                <img
                                                    src={IconDragnDrop}
                                                    alt=""
                                                    height="35"
                                                    style={{ marginTop: '12px' }}
                                                />
                                                <Typography
                                                    style={{
                                                        marginLeft: 6,
                                                    }}>
                                                    Drag and drop to upload or{' '}
                                                    <span
                                                        className={classes.open_browser_file}
                                                        onClick={() => {
                                                            if (!isUploading) {
                                                                browseFiles()
                                                            }
                                                        }}>
                                                        browse for a file
                                                    </span>{' '}
                                                </Typography>
                                            </>
                                        </Grid>
                                    </Grid>
                                </>}
                            </Grid>
                        )
                    }}
                </Files>
            </Grid>
        </Grid>
    )
}

export default FileUploadInsusrance
