import React from 'react'
import {
    Radio,
    FormLabel,
    FormControl,
    FormHelperText,
    RadioGroup as MuiRadioGroup,
    FormControlLabel,
    RadioGroupProps,
} from '@mui/material'

type Item = {
    label?: string
    value?: unknown
    name?: string
    disabled?: boolean
    checked?: boolean
    control?: React.ReactElement<any, any>
    onChange?: (checked: boolean) => void
}

interface Props {
    label?: string
    required?: boolean
    disabled?: boolean
    error?: boolean
    helperText?: string
    value?: string
    items?: Item[]
}

export const RadioGroup = ({
    label,
    required,
    error,
    helperText,
    items,
    disabled,
    ...props
}: Props & RadioGroupProps) => {
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label" style={{ marginBottom: 8 }}>
                <span className={required ? 'required' : ''}>{label}</span>
            </FormLabel>

            <MuiRadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                {...props}>
                {items &&
                    items.length &&
                    items.map(({ label, checked, onChange, ...rest }, idx) => (
                        <FormControlLabel
                            key={idx}
                            label={label}
                            disabled={disabled}
                            sx={{
                                '&.MuiFormControlLabel-root': {
                                    marginLeft: 0,
                                    marginRight: 3,

                                    '& .MuiFormControlLabel-label': {
                                        marginLeft: 1,
                                    },

                                    '& .MuiButtonBase-root': {
                                        color: 'black',
                                    },

                                    '& .MuiButtonBase-root-MuiRadio-root': {
                                        width: 20,
                                        height: 20,
                                    },

                                    '& .Mui-checked': {
                                        color: '#1976d2 !important',
                                    },
                                },
                            }}
                            {...rest}
                            control={
                                <Radio
                                    size="small"
                                    checked={checked}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 25,
                                        },
                                    }}
                                />
                            }
                            onChange={(e, checked) => onChange && onChange(checked)}
                        />
                    ))}
            </MuiRadioGroup>

            {required && error && helperText && (
                <FormHelperText className="helper-text">{helperText}</FormHelperText>
            )}
        </FormControl>
    )
}
