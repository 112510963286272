import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import userIcon from '../../assests/images/user_icon.png'
import calendarIcon from '../../assests/images/icon_calendar.png'
import cancelSuccess from '../../assests/images/icon_cancel_success.png'
import moment from 'moment'

const CancelModal = ({
    open,
    toggleOpen,
    handleCancelAppointment,
    selectedAppointmentDetail,
}) => {
    const [isCancelled, setIsCancelled] = useState(false)
    const [isStillConfirmed, setStillConfirmed] =
        useState(false)
    const isBefore24Hrs = () => {
        const { date, start } =
            selectedAppointmentDetail || {}
        const currentTime = moment()
        const startTime = moment
            .utc(`${date} ${start}`)
            .local()
        const diff = startTime.diff(currentTime, 'h')
        return diff >= 24
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setIsCancelled(false)
                toggleOpen(false)
            }}>
            <div className="apt-cancel-modal-container">
                <div className="popup-close-wrapper">
                    <CloseIcon
                        id="auto-appt-cancel"
                        onClick={() => {
                            toggleOpen(false)
                        }}
                    />
                </div>
                {isStillConfirmed && (
                    <div className="apt-cancel-modal">
                        <span className="apt-cancel-confirm-msg">
                            Your appointment is still
                            confirmed, no changes were made.
                        </span>
                        <img
                            className="apt-calendar-icn"
                            src={calendarIcon}
                            alt=""
                        />
                    </div>
                )}
                {isCancelled && isBefore24Hrs() && (
                    <div className="apt-cancel-modal">
                        <span className="apt-cancel-success-before-twentyfour-msg">
                            Your appointment has been
                            cancelled.
                        </span>
                        <img
                            className="apt-cancel-success-icn"
                            src={cancelSuccess}
                            alt=""
                        />
                    </div>
                )}
                {isCancelled && !isBefore24Hrs() && (
                    <div className="apt-cancel-modal">
                        <span className="apt-cancel-success-msg">
                            Your appointment has been
                            cancelled.
                        </span>
                        <span className="apt-cancel-success-sub-msg">
                            Our team may contact you if any
                            cancellation charges are
                            applicable.
                        </span>
                        <img
                            className="apt-user-icn"
                            src={userIcon}
                            alt=""
                        />
                    </div>
                )}
                {!isCancelled && !isStillConfirmed && (
                    <div className="apt-cancel-modal">
                        <span className="apt-cancel-msg">
                            {!isBefore24Hrs()
                                ? 'Please note cancellation charges may apply. Do you still wish to proceed and cancel?'
                                : 'Do you wish to proceed and cancel?'}
                        </span>
                        <div className="proceed-wrap">
                            <button
                                type="button"
                                id="auto-appt-cancel-no-btn"
                                className="apt-cancel-no-btn"
                                onClick={() => {
                                    // toggleOpen(false);
                                    setStillConfirmed(true)
                                }}>
                                <span>No</span>
                            </button>
                            <button
                                type="button"
                                id="auto-appt-cancel-yes-btn"
                                className="apt-cancel-yes-btn"
                                onClick={() => {
                                    handleCancelAppointment(
                                        setIsCancelled,
                                        isBefore24Hrs(),
                                    )
                                }}>
                                <span>Yes</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default CancelModal
