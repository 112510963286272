import React from 'react'
import { searchAllergen } from 'services/Patient/patient'
import { Allergen } from 'containers/PatientsSetting/interfaces'
import { SearchBox } from 'shared/elements/FormItem/SearchBox'
import { stringConcatenation } from 'shared/utils'
import { label } from 'aws-amplify'

interface Allergy {
    form: string
    id?: string
    rCopiaID?: string
    nDCID?: string
    brandName?: string
    genericName?: string
    strength?: string
    drugGroup?: {
        group_id?: string
        group_name?: string
    }[]
    firstDataBankMedID?: string
    rxnormID?: string
}

interface ResponseDTO extends Allergen {
    highlightText?: string
    key?: React.Key
}

interface Props {
    label?: string
    required?: boolean
    name?: string
    value?: Allergen
    placeholder?: string
    error?: boolean
    helperText?: string | boolean
    onChange?: (value: Allergen) => void
}

export const SearchAllergen = (props: Props) => {
    const { value, onChange, ...rest } = props

    return (
        <SearchBox<ResponseDTO>
            label={props.label}
            value={{
                id: value?.id,
                text: value?.text,
                brandName: value?.brandName,
                genericName: value?.genericName,
                strength: value?.strength,
                form: value?.form,
            }}
            searchHandler={(text) =>
                searchAllergen(text).then((resp) => {
                    const data = resp.data.data as Allergy[]

                    return data.map((item) => ({
                        id: item.id,
                        text: item.genericName,
                        coding: [
                            { code: item?.firstDataBankMedID || '', display: 'FirstDataBankMedID' },
                            { code: item?.nDCID || '', display: 'NCDID' },
                            { code: item?.rCopiaID || '', display: 'RcopiaID' },
                            { code: item?.rxnormID || '', display: 'rxnormID' },
                        ],
                        // highlightText: `${item.id} - <${item.brandName}> ${item.name} ${item.strength}`
                        highlightText: stringConcatenation(
                            item.brandName,
                            item.genericName,
                            item.strength,
                            item.form,
                        ),
                        drugGroup: item.drugGroup || '',
                        genericName: item.genericName || '',
                        strength: item.strength || '',
                        brandName: item.brandName || '',
                    }))
                })
            }
            renderOption={(option) => option.highlightText}
            getOptionLabel={(option) => {
                return stringConcatenation(
                    option.brandName,
                    option.genericName,
                    option.strength,
                    option.form,
                )
            }}
            renderKey="id"
            isOptionEqualToValue={(option, value) =>
                option.id === value.id || option.text === value.text
            }
            onChange={(option) => {
                // console.log(option)
                onChange &&
                    onChange({
                        id: option?.id,
                        coding: option?.coding || [],
                        text: option?.text || '',
                        drugGroup: option?.drugGroup || '',
                        genericName: option?.genericName || '',
                        strength: option?.strength || '',
                        brandName: option?.brandName || '',
                        form: option?.form || '',
                    })
            }}
            {...rest}
        />
    )
}
