import React, { useState } from 'react'
import {
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    container: {
        // marginBottom: 30,
        minWidth: '65%',
    },
    labelText: {
        fontSize: 14,
        color: '#667689',
    },
})

const RadioOptions = ({
    name,
    options,
    onChange,
    value,
    flexDirection = 'row',
    md = 3,
}) => {
    const classes = useStyles()

    return (
        <FormControl className={classes.container}>
            <RadioGroup
                name={name}
                row={true}
                onChange={onChange}>
                <Grid direction={flexDirection} container>
                    {options.map((el, i) => (
                        <Grid item key={i} xs={12} md={md}>
                            <FormControlLabel
                                value={el.value}
                                disabled={el?.disable ? el?.disable : false}
                                control={
                                    <Radio color="primary" />
                                }
                                label={
                                    <Typography
                                        className={
                                            classes.labelText
                                        }>
                                        {el.label}
                                    </Typography>
                                }
                                checked={value === el.value}
                            />
                        </Grid>
                    ))}
                </Grid>
            </RadioGroup>
        </FormControl>
    )
}

export default RadioOptions
