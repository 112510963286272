import moment from 'moment'

export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export const getName = (data) => {
    if (!data) return ''
    const { first_name = '', last_name = '' } = data
    return `${first_name} ${last_name}`
}

export const convertWaitlist = (waitlist) => {
    const { id, patient, specialty, visit_type, service_location, prefer_provider, prefer_time, patient_clinical_practice_name } =
        waitlist

    let availability = ''
    prefer_time.forEach((el, index) => {
        if (el?.days) {
            ;(el?.days).map((day, index1) => {
                let dayName
                if (day.key === 3 || day.key === 6 || day.key === 5) {
                    dayName = days[day.key].slice(0, 2)
                } else {
                    dayName = days[day.key].slice(0, 1)
                }
                availability +=
                    index !== prefer_time.length - 1
                        ? `${dayName}, `
                        : index1 !== (el?.days).length - 1
                        ? `${dayName}, `
                        : dayName
            })
        } else {
            let dayName
            if (el?.day === 3 || el?.day === 6 || el?.day === 5) {
                if (days?.length > 0) dayName = days?.[el?.day]?.slice(0, 2)
            } else {
                if (days?.length > 0) dayName = days?.[el?.day]?.slice(0, 1)
            }
            availability += index !== prefer_time.length - 1 ? `${dayName}, ` : dayName
        }
    })

    const getNextAppointmentTime = () => {
        // const { next_appointment } = patient
        if (patient?.next_appointment) {
            const date = moment(patient?.next_appointment?.start).format('MM/DD/YYYY')
            const start = moment(patient?.next_appointment.start).format('hh:mm')
            const end = moment(patient?.next_appointment.end).format('hh:mm')
            return `${date}`
            // return `${date} -- ${start}-${end}`
        }
        return ''
    }

    return {
        id,
        patient_name: getName(patient),
        patient_id: patient?.id,
        specialty: specialty?.specialty,
        visit_type: visit_type?.post_code,
        service_location: service_location?.name,
        pereferred_provider: getName(prefer_provider),
        availability,
        next_appointment_time: getNextAppointmentTime(),
        next_appointment_id: patient?.next_appointment?.appointment_id,
        clinical_practice:patient_clinical_practice_name ?? '-'
    }
}

export const copyObj = (data) => {
    return JSON.parse(JSON.stringify(data))
}
