import React, { useMemo, useState, useEffect } from 'react'
import { ModalEl } from 'shared/elements'
import moment from 'moment'
import 'moment-timezone'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { uniqBy } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { getSchedule } from 'services/Calendar'
import { TIME_FORMAT, VIEW } from 'containers/CalendarScheduler/constant'
import { DayCalendarEvent } from 'containers/CalendarScheduler/EventCard/components'
import { WeekHeaderCellContent } from 'containers/CalendarScheduler/CalendarHeader'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'
import { ReactComponent as TopPanelCalendarIcon } from '../../../assests/icons/calendar.svg'
import { Calendar as ToolbarCalendar } from 'react-date-range'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles(() => ({
    wrapDay: {
        height: '700px',
        '& .close-viewday': {
            color: '#606060',
            cursor: 'pointer',
            position: 'absolute',
            top: '3px',
            right: '3px',
        },
        '& .rbc-toolbar': {
            color: '#333',
        },
        '& .rbc-btn-group': {
            display: 'none',
        },
        '& .rbc-label': {
            fontSize: '14px',
            color: '#333',
        },
        '& .rbc-day-slot .rbc-event-content': {
            display: 'flex',
        },
        '& .rbc-time-header': {
            display: 'none',
        },
    },
}))

const ViewDayPicker = (props) => {
    const { date = moment(), open, onClose, onOke, setFieldValue, idProvider, values, duration } = props
    const classes = useStyles()
    const isProvider = localStorage.getItem('real_role')?.includes('provider'),
        currentUserId = localStorage.getItem('userId')

    //State
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)
    const [data, setData] = useState([])
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const { defaultDate, localizer } = useMemo(
        () => ({
            defaultDate: moment(date),
            localizer: momentLocalizer(moment),
        }),
        [date],
    )

    async function getScheduledSlotsCount() {
        try {
            setLoadingSchedule(true)
            const resp_by_provider = await getSchedule({
                status: '',
                visitType: '',
                location: '',
                practitioner_id: idProvider ? idProvider : (isProvider ? currentUserId : ''),
                startTime: date
                    ? moment(date).startOf('day').utc().format()
                    : moment().startOf('day').utc().format(),
                endTime: date
                    ? moment(date).endOf('day').utc().format()
                    : moment().endOf('day').utc().format(),
            })
            if (values.appointment_type === 'Office Visit') {
                const resp_by_location = await getSchedule({
                    status: '',
                    visitType: '',
                    location: values.location ? values.location : '',
                    practitioner_id: '',
                    startTime: date
                        ? moment(date).startOf('day').utc().format()
                        : moment().startOf('day').utc().format(),
                    endTime: date
                        ? moment(date).endOf('day').utc().format()
                        : moment().endOf('day').utc().format(),
                })

                // let appointments_off = resp_by_location.data.filter(
                //     (appointment) => appointment.appointmentType === 'Office Visit',
                // )

                let appointments_off = resp_by_location?.data?.calendar

                //khi response data có office visit thì room_id === location_id
                if (values.room) {
                    appointments_off = appointments_off.filter(
                        (i) => `${i.location_id}` === `${values.room}`,
                    )
                }

                const listAppointmentByRoom = uniqBy(
                    [...resp_by_provider?.data?.calendar, ...appointments_off],
                    function (e) {
                        return e.id
                    },
                )
                const dataRoom = listAppointmentByRoom
                    .filter(item => {
                        if (idProvider) return item?.practitioner_id === idProvider
                        if (isProvider) return item?.practitioner_id === currentUserId
                        return null
                    })
                    .map((appt) => ({
                        ...appt,
                        end: new Date(appt.end),
                        start: new Date(appt.start),
                    }))

                setData(dataRoom)
            } else {
                setData(() => {
                    const data = resp_by_provider?.data?.calendar
                        .filter(item => {
                            if (idProvider) return item?.practitioner_id === idProvider
                            if (isProvider) return item?.practitioner_id === currentUserId
                            return item
                        })
                        .map((appt) => ({
                            ...appt,
                            end: new Date(appt.end),
                            start: new Date(appt.start),
                        }))
                    return data
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        getScheduledSlotsCount()
    }, [idProvider, date])

    return (
        <ModalEl
            width={'700'}
            title={'Choose a time for the appointment'}
            ifOffTitle={true}
            description="Booking Appointment Details"
            open={open || false}
            backdropClick
            onClose={() => onClose?.()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            {loadingSchedule && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 3,
                    }}>
                    <CircularProgress />
                </Box>
            )}
            <Box className={classes['wrapDay']}>
                <Box className={'close-viewday'} onClick={() => onClose?.()}>
                    <HighlightOffIcon />
                </Box>
                <Calendar
                    date={defaultDate}
                    events={data}
                    localizer={localizer}
                    selectable="ignoreEvents"
                    showMultiDayTimes
                    formats={{
                        eventTimeRangeFormat: () => null,
                        eventTimeRangeStartFormat: () => null,
                        eventTimeRangeEndFormat: () => null,
                        dayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'dddd', culture),
                        timeGutterFormat: (date, culture, localizer) =>
                            localizer.format(date, 'hh:mm a', culture),
                        dayHeaderFormat: (date, culture, localizer) =>
                            localizer.format(date, 'MM/DD/YYYY', culture),
                        monthHeaderFormat: (date, culture, localizer) =>
                            localizer.format(date, 'MMMM', culture),
                        weekdayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'dddd', culture),
                    }}
                    endAccessor={({ end }) => {
                        if (moment(end).format(TIME_FORMAT) === '00:00:00') {
                            return new Date(end.getTime() - 1)
                        }
                        return new Date(end.getTime())
                    }}
                    step={15}
                    popup={true}
                    onSelectSlot={(e) => {
                        if (moment(e.start).valueOf() - moment().valueOf() > 0) {
                            const newDuration = duration ? duration : Math.floor(
                                (moment(e.end).valueOf() - moment(e.start).valueOf()) / (1000 * 60),
                            )
                            const endTime = duration ? moment(e.start).add(newDuration, 'minutes').format('HH:mm') : moment(e.end).format('HH:mm')
                            setFieldValue('start_time', moment(e.start).format('HH:mm'))
                            setFieldValue('end_time', endTime)
                            setFieldValue('duration', newDuration)

                            onOke?.()
                        } else {
                            setAlerEmtyForm(true)
                        }
                    }}
                    views={{
                        day: true,
                    }}
                    view={'day'}
                    components={{
                        [VIEW.DAY]: {
                            header: WeekHeaderCellContent,
                            event: DayCalendarEvent,
                        },
                        toolbar: (props) => (
                            <CustomToolbar
                                {...props}
                                setFieldValue={setFieldValue}
                                defaultDate={defaultDate}
                            />
                        ),
                    }}
                />
            </Box>
            <AlertEmtyForm
                title="Can't make an appointment in the past!"
                description={"Can't make an appointment in the past!"}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </ModalEl>
    )
}

export default ViewDayPicker
const CustomToolbar = (props) => {
    const calIcon = React.useRef()
    const refOne = React.useRef()
    const [openCalender, setOpenCalender] = useState(false)

    const visibleCalender = () => {
        setOpenCalender((openCalender) => !openCalender)
    }
    const hideOnClickOutside = (e) => {
        if (
            refOne.current &&
            !refOne.current?.contains(e.target) &&
            calIcon.current &&
            !calIcon.current?.contains(e.target)
        ) {
            setOpenCalender(false)
        }
    }

    const hideOnEscape = (e) => {
        if (e.key === 'Escape') {
            setOpenCalender(false)
        }
    }
    const handleOnchangeDate = (e) => {
        const newDate = new Date(e)
        // setUpdatedDate(newDate)
        props.setFieldValue('date', moment(newDate).format('YYYY-MM-DD'))
        setOpenCalender(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideOnClickOutside, true)
        document.addEventListener('keydown', hideOnEscape, true)
    }, [])

    return (
        <div className="rbc-toolbar">
            <span
                className="rbc-toolbar-label"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                {moment(props.defaultDate).format('MM/DD/YYYY')}
                <HtmlTooltip title="View calendar" placement="bottom" arrow>
                    <TopPanelCalendarIcon
                        ref={calIcon}
                        className="top-panel-calender-icon"
                        onClick={visibleCalender}
                    />
                </HtmlTooltip>
            </span>
            {openCalender && (
                <Box
                    ref={refOne}
                    style={{
                        position: 'absolute',
                        zIndex: 10,
                        top: '70px',
                        border: '1px #f4f4f4 solid',
                        borderRadius: '5px',
                    }}
                    sx={{
                        '& .MuiPickersCalendarHeader-transitionContainer': {
                            ' & p': {
                                color: '#303E4E',
                                fontWeight: 500,
                                fontSize: '16px',
                            },
                        },
                        '& .MuiSvgIcon-root': {
                            color: '#303E4E',
                            width: '30px',
                            height: '30px',
                        },
                        '& .MuiPickersCalendarHeader-dayLabel': {
                            color: '#9CA9B8',
                            fontWeight: 400,
                            fontSize: '14px',
                            width: '41px',
                        },
                        '& .MuiPickersCalendarHeader-daysHeader': {
                            marginTop: '16px',
                            marginBottom: '8px',
                        },
                        '& .MuiPickersDay-daySelected': {
                            background: '#5571C6',
                            borderRadius: '8px',
                            color: 'white !important',
                        },
                        '& .MuiPickersBasePicker-pickerView': {
                            maxWidth: '310px',
                            width: '310px',
                        },
                        '& .MuiPickersDay-day': {
                            width: '41px',
                            height: '32px',
                        },
                        '& .MuiIconButton-label': {
                            '& p': {
                                fontSize: '14px',
                            },
                        },
                    }}>
                    <ToolbarCalendar
                        date={new Date(props.defaultDate)}
                        onChange={handleOnchangeDate}
                        className="calendarElement"
                    />
                </Box>
            )}
            {/* <span className="rbc-btn-group">{viewNamesGroup(props.messages)}</span> */}
        </div>
    )
}
