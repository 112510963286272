import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { ModalEl as Modal } from 'shared/elements'
import { InpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import './style.scss'

import { RadioGroup } from 'shared/elements/FormItem/RadioGroup'
import { InputNumber } from 'shared/elements/FormItem/Input/InputNumber'
import { Select } from 'shared/elements/FormItem/Select'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { inpatientPsychiatricHistoryApis } from 'services/Patient/inpatientPsychiatricHistoryApis'
import { useNotification } from 'shared/elements/Notification'
import { getAge } from 'utilities/formats'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { getPatient } from 'services/Patient/patient'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'

type Props = {
    inpatientPsychiatricId?: string | null
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: (forceReload?: boolean) => void
    afterActionOk?: () => void
}

const Reasons = [
    // @TODO:
    { key: 'Depression', value: 'Depression' },
    { key: 'Suicidal thoughts', value: 'Suicidal thoughts' },
    { key: 'Suicidal attempt', value: 'Suicidal attempt' },
    { key: 'Manic episode', value: 'Manic episode' },
    { key: 'Psychotic episode', value: 'Psychotic episode' },
    { key: 'Severe anxiety', value: 'Severe anxiety' },
    { key: 'Durg/Alcohol related', value: 'Durg/Alcohol related' },
    { key: 'Assault', value: 'Assault' },
    { key: 'Violence', value: 'Violence' },
].sort((option1, option2) => {
    let a = option1.value
    let b = option2.value
    return a === b ? 0 : a > b ? 1 : -1
})

const Outcomes = [
    { key: 'Feeling worse or negative result', value: 'Feeling worse or negative result' },
    {
        key: 'Minor improvement or no effect (0% - 24%)',
        value: 'Minor improvement or no effect (0% - 24%)',
    },
    { key: 'Partial response (25% - 49%)', value: 'Partial response (25% - 49%)' },
    { key: 'Significant improvement (50% - 74%)', value: 'Significant improvement (50% - 74%)' },
    {
        key: 'Resolved or nearly resolved problem (75% - 100%)',
        value: 'Resolved or nearly resolved problem (75% - 100%)',
    },
]

const validationSchema = yup.object().shape({
    // id: yup.string(),
    // facility: yup.string(),
    // voluntary: yup.bool(),
    // reason: yup.string(),
    // outcome: yup.string(),
    // comment: yup.string(),
    // outcome: yup.string().required('This field is required!'),
    age: yup
        .number()
        .min(1, `Age can't be less than 1`)
        .max(yup.ref('age_patient'), "The age must be less than or equal to the patient's age!"),
})

const INITIAL_VALUES = {
    facility: '',
    voluntary: false,
    reason: '',
    age: '',
    outcome: '',
    comments: '',
    age_patient: 100,
}

const InpatientPsychiatricForm = (props: Props) => {
    // Hooks
    const notification = useNotification()

    // State
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [initialValues, setInitialValues] = useState<InpatientPsychiatric>(INITIAL_VALUES)
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)

    function compareObject(obj1, obj2, dropKey?: string[]) {
        const a = { ...obj1 }
        const b = { ...obj2 }
        if (dropKey) {
            dropKey.forEach((key) => {
                delete a[key]
                delete b[key]
            })
        }

        return JSON.stringify(a) === JSON.stringify(b) ? true : false
    }

    const handleSubmit = async (values: InpatientPsychiatric) => {
        const payload = { ...values }

        delete payload['age_patient']

        const checkValueForm = compareObject(INITIAL_VALUES, payload, ['age_patient'])
        if (checkValueForm && !props.inpatientPsychiatricId) {
            setAlerEmtyForm(true)
            return
        }

        setIsSubmitting(true)
        try {
            if (props.inpatientPsychiatricId) {
                await inpatientPsychiatricHistoryApis.putInpatientPsychiatric(
                    props.patientId as string,
                    props.inpatientPsychiatricId,
                    { ...values, encounter_id: props.encounter_id },
                )
                notification('Inpatient Psychiatric History updated successfully', 'success')
            } else {
                await inpatientPsychiatricHistoryApis.postInpatientPsychiatric(
                    props.patientId as string,
                    { ...values, encounter_id: props.encounter_id },
                )
                notification('Inpatient Psychiatric History created successfully', 'success')
            }

            setIsSubmitting(false)

            formik.resetForm({
                values: initialValues,
            })
            props.onClose?.(true)
            props.afterActionOk?.()
        } catch (error) {
            notification('Inpatient Psychiatric History not saved', 'error')
            setIsSubmitting(false)
        }
    }

    const getInpatientPsychiatric = async (id: string) => {
        setLoading(true)

        try {
            const data = await inpatientPsychiatricHistoryApis.getById(
                props.patientId as string,
                id,
            )

            const resPatient = await getPatient(props.patientId)
            let agePatient: number | null = null
            if (resPatient.data) {
                agePatient = getAge(resPatient.data.dob, 'YYYY-MM-DD')
                if (agePatient === 0) agePatient = 1
            }

            formik.setValues({
                ...data,
                age_patient: agePatient ? agePatient : 100,
            })
        } catch (error) {
            notification('Cannot get Inpatient Psychiatric History', 'error')
        } finally {
            setLoading(false)
        }
    }

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                console.log(resp.data)
                let age = getAge(resp.data.dob, 'YYYY-MM-DD')
                if (age === 0) age = 1
                if (age) {
                    // formik.setFieldValue('age_patient', age)
                    setInitialValues({
                        ...initialValues,
                        age_patient: age,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik<InpatientPsychiatric>({
        validationSchema,
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    const modalTitle = props.inpatientPsychiatricId
        ? 'Update Inpatient Psychiatric History'
        : 'Add Inpatient Psychiatric History'

    useEffect(() => {
        if (props.inpatientPsychiatricId) {
            getInpatientPsychiatric(props.inpatientPsychiatricId)
        }
    }, [props.inpatientPsychiatricId])

    useEffect(() => {
        if (props.patientId && !props.inpatientPsychiatricId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="inpatient-psychiatric-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12} container direction="column">
                        <TextBox
                            label="Facility"
                            name="facility"
                            value={formik.values.facility}
                            placeholder="Facility"
                            error={
                                Boolean(formik.touched.facility) && Boolean(formik.errors.facility)
                            }
                            helperText={
                                Boolean(formik.touched.facility) &&
                                (formik.errors.facility as string)
                            }
                            onChange={(value) => formik.setFieldValue('facility', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {/* @ts-ignore */}
                        <RadioGroup
                            label="Voluntary"
                            name="voluntary"
                            value={formik.values.voluntary}
                            items={[
                                {
                                    value: true,
                                    label: 'Yes',
                                    checked: formik.values.voluntary === true,
                                },
                                {
                                    value: false,
                                    label: 'No',
                                    checked: formik.values.voluntary === false,
                                },
                            ]}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'voluntary',
                                    e.target.value === 'true' ? true : false,
                                )
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            label="Reason"
                            name="reason"
                            value={formik.values.reason}
                            placeholder="Reason"
                            options={Reasons} // @TODO:
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            helperText={formik.touched.reason && formik.errors.reason}
                            onChange={(value) => formik.setFieldValue('reason', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Age"
                            name="age"
                            min={0}
                            max={99}
                            value={formik.values.age}
                            error={formik.touched.age && Boolean(formik.errors.age)}
                            helperText={formik.touched.age && formik.errors.age}
                            onChangeValue={(value) => formik.setFieldValue('age', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            label="Outcome"
                            name="outcome"
                            value={formik.values.outcome}
                            options={Outcomes}
                            error={formik.touched.outcome && Boolean(formik.errors.outcome)}
                            helperText={formik.touched.outcome && formik.errors.outcome}
                            onChange={(value) => formik.setFieldValue('outcome', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Comments"
                            name="comment"
                            value={formik.values.comment}
                            minRows={4}
                            multiline
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            onChange={(value) => formik.setFieldValue('comment', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={isSubmitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.inpatientPsychiatricId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
            <AlertEmtyForm
                title="Please input the value to form!"
                description={'Please input the value to form!'}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </Modal>
    )
}

export { InpatientPsychiatricForm }
export default InpatientPsychiatricForm
