import { ClickAwayListener, Grid, makeStyles, Typography, Box } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment, { Moment } from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'

const useStyles = makeStyles(() => ({
    timeContainerCss: {
        width: '100%',
        textAlign: 'left',
        padding: '10px',
        font: 'Rubik',
        '&:hover': {
            background: '#DDE1E6 !important',
            opacity: 0.8,
        },
    },
    timeContainerCssdisable: {
        width: '100%',
        textAlign: 'left',
        padding: '10px',
        font: 'Rubik',
        color: '#989CA8',
        cursor: 'none',
        pointerEvents: 'none',
    },
    TimePaperCss: {
        background: '#FBFCFC',
        border: '1px solid #BFBFBF',
        borderRadius: '5px',
        width: '100%',
        height: '158px',
        boxSizing: 'border-box',
        cursor: 'pointer',
        overflow: 'auto',
    },
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        top: 44,
        left: 0,
        background: '#FFFFFF',
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        zIndex: 1,
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
    label: {
        fontWeight: 500,
        marginBottom: 8,
        color: '#303E4E',
    },
    root: {
        '& .MuiOutlinedInput-root': {
            height: '40px',
            '&:hover fieldset': {
                borderColor: 'none', // Customize hover color
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Hide the focused border
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Hide hover border color
            },
            '&.Mui-focused fieldset': {
                borderColor: 'none', // Customize focus color
            },
            '& fieldset': {
                border: 'none', // Remove default border
            },
        },
    },
}))

const hourOptions = [
    { key: '0', value: '00' },
    { key: '1', value: '1' },
    { key: '2', value: '2' },
    { key: '3', value: '3' },
    { key: '4', value: '4' },
    { key: '5', value: '5' },
    { key: '6', value: '6' },
    { key: '7', value: '7' },
    { key: '8', value: '8' },
    { key: '9', value: '9' },
    { key: '10', value: '10' },
    { key: '11', value: '11' },
    { key: '12', value: '12' },
    { key: '13', value: '13' },
    { key: '14', value: '14' },
    { key: '15', value: '15' },
    { key: '16', value: '16' },
    { key: '17', value: '17' },
    { key: '18', value: '18' },
    { key: '19', value: '19' },
    { key: '20', value: '20' },
    { key: '21', value: '21' },
    { key: '22', value: '22' },
    { key: '23', value: '23' },
]
const minuteOptions = [
    { key: '0', value: '00' },
    { key: '5', value: '05' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
    { key: '20', value: '20' },
    { key: '25', value: '25' },
    { key: '30', value: '30' },
    { key: '35', value: '35' },
    { key: '40', value: '40' },
    { key: '45', value: '45' },
    { key: '50', value: '50' },
    { key: '55', value: '55' },
]
function getTimeByInput(input, timeArray) {
    const filteredTimes = timeArray.filter((time) => time.startsWith(input))
    return filteredTimes.length > 0 ? filteredTimes[0] : null
}
function NewTimePicker({
    onChange,
    busySlots = [],
    values,
    error,
    helperText,
    disabled,
    style,
    isBeforeNotCheck = false,
    makeEditable = false,
}) {
    const classes = useStyles()
    const [startTime, setStartTime] = React.useState({
        hour: '0',
        min: '0',
    })
    const [date, setdate] = useState(moment(values).clone().format('MM/DD/YYYY'))
    const [timeArray, setTimeArray] = useState([])
    const [selectedTime, setSelectedTime] = useState('')

    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [editable, setEditable] = React.useState(false)
    const [editableVal, setEditableVal] = useState()
    const [AllowedKeys, setAllowedKeys] = useState([
        '0',
        '1',
        '2',
        '3',
        '4',
        ' ',
        '5',
        '6',
        '7',
        '8',
        '9',
        ':',
        'P',
        'A',
        'M',
    ])

    const inputRef = useRef(null)

    const onChangeStart = (ev) => {
        let e = ev
        if (e.length === 1) {
            const value = getTimeByInput(e, timeArray)
            e = value
        }
        setEditableVal(e)
        setSelectedTime(e)
        const TimeFOrmatChange = moment(e, 'h:mm A')
        const paramTime = TimeFOrmatChange.format('HH:mm')
        const [hour, min] = paramTime.split(':')

        setStartTime((startTimePrev) => ({
            ...startTimePrev,
            hour,
            min,
        }))
        // const paramTime =
        //     name === 'hour' ? `${ value }: ${ startTime.min }` : `${ startTime.hour }: ${ value }`
        if (onChange) {
            const newTime = moment(`${date} ${paramTime}`, 'MM/DD/YYYY HH:mm')
            if (!newTime.isSame(moment(values))) {
                onChange(newTime)
            }
        }
        setOpen(false)
    }

    const generateTimeArray = (hourArray, minutesArray) => {
        setTimeArray([])
        for (let i = 0; i < hourArray.length; i++) {
            const elementHour = hourArray[i].value
            for (let j = 0; j < minutesArray.length; j++) {
                const elementMin = minutesArray[j].value
                const exactTime = moment().hour(elementHour).minute(elementMin)
                setTimeArray((prev) => [...prev, exactTime.format('h:mm A')])
            }
        }
    }

    const isBeforeCheck = (targetTime) => {
        const [month, day, year] = date.split('/')
        const temp = moment()
        temp.set({
            year,
            month: month - 1,
            date: day,
            hour: moment(targetTime, 'h:mm A').hour(),
            minute: moment(targetTime, 'h:mm A').minute(),
            second: 0,
            millisecond: 0,
        })

        return temp.isBefore(moment())
    }

    useEffect(() => {
        generateTimeArray(hourOptions, minuteOptions)
        scrollOptionsToActive()
        // if (editable && makeEditable) {
        //     const hourMinVal = editableVal.split(":")
        //     setStartTime({
        //         hour: '12',
        //         min: '00'
        //     })
        // }
    }, [open, editableVal])

    useEffect(() => {
        setdate(moment(values).clone().format('MM/DD/YYYY'))
        const time = moment(values)
        setStartTime({
            hour: time.hours(),
            min: time.minute() === 0 ? '0' : time.minute() === 5 ? '05' : time.minute(),
        })
    }, [values])

    const handleClickAway = () => {
        setOpen(false)
        setEditable(false)
        setEditableVal(moment(`${startTime.hour}:${startTime.min}`, 'HH:mm').format('hh:mm A'))
    }

    const onClickPreventDefault = (e) => {
        // e.stopPropagation()
        // e.preventDefault()
    }

    function scrollOptionsToActive() {
        const options = document.querySelectorAll('.options')
        const activeOptionCalender = Array.from(options)?.find(
            (option) =>
                option.getAttribute('current-time') ===
                moment(`${startTime.hour}: ${startTime.min}`, 'HH:mm').format('h:mm A'),
        )

        if (activeOptionCalender) {
            const dropdownOptions = document.querySelector('.options-wrapper')
            dropdownOptions.scrollTop = activeOptionCalender?.offsetTop
        }
        if (!activeOptionCalender) {
            const activeOptionNormal = Array.from(options)?.find(
                (option) => option?.getAttribute('data-enabled') === 'true',
            )
            if (activeOptionNormal) {
                const dropdownOptions = document.querySelector('.options-wrapper')
                dropdownOptions.scrollTop = activeOptionNormal?.offsetTop
            }
        }

        if (makeEditable) {
            if (parseInt(startTime.min) % 5 !== 0) {
                const remainder = parseInt(startTime.min) % 5
                const mindata = parseInt(startTime.min) + parseInt(5 - remainder)
                const activeOptionEditable = Array.from(options)?.find(
                    (option) =>
                        option.getAttribute('current-time') ===
                        moment(`${startTime.hour}: ${mindata}`, 'HH:mm').format('h:mm A'),
                )

                if (activeOptionEditable) {
                    const dropdownOptions = document.querySelector('.options-wrapper')
                    dropdownOptions.scrollTop = activeOptionEditable?.offsetTop
                }
            }
        }
    }

    const handleKeyDown = (e) => {
        console.log(e.target.value)
        setEditableVal(e.target.value)
    }
    const mask = [/\d/, /\d/, ':', /[0-5]/, /\d/, ' ', /[APap]/, 'M']

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.selectionStart = 0
            // inputRef.current.selectionEnd = 0;
            inputRef.current.select()
        }
    }, [editable])

    useEffect(() => {
        setEditableVal(moment(`${startTime.hour}:${startTime.min}`, 'HH:mm').format('hh:mm A'))
    }, [])

    const MaskedInput = ({ mask, value, onChange }) => {
        return (
            <InputMask mask={mask} value={value} onChange={onChange}>
                {(inputProps) => <TextField className={classes.root} {...inputProps} />}
            </InputMask>
        )
    }

    const validFormatCheck = (data) => {
        const isValidFormat = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/.test(data)
        if (isValidFormat) {
            return data
        }
    }
    function isNumericKey(keyCode) {
        return (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)
    }
    const handleKeyDownCheck = (event) => {
        // Allow only numeric keys (0-9), backspace, delete, arrow keys, and navigation keys
        if (!isAllowedKey(event.key)) {
            event.preventDefault() // Prevent input if key is not allowed
            return false
        }
        return true
    }

    const isAllowedKey = (key) => {
        // List of allowed keys
        const allowedKeys = [
            '0',
            '1',
            '2',
            '3',
            '4',
            ' ',
            '5',
            '6',
            '7',
            '8',
            '9',
            ':',
            'P',
            'A',
            'M',
            'p',
            'a',
            'm',
        ]
        const navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End']
        // Check if the pressed key is allowed
        return allowedKeys.includes(key) || navigationKeys.includes(key)
    }

    const isValidInput = (value) => {
        // Regular expression to match the time format "hh:mm AM/PM"
        const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i

        return timeRegex.test(value)
    }
    const roundTimeToNearest5MinutesAndValidate = (timeString) => {
        const parsedTime = moment(timeString, 'hh:mm A')
        let hour = parsedTime.hours()
        let minute = parsedTime.minutes()
        minute = Math.ceil(minute / 5) * 5
        if (minute === 60) {
            hour = (hour + 1) % 24
            minute = 0
        }
        const formattedHour = (hour % 12 === 0 ? 12 : hour % 12).toString().padStart(2, '0')
        const formattedMinute = minute.toString().padStart(2, '0')
        const period = hour < 12 ? 'AM' : 'PM'
        const formattedTime = `${formattedHour}:${formattedMinute} ${period}`
        return formattedTime
    }
    const handleBlurEvent = React.useCallback(
        (event) => {
            event.preventDefault()
            event.stopPropagation()
            const roundData = roundTimeToNearest5MinutesAndValidate(editableVal)
            if (roundData !== null) {
                onChangeStart(roundData)
            } else {
                onChangeStart(editableVal)
            }
        },
        [editableVal, editable],
    )
    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Grid container className={classes.container} onClick={onClickPreventDefault}>
                <Grid
                    container
                    className={[classes.input_box]}
                    justifyContent="space-between"
                    onClick={() => {
                        if (makeEditable) {
                            setEditable(true)
                            setEditableVal(
                                `${moment(`${startTime.hour}: ${startTime.min}`, 'HH:mm').format(
                                    'hh:mm A',
                                )} `,
                            )
                            onChangeStart(
                                `${moment(`${startTime.hour}: ${startTime.min}`, 'HH:mm').format(
                                    'hh:mm A',
                                )} `,
                            )
                        }
                        setOpen(true)
                        scrollOptionsToActive()
                    }}
                    alignItems="center"
                    style={{
                        opacity: disabled ? 0.7 : 1,
                        pointerEvents: disabled ? 'none' : 'initial',
                    }}>
                    {!editable && (
                        <Typography>{`${moment(
                            `${startTime.hour}: ${startTime.min}`,
                            'HH:mm',
                        ).format('hh:mm A')} `}</Typography>
                    )}
                    {editable && (
                        <TextField
                            className={classes.root}
                            inputRef={inputRef} // Assign ref to the TextField input
                            value={editableVal}
                            onKeyDown={handleKeyDownCheck}
                            onChange={(event, data) => {
                                if (isValidInput(event.target.value)) {
                                    setEditableVal(event.target.value)
                                    onChangeStart(event.target.value)
                                    scrollOptionsToActive()
                                    setOpen(false)
                                    return
                                }

                                if (!isValidInput(event.target.value)) {
                                    onChangeStart(event.target.value)
                                }
                                // onChangeStart(event.target.value)
                                // onChangeStart(event.target.value)
                                setOpen(true)
                                scrollOptionsToActive()
                            }}
                            onBlur={handleBlurEvent}
                        />
                    )}
                </Grid>
                {open && (
                    <Grid
                        container
                        className={[classes.drop__down, classes.TimePaperCss, 'options-wrapper']}
                        style={{ ...style }}
                        role="listbox">
                        {timeArray.length &&
                            timeArray.map((item, idx) => {
                                return (
                                    <Box
                                        current-time={`${item}`}
                                        data-enabled={`${
                                            isBeforeCheck(item) && !isBeforeNotCheck ? false : true
                                        }`}
                                        key={idx}
                                        className={[
                                            'options',
                                            `${
                                                isBeforeCheck(item) && !isBeforeNotCheck
                                                    ? classes.timeContainerCssdisable
                                                    : classes.timeContainerCss
                                            }`,
                                        ]}
                                        onClick={() => {
                                            setEditable(false)
                                            onChangeStart(item)
                                        }}
                                        onMouseEnter={() => setEditable(false)}
                                        onMouseLeave={() => setEditable(true)}
                                        style={{
                                            background: `${selectedTime === item ? '#DDE1E6' : ''}`,
                                            paddingLeft: '15px',
                                        }}>
                                        {item}
                                    </Box>
                                )
                            })}
                    </Grid>
                )}
                {error && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                        {helperText}
                    </p>
                )}
            </Grid>
        </ClickAwayListener>
    )
}

export default NewTimePicker
