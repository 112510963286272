
import { CareTeamInterfaces } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService
} from 'utilities/request/restClient'

interface Payload {
    page?: string
    limit?: string 
}

export const apiClinicalNotes = {
  getAll(patienrId, payload) {
      const queryString = new URLSearchParams({
          ...payload,
      }).toString()
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient-note/${patienrId}?${queryString}`,
      )
  }
}