import { Grid } from '@material-ui/core'
import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { usePatientDashboardStyles } from '../style'
import { CareTeam } from './CareTeam'
import { ClinicalReminder } from './ClinicalReminder'
import { Documents } from './Documents'
import { Insurances } from './Insurances'
import { PsychotherapyNote } from './PsychotherapyNote'
import { PatientsContacts } from './PatientsContacts'

export const GeneralPatientRecords = () => {
    const classes = usePatientDashboardStyles()

    const isPermission = useSelector((state: { permission?: { patient_accounts?: unknown[] } }) =>
        state.permission?.patient_accounts?.find(
            (item) => item === 'view_psychotherapy_process_note',
        ),
    )

    return (
        <Grid
            item
            xs={12}
            container
            spacing={2}
            // wrap="nowrap"
            className={classes.container_tab}
            style={{
                // marginBottom: 4,
                // height: '50%',
                height: 'calc(100% - 10px)',
                marginTop: 10,
            }}>
            {/* Clinical Reminder */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <ClinicalReminder />
                </Box>
            </Grid>

            {/* Insurances */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <Insurances />
                </Box>
            </Grid>

            {/* Documents */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <Documents />
                </Box>
            </Grid>

            {/* CareTeam */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <CareTeam />
                </Box>
            </Grid>
            {/* Documents */}
            {isPermission && (
                <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                    <Box className={classes.box_ct}>
                        <PsychotherapyNote />
                    </Box>
                </Grid>
            )}

            {/* Patients Contacts */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct}>
                    <PatientsContacts />
                </Box>
            </Grid>


        </Grid>
    )
}
