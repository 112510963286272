import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    OutlinedInput,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Input,
    FormControlLabel,
    TextField,
    InputAdornment,
    SxProps,
    CircularProgress,
    FilledInput,
    IconButton
} from '@mui/material'
import {
    Button, Typography, Accordion, AccordionSummary, AccordionDetails, Chip
} from '@material-ui/core'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Placeholder from 'shared/elements/Placeholder'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
// import SearchIcon from '@mui/icons-material/Search'
import SearchIconLabel from 'assests/icons/SearchIconLabel'
import SearchIcon from 'assests/icons/search_icon.svg'
import { useNotification } from '../Notification'
// import { Input } from '@material-ui/core'
// import { makeStyles } from '@mui/material/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 3
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    sx?: SxProps
    options: {
        key: string | number
        value: string
    }[]
    value?:
    | {
        key: string | number
        value: string
    }[]
    | null

    label?: string
    handleChange: (option: { key: string | number; value: string }, checked: boolean) => void
    placeholder?: string
    placeholderSearch?: string
    style?: React.CSSProperties
    onOpen?: () => void
    loadingOptions?: boolean
    disable?: boolean
    filterSearch: (value: string) => void
}

const useStyles = makeStyles(() => ({
    wrapSelectMuntil: {
        overflow: 'hidden',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    label: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '0',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '32px',
        },
    },
    box_options: {
        padding: '15px 0',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    list_options: {
        maxHeight: '150px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        width: '100%',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
}))

const AccordionFilter = (props: Props) => {
    const {
        options,
        value,
        handleChange,
        label,
        placeholder,
        placeholderSearch,
        style,
        onOpen,
        sx,
        loadingOptions,
        disable,
        filterSearch
    } = props
    const [searchOptions, setSearchOptions] = useState<
        {
            key: string | number
            value: string
        }[]
    >(options || [])

    const notification = useNotification()

    useEffect(() => {
        setSearchOptions(options)
    }, [options])

    // const newOptions = options.map(option => option.label)
    const handleSearch = (e) => {
        setSearchOptions((prev) => {
            const listResult = options.filter((item) => {
                return item.value.toUpperCase().indexOf(e.toUpperCase()) >= 0
            })
            return listResult
        })
    }

    const classes = useStyles()
    return (
        <Box sx={sx} className={classes.wrapSelectMuntil}>
            <Box className={classes.box_options}>
                <div className='search_holder' style={{ marginTop: "-10px" }}>
                    <FormControl sx={{ m: 1, width: '27ch' }} variant="filled">
                        <FilledInput
                            sx={{ paddingTop: "8px", paddingBottom: "8px" }}
                            id="filled-adornment-password"
                            placeholder="Search..."
                            onChange={(e) => { filterSearch(e.target.value) }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton edge="end">
                                        <img src={SearchIcon} alt="icon"></img>
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </div>
                <Box className={classes.list_options}>
                    {loadingOptions ? (
                        <Box className={classes.box_option}>
                            <Box>Loading Options...</Box>
                        </Box>
                    ) : (
                        <>
                            {!options.length ? (
                                <Box className={classes.box_option}>
                                    <Box>No Options...</Box>
                                </Box>
                            ) : (
                                options.map((option) => {
                                    const check = value?.find(
                                        (item) => item.key === option.key,
                                    )
                                    return (
                                        <Box
                                            key={option.key}
                                            className={classes.box_option}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={check ? true : false}
                                                        onChange={(e) => {
                                                            // console.log(e.target.checked)
                                                            if (placeholder === "Provider") {
                                                                if (typeof value != "undefined" && value != null && value?.length < 10) {
                                                                    handleChange(
                                                                        option,
                                                                        e.target.checked,
                                                                    )
                                                                } else {
                                                                    if (check) {
                                                                        handleChange(
                                                                            option,
                                                                            e.target.checked,
                                                                        )
                                                                    } else {
                                                                        notification(
                                                                            `Maximum 10 Providers can be filtered with`,
                                                                            'warning'
                                                                        )
                                                                    }
                                                                }

                                                            } else {
                                                                handleChange(
                                                                    option,
                                                                    e.target.checked,
                                                                )
                                                            }
                                                        }}
                                                        name={option.value}
                                                    />
                                                }
                                                label={option.value}
                                            />
                                        </Box>
                                    )
                                })
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default React.memo(AccordionFilter)
