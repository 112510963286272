import React from 'react'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@mui/material'

const useStyles = makeStyles({
    btn_add: {
        '&.MuiButton-contained': {
            fontWeight: 400,
            fontSize: '14px',
        },
    },
    // btn_free: {
    //     height: 35,
    //     minWidth: 32,
    //     background: '#5686b5',
    // },
})

export default function ButtonTextTooltip(props) {
    const { children, tooltip, sx } = props
    const classes = useStyles()

    return (
        <HtmlTooltip title={tooltip || ''} arrow placement="top">
            <Button sx={sx} className={`${classes.btn_add}`} variant="contained" {...props}>
                {children}
            </Button>
        </HtmlTooltip>
    )
}
