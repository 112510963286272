import { Grid } from '@material-ui/core'
import { FormikProps, useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { CareTeamInterfaces, FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import { ModalEl as Modal } from 'shared/elements'
import './style.scss'

import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'

import { ErrorType } from 'containers/PatientsSetting/interfaces'
import { apiCareTeam } from 'services/Patient/apiCareTeam'
import { useNotification } from 'shared/elements/Notification'
import { MultiSelect } from './MultiSelect'
import { INITIAL_VALUES } from './Schema'
import { getProivders } from 'services/Calendar'
import ErrorDialog from './ErrorDialog'

type Props = {
    careTeamId?: string | number | boolean
    patientId?: string
    encounter_id?: string
    open?: boolean
    onOke?: () => void
    onClose?: () => void
}

const validationSchema = yup.object({
    practitioner_ids: yup
        .array()
        .min(1, "You can't leave this blank !")
        .required("You can't leave this blank !"),
})

const FamilyMembers = [
    // @TODO:
    { key: 'MOTHER', value: 'Mother ' },
    { key: 'FATHER', value: 'Father  ' },
    { key: 'BROTHER', value: 'Brother ' },
    { key: 'SISTER', value: 'Sister ' },
    { key: 'GRANDMOTHER', value: 'Grandmother ' },
    { key: 'GRANDFATHER', value: 'Grandfather ' },
    { key: 'AUNT', value: 'Aunt ' },
    { key: 'UNCLE', value: 'Uncle ' },
    { key: 'COUSIN', value: 'Cousin ' },
    { key: 'SON', value: 'Son  ' },
    { key: 'DAUGHTER', value: 'Daughter ' },
]

const PsychiatricConditions = [
    { value: 'DEPRESSION', label: '  Depression ', checked: false },
    { value: 'PANIC_DISORDER', label: 'Panic disorder ', checked: false },
    {
        value: 'GENERALIZED_ANXIETY_DISORDER',
        label: 'Generalized anxiety disorder. ',
        checked: false,
    },
    { value: 'SOCIAL_ANXIETY_DISORDER', label: 'Social anxiety disorder ', checked: false },
    {
        value: 'OBSESSIVE_COMPULSIVE_DISORDER',
        label: 'Obsessive compulsive disorder ',
        checked: false,
    },
    {
        value: 'POST-TRAUMATIC_STRESS_DISORDER',
        label: 'Post-traumatic stress disorder ',
        checked: false,
    },
    { value: 'BIPOLAR_DISORDER', label: 'Bipolar disorder ', checked: false },
    { value: 'EATING_DISORDER', label: 'Eating disorder ', checked: false },
    { value: 'ALCOHOLISM', label: 'Alcoholism ', checked: false },
    { value: 'DRUG_ABUSE', label: 'Drug abuse ', checked: false },
    { value: 'ADHD', label: 'ADHD ', checked: false },
    { value: 'PERSONALITY_DISORDER', label: 'Personality disorder ', checked: false },
    { value: 'SCHIZOPHRENIA/PSYCHOSIS', label: 'Schizophrenia/Psychosis ', checked: false },
    { value: 'PSYCHIATRIC_HOSPITALIZATION', label: 'Psychiatric hospitalization ', checked: false },
    { value: 'SUICIDE_ATTEMPT', label: 'Suicide attempt ', checked: false },
    { value: 'SUICIDE', label: 'Suicide ', checked: false },
]

const CareTeamForm = (props: Props) => {
    // State
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const notification = useNotification()
    const [initialValues, setInitialValues] = useState<CareTeamInterfaces>(INITIAL_VALUES)
    const [optionsChecked, setOptionsChecked] = useState(PsychiatricConditions)
    const [providerOptions, setProviderOptions] = React.useState([])
    const [isWarning, setIsWarning] = React.useState([])




    const handleSubmit = async (values, resetForm) => {
        if (!props.careTeamId && props.patientId) {
            setSubmitting(true)
            try {
                await apiCareTeam.getAll(props.patientId, {
                    page: "0",
                    limit: '1000000000000',
                }).then((res) => {
                    const providers = values.practitioner_ids.filter(id => res.data.data.findIndex(i => i?.practitioner?.id === id) < 0)
                    const careTeams = values.practitioner_ids.filter(id => res.data.data.findIndex(i => i?.practitioner?.id === id) >= 0)

                    if (providers.length > 0) {
                        apiCareTeam.create(props.patientId, {
                            practitioner_ids: providers,
                            encounter_id: props.encounter_id,
                        }).then(() => {
                            notification('Add providers to care team successfully', 'success')
                            formik.resetForm()
                            setSubmitting(false)
                            if (careTeams.length === 0) props.onOke?.()
                        })
                    }
                    if (careTeams.length > 0) {
                        formik.resetForm()
                        setSubmitting(false)
                        setIsWarning(careTeams.map(id => providerOptions.find(item => item?.value === id)?.label))
                    }


                })

            } catch (error) {
                notification((error as ErrorType)?.data?.message || 'Internal Error', 'error')
            } finally {
                setSubmitting(false)
            }
        }
        //update
        else if (props.careTeamId && props.patientId) {
            setSubmitting(true)
            try {
                const rest = await apiCareTeam.update(
                    props.patientId,
                    props.careTeamId as string | number,
                    { ...values },
                )
                notification('Care Team updated successfully', 'success')
                setSubmitting(false)
                formik.resetForm()
                props.onOke?.()
            } catch (error) {
                notification('Internal Error', 'error')
            } finally {
                setSubmitting(false)
            }
        }
    }

    const getFamilyPsychiatricById = async (careTeamId) => {
        setLoading(true)
        try {
            // const rest = await apiCareTeam.getId(props.patientId, careTeamId)
            // if (rest.data.Status === 'SUCCESSED') {
            //     const practitioner_ids = rest.data.familyMedicalHistory.practitioner_ids

            //     setInitialValues({
            //         practitioner_ids,
            //     })

            //     const listCheckox: { value: string; label: string; checked: boolean }[] = []
            //     optionsChecked.forEach((item) => {
            //         let checked = practitioner_ids.find((check) => check === item.value)
            //         if (checked) {
            //             listCheckox.push({ ...item, checked: true })
            //         } else {
            //             listCheckox.push({ ...item, checked: false })
            //         }
            //     })

            //     setOptionsChecked(listCheckox)
            // }
        } catch (error) {
            notification((error as ErrorType).data?.message || 'Internal Error', 'error')
        } finally {
            setLoading(false)
        }
    }

    async function getProvidersData() {
        try {
            const response = await getProivders({
                page: 1,
                size: 100000,
            })
            setProviderOptions(response.data?.map(item => ({ label: `${item?.first_name || ""} ${item?.last_name || ''}`, value: item.id })) || [])
        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        if (props.careTeamId) {
            getFamilyPsychiatricById(props.careTeamId)
        }
    }, [props.careTeamId])

    useEffect(() => {
        getProvidersData()
    }, [])


    const formik = useFormik<CareTeamInterfaces>({
        validationSchema,
        initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    const optionsCareTeams = [
        { label: 'Change in appetite', value: '1' },
        { label: 'Dysphoria', value: '12' },
        { label: 'Bloating', value: '123' },
        { label: 'Cramps', value: '124' },
        { label: 'Sleep disturbance', value: '125' },
    ]


    const handleSelect = (payload, checked) => {
        let record = [...formik.values.practitioner_ids]
        if (checked) {
            record.push(payload.value)
        } else {
            record = record.filter((item) => item !== payload.value)
        }
        formik.setFieldValue('practitioner_ids', record)
    }

    const modalTitle = props.careTeamId
        ? 'Update Care Team'
        : 'Add Provider To Care Team'

    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="outpatient-psychiatric-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12} style={{ width: '670px' }}>

                        <MultiSelect
                            label={'Provider Name'}
                            value={formik.values.practitioner_ids}
                            options={providerOptions}
                            handleChange={handleSelect}
                        />
                    </Grid>


                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={submitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.careTeamId ? 'Update' : 'Submit'}
                    />
                    {isWarning.length > 0 &&
                        <ErrorDialog
                            open={isWarning.length > 0}
                            dismissError={() => {
                                setIsWarning([])
                                props.onOke?.()
                            }}
                            error={isWarning} />
                    }
                </Grid>
            </Grid>
        </Modal>
    )
}

export { CareTeamForm }
export default CareTeamForm
