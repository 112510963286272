import { Grid, Typography, Button } from '@material-ui/core';
import React from "react";
import { makeStyles } from '@material-ui/styles';
import bgImage from './icons/bg_end_meeting.png'
import appLogo from '../../assests/Logos/tm2u_logo_150.png'
import avatarCrop from './icons/avatar_crop.png'
import TimerIcon from './icons/TimerIcon'
import TeleInvalidLinkBg from './icons/TeleInvalidLinkBg';
import TeleWaveInvalidLinkBg from './icons/TeleWaveInvalidLinkBg';

const useStyles = makeStyles(() => ({
  container: {
    // backgroundImage: `url(${bgImage})`,
    background: '#F5F8FF',
    // backgroundSize: 'cover',
    height: '100%'
  },
  box: {
    margin: 'auto',
    marginTop: 140,
    width: 'fit-content',
    zIndex: 1,
  },
  bg: {
    height: '70%', width: '100%', position: 'absolute', bottom: 0, left: 0, zIndex: 0 , overflow: 'hidden'
  },
  name:{
    lineHeight: 'unset',
    fontSize: 32,
    fontWeight: 500,
    color: '#303E4E',
    marginTop: 72,
  },
  end_text:{
    lineHeight: 'unset',
    fontSize: 16,
  },
  appLogo:{
    width: 112,
    position: 'absolute',
    top: 44,
    left:60,
  },
  button:{
    background: '#5571c6',
    color: 'white',
    fontSize: 15,
    fontWeight: 400
  },
  avatar_crop:{
    position: 'relative'
  },
  avatar_crop_icon:{
    position: 'absolute',
    left: 32,
    top: 39,
    borderRadius:'50%',
  }
}))

export default function InvalidLink() {
  const classes = useStyles()

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.box} container alignItems="center" direction="column">
          <img
            className={classes.appLogo}
            src={appLogo}
            alt="TeleMed2U"
        />
        <div className={classes.avatar_crop}>
        <TeleInvalidLinkBg/>
        {/* <img
            className={classes.avatar_crop_icon}
            src={avatarCrop}
            alt="TeleMed2U"
        /> */}
        </div>
        
      <Typography className={classes.name}>Link Expired</Typography>
      <Grid container style={{ marginTop: 16,
          marginBottom: 24,
        }} alignItems="center" justifyContent="center">
          <Typography className={classes.end_text}>The meeting time has expired. Please create a new meeting</Typography>
        </Grid>

      <Button href={"/"} variant="contained"  className={classes.button}>Go to Home page</Button>
      </Grid>
      <div className={classes.bg}>
        <TeleWaveInvalidLinkBg/>
      </div>
    </Grid>
  );
}
