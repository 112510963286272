import * as actionType from '../actionType'
import { setLoading, setError } from './loader'
import {
    getMacros,
    addMacro,
    updateMacro,
    inactiveMacro,
    searchMacros,
    getMacroList as getMacroListAPI,
    getPractitionerMarco,
} from '../../services/Macros/macros'

export const getMacroList = (payload) => {
    return (dispatch) => {
        getMacroListAPI(payload)
            .then((res) => {
                dispatch(setMacroList(res.data.data)) // TODO: BE response structure { data:{ data: [] }} , consider to fix!
            })
            .catch((err) => {
                dispatch(setError(true))
            })
    }
}

export const addNewMacro = (payload, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        addMacro(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                onError && onError(err && err.data ? err.data.message : '')
            })
    }
}

export const editMacro = (payload, onSuccess, onError) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        updateMacro(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                onError && onError(err && err.data ? err.data.message : '')
            })
    }
}

export const deleteMacro = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        inactiveMacro(payload)
            .then((res) => {
                dispatch(setLoading(false))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const searchMacro = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        searchMacros(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setMacroList(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}


export const getPractitionerMacroList = (id,payload) => {
    return (dispatch) => {
        getPractitionerMarco(id,payload)
            .then((res) => {
                dispatch(setPractitionerMacroList(res.data.data)) 
            })
            .catch((err) => {
                dispatch(setError(true))
            })
    }
}

export const setPractitionerMacroList = (value) => {
    return {
        type: actionType.SET_PRACTITIONER_MACRO_LIST,
        value,
    }
}

export const setMacroCount = (value) => {
    return {
        type: actionType.SET_TOTAL_MACRO_COUNT,
        value,
    }
}

export const setMacroList = (value) => {
    return {
        type: actionType.SET_MACRO_LIST,
        value,
    }
}
