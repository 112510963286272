import React, { useEffect, useRef, useState } from 'react'
import { IconButton, InputAdornment, TextFieldProps } from '@material-ui/core'
import { TextField } from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'

import SearchIcon from 'assests/icons/search_icon.svg'
import { debounce } from 'lodash'

interface Props {
    debounceSearchTime?: number
    onSearch?: (text: string) => any
}

const SearchField = (props: TextFieldProps & Props) => {
    const [searchText, setSearchText] = useState('')

    const search = useRef(
        debounce(
            (text: string) => props.onSearch && props.onSearch(text),
            props.debounceSearchTime || 500,
        ),
    )

    useEffect(() => {
        search.current(searchText)
    }, [searchText])

    return (
        <TextField
            style={{ minWidth: 300 }}
            placeholder="Search..."
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            variant="outlined"
            sx={{
                '& .MuiOutlinedInput-root': {
                    fontSize: 14,
                    height: 38,
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                    height: 0,
                    padding: '12px !important',
                },
            }}
            inputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <img src={SearchIcon} alt=""></img>
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="start">
                        {!!searchText.length && (
                            <IconButton size="small" onClick={() => setSearchText('')}>
                                <CloseIcon
                                    style={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
                disableUnderline: true,
            }}
            {...props}
        />
    )
}

export { SearchField }
export default SearchField
