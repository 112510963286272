import * as actionType from '../actionType'

const initialState = {
    clinicalNotesList: [],
    missingMedicalCoding: [],
    addendumList: [],
    isSuccessCreateAddendum: null,
    isLoadingAddendum: false,
    isLoadingGetListAddendum: false,
    errorCreateAddendum: null,
    totalCount: 0,
    pageMissingMedicalCoding: 0,
    isUpdatingMedicalCoding: false,
    activeNote: '',
    currentNote: '',
    updateMedication: false,
    updateMedicalCoding: false,
    saveMedicalCodingPermision: false,
    syncAptForCN: false,
    setActivePlan:false,
    saveAndCloseButtonVisible: {
        0: false
    },
    saveAndCloseDialog: {
        0: false
    },
    currentEncounter: {},
    allowError: false,
    isTabChange: false,
    onHandleScrollChangeCategory: {}
}

const clinicalNotes = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_CLINICAL_NOTES_LIST: {
            return {
                ...state,
                clinicalNotesList: action.value,
            }
        }
        case actionType.SET_IS_UPDATING_MEDICAL_CODING: {
            return {
                ...state,
                isUpdatingMedicalCoding: action.value,
            }
        }
        case actionType.SET_MISSING_MEDICAL_CODING: {
            return {
                ...state,
                missingMedicalCoding: action.value,
            }
        }
        case actionType.SET_PAGE_MISSING_MEDICAL_CODING: {
            return {
                ...state,
                pageMissingMedicalCoding: action.value,
            }
        }
        case actionType.SET_CLINICAL_NOTES_COUNT: {
            return {
                ...state,
                totalCount: action.value,
            }
        }
        case actionType.GET_ALL_ADDENDUM_BY_CLINICAL_NOTE: {
            return {
                ...state,
                addendumList: action.value,
            }
        }
        case actionType.SET_IS_SUCCESS_CREATE_ADDENDUM: {
            return {
                ...state,
                isSuccessCreateAddendum: action.value,
            }
        }
        case actionType.SET_ERROR_CREATE_ADDENDUM: {
            return {
                ...state,
                errorCreateAddendum: action.value,
            }
        }
        case actionType.SET_IS_LOADING_ADDENDUM: {
            return {
                ...state,
                isLoadingAddendum: action.value,
            }
        }
        case actionType.SET_IS_LOADING_GET_LIST_ADDENDUM: {
            return {
                ...state,
                isLoadingGetListAddendum: action.value,
            }
        }
        case actionType.SET_ACTIVE_NOTE: {
            return {
                ...state,
                activeNote: action.value,
            }
        }
        case actionType.SET_CURRENT_NOTE: {
            return {
                ...state,
                currentNote: action.value,
            }
        }
        case actionType.UPDATING_MEDICATION_LIST: {
            return {
                ...state,
                updateMedication: action.value,
            }
        }
        case actionType.UPDATING_MEDICAL_CODING_FORCEFULLY:{
            return {
                ...state,
                updateMedicalCoding: action.value,
            }
        }
        case actionType.PERMISSION_SAVE_MEDICAL_CODING:{
            return {
                ...state,
                saveMedicalCodingPermision: action.value,
            }
        }
        case actionType.SYNC_APPOINTMENT_CLINICALNOTE:{
            return {
                ...state,
                syncAptForCN: action.value,
            }
        }
        case actionType.SET_CATEGORY_PLAN:{
            return {
                ...state,
                setActivePlan: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        case actionType.SET_SHOW_SAVE_AND_CLOSE_BUTTON: {
            return {
                ...state,
                saveAndCloseButtonVisible: action.value
            }
        }
        case actionType.SET_SHOW_SAVE_AND_CLOSE_DIALOG: {
            return {
                ...state,
                saveAndCloseDialog: action.value
            }
        }
        case actionType.SET_CURRENT_ENCOUNTER: {
            return {
                ...state,
                currentEncounter: action.value
            }
        }
        case actionType.SET_ALLOW_ERROR: {
            return {
                ...state,
                allowError: action.value
            }
        }
        case actionType.SET_TAB_CHANGE: {
            return {
                ...state,
                isTabChange: action.value
            }
        }
        case actionType.SET_CATEGORY_ONHANDLESCROLL: {
            return {
                ...state,
                onHandleScrollChangeCategory: action.value
            }
        }
        default:
            return state
    }
}

export default clinicalNotes
