import React from 'react';

export default function TeleSendMessageIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.57934 4.44576L17.9016 8.60687C21.6349 10.4735 21.6349 13.5263 17.9016 15.393L9.57934 19.5541C3.97934 22.3541 1.69462 20.0597 4.49462 14.4694L5.34045 12.7874C5.55434 12.3597 5.55434 11.6499 5.34045 11.2221L4.49462 9.53048C1.69462 3.94021 3.98906 1.64576 9.57934 4.44576Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.62256 12H10.8726Z" fill="white"/>
<path d="M5.62256 12H10.8726" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>



  );
}
