import { Box, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import moment from 'moment'
import React from 'react'
import TeleCalendarIcon from '../../../assests/icons/TeleCalendarIcon'
import AlertErrorDialog from '../../../components/shared/Elements/AlertErrorDialog'
import { getPatientListForUsers } from '../../../services/Patient/patient'
import { SelectEl } from '../../../shared/elements'
import DatePickerInput from './DatePickerInput'
import TimePickerInput from './TimePickerInput'

const useStyles = makeStyles(() => ({
    required_text: {
        color: 'red',
    },
}))

function Staff({ values, setFieldValue, appointmentModal, label, events, errors, touched }) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const loading = open && options.length === 0
    const [errorMessage, setErrorMessage] = React.useState(null)

    React.useEffect(() => {
        onTextChange('')
    }, [])

    async function onTextChange(searchQuery) {
        try {
            const response = await getPatientListForUsers({
                page: 1,
                filterString: searchQuery,
            })

            setOptions(
                response.data.patient.map((patient) => {
                    return {
                        ...patient,
                        id: patient.user_id,
                        title: `${patient.first_name} ${patient.last_name}`,
                        subtitle: patient.dob ? moment(patient.dob).format('MM/DD/YYYY') : '-',
                    }
                }),
            )
        } catch (error) {
            console.log({ error })
        }
    }

    const handleDownloadCalendar = () => {
        if (ics) {
            const cal = ics()
            const nameCalendar = label ? `Calendar ${label}` : 'Calendar'
            if (events?.data?.length > 0) {
                events.data.forEach((event) => {
                    cal.addEvent(
                        `${moment(new Date(event.start).getTime()).format('HH:mm')} - ${moment(
                            new Date(event.end).getTime(),
                        ).format('HH:mm')}  ${event.patientName}  ${event.appointmentType}`,
                        '',
                        event.patientName,
                        event.start,
                        event.end,
                    )
                })
                cal.download(nameCalendar)
            } else {
                setErrorMessage(
                    'There is no event at all. Please create some events to download calendar!',
                )
            }
        } else {
            setErrorMessage('Something went wrong. Please try again in a few minutes!')
        }
    }

    return (
        <Grid container item spacing={3} className="modal-spacer" style={{ marginTop: 24 }}>
            <>
                <Grid container item alignItems="unset" spacing={4} className="add-button">
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Category
                        </FormLabel>
                        <SelectEl
                            name="appointmentType"
                            placeholder="Select category"
                            value={values.category}
                            options={values.categoryOption}
                            selectProps={{
                                style: {
                                    height: 40,
                                },
                            }}
                            onChange={(e) => {
                                setFieldValue('category', e.target.value)
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Staff Member <span className={classes.required_text}>*</span>
                        </FormLabel>
                        <SearchProviderInput
                            value={values.provider}
                            handleInputChange={(e) => {
                                setFieldValue('provider', e.target.value)
                            }}
                            isProviderOnly={true}
                            handleAddRow={(value) => {
                                setFieldValue('provider', value?.id)
                            }}
                            error={touched?.provider && Boolean(errors?.provider)}
                            helperText={touched?.provider && errors?.provider}
                        />
                    </Grid>
                </Grid>
                <Grid container item alignItems="unset" spacing={4} className="add-button">
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Date <span className={classes.required_text}>*</span>
                        </FormLabel>
                        <DatePickerInput
                            onChange={(e) => {
                                const timeStartValue = values.start.format('HH:mm')
                                const timeEndValue = values.end.format('HH:mm')
                                setFieldValue(
                                    'start',
                                    moment(`${e.format('YYYY-MM-DD')} ${timeStartValue}`),
                                )
                                setFieldValue(
                                    'end',
                                    moment(`${e.format('YYYY-MM-DD')} ${timeEndValue}`),
                                )
                            }}
                            appointmentModal={{
                                start: values.start,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Time <span className={classes.required_text}>*</span>
                        </FormLabel>
                        <TimePickerInput
                            values={{
                                start: values.start,
                                end: values.end,
                            }}
                            appointmentModal={appointmentModal}
                            onChange={(e) => {
                                const timeStartValue = values.start.format('YYYY-MM-DD')
                                const timeEndValue = values.end.format('YYYY-MM-DD')
                                setFieldValue('start', moment(`${timeStartValue} ${e.startTime}`))
                                setFieldValue('end', moment(`${timeEndValue} ${e.endTime}`))
                            }}
                            error={touched?.start && Boolean(errors?.start)}
                            helperText={touched?.start && errors?.start}
                        />
                    </Grid>
                </Grid>
            </>

            <>
                <Grid container item alignItems="flex-end" spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel component="p" className="input-form-label">
                            Note
                        </FormLabel>
                        <Box
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 'auto',
                                },
                            }}>
                            <TextField
                                id="additional_info"
                                name="additional_info"
                                variant="outlined"
                                value={values.additional_info}
                                fullWidth
                                multiline
                                minRows={4}
                                placeholder="Text area"
                                onChange={(e) => setFieldValue('additional_info', e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </>
            <Grid container item alignItems="flex-end" style={{ marginBottom: 12 }} spacing={2}>
                <Grid container item xs={12} alignItems="center" onClick={handleDownloadCalendar}>
                    <TeleCalendarIcon />
                    <Typography
                        style={{
                            color: '#5571C6',
                            marginLeft: 4,
                            cursor: 'pointer',
                        }}>
                        Add to personal calendar
                    </Typography>
                </Grid>
            </Grid>
            {Boolean(errorMessage) && (
                <AlertErrorDialog
                    message={errorMessage}
                    open={Boolean(errorMessage)}
                    handleClose={() => {
                        setErrorMessage(false)
                    }}
                />
            )}
        </Grid>
    )
}

export default Staff
