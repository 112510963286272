import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { LoadingButton } from '@mui/lab'
import IconAPerson from 'components/TwilioVideo/icon/iconAPerson'
import IconEditAPerson from 'components/TwilioVideo/icon/iconEditAPerson'
import {
    addPsychotherapy,
    getPsychotherapyById,
    updatePsychotherapy,
} from 'containers/ClinicalNotes/api/services'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNotification } from 'shared/elements/Notification'
import LoadingPage from 'utilities/loading-page'

const useStyles = makeStyles({
    subLabel: {
        fontSize: 14,
    },
    sectionHeading: {
        marginRight: 16,
        fontSize: 14,
    },
    textFieldWrap: {
        height: '100%',
    },
    header: {
        fontSize: '14px',
        color: '#667689',
        backgroundColor: '#FBFCFC',
        border: '1px solid #E9ECF1',
        borderRadius: 80,
        padding: '8px 16px',
        display: 'flex',
        alignItems: 'center',
    },
    val_txt: {
        marginLeft: 8,
        color: '#303E4E',
        fontWeight: 'bold',
    },
    input_box: {},
})

const PsychotherapyNote = ({
    fieldName,
    values,
    psychotherapy_note_id,
    patient,
    onChange,
    clinical_note_id,
    patient_id,
    fetchClinicalNote,
    nonEditable
}) => {
    const classes = useStyles()

    const notification = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingData, setIsLoadingData] =
        useState(false)
    const [psyData, setPsyData] = useState({
        dataTime: moment().clone().unix(),
        providerName: '',
        description: '',
    })

    const handleCancel = () => {
        getPsycho()
    }

    const handleSave = async () => {
        setIsLoading(true)
        const payload = {
            clinical_note_id: parseInt(
                clinical_note_id,
                10,
            ),
            description: psyData.description,
            patient_id: parseInt(patient_id, 10),
        }

        if (psychotherapy_note_id) {
            await updatePsychotherapy(
                psychotherapy_note_id,
                payload,
            )
                .then((res) => {
                    getPsycho()
                    notification(
                        'The Psychotherapy Note was successfully updated!',
                        'success',
                    )
                })
                .catch(() => {
                    notification(
                        "You don't have permission to create Psychotherapy Note!",
                        'error',
                    )
                })
        } else {
            await addPsychotherapy(payload)
                .then(() => {
                    fetchClinicalNote()
                    notification(
                        'The Psychotherapy Note was successfully created!',
                        'success',
                    )
                })
                .catch(() => {
                    notification(
                        "You don't have permission to create Psychotherapy Note!",
                        'error',
                    )
                })
        }
        setIsLoading(false)
    }

    const getPsycho = async () => {
        setIsLoadingData(true)
        await getPsychotherapyById(
            psychotherapy_note_id,
        ).then((res) => {
            if (res?.data) {
                const {
                    practitioner_last_name,
                    description,
                    practitioner_first_name,
                    updatedAt,
                } = res?.data
                onChange(`${fieldName}.description`,description)
                setPsyData({
                    providerName: `${
                        practitioner_first_name || ''
                    } ${practitioner_last_name || ''}`,
                    dateTime: updatedAt,
                    description,
                })
            }
        })
        setIsLoadingData(false)
    }

    useEffect(() => {
        if (psychotherapy_note_id) {
            getPsycho()
        }
    }, [psychotherapy_note_id])

    return (
        <Grid container spacing={2}>
            {isLoadingData && <LoadingPage />}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={4}
                        className={classes.input_box}>
                        <Typography
                            className={classes.header}>
                            <AccessTimeIcon
                                style={{
                                    fontSize: 16,
                                    marginRight: 6,
                                }}
                            />
                            Date / Time
                            <span
                                span
                                className={classes.val_txt}>
                                {psyData?.dateTime &&
                                    moment(
                                        psyData?.dateTime,
                                    ).format(
                                        'DD MMM YYYY hh:mm:ss a',
                                    )}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.input_box}>
                        <Typography
                            className={classes.header}>
                            <IconEditAPerson />
                            <span style={{ marginLeft: 6 }}>
                                Provider
                            </span>

                            <span
                                className={classes.val_txt}>
                                {psyData.providerName}
                            </span>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        className={classes.input_box}>
                        <Typography
                            className={classes.header}>
                            <IconAPerson
                                style={{ marginRight: 6 }}
                            />
                            <span style={{ marginLeft: 6 }}>
                                Patient
                            </span>

                            <span
                                className={classes.val_txt}>
                                {' '}
                                {patient}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{ height: '100%' }}>
                <Box
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '97%',
                        },
                    }}>
                    <TextField
                        variant="outlined"
                        name={`${fieldName}.description`}
                        className={classes.textFieldWrap}
                        multiline
                        rows={20}
                        fullWidth
                        size="small"
                        value={psyData.description}
                        onChange={(e) => {
                            const { value } = e.target
                            setPsyData((prev) => ({
                                ...prev,
                                description: value,
                            }))
                        }}
                        disabled={nonEditable}
                    />
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                }}>
                <Grid container justify="flex-end">
                    <Button
                        style={{
                            borderRadius: 50,
                            width: 120,
                            background: '#303E4E',
                            marginRight: 12,
                            fontWeight: 'normal',
                        }}
                        disabled={nonEditable}
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        style={{
                            borderRadius: 50,
                            width: 120,
                            fontWeight: 'normal',
                            color: 'white',
                        }}
                        disabled={nonEditable}
                        onClick={handleSave}>
                        {psychotherapy_note_id
                            ? 'Save'
                            : 'Create'}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PsychotherapyNote
