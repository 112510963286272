import React from 'react'
import { ChatsStyle as useStyles } from './styles'
import erikaUser from '../../../../../assests/images/erika_provider.png'
import image4 from '../../../icons/ava_messages.png'

function linkify(inputText) {
    var replacedText, replacePattern1, replacePattern2, replacePattern3

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
    replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>',
    )

    //Change email addresses to mailto:: links.
    // replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    // replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText
}
// @ts-ignore
export default function Chats({ client, msg, time, author }) {
    const classes = useStyles()
    const [fn, ln] = author.split(' ')

    return (
        <div
            className={classes.chats}
            style={{
                justifyContent: client === 'global' ? 'flex-start' : 'flex-end',
                // marginTop: client === 'global' ? "16px" : '8px',
            }}>
            <div className={classes.chatMessage}>
                <div
                    className={classes.profileImageContainer}
                    style={{ flexDirection: client === 'global' ? 'row-reverse' : 'row' }}>
                    <div
                        className={[classes.chatText].join(' ')}
                        style={{
                            backgroundColor: client === 'global' ? '#F5F6F7' : '#F0F4FF',
                            alignItems: client === 'global' ? 'flex-start' : 'flex-end',
                        }}>
                        {/* <p className={classes.chatValue}>
                            {msg}
                        </p> */}
                        {client === 'global' && <div
                            className={classes.chatAuthor}
                            dangerouslySetInnerHTML={{ __html: linkify(author) }}
                        />}
                        <div
                            className={classes.chatValue}
                            dangerouslySetInnerHTML={{ __html: linkify(msg) }}
                        />
                        <p className={classes.chatTime}>
                            {/* {time < 5 ? 'Just now' : `${time} ago` } */}
                            {time < 5 ? 'Just now' : `${time}`}
                        </p>
                    </div>
                    {/* <img className="apt-provider-thumbnail" src={erikaUser} alt="" /> */}
                    {client === 'global' && (
                        <span className={classes.chatInitial}>
                            {/* <span className={classes.nameInitial}>{`${fn[0].toUpperCase()}${ln ? ln[0].toUpperCase() : ''}`}
                        </span> */}
                            <div className={classes.avatar}>
                                <img src={image4} alt="alternative_video" />
                            </div>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
