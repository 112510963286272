import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import _, { debounce } from 'lodash'
import SearchIcon from '../../assests/icons/search_icon.svg'
import { Autocomplete } from '@mui/material'
import { getTaxonomyCode } from 'containers/ClinicalNotes/api/services'
import ellipse from '../../assests/icons/Ellipse.svg'
const renderTextField = ({ name, value, placeholder, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const NewSearchTaxonomyCodeInput = (props) => {
    const { disabled, handleAddRow, error, helperText, name, placeholder } = props

    const taxonomyCodeValue = props.value || ''
    const [value, setValue] = useState()
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false)
    const [primary, setPrimary] = useState(false)
    useEffect(async () => {
        if (taxonomyCodeValue) {
                setValue(taxonomyCodeValue?.id || '')
                setInputValue(taxonomyCodeValue?.label || '')
                setPrimary(taxonomyCodeValue?.isPrimary || '')
        }
    }, [taxonomyCodeValue])

    const getPatientData = useRef(
        debounce(async (val) => {
            try {
                setLoading(true)
                let response = null
                response = await getTaxonomyCode(val)
                const data = response.data.hits.hits.map((item) => ({
                    // ...item._source,
                    // highlightText: item.highlight?.clinicianDescriptor?.[0] || '',
                    label: item._source?.code,
                    id: item._source?.code,
                    display: `${item._source?.code} - ${
                        item._source?.display ? item._source?.display : item._source?.definition
                    }`,
                }))
                setOptions([])
                setOptions(data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }, 800),
    )

    useEffect(() => {
        const abortController = new AbortController()
        let active = true
        if (!open) return
        ;(async () => {
            setLoading(true)

            if (active) {
                await getPatientData.current(inputValue)
            }
            setLoading(false)
        })()

        return () => {
            active = false
            abortController.abort()
        }
    }, [open, value, inputValue])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
                '& .MuiFormHelperText-root': {
                    textTransform: 'inherit',
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                selectOnFocus
                clearIcon={false}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                inputValue={inputValue}
                value={value}
                open={open}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: `<span class="hightlight">${
                                        option.display
                                    }</span>`,
                                }}
                            />
                        </li>
                    )
                }}
                filterOptions={() => {
                    return options
                }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    setOpen(false)
                    handleAddRow(newValue)
                    setValue(newValue?.id)
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return
                    }
                    setInputValue(newInputValue)
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: placeholder || 'Search...',
                        props: {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {primary ? <div> <img src={ellipse} alt=""></img> {"Primary"} </div>: ""}
                                    </>
                                ),
                            },
                            error,
                            helperText,
                            name,
                        },
                    })
                }
            />
        </Box>
    )
}

export default NewSearchTaxonomyCodeInput
