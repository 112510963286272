import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiIconButton-root': {
            color: '#303e4e',
        },
    },
})

interface Props {
    placeholder?: string
    options?: {
        key: string | number
        value: string
    }[]
    onOpen?: () => void
    onClose?: () => void
    onInputChange?: (value: any) => void
    onChange?: (value: any) => void
    error?: boolean
    helperText?: string
    valueSelect?: {
        key: string | number
        value: string
    } | null
    height?: string
    loading?: boolean
}

export const SelectSearchOptions = (props: Props) => {
    const classes = useStyles()

    const {
        placeholder,
        options,
        onOpen,
        onClose,
        onInputChange,
        onChange,
        error,
        helperText,
        valueSelect,
        loading,
        height = '35px',
    } = props

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')

    return (
        <Box
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: height,
                    minHeight: height,
                    padding: '5px 12px !important',
                },
            }}>
            <Autocomplete
                className={classes['autocomplete']}
                loading={loading}
                noOptionsText={!loading && search ? 'No results found' : undefined}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                onClose={() => {
                    setOpen(false)
                    onClose?.()
                }}
                options={options || []}
                value={valueSelect || null}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>{option.value}</span>
                        </li>
                    )
                }}
                autoComplete
                getOptionLabel={(option) => option.value}
                clearIcon={false}
                autoSelect
                disablePortal
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0px 36px 0px 0px',
                            },
                        }}
                        size="small"
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                    />
                )}
                onInputChange={(e, value) => {
                    setSearch(value)
                    onInputChange?.(value)
                }}
                onChange={(e, option) => {
                    onChange?.(option)
                }}
            />
        </Box>
    )
}
