import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles, Typography, Button, Grid } from '@material-ui/core'
import { IconRequestFaile } from '../../TwilioVideo/icon'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 14,
        fontWeight: 400,
        marginTop: 24,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_encounter: {
        fontSize: 15,
        width: '460px',
        borderRadius: 8,
        background: '#5571c6',
        '&:hover': {
            background: '#5571c6',
            opacity: 0.7,
        },
    },
    dialog_content: {
        width: '-webkit-fill-available',
    },
    avatar: {
        width: 45,
        height: 45,
        borderRadius: 45,
        background: 'gray',
        margin: 8,
    },
    information: {
        marginLeft: 16,
    },
    name: {
        color: '#303E4E',
        fontSize: 16,
    },
    iconReques: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
}))

export default function ErrorDialog({ open, handleClose }) {
    const classes = useStyles()
    const title = 'Allow failed'
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <div className={classes.iconReques}>
                    <IconRequestFaile />
                </div>
                <Typography className={classes.header}>Opps, Something went wrong.</Typography>
            </DialogTitle>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                    className={classes.btn_encounter}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}
