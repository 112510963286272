import React from 'react'
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useHistory } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Button, Paper } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector } from 'react-redux'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { Pagination } from 'shared/elements/Pagination'
import { useNotification } from 'shared/elements/Notification'
import { NoRowsOverlayEl } from 'shared/elements'
import Routes from 'constants/routes'
import { apiWaitlist } from 'services/WorkQueue'
import { Button as ButtonCustom } from 'components/custom'
import {
    getLocations,
    getSpecialty,
    getVisitType,
    searchProvider as searchProviderApi,
    updateSchedule,
    bulkDeleteWaitlist
} from 'services/Calendar'
import { SelectSearchInput } from 'shared/elements/FormItem/SelectSearchInput'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import AppointmentBooking from 'containers/AppointmentBooking'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import { useDebounce } from 'shared/hooks'
import SearchIcon from 'assests/icons/search_icon.svg'
import { useStyles } from '../FollowUp/styles'
import { convertWaitlist, days } from './util'
import { LoadingButton } from '@mui/lab'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as FilterIcon } from '../../../assests/images/filter.svg'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import {
    _getClinicalNotesFilterPatient,
    _getClinicalNotesFilterProvider,
} from 'containers/ClinicalNotes/api/services'
import moment from 'moment'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import format from 'date-fns/format'
import { endDateFilterToUTC, formatNumber, startDateFilterToUTC } from 'shared/utils'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import { getPhoneNumberList } from 'services/Patient/patient'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import './waitlistStyles.scss'
import { ReactComponent as BulkDeleteIcon } from '../../../assests/icons/BulkDeleteButton.svg'
import ReportIcon from '@mui/icons-material/Report'

const UserStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
}))
export const Waitlist = () => {
    const notification = useNotification()
    // const classes = useStyles()
    const classes = useStyles()
    const history = useHistory()
    const definedStyles = UserStyles()
    const [mounted, setMounted] = useState(false)

    const [loading, setLoading] = useState(false)
    const [listWaitlist, setListWaitlist] = useState([])
    const [pagination, setPagination] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [filter, setFilter] = useState({
        specialty_id: -1,
        visit_type_id: -1,
        prefer_provider_id: -1,
        service_location_id: -1,
        day: -1,
    })

    const { locations, visitTypes } = useAppointmentBookingFormData(null)

    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [listLocation, setListLocation] = useState([])
    const [listProvider, setListProvider] = useState([])
    const [listDay, setListDay] = useState([])
    const [patientData, setPatientData] = useState([])
    const [currentPage, setCurrentPage] = useState(null)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    const [bundleId, setBundleId] = useState(null)
    const [loadingPatientOption, setLoadingPatientOption] = useState(false)
    const [currentPageProvider, setCurrentPageProvider] = useState(1)
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = useState(null)
    const [activeRange, setActiveRange] = useState(false)
    const [SingleDate, setSingleDate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [loadingPhoneNumberOption, setLoadingPhoneNumberOption] = useState(false)
    const [phnData, setPhnData] = useState([])
    const [currentPagePhNo, setCurrentPagePhNo] = useState(1)
    const [totalPageNoOfPhNo, setTotalPageNoOfPhNo] = useState(null)

    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        patient_id: false,
        specialty_id: false,
        listDay_id: false,
        provider_id: false,
        visit_id: false,
        location_id: false,
        phoneNumber: false,
    })
    const [scrollPosition, setScrollPosition] = useState({
        patient_id: null,
        specialty_id: null,
        listDay_id: null,
        provider_id: null,
        visit_id: null,
        location_id: null,
        phoneNumber: null,
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient_id: '',
        provider_id: '',
        phoneNumber: '',
    })
    const [searchQueries, setSearchQueries] = useState({
        patient_id: null,
        specialty_id: null,
        listDay_id: null,
        provider_id: null,
        visit_id: null,
        location_id: null,
        phoneNumber: null,
    })
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [loadingSpecialty, setLoadingSpecialty] = useState(false)
    const [loadingVisitType, setLoadingVisitType] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [loadingProvider, setLoadingProvider] = useState(false)

    const [appointmentModal, setAppointmentModal] = useState({ id: null, status: false })
    const [appointmentSubmitting, setAppointmentSubmitting] = useState(false)

    const [searchProvider, setSearchProvider] = useState('')
    const [patientName, setPatientName] = useState('')

    const [selectedRows, setSelectedRows] = useState([])
    const [allRowCheckbox, setAllRowCheckbox] = useState(false)
    const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false)

    const statusOptions = useSelector((state) => state.setting.appointmentStatus)

    const debouncedProvider = useDebounce(searchTextOffilter.provider_id, 500)
    const debouncedPatientName = useDebounce(patientName, 500)
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient_id, 500)
    const debouncedPhNo = useDebounce(searchTextOffilter.phoneNumber, 500)

    const handleSelectRows = (waitlistId, checkedValue) => {
        if (checkedValue) {
            let check = []
            setSelectedRows((state) => {
                check = [
                    ...state,
                    waitlistId
                ]
                return check
            })

            if (check?.length === listWaitlist?.length) setAllRowCheckbox(checkedValue)
        }
        else {
            setSelectedRows(selectedRows?.filter(selectedRowItem => selectedRowItem !== waitlistId))
            setAllRowCheckbox(false)
        }
    }

    const handleSelectAllRows = (checkedValue) => {
        setAllRowCheckbox(checkedValue)
        if (checkedValue) {
            setSelectedRows(() => listWaitlist?.map(waitlistItem => waitlistItem?.id))
        }
        else setSelectedRows([])
    }

    const handleDeleteDialogBox = () => {
        if (selectedRows?.length > 0) {
            setOpenBulkDeleteDialog(true)
        }
    }

    const handleBulkDelete = () => {
        const payload = {
            ids: selectedRows
        }
        bulkDeleteWaitlist(payload)?.then(() => {
            notification('Waitlist(s) deleted successfully!', 'success')
            getWaitlist()
            setSelectedRows([])
            setAllRowCheckbox(false)
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => setOpenBulkDeleteDialog(false))
    }

    useEffect(() => {
        setAllRowCheckbox(false)
        setSelectedRows([])
    }, [page])

    const columns = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient_name',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row: { patient_name, patient_id } }) => {
                return (
                    <Box
                        onClick={() => {
                            window.open(
                                Routes.PATIENT.DASHBOARD.replace(':id', patient_id),
                                '_blank',
                            )
                        }}
                        sx={{ cursor: 'pointer' }}>
                        <DataGridCell value={patient_name} highlight />
                    </Box>
                )
            },
        },
        {
            headerName: 'Phone Number ',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Availability',
            headerClassName: 'super-app-theme--header',
            field: 'availability',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Clinical Practice',
            headerClassName: 'super-app-theme--header',
            field: 'clinical_practice',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Specialty',
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Visit Type',
            headerClassName: 'super-app-theme--header',
            field: 'visit_type',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Preferred Provider',
            headerClassName: 'super-app-theme--header',
            field: 'pereferred_provider',
            flex: 0.5,
            sortable: false,
        },
        // {
        //     headerName: 'Service Location',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'service_location',
        //     flex: 0.7,
        //     sortable: false,
        // },
        {
            headerName: 'Next Appointment',
            headerClassName: 'super-app-theme--header',
            field: 'next_appointment_time',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value, row: { next_appointment_id } }) => {
                return (
                    <Box
                        sx={{ cursor: next_appointment_id ? 'pointer' : 'default' }}
                        onClick={() => {
                            if (next_appointment_id) {
                                setAppointmentModal({ id: next_appointment_id, status: true })
                            }
                        }}>
                        <DataGridCell value={value} highlight />
                    </Box>
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => handleEdit(row.id),
                        },
                        {
                            text: 'Delete',
                            handler: () => handleDelete(row.id),
                        },
                    ]}
                    deleteMessage="Are you sure you want to delete this waitlist?"
                />
            ),
        },
        {
            headerName:
                <CheckboxOption
                    style={{ marginLeft: "36px" }}
                    option={{
                        label: '',
                        value: false
                    }}
                    checked={allRowCheckbox}
                    onChange={(event) => {
                        handleSelectAllRows(event?.target?.checked)
                    }}
                />,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => {
                return (
                    <CheckboxOption
                        style={{ marginLeft: "36px" }}
                        option={{
                            label: '',
                            value: row?.id
                        }}
                        checked={selectedRows.includes(row?.id)}
                        onClick={(event) => event?.stopPropagation()}
                        onChange={(event) => handleSelectRows(row?.id, event.target.checked)}
                    />
                )
            },
        },
    ]
    const getPatientlist = async (name = '') => {
        try {
            setLoadingPatientOption(true)
            const payload = {
                page: 1,
                size: 20,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ` ${item.middle_name}` : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            setPatientData(tempUserList)
            setCurrentPage(1)
            setBundleId(res?.data?.bundle_id)
            const b = res?.data?.paging?.total / 20

            const remainder = res?.data?.paging?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPatient(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPatientOption(false)
        }
    }
    const handleChangePage = async (name) => {
        if (totalPageNoOfPatient > currentPage) {
            const nextPage = currentPage + 1
            const payload = {
                page: nextPage,
                size: 20,
                bundle_id: bundleId,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ` ${item.middle_name}` : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            const sortedPatientData = [...patientData, ...tempUserList]
            setPatientData(sortedPatientData)
            setCurrentPage(nextPage)
            setBundleId(res?.data?.bundle_id)
        }
    }
    const handleLoadDataProvider = async (name = '') => {
        try {
            setLoadingProvider(true)
            const payload = {
                page: 1,
                limit: 20,
                name: searchTextOffilter.provider_id,
            }
            const res = await _getClinicalNotesFilterProvider(payload)
            const tempUserList = []
            res?.data?.data?.data?.map((item) =>
                tempUserList.push({ key: item?.id, value: item?.name }),
            )
            setListProvider(tempUserList)
            setCurrentPageProvider(1)
            const b = res?.data?.data?.total / 20
            const remainder = res?.data?.data?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = Math.floor(b)
            }
            setTotalPageNoOfProvider(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingProvider(false)
        }
    }

    const handleScrollProvider = async () => {
        if (totalPageNoOfProvider > currentPageProvider) {
            try {
                const nextPage = currentPageProvider + 1
                const payload = {
                    page: nextPage,
                    limit: 20,
                    name: searchTextOffilter.provider_id,
                }
                const res = await _getClinicalNotesFilterProvider(payload)
                const tempUserList = []
                res?.data?.data?.data?.map((item) =>
                    tempUserList.push({ key: item?.id, value: item?.name }),
                )
                const sortedProviderData = [...listProvider, ...tempUserList]
                setListProvider(sortedProviderData)
                setCurrentPageProvider(nextPage)
            } catch (e) {
                notification('An error occurred, please try again!', 'error')
            }
        }
    }
    const getPhoneNumberListForFilter = async () => {
        try {
            setLoadingPhoneNumberOption(true)
            const payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: 0,
            }
            const res = await getPhoneNumberList(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            setPhnData(tempUserList)
            const b = res?.data?.total / 10

            const remainder = res?.data?.total % 10
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPhNo(result)
            if (result > 0) {
                setCurrentPagePhNo(1)
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPhoneNumberOption(false)
        }
    }
    const handleChangePagePhoneNumber = async () => {
        if (totalPageNoOfPhNo > currentPagePhNo) {
            const nextPage = currentPagePhNo + 1
            const payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: currentPagePhNo,
            }
            const res = await getPhoneNumberList(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            const sortedPhoneNumberData = [...phnData, ...tempUserList]
            setPhnData(sortedPhoneNumberData)
            setCurrentPagePhNo(nextPage)
        }
    }
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [setIsAccordionExpanded, isAccordionExpanded],
    )
    const scrollBarPosition = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        [setScrollPosition, scrollPosition],
    )
    const filterSearch1 = (value, name) => {
        setSearchTextOffilter({
            ...searchTextOffilter,
            [name]: value,
        })
    }
    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'patient_id',
                name: 'Patient',
                value: searchQueries.patient_id,
                options: patientData,
                loading: loadingPatientOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.patient_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient_id,
                scrollBarPosition,
                filterValue: searchTextOffilter.patient_id
            },
            {
                key: 'phoneNumber',
                searchInputType: 'phoneNumber',
                name: 'Phone Number',
                value: searchQueries.phoneNumber,
                options: phnData,
                loading: loadingPhoneNumberOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePagePhoneNumber,
                AccordionExpanded: isAccordionExpanded.phoneNumber,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.phoneNumber,
                scrollBarPosition,
                // setFilterValue: setFilterValue,
                filterValue: searchTextOffilter.phoneNumber
            },
            {
                key: 'listDay_id',
                name: 'Availability',
                value: searchQueries.listDay_id,
                options: listDay,
                AccordionExpanded: isAccordionExpanded.listDay_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.listDay_id,
                scrollBarPosition,
            },
            {
                key: 'specialty_id',
                name: 'Speciality',
                value: searchQueries.specialty_id,
                options: listSpecialty,
                loading: loadingSpecialty,
                AccordionExpanded: isAccordionExpanded.specialty_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.specialty_id,
                scrollBarPosition,
            },
            {
                key: 'visit_id',
                name: 'Visit Type',
                value: searchQueries.visit_id,
                options: listVisitType,
                loading: loadingVisitType,
                AccordionExpanded: isAccordionExpanded.visit_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.visit_id,
                scrollBarPosition,
            },
            {
                key: 'provider_id',
                name: 'Preferred Provider',
                value: searchQueries.provider_id,
                options: listProvider,
                filterSearch: filterSearch1,
                loading: loadingProvider,
                handleOnScrollChangePage: handleScrollProvider,
                AccordionExpanded: isAccordionExpanded.provider_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.provider_id,
                scrollBarPosition,
                filterValue: searchTextOffilter.provider_id
            },
            // {
            //     key: 'location_id',
            //     name: 'Location',
            //     value: searchQueries.location_id,
            //     options: listLocation,
            //     loading: loadingLocation,
            //     AccordionExpanded: isAccordionExpanded.location_id,
            //     handleAccordionExpand: handleAccordionExpandNew,
            //     setScrollBarPosition: scrollPosition.location_id,
            //     scrollBarPosition: scrollBarPosition,
            // },
        ]
    }, [
        patientData,
        loadingPatientOption,
        isAccordionExpanded,
        scrollPosition,
        searchQueries,
        listSpecialty,
        loadingSpecialty,
        listDay,
        listProvider,
        loadingProvider,
        listVisitType,
        loadingVisitType,
        scrollBarPosition,
        handleAccordionExpandNew,
        filterSearch1,
        handleChangePage,
        handleScrollProvider,
        listLocation,
        loadingLocation,
        loadingPhoneNumberOption,
        phnData,
        searchTextOffilter
    ])

    const handleChangeSelect = React.useCallback(
        (option, checked, state, field) => {
            let arr = [...(state ? state : [])]
            if (checked) {
                arr.push(option)
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            setSearchQueries({ ...searchQueries, [field]: arr })
            if (arr?.length > 0) {
                if (!totalNoOfFilterUsed.includes(field)) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, field])
                }
            } else if (arr?.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== field)
                setTotalNoOfFilterUsed(newData)
            }
        },
        [searchQueries, totalNoOfFilterUsed],
    )
    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setSearchQueries({
                ...searchQueries,
                [name]: null,
            })
            const newData = totalNoOfFilterUsed.filter((item) => item !== name)
            setTotalNoOfFilterUsed(newData)
        },
        [searchQueries, totalNoOfFilterUsed],
    )
    const clearSearch = () => {
        setSearchQueries({
            patient_id: null,
            specialty_id: null,
            listDay_id: null,
            provider_id: null,
            visit_id: null,
            location_id: null,
        })
        setTotalNoOfFilterUsed([])
    }
    const onChangeFilterData = React.useCallback(
        (field, value) => {
            setSearchQueries({ ...searchQueries, [field]: value })
        },
        [searchQueries],
    )
    const handleDelete = async (id) => {
        try {
            await apiWaitlist.delete(id)
            await getWaitlist()
            notification('Waitlist is deleted successfully!', 'success')
        } catch (error) {
            notification(
                error?.data?.message ||
                error?.message ||
                'An error occurred while deleting the Waitlist!',
                'error',
            )
        }
    }

    const handleEdit = (id) => {
        window.open(
            (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.WAITLIST.EDIT).replace(
                ':id',
                `${id}`,
            ),
            '_blank'
        )
    }

    const getWaitlist = async (start = 1) => {
        setLoading(true)
        try {
            const joinArrString = (arr) => {
                return arr.map((item) => item.key).join()
            }
            const formatChange = (arr) => {
                return arr.map((item) => moment(item).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'))
            }
            let {
                patient_id,
                specialty_id,
                listDay_id,
                provider_id,
                visit_id,
                location_id,
                createdAt,
            } = searchQueries
            patient_id = patient_id?.length ? joinArrString(patient_id) : ''
            specialty_id = specialty_id?.length ? joinArrString(specialty_id) : ''
            listDay_id = listDay_id?.length ? joinArrString(listDay_id) : ''
            provider_id = provider_id?.length ? joinArrString(provider_id) : ''
            visit_id = visit_id?.length ? joinArrString(visit_id) : ''
            location_id = location_id?.length ? joinArrString(location_id) : ''
            createdAt = createdAt?.length ? formatChange(createdAt) : ''

            const {
                data: {
                    listWaitlist: { paging, data },
                },
            } = await apiWaitlist.getList({
                page: start,
                limit,
                specialty_id,
                listDay_id,
                provider_id,
                visit_id,
                location_id,
                patient_id,
                createdAt,
                patient_name: patientName.trim(),
            })
            setListWaitlist(() => data.map((el) => convertWaitlist(el)))
            setTotal(paging.total)
        } catch (e) {
            notification(e?.data?.message || e?.message || 'Cannot get list Waitlist!', 'error')
        } finally {
            setLoading(false)
        }
    }

    const getListSpecialty = async () => {
        try {
            setLoadingSpecialty(true)
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                // list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Specialty!',
                'error',
            )
        } finally {
            setLoadingSpecialty(false)
        }
    }

    const getListVisitType = async () => {
        try {
            setLoadingVisitType(true)
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType({ page: 1, limit: 100000 })
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                // list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        } finally {
            setLoadingVisitType(false)
        }
    }

    const getListLocation = async () => {
        try {
            setLoadingLocation(true)
            const {
                data: { data },
            } = await getLocations({ size: 100000 })
            setListLocation(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.name,
                }))
                // list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Location!',
                'error',
            )
        } finally {
            setLoadingLocation(false)
        }
    }

    const getListDay = async () => {
        try {
            setListDay(() => {
                const list = days.map((day, index) => ({
                    key: index,
                    value: day,
                }))
                // list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(error?.data?.message || error?.message || 'Cannot get list Day!', 'error')
        }
    }

    const getListProvider = async () => {
        try {
            setLoadingProvider(true)
            const { data } = await searchProviderApi(searchProvider)
            setListProvider(() => {
                const list = data.hits.hits.map((el) => ({
                    key: el?._source?.id,
                    value: el?._source?.fullName,
                }))
                list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Provider!',
                'error',
            )
        } finally {
            setLoadingProvider(false)
        }
    }

    const handleAppointmentSubmit = async (values) => {
        try {
            setAppointmentSubmitting(true)
            await updateSchedule({ id: appointmentModal.id, payload: values })
            setAppointmentModal({ id: null, status: false })
            notification('An appointment was successfully updated!', 'success')
        } catch (error) {
            notification(
                error?.data?.message ||
                error?.message ||
                'An appointment was not successfully updated!',
                'error',
            )
        } finally {
            setAppointmentSubmitting(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (!mounted) return setMounted(true)

            if (page !== 1) return setPage(1)

            const abortController = new AbortController()
            await getWaitlist()
            return () => abortController.abort()
        })()
    }, [filter, debouncedPatientName, searchQueries])

    useEffect(() => {
        (async () => {
            const abortController = new AbortController()
            await getWaitlist(page)
            return () => abortController.abort()
        })()
    }, [page])

    useEffect(() => {
        (async () => {
            const abortController = new AbortController()
            await [getListSpecialty(), getListVisitType(), getListLocation(), getListDay()]
            return () => abortController.abort()
        })()
    }, [])

    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedProvider])
    useEffect(() => {
        getPatientlist()
    }, [debouncedSearchFilterPatientText])
    useEffect(() => {
        if (searchTextOffilter.phoneNumber.length > 2) {
            getPhoneNumberListForFilter()
        } else if (searchTextOffilter.phoneNumber.length <= 2) {
            setPhnData([])
        }
    }, [debouncedPhNo])

    const handleClickRow = (row) => {
        if (row.field === 'patient' || (row.field === 'next_appointment_time' && row.value)) return
        return handleEdit(row.id)
    }

    const removeWaitlistDialogContent = () => {
        return (
            <div style={{ maxWidth: '350px' }}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                    }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <ReportIcon
                            style={{
                                fontSize: '18px',
                                marginRight: '10px',
                                color: '#D22121',
                            }}
                        />
                        <Typography variant="h6" fontWeight="bold">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: 'Remove From Waitlist'
                                }}></div>
                        </Typography>
                    </span>
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <p
                        style={{
                            color: '#303E4E',
                            fontSize: 16,
                            fontWeight: 400,
                        }}>
                        Are you sure you want to remove the selected record(s) from the waitlist queue?
                    </p>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        size="small"
                        className="cancel-btn"
                        onClick={() => setOpenBulkDeleteDialog(false)}
                        style={{ marginRight: '10px', color: '#444' }}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            background: '#D22121',
                            borderRadius: '5px !important',
                        }}
                        onClick={handleBulkDelete}>
                        Remove
                    </Button>
                </span>
            </div>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between">
                    <Grid
                        container
                        flex={1}
                        justifyContent="end"
                        spacing={1}
                        style={{ marginTop: 5, marginBottom: 15 }}
                        className={classes?.subtabDiv}>
                        <Grid item style={{ width: 300 }}>
                            {!activeRange && (
                                <SingleCalenderComp
                                    placeHolder="Date added"
                                    onChange={(e) => {
                                        setSingleDate(e ? format(e, 'MM/dd/yyyy') : '')
                                        onChangeFilterData(
                                            'createdAt',
                                            e
                                                ? [startDateFilterToUTC(e), endDateFilterToUTC(e)]
                                                : [],
                                        )
                                    }}
                                    HideShowIcon={activeRange}
                                    value={SingleDate}
                                    onClear={() => {
                                        setSingleDate('')
                                    }}
                                />
                            )}
                            <Box
                                sx={{
                                    width: 0,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    position: 'absolute',
                                }}>
                                <Paper>
                                    {activeRange && (
                                        <DateRangeComp
                                            placeHolder="Date added"
                                            style={{ marginTop: '20px' }}
                                            onChange={(e) => {
                                                if (e?.startDate && e?.endDate) {
                                                    setStartDate(format(e?.startDate, 'MM/dd/yyyy'))
                                                    setEndDate(format(e?.endDate, 'MM/dd/yyyy'))
                                                    setSingleDate('')
                                                    onChangeFilterData('createdAt', [
                                                        startDateFilterToUTC(e?.startDate),
                                                        endDateFilterToUTC(e?.endDate),
                                                    ])
                                                }
                                            }}
                                            activeRange={activeRange}
                                            dateSeparator="-"
                                            startDate={startDate}
                                            defaultRange={SingleDate || null}
                                            endDate={endDate}
                                            onClear={() => {
                                                setSingleDate('')
                                                setStartDate('')
                                                setEndDate('')
                                                onChangeFilterData('createdAt', [])
                                            }}
                                        />
                                    )}
                                </Paper>
                            </Box>
                        </Grid>

                        <Grid item>
                            <HtmlTooltip
                                title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                                arrow
                                placement="bottom"
                                style={{
                                    background: 'white !important',
                                    color: '#303E4E',
                                }}>
                                <Box style={{ marginTop: '12px' }}>
                                    <AntSwitch
                                        onChange={() => {
                                            setActiveRange(activeRange ? false : true)
                                            if (startDate && endDate && activeRange) {
                                                onChangeFilterData('createdAt', [])
                                                setStartDate('')
                                                setEndDate('')
                                            }
                                        }}
                                        checked={activeRange}
                                    />
                                </Box>
                            </HtmlTooltip>
                        </Grid>
                        <Grid item style={{ marginRight: '30px' }}>
                            <MultiFilter
                                filterOptionsArrayObject={filterOptionsArrayObject}
                                handleChange={handleChangeSelect}
                                handleClearSingleFilter={handleClearSingleFilter}
                                handleClearFilter={clearSearch}
                                totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                            />
                            <ButtonCustom
                                tooltip="Add New Waitlist"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN +
                                        Routes.WORK_QUEUES.FOLLOW_UP.WAITLIST.CREATE,
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                            <HtmlTooltip
                                disableHoverListener
                                title={removeWaitlistDialogContent()}
                                placement='bottom'
                                open={openBulkDeleteDialog}
                            // onClose={() => setOpenBulkDeleteDialog(false)}
                            >
                                <ButtonCustom
                                    tooltip="Remove from waitlist"
                                    tooltip_placement="bottom"
                                    variant="contained"
                                    // color="primary"
                                    style={{
                                        cursor: selectedRows?.length > 0 ? 'pointer' : 'default',
                                        backgroundColor: selectedRows?.length > 0 ? '#D22121' : '#DCDCDC',
                                        margin: 0,
                                        minWidth: 32,
                                        width: 32,
                                        height: 32,
                                        borderRadius: 50,
                                        padding: 7
                                    }}
                                    onClick={handleDeleteDialogBox}
                                >
                                    <BulkDeleteIcon />
                                </ButtonCustom>
                            </HtmlTooltip>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.form_list_container}`}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        className='custom_data_grid'
                        getRowId={(row) => row.id}
                        rows={listWaitlist}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={loading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={setPage}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        onCellClick={handleClickRow}
                    />
                    <AppointmentBooking
                        open={appointmentModal.status}
                        appointmentModal={appointmentModal}
                        onModalClose={() =>
                            setAppointmentModal({
                                id: null,
                                status: false,
                            })
                        }
                        loading={appointmentSubmitting}
                        locationOptionsData={locations.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={visitTypes.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                        onSubmit={handleAppointmentSubmit}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
