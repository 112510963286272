import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    OutlinedInput,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Input,
    FormControlLabel,
    TextField,
    InputAdornment,
    CircularProgress,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Placeholder from 'shared/elements/Placeholder'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@mui/icons-material/Search'
import SearchIconLabel from 'assests/icons/SearchIconLabel'
import { debounce } from 'lodash'
import { getTaxonomyCode } from 'containers/ClinicalNotes/api/services'
// import { Input } from '@material-ui/core'
// import { makeStyles } from '@mui/material/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    options?: string[]
    value: string[]
    label?: string
    onChangeValue: (option: string, checked: boolean) => void
    placeholder?: string
    placeholderSearch?: string
}

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '0',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '40px',
        },
    },
    box_options: {
        padding: '15px 0',
    },
    list_options: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
}))

export const MultiSearchTaxionomyCodeInput = (props: Props) => {
    const { options = [], value, onChangeValue, label, placeholder, placeholderSearch } = props

    const [searchOptions, setSearchOptions] = useState<string[]>([])
    const [loading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState('')

    useEffect(() => {
        setSearchOptions(options)
    }, [])

    // const newOptions = options.map(option => option.label)
    const handleSearch = useRef(
        debounce(async (val) => {
            try {
                setLoading(true)
                const response = await getTaxonomyCode(val)
                const data = response.data.hits.hits.map(
                    (item) => item._id + ' - ' + item._source.display,
                )
                // console.log(data, 'log data2')
                setSearchOptions(data)
                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }, 800),
    )

    const classes = useStyles()
    return (
        <Box>
            {label && (
                <Box component={'p'} className={classes.label}>
                    {label}
                </Box>
            )}
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                {value.length === 0 && (
                    <InputLabel id="demo-multiple-checkbox-label" className={classes.placeholder}>
                        {placeholder ? placeholder : 'Select option'}
                    </InputLabel>
                )}
                <Select
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={value}
                    placeholder="lol"
                    onChange={(e: SelectChangeEvent<typeof value>) => {
                        console.log(e.target)
                    }}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return selected?.join(', ')
                    }}
                    IconComponent={KeyboardArrowDown}
                    // MenuProps={MenuProps}
                >
                    <Box className={classes.box_options}>
                        <Box className={classes.input_search}>
                            <TextField
                                fullWidth
                                placeholder={placeholderSearch ? placeholderSearch : 'Search...'}
                                id="outlined-start-adornment"
                                sx={{ m: 1, width: '25ch' }}
                                value={textSearch}
                                onChange={(e) => {
                                    setTextSearch(e.target.value)
                                    handleSearch.current(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Box sx={{ display: 'flex' }}>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : (
                                                    <SearchIconLabel />
                                                )}
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className={classes.list_options}>
                            {searchOptions.map((option) => {
                                const formatOption = option.split(' - ')[0]
                                const check = value.find((item) => item === formatOption)
                                return (
                                    <Box key={option} className={classes.box_option}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={check ? true : false}
                                                    onChange={(e) => {
                                                        // console.log(e.target.checked)
                                                        onChangeValue(
                                                            formatOption,
                                                            e.target.checked,
                                                        )
                                                    }}
                                                    name={option}
                                                />
                                            }
                                            label={option}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    )
}
