import { FormLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { createDocument } from 'services/Documents'
import { ModalEl as Modal } from 'shared/elements'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { Document, ErrorType } from '../../interfaces'

import { TextBox } from 'shared/elements/FormItem/TextBox'
import moment from 'moment'
import {
    addPsychotherapy,
    getPsychotherapyById,
    updatePsychotherapy,
} from 'containers/ClinicalNotes/api/services'
import { getPatientByUserId } from 'services/Patient/patient'
import { Loading } from 'shared/elements/Loading'
import LoadingPage from 'utilities/loading-page'

interface Props {
    open?: boolean
    disabled?: boolean
    patientId?: string
    id?: string
    onCancel?: (forceReload?: boolean) => void
    refresh?: () => void
}

const useStyles = makeStyles(() => ({
    text: {
        color: '#303E4E',
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 25,
    },
    reset_pass: {
        color: '#5571C6',
        fontSize: 14,
        textAlign: 'end',
        cursor: 'pointer',
        marginTop: 4,
    },
    container: {
        width: '100%',
        background: '#FBFCFC',
        border: '1px solid #F3F6F9',
        borderRadius: 10,
        padding: '32px 20px',
    },
    required_text: {
        color: 'red',
    },
}))

export const PsychotherapyNoteForm = (props: Props) => {
    const notification = useNotification()
    const classes = useStyles()

    const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [patientName, setPatientName] = useState('')
    const [data, setData] = useState({ description: '', patient_id: props.patientId })

    const handleSubmit = async (values) => {
        setSubmitting(true)
        try {
            if (props.id) {
                await updatePsychotherapy(props.id, values)
            } else {
                await addPsychotherapy(values)
            }
            props.refresh?.()

            notification('Psychotherapy created successfully!', 'success')

            setSubmitting(false)
            handleCancel(true)
        } catch (error) {
            setSubmitting(false)

            notification((error as ErrorType).data?.message || 'Something went wrong!', 'error')
        }
    }

    const handleCancel = (forceReload?: boolean) => {
        setData({ description: '', patient_id: props.patientId })
        props.onCancel && props.onCancel(forceReload)
    }

    const getPatient = async () => {
        props.patientId &&
            (await getPatientByUserId(props.patientId).then((res) => {
                const { first_name, last_name } = res?.data
                setPatientName(first_name + last_name)
            }))
    }

    const getPsycho = async () => {
        props.id &&
            (await getPsychotherapyById(props.id).then((res) => {
                setData((prev) => ({ ...prev, description: res?.data?.description }))
            }))
    }

    useEffect(() => {
        const abortController = new AbortController()

        setLoading(true)

        Promise.allSettled([getPatient(), getPsycho()]).finally(() => {
            setLoading(false)
        })

        return () => abortController.abort()
    }, [props.id, props.patientId])

    const validationSchema = yup.object({
        description: yup.string(),
    })
    const formik = useFormik({
        initialValues: data,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={1000}
            open={props.open}
            onClose={handleCancel}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                overflowX: 'hidden',
            }}>
            <Grid container item className={classes.container}>
                {loading && <LoadingPage />}
                <Grid item xs={12}>
                    <Typography className={classes.text}>
                        {props?.id ? 'Edit The' : 'Add New'} Psychotherapy Process Note
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <FormLabel component="p" className="input-form-label">
                        Time: {moment().format('hh:mm a')}
                    </FormLabel>
                </Grid>
                <Grid item xs={3}>
                    <FormLabel component="p" className="input-form-label">
                        Date: {moment().format('DD MMM YYYY')}
                    </FormLabel>
                </Grid>
                <Grid item xs={3}>
                    <FormLabel component="p" className="input-form-label">
                        Provider: {localStorage.getItem('name')}
                    </FormLabel>
                </Grid>
                <Grid item xs={3}>
                    <FormLabel component="p" className="input-form-label">
                        Patient: {patientName}
                    </FormLabel>
                </Grid>

                <Grid item xs={12}>
                    <TextBox
                        disabled={props.disabled}
                        label="Description"
                        name="description"
                        value={formik.values.description}
                        multiline
                        minRows={20}
                        placeholder=""
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        onChange={(value) => formik.setFieldValue('description', value)}
                    />
                </Grid>
            </Grid>

            <Grid container style={{ padding: '20px 20px', width: '50%', margin: 'auto' }}>
                <Footer
                    disabled={props.disabled}
                    loading={submitting}
                    onCancel={() => handleCancel(false)}
                    onOk={formik.handleSubmit}
                />
            </Grid>
        </Modal>
    )
}
