import React from 'react'
import { shortenString } from 'utilities/stringOperations'
import { Box, Collapse, Divider, IconButton, Typography } from '@material-ui/core'

import { ReactComponent as FaxIcon } from 'assests/icons/faxContactIcon.svg'
import { ReactComponent as PhoneIcon } from 'assests/icons/phone.svg'
import TextBox from 'shared/elements/FormItem/TextBox'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { ReactComponent as FaxEditIcon } from 'assests/icons/faxEditIcon.svg'
import { ReactComponent as SaveIcon } from 'assests/icons/NoteCreate.svg'
import { ReactComponent as CancelIcon } from 'assests/icons/NoteCreateClose.svg'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { TextField } from '@mui/material'

const formatPhoneNumber = (value) => {
    if(!value) return

    let val = value.replace(/\D/g, '')
    
    if (val?.length > 0) {
        if (val?.length < 4) {
            val = `(${val}`
        } else if (val?.length >= 4 && val?.length < 7) {
            val = `(${val?.substring(0, 3)}) ${val?.substring(3)}`
        } else if (val?.length >= 7) {
            val = `(${val?.substring(0, 3)}) ${val?.substring(3, 6)}-${val?.substring(6)}`
        }
    }

    return val
}

const InlineEditableFields = ({isEditRecipient, classes, onChangeEditRecipientValues, fieldName, fieldValue, textFieldValue}) => {
    const displayValue = fieldValue === "" ? ( fieldName === 'name' ? "Enter Recipient" : '(000) 000-0000' ) : fieldValue
    return (
        <>
            {isEditRecipient ?
                <span style={{ display: 'flex' }}>
                    <TextField
                        className={classes.labelStyle} 
                        sx={{ 
                            width: '140px', 
                            '& .MuiOutlinedInput-root': {
                                height: '20px !important'
                            },
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        value={textFieldValue}
                        onChange={(event) => onChangeEditRecipientValues(event?.target?.value, fieldName)}
                        placeholder={fieldName === 'name' ? 'Enter Recipient' : '(000) 000-0000'}
                    />
                    {(fieldName !== 'contact') && <span style={{ color: 'red' }}>*</span>}
                </span>
                :
                <span className={classes.labelStyle} style={{ display: 'flex', color: fieldValue === "" ? '#667689' : '#303E4E' }}>
                    {displayValue}
                    {(fieldName !== 'contact' && fieldValue === "") && <span style={{ color: 'red' }}>*</span>}
                </span>
            }
        </>
    )
}

function Form({
    classes,
    faxData,
    isEditRecipient,
    onChangeEditRecipientValues,
    editedRecipientValues,
    onSaveEditedRecipient,
    onChangeFaxData,
    showAllCheck,
    handleCheckShowAllCheck,
    isModuleExpanded,
    handleCheckModuleAllChecks,
    getSelectedCount,
    handleModuleExpand,
    handleChangeModuleChecks,
    handleChangeModuleProps
}) {

    return (
        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '90%' }}>
            <Box className={classes.boxLeft}>
                <div className={classes.label2}>Build Cover Sheet</div>
                <Divider style={{ background: '#E9ECF1', width: '95%' }} />

                <Box style={{ marginTop: '13px' }}>
                    <span className={classes.labelStyle}>Date</span>: {faxData?.date}
                </Box>

                <Box style={{ marginTop: '20px' }} className={classes.label2}>
                    Fax Sender:
                </Box>
                <Box
                    style={{
                        borderRadius: '8px',
                        border: '1px solid #E3E7ED',
                        background: '#E7EDF4',
                        padding: '11px',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                        gap: 20,
                        width: 520
                    }}>
                    <div className={classes.labelStyle} style={{ width: '150px' }}>{shortenString(faxData?.sender?.name, 18)}</div>
                    <div style={{
                        display: 'flex',
                        gap: 15
                    }}>
                        <div className={classes.labelStyle}>
                            <PhoneIcon />
                            <span style={{ marginLeft: '10px' }}>{faxData?.sender?.contact}</span>
                        </div>
                        <div className={classes.labelStyle}>
                            <FaxIcon />
                            <span style={{ marginLeft: '10px' }}>{faxData?.sender?.fax}</span>
                        </div>
                    </div>
                </Box>

                <Box style={{ marginTop: '20px' }} className={classes.label2}>
                    Fax Recipient:
                </Box>
                <Box
                    style={{
                        borderRadius: '8px',
                        border: isEditRecipient ? '1px solid #5571C6' : '1px solid #E3E7ED',
                        background: '#E7EDF4',
                        padding: '11px',
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px',
                        gap: 20,
                        width: isEditRecipient ? 580 : 520,
                        boxShadow: isEditRecipient ? '0px 4px 4px 0px rgba(0, 0, 0, 0.10)' : 'none'
                    }}>
                    <div className={classes.labelStyle} style={{ width: '150px' }}>
                        <InlineEditableFields
                            isEditRecipient={isEditRecipient}
                            classes={classes}
                            onChangeEditRecipientValues={onChangeEditRecipientValues}
                            fieldName={'name'}
                            fieldValue={shortenString(faxData?.recipient?.name, 18)}
                            textFieldValue={editedRecipientValues?.name}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        gap: 15
                    }}>
                        <div className={classes.labelStyle} style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneIcon />
                            <span style={{ marginLeft: '10px' }}>
                                <InlineEditableFields
                                    isEditRecipient={isEditRecipient}
                                    classes={classes}
                                    onChangeEditRecipientValues={onChangeEditRecipientValues}
                                    fieldName={'contact'}
                                    fieldValue={faxData?.recipient?.contact}
                                    textFieldValue={formatPhoneNumber(editedRecipientValues?.contact)}
                                />
                            </span>
                        </div>
                        <div className={classes.labelStyle} style={{ display: 'flex', alignItems: 'center' }}>
                            <FaxIcon />
                            <span style={{ marginLeft: '10px' }}>
                                <InlineEditableFields
                                    isEditRecipient={isEditRecipient}
                                    classes={classes}
                                    onChangeEditRecipientValues={onChangeEditRecipientValues}
                                    fieldName={'fax'}
                                    fieldValue={faxData?.recipient?.fax}
                                    textFieldValue={formatPhoneNumber(editedRecipientValues?.fax)}
                                />
                            </span>
                        </div>
                        {isEditRecipient ?
                            <div style={{ display: 'flex' }}>
                                <IconButton onClick={() => onSaveEditedRecipient()}>
                                    <CancelIcon />
                                </IconButton>
                                <IconButton onClick={() => onSaveEditedRecipient(true)}>
                                    <SaveIcon />
                                </IconButton>
                            </div>
                            :
                            <IconButton onClick={() => handleChangeModuleProps('isEditRecipient', true)}>
                                <FaxEditIcon style={{ width: 14, height: 14 }} />
                            </IconButton>
                        }
                    </div>
                    
                </Box>

                <Divider style={{ background: '#E9ECF1', marginTop: isEditRecipient ? '10px' : '6px', width: 545 }} />

                <Box style={{ marginTop: '20px' }} className={classes.label2}>
                    Subject:
                </Box>
                <Box style={{
                    marginTop: 10
                }}>
                    <TextBox 
                        name='subject'
                        value={faxData?.subject}
                        onChange={(value) => onChangeFaxData(value, 'subject')}
                        multiline
                        placeholder=' '
                        variant='outlined'
                        minRows={isEditRecipient ? 15 : 16}
                        style={{ maxWidth: 540 }}
                        className={classes.subjectTextBox}
                    />
                </Box>

                <Box style={{
                    margin: '10px 0px'
                }}>
                    <CheckboxOption
                        option={{
                            value: false,
                            label: 'Mark as urgent'
                        }}
                        checked={faxData?.markAsUrgent}
                        onChange={(event) => onChangeFaxData(event?.target?.checked, 'markAsUrgent')}
                    />
                </Box>
            </Box>

            <Divider orientation='vertical' variant='middle' style={{ height: '650px', marginTop: 7 }} />

            <Box className={classes.boxRight}>
                <div className={classes.label2}>Document Selection</div>
                <Divider style={{ background: '#E9ECF1' }} />

                <Box style={{ paddingLeft: 10, margin: '10px 0' }} className={classes.selectAllCheckboxContainer}>
                    <CheckboxOption
                        option={{
                            label: 'Select all records',
                            value: false
                        }}
                        checked={showAllCheck?.all}
                        onChange={(event) => handleCheckShowAllCheck(event?.target?.checked)}
                    />
                </Box>

                <Box style={{ display: 'flex', flexDirection: 'column', gap: 15 }} className={classes.moduleCheckBoxContainer}>
                    {
                        faxData?.documentSelection?.map((item, idx) => {
                            return (
                                <Collapse key={idx} in={isModuleExpanded[item?.keyName]} collapsedSize={'38px'}>
                                    <Box style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: isModuleExpanded[item?.keyName] ? 240 : 'auto',
                                        border: '1px solid #EAECF2', 
                                        borderRadius: 8, 
                                        backgroundColor: '#F5F6F9',
                                        alignItems: 'center',
                                    }}>
                                        <Box key={item?.keyName} style={{ 
                                            display: 'flex', 
                                            // justifyContent: 'space-between', 
                                            padding: '0px 10px',
                                            alignItems: 'center',
                                            marginBottom: isModuleExpanded[item?.keyName] ? 10 : 0,
                                            width: '97%'
                                        }}>
                                            
                                            <CheckboxOption
                                                option={{
                                                    label: '',
                                                    value: showAllCheck?.modules[item?.keyName]
                                                }}
                                                checked={showAllCheck?.modules[item?.keyName]}
                                                onChange={(event) => {
                                                    handleCheckModuleAllChecks(item, idx, event?.target?.checked)
                                                }}
                                            />
                                            <div onClick={() => handleModuleExpand(item?.keyName)}
                                                style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'center', 
                                                    gap: 10, 
                                                    width: '100%' ,
                                                    cursor: 'pointer'
                                                }}>
                                                <Typography className='module_item_label'>{item?.label}</Typography>

                                                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                                    <Typography className={classes.selectedCountText}>
                                                        {getSelectedCount(item)?.count}
                                                        {'/'}
                                                        {item?.content?.length}
                                                        {' selected'}
                                                    </Typography>
                                                    <IconButton>
                                                        {isModuleExpanded[item?.keyName] ? <ExpandLess style={{ color: '#667689' }} /> : <ExpandMore style={{ color: '#667689' }} />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Box>

                                        <Box style={{
                                            padding: '0 10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 10,
                                            height: isModuleExpanded[item?.keyName] ? 180 : 0,
                                            marginRight: 10,
                                            overflowY: 'scroll',
                                            width: '96%'
                                        }}>
                                            {isModuleExpanded[item?.keyName] && (
                                                item?.content?.length === 0 ?
                                                    <Typography>No Data!</Typography>
                                                    :
                                                    item?.content?.map((contentItem, contentItemIndex) => {
                                                        return (
                                                            <Box key={contentItem?.id} className={classes.moduleContent} style={{
                                                                borderRadius: 5,
                                                                border: '1px solid #DDE1E6',
                                                                background: '#ffffff',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                padding: '0 20px 0 10px',
                                                                gap: 20
                                                            }}>
                                                                <Box style={{
                                                                    width: Object.keys(contentItem)?.length > 3 ? '50%' : '95%'
                                                                }}>
                                                                    <CheckboxOption
                                                                        option={{
                                                                            label: contentItem?.date || contentItem?.name,
                                                                            value: contentItem?.selection
                                                                        }}
                                                                        checked={contentItem?.selection}
                                                                        onChange={(event) => handleChangeModuleChecks(
                                                                            idx,
                                                                            contentItemIndex,
                                                                            item?.keyName,
                                                                            event?.target?.checked
                                                                        )}
                                                                    />
                                                                </Box>
                                                                
                                                                {Object.keys(contentItem)?.length > 3 &&
                                                                    <Typography>{contentItem?.name}</Typography>
                                                                }
                                                            </Box>
                                                        )
                                                    })
                                            )}
                                        </Box>
                                    </Box>
                                </Collapse>
                            )
                        })
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default Form
