import { Box, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { DatePicker as DatePickerCustom } from 'shared/elements/DatePicker/DatePicker'
import clsx from 'clsx'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { usePatient } from 'containers/PatientsSetting/hooks'
import * as yup from 'yup'
import { Loading } from 'shared/elements/Loading'
import { LoadingButton } from '@mui/lab'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getMedicalProblemList } from 'services/Patient/patient'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { SelectEl } from '../../../../shared/elements'
import { SearchDrug } from '../../../../shared/elements/FormItem/SearchComponents/SearchDrug'
import { SelectAndEnterText } from 'shared/elements/FormItem/SelectAndEnterText'
import { useFormik } from 'formik'
import { ReactComponent as CalenderIcon } from '../../../../assests/images/calender_icon.svg'
const useStyles = makeStyles(() => ({
    colorContent: {
        color: '#667689',
    },
    ellipsis__text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    button_create_form: {
        background: '#5571C6 !important',
        color: '#fff !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    required: {
        color: "#E81A1A"
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        margin: "4px 0"
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
    },
    heightSetter: {
        '& .MuiOutlinedInput-root': {
            height: '45px !important',
            minHeight: "26px !important",
            paddingRight: "2px !important",
        },
        '& .MuiBox-root .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
            // borderRadius: '0px 6px 6px 0px !important',
            // borderLeft: '0 !important',
            width: '100% !important',
            padding: '0 !important',
            '& .MuiInputAdornment-root .MuiButtonBase-root img': {
                height: '20px',
                width: '10px',
                // marginLeft: '5px'
            }
        }
    },
    datefieldStyle: {
        '& .MuiOutlinedInput-root': {
            height: '45px !important',
            minHeight: "26px !important",
            paddingRight: "3px !important",
        },
        '& .MuiBox-root .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
            // borderRadius: '0px 6px 6px 0px !important',
            // borderLeft: '0 !important',
            width: '100% !important',
            padding: '0 !important',
            '& .MuiInputAdornment-root .MuiButtonBase-root img': {
                height: '20px',
                width: '10px',
                marginLeft: '5px'
            }
        }
    },
    button_cancel:{
        background: '#fff !important',
        color: '#303E4E !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    }
}))

export const INITIAL_VALUES = {
    id: '',
    severity: '',
    allergy: {
        coding: [],
        text: '',
    },
    status: {
        code: 'active',
        display: '',
    },
    patient_id: '',
    note: '',
    reaction: {
        code: '',
        display: '',
    },
    // external_id: '',
    // rcopia_id: '',
    onset_date: '',
    encounter_id: '',
}
const Dropdown = (props) => {
    const classes = useStyles()

    return (
        <SelectEl
            selectProps={{
                style: {
                    height: 40,
                },
            }}
            renderValue={(sel) => {
                return (typeof sel === 'string' && !sel) || !sel ? (
                    <Typography className={classes.colorContent}>{props.placeholder}</Typography>
                ) : (
                    <Typography className={clsx(classes.ellipsis__text, classes.colorContent)}>
                        {props.options.find((item) => item.key === sel)?.value}
                    </Typography>
                )
            }}
            {...props}
        />
    )
}

function FormMedication({ isSubmitting, itemId, handleSubmit, loadingSchedule, values, touched, errors, setFieldValue, setFieldError, patientId, patientInfo, handleClose }) {
    const [problemOptions, setProblemOptions] = useState([])
    const classes = useStyles()

    async function getProblemsData(params) {
        try {
            //@todo update correct params
            // const response = await getMedicalProblemList(patientId, 1, 10000)

            // setProblemOptions(
            //     response.data?.data?.map((item) => ({
            //         // key: item.id,
            //         // value: item?.icd_code?.code + ' - ' + item?.icd_code?.display,
            //         // value: `${item?.icd_code?.code} - ${item?.icd_code?.display}`,
            //         key: `${item?.icd_code?.code}`,
            //         value: `${item?.icd_code?.code} - ${item?.icd_code?.display}`,
            //     })) || [],
            // )
        } catch (error) {
            console.log({ error })
        }
    }

    const handleChangeDate = (value, key) => {
        if (!value) {
            return setFieldValue(key, null)
        }
        // mm/dd/yyyy
        const regex = /^\d{2}\/\d{2}\/\d{4}$/
        if (regex.test(value)) {
            setFieldValue(key, moment(value, 'MM/DD/YYYY'))
        }
    }

    const formSubmit = () => {
        handleSubmit()
    }

    useEffect(() => {
        if (patientId) {
            getProblemsData()
        }
    }, [patientId])


    return (
        <Grid container>
            <Grid container item>
                <Grid
                    container
                    item>
                    <Grid container item alignItems="flex-end">
                        <Grid item xs={12} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                               Medication information <span className={classes.required}>*</span></FormLabel>
                            <SearchDrug
                                required
                                name="drug"
                                value={values.drug}
                                placeholder="Search Medication"
                                error={touched.drug?.display && Boolean(errors.drug?.display)}
                                helperText={touched.drug?.display && errors.drug?.display}
                                onChange={async (value, form, strength) => {
                                    // console.log(value, 'z value')
                                    await setFieldValue('drug', value)
                                    await setFieldValue('form', form)
                                    await setFieldValue('strength', strength)
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item alignItems="flex-start" style={{ marginTop: "4px" }} spacing={2}>
                        <Grid item xs={6} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                               Form <span className={classes.required}>*</span>
                            </FormLabel>
                            <TextBox
                                required
                                readOnly={true}
                                variant="outlined"
                                value={values.form}
                                fullWidth
                                error={touched.form && Boolean(errors.form)}
                                helperText={touched.form && errors.form}
                                placeholder="Enter form"
                                onChange={(e) => setFieldValue('form', e)}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                               Strength <span className={classes.required}>*</span>
                            </FormLabel>
                            <TextBox
                                required
                                readOnly={true}
                                variant="outlined"
                                value={values.strength}
                                fullWidth
                                error={touched.strength && Boolean(errors.strength)}
                                helperText={touched.strength && errors.strength}
                                placeholder="Enter strength"
                                onChange={(e) => setFieldValue('strength', e)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="flex-end" spacing={2} style={{ marginTop: "4px" }}>
                        <Grid item xs={6} className={classes.datefieldStyle}
                        >
                            <FormLabel component="p" className={classes.formLabel}>
                                Start Date
                            </FormLabel>
                            <DatePickerCustom
                                icon={<CalenderIcon />}
                                name="start_date"
                                value={values.start_date}
                                onChange={(value) => handleChangeDate(value, 'start_date')}
                                maxDate={moment()}
                                minDate={moment(patientInfo.dob)}
                                notDefaultValue={true}
                                error={touched.start_date && Boolean(errors.start_date)}
                                helperText={touched.start_date && errors.start_date}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.datefieldStyle}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Stop Date
                            </FormLabel>
                            <DatePickerCustom
                                icon={<CalenderIcon />}
                                name="stop_date"
                                value={values.stop_date}
                                onChange={(value) => handleChangeDate(value, 'stop_date')}
                                minDate={
                                    values.start_date
                                        ? moment.max(moment(patientInfo.dob), values.start_date)
                                        : moment(patientInfo.dob)
                                }
                                notDefaultValue={true}
                                error={touched.stop_date && Boolean(errors.stop_date)}
                                helperText={touched.stop_date && errors.stop_date}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "11px", marginTop: "4px" }}>
                        <FormLabel component="p" className={classes.formLabel}>
                           Direction
                        </FormLabel>
                        <TextBox
                            variant='outlined'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '79px !important'
                                },
                                ' & .MuiInputBase-input': {
                                    height: "60px !important"
                                }
                            }}
                            placeholder='Describe here  and instruction for a patient'
                            multiline
                            maxRows={4}
                            value={values.direction_patient}
                            onChange={(e) => setFieldValue('direction_patient', e)}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} justifyContent='flex-end' >
                    <div style={{ height: "", marginTop: "17px", marginBottom: "5px", display: "flex", justifyContent:'flex-end', gap: 10 }}>
                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={(event) => {
                                handleClose()
                            }}>
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create_form}
                            onClick={(event) => {
                                formSubmit()
                            }}>
                            {itemId ? 'Update medication' : 'Add medication'}
                        </LoadingButton>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormMedication
