
import {
  getService,
  postService,
  deleteService,
  patchService,
  putService
} from 'utilities/request/restClient'

export const apiPsychiatricMedicalHistory = {
//   create(patienrId: string | number |boolean, params) {
//       return postService(
//           `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/psychiatric-medication`,
//           params,
//       )
//   },
  create(patienrId: string | number |boolean, params) {
      return postService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/medication`,
          params,
      )
  },
  getPsychiatricMedication(patienrId, payload) {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    return getService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/psymed?${queryString}`,
    )
  },
  createPsychiatricMedication(patienrId: string | number |boolean, params) {
    return postService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/psymed`,
        params,
    )
  },
  getAll(patienrId, payload) {
      const queryString = new URLSearchParams({
          ...payload,
      }).toString()
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/medication?${queryString}`,
      )
  },
  getId(patientId, psychiatricMedicalId) {
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/medication/${psychiatricMedicalId}`,
      )
  },
  update(patientId: string | number | boolean, psychiatricMedicalId: string | number , payload) {
      return putService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/medication/${psychiatricMedicalId}`,
          payload,
      )
  },
  updatePsychiatricMedication(patientId: string | number | boolean, psychiatricMedicalId: string | number , payload) {
    return putService(
        `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/psymed/${psychiatricMedicalId}`,
        payload,
    )
 },
  delete(patientId, psychiatricMedicalId ) {
      return deleteService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/medication/${psychiatricMedicalId}`
      )
  },
}