import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const mapOptions = (assessment = []) => {
    return assessment.map((a) => ({
        label: a.name,
        value: a._id,
        seq: a.seq,
    }))
}

const Plan = ({
    fieldName,
    master,
    values,
    onChange,
    lastNarrative,
    copyValues,
    copyAble,
    macros,
    nonEditable
}) => {
    const options = mapOptions(master?.planMasters)

    const handleCheckChange = (e, option) => {
        const { name, value, checked } = e.target
        const { plan_opt } = values
        if (checked) {
            onChange(name, [
                ...plan_opt,
                { id: value, label: option.label },
            ])
        } else {
            onChange(
                name,
                plan_opt.filter((uv) => uv.id !== value),
            )
        }
    }
    const handleSelectMacro = (value) => {
        if (nonEditable) { return }
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const foundValues = []
            for (const element of copyValues?.plan_opt) {
                if (typeof element === 'object' && element !== null) {
                    foundValues.push(element);
                }
            }
            const finalValues = {
                plan_opt: [...new Set([...foundValues, ...values?.plan_opt])],
                narrative: `${copyValues?.narrative} ${values?.narrative}`
            }

            if (foundValues?.length !== 0 || copyValues?.narrative !== '') {
                onChange(fieldName, finalValues)
                resolve()
            } else {
                reject()
            }
        })
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 20,
            }}>

            {/* <CopyBar content={copyValues}
                handleSelectMacro={handleSelectMacro} /> */}
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {options.map((option, index) => (
                    <>
                        <CheckboxOption
                            disabled={nonEditable}
                            key={index}
                            name={`${fieldName}.plan_opt`}
                            option={option}
                            onChange={(e) =>
                                handleCheckChange(e, option)
                            }
                            checked={
                                values?.plan_opt.some(
                                    (item) =>
                                        item.id ===
                                        option.value,
                                )
                                    ? true
                                    : false
                            }
                        />
                        {[9, 13, 15].includes(
                            option.seq,
                        ) && (
                                <Box
                                    sx={{
                                        marginBottom: 35,
                                    }}></Box>
                            )}
                    </>
                ))}
            </Box>
            <NarrativeAndMacro
                hideTextNarrative={true}
                hideGenerateBtn={true}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                // textCharecterlimit={false}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default Plan
