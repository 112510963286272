
import {
    deleteService,
    getService,
    postService,
    putService
} from 'utilities/request/restClient'


export const patientContact = {
    create(params, patient_id) {
        return postService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patient_id}/emergency_contact`,
            params,
        )
    },

    getList(patient_id) {
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patient_id}/emergency_contact`,
        )
    },


}