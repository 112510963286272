import React, { useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'

import CustomTabs from 'components/shared/Elements/CustomTabs'
import { usePatient } from 'containers/PatientsSetting/hooks'
import Routes from 'constants/routes'
import { Button as ButtonCustom } from 'components/custom'
import { useNotification } from 'shared/elements/Notification'

import PatientInfor from '../PatientInfor'
import { ActiveProblems } from './ActiveProblems'
import { AuditLogs } from './AuditLogs'
import { useStyles } from './style'
import { useHistory, useParams } from 'react-router-dom'
import { MedicalProblemForm } from './NewProblem'

const sectionsTabs = [
    {
        key: 234234,
        label: 'Active Problems',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Audit Logs',
        _id: 2,
    },
]

interface Props {
    isInClinicalNote: boolean
    isAddingFromClinicalNote: boolean
    patient_Id: string
    encounter_id: string
    afterActionOk?: () => void
    handleAddICDMedicalCoding?: () => void
    nonEditable: boolean
}

const MedicalProblems = ({
    isAddingFromClinicalNote,
    isInClinicalNote,
    patient_Id,
    encounter_id,
    nonEditable,
    afterActionOk,
    handleAddICDMedicalCoding,
}: Props) => {
    const classes = useStyles()
    const { id } = useParams<{ id: string }>()
    const [patientId, setPaitentId] = useState('')
    // const { patientInfo } = usePatient(patientId)

    const history = useHistory()
    const notification = useNotification()

    const [selectedTab, setSelectedTab] = useState(0)
    const [appointmentModal, setAppointmentModal] = useState<{
        status: boolean
        itemId: string | null | undefined
    }>({
        status: false,
        itemId: null,
    })

    useEffect(() => {
        setPaitentId(patient_Id || id)
    }, [patient_Id, id])

    const [cycleCount, setCycleCount] = useState(0)

    const appointmentModalToggle = (forceReload: boolean = false, itemId?: string) => {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId,
        })

        if (forceReload) setCycleCount(cycleCount + 1)
    }

    const handleClickEdit = (problemId: string) => {
        appointmentModalToggle(false, problemId)
    }

    // const handleRowClick = (params) => {
    //     console.log('paramsparams')
    //     changePassDialogToggle(params.row)
    // }

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ padding: !isInClinicalNote ? '16px 24px' : '0' }}>
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(
                            Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(
                                ':id',
                                patientId,
                            ),
                        )
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Medical Problems</Typography>
                        <Grid item>
                            <Grid container spacing={2} wrap={'nowrap'}>
                                <Grid item>
                                    <ButtonCustom
                                        tooltip="Add New Problem"
                                        variant="contained"
                                        color="primary"
                                        disabled={nonEditable}
                                        onClick={() => {
                                            // if (
                                            //     !patientInfo.rcopia_id ||
                                            //     !patientInfo.external_id
                                            // ) {
                                            //     return notification(
                                            //         `Patient ${patientInfo.first_name || patientId
                                            //         } doesn't exist in Dr.First`,
                                            //         'warning',
                                            //     )
                                            // }
                                            appointmentModalToggle()
                                        }}
                                        fullWidth>
                                        <AddIcon
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                    </ButtonCustom>
                                </Grid>
                                {!isInClinicalNote && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn_print}
                                            onClick={() => appointmentModalToggle()}
                                            fullWidth>
                                            <LocalPrintshopOutlinedIcon
                                                style={{
                                                    fontSize: 18,
                                                    color: '#667689',
                                                    marginRight: 8,
                                                }}
                                            />
                                            Print
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <CustomTabs
                            contents={sectionsTabs}
                            selectedTab={selectedTab}
                            onChangeTab={(tabId: number) => {
                                setSelectedTab(tabId)
                            }}
                        />
                    </Grid>
                )}

                {selectedTab === 0 ? (
                    <ActiveProblems
                        nonEditable={nonEditable}
                        patient_Id={patient_Id}
                        isInClinicalNote={isInClinicalNote}
                        cycleCount={cycleCount}
                        onClickEdit={handleClickEdit}
                        afterActionOk={afterActionOk}
                    />
                ) : (
                    <AuditLogs />
                )}

                {appointmentModal.status && (
                    <MedicalProblemForm
                        isAddingFromClinicalNote={isAddingFromClinicalNote}
                        handleAddICDMedicalCoding={handleAddICDMedicalCoding}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        problemId={appointmentModal.itemId}
                        open={appointmentModal.status}
                        onClose={appointmentModalToggle}
                        afterActionOk={afterActionOk}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(MedicalProblems)
