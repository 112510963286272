export const APPOINTMENT_FLOW = {
    NEW: 'new',
    FOLLOW_UP: 'follow-up',
    RESCHEDULE: 'reschedule',
};

export const SPECIALITY_TYPE = {
    NON_BEHAVIORAL: 'non-behavioral',
    BEHAVIORAL: 'behavioral',
};

export const APPOINTMENT_STATUS_CODE = {
    PROPOSED: 'proposed',
    PENDING: 'pending',
    BOOKED: 'booked',
    ARRIVED: 'arrived',
    FULFILLED: 'fulfilled',
    CANCELLED: 'cancelled',
    NOSHOW: 'noshow',
    OPEN: 'open',
    ENTERED_IN_ERROR: 'entered-in-error',
    CHECKED_IN: 'checked-in',
    WAITLIST: 'waitlist',
};

export const APPOINTMENT_TYPE = {
    IN_CLINIC: 'in-clinic',
    TELE_CONSULTANT: 'tele-consultant',
};

export const PATIENT_TYPE = {
    CHILD: 'child',
    ADULT: 'adult',
};

export const BOOKING_TYPE = {
    CHECKUP: 'checkup',
    EMERGENCY: 'emergency',
    FOLLOWUP: 'followup',
    ROUTINE: 'routine',
    WALKIN: 'walkin',
};
