import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'
import { addDays } from 'date-fns'

import './style.scss'
import DateRangeField from './DateRangeField'
import './default.scss'
import { InputAdornment, TextField } from '@material-ui/core'
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from 'moment'

const DateRangeComp = (props) => {
    // date state
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ])

    const [formedValue, setFormedValue] = useState("")


    // open close
    const [open, setOpen] = useState(false)
    // get the target element to toggle 
    const refOne = useRef(null)

    useEffect(() => {

        dateRangeSetter()
        valueFormatFortxtField(props.dateSeparator)
        if(props.dateRangeModalOpen){
            setOpen(true)
        }
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    useEffect(() => {
        valueFormatFortxtField(props.dateSeparator)
    }, [props.endDate])

    const dateRangeSetter = () => {
        if (props.defaultRange) {
            setRange([{

                startDate: new Date(props.defaultRange),
                endDate: new Date(props.defaultRange),
                key: 'selection'
            }])
        }

        if (props.startDate && props.endDate) {
            setRange([{

                startDate: new Date(props.startDate),
                endDate: new Date(props.endDate),
                key: 'selection'
            }])

        }
    }

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const dateSetter = (item) => {
        setRange([item.selection])
        if ((range[0].startDate && range[0].endDate) && (item.selection.startDate !== item.selection.endDate) && (format(item.selection.startDate, "yyyy-MM-dd") !== format(item.selection.endDate, "yyyy-MM-dd"))) {
            props.onChange(item.selection)
            valueFormatFortxtField(props.dateSeparator)
        }
    }

    const valueFormatFortxtField = (separator = "-") => {
        let dateConstruct = ''
        if ((props.startDate && props.endDate)) {
            dateConstruct = `${props.startDate} ${separator} ${props.endDate}`
            setFormedValue(dateConstruct)

            return
        }
        if (props.defaultRange) {
            dateConstruct = `${props.defaultRange} ${separator} ${props.defaultRange}`
            setFormedValue(dateConstruct)
            return;
        }
        setFormedValue(dateConstruct)


    }

    const clearData = (e) => {
        props.onChange([])

        setFormedValue("")

        setRange([{
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }])
        setOpen((prev) => {
            setOpen(prev)
        })
        props.onClear()
        e.stopPropagation()

    }
    return (
        <>
            {props.activeRange && <TextField
                style={{ width: 280 }}
                hiddenLabel
                id="filled-hidden-label-small"
                variant="outlined"
                size="small"
                placeholder={props.placeHolder ? props.placeHolder : 'Date of Service'}
                value={formedValue}
                onClick={() => { setOpen(true) }}
                mask="__/__/____"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {!formedValue ? <DateRangeIcon style={{ cursor: 'pointer', color: "grey", height: '20px', width: '20px' }} /> :
                                < CancelIcon style={{ cursor: 'pointer', color: "grey", height: '20px', width: '20px' }} onClick={clearData} />}
                        </InputAdornment>
                    ),
                }}
            />}
            <div className="calendarWrap23" ref={refOne}>

                {open && <DateRangeField placeholder1="MM/DD/YYYY" placeholder2="MM/DD/YYYY" onChange={(data) => {
                    setRange([data])
                    if (data.startDate && data.endDate) {
                        setFormedValue(`${format(data.startDate, "MM/dd/yyyy")} - ${format(data.endDate, "MM/dd/yyyy")}`)
                        props.onChange(data)
                    } else {
                        setFormedValue("")
                        props.onChange([])

                    }
                }}
                    startDate={props.defaultRange ? new Date(props.defaultRange) : new Date(props.startDate)}
                    endDate={props.defaultRange ? new Date(props.defaultRange) : new Date(props.endDate)}
                />}


                <div >
                    {open &&
                        <DateRange
                            onChange={(item) => { dateSetter(item) }}
                            moveRangeOnFirstSelection={false}
                            ranges={range}
                            direction="horizontal"
                            className="calendarElement"
                            editableDateInputs={false}
                        />
                    }
                </div>

            </div >
        </>
    )
}

export default DateRangeComp