import React from 'react'
import {
    Box,
    capitalize,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomButton from '../../shared/Elements/CustomButton'
import { PROVIDER_TYPE } from '../../../containers/ClinicalNotes/constants'
import { capitalizeFirstLetter } from '../../../shared/utils'
import moment from 'moment'

const useStyles = makeStyles({
    headerGridContainer: {
        padding: '35px 41px',
    },
    detailText: {
        fontSize: 18,
        color: '#F7F7F7',
    },
    separator: {
        borderLeft: '2px solid #ffffff',
        height: 55,
        width: 2,
        boxSizing: 'border-box',
        marginRight: 10,
        marginLeft: 10,
    },
    signedByText: {
        color: '#516983',
        fontSize: 14,
    },
    drNameText: {
        marginTop: 10,
        fontSize: 22,
        fontWeight: 'bolder',
    },
    drTypeText: {
        marginBottom: 10,
        fontSize: 14,
        fontWeight: 'bold',
    },
    apptTimeText: {
        color: '#516983',
        fontSize: 14,
    },
    btnContainer: {
        gap: 20,
    },
})

const renderSaveDraftButton = () => {
    return <CustomButton label="Save draft" color="white" />
}

const renderLockNoteButton = () => {
    return <CustomButton label="Lock note" />
}

const Footer = ({
    children,
    providerType,
    loggedInProvider,
}) => {
    const classes = useStyles()

    return (
        <Box
            sx={{
                padding: '16px 32px',
                background: '#ffffff',
            }}>
            <Grid container justifyContent="space-between">
                {providerType === PROVIDER_TYPE.SENIOR && (
                    <Grid item>
                        <>
                            <Typography
                                className={
                                    classes.signedByText
                                }>
                                Electronically Signed by:{' '}
                            </Typography>
                            <Typography
                                className={
                                    classes.drNameText
                                }>
                                Dr.{' '}
                                {capitalize(
                                    loggedInProvider?.first_name ||
                                        '',
                                )}{' '}
                                {capitalize(
                                    loggedInProvider?.last_name ||
                                        '',
                                )}
                                , MD
                            </Typography>
                            <Typography
                                className={
                                    classes.drTypeText
                                }>
                                {
                                    loggedInProvider?.practice_name
                                }
                            </Typography>
                            <Typography
                                className={
                                    classes.drTypeText
                                }>
                                {capitalizeFirstLetter(
                                    loggedInProvider
                                        ?.speciality?.name,
                                )}
                            </Typography>

                            <Typography
                                className={
                                    classes.apptTimeText
                                }>
                                {moment().format(
                                    'MM/DD/YYYY',
                                )}
                            </Typography>
                            <Typography
                                className={
                                    classes.apptTimeText
                                }>
                                {moment().format('hh:mm A')}
                            </Typography>
                        </>
                    </Grid>
                )}

                {children}
            </Grid>
        </Box>
    )
}

export default Footer
