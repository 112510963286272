import {
    Button,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    Popover,
    Chip,
    FormControl,
    IconButton,
    InputAdornment,
    FilledInput,
    Tabs,
    Tab,
    Divider,
} from '@material-ui/core'
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled'
import { Button as ButtonCustom } from '../../../components/custom'
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { isEmpty } from 'lodash'

import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AlertErrorDialog from '../../../components/shared/Elements/AlertErrorDialog'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import { SelectEl } from '../../../shared/elements'
import { Box, Grid, Tooltip, tooltipClasses } from '@mui/material'
import { makeStyles, withStyles } from '@material-ui/styles'
import { SelectSearchOptionsTimezone } from 'shared/elements/FormItem/SelectSearchOptionsTimezone'
import MultipleSelect from 'shared/elements/Select/MultipleSelect'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import { CalendarIcon } from '@mui/x-date-pickers'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ReactComponent as FilterIcon } from '../../../assests/images/filter.svg'
import { ReactComponent as ReloadIcon } from '../../../assests/images/Reload.svg'
import SearchIcon from '../../../assests/icons/search_icon.svg'
import AccordionFilter from 'shared/elements/Select/AccordionFilter'
import { getDateInClassicFormat } from 'utilities/dateOperations'
import { ReactComponent as TopPanelCalendarIcon } from '../../../assests/icons/calendar.svg'
import { ReactComponent as NextDateArrow } from '../../../assests/icons/next-arrow.svg'
import { ReactComponent as PreviousDateArrow } from '../../../assests/icons/pre-arrow.svg'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { handleGetEncounterQueueList, setDateEncounterQueueList } from 'store/actions/provider'
import { Calendar } from 'react-date-range'
import { useNotification } from 'shared/elements/Notification'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import { ReactComponent as TodayIcon } from 'assests/icons/todayIcon.svg'
import { getDraftAppointments } from '../../CalendarScheduler/helpers'
import { VIEW } from '../constant'
import { _getClinicalNotesFilterProvider } from 'containers/ClinicalNotes/api/services'
import { useDebounce } from 'shared/hooks'
import { DateCalendar as MuiDateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { dateCalendarSx } from './styles.ts'

// const Tab = styled(TabUnstyled)`
//     font-family: Rubik;
//     cursor: pointer;
//     font-size: 14px;
//     background-color: transparent;
//     color: #303e4e;
//     width: 100%;
//     padding: 6px 16px;
//     margin: 6px 6px;
//     border: none;
//     border-radius: 5px;
//     display: flex;
//     justify-content: center;

//     &:hover {
//         background-color: #f0f7ff;
//     }

//     &:focus {
//         color: #fff;
//     }

//     &.${tabUnstyledClasses.selected} {
//         background-color: #5571c6;
//         color: white;
//     }

//     &.${buttonUnstyledClasses.disabled} {
//         opacity: 0.5;
//         cursor: not-allowed;
//     }
// `
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            background: '#5571C6',
            borderRadius: '8px',
            color: 'white',
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '10px !important',
    },
}))((props) => <Tab {...props} />)

const useStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: '130px !important',
        fontWeight: 'normal !important',
        borderRadius: '10px !important',
        background: '#5571C6 !important',
        color: 'white !important',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '18px',
    },
}))

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popperArrow: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 10px #ddd',
        fontSize: 14,
    },
}))

export const dateTimeOption = [
    { key: 'day', value: 'Day' },
    { key: 'week', value: 'Week' },
    { key: 'month', value: 'Month' },
]

const TopPanel = ({
    locationOptions,
    providerOptions,
    visitTypeOptions,
    statusOptions,
    events,
    getProvidersData,
    ...props
}) => {
    const notification = useNotification()
    const localState = JSON.parse(localStorage.getItem('calendar_state'))

    const defaultDateHeader = {
        day: getDateInClassicFormat(props.date),
        month: `${moment(new Date(props.date).getTime()).format('MMMM')} ${moment(
            new Date(props.date).getTime(),
        ).format('YYYY')}`,
        week: weekHeader(new Date(props.date)).formatted,
    }

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [dateSelected, setDateSelected] = React.useState(1)
    const [DateHeader, setDateHeader] = useState(defaultDateHeader[props.view])
    const [monthNumber, setMonthNumber] = useState(new Date(props.date).getMonth() + 1)
    const [updatedDate, setUpdatedDate] = useState(new Date(props.date))
    const [openCalender, setOpenCalender] = useState(false)
    const [flproviderOptions, setFlproviderOptions] = React.useState(null)
    const [flLocations, setFlLocations] = React.useState(null)
    const [flvisitTypeOptions, setFlvisitTypeOptions] = React.useState(null)
    const [flstatusOptions, setFlstatusOptions] = React.useState(null)
    const [totalfilterUsed, setTotalFilterUsed] = React.useState(null)
    const [filterEl, setFilterEl] = React.useState(null)
    const [isProviderClick, setIsProviderClick] = useState(false)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        provider_multi: false,
        location_multi: false,
        visitType_multi: false,
        status_multi: false,
    })
    const [scrollPosition, setScrollPosition] = useState({
        provider_multi: null,
        location_multi: null,
        visitType_multi: null,
        status_multi: null,
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        provider_multi: '',
    })
    const debouncedSearchFilterTextProvider = useDebounce(searchTextOffilter.provider_multi, 500)
    const [providerData, setProviderData] = useState([])
    const [currentPageProvider, setCurrentPageProvider] = useState(1)
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = useState(null)
    const [loadingProviderOption, setLoadingProviderOption] = useState(false)
    const filterSearch1 = (value, name) => {
        setSearchTextOffilter({
            ...searchTextOffilter,
            [name]: value,
        })
    }
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [setIsAccordionExpanded, isAccordionExpanded],
    )
    const scrollBarPosition = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        
        [setScrollPosition, scrollPosition],
    )
    const openFilter = Boolean(filterEl)
    const id = openFilter ? 'simple-popper' : undefined
    const open = Boolean(anchorEl)
    const [value, setValue] = React.useState('day')
    const history = useHistory()
    const classes = useStyles()
    const refOne = useRef(null)
    const calIcon = useRef()
    const dispatch = useDispatch()

    const viewValue = () => {
        if (localState && localState?.view?.selected) {
            return localState?.view?.selected
        }
        return value
    }

    const MonthChanger = (OpType = null) => {
        const dateMaker = updateDate(updatedDate, OpType, 'month')
        // console.log(dateMaker, 'Datemake')
        const updatedFormattedDate = moment(new Date(updatedDate))
        setDateHeader(
            `${updatedFormattedDate.format('MMMM')} ${updatedFormattedDate.format('YYYY')}`,
        )
        props.onNavigate(moment(new Date(updatedDate)), props.view)
        dispatch(
            setDateEncounterQueueList(moment(new Date(updatedFormattedDate)).format('YYYY-MM-DD')),
        )
        dispatch(handleGetEncounterQueueList())
    }

    const DateChanger = (OpType = null) => {
        const dateMaker = updateDate(updatedDate, OpType, 'day')
        // console.log('288=>dateMaker', dateMaker)
        setDateHeader(getDateInClassicFormat(updatedDate))
        props.onNavigate(moment(new Date(updatedDate)), props.view)
        dispatch(setDateEncounterQueueList(moment(new Date(updatedDate)).format('YYYY-MM-DD')))
        dispatch(handleGetEncounterQueueList())
    }

    const WeekChanger = (OpType = null) => {
        const dateMaker = updateDate(updatedDate, OpType, 'week')
        // console.log('288=>dateMaker', dateMaker)
        setDateHeader(weekHeader(updatedDate).formatted)
        props.onNavigate(moment(new Date(updatedDate)), props.view)
        dispatch(setDateEncounterQueueList(moment(new Date(updatedDate)).format('YYYY-MM-DD')))
        dispatch(handleGetEncounterQueueList())
    }

    function weekHeader(dateStr) {
        const now = new Date(dateStr)
        const startOfWeek = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay() + 1,
        )
        //temporary solution
        const endOfWeek = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - now.getDay() + 1,
        )
        endOfWeek.setDate(startOfWeek.getDate() + 6)

        // const endOfWeek = new Date(now.getFullYear(), now.getMonth(), startOfWeek.getDate() + 6)
        return {
            start: startOfWeek,
            end: endOfWeek,
            formatted: `${getDateInClassicFormat(startOfWeek)} - ${getDateInClassicFormat(
                endOfWeek,
            )}`,
        }
    }

    function updateDate(date, OpType = null, view) {
        if (view === 'month') {
            if (OpType && OpType === 'NEXT_MONTH') {
                date.setMonth(date.getMonth() + 1)
                const newFormattedDate = moment(new Date(updatedDate)).add(1, 'months')
                setDateHeader(
                    `${newFormattedDate.format('MMMM')} ${newFormattedDate.format('YYYY')}`,
                )
            }

            if (OpType && OpType === 'PREV_MONTH') {
                date.setMonth(date.getMonth() - 1)
                const newFormattedDate = moment(new Date(updatedDate)).subtract(1, 'months')
                setDateHeader(
                    `${newFormattedDate.format('MMMM')} ${newFormattedDate.format('YYYY')}`,
                )
            }
        } else if (view === 'day') {
            if (OpType && OpType === 'NEXT_DAY') {
                date.setDate(date.getDate() + 1)
            } else if (OpType && OpType === 'PREV_DAY') {
                date.setDate(date.getDate() - 1)
            }

            setDateHeader(getDateInClassicFormat(date))
        } else if (view === 'week') {
            if (OpType && OpType === 'NEXT_WEEK') {
                date.setDate(date.getDate() + 7)

                const { start, end } = weekHeader(new Date(date))

                const newStart = moment(new Date(start)).add(7, 'd'),
                    newEnd = moment(new Date(end)).add(7, 'd')

                setDateHeader(
                    `${getDateInClassicFormat(newStart)} - ${getDateInClassicFormat(newEnd)}`,
                )
            } else if (OpType && OpType === 'PREV_WEEK') {
                date.setDate(date.getDate() - 7)

                const { start, end } = weekHeader(new Date(date))

                const newStart = moment(new Date(start)).subtract(7, 'd'),
                    newEnd = moment(new Date(end)).subtract(7, 'd')

                setDateHeader(
                    `${getDateInClassicFormat(newStart)} - ${getDateInClassicFormat(newEnd)}`,
                )
            }
        }

        setUpdatedDate(date)
        return date
    }

    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        if (
            refOne.current &&
            !refOne.current.contains(e.target) &&
            calIcon.current &&
            !calIcon.current.contains(e.target)
        ) {
            setOpenCalender(false)
        }
    }

    const hideOnEscape = (e) => {
        if (e.key === 'Escape') {
            setOpenCalender(false)
        }
    }

    const handleOnchangeDate = (e) => {
        const newDate = new Date(e)
        setUpdatedDate(newDate)
        const newDateHeader = {
            day: getDateInClassicFormat(newDate),
            month: `${moment(new Date(newDate).getTime()).format('MMMM')} ${moment(
                new Date(newDate).getTime(),
            ).format('YYYY')}`,
            week: weekHeader(new Date(newDate)).formatted,
        }
        setDateHeader(newDateHeader[props.view])
        setOpenCalender(false)
        props.onNavigate(moment(new Date(e)), props.view)
        dispatch(setDateEncounterQueueList(moment(new Date(e)).format('YYYY-MM-DD')))
        dispatch(handleGetEncounterQueueList())
    }

    const onTodayClick = (e) => {
        const newDate = new Date(e)
        setUpdatedDate(newDate)
        const newDateHeader = {
            day: getDateInClassicFormat(newDate),
            month: `${moment(new Date(newDate).getTime()).format('MMMM')} ${moment(
                new Date(newDate).getTime(),
            ).format('YYYY')}`,
            week: weekHeader(new Date(newDate)).formatted,
        }
        setDateHeader(newDateHeader[props.view])
        props.onNavigate(moment(new Date(e)), props.view)
        dispatch(setDateEncounterQueueList(moment(new Date(e)).format('YYYY-MM-DD')))
        props.handleRefresh(moment(newDate))
    }

    const visibleCalender = () => {
        setOpenCalender((openCalender) => !openCalender)
    }

    const handleRefresh = () => {
        props.handleRefresh()
    }
    
    const totalFilterNumber = () => {
        const noOfProvider = props.provider_multi.length > 0 ? 1 : 0
        const noOfloction = props.location_multi.length > 0 ? 1 : 0
        const noOfvisit = props.visitType_multi.length > 0 ? 1 : 0
        const noOfStatus = props.status_multi.length > 0 ? 1 : 0
        const total = noOfProvider + noOfloction + noOfvisit + noOfStatus
        setTotalFilterUsed(total)
    }
    const handleChangeTab = (event, newValue) => {
        setValue(newValue)
        props.onView(newValue, updatedDate)
    }

    const shedulePermission = useSelector((state) => state.permission?.scheduling)

    React.useEffect(() => {
        setDateSelected(moment(new Date(props.date)).date())
        setDateHeader(defaultDateHeader[props.view])
        props.onNavigate(moment(new Date(props.date)), props.view)
        dispatch(setDateEncounterQueueList(moment(new Date(props.date)).format('YYYY-MM-DD')))
        dispatch(handleGetEncounterQueueList())
    }, [props.view, props.locationTimezone])

    React.useEffect(() => {
        totalFilterNumber()
    }, [props.provider_multi, props.location_multi, props.visitType_multi, props.status_multi])

    useEffect(() => {
        document.addEventListener('click', hideOnClickOutside, true)
        document.addEventListener('keydown', hideOnEscape, true)
    }, [])
    const handleLoadDataProvider = async (name = '') => {
        try {
            setLoadingProviderOption(true)
            let payload = {
                page: 1,
                limit: 20,
                name: searchTextOffilter.provider_multi,
            }
            const res = await _getClinicalNotesFilterProvider(payload)
            let tempUserList = []
            res?.data?.data?.data?.map((item) =>
                tempUserList.push({key: item?.id, value: item?.name }),
            )
            setProviderData(tempUserList)
            setCurrentPageProvider(1)
            const b = res?.data?.data?.total / 20
            const remainder = res?.data?.data?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = Math.floor(b)
            }
            setTotalPageNoOfProvider(result)
            setLoadingProviderOption(false)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
            setLoadingProviderOption(false)
        }
    }
    const handleChangePage = async() =>{
        if (totalPageNoOfProvider > currentPageProvider) {
                try {
                    const nextPage = currentPageProvider + 1
                    let payload = {
                        page: nextPage,
                        limit: 20,
                        name: searchTextOffilter.provider_multi,
                    }
                    const res = await _getClinicalNotesFilterProvider(payload)
                    let tempUserList = []
                    res?.data?.data?.data?.map((item) =>
                        tempUserList.push({key: item?.id, value: item?.name }),
                    )
                    let sortedProviderData = [...providerData, ...tempUserList]
                    setProviderData(sortedProviderData)
                    setCurrentPageProvider(nextPage)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
        }
    }
    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'provider_multi',
                name: 'Provider',
                value: props.provider_multi,
                options: providerData,
                loading: loadingProviderOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.provider_multi,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.provider_multi,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'location_multi',
                name: 'Location',
                value: props.location_multi,
                options: locationOptions,
                loading: false,
                AccordionExpanded: isAccordionExpanded.location_multi,
                handleAccordionExpand: handleAccordionExpandNew,
            },
            {
                key: 'visitType_multi',
                name: 'Visit Type',
                value: props.visitType_multi,
                options: visitTypeOptions,
                loading: false,
                AccordionExpanded: isAccordionExpanded.visitType_multi,
                handleAccordionExpand: handleAccordionExpandNew,
            },
            {
                key: 'status_multi',
                name: 'Status',
                value: props.status_multi,
                options: statusOptions,
                loading: false,
                AccordionExpanded: isAccordionExpanded.status_multi,
                handleAccordionExpand: handleAccordionExpandNew,
            },
        ]
    }, [
        providerOptions,
        visitTypeOptions,
        locationOptions,
        statusOptions,
        isAccordionExpanded,
        scrollPosition,
        providerData,
        loadingProviderOption
    ])
    /**
     * Provider Options fetch
     */
    const fetchProviderOptions = () => {
        if (!isProviderClick) {
            setIsProviderClick(true)
            getProvidersData()
        }
    }

    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedSearchFilterTextProvider])
    return (
        <Grid
            container
            justifyContent={'flex-start'}
        >
            {/* sx={{ width: { xs: '100%', lg: 'fit-content' } }} */}
            <div
                container
                className={classes.header_filter}
                style={{
                    marginTop: '8px',
                    marginBottom: '8px',
                    width: '100%',
                }}>
                {/* sx={{ width: { xs: '100%', lg: 'fit-content' } }} */}
                <Grid item style={{ flex: 1, marginLeft: 10 }}>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <HtmlTooltip
                            placement="top-start"
                            title={`Go to previous ${props.view}`}
                            arrow>
                            <PreviousDateArrow
                                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={() => {
                                    if (props.view === 'month') MonthChanger('PREV_MONTH')
                                    else if (props.view === 'day') DateChanger('PREV_DAY')
                                    else if (props.view === 'week') WeekChanger('PREV_WEEK')
                                }}
                            />
                        </HtmlTooltip>

                        <Typography className={classes.month_header}>{DateHeader}</Typography>

                        <HtmlTooltip
                            placement="top-start"
                            title={`Go to next ${props.view}`}
                            className={classes.calendar_arrow_tooltip}
                            arrow>
                            <NextDateArrow
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (props.view === 'month') MonthChanger('NEXT_MONTH')
                                    else if (props.view === 'day') DateChanger('NEXT_DAY')
                                    else if (props.view === 'week') WeekChanger('NEXT_WEEK')
                                }}
                            />
                        </HtmlTooltip>

                        {props?.view === VIEW.DAY && (
                            <div
                                onClick={() => onTodayClick(new Date())}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 5,
                                    border: '1px solid #989CA8',
                                    backgroundColor: '#fff',
                                    padding: '1px 10px',
                                    cursor: 'pointer',
                                    gap: 2,
                                }}>
                                <TodayIcon style={{ marginTop: 2 }} />
                                <Typography
                                    style={{ color: '#303E4E', fontSize: 13, fontWeight: 400 }}>
                                    Today
                                </Typography>
                            </div>
                        )}
                    </Box>
                    
                </Grid>

                <div
                    style={{
                        width: 'fit-content',
                        display: 'flex',
                        gap: 20,
                        alignItems: 'center',
                        marginRight: 10,
                    }}>
                    {/* Day, Month, Week View changing tab buttons */}
                    <Grid item>
                        <div className={`view_tab_parent ${classes.tabBackGround}`}>
                            <Tabs
                                value={viewValue()}
                                onChange={handleChangeTab}
                                TabIndicatorProps={{ style: { display: 'none' } }}
                                centered>
                                <CustomTab value="day" label="Day" size="small" />
                                <CustomTab value="week" label="Week" size="small" />
                                <CustomTab value="month" label="Month" size="small" />
                            </Tabs>
                        </div>
                    </Grid>

                    <Grid item className="calendar-top-panel-multifilter-container">
                        <MultiFilter
                            filterOptionsArrayObject={filterOptionsArrayObject}
                            handleChange={props.onChangeFilterMulti}
                            handleClearSingleFilter={props.handleClearSingleFilter}
                            handleClearFilter={props.onClearFilter}
                            totalNoOfFilterUsed={totalfilterUsed}
                            handleFilterClick={props.handleFilterClick}
                            // handleChangePage={handleChangePage}
                        />
                    </Grid>

                    {shedulePermission.includes('create_appointments') && (
                        <Grid
                            item
                            sx={{
                                width: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <ButtonCustom
                                tooltip="New Appointment Form"
                                variant="contained"
                                color="primary"
                                onClick={() => props.newAppointmentModalToggle()}
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    )}
                </div>
            </div>

            <Divider style={{ width: '99%', margin: '0px 5px' }} className="inner_divider" />

            <div
                container
                className={classes.header_filter}
                style={{
                    margin: '5px 10px',
                    width: '100%',
                }}>
                {/* sx={{ width: { xs: '100%', lg: 'fit-content' } }} */}
                <Grid item style={{ flex: 1 }}>
                    <Box style={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                        <div>
                            <HtmlTooltip title={`Refresh`}>
                                <IconButton
                                    disabled={!isEmpty(getDraftAppointments())}
                                    onClick={() => handleRefresh()}>
                                    <ReloadIcon
                                        style={{ cursor: 'pointer', width: 17, height: 17 }}
                                    />
                                </IconButton>
                            </HtmlTooltip>
                        </div>
                        <Box sx={{height: '23px', display: 'flex', alignItems: 'center'}}>
                            <TopPanelCalendarIcon
                                ref={calIcon}
                                className="top-panel-calender-icon"
                                onClick={visibleCalender}
                            />
                        </Box>
                    </Box>

                    {openCalender && (
                        <Box
                            ref={refOne}
                            style={{
                                position: 'absolute',
                                zIndex: 10,
                                top: '70px',
                                border: '1px #f4f4f4 solid',
                                left: '100px',
                                borderRadius: '5px',
                            }}
                            sx={{
                                '& .MuiPickersCalendarHeader-transitionContainer': {
                                    ' & p': {
                                        color: '#303E4E',
                                        fontWeight: 500,
                                        fontSize: '16px',
                                    },
                                },
                                '& .MuiSvgIcon-root': {
                                    color: '#303E4E',
                                    width: '30px',
                                    height: '30px',
                                },
                                '& .MuiPickersCalendarHeader-dayLabel': {
                                    color: '#9CA9B8',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    width: '41px',
                                },
                                '& .MuiPickersCalendarHeader-daysHeader': {
                                    marginTop: '16px',
                                    marginBottom: '8px',
                                },
                                '& .MuiPickersDay-daySelected': {
                                    background: '#5571C6',
                                    borderRadius: '8px',
                                    color: 'white !important',
                                },
                                '& .MuiPickersBasePicker-pickerView': {
                                    maxWidth: '310px',
                                    width: '310px',
                                },
                                '& .MuiPickersDay-day': {
                                    width: '41px',
                                    height: '32px',
                                },
                                '& .MuiIconButton-label': {
                                    '& p': {
                                        fontSize: '14px',
                                    },
                                },
                            }}>
                            <Box>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                <MuiDateCalendar
                                    timezone={props.locationTimezone}
                                    value={moment(updatedDate).startOf('day')}
                                    onChange={handleOnchangeDate}
                                    showDaysOutsideCurrentMonth
                                    className="calendarElement"
                                    sx={dateCalendarSx}
                                />
                                </LocalizationProvider>
                            </Box>   
                        </Box>
                    )}
                </Grid>

                <div style={{ marginLeft: '25px' }}>
                    <HtmlTooltip
                        title={`${
                            props.toggleCancelledAppointment ? 'Hide' : 'Show'
                        } cancelled appointments`}>
                        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                            <ToggleSwitch
                                onChange={() =>
                                    setTimeout(() => props.manageToggleCancelledAppointment(), 1500)
                                }
                                checked={props.toggleCancelledAppointment}
                            />
                            <Typography style={{ color: '#989CA8', fontSize: 12, fontWeight: 400 }}>
                                Show cancelled
                            </Typography>
                        </div>
                    </HtmlTooltip>
                </div>
            </div>
            {Boolean(errorMessage) && (
                <AlertErrorDialog
                    message={errorMessage}
                    open={Boolean(errorMessage)}
                    handleClose={() => {
                        setErrorMessage(false)
                    }}
                />
            )}
        </Grid>
    )
}

export default TopPanel
// export default React.memo(TopPanel)
