import React from 'react'
import { Box, TextField } from '@material-ui/core'

const RenderRespiratoryRate = ({ master, values, handleChange }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'respiratory rate',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { value } = e.target
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: filteredMaster?.units[0],
            value: value.replace(/[^0-9]/g, ''),
        })
    }
    return (
        <Box
            sx={{
                border: '1px solid #EAECF0',
                width: '100px',
                borderRadius: 3,
                alignItems: 'center',
                display: 'flex',
                backgroundColor: '#FFF',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
            }}>
            <TextField
                placeholder="Respiratory Rate"
                fullWidth="true"
                InputProps={{
                    style: {
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                    },
                    disableUnderline: true,
                }}
                hiddenLabel
                value={filteredEle?.value}
                variant="filled"
                size="small"
                onChange={handleInput}
                style={{ width: 100 }}
            />
        </Box>
    )
}

export default RenderRespiratoryRate