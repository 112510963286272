import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { DialogActions, makeStyles, Typography } from '@material-ui/core'
import { Button } from '@mui/material'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689 !important',
        fontSize: 13,
    },
    header: {
        lineHeight: 1.5,
        color: '#303E4E !important',
        fontWeight: 500,
        fontSize: 18,
        padding: 8,
        textAlign: 'center',
        textTransform: 'none',
    },
    actions: {
        width: '50%',
        padding: '12px 24px',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        fontSize: '15px !important',
        borderRadius: '25px !important',
        width: '100%',
        height: '36px',
    },
}))

interface Props {
    open?: boolean
    loading?: boolean
    message?: string | null
    btnText?: string
    onCancel?: () => void
    headerSize?: number | string
}

const Confirm = (props: Props) => {
    const classes = useStyles()

    return (
        <Dialog
            open={Boolean(props.open)}
            onClose={props.onCancel}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography
                    style={{
                        fontSize: props.headerSize || '',
                    }}
                    className={classes.header}>
                    {props.message}
                </Typography>
            </DialogTitle>

            <DialogActions className={classes.actions}>
                <Button
                    variant="contained"
                    className={classes.button_cancel}
                    onClick={props.onCancel}>
                    {props.btnText || 'OK'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { Confirm }

export default Confirm
