import { Box, CircularProgress, FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import TeleCalendarIcon from '../../../assests/icons/TeleCalendarIcon'
import AlertErrorDialog from '../../../components/shared/Elements/AlertErrorDialog'
import { getPatientListForUsers } from '../../../services/Patient/patient'
import { SelectEl } from '../../../shared/elements'
import DatePickerInput from './DatePickerInput'
import TimePickerInput from './TimePickerInput'
import { useFormik } from 'formik'
import { NewTimePicker } from '../../../shared/elements'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { ReactComponent as CalenderIcon } from '../../../assests/icons/calendar.svg'
import { getListAdminCodes, getScheduleById, staffNoteAdd } from 'services/Calendar'
import { SelectSearchInput } from 'shared/elements/FormItem/SelectSearchInput'
import { useNotification } from 'shared/elements/Notification'
import { LoadingButton } from '@mui/lab'
import { Button, Tooltip } from '@mui/material'
import { getProvidingProviders } from 'services/WorkQueue'
import { SelectSearchInputCustom } from 'shared/elements/FormItem/SelectSearchInputCustom'
import { appointmentStatus } from 'containers/CalendarScheduler/EventCard/components/appointmentStatus'
import { ReactComponent as HourWatchIcon } from '../../../assests/icons/hour_watch.svg'
import { Popover, Divider } from '@mui/material'
import SelectTimeRange from '../Views/SelectTimeRange'
import { addDraftAppointment } from '../../CalendarScheduler/helpers'

import { useSelector } from 'react-redux'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import * as yup from 'yup'
import RecurringForm from './RecurringForm'
import MultipleSelect from './MultipleSelect'
import NoteField from 'containers/WorkQueues/component/NoteField'
const { v4: uuidv4 } = require('uuid')

const useStyles = makeStyles(() => ({
    status_button: {
        padding: '2px 8px',
        margin: 0,
        borderRadius: 3,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
    },
    required_text: {
        color: 'red',
    },
    fieldSet: {
        width: '100%',
        border: '1px solid #666',
        borderRadius: '5px',
    },
    BorderRightGrid: {
        borderRight: '1px solid #EEEEEE ',
    },
    siderBaropenDateTimeView: {
        flexGrow: 1, // Override flexGrow: "0"
        maxWidth: '100%', // Override maxWidth: "100%"
        flexBasis: '0', // Override flexBasis: 0
        // Add any additional styles you want to apply
        textAlign: 'center',
    },
    rotateSvgArrow: {
        rotate: '90deg',
    },
    item_header_text: {
        fontSize: 11,
    },
    dateTimeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
        // height: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },
    border_top: {
        borderTop: '5px solid #e9ecf1',
        height: '1px',
        width: '100%',
        margin: '16px',
    },
    noteField: {
        paddingBottom: "20px",
        width: "100%",
        border: '1px solid #889db6',
        borderRadius: "5px",
        // marginBottom: "20px",
        marginTop: 10
    },
}))

const defaultStatus = [
    {
        value: 'Confirmed',
        key: 'booked',
    },
    // {
    //     value: 'Completed',
    //     key: 'fulfilled',
    // },
    {
        value: 'No Show',
        key: 'noshow',
    },
    {
        value: 'Rescheduled',
        key: 'waitlist',
    },
    {
        value: 'Cancelled',
        key: 'cancelled',
    },
]



function Staff({
    handleSubmit,
    values,
    onCloseStaff,
    setFieldValue,
    appointmentModal,
    label,
    events,
    errors,
    touched,
    sidebarOpen,
}) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const loading = open && options.length === 0
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [adminCodeOptions, setAdminCodeOptions] = useState([])
    const [listProvider, setlistProvider] = useState([])
    const notification = useNotification()
    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const [status, setStatus] = useState('pending')
    const { bgColor, displayName, nativeName, icon } = appointmentStatus(status)
    const open2 = Boolean(anchorEl2)
    const endId2 = open2 ? 'simple-popover' : undefined
    const noPostActionStatus = ['noshow', 'cancelled', 'fulfilled']
    const [adminCodesAll, setadminCodesAll] = React.useState([])
    const [listStatus, setListStatus] = useState(defaultStatus)
    const [staffObj, setStaffObj] = React.useState(null)
    const [showViewDay, setShowViewDay] = useState(false)
    const appointment = useSelector((state) => state.schedule.appointment)
    const [loadingSchedule, setLoadingSchedule] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const openpop = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined
    const [initialValues, setinitialValues] = useState({
        is_reoccurring_appointment: values.is_reoccurring_appointment,
        days: values.days,
        end_date: values.end_date,
        unit: values.unit,
        once_every: values.once_every,
        end_type: values.end_type,
        note: values.note,
        noteView: values.noteView,
        notes: values.notes
    })
    const loggedInUser = useSelector((state) => state?.login?.userData)

    const [notesLoading, setNotesLoading] = useState(false)

    React.useEffect(() => {
        getListAdminCode()
        fetchProvider()
    }, [])

    useEffect(() => {
        if (appointment.id) {
            getASchedule(appointment.id)
        } else {
            if (appointment?.values?.date) {
                setFieldValue('date', appointment?.values?.date)
            }
            if (appointment?.values?.start_time) {
                const start_time = moment(appointment?.values?.start_time, 'HH:mm')
                setFieldValue('start_time', start_time.format('HH:mm'))
                const end_time = start_time.clone().add(appointment?.values?.duration, 'minutes')
                setFieldValue('end_time', end_time.format('HH:mm'))
            }

        }

    }, [appointment.id, adminCodeOptions])


    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    async function getASchedule(idEvent) {
        try {
            setLoadingSchedule(true)
            const response = await getScheduleById({
                id: idEvent,
            })

            setFieldValue('id', response.data?.id)
            setFieldValue('admin_code_id', response.data?.admin_code_id)
            setFieldValue('duration', response.data?.duration)
            setFieldValue('practitioner_ids', response.data?.practitioner_ids)
            setFieldValue('date', moment(response.data.end).format('YYYY-MM-DD'))
            setFieldValue('start_time', moment(response.data.start).format('HH:mm'))
            setFieldValue('end_time', moment(response.data.end).format('HH:mm'))
            setFieldValue('is_reoccurring_appointment', JSON.parse(response.data.is_reoccurring_appointment))
            setFieldValue('once_every', response.data.once_every)
            setFieldValue('end_date', response.data.end_date)
            setFieldValue('unit', response.data.unit)
            setFieldValue('end_type', response.data.end_date ? 'ON' : response.data.end_type)
            setFieldValue('days', JSON.parse(response.data.days).map((day) => capitalizeFirstLetter(day)))
            setFieldValue('series_edit', appointment.series_edit)
            setFieldValue('series_id', response.data?.series_id)
            setFieldValue('notes', response.data?.notes)

            setinitialValues({
                is_reoccurring_appointment: JSON.parse(response.data.is_reoccurring_appointment),
                once_every: response.data.once_every,
                end_date: response.data.end_date,
                unit: response.data.unit,
                end_type: response.data.end_date ? 'ON' : response.data.end_type,
                days: response.data.days.length ? JSON.parse(response.data.days).map((day) => capitalizeFirstLetter(day)) : [],
                notes: response.data?.notes,
                note: '',
                noteView: 'no-view'
            })
            const staff = adminCodeOptions.find(
                (item) => {
                    return item.key === parseInt(response.data.admin_code_id)
                }
            )
            setStaffObj(staff)
            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }


    const getListAdminCode = async () => {
        setLoadingSchedule(true)
        const Listdata = await getListAdminCodes()

        const ListData = Listdata.data.adminCodesList.data
        setadminCodesAll(ListData)
        genrateListAppointmentCode(ListData)
        setLoadingSchedule(false)
    }

    const fetchProvider = async () => {
        setLoadingSchedule(true)
        const listproviderData = await getProvidingProviders()
        const tempData = listproviderData.data
        const generatedData = tempData.map((item, idx) => {
            return {
                key: item.id,
                value: `${item.first_name} ${item.last_name}`,
            }
        })
        setlistProvider(generatedData)
        setLoadingSchedule(false)
    }

    const genrateListAppointmentCode = (data) => {
        try {
            setAdminCodeOptions((item, idx) => {
                const list = data?.map((item, index) => ({
                    key: item.id,
                    value: item.code,
                    color: item.color,
                    description: item.description,
                    title: item.title,
                }))
                return list
            })
        } catch (error) {
            notification(error?.data?.message || error?.message || 'Cannot get list Day!', 'error')
        }
    }

    const handleDownloadCalendar = () => {
        if (ics) {
            const cal = ics()
            const nameCalendar = label ? `Calendar ${label}` : 'Calendar'
            if (events?.data?.length > 0) {
                events.data.forEach((event) => {
                    cal.addEvent(
                        `${moment(new Date(event.start).getTime()).format('HH:mm')} - ${moment(
                            new Date(event.end).getTime(),
                        ).format('HH:mm')}  ${event.patientName}  ${event.appointmentType}`,
                        '',
                        event.patientName,
                        event.start,
                        event.end,
                    )
                })
                cal.download(nameCalendar)
            } else {
                setErrorMessage(
                    'There is no event at all. Please create some events to download calendar!',
                )
            }
        } else {
            setErrorMessage('Something went wrong. Please try again in a few minutes!')
        }
    }

    const statusDisabled = () => {
        // return formik.values.id === ''
        //     ? true
        //     : formik?.values?.status === 'noshow' ||
        //         formik?.values?.status === 'cancelled' ||
        //         formik?.values?.status === 'fulfilled'
        //         ? true
        //         : false
    }

    const openstatus = (e) => {
        setAnchorEl2(e.currentTarget)
        e.stopPropagation()
    }

    const handleClose2 = () => {
        setAnchorEl2(null)
    }

    const validationSchema = yup.object({
        once_every: yup.string().required('This field is required!'),
        unit: yup.string().required('This Field is required'),
        // days: unit !== 'day' ? yup.array().min(1, 'This field is required') : "",
        days: yup.array().when("unit", (unit, schema) => {
            if (unit !== 'day')
                return schema.min(1, 'This field is required')
            return schema
        }),
        end_date: yup.string().required('This Field is required'),
        end_type: yup.string().required('This Field is required')
    })
    const save = () => {
        setAnchorEl(null)
        setFieldValue('is_reoccurring_appointment', formik.values.is_reoccurring_appointment)
        setFieldValue('once_every', formik.values.once_every)
        setFieldValue('end_date', formik.values.end_date)
        setFieldValue('unit', formik.values.unit)
        setFieldValue('end_type', formik.values.end_type)
        setFieldValue('days', formik.values.days.map(element => {
            return element.toUpperCase();
        }))
        setFieldValue('note', formik.values.note)
        setFieldValue('noteView', formik.values.noteView)
        setFieldValue('notes', values.notes)
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: save
    })

    // useEffect(() => {
    //     if (!formik.values.is_reoccurring_appointment) {
    //         formik.setFieldValue('once_every', '')
    //         formik.setFieldValue(
    //             'end_date',
    //             ''
    //         )
    //         formik.setFieldValue('unit', '')
    //         formik.setFieldValue('end_type', '')
    //         formik.setFieldValue('days', [])

    //     }

    // }, [formik.values.is_reoccurring_appointment])

    const handleSubmitData = () => {
        try {
            const modifyEventObj = {
                id: values.id,
                appointmentType: values.appointment_type,
                start: moment
                    .tz(`${values.date} ${values.start_time}`, 'YYYY-MM-DD HH:mm', values.timezone)
                    .utc()
                    .set('seconds', 0)
                    .format(),
                end: moment
                    .tz(`${values.date} ${values.end_time}`, 'YYYY-MM-DD HH:mm', values.timezone)
                    .utc()
                    .set('seconds', 0)
                    .format(),
                color_tag: staffObj?.color,
                admin_code: staffObj?.value,
                admin_code_id: staffObj?.key,
                code_title: staffObj?.title,
                code_description: staffObj?.description,
                type: 'appointment',
                practitioner_id: values.practitioner_ids[0]?.key,
                notes: values?.notes
            }

            save()
            addDraftAppointment(modifyEventObj)
            handleSubmit(values)
        } catch (error) {
            console.log(error)
        }
    }

    const onChangeNote = (allNotes, appointmentId, callBack) => {
        setNotesLoading(true)
        staffNoteAdd({
            notes: allNotes,
            resource_name: "Appointment",
            resource_id: appointmentId
        })?.then(() => {
            callBack()
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => setNotesLoading(false))
    }

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (values?.id === 0 || !values?.id) {
                if (values.note !== '') {
                    allNotesFinal = [
                        ...values?.notes,
                        {
                            id: uuidv4(),
                            content: values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (values?.noteView === 'no-view' && values.note !== '') {
                    allNotesFinal = [
                        ...values?.notes,
                        {
                            id: uuidv4(),
                            content: values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = values?.notes
            }
            if(values?.id && values?.id !== "") {
                onChangeNote(allNotesFinal, values?.id, () => {
                    setFieldValue('notes', allNotesFinal)
                    setFieldValue('note', '')
                })
            }else{
                setFieldValue('notes', allNotesFinal)
                setFieldValue('note', '')    
            }
        },
        [values],
    )

    const handleEditNote = (updatedNotes) => {
        if(formik?.values?.id && formik?.values?.id !== "") {
            onChangeNote(updatedNotes, formik?.values?.id, () => {
                formik.setFieldValue('notes', updatedNotes)
            })
        }else{
            formik.setFieldValue('notes', updatedNotes)
        }
    }

    const handleCancel = () => {
        setAnchorEl(null)
        formik.setValues(initialValues)
    }

    // const handleCliaway = () => {
    //     // if (!appointment?.id) {
    //     //     formik.setFieldValue('is_reoccurring_appointment', false)
    //     //     setAnchorEl(null)
    //     //     formik.setFieldValue('is_reoccurring_appointment', false)
    //     //     formik.setFieldValue('once_every', '')
    //     //     formik.setFieldValue(
    //     //         'end_date',
    //     //         ''
    //     //     )
    //     //     formik.setFieldValue('unit', '')
    //     //     formik.setFieldValue('end_type', '')
    //     //     formik.setFieldValue('days', [])
    //     // } else {
    //     //         setAnchorEl(null)
    //     // }
    // }
    const disabledChecker = () => {
        if (!appointment.id) {
            return false
        }
        if (appointment.id && !appointment.series_edit) {
            return true
        }

        if (appointment.id && appointment.series_edit) {
            return false
        }
    }

    return (
        <Grid container item spacing={3} className="modal-spacer" style={{ padding: '15px' }}>

            <HtmlTooltip
                placement="top-start"
                arrow>
                <Popover
                    id={endId}
                    open={openpop}
                    anchorEl={anchorEl}
                    // onClose={handleCliaway}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {

                            background: "transparent"
                        },
                    }}

                >

                    <RecurringForm
                        formik={formik} classes={classes}
                        save={save}
                        cancel={handleCancel}
                    />

                </Popover>
            </HtmlTooltip>
            {(loadingSchedule) && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '75%',
                        height: '30%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                    <CircularProgress />
                </Box>
            )}
            <Box style={{ display: 'flex' }}>
                <span>Appointment Status:</span>
                <span
                    // onClick={openstatus}
                    className={classes.status_button}
                    style={{
                        backgroundColor: '#909EAE',
                        width: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '3px',
                        border: '1px solid grey',
                        color: 'white',
                        fontSize: 'small',
                        marginLeft: '4px',
                        height: '21px',
                        flexShrink: 0,
                        cursor: `${statusDisabled() ? 'not-allowed' : 'pointer'}`,
                        pointerEvents: `${statusDisabled() ? 'none' : 'auto'}`,
                    }}>
                    <span style={{ marginTop: '3px' }}>
                        {status === 'pending' ? <HourWatchIcon /> : icon}
                    </span>

                    <span
                        style={{
                            marginLeft: `${status === 'pending' ? '2px' : '-1px'}`,
                            marginTop: '2px',
                        }}>
                        {displayName}
                    </span>
                </span>
            </Box>

            <Popover
                id={endId2}
                open={noPostActionStatus.includes(values.status) ? false : open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{
                    style: {
                        boxShadow: '0px 0px 10px #888888',
                    },
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        padding: '10px',
                        justifyContent: 'center',
                    }}>
                    {defaultStatus.map((item, i) => {
                        const { bgColor, displayName, nativeName, icon } = appointmentStatus(
                            item.key,
                        )
                        return (
                            <span key={i}>
                                <span
                                    className={`${classes.status_button} ${classes.item_header_text}`}
                                    style={{
                                        backgroundColor: bgColor,
                                        width: '88px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: '3px',
                                        border: '1px solid grey',
                                        color: 'white',
                                        fontSize: 'small',
                                        marginLeft: '4px',
                                        height: '21px',
                                        flexShrink: 0,
                                        marginTop: '6px',
                                    }}
                                    onClick={(event) => {
                                        setStatus(defaultStatus[i].key)
                                        setAnchorEl2(null)
                                        statusDisabled()
                                        event.stopPropagation()
                                    }}>
                                    <span style={{ marginTop: '5px' }}>{icon}</span>

                                    <span style={{ marginTop: '3px' }}>{item.value}</span>
                                </span>
                            </span>
                        )
                    })}
                </div>
            </Popover>
            <fieldset style={{ width: '100%', borderRadius: '5px' }}>
                <legend>Appointment</legend>
                <>
                    <Grid container item alignItems="unset" spacing={4} className="add-button">
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Appointment type<span className={classes.required_text}>*</span>
                            </FormLabel>
                            <SelectEl
                                name="appointmentType"
                                placeholder="Select Appointment Type"
                                options={adminCodeOptions}
                                value={values.admin_code_id}
                                selectProps={{
                                    style: {
                                        height: 40,
                                    },
                                }}
                                onChange={(e) => {
                                    const staff = adminCodeOptions.find(
                                        (item) => item.key === e.target.value,
                                    )
                                    setStaffObj(staff)
                                    setFieldValue('admin_code_id', e.target.value)
                                    const obj = adminCodesAll.find((o) => o.id === e.target.value)
                                    setFieldValue('duration', parseInt(obj.duration))
                                    setFieldValue(
                                        'end_time',
                                        moment(values.start_time, 'HH:mm')
                                            .add(obj.duration, 'minutes')
                                            .format('HH:mm'),
                                    )
                                }}
                                error={touched.admin_code_id && Boolean(errors.admin_code_id)}
                                helperText={touched.admin_code_id && errors.admin_code_id}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Providers <span className={classes.required_text}>*</span>
                            </FormLabel>
                            {/* <SelectEl
                                name="provider"
                                placeholder="Select Providers"
                                value={values.category}
                                options={values.categoryOption}
                                selectProps={{
                                    style: {
                                        height: 40,
                                    },
                                }}
                                onChange={(e) => {
                                    setFieldValue('provider', e.target.value)
                                }}
                            /> */}
                            <SelectSearchInputCustom
                                placeholder="Provider"
                                options={listProvider}
                                multiple={true}
                                value={values?.practitioner_ids}
                                disabled={disabledChecker()}
                                onChange={(value) => {
                                    setFieldValue('practitioner_ids', value)
                                }}
                                isChecBoxFlag
                                limitTags={sidebarOpen ? 1 : 2}
                                error={touched.practitioner_ids && Boolean(errors.practitioner_ids)}
                                helperText={touched.practitioner_ids && errors.practitioner_ids}
                            />
                            {/* <MultipleSelect
                                options={listProvider}
                                value={values?.practitioner_ids}
                                handleChange={(value) => {
                                    setFieldValue('practitioner_ids', value)
                                }}
                            /> */}
                        </Grid>
                    </Grid>
                    {/* <Grid container item alignItems="unset" spacing={4} className="add-button">
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Date <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <DatePickerInput
                                onChange={(e) => {
                                    const timeStartValue = values.start.format('HH:mm')
                                    const timeEndValue = values.end.format('HH:mm')
                                    setFieldValue(
                                        'start',
                                        moment(`${e.format('YYYY-MM-DD')} ${timeStartValue}`),
                                    )
                                    setFieldValue(
                                        'end',
                                        moment(`${e.format('YYYY-MM-DD')} ${timeEndValue}`),
                                    )
                                }}
                                appointmentModal={{
                                    start: values.start,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Time <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <TimePickerInput
                                values={{
                                    start: values.start,
                                    end: values.end,
                                }}
                                appointmentModal={appointmentModal}
                                onChange={(e) => {
                                    const timeStartValue = values.start.format('YYYY-MM-DD')
                                    const timeEndValue = values.end.format('YYYY-MM-DD')
                                    setFieldValue('start', moment(`${timeStartValue} ${e.startTime}`))
                                    setFieldValue('end', moment(`${timeEndValue} ${e.endTime}`))
                                }}
                                error={touched?.start && Boolean(errors?.start)}
                                helperText={touched?.start && errors?.start}
                            />
                        </Grid>
                    </Grid> */}
                </>
            </fieldset>
            <fieldset style={{ width: '100%', borderRadius: '5px', marginTop: '10px' }}>
                <legend>Date/Time</legend>
                <>
                    <Grid container item alignItems="flex-end" spacing={2}>
                        <Grid item style={{ paddingLeft: '8px' }}>
                            <DatePickerInput
                                minDate={moment()}
                                appointmentModal={{
                                    start: values.date,
                                }}
                                onChange={(e) => {
                                    if (e)
                                        setFieldValue('date', e.format('YYYY-MM-DD'))
                                }}
                                error={touched.date && Boolean(errors.date)}
                                helperText={touched.date && errors.date}
                                readOnly={false}
                            />
                            {touched.date && Boolean(errors.date) && (
                                <p
                                    style={{
                                        margin: '8px 0 0 0',
                                    }}
                                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                                    {errors.date}
                                </p>
                            )}

                        </Grid>
                        <Grid item>
                            <Box
                                style={{
                                    border: '1px solid #b4b0b0',
                                    borderRadius: '20px',
                                    padding: '5px',
                                    background: '##d5f0e8',
                                    marginBottom: '5px',
                                }}
                                onClick={() => setShowViewDay(true)}>
                                <CalenderIcon
                                    style={{ display: 'block', cursor: 'pointer' }}
                                // onClick={handleDownloadCalendar}
                                />
                            </Box>
                            {showViewDay && (
                                <SelectTimeRange
                                    values={values}
                                    idProvider={values.practitioner_id}
                                    open={showViewDay}
                                    onClose={() => {
                                        setShowViewDay(false)
                                    }}
                                    onOke={() => {
                                        setShowViewDay(false)
                                    }}
                                    date={moment(values.date, 'YYYY-MM-DD')}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Grid>
                        <Grid item style={{ paddingLeft: '8px' }}>
                            <TextField
                                variant="outlined"
                                value={values.duration}
                                style={{
                                    height: 'fit-content',
                                }}
                                disabled
                                placeholder="Duration"
                                fullWidth
                                inputProps={{ maxLength: 3 }}
                                onChange={(e) =>
                                    setFieldValue(
                                        'duration',
                                        e.target.value.replace(/[^0-9]+/g, ''),
                                    )
                                }
                                error={touched.duration && Boolean(errors.duration)}
                                helperText={touched.duration && errors.duration}
                            />
                        </Grid>

                        <Grid item xs={2} md={2}>
                            <NewTimePicker
                                values={moment(`${values.date} ${values.start_time}`, "YYYY-MM-DD HH:mm")}
                                onChange={(value) => {
                                    // console.log(moment(value).format('HH:mm'))
                                    setFieldValue(
                                        'start_time',
                                        moment(value).format('HH:mm'),
                                    )
                                    setFieldValue(
                                        'end_time',
                                        moment(value)
                                            .add(values.duration, 'minutes')
                                            .format('HH:mm'),
                                    )
                                }}
                                error={touched?.start_time && Boolean(touched?.start_time)}
                                helperText={touched.start_time && errors.start_time}
                            />
                        </Grid>
                        <Grid item>
                            {' '}
                            <ArrowForwardIcon
                                fontSize="small"
                                style={{ marginTop: '5px' }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <NewTimePicker
                                values={moment(values.end_time, "HH:mm").format(
                                    'YYYY-MM-DD HH:mm',
                                )}
                                disabled
                                error={touched?.end_time && Boolean(touched?.end_time)}
                                helperText={touched.end_time && errors.end_time}
                            />
                        </Grid>

                        <Box style={{ paddingLeft: "4px", marginBottom: "10px" }}>
                            <CheckboxOption
                                option={{
                                    label: 'Reccurring appointment',
                                    value: false,
                                }}
                                onChange={(e) => {
                                    formik.setFieldValue('is_reoccurring_appointment', true)
                                    setAnchorEl(e.currentTarget)
                                }}
                                checked={formik.values.is_reoccurring_appointment}
                            />
                        </Box>
                    </Grid>
                </>
            </fieldset>

            <fieldset className={classes.noteField}>
                <legend>Staff Notes</legend>
                <NoteField
                    note={values?.note}
                    noteView={values?.noteView}
                    notes={values?.notes}
                    onChange={setFieldValue}
                    // onBlur={handleNotesChange}
                    handleNotesChange={handleNotesChange}
                    handleNotesEditChange={handleEditNote}
                    loading={notesLoading}
                />
            </fieldset>

            <Grid item xs={12} style={{ paddingRight: '40px' }}>
                <div className={classes.border_top} />
            </Grid>
            <Grid
                container
                item
                justifyContent="flex-end"
                direction="row"
                spacing={2}
                style={{ padding: 16 }}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        className={classes.button_cancel}
                        onClick={() => {
                            onCloseStaff()
                        }}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        className={classes.button_create}
                        // onClick={formik.handleSubmit}
                        onClick={() => {
                            handleSubmitData()
                        }}>
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
            {Boolean(errorMessage) && (
                <AlertErrorDialog
                    message={errorMessage}
                    open={Boolean(errorMessage)}
                    handleClose={() => {
                        setErrorMessage(false)
                    }}
                />
            )}
        </Grid>
    )
}

export default Staff
