import React, { useState } from "react"
import { Box, CircularProgress, Grid, makeStyles } from "@material-ui/core"

import { IconButton } from './IconButton'
import RefreshIcon from 'assests/icons/RefreshIcon'
import ArchivedWhiteIcon from "assests/icons/ArchivedWhiteIcon"
import SendIcon from "assests/icons/SendIcon"
import AssignIcon from "assests/icons/AssignIcon"
import { SmsUnreadIcon } from "assests/icons/SmsUnreadIcon"
import { SmsIcon } from "assests/icons/SmsIcon"
import ArchivedIcon from "assests/icons/ArchivedIcon"
import { UnarchiveIcon } from "assests/icons/UnarchiveIcon"

interface Props {
  selectionLength?: number
  onRefresh?: () => void
  onArchive?: () => void
  onResend?: () => Promise<void>
  onAssignToProvider?: () => void
  onUnarchive?: () => void
  onMarkAsRead?: () => void
  onMarkAsUnread?: () => void
  onShowArchived?: () => void
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 35,
    backgroundColor: '#FBFCFC',
    padding: '10px 20px 10px 20px',
    borderRadius: 12,
    border: '1px solid #ECF0F0',
    transformOrigin: '0 0 0',
  }
})

export const FunctionBar = (props: Props) => {
  const classes = useStyles()
  const [resending, setResending] = useState(false)

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          '& .MuiIconButton-root.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'inherit'
          }
        }}
        className={classes.container}>

        <div>
          {props.onArchive && <IconButton
            disabled={!props.selectionLength}
            icon={<ArchivedWhiteIcon />}
            onClick={props.onArchive}>
            Archive
          </IconButton>}

          {props.onMarkAsRead && <IconButton
            disabled={!props.selectionLength}
            icon={<SmsIcon />}
            onClick={props.onMarkAsRead}>
            Mark as Read
          </IconButton>}

          {props.onMarkAsUnread && <IconButton
            disabled={!props.selectionLength}
            icon={<SmsUnreadIcon />}
            onClick={props.onMarkAsUnread}>
            Mark as Unread
          </IconButton>}

          {props.onAssignToProvider && <IconButton
            disabled={!props.selectionLength}
            icon={<AssignIcon />}
            onClick={props.onAssignToProvider}>
            Assign
          </IconButton>}

          {props.onUnarchive && <IconButton
            disabled={!props.selectionLength}
            icon={<UnarchiveIcon />}
            onClick={props.onUnarchive}>
            Unarchive
          </IconButton>}

          {props.onResend && <IconButton
            disabled={!props.selectionLength}
            icon={resending ? <CircularProgress
              size={15}
              style={{
                marginRight: 2,
              }}
            /> : <SendIcon />}
            onClick={() => {
              setResending(true)
                ; (props.onResend ? props.onResend() : Promise.resolve())
                  .finally(() => setResending(false))
            }}>
            Resend
          </IconButton>}
        </div>

        <div>
          {props.onRefresh && <IconButton icon={<RefreshIcon />}
            onClick={props.onRefresh}>
            Refresh
          </IconButton>}

          {props.onShowArchived && <IconButton icon={<ArchivedIcon />}
            onClick={props.onShowArchived}>
            Show Archived
          </IconButton>}
        </div>
      </Box>
    </Grid>
  )
}
