export const CHANGE_CALENDAR_VIEW = '[CALENDAR] CHANGE_CALENDAR_VIEW'
export const NAVIGATE_TO_DATE = '[CALENDAR] NAVIGATE_TO_DATE'

export const CHANGE_TIMEZONE = '[CALENDAR] CHANGE_TIMEZONE'
export const CHANGE_TAB = '[CALENDAR] CHANGE_TAB'
export const CHANGE_APPT_ACTION = '[CALENDAR] CHANGE_APPT_ACTION'

export const PROVIDER_LOADING = '[CALENDAR] PROVIDER_LOADING'
export const CHANGE_FILTER = '[CALENDAR] CHANGE_FILTER'
export const CHANGE_FILTER_MULTI = '[CALENDAR] CHANGE_FILTER_MULTI'
export const CLEAR_FILTER_MULTI = '[CALENDAR] CLEAR_FILTER_MULTI'
export const CLEAR_FILTER_MULTI_PROVIDER = '[CALENDAR] CLEAR_FILTER_MULTI_PROVIDER'
export const CLEAR_FILTER_MULTI_LOCATION = '[CALENDAR] CLEAR_FILTER_MULTI_LOCATION'
export const CLEAR_FILTER_MULTI_VISITTYPE = '[CALENDAR] CLEAR_FILTER_MULTI_VISITTYPE'
export const CLEAR_FILTER_MULTI_STATUS = '[CALENDAR] CLEAR_FILTER_MULTI_STATUS'
export const CHANGE_DATA = '[CALENDAR] CHANGE_DATA'
export const PROVIDER_SUCCESS = '[CALENDAR] PROVIDER_SUCCESS'
export const PROVIDER_FAILURE = '[CALENDAR] PROVIDER_FAILURE'

export const APPOINTMENTS_LOADING = '[CALENDAR] APPOINTMENTS_LOADING'
export const APPOINTMENTS_SUCCESS = '[CALENDAR] APPOINTMENTS_SUCCESS'
export const APPOINTMENTS_FAILURE = '[CALENDAR] APPOINTMENTS_FAILURE'
export const APPOINTMENT_DELETE = '[CALENDAR] APPOINTMENT_DELETE'
export const APPOINTMENT_UPDATE = '[CALENDAR] APPOINTMENT_UPDATE'

export const APPOINTMENTS_COUNT_SUCCESS = '[CALENDAR] APPOINTMENTS_COUNT_SUCCESS'

export const SLOTS_LOADING = '[CALENDAR] SLOTS_LOADING'
export const SLOTS_SUCCESS = '[CALENDAR] SLOTS_SUCCESS'
export const SLOTS_FAILURE = '[CALENDAR] SLOTS_FAILURE'

export const SEARCH_PROVIDERS_LOADING = '[CALENDAR] SEARCH_PROVIDERS_LOADING'
export const SEARCH_PROVIDERS_SUCCESS = '[CALENDAR] SEARCH_PROVIDERS_SUCCESS'
export const SEARCH_PROVIDERS_FAILURE = '[CALENDAR] SEARCH_PROVIDERS_FAILURE'
export const SELECT_PROVIDER_DETAILS = '[CALENDAR] SELECT_PROVIDER_DETAILS'

export const REASSIGN_PROVIDER_MULTI = '[CALENDAR] REASSIGN_PROVIDER_MULTI'
