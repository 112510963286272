export enum APPOINTMENT_STATUSES {
    booked = 'booked',
    cancelled = 'cancelled',
    noshow = 'noshow',
    enteredInError = 'entered-in-error',
    fulfilled = 'fulfilled',
    pending = 'pending',
    chekedIn = 'checked-in',
    waitlist = 'waitlist',
    arrived = 'arrived',
}

export enum LOCAL_STORAGE_KEYS {
    currentEvent = 'currentEvent',
    updatingAppointmentId = 'updatingAppointmentId',
    draftAppointments = 'draftAppointments',
  }
  
  export type DateRange<T = string> = {
    start: T
    end: T
  }
  
  export type CalendarAppointment = DateRange & BaseEvent
  
  export type BaseEvent = {
    id: string
    patient_id: string
    practitioner_id: string
    status: string
    appointment_status: string
  }
  
  export type SerializedAppointment = BaseEvent &
    DateRange<Date> & {
      resourceId: string
    }
  
  export type ResourceMap = {
    arrowIndex: string
    resourceId: string
    value: string
  }