import React from 'react'

export default function IconAddParticipant() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0003 11.9999C14.685 11.9999 16.8614 9.82354 16.8614 7.13882C16.8614 4.4541 14.685 2.27771 12.0003 2.27771C9.31555 2.27771 7.13916 4.4541 7.13916 7.13882C7.13916 9.82354 9.31555 11.9999 12.0003 11.9999Z"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3.64893 21.7221C3.64893 17.9596 7.39198 14.9166 12.0003 14.9166C12.9336 14.9166 13.8378 15.0429 14.6837 15.2763"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.7226 17.8332C21.7226 18.1443 21.6837 18.4457 21.6059 18.7374C21.5184 19.1263 21.3629 19.5054 21.1587 19.836C20.4879 20.9638 19.2532 21.7221 17.8337 21.7221C16.8323 21.7221 15.9282 21.3429 15.2476 20.7207C14.9559 20.4679 14.7031 20.1666 14.5087 19.836C14.149 19.2527 13.9448 18.5624 13.9448 17.8332C13.9448 16.7832 14.3629 15.8207 15.0434 15.1207C15.7532 14.3916 16.7448 13.9443 17.8337 13.9443C18.9809 13.9443 20.0212 14.4402 20.7212 15.2374C21.3434 15.9277 21.7226 16.8416 21.7226 17.8332Z"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.282 17.8138H16.3848"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.8335 16.3944V19.3013"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
