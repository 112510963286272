import { Box, Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import NoteField from 'containers/WorkQueues/component/NoteField'
import moment from 'moment'
import React, { useEffect } from 'react'
import {useDispatch, useSelector } from 'react-redux'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { ConcurrencyInput, ZipCode } from 'shared/elements/FormItem/Input'
import { RadioGroup } from 'shared/elements/FormItem/RadioGroup'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { backInsuranceCard, frontInsuranceCard } from 'store/actions/patient'
import { formatPhone } from 'utilities'
import FileUploadInsusrance from './FileUploadeInsurance'
const InsuranceForm = (props) => {
    const FrontendURL = useSelector((state) => state?.patient?.insuranceFrontURL)
    const BackendURL = useSelector((state) => state?.patient?.insuranceBackURL)
    const dispatch = useDispatch()
    useEffect(()=>{
        return() => {
            dispatch(frontInsuranceCard(''))
            dispatch(backInsuranceCard(''))
        }
    },[])

    return (
        <>
            <Grid container item spacing={3} className="modal-spacer mt-3" style={{ margin: 0,height:"550px",overflow:"auto" }}>
                {/**----------------------- Insurance Information-------------------------------- */}
                <fieldset className="divider-information" style={{ width: '100%' }}>
                    <legend>
                        <Typography className={'heading1-title'}>Insurance Information</Typography>
                    </legend>
                    <Grid container item spacing={2}>
                        <Grid container item xs={12}>
                            <Grid item xs={6}>
                                <RadioGroup
                                    label="Payment Method"
                                    items={props?.paymentMethodOptions}
                                    value={props?.formik.values.payment_method?.code}
                                    onChange={(e) => {
                                        const item = props?.paymentMethodOptions.find(
                                            (option) => option.value === e.target.value,
                                        )
                                        props?.formik.setFieldValue('payment_method', {
                                            code: item?.value,
                                            display: item?.label,
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: '8px' }}>
                                <span>Select:</span>
                                <Box sx={{ mt: 1 }}>
                                    {!props?.insuranceType?.isPrimary && (
                                        // (formik?.values?.status === 'inactive' || formik?.values?.status === '' ) ) &&
                                        <Button
                                            style={{
                                                backgroundColor: '#EBFFF3',
                                                borderRadius: '5px',
                                                color: '#14AE5C',
                                                border: `1px solid ${
                                                    props?.formik.values?.type === 'Primary'
                                                        ? '#14AE5C'
                                                        : '#E8FDF0'
                                                }`,
                                                marginRight: '5px',
                                                padding: '4px 12px',
                                                fontSize: '13px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                            }}
                                            onClick={() =>
                                                props?.formik.setFieldValue('type', 'Primary')
                                            }>
                                            Primary Insurance
                                        </Button>
                                    )}

                                    {!props?.insuranceType?.isSecondary && (
                                        <Button
                                            style={{
                                                backgroundColor: '#E1E9FF',
                                                borderRadius: '5px',
                                                color: '#5571C6',
                                                border: `1px solid ${
                                                    props?.formik.values?.type === 'Secondary'
                                                        ? '#5571C6'
                                                        : '#DCE5FF'
                                                }`,
                                                marginRight: '5px',
                                                padding: '4px 12px',
                                                fontSize: '13px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                            }}
                                            onClick={() =>
                                                props?.formik.setFieldValue('type', 'Secondary')
                                            }>
                                            Secondary Insurance
                                        </Button>
                                    )}
                                    {!props?.insuranceType?.isTertiary && (
                                        <Button
                                            style={{
                                                backgroundColor: '#FFF4DB',
                                                borderRadius: '5px',
                                                color: '#FFAC0C',
                                                border: `1px solid ${
                                                    props?.formik.values?.type === 'Tertiary'
                                                        ? '#FFAC0C'
                                                        : '#FCF0D6'
                                                }`,
                                                padding: '4px 12px',
                                                fontSize: '13px',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                            }}
                                            onClick={() =>
                                                props?.formik.setFieldValue('type', 'Tertiary')
                                            }>
                                            Tertiary Insurance
                                        </Button>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} style={{ display: 'inline-flex', alignItems: 'center' }}>
                            <span
                                style={{
                                    fontSize: '15px',
                                    fontWeight: '500',
                                    color: '#516983',
                                    marginRight: '8px',
                                }}>
                                Active Policy
                            </span>
                            <ToggleSwitch
                                onChange={(event) => {
                                    const isChecked = event?.target?.checked
                                    props?.formik.setFieldValue(
                                        'status',
                                        isChecked ? 'active' : 'inactive',
                                    )
                                }}
                                checked={
                                    props?.formik.values.status === 'inactive' ||
                                    props?.formik.values.status === ''
                                        ? false
                                        : true
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                label="Health Plan"
                                required
                                placeholder="Select Health Plan"
                                options={props?.planOptions}
                                value={props?.formik.values?.insurance?.plan?.code}
                                error={
                                    props?.formik.touched?.insurance?.plan?.code &&
                                    Boolean(props?.formik.errors?.insurance?.plan?.code)
                                }
                                helperText={
                                    props?.formik.touched?.insurance?.plan?.code &&
                                    props?.formik.errors?.insurance?.plan?.code
                                }
                                onChange={(value) => {
                                    const item = props?.planOptions.find(
                                        (item) => item.key === value,
                                    )
                                    props?.formik.setFieldValue('insurance.plan', {
                                        code: item?.key,
                                        display: item?.value,
                                    })
                                    props?.formik.setFieldValue(
                                        'insurance.phone',
                                        formatPhone(item.phone_number),
                                    )
                                }}
                                disabled={props?.isSelfPay}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Phone Number"
                                placeholder="Enter phone number"
                                inputProps={{
                                    maxLength: 10,
                                }}
                                value={props?.formik.values.insurance.phone}
                                // onChange={(e) =>
                                //     formik.setFieldValue(
                                //         'insurance.phone',
                                //         e?.replace(/\D/g, '').trim(),
                                //     )
                                // }
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Policy Number"
                                required
                                inputProps={{
                                    maxLength: 32,
                                }}
                                placeholder="Enter policy number"
                                value={props?.formik.values.insurance.policy_no}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.policy_no', e)
                                }
                                error={
                                    props?.formik.touched?.insurance?.policy_no &&
                                    Boolean(props?.formik.errors?.insurance?.policy_no)
                                }
                                helperText={
                                    props?.formik.touched?.insurance?.policy_no &&
                                    props?.formik.errors?.insurance?.policy_no
                                }
                                disabled={props?.isSelfPay}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Group Number"
                                placeholder="Enter group number"
                                inputProps={{
                                    maxLength: 32,
                                }}
                                value={props?.formik.values.insurance.group_number}
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.group_number',
                                        e,
                                        // e.replace(/[^0-9]/g, ''),
                                    )
                                }
                                error={
                                    props?.formik.touched?.insurance?.group_number &&
                                    Boolean(props?.formik.errors?.insurance?.group_number)
                                }
                                helperText={
                                    props?.formik.touched?.insurance?.group_number &&
                                    props?.formik.errors?.insurance?.group_number
                                }
                                disabled={props?.isSelfPay}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Group Name"
                                placeholder="Enter group name"
                                inputProps={{
                                    maxLength: 32,
                                }}
                                value={props?.formik.values.insurance.group_name}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.group_name', e)
                                }
                                disabled={props?.isSelfPay}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <ConcurrencyInput
                                label="Copay"
                                value={props?.formik.values.insurance.copay_amount}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.copay_amount', e)
                                }
                                disabled={props?.isSelfPay}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <ConcurrencyInput
                                label="Deductible"
                                value={props?.formik.values.insurance.deductible_amount}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.deductible_amount', e)
                                }
                                disabled={props?.isSelfPay}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Effective Start Date"
                                value={props?.formik.values.insurance.policy_effective}
                                onChange={(e) => {
                                    props?.formik.setFieldValue(
                                        'insurance.policy_effective',
                                        e ? moment(e) : '',
                                    )
                                }}
                                notDefaultValue
                                error={
                                    props?.formik.touched.insurance?.policy_effective &&
                                    Boolean(props?.formik.errors.insurance?.policy_effective)
                                }
                                helperText={
                                    props?.formik.touched.insurance?.policy_effective &&
                                    props?.formik.errors.insurance?.policy_effective
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Effective End Date"
                                minDate={props?.formik.values.insurance.policy_effective}
                                value={props?.formik.values.insurance.policy_end}
                                onChange={(e) => {
                                    props?.formik.setFieldValue(
                                        'insurance.policy_end',
                                        e ? moment(e) : '',
                                    )
                                }}
                                notDefaultValue
                                error={
                                    props?.formik.touched.insurance?.policy_end &&
                                    Boolean(props?.formik.errors.insurance?.policy_end)
                                }
                                helperText={
                                    props?.formik.touched.insurance?.policy_end &&
                                    props?.formik.errors.insurance?.policy_end
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FileUploadInsusrance
                               placeholder='Upload Front of Insurance Card'
                               fileId={(value) => {
                                   props?.formik.setFieldValue('insurance.front_key', value)
                               }}
                               url={FrontendURL}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FileUploadInsusrance
                               placeholder='Upload Back of Insurance Card'
                               fileId={(value) => {
                                   props?.formik.setFieldValue('insurance.back_key', value)
                               }}
                               url={BackendURL}
                            />
                        </Grid>
                    </Grid>
                </fieldset>
                {/**----------------------- Responsible Party-------------------------------- */}
                <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                    <legend>
                        <Typography className={'heading1-title'}>Responsible Party</Typography>
                    </legend>
                    <Grid container item spacing={2}>
                        <Grid item xs={12}>
                            <Select
                                label="Patient Relationship To Insured"
                                required
                                placeholder="Select patient's relationship"
                                options={props?.patientsRelationship}
                                value={props?.formik.values.insurance.patient_relationship}
                                onChange={(e) => {
                                    props?.formik.setFieldValue('insurance.patient_relationship', e)
                                    if (e === 'Self') {
                                        props?.resetRelationshipObj()
                                    }
                                }}
                                error={Boolean(
                                    props?.formik.touched?.insurance?.patient_relationship &&
                                        props?.formik.errors.insurance?.patient_relationship,
                                )}
                                helperText={
                                    props?.formik.touched?.insurance?.patient_relationship &&
                                    props?.formik.errors.insurance?.patient_relationship
                                }
                                style={{ width: '450px' }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="First Name"
                                value={props?.formik.values.insurance.relation_info.first_name}
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.first_name',
                                        e,
                                    )
                                }
                                error={Boolean(
                                    props?.formik.errors.insurance?.relation_info?.first_name,
                                )}
                                helperText={
                                    props?.formik.errors.insurance?.relation_info?.first_name
                                }
                                placeholder="Enter First Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="Last Name"
                                value={props?.formik.values.insurance.relation_info.last_name}
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.last_name',
                                        e,
                                    )
                                }
                                error={Boolean(
                                    props?.formik.errors.insurance?.relation_info?.last_name,
                                )}
                                helperText={
                                    props?.formik.errors.insurance?.relation_info?.last_name
                                }
                                placeholder="Enter Last Name"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="Insured ID"
                                value={props?.formik.values.insurance.relation_info.insured_number}
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.insured_number',
                                        e,
                                    )
                                }
                                // error={Boolean(
                                //     formik.errors.insurance?.relation_info
                                //         ?.insured_name,
                                // )}
                                // helperText={
                                //     formik.errors.insurance?.relation_info
                                //         ?.insured_name
                                // }
                                placeholder="Enter Insured ID"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="SSN"
                                value={props?.formik.values.insurance.relation_info.ssn}
                                onChange={(e) => {
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.ssn',
                                        e.replace(/^(\d{3})-?(\d{2})-?(\d{4})$/, '$1-$2-$3'),
                                    )
                                }}
                                // error={Boolean(
                                //     formik.errors.insurance?.relation_info
                                //         ?.ssn,
                                // )}
                                // helperText={
                                //     formik.errors.insurance?.relation_info
                                //         ?.ssn
                                // }
                                placeholder="Enter SSN"
                                error={Boolean(props?.formik.errors.insurance?.relation_info?.ssn)}
                                helperText={props?.formik.errors.insurance?.relation_info?.ssn}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                disabled={!props?.isSelfInsured}
                                label="Date of Birth"
                                // minDate={formik.values.insurance.policy_effective}
                                // value={formik.values.insurance.policy_end}
                                notDefaultValue
                                onChange={(e) => {
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.dob',
                                        e ? moment(e) : '',
                                    )
                                }}
                                value={props?.formik.values.insurance.relation_info.dob}
                                maxDate={moment()}
                                error={
                                    props?.formik.touched.insurance?.relation_info?.dob &&
                                    Boolean(props?.formik.errors.insurance?.relation_info?.dob)
                                }
                                helperText={
                                    props?.formik.touched.insurance?.relation_info?.dob &&
                                    props?.formik.errors.insurance?.relation_info?.dob
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                                    <Divider />
                                    <fieldset
                                        className="divider"
                                        style={{ maxHeight: '10px', overflowY: 'scroll', width: '99%' }}>
                                        <legend>
                                            <Typography className={'heading1-title'}>Address</Typography>
                                        </legend>
                                    </fieldset>
                                </Grid> */}
                        <Grid item xs={12}>
                            <fieldset
                                className="divider"
                                style={{
                                    maxHeight: '10px',
                                    overflowY: 'scroll',
                                    width: '99%',
                                    marginLeft: '1px',
                                }}>
                                <legend>
                                    <Typography className={'heading1-title'}>Address</Typography>
                                </legend>
                            </fieldset>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="Address Line 1"
                                value={props?.formik.values.insurance.relation_info.address_line_1}
                                error={Boolean(
                                    props?.formik.errors.insurance?.relation_info?.address_line_1,
                                )}
                                helperText={
                                    props?.formik.errors.insurance?.relation_info?.address_line_1
                                }
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.address_line_1',
                                        e,
                                    )
                                }
                                placeholder="Enter Address line 1"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="Address Line 2"
                                value={props?.formik.values.insurance.relation_info.address_line_2}
                                onChange={(e) =>
                                    props?.formik.setFieldValue(
                                        'insurance.relation_info.address_line_2',
                                        e,
                                    )
                                }
                                placeholder="Enter Address line 2"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={!props?.isSelfInsured}
                                label="City"
                                value={props?.formik.values.insurance.relation_info.city}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.relation_info.city', e)
                                }
                                error={Boolean(props?.formik.errors.insurance?.relation_info?.city)}
                                helperText={props?.formik.errors.insurance?.relation_info?.city}
                                placeholder="Enter City"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Select
                                disabled={!props?.isSelfInsured}
                                label="State"
                                placeholder="Select state"
                                value={props?.formik.values.insurance.relation_info.state}
                                options={props?.stateList.map((item) => ({
                                    key: item.state,
                                    value: item.stateLabel,
                                }))}
                                error={Boolean(
                                    props?.formik.errors.insurance?.relation_info?.state,
                                )}
                                helperText={props?.formik.errors.insurance?.relation_info?.state}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.relation_info.state', e)
                                }
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <ZipCode
                                disabled={!props?.isSelfInsured}
                                label="Zip Code"
                                format={9}
                                value={props?.formik.values.insurance.relation_info.zip}
                                error={Boolean(props?.formik.errors.insurance?.relation_info?.zip)}
                                helperText={props?.formik.errors.insurance?.relation_info?.zip}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.relation_info.zip', e)
                                }
                            />
                        </Grid>
                    </Grid>
                </fieldset>
                {/**----------------------- Co-Insurance-------------------------------- */}
                <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                    <legend>
                        <Typography className={'heading1-title'}>Co-Insurance</Typography>
                    </legend>
                    <Grid container item spacing={2}>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={props?.isSelfPay}
                                label="Deductible"
                                value={props?.formik.values.insurance.deductible}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.deductible', e)
                                }
                                error={Boolean(props?.formik.errors.insurance?.deductible)}
                                helperText={props?.formik.errors.insurance?.deductible}
                                placeholder="Enter deductible"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={props?.isSelfPay}
                                label="Deductible Met"
                                value={props?.formik.values.insurance.deductible_met}
                                onChange={(e) =>
                                    props?.formik.setFieldValue('insurance.deductible_met', e)
                                }
                                error={Boolean(props?.formik.errors.insurance?.deductible_met)}
                                helperText={props?.formik.errors.insurance?.deductible_met}
                                placeholder="Enter amount met"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                disabled={true}
                                label="Deductible Remaining"
                                value={
                                    props?.formik.values.insurance.deductible &&
                                    props?.formik.values.insurance.deductible_met
                                        ? (
                                              props?.formik.values.insurance.deductible -
                                              props?.formik.values.insurance.deductible_met
                                          ).toFixed(2)
                                        : ''
                                }
                                placeholder=" "
                            />
                        </Grid>
                    </Grid>
                </fieldset>
                {/*------------------------Stuff Notes------------------------------------------*/}
                <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                    <legend>
                        <Typography className={'heading1-title'}>Staff Notes</Typography>
                    </legend>
                    <Grid item spacing={2} container>
                        <Grid item xs={12}>
                            <NoteField
                                note={props?.formik?.values?.note}
                                noteView={props?.formik?.values?.noteView}
                                notes={props?.formik?.values?.insurance?.notes}
                                onChange={props?.formik?.setFieldValue}
                                // onBlur={handleNotesBlur}
                                handleNotesEditChange={props?.handleNotesEditChange}
                                handleNotesChange={props?.handleNotesBlur}
                            />
                        </Grid>
                    </Grid>
                </fieldset>
            </Grid>
            <Grid
                container
                item
                style={{ marginTop: '16px',position:"sticky",bottom:"0px" }}
                // justifyContent="flex-end"
                direction="row"
                spacing={2}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        className={props?.classes.button_cancel}
                        onClick={(e) => props?.openAlert(e)}>
                        Cancel
                    </Button>
                    <CustomAlertPopOver
                        anchorEl={props?.anchorAlert}
                        handleClose={() => props?.setAnchorAlert(null)}
                        title="Save Changes?"
                        bodyText={`Are you sure you want to cancel the insurance form?`}
                        confirmButtonName={'Yes'}
                        cancelButtonName={'No'}
                        confirmButtonColor={'#5571c6'}
                        type={'other'}
                        onSuccess={() => props?.handleCloseForm()}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LoadingButton
                        loading={props?.isSubmitting}
                        variant="contained"
                        className={props?.classes.button_create}
                        onClick={(e) => {
                            props?.formik.handleSubmit(e)
                        }}>
                        Save
                    </LoadingButton>
                </Grid>
            </Grid>
        </>
    )
}

export default InsuranceForm
