// Import necessary components from Material-UI
import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { Box, Divider, Grid ,makeStyles} from '@material-ui/core';
import moment from 'moment';
import blankImage from "../../../assests/icons/blankImage.svg"
import editIcon from "../../../assests/icons/editIcon.svg"
import Folder_svg_selected from '../../../assests/icons/Folder_svg_seleted.svg'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import FlagIcon from '@mui/icons-material/Flag'
import { apiStatusCodes } from 'services/Calendar';
import clsx from 'clsx'
import { LoadingButton } from '@mui/lab';
import { ClinicalNoteContext } from '../context/ClinicalNoteContext';
import { UserRoles } from '../../../constants/user-roles'

const useStyles = makeStyles(() => ({
  text:{
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color: "#424242",
    padding: "0px 12px 0px 12px",
    cursor: "pointer"
  },
  statusbar:{
        padding: "10px 10px",
        borderRadius: "4px",
        border:'1px solid #EAECF0',
        background: "#F8F9FB"
        
  },
  patientName:{
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 500,
    color: "#303E4E",
    padding: "3px 0px 0px 20px"
  },
  patientStatus:{
    fontSize: 13,
    fontWeight: '400',
    color: '#667689',
    marginLeft: "13px",
    borderRadius: "5px",
    backgroundColor:  '#E7E7E7' ,
    padding: "5px 10px 0px 10px",
    height: "23px"
  },
  patienDetails:{
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#303E4E",
    padding: "3px 0px 0px 10px"
  },
  date_back: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    color: "#5571C6",
    padding: "3px 0px 0px 10px",
    cursor: 'pointer',
  },
  bgColor_draft: {
    background: '#E9ECF1',
    fontSize: 13,
    fontWeight: '400',
    marginLeft: "13px",
    borderRadius: "5px",
    padding: "5px 5px 0px 5px",
    height: "23px"
},
bgColor_pending_approve: {
    background: '#FFF4DB',
    color: '#FFAC0C',
    fontSize: 13,
    fontWeight: '400',
    marginLeft: "13px",
    borderRadius: "5px",
    padding: "5px 5px 0px 5px",
    height: "23px"
},
bgColor_resend_edit: {
    background: '#ECF5FF',
    color: '#0076FC',
    fontSize: 13,
    fontWeight: '400',
    marginLeft: "13px",
    borderRadius: "5px",
    padding: "5px 5px 0px 5px",
    height: "23px"
},
bgColor_locked: {
    background: '#EBFFF3',
    color: '#14AE5C',
    fontSize: 13,
    fontWeight: '400',
    marginLeft: "13px",
    borderRadius: "5px",
    padding: "5px 5px 0px 5px",
    height: "23px"
},
bgColor_in_progress: {
    background: '#D8D1FF',
    color: '#7340DF',
    fontSize: 13,
    fontWeight: '400',
    marginLeft: "13px",
    borderRadius: "5px",
    padding: "5px 5px 0px 5px",
    height: "23px"
},
save_btn: {
  height: '39px !important',
  fontWeight: 'normal !important',
  borderRadius: '5px !important',
  background: '#5571C6 !important',
  color: 'white !important',
  textTransform: 'capitalize !important',
}
}))

const PatientDetails = ({clinicalNoteData,isConsultant,values,onChange,submitFormRef,initialValues,status,role,noteDetails,categoryList=[]}) => {

    const patientDetails = clinicalNoteData?.patient
    const classes = useStyles()
    const { state, checkDirtySessions } = React.useContext(ClinicalNoteContext)
    const nonEditable =
        [
            'pending',
            'locked',
            'approved_lock',
            'Pending Approval',
            'Approved & Locked',
            'LOCKED',
        ].includes(status) ||
        noteDetails?.name === 'view' ||
        (status === 'resent_to_edit' && role === UserRoles.PROVIDER) ? true : false
    const [category, setCategory] = useState([])
    const cpiPresent = category.some((item) => item.componentId === 'chief_complaint');
    const [listPatientStatus, setListPatientStatus] = useState([])
    const [disable, setDisable] = useState(true)
    const [textMessage ,setTextMessage] = useState(values?.chief_complaint?.narrative)
    const calculateAge = (dob) =>{
        const today = moment();
        const birthDate = moment(dob);
        const years = today.diff(birthDate, 'years');
        return years
    }
    const getListPatientStatus = async () => {
      try {
          const res = await apiStatusCodes.getStatusCodes({
              category: 'PATIENT',
              limit: 100000,
          })
          setListPatientStatus(
              res?.data?.listStatusCode?.data?.map((item) => ({
                  key: item.code,
                  value: item.name,
              })),
          )
      } catch (error) {
          console.log("err",err)
      }
    }
    const handleSave = () =>{
      const value = {narrative: textMessage}
        submitFormRef.current()
        checkDirtySessions({
          key: "chief_complaint",
          value: !_.isEqual(value, initialValues["chief_complaint"]),
        });
    
        onChange("chief_complaint", value);
        setDisable(true)
    }
    const patientStatus = (status) => {
      const foundStatus = listPatientStatus.find((item) => item.key === status);
    
      return foundStatus ? foundStatus.value : null;
    };
    function statusMapper(status) {
      switch (status) {
          case 'PENDING_APPROVE':
              return 'Pending Approve'
          case 'DRAFT':
              return 'Draft'
          case 'LOCKED':
              return 'Locked'
          case 'RESEND_EDIT':
              return 'Resend edit'
          case 'IN_PROGRESS':
              return 'In Progress'
          default:
              return status
      }
  }
  useEffect(() => {
    setCategory(categoryList)
}, [categoryList])
  useEffect(()=>{
    getListPatientStatus()
  },[])
  return (
      <Box
          sx={{
              overflow: 'auto',
              height: '90px',
              // maxWidth: "30%"
              marginRight: isConsultant ? '25px' : '0px',
          }}
          className={classes.statusbar}>
          <Grid
              container
              style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <img src={blankImage} alt="" />
                  <span className={classes.patientName}>
                      {patientDetails?.first_name + ' ' + patientDetails?.last_name}
                  </span>
                  <img
                      src={Folder_svg_selected}
                      alt=""
                      style={{ paddingLeft: '10px', cursor: 'pointer' }}
                      onClick={() => {
                          window.open(`/patient/${patientDetails?.id}/patient-record`)
                      }}
                  />
                  {patientDetails?.patient_status && (
                      <span className={classes.patientStatus}>
                          {patientStatus(patientDetails?.patient_status)}
                      </span>
                  )}
                  <span className={classes.patienDetails}>
                      {patientDetails?.gender.charAt(0).toUpperCase() +
                          patientDetails?.gender.slice(1)}{' '}
                  </span>
                  <span className={classes.patienDetails}>|</span>
                  <span className={classes.patienDetails}>
                      {calculateAge(patientDetails?.dob)} Years
                  </span>
                  <span className={classes.patienDetails}>|</span>
                  <span className={classes.patienDetails}>
                      {moment(patientDetails?.dob).format('MM/DD/YYYY')}{' '}
                  </span>
                  {patientDetails?.is_self_harm && (
                      <FlagIcon style={{ color: 'red', fontSize: 20, padding: '4px 0px 0 10px' }} />
                  )}
                  {patientDetails?.is_self_harm && (
                      <span className={classes.patienDetails}>Suicide/self-harm history </span>
                  )}
                  {patientDetails?.is_violence_behavior && (
                      <FlagIcon style={{ color: 'red', fontSize: 20, padding: '4px 0px 0 10px' }} />
                  )}
                  {patientDetails?.is_violence_behavior && (
                      <span className={classes.patienDetails}>Violence behavior history </span>
                  )}
                  {clinicalNoteData?.previous_cn_sign_lock_date && (<span className={classes.date_back} onClick={()=>{window.open(`/work-queues/medical-clinical-note/previous-note/${clinicalNoteData.id}`)}}>View previous note:{moment(clinicalNoteData?.previous_cn_sign_lock_date).format('MM/DD/YYYY')}{' '} </span>)}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span className={classes.patienDetails}>
                      {clinicalNoteData?.provider?.first_name}{' '}
                      {clinicalNoteData?.provider?.last_name}
                  </span>
                  <span className={classes.patienDetails}>|</span>
                  <span className={classes.patienDetails}>{clinicalNoteData?.templateName}</span>
                  <span className={classes.patienDetails}>|</span>
                  <span
                      className={clsx({
                          [classes.status_box]: true,
                          [classes.bgColor_draft]: clinicalNoteData?.status === 'DRAFT',
                          [classes.bgColor_in_progress]: clinicalNoteData?.status === 'IN_PROGRESS',
                          [classes.bgColor_locked]:
                              clinicalNoteData?.status === 'approved_lock' ||
                              clinicalNoteData?.status === 'LOCKED',
                          [classes.bgColor_resend_edit]: clinicalNoteData?.status === 'RESEND_EDIT',
                      })}>
                      {statusMapper(clinicalNoteData?.status)}
                  </span>
              </div>
          </Grid>
          {cpiPresent && (
              <Grid
                  container
                  item
                  alignItems="flex-start"
                  style={{ marginTop: '7px', overflow: 'auto' }}
                  xs={12}>
                  <Grid item xs={disable ? 12 : 11}>
                      <TextBox
                          multiline
                          minRows={1}
                          fullWidth
                          value={textMessage}
                          onChange={(value) => {
                              setTextMessage(value)
                          }}
                          disabled={disable}
                          placeholder="Enter Chief complaint"
                          InputProps={{
                              endAdornment: (
                                  <>
                                      {' '}
                                      {disable && !nonEditable ? (
                                          <img
                                              src={editIcon}
                                              alt=""
                                              onClick={() => {
                                                  setDisable(false)
                                              }}
                                              style={{ cursor: 'pointer' }}
                                          />
                                      ) : (
                                          <></>
                                      )}{' '}
                                  </>
                              ),
                          }}
                      />
                  </Grid>
                  {!disable && (
                      <Grid item xs={1} style={{ paddingLeft: '10px' }}>
                          <LoadingButton className={classes.save_btn} onClick={handleSave}>
                              Save
                          </LoadingButton>
                      </Grid>
                  )}
              </Grid>
          )}
      </Box>
  )
};

export default PatientDetails;
