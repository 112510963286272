import * as actionType from '../actionType'
import { UserRoles } from '../../constants/user-roles'
import { setLoading, setError, setRoleLoading } from './loader'
import {
    sendOtpService,
    verifyOtpService,
    getDetailsService,
    sendOtpToEmailService,
    verifyQuestionsService,
    verifyTokenService,
    getPatientProfileById,
    getAccountInfo,
} from '../../services/Login/login'
import { resetLocalStorage } from '../../utilities'
import { Auth } from 'aws-amplify'
import { getPermissionByRole } from './permission'
import axios from 'axios'
const baseURL = process.env.REACT_APP_SONORA_V2_API_URL

export const setMaxAttempt = (value) => {
    return {
        type: actionType.SET_MAX_ATTEMPT,
        value,
    }
}
export const setIsUserExist = (value) => {
    return {
        type: actionType.SET_IS_USER_EXSIST,
        value,
    }
}
export const setIsValidEmail = (value) => {
    return {
        type: actionType.SET_EMAIL_VALID,
        value,
    }
}

export const setResetData = (value) => {
    return {
        type: actionType.SET_RESET_DATA,
        value,
    }
}
export const setUser = (value) => {
    return {
        type: actionType.SET_USER,
        value,
    }
}

export const setAuthQRCodeURL = (value) => {
    return {
        type: actionType.SET_AUTH_QR_CODE,
        value,
    }
}

export const setMFA = (value) => {
    return {
        type: actionType.MFA,
        value,
    }
}

export const setSession = (value) => {
    return {
        type: actionType.SET_SESSION,
        value,
    }
}
export const setNewUser = (value) => {
    return {
        type: actionType.SET_NEW_USER,
        value,
    }
}

export const setPatientData = (value) => {
    return {
        type: actionType.SET_PATIENT_DATA,
        value,
    }
}
export const setOtpError = (value) => {
    return {
        type: actionType.SET_OTP_ERROR,
        value,
    }
}

export const setShowAnotherWayOtpField = (value) => ({
    type: actionType.SET_ANOTHER_WAY_OTP_FIELD,
    value,
})

export const logout = () => {
    return {
        type: actionType.LOGOUT,
    }
}

export const setRedirectURL = (url) => ({
    type: actionType.SET_REDIRECT_URL,
    url,
})

export const setIsOTPRequired = (value) => ({
    type: actionType.SET_IS_OTP_REQUIRED,
    value,
})

export const setForceToChangePassword = (value) => ({
    type: actionType.SET_FORCE_TO_CHANGE_PASSWORD,
    value,
})

export const setActiveLatest = (value) => ({
    type: actionType.SET_ACTIVE_LATEST,
    value: value === undefined ? parseInt(new Date().getTime() / 1000) : value,
})

export const setCurrentSession = (session) => ({
    type: actionType.SET_CURRENT_SESSION,
    value: session,
})

export const redirectTo = (url) => {
    return (dispatch) => {
        dispatch(setRedirectURL(url))
    }
}
export const sendOtp = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        sendOtpService(data)
            .then((res) => {
                dispatch(setLoading(false))
                if (res && res.data) {
                    dispatch(setUser(!!res.data.Session))
                    dispatch(setSession(res.data.Session))
                } else {
                    dispatch(setError(true))
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err.status === 404) {
                    dispatch(setUser(false))
                    return
                }
                dispatch(setError(err.data))
            })
    }
}

export const verifyLogin = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        verifyOtpService(payload)
            .then((res) => {
                dispatch(setLoading(false))
                const { data } = res
                if (!data.isOtpValid && data.isOtpValid !== undefined) {
                    dispatch(setOtpError(true))
                } else {
                    localStorage.setItem('token', data?.IdToken)
                    return getAccountInfo().then((response) => {
                        dispatch(
                            setPatientData({
                                ...response.data,
                                accessToken: data?.IdToken,
                                name: `${response?.data.given_name} ${response?.data.family_name}`,
                                role: response?.data['custom:role'],
                            }),
                        )
                        dispatch(setError(false))
                        dispatch(setOtpError(false))
                    })
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                console.log(err, 'err')
                if (err && err.data) {
                    if (err.data.te) {
                        dispatch(setMaxAttempt(true))
                    }
                    if (err.data.statusCode === 400) {
                        dispatch(setOtpError(true))
                    } else {
                        dispatch(setError(err.data))
                    }
                } else {
                    dispatch(setError(true))
                }
            })
    }
}

export const verifyToken = () => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setRoleLoading(true))
        return getAccountInfo()
            .then((response) => {
                localStorage.setItem(
                    'role',
                    response.data.role === UserRoles.TENANT_ADMIN
                        ? UserRoles.TENANT_ADMIN
                        : UserRoles.PROVIDER,
                )
                localStorage.setItem('real_role', response.data.role)
                dispatch(
                    setPatientData({
                        ...response.data,
                        accessToken: localStorage.getItem('token'),
                        name: `${response?.data.given_name} ${response?.data.family_name}`,
                        role:
                            response.data.role === UserRoles.TENANT_ADMIN
                                ? UserRoles.TENANT_ADMIN
                                : UserRoles.PROVIDER,
                        role_code: response.data.role,
                    }),
                )
                // dispatch(getPermissionByRole(response?.data['role_id']))
                dispatch(setLoading(false))
                dispatch(setRoleLoading(false))
                dispatch(setError(false))
                dispatch(setOtpError(false))
            })
            .catch((err) => {
                console.log(err, 'error')
                dispatch(setLoading(false))
                dispatch(setRoleLoading(false))
                // reset localStorage
                resetLocalStorage()
                dispatch(logout())
                dispatch(setError(true))
            })
    }
}

export const getDetails = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getDetailsService(data)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setError(false))
                dispatch(setResetData(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setLoading(false))
            })
    }
}

export const sendOtpToEmail = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        sendOtpToEmailService(data)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setIsValidEmail(res.data))
                dispatch(setShowAnotherWayOtpField(true))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err && err.data && err.data.isMaxAttempts) {
                    dispatch(setMaxAttempt(true))
                } else if (
                    err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.isMaxAttempts
                ) {
                    dispatch(setMaxAttempt(true))
                } else {
                    dispatch(setError(true))
                }
            })
    }
}
export const verifyQuestions = (data) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        verifyQuestionsService(data)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setPatientData(res.data))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                if (err && err.data && err.data.isMaxAttempts) {
                    dispatch(setMaxAttempt(true))
                } else {
                    dispatch(setMaxAttempt(false))
                    dispatch(setError(true))
                }
            })
    }
}
export const getPatientProfileByUserId = (userId, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getPatientProfileById(userId)
            .then((res) => {
                const { data } = res
                dispatch(setLoading(false))
                onSuccess && onSuccess(data)
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const logInSuccess = (data) => {
    return (dispatch) => {
        // update email as verified email after logging in
        if (!data.attributes.email_verified) {
            const token = data?.signInUserSession?.idToken?.jwtToken
            axios({
                url: `${baseURL}/practitioner/email-verified`,
                headers: {
                    authorization: `Bearer ${token}`,
                },
                method: 'PUT',
            })
        }

        const token = data?.signInUserSession?.idToken?.jwtToken
        localStorage.setItem('announcements_is_read',false)
        localStorage.setItem('token', token)
        localStorage.setItem(
            'role',
            data.storage.role === UserRoles.TENANT_ADMIN
                ? UserRoles.TENANT_ADMIN
                : UserRoles.PROVIDER,
        )
        localStorage.setItem(
            'userId',
            data.attributes['custom:user_id']
                ? data.attributes['custom:user_id']
                : data.attributes.sub,
        )
        localStorage.setItem(
            'name',
            Boolean(data.attributes.given_name) && Boolean(data.attributes.given_name)
                ? `${data.attributes.given_name} ${data.attributes.family_name} `
                : data.attributes.email,
        )
        localStorage.setItem('isProfileComplete', true)
        localStorage.setItem(
            'photo',
            data.attributes.photo ||
                'https://decisionsystemsgroup.github.io/workshop-html/img/john-doe.jpg',
        )
        dispatch(setUser(!!data.signInUserSession))
        dispatch(setSession(data.signInUserSession))
        dispatch(setError(false))
        dispatch(setOtpError(false))
        dispatch(setIsOTPRequired(false))

        dispatch(
            setPatientData({
                ...data.attributes,
                accessToken: token,
                name: data.attributes.email,
                role:
                    data.storage.role === UserRoles.TENANT_ADMIN
                        ? UserRoles.TENANT_ADMIN
                        : UserRoles.PROVIDER,
                id: data.attributes.sub,
            }),
        )
        dispatch(verifyToken())
    }
}

export const staffLogin = (email, password) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        Auth.signIn(email, password)
            .then(async (data) => {
                if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    dispatch(setForceToChangePassword(data))
                    return
                }

                if (data.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    dispatch(setMFA(data))
                    return
                }

                const token = data?.signInUserSession?.idToken?.jwtToken
                const response = await axios({
                    url: `${baseURL}/account/${data.attributes['custom:user_id']}`,
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                })

                if (data.preferredMFA === 'NOMFA' && response.data.mfa) {
                    dispatch(setMFA(data))
                    return
                }

                if (data.preferredMFA === 'NOMFA') {
                    dispatch(logInSuccess(data))
                }
            })
            .catch((e) => {
                dispatch(setError(e))
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const verifyOTP = (otp) => {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            dispatch(setLoading(true))
            const data = await Auth.confirmSignIn(state.login.session, otp)
            const token = data?.signInUserSession?.idToken?.jwtToken
            localStorage.setItem('token', token)
            localStorage.setItem('role', UserRoles.PROVIDER)
            localStorage.setItem(
                'userId',
                data.attributes['custom:user_id']
                    ? data.attributes['custom:user_id']
                    : data.attributes.sub,
            )
            localStorage.setItem(
                'name',
                Boolean(data.attributes.given_name) && Boolean(data.attributes.given_name)
                    ? `${data.attributes.given_name} ${data.attributes.family_name}`
                    : data.attributes.email,
            )
            localStorage.setItem('isProfileComplete', true)
            localStorage.setItem(
                'photo',
                data.attributes.photo ||
                    'https://decisionsystemsgroup.github.io/workshop-html/img/john-doe.jpg',
            )
            dispatch(setUser(!!data.signInUserSession))
            dispatch(setSession(data.signInUserSession))
            dispatch(setError(false))
            dispatch(setOtpError(false))
            dispatch(setIsOTPRequired(false))

            dispatch(
                setPatientData({
                    ...data.attributes,
                    accessToken: token,
                    name: data.attributes.email,
                    role: UserRoles.PROVIDER,
                    id: data.attributes.sub,
                }),
            )
        } catch (e) {
            dispatch(setError(e))
        } finally {
            dispatch(setLoading(false))
        }
    }
}
