import {
    Button,
    Grid,
    Snackbar,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { CancellationChargeModal } from '../../components/Appointments/CancellationChargeModal'
import CancelModal from '../../components/Appointments/CancelModal'
import {
    APPOINTMENT_STATUS_CODE,
    APPOINTMENT_TYPE,
} from '../../constants/appointment'
import { BOOKING_TYPE } from '../../constants/appointments'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import {
    cancelAppointmentById,
    getAppointments,
    refundInitiate,
    refundInitiateFromModal,
} from '../../services/Appointments/appointments'
import {
    NoRowsOverlayEl,
    ButtonEl,
} from '../../shared/elements'
import { getTimeframe } from '../../shared/utils'
import {
    resetAppointmentsData,
    setBookAppointmentData,
    setIsRescheduleAppointment,
    setSearchProviderData,
    setSelectedAppointmentData,
    setSelectedProviderDetails,
    setUserDetails,
} from '../../store/actions/appointments'
import { getUserDetailsForForm } from '../../utilities/appointments'
import LoadingPage, {
    LoaderType,
} from '../../utilities/loading-page'
import * as actionTypes from './actionTypes'
import { initialState, reducer } from './reducer'
import { generateListColumns } from './utils'

function AppointmentList(props) {
    const history = useHistory()
    const dispatcher = useDispatch()

    const [isModalOpen, setModalOpen] =
        React.useState(false)
    const [cancellationTriger, setCancellationTriger] =
        React.useState('')
    const [newAppointmentId, setNewAppointmentId] =
        React.useState('')
    const [
        isCancellationChargeDone,
        setIsCancellationChargeDone,
    ] = React.useState(true)

    const [rowData, setRowData] = React.useState()
    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )
    const [snackbar, setSnackbar] = React.useState({
        open: false,
        message: '',
    })

    const userInfo = React.useMemo(() => {
        return {
            userId: localStorage.getItem('userId'),
            role: localStorage.getItem('role'),
        }
    }, [])

    const columns = [
        ...generateListColumns(
            props.role,
            userInfo.role,
            onSort,
            onRowClick,
        ),
        {
            headerName: (
                <Typography variant="body2">
                    Actions
                </Typography>
            ),
            field: 'action',
            flex: 2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        className="content-align-center">
                        {renderFollowUp(params.row)}
                        {renderReschedule(params.row)}
                        {renderCancel(params.row)}
                        {renderStartConsultation(
                            params.row,
                        )}
                    </Grid>
                )
            },
        },
    ]

    const [filters, setFilters] = React.useState({
        page: 1,
        limit: 10,
        type: props.type,
        ...getTimeframe(props.interval, props.type),
    })

    const query = useQuery(
        [
            'appointments',
            props.role === UserRoles.TM2U_ADMIN ||
            props.role === UserRoles.TM2U_SUPER_ADMIN
                ? ''
                : props.userId,
            props.role,
            filters,
        ],
        getAppointments,
        { keepPreviousData: true },
    )

    React.useEffect(() => {
        const { fromDate, toDate } = getTimeframe(
            props.interval,
            props.type,
        )
        setFilters({
            ...filters,
            page: 1,
            type: props.type,
            fromDate,
            toDate,
        })
    }, [props.type, props.interval])

    React.useEffect(() => {
        dispatcher(resetAppointmentsData())
    }, [])

    async function handleChargeCancelAppointment(val) {
        setIsCancellationChargeDone(false)
        const cancellationChargeApplyId =
            cancellationTriger === 'fromProviderTable'
                ? state.appointments.selected.id
                : newAppointmentId
        try {
            const res =
                cancellationChargeApplyId &&
                (await refundInitiateFromModal({
                    appointment_id:
                        cancellationChargeApplyId,
                    cancellation_charge: val.chargeAmount,
                    refund_remarks: val.remarks,
                }))
            if (res?.data?.message) {
                const successMsg =
                    res.data && res.data.message
                        ? res.data.message
                        : 'Refund completed'
                setSnackbar({
                    open: true,
                    message: successMsg,
                })
                setModalOpen(false)
                setIsCancellationChargeDone(true)

                const { fromDate, toDate } = getTimeframe(
                    props.interval,
                    props.type,
                )

                fetchAppointments({
                    userId: props.userId,
                    role: props.role,
                    page: filters.page,
                    limit: filters.limit,
                    type: props.type,
                    fromDate,
                    toDate,
                })
            } else {
                setSnackbar({
                    open: true,
                    message: 'Something went wrong',
                })
                setModalOpen(false)
                setIsCancellationChargeDone(true)
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message:
                    'Something went wrong. Please try again later',
            })
            setModalOpen(false)
            setIsCancellationChargeDone(true)
        }
    }

    async function handleCancelAppointment(_, isB24) {
        if (
            (userInfo.role === UserRoles.TM2U_ADMIN ||
                userInfo.role ===
                    UserRoles.TM2U_SUPER_ADMIN ||
                userInfo.role ===
                    UserRoles.CLINIC_CORDINATION) &&
            !isB24
        ) {
            try {
                const res = await cancelAppointmentById({
                    appointment_id:
                        state.appointments.selected.id,
                })
                if (res?.data?.status === true) {
                    const getNewAppointmentId =
                        res?.data?.data?.id
                    setNewAppointmentId(getNewAppointmentId)
                    setSnackbar({
                        open: true,
                        message:
                            'Appointment cancelled successfully',
                    })
                    onDialogToggle()
                    setModalOpen(true)
                    setCancellationTriger(
                        'fromPatientTable',
                    )
                    query.refetch()
                }
            } catch (error) {
                setSnackbar({
                    open: true,
                    message:
                        'Something went wrong. Please try again later',
                })
            }
        } else {
            try {
                onDialogToggle()
                const res = await cancelAppointmentById({
                    appointment_id:
                        state.appointments.selected.id,
                })
                if (res?.data?.status === true) {
                    setSnackbar({
                        open: true,
                        message:
                            'Appointment cancelled successfully',
                    })
                    query.refetch()
                    try {
                        const newAppointmentId =
                            res?.data?.data?.id
                        if (newAppointmentId) {
                            await refundInitiate({
                                appointment_id:
                                    newAppointmentId,
                            })
                        }
                    } catch (error) {
                        setSnackbar({
                            open: true,
                            message:
                                'Something went wrong. Please try again later',
                        })
                    }
                }
            } catch (error) {
                setSnackbar({
                    open: true,
                    message:
                        'Something went wrong. Please try again later',
                })
            }
        }
    }

    function calculateTimeDiff(params) {
        const currentTime = moment.utc().local()
        const startTime = moment
            .utc(`${params.date} ${params.start}`)
            .local()
        const endTime = moment
            .utc(`${params.date} ${params.end}`)
            .local()
        const start = startTime.diff(currentTime, 'minutes')
        const end = currentTime.diff(endTime, 'minutes')

        return { start, end }
    }

    function renderFollowUp(row) {
        if (
            props.type === 'past' &&
            row.appointment_status ===
                APPOINTMENT_STATUS_CODE.FULFILLED
        ) {
            return (
                <Grid item className="content-align-center">
                    <Typography
                        variant="body1"
                        color="primary"
                        onClick={() => onFollowUP(row)}
                        style={{
                            cursor: 'pointer',
                        }}>
                        <strong>Follow Up</strong>
                    </Typography>
                </Grid>
            )
        }
    }

    function renderCancel(row) {
        // NOTE: We don't have to show reschedule and cancel button when they are viewing patients appointments
        if (
            userInfo.role === UserRoles.PROVIDER &&
            props.role === UserRoles.PATIENT
        ) {
            return
        }

        if (props.role !== UserRoles.PROVIDER) {
            if (
                (row.appointment_status ===
                    APPOINTMENT_STATUS_CODE.NOSHOW ||
                    row.appointment_status ===
                        APPOINTMENT_STATUS_CODE.PENDING) &&
                !row.is_followup_required &&
                row.appointment_status !==
                    APPOINTMENT_STATUS_CODE.FULFILLED
            ) {
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Typography
                            variant="body1"
                            color="primary"
                            onClick={() =>
                                onCancelAppointment(row)
                            }
                            style={{
                                cursor: 'pointer',
                            }}>
                            <strong>Cancel</strong>
                        </Typography>
                    </Grid>
                )
            }
        }
    }

    function renderReschedule(row) {
        // NOTE: We don't have to show reschedule and cancel button when they are viewing patients appointments
        if (
            userInfo.role === UserRoles.PROVIDER &&
            props.role === UserRoles.PATIENT
        ) {
            return
        }

        if (props.role !== UserRoles.PROVIDER) {
            if (
                (row.appointment_status ===
                    APPOINTMENT_STATUS_CODE.NOSHOW ||
                    row.appointment_status ===
                        APPOINTMENT_STATUS_CODE.PENDING) &&
                !row.is_followup_required &&
                row.appointment_status !==
                    APPOINTMENT_STATUS_CODE.FULFILLED
            ) {
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Typography
                            variant="body1"
                            color="primary"
                            onClick={() =>
                                onReschedule(row)
                            }
                            style={{
                                cursor: 'pointer',
                            }}>
                            <strong>Reschedule</strong>
                        </Typography>
                    </Grid>
                )
            }
        }
    }

    function renderStartConsultation(row) {
        if (
            userInfo.role === UserRoles.PATIENT ||
            userInfo.role === UserRoles.CLINIC_CORDINATION
        ) {
            if (
                [
                    APPOINTMENT_STATUS_CODE.NOSHOW,
                    APPOINTMENT_STATUS_CODE.PENDING,
                    APPOINTMENT_STATUS_CODE.OPEN,
                ].includes(row.appointment_status)
            ) {
                const diff = calculateTimeDiff(row)
                if (
                    row.appointment_type ===
                        APPOINTMENT_TYPE.IN_CLINIC &&
                    props.role !==
                        UserRoles.CLINIC_CORDINATION
                ) {
                    return <></>
                } else if (
                    diff.start > 30 ||
                    diff.end >= 30 ||
                    row.appointment_status ===
                        APPOINTMENT_STATUS_CODE.NOSHOW
                ) {
                    return (
                        <Grid
                            item
                            className="content-align-center">
                            <Tooltip
                                title="Button will be enable 30 min prior
                        to appointment"
                                placement="bottom">
                                <div>
                                    <Typography
                                        variant="body1"
                                        color="primary">
                                        <strong>
                                            Start
                                            Consultation
                                        </strong>
                                    </Typography>
                                    {/* <Button
                                        disabled
                                        variant="outlined">
                                        Start Consultation
                                    </Button> */}
                                </div>
                            </Tooltip>
                        </Grid>
                    )
                }
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <ButtonEl
                            href={row.consultation_link}
                            variant="outlined">
                            Start Consultation
                        </ButtonEl>
                    </Grid>
                )
            } else if (
                row.appointment_status ===
                APPOINTMENT_STATUS_CODE.CANCELLED
            ) {
                return (
                    <>
                        <Grid
                            item
                            className="content-align-center">
                            <Typography
                                variant="body1"
                                color="error">
                                <strong>Cancelled</strong>
                            </Typography>
                        </Grid>
                        {userInfo.role ===
                            UserRoles.CLINIC_CORDINATION && (
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    color="primary"
                                    className={
                                        row.refund_status ===
                                            APPOINTMENT_STATUS_CODE.PENDING &&
                                        row.refund_required ===
                                            true
                                            ? 'cursor-pointer'
                                            : 'disabled-refund'
                                    }
                                    onClick={() =>
                                        onChargeCancellation(
                                            row,
                                        )
                                    }>
                                    <strong>Refund</strong>
                                </Typography>
                            </Grid>
                        )}
                    </>
                )
            } else if (
                row.appointment_status ===
                APPOINTMENT_STATUS_CODE.FULFILLED
            ) {
                return (
                    <Grid
                        item
                        className="content-align-center">
                        <Typography
                            variant="body1"
                            style={{ color: '#4C8080' }}>
                            <strong>Completed</strong>
                        </Typography>
                    </Grid>
                )
            }
        } else if (
            row.appointment_status ===
            APPOINTMENT_STATUS_CODE.CANCELLED
        ) {
            return (
                <>
                    <Grid item>
                        <Typography
                            variant="body1"
                            color="error">
                            <strong>Cancelled</strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body1"
                            color="primary"
                            className={
                                row.refund_status ===
                                    APPOINTMENT_STATUS_CODE.PENDING &&
                                row.refund_required === true
                                    ? 'cursor-pointer'
                                    : 'disabled-refund'
                            }
                            onClick={() =>
                                onChargeCancellation(row)
                            }>
                            <strong>Refund</strong>
                        </Typography>
                    </Grid>
                </>
            )
        } else if (
            row.appointment_status ===
            APPOINTMENT_STATUS_CODE.FULFILLED
        ) {
            return (
                <Grid item className="content-align-center">
                    <Typography
                        variant="body1"
                        style={{ color: '#4C8080' }}>
                        <strong>Completed</strong>
                    </Typography>
                </Grid>
            )
        }
    }

    async function onReschedule(row) {
        dispatch({
            type: actionTypes.SET_SELECTED_APPOINTMENT,
            row,
        })
        try {
            const userInfo = await getUserDetailsForForm(
                row.user_id,
            )
            dispatcher(setSelectedAppointmentData(row))
            dispatcher(setUserDetails(userInfo))
            dispatcher(
                setSearchProviderData({
                    speciality: row.speciality?.id ?? '',
                    visitType: row.appointment_type,
                    clinic: row.clinic?.id ?? '',
                    zipCode: row.clinic?.zip ?? '',
                }),
            )

            const bookingData = {
                specialityType: row.speciality?.type ?? '',
                appointmentType: row.appointment_type,
                clinicName: row.clinic?.clinic_name ?? '',
                bookingType: row.is_followup_required
                    ? BOOKING_TYPE.FOLLOWUP
                    : BOOKING_TYPE.CHECKUP,
                selectedMinorId: row.user_id,
                patientType: row.interact_with_dr,
            }

            dispatcher(
                setSelectedProviderDetails({
                    firstName: row.provider.first_name,
                    lastName: row.provider.last_name,
                    speciality: row.speciality?.id,
                    previousAppointmentDate: row.date,
                    previousAppointmentSlot: row.start,
                    startTime: row.start,
                    endTime: row.end,
                    providerId: row.provider_id,
                    isFollowUp: false,
                    groupTaxId: row.provider.group_tax_id,
                }),
            )

            dispatcher(setBookAppointmentData(bookingData))
            dispatcher(setIsRescheduleAppointment(true))
            history.push(Routes.APPOINTMENTS.PROVIDER_LIST)
        } catch (error) {
            console.log({ error })
            setSnackbar({
                open: true,
                message:
                    'Something went wrong. Please try again later',
            })
        }
    }

    async function onFollowUP(row) {
        dispatch({
            type: actionTypes.SET_SELECTED_APPOINTMENT,
            row,
        })

        try {
            const userInfo = await getUserDetailsForForm(
                row.user_id,
            )
            dispatcher(setUserDetails(userInfo))
            dispatcher(
                setSearchProviderData({
                    speciality: row.speciality?.id ?? '',
                    visitType: row.appointment_type,
                    clinic: row.clinic?.id ?? '',
                    zipCode: row.clinic?.zip ?? '',
                }),
            )
            const bookingData = {
                specialityType: row.speciality?.type ?? '',
                appointmentType: row.appointment_type,
                clinicName: row.clinic?.clinic_name ?? '',
                bookingType: BOOKING_TYPE.FOLLOWUP,
                selectedMinorId: row.user_id,
                patientType: row.interact_with_dr,
                followupDays: row.followup_days,
                previousAppointmentId:
                    row.is_followup_required ||
                    row.booking_type ===
                        BOOKING_TYPE.FOLLOWUP
                        ? row.id
                        : '',
                providerIdOnFollowUp: row.provider_id,
                appointmentDate: row.date,
                patientId: row.user_id,
            }

            dispatcher(setBookAppointmentData(bookingData))
            history.push(Routes.APPOINTMENTS.PROVIDER_LIST)
        } catch (error) {
            setSnackbar({
                open: true,
                message:
                    'Something went wrong. Please try again later',
            })
        }
    }

    function onCancelAppointment(row) {
        dispatch({
            type: actionTypes.SET_SELECTED_APPOINTMENT,
            row,
        })
        onDialogToggle()
        setRowData(row)
    }

    function onModalClose() {
        setModalOpen(false)
    }

    function onChargeCancellation(row) {
        dispatch({
            type: actionTypes.SET_SELECTED_APPOINTMENT,
            row,
        })
        setRowData(row)
        setModalOpen(true)
        setCancellationTriger('fromProviderTable')
    }

    function onSort(value) {}

    function onRowClick(userId, role) {
        const editDecider =
            role === UserRoles.PROVIDER ? 'Provider' : ''
        history.push(Routes.PROFILE, {
            userId,
            role,
            editDecider,
        })
    }

    function onPageChange(page) {
        setFilters({ ...filters, page: page + 1 })
    }

    function onDialogToggle() {
        dispatch({
            type: actionTypes.TOGGLE_DIALOG,
        })
    }

    function onSnackbarClose() {
        setSnackbar({
            open: false,
            message: '',
        })
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={snackbar.open}
                autoHideDuration={3000}
                message={snackbar.message}
                onClose={onSnackbarClose}
            />
            {query.isFetching && (
                <LoadingPage type={LoaderType.LINEAR} />
            )}
            <Grid
                container
                className="MuiDataGrid-table-wrapper appt-table bg-white">
                <DataGrid
                    getRowId={(row) => row.id}
                    rows={query.data?.data ?? []}
                    columns={columns}
                    pagination
                    page={filters.page - 1}
                    pageSize={filters.limit}
                    rowsPerPageOptions={[filters.limit]}
                    rowCount={
                        query.data?.totalCount ??
                        filters.limit
                    }
                    paginationMode="server"
                    onPageChange={onPageChange}
                    disableColumnSelector
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={55}
                    components={{
                        NoRowsOverlay: () => (
                            <NoRowsOverlayEl title="No Appointments Found." />
                        ),
                    }}
                    loading={query.isLoading}
                />
            </Grid>

            <CancelModal
                key={state.appointments.timestamp}
                open={state.dialog.open}
                toggleOpen={onDialogToggle}
                handleCancelAppointment={
                    handleCancelAppointment
                }
                selectedAppointmentDetail={
                    state.appointments.selected
                }
            />
            <CancellationChargeModal
                isCancellationChargeDone={
                    isCancellationChargeDone
                }
                opened={isModalOpen}
                onClose={onModalClose}
                rowData={rowData}
                onSubmit={handleChargeCancelAppointment}
            />
        </div>
    )
}

export default AppointmentList
