const TickCircle = () => {
    return (
        <svg
            width="121"
            height="120"
            viewBox="0 0 121 120"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M60.5 110C88 110 110.5 87.5 110.5 60C110.5 32.5 88 10 60.5 10C33 10 10.5 32.5 10.5 60C10.5 87.5 33 110 60.5 110Z"
                stroke="#14AE5C"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M39.25 60.0001L53.4 74.1501L81.75 45.8501"
                stroke="#14AE5C"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default TickCircle
