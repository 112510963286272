import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    makeStyles,
} from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import Routes from 'constants/routes'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import NoteField from 'containers/WorkQueues/component/NoteField'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getPractitionerByRole } from 'services/Calendar'
import { apiInsurrancePolicy } from 'services/Patient/apiInsurrancePolicy'
import { getListInsurancePlans } from 'services/Patient/patient'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { ConcurrencyInput, ZipCode } from 'shared/elements/FormItem/Input'
import { RadioGroup } from 'shared/elements/FormItem/RadioGroup'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { formatPhone } from 'utilities'
import * as yup from 'yup'
import '../../styles.scss'
import { INITIAL_VALUES, patientsRelationship, paymentMethodOptions } from './seed'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import InsuranceForm from './components/InsuranceForm'
import { backInsuranceCard, frontInsuranceCard } from 'store/actions/patient'

const { v4: uuidv4 } = require('uuid')
const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
    },
    new_plan_btn: {
        padding: '8px 16px',
        cursor: 'pointer',
        background: '#66768a33',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    patient_session: {
        color: '#5571C6',
    },

    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        marginBottom: '1rem',
    },
    divider: {
        fontSize: 18,
        color: '#5571c6',
        border: 'none',
        borderBottom: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        paddingInlineStart: 0,
        marginLeft: -2,
        marginTop: '1rem',
        marginBottom: -15,
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
        // height: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },

    readOnlyInput: {
        backgroundColor: '#989CA8 !important', // Set your desired background color here
    },
    inputFormLabel: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#667689',
    },
    required_text: {
        color: 'red',
    },
})

const InsuranceNewForm = (props) => {
    const classes = useStyles()
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const dispatch = useDispatch()
    const notification = useNotification()
    const history = useHistory()
    // console.log('history@@@: ', history.location.state)
    const { id: patientId, insuranceId } = useParams()
    const [insuranceType, setInsuranceType] = useState(history?.location?.state?.insuranceType)

    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [providerInput, setProviderInput] = useState(false)
    const [providerOptions, setProviderOptions] = React.useState([])
    const [planOptions, setPlanOptions] = React.useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [insurancePlanFormOpen, setInsurancePlanFormOpen] = useState(false)
    const [anchorAlert, setAnchorAlert] = useState(null)
    // const [isSelfInsured, setIsSelfInsured] = useState(false)
    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(true)

        Promise.allSettled([
            getPractitionerOptions(),
            getPlansOptions(),
            insuranceId ? fetchInsuranceDetails() : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])

    async function getPractitionerOptions() {
        try {
            const response = await getPractitionerByRole()
            const data = response.data.map((item) => ({
                key: item.id,
                value: `${item.first_name} ${item.last_name}`,
            }))
            setProviderOptions(data)
        } catch (error) {
            console.log({ error })
        }
    }

    async function getPlansOptions() {
        try {
            const response = await getListInsurancePlans(0, 10000)
            const data = response.data.data.map((item) => ({
                key: item.id,
                value: item.plan_name,
                phone_number: item.phone_number,
            }))
            setPlanOptions(data)
        } catch (error) {
            console.log({ error })
        }
    }

    const fetchInsuranceDetails = async () => {
        setDataLoading(true)
        try {
            const resp = await apiInsurrancePolicy.getId(patientId, insuranceId)
            const {
                kareo_case_external_id,
                kareo_case_id,
                kareo_company_external_id,
                kareo_company_id,
                ...payload
            } = resp.data
            setInitData({
                ...payload,
                insurance: {
                    ...payload.insurance,
                    policy_effective:
                        payload.insurance.policy_effective &&
                        moment(payload.insurance.policy_effective),
                    policy_end:
                        payload.insurance.policy_end && moment(payload.insurance.policy_end),
                    relation_info: {
                        ...payload.insurance.relation_info,
                        dob:
                            payload.insurance.relation_info.dob &&
                            moment(payload.insurance.relation_info.dob),
                    },
                },
                note: '',
                noteView: 'no-view',
            })
            setProviderInput(!!resp?.data?.practitioner_id)
            dispatch(frontInsuranceCard(resp?.data?.front_card))
            dispatch(backInsuranceCard(resp?.data?.back_card))
            // setIsSelfInsured(payload.insurance.patient_relationship &&
            //     payload.insurance.patient_relationship !== 'Self')
        } catch (error) {
            notification(error?.data?.message || `Cannot get insurance details ${insuranceId}`)
        } finally {
            setDataLoading(false)
        }
    }
    const handleCloseForm = () => {
        history.replace(Routes.PATIENT.INSURANCES.MAIN.replace(':id', patientId))
    }
    const goToEditPage = (insuranceId) => {
        // insuranceModalToggle(false, insuranceId)
        history.push(
            Routes.PATIENT.INSURANCES.EDIT.replace(':id', patientId).replace(
                ':insuranceId',
                insuranceId,
            ),
        )
    }
    const handleSubmit = async (payload) => {
        try {
            if (
                formik?.values?.payment_method?.code === 'Insurance' &&
                formik?.values?.type === ''
            ) {
                notification('Please select an insurance-type!', 'error')
                return
            }

            setIsSubmitting(true)

            delete payload.patient_id
            delete payload.practitioner_id
            delete payload.insurance.is_policy_emp

            if (payload.insurance.patient_relationship === 'Self') {
                payload.insurance.relation_info = INITIAL_VALUES.insurance.relation_info
            }

            if (payload.payment_method.code === 'Self Pay') {
                payload.insurance = {
                    ...INITIAL_VALUES.insurance,
                    policy_effective: payload.insurance.policy_effective,
                    policy_end: payload.insurance.policy_end,
                    patient_relationship: payload.insurance.patient_relationship,
                    relation_info: payload.insurance.relation_info,
                }
                payload.status = ''
                payload.type = ''
            }

            const valueToSend = {
                ...payload,
                insurance: {
                    ...payload.insurance,
                    group_name: payload.insurance?.group_name.trim(),
                    policy_effective:
                        payload.insurance?.policy_effective &&
                        moment(payload.insurance?.policy_effective).utc(),
                    policy_end:
                        payload.insurance?.policy_end &&
                        moment(payload.insurance?.policy_end).utc(),
                },
            }
            let res
            if (insuranceId) {
                await apiInsurrancePolicy.update(patientId, insuranceId, valueToSend)
            } else {
                res = await apiInsurrancePolicy.create(patientId, valueToSend)
            }
            const msg = insuranceId
                ? `Insurance plan updated successfully!`
                : `Insurance plan created successfully!`

            notification(msg, 'success')
            if (props?.handleCloseEdit) {
                props?.handleCloseEdit()
            } else {
                if (res) {
                    goToEditPage(res?.data?.id)
                }
            }
        } catch (error) {
            const errMsg =
                error?.data?.message ||
                (insuranceId
                    ? `Insurance plan updated unsuccessfully`
                    : `Insurance plan created unsuccessfully`)

            notification(errMsg, 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    const validationSchema = yup.object({
        insurance: yup.object({
            plan: yup.object({
                code: yup.string().test({
                    name: 'plan_code',
                    test(value, context) {
                        if (context.from[2].value.payment_method.code === 'Insurance' && !value) {
                            return this.createError({
                                message: 'Enter health plan',
                                path: 'insurance.plan.code',
                            })
                        }
                        return true
                    },
                }),
                display: yup.string(),
            }),
            policy_no: yup.string().test({
                name: 'policy_no',
                test(value, context) {
                    if (context.from[1].value.payment_method.code === 'Insurance' && !value) {
                        return this.createError({
                            message: 'Enter policy number',
                            path: 'insurance.policy_no',
                        })
                    }
                    return true
                },
            }),
            policy_effective: yup.string().test({
                name: 'policy_effective',
                test(value, context) {
                    const { policy_effective, policy_end } = context.from[1].value.insurance

                    if (
                        context.from[1].value.payment_method.code === 'Insurance' &&
                        !policy_effective
                    ) {
                        return this.createError({
                            message: 'Enter effective start date',
                            path: 'insurance.policy_effective',
                        })
                    }

                    const date = moment(policy_effective)
                    if (!date.isValid()) {
                        return this.createError({
                            message: 'Date invalid',
                            path: 'insurance.policy_effective',
                        })
                    }

                    if (policy_end && moment(policy_end).isBefore(date)) {
                        return this.createError({
                            message: 'Effective start date must be before effective end date',
                            path: 'insurance.policy_effective',
                        })
                    }
                    return true
                },
            }),
            policy_end: yup.string().test({
                name: 'policy_end',
                test(value, context) {
                    const { policy_effective, policy_end } = context.from[1].value.insurance

                    // if (!policy_end) {
                    //     return this.createError({
                    //         message: 'Enter effective end date',
                    //         path: 'insurance.policy_end',
                    //     })
                    // }

                    const date = moment(policy_end)
                    if (!date.isValid()) {
                        return this.createError({
                            message: 'Date invalid',
                            path: 'insurance.policy_end',
                        })
                    }

                    if (policy_effective && moment(policy_effective).isAfter(date)) {
                        return this.createError({
                            message: 'Effective end date must be after effective start date',
                            path: 'insurance.policy_end',
                        })
                    }
                    return true
                },
            }),
            patient_relationship: yup.string().test({
                name: 'patient_relationship',
                test(value, context) {
                    if (context.from[1].value.payment_method.code === 'Insurance' && !value) {
                        return this.createError({
                            message: "Select patient's relationship",
                            path: 'insurance.patient_relationship',
                        })
                    }
                    return true
                },
            }),
            // patient_relationship: yup.string().required("Select patient's relationship"),
            relation_info: yup.object({
                dob: yup.string().test({
                    name: 'relation_info_dob',
                    test(value, context) {
                        const { dob } = context.from[1].value.relation_info

                        if (!dob || context.from[1].value.patient_relationship === 'Self')
                            return true

                        const date = moment(dob)
                        if (!date.isValid()) {
                            return this.createError({
                                message: 'Date invalid',
                                path: 'insurance.relation_info.dob',
                            })
                        }

                        if (moment().isBefore(date)) {
                            return this.createError({
                                message: 'Date of birth must be before current date',
                                path: 'insurance.relation_info.dob',
                            })
                        }
                        return true
                    },
                }),
                ssn: yup.string().test({
                    name: 'relation_info_ssn',
                    test(value, context) {
                        const { ssn } = context.from[1].value.relation_info

                        if (!ssn || context.from[1].value.patient_relationship === 'Self')
                            return true

                        if (!/^(\d{3})-?(\d{2})-?(\d{4})$/.test(ssn)) {
                            return this.createError({
                                message: 'SSN invalid',
                                path: 'insurance.relation_info.ssn',
                            })
                        }
                        return true
                    },
                }),
            }),
            deductible: yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, 'Invalid number'),
            deductible_met: yup.string().matches(/^[0-9]+(\.[0-9]+)?$/, 'Invalid number'),
            // relation_info: yup
            //     .object({
            //         first_name: yup.string(),
            //         last_name: yup.string(),
            //         address_line_1: yup.string(),
            //         city: yup.string(),
            //         state: yup.string(),
            //         zip: yup.string(),
            //     })
            //     .when('patient_relationship', {
            //         is: (patient_relationship) => {
            //             return ['Child', 'Spouse', 'Other'].includes(patient_relationship)
            //         },
            //         then: yup.object({
            //             first_name: yup.string().required('Enter First Name'),
            //             last_name: yup.string().required('Enter Last Name'),
            //             address_line_1: yup.string().required('Enter address line 1'),
            //             city: yup.string().required('Enter city'),
            //             state: yup.string().required('Enter state'),
            //             zip: yup.string().required('Enter zip code'),
            //         }),
            //     }),
        }),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    const isSelfInsured =
        formik.values.insurance.patient_relationship &&
        formik.values.insurance.patient_relationship !== 'Self'

    const isSelfPay = formik.values.payment_method?.code === 'Self Pay'

    useEffect(() => {
        if (!providerInput) {
            formik.setFieldValue('practitioner_id', '')
        }
    }, [providerInput])
    const handleNotesBlur = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (insuranceId === 0 || !insuranceId) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.insurance?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note',
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.insurance?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note',
                        },
                    ]
                } else allNotesFinal = formik.values?.insurance?.notes
            }
            formik.setFieldValue('insurance.notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )
    const handleNotesEditChange = (value) => {
        formik.setFieldValue('insurance.notes', value)
        formik.setFieldValue('note', '')
    }
    const resetRelationshipObj = () => {
        formik.setValues({
            ...formik.values,
            insurance: {
                ...formik.values.insurance,
                patient_relationship: 'Self',
                relation_info: {
                    first_name: '',
                    last_name: '',
                    insured_number: '',
                    ssn: '',
                    dob: '',
                    address_line_1: '',
                    address_line_2: '',
                    city: '',
                    state: '',
                    zip: '',
                },
            },
        })
    }
    const openAlert = (e) => {
        setAnchorAlert(e.currentTarget)
    }
    return (
        <Grid container className={`${classes.container} insurance-form`}>
            <Grid item xs={12}>
                <Grid
                    container
                    // onClick={() =>
                    //     history.push(Routes.PATIENT.INSURANCES.MAIN.replace(':id', patientId))
                    // }
                    onClick={() => handleCloseForm()}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>
                        Back to Insurance Policy List
                    </Typography>
                </Grid>
                <Grid container item>
                    <Grid container alignContent="center" className="border-bottom">
                        <h4 className="page-title mb-1 page-RecordCall">
                            {insuranceId ? 'Update Insurance Policy' : 'Add Insurance Policy'}{' '}
                            <span
                                style={{
                                    marginLeft: 12,
                                    display: 'inline-flex',
                                    alignItems: 'flex-end',
                                }}></span>
                        </h4>
                    </Grid>
                    {dataLoading && (
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1,
                            }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <InsuranceForm
                        classes={classes}
                        formik={formik}
                        paymentMethodOptions={paymentMethodOptions}
                        insuranceType={insuranceType}
                        planOptions={planOptions}
                        isSelfPay={isSelfPay}
                        patientsRelationship={patientsRelationship}
                        resetRelationshipObj={resetRelationshipObj}
                        isSelfInsured={isSelfInsured}
                        stateList={stateList}
                        handleNotesBlur={handleNotesBlur}
                        openAlert={openAlert}
                        anchorAlert={anchorAlert}
                        setAnchorAlert={setAnchorAlert}
                        handleCloseForm={handleCloseForm}
                        isSubmitting={isSubmitting}
                        handleNotesEditChange={handleNotesEditChange}
                    />
                    {/* <Grid container item spacing={3} className="modal-spacer mt-3">
                       
                        <fieldset className="divider-information" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Insurance Information
                                </Typography>
                            </legend>
                            <Grid container item spacing={2}>
                                <Grid container item xs={12}>
                                    <Grid item xs={6}>
                                        <RadioGroup
                                            label="Payment Method"
                                            items={paymentMethodOptions}
                                            value={formik.values.payment_method?.code}
                                            onChange={(e) => {
                                                const item = paymentMethodOptions.find(
                                                    (option) => option.value === e.target.value,
                                                )
                                                formik.setFieldValue('payment_method', {
                                                    code: item?.value,
                                                    display: item?.label,
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{ marginTop: '8px' }}>
                                        <span>Select:</span>
                                        <Box sx={{ mt: 1 }}>
                                            {!insuranceType?.isPrimary && (
                                                // (formik?.values?.status === 'inactive' || formik?.values?.status === '' ) ) &&
                                                <Button
                                                    style={{
                                                        backgroundColor: '#EBFFF3',
                                                        borderRadius: '5px',
                                                        color: '#14AE5C',
                                                        border: `1px solid ${
                                                            formik.values?.type === 'Primary'
                                                                ? '#14AE5C'
                                                                : '#E8FDF0'
                                                        }`,
                                                        marginRight: '5px',
                                                        padding: '4px 12px',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                    }}
                                                    onClick={() =>
                                                        formik.setFieldValue('type', 'Primary')
                                                    }>
                                                    Primary Insurance
                                                </Button>
                                            )}

                                            {!insuranceType?.isSecondary && (
                                                <Button
                                                    style={{
                                                        backgroundColor: '#E1E9FF',
                                                        borderRadius: '5px',
                                                        color: '#5571C6',
                                                        border: `1px solid ${
                                                            formik.values?.type === 'Secondary'
                                                                ? '#5571C6'
                                                                : '#DCE5FF'
                                                        }`,
                                                        marginRight: '5px',
                                                        padding: '4px 12px',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                    }}
                                                    onClick={() =>
                                                        formik.setFieldValue('type', 'Secondary')
                                                    }>
                                                    Secondary Insurance
                                                </Button>
                                            )}
                                            {!insuranceType?.isTertiary && (
                                                <Button
                                                    style={{
                                                        backgroundColor: '#FFF4DB',
                                                        borderRadius: '5px',
                                                        color: '#FFAC0C',
                                                        border: `1px solid ${
                                                            formik.values?.type === 'Tertiary'
                                                                ? '#FFAC0C'
                                                                : '#FCF0D6'
                                                        }`,
                                                        padding: '4px 12px',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 400,
                                                    }}
                                                    onClick={() =>
                                                        formik.setFieldValue('type', 'Tertiary')
                                                    }>
                                                    Tertiary Insurance
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ display: 'inline-flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            fontSize: '15px',
                                            fontWeight: '500',
                                            color: '#516983',
                                            marginRight: '8px',
                                        }}>
                                        Active Policy
                                    </span>
                                    <ToggleSwitch
                                        onChange={(event) => {
                                            const isChecked = event?.target?.checked
                                            formik.setFieldValue(
                                                'status',
                                                isChecked ? 'active' : 'inactive',
                                            )
                                        }}
                                        checked={
                                            formik.values.status === 'inactive' ||
                                            formik.values.status === ''
                                                ? false
                                                : true
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        label="Health Plan"
                                        required
                                        placeholder="Select Health Plan"
                                        options={planOptions}
                                        value={formik.values?.insurance?.plan?.code}
                                        error={
                                            formik.touched?.insurance?.plan?.code &&
                                            Boolean(formik.errors?.insurance?.plan?.code)
                                        }
                                        helperText={
                                            formik.touched?.insurance?.plan?.code &&
                                            formik.errors?.insurance?.plan?.code
                                        }
                                        onChange={(value) => {
                                            const item = planOptions.find(
                                                (item) => item.key === value,
                                            )
                                            formik.setFieldValue('insurance.plan', {
                                                code: item?.key,
                                                display: item?.value,
                                            })
                                            formik.setFieldValue(
                                                'insurance.phone',
                                                formatPhone(item.phone_number),
                                            )
                                        }}
                                        disabled={isSelfPay}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Phone Number"
                                        placeholder="Enter phone number"
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        value={formik.values.insurance.phone}
                                        // onChange={(e) =>
                                        //     formik.setFieldValue(
                                        //         'insurance.phone',
                                        //         e?.replace(/\D/g, '').trim(),
                                        //     )
                                        // }
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Policy Number"
                                        required
                                        inputProps={{
                                            maxLength: 32,
                                        }}
                                        placeholder="Enter policy number"
                                        value={formik.values.insurance.policy_no}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.policy_no', e)
                                        }
                                        error={
                                            formik.touched?.insurance?.policy_no &&
                                            Boolean(formik.errors?.insurance?.policy_no)
                                        }
                                        helperText={
                                            formik.touched?.insurance?.policy_no &&
                                            formik.errors?.insurance?.policy_no
                                        }
                                        disabled={isSelfPay}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Group Number"
                                        placeholder="Enter group number"
                                        inputProps={{
                                            maxLength: 32,
                                        }}
                                        value={formik.values.insurance.group_number}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.group_number',
                                                e,
                                                // e.replace(/[^0-9]/g, ''),
                                            )
                                        }
                                        error={
                                            formik.touched?.insurance?.group_number &&
                                            Boolean(formik.errors?.insurance?.group_number)
                                        }
                                        helperText={
                                            formik.touched?.insurance?.group_number &&
                                            formik.errors?.insurance?.group_number
                                        }
                                        disabled={isSelfPay}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Group Name"
                                        placeholder="Enter group name"
                                        inputProps={{
                                            maxLength: 32,
                                        }}
                                        value={formik.values.insurance.group_name}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.group_name', e)
                                        }
                                        disabled={isSelfPay}
                                    />
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}>
                                    <ConcurrencyInput
                                        label="Copay"
                                        value={formik.values.insurance.copay_amount}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.copay_amount', e)
                                        }
                                        disabled={isSelfPay}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <ConcurrencyInput
                                        label="Deductible"
                                        value={formik.values.insurance.deductible_amount}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.deductible_amount', e)
                                        }
                                        disabled={isSelfPay}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DatePicker
                                        label="Effective Start Date"
                                        value={formik.values.insurance.policy_effective}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'insurance.policy_effective',
                                                e ? moment(e) : '',
                                            )
                                        }}
                                        notDefaultValue
                                        error={
                                            formik.touched.insurance?.policy_effective &&
                                            Boolean(formik.errors.insurance?.policy_effective)
                                        }
                                        helperText={
                                            formik.touched.insurance?.policy_effective &&
                                            formik.errors.insurance?.policy_effective
                                        }
                                        required
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DatePicker
                                        label="Effective End Date"
                                        minDate={formik.values.insurance.policy_effective}
                                        value={formik.values.insurance.policy_end}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'insurance.policy_end',
                                                e ? moment(e) : '',
                                            )
                                        }}
                                        notDefaultValue
                                        error={
                                            formik.touched.insurance?.policy_end &&
                                            Boolean(formik.errors.insurance?.policy_end)
                                        }
                                        helperText={
                                            formik.touched.insurance?.policy_end &&
                                            formik.errors.insurance?.policy_end
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                        <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Responsible Party
                                </Typography>
                            </legend>
                            <Grid container item spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        label="Patient Relationship To Insured"
                                        required
                                        placeholder="Select patient's relationship"
                                        options={patientsRelationship}
                                        value={formik.values.insurance.patient_relationship}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'insurance.patient_relationship',
                                                e,
                                            )
                                            if (e === 'Self') {
                                                resetRelationshipObj()
                                            }
                                        }}
                                        error={Boolean(
                                            formik.touched?.insurance?.patient_relationship &&
                                                formik.errors.insurance?.patient_relationship,
                                        )}
                                        helperText={
                                            formik.touched?.insurance?.patient_relationship &&
                                            formik.errors.insurance?.patient_relationship
                                        }
                                        style={{ width: '450px' }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="First Name"
                                        value={formik.values.insurance.relation_info.first_name}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.relation_info.first_name',
                                                e,
                                            )
                                        }
                                        error={Boolean(
                                            formik.errors.insurance?.relation_info?.first_name,
                                        )}
                                        helperText={
                                            formik.errors.insurance?.relation_info?.first_name
                                        }
                                        placeholder="Enter First Name"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="Last Name"
                                        value={formik.values.insurance.relation_info.last_name}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.relation_info.last_name',
                                                e,
                                            )
                                        }
                                        error={Boolean(
                                            formik.errors.insurance?.relation_info?.last_name,
                                        )}
                                        helperText={
                                            formik.errors.insurance?.relation_info?.last_name
                                        }
                                        placeholder="Enter Last Name"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="Insured ID"
                                        value={formik.values.insurance.relation_info.insured_number}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.relation_info.insured_number',
                                                e,
                                            )
                                        }
                                        // error={Boolean(
                                        //     formik.errors.insurance?.relation_info
                                        //         ?.insured_name,
                                        // )}
                                        // helperText={
                                        //     formik.errors.insurance?.relation_info
                                        //         ?.insured_name
                                        // }
                                        placeholder="Enter Insured ID"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="SSN"
                                        value={formik.values.insurance.relation_info.ssn}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'insurance.relation_info.ssn',
                                                e.replace(
                                                    /^(\d{3})-?(\d{2})-?(\d{4})$/,
                                                    '$1-$2-$3',
                                                ),
                                            )
                                        }}
                                        // error={Boolean(
                                        //     formik.errors.insurance?.relation_info
                                        //         ?.ssn,
                                        // )}
                                        // helperText={
                                        //     formik.errors.insurance?.relation_info
                                        //         ?.ssn
                                        // }
                                        placeholder="Enter SSN"
                                        error={Boolean(formik.errors.insurance?.relation_info?.ssn)}
                                        helperText={formik.errors.insurance?.relation_info?.ssn}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <DatePicker
                                        disabled={!isSelfInsured}
                                        label="Date of Birth"
                                        // minDate={formik.values.insurance.policy_effective}
                                        // value={formik.values.insurance.policy_end}
                                        notDefaultValue
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'insurance.relation_info.dob',
                                                e ? moment(e) : '',
                                            )
                                        }}
                                        value={formik.values.insurance.relation_info.dob}
                                        maxDate={moment()}
                                        error={
                                            formik.touched.insurance?.relation_info?.dob &&
                                            Boolean(formik.errors.insurance?.relation_info?.dob)
                                        }
                                        helperText={
                                            formik.touched.insurance?.relation_info?.dob &&
                                            formik.errors.insurance?.relation_info?.dob
                                        }
                                    />
                                </Grid>
                                {/* <Grid item xs={12}>
                                    <Divider />
                                    <fieldset
                                        className="divider"
                                        style={{ maxHeight: '10px', overflowY: 'scroll', width: '99%' }}>
                                        <legend>
                                            <Typography className={'heading1-title'}>Address</Typography>
                                        </legend>
                                    </fieldset>
                                </Grid> */}
                    {/* <Grid item xs={12}>
                                    <fieldset
                                        className="divider"
                                        style={{
                                            maxHeight: '10px',
                                            overflowY: 'scroll',
                                            width: '99%',
                                            marginLeft: '1px',
                                        }}>
                                        <legend>
                                            <Typography className={'heading1-title'}>
                                                Address
                                            </Typography>
                                        </legend>
                                    </fieldset>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="Address Line 1"
                                        value={formik.values.insurance.relation_info.address_line_1}
                                        error={Boolean(
                                            formik.errors.insurance?.relation_info?.address_line_1,
                                        )}
                                        helperText={
                                            formik.errors.insurance?.relation_info?.address_line_1
                                        }
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.relation_info.address_line_1',
                                                e,
                                            )
                                        }
                                        placeholder="Enter Address line 1"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="Address Line 2"
                                        value={formik.values.insurance.relation_info.address_line_2}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'insurance.relation_info.address_line_2',
                                                e,
                                            )
                                        }
                                        placeholder="Enter Address line 2"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={!isSelfInsured}
                                        label="City"
                                        value={formik.values.insurance.relation_info.city}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.relation_info.city', e)
                                        }
                                        error={Boolean(
                                            formik.errors.insurance?.relation_info?.city,
                                        )}
                                        helperText={formik.errors.insurance?.relation_info?.city}
                                        placeholder="Enter City"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        disabled={!isSelfInsured}
                                        label="State"
                                        placeholder="Select state"
                                        value={formik.values.insurance.relation_info.state}
                                        options={stateList.map((item) => ({
                                            key: item.state,
                                            value: item.stateLabel,
                                        }))}
                                        error={Boolean(
                                            formik.errors.insurance?.relation_info?.state,
                                        )}
                                        helperText={formik.errors.insurance?.relation_info?.state}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.relation_info.state', e)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <ZipCode
                                        disabled={!isSelfInsured}
                                        label="Zip Code"
                                        format={9}
                                        value={formik.values.insurance.relation_info.zip}
                                        error={Boolean(formik.errors.insurance?.relation_info?.zip)}
                                        helperText={formik.errors.insurance?.relation_info?.zip}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.relation_info.zip', e)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset> */}
                    {/* <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>Co-Insurance</Typography>
                            </legend>
                            <Grid container item spacing={2}>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={isSelfPay}
                                        label="Deductible"
                                        value={formik.values.insurance.deductible}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.deductible', e)
                                        }
                                        error={Boolean(formik.errors.insurance?.deductible)}
                                        helperText={formik.errors.insurance?.deductible}
                                        placeholder="Enter deductible"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={isSelfPay}
                                        label="Deductible Met"
                                        value={formik.values.insurance.deductible_met}
                                        onChange={(e) =>
                                            formik.setFieldValue('insurance.deductible_met', e)
                                        }
                                        error={Boolean(formik.errors.insurance?.deductible_met)}
                                        helperText={formik.errors.insurance?.deductible_met}
                                        placeholder="Enter amount met"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={true}
                                        label="Deductible Remaining"
                                        value={
                                            formik.values.insurance.deductible &&
                                            formik.values.insurance.deductible_met
                                                ? (
                                                      formik.values.insurance.deductible -
                                                      formik.values.insurance.deductible_met
                                                  ).toFixed(2)
                                                : ''
                                        }
                                        placeholder=" "
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                        <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>Staff Notes</Typography>
                            </legend>
                            <Grid item spacing={2} container>
                                <Grid item xs={12}>
                                    <NoteField
                                        note={formik?.values?.note}
                                        noteView={formik?.values?.noteView}
                                        notes={formik?.values?.insurance?.notes}
                                        onChange={formik?.setFieldValue}
                                        // onBlur={handleNotesBlur}
                                        // handleNotesEditChange={handleNotesEditChange}
                                        handleNotesChange={handleNotesBlur}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid> */}

                    {/* <Grid
                        container
                        item
                        style={{ marginTop: '16px' }}
                        // justifyContent="flex-end"
                        direction="row"
                        spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={(e) => openAlert(e)}>
                                Cancel
                            </Button>
                            <CustomAlertPopOver
                                anchorEl={anchorAlert}
                                handleClose={() => setAnchorAlert(null)}
                                title="Save Changes?"
                                bodyText={`Are you sure you want to cancel the insurance form?`}
                                confirmButtonName={'Yes'}
                                cancelButtonName={'No'}
                                confirmButtonColor={'#5571c6'}
                                type={'other'}
                                onSuccess={() => handleCloseForm()}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                variant="contained"
                                className={classes.button_create}
                                onClick={(e) => {
                                    formik.handleSubmit(e)
                                }}>
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InsuranceNewForm
