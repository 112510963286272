import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(() => ({
    wrapDay: {
        height: '700px',
        '& .close-viewday': {
            color: '#606060',
            cursor: 'pointer',
            position: 'absolute',
            top: '3px',
            right: '3px',
        },
        '& .rbc-toolbar': {
            color: '#333',
        },
        '& .rbc-btn-group': {
            display: 'none',
        },
        '& .rbc-label': {
            fontSize: '14px',
            color: '#333',
        },
        '& .rbc-day-slot .rbc-event-content': {
            display: 'flex',
        },
        '& .rbc-time-header': {
            display: 'none',
        },
    },
}))
