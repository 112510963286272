import React from 'react'
import { CircularProgress, IconButton as MuiIconButton, Typography } from '@material-ui/core'

interface Props {
  loading?: React.ReactNode
  icon?: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: (params?: any[]) => void
  children?: React.ReactNode
}

export const IconButton = (props: Props) => (
  <MuiIconButton
    disabled={props.disabled}
    onClick={() => props.onClick && props.onClick()}
    style={{
      color: '#667689',
      padding:
        '0px 10px 0px 10px',
      margin: '0 5px'
    }}
    className={props.className}>

    {props.loading && <CircularProgress color="inherit" size={15} style={{ marginRight: 5 }} />}

    {props.icon}

    <Typography
      style={{
        fontFamily: "'Rubik'",
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '13px',
        lineHeight: '140%',
        color: '#667689',
        marginLeft: '3px',
      }}
    >
      {props.children}
    </Typography>
  </MuiIconButton>
)
