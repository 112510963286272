import React, { useEffect } from 'react';
import {
    Box,
    Grid,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';

// import MainLayout from "../../MainLayout";

import './MainProfile.scss';

//tab Panel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

//a11y
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const MainProfile = ({ tabsList, selectedTab }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (selectedTab) {
            setValue(selectedTab.tabIndex);
        }
    }, [selectedTab]);

    return (
        <>
            {/* tabs section */}
            <Grid container className="profile-main-wrap">
                <Grid item xs={12}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            textColor="primary"
                            indicatorColor="primary"
                            className="tabs-main"
                        >
                            {tabsList.map(
                                ({ label }, i) => (
                                    <Tab
                                        key={label}
                                        label={label}
                                        {...a11yProps(i)}
                                        className="capitalize"
                                    />
                                ),
                            )}
                        </Tabs>

                        {tabsList.map(
                            ({ components }, i) => (
                                <TabPanel
                                    key={i}
                                    value={value}
                                    index={i}
                                >
                                    {components}
                                </TabPanel>
                            ),
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default MainProfile;
