import React, { useState } from 'react'
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import { AlertDeleteDialog } from 'components/shared/Elements/AlertDeleteDialog'


type ActionType = 'Edit' | 'Delete' | string

type MenuItems = {
  text: ActionType
  handler: () => void | Promise<void>
}[]

interface Props {
  menu: MenuItems
  deleteMessage?: string
  disabled: boolean
}

export const EditAction = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () =>
    setAnchorEl(null)

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <IconButton
          size="small"
          onClick={handleClick}
          style={{
            background: '#FFFFFF',
            border: '1px solid #E9ECF1',
            borderRadius: '20px',
          }}
          disabled={props.disabled}
          >
          <MoreHorizIcon
            style={{
              color: '#3E465B',
              fontSize: 18,
            }}
          />
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: 120,
              marginTop: 8,
            },
          }}
          getContentAnchorEl={null}>
          {props.menu.map((menu, index) => (
            <MenuItem
              id={`auto-header-${menu.text}`}
              key={index}
              onClick={() => {
                handleClose()

                if (menu.text !== 'Delete')
                  menu.handler()
                else
                  setAlertDeleteOpen(true)
              }}
              style={{
                color:
                  menu.text === 'Delete'
                    ? '#E81212'
                    : '#667689',
              }}>
              {menu.text}
            </MenuItem>
          ))}
        </Menu>

        <AlertDeleteDialog
          title="Confirm Data Deletion"
          description={props.deleteMessage ||
            "Are you sure you want to delete this record?"}
          loading={loading}
          open={alertDeleteOpen}
          handleClose={() =>
            setAlertDeleteOpen(false)
          }
          handleDelete={async () => {
            try {
              const deleteAction = Object.values(props.menu)
                .find(item => item.text === 'Delete')

              if (!deleteAction || !deleteAction.handler)
                throw new Error(
                  'No implementation of delete handler() was provided!'
                )

              setLoading(true)
              await deleteAction.handler()

              setLoading(false)
              setAlertDeleteOpen(false)
            } catch (ex) {
              console.error(ex)
            }
          }}
        />
      </Grid>
    </Grid>
  )
}
