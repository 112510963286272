export const errorMessages = {
    parentName: 'Please enter parent name.',
    parentMaritalStatus: `Please select parent's marital status`,
    firstName: 'Please enter valid First Name',
    lastName: 'Please enter valid Last Name',
    dob: 'Please select Date of Birth',
    gender: 'Please choose Gender.',
    phoneNumber: 'Please enter Cellphone Number',
    validPhoneNumber:
        'Please enter valid Cellphone Number.',
    emailId: 'Please enter email address.',
    validEmailId: 'Please enter valid Email Address',
    docType: 'Please select document type.',
    uploadedDoc: 'Please upload selected document.',
    speciality: 'Please select speciality.',
    reason: 'Please provide reason.',
    otherDetails: 'Please provide other details.',
    preferredLocation: 'Please choose preferred location.',
    defaultChooseOneOption:
        'Please choose at least 1 option.',
    address_line_1: 'Please enter Address Line 1',
    city: 'Please enter City',
    state: 'Please select State',
    zip: 'Please enter Zip Code',
    timezone: 'Please select Timezone',
    employmentType: 'Please enter Employment Type',
    taxonomyCode: 'Please enter Taxonamy Code',
    grouptaxid: 'Please enter Group Tax id',
    groupnpinumber: 'Please enter Group NPI Number',
    npinumber: 'Please enter NPA Number',
    mpnnumber: 'Please enter MPN Number',
    bio: 'Please enter Bio',
    staff_admin_name: 'Please enter Staff Admin Name',
    admin_role: 'Please select Admin Role',
    relation: 'Please enter relation',
    securityAnwer: 'Please enter answer',
    planName: 'Please select Plan Name',
    groupNumber: 'Please enter Group Number',
    payer: 'Please enter Payer Name',
    payerId: 'Please enter Payer Id',
    memberId: 'Please enter Member Id',
    clinic_name: 'Please enter Clinic Name',
    validZip: 'Please enter valid zipcode',
    address_line_2: 'Please enter address line 2',
};
