import * as actionTypes from '../actionType'

export const changeAppointment = (value) => {
    return {
        type: actionTypes.SCHEDULE_CHANGE_APPOINTMENT,
        value,
    }
}

export const changeSearchValues = (value) => {
    return {
        type: actionTypes.SCHEDULE_CHANGE_SEARCH_VALUES,
        value,
    }
}

export const changeSearchResult = (value) => {
    return {
        type: actionTypes.SCHEDULE_CHANGE_SEARCH_RESULT,
        value,
    }
}

export const resetState = () => {
    return {
        type: actionTypes.SCHEDULE_RESET_STATE,
    }
}

export const setReschedulePopup = (appointment_id, value) => {
    return {
        type: actionTypes.IS_RESCHEDULE_POPUP,
        appointment_id,
        value
    }
}

export const setLocationAccoringToProvider = (value) => {
    return {
        type: actionTypes.PROVIDER_LOCATION,
        value
    }
}
