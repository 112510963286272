export const SCREEN = {
    PATIENT_DETAILS: 'PATIENT_DETAILS',
    ADD_PERSON: 'ADD_PERSON',
    INSURANCE: 'INSURANCE',
    BEHAVIRIOAL_QUESTIONS: 'BEHAVIRIOAL_QUESTIONS',
    ADD_PATIENT: 'ADD_PATIENT',
}

export const DOCUMENT_TYPE = [
    { key: 'Inbound Referral', value: 'Inbound Referral' },
    {
        key: 'Outbound Referral',
        value: 'Outbound Referral',
    },
    { key: 'Medical Record', value: 'Medical Record' },
    {
        key: 'Medical Records Request',
        value: 'Medical Records Request',
    },
    { key: 'Imaging', value: 'Imaging' },
    { key: 'Lab Result', value: 'Lab Result' },
    { key: 'RX Pre-Auth', value: 'RX Pre-Auth' },
    { key: 'Insurance Card', value: 'Insurance Card' },
    { key: 'Eligibility', value: 'Eligibility' },
    { key: 'Clinical Note', value: 'Clinical Note' },
    {
        key: 'Education / Information Material',
        value: 'Education / Information Material',
    },
    { key: 'Amendment', value: 'Amendment' },
    {
        key: 'Ketamine / SPRAVATO Monitoring',
        value: 'Ketamine / SPRAVATO Monitoring',
    },
    { key: 'Presciption', value: 'Presciption' },
    { key: 'Disablility/FMLA', value: 'Disablility/FMLA' },
    { key: 'Cambridge Result', value: 'Cambridge Result' },
    { key: 'Prior Auth', value: 'Prior Auth' },
    { key: 'RCM Report', value: 'RCM Report' },
    {
        key: 'Accommodation Letter',
        value: 'Accommodation Letter',
    },
    { key: 'Other', value: 'Other' },
].sort((type1, type2) => {
    let a = type1.key
    let b = type2.key
    return a === b ? 0 : a > b ? 1 : -1
})
