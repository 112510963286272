import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridColumns } from '@mui/x-data-grid'

import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import Routes from 'constants/routes'
import PatientInfor from '../PatientInfor'
import { Button as ButtonCustom } from 'components/custom'
import { SearchField } from 'shared/elements/FormItem/SearchComponents/SearchField'
import { Select } from 'shared/elements/FormItem/Select'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { DataGrid } from 'shared/elements/DataGrid'

import { useStyles } from '../MedicalProblems/style'
import { DataGridCell, downloadDocument, getPatientName,previewDocument } from 'containers/PatientsSetting/shared'
import { formatDateTime } from 'utilities/formats'
import { deleteDocumentById, getDocumentList } from 'services/Documents'
import { useNotification } from 'shared/elements/Notification'

import { DOCUMENT_TYPE } from 'containers/Documents/CreateDocumentDialog/constant'
import { Document, ErrorType } from '../../interfaces'
import { DocumentForm } from './DocumentForm'

const Documents = () => {
    const classes = useStyles()

    const history = useHistory()
    const notification = useNotification()
    const { id: patientId } = useParams<{ id: string }>()

    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const [searchCriteria, setSearchCriteria] = useState({
        search: '',
        document_type: '',
        encounter_id: '',
        upload_by: '',
        source_type: '',
    })

    const [formOpen, setFormOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [documents, setDocuments] = useState<Document[]>([])

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const handlePageChange = (page: number) => setPage(page)

    const getDocuments = async () => {
        setLoading(true)

        try {
            const resp = await getDocumentList({
                page,
                limit,
                associated_patient: patientId,
                ...searchCriteria,
            })

            setDocuments(resp.data.data as Document[])
            setTotal(resp.data.paging.total)
        } catch (error) {
            notification((error as ErrorType).data?.message || 'Cannot get list document', 'error')
        } finally {
            setLoading(false)
        }
    }

    const deletePaticularDocument = (documentId: number | undefined) => {
        deleteDocumentById(documentId)?.then(res => {
            notification('Document deleted successfully', 'success')
            getDocuments()
        })?.catch(err => {
            notification(err?.message || 'Something went wrong!', 'error')
        })
    } 

    useEffect(() => {
        const abortController = new AbortController()
        getDocuments()

        return () => abortController.abort()
    }, [page, searchCriteria])

    const columns: GridColumns<Document> = [
        {
            headerName: 'Document Name',
            headerClassName: 'super-app-theme--header',
            field: 'document_name',
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.document_name,
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell
                    value={
                        <Typography style={{ color: '#0076FC',cursor:'pointer' }} onClick={()=>previewDocument(row.id, row.document_name, notification)}>
                            {row.document_name || 'N/A'}
                        </Typography>
                    }
                />
            ),
        },
        {
            headerName: 'Document Type',
            headerClassName: 'super-app-theme--header',
            field: 'document_type',
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.document_type || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => <DataGridCell value={row.document_type} />,
        },
        {
            headerName: 'Source Type',
            headerClassName: 'super-app-theme--header',
            field: 'source_type',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.source_type || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => <DataGridCell value={row.source_type} />,
        },
        {
            headerName: 'Associated Patient',
            headerClassName: 'super-app-theme--header',
            field: 'associated_patient_first_name',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.associated_patient_first_name || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell
                    value={getPatientName({
                        first_name: row.associated_patient_first_name,
                        last_name: row.associated_patient_last_name,
                    })}
                />
            ),
        },
        {
            headerName: 'Encounter',
            headerClassName: 'super-app-theme--header',
            field: 'encounter',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.encounter || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell
                    value={
                        row.encounter?.start ? (
                            <>
                                <Typography>
                                    {formatDateTime(row.encounter?.start, 'MMM DD YYYY hh:mm A')} -{' '}
                                </Typography>
                                <Typography>
                                    {formatDateTime(row.encounter?.end, 'MMM DD YYYY hh:mm A')}
                                </Typography>
                            </>
                        ) : (
                            'N/A'
                        )
                    }
                />
            ),
        },
        {
            headerName: 'Description',
            headerClassName: 'super-app-theme--header',
            field: 'description',
            // width: 100,
            flex: 1.5,
            sortable: true,
            renderCell: ({ value }) => <DataGridCell value={value} />,
        },
        {
            headerName: 'Uploaded By',
            headerClassName: 'super-app-theme--header',
            field: 'upload_by',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.upload_by || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell
                    value={getPatientName({
                        first_name: row.upload_by_first_name,
                        last_name: row.upload_by_last_name,
                    })}
                />
            ),
        },
        {
            headerName: 'Uploaded Date',
            headerClassName: 'super-app-theme--header',
            field: 'upload_date',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.upload_date || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell value={formatDateTime(row.upload_date, 'MMM DD YYYY hh:mm A')} />
            ),
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'Download',
                            handler: () =>
                                downloadDocument(row.id, row.document_name, notification),
                        },
                        {
                            text: 'Delete',
                            handler: () =>{
                                deletePaticularDocument(row?.id)
                        }},
                    ]}
                />
            ),
        },
    ]

    return (
        <>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    // onClick={() =>
                    //     history.replace(
                    //         Routes.PATIENT.DASHBOARD_PATIENT_ACCOUNT.replace(':id', patientId),
                    //     )
                    // }
                    onClick={() => history.goBack()}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>

                <Grid container style={{ marginTop: 12 }}>
                    <Grid item xs={12} container alignItems="center">
                        <Grid container justifyContent="space-between">
                            <Typography className={classes.header}>Patient Document</Typography>

                            <Grid item xs={8}>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    spacing={2}
                                    wrap={'nowrap'}>
                                    <Grid item xs={5} style={{ maxWidth: 300 }}>
                                        <SearchField
                                            onSearch={(text) =>
                                                setSearchCriteria({
                                                    ...searchCriteria,
                                                    search: text,
                                                })
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={4} style={{ maxWidth: 250 }}>
                                        <Select
                                            value={searchCriteria.document_type}
                                            options={[{ key: '', value: 'All' }, ...DOCUMENT_TYPE]} // FIXME:
                                            onChange={(value) =>
                                                setSearchCriteria({
                                                    ...searchCriteria,
                                                    document_type: value,
                                                })
                                            }
                                        />
                                    </Grid>

                                    <Grid item className="d-flex align-center">
                                        <ButtonCustom
                                            tooltip="Upload New Document"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setFormOpen(true)}
                                            fullWidth>
                                            <AddIcon
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </ButtonCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>

                    <DataGrid
                        page={page}
                        loading={loading}
                        columns={columns}
                        rows={documents}
                        pageSize={limit}
                        totalRecords={total}
                        onPageChange={handlePageChange}
                    />
                </Grid>
            </Grid>

            <DocumentForm
                open={formOpen}
                patientId={patientId}
                onCancel={(forceReload) => {
                    setFormOpen(false)

                    if (forceReload) setTimeout(() => getDocuments(), 800)
                }}
            />
        </>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Documents)
