import {
    Grid,
    Typography,
    Button,
    Box,
    FormControl,
    TextField,
    InputLabel,
    FormLabel,
    InputAdornment,
    IconButton,
} from '@material-ui/core'
import Swal from 'sweetalert2'
import queryString from 'querystring'
import { Auth } from 'aws-amplify'
import LoadingButton from '@mui/lab/LoadingButton'
import * as yup from 'yup'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tms2uLogo from '../../assests/icons/Tms2uLogo'
import shutterstock from '../../assests/images/shutterstock_13160805981.png'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'
import TickCircle from '../../assests/icons/TickCircle'
import { Formik, useFormik } from 'formik'
import IconPassword from '../../assests/images/iconPassword.png'
import IconDisablePassword from '../../assests/images/iconDisablePassword.png'
import './style.css'
import SpinnerIcon from 'assests/icons/SpinnerIcon'
import { PasswordValidator } from 'utilities/regex'

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${shutterstock})`,
        backgroundSize: 'cover',
    },
    title: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 32,
        lineHeight: '140%',
        marginTop: 5,
    },
    selected: {
        border: '1px solid #5571C6',
    },
    otpInput: {
        marginTop: 32,
    },
    InputLabel: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '140%',
    },
    passwordSuccess: {
      color: '#52b202'
    },
    passwordError: {
      color: 'red'
    }
}))

const AuthenticationSteps = {
    EMAIL: 'EMAIL',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    EMAIL_SENT: 'EMAIL_SENT',
    COMPLETED: 'COMPLETED',
}

const isValidEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
}

export default function ResetPasswordForm() {
    const classes = useStyles()
    const history = useHistory()

    const params = queryString.parse(
        history?.location?.search?.replace('?', ''),
    )

    const [showNewPass, setShowNewPass] = useState(false)
    const [showConfirmPass, setShowConfirmPass] =
        useState(false)
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(
        params.code && params.user_name
            ? AuthenticationSteps.CHANGE_PASSWORD
            : AuthenticationSteps.EMAIL,
    )

    const [passwordValidation, setPasswordValidation] = useState({
      isAtLeastEightCharactors: false,
      isAtLeastOneSpecialCharacter: false,
      isAtLeastOneLowerCaseLetter: false,
      isAtLeastOneDigit: false,
      isAtLeastOneUpperCaseLetter: false,
    })

    const formik = useFormik({
        initialValues: {
            new_password: '',
            confirm_password: '',
        },
        onSubmit: () => {
            if (!formik.values.new_password) {
                Swal.fire(
                    '',
                    'Password field is required',
                    'error',
                )
                return
            }

            if (!formik.values.confirm_password) {
                Swal.fire(
                    '',
                    'Confirm passwword field is required',
                    'error',
                )
                return
            }

            if (
                formik.values.new_password !==
                formik.values.confirm_password
            ) {
                Swal.fire(
                    '',
                    'Confirm passwword does not match',
                    'error',
                )
                return
            }

            Auth.forgotPasswordSubmit(
                params.user_name,
                params.code,
                formik.values.new_password,
            )
                .then(() => {
                    setCurrentStep(
                        AuthenticationSteps.COMPLETED,
                    )
                })
                .then(() => {
                    setCurrentStep(
                        AuthenticationSteps.COMPLETED,
                    )
                })
                .finally(() => {
                    setIsLoading(false)
                })
                .catch(() => {
                    Swal.fire(
                        '',
                        'Change password failed',
                        'error',
                    )
                })
        },
    })

    useEffect(() => {
      setPasswordValidation({
        isAtLeastEightCharactors: PasswordValidator.isAtLeastEightCharactors(formik.values.new_password),
        isAtLeastOneSpecialCharacter: PasswordValidator.isAtLeastOneSpecialCharacter(formik.values.new_password),
        isAtLeastOneLowerCaseLetter: PasswordValidator.isAtLeastOneLowerCaseLetter(formik.values.new_password),
        isAtLeastOneDigit: PasswordValidator.isAtLeastOneDigit(formik.values.new_password),
        isAtLeastOneUpperCaseLetter: PasswordValidator.isAtLeastOneUpperCaseLetter(formik.values.new_password)
      })
    }, [formik.values.new_password])

    const handleNextStep = () => {
        if (currentStep === AuthenticationSteps.EMAIL) {
            if (!email) {
                Swal.fire(
                    '',
                    'Email field is required',
                    'error',
                )
                return
            }

            if (!isValidEmail(email)) {
                Swal.fire(
                    '',
                    'Email field is invalid',
                    'error',
                )
                return
            }
            setIsLoading(true)
            Auth.forgotPassword(email)
                .then(() => {
                    setCurrentStep(
                        AuthenticationSteps.EMAIL_SENT,
                    )
                })
                .finally(() => {
                    setIsLoading(false)
                })

            return
        }

        if (
            currentStep ===
            AuthenticationSteps.CHANGE_PASSWORD
        ) {
            formik.submitForm()
        }
    }

    const renderCurrentStepText = () => {
        if (currentStep === AuthenticationSteps.EMAIL) {
            return 'Email'
        }

        if (
            currentStep ===
            AuthenticationSteps.CHANGE_PASSWORD
        ) {
            return 'Change Password'
        }

        return ''
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
            style={{ minHeight: '100vh' }}>
            <Box container direction="column">
                {currentStep !==
                    AuthenticationSteps.COMPLETED && (
                    <Box sx={{ width: '100%' }}>
                        <Button
                            onClick={() => {
                                history.push('/login/staff')
                            }}
                            startIcon={<ArrowBackIcon />}
                            size="small"
                            style={{
                                backgroundColor:
                                    'transparent',
                                marginBottom: 5,
                                padding: 0,
                                fontSize: 14,
                                fontWeight: 400,
                                fontFamily: 'Rubik',
                                textTransform: 'none',
                            }}>
                            Go to main page
                        </Button>
                    </Box>
                )}

                <Box
                    style={{
                        padding: 50,
                    }}
                    container
                    direction="row"
                    justifyContent="space-between"
                    sx={{
                        width: 700,
                        backgroundColor: '#FFFFFF',
                    }}>
                    <Tms2uLogo> </Tms2uLogo>
                    <Typography className={classes.title}>
                        {renderCurrentStepText()}
                    </Typography>
                    {currentStep ===
                        AuthenticationSteps.EMAIL && (
                        <Grid
                            direction="colum"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <InputLabel
                                style={{
                                    fontSize: '14px',
                                    lineHeight: '140%',
                                    color: '#303E4E',
                                }}>
                                Email
                            </InputLabel>
                            <TextField
                                // inputProps={{
                                //     inputMode: 'numeric',
                                //     pattern: '[0-9]*',
                                // }}
                                placeholder={
                                    'Enter email address'
                                }
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                type={'email'}
                            />
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.CHANGE_PASSWORD && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Grid
                                container
                                item
                                justifyContent="space-between"
                                direction="column">
                                <Grid
                                    container
                                    item
                                    spacing={3}
                                    className="modal-spacer"
                                    style={{
                                        marginTop: 24,
                                    }}>
                                    <Grid item xs={12}>
                                        <FormLabel
                                            className={
                                                classes.InputLabel
                                            }
                                            component="p">
                                            New Password *
                                        </FormLabel>
                                        <Box
                                            sx={{
                                                '& .MuiOutlinedInput-root':
                                                    {
                                                        height: 'auto',
                                                    },
                                            }}>
                                            <TextField
                                                id="new_password"
                                                name="new_password"
                                                variant="outlined"
                                                value={
                                                    formik
                                                        .values
                                                        .new_password
                                                }
                                                type={
                                                    showNewPass
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                fullWidth
                                                placeholder="Enter password"
                                                InputProps={{
                                                    maxLength: 255,
                                                    endAdornment:
                                                        (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setShowNewPass(
                                                                            !showNewPass,
                                                                        )
                                                                    }>
                                                                    {showNewPass ? (
                                                                        <img
                                                                            src={
                                                                                IconDisablePassword
                                                                            }
                                                                            width={
                                                                                16
                                                                            }
                                                                            height={
                                                                                16
                                                                            }
                                                                            loading="lazy"
                                                                            alt="App store"
                                                                            className="app-btn"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                IconPassword
                                                                            }
                                                                            width={
                                                                                16
                                                                            }
                                                                            height={
                                                                                16
                                                                            }
                                                                            loading="lazy"
                                                                            alt="App store"
                                                                            className="app-btn"
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onChange={(
                                                    e,
                                                ) =>
                                                    formik.setFieldValue(
                                                        'new_password',
                                                        e
                                                            .target
                                                            .value,
                                                    )
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormLabel
                                            className={
                                                classes.InputLabel
                                            }
                                            component="p">
                                            Confirm New
                                            Password *
                                        </FormLabel>
                                        <Box
                                            sx={{
                                                '& .MuiOutlinedInput-root':
                                                    {
                                                        height: 'auto',
                                                    },
                                            }}>
                                            <TextField
                                                id="confirm_password"
                                                name="confirm_password"
                                                variant="outlined"
                                                value={
                                                    formik
                                                        .values
                                                        .confirm_password
                                                }
                                                type={
                                                    showConfirmPass
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                fullWidth
                                                placeholder="Enter password"
                                                InputProps={{
                                                    maxLength: 255,
                                                    endAdornment:
                                                        (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() =>
                                                                        setShowConfirmPass(
                                                                            !showConfirmPass,
                                                                        )
                                                                    }>
                                                                    {showConfirmPass ? (
                                                                        <img
                                                                            src={
                                                                                IconDisablePassword
                                                                            }
                                                                            width={
                                                                                16
                                                                            }
                                                                            height={
                                                                                16
                                                                            }
                                                                            loading="lazy"
                                                                            alt="App store"
                                                                            className="app-btn"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                IconPassword
                                                                            }
                                                                            width={
                                                                                16
                                                                            }
                                                                            height={
                                                                                16
                                                                            }
                                                                            loading="lazy"
                                                                            alt="App store"
                                                                            className="app-btn"
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onChange={(
                                                    e,
                                                ) =>
                                                    formik.setFieldValue(
                                                        'confirm_password',
                                                        e
                                                            .target
                                                            .value,
                                                    )
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} style={{
                                      marginBottom: '20px',
                                      padding: '5px'
                                    }}>
                                      <Typography
                                        style={{
                                          color: '#303E4E',
                                          fontSize: 14,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Password must be matched the following requirements:
                                      </Typography>
                                      <Typography
                                        className={{
                                          [classes.passwordSuccess]: passwordValidation.isAtLeastEightCharactors,
                                          [classes.passwordError]: !passwordValidation.isAtLeastEightCharactors
                                        }}
                                      >
                                        - At least 8 letters
                                      </Typography>
                                      <Typography
                                         className={{
                                          [classes.passwordSuccess]: passwordValidation.isAtLeastOneUpperCaseLetter,
                                          [classes.passwordError]: !passwordValidation.isAtLeastOneUpperCaseLetter
                                          }}
                                      >
                                        - An uppercase letter (A-Z)
                                      </Typography>
                                      <Typography
                                         className={{
                                          [classes.passwordSuccess]: passwordValidation.isAtLeastOneLowerCaseLetter,
                                          [classes.passwordError]: !passwordValidation.isAtLeastOneLowerCaseLetter
                                          }}
                                      >
                                        - A lowercase letter (a-z)
                                      </Typography>
                                      <Typography
                                       className={{
                                        [classes.passwordSuccess]: passwordValidation.isAtLeastOneDigit,
                                        [classes.passwordError]: !passwordValidation.isAtLeastOneDigit
                                        }}
                                      >
                                        - A Number (0-9)
                                      </Typography>
                                      <Typography
                                       className={{
                                        [classes.passwordSuccess]: passwordValidation.isAtLeastOneSpecialCharacter,
                                        [classes.passwordError]: !passwordValidation.isAtLeastOneSpecialCharacter
                                        }}
                                      >
                                        - A special character
                                      </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.COMPLETED && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}>
                                <TickCircle />
                            </Box>
                            <Typography
                                style={{
                                    marginTop: 24,
                                    fontWeight: 500,
                                    fontSize: '32px',
                                    color: ' #303E4E',
                                    textAlign: 'center',
                                }}>
                                Change Password Successful
                            </Typography>
                        </Grid>
                    )}

                    {currentStep ===
                        AuthenticationSteps.EMAIL_SENT && (
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                            style={{
                                height: '320px',
                                width: '100%',
                                marginTop: '24px',
                            }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                }}>
                                <TickCircle />
                            </Box>
                            <Typography
                                style={{
                                    marginTop: 24,
                                    fontWeight: 500,
                                    fontSize: '32px',
                                    color: ' #303E4E',
                                    textAlign: 'center',
                                }}>
                                Please check your email
                            </Typography>
                        </Grid>
                    )}

                    {[
                        AuthenticationSteps.EMAIL,
                        AuthenticationSteps.CHANGE_PASSWORD,
                    ].includes(currentStep) && (
                        <LoadingButton
                            onClick={() => {
                                handleNextStep()
                            }}
                            // disabled={!email || isLoading}
                            loading={isLoading}
                            variant="contained"
                            loadingIndicator={
                                <SpinnerIcon />
                            }
                            className={classes.button}
                            style={{
                                marginTop: 62,
                                borderRadius: 20,
                            }}
                            fullWidth>
                            {!isLoading ? 'Submit' : ''}
                        </LoadingButton>
                    )}

                    {[
                        AuthenticationSteps.COMPLETED,
                        AuthenticationSteps.EMAIL_SENT,
                    ].includes(currentStep) && (
                        <Button
                            onClick={() => {
                                history.push('/login/staff')
                            }}
                            style={{
                                marginTop: 62,
                                textTransform: 'none',
                            }}
                            variant="contained"
                            size="large"
                            fullWidth>
                            Go to main page
                        </Button>
                    )}
                </Box>
            </Box>
        </Grid>
    )
}
