import React, { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { FormikProps } from 'formik'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'

import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'

import { getClinicalNoteType } from 'services/Calendar'
import { CptCodeSelectors } from './CptCodeSelector'
import { ClinicalNote, CPTCode } from '../interfaces'
import { getListSpecialties } from 'services/Setting'

const useStyles = makeStyles({
    header: {
        color: '#303E4E',
        fontSize: 14,
        // fontWeight: 500
    },
    table__header: {
        marginRight: 8,
        fontWeight: 500,
        color: '#303E4E',
        whiteSpace: 'break-spaces',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
    },
    table: {
        '& .MuiTableCell-root': {
            // border: '1px solid #B2C0CF',
            border: 'unset',
            width: '60%',
            boxSizing: 'border-box',
            padding: 8,
            '&:first-child': {
                width: '30%',
            },
            fontSize: 14,
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            // alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
})

const tableHeading = [
    { title: 'CPT Code', required: true },
    { title: 'Description', required: false },
]

interface Props {
    visitTypeTouched: any
    cptCodeErrors: { [code: string]: string }
    formik: FormikProps<any>
    setVisitTypeTouched: (value: boolean) => void
    setFieldValue: (field: string, value: any, initialize?: boolean) => void
}

export const FormContent = ({
    visitTypeTouched,
    cptCodeErrors,
    formik,
    setVisitTypeTouched,
    setFieldValue,
}: Props) => {
    const classes = useStyles()
    const [clinicalNoteOptions, setClinicalNoteOptions] = useState<ClinicalNote[]>([])

    const [displayColorPicker, setDisplayColorPicker] = useState(false)
    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    })

    useEffect(() => {
        if (formik.values.color_tag) {
            const hex2rgba = (hex, alpha = 1) => {
                const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
                setColor({ r, g, b, a: alpha })
            }
            hex2rgba(formik.values.color_tag)
        }
    }, [formik.values.color_tag])

    const [specialtyOptions, setSpecialtyOptions] = useState([])

    const styles = reactCSS({
        default: {
            color: {
                width: '50px',
                height: '20px',
                borderRadius: '6px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '6px',
                marginTop: 8,
                boxShadow: '0 0 0 1px #e9ecf1',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                top: 150,
                left: 90,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    useEffect(() => {
        const abortController = new AbortController()
        getClinicalNote()
        getListSpecialtiesOptions()

        return () => abortController.abort()
    }, [])

    const handleChange = (updatedValues: CPTCode[], initialize = false) => {
        setFieldValue(`cptArr`, updatedValues, initialize)
    }

    const handleChangeColor = (color) => {
        formik.setFieldValue('color_tag', color.hex)
        setColor(color.rgb)
    }

    async function getClinicalNote() {
        try {
            const response = await getClinicalNoteType()
            setClinicalNoteOptions(response?.data)
        } catch (error) {
            console.log({ error })
        }
    }
    async function getListSpecialtiesOptions() {
        try {
            const response = await getListSpecialties({
                page: 1,
                size: 100000,
            })
            setSpecialtyOptions(
                response?.data?.data
                    ?.map((item) => ({
                        key: item.id,
                        value: item.specialty,
                    }))
                    .sort((option1, option2) => {
                        let a = option1.value
                        let b = option2.value
                        return a === b ? 0 : a > b ? 1 : -1
                    }),
            )
        } catch (error) {
            console.log({ error })
        }
    }

    const changeVisitType = (e: React.ChangeEvent<HTMLInputElement>, visitType: string) => {
        setFieldValue('visitType', e.target.checked ? visitType : '')

        if (e.target.checked) setVisitTypeTouched(true)
    }

    return (
        <>
            <Grid container item className="modal-spacer">
                <Grid item xs={12} style={{ margin: '0 12px' }}>
                    <Select
                        label="Specialty"
                        name="specialty_id"
                        required
                        placeholder="Select Specialty"
                        value={formik.values.specialty_id}
                        error={formik.touched.specialty_id && Boolean(formik.errors.specialty_id)}
                        helperText={formik.touched.specialty_id && formik.errors.specialty_id}
                        options={specialtyOptions}
                        onChange={(value) => setFieldValue('specialty_id', value)}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '0 12px' }}>
                    <FormLabel
                        component="p"
                        className="input-form-label"
                        style={{ color: 'rgb(81, 105, 131)', fontSize: 14, fontWeight: 500 }}>
                        Tags
                    </FormLabel>

                    <div className="fff-color-picker">
                        <div style={styles.swatch} onClick={handleClick}>
                            <div style={styles.color} />
                        </div>
                        {displayColorPicker ? (
                            <div style={styles.popover}>
                                <div style={styles.cover} onClick={handleClose} />
                                <SketchPicker color={color} onChange={handleChangeColor} />
                            </div>
                        ) : null}
                    </div>
                </Grid>
                {/* <Grid
                    container
                    item
                    direction="row">
                    {visitTypes.map((visitType) => (
                        <Grid item key={visitType}>
                            <CheckboxOption
                                name="visitType"
                                option={{
                                    label: visitType,
                                    value: formik.values?.type ===
                                        visitType,
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeVisitType(e, visitType)}
                                checked={formik.values.visitType === visitType}
                                required disabled={undefined} defaultChecked={undefined} classRoot={undefined} />
                        </Grid>
                    ))}
                </Grid> */}
            </Grid>

            {visitTypeTouched && formik.errors.visitType && (
                <p className="helper-text" style={{ marginTop: '-1rem' }}>
                    {formik.errors.visitType}
                </p>
            )}

            <Grid container item spacing={3} className="modal-spacer">
                <Grid item xs={4}>
                    <TextBox
                        label="Code"
                        required
                        name="code"
                        placeholder="Enter Visit Type Code"
                        value={formik.values.postCode}
                        error={formik.touched.postCode && Boolean(formik.errors.postCode)}
                        helperText={formik.touched.postCode && formik.errors.postCode}
                        onChange={(value) => setFieldValue('postCode', value)}
                        inputProps={{ maxLength: 12 }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextBox
                        label="Duration"
                        required
                        name="duration"
                        placeholder="Enter Duration"
                        value={formik.values.duration}
                        error={formik.touched.duration && Boolean(formik.errors.duration)}
                        helperText={formik.touched.duration && formik.errors.duration}
                        onChange={(value) =>
                            setFieldValue('duration', value.replace(/[^0-9]+/g, ''))
                        }
                        inputProps={{ maxLength: 3 }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Select
                        label="Note Type"
                        name="noteTemplateId"
                        required
                        placeholder="Select Note Type"
                        value={formik.values.noteTemplateId}
                        error={
                            formik.touched.noteTemplateId && Boolean(formik.errors.noteTemplateId)
                        }
                        helperText={formik.touched.noteTemplateId && formik.errors.noteTemplateId}
                        options={clinicalNoteOptions.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        onChange={(value) => setFieldValue('noteTemplateId', value)}
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={1} className="modal-spacer">
                <Grid container item alignItems="center" spacing={2} className="add-button">
                    <Grid item xs={12}>
                        <TextBox
                            label="Comment"
                            name="comment"
                            placeholder="Enter comment"
                            value={formik.values.comment}
                            onChange={(value) => setFieldValue('comment', value)}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container item style={{ padding: '0 20px' }}>
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        sx={{ minWidth: 650 }}
                        ariaLabel="simple table">
                        <TableHead>
                            <TableRow>
                                {tableHeading.map(({ title, required }, index) => (
                                    <TableCell
                                        key={index}
                                        align="left"
                                        style={{
                                            width: index === 0 ? '35%' : '68%',
                                        }}>
                                        <Typography
                                            variant="body1"
                                            className={classes.table__header}>
                                            <span className={required ? 'required' : ''}>
                                                {title}
                                            </span>
                                        </Typography>
                                    </TableCell>
                                ))}
                                <TableCell align="left" style={{ width: 100 }}>
                                    <Typography
                                        variant="body1"
                                        className={classes.table__header}
                                        style={{
                                            width: 'fit-content',
                                        }}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <CptCodeSelectors
                                errors={cptCodeErrors}
                                rows={formik.values.cptArr}
                                onChange={handleChange}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </>
    )
}
