import {
    Box,
    Grid,
    Radio,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import CheckboxOption from '../reusable/CheckboxOptions'
import DataGridCustom from '../reusable/DataGridCustom'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import TextWithMacro from '../reusable/TextWithMacro'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'
import CopyBar from 'components/shared/Elements/CopyBar'

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },

    actioneGrid: {
        padding: 20,
        border: '1px solid #B0BCE4',
        borderTop: 0,
    },

    subHeadingText: {
        // ,
        fontSize: 12,
        color: '#9CA9B8',
    },

    columnText: {
        color: '#667689',
        fontSize: 14,
    },

    columnHead: {
        // ,
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 8,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        flex: 1,
    },
})

const data = [
    {
        id: 1,
        name: 'Family',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 2,
        name: 'Friends',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 3,
        name: 'Relationships',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 4,
        name: 'Education',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 5,
        name: 'Economic',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 6,
        name: 'Occupational',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 7,
        name: 'Housing',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 8,
        name: 'Legal',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 9,
        name: 'Health',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
]

const renderRadio = (value, isChecked, onChange) => {
    return (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Radio
                color="primary"
                value={value}
                checked={isChecked}
                onChange={onChange}
            />
        </Box>
    )
}

const filterSection = (master = [], key) => {
    return master.filter((m) => m.section_name === key)
}

const SideEffect = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    nonEditable,
    copyAble,
    copyValues,
    lastNarrative
}) => {
    const classes = useStyles()
    //{
    //     narrative: '',
    //     "no_known": false,
    //     side_effect_opt: [],
    // }
    const categoryOptions = filterSection(
        master?.sideEffectMasters,
        'category',
    )
    const levelOptions = filterSection(
        master?.sideEffectMasters,
        'level',
    )

    const columns = [
        {
            headerName: <></>,
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1.25,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Typography
                        className={classes.columnText}
                        variant="body1">
                        {value}
                    </Typography>
                )
            },
        },
    ]
    const handleCheckChange = (e) => {
        const { name, checked } = e.target
        onChange(name, checked)
    }
    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_side_effects_opt= []
            const side_effects_opt = copyValues?.side_effect_opt?.length ? copyValues?.side_effect_opt : []
            for (const element of side_effects_opt) {
                if (typeof element === 'object' && element !== null) {
                    found_side_effects_opt.push(element);
                }
            }

            const finalValues = {
                narrative: `${copyValues?.narrative} ${values?.narrative}`,
                side_effect_opt: [...found_side_effects_opt, ...(values?.side_effect_opt || [])],
            }
            if (!found_side_effects_opt.length  && copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }
    const handleSelectMacro = (value) => {
        if(nonEditable){return}
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const handleRadioChange = (e, row) => {
        const side_effect_opt = values?.side_effect_opt? values?.side_effect_opt: values?.side_effects_opt
        const existIndex = side_effect_opt.findIndex(
            (so) => so.sideEffect_category === row._id,
        )
        const updatedValue = [...side_effect_opt]
        if (existIndex >= 0) {
            updatedValue[existIndex] = {
                sideEffect_category: row._id,
                sideEffect_level: e.target.value,
            }
        } else {
            updatedValue.push({
                sideEffect_category: row._id,
                sideEffect_level: e.target.value,
            })
        }

        onChange(
            `${fieldName}.side_effect_opt`,
            updatedValue,
        )
    }

    levelOptions.forEach((lo) => {
        columns.push({
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        // textAlign: 'center',
                    }}>
                    <Typography
                        className={classes.columnHead}
                        variant="body2">
                        {lo.name}
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),
            headerClassName: 'super-app-theme--header',
            field: lo.name,
            flex: lo.seq === 1 ? 0.5 : 1,
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value, row }) => {
                const handleChange = (e) => {
                    if(!nonEditable){
                        handleRadioChange(e, row)
                    }
                }
                const selectedEl =
                    values?.side_effect_opt?.find(
                        (so) =>
                            so.sideEffect_category ===
                            row._id,
                    )
                const isChecked =
                    selectedEl?.sideEffect_level === lo._id

                return renderRadio(
                    lo._id,
                    isChecked,
                    handleChange,
                )
            },
        })
    })

    return (
        <Box className={classes.container}>
            <Typography className={classes.subHeadingText}>
                Instruction: This section is concerned with
                SIDE EFFECTS FROM MEDICATION. If you are
                filling this out on behalf of the patient,
                please answer from the patient's
                perspective.
            </Typography>
            {copyAble ? (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            ) : (
                <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            )}
            <CheckboxOption
                name={`${fieldName}.no_known`}
                option={{
                    label: 'No known side effects',
                    value: false,
                }}
                disabled={nonEditable}
                onChange={handleCheckChange}
                checked={values?.no_known}
            />
            <DataGridCustom
                tableProps={{
                    rows: categoryOptions,
                    columns,
                    hideFooter: true,
                }}
            />
            <Grid item>
                <NarrativeAndMacro
                    hideClearBtn={true}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    onChange={onChange}
                    values={values}
                    macros={macros}
                    meta={{
                        values,
                        master: master?.sideEffectMasters,
                    }}
                    nonEditable={nonEditable}
                    // narrativeValue={values.narrative}
                />
            </Grid>
        </Box>
    )
}

export default SideEffect
