import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import TextWithMacrosNew from 'components/ClinicalNotes/reusable/TextWithMacrosNew'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { MEDICAL_INITIAL_CLINICAL_NOTE } from 'constants/clinicalNote'
import { formatString } from 'containers/CalendarScheduler/utils'
import { displayName } from 'containers/EFax/shared/util'
import React from 'react'
import { SelectSearchInputCustom } from 'shared/elements/FormItem/SelectSearchInputCustom'

const useStyles = makeStyles(() => ({
    selectionChecks: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '-2px !important',
        },
    },
    sectionCheckContainer: {
        width: '100%',
        marginTop: 35,
        border: '1px solid #E0E0E0',
        display: 'flex',
        alignItems: 'center',
        '& .MuiBox-root .MuiGrid-root .MuiGrid-root': {
            borderRadius: '0px !important',
            borderWidth: '0px 0px 1px 0px !important'
        },
        '& .MuiBox-root div .MuiFormControl-root': {
            border: '0px !important',
        },
    },
}))

function PhysicalExamCopyComponent(props) {
    const classes = useStyles()

    return (
        <Grid container item xs={2} style={{ padding: 15, maxWidth: 'fit-content' }}>
            <Grid container item direction="row" alignItems="flex-start" style={{ gap: 35 }}>
                {props?.physicalExamItems?.map((item) => {
                    if(item !== 'narrative') {
                        return (
                            <div
                                key={item}
                                style={{
                                    width: '400px',
                                    gap: 10,
                                    border: '1px solid #E0E0E0',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <div
                                    className={classes.selectionChecks}
                                    style={{
                                        borderRight: '1px solid #E0E0E0',
                                        display: 'flex',
                                        alignSelf: 'stretch',
                                        alignItems: 'center',
                                        background: props?.copySelectionFieldNames?.includes(item)
                                            ? '#EAECF0'
                                            : '#F8F9FB',
                                    }}>
                                    <CheckboxOption
                                        name={`${props?.fieldName}.physical_exam.${item}.wnl`}
                                        option={{
                                            value: '',
                                            label: '',
                                        }}
                                        checked={props?.copySelectionFieldNames?.includes(item)}
                                        onChange={(e) => {
                                            const checked = e?.target?.checked
                                            let currentSelections = props?.copySelectionFieldNames
                                            if (
                                                checked &&
                                                !props?.copySelectionFieldNames?.includes(item)
                                            ) {
                                                currentSelections?.push(item)
                                            }
                                            if (!checked) {
                                                currentSelections = currentSelections?.filter(
                                                    (selection) => selection !== item,
                                                )
                                            }
                                            props?.handleSetSelectedValues(currentSelections)
                                        }}
                                    />
                                </div>
                                <div
                                    key={item}
                                    style={{ paddingTop: 0, paddingBottom: 26, width: 340 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}>
                                        <FormLabel
                                            component="p"
                                            style={{ color: '#303E4E', margin: 0 }}>
                                            {['heent', 'msk']?.includes(item)
                                                ? item?.toUpperCase()
                                                : formatString(item)}
                                        </FormLabel>
                                        <CheckboxOption
                                            name={`${props?.fieldName}.physical_exam.${item}.wnl`}
                                            option={{
                                                value: props?.values[item]?.wnl,
                                                label: 'WNL',
                                            }}
                                            checked={props?.values[item]?.wnl}
                                            onChange={() => {}}
                                            disabled={true}
                                        />
                                    </div>

                                    {item !== 'skin' ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                gap: '10px',
                                            }}>
                                            <div
                                                style={{ width: '100%' }}
                                                className={props?.classes?.SelectInputWapper}>
                                                {/* <SelectSearchInputCustom
                                                    isChecBoxFlag={true}
                                                    name={`${props?.fieldName}.physical_exam.${item}.selections`}
                                                    placeholder="Not chosen"
                                                    options={MEDICAL_INITIAL_CLINICAL_NOTE.physical_exam[item]}
                                                    multiple={true}
                                                    value={props?.values[item].selections}
                                                    limitTags={2}
                                                    titleModal={formatString(item)}
                                                    styleModal={{
                                                        backgroundColor: '#FFF',
                                                        border: '1px solid #EAECF0',
                                                    }}
                                                    readonly={true}
                                                    disabled={true}
                                                /> */}
                                                <Box
                                                    style={{
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: 5,
                                                        padding: '10px 7px',
                                                    }}>
                                                    {props?.values[item].selections?.length > 0 ? (
                                                        <div
                                                            style={{
                                                                overflowX: 'auto',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 10,
                                                            }}>
                                                            {props?.values[item].selections?.map(
                                                                (el) => {
                                                                    return (
                                                                        <Typography
                                                                            key={el?.key}
                                                                            style={{
                                                                                padding: '5px',
                                                                                borderRadius: 5,
                                                                                background: '#e0e0e0',
                                                                            }}>
                                                                            {el?.key}
                                                                        </Typography>
                                                                    )
                                                                },
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <Typography>Not chosen</Typography>
                                                    )}
                                                </Box>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                gap: '10px',
                                                padding: '10px 0px 10px 5px',
                                                background: '#f8f9fb',
                                                borderRadius: 5,
                                            }}>
                                            <FormControlLabel
                                                control={
                                                    <AntSwitch
                                                        name={`${props?.fieldName}.physical_exam.skin.skin_lesion`}
                                                        checked={props?.values?.skin.skin_lesion}
                                                        onChange={() => {}}
                                                        disabled={true}
                                                    />
                                                }
                                                label="Skin Lesion"
                                                style={{ margin: '0px', gap: '10px' }}
                                                sx={{
                                                    '& .MuiTypography-body1': {
                                                        color: '#303E4E !important',
                                                    },
                                                }}
                                            />
                                        </div>
                                    )}

                                    {props?.values[item]?.comment !== '' && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                marginTop: 8,
                                            }}>
                                            <Typography style={{ color: '#303e4e' }}>
                                                Comment
                                            </Typography>
                                            <TextField
                                                value={props?.values[item]?.comment || ''}
                                                onChange={() => {}}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                })}
            </Grid>

            <div className={classes.sectionCheckContainer}>
                <div
                    className={classes.selectionChecks}
                    style={{
                        borderRight: '1px solid #E0E0E0',
                        display: 'flex',
                        alignSelf: 'stretch',
                        alignItems: 'center',
                        background: props?.copySelectionFieldNames?.includes('narrative')
                            ? '#EAECF0'
                            : '#F8F9FB',
                    }}>
                    <CheckboxOption
                        option={{
                            value: '',
                            label: '',
                        }}
                        checked={props?.copySelectionFieldNames?.includes('narrative')}
                        onChange={(e) => {
                            const checked = e?.target?.checked
                            let currentSelections = props?.copySelectionFieldNames
                            if (checked && !props?.copySelectionFieldNames?.includes('narrative')) {
                                currentSelections?.push('narrative')
                            }
                            if (!checked) {
                                currentSelections = currentSelections?.filter(
                                    (selection) => selection !== 'narrative',
                                )
                            }
                            props?.handleSetSelectedValues(currentSelections)
                        }}
                    />
                </div>
                <TextWithMacrosNew
                    name={`${props?.fieldName}.narrative`}
                    onChange={() => {}}
                    isDisabled={true}
                    rows={12}
                    withoutMacro={false}
                    values={props?.values?.narrative}
                />
            </div>
        </Grid>
    )
}

export default PhysicalExamCopyComponent
