import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    TextField
} from '@mui/material'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { FormHelperText, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    wrapSelectMuntil: {
        // overflow: 'hidden',
        '& *': {
            boxSizing: 'border-box',
        },
        '& .MuiFormControl-root .MuiFormLabel-root': {
            top: '-10px !important'
        }
    },
    label: {
        fontSize: '18px',
        fontWeight: 400,
        color: '#303E4E',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '15px',
        fontFamily:'Rubik',
    },
    placeholder: {
        top: '45% !important',
        left: '13px !important',
        transform: 'translate(0, 95%) !important',
        lineHeight: 'normal',
        color: 'rgb(166, 172, 179) !important',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1 !important',
        },
        fontWeight: '400',
        fontSize: '14px !important',
        textTransform:'none !important'
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '40px',
        },
    },
    box_options: {
        padding: '15px 0',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    list_options: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
    chips: {
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 6,
    },
    chip: {
        background: '#E9ECEF',
        borderRadius: 99,
        margin: '4px 8px 4px 0',
    },
    choseText:{
        color: '#616161',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '16px',
        paddingLeft: '10px',
    }
}))

const MultipleSelectCondition = (props) => {
    const {
        options,
        value = [],
        handleChange,
        style,
        onOpen,
        sx,
        otherValue,
        otherCondition,
        ...rest
    } = props
    const classes = useStyles()
    const check = value?.some(
        (item) => item.value === 'Other',
    )
    const [textValue, setTextValue] = useState('')
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && textValue !== "" ) {
            otherCondition(textValue)
        }
    };
    const textFieldRef = useRef(null);

    useEffect(() => {
        if (check && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [check]);
    useEffect(()=>{
        if(value.length > 0){
            const otherPresent = value.find(item => item?.key === 'Other')
            const unmatched = value.find(item => item?.key === 'otherComment')
            unmatched?.value && otherPresent ? setTextValue(unmatched.value) : setTextValue('')
        }
    },[value])
    return (
        <Box sx={sx} className={classes.wrapSelectMuntil}>
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                <InputLabel shrink={false} id="demo-multiple-checkbox-label" className={classes.placeholder}>
                        {value.length > 0 ?  'Selected condition' : 'Not chosen'}
                </InputLabel>
                <Select
                    style={style}
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={[]}
                    // onChange={(e) => {
                    //     console.log(e)
                    // }}
                    onOpen={() => onOpen?.(true)}
                    onClose={()=> onOpen?.(false)}
                    IconComponent={KeyboardArrowDown}
                    {...rest}>
                    <Box className={classes.box_options}>
                        <Box className={classes.list_options}>
                            <Typography className={classes.choseText}>Choose conditions</Typography>
                            {
                                options.map((option) => {
                                    const check = value?.find(
                                        (item) => item.value === option.value,
                                    )
                                    return (
                                        <Box key={option.value} className={classes.box_option}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={check ? true : false}
                                                        onChange={(e) => {
                                                            handleChange(
                                                                option,
                                                                e.target.checked,
                                                            )
                                                        }}
                                                        name={option.value}
                                                    />
                                                }
                                                label={
                                                    <li>
                                                        <span
                                                            style={{
                                                                marginRight: '3px',
                                                            }}>
                                                            {option.value}
                                                        </span>
                                                    </li>
                                                }
                                            />
                                        </Box>
                                    )
                                })   
                            }
                            {check &&
                                <div style={{padding:'10px', marginBottom: '2px'}}>
                                    <TextField
                                        inputRef={textFieldRef}
                                        variant="outlined"
                                        style={{ marginTop: 1, }}
                                        placeholder="Enter 'other' condition"
                                        value={textValue}
                                        onChange={(e)=>{setTextValue(e.target.value)}}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                            }
                        </Box>
                    </Box>
                </Select>
                {props.error &&
                <FormHelperText style={{color: 'red', marginTop: '2px'}}>{props.helperText}</FormHelperText>}
            </FormControl>
        </Box>
    )
}

export default React.memo(MultipleSelectCondition)
