const TeleIconCloseCircle = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99935 1.33332C11.6765 1.33332 14.666 4.3228 14.666 7.99999M7.99935 1.33332C4.32216 1.33332 1.33268 4.3228 1.33268 7.99999M7.99935 1.33332C4.3232 1.33332 1.33268 4.32385 1.33268 7.99999M7.99935 1.33332C11.6755 1.33332 14.666 4.32385 14.666 7.99999M14.666 7.99999C14.666 11.6772 11.6765 14.6667 7.99935 14.6667M14.666 7.99999C14.666 11.6761 11.6755 14.6667 7.99935 14.6667M1.33268 7.99999C1.33268 11.6772 4.32216 14.6667 7.99935 14.6667M1.33268 7.99999C1.33268 11.6761 4.32321 14.6667 7.99935 14.6667"
                stroke="white"
            />
            <mask
                id="path-2-inside-1_3766_5368"
                fill="white">
                <path d="M9.88643 5.61334C10.0131 5.61334 10.1398 5.66001 10.2398 5.76001C10.4331 5.95334 10.4331 6.27334 10.2398 6.46668L6.46643 10.24C6.2731 10.4333 5.9531 10.4333 5.75977 10.24C5.56643 10.0467 5.56643 9.72668 5.75977 9.53334L9.5331 5.76001C9.62643 5.66001 9.75977 5.61334 9.88643 5.61334Z" />
            </mask>
            <path
                d="M9.5331 5.76001L10.2402 6.46712L10.2524 6.45493L10.2642 6.44233L9.5331 5.76001ZM9.88643 6.61334C9.76219 6.61334 9.63008 6.56453 9.53266 6.46712L10.9469 5.0529C10.6495 4.75548 10.264 4.61334 9.88643 4.61334L9.88643 6.61334ZM9.53266 6.46712C9.33547 6.26993 9.33547 5.95676 9.53266 5.75957L10.9469 7.17378C11.5307 6.58992 11.5307 5.63676 10.9469 5.0529L9.53266 6.46712ZM9.53266 5.75957L5.75932 9.5329L7.17354 10.9471L10.9469 7.17378L9.53266 5.75957ZM5.75932 9.5329C5.95652 9.33571 6.26968 9.33571 6.46687 9.5329L5.05266 10.9471C5.63652 11.531 6.58968 11.531 7.17354 10.9471L5.75932 9.5329ZM6.46687 9.5329C6.66406 9.73009 6.66406 10.0433 6.46687 10.2405L5.05266 8.82624C4.4688 9.41009 4.4688 10.3633 5.05266 10.9471L6.46687 9.5329ZM6.46687 10.2405L10.2402 6.46712L8.82599 5.0529L5.05266 8.82624L6.46687 10.2405ZM10.2642 6.44233C10.138 6.57751 9.98905 6.61334 9.88643 6.61334L9.88643 4.61334C9.53048 4.61334 9.11488 4.74251 8.80204 5.07769L10.2642 6.44233Z"
                fill="white"
                mask="url(#path-2-inside-1_3766_5368)"
            />
            <mask
                id="path-4-inside-2_3766_5368"
                fill="white">
                <path d="M6.1131 5.61334C6.23976 5.61334 6.36643 5.66001 6.46643 5.76001L10.2398 9.53334C10.4331 9.72668 10.4331 10.0467 10.2398 10.24C10.0464 10.4333 9.72643 10.4333 9.5331 10.24L5.75977 6.46668C5.56643 6.27334 5.56643 5.95334 5.75976 5.76001C5.85976 5.66001 5.98643 5.61334 6.1131 5.61334Z" />
            </mask>
            <path
                d="M6.1131 5.61334C6.23976 5.61334 6.36643 5.66001 6.46643 5.76001L10.2398 9.53334C10.4331 9.72668 10.4331 10.0467 10.2398 10.24C10.0464 10.4333 9.72643 10.4333 9.5331 10.24L5.75977 6.46668C5.56643 6.27334 5.56643 5.95334 5.75976 5.76001C5.85976 5.66001 5.98643 5.61334 6.1131 5.61334Z"
                fill="#292D32"
            />
            <path
                d="M6.46643 5.76001L7.17354 5.0529L6.46643 5.76001ZM10.2398 9.53334L9.53266 10.2405L10.2398 9.53334ZM9.5331 10.24L10.2402 9.5329L9.5331 10.24ZM5.75977 6.46668L5.05266 7.17378L5.75977 6.46668ZM6.1131 6.61334C5.98886 6.61334 5.85674 6.56453 5.75932 6.46712L7.17354 5.0529C6.87612 4.75548 6.49067 4.61334 6.1131 4.61334L6.1131 6.61334ZM5.75932 6.46712L9.53266 10.2405L10.9469 8.82624L7.17354 5.0529L5.75932 6.46712ZM9.53266 10.2405C9.33547 10.0433 9.33547 9.73009 9.53266 9.5329L10.9469 10.9471C11.5307 10.3633 11.5307 9.41009 10.9469 8.82624L9.53266 10.2405ZM9.53266 9.5329C9.72985 9.33571 10.043 9.33571 10.2402 9.5329L8.82599 10.9471C9.40985 11.531 10.363 11.531 10.9469 10.9471L9.53266 9.5329ZM10.2402 9.5329L6.46687 5.75957L5.05266 7.17378L8.82599 10.9471L10.2402 9.5329ZM6.46687 5.75957C6.66406 5.95676 6.66406 6.26993 6.46687 6.46712L5.05266 5.0529C4.4688 5.63676 4.4688 6.58993 5.05266 7.17378L6.46687 5.75957ZM6.46687 6.46712C6.36945 6.56453 6.23734 6.61334 6.1131 6.61334L6.1131 4.61334C5.73552 4.61334 5.35008 4.75549 5.05266 5.0529L6.46687 6.46712Z"
                fill="white"
                mask="url(#path-4-inside-2_3766_5368)"
            />
        </svg>
    )
}
export default TeleIconCloseCircle
