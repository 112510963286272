import {
    Box,
    FormLabel,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import MicrosoftWordIcon from 'assests/icons/MicrosoftWordIcon'
import React, { useState } from 'react'
import { ButtonIconEl } from 'shared/elements'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Files from 'react-butterfiles'
import IconDragnDrop from 'assests/sidebar/imageUpload.svg'
import { uploadFileToS3 } from 'services/Documents'
import LoadingProgressPage from 'utilities/loading-progress-page'
import axios from 'axios'
import { requestPutAvatarUrl } from 'services/Provider/provider'

const useStyles = makeStyles(() => ({
    download_text: {
        color: '#5571C6',
        cursor: 'pointer',
        padding: '8px !important',
    },
    open_browser_file: {
        color: '#5571C6',
        cursor: 'pointer',
    },
    box_files: {
        marginLeft: -6,
        paddingTop: 12,
    },
    drop_text: {
        pointerEvents: 'none',
    },
}))

const ImageUpload = ({ onChange ,disabled}) => {
    const classes = useStyles()
    const [isHover, setIsHover] = useState(false)
    const [files, setFiles] = useState([])
    const [errors, setErrors] = useState([])
    const [uploadProgress, setUploadProgress] =
        React.useState(0)
    const [isUploading, setIsUploading] =
        React.useState(false)

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
            (progressEvent.loaded * 100) /
            progressEvent.total,
        )
        setUploadProgress(percentCompleted)
    }

    const handleUploadAttachmentFile = async (
        files = [],
    ) => {
            try {
                if (!disabled) {
                    const file = files[0]
                    setIsUploading(true)
                    setUploadProgress(0)
                    const fileContext = await new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.addEventListener('loadend', (event) => {
                            resolve(event.target.result)
                        })
                        reader.readAsArrayBuffer(file?.src?.file)
                    })
                    const res = await requestPutAvatarUrl()
                    const { url, key } = res.data
                    file.key = key
                    await axios.put(url, fileContext, {
                        headers: {
                            'Content-Type': file.type,
                        },
                        onUploadProgress,
                    })
                }
            } finally {
                setIsUploading(false)
            }
        
        setFiles(files)
        onChange &&
            onChange({
                attachment_key: files[0].key,
                document_name: files[0].name,
            })
        setIsHover(false)
    }

    const handleErrors = (errors) => {
        setErrors(errors)
        setIsHover(false)
    }

    return (
        <Grid container>
            <Grid item>
                <FormLabel
                    component="p"
                    className="d-flex justify-space-between align-center"
                    style={{ fontSize: 14, fontWeight: 500 }}
                >
                 Upload Photo
                </FormLabel>
            </Grid>
            <Grid item xs={12}>
                <Files
                    multiple={false}
                    multipleMaxSize="2mb"
                    maxSize="2mb"
                    Extension
                    accept={[
                        'image/jpg',
                        'image/jpeg',
                        'image/png',
                    ]}
                    onSuccess={handleUploadAttachmentFile}
                    onError={handleErrors}>
                    {({
                        browseFiles,
                        getDropZoneProps,
                    }) => {
                        return (
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                {...getDropZoneProps({
                                    style: {
                                        height: 90,
                                        borderRadius: 4,
                                        border: !isHover
                                            ? '2px #E9ECF1 dashed'
                                            : '2px #bcbec3 dashed',
                                    },
                                })}
                                onDragEnter={(e) => {
                                    setIsHover(true)
                                }}
                                onDragLeave={(e) => {
                                    setIsHover(false)
                                }}>
                                {!isHover ? (
                                    <>
                                        <img src={IconDragnDrop} alt="" height="40" style={{marginTop: "12px"}}/>
                                        <Typography
                                            style={{
                                                marginLeft: 6,
                                            }}>
                                            Drag and drop to
                                            upload or{' '}
                                            <span
                                                className={
                                                    classes.open_browser_file
                                                }
                                                onClick={()=>{
                                                    if (!isUploading && !disabled) {
                                                        browseFiles()
                                                    }
                                                }
                                                }
                                                >
                                                browse for a
                                                file
                                            </span>{' '}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography
                                        className={
                                            classes.drop_text
                                        }>
                                        Drop here
                                    </Typography>
                                )}
                            </Grid>
                        )
                    }}
                </Files>
            </Grid>

            {/* <Grid
                item
                xs={12}
                className={classes.box_files}>
                <Grid container spacing={1}>
                    {files.map((item) => (
                        <Grid
                            key={item.name}
                            item
                            container
                            justifyContent="space-between">
                            <Box
                                display="flex"
                                alignItems="center">
                                <Grid item>
                                    <MicrosoftWordIcon />
                                </Grid>

                                <Grid item>
                                    <Typography
                                        style={{
                                            marginLeft: 6,
                                        }}>
                                        {item.name}
                                    </Typography>
                                </Grid>
                            </Box>
                            <ButtonIconEl
                                size="small"
                                // className={
                                // 	classes.act_iconbtn
                                // }
                                onClick={() => {
                                    setFiles([])
                                    onChange &&
                                        onChange({
                                            attachment_key:
                                                '',
                                            document_name:
                                                '',
                                        })
                                }}>
                                <HighlightOffIcon
                                    style={{
                                        fontSize: 18,
                                        color: '#9CA9B8',
                                    }}
                                />
                            </ButtonIconEl>
                        </Grid>
                    ))}
                </Grid>
            </Grid> */}
            {isUploading && (
                <LoadingProgressPage
                    progress={uploadProgress}
                />
            )}
        </Grid>
    )
}

export default ImageUpload
