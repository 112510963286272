import { UserRoles, getAllUserRoles } from './user-roles'
import Routes from './routes'
import dashboardIcon from '../assests/sidebar/icon_dashboard.svg'
import activeDashboardIcon from '../assests/sidebar/icon_dashboard_pressed.svg'
import scheduleIcon from '../assests/sidebar/icon_schedule_normal.svg'
import activeScheduleIcon from '../assests/sidebar/icon_schedule_pressed.svg'
import healthReportIcon from '../assests/sidebar/icon_report_normal.svg'
import activeHealthReportIcon from '../assests/sidebar/icon_report_pressed.svg'
import appointmentsIcon from '../assests/sidebar/icon_appointments.svg'
import activeAppointmentsIcon from '../assests/sidebar/icon_appointments_pressed.svg'
import reportIcon from '../assests/sidebar/icon_report_normal.svg'
import reportActiveIcon from '../assests/sidebar/icon_report_pressed.svg'
import billingIcon from '../assests/sidebar/icon_billing.svg'
import billingActiveIcon from '../assests/sidebar/icon_billing_pressed.svg'
import messageIcon from '../assests/sidebar/icon_messages.svg'
import messageActiveIcon from '../assests/sidebar/icon_messages_pressed..svg'
import supportActiveIcon from '../assests/sidebar/Support_Active.svg'
import supportIcon from '../assests/sidebar/Support_Normal.svg'
import eRxIcon from '../assests/sidebar/eRx_Normal.svg'
import eRxActiveIcon from '../assests/sidebar/eRx_Active.svg'
import workQueueActiveIcon from '../assests/sidebar/icon_queues_active.svg'
import workQueueIcon from '../assests/sidebar/icon_queues.svg'
import clinicalNotesIcon from '../assests/sidebar/icon_notes.svg'
import clinicalNotesActiveIcon from '../assests/sidebar/icon_notes_pressed..svg'
import labsIcon from '../assests/sidebar/icon_lab orders.svg'
import labsActiveIcon from '../assests/sidebar/icon_lab orders_pressed..svg'
import imagingIcon from '../assests/sidebar/icon_imaging.svg'
import imagingActiveIcon from '../assests/sidebar/icon_imaging_pressed..svg'
import documentsIcon from '../assests/sidebar/icon_documents.svg'
import documentsActiveIcon from '../assests/sidebar/icon_documents_pressed.svg'
import SettingIcon from '../assests/sidebar/Settings_Normal.svg'
import PatientsIcon from '../assests/sidebar/Patients_Normal.svg'
import PatientsActiveIcon from '../assests/sidebar/Patients_Active.svg'
import SettingsChildTabActive from '../assests/sidebar/Settings_Child_Tab_Active.svg'
import SettingsChildTab from '../assests/sidebar/Settings_Child_Tab.svg'
import SettingActiveIcon from '../assests/sidebar/Settings_Active.svg'
import PatientIcon from '../assests/sidebar/Patients_Normal.svg'
import PatientActiveIcon from '../assests/sidebar/Patients_Active.svg'
import ActionIcon from '../assests/sidebar/ActionItems.svg'
import ActionActiveIcon from '../assests/sidebar/ActionItems.svg'
import bhscale from '../assests/sidebar/bhscale.svg'
import bhscaleActive from '../assests/sidebar/bhscale_pressed.svg'
import LabOrderIcon from '../assests/images/icon_lab_order.svg'
import LabOrderIconActive from '../assests/images/icon_lab_order_active.svg'
import ProviderDirectory from '../assests/images/provider_directory.svg'
import ProviderDirectoryActive from '../assests/images/provider_directory_active.svg'
import HealthRecordsIcon from '../assests/images/icon_health_records.svg'
import HealthRecordsIconActive from '../assests/images/icon_health_records_active.svg'

//header menu icons
import profileIcon from '../assests/images/icon_profile.svg'
import LogOutIcon from '../assests/sidebar/icon_log_out.svg'
import ProviderDashboard from '../components/Provider/ProviderDetails/Provider-dashboard'
import ProviderAppintment from '../components/Provider/ProviderDetails/Provider-appointments'
import { TELE_HEALTH_CONSULTATION } from '../containers/Consultation/constants'
import LocationSettings from '../containers/LocationSettings/index'
import FaxIcon from 'assests/icons/FaxIcon.svg'
import FaxActiveIcon from 'assests/icons/FaxActiveIcon.svg'

const allUserRoles = getAllUserRoles()
const adminUserRoles = [
    UserRoles.TM2U_ADMIN,
    UserRoles.TM2U_SUPER_ADMIN,
    UserRoles.CLINIC_CORDINATION,
    UserRoles.TENANT_ADMIN,
]

// export const DashboardMenu = {
//     role: [...allUserRoles],
//     component: ProviderDashboard,
//     name: 'Dashboard',
//     path: Routes.DASHBOARD.BI_STUDIO,
//     icon: dashboardIcon,
//     activeIcon: activeDashboardIcon,
// }

export const DashboardMenu = {
    role: [...allUserRoles, UserRoles.PROVIDER],
    name: 'Bi Studio',
    actualName: 'Dashboard',
    path: Routes.DASHBOARD.BI_STUDIO,
    parent: 'Dashboard',
    icon: SettingsChildTab,
    activeIcon: SettingsChildTabActive,
    public: true,
}

export const LabOrderMenu = {
    role: [...allUserRoles],
    name: 'Lab Order',
    path: `${Routes.TELE_HEALTH.CONSULTATION}?info=${TELE_HEALTH_CONSULTATION.LAB_ORDER}`,
    icon: LabOrderIcon,
    activeIcon: LabOrderIconActive,
    isConsultationPage: true,
}

export const TAB_CLINICALNOTE = 'Clinical Notes'
export const TAB_HEALTH_RECORD = 'Health Records'

export const HealthRecordsMenu = {
    role: [...allUserRoles],
    name: TAB_HEALTH_RECORD,
    path: `${Routes.TELE_HEALTH.CONSULTATION}?info=${TELE_HEALTH_CONSULTATION.HEALTH_RECORDS}`,
    icon: HealthRecordsIcon,
    activeIcon: HealthRecordsIconActive,
    isConsultationPage: true,
}

export const ClinicalNotesMenu = {
    role: [...allUserRoles],
    name: TAB_CLINICALNOTE,
    path: `${Routes.TELE_HEALTH.CONSULTATION}?info=${TELE_HEALTH_CONSULTATION.CLINICAL_NOTE}`,
    icon: clinicalNotesIcon,
    activeIcon: clinicalNotesActiveIcon,
    isConsultationPage: true,
}

export const Menu = [
    //<--------DashboardMenu------<//
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        name: 'Dashboard',
        path: Routes.DASHBOARD.MAIN,
        hasChild: true,
        isOpenChildTab: false,
        icon: dashboardIcon,
        activeIcon: activeDashboardIcon,
        public: true,
    },
    DashboardMenu,
    {
        role: [...allUserRoles, UserRoles.PROVIDER],
        name: 'Report Repository',
        actualName: 'Dashboard',
        path: Routes.DASHBOARD.REPORT_REPOSITORY,
        parent: 'Dashboard',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    //<------//------>//
    // LabOrderMenu,
    // HealthRecordsMenu,
    // ClinicalNotesMenu,

    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        name: 'Patients',
        path: Routes.PATIENT.MAIN,
        icon: PatientsIcon,
        activeIcon: PatientsActiveIcon,
        public: true,
    },
    {
        path: Routes.DOCUMENTS,
        role: [UserRoles.PROVIDER],
        component: '',
        name: 'Documents',
        icon: documentsIcon,
        activeIcon: documentsActiveIcon,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        position: 'bottom',
        name: 'Settings',
        path: Routes.SETTINGS.MAIN,
        hasChild: true,
        isOpenChildTab: false,
        icon: SettingIcon,
        activeIcon: SettingActiveIcon,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER], // TODO:
        component: LocationSettings,
        position: 'bottom',
        name: 'Clinical Practices',
        actualName: 'Clinical Practices',
        path: Routes.SETTINGS.CLINICAL_PRACTICES,
        parent: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },

    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: LocationSettings,
        position: 'bottom',
        name: 'Service Locations',
        actualName: 'Settings: Service Locations',
        path: Routes.SETTINGS.LOCATIONS,
        parent: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: LocationSettings,
        position: 'bottom',
        name: 'Visit Types',
        actualName: 'Settings',
        path: Routes.SETTINGS.VISIT_TYPES,
        parent: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: LocationSettings,
        position: 'bottom',
        name: 'Status Codes',
        path: Routes.SETTINGS.STATUS_CODES,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        position: 'bottom',
        name: 'Custom Proc Codes',
        path: Routes.SETTINGS.CUSTOM_PROC_CODES,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'User Accounts',
        position: 'bottom',
        path: Routes.SETTINGS.USER_SECURITY,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Departments',
        position: 'bottom',
        path: Routes.SETTINGS.DEPARTMENTS,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Security Roles',
        position: 'bottom',
        path: Routes.SETTINGS.ROLES_MANAGEMENT,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    // {
    //     role: [...adminUserRoles, UserRoles.PROVIDER],
    //     name: 'Media Keywords',
    //     position: 'bottom',
    //     path: Routes.SETTINGS.MEDIA_KEYWORD,
    //     parent: 'Settings',
    //     actualName: 'Settings',
    //     icon: SettingsChildTab,
    //     activeIcon: SettingsChildTabActive,
    // },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Specialties',
        position: 'bottom',
        path: Routes.SETTINGS.SPECIALTIES,
        parent: 'Settings',
        actualName: 'Specialties',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER], // TODO:

        position: 'bottom',
        name: 'Health Plans',
        actualName: 'Health Plans',
        path: Routes.SETTINGS.HEALTH_PLAN,
        parent: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Macros',
        position: 'bottom',
        path: Routes.SETTINGS.MACRO,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Resources',
        position: 'bottom',
        path: Routes.SETTINGS.RESOURCE_MANAGEMENT,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Referring Providers',
        position: 'bottom',
        path: Routes.SETTINGS.REFERRING_PROVIDER,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Referral Checklist',
        position: 'bottom',
        path: Routes.SETTINGS.REFERRAL_CHECKLIST,
        parent: 'Settings',
        actualName: 'Settings: Referral Checklist',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [
            ...adminUserRoles,
            UserRoles.TM2U_SUPER_ADMIN,
            UserRoles.TM2U_ADMIN,
            UserRoles.PATIENT,
            UserRoles.PROVIDER,
        ],
        component: ProviderAppintment,
        name: 'Schedule',
        path: Routes.SCHEDULE,
        icon: scheduleIcon,
        activeIcon: activeScheduleIcon,
        public: true,
    },
    // {
    //     role: [...adminUserRoles],
    //     component: '',
    //     name: 'Users',
    //     path: Routes.USERS,
    //     icon: messageIcon,
    //     activeIcon: PatientActiveIcon,
    // },
    // {
    //     role: [UserRoles.TENANT_ADMIN],
    //     component: '',
    //     name: 'Fax',
    //     path: Routes.EFax.MAIN,
    //     icon: FaxIcon,
    //     activeIcon: FaxActiveIcon,
    //     public: true,
    // },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        name: 'eRX',
        path: Routes.ERX,
        hasChild: true,
        icon: eRxIcon,
        activeIcon: eRxActiveIcon,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        name: 'Lab Order',
        path: Routes.LABS_ORDER.MAIN,
        icon: LabOrderIcon,
        activeIcon: LabOrderIconActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        component: '',
        name: 'Provider Directory',
        path: Routes.PROVIDER_DIRECTORY.MAIN,
        icon: ProviderDirectory,
        activeIcon: ProviderDirectoryActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER, UserRoles.PATIENT, UserRoles.MID_PROVIDER],
        component: '',
        name: 'Work Queue',
        actualName: 'Work Queue',
        path: Routes.CLINICAL_NOTES.MAIN,
        icon: workQueueIcon,
        activeIcon: workQueueActiveIcon,
        hasChild: true,
        isOpenChildTab: false,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Clinical Notes',
        actualName: 'Work Queue: Clinical Notes',
        path: Routes.CLINICAL_NOTES.LISTING,
        parent: 'Work Queue',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Superbills',
        actualName: 'Work Queue: Superbills',
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.SUPERBILLS.MAIN,
        parent: 'Work Queue',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Referrals',
        actualName: 'Work Queue: Referrals',
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN,
        parent: 'Work Queue',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Follow Up',
        actualName: 'Work Queue: Follow Up',
        path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN,
        parent: 'Work Queue',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    // {
    //     role: [...adminUserRoles, UserRoles.PROVIDER],
    //     name: 'Waitlist',
    //     actualName: 'Waitlist',
    //     path: Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.WAITLIST.MAIN,
    //     parent: 'Work Queue',
    //     icon: SettingsChildTab,
    //     activeIcon: SettingsChildTabActive,
    //     public: true,
    // },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Branding',
        position: 'bottom',
        path: Routes.SETTINGS.BRANDING,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'Patient Outcomes',
        position: 'bottom',
        path: Routes.SETTINGS.PATIENT_OUTCOMES,
        parent: 'Settings',
        actualName: 'Settings',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    },
    {
        role: [...adminUserRoles, UserRoles.PROVIDER],
        name: 'System Announcements',
        position: 'bottom',
        path: Routes.SETTINGS.SYSTEM_ANNOUNCEMENT,
        parent: 'Settings',
        actualName: 'Setting: System Announcements',
        icon: SettingsChildTab,
        activeIcon: SettingsChildTabActive,
        public: true,
    }
]

export const getMenusByUserRole = (userRole, currentPath, telehealthScreen) => {
    // console.log(telehealthScreen, 'log telehealthScreen')
    return currentPath === Routes.TELE_HEALTH.CONSULTATION || telehealthScreen
        ? Menu.filter((item) => {
              return item.public || (item.role.includes(userRole) && item.isConsultationPage)
          })
        : Menu.filter((item) => {
              return item.public || (item.role.includes(userRole) && !item.isConsultationPage)
          })
}

export const userMenus = [
    {
        name: 'Profile',
        icon: PatientIcon,
        action: 'onProfileClick',
    },
    {
        name: 'Logout',
        icon: LogOutIcon,
        action: 'onLogoutClick',
    },
]

export const profileTabsList = {
    patient: [
        'profile',
        'appointments',
        'Health summary',
        'clinical notes',
        'labs',
        'imaging',
        'eRx',
    ],
    provider: ['profile', 'Working Hours', 'Billing & Payments'],
}

export const rolesToiewFaxModule = [UserRoles.TM2U_ADMIN, UserRoles.TM2U_SUPER_ADMIN]
