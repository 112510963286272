import React, { useEffect } from 'react'
import CheckboxOption from '../reusable/CheckboxOptions'
import {
    Box,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'

const useStyles = makeStyles({
    slash: {
        fontSize: 22,
        color: '#516983',
        marginLeft: 5,
        marginRight: 5,
    },
    header: {
        textDecoration: 'underline',
        marginBottom: 8,
        fontWeight: '500',
        color: '#303E4E',
    },
    table: {
        borderTop: '1px solid #B2C0CF',
        borderLeft: '1px solid #B2C0CF',
        marginBottom: 24,
    },
    item: {
        height: 40,
        // width: '49%',
        paddingLeft: 16,
        flex: 1,
        flexBasis: '40%',
        borderRight: '1px solid #B2C0CF',
        borderBottom: '1px solid #B2C0CF',
    },
    checkbox: {
        padding: '0 16px',
        borderRight: '1px solid #B2C0CF',
    },
})

const Item = ({
    label,
    check,
    child,
    index,
    handleChange,
}) => {
    const classes = useStyles()
    return (
        <Grid className={classes.item}>
            {/* <Typography >{label}</Typography> */}
            <CheckboxOption
                name={child}
                option={{
                    label,
                    value: label,
                }}
                onChange={(e) => {
                    const { name, checked } = e.target
                    handleChange(name, checked, index)
                }}
                checked={check}
            />
        </Grid>
    )
}

const PatientEducationResources = ({
    master,
    values,
    onChange,
    fieldName,
}) => {
    const classes = useStyles()
    const handleChange = (name, checked, index) => {
        values[name][index].check = checked
        onChange(fieldName, values)
    }

    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Typography
                style={{
                    marginBottom: 16,
                    color: '#9CA9B8',
                    fontSize: 12,
                }}>
                Patient education repository maintained by
                AdminSuper Admin organized by specialty
            </Typography>
            <Typography className={classes.header}>
                Endocrinology:
            </Typography>
            <Grid container className={classes.table}>
                {values?.endocrinology?.map(
                    (item, index) => (
                        <Item
                            key={item.label}
                            {...item}
                            handleChange={handleChange}
                            child={'endocrinology'}
                            index={index}
                        />
                    ),
                )}
            </Grid>
            <Typography className={classes.header}>
                Rheumatology:
            </Typography>
            <Grid container className={classes.table}>
                {values?.rheumatology?.map(
                    (item, index) => (
                        <Item
                            key={item.label}
                            {...item}
                            handleChange={handleChange}
                            child={'rheumatology'}
                            index={index}
                        />
                    ),
                )}
            </Grid>
            <Typography className={classes.header}>
                Infectious Diseases:
            </Typography>
            <Grid container className={classes.table}>
                {values?.infectious_diseases?.map(
                    (item, index) => (
                        <Item
                            key={item.label}
                            {...item}
                            handleChange={handleChange}
                            child={'infectious_diseases'}
                            index={index}
                        />
                    ),
                )}
            </Grid>
        </Box>
    )
}

export default PatientEducationResources
