export const SET_LOADER = 'SET_LOADER'
export const SET_ROLE_LOADER = 'SET_ROLE_LOADER'
export const SET_CLINICAL_LIST = 'SET_CLINICAL_LIST'
export const SET_MASTER_DATA = 'SET_MASTER_DATA'
export const SET_CLINICAL_NOTES_DETAILS = 'SET_CLINICAL_NOTES_DETAILS'

export const SET_MEDICATION_HISTORY = 'SET_MEDICATION_HISTORY'
export const SET_ALLERGY_HISTORY = 'SET_ALLERGY_HISTORY'
export const SET_DIAGNOSIS_HISTORY = 'SET_DIAGNOSIS_HISTORY'

export const RESET_NOTE = 'RESET_NOTE'

export const SET_ICD_CODES = 'SET_ICD_CODES'

export const SET_MACRO_DATA = 'SET_MACRO_DATA'

export const SET_PROVIDER_DETAIL = 'SET_PROVIDER_DETAIL'
export const SET_LOGGED_IN_PROVIDER = 'SET_LOGGED_IN_PROVIDER'
export const SET_THEARPHY_MASTER = 'SET_THEARPHY_MASTER'
export const SET_DIRTY_SESSION = 'SET_DIRTY_SESSION'
export const SET_CLINICAL_NOTES_TEMPLATE = 'SET_CLINICAL_NOTES_TEMPLATE'
