import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CopyBar from 'components/shared/Elements/CopyBar'
import React, { useState } from 'react'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    sectionGridContainer: {
        margin: '6px 0',
    },
    textFieldWrap: {
        height: 41,
        width: 261,
    },
    header: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#303E4E',
    },
    textFieldWrapArea: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
        marginBottom: 24,
    },
})

const mapSections = (examMaster = [], property) => {
    return examMaster.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

const mapOptionssss = (masterData = []) => {
    return masterData.map((md, index) => {
        if (
            md.reviews_options.length > 0 &&
            !md.reviews_options.some((ro) => ro.type === 'other')
        ) {
            md.reviews_options.splice(md.reviews_options.length - 1, 0, { type: 'other' })
        }
        return {
            ...md,
        }
    })
}

const Exam = ({
    fieldName,
    master,
    values,
    onChange,
    lastNarrative,
    macros,
    generateAble,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = useState(0)

    const sections = mapSections(master?.examMasters, 'section_text')

    const sectionsTabs = Object.keys(sections).map((item, index) => ({
        key: item === 'undefined' ? 'Default' : item,
        label: item === 'undefined' ? 'Default' : item,
        _id: index,
    }))

    const handleInputChange = (e, se) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name] ? [...values[name]] : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [
                      ...updatedValue,
                      {
                          id: value,
                          desc: '',
                          label: se.name,
                      },
                  ]
                : updatedValue.filter((uv) => uv.id !== value)
        }

        if (type === 'text') {
            updatedValue = updatedValue.map((uv) =>
                uv.id === se._id ? { ...uv, desc: value } : uv,
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const handleSelectMacro = (value) => {
        if(nonEditable){return}
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Box>
            {/* <Grid item xs={12}>
                <CustomTabs
                    contents={sectionsTabs}
                    selectedTab={selectedTab}
                    onChangeTab={(e) => {
                        setSelectedTab(e)
                    }}
                />
            </Grid> */}
            {copyAble ? (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            ) : (
                <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            )}
            {Object.keys(sections).map((key, index) => {
                const sectionElements = sections[key]
                return (
                    <Box key={index}>
                        <Typography className={classes.header}>{key}</Typography>
                        <Grid container className={classes.sectionGridContainer}>
                            <Grid container>
                                {sectionElements
                                    .filter((se) => !se.is_free_text)
                                    .map((se, index) => {
                                        const filterEle = values[se.section_name]?.find(
                                            (sn) => sn.id === se._id,
                                        )
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    width: '33%',
                                                    // margin: '6px 0',
                                                }}>
                                                <CheckboxOption
                                                    name={se.section_name}
                                                    option={{
                                                        value: se._id,
                                                        label: `${se.name}${
                                                            se.is_free_text ? ':' : ''
                                                        }`,
                                                    }}
                                                    disabled={nonEditable}
                                                    onChange={(e) => {
                                                        handleInputChange(e, se)
                                                    }}
                                                    checked={filterEle?.id ? true : false}
                                                />
                                            </Box>
                                        )
                                    })}
                            </Grid>
                            <Grid container>
                                {sectionElements
                                    .filter((se) => se.is_free_text)
                                    .map((se, index) => {
                                        const filterEle = values[se.section_name]?.find(
                                            (sn) => sn.id === se._id,
                                        )
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    width: '50%',
                                                    margin: '6px 0',
                                                }}>
                                                <CheckboxOption
                                                    name={se.section_name}
                                                    option={{
                                                        value: se._id,
                                                        label: `${se.name}${
                                                            se.is_free_text ? ':' : ''
                                                        }`,
                                                    }}
                                                    disabled={nonEditable}
                                                    onChange={(e) => {
                                                        handleInputChange(e, se)
                                                    }}
                                                    checked={filterEle?.id ? true : false}
                                                />
                                                {se.is_free_text && (
                                                    <TextFieldDebounce
                                                        disabled={!filterEle?.id || nonEditable}
                                                        variant="outlined"
                                                        name={se.section_name}
                                                        className={classes.textFieldWrap}
                                                        size="small"
                                                        value={filterEle?.desc || ''}
                                                        onChange={(e) => {
                                                            handleInputChange(e, se)
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    })}
                            </Grid>
                        </Grid>
                    </Box>
                )
            })}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                    marginBottom: 16,
                }}>
                <TextFieldDebounce
                    variant="outlined"
                    disabled={nonEditable}
                    name={`${fieldName}.other`}
                    placeholder="Other"
                    className={classes.textFieldWrapArea}
                    size="small"
                    value={values.other}
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    multiline
                    minRows={4}
                />
            </Box>
            <NarrativeAndMacro
                hideGenerateBtn={!generateAble}
                hideTextNarrative={!generateAble}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: sections,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default Exam
