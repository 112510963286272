import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import moment from 'moment'
import 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import { SET_COUNTRY } from 'store/actionType'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiIconButton-root': {
            color: '#303e4e',
        },
    },
})

interface Props {
    placeholder?: string
    options?: {
        key: string | number
        value: string
    }[]
    onOpen?: () => void
    onClose?: () => void
    onInputChange?: (value: any) => void
    onChangeCustom?: (value: any) => void
    error?: boolean
    helperText?: string
    valueSelect?: {
        key: string | number
        value: string
    } | null
    height?: string
}

export const SelectSearchOptionsTimezone = (props: Props) => {
    const classes = useStyles()

    const {
        placeholder,
        options,
        onOpen,
        onClose,
        onInputChange,
        onChangeCustom = () => {},
        error,
        helperText,
        // valueSelect,
        height = '35px',
    } = props

    //Redux
    const valueSelect = useSelector((state) => state.timezones.tz)

    const dispatch = useDispatch()
    // console.log(optionS, 'sjdsjdhs')

    //State
    const [open, setOpen] = useState(false)
    const [optionsTimezone, setOptionsTimezone] = useState<{ key: string; value: string }[]>([])

    const [optionTZ, setOptionTZ] = useState([
        {
            utc: '-05:00',
            key: 'US/Eastern',
            value: '(UTC-05:00) US/Eastern',
            dst: 'EDT',
            acronym: 'EST',
            label: 'Eastern Time',
        },
        {
            utc: '-06:00',
            key: 'US/Central',
            value: '(UTC-06:00) US/Central',
            dst: 'CDT',
            acronym: 'CST',
            label: 'Central Time',
        },
        {
            utc: '-07:00',
            key: 'US/Mountain',
            value: '(UTC-07:00) US/Mountain',
            dst: 'MDT',
            acronym: 'MST',
            label: 'Mountain Time',
        },
        {
            utc: '-08:00',
            key: 'US/Pacific',
            value: '(UTC-08:00) US/Pacific',
            dst: 'PDT',
            acronym: 'PST',
            label: 'Pacific Time',
        },
        {
            utc: '-09:00',
            key: 'US/Alaska',
            value: '(UTC-09:00) US/Alaska',
            dst: 'AKDT',
            acronym: 'AKST',
            label: 'Alaskan Time',
        },
        {
            utc: '-10:00',
            key: 'US/Hawaii',
            value: '(UTC-10:00) US/Hawaii',
            dst: 'HDT',
            acronym: 'HAST',
            label: 'Hawaiian Time',
        },
    ])

    const onChange = (option) => {
        // setValueSelect(option)
        if (option) {
            dispatch({
                type: SET_COUNTRY,
                option: option,
            })
        }
    }

    // useEffect(() => {
    //     const getOptionsTimezone = moment.tz.names().map((tz) => ({
    //         key: tz,
    //         value: `(UTC${moment().tz(tz).format('Z')}) ${tz}`,
    //     }))
    //     setOptionsTimezone(getOptionsTimezone)
    // }, [])

    return (
        <Box
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: height,
                    minHeight: height,
                    padding: '5px 12px !important',
                },
                '& .MuiAutocomplete-paper': {
                    backgroundColor: '#fff !important',
                },
            }}>
            <Autocomplete
                className={classes['autocomplete']}
                loading={true}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                onClose={() => {
                    setOpen(false)
                    onClose?.()
                }}
                options={optionTZ || []}
                value={valueSelect || null}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>{option.label || ''}</span>
                        </li>
                    )
                }}
                popupIcon={<ExpandMoreIcon style={{ color: '#667689' }}/>}
                autoComplete
                getOptionLabel={(option) => option.label || ''}
                clearIcon={false}
                autoSelect
                disablePortal
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0px 36px 0px 0px',
                            },
                        }}
                        size="small"
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                    />
                )}
                onInputChange={(e, value) => {
                    // onInputChange?.(value)
                    // console.log(valueSelect, 'checkkkkkkkkk')
                }}
                onChange={(e, option) => {
                    console.log(option)
                    onChange?.(option)
                    onChangeCustom(option)
                }}
            />
        </Box>
    )
}
