import * as actionType from '../actionType'

const initialState = {
    organizational_units: [],
    security_roles: [],
    user_security_accounts: [],
    patient_accounts: [],
    scheduling: [],
    medical_notes: [],
    behavioral_health_notes: [],
    cpoe: [],
    revenue_cycle_management: [],
    roles: [],
    resource_management: [],
    super_bills: [],
    clinical_practice: [],
    service_location: [],
    status_code: [],
    specialty: [],
    clinical_notes: [],
    referring_providers: [],
    custom_proc_codes: [],
    health_plan: [],
    erx: [],
    referrals: [],
    isPermissionAvailable: false
}

const mapPermission = (value = [], name) => {
    const permissions = value.find((item) => item.resources === name)?.permissions || []

    return permissions.filter((item) => item.is_allow).map((item) => item.permission_name)
}

const permission = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PERMISSION:
            return {
                ...state,
                isPermissionAvailable: action.value?.isPermissionAvailable,
                organizational_units: mapPermission(action.value?.data, 'organizational_units'),
                security_roles: mapPermission(action.value?.data, 'security_roles'),
                user_security_accounts: mapPermission(action.value?.data, 'user_security_accounts'),
                patient_accounts: mapPermission(action.value?.data, 'patient_accounts'),
                scheduling: mapPermission(action.value?.data, 'scheduling'),
                medical_notes: mapPermission(action.value?.data, 'medical_notes'),
                behavioral_health_notes: mapPermission(
                    action.value?.data,
                    'behavioral_health_notes',
                ),
                cpoe: mapPermission(action.value?.data, 'cpoe'),
                revenue_cycle_management: mapPermission(
                    action.value?.data,
                    'revenue_cycle_management',
                ),
                resource_management: mapPermission(action.value?.data, 'resource_management'),
                super_bills: mapPermission(action.value?.data, 'super_bills'),
                clinical_practice: mapPermission(action.value?.data, 'clinical_practice'),
                service_location: mapPermission(action.value?.data, 'service_location'),
                status_code: mapPermission(action.value?.data, 'status_code'),
                specialty: mapPermission(action.value?.data, 'specialty'),
                clinical_notes: mapPermission(action.value?.data, 'clinical_notes'),
                referring_providers: mapPermission(action.value?.data, 'referring_providers'),
                custom_proc_codes: mapPermission(action.value?.data, 'custom_proc_codes'),
                health_plan: mapPermission(action.value?.data, 'health_plan'),
                erx: mapPermission(action.value?.data, 'erx'),
                referrals: mapPermission(action.value?.data, 'referrals')
            }
        case actionType.SET_ROLES:
            return {
                ...state,
                roles: action.value,
            }
        default:
            return state
    }
}

export default permission
