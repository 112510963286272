import {
    Box,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    TextField,
    Typography,
} from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import SubstanceAbuseHistory from 'containers/PatientsSetting/view/SubstanceAbuseHistory'
import React, { useEffect } from 'react'
import { apiSubstanceAbuseHistory } from 'services/Patient/apiSubstanceAbuseHistory'
import { useNotification } from 'shared/elements/Notification'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { SelectEl } from '../../../shared/elements'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import AntSwitch from '../../shared/Elements/AntSwitch'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import DataGridCustom from '../reusable/DataGridCustom'
import RadioOptions from '../reusable/RadioOption'


const howWasItTakenList = [
    { label: 'Oral', value: 'Oral' },
    { label: 'Nasal', value: 'Nasal' },
    { label: 'Inhaled', value: 'Inhaled' },
    { label: 'Injected', value: 'Injected' },
]

const useStyles = makeStyles({
    ellipsis_text: {
        // textOverflow: 'ellipsis',
        // overflow: 'hidden',
        whiteSpace: 'break-spaces',
        wordBreak: 'break-word',
    },
    headerTxt: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
        wordBreak: 'break-word',
        whiteSpace: 'break-spaces',
    },
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 40,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})

const SubstanceAbuseHx = ({
    fieldName,
    master,
    values,
    onChange,
    patientId,
    encounter_id,
    macros,
    nonEditable = false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable },
        { label: 'No', value: 'No' , disable: nonEditable},
    ]
    const classes = useStyles()
    const notification = useNotification()

    const options = master?.substanceAbuseMasters || []

    const defaultCustomOption = {
        name: '',
        is_used: false,
        how_opts: '',
        firstAge: '',
        lastAge: '',
        amountPerDay: '',
        daysPerMonth: '',
        isBelongMaster: false,
        addNew: true,
    }
    const defaultList = options.map((item, index) => ({
        id: index,
        name: item.name,
        is_used: false,
        how_opts: [],
        firstAge: '',
        lastAge: '',
        amountPerDay: '',
        daysPerMonth: '',
        isBelongMaster: true,
    }))

    const handleAddMore = (action, index) => {
        const val = values['substance_opts'] || []
        if (action === 'add') {
            const updatedValue = [...val, defaultCustomOption]
            onChange(`${fieldName}.substance_opts`, updatedValue)
        } else {
            const updatedValue = val.find((v, i) => i === index)
            if (updatedValue.isBelongMaster) {
                onChange(
                    `${fieldName}.substance_opts`,
                    val.map((v, i) =>
                        i === index
                            ? {
                                  id: index,
                                  name: v.name,
                                  is_used: false,
                                  how_opts: [],
                                  firstAge: '',
                                  lastAge: '',
                                  amountPerDay: '',
                                  daysPerMonth: '',
                                  isBelongMaster: true,
                              }
                            : v,
                    ),
                )
            } else {
                onChange(
                    `${fieldName}.substance_opts`,
                    val.filter((v, i) => i !== index),
                )
            }
        }
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box sx={{ display: 'flex' }}>
                <IconButton
                    variant="outlined"
                    onClick={() => setAlertDeleteOpen(true)}
                    // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const mapValues = (values = []) => {
        return values.map((v, index) => {
            const itemIndex = defaultList.find((_, i) => i === index) || []
            return {
                ...itemIndex,
                ...v,
                id: index,
            }
        })
    }

    const getOptions = (master = [], key) => {
        return master.map((im) => ({
            value: im[key],
            key: im._id,
        }))
    }

    const reasonOptions = getOptions(master?.primaryReasonForHospitalizationMasters, 'name')
    const treatmentOutcome = getOptions(master?.therapeuticEffect, 'name')

    const handleChange = (e, keyName, id) => {
        const { name, value } = e.target
        const val = values[name] || defaultList
        const updatedValue = val?.map((v, index) => (index === id ? { ...v, [keyName]: value } : v))
        onChange(`${fieldName}.${name}`, updatedValue)
    }
    const handleChangeHows = (e, keyName, id) => {
        const { name, value, checked } = e.target
        const val = values[name] || defaultList
        let updatedValue = []
        if (checked) {
            updatedValue = val?.map((v, index) =>
                index === id
                    ? {
                          ...v,
                          [keyName]: [...v.how_opts, value],
                      }
                    : v,
            )
        } else {
            updatedValue = val?.map((v, index) =>
                index === id
                    ? {
                          ...v,
                          [keyName]: [...(v.how_opts = v.how_opts.filter((e) => e !== value))],
                      }
                    : v,
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const handleChangeSwitch = (e, keyName, id) => {
        const { name, checked } = e.target
        const val = values[name] || defaultList
        const updatedValue = val?.map((v, index) =>
            index === id ? { ...v, [keyName]: checked } : v,
        )
        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const getSubstanceAbuseHistoryList = async () => {
        try {
            const resp = await apiSubstanceAbuseHistory.getAllbyPtientId(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data.data) {
                onChange(`${fieldName}.subtance_used`, resp.data.data)
            }
        } catch (ex) {
            notification('Cannot get list Substance Abuse History', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.is_recreational_drug) {
            getSubstanceAbuseHistoryList()
        }
    }, [patientId, values.is_recreational_drug])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                    width: '70%',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['is_recreational_drug']}
                </Typography>
                <RadioOptions
                    name={`${fieldName}.is_recreational_drug`}
                    options={radioOptions}
                    value={values['is_recreational_drug'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value === 'Yes')
                        if (value === 'No') {
                            onChange(`${fieldName}.subtance_used`, [])
                        }
                        onChange(`${fieldName}.narrative`, '')
                    }}
                />
            </Box>
            {values['is_recreational_drug'] && (
                <SubstanceAbuseHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getSubstanceAbuseHistoryList}
                    nonEditable={nonEditable}
                />
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SubstanceAbuseHx
