import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography, Box, Popover, IconButton, Dialog, ListItem } from '@material-ui/core'
import Divider from '@mui/material/Divider';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import AddIcon from '@mui/icons-material/Add'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ReactComponent as TrashIcon } from '../../../../assests/icons/trash.svg'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { ReactComponent as ReportIcon1 } from '../../../../assests/images/report_icon_1.svg'
import copyNew from '../../../../assests/icons/copyNew.svg'
import {
    deleteMedication,
    getListMedications,
    getListMedicationsType,
    getMedicationById,
    updateMedication,
} from 'services/Patient/patient'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import CustomTabs from 'components/shared/Elements/CustomTabs'
import MedicationsCreateDialog from '../Medication/MedicationCreateDialog'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { convertToFixedDate, stringConcatenation } from 'shared/utils'
import moment from 'moment'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextWithMacrosNew from 'components/ClinicalNotes/reusable/TextWithMacrosNew';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as MedicationgroupIcon } from '../../../../assests/images/medicationgroupIcon.svg'
import { ReactComponent as CapsuleIcon } from 'assests/icons/capsule.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Skeleton from '@mui/material/Skeleton';
import PreviousCN from 'components/ClinicalNotes/reusable/PreviousCN';
import { formatDateTime } from 'utilities/formats';
import AppsIcon from '@mui/icons-material/Apps';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { shortenString } from 'utilities/stringOperations';
import { ReactComponent as EditIcon } from 'assests/icons/edit1_icon.svg'
import { ReactComponent as ActiveToInactiveIcon } from 'assests/icons/activeToInactive.svg'
import { useNotification } from 'shared/elements/Notification';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { ReactComponent as MoreCircle } from 'assests/icons/More Circle.svg'
import { NoRowsOverlayEl } from 'shared/elements'
import Loading from 'shared/elements/Loading';

// const useStyles = makeStyles({
//     text_back: {
//         color: '#5571C6',
//         marginLeft: 8,
//     },
//     container: {
//         // position: 'absolute',
//         top: 0,
//         zIndex: 1,
//         left: 0,
//         background: 'white',
//         height: '100%',
//         padding: '16px 24px',
//         position: 'relative',
//     },
//     header: {
//         fontSize: 22,
//         fontWeight: 500,
//         color: '#303E4E',
//     },
//     notchedOutline: {
//         borderWidth: '1px',
//         borderColor: `$secondaryGray !important`,
//     },
//     btn_print: {
//         height: 32,
//         background: '#E9ECF1',
//         fontSize: 14,
//         borderRadius: 50,
//         fontWeight: 400,
//         padding: '4px 16px',
//         color: '#667689',
//         '&:hover': {
//             background: '#E9ECF1',
//         },
//     },
//     capitalize: {
//         textTransform: 'capitalize',
//     },
// })
const commonStyles = {
    wrap_data_grid: {
        height: 'calc(100vh - 265px)',
        border: '1px solid #c4c7c6',
    }
}
const useStyles = makeStyles({
    wrap_data_grid: {
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-row:hover': {
            background: 'inherit !important',
          },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            ...commonStyles.wrap_data_grid,
        },
        '& .MuiDataGrid-cell': {
            // border: 'none !important',
            // color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
            background: '#F8F9FB'
        },

        '& .MuiDataGrid-footerContainer': {
            display: 'none'
        },
        '& .MuiDataGrid-row': {
            margin: '4px 0',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
    form_list_container: {
        marginTop: 10,
        padding:'10px'
        // border: '1px solid #c4c7c6',
        // borderRadius: '5px',
        // backgroundColor: '#FAFDFC',
    },
    copy_button: {
        borderRadius: "4px",
        border: "1px solid #D1D1D1",
        background: "#FFF",
        fontSize: "12px",
        color: "#303E4E",
        fontWeight: "400",
        height: "26px"
    },
    MedicationCard: {
        width: "311px",
        // height: "155px",
        borderRadius: "5px",
        border: "1px solid #E4E2E2",
        margin:'0px 10px 10px 10px',
        // paddingLeft: "10px",
        // paddingRight: "10px",
        // paddingBottom: "15px",
        // paddingTop: "15px",
        cursor: "pointer"

    },

    cutText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "160px",
        height: "1.2em",
        whiteSpace: "nowrap",
    },

    itemCard: {
        width: 400,
        height: 500,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    TopBar:{
        borderRadius: '5px',
        border: '1px solid #E0E0E0',
        background: '#F8F9FB',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        padding:'10px'
    },
    toggleButton:{
        display: 'inline-flex',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
    },
    toggleOption:{
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s, color 0.3s',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Rubik',
        backgroundColor: 'white',
        color: '#333',
    },
    DividerText: {
        color: "var(--Light-Foreground-Foreground-2, #424242)",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
    },

    MedicationAllergyButton: {
        width: "133px",
        height: "28px",
        borderRadius: "5px",
        background: "#15A452",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        cursor: "pointer",
        marginLeft: "5px"
    },

    cardText: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        color: '#303E4E',
        fontWeight: '500',
        fontSize: 14,
        letterSpacing: 0,
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    boxContainer: {
        borderRadius: 4,
    },
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 5,
        width: '97%',

    },
    fullWidth: {
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: 4,
        width: 28,
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionHeading_instruction: {
        marginBottom: 16,
        fontSize: 12,
        color: '#9CA9B8',
    },

    PopOver: {
        width: "426px",
        height: "496px",
        padding:"20px",
        borderRadius: "5px",
        // border: "1px solid #DEE2E6",
        background: "#FFF",
        // boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        overflowY: "auto",
    },

    TopContainer: {
        maxWidth: "100%",
    },

    cardTextTypography: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
    },
    ellipsePopover:{
        '& .MuiPaper-root': {
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)'
        }
    }
})
const sectionsTabs = [
    {
        key: 234234,
        label: 'Active Medication Orders',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Inactive Medication Orders',
        _id: 2,
    },
]
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
//Card component sekeleton More
const CardComponent = ({ item, onEllipseOpen }) => {
    const classes = useStyles()
    return (
        <Box className={classes.MedicationCard} key={item.id}>
            <div style={{ 
                display: "flex", 
                alignItems: 'center', 
                borderBottom: '1px solid #e0e0e0', 
                justifyContent: 'space-between' ,
                padding:'12px',
                background:'#F8F9FB'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <CapsuleIcon />  
                    <Typography className={classes.cardText}>{capitalizeFirstLetter(shortenString(item?.drug?.display,28))}</Typography>
                </div>
                <div onClick={(event) => onEllipseOpen(event, item?.id)}>
                    <MoreVertIcon />
                </div>
            </div>
            <div style={{ 
                padding: '12px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 10,
            }}>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Strength: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400}}
                    >{shortenString(item?.strength,22)}</Typography>
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Form: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400}}
                    >{capitalizeFirstLetter(shortenString(item?.form,22))}</Typography>
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Directions: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400}}
                    >{shortenString(item?.direction_patient,22)}</Typography>
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Start: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400}}
                    >{item?.start_date ? moment(item?.start_date).format('MM/DD/YYYY') : '---'}</Typography>
                </div>
            </div>

        </Box>
    )
}

//Card component sekeleton
const CardComponentSkeleton = () => {
    const classes = useStyles()
    return (
        <Box className={classes.MedicationCard}>
            <div style={{ 
                display: "flex", 
                alignItems: 'center', 
                borderBottom: '1px solid #e0e0e0', 
                justifyContent: 'space-between' ,
                padding:'12px',
                background:'#F8F9FB'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <CapsuleIcon />  
                    <Typography className={classes.cardText}>
                        <Skeleton width={180} />
                    </Typography>
                </div>
                <div>
                    <MoreVertIcon />
                </div>
            </div>
            <div style={{ 
                padding: '12px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 10,
            }}>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Strength: </Typography>
                    <Typography
                        className={[classes.cardTextTypography]}
                    ><Skeleton width={58} /></Typography>
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Form: </Typography>
                    <Typography
                        className={[classes.cardTextTypography]}
                    ><Skeleton width={58} /></Typography>
                </div>
                <div style={{ display: "flex", gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Directions: </Typography>
                    <Typography
                        className={[classes.cardTextTypography]}
                    ><Skeleton width={58} /></Typography>
                </div>
                <div style={{ display: "flex", gap: 5}}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Start:</Typography>
                    <Typography
                        className={[classes.cardTextTypography]}
                    ><Skeleton width={58} /></Typography>
                </div>
            </div>
        </Box>
    )
}

const MedicationPatient = ({ formik, onChange, isInClinicalNote, patient_Id, encounter_id, afterActionOk, fieldName, values, lastNarrative,
    lastUpdateDate, nonEditable }) => {
    const classes = useStyles()
    const notification = useNotification()
    const history = useHistory()
    const { id } = useParams()
    const[active,setActive] = useState(false)
    const [viewMode,setViewMode] = useState('card')

    const [patientId, setPaitentId] = useState('')
    const [selectedTab, setSelectedTab] = useState(0)
    // const [page, setPage] = useState(1)
    const [bundleId, setBundleId] = useState('')

    const [appointmentModal, setAppointmentModal] = useState({
        status: false,
    })
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [medicationList, setMedicationList] = useState([])
    const [activeMedicationList, setActiveMedicationList] = useState([])
    const [inActiveMedicationList, setInActiveMedicationList] = useState([])
    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElOpen, setAnchorElOpen] = useState(null)
    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined
    const [medicationId, setMedicationId] = useState(null)
    const [medicationPatientId, setMedicationPatientId] = useState(null)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [itemId, setItemid] = useState(0)
    const [modalOpen, setModalOpen] = useState(false)
    const [openCopy, setOpenCopy] = useState(false)
    const [copied, setCopied] = useState(false)
    const onPageChange = (value) => {
        setPage(value)
    }

    const [ellipseAnchorEl, setEllipseAnchorEl] = useState(null)
    const ellipseOpen = Boolean(ellipseAnchorEl),
    ellipseEndId = ellipseOpen ? 'simple-popover' : undefined
    
    const [activationAnchorEl, setActivationAnchorEl] = useState(null)

    useEffect(() => {
        setPaitentId(patient_Id || id)
    }, [patient_Id, id])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setModalOpen(true)
    }

    const handleDelete = (event,medicationId) => {
        setAnchorElOpen(event.currentTarget)
        setMedicationId(medicationId)
    };

    const ModalOpen = () => {
        setOpenCopy(true)
    }
    const handleCloseModal = () => {
        setOpenCopy(false)
    }

    const handleDeleteServiceLine = async () => {
        try {
            onEllipseClose()
            setAnchorElOpen(null)
            setMedicationId(null)
            setLoading(true)
            deleteMedication(patient_Id, medicationId)
                .then(() => {
                    refetchStatus()
                    afterActionOk?.()
                })
            notification('A medication was successfully deleted!', 'success')
        } catch (error) {
            console.log('error')
            notification('A medication was not successfully deleted!', 'error')
        } finally {
            setAnchorElOpen(null)
            setMedicationId(null)
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setModalOpen(false)
    };
    const columns = [
        {
            headerName: 'Medication Name',
            headerClassName: 'super-app-theme--header',
            field: 'medication_name',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row}) => {
                return (
                    capitalizeFirstLetter(row?.drug?.display) || '-'
                )
            },
        },
        {
            headerName: 'Form',
            headerClassName: 'super-app-theme--header',
            field: 'form',
            flex: 0.3,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    capitalizeFirstLetter(value) || '-'
                )
            },
        },
        {
            headerName: 'Strength',
            headerClassName: 'super-app-theme--header',
            field: 'strength',
            flex: 0.3,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    capitalizeFirstLetter(value) || '-'
                )
            },
        },
        {
            headerName: 'Direction',
            headerClassName: 'super-app-theme--header',
            field: 'direction_patient',
            flex: 0.8,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Start date',
            headerClassName: 'super-app-theme--header',
            field: 'start_date',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return value ?  moment(value).format('MM/DD/YYYY') : '-'
            },
        },
        {
            headerName: 'End Date',
            headerClassName: 'super-app-theme--header',
            field: 'stop_date',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return value ? moment(value).format('MM/DD/YYYY') : '-'
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => 
                {
                    return(
                        <IconButton onClick={(event)=>{onEllipseOpen(event, row?.id)}}>
                            <MoreCircle/>
                        </IconButton>
                    )
                }
             ,
        },
    ]

    const refetchStatus = async () => {
        setLoading(true)
        try {
            const resp = await getListMedications(patientId, 1, 100000)
            // if (isInClinicalNote) return setMedicationList(resp.data.data)
            const medicationList = []
            if (isInClinicalNote) {
                resp.data.data.forEach((medication) => {
                    if (!moment(medication.stop_date).isSameOrBefore(moment(), 'day')) {
                        medicationList.push(medication)
                    }
                })
                return setMedicationList(medicationList)
            }
            const active = []
            const inactive = []

            resp.data.data.forEach((medication) => {
                if (moment(medication.stop_date).isSameOrBefore(moment(), 'day'))
                    return inactive.push(medication)

                active.push(medication)
            })
            setActiveMedicationList(active)
            setInActiveMedicationList(inactive)
            const active_medication = active.length > 0 ?
                 active.map((item)=>{
                    return{
                        medication_name: `${item?.drug?.brandName}, ${item?.drug?.genericName}`,
                        strength: item?.strength,
                        form: item?.form,
                        start_date:  item?.start_date ? formatDateTime(item?.start_date, 'MM/DD/YYYY') : "",
                        stop_date: item?.stop_date ? formatDateTime(item?.stop_date, 'MM/DD/YYYY') : ""
                    }
                 })
                 :
                 [
                    {
                      "medication_name": "",
                      "strength": "",
                      "form": "",
                      "frequency": "",
                      "unit": "",
                      "note": ""
                    }
                  ]
            // if (formik?.current?.values?.medications) {
            //     formik.current.values.medications.active_medications = [...active]
            //     formik.current.values.medications.inactive_medications = [...inactive]
            // }
            onChange(`${fieldName}.active_medications`, active_medication)
            onChange(`${fieldName}.inactive_medications`, inactive)

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
            setModalOpen(false)
        }
    }

    useEffect(() => {
        if (patientId) {
            refetchStatus()
        }
    }, [patientId])


    const changeActivationStatus = async() => {
        setEllipseAnchorEl(null)
        setActivationAnchorEl(null)
        setLoading(true)
        getMedicationById(patientId, medicationId)?.then(async (response) => {
            const payload = {
                ...response?.data,
                stop_date: convertToFixedDate(moment())
            }
            await updateMedication(patientId, medicationId, payload)
            setLoading(false)
            refetchStatus()
        })?.catch(err => {
            notification('Something went wrong!', 'error')
        })?.finally(() => {
            setLoading(false)
            setMedicationId(null)
        })
    }

    const completeAction = () => {
        onEllipseClose()
        setAnchorEl(null);
        afterActionOk?.()
        setItemid(0)
        setModalOpen(false)
    }

    

    // useEffect(() => {
    //     setPage(1)
    // }, [selectedTab])

    
    const getData = (e, item) => {
        setAnchorEl(e.currentTarget);
    }
    function myFunction() {
        const text = typeof lastNarrative === 'string' ? lastNarrative : null
        navigator.clipboard.writeText(text)
        if (text) {
            handleSelectMacro(text)
            setCopied(true)
        }
        handleCloseModal()
    }

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const onEllipseOpen = (event, itemId) => {
        setItemid(itemId)
        setEllipseAnchorEl(event?.currentTarget)
    }
    const onEllipseClose = () => {
        setEllipseAnchorEl(null)
        setItemid(0)
    }
    return (
            <Box className={classes.container} style={{ minHeight: "800px", marginTop:'30px',marginBottom:'30px' }}>
                <Box className={classes.TopBar}>
                    <Typography className={classes.HeadingColor}>Medication</Typography>
                    <div className={classes.toggleButton}>
                        <div className={classes.toggleOption} onClick={()=>{setActive(false)}} style={{background: !active ? '#5571C6' : '', color: !active ? '#fff' : '' }}>Active</div>
                        <div className={classes.toggleOption} onClick={()=>{setActive(true)}} style={{background: active ? '#5571C6' : '', color: active ? '#fff' : '' }}>Inactive</div>
                    </div>
                    <Box className={classes.MedicationAllergyButton} onClick={handleClick} >
                        <AddCircleOutlineIcon style={{
                            color: "#FFF",
                            fontSize: "large"
                        }} />
                        <Typography style={{
                            color: "#FFF",
                            textAlign: "center",
                            fontFamily: "Rubik",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "24px",
                        }}>Add Medication</Typography>
                    </Box>
                </Box>
                <div style={{display:'flex', gap:'10px', marginTop:'15px', marginLeft:'15px'}}>
                    <IconButton onClick={(e)=>{setViewMode('card')}}>
                        <AppsIcon style={{color:viewMode === 'card' ? "#5571C6" : "#D9D9D9"}}/>
                    </IconButton>
                    <IconButton onClick={(e)=>{setViewMode('table')}}>
                        <DehazeIcon style={{color:viewMode === 'table' ? "#5571C6" : "#D9D9D9"}}/>
                    </IconButton>
                </div>
                {viewMode === 'card' && <Box style={{ display: "flex", alignItems: `${activeMedicationList.length ? "center" : ""}`, flexWrap: "wrap", gap: "10px", marginTop:'15px',marginBottom:'8px'}}>
                    {!active && activeMedicationList.length > 0 && 
                    activeMedicationList.map((item, idx) => <Box style={{ 
                                                    display: "flex", 
                                                    flexWrap: "wrap", 
                                                    alignItems: "center", 
                                                    gap: "12px", 
                                                    // paddingTop: '12px',
                                                    overflowY: "auto" }}>
                    <CardComponent key={idx} item={item} onEllipseOpen={onEllipseOpen}/>
                                                </Box>)}
                    <Loading visible={isLoading} />
                    {/* {
                        isLoading && [1, 2, 3, 4, 5].map((id) => <CardComponentSkeleton key={id} />)

                    } */}
                    { active && inActiveMedicationList.length > 0 && 
                                        inActiveMedicationList.map((item, idx) => <Box style={{ 
                                                                        display: "flex", 
                                                                        flexWrap: "wrap", 
                                                                        alignItems: "center", 
                                                                        gap: "12px", 
                                                                        paddingTop: '12px',
                                                                        overflowY: "auto" }}>
                                        <CardComponent key={idx} item={item} onEllipseOpen={onEllipseOpen}/>
                                                                    </Box>)}
                </Box>}

                {viewMode === 'table' &&    <Grid item xs={12} className={`${classes.form_list_container}`}>
                        <Box className={classes.wrap_data_grid}>
                            <MuiDataGrid
                                className='custom_data_grid'
                                getRowId={(row) => row.id}
                                rows={!active ? activeMedicationList : inActiveMedicationList}
                                columns={columns}
                                paginationMode="server"
                                disableColumnSelector={true}
                                disableSelectionOnClick
                                loading={isLoading}
                                pagination={false}
                                hideFooterPagination={true}
                                components={{
                                    NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                                }}
                                disableColumnMenu={true}
                            />
                        </Box>
                    </Grid>
                } 
              
                <Box style={{ paddingLeft: "10px", paddingRight: "10px",marginTop:'15px' }}>
                    <TextWithMacrosNew
                        name={`${fieldName}.narrative`}
                        onChange={onChange}
                        // macros={macros}
                        rows={12}
                        withoutMacro={false}
                        values={values?.narrative}
                        socialHistory={true}
                    // placeholder={placeholder}
                    />
                </Box>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        style={{ marginLeft: `${itemId ? "5px" : "-5px"}` }}
                    >
                        <Box className={classes.PopOver}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "8px",
                                paddingTop: "12px",
                                marginTop: '8px'
                            }}>
                                <Typography style={{ fontSize: "24px", fontWeight: 500, color: 'black' }}className={classes.HeadingColor}>{itemId ? 'Update Medication' : 'Add Medication'}</Typography>
                            </Box>
                            <MedicationsCreateDialog
                                open={modalOpen}
                                itemId={itemId}
                                patientId={patientId}
                                encounter_id={encounter_id}
                                refetchData={refetchStatus}
                                afterActionOk={afterActionOk}
                                completeAction={completeAction}
                                handleClose={handleClose}
                            />
                        </Box>
                    </Dialog>

                        <CustomAlertPopOver
                                anchorEl={anchorElOpen}
                                handleClose={() => {
                                    setAnchorElOpen(null)
                                    setMedicationPatientId(null)
                                    setMedicationId(null)
                                }}
                                icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                                title="Medication Deletion"
                                bodyText={`Are you sure you want to delete this medication from the patient's record?`}
                                confirmButtonName={'Delete'}
                                cancelButtonName={'Cancel'}
                                confirmButtonColor={'red'}
                                onSuccess={handleDeleteServiceLine}
                                buttonProps={{
                                    styleConfirm: {
                                        textTransform: 'capitalize',
                                        fontWeight: "500",
                                        display: "flex",
                                        width: "68px",
                                        height: "32px",
                                        padding: "8px 20px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        fontSize: "15px",
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Rubik",
                                        fontStyle: "normal",
                                        lineHeight: "24px",
                                        ':hover': {
                                            boxShadow: "none"
                                        }
                                    },
                                    styleCancel: {
                                        display: "flex",
                                        height: "32px",
                                        padding: "6px 12px",
                                        alignItems: "center",
                                        textAlign: "center",
                                        fontFamily: "Rubik",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "20px",
                                        textTransform: 'none',
                                    }
                                }}
                    />

                    <CustomAlertPopOver
                        anchorEl={activationAnchorEl}
                        handleClose={() => {
                            setActivationAnchorEl(null)
                        }}
                        sx={{
                            '& .MuiButtonBase-root': {
                                borderRadius: '4px !important'
                            }
                        }}
                        icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                        title={`Move medication to inactive`}
                        bodyText={`Are you sure you want to make this medication  'inactive'?`}
                        confirmButtonName={'Yes'}
                        confirmButtonColor={'red'}
                        onSuccess={changeActivationStatus}
                        buttonProps={{
                            styleConfirm: {
                                textTransform: 'capitalize',
                                fontWeight: "500",
                                display: "flex",
                                width: "68px",
                                height: "32px",
                                padding: "8px 20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                fontSize: "15px",
                                color: "#FFF",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontStyle: "normal",
                                lineHeight: "24px",
                                ':hover': {
                                    boxShadow: "none"
                                },
                            },
                            styleCancel: {
                                display: "flex",
                                height: "32px",
                                padding: "6px 12px",
                                alignItems: "center",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "20px",
                                textTransform: 'none',
                            }
                        }}
                    />


                    <Popover
                        id={ellipseEndId}
                        open={ellipseOpen}
                        anchorEl={ellipseAnchorEl}
                        onClose={onEllipseClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        // style={{ marginLeft: `${styleCreate()}` }}
                        className={classes.ellipsePopover}
                    >
                        <Box style={{ padding: '12px 0px', display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={(event) => getData(event, itemId)}>
                                <EditIcon />
                                <Typography>Edit</Typography>
                            </ListItem>

                           {!active && <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={(event) => {setActivationAnchorEl(event?.currentTarget),setMedicationId(itemId)}}>
                                <ActiveToInactiveIcon />
                                <Typography>Move to inactive</Typography>
                            </ListItem>}

                            <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={(event)=>handleDelete(event,itemId)}
                                >
                                <TrashIcon />
                                <Typography>Remove</Typography>
                            </ListItem>
                        </Box>
                    </Popover>


                {openCopy && (
                    <PreviousCN
                        open={openCopy}
                        handleClose={handleCloseModal}
                        categoryName={'Medications'}
                        lastNarrative={lastNarrative}
                        lastUpdateDate={lastUpdateDate}
                        narrativeText={'Medications'}
                        handleChangecopy={myFunction}
                    />
                )}

            </Box >
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(MedicationPatient)
