import { blue, green, red, yellow } from '@material-ui/core/colors'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { grey } from '@mui/material/colors'
import { formatString } from 'containers/CalendarScheduler/utils'
import { ReactComponent as TargetIcon } from '../../../../assests/images/target_icon.svg'
import { ReactComponent as MinusCircleIcon } from '../../../../assests/images/minus-circle-white.svg'
import { ReactComponent as MapIcon } from '../../../../assests/icons/map-icon.svg'
import { ReactComponent as DollerIcon } from '../../../../assests/icons/doller.svg'
import './appointmentStatus.scss'

export const appointmentStatus = (statusName) => {
    if (statusName === 'booked') {
        return {
            nativeName: statusName,
            displayName: 'Confirmed',
            bgColor: green[500],
            icon: (
                <CheckIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'cancelled') {
        return {
            nativeName: statusName,
            displayName: 'Cancelled',
            bgColor: '#132C77',
            icon: (
                <CloseIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'noshow') {
        return {
            nativeName: statusName,
            displayName: 'No Show',
            bgColor: red[500],
            icon: (
                <CloseIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'waitlist') {
        return {
            nativeName: statusName,
            displayName: 'Rescheduled',
            bgColor: yellow[700],
            icon: (
                <PendingActionsIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'fulfilled') {
        return {
            nativeName: statusName,
            displayName: 'Completed',
            bgColor: blue[500],
            icon: (
                <TargetIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff', width: '12px', height: '10px' }}
                />
            ),
        }
    } 
    
    if (statusName === 'checked-in') {
        return {
            nativeName: statusName,
            displayName: 'In Session',
            bgColor: '#5571c6',
            icon: (
                <MinusCircleIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff', width: '12px', height: '12px' }}
                />
            ),
        }
    } 
    
    if (statusName === 'arrived') {
        return {
            nativeName: statusName,
            displayName: 'Arrived',
            bgColor: '#15a452',
            icon: (
                <MapIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'pending') {
        return {
            nativeName: statusName,
            displayName: 'Pending',
            bgColor: '#909EAE',
            icon: (
                <ErrorOutlineIcon
                className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    } 
    
    if (statusName === 'entered-in-error') {
        return {
            nativeName: statusName,
            displayName: 'Billing Hold',
            bgColor: '#101720',
            icon: (
                <DollerIcon
                    className='card_icon card_icon_small_font'
                    style={{ color: '#fff' }}
                />
            ),
        }
    }
    
    return {
        nativeName: statusName,
        displayName: formatString(statusName),
        bgColor: grey[500],
        icon: (
            <ErrorOutlineIcon
                className='card_icon card_icon_small_font'
                style={{ color: '#fff' }}
            />
        ),
    }
}
