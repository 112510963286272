import moment from 'moment'

export const INITIAL_VALUES = {
    drug: { code: '', display: '' },
    strength: '',
    form: '',
    patient_id: '',
    drugType: '',
    practitioner_id: '',
    schedule_drug: '',
    action: '',
    dose: '',
    dose_unit: '',
    route: '',
    frequency: '',
    other: '',
    direction_patient: '',
    day_supply: '',
    quantity: '',
    unit: '',
    refills: '',
    substitution: '',
    start_date: '',
    stop_date: '',
    last_written_date: '',
    direction_pharmacist: '',
    diagnosis_1: { code: '', display: '' },
    diagnosis_2: { code: '', display: '' },
}

export const diagnosisOptions = [
    {
        key: 'S00.02',
        value: 'S00.02 (ICD-10) Blister (nonthermal) of scalp, initial enco',
    },
    {
        key: 'D01.9',
        value: 'D01.9 (ICD-10) Carcinoma in situ of digestive organ, unspe',
    },
    {
        key: 'F30.4',
        value: 'F30.4 (ICD-10) Manic episode in full remission',
    },
    {
        key: 'A01.1',
        value: 'A01.1 (ICD-10) Paratyphoid fever A',
    },
]
