import React, { useState } from 'react'
import { ConfirmBox } from '../RouteLeavingGuard/ConfirmBox'
import { useModal } from 'context/ModalContext'
import { Typography } from '@material-ui/core'
const GlobalModal = () => {
    const { isModalOpen, modalData, closeModal } = useModal()
    const [showButtonLoader, setShowButtonLoader] = useState(false)

    return (
        <ConfirmBox
            title={modalData?.title}
            yes={modalData?.yes}
            no={modalData?.no}
            visible={isModalOpen}
            isLoading={showButtonLoader}
            onConfirm={async () => {
                setShowButtonLoader(() => true)
                await modalData?.onConfirm?.()
                setShowButtonLoader(() => false)
            }}
            onCancel={() => {
                closeModal()
                modalData?.onClose?.()
            }}
        >
            <Typography style={{ textAlign: 'center' }}>{modalData?.content}</Typography>
        </ConfirmBox>
    )
}

export default GlobalModal
