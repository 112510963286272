import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import {
    DataGrid as MuiDataGrid,
    DataGridProps,
    GridColumns,
    GridEventListener,
    GridInputSelectionModel,
    GridSortModel,
    GridValidRowModel,
    // useGridApiRef
} from '@mui/x-data-grid'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { NoRowsOverlayEl } from 'shared/elements'
import { Pagination } from 'shared/elements/Pagination'
import { Box } from '@mui/material'
import { PaginationIndex } from '../Pagination/PaginationIndex'

interface Props<DataType extends GridValidRowModel> {
    rows?: DataType[]
    page?: Number
    loading?: boolean
    totalRecords?: number
    total?: number
    pageSize?: number
    height?: number | string
    columns: GridColumns<DataType>
    selectionModel?: GridInputSelectionModel
    onCellClick?: GridEventListener<'cellClick'>
    onPageChange?: (page: number) => void
}

export const DataGrid = <DataType extends GridValidRowModel>(
    props: DataGridProps<DataType> & Props<DataType>,
) => {
    // const apiRef = useGridApiRef()

    const {
        loading,
        sortModel,
        pageSize,
        rows = [],
        columns,
        totalRecords = 0,
        total = 0,
        onCellClick,
        onPageChange,
        page,
        ...rest
    } = props
    return (
        <Grid item xs={12}>
            <Box
                sx={{
                    width: '100%',
                    '& .super-app-theme--header': {
                        border: 'none',
                        '& > .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#303E4E',
                            fontWeight: 600,
                            fontSize: '14px',
                        },
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        border: 'none',
                    },
                    '& .MuiCircularProgress-root': {
                        zIndex: 1,
                    },
                    '& .MuiPaginationItem-previousNext': {
                        padding: '0 22px',
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important',
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                        borderRadius: '5px',
                        background: '#667689',
                        color: 'white',
                        border: 'none',
                    },
                    '& .MuiDataGrid-root': {
                        border: 'none',
                        height: props.height || 'calc(100vh - 282px)',
                    },
                    '& .MuiDataGrid-cell': {
                        border: 'none !important',
                        color: '#667689',
                        // background: 'red'
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                        border: 'none',
                        outline: 'none !important',
                    },

                    '& .MuiDataGrid-footerContainer': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-row': {
                        background: '#FBFCFC',
                        margin: '4px 0',
                        borderRadius: '8px',
                        color: '#667689',
                        fontSize: '14px',
                        fontFamily: "'Rubik'",
                        fontStyle: 'normal',
                    },
                    '& .MuiDataGrid-row-unread': {
                        background: '#EEF1F4',
                        color: '#303E4E',
                        fontSize: '14px',
                        fontWeight: 500,
                        fontFamily: "'Rubik'",
                        fontStyle: 'normal',
                        lineHeight: '140%',
                        '& .MuiDataGrid-cell': {
                            '& .MuiTypography-body1': {
                                fontWeight: 500,
                            },
                        },
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                        {
                            width: 'auto',
                            visibility: 'visible',
                        },
                }}>
                <MuiDataGrid
                    // apiRef={apiRef}
                    sortModel={sortModel}
                    selectionModel={props.selectionModel}
                    getRowId={(row) => row.id}
                    rows={rows}
                    columns={columns}
                    // onRowClick={onRowClick}
                    onCellClick={onCellClick}
                    pageSize={pageSize}
                    paginationMode="server"
                    disableColumnSelector={true}
                    disableSelectionOnClick
                    loading={loading}
                    rowCount={totalRecords || total}
                    pagination
                    components={{
                        NoRowsOverlay: () => <NoRowsOverlayEl title="No results found." />,
                        Pagination: () =>
                            page ? (
                                <PaginationIndex
                                    page={page}
                                    totalRecords={totalRecords || rest.rowCount}
                                    onPageChange={onPageChange}
                                />
                            ) : (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={totalRecords || rest.rowCount}
                                    onPageChange={onPageChange}
                                />
                            ),
                        ColumnUnsortedIcon: TeleIconUpDownSortedList,
                        ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                        ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                    }}
                    disableColumnMenu={true}
                    getRowClassName={(params) => {
                        if (params.row.mark_read === false) {
                            return 'MuiDataGrid-row-unread'
                        }

                        return ''
                    }}
                    {...rest}
                />
            </Box>
        </Grid>
    )
}
