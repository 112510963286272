import { config } from '../../constants'
import { Surgical } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService
} from 'utilities/request/restClient'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'

const baseUrl = config.dev.baseURLV2

export const surgicalHistoryApis = {
  getAll(patientId: string, page: number = 1, limit: number = 10): { data: Surgical[], paging: Pagination } {
    return getService(
      `${baseUrl}/patient/${patientId}/surgical-history?page=${page}&limit=${limit}`,
    )
      .then(resp => resp.data.listSurgicalHistory)
  },
  getById(patientId: string,surgicalId: string): Surgical {
    return getService(
      `${baseUrl}/patient/${patientId}/surgical-history/${surgicalId}`
    )
      .then(resp => resp.data.surgicalHistory)
  },
  postSurgical(patientId: string, values: Surgical) {
    return postService(
      `${baseUrl}/patient/${patientId}/surgical-history`,
      values
    )
  },
  patchSurgical(patientId: string,values: Surgical, surgicalId: string) {
    return patchService(
      `${baseUrl}/patient/${patientId}/surgical-history/${surgicalId}`,
      values
    )
  },
  deleteSurgical(patientId: string,surgicalId: string) {
    return deleteService(
      `${baseUrl}/patient/${patientId}/surgical-history/${surgicalId}`
    )
  }
}
