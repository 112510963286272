import {
    getService,
    postService,
} from '../../utilities/request/restClient'
import { config } from '../../constants'
const otpServiceUrl = `${config.dev.baseURLV2}/account`

export const sendOtpService = (data) => {
    return postService(`${otpServiceUrl}/get-otp`, data)
}
export const verifyOtpService = (data) => {
    return postService(`${otpServiceUrl}/login`, data)
}

export const getAccountInfo = () => {
    return getService(`${otpServiceUrl}/me`)
}

export const verifyTokenService = (data) => {
    return getService(`/verifytoken`)
}

export const providerDetailById = (id) => {
    return getService(`/providers/findById/${id}`)
}

export const registerService = (data) => {
    return postService(`${otpServiceUrl}/signup`, data)
}
export const completeProfileService = (data) => {
    return postService(`/completeprofile`, data)
}
export const getDetailsService = (data) => {
    return postService(`/get-details`, data)
}
export const sendOtpToEmailService = (data) => {
    return postService(`/validate-Email`, data)
}
export const verifyQuestionsService = (data) => {
    return postService(`/validate-security-qes`, data)
}
export const getPatientProfileById = (userId) => {
    return getService(
        `${config.dev.baseURLV2}/patients/${userId}`,
    )
}

export const verifyEmail = (id) => {
    return getService(`/verify-email?id=${id}`)
}
