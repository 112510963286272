import { useState, useEffect, useRef } from 'react'
import {
    Button,
    Container,
    FormControl,
    makeStyles,
    Grid,
    MenuItem,
    Paper,
    Select,
    Typography,
    Theme,
    createStyles,
    Box,
} from '@material-ui/core'
import React from 'react'
import Divider from '@mui/material/Divider'
// import { ActivePane, useAppStateContext } from '../../AppStateProvider/AppStateProvider';
// import { SmallError } from '../../../icons/SmallError';
import { useCameraTest } from './hooks/useCameraTest/useCameraTest'
import useDevices from './hooks/useDevices/useDevicesForTest'
import VideoIcon from './assests/icons/video.svg'
import MicIcon from './assests/icons/mic.svg'
import VideoWhiteIcon from './assests/icons/white_video.svg'
import MicWhiteIcon from './assests/icons/white_mic.svg'
import ShapeIcon from './assests/icons/Shape.svg'
import CheckIcon from './assests/icons/check.svg'
import ChromeScrIcon from './assests/images/chrome_scr.png'
import EdgeScrIcon from './assests/images/edge_scr.png'
import FireFoxScrIcon from './assests/images/fire_fox_scr.png'
import SafariScr1Icon from './assests/images/safari_scr_1.png'
import SafariScr2Icon from './assests/images/safari_scr_2.png'
import Popper from '@mui/material/Popper'
import { IconMuteMic } from '../../../TwilioVideo/icon'
import { IconDisableVideo } from '../../../TwilioVideo/icon'
import CloseIcon from '@mui/icons-material/Close'
import useLocalAudioToggle from 'components/Consultation/hooks/useLocalAudioToggle/useLocalAudioToggle'
import useLocalVideoToggle from 'components/Consultation/hooks/useLocalVideoToggle/useLocalVideoToggle'
import { useHistory } from 'react-router-dom'
const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
            },
        },
        paper: {
            padding: '1em 0rem',
            borderRadius: '5px',
            maxWidth: '626px',
            // height: '372px',
            backgroundColor: '#303E4E',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
        videoContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        aspectRatioContainer: {
            position: 'relative',
            display: 'flex',
            width: '80%',
            padding: '1em',
            margin: '1em 0',
            height: '230px',
            '& video': {
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        header: {
            float: 'left',
            [theme.breakpoints.down('md')]: {
                float: 'initial',
            },
        },
        cameraAudioTest: {
            // float: 'right',
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                float: 'initial',
            },
        },
        videoTest: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            // float: 'left',
            marginRight: '1em',
            [theme.breakpoints.down('md')]: {
                float: 'initial',
                display: 'flex',
                justifyContent: 'center',
                margin: '0 0 2.5em 0',
                flexWrap: 'wrap',
            },
        },
        confirmationButtons: {
            clear: 'left',
            [theme.breakpoints.down('md')]: {
                clear: 'initial',
                marginBottom: '2em',
            },
        },
        error: {
            display: 'flex',
            alignItems: 'center',
            margin: '0.5em 0',
            '& svg': {
                marginRight: '0.3em',
            },
        },
        audioTitle: {
            display: 'flex',
            alignItems: 'center',
            color: '#fff',
            fontSize: '18px',
            fontWeight: 500,
            gap: 6,
        },
        selectInput: {
            borderRadius: '5px',
            border: '1px solid #CDCDCD',
            background: '#FBFCFC',
            width: '100%',
            [theme.breakpoints.between('xs', 'sm')]: {
                maxWidth: '250px',
            },
        },
        conTitle: {
            color: '#fff',
            fontSize: '25px',
            fontWeight: 500,
        },
        conTitleContainer: {
            padding: '10px 0px',
        },
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        continueButton: {
            borderRadius: '5px',
            border: '1px solid #1B9B52',
            color: '#D8D8D8',
            background: '#15A452',
            '&.Mui-disabled': {
                border: '1px solid #60B082',
                background: '#84BB9C',
            },
        },
        cameraAudioTestContainer: {
            // float: 'right',
            display: 'flex',
            gap: 50,
            width: '100%',
            marginTop: 10,
        },
        formListContainer: {
            // float: 'right',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
        boxIconContainer: {
            width: '74px',
            height: '44px',
            backgroundColor: '#fff',
            borderRadius: '5px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        gridIconContainer: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            gap: 5,
        },
        instrucTitle: {
            color: '#fff',
            fontSize: '17px',
            fontWeight: 400,
        },
        instrucContainer: {
            display: 'inline-flex',
            gap: 10,
        },
        selectContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: 5,
        },
        errorContainer: {
            background: '#667689CC',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            padding: '0.5em 0em',
        },
        numberSpan: {
            height: '14px',
            width: '14px',
            background: '#5571C6',
            borderRadius: '50%',
            color: '#fff',
            textAlign: 'center',
            fontSize: '10px',
        },
        refreshButton: {
            borderRadius: '5px',
            color: '#D8D8D8',
        },
    }),
)


function getBrowserName(userAgent) {
    if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
        return 'Opera'
    } else if (userAgent.indexOf('Edg') !== -1) {
        return 'Edge'
    } else if (userAgent.indexOf('Chrome') !== -1) {
        return 'Chrome'
    } else if (userAgent.indexOf('Safari') !== -1) {
        return 'Safari'
    } else if (userAgent.indexOf('Firefox') !== -1) {
        return 'Firefox'
    } else if (userAgent.indexOf('MSIE') !== -1 || !!document?.documentMode === true) {
        //IF IE > 10
        return 'IE'
    }
    return 'Unknown'

}
export function CameraTest({ mediaError, openLoading }) {
    const history = useHistory()
    const classes = useStyles()
    const { videoElementRef, startVideoTest, stopVideoTest, videoTest, videoTestError } =
        useCameraTest()
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
    const { videoInputDevices, audioInputDevices } = useDevices({ isVideoEnabled })
    const prevVideoDeviceID = useRef('')
    const [videoInputDeviceID, setVideoInputDeviceID] = useState('')
    const [selectedAudioDevice, setSelectedDevice] = useState('')
    const [open, setOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const navigator = window.navigator
    const userAgent = navigator.userAgent
    const browserName = getBrowserName(userAgent)
    let browser = browserName
    if (browser === 'Edg') {
        browser = 'Edge'
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen((previousOpen) => !previousOpen)
    }
    const setDevice = (deviceID) => {
        setVideoInputDeviceID(deviceID)
    }
    const updateSelectedDevice = React.useCallback(
        (value) => {
            setSelectedDevice(value)
            //   setDeviceError('');
        },
        [setSelectedDevice],
    )
    useEffect(() => {
        const hasSelectedDevice = audioInputDevices.some(
            (device) => device.deviceId === selectedAudioDevice,
        )
        if (audioInputDevices.length && !hasSelectedDevice) {
            updateSelectedDevice(audioInputDevices[0].deviceId)
        }
    }, [audioInputDevices, selectedAudioDevice])
    useEffect(() => {
        // Stop the test when we are not on the CameraTest pane and there is an active test
        if (videoTest && !isVideoEnabled) {
            stopVideoTest()
            prevVideoDeviceID.current = ''
        }
    }, [stopVideoTest, videoTest, isVideoEnabled])

    useEffect(() => {
        // Start the test when we are on the CameraTest pane and when the videoInputDeviceID changes
        if (isVideoEnabled) {
            const newDeviceSelected = prevVideoDeviceID.current !== videoInputDeviceID
            prevVideoDeviceID.current = videoInputDeviceID

            if (videoInputDeviceID && newDeviceSelected) {
                startVideoTest(videoInputDeviceID)
            }
            if (videoTestError) {
                stopVideoTest()
            }
        }
    }, [videoInputDeviceID, startVideoTest, stopVideoTest, videoTestError, isVideoEnabled])

    useEffect(() => {
        // If no device is select, set the first available device as the active device.
        const hasSelectedDevice = videoInputDevices.some(
            (device) => device.deviceId === videoInputDeviceID,
        )
        if (videoInputDevices.length && !hasSelectedDevice) {
            setVideoInputDeviceID(videoInputDevices[0].deviceId)
        }
    }, [videoInputDevices, videoInputDeviceID])
    const handleClose = () => {
        setAnchorEl(null)
        setOpen(false)
    }
    return (
        <Container>
            <div className={classes.mainContainer}>
                <Grid item lg={6} className={classes.videoTest}>
                    <Box className={classes.conTitleContainer}>
                        <Typography variant="subtitle2" className={classes.conTitle}>
                            <strong>Test your connection!</strong>
                        </Typography>
                    </Box>

                    <Paper className={classes.paper}>
                        {mediaError && (
                            <Box className={classes.errorContainer}>
                                <img src={ShapeIcon} alt="TeleMed2U" />
                                <Typography variant="subtitle2" className={classes.instrucTitle}>
                                    <p style={{ cursor: 'pointer', fontSize: '16px' }}>
                                        Audio/video not connected. Please troubleshoot your browser.
                                    </p>
                                </Typography>
                            </Box>
                        )}

                        <Grid container direction="column" alignItems="center">
                            <div
                                className={classes.aspectRatioContainer}
                                style={{ marginTop: !mediaError ? '44px' : '' }}>
                                {!mediaError && (
                                    <video autoPlay playsInline ref={videoElementRef} />
                                )}
                            </div>
                            {videoTestError && (
                                <div className={classes.error}>
                                    <Typography variant="subtitle2" color="error">
                                        Unable to connect.
                                    </Typography>
                                </div>
                            )}
                        </Grid>
                        <Grid className={classes.gridIconContainer}>
                            <Box className={classes.boxIconContainer} onClick={toggleVideoEnabled}>
                                {isVideoEnabled ? (
                                    <img src={VideoIcon} alt="TeleMed2U" />
                                ) : (
                                    <IconDisableVideo />
                                )}
                            </Box>
                            <Box className={classes.boxIconContainer} onClick={toggleAudioEnabled}>
                                {isAudioEnabled ? (
                                    <img src={MicIcon} alt="TeleMed2U" />
                                ) : (
                                    <IconMuteMic />
                                )}
                            </Box>
                        </Grid>
                    </Paper>
                    <Box className={classes.instrucContainer}>
                        <img src={ShapeIcon} alt="TeleMed2U" />
                        <Typography variant="subtitle2" className={classes.instrucTitle}>
                            <p
                                style={{ cursor: 'pointer' }}
                                onClick={(event) => handleClick(event)}>
                                Need help trouble shooting your browser? {browser}
                            </p>
                        </Typography>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            classes={classes}
                            browser={browser}
                            handleClose={() => handleClose()}
                        />
                    </Box>
                </Grid>
                <Grid item lg={6} className={classes.cameraAudioTest}>
                    <Grid item className={classes.cameraAudioTestContainer} direction="column">
                        <Grid md={12} sm={12} xs={12} className={classes.formListContainer}>
                            <Typography variant="subtitle2" className={classes.audioTitle}>
                                <img src={MicWhiteIcon} alt="TeleMed2U" />
                                <strong>Audio Settings</strong>
                            </Typography>
                            <Grid item md={12} sm={12} xs={12} className={classes.selectContainer}>
                                <Grid md={10} sm={12} xs={12}>
                                    <Select
                                        value={selectedAudioDevice}
                                        onChange={(e) =>
                                            updateSelectedDevice(e.target.value)
                                        }
                                        className={classes.selectInput}
                                        disableUnderline
                                        disabled={!!mediaError}>
                                        {audioInputDevices.map((device) => (
                                            <MenuItem value={device.deviceId} key={device.deviceId}>
                                                {device.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {!mediaError && selectedAudioDevice && (
                                    <Grid md={2}>
                                        <img src={CheckIcon} width={22} alt="TeleMed2U" />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid md={12} sm={12} xs={12} className={classes.formListContainer}>
                            <Typography variant="subtitle2" className={classes.audioTitle}>
                                <img src={VideoWhiteIcon} alt="TeleMed2U" />
                                <strong>Video Settings</strong>
                            </Typography>
                            <Grid item md={12} sm={12} xs={12} className={classes.selectContainer}>
                                <Grid md={10} sm={12} xs={12}>
                                    <Select
                                        onChange={(e) => setDevice(e.target.value)}
                                        value={videoInputDeviceID}
                                        variant="outlined"
                                        disabled={!!videoTestError || !!mediaError}
                                        disableUnderline
                                        className={classes.selectInput}>
                                        {videoInputDevices.map((device) => (
                                            <MenuItem value={device.deviceId} key={device.deviceId}>
                                                {device.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {!mediaError && videoInputDeviceID && (
                                    <Grid md={2}>
                                        <img src={CheckIcon} width={22} alt="TeleMed2U" />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid md={10} sm={12} xs={12} className={classes.formListContainer}>
                            <Divider
                                sx={{
                                    height: '2px !important',
                                    background: '#fff !important',
                                }}
                            />
                            <Box className={classes.buttonContainer}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={() => openLoading(false)}
                                    // onClick={() => history.push('/schedule')}
                                    href='/schedule'
                                    className={classes.continueButton}
                                    disabled={
                                        !!videoTestError ||
                                        !!mediaError ||
                                        !videoInputDeviceID ||
                                        !selectedAudioDevice
                                    }>
                                    Continue
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
}
const Popover = (props) => {
    return (
        <Popper open={props?.open} anchorEl={props?.anchorEl} placement="right-end">
            <Box sx={{ borderRadius: '5px', bgcolor: 'background.paper' }}>
                <Grid
                    md={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        padding: '10px 10px 0px 0px',
                    }}>
                    <CloseIcon
                        style={{ fontSize: '16px', cursor: 'pointer' }}
                        onClick={props?.handleClose}
                    />
                </Grid>
                {props?.browser === 'Chrome' && <ChromeBrowser classes={props?.classes} />}
                {props?.browser === 'Edge' && <EdgeBrowser classes={props?.classes} />}
                {props?.browser === 'Safari' && <SafariBrowser classes={props?.classes} />}
                {props?.browser === 'Firefox' && <FireFoxBrowser classes={props?.classes} />}
            </Box>
        </Popper>
    )
}

const ChromeBrowser = (props) => {
    return (
        <Grid
            item
            container
            direction="column"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                padding: '18px',
            }}>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>1</span>
                <p>Click on the camera icon on the right side of your search bar.</p>
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>2</span>
                <p>Give access to your browser.</p>
            </Grid>
            <Grid md={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <img src={ChromeScrIcon} alt="TeleMed2U" />
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>3</span>
                <p>Please refresh the page.</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    className={props?.classes.refreshButton}>
                    Reload
                </Button>
            </Grid>
        </Grid>
    )
}

const EdgeBrowser = (props) => {
    return (
        <Grid
            item
            container
            direction="column"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                padding: '18px',
            }}>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>1</span>
                <p>Click on the camera icon on the right side of your search bar.</p>
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>2</span>
                <p>Give access to your browser.</p>
            </Grid>
            <Grid md={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <img src={EdgeScrIcon} alt="TeleMed2U" />
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>3</span>
                <p>Please refresh the page.</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    className={props?.classes.refreshButton}>
                    Reload
                </Button>
            </Grid>
        </Grid>
    )
}

const SafariBrowser = (props) => {
    return (
        <Grid
            item
            container
            direction="column"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                padding: '18px',
            }}>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>1</span>
                <p>Click on the Safari tab on the top left menu bar.</p>
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>2</span>
                <p>Select “Settings for Telehealth.telemed2u.com”</p>
            </Grid>
            <Grid md={12} style={{ display: 'flex', justifyContent: 'start' }}>
                <img src={SafariScr1Icon} alt="TeleMed2U" height={200} />
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>3</span>
                <p>Select “Settings for Telehealth.telemed2u.com”</p>
            </Grid>
            <Grid md={12} style={{ display: 'flex', justifyContent: 'end' }}>
                <img src={SafariScr2Icon} alt="TeleMed2U" height={200} />
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>4</span>
                <p>Please refresh the page.</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    className={props?.classes.refreshButton}>
                    Reload
                </Button>
            </Grid>
        </Grid>
    )
}

const FireFoxBrowser = (props) => {
    return (
        <Grid
            item
            container
            direction="column"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
                padding: '18px',
            }}>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>1</span>
                <p>Click on the camera icon on the left side of your search bar.</p>
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>2</span>
                <p>Give access to your browser.</p>
            </Grid>
            <Grid md={12}>
                <img src={FireFoxScrIcon} alt="TeleMed2U" />
            </Grid>
            <Grid
                md={12}
                style={{
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                }}>
                <span className={props?.classes.numberSpan}>3</span>
                <p>Please refresh the page.</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    className={props?.classes.refreshButton}>
                    Reload
                </Button>
            </Grid>
        </Grid>
    )
}
