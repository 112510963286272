import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import palette from 'styles/themes/palette'

export const StyledButton = styled(MuiButton)({
  backgroundColor: palette.accent.dark,
  textTransform: 'none',
  color: palette.common.white,
  '&:hover': {
    backgroundColor: palette.accent.dark
  }
})