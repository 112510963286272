import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, makeStyles, Typography } from '@material-ui/core'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Grid } from '@mui/material'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
        textTransform: 'none',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        fontSize: 15,
        borderRadius: 25,
        width: '100%',
    },
}))

interface Props {
    open: boolean
    loading: boolean
    title?: string
    description?: string
    btnOkText?: string
    onOke?: () => void
    onClose?: () => void
}

const AlertEmtyForm = ({ open, loading, title, description, btnOkText, ...props }: Props) => {
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={() => props.onClose?.()}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>{title || '-'}</Typography>
            </DialogTitle>

            {/* <DialogContent>
                <DialogContentText>
                    <Typography className={classes.description} component={'div'}>
                        {description || 'Are you sure to delete this item?'}
                    </Typography>
                </DialogContentText>
            </DialogContent> */}

            <Grid container justifyContent={'center'}>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        className={classes.button_cancel}
                        onClick={() => props.onClose?.()}>
                        {btnOkText || 'Ok'}
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export { AlertEmtyForm }

export default AlertEmtyForm
