import { Box, Grid } from '@material-ui/core'
import moment from 'moment'
import { VIEW } from '../constant'

const MonthHeaderCellContent = (props) => {
    // const isCurrentDay =
    //     moment().format('ddd') === moment(props.date).format('ddd') &&
    //     props.selectedView.date.isSame(moment(), VIEW.MONTH)

    return (
        <Grid container direction="column">
            {/* {isCurrentDay && (
                <Grid item className="currentDay">
                    <Box
                        sx={{
                            height: 10,
                            width: '100%',
                            backgroundColor: '#5571C6',
                            padding: 0,
                        }}
                    ></Box>
                </Grid>
            )} */}
            <Grid item>
                <Box
                    sx={{
                        padding: 20,
                        fontSize: '16px',
                        fontWeight: 500,
                    }}>
                    {props.label}
                </Box>
            </Grid>
        </Grid>
    )
}

export default MonthHeaderCellContent
