/* eslint-disable default-case */
import {
    getService,
    postService,
    putService,
    deleteService,
} from '../../utilities/request/restClient'
// import { config } from '../../constants';
import { getLoggedInUserRole } from '../../utilities'
import { UserRoles } from '../../constants/user-roles'
import { config } from '../../constants'
import { isEmpty, reject } from 'lodash'

export const getProviderDetail = (id) => {
    return getService(`/providers/details/${id}`)
}

export const getProviderList = (speciality, visitType, patient, clinicId) => {
    return getService(
        `/providers/search-provider?speciality=${speciality}&patient=${patient}&appointmentType=${visitType}&clinic=${clinicId}`,
    )
}

export const getCalendarDates = (payload) => {
    return postService(`/providers/calender-dates`, payload)
}

export const getCalendarSlots = (payload) => {
    return postService(`/providers/calender-slots`, payload)
}

export const getProviderSearchList = (payload) => {
    const userRole = getLoggedInUserRole()

    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
    }
    return getService(`${config.dev.baseURLV2}/${userType}/provider?start=0&limit=10`)
    // return postService(`${providerBaseUrl}${userType}/provider-list`, {
    //     limit: 10,
    //     ...payload,
    // })
}

export const updateProviderStatus = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    const { userId, status } = payload

    return postService(`/${userType}/provider/change-status`, {
        user_id: userId,
        is_active: status,
    })
}

export const getProviderProfile = (id) => {
    // let url = ''
    // const userRole = getLoggedInUserRole()

    // switch (userRole) {
    //     case UserRoles.TM2U_SUPER_ADMIN:
    //         userType = 'super-admin'
    //         url = ``
    //         break
    //     default:
    //         url = `${config.dev.baseURLV2}/get-provider-detail/${id}`
    //         break
    // }

    return getService(`${config.dev.baseURLV2}/super-admin/get-provider-detail/${id}`).then(
        (response) => {
            return {
                data: {
                    ...response.data.provider[0],
                    education: JSON.parse(response.data.provider[0].education),
                    training: JSON.parse(response.data.provider[0].training),
                    in_network_insurances: JSON.parse(
                        response.data.provider[0].in_network_insurances,
                    ),
                    speciality: response.data.provider[0].speciality_id,
                    certification: [],
                    user: {
                        ...response.data.provider[0].user,
                        language: [response.data.provider[0].user.language],
                    },
                },
            }
        },
    )
}

export const getProviderSpecialityList = () => {
    //getService(`/speciality`)
    return Promise.resolve({
        data: {
            status: 200,
            data: [
                {
                    id: 1,
                    name: 'Dermatology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 2,
                    name: 'Endocrinology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 3,
                    name: 'Otolaryngology (ENT)',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 4,
                    name: 'Gastroenterology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 5,
                    name: 'Infectious Diseases',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 6,
                    name: 'Nephrology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 7,
                    name: 'Neurology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 8,
                    name: 'Nutrition',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 9,
                    name: 'Orthopedics',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 10,
                    name: 'Pain Management',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 11,
                    name: 'Psychiatry',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 12,
                    name: 'Psychology',
                    type: 'behavioral',
                    is_special: false,
                },
                {
                    id: 13,
                    name: 'Pulmonology',
                    type: 'behavioral',
                    is_special: false,
                },
                {
                    id: 14,
                    name: 'Rheumatology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 15,
                    name: 'Urology',
                    type: 'non-behavioral',
                    is_special: false,
                },
                {
                    id: 17,
                    name: 'Psychiatrist',
                    type: 'behavioral',
                    is_special: false,
                },
                {
                    id: 18,
                    name: 'Psychotherapy',
                    type: 'therapy',
                    is_special: false,
                },
            ],
        },
    })
}

export const getProviderClientList = () => {
    //getService(`/clinic`)
    return Promise.resolve({
        data: [
            {
                id: 1,
                clinic_name: 'Inland Endocrine',
                clinic_type: 'owned',
                add1: '9471 Haven Ave',
                add2: 'Suite 140',
                zip: 91730,
                city: 'Rancho Cucamonga',
                state: 'CA',
                mobile: '9232932393',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 2,
                clinic_name: 'Specialty Clinic of Austin - North',
                clinic_type: 'owned',
                add1: '2217 Park Bend Drive',
                add2: 'Suite 300',
                zip: 78758,
                city: 'Austin',
                state: 'TX',
                mobile: '9232932393',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 3,
                clinic_name: 'Specialty Clinic of Austin - South',
                clinic_type: 'owned',
                add1: '5625 Eiger Road',
                add2: 'Suite 215',
                zip: 78735,
                city: 'Austin',
                state: 'TX',
                mobile: '8234672462',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 4,
                clinic_name: 'Clearlake Health Center',
                clinic_type: 'contracted',
                add1: '1631 30th Street',
                add2: '',
                zip: 93301,
                city: 'Bakersfield ',
                state: 'CA',
                mobile: '8234672462',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 5,
                clinic_name: 'El Dorado Community Health Center',
                clinic_type: 'contracted',
                add1: '4327 Golden Center Dr.',
                add2: '',
                zip: 95667,
                city: 'Placerville',
                state: 'CA',
                mobile: '8234672462',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 6,
                clinic_name: 'Open Door Community Health Center – Eureka',
                clinic_type: 'contracted',
                add1: '2200 Tydd Street',
                add2: '',
                zip: 95501,
                city: 'Eureka',
                state: 'CA',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 7,
                clinic_name: 'Open Door Community Health Center – Eureka',
                clinic_type: 'contracted',
                add1: '550 E Washington Blvd.',
                add2: 'Suite 100',
                zip: 95531,
                city: 'Del Norte',
                state: 'CA',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 8,
                clinic_name: 'Alexander Valley',
                clinic_type: 'contracted',
                add1: '6 Tarman Dr',
                add2: '',
                zip: 95425,
                city: 'Cloverdale',
                state: 'CA',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 9,
                clinic_name: 'Manipall',
                clinic_type: 'contracted',
                add1: 'Blore',
                add2: '',
                zip: 99999,
                city: 'blore',
                state: 'West Virginia',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 10,
                clinic_name: 'Apollo network clinic',
                clinic_type: 'owned',
                add1: 'nyc',
                add2: '',
                zip: 90010,
                city: 'cal',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 11,
                clinic_name: 'test',
                clinic_type: 'owned',
                add1: 'test',
                add2: 'test2',
                zip: 90010,
                city: 'cal',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 12,
                clinic_name: 'third party clinic',
                clinic_type: 'contracted',
                add1: 'houston',
                add2: '',
                zip: 23454,
                city: 'houston',
                state: 'Georgia',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 13,
                clinic_name: 'Axon clinc',
                clinic_type: 'contracted',
                add1: 'stanford',
                add2: '',
                zip: 43533,
                city: 'newyork',
                state: 'New York',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 14,
                clinic_name: 'TEstActiveClinic',
                clinic_type: 'owned',
                add1: 'testt',
                add2: 'test',
                zip: 41234,
                city: 'bagnalore',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 15,
                clinic_name: 'ManipalPune',
                clinic_type: 'contracted',
                add1: 'pune',
                add2: 'steet naame',
                zip: 41231,
                city: 'pune',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 16,
                clinic_name: 'ALclinic',
                clinic_type: 'contracted',
                add1: 'AD1',
                add2: '',
                zip: 12345,
                city: 'Citie',
                state: 'Indiana',
                mobile: '',
                is_active: true,
                created_at: '2022-01-19T08:13:53.094Z',
                updated_at: '2022-01-19T08:13:53.818Z',
            },
            {
                id: 17,
                clinic_name: 'OBRegTwentyOne',
                clinic_type: 'contracted',
                add1: 'Tests',
                add2: 'Tests',
                zip: 21312,
                city: 'Tests',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-03-10T11:23:39.497Z',
                updated_at: '2022-03-10T11:25:53.000Z',
            },
            {
                id: 18,
                clinic_name: 'OBTA RegTOne',
                clinic_type: 'contracted',
                add1: 'Test',
                add2: 'Test',
                zip: 31212,
                city: 'Test',
                state: 'California',
                mobile: '',
                is_active: true,
                created_at: '2022-03-10T12:20:04.197Z',
                updated_at: '2022-03-10T12:20:04.197Z',
            },
        ],
    })
}

export const getActiveProviderList = () => {
    return getService(`/providers/active-list`)
}

export const getTimeoffsList = (id) => {
    return getService(`/time-off/list/${id}`)
}
export const getTimeoffsListStatus = (id, status) => {
    const query = status ? `status=${status}` : ''
    return getService(`/time-off/list/${id}?${query}`)
}
export const commonMasterData = () => {
    //postService(`/common/get-masters`)
    return Promise.resolve({
        data: {
            race: [
                { label: 'White', value: 'white' },
                {
                    label: 'Black or African American',
                    value: 'black_or_african_america',
                },
                {
                    label: 'American Indian or Alaskan Native',
                    value: 'american_indian_or_alaska_native',
                },
                { label: 'Asian', value: 'asian' },
                {
                    label: 'Native Hawaiian or Pacific Islander',
                    value: 'native_hawaiin_or_other_pacific_islander',
                },
                { label: 'Other', value: 'other_race' },
                {
                    label: 'Declined to specify',
                    value: 'declined_to_specify',
                },
            ],
            ethinicity: [
                { label: 'Hispanic', value: 'hispanic' },
                {
                    label: 'Non Hispanic',
                    value: 'non_hispanic',
                },
                {
                    label: 'Declined to specify',
                    value: 'declined_to_specify',
                },
                {
                    label: 'Other',
                    value: 'other_ethinicity',
                },
            ],
            relation: [
                { label: 'Father', value: 'father' },
                { label: 'Mother', value: 'mother' },
                { label: 'Husband', value: 'Husband' },
                { label: 'Wife', value: 'wife' },
                { label: 'Daughter', value: 'daughter' },
                { label: 'Son', value: 'son' },
                { label: 'Relative', value: 'relative' },
            ],
            language: [
                { label: 'English', value: 'en' },
                { label: 'Spanish', value: 'es' },
                { label: 'German', value: 'de' },
            ],
            employment_type: [
                { label: 'Full Time', value: 'fulltime' },
                { label: 'Part Time', value: 'parttime' },
            ],
            in_network_insurance: [
                {
                    label: 'Tuft Health Freedom plan',
                    value: 'tuft_health_freedom_plan',
                },
                {
                    label: 'Freedom Plan',
                    value: 'freedom_plan',
                },
                { label: 'TM2U Plan', value: 'tm2u_plan' },
                {
                    label: 'United HealthCate',
                    value: 'united_healthCate',
                },
                {
                    label: 'Bluc Cross BludShield',
                    value: 'bluc_cross_bludShield',
                },
                {
                    label: 'Emblem Health',
                    value: 'emblem_health',
                },
            ],
            time_zone: [
                {
                    label: 'Atlantic Standard Time (AST)',
                    value: 'America/Montserrat',
                },
                {
                    label: 'Eastern Standard Time (EST)',
                    value: 'America/New_York',
                },
                {
                    label: 'Central Standard Time (CST)',
                    value: 'America/Chicago',
                },
                {
                    label: 'Mountain Standard Time (MST)',
                    value: 'America/Phoenix',
                },
                {
                    label: 'Pacific Standard Time (PST)',
                    value: 'America/Los_Angeles',
                },
                {
                    label: 'Alaskan Standard Time (AKST)',
                    value: 'America/Anchorage',
                },
                {
                    label: 'Hawaii-Aleutian Standard Time (HST)',
                    value: 'Pacific/Johnston',
                },
                {
                    label: 'Samoa standard Time (UTC-11)',
                    value: 'Pacific/Pago_Pago',
                },
                {
                    label: 'Chamorro Standard Time (UTC+10)',
                    value: 'Pacific/Guam',
                },
            ],
            document_types: [
                {
                    label: 'Inbound Refrral',
                    value: 'inbound_refrral',
                },
                {
                    label: 'Outbound Refrral',
                    value: 'outbound_refrral',
                },
                {
                    label: 'Medical Record',
                    value: 'medical_record',
                },
                {
                    label: 'Medical Records Request',
                    value: 'medical_records_request',
                },
                { label: 'Imaging', value: 'imaging' },
                {
                    label: 'Lab Result',
                    value: 'lab_result',
                },
                {
                    label: 'Prescription',
                    value: 'prescription',
                },
                {
                    label: 'RX Pre-Auth',
                    value: 'rX_pre_auth',
                },
                {
                    label: 'Insuranace Card',
                    value: 'insurance_card',
                },
                {
                    label: 'Eligibility',
                    value: 'eligibility',
                },
                {
                    label: 'Clinical Note',
                    value: 'clinical_note',
                },
                {
                    label: 'Education / Informational Material',
                    value: 'education_informational_material',
                },
                { label: 'Amendment', value: 'amendment' },
                {
                    label: 'Ketamine / SPRAVATO Monitoring',
                    value: 'ketamine_spravato_monitoring',
                },
                {
                    label: 'Disavility/FMLA',
                    value: 'disavility_fmla',
                },
                {
                    label: 'Cambridge Result',
                    value: 'cambridge_cesult',
                },
                {
                    label: 'Prior Auth',
                    value: 'prior_auth',
                },
                {
                    label: 'RCM Report',
                    value: 'rcm_report',
                },
                {
                    label: 'Accommodation Letter',
                    value: 'accommodation_letter',
                },
                { label: 'Other', value: 'other' },
            ],
            admin_queue_reasons: [
                {
                    label: 'Speciality',
                    value: 'speciality',
                },
                {
                    label: 'Cancellation',
                    value: 'cancellation',
                },
                { label: 'TimeOff', value: 'timeoff' },
            ],
        },
    })
}

export const updateTimeoffsStatus = (row_id, status, comments) => {
    const payload = { comments }
    return postService(`/time-off/${status}/${row_id}`, payload)
}
export const saveProviderProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }

    return postService(`${config.dev.baseURLV2}/${userType}/add-provider`, payload)
}

export const editProviderProfile = (payload) => {
    let userType = ''
    const userRole = getLoggedInUserRole()

    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
    }
    const personaUrl =
        userRole === UserRoles.PROVIDER
            ? `${config.dev.baseURL}/providers/edit-profile`
            : `${config.dev.baseURL}/${userType}/edit-provider`
    return postService(personaUrl, payload)
}

export const getEncounterQueueListAPI = (params = {}) => {
    return getService(`${config.dev.baseURLV2}/telehealth/encounter`, params)
}

export const changeEncounterStatus = (payload) => {
    const finalPayload = {
        ...payload,
        is_delete_note: payload?.is_delete_note ? payload.is_delete_note : false
    }

    return postService(`${config.dev.baseURLV2}/telehealth/encounter/change-status`, finalPayload)
}

export const getTokenVideoProvider = (payload) => {
    return postService(`${config.dev.baseURLV2}/telehealth/allow-provider`, payload)
}

export const postAdmitUrl = (encounter_id, params) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounter_id}/exam/participant/allow`,
        params,
    )
}

export const postEndRoomUrl = (encounter_id, params) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounter_id}/exam/participant/kick`,
        params,
    )
}

export const postKickParticipantUrl = (payload) => {
    return postService(`${config.dev.baseURLV2}/telehealth/kick-participant`, payload)
}

export const addParticipantService = (payload) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${payload?.encounter_id}/exam/participant`,
        payload,
    )
}

export const postStopConsultation = () => {
    return putService(`${config.dev.baseURLV2}/telehealth/consultation`)
}

export const getAssistantPractitionerAPI = () => {
    return getService(`${config.dev.baseURLV2}/telehealth/AssistantPractitioner`)
}

export const assignConsultationToOtherProviderAPI = (payload) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${payload.encounterId}/assign`,
        {
            assigned_provider_id: payload.assignedProviderId,
            message: payload.message,
        },
    )
}

// Translation serviec
export const getTokenTranslationService = (encounter_id, payload = '') => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounter_id}/exam/translation`,
        payload,
    )
}

// get socket ben patient
export const apiStopTranslation = (encounter_id, payload = '') => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounter_id}/exam/translation-stop`,
        payload,
    )
}

// exam

export const examAllowPatientJoinApi = (encounterId) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/patient/allow`,
        {},
    )
}

export const examLeaveApi = (encounterId) => {
    return deleteService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/practitioner`,
    )
}

export const examAllowParticipantJoinApi = (encounterId) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/participant/allow`,
        {},
    )
}

export const examPractitionerJoinApi = (encounterId) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/practitioner`,
        {},
    )
}

export const examEndApi = (encounterId, sessionData) => {
    return postService(`${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/end`, sessionData)
}

export const examAddParticipantToJoinApi = (encounterId, payload) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/participant`,
        payload,
    )
}

export const examKickParticipantApi = (encounterId, payload) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${encounterId}/exam/participant/kick`,
        payload,
    )
}

export const examAssignEncounterToAssistantApi = (payload) => {
    return postService(
        `${config.dev.baseURLV2}/telehealth/encounter/${payload.encounterId}/exam/assign`,
        {
            assigned_provider_id: payload.assignedProviderId,
        },
    )
}

export const requestPutAvatarUrl = () => {
    return getService(`${config.dev.baseURLV2}/practitioner/upload-avatar`)
}
export const requestPostBrandingBackGround = (payload) => {
    return postService(`${config.dev.baseURLV2}/upload`, payload)
}
export const requestPostBhFileUpload = (payload) => {
    return postService(`${config.dev.baseURLV2}/upload/attachment-url`, payload)
}
export const createBhScale = (payload) => {
    return postService(`${config.dev.baseURLV2}/upload/file/entry`, payload)
}
export const getCariumSSOLink = (payload) => {
    return getService(`${config.dev.baseURLV2}/patient/carium-sso`)
}
export const requestPreviewBrandingBackGround = (payload) => {
    return postService(`${config.dev.baseURLV2}/preview`, payload)
}
export const addBrandingBackGround = (payload) => {
    return postService(`${config.dev.baseURLV2}/setting`, payload)
}
export const getListOfBrandingBackGround = (payload = {}) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    return getService(`${config.dev.baseURLV2}/setting?${queryString}`)
}
export const deleteOfBrandingBackGround = (id) => {
    return deleteService(`${config.dev.baseURLV2}/setting/${id}`)
}
export const getDefaultBackgroundImage = (payload) => {
    return postService(`${config.dev.baseURLV2}/setting/default`, payload)
}
export const requestGetAvatarUrl = (id) => {
    return getService(`${config.dev.baseURLV2}/practitioner/avatar/${id}`)
}

export const forgetPassword = (email) => {
    // return postService(
    //   `${config.dev.baseURLV2}/practitioner/forgetPassword`,
    //   {
    //     email
    //   }
    // )

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({})
        }, 500)
    })
}

export function searchProvider(payload) {
    const newPayload = {
        ...payload,
        isAllProvider: true,
    }
    const queryString = new URLSearchParams(newPayload).toString()
    return getService(`${config.dev.baseURLV2}/practitioner?${queryString}`)
}
