import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

import { useHistory, useLocation } from 'react-router-dom'

import InComingFaxTable from './components/InComingFaxTable'
import OutGoingFaxTable from './components/OutGoingFaxTable'
import InComingMessageActiveIcon from 'assests/icons/InComingMessageActiveIcon'
import InComingMessageIcon from 'assests/icons/InComingMessageIcon'
import OutGoingMessageIcon from 'assests/icons/OutGoingMessageIcon'
import OutGoingMessageActiveIcon from 'assests/icons/OutGoingMessageActiveIcon'

import AssignToProviderModal from './components/AssignToProviderModal'
import SuccessModal from 'components/SuccessModal'
import Routes from 'constants/routes'
import { useFaxMe } from './hooks'
import { FaxPageHeader } from './shared/FaxPageHeader'

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    tab: {
        height: 30,
        marginRight: 20,
        textTransform: 'none',
        fontFamily: "'Rubik'",
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '140%',
        textAlign: 'center',
        color: '#667689',
    },
})

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3, padding: '24px 0' }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

const defaultSearchCriteria = {
    textSearch: null,
    showArchived: null,
}

const Fax = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const { faxMe } = useFaxMe()

    const [
        openAssignToProviderModal,
        setOpenAssignToProviderModal,
    ] = useState(false)
    const [
        isAssignedToProviderSuccess,
        setIsAssignedToProviderSuccess,
    ] = useState(false)

    const [searchCriteria, setSearchCriteria] = useState(
        defaultSearchCriteria,
    )

    const [shouldRefreshFaxList, setShouldRefreshFaxList] =
        useState(0)

    const [currentTab, setActiveTab] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])

    const handleCellClick = (params) => {
        // setIsShowFaxDetailDialog(true)
        history.push(
            Routes.EFax.DETAILS.replace(
                ':id',
                params?.row.message_id,
            ),
        )
    }

    const onSelectedItemsChanged = (items) => {
        setSelectedItems(items)
    }

    useEffect(() => {
        // Guard fax type, incoming | outgoing
        if (location.pathname.endsWith('outgoing'))
            setActiveTab(1)
        else if (location.pathname.endsWith('incoming'))
            setActiveTab(0)
        else return history.replace(Routes.EFax.INCOMING)
    }, [location])

    useEffect(() => {
        if (currentTab === 0)
            window.history.replaceState(
                {},
                '',
                Routes.EFax.INCOMING,
            )
        else if (currentTab === 1)
            window.history.replaceState(
                {},
                '',
                Routes.EFax.OUTGOING,
            )
    }, [currentTab, location])

    return (
        <Grid container className={classes.container}>
            <FaxPageHeader
                title="Fax History Log"
                onClickAddNew={() =>
                    history.push(Routes.EFax.NEW_FAX)
                }
                onSearch={(text) =>
                    setSearchCriteria({
                        ...searchCriteria,
                        textSearch: text,
                    })
                }
            />

            <Grid item xs={12}>
                <Tabs
                    onChange={(e, value) =>
                        setActiveTab(value)
                    }
                    value={currentTab}
                    aria-label="Tabs where selection follows focus"
                    selectionFollowsFocus>
                    <Tab
                        icon={
                            currentTab === 0 ? (
                                <InComingMessageActiveIcon />
                            ) : (
                                <InComingMessageIcon />
                            )
                        }
                        iconPosition="start"
                        className={classes.tab}
                        label="Incoming"
                        {...a11yProps(0)}
                    />
                    <Tab
                        icon={
                            currentTab === 1 ? (
                                <OutGoingMessageActiveIcon />
                            ) : (
                                <OutGoingMessageIcon />
                            )
                        }
                        iconPosition="start"
                        label="Outgoing"
                        {...a11yProps(1)}
                        className={classes.tab}
                    />
                </Tabs>
                <TabPanel value={currentTab} index={0}>
                    <InComingFaxTable
                        searchCriteria={searchCriteria}
                        onCellClick={handleCellClick}
                        me={faxMe}
                        // onRowClick={handleOnRowClick}
                        onSelectedItemsChanged={
                            onSelectedItemsChanged
                        }
                        shouldRefreshFaxList={
                            shouldRefreshFaxList
                        }
                        onAssignToProvider={() =>
                            setOpenAssignToProviderModal(
                                true,
                            )
                        }
                    />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    <OutGoingFaxTable
                        searchCriteria={searchCriteria}
                        onCellClick={handleCellClick}
                        me={faxMe}
                        onSelectedItemsChanged={
                            onSelectedItemsChanged
                        }
                        shouldRefreshFaxList={
                            shouldRefreshFaxList
                        }
                        onAssignToProvider={() =>
                            setOpenAssignToProviderModal(
                                true,
                            )
                        }
                    />
                </TabPanel>
            </Grid>

            <AssignToProviderModal
                open={openAssignToProviderModal}
                onModalClose={() => {
                    setOpenAssignToProviderModal(false)
                }}
                onSubmitSuccess={() => {
                    setOpenAssignToProviderModal(false)
                    setIsAssignedToProviderSuccess(true)
                    setShouldRefreshFaxList(
                        shouldRefreshFaxList + 1,
                    )
                }}
                selectedItems={selectedItems}
            />
            <SuccessModal
                isOpen={isAssignedToProviderSuccess}
                closeModal={() => {
                    setIsAssignedToProviderSuccess(false)
                }}
                title={'Completed'}
                message={'Fax has been assigned'}
                closeButtonText={'OK'}
            />
        </Grid>
    )
}
export default Fax
