import { makeStyles } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'
import CloseIcon from '@mui/icons-material/Close'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import Chip from '@mui/material/Chip'
import './style.scss'

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiIconButton-root': {
            color: '#303e4e',
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
            padding: '7.5px 36px 7.5px 6px',
        },
        '& .MuiOutlinedInput-root': {
            maxHeight: '50px !important',
            overflow: 'hidden !important',
        },
    },
})
const removeDuplicates = (array, property) => {
    // Create an empty object to store unique values based on the specified property
    const uniqueValues = {}

    // Use the filter method to create a new array with unique objects
    const uniqueArray = array.filter((obj) => {
        // Extract the property value of the current object
        const value = obj[property]
        // Check if the property value is already present in the uniqueValues object
        // If not present, add it to the uniqueValues object and return true
        if (!uniqueValues[value]) {
            uniqueValues[value] = true
            return true
        }
        // If the property value is already present, return false to filter out the duplicate object
        return false
    })

    return uniqueArray
}
export const SelectSearchInput = (props) => {
    const classes = useStyles()

    const {
        placeholder,
        options,
        onOpen,
        onClose,
        onInputChange,
        onChange,
        error,
        helperText,
        loading,
        ...rest
    } = props
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [value, setValue] = useState([])
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const handlePopoverOpen = (event) => {
        event.preventDefault()
        setIsPopoverOpen(true)
    }
    const popTargetRef = React.useRef(null)
    const handlePopoverClose = (event) => {
        event.preventDefault()
        setIsPopoverOpen(false)
    }
    const handleChange = (event, newValue) => {
        event.preventDefault()
        if (newValue?.length > 0 || props?.value?.length === 1) {
            const uniqueArray = removeDuplicates(newValue, 'key')
            setValue(uniqueArray)
            onChange?.(uniqueArray)
        }
    }
    const handleTagClick = (event, index) => {
        event.preventDefault()
        const updatedTags = [...value]
        updatedTags.splice(index, 1)
        onChange?.(updatedTags)
        setValue(updatedTags)
        setIsPopoverOpen(updatedTags.length > 4)
    }

    return (
        <Box>
            <Autocomplete
                className={classes['autocomplete']}
                loading={loading}
                noOptionsText={!loading && search ? 'No results found' : undefined}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                onClose={() => {
                    setOpen(false)
                    onClose?.()
                }}
                options={options}
                // value={props?.value}
                multiple={props?.multiple ? true : false}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>
                                {option.value} {option.custom_id ? ' - ' + option.custom_id : ''}
                            </span>
                        </li>
                    )
                }}
                autoComplete
                getOptionLabel={(option) => option.value}
                clearIcon={false}
                limitTags={4}
                // autoSelect
                disablePortal
                renderInput={(params) => {
                    return (
                        <div style={{ position: 'relative' }}>
                            <TextField
                                {...params}
                                placeholder={placeholder}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        padding: props?.multiple && '0px 2px !important',
                                    },
                                    '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        padding: '0px 36px 0px 0px',
                                    },
                                }}
                                size="small"
                                variant="outlined"
                                error={error}
                                helperText={helperText}
                            />
                            {props?.multiple && value?.length > 4 && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '36px',
                                        transform: 'translateY(-50%)',
                                    }}
                                    onClick={handlePopoverOpen}
                                    ref={popTargetRef}>
                                    <IconButton
                                        size="small"
                                        edge="end"
                                        style={{ marginRight: '-8px' }}>
                                        <MoreHoriz />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    )
                }}
                onInputChange={(e, value) => {
                    setSearch(value)
                    onInputChange?.(value)
                }}
                onChange={(e, option) => {
                    props?.multiple ? handleChange?.(e, option) : onChange?.(option?.key)
                }}
                // renderTags={(value, getTagProps) =>
                //     value?.length < 5 &&
                //     value.map((option, index) => (
                //         <Chip
                //             label={option.value}
                //             {...getTagProps({ index })}
                //             key={index}
                //             // disabled
                //         />
                //     ))
                // }
                {...rest}
            />
            <Popover
                open={isPopoverOpen}
                anchorEl={popTargetRef.current}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top', // Always open on top of the Chip
                    horizontal: 'right', // Always open on the right side of the Chip
                }}
                transformOrigin={{
                    vertical: 'bottom', // Align with the top of the Chip
                    horizontal: 'right', // Align with the left of the Chip
                }}>
                <div
                    style={{
                        padding: '10px',
                        maxWidth: '350px',
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                    {value?.map((tag, index) => (
                        <div className="chip-popover" key={index}>
                            <span style={{ color: '#212529', fontSize: '14px' }}>{tag.value}</span>
                            <IconButton
                                size="small"
                                onClick={(event) => handleTagClick(event, index)}
                                style={{ color: '#989CA8' }}>
                                <CloseIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                        </div>
                    ))}
                </div>
            </Popover>
        </Box>
    )
}
