import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getProviderDetail } from '../../services/Provider/provider'
import { Box } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'
import {
    getClinicalNoteInitialData,
    getClinicalNotesDetail,
    getICDCodes,
    _getClinicalNotesDetail,
    getConfigureTemplate,
} from './api/services'
import Behavioral from './Behavioral'
import Medical from './Medical'
import { ClinicalNoteContext } from './context/ClinicalNoteContext'
import {
    SET_ALLERGY_HISTORY,
    SET_CLINICAL_NOTES_DETAILS,
    SET_CLINICAL_NOTES_TEMPLATE,
    SET_ICD_CODES,
    SET_LOADER,
    SET_LOGGED_IN_PROVIDER,
    SET_MASTER_DATA,
    SET_MEDICATION_HISTORY,
    SET_PROVIDER_DETAIL,
} from './store/actionTypes'
import { setCurrentNote } from 'store/actions/clinicalNotes'
import { clinical_note_master } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { setEncounterClinicalNoteLoaded } from '../../store/actions/provider'
import LoadingPage from '../../utilities/loading-page'
import { getMacroList, getPractitionerMacroList } from '../../store/actions/macros'
import { addOrUpdateVisitedPage } from './ClinicalNotesWrapper'
const ClinicalNotesForView = ({ nodeDetailData, isConsultant }) => {
    const params = useParams()
    const userData = useSelector((state) => state?.permission.clinical_notes)
    const viewOrEdit =
        userData.includes('update_medical_clinical_notes') && params.mode === 'edit'
            ? 'edit'
            : 'view'
    const reduxDispatch = useDispatch()
    const provider_id = localStorage.getItem('userId')
    const [configureTemplateDetails, setConfigureTemplateDetails] = useState({})
    const [isMode, setIsMode] = useState(viewOrEdit)
    const [noteDetails, setNoteDetails] = useState(() => {
        const storedClinicalNotes = localStorage.getItem('visitedClinicalNotes')
        let isSame = false
        const visitedClinicalNotes = storedClinicalNotes && JSON.parse(storedClinicalNotes)
        isSame =
            visitedClinicalNotes &&
            visitedClinicalNotes?.includes(Buffer.from(params.id, 'base64').toString('ascii'))
        setIsMode(isSame ? 'view' : viewOrEdit)
        if (_.isEmpty(nodeDetailData)) {
            return {
                id: Buffer.from(params.id, 'base64').toString('ascii'),
                name: isSame ? 'view' : viewOrEdit,
            }
        }
        return nodeDetailData
    })
    useEffect(() => {
        if (_.isEmpty(nodeDetailData)) {
            setNoteDetails({
                id: Buffer.from(params.id, 'base64').toString('ascii'),
                name: viewOrEdit,
            })
        } else {
            setNoteDetails(nodeDetailData)
        }
    }, [nodeDetailData, params?.id])

    useEffect(() => {
        if (noteDetails?.id) reduxDispatch(setCurrentNote(noteDetails?.id))
        return () => {
            reduxDispatch(setCurrentNote(''))
        }
    }, [noteDetails?.id])

    useEffect(() => {
        reduxDispatch(
            getMacroList({
                page: 1,
                size: 100000,
            }),
        )
    }, [])
    useEffect(() => {
        reduxDispatch(getPractitionerMacroList(provider_id, { page: 1, limit: 10000 }))
    }, [])

    useEffect(() => {
        if (!userData || userData?.length === 0) {
            setLoader(false)
        } else {
            setLoader(true)
        }
    }, [userData])

    const [providerType, setProviderType] = useState('')
    const [clinicalNoteData, setClinicalNoteData] = useState([])
    const [providerDecision, setProviderDecesion] = useState(false)

    const { state, dispatch } = React.useContext(ClinicalNoteContext)

    useEffect(() => {
        fetchClinicalNote()
        fetchMasterData()
    }, [noteDetails?.id])
    /**
     * Clinical Note Store for Restriction
     */
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (isMode === 'edit') {
                addOrUpdateVisitedPage(noteDetails?.id)
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [isMode])
    useEffect(() => {
        if (noteDetails?.id && isMode === 'edit') addOrUpdateVisitedPage(noteDetails?.id)
        return () => {
            if (isMode === 'edit') {
                addOrUpdateVisitedPage(noteDetails?.id)
            }
        }
    }, [noteDetails?.id, isMode])
    //End Functionlity for clinical note restriction
    const [clinicalNoteInitialData, setClinicalNoteInitialData] = useState([])

    const fetchClinicalNote = async (updateStatus= '') => {
        try {
            setLoader(true)
            const clinicalNotesDetail = await _getClinicalNotesDetail(noteDetails?.id,viewOrEdit)
            // const configureTemplate = await getConfigureTemplate(noteDetails.id)
            setNoteDetails({
                ...noteDetails,
                ...clinicalNotesDetail.data,
            })

            if (_.isString(clinicalNotesDetail?.data?.data)) {
                const dataAttribute = JSON.parse(clinicalNotesDetail?.data?.data)

                const configureTemplate = dataAttribute?.clinicalNoteTemplate,
                    initData = dataAttribute?.clinicalNoteModel

                setConfigureTemplateDetails(configureTemplate)

                if (noteDetails?.templateId) setClinicalNoteInitialData(initData)

                const applyConfigureTemplate =
                    dataAttribute?.clinicalNoteTemplate.components.map((item, i) => {
                        const session = configureTemplate?.data?.components?.find(
                            (temp, index) => temp.componentId === item.componentId,
                        )
                        if (session?.status) {
                            delete session.status
                        }
                        if (session?.header) {
                            delete session.header
                        }
                        return session
                            ? {
                                  ...item,
                                  ...session,
                              }
                            : item
                    }) || []

                dispatch({
                    type: SET_CLINICAL_NOTES_TEMPLATE,
                    value: applyConfigureTemplate,
                })
                reduxDispatch(setEncounterClinicalNoteLoaded(true))
            }

            setClinicalNoteData({
                ...clinicalNotesDetail.data,
                status: updateStatus ? updateStatus : clinicalNotesDetail?.data?.status,
                fhir_id: noteDetails.fhir_id,
            })
        } catch (err) {
            console.log(err, 'ERROR')
        } finally {
            setLoader(false)
        }
    }
    const setLoader = (value) => {
        dispatch({ type: SET_LOADER, value })
    }

    const fetchMasterData = async () => {
        dispatch({
            type: SET_MASTER_DATA,
            value: clinical_note_master,
        })
    }

    const [clinicalNoteModel, setClinicalNoteModel] = useState({})

    return (
        <>

            {clinicalNoteData?.data ? (
                clinicalNoteData?.template_type !== 'Medical' ? (
                    <Behavioral
                        isConsultant={isConsultant}
                        noteDetails={noteDetails}
                        clinicalNoteData={clinicalNoteData}
                        configureTemplateDetails={configureTemplateDetails}
                        clinicalNoteInitialData={clinicalNoteInitialData}
                        categoryNotes={
                            clinicalNoteData?.data ? JSON.parse(clinicalNoteData.data) : {}
                        }
                        psycho_note={{
                            primary_care_physician:
                                clinicalNoteData?.patient?.primary_care_physician || '',
                            dateTime: clinicalNoteData.createdAt || '',
                            provider:
                                clinicalNoteData?.psycho_created_by ||
                                clinicalNoteData?.provider_id,
                            patient: `${clinicalNoteData?.patient?.first_name || ''} ${
                                clinicalNoteData?.patient?.last_name || ''
                            }`,
                            psychotherapy_note_id: clinicalNoteData.psychotherapy_note_id,
                            encounter_id: clinicalNoteData.encounter_id,
                            last_HPI: clinicalNoteData.last_HPI,
                            chief_complaint: clinicalNoteData.chief_complaint,
                            patient_id: clinicalNoteData?.patient?.id,
                            clinical_note_id: clinicalNoteData?.id,
                        }}
                        previous_cn={clinicalNoteData?.previous_cn}
                        state={state}
                        providerType={providerType}
                        setLoader={setLoader}
                        onUpdate={fetchClinicalNote}
                    />
                ) : (
                    <Medical
                        isConsultant={isConsultant}
                        noteDetails={noteDetails}
                        clinicalNoteData={clinicalNoteData}
                        configureTemplateDetails={configureTemplateDetails}
                        clinicalNoteInitialData={clinicalNoteInitialData}
                        categoryNotes={
                            clinicalNoteData?.data ? JSON.parse(clinicalNoteData.data) : {}
                        }
                        psycho_note={{
                            primary_care_physician:
                                clinicalNoteData?.patient?.primary_care_physician || '',
                            dateTime: clinicalNoteData.createdAt || '',
                            provider:
                                clinicalNoteData?.psycho_created_by ||
                                clinicalNoteData?.provider_id,
                            patient: `${clinicalNoteData?.patient?.first_name || ''} ${
                                clinicalNoteData?.patient?.last_name || ''
                            }`,
                            psychotherapy_note_id: clinicalNoteData.psychotherapy_note_id,
                            encounter_id: clinicalNoteData.encounter_id,
                            last_HPI: clinicalNoteData.last_HPI,
                            chief_complaint: clinicalNoteData.chief_complaint,
                            patient_id: clinicalNoteData?.patient?.id,
                            clinical_note_id: clinicalNoteData?.id,
                        }}
                        previous_cn={clinicalNoteData?.previous_cn}
                        state={state}
                        providerType={providerType}
                        setLoader={setLoader}
                        onUpdate={fetchClinicalNote}
                    />
                )
            ) : (
                // !history?.location?.pathname.includes('/consultation') && (
                //     <>
                //         <LoadingPage />
                //     </>
                // )
                <>
                    <LoadingPage />
                </>
            )}
        </>
    )
}

export default ClinicalNotesForView
