import { Button, Grid, makeStyles } from '@material-ui/core'
import { LoadingButton } from '@mui/lab'
import React from 'react'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '25px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '25px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none !important',
    },
})

interface Props {
    cancelBtnText?: string
    okBtnText?: string
    disabled?: boolean
    loading?: boolean
    style?: React.CSSProperties
    onCancel?: () => void
    onOk?: () => void
}

export const Footer = (props: Props) => {
    const classes = useStyles()

    return (
        <Grid container item spacing={2} style={props.style}>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    disabled={props.disabled}
                    className={classes.button_cancel}
                    onClick={props.onCancel}>
                    {props.cancelBtnText || 'Cancel'}
                </Button>
            </Grid>

            <Grid item xs={6}>
                <LoadingButton
                    type="submit"
                    loading={props.loading}
                    variant="contained"
                    disabled={props.disabled}
                    className={classes.button_create}
                    loadingPosition="start"
                    startIcon={<></>}
                    onClick={props.onOk}>
                    {props.okBtnText || 'Submit'}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}

export default Footer
