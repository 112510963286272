import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button as ButtonCustom } from 'components/custom'
import { connect, useSelector } from 'react-redux'
import { deleteLocation } from 'services/Calendar/index'
import { NoRowsOverlayEl } from 'shared/elements'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { getLocations } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { Tooltip } from 'shared/elements/Tooltip'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import Routes from 'constants/routes'
import { useHistory } from 'react-router-dom'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { USStatesMapper } from './LocationCreateDialog/constant'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const LocationSettings = () => {
    const classes = useStyles()
    const notification = useNotification()
    const history = useHistory()
    const permissions = useSelector((state) => state?.permission?.service_location)

    // const [page, setPage] = useState(1)

    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [bundleId, setBundleId] = useState('')
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const handlePageChange = (value) => {
        setPage(value)
    }

    const columns = [
        {
            headerName: <Box fontWeight="bold">Name</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: <Box fontWeight="bold">NPI</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'npi',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: <Box fontWeight="bold">Fax</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'fax',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: <Box fontWeight="bold">External Location</Box>,
            headerClassName: 'super-app-theme--header text-center-header',
            field: 'is_external',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Grid container justifyContent="center">
                        <CheckboxOption
                            disabled
                            option={{
                                label: '',
                                value: false,
                            }}
                            // onChange={(e) => {
                            //     formik.setFieldValue(
                            //         'is_external',
                            //         e.target
                            //             .checked,
                            //     )
                            // }}
                            checked={value}
                        />
                    </Grid>
                )
            },
        },
        {
            headerName: <Box fontWeight="bold">Physical Address</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'address1',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return (
                    <Tooltip
                        title={
                            value +
                            (row.state ? `, ${USStatesMapper[row.state]}` : '') +
                            (row.city ? `, ${row.city}` : '')
                        }>
                        <span>{value}</span>
                    </Tooltip>
                )
            },
        },
        {
            headerName: <Box fontWeight="bold">Actions</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_service_location') &&
                !permissions.includes('delete_service_location'),
            renderCell: ({ row }) => {
                const menu = []
                if (permissions.includes('modify_service_location')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleEditClick(row),
                    })
                }
                if (permissions.includes('delete_service_location')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDeleteLocation(row.id),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this service location?"
                    />
                )
            },
        },
    ]

    const handleDeleteLocation = async (locationId) => {
        try {
            await deleteLocation(locationId)
            refetchLocation()
            notification('A Service Location was successfully deleted!', 'success')
        } catch (error) {
            notification(
                error?.data?.message || 'A Service Location was not successfully deleted!',
                'error',
            )
        }
    }

    const handleEditClick = (row) => {
        history.push(Routes.SETTINGS.LOCATIONS_EDIT.replace(':id', row?.id))
    }

    useEffect(() => {
        const abortController = new AbortController()
        refetchLocation()

        return () => abortController.abort()
    }, [page, sortModel])

    const refetchLocation = async (start = page) => {
        setLoading(true)
        try {
            const res = await getLocations(
                sortModel[0]?.sort
                    ? {
                          page: start,
                          size: limit || 10,
                          [sortModel[0].sort]: sortModel[0].field,
                          bundle_id: bundleId,
                      }
                    : {
                          page: start,
                          size: limit || 10,
                          asc: '',
                          bundle_id: bundleId,
                      },
            )

            setLoading(false)

            if (res?.data?.bundle_id) setBundleId(res?.data?.bundle_id)
            setPatientList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }
    const handleOnClick = () => {
        history.push(Routes.SETTINGS.LOCATIONS_NEW)
    }
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                    {/* <Typography className={classes.header}>Service Locations</Typography> */}

                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_service_location']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Service Location"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleOnClick()}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .text-center-header': {
                            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                                justifyContent: 'center',
                            },
                        },

                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(LocationSettings)
