export const config = {
    dev: {
        baseURL: process.env.REACT_APP_SONORA_API_URL,
        baseURLV2: process.env.REACT_APP_SONORA_V2_API_URL,
        notificationURL: process.env.REACT_APP_NOTIFICATION_API_URL,
        clinicalNotesURL: process.env.REACT_APP_CLINICAL_NOTES_API_URL,
        backendURL: process.env.REACT_APP_SONORA_API_URL,
        S3URL: process.env.REACT_APP_DOCUMENTS_URL,
        baseUrlSocket: process.env.REACT_APP_SONORA_SOCKET_ON_EXAM_ROOM,
    },
    //KEY: process.env.REACT_APP_STRIPE_API_KEY,
    country: {
        code: process.env.REACT_APP_COUNTRY_CODE,
        name: process.env.REACT_APP_DEFAULT_COUNTRY,
    },
}

export const appointmentTypes = {
    TELE_CONSULTANT: 'tele-consultant',
    IN_CLINIC: 'in-clinic',
}

export const faxTypes = ['inbound', 'outbound']

export const FAX_NUMBER = '8339210093'

export const maxCharLength = 40
export const maxCharLengthZip = 5
export const maxCharLengthDocumentName = 150

export const FAX_MODULE_DATA = {
    MARK_AS_READ: {
        key: 'mark_read',
        value: true,
        isToRefreshBadgeCount: true,
        message: 'Record(s) marked as read successfully',
    },
    MARK_AS_UN_READ: {
        key: 'mark_read',
        value: false,
        isToRefreshBadgeCount: true,
        message: 'Record(s) marked as unread successfully',
    },
    ARCHIVE: {
        key: 'published_status',
        value: 'archive',
        message: 'Record(s) archived successfully',
    },
    UN_ARCHIVE: {
        key: 'published_status',
        value: 'published',
        message: 'Record(s) unarchived successfully',
    },
}

export const SCREENS = {
    FAX_LIST: 'faxList',
    FAX_DETAIL: 'faxDetail',
    ARCHIVE_DETAIL: 'archiveDetail',
}

export const FORMS_TABS = [
    { label: 'Survey', component: '' },
    { label: 'Intake', component: '' },
]

export const url = 'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/'

export const Document_TABS = [
    { label: 'My Documents', component: '' },
    { label: 'Received', component: '' },
]
export const Category_Notes_TABS = [
    { label: 'All', component: '', key: 'all' },
    { label: 'Completed', component: '', key: 'completed' },
    {
        label: 'Incomplete',
        component: '',
        key: 'incomplete',
    },
]

export const API_SYSTEM = ['/notification/count']

export const SCHEDULE_POPULATE = {
    CLINICAL_NOTE: {
        name: 'CLINICAL_NOTE',
        text_back: 'Clinical Note',
    },
    REFERRAL: {
        name: 'REFERRAL',
        text_back: 'Referral Queue',
    },
    WAITLIST: {
        name: "WAITLIST",
        text_back: 'Waitlist'
    }
}
