import * as actionType from '../actionType'

const initialState = {
    locations: [],
    statusCode: [],
}

const metaData = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_LOCATIONS:
            return {
                ...state,
                locations: action.value,
            }
        case actionType.SET_STATUS_CODE:
            return {
                ...state,
                statusCode: action.value,
            }
        default:
            return state
    }
}

export default metaData
