import React, { useEffect, useRef } from 'react'
import {
    Button,
    Container,
    FormLabel,
    Grid,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormHelperText,
} from '@material-ui/core'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import './register.scss'
import { Link } from 'react-router-dom'
import backIcon from '../../assests/images/icon_back.png'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import { ButtonSpinner } from '../custom'
import { errorMessages } from '../../constants/errorMessages'

const dateValidationMsgs = [
    'Incorrect date format',
    'Age should be 18 years and above',
    'Please select date of birth',
]

const RegisterForm = ({
    handleSubmit,
    startDate,
    isNewUser,
    registerPatient,
    goBack,
    otpError,
    otpErrMsg,
    resendButtonDisabledTime,
    otp,
}) => {
    const formikRef = useRef()
    useEffect(() => {
        if (otpError && otp) {
            formikRef.current.setFieldError('otp', otpErrMsg)
        }
    }, [otpError, otp])

    const validateSchema = Yup.object().shape({
        firstName: Yup.string().required(errorMessages.firstName),
        lastName: Yup.string()
            .trim()
            .required(errorMessages.lastName)
            .matches(/^[aA-zZ\s]+$/, errorMessages.lastName),
        gender: Yup.string()
            .required(errorMessages.gender)
            .test('gender', 'Please select Gender', (answers) => answers !== 'none'),
        mob: Yup.string().required(errorMessages.phoneNumber),
        // .matches(
        //     usNumberRegex,
        //     errorMessages.validPhoneNumber,
        // ),
        dob: Yup.date().required(errorMessages.dob).nullable().typeError(''),
        email: Yup.string().email(errorMessages.validEmailId),
        otp: Yup.string(),
    })

    return (
        <Container className="container register-wrapper">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6} span={12}>
                    <div
                        className="form-img"
                        style={{
                            background: 'url(http://source.unsplash.com/620x700/?medical)',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} span={12} ml={2}>
                    <Formik
                        innerRef={formikRef}
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            dob: null,
                            gender: 'Male',
                            email: '',
                            mob: '',
                            otp: '',
                        }}
                        validationSchema={validateSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            await handleSubmit(values)
                            setSubmitting(false)
                        }}>
                        {({
                            errors,
                            touched,
                            isValid,
                            values,
                            setTouched,
                            setFieldValue,
                            setFieldError,
                            isSubmitting,
                            dirty,
                            handleBlur,
                            setFieldTouched,
                            handleChange,
                            handleSubmit,
                        }) => {
                            return (
                                <form
                                    className="form registration"
                                    onSubmit={handleSubmit}
                                    noValidate>
                                    <div className="inner-wrapper-logo">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                    </div>
                                    <h1 className="align-item-center">
                                        {isNewUser ? (
                                            <img
                                                id="auto-register-back"
                                                onClick={goBack}
                                                className="ba-back-icon"
                                                src={backIcon}
                                                alt="d"
                                            />
                                        ) : null}
                                        Register to book appointment
                                    </h1>
                                    <FormLabel className="input-label" component="legend">
                                        First name
                                        <span className="astrik">*</span>
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-firstname"
                                        required
                                        error={errors.firstName && touched.firstName}
                                        helperText={
                                            errors.firstName && touched.firstName
                                                ? errors.firstName
                                                : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="text-input"
                                        name="firstName"
                                        inputProps={{
                                            maxLength: 40,
                                        }}
                                        placeholder="Enter your first name"
                                        onChange={handleChange}
                                        value={values.firstName}
                                        onBlur={handleBlur}
                                    />
                                    <FormLabel className="input-label" component="legend">
                                        Last name
                                        <span className="astrik">*</span>
                                    </FormLabel>
                                    <TextField
                                        onBlur={handleBlur}
                                        id="auto-reg-lastname"
                                        required
                                        error={errors.lastName && touched.lastName}
                                        helperText={
                                            errors.lastName && touched.lastName
                                                ? errors.lastName
                                                : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="text-input"
                                        name="lastName"
                                        inputProps={{
                                            maxLength: 40,
                                        }}
                                        placeholder="Enter your last name"
                                        onChange={handleChange}
                                        value={values.lastName}
                                    />
                                    <FormLabel className="input-label" component="legend">
                                        Date of birth
                                        <span className="astrik">*</span>
                                    </FormLabel>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Grid container justifyContent="flex-start">
                                            <KeyboardDatePicker
                                                id="auto-reg-dob"
                                                // disableToolbar
                                                error={errors.dob && touched.dob}
                                                helperText={
                                                    errors.dob && touched.dob ? errors.dob : ''
                                                }
                                                inputVariant="outlined"
                                                variant="inline"
                                                format="MM/DD/YYYY"
                                                margin="normal"
                                                name="dob"
                                                autoOk={true}
                                                placeholder={'MM/DD/YYYY'}
                                                value={values.dob}
                                                maxDate={startDate}
                                                onChange={(date) => {
                                                    setFieldValue(
                                                        'dob',
                                                        moment(date).format().split('T')[0],
                                                    )
                                                }}
                                                onBlur={handleBlur}
                                                className="date-picker"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                    <FormLabel component="legend" className="input-label">
                                        Gender at birth
                                        <span className="astrik">*</span>
                                    </FormLabel>
                                    <FormControl
                                        component="fieldset"
                                        className="form-gender"
                                        error={errors.gender && touched.gender}>
                                        <RadioGroup
                                            id="auto-reg-gender"
                                            row
                                            aria-label="gender"
                                            name="gender"
                                            value={values.gender}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            <FormControlLabel
                                                value="Male"
                                                control={<Radio color="primary" />}
                                                label="Male"
                                            />
                                            <FormControlLabel
                                                value="Female"
                                                control={<Radio color="primary" />}
                                                label="Female"
                                            />
                                        </RadioGroup>
                                        <FormHelperText>
                                            {errors.gender && touched.gender ? errors.gender : ''}
                                        </FormHelperText>
                                    </FormControl>
                                    <FormLabel className="input-label" component="legend">
                                        Email
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-email"
                                        required
                                        error={errors.email && touched.email}
                                        helperText={
                                            errors.email && touched.email ? errors.email : ''
                                        }
                                        variant="outlined"
                                        size="small"
                                        className="text-input"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormLabel className="input-label" component="legend">
                                        Cellphone
                                        <span className="astrik">*</span>
                                    </FormLabel>
                                    <TextField
                                        id="auto-reg-phone"
                                        required
                                        error={errors.mob && touched.mob}
                                        helperText={errors.mob && touched.mob ? errors.mob : ''}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{
                                            maxLength: 14,
                                        }}
                                        className="text-input"
                                        name="mob"
                                        placeholder="Enter your cellphone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={isNewUser}
                                        value={values.mob}
                                    />
                                    {isNewUser ? (
                                        <div className="form-inputs">
                                            <FormLabel className="input-label" component="legend">
                                                Enter your one-time password sent to your phone
                                                number
                                            </FormLabel>
                                            <TextField
                                                id="auto-reg-otp"
                                                required
                                                variant="outlined"
                                                size="small"
                                                name="otp"
                                                inputProps={{
                                                    maxLength: 6,
                                                }}
                                                className="text-input"
                                                // helperText="Get one-time password"
                                                onChange={handleChange}
                                                value={values.otp}
                                                error={errors.otp}
                                                helperText={
                                                    resendButtonDisabledTime > 0 ? (
                                                        <>
                                                            <div className="dbl-error">
                                                                {errors.otp ? otpErrMsg + ' ' : ''}
                                                            </div>
                                                            <div className="flex-center">
                                                                <span className="resendotp-grayed text-center">
                                                                    {`Resend one-time password (${resendButtonDisabledTime})`}
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="dbl-error">
                                                                {errors.otp ? otpErrMsg + ' ' : ''}
                                                            </div>
                                                            <div className="flex-center">
                                                                <span
                                                                    id="auto-reg-reset-otp"
                                                                    className="link-blue text-center"
                                                                    onClick={() => {
                                                                        handleSubmit(values)
                                                                        setFieldValue('otp', '')
                                                                    }}>
                                                                    Resend one-time password
                                                                </span>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            />
                                        </div>
                                    ) : null}
                                    <div className="submit">
                                        {isNewUser ? (
                                            <Button
                                                id="auto-reg-next-btn"
                                                variant="contained"
                                                className="btn-primary"
                                                onClick={() => {
                                                    registerPatient({
                                                        ...values,
                                                    })
                                                }}
                                                disabled={errors.otp || values.otp.length < 6}>
                                                Next
                                            </Button>
                                        ) : (
                                            <ButtonSpinner
                                                disabled={
                                                    !(isValid && dirty) || Boolean(isSubmitting)
                                                }
                                                isLoading={Boolean(isSubmitting)}
                                                label={'Submit'}
                                            />
                                        )}
                                        <h5>
                                            Already have an account?{' '}
                                            <Link id="auto-login-link" to="/">
                                                Log in
                                            </Link>
                                        </h5>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    )
}

export default RegisterForm
