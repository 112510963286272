
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService,
  putService
} from 'utilities/request/restClient'

    
export const apiSubstanceAbuseTreatmentHistory = {
    getAllbyPtientId(patientId: string|number, payload){ 
        const queryString = new URLSearchParams({
            ...(payload as any),
        }).toString()

        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-treatment-abuse?${queryString}`)
    },

    getTreatment(payload){
        const queryString = new URLSearchParams({
            ...(payload as any),
        }).toString()
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/treatment?${queryString}`)
    },

    createTreatmentType(payload: {name: string}){
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/treatment`, payload)
    },

    createSubstanceAbuseTreatmentHistory(patientId, payload){
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-treatment-abuse`, payload)
    },

    updateSubstanceAbuseTreatment(patientId, substanceAbuseTreatmentId,  payload){
        return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-treatment-abuse/${substanceAbuseTreatmentId}`, payload)
    },

    getSubstanceAbuseTreatmentId(patientId, substanceAbuseTreatmentId){
        return getService(
            `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-treatment-abuse/${substanceAbuseTreatmentId}`,
        )   
    },

    deleteSubstanceAbuseTreatment(patientId, substanceAbuseTreatmentId){
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/substance-treatment-abuse/${substanceAbuseTreatmentId}`)
    },
}