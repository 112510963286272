import { makeStyles } from '@material-ui/core'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import React, { useEffect, useRef, useState } from 'react'
import './index.scss'

const useStyles = makeStyles(() => ({
    text__field: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e9ecf1 !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#667689',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important',
        },
    },
}))

const TooltipText = ({ value, ...props }) => {
    const classes = useStyles()
    const textElementRef = useRef()

    const compareSize = () => {
        const compare =
            textElementRef.current.scrollWidth >
            textElementRef.current.clientWidth
        console.log('compare: ', compare)
        setHover(compare)
    }

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize()
        window.addEventListener('resize', compareSize)
    }, [value])

    // remove resize listener again on "componentWillUnmount"
    useEffect(
        () => () => {
            window.removeEventListener(
                'resize',
                compareSize,
            )
        },
        [value],
    )

    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false)

    return (
        <HtmlTooltip
            title={hoverStatus ? value : ''}
            disableHoverListener={!hoverStatus}
            arrow
            placement="top"
            style={{
                background: 'white !important',
                color: '#303E4E',
            }}
            // disableHoverListener={!hoverStatus}
        >
            <div
                ref={textElementRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#303e4e',
                    fontSize: '19px',
                    fontFamily: 'Rubik',
                    fontWeight: 500,
                }}>
                {value}
            </div>
        </HtmlTooltip>
    )
}

export default TooltipText
