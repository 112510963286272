import * as actionType from '../actionType'
import moment from 'moment'
import 'moment-timezone'

const TZ = moment.tz.guess(true)

const optionsTz = [
    {
        utc: '-05:00',
        key: 'US/Eastern',
        value: '(UTC-05:00) US/Eastern',
        dst: 'EDT',
        label: 'Eastern Time - EST',
    },
    {
        utc: '-06:00',
        key: 'US/Central',
        value: '(UTC-06:00) US/Central',
        dst: 'CDT',
        label: 'Central Time - CST',
    },
    {
        utc: '-07:00',
        key: 'US/Mountain',
        value: '(UTC-07:00) US/Mountain',
        dst: 'MDT',
        label: 'Mountain Time - MST',
    },
    {
        utc: '-08:00',
        key: 'US/Pacific',
        value: '(UTC-08:00) US/Pacific',
        dst: 'PDT',
        label: 'Pacific Time - PST',
    },
    {
        utc: '-09:00',
        key: 'US/Alaska',
        value: '(UTC-09:00) US/Alaska',
        dst: 'AKDT',
        label: 'Alaskan Time - AKST',
    },
    {
        utc: '-10:00',
        key: 'US/Hawaii',
        value: '(UTC-10:00) US/Hawaii',
        dst: 'HDT',
        label: 'Hawaiian Time - HAST',
    },
]

const currentTz = optionsTz.find((option) => option.dst === moment.tz(TZ).format('z'))

const timezoneOffset = optionsTz.map((option) => ({
    ...option,
    offset: moment.tz(option.key).utcOffset(),
}))

let selectTz = {
    key: TZ,
    value: `(UTC${moment.tz(TZ).format('Z')}) ${TZ}`,
}

if (currentTz) {
    selectTz = {
        key: currentTz.key,
        value: currentTz.value,
    }
}

selectTz.label =
    timezoneOffset.find((timezone) => timezone.offset === moment().utcOffset())?.label ||
    moment.tz.guess()

const initialState = {
    time: moment(),
    location: TZ,
    date: moment().tz(TZ),
    tz: selectTz,
}

const timezones = (state = initialState, action) => {
    // return state
    switch (action.type) {
        case actionType.SET_COUNTRY:
            return {
                ...state,
                tz: action.option,
            }
        default:
            return state
    }
}

export default timezones
