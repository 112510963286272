import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

export const LoaderType = Object.freeze({
    LINEAR: 'linear',
    CIRCULAR: 'circular',
})
const LoadingPage = () => {
    return (
        <div className="root-loaderWrapper">
            <div className="loader">
                <CircularProgress />
            </div>
        </div>
    )
}

export default LoadingPage
