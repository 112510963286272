import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxOption from '../reusable/CheckboxOptions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CustomTabs from '../../shared/Elements/CustomTabs'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    questionLabel: {
        fontSize: 20,
        color: '#303E4E',
    },
    sectionHeading: {
        fontSize: 14,
        fontWeight: '500',
    },
    sectionGridContainer: {
        margin: '8px 0',
    },
    header: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#303E4E',
    },
})

const mapOptions = (masterData = []) => {
    return masterData.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const mapSections = (examMaster = [], property) => {
    return examMaster.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

const SuicidePreventionPlan = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = useState(0)
    const sections = mapSections(master?.suicidePreventationMasters, 'section_text')
    const sectionsTabs = Object.keys(sections).map((item, index) => ({
        key: item === 'undefined' ? 'Default' : item,
        label: item === 'undefined' ? 'Default' : item,
        _id: index,
    }))

    const options = mapOptions(master?.suicidePreventationMasters)

    const handleInputChange = (e, se) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name] ? [...values[name]] : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [
                      ...updatedValue,
                      {
                          id: value,
                          desc: '',
                          label: se.name,
                      },
                  ]
                : updatedValue.filter((uv) => uv.id !== value)
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_address_immense_risk = []
            const address_immense_risk = copyValues?.address_immense_risk?.length ? copyValues?.address_immense_risk : []
            for (const element of address_immense_risk) {
                if (typeof element === 'object' && element !== null) {
                    found_address_immense_risk.push(element);
                }
            }

            const found_addressing_diag = []
            const addressing_diag = copyValues?.addressing_diag?.length ? copyValues?.addressing_diag : []
            for (const element of addressing_diag) {
                if (typeof element === 'object' && element !== null) {
                    found_addressing_diag.push(element);
                }
            }

            const found_addressing_symptoms = []
            const addressing_symptoms = copyValues?.addressing_symptoms?.length ? copyValues?.addressing_symptoms : []
            for (const element of addressing_symptoms) {
                if (typeof element === 'object' && element !== null) {
                    found_addressing_symptoms.push(element);
                }
            }

            const found_addressing_social_factors = []
            const addressing_social_factors = copyValues?.addressing_social_factors?.length ? copyValues?.addressing_social_factors : []
            for (const element of addressing_social_factors) {
                if (typeof element === 'object' && element !== null) {
                    found_addressing_social_factors.push(element);
                }
            }


            const finalValues = {
                narrative: `${copyValues?.narrative} ${values?.narrative}`,
                address_immense_risk: [...found_address_immense_risk, ...(values?.address_immense_risk || [])],
                addressing_diag: [...found_addressing_diag, ...(values?.addressing_diag || [])],
                addressing_symptoms: [...found_addressing_symptoms, ...(values?.addressing_symptoms || [])],
                addressing_social_factors: [...found_addressing_social_factors, ...(values?.addressing_social_factors || [])],
            }
            if (!found_address_immense_risk.length && !found_addressing_diag.length && !found_addressing_symptoms.length && !found_addressing_social_factors.length && copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}>
            {/* <Grid item xs={12}>
                <CustomTabs
                    contents={sectionsTabs}
                    selectedTab={selectedTab}
                    onChangeTab={(e) => {
                        setSelectedTab(e)
                    }}
                />
            </Grid> */}
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            {Object.keys(sections)
                // .filter((_, index) => index === selectedTab)
                .map((key, index) => {
                    const sectionElements = sections[key]
                    return (
                        <Box key={index}>
                            <Typography className={classes.header}>{key}</Typography>
                            <Grid container className={classes.sectionGridContainer}>
                                {sectionElements.map((se, index) => {
                                    const isSelected = values[se.section_name]?.some(
                                        (item) => item.id === se._id,
                                    )

                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                width: '100%',
                                            }}>
                                            <CheckboxOption
                                                name={se.section_name}
                                                option={{
                                                    value: se._id,
                                                    label: se.name,
                                                }}
                                                onChange={(e) => handleInputChange(e, se)}
                                                checked={isSelected ? true : false}
                                                disabled={nonEditable}
                                            />
                                            {se.is_free_text && (
                                                <TextField
                                                    disabled={!isSelected || nonEditable}
                                                    name={se.section_name}
                                                    variant="outlined"
                                                    className={classes.textFieldWrap}
                                                    size="small"
                                                    onChange={(e) => handleInputChange(e, se)}
                                                />
                                            )}
                                        </Box>
                                    )
                                })}
                            </Grid>
                        </Box>
                    )
                })}
            {/* <GenerateNarrative
                hideGenerateBtn={true}
                hideClearBtn={true}
            /> */}
            <NarrativeAndMacro
                hideGenerateBtn={false}
                hideTextNarrative={false}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SuicidePreventionPlan
