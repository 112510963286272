import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { connect, useSelector } from 'react-redux'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'

import { Button as ButtonCustom } from 'components/custom'

import { useNotification } from 'shared/elements/Notification'

import { HealthPlanForm } from './HealthPlanForm'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { HealthPlan } from './interface'
import { DataGrid } from 'shared/elements/DataGrid'
import { ErrorType } from 'containers/PatientsSetting/interfaces'
import {
    getListHealthPlans,
    deleteHealthPlan,
    createHealthPlan,
    updateHealthPlan,
} from 'services/Setting'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
})

const HealthPlans = () => {
    const classes = useStyles()
    const notification = useNotification()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    // const [page, setPage] = useState(1)
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     page: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [clinicalHealthPlanForm, setClinicalHealthPlanForm] = useState<{
        healthPlan_id: string | undefined
        status: boolean
        type?: string
    }>({
        healthPlan_id: '',
        status: false,
    })

    const [isLoading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState<String>('')
    const [clinicalHealthPlans, setClinicalHealthPlans] = useState([])
    const [bundleId, setBundleId] = useState('')

    const permissions = useSelector((state) => state?.permission?.health_plan)
    // const permissions = ['delete_health_plan', 'modify_health_plan', 'create_health_plan']

    const handlePageChange = (value) => {
        setPage(value)
    }

    const columns: GridColumns<HealthPlan> = [
        {
            headerName: 'Insurance Company',
            headerClassName: 'super-app-theme--header',
            field: 'company_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Plan Name',
            headerClassName: 'super-app-theme--header',
            field: 'plan_name',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Phone',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions?.includes('modify_health_plan') &&
                !permissions?.includes('delete_health_plan'),
            renderCell: ({ row }) => {
                const menu = [] as any
                if (permissions?.includes('modify_health_plan')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleClickEdit(row.id as string), // FIXME:
                    })
                }
                if (permissions?.includes('delete_health_plan')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDelete(row.id as string),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Health Plans?"
                    />
                )
            },
        },
    ]

    const handleSubmit = async (payload: HealthPlan) => {
        const successMsg = clinicalHealthPlanForm.healthPlan_id
            ? 'Health Plan was successfully updated!'
            : 'Health Plan was successfully created!'

        const errorMsg = clinicalHealthPlanForm.healthPlan_id
            ? 'Health Plan was not successfully updated!'
            : 'Health Plan was not successfully added!'

        try {
            setIsSubmitting(true)

            if (clinicalHealthPlanForm.healthPlan_id)
                await updateHealthPlan(clinicalHealthPlanForm.healthPlan_id, payload)
            else await createHealthPlan(payload)

            notification(successMsg, 'success')

            getListClinicalHealthPlans()
            handleChangeForm()
        } catch (exception) {
            notification((exception as ErrorType).data?.message || errorMsg, 'error')
        }

        setIsSubmitting(false)
    }

    const handleDelete = async (healthPlan_id: string) => {
        try {
            await deleteHealthPlan(healthPlan_id)
            notification('A Health Plan was successfully deleted!', 'success')
            getListClinicalHealthPlans()
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message ||
                'A Health Plan was not successfully deleted!',
                'error',
            )
        }
    }

    const handleClickEdit = (healthPlan_id: string) => {
        handleChangeForm(healthPlan_id)
    }

    const handleChangeForm = (healthPlan_id?: string) => {
        setClinicalHealthPlanForm({
            status: !clinicalHealthPlanForm.status,
            healthPlan_id,
        })
    }

    useEffect(() => {
        const abortController = new AbortController()
        getListClinicalHealthPlans()

        return () => abortController.abort()
    }, [textSearch, sortModel, page])

    const getListClinicalHealthPlans = async () => {
        setLoading(true)
        try {
            const resp = await getListHealthPlans(
                sortModel[0]?.sort
                    ? {
                        page,
                        size: limit,
                        search: textSearch,
                        [sortModel[0].sort]: sortModel[0].field,
                        bundle_id: bundleId,
                    }
                    : {
                        page,
                        size: limit,
                        search: textSearch,
                        asc: '',
                        bundle_id: bundleId,
                    },
            )
            if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)

            setClinicalHealthPlans(resp.data.data || [])
            setTotal(resp.data?.paging?.total)
        } catch (exception) {
            setLoading(false)
            notification(
                (exception as ErrorType).data?.message || 'Cannot get list Health Plans',
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Health Plans</Typography>
                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_health_plan']}>
                            <Grid item className="d-flex space-between align-center">
                                {/* <SearchField onSearch={handleSearch} /> */}
                                <ButtonCustom
                                    tooltip="Add New Health Plan"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        setClinicalHealthPlanForm({
                                            status: true,
                                            healthPlan_id: undefined,
                                        })
                                    }
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    height={'calc(100vh - 140px)'}
                    onCellClick={(e) => {
                        setClinicalHealthPlanForm({
                            status: true,
                            healthPlan_id: e?.id || '',
                            type: 'view',
                        })
                    }}
                    rows={clinicalHealthPlans}
                    pageSize={limit}
                    columns={columns}
                    loading={isLoading}
                    // hideFooter={true}
                    totalRecords={total}
                    page={page}
                    onPageChange={handlePageChange}
                />
            </Grid>
            {clinicalHealthPlanForm.status && (
                <HealthPlanForm
                    open={clinicalHealthPlanForm.status}
                    healthPlan_id={clinicalHealthPlanForm.healthPlan_id}
                    loading={isSubmitting}
                    type={clinicalHealthPlanForm.type}
                    onCancel={handleChangeForm}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(HealthPlans)
