import { Box, Grid, Button, Typography, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import EncounterQueue from '../../../containers/Consultation/components/EncounterQueue'
import { useDispatch, useSelector } from 'react-redux'
import { createSchedule } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { useTimezone } from 'shared/hooks/useTimezone'
import { changeAppointment } from 'store/actions/schedule'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
const ButtonHK = styled(Button)({
    height: 40,
    borderRadius: 8,
    borderColor: '#5571C6 !important',
    color: '#5571C6',
    // background: 'white',
    backgroundColor: 'white',
    fontSize: 15,
    fontWeight: '400',
    marginTop: 16,
    textTransform: 'unset',
    // '&:hover': {
    //     backgroundColor: '#0069d9',
    //     borderColor: '#0062cc',
    //     boxShadow: 'none',
    // },
    // '&:active': {
    //     boxShadow: 'none',
    //     backgroundColor: '#0062cc',
    //     borderColor: '#005cbf',
    // },
    // '&:focus': {
    //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    // },
})

const useStyles = makeStyles({
    headerGridContainerEq: {
        width: 0,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        borderLeft: '1px solid #e8e8e8',
        transition: 'width 0.5s',
    },
    headerGridContainer: {
        width: 320,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        borderLeft: '1px solid #e8e8e8',
        transition: 'width 0.5s',
    },
    width0: {
        width: 0,
    },
    appLogo: {
        width: 150,
        height: 66,

        '@media (max-width: 1500px)': {
            width: '70%',
            height: 'auto',
        },
    },
    typo_line: {
        color: '#9CA9B8',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_tab: {
        // color: '#5571c6',
        color: '#303E4E',
        cursor: 'pointer',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        fontWeight: 500,
        padding: '6px 0',
        marginBottom: '8px',
        borderBottom: '1px solid #F3F6F9',
    },
    typo_line_gray: {
        color: '#303E4E',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_cover: {
        borderBottom: '1px solid #e8e8e8',
        margin: '8px 0',
    },
    typo_line_cover_tab: {
        margin: '8px 0',
    },
    separator: {
        borderLeft: '2px solid #ffffff',
        height: 55,
        width: 2,
        boxSizing: 'border-box',
        marginRight: 10,
        marginLeft: 10,
    },
    leftBorder: {
        borderLeft: '2px solid #ffffff',
        paddingLeft: 10,
    },
    dFlex: {
        display: 'flex',
    },
    oveflowDegree: {
        width: '235px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textTransformCapital: {
        textTransform: 'uppercase',
    },
    avatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
        marginTop: 16,
        background: 'gray',
        marginBottom: 16,
    },
    provider_tag: {
        padding: '4px 16px',
        background: '#3E465B',
        borderRadius: 5,
        color: 'white',
        margin: '12px 0',
    },
    cover_info: {
        padding: 16,
        marginTop: 8,
        borderTop: '1px solid #e8e8e8',
    },
    show_header_btn: {
        position: 'absolute !important',
        top: 10,
        left: -54,
    },
    hidden_cover: {
        // overflow: 'hidden',
        overflowY: 'scroll',
        overflowX: 'hidden',
        // height: 'calc(100vh - 90px)',
    },
    encounterQueue: {
        borderTop: '1px solid #E9ECF1',
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 100px)',
        height: 'fit-content',
    },
    btn_show_more: {
        cursor: 'pointer',
        color: '#5571C6',
        textAlign: 'center',
        marginTop: 16,
    },
    scroll_content: {
        maxHeight: "350px",
        overflow: "auto"
    }
})

const MedicalHeader = ({
    isConsultant,
    noteDetails,
    activeView,
    setEqStatus,
    EqStatus,
    providerDetail,
    patientDetails,
    isAppointmentDetails,
    clinicalNoteData,
    onChangeActiveView,
    appointmentDetails,
    handleSetViewHealthyRecord,
}) => {

    const [openERX, setOpenERX] = useState(false)
    const { patientName, gender, dob, chartId } = noteDetails || {}
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState({ id: null, status: false })
    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [activeTab, setActiveTab] = useState(1)

    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    // const { locations, visitTypes, dataLoading } = useAppointmentBookingFormData(
    //     clinicalNoteData?.provider_id,
    // )

    const timezone = useTimezone()
    const dispatch = useDispatch()

    const {
        id: patientId,
        last_name: patient_last_name,
        first_name: patient_first_name,
        dob: patient_dob,
        preferred_name: patient_preferred_name
    } = clinicalNoteData?.patient
    const {
        appointment_date,
        appointment_start,
        appointment_end,
        appointment_duration,
        insurance_company,
        insurance_plan_name,
    } = clinicalNoteData
    const providerN = localStorage.getItem('name')

    const { first_name, last_name, education } = providerDetail || {}
    const [showHeader, setShowHeader] = useState(!isConsultant)
    const [isShowMore, setIsShowMore] = useState(!isConsultant)
    const [isShowMoreInfo, setIsShowMoreInfo] = useState(true)
    const providerEducation = education && education[0] ? education[0]?.education : ''
    const {
        first_name: patientFristName,
        last_name: patientLastName,
        dob: patientDob,
    } = patientDetails || {}

    const classes = useStyles()

    const encounterQueueList = useSelector((state) => state.provider.encounterQueueList)
    const openListConsultation = useSelector((state) => state.provider?.openListConsultation)
    const openListProvider = useSelector((state) => state.provider.openListProvider)

    const notification = useNotification()

    const handleShowHeader = () => {
        setShowHeader(!showHeader)
    }
    const handleToggleCollapseInfo = () => {
        setIsShowMore(!isShowMore)
    }

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            await createSchedule(values)

            setIsSubmitting(false)
            setAppointmentModal({ id: null, status: false })
            notification('An appointment was successfully created!', 'success')

        } catch (error) {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'An appointment was not successfully created!',
                'error',
            )
        }
    }

    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: clinicalNoteData.patient.id,
                        practitioner_id: clinicalNoteData.provider_id,
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                    },
                }),
            )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }

    useEffect(() => {
        setShowHeader(!isConsultant || openListConsultation || openListProvider)
    }, [openListConsultation, openListProvider])

    return (
        <Grid
            style={{ borderLeft: !EqStatus ? "none" : '' }}
            className={clsx({
                [classes.headerGridContainer]: EqStatus,
                [classes.width0]: showHeader,
                [classes.headerGridContainerEq]: !EqStatus,
            })}
            direction={'column'}
            container
            alignItems={'center'}
            wrap="nowrap">
            {
                isConsultant && 
                <div
                    style={{
                        width: "23px",
                        height: "30px",
                        background: "white",
                        position: "absolute",
                        right: EqStatus ? "316px" : "0px",
                        marginLeft: EqStatus ? '' : '270px',
                        color: "#3c8fe2",
                        top: "25px",
                        borderBottom: "2px #E9ECF1 solid",
                        borderLeft: "2px #E9ECF1 solid",
                        borderTop: "2px #E9ECF1 solid",
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                        paddingLeft: "3px",
                        zIndex: 10000,
                        cursor: "pointer"
                    }}
                    onClick={() => setEqStatus(EqStatus => !EqStatus)}
                >


                    {!EqStatus ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </div>}
            {isConsultant && EqStatus && (
                <Grid className={classes.encounterQueue}>
                    <EncounterQueue
                        encounterQueueList={encounterQueueList}
                    // lastMessage={lastMessage}
                    />
                </Grid>
            )}
        </Grid>
    )
}

export default MedicalHeader