const SendIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.31719 9.92181L2.31733 9.92151L2.89685 8.76914C2.92401 8.71174 2.94167 8.61103 2.94167 8.49977C2.94167 8.3885 2.92401 8.28779 2.89684 8.23039L2.31733 7.07803L2.31728 7.07792C1.64983 5.74637 1.41631 4.75494 1.42919 4.01636C1.44212 3.27478 1.70362 2.79138 2.01596 2.48239C2.32493 2.17007 2.80833 1.90855 3.54909 1.89562C4.28687 1.88274 5.27667 2.11628 6.60491 2.78375L12.3112 5.63691L2.31719 9.92181ZM2.31719 9.92181C1.65314 11.2532 1.41966 12.2429 1.43169 12.9806C1.44376 13.7216 1.70378 14.205 2.01596 14.5171C2.33554 14.8367 2.83467 15.0998 3.60667 15.0998M2.31719 9.92181L3.60667 15.0998M3.60667 15.0998C4.33408 15.0998 5.30974 14.8667 6.61139 14.2159L12.3181 11.3625C13.7476 10.6478 14.5667 9.63402 14.5667 8.49977C14.5667 7.36546 13.7475 6.35174 12.3114 5.63699L3.60667 15.0998ZM2.86356 3.33097L2.86405 3.33048C3.0108 3.18373 3.25957 3.09977 3.60667 3.09977C4.20367 3.09977 5.06042 3.35178 6.06862 3.85588L6.0687 3.85592L11.7754 6.70259L11.7755 6.70266C12.8142 7.21869 13.3667 7.87997 13.3667 8.4931C13.3667 9.10657 12.8203 9.76778 11.7822 10.2835L11.782 10.2837L6.07529 13.137C5.28052 13.5344 4.57921 13.7781 4.02154 13.8634C3.4578 13.9497 3.06925 13.8704 2.86454 13.6629L2.86356 13.6619C2.65597 13.4572 2.57676 13.0702 2.66302 12.5083C2.74836 11.9523 2.99203 11.2527 3.38943 10.4579C3.38944 10.4579 3.38944 10.4578 3.38945 10.4578L3.96935 9.3047L3.96935 9.3047L3.96972 9.30394C4.08458 9.07064 4.14001 8.78317 4.14001 8.49977C4.14001 8.21637 4.08458 7.9289 3.96973 7.6956L3.96945 7.69505L3.38945 6.53505L3.38937 6.5349C2.99203 5.74352 2.74837 5.04391 2.66302 4.48712C2.57674 3.92426 2.656 3.53568 2.86356 3.33097Z"
                fill="#667689"
                stroke="#667689"
                strokeWidth="0.2"
            />
            <path
                d="M3.62671 9.1H7.22671C7.55527 9.1 7.82671 8.82856 7.82671 8.5C7.82671 8.17144 7.55527 7.9 7.22671 7.9H3.62671C3.29815 7.9 3.02671 8.17144 3.02671 8.5C3.02671 8.82856 3.29815 9.1 3.62671 9.1Z"
                fill="#667689"
                stroke="#667689"
                strokeWidth="0.2"
            />
        </svg>
    )
}

export default SendIcon
