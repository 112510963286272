import { LoadingButton as MuiLoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import palette from 'styles/themes/palette'

export const StyledLoadingButton = styled(MuiLoadingButton)({
  backgroundColor: palette.accent.dark,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: palette.accent.dark
  }
})