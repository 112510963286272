export const SendFaxIcon = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.55001 4.74012L11.9175 2.61762C14.775 1.66512 16.3275 3.22512 15.3825 6.08262L13.26 12.4501C11.835 16.7326 9.49501 16.7326 8.07001 12.4501L7.44001 10.5601L5.55001 9.93012C1.26751 8.50512 1.26751 6.17262 5.55001 4.74012Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.58252 10.2374L10.2675 7.54492"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
