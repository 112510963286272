import * as actionType from '../actionType';
import { setLoading, setError } from './loader';
import {
    getAdminProviderList,
    getStaffList,
    getStaffResultList,
    addStaffProfile,
    updateStaffStatus as updateStaffStatusService,
} from '../../services/Admin/admin';

export const setAdminProviderList = (value) => ({
    type: actionType.SET_ADMIN_PROVIDER_LIST,
    value,
});
export const setStaffList = (value) => ({
    type: actionType.SET_SEARCH_STAFF_LIST,
    value,
});

export const setAddStaffProfile = (value) => ({
    type: actionType.SET_STAFF_PROFILE,
    value,
});

export const setUpdateStaffStatus = (value) => {
    return {
        type: actionType.SET_UPDATE_STAFF_STATUS,
        value,
    };
};
export const setSearchStaffResult = (value) => {
    return {
        type: actionType.SET_STAFF_RESULT_LIST,
        value,
    };
};
export const searchStaffListResult = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        getStaffResultList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setSearchStaffResult(res.data));
            })
            .catch((err) => {
                console.log('Error', err);
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(setSearchStaffResult({}));
            });
    };
};
export const searchAdminProvider = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        getAdminProviderList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setAdminProviderList(res.data));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(setAdminProviderList([]));
            });
    };
};
export const staffProfile = (payload, onSuccess) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        addStaffProfile(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setAddStaffProfile(res.data));

                onSuccess && onSuccess(res.data);
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(setAddStaffProfile([]));
            });
    };
};

export const searchStaffingList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        getStaffList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setStaffList(res.data));
            })

            .catch((err) => {
                console.log('Error', err);
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(setStaffList({}));
            });
    };
};

export const updateStaffStatus = (payload) => {
    return (dispatch) => {
        setLoading(true);
        updateStaffStatusService(payload)
            .then((res) => {
                setLoading(false);

                // update row user status based on userId
                dispatch(setUpdateStaffStatus(payload));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(setError(true));
            });
    };
};
