import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from '@mui/material'

import { getAppointmentsByPatientId } from 'services/Patient/patient'
import { Appointment } from 'containers/PatientsSetting/interfaces'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { formatDateTime } from 'utilities/formats'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import AppointmentBooking from 'containers/AppointmentBooking'
import { updateSchedule } from 'services/Calendar'
import { sx } from 'containers/PatientsSetting/style'
import { useNotification } from 'shared/elements/Notification'
import { useStyles } from './style'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { DataGrid } from 'shared/elements/DataGrid'
import SchedulerMenu from 'containers/SchedulerMenu'
import { changeAppointment } from 'store/actions/schedule'

interface Props {
    selectedTab: number
    cycleCount: number
    filterByDate?: string
    hasActions?: boolean
    onClickEdit: (appointmentId: string) => void
}

export const DataAppointments = (props: Props) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const [activeTab, setActiveTab] = useState(1)

    const [newAppointmentModal, setNewAppointmentModal] = useState({ status: false })

    const { id: patientId } = useParams<{ id: string }>()
    const notification = useNotification()

    const [practitionerId, setPractitionerId] = useState<string | null>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState<{
        id: string | null
        status: boolean
    }>({ id: null, status: false })

    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    const { locations, visitTypes, dataLoading } = useAppointmentBookingFormData(practitionerId)

    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [isLoading, setLoading] = useState(false)
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'type',
            sort: 'desc',
        },
    ])

    useEffect(() => {
        const abortController = new AbortController()
        getListAppointments()

        return () => abortController.abort()
    }, [page, props.filterByDate])

    useEffect(() => {
        setPage(1)
    }, [props.selectedTab])

    const getListAppointments = async (start = page) => {
        try {
            setLoading(true)
            const resp = await getAppointmentsByPatientId(patientId, {
                page: start,
                pageSize: limit,
                date: props.filterByDate,
            }) // Last 10 records

            setAppointments(resp.data.data as Appointment[])
            setTotal(resp.data.totalCount)
        } catch (ex) {
            notification('Cannot fetch list appointments', 'error')
        }

        setLoading(false)
    }

    const handleSubmit = async (values: Appointment) => {
        try {
            setIsSubmitting(true)

            if (appointmentModal.id) {
                await updateSchedule({
                    id: appointmentModal.id,
                    payload: values,
                })

                setIsSubmitting(false)
                setAppointmentModal({ id: null, status: false })
                notification('An appointment was successfully updated!', 'success')

                setTimeout(() => getListAppointments(), 800)
            }
        } catch (error) {
            setIsSubmitting(false)
            notification('An appointment was not successfully updated!', 'error')
        }
    }
    const handlePageChange = (page: number) => setPage(page)

    function newAppointmentModalToggle() {
        setNewAppointmentModal({
            status: !newAppointmentModal.status,
        })
    }

    const columns: GridColumns<Appointment> = [
        {
            headerName: 'Scheduled Time',
            headerClassName: 'super-app-theme--header',
            field: 'start',
            flex: 1.5,
            sortable: true,
            renderCell: ({ row }) => {
                return (
                    <DataGridCell
                        value={
                            row.start &&
                            row.end && (
                                <Grid container direction="column">
                                    <Typography>
                                        {formatDateTime(row.start, 'MMM DD, YYYY - hh:mm A')} -
                                    </Typography>
                                    <Typography>
                                        {formatDateTime(row.end, 'MMM DD, YYYY - hh:mm A')}
                                    </Typography>
                                </Grid>
                            )
                        }
                    />
                )
            },
        },

        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'appointment_for',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return (
                    <DataGridCell
                        value={
                            <>
                                {row.practitioner_first_name}
                                {!!row.practitioner_last_name && ` ${row.practitioner_last_name}`}
                            </>
                        }
                    />
                )
            },
        },
        {
            headerName: 'Notes',
            headerClassName: 'super-app-theme--header',
            field: 'internal_note',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value} />
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Office',
            headerClassName: 'super-app-theme--header',
            field: 'location_name',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return <DataGridCell value={row.location_name || `Room #${row.location_id}`} /> // @TODO
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Exam Room',
            headerClassName: 'super-app-theme--header',
            field: 'room_name',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return <DataGridCell value={row.room_name || row.room_id} /> // @TODO
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Billing Status',
            headerClassName: 'super-app-theme--header',
            field: 'description53',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <DataGridCell
                        value={<Typography className={classes.status_box}>N/A</Typography>}
                    />
                )
            },
        },
        {
            headerName: 'Encounter Status',
            headerClassName: 'super-app-theme--header',
            field: 'encounter_status',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <DataGridCell
                        value={
                            <Typography
                            // className={classes.status_box}
                            >
                                {value || 'N/A'}
                            </Typography>
                        }
                    />
                )
            },
        },
    ]

    if (props.hasActions) {
        columns.push({
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => {
                                    if (!newAppointmentModal.status) {
                                        dispatch(
                                            changeAppointment({
                                                id: row.id,
                                                values: null,
                                            }),
                                        )
                                    }
                                    newAppointmentModalToggle()
                                    setPractitionerId(row.practitioner_id)
                                },
                            },
                        ]}
                    />
                )
            },
        })
    }

    return (
        <>
            <Grid item xs={12}>
                <DataGrid
                    sx={sx.datagrid}
                    getRowId={(row) => row.id}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    rows={appointments}
                    columns={columns}
                    paginationMode="server"
                    disableColumnSelector={true}
                    pageSize={limit}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={total}
                    pagination
                    disableColumnMenu={true}
                    totalRecords={total}
                    page={page}
                    onPageChange={handlePageChange}
                    // onRowClick={handleRowClick}
                />
            </Grid>

            {/* <AppointmentBooking
                open={appointmentModal.status}
                appointmentModal={appointmentModal}
                loading={isSubmitting}
                dataLoading={dataLoading}
                onModalClose={() =>
                    setAppointmentModal({
                        ...appointmentModal,
                        id: null,
                        status: false,
                    })
                }
                onSubmit={handleSubmit}
                locationOptionsData={locations.map((item) => ({
                    key: item.id,
                    value: item.name,
                }))}
                visitTypeOptionsData={visitTypes.map((item) => ({
                    key: item.id,
                    value: item.post_code,
                    duration: item.duration,
                }))}
                statusOptionsData={statusOptions.map((item) => ({
                    id: item.id,
                    key: item.code,
                    value: item.name,
                }))}
            /> */}

            {newAppointmentModal.status && (
                <Dialog
                    open={newAppointmentModal.status}
                    sx={{
                        '& .MuiDialog-paperFullWidth': {
                            maxWidth: '1200px',
                            height: '100vh',
                            overflow: 'hidden',
                        },
                    }}
                    fullWidth={true}
                    onClose={newAppointmentModalToggle}>
                    <SchedulerMenu
                        isPopupType
                        tab={activeTab}
                        action={'update'}
                        onChangeTab={(tab) => setActiveTab(tab)}
                        isFromClinicalNote
                        open={newAppointmentModal.status}
                        appointmentModal={newAppointmentModal}
                        loading={isSubmitting}
                        onModalClose={newAppointmentModalToggle}
                        onSubmit={handleSubmit}
                        setIsLoading={setIsSubmitting}
                        locationOptionsData={locations.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={visitTypes.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                        onOk={getListAppointments}
                    />
                </Dialog>
            )}
        </>
    )
}
