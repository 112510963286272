import React, { useState } from 'react'
import './Header.scss'
import { Button } from '@material-ui/core'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import useViewport from '../../hoc/AppLayout/Sidebar/useviewport'
import { ReactComponent as HamburgerMenu } from '../../assests/sidebar/Mobile-Icons_hamburger-menu.svg'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import MenuIcon from '@material-ui/icons/Menu'

const Header = ({ handleClick }) => {
    const { width } = useViewport()
    const [drawer, setDrawer] = useState(false)
    const breakpoint = 1024
    const drawerWidth = 240
    const [open, setOpen] = React.useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
        setDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
        setDrawer(false)
    }

    return (
        <header className="header-container">
            <div className="navTextContainer">
                <p>
                    Everyone is facing new challenges.
                    <span>
                        Our doctors are here to help.
                    </span>
                </p>
            </div>
            {width <= breakpoint ? (
                <div className="background-container mobile-menu-wrapper">
                    <button
                        type="button"
                        onClick={handleDrawerOpen}
                        className="hamburger-menu">
                        <MenuIcon />
                    </button>
                    {drawer ? (
                        <>
                            <Drawer
                                variant="persistent"
                                anchor="left"
                                open={open}
                                className="mobile-menu">
                                <div>
                                    <div className="logo-wrapper">
                                        <img
                                            className="mobile-logo"
                                            src={logo}
                                            alt="TeleMed2U"></img>
                                        <div className="flex-end">
                                            <button
                                                type="button"
                                                onClick={
                                                    handleDrawerClose
                                                }
                                                className="closeIcon-btn">
                                                <CloseIcon />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="app__sidebar">
                                        <nav>
                                            <ul>
                                                <li>
                                                    <a
                                                        id="auto-header-what-we-treat"
                                                        href="/">
                                                        What
                                                        We
                                                        Treat
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        id="auto-header-about-us"
                                                        href="/">
                                                        About
                                                        Us
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        id="auto-header-meet-our-doc"
                                                        href="/">
                                                        Meet
                                                        Our
                                                        Doctors
                                                    </a>
                                                </li>

                                                <li>
                                                    <a
                                                        id="auto-header-organization"
                                                        href="/">
                                                        Organization
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        id="auto-header-providers"
                                                        href="/">
                                                        Providers
                                                    </a>
                                                </li>
                                                <li>
                                                    <Button
                                                        style={{
                                                            background:
                                                                '#5571c6',
                                                            color: 'white',
                                                        }}
                                                        id="auto-header-book-apt-btn"
                                                        variant="contained"
                                                        className="btn-primary"
                                                        onClick={
                                                            handleClick
                                                        }>
                                                        Book
                                                        Appointment
                                                    </Button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </Drawer>
                        </>
                    ) : null}
                </div>
            ) : (
                <nav className="navbar">
                    <ul className="ulContainer header-menu">
                        <li>
                            <a
                                id="auto-header-what-we-treat"
                                href="/">
                                What We Treat
                            </a>
                        </li>
                        <li>
                            <a
                                id="auto-header-about-us"
                                href="/">
                                About Us
                            </a>
                        </li>
                        <li>
                            <a
                                id="auto-header-meet-our-doc"
                                href="/">
                                Meet Our Doctors
                            </a>
                        </li>
                        <li>
                            <img
                                src={logo}
                                alt="TeleMed2U"></img>
                        </li>
                        <li>
                            <a
                                id="auto-header-organization"
                                href="/">
                                Organization
                            </a>
                        </li>
                        <li>
                            <a
                                id="auto-header-providers"
                                href="/">
                                Providers
                            </a>
                        </li>
                        <li>
                            <Button
                                id="auto-header-book-apt-btn"
                                variant="contained"
                                className="btn-primary"
                                onClick={handleClick}>
                                Book Appointment
                            </Button>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    )
}
export default Header
