import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxOption from '../reusable/CheckboxOptions'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    questionLabel: {
        fontSize: 20,
        color: '#303E4E',
    },
})

const mapOptions = (masterData = []) => {
    return masterData.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const SuicideRiskFactor = ({
    fieldName,
    master,
    values,
    onChange,
    lastNarrative,
    macros,
    generateAble,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()
    const options = mapOptions(master?.suicideRiskFactorMasters)

    const handleInputChange = (e, option) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name] ? [...values[name]] : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [...updatedValue, { id: value, label: option.label }]
                : updatedValue.filter((uv) => uv.id !== value)
        }

        if (type === 'text') {
            updatedValue = updatedValue.map((uv) =>
                uv.id === option._id ? { ...uv, desc: value } : uv,
            )
        }

        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const handleSelectMacro = (value) => {
        if(nonEditable){return}
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 5,
            }}>
            {copyAble ? (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            ) : (
                <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            )}
            <Box sx={{ marginBottom: 10 }}>
                <CheckboxOption
                    disabled={nonEditable}
                    name={`${fieldName}.is_patient_screened`}
                    option={{
                        label: questions.is_patient_screened,
                        value: values?.is_patient_screened,
                    }}
                    onChange={(e) => {
                        const { name, checked } = e.target
                        onChange(name, {
                            check: checked,
                            label: questions.is_patient_screened,
                        })
                    }}
                    checked={values?.is_patient_screened.check ? true : false}
                />
            </Box>
            <Typography className={classes.questionLabel}>
                {questions.suicide_risk_factors}:
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {options.map((option) => {
                    const isSelected = values['suicide_risk_factors'].some(
                        (item) => item.id === option.value,
                    )

                    return (
                        <CheckboxOption
                            disabled={nonEditable}
                            key={option.value}
                            name="suicide_risk_factors"
                            option={option}
                            onChange={(e) => handleInputChange(e, option)}
                            checked={isSelected ? true : false}
                        />
                    )
                })}
            </Box>
            <NarrativeAndMacro
                hideTextNarrative={!generateAble}
                hideGenerateBtn={!generateAble}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SuicideRiskFactor
