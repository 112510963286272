import React, { useEffect, useState } from 'react'
import { FormLabel, TextField, TextFieldProps } from '@material-ui/core'
import { debounce } from 'lodash'
import { Box } from '@mui/material'

interface Props {
  label?: string
  value?: string
  required?: boolean
  /** Format of zipcode, specified by number.
   *
   * 5-digit zipcode format looks like xxxxx, while 9-digit zipcode format is xxxxx-xxxx */
  format?: number
  onChange?: (value: string) => void
}

export const ZipCode = ({ label, required, format, ...props }: TextFieldProps & Props) => {
  const [text, setText] = useState(props.value as string)
  const ZIPCODE_LENGTH = format === 9 ? 10 : 5 // 9+1 for one dash (-)

  const dispatchChangeEvent = debounce((value: string) =>
    props.onChange && props.onChange(value), 0)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let text = e.target.value

    if (text.length > ZIPCODE_LENGTH) return // Exceeding maximum character length

    if (text.length !== 6 && (
      text[text.length - 1] < '0'
      || text[text.length - 1] > '9')
    ) return

    if (text.length === 6) { // FIXME:
      if (text[text.length - 1] === '-') // Case: Deleting character
        text = text.substring(0, 5)

      else text = text.substring(0, 5) + '-' + text.substring(5, text.length)
    }

    setText(text)
    if (!text.length || text.length === ZIPCODE_LENGTH)
      dispatchChangeEvent(text)
  }

  useEffect(() => {
    const preservedValue = props.value as string
    if (preservedValue !== text) {
      setText(preservedValue)
    }
  }, [props.value])

  return (
    <>
      <FormLabel
        component="p"
        className="input-form-label"
        style={{ fontSize: 14, fontWeight: 500 }}
      >
        <span
          className={!!required ? 'required' : ''}>
          {label || 'Zip Code'}
        </span>
      </FormLabel>

      <Box>
        <TextField
          variant="outlined"
          style={{ height: 'fit-content' }}
          fullWidth
          {...props}
          placeholder={ZIPCODE_LENGTH === 5 ? '00000' : '00000-0000'}
          value={text || ''}
          onChange={handleChange}
          error={props?.error}
          helperText={props.helperText}
          onBlur={() => dispatchChangeEvent(text)}
        />
      </Box>
    </>
  )
}
