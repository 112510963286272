import moment from 'moment'
import { useTimezone } from 'shared/hooks/useTimezone'

export const OPTIONS_TZ = [
    {
        no_utc: -5,
        utc: '-05:00',
        key: 'US/Eastern',
        value: 'Eastern Time- EST',
        dst: 'EDT',
    },
    {
        no_utc: -6,
        utc: '-06:00',
        key: 'US/Central',
        value: 'Central Time- CT',
        dst: 'CDT',
    },
    {
        no_utc: -7,
        utc: '-07:00',
        key: 'US/Mountain',
        value: 'Mountain Time - MT',
        dst: 'MDT',
    },
    {
        no_utc: -8,
        utc: '-08:00',
        key: 'US/Pacific',
        value: 'Pacific Time - PT',
        dst: 'PDT',
    },
    {
        no_utc: -9,
        utc: '-09:00',
        key: 'US/Alaska',
        value: 'Alaskan Time- AKST',
        dst: 'AKDT',
    },
    {
        no_utc: -10,
        utc: '-10:00',
        key: 'US/Hawaii',
        value: 'Hawaiian Time- HAST',
        dst: 'HDT',
    },
]

export const OPTIONS_TYPE_DATE = [
    { key: 'MONTH', value: 'Month' },
    { key: 'WEEK', value: 'Week' },
    { key: 'WEEKDAY', value: 'Weekday' },
    { key: 'DAY', value: 'Day' },
]

export const days = [
    { key: 'SUN', value: 'S', label: 'Sunday' },
    { key: 'MON', value: 'M', label: 'Monday' },
    { key: 'TUE', value: 'T', label: 'Tuesday' },
    { key: 'WED', value: 'W', label: 'Wednesday' },
    { key: 'THU', value: 'TH', label: 'Thursday' },
    { key: 'FRI', value: 'F', label: 'Friday' },
    { key: 'SAT', value: 'S', label: 'Saturday' },
]

export const initialValues = {
    days: [],
    start_date: moment().format('MM/DD/YYYY'),
    end_date: moment().format('MM/DD/YYYY'),
    start_time: moment().format('HH:mm'),
    end_time: moment().format('HH:mm'),
    all_day: false,
    is_recur: false,
    repeat_gap: '',
    repeat_by: '',
    end_type: '',
    timezone: moment.tz.guess(),
}

export const initialValuesV2 = () => {
    const { locationTimezone } = useTimezone()
    return {
        start_date: '',
        end_date: '',
        timezone: locationTimezone,
        is_recur: false,
        days: [
            {
                day: 'SUN',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'MON',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'TUE',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'WED',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'THU',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'FRI',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
            {
                day: 'SAT',
                available: 0,
                options: [
                    {
                        start_time: '08:00',
                        end_time: '17:00',
                    },
                ],
            },
        ],
    }
}
