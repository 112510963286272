import React from 'react'
import { styled } from '@mui/material/styles'
import MatTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const LightTooltip = styled(
  ({ className, ...props }: TooltipProps) =>
    <MatTooltip {...props} classes={{ popper: className }} />
)(
  ({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#667689',
    // boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: 8,
    boxShadow: `3px 4px 8px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`
  },
}))