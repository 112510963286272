import { Grid } from '@material-ui/core'
import React from 'react'
import './index.scss'

const Tabs = ({ active, action, data, onChange, children }) => {
    return (
        <Grid container>
            <Grid container item alignItems="center" justifyContent="space-between">
                <Grid item xs>
                    <ul className="tab-view-container">
                        {data &&
                            data?.length > 0 &&
                            data?.map((tab, index) => (
                                <li
                                    key={tab.key}
                                    id={`auto-book-apt-${tab.key}-btn`}
                                    className={`tab ${tab.key === active.key ? 'tab-active' : ''}`}
                                    onClick={() => onChange(data[index])}>
                                    <span className="tab-name">{tab.value}</span>
                                </li>
                            ))}
                    </ul>
                </Grid>
                {action && (
                    <Grid item xs>
                        {action}
                    </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
                <div className="tab-content">{children}</div>
            </Grid>
        </Grid>
    )
}

export default Tabs
