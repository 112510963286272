import React, { useState } from 'react'
import moment from 'moment'
import {
    Grid,
    Typography,
    TextField,
    Button,
    makeStyles,
} from '@material-ui/core'
import Avatar from '../../../assests/images/avatar.svg'
import IconDate from '../../../assests/images/icon_date.svg'
import './styles.scss'

const useStyles = makeStyles({
    addendumItemContainer: {
        width: '100%',
        borderBottom: '1px solid #E9ECF1',
        marginTop: '16px',
    },
    avatar: {
        width: '30px',
        height: '30px',
    },
    username: {
        fontSize: '14px',
        color: '#303E4E',
        fontWeight: 500,
    },
    iconDate: {
        marginRight: '10px',
    },
    userInfoWrapper: {
        alignItems: 'center',
        display: 'flex',
    },
    avatarWrapper: {
        marginRight: '16px',
    },
    usernameWrapper: {},
    descriptionWrapper: {
        marginTop: '16px',
        wordBreak: 'break-all',
    },
    createDateWrapper: {
        display: 'flex',
        marginTop: '16px',
        marginBottom: '16px',
    },
    showMore: {
        background: 'none !important',
        color: '#5571c6',
        fontSize: '13px',
        height: '13px',
        fontWeight: '400',
        paddingLeft: '0',
    },
    showLessText: {
        marginLeft: '3px',
    },
})

const AddendumItem = ({ dataInfo }) => {
    const LENGTH_SHOW_MORE = 400
    const classes = useStyles()
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleShow = () => {
        setIsExpanded(!isExpanded)
    }

    return (
        <Grid className={classes.addendumItemContainer}>
            <Grid className={classes.userInfoWrapper}>
                <div className={classes.avatarWrapper}>
                    <img
                        className={classes.avatar}
                        src={dataInfo.avatar || Avatar}
                        alt="Avatar"
                    />
                </div>
                <div className={classes.usernameWrapper}>
                    <Typography
                        className={classes.username}>
                        {dataInfo.first_name}{' '}
                        {dataInfo.last_name}
                    </Typography>
                </div>
            </Grid>
            <Grid className={classes.descriptionWrapper}>
                <Typography className={classes.description}>
                    {dataInfo.description.length >
                        LENGTH_SHOW_MORE && !isExpanded
                        ? `${dataInfo.description.substring(
                              0,
                              LENGTH_SHOW_MORE,
                          )}...`
                        : dataInfo.description}
                    {dataInfo.description.length >
                        LENGTH_SHOW_MORE && (
                        <Button
                            className={classes.showMore}
                            onClick={toggleShow}>
                            {!isExpanded ? (
                                'Show more'
                            ) : (
                                <span
                                    className={
                                        classes.showLessText
                                    }>
                                    Show less
                                </span>
                            )}
                        </Button>
                    )}
                </Typography>
            </Grid>
            <Grid className={classes.createDateWrapper}>
                <img
                    className={classes.iconDate}
                    src={IconDate}
                    alt="Icon Date"
                />
                <Typography>
                    {dataInfo.createdAt &&
                        moment(dataInfo.createdAt).format(
                            'MMM DD, YYYY',
                        )}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default AddendumItem
