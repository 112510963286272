import React from 'react';

export default function TeleDisableVideoUserIcon() {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" rx="80" fill="#253547" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38 98.6294C38 98.6294 48.4579 82.5525 57.5663 81.2144C64.6508 79.8745 66.3371 81.5493 69.7104 83.5592C77.8065 87.9131 82.8667 87.5781 90.9639 83.2242C93.9997 81.5494 95.6866 80.2102 102.434 81.2144C111.542 82.5543 122 98.6295 122 98.6295C102.434 132.457 57.5672 132.457 38.001 98.6295L38 98.6294ZM79.8298 36C91.6371 36 101.419 45.3777 101.419 57.1006C101.419 68.8229 91.6365 78.5349 79.8298 78.5349C68.0225 78.5349 58.5763 68.8223 58.5763 57.1006C58.5763 45.3782 68.022 36 79.8298 36Z" fill="#405062" />
    </svg>
  );
}
