import React, { useCallback, useEffect, useState } from 'react'
import {
    Box,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Modal,
    Radio,
    RadioGroup,
    TextField,
    makeStyles,
    withStyles,
} from '@material-ui/core'
import { Stepper, Step, StepLabel, Checkbox } from '@mui/material'
import ScheduleIcon from '@mui/icons-material/Schedule'
import moment from 'moment'
import CheckIcon from '@mui/icons-material/Check'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import SearchPatientInput from 'components/custom/SearchPatientInput'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { PATIENT_VISIT_TYPE, optionTZ } from '../constant'
import { ModalEl, NewTimePicker, SelectEl } from '../../../shared/elements'
import { searchProvider } from 'services/Provider/provider'
import { getFullName } from 'utilities/formats'
import { Typography } from '@mui/material'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import { Link } from 'react-router-dom'
import { timezoneFormatter } from 'utilities/formatTimeZone'
import PatientInformation from 'components/custom/PatientInformation'
import CheckboxOption from '../../../components/ClinicalNotes/reusable/CheckboxOptions'
import DatePickerInput from '../Views/DatePickerInput'
import TimePicker from 'shared/elements/TimePicker'
import { Popover } from '@mui/material'
import RecurringForm from '../Views/RecurringForm'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { Tooltip } from 'shared/elements/Tooltip'
import { ReactComponent as CalenderIcon } from '../../../assests/icons/calendar.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assests/icons/arrow_Icon.svg'
import SelectTimeRange from '../Views/SelectTimeRange'
import AllViewMiniCalendar from '../Views/SelectTimeRangeV2'
import { isObjectNull } from 'containers/CalendarScheduler/utils'
import { _getClinicalNotesFilterProvider } from 'containers/ClinicalNotes/api/services';
// import { NewTimePicker } from '../../../shared/elements'
import { ReactComponent as ActiveIcon } from 'assests/icons/activeIcon.svg'
import { Close } from '@material-ui/icons'
import { useNotification } from 'shared/elements/Notification'
import { insuranceMatching } from 'services/Calendar'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'

const useStyles = makeStyles(() => ({
    fieldSet: {
        width: '100%',
        border: '1px solid #666',
        borderRadius: '5px',
        paddingBottom: '20px',
        marginBottom: '28px',
    },
    BorderRightGrid: {
        borderRight: '1px solid #EEEEEE ',
    },
    siderBaropenDateTimeView: {
        flexGrow: 1, // Override flexGrow: "0"
        maxWidth: '100%', // Override maxWidth: "100%"
        flexBasis: '0', // Override flexBasis: 0
        // Add any additional styles you want to apply
        textAlign: 'center',
    },
    rotateSvgArrow: {
        // rotate: "90deg",
        maxWidth: '60px',
    },
    dateTimeContainer: {
        display: 'flex',
        // justifyContent: "space-between",
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    insuranceModal: {
        '& .insurance_modal_title': {
            display: 'flex',
            padding: '10px 20px',
            justifyContent: 'space-between',
        },
        '& .insurance_modal_body': {
            padding: '10px 20px',
            display: 'flex',
            gap: 20,
        },
        '& .insurance_modal_section_container': {
            display: 'flex',
            flexDirection: 'column',
            width: 518,
            maxWidth: 518,
            gap: 10,
        },
        '& .insurance_modal_header_typography': {
            color: '#303E4E !important',
            fontFamily: 'Rubik !important',
            fontSize: '16px !important',
            fontStyle: 'normal !important',
            fontWeight: 500,
            lineHeight: '16px !important',
        },
        '& .insurance_modal_content_typography': {
            color: '#303E4E !important',
            fontFamily: 'Rubik !important',
            fontSize: '16px !important',
            fontStyle: 'normal !important',
            fontWeight: 400,
            lineHeight: '16px !important',
        },
        '& .insurance_modal_sub_content_typography': {
            color: '#667689 !important',
            fontFamily: 'Rubik !important',
            fontSize: '14px !important',
            fontStyle: 'normal !important',
            fontWeight: 400,
            lineHeight: '16px !important',
        },
        '& .insurance_modal_section': {
            borderRadius: '5px',
            border: '1px solid #D9D9D9',
            background: '#FFF',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            height: 400,
            maxHeight: 400,
        },
        '& .insurance_modal_provider_info': {
            display: 'flex',
            gap: 10,
            alignItems: 'center',
        },
        '& .insurance_modal_provider_location, .insurance_modal_provider_health_plan': {
            display: 'flex',
            gap: 10,
            flexDirection: 'column',
        },
        '& .insurance_modal_health_insurance': {
            borderRadius: '5px',
            border: '1px solid #D9D9D9',
            background: '#FFF',
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            gap: 15,
            height: 235,
            maxHeight: 235,
            overflowY: 'scroll',
        },
        '& .insurance_modal_insurance_list': {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        '& .insurance_modal_insurance_list_info': {
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            width: '60%',
        },
    },
    translatorBox: {
        marginTop: 5,
        paddingLeft: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        '& .MuiButtonBase-root': {
            paddingRight: '0px !important',
        },
    },
    insuranceMatchIconDisable: {
        '& svg path': {
            fill: 'rgba(85, 113, 198, 0.5) !important',
        },
    },
}))

const defaultInsuranceTypeStyle = {
    primary: {
        border: '1px solid #E8FDF0',
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    secondary: {
        border: '1px solid #DCE5FF',
        background: '#E1E9FF',
        color: '#5571C6',
    },
    tertiary: {
        border: '1px solid #FCF0D6',
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
}

const defaultHealthDummyList = [
    'Health Plan 1',
    'Health Plan 2',
    'Health Plan 3',
    'Health Plan 4',
    'Health Plan 5',
    'Health Plan 6',
    'Health Plan 7',
    'Health Plan 8',
]

const defaultInsuranceDummyList = [
    {
        name: 'Insurance 1',
        start: moment().format('MM/DD/YYYY'),
        end: moment().format('MM/DD/YYYY'),
        type: 'primary',
    },
    {
        name: 'Insurance 2',
        start: moment().format('MM/DD/YYYY'),
        end: moment().format('MM/DD/YYYY'),
        type: 'secondary',
    },
    {
        name: 'Insurance 3',
        start: moment().format('MM/DD/YYYY'),
        end: moment().format('MM/DD/YYYY'),
        type: 'tertiary',
    },
]

function BookingHeader({
    listSpecialty,
    listVisitType,
    classes,
    formik,
    customHeader,
    appointmentId,
    sidebarOpen,
    setIsTimingChanging,
    patientAgeCheckText,
    setPatientAgeCheckText,
    availErr,
    availStatus,
    isAppointmentCompleted,
    checkNeedToCreateCarium
}) {
    const notification = useNotification()

    const [patient, setPatientDetails] = useState({})
    const [healthPlanList, setHealthPlanList] = useState([])
    const [insuranceList, setInsuranceList] = useState([])
    const [providerDetails, setProviderDetails] = useState({})
    const [insuranceMatchingGeneralInfo, setInsuranceMatchingGeneralInfo] = useState({})    
    const isDisableInsuranceMatch = React.useMemo(() => {
        return formik?.values?.practitioner_id === '' || formik?.values?.patient_id === ''
    }, [formik?.values?.practitioner_id, formik?.values?.patient_id])
    // console.log("providerDetails",providerDetails)

    const handleChangeAgeCheckText = (patientDOB, is_treat_adult, is_treat_child, is_geriatric_care) => {
        const dob = new Date(patientDOB)
        const now = new Date()

        const diff = now - dob

        const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365))

        if (age >= 18 && age < 65 && !is_treat_adult) {
            return 'Provider does not treat adult'
        }

        if (age >= 6 && age < 18 && !is_treat_child) {
            return 'Provider does not treat children'
        }

        if (age >= 65 && !is_geriatric_care) {
            return 'Provider does not treat geriatric'
        }

        return ''
    }

    const mystyle = useStyles()
    const handleSearch = async (keyword, type) => {
        if (!keyword) return []
        // let payload = {
        //     page: 1,
        //     limit: 20,
        //     name: keyword,
        // }
        // const res = await _getClinicalNotesFilterProvider(payload)
        // if (res?.data?.data?.data) {
        //     return res?.data?.data?.data?.map((el) => {
        //         return {
        //             id: el.id,
        //             value: el.name,
        //             label: `${el.name} - ${timezoneFormatter(el.timezone) ?? 'N/A'}`,
        //             key: el.id,
        //             providerAccount: true,
        //             supervising_physician: '',
        //         }
        //     })
        // }

        const res = await searchProvider({
            page: 1,
            size: 100000,
            name: keyword,
            role:
                type === 'rendering_provider'
                    ? 'bh_provider,mid_bh_provider,medical_provider,mid_medical_provider,therapist'
                    : 'bh_provider,medical_provider,therapist',
            _sort: '-_id',
        })

        if (res?.data) {
            return res.data.map((el) => {
                const fullName = getFullName(el.first_name, el.last_name)
                return {
                    id: el.id,
                    value: fullName,
                    label: `${fullName} - ${timezoneFormatter(el.timezone) ?? 'N/A'}`,
                    key: el.id,
                    providerAccount: true,
                    supervising_physician: el.supervising_physician,
                }
            })
        }
        return []
    }

    const disableStartTime = (startTime, appointmentDate) => {
        if (appointmentId === '' || !startTime || !appointmentDate) return false
        const EndDate = moment(`${appointmentDate} ${startTime}`, 'YYYY-MM-DD HH:mm').format(
            'MM/DD/YYYY HH:mm',
        )
        return moment(EndDate).isBefore(moment().format('MM/DD/YYYY HH:mm'), 'minute')
    }

    const setps = [1, 2]
    const styles = (theme) => ({
        verticalConnector: {
            marginLeft: theme.spacing(2),
            padding: 0,
            flex: '1 0 auto',
        },
    })

    const steps = [1, 2]
    const [activeStep, setActiveStep] = React.useState(-1)
    const [completed, setCompleted] = React.useState({})
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined
    const [showViewDay, setShowViewDay] = useState(false)
    const [insuranceMatchingModal, setInsuranceMatchingModal] = useState(false)

    //status popover
    const validationSchema = yup.object({
        once_every: yup.string().required('This field is required!'),
        unit: yup.string().required('This Field is required'),
        days: yup.array().when("unit", (unit, schema) => {
            if (unit !== 'day')
                return schema.min(1, 'This field is required')
            return schema
        }),
        end_date: yup.string().required('This Field is required'),
        end_type: yup.string().required('This Field is required')
    })

    const initialValues = {
        is_reoccurring_appointment: true,
        once_every: formik.values.once_every,
        end_date: formik.values.end_date || moment().format('YYYY-MM-DD'),
        unit: formik.values.unit,
        end_type: formik.values.end_type,
        days: formik.values.days,
    }

    const save = () => {
        setAnchorEl(null)
        formik.setFieldValue('once_every', formikRecurring.values.once_every)
        formik.setFieldValue('end_date', formikRecurring.values.end_date)
        formik.setFieldValue('unit', formikRecurring.values.unit)
        formik.setFieldValue('end_type', formikRecurring.values.end_type)
        formik.setFieldValue('days', formikRecurring.values.days)
    }

    const formikRecurring = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: save
    })

    const handleClose2 = () => {
        setAnchorEl(null)
        formik.setFieldValue('is_reoccurring_appointment', false)
        formik.setFieldValue('once_every', '')
        formik.setFieldValue('end_date', '')
        formik.setFieldValue('unit', '')
        formik.setFieldValue('end_type', '')
        formik.setFieldValue('days', [])
    }

    useEffect(() => {
        stepActivater()
    }, [activeStep])

    useEffect(() => {
        stepActivater()
    }, [formik.values.specialty_id, formik.values.visit_type_id])

    const stepActivater = () => {
        if (formik.values.specialty_id > 0) {
            setActiveStep(0)
            const newCompleted = completed
            newCompleted[activeStep] = true
            setCompleted(newCompleted)
        }
        if (formik.values.visit_type_id > 0) {
            setActiveStep(1)
            const newCompleted = completed
            newCompleted[activeStep] = true
            setCompleted(newCompleted)
        }
        const newCompleted = completed
        newCompleted[activeStep] = true
        setCompleted(newCompleted)
    }

    // const save = () => {
    //     setAnchorEl(null)
    // }

    const flattenInsuranceMatch = (data) => {
        if (!data || isObjectNull(data)) return

        return {
            patientInfo: {
                patientName: `${data?.patient?.first_name} ${data?.patient?.last_name}`,
                patientState:
                    data?.patient?.state === '' || !data?.patient?.state
                        ? 'N/A'
                        : stateList?.find(
                              (stateListItem) => stateListItem?.state === data?.patient?.state,
                          )?.stateLabel,
            },
            providerInfo: {
                providerName: `Dr. ${data.provider.first_name} ${data.provider.last_name}`,
                providerState:
                    data?.provider?.states?.length === 0
                        ? 'N/A'
                        : data?.provider?.states
                              ?.map(
                                  (stateItem) =>
                                      stateList?.find(
                                          (stateListItem) =>
                                              stateListItem?.state === stateItem?.state,
                                      )?.stateLabel,
                              )
                              ?.join(', '),
                speciality:
                    !providerDetails?.specialities ? '' : (typeof providerDetails?.specialities[0] === 'string'
                        ? providerDetails?.specialities[0]
                        : providerDetails?.specialities[0]?.value)
            },
            providerHealthPlans: data?.provider_health_plan?.map(
                (healthPlanItem) => healthPlanItem?.plan_name,
            ),
            patientInsurances: data?.patient_insurance?.map((patientInsuranceItem) => {
                const { insurance, type } = patientInsuranceItem
                return {
                    name: insurance?.plan?.display,
                    start: insurance?.policy_effective
                        ? moment(insurance?.policy_effective).format('MM/DD/YYYY')
                        : 'N/A',
                    end: insurance?.policy_end
                        ? moment(insurance?.policy_end).format('MM/DD/YYYY')
                        : 'N/A',
                    type,
                }
            }),
        }
    }

    const getInsuranceMatchingData = useCallback(() => {
        if (formik.values.practitioner_id === '' || formik.values.patient_id === '') {
            notification('Please add a provider and a paient first!', 'error')
            return
        }
        const payloadData = {
            provider_id: formik.values.practitioner_id,
            patient_id: formik.values.patient_id,
        }
        insuranceMatching(payloadData)
            ?.then((res) => {
                const formattedResponse = flattenInsuranceMatch(res.data?.data)

                setInsuranceMatchingGeneralInfo({
                    patientInfo: formattedResponse?.patientInfo,
                    providerInfo: formattedResponse?.providerInfo,
                })

                setHealthPlanList(formattedResponse?.providerHealthPlans)

                setInsuranceList(formattedResponse?.patientInsurances)

                setInsuranceMatchingModal(true)
            })
            ?.catch(() => {
                setInsuranceMatchingModal(false)
                notification('Something went wrong!', 'error')
            })
    }, [formik.values?.practitioner_id, formik.values?.patient_id])

    useEffect(() => {
        if (!isObjectNull(patient) && !isObjectNull(providerDetails) && appointmentId === '') {
            setPatientAgeCheckText(
                handleChangeAgeCheckText(
                    patient?.dob,
                    providerDetails?.is_treat_adult,
                    providerDetails?.is_treat_child,
                    providerDetails?.is_geriatric_care,
                ),
            )
        } else {
            setPatientAgeCheckText('')
        }
    }, [patient, providerDetails])

    return (
        <>
            <ModalEl
                width={1100}
                open={insuranceMatchingModal}
                onClose={() => setInsuranceMatchingModal(false)}>
                <div className={mystyle.insuranceModal}>
                    <div className="insurance_modal_title">
                        <Typography className="insurance_modal_header_typography">
                            Insurance Matching
                        </Typography>
                        <IconButton onClick={() => setInsuranceMatchingModal(false)}>
                            <Close style={{ width: 15, height: 15 }} />
                        </IconButton>
                    </div>
                    <hr color="#D9D9D9" size="1" width="98%" style={{ margin: '0 0 0 10px' }} />

                    <div className="insurance_modal_body">
                        <div className="insurance_modal_section_container">
                            <Typography className="insurance_modal_header_typography">
                                Provider
                            </Typography>
                            <div className="insurance_modal_section">
                                <div className="insurance_modal_provider_info">
                                    <Typography className="insurance_modal_header_typography">
                                        {insuranceMatchingGeneralInfo?.providerInfo?.providerName}
                                    </Typography>
                                    <Typography className="insurance_modal_content_typography">
                                        {insuranceMatchingGeneralInfo?.providerInfo?.speciality}
                                    </Typography>
                                </div>
                                <div className="insurance_modal_provider_location">
                                    <Typography className="insurance_modal_header_typography">
                                        States
                                    </Typography>
                                    <Typography className="insurance_modal_content_typography">
                                        {insuranceMatchingGeneralInfo?.providerInfo?.providerState}
                                    </Typography>
                                </div>
                                <div className="insurance_modal_provider_health_plan">
                                    <Typography className="insurance_modal_header_typography">
                                        Health Plans
                                    </Typography>
                                    <div className="insurance_modal_health_insurance">
                                        {healthPlanList?.length > 0 ? (
                                            healthPlanList?.map(
                                                (healthPlanItem, healthPlanItemIndex) => {
                                                    return (
                                                        <Typography
                                                            key={healthPlanItemIndex}
                                                            className="insurance_modal_content_typography">
                                                            {healthPlanItem}
                                                        </Typography>
                                                    )
                                                },
                                            )
                                        ) : (
                                            <Typography
                                                style={{
                                                    fontFamily: 'Rubik',
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                }}>
                                                No health plan found!
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="insurance_modal_section_container">
                            <Typography className="insurance_modal_header_typography">
                                Patient
                            </Typography>
                            <div className="insurance_modal_section">
                                <div className="insurance_modal_provider_info">
                                    <Typography className="insurance_modal_header_typography">
                                        {insuranceMatchingGeneralInfo?.patientInfo?.patientName}
                                    </Typography>
                                </div>
                                <div className="insurance_modal_provider_location">
                                    <Typography className="insurance_modal_header_typography">
                                        States
                                    </Typography>
                                    <Typography className="insurance_modal_content_typography">
                                        {insuranceMatchingGeneralInfo?.patientInfo?.patientState}
                                    </Typography>
                                </div>
                                <div className="insurance_modal_provider_health_plan">
                                    <Typography className="insurance_modal_header_typography">
                                        Insurance
                                    </Typography>
                                    <div
                                        className="insurance_modal_health_insurance"
                                        style={{ gap: 20 }}>
                                        {insuranceList?.length > 0 ? (
                                            insuranceList?.map(
                                                (insuranceItem, insuranceItemIndex) => {
                                                    return (
                                                        <div
                                                            className="insurance_modal_insurance_list"
                                                            key={insuranceItemIndex}>
                                                            <div className="insurance_modal_insurance_list_info">
                                                                <Typography className="insurance_modal_content_typography">
                                                                    {insuranceItem.name}
                                                                </Typography>
                                                                <Typography className="insurance_modal_sub_content_typography">
                                                                    {insuranceItem.start}
                                                                    {'-'}
                                                                    {insuranceItem.end}
                                                                </Typography>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div
                                                                    style={{
                                                                        borderRadius: 5,
                                                                        border: defaultInsuranceTypeStyle[
                                                                            insuranceItem?.type?.toLocaleLowerCase()
                                                                        ]?.border,
                                                                        background:
                                                                            defaultInsuranceTypeStyle[
                                                                                insuranceItem?.type?.toLocaleLowerCase()
                                                                            ]?.background,
                                                                        padding: 5,
                                                                        textTransform: 'capitalize',
                                                                        height: 'max-content',
                                                                    }}>
                                                                    <Typography
                                                                        style={{
                                                                            fontFamily: 'Rubik',
                                                                            fontSize: 13,
                                                                            color: defaultInsuranceTypeStyle[
                                                                                insuranceItem.type?.toLocaleLowerCase()
                                                                            ].color,
                                                                        }}>
                                                                        {insuranceItem.type}{' '}
                                                                        Insurance
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                },
                                            )
                                        ) : (
                                            <Typography
                                                style={{
                                                    fontFamily: 'Rubik',
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                }}>
                                                No insurance found!
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalEl>

            <Grid container spacing={2}>
                <HtmlTooltip placement="top-start" arrow>
                    <Popover
                        id={endId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose2}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            style: {
                                background: 'transparent',
                            },
                        }}>
                        <RecurringForm
                            formik={formikRecurring}
                            classes={classes}
                            save={save}
                            cancel={handleClose2}
                        />
                    </Popover>
                </HtmlTooltip>
                <fieldset className={mystyle.fieldSet}>
                    <legend>Appointment</legend>
                    <Grid container xs={12} item alignItems="unset" spacing={2}>
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Rendering Provider <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <SearchProviderInput
                                value={formik.values.practitioner_id}
                                handleInputChange={(e) => {
                                    formik.setFieldValue('practitioner_id', e.target.value || '')
                                }}
                                handleAddRow={(value) => {
                                    if(formik.values.practitioner_id !== value?.id){
                                        formik.setFieldValue('location_id','')
                                    } 
                                    formik.setFieldValue('practitioner_id', value?.id || '')
                                    formik.setFieldValue(
                                        'supervising_provider',
                                        value?.supervising_physician || '',
                                    )
                                    const fullName = value?.value.split(' ')
                                    const firstName = fullName?.length > 0 && fullName[0]
                                    const lastName =
                                        fullName?.length > 0 && fullName[fullName?.length - 1]
                                    formik.setFieldValue('practitioner_first_name', firstName || '')
                                    formik.setFieldValue('practitioner_last_name', lastName || '')
                                }}
                                error={
                                    formik.touched.practitioner_id &&
                                    Boolean(formik.errors.practitioner_id)
                                }
                                helperText={
                                    formik.touched.practitioner_id && formik.errors.practitioner_id
                                }
                                getProviderInfo={(provider) => {
                                    formik.setFieldValue(
                                        'practitioner_first_name',
                                        provider?.first_name || '',
                                    )
                                    formik.setFieldValue(
                                        'practitioner_last_name',
                                        provider?.last_name || '',
                                    )
                                    formik.setFieldValue(
                                        'supervising_provider',
                                        provider?.supervising_physician || '',
                                    )
                                    setProviderDetails(provider)
                                }}
                                placeholder="Search Rendering Provider"
                                handleSearch={async (keyword) => {
                                    return await handleSearch(keyword, 'rendering_provider')
                                }}
                                fromSchedulePage={true}
                                disabled={isAppointmentCompleted}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Supervising Provider
                            </FormLabel>
                            <SearchProviderInput
                                value={formik.values.supervising_provider}
                                handleInputChange={(e) => {
                                    formik.setFieldValue(
                                        'supervising_provider',
                                        e.target.value || '',
                                    )
                                }}
                                handleAddRow={(value) => {
                                    formik.setFieldValue('supervising_provider', value?.id || '')
                                }}
                                error={
                                    formik.touched.supervising_provider &&
                                    Boolean(formik.errors.supervising_provider)
                                }
                                helperText={
                                    formik.touched.supervising_provider &&
                                    formik.errors.supervising_provider
                                }
                                placeholder="Supervising Provider"
                                handleSearch={async (keyword) => {
                                    return await handleSearch(keyword, 'supervising_provider')
                                }}
                                disabled
                                hideSearchIcon
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems="unset"
                        spacing={2}
                        style={{ padding: '8px', flexWrap: 'nowrap' }}>
                        <Grid xs={4}>
                            <FormLabel
                                component="p"
                                className="input-form-label"
                                style={{ paddingLeft: 26, marginBottom: 0 }}>
                                Speciality<span className={classes.required_text}>*</span>
                            </FormLabel>
                        </Grid>
                        <Grid xs={4} style={{ paddingLeft: '8px' }}>
                            <FormLabel component="p" className="input-form-label" style={{ marginBottom: 0 }}>
                                Patient <span className={classes.required_text}>*</span>
                            </FormLabel>
                        </Grid>
                        <Grid xs={4} style={{ paddingLeft: '8px' }}>
                            <FormLabel component="p" className="input-form-label" style={{ marginBottom: 0 }}>
                                Date & Time <span className={classes.required_text}>*</span>
                            </FormLabel>
                        </Grid>
                    </Grid>

                    <Grid container item alignItems="unset" spacing={2} style={{ padding: '8px', paddingLeft:'0px' }}>
                        <Grid container item xs={4} className={mystyle.BorderRightGrid}>
                            <Grid item xs={1} alignItems="flex-end" style={{ marginTop: '10px' }}>
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                    alternativeLabel
                                    sx={{
                                        '& .MuiStepLabel-root': {
                                            padding: '0px',
                                        },
                                        '& .MuiStepIcon-root': {
                                            width: '10px', // Adjust the width as needed
                                            height: '10px', // Adjust the height as needed
                                        },
                                        '& .MuiStepConnector-root': {
                                            marginLeft: '4px',
                                            position: 'initial',
                                        },
                                        '& .MuiStepConnector-line': {
                                            minHeight: '50px',
                                        },
                                        '& .MuiStepIcon-text': {
                                            display: 'none',
                                        },
                                        '& .MuiStepper-root': {
                                            alignItems: 'flex-end',
                                            paddingRight: '12px',
                                        },
                                    }}>
                                    {steps.map((item, index) => (
                                        <Step key={item} completed={completed[index]}>
                                            <StepLabel></StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>

                            <Grid item xs={11}>
                                <Box>
                                    <SelectEl
                                        name="specialty_id"
                                        placeholder="Select Specialty"
                                        value={formik.values.specialty_id}
                                        options={listSpecialty}
                                        selectProps={{
                                            style: {
                                                height: 40,
                                            },
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldValue('specialty_id', e.target.value)
                                            formik.setFieldValue('visit_type_id', '')
                                            if (e.target.value) setActiveStep(0)
                                        }}
                                        error={
                                            formik.touched.specialty_id &&
                                            Boolean(formik.errors.specialty_id)
                                        }
                                        helperText={
                                            formik.touched.specialty_id &&
                                            formik.errors.specialty_id
                                        }
                                        disabled={isAppointmentCompleted}
                                        // disabled={!!formik.values.id}
                                    />
                                </Box>
                                <Box style={{ marginTop: '8px' }}>
                                    <SelectEl
                                        className="mt-2"
                                        name="visitType"
                                        placeholder="Select Visit Type"
                                        value={formik.values.visit_type_id}
                                        options={listVisitType}
                                        selectProps={{
                                            style: {
                                                height: 40,
                                            },
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldValue('visit_type_id', e.target.value)
                                            formik.setFieldValue(
                                                'duration',
                                                listVisitType.find(
                                                    (item) => item.key === e.target.value,
                                                )?.duration,
                                            )
                                            formik.setFieldValue(
                                                'color_tag',
                                                listVisitType.find(
                                                    (item) => item.key === e.target.value,
                                                )?.color_tag,
                                            )
                                            if (e.target.value) setActiveStep(1)
                                        }}
                                        error={
                                            formik.touched.visit_type_id &&
                                            Boolean(formik.errors.visit_type_id)
                                        }
                                        helperText={
                                            formik.touched.visit_type_id &&
                                            formik.errors.visit_type_id
                                        }
                                        disabled={formik?.values?.specialty_id === '' || isAppointmentCompleted}
                                    />
                                </Box>
                            </Grid>
                            <Box>
                                <FormLabel
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                    }}>
                                    Date appointment first offered
                                </FormLabel>
                                <Grid xs={12} container style={{marginTop:'3px'}}>
                                    <Grid item xs={7} >
                                        <DatePicker
                                            name="dob"
                                            value={formik.values.appointment_first_offered_date}
                                            onChange={(value) => {
                                                formik.setFieldValue('appointment_first_offered_date', value)
                                            }}
                                            notDefaultValue
                                        />
                                    </Grid>
                                    <Grid Grid item xs={5} style={{paddingLeft:'15px'}}>
                                        <CheckboxOption
                                            option={{
                                                label: 'Patient declined',
                                                value: false,
                                            }}
                                            onChange={(e) => {
                                                if(e.target.checked){
                                                    formik.setFieldValue(
                                                        'is_patient_declined',
                                                        'true'
                                                    )
                                                }else{
                                                    formik.setFieldValue(
                                                        'is_patient_declined',
                                                        'false'
                                                    )
                                                }
                                                
                                            }}
                                            checked={formik.values.is_patient_declined === 'true' ? true : false}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item direction="column" xs={4} className={mystyle.BorderRightGrid}>
                            <SearchPatientInput
                                value={formik.values.patient_id}
                                handleInputChange={(e) => {
                                    formik.setFieldValue('patient_id', e.target.value)
                                    setPatientDetails('')
                                }}
                                handleAddRow={(value) => {
                                    formik.setFieldValue('patient_id', value?.id)
                                }}
                                error={
                                    formik.touched.patient_id && Boolean(formik.errors.patient_id)
                                }
                                helperText={formik.touched.patient_id && formik.errors.patient_id}
                                placeholder="Search Patient"
                                getDetailPatient={(patient) => {
                                    const check = (patient?.carium_id || patient?.inactive_carium_id)  ? false : true
                                    checkNeedToCreateCarium(check)
                                    formik.setFieldValue(
                                        'patientName',
                                        `${patient?.first_name} ${patient?.last_name}`,
                                    )
                                    formik.setFieldValue('insurance', patient?.insurance)
                                    if(!formik?.values?.id || formik?.values?.id === '') formik.setFieldValue('is_require_translator', patient?.require_translator || false)
                                    setPatientDetails(patient)
                                }}
                                disabled={isAppointmentCompleted}
                            />
                            <Box style={{ marginTop: '9px' }}>
                                {patient && <PatientInformation patient={patient} />}
                            </Box>
                            <Box
                                onClick={() => getInsuranceMatchingData()}
                                className={
                                    isDisableInsuranceMatch ? mystyle.insuranceMatchIconDisable : ''
                                }
                                style={{
                                    paddingLeft: '4px',
                                    marginTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 5,
                                    cursor: (isDisableInsuranceMatch || isAppointmentCompleted) ? 'default' : 'pointer',
                                    pointerEvents: isAppointmentCompleted ? 'none' : 'auto'
                                }}>
                                <ActiveIcon />
                                <p
                                    style={{
                                        fontFamily: 'Rubik',
                                        color: isDisableInsuranceMatch
                                            ? 'rgba(85, 113, 198, 0.5)'
                                            : '#5571C6',
                                        fontWeight: 400,
                                        fontSize: 16,
                                        margin: 0,
                                    }}>
                                    Insurance Matching
                                </p>
                            </Box>
                            <Box className={mystyle.translatorBox}>
                                <CheckboxOption
                                    option={{
                                        label: 'Require translator',
                                        value: false,
                                    }}
                                    onChange={(e) => {
                                        formik.setFieldValue(
                                            'is_require_translator',
                                            e.target.checked,
                                        )
                                    }}
                                    checked={formik.values.is_require_translator}
                                    disabled={isAppointmentCompleted}
                                />
                                <div
                                    style={{
                                        padding: '5px 8px',
                                        borderRadius: 5,
                                        background: '#FFF1F1',
                                        height: 'max-content',
                                        width: 'max-content',
                                        display: patientAgeCheckText === '' ? 'none' : 'flex',
                                    }}>
                                    <Typography
                                        style={{
                                            color: '#E81212',
                                            fontFamily: 'Rubik',
                                            fontSize: 14,
                                            fontWeight: 400,
                                        }}>
                                        {patientAgeCheckText}
                                    </Typography>
                                </div>
                            </Box>
                        </Grid>
                        <Grid container item style={{ paddingLeft: '8px' }} xs={4}>
                            <Grid
                                container
                                item
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '300px',
                                    alignItems: 'start',
                                }}>
                                <Grid item xs={9}>
                                    <DatePickerInput
                                        minDate={moment()}
                                        appointmentModal={{
                                            start: formik.values.date,
                                        }}
                                        onChange={(e) => {
                                            if (e)
                                                formik.setFieldValue('date', e.format('YYYY-MM-DD'))
                                            if (formik?.values?.id) setIsTimingChanging(true)
                                        }}
                                        error={false}
                                        helperText={null}
                                        readOnly={false}
                                        disabled={isAppointmentCompleted}
                                    />
                                    {formik.touched.date && Boolean(formik.errors.date) && (
                                        <p
                                            style={{
                                                margin: '8px 0 0 0',
                                            }}
                                            className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                                            {formik.errors.date}
                                        </p>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    className="d-flex"
                                    justifyContent="flex-start"
                                    alignItems="center">
                                    <Box
                                        style={{
                                            border: '1px solid #b4b0b0',
                                            borderRadius: '20px',
                                            padding: '5px',
                                            marginLeft: '20px',
                                            background: '#d5f0e8',
                                            marginTop: '2px',
                                            pointerEvents: isAppointmentCompleted ? 'none' : 'auto'
                                        }}
                                        onClick={() => {
                                            setShowViewDay(true)
                                        }}>
                                        <CalenderIcon
                                            style={{ display: 'block', cursor: 'pointer' }}
                                        />
                                    </Box>
                                    {showViewDay && (
                                        <AllViewMiniCalendar
                                            values={formik.values}
                                            idProvider={formik.values.practitioner_id}
                                            idProviderName={`${formik?.values?.practitioner_first_name} ${formik?.values?.practitioner_last_name}`}
                                            open={showViewDay}
                                            onClose={() => {
                                                setShowViewDay(false)
                                            }}
                                            onOke={() => {
                                                setShowViewDay(false)
                                            }}
                                            date={moment(formik.values.date, 'YYYY-MM-DD')}
                                            setFieldValue={formik.setFieldValue}
                                            duration={formik.values.duration}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                    className="d-flex"
                                    justifyContent="flex-start"
                                    alignItems="center">
                                    <Box style={{ marginTop: '8px' }}>
                                        <TextField
                                            variant="outlined"
                                            value={formik.values.duration}
                                            style={{
                                                height: 'fit-content',
                                            }}
                                            placeholder="Duration"
                                            fullWidth
                                            inputProps={{ maxLength: 3 }}
                                            onChange={(e) =>
                                                formik.setFieldValue(
                                                    'duration',
                                                    e.target.value.replace(/[^0-9]+/g, ''),
                                                )
                                            }
                                            error={
                                                formik.touched.duration &&
                                                Boolean(formik.errors.duration)
                                            }
                                            helperText={
                                                formik.touched.duration && formik.errors.duration
                                            }
                                            disabled={isAppointmentCompleted}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} className={mystyle.dateTimeContainer}>
                                <Grid
                                    item
                                    xs={5}
                                    className={sidebarOpen ? mystyle.siderBaropenDateTimeView : ''}>
                                    <Box style={{ marginTop: '8px' }}>
                                        {/* <TimePicker
                                            values={moment(
                                                `${formik.values.date} ${formik.values.start_time}`,
                                                'YYYY-MM-DD HH:mm',
                                            )}
                                            onChange={(value) => {
                                                formik.setFieldValue(
                                                    'start_time',
                                                    moment(value).format('HH:mm'),
                                                )
                                            }}
                                            labelDropdown="Start time"
                                            disabled={disableStartTime(
                                                formik?.values?.start_time,
                                                formik?.values?.date,
                                            )}
                                        /> */}
                                        <NewTimePicker
                                            values={moment(
                                                `${formik.values.date} ${formik.values.start_time}`,
                                                'YYYY-MM-DD HH:mm',
                                            )}
                                            onChange={(value) => {
                                                formik.setFieldValue(
                                                    'start_time',
                                                    moment(value).format('HH:mm'),
                                                )
                                                if (formik?.values?.id) setIsTimingChanging(true)
                                            }}
                                            disabled={disableStartTime(
                                                formik?.values?.start_time,
                                                formik?.values?.date,
                                            ) || isAppointmentCompleted}
                                            error={
                                                formik?.touched?.start_time &&
                                                Boolean(formik?.touched?.start_time)
                                            }
                                            helperText={
                                                formik.touched.start_time &&
                                                formik.errors.start_time
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        textAlign: 'center',
                                        marginRight: '6px',
                                        marginLeft: '6px',
                                    }}>
                                    <ArrowRightIcon
                                        style={{ marginTop: '20px', textAlign: 'center' }}
                                        className={
                                            sidebarOpen
                                                ? [
                                                      mystyle.siderBaropenDateTimeView,
                                                      mystyle.rotateSvgArrow,
                                                  ]
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    className={sidebarOpen ? mystyle.siderBaropenDateTimeView : ''}>
                                    <Box style={{ marginTop: '8px' }}>
                                        <NewTimePicker
                                            values={moment(
                                                `${formik.values.date} ${formik.values.end_time}`,
                                                'YYYY-MM-DD HH:mm',
                                            )}
                                            onChange={(value) => {
                                                formik.setFieldValue(
                                                    'end_time',
                                                    moment(value).format('HH:mm'),
                                                )
                                                if (formik?.values?.id) setIsTimingChanging(true)
                                            }}
                                            disabled
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Box style={{ paddingLeft: '4px', marginTop: '10px' }}>
                                    <CheckboxOption
                                        option={{
                                            label: 'Reccurring appointment',
                                            value: false,
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'is_reoccurring_appointment',
                                                e.target.checked,
                                            )
                                            if (e.target.checked) {
                                                setAnchorEl(e.currentTarget)
                                            } else {
                                                formik.setFieldValue('once_every', '')
                                                formik.setFieldValue('end_date', '')
                                                formik.setFieldValue('unit', '')
                                                formik.setFieldValue('end_type', '')
                                                formik.setFieldValue('days', [])
                                            }
                                        }}
                                        checked={formik.values.is_reoccurring_appointment}
                                        disabled={true}
                                    />
                                </Box>
                                <span
                                    style={{
                                        fontSize: '14px',
                                        color: 'red',
                                        backgroundColor: '#f2e3e3',
                                        padding: '2px',
                                    }}>
                                    {!availStatus && availErr && availErr}
                                </span>
                            </Grid>
                        </Grid>
                    </Grid>
                </fieldset>
            </Grid>
        </>
    )
}

export default BookingHeader
