import React, { useState } from 'react'
import { Box, Grid, Stack } from '@mui/material'
import TextBox from 'shared/elements/FormItem/TextBox'
import { Button, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

const useStyles = makeStyles(
    (theme) =>
        ({
            btn_anew: {
                fontSize: '14px',
                fontWeight: '400',
                '& .MuiSvgIcon-root': {
                    width: '20px',
                },
            },
            wrap_scroll_list: {
                maxHeight: '469px',
                overflowY: 'auto',
            },
            item_ric: {
                background: '#fbfcfc',
                padding: '5px 15px',
                marginBottom: '13px',
                borderRadius: '7px',
                '& .icon': {
                    '& svg': {
                        width: '22px',
                        color: '#6c6c6c',
                    },
                },
            },
        } as any),
)

interface ItemChecklist {
    label: string
    checked: boolean
}
interface Props {
    handleAddNew: (value: string) => void
    checkList: ItemChecklist[]
    handleDelete: (item: ItemChecklist) => void
    handleChangCheked: (item: ItemChecklist, checked: boolean) => void
    label?: string
}

export const Checklist = (props: Props) => {
    const { handleAddNew, checkList, handleDelete, handleChangCheked, label } = props
    const [newText, setNewText] = useState<string>('')
    const classes = useStyles()
    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item flex={1}>
                        <TextBox
                            label={label ? label : ''}
                            value={newText}
                            placeholder="Enter Comment"
                            onChange={(value) => setNewText(value)}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title={`Add New ${label}`} placement="top">
                            <Button
                                className={classes['btn_anew']}
                                variant="contained"
                                fullWidth={true}
                                // startIcon={}
                                onClick={() => {
                                    if (newText) {
                                        handleAddNew(newText)
                                        setNewText('')
                                    }
                                }}>
                                <AddCircleOutlineIcon fontSize="small" />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {checkList.length > 0 && (
                    <Box className={classes['wrap_scroll_list']}>
                        {checkList.map((item, index) => (
                            <Box key={index} className={classes['item_ric']}>
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}>
                                    <FormControlLabel
                                        label={item.label}
                                        control={
                                            <Checkbox
                                                color="primary"
                                                checked={item.checked}
                                                onChange={(e) =>
                                                    handleChangCheked(item, e.target.checked)
                                                }
                                            />
                                        }
                                    />
                                    <Box className="icon" onClick={() => handleDelete(item)}>
                                        <DeleteOutlineIcon fontSize="small" />
                                    </Box>
                                </Stack>
                            </Box>
                        ))}
                    </Box>
                )}
            </Grid>
        </>
    )
}
