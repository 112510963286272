import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import React from 'react'
import { ModalEl as Modal } from 'shared/elements'
import { Footer } from 'shared/elements/FormItem/Footer'

export const ConfirmBox = (props) => {
    return (
        <Modal
            width={600}
            title={props?.title ? props?.title : 'Referrals'}
            open={props.visible}
            onClose={() => props.onCancel?.()}>
            <Box
                sx={{
                    '&*': {
                        boxSizing: 'border-box',
                    },
                    padding: '20px',
                }}>
                <Box>
                    {/* <Typography>Please save your changes</Typography> */}
                    {props.children}
                </Box>
                <Box>
                    <Footer
                        style={{ marginTop: '2rem' }}
                        onCancel={() => {
                            props.onCancel?.()
                        }}
                        onOk={() => props.onConfirm?.()}
                        okBtnText={props.yes}
                        cancelBtnText={props.no}
                        loading={props.isLoading}
                    />
                </Box>
            </Box>
        </Modal>
    )
}
