import React, { useEffect, useState } from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import { Box, Grid, makeStyles, Typography, Tab, Tabs, withStyles } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import { Button as ButtonCustom } from 'components/custom'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { connect, useSelector } from 'react-redux'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { getVisitType, deleteVisitType, getListAdminCodes, deleteAdminCode } from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import { createVisitType, updateVisitType } from 'services/Calendar/index'
import { Pagination } from 'shared/elements/Pagination'
import { useNotification } from 'shared/elements/Notification'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { LightTooltip } from 'shared/elements/Tooltip/LightTooltip'

import { VisitTypesCreateDialog } from './VisitTypesCreateDialog'
import { RenderCptCodeCell } from './RenderCptCodeCell'
import { VisitType } from './interfaces'

import './VisitTypesCreateDialog/styles.scss'
import AdminCodesCreate from './AdminCodeCreateDialog'

const useStyles = makeStyles({
    subtabDiv: {
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        padding: '3px',
        backgroundColor: '#F8F9FB',
        marginTop: '7px ',
    },
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    text_clinical_note: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
})

const VisitTypesSettings = () => {
    const classes = useStyles()
    const notification = useNotification()

    // const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState({ status: false, itemId: null })
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [sortModel, setSortModel] = useState([])
    const [subValue, setSubValue] = useState("appointments")
    const [adminCodeListData, setAdminCodeListData] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(100)

    const [adminCodeCreate, setAdminCodeCreateModal] = useState({ status: false, code_id: null })


    const handlePageChange = (value) => {
        setPage(value)
    }

    const permissions = useSelector((state) => state?.permission?.scheduling)

    const appointmentModalToggle = (visitTypeId) => {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId: visitTypeId,
        })
    }

    const adminCodeModalToggle = (code_id) => {
        setAdminCodeCreateModal({
            status: !adminCodeCreate.status,
            code_id,
        })
    }

    const columns = [
        {
            headerName: <Box fontWeight={'bold'}>Short Code</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'post_code',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: <Box fontWeight={'bold'}>Specialty</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value?.specialty
            },
        },
        {
            headerName: <Box fontWeight={'bold'}>Duration</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'duration',
            flex: 0.75,
            sortable: true,
            renderCell: ({ value }) => {
                return `${value}`
            },
        },
        {
            headerName: (
                <Box fontWeight={'bold'}>
                    CPT Codes
                    {/* <KeyboardArrowDownIcon /> */}
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'cpt_arr',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => <RenderCptCodeCell cptArr={value} />,
        },

        {
            headerName: (
                <Box fontWeight={'bold'}>
                    Note Type
                    {/* <KeyboardArrowDownIcon /> */}
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'clinical_note_type',
            // width: 100,
            flex: 1.75,
            sortable: false,
            renderCell: (params) => (
                <LightTooltip title={params.value}>
                    <Typography className={classes.text_clinical_note}>{params.value}</Typography>
                </LightTooltip>
            ),
        },
        {
            headerName: <Box fontWeight={'bold'}>Actions</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_appointment_visit_types') &&
                !permissions.includes('delete_appointment_visit_types'),
            renderCell: ({ row }) => {
                const menu = []
                if (permissions.includes('modify_appointment_visit_types')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleEditClick(row),
                    })
                }
                if (permissions.includes('delete_appointment_visit_types')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDelete(row.id),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this visit type?"
                    />
                )
            },
        },
    ]

    const AdminCodecolumns = [
        {
            headerName: <Box fontWeight={'bold'}>Short Code</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'code',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: <Box fontWeight={'bold'}>Duration</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'duration',
            flex: 0.75,
            sortable: false,
            renderCell: ({ value }) => {
                return `${value} minutes`
            },
        },
        {
            headerName: <Box fontWeight={'bold'}>Title</Box>,
            headerClassName: 'super-app-theme--header',
            field: 'title',
            flex: 0.75,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: <Box fontWeight={'bold'}>Actions</Box>,
            headerClassName: 'super-app-theme--header',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_appointment_visit_types') &&
                !permissions.includes('delete_appointment_visit_types'),
            renderCell: ({ row }) => {
                const menu = []
                if (permissions.includes('modify_appointment_visit_types')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleEditClickCodes(row),
                    })
                }
                if (permissions.includes('delete_appointment_visit_types')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handledeleteAdminCode(row.id),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Admin Code?"
                    />
                )
            },
        },
    ]

    const handleDelete = (visitTypeId) => {
        setLoading(true)

        deleteVisitType(visitTypeId)
            .then(() => {
                setLoading(false)
                refetchVisitType()
                notification('A Visit Type was successfully deleted!', 'success')
            })
            .catch((error) => {
                notification(
                    error?.data?.message || 'A Visit Type was not successfully deleted!',
                    'error',
                )
                setLoading(false)
            })
    }

    const handledeleteAdminCode = async (id) => {
        try {
            const deleteData = await deleteAdminCode(id)
            notification('Admin code delete Successfully', 'success')
            afterComplete()
        } catch (error) {
            notification('Something went wrong', 'error')
        }

    }

    const handleEditClick = (row) => {
        appointmentModalToggle(row?.id)
    }

    const handleEditClickCodes = (row) => {
        console.log(row)
        adminCodeModalToggle(row?.id)
    }

    const handleSubmit = async (payload) => {
        if (appointmentModal?.itemId) {
            try {
                setIsSubmitting(true)
                updateVisitType({
                    id: appointmentModal?.itemId,
                    payload,
                })
                    .then(() => {
                        appointmentModalToggle()
                        setIsSubmitting(false)
                        notification('A visit type was successfully updated!', 'success')
                        refetchVisitType()
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        notification(
                            error?.data?.message || 'A visit type was not successfully updated!',
                            'error',
                        )
                    })
            } catch (error) {
                console.log({ error })

                setIsSubmitting(false)
            }
        } else {
            try {
                setIsSubmitting(true)
                createVisitType(payload)
                    .then(() => {
                        appointmentModalToggle()
                        setIsSubmitting(false)
                        notification('A visit type was successfully added!', 'success')
                        refetchVisitType()
                    })
                    .catch((error) => {
                        setIsSubmitting(false)
                        notification(
                            error?.data?.message || 'A visit type was not successfully added!',
                            'error',
                        )
                    })
            } catch (error) {
                console.log({ error })

                setIsSubmitting(false)
            }
        }
    }

    const getListAdminCode = async () => {
        setLoading(true)
        const payload = {
            page,
            limit,
        }
        const Listdata = await getListAdminCodes(payload)

        setAdminCodeListData(Listdata.data.adminCodesList.data)
        setTotal(Listdata.data.adminCodesList.paging.total)
        setLoading(false)
    }

    const refetchVisitType = async () => {
        setLoading(true)
        try {
            const res = await getVisitType(
                sortModel[0]?.sort
                    ? {
                        page,
                        limit,
                        [sortModel[0].sort]: sortModel[0].field,
                    }
                    : {
                        page,
                        limit,
                        desc: 'createdAt',
                    },
            )
            setLoading(false)
            setPatientList(res.data?.visitTypeList?.data || [])
            setTotal(res.data?.visitTypeList?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (subValue === 'appointments')
            refetchVisitType()
        if (subValue === 'admin_codes')
            getListAdminCode()

        return () => abortController.abort()
    }, [page, sortModel, subValue])

    const CustomTab = withStyles((theme) => ({
        root: {
            minWidth: 0,
            [theme.breakpoints.up('sm')]: {
                minWidth: 0,
            },
            '&.Mui-selected': {
                '& .MuiTab-wrapper': {
                    background: 'transparent',
                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                    paddingBottom: '8px',
                    paddingTop: '8px',
                },
            },
        },
        wrapper: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
    }))((props) => <Tab {...props} />)

    const handleChangeSubValue = (event, newValue) => {
        setTotal(0)
        setPage(1)
        setSubValue(newValue);
        if (newValue === 'admin_codes') {
            getListAdminCode()
        }

    };

    const afterComplete = async () => {
        await getListAdminCode()
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems='center' className={classes.subtabDiv}>
                    <Tabs
                        value={subValue}
                        onChange={handleChangeSubValue}
                        TabIndicatorProps={{ style: { display: 'none' } }}
                    >
                        <CustomTab style={{ background: "none", textTransform: "capitalize" }} label="Appointment" value="appointments" />
                        <CustomTab style={{ background: "none", textTransform: "capitalize" }} label="Admin Codes" value="admin_codes" />
                    </Tabs>
                    {subValue === 'appointments' && <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_appointment_visit_types']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Visit Type"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => appointmentModalToggle()}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>}
                    {subValue === 'admin_codes' && <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_appointment_visit_types']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Admin Codes"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => adminCodeModalToggle()}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>}
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#F9F9F9',
                            margin: '3px 0',
                            borderRadius: '5px',
                            border: "1px solid #E9ECF1",
                            Maxwidth: "1575px"

                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                        {
                            width: 'auto',
                            visibility: 'visible',
                        },
                    }}>
                    {subValue === 'appointments' && <DataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={patientList}
                        columns={columns}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />}
                    {console.log(adminCodeListData, "HEY")}
                    {subValue === 'admin_codes' && <DataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={adminCodeListData}
                        columns={AdminCodecolumns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />}
                </Box>
            </Grid>

            {appointmentModal.status && (
                <VisitTypesCreateDialog
                    open={appointmentModal.status}
                    visitTypeId={appointmentModal.itemId}
                    loading={isSubmitting}
                    onModalClose={appointmentModalToggle}
                    onSubmit={handleSubmit}
                />
            )}
            {adminCodeCreate.status && (
                <AdminCodesCreate
                    open={adminCodeCreate.status}
                    codeId={adminCodeCreate.code_id}
                    loading={isSubmitting}
                    onClose={adminCodeModalToggle}
                    onSubmit={handleSubmit}
                    afterComplete={afterComplete}
                />
            )}
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(VisitTypesSettings)
