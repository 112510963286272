import {
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import useStyles from './style'

const SearchBar = ({
    onSearch,
    placeholder,
    value,
    ...rest
}) => {
    const classes = useStyles()

    const handleChange = (event) => {
        onSearch(event.target.value)
    }

    return (
        <div>
            <TextField
                InputProps={{
                    classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline:
                            classes.notchedOutline,
                    },
                    startAdornment: (
                        <InputAdornment>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                        input: classes.input,
                    },
                    style: {
                        color: '#516983 !important',
                    },
                }}
                fullWidth
                variant="outlined"
                placeholder={
                    placeholder
                        ? placeholder
                        : 'Search Messages'
                }
                onChange={handleChange}
                value={value}
                {...rest}
                className="Search-bar"
            />
        </div>
    )
}

export default React.memo(SearchBar)
