import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    makeStyles,
} from '@material-ui/core'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { patientContact } from 'services/Patient/apiPatientContacts'
import { ModalEl as Modal, SelectEl } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { formatNumber } from 'shared/utils'
import { USPhoneRegexp, emailRegex } from 'utilities/regex'
import * as yup from 'yup'
import { INITIAL_VALUES, RELATIONSHIP_TYPE } from './schema'
import './styles.scss'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
        borderTop: '1px solid #DAD9D9 !important',
    },
})

interface Props {
    patientId: string
    itemId?: string | null | number
    open?: boolean
    onClose: (forceReload?: boolean) => void
    forceReload?: () => void
}

export const ContactsCreate = (props: Props) => {
    const classes = useStyles()

    const { id: patient_id } = useParams<{ id: string }>()
    const notification = useNotification()
    const [relationShip, setrelationShip] = useState(RELATIONSHIP_TYPE)

    const [initData, setInitData] = useState(INITIAL_VALUES)

    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isChecked, setIsChecked] = useState(false)
    const [isAppointmentNotificationChecked, setAppointmentNotificationIsChecked] = useState(false)
    const [patientsContacts, setpatientsContacs] = useState<Array>([])

    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(false)
        return () => abortController.abort()
    }, [])

    useEffect(() => {
        fetchContacts()
    }, [])

    const fetchContacts = async () => {
        const resp = (await patientContact.getList(patient_id)).data
        setpatientsContacs(resp)
    }

    const handleSubmit = async (payload) => {
        setIsSubmitting(true)

        try {
            if (props.itemId) {
                const id = props.itemId
                const idx = patientsContacts.findIndex((x) => x.id === id)
                patientsContacts[idx] = payload
                const generatedData = [...patientsContacts]
                await patientContact.create(
                    {
                        emergency_contacts: generatedData,
                    },
                    patient_id,
                )
                // return;
            } else {
                const generatedData = [...patientsContacts, payload]

                await patientContact.create(
                    {
                        emergency_contacts: generatedData,
                    },
                    patient_id,
                )
                // return
            }
            setIsSubmitting(false)

            const msg = props.itemId
                ? `Contact updated successfully!`
                : `Contact Added successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')

            props.onClose()
            if (props.forceReload) props?.forceReload()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg = `Something went wrong!`

            notification(errMsg, 'error')
        }
    }

    const validationSchema = yup.object({
        related_person_email: yup.string().trim().matches(emailRegex, 'Invalid email address!'),
        related_person_name: yup.string().required('Contact name is required!'),
        related_person_phone: yup
            .string()
            .trim()
            .required()
            .matches(USPhoneRegexp, 'Invalid phone number!'),
        relation: yup.string().required('Relation is required!'),
        is_emergency_contact: yup.number(),
        appointment_notification: yup.string(),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        const idx = patientsContacts.findIndex((x) => x.id === props.itemId)
        if (idx > -1) setIsSubmitting(true)
        const editData = patientsContacts[idx]
        if (editData) {
            setTimeout(() => {
                setIsSubmitting(false)
            }, 500)
            setInitData(editData)
            if (editData.is_emergency_contact == 1 || editData.is_emergency_contact === 'true') {
                setIsChecked(true)
            } else {
                setIsChecked(false)
            }
            if (
                editData.appointment_notification == 1 ||
                editData.appointment_notification === 'true'
            ) {
                setAppointmentNotificationIsChecked(true)
            } else {
                setAppointmentNotificationIsChecked(false)
            }
        }
    }, [props.itemId, patientsContacts])

    // const CheckBoxChange = (e) => {
    //     console.log('11==========================>')
    //     console.log(e.target)
    //     setIsChecked(!isChecked)
    //     setAppointmentNotificationIsChecked(!isAppointmentNotificationChecked)
    //     formik.setFieldValue(e.target.name, e.target.checked ? '1' : '0')
    //     // checked = { formik.values.is_emergency_contact === "1" ? true : false }
    // }

    return (
        <Modal
            width={680}
            height={378}
            title={props.itemId ? 'Update Patients Contacts' : 'Add Patients Contacts'}
            open={props.open}
            onClose={() => props.onClose(false)}
            style={{
                alignItems: 'left',
                justifyContent: 'left',
            }}
            className="calendar-appointment-modal"
            dynaClass="myheader">
            <CloseOutlinedIcon
                style={{
                    fontSize: 20,
                    color: 'black',
                    position: 'absolute',
                    top: '5%',
                    right: '3%',
                    cursor: 'pointer',
                }}
                onClick={() => props.onClose(false)}
            />
            <Grid container className="modal_padding">
                {isSubmitting && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            // marginTop: 24,
                            marginBottom: 10,
                        }}>
                        {/* <Grid
                            container
                            style={{
                                marginLeft: 7,
                                marginRight: 7,
                            }}
                            spacing={2}>
                            <Grid
                                container
                                item
                                alignItems="flex-end"
                                spacing={2}>
                                <Grid item xs={12}>

                                </Grid> */}

                        <Grid item xs={6}>
                            <TextBox
                                label="Contact Name"
                                name="related_person_name"
                                placeholder="Enter contact's full name"
                                value={formik.values?.related_person_name}
                                onChange={(value) =>
                                    formik.setFieldValue('related_person_name', value)
                                }
                                required
                                error={
                                    formik.touched.related_person_name &&
                                    Boolean(formik.errors.related_person_name)
                                }
                                helperText={
                                    formik.touched.related_person_name &&
                                    formik.errors.related_person_name
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormLabel
                                component="p"
                                className="input-form-label"
                                style={{ color: '#516983' }}>
                                Relationship to patient <span style={{ color: 'red' }}>*</span>
                            </FormLabel>
                            <SelectEl
                                name="relation"
                                placeholder=" Select"
                                value={formik.values.relation}
                                options={relationShip}
                                selectProps={{
                                    style: {
                                        height: 40,
                                    },
                                }}
                                onChange={(e) => {
                                    formik.setFieldValue('relation', e.target.value)
                                }}
                                error={formik.touched.relation && Boolean(formik.errors.relation)}
                                helperText={formik.touched.relation && formik.errors.relation}
                            />
                        </Grid>
                        {/* </Grid>
                        </Grid> */}

                        <Grid item xs={6}>
                            <TextBox
                                label="Phone Number"
                                name="related_person_phone"
                                value={formik.values?.related_person_phone}
                                placeholder="Enter phone number"
                                onChange={(value) =>
                                    formik.setFieldValue(
                                        'related_person_phone',
                                        formatNumber(value),
                                    )
                                }
                                error={
                                    formik.touched.related_person_phone &&
                                    Boolean(formik.errors.related_person_phone)
                                }
                                helperText={
                                    formik.touched.related_person_phone &&
                                    formik.errors.related_person_phone
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextBox
                                label="Email"
                                type="email"
                                name="related_person_email"
                                value={formik.values?.related_person_email}
                                placeholder="Enter Email"
                                onChange={(value) =>
                                    formik.setFieldValue('related_person_email', value)
                                }
                                error={
                                    formik.touched.related_person_email &&
                                    Boolean(formik.errors.related_person_email)
                                }
                                helperText={
                                    formik.touched.related_person_email &&
                                    formik.errors.related_person_email
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="is_emergency_contact"
                                            onChange={(e) => {
                                                setIsChecked(!isChecked)
                                                formik.setFieldValue(
                                                    e.target.name,
                                                    e.target.checked ? '1' : '0',
                                                )
                                            }}
                                            checked={isChecked}
                                        />
                                    }
                                    label="Person is patient's emergency contact"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="appointment_notification"
                                            onChange={(e) => {
                                                setAppointmentNotificationIsChecked(
                                                    !isAppointmentNotificationChecked,
                                                )
                                                formik.setFieldValue(
                                                    e.target.name,
                                                    e.target.checked ? '1' : '0',
                                                )
                                            }}
                                            checked={isAppointmentNotificationChecked}
                                        />
                                    }
                                    label="Appointment Notification"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                            borderTop: '1px solid #DAD9D9',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => props.onClose(false)}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                variant="contained"
                                className={classes.button_create}
                                loadingPosition="start"
                                startIcon={<></>}
                                onClick={(e) => formik.handleSubmit(e)}>
                                {props.itemId ? 'Update' : 'Submit'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
