import { EVENT_TYPE, VIEW } from '../constant';
import { getSlotTypeColor } from '../utils';

const eventStyleGetter = (event, view) => {
    if (
        event.type === EVENT_TYPE.AVAILABILITY ||
        event.type === EVENT_TYPE.TIMEOFF
    ) {
        return {
            style: {
                backgroundColor: getSlotTypeColor(
                    event.slot_type,
                ),
                border: 'none',
                borderRadius: 0,
                pointerEvents:
                    view !== VIEW.MONTH &&
                    event.type === EVENT_TYPE.TIMEOFF
                        ? 'none'
                        : 'initial',
            },
        };
    }

    return {
        style: {
            backgroundColor: event.color,
            border: 'none',
            borderRadius: 0,
        },
    };
};

export default eventStyleGetter;
