import React, { useState } from 'react'
import { Grid, IconButton, Typography, Menu, MenuItem } from '@material-ui/core'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import IconNoItem from 'assests/icons/IconNoItem'

import { usePatientDashboardStyles } from '../style'

interface Menu {
    name: string
    handler?: (name: string) => void
}
interface Props {
    header: string
    menu?: Menu[]
    children?: React.ReactNode | React.FC
    onClick?: () => void
}

const defaultMenu: Menu[] = [
    {
        name: 'Add',
    },
    {
        name: 'View all',
    },
]

export const Card = (props: Props) => {
    const { header, menu = defaultMenu, children, onClick } = props

    const classes = usePatientDashboardStyles()

    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null | undefined>(null)

    const handleToggleMenu = (event?: React.MouseEvent) => {
        setAnchorEl(event?.currentTarget)
    }

    return (
        <>
            <Grid
                container
                direction="column"
                style={{ height: '100%', cursor: 'pointer', position: 'relative' }}
                wrap="nowrap"
                onClick={() => onClick && onClick()}>
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    wrap="nowrap"
                    style={{ paddingRight: 15, marginBottom: '1.2rem' }}>
                    <Typography className={classes.header_card}>{header}</Typography>

                    {!!menu.length && (
                        <IconButton
                            size="small"
                            onClick={handleToggleMenu}
                            style={{
                                background: '#F3F6F9',
                                borderRadius: '5px',
                                height: 23,
                            }}>
                            <MoreHorizIcon
                                style={{
                                    color: '#3E465B',
                                    fontSize: 18,
                                }}
                            />
                        </IconButton>
                    )}
                </Grid>

                {!children ? (
                    <Grid
                        style={{ flex: 1, paddingRight: 15 }}
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column">
                        <IconNoItem />

                        {/* <IconButton style={{ marginTop: 16 }}>
              <AddCircleRoundedIcon
                style={{ color: '#14AE5C' }}
              />
            </IconButton> */}
                    </Grid>
                ) : (
                    children
                )}
            </Grid>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleToggleMenu()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: 120,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {menu.map((item, index) => (
                    <MenuItem
                        key={index}
                        style={{
                            width: '100%',
                            paddingLeft: '15px',
                            paddingRight: '5px',
                        }}
                        id={`auto-header-${item.name}`}
                        onClick={() => {
                            handleToggleMenu()
                            item.handler && item.handler(item.name)
                        }}>
                        {item.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
