const IncomingMessageActiveIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                width="24"
                height="24"
                rx="6"
                fill="white"
            />
            <path
                d="M12 4.5V9.75L13.5 8.25"
                stroke="#5571C6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.75L10.5 8.25"
                stroke="#5571C6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.48486 12.75H7.79236C8.07736 12.75 8.33236 12.9075 8.45986 13.1625L9.33736 14.9175C9.59236 15.4275 10.1099 15.75 10.6799 15.75H13.3274C13.8974 15.75 14.4149 15.4275 14.6699 14.9175L15.5474 13.1625C15.6749 12.9075 15.9374 12.75 16.2149 12.75H19.4849"
                stroke="#5571C6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.01758 17.2502C5.73758 18.8252 7.23758 19.5002 9.75008 19.5002H14.2501C18.0001 19.5002 19.5001 18.0002 19.5001 14.2502V11.2502C19.5001 8.04766 18.4051 6.48766 15.7501 6.09766"
                stroke="#5571C6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.25 6.09766C5.595 6.48766 4.5 8.04766 4.5 11.2502V14.2502"
                stroke="#5571C6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default IncomingMessageActiveIcon
