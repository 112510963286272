export const LIST_PROCEDURES = [
   "BACK",
   "NECK",
   "BRAIN",
   "CARDIAC",
   "EAR/NOSE/THROAT",
   "GALL_BLADDER",
   "HERNIA",
   "HIP",
   "KNEE",
   "ANKLE",
   "FOOT",
   "HYSTERECTOMY_(OVARIES_REMOVED)",
   "HYSTERECTOMY_(OVARIES_RETAINED)",
   "INTESTINE",
   "KIDNEY",
   "LIVER",
   "LUNG",
   "PANCREAS",
   "PELVIS",
   "PENIS",
   "PROSTATE",
   "SEX_CHANGE",
   "SHOULDER",
   "ELBOW",
   "WRIST",
   "HAND",
   "STOMACH",
   "TONSILS",
   "VAGINA",
   "WEIGHT_LOSS",
  ].map(el => ({
    key: el,
    value: el.replaceAll('_', ' ').toLowerCase()
  }))
  .sort((option1, option2) => {
  let a = option1.value
  let b = option2.value
  return a === b ? 0 : a > b ? 1 : -1
})
