import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import { formatDateTime } from 'utilities/formats'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'

import { Card } from '../view/Card'
import { usePatientDashboardStyles } from '../style'
import { Document } from '../interfaces'
import { getDocumentList } from 'services/Documents'
import { DownloadIcon } from 'assests/icons/DownloadIcon'
import { downloadDocument,previewDocument } from '../shared'
import { DocumentForm } from '../view/Documents/DocumentForm'
import { NoData } from './NoData'

interface Props {
    onClick?: () => void
}

export const Documents = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const { idPatient: patientIdByRef } = useParams<{ idPatient: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [documents, setDocuments] = useState<Document[]>([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [paging] = useState<{
        page: number
        pageSize: number
    }>({
        page: 1,
        pageSize: 5,
    })

    useEffect(() => {
        const abortController = new AbortController()
        getDocuments()

        return () => abortController.abort()
    }, [])

    const getDocuments = async () => {
        try {
            setLoading(true)
            const resp = await getDocumentList({
                page: paging.page,
                limit: paging.pageSize,
                associated_patient: patientId || patientIdByRef,
            })

            setDocuments(resp.data.data as Document[])
        } catch (ex) {
            notification('Cannot get list documents', 'error')
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.DOCUMENTS.replace(':id', patientId || patientIdByRef))

    const Content = () => (
        <>
            {documents.map((item, key) => (
                <Grid
                    container
                    key={key}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #EFF2F5',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        direction="column"
                        style={{
                            width: 'fit-content',
                            flex: 1,
                        }}>
                        <Typography style={{ color: '#0076FC' }} onClick={() => previewDocument(item.id, item.document_name, notification)}>
                            {/* <span
                      className={
                          classes.title_clinical_reminder
                      }>
                      Assessment:
                  </span>{' '} */}
                            {item.document_name}
                        </Typography>

                        <Typography
                            style={{
                                color: '#000000',
                                fontSize: 13,
                            }}>
                            {item.upload_by}
                            {' - '}
                            <span style={{ color: '#9CA9B8' }}>
                                {formatDateTime(item.upload_date, 'MMMM DD YYYY')}
                            </span>
                        </Typography>
                    </Grid>

                    <IconButton
                        onClick={() => downloadDocument(item.id, item.document_name, notification)}>
                        <DownloadIcon />
                    </IconButton>
                </Grid>
            ))}

            {!!documents.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Business Documents">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {documents.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            <DocumentForm
                patientId={patientId || patientIdByRef}
                open={isAddingNew}
                onCancel={(forceReload) => {
                    setIsAddingNew(false)

                    if (forceReload) setTimeout(() => getDocuments(), 800)
                }}
            />
        </Card>
    )
}
