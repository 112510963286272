const StarActiveIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2975 2.69938L11.6175 5.33938C11.7975 5.70688 12.2775 6.05938 12.6825 6.12688L15.075 6.52438C16.605 6.77938 16.965 7.88938 15.8625 8.98438L14.0025 10.8444C13.6875 11.1594 13.515 11.7669 13.6125 12.2019L14.145 14.5044C14.565 16.3269 13.5975 17.0319 11.985 16.0794L9.74249 14.7519C9.33749 14.5119 8.66999 14.5119 8.25749 14.7519L6.01499 16.0794C4.40999 17.0319 3.43499 16.3194 3.85499 14.5044L4.38749 12.2019C4.48499 11.7669 4.31249 11.1594 3.99749 10.8444L2.13749 8.98438C1.04249 7.88938 1.39499 6.77938 2.92499 6.52438L5.31749 6.12688C5.71499 6.05938 6.19499 5.70688 6.37499 5.33938L7.69499 2.69938C8.41499 1.26688 9.58499 1.26688 10.2975 2.69938Z"
                fill="#FFAC0C"
                stroke="#FFAC0C"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default StarActiveIcon
