import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import { ModalEl as Modal } from 'shared/elements'
import {
    getEncountersById,
    getEncountersByPatientId,
    getMedicalProblem,
    putMedicalProblem,
} from 'services/Patient/patient'
import { formatDateTime } from 'utilities/formats'
import { Encounter, ErrorType, MedicalProblem } from 'containers/PatientsSetting/interfaces'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { useNotification } from 'shared/elements/Notification'

import { postMedicalProblem } from 'services/Patient/patient'
import { INITIAL_VALUES, PROBLEM_STATUS } from './schema'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'

import { usePatient } from 'containers/PatientsSetting/hooks'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import '../../styles.scss'

interface Props {
    patientId?: string
    encounter_id?: string
    MedicalProblemForm?: string
    open?: boolean
    isAddingFromClinicalNote?: boolean
    problemId?: string | null | undefined
    onClose: (forceReload?: boolean) => void
    afterActionOk?: () => void
    handleAddICDMedicalCoding?: () => void
}

export const MedicalProblemForm = (props: Props) => {
    const notification = useNotification()
    const { patientInfo } = usePatient(props.patientId)

    const [initData, setInitData] = useState<MedicalProblem>(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [encounter, setEncounter] = useState('')

    const [appointments, setAppointments] = useState<Encounter[]>([])

    useEffect(() => {
        const abortController = new AbortController()

        setDataLoading(true)
        Promise.allSettled([
            getAEncounter(),
            getEncounterList(props.patientId as string),
            props.problemId
                ? getProblemDetail(props.patientId as string, props.problemId)
                : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload: MedicalProblem) => {
        setIsSubmitting(true)

        const postData = {
            ...payload,
            // time_changed: formatDateTime(payload.time_changed, 'YYYY-MM-DD'),
            // time_diagnosis: formatDateTime(payload.time_diagnosis, 'YYYY-MM-DD'),
            encounter_id: props.encounter_id,
            time_onset: formatDateTime(payload.time_onset, 'YYYY-MM-DD'),
        }

        try {
            if (props.problemId) {
                await putMedicalProblem(props.patientId, props.problemId, postData)
            } else {
                // if (props.handleAddICDMedicalCoding && props.isAddingFromClinicalNote) {
                //     props.handleAddICDMedicalCoding({
                //         code: postData?.icd_code.code,
                //         description: postData?.icd_code.display,
                //     })
                // }
                await postMedicalProblem(props.patientId, postData)
            }
            setIsSubmitting(false)

            const msg = props.problemId
                ? `Problem updated successfully!`
                : `Problem created successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')

            props.onClose(true)
            props.afterActionOk?.()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                (error as ErrorType)?.data?.message ||
                (props.problemId
                    ? `Problem updated unsuccessfully`
                    : `Problem created unsuccessfully`)
            notification(errMsg, 'error')
        }
    }

    const getProblemDetail = async (patientId: string, problemId: string) => {
        try {
            const resp = await getMedicalProblem(patientId, problemId)

            if (resp && resp.data) {
                setInitData({
                    ...INITIAL_VALUES,
                    ...resp.data,
                } as MedicalProblem)
            }
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message || 'Cannot get medical problem details',
                'error',
            )
        }
    }

    const getEncounterList = useCallback(async (patientId: string) => {
        try {
            const resp = await getEncountersByPatientId({
                patient: patientId,
                status: 'finished',
            })
            const data = (resp.data.data || resp.data) as Encounter[]
            setAppointments(data.sort((p, q) => p.start.localeCompare(q.start)))
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message || '[New Problem] cannot get appointment list',
                'error',
            )
        }
    }, [])

    const getAEncounter = useCallback(async () => {
        try {
            if (props.encounter_id) {
                console.log('asfdas', props.encounter_id)
                const resp = await getEncountersById(props.encounter_id)
                if (resp?.data?.encounter_id) {
                    const { start, end } = resp?.data
                    console.log('asfdas 1111', resp?.data)
                    setEncounter(
                        `${moment(start).format('MMM DD, YYYY')} -- ${moment(start).format(
                            'hh:mm A',
                        )} - ${moment(end).format('hh:mm A')}`,
                    )
                }
            }
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message || '[New Problem] cannot getAEncounter',
                'error',
            )
        }
    }, [props.encounter_id])

    const validationSchema = yup.object({
        encounter_id: yup.string(),
        time_diagnosis: yup.date(),
        time_onset: yup
            .date()
            .nullable()
            .typeError('Please input valid Onset Of Condition')
            .min(moment(patientInfo.dob), `Onset Of Condition must be after Patient's birth date`)
            .max(moment(), 'Onset Of Condition must be before current date'),
        time_changed: yup.date(),
        note: yup.string(),
        // snomed: yup.object({
        //     code: yup.string().required('Snowmed is required!'),
        // }),
        icd_code: yup.object({
            code: yup.string().required('ICD 10 Code is required!'),
        }),
        status: yup.object({
            code: yup.string().required('Status is required!'),
        }),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    const completedAppointments = appointments.filter(
        (appointment) => appointment.status === 'COMPLETED',
    )

    return (
        <Modal
            title={props.problemId ? 'Update Problem' : 'Add Problem'}
            width={800}
            description={'Booking Appointment Details'}
            open={props.open}
            onClose={props.onClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="problem-create">
                <Loading visible={dataLoading} />

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                        }}>
                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid item xs={12}>
                                <SearchICD10Code
                                    label="Medical Problems"
                                    required
                                    name="icd_code"
                                    placeholder="Search problem"
                                    value={formik.values?.icd_code}
                                    error={
                                        formik.touched.icd_code?.code &&
                                        Boolean(formik.errors.icd_code?.code)
                                    }
                                    helperText={
                                        formik.touched.icd_code?.code &&
                                        formik.errors.icd_code?.code
                                    }
                                    onChange={(value) => {
                                        if (value) {
                                            formik.setFieldValue('problem', value)
                                            formik.setFieldValue('icd_code', value)
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="ICD 10 Code"
                                    name="icd_code_display"
                                    disabled
                                    placeholder="Enter ICD 10 Code"
                                    value={formik.values?.icd_code?.code}
                                    // onChange={value =>
                                    //   formik.setFieldValue
                                    // }
                                />
                            </Grid>

                            {/* <Grid item xs={12}>
                                <TextBox
                                    required
                                    label="Snomed"
                                    name="snomed"
                                    placeholder="Enter snomed"
                                    value={formik.values?.snomed?.code}
                                    onChange={(value) => {
                                        formik.setFieldValue('snomed', { code: value })
                                    }}
                                    error={
                                        formik.touched?.snomed?.code &&
                                        Boolean(formik.errors?.snomed?.code)
                                    }
                                    helperText={
                                        formik.touched.snomed?.code && formik.errors.snomed?.code
                                    }
                                />
                            </Grid> */}
                        </Grid>

                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid item xs={12}>
                                <Select
                                    label="Status"
                                    name="status"
                                    required
                                    // disabled={
                                    //     !props.problemId || initData.status?.code === 'Deleted'
                                    // } // FIXME: Hard code Problem status
                                    placeholder="Select status"
                                    value={formik.values.status.code}
                                    error={
                                        formik.touched.status?.code &&
                                        Boolean(formik.errors.status?.code)
                                    }
                                    helperText={
                                        formik.touched.status?.code && formik.errors.status?.code
                                    }
                                    options={PROBLEM_STATUS}
                                    onChange={(value) => {
                                        if (initData.status?.code !== 'Deleted')
                                            // FIXME: Hard code Problem status
                                            formik.setFieldValue('status', {
                                                code: value,
                                            })
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {props.isAddingFromClinicalNote ? (
                                <TextBox
                                    label="Encounter"
                                    name="icd_code_display"
                                    disabled
                                    placeholder="Enter Encounter"
                                    value={encounter}
                                    // onChange={value =>
                                    //   formik.setFieldValue
                                    // }
                                />
                            ) : (
                                <Select
                                    disabled={props.isAddingFromClinicalNote}
                                    label="Encounter"
                                    name="encounter_id"
                                    placeholder="Select encounter"
                                    value={formik.values.encounter_id}
                                    error={
                                        formik.touched.encounter_id &&
                                        Boolean(formik.errors.encounter_id)
                                    }
                                    helperText={
                                        formik.touched.encounter_id && formik.errors.encounter_id
                                    }
                                    options={completedAppointments.map((appointment) => ({
                                        key: appointment.encounter_id,
                                        value: `${formatDateTime(
                                            appointment.start,
                                            'MMM DD, YYYY -- hh:mm A',
                                        )} - ${formatDateTime(appointment.end, 'hh:mm A')}`,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('encounter_id', value)
                                    }
                                />
                            )}
                        </Grid>

                        {/* <Grid item xs={4}>
              <DatePicker
                label='Time Diagnosis'
                name='time_diagnosis'
                value={formik.values.time_diagnosis}
                onChange={(value) => {
                  formik.setFieldValue('time_diagnosis', value)
                }}
              />
            </Grid> */}

                        <Grid item xs={12}>
                            <DatePicker
                                label="Onset of Condition"
                                maxDate={moment()}
                                minDate={moment(patientInfo.dob)}
                                error={
                                    formik.touched.time_onset && Boolean(formik.errors.time_onset)
                                }
                                helperText={formik.touched.time_onset && formik.errors.time_onset}
                                name="time_onset"
                                value={formik.values.time_onset}
                                onChange={(value) => {
                                    formik.setFieldValue('time_onset', value)
                                }}
                                notDefaultValue
                            />
                        </Grid>

                        {/* <Grid item xs={4}>
              <DatePicker
                label='Time Changed'
                name='time_changed'
                value={formik.values.time_changed}
                onChange={(value) => {
                  formik.setFieldValue('time_changed', value)
                }}
              />
            </Grid> */}

                        <Grid item xs={12}>
                            <TextBox
                                label="Note"
                                name="note"
                                value={formik.values.note}
                                multiline
                                variant="outlined"
                                minRows={4}
                                placeholder="Enter note"
                                onChange={(value) => formik.setFieldValue('note', value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Footer
                                loading={isSubmitting}
                                onCancel={() => props.onClose(false)}
                                onOk={() => formik.handleSubmit()}
                                okBtnText={props.problemId ? 'Update' : 'Submit'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
