import { Divider, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { formatString, isObjectNull } from 'containers/CalendarScheduler/utils'
import React, { useCallback } from 'react'
import { ReactComponent as ProviderRunningLateIcon } from 'assests/icons/ProviderLate.svg'
import { ReactComponent as PlayIcon } from 'assests/icons/play-icon.svg'
import moment from 'moment'
import { ENCOUNTER_QUEUE_PATIENT_STATUS_LIST } from 'containers/CalendarScheduler/constant'

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 20px',
        width: '50%',
        justifyContent: 'space-between'
    },
    noteSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        width: '60%',
        justifyContent: 'space-between',
    },
    noteStatus: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    noteNameText: {
        fontFamily: 'Rubik !important',
        fontSize: '16px !important',
        fontWeight: '500 !important',
        color: '#303E4E !important',
    },
    practitionerNameText: {
        fontFamily: 'Rubik !important',
        fontSize: '14px !important',
        fontWeight: '400 !important',
        color: '#303E4E !important',
    },
    noteStatusChip: {
        display: 'flex',
        background: '#F9E0FF',
        color: '#A12FE7',
        fontFamily: 'Rubik',
        fontSize: '13px',
        fontWeight: 400,
        padding: '4px 12px',
        borderRadius: 5,
    },
    divider: {
        borderRight: '1px solid #E0E0E0',
    },
    eqSection: {
        display: 'flex',
        alignItems: 'center',
        width: '30%',
        justifyContent: 'flex-end'
    },
    eqSectionContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
    },
    playButtonContainer: {
        background: '#15a452',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '50%',
        padding: 5,
        marginLeft: 30
    },
}))

function CustomTypography({
    fontFamily = 'Rubik',
    fontSize = '12px',
    fontWeight = '400',
    color = '#303E4E',
    content,
}) {
    return <Typography style={{ fontFamily, fontSize, fontWeight, color }}>{content}</Typography>
}

function NoteEQDetails({ clinicalNoteData, nextEncounterDetails }) {
    const classes = useStyles()

    const getSpeciality = useCallback(() => {
        return (
            typeof(clinicalNoteData?.provider?.specialities[0]) === 'string' ?
                clinicalNoteData?.provider?.specialities[0] : clinicalNoteData?.provider?.specialities[0]?.value
        )
    }, [clinicalNoteData])

    return (
        <div className={classes.container}>
            <div className={classes.noteSection}>
                <div className={classes.noteStatus}>
                    <Typography className={classes.noteNameText}>
                        {clinicalNoteData?.templateName}
                    </Typography>

                    <span className={classes.noteStatusChip}>
                        {formatString(clinicalNoteData?.status?.toLowerCase())}
                    </span>
                </div>

                <Typography className={classes.practitionerNameText}>
                    {clinicalNoteData?.provider?.first_name} {clinicalNoteData?.provider?.last_name}
                    {'  '}
                    {clinicalNoteData?.provider?.specialities?.length && `(${getSpeciality()})`}
                </Typography>

                <Typography className={classes.practitionerNameText}>
                    {moment(clinicalNoteData?.appointment_date).format('MM/DD/YYYY')}
                </Typography>
            </div>

            {!isObjectNull(nextEncounterDetails) && <div className={classes.eqSection}>
                <Divider orientation="vertical" style={{ height: '70px' }} />

                <div className={classes.eqSectionContent}>
                    <CustomTypography content={'Next in queue'} />
                    <CustomTypography fontSize="14px" fontWeight="500" content={`${nextEncounterDetails?.patient?.first_name} ${nextEncounterDetails?.patient?.last_name}`} />
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <CustomTypography content={moment(nextEncounterDetails?.start).format('hh:mm A')} />
                        <ProviderRunningLateIcon style={{ marginTop: '2px' }} />
                        <CustomTypography content={'|'} color="#C1C1C1" />
                        <CustomTypography content={ENCOUNTER_QUEUE_PATIENT_STATUS_LIST.find((element) => element.value === nextEncounterDetails?.status)?.label} fontSize="14px" />
                    </div>
                </div>

                {nextEncounterDetails?.status === 'EXAM_WAITING' && <div className={classes.playButtonContainer}>
                    <PlayIcon />
                </div>}
            </div>}
        </div>
    )
}

export default NoteEQDetails
