import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import TextWithMacro from '../reusable/TextWithMacro'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
    },
})
const TreatmentApproach = ({
    macros,
    values,
    onChange,
    onClear,
    read_only,
    initialValue,
    fieldName,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()

    const [narrative, setNarrative] = React.useState('')
    function generatNarrative(macro) {
        const text = `${
            values?.spec_prot_treatment
                ? `The approach for treatment for this patient is protocol-driven.`
                : ''
        }${
            values?.spec_prot_details
                ? ` The specific details are:${values?.spec_prot_details}.`
                : ''
        }\n${
            values?.gen_princ_treatment
                ? ` The approach for treatment for this patient is principal-driven.`
                : ''
        }${
            values?.gen_prin_details ? ` The specific details are:${values?.gen_prin_details}.` : ''
        }\n${
            values?.mod_approach ? ` Additionally, a modular approach is being implemented.` : ''
        }${
            values?.mod_approach_details
                ? ` The details of this approach are ${values?.mod_approach_details}.`
                : ''
        }\n${
            values?.other_treatment
                ? ` The approach for treatment for this patient is neither protocol or principle driven.`
                : ''
        }${
            values?.other_treat_details
                ? ` The specific details are:${values?.other_treat_details}.`
                : ''
        }`

        onChange('treatment_approach.narrative', `${text} \n${macro}`)
    }

    function handleChange(name, value) {
        onChange(name, value)
    }

    const handleCheckChange = (e) => {
        const { name, checked } = e.target
        onChange(name, checked)
    }

    const handleChangeText = (e) => {
        const { name, value } = e.target
        onChange(name, value)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()
            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()
            const noValue = keys.every((key) => {
                if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'boolean') {
                    if (!copyValues[key]) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Grid container spacing={2}>
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            <Grid item xs={12}>
                <CheckboxOption
                    name={`treatment_approach.spec_prot_treatment`}
                    option={{
                        label: 'Protocol-driven treatment (specific, session-by-session, manualized protocol implemented with reasonable flexibility):',
                        value: false,
                    }}
                    disabled={nonEditable}
                    onChange={handleCheckChange}
                    checked={values?.spec_prot_treatment}
                />

                {/* <TextField
                    rows={4}
                    multiline
                    placeholder="Text area"
                    variant="outlined"
                    size="small"
                    macros={macros}
                    onChange={handleChangeText}
                    className={classes.textFieldWrap}
                    value={values?.spec_prot_details}
                    name="treatment_approach.spec_prot_details"
                    isDisabled={read_only}
                /> */}

                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.spec_prot_details`}
                    onChange={onChange}
                    macros={macros}
                    values={values.spec_prot_details}
                    isDisabled={nonEditable}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckboxOption
                    name={`treatment_approach.gen_princ_treatment`}
                    option={{
                        label: 'Principle-driven treatment (e.g., general CBT, ERP, behavior therapy, FAP, ACT, mindfulness-informed CBT, etc):',
                        value: false,
                    }}
                    disabled={nonEditable}
                    onChange={handleCheckChange}
                    checked={values?.gen_princ_treatment}
                />
                {/* <TextField
                    className={classes.textFieldWrap}
                    multiline
                    placeholder="Text area"
                    variant="outlined"
                    size="small"
                    macros={macros}
                    onChange={handleChangeText}
                    rows={4}
                    value={values?.gen_prin_details}
                    name="treatment_approach.gen_prin_details"
                    isDisabled={read_only}
                /> */}

                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.gen_prin_details`}
                    onChange={onChange}
                    macros={macros}
                    values={values.gen_prin_details}
                    isDisabled={nonEditable}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckboxOption
                    name={`treatment_approach.mod_approach`}
                    option={{
                        label: 'Used a modular approach (i.e., sections of manuals/approaches sequenced according to client need rather than according to a manualized protocol):',
                        value: false,
                    }}
                    onChange={handleCheckChange}
                    checked={values?.mod_approach}
                    disabled={nonEditable}
                />
                {/* <TextField
                    className={classes.textFieldWrap}
                    multiline
                    placeholder="Text area"
                    variant="outlined"
                    size="small"
                    macros={macros}
                    onChange={handleChangeText}
                    rows={4}
                    value={values?.mod_approach_details}
                    name="treatment_approach.mod_approach_details"
                    isDisabled={read_only}
                /> */}
                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.mod_approach_details`}
                    onChange={onChange}
                    macros={macros}
                    values={values.mod_approach_details}
                    isDisabled={nonEditable}
                />
            </Grid>
            <Grid item xs={12}>
                <CheckboxOption
                    name={`treatment_approach.other_treatment`}
                    option={{
                        label: 'Other Treatment:',
                        value: false,
                    }}
                    onChange={handleCheckChange}
                    checked={values?.other_treatment}
                    disabled={nonEditable}
                />
                {/* <TextField
                    className={classes.textFieldWrap}
                    multiline
                    placeholder="Text area"
                    variant="outlined"
                    size="small"
                    macros={macros}
                    onChange={handleChangeText}
                    rows={4}
                    value={values?.other_treat_details}
                    name="treatment_approach.other_treat_details"
                    isDisabled={read_only}
                /> */}
                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.other_treat_details`}
                    onChange={onChange}
                    macros={macros}
                    values={values.other_treat_details}
                    isDisabled={nonEditable}
                />
            </Grid>

            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleChange}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default React.memo(TreatmentApproach)
