export const dateCalendarSx = {
  backgroundColor: 'white',
  '& .MuiPickersSlideTransition-root': {
      minHeight: '220px',
  },
  '& .MuiMonthCalendar-root': {      
      border: 'none',  
  },
  '& .MuiPickersDay-root': {
      borderRadius: '4px',
      width: '36px', 
      height: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
      fontSize: '16px',
  },
  '& .MuiPickersDay-root.MuiToday': {
      backgroundColor: '#f4f4f4',
  },
  '& .Mui-selected': {
      backgroundColor: '#5571C6 !important',
  },
  '& .MuiPickersDay-root:hover': {
      backgroundColor: 'rgba(169, 169, 169, 0.5)',
   },
}