import * as actionType from "../actionType";

const initialState = {
  userId: localStorage.getItem('userId')
};

const user = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default user;
