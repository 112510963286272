import React from 'react';

export default function TeleChattingIcon() {
  return (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.6389 8.23261V14.6784C28.6389 16.2836 28.1081 17.6359 27.1602 18.5712C26.2249 19.5191 24.8726 20.0499 23.2674 20.0499V22.3376C23.2674 23.197 22.3069 23.7152 21.5991 23.235L20.3731 22.4261C20.4869 22.0342 20.5374 21.6045 20.5374 21.1495V16.0056C20.5374 13.4272 18.8185 11.7083 16.2402 11.7083H7.65838C7.48144 11.7083 7.31714 11.721 7.15283 11.7336V8.23261C7.15283 5.00969 9.30144 2.86108 12.5244 2.86108H23.2674C26.4903 2.86108 28.6389 5.00969 28.6389 8.23261Z" stroke="#303E4E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.5376 16.0055V21.1495C20.5376 21.6045 20.487 22.0342 20.3733 22.426C19.9056 24.2839 18.3637 25.4467 16.2404 25.4467H12.8026L8.98563 27.9871C8.41688 28.379 7.65854 27.9619 7.65854 27.2794V25.4467C6.36938 25.4467 5.29508 25.017 4.54939 24.2713C3.79105 23.513 3.36133 22.4386 3.36133 21.1495V16.0055C3.36133 13.6041 4.85272 11.9484 7.15299 11.7336C7.3173 11.7209 7.4816 11.7083 7.65854 11.7083H16.2404C18.8187 11.7083 20.5376 13.4272 20.5376 16.0055Z" stroke="#303E4E" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}
