import { Box, Button, CircularProgress, FormLabel, Grid, makeStyles } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import * as yup from 'yup'

import { apiStatusCodes } from 'services/Calendar'
import { ModalEl } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'

// import { INITIAL_VALUES } from './schema'

import { Select } from 'shared/elements/FormItem/Select'
import { CheckBoxLabel } from '../component/CheckBoxLabel'
import './styles.scss'

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
}))

const INITIAL_VALUES = {
    name: '',
    shortCode: '',
    colorCode: '#f17013',
    description: '',
    category: '',
    is_default: false,
    is_delete_able: false,
}

export const AppointmentStatusCreateDialog = (props) => {
    const classes = useStyles()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [loadingSchedule, setLoadingSchedule] = useState(false)

    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    })

    const [valueCategory, setValueCategory] = useState({
        valueKey: '',
        noDelAble: false,
        makeDefault: false,
    })

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    const handleChange = (color) => {
        formik.setFieldValue('colorCode', color.hex)
        setColor(color.rgb)
    }

    useEffect(() => {
        if (props.itemId) {
            getAStaus(props.itemId)
        }
    }, [props.itemId])

    async function getAStaus(idEvent) {
        try {
            setLoadingSchedule(true)
            const response = await apiStatusCodes.getStatusById(idEvent)

            if (response?.data.statusCode) {
                const { name, code, color, description, category, is_default, is_delete_able } =
                    response?.data.statusCode

                setInitData({
                    ...INITIAL_VALUES,
                    category,
                    is_default,
                    is_delete_able: !is_delete_able,
                    name,
                    shortCode: code,
                    colorCode: color,
                    description,
                })
                const hex2rgba = (hex, alpha = 1) => {
                    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
                    setColor({ r, g, b, a: alpha })
                }
                hex2rgba(color)
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }

    const handleSubmit = ({
        name,
        shortCode,
        colorCode,
        description,
        category,
        is_default,
        is_delete_able,
    }) => {
        props.onSubmit({
            name,
            code: shortCode,
            color: colorCode,
            description,
            category,
            is_default,
            is_delete_able: !is_delete_able,
        })
    }

    const styles = reactCSS({
        default: {
            color: {
                width: '50px',
                height: '20px',
                borderRadius: '6px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '6px',
                marginTop: 8,
                boxShadow: '0 0 0 1px #e9ecf1',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
                top: 150,
                left: 90,
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })

    const validationSchema = yup.object({
        name: yup.string().required('Status Name is required!'),
        shortCode: yup.string().required('Short code is required!'),
        category: yup.string().required('Category Name is required!'),
    })

    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    return (
        <ModalEl
            title={props.itemId ? 'Update status' : 'Add New Status'}
            description={'Booking Appointment Details'}
            open={props.open}
            onClose={() => props.onModalClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                        }}>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid item xs={6}>
                                <TextBox
                                    label="Name"
                                    placeholder="Enter status name"
                                    required
                                    name="name"
                                    value={formik.values.name}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    onChange={(value) => formik.setFieldValue('name', value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    label={'Category'}
                                    placeholder="Select category"
                                    required
                                    value={formik.values.category}
                                    onChange={(value) => formik.setFieldValue('category', value)}
                                    options={props.optionCategory.filter(
                                        (item) => item.value !== 'All',
                                    )}
                                    error={
                                        formik.touched.category && Boolean(formik.errors.category)
                                    }
                                    helperText={formik.touched.category && formik.errors.category}
                                />
                                <Stack direction={'row'} spacing={2} margin="10px 10px">
                                    <CheckBoxLabel
                                        label="Can't be deleted"
                                        // disabled={
                                        //     valueCategory.valueKey ===
                                        //     ''
                                        //         ? true
                                        //         : false
                                        // }
                                        onChange={(e) => {
                                            formik.setFieldValue('is_delete_able', e.target.checked)
                                        }}
                                        checked={formik.values.is_delete_able}
                                    />
                                    <CheckBoxLabel
                                        label="Make default"
                                        // disabled={
                                        //     valueCategory.valueKey ===
                                        //     ''
                                        //         ? true
                                        //         : false
                                        // }
                                        onChange={(e) => {
                                            formik.setFieldValue('is_default', e.target.checked)
                                        }}
                                        checked={formik.values.is_default}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                label="Short Code"
                                placeholder="Enter code"
                                required
                                name="shortCode"
                                value={formik.values.shortCode}
                                error={formik.touched.shortCode && Boolean(formik.errors.shortCode)}
                                helperText={formik.touched.shortCode && formik.errors.shortCode}
                                onChange={(value) => formik.setFieldValue('shortCode', value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel component="p" className="input-form-label">
                                Tags
                            </FormLabel>

                            <div className="fff-color-picker">
                                <div style={styles.swatch} onClick={handleClick}>
                                    <div style={styles.color} />
                                </div>
                                {displayColorPicker ? (
                                    <div style={styles.popover}>
                                        <div style={styles.cover} onClick={handleClose} />
                                        <SketchPicker color={color} onChange={handleChange} />
                                    </div>
                                ) : null}
                            </div>
                        </Grid>

                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    placeholder="Enter description"
                                    label="Description"
                                    name="description"
                                    value={formik.values.description}
                                    onChange={(value) => formik.setFieldValue('description', value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => props.onModalClose()}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={props.loading}
                                variant="contained"
                                className={classes.button_create}
                                loadingPosition="start"
                                startIcon={<></>}
                                onClick={formik.handleSubmit}>
                                {props.itemId ? 'Update' : 'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ModalEl>
    )
}
