const StarIcon = () => {
    return (
        <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2974 3.36588L11.6174 6.00588C11.7974 6.37338 12.2774 6.72588 12.6824 6.79338L15.0749 7.19088C16.6049 7.44588 16.9649 8.55588 15.8624 9.65088L14.0024 11.5109C13.6874 11.8259 13.5149 12.4334 13.6124 12.8684L14.1449 15.1709C14.5649 16.9934 13.5974 17.6984 11.9849 16.7459L9.74243 15.4184C9.33743 15.1784 8.66993 15.1784 8.25743 15.4184L6.01493 16.7459C4.40993 17.6984 3.43493 16.9859 3.85493 15.1709L4.38743 12.8684C4.48493 12.4334 4.31243 11.8259 3.99743 11.5109L2.13743 9.65088C1.04243 8.55588 1.39493 7.44588 2.92493 7.19088L5.31743 6.79338C5.71493 6.72588 6.19493 6.37338 6.37493 6.00588L7.69493 3.36588C8.41493 1.93338 9.58493 1.93338 10.2974 3.36588Z"
                stroke="#667689"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default StarIcon
