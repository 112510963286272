import { Grid, Typography } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import {
    apiFollowUp,
    apiStatusCodes,
    getLocations,
    getSpecialty,
    getVisitType,
    updateSchedule,
} from 'services/Calendar'

import { useStyles } from './styles'
import { Tab, Tabs, makeStyles, withStyles } from '@material-ui/core'
import Routes from 'constants/routes'
import { Waitlist } from '../Waitlist'
import RecallList from './Recall/RecallList'
import ChartPrepList from './ChartPrep/ChartPrepList'
import { useHistory } from 'react-router-dom'
import { useNotification } from 'shared/elements/Notification'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)

const UserStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
}))
export const FollowUp = () => {
    const history = useHistory()
    const previousTab = history.location.state?.previousTab
    const classes = useStyles()
    const definedStyles = UserStyles()
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [dataFollowUP, setDataFollowUP] = useState([])
    const [listLocation, setListLocation] = useState([])
    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [listAppointmentStatus, setListAppointmentStatus] = useState([])

    const [loadingSpecialty, setLoadingSpecialty] = useState(false)
    const [loadingVisitType, setLoadingVisitType] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [loadingStatusCode, setLoadingStatusCode] = useState(false)

    const [mounted, setMounted] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [value, setValue] = useState()
    const [subValue, setsubValue] = useState(0)

    const { locations, visitTypes } = useAppointmentBookingFormData(null)
    const statusOptions = useSelector((state) => state.setting?.appointmentStatus)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [tabName, setTabName] = useState('in_progress')
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const handleChange = (event, newValue) => {
        // tabContainer(newValue)
        setValue(newValue)
    }
    const handleChangeSubValue = (event, newValue) => {
        // tabContainer(newValue)
        setsubValue(newValue)
        if (newValue === 0) {
            setTabName('in_progress')
        } else if (newValue === 1) {
            setTabName('completed')
        }
    }

    useEffect(() => {
        if (value === 0) {
            setsubValue(0)
        }
        if (previousTab) {
            setValue(previousTab)
        }
    }, [value, previousTab])

    useEffect(() => { }, [subValue])

    const [valuesFilter, setValuesFilter] = useState({
        specialty: null,
        visit_type: null,
        service_location: null,
        appointment_status: null,
        serach_patient: null,
    })

    const { specialty, visit_type, service_location, appointment_status, serach_patient } =
        valuesFilter

    const notification = useNotification()

    const handlePageChange = (value) => {
        setPage(value)
    }

    const handleSubmit = async (values) => {
        // console.log(values)
        try {
            setIsSubmitting(true)

            await updateSchedule({ id: appointmentModal.id, payload: values })

            setIsSubmitting(false)
            getListAppointments(page)
            notification('An appointment was successfully updated!', 'success')
        } catch (error) {
            setIsSubmitting(false)
            notification('An appointment was not successfully updated!', 'error')
        }
    }
    const getListAppointments = async (page = 1) => {
        setLoading(true)
        const joinArrString = (arr) => {
            return arr?.map((item) => item.key).join()
        }
        try {
            const res = await apiFollowUp.getAappointments(
                sortModel[0]?.sort
                    ? {
                        _sort: '-_id',
                        page,
                        size: paginationData.limit || 10,
                        [sortModel[0].sort]: sortModel[0].field,
                        location: service_location?.length ? joinArrString(service_location) : '',
                        specialty: specialty?.length ? joinArrString(specialty) : '',
                        'visit-type': visit_type?.length ? joinArrString(visit_type) : '',
                        appointmentStatus: appointment_status?.length
                            ? joinArrString(appointment_status)
                            : '',
                        patient: serach_patient?.key || '',
                    }
                    : {
                        _sort: '-_id',
                        page,
                        size: paginationData.limit || 10,
                        location: service_location?.length ? joinArrString(service_location) : '',
                        specialty: specialty?.length ? joinArrString(specialty) : '',
                        'visit-type': visit_type?.length ? joinArrString(visit_type) : '',
                        appointmentStatus: appointment_status?.length
                            ? joinArrString(appointment_status)
                            : '',
                        patient: serach_patient?.key || '',
                    },
            )
            if (res.data) {
                const dataAppointment = res.data.data
                const dataUi = dataAppointment?.map((item) => ({
                    id: item.id,
                    patient:
                        (item.participant[0]?.first_name || '') +
                        ' ' +
                        (item.participant[0]?.last_name || ''),
                    patient_status: item.patient_status,
                    patient_phone: item.participant[0]?.phone_number,
                    service_location: item.location_name,
                    specialty: item.specialty?.specialty,
                    visit_type: item.visit_type?.post_code,
                    date: item.start,
                    appointment_status: item.appointment_status,
                    priority: item.priority,
                    patient_status_code: item.patient_status_code,
                    appointment_status_code: item.appointment_status_code,
                }))
                setDataFollowUP(dataUi || [])

                setTotal(res.data.totalCount)
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong !', 'error')
        } finally {
            setLoading(false)
        }
    }

    const getListLocation = async () => {
        try {
            setLoadingLocation(true)
            const {
                data: { data },
            } = await getLocations({ size: 100000 })
            setListLocation(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.name,
                }))
                // list.unshift({ key: '', value: 'All' })
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingLocation(false)
        }
    }

    const getListSpecialty = async () => {
        try {
            setLoadingSpecialty(true)
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                // list.unshift({ key: '', value: 'All' })
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingSpecialty(false)
        }
    }

    const getListVisitType = async () => {
        try {
            setLoadingVisitType(true)
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType({ page: 1, limit: 100000 })

            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                // list.unshift({ key: '', value: 'All' })
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingVisitType(false)
        }
    }

    const getAppointmentStatus = async () => {
        try {
            setLoadingStatusCode(true)
            const res = await apiStatusCodes.getStatusCodes({
                category: 'APPOINTMENT',
            })

            setListAppointmentStatus((prev) => {
                const list = res.data?.listStatusCode?.data.map((item) => ({
                    key: item.code,
                    value: item.name,
                }))
                if (list) {
                    // list.unshift({ key: '', value: 'All' })
                    return list
                } else {
                    return prev
                }
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingStatusCode(false)
        }
    }

    useEffect(() => {
        ; (async () => {
            const abortController = new AbortController()
            await Promise.all([
                getListLocation(),
                getListSpecialty(),
                getListVisitType(),
                getAppointmentStatus(),
            ])
            return () => abortController.abort()
        })()

        setValue(0)
        if (history?.location?.state?.from === 'Recall') {
            setValue(2)
        }
        if (history?.location?.state?.from === 'ChatPrep') {
            setValue(0)
        }
        window?.history?.replaceState(null, '')
    }, [])

    useEffect(() => {
        const abortController = new AbortController()
        getListAppointments(page)
        return () => abortController.abort()
    }, [page, sortModel])

    useEffect(() => {
        if (!mounted) return setMounted(true)
        if (page !== 1) return setPage(1)

        const abortController = new AbortController()
        getListAppointments()
        return () => abortController.abort()
    }, [specialty, visit_type, service_location, appointment_status, serach_patient])

    const handleCellClick = (row) => {
        // console.log(row, 'log params')
    }

    const handleChangeSelect = (option, checked, state, field) => {
        let arr = [...(state ? state : [])]
        console.log(option, checked)
        if (checked) {
            arr.push(option)
        } else {
            arr = arr.filter((item) => item.key !== option.key)
        }
        setValuesFilter({ ...valuesFilter, [field]: arr })
    }

    return (
        <Grid container className={classes.container}>
            <Grid container>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Chart Prep"
                    />
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Waitlist"
                    />
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Recall"
                    />
                </Tabs>
                <Grid
                    container
                    style={{
                        display: 'inline-flex',
                        alignItems: 'flex-end',
                        borderTop: '1px solid #E5E9E8 ',
                    }}></Grid>
            </Grid>
            {value === 1 && <Waitlist />}

            {value === 0 && <ChartPrepList />}
            {value === 2 && <RecallList />}
        </Grid>
    )
}
