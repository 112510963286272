import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CheckboxOption from '../reusable/CheckboxOptions'
import TextWithMacro from '../reusable/TextWithMacro'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import CustomTabs from '../../shared/Elements/CustomTabs'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyBar from 'components/shared/Elements/CopyBar'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const noteTxt = `Please look at the list of physical symptoms below and check off any that you have experienced in the last several days. If you have NOT experienced any symptoms in an area, be sure to check "None of the above" for that area. If you are filling this out on behalf of the patient, please answer from the patient's perspective.`

const useStyles = makeStyles({
    rootContainerGrid: {
        marginBottom: 20,
        borderRadius: 4,
    },
    horizontalGridContainer: {
        // border: '1px solid #B0BCE4',
        // borderRadius: 4,
    },
    noteTxt: {
        color: '#9CA9B8',
        fontSize: 12,
    },
    horizontalGridItem: {
        height: 'fit-content',
        // border: '1px solid #B0BCE47F',
        display: 'flex',
        alignItems: 'center',
    },
    textFieldWrap: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },
    optionLabel: {
        color: '#132044',
        fontSize: 16,
        padding: 7,
    },
    header: {
        fontWeight: '500',
        fontSize: '16px',
        color: '#303E4E',
        margin: '24px 0px 6px 0px',
    },
})

const mapOptions = (masterData = []) => {
    return masterData.map((md, index) => {
        if (
            md.reviews_options.length > 0 &&
            !md.reviews_options.some((ro) => ro.type === 'other')
        ) {
            md.reviews_options.splice(md.reviews_options.length - 1, 0, { type: 'other' })
        }
        return {
            ...md,
        }
    })
}

const ReviewOfSystems = ({
    master,
    lastNarrative,
    fieldName,
    values,
    onChange,
    macros,
    initialValue,
    nonEditable,
    copyAble,
    copyValues
}) => {
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = useState(0)
    const reviewMaster = mapOptions(master?.systemReviewMasters)

    const handleChange = (e, header) => {
        if (nonEditable) { return }
        const { name, value, checked, type } = e.target
        const system_reviews_opt = _.cloneDeep(values.system_reviews_opt)
        const existingIndex = system_reviews_opt?.findIndex((sro) => sro?.header === header)

        if (type === 'checkbox') {
            if (existingIndex !== -1) {
                if (checked) {
                    const isNone = reviewMaster
                        .find((item) => item._id === header)
                        .reviews_options.find((item) => item.is_none)
                    if (isNone?._id === value) {
                        system_reviews_opt[existingIndex] = {
                            ...system_reviews_opt[existingIndex],
                            reviews_options: [value],
                        }
                    } else {
                        const reviews_options =
                            system_reviews_opt[existingIndex]?.reviews_options?.filter(
                                (sro) => sro !== isNone?._id,
                            ) || []
                        reviews_options.push(value)
                        system_reviews_opt[existingIndex] = {
                            ...system_reviews_opt[existingIndex],
                            reviews_options,
                        }
                    }
                } else {
                    system_reviews_opt[existingIndex] = {
                        ...system_reviews_opt[existingIndex],
                        reviews_options:
                            system_reviews_opt[existingIndex]?.reviews_options?.filter(
                                (sro) => sro !== value,
                            ) || [],
                    }
                }
            } else {
                system_reviews_opt.push({
                    header,
                    reviews_options: [value],
                    other: '',
                })
            }
        } else if (type === 'text' || type === 'textarea') {
            if (existingIndex !== -1) {
                system_reviews_opt[existingIndex] = {
                    ...system_reviews_opt[existingIndex],
                    other: value,
                }
            } else {
                system_reviews_opt?.push({
                    header,
                    reviews_options: [],
                    other: value,
                })
            }
        }
        onChange(`${fieldName}.${name}`, system_reviews_opt)
    }

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const hasValue = copyValues?.system_reviews_opt.some(item => item?.reviews_options.length > 0 || item?.other.trim() !== '');
            const foundValues = copyValues?.system_reviews_opt.map((item, index)=>{
                return{
                    ...item,
                    other: values?.system_reviews_opt[index]?.other 
                        ? `${item?.other} ${values?.system_reviews_opt[index]?.other}`
                        :item?.other,
                    reviews_options: [
                            ...new Set([
                                ...(item?.reviews_options || []), 
                                ...(values?.system_reviews_opt?.[index]?.reviews_options || [])
                            ])
                        ]
                }
            })

            const finalValues = {
                system_reviews_opt: foundValues,
                narrative: `${copyValues?.narrative} ${values?.narrative}`
            }

            if (hasValue || copyValues?.narrative !== '') {
                onChange(fieldName, finalValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}>
            {
                !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )
            }
            <Typography className={classes.noteTxt}>{noteTxt}</Typography>
            <Grid container className={classes.rootContainerGrid}>
                {/* <Grid item xs={12}>
                    <CustomTabs
                        contents={reviewMaster}
                        selectedTab={selectedTab}
                        onChangeTab={(e) => {
                            setSelectedTab(e)
                        }}
                    />
                </Grid> */}

                {reviewMaster
                    // .filter(
                    //     (_, index) => index === selectedTab,
                    // )
                    .map((rm, index) => {
                        const filteredEl = values?.system_reviews_opt?.find(
                            (sro) => sro?.header === rm._id,
                        )

                        return (
                            <>
                                <Typography className={classes.header}>
                                    {rm.header_name || rm.header_label}
                                </Typography>
                                <Grid
                                    key={index}
                                    container
                                    className={classes.horizontalGridContainer}>
                                    {rm.reviews_options
                                        .filter((item) => item.type !== 'other')
                                        .map((ro, i) => {
                                            const isChecked = filteredEl?.reviews_options?.includes(
                                                ro._id,
                                            )
                                            return (
                                                <Grid
                                                    key={i}
                                                    xs={4}
                                                    item
                                                    className={classes.horizontalGridItem}>
                                                    <CheckboxOption
                                                        name={`system_reviews_opt`}
                                                        option={{
                                                            label: ro.name,
                                                            value: ro._id,
                                                        }}
                                                        onChange={(e) => {
                                                            handleChange(e, rm._id)
                                                        }}
                                                        checked={isChecked ? true : false}
                                                    />
                                                </Grid>
                                            )
                                        })}

                                    {rm.reviews_options
                                        .filter((item) => item.type === 'other')
                                        .map((ro, i) => {
                                            return (
                                                <Box
                                                    key={i}
                                                    sx={{
                                                        display: 'flex',
                                                        marginTop: 12,
                                                        width: '100%',
                                                    }}>
                                                    <TextFieldDebounce
                                                        name={`system_reviews_opt`}
                                                        variant="outlined"
                                                        placeholder="Other"
                                                        multiline
                                                        rows={4}
                                                        disabled={nonEditable}
                                                        className={classes.textFieldWrap}
                                                        onChange={(e) => {
                                                            handleChange(e, rm._id)
                                                        }}
                                                        value={filteredEl?.other || ''}
                                                    />
                                                </Box>
                                            )
                                        })}
                                </Grid>
                            </>
                        )
                    })}
            </Grid>
            <Grid item>
                <NarrativeAndMacro
                    hideClearBtn={true}
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    onChange={onChange}
                    macros={macros}
                    meta={{
                        values,
                        master: reviewMaster,
                    }}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box >
    )
}

export default ReviewOfSystems
