import * as actionType from '../actionType';

const initialState = {
    macroList: [],
    practitionerMacroList: [] ,
    totalCount: 0,
};

const macros = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_MACRO_LIST: {
            return {
                ...state,
                macroList: action.value,
            };
        }
        case actionType.SET_PRACTITIONER_MACRO_LIST: {
            return {
                ...state,
                practitionerMacroList: action.value,
            };
        }
        case actionType.SET_TOTAL_MACRO_COUNT: {
            return {
                ...state,
                totalCount: action.value,
            };
        }
        case actionType.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default macros;
