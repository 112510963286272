import * as actionType from '../actionType'

const initialState = {
    appointmentStatus: [],
}

const setting = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_APPOINTMENT_STATUS: {
            return {
                ...state,
                appointmentStatus: action.value,
            }
        }
        default:
            return state
    }
}

export default setting
