const NextIcon = () => {
    return (
        <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3225 14.115C11.18 14.115 11.0375 14.0625 10.925 13.95C10.7075 13.7325 10.7075 13.3725 10.925 13.155L15.08 8.99997L10.925 4.84497C10.7075 4.62747 10.7075 4.26747 10.925 4.04997C11.1425 3.83247 11.5025 3.83247 11.72 4.04997L16.2725 8.60247C16.49 8.81997 16.49 9.17997 16.2725 9.39747L11.72 13.95C11.6075 14.0625 11.465 14.115 11.3225 14.115Z"
                fill="#303E4E"
            />
            <path
                d="M15.7475 9.5625H3.125C2.8175 9.5625 2.5625 9.3075 2.5625 9C2.5625 8.6925 2.8175 8.4375 3.125 8.4375H15.7475C16.055 8.4375 16.31 8.6925 16.31 9C16.31 9.3075 16.055 9.5625 15.7475 9.5625Z"
                fill="#303E4E"
            />
        </svg>
    )
}
export default NextIcon
