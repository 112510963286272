import React, { useState } from 'react'
import './Login.scss'
import logo from '../../assests/Logos/tm2u_logo_150.png'
import {
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormHelperText,
    FormControlLabel,
    FormLabel,
    TextField,
    Grid,
    Container,
    CircularProgress,
    Modal,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import backIcon from '../../assests/images/icon_back.png'
import { Formik } from 'formik'
import { ButtonSpinner } from '../custom'

const StaffLoginForm = (props) => {
    const { onSubmit, error, isOTPRequired, isLoading } = props

    const mapErrorCode = (error) => {
        if (error.code === 'NotAuthorizedException') {
            return {
                email: error.message,
                password: error.message,
                otp: null,
            }
        } else if (error.code === 'CodeMismatchException') {
            return {
                email: null,
                password: null,
                otp: error.message,
            }
        }
    }

    return (
        <Container className="container">
            <Grid container className="form-wrapper">
                <Grid item xs={12} sm={6} className="" span={12}>
                    <div
                        className="form-img"
                        style={{
                            background: 'url(http://source.unsplash.com/645x480/?medical)',
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} className="" span={12} ml={2}>
                    <Formik
                        initialErrors={mapErrorCode(error)}
                        initialValues={{
                            email: '',
                            password: '',
                            otp: '',
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.email) {
                                errors.email = 'Required'
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address'
                            }
                            return errors
                        }}
                        onSubmit={(values) => {
                            if (isOTPRequired) {
                                onSubmit(values.otp)
                            }
                            onSubmit(values.email, values.password)
                        }}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form className="form" noValidate onSubmit={handleSubmit}>
                                <div className="inner-wrapper-logo">
                                    <img className="mobile-logo" alt="TeleMed2U" src={logo}></img>
                                </div>
                                <h1 className="align-item-center">
                                    <Link id="auto-law-back" to="/">
                                        <img className="ba-back-icon" src={backIcon} alt="" />
                                    </Link>
                                    <span className="main-header">Login to Sonora EHR</span>
                                </h1>
                                {isOTPRequired ? (
                                    <>
                                        <FormLabel className="input-label" component="legend">
                                            OTP
                                            <span className="astrik">*</span>
                                        </FormLabel>
                                        <TextField
                                            required
                                            error={Boolean(errors.otp)}
                                            helperText={Boolean(errors.otp) ? errors.otp : null}
                                            variant="outlined"
                                            size="small"
                                            className="text-input"
                                            type="text"
                                            name="otp"
                                            value={values.otp}
                                            placeholder="Enter Your OTP"
                                            inputProps={{
                                                maxLength: 5,
                                            }}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FormLabel className="input-label" component="legend">
                                            Email
                                            <span className="astrik">*</span>
                                        </FormLabel>
                                        <TextField
                                            id="auto-law-phone"
                                            required
                                            error={Boolean(
                                                errors.email ||
                                                    error.code === 'NotAuthorizedException',
                                            )}
                                            helperText={
                                                Boolean(
                                                    errors.email ||
                                                        error.code === 'NotAuthorizedException',
                                                )
                                                    ? errors.email || error.message
                                                    : null
                                            }
                                            value={values.email}
                                            variant="outlined"
                                            size="small"
                                            className="text-input"
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your Email"
                                            inputProps={{
                                                maxLength: 225,
                                            }}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                        <FormLabel className="input-label" component="legend">
                                            Password
                                            <span className="astrik">*</span>
                                        </FormLabel>
                                        <TextField
                                            id="auto-law-phone"
                                            required
                                            value={values.password}
                                            error={
                                                errors.password ||
                                                error.code === 'NotAuthorizedException'
                                            }
                                            helperText={
                                                Boolean(
                                                    errors.password ||
                                                        error.code === 'NotAuthorizedException',
                                                )
                                                    ? errors.password || error.message
                                                    : null
                                            }
                                            variant="outlined"
                                            size="small"
                                            className="text-input"
                                            type="password"
                                            name="password"
                                            placeholder="Enter Your Password"
                                            inputProps={{
                                                maxLength: 255,
                                            }}
                                            onChange={handleChange}
                                            disabled={false}
                                        />
                                    </>
                                )}
                                <div className="center-align">
                                    <ButtonSpinner
                                        id="auto-law-submit-btn"
                                        type="submit"
                                        label="Submit"
                                        variant="contained"
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                        className={'btn-primary'}
                                    />
                                </div>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    )
}

export default StaffLoginForm
