import React, { useRef, useState, useEffect } from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import CalenderTick from '../../assests/icons/calendar-tick.svg'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { DateRange } from 'react-date-range'
import Routes from 'constants/routes'
import { Footer } from 'shared/elements/FormItem/Footer'
import { INITIAL_VALUES } from './schema'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useNotification } from 'shared/elements/Notification'
import './styles.scss'
import dayjs from 'dayjs'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import {
    getOneNotification,
    postEditSystemNotification,
    postSystemNotification,
} from 'services/Patient/patient'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import format from 'date-fns/format'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import moment from 'moment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    form: {
        position: 'relative',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 15,
    },
})

function createSystemNotification() {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const notification = useNotification()

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [singleDate, setSingleDate] = useState('')
    const [filedDueSchedule, setFiledDueSchedule] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [dateRangeModalOpen, setDateRangeModalOpen] = useState(false)
    const [activeRange, setActiveRange] = useState(false)
    const goBack = () => history.push(Routes.SETTINGS.SYSTEM_ANNOUNCEMENT)
    const userName = localStorage.getItem('name')


    const handleSubmit = async (values) => {
        setIsSubmitting(true)
        const payload = {
            ...values,
            created_by: userName,
        }

        try {
            let response = ''
            if (location?.state?.id) {
                response = await postEditSystemNotification(payload, location?.state?.id)
            } else {
                response = await postSystemNotification(payload)
            }

            if (response?.data?.status === 'Success') {
                notification(
                    `Announcement ${location?.state?.id ? 'updated' : 'created'} successfully!`,
                    'success',
                )
                setIsSubmitting(false)
                history.push(Routes.SETTINGS.SYSTEM_ANNOUNCEMENT)
            }
        } catch (e) {
            notification('Something went wrong!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    const validationSchema = yup.object({
        announcement_title: yup.string().required('Announcement title is required!'),
        content: yup.string().required('Content is required!'),
        duration_start_date: yup.date().test({
            name: 'duration_start_date',
            test(value, context) {
                if(!value){
                    return this.createError({
                        message: 'Start date is required!',
                        path: 'duration_start_date',
                    })
                }
                const start = moment(context.parent.duration_start_date, 'MM/DD/YYYY')
                const end = moment(context.parent.duration_end_date)
                if (end.isBefore(start)) {
                    return this.createError({
                        message: 'Start date must be before end date!',
                        path: 'duration_start_date',
                    })
                }
                return true
            },
        }),
        duration_end_date: yup.date().test({
                name: 'duration_end_date',
                test(value, context) {
                    if(!value && activeRange){
                        return this.createError({
                            message: 'End date is required!',
                            path: 'duration_end_date',
                        })
                    }
                    const start = moment(context.parent.duration_start_date, 'MM/DD/YYYY')
                    const end = moment(context.parent.duration_end_date)
                    if (end.isBefore(start)) {
                        return this.createError({
                            message: 'End date must be after start date!',
                            path: 'duration_end_date',
                        })
                    }
                    return true
                },
            })
    })

    const formik = useFormik({
        initialValues: INITIAL_VALUES,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })

    //heading title
    const headingTitle = () => {
        const pageTitle = location?.state?.id ? 'Update Announcement' : 'Create Announcement'

        return <h1 className="page-title">{pageTitle}</h1>
    }

    const setValueForEdit = async (id) => {
        try {
            setIsLoading(true)
            const res = await getOneNotification(id)
            if(!res) {
                notification('Something went wrong!', 'error')
                return
            }

            const data = res?.data?.data
            const outcomeDataToSet = {
                announcement_title: data?.announcement_title || '',
                content: data?.content || '',
                duration_start_date: data?.duration_start_date || '',
                duration_end_date: data?.duration_end_date || '',
            }

            formik.setValues(outcomeDataToSet)

            const isActiveRange = !moment.utc(data?.duration_start_date)?.isSame(moment.utc(res?.data?.data?.duration_end_date), 'day')

            setActiveRange(isActiveRange)

            if (data?.duration_start_date) {
                setEndDate(
                    data?.duration_end_date
                        ? moment(data?.duration_end_date?.split('T')[0]).format(
                              'MM/DD/YYYY',
                          )
                        : '',
                )
                setStartDate(format(new Date(data?.duration_start_date), 'MM/dd/yyyy'))
                setSingleDate(format(new Date(data?.duration_start_date), 'MM/dd/yyyy'))
            }
            setActiveRange(!moment.utc(data?.duration_start_date)?.isSame(moment.utc(res?.data?.data?.duration_end_date), 'day'))
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            console.log(e)
        }
    }
    useEffect(() => {
        if (location?.state?.id) {
            setIsLoading(true)
            setValueForEdit(location.state.id)
        }
    }, [location])

    useEffect(() => {
        const abortController = new AbortController()
        return () => abortController.abort()
    }, [])

    return (
        <div ref={formRef} style={{ height: '100%' }}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    onClick={() => goBack()}
                    style={{
                        cursor: 'pointer',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>
                        {'Back to Announcement list'}
                    </Typography>
                </Grid>
                <Grid container className={`border-bottom`}>
                    {headingTitle()}
                </Grid>

                <Grid container className={classes.form}>
                    <Grid item xs={12}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography
                                    className={'heading1-title'}
                                    style={{ fontSize: '18px' }}>
                                    {location?.state?.id ? 'Update Announcement' : 'Create Announcement'}
                                </Typography>
                            </legend>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <TextBox
                                        label="Announcement Title"
                                        required
                                        name="announcement_title"
                                        value={formik.values.announcement_title}
                                        placeholder="Enter title of announcement"
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.announcement_title &&
                                            Boolean(formik.errors.announcement_title)
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue('announcement_title', value)
                                        }
                                        helperText={
                                            formik.errors.announcement_title &&
                                            formik.touched.announcement_title
                                                ? formik.errors.announcement_title
                                                : ''
                                        }
                                    />
                                </Grid>
                            </Grid>

                            
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, gap: 10 }}>
                                <Typography
                                    style={{
                                        color: 'rgb(48, 62, 78)',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                    }}>
                                    Duration of announcement
                                    <span style={{ color: 'red' }}>*</span>
                                </Typography>

                                <div style={{ display: 'flex', width: '100%', gap: 20, alignItems: 'flex-start' }}>
                                    {!activeRange ? 
                                        <div
                                            style={{
                                                marginTop: '1px',
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}>
                                            <div className='calendar_container'> 
                                                <SingleCalenderComp
                                                    onChange={(e) => {
                                                        const dateToShow = e ? moment(e).format('MM/DD/YYYY') : ''
                                                        formik.setFieldValue(
                                                            'duration_start_date',
                                                            e ? moment(e).format('YYYY-MM-DDTHH:mm:ss.SSS') : '',
                                                        )
                                                        formik.setFieldValue(
                                                            'duration_end_date',
                                                            e ? moment(e).format('YYYY-MM-DDT23:59:59.000') : '',
                                                        )
                                                        setSingleDate(dateToShow)
                                                        setStartDate(dateToShow)
                                                        setEndDate(dateToShow)
                                                    }}
                                                    HideShowIcon={activeRange}
                                                    value={singleDate}
                                                    onClear={() => {
                                                        setSingleDate('')
                                                        formik?.setFieldValue('duration_start_date', '')
                                                        formik?.setFieldValue('duration_end_date', '')
                                                    }}
                                                    placeHolder='Enter date'
                                                    error={
                                                        formik.touched.duration_start_date &&
                                                        Boolean(formik.errors.duration_start_date)
                                                    }
                                                    helperText={
                                                        formik.errors.duration_start_date &&
                                                        formik.touched.duration_start_date
                                                            ? formik.errors.duration_start_date
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                        :
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                backgroundColor: 'primary.dark',
                                                '&:hover': {
                                                    backgroundColor: 'primary.main',
                                                    opacity: [0.9, 0.8, 0.7],
                                                },
                                            }}>
                                            
                                            <div className='calendar_container'>
                                                <SingleCalenderComp
                                                    onChange={(e) => {
                                                        if(e) {
                                                            const currentStartDate = moment(e)
                                                            formik.setFieldValue('duration_start_date', currentStartDate?.format('YYYY-MM-DDTHH:mm:ss.SSS'))
                                                            setStartDate(currentStartDate?.format('MM/DD/YYYY'))
                                                            setSingleDate(currentStartDate?.format('MM/dd/yyyy'))
                                                        }else {
                                                            formik.setFieldValue('duration_start_date', '')
                                                            setStartDate('')
                                                            setSingleDate('')
                                                        }
                                                    }}
                                                    // HideShowIcon={openStart}
                                                    value={startDate}
                                                    onClear={() => {
                                                        setSingleDate('')
                                                        setStartDate('')
                                                    }}
                                                    placeHolder='Enter start date'
                                                    error={
                                                        formik.touched.duration_start_date &&
                                                        Boolean(formik.errors.duration_start_date)
                                                    }
                                                    helperText={
                                                        formik.errors.duration_start_date &&
                                                        formik.touched.duration_start_date
                                                            ? formik.errors.duration_start_date
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            
                                            <ArrowRightAltIcon
                                                style={{
                                                    marginTop:
                                                        '5px',
                                                    color: 'grey',
                                                    marginRight:
                                                        '12px',
                                                    marginLeft:
                                                        '12px',
                                                }}
                                            />
                                            
                                            <div className='calendar_container'>
                                                <SingleCalenderComp
                                                    onChange={(e) => {
                                                        if(e) {
                                                            const currentEndDate = moment(e)
                                                            formik.setFieldValue('duration_end_date', currentEndDate?.format('YYYY-MM-DDTHH:mm:ss.SSS'))
                                                            setEndDate(currentEndDate?.format('MM/DD/YYYY'))
                                                        }else {
                                                            formik.setFieldValue('duration_end_date', '')
                                                            setEndDate('')
                                                        }
                                                    }}
                                                    // HideShowIcon={openStart}
                                                    value={endDate}
                                                    onClear={() => {
                                                        setEndDate('')
                                                    }}
                                                    placeHolder='Enter end date'
                                                    error={
                                                        formik.touched.duration_end_date &&
                                                        Boolean(formik.errors.duration_end_date)
                                                    }
                                                    helperText={
                                                        formik.errors.duration_end_date &&
                                                        formik.touched.duration_end_date
                                                            ? formik.errors.duration_end_date
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </Box>
                                    }
                                
                                    <HtmlTooltip
                                        title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                                        arrow
                                        placement="bottom">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    onChange={(event) => {
                                                        const checkedValue = event?.target?.checked
                                                        // localforage.setItem('clinicalActiveRange', !activeRange)
                                                        setActiveRange(checkedValue)
                                                        // onChangeFilterData('createdAt', [])
                                                        if (
                                                            !singleDate &&
                                                            checkedValue
                                                        ) {
                                                            formik.setFieldValue(
                                                                'duration_end_date',
                                                                '',
                                                            )
                                                            formik.setFieldValue(
                                                                'duration_start_date',
                                                                '',
                                                            )
                                                            setStartDate('')
                                                            setEndDate('')
                                                            setSingleDate('')
                                                        }
                                                        if(!checkedValue){
                                                            formik?.setFieldValue('duration_end_date', formik?.values?.duration_start_date)
                                                            setEndDate(startDate)
                                                        }
                                                    }}
                                                    checked={activeRange}
                                                />
                                            }
                                            label=""
                                        />
                                    </HtmlTooltip>
                                </div>
                            </div>
                            
                            <div style={{ marginTop: 10 }}>
                                <TextBox
                                    label="Announcement Content"
                                    required
                                    name="content"
                                    value={formik.values.content}
                                    multiline
                                    minRows={12}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 'auto !important',
                                        },
                                    }}
                                    placeholder="Enter Announcement Comment"
                                    onChange={(value) => {
                                        formik.setFieldValue('content', value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    error={!!(formik.errors.content && formik.touched.content)}
                                    helperText={
                                        formik.errors.content && formik.touched.content
                                            ? formik.errors.content
                                            : ''
                                    }
                                />
                            </div>
                        </fieldset>
                    </Grid>
                </Grid>
                <Grid container style={{ borderTop: '1px solid #CFCFCF' }}>
                    <Footer
                        style={{
                            marginTop: '1.5rem',
                        }}
                        loading={isSubmitting}
                        onCancel={goBack}
                        okBtnText={'Save'}
                        disabled={isSubmitting}
                        onOk={() => {
                            formik.handleSubmit()
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default createSystemNotification
