import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, makeStyles, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from 'clsx';
import { createDecipheriv } from "crypto";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useHistory, useLocation } from "react-router-dom";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import IconClock from '../../assests/icons/IconClock.js';
import Tms2uLogo from '../../assests/icons/Tms2uLogo.js';
import Avatar from '../../assests/images/avatar.svg';
import bgImage from '../../assests/images/waiting_room_bg.jpg';
import { config } from "../../constants";
import { getIsLoggedIn } from "../../utilities";
import LoadingPage from "../../utilities/loading-page";
import ServicesSlider from "../ServicesSlider/index.js";
import { getPatientCount, submitConsulationTracking } from "./apis/closingConsultation";
import createWaitingQueue, { WaitingQueue } from "./apis/createWaitingQueue";
import {
  getAppointmentDetails,
  getParticipantDetails,
  getPatientDetails,
  getProvidersDetails
} from "./apis/fetchDetails";
import getCurrentupcoming from "./apis/getCurrentUpcoming";
import listWaitingQueue from "./apis/listWaitingQueue";
import { getCurrentApp } from "./apis/setCurrentApp";
import ToggleAudioButton from "./components/Buttons/ToggleAudioButton/ToggleAudioButton";
import ToggleVideoButton from "./components/Buttons/ToggleVideoButton/ToggleVideoButton";
import useChatContext from "./hooks/useChatContext/useChatContext";
import useProviderContext from "./hooks/useProviderContext/useProviderContext";
import useRoomState from "./hooks/useRoomState/useRoomState";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import { useAppState } from "./state";

const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: 'contain',
    height: '100%',

    '& .MuiTypography-subtitle1': {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '500',
      color: '#132044',
    },
    '& .MuiTypography-subtitle2': {
      fontSize: '13px',
      lineHeight: '18.2px',
      fontWeight: '400',
      color: '#667689',
    },
  },
  logo: {
    width: '111.94px',
    height: '50px',
    position: 'absolute',
    top: 40,
    left: 50,
  },
  avartar: {
    width: '273px',
    height: '265px',
    marginTop: '80px',
    borderRadius: 150,
    overflow: "hidden",
    borderWidth: 3,
  },
  info_column: {
    display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px 20px',
  },
  name: {
    marginTop: '16px',
    fontSize: '32px',
    lineHeight: '44.8px',
    color: '#132044',
    fontWeight: 500,
  },
  user_info: {
    width: 'fit-content',
    marginTop: '16px',
    marginBottom: '16px',
    borderBottom: '1px solid #ffffff4d',
    paddingBottom: '16px',
  },
  waiting_time: {
    width: 'fit-content'
  },
  time: {
    color: '#14AE5C',
    lineHeight: 'normal'
  },
  buttons: {
    margin: '0px 6px',
    padding: '0px',
    background: 'rgba(255, 255, 255, 0.6)',
    width: '65px',
    height: '65px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
        background: 'rgba(34,43,55,0.5) !important',
    },
  },
  btn_group: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '32px',
      width: 'fit-content',
      background: "rgba(255, 255, 255, 0.3)",
      borderRadius: 30,
      padding: 10,
  },
  info_table_header: {
    fontSize: '13px',
    lineHeight: '18.2px',
    fontWeight: 400,
    color: '#667689',
    marginBottom: 5

  },
  info_table_content: {
    fontSize: '16px',
    lineHeight: '22.4px',
    fontWeight: 500,
    color: '#132044',

  },


  border: {
    borderRight: '1px solid #ffffff4d',
  },
  boxService: {
    position: 'relative'

  },

}))


function JoinMainRoom() {

  const classes = useStyles()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [waitingTime, setWaitingTime] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');
  const [socketUrl, setSocketUrl] = useState<string>('');
  const location = useLocation()
  const roomState = useRoomState()
  const isReconnecting = roomState === 'reconnecting'

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const data = query.get("data")
  
  const [userInfor, setUserInfor] = useState<{ avartar: string, name: string, startTime: string, endTime: string, duaration: string}>({
    avartar: '',
    name: '',
    startTime: '',
    endTime: '',
    duaration: '',

  });
  const { user, getToken, isFetching } = useAppState();
  const {
    connect: videoConnect,
    isAcquiringLocalTracks,
    isConnecting,
    localTracks,
  } = useVideoContext();
  const { connect: chatConnect, conversation } = useChatContext();
  const {
    socket,
    setAccessAdmit,
    setCurrentUser,
    setListWaiting,
    setCountWaitingUser,
    setAppointmentInfo,
    setupcoming,
    setcurrentAppointment,
  } = useProviderContext();
  let history = useHistory();

  useEffect(() => {
    if (location?.state?.infoRoom) {
      converDataForDisplayDisplay(location.state.infoRoom)
      setAppointmentInfo(location?.state?.infoRoom);
    }
    
  }, [location]);

  const modalClose = () => {
    setIsModalOpen(false);
    window.location.href = '/';
  }

  const AlertModal = () => {
    return (
      <Dialog open={isModalOpen} onClose={() => modalClose()}>
        <Alert variant="outlined" severity="info">
          <DialogTitle id="alert-modal-title">Appointment information is incorrect. Check it again.</DialogTitle>
          <DialogActions>
            <Button onClick={() => modalClose()} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Alert>
      </Dialog>
    );
  };

  const decrypt = (encryptediv: any, encryptedData: any) => {
    try {
      if (config.KEY) {
        let iv = Buffer.from(encryptediv, "hex");
        let encryptedText = Buffer.from(encryptedData, "hex");
        let decipher = createDecipheriv(
          "aes-256-cbc",
          Buffer.from(config.KEY),
          iv
        );
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
      }
    }
    catch (e) {
      console.log("error", e)
      return ''
    }

  };


  const handleDirectLink = async (iv: any, data: any) => {

    const fetchWaitingList = async (roomid: number) => {
      try {
        const res = await listWaitingQueue(roomid);
        setListWaiting(res.data);
        let count = 0;
        if (res.data && res.data.length > 0) {
          res.data.map((el: any) => {
            if (!el.is_admitted) {
              count = count + 1;
            }
          })
          setCountWaitingUser(count);
        } else {
          setCountWaitingUser(0);
        }
        return res.data;
      } catch (error) {
        console.log({ Errorwaitinglist: error });
      }
    };

    const handleForProvider = async (
      first_name: string,
      last_name: string,
      roomName: string,
      roomId: number,
      provider: number
    ) => {
      getToken(`${first_name} ${last_name}`, roomName, provider)
        .then(async (token) => {
          await videoConnect(token);
          // await deleteChatMessages(conversation?.sid);
          // process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== "true" &&
          //   (await chatConnect(token));
          let waitingList: any = await fetchWaitingList(Number(roomId));
          let admittedPatientList: any = []
          waitingList && waitingList.map((pat: any) => {
            if (pat.is_admitted === true) {
              admittedPatientList.push(pat)
            }
          })
          let isPatinetAdmitted = admittedPatientList && admittedPatientList.length > 0 ? true : false
          if (isPatinetAdmitted) {

            let data = localStorage.getItem('roomName');
            let countPayload = { roomName: data };
            let countData = await getPatientCount(countPayload);
            if (countData) {
              let payload = {
                is_consultation_end: false,
                provider_id: provider,
                participants_count: countData.data.length,
                appointment_id: admittedPatientList[0].appointment_id,
              };
              let res = await submitConsulationTracking(payload);
            }
          }
          let appId = localStorage.getItem("currentAppointment");

          let isCurrentAvailable = waitingList.filter((app: any) => app.is_admitted && app.appointment_id == appId);
          if (isCurrentAvailable.length > 0) {
            setCurrentUpcoming(provider, appId);
          }
          // history.push(`/room/${roomName}`);
        })
        .catch((err) => {
          console.log(err);
          history.push(`/`);
        });
    };

    const setCurrentUpcoming = async (provider_id: any, appointment_id: any) => {
      let appointmentDetails: any = await getAppointmentDetails(appointment_id);
      let upcomingAppDetails: any = await getCurrentupcoming(provider_id, appointmentDetails.data.end);
      if (appointmentDetails && appointmentDetails.data) {
        let currentApp = await getCurrentApp(appointment_id);
        setcurrentAppointment(currentApp.data);
        let upcoming = {
          Current: (appointmentDetails && appointmentDetails.data) ? appointmentDetails.data : "undefined",
          Upcoming: (upcomingAppDetails && upcomingAppDetails.data) ? upcomingAppDetails.data.Upcoming : "undefined",
        };
        setupcoming(upcoming);
      }
    }

    const handleForPatient = async (
      first_name: string,
      last_name: string,
      userId: number,
      roomId: number,
      isProvider: boolean,
      roomName: string,
      appointmentId: number,
      relationShip: string,
      is_admitted: boolean
    ) => {
      const data: WaitingQueue = {
        first_name,
        last_name,
        user_id: Number(userId),
        room_id: Number(roomId),
        is_patient: isProvider,
        appointment_id: Number(appointmentId),
        relationShip,
        is_admitted: false
      };

      try {
        const res = await createWaitingQueue(data);
        if (res) {
          // setCurrentUser(res.data);
          socket.emit("get_waiting_list_user", { room_id: roomId });
          history.push(`/prejoinscreen/${first_name} ${last_name}/${roomName}`);
        }
      } catch (err) {
        console.log(err);
        history.push(`/`);
      }
    };

    const checkAppointmentTime = (appointmentData: any) => {
      // let appointmentDate = moment.utc(appointmentData.date).local().format('YYYY-MM-DD');
      let appointmentDate = moment.utc(`${appointmentData.date} ${appointmentData.start}`).local().format('YYYY-MM-DD');
      let now = moment().utc().local().format('YYYY-MM-DD');
      let startTime = moment.utc(`${appointmentData.date} ${appointmentData.start}`).local().subtract(30, 'minutes').format('hh:mm A');
      let currentTime = moment().utc().local().format("hh:mm A");
      let endTime = moment.utc(`${appointmentData.date} ${appointmentData.end}`).local().add(30, 'minutes').format('hh:mm A');
      const beginningTime = moment(startTime, 'h:mm a')
      const liveTime = moment(currentTime, 'h:mm a')
      const endingTime = moment(endTime, 'h:mm a')
      if (appointmentDate > now) {
        return 1;
      }
      else if (appointmentDate < now) {
        return -1;
      }
      else if (liveTime.isBefore(beginningTime)) {
        return 1;
      }
      else if (liveTime.isAfter(endingTime)) {
        return -1;
      } else {
        return 0
      }
    }

    const setCurrentUserData = (first_name: string, last_name: string, room_id: number, room_name: string, paitent_id: number, participant_id: number, provider_id: number, is_provider: boolean) => {
      let currentUser = {
        first_name: first_name,
        last_name: last_name,
        room_id: room_id,
        room_name: room_name,
        paitent_id: paitent_id,
        participant_id: participant_id,
        provider_id: provider_id,
        is_provider: is_provider,
      }
      setCurrentUser(currentUser);
    }

    const decryptedData = await decrypt(iv, data);
    if (decryptedData) {
      const arraydecrypt = decryptedData.split("&");

      const decrypt = arraydecrypt.reduce((acc, key) => {
        const detail = key.split("=");
        //@ts-ignore
        acc[detail[0]] = detail[1];
        return acc;
      }, {});

      //@ts-ignore
      let { clinic, provider, isProvider, speciality, patient, appointment, roomName, participant, roomId
      } = decrypt;

      let isLoggedIn = getIsLoggedIn()
      if (isProvider === "true" && !isLoggedIn) {
        let currentUrl = window.location.origin.toString();
        let url = `${currentUrl}/login`;
        window.open(url, '_self');
        return
      }
      localStorage.setItem('roomName', roomName)

      let first_name;
      let last_name;
      isProvider = Boolean(JSON.parse(isProvider));
      patient = Number(patient);
      clinic = Number(clinic);
      provider = Number(provider);
      speciality = Number(speciality);
      roomId = Number(roomId.split("-")[1]);
      appointment = Number(appointment);
      participant = participant ? Number(participant) : null;
      if (appointment) {
        let appointmentDetails: any = await getAppointmentDetails(appointment);
        if (appointmentDetails) {
          let checkTime = checkAppointmentTime(appointmentDetails.data);
          if (checkTime === 1) {
            let appointmentDate = moment.utc(`${appointmentDetails.data.date} ${appointmentDetails.data.start}`).local().format('MM/DD/YYYY');
            let startTime = moment.utc(`${appointmentDetails.data.date} ${appointmentDetails.data.start}`).local().format('hh:mm A');
            setModalMessage(`Your appointment time is on ${appointmentDate}-${startTime}`);
            setIsModalOpen(true);
            return;
          } else if (checkTime === -1) {
            setModalMessage(`Your consultation ended, kindly message the doctor if you have questions`);
            setIsModalOpen(true);
            return;
          } else {
            let provideDetails: any = await getProvidersDetails(appointmentDetails.data.provider_id)
            appointmentDetails.data.first_name = provideDetails.data.first_name;
            appointmentDetails.data.last_name = provideDetails.data.last_name;
            setAppointmentInfo(appointmentDetails.data);

          }
        }

      }
      if (isProvider) {
        let providerDetails: any = await getProvidersDetails(provider);
        // let upcomingAppDetails: any = await getCurrentupcoming(provider);
        // if (upcomingAppDetails && upcomingAppDetails.status == 200) {
        //   setupcoming(upcomingAppDetails.data);
        // }
        if (providerDetails && providerDetails.status == 200) {
          first_name = providerDetails.data.first_name;
          last_name = providerDetails.data.last_name;
          handleForProvider(first_name, last_name, roomName, roomId, provider);
          setCurrentUserData(
            first_name,
            last_name,
            roomId,
            roomName,
            patient,
            participant,
            provider,
            isProvider,
          );
          setAccessAdmit(isProvider);
        }
      } else {
        if (participant) {
          let participantDetails: any = await getParticipantDetails(participant);
          if (participantDetails && participantDetails.status == 200) {
            first_name = participantDetails.data.first_name;
            last_name = participantDetails.data.last_name;
            handleForPatient(
              first_name,
              last_name,
              participant,
              roomId,
              false,
              roomName,
              appointment,
              participantDetails.data.relationship,
              false
            );
            setCurrentUserData(
              first_name,
              last_name,
              roomId,
              roomName,
              patient,
              participant,
              provider,
              isProvider,
            );
          }
        } else {
          let patientDetails: any = await getPatientDetails(patient);
          if (patientDetails && patientDetails.status == 200) {
            first_name = patientDetails.data.first_name;
            last_name = patientDetails.data.last_name;
            handleForPatient(
              first_name,
              last_name,
              patient,
              roomId,
              true,
              roomName,
              appointment,
              "Patient",
              false
            );
            setCurrentUserData(
              first_name,
              last_name,
              roomId,
              roomName,
              patient,
              participant,
              provider,
              isProvider,
            );
          }
        }
      }
    } else {
      history.push(`/`);
    }
  };

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${process.env.REACT_APP_SONORA_SOCKET_URL}?data=${encodeURIComponent(data)}`);

  useEffect(async () => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data)
      const action = message?.action
      switch (action) {
        case 'admit':
          await videoConnect(message.data.token);
          await chatConnect(message.data.token);
          history.push(`/room/${roomName}`,{token : encodeURIComponent(data)})
          
          break
        default:
          break
      }
    }
  }, [lastMessage])
  // const handleClickSendMessage = useCallback(() => sendMessage('new message'), []);
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  console.log('connectionStatus =>', connectionStatus)

  const converDataForDisplayDisplay=  (infoRoom: any) =>{
    const name = `${infoRoom.first_name} ${infoRoom.last_name}`
    const startTime = moment(infoRoom.start, "x")
    const endTime = moment(infoRoom.end, "x")
    const duaration = moment.duration(endTime.diff(startTime)).asMinutes().toFixed();
    setRoomName(infoRoom.room_id)
    setUserInfor(pre => {
      return {
        ...pre,
        name,
        startTime: startTime.format("HH:mm"),
        endTime: endTime.format("HH:mm"),
        duaration
      }
    })

  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Box className={classes.logo}>
        <Tms2uLogo ></Tms2uLogo>
      </Box>
       
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <img
          className={classes.avartar}
          src={!!userInfor.avartar?userInfor.avartar: Avatar}
          alt="Avatar"
        />
        <Typography className={classes.name}>
        {userInfor.name}
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          className={classes.user_info}
          
        >

          <Box
            className={clsx(classes.info_column, classes.border)}
          >
            <Typography className={classes.info_table_header}>
              Gender
            </Typography>
            <Typography className={classes.info_table_content}>
              Female
            </Typography>
          </Box>
          <Box
            className={clsx(classes.info_column, classes.border)}
          >
            <Typography className={classes.info_table_header}>
              Age
            </Typography>
            <Typography className={classes.info_table_content}>
              45 years old
            </Typography>
          </Box>
          <Box
            className={clsx(classes.info_column, classes.border)}
          >
            <Typography className={classes.info_table_header}>
              Call duration
            </Typography>
            <Typography className={classes.info_table_content}>
            {`${userInfor.duaration} Minutes`}
            </Typography>
          </Box>
          <Box className={classes.info_column}>
            <Typography className={classes.info_table_header}>
              Start time - End time
            </Typography>
            <Typography className={classes.info_table_content}>
              {`${userInfor.startTime} - ${userInfor.endTime}`}
            </Typography>
          </Box>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          className={classes.waiting_time}
          >
          <IconClock />
          <Typography style={{margin: '0 8px', color: '#303E4E'}}>{
             `Waiting for your physician to start this
             meeting -`
            }
           
          </Typography>
            { !!userInfor.startTime && (
              <Countdown className={classes.time} date={parseInt(userInfor.startTime)} />
            )
            }
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          className={classes.btn_group}
          >
           <ToggleAudioButton
              className={classes.buttons}
              disabled={isReconnecting}
              hideLabel
          />
          <ToggleVideoButton
              className={classes.buttons}
              disabled={isReconnecting}
              hideLabel
          />
        </Grid>
      <ServicesSlider />
      </Grid>
      <AlertModal/>
      {loading && <LoadingPage />}
    </Grid>
  )
}

export default JoinMainRoom;