import {
    Box,
    Grid,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
import React, { useEffect } from 'react'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'
import CopyBar from 'components/shared/Elements/CopyBar'

const useStyles = makeStyles({
    subLabel: {
        fontSize: 14,
    },
    sectionHeading: {
        marginRight: 16,
        fontSize: 14,
    },
    textFieldWrap: {
        height: 40,
        width: 300,
    },
})

const mapOptions = (data = []) => {
    return data.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const Adherence = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    nonEditable,
    copyAble,
    copyValues,
    lastNarrative,
}) => {
    const classes = useStyles()

    const options = mapOptions(
        master?.substanceConsequenceMasters,
    )

    const handleCheckChange = (e) => {
        const { name, checked } = e.target
        onChange(name, checked)
    }
    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }
    const handleSelectMacro = (value) => {
        if(nonEditable){return}
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 20,
            }}>

            {copyAble ? (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            ) : (
                <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            )}
            
            <CheckboxOption
                name={`${fieldName}.no_concern`}
                option={{
                    label: 'no concerns',
                    value: false,
                }}
                disabled={nonEditable}
                onChange={handleCheckChange}
                checked={values?.no_concern}
            />
            <Typography className={classes.subLabel}>
                During the last 30 days, on how many days
                did you actually take all your medications?
            </Typography>
           
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                <TextField
                    variant="outlined"
                    name={`${fieldName}.past_30_medic`}
                    className={classes.textFieldWrap}
                    size="small"
                    value={values?.past_30_medic || ''}
                    disabled={nonEditable}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                    }}
                />
            </Box>
            <Grid item>
                <NarrativeAndMacro
                    hideClearBtn={true}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    macros={macros}
                    meta={{
                        values,
                        master: master?.substanceConsequenceMasters,
                    }}
                    onChange={onChange}
                    narrativeValue={values.narrative}
                    values={values}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box>
    )
}

export default Adherence
