import {
    Grid,
    makeStyles,
    Select,
    TextField,
    MenuItem,
    Button,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addParticipant,
    setIsSuccessAddParticipant,
    setErrorAddParticipant,
} from '../../store/actions/provider'
import LoadingPage from '../../utilities/loading-page'
import ErrorModal from '../ErrorModal'
import { emailRegex } from '../../utilities/regex'
import _ from 'lodash'
import './styles.scss'
import TeleIconCloseCircle from '../../assests/icons/TeleIconCloseCircle'
import LoadingButton from '@mui/lab/LoadingButton'
import SpinnerIcon from '../../assests/icons/SpinnerIcon'
import SuccessModal from '../SuccessModal'

const useStyles = makeStyles({
    addParticipantContainer: {
        width: 0,
        height: '100%',
        transition: 'width 0.5s',
        borderRadius: '0px 16px 16px 0px',
        textAlign: 'left',
        fontWeight: '500',
        boxShadow: '0px 16px 24px rgba(136, 145, 156, 0.2)',
        // padding: '20px 0',
        overflowY: 'scroll',
        position: 'relative',
        background: 'white'
    },
    open: {
        width: '460px',
        maxWidth: '460px',
    },
    addParticipantWrapper: {
        margin: '20px 20px auto',
    },
    sectionHeader: {
        marginBottom: '20px',
        fontSize: '16px',
    },
    inputInfo: {
        marginTop: '8px',
        // height: '40px',
        width: '100%',
        background: '#FBFCFC',
        borderRadius: '5px',
    },
    firstSectionItem: {
        display: 'flex',
    },
    sectionItem: {
        marginBottom: '20px',
        marginTop: '16px',
    },
    sectionLeft: {
        width: '198px',
        marginRight: '16px',
        fontSize: '16px',
    },
    label_input: {
        color: '#303E4E',
        fontWeight: '500',
    },
    sectionRight: {
        width: '198px',
        alignContent: 'flex-end',
    },
    btnAdd: {
        width: '100%',
        backgroundColor: '#5571C6 !important',
        marginBottom: '20px !important',
        textTransform: 'none !important',
        borderRadius: '8px !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
    },
    chatWindowContainerTopToggle: {
        padding: 0,
        border: 'none',
        cursor: 'pointer',
        backgroundColor: 'rgba(48, 62, 78, 0.8)',
        borderRadius: 10,
        width: 30,
        minWidth: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'rgba(48, 62, 78, 0.8)',
        },
    },
    small: {
        display: 'block',
        marginTop: '8px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '140%',
        color: '#303e4e',
    },
})

const AddParticipant = ({ onClose, isDisplayAddParticipant }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [participantInfo, setParticipantInfo] = useState({
        relation_ship: 'ADM',
    })
    const [errorParticipantInfo, setErrorParticipantInfo] = useState({})
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false)
    const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)

    //------------------------------------------------------------
    const isAdding = useSelector((state) => state.loader.addingParticipant)
    const errorAddParticipant = useSelector((state) => state.provider.errorAddParticipant)
    const isSuccessAddParticipant = useSelector((state) => state.provider.isSuccessAddParticipant)

    const relationShipList = [
        { code: 'ADM', name: 'Admitter' },
        { code: 'ATND', name: 'Attender' },
        { code: 'CALLBCK', name: 'Callback contact' },
        { code: 'CON', name: 'Consultant' },
        { code: 'DIS', name: 'Discharger' },
        { code: 'ESC', name: 'Escort' },
        { code: 'REF', name: 'Referrer' },
        { code: 'SPRF', name: 'Secondary performer' },
        { code: 'PPRF', name: 'Primary performer' },
        { code: 'PART', name: 'Participation' },
        { code: 'TRAS', name: 'Translator' },
        { code: 'EMER', name: 'Emergency' },
    ]

    const encounterMeetingId = useSelector((state) => state.provider.encounterMeetingId)

    useEffect(() => {
        setErrorParticipantInfo({})
        setParticipantInfo({
            first_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            relation_ship: 'ADM',
        })
    }, [isDisplayAddParticipant])

    const handleTextChange = (e) => {
        const { name, value } = e.target

        // if (name === 'phone_number') {
        //     const phoneNumber =
        //         value.replace(/\D/g, '') || ''
        //     setParticipantInfo((prev) => ({
        //         ...prev,
        //         [name]: phoneNumber,
        //     }))
        // } else {
        setErrorParticipantInfo({
            ...errorParticipantInfo,
            [name]: '',
        })
        setParticipantInfo((prev) => ({
            ...prev,
            [name]: value,
        }))
        // }
    }

    const handleAddParticipant = () => {
        const tempError = {}
        if (!participantInfo.first_name) {
            tempError.first_name = 'Please enter first name'
        }
        if (!participantInfo.last_name) {
            tempError.last_name = 'Please enter last name'
        }
        if (!participantInfo.email) {
            tempError.email = 'Please enter email'
        } else {
            if (!emailRegex.test(participantInfo.email)) {
                tempError.email = 'Please enter correct format email'
            }
        }
        if (!participantInfo.phone_number) {
            tempError.phone_number = 'Please enter phone number'
        }
        setErrorParticipantInfo(tempError)
        if (_.isEmpty(tempError)) {
            dispatch(
                addParticipant({
                    ...participantInfo,
                    encounter_id: encounterMeetingId,
                }),
            )
        }
    }

    useEffect(() => {
        if (isSuccessAddParticipant) {
            setIsOpenSuccessModal(true)
            setParticipantInfo({
                first_name: '',
                last_name: '',
                phone_number: '',
                email: '',
                relation_ship: 'ADM',
            })
            dispatch(setIsSuccessAddParticipant(false))
        }
        return () => {
            setIsOpenErrorModal(false)
        }
    }, [isSuccessAddParticipant])

    useEffect(() => {
        if (errorAddParticipant) {
            setIsOpenErrorModal(true)
            setErrorAddParticipant('')
        }
        return () => {
            setIsOpenErrorModal(false)
        }
    }, [errorAddParticipant])

    const closeSuccessModal = () => {
        setIsOpenSuccessModal(false)
    }

    return (
        <>
            <Grid
                className={clsx({
                    [classes.addParticipantContainer]: true,
                    [classes.open]: isDisplayAddParticipant,
                })}>
                <Grid className={classes.addParticipantWrapper}>
                    <Grid
                        className={classes.sectionHeader}
                        container
                        alignItems="center"
                        justifyContent="space-between">
                        <Typography
                            style={{
                                fontWeight: 500,
                                color: '#303E4E',
                                fontSize: 16,
                            }}>
                            Add Participant
                        </Typography>
                        <Button onClick={onClose} className={classes.chatWindowContainerTopToggle}>
                            <TeleIconCloseCircle />
                        </Button>
                    </Grid>

                    <Grid className={classes.firstSectionItem}>
                        <div className={classes.sectionLeft}>
                            <div>First name *</div>
                            <div>
                                <TextField
                                    error={!!errorParticipantInfo.first_name}
                                    helperText={errorParticipantInfo.first_name}
                                    className={classes.inputInfo}
                                    value={participantInfo.first_name}
                                    name="first_name"
                                    variant="outlined"
                                    placeholder="First name"
                                    onChange={(e) => {
                                        handleTextChange(e)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.sectionRight}>
                            <div>Last name *</div>
                            <div>
                                <TextField
                                    error={!!errorParticipantInfo.last_name}
                                    helperText={errorParticipantInfo.last_name}
                                    className={classes.inputInfo}
                                    value={participantInfo.last_name}
                                    name="last_name"
                                    variant="outlined"
                                    placeholder="Last name"
                                    onChange={(e) => {
                                        handleTextChange(e)
                                    }}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid className={classes.sectionItem}>
                        <div>Email</div>
                        <div>
                            <TextField
                                error={!!errorParticipantInfo.email}
                                helperText={errorParticipantInfo.email}
                                className={classes.inputInfo}
                                value={participantInfo.email}
                                name="email"
                                variant="outlined"
                                placeholder="Email"
                                onChange={(e) => {
                                    handleTextChange(e)
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid className={classes.sectionItem}>
                        <div>Phone number *</div>
                        <div>
                            <TextField
                                error={!!errorParticipantInfo.phone_number}
                                helperText={errorParticipantInfo.phone_number}
                                className={classes.inputInfo}
                                value={participantInfo.phone_number}
                                name="phone_number"
                                variant="outlined"
                                placeholder="Phone number"
                                onChange={(e) => {
                                    handleTextChange(e)
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid className={classes.sectionItem}>
                        <div>Relationship *</div>
                        <div className={classes.inputInfo}>
                            <Select
                                variant="outlined"
                                name="relation_ship"
                                value={participantInfo.relation_ship}
                                placeholder="Select"
                                onChange={(e) => handleTextChange(e)}
                                fullWidth>
                                {relationShipList.map((item) => (
                                    <MenuItem value={item.code} key={item.code}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Grid>
                    <Grid className={classes.sectionItem}>
                        <LoadingButton
                            onClick={handleAddParticipant}
                            loading={isAdding}
                            variant="contained"
                            loadingIndicator={<SpinnerIcon />}
                            className={classes.btnAdd}>
                            Add
                        </LoadingButton>
                    </Grid>
                </Grid>
                <SuccessModal
                    title="Add Participant Successfully!!!"
                    message="You have added participant successfully!!!"
                    isOpen={isOpenSuccessModal}
                    closeModal={closeSuccessModal}
                />
                {errorAddParticipant && (
                    <ErrorModal
                        isOpen={isOpenErrorModal}
                        setIsOpen={setIsOpenErrorModal}
                        title="Add Participant Failed"
                        message={errorAddParticipant?.data}
                    />
                )}
            </Grid>
        </>
    )
}

export default AddParticipant
