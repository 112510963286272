import React, { useEffect } from 'react'
import { Button, Grid, makeStyles, styled, Typography } from '@material-ui/core'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import './loader.scss'
import urlLoginVideoScreen from '../../assests/images/loginVideoScreen_bg.png'
import appLogo from '../../assests/Logos/tm2u_logo_white.png'
import { useSelector } from 'react-redux'
import { CameraTest } from 'components/Consultation/components/CameraTest/CameraTest'
import { useHistory } from 'react-router-dom'
import TroubleShootIcon from '../../assests/icons/Vector.svg'

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        width: '100%',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundColor: '#889BD7',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 100,
    },
    appLogo: {
        width: 150,
        height: 66,
    },
    button: {
        background: '#5571c6',
        color: 'white',
        fontSize: 15,
        fontWeight: 400,
    },
}))

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    width: 350,
    marginTop: 32,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: 'gray',
        borderRadius: 5,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'white',
    },
}))

export default function LoadingPage({ mediaError }) {
    const classes = useStyles()
    const history = useHistory()
    const [progress, setProgress] = React.useState(10)
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5))
        }, 500)
        return () => {
            clearInterval(timer)
        }
    }, [])
    const backgroundImage = useSelector(
        (state) => state?.login?.userData?.background_image?.file?.url,
    )

    return (
        <>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                className={classes.container}
                // style={{ backgroundImage: `url(${backgroundImage? backgroundImage : urlLoginVideoScreen})`,}}
            >
                <img className={classes.appLogo} src={appLogo} alt="TeleMed2U" />
                {mediaError ? (
                    <>
                        <>
                            <Typography
                                variant="body2"
                                style={{
                                    color: 'white',
                                    fontSize: '19px',
                                    marginTop: 80,
                                    marginBottom: 32,
                                }}>
                                Oops! Check your devices, please.
                            </Typography>

                            <Button
                                onClick={() => history.push('/video')}
                                variant="contained"
                                startIcon={<img src={TroubleShootIcon} alt=""></img>}
                                className={classes.button}>
                                Troubleshoot
                            </Button>
                        </>
                    </>
                ) : (
                    <>
                        <Typography
                            variant="body2"
                            style={{
                                color: 'white',
                                fontSize: '19px',
                                marginTop: 80,
                            }}>
                            Connecting to Telehealth...
                        </Typography>

                        <BorderLinearProgress variant="determinate" value={progress} />
                        <Typography
                            style={{
                                fontSize: 14,
                                color: 'white',
                                marginTop: 32,
                            }}>
                            {progress} %
                        </Typography>
                    </>
                )}
            </Grid>
        </>
    )
}
