import moment from 'moment-timezone'

export const generateAvailability = ({
    start: start_string,
    end: end_string,
    days: days_string,
    repeat_by,
    repeat_gap,
    timezone,
}) => {
    const result = []

    const start = moment.tz(start_string, timezone)
    const end = moment.tz(end_string, timezone)

    const days = days_string.map((day) => {
        switch (day) {
            case 'MON':
                return 1
            case 'TUE':
                return 2
            case 'WED':
                return 3
            case 'THU':
                return 4
            case 'FRI':
                return 5
            case 'SAT':
                return 6
            case 'SUN':
                return 0
            default:
        }
    })

    const tmp = start.clone()

    while (tmp.isSameOrBefore(end)) {
        const get = () => {
            return {
                start: tmp
                    .clone()
                    .set({
                        hour: start.get('hour'),
                        minute: start.get('minute'),
                    })
                    .toDate(),
                end: tmp
                    .clone()
                    .set({
                        hour: end.get('hour'),
                        minute: end.get('minute'),
                    })
                    .toDate(),
            }
        }

        days.forEach((day) => {
            if (day === tmp.get('day')) {
                result.push(get())
            }
        })

        switch (repeat_by) {
            case 'WEEK':
                if (repeat_gap > 1) {
                    if (tmp.get('day') === 0) {
                        tmp.add(repeat_gap - 1, 'week')
                    }
                }
                tmp.add(1, 'day')
                break
            case 'MONTH':
                if (repeat_gap > 1) {
                    if (tmp.date() === tmp.daysInMonth()) {
                        const next = tmp.clone().add(1, 'day')
                        let sum = 0
                        for (let i = 1; i < repeat_gap; i++) {
                            sum += next.daysInMonth()
                            next.add(next.daysInMonth(), 'day')
                        }
                        tmp.add(sum, 'day')
                    }
                }
                tmp.add(1, 'day')
                break
            case 'DAY':
                result.push(get())
                tmp.add(repeat_gap, 'day')
                break
            case 'WEEKDAY':
                tmp.add(1, 'day')
                break
            default:
        }
    }

    return result
}

// export const generateAvailabilityV2 = (item) => {
//     const result = []
//     const start = moment.tz(item?.start_date, item?.timezone)
//     const end = moment.tz(item?.end_date, item?.timezone)
//     const days = item?.days
//         ?.filter((item) => item.available)
//         ?.map((item) => {
//             switch (item.day) {
//                 case 'MON':
//                     return { num: 1, time: item?.options[0] }
//                 case 'TUE':
//                     return { num: 2, time: item?.options[0] }
//                 case 'WED':
//                     return { num: 3, time: item?.options[0] }
//                 case 'THU':
//                     return { num: 4, time: item?.options[0] }
//                 case 'FRI':
//                     return { num: 5, time: item?.options[0] }
//                 case 'SAT':
//                     return { num: 6, time: item?.options[0] }
//                 case 'SUN':
//                     return { num: 0, time: item?.options[0] }
//                 default:
//             }
//         })
//     const tmp = start.clone()
//     while (tmp.isSameOrBefore(end)) {
//         console.log('days', days)
//         const getEx = item?.exception?.find(
//             (ex) =>
//                 moment(tmp).format('DD/MM/YYYY') ===
//                 moment(moment.tz(ex?.start_date, item?.timezone).clone()).format('DD/MM/YYYY'),
//         )
//         const get = (time) => {
//             return {
//                 start:
//                     getEx &&
//                     moment(tmp).format('DD/MM/YYYY') ===
//                         moment(moment.tz(getEx?.start_date, item?.timezone).clone()).format(
//                             'DD/MM/YYYY',
//                         )
//                         ? tmp
//                               .clone()
//                               .set({
//                                   hour: moment(getEx?.start_time, 'hh:mm:ss').hours(),
//                                   minute: moment(getEx?.start_time, 'hh:mm:ss').minutes(),
//                               })
//                               .toDate()
//                         : tmp
//                               .clone()
//                               .set({
//                                   hour: moment(time?.start_time, 'hh:mm:ss').hours(),
//                                   minute: moment(time?.start_time, 'hh:mm:ss').minutes(),
//                               })
//                               .toDate(),
//                 end:
//                     getEx &&
//                     moment(tmp).format('DD/MM/YYYY') ===
//                         moment(moment.tz(getEx?.start_date, item?.timezone).clone()).format(
//                             'DD/MM/YYYY',
//                         )
//                         ? tmp
//                               .clone()
//                               .set({
//                                   hour: moment(getEx?.end_time, 'hh:mm:ss').hours(),
//                                   minute: moment(getEx?.end_time, 'hh:mm:ss').minutes(),
//                               })
//                               .toDate()
//                         : tmp
//                               .clone()
//                               .set({
//                                   hour: moment(time?.end_time, 'hh:mm:ss').hours(),
//                                   minute: moment(time?.end_time, 'hh:mm:ss').minutes(),
//                               })
//                               .toDate(),
//             }
//         }
//         days.forEach((day) => {
//             if (day.num === tmp.get('day')) {
//                 result.push(get(day.time))
//             }
//         })
//         tmp.add(1, 'day')
//     }
//     console.log(
//         'result',
//         result,
//         // item,
//         // start,
//         // end,
//         // tmp,

//         // moment(moment.tz(item?.start_date, item?.timezone)).format('DD/MM/YYYY hh:mm a'),
//         // moment(end).format('DD/MM/YYYY hh:mm a'),
//         start,
//         end,
//         start.clone(),
//         tmp,
//         tmp.isSameOrBefore(end),

//         item?.timezone,
//     )
//     return result
// }
export const generateAvailabilityV2 = (item) => {
    const result = []
    const start = moment.tz(item?.start_date, item?.timezone)
    const end = moment.tz(item?.end_date, item?.timezone)

    const days = item?.days
        ?.filter((item) => item.available)
        ?.map((item) => ({
            num: moment().isoWeekday(item.day).isoWeekday(),
            time: item?.options[0],
        }))

    const exceptions = item?.exception || []

    let tmp = start.clone().startOf('day')
    end.endOf('day')

    while (tmp.isSameOrBefore(end)) {
        const getEx = exceptions.find((ex) =>
            moment(tmp).isSame(moment.tz(ex?.start_date, item?.timezone), 'day'),
        )

        const deletedEx = exceptions
        .find((ex) =>
            moment(tmp).isSame(moment.tz(ex?.start_date, item?.timezone), 'day') && ex.exception_type === 'DELETED',
        )


        const get = (time) => ({
            start: getEx
                ? tmp
                      .clone()
                      .set({
                          hour: moment(getEx?.start_time, 'HH:mm:ss').hours(),
                          minute: moment(getEx?.start_time, 'HH:mm:ss').minutes(),
                      })
                      .toDate()
                : tmp
                      .clone()
                      .set({
                          hour: moment(time?.start_time, 'HH:mm:ss').hours(),
                          minute: moment(time?.start_time, 'HH:mm:ss').minutes(),
                      })
                      .toDate(),
            end: getEx
                ? tmp
                      .clone()
                      .set({
                          hour: moment(getEx?.end_time, 'HH:mm:ss').hours(),
                          minute: moment(getEx?.end_time, 'HH:mm:ss').minutes(),
                      })
                      .toDate()
                : tmp
                      .clone()
                      .set({
                          hour: moment(time?.end_time, 'HH:mm:ss').hours(),
                          minute: moment(time?.end_time, 'HH:mm:ss').minutes(),
                      })
                      .toDate(),
        })

        days.forEach((day) => {
            if (day.num === tmp.isoWeekday() && !deletedEx) {
                result.push(get(day.time))
            }
        })

        tmp = tmp.add(1, 'day').startOf('day')
    }
    return result
}
