// import React from 'react';

export const SetCheckMark = (componentId, value, nonEditable = false) => {
    // console.log('componentId, value', componentId, value)
    if (nonEditable) { return }
    if (
        componentId === 'verbal_consent_telemedicine' ||
        componentId === 'telemedicine_attestation' ||
        componentId === 'chief_complaint' ||
        componentId === 'present_illness_history' ||
        componentId === 'review_of_measures' ||
        componentId === 'assessment_impression' ||
        componentId === 'homework' ||
        componentId === 'subjective_concerns' ||
        componentId === 'treatment_notes' ||
        componentId === 'time_spent'
    ) {
        if (value?.hasOwnProperty('narrative') && value?.narrative) {
            return true
        }
        return false

    } else if (componentId === 'present_in_sessions') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('sessions') && value?.sessions?.length > 0)
        ) {
            return true
        }
        return false

    } else if (componentId === 'stressors') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('stressor_opt') && value?.stressor_opt?.length > 0)
        ) {
            return true
        }
        return false

    } else if (componentId === 'system_reviews') {
        const systemReviewOptHaveValue = value?.system_reviews_opt?.some(
            (item) => item.reviews_options.length > 0 || item.other !== '',
        )
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || systemReviewOptHaveValue) {
            return true
        }
        return false

    } else if (componentId === 'subtance_abuse_history') {
        const recreationalDrug = value?.is_recreational_drug ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || recreationalDrug) {
            return true
        }
        return false

    } else if (componentId === 'subtance_abuse_treat_history') {
        const recreationalDrug = value?.received_treat ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || recreationalDrug) {
            return true
        }
        return false

    } else if (componentId === 'subtance_use_consequences') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            value?.consequences.length > 0
        ) {
            return true
        }
        return false

    } else if (componentId === 'inpatient_psychiatric_history') {
        const recreationalDrug = value?.have_inpatient_psyc_treat ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || recreationalDrug) {
            return true
        }
        return false

    } else if (componentId === 'outpatient_psychiatric_history') {
        const recreationalDrug = value?.have_outpatient_psyc_treat ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || recreationalDrug) {
            return true
        }
        return false

    } else if (componentId === 'suicide_history') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('intent_to_die') && value?.intent_to_die) ||
            (value?.hasOwnProperty('intent_to_die_desc') && value?.intent_to_die_desc) ||
            (value?.hasOwnProperty('most_recent_episode') && value?.most_recent_episode) ||
            (value?.hasOwnProperty('suicide_occurance') && value?.suicide_occurance) ||
            (value?.hasOwnProperty('tried_to_harm') && value?.tried_to_harm)
        ) {
            return true
        }
        return false

    } else if (componentId === 'violence_history') {
        const have_violent_behavior = value?.have_violent_behavior ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || have_violent_behavior) {
            return true
        }
        return false

    } else if (componentId === 'past_medical_history') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('has_medical_problem') && value?.has_medical_problem) ||
            (value?.hasOwnProperty('has_surgical_history') && value?.has_surgical_history) ||
            (value?.hasOwnProperty('taking_med_currently') && value?.taking_med_currently) ||
            (value?.hasOwnProperty('primary_care_physician') && value?.primary_care_physician)
        ) {
            return true
        }
        return false

    } else if (componentId === 'psychiatric_medication_history') {
        const med_psychiatric_treat = value?.med_psychiatric_treat ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || med_psychiatric_treat) {
            return true
        }
        return false

    } else if (componentId === 'allergy') {
        const have_any_allergy = value?.have_any_allergy ? true : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || have_any_allergy || value?.allergies.length > 0) {
            return true
        }
        return false

    } else if (componentId === 'family_history') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('psych_illness_history') && value?.psych_illness_history) ||
            (value?.hasOwnProperty('additional_med_desc') && value?.additional_med_desc)
        ) {
            return true
        }
        return false

    } else if (componentId === 'dev_and_edu_history') {
        const additional_schooling = value?.additional_schooling
            ? value?.additional_schooling?.options?.length || value?.additional_schooling?.other
            : false
        const childhood_atmos = value?.childhood_atmos
            ? value?.childhood_atmos?.options?.length || value?.childhood_atmos?.other
            : false
        const childhood_challenges = value?.childhood_challenges
            ? value?.childhood_challenges?.options?.length || value?.childhood_challenges?.other
            : false
        const comment_on_dev_and_edu = value?.comment_on_dev_and_edu
            ? value?.comment_on_dev_and_edu
            : false
        const complic_after_your_birth = value?.complic_after_your_birth === '' ? false
            : true 
        const difficulties_in_reaching = value?.difficulties_in_reaching
            ? value?.difficulties_in_reaching?.options?.length ||
            value?.difficulties_in_reaching?.other
            : false
        const during_preg_prob = value?.during_preg_prob
            ? value?.during_preg_prob?.options?.length || value?.during_preg_prob?.other
            : false
        const have_prob_in_school = value?.have_prob_in_school
            ? value?.have_prob_in_school.length
            : false
        const highest_edu_level = value?.highest_edu_level ? value?.highest_edu_level : false
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            additional_schooling ||
            childhood_atmos ||
            childhood_challenges ||
            comment_on_dev_and_edu ||
            complic_after_your_birth ||
            difficulties_in_reaching ||
            during_preg_prob ||
            have_prob_in_school ||
            highest_edu_level
        ) {
            return true
        }
        return false

    } else if (componentId === 'general_social_history') {
        const social_history_list = value?.social_history_list
            ? value?.social_history_list.length
            : false
        if ((value?.hasOwnProperty('narrative') && value?.narrative) || social_history_list) {
            return true
        }
        return false

    } else if (componentId === 'menstruation_and_pregnancy_history') {
        const menstruation_pregnancy_history_list = value?.menstruation_pregnancy_history_list
            ? value?.menstruation_pregnancy_history_list.length
            : false
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            menstruation_pregnancy_history_list
        ) {
            return true
        }
        return false

    } else if (componentId === 'vitals') {
        const filterValue = value?.current.filter((item) => item.label !== 'Smoking Status')
        const havingValue = filterValue.some((item) => item.value !== '')
        if (havingValue) {
            return true
        }
        return false

    } 
    else if (componentId === 'behavior_rate_scales') {
        if ((value?.length)) {
            return true
        }
        return false

    }else if (componentId === 'exam') {
        const havingValue = Object.values(value).some((arr) => arr.length > 0)
        if (havingValue) {
            return true
        }
        return false

    } else if (componentId === 'lab_results') {
        if ((value?.hasOwnProperty('lab_result_list') && value?.lab_result_list.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative)) {
            return true
        }
        return false

    } else if (componentId === 'source_of_information') {
        if (
            (value?.hasOwnProperty('informations') && value?.informations.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative)
        ) {
            return true
        }
        return false

        // } else if (componentId === 'source_of_information') {
        //     if (
        //         (value?.hasOwnProperty('informations') && value?.informations.length) ||
        //         (value?.hasOwnProperty('narrative') && value?.narrative)
        //     ) {
        //         return true
        //     } 
        //         return false

    } else if (componentId === 'assessment_sources_of_risk') {
        if (
            (value?.hasOwnProperty('source_risk_opt') && value?.source_risk_opt.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative)
        ) {
            return true
        }
        return false

    } else if (componentId === 'suicide_risk_factors') {
        if (
            (value?.hasOwnProperty('suicide_risk_factors') && value?.suicide_risk_factors.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('is_patient_screened') && value?.is_patient_screened?.check)
        ) {
            return true
        }
        return false

    } else if (componentId === 'suicide_protective_factors') {
        if (
            (value?.hasOwnProperty('protective_factors') && value?.protective_factors.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative)
        ) {
            return true
        }
        return false

        // } else if (componentId === 'suicide_protective_factors') {
        //     if (
        //         (value?.hasOwnProperty('protective_factors') && value?.protective_factors.length) ||
        //         (value?.hasOwnProperty('narrative') && value?.narrative)
        //     ) {
        //         return true
        //     } 
        //         return false

    } else if (componentId === 'suicide_preventive_plan') {
        const havingValue = Object.values(value).some((arr) => arr.length > 0)
        if (havingValue) {
            return true
        }
        return false

    } else if (componentId === 'icd_code') {
        if (value?.length > 0) {
            return true
        }
        return false

    } else if (componentId === 'assessment_informed_consent') {
        const havingValue = Object.values(value).some((arr) => arr.length > 0)
        if (havingValue) {
            return true
        }
        return false

    } else if (componentId === 'counselling_and_coordination_care') {
        if (
            (value?.hasOwnProperty('academic_assesment') && value?.academic_assesment.length) ||
            (value?.hasOwnProperty('coordination_care') && value?.coordination_care.length) ||
            (value?.hasOwnProperty('counselling') && value?.counselling.length > 1) ||
            (value?.hasOwnProperty('diagnosis_and_prog') && value?.diagnosis_and_prog.length) ||
            (value?.hasOwnProperty('medication_management') &&
                value?.medication_management.length) ||
            (value?.hasOwnProperty('patient_self_mgmt') && value?.patient_self_mgmt.length) ||
            (value?.hasOwnProperty('recent_stressors') && value?.recent_stressors.length) ||
            (value?.hasOwnProperty('referred_patient') && value?.referred_patient.length) ||
            (value?.hasOwnProperty('narrative') && value?.narrative)
        ) {
            return true
        }
        return false

    } else if (componentId === 'psychotherapy') {
        const havingValue = value?.psychotherapy_opt?.some((arr) => arr?.hasOwnProperty('label'))
        if (havingValue || (value?.hasOwnProperty('narrative') && value?.narrative)) {
            return true
        }
        return false

    } else if (componentId === 'plan') {
        const havingValue = value?.plan_opt?.some((arr) => arr?.hasOwnProperty('label'))
        if (havingValue || (value?.hasOwnProperty('narrative') && value?.narrative)) {
            return true
        }
        return false

    } else if (componentId === 'plan_med_changes') {
        const havingValue = value?.plan_med_opt?.some((arr) => arr?.hasOwnProperty('label'))
        if (
            havingValue ||
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('medication_list') && value?.medication_list.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'plan_lab_ordered') {
        if ((value?.hasOwnProperty('lab_ordered_list') && value?.lab_ordered_list.length)||
            (value?.hasOwnProperty('narrative') && value?.narrative)) {
            return true
        }
        return false

    } else if (componentId === 'plan_next_appointment') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            value?.hasOwnProperty('next_appt') ||
            (value?.hasOwnProperty('plan_next_opt') && value?.plan_next_opt.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'past_history') {
        const havingValue = Object.values(value).some((arr) => Boolean(arr))
        if (havingValue) {
            return true
        }
        return false

    } else if (componentId === 'side_effects') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('no_known') && value?.no_known) ||
            (value?.hasOwnProperty('side_effect_opt') && value?.side_effect_opt.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'adherence') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('no_concern') && value?.no_concern) ||
            (value?.hasOwnProperty('past_30_medic') && value?.past_30_medic)
        ) {
            return true
        }
        return false

    } else if (componentId === 'treatment_approach') {
        const havingValue = Object.values(value).some((arr) => Boolean(arr))
        if (havingValue) {
            return true
        }
        return false

    } else if (componentId === 'session_focus') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('other') && value?.other) ||
            (value?.hasOwnProperty('session') && value?.session?.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'interven_utilized') {
        const haveValue =
            value?.interventions.length > 0
                ? value?.interventions.some(
                    (arr) => arr?.additional || arr?.description || arr?.main,
                )
                : false
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            haveValue ||
            (value?.hasOwnProperty('other') &&
                (value?.other?.additional ||
                    value?.other?.description ||
                    value?.other?.main ||
                    value?.other?.value))
        ) {
            return true
        }
        return false

    } else if (componentId === 'family_env_strategy') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('fam_env_strategy') && value?.fam_env_strategy?.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'suicide_ideation') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('answer') && value?.answer)
        ) {
            return true
        }
        return false

    } else if (componentId === 'clinical_global_impression') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('client_condition') && value?.client_condition)
        ) {
            return true
        }
        return false

    } else if (componentId === 'therapy_plan') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('therapy') && value?.therapy.length)
        ) {
            return true
        }
        return false

    } else if (componentId === 'last_session_hmwrk') {
        if (
            (value?.hasOwnProperty('narrative') && value?.narrative) ||
            (value?.hasOwnProperty('add_comments') && value?.add_comments) ||
            (value?.hasOwnProperty('amt_hmwrk_completed') && value?.amt_hmwrk_completed) ||
            (value?.hasOwnProperty('hmwrk_not_assigned') && value?.hmwrk_not_assigned)
        ) {
            return true
        }
        return false

    } else if (componentId === 'psychotherapy_note') {
        if (
            (value?.hasOwnProperty('description') && value?.description)
        ) {
            return true
        }
        return false

    } else if (componentId === 'medical_coding') {
        return true
    }
}


