export default {
    stressors: `Given the list of categories below, how much stress is each currently causing you?`,
    have_any_allergy_to_medication: `Do have any known allergies (drug, food etc)?`,
    have_any_allergy: `Do have any known allergies to medication?`,
    have_any_allergy_sub: `If YES, please fill out your allergy information below:`,
    reviewOfSystem: `Please look at the list of physical symptoms below and check off any that you have experienced in the last several days. If you have NOT experienced any symptoms in an area, be sure to check "None of the above" for that area. If you are filling this out on behalf of the patient, please answer from the patient's perspective.`,
    tried_to_harm: `Have you ever tried to harm or kill yourself?`,
    intent_to_die: `If you answered "no", skip the rest of this page and click "Next Section" at the bottom.`,
    intent_to_die_sub: `Was your intent to die?`,
    intent_to_die_desc: `Elaborate below, if desired:`,
    suicide_occurance: `How many times in your life has this occurred?`,
    have_violent_behavior: `Have you had any history of violent behavior?`,
    violent_behavior_desc: `Elaborate below, if desired:`,
    have_inpatient_psyc_treat: `Do you have a history of inpatient psychiatric treatment?`,
    have_outpatient_psyc_treat: `Do you have a history of outpatient psychiatric treatment?`,
    have_outpatient_psyc_treat_sub: `Please list any past outpatient treatment history below. Start with most recent and list each episode of treatment as a separate line.`,
    have_inpatient_psyc_treat_sub: `Please list any past inpatient treatment history below. Start with most recent and list each episode of treatment as a separate line.`,
    is_recreational_drug: `Do you have a history of any recreational drug use?`,
    subtance_used: `If YES, please fill out the table below to the best of your knowledge:`,
    received_treat: `Did you receive any treatment for substance abuse?`,
    subtance_treat: `If YES, please fill out the table below to the best of your knowledge:`,
    consequences: `Have you experienced any of these consequences as a result of alcohol consumption or abuse of substances?`,
    consequences_sub: `(Please check all that apply)`,
    is_patient_screened: `The patient was screened for the following risk factors: prior attempt; current attempt; history of medically serious attempt; recent psychiatric hospital discharge; recent loss (particularly interpersonal or fall in social status); currently diagnosed with Major Depression; currently diminished concentration or indecision (Cognitive Impairment); current sleep problems; currently experiencing hopelessness; currently experiencing panic or significant anxiety; psychotic symptoms or underlying thought disorder or loss of rational thought (i.e., dementia); currently diagnosed with Borderline Personality Disorder; current ETOH or drug use; history of impulsivity; intense level of agitation; actively making death arrangements (updated will, suicide note, recently purchased life insurance, giving away possessions, etc.); lethal methods available or easily obtained; likely to be alone, currently socially isolated; family member committed suicide; history of childhood sexual abuse; unemployed; financial strain; and physical illness.`,
    suicide_risk_factors: `The following risk factors for suicide/self-harm exist for this patient`,
    protective_factors: `The following protective factors from suicide/self-harm exist for this patient`,
    treat_consent_given_by: `Informed consent for the treatment described in this note was given by`,
    confidentiality_disc: `This included (check all that apply)`,
    med_couns: `Medication Management Counseling`,
    prognosis_couns: `Diagnosis & Prognosis Counseling`,
    'self-manage_couns': `Patient Self-Management Counseling`,
    stressors_couns: `Recent Stressors Counseling`,
    coordination_care: `Coordination of Care`,
    caregiver: `Referred patient/caregiver to`,
    academic_asses: `Additional academic assessment and/or supports`,
    primary_care_physician: `Who is your primary care physician?`,
    taking_med_currently: `Are you taking any medications currently? (Excluding medications for psychiatric treatment)`,
    has_medical_problem: `Have you a history of any of the following health problems?`,
    has_surgical_history: `Have you a history of surgery in any of the following areas?`,
    medication_details: `If YES, please include these medications below:`,
    health_problems: `Have you a history of any of the following health problems?`,
    surgery_area: `Have you a history of surgery in any of the following areas?`,
    med_psychiatric_treat: `Have you ever taken any medication for psychiatric treatment?`,
    during_preg_prob: `During your pregnancy/birth, did your mother have any problems with any of the following:`,
    complic_after_your_birth: `Did you have any complications after your birth? (e.g. premature birth, jaundice, breathing difficulties)`,
    difficulties_in_reaching: `Did you have any delays or difficulties in reaching the following developmental milestones?`,
    childhood_atmos: `Which options below best describe your childhood home atmosphere?`,
    childhood_challenges: `Which of the following challenges were experienced during your childhood?`,
    have_prob_in_school: `Which of the following best describe problems you may have had in school? `,
    additional_schooling: `Did you have additional schooling outside of the standard classroom setting? (please check all that apply)`,
    highest_edu_level: `Please select your highest level of education:`,
    comment_on_dev_and_edu: `If you have any further comments about your developmental or educational history and wish to elaborate further, please do so in the space provided below:`,
    psych_illness_history: `Do you have any family members with a history of psychiatric illness?`,
    psych_illness_history_sub: `If YES, please elaborate below:`,
    additional_med_desc: `Is there any additional family medical history?`,
    no_mens_note: `If you have not begun menstruation or this is not applicable (e.g. biologically male), please skip this section.`,
    begin_menstruation_age: `At what age did you begin menstruation?`,
    premenstrual_symptoms: `Which of these best describe your premenstrual symptoms?`,
    contraception: `Do you have a method of contraception? (check all that apply)`,
    ever_been_pregnent: `Have you ever been pregnant?`,
    how_many_times_pregnent: `If YES, how many times?`,
    ever_given_birth: `Have you ever given birth?`,
    no_of_given_birth: `If YES, how many times?`,
    had_any_miscarriage: `Have you had any miscarriages?`,
    no_of_miscarriage: `If YES, how many times?`,
    had_any_abortion: `Have you had any abortions?`,
    no_of_abortion: `If YES, how many times?`,
    currently_live_with: `Who do you currently live with? (Please check all that apply)`,
    social_situation: `Which options below best describes your social situation?`,
    current_matrial_status: `What is your current marital status?`,
    intimate_relationship_status: `What is the status of your intimate relationship?`,
    intimate_satisfaction_level: `What is the satisfaction level of your intimate relationship?`,
    sexual_orientation: `What is your sexual orientation?`,
    current_living_situation: `What is your current living situation?`,
    participate_in_spritual_act: `Do you currently participate in spiritual activities?`,
    current_occupation_status: `What is your current occupation status?`,
    current_yearly_income: `What is your current yearly income?`,
    longest_period_employment: `What is your longest period of continuous employment? (Please include dates and description)`,
    employment_start: `Employment Start:`,
    employment_end: `Employment End:`,
    longest_period_unemployment: `What is your longest period of continuous unemployment? (Please include dates and description)`,
    unemployment_start: `Unemployment Start:`,
    unemployment_end: `Unemployment End:`,
    desc: `Desscription:`,
}
