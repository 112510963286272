import React from 'react'
import { Grid } from '@material-ui/core'
import { Box } from '@mui/material'

import { Allergies } from './Allergies'
import { Appointments } from './Appointments'
import { Immunizations } from './Immunizations'
import { Medications } from './Medications'
import { usePatientDashboardStyles } from '../style'
import { MedicalProblems } from './MedicalProblems'
import { Patient } from '../interfaces'
import { Labs } from './Labs/index'
import { ClinicalNotes } from './ClinicalNotes'
import { VitalSigns } from './VitalSigns'
import BehaviorRateScale from './BehaviorRateScale'


interface Props {
    patientInfo: Patient
}

export const HealthRecords = (props: Props) => {
    const classes = usePatientDashboardStyles()
    return (
        <Grid
            item
            xs={12}
            container
            spacing={1}
            // wrap="nowrap"
            className={classes.container_tab}
            style={{
                // marginBottom: 4,
                // height: '50%',
                // height: 'calc(100% - 10px)',
                // marginTop: 10,
            }}>
            {/* Appointments */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <Appointments />
                </Box>
            </Grid>

            {/* Medical Problems */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <MedicalProblems
                        patientId={props.patientInfo.id}
                        patientInfo={props.patientInfo}
                    />
                </Box>
            </Grid>

            {/* Medications */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <Medications patientId={props.patientInfo.id} />
                </Box>
            </Grid>

            {/* Allergies */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <Allergies patientData={props.patientInfo} patientId={props.patientInfo.id} />
                </Box>
            </Grid>

            {/* Immunizations */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <Immunizations />
                </Box>
            </Grid>

            {/* Labs */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <Labs />
                </Box>
            </Grid>

            {/* Clinical Notes */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <ClinicalNotes />
                </Box>
            </Grid>

            {/* Vital Signs */}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <VitalSigns />
                </Box>
            </Grid>
            {/* Behavior Rate Scales*/}
            <Grid item xs={12} md={6} lg={4} className={classes.group_item}>
                <Box className={classes.box_ct_health_record}>
                    <BehaviorRateScale
                        patientId={props.patientInfo.id}
                        patientInfo={props.patientInfo}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
