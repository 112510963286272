const axios = require('axios')
const { Auth } = require('aws-amplify')
const { default: store } = require('store')
const { default: Routes } = require('constants/routes')
const { API_SYSTEM } = require('constants/index')
const { setActiveLatest } = require('store/actions/login')

const baseURL = process.env.REACT_APP_SONORA_V2_API_URL
//const baseURL =('https://qa.telemed2u.com/telemed2u-clinical-notes')

const axiosInstance = axios.create({
    baseURL,
})

async function getToken() {
    const token = localStorage.getItem('token')
    if (!token) return ''

    const { activeLatest } = store.getState().login

    const payload = JSON.parse(atob(token.split('.')[1]))

    const currentTime = parseInt(new Date().getTime() / 1000)
    const { exp } = payload

    // if (activeLatest && currentTime - activeLatest > 3600) return ''
    if (exp - currentTime > 60) return token

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })

    return user.signInUserSession.idToken.jwtToken
}

axiosInstance.interceptors.request.use(
    async (requestConfig) => {
        // const token = localStorage.getItem('token')

        const token = await getToken()
        if (token) {
            let uri = ''
            const { url, baseURL } = requestConfig

            if (url.startsWith('http')) uri = url
            else uri = baseURL + url

            const { pathname } = new URL(uri)
            if (!API_SYSTEM.includes(pathname)) store.dispatch(setActiveLatest())

            localStorage.setItem('token', token)
            requestConfig.headers['Authorization'] = `Bearer ${token}`
        } else {
            store.dispatch(setActiveLatest(0))
            localStorage.clear()
            window.location.href = Routes.HOME
            return
        }

        // requestConfig.headers[
        //     'Authorization'
        // ] = `Bearer ${token}`

        requestConfig.headers['Content-Type'] = `application/json`
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] = requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    },
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error.response)
    },
)

export function getService(url, config) {
    return axiosInstance.get(url, config)
}

export function getServiceForPdf(url, config) {
    return axiosInstance.get(url, config)
}

export function postService(url, params, options) {
    return axiosInstance.post(url, params, options)
}

export function putService(url, params) {
    return axiosInstance.request({
        url,
        method: 'PUT',
        data: params,
    })
}

export function deleteService(url, params) {
    return axiosInstance.request({
        url,
        method: 'DELETE',
        data: params,
    })
}
