import React from 'react';
import { Message } from '@twilio/conversations/lib/message';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import MediaMessage from './MediaMessage/MediaMessage';
import { useSelector } from 'react-redux';

interface MessageListProps {
  messages: any[];
  room: any;
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

export default function MessageList({ messages, room }: MessageListProps) {
  const localParticipant = room?.localParticipant;

  return (
    <MessageListScrollContainer messages={messages} localParticipant={localParticipant}>

      {messages.map((message, idx) => {
        // const time = getFormattedTime(message)!;
        // const previousTime = getFormattedTime(messages[idx - 1]);

        // // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        // const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        // const isLocalParticipant = localParticipant?.identity === message.author;

        const time = message.date

        const isLocalParticipant = message.isSentByMe

        const msgFrom = JSON.parse(message.from?.connectionData || '{}')

        return (
          <React.Fragment key={`message_${idx}`}>
            {/* {shouldDisplayMessageInfo && (
              <MessageInfo author={message.author} isLocalParticipant={isLocalParticipant} dateCreated={time} />
            )} */}
            {/* {message.type === 'text' && <TextMessage body={message.body} author={message.author}
              isLocalParticipant={isLocalParticipant}
              // isLocalParticipant={idx /2 === 0}
              dateCreated={time} />}
            {message.type === 'media' && <MediaMessage media={message.media} />} */}
            <TextMessage body={message.data} author={message.from?.name || msgFrom?.email}
              isLocalParticipant={isLocalParticipant}
              dateCreated={time} />
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
