import * as actionType from '../actionType'

const initialState = {
    active: false,
    tabName: '',
}

const telehealthScreen = (state = initialState, action) => {
    // return state
    switch (action.type) {
        case actionType.SET_TELEHEALTH_SCREEN:
            return {
                ...state,
                active: action.active,
                tabName: action.tabName,
            }
        default:
            return state
    }
}

export default telehealthScreen
