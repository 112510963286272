import React, { Suspense, useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { getAppointmentsByPatientId } from 'services/Patient/patient'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Dialog, DialogActions, DialogContent } from '@mui/material'

import Routes from 'constants/routes'
import { Loading } from 'shared/elements/Loading'

import { Appointment } from '../interfaces'
import { Card } from '../view/CardHealthRecord'
import { usePatientDashboardStyles } from '../style'
import AppointmentBooking from 'containers/AppointmentBooking'
import { createSchedule } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { NoData } from './NoData'
import SchedulerMenu from 'containers/SchedulerMenu'
import { changeAppointment } from 'store/actions/schedule'
import { useTimezone } from 'shared/hooks/useTimezone'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'


import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ReactComponent as MinusCircleIcon } from '../../../assests/images/minus-circle-white.svg'
import { ReactComponent as MapIcon } from '../../../assests/icons/map-icon.svg'
import { ReactComponent as DollerIcon } from '../../../assests/icons/doller.svg'
import { formatString } from 'containers/CalendarScheduler/utils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel';
import officeVisit  from '../../../assests/icons/officeVisitIcon.svg'
import teleVisit from '../../../assests/icons/teleVisitIcon.svg'
import  { ReactComponent as HourWatchIcon }from '../../../assests/icons/pendingStatusIcon.svg'
import  { ReactComponent as Rescheduled }from '../../../assests/icons/rescheduledIcon.svg'

interface Props {
    onClick?: () => void
}

type CAppointment = Appointment & { timestamp: string }

export const Appointments = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const notification = useNotification()
    const { id: patientId } = useParams<{ id: string }>()
    const history = useHistory()
    const dispatch = useDispatch()
    const timezone = useTimezone()

    const [appointments, setAppointments] = useState<Appointment[]>([])
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [appointmentModal, setAppointmentModal] = useState({ id: null, status: false })

    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [activeTab, setActiveTab] = useState(1)

    const now = moment()
    const statusOptions = useSelector((state) => state.setting.appointmentStatus)

    const { locations, visitTypes } = useAppointmentBookingFormData()

    useEffect(() => {
        const abortController = new AbortController()
        getListAppointments()

        return () => abortController.abort()
    }, [])

    const getListAppointments = async () => {
        // @TODO: Consider to implement show more list or pagination
        try {
            setLoading(true)
            const resp = await getAppointmentsByPatientId(patientId) // Last 10 records
            setAppointments(resp.data.data as Appointment[])
        } catch (ex) {
            console.error('[Appointments] cannot fetch list appointments', ex)
        }

        setLoading(false)
    }

    const handleViewAll = () => history.push(Routes.PATIENT.APPOINTMENTS.replace(':id', patientId))

    const handleAddNew = () => {
        // history.push(Routes.SCHEDULE, {
        //     openNewDialog: true,
        //     patientId,
        // })
        newAppointmentModalToggle()
    }

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            await createSchedule(values)

            setIsSubmitting(false)
            setAppointmentModal({ id: null, status: false })
            getListAppointments()
            notification('An appointment was successfully created!', 'success')
        } catch (error) {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'An appointment was not successfully created!',
                'error',
            )
        }
    }

    const groupAppointmentsByDate = () => {
        return appointments?.reduce((acc: { [date: string]: CAppointment[] }, appointment) => {
            const startTime = moment(appointment.start),
                endTime = moment(appointment.end)
            const appointmentDate = startTime.format('MMM DD, YYYY')

            const newAppointment = {
                ...appointment,
                timestamp: `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`,
            }

            if (!acc[appointmentDate]) {
                acc[appointmentDate] = [newAppointment]
            } else {
                acc[appointmentDate].push(newAppointment)
            }

            return acc
        }, {})
    }

    const appointmentGroups = groupAppointmentsByDate()

    const handleAddToWaitlist = () => {
        history.push(
            `${Routes.WORK_QUEUES.MAIN}${Routes.WORK_QUEUES.WAITLIST.CREATE}?patient_id=${patientId}`,
        )
    }

    const appointmentStatus = (status) => {
        if (status === 'booked') {
            return (
                <Grid container>
                    <CheckCircleIcon style={{ color: '#15A452', fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Confirmed</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'cancelled') {
            return (
                <Grid container>
                    <CancelIcon style={{ color: '#132C77',fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Cancelled</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'noshow') {
            return (
                <Grid container>
                    <CancelIcon style={{ color: 'red', fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>No Show</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'waitlist') {
            return (
                <Grid container>
                    <Rescheduled style={{  fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Rescheduled</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'fulfilled') {
            return (
                <Grid container>
                    <CheckCircleIcon style={{ color: '#5571C6', fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Completed</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'checked-in') {
            return (
                <Grid container>
                    <MinusCircleIcon style={{ color: '#5571c6', width: '12px', height: '16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>In Session</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'arrived') {
            return (
                <Grid container>
                    <MapIcon style={{ color: '#15a452',fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Arrived</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'pending') {
            return (
                <Grid container>
                    <HourWatchIcon style={{ fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Pending</span>
                    </Typography>
                </Grid>
            )
        } else if (status === 'entered-in-error') {
            return (
                <Grid container>
                    <DollerIcon style={{ color: '#101720', fontSize:'16px' }} />
                    <Typography
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                        <span
                        className={classes.title_clinical_reminder}
                        style={{
                            marginLeft: 6,
                        }}>Billing Hold</span>
                    </Typography>
                </Grid>
            )
        }
        return (
            <Grid container>
                <ErrorOutlineIcon style={{ color: 'grey', fontSize:'16px' }} />
                <Typography
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}>
                    <span
                    className={classes.title_clinical_reminder}
                    style={{
                        marginLeft: 6,
                    }}>{formatString(status)}</span>
                </Typography>
            </Grid>
        )
    }

    const Content = () => (
        <>
            {/* {Object.keys(appointmentGroups).map((appointmentDate, i) => (
                <Grid container key={i}>
                    <Typography
                        style={{
                            color: '#9CA9B8',
                            marginBottom: 12,
                            // marginTop: 8
                        }}>
                        {appointmentDate}
                    </Typography> */}

                    {appointments.length && appointments.map((mb) => {
                        const color = moment(mb.end).isBefore(now)
                            ? '#E81212'
                            : moment(mb.start).isSameOrBefore(now)
                                ? 'rgb(232 146 18)'
                                : '#14AE5C'

                        return (
                            <>
                                <Grid
                                    container
                                    key={mb.id}
                                    // justifyContent="space-between"
                                    // wrap="nowrap"
                                    // alignItems="center"
                                    style={{
                                        borderBottom: '1px solid #E4E8EE',
                                        paddingBottom: 8,
                                        marginTop: 8,
                                        height: 'fit-content',
                                    }}>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        wrap="nowrap"
                                        alignItems="center">
                                        <Grid
                                            item
                                            direction="column"
                                            alignItems="flex-start"
                                            style={{
                                                width: 'fit-content',
                                            }}>
                                            <Typography
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                }}>
                                                <span className={classes.appointmentDatetext}>
                                                    {moment(mb.start).format('MM/DD/YYYY')}
                                                </span>
                                                <span
                                                    className={classes.appointmentDatetext}
                                                    style={{
                                                        margin: '0 6px',
                                                    }}>
                                                    {moment(mb.start).format('h:mm A')}
                                                </span>
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            direction="column"
                                            alignItems="flex-start"
                                            style={{
                                                width: 'fit-content',
                                            }}>
                                            {appointmentStatus(mb.status)}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        direction="column"
                                        alignItems="flex-start"
                                        style={{
                                            width: 'fit-content',
                                        }}>
                                        <Typography
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}>
                                            <span className={classes.title_clinical_reminder}>
                                                {`${mb?.practitioner_first_name} ${mb?.practitioner_last_name}`}
                                            </span>
                                            <span
                                                className={classes.title_clinical_reminder}
                                                style={{
                                                    margin: '0 5px',
                                                }}>
                                                {mb?.visit_type?.post_code}
                                            </span>
                                            {mb.appointment_type === 'Telehealth Visit' ? (
                                                <img
                                                    src={teleVisit}
                                                    style={{ marginLeft: '5px' }}
                                                />
                                            ) : (
                                                <img
                                                    src={officeVisit}
                                                    style={{ marginLeft: '5px' }}
                                                />
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })}

                {/* <Grid container item justifyContent="flex-end">
                    <span className={classes.viewall} onClick={handleViewAll}>
                        View all
                    </span>
                </Grid> */}
        </>
    )

    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: patientId,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }

    return (
        <Card
            // menu={[
            //     {
            //         name: 'View all',
            //         handler: handleViewAll,
            //     },
            //     {
            //         name: 'Add new',
            //         handler: handleAddNew,
            //     },
            //     {
            //         name: 'Add to waitlist',
            //         handler: handleAddToWaitlist,
            //     },
            // ]}
            viewAllHandler={handleViewAll}
            addHandler={handleAddNew}
            onClick={props.onClick}
            header="Appointments">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        padding: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {Object.keys(appointmentGroups || {})?.length ? <Content /> : <NoData />}
                </Grid>
                <Grid container item justifyContent="flex-end" style={{padding: "5px"}}>
                    <span className={classes.viewall} onClick={handleViewAll}>
                        View all
                    </span>
                </Grid>
            </Suspense>

            {/* {appointmentModal.status && (
                <AppointmentBooking
                    addFormCardAppointment={true}
                    open={appointmentModal.status}
                    appointmentModal={appointmentModal}
                    loading={isSubmitting}
                    dataLoading={loading}
                    onModalClose={() =>
                        setAppointmentModal({
                            id: null,
                            status: false,
                        })
                    }
                    onSubmit={handleSubmit}
                    locationOptionsData={locations.map((item) => ({
                        key: item.id,
                        value: item.name,
                    }))}
                    visitTypeOptionsData={visitTypes.map((item) => ({
                        key: item.id,
                        value: item.post_code,
                        duration: item.duration,
                    }))}
                    statusOptionsData={statusOptions.map((item) => ({
                        id: item.id,
                        key: item.code,
                        value: item.name,
                    }))}
                />
            )} */}
            {newAppointmentModal.status && (
                <Dialog
                    open={newAppointmentModal.status}
                    sx={{
                        '& .MuiDialog-paperFullWidth': {
                            maxWidth: '1200px',
                            height: '100vh',
                            overflow: 'hidden',
                        },
                    }}
                    fullWidth={true}
                    onClose={newAppointmentModalToggle}
                    className={classes.root}>
                    <SchedulerMenu
                        isPopupType
                        tab={activeTab}
                        onChangeTab={(tab) => setActiveTab(tab)}
                        isFromClinicalNote
                        open={newAppointmentModal.status}
                        appointmentModal={newAppointmentModal}
                        loading={isSubmitting}
                        onModalClose={newAppointmentModalToggle}
                        setIsLoading={setIsSubmitting}
                        onSubmit={handleSubmit}
                        locationOptionsData={locations.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={visitTypes.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                        onOk={getListAppointments}
                    />
                </Dialog>
            )}
        </Card>
    )
}
