/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id:
        process.env.REACT_APP_SONORA_COG_USER_POOL_ID,
    aws_user_pools_web_client_id:
        process.env.REACT_APP_SONORA_COG_CLIENT_ID,
    oauth: {},
    aws_cognito_username_attributes: [],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [
        'GIVEN_NAME',
        'FAMILY_NAME',
        'EMAIL',
    ],
    aws_cognito_mfa_configuration: 'OPTIONAL',
    aws_cognito_mfa_types: ['SMS', 'TOTP'],
    aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [
            'REQUIRES_LOWERCASE',
            'REQUIRES_UPPERCASE',
            'REQUIRES_NUMBERS',
            'REQUIRES_SYMBOLS',
        ],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
}

export default awsmobile
