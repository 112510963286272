import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { ConfirmBox } from './ConfirmBox'
import { Typography } from '@material-ui/core'

interface LocationState {
    pathname: string
    search: string
    hash: string
    state: any
    key: string
}
interface Props {
    navigate: (path?: LocationState) => void
    when: boolean
    shouldBlockNavigation: (location: LocationState) => boolean
    yes: string
    no: string
    content: string
    onConfirm?: () => void
}

export const RouteLeavingGuard = ({
    navigate,
    when,
    shouldBlockNavigation,
    yes,
    no,
    content,
    onConfirm,
}: Props) => {
    const [modalVisible, updateModalVisible] = useState(false)
    const [lastLocation, updateLastLocation] = useState<LocationState>()
    const [confirmedNavigation, updateConfirmedNavigation] = useState(false)

    const showModal = (location) => {
        updateModalVisible(true)
        updateLastLocation(location)
    }

    const closeModal = (cb?: () => void) => {
        updateModalVisible(false)
        if (cb) {
            cb()
        }
    }

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation)
            return false
        }
        return true
    }
    const handleConfirmNavigationClick = () => {
        closeModal(() => {
            if (lastLocation) {
                updateConfirmedNavigation(true)
            }
        })
    }

    useEffect(() => {
        if (confirmedNavigation) {
            navigate(lastLocation)
            updateConfirmedNavigation(false)
        }
    }, [confirmedNavigation])

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation} />
            <ConfirmBox
                yes={yes}
                no={no}
                visible={modalVisible}
                onConfirm={() => {
                    closeModal()
                    onConfirm?.()
                }}
                onCancel={handleConfirmNavigationClick}>
                <Typography style={{ textAlign: 'center' }}>{content}</Typography>
            </ConfirmBox>
        </>
    )
}

export default RouteLeavingGuard
