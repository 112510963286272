import {
    Checkbox,
    Typography,
    Grid,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import FormControlLabel from '@mui/material/FormControlLabel'
import { ClinicalNoteContext } from '../context/ClinicalNoteContext'

const useStyles = makeStyles({
    root: ({ selected }) => ({
        background: selected ? '#d3def3' : 'none',
        padding: '0px 16px',
        borderRadius: 4,
    }),
})

const NoteItem = ({
    header,
    onClick,
    selected,
    fieldName,
    checked,
}) => {
    const classes = useStyles({ selected })
    return (
        <Grid
            className={classes.root}
            container
            onClick={onClick}>
            <FormControlLabel
                control={
                    <Checkbox
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={
                            <CheckCircleIcon
                                sx={{ color: '#14ae5c' }}
                            />
                        }
                        checked={Boolean(checked)}
                    />
                }
                label={
                    <Typography variant="body1">
                        {header}
                    </Typography>
                }
            />
        </Grid>
    )
}

export default NoteItem
