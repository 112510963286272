function SyncIcon(props) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.64399 2.63602C5.27267 1.00735 7.52267 0 10.0079 0C13.3421 0 16.2529 1.81308 17.808 4.50711V2.00009C17.808 1.4478 18.2557 1.00009 18.808 1.00009C19.3258 1.00009 19.7516 1.39359 19.8029 1.89784C19.8063 1.93146 19.808 1.96557 19.808 2.00009V7.0001C19.808 7.2653 19.7027 7.5197 19.5151 7.7072C19.3276 7.8947 19.0732 8.0001 18.808 8.0001H13.808C13.2557 8.0001 12.808 7.5524 12.808 7.0001C12.808 6.4478 13.2557 6.00008 13.808 6.00008L16.3344 6.00009C15.2109 3.63509 12.8004 2 10.0079 2C8.075 2 6.32496 2.7835 5.05821 4.05024C4.42507 4.68338 3.91265 5.43723 3.55845 6.27432C3.38011 6.6958 2.98408 6.99997 2.52643 6.99997C1.86276 6.99997 1.36923 6.38098 1.6081 5.76178C2.0644 4.5789 2.76338 3.51664 3.64399 2.63602Z"
                fill={props.color}
            />
            <path
                d="M16.3892 11.8816C16.0387 12.6565 15.5513 13.3562 14.9577 13.9497C13.691 15.2165 11.941 16 10.008 16C7.21554 16 4.805 14.3649 3.68156 11.9999H6.20801C6.7603 11.9999 7.20801 11.5522 7.20801 10.9999C7.20801 10.4476 6.7603 9.99988 6.20801 9.99988H1.20801C0.942788 9.99988 0.688438 10.1052 0.500898 10.2928C0.313358 10.4803 0.208008 10.7347 0.208008 10.9999V15.9999C0.208008 16.5177 0.601508 16.9435 1.10576 16.9947C1.13938 16.9981 1.17349 16.9999 1.20801 16.9999C1.76029 16.9999 2.20801 16.5522 2.20801 15.9999V13.4931C3.76317 16.187 6.67384 18 10.008 18C12.4932 18 14.7432 16.9926 16.3719 15.3639C17.2525 14.4833 17.9515 13.4211 18.4078 12.2382C18.6467 11.619 18.1531 11 17.4895 11C17.0318 11 16.6358 11.3042 16.4575 11.7256C16.4353 11.778 16.4126 11.83 16.3892 11.8816Z"
                fill={props.color}
            />
        </svg>
    )
}

export default SyncIcon
