import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Typography, Box, Grid, Checkbox, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxOption from '../reusable/CheckboxOptions'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import DataGridCustom from '../reusable/DataGridCustom'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'
import { genrateButtonIdentification } from 'containers/ClinicalNotes/utils'

const useStyles = makeStyles({
    container: {
        width: '100%',
        marginBottom: '30px',
    },
    actioneGrid: {
        padding: 20,
        border: '1px solid #B0BCE4',
        borderTop: 0,
        marginBottom: 20,
    },

    subHeadingText: {
        fontSize: 12,
        color: '#9CA9B8',
        marginBottom: 20,
    },

    columnText: {
        fontSize: 14,
    },

    columnHead: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    box: {
        borderRight: '1px solid #B0BCE4',
    },
    no__margin: {
        margin: 0,
    },
})

const mapList = (masterList = []) => {
    return masterList.map((list, i) => ({
        id: list._id,
        name: list?.name,
        label: list?.label,
        additional: false,
        description: '',
        main: false,
        seq: list?.seq,
    }))
}

const defaultValue = {
    main: false,
    additional: false,
    interventions_utilized: '',
    description: '',
}

const InterventionUtilized = ({
    master,
    macros,
    values,
    onChange,
    read_only,
    onClear,
    initialValue,
    fieldName,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()

    const mappedList = mapList(master)
    const [narration, setNaration] = React.useState('')
    const handleFieldChange = (e, id, type, isOther, row) => {
        const { name, value, checked } = e.target

        if (isOther) {
            const updatedValues = {
                ...values?.other,
                [name]: type === 'checkbox' ? checked : value,
            }
            onChange('interven_utilized', {
                ...values,
                other: updatedValues,
            })
        } else {
            const updatedValues = [...values?.interventions]
            const filteredIndex = values?.interventions.findIndex(
                (v) => v.interventions_utilized === id,
            )
            if (filteredIndex > -1) {
                updatedValues[filteredIndex] = {
                    ...defaultValue,
                    ...updatedValues[filteredIndex],
                    interventions_utilized: id,
                    [name]: type === 'checkbox' ? checked : value,
                }
            } else {
                updatedValues?.push({
                    ...defaultValue,
                    interventions_utilized: id,
                    [name]: type === 'checkbox' ? checked : value,
                })
            }

            onChange('interven_utilized', {
                ...values,
                interventions: updatedValues,
            })
        }
    }

    mappedList.push({
        id: 1,
        name: '',
        additional: false,
        description: '',
        main: false,
        isEditable: true,
    })
    const columns = [
        {
            headerClassName: 'super-app-theme--header',
            headerName: (
                <Grid container justifyContent="center">
                    <Typography className={classes.columnHead} variant="body2">
                        Main
                    </Typography>
                </Grid>
            ),
            flex: 0.2,
            sortable: false,
            field: 'main',
            renderCell: (props) => {
                let mains
                if (props.row.isEditable) {
                    mains = values?.other.main
                } else {
                    const filterEle = values?.interventions?.find(
                        (sn) => sn.interventions_utilized === props.row.id,
                    )
                    mains = filterEle?.main
                }
                return (
                    <Grid container justifyContent="center">
                        <CheckboxOption
                            name="main"
                            option={{
                                value: props.row.id,
                                label: '',
                            }}
                            onChange={(e) =>
                                handleFieldChange(
                                    e,
                                    props.row.id,
                                    'checkbox',
                                    props.row.isEditable,
                                    props.row.seq,
                                )
                            }
                            classRoot={classes.no__margin}
                            checked={!!mains}
                            disabled={read_only || nonEditable}
                        />
                    </Grid>
                )
            },
        },
        {
            headerClassName: 'super-app-theme--header',
            headerName: (
                <Grid container justifyContent="center">
                    <Typography className={classes.columnHead} variant="body2">
                        Additional
                    </Typography>
                </Grid>
            ),
            field: 'additional',
            flex: 0.3,
            sortable: false,

            renderCell: (props) => {
                let desc
                if (props.row.isEditable) {
                    desc = values?.other.additional
                } else {
                    const filterEle = values?.interventions?.find(
                        (sn) => sn.interventions_utilized === props.row.id,
                    )
                    desc = filterEle?.additional
                }

                return (
                    <Grid container justifyContent="center">
                        <CheckboxOption
                            name="additional"
                            option={{
                                value: props.row.id,
                                label: '',
                            }}
                            onChange={(e) =>
                                handleFieldChange(
                                    e,
                                    props.row.id,
                                    'checkbox',
                                    props.row.isEditable,
                                    props.row.seq,
                                )
                            }
                            classRoot={classes.no__margin}
                            checked={!!desc}
                            disabled={read_only || nonEditable}
                        />
                    </Grid>
                )
            },
        },
        {
            headerClassName: 'super-app-theme--header',
            headerName: (
                <Typography className={classes.columnHead} variant="body2">
                    Intervention Utilized
                </Typography>
            ),
            field: 'name',
            flex: 1,
            sortable: false,

            renderCell: (props) => {
                const { row } = props
                return row?.isEditable ? (
                    <TextFieldDebounce
                        onKeyDown={(event) => {
                            event.stopPropagation()
                        }}
                        placeholder=""
                        className={classes.textFieldWrap}
                        variant="outlined"
                        size="small"
                        value={values?.other.value || ''}
                        name="value"
                        onChange={(e) =>
                            handleFieldChange(e, props.row.id, 'text', props.row.isEditable)
                        }
                        inputProps={{
                            maxLength: 20,
                        }}
                        disabled={nonEditable}
                        // disabled={read_only}
                    />
                ) : (
                    <Typography className={classes.columnText}>{props.value}</Typography>
                )
            },
        },
        {
            headerClassName: 'super-app-theme--header',
            headerName: (
                <Typography className={classes.columnHead} variant="body2">
                    Optional Text Description
                </Typography>
            ),
            field: 'description',
            flex: 0.8,
            sortable: false,
            renderCell: (props) => {
                let desc
                if (props.row.isEditable) {
                    desc = values?.other.description
                } else {
                    const filterEle = values?.interventions?.find(
                        (sn) => sn.interventions_utilized === props.row.id,
                    )
                    desc = filterEle?.description
                }

                return (
                    <TextFieldDebounce
                        onKeyDown={(event) => {
                            event.stopPropagation()
                        }}
                        className={classes.textFieldWrap}
                        placeholder=""
                        name="description"
                        variant="outlined"
                        size="small"
                        InputProps={{
                            classes: classes.root,
                        }}
                        value={desc || ''}
                        onChange={(e) =>
                            handleFieldChange(
                                e,
                                props.row.id,
                                'text',
                                props.row.isEditable,
                                props.row,
                            )
                        }
                        disabled={nonEditable}
                        // disabled={read_only}
                    />
                )
            },
        },
    ]
    function generatNarrative(macro) {
        const obj = {}

        mappedList.forEach((m) => {
            obj[m.id || m.id] = m.label
        })
        const joinHaveAnd = (arrs) => {
            let str = ''
            arrs.forEach((item, index) => {
                str += index === 0 ? item : index === arrs.length - 1 ? ` and ${item}` : `, ${item}`
            })
            return str
        }
        const mainInterventions = joinHaveAnd(
            values?.interventions.filter((c) => c.main).map((x) => obj[x.interventions_utilized]),
        )

        const additionalInterventions = joinHaveAnd(
            values?.interventions
                .filter((c) => c.additional || c.isEditable)
                .map((x) => obj[x.interventions_utilized]),
        )

        const regardFinalStatement = values?.interventions
            .filter((c) => c.description !== '')
            .map((c) => {
                return `With regard to ${obj[c.interventions_utilized]}${
                    c.description ? `, ${c.description}` : ''
                }`
            })
            .join('. ')

        let str = ''
        if (!!mainInterventions) {
            str = `The main intervention utilized was ${mainInterventions.toLowerCase()}.`
        }

        if (values?.other?.value) {
            if (values?.other?.main) {
                str = `${str} The main intervention utilized was ${values?.other?.value}`
                if (values?.other?.description !== '') {
                    str = `${str} (${values?.other?.description}).`
                } else {
                    str = `${str}.`
                }
            } else if (values?.other?.additional) {
                if (values?.other?.description !== '') {
                    str = `${str} Another intervention utilized was ${
                        values?.other?.value
                    } (${values?.other?.description.toLowerCase()}).`
                } else {
                    str = `${str} An additional intervention utilized was ${values?.other?.value}.`
                }
            }
        }

        if (additionalInterventions) {
            str = `${str} Additional intervention(s) utilized were ${additionalInterventions.toLowerCase()}.`
        }

        if (regardFinalStatement) {
            if (str !== '') {
                str = `${str}\n\n`
            }
            str = `${str}${regardFinalStatement}.`
        }

        setNaration(str)
        const newNarrative = genrateButtonIdentification(str, values?.narrative)
        onChange('interven_utilized.narrative', `${newNarrative}`)
        // onChange('interven_utilized.narrative', `${str} \n${macro}`)
    }
    function handleChange(name, value) {
        onChange(name, value)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Grid>
            <Box className={classes.container}>
                {copyAble && !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )}
                <Typography className={classes.subHeadingText}>
                    (please select one main intervention and any additional interventions; all text
                    is optional)
                </Typography>

                <DataGridCustom
                    tableProps={{
                        rows: mappedList,
                        columns,
                        rowHeight: 50,
                        hideFooter: true,
                    }}
                />
            </Box>
            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleChange}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default InterventionUtilized
