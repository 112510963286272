import moment from 'moment';
import {
    CONSULTATION_FAILURE,
    CONSULTATION_LOADING,
    CONSULTATION_SUCCESS,
    PAGE_CHANGE,
    SEARCH_CONSULTATION,
    CONSULTATION_FILTER_SORT,
} from './constant';
import {
    sortAscending,
    sortDescending,
} from '../../shared/utils';

export const initialState = {
    consultations: {
        data: [],
        initialData: [],
        isLoading: false,
        isLoaded: false,
        isError: false,
        sort: {
            appointment_date: 'asc',
            time: 'asc',
            speciality_name: 'asc',
            provider_name: 'asc',
            patient_name: 'asc',
            participants: 'asc',
        },
    },
    pagination: {
        page: 1,
        limit: 10,
    },
};

export function reducer(state, action) {
    switch (action.type) {
        case CONSULTATION_LOADING:
            return {
                ...state,
                consultations: {
                    ...state.consultations,
                    isLoading: true,
                },
            };

        case CONSULTATION_SUCCESS:
            const data = action.data.map((item) => ({
                ...item,
                time: `${moment
                    .utc(
                        `${item.appointment_date} ${item.start}`,
                    )
                    .local()
                    .format('hh:mm A')} - ${moment
                    .utc(
                        `${item.appointment_date} ${item.end}`,
                    )
                    .local()
                    .format('hh:mm A')}`,
                provider_name: `${item.provider?.first_name} ${item.provider?.last_name}`,
                patient_name: `${item.patient?.first_name} ${item.patient?.last_name}`,
            }));

            return {
                ...state,
                consultations: {
                    ...state.consultations,
                    isLoading: false,
                    isLoaded: true,
                    initialData: data,
                    data,
                },
            };

        case CONSULTATION_FAILURE:
            return {
                ...state,
                consultations: {
                    ...state.consultations,
                    isError: true,
                    isLoaded: true,
                },
            };

        case SEARCH_CONSULTATION:
            return {
                ...state,
                consultations: {
                    ...state.consultations,
                    data: state.consultations.initialData.filter(
                        (item) => {
                            return item.provider_name
                                .toLowerCase()
                                .includes(
                                    action.value.toLowerCase(),
                                );
                        },
                    ),
                },
            };

        case PAGE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.page,
                },
            };

        case CONSULTATION_FILTER_SORT:
            return {
                ...state,
                consultations: {
                    ...state.consultations,
                    sort: {
                        ...state.consultations.sort,
                        [action.value.field]:
                            state.consultations.sort[
                                action.value.field
                            ] === 'asc'
                                ? 'desc'
                                : 'asc',
                    },
                    data:
                        state.consultations.sort[
                            action.value.field
                        ] === 'asc'
                            ? sortAscending(
                                  state.consultations.data,
                                  action.value.field,
                              )
                            : sortDescending(
                                  state.consultations.data,
                                  action.value.field,
                              ),
                },
            };

        default:
            return state;
    }
}
