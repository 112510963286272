import React from 'react'
import { Box, TextField } from '@material-ui/core'

const RenderWeight = ({ master, values, handleChange, setBmiMeasures }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'weight',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { value } = e.target
        const updatedValue = {
            vital_master_id: filteredMaster?._id,
            unit: filteredEle?.unit || filteredMaster?.units[0],
            value: value.replace(/[^0-9]/g, ''),
        }
        handleChange(updatedValue)
        setBmiMeasures(updatedValue?.value, updatedValue?.unit)
    }

    React.useEffect(() => {
        setBmiMeasures(filteredEle?.value, filteredEle?.unit)
    }, [])

    return (
        <Box
            sx={{
                border: '1px solid #EAECF0',
                width: '100px',
                borderRadius: 3,
                alignItems: 'center',
                display: 'flex',
                backgroundColor: '#FFF',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
            }}>
            <TextField
                placeholder="Weight"
                onChange={handleInput}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        backgroundColor: '#FFF',
                    },
                }}
                value={filteredEle?.value}
                hiddenLabel
                variant="filled"
                size="small"
                style={{ width: 100 }}
            />
        </Box>
    )
}

export default RenderWeight