import React, { useEffect, useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useHistory } from 'react-router-dom'
import {
    Box,
    Grid,
    makeStyles,
    CircularProgress,
    Typography,
    IconButton,
} from '@material-ui/core'
import { Button } from '@mui/material'
import moment from 'moment'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
    createSchedule,
    getSchedule,
    getScheduleById,
    getSpecialty,
    getVisitType,
    postStatusChangeRequest,
    sendResetConfirmationLink,
    updateSchedule,
} from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { changeAppointment } from 'store/actions/schedule'
import { useTimezone } from 'shared/hooks/useTimezone'
import BookingHeader from '../Header'
import { scheduleValidationSchema } from './validation'
import { PATIENT_VISIT_TYPE } from '../constant'
import { handleGetEncounterQueueList } from 'store/actions/provider'
import { CustomStatusChangeDialogBody} from 'components/shared/Elements/CustomStatusChangeDialogBody'
import { appointmentStatus } from 'containers/CalendarScheduler/EventCard/components/appointmentStatus'
import AlertStatusChangeDialog from 'components/shared/Elements/AlertStatusChangeDialog'
import ServiceType from './ServiceType'
import { Popover, Divider } from '@mui/material'
import { ReactComponent as HourWatchIcon } from '../../../assests/icons/hour_watch.svg'
import openLog from 'assests/icons/open.svg'
import closeLog from 'assests/icons/close.svg'
import AppointmentLog from 'containers/CalendarScheduler/EventCard/components/AppointmentLog'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import ReportIcon from '@mui/icons-material/Report'
import { Close } from '@material-ui/icons'
import ReasonForVisit from './ReasonForVisit'
import { checkProviderAvailability } from 'services/ProviderDirectory'
import { APPOINTMENT_STATUSES } from 'containers/CalendarScheduler/types'
import { ConfirmReasonDialogBody } from 'components/shared/Elements/CustomStatusChangeDialogBody'
import { activeCariumAccess } from 'services/Patient/patient'
import { setActiveNoteToPlan } from 'store/actions/clinicalNotes'
import { addDraftAppointment } from '../../CalendarScheduler/helpers'

const useStyles = makeStyles(() => ({
    required_text: {
        color: 'red',
    },
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },
    right_time: {
        color: '#fff',
        width: '60px',
        background: '#4c8080',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '39px',
        marginLeft: '10px',
        cursor: 'pointer',
    },
    status_button: {
        padding: '2px 8px',
        margin: 0,
        borderRadius: 3,
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
    },
    notificationTooltip: {
        '& .MuiTooltip-tooltip': {
            padding: '0px !important',
        },
    },
}))
const dummyEventObj = {
    type: 'appointment',
    appointmentType: '',
    category: '',
    status: APPOINTMENT_STATUSES.pending,
    id: '',
    description: '',
    start: '',
    end: '',
    generate_link: '',
    clinical_note_id: '',
    color_tag: '#b8e986',
    patient_id: '',
    insurance: {},
    location_id: '',
    patientName: '',
    patientTimezone: '',
    patientDob: '',
    patientPhone: '',
    locationName: '',
    chart_status: '',
    practitioner_id: '',
    practitioner_first_name: '',
    practitioner_last_name: '',
    practitioner_timezone: '',
}

function Scheduler({
    previousPatientId,
    handleClose,
    customHeader,
    onSubmit,
    isScheduler,
    sidebarOpen,
    isTimingChanging,
    setIsTimingChanging,
    appointmentIdFromModalProp = null,
    isSubmitting,
    setIsSubmitting = () => {},
    setPreviousPatientId = () => {},
}) {
    const classes = useStyles()
    const userName = localStorage.getItem('name')

    const [loading, setLoading] = useState(false)
    const [availErr, setAvailErr] = useState('')
    const [availStatus, setAvailStatus] = useState(true)

    const [sendConfirmationPopupOpen, setSendConfirmationPopupOpen] = useState(false)

    const [formSendNotificationValue, setFormSendNotificationValue] = useState('0')

    const [patientAgeCheckText, setPatientAgeCheckText] = useState('')
    const [checkNeedToCreateCarium, setCheckNeedToCreateCarium] = useState(false)

    const [previousValue, setPreviousValue] = useState({
        practitioner_id: '',
        visit_type_id: '',
        duration: '',
        date: '',
        id: null,
        start: '',
    })

    const primaryStatus = [
        {
            value: 'Confirmed',
            key: APPOINTMENT_STATUSES.booked,
        },
        {
            value: 'No Show',
            key: APPOINTMENT_STATUSES.noshow,
        },
        {
            value: 'Cancelled',
            key: APPOINTMENT_STATUSES.cancelled,
        },
    ]
    const defaultStatus =
        localStorage.getItem('real_role') === 'care_coordinator'
            ? [...primaryStatus, { value: 'Billing Hold', key: APPOINTMENT_STATUSES.enteredInError }]
            : primaryStatus

    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])

    const [openAlertStatusDialog, setOpenAlertStatusDialog] = useState(false)
    const [isWaitlist, setIsWaitlist] = useState(false)
    const [isRecallList, setIsRecallList] = useState(false)
    const [isChartRecord, setIsChartRecord] = useState(true)
    const [isCreateNote, setIsCreateNote] = useState(true)

    const appointment = useSelector((state) => state.schedule.appointment)
    const dispatch = useDispatch()
    const notification = useNotification()
    const timezone = useTimezone()
    const history = useHistory()

    const dateFilterEq = useSelector((state) => state.provider.encounterQueueListDate)
    const isUpdate = (appointment.id && appointment.id !== -1) || appointmentIdFromModalProp

    const [status, setStatus] = useState(APPOINTMENT_STATUSES.pending)
    const { bgColor, displayName, icon } = appointmentStatus(status)
    const [appointmentId, setAppointmentId] = useState('')
    const [anchorEl2, setAnchorEl2] = React.useState(null)
    const open2 = Boolean(anchorEl2)
    const endId2 = open2 ? 'simple-popover' : undefined
    const [appointmentLogOpen, setAppointmentLogOpen] = React.useState(false)
    const [anchorElCancelOrNoshow, setAnchorElCancelOrNoshow] = React.useState(false)

    const [initialValues] = useState(
        appointment.values || {
            id: '',
            date: moment().format('YYYY-MM-DD'),
            end_time: moment().format('HH:mm'),
            start_time: moment().format('HH:mm'),
            appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
            reason_for_visit: '',
            internal_note: '',
            specialty_id: '',
            location_id: '',
            duration: '',
            visit_type_id: '',
            patient_id: '',
            practitioner_id: '',
            supervising_provider: '',
            is_require_translator: false,
            appointment_status: '',
            is_reoccurring_appointment: false,
            days: [],
            end_date: '',
            unit: '',
            once_every: '',
            end_type: '',
            timezone: timezone.locationTimezone,
            populate: false,
            clinical_note_status: '',
            encounter_status: '',
            encounter_id: '',
            reoccuring_created: false,
            practitioner_first_name: '',
            practitioner_last_name: '',
            patientName: '',
            color_tag: '',
            clinical_note_id: '',
            chart_status: '',
            insurance: {},
            notes: [],
            note: '',
            noteView: 'no-view',
            appointment_first_offered_date: '',
            is_patient_declined: false
        },
    )

    const handleOpenCancelOrConfirm = (_, status) => {
        setOpenAlertStatusDialog(true)

        setStatus(status)
    }

    const statusDisabled = () => {
        return formik.values.id === ''
            ? true
            : formik?.values?.status === APPOINTMENT_STATUSES.noshow ||
              formik?.values?.status === APPOINTMENT_STATUSES.cancelled ||
              formik?.values?.status === APPOINTMENT_STATUSES.fulfilled
            ? true
            : false
    }
    const handleCloseCancelOrConfirm = () => {
        setOpenAlertStatusDialog(false)
        setIsWaitlist(false)
        setIsRecallList(false)
        setIsCreateNote(true)
        setIsChartRecord(true)
        setAnchorElCancelOrNoshow(false)
        formik?.setFieldValue('cancelationReason', '')
        formik?.setFieldValue('cancelationReasonType', '')
    }

    const handleCheckWaitlist = () => setIsWaitlist(!isWaitlist)
    const handleCheckRecallList = () => setIsRecallList(!isRecallList)
    const handleCheckCreateNote = () => setIsCreateNote(!isCreateNote)
    const handleCheckChartRecord = () => setIsChartRecord(!isChartRecord)

    async function refetchSchedule() {
        const params = {
            startTime: dateFilterEq
                ? moment(dateFilterEq).startOf('day').utc().format()
                : moment().startOf('day').utc().format(),
            endTime: dateFilterEq
                ? moment(dateFilterEq).endOf('day').utc().format()
                : moment().endOf('day').utc().format(),
        }
        await getSchedule(params)
    }
    const createCarium = async(patientId) =>{
        try{       
            const res= await activeCariumAccess(patientId)
            if(res?.data?.status === "error"){
                notification(`Couldn't get updated patient details because ${res?.data?.message}`, 'error')
            }
        }catch(err){
            console.log("err12",err)
        }
    }

    const handleSubmit = async (values) => {
        try {
            const statusRequiredReason = ['noshow', 'cancelled']
            if(formik?.values?.cancelationReason === '' && statusRequiredReason.includes(formik?.values?.cancelationReasonType)) {
                notification("Reason is required!", 'error')
                return
            }

            if (formik?.values?.status === APPOINTMENT_STATUSES.fulfilled || formik?.values?.status === APPOINTMENT_STATUSES.cancelled) {
                notification("Can't update completed or cancelled appointment!", 'error')
                return
            }

            setIsSubmitting(true)

            const { timezone } = values
            if (values.is_reoccurring_appointment) {
                values.reoccurring_appointment = {
                    days: values.days,
                    end_date: moment
                        .tz(values.end_date || values.date, 'YYYY-MM-DD HH:mm', timezone)
                        .utc()
                        .format(),
                    unit: values.unit === 'weekday' ? 'week' : values.unit,
                    once_every: values.once_every,
                }
            }
            values.start = moment
                .tz(`${values.date} ${values.start_time}`, 'YYYY-MM-DD HH:mm', timezone)
                .utc()
                .set('seconds', 0)
                .format()
            values.end = moment
                .tz(`${values.date} ${values.end_time}`, 'YYYY-MM-DD HH:mm', timezone)
                .utc()
                .set('seconds', 0)
                .format()
            values.appointment_for = 'patient'
            values.participants = [
                {
                    status: 'accepted',
                    role: 'Patient',
                    participant_id: values.patient_id,
                },
            ]

            const payload = {
                start: values.start,
                end: values.end,
                practitioner_id: values.practitioner_id,
                timezone,
            }

            if (values.is_reoccurring_appointment) {
                payload.reoccurring_appointment = {
                    ...values.reoccurring_appointment,
                    unit: values.unit,
                }
            }
            if (values.id) payload.appointment_id = values.id

            const { chart_status, ...restValues } = values

            const is_delete_note = status === APPOINTMENT_STATUSES.noshow || status === APPOINTMENT_STATUSES.cancelled ? true : false,
                delete_chart_record = status === APPOINTMENT_STATUSES.noshow || status === APPOINTMENT_STATUSES.cancelled ? true : false,
                add_clinical_note =
                    status === 'booked' && formik?.values?.status !== 'booked'
                        ? isCreateNote
                        : false,
                add_chart_record =
                    status === 'booked' && formik?.values?.status !== 'booked'
                        ? isChartRecord
                        : false,
                add_recall = isRecallList,
                add_waitlist = isWaitlist

            const dependables = {
                is_delete_note,
                delete_chart_record,
                add_recall,
                add_waitlist,
                add_chart_record,
                add_clinical_note,
            }

            const cancelationReason = statusRequiredReason.includes(status) ? formik?.values?.cancelationReason : null

            if (isScheduler) {
                const modifyEventObj = {
                    ...dummyEventObj,
                    id: restValues?.id,
                    appointmentType: restValues.appointment_type,
                    start: restValues.start,
                    end: restValues.end,
                    patient_id: restValues.patient_id,
                    patientName: restValues.patientName,
                    practitioner_id: restValues.practitioner_id,
                    practitioner_first_name: restValues.practitioner_first_name,
                    practitioner_last_name: restValues.practitioner_last_name,
                    color_tag: restValues.color_tag,
                    status: previousPatientId === restValues.patient_id ? status : APPOINTMENT_STATUSES.pending,
                    insurance: restValues.insurance,
                }
                addDraftAppointment(modifyEventObj)
                onSubmit &&
                    onSubmit(
                        { ...restValues, send_reschedule_notification: formSendNotificationValue },
                        status,
                        dependables,
                        null,
                        cancelationReason
                    )
            } else {
                if (restValues.id) {
                    const res = await postStatusChangeRequest(
                        status,
                        restValues.id,
                        dependables,
                        timezone,
                        cancelationReason
                    )
                    if (res) {
                        const res = await updateSchedule({
                            id: restValues.id,
                            payload: {
                                ...restValues,
                                appointment_status: 'Appt_Not_Start',
                                status,
                                send_reschedule_notification: formSendNotificationValue,
                            },
                        })
                        if (res.status === 200) {
                            refetchSchedule()
                            if (formSendNotificationValue !== '0')
                                sendResetConfirmationLink(restValues?.id, formSendNotificationValue)
                        }
                    }
                } else {
                    await createSchedule({
                        ...restValues,
                        appointment_status: 'Appt_Not_Start',
                        user_name: userName,
                        status,
                    })
                }
                notification(
                    `An appointment was successfully ${restValues.id ? 'updated' : 'created'}!`,
                    'success',
                )
            }
            if(checkNeedToCreateCarium && values?.patient_id){
                await createCarium(values?.patient_id);
            }
            dispatch(handleGetEncounterQueueList())
            if (history?.location?.state?.fromMedicalNote){
                dispatch(setActiveNoteToPlan(true))
            }
            if (restValues.populate) return history.goBack()
            handleClose()
        } catch (error) {
            notification(error?.data?.message || 'Something went wrong!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    const formik = useFormik({
        validationSchema: scheduleValidationSchema,
        enableReinitialize: false,
        initialValues,
        onSubmit: handleSubmit,
    })

    async function getAppointment() {
        try {
            const {
                data: {
                    id,
                    appointment_type,
                    reason_for_visit,
                    internal_note,
                    duration,
                    start,
                    end,
                    specialty_id,
                    practitioner_id,
                    location_id,
                    is_require_translator,
                    visit_type_id,
                    appointment_status,
                    status,
                    supervising_provider,
                    participant,
                    clinical_note_status,
                    encounter_status,
                    reoccuring_created,
                    visit_type,
                    encounter_id,
                    clinical_note_id,
                    chart_status,
                    notes,
                    is_patient_declined,
                    appointment_first_offered_date,
                    cancelationReason,
                    cancelationReasonType
                },
            } = await getScheduleById({
                id: appointmentIdFromModalProp ? appointmentIdFromModalProp : appointment.id,
            })
            setPreviousValue({
                practitioner_id,
                visit_type_id,
                duration,
                date: moment(start).format('YYYY-MM-DD'),
                id,
                start: moment(start).format('HH:mm'),
            })

            const values = {
                id,
                appointment_type,
                reason_for_visit,
                internal_note,
                duration,
                specialty_id,
                practitioner_id,
                location_id,
                is_require_translator,
                visit_type_id,
                appointment_status,
                status,
                supervising_provider,
                patient_id: participant[0]?.participant_id,
                date: moment(start).format('YYYY-MM-DD'),
                start_time: moment(start).format('HH:mm'),
                end_time: moment(end).format('HH:mm'),
                days: [],
                end_date: '',
                unit: '',
                once_every: '',
                end_type: '',
                timezone: timezone.locationTimezone,
                is_reoccurring_appointment: false,
                clinical_note_status,
                encounter_status,
                reoccuring_created,
                color_tag: visit_type?.color_tag,
                encounter_id,
                clinical_note_id,
                chart_status,
                notes,
                noteView: 'no-view',
                note: '',
                is_patient_declined,
                appointment_first_offered_date,
                cancelationReason,
                cancelationReasonType
            }
            formik.setValues(values)
            console.log('patientIdSet', values.patient_id)
            setPreviousPatientId(values.patient_id)
            setStatus(values.status)
            setAppointmentId(values.id)
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    const getListVisitType = async () => {
        try {
            const params = { page: 1, limit: 100000 }
            if (formik.values.specialty_id) {
                params.specialty_id = formik.values.specialty_id
            }
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType(params)
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                    duration: el.duration,
                    color_tag: el.color_tag,
                }))
                return list
            })
            setLoading(false)
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    const getListSpecialty = async () => {
        try {
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    useEffect(() => {
        return () => {
            dispatch(
                changeAppointment({
                    id: '',
                    values: formik.values,
                }),
            )
        }
    }, [formik.values, dispatch])

    useEffect(async () => {
        if (appointment.id && appointment.id > 0) return
        const start_time = moment(formik.values.start_time, 'HH:mm')
        const end_time = start_time.clone().add(formik.values.duration, 'minutes')
        formik.setFieldValue('end_time', end_time.format('HH:mm'))
        const start = moment(
            `${formik.values.date} ${formik.values.start_time}`,
            'YYYY-MM-DD HH:mm',
        ).format()
        const end = moment(
            `${formik.values.date} ${end_time.format('HH:mm')}`,
            'YYYY-MM-DD HH:mm',
        ).format()
        if (previousValue?.id) {
            if (
                formik.values.duration !== previousValue?.duration ||
                formik.values.practitioner_id !== previousValue?.practitioner_id ||
                formik.values.date !== previousValue?.date ||
                formik.values.start_time !== previousValue?.start
            ) {
                const res = await checkProviderAvailability(formik.values.practitioner_id, {
                    appointment_id: formik.values.id,
                    start,
                    end,
                })
                setAvailErr(res.data.error)
                setAvailStatus(res.data.status)
            }
        } else {
            if (formik.values.duration && formik.values.practitioner_id && !formik.values.id) {
                const res = await checkProviderAvailability(formik.values.practitioner_id, {
                    appointment_id: formik.values.id,
                    start,
                    end,
                })
                setAvailErr(res.data.error)
                setAvailStatus(res.data.status)
            }
        }
    }, [
        formik.values.duration,
        formik.values.start_time,
        formik.values.practitioner_id,
        formik.values.date,
    ])

    useEffect(() => {
        formik.validateForm()
    }, [formik.values.unit])

    useEffect(() => {
        (async () => {
            const abortController = new AbortController()
            const promises = [getListSpecialty(), getListVisitType()]
            if (isUpdate) {
                promises.unshift(getAppointment())
            }
            setLoading(true)
            await Promise.allSettled(promises)
            setLoading(false)
            return () => abortController.abort()
        })()
    }, [])

    useEffect(() => {
        (async () => {
            setLoading(true)
            if (formik.values.specialty_id) {
                await getListVisitType()
            }
        })()
    }, [formik.values.specialty_id])

    const ableUpdate = useMemo(() => {
        const { id, clinical_note_status, encounter_status } = formik.values

        if (!id) return true

        return (
            clinical_note_status !== 'LOCKED' &&
            encounter_status !== 'EXAM' &&
            encounter_status !== 'COMPLETED'
        )
    }, [formik.values.id, formik.values.clinical_note_status, formik.values.encounter_status])

    const noPostActionStatus = [APPOINTMENT_STATUSES.noshow, APPOINTMENT_STATUSES.cancelled, APPOINTMENT_STATUSES.fulfilled]
    const handleClose2 = () => {
        setAnchorEl2(null)
    }
    const openStatus = (e) => {
        setAnchorEl2(e.currentTarget)
        e.stopPropagation()
    }
    const handleClickAppointLog = () => {
        setAppointmentLogOpen(!appointmentLogOpen)
    }

    const handleSendNotification = (decision = null) => {
        const send_reschedule_notification = decision === 'yes' ? '2' : '1'
        setFormSendNotificationValue(send_reschedule_notification)
        setSendConfirmationPopupOpen(false)
        formik?.handleSubmit()
    }

    const tooltipContent = () => (
        <div style={{ maxWidth: '350px' }}>
            <span style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 5px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <ReportIcon
                        style={{ fontSize: '18px', marginRight: '10px', color: '#F8B636' }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                        <div dangerouslySetInnerHTML={{ __html: 'Send Change Notification' }}></div>
                    </Typography>
                </span>

                <IconButton onClick={() => setSendConfirmationPopupOpen(false)}>
                    <Close style={{ width: 15, height: 15 }} />
                </IconButton>
            </span>

            <Divider style={{ width: '100%', borderColor: '#BFBDBF' }} />

            <span
                style={{ padding: '10px 20px', display: 'flex', flexDirection: 'column', gap: 10 }}>
                <Typography
                    style={{
                        color: '#303E4E',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}>
                    You have made a change to the patient's appointment. Do you want to send the
                    patient a change notification?
                </Typography>
                <div
                    style={{
                        display: 'flex',
                        gap: 5,
                        color: '#303E4E',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}>
                    Date:{' '}
                    <div style={{ color: '#303E4E', fontWeight: 500 }}>
                        {moment(formik?.values?.date).format('MM/DD/YYYY')}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: 5,
                        color: '#303E4E',
                        fontFamily: 'Rubik',
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}>
                    Time:
                    <div style={{ color: '#303E4E', fontWeight: 500 }}>
                        {moment(
                            `${formik?.values?.date} ${formik?.values?.start_time}`,
                            'YYYY-MM-DD HH:mm',
                        ).format('h:mm A')}
                        {' - '}
                        {moment(
                            `${formik?.values?.date} ${formik?.values?.end_time}`,
                            'YYYY-MM-DD HH:mm',
                        ).format('h:mm A')}
                    </div>
                </div>
            </span>

            <Divider style={{ width: '100%', borderColor: '#BFBDBF' }} />

            <span style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
                <Button
                    size="small"
                    className="cancel-btn"
                    onClick={() => handleSendNotification()}
                    style={{ marginRight: '10px', color: '#444' }}>
                    No
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    style={{
                        background: '#5571C6',
                        borderRadius: '5px !important',
                    }}
                    onClick={() => {
                        handleSendNotification('yes')
                    }}>
                    Yes
                </Button>
            </span>
        </div>
    )

    const handleSubmitFinal = () => {
        if(status === 'booked') formik?.handleSubmit()
        else {
            setOpenAlertStatusDialog(false)
            setAnchorElCancelOrNoshow(true)
            formik?.setFieldValue('cancelationReasonType', status)
        }
    }

    const setCurrentReason = (value: string) => {
        formik?.setFieldValue('cancelationReason', value)
    }

    return (
        <>
            {!!loading && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="flex-start"
                    justifyContent="center">
                    <CircularProgress color="inherit" thickness={3} style={{ color: 'black' }} />
                </Grid>
            )}
            {appointmentLogOpen && appointmentId && (
                <AppointmentLog
                    open={appointmentLogOpen}
                    close={handleClickAppointLog}
                    id={appointmentId}
                />
            )}
            <Box style={{ display: 'flex', alignItems: 'center', marginLeft: 10 }}>
                <span>Appointment Status:</span>
                <span
                    onClick={openStatus}
                    className={classes.status_button}
                    style={{
                        backgroundColor: bgColor,
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '3px',
                        border: '1px solid grey',
                        color: 'white',
                        fontSize: 'small',
                        marginLeft: '4px',
                        height: '21px',
                        flexShrink: 0,
                        cursor: `${statusDisabled() ? 'not-allowed' : 'pointer'}`,
                        pointerEvents: `${statusDisabled() ? 'none' : 'auto'}`,
                    }}>
                    <span style={{ marginTop: '3px' }}>
                        {status === APPOINTMENT_STATUSES.pending ? <HourWatchIcon /> : icon}
                    </span>

                    <span
                        style={{
                            marginLeft: `${status === APPOINTMENT_STATUSES.pending ? '2px' : '0px'}`,
                        }}>
                        {displayName}
                    </span>
                </span>
                {appointmentId && (
                    <img
                        src={appointmentLogOpen ? closeLog : openLog}
                        alt=""
                        style={{
                            height: '18px',
                            paddingLeft: '12px',
                            cursor: 'pointer',
                        }}
                        onClick={handleClickAppointLog}
                    />
                )}
            </Box>
            <Popover
                id={endId2}
                open={noPostActionStatus.includes(formik.values.status) ? false : open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                PaperProps={{
                    style: {
                        boxShadow: '0px 0px 10px #888888',
                    },
                }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        cursor: 'pointer',
                        padding: '10px',
                        justifyContent: 'center',
                        width: 120,
                    }}>
                    {defaultStatus.map((item, i) => {
                        const { bgColor, icon } = appointmentStatus(
                            item.key,
                        )
                        if (
                            formik?.values?.clinical_note_id !== '' &&
                            formik?.values?.status !== APPOINTMENT_STATUSES.enteredInError
                        ) {
                            if (item.key !== 'booked')
                                return (
                                    <span key={i}>
                                        <span
                                            className={`${classes.status_button} ${classes.item_header_text}`}
                                            style={{
                                                backgroundColor: bgColor,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                borderRadius: '3px',
                                                border: '1px solid grey',
                                                color: 'white',
                                                fontSize: 'small',
                                                marginLeft: '4px',
                                                height: '21px',
                                                flexShrink: 0,
                                                marginTop: '6px',
                                            }}
                                            onClick={(event) => {
                                                console.log('setStatus', defaultStatus[i].key)

                                                setStatus(defaultStatus[i].key)
                                                setAnchorEl2(null)
                                                statusDisabled()
                                                event.stopPropagation()
                                            }}>
                                            <span style={{ display: 'flex' }}>{icon}</span>

                                            <span>{item.value}</span>
                                        </span>
                                    </span>
                                )
                        } else {
                            return (
                                <span key={i}>
                                    <span
                                        className={`${classes.status_button} ${classes.item_header_text}`}
                                        style={{
                                            backgroundColor: bgColor,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '3px',
                                            border: '1px solid grey',
                                            color: 'white',
                                            fontSize: 'small',
                                            marginLeft: '4px',
                                            height: '21px',
                                            flexShrink: 0,
                                            marginTop: '6px',
                                            position: 'relative',
                                        }}
                                        onClick={(event) => {
                                            setStatus(defaultStatus[i].key)
                                            console.log('setStatus else', defaultStatus[i].key)
                                            setAnchorEl2(null)
                                            statusDisabled()
                                            event.stopPropagation()
                                        }}>
                                        <span style={{ display: 'flex' }}>{icon}</span>

                                        <span>{item.value}</span>
                                    </span>
                                </span>
                            )
                        }
                    })}
                </div>
            </Popover>

            {(formik?.values?.id && formik?.values?.cancelationReason !== "" ) && <Box style={{ display: 'flex', alignItems: 'center', gap: 10, margin: 10 }}>
                <Typography>Reason:</Typography>
                <Typography>{formik?.values?.cancelationReason}</Typography>
            </Box>}

            <Grid
                container
                item
                className="modal-spacer"
                style={{ padding: customHeader ? 0 : 16 }}>
                <Grid item xs={12}>
                    <BookingHeader
                        classes={classes}
                        listSpecialty={listSpecialty}
                        listVisitType={listVisitType}
                        formik={formik}
                        customHeader={customHeader}
                        speciality_id={formik?.values?.speciality_id}
                        appointmentId={appointmentId}
                        sidebarOpen={sidebarOpen}
                        setIsTimingChanging={setIsTimingChanging}
                        patientAgeCheckText={patientAgeCheckText}
                        setPatientAgeCheckText={setPatientAgeCheckText}
                        availErr={availErr}
                        availStatus={availStatus}
                        isAppointmentCompleted={formik?.values?.status === APPOINTMENT_STATUSES.fulfilled}
                        checkNeedToCreateCarium={setCheckNeedToCreateCarium}
                    />
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <ReasonForVisit formik={formik} isAppointmentCompleted={formik?.values?.status === APPOINTMENT_STATUSES.fulfilled}/>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px' }}>
                    <ServiceType formik={formik} classes={classes} isAppointmentCompleted={formik?.values?.status === APPOINTMENT_STATUSES.fulfilled}/>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingRight: '40px' }}>
                <div className="border-top" />
            </Grid>

            <div
                style={{
                    padding: '2rem',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: '0',
                    maxWidth: '100%',
                    background: 'white',
                    display: 'grid',
                    width: '100%',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '1rem',
                    boxSizing: 'border-box',
                }}>
                    <Button
                        variant="contained"
                        className={classes.button_cancel}
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <HtmlTooltip
                        open={sendConfirmationPopupOpen}
                        title={tooltipContent()}
                        arrow
                        disableHoverListener
                        placement="top-start"
                        className={classes.notificationTooltip}>
                        <LoadingButton
                            loading={isSubmitting}
                            disabled={
                                isSubmitting ||
                                !ableUpdate ||
                                patientAgeCheckText !== '' ||
                                !availStatus
                            }
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create}
                            onClick={(event) => {
                                if (appointmentId === '' && patientAgeCheckText) {
                                    return
                                }

                                if (appointmentId && isTimingChanging) {
                                    setSendConfirmationPopupOpen(true)
                                    return
                                }

                                if (formik?.values?.status === status) {
                                    formik?.handleSubmit(event)
                                } else if (
                                    (status === 'booked' && formik?.values?.encounter_id === '') ||
                                    status === APPOINTMENT_STATUSES.cancelled ||
                                    status === APPOINTMENT_STATUSES.noshow
                                ) {
                                    handleOpenCancelOrConfirm(event, status)
                                } else formik?.handleSubmit(event)
                            }}>
                            Save
                        </LoadingButton>
                    </HtmlTooltip>
            </div>
            {/*Cancel Confirmation or Appointment Confirmation*/}
            <AlertStatusChangeDialog
                open={openAlertStatusDialog}
                title={
                    status === APPOINTMENT_STATUSES.cancelled
                        ? 'Cancellation Confirmation'
                        : status === APPOINTMENT_STATUSES.noshow
                        ? 'No Show Confirmation'
                        : 'Appointment is confirmed!'
                }
                handleClose={handleCloseCancelOrConfirm}
                handleConfirm={handleSubmitFinal}
                confirmButtonName={'OK'}
                confirmButtonColor={status !== 'booked' ? '#D22121 !important' : 'default'}>
                <CustomStatusChangeDialogBody
                    isWaitlist={isWaitlist}
                    handleCheckWaitlist={handleCheckWaitlist}
                    isRecallList={isRecallList}
                    handleCheckRecallList={handleCheckRecallList}
                    isCreateNote={isCreateNote}
                    handleCheckCreateNote={handleCheckCreateNote}
                    isChartRecord={isChartRecord}
                    handleCheckChartRecord={handleCheckChartRecord}
                    previousStatus={formik?.values?.status}
                    statusObject={appointmentStatus(status)}
                    clinical_notes_id={formik?.values?.clinical_note_id}
                    chart_status={formik?.values?.chart_status}
                />
            </AlertStatusChangeDialog>

            <AlertStatusChangeDialog
                open={anchorElCancelOrNoshow}
                title={
                    status === 'cancelled' ? 'Cancellation Confirmation': 'No Show Confirmation'
                }
                handleClose={handleCloseCancelOrConfirm}
                handleConfirm={formik?.handleSubmit}
                confirmButtonName={'OK'}
                confirmButtonDisabled={formik?.values?.cancelationReason === ""}
                confirmButtonColor={status !== 'booked' ? '#D22121 !important' : 'default'}>
                <ConfirmReasonDialogBody
                    currentReason={formik?.values?.cancelationReason}
                    setCurrentReason={setCurrentReason}
                    status={status}
                />
            </AlertStatusChangeDialog>
        </>
    )
}

export default Scheduler
