export const INITIAL_VALUES = {
    prefix: '',
    first_name: '',
    last_name: '',
    suffix: '',
    role: '',
    phone_number: '',
    email: '',
    photo: '',
    federal_tax_id: '',
    // kareoId: '',
    npi: '',
    prescribing_license: [{ type: '', number: '' }],
    specialities: '',
    taxonomy_code: [{ id: '', label: '', isPrimary: false }],
    location_id: [{ id: '', key: Date.now() }],
    mfa: false,
    provider_account: false,
    department_id: '',
    avatar: '',
    timezone: '',
    rcopia_user_name: '',
    rcopia_user_role: '',
    is_enable_eprescribe: false,
    rcopia_member_type: '',
    states: [{ state: '', number: '' }],
    // nadean_number: [{ type: '', number: '' }],
    clinician: false,
    supervising_physician: '',
    provider_clinical_practices: [{ clinical_practice_id: '',kareo_id: '' }]
}
