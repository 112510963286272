import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Switch } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: '70px',
        height: '30px',
        padding: '0px',
    },
    switchBase: {
        color: '#4c8080',
        padding: '0px',
        '&$checked': {
            '& + $track': {
                backgroundColor: '#4c8080',
            },
        },
    },
    thumb: {
        color: 'white',
        width: '28px',
        height: '28px',
        marginTop: 1,
        marginLeft: 1,
    },
    track: {
        borderRadius: '20px',
        backgroundColor: '#4c8080',
        opacity: '1 !important',
        '&:after, &:before': {
            color: 'white',
            fontSize: '13px',
            position: 'absolute',
            top: '3px',
        },
        '&:after': {
            content: ({ label1 = '' }) => `'${label1}'`,
            left: '10px',
            top: '6px',
        },
        '&:before': {
            content: ({ label2 = '' }) => `'${label2}'`,
            right: '10px',
            top: '8px',
        },
    },
    checked: {
        color: '#23bf58 !important',
        transform: 'translateX(40px) !important',
    },
})

export default function CustomizedSwitches({
    labels,
    value,
    index,
    name,
    onChange,
}) {
    const props = {
        ...labels,
    }
    const classes = useStyles(props)
    return (
        <Switch
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            checked={value}
            onChange={onChange}
            data-before-content="C"
            name={name}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    )
}
