import React, { useRef, useState, useEffect } from 'react'
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import { getClinicalNoteType } from 'services/Calendar'
import LoadingPage from 'utilities/loading-page'
import Routes from 'constants/routes'
import { Footer } from 'shared/elements/FormItem/Footer'
import { INITIAL_VALUES } from './schema'
import { useNotification } from 'shared/elements/Notification'
import './styles.scss'
import { getListSpecialties } from 'services/Setting'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import Divider from '@mui/material/Divider'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import TrashIcon from 'assests/icons/TrashIcon'
import { allOutComeList, createOutcomes, updateOutcomes } from 'services/Patient/patient'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    form: {
        position: 'relative',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 15,
    },

})

function createPatientOutcomes() {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()
    const location = useLocation()
    const params = useParams()
    const notification = useNotification()

    const [outComesData, setOutcomesData] = useState(INITIAL_VALUES)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [specialityOptions, setSpecialityOptions] = React.useState([])
    const [clinicalNoteOptions, setClinicalNoteOptions] = React.useState([])
    const [loadingForSpeciality, setloadingForSpeciality] = useState(true)
    const [loadingForNoteType, setloadingForNoteType] = useState(true)
    const goBack = () => history.push(Routes.SETTINGS.PATIENT_OUTCOMES)
    async function getListSpecialtiesOptions() {
        try {
            setloadingForSpeciality(true)
                const response = await getListSpecialties({
                    page: 1,
                    size: 100000,
                })
                setSpecialityOptions(
                    response?.data?.data
                        ?.map((item) => ({
                            key: item.id,
                            value: item.specialty,
                        }))
                        .sort((option1, option2) => {
                            let a = option1.value
                            let b = option2.value
                            return a === b ? 0 : a > b ? 1 : -1
                        }),
                )
            setloadingForSpeciality(false)
        } catch (error) {
            setloadingForSpeciality(false)
            console.log({ error })
        }
    }
    async function getClinicalNote() {
        try {
            setloadingForNoteType(true)
            const response = await getClinicalNoteType()
            setClinicalNoteOptions(response?.data?.map((item) => ({
                    key: item.id,
                    value: item.name,
                })))
            setloadingForNoteType(false)
        } catch (error) {
            setloadingForNoteType(false)
            console.log({ error })
        }
    }

    const handleSubmit = async (values) => {
        if(values?.entry.every(item => item.entry.trim() === '')){
           return notification(
                `Please create 1 entry to continue`,
                'info',
            )
        }
        const payload = {
            ...values,
            entry: values?.entry?.filter((item)=> item.entry.trim() !== '')
        }
        if (values.id) {
            setIsSubmitting(true)
            updateOutcomes(payload)
                .then(() => {
                    notification('Patient outcomes updated successfully!', 'success')
                    setIsSubmitting(false)
                    goBack()
                })
                .catch((error) => {
                    setIsSubmitting(false)
                    error?.data?.data?.length > 0 &&
                        error?.data?.data?.forEach((item) => {
                            notification(
                                item.error ||
                                    error?.data?.message ||
                                    "Patient outcome  didn't updated successfully!",
                                'error',
                            )
                        })
                })
        } else {
            setIsSubmitting(true)
            createOutcomes(payload)
                .then(() => {
                    notification('Patient outcome created successfully!', 'success')
                    setIsSubmitting(false)
                    goBack()
                })
                .catch((error) => {
                    setIsSubmitting(false)

                    error?.data?.data?.length > 0 &&
                        error?.data?.data?.forEach((item) => {
                            notification(
                                item.error ||
                                    error?.data?.message ||
                                    "Patient outcome  didn't create successfully!",
                                'error',
                            )
                        })
                })
        }
    }

    const validationSchema = yup.object({
        name: yup.string().required('Outcome Title is required!'),
        specialty_id: yup.string().required('Specialty is required!'),
        clinical_note_template_id: yup
            .string()
            .required('Note Type is required!'),
        // entry: yup
        //     .array()
        //     .of(
        //         yup.object({
        //             entry: yup.string().required('This field is required!')
        //         }),
        //     )
    })

    const formik = useFormik({
        initialValues: outComesData,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })

    /** Auto scroll into position of element whose validation does not meet */
    const validateForm = (errors) => {
        if (!isEmpty(errors)) {
            const field = Object.keys(errors)[0]
            let el

            if (Array.isArray(errors[field])) {
                const errorIndex = errors[field].findIndex((error) => !!error)

                el = formRef.current.querySelector(`[name="${field}_${errorIndex}"]`)
            } else el = formRef.current.querySelector(`[name="${field}"]`)

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
    }
    //heading title
    const headingTitle = () => {
        const pageTitle =
            formik.values.id
                ? 'Update Outcome'
                : 'Create Outcome'

        return <h1 className="page-title">{pageTitle}</h1>
    }
    const setValueForEdit = async(id) =>{
        try{
            setIsLoading(true)
            const res = await allOutComeList({id}) 
            const newEntry = res?.data?.entry 
            while (newEntry.length < 4) {
                newEntry.push({ entry: '' });
            }
            setOutcomesData({
                id: res?.data?.id || '',
                name: res?.data?.name || "",
                specialty_id: res?.data?.specialty_id || "",
                clinical_note_template_id: res?.data?.clinical_note_template_id || "",
                entry: newEntry || []

            })
            setIsLoading(false)
        }catch(e){
            setIsLoading(false)
            console.log(e)
        }
    }
    useEffect(()=>{
        if(location?.state?.id){
            setIsLoading(true)
        }
        if(location?.state?.id && !loadingForNoteType && !loadingForSpeciality){
            setValueForEdit(location.state.id)
        }
    },[location,loadingForNoteType,loadingForSpeciality])

    useEffect(() => {
        const abortController = new AbortController()
        getListSpecialtiesOptions()
        getClinicalNote()
        return () => abortController.abort()
    }, [])
    if (isLoading) {
        return <LoadingPage />
    }

    return (
        <div ref={formRef} style={{ height: '100%' }}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    onClick={() => (goBack())}
                    style={{
                        cursor: 'pointer',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>{'Back to Outcomes list'}</Typography>
                </Grid>
                <Grid container className={`border-bottom`}>
                    {headingTitle()}
                </Grid>

                <Grid container className={classes.form}>
                        <Grid item xs={12}>
                            <fieldset className="divider-information">
                                <legend>
                                    <Typography className={'heading1-title'} style={{fontSize:"18px"}}>
                                        Patient Outcome Selections
                                    </Typography>
                                </legend>
                                <Grid container item alignItems="flex-start" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Outcome Title"
                                            required
                                            name="name"
                                            value={formik.values.name}
                                            placeholder="Enter title of outcome"
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.first_name}
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    'name',
                                                    value,
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="flex-start" spacing={2}>
                                    <Grid item xs={3}>
                                        <Select
                                            resetData
                                            label="Specialty"
                                            required
                                            name="specialty_id"
                                            placeholder="Select specialty"
                                            value={formik.values.specialty_id}
                                            options={specialityOptions}
                                            error={formik.touched.specialty_id && Boolean(formik.errors.specialty_id)}
                                            helperText={formik.touched.specialty_id && formik.errors.specialty_id}
                                            selectProps={{
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                            onChange={(value) =>
                                                formik.setFieldValue('specialty_id', value)
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            resetData
                                            label="Note Type"
                                            required
                                            name="note_type_id"
                                            placeholder="Select note type"
                                            value={formik.values.clinical_note_template_id}
                                            options={clinicalNoteOptions}
                                            error={formik.touched.clinical_note_template_id && Boolean(formik.errors.clinical_note_template_id)}
                                            helperText={formik.touched.clinical_note_template_id && formik.errors.clinical_note_template_id}
                                            selectProps={{
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                            onChange={(value) =>
                                                formik.setFieldValue('clinical_note_template_id', value)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                {formik.values.entry.length && formik.values.entry.map((item,index)=>{
                                    return (
                                        <div style={{ padding: '0px 9px 0px 9px' }}>
                                            <Grid
                                                key={index}
                                                container
                                                item
                                                alignItems="flex-start"
                                                spacing={2}
                                                style={{ marginTop: '20px' }}
                                                className="modifier_top_state">
                                                <Grid item xs={11}>
                                                    <TextBox
                                                        name="entry"
                                                        placeholder={`Create entry ${index+1}`}
                                                        value={item.entry}
                                                        onChange={(value) => {
                                                            const updatedEntry = formik?.values?.entry.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    entry: idx === index ? value : item.entry,
                                                                }),
                                                            )
                                                            formik.setFieldValue('entry', updatedEntry)
                                                        }}
                                                        // error={
                                                        //     (formik.touched.entry || [])[index]?.entry &&
                                                        //     Boolean((formik.errors.entry || [])[index])
                                                        // }
                                                        // helperText={
                                                        //     (formik.touched.entry || [])[index]?.entry &&
                                                        //     (formik.errors.entry || [])[index]?.entry
                                                        // }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={1}
                                                    className="d-flex align-flex-start justify-end">
                                                    <Grid item style={{ paddingLeft: '5px' }}>
                                                        <Divider
                                                            variant="middle"
                                                            flexItem
                                                            orientation="vertical"
                                                            className="divider_selectBoxTaxonomy"
                                                        />
                                                    </Grid>
                                                    <IconButton
                                                        style={{
                                                            paddingLeft: '20px',
                                                            marginTop: 8,
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.entry.length > 1) {
                                                                formik.setFieldValue(
                                                                    'entry',
                                                                    formik.values.entry.filter(
                                                                        (_, idx) => idx !== index,
                                                                    ),
                                                                )
                                                            } else {
                                                                notification(
                                                                    `Please create 1 entry to continue`,
                                                                    'info',
                                                                )
                                                            }
                                                        }}
                                                    >
                                                        <TrashIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                })}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        whiteSpace: 'nowrap',
                                        marginTop: '15px',
                                    }}
                                    onClick={() => {
                                        formik.setFieldValue('entry', [...formik.values.entry, { entry: '' }])
                                    }}
                                    >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}>
                                        <AddCircleOutlineOutlined
                                            style={{
                                                color: '#15A452',
                                                fontSize: 18,
                                            }}
                                        />
                                        <Typography
                                            className='add-button'
                                            style={{
                                                color: '#15A452',
                                                marginLeft: 8,
                                            }}>
                                           Add New Entry
                                        </Typography>
                                    </div>
                                </div>
                            </fieldset>
                        </Grid>
                </Grid>
                <Grid container style={{borderTop:'1px solid #CFCFCF'}}>
                    <Footer
                        style={{
                            marginTop: '1.5rem',
                        }}
                        loading={isSubmitting}
                        onCancel={goBack}
                        okBtnText={'Save'}
                        onOk={() => {
                            formik.handleSubmit()
                            validateForm(formik.errors)
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default createPatientOutcomes
