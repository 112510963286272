export const INITIAL_VALUES = {
    id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    suffix: '',
    previous_last_name: '',
    previous_first_name: '',
    previous_middle_name: '',
    dob: '',
    photo: '',
    gender: '',
    clinical_practice_id: '',
    zip: '',
    address1: '',
    address2: '',
    email: '',
    city: '',
    state: '',
    phone_number: '',
    date_of_death: '',
    emergency_contacts: [],
    external_id: '',
    rcopia_id: '',
    referring_provider: '',
    referring_location: '',
    sexual_orientation: {
        code: '',
        display: '',
    },
    race: {
        code: '',
        display: '',
    },
    ethnicity: {
        code: '',
        display: '',
    },
    tribal_affiliation: {
        code: '',
        display: '',
    },
    gender_identity: {
        code: '',
        display: '',
    },
    occupation: {
        code: '',
        display: '',
    },
    occupation_industry: {
        code: '',
        display: '',
    },
    language: {
        code: '',
        display: '',
    },
    primary_language: {
        code: '',
        display: '',
    },
    other_language: '',
    smoking_status: '',
    smoking_start_date: '',
    smoking_end_date: '',
    patient_status: '',
    is_push_referral: false,
    is_test_patient: false,
    timezone: '',
    access_patient_portal: false,
    secondary_phone_number: '',
    primary_care_physician_phone: '',
    primary_care_physician_fax: '',
    shared_phone: false,
    shared_secondary_phone: false,
    shared_email: false,

}
export const INSURANCE_INITIAL_VALUES = {
    status: 'active',
    type: '',
    insurance: {
        plan: {
            code: '',
            display: '',
        },
        policy_no: '',
        group_name: '',
        group_number: '',
        phone: '',
        copay_amount: '',
        deductible_amount: '',
        policy_effective: '',
        policy_end: '',
        patient_relationship: '',
        policy_emp: '',
        notes: [],
        relation_info: {
            first_name: '',
            last_name: '',
            insured_number: '',
            ssn: '',
            dob: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zip: '',
        },
        deductible: '',
        deductible_met: '',
    },
    payment_method: {
        code: 'Insurance',
        display: 'Insurance',
    },
    note: '',
    noteView: 'no-view',
}
export const RACE = [
    {
        code: 'American Indian or Alaska Native',
        text: 'American Indian or Alaska Native',
    },
    { code: 'Asian', text: 'Asian' },
    { code: 'Black or African American', text: 'Black or African American' },
    {
        code: 'Native Hawaiian or Other Pacific Islander',
        text: 'Native Hawaiian or Other Pacific Islander',
    },
    { code: 'White', text: 'White' },
    { code: 'Patient Declined', text: 'Patient Declined' },
]

export const ETHNICITY = [
    { code: 'Hispanic or Latino', text: 'Hispanic or Latino' },
    { code: 'Not Hispanic or Latino', text: 'Not Hispanic or Latino' },
    { code: 'Patient Declined', text: 'Patient Declined' },
]

export const SMOKING_STATUS = [
    {
        code: '428071000124103',
        text: 'Current Heavy tobacco smoker',
    },
    {
        code: '428061000124105',
        text: 'Current Light tobacco smoker',
    },
    {
        code: '428041000124106',
        text: 'Current some day smoker',
    },
    { code: '8517006', text: 'Ex-smoker (finding)' },
    {
        code: '266919005',
        text: 'Never smoked tobacco (finding)',
    },
    { code: '77176002', text: 'Smoker (finding)' },
    {
        code: '449868002',
        text: 'Smokes tobacco daily (finding)',
    },
    {
        code: '266927001',
        text: 'Tobacco smoking consumption unknown (finding)',
    },
]

export const SEX_AT_BIRTH = [
    { code: 'female', text: 'Female' },
    { code: 'male', text: 'Male' },
    { code: 'unknown', text: 'Unknown' },
]

export const SEXUAL_ORIENTATION = [
    {
        code: '38628009',
        text: 'Lesbian, gay or homosexual',
    },
    { code: '20430005', text: 'Straight or heterosexual' },
    { code: '42035005', text: 'Bisexual' },
    {
        code: 'OTH',
        text: 'Something else, please describe',
    },
    { code: 'UNK', text: "Don't know" },
    { code: 'ASKU', text: 'Choose not to disclose' },
]

export const GENDER_IDENTITY = [
    { code: '446151000124109', text: 'Male', pronouns: 'He/him/his' },
    { code: '446141000124107', text: 'Female', pronouns: 'She/her/hers' },
    {
        code: '407377005',
        text: 'Female-to-Male (FTM)/Transgender Male/Trans Man',
        pronouns: 'He/him/his'
    },
    {
        code: '407376001',
        text: 'Male-to-Female (MTF)/Transgender Female/Trans Woman',
        pronouns: 'She/her/hers'
    },
    {
        code: '446131000124102',
        text: 'Genderqueer, neither exclusively male nor female',
        pronouns: 'They/them/their'
    },
    {
        code: 'OTH',
        text: 'Additional gender category or other, please specify',
        pronouns: 'They/them/their'
    },
    { code: 'ASKU', text: 'Choose not to disclose', pronouns: 'They/them/their' },
    { code: 'OTHER', text: 'Other' },
]

export const OCCUPATION = [
    {
        code: '53-4031.00.038615',
        text: 'Sleeping Car Conductor [Railroad Conductors and Yardmasters]',
    },
    {
        code: '39-6011.00.038616',
        text: 'Sleeping Car Porter [Baggage Porters and Bellhops]',
    },
    {
        code: '53-6061.00.038617',
        text: 'Sleeping Car Service Attendant [Transportation Attendants, Except Flight Attendants]',
    },
    {
        code: '51-6031.00.038618',
        text: 'Sleeve Fixer [Sewing Machine Operators]',
    },
    {
        code: '51-6021.00.038619',
        text: 'Sleeve Ironer [Pressers, Textile, Garment, and Related Materials]',
    },
    {
        code: '51-6031.00.038620',
        text: 'Sleeve Maker [Sewing Machine Operators]',
    },
    {
        code: '51-6021.00.038621',
        text: 'Sleeve Presser Operator [Pressers, Textile, Garment, and Related Materials]',
    },
    {
        code: '51-6031.00.038622',
        text: 'Sleeve Setter [Sewing Machine Operators]',
    },
    {
        code: '51-6031.00.038623',
        text: 'Sleeve Sewer [Sewing Machine Operators]',
    },
    {
        code: '51-6021.00.038624',
        text: 'Sleever [Pressers, Textile, Garment, and Related Materials]',
    },
    {
        code: '51-9199.00.038624',
        text: 'Sleever [Production Workers, All Other]',
    },
    {
        code: '51-6031.00.038624',
        text: 'Sleever [Sewing Machine Operators]',
    },
    {
        code: '51-9032.00.038625',
        text: 'Slice Plug Cutter Operator [Cutting and Slicing Machine Setters, Operators, and Tenders]',
    },
    {
        code: '51-9198.00.038626',
        text: 'Slice Plug Cutter Operator Helper [Helpers--Production Workers]',
    },
    {
        code: '51-3021.00.038627',
        text: 'Slicer [Butchers and Meat Cutters]',
    },
    {
        code: '35-2021.00.038627',
        text: 'Slicer [Food Preparation Workers]',
    },
    {
        code: '51-9032.00.038628',
        text: 'Slicing Machine Operator [Cutting and Slicing Machine Setters, Operators, and Tenders]',
    },
    {
        code: '51-9032.00.038629',
        text: 'Slicing Machine Operator/Tender [Cutting and Slicing Machine Setters, Operators, and Tenders]',
    },
    {
        code: '51-7042.00.038629',
        text: 'Slicing Machine Operator/Tender [Woodworking Machine Setters, Operators, and Tenders, Except Sawing]',
    },
    {
        code: '53-7063.00.038630',
        text: 'Slicing Machine Tender [Machine Feeders and Offbearers]',
    },
    {
        code: '51-7042.00.038630',
        text: 'Slicing Machine Tender [Woodworking Machine Setters, Operators, and Tenders, Except Sawing]',
    },
    {
        code: '51-9199.00.038631',
        text: 'Slicker [Production Workers, All Other]',
    },
    {
        code: '51-9199.00.038632',
        text: 'Slide Fastener Chain Assembler [Production Workers, All Other]',
    },
    {
        code: '51-9199.00.038633',
        text: 'Slide Fastener Repairer [Production Workers, All Other]',
    },
    {
        code: '51-9061.00.038634',
        text: 'Slide Fasteners Inspector [Inspectors, Testers, Sorters, Samplers, and Weighers]',
    },
    {
        code: '51-9196.00.038635',
        text: 'Slide Machine Tender [Paper Goods Machine Setters, Operators, and Tenders]',
    },
    {
        code: '51-9199.00.038635',
        text: 'Slide Machine Tender [Production Workers, All Other]',
    },
    {
        code: '51-9151.00.038636',
        text: 'Slide Maker [Photographic Process Workers and Processing Machine Operators]',
    },
    {
        code: '51-9199.00.038637',
        text: 'Slider Assembler [Production Workers, All Other]',
    },
    {
        code: '51-9199.00.038638',
        text: 'Sliding Joint Maker [Production Workers, All Other]',
    },
]

export const OCCUPATION_INDUSTRY = [
    {
        code: '452990.010002',
        text: 'Limited price variety stores [All Other General Merchandise Stores ]',
    },
    {
        code: '212210.010003',
        text: 'Limonite mining and/or beneficiating [Iron Ore Mining]',
    },
    {
        code: '532111.010004',
        text: 'Limousine rental without driver [Passenger Car Rental ]',
    },
    {
        code: '485320.010005',
        text: 'Limousine services (except shuttle services) [Limousine Service]',
    },
    {
        code: '485320.010006',
        text: 'Limousines for hire with driver (except taxis) [Limousine Service]',
    },
    {
        code: '325320.010007',
        text: 'Lindane pesticides manufacturing [Pesticide and Other Agricultural Chemical Manufacturing]',
    },
    {
        code: '334512.010008',
        text: 'Line or limit control for electric heat manufacturing [Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use ]',
    },
    {
        code: '561730.010009',
        text: 'Line slash (i.e., rights of way) maintenance services [Landscaping Services]',
    },
    {
        code: '238910.010010',
        text: 'Line slashing or cutting (except maintenance) [Site Preparation Contractors]',
    },
    {
        code: '335311.010011',
        text: 'Line voltage regulators (i.e., electric transformers) manufacturing [Power, Distribution, and Specialty Transformer Manufacturing ]',
    },
    {
        code: '335999.010012',
        text: 'Linear accelerators manufacturing [All Other Miscellaneous Electrical Equipment and Component Manufacturing ]',
    },
    {
        code: '332991.010013',
        text: 'Linear ball bearings manufacturing [Ball and Roller Bearing Manufacturing]',
    },
    {
        code: '334514.010014',
        text: 'Linear counters manufacturing [Totalizing Fluid Meter and Counting Device Manufacturing ]',
    },
    {
        code: '325220.010015',
        text: 'Linear esters fibers and filaments manufacturing [Artificial and Synthetic Fibers and Filaments Manufacturing]',
    },
    {
        code: '332991.010016',
        text: 'Linear roller bearings manufacturing [Ball and Roller Bearing Manufacturing]',
    },
    {
        code: '442299.010017',
        text: 'Linen stores [All Other Home Furnishings Stores ]',
    },
    {
        code: '812331.010018',
        text: 'Linen supply services [Linen Supply ]',
    },
    {
        code: '423220.010019',
        text: 'Linens (e.g., bath, bed, table) merchant wholesalers [Home Furnishing Merchant Wholesalers ]',
    },
    {
        code: '314120.010020',
        text: 'Linens made from purchased materials [Curtain and Linen Mills]',
    },
    {
        code: '327120.010021',
        text: 'Liner brick and plates, vitrified clay, manufacturing [Clay Building Material and Refractories Manufacturing ]',
    },
]

export const PREFFERED_LANGUAGE_FULL = [
    { code: 'Arabic', text: 'Arabic' },
    { code: 'Bengali', text: 'Bengali' },
    { code: 'Czech', text: 'Czech' },
    { code: 'Danish', text: 'Danish' },
    { code: 'German', text: 'German' },
    // { code: 'de-AT', text: 'German (Austria)' },
    // { code: 'de-CH', text: 'German (Switzerland)' },
    // { code: 'de-DE', text: 'German (Germany)' },
    { code: 'Greek', text: 'Greek' },
    { code: 'English', text: 'English' },
    // { code: 'en-AU', text: 'English (Australia)' },
    // { code: 'en-CA', text: 'English (Canada)' },
    // { code: 'en-GB', text: 'English (Great Britain)' },
    // { code: 'en-IN', text: 'English (India)' },
    // { code: 'en-NZ', text: 'English (New Zealand)' },
    // { code: 'en-SG', text: 'English (Singapore)' },
    // { code: 'en-US', text: 'English (United States)' },
    { code: 'Spanish', text: 'Spanish' },
    // { code: 'es-AR', text: 'Spanish (Argentina)' },
    // { code: 'es-ES', text: 'Spanish (Spain)' },
    // { code: 'es-UY', text: 'Spanish (Uruguay)' },
    { code: 'Finnish', text: 'Finnish' },
    { code: 'French', text: 'French' },
    // { code: 'fr-BE', text: 'French (Belgium)' },
    // { code: 'fr-CH', text: 'French (Switzerland)' },
    // { code: 'fr-FR', text: 'French (France)' },
    // { code: 'fr-CA', text: 'French (Canada)' },
    { code: 'Frysian', text: 'Frysian' },
    // { code: 'fy-NL', text: 'Frysian (Netherlands)' },
    { code: 'Hindi', text: 'Hindi' },
    { code: 'Croatian', text: 'Croatian' },
    { code: 'Italian', text: 'Italian' },
    // { code: 'it-CH', text: 'Italian (Switzerland)' },
    // { code: 'it-IT', text: 'Italian (Italy)' },
    { code: 'Japanese', text: 'Japanese' },
    { code: 'Korean', text: 'Korean' },
    { code: 'Dutch', text: 'Dutch' },
    // { code: 'nl-BE', text: 'Dutch (Belgium)' },
    // { code: 'nl-NL', text: 'Dutch (Netherlands)' },
    { code: 'Norwegian', text: 'Norwegian' },
    // { code: 'no-NO', text: 'Norwegian (Norway)' },
    { code: 'Punjabi', text: 'Punjabi' },
    { code: 'Polish', text: 'Polish' },
    { code: 'Portuguese', text: 'Portuguese' },
    // { code: 'pt-BR', text: 'Portuguese (Brazil)' },
    { code: 'Russian', text: 'Russian' },
    // { code: 'ru-RU', text: 'Russian (Russia)' },
    { code: 'Serbian', text: 'Serbian' },
    // { code: 'sr-RS', text: 'Serbian (Serbia)' },
    { code: 'Swedish', text: 'Swedish' },
    // { code: 'sv-SE', text: 'Swedish (Sweden)' },
    { code: 'Telegu', text: 'Telegu' },
    { code: 'Chinese', text: 'Chinese' },
    // { code: 'zh-CN', text: 'Chinese (China)' },
    // { code: 'zh-HK', text: 'Chinese (Hong Kong)' },
    // { code: 'zh-SG', text: 'Chinese (Singapore)' },
    // { code: 'zh-TW', text: 'Chinese (Taiwan)' },
].sort((p, q) => p.text.localeCompare(q.text))

export const PREFFERED_LANGUAGE = [
    { code: 'ar', text: 'Arabic' },
    { code: 'bn', text: 'Bengali' },
    { code: 'cs', text: 'Czech' },
    { code: 'da', text: 'Danish' },
    { code: 'de', text: 'German' },
    { code: 'el', text: 'Greek' },
    { code: 'en', text: 'English' },
    { code: 'es', text: 'Spanish' },
    { code: 'fi', text: 'Finnish' },
    { code: 'fr', text: 'French' },
    { code: 'fy', text: 'Frysian' },
    { code: 'hi', text: 'Hindi' },
    { code: 'hr', text: 'Croatian' },
    { code: 'it', text: 'Italian' },
    { code: 'ja', text: 'Japanese' },
    { code: 'ko', text: 'Korean' },
    { code: 'nl', text: 'Dutch' },
    { code: 'no', text: 'Norwegian' },
    { code: 'pa', text: 'Punjabi' },
    { code: 'pl', text: 'Polish' },
    { code: 'pt', text: 'Portuguese' },
    { code: 'ru', text: 'Russian' },
    { code: 'sr', text: 'Serbian' },
    { code: 'sv', text: 'Swedish' },
    { code: 'te', text: 'Telegu' },
    { code: 'zh', text: 'Chinese' },
]

export const PRIMARY_LANGUAGE = [
    { code: 'en', text: 'English' },
    { code: 'es', text: 'Spanish' },
    { code: 'ot', text: 'Other' },
]
export const paymentMethodOptions = [
    { label: 'Insurance', value: 'Insurance', name: 'Insurance' },
    { label: 'Self Pay', value: 'Self Pay', name: 'Self Pay' },
]
export const patientsRelationship = [
    {
        key: 'Self',
        value: 'Self',
    },
    {
        key: 'Child',
        value: 'Child',
    },
    {
        key: 'Spouse',
        value: 'Spouse',
    },
    {
        key: 'Other',
        value: 'Other',
    },
]
