import { Grid, Typography } from '@material-ui/core'
import { GridColumns } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import { Button as ButtonCustom } from 'components/custom'
import Routes from 'constants/routes'
import { DataGrid } from 'shared/elements/DataGrid'
import PatientInfor from '../PatientInfor'

import { DataGridCell, getPatientName } from 'containers/PatientsSetting/shared'
import { getDocumentList } from 'services/Documents'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'
import { useStyles } from '../MedicalProblems/style'

import { Document, ErrorType } from '../../interfaces'
import { PsychotherapyNoteForm } from './PsychotherapyNoteForm'
import { getPsychotherapies } from 'containers/ClinicalNotes/api/services'
import moment from 'moment'
import { BreakTheGlassForm } from './BreakTheGlassForm'

const PsychotherapyNote = () => {
    const classes = useStyles()

    const history = useHistory()
    const notification = useNotification()
    const { id: patientId } = useParams<{ id: string }>()

    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })

    const hasPermission = useSelector((state: PermissionState) =>
        state.permission?.patient_accounts.find(
            (item) => item === 'view_psychotherapy_process_note',
        ),
    )
    const providerId = useSelector((state) => {
        return state.login.userData['custom:user_id']
    })

    const [formOpen, setFormOpen] = useState(false)
    const [disabledForm, setDisabledForm] = useState(false)
    const [formBreakTheGlassOpen, setFormBreakTheGlassOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [documents, setDocuments] = useState<Document[]>([])

    const handlePageChange = (page: number) =>
        setPaginationData({
            ...paginationData,
            start: page,
        })

    const getPsychotherapiesData = async () => {
        setLoading(true)

        try {
            const resp = await getPsychotherapies({
                page: paginationData.start,
                size: paginationData.limit,
                patient_id: patientId,
            })

            setDocuments(resp.data.data)
            setPaginationData(resp.data.paging)
        } catch (error) {
            notification(error?.data?.message || 'Cannot get list psychotherapy notes', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleRefresh = () => {
        getPsychotherapiesData()
    }

    useEffect(() => {
        const abortController = new AbortController()
        getPsychotherapiesData()

        return () => abortController.abort()
    }, [paginationData.start])

    const columns = [
        {
            headerName: 'No.',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.5,
            sortable: true,
            // valueGetter: ({ row }) => row.document_name,
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell
                    value={<Typography style={{ color: '#0076FC' }}>{row.id || 'N/A'}</Typography>}
                />
            ),
        },
        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'document_type',
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.document_type || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell value={row?.practitioner_first_name + row?.practitioner_last_name} />
            ),
        },
        {
            headerName: 'Encounter',
            headerClassName: 'super-app-theme--header',
            field: 'source_type',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.source_type || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => <DataGridCell value={row.encounter_time} />,
        },
        {
            headerName: 'Created At',
            headerClassName: 'super-app-theme--header',
            field: 'associated_patient_first_name',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.associated_patient_first_name || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell value={moment(row.createdAt).format('DD MMM YYYY hh:mm a')} />
            ),
        },
        {
            headerName: 'Updated at',
            headerClassName: 'super-app-theme--header',
            field: 'encounter',
            // width: 100,
            flex: 1,
            sortable: true,
            // valueGetter: ({ row }) => row.encounter || '',
            // sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => (
                <DataGridCell value={moment(row.updatedAt).format('DD MMM YYYY hh:mm a')} />
            ),
        },

        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => {
                return (
                    <Typography
                        className={classes.view_detail}
                        onClick={() => {
                            if (hasPermission) {
                                if (providerId === row?.practitioner_id) {
                                    setFormOpen(row.id)
                                } else {
                                    setFormBreakTheGlassOpen(row.id)
                                }
                            } else {
                                notification(
                                    'You do not have permission to view Psychotherapy',
                                    'error',
                                )
                            }
                        }}>
                        View Detail
                    </Typography>
                )
            },
        },
    ]

    return (
        <>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    // onClick={() => history.replace(Routes.PATIENT.DASHBOARD.replace(':id', patientId))}
                    onClick={() => history.goBack()}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>

                <Grid container style={{ marginTop: 12 }}>
                    <Grid item xs={12} container alignItems="center">
                        <Grid container justifyContent="space-between">
                            <Typography className={classes.header}>
                                Psychotherapy Note List
                            </Typography>

                            <Grid item xs={8}>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    spacing={2}
                                    wrap={'nowrap'}>
                                    <Grid item className="d-flex align-center">
                                        <ButtonCustom
                                            tooltip="Upload New Document"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setFormOpen(true)}
                                            fullWidth>
                                            <AddIcon
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </ButtonCustom>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>

                    <DataGrid
                        loading={loading}
                        columns={columns}
                        rows={documents}
                        pageSize={paginationData.limit}
                        totalRecords={paginationData.total}
                        onPageChange={handlePageChange}
                    />
                </Grid>
            </Grid>

            {formOpen && (
                <PsychotherapyNoteForm
                    open={formOpen}
                    id={typeof formOpen !== 'boolean' ? formOpen : false}
                    disabled={disabledForm}
                    patientId={patientId}
                    refresh={handleRefresh}
                    onCancel={() => {
                        setFormOpen(false)
                        setDisabledForm(false)
                    }}
                />
            )}
            {Boolean(formBreakTheGlassOpen) && (
                <BreakTheGlassForm
                    open={Boolean(formBreakTheGlassOpen)}
                    id={formBreakTheGlassOpen}
                    onCancel={() => {
                        setFormBreakTheGlassOpen(false)
                    }}
                    onEditPsycho={() => {
                        setDisabledForm(true)
                        setFormOpen(formBreakTheGlassOpen)
                        setFormBreakTheGlassOpen(false)
                    }}
                />
            )}
        </>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(PsychotherapyNote)
