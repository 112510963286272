const NoDataIcon = () => {
    return (
        <svg
            width="133"
            height="100"
            viewBox="0 0 133 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M60.838 27.8773C63.1766 25.544 65.8732 23.7897 68.748 22.5958C68.748 22.5943 68.7484 22.5928 68.7484 22.5916V7.53055C68.7484 3.37143 65.3694 0 61.201 0H8.36961C4.20121 0 0.822266 3.37143 0.822266 7.53055V22.5916C0.822266 26.7508 4.20121 30.1222 8.36961 30.1222H58.8697C59.4784 29.3469 60.1229 28.5912 60.838 27.8773Z"
                fill="#9CA9B8"
            />
            <path
                d="M10.2562 15.0611C13.3824 15.0611 15.9167 12.5324 15.9167 9.41318C15.9167 6.29393 13.3824 3.76527 10.2562 3.76527C7.13 3.76527 4.5957 6.29393 4.5957 9.41318C4.5957 12.5324 7.13 15.0611 10.2562 15.0611Z"
                fill="#D9DDE3"
            />
            <path
                d="M55.5403 9.41281H21.5773C20.5354 9.41281 19.6904 8.56976 19.6904 7.53017C19.6904 6.49058 20.5354 5.64753 21.5773 5.64753H55.5403C56.5822 5.64753 57.4272 6.49058 57.4272 7.53017C57.4272 8.56976 56.5822 9.41281 55.5403 9.41281Z"
                fill="#D9DDE3"
            />
            <path
                d="M63.0877 16.9433H21.5773C20.5354 16.9433 19.6904 16.1007 19.6904 15.0607C19.6904 14.0211 20.5354 13.1781 21.5773 13.1781H63.0877C64.1296 13.1781 64.9745 14.0211 64.9745 15.0607C64.9745 16.1007 64.1296 16.9433 63.0877 16.9433Z"
                fill="#D9DDE3"
            />
            <path
                d="M46.1061 24.4739H21.5773C20.5354 24.4739 19.6904 23.6309 19.6904 22.5913C19.6904 21.5517 20.5354 20.7086 21.5773 20.7086H46.1061C47.148 20.7086 47.993 21.5517 47.993 22.5913C47.993 23.6309 47.148 24.4739 46.1061 24.4739Z"
                fill="#D9DDE3"
            />
            <path
                d="M80.0692 64.0096H27.2378C23.0694 64.0096 19.6904 60.6378 19.6904 56.4791V41.418C19.6904 37.2589 23.0694 33.8875 27.2378 33.8875H80.0692C84.2376 33.8875 87.6165 37.2589 87.6165 41.418V56.4791C87.6165 60.6378 84.2376 64.0096 80.0692 64.0096Z"
                fill="#E9ECF1"
            />
            <path
                d="M29.1244 48.9485C32.2506 48.9485 34.7849 46.4199 34.7849 43.3006C34.7849 40.1814 32.2506 37.6527 29.1244 37.6527C25.9982 37.6527 23.4639 40.1814 23.4639 43.3006C23.4639 46.4199 25.9982 48.9485 29.1244 48.9485Z"
                fill="#D9DDE3"
            />
            <path
                d="M74.4085 43.3003H40.4454C39.4035 43.3003 38.5586 42.4572 38.5586 41.4176C38.5586 40.378 39.4035 39.535 40.4454 39.535H74.4085C75.4504 39.535 76.2953 40.378 76.2953 41.4176C76.2953 42.4572 75.4504 43.3003 74.4085 43.3003Z"
                fill="#D9DDE3"
            />
            <path
                d="M81.9558 50.8308H40.4454C39.4035 50.8308 38.5586 49.9878 38.5586 48.9482C38.5586 47.9086 39.4035 47.0655 40.4454 47.0655H81.9558C82.9977 47.0655 83.8427 47.9086 83.8427 48.9482C83.8427 49.9878 82.9977 50.8308 81.9558 50.8308Z"
                fill="#D9DDE3"
            />
            <path
                d="M64.9743 58.3614H40.4454C39.4035 58.3614 38.5586 57.5183 38.5586 56.4787C38.5586 55.4391 39.4035 54.5961 40.4454 54.5961H64.9743C66.0162 54.5961 66.8611 55.4391 66.8611 56.4787C66.8611 57.5183 66.0162 58.3614 64.9743 58.3614Z"
                fill="#D9DDE3"
            />
            <path
                d="M61.201 97.8971H8.36961C4.20121 97.8971 0.822266 94.5257 0.822266 90.3665V75.3055C0.822266 71.1463 4.20121 67.7749 8.36961 67.7749H61.201C65.3691 67.7749 68.7484 71.1463 68.7484 75.3055V90.3665C68.7484 94.5257 65.3691 97.8971 61.201 97.8971Z"
                fill="#9CA9B8"
            />
            <path
                d="M10.2562 82.836C13.3824 82.836 15.9167 80.3073 15.9167 77.1881C15.9167 74.0688 13.3824 71.5402 10.2562 71.5402C7.13 71.5402 4.5957 74.0688 4.5957 77.1881C4.5957 80.3073 7.13 82.836 10.2562 82.836Z"
                fill="#D9DDE3"
            />
            <path
                d="M55.5403 77.1877H21.5773C20.5354 77.1877 19.6904 76.3447 19.6904 75.3051C19.6904 74.2655 20.5354 73.4224 21.5773 73.4224H55.5403C56.5822 73.4224 57.4272 74.2655 57.4272 75.3051C57.4272 76.3447 56.5822 77.1877 55.5403 77.1877Z"
                fill="#D9DDE3"
            />
            <path
                d="M63.0877 84.7183H21.5773C20.5354 84.7183 19.6904 83.8752 19.6904 82.8356C19.6904 81.796 20.5354 80.953 21.5773 80.953H63.0877C64.1296 80.953 64.9745 81.796 64.9745 82.8356C64.9745 83.8752 64.1296 84.7183 63.0877 84.7183Z"
                fill="#D9DDE3"
            />
            <path
                d="M46.1061 92.2488H21.5773C20.5354 92.2488 19.6904 91.4058 19.6904 90.3662C19.6904 89.3266 20.5354 88.4835 21.5773 88.4835H46.1061C47.148 88.4835 47.993 89.3266 47.993 90.3662C47.993 91.4058 47.148 92.2488 46.1061 92.2488Z"
                fill="#D9DDE3"
            />
            <path
                d="M129.967 97.7944C127.02 100.735 122.241 100.735 119.294 97.7944L103.284 81.8194C100.336 78.8788 100.336 74.1108 103.284 71.1697C106.231 68.2291 111.01 68.2291 113.957 71.1697L129.968 87.1443C132.915 90.0853 132.915 94.8537 129.967 97.7944Z"
                fill="#9CA9B8"
            />
            <path
                d="M129.967 97.7944C127.02 100.735 122.241 100.735 119.294 97.7944L103.284 81.8194C100.336 78.8788 100.336 74.1108 103.284 71.1697C106.231 68.2291 111.01 68.2291 113.957 71.1697L129.968 87.1443C132.915 90.0853 132.915 94.8537 129.967 97.7944Z"
                fill="black"
                fillOpacity="0.2"
            />
            <path
                d="M107.839 75.7152C106.366 77.1855 103.976 77.1855 102.503 75.7152L96.0606 69.2874C94.5866 67.8167 94.5866 65.4329 96.0606 63.9622C97.5342 62.4919 99.9233 62.4919 101.397 63.9622L107.839 70.3899C109.313 71.8606 109.313 74.2444 107.839 75.7152Z"
                fill="#D9DDE3"
            />
            <path
                d="M99.5297 23.8835C87.74 12.12 68.6252 12.12 56.8355 23.8835C45.0457 35.647 45.0457 54.7196 56.8355 66.4831C68.6252 78.2466 87.74 78.2466 99.5297 66.4831C111.319 54.7196 111.319 35.647 99.5297 23.8835ZM95.527 62.4889C85.9479 72.0467 70.4169 72.0467 60.8378 62.4889C51.2587 52.9311 51.2587 37.4347 60.8378 27.8773C70.4169 18.3192 85.9479 18.3192 95.527 27.8773C105.106 37.4351 105.106 52.9315 95.527 62.4889Z"
                fill="#D9DDE3"
            />
        </svg>
    )
}
export default NoDataIcon
