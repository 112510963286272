import React, { useEffect, useState } from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import { DataGrid, GridColumns, GridSortItem } from '@mui/x-data-grid'
import { connect, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import {
    getListMacros,
    getMacroById,
    createMacro,
    updateMacroById,
    deleteMacro,
} from 'services/Macros/macros'
import { NoRowsOverlayEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'

import { CreateMacrosDialog } from './CreateMacrosDialog'
import { Macros } from './interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    text_clinical_note: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    text_show_more: {
        color: 'rgba(0, 118, 252, 1)',
        marginLeft: 4,
        cursor: 'pointer',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const MacrosSettings = () => {
    const classes = useStyles()
    const notification = useNotification()

    // const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [macrosModal, setMacrosModal] = useState({
        status: false,
        itemId: null,
        item: null,
    })

    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const permissions = useSelector((state) => state?.permission?.clinical_notes)

    const handlePageChange = (value: number) => {
        setPage(value)
    }

    const macrosModalToggle = (row?: Macros) => {
        setMacrosModal({
            status: !macrosModal.status,
            itemId: row?.id,
            item: row,
        })
    }

    const columns: GridColumns<Macros> = [
        {
            headerName: <Typography variant="body1">Name</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value || 'N/A'
            },
        },
        {
            headerName: <Typography variant="body1">Description</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'description',
            flex: 3,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value || 'N/A'
            },
        },
        {
            headerName: <Typography variant="body1">Actions</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'id',
            width: 100,
            sortable: false,
            hide: !permissions.includes('modify_macro') && !permissions.includes('delete_macro'),
            renderCell: ({ row }) => {
                const menu = [] as any
                if (permissions.includes('modify_macro')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => macrosModalToggle(row),
                    })
                }
                if (permissions.includes('delete_macro')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDeleteMacro(row.id as number),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Macro?"
                    />
                )
            },
        },
    ]

    const handleSubmit = async (payload: Macros) => {
        try {
            setIsSubmitting(true)

            if (macrosModal?.itemId) {
                await updateMacroById(macrosModal?.itemId, payload)
                    .then(() => {
                        notification('A Macro was successfully updated!', 'success')
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message || 'A Macro was not successfully updated!',
                            'error',
                        )
                    })
            } else {
                await createMacro(payload)
                    .then(() => {
                        notification('A Macro was successfully added!', 'success')
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message || 'A Macro was not successfully added!',
                            'error',
                        )
                    })
            }

            macrosModalToggle()
            setIsSubmitting(false)
            refetchMacros()
        } catch (error) {
            console.log({ error })
            setIsSubmitting(false)
        }
    }

    const handleDeleteMacro = async (macroId: number) =>
        deleteMacro(macroId)
            .then(() => {
                refetchMacros()
                notification('A Macro was successfully deleted!', 'success')
            })
            .catch((error) => {
                notification(
                    error?.data?.message || 'A Macro was not successfully deleted!',
                    'error',
                )
            })

    const refetchMacros = async () => {
        setLoading(true)
        try {
            const res = await getListMacros(page)
            setPatientList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (err) {
            console.error('Cannot fetch macro', err)
        }

        setLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()
        refetchMacros()

        return () => abortController.abort()
    }, [page])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Macros</Typography>
                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_macro']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Macro"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => macrosModalToggle()}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id as number}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {macrosModal.status && (
                <CreateMacrosDialog
                    open={macrosModal.status}
                    macros={macrosModal.item}
                    loading={isSubmitting}
                    onClose={macrosModalToggle}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(MacrosSettings)
