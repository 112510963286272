import { Box, Button, ButtonGroup, Grid, Tab, Tabs, Typography, styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import copyNew from '../../../../assests/icons/copyNew.svg'
import TextWithMacrosNew from '../../reusable/TextWithMacrosNew'
import PhysicalForm from './PhysicalForm'
import VitalSigns from './VitalSignsV2'
import moment from 'moment'
import PreviousCNDrawer from 'components/ClinicalNotes/reusable/PreviousCNDrawer'
import PhysicalExamCopyComponent from './PhysicalExamCopyComponent'
const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
    },
    first_box: {
        // borderRadius: '5px',
        // border: '1px solid #EAECF0',
        // background: '#F8F9FB',
        // // height: 'auto',
        // // overflow: 'auto',
        // padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        // gap: 10
    },
    second_box: {
        borderRadius: '4px 4px 0px 0px',
        border: '1px solid #E0E0E0',
        padding: '10px',
        background: '#F8F9FB',
    },
    copy_button: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        background: '#FFF',
        fontSize: '12px',
        color: '#303E4E',
        fontWeight: '400',
        height: '26px',
        marginRight: '8px',
        padding: '4px 8px',
    },
    box_type: {
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '30px 20px 10px 20px',
    },
    divider: {
        background: 'transparent',
        width: '100%',
        // height: '1px',
    },
    DividerText: {
        color: 'var(--Light-Foreground-Foreground-2, #424242)',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    multisearch: {
        '&.MuiOutlinedInput-input': {
            padding: '0px 7px !important',
        },
    },
    SelectInputWapper:{
        '& .MuiAutocomplete-tag': {
            backgroundColor: '#fff !important',
            border:'1px solid #EAECF0'
        },
    },
    totalBtnGroup: {
        '.MuiButtonGroup-groupedTextHorizontal:not(:last-child)': {
            borderRight: '0px !important'
        }
    },
    totalBtn: {
        background: '#fff !important',
        color: '#303e4e !important',
        border: '1px solid #e0e0e0 !important',
        height: '30px !important'
    },
    totalBtnActive: {
        background: '#5571C6 !important',
        color: '#fff !important',
        height: '30px !important'
    }
})

const PhysicalExamTabs = styled(Tabs)({
    // borderBottom: '1px solid #e8e8e8',
    // margin: '0',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
        left: 0,
    },
    '& .MuiTabs-flexContainer': {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    '& .MuiTab-root': {
        minHeight: '32px !important',
    },
})

const PhysicalExamTab = styled(Tab)(({ theme }) => ({
    textTransform: 'unset !important',
    fontFamily: 'Rubik',
    fontSize: 14,
    backgroundColor: 'none',
    minWidth: 0,
    // minHeight: '400px',
    fontWeight: '400',
    marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    flex: 1,
    '&:hover': {
        color: '#242424',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#242424',
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

const defaultTotals = [7, 14, 30, 60, 90, 365]

const physicalExamItems = ['general', 'heent', 'skin', 'neck', 'cardiovascular', 'lungs', 'abdomen', 'msk', 'breasts', 'neuro', 'exterminates', 'narrative']

const PhysicalExam = (props) => {
    const classes = useStyles()
    const [total, setTotal] = React.useState(60)
    const [copyModalOpen, setCopyModalOpen] = React.useState(false)
    const [copySelectionFieldNames, setCopySelectionFieldNames] = React.useState([])
    const [selectAllCheck, setSelectAllCheck] = React.useState(false)
    const [copiedText, setCopiedText] = React.useState(props?.values?.copiedText ? true : false)

    const handleCloseDrawer = () => {
        setCopyModalOpen(false)
        setCopySelectionFieldNames([])
        setSelectAllCheck(false)
    }

    const handleChangecopy = () => {
        copySelectionFieldNames?.map(selection => {
            props?.onChange(`${props?.fieldName}.physical_exam.${selection}`, props?.lastNarrative[selection])
        })
        handleCloseDrawer()
        setCopiedText(true)
        props?.onChange(`${props?.fieldName}.copiedDate`, moment(props.copyDate).format('MM/DD/YYYY'))
    }

    const handleUnsetCopiedText = () => {
        setCopiedText(false)
        props?.onChange(`${props?.fieldName}.copiedDate`, null)
    }

    const handleSetSelectedValues = (selections) => {
        setCopySelectionFieldNames([...selections])
        if(selections?.length === physicalExamItems?.length)
            setSelectAllCheck(true)
        else
            setSelectAllCheck(false)
    }

    const handleSelectAllChecks = (checkedValue) => {
        setSelectAllCheck(checkedValue)
        if(checkedValue)
            setCopySelectionFieldNames(physicalExamItems)
        else
            setCopySelectionFieldNames([])
    }

    return (
        <Grid item className={classes.first_box}>
            {/* <PreviousCNDrawer
                open={copyModalOpen}
                handleClose={handleCloseDrawer}
                categoryName={'Physical Exam'}
                lastNarrative={props?.lastNarrative}
                lastUpdateDate={props?.copyDate}
                // narrativeText={'Please enter the patient’s history of present illness'}
                handleChangecopy={handleChangecopy}
                fieldName={props?.fieldName}
                values={props?.lastNarrative}
            /> */}
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.second_box} style={{ borderRadius: 4 }}>
                <Typography className={classes.narrative}>Vitals</Typography>

                <ButtonGroup variant="text" aria-label="Basic button group">
                    {defaultTotals?.map((totalItem) => {
                        return (
                            <Button
                                onClick={() => setTotal(totalItem)}
                                key={totalItem}
                                className={`${total === totalItem ? classes.totalBtnActive : classes.totalBtn}`}>
                                {totalItem === 365 ? '1 year' : `${totalItem} days`} 
                            </Button>
                        )
                    })}
                </ButtonGroup>

                <Button
                    onClick={() => setCopyModalOpen(true)}
                    disabled={!props?.lastNarrative || props?.nonEditable}
                    startIcon={<img src={copyNew} alt="" style={{ height: '9px', width: '7px' }} />}
                    className={classes.copy_button}>
                    {' '}
                    Copy: {props?.copyDate ? moment(props?.copyDate)?.format('MM/DD/YYYY') : ''}
                </Button>
            </Grid>

            <Box className={classes.box_type} style={{ marginBottom: 50, border: 0, padding: '30px 0px 10px 0px' }}>
                <VitalSigns classes={classes} total={total} {...props}/>
            </Box>

            <Grid
                container
                // justifyContent="space-between"
                alignItems="center"
                style={{ gap: 10 }}
                className={classes.second_box}>
                <Typography className={classes.narrative}>Physical Exam</Typography>
                {copiedText && <Typography>
                    Copied from {props?.copyDate ? moment(props?.copyDate)?.format('MM/DD/YYYY') : ''}
                </Typography>}
            </Grid>

            <Box className={classes.box_type} style={{ paddingTop: 60, paddingBottom: 60 }}>
                <PhysicalForm setCopiedText={handleUnsetCopiedText} classes={classes} {...props}/>
            </Box>

            <Grid item style={{ marginTop: 40 }}>
                <TextWithMacrosNew
                    name={`${props?.fieldName}.physical_exam.narrative`}
                    onChange={props?.onChange}
                    // // macros={macros}
                    isDisabled={props?.nonEditable}
                    rows={12}
                    withoutMacro={false}
                    values={props?.values?.physical_exam?.narrative}
                    // placeholder={placeholder}
                />
            </Grid>

            <PreviousCNDrawer
                open={copyModalOpen}
                handleClose={handleCloseDrawer}
                categoryName={'Physical Exam'}
                lastNarrative={props?.lastNarrative}
                lastUpdateDate={props?.copyDate}
                // narrativeText={'Please enter the patient’s history of present illness'}
                handleChangecopy={handleChangecopy}
                customCheckAllValue={selectAllCheck}
                customCheckAllHandler={handleSelectAllChecks}
                applyButtonDisabled={copySelectionFieldNames?.length === 0}
            >
                <PhysicalExamCopyComponent 
                    fieldName={props?.fieldName}
                    values={props?.lastNarrative}
                    copySelectionFieldNames={copySelectionFieldNames}
                    handleSetSelectedValues={handleSetSelectedValues}
                    physicalExamItems={physicalExamItems}
                />
            </PreviousCNDrawer>
        </Grid>
    )
}

export default PhysicalExam


