import React, { useState, useCallback, useEffect } from 'react'
// import Video from 'twilio-video'
// import Room from './Room'
import './styles.scss'
import { useDispatch, useSelector } from 'react-redux'

// import { isEmpty } from 'lodash'
// import { VideoProvider } from '../Consultation/components/VideoProvider'
// import { ChatProvider } from '../Consultation/components/ChatProviderContext'
// import { setLoading } from '../../store/actions/loader'
// import { setEncounterRoom } from '../../store/actions/provider'
import VideoRoom from 'components/Telehealth/VideoRoom'

const VideoChat = ({ lastMessage, inProgressEncounter, onError }) => {
    const dispatch = useDispatch()
    const [roomName, setRoomName] = useState('')
    const [room, setRoom] = useState(null)

    const tokenVideoProvider = useSelector((state) => state.provider.tokenVideoProvider)
    const vonageProviderSession = useSelector((state) => state.provider.vonageProviderSession)

    console.log("vonageProviderSession :: ", vonageProviderSession)

    const handleLogout = useCallback(() => {
        setRoom((prevRoom) => {
            if (prevRoom) {
                prevRoom.localParticipant.tracks.forEach((trackPub) => {
                    trackPub.track.stop()
                })
                prevRoom.disconnect()
            }
            return null
        })
    }, [])

    // useEffect(() => {
    //     setRoomName(inProgressEncounter?.twillio_room_name)
    //     console.log(tokenVideoProvider, 'tokenVideoProvider')
    //     if (!isEmpty(tokenVideoProvider)) {
    //         dispatch(setLoading(true))
    //         Video.connect(tokenVideoProvider, {
    //             video: { aspectRatio: 16 / 9 },
    //             audio: true,
    //             dominantSpeaker: true,
    //         })
    //             .then((room) => {
    //                 setRoom(room)
    //                 dispatch(setEncounterRoom(room))

    //                 // connect chat()
    //             })
    //             .catch((err) => {
    //                 console.error(err, tokenVideoProvider, 'cannot connect to twillio')
    //                 onError(true)
    //             })
    //             .finally(() => {
    //                 dispatch(setLoading(false))
    //             })
    //     }
    // }, [tokenVideoProvider])

    useEffect(() => {
        if (room) {
            const tidyUp = (event) => {
                if (event.persisted) {
                    return
                }
                if (room) {
                    handleLogout()
                }
            }
            window.addEventListener('pagehide', tidyUp)
            window.addEventListener('beforeunload', tidyUp)
            return () => {
                window.removeEventListener('pagehide', tidyUp)
                window.removeEventListener('beforeunload', tidyUp)
            }
        }
    }, [room, handleLogout])

    let render
    if (vonageProviderSession?.token && inProgressEncounter) {
        render = (
            // <VideoProvider>
            //     <ChatProvider>
            //         {/* <Room
            //             roomName={roomName}
            //             room={room}
            //             handleLogout={handleLogout}
            //             lastMessage={lastMessage}
            //         /> */}
            //         <VideoRoom vonageProviderSession={vonageProviderSession} prvName={`${inProgressEncounter.practitioner_first_name} ${inProgressEncounter.practitioner_last_name}`} />
            //     </ChatProvider>
            // </VideoProvider>
            <VideoRoom vonageProviderSession={vonageProviderSession} prvName={`${inProgressEncounter.practitioner_first_name} ${inProgressEncounter.practitioner_last_name}`} />
        )
    } else {
        render = null
    }
    return render
}

export default VideoChat
