import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    getMedicalCoding,
    postMedicalCoding,
} from '../../../../containers/ClinicalNotes/api/services'
import LoadingPage from '../../../../utilities/loading-page'
import AlertComfirm from './AlertComfirm'
import RoleSectionRow from './RoleSectionRow'
import SearchCPTCodeInput from './SearchCPTCodeInput'
import { permissionSaveMedicalCoding, setIsUpdatingMedicalCoding, updatingMedicalCoding } from 'store/actions/clinicalNotes'
import { useNotification } from 'shared/elements/Notification'
import { allOutComeList, getMedicalProblemList } from 'services/Patient/patient'
import { searchCptModifier } from 'services/WorkQueue'
import MultipleSelect from './MultipleSelect'
import { TextBox } from 'shared/elements/FormItem/TextBox'

const useStyles = makeStyles({
    table: {
        '& .MuiTableCell-root': {
            // border: '1px solid #B2C0CF',
            border: 'unset',
            width: '16.28%',
            boxSizing: 'border-box',
            padding: 8,
            '&:last-child': {
                width: '6%',
            },
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            marginBottom: '-12px',
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    colorContent: {
        color: '#667689',
    },
    ellipsis__text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    cover__add__new: {
        cursor: 'pointer',
        width: 'fit-content',
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
    text__field: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e9ecf1 !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#667689',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important',
        },
    },
    search_input: {
        width: 'auto',
        height: 40,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    header: {
        fontSize: 19,
        color: '#303E4E',
    },
    subContent: {
        justifyContent: 'space-between',
        margin: '0 !important',
        borderRadius: '0 !important',
    },
    boderContentSub: {
        borderRadius: 8,
    },
    wrapContent: {
        margin: '16px 0 0 0 !important',
        borderRadius: '0 !important',
        background: '#E9ECF1 !important',
    },
    warpViewContainer: {
        marginBottom: 16,
        overflowX: 'auto',
        overflowY: 'hidden',
        display: '-webkit-inline-box',
        '&:nth-child(2)': {
            borderBottom: '5px solid #e8e8e8',
            borderTop: '5px solid #e8e8e8',
            padding: '24px 0',
            margin: '24px 0',
        },
    },
})
const tableHeading = ['No.', 'Code', 'description', 'Actions']

const getDropdownOptions = (master = [], key) => {
    return master.map((im) => ({
        value: im[key],
        key: im._id,
    }))
}

const MedicalCoding = ({
    master,
    noteDetails,
    fieldName,
    chief_complaint,
    onChange,
    macros,
    appointmentId,
    read_only,
    clinicalNoteData,
    values,
    MedicalCoding
}) => {
    const classes = useStyles()
    const { roleMasters, participationMasters } = master
    const roleOptions = getDropdownOptions(roleMasters, 'name')
    const participationOptions = getDropdownOptions(participationMasters, 'name')

    const deleted = values?.deleted_icd_medical_problem || []

    const [headerSection, setheaderSection] = useState([
        {
            name: 'ICD-10 Codes',
            type: 'icd',
            data: [],
        },
        {
            name: 'CPT Codes',
            type: 'cpt',
            data: [],
        },
        {
            name: 'HCPCS Codes',
            type: 'hcp',
            data: [],
        },
    ])
    const [textSearch, setTextSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [dataModifiers, setDataModifiers] = useState([])
    const [foceUpdate, setFoceUpdate] = useState(0)
    const [alertComfirm, setAlertComfirm] = React.useState(false)
    const [seletedOutcome, setSeletedOutcome] = useState([])
    const [outComeList, setOutComeList] = useState([])
    const [loadingOption, setLoadingOption] = useState(false)

    const generalRows = useMemo(
        () => chief_complaint?.sessions?.filter((v) => v.hasOwnProperty('role')),
        [chief_complaint],
    )
    const customRows = useMemo(
        () => chief_complaint?.sessions?.filter((v) => v.hasOwnProperty('custom_role')),
        [chief_complaint],
    )
    const selfRows = useMemo(
        () => chief_complaint?.sessions?.filter((v) => v.hasOwnProperty('was_available')),
        [chief_complaint],
    )

    const isUpdatingMedicalCoding = useSelector(
        (state) => state.clinicalNotes.isUpdatingMedicalCoding,
    )
    const updatingMedicalCodingForce = useSelector(
        (state) => state.clinicalNotes.updateMedicalCoding,
    )
    const permissionSaveCedicalCodeing = useSelector(
        (state) => state.clinicalNotes.saveMedicalCodingPermision,
    )
    const dispatch = useDispatch()

    const notification = useNotification()

    useEffect(() => {
        setLoading(true)
        if (isUpdatingMedicalCoding) return
        if (appointmentId) {
            dispatch(permissionSaveMedicalCoding(true))
            getMedicalCoding(appointmentId)
                .then(async (response) => {
                    const medical_problems = []
                    if (clinicalNoteData?.patient?.id) {
                        const {
                            data: { data },
                        } = await getMedicalProblemList(clinicalNoteData.patient.id, 1, 100000)
                        data.forEach(({ icd_code, id }) => {
                            medical_problems.push({
                                code: icd_code.code,
                                description: icd_code.display,
                                fromMedicalProblems: true,
                                id,
                                origin_type: 'from_medical_problems',
                            })
                        })
                    }

                    for (let index = 0; index < headerSection.length; index++) {
                        const element = headerSection[index].type

                        switch (element) {
                            case 'icd':
                                const icd_filterd = (response.data?.icd10 || []).filter((icd) => {
                                    if (icd.origin_type === 'from_medical_problems') {
                                        if (medical_problems.some((el) => el.code === icd.code))
                                            return true
                                        return false
                                    }
                                    return true
                                })
                                headerSection[index]['data'] = [...icd_filterd, ...medical_problems]
                                    .filter((el) => {
                                        if (el.origin_type === 'from_medical_problems') {
                                            if (deleted.includes(el.id)) return false
                                        }
                                        return true
                                    })
                                    .filter(
                                        (el, index, self) =>
                                            self.findIndex((item) => item.code === el.code) ===
                                            index,
                                    )

                                break
                            case 'cpt':
                                headerSection[index]['data'] = response.data?.cpt
                                break
                            case 'hcp':
                                headerSection[index]['data'] = response.data?.hcpcs
                                break

                            default:
                                break
                        }
                    }
                    setheaderSection(headerSection)
                    setLoading(false)
                    if (response) {
                        dispatch(permissionSaveMedicalCoding(false))
                    }
                })
                .catch((e) => {
                    console.log(e, 'err')
                    permissionSaveMedicalCoding(false)
                })
                .finally(() => {
                    if (!isUpdatingMedicalCoding) setLoading(false)
                })
        } else {
            setLoading(false)
        }

        return () => {
            setFoceUpdate(0)
            handleSaveDataMedicalCoding()
        }
    }, [isUpdatingMedicalCoding])
    useEffect(() => {
        if (updatingMedicalCodingForce) {
            handleSaveDataMedicalCoding()
            dispatch(updatingMedicalCoding(false))
        }
    }, [updatingMedicalCodingForce])
    const getModifiers = () =>
        searchCptModifier()
            .then((data) => setDataModifiers(data))
            .catch((err) =>
                notification(
                    err?.data?.message || 'Something went wrong when fetching Modifiders',
                    'error',
                ),
            )
    useEffect(() => {
        getModifiers()
        // fetch(
        //     `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cpt_modifier/_search?size=1000&filter_path=hits.hits._source`,
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Authorization: 'Basic c29ub3JhQWRtaW46O1NTZlUyVDghVTJPJ112Ukg4bUQ=',
        //         },
        //     },
        // )
        //     .then((res) => res.json())
        //     .then((data) => {
        //         const dataMap = data.hits.hits
        //             .sort((option1, option2) => {
        //                 const a = option1._source?.display || ''
        //                 const b = option2._source?.display || ''
        //                 return a === b ? 0 : a > b ? 1 : -1
        //             })
        //             .map((item) => ({
        //                 ...item._source,
        //                 code: item._source?.code,
        //                 display: `${item._source?.code} - ${item._source?.display}`,
        //             }))
        //         setDataModifiers(dataMap)
        //     })
        //     .catch((err) => console.log(err))
    }, [])

    const handleSaveDataMedicalCoding = async () => {
        // if (read_only) return
        // if (permissionSaveCedicalCodeing) return
        // if (appointmentId) {
        //     const objectData = {
        //         icd10: [],
        //         cpt: [],
        //         hcpcs: [],
        //     }
        //     for (let index = 0; index < headerSection.length; index++) {
        //         const element = headerSection[index].type
        //         switch (element) {
        //             case 'icd':
        //                 objectData['icd10'] = headerSection[index].data
        //                 break
        //             case 'cpt':
        //                 objectData['cpt'] = headerSection[index].data
        //                 break
        //             case 'hcp':
        //                 objectData['hcpcs'] = headerSection[index].data
        //                 break
        //             default:
        //                 break
        //         }
        //     }
        //     dispatch(setIsUpdatingMedicalCoding(true))
        //     postMedicalCoding(appointmentId, objectData)
        //         .then((response) => {
        //             if(response){
        //                dispatch(setIsUpdatingMedicalCoding(false))
        //             }
        //             // setLoading(false)
        //         })
        //         .catch((e) => {
        //             console.log(e, 'err')
        //             dispatch(setIsUpdatingMedicalCoding(false))
        //         })
        //         .finally(() => {
        //             // dispatch(setIsUpdatingMedicalCoding(false))

        //             // setLoading(false)
        //         })
        // }
        try {
            if (appointmentId && !read_only && !permissionSaveCedicalCodeing) {
                const objectData = {
                    icd10: [],
                    cpt: [],
                    hcpcs: [],
                }
                for (let index = 0; index < headerSection.length; index++) {
                    const element = headerSection[index].type
                    switch (element) {
                        case 'icd':
                            objectData['icd10'] = headerSection[index].data
                            break
                        case 'cpt':
                            objectData['cpt'] = headerSection[index].data
                            break
                        case 'hcp':
                            objectData['hcpcs'] = headerSection[index].data
                            break
                        default:
                            break
                    }
                }
                dispatch(setIsUpdatingMedicalCoding(true))
                if (objectData?.cpt?.length > 0) {
                    const response = await postMedicalCoding(appointmentId, objectData)
                    if (response) {
                        dispatch(setIsUpdatingMedicalCoding(false))
                    }
                }
            }
        } catch (error) {
            console.log(error, 'err')
        } finally {
            dispatch(setIsUpdatingMedicalCoding(false))
        }
    }

    const handleInputChange = (name, value, index) => { }

    const handleAddRow = (updatedValues, type) => {
        if (MedicalCoding) { return }
        const icd = headerSection.find((section) => section.type === 'icd')
        if (icd.data.length >= 26 && type === 'icd') {
            return notification('Max 26 codes', 'error')
        }
        setFoceUpdate(foceUpdate + 1)
        const converUpdatedValues = {
            code: updatedValues?.code,
            description: updatedValues?.description,
            clinicianDescriptorId: updatedValues?.clinicianDescriptorId,
            extension: { modifiers: [] },
        }
        if (updatedValues) {
            for (let index = 0; index < headerSection.length; index++) {
                const element = headerSection[index].type

                const notDuplicate = headerSection[index].data.filter((object) => {
                    return object?.clinicianDescriptorId
                        ? object?.clinicianDescriptorId === updatedValues?.clinicianDescriptorId
                        : object?.description === updatedValues?.description
                })

                if (type === element && isEmpty(notDuplicate)) {
                    headerSection[index]['data'].push(converUpdatedValues)
                } else {
                    if (!isEmpty(notDuplicate)) {
                        setAlertComfirm(true)
                    }
                }
            }
        }
    }

    const handleAddOrRemove = (action, index, type, code, row) => {
        if (MedicalCoding) { return }
        if (action === 'remove' && type === 'icd' && row.origin_type === 'from_medical_problems') {
            deleted.push(row.id)
            onChange(`${fieldName}.deleted_icd_medical_problem`, deleted)
        }
        setFoceUpdate(foceUpdate + 1)
        for (let i = 0; i < headerSection.length; i++) {
            const element = headerSection[i].type
            if (type === element) {
                const newArr = headerSection[i].data.filter((object) => {
                    return object?.clinicianDescriptorId
                        ? object.clinicianDescriptorId !== code
                        : object.description !== code
                })
                headerSection[i].data = newArr
            }
        }
    }

    const handleChange = (updatedValues) => {
        setFoceUpdate(foceUpdate + 1)
    }

    const renderHeaderSection = (item, index) => {
        return (
            <Grid
                container
                key={index}
                justifyContent="space-between"
                alignItems="center"
                className={classes.warpViewContainer}>
                <Grid item xs={12} style={{ overflow: 'hidden' }}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography className={classes.header}>{item.name}</Typography>
                        <Grid item>
                            <Grid container>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justifyContent="flex-end"
                                    spacing={1}
                                    style={{ flex: 1 }}>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        wrap="nowrap"
                                        justifyContent="flex-end"
                                        style={{
                                            gap: 8,
                                            marginRight: 16,
                                            flex: 1,
                                        }}>
                                        <Grid
                                            item
                                            style={{
                                                maxWidth: 280,
                                                // flex: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 5,
                                                }}>
                                                <SearchCPTCodeInput
                                                    handleInputChange={handleInputChange}
                                                    r={item}
                                                    index={index}
                                                    type={item.type}
                                                    disabled={read_only}
                                                    handleAddRow={handleAddRow}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Table className={classes.table} sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {tableHeading.map((heading, index) => (
                                <TableCell
                                    key={index}
                                    align="left"
                                    style={{
                                        width: (index === 1 || index === 3 || index === 0) && 100,
                                        flex: index === 2 && 1,
                                    }}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            marginRight: 8,
                                            fontWeight: 500,
                                            color: '#303E4E',
                                        }}>
                                        {heading}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <RoleSectionRow
                            roleOptions={roleOptions}
                            participationOptions={participationOptions}
                            handleChange={handleChange}
                            rows={generalRows}
                            customRows={customRows}
                            selfRows={selfRows}
                            dataContent={item.data}
                            type={item.type}
                            handleAddOrRemove={handleAddOrRemove}
                            dataModifiers={dataModifiers}
                            headerSection={headerSection}
                            handleOpenModal={() => setAlertComfirm(true)}
                            read_only={read_only}
                        />
                    </TableBody>
                </Table>
            </Grid>
        )
    }

    const handleChangeMultipleSelect = (option, checked) => {
        const newValue = [...seletedOutcome]
        if (checked) {
            newValue.push(option)
        } else {
            newValue.splice(
                newValue.findIndex((el) => el.id === option.id),
                1,
            )
        }
        setSeletedOutcome(newValue)
        showSelectedValue()
        const getOnlyId = newValue.length ? newValue.map((item) => { return { id: item.id } }) : []
        onChange(`${fieldName}.outComes`, getOnlyId)
    }
    const showSelectedValue = () => {
        if (seletedOutcome.length) {
            const data = seletedOutcome.map((outerItem) => {
                return outerItem.entry.map((innerItem) => {
                    return innerItem.entry;
                });
            })
            const flattenedData = data.flat();
            return flattenedData
        }
        return []
    }
    const getOutcomeList = async () => {
        try {
            setLoadingOption(true)
            const resp = await allOutComeList({ clinical_note_template_id: clinicalNoteData?.template_id })
            setOutComeList(resp?.data)
            if (values?.outComes?.length) {
                const actualSelectedalist = resp?.data.filter(item => values?.outComes.some(val => val.id === item.id))
                setSeletedOutcome(actualSelectedalist)
                showSelectedValue()
            }
            setLoadingOption(false)

        } catch (e) {
            setLoadingOption(false)
            console.log(e)
        }
    }
    useEffect(() => {
        getOutcomeList()
    }, [])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <TableContainer component={Paper} style={{ overflowX: 'unset' }}>
                {headerSection.map((item, index) => {
                    return renderHeaderSection(item, index)
                })}
            </TableContainer>
            <AlertComfirm
                open={alertComfirm}
                handleClose={() => {
                    setAlertComfirm(false)
                }}
            />
            <Grid item xs={12}>
                <MultipleSelect
                    name="outcomes"
                    label='Outcomes'
                    placeholder="Select outcome"
                    value={seletedOutcome}
                    options={outComeList}
                    disabled={read_only}
                    onOpen={() => { }}
                    loadingOptions={loadingOption}
                    handleChange={(option, checked) => {
                        handleChangeMultipleSelect(option, checked)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextBox
                    name="note"
                    value={showSelectedValue()}
                    multiline
                    variant="outlined"
                    minRows={4}
                    placeholder=" "
                    // disabled={true}
                    read
                    style={{ marginTop: 10 }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '180px !important',
                        },
                        ' & .MuiInputBase-input': {
                            height: '150px !important',
                        },
                    }}
                />
            </Grid>
            {loading && <LoadingPage />}
        </Box>
    )
}

export default MedicalCoding
