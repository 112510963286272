import {
    Box,
    Grid,
    Button,
    Typography,
} from '@material-ui/core'

import IconButton from '@mui/material/IconButton'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info'
import clsx from 'clsx'
import { capitalize, startCase, toLower } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ViewParticipant from '../compoment/viewParticipant'
import {
    IconHidenConsultation,
    IconRequestFaile,
    IconRequestSuccess,
} from '../icon'
import * as actionTypes from '../actionTypes'
import {
    connect,
    useDispatch,
    useSelector,
} from 'react-redux'
import {
    assignConsultationToOtherProvider,
    handleOpenListConsultation,
    postAdmit,
    postEndRoom,
} from '../../../store/actions/provider'
import EmtyCosnultationList from './EmtyCosnultationList'
import AssistantPractitionerList from './AssistantPractitionerList'
import AssistantStatusDialog from '../../shared/Elements/AssistantStatusDialog'

const StatusTitle = {
    REQUEST: 'Request',
    ASSIGN_FAILED: 'Assign Failed',
    ASSIGN_SUCCESS: 'Assign Successfully',
}

const useStyles = makeStyles({
    headerGridContainer: {
        width: 448,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        // borderLeft: '1px solid #e8e8e8',
        boxShadow: '10px 5px 26px rgba(39, 46, 65, 0.25)',
        transition: 'width 0.5s',
    },
    width0: {
        width: 0,
    },
    appLogo: {
        width: 150,
        height: 66,

        '@media (max-width: 1500px)': {
            width: '70%',
            height: 'auto',
        },
    },
    typo_line: {
        color: '#9CA9B8',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_gray: {
        color: '#303E4E',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_cover: {
        borderBottom: '1px solid #e8e8e8',
        margin: '8px 0',
    },
    separator: {
        borderLeft: '2px solid #ffffff',
        height: 55,
        width: 2,
        boxSizing: 'border-box',
        marginRight: 10,
        marginLeft: 10,
    },
    leftBorder: {
        borderLeft: '2px solid #ffffff',
        paddingLeft: 10,
    },
    dFlex: {
        display: 'flex',
    },
    oveflowDegree: {
        width: '235px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textTransformCapital: {
        textTransform: 'uppercase',
    },
    avatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
        marginTop: 16,
        background: 'gray',
        marginBottom: 16,
    },
    provider_tag: {
        padding: '4px 16px',
        background: '#3E465B',
        borderRadius: 5,
        color: 'white',
        margin: '12px 0',
    },
    cover_info: {
        padding: 24,
        boxShadow: '10px 5px 26px rgba(39, 46, 65, 0.25)',
        // borderTop: '1px solid #e8e8e8',
    },
    show_header_btn: {
        position: 'absolute !important',
        top: 40,
        left: -40,
        zIndex: 2,
    },
    hidden_cover: {
        overflowX: 'inherit',
        overflowY: 'scroll',
    },

    titleHeader: {
        fontSize: 19,
        lineHeight: '160%',
        fontWeight: 500,
        alignItems: 'center',
    },
})

const ProviderList = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()

    const [openEndDialog, setOpenEndDialog] =
        useState(false)
    const [infoAssign, setInfoAssign] = useState({})

    const [openAssignSuccess, setOpenAssignSuccess] =
        useState(false)

    const [openAssignFaile, setOpenAssignFaile] =
        useState(false)

    const [showProviderAssgin, setShowProviderAssgin] =
        useState(true)

    const [textError, setTextError] = useState('')
    const [message, setMessage] = useState('')

    const [title, setTitle] = useState(StatusTitle.REQUEST)

    const [iconAssign, seticonAssign] = useState(null)

    const handleShowListConsultatuon = (item) => {
        dispatch(handleOpenListConsultation(item))
    }

    const openListProvider = useSelector(
        (state) => state.provider?.openListProvider,
    )

    const encounterMeetingId = useSelector(
        (state) => state.provider?.encounterMeetingId,
    )

    const assistantPractitioner = useSelector(
        (state) => state.provider?.assistantPractitioner,
    )

    const assignedProvider = useSelector((state) => {
        return state.provider?.assignedProvider
    })

    const handleAssign = (item) => {
        console.log(item, 'item')
        setInfoAssign(item)
        setOpenEndDialog(true)
        setShowProviderAssgin(true)
        seticonAssign(null)
        setTitle(StatusTitle.REQUEST)
    }

    const _handleComfirmAssign = () => {
        setOpenEndDialog(false)
        dispatch(
            assignConsultationToOtherProvider(
                {
                    encounterId: encounterMeetingId,
                    assignedProviderId:
                        infoAssign?.practitioner_id,
                    message,
                },
                () => {
                    setTitle(StatusTitle.ASSIGN_SUCCESS)
                    setOpenEndDialog(true)
                    setOpenAssignSuccess(true)
                    //hide the forwarded doctor information
                    setShowProviderAssgin(false)
                    seticonAssign(<IconRequestSuccess />)
                },
                (err) => {
                    setTitle(StatusTitle.ASSIGN_FAILED)
                    setOpenEndDialog(true)
                    setOpenAssignFaile(true)
                    setTextError(err)
                    //hide the forwarded doctor information
                    setShowProviderAssgin(false)
                    seticonAssign(<IconRequestFaile />)
                },
            ),
        )
    }

    const _handleOpentEndEcounter = () => {
        setOpenEndDialog(false)
        setOpenAssignSuccess(false)
        setOpenAssignFaile(false)
    }

    const objectPatient = useSelector(
        (state) => state.provider.objectEncouterStart,
    )

    const _handleConfirmEndEncouter = () => {
        setOpenEndDialog(false)
        setOpenAssignSuccess(false)
        setOpenAssignFaile(false)
    }
    const _handleChange = (event) => {
        setMessage(event.target.value)
    }

    return (
        <Grid
            className={clsx({
                [classes.headerGridContainer]: true,
                [classes.width0]: !openListProvider,
            })}
            direction={'column'}
            container
            alignItems={'center'}
            wrap="nowrap">
            {openListProvider && (
                <IconButton
                    variant="outlined"
                    onClick={() =>
                        handleShowListConsultatuon({
                            open: false,
                        })
                    }
                    className={classes.show_header_btn}>
                    <IconHidenConsultation />
                </IconButton>
            )}

            <Grid
                direction={'column'}
                container
                alignItems={'center'}
                wrap="nowrap"
                className={classes.hidden_cover}>
                <Grid
                    container
                    className={classes.cover_info}
                    direction="column">
                    <Typography
                        style={{
                            paddingBottom: 16,
                            fontSize: 19,
                            color: 'black',
                            fontWeight: 500,
                        }}>
                        Assistance list
                    </Typography>
                    <div>
                        {assistantPractitioner?.length >
                        0 ? (
                            <AssistantPractitionerList
                                assistantPractitionerList={
                                    assistantPractitioner
                                }
                                handleAssign={handleAssign}
                                assignedProvider={
                                    assignedProvider
                                }
                            />
                        ) : (
                            <EmtyCosnultationList />
                        )}
                    </div>
                    <AssistantStatusDialog
                        title={title}
                        open={openEndDialog}
                        handleClose={() => {
                            setOpenEndDialog(false)
                        }}
                        handleConfirm={() => {
                            _handleComfirmAssign()
                        }}
                        handleOpentEndEcounter={() => {
                            _handleOpentEndEcounter()
                        }}
                        handleConfirmEndEncouter={() => {
                            _handleConfirmEndEncouter()
                        }}
                        icon={iconAssign}
                        infoAssign={infoAssign}
                        showProviderAssgin={
                            showProviderAssgin
                        }
                        openAssignSuccess={
                            openAssignSuccess
                        }
                        openAssignFaile={openAssignFaile}
                        textError={textError}
                        handleChange={_handleChange}
                        messageBody={message}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProviderList
