import { Checkbox, Typography, Modal, Button } from '@material-ui/core'
import { RefreshOutlined } from '@material-ui/icons'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { Box, Grid, Stack } from '@mui/material'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { Button as ButtonCustom } from 'components/custom'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import CHC from 'containers/PatientsSetting/demographic/Labs/CHC'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
    allApiLabOrder,
    updateAcknowledge,
    updateLabOrderPreview,
    labOrderMatch,
} from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { convertFullName } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'
import PatientInfor from '../PatientInfor'
import ViewDetailDialog from './ViewDetailDialog'
import { useStyles } from './styles'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import CloseIcon from '@material-ui/icons/Close'
import Divider from '@mui/material/Divider'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import { getLabResultStatus } from 'utilities/labResults'
import './styles.scss'
import SearchCHCProviderInput from 'components/custom/SearchCHCProviderInput'
export const LabOrdersPatient = ({ isInClinicalNote, patient_Id, nonEditable = false }) => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()
    const [sortModel, setSortModel] = useState([])
    const [listLabOrder, setListLabOrder] = useState([])
    const [viewDetail, setViewDetail] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [isDownload, setIsDownload] = useState()
    const [anchorEl, setAnchorEl] = useState(null)
    // const [page, setPage] = useState(1)
    const notification = useNotification()
    const [openCHC, setOpenCHC] = useState(false)
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    const [synchronizing, setSynchronizing] = useState(false)
    const [patientId, setPaitentId] = useState('')
    const [patientDetails, setPatientDetails] = useState()
    const [patientName, setpatientName] = useState('')
    const [labOrderId, setLabOrderId] = useState('')
    const [pdf, setPdf] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [patientAssignId, setPaitenAssigntId] = useState('')
    // const [providerAssignId, setProviderAssigntId] = useState('')
    const [anchorEl1, setAnchorEl1] = useState(null)
    const [labId, setLabId] = useState('')
    // const [providerDetails, setProviderDetails] = useState()

    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 0,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        setPaitentId(patient_Id || id)
    }, [patient_Id, id])

    const getLabOrderByPatient = async (patientId) => {
        setLoading(true)
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId, page, limit)
            const formatData =
                res.data?.data.map((item) => ({
                    id: item.id,
                    requisition_number: item.placer_order_number,
                    patient_name: `${item.first_name} ${item.last_name}`,
                    lab_orderred: `${item.lab_code} - ${item.sponsor_name}`,
                    order_date: item.creation_datetime,
                    status: getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status),
                    orderring_provider: convertFullName(
                        item.provider_first_name,
                        item.provider_middle_name,
                        item.provider_last_name,
                    ),
                    requisition_pdf: item.requisitionUrl,
                    results: {
                        html: item.htmlUrl || null,
                        pdf: item.pdfUrl || null,
                    },
                    is_acknowledge: item.is_acknowledge,
                    is_review: item.is_review,
                })) || []

            setListLabOrder(formatData)
            setTotal(res.data.paging?.total || 0)
        } catch (error) {
            notification('Get Lab order error', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handlePageChange = (value) => {
        setPage(value)
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) getLabOrderByPatient(patientId)
        return () => abortController.abort()
    }, [page, sortModel, patientId])
    const handleAcknoledge = async () => {
        setLoading(true)
        if (labOrderId && patientId)
            try {
                const res = await updateAcknowledge(patientId, labOrderId)
                if (res) {
                    setListLabOrder((prevData) => prevData.filter((item) => item.id !== labOrderId))
                    setTotal(total - 1)
                    notification('Get Lab order acknowledge successfull', 'success')
                }
            } catch (error) {
                notification('Get Lab order acknowledge error', 'error')
            } finally {
                setLoading(false)
                setAnchorEl(null)
                setLabOrderId('')
            }
    }
    const handleIsPreview = async (event, id, value) => {
        event?.preventDefault()
        setLoading(true)
        if (patientId && id)
            try {
                const payload = {
                    input: {
                        is_review: value,
                    },
                }
                const res = await updateLabOrderPreview(patientId, id, payload)
                if (res) {
                    setListLabOrder((prevData) =>
                        prevData.map((item) =>
                            item.id === id ? { ...item, is_review: value } : item,
                        ),
                    )
                    notification('Get Lab provider review status updated', 'success')
                }
            } catch (error) {
                notification('Get Lab order error', 'error')
            } finally {
                setLoading(false)
                setAnchorEl(null)
                setLabOrderId('')
            }
    }
    const columns = [
        {
            headerName: 'Ordering provider',
            headerClassName: 'super-app-theme--header',
            field: 'orderring_provider',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Lab ordered',
            headerClassName: 'super-app-theme--header',
            field: 'lab_orderred',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value || ''
            },
        },
        {
            headerName: 'Requisition Number',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_number',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || 1212
            },
        },
        {
            headerName: 'Order Date',
            headerClassName: 'super-app-theme--header',
            field: 'order_date',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Order Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            flex: 0.4,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <span
                        className={
                            value !== 'Final Reported' ? 'bgColor_in_progress' : 'bgColor_completed'
                        }>
                        {value || '-'}
                    </span>
                )
            },
        },
        {
            headerName: 'Requisition PDF',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_pdf',
            flex: 0.3,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ cursor: 'pointer' }}>
                        {value ? (
                            <Box onClick={() => handlePDF(value)}>
                                <DataGridCell value={'View detail'} highlight />
                            </Box>
                        ) : (
                            '-'
                        )}
                        {/* {row.id === isDownload?.id && isDownload.loading && <LinearProgress />} */}
                    </Box>
                )
            },
        },
        {
            headerName: 'Results',
            headerClassName: 'super-app-theme--header',
            field: 'results',
            flex: 0.2,
            sortable: true,
            renderCell: ({ value, row }) => {
                if (value) {
                    return (
                        <Box
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#5571c6 !important',
                                justifyContent: 'center',
                            }}>
                            {row.results.pdf && (
                                <>
                                    <Box onClick={() => handlePDF(row.results.pdf)}>{'PDF'}</Box>{' '}
                                    <Box sx={{ padding: '0px 5px', marginBottom: '3px' }}>|</Box>{' '}
                                </>
                            )}
                            {row.results.html && (
                                <Box onClick={() => handleHTML(row.results.html)}>{'HTML'}</Box>
                            )}
                        </Box>
                    )
                }
                return '-'
            },
        },
        {
            headerName: 'Provider Review',
            headerClassName: 'super-app-theme--header',
            field: 'is_review',
            flex: 0.3,
            sortable: false,
            renderCell: ({ value, id, row }) => {
                return (
                    <Checkbox
                        color="primary"
                        // The functionality is disabled because it is not working
                        disabled={true}
                        checked={value}
                        onChange={(event) => {
                            if (value) {
                                handleOpenAlertPopup(event, id, row.patient_name)
                            } else {
                                handleIsPreview(event, id, true)
                            }
                        }}
                    />
                )
            },
        },
        {
            headerName: 'Refresh',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.2,
            sortable: false,
            renderCell: () => {
                return (
                    <RefreshOutlined
                        style={{
                            padding: 5,
                            cursor: 'pointer',
                        }}
                        fontSize="small"
                        onClick={handleRefresh}
                    />
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.3,
            sortable: false,
            renderCell: ({ row }) => (
                !nonEditable
                ?
                <EditAction
                    menu={[
                        {
                            text: 'Reassign Lab ',
                            handler: () => handleModalOpen(row),
                        },
                    ]}
                    // The functionality is disabled because it is not working
                    disabled={true}
                />
                : 
                ""
            ),
        },
    ]

    const handleRefresh = async () => {
        try {
            setLoading(true)
            setSynchronizing(true)
            await allApiLabOrder.syncLabOrder()
            await getLabOrderByPatient(patientId)
        } catch (error) {
            notification('Something went wrong', 'error')
        } finally {
            setLoading(false)
            setSynchronizing(false)
        }
    }

    const handleHTML = (html) => {
        setViewDetail(html)
    }

    const handlePDF = (pdf) => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        a.href = pdf
        a.target = '_blank'
        a.download = 'Requisition PDF'
        a.click()
        window.URL.revokeObjectURL(pdf)
    }

    const handleCellClick = (params) => {
        if (params.field === 'requisition_pdf') {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.setAttribute('style', 'display:none')
            a.href = params.row.requisition_pdf
            a.target = '_blank'
            a.download = 'Requisition PDF'
            a.click()
            window.URL.revokeObjectURL(params.row.requisition_pdf)

            // setIsDownload({
            //     id: params.id || params.row.id || null,
            //     loading: true,
            // })
            // getDocumentById(139)
            //     .then((res) => {
            //         const uri = res?.data?.url
            //         const a = document.createElement('a')
            //         document.body.appendChild(a)
            //         a.setAttribute('style', 'display:none')
            //         if (uri) {
            //             fetch(uri).then(async (res) => {
            //                 const response = await res.blob()
            //                 const url = window.URL.createObjectURL(response)
            //                 a.href = url
            //                 // a.download = params?.row?.file_url || 'Requisition PDF'
            //                 a.target = '_blank'
            //                 a.click()
            //                 window.URL.revokeObjectURL(url)
            //                 setIsDownload({
            //                     id: params.id || params.row.id || null,
            //                     loading: false,
            //                 })
            //             })
            //         }
            //     })
            //     .catch((err) => {
            //         setIsDownload({
            //             id: params.id || params.row.id || null,
            //             loading: false,
            //         })
            //     })
        }
        if (params.field === 'results') {
            setViewDetail(params.row.results)
            // const a = document.createElement('a')
            // document.body.appendChild(a)
            // a.setAttribute('style', 'display:none')
            // a.href = params.row.results
            // a.target = '_blank'
            // a.click()
            // window.URL.revokeObjectURL(params.row.results)
        }
    }
    const handleOpenAlertPopup = (event, item, patient_name) => {
        setAnchorEl(event.currentTarget)
        setLabOrderId(item)
        setpatientName(patient_name)
    }

    const handleCloseAlertPopup = () => {
        setAnchorEl(null)
        setLabOrderId('')
    }
    const handleOpenAlert1Popup = (event) => {
        setAnchorEl1(event.currentTarget)
    }

    const handleCloseAlert1Popup = () => {
        setAnchorEl1(null)
    }
    const sendLabUpdateTime = async () => {
        try {
            setIsLoadingButton(true)
            const res = await allApiLabOrder.syncLabOrder()
            if (res) {
                if (patientId) getLabOrderByPatient(patientId)
                setOpenCHC(false)
                setIsLoadingButton(false)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const handleModalOpen = (value) => {
        setPatientDetails(null)
        setPaitenAssigntId('')
        // setProviderAssigntId('')
        setPdf(value.requisition_pdf)
        setLabId(value.id)
        setOpenModal(true)
    }
    const handleModalClose = () => {
        setOpenModal(false)
        setPatientDetails(null)
        // setProviderDetails(null)
        setPaitenAssigntId('')
        // setProviderAssigntId('')
        setPdf(null)
        setLabId('')
    }
    const handleMatch = async () => {
        if (patientAssignId && labId && patientDetails) {
            try {
                setLoading(true)
                setOpenModal(false)
                setAnchorEl1(null)
                // const payload = {
                //     provider_first_name: providerDetails.provider_fname,
                //     provider_middle_name: providerDetails.provider_mname,
                //     provider_last_name: providerDetails.provider_lname,
                //     provider_name: providerAssignId,
                // }
                const res = await labOrderMatch(patientAssignId, labId)
                if (res.data) {
                    setListLabOrder((prevData) => prevData.filter((item) => item.id !== labId))
                    setLabId('')
                    setPaitenAssigntId('')
                    // setProviderAssigntId('')
                    setPdf(null)
                    setLoading(false)
                    // setProviderDetails(null)
                    notification(
                        `Lab order for ${patientDetails?.label} has been reassigned.`,
                        'success',
                    )
                }
            } catch (e) {
                setLabId('')
                setPaitenAssigntId('')
                // setProviderAssigntId('')
                setPdf(null)
                // setProviderDetails(null)
                setLoading(false)
                notification(`Lab order for ${patientDetails?.label} has been reassigned.`, 'error')
            }
        }
    }
    return (
        <Box
            className={classes.container}
            style={{ padding: !isInClinicalNote ? '16px 24px' : '0' }}>
            <Grid container alignItems={'center'}>
                <Grid item sx={{ flex: 1 }}>
                    <Grid container>
                        {!isInClinicalNote && (
                            <>
                                {' '}
                                <Grid
                                    item
                                    onClick={() => history.goBack()}
                                    style={{
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                    }}>
                                    <Stack direction={'row'} spacing={1}>
                                        <KeyboardBackspaceRoundedIcon
                                            style={{
                                                color: '#5571C6',
                                                fontSize: 18,
                                            }}
                                        />
                                        {location.state?.from === 'patient-dashboard' ? (
                                            <Typography className={classes['text_back']}>
                                                Back to Patient Dashboard
                                            </Typography>
                                        ) : (
                                            <Typography className={classes['text_back']}>
                                                Back to Lab Order
                                            </Typography>
                                        )}
                                    </Stack>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} style={{ marginTop: 12 }}>
                            <Grid container justifyContent="space-between">
                                <Typography className={classes.header}>Lab order List</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item height={'100%'}>
                    <ButtonCustom
                        tooltip="Refresh"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={handleRefresh}
                        fullWidth>
                        <RefreshOutlined
                            style={{
                                fontSize: 20,
                            }}
                        />
                    </ButtonCustom>
                    <ButtonCustom
                        tooltip="Add New"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setOpenCHC(true)}
                        fullWidth>
                        <AddIcon
                            style={{
                                fontSize: 20,
                            }}
                        />
                    </ButtonCustom>
                </Grid>
            </Grid>
            {!isInClinicalNote && (
                <>
                    <Grid container style={{ marginTop: 12 }}>
                        <Grid item xs={12}>
                            <PatientInfor />
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid item xs={12} className={classes.form_list_container}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        rows={listLabOrder}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        // disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        // onCellClick={handleCellClick}
                    />
                </Box>
            </Grid>
            {Boolean(viewDetail) && (
                <ViewDetailDialog
                    btnFromLabOrder={true}
                    link={viewDetail}
                    open={Boolean(viewDetail)}
                    handleClose={() => setViewDetail(false)}
                />
            )}

            {openCHC && (
                <CHC
                    open={openCHC}
                    patientId={patientId}
                    handleClose={() => sendLabUpdateTime()}
                    description={undefined}
                    isLoadingButton={isLoadingButton}
                />
            )}
            <CustomAlertPopOver
                anchorEl={anchorEl}
                handleClose={handleCloseAlertPopup}
                disableOutsideClose={true}
                title="Provider Review"
                bodyText={`You are acknowledging that you have reviewed the lab for the ${patientName}`}
                confirmButtonName={'Acknowledge'}
                cancelButtonName={`Cancel`}
                confirmButtonColor={'#5571c6'}
                onSuccess={handleAcknoledge}
                customHandleClose={(event) => handleIsPreview(event, labOrderId, false)}
            />
            <Modal open={openModal} onClose={handleModalClose}>
                <div className="lab-modal-container">
                    <header>
                        <span>Viewing Unmatched Results</span>
                        <CloseIcon
                            style={{
                                color: 'gray',
                                cursor: 'pointer',
                            }}
                            onClick={handleModalClose}
                        />
                    </header>
                    <main>
                        <Divider orientation="horizontal" className="divider_height" flexItem />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                                width: '100%',
                                margin: '2px',
                            }}>
                            <div style={{ marginRight: '2px', width: '48%' }}>
                                <SearchPatientInput
                                    value={patientAssignId}
                                    handleAddRow={(value) => {
                                        setPaitenAssigntId(value?.id)
                                        setPatientDetails(value)
                                    }}
                                    patientStoreIconStyle={{
                                        position: 'absolute',
                                        bottom: 4,
                                        right: 5,
                                    }}
                                    placeholder="Select Patient to match result"
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                {/* <SearchCHCProviderInput
                                    value={providerAssignId}
                                    placeholder="Select Provider"
                                    handleAddRow={(value) => {
                                        setProviderAssigntId(value?.id)
                                        setProviderDetails(value?.provider_details)
                                    }}
                                /> */}
                            </div>
                        </div>

                        <Divider orientation="horizontal" className="divider_height" flexItem />
                        <div
                            style={{
                                width: '100%',
                                height: '400px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {pdf ? (
                                <iframe
                                    src={pdf}
                                    width="100%"
                                    height="390px"
                                    style={{ userSelect: 'none' }}
                                />
                            ) : (
                                <div style={{ textAlign: 'center' }}>Lab Report Not Found !!</div>
                            )}
                        </div>
                    </main>
                    <footer>
                        <Button
                            variant="contained"
                            className="white-btn"
                            // disabled={!patientAssignId && !providerAssignId}
                            onClick={handleModalClose}>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            variant="contained"
                            className="blue-btn"
                            disabled={!patientAssignId}
                            onClick={(e) => {
                                handleOpenAlert1Popup(e)
                            }}>
                            <span>Confirm</span>
                        </Button>
                        <CustomAlertPopOver
                            anchorEl={anchorEl1}
                            handleClose={handleCloseAlert1Popup}
                            disableOutsideClose={true}
                            title="Confirm Patient Match"
                            bodyText={`You are matching this lab with: ${patientDetails?.label} ${patientDetails?.birthDate} - ${patientDetails?.phoneNumber}`}
                            confirmButtonName={'Match'}
                            cancelButtonName={`Cancel`}
                            confirmButtonColor={'#5571c6'}
                            onSuccess={handleMatch}
                        />
                    </footer>
                </div>
            </Modal>
        </Box>
    )
}
