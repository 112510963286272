import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, IconButton, ListItem, Typography, makeStyles } from '@material-ui/core'
import { ReactComponent as CopyIcon } from '../../../../assests/icons/copyNew.svg'
import CheckboxOption from '../../reusable/CheckboxOptions'
import { Divider, TextField } from '@mui/material'
import TextWithMacrosNew from '../../reusable/TextWithMacrosNew'
import { toSnakeCase } from 'utilities/stringOperations'
import { headerNames } from './headerName'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { formatString } from 'containers/CalendarScheduler/utils'
import { ReactComponent as CommentIcon } from 'assests/icons/commentIcon.svg'
import { ReactComponent as TrashIcon } from 'assests/icons/trash.svg'
import { ReactComponent as CommentAddedIcon } from 'assests/icons/comment1Icon.svg'
import PreviousCNDrawer from 'components/ClinicalNotes/reusable/PreviousCNDrawer'
import moment from 'moment'
import ROSCopy from './ROSCopy'
import copyNew from 'assests/icons/copyNew.svg'

const useStyles = makeStyles((theme) => ({
    outsideContainer: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        padding: 10,
    },
    insideContainer: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '600px',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 20px',
        background: '#F8F9FB'
    },
    copy: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        cursor: 'pointer'
    },
    horizontalDivider: {
        width: '100%',
        borderTop: '1px solid #EAECF0',
    },
    formContainer: {
        padding: '30px',
        display: 'flex',
        gap: 20,
    },
    //---------------Left Bar----------------------
    leftBar: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '20%',
        paddingTop: 10,
    },
    leftBarContent: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 0px 10px 0px',
        borderBottom: '1px solid #EDF1F6',
        // width: '80%',
    },
    leftBarAction: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        // padding: '0px 0px 10px 0px',
        // borderBottom: '1px solid #EDF1F6',
        // width: '80%',
    },
    selectedBar: {
        height: '20px',
        borderRadius: '5px',
        marginRight: '10px',
        width: '4px',
    },
    selectedColor: {
        background: '#5571C6',
    },
    nonSelectedColor: {
        background: 'transparent',
    },
    noKnownIssue: {
        display: 'flex',
        // justifyContent: 'space-between',
        alignItems: 'center',
        gap: 10,
        '& .MuiFormControlLabel-root': {
            marginLeft: '10px !important',
            marginRight: '4px !important',
            '& .MuiIconButton-root ': {
                padding: '0 !important',
            },
        },
        '& .MuiSwitch-thumb': {
            background: '#303E4E !important'
        },
        '& .MuiSwitch-track': {
            background: '#fff',
            border: '0.5px solid black'
        },
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                background: '#fff !important'
            },
            '& .MuiSwitch-track': {
                background: '#5571C6 !important',
                border: '1px solid #5571C6 !important'
            }
        },
    },
    leftBarItem: {
        color: '#242424',
        fontWeight: 400,
        fontSize: 14,
        width: '100%',
        cursor: 'default',
    },
    leftBarItemNarrative: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 14,
        cursor: 'default',
    },
    sectionCheckCount: {
        borderRadius: '50%',
        background: '#F0AE5F',
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
            color: 'white',
            fontSize: 12,
            padding: '0px 2px',
            cursor: 'default'
        }
        
    },
    // ---------------Main Content---------------------
    mainContent: {
        maxHeight: '650px',
        border: '1px solid #CFCFCF',
        borderRadius: 5,
        width: '76%',
        padding: '20px 10px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        // gap: 10,
        padding: '0 10px',
        '& div .MuiDivider-root': {
            marginBottom: 10,
        },
    },
    // sectionTitle: {
    //     padding: '0 10px'
    // },
    sectionParts: {
        padding: '0 20px 16px 20px',
        '& .MuiIconButton-root': {
            paddingRight: '0 !important',
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
        },
        '& .MuiFormControlLabel-root .MuiFormControlLabel-label .MuiTypography-root span': {
            color: '#303E4E !important',
            fontSize: 16,
            width: '100%',
            display: 'block',
        },
    },
    sectionTitleText: {
        color: '#424242',
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 12
    },
    sectionSubtitleText: {
        color: '#667689',
        fontSize: 10,
    },
    checkboxGroup: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        width: '100%',
        columnGap: 45,
        '& .MuiFormControlLabel-root': {
            width: '30% !important',
        },
        // marginRight: 1000
    },
    sectionNKI: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        '& .MuiSwitch-thumb': {
            background: '#303E4E !important'
        },
        '& .MuiSwitch-track': {
            background: '#fff',
            border: '0.5px solid black'
        },
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                background: '#fff !important'
            },
            '& .MuiSwitch-track': {
                background: '#5571C6 !important',
                border: '1px solid #5571C6 !important'
            }
        },
    },
    trashIcon: {
        '& path': {
            fill: '#E81A1A !important'
        }
    },
    copy_button: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        background: '#FFF',
        fontSize: '12px',
        color: '#303E4E',
        fontWeight: '400',
        height: '26px',
        marginRight: '8px',
        padding: '4px 8px',
    },
}))


function ReviewOfSystems(props) {

    const classes = useStyles()
    const sectionRef = useRef(null)

    const [systemHeaders, setSystemHeaders] = useState([])

    const [sectionCheckCount, setSectionCheckCount] = useState({})

    const [commentAddToggle, setCommentAddToggle] = useState({})

    const [firstRender, setFirstRender] = useState(false)

    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [copySelectionFieldNames, setCopySelectionFieldNames] = useState([])
    const [selectAllCheck, setSelectAllCheck] = useState(false)
    const [copiedText, setCopiedText] = useState(props?.values?.copiedDate ? true : false)


    const handleCloseDrawer = () => {
        setCopyModalOpen(false)
        setCopySelectionFieldNames([])
        setSelectAllCheck(false)
    }

    const handleChangecopy = () => {
        copySelectionFieldNames?.map(selection => {
            if(props?.lastNarrative[selection]?.comments !== "") {
                setCommentAddToggle((prevProps) => ({
                    ...prevProps,
                    [selection]: true
                }))
            }
            setSectionCheckCount((prevProps) => ({
                ...prevProps,
                [selection]: props?.lastNarrative[selection]?.symptoms?.length
            }))
            props?.onChange(`${props?.fieldName}.${selection}`, props?.lastNarrative[selection])
        })
        handleCloseDrawer()
        setCopiedText(true)
        props?.onChange(`${props?.fieldName}.copiedDate`, moment(props.copyDate).format('MM/DD/YYYY'))
    }

    const handleUnsetCopiedText = () => {
        setCopiedText(false)
        props?.onChange(`${props?.fieldName}.copiedDate`, null)
    }

    const handleSetSelectedValues = (selections) => {
        setCopySelectionFieldNames([...selections])
        if(selections?.length === Object?.keys(props?.values)?.length)
            setSelectAllCheck(true)
        else
            setSelectAllCheck(false)
    }

    const handleSelectAllChecks = (checkedValue) => {
        setSelectAllCheck(checkedValue)
        if(checkedValue)
            setCopySelectionFieldNames(Object?.keys(props?.values))
        else
            setCopySelectionFieldNames([])
    }

    const handleChangeTab = (slectedHeader) => {
        setSystemHeaders((state) => {
            return state?.map((stateItem) => {
                if (stateItem?.nativeName === slectedHeader) {
                    return {
                        ...stateItem,
                        selected: true,
                    }
                }
                return {
                    ...stateItem,
                    selected: false,
                }
            })
        })
    }

    const countTooltip = useCallback((list) => {
        return list?.symptoms?.map(listItem => {
            return (
                <ListItem style={{ cursor: 'default' }} key={listItem}>{formatString(listItem)}</ListItem>
            )
        }) 
    }, [props?.values])

    useEffect(() => {
        setSystemHeaders(headerNames(props?.values))

        if(!firstRender) {
            const checkCountObj = {}, commentToggle = {}
            Object.keys(props?.values || {})?.forEach((header) => {
                if(header !== "narrative") {
                    checkCountObj[header] = props?.values[header]?.symptoms?.length
                    commentToggle[header] = props?.values[header]?.comments !== "" ? true : false
                }
            })
            setSectionCheckCount(checkCountObj)
            setCommentAddToggle(commentToggle)
            setFirstRender(true)
        }
    }, [props?.values])

    return (
        // <div className={classes.outsideContainer}>
            <div className={classes.insideContainer} style={{ margin: '30px 0' }}>
                <div className={classes.header}>
                    <div style={{
                        display: 'flex',
                        gap: 10,
                        alignItems: 'center'
                    }}>
                        <Typography
                            style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: '#303E4E',
                                padding: '5px 0',
                            }}>
                            Review of Systems
                        </Typography>

                        {(copiedText && props?.copyDate) && <Typography>
                            Copied from {props?.copyDate ? moment(props?.copyDate)?.format('MM/DD/YYYY') : ''}
                        </Typography>}
                    </div>

                    <Button
                        onClick={() => setCopyModalOpen(true)}
                        disabled={!props?.lastNarrative || props?.nonEditable}
                        startIcon={<img src={copyNew} alt="" style={{ height: '9px', width: '7px' }} />}
                        className={classes.copy_button}>
                        {' '}
                        Copy: {props?.copyDate ? moment(props?.copyDate)?.format('MM/DD/YYYY') : ''}
                    </Button>
                </div>

                <span className={classes.horizontalDivider}></span>

                <div className={classes.formContainer}>
                    <div className={classes.leftBar}>
                        {systemHeaders?.map((leftBarItem) => {
                            return (
                                <div
                                    className={`${classes.leftBarContent}`}
                                    key={leftBarItem.header}>
                                    <span
                                        className={`${classes.selectedBar} ${
                                            leftBarItem?.selected
                                                ? classes.selectedColor
                                                : classes.nonSelectedColor
                                        }`}></span>
                                    <div className={classes.leftBarAction}>
                                        <div style={{ display: 'flex', gap: 4, justifyContent: 'flex-start', width: '100%' }}>
                                        <Typography
                                            style={{ width: 'fit-content' }}
                                            onClick={() => {
                                                sectionRef.current = document?.getElementById(
                                                    `${leftBarItem.nativeName}`,
                                                )
                                                sectionRef.current?.scrollIntoView({
                                                    top: sectionRef.current.offsetTop,
                                                    behavior: 'smooth',
                                                })
                                                handleChangeTab(leftBarItem?.nativeName)
                                            }}
                                            className={
                                                leftBarItem?.nativeName !== 'narrative'
                                                    ? classes.leftBarItem
                                                    : classes.leftBarItemNarrative
                                            }>
                                            {leftBarItem?.header}
                                        </Typography>
                                        
                                        {(leftBarItem?.nativeName !== "narrative" && sectionCheckCount[leftBarItem?.nativeName]) > 0 && 
                                            <HtmlTooltip 
                                                arrow
                                                placement='top'
                                                title={countTooltip(props?.values[leftBarItem?.nativeName])}>
                                                <div className={classes.sectionCheckCount}>
                                                    <Typography>{sectionCheckCount[leftBarItem?.nativeName]}</Typography>
                                                </div>
                                            </HtmlTooltip>
                                        }

                                        {(leftBarItem?.nativeName !== "narrative" && commentAddToggle[leftBarItem?.nativeName] && props?.values[leftBarItem?.nativeName]?.comments !== "") &&
                                            <HtmlTooltip 
                                                arrow
                                                placement='top'
                                                title={props?.values[leftBarItem?.nativeName]?.comments}>
                                                <CommentAddedIcon />
                                            </HtmlTooltip>
                                        }
                                        </div>

                                        {leftBarItem?.nativeName !== 'narrative' && (
                                            <span className={classes.noKnownIssue}>
                                                <Typography className={classes.leftBarItem} style={{ marginTop: 3 }}>
                                                    NKI
                                                </Typography>
                                                <AntSwitch
                                                    checked={
                                                        props?.values[leftBarItem?.nativeName]
                                                            ?.no_known_issues
                                                    }
                                                    onChange={(event) => {
                                                        const { checked } = event?.target
                                                        props?.onChange(
                                                            `${props?.fieldName}.${leftBarItem?.nativeName}.no_known_issues`,
                                                            checked,
                                                        )
                                                        if(checked){
                                                            props?.onChange(
                                                                `${props?.fieldName}.${leftBarItem?.nativeName}.symptoms`,
                                                                [],
                                                            )
                                                            props?.onChange(
                                                                `${props?.fieldName}.${leftBarItem?.nativeName}.comments`,
                                                                "",
                                                            )
                                                            setSectionCheckCount((prevProps) => ({
                                                                ...prevProps,
                                                                [leftBarItem?.nativeName]: 0
                                                            }))
                                                            setCommentAddToggle((prevProps) => ({
                                                                ...prevProps,
                                                                [leftBarItem?.nativeName]: false
                                                            }))
                                                        }
                                                        handleUnsetCopiedText()
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className={classes.mainContent}>
                        {systemHeaders?.map((section) => {
                            if (section?.nativeName === 'narrative') {
                                return (
                                    <div
                                        className={classes.section}
                                        key={section.nativeName}
                                        id={`${section.nativeName}`}>
                                        <div className={classes.sectionTitle}>
                                            {/* <Divider
                                                sx={{
                                                    '&::before': {
                                                        width: '1%', // Clear the default content
                                                        background: '#E0E0E0',
                                                    },
                                                    '&::after': {
                                                        background: '#E0E0E0',
                                                        width: '97%',
                                                    },
                                                }}
                                                textAlign="left"
                                                flexItem
                                                style={{ backgroundColor: 'transparent' }}>
                                                <Typography className={classes.sectionTitleText}>
                                                    {section?.header}
                                                </Typography>
                                            </Divider> */}
                                            <Typography className={classes.sectionTitleText}>
                                                {section?.header}
                                            </Typography>
                                        </div>

                                        <div className={`${classes.sectionParts}`}>
                                            <TextWithMacrosNew
                                                name={`${props?.fieldName}.narrative`}
                                                onChange={(fieldName, text) => {
                                                    props?.onChange(fieldName, text)
                                                    handleUnsetCopiedText()
                                                }}
                                                isDisabled={props?.nonEditable}
                                                rows={12}
                                                withoutMacro={false}
                                                values={props?.values?.narrative}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div
                                    className={classes.section}
                                    key={section.nativeName}
                                    ref={sectionRef}
                                    id={`${section.nativeName}`}>
                                    {/* <Divider
                                        sx={{
                                            '&::before': {
                                                width: '1%', // Clear the default content
                                                background: '#E0E0E0',
                                            },
                                            '&::after': {
                                                background: '#E0E0E0',
                                                width: '97%',
                                            },
                                        }}
                                        textAlign="left"
                                        flexItem
                                        style={{ backgroundColor: 'transparent' }}>
                                        
                                    </Divider> */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 18px' }}>
                                        <Typography className={classes.sectionTitleText}>
                                            {section?.header}
                                        </Typography>
                                        <div className={classes.sectionNKI}>
                                            <AntSwitch
                                                checked={
                                                    props?.values[section?.nativeName]?.no_known_issues
                                                }
                                                onChange={(event) => {
                                                    const { checked } = event?.target
                                                    props?.onChange(
                                                        `${props?.fieldName}.${section.nativeName}.no_known_issues`,
                                                        checked,
                                                    )
                                                    if(checked){
                                                        props?.onChange(
                                                            `${props?.fieldName}.${section?.nativeName}.symptoms`,
                                                            [],
                                                        )
                                                        props?.onChange(
                                                            `${props?.fieldName}.${section?.nativeName}.comments`,
                                                            "",
                                                        )
                                                        setSectionCheckCount((prevProps) => ({
                                                            ...prevProps,
                                                            [section?.nativeName]: 0
                                                        }))
                                                        setCommentAddToggle((prevProps) => ({
                                                            ...prevProps,
                                                            [section?.nativeName]: false
                                                        }))
                                                    }
                                                    handleUnsetCopiedText()
                                                }}
                                            />
                                            <Typography style={{ color: '#303E4E' }}>
                                                No known issues
                                            </Typography>
                                        </div>
                                    </div>

                                    {section?.nativeName === 'constitutional' && (
                                        <div className={classes.sectionParts}>
                                            <Typography className={classes.sectionSubtitleText}>
                                                Please look at the list of physical symptoms below
                                                and check off any that you have experienced in the
                                                last several days. If you have NOT experienced any
                                                symptoms in an area, be sure to check "None of the
                                                above" for that area. If you are filling this out on
                                                behalf of the patient, please answer from the
                                                patient's perspective.
                                            </Typography>
                                        </div>
                                    )}

                                    <div
                                        className={`${classes.sectionParts} ${classes.checkboxGroup}`}>
                                        {section?.checkboxOptions?.map((checkBox) => {
                                            return (
                                                <CheckboxOption
                                                    key={checkBox?.display}
                                                    option={{
                                                        value: false,
                                                        label: checkBox?.display,
                                                    }}
                                                    disabled={props?.values[section?.nativeName]?.no_known_issues}
                                                    checked={props?.values[section?.nativeName]?.symptoms?.includes(toSnakeCase(checkBox?.display))}
                                                    onChange={(event) => {
                                                        let allSymptoms =
                                                            props?.values[section?.nativeName]
                                                                ?.symptoms
                                                        if (event?.target?.checked) {
                                                            if (
                                                                !allSymptoms?.includes(
                                                                    toSnakeCase(checkBox?.display),
                                                                )
                                                            ){
                                                                allSymptoms?.push(
                                                                    toSnakeCase(checkBox?.display),
                                                                )
                                                            }
                                                        } else {
                                                            allSymptoms = allSymptoms?.filter(
                                                                (item) =>
                                                                    item !==
                                                                    toSnakeCase(checkBox?.display),
                                                            )
                                                        }
                                                        props?.onChange(
                                                            `${props?.fieldName}.${section.nativeName}.symptoms`,
                                                            allSymptoms,
                                                        )
                                                        setSectionCheckCount((prevProps) => ({
                                                            ...prevProps,
                                                            [section?.nativeName]: allSymptoms?.length
                                                        }))
                                                        handleUnsetCopiedText()
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>

                                    <div className={classes.sectionParts}>
                                        {commentAddToggle[section?.nativeName] ?
                                            <div
                                                style={{ 
                                                    display: 'flex', 
                                                    justifyContent: 'space-between', 
                                                    alignItems: 'center', 
                                                    cursor: 'pointer' 
                                                }}>
                                                <Typography>Comment</Typography>
                                                { props?.values[section?.nativeName]?.comments &&
                                                    <div 
                                                        onClick={() => {
                                                            setCommentAddToggle((prevProps) => ({
                                                                ...prevProps,
                                                                [section?.nativeName]: false
                                                            }))
                                                            props?.onChange(`${props?.fieldName}.${section?.nativeName}.comments`, '')
                                                        }} 
                                                        style={{ display: 'flex', gap: 5, alignItems: 'center', cursor: 'pointer' }}>
                                                        <TrashIcon className={classes.trashIcon}/>
                                                        <Typography style={{ color: '#E81A1A' }}>Remove Comment</Typography>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div 
                                                onClick={() => {
                                                    if(!props?.values[section?.nativeName]?.no_known_issues) {
                                                        setCommentAddToggle((prevProps) => ({
                                                            ...prevProps,
                                                            [section?.nativeName]: true
                                                        }))
                                                    }
                                                }}
                                                style={{ 
                                                    display: 'flex', 
                                                    gap: 5, 
                                                    alignItems: 'center', 
                                                    cursor: props?.values[section?.nativeName]?.no_known_issues ? 'default' : 'pointer' 
                                                }}>
                                                <CommentIcon />
                                                <Typography style={{ color: props?.values[section?.nativeName]?.no_known_issues ? 'rgba(85, 113, 198, 0.5)' : 'rgb(85, 113, 198)' }}>Add comment</Typography>
                                            </div>
                                        }
                                        {commentAddToggle[section?.nativeName] &&
                                            <TextField
                                                name={`${props?.fieldName}.${section?.nativeName}.comments`}
                                                variant="outlined"
                                                style={{ marginTop: 10 }}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '60px !important',
                                                    },
                                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                        {
                                                            fontSize: 14,
                                                            color: '#989CA8 !important',
                                                            fontWeight: 400,
                                                            opacity: 1,
                                                        },
                                                }}
                                                placeholder="Comment"
                                                multiline
                                                maxRows={2}
                                                value={props?.values[section?.nativeName]?.comments}
                                                onChange={(e) => {
                                                    const { name, value } = e.target
                                                    props?.onChange(name, value)
                                                    handleUnsetCopiedText()
                                                }}
                                            />
                                        }
                                    </div>

                                    <hr style={{ margin: '1px 18px', borderColor: '#E0E0E0', borderStyle: 'dashed' }}/>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <PreviousCNDrawer
                    open={copyModalOpen}
                    handleClose={handleCloseDrawer}
                    categoryName={'Review of Systems'}
                    lastNarrative={props?.lastNarrative}
                    lastUpdateDate={props?.copyDate}
                    // narrativeText={'Please enter the patient’s history of present illness'}
                    handleChangecopy={handleChangecopy}
                    customCheckAllValue={selectAllCheck}
                    customCheckAllHandler={handleSelectAllChecks}
                    applyButtonDisabled={copySelectionFieldNames?.length === 0}
                >
                    <ROSCopy
                        fieldName={props?.fieldName}
                        lastNarrative={props?.lastNarrative}
                        copySelectionFieldNames={copySelectionFieldNames}
                        handleSetSelectedValues={handleSetSelectedValues}
                    />
                </PreviousCNDrawer>
            </div>
        // </div>
    )
}

export default ReviewOfSystems
