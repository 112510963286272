import moment from 'moment'
import React from 'react'

//mui-components
import { Grid, Typography } from '@mui/material'

//utility functions and components
function StaffCard({ parentProps, classes }) {
    return (
        <div style={{ width: '340px' }}>
            <Grid>
                <div
                    className={classes.item_header_container}
                    style={{
                        borderBottom: `2px solid ${
                            parentProps?.event?.color_tag ||
                            (parentProps.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                        }`,
                    }}>
                    <span>
                        <Typography
                            noWrap
                            className={`${classes.item_header_text} ${classes.font_weight_500}`}>
                            {moment(new Date(parentProps?.event?.start)?.getTime())?.format(
                                'hh:mm A',
                            )}
                            {` - `}
                            {moment(new Date(parentProps?.event?.end)?.getTime())?.format(
                                'hh:mm A',
                            )}
                        </Typography>
                    </span>
                </div>

                <Grid item xs={12} className={classes.text_wrapper} style={{ paddingTop: '5px' }}>
                    <div
                        className={`${classes.item_text} ${classes.default_flex_view}`}
                        style={{
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            gap: 2,
                        }}>
                        <span
                            className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                            <span
                                className={classes.font_weight_500}
                                style={{
                                    fontSize: '16px',
                                    color: '#303E4E',
                                }}>
                                {parentProps?.event?.code_title}
                            </span>
                        </span>
                        <span
                            className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                            <span
                                className={classes.font_weight_400}
                                style={{
                                    fontSize: '16px',
                                    color: '#303E4E',
                                }}>
                                {parentProps?.event?.admin_code}
                            </span>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default StaffCard
