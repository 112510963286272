import React from 'react'
import { LoadingButton } from '@mui/lab'
import AccordionFilter from 'shared/elements/Select/AccordionFilter'
import { makeStyles, withStyles } from '@material-ui/styles'
import { ReactComponent as FilterIcon } from '../../../assests/images/filter.svg'
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,
    Chip,
} from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionNewFilter from './AccordionNewFilter'

const useStyles = makeStyles((theme) => ({
    filter_btn: {
        width: '130px !important',
        fontWeight: 'normal !important',
        borderRadius: '10px !important',
        background: '#5571C6 !important',
        color: 'white !important',
        padding: '11px !important',
        height: '40px !important',
        textTransform: 'capitalize !important',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '600',
        borderRadius: '50%',
        background: 'white',
        color: '#5571C6',
        padding: '5px',
        marginLeft: 6,
        height: '14px',
        width: '14px',
        fontSize: '14px',
    },
}))
const MultiFilter = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [filterEl, setFilterEl] = React.useState(null)
    const openFilter = Boolean(filterEl)
    const id = openFilter ? 'simple-popper' : undefined

    const handleFilterClick = (event) => {
        setFilterEl(filterEl ? null : event.currentTarget)
        if (props?.handleFilterClick) {
            props?.handleFilterClick()
        }
    }
    const handleFilterClose = () => {
        setFilterEl(null)
    }
    return (
        <>
            <LoadingButton
                onClick={handleFilterClick}
                variant="contained"
                className={classes.filter_btn}>
                <FilterIcon />
                <span
                    style={{
                        marginLeft: 6,
                        fontSize: '16px',
                    }}>
                    Filters
                </span>
                {props?.totalNoOfFilterUsed > 0 && (
                    <span className={classes.noOfFilter}>{props?.totalNoOfFilterUsed}</span>
                )}
            </LoadingButton>
            <Popover
                id={id}
                open={openFilter}
                anchorEl={filterEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="popover_card_holder" style={{ width: '335px' }}>
                    <div className="heading_holder">
                        <h2 className="heading" style={{ fontSize: 16 }}>
                            Filter By:
                        </h2>
                        <p
                            className="clear_holder"
                            onClick={() => {
                                props.handleClearFilter()
                            }}
                            style={{ fontSize: 16 }}>
                            Clear All Filters
                        </p>
                    </div>
                    {props.filterOptionsArrayObject?.length > 0 &&
                        props.filterOptionsArrayObject?.map((item, i) => (
                            <div className="cstm_accordion_holder" key={i}>
                                <Accordion expanded={item.AccordionExpanded}>
                                    <AccordionSummary
                                        className="summary_holder"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => {
                                            item.handleAccordionExpand(item?.key)
                                        }}>
                                        <Typography>{item?.name}</Typography>
                                        {item?.value?.length > 0 && (
                                            <Chip
                                                label={item?.value?.length}
                                                onDelete={() => {
                                                    props.handleClearSingleFilter(item?.key)
                                                }}
                                            />
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails className="details_holder">
                                        {item?.options?.length >= 0 && (
                                            <AccordionNewFilter
                                                searchInputType={item?.searchInputType}
                                                placeholder={item?.name}
                                                setScrollBarPosition={
                                                    item.AccordionExpanded
                                                        ? item?.setScrollBarPosition
                                                        : 0
                                                }
                                                name={item?.key}
                                                value={item?.value}
                                                options={item?.options}
                                                loadingOptions={item?.loading}
                                                filterValue={item?.filterValue}
                                                handleChange={(option, checked) => {
                                                    props.handleChange(
                                                        option,
                                                        checked,
                                                        item?.value,
                                                        item?.key,
                                                    )
                                                }}
                                                handleChangePage={
                                                    item?.handleOnScrollChangePage &&
                                                    ((value) => {
                                                        value && item?.handleOnScrollChangePage
                                                            ? item?.handleOnScrollChangePage(value)
                                                            : ''
                                                    })
                                                }
                                                filterSearch={
                                                    item?.filterSearch &&
                                                    ((value) => {
                                                        item?.filterSearch &&
                                                            item?.filterSearch(value, item?.key)
                                                    })
                                                }
                                                scrollBarPosition={
                                                    item?.scrollBarPosition &&
                                                    ((value) => {
                                                        item?.scrollBarPosition &&
                                                            item?.scrollBarPosition(
                                                                value,
                                                                item?.key,
                                                            )
                                                    })
                                                }
                                                setFilterValueField={
                                                    item?.setFilterValue &&
                                                    ((value) => {
                                                        item?.setFilterValue &&
                                                            item?.setFilterValue(value, item?.key)
                                                    })
                                                }
                                            />
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))}
                </div>
            </Popover>
        </>
    )
}

export default MultiFilter
