export default {
    psychiatric_medication_history:
        'The patient does not have a recent history of taking any medications for psychiatric treatment.',
    was_available: (wasAvailable) =>
        `The patient was${wasAvailable ? '' : ' not'} present in the session.`,

    decision_maker: (decisionMaker) =>
        `The patient is ${decisionMaker === 'Yes' ? '' : 'not'} a medical decision maker`,

    participation: (participation = '') =>
        participation.toLowerCase() === 'physically present'
            ? 'was physically present in the session'
            : `was present via ${participation.toLowerCase()} in the session`,

    role: (role = '', name, decisionMaker, participation) =>
        `The patient's ${role.toLowerCase()}${name ? `, ${name},` : ''} ${
            decisionMaker !== undefined && decisionMaker !== ''
                ? `who is ${decisionMaker === 'Yes' ? '' : 'not'} a medical decision maker,`
                : ''
        } ${
            participation !== undefined
                ? `${
                      participation.toLowerCase() === 'physically present'
                          ? 'was physically present in the session.'
                          : `was present via ${participation.toLowerCase()} in the session.`
                  }`
                : 'was in the session.'
        } `,

    desc_social_situation: `The status of the patient's social network is:`,
    past_history: `Past, Family, and Social History remain unchanged as captured on intake and former session documents`,
    side_effects: `The patient did not report any side effects due to their medications.`,
    adherence: 'No concerns.',
    current_matrial_status: (status) => `The patient's marital status is ${status?.toLowerCase()}.`,
    intimate_relationship_status: (status) =>
        `The current status of their intimate relationship is that they ${
            status === 'Never been in a serious relationship' ? 'have' : 'are'
        } ${status?.toLowerCase()}`,
    intimate_satisfaction_level: (level) =>
        `their satisfaction level of the relationship is ${level?.toLowerCase()}`,
    sexual_orientation: (orientation) =>
        `The patient's sexual orientation is ${orientation?.toLowerCase()}`,
    current_living_situation: (situation) =>
        `The patient's current living situation is that they ${situation?.toLowerCase()}.`,
    currently_live_with_opt: `They currently reside with`,
    participate_in_spritual_act: (isSpiritual) =>
        `The patient ${isSpiritual ? '' : 'does not'} currently participate${
            isSpiritual ? 's' : ''
        } in spiritual activities.`,

    current_occupation_status: (status) =>
        `With regard to occupation, the patient is ${status?.toLowerCase()}.`,

    current_yearly_income: (income) => `The patient's annual income is ${income?.toLowerCase()}.`,
    longest_period_employmentment: (startDate, endDate, description, unemployed = false) =>
        `The patient's longest period of ${
            unemployed ? 'un' : ''
        }employment began ${startDate} and ended ${endDate}. ${
            description
                ? `The patient described this period of ${
                      unemployed ? 'un' : ''
                  }employment as: "${description}".`
                : ''
        }`,
    during_preg_prob: `The patient's mother had history of the following problems experienced during the patient's pregnancy/birth:`,
    during_preg_prob_none: `The patient denied any problems experienced by their mother during their pregnancy.`,
    complic_after_your_birth: (complicated) =>
        `Additionally, the patient ${complicated ? '' : 'did not '}experience${
            complicated ? 'd ' : ' any '
        }complications after their birth.`,
    difficulties_in_reaching: `The patient experienced difficulty in reaching the following developmental milestones:`,
    difficulties_in_reaching_none: `The patient denied experiencing any difficulties in reaching developmental milestones.`,
    childhood_atmos: `The patient described their childhood home atmosphere as`,
    childhood_challenges_none: `The patient denied experiencing any challenges during childhood.`,
    childhood_challenges: `The patient reported experiencing the following psychological or behavior problems during childhood:`,
    patient_allergies: `The patient has no known medication allergies.`,
    have_prob_in_school_none: `The patient denied experiencing any problems in school.`,
    have_prob_in_school: `The patient has a history of the following problems in school:.`,
    additional_schooling_none: `The patient denied requiring additional schooling outside of the classroom`,
    additional_schooling: `They also required the following additional schooling outside of the classroom:`,
    highest_edu_level: (education = '') =>
        `The patient's highest level of education is ${education.toLowerCase()}.`,
    comment_on_dev_and_edu: (comment = '') =>
        `Concerning their developmental or educational history, the patient additionally reported the following: ${comment.toLowerCase()}.`,

    medication_management: `Counseling provided to the patient/caregiver as outlined below. Addressed patient/caregiver concerns regarding current medication regimen including`,
    diagnosis_and_prog: `Addressed patient/caregiver concerns regarding diagnosis and prognosis including`,
    patient_self_mgmt: `Addressed patient/caregiver concerns regarding patient self-management including`,
    recent_stressors: `Addressed patient/caregiver concerns regarding impact of recent stressors including`,
    coordination_care: `Coordination of care provided to the patient/caregiver as outlined below.  Case collaboration with patient's`,
    referred_patient: `Coordination of care provided to the patient/caregiver as outlined below.  Referred patient/caregiver to`,
    academic_assesment: `Referred patient/caregiver to additional academic assessment and/or supports. This included`,
    premenstrual_symptoms_none: `The patient denies experiencing any common premenstrual symptoms.`,
    premenstrual_symptoms: `Their premenstrual symptoms include:`,
    contraception_opt: `The patient reports using`,
    contraception_methods_other: (text) =>
        `The patient reports using ${text} as a method of contraception.`,
    ever_been_pregnent: (numberOfPregnency) =>
        `been pregnant a total of ${numberOfPregnency} time(s)`,
    ever_given_birth: (numberOfBirth) => `given birth a total of ${numberOfBirth} time(s)`,
    had_any_miscarriage: (numberOfMiscarriage) =>
        `experienced a total of ${numberOfMiscarriage} miscarriage(s)`,
    had_any_abortion: (numberOfAbortion) =>
        `experienced a total of ${numberOfAbortion} abortion(s)`,
    ever_been_pregnent_not: `has never been pregnant`,
    ever_given_birth_not: `has never given birth`,
    had_any_miscarriage_not: `has never had any miscarriages`,
    had_any_abortion_not: `has never had any abortions`,
}
