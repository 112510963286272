import React from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Button as ButtonCustom } from 'components/custom'
import AddIcon from '@mui/icons-material/Add'

import { useStyles } from './styles'
import { DataGrid as MuiDataGrid, GridColumns } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NoRowsOverlayEl } from 'shared/elements'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { USStatesMapper } from 'containers/LocationSettings/LocationCreateDialog/constant'
import { Box } from '@mui/material'
import { allApiReffringProvider, getLocations, getSpecialty } from 'services/Calendar'
import Tooltip from '@mui/material/Tooltip'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { Pagination } from 'shared/elements/Pagination'
import { ReferringProviderForm } from './View/ReferringProviderForm'
import { useNotification } from 'shared/elements/Notification'

export const ReferringProviders = () => {
    const notification = useNotification()
    const classes = useStyles()
    // const [page, setPage] = useState(1)
    const [sortModel, setSortModel] = useState<{ sort?: any; field?: any }[]>([])
    const [isLoading, setLoading] = useState(false)
    const [refferingLocationList, setRefferingLocationList] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [appointmentModal, setAppointmentModal] = useState<{
        status?: boolean
        refferringItem?: any
    }>({
        status: false,
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const permissions = useSelector((state) => state?.permission?.referring_providers)

    const handleEditClick = (row: any) => {
        appointmentModalToggle(row)
    }
    const appointmentModalToggle = (refferringItem: any) => {
        setAppointmentModal({
            status: !appointmentModal.status,
            refferringItem: refferringItem,
        })
    }
    const [optionServiceLocation, setOptionServiceLocation] = useState<
        {
            key?: string | number
            value?: string | number
        }[]
    >()
    const [optionSpecialty, setOptionSpecialty] = useState<
        {
            key: string | number
            value: string | number
        }[]
    >()

    const columns: GridColumns = [
        {
            headerName: 'First Name ',
            headerClassName: 'super-app-theme--header',
            field: 'first_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Last Name',
            headerClassName: 'super-app-theme--header',
            field: 'last_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'NPI',
            headerClassName: 'super-app-theme--header',
            field: 'npi',
            flex: 0.7,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Specialty',
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 0.7,
            sortable: true,
            renderCell: ({ value }) => {
                return value?.specialty || '-'
            },
        },
        {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Phone',
            headerClassName: 'super-app-theme--header',
            field: 'phone',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '-'
                )
            },
        },
        {
            headerName: 'Fax',
            headerClassName: 'super-app-theme--header',
            field: 'fax',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') || '-'
                )
            },
        },
        {
            headerName: 'Service Location',
            headerClassName: 'super-app-theme--header',
            field: 'location',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return (
                    <Tooltip title={value?.description}>
                        <span>{value?.name}</span>
                    </Tooltip>
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('delete_referring_provider') &&
                !permissions.includes('modify_referring_provider'),
            renderCell: ({ row }) => {
                const menu = [] as any
                if (permissions.includes('modify_referring_provider')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleEditClick(row),
                    })
                }
                if (permissions.includes('delete_referring_provider')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDeleteReffringProvider(row.id),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this Referring providers?"
                    />
                )
            },
        },
    ]
    const handlePageChange = (value: any) => {
        setPage(value)
    }
    useEffect(() => {
        const abortController = new AbortController()
        refetchLocation()

        return () => abortController.abort()
    }, [page, sortModel])

    useEffect(() => {
        const abortController = new AbortController()
        getLocationExternal()
        getSpecialtyAll()
        return () => abortController.abort()
    }, [])

    const refetchLocation = async () => {
        setLoading(true)
        try {
            const res = await allApiReffringProvider.getAll(
                sortModel[0]?.sort
                    ? {
                          page,
                          size: limit,
                          [sortModel[0].sort]: sortModel[0].field,
                      }
                    : {
                          page,
                          size: limit,
                          asc: '',
                      },
            )

            setLoading(false)
            setRefferingLocationList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }
    const getLocationExternal = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            // console.log(res.data, 'check')
            if (locationExternal) {
                setOptionServiceLocation(
                    locationExternal.map((item) => ({
                        key: item.id,
                        value: item.name,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const getSpecialtyAll = async () => {
        try {
            const res = await getSpecialty({ size: 1000 })

            if (res.data?.data) {
                setOptionSpecialty(
                    res.data?.data?.map((item) => ({
                        key: item.id,
                        value: item.specialty,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const hanleSubmid = async (payload) => {
        setIsSubmitting(true)
        if (appointmentModal.refferringItem.id) {
            try {
                const update = await allApiReffringProvider.update(
                    payload,
                    appointmentModal.refferringItem.id,
                )
                notification('Referring provider is updated successfully !', 'success')
                await refetchLocation()
            } catch (error) {
                notification('Something went wrong !', 'error')
            } finally {
                setIsSubmitting(false)
            }
        } else {
            try {
                const create = await allApiReffringProvider.create(payload)
                notification('Referring Provider is created successfully!', 'success')
                await refetchLocation()
            } catch (error) {
                notification('Something went wrong !', 'error')
            } finally {
                setIsSubmitting(false)
            }
        }
        appointmentModalToggle(null)
    }

    const handleDeleteReffringProvider = async (id) => {
        try {
            const ResDelete = await allApiReffringProvider.delete(id)
            notification('successful delete!', 'success')
            await refetchLocation()
        } catch (error) {
            notification('delete failed!', 'error')
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Referring Providers</Typography>
                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_referring_provider']}>
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add Referring Provider"
                                    variant="contained"
                                    color="primary"
                                    onClick={appointmentModalToggle}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        rows={refferingLocationList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {appointmentModal.status && (
                <ReferringProviderForm
                    open={appointmentModal.status}
                    optionServiceLocation={optionServiceLocation}
                    optionSpecialty={optionSpecialty}
                    refferringItem={appointmentModal.refferringItem}
                    loading={isSubmitting}
                    onModalClose={appointmentModalToggle}
                    onSubmit={(payload) => hanleSubmid(payload)}
                />
            )}
        </Grid>
    )
}
