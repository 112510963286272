import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    OutlinedInput,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Input,
    FormControlLabel,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import Placeholder from 'shared/elements/Placeholder'
// import { Input } from '@material-ui/core'
// import { makeStyles } from '@mui/material/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    options: string[]
    value: string[]
    label?: string
    handleChange: (name: string, checked: boolean) => void
    placeholder?: string
}

const useStyles = makeStyles(() => ({
    label: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '0',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            display: 'none',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
    },
    box_options: {
        padding: '15px 0',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
        },
    },
}))

export const MultipleSelect = (props: Props) => {
    const { options, value, handleChange, label, placeholder } = props

    // const newOptions = options.map(option => option.label)

    const classes = useStyles()
    return (
        <Box>
            {label && (
                <Box component={'p'} className={classes.label}>
                    {label}
                </Box>
            )}
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                <InputLabel id="demo-multiple-checkbox-label" className={classes.placeholder}>
                    {placeholder ? placeholder : 'Select option'}
                </InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={value}
                    placeholder="lol"
                    onChange={(e: SelectChangeEvent<typeof value>) => {
                        console.log(e)
                    }}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected?.join(', ')}
                    // MenuProps={MenuProps}
                >
                    <Box className={classes.box_options}>
                        {options.map((name) => {
                            return (
                                <Box key={name} className={classes.box_option}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.indexOf(name) > -1}
                                                onChange={(e) => {
                                                    console.log(e.target.checked)
                                                    handleChange(name, e.target.checked)
                                                }}
                                                name={name}
                                            />
                                        }
                                        label={name}
                                    />
                                </Box>
                            )
                        })}
                    </Box>
                </Select>
            </FormControl>
        </Box>
    )
}
