import React from 'react'
import { UserRoles } from '../../constants/user-roles'
import { getLoggedInUserRole } from '../../utilities'
import {
    PatientDashboard,
    ClinicAdminDashboard,
    Tm2uDashboard,
    SuperAdminDashboard,
    ProviderDashboard,
} from '../../components/Dashboard'

class Dashboard extends React.Component {
    render() {
        const DashboardComponent = <PatientDashboard />

        switch (getLoggedInUserRole()) {
            case UserRoles.TENANT_ADMIN:
                return <ProviderDashboard />
            case UserRoles.PATIENT:
                return DashboardComponent
            case UserRoles.PROVIDER:
                return <ProviderDashboard />
            case UserRoles.TM2U_ADMIN:
                return <Tm2uDashboard />
            case UserRoles.TM2U_SUPER_ADMIN:
                return <SuperAdminDashboard />
            case UserRoles.CLINIC_CORDINATION:
                return <ClinicAdminDashboard />

            default:
                return PatientDashboard
        }
    }
}

export default Dashboard
