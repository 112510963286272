import React from 'react'
import { Grid, Modal, Typography, makeStyles } from '@material-ui/core'

import './index.scss'

const useStyles = makeStyles({
    header: {
        fontSize: 22,
        fontWeight: 500,
        marginTop: 24,
        textAlign: 'center',
        color: '#303E4E',
    },
})

const Modals = (props) => {
    const classes = useStyles()
    return (
        <Modal
            aria-labelledby={props.title}
            aria-describedby={props.description}
            open={props.open}
            // onClose={props.onClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            disableEnforceFocus
            disableAutoFocus
            {...props}
            title={props?.ifOffTitle && ''}
            onClose={(event, reason) => {
                if (props.backdropClick) {
                    props.onClose(event)
                }
                if (reason && reason === 'backdropClick') return

                if (props.onClose) {
                    props.onClose(event)
                }
            }}>
            <div
                className="modals-container"
                style={{
                    '--width': `${props.width || 900}px`,
                    ...(props.style || {}),
                }}>
                {!!props.title && (
                    <Typography className={`${props.dynaClass} ${classes.header}`}>
                        {props.title}
                        {/* <CloseIcon
                        style={{
                            color: '#FFF',
                            cursor: 'pointer',
                        }}
                        onClick={props.onClose}
                    /> */}
                    </Typography>
                )}
                {/* <body style={{height: props.showfooter ? "80%" : "90%"}}> */}
                <div className={props.footer && props.footer !== undefined ? 'show-footer' : ''}>
                    {props.children}
                </div>
                {props.footer && props.footer !== undefined && <footer>{props.footer}</footer>}
            </div>
        </Modal>
    )
}

/* Modals.defaultProps = {
  showfooter: true,
} */
/* Modals.Header = function (props) {
  return props.children;
} */

export default Modals
