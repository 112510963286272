import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { useHistory, useParams } from 'react-router-dom'
import { getLocationById } from 'services/Calendar'
import { createLocations, updateLocationById } from 'services/Calendar/index'
import { getListPractices } from 'services/Setting'
import { ZipCode } from 'shared/elements/FormItem/Input'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { USPhoneRegex, zipCodeRegex } from 'utilities/regex'
import { stateList } from './constant'
import { INITIAL_VALUES, PLACE_OF_SERVICE } from './schema'
import './styles.scss'
import Routes from 'constants/routes'

const useStyles = makeStyles((theme) => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
    container: {
        padding: 24,
        position: 'relative',
        height: 'calc(100vh - 70px)',
        overflowY: 'auto',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        // marginBottom: '1rem',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    sub_header: {
        fontSize: 16,
        fontWeight: 500,
        color: '#303E4E',
    },
    border_text_grid: {
        background: '#ffffff',
        marginTop: '-24px',
        display: 'table',
        marginBottom: '20px',
    },
    required_text: {
        color: 'red',
    },
    item_ric: {
        background: '#fbfcfc',
        padding: '5px 15px',
        marginBottom: '13px',
        borderRadius: '7px',
        '& .icon': {
            '& svg': {
                width: '22px',
                color: '#6c6c6c',
            },
        },
    },
    btn_anew: {
        fontSize: '14px',
        fontWeight: '400',
    },
    wrap_scroll_list: {
        maxHeight: '469px',
        overflowY: 'auto',
    },
}))

function LocationCreateDialog() {
    const classes = useStyles()
    const notification = useNotification()
    const history = useHistory()
    const { id: locationId } = useParams()
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [specialOptions, setSpecialOptions] = useState([])
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    useEffect(() => {
        const abortController = new AbortController()
        if (locationId) {
            getLocationDetails(locationId)
        }
        getSpecialtiesList()
        return () => abortController.abort()
    }, [locationId])

    const validationSchema = yup.object({
        name: yup
            .string()
            // .matches(
            //     /^[a-zA-Z0-9 ]+$/,
            //     'Service Location name does not includes special character!',
            // )
            .required('Service Location name is required!'),
        phone: yup
            .string()
            .matches(USPhoneRegex, 'Not a valid phone number!')
            .required('Phone is required!'),
        address1: yup
            .string()
            // .matches(
            //     /^[a-zA-Z0-9 ]+$/,
            //     'Address does not includes special character!',
            // )
            .required('Address Line 1 is required!'),
        address2: yup.string(),
        //     .matches(
        //         /^[a-zA-Z0-9 ]+$/,
        //         'Address does not includes special character!',
        // )
        physicalCity: yup.string().required('City is required!'),
        physicalState: yup.string().required('State is required!'),
        physicalZipCode: yup
            .string()
            .matches(zipCodeRegex, 'Not a valid zip code')
            .required('Zip Code is required!'),
        fax: yup
            .string()
            .required('Fax is required!')
            .matches(USPhoneRegex, 'Not a valid fax number!'),
        npi: yup.string().required('NPI is required!'),
        // clinical_practice_id: yup.string().required('Clinical Practice is required!'),
        place_of_service: yup.string().required('Place of Service is required!'),
    })
    const getLocationDetails = async (locationId) => {
        try {
            setLoadingSchedule(true)
            const response = await getLocationById(locationId)

            if (response?.data) {
                const {
                    city,
                    state,
                    postal,
                    address1,
                    address2,
                    phone_number,
                    npi,
                    place_of_service,
                    facility_taxonomy,
                    clia_number,
                    name,
                    // clinical_practice_id,
                    is_external,
                    fax,
                    rcopia_id,
                    external_id,
                } = response?.data

                setInitData({
                    ...INITIAL_VALUES,
                    is_external,
                    name,
                    physicalCity: city,
                    physicalState: state,
                    physicalZipCode: postal,
                    address1,
                    address2,
                    phone: phone_number,
                    npi,
                    facilityTaxonomy: facility_taxonomy,
                    CLIANumber: clia_number,
                    place_of_service,
                    // clinical_practice_id,
                    fax,
                    rcopia_id,
                    external_id,
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }
    const getSpecialtiesList = async () => {
        const response = await getListPractices({
            size: 10000,
        })
        console.log('response', response)
        setSpecialOptions(
            response?.data?.data.map((item) => ({
                key: item.id,
                value: item.name,
            })) || [],
        )
    }
    const handleLocationSubmit = async (payload) => {
        try {
            setIsSubmitting(true)
            let res
            // console.log('payload1', payload)
            if (locationId) {
                res = await updateLocationById({
                    id: locationId,
                    payload,
                })
                notification('A Service Location was successfully updated!', 'success')
            } else {
                res = await createLocations(payload)
                notification('A Service Location was successfully created!', 'success')
            }
            if (res) {
                history.push(Routes.SETTINGS.LOCATIONS_EDIT.replace(':id', res?.data?.id))
            }
        } catch (error) {
            const errorMsg = locationId
                ? 'A Service Location was not successfully updated!'
                : 'A Service Location was not successfully added!'

            notification(error?.data?.message || error?.message || errorMsg, 'error')
        } finally {
            setIsSubmitting(false)
        }
    }
    const handleSubmit = (values) => {
        const {
            name,
            phone,
            physicalCity,
            physicalState,
            physicalZipCode,
            address1,
            address2,
            npi,
            place_of_service,
            facilityTaxonomy,
            CLIANumber,
            // clinical_practice_id,
            rcopia_id,
            is_external,
            fax,
            external_id,
        } = values
        const payload = {
            is_external,
            name,
            city: physicalCity,
            state: physicalState,
            postal: physicalZipCode,
            address1: address1?.trim(),
            address2: address2?.trim(),
            phone_number: phone,
            npi,
            rcopia_id,
            place_of_service,
            facility_taxonomy: facilityTaxonomy,
            clia_number: CLIANumber,
            // clinical_practice_id,
            fax,
            external_id,
        }
        // console.log('payload', payload)
        handleLocationSubmit(payload)
    }

    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    return (
        <Grid container className={classes['container']}>
            <Grid container style={{ marginBottom: 16 }}>
                <Grid
                    item
                    xs={12}
                    style={{
                        width: 'fit-content',
                    }}>
                    <Box
                        style={{ cursor: 'pointer', display: 'inline-flex', marginBottom: '1rem' }}
                        onClick={() => history.push(Routes.SETTINGS.LOCATIONS)}>
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />
                        <Typography className={classes['text_back']}>
                            Back to service location list
                        </Typography>
                    </Box>
                </Grid>
                <Typography className={classes['header']}>
                    {locationId ? 'Update Service Location' : 'Add New Service Location'}
                </Typography>
            </Grid>
            <Grid container xs={12}>
                <hr
                    style={{
                        color: '#ddd',
                        width: '100%',
                    }}
                />
            </Grid>
            <Grid container className="appointment-booking-container">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            left: 0,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item className="referrals-form">
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        {/*------------------------------------------------Referral Information----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset style={{ marginBottom: '28px', borderRadius: '5px' }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Service Location:
                                    </Typography>
                                </legend>
                                <Grid container spacing={2}>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                        alignItems="flex-start">
                                        <Grid item xs={4}>
                                            <TextBox
                                                label="Service Location Name"
                                                required
                                                name="name"
                                                value={formik.values.name}
                                                placeholder="Enter location name"
                                                onChange={(value) =>
                                                    formik.setFieldValue('name', value)
                                                }
                                                error={
                                                    formik.touched.name &&
                                                    Boolean(formik.errors.name)
                                                }
                                                helperText={
                                                    formik.touched.name && formik.errors.name
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            style={{
                                                marginTop: '50px',
                                            }}>
                                            <CheckboxOption
                                                option={{
                                                    label: 'External Location',
                                                    value: false,
                                                }}
                                                onChange={(e) => {
                                                    formik.setFieldValue(
                                                        'is_external',
                                                        e.target.checked,
                                                    )
                                                }}
                                                disabled={!!locationId}
                                                checked={formik.values.is_external}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextBox
                                                label="Phone"
                                                required
                                                name="phone"
                                                placeholder="Enter location contact number"
                                                value={formik.values.phone}
                                                onChange={(value) =>
                                                    formik.setFieldValue(
                                                        'phone',
                                                        value.replace(/[^0-9]+/g, ''),
                                                    )
                                                }
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                error={
                                                    formik.touched.phone &&
                                                    Boolean(formik.errors.phone)
                                                }
                                                helperText={
                                                    formik.touched.phone && formik.errors.phone
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextBox
                                                label="Fax"
                                                required
                                                name="fax"
                                                placeholder="Enter fax number"
                                                value={formik.values.fax}
                                                onChange={(value) =>
                                                    formik.setFieldValue('fax', value)
                                                }
                                                inputProps={{
                                                    maxLength: 10,
                                                }}
                                                error={
                                                    formik.touched.fax && Boolean(formik.errors.fax)
                                                }
                                                helperText={formik.touched.fax && formik.errors.fax}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextBox
                                                label="NPI"
                                                required
                                                name="npi"
                                                value={formik.values.npi}
                                                placeholder="Enter NPI number"
                                                error={
                                                    formik.touched.npi && Boolean(formik.errors.npi)
                                                }
                                                helperText={formik.touched.npi && formik.errors.npi}
                                                onChange={(value) =>
                                                    formik.setFieldValue('npi', value)
                                                }
                                            />
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                            <Select
                                                label="Clinical Practice"
                                                required
                                                name="clinical_practice_id"
                                                value={formik.values.clinical_practice_id}
                                                displayEmpty={!formik.values.clinical_practice_id}
                                                placeholder="Select state"
                                                style={{ height: 40 }}
                                                error={
                                                    formik.touched.clinical_practice_id &&
                                                    Boolean(formik.errors.clinical_practice_id)
                                                }
                                                helperText={
                                                    formik.touched.clinical_practice_id &&
                                                    formik.errors.clinical_practice_id
                                                }
                                                options={specialOptions}
                                                onChange={(value) =>
                                                    formik.setFieldValue(
                                                        'clinical_practice_id',
                                                        value,
                                                    )
                                                }
                                            />
                                        </Grid> */}
                                        <Grid item xs={4}>
                                            <TextBox
                                                label="Service Location ID"
                                                name="rcopia_id"
                                                value={formik.values.rcopia_id}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                style={{ color: '#989CA8' }}
                                                placeholder="Service Location ID"
                                                error={
                                                    formik.touched.rcopia_id &&
                                                    Boolean(formik.errors.rcopia_id)
                                                }
                                                helperText={
                                                    formik.touched.rcopia_id &&
                                                    formik.errors.rcopia_id
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        {/*------------------------------------------------Location Information----------------------------------------------------*/}
                        <Grid item xs={12}>
                            <fieldset style={{ marginBottom: '28px', borderRadius: '5px' }}>
                                <legend>
                                    <Typography className={'heading-title'}>
                                        Location Information:
                                    </Typography>
                                </legend>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    spacing={2}
                                    alignItems="flex-start">
                                    <Grid item xs={12}>
                                        <span style={{ color: '#667689', fontSize: '14px' }}>
                                            Enter physical address
                                        </span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextBox
                                            label="Address line 1"
                                            required
                                            name="address1"
                                            placeholder="Enter address line 1"
                                            value={formik.values.address1}
                                            error={
                                                formik.touched.address1 &&
                                                Boolean(formik.errors.address1)
                                            }
                                            helperText={
                                                formik.touched.address1 && formik.errors.address1
                                            }
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    'address1',
                                                    value.replace(/[^0-9a-zA-Z ]+/g, ''),
                                                )
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextBox
                                            label="Address line 2"
                                            name="address2"
                                            placeholder="Enter address line 2"
                                            value={formik.values.address2}
                                            onChange={(value) =>
                                                formik.setFieldValue(
                                                    'address2',
                                                    value.replace(/[^0-9a-zA-Z ]+/g, ''),
                                                )
                                            }
                                            error={
                                                formik.touched.address2 &&
                                                Boolean(formik.errors.address2)
                                            }
                                            helperText={
                                                formik.touched.address2 && formik.errors.address2
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextBox
                                            label="City"
                                            required
                                            name="physicalCity"
                                            value={formik.values.physicalCity}
                                            placeholder="Enter city"
                                            error={
                                                formik.touched.physicalCity &&
                                                Boolean(formik.errors.physicalCity)
                                            }
                                            helperText={
                                                formik.touched.physicalCity &&
                                                formik.errors.physicalCity
                                            }
                                            onChange={(value) =>
                                                formik.setFieldValue('physicalCity', value)
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Select
                                            label="State"
                                            required
                                            name="physicalState"
                                            value={formik.values.physicalState}
                                            displayEmpty={!formik.values.physicalState}
                                            placeholder="Select state"
                                            style={{ height: 40 }}
                                            error={
                                                formik.touched.physicalState &&
                                                Boolean(formik.errors.physicalState)
                                            }
                                            helperText={
                                                formik.touched.physicalState &&
                                                formik.errors.physicalState
                                            }
                                            options={stateList.map((item) => ({
                                                key: item.state,
                                                value: item.stateLabel,
                                            }))}
                                            onChange={(value) =>
                                                formik.setFieldValue('physicalState', value)
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <ZipCode
                                            required
                                            name="physicalZipCode"
                                            placeholder="Enter zip code"
                                            value={formik.values.physicalZipCode}
                                            error={
                                                formik.touched.physicalZipCode &&
                                                Boolean(formik.errors.physicalZipCode)
                                            }
                                            helperText={
                                                formik.touched.physicalZipCode &&
                                                formik.errors.physicalZipCode
                                            }
                                            onChange={(value) => {
                                                formik.setFieldValue('physicalZipCode', value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            label="Place of Service"
                                            required
                                            name="place_of_service"
                                            value={formik.values.place_of_service}
                                            displayEmpty={!formik.values.place_of_service}
                                            placeholder="Select state"
                                            style={{ height: 40 }}
                                            error={
                                                formik.touched.place_of_service &&
                                                Boolean(formik.errors.place_of_service)
                                            }
                                            helperText={
                                                formik.touched.place_of_service &&
                                                formik.errors.place_of_service
                                            }
                                            options={PLACE_OF_SERVICE}
                                            onChange={(value) =>
                                                formik.setFieldValue('place_of_service', value)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                    </Grid>

                    <Grid container item justifyContent="flex-end" direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes['button_cancel']}
                                onClick={() => history.goBack()}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                disabled={isSubmitting}
                                className={classes.button_create}
                                onClick={formik.handleSubmit}>
                                {!locationId ? 'Save' : 'Update'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LocationCreateDialog
