import React from 'react'
import { searchDrug } from 'services/Patient/patient'
import { SearchBox } from 'shared/elements/FormItem/SearchBox'
import { stringConcatenation } from 'shared/utils'

interface ResponseDTO {
    brandName?: string | null
    code?: string | null
    display?: string | null
    highlightText?: string
    form?: string
    strength?: string
    key?: React.Key
    genericName?: string
}

interface Drug {
    brandName: string
    id?: string
    rcopia_id?: string
    nDCID?: string
    brand_name?: string
    genericName?: string
    strength?: string
    form?: string
    drug_group?: {
        group_id?: string
        group_name?: string
    }[]
}

interface Props {
    label?: string
    required?: boolean
    name?: string
    value?: {
        code: string
        display: string
        genericName?: string
    }
    placeholder?: string
    error?: boolean
    helperText?: string | boolean
    onChange: (value: any, form: string, strength: string) => void
}

export const SearchDrug = (props: Props) => {
    const { value, onChange, ...rest } = props

    return (
        <SearchBox<ResponseDTO>
            value={value}
            renderKey={value?.code ? 'code' : 'display'}
            searchHandler={(text) => {
                return searchDrug(text).then((resp) => {
                    const data = resp.data.data.filter((item) => item.id !== '') as Drug[]

                    return data.map((item) => ({
                        ...item,
                        code: item.id,
                        display: item.genericName,
                        // display:
                        //     item.brandName +
                        //     ', ' +
                        //     item.genericName +
                        //     ', ' +
                        //     item.strength +
                        //     ', ' +
                        //     item.form,
                        form: item.form,
                        strength: item.strength,
                        brandName: item.brandName,
                        // highlightText:
                        //     item.brandName +
                        //     ', ' +
                        //     item.genericName +
                        //     ', ' +
                        //     item.strength +
                        //     ', ' +
                        //     item.form,
                        highlightText: stringConcatenation(
                            item.brandName,
                            item.genericName,
                            item.strength,
                            item.form,
                        ),
                    }))
                })
            }}
            getOptionLabel={(option) => {
                return stringConcatenation(
                    option.brandName,
                    option.genericName,
                    option.strength,
                    option.form,
                )
            }}
            isOptionEqualToValue={(option, value) =>
                value.code === option.code || value.display === option.display
            }
            renderOption={(option) => option.highlightText}
            onChange={(option) =>
                onChange &&
                onChange(
                    {
                        code: option?.code,
                        brandName: option?.brandName,
                        firstDataBankMedID: option?.firstDataBankMedID,
                        form: option?.form,
                        genericName: option?.genericName,
                        id: option?.id,
                        nDCID: option?.nDCID,
                        rCopiaID: option?.rCopiaID,
                        rxnormID: option?.rxnormID,
                        strength: option?.strength,
                        display: option?.display,
                    },
                    option?.form as string,
                    option?.strength as string,
                )
            }
            {...rest}
        />
    )
}
