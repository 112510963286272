import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@mui/material/Collapse'
import { Box, Grid, Typography } from '@material-ui/core'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

const useStyles = makeStyles({
    header: {
        cursor: 'pointer',
        borderBottom: '1px solid #F5F6F7',
        marginBottom: 16,
        paddingBottom: 8,
    },
    sectionHeading: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
})

export default function CollapsePaper({
    header = 'Default Header',
    isCollapseDefault,
    collapseComp,
}) {
    const classes = useStyles()
    const [isCollapse, setIsCollapse] =
        React.useState(false)
    const handleChange = () => {
        setIsCollapse((prev) => !prev)
    }

    React.useEffect(() => {
        setIsCollapse(isCollapseDefault)
    }, [isCollapseDefault])

    return (
        <Collapse in={isCollapse} collapsedSize={40}>
            <Box sx={{ m: 1, margin: 0 }}>
                <Grid
                    container
                    className={classes.header}
                    justifyContent="space-between"
                    onClick={handleChange}>
                    <Typography
                        className={classes.sectionHeading}>
                        {header}
                    </Typography>
                    {isCollapse ? (
                        <RemoveRoundedIcon
                            style={{
                                fontSize: 24,
                                color: '#5571C6',
                            }}
                        />
                    ) : (
                        <AddRoundedIcon
                            style={{
                                fontSize: 24,
                                color: '#5571C6',
                            }}
                        />
                    )}
                </Grid>
                {collapseComp}
            </Box>
        </Collapse>
    )
}
