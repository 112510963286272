/* eslint-disable react/button-has-type */
import { Button } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import Man4Icon from '@mui/icons-material/Man4'
import { Box } from '@mui/material'

const BtnIconTop = ({ notification, Icon, styles, background, onPress }) => {
    return (
        <>
            {Icon ? (
                <Box className="item-icon-wrap">
                    {notification && (
                        <Box className="icon-notification">
                            <Man4Icon fontSize="small" />
                        </Box>
                    )}
                    <button
                        className="icon-svg-btn-top"
                        style={{
                            background,
                            cursor: 'pointer',
                        }}
                        onClick={onPress}>
                        {Icon}
                    </button>
                </Box>
            ) : null}
        </>
    )
}

export default BtnIconTop
