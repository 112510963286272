import { Box, Button, Grid, Tab, Tabs, Typography, styled, IconButton, Dialog, MenuItem, FormLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {Select as NewSelect} from '@material-ui/core'
import { Lab, Prescribe } from 'containers/PatientsSetting/demographic/OrderEntry'
import React, { useState, useEffect } from 'react'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { ReactComponent as CopyIcon } from 'assests/icons/copyNew.svg'
import { Chip, TextField } from '@mui/material'
import AddIcon from 'assests/icons/Add.svg';
import { ExpandMore } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getMacroList } from 'store/actions/macros'
import edit_icon from 'assests/icons/edit1_icon.svg'
import { SelectEl } from 'shared/elements'
import { DatePicker as DatePickerCustom } from 'shared/elements/DatePicker/DatePicker'
import { ReactComponent as CalenderIcon } from 'assests/images/calender_icon.svg'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { _getClinicalNotesDetail, _getClinicalNotesFilterProvider } from 'containers/ClinicalNotes/api/services'
import {
    searchProvider
} from 'services/ProviderDirectory'
import Routes from 'constants/routes'
import { SCHEDULE_POPULATE } from 'constants/index'
import { useHistory } from 'react-router-dom'
import { getAppointmentsByPatientId } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import moment from 'moment'
import { optionTZ } from 'containers/SchedulerMenu/constant'
import LoadingPage from 'utilities/loading-page'
import { setLoading } from 'store/actions/loader'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
    },
    first_box: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        // height: 'auto',
        // overflow: 'auto',
        padding: '10px',
    },
    second_box: {
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        padding: '10px',
        background: '#FFF',
    },
    copy_button: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        background: '#FFF',
        fontSize: '12px',
        color: '#303E4E',
        fontWeight: '400',
        height: '26px',
        marginRight: '8px',
        padding: '4px 8px',
    },
    copy_button_copied: {
        border: '1px solid #5571C6 !important'
    },
    box_type: {
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '30px 20px 10px 20px',
    },
    divider: {
        background: 'transparent',
        width: '100%',
        // height: '1px',
    },
    DividerText: {
        color: 'var(--Light-Foreground-Foreground-2, #424242)',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        // borderRadius: '5px',
        // border: '1px solid #E0E0E0'
    },
    TopBar:{   
        borderBottom: '1px solid #E0E0E0',
        background: '#F8F9FB',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        padding:'10px'
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22px",
    },
    toggleButton:{
        display: 'inline-flex',
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
        gap:"1px",
        background:'#ccc'
    },
    toggleOption:{
        padding: '4px 8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.3s, color 0.3s',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Rubik',
        backgroundColor: 'white',
        color: '#333',
    },
    copy: {
        display: 'flex',
        height: '24px',
        padding: '2px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        background:'#FFF',
        cursor:'pointer'
    },
    copyText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    headingText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22x',
    },
    activeStatus:{
        display: 'flex',
        height: '20px',
        padding: '6px',
        alignItems: 'center',
        gap: '4px',
        color: '#15A452',
        borderRadius:  '4px',
        border: '1px solid  #15A452',
        background: '#EBFFF3'
    },
    addIcon: {
        background: '#5571C6 !important',
        borderRadius: '4px',
        height: '24px',
        color: '#FFF',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px', 
        textTransform: 'none !important',
    },
    PopOver: {
        width: "424px",
        height: "533px",
        padding:"20px",
        borderRadius: "5px",
        background: "#FFF",
        overflowY: "auto",
    },
    CentralizedMacrosText: {
        color: '#616161 !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        padding: '16px 0 0 16px'
    },
    btnMacro: {
        color: '#303E4E !important',
        fontSize: '14px !important',
        maxWidth: '100%',
        textWrap: 'wrap'
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        margin: "4px 0"
    },
    button_create_form: {
        background: '#5571C6 !important',
        color: '#fff !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    button_cancel:{
        background: '#fff !important',
        color: '#303E4E !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    leftsubText:{
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineWeight: '16px',
    },
    next_appt_header:{
        color: '#667689',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineheight: '16px',
        padding: '8px 15px 5px 15px ',
    }
})

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


const NewPlan = (props) => {
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const providerName= localStorage.getItem('name')
    const [active,setActive] = useState('plan')
    const [open, setOpen] = useState(false)
    const [indexValue, setIndexValue] = useState(null)
    const [taskValue, setTaskValue]= useState({ title: '', assign: '', createdDate:'', dueDate: '', comment:'' })
    const [providerData,setProviderData] = useState([])
    const syncAppointment = useSelector((state) => state.clinicalNotes.syncAptForCN)
    const [loading, setLoading] = useState(false)
    const validationSchema = yup.object().shape({
        title: yup.string().required('Title is required'),
        assign: yup.string().required('Assignee is required'),
        createdDate: yup.date()
            .nullable()
            .typeError('Invalid date'),
        dueDate: yup.date()
            .nullable()
            .typeError('Invalid date')
    })

    const [selectedMacro, setSelectedMacro] = useState([])
    const [nextAppt, setNextAppt] = useState()
    const [newAppt, setNewAppt] = useState([])

    const dispatch = useDispatch()
    const macrosData = useSelector((state) => state.macros.macroList || [])
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]
    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])
    const handleLoadDataProvider = async (name = '') => {
        try {
                let { data } = await searchProvider({
                    page: 1,
                    size: 100000,
                    name: '',
                    _sort: '-_id',
                })
                const tempUserList = [];
                data?.map((item) =>
                    tempUserList.push({ value: capitalizeFirstLetter(`${item?.first_name} ${item?.last_name}`), key: item?.id })
                );
                const sortValue =  tempUserList.sort((a, b) => a.value.localeCompare(b.value));
                setProviderData(sortValue);
        } catch (e) {
            console.log("error", e)
        }
    };
    
    const getPlanList = () =>{  
        setLoading(true)
        if(!props.values?.plan.hasOwnProperty('planList') || props.values?.plan?.planList.length === 0){
            if(props.planList.length > 0){
                const list = props.planList.map(objA => ({
                    id: objA.id,
                    code: objA.code,
                    status: objA.status,
                    problem: objA.problem,
                    text: '',
                    task: { title: '', assign: '', createdDate:'', dueDate: '', comment:'' }
                  }))
                props.onChange(`${props?.fieldName}.plan.planList`,list)
            }else if(props.planList.length == 0 ){props.onChange(`${props?.fieldName}.plan.planList`,[])}  
        }else if(props.values?.plan?.planList.length > 0){
            let updateList = props?.planList.length > 0 ? props.values?.plan?.planList.filter(objB => props?.planList.some(objA => objA.id === objB.id && objA.code === objB.code)) : [];
            props?.planList.length > 0 ?
            props?.planList.forEach(objA => {
            if (!updateList.some(objB => objB.id === objA.id && objB.code === objA.code)) {
                updateList.push({
                id: objA.id,
                code: objA.code,
                status: objA.status,
                problem: objA.problem,
                text: '',
                task: { title: '', assign: '', createdDate:'', dueDate: '', comment:'' }
                });
            }
            })
            : updateList = [];
            props.onChange(`${props?.fieldName}.plan.planList`,updateList)
            
        }
        setLoading(false)
    }
    useEffect(()=>{getPlanList(),handleLoadDataProvider()},[])
    const handleClose = () =>{
        setOpen(false)
        setTaskValue({})
        setIndexValue()
        formik.resetForm();
    }
    const handleSubmit = (value)=>{
        props.onChange(
            `${props?.fieldName}.plan.planList[${indexValue}].task`,value
        )
        handleClose()
    }
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: taskValue,
        onSubmit: handleSubmit,
    })
    const handlePopulate = () => {
        const { specialty_id, visit_type_id, duration, location_id } = props?.appointmentDetails
        history.push(Routes.SCHEDULE, {
            populate: true,
            fromMedicalNote: true,
            from: SCHEDULE_POPULATE.CLINICAL_NOTE,
            value: {
                specialty_id,
                visit_type_id,
                duration,
                location_id,
                practitioner_id: props?.clinicalNoteData?.provider_id,
                patient_id: props?.clinicalNoteData?.patient.id,
            },
        })
    }
    const getNextAppt = async () => {
        try {
            if (!props?.clinicalNoteData?.id) return
            setLoading(true)
            const {
                data: { next_appt },
            } = await _getClinicalNotesDetail(props?.clinicalNoteData.id)

            if (!next_appt?.id || props.clinicalNoteData?.appointment_id === next_appt?.id) {
                setLoading(false)
                return props.onChange(`${props?.fieldName}.plan.next_appt`, null)
            }
            const start = moment(next_appt.start)
            const timezoneOffset = optionTZ.map((option) => ({
                ...option,
                offset: moment.tz(option.key).utcOffset(),
            }))
            const result = {
                id: next_appt.id,
                day: start.format('dddd'),
                date: start.format('MM/DD/YYYY'),
                start_time: start.format('hh:mm A'),
                end_time: moment(next_appt.end).format('hh:mm A'),
                timezone:
                    timezoneOffset.find((timezone) => timezone.offset === moment().utcOffset())
                        ?.value || moment.tz.guess(),
                provider: `${next_appt.practitioner_first_name} ${next_appt.practitioner_last_name }`,
                specialty: next_appt?.specialty?.specialty
            }
            setNextAppt(result)
            props.onChange(`${props?.fieldName}.plan.next_appt`, result)
            const resp = await getAppointmentsByPatientId(props.clinicalNoteData?.patient_id, {
                page: 1,
                pageSize: 20,
                date: `ge${props.clinicalNoteData?.appointment_end}`,
            })
            const filterData = resp?.data?.data.length > 0 && resp?.data?.data.filter((item)=> item.id !== next_appt?.id )
            const newDataConvert = filterData.length > 0 && filterData.map((item)=>{
                const start = moment(item.start)
                return{
                    id: item.id,
                    day: start.format('dddd'),
                    date: start.format('MM/DD/YYYY'),
                    start_time: start.format('hh:mm A'),
                    end_time: moment(item.end).format('hh:mm A'),
                    timezone:
                        timezoneOffset.find((timezone) => timezone.offset === moment().utcOffset())
                            ?.value || moment.tz.guess(),
                    provider: `${item.practitioner_first_name} ${item.practitioner_last_name }`,
                    specialty: item?.specialty?.specialty
                }
            })
            const sortData = newDataConvert.length > 0 && newDataConvert.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA - dateB;
            });
            setNewAppt(sortData)
            if (sortData.length === 0) {
                setLoading(false)
                return props.onChange(`${props?.fieldName}.plan.newAppt`, [])
            }
            props.onChange(`${props?.fieldName}.plan.newAppt`, sortData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            notification('An error occurred while get the next appointment!', 'error')
        }
    }
    useEffect(() => {
        ;(async () => {
            if (!syncAppointment && props?.clinicalNoteData?.id) {
                await getNextAppt()
            }
        })()
    }, [syncAppointment])
    return (
        <Grid item
            className={classes.container}
            style={{ marginTop: '30px', marginBottom: '30px', minHeight:'800px' }}>
            <Grid item style={{borderRadius: '5px', border: '1px solid #E0E0E0'}}>
            <Box className={classes.TopBar}>
                <Typography className={classes.HeadingColor}>Plan</Typography>
                <div className={classes.toggleButton}>
                    <div
                        className={classes.toggleOption}
                        onClick={() => {
                            setActive('plan')
                        }}
                        style={{
                            background: active == 'plan' ? '#5571C6' : '',
                            color: active == 'plan' ? '#fff' : '',
                        }}>
                        Plan
                    </div>
                    <div
                        className={classes.toggleOption}
                        onClick={() => {
                            setActive('erx')
                        }}
                        style={{
                            background: active == 'erx' ? '#5571C6' : '',
                            color: active == 'erx' ? '#fff' : '',
                        }}>
                        eRX
                    </div>
                    <div
                        className={classes.toggleOption}
                        onClick={() => {
                            setActive('lab')
                        }}
                        style={{
                            background: active == 'lab' ? '#5571C6' : '',
                            color: active == 'lab' ? '#fff' : '',
                        }}>
                        Labs
                    </div>
                    <div
                        className={classes.toggleOption}
                        onClick={() => {
                            setActive('imaging')
                        }}
                        style={{
                            background: active == 'imaging' ? '#5571C6' : '',
                            color: active == 'imaging' ? '#fff' : '',
                        }}>
                        Imaging
                    </div>
                </div>
                <div style={{display:'flex', gap:'10px'}}>
                    <div>
                        <Button
                            startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                            className={classes.addIcon}
                            style={{height:"30px"}}
                            onClick={() => {
                                handlePopulate()
                            }}
                            >
                            Schedule Appointment
                        </Button>
                    </div>
                    <div className={classes.copy}>
                        <IconButton>
                            <CopyIcon style={{ height: 14 }} />
                        </IconButton>
                        <Typography className={classes.copyText}>Copy: </Typography>
                        <Typography className={classes.copyText}>{''}</Typography>
                    </div>
                </div>
            </Box>
            {active === 'plan' &&
                <> 
                   {loading && <LoadingPage />}
                   {nextAppt?.id &&
                    <Grid>
                        <Typography className={classes.next_appt_header}>
                             Next appointment on: <span style={{color:'#303E4E'}}>{nextAppt?.date} - {nextAppt?.provider}- {nextAppt?.specialty}</span>
                        </Typography>
                    </Grid>}
                    {newAppt?.length > 0 &&
                       newAppt.map((item)=>{
                        return(
                            <Grid>
                                <Typography className={classes.next_appt_header}>
                                    Newly Created on: <span style={{color:'#303E4E'}}>{item?.date} - {item?.provider}- {item?.specialty}</span>
                                </Typography>
                            </Grid>
                        )
                       })
                    }
                    {props.values.plan?.planList?.length > 0 && props.values.plan?.planList?.map((item, index)=>(
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '4px',
                                padding: '15px',
                                position: 'relative',
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTop: '1px solid #ccc !important',
                                    borderLeft: '1px solid #ccc !important',
                                    borderRight: '1px solid #ccc !important',
                                    borderTopRightRadius: '5px !important',
                                    borderTopLeftRadius: '5px !important',
                                    borderBottomRightRadius: '0px',
                                    borderBottomLeftRadius: '0px',
                                    padding: '10px',
                                    background: '#F8F9FB',
                                }}>
                                <Typography className={classes.headingText}>{item?.code} {item?.problem}</Typography>
                                <div style={{ display: "flex", justifyContent:'flex-end', gap: 10 }}>
                                    <Box
                                        sx={{
                                            '& .MuiSelect-selectMenu': {
                                                borderRadius: '4px',
                                                border: '0.7px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                                                background: '#FFFFFF',
                                                padding: '4px 24px 4px 8px !important',
                                                color: '#242424',
                                            },
                                            '& .MuiInput-root': {
                                                marginRight: '8px',
                                            },
                                        }}>
                                        <NewSelect
                                            disableUnderline
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 505,
                                                        maxWidth: 300,
                                                        marginTop: 8,
                                                    },
                                                },
                                            }}
                                            IconComponent={(props) => (<ExpandMore {...props} />)}
                                            name="macro"
                                            onChange={(e) => {
                                                const value = selectedMacro
                                                if(selectedMacro.length > index){
                                                    value[index] =  e.target.value
                                                setSelectedMacro((value))
                                                }else{
                                                    setSelectedMacro([...selectedMacro,e.target.value])
                                                }
                                            }}
                                            value={selectedMacro[index]}
                                            displayEmpty
                                            renderValue={(sel) =>
                                                sel ? (
                                                    <span
                                                        className={classes.btnMacro}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '5px',
                                                            color: '#242424',
                                                            fontSize: '12px',
                                                            fontWeight: '400px',
                                                        }}>
                                                        <img
                                                            src={edit_icon}
                                                            alt=""
                                                            style={{ width: '12px' }}
                                                        />
                                                        Macros:{' '}
                                                        {
                                                            allMacros.find((item) => item.name === sel)
                                                                ?.name
                                                        }
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={classes.btnMacro}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '5px',
                                                            color: '#242424',
                                                            fontSize: '12px',
                                                            fontWeight: '400px',
                                                        }}>
                                                        <img
                                                            src={edit_icon}
                                                            alt=""
                                                            style={{ width: '12px' }}
                                                        />
                                                        Macros
                                                    </span>
                                                )
                                            }>
                                            <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                                                {macrosPractitionerData?.map((m) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                            let newValue = m.description
                                                            if (props?.values?.plan?.planList[index]?.text === undefined) {
                                                                newValue = m.description
                                                            } else {
                                                                newValue = props?.values?.plan?.planList[index]?.text.concat(` ${m.description}`)
                                                            }
                                                            props.onChange(
                                                                `${props?.fieldName}.plan.planList[${index}]`,
                                                                {
                                                                    ...item,
                                                                    text: newValue
                                                                }
                                                            )
                                                        }}
                                                        // className={classes.menu__item}
                                                        key={m.id}
                                                        value={m.name}
                                                        >
                                                        <Typography className={classes.btnMacro}>
                                                            {m.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                                                {macrosData?.map((m) => (
                                                    <MenuItem
                                                        onClick={() => {
                                                            let newValue = m.description
                                                            if (props?.values?.plan?.planList[index]?.text === undefined) {
                                                                newValue = m.description
                                                            } else {
                                                                newValue = props?.values?.plan?.planList[index]?.text.concat(` ${m.description}`)
                                                            }
                                                            props.onChange(
                                                                `${props?.fieldName}.plan.planList[${index}]`,
                                                                {
                                                                    ...item,
                                                                    text: newValue
                                                                }
                                                            )
                                                        }}
                                                        // className={classes.menu__item}
                                                        key={m.id}
                                                        value={m.name}>
                                                        <Typography className={classes.btnMacro}>
                                                            {m.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                        </NewSelect>
                                    </Box>
                                    <Typography className={classes.activeStatus}>Active</Typography>
                                </div>
                            </Box>
                            <div style={{ width: '100%' }}>
                                <TextField
                                    multiline
                                    minRows={5}
                                    maxRows={5}
                                    variant="outlined"
                                    fullWidth
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            padding: '8px',
                                            borderTopRightRadius: '0px',
                                            borderTopLeftRadius: '0px',
                                            borderBottomRightRadius: '5px',
                                            borderBottomLeftRadius: '5px',
                                            height:'100% !important'
                                        },
                                    }}
                                    value={props?.values?.plan?.planList[index]?.text}
                                    onChange={(e)=>{
                                        props.onChange(
                                            `${props?.fieldName}.plan.planList[${index}]`,
                                            {
                                                ...item,
                                                text:e.target.value
                                            }
                                        )
                                    }}
                                />
                            </div>
                            <Grid item xs={12} justifyContent='flex-end' style={{marginTop:'10px'}}>
                                <div style={{ display: "flex", justifyContent:'flex-end', gap: 10 }}>
                                    
                                    <Button
                                        startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                                        className={classes.addIcon}
                                        onClick={() => {
                                            setOpen(true)
                                            setIndexValue(index)
                                            setTaskValue(item.task)
                                        }}
                                        >
                                        {item.task?.title != '' ? 'View task' : 'Create task'}
                                    </Button>
                                </div>
                            </Grid>
                        </Box>
                    )) }
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        style={{ marginLeft: "-5px"}}
                        sx={{
                            
                            '& .MuiPaper-root':{
                                maxWidth: '700px !important',
                            }
                        }}
                    >
                        <Box className={classes.PopOver}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: "8px",
                                paddingTop: "12px",
                                marginTop: '8px'
                            }}>
                                <Typography style={{ fontSize: "24px", fontWeight: 500, color: '#000' }}className={classes.headingText}>{taskValue.title ?'View Task': 'Create Task' }</Typography>
                            </Box>
                            <Grid container item style={{ marginTop: "4px" }}>
                                <Grid container item alignItems="flex-end" style={{ marginTop: "5px" }}>
                                    <Grid item xs={12}>
                                        <FormLabel component="p" className={classes.formLabel}>
                                            Title <span style={{ color: '#E81A1A' }}>*</span>
                                        </FormLabel>
                                        <TextBox
                                            name="title"
                                            variant="outlined"
                                            placeholder="Create task title"
                                            required
                                            disabled={taskValue.title}
                                            value={formik.values.title}
                                            error={
                                                formik.touched.title &&
                                                Boolean(formik.errors.title)
                                            }
                                            helperText={
                                                formik.touched.title &&
                                                formik.errors.title
                                            }
                                            onChange={(value) => {
                                                formik.setFieldValue('title',value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item alignContent='flex-end' style={{ marginTop: "8px" }}>
                                    <Grid item xs={12}>
                                        <FormLabel component="p" className={classes.formLabel}>
                                            Assignee <span style={{ color: '#E81A1A' }}>*</span>
                                        </FormLabel>
                                        <SelectEl
                                            name="family_member"
                                            placeholder="Choose assignee"
                                            value={formik.values.assign}
                                            disabled={taskValue.title}
                                            error={
                                                formik.touched.assign &&
                                                Boolean(formik.errors.assign)
                                            }
                                            helperText={
                                                formik.touched.assign &&
                                                formik.errors.assign
                                            }
                                            options={providerData}
                                            onChange={(event) => {
                                                formik.setFieldValue('assign',event.target.value)
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item alignItems="flex-end" spacing={2} style={{ marginTop: "4px" }}>
                                    <Grid item xs={6}>
                                        <FormLabel component="p" className={classes.formLabel}>
                                            Created on
                                        </FormLabel>
                                        <DatePickerCustom
                                            icon={<CalenderIcon />}
                                            name="start_date"
                                            value={formik.values.createdDate}
                                            onChange={(value) => {
                                                formik.setFieldValue('createdDate',value)
                                            }}
                                            disabled={taskValue.title}
                                            // maxDate={moment()}
                                            // minDate={moment()}
                                            notDefaultValue={false}
                                            error={
                                                formik.touched.createdDate &&
                                                Boolean(formik.errors.createdDate)
                                            }
                                            helperText={
                                                formik.touched.createdDate &&
                                                formik.errors.createdDate
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormLabel component="p" className={classes.formLabel}>
                                            Due Date
                                        </FormLabel>
                                        <DatePickerCustom
                                            icon={<CalenderIcon />}
                                            name="stop_date"
                                            value={formik.values.dueDate}
                                            onChange={(value) => {
                                                formik.setFieldValue('dueDate',value)
                                            }}
                                            // maxDate={moment()}
                                            // minDate={moment()}
                                            notDefaultValue={true}
                                            disabled={taskValue.title}
                                            error={
                                                formik.touched.dueDate &&
                                                Boolean(formik.errors.dueDate)
                                            }
                                            helperText={
                                                formik.touched.dueDate &&
                                                formik.errors.dueDate
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "4px",marginBottom: "10px" }}>
                                    <FormLabel component="p" className={classes.formLabel}>
                                        Comment
                                    </FormLabel>
                                    <TextBox
                                        variant='outlined'
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '95px !important'
                                            },
                                            ' & .MuiInputBase-input': {
                                                height: "75px !important"
                                            }
                                        }}
                                        placeholder='Describe here  and instruction for a patient'
                                        multiline
                                        maxRows={4}
                                        disabled={taskValue.title}
                                        value={formik.values.comment}
                                        onChange={(value) => {
                                            formik.setFieldValue('comment',value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: "5px", borderTop: '1px solid #ccc' }}>
                                    <Grid item style={{marginTop: "10px", display: 'flex', alignItems: 'center', gap: '7px' }}>
                                        <Typography className={classes.leftsubText}>Patient:</Typography><Typography className={classes.leftsubText} style={{color:'#303E4E'}}>{capitalizeFirstLetter(props.patient?.first_name) + ' ' +capitalizeFirstLetter(props.patient?.last_name) }</Typography>
                                    </Grid>
                                    <Grid item style={{marginTop: "4px",display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <Typography className={classes.leftsubText}>Provider:</Typography> <Typography className={classes.leftsubText} style={{color:'#303E4E'}}>{capitalizeFirstLetter(providerName) }</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} justifyContent='flex-end' >
                                <div style={{ height: "", marginTop: "15px", marginBottom: "5px", display: "flex", justifyContent:'flex-end', gap: 15 }}>
                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        className={classes.button_cancel}
                                        onClick={(event) => {
                                            handleClose()
                                        }}>
                                        Cancel
                                    </LoadingButton>
                                    <LoadingButton
                                        // loading={isSubmitting}
                                        loadingPosition="start"
                                        variant="contained"
                                        className={classes.button_create_form}
                                        disabled={taskValue.title}
                                        onClick={(event) => {
                                            formik.handleSubmit()
                                        }}>
                                        Create task
                                    </LoadingButton>
                                </div>
                            </Grid>
                        </Box>
                    </Dialog>
                </>
            }
            {active === 'erx' && <Grid item style={{height: '800px'}}> <Prescribe patientId={props?.patient.id} /> </Grid>}
            {active === 'lab' && <Grid item style={{height: '800px'}}> <Lab patientId={props?.patient.id} /> </Grid>}
            {active === 'imaging' && <></>}
            </Grid>
        </Grid>
        
        

        
    )
}
export default NewPlan

