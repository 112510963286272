import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Grid } from '@material-ui/core'

import { ModalEl as Modal } from 'shared/elements'
import { Document, ErrorType } from '../../interfaces'
import { Footer } from 'shared/elements/FormItem/Footer'
import { createDocument } from 'services/Documents'
import { useNotification } from 'shared/elements/Notification'

import DocumentFormBody from 'containers/Documents/CreateDocumentDialog/Views/Staff'
import { INITIAL_VALUES } from 'containers/Documents/CreateDocumentDialog/schema'


interface Props {
  open?: boolean,
  patientId?: string
  onCancel?: (forceReload?: boolean) => void
}


export const DocumentForm = (props: Props) => {
  const notification = useNotification()

  const [submitting, setSubmitting] = useState(false)
  const [data, setData] = useState<Document>(INITIAL_VALUES)

  const handleSubmit = async (values: Document) => {
    setSubmitting(true)
    try {
      await createDocument(values)

      notification(
        'Document created successfully!',
        'success',
      )

      setSubmitting(false)
      handleCancel(true)
    } catch (error) {
      setSubmitting(false)

      notification(
        (error as ErrorType).data?.message || 'Something went wrong!',
        'error',
      )
    }
  }

  const handleCancel = (forceReload?: boolean) => {
    setData({ ...INITIAL_VALUES, associated_patient: props.patientId })
    props.onCancel && props.onCancel(forceReload)
  }

  useEffect(() => {
    if (props.patientId) setData({
      ...data,
      associated_patient: props.patientId
    })
  }, [props.patientId])

  const validationSchema = yup.object({
    associated_patient: yup
      .string()
      .required('Associated Patient is required!'),
  })
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit
  })

  return (
    <Modal
      width={680}
      open={props.open}
      onClose={handleCancel}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden'
      }}>

      <DocumentFormBody
        // isViewOnly={isViewOnly}
        touched={formik.touched}
        errors={formik.errors}
        values={formik.values}
        disableSearchPatient={true}
        setFieldValue={formik.setFieldValue}
      />

      <Grid container style={{ padding: '20px 20px' }}>
        <Footer
          loading={submitting}
          onCancel={() => handleCancel(false)}
          onOk={formik.handleSubmit}
        />
      </Grid>
    </Modal>
  )
}
