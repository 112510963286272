import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import AddIcon from '@mui/icons-material/Add'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button as ButtonCustom } from 'components/custom'
import {
    deleteMedication,
    getListMedications,
    getListMedicationsType,
} from 'services/Patient/patient'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import CustomTabs from 'components/shared/Elements/CustomTabs'
import MedicationsCreateDialog from '../../MedicationsCreateDialog'
import PatientInfor from '../PatientInfor'
import { DataGrid } from 'shared/elements/DataGrid'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { dateFormat, stringConcatenation } from 'shared/utils'
import moment from 'moment'
import { updatingMedicationList } from 'store/actions/clinicalNotes'

const useStyles = makeStyles({
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    container: {
        // position: 'absolute',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    btn_print: {
        height: 32,
        background: '#E9ECF1',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '4px 16px',
        color: '#667689',
        '&:hover': {
            background: '#E9ECF1',
        },
    },
    capitalize: {
        textTransform: 'capitalize',
    },
})

const sectionsTabs = [
    {
        key: 234234,
        label: 'Active Medication Orders',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Inactive Medication Orders',
        _id: 2,
    },
]

const MedicationPatient = ({ isInClinicalNote, patient_Id, encounter_id, afterActionOk, encounter_date, nonEditable=false, clinicalNoteMedList =[] }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const { id } = useParams()
    const updateMedicationList = useSelector((state) => state.clinicalNotes.updateMedication)
    const [patientId, setPaitentId] = useState('')
    const [selectedTab, setSelectedTab] = useState(0)
    // const [page, setPage] = useState(1)
    const [bundleId, setBundleId] = useState('')

    const [appointmentModal, setAppointmentModal] = useState({
        status: false,
    })
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [medicationList, setMedicationList] = useState([])
    const [activeMedicationList, setActiveMedicationList] = useState([])
    const [inActiveMedicationList, setInActiveMedicationList] = useState([])

    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const onPageChange = (value) => {
        setPage(value)
    }

    function appointmentModalToggle(itemId) {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId,
        })
    }

    useEffect(() => {
        setPaitentId(patient_Id || id)
    }, [patient_Id, id])


    const columns = [
        {
            headerName: 'Drug name',
            headerClassName: 'super-app-theme--header',
            field: 'drug',
            flex: 1.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                const display = stringConcatenation(
                    value.brandName,
                    value.genericName,
                    value.strength,
                    value.form,
                )
                return (
                    <HtmlTooltip title={display} arrow placement="top">
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {display}
                        </Typography>
                    </HtmlTooltip>
                )
            },
        },
        // {
        //     headerName: 'Drug type',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'drugType',
        //     flex: 1.5,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         return value?.code || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
        //     },
        // },

        {
            headerName: 'Strength',
            headerClassName: 'super-app-theme--header',
            field: 'strength',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        // {
        //     headerName: 'Scheduled',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'schedule_drug',
        //     // width: 100,
        //     flex: 1,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         return value?.display || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
        //     },
        // },
        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'practitioner_id',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    `${row.provider_first_name} ${row.provider_last_name}` || (
                        <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
                    )
                )
            },
        },
        {
            headerName: 'Days Supply',
            headerClassName: 'super-app-theme--header',
            field: 'day_supply',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value }) => {
                return value?.display || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        // {
        //     headerName: 'Quantity',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'quantity',
        //     // width: 100,
        //     flex: 1,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
        //     },
        // },
        {
            headerName: 'Date Written',
            headerClassName: 'super-app-theme--header',
            field: 'last_written_date',
            // width: 100,
            flex: 0.7,
            sortable: true,
            renderCell: ({ value }) => {
                return  value ? dateFormat(value).format('MM/DD/YYYY') : <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Refills',
            headerClassName: 'super-app-theme--header',
            field: 'refills',
            // width: 100,
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Direction',
            headerClassName: 'super-app-theme--header',
            field: 'direction',
            // width: 100,
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                const display = stringConcatenation(
                    row?.dose,
                    row?.dose_unit,
                    row?.route?.display,
                    row?.frequency?.display,
                )
                return (
                    <HtmlTooltip title={display} arrow placement="top">
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {display || 'N/A'}
                        </Typography>
                    </HtmlTooltip>
                )
            }
        },
        {
            headerName: 'Action Status',
            headerClassName: 'super-app-theme--header',
            field: 'action_status',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !nonEditable 
                ?
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => handleEditClick(params),
                        },
                        {
                            text: 'Delete',
                            handler: () => handleDelete(patientId, params.id.toString()),
                        },
                    ]}
                    deleteMessage="Are you sure you want to delete this Medication?"
                />
                :
                ""
            ),
        },
    ]

    const clinicalNoteViewcolumn = [
        {
            headerName: 'Drug name',
            headerClassName: 'super-app-theme--header',
            field: 'drug',
            flex: 1.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                const display = row?.drug_name ? row?.drug_name : row?.medication
                return (
                    <HtmlTooltip title={display} arrow placement="top">
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {display}
                        </Typography>
                    </HtmlTooltip>
                )
            },
        },

        {
            headerName: 'Strength',
            headerClassName: 'super-app-theme--header',
            field: 'strength',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'prescriber',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    `${value}` || (
                        <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
                    )
                )
            },
        },
        {
            headerName: 'Days Supply',
            headerClassName: 'super-app-theme--header',
            field: 'days_supply',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Date Written',
            headerClassName: 'super-app-theme--header',
            field: 'date_written',
            // width: 100,
            flex: 0.7,
            sortable: true,
            renderCell: ({ value }) => {
                return  value  || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Refills',
            headerClassName: 'super-app-theme--header',
            field: 'refills',
            // width: 100,
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Direction',
            headerClassName: 'super-app-theme--header',
            field: 'sig',
            // width: 100,
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    <HtmlTooltip title={value} arrow placement="top">
                        <Typography
                            style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            }}>
                            {value || 'N/A'}
                        </Typography>
                    </HtmlTooltip>
                )
            }
        },
        {
            headerName: 'Action Status',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.8,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                ""
            ),
        },
    ]

    const handleDelete = (patientId, medicationId) => {
        setLoading(true)
        deleteMedication(patientId, medicationId)
            .then(() => {
                refetchStatus()
                afterActionOk?.()
            })
            .finally(() => setLoading(false))
    }

    const handleEditClick = (params) => {
        const { row } = params
        appointmentModalToggle(row?.id)
    }


    const statusOfMedicationList = (lastWrittenDate= null, stopDate= null, isPrescribe= false) =>{
        if(stopDate && dateFormat(stopDate).isSameOrBefore(moment(), 'day')){
            return 'Discontinue'
        }else if(isPrescribe && lastWrittenDate){
            if( dateFormat(lastWrittenDate).isSameOrAfter(moment(encounter_date), 'day')){
                return 'New Order'
            }else if(dateFormat(lastWrittenDate).isBefore(moment(encounter_date), 'day')){
                return 'Continue'
            }
        }else if((!isPrescribe || !lastWrittenDate)){
            return 'Continue'
        }
    }
    



    const refetchStatus = async () => {
        setLoading(true)
        try {
            const resp = !nonEditable ? await getListMedications(patientId, 1, 100000) :[]

            // if (isInClinicalNote) return setMedicationList(resp.data.data)
            const medicationList = []
            if (isInClinicalNote && !nonEditable) {
                resp.data.data.forEach((medication) => {
                    if (!dateFormat(medication?.stop_date).isSameOrBefore(moment(), 'day')) {
                        const actionStatusAdd = {
                            ...medication,
                            action_status: statusOfMedicationList(medication?.last_written_date, medication?.stop_date, medication?.is_prescribe)
                        }
                        medicationList.push(actionStatusAdd)
                    }
                })
                return setMedicationList(medicationList)
            }else if(isInClinicalNote && nonEditable){
                const convertData = clinicalNoteMedList.length && clinicalNoteMedList.map((item,index)=>{
                    return{
                        ...item,
                        id: index
                    }
                })
                return setMedicationList(convertData)
            }

            const active = []
            const inactive = []

            resp.data.data.forEach((medication) => {
                //the user who view from patient dashboard
                const actionStatusCloseList = {
                    ...medication,
                    action_status: '-'
                }
                if (medication?.stop_date && dateFormat(medication?.stop_date).isSameOrBefore(moment(), 'day'))
                    return inactive.push(actionStatusCloseList)

                active.push(actionStatusCloseList)
            })

            setActiveMedicationList(active)
            setInActiveMedicationList(inactive)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (patientId) {
            refetchStatus()
        }
    }, [patientId])
    useEffect(() => {
        if (updateMedicationList) {
            refetchStatus()
            afterActionOk?.()
            dispatch(updatingMedicationList(false))
        }
    }, [updateMedicationList])
    // useEffect(() => {
    //     setPage(1)
    // }, [selectedTab])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ padding: !isInClinicalNote ? '16px 24px' : '0' }}>
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() => history.goBack()}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}
            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Medication List</Typography>
                        <Grid item>
                            <Grid container spacing={2} wrap={'nowrap'}>
                                <Grid item>
                                    <ButtonCustom
                                        tooltip="Add New Medication"
                                        variant="contained"
                                        color="primary"
                                        disabled={nonEditable}
                                        onClick={() => appointmentModalToggle()}
                                        fullWidth>
                                        <AddIcon
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                    </ButtonCustom>
                                </Grid>
                                {!isInClinicalNote && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn_print}
                                            onClick={() => appointmentModalToggle()}
                                            fullWidth>
                                            <LocalPrintshopOutlinedIcon
                                                style={{
                                                    fontSize: 18,
                                                    color: '#667689',
                                                    marginRight: 8,
                                                }}
                                            />
                                            Print
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}
                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <CustomTabs
                            contents={sectionsTabs}
                            selectedTab={selectedTab}
                            onChangeTab={(e) => {
                                setSelectedTab(e)
                                console.log(e, 'log e')
                            }}
                        />
                    </Grid>
                )}

                {(selectedTab === 0 || selectedTab === 1) && (
                    <>
                        {!isInClinicalNote && (
                            <Grid
                                container
                                item
                                direction="row"
                                style={{
                                    borderBottom: '1px solid #E9ECF1',
                                    paddingBottom: 12,
                                    paddingLeft: 12,
                                }}>
                                <Grid item>
                                    <CheckboxOption
                                        // name={`${fieldName}.no_concern`}
                                        option={{
                                            label: 'Patient has no known medications',
                                            value: true,
                                        }}
                                    // onChange={(e) => {
                                    //     setFieldValue(
                                    //         'type',
                                    //         e.target.checked
                                    //             ? 'Medical'
                                    //             : '',
                                    //     )
                                    // }}
                                    // checked={
                                    //     values?.type === 'Medical'
                                    // }
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.form_list_container}>
                            <DataGrid
                                getRowId={(row) => row.id}
                                sortModel={sortModel}
                                onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                                rows={
                                    isInClinicalNote
                                        ? medicationList
                                        : !selectedTab
                                            ? activeMedicationList
                                            : inActiveMedicationList
                                }
                                columns={(isInClinicalNote && nonEditable) ? clinicalNoteViewcolumn : columns}
                                pageSize={limit}
                                // rowsPerPageOptions={[10]}
                                onPageChange={onPageChange}
                                disableColumnSelector
                                disableSelectionOnClick
                                loading={isLoading}
                                paginationMode="client"
                                rowCount={
                                    isInClinicalNote
                                        ? medicationList.length
                                        : !selectedTab
                                            ? activeMedicationList.length
                                            : inActiveMedicationList.length
                                }
                                page={page}
                                pagination
                                disableColumnMenu
                                height={!isInClinicalNote ? 'calc(100vh - 348px)' : ''}
                            />
                        </Grid>
                    </>
                )}

                {appointmentModal.status && (
                    <MedicationsCreateDialog
                        open={appointmentModal.status}
                        itemId={appointmentModal.itemId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        refetchData={refetchStatus}
                        onModalClose={appointmentModalToggle}
                        afterActionOk={afterActionOk}
                    />
                )}
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(MedicationPatient)
