import {
    Box,
    Card,
    CardContent,
    IconButton,
    Typography,
    Tooltip,
    Link,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import HeaderIcon from '../../../assests/sidebar/cstmtu.svg'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import { getConsultationTracking } from '../../../services/Consultation/tracking'
import {
    CardHeaderEl,
    NoRowsOverlayEl,
} from '../../../shared/elements'
import {
    CONSULTATION_FAILURE,
    CONSULTATION_LOADING,
    CONSULTATION_SUCCESS,
} from '../constant'
import { initialState, reducer } from '../reducer'
import './style.scss'

function ConsultationTrackingCard(props) {
    const columns = [
        {
            headerName: (
                <Typography variant="body2">
                    Date
                </Typography>
            ),
            field: 'appointment_date',
            flex: 1,
            sortable: false,
            renderCell: (cell) => {
                return (
                    <Tooltip
                        title={`${moment
                            .utc(cell.value)
                            .local()
                            .format('MM/DD/YYYY')} ${
                            cell.row.time
                        }`}
                        placement="top">
                        <Typography noWrap variant="body2">
                            {`${moment
                                .utc(cell.value)
                                .local()
                                .format('MM/DD/YYYY')} ${
                                cell.row.time
                            }`}
                        </Typography>
                    </Tooltip>
                )
            },
        },
        {
            headerName: (
                <Typography noWrap variant="body2">
                    Provider
                </Typography>
            ),
            field: 'provider_name',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.value}
                        placement="top">
                        <Link
                            noWrap
                            component="button"
                            color="primary"
                            className="capitalize"
                            underline="none"
                            onClick={() =>
                                onRowClick(
                                    params.row.provider
                                        .user_id,
                                    UserRoles.PROVIDER,
                                )
                            }>
                            <strong>{params.value}</strong>
                        </Link>
                    </Tooltip>
                )
            },
        },
        {
            headerName: (
                <Typography noWrap variant="body2">
                    Patient
                </Typography>
            ),
            field: 'patient_name',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={params.value}
                        placement="top">
                        <Link
                            noWrap
                            component="button"
                            color="primary"
                            className="capitalize"
                            underline="none"
                            onClick={() =>
                                onRowClick(
                                    params.row.patient
                                        .user_id,
                                    UserRoles.PATIENT,
                                )
                            }>
                            <strong>{params.value}</strong>
                        </Link>
                    </Tooltip>
                )
            },
        },
    ]
    const history = useHistory()

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    React.useEffect(() => {
        fetchConsultationTracking()
    }, [])

    async function fetchConsultationTracking() {
        try {
            dispatch({ type: CONSULTATION_LOADING })
            const consultations =
                await getConsultationTracking()

            dispatch({
                type: CONSULTATION_SUCCESS,
                data: consultations.data,
            })
        } catch (error) {
            if (error?.status === 404) {
                return dispatch({
                    type: CONSULTATION_SUCCESS,
                    data: [],
                })
            }

            dispatch({
                type: CONSULTATION_FAILURE,
            })
        }
    }

    function handleConsultationRefresh() {
        fetchConsultationTracking()
    }

    function onRowClick(userId, role) {
        const editDecider =
            role === UserRoles.PROVIDER ? 'Provider' : ''
        history.push(Routes.PROFILE, {
            userId,
            role,
            editDecider,
        })
    }

    return (
        <div className="MuiDataGrid-table-card-wrapper">
            <Card style={{ height: 386, width: '100%' }}>
                <CardHeaderEl
                    title="Consultation Tracking"
                    avatar={
                        <Box
                            style={{
                                height: 28,
                                width: 28,
                            }}>
                            <img src={HeaderIcon} />
                        </Box>
                    }
                    action={
                        <IconButton
                            disableFocusRipple={true}
                            disableRipple={true}
                            size={'small'}
                            onClick={() =>
                                history.push(
                                    Routes.CONSULTATION_TRACKING,
                                )
                            }>
                            <Typography
                                color="primary"
                                variant="body2">
                                View All
                            </Typography>
                        </IconButton>
                    }
                />
                <CardContent style={{ padding: 0 }}>
                    <div
                        style={{
                            height: 350,
                            width: '100%',
                        }}>
                        <DataGrid
                            getRowId={(row) =>
                                row.appointment_id
                            }
                            rows={state.consultations.data.slice(
                                0,
                                5,
                            )}
                            columns={columns}
                            disableColumnSelector
                            disableSelectionOnClick
                            disableColumnFilter
                            disableColumnMenu
                            components={{
                                NoRowsOverlay: () => (
                                    <NoRowsOverlayEl
                                        title={
                                            !state
                                                .consultations
                                                .isFailure
                                                ? 'No consultation is in-progress'
                                                : 'Failed to retrieve consultations'
                                        }
                                    />
                                ),
                            }}
                            rowHeight={52}
                            hideFooter
                            loading={
                                state.consultations
                                    .isLoading
                            }
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

export default ConsultationTrackingCard
