import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { LoadingButton } from '@mui/lab'
import { Button as ButtonCustom } from 'components/custom'
import { useFaxMe } from '../hooks'
import { SearchField } from 'shared/elements/FormItem/SearchComponents/SearchField'
import { UserFaxInfo } from './UserFaxInfo'
import Routes from 'constants/routes'
import { useHistory } from 'react-router-dom'
interface Props {
    title?: string
    style?: React.CSSProperties
    onClickAddNew?: () => void
    onSearch?: (text: string) => void
}

const useStyles = makeStyles({
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    patient_btn: {
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
        padding: '11px',
        height: 40,
    },
})

export const FaxPageHeader = (props: Props) => {
    const classes = useStyles()
    const { faxMe } = useFaxMe()
    const history = useHistory()

    return (
        <Grid container style={props.style} justifyContent="space-between">
            <Typography className={classes.header}>
                {props.title}

                <UserFaxInfo faxMe={faxMe} />
            </Typography>

            <Grid item>
                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="flex-end"
                        style={{ flex: 1 }}>
                        <Grid
                            item
                            container
                            direction="row"
                            wrap="nowrap"
                            justifyContent="flex-end"
                            alignItems="center"
                            style={{
                                gap: 8,
                                marginRight: 16,
                                flex: 1,
                            }}>
                            <Grid item>
                                <SearchField onSearch={props.onSearch} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <LoadingButton
                        onClick={() =>
                            history.push(Routes.PATIENT.CREATE, {
                                previousRoute: history.location.pathname,
                            })
                        }
                        variant="contained"
                        classes={{
                            root: classes.patient_btn,
                        }}>
                        <AddIcon />
                        <span
                            style={{
                                marginLeft: 6,
                                fontSize: '16px',
                            }}>
                            Add New Patient
                        </span>
                    </LoadingButton>

                    <Grid item className="d-flex align-center">
                        <ButtonCustom
                            tooltip="Add New FAX"
                            variant="contained"
                            color="primary"
                            onClick={props.onClickAddNew}
                            fullWidth>
                            <AddIcon
                                style={{
                                    fontSize: 20,
                                }}
                            />
                        </ButtonCustom>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
