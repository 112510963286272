import { useState, useEffect } from 'react'
import { Patient } from '../interfaces'
import { getPatient} from 'services/Patient/patient'
import { useParams } from 'react-router-dom'
import { apiInsurrancePolicy } from 'services/Patient/apiInsurrancePolicy'
import { requestPreviewBrandingBackGround } from 'services/Provider/provider'

export default function usePatient(patientId?: string, inReferral?: boolean) {
    if (!patientId) {
        patientId = useParams<{ id: string }>().id
    }
    const [patientInfo, setPatientInfo] = useState<Patient>({} as Patient)
    const [loadingPatient, setLoadingPatient] = useState<Boolean>(false)

    useEffect(() => {
        const abortController = new AbortController()
        patientId && fetchPatient(patientId)

        return () => abortController.abort()
    }, [patientId])
    // console.log(patientInfo, 'log patientInfo')
    const fetchPatient = async (id: string) => {
        setLoadingPatient(true)
        
        try {
            const resp = await getPatient(id)
            if(resp?.data.avatar){
                const payload = {
                    "key":resp?.data.avatar
                }

                const previewUrl = await requestPreviewBrandingBackGround(payload)
                if(previewUrl)
                    resp.data.photo = previewUrl?.data?.url 
            }
            if (inReferral) {
                const respInsurrancePolicy = await apiInsurrancePolicy.getAll(patientId, {
                    page: 1,
                    size: 5,
                })
                const insurrancePolicyByType = respInsurrancePolicy?.data?.data.find(
                    (item) => item.type === 'Primary',
                )
                if (insurrancePolicyByType) {
                    return setPatientInfo({
                        ...resp.data,
                        insurance: {
                            health_plan: insurrancePolicyByType.insurance.plan.display,
                            policy_no: insurrancePolicyByType.insurance.policy_no,
                            group_number: insurrancePolicyByType.insurance.group_number,
                        },
                    })
                }
            }
            setPatientInfo(resp.data)
        } catch (ex) {
            console.info('[ERROR] Cannot fetch patient data', ex)
        } finally {
            setLoadingPatient(false)
        } 
    }

    return {
        patientInfo, fetchPatient, loadingPatient, setLoadingPatient
    }
}
