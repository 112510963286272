import {
    Button,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import ForwardIcon from '../../assests/icons/ForwardIcon'
import NextIcon from '../../assests/icons/NextIcon'
import Service3 from '../../assests/images/image_18.png'
import Service1 from '../../assests/images/image_23.png'
import Service4 from '../../assests/images/image_26.png'
import Service2 from '../../assests/images/image_27.png'

const useStyles = makeStyles(() => ({
    root: {
        padding: '20px 60px 14px 60px',
    },
    textService: {
        fontSize: '22px',
        lineHeight: '30.8px',
        fontWeight: 500,
        color: '#132044',
        marginBottom: '16px',
    },
    service: {
        width: '100%',
    },
    item_root: {
        position: 'relative',
        borderRadius: 16,
        height: 210,
        flex: 1,
    },
    overlay: {
        borderRadius: 16,
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#e1e1e1cc',
        width: '100%',
        height: '100%',
    },
    text_box: {
        borderBottomRightRadius: 16,
        borderBottomLeftRadius: 16,
        background:
            'linear-gradient(90deg, #132044 36.9%, rgba(19, 32, 68, 0) 100%)',
        opacity: 0.9,
        height: '55px',
        alignItems: 'center',
        padding: '12px 20px',
    },
    btn_icon: {
        zIndex: 10,
        background: 'white',
        boxShadow: '0px 5px 16px rgba(39, 46, 65, 0.25)',
        borderRadius: '50%',
        minWidth: 50,
        height: 50,
        position: 'absolute',
        top: 78,
        '&:hover': {
            background: 'rgba(34,43,55,0.7) !important',
        },
    },
    btn_icon_left: {
        left: -28,
    },
    btn_icon_right: {
        right: -28,
    },
}))
const servicesList = [
    {
        id: 1,
        img: Service1,
        text: 'Lorem Ipsum is simply dummy text',
    },
    {
        id: 2,
        img: Service2,
        text: 'It is a long established fact',
    },
    {
        id: 3,
        img: Service3,
        text: 'There are many variations of passages ',
    },
    {
        id: 4,
        img: Service4,
        text: 'Contrary to popular belief, Lorem ',
    },
]

export default () => {
    const classes = useStyles()
    const [selectedIndex, setSelectedIndex] =
        useState(servicesList)

    const handleNext = () => {
        setSelectedIndex((prev) => {
            return prev.map((item, index) => {
                if (index === prev.length - 1)
                    return prev[0]
                return prev[index + 1]
            })
        })
    }
    const handlePrevious = () => {
        setSelectedIndex((prev) => {
            return prev.map((item, index) => {
                if (index === 0)
                    return prev[prev.length - 1]
                return prev[index - 1]
            })
        })
    }
    return (
        <Grid
            container
            className={classes.root}
            direction="column">
            <Typography className={classes.textService}>
                My Services
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ gap: 24 }}
                className={classes.service}>
                {selectedIndex.map((item, index) => {
                    return (
                        <Grid
                            container
                            alignItems="flex-end"
                            key={item.id}
                            className={classes.item_root}
                            style={{
                                backgroundImage: `url(${item.img})`,
                            }}>
                            {(index === 0 ||
                                index === 3) && (
                                <div
                                    className={
                                        classes.overlay
                                    }></div>
                            )}
                            {index === 1 && (
                                <Button
                                    className={clsx(
                                        classes.btn_icon,
                                        classes.btn_icon_left,
                                    )}
                                    onClick={
                                        handlePrevious
                                    }>
                                    <ForwardIcon />
                                </Button>
                            )}
                            {index === 2 && (
                                <Button
                                    className={clsx(
                                        classes.btn_icon,
                                        classes.btn_icon_right,
                                    )}
                                    onClick={handleNext}>
                                    <NextIcon />
                                </Button>
                            )}
                            <Grid
                                container
                                className={
                                    classes.text_box
                                }>
                                <Typography
                                    style={{
                                        fontSize: 14,
                                        color: 'white',
                                        fontWeight: '500',
                                    }}>
                                    {item.text}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
