import React from 'react'
import { ChatsStyle as useStyles } from './styles'
import erikaUser from '../../../../../assests/images/erika_provider.png'
import image4 from '../../../icons/ava_messages.png'

// @ts-ignore
export default function Chats({ client, msg, time, author }) {
    const classes = useStyles()
    const [fn, ln] = author.split(' ')

    return (
        <div
            className={classes.chats}
            style={{
                justifyContent: client === 'global' ? 'flex-start' : 'flex-end',
                // marginTop: client === 'global' ? "16px" : '8px',
            }}>
            <div className={classes.chatMessage}>
                <div
                    className={classes.profileImageContainer}
                    style={{ flexDirection: client === 'global' ? 'row-reverse' : 'row' }}>
                    <div
                        className={[classes.chatText].join(' ')}
                        style={{
                            backgroundColor: client === 'global' ? '#F5F6F7' : '#F0F4FF',
                            alignItems: client === 'global' ? 'flex-start' : 'flex-end',
                        }}>
                        <p className={classes.chatValue}>{msg}</p>
                        <p className={classes.chatTime}>
                            {/* {time < 5 ? 'Just now' : `${time} ago` } */}
                            {time < 5 ? 'Just now' : `${time}`}
                        </p>
                    </div>
                    {/* <img className="apt-provider-thumbnail" src={erikaUser} alt="" /> */}
                    {client === 'global' && (
                        <span className={classes.chatInitial}>
                            {/* <span className={classes.nameInitial}>{`${fn[0].toUpperCase()}${ln ? ln[0].toUpperCase() : ''}`}
                        </span> */}
                            <div className={classes.avatar}>
                                <img src={image4} alt="alternative_video" />
                            </div>
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}
