import moment from 'moment'
import { DATE_FORMAT, SCREEN } from './Constant'

export const INITIAL_VALUES = {
    referring_provider_id: '',
    specialty_id: '',
    location_id: '',
    status: '',
    patient_id: '',
    reason: '',
    checklist: [],
    created_date: moment(),
    received_date: moment(),
    due_schedule: moment().add(3, 'days'),
    note: '',
    notes: [],
    clinical_practice:'',
    noteView: 'no-view',
    date_appointment_first_offered: '',
    patient_declined_first_appointment: 0,
    next_appointment_date: '',
    next_appointment_id: ''
}
