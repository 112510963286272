import React from 'react'
import { FormLabel, TextField, TextFieldProps } from '@material-ui/core'
import { Box, SxProps } from '@mui/material'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { makeStyles } from '@material-ui/core'

export interface TextBoxProps {
    placeholder?: string
    label?: string | React.ReactNode | React.ReactChildren
    required?: boolean
    slot?: React.ReactNode | any
    layout?: 'vertical' | 'horizontal'
    uncontrolled?: boolean
    sx?: SxProps
    onChange?: (value: any, e?: any) => void
    labelComponent?: string | React.ReactNode | React.ReactChildren
}
const defaultSX = {
    '& .MuiOutlinedInput-root': {
        height: 'auto',
    },
    '& .MuiFormHelperText-root': {
        textTransform: 'inherit',
    },
}

const useStyle = makeStyles(() => ({
    checkboxLabel: {
        '& .MuiFormControlLabel-root': {
            marginRight: '0 !important',
            '& .MuiIconButton-root': {
                paddingBottom: '0 !important',
                paddingTop: '0 !important',
            },
        },
    },
}))

const TextBox = ({
    placeholder,
    label,
    required,
    slot,
    value,
    uncontrolled,
    layout = 'horizontal',
    sx,
    ...props
}: TextBoxProps & TextFieldProps) => {
    const classes = useStyle()

    return (
        <>
            {label && (
                <FormLabel
                    component="p"
                    className="d-flex justify-space-between align-center"
                    style={{ fontSize: 14, fontWeight: 500 }}>
                    <span
                        className={`${classes.checkboxLabel} ${!!required ? 'required' : ''}`}
                        style={{ color: '#303E4E' }}>
                        {props?.labelComponent}
                        {label}
                    </span>
                    {slot}
                </FormLabel>
            )}

            {!uncontrolled && (
                <Box sx={{ defaultSX, ...sx }}>
                    <TextField
                        variant="outlined"
                        style={{ height: 'fit-content' }}
                        placeholder={placeholder || 'Enter something'}
                        fullWidth
                        value={value || ''}
                        {...props}
                        onChange={(e) => props.onChange && props.onChange(e.target.value, e)}
                        error={props.error}
                        helperText={
                            props.error &&
                            (props.helperText || (required ? 'This field is required!' : ''))
                        }
                    />
                </Box>
            )}
        </>
    )
}

export { TextBox }
export default TextBox
