import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { Loading } from 'shared/elements/Loading'
import { Card } from '../../view/Card'
import { NoData } from '../NoData'

import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { usePatientDashboardStyles } from '../../style'
import { SubstanceAbuseTreatment } from '../../interfaces'
import { SubstanceAbuseTreatmentForm } from '../../view/SubstanceAbuseTreatmentHistory/SubstanceAbuseTreatmentForm'
import { apiSubstanceAbuseTreatmentHistory } from 'services/Patient/apiSubstanceAbuseTreatmentHistory'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    encounter_id?: string
}

export const SubstanceAbuseTreatmentHistory = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [substanceAbuseTreatment, setSubstanceAbuseTreatment] = useState<
        SubstanceAbuseTreatment[]
    >([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    const getSubstanceAbuseTreatmentHistory = async () => {
        try {
            setLoading(true)

            const resp = await apiSubstanceAbuseTreatmentHistory.getAllbyPtientId(patientId, {
                page: 1,
                size: 5,
            })
            if (resp.data) {
                setSubstanceAbuseTreatment(resp.data.data as SubstanceAbuseTreatment[])
            }
        } catch (ex) {
            notification('Cannot get list substanceAbuse', 'error')
        }

        setLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getSubstanceAbuseTreatmentHistory()
        }

        return () => abortController.abort()
    }, [patientId])

    const handleViewAll = () =>
        history.push(Routes.PATIENT.SUBSTANCE_ABUSE_TREATMENT_HISTORY.replace(':id', patientId))

    const Content = () => (
        <>
            {substanceAbuseTreatment.map((item, key) => (
                <Grid
                    container
                    key={key}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #EFF2F5',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        direction="column"
                        style={{ width: 'fit-content' }}>
                        <Typography>{item.treatment_name}</Typography>
                    </Grid>
                </Grid>
            ))}

            {!!substanceAbuseTreatment.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Substance Abuse Treatment History">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {substanceAbuseTreatment.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>
            {isAddingNew && (
                <SubstanceAbuseTreatmentForm
                    // substanceAbuseTreatmentId={}
                    patientId={patientId}
                    open={isAddingNew}
                    onClose={() => {
                        setIsAddingNew(false)
                    }}
                    onOke={async () => {
                        setIsAddingNew(false)
                        await getSubstanceAbuseTreatmentHistory()
                    }}
                />
            )}
        </Card>
    )
}
