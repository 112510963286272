import moment from 'moment'

export const INITIAL_VALUES = {
    id: '',
    // status: '',
    patient_id: '',
    // manufacturer_id: '',
    // lot_number: '',
    administered_by: { id: '' },
    // lot_expiration_date: '2015-02-15',
    administered_on: moment(),
    // administered_amount: 0,
    // note: '',
    // route: {
    //     code: '',
    //     display: '',
    // },
    // site: {
    //     code: '',
    //     display: '',
    // },
    vaccine: {
        code: '',
        display: '',
    },
}
