import React, { useState } from 'react';
import Draggable from 'react-draggable';
import useChatContext from '../../hooks/useChatContextProvider/useChatContext';
import { chatWindowStyle as useStyles } from './styles';
import SendIcon from '../../assets/submitchat.svg';
import ChatInput from './ChatInput/ChatInput';
import Chats from './Chats/Chats';
import MessageList from './MessageList/MessageList';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import close from '../../assets/close.png';
import {
    Dialog,
    DialogContent,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx'
import useSignal from 'components/Telehealth/hooks/useSignal';
interface Props {
    onClose: any;
    isDisplayChatProvider: boolean;
    room: Object;
}

export default function ChatWindow(props: Props) {
    const { onClose, isDisplayChatProvider, room } = props;
    const classes = useStyles();
    const {
        isChatWindowOpen,
        messages,
        conversation,
        setIsChatWindowOpen,
    } = useChatContext();
    const { listOfMessages, sendSignal } = useSignal({ room });
    const [text, setText] = useState('');
    const patientImage =
        'https://i.pinimg.com/564x/36/60/58/366058cd421e6a981e50c6f800abbbd0.jpg';
    const doctorImage =
        'https://i.pinimg.com/736x/b4/ea/c6/b4eac6d67645f2b6e1d1a440e42cca57.jpg';
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm'),
    );

    return (
        <>
            <div className={clsx({
                [classes.chatBox]: true,
                [classes.open]: isDisplayChatProvider,
            })}>
                <div
                    className={
                        classes.chatWindowContainer
                    }
                >
                    <div
                        className={
                            classes.chatWindowContainerTop
                        }
                    >
                        <Typography
                            className={
                                classes.chatWindowContainerTopName
                            }
                        >
                            Message
                        </Typography>
                        <button
                            onClick={onClose}
                            className={
                                classes.chatWindowContainerTopToggle
                            }
                        >
                            <HighlightOffRoundedIcon style={{
                                color: 'white', fontSize: 15,
                            }} />
                        </button>
                    </div>

                    <div
                        className={
                            classes.chatWindowBottomChats
                        }
                    >
                        <MessageList
                            messages={listOfMessages}
                            room={room}
                        />
                    </div>

                    <ChatInput
                        conversation={conversation!}
                        isChatWindowOpen={
                            isChatWindowOpen
                        }
                        sendMsg={sendSignal}
                    />
                </div>
            </div>
        </>
    );
}
