import { Button, IconButton, Popover, Typography } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import CloseIcon from '@mui/icons-material/Close'
// import { ReactComponent as ReportIcon } from 'assests/icons/error-icon.svg';
export default function AlertPopOverV2({
    anchorEl,
    handleClose,
    title,
    customHandleClose = null, //custom function to pass while cancelling
    disableOutsideClose = false, //allow or resist the popover to close while clicking outside
    bodyText,
    confirmButtonName = 'confirm',
    cancelButtonName = 'Cancel',
    confirmButtonColor = 'blue',
    iconColor = null,
    onSuccess,
    customAnchorOrigin = { vertical: 'bottom', horizontal: 'left' },
    customTransformOrigin = { vertical: 'top', horizontal: 'left' },
    type = 'default',
    customPaperStyle = {},
    icon,
    ...props
}) {
    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined
    const outsideClose = !disableOutsideClose
        ? { onClose: type === 'default' ? handleClose : onSuccess }
        : {}
    return (
        <div style={{ padding: '10px 20px', maxWidth: '300px' }}>
            <span
                style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    {icon ?? <ReportIcon
                        style={{
                            fontSize: '18px',
                            marginRight: '10px',
                            color: iconColor ? iconColor : confirmButtonColor,
                        }}
                    />}
                    <Typography variant="h6" fontWeight="bold">
                        <div dangerouslySetInnerHTML={{ __html: title }}></div>
                    </Typography>
                </span>
                {disableOutsideClose && (
                    <IconButton onClick={handleClose}>
                        <CloseIcon
                            style={{ fontSize: '20px', marginleft: '50px', color: '#666' }}
                        />
                    </IconButton>
                )}
            </span>
            <span style={{ paddingRight: '10px' }}>
                <Typography>
                    <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
                </Typography>
                {props.children}
            </span>
            <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                <Button
                    size="small"
                    className="cancel-btn"
                    onClick={() => (!customHandleClose ? handleClose() : customHandleClose())}
                    style={{ marginRight: '10px', color: '#444' }}>
                    {cancelButtonName}
                </Button>
                <Button
                    size="small"
                    variant="contained"
                    style={{ background: confirmButtonColor }}
                    onClick={onSuccess}>
                    {confirmButtonName}
                </Button>
            </span>
        </div>
    )
}