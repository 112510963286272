import React, { useEffect, useState } from 'react'
import { FormLabel, Grid, makeStyles, Typography, IconButton, Popover, Button } from '@material-ui/core'
import ReactFileUpload from 'react-butterfiles'

import SearchTaxionomyCodeInput from 'components/custom/SearchTaxionomyCodeInput'
import { MultiSearchTaxionomyCodeInput } from 'components/custom/MultiSearchTaxionomyCodeInput'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import axios from 'axios'
import { requestGetAvatarUrl, requestPutAvatarUrl } from 'services/Provider/provider'
import LoadingProgressPage from 'utilities/loading-progress-page'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import { OPTIONS_TIMEZONES } from 'constants/states'
import Select from 'shared/elements/FormItem/Select'
import './UserSecuritySettingsDialog/styles.scss'
import Divider from '@mui/material/Divider'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import TrashIcon from 'assests/icons/TrashIcon'
import NewSearchTaxonomyCodeInput from 'components/custom/NewSearchTaxonomyCodeInput'
import { LoadingButton } from '@mui/lab'
import ErrorIcon from '../../assests/icons/taxonomy.svg'
import { useNotification } from 'shared/elements/Notification'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import ImageUpload from './ImageUpload'
import { getListSpecialties } from 'services/Setting'
import { Select as MuiSelect, MenuItem } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles(() => ({
    text: {
        color: '#303E4E',
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 25,
    },
    reset_pass: {
        color: '#5571C6',
        fontSize: 14,
        textAlign: 'end',
        cursor: 'pointer',
        marginTop: 4,
    },
    required_text: {
        color: 'red',
    },
    container: {
        marginTop: 24,
        background: '#FBFCFC',
        border: '1px solid #F3F6F9',
        borderRadius: 10,
        width: 680,
        padding: '32px 40px',
    },
    avatar: {
        marginBottom: 16,
        overflow: 'hidden',
        position: 'relative',
        width: 180,
        height: 180,
        borderRadius: 800,
        background: 'gray',
    },
    add_image: {
        cursor: 'pointer',
        position: 'absolute',
        left: 0,
        bottom: 0,
        background: 'rgba(48, 62, 78, 0.8)',
        backdropFilter: 'blur(8px)',
        width: '100%',
        padding: '8px 0',
        textAlign: 'center',
    },
    display_image: {
        cursor: 'pointer',
        position: 'absolute',
        left: 0,
        bottom: 0,
        backdropFilter: 'blur(8px)',
        width: '100%',
        padding: '8px 0',
        textAlign: 'center',
    },
}))
const licenseTypeFixed = [{ key: "DEA", value: "DEA" }, { key: "NADEAN", value: "NADEAN" }]
const exceptionLicenseType = [
    { key: "NM", value: "NM License" },
    { key: "DC", value: "DC CS" },
    { key: "PR", value: "ASSCMA" }
]



const renderTaxonomyCodeInput = (props) => {
    const { isViewOnly } = props
    const [anchorEndEl, setAnchorEndEl] = useState(null)
    // const [anchorEl, setAnchorEl] = useState(null)
    const openEnd = Boolean(anchorEndEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined
    const handleClick = (event) => {
        if (!isViewOnly && props.provider && props.clinician) {
            event.stopPropagation()
            setAnchorEndEl(event.currentTarget)
        }
    }
    const handleClose = (fromYes = "") => {
        if (fromYes) {
            setAnchorEndEl(null)
        } else {
            props.setFieldValue('taxonomy_code', [
                ...props.taxonomy_code,
                {
                    id: '',
                    label: '',
                    isPrimary: false,
                },
            ])
            setAnchorEndEl(null)
        }

    }
    return props?.taxonomy_code?.map((taxonomyCode, index) => {
        return (
            <Grid container item key={index} style={{ paddingBottom: "10px" }}>
                <Grid item xs={10}>
                    <NewSearchTaxonomyCodeInput
                        value={taxonomyCode}
                        name={`taxonomy_code_${index}`}
                        handleAddRow={(value) => {
                            props.setFieldValue(
                                'taxonomy_code',
                                props.taxonomy_code.map((taxonomyCode, idx) => {
                                    if (idx === index)
                                        return {
                                            ...taxonomyCode,
                                            id: value?.id,
                                            label: value?.label,
                                            isPrimary: false,
                                        }
                                    return taxonomyCode
                                }),
                            )
                        }}
                        disabled={isViewOnly || !props.provider || !props.clinician}
                        error={
                            (props.touched.taxonomy_code || [])[index]?.id &&
                            Boolean((props.errors.taxonomy_code || [])[index]?.id)
                        }
                        helperText={
                            (props.touched.taxonomy_code || [])[index]?.id &&
                            (props.errors.taxonomy_code || [])[index]?.id
                        }
                    />
                </Grid>
                {(props.taxonomy_code.length === 1 || index + 1 === props.taxonomy_code.length) && (
                    <Grid item xs={1} style={{ marginTop: 5 }}>
                        <span
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                marginTop: '5px',
                                paddingLeft: '10px',
                            }}
                            onClick={handleClick}
                        >
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add New Code
                            </Typography>
                        </span>
                    </Grid>
                )}
                <Popover
                    id={idEnd}
                    open={openEnd}
                    anchorEl={anchorEndEl}
                    onClose={() => { handleClose() }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div className="popover_container">
                        <div className="heading_holder">
                            <img src={ErrorIcon}></img>
                            <h2 className="heading" style={{ paddingLeft: '5px' }}>
                                Set as primary?
                            </h2>
                        </div>
                        <Typography className="content">
                            Would you like to set this code as the primary taxonomy code?
                        </Typography>
                        <div className="btn_holder">
                            <Button className="no_bg" onClick={() => { handleClose() }}>
                                No
                            </Button>
                            <LoadingButton
                                style={{ background: '#5571C6', color: 'white' }}
                                className="error"
                                onClick={async () => {
                                    const updatedTaxonomy = props.taxonomy_code.map((item, idx) => ({
                                        ...item,
                                        isPrimary: idx === index ? true : false,
                                    }));
                                    props.setFieldValue('taxonomy_code', [
                                        ...updatedTaxonomy,
                                        {
                                            id: '',
                                            label: '',
                                            isPrimary: false,
                                        },
                                    ])
                                    handleClose(true)
                                }}>
                                Yes
                            </LoadingButton>
                        </div>
                    </div>
                </Popover>
                {props.taxonomy_code.length > 1 && index + 1 !== props.taxonomy_code.length && (
                    <Grid
                        item
                        xs={1}
                        style={{ marginTop: 0 }}
                        className="d-flex align-flex-start justify-end">
                        <Grid item style={{ paddingLeft: '10px' }}>
                            <Divider
                                variant="middle"
                                flexItem
                                orientation="vertical"
                                className="divider_selectBoxTaxonomy"
                            />
                        </Grid>
                        <IconButton
                            style={{ paddingLeft: '10px', marginTop: 8 }}
                            onClick={() => {
                                props.setFieldValue(
                                    'taxonomy_code',
                                    props.taxonomy_code.filter((_, idx) => idx !== index),
                                )
                            }}
                            disabled={isViewOnly || !props.provider || !props.clinician}
                        >
                            <TrashIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        )
    })
}

function ProviderAccountInformation({
    values,
    touched,
    errors,
    setFieldValue,
    changePassDialogToggle,
    isViewOnly = false,
    specialityOptions
}) {
    const notification = useNotification()
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const [avatar, setAvatar] = React.useState(values?.avatar)
    const [avatarUrl, setAvatarUrl] = React.useState()
    const [fileErrors, setFileErrors] = React.useState()
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [allselelctedState, setallStateSelected] = useState([])
    const [licenseType, setlicenseType] = useState([]
    )

    const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(percentCompleted)
    }



    useEffect(() => {
        licenseTypeAdder()
    }, [values])


    useEffect(() => {
    }, [licenseType])

    const licenseTypeAdder = () => {
        const arraygen = []
        const fixedException = ['DC', 'PR', "NM"]

        values?.states.map((itemxc, idx) => {
            exceptionLicenseType.map((item, idx) => {
                if (item.key === itemxc.state) {
                    // console.log(item)
                    arraygen.push(item)
                }


            })
        })
        const licenseTypex = arraygen.filter((obj, index, self) =>
            index === self.findIndex((o) => o.key === obj.key)
        );
        setlicenseType([...licenseTypeFixed, ...licenseTypex])
    }


    const licenseTypeRemover = () => {
        const fixedException = ['DC', 'PR', "NM"]

        fixedException.map((data, idx) => {
            const data1 = data
            values?.states.map((singlestate, data) => {
                if (singlestate.state === data1) {

                    console.log(data1, "IAI<POP")
                } else {
                    values?.prescribing_license?.map((utem) => {
                        if (utem.type === data1)
                            utem.type = ''
                    })
                }

            })

        })

    }

    const handleUploadImage = async (files) => {
        const file = files[0]
        setIsLoading(true)
        setUploadProgress(0)
        const fileContext = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.addEventListener('loadend', (event) => {
                resolve(event.target.result)
            })
            reader.readAsArrayBuffer(file?.src?.file)
        })

        const res = await requestPutAvatarUrl()
        const { url, key } = res.data
        await axios.put(url, fileContext, {
            headers: {
                'Content-Type': file.type,
            },
            onUploadProgress,
        })
        setIsLoading(false)
        setAvatar(key)
        setFieldValue('avatar', key)
    }

    useEffect(() => {

        console.log(values, "()=>ooop")
    }, [values])

    const handleChangValueTaxionomyCodeuseEffect = (code, checked) => {
        let record = [...values.taxonomy_code]
        if (checked) {
            record.push(code)
        } else {
            record = record.filter((item) => item !== code)
        }

        setFieldValue('taxonomy_code', record)
    }
    useEffect(async () => {
        if (!avatar) {
            return
        }
        const res = await requestGetAvatarUrl(avatar)
        const { url, key } = res.data
        setAvatarUrl(url)
    }, [avatar])

    return (

        <Grid container item spacing={2}>
            {/*--------------------------------------Provider ----------------------- -----------------*/}
            <Grid
                item
                xs={12}
                style={{ opacity: !values?.provider_account || !values?.clinician ? 0.4 : '' }}>
                <fieldset className="divider-information">
                    <legend>
                        <Typography className={'heading1-title'}>Provider Account</Typography>
                    </legend>
                    <Grid container item alignItems="flex-start" spacing={2}>
                        <Grid item xs={4}>
                            <TextBox
                                placeholder="Enter NPI"
                                label="Provider NPI"
                                name="npi"
                                required
                                value={values?.npi}
                                error={touched?.npi && Boolean(errors?.npi)}
                                helperText={touched?.npi && errors?.npi}
                                onChange={(value) =>
                                    setFieldValue('npi', value?.replace(/[^0-9]+/g, ''))
                                }
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                                inputProps={{ maxLength: 10 }} // NPI consists of 10 digits
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                required
                                label="Suffix"
                                name="suffix"
                                value={values?.suffix}
                                inputProps={{
                                    maxLength: 10,
                                }}
                                error={touched?.suffix && Boolean(errors?.suffix)}
                                helperText={touched?.suffix && errors?.suffix}
                                placeholder="Enter Suffix"
                                onChange={
                                    (value) => setFieldValue('suffix', value?.toUpperCase())
                                    // setFieldValue('suffix', value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ''))
                                }
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextBox
                                placeholder="Enter Kareo ID"
                                label="Kareo ID"
                                required
                                name="kareoId"
                                value={values.kareoId}
                                error={touched.kareoId && Boolean(errors.kareoId)}
                                helperText={touched.kareoId && errors.kareoId}
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                                onChange={(value) => setFieldValue('kareoId', value)}
                            />
                        </Grid> */}
                        <Grid item xs={4}>
                            <FormLabel
                                component="p"
                                className="input-form-label"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}>
                                Time zone{' '}
                            </FormLabel>
                            <Select
                                name="timezone"
                                placeholder="Select time zone"
                                value={values?.timezone}
                                options={OPTIONS_TIMEZONES.map((item) => ({
                                    key: item.key,
                                    value: item.label,
                                }))}
                                onChange={(value) => {
                                    setFieldValue('timezone', value)
                                }}
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormLabel
                                component="p"
                                className="input-form-label"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                }}>
                                Supervising Physician{' '}
                            </FormLabel>
                            <SearchProviderInput
                                value={values?.supervising_physician}
                                handleInputChange={(e) => {
                                    setFieldValue('supervising_physician', e?.target?.value)
                                }}
                                isProviderOnly={true}
                                handleAddRow={(value) => {
                                    setFieldValue('supervising_physician', value?.id)
                                }}
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUpload
                                onChange={({ attachment_key }) => {
                                    setAvatar(attachment_key)
                                    setFieldValue('avatar', attachment_key)
                                }}
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid container item alignItems="flex-end">
                                    <FormLabel
                                        component="p"
                                        className="input-form-label"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                        }}>
                                        Speciality
                                        {/* <span
                                            style={{
                                                color: 'red',
                                            }}>
                                            *
                                        </span> */}
                                    </FormLabel>
                                </Grid>
                            </Grid>

                            <MuiSelect
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => (<KeyboardArrowDown {...props} />)}
                                renderValue={(selected) => {
                                    if (selected?.length === 0 || !selected) {
                                        return <Typography className={classes.placeholder}>Select</Typography>;
                                    }

                                    return selected;
                                }}
                                value={values?.specialities}
                                onChange={(e) => {
                                    setFieldValue('specialities', e?.target?.value)
                                }}
                                disabled={
                                    isViewOnly || !values?.provider_account || !values?.clinician
                                }
                                style={{ width: '100%' }}
                            >
                                {specialityOptions?.map(item => (
                                    <MenuItem key={item?.key} value={item?.value}>
                                        <Typography
                                            // variant="body2"
                                            noWrap
                                            style={{
                                                color: '#667689',
                                                fontSize: 14,
                                            }}>
                                            {item?.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </MuiSelect>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid container item alignItems="flex-end">
                                    <FormLabel
                                        component="p"
                                        className="input-form-label"
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 500,
                                        }}>
                                        Taxonomy Codes
                                        <span
                                            style={{
                                                color: 'red',
                                            }}>
                                            *
                                        </span>
                                    </FormLabel>
                                </Grid>
                            </Grid>
                            {renderTaxonomyCodeInput({
                                taxonomy_code: values?.taxonomy_code,
                                isRequired: true,
                                isViewOnly,
                                setFieldValue,
                                touched,
                                errors,
                                provider: values?.provider_account,
                                clinician: values?.clinician,
                            })}
                        </Grid>
                    </Grid>
                </fieldset>
            </Grid>
            {/*--------------------------------------State License ----------------------- -----------------*/}
            <Grid
                item
                xs={12}
                spacing={2}
                style={{ opacity: !values?.provider_account || !values?.clinician ? 0.4 : '' }}>
                <fieldset className="divider-information">
                    <legend>
                        <Typography className={'heading1-title'}>State License</Typography>
                    </legend>
                    <Typography className={'heading1-title-licenses'}>
                        Enter all of provider’s licenses.
                    </Typography>
                    <Grid item style={{ padding: '0px 10px 10px 10px' }}>
                        {values?.states?.length > 0 &&
                            values?.states.map((item, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        container
                                        item
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ marginTop: index === 0 ? '10px' : '20px' }}
                                        className="modifier_top_state">
                                        <Grid item xs={4}>
                                            <Select
                                                resetData
                                                label="State"
                                                name="state"
                                                // required
                                                placeholder="Select State"
                                                value={item.state}
                                                options={stateList.map((item) => ({
                                                    key: item.state,
                                                    value: item.stateLabel,
                                                }))}
                                                selectProps={{
                                                    style: {
                                                        height: 40,
                                                    },
                                                }}
                                                onChange={(value) => {
                                                    const updatedStates = values?.states.map(


                                                        (item, idx) => {
                                                            return {
                                                                ...item,
                                                                state:
                                                                    idx === index ? value : item.state,
                                                            }
                                                        })

                                                    const findeValue = (updatedStates.filter((e) => e.state === item.state))
                                                    if (findeValue.length === 0) {
                                                        const licenseTypeupdate = values?.prescribing_license?.map(
                                                            (itemx, idx) => {
                                                                if (itemx.type === item.state) {
                                                                    // aler
                                                                    itemx.type = ''
                                                                }
                                                                return {
                                                                    ...itemx
                                                                }
                                                            }

                                                        )
                                                        setFieldValue('prescribing_license', licenseTypeupdate)
                                                    }

                                                    setallStateSelected([item])
                                                    // pescribingLicenseSelctionChecker();
                                                    setFieldValue('states', updatedStates)
                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }
                                                // error={
                                                //     (touched.states || [])[index]?.state &&
                                                //     Boolean((errors.states || [])[index]?.state)
                                                // }
                                                // helperText={
                                                //     (touched.states || [])[index]?.state &&
                                                //     (errors.states || [])[index]?.state
                                                // }
                                            />
                                        </Grid>
                                        {/* <Grid item xs={4}>
                                            <Select
                                                resetData
                                                label="License Type"
                                                name="license_type"
                                                placeholder="License Type"
                                                value={item.type}
                                                options={licenseType}
                                                selectProps={{
                                                    style: {
                                                        height: 40,
                                                    },
                                                }}
                                                onChange={(value) => {
                                                    const updatedStates = values?.states.map(
                                                        (item, idx) => ({
                                                            ...item,
                                                            type: idx === index ? value : item.type,
                                                        }),
                                                    )
                                                    setFieldValue('states', updatedStates)
                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }
                                                error={
                                                    (touched.states || [])[index]?.type &&
                                                    Boolean((errors.states || [])[index]?.type)
                                                }
                                                helperText={
                                                    (touched.states || [])[index]?.type &&
                                                    (errors.states || [])[index]?.type
                                                }
                                            />
                                        </Grid> */}
                                        <Grid item xs={7}>
                                            <TextBox
                                                placeholder="Enter License Number"
                                                label="License Number"
                                                name="license_number"
                                                // required
                                                value={item.number}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }
                                                onChange={(value) => {
                                                    if (
                                                        (value && /^[a-zA-Z0-9]+$/gi.test(value)) ||
                                                        !value
                                                    ) {
                                                        const updatedStates = values?.states.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                number:
                                                                    idx === index
                                                                        ? value
                                                                        : item.number,
                                                            }),
                                                        )
                                                        setFieldValue('states', updatedStates)
                                                    }
                                                }}
                                                // error={
                                                //     (touched.states || [])[index]?.number &&
                                                //     Boolean((errors.states || [])[index]?.number)
                                                // }
                                                // helperText={
                                                //     (touched.states || [])[index]?.number &&
                                                //     (errors.states || [])[index]?.number
                                                // }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            className="d-flex align-flex-start justify-end">
                                            <Grid
                                                item
                                                style={{ paddingLeft: '10px', marginTop: '31px' }}>
                                                <Divider
                                                    variant="middle"
                                                    flexItem
                                                    orientation="vertical"
                                                    className="divider_selectBoxTaxonomy"
                                                />
                                            </Grid>
                                            <IconButton
                                                style={{ paddingLeft: '10px', marginTop: '38px' }}
                                                onClick={() => {
                                                    if (values.states.length > 1) {
                                                        setFieldValue(
                                                            'states',
                                                            values.states.filter(
                                                                (_, idx) => idx !== index,
                                                            ),
                                                        )
                                                    } else {
                                                        notification(
                                                            `You Can't delete the State License row but filed empty sucessfully`,
                                                            'info',
                                                        )
                                                    }
                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }>
                                                <TrashIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            whiteSpace: 'nowrap',
                            marginTop: '5px',
                        }}
                        onClick={() => {
                            if (!isViewOnly && values?.clinician && values?.provider_account) {
                                setFieldValue('states', [
                                    ...values.states,
                                    {
                                        state: '',
                                        type: 'primary',
                                        number: '',
                                    },
                                ])
                            }
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}>
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add New License
                            </Typography>
                        </div>
                    </div>
                </fieldset>
            </Grid>
            {/*--------------------------------------DEA Number ----------------------- -----------------*/}
            <Grid
                item
                xs={12}
                spacing={2}
                style={{ marginBottom: '1rem', opacity: !values?.provider_account || !values?.clinician ? 0.4 : '' }}>
                <fieldset className="divider-information">
                    <legend>
                        <Typography className={'heading1-title'}>Prescribing License</Typography>
                    </legend>
                    <Typography className={'heading1-title-licenses'}>
                        Enter all of provider’s DEA numbers.{' '}
                    </Typography>
                    <Grid item style={{ padding: '0px 10px 10px 10px' }}>
                        {values?.prescribing_license?.length > 0 &&
                            values?.prescribing_license?.map((item, index) => {
                                return (
                                    <Grid
                                        key={index}
                                        container
                                        item
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ marginTop: index === 0 ? '10px' : '20px' }}
                                        className="modifier_top_state">
                                        <Grid item xs={2}>
                                            <Select
                                                resetData
                                                label="License Type"
                                                name="license_type"
                                                placeholder="License Type"
                                                value={item.type}
                                                options={licenseType}
                                                selectProps={{
                                                    style: {
                                                        height: 40,
                                                    },
                                                }}
                                                onChange={(value) => {
                                                    let err = false
                                                    values?.prescribing_license?.map((item, idx) => {
                                                        if (['DC', 'PR', 'NM'].includes(value) && item.type === value) {

                                                            notification(
                                                                `Cannot select twice this option`,
                                                            )
                                                            err = true
                                                            return;
                                                        }
                                                    })

                                                    if (!err) {
                                                        const updatedStates = values?.prescribing_license?.map(
                                                            (item, idx) => ({
                                                                ...item,
                                                                type: idx === index ? value : item.type,
                                                            }),
                                                        )
                                                        setFieldValue('prescribing_license', updatedStates)
                                                    }

                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }
                                                // error={
                                                //     (touched.states || [])[index]?.type &&
                                                //     Boolean((errors.states || [])[index]?.type)
                                                // }
                                                // helperText={
                                                //     (touched.states || [])[index]?.type &&
                                                //     (errors.states || [])[index]?.type
                                                // }
                                            />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <TextBox
                                                label="DEA Number"
                                                name="dea_number"
                                                placeholder="Enter DEA number"
                                                value={item.number}
                                                onChange={(value) => {
                                                    const updatedDEA = values?.prescribing_license?.map(
                                                        (item, idx) => ({
                                                            ...item,
                                                            number: idx === index ? value : item.number,
                                                        }),
                                                    )
                                                    setFieldValue('prescribing_license', updatedDEA)
                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }
                                                inputProps={{ maxLength: 80 }}
                                                error={
                                                    (touched.prescribing_license || [])[index]
                                                        ?.number &&
                                                    Boolean(
                                                        (errors.prescribing_license || [])[
                                                            index
                                                        ]?.number,
                                                    )
                                                }
                                                helperText={
                                                    (touched.prescribing_license || [])[index]
                                                        ?.number &&
                                                    (errors.prescribing_license || [])[index]
                                                        ?.number
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ marginTop: '32px' }}
                                            className="d-flex align-flex-start justify-end">
                                            <Grid item style={{ paddingLeft: '10px' }}>
                                                <Divider
                                                    variant="middle"
                                                    flexItem
                                                    orientation="vertical"
                                                    className="divider_selectBoxTaxonomy"
                                                />
                                            </Grid>
                                            <IconButton
                                                style={{ paddingLeft: '10px', marginTop: 8 }}
                                                onClick={() => {
                                                    if (values?.prescribing_license?.length > 1) {
                                                        setFieldValue(
                                                            'prescribing_license',
                                                            values?.prescribing_license?.filter(
                                                                (_, idx) => idx !== index,
                                                            ),
                                                        )
                                                    } else {
                                                        notification(
                                                            `You Can't delete the DEA Number row but filed empty sucessfully`,
                                                            'info',
                                                        )
                                                    }
                                                }}
                                                disabled={
                                                    isViewOnly || !values?.provider_account || !values?.clinician
                                                }>
                                                <TrashIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                    </Grid>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            whiteSpace: 'nowrap',
                            marginTop: '5px',
                        }}
                        onClick={() => {
                            if (!isViewOnly && values?.clinician && values?.provider_account) {
                                setFieldValue('prescribing_license', [...values?.prescribing_license, { type: '', number: '' }])
                            }
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}>
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add New Number
                            </Typography>
                        </div>
                    </div>
                </fieldset>
            </Grid>
        </Grid>
    )
}

export default ProviderAccountInformation
