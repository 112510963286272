import React, { useState, useEffect } from 'react'
import { TextField, makeStyles } from '@material-ui/core'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles({
    autocomplete: {
        '& .MuiIconButton-root': {
            color: '#303e4e',
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input': {
            padding: '7.5px 36px 7.5px 6px',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
            display: 'flex',
            alignItems: 'flex-start',
        },
    },
})

export const SelectAndEnterText = (props) => {
    const classes = useStyles()

    const {
        placeholder,
        options,
        onOpen,
        onClose,
        onInputChange,
        onChange,
        error,
        helperText,
        inputValue = '',
        ...rest
    } = props

    const [open, setOpen] = useState(false)

    return (
        <Box>
            <Autocomplete
                className={classes['autocomplete']}
                open={open}
                onOpen={() => {
                    setOpen(true)
                    onOpen?.()
                }}
                onClose={() => {
                    setOpen(false)
                    onClose?.()
                }}
                options={options}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <span style={{ fontSize: '14px' }}>
                                {option.value} {option.custom_id ? ` - ${option.custom_id}` : ''}
                            </span>
                        </li>
                    )
                }}
                getOptionLabel={(option) => option.value}
                clearIcon={false}
                disablePortal
                inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        sx={{
                            '& .MuiOutlinedInput-root.MuiInputBase-input': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0px 36px 0px 0px',
                            },
                        }}
                        size="small"
                        variant="outlined"
                        error={error}
                        helperText={helperText}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <KeyboardArrowDown
                                    style={{
                                        position: 'relative',
                                        left: 6,
                                        top: -6,
                                    }}
                                />
                            ),
                        }}
                    />
                )}
                onInputChange={(e, value) => {
                    if (e?.type === 'change') {
                        onInputChange?.(value || '')
                    }
                }}
                onChange={(e, option) => {
                    onInputChange?.(option?.value || '')
                }}
                {...rest}
            />
        </Box>
    )
}
