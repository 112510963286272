import React from 'react'
import { FormControl, InputLabel, MenuItem, OutlinedInput, Typography } from '@material-ui/core'
import clsx from 'clsx'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Select from '@mui/material/Select'
import CircularProgress from '@mui/material/CircularProgress'

import { useStyles } from './styles'

function SelectEl(props) {
    const classes = useStyles(props)
    return (
        <FormControl
            disabled={props.disabled}
            fullWidth
            classes={{
                root: classes.oulinedInput,
            }}
            variant="outlined">
            {props.label && <InputLabel>{props.label}</InputLabel>}
            <Select
                fullWidth
                displayEmpty
                input={
                    <OutlinedInput
                        margin="dense"
                    // classes={classes}
                    />
                }
                classes={{
                    root: classes.root,
                }}
                style={{
                    height: props.height,
                    padding: 0,
                    backgroundColor: '#FFF',
                    ...props?.style,
                    ...props?.selectprops?.style,
                }}
                IconComponent={KeyboardArrowDown}
                {...props}
                renderValue={
                    props.placeholder
                        ? (sel) => {
                            return (typeof sel === 'string' && !sel) || !sel ? (
                                <Typography
                                    style={{
                                        color: '#a6acb3',
                                    }}>
                                    {props.placeholder}
                                </Typography>
                            ) : (
                                <Typography
                                    className={clsx(
                                        classes.ellipsis__text,
                                        classes.colorContent,
                                    )}>
                                    {props.options.find((item) => item.key == sel)?.value}
                                </Typography>
                            )
                        }
                        : null
                }>
                {props.loadingoption &&
                    (props.options.length === 0 ? (
                        <MenuItem value={'.........'}>
                            <Typography
                                variant="body2"
                                noWrap
                                style={{
                                    color: '#667689',
                                    fontSize: 14,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <CircularProgress size={15} />
                            </Typography>
                        </MenuItem>
                    ) : (
                        props.options.map((option, index) => (
                            <MenuItem key={index} value={option.key}>
                                <Typography
                                    variant="body2"
                                    noWrap
                                    style={{
                                        color: '#667689',
                                        fontSize: 14,
                                        ...(props.dropdownliststyles || {}),
                                    }}>
                                    {option.value}
                                </Typography>
                            </MenuItem>
                        ))
                    ))}

                {!props.loadingoption &&
                    props.options.map((option, index) => (
                        <MenuItem key={index} value={option.key}>
                            <Typography
                                variant="body2"
                                noWrap
                                style={{
                                    color: '#667689',
                                    fontSize: 14,
                                    ...(props.dropdownliststyles || {}),
                                }}>
                                {option.value}
                            </Typography>
                        </MenuItem>
                    ))}
            </Select>

            {props.error && (
                <p
                    style={{
                        margin: '8px 0 0 0',
                    }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text"
                    id={`${props.name}-helper-text`}>
                    {props.helperText}
                </p>
            )}
        </FormControl>
    )
}

SelectEl.defaultProps = {
    border: '1px solid $secondaryGray',
    height: 40,
}

export default SelectEl
