import * as actionType from '../actionType'

export const setLoading = (value) => {
    return {
        type: actionType.SET_LOADER,
        value,
    }
}
export const setRoleLoading = (value) => {
    return {
        type: actionType.SET_ROLE_LOADER,
        value,
    }
}

export const setError = (value) => {
    return {
        type: actionType.SET_ERROR,
        value,
    }
}

export const setErrorMessage = (value) => {
    return {
        type: actionType.SET_ERROR_MSG,
        value,
    }
}

export const setLoadingAdmit = (value) => {
    return {
        type: actionType.SET_LOADING_ADMIT,
        value,
    }
}

export const setLoadingEndRoom = (value) => {
    return {
        type: actionType.SET_LOADING_END_MEETING,
        value,
    }
}

export const setAddingParticipant = (value) => {
    return {
        type: actionType.SET_ADDING_PARTICIPANT,
        value,
    }
}
