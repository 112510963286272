import React, { useState } from 'react'
import {
    Box,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    makeStyles,
} from '@material-ui/core'

import { PATIENT_VISIT_TYPE, optionTZ } from '../constant'
import SearchLocationInput from 'components/custom/SearchLocationInput'
import { SelectEl } from 'shared/elements'
import NoteField from 'containers/WorkQueues/component/NoteField'
import moment from 'moment'
import { useSelector } from 'react-redux'
import SearchLocationInputSchedule from './SearchLocationInputSchedule'
import { staffNoteAdd } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
const { v4: uuidv4 } = require('uuid')


const useStyles = makeStyles(() => ({
    fieldSet: {
        paddingBottom: "20px",
        width: "100%",
        border: '1px solid #666',
        borderRadius: "5px",
        marginBottom: "28px"
    },
    fieldSet1: {
        paddingBottom: "20px",
        width: "100%",
        border: '1px solid #666',
        borderRadius: "5px",
        marginBottom: "20px"
    },
}))


function ServiceType({ formik, classes, isAppointmentCompleted }) {
    const mystyle = useStyles()
    const [listRoom, setListRoom] = useState([])
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const [notesLoading, setNotesLoading] = useState(false)

    const notification = useNotification()

    const onChangeNote = (allNotes, appointmentId, callBack) => {
        setNotesLoading(true)
        staffNoteAdd({
            notes: allNotes,
            resource_name: "Appointment",
            resource_id: appointmentId
        })?.then(() => {
            callBack()
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => setNotesLoading(false))
    }

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                allNotesFinal = [
                    ...formik.values?.notes,
                    {
                        id: uuidv4(),
                        content: formik.values.note,
                        created_at: moment(new Date()),
                        created_by: loggedInUser['custom:user_id'],
                        name: loggedInUser['name'],
                        state: 'Created note'
                    },
                ]
            } else allNotesFinal = formik.values?.notes

            if(formik?.values?.id && formik?.values?.id !== "") {
                onChangeNote(allNotesFinal, formik?.values?.id, () => {
                    formik.setFieldValue('notes', allNotesFinal)
                    formik.setFieldValue('note', '')
                })
            }else{
                formik.setFieldValue('notes', allNotesFinal)
                formik.setFieldValue('note', '')    
            }
        },
        [formik],
    )

    const handleEditNote = (updatedNotes) => {
        if(formik?.values?.id && formik?.values?.id !== "") {
            onChangeNote(updatedNotes, formik?.values?.id, () => {
                formik.setFieldValue('notes', updatedNotes)
            })
        }else{
            formik.setFieldValue('notes', updatedNotes)
        }
    }

    return (
        <Grid container spacing={2}>
            <fieldset className={mystyle.fieldSet}>
                <legend>Service Type</legend>
                <Grid container item spacing={2}>
                    <Grid item xs={3}>
                        <FormLabel component="p" className="input-form-label">
                            Service Type<span className={classes.required_text}>*</span>
                        </FormLabel>
                        <RadioGroup
                            row
                            value={formik.values.appointment_type}
                            onChange={(e) => {
                                formik.setFieldValue('appointment_type', e.target.value)
                            }}>
                            <FormControlLabel
                                value={PATIENT_VISIT_TYPE.TELEHEALTH}
                                control={<Radio color="primary" />}
                                label="Telehealth Visit"
                                disabled={isAppointmentCompleted}
                            />
                            <FormControlLabel
                                value={PATIENT_VISIT_TYPE.OFFICE}
                                control={<Radio color="primary" />}
                                label="In Office"
                                disabled={isAppointmentCompleted}
                            />
                        </RadioGroup>
                        {formik.touched.appointment_type && Boolean(formik.errors.appointment_type) && (
                            <p
                                style={{
                                    margin: '8px 0 0 0',
                                }}
                                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                                {formik.errors.appointment_type}
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={3} >
                        <FormLabel component="p" className="input-form-label">
                            Service Location<span className={classes.required_text}>*</span>
                        </FormLabel>
                        <SearchLocationInputSchedule
                            edit={formik.values.id}
                            value={formik.values.location_id}
                            handleInputChange={(e) => {
                                formik.setFieldValue('location_id', e.target.value)
                            }}
                            handleAddRow={(value) => {
                                formik.setFieldValue('location_id', value?.id)
                            }}
                            error={
                                formik.touched.location_id && Boolean(formik.errors.location_id)
                            }
                            helperText={formik.touched.location_id && formik.errors.location_id}
                            placeholder="Search Location"
                            disabled={isAppointmentCompleted}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormLabel component="p" className="input-form-label">
                            Room
                        </FormLabel>
                        <SelectEl
                            name="room"
                            placeholder="Select Room"
                            options={listRoom}
                            selectProps={{
                                style: {
                                    height: 40,
                                },
                            }}
                            onChange={(e) => { }}
                            disabled={isAppointmentCompleted}
                        />
                    </Grid>
                </Grid>
            </fieldset >
            <fieldset className={mystyle.fieldSet1} >
                <legend>Staff Notes</legend>
                <NoteField
                    note={formik?.values?.note}
                    noteView={formik?.values.noteView}
                    notes={formik.values.notes}
                    onChange={formik?.setFieldValue}
                    // onBlur={handleNotesChange}
                    handleNotesChange={handleNotesChange}
                    handleNotesEditChange={handleEditNote}
                    loading={notesLoading}
                />
            </fieldset>
        </Grid >
    )
}

export default ServiceType