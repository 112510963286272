import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react'
import { ModalEl } from 'shared/elements'
import moment from 'moment'
import 'moment-timezone'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Box } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { uniqBy, isEmpty } from 'lodash'
import { makeStyles, withStyles } from '@material-ui/styles'
import { getProivders, getSchedule, getVisitType } from 'services/Calendar'
import { TIME_FORMAT, VIEW } from 'containers/CalendarScheduler/constant'
import { DayCalendarEvent, MonthCalendarEvent, WeekCalendarEvent, eventStyleGetter } from 'containers/CalendarScheduler/EventCard/components'
import { MonthHeaderCellContent, WeekHeaderCellContent } from 'containers/CalendarScheduler/CalendarHeader'
import AlertEmtyForm from 'components/shared/Elements/AlertEmtyForm'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { ReactComponent as TopPanelCalendarIcon } from 'assests/icons/calendar.svg'
import { ReactComponent as NextDateArrow } from 'assests/icons/next-arrow.svg'
import { ReactComponent as PreviousDateArrow } from 'assests/icons/pre-arrow.svg'
import { Divider, Grid, IconButton, Tab, Tabs, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { ReactComponent as TodayIcon } from 'assests/icons/todayIcon.svg'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import { ReactComponent as ReloadIcon } from 'assests/images/Reload.svg'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import { getDateInClassicFormat } from 'utilities/dateOperations'
import { useNotification } from 'shared/elements/Notification'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Calendar as ReactMiniCalendar } from 'react-date-range'
import { getDraftAppointments } from '../../CalendarScheduler/helpers'

const useStyles = makeStyles(() => ({
    wrapDay: {
        height: '765px',
        padding: '30px 30px 30px 30px',
        '& .close-viewday': {
            color: '#606060',
            cursor: 'pointer',
            position: 'absolute',
            top: '3px',
            right: '10px',
        },
        '& .rbc-toolbar, .rbc-allday-cell': {
            // color: '#333',
            display: 'none'
        },
        '& .rbc-btn-group': {
            display: 'none',
        },
        '& .rbc-label': {
            fontSize: '14px',
            color: '#333',
        },
        '& .rbc-day-slot .rbc-event-content': {
            display: 'flex',
        },
        '& .rbc-time-header': {
            // display: 'none',
        },
        '& .rbc-header button': {
            width: '100%'
        },
        '& .rbc-time-content': {
            overflowX: 'scroll'
        },
        '& .custom-top-panel': {
            padding: '10px 20px 0px 20px',
            borderRadius: '5px',
            border: '1px solid #EAECF0',
            background: '#F8F9FB',
            marginBottom: 2,
            '& .upper-top-panel': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 5,
                '& button, .MuiTabs-root': {
                    minHeight: '30px !important',
                    height: '30px !important'
                }
            },
            '& .lower-top-panel': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }
        },
        '& .calendar-container': {
            padding: '0px 10px 40px 10px',
            borderRadius: '5px',
            border: '1px solid #EAECF0',
            background: '#F8F9FB',
            maxHeight: 620
        },
        '& .non-day-view-header-space': {
            paddingTop: '40px !important'
        },
        '& .rbc-calendar': {
            overflowY: 'scroll',
            height: '640px',
            '& .rbc-day-slot .rbc-events-container': {
                marginRight: 0
            },
            '& .rbc-month-header .rbc-header': {
                height: '49px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                display: 'flex',
                padding: 0,
                position: 'relative',
                color: '#303e4e !important',
                '& .MuiGrid-root .MuiGrid-item': {
                    display: 'flex',
                    justifyContent: 'center'
                }
            },
            '& .rbc-month-row': {
                borderLeft: '1px solid #ddd',
                borderRight: '1px solid #ddd',
                borderBottom: '1px solid #ddd',
                '& .rbc-row-content .rbc-row': {
                    '& .rbc-date-cell button': {
                        width: '100%',
                        border: 'none',
                        fontFamily: 'Rubik',
                        fontSize: 16
                    },
                    '& .rbc-off-range button': {
                        color: '#ddd',
                        pointerEvents: 'none'
                    }
                }
            },
        },
        '& .date-header': {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '140%'
        }
    },
    customResourceTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 10px',
        width: '100%',
        border: '2px solid #999',
        boxShadow: '2px 2px 5px #999',
        borderRadius: '5px',
        position: 'relative',
        backgroundColor: '#fff',
        '& button': {
            width: 'auto !important'
        }
    }
}))

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        padding: '0 8px',
        background: 'none',
        color: 'black',
        textTransform: 'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            background: '#5571C6',
            borderRadius: '8px',
            color: 'white',
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: '10px !important',
    },
}))((props) => <Tab {...props} />)

const AllViewMiniCalendar = (props) => {
    const { date = moment(), open, onClose, onOke, setFieldValue, idProvider, values, idProviderName } = props
    
    const classes = useStyles()
    const notification = useNotification()
    const refOne = useRef(null)
    const calIcon = useRef(null)
    const clickRef = useRef(null)

    useEffect(() => {
        return () => {
          window.clearTimeout(clickRef?.current)
        }
      }, [])

    const { isProvider, currentUserId, currentUserName } = useMemo(() => ({
        isProvider: localStorage.getItem('real_role')?.includes('provider'),
        currentUserId: localStorage.getItem('userId'),
        currentUserName: localStorage.getItem('name')
    }), [])
        
    const defaultProviderMulti = useMemo(() => {
        if(idProvider) return [{
            key: idProvider,
            value: idProviderName
        }]

        if(isProvider) return [{
            key: currentUserId,
            value: currentUserName
        }]
        
        return []
    }, [])

    //State
    const [alertEmtyForm, setAlerEmtyForm] = useState(false)
    const [data, setData] = useState([])
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [selectedView, setSelectedView] = useState(VIEW.DAY)
    const [updatedDate, setUpdatedDate] = useState(new Date())

    const [providerOptions, setProviderOptions] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [visitTypeOptions, setVisitTypeOptions] = useState([])
    const [statusOptions, setstatusOptions] = useState([])
    const [providerMulti, setProviderMulti] = useState(defaultProviderMulti)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
    const [isFirstFilterClick, setIsFirstFilterClick] = useState(false)
    const [showCancelledAppointment, setShowCancelledAppointment] = useState(false)
    const [showMiniCalendar, setShowMiniCalendar] = useState(false)

    function addKeyConditionally(arr1, arr2) {
        for (const obj1 of arr1) {
            // Find the corresponding object in the second array based on the 'id'
            const obj2 = arr2?.find((item) => item?.id === parseInt(obj1?.visit_type_id))

            // If a corresponding object is found, add the key
            if (obj2) {
                obj1.color_tag = obj2?.color_tag
            } else {
                obj1.color_tag = obj1?.admin_color || ''
            }
        }
        return arr1
    }

    const handleAccordionExpandNew = () => {
        setIsAccordionExpanded(!isAccordionExpanded)
    }

    const filterOptionsArrayObject = [{
        key: 'provider_multi',
        name: 'Provider',
        value: providerMulti,
        options: providerOptions,
        loading: false,
        AccordionExpanded: isAccordionExpanded,
        handleAccordionExpand: handleAccordionExpandNew,
    }]

    const { defaultDate, localizer } = useMemo(
        () => ({
            defaultDate: moment(updatedDate),
            localizer: momentLocalizer(moment),
        }),
        [updatedDate],
    )

    const defaultDateHeader = (newUpdatedDate) => ({
        day: getDateInClassicFormat(newUpdatedDate),
        month: `${moment(newUpdatedDate).format('MMMM')} ${moment(newUpdatedDate).format('YYYY')}`,
        week: weekHeader(newUpdatedDate).formatted,
    })

    const [dateHeader, setDateHeader] = useState(defaultDateHeader(updatedDate)[selectedView])

    const onViewChange = (view) => {
        setSelectedView(view)
    }

    const onChangeProviderMulti = (options, checked, value=null, key=null) => {
        if (handleAddResourceDisable(options.key)) {
            notification("Default Provider can't be deselected!", 'warning')
            return
        }
        if (providerMulti?.length === 25) {
            notification('Cannot filter more than 25 providers!', 'warning')
            return
        }

        if(checked){
            setProviderMulti((providerMultiState) => ([
                ...providerMultiState,
                options
            ]))
        } else {
            const formatProviderMulti = providerMulti?.filter((providerMultiItem) => providerMultiItem?.key !== options?.key)
            setProviderMulti(formatProviderMulti)
        }
    }

    const handleClearFilter = () => {
        setProviderMulti(defaultProviderMulti)
        notification('Default Provider is always auto-filtered!', 'warning')
    }

    const totalfilterUsed = useMemo(() => {
        return providerMulti?.length > 0 ? 1 : 0
    }, [providerMulti])

    const handleFilterClick = () => {
        if(!isFirstFilterClick){
            setIsFirstFilterClick(true)
            getProvidersData()
        }
    }
 
    async function getScheduledSlotsCount(newUpdatedDate = null) {
        const currentDate = newUpdatedDate ? newUpdatedDate : updatedDate
        try {
            setLoadingSchedule(true)
            const resp_visit_type = await getVisitType({ page: 1, limit: 1000, desc: 'createdAt' })
            const resp_by_provider = await getSchedule({
                status: '',
                visitType: '',
                location: '',
                practitioner_id: idProvider ? idProvider : (isProvider ? currentUserId : ''),
                startTime: currentDate
                    ? moment(currentDate).startOf(selectedView).utc().format()
                    : moment().startOf(selectedView).utc().format(),
                endTime: currentDate
                    ? moment(currentDate).endOf(selectedView).utc().format()
                    : moment().endOf(selectedView).utc().format(),
            })

            if (values.appointment_type === 'Office Visit') {
                const resp_by_location = await getSchedule({
                    status: '',
                    visitType: '',
                    location: values.location ? values.location : '',
                    practitioner_id: '',
                    startTime: currentDate
                        ? moment(currentDate).startOf(selectedView).utc().format()
                        : moment().startOf(selectedView).utc().format(),
                    endTime: currentDate
                        ? moment(currentDate).endOf(selectedView).utc().format()
                        : moment().endOf(selectedView).utc().format(),
                })

                let appointments_off = resp_by_location?.data?.calendar

                //khi response data có office visit thì room_id === location_id
                if (values.room) {
                    appointments_off = appointments_off.filter(
                        (i) => `${i.location_id}` === `${values.room}`,
                    )
                }

                const listAppointmentByRoom = uniqBy(
                    [...resp_by_provider?.data?.calendar, ...appointments_off],
                    function (e) {
                        return e.id
                    },
                )
                const dataRoom = listAppointmentByRoom
                    .filter(item => {
                        if (idProvider) return item?.practitioner_id === idProvider
                        if (isProvider) return item?.practitioner_id === currentUserId
                        return null
                    })
                    .map((appt) => ({
                        ...appt,
                        end: new Date(appt?.end),
                        start: new Date(appt?.start),
                        resourceId: appt?.practitioner_id
                    }))

                setData(addKeyConditionally(dataRoom, resp_visit_type?.data?.visitTypeList?.data))
            } else {
                setData(() => {
                    const data = resp_by_provider?.data?.calendar
                        .filter(item => {
                            if (idProvider) return item?.practitioner_id === idProvider
                            if (isProvider) return item?.practitioner_id === currentUserId
                            return item
                        })
                        .map((appt) => ({
                            ...appt,
                            end: new Date(appt.end),
                            start: new Date(appt.start),
                            resourceId: appt?.practitioner_id
                        }))
                    return addKeyConditionally(data, resp_visit_type?.data?.visitTypeList?.data)
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        getScheduledSlotsCount()
    }, [
        idProvider,
        selectedView
    ])

    const onTodayClick = () => {
        setUpdatedDate(new Date())
        setDateHeader(defaultDateHeader(moment())[selectedView])
        getScheduledSlotsCount(moment())
    }

    const onNavigate = (receivedDate) => {
        setUpdatedDate(receivedDate)
        setDateHeader(defaultDateHeader(moment(receivedDate))[selectedView])
        getScheduledSlotsCount(moment(receivedDate))
    }

    function weekHeader(dateStr) {
        const startOfWeek = moment(dateStr).startOf('week'),
        endOfWeek = moment(dateStr).endOf('week')

        return {
            start: startOfWeek,
            end: endOfWeek,
            formatted: `${getDateInClassicFormat(startOfWeek)} - ${getDateInClassicFormat(
                endOfWeek,
            )}`,
        }
    }

    function updateDate(receivedDate, OpType, view) {
        let returnedDate = ''

        if (view === 'month') {
            if (OpType === 'NEXT') {
                returnedDate = moment(receivedDate).add(1, 'months')
                const newFormattedDate = moment(updatedDate).add(1, 'months')
                setDateHeader(
                    `${newFormattedDate.format('MMMM')} ${newFormattedDate.format('YYYY')}`,
                )
            }

            if (OpType === 'PREV') {
                returnedDate = moment(receivedDate).subtract(1, 'months')
                const newFormattedDate = moment(new Date(updatedDate)).subtract(1, 'months')
                setDateHeader(
                    `${newFormattedDate.format('MMMM')} ${newFormattedDate.format('YYYY')}`,
                )
            }
        } else if (view === 'day') {
            if (OpType === 'NEXT') {
                returnedDate = moment(receivedDate).add(1, 'day')
            } else if (OpType === 'PREV') {
                returnedDate = moment(receivedDate).subtract(1, 'day')
            }

            setDateHeader(getDateInClassicFormat(returnedDate))

        } else if (view === 'week') {
            if (OpType === 'NEXT') {
                returnedDate = moment(receivedDate).add(7, 'day')
                const { start, end } = weekHeader(receivedDate)

                const newStart = moment(start).add(7, 'd'),
                    newEnd = moment(end).add(7, 'd')

                setDateHeader(
                    `${getDateInClassicFormat(newStart)} - ${getDateInClassicFormat(newEnd)}`,
                )
            } else if (OpType === 'PREV') {
                returnedDate = moment(receivedDate).subtract(7, 'day')
                const { start, end } = weekHeader(receivedDate)

                const newStart = moment(new Date(start)).subtract(7, 'd'),
                    newEnd = moment(new Date(end)).subtract(7, 'd')

                setDateHeader(
                    `${getDateInClassicFormat(newStart)} - ${getDateInClassicFormat(newEnd)}`,
                )
            }
        }
        setUpdatedDate(new Date(returnedDate))
        getScheduledSlotsCount(returnedDate)
    }

    async function getProvidersData() {
        try {
            const response = await getProivders({
                page: 1,
                size: 100000,
            })
            const providerData = response?.data?.map((item) => ({
                key: item.id,
                // value: `${item.id} - ${item.first_name} ${item.last_name}`,
                value: `${item.first_name} ${item.last_name}`,
                speciality: (item?.specialities?.length > 0) ? `${item?.specialities[0]?.id}` : ''
            }))
            setProviderOptions(providerData || [])
        } catch (error) {
            console.log(error)
        }
    }

    const filterCancelledEvents = (eventData) => {
        if(!showCancelledAppointment){
            return eventData?.filter(
                (item) => item.status !== 'cancelled'
            )
        }
        return eventData
    }

    const handleAddResourceDisable = useCallback((resourceKey) => {
        if(idProvider) return (resourceKey === idProvider)
        if(isProvider) return (resourceKey === currentUserId)
        return false
        
        // return (resourceKey === idProvider || resourceKey === currentUserId)
    }, [providerMulti])

    const generateEvents = (eventData) => {
        if(providerMulti?.length === 0) return filterCancelledEvents(eventData)

        const ids = providerMulti?.map((providerMultiItem) => providerMultiItem?.key)
        const filteredData = []
        eventData?.forEach(eventDataItem => {
            if(ids.includes(eventDataItem?.practitioner_id)) filteredData.push(eventDataItem)
        })

        return filterCancelledEvents(filteredData)
    }

    const resourceView = useMemo(() => {
        if(providerMulti?.length === 0) return {}
        return {
            resourceIdAccessor: 'key',
            resources: providerMulti,
            resourceTitleAccessor: (e) => {
                return (
                    <div className={classes.customResourceTitle}>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}>
                            <Typography
                                style={{ padding: '5px 0', width: '100%', cursor: 'default' }}>
                                {e.value}
                            </Typography>
                        </span>
                        <IconButton
                            onClick={() =>
                                onChangeProviderMulti(
                                    { value: e?.value, key: e?.key },
                                    false,
                                )
                            }
                            disabled={handleAddResourceDisable(e?.key)}>
                            <HighlightOffOutlinedIcon
                                style={{
                                    fontSize: '18px',
                                    color:
                                        handleAddResourceDisable(e?.key)
                                            ? '#aaa'
                                            : '#888',
                                }}
                            />
                        </IconButton>
                    </div>
                )
            },
        }
    }, [providerMulti])

    const calendarProps = {
        slotPropGetter: (date) => {
            const className = moment(date).hour() < 8 || moment(date).hour() > 16 ? 'customSlot' : ''
            return { className }
        },
        messages: {
            showMore: (total) => `+${total} view more`,
        },
        events: generateEvents(data),
        views: {
            month: true,
            week: true,
            day: true,
        },
        formats: {
            eventTimeRangeFormat: () => null,
            eventTimeRangeStartFormat: () => null,
            eventTimeRangeEndFormat: () => null,
            dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
            timeGutterFormat: (date, culture, localizer) =>
                localizer.format(date, 'hh:mm a', culture),
            dayHeaderFormat: (date, culture, localizer) =>
                localizer.format(date, 'MMMM D', culture),
            monthHeaderFormat: (date, culture, localizer) =>
                localizer.format(date, 'MMMM', culture),
            weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
        },
        eventPropGetter: (props) => eventStyleGetter(props, selectedView),
        scrollToTime: (() => {
            const current = moment()
            const minute = current.get('minute')
            if (minute >= 30) {
                return current.set('minute', 30)
            }
            return current.set('minute', 0)
        }),
        selectable: true,
        onSelectSlot: useCallback((slotInfo) => {
            window.clearTimeout(clickRef?.current)
            clickRef.current = window.setTimeout(() => {
                onClose?.()
                setFieldValue('start_time', moment(slotInfo?.start).format('HH:mm'))
                setFieldValue('date', moment(slotInfo?.start).format('YYYY-MM-DD'))
            }, 250)
        }, []),
        step: 15,
        timeslots: 1,
        view: selectedView,
        onView: onViewChange,
        date: updatedDate,
        // onNavigate,
        localizer,
        // getNow,
        showAllEvents: true,
        showMultiDayTimes: true,
        popup: true,
        ...resourceView,
        components: {
            [VIEW.MONTH]: {
                header: MonthHeaderCellContent,
                event: (eventProps) => <MonthCalendarEvent  {...eventProps} isSchedulerForm={true} />,
            },
            [VIEW.WEEK]: {
                header: WeekHeaderCellContent,
                event: (eventProps) => <WeekCalendarEvent  {...eventProps} isSchedulerForm={true} />,
            },
            [VIEW.DAY]: {
                header: WeekHeaderCellContent,
                event: (eventProps) => <DayCalendarEvent  {...eventProps} isSchedulerForm={true} />,
            }
        },
        
    }

    const hideOnClickOutside = (e) => {
        if (
            refOne.current &&
            !refOne.current.contains(e.target) &&
            calIcon.current &&
            !calIcon.current.contains(e.target)
        ) {
            setShowMiniCalendar(false)
        }
    }

    const hideOnEscape = (e) => {
        if (e.key === 'Escape') {
            setShowMiniCalendar(false)
        }
    }

    useEffect(() => {
        document.addEventListener('click', hideOnClickOutside, true)
        document.addEventListener('keydown', hideOnEscape, true)
    }, [])

    useEffect(() => {
        setDateHeader(defaultDateHeader(updatedDate)[selectedView])
    }, [selectedView])

    return (
        <ModalEl
            width={'1200'}
            title={''}
            description="Booking Appointment Details"
            open={open || false}
            backdropClick
            onClose={() => onClose?.()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            {loadingSchedule && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 3,
                    }}>
                    <CircularProgress />
                </Box>
            )}
            <Box className={classes['wrapDay']}>
                <Box className={'close-viewday'} onClick={() => onClose?.()}>
                    {/* <HighlightOffIcon /> */}
                    <Close style={{ height: 15, width: 15 }} />
                </Box>
                <div className='custom-top-panel'>
                    <div className='upper-top-panel'>
                        <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <HtmlTooltip
                                placement="top-start"
                                title={`Go to previous ${selectedView}`}
                                arrow>
                                <PreviousDateArrow
                                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                    onClick={() => {
                                        updateDate(updatedDate,'PREV', selectedView)
                                    }}
                                />
                            </HtmlTooltip>

                            <Typography className='date-header'>{dateHeader}</Typography>

                            <HtmlTooltip
                                placement="top-start"
                                title={`Go to next ${selectedView}`}
                                className={classes.calendar_arrow_tooltip}
                                arrow>
                                <NextDateArrow
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        updateDate(updatedDate,'NEXT', selectedView)
                                    }}
                                />
                            </HtmlTooltip>

                            {selectedView === VIEW.DAY && (
                                <div
                                    onClick={onTodayClick}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        border: '1px solid #989CA8',
                                        backgroundColor: '#fff',
                                        padding: '1px 10px',
                                        cursor: 'pointer',
                                        gap: 2,
                                    }}>
                                    <TodayIcon style={{ marginTop: 2 }} />
                                    <Typography
                                        style={{ color: '#303E4E', fontSize: 13, fontWeight: 400 }}>
                                        Today
                                    </Typography>
                                </div>
                            )}

                            {/* <TopPanelCalendarIcon
                                // ref={calIcon}
                                className="top-panel-calender-icon"
                                // onClick={visibleCalender}
                            /> */}
                        </Box>

                        <Box style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.09)', borderRadius: '8px' }}>
                                <Tabs
                                    value={selectedView}
                                    onChange={(_,newValue) => onViewChange(newValue)}
                                    TabIndicatorProps={{ style: { display: 'none' } }}
                                    centered>
                                    <CustomTab value="day" label="Day" size="small" />
                                    <CustomTab value="week" label="Week" size="small" />
                                    <CustomTab value="month" label="Month" size="small" />
                                </Tabs>
                            </div>

                            <MultiFilter
                                filterOptionsArrayObject={filterOptionsArrayObject}
                                handleChange={onChangeProviderMulti}
                                handleClearSingleFilter={handleClearFilter}
                                handleClearFilter={handleClearFilter}
                                totalNoOfFilterUsed={totalfilterUsed}
                                handleFilterClick={handleFilterClick}
                            />
                        </Box>
                    </div>

                    <Divider style={{ width: '100%' }} className="inner_divider" />

                    <div className='lower-top-panel'>
                        <Grid item style={{ flex: 1 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                                <div>
                                    <HtmlTooltip title={`Refresh`}>
                                        <IconButton
                                            disabled={!isEmpty(getDraftAppointments())}
                                            onClick={() => getScheduledSlotsCount()}
                                        >
                                            <ReloadIcon
                                                style={{ cursor: 'pointer', width: 17, height: 17 }}
                                            />
                                        </IconButton>
                                    </HtmlTooltip>
                                </div>

                                <TopPanelCalendarIcon
                                    ref={calIcon}
                                    className="top-panel-calender-icon"
                                    onClick={() => setShowMiniCalendar(!showMiniCalendar)}
                                />

                                {showMiniCalendar && 
                                    <Box
                                        ref={refOne}
                                        style={{
                                            position: 'absolute',
                                            zIndex: 11,
                                            top: '70px',
                                            border: '1px #f4f4f4 solid',
                                            left: '100px',
                                            borderRadius: '5px',
                                        }}
                                        sx={{
                                            '& .MuiPickersCalendarHeader-transitionContainer': {
                                                ' & p': {
                                                    color: '#303E4E',
                                                    fontWeight: 500,
                                                    fontSize: '16px',
                                                },
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: '#303E4E',
                                                width: '30px',
                                                height: '30px',
                                            },
                                            '& .MuiPickersCalendarHeader-dayLabel': {
                                                color: '#9CA9B8',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                width: '41px',
                                            },
                                            '& .MuiPickersCalendarHeader-daysHeader': {
                                                marginTop: '16px',
                                                marginBottom: '8px',
                                            },
                                            '& .MuiPickersDay-daySelected': {
                                                background: '#5571C6',
                                                borderRadius: '8px',
                                                color: 'white !important',
                                            },
                                            '& .MuiPickersBasePicker-pickerView': {
                                                maxWidth: '310px',
                                                width: '310px',
                                            },
                                            '& .MuiPickersDay-day': {
                                                width: '41px',
                                                height: '32px',
                                            },
                                            '& .MuiIconButton-label': {
                                                '& p': {
                                                    fontSize: '14px',
                                                },
                                            },
                                        }}>
                                        <ReactMiniCalendar
                                            date={updatedDate} 
                                            onChange={onNavigate}
                                            className="calendarElement"
                                        />
                                    </Box>
                                }

                            </Box>
                        </Grid>

                        <div style={{ marginLeft: '25px' }}>
                            <HtmlTooltip
                                title={`${
                                    props?.toggleCancelledAppointment ? 'Hide' : 'Show'
                                } cancelled appointments`}>
                                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                                    <ToggleSwitch
                                        onChange={(_, newVal) => setShowCancelledAppointment(newVal)}
                                        checked={showCancelledAppointment}
                                    />
                                    <Typography style={{ color: '#989CA8', fontSize: 12, fontWeight: 400 }}>
                                        Show cancelled
                                    </Typography>
                                </div>
                            </HtmlTooltip>
                        </div>
                    </div>
                </div>
                <div className={`calendar-container ${(selectedView === VIEW.DAY && providerMulti?.length === 0) ? 'non-day-view-header-space' : ''}`}>
                    <Calendar {...calendarProps} />
                </div>
            </Box>
            <AlertEmtyForm
                title="Can't make an appointment in the past!"
                description={"Can't make an appointment in the past!"}
                loading={false}
                open={alertEmtyForm}
                onClose={() => setAlerEmtyForm(false)}
            />
        </ModalEl>
    )
}

export default AllViewMiniCalendar
