import MomentUtils from '@date-io/moment'
import { ThemeProvider } from '@material-ui/core'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import moment from 'moment'
import React, { useState } from 'react'
import { materialTheme as theme } from '../../../utilities/calendar/style'
import DateTimePickerIcon from '../../../assests/icons/datetimepicker.svg'

function DateTimePicker(props) {
    const refusePattern = () => {
        return /[^a-zA-Z0-9]+/gi;
    };
    const [datevalue, setDateValue] = useState(null)

    const handleDateChange = (date) => {
        setDateValue(date)
    }
    return (
        <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}>
            <ThemeProvider theme={theme}>
                <KeyboardDatePicker
                    id={props.name}
                    aria-label={props.name}
                    name={props.name}
                    variant="inline"
                    placeholder={'MM/DD/YYYY'}
                    disableToolbar
                    inputVariant="outlined"
                    {...props}
                    autoOk
                    refuse={refusePattern()}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}
DateTimePicker.defaultProps = {
    keyboardIcon: DateTimePickerIcon,
}
export default DateTimePicker
