import React from 'react'

export default function IconChat() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.7226 6.40965V11.368C21.7226 12.6027 21.3143 13.643 20.5851 14.3624C19.8657 15.0916 18.8254 15.4999 17.5907 15.4999V17.2596C17.5907 17.9207 16.8518 18.3194 16.3073 17.9499L15.3643 17.3277C15.4518 17.0263 15.4907 16.6957 15.4907 16.3457V12.3888C15.4907 10.4055 14.1684 9.08327 12.1851 9.08327H5.58371C5.4476 9.08327 5.32121 9.09301 5.19482 9.10273V6.40965C5.19482 3.93049 6.8476 2.27771 9.32677 2.27771H17.5907C20.0698 2.27771 21.7226 3.93049 21.7226 6.40965Z"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4903 12.3888V16.3457C15.4903 16.6957 15.4514 17.0263 15.3639 17.3277C15.0042 18.7568 13.8181 19.6513 12.1848 19.6513H9.54033L6.60422 21.6055C6.16672 21.9069 5.58338 21.586 5.58338 21.061V19.6513C4.59171 19.6513 3.76533 19.3208 3.19172 18.7471C2.60839 18.1638 2.27783 17.3374 2.27783 16.3457V12.3888C2.27783 10.5416 3.42505 9.26799 5.1945 9.10272C5.32089 9.09299 5.44727 9.08325 5.58338 9.08325H12.1848C14.1681 9.08325 15.4903 10.4055 15.4903 12.3888Z"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
