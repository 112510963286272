import {
    Grid,
    IconButton,
    Typography,
    Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Routes from '../../../constants/routes'
import { UserRoles } from '../../../constants/user-roles'
import { getConsultationTracking } from '../../../services/Consultation/tracking'
import {
    GridHeaderEl,
    NoRowsOverlayEl,
} from '../../../shared/elements'
import LoadingPage, {
    LoaderType,
} from '../../../utilities/loading-page'
import {
    CONSULTATION_FAILURE,
    CONSULTATION_FILTER_SORT,
    CONSULTATION_LOADING,
    CONSULTATION_SUCCESS,
    PAGE_CHANGE,
    SEARCH_CONSULTATION,
} from '../constant'
import { initialState, reducer } from '../reducer'
import ScreenHeader from '../ScreenHeader'

const useStyles = makeStyles({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#889db6 !important',
    },
})

function ConsultationTrackingList() {
    const history = useHistory()
    const classes = useStyles()

    const columns = [
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Date"
                    field="appointment_date"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Date
                </Typography>
            ),
            field: 'appointment_date',
            flex: 1,
            sortable: false,
            renderCell: (cell) => {
                return moment
                    .utc(cell.value)
                    .local()
                    .format('MM/DD/YYYY')
            },
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Time"
                    field="time"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Time
                </Typography>
            ),
            field: 'time',
            flex: 1,
            sortable: false,
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Specialty"
                    field="speciality_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Specialty
                </Typography>
            ),
            field: 'speciality_name',
            flex: 1,
            sortable: false,
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Provider"
                    field="provider_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Provider
                </Typography>
            ),
            field: 'provider_name',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link
                        noWrap
                        component="button"
                        color="primary"
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(
                                params.row.provider.user_id,
                                UserRoles.PROVIDER,
                            )
                        }>
                        <strong>{params.value}</strong>
                    </Link>
                )
            },
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Patient"
                    field="patient_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Patient
                </Typography>
            ),
            field: 'patient_name',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Link
                        noWrap
                        component="button"
                        color="primary"
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(
                                params.row.patient.user_id,
                                UserRoles.PATIENT,
                            )
                        }>
                        {params.value}
                    </Link>
                )
            },
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Participants"
                    field="participants"
                    onSort={onSort}
                    {...values}
                />
            ),
            headerName: (
                <Typography variant="body2">
                    Participants
                </Typography>
            ),
            field: 'participants',
            flex: 1,
            sortable: false,
        },
    ]

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    React.useEffect(() => {
        fetchConsultationTracking()
    }, [])

    function onSort(value) {
        dispatch({ type: CONSULTATION_FILTER_SORT, value })
    }

    function onRowClick(userId, role) {
        const editDecider =
            role === UserRoles.PROVIDER ? 'Provider' : ''
        history.push(Routes.PROFILE, {
            userId,
            role,
            editDecider,
        })
    }

    function handleConsultationRefresh() {
        fetchConsultationTracking()
    }

    async function fetchConsultationTracking() {
        try {
            dispatch({ type: CONSULTATION_LOADING })
            const consultations =
                await getConsultationTracking()

            dispatch({
                type: CONSULTATION_SUCCESS,
                data: consultations.data,
            })
        } catch (error) {
            if (error?.status === 404) {
                return dispatch({
                    type: CONSULTATION_SUCCESS,
                    data: [],
                })
            }

            dispatch({
                type: CONSULTATION_FAILURE,
            })
        }
    }

    function onSearch(value) {
        dispatch({ type: SEARCH_CONSULTATION, value })
    }

    function onPageChange(_, page) {
        dispatch({ type: PAGE_CHANGE, page: page + 1 })
    }

    function onGoBack() {
        history.goBack()
    }

    function onFilterReset() {
        dispatch({ type: SEARCH_CONSULTATION, value: '' })
    }

    return (
        <div className="MuiDataGrid-table-wrapper">
            <Grid
                container
                xs={12}
                direction="column"
                spacing="2">
                <Grid container item xs={12}>
                    <ScreenHeader
                        title="Consultation Tracking"
                        onGoBack={onGoBack}
                        onSearch={onSearch}
                        onReset={onFilterReset}
                        classes={classes}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    className="bg-white">
                    {state.consultations.isLoading && (
                        <LoadingPage
                            type={LoaderType.LINEAR}
                        />
                    )}
                    <DataGrid
                        getRowId={(row) =>
                            row.appointment_id
                        }
                        rows={state.consultations.data}
                        columns={columns}
                        pagination
                        page={state.pagination.page - 1}
                        pageSize={state.pagination.limit}
                        rowsPerPageOptions={[
                            state.pagination.limit,
                        ]}
                        rowCount={
                            state.consultations.data.length
                        }
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector
                        disableSelectionOnClick
                        autoHeight
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl
                                    title={
                                        !state.consultations
                                            .isFailure
                                            ? 'No consultation is in-progress'
                                            : 'Failed to retrieve consultations'
                                    }
                                />
                            ),
                        }}
                        loading={
                            state.consultations.isLoading
                        }
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default ConsultationTrackingList
