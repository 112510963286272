import {
    deleteService,
    getSearchService,
    getService,
    patchService,
    postService,
    putService,
} from 'utilities/request/restClient'

const baseUrl = process.env.REACT_APP_SONORA_V2_API_URL

export const searchAvailability = (providerId, payload) => {
    const queryString = new URLSearchParams(payload).toString()
    // return getService(`${baseUrl}/provider/${providerId}/availability?${queryString}`)
    return getService(`${baseUrl}/provider/${providerId}/provider-availability?${queryString}`)
}

export const providerRunninglate = (id) => {
    return postService(`${baseUrl}/appointment/${id}/provider-late`)
}

export const getAvailability = (providerId, id) => {
    // return getService(`${baseUrl}/provider/${providerId}/availability/${id}`)
    return getService(`${baseUrl}/provider/${providerId}/provider-availability/${id}`)
}

export const postAvailability = (providerId, payload) => {
    // return postService(`${baseUrl}/provider/${providerId}/availability`, payload)
    return postService(`${baseUrl}/provider/${providerId}/provider-availability`, payload)
}

export const putAvailability = (providerId, id, payload) => {
    // return putService(`${baseUrl}/provider/${providerId}/availability/${id}`, payload)
    return putService(`${baseUrl}/provider/${providerId}/provider-availability/${id}`, payload)
}
export const postOccuranceAvailability = (providerId, id, payload) => {
    // return putService(`${baseUrl}/provider/${providerId}/availability/${id}`, payload)
    return postService(`${baseUrl}/provider/${providerId}/exceptional-availability/${id}`, payload)
}
export const deleteAvailability = (providerId, id) => {
    // return deleteService(`${baseUrl}/provider/${providerId}/availability/${id}`)
    return deleteService(`${baseUrl}/provider/${providerId}/provider-availability/${id}`)
}

export function searchProvider(payload) {
    const queryString = new URLSearchParams(payload).toString()
    return getService(
        `${baseUrl}/practitioner?role=bh_provider,mid_bh_provider,medical_provider,mid_medical_provider,therapist&isAllProvider=true&${queryString}`,
    )
}
export function updateProviderAvailabilityCheck(payload) {
    return postService(`${baseUrl}/practitioner/update-provider-availability-check`, payload)
}
export function checkProviderAvailability(providerId, payload) {
    return postService(`${baseUrl}/provider/${providerId}/check-provider-availability`, payload)
}
export function getProviderAvailabilityCheck() {
    return getService(`${baseUrl}/practitioner/get-provider-availability-check`)
}
