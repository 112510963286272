/* eslint-disable default-case */
import {
  getService,
  postService,
} from '../../utilities/request/restClient';
import { getLoggedInUserRole } from '../../utilities';
import { UserRoles } from '../../constants/user-roles';

export const getClinicList = (payload) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
    case UserRoles.CLINIC_CORDINATION:
      userType = 'clinic-admin';
      break;
  }
  return postService(`/${userType}/clinic/search`, {
    limit: 10,
    ...payload,
  });
};

export const getClinicByClinicId = (clinicId) => {
  return getService(`/clinic/${clinicId}`);
};

export const addClinicDetails = (payload) => {
  let userType = '';
  const userRole = getLoggedInUserRole();

  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
  }

  return postService(`/${userType}/clinic/add`,
    payload
  );
};

export const editClinicDetails = (payload) => {
  let userType = '';
  const userRole = getLoggedInUserRole();

  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
  }

  return postService(`/${userType}/clinic/edit`, 
    payload
  );
};

