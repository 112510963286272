import { useEffect, useState } from 'react'

import { getLocationsByPratitioner, getVisitType } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'

interface Location {
  id: string
  status: string
  name: string
  description: string
  longitude: number
  latitude: number
  organization_id: string
  parent_id: string
  billing_npi: string
  facility_taxonomy: string
  clia_number: string
  tax_id: string
  city: string
  state: string
  district: string
  postal: string
  country: string
  address1: string
  phone_number: string
}

interface VisitType {
  id: number
  post_code: string
  long_code: string
  note_template_id: string
  comment: string
  cpt_arr: {
    code: string
    description: string
  }[]
  duration: number
  type: string
  clinical_note_type: string
}

export const useAppointmentBookingFormData = (practitionerId: string | null) => {
  const [dataLoading, setDataLoading] = useState(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [visitTypes, setVisitTypes] = useState<VisitType[]>([])

  const notification = useNotification()

  useEffect(() => {
    if (!practitionerId) return
    const abortController = new AbortController()

    setDataLoading(true)
    Promise.allSettled([
      getLocationsData(practitionerId),
      getVisitTypeData()
    ])
      .then(() => setDataLoading(false))

    return () => abortController.abort()
  }, [practitionerId])

  const getLocationsData = async (practitionerId: string | null) => {
    try {
      const response = await getLocationsByPratitioner(practitionerId)
      setLocations(response.data?.data)
    } catch (error) {
      notification(`Cannot get list location for practitionerId ${practitionerId}`)
    }
  }

  const getVisitTypeData = async () => {
    try {
      const response = await getVisitType({
        page: 1,
        limit: 100000,
      })
      setVisitTypes(response.data.visitTypeList.data)
    } catch (error) {
      notification(`Cannot get list visit type for practitionerId ${practitionerId}`)
    }
  }

  return {
    locations,
    visitTypes,
    dataLoading
  }
}
