import React from 'react';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import EmptyListIcon from '../../../components/Consultation/icons/EmptyListIcon';

const useStyles = makeStyles({
  emptyListWrapper: {
    width: '100%',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    marginTop: 200,
  },
  wrapText: {
    marginTop: '20px',
    textAlign: 'center',
  },
  title: {
    marginTop: '10px',
    fontSize: '22px',
    color: '#303E4E'
  },
  text: {
    marginTop: '10px',
    fontSize: '14px',
  }
});

const EmptyList = () => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" justifyContent="center" className={classes.emptyListWrapper}>
      <div>
        <EmptyListIcon />
      </div>
      <div className={classes.wrapText}>
        <Typography className={classes.title}>Empty List</Typography>
      </div>
    </Grid>
  )
}

export default EmptyList;