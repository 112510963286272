import { Grid, makeStyles, Typography } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import ErxDialog from 'containers/PatientsSetting/view/MedicationPatient/ErxDialog'
import { getListMedications , sentErxUpatedTime} from 'services/Patient/patient'
import MedicationsCreateDialog from '../MedicationsCreateDialog'
import { Card } from '../view/CardHealthRecord'
import { NoData } from './NoData'
import Confirm from '../../../components/shared/Elements/Confirm'
import { useSelector } from 'react-redux'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    patientId?: string
    encounter_id?: string
}

const useStyles = makeStyles(() => ({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    viewall: {
        color: '#667689',
        '&:hover': {
            color: '#4e52d9',
            textDecoration: 'underline',
        },
    },
}))

export const Medications = (props: Props) => {
    const history = useHistory()
    const classes = useStyles()
    const [openERX, setOpenERX] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const userData = useSelector((state: any) => state.permission.erx)
    const is_enable_eprescribe = useSelector((state: any) => state?.login?.userData?.practitioner?.is_enable_eprescribe)
    const [patientId, setPatientId] = useState('')
    const [editTime , setEditTime] = useState('')
    const [isLoadingButton, setIsLoadingButton] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const { id } = useParams<{ id: string }>()

    const [medications, setMedications] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            fetchMedications()
        }

        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    const fetchMedications = async () => {
        try {
            setLoading(true)
            const resp = await getListMedications(patientId, 1, 10)
            setMedications(resp.data.data)
        } catch (ex) {
            console.error('[Medication] cannot fetch medial problem list', ex)
        }

        setLoading(false)
    }
    const lastUpatedTime = () => {
        let startDate = moment().utc()
        if(moment().tz('America/New_York').isDST()){
            setEditTime(startDate.subtract(240, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        }else{
            setEditTime(startDate.subtract(300, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        }
    } 
    const checkAccess = () => {
        if (!userData.includes("access_erx") || !is_enable_eprescribe) {
            setOpenConfirm(true)
        } else if (userData.includes("access_erx") && is_enable_eprescribe) {
            setOpenERX(true)
            lastUpatedTime()
        }
    }
    const sendErxUpdateTime = async () => {
        try {
            setIsLoadingButton(true)
            const res = await sentErxUpatedTime(patientId, editTime)
            if (res) {
                fetchMedications(), setIsLoadingButton(false), setOpenERX(false)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleViewAll = () => history.push(Routes.PATIENT.MEDICATIONS.replace(':id', patientId))

    const Content = () => (
        <>
            <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{
                    gap: 8,
                    height: '100%',
                    overflow: 'auto',
                    padding: 15,
                    flexWrap: 'nowrap',
                }}>
                {medications.map((item: any) => {
                    const brandName = item?.drug?.brandName
                    const genericName = item?.drug?.genericName
                    const strength = item?.drug?.strength
                    const form = item?.drug?.form
                    const showText = `${brandName} ${genericName && genericName}, ${strength && strength
                        }, ${form && form}`
                    return (
                        <Grid
                            container
                            key={item?.id}
                            justifyContent="space-between"
                            wrap="nowrap"
                            alignItems="center"
                            style={{
                                borderBottom: '1px solid #E4E8EE',
                                paddingBottom: 8,
                            }}>
                            <Grid
                                item
                                container
                                alignItems="flex-start"
                                direction="column"
                                style={{
                                    width: 'fit-content',
                                }}>
                                <Typography>
                                    {/* <span
                        className={
                            classes.title_clinical_reminder
                        }>
                        Assessment:
                    </span>{' '} */}
                                    {showText}
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#9CA9B8',
                                        fontSize: 12,
                                    }}>
                                    {item.last_written_date &&
                                        moment(item.last_written_date).format('MMMM D, YYYY')}
                                    {/* {moment(item.last_writent_date).format('MMMM D, YYYY')} */}
                                </Typography>
                            </Grid>
                            {/* <Typography
                    className={
                        classes.tag_type_active
                    }>
                    Active
                </Typography> */}
                        </Grid>
                    )
                })}
            </Grid>

            {!!medications.length && (
                <Grid container item justifyContent="flex-end" style={{ padding: 15 }}>
                    <span className={classes.viewall} onClick={props?.viewAllFunc || handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            // menu={[
            //     {
            //         name: 'eRx',
            //         handler: () => checkAccess(),
            //     },
            //     {
            //         name: 'Add',
            //         handler: () => {
            //             setIsAddingNew(true)
            //         },
            //     },
            //     {
            //         name: 'View all',
            //         handler: props?.viewAllFunc || handleViewAll,
            //     },
            // ]}
            viewAllHandler={props?.viewAllFunc || handleViewAll}
            addHandler={() => setIsAddingNew(true)}
            onClick={props.onClick}
            header="Medications">
            {!!loading && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress color="inherit" thickness={1.5} />
                </Grid>
            )}

            {medications.length ? <Content /> : <NoData />}

            {(userData.includes("access_erx") && is_enable_eprescribe) ?
                <ErxDialog
                    open={openERX}
                    patientId={patientId}
                    handleClose={() => {sendErxUpdateTime()}}
                    isLoadingButton= {isLoadingButton}
                />
                :
                <Confirm
                    open={openConfirm}
                    btnText="Confirm"
                    message={'Sorry! You do not have permission to access this feature.'}
                    onCancel={() => setOpenConfirm(false)}
                    headerSize={15}
                />
            }

            {!!isAddingNew && (
                <MedicationsCreateDialog
                    open={isAddingNew}
                    itemId=""
                    patientId={patientId}
                    refetchData={fetchMedications}
                    encounter_id={props.encounter_id}
                    onModalClose={() => {
                        setIsAddingNew(false)
                    }}
                />
            )}
        </Card>
    )
}
