import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { PsychiatricMedication } from 'containers/PatientsSetting/interfaces'
import { ModalEl as Modal } from 'shared/elements'
import './style.scss'

import moment from 'moment'
import { apiPsychiatricMedicalHistory } from 'services/Patient/apiPsychiatricMedicalHistory'
import { getPatient } from 'services/Patient/patient'
import DatePicker from 'shared/elements/DatePicker/DatePicker'
import { Footer } from 'shared/elements/FormItem/Footer'
import { SearchDrug } from 'shared/elements/FormItem/SearchComponents/SearchDrug'
import { Select } from 'shared/elements/FormItem/Select'
import TextBox from 'shared/elements/FormItem/TextBox'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'

type Props = {
    psychiatricMedicalId?: string | false
    patientId?: string | boolean
    encounter_id?: string | boolean
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const validationSchema = yup.object({
    stop_date: yup
        .date()
        .nullable()
        .typeError('Invalid date')
        .min(yup.ref('patient_birth'), "End date cannot be smaller than the patient's age!")
        .max(moment(), 'End Date can not be in future'),
})

const TherapeuticEffects = [
    // @TODO:
    { key: 'Feeling worse or negative result ', value: 'Feeling worse or negative result ' },
    {
        key: 'Minor improvement or no effect (0% - 24%) ',
        value: 'Minor improvement or no effect (0% - 24%) ',
    },
    { key: 'Partial response (25% - 49%) ', value: 'Partial response (25% - 49%) ' },
    { key: 'Significant improvement (50% - 74%) ', value: 'Significant improvement (50% - 74%) ' },
    {
        key: 'Resolved or nearly resolved problem (75% - 100%) ',
        value: 'Resolved or nearly resolved problem (75% - 100%) ',
    },
]

const initialValues = {
    drug: {
        code: '',
        display: '',
    },
    strength: '',
    how_long: '',
    stop_date: '',
    therapeutic_effect: '',
    side_effects: '',
    reason_for_stopping: '',
    patient_birth: '',
    encounter_id: '', //backend dang nham require
}
const PsychiatricMedicationForm = (props: Props) => {
    //Hooks
    const notification = useNotification()

    // State
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const formik = useFormik<PsychiatricMedication>({
        validationSchema,
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    async function handleSubmit(values) {
        console.log('ádfasdf   sfasdf', props.patientId, props.psychiatricMedicalId)
        const payload = { ...values, encounter_id: props.encounter_id }
        delete payload['patient_birth']
        if (props.patientId && !props.psychiatricMedicalId) {
            setIsSubmitting(true)
            try {
                const res = await apiPsychiatricMedicalHistory.createPsychiatricMedication(props.patientId, payload)
                if (res.data) {
                    notification('Psychiatric Medical History careated successfully !', 'success')
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong!', 'error')
            } finally {
                setIsSubmitting(false)
            }
        } else if (props.patientId && props.psychiatricMedicalId) {
            setIsSubmitting(true)
            try {
                const res = await apiPsychiatricMedicalHistory.updatePsychiatricMedication(
                    props.patientId,
                    props.psychiatricMedicalId,
                    payload,
                )
                notification('Psychiatric Medication created successfully', 'success')
                props.onOke?.()
            } catch (error) {
                console.log(error)
                notification('Something went wrong!', 'error')
            } finally {
                setIsSubmitting(false)
            }
        }
    }

    async function getPsychiatricMedicationById(patientId, psychiatricMedicalId) {
        try {
            setLoading(true)
            const res = await apiPsychiatricMedicalHistory.getId(patientId, psychiatricMedicalId)
            const respPatient = await getPatient(patientId)
            let patient_birth
            if (respPatient.data) {
                patient_birth = moment(respPatient.data.dob, 'YYYY-MM-DD')
            }
            if (res.data) {
                const {
                    drug,
                    strength,
                    how_long,
                    stop_date,
                    therapeutic_effect,
                    side_effects,
                    reason_for_stopping,
                } = res.data

                const payload = {
                    drug,
                    strength,
                    how_long,
                    stop_date,
                    therapeutic_effect,
                    side_effects,
                    reason_for_stopping,
                    patient_birth,
                    // encounter_id: res.data.encounter?.id,
                }

                formik.setValues({ ...payload })
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.psychiatricMedicalId && props.patientId) {
            getPsychiatricMedicationById(props.patientId, props.psychiatricMedicalId)
        }
    }, [props.patientId, props.psychiatricMedicalId])

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                formik.setFieldValue('patient_birth', moment(resp.data.dob, 'YYYY-MM-DD'))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && !props.psychiatricMedicalId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    const modalTitle = props.psychiatricMedicalId
        ? 'Update Psychiatric Medical History'
        : 'Add Psychiatric Medical History'
    console.log('ádfasdf', formik.errors)
    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="psychiatric-medical-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12}>
                        <SearchDrug
                            label="Medication"
                            name="medication"
                            value={formik.values.drug as { code: string; display: string }}
                            placeholder="Search Drug"
                            error={formik.touched.drug?.code && Boolean(formik.errors.drug?.code)}
                            helperText={formik.touched.drug?.code && formik.errors.drug?.code}
                            onChange={async (value, form, strength) => {
                                await formik.setFieldValue('drug', value)
                                await formik.setFieldValue('strength', strength)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Strength"
                            placeholder="Strength"
                            name="strength"
                            disabled
                            value={formik.values.strength}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="How Long"
                            placeholder="How Long"
                            name="how_long"
                            value={formik.values.how_long}
                            error={formik.touched.how_long && Boolean(formik.errors.how_long)}
                            helperText={formik.touched.how_long && formik.errors.how_long}
                            onChange={(value) => formik.setFieldValue('how_long', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DatePicker
                            label="End Date"
                            placeholder="End Date"
                            name="stop_date"
                            maxDate={moment()}
                            notDefaultValue
                            value={formik.values.stop_date}
                            error={formik.touched.stop_date && Boolean(formik.errors.stop_date)}
                            helperText={formik.touched.stop_date && formik.errors.stop_date}
                            onChange={(value) => {
                                formik.setFieldValue('stop_date', value)
                            }}
                            minDate={formik.values.patient_birth}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            label="Therapeutic Effect"
                            placeholder="Therapeutic Effect"
                            name="therapeutic_effect"
                            value={formik.values.therapeutic_effect}
                            options={TherapeuticEffects} //@TODO:
                            error={
                                formik.touched.therapeutic_effect &&
                                Boolean(formik.errors.therapeutic_effect)
                            }
                            helperText={
                                formik.touched.therapeutic_effect &&
                                formik.errors.therapeutic_effect
                            }
                            onChange={(value) => formik.setFieldValue('therapeutic_effect', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Side Effects"
                            placeholder="Side Effects"
                            name="side_effects"
                            value={formik.values.side_effects}
                            error={
                                formik.touched.side_effects && Boolean(formik.errors.side_effects)
                            }
                            helperText={formik.touched.side_effects && formik.errors.side_effects}
                            onChange={(value) => formik.setFieldValue('side_effects', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Reason for stopping"
                            placeholder="Reason for stopping"
                            name="reason_for_stopping"
                            value={formik.values.reason_for_stopping}
                            error={
                                formik.touched.reason_for_stopping &&
                                Boolean(formik.errors.reason_for_stopping)
                            }
                            helperText={
                                formik.touched.reason_for_stopping &&
                                formik.errors.reason_for_stopping
                            }
                            onChange={(value) => formik.setFieldValue('reason_for_stopping', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={isSubmitting}
                        onCancel={() => {
                            formik.resetForm()
                            props.onClose?.()
                        }}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.psychiatricMedicalId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export { PsychiatricMedicationForm }
export default PsychiatricMedicationForm
