import { Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { convertHexToRGBA } from 'shared/utils'
import './styles.scss'

interface Props {
    color: string
    code: string
    options: {
        color: string
        code: string
        value: string
    }[]
    onChange: (value: string) => void
}
const useStyles = makeStyles(() => ({
    muiItem: {
        color: '#516983',
        padding: '2px 5px',
    },
}))
export const StatusEdit = ({ color, code, options, onChange }: Props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    return (
        <>
            <Box className="wrap-status-tb">
                <Box
                    aria-controls="simple-menu"
                    onClick={handleClick}
                    sx={{
                        background: convertHexToRGBA(color, 0.08) || '#f0f0f0',
                        cursor: 'pointer',
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        borderRadius: '6px',
                        color: color,
                        textAlign: 'center',
                        fontWeight: 500
                    }}>
                    {code}
                </Box>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    PaperProps={{
                        style: {
                            width: '250px',
                            marginTop: 5,
                        },
                    }}
                    getContentAnchorEl={null}>
                    {options?.map((item, index) => (
                        <MenuItem
                            key={index}
                            className={classes['muiItem']}
                            onClick={() => {
                                onChange(item.code)
                                setAnchorEl(null)
                            }}>
                            <Box
                                sx={{
                                    background: convertHexToRGBA(item.color, 0.2),
                                    width: '100%',
                                    padding: '0 10px',
                                    borderRadius: '3px',
                                    color: item.color,
                                }}>
                                {item?.value}
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
        </>
    )
}
