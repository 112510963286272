import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { ModalEl as Modal } from 'shared/elements'
import { OutpatientPsychiatric, Social } from 'containers/PatientsSetting/interfaces'
import './style.scss'

import { InputNumber } from 'shared/elements/FormItem/Input/InputNumber'
import { Select } from 'shared/elements/FormItem/Select'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Checklist } from './Checklist'
import { RadioGroup } from 'shared/elements/FormItem/RadioGroup'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import moment from 'moment'
import { Box, Grid, Typography } from '@mui/material'
import { apiSocialHistory } from 'services/Patient/apiSocialHistory'
import { useNotification } from 'shared/elements/Notification'
import { getPatient } from 'services/Patient/patient'
import { uniqBy } from 'lodash'

type Props = {
    socialId?: string | number | false
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const validationSchema = yup.object({
    start_unemployment: yup
        .date()
        .nullable()
        .typeError('Invalid date')
        .min(
            yup.ref('patient_birth'),
            `Start unemployment date cannot be smaller than the patient's age!`,
        )
        .max(moment(), `Start unemployment Date can not be in future`),
    end_unemployment: yup
        .date()
        .nullable()
        .typeError('Invalid date')
        .when('start_unemployment', (start_unemployment, schema) => {
            return moment(start_unemployment).isValid()
                ? schema.min(
                      moment(start_unemployment),
                      `End unemployment' date must be greater than start unemployment date`,
                  )
                : schema.min(
                      yup.ref('patient_birth'),
                      `End unemployment date cannot be smaller than the patient's age!`,
                  )
        })
        .max(moment(), `'End unemployment' can not be in future`),
})

const INNIT_DEFAULT_SITUATIION = [
    {
        label: 'Supportive social network ',
        checked: false,
    },
    {
        label: 'No friends ',
        checked: false,
    },
    {
        label: 'Few friends',
        checked: false,
    },
    {
        label: 'Distant from family of origin',
        checked: false,
    },
    {
        label: 'Substance use based friends',
        checked: false,
    },
    {
        label: 'Family conflict',
        checked: false,
    },
]

const INNIT_DEFAULT_HOUSEMATES = [
    // @TODO:
    { label: 'Live alone', checked: false },
    { label: 'Roommates', checked: false },
    { label: 'Partner/spouse', checked: false },
    { label: 'Parents', checked: false },
    { label: 'Sibling(s)', checked: false },
    { label: 'Children', checked: false },
]

const CurrentMaritalOption = [
    // @TODO:
    { key: 'Single', value: 'Single' },
    { key: 'Never married', value: 'Never married' },
    { key: 'Married', value: 'Married' },
    { key: 'Permanent partnership', value: 'Permanent partnership' },
    { key: 'Divorced', value: 'Divorced' },
    { key: 'Separated', value: 'Separated' },
    { key: 'Widowed', value: 'Widowed' },
]
const RelationshipStatusOption = [
    // @TODO:
    { key: 'Never been in a serious relationship', value: 'Never been in a serious relationship' },
    { key: 'Not currently in a relationship ', value: 'Not currently in a relationship ' },
    { key: 'Currently in a serious relationship ', value: 'Currently in a serious relationship ' },
]
const Relationshipsatisfactions = [
    // @TODO:
    { key: 'Dissatisfied', value: 'Dissatisfied' },
    { key: 'Satisfied', value: 'Satisfied' },
    { key: 'Somewhat satisfied', value: 'Somewhat satisfied' },
    { key: 'Very satisfied', value: 'Very satisfied' },
    { key: 'N/A', value: 'N/A' },
]
const Livingsituation = [
    // @TODO:
    { key: 'Rent', value: 'Rent' },
    { key: 'Own', value: 'Own' },
    { key: 'Group home', value: 'Group home' },
    { key: 'Homeless', value: 'Homeless' },
    { key: 'Foster care', value: 'Foster care' },
]

const Employmentstatus = [
    // @TODO:
    { key: 'Full-time', value: 'Full-time' },
    { key: 'Part-time', value: 'Part-time' },
    { key: 'Full-time student', value: 'Full-time student' },
    { key: 'Part-time student', value: 'Part-time student' },
    { key: 'Homemaker', value: 'Homemaker' },
    { key: 'Unemployed (Seeking work)', value: 'Unemployed (Seeking work)' },
    { key: 'Unemployed (Not seeking work)', value: 'Unemployed (Not seeking work)' },
    { key: 'Retired', value: 'Retired' },
    { key: 'Disability', value: 'Disability' },
]
const Annualincome = [
    // @TODO:
    { key: 'Less than $11,000', value: 'Less than $11,000' },
    { key: '$11,000 - $25,999', value: '$11,000 - $25,999' },
    { key: '$26,000 - $75,999', value: '$26,000 - $75,999' },
    { key: '$76,000 - $100,000', value: '$76,000 - $100,000' },
    { key: 'More than $100,000', value: 'More than $100,000' },
]
const Sexualorientation = [
    // @TODO:
    { key: 'Heterosexual', value: 'Heterosexual' },
    { key: 'Homosexual', value: 'Homosexual' },
    { key: 'Bisexual', value: 'Bisexual' },
]

const spirituallyOption = [
    { label: 'Yes', value: 'yes', name: 'Yes' },
    { label: 'No', value: 'no', name: 'No' },
]

const SocialForm = (props: Props) => {
    //Hooks
    const notification = useNotification()

    // State
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [loading, setLoading] = useState(false)
    const [situationChecklist, setSituationChecklist] =
        useState<{ label: string; checked: boolean }[]>(INNIT_DEFAULT_SITUATIION)
    const [housematesChecklist, setHousematesChecklist] =
        useState<{ label: string; checked: boolean }[]>(INNIT_DEFAULT_HOUSEMATES)

    const formik = useFormik<Social>({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            id: '0',
            social_situation: '',
            current_marital_status: '',
            relationship_status: '',
            relationship_satisfactions: '',
            sexual_orientation: '',
            living_situation: '',
            housemates: '',
            spiritually_active: '',
            employment_status: '',
            annual_income: '',
            start_unemployment: '',
            end_unemployment: '',
            patient_birth: '',
        },
        onSubmit: handleSubmit,
    })

    // useEffect(() => {
    //     if (!props.socialId) {
    //         setSituationChecklist(INNIT_DEFAULT_SITUATIION)
    //         setHousematesChecklist(INNIT_DEFAULT_HOUSEMATES)
    //     }
    // }, [])

    const handleAdNewSituation = (newText) => {
        if (newText) {
            setSituationChecklist([{ label: newText, checked: false }, ...situationChecklist])
        }
    }
    const handleAdNewHousemates = (newText) => {
        if (newText) {
            setHousematesChecklist([{ label: newText, checked: false }, ...housematesChecklist])
        }
    }

    const handleChangChekedSituation = (rowItem, checked) => {
        const record = [...situationChecklist]
        const index = record.findIndex((element) => element.label === rowItem.label)
        record[index] = {
            ...record[index],
            checked,
        }
        setSituationChecklist(record)
    }
    const handleChangChekedHousemates = (rowItem, checked) => {
        const record = [...housematesChecklist]
        const index = record.findIndex((element) => element.label === rowItem.label)
        record[index] = {
            ...record[index],
            checked,
        }
        setHousematesChecklist(record)
    }

    const handleDeleteSituation = (rowItem) => {
        const newSituationChecklist = situationChecklist.filter(
            (item) => item.label !== rowItem.label,
        )
        setSituationChecklist(newSituationChecklist)
    }
    const handleDeleteHousemates = (rowItem) => {
        const newHousematesChecklist = housematesChecklist.filter(
            (item) => item.label !== rowItem.label,
        )
        setHousematesChecklist(newHousematesChecklist)
    }

    const modalTitle = props.socialId ? 'Update Social History' : 'Add New Social History'

    async function handleSubmit(values) {
        const social_situation_checked = situationChecklist
            .filter((item) => item.checked === true)
            .map((item) => item.label)
        const housemates_checked = housematesChecklist
            .filter((item) => item.checked === true)
            .map((item) => item.label)
        const payload = {
            ...values,
            social_situation: social_situation_checked,
            housemates: housemates_checked,
            encounter_id: props.encounter_id,
            // spiritually_active:
            //     values.spiritually_active === 'yes'
            //         ? true
            //         : values.spiritually_active === 'no'
            //         ? false
            //         : '',
        }
        delete payload['patient_birth']
        // return console.log(payload)

        setIsSubmitting(true)
        try {
            if (props.patientId && !props.socialId) {
                const resp = await apiSocialHistory.create(props.patientId, payload)
                if (resp.data) {
                    notification('Social History careated successfully !', 'success')
                    props.onOke?.()
                }
            } else if (props.patientId && props.socialId) {
                const resp = await apiSocialHistory.update(props.patientId, props.socialId, payload)
                notification('Social History updated successfully !', 'success')
                props.onOke?.()
            }
        } catch (error) {
            console.log(error)
            notification('Something Went Wrong!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }

    async function getSocialById() {
        setLoading(true)
        try {
            const resp = await apiSocialHistory.getId(props.patientId, props.socialId)
            if (resp.data) {
                let social_situation = []
                let housemates = []
                if (resp.data.social_situation.length) {
                    social_situation = resp.data.social_situation.map((item) => ({
                        label: item,
                        checked: true,
                    }))
                }
                if (resp.data.housemates.length) {
                    housemates = resp.data.housemates.map((item) => ({
                        label: item,
                        checked: true,
                    }))
                }
                setSituationChecklist(
                    uniqBy([...social_situation, ...situationChecklist], function (e) {
                        return e.label
                    }),
                )
                setHousematesChecklist(
                    uniqBy([...housemates, ...housematesChecklist], function (e) {
                        return e.label
                    }),
                )

                const respPatient = await getPatient(props.patientId)
                if (respPatient.data) {
                    const patient_birth = moment(respPatient.data.dob, 'YYYY-MM-DD')
                    formik.setValues({ ...resp.data, patient_birth })
                }
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && props.socialId) {
            getSocialById()
        }
    }, [props.patientId, props.socialId])

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                formik.setFieldValue('patient_birth', moment(resp.data.dob, 'YYYY-MM-DD'))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && !props.socialId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    // console.log(formik.values)

    return (
        <Modal
            width={900}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="outpatient-psychiatric-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Checklist
                        label="Social Situation"
                        checkList={situationChecklist}
                        handleAddNew={handleAdNewSituation}
                        handleChangCheked={handleChangChekedSituation}
                        handleDelete={handleDeleteSituation}
                    />

                    <Grid item xs={6}>
                        <Select
                            label="Current marital status"
                            name="current_marital_status"
                            value={formik.values.current_marital_status}
                            placeholder="Current marital status"
                            options={CurrentMaritalOption} // @TODO:
                            error={
                                formik.touched.current_marital_status &&
                                Boolean(formik.errors.current_marital_status)
                            }
                            helperText={
                                formik.touched.current_marital_status &&
                                formik.errors.current_marital_status
                            }
                            onChange={(value) =>
                                formik.setFieldValue('current_marital_status', value)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="Relationship status"
                            name="relationship_status"
                            value={formik.values.relationship_status}
                            placeholder="Relationship status"
                            options={RelationshipStatusOption} // @TODO:
                            error={
                                formik.touched.relationship_status &&
                                Boolean(formik.errors.relationship_status)
                            }
                            helperText={
                                formik.touched.relationship_status &&
                                formik.errors.relationship_status
                            }
                            onChange={(value) => formik.setFieldValue('relationship_status', value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="Relationship satisfactions"
                            name="relationship_satisfactions"
                            value={formik.values.relationship_satisfactions}
                            placeholder="Relationship satisfactions"
                            options={Relationshipsatisfactions} // @TODO:
                            error={
                                formik.touched.relationship_satisfactions &&
                                Boolean(formik.errors.relationship_satisfactions)
                            }
                            helperText={
                                formik.touched.relationship_satisfactions &&
                                formik.errors.relationship_satisfactions
                            }
                            onChange={(value) =>
                                formik.setFieldValue('relationship_satisfactions', value)
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="Sexual orientation"
                            name="sexual_orientation"
                            value={formik.values.sexual_orientation}
                            placeholder="Sexual orientation"
                            options={Sexualorientation} // @TODO:
                            error={
                                formik.touched.sexual_orientation &&
                                Boolean(formik.errors.sexual_orientation)
                            }
                            helperText={
                                formik.touched.sexual_orientation &&
                                formik.errors.sexual_orientation
                            }
                            onChange={(value) => formik.setFieldValue('sexual_orientation', value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="Employment status"
                            name="employment_status"
                            value={formik.values.employment_status}
                            placeholder="Employment status"
                            options={Employmentstatus} // @TODO:
                            error={
                                formik.touched.employment_status &&
                                Boolean(formik.errors.employment_status)
                            }
                            helperText={
                                formik.touched.employment_status && formik.errors.employment_status
                            }
                            onChange={(value) => formik.setFieldValue('employment_status', value)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="Annual income"
                            name="annual_income"
                            value={formik.values.annual_income}
                            placeholder="Annual income"
                            options={Annualincome} // @TODO:
                            error={
                                formik.touched.annual_income && Boolean(formik.errors.annual_income)
                            }
                            helperText={formik.touched.annual_income && formik.errors.annual_income}
                            onChange={(value) => formik.setFieldValue('annual_income', value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#516983',
                                marginTop: '14px',
                            }}>
                            Longest period of unemployment
                        </Box>
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    '& .MuiFormLabel-root': {
                                        marginBottom: 0,
                                        color: '#516983 !important',
                                        marginTop: 0,
                                        fontSize: '12px !important',
                                    },
                                }}>
                                <DatePicker
                                    label="Start"
                                    name="start_unemployment"
                                    value={formik.values.start_unemployment}
                                    error={
                                        formik.touched.start_unemployment &&
                                        Boolean(formik.errors.start_unemployment)
                                    }
                                    helperText={
                                        formik.touched.start_unemployment &&
                                        formik.errors.start_unemployment
                                    }
                                    onChange={(value) => {
                                        formik.setFieldValue('start_unemployment', value)
                                    }}
                                    notDefaultValue
                                    maxDate={moment()}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    '& .MuiFormLabel-root': {
                                        marginBottom: 0,
                                        color: '#516983 !important',
                                        marginTop: 0,
                                        fontSize: '12px !important',
                                    },
                                }}>
                                <DatePicker
                                    label="End"
                                    name="end_unemployment"
                                    value={formik.values.end_unemployment}
                                    error={
                                        formik.touched.end_unemployment &&
                                        Boolean(formik.errors.end_unemployment)
                                    }
                                    helperText={
                                        formik.touched.end_unemployment &&
                                        formik.errors.end_unemployment
                                    }
                                    onChange={(value) => {
                                        formik.setFieldValue('end_unemployment', value)
                                    }}
                                    notDefaultValue
                                    // minDate={
                                    //     formik.values.end_unemployment ||
                                    //     moment().subtract(100, 'year')
                                    // }
                                    maxDate={moment()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Checklist
                        label="Housemates"
                        checkList={housematesChecklist}
                        handleAddNew={handleAdNewHousemates}
                        handleChangCheked={handleChangChekedHousemates}
                        handleDelete={handleDeleteHousemates}
                    />

                    <Grid item xs={12}>
                        <RadioGroup
                            label="Spiritually active"
                            items={spirituallyOption}
                            value={formik.values.spiritually_active ? 'yes' : 'no'}
                            onChange={(e) => {
                                console.log(e.target.value)
                                formik.setFieldValue(
                                    'spiritually_active',
                                    e.target.value === 'yes' ? true : false,
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            label="Living situation"
                            name="annual_income"
                            value={formik.values.living_situation}
                            placeholder="Living situation"
                            options={Annualincome} // @TODO:
                            error={
                                formik.touched.living_situation &&
                                Boolean(formik.errors.living_situation)
                            }
                            helperText={
                                formik.touched.living_situation && formik.errors.living_situation
                            }
                            onChange={(value) => formik.setFieldValue('living_situation', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={isSubmitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.socialId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

// export { SocialForm }
export default SocialForm
