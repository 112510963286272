import React, { useState } from 'react'
import { IconButton } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'

import StarActiveIcon from 'assests/icons/StarActiveIcon'
import StarIcon from 'assests/icons/StarIcon'

interface Props {
    value?: boolean
    onChange?: (value?: boolean) => void
}

const StarCheckBox = ({
    value = false,
    onChange,
}: Props) => {
    const [dataLoading, setDataLoading] = useState(false)

    const handleClick = async () => {
        // FIXME: Callback
        // Whatever api call, UI should update due to React's updateState delay
        // This is tedious but can avoid bug when state change not consitent
        setDataLoading(true)

        if (onChange) await onChange(!value)

        setDataLoading(false)
    }

    if (dataLoading)
        return (
            <CircularProgress size={15} thickness={1.5} />
        )

    return (
        <IconButton onClick={() => handleClick()}>
            {value ? <StarActiveIcon /> : <StarIcon />}
        </IconButton>
    )
}

export default StarCheckBox
