import { Box, Button, CircularProgress, FormLabel, Grid, makeStyles } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { getDepartmentById } from 'services/Calendar'
import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import * as yup from 'yup'
import SearchDepartmentHeadInput from '../SearchDepartmentHeadInput'

import './styles.scss'

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    required_text: {
        color: 'red',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
}))

function DepartmentsSettingsDialog({ loading, ...props }) {
    const classes = useStyles()
    const { onSubmit, locations } = props
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [device, setDevice] = useState({
        name: '',
        head: '',
        id: '',
    })

    useEffect(() => {
        if (props.itemId) {
            setLoadingSchedule(true)
            getDepartmentById(props.itemId)
                .then((res) => {
                    setDevice({
                        ...res.data,
                    })
                    setLoadingSchedule(false)
                })
                .catch((err) => {
                    console.error('Cannot fetch department', err)
                })
        }
    }, [props.itemId])

    const handleSubmit = (values) => {
        if (!values?.head) delete values.head
        if (!values?.id) delete values.id
        onSubmit(values)
    }

    const validationSchema = yup.object({
        name: yup.string().required('Department Name is required!'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: device,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={700}
            title={props.itemId ? 'Update Department' : 'Add New Department'}
            open={props.open}
            onClose={() => props.onModalClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container className="">
                {loadingSchedule && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid
                            container
                            item
                            spacing={3}
                            className="modal-spacer"
                            style={{
                                marginTop: '-20px',
                                marginBottom: 16,
                            }}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Department Name"
                                    required
                                    name="name"
                                    value={formik.values.name}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    onChange={(value) => formik.setFieldValue('name', value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormLabel component="p" className="input-form-label">
                                    Department Head{' '}
                                </FormLabel>
                                <SearchDepartmentHeadInput
                                    valueDefault={formik.values?.head}
                                    handleAddRow={(value) => {
                                        formik.setFieldValue('head', value)
                                    }}
                                    error={formik.touched.head && Boolean(formik.errors.head)}
                                    helperText={formik.touched.head && formik.errors.head}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs="6">
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={() => props.onModalClose()}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs="6">
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                className={classes.button_create}
                                onClick={formik.handleSubmit}>
                                {props.itemId ? 'Update' : 'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default DepartmentsSettingsDialog
