import * as actionType from '../actionType'

const initialState = {
    error: false,
    loading: false,
    loadingAdmit: {},
    loadingRole: false,
    loadingEndRoom: {},
    loadingEncounterQue: true,
    errorMsg: '',
    addingParticipant: false,
}

const loader = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_LOADER: {
            return {
                ...state,
                loading: action.value,
            }
        }
        case actionType.SET_ROLE_LOADER: {
            return {
                ...state,
                loadingRole: action.value,
            }
        }
        case actionType.SET_ERROR: {
            return {
                ...state,
                error: action.value,
            }
        }
        case actionType.SET_ERROR_MSG: {
            return {
                ...state,
                errorMsg: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        case actionType.SET_LOADING_ADMIT:
            return { ...state, loadingAdmit: action.value }
        case actionType.SET_LOADING_END_MEETING:
            return {
                ...state,
                loadingEndRoom: action.value,
            }
        case actionType.SET_LOADING_ENCOUNTER_QUE:
            return {
                ...state,
                loadingEncounterQue: action.value,
            }
        case actionType.SET_LOADING_END_ROOM:
            return {
                ...state,
                loadingEndRoom: action.value,
            }
        case actionType.SET_ADDING_PARTICIPANT: {
            return {
                ...state,
                addingParticipant: action.value,
            }
        }
        default:
            return state
    }
}
export default loader
