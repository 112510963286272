import React from 'react'
import { searchLocationAppointment } from 'services/Calendar'
import { SearchBox } from '../SearchBox'

interface Location {
    _index?: string
    _type?: string
    _id?: string
    _score?: number
    _source?: {
        id?: string
        name?: string
        phoneNumber?: string
        email?: string
        address?: {
            use?: string
            type?: string
        }
        position?: {
            longitude?: number
            latitude?: number
        }
    }
    highlight?: {
        name?: string[]
    }
}

interface ResponseDTO {
    key?: React.Key
    code?: string
    display?: string
    highlightText?: string
}

interface Props {
    label?: string
    required?: boolean
    name?: string
    value?: {
        code?: string
        display?: string
    }
    placeholder?: string
    error?: boolean
    helperText?: string | boolean
    searchOnFirstLoad?: boolean
    onChange: (value: ResponseDTO | null) => void
}

const SearchLocation = (props: Props) => {
    const { ...rest } = props

    return (
        <SearchBox<ResponseDTO>
            searchHandler={(text) =>
                searchLocationAppointment(text).then((resp) => {
                    const data = resp.data.hits.hits as Location[]

                    return data.map((item) => ({
                        code: item._source?.id,
                        display: item._source?.name,
                        highlightText: `${item._source?.id || ''}${
                            item._source?.name ? ' - ' + item._source?.name : ''
                        }`,
                    }))
                })
            }
            renderKey="code"
            renderOption={(option) => option.highlightText}
            getOptionLabel={(option) => option.display || ''}
            isOptionEqualToValue={(option, value) =>
                option.code === value.code || option.display === value.display
            }
            {...rest}
        />
    )
}

export { SearchLocation }
export default SearchLocation
