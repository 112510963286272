import React, { useState, useEffect, useCallback } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from 'lodash'
import { Box, TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material'

import { getCPTCode } from 'services/Calendar'
import { ReactComponent as SearchIcon } from 'assests/images/icon_search.svg'

import { CPTCode } from '../interfaces'


interface ResponseDTO {
  key?: number
  _source: {
    cptCode: string
    clinicianDescriptor: string
    clinicianDescriptorId?: string
  }
  highlight?: {
    cptCode?: string
    highlightText?: string
    clinicianDescriptor?: string[]
  }
}

interface Props {
  r: CPTCode
  index: number
  onChange: (data: CPTCode, index: number) => void
}

export const SearchCPTCode = ({
  r,
  index,
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = useState(r.code)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<ResponseDTO[]>([])
  const [autoCompleteVisible, setAutoCompleteVisibility] =
    useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    if (r.code) getCPTcodeData(r.code)

    return () => abortController.abort()
  }, [])

  useEffect(() => {
    setInputValue(r.code)
  }, [r.code])

  const getCPTcodeData = useCallback(
    debounce(async (val) => {
      try {
        setLoading(true)

        const payload = {
          size: 25,
          query: {
            multi_match: {
              query: val,
              type: 'bool_prefix',
              fields: [
                'cptCode',
                'cptCode._2gram',
                'cptCode._3gram',
                'clinicianDescriptor',
                'clinicianDescriptor._2gram',
                'clinicianDescriptor._3gram',
              ],
            },
          },
          highlight: {
            pre_tags: [
              '<em class="text-hightlight">',
            ],
            post_tags: ['</em>'],
            fields: {
              cptCode: {
                number_of_fragments: 1,
                fragment_size: 20,
              },
              clinicianDescriptor: {
                number_of_fragments: 3,
                fragment_size: 50,
              },
            },
          },
        }

        const response = await getCPTCode(payload)
        const data = response.data.hits.hits as ResponseDTO[]
        setOptions(data.map((item, i) => ({ ...item, key: i })))

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }, 500),
    [],
  )

  useEffect(() => {
    if (inputValue.length) {
      getCPTcodeData(inputValue)
    } else {
      setOptions([])
    }
  }, [inputValue])

  const filterAutoComplete = () => {
    return options
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiIconButton-root': {
          transform: 'none !important',
        },
        '& .MuiAutocomplete-root': {
          width: '100%',
        },
        '& .MuiInputBase-input': {
          marginRight: 24,
        },
      }}>
      <Autocomplete
        popupIcon={
          <SearchIcon style={{ marginTop: 4 }} />
        }
        selectOnFocus
        clearIcon={false}
        forcePopupIcon={!loading}
        autoComplete
        loading={loading}
        options={options}
        value={{ _source: { cptCode: inputValue, clinicianDescriptor: inputValue }, highlight: {} }}
        isOptionEqualToValue={(option, value) =>
          value._source.cptCode === option._source.cptCode
          || value._source.clinicianDescriptorId === option._source.clinicianDescriptorId
          || value._source.clinicianDescriptor === option._source.clinicianDescriptor
          || value.highlight?.clinicianDescriptor === option.highlight?.clinicianDescriptor}
        open={autoCompleteVisible}
        renderOption={(props, option) =>
          <li {...props} key={option.key}>
            <td
              dangerouslySetInnerHTML={{
                __html: option.highlight?.cptCode ?
                  `<span class="hightlight">${option.highlight?.cptCode} - ${option.highlight?.clinicianDescriptor ||
                  option._source?.clinicianDescriptor}</span>` :
                  `<span class="hightlight">${option._source?.cptCode} - ${option.highlight?.clinicianDescriptor ||
                  option._source?.clinicianDescriptor}</span>`,
              }}
            />
          </li>
        }
        filterOptions={filterAutoComplete}
        onOpen={() =>
          setAutoCompleteVisibility(true)
        }
        onClose={() =>
          setAutoCompleteVisibility(false)
        }
        onChange={(event, newValue) => {
          setAutoCompleteVisibility(false)
          // setOptions(
          //     newValue
          //         ? [newValue, ...options]
          //         : options,
          // )
          onChange({
            description:
              newValue?._source?.clinicianDescriptor || '',
            code: newValue?._source?.cptCode || '',
          },
            index)
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={option => option._source?.cptCode || ''}
        sx={{ width: 300 }}
        renderInput={(params) => {
          const renderProps = {
            ...params,
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    />
                  ) : null}
                  {
                    params
                      .InputProps
                      .endAdornment
                  }
                </React.Fragment>
              ),
            },
          }

          return (
            <TextField
              variant="outlined"
              placeholder='Enter code'
              name='code'
              {...renderProps}
            />
          )
        }}
      />
    </Box>
  )
}
