import { DashboardMenu } from '../../constants/menu'
import {
    SET_ACTIVE_MENU,
    LOGOUT,
    SET_REFRESH_NOTF_BADGE_COUNT,
} from '../actionType'
const initialState = {
    activeMenu: DashboardMenu,
    isToRefreshCount: true,
}

const menu = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_MENU:
            return {
                ...state,
                activeMenu: action.activeMenu,
            }
        case LOGOUT: {
            return initialState
        }
        case SET_REFRESH_NOTF_BADGE_COUNT: {
            return {
                ...state,
                isToRefreshCount: action.isToRefreshCount,
            }
        }
        default:
            return state
    }
}

export default menu
