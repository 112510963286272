import React from 'react'

export default function IconHidenConsultation() {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_bd_1636_16817)">
                <rect
                    width="30"
                    height="30"
                    rx="10"
                    transform="matrix(-1 0 0 1 43 10)"
                    fill="white"
                    shape-rendering="crispEdges"
                />
                <path
                    d="M25.9395 19.72L30.2861 24.0667C30.7995 24.58 30.7995 25.42 30.2861 25.9333L25.9395 30.28"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_bd_1636_16817"
                    x="-3"
                    y="-6"
                    width="62"
                    height="62"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB">
                    <feFlood result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImage"
                        stdDeviation="8"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1636_16817"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-3" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.100781 0 0 0 0 0.137361 0 0 0 0 0.179167 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_1636_16817"
                        result="effect2_dropShadow_1636_16817"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1636_16817"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}
