import React, { useRef, useState, useEffect } from 'react'
import { Divider, FormLabel, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { SelectSearchInputCustom } from 'shared/elements/FormItem/SelectSearchInputCustom'
import TrashIcon from 'assests/icons/TrashIcon'
import { SelectEl } from 'shared/elements'
import TextBox from 'shared/elements/FormItem/TextBox'
import { AddCircleOutlineOutlined } from '@material-ui/icons';
import { apiFamilyPsychiatric } from 'services/Patient/patient'
import Loading from 'shared/elements/Loading'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    container2: {
        width: "100%",
        // background: '#FBFCFC',
        // border: '1px solid #CFCFCF',
        // borderRadius: 10,
        padding: '22px 10px',
    },
    form: {
        position: 'relative',
        // marginTop: 8,
        // height: 'calc(100% - 40px)',
        flexWrap: 'nowrap',
        // overflowY: 'auto',
        // overflowX: 'auto',
        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    border_bottom: {
        borderBottom: "1px solid #CFCFCF",
        marginBottom: "7px",
        paddingLeft: "30px"
    },
    border_top: {
        borderTop: "1px solid #CFCFCF",
        paddingTop: "2px",
        paddingLeft: "30px"
    },
    page_title: {
        fontSize: "22px",
        fontWeight: "500",
        color: "#303E4E"
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%",
        margin: "9px 0 3px 0"
    }
    
})
const condition = [{value:"High blood pressure", key:"High blood pressure"},{value:"Alzheimer's disease",key:"Alzheimer's disease"}
 ,{value:"Parkinson's disease",key:"Parkinson's disease"},{value:"Diabetes",key:"Diabetes"},{value:"Obesity",key:"Obesity"},{value:"Coronary artery disease",key:"Coronary artery disease"},
 {value:"Stroke",key:"Stroke"},{value:"Epilepsy",key:"Epilepsy"},{value:"Migraine(S)",key:"Migraine(s)"},{value:"Sickle cell", key:"Sickle cell"},{value:"Bleeding disorder(Cholesterol)", key:"Bleeding disorder(cholesterol)"},
 {value:"Thyroid disorder",key:"Thyroid disorder"},{value:"Mental illness",key:"Mental illness"},{value:"Asthma",key:"Asthma"},{value:"Alcoholism",key:"Alcoholism"},{value:"Anemia",key:"Anemia"},{value:"Osteoporosis",key:"Osteoporosis"},
 {value:"Arthritis", key:"Arthritis"},{value:"Multiple sclerosis", key:"Multiple sclerosis"},{value:"ADHD",key:"ADHD"},{value:"ADD",key:"ADD"},{value:"Hypertension",key:"Hypertension"},{value:"TB",key:"TB"},{value:"COPD",key:"COPD"},
 {value:"Cancer",key:"Cancer"},{ value: 'Depression', key: 'Depression'},{ value: 'Panic disorder', key: 'Panic disorder'},
 {value: 'Generalized anxiety disorde',key: 'Generalized anxiety disorder'},
 { value: 'Social anxiety disorder', key: 'Social anxiety disorder'},
 {value: 'Obsessive compulsive disorder',key: 'Obsessive compulsive disorder'},
 {value: 'Post-traumatic stress disorder',key: 'Post-traumatic stress disorder'},
 { value: 'Bipolar disorder', key: 'Bipolar disorder'},
 { value: 'Eating disorder', key: 'Eating disorder'},
 { value: 'Drug abuse', key: 'Drug abuse'},
 { value: 'Personality disorder', key: 'Personality disorder'},
 { value: 'Schizophrenia/Psychosis', key: 'Schizophrenia/Psychosis'},
 { value: 'Psychiatric hospitalization', key: 'Psychiatric hospitalization'},
 { value: 'Suicide attempt', key: 'Suicide attempt'},
 { value: 'Suicide', key: 'Suicide'}]

 const FamilyMembers = [
    // @TODO:
    { key: 'MOTHER', value: 'Mother ' },
    { key: 'FATHER', value: 'Father  ' },
    { key: 'BROTHER', value: 'Brother ' },
    { key: 'SISTER', value: 'Sister ' },
    { key: 'GRANDMOTHER', value: 'Grandmother ' },
    { key: 'GRANDFATHER', value: 'Grandfather ' },
    { key: 'AUNT', value: 'Aunt ' },
    { key: 'UNCLE', value: 'Uncle ' },
    { key: 'COUSIN', value: 'Cousin ' },
    { key: 'SON', value: 'Son  ' },
    { key: 'DAUGHTER', value: 'Daughter ' },
]

function FamilyHistoryCreateForm({familyMedicalId,patientId,forModalOpen,closeModal, onSubmitting, encounter_id}) {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()

    const location = useLocation()
    const familyHistoryId = forModalOpen ? familyMedicalId : location?.state?.familyMedicalId
    const { id } = useParams()
    const pId = forModalOpen ? patientId : id
    // const { id: accountId } = useParams()
    const notification = useNotification()
    const [loading , setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [addMore, setAddmore]= useState(false)
    const [initData, setInitData] =useState([{
                                        family_member: '',
                                        psychiatric_condition: [],
                                        encounter_id: "",
                                        comment:''
                                    }])
    const goBack = () => {
        if(forModalOpen){
            closeModal(true)
        }else{
            history.push(`/patient/${pId}/family-history`)
        }
          
    }

    const handleSubmit = async(value)=>{
        if(addMore){
            formik.setValues([
                ...formik.values,
                {
                    family_member: '',
                    psychiatric_condition: [],
                    encounter_id: "",
                    comment: '',
                },
            ])
            return;
        }

        if(familyHistoryId){
            setIsSubmitting(true)
            const editData = value[0]
            const finalData = {
                                    ...editData,
                                    psychiatric_condition: editData?.psychiatric_condition.length > 0 ? editData?.psychiatric_condition.map(condition => condition.value) : []
                                }
            
            try {
                const rest = await apiFamilyPsychiatric.update(
                    pId,
                    familyHistoryId,
                    finalData,
                )
                setIsSubmitting(false)
                if (rest.data.Status === 'SUCCESSED') {
                    notification('Family Psychiatric History updated successfully', 'success')
                    forModalOpen && onSubmitting(true)
                    goBack()
                }
            } catch(err){
                notification(err?.data?.message || 'Internal Error', 'error')
            }
            
        }else{
            setIsSubmitting(true)
            const addData = encounter_id ?
            value?.filter(item => !item?.id).map(item => ({
                ...item,
                encounter_id:encounter_id,
                psychiatric_condition: item?.psychiatric_condition.map(condition => condition.value)
                }))
            :
            value?.filter(item => !item?.id).map(item => ({
                ...item,
                psychiatric_condition: item?.psychiatric_condition.map(condition => condition.value)
                }));
            try {
                if(addData.length > 0){
                    const res = await apiFamilyPsychiatric.create(pId, addData)
                    setIsSubmitting(false)
                    if(res?.data?.Status === "SUCCESSED"){
                        notification('Family Psychiatric History created successfully', 'success') 
                        forModalOpen && onSubmitting(true)
                        goBack()
                    }
                }
            } catch(err){
                notification(err?.data?.message || 'Internal Error', 'error')
            }
        }    
    }
    
    const validationSchema = yup.array().of(
        yup.object({
        id: yup.string(),
        family_member: yup.string().required('This field is required!'),
    }))
    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    /** Auto scroll into position of element whose validation does not meet */
    const validateForm = (errors) => {
        if (!isEmpty(errors)) {
            const field = Object.keys(errors)[0]
            let el

            if (Array.isArray(errors[field])) {
                const errorIndex = errors[field].findIndex((error) => !!error)

                el = formRef.current.querySelector(`[name="${field}_${errorIndex}"]`)
            } else el = formRef.current.querySelector(`[name="${field}"]`)

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
    }
    const getFamilyPsychiatricById = async (familyMedicalId) => {
        setLoading(true)
        try {
            const rest = await apiFamilyPsychiatric.getId(pId, familyMedicalId)
            if (rest.data.Status === 'SUCCESSED') {
                setInitData([
                    {   
                        family_member: rest.data.familyMedicalHistory.family_member,
                        psychiatric_condition: rest?.data?.familyMedicalHistory?.psychiatric_condition.map((item,index)=>({value: item,key: item})),
                        comment:rest?.data?.familyMedicalHistory?.comment
                    }
                ])
            }
        } catch (error) {
            notification(error .data?.message || 'Internal Error', 'error')
        } finally {
            setLoading(false)
        }
    }
     useEffect(()=>{
        if(familyHistoryId){
            getFamilyPsychiatricById(familyHistoryId)
        }
     },[familyHistoryId])
    return (
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Loading visible={loading} />
                  {!forModalOpen &&
                    <> 
                        <Grid
                            container
                            onClick={() => goBack()}
                            style={{
                                cursor: 'pointer',
                            }}>
                            <KeyboardBackspaceRoundedIcon
                                style={{
                                    color: '#5571C6',
                                    fontSize: 18,
                                }}
                            />
                            <Typography className={classes.text_back}>
                                {'Back to patient health record'}
                            </Typography>
                        </Grid>
                        <Grid container className={classes.border_bottom}>
                            {' '}
                            <h1 className={classes.page_title}>{familyHistoryId? "Update Family History " : "Add Family History"}</h1>
                        </Grid>
                    </>
                   }

                <Grid container direction="column" className={classes.form}>
                    {location?.state?.backTitle && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}></div>
                    )}
                    <Grid container item className={classes.container2} spacing={2} xs={12}>
                        <Grid item xs={12} style={{ paddingBottom: '25px' }}>
                            <fieldset className="divider-information">
                                <legend>
                                    <Typography className={'heading1-title'}>
                                        Family Condition
                                    </Typography>
                                </legend>
                                {formik.values.map((item, index) => (
                                    <Grid
                                        item
                                        style={{
                                            background: '#F8F9FB',
                                            border: '1px solid #EAECF0',
                                            borderRadius: '5px',
                                            padding: '0px 10px 10px 10px',
                                            marginBottom: '10px',
                                        }}
                                        className="task-user-popper-containerTwo">
                                        <Grid container item alignItems="flex-start" spacing={2}>
                                            <Grid item xs={2}>
                                                <FormLabel
                                                    component="p"
                                                    className={classes.formLabel}>
                                                    Family Member{' '}
                                                    <span style={{ color: '#E81A1A' }}>*</span>
                                                </FormLabel>
                                                <SelectEl
                                                    // label="Family Member"
                                                    name="family_member"
                                                    // required
                                                    placeholder="Select"
                                                    value={item?.family_member}
                                                    error={
                                                        (formik.touched || [])[index]?.family_member &&
                                                        Boolean((formik.errors || [])[index]?.family_member)
                                                    }
                                                    helperText={
                                                        (formik.touched || [])[index]?.family_member &&
                                                        (formik.errors || [])[index]?.family_member
                                                    }
                                                    options={FamilyMembers}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            const updatedStates = formik.values.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    family_member:
                                                                        idx === index
                                                                            ? value.target.value
                                                                            : item.family_member,
                                                                }),
                                                            )
                                                            formik.setValues(updatedStates)
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormLabel
                                                    component="p"
                                                    className={classes.formLabel}>
                                                    Condition(S)
                                                </FormLabel>
                                                <SelectSearchInputCustom
                                                    placeholder="Select"
                                                    options={condition}
                                                    readonly={false}
                                                    disabled={false}
                                                    multiple={true}
                                                    isChecBoxFlag
                                                    value={item?.psychiatric_condition}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            const updatedStates = formik.values.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    psychiatric_condition:
                                                                        idx === index
                                                                            ? value
                                                                            : item.psychiatric_condition,
                                                                }),
                                                            )
                                                            formik.setValues(updatedStates)
                                                        }
                                                    }}
                                                    limitTags={1}
                                                    // limitTags={sidebarOpen ? 1 : 2}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextBox
                                                    // label="Note"
                                                    name="note"
                                                    variant="outlined"
                                                    minRows={4}
                                                    placeholder="Comments"
                                                    style={{ marginTop: '32px' }}
                                                    value={item?.comment}
                                                    onChange={(value) => {
                                                        if (value) {
                                                            const updatedStates = formik.values.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    comment: idx === index ? value : item.comment,
                                                                }),
                                                            )
                                                            formik.setValues(updatedStates)
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            { !familyHistoryId &&
                                                <Grid
                                                    item
                                                    xs={1}
                                                    className="d-flex align-flex-start justify-end">
                                                    <Grid
                                                        item
                                                        style={{
                                                            paddingLeft: '10px',
                                                            marginTop: '31px',
                                                        }}>
                                                        <Divider
                                                            variant="middle"
                                                            flexItem
                                                            orientation="vertical"
                                                            className="divider_selectBoxTaxonomy"
                                                        />
                                                    </Grid>
                                                    <IconButton
                                                        style={{
                                                            paddingLeft: '10px',
                                                            marginTop: '38px',
                                                        }}
                                                        onClick={() => {
                                                            if (formik.values.length > 1) {
                                                                formik.setValues(
                                                                    formik.values.filter(
                                                                        (_, idx) => idx !== index,
                                                                    ),
                                                                )
                                                            }
                                                        }}>
                                                        <TrashIcon />
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                ))}
                                {!familyHistoryId &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            whiteSpace: 'nowrap',
                                            marginTop: '5px',
                                        }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                marginBottom: '7px',
                                            }}
                                            onClick={() => {
                                                setAddmore(true)
                                                formik.handleSubmit()
                                            }}>
                                            <AddCircleOutlineOutlined
                                                style={{
                                                    color: '#15A452',
                                                    fontSize: 18,
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    marginLeft: 6,
                                                    color: '#15A452',
                                                    fontFamily: 'Rubik',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    lineHeight: '140%',
                                                }}>
                                                Add New Family Condition
                                            </Typography>
                                        </div>
                                    </div>
                                }
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} className={classes.border_top}>
                            {!location?.state?.backTitle && (
                                <Footer
                                    style={{
                                        marginTop: '1.5rem',
                                    }}
                                    loading={isSubmitting}
                                    onCancel={goBack}
                                    onOk={() => {
                                        setAddmore(false)
                                        formik.handleSubmit()
                                        validateForm(formik.errors)
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default FamilyHistoryCreateForm
