import { Grid, makeStyles, MenuItem, Select, Typography } from '@material-ui/core'
import { RefreshOutlined } from '@material-ui/icons'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { Box } from '@mui/material'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import ViewDetailDialog from 'containers/PatientsSetting/view/LabOrdersPatient/ViewDetailDialog'
import PatientInfor from 'containers/PatientsSetting/view/PatientInfor'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { allApiLabOrder } from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { convertFullName } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'
import TextWithMacro from '../reusable/TextWithMacro'
import { ExpandMore } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { getLabResultStatus } from 'utilities/labResults'

export const useStyles = makeStyles({
    container: {
        overflow: 'auto',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    btn__macro: {
        color: '#667689',
        width: "289px",
    },
    CentralizedMacrosText:{
        color: "#667689",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
        margin: "10px 7px 7px 7px"
    },
    wrap_data_grid: {
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    fontWeight: 500,
                    alignItems: 'center',
                    color: '#303E4E',
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 267px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
        },
        '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            margin: '4px 0',
            borderRadius: '8px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
})

const LabResults = ({ patientId, onChange, fieldName,macros,values,nonEditable }) => {
    const classes = useStyles()
    const history = useHistory()
    // const { id: patientId } = useParams()
    const [sortModel, setSortModel] = useState([])
    const [viewDetail, setViewDetail] = useState(false)
    const [listLabOrder, setListLabOrder] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isDownload, setIsDownload] = useState()
    const [page, setPage] = useState(1)
    const notification = useNotification()
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 0,
        limit: 10,
    })
    const [synchronizing, setSynchronizing] = useState(false)
    const [selectedMacro, setSelectedMacro] = useState('')
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]
    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const getLabOrderByPatient = async (patientId) => {
        setLoading(true)
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId)

            const formatData =
                res.data?.data.map((item) => ({
                    id: item.id,
                    requisition_number: item.placer_order_number,
                    lab_orderred: item.lab_code + ' - ' + item.sponsor_name,
                    order_date: item.creation_datetime,
                    status: getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status),
                    orderring_provider: convertFullName(
                        item.provider_first_name,
                        item.provider_middle_name,
                        item.provider_last_name,
                    ),
                    requisition_pdf: item.requisitionUrl,
                    results: {
                        html: item.htmlUrl || null,
                        pdf: item.pdfUrl || null,
                    },
                })) || []

            setListLabOrder(formatData)
            if (res.data?.paging) {
                setPaginationData({
                    total: res.data.paging?.total || 0,
                    start: res.data.paging?.page || 0,
                    limit: res.data.paging?.limit || 10,
                })
            }
        } catch (error) {
            console.log(error)
            notification('Get Lab order error', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handlePageChange = (value) => {
        setPage(+value - 1)
    }

    const handleHTML = (html) => {
        setViewDetail(html)
    }

    const handlePDF = (pdf) => {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display:none')
        a.href = pdf
        a.target = '_blank'
        a.download = 'Requisition PDF'
        a.click()
        window.URL.revokeObjectURL(pdf)
    }

    useEffect(() => {
        const abortController = new AbortController()
        getLabOrderByPatient(patientId)
        return () => abortController.abort()
    }, [page, sortModel])

    const columns = [
        {
            headerName: 'Ordering provider',
            headerClassName: 'super-app-theme--header',
            field: 'orderring_provider',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Lab ordered',
            headerClassName: 'super-app-theme--header',
            field: 'lab_orderred',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value || ''
            },
        },
        {
            headerName: 'Requisition Number',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_number',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || 1212
            },
        },
        {
            headerName: 'Order Date',
            headerClassName: 'super-app-theme--header',
            field: 'order_date',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Order Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            flex: 0.4,
            sortable: true,
            renderCell: ({ value, row }) => {
                if (row.results.pdf || row.results.html) {
                    return 'Final Reported'
                }
                return 'Requisition Received'
            },
        },

        {
            headerName: 'Requisition PDF',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_pdf',
            flex: 0.4,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ cursor: 'pointer' }}>
                        {value ? (
                            <Box onClick={() => handlePDF(value)}>
                                <DataGridCell value={'View detail'} highlight />
                            </Box>
                        ) : (
                            '-'
                        )}
                        {/* {row.id === isDownload?.id && isDownload.loading && <LinearProgress />} */}
                    </Box>
                )
            },
        },
        {
            headerName: 'Results',
            headerClassName: 'super-app-theme--header',
            field: 'results',
            flex: 0.3,
            sortable: true,
            renderCell: ({ value, row }) => {
                if (value) {
                    return (
                        <Box
                            sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#5571c6 !important',
                                justifyContent: 'center',
                            }}>
                            {row.results.pdf && (
                                <>
                                    <Box onClick={() => handlePDF(row.results.pdf)}>{'PDF'}</Box>{' '}
                                    <Box sx={{ padding: '0px 5px', marginBottom: '3px' }}>|</Box>{' '}
                                </>
                            )}
                            {row.results.html && (
                                <Box onClick={() => handleHTML(row.results.html)}>{'HTML'}</Box>
                            )}
                        </Box>
                    )
                }
                return '-'
            },
        },
        {
            headerName: 'Refresh',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.2,
            sortable: false,
            renderCell: () => {
                return (
                    <RefreshOutlined
                        style={{
                            padding: 5,
                            cursor: 'pointer',
                        }}
                        fontSize="small"
                        onClick={handleRefresh}
                    />
                )
            },
        },
    ]

    const handleRefresh = async () => {
        try {
            setLoading(true)
            setSynchronizing(true)
            await allApiLabOrder.syncLabOrder()
            await getLabOrderByPatient(patientId)
        } catch (error) {
            notification('Something went wrong', 'error')
        } finally {
            setLoading(false)
            setSynchronizing(false)
        }
    }

    const handleCellClick = (params) => {
        console.log(params)
        if (params.field === 'requisition_pdf') {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.setAttribute('style', 'display:none')
            a.href = params.row.requisition_PDF
            a.download = 'Requisition PDF'
            a.click()
            window.URL.revokeObjectURL(params.row.requisition_PDF)

            // setIsDownload({
            //     id: params.id || params.row.id || null,
            //     loading: true,
            // })
            // getDocumentById(139)
            //     .then((res) => {
            //         const uri = res?.data?.url
            //         const a = document.createElement('a')
            //         document.body.appendChild(a)
            //         a.setAttribute('style', 'display:none')
            //         if (uri) {
            //             fetch(uri).then(async (res) => {
            //                 const response = await res.blob()
            //                 const url = window.URL.createObjectURL(response)
            //                 a.href = url
            //                 // a.download = params?.row?.file_url || 'Requisition PDF'
            //                 a.target = '_blank'
            //                 a.click()
            //                 window.URL.revokeObjectURL(url)
            //                 setIsDownload({
            //                     id: params.id || params.row.id || null,
            //                     loading: false,
            //                 })
            //             })
            //         }
            //     })
            //     .catch((err) => {
            //         setIsDownload({
            //             id: params.id || params.row.id || null,
            //             loading: false,
            //         })
            //     })
        }
        if (params.field === 'results') {
            setViewDetail(params.row.results)
            // const a = document.createElement('a')
            // document.body.appendChild(a)
            // a.setAttribute('style', 'display:none')
            // a.href = params.row.results
            // a.target = '_blank'
            // a.click()
            // window.URL.revokeObjectURL(params.row.results)
        }
    }

    const getAllLabResultByPatient = async () => {
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId, 0, 100000)
            const list = res.data.data.map((item) => ({
                ...item,
                sponsor_name: convertFullName(
                    item.provider_first_name,
                    item.provider_middle_name,
                    item.provider_last_name,
                ),
                lab_code: `${item.lab_code} - ${item.sponsor_name}`,
                report_service_date: formatDateTime(item.creation_datetime),
                report_status: (() => {
                    return getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status)
                })(),
                requisition: item.requisitionUrl ? 'View detail' : '',
                results: (() => {
                    if (item.pdfUrl && item.htmlUrl) {
                        return 'HTML | PDF'
                    }
                    if (item.htmlUrl) {
                        return 'HTML'
                    }
                    if (item.pdfUrl) {
                        return 'PDF'
                    }
                })(),
            }))
            onChange(`${fieldName}.lab_result_list`, list)
        } catch (error) {
            notification('Get Lab result error', 'error')
        }
    }

    useEffect(() => {
        if (patientId) {
            getAllLabResultByPatient()
        }
    }, [patientId])

    return (
        <Box className={classes.container}>
            {/* <Grid container direction="column" wrap="nowrap">
                <Grid
                    container
                    onClick={() => history.goBack()}
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes['text_back']}>
                        Back to Patient Dashboard
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 12 }}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Lab order List</Typography>
                    </Grid>
                </Grid>
            </Grid> */}
            {/* <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>
            </Grid> */}
            {/* <Grid item xs={12} className={classes.form_list_container}> */}
            <Box className={classes.wrap_data_grid}>
                <MuiDataGrid
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    getRowId={(row) => row.id}
                    rows={listLabOrder}
                    columns={columns}
                    pageSize={+paginationData.limit}
                    paginationMode="server"
                    // disableColumnSelector={true}
                    disableSelectionOnClick
                    loading={isLoading}
                    rowCount={paginationData.total}
                    pagination
                    components={{
                        NoRowsOverlay: () => <NoRowsOverlayEl />,
                        Pagination: () => (
                            <Pagination
                                totalRecords={paginationData.total}
                                onPageChange={handlePageChange}
                            />
                        ),
                        ColumnUnsortedIcon: TeleIconUpDownSortedList,
                        ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                        ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                    }}
                    disableColumnMenu={true}
                    // onCellClick={handleCellClick}
                />
            </Box>
            {/* </Grid> */}
            {Boolean(viewDetail) && (
                <ViewDetailDialog
                    btnFromLabOrder={true}
                    link={viewDetail}
                    open={Boolean(viewDetail)}
                    handleClose={() => setViewDetail(false)}
                />
            )}
            <Grid container justifyContent="space-between" alignItems="cetner">
                <Typography className={classes.narrative} style={{marginTop:"10px"}}>Narrative</Typography>
                <Grid item>
                    <Grid container alignItems="center">
                        <Box
                            sx={{
                                '& .MuiSelect-selectMenu': {
                                    background: '#F3F6F9',
                                    padding: '7px 30px 7px 16px',
                                    borderRadius: '6px',
                                },
                                '& .MuiInput-root': {
                                    marginRight: '8px',
                                },
                            }}>
                            <Select
                                disableUnderline
                                disabled={nonEditable}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 505,
                                            maxWidth: 300,
                                            marginTop: 8,
                                        },
                                    },
                                }}
                                IconComponent={ExpandMore}
                                name="macro"
                                onChange={(e) => {
                                    setSelectedMacro(e.target.value)
                                }}
                                value={selectedMacro}
                                displayEmpty
                                renderValue={(sel) =>
                                    sel ? (
                                        <span className={classes.btn__macro}>
                                            Insert Macro:{' '}
                                            {allMacros.find((item) => item.name === sel)?.name}
                                        </span>
                                    ) : (
                                        <span className={classes.btn__macro}>Insert Macro</span>
                                    )
                                }>
                                <Typography className={classes.CentralizedMacrosText}>
                                    Personalized Macros
                                </Typography>
                                {macrosPractitionerData?.map((m) => (
                                    <MenuItem
                                        onClick={() => {
                                            handleSelectMacro(m.description)
                                        }}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={m.name}>
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                                <Typography
                                    className={classes.CentralizedMacrosText}
                                    style={{ borderTop: '1px solid #DEE2E6', paddingTop: '12px' }}>
                                    Centralized Macros
                                </Typography>
                                {macrosData?.map((m) => (
                                    <MenuItem
                                        onClick={() => handleSelectMacro(m.description)}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={m.name}>
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <TextWithMacro
                name={`${fieldName}.narrative`}
                onChange={onChange}
                macros={macros}
                rows={12}
                values={values?.narrative}
                isDisabled={nonEditable}
            />
        </Box>
    )
}

export default LabResults
