import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingPage from 'utilities/loading-page'

export const EmpyConsultation = () => {
    const telehealthScreen = useSelector((state: any) => state.telehealthScreen)
    const history = useHistory()
    useEffect(() => {
        if (!telehealthScreen.active) {
            history.push('/schedule')
        }
    }, [])
    return !telehealthScreen.active ? <LoadingPage /> : null
}
