import * as actionTypes from './actionTypes'

export const initialState = {
    minors: {
        data: [],
        isLoading: false,
        isLoaded: false,
        isError: false,
    },
}

export function reducer(state, action) {
    console.log(action.type, action.data, 'action.type')
    switch (action.type) {
        case actionTypes.MINORS_LOADING:
            return {
                ...state,
                minors: {
                    ...state.minors,
                    isLoading: true,
                },
            }

        case actionTypes.MINORS_SUCCESS:
            return {
                ...state,
                minors: {
                    ...state.minors,
                    isLoading: false,
                    isLoaded: true,
                    data: action.data || [],
                },
            }

        case actionTypes.MINORS_FAILURE:
            return {
                ...state,
                minors: {
                    ...state.minors,
                    isError: true,
                    isLoaded: true,
                },
            }

        default:
            return state
    }
}
