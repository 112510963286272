import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { connect, useSelector } from 'react-redux'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'

import { Button as ButtonCustom } from 'components/custom'

import { useNotification } from 'shared/elements/Notification'

import { ClinicalPracticeForm } from './ClinicalPracticeForm'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { ClinicalPractice } from './interface'
import { DataGrid } from 'shared/elements/DataGrid'
import { ErrorType } from 'containers/PatientsSetting/interfaces'
import { getListPractices, deletePractice, createPractice, updatePractice } from 'services/Setting'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
})

const ClinicalPractices = () => {
    const classes = useStyles()
    const notification = useNotification()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [clinicalPracticeForm, setClinicalPracticeForm] = useState<{
        practice_id: string | undefined
        status: boolean
    }>({
        practice_id: '',
        status: false,
    })

    const [isLoading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState<String>('')
    const [clinicalPractices, setClinicalPractices] = useState([])

    const permissions = useSelector((state) => state?.permission?.clinical_practice)

    const columns: GridColumns<ClinicalPractice> = [
        {
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Group NPI',
            headerClassName: 'super-app-theme--header',
            field: 'group_npi_number',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Tax ID',
            headerClassName: 'super-app-theme--header',
            field: 'tax_id',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_clinical_practice') &&
                !permissions.includes('delete_clinical_practice'),
            renderCell: ({ row }) => {
                const menu = [] as any
                if (permissions.includes('modify_clinical_practice')) {
                    menu.push({
                        text: 'Edit',
                        handler: () => handleClickEdit(row.id as string), // FIXME:
                    })
                }
                if (permissions.includes('delete_clinical_practice')) {
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDelete(row.id as string),
                    })
                }
                return (
                    <EditAction
                        menu={menu}
                        deleteMessage="Are you sure you want to delete this clinical practices?"
                    />
                )
            },
        },
    ]

    const handleSubmit = async (payload: ClinicalPractice) => {
        const successMsg = clinicalPracticeForm.practice_id
            ? 'Clinical Practice was successfully updated!'
            : 'Clinical Practice was successfully created!'

        const errorMsg = clinicalPracticeForm.practice_id
            ? 'Clinical Practice was not successfully updated!'
            : 'Clinical Practice was not successfully added!'

        try {
            setIsSubmitting(true)

            if (clinicalPracticeForm.practice_id)
                await updatePractice(clinicalPracticeForm.practice_id, payload)
            else await createPractice(payload)

            notification(successMsg, 'success')

            getListClinicalPractices()
            handleChangeForm()
        } catch (exception) {
            notification((exception as ErrorType).data?.message || errorMsg, 'error')
        }

        setIsSubmitting(false)
    }

    const handleDelete = async (practice_id: string) => {
        try {
            await deletePractice(practice_id)
            notification('A Clinical Practice was successfully deleted!', 'success')
            getListClinicalPractices()
        } catch (error) {
            notification(
                (error as ErrorType)?.data?.message ||
                    'A Clinical Practice was not successfully deleted!',
                'error',
            )
        }
    }

    const handleClickEdit = (practice_id: string) => {
        handleChangeForm(practice_id)
    }

    const handleChangeForm = (practice_id?: string) => {
        setClinicalPracticeForm({
            status: !clinicalPracticeForm.status,
            practice_id,
        })
    }

    useEffect(() => {
        const abortController = new AbortController()
        getListClinicalPractices()

        return () => abortController.abort()
    }, [textSearch, sortModel])

    const getListClinicalPractices = async () => {
        setLoading(true)
        try {
            const resp = await getListPractices(
                sortModel[0]?.sort
                    ? {
                          search: textSearch,
                          [sortModel[0].sort]: sortModel[0].field,
                      }
                    : {
                          search: textSearch,
                          asc: '',
                      },
            )

            setClinicalPractices(resp.data.data || [])
        } catch (exception) {
            setLoading(false)
            notification(
                (exception as ErrorType).data?.message || 'Cannot get list clinical practices',
                'error',
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Clinical Practices</Typography>
                    <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_clinical_practice']}>
                            <Grid item className="d-flex space-between align-center">
                                {/* <SearchField onSearch={handleSearch} /> */}
                                <ButtonCustom
                                    tooltip="Add New Clinical Practices"
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        setClinicalPracticeForm({
                                            status: true,
                                            practice_id: undefined,
                                        })
                                    }
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        </RBACWrapper>
                    </RBACProvider>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    sortingMode="server"
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    height={'calc(100vh - 140px)'}
                    rows={clinicalPractices}
                    columns={columns}
                    loading={isLoading}
                    hideFooter={true}
                />
            </Grid>
            {clinicalPracticeForm.status && (
                <ClinicalPracticeForm
                    open={clinicalPracticeForm.status}
                    practice_id={clinicalPracticeForm.practice_id}
                    loading={isSubmitting}
                    onCancel={handleChangeForm}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(ClinicalPractices)
