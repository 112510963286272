import * as actionType from '../actionType'
import { setLoading, setError } from './loader'
import {
    getClinicalNotes,
    getAllAddendumByClinicalNoteId,
    createAddendum,
} from '../../containers/ClinicalNotes/api/services'

export const getClinicalNotesList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setClinicalNotesList([]))
        dispatch(setClinicalNotesCount(0))
        getClinicalNotes(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setClinicalNotesList(res.data.clinicalNotes))
                dispatch(setClinicalNotesCount(res.data.total))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getAllAddendumByClinicalNote = (payload) => {
    return (dispatch) => {
        dispatch(setIsLoadingGetListAddendum(true))
        getAllAddendumByClinicalNoteId(payload)
            .then((res) => {
                dispatch(setIsLoadingGetListAddendum(false))
                dispatch(setAddendumList(res.data.response))
            })
            .catch((err) => {
                dispatch(setIsLoadingGetListAddendum(false))
                dispatch(setError(true))
            })
    }
}

export const createAddendumByClinicalNote = (payload) => {
    return (dispatch) => {
        dispatch(setIsSuccessCreateAddendum(false))
        dispatch(setIsLoadingAddendum(true))
        createAddendum(payload)
            .then((res) => {
                dispatch(setIsLoadingAddendum(false))
                dispatch(setIsSuccessCreateAddendum(true))
            })
            .catch((err) => {
                dispatch(setIsLoadingAddendum(false))
                dispatch(setError(true))
                dispatch(setIsSuccessCreateAddendum(false))
                dispatch(setErrorCreateAddendum(err))
            })
    }
}

export const setClinicalNotesList = (value) => {
    return {
        type: actionType.SET_CLINICAL_NOTES_LIST,
        value,
    }
}

export const setMissingMedicalCoding = (value) => {
    return {
        type: actionType.SET_MISSING_MEDICAL_CODING,
        value,
    }
}

export const setPageMissingMedicalCoding = (value) => {
    return {
        type: actionType.SET_PAGE_MISSING_MEDICAL_CODING,
        value,
    }
}

export const setIsUpdatingMedicalCoding = (value) => {
    return {
        type: actionType.SET_IS_UPDATING_MEDICAL_CODING,
        value,
    }
}

export const setClinicalNotesCount = (value) => {
    return {
        type: actionType.SET_CLINICAL_NOTES_COUNT,
        value,
    }
}

export const setAddendumList = (value) => {
    return {
        type: actionType.GET_ALL_ADDENDUM_BY_CLINICAL_NOTE,
        value,
    }
}

export const setIsSuccessCreateAddendum = (value) => {
    return {
        type: actionType.SET_IS_SUCCESS_CREATE_ADDENDUM,
        value,
    }
}

export const setErrorCreateAddendum = (value) => {
    return {
        type: actionType.SET_ERROR_CREATE_ADDENDUM,
        value,
    }
}

export const setIsLoadingAddendum = (value) => {
    return {
        type: actionType.SET_IS_LOADING_ADDENDUM,
        value,
    }
}

export const setIsLoadingGetListAddendum = (value) => {
    return {
        type: actionType.SET_IS_LOADING_GET_LIST_ADDENDUM,
        value,
    }
}

export const setActiveNote = (value) => {
    return {
        type: actionType.SET_ACTIVE_NOTE,
        value,
    }
}

export const setCurrentNote = (value) => {
    return {
        type: actionType.SET_CURRENT_NOTE,
        value,
    }
}
export const updatingMedicationList = (value) => {
    return {
        type: actionType.UPDATING_MEDICATION_LIST,
        value,
    }
}
export const updatingMedicalCoding = (value) => {
    return {
        type: actionType.UPDATING_MEDICAL_CODING_FORCEFULLY,
        value,
    }
}
export const permissionSaveMedicalCoding = (value) => {
    return {
        type: actionType.PERMISSION_SAVE_MEDICAL_CODING,
        value,
    }
}
export const syncAppointmentForCN = (value) => {
    return {
        type: actionType.SYNC_APPOINTMENT_CLINICALNOTE,
        value,
    }
}
export const setActiveNoteToPlan = (value) => {
    return {
        type: actionType.SET_CATEGORY_PLAN,
        value,
    }
}
export const handleVisibleSaveAndClose = (value) => {
    return {
        type: actionType.SET_SHOW_SAVE_AND_CLOSE_BUTTON,
        value
    }
}

export const handleOpenSaveAndCloseDialog = (value) => {
    return {
        type: actionType.SET_SHOW_SAVE_AND_CLOSE_DIALOG,
        value
    }
}

export const handleSetCurrentEncounter = (value) => {
    return {
        type: actionType.SET_CURRENT_ENCOUNTER,
        value
    }
}

export const setAllowError = (value) => {
    return {
        type: actionType.SET_ALLOW_ERROR,
        value
    }
}

export const checkTabChange = (value) => {
    return {
        type: actionType.SET_TAB_CHANGE,
        value
    }
}

export const checkHandleScroll = (value) => {
    return {
        type: actionType.SET_CATEGORY_ONHANDLESCROLL,
        value
    }
}
