import React, { useState, useEffect } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { SecurityRole } from '../interfaces'
import { postSecurityRole } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'

import './styles.scss'

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    container: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
    },
    form: {
        // marginTop: 8,
        height: 'calc(100% - 40px)',
        margin: 'auto',
        width: 'fit-content',
        // background: '#FBFCFC',
        // border: '1px solid #F3F6F9',
        // borderRadius: 10,
        // padding: '32px 40px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
}))

interface Props {
    open: boolean
    roles: SecurityRole[]
    onClose: (forceReload?: boolean) => void
}

export const SecurityRoleForm = (props: Props) => {
    const classes = useStyles()
    const notification = useNotification()
    const permissions = useSelector((state) => state.permission?.security_roles)

    const [loading, setLoading] = useState(false)
    const [data] = useState<SecurityRole>({
        id: 0,
        name: '',
        description: '',
        active: true,
        predefined_id: '',
    })

    const canCreateNewRole = permissions.includes('create_new_security_roles')

    const validationSchema = yup.object({
        name: yup.string().required('Role Name is required!'),
        description: yup.string(),
        predefined_id: yup.string(),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema,
        onSubmit: async (values) => {
            if (!canCreateNewRole) return notification(`Not allowed`, 'error')

            setLoading(true)

            try {
                await postSecurityRole(values)
                notification('A new Security Role created successfully!', 'success')
                setLoading(false)
                props.onClose(true)
            } catch (ex) {
                setLoading(false)
                notification(
                    ex?.data?.message || 'A new Security Role created unsuccessfully!',
                    'error',
                )
            }
        },
    })

    return (
        <Modal
            width={700}
            title="Add New Security Role"
            description="Add New Security Role"
            open={props.open}
            onClose={() => props.onClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="">
                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        <Grid item xs={12}>
                            <TextBox
                                label="Role Name"
                                required
                                placeholder="Enter Role Name"
                                value={formik.values.name}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                onChange={(value) => formik.setFieldValue('name', value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                label="Description"
                                multiline
                                minRows={4}
                                placeholder="Enter Description"
                                value={formik.values.description}
                                onChange={(value) => formik.setFieldValue('description', value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Select
                                label="Security Template"
                                placeholder="Select Security Template"
                                value={formik.values.predefined_id}
                                onChange={(value) => formik.setFieldValue('predefined_id', value)}
                                options={props.roles.map((role) => ({
                                    key: role.id as number,
                                    value: role.name,
                                }))}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}
                    style={{
                        margin: '0 10px',
                    }}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={() => props.onClose()}>
                            Cancel
                        </Button>
                    </Grid>

                    <Grid item xs={6}>
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<></>}
                            variant="contained"
                            disabled={loading}
                            className={classes.button_create}
                            onClick={formik.handleSubmit}>
                            Save Security Role
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
