import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Select, MenuItem, Button,Box } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'
import { ExpandMore } from '@material-ui/icons'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import DescriptionIcon from '@mui/icons-material/Description'
import { useNotification } from 'shared/elements/Notification'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import copyNew from 'assests/icons/copyNew.svg'
import edit_icon from 'assests/icons/edit1_icon.svg'
import PreviousCN from '../reusable/PreviousCN'
import { set } from 'lodash'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'
import PreviousCNDrawer from 'components/ClinicalNotes/reusable/PreviousCNDrawer'
import moment from 'moment'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689', 
    },
    first_box:{
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        // height: "814px",
        // overflow: 'hide',
        padding: '10px',
    },
    second_box:{
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        padding: '10px',
        background: 'var(--Backgrounds-Light-Grey-Background, #F8F9FB)',
        alignItems: 'center',
    },
    copy_button:{
        borderRadius: "4px",
        background: "#FFF",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "26px",
        textAlign: "center",
        fontFamily: "Rubik",
        fontStyle: "normal",
    },
    box_type:{
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '10px',
        height: '510px'
    },
    textFieldWrap: {
        // border: '1px solid #E9ECF1',
        minHeight: '100%',
        boxSizing: 'border-box',
        width: '100%',
        // borderRadius: '0px 0px 5px 5px',
        // borderRight: '1px solid #C8C9CD',
        // borderBottom: '1px solid #C8C9CD',
        // borderLeft: '1px solid #C8C9CD',
        marginTop: 0,
        color: '#667689',
        // background: '#FFF',
        '& .MuiFilledInput-root': {
            background: '#fff',
            minHeight: '380px',
            '& textarea:first-child': {
                minHeight: '380px',
            }
        },
        '& .MuiInputBase-root': {
            resize: 'vertical',
            overflow: 'auto', 
            padding: '0px !important'
        },
        '& .MuiFilledInput-input': {
            color: '#303E4E !important'
        }
    },
    CentralizedMacrosText: {
        color: '#616161 !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        padding: '16px 0 0 16px'
    },
    btnMacro: {
        color: '#303E4E !important',
        fontSize: '14px !important',
        maxWidth: '100%',
        textWrap: 'wrap'
    }
})

const HPI = ({
    chief_complaint = '',
    hideGenerateBtn,
    description,
    onChange,
    fieldName,
    onGenerateClick,
    meta,
    values,
    placeholder = '',
    nonEditable,
    lastNarrative,
    lastUpdateDate,
    copyDate
}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedMacro, setSelectedMacro] = useState('')
    const [open, setOpen] = useState(false)
    const [copied, setCopied] = useState(values?.copiedDate ? true : false)
    const notification = useNotification()
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            const a = option1.name.toUpperCase(),
            b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            const a = option1.name.toUpperCase(),
            b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )

    const allMacros = [...macrosData, ...macrosPractitionerData]

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    
    function handleChangeCopy() {
        const text = typeof lastNarrative === 'string' ? lastNarrative : null
        navigator.clipboard.writeText(text)
        if (text) {
            handleSelectMacro(text)
            setCopied(true)
            onChange(`${fieldName}.copiedDate`, moment(copyDate).format('MM/DD/YYYY'))
        }

        handleClose()
    }

    const handleUnsetCopiedText = () => {
        setCopied(false)
        onChange(`${fieldName}.copiedDate`, null)
    }

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const handleModalOpen = () =>{
        setOpen(true)
    }
    const handleClose = () =>{
        setOpen(false)
    }
    return (
        // <Grid item className={classes.first_box}>
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="cetner"
                className={classes.second_box}>
                {/* <Typography className={classes.narrative}>History of Present Illness</Typography> */}
                <div style={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                    <Typography className={classes.narrative}>HPI</Typography>
                    {copied && <Typography>
                        Copied from {copyDate ? moment(copyDate)?.format('MM/DD/YYYY') : ''}
                    </Typography>}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                    <Button
                        startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                        className={classes.copy_button}
                        onClick={() => {
                            if(!nonEditable && lastNarrative){
                                handleModalOpen()
                            }
                        }}
                        disabled={!lastNarrative || nonEditable}
                        style={{border: copied ? "1px solid #5571C6" : "1px solid #D1D1D1", opacity: (!lastNarrative || nonEditable) ? "25%" : ""}}>
                        {' '}
                        Copy: {copyDate ? moment(copyDate)?.format('MM/DD/YYYY') : ''}
                    </Button>
                    <Box
                        sx={{
                            '& .MuiSelect-selectMenu': {
                                borderRadius: '4px',
                                border: '0.7px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                                background: '#FFFFFF',
                                padding: '4px 24px 4px 8px !important',
                                color: '#242424',
                            },
                            '& .MuiInput-root': {
                                marginRight: '8px',
                            },
                            '& .MuiInputBase-root': {
                                minHeight: 'auto !important'
                            },
                            '& .MuiSelect-select:focus': {
                                borderRadius: '4px !important'
                            }
                        }}>
                        <Select
                            disableUnderline
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        maxHeight: 505,
                                        maxWidth: 300,
                                        marginTop: 8,
                                    },
                                },
                            }}
                            IconComponent={ExpandMore}
                            name="macro"
                            onChange={(e) => {
                                setSelectedMacro(e.target.value)
                            }}
                            value={selectedMacro}
                            displayEmpty
                            // disabled={nonEditable}
                            renderValue={(sel) =>
                                sel ? (
                                    <span
                                        className={classes.btnMacro}
                                        style={{
                                            display: 'flex',
                                            alignItem: 'center',
                                            paddingRight: '3px',
                                        }}>
                                        <img
                                            src={edit_icon}
                                            alt=""
                                            style={{ paddingRight: '3px' }}
                                        />
                                        Macros:{' '}
                                        {allMacros?.find((item) => item?.name === sel)?.name}
                                    </span>
                                ) : (
                                    <span
                                        className={classes.btnMacro}
                                        style={{
                                            display: 'flex',
                                            alignItem: 'center',
                                            paddingRight: '3px',
                                        }}>
                                        <img
                                            src={edit_icon}
                                            alt=""
                                            style={{ paddingRight: '3px' }}
                                        />
                                        Macros
                                    </span>
                                )
                            }>
                            <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                            {macrosPractitionerData?.map((m) => (
                                <MenuItem
                                    onClick={() => {handleSelectMacro(m.description)}}
                                    className={classes.menu__item}
                                    key={m.id}
                                    value={m.name}
                                    >
                                    <Typography className={classes.btnMacro}>
                                        {m.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                            <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                            {macrosData?.map((m) => (
                                <MenuItem
                                    onClick={() => handleSelectMacro(m.description)}
                                    className={classes.menu__item}
                                    key={m.id}
                                    value={m.name}>
                                    <Typography className={classes.btnMacro}>
                                        {m.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </div>
            </Grid>
            <Box className={classes.box_type}>
                <TextFieldDebounce
                    className={classes.textFieldWrap}
                    placeholder={placeholder}
                    name={`${fieldName}.narrative`}
                    variant="filled"
                    size="small"
                    multiline
                    minRows={12}
                    value={values?.narrative}
                    disabled={nonEditable}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                        handleUnsetCopiedText()
                    }}
                    InputProps={{
                        classes: classes.root,
                        disableUnderline: true,
                    }}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    applyDebounce={false}
                />
            </Box>
            {open && (
                <PreviousCNDrawer
                    open={open}
                    handleClose={handleClose}
                    categoryName={'HPI'}
                    lastNarrative={lastNarrative}
                    lastUpdateDate={copyDate}
                    // narrativeText={'Please enter the patient’s history of present illness'}
                    handleChangecopy={handleChangeCopy}
                    noAllSelectionCheck={true}
                >
                    <Typography style={{ padding: 15, color: '#303e4e' }}>
                        {lastNarrative}
                    </Typography>
                </PreviousCNDrawer>
            )}
        </>
        // </Grid>
    )
}

export default HPI
