import {
    Button,
    CircularProgress,
    FormHelperText,
    FormLabel,
    Grid,
    TextField,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { maxCharLength } from '../../../constants'
import { clinicAdminProfileForm } from '../../../constants/clinicAdminProfileFormFields'
import { errorMessages } from '../../../constants/errorMessages'
import { UserRoles } from '../../../constants/user-roles'
import NotificationPreferences from '../../../containers/NotificationPreferences'
import { saveNotificationPreferences } from '../../../services/Notification'
import {
    handleClinicAdminProfileAdd,
    handleClinicAdminProfileEdit,
} from '../../../store/actions/clinicAdmin'
import {
    setError,
    setErrorMessage,
} from '../../../store/actions/loader'
import {
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'
import { getCharOfName } from '../../../utilities/profile'
import { usNumberRegex } from '../../../utilities/regex'
import { ProfilePhotoModal } from '../TM2UAdmin/ProfilePhotoUpload'
import { ButtonEl } from '../../../shared/elements'

const ClinicAdminProfileEdit = (props) => {
    const {
        profileDetails,
        handleEditClose,
        isCreate,
        clinicId,
        preferences,
    } = props

    const dispatch = useDispatch()

    const loading = useSelector(
        (state) => state.loader.loading,
    )
    const errMsg = useSelector(
        (state) => state.loader.errorMsg,
    )
    const initialValues = {
        first_name: profileDetails?.first_name,
        last_name: profileDetails?.last_name,
        email: profileDetails?.user?.email,
        photo: profileDetails?.photo,
        mob: formatPhone(
            profileDetails?.user?.mob
                .replace(/\D/g, '')
                .slice(-10),
        ),
        email_notification: preferences?.email,
        sms_notification: preferences?.sms,
        app_notification: preferences?.app_notification,
    }
    const [formIntial, setFormIntial] = useState({
        ...initialValues,
    })
    const userRole = getLoggedInUserRole()
    const validateSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(errorMessages.firstName)
            .trim()
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.firstName,
            ),
        last_name: Yup.string()
            .trim()
            .required(errorMessages.lastName)
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.lastName,
            ),
        email: Yup.string()
            .email()
            .required(errorMessages.validEmailId),
        mob: Yup.string()
            .required(errorMessages.validPhoneNumber)
            .matches(usNumberRegex, 'Invalid phone number'),
    })
    const getChangedValues = (values, initial) => {
        return Object.entries(values).reduce(
            (acc, [key, value]) => {
                const hasChanged = initial[key] !== value
                if (hasChanged) {
                    if (!key.includes('_notification')) {
                        acc[key] = value
                    }
                }
                return acc
            },
            {},
        )
    }

    async function savePreferences(values) {
        await saveNotificationPreferences({
            user_id: profileDetails?.user?.id,
            email: values.email_notification,
            sms: values.sms_notification,
            app_notification: values.app_notification,
        })
    }

    const saveProfile = async (val) => {
        const changedValues = getChangedValues(
            val,
            initialValues,
        )
        if (changedValues['mob']) {
            changedValues['mob'] =
                process.env.REACT_APP_COUNTRY_CODE +
                val.mob.replace(/\D/g, '').slice(-10)
        }
        // let payload = {
        //     first_name: val?.first_name,
        //     last_name: val?.last_name,
        //     // mob: val?.user?.mob,
        //     email: val?.email,
        //     user_id: profileDetails?.user_id
        // }
        // await dispatch(handleClinicAdminProfileEdit(payload, () => {
        //     Swal.fire(
        //         '',
        //         'Profile Updated Successfully',
        //     ).then((result) => {

        //         handleEditClose()
        //     })
        // }))
        const actionMessage = isCreate
            ? 'Profile Created Successfully'
            : 'Profile Updated Successfully'
        if (isCreate) {
            if (Object.keys(changedValues).length !== 0) {
                changedValues.clinic_id = clinicId
                await dispatch(
                    handleClinicAdminProfileAdd(
                        changedValues,
                        (res) => {
                            const { isUserExist } = res.data
                                ?.data
                                ? res.data?.data
                                : res.data
                            const clinicAdminCreationResponse =
                                isUserExist
                                    ? 'User Already Existed'
                                    : actionMessage
                            Swal.fire(
                                '',
                                clinicAdminCreationResponse,
                            ).then((result) => {
                                handleEditClose()
                            })
                        },
                    ),
                )
            }
        } else {
            changedValues.user_id = profileDetails?.user_id
            await dispatch(
                handleClinicAdminProfileEdit(
                    changedValues,
                    () => {
                        Swal.fire(
                            '',
                            'Profile Updated Successfully',
                        ).then((result) => {
                            handleEditClose()
                        })
                    },
                ),
            )
        }
    }

    useEffect(() => {
        if (errMsg) {
            Swal.fire(
                '',
                'User Already Existed',
                'error',
            ).then(() => {
                dispatch(setError(false))
                dispatch(setErrorMessage(''))
            })
        }
    }, [errMsg])
    const nameInitials = getCharOfName(
        `${profileDetails?.first_name} ${profileDetails?.last_name}`,
    )
    // Modal
    const [isImageLoaded, setIsImageLoaded] =
        useState(false)
    const onEditCallbackWithImgLoad = (x, y) => {
        handleEditClose(x, y)
        setIsImageLoaded(false)
    }

    // end modal
    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="flex-start">
                {/* <Grid
                    item
                    xs={12}
                    md={12}
                    className="bg-tab-client bg-tab dFlexSpaceBw">
                    {' '}
                </Grid> */}
                <Grid
                    container
                    direction="row"
                    className="edit-profile-view-wrapper"
                    xs={12}
                    md={12}>
                    <Grid item>
                        {!isCreate && (
                            <>
                                <Grid
                                    xs
                                    className="upload-wrap mt80 client-profile-wrap">
                                    <div className="empty-bg">
                                        {profileDetails?.photo ? (
                                            <>
                                                {!isImageLoaded && (
                                                    <CircularProgress />
                                                )}
                                                <img
                                                    style={{
                                                        display:
                                                            isImageLoaded
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    src={
                                                        profileDetails?.photo
                                                    }
                                                    alt="Profile"
                                                    className="profile-img"
                                                    onLoad={() =>
                                                        setIsImageLoaded(
                                                            true,
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div className="profile-img-placeholder">
                                                {
                                                    nameInitials
                                                }
                                            </div>
                                        )}

                                        <ProfilePhotoModal
                                            profilePhoto={
                                                profileDetails?.photo
                                            }
                                            nameInitials={
                                                !isCreate
                                                    ? nameInitials
                                                    : ''
                                            }
                                            userId={
                                                profileDetails.user_id
                                            }
                                            whois="clinical-admin"
                                            onEditCallback={() =>
                                                onEditCallbackWithImgLoad()
                                            }
                                        />
                                    </div>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        // xs={!isCreate ? 12 : 12}
                        md={12}
                        spacing={2}
                        className="edit-view">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                saveProfile(values)
                                if (
                                    userRole ===
                                    UserRoles.TM2U_SUPER_ADMIN
                                ) {
                                    savePreferences(values)
                                }
                            }}
                            validationSchema={
                                validateSchema
                            }>
                            {({
                                errors,
                                touched,
                                values,
                                isSubmitting,
                                setFieldValue,
                                handleBlur,
                            }) => {
                                return (
                                    <Form>
                                        <Grid
                                            container
                                            spacing={2}>
                                            {clinicAdminProfileForm.map(
                                                (
                                                    item,
                                                    i,
                                                ) => {
                                                    return (
                                                        <React.Fragment
                                                            key={
                                                                i
                                                            }>
                                                            {item.sectionTitle ===
                                                                'Profile' && (
                                                                <Grid
                                                                    container
                                                                    item
                                                                    spacing={
                                                                        2
                                                                    }
                                                                    className="profile-name-wrap-clinic">
                                                                    {item.fieldItems.map(
                                                                        (
                                                                            item,
                                                                            index,
                                                                        ) => (
                                                                            <>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        6
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                    key={
                                                                                        index
                                                                                    }>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                        md={
                                                                                            11
                                                                                        }>
                                                                                        <FormLabel className="tm-form-title">
                                                                                            {
                                                                                                item.label
                                                                                            }
                                                                                            {item.required && (
                                                                                                <span className="astrik">
                                                                                                    *
                                                                                                </span>
                                                                                            )}
                                                                                        </FormLabel>
                                                                                        <TextField
                                                                                            name={
                                                                                                item.name
                                                                                            }
                                                                                            onChange={async (
                                                                                                e,
                                                                                            ) => {
                                                                                                if (
                                                                                                    item.name ===
                                                                                                    'mob'
                                                                                                ) {
                                                                                                    // add value masking
                                                                                                    const maskedValue =
                                                                                                        await formatPhone(
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        )

                                                                                                    setFieldValue(
                                                                                                        e
                                                                                                            .target
                                                                                                            .name,
                                                                                                        maskedValue,
                                                                                                    )
                                                                                                } else {
                                                                                                    await setFieldValue(
                                                                                                        e
                                                                                                            .target
                                                                                                            .name,
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                            fullWidth
                                                                                            disabled={
                                                                                                item.readOnly
                                                                                            }
                                                                                            inputProps={{
                                                                                                maxLength:
                                                                                                    item.name ===
                                                                                                    'mob'
                                                                                                        ? 14
                                                                                                        : maxCharLength,
                                                                                            }}
                                                                                            id={
                                                                                                item.id
                                                                                            }
                                                                                            variant="outlined"
                                                                                            placeholder={
                                                                                                item.placeHolder
                                                                                            }
                                                                                            onBlur={
                                                                                                handleBlur
                                                                                            }
                                                                                            value={
                                                                                                values[
                                                                                                    item
                                                                                                        .name
                                                                                                ]
                                                                                            }
                                                                                        />
                                                                                        {errors[
                                                                                            item
                                                                                                .name
                                                                                        ] &&
                                                                                            touched[
                                                                                                item
                                                                                                    .name
                                                                                            ] && (
                                                                                                <FormHelperText
                                                                                                    error>
                                                                                                    {
                                                                                                        errors[
                                                                                                            item
                                                                                                                .name
                                                                                                        ]
                                                                                                    }
                                                                                                </FormHelperText>
                                                                                            )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        ),
                                                                    )}
                                                                </Grid>
                                                            )}
                                                            <Grid
                                                                item
                                                                xs={
                                                                    12
                                                                }>
                                                                {(userRole ===
                                                                    UserRoles.TM2U_SUPER_ADMIN &&
                                                                    item.sectionTitle) ===
                                                                    'Notifications' && (
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            2
                                                                        }>
                                                                        <Grid
                                                                            container
                                                                            item>
                                                                            {item.fieldItems.map(
                                                                                (
                                                                                    item,
                                                                                    index,
                                                                                ) => {
                                                                                    return (
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                6
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }>
                                                                                            <NotificationPreferences
                                                                                                label={
                                                                                                    item.label
                                                                                                }
                                                                                                name={
                                                                                                    item.name
                                                                                                }
                                                                                                value={
                                                                                                    values[
                                                                                                        item
                                                                                                            .name
                                                                                                    ]
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) => {
                                                                                                    setFieldValue(
                                                                                                        e
                                                                                                            .target
                                                                                                            .name,
                                                                                                        !values[
                                                                                                            item
                                                                                                                .name
                                                                                                        ],
                                                                                                    )
                                                                                                }}
                                                                                            />
                                                                                        </Grid>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </React.Fragment>
                                                    )
                                                },
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={11}
                                            justifyContent="flex-end">
                                            <ButtonEl
                                                variant="contained"
                                                type="submit"
                                                disabled={
                                                    isSubmitting
                                                }
                                                className="mb2 mt1">
                                                Submit
                                            </ButtonEl>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
            {loading && (
                <div className="dialog-loader">
                    <CircularProgress />
                    <small> Loading </small>
                </div>
            )}
        </>
    )
}
export default ClinicAdminProfileEdit
