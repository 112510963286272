import React, { useReducer, useState, useEffect } from 'react'
import { Box } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

import { useNotification } from 'shared/elements/Notification'
import { searchAvailability } from 'services/ProviderDirectory'

import './styles.scss'
import { CircularProgress } from '@material-ui/core'
import { initialState, reducer, VIEW } from 'containers/ProviderDirectory/AvailabilityTab/reducer'
import {
    generateAppointments,
    generateTimeAvailability,
    PropsGenerateTimeAvailability,
    Reoccuring,
    UnitOfTime,
} from 'containers/ProviderDirectory/AvailabilityTab/utils'
import { CustomDateHeader } from 'containers/ProviderDirectory/AvailabilityTab/ViewMonth/CustomDateHeader'
import { MonthHeaderCellContent } from 'containers/ProviderDirectory/AvailabilityTab/ViewMonth/MonthHeaderCellContent'
import MonthCalendarEvent from 'containers/ProviderDirectory/AvailabilityTab/ViewMonth/MonthCalendarEvent'
import WeekHeaderCellContent from 'containers/ProviderDirectory/AvailabilityTab/ViewWeek/WeekHeaderCellContent'
import WeekCalendarEvent from 'containers/ProviderDirectory/AvailabilityTab/ViewWeek/WeekCalendarEvent'
import DayCalendarEvent from 'containers/ProviderDirectory/AvailabilityTab/ViewDay/DayCalendarEvent'
import { AddNewAvailability } from 'containers/ProviderDirectory/AvailabilityTab/AddNewAvailability'
import { useSelector } from 'react-redux'
import { generateAvailability } from 'containers/ProviderDirectory/AvailabilityTab/generateAvailability'

const VIEWS = ['day', 'week', 'month']

export const ProviderAvailability = ({ date: dateSchedule, isFromClinicalNote }) => {
    //reducer
    const [state, dispatch] = useReducer(reducer, initialState)
    const notification = useNotification()
    const [loadingAvailability, setLoadingAvailability] = React.useState(false)

    //state
    const [views, setViews] = useState(VIEWS)
    const [id, setId] = useState(0)
    const [opentForm, setOpenForm] = useState(false)
    // const [date, setDate] = useState(moment())

    const userData = useSelector((state) => state?.login?.userData)
    const providerId = userData?.['custom:user_id']

    const hanldeChangeView = (view) => {
        dispatch({
            type: 'CHANGE_CALENDAR_VIEW',
            view,
        })
    }
    function onNavigate(date, view = state.view.selected) {
        dispatch({
            type: 'NAVIGATE_TO_DATE',
            date,
            view,
        })
    }
    const { localizer, getNow } = React.useMemo(() => {
        moment.locale(moment().locale(), {
            week: {
                dow: 1,
            },
        })
        return {
            localizer: momentLocalizer(moment),
            getNow: () => moment().toDate(),
            startOfWeek: () => 1,
        }
    }, [])

    useEffect(() => {
        getDataAvailability()
    }, [state.view.selected, state.view.date])

    async function getDataAvailability() {
        // const scheme = [1].map((item) => ({
        //     id: 18,
        //     provider_id: '55055',
        //     days: [0, 1, 2, 3],
        //     start: '2023-03-01T20:41:00.000Z',
        //     end: '2023-03-30T20:41:00.000Z',
        //     all_day: false,
        //     is_recur: false,
        //     repeat_gap: 1,
        //     repeat_by: 'weeks',
        //     after: 0,
        //     end_type: '',
        //     timezone: 'US/Eastern',
        // }))
        const showData: { start: Date; end: Date; id: number }[] = []
        const converData: PropsGenerateTimeAvailability[] = []
        setLoadingAvailability(true)
        try {
            const params = {
                start: state.view.start_date,
                end: state.view.end_date,
            }
            const {
                data: { availability },
            } = await searchAvailability(providerId, params)
            // console.log(availability)

            if (availability) {
                // availability.forEach((item) => {
                //     const record: PropsGenerateTimeAvailability = {
                //         id: item.id,
                //         startDate: moment(item.start),
                //         ends: moment(item.end),
                //         weekDays: item.days.map((item) => {
                //             switch (item) {
                //                 case 'MON':
                //                     return 1
                //                 case 'TUE':
                //                     return 2
                //                 case 'WED':
                //                     return 3
                //                 case 'THU':
                //                     return 4
                //                 case 'FRI':
                //                     return 5
                //                 case 'SAT':
                //                     return 6
                //                 case 'SUN':
                //                     return 0
                //             }
                //         }),
                //         repeatBy:
                //             item.repeat_by === 'WEEK'
                //                 ? 'weeks'
                //                 : item.repeat_by === 'MONTH'
                //                 ? 'month'
                //                 : 'weeks',
                //         repeatGap: item.repeat_gap ? item.repeat_gap : 1,
                //     }
                //     converData.push(record)
                //     console.log(record, 'log record')
                // })
                availability.forEach((item) => {
                    const dayAvailibility = generateAvailability(item)
                    dayAvailibility.forEach((el) => {
                        showData.push({
                            ...el,
                            id: item.id,
                        })
                    })
                })
                dispatch({
                    type: 'CHANGE_DATA',
                    data: showData,
                })
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingAvailability(false)
        }
    }

    useEffect(() => {
        dispatch({
            type: 'NAVIGATE_TO_DATE',
            date: moment(dateSchedule),
            view: state.view.selected,
        })
    }, [dateSchedule])

    return (
        <>
            <Box className="wrap-availability-provider">
                <Box className="hearder-availability">
                    <Box className="group-views">
                        {views.map((view, index) => (
                            <Box
                                onClick={() => hanldeChangeView(view)}
                                key={index}
                                className={`btn-views ${
                                    state.view.selected === view ? 'active' : ''
                                }`}>
                                {view}
                            </Box>
                        ))}
                    </Box>
                    <Box className="btn-label" onClick={() => setOpenForm(!opentForm)}>
                        <AddCircleOutlineIcon /> Add New Availability
                    </Box>
                </Box>
                <Box
                    className={`wrap-calendar-provider ${
                        isFromClinicalNote ? 'in-clinical-note' : ''
                    }`}>
                    <Box className="left-calendar">
                        {loadingAvailability && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1,
                                }}>
                                <CircularProgress thickness={2.0} />
                            </Box>
                        )}
                        <Box className="example">
                            <Box className="block-calendar">
                                <Calendar
                                    style={{
                                        backgroundColor: '#FFF',
                                    }}
                                    events={state.events.data}
                                    views={{
                                        month: true,
                                        // week: CustomWeekView,
                                        week: true,
                                        day: true,
                                    }}
                                    formats={{
                                        eventTimeRangeFormat: () => null,
                                        eventTimeRangeStartFormat: () => null,
                                        eventTimeRangeEndFormat: () => null,
                                        dayFormat: (date, culture, localizer) =>
                                            localizer.format(date, 'dddd', culture),
                                        timeGutterFormat: (date, culture, localizer) =>
                                            localizer.format(date, 'hh:mm a', culture),
                                        dayHeaderFormat: (date, culture, localizer) =>
                                            localizer.format(date, 'MMMM D', culture),
                                        monthHeaderFormat: (date, culture, localizer) =>
                                            localizer.format(date, 'MMMM', culture),
                                        weekdayFormat: (date, culture, localizer) =>
                                            localizer.format(date, 'dddd', culture),
                                    }}
                                    messages={{
                                        showMore: (total) => `+${total} view more`,
                                    }}
                                    scrollToTime={state.view.date}
                                    selectable="ignoreEvents"
                                    onSelectSlot={(e) => {
                                        console.log(e)
                                        if (state.view.selected === VIEW.MONTH) {
                                            dispatch({
                                                type: 'NAVIGATE_TO_DATE',
                                                date: moment(e.start),
                                                view: 'day',
                                            })
                                        }
                                    }}
                                    onSelectEvent={(e) => {
                                        setId(e.id)
                                        setOpenForm(true)
                                        // dispatch({
                                        //     type: 'NAVIGATE_TO_DATE',
                                        //     date: moment(e.start),
                                        //     view: 'day',
                                        // })
                                    }}
                                    step={15}
                                    view={state.view.selected}
                                    onView={hanldeChangeView}
                                    date={state.view.date}
                                    onNavigate={onNavigate}
                                    localizer={localizer}
                                    getNow={getNow}
                                    showMultiDayTimes
                                    popup={true}
                                    components={{
                                        month: {
                                            dateHeader: (props) => <CustomDateHeader {...props} />,
                                            header: (props) => (
                                                <MonthHeaderCellContent
                                                    {...props}
                                                    selectedView={state.view}
                                                />
                                            ),
                                            event: (props) => <MonthCalendarEvent {...props} />,
                                        },
                                        week: {
                                            header: (props) => <WeekHeaderCellContent {...props} />,
                                            event: (props) => <WeekCalendarEvent {...props} />,
                                        },
                                        day: {
                                            event: (props) => (
                                                <DayCalendarEvent {...props} timezone={''} />
                                            ),
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {isFromClinicalNote && (
                        <Box className="right-calendar-small">
                            <CalendarPicker
                                onChangeMonth={(value) => {}}
                                onchangeDate={(value) => {
                                    dispatch({
                                        type: 'NAVIGATE_TO_DATE',
                                        date: moment(value),
                                        view: state.view.selected,
                                    })
                                }}
                                date={state.view.date}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            {opentForm && (
                <AddNewAvailability
                    id={id}
                    open={opentForm}
                    onClose={() => {
                        setOpenForm(false)
                        setId(0)
                    }}
                    onOke={() => {
                        setOpenForm(false)
                        setId(0)
                        getDataAvailability()
                    }}
                    providerId={providerId}
                />
            )}
        </>
    )
}

function CalendarPicker({ onchangeDate, onChangeMonth, date }) {
    return (
        <>
            <Box
                sx={{
                    padding: '15px',
                    '& .MuiPickersCalendarHeader-transitionContainer': {
                        ' & p': {
                            color: '#303E4E',
                            fontWeight: 500,
                            fontSize: '16px',
                        },
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#303E4E',
                        width: '30px',
                        height: '30px',
                    },
                    '& .MuiPickersCalendarHeader-dayLabel': {
                        color: '#9CA9B8',
                        fontWeight: 400,
                        fontSize: '14px',
                        width: '41px',
                    },
                    '& .MuiPickersCalendarHeader-daysHeader': {
                        marginTop: '16px',
                        marginBottom: '8px',
                    },
                    '& .MuiPickersDay-daySelected': {
                        background: '#5571C6',
                        borderRadius: '8px',
                        color: 'white !important',
                    },
                    '& .MuiPickersBasePicker-pickerView': {
                        maxWidth: 'none',
                        width: '100%',
                    },
                    '& .MuiPickersDay-day': {
                        width: '41px',
                        height: '32px',
                    },
                    '& .MuiIconButton-label': {
                        '& p': {
                            fontSize: '14px',
                        },
                    },
                    '& .MuiPickersSlideTransition-transitionContainer': {
                        '& > div': {
                            background: 'ghostwhite',
                            padding: '20px 0 20px 0',
                            borderRadius: '3px',
                        },
                    },
                }}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <DatePicker
                        autoOk
                        variant="static"
                        disableToolbar
                        // openTo="year"
                        value={date}
                        onChange={onchangeDate}
                        onMonthChange={onChangeMonth}
                    />
                </MuiPickersUtilsProvider>
            </Box>
        </>
    )
}
