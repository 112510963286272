import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { PsychiatricMedication } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import moment from 'moment'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { Typography } from '@mui/material'

type Props = {
    page?: number
    dataSource?: PsychiatricMedication[]
    pagination?: Pagination
    sortModel?: GridSortModel
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    onPageChange?: (page: number) => void
    handleEdit?: (id: string | number) => void
    hanldeDelete?: (id: string | number) => void
    disabled?: Boolean
}

export const PsychiatricMedicationHistoryDashboard = (props: Props) => {
    // Hooks
    const notification = useNotification()

    const columns: GridColumns<PsychiatricMedication> = [
        {
            headerName: 'Medication',
            headerClassName: 'super-app-theme--header',
            field: 'drug',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => (
                <HtmlTooltip
                    title={`${value.brandName}, ${value.genericName}, ${value.strength}, ${value.form}`}
                    arrow
                    placement="top">
                    <Typography
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}>
                        {`${value.brandName || ''}, ${value.genericName || ''}, ${
                            value.strength || ''
                        }, ${value.form || ''}`}
                    </Typography>
                </HtmlTooltip>
            ),
        },
        {
            headerName: 'Strength',
            headerClassName: 'super-app-theme--header',
            field: 'strength',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.strength} />,
        },
        {
            headerName: 'How Long',
            headerClassName: 'super-app-theme--header',
            field: 'how_long',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.how_long} />,
        },
        {
            headerName: 'End Date',
            headerClassName: 'super-app-theme--header',
            field: 'stop_date',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => (
                <DataGridCell
                    value={row.stop_date ? moment(row.stop_date).format('MM/DD/YYYY') : undefined}
                />
            ),
        },
        {
            headerName: 'Therapeutic Effect',
            headerClassName: 'super-app-theme--header',
            field: 'therapeutic_effect',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.therapeutic_effect} />,
        },
        {
            headerName: 'Side Effects',
            headerClassName: 'super-app-theme--header',
            field: 'side_effects',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.side_effects} />,
        },
        {
            headerName: 'Reason For Stopping',
            headerClassName: 'super-app-theme--header',
            field: 'reason_for_stopping',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.reason_for_stopping} />,
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                    !props.disabled
                    ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.handleEdit?.(params.id),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.hanldeDelete?.(params.id),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                
            ),
        },
    ]

    return (
        <DataGrid
            page={props.page}
            onPageChange={props.onPageChange}
            getRowId={(row) => row.id as string | number}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
        />
    )
}
