import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
   
}))

export default function AlertModal({ ele, ind, length, viewModal, setViewMOdal, isShow }) {
    const [open, setOpen] = React.useState(isShow)
    const handleClose = () => {
        const newInd = viewModal?.slice()
        newInd[ind] = false
        setViewMOdal(newInd)
        viewModal[ind] = false
        setOpen(false)
        if (ind < length - 1) {
            newInd[ind + 1] = true
            setViewMOdal(newInd)
        }
        if (ind === length - 1) localStorage.setItem('announcements_is_read', true)
    }

    const SVG = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 23 31"
                fill="none">
                <path
                    d="M8.53387 14.6157C8.60912 14.2364 8.71394 13.8636 8.84737 13.5005L9.11797 14.3565L9.74827 14.1597L9.13447 12.218L7.21387 12.8182L7.41187 13.4447L8.25007 13.179C8.08648 13.6046 7.9606 14.0436 7.87387 14.491C7.4543 16.6779 7.92568 18.9409 9.18437 20.7825C10.4431 22.624 12.386 23.8933 14.5861 24.3113L14.7115 23.6553C12.6876 23.2683 10.9007 22.0993 9.74264 20.4048C8.58459 18.7102 8.1499 16.6283 8.53387 14.6157Z"
                    fill="#FBFCFC"
                />
                <path
                    d="M16.4999 13.963C16.0151 13.9636 15.5413 14.1071 15.1386 14.3753C14.7358 14.6434 14.422 15.0243 14.237 15.4696C14.0519 15.915 14.0038 16.4049 14.0988 16.8774C14.1938 17.35 14.4275 17.7839 14.7706 18.1244C15.1136 18.4649 15.5505 18.6967 16.026 18.7904C16.5016 18.8842 16.9944 18.8358 17.4422 18.6512C17.8901 18.4667 18.2728 18.1543 18.5421 17.7536C18.8114 17.3529 18.9551 16.8819 18.9551 16.4C18.9551 16.0797 18.8915 15.7625 18.7681 15.4666C18.6447 15.1707 18.4638 14.9019 18.2358 14.6756C18.0077 14.4492 17.737 14.2698 17.4392 14.1475C17.1413 14.0252 16.8221 13.9625 16.4999 13.963ZM16.4999 18.181C16.1456 18.1804 15.7995 18.0754 15.5052 17.8793C15.211 17.6832 14.9818 17.4048 14.8467 17.0793C14.7116 16.7538 14.6766 16.3958 14.7461 16.0505C14.8156 15.7053 14.9865 15.3882 15.2373 15.1395C15.488 14.8907 15.8073 14.7214 16.1548 14.6529C16.5023 14.5845 16.8624 14.6199 17.1897 14.7548C17.5169 14.8897 17.7966 15.118 17.9933 15.4108C18.1901 15.7037 18.2951 16.0479 18.2951 16.4C18.2942 16.8727 18.1047 17.3257 17.7681 17.6596C17.4315 17.9935 16.9754 18.181 16.4999 18.181Z"
                    fill="#FBFCFC"
                />
                <path
                    d="M20.6413 14.0286L21.331 13.0446L19.8493 11.5718L18.8593 12.2639C18.5154 12.0657 18.1472 11.9125 17.7637 11.808L17.5525 10.6206H15.457L15.2458 11.808C14.8637 11.9102 14.4966 12.0612 14.1535 12.2574L13.1635 11.5653L11.6818 13.038L12.3715 14.022C12.1705 14.3657 12.0152 14.734 11.9095 15.1175L10.7314 15.3242V17.407L11.8963 17.6136C12.0001 18.004 12.1555 18.379 12.3583 18.7288L11.6983 19.6898L13.18 21.1626L14.1403 20.5066C14.4962 20.7098 14.8765 20.8674 15.2722 20.9756L15.4768 22.117H17.5723L17.7769 20.9756C18.1727 20.8674 18.553 20.7098 18.9088 20.5066L19.8691 21.1626L21.3508 19.6898L20.6908 18.7288C20.891 18.3776 21.0462 18.003 21.1528 17.6136L22.3177 17.407V15.3307L21.1198 15.1241C21.009 14.7398 20.8481 14.3715 20.6413 14.0286ZM21.6313 16.8625L20.572 17.056L20.5225 17.2725C20.4228 17.7385 20.2395 18.1829 19.9813 18.5845L19.8658 18.7714L20.4598 19.621L19.7602 20.3163L18.9088 19.7161L18.7207 19.8342C18.3167 20.0935 17.8699 20.28 17.4007 20.3852L17.1862 20.4311L17.0014 21.4578H16.0114L15.8398 20.4311L15.6253 20.3852C15.1561 20.2803 14.7092 20.0938 14.3053 19.8342L14.1205 19.7161L13.2559 20.3163L12.5398 19.621L13.147 18.7583L13.0282 18.5714C12.7729 18.1684 12.5898 17.7245 12.487 17.2594L12.4375 17.056L11.3881 16.869V15.885L12.4474 15.6981L12.4936 15.4849C12.5995 15.0274 12.7837 14.5913 13.0381 14.1958L13.1569 14.0122L12.5398 13.12L13.2394 12.4246L14.1337 13.0446L14.3185 12.9298C14.7137 12.6813 15.1491 12.5026 15.6055 12.4017L15.82 12.3525L16.0147 11.2832H17.0047L17.1961 12.3525L17.4106 12.4017C17.8672 12.5021 18.3028 12.6808 18.6976 12.9298L18.8857 13.0446L19.78 12.4246L20.4598 13.12L19.8361 14.0056L19.9549 14.1893C20.2116 14.5836 20.3959 15.0201 20.4994 15.4783L20.5489 15.6915L21.6082 15.8785L21.6313 16.8625Z"
                    fill="#FBFCFC"
                />
                <path
                    d="M25.588 19.375L24.7366 19.6374C24.9041 19.2055 25.0332 18.7599 25.1227 18.3057C25.5425 16.1206 25.0723 13.8593 23.8156 12.0185C22.5589 10.1778 20.6184 8.90817 18.4204 8.48865L18.2983 9.14465C20.3218 9.53087 22.1084 10.6993 23.266 12.3935C24.4236 14.0877 24.8575 16.1692 24.4726 18.181C24.3944 18.5677 24.284 18.9473 24.1426 19.3159L23.8753 18.4598L23.245 18.6534L23.8522 20.5951L25.786 20.008L25.588 19.375Z"
                    fill="#FBFCFC"
                />
            </svg>
        )
    }
    const CloseIcon = () => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 12 12"
                fill="none">
                <path
                    d="M1 1L11 11M1 11L11 1"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        )
    }
    return (
        <React.Fragment>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                className="system_notice"
                open={open}>
                <DialogTitle
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'rgb(85, 113, 198)',
                        color: 'white',
                        fontWeight: '500',
                        fontSize: '16px',
                        fontFamily: 'Rubik',
                        height: '42px',
                        // maxWidth: '47em',
                        // paddingRight: '2em',
                        width: '100%'
                    }}
                    sx={{ m: 0, p: 0 }}
                    id="customized-dialog-title">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                        }}>
                        <SVG />
                        <span> Sonora Announcement : {ele?.announcement_title}</span>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        alignSelf: 'center',
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom style={{ whiteSpace: 'pre-wrap' }}>{ele?.content}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            backgroundColor: 'rgb(85, 113, 198)',
                            color: 'white',
                            fontSize: '13px',
                            fontWeight: '500',
                            fontFamily: 'Rubik',
                            cursor: 'pointer',
                            paddingLeft: '62px',
                            paddingRight: '62px'
                        }}
                        autoFocus
                        onClick={handleClose}>
                        Dismiss
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
