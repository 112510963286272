import React, { useEffect } from 'react'
import { Box, Typography, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import CheckboxOption from '../reusable/CheckboxOptions'
import RadioOptions from '../reusable/RadioOption'
import { SelectEl } from '../../../shared/elements'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import MenstruationAndPregnancyHistory from 'containers/PatientsSetting/view/MenstruationAndPregnancyHistory'
import { apiMenstruationAndPregnancyHistory } from 'services/Patient/apiMenstruationAndPregnancyHistory'
import { useNotification } from 'shared/elements/Notification'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
        marginTop: 16,
    },
    sectionGridContainer: {
        marginTop: 10,
    },
    textFieldWrap: {
        margin: '10px 0',
    },
    divider: {
        background: '#E9ECF1',
        height: 1,
    },
})

const mapSections = (master = [], property) => {
    return master.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

const countOptions = [
    { key: 1, value: 1 },
    { key: 2, value: 2 },
    { key: 3, value: 3 },
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
    { key: 9, value: 9 },
    { key: 10, value: 10 },
    { key: 11, value: 11 },
    { key: 12, value: 12 },
    { key: 13, value: 13 },
    { key: 14, value: 14 },
    { key: 15, value: 15 },
    { key: 16, value: 16 },
    { key: 17, value: 17 },
    { key: 18, value: 18 },
    { key: 19, value: 19 },
    { key: 20, value: 20 },
    { key: 'more_20', value: 'more than 20' },
]

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

const MenstruationAndPregnancy = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    patientId,
    encounter_id,
    nonEditable = false
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const sections = mapSections(master?.mensPregHisMasters, 'section_name')

    const handleInputChange = (e, id, sectionName) => {
        const { name, value, checked, type } = e.target

        if (type === 'checkbox') {
            if (sectionName === 'contraception') {
                let updatedValue = values[name]?.contraception_opt
                    ? values[name]?.contraception_opt
                    : []
                updatedValue = checked
                    ? [...updatedValue, id]
                    : updatedValue.filter((uv) => uv !== value)
                onChange(`${fieldName}.${name}.contraception_opt`, updatedValue)
            } else {
                let updatedValue = values[name] ? [...values[name]] : []
                updatedValue = checked
                    ? [...updatedValue, id]
                    : updatedValue.filter((uv) => uv !== value)

                onChange(`${fieldName}.${name}`, updatedValue)
            }
        }
    }

    const getValueRadioButton = (values, fileName) => {
        if (typeof values[`${fileName}`] === 'boolean') {
            return values[`${fileName}`] ? 'Yes' : 'No'
        }
        return values[`${fileName}`]
    }

    const getMenstruationAndPregnancyHistory = async () => {
        try {
            const resp = await apiMenstruationAndPregnancyHistory.getAll(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data?.data) {
                onChange(`${fieldName}.menstruation_pregnancy_history_list`, resp.data.data)
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }

    useEffect(() => {
        if (patientId) {
            getMenstruationAndPregnancyHistory()
        }
    }, [patientId])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <MenstruationAndPregnancyHistory
                isInClinicalNote={true}
                patient_Id={patientId}
                encounter_id={encounter_id}
                afterActionOk={getMenstruationAndPregnancyHistory}
                nonEditable={nonEditable}
            />

            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: master?.mensPregHisMasters,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default MenstruationAndPregnancy
