import React, { useRef, useState } from 'react'
import './Login.scss'
import './ProviderLogin.scss'
import logo from '../../assests/Logos/tm2u_logo_white.png'
import logotm2u from '../../assests/Logos/tm2u_logo_150.png'
import {
    Button,
    Radio,
    RadioGroup,
    FormControl,
    FormHelperText,
    FormControlLabel,
    FormLabel,
    TextField,
    Grid,
    Container,
    CircularProgress,
    InputAdornment,
    IconButton,
    Checkbox,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import backIcon from '../../assests/images/icon_back.png'
import { Formik } from 'formik'
import { ButtonSpinner } from '../custom'
import { CheckBox, Send, Visibility, VisibilityOff } from '@material-ui/icons'
import { IconBack, IconSendOtp } from './Icon'
import IconDisablePassword from '../../assests/images/iconDisablePassword.png'
import IconPassword from '../../assests/images/iconPassword.png'
import { Box } from '@mui/material'
import bg1 from 'assests/images/bg1.png'
import bg2 from 'assests/images/bg2.png'
import bg3 from 'assests/images/bg3.png'
import bg4 from 'assests/images/bg4.png'
import bg5 from 'assests/images/bg5.png'

const bgImages = [bg1, bg2, bg3, bg4, bg5]
// Computation
const randomBgImage = bgImages[Math.floor(Math.random() * bgImages.length)]

const ProviderLogin = (props) => {
    const { onSubmit, error, isOTPRequired, isLoading } = props

    const [showPass, setShowPass] = useState(false)
    const [remember, setRemember] = useState(false)
    const [showVerifyOtp, setshowVerifyOtp] = useState(false)

    const mapErrorCode = (error) => {
        if (error.code === 'NotAuthorizedException') {
            return {
                email: error.message,
                password: error.message,
                otp: null,
            }
        } else if (error.code === 'CodeMismatchException') {
            return {
                email: null,
                password: null,
                otp: error.message,
            }
        }
    }

    const handleChangeRemember = (values) => {
        if (values && remember) {
            localStorage.setItem('RememberPassword', values)
        }
        setRemember(!remember)
    }

    const videoRef = useRef(null)

    const videoHandler = (control) => {
        if (control === 'play') {
            videoRef.current.play()
        } else if (control === 'pause') {
            videoRef.current.pause()
        }
    }

    return (
        <Container className="containerProvider">
            <div className="inner-wrapper-logo-mobile">
                <img className="mobile-logo-header" alt="TeleMed2U" src={logotm2u}></img>
            </div>
            <Grid container className="form-wrapper-provider">
                <Grid item xs={12} sm={6} className="form-video-provider" span={12}>
                    <div className="inner-wrapper-logo">
                        <img className="mobile-logo" alt="TeleMed2U" src={logo}></img>
                    </div>
                    <div
                        className="form-img"
                        style={{
                            background: `url(${randomBgImage})`,
                            backgroundRepeat: 'none',
                            backgroundSize: 'cover',
                        }}></div>
                </Grid>
                <Grid item xs={12} sm={6} className="form-login-provider" span={12} ml={2}>
                    <Formik
                        initialErrors={mapErrorCode(error)}
                        initialValues={{
                            email: '',
                            password: '',
                            otp: '',
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.email) {
                                errors.email = 'Required'
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                                errors.email = 'Invalid email address'
                            }
                            return errors
                        }}
                        onSubmit={(values) => {
                            if (isOTPRequired) {
                                onSubmit(values.otp)
                            }
                            onSubmit(values.email.trim(), values.password.trim())
                        }}>
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form
                                className="form formik-content"
                                noValidate
                                onSubmit={handleSubmit}>
                                <Box className="wrap-lg-top">
                                    {isOTPRequired ? (
                                        <>
                                            <FormControlLabel
                                                className="label--back"
                                                value={'ResendCode'}
                                                control={<IconBack />}
                                                label="Back"
                                            />
                                            <h1 className="align-item-center">
                                                {/* <Link
                                            id="auto-law-back"
                                            to="/">
                                            <img
                                                className="ba-back-icon"
                                                src={backIcon}
                                                alt=""
                                            />
                                        </Link> */}
                                                <span className="main-header">
                                                    Verification Code
                                                </span>
                                                <span className="main-description">
                                                    Lorem Ipsum is simply dummy text
                                                </span>
                                            </h1>
                                            <FormLabel
                                                className="input-label input-label-username input-label-Authentication-code"
                                                component="legend">
                                                Authentication code
                                                <span className="astrik">*</span>
                                            </FormLabel>
                                            <TextField
                                                required
                                                error={Boolean(errors.otp)}
                                                helperText={Boolean(errors.otp) ? errors.otp : null}
                                                variant="outlined"
                                                size="small"
                                                className="text-input"
                                                type="text"
                                                name="otp"
                                                value={values.otp}
                                                placeholder="Enter authentication code"
                                                inputProps={{
                                                    maxLength: 5,
                                                }}
                                                onChange={handleChange}
                                                disabled={false}
                                            />

                                            <h1 className="align-item-center-wrong-code">
                                                <span className="main-wrong-code">
                                                    Wrong code. Try again.
                                                </span>
                                            </h1>

                                            <h1 className="align-item-center-wrong-code">
                                                <span className="main-successful">Successful</span>
                                            </h1>

                                            <FormControlLabel
                                                className="remember-send-otp-label"
                                                value={'ResendCode'}
                                                control={<IconSendOtp />}
                                                label="Resend code"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <h1 className="align-item-center">
                                                {/* <Link
                                            id="auto-law-back"
                                            to="/">
                                            <img
                                                className="ba-back-icon"
                                                src={backIcon}
                                                alt=""
                                            />
                                        </Link> */}
                                                <span className="main-header">Login</span>
                                                <span className="main-description">
                                                    Welcome back! Please enter your details.
                                                </span>
                                            </h1>
                                            <FormLabel
                                                className="input-label input-label-username"
                                                component="legend">
                                                Username
                                                {/* <span className="astrik">
                                                    *
                                                </span> */}
                                            </FormLabel>
                                            <TextField
                                                id="auto-law-phone"
                                                required
                                                error={Boolean(
                                                    errors.email ||
                                                        error.code === 'NotAuthorizedException',
                                                )}
                                                helperText={
                                                    Boolean(
                                                        errors.email ||
                                                            error.code === 'NotAuthorizedException',
                                                    )
                                                        ? errors.email
                                                        : // || error.message
                                                          null
                                                }
                                                value={values.email}
                                                variant="outlined"
                                                size="small"
                                                className="text-input"
                                                type="email"
                                                name="email"
                                                placeholder="Enter Your Email"
                                                inputProps={{
                                                    maxLength: 225,
                                                }}
                                                onChange={handleChange}
                                                disabled={false}
                                            />
                                            <FormLabel className="input-label" component="legend">
                                                Password
                                                {/* <span className="astrik">
                                                    *
                                                </span> */}
                                            </FormLabel>
                                            <TextField
                                                id="auto-law-phone"
                                                required
                                                value={values.password}
                                                error={
                                                    errors.password ||
                                                    error.code === 'NotAuthorizedException'
                                                }
                                                helperText={
                                                    Boolean(
                                                        errors.password ||
                                                            error.code === 'NotAuthorizedException',
                                                    )
                                                        ? errors.password || error.message
                                                        : null
                                                }
                                                variant="outlined"
                                                size="small"
                                                className="text-input"
                                                type={showPass ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Enter Your Password"
                                                InputProps={{
                                                    maxLength: 255,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() =>
                                                                    setShowPass(!showPass)
                                                                }>
                                                                {showPass ? (
                                                                    <img
                                                                        src={IconDisablePassword}
                                                                        width={16}
                                                                        height={16}
                                                                        loading="lazy"
                                                                        alt="App store"
                                                                        className="app-btn"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={IconPassword}
                                                                        width={16}
                                                                        height={16}
                                                                        loading="lazy"
                                                                        alt="App store"
                                                                        className="app-btn"
                                                                    />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={handleChange}
                                                disabled={false}
                                            />
                                            <RadioGroup
                                                className="forgot-password-radio"
                                                id="auto-law-loginType"
                                                aria-label="loginType"
                                                name="loginType"
                                                // onChange={(e) => {
                                                //     handleChange(e);
                                                //     setShowAnotherWayOtpField(
                                                //         false,
                                                //     );
                                                //     setOtpError(
                                                //         false,
                                                //     );
                                                // }}
                                                row={true}>
                                                <FormControlLabel
                                                    className="remember-password-label"
                                                    value={'email'}
                                                    control={
                                                        // <CheckBox color="primary" />
                                                        <Checkbox
                                                            id="check--password"
                                                            name="checkePW"
                                                            color="primary"
                                                            onChange={() =>
                                                                handleChangeRemember(values)
                                                            }
                                                            checked={remember}
                                                        />
                                                    }
                                                    label="Remember password"
                                                />
                                                <Link
                                                    id="forgot-password-btn"
                                                    to="/forget-password">
                                                    Forgot password?
                                                </Link>
                                            </RadioGroup>
                                        </>
                                    )}
                                </Box>
                                <div className="center-align">
                                    {isOTPRequired ? (
                                        <ButtonSpinner
                                            id="auto-law-submit-btn"
                                            type="Login"
                                            label="Log in"
                                            variant="contained"
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                            className={'btn-primary'}
                                        />
                                    ) : (
                                        <ButtonSpinner
                                            id="auto-law-submit-btn"
                                            type="submit"
                                            label="Submit"
                                            variant="contained"
                                            disabled={isLoading}
                                            isLoading={isLoading}
                                            className={'btn-primary'}
                                        />
                                    )}

                                    <h5>
                                        Don’t have an account?{' '}
                                        <Link
                                            className="sign-up-now-link"
                                            id="auto-register-link"
                                            to="/Register">
                                            Sign up now.
                                        </Link>
                                    </h5>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ProviderLogin
