import DescriptionIcon from '@mui/icons-material/Description'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import HtmlTooltip from './HTMLToolTip'
import { useNotification } from 'shared/elements/Notification'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
})

const CopyBar = ({ content = '', handleSelectMacro }) => {
    const classes = useStyles()
    const notification = useNotification()

    function myFunction() {
        navigator.clipboard.writeText(content)
        if (content && handleSelectMacro) {
            handleSelectMacro(content)
        }
        notification('Copied!', 'success')
    }

    return (
        <HtmlTooltip title={'Copy from Previous Note'} arrow placement="top">
            <Grid
                item
                style={{
                    cursor: 'pointer',
                    marginBottom: 16,
                    borderRadius: 4,
                    width: 'fit-content',
                    padding: 8,
                    background: 'rgb(211, 222, 243)',
                }}
                onClick={() => {
                    myFunction()
                }}>
                <Grid container alignItems="center" wrap="nowrap">
                    <DescriptionIcon style={{ color: '#5571c6', marginRight: 8 }} />
                    <Typography className={classes.description}>{content}</Typography>
                </Grid>
            </Grid>
        </HtmlTooltip>
    )
}

export default CopyBar
