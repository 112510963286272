import React, { useEffect, useRef, useState } from 'react'
import { Box, TextField, Typography, Grid, Button, Divider, Popover, IconButton, ListItem, Dialog, FormControlLabel, Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import {
    deleteMedicalProblem,
    getListMedications,
    getListMedicationsType,
    getMedicalProblem,
    getMedicalProblemList,
    putMedicalProblem,
    setPrimaryCarePhysicianPatient,
} from 'services/Patient/patient'
import { LoadingButton } from '@mui/lab'

import { useNotification } from 'shared/elements/Notification'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'
import { stringConcatenation } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'

import { AddCircleOutlineOutlined } from '@material-ui/icons'
import MedicalHistory from '../../../../assests/icons/medicalHistory.svg'
import Skeleton from '@mui/material/Skeleton'
import { Loading } from 'shared/elements/Loading'

import MedicalProblemForm from 'containers/PatientsSetting/view/MedicalProblems/medicalProblemForm'
import moment from 'moment'
import DocumentIcon from 'assests/icons/Document.svg'
import copyNew from 'assests/icons/copyNew.svg'
import AddIcon from 'assests/icons/Add.svg';
import { shortenString } from 'utilities/stringOperations'
import { ReactComponent as CapsuleIcon } from 'assests/icons/MedicalHistoryNew.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { ReactComponent as EditIcon } from 'assests/icons/edit1_icon.svg'
import { ReactComponent as ActiveToInactiveIcon } from 'assests/icons/activeToInactive.svg'
import { ReactComponent as TrashIcon } from 'assests/icons/trash.svg'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { ReactComponent as ReportIcon1 } from 'assests/images/report_icon_1.svg'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        marginLeft: 12,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    itemCard: {
        width: 400,
        height: 500,
    },
    gridContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: '730px',
    },
    gridSurgicalContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: 360,
    },
    gridItem: {
        width: '33.333%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    gridItemShow: {
        width: '50%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        background: '#B0BCE4',
    },
    subText: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },


    first_box:{
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        height: '400px',
        overflow: 'auto',
        padding: '10px',
    },
    second_box:{
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        padding: '10px',
        background: '#FFF',
    },
    copy_button:{
        borderRadius: "4px",
        border: "1px solid #D1D1D1",
        background: "#FFF",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "26px"
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    box_type:{
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '10px',
    },
    filter_btn: {
        width: '170px !important',
        height: '28px',
        fontWeight: 'normal !important',
        borderRadius: '5px !important',
        background: '#15A452 !important',
        color: 'white !important',
        padding: '0px !important',
        textTransform: 'capitalize !important',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '133px',
        borderRadius: '5px',
        border: '1px solid #E4E2E2',
        background: '#F8F9FB',
        padding: '15px 6px 15px 6px',
        marginBottom: '10px',
        marginRight: '10px',
        justifyContent: 'space-between',
        width: '207px',
    },
    displayInbox:{
        display:"flex",
        alignItem: "center",
        justifyContent: "center"
    },
    header:{
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        color: "#303E4E"
    },
    text:{
        fontFamily:"Rubik", fontSize: "14px", fontWeight: "400", color:"#303E4E"
    },
    status:{
        background: "#EBFFF3", 
        color:"#14AE5C", 
        padding:"1px 10px 1px 10px", 
        marginLeft:"2px", 
        fontSize:"13px",
        fontWeight:"400",
        borderRadius: "5px"
    },
    emptyBox:{
        background: '#F8F9FB',
        width:'100%',
        height:"400px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center'
    },
    emptyDocuments:{
        borderRadius: '50%',
        padding: '20px',
        border: '1px solid #667689'
    },
    familyHistoryEmptyText:{
        color: '#303E4E',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '22px',
    },
    copy_button:{
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        background: "#FFF !important",
        fontSize: "12px",
        color:"#303E4E",
        fontWeight: "400",
        height: "24px"
    },
    addIcon:{
        background: "#15A452 !important",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        fontSize: "12px",
        color:"#fff",
        fontWeight: "400",
        height: "24px"
    },
    MedicationCard: {
        width: "322px",
        borderRadius: "5px",
        border: "1px solid #E4E2E2",
        margin:'5px 10px 12px 10px',
        cursor: "pointer"

    },
    cardTextTypography: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '20px',
    },
    cardText: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
    },
    ellipsePopover:{
        '& .MuiPaper-root': {
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)'
        }
    }
})
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const CardComponent = ({ item, onEllipseOpen }) => {
    const classes = useStyles()
    return (
        <Box className={classes.MedicationCard} key={item.id}>
            <div style={{ 
                display: "flex", 
                alignItems: 'center', 
                borderBottom: '1px solid #e0e0e0', 
                justifyContent: 'space-between' ,
                padding:'12px',
                background:'#F8F9FB'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <CapsuleIcon />  
                    <Typography className={classes.cardText}>{capitalizeFirstLetter(shortenString(item.icd_code?.code ,28))}</Typography>
                </div>
                <div onClick={(event) => onEllipseOpen(event, item?.id)}>
                    <MoreVertIcon />
                </div>
            </div>
            <div style={{ 
                padding: '12px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 10,
            }}>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Status: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400, color: '#15A452'}}
                    >{capitalizeFirstLetter(shortenString(item.status?.code ,22))}</Typography>
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Diagnosis: </Typography>
                    {item.icd_code?.display?.length > 28 
                    ?
                        <HtmlTooltip
                            arrow
                            title={item.icd_code?.display}
                            placement="top">
                            <Typography
                                className={classes.cardTextTypography}
                                style={{fontWeight: 400}}
                            >{shortenString(item.icd_code?.display, 27)}</Typography>
                        </HtmlTooltip>
                    :
                        <Typography
                            className={classes.cardTextTypography}
                            style={{fontWeight: 400}}
                        >{shortenString(item.icd_code?.display,27)}</Typography>
                    }
                </div>
                <div style={{ display: "flex",gap: 5 }}>
                    <Typography
                        className={classes.cardTextTypography}
                    >Onset: </Typography>
                    <Typography
                        className={classes.cardTextTypography}
                        style={{fontWeight: 400}}
                    >{item?.time_onset ? moment(item?.time_onset ).format('MM/DD/YYYY') : '---'}</Typography>
                </div>
            </div>

        </Box>
    )
}

const MedicalProblemListNew = ({
    fieldName,
    master,
    values,
    onChange,
    primary_care_physician,
    macros,
    patientId,
    encounter_id,
    handleSetViewHealthyRecord,
    openForm,
    setCloseForm,
    active,
    atTheEnd,
    InactiveEnd,
    ActiveEnd
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const classesCard = usePatientDashboardStyles()

    const healthProblemMaster = master?.healthProbMasters || []
    const surgicalAreaMaster = master?.surgicalAreaMasters || []
    const [medicalProblemList, setMedicalProblemList] = React.useState([])
    const [anchorElStaffNote, setAnchorElStaffNote] = React.useState(openForm)
    const [showEmpty, setShowEmpty] = React.useState(false)
    const [problemID, setProblemId] = React.useState(false)
    const [dataLoading, setDataLoading] = React.useState(false)
    const [itemId, setItemid] = useState(0)
    const [medicationId, setMedicationId] = useState(null)
    const [ellipseAnchorEl, setEllipseAnchorEl] = useState(null)
    const ellipseOpen = Boolean(ellipseAnchorEl),
    ellipseEndId = ellipseOpen ? 'simple-popover' : undefined

    const [anchorElOpen, setAnchorElOpen] = useState(null)
    const [activationAnchorEl, setActivationAnchorEl] = useState(null)

    const handleCloseStaffNote = () =>{   
        setCloseForm(false)
        setProblemId(null)
        setItemid(null)
        // setAnchorElStaffNote(null)        
    }
    const scrollableContentRef = useRef(null)
    const getMedialProblemList = async () => {
        try {
            setDataLoading(true)
            const resp = await getMedicalProblemList(patientId, 1, 1000)
            const ActiveList =  resp.data.data.length > 0 ? resp.data.data.filter((item)=>(item.status.code === (active? 'inactive':'active'))) : []
            setDataLoading(false)
            if(ActiveList.length <= 6 ){
                atTheEnd(true)
            }
            setMedicalProblemList(ActiveList)
            const list = resp.data.data.map((item) => {
                    return {
                        medical_problem: item.icd_code?.display || '',
                        icd10_code: item.icd_code?.code || '',
                        onset_condition: item?.time_onset ? formatDateTime(item?.time_onset, 'MM/DD/YYYY') : "",
                        // date_changed: formatDateTime(item.time_changed, 'MMM DD, YYYY'),
                        date_changed: '',
                        status:  item.status?.code.charAt(0).toUpperCase() + item.status?.code.slice(1).toLowerCase() || '',
                        note: item.note || '',
                    }
            })
            onChange(list)
        } catch (ex) {
            setDataLoading(true)
            notification(`Cannot get list problem!`, 'error')
        }
    }
    useEffect(() => {
        if (patientId) {
            getMedialProblemList()
        }
    }, [patientId,active])

    useEffect(() => {
        if (primary_care_physician)
            onChange(`${fieldName}.primary_care_physician`, primary_care_physician)
    }, [primary_care_physician])

    const onEllipseOpen = (event, itemId) => {
        setItemid(itemId)
        setEllipseAnchorEl(event?.currentTarget)
    }
    const onEllipseClose = () => {
        setEllipseAnchorEl(null)
        setItemid(0)
    }
    const handleDeleteOpen = (event,medicationId) => {
        setAnchorElOpen(event.currentTarget)
        setMedicationId(medicationId)
    };
    const handleDelete = async () => {
        try {
            onEllipseClose()
            setAnchorElOpen(null)
            setMedicationId(null)
            setDataLoading(true)
            await deleteMedicalProblem(patientId, medicationId)
            setDataLoading(false)
            notification(`Problem Deleted Successfully!`, 'success')
            getMedialProblemList()
        } catch (error) {
            setDataLoading(false)
            const errMsg =
                error?.data?.message || `Something went wrong when deleting problem!`
            notification(errMsg, 'error')
        }
    }
    const changeActivationStatus = async() => {
        onEllipseClose()
        setActivationAnchorEl("")
        setMedicationId(null)
        setDataLoading(true)
        getMedicalProblem(patientId, medicationId)?.then(async (response) => {
            const payload = {
                ...response?.data,
                status:{code: !active? 'inactive' : 'active'}
            }
            await putMedicalProblem(patientId, medicationId, payload)
            setDataLoading(false)
            getMedialProblemList()
        })?.catch(err => {
            setDataLoading(false)
            notification('Something went wrong!', 'error')
        })?.finally(() => {
            
            setMedicationId(null)
        })
    }
    const handleScroll = (event) =>{
        const scrollableContent = scrollableContentRef?.current
        const tolerance = 2
        const isAtEnd =
            scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
            scrollableContent?.clientHeight + tolerance
        if(isAtEnd){
            atTheEnd(true)
        }        
    }
    return (
        <Grid item>
            <Loading visible={dataLoading} />
            <Grid container item alignItems="flex-start" spacing={2} style={{maxHeight: "400px", overflowY: "auto", padding: '10px' }} onScroll={handleScroll} ref={(ref) => (scrollableContentRef.current = ref)}>
                    {medicalProblemList?.length > 0 &&
                        <Grid container justifyContent='space-between'>
                            <HtmlTooltip
                                    arrow
                                    title={'Scroll down and check all problems'}
                                    placement="top">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={active ? InactiveEnd : ActiveEnd}
                                            disabled={true}
                                            name={'Reviewed by Provider'}
                                        />
                                    }
                                    label={
                                        <li>
                                            <span
                                                style={{
                                                    marginRight: '3px',
                                                    color: '#303E4E',
                                                    fontSize:'14px'
                                                }}>
                                                Reviewed by Provider
                                            </span>
                                        </li>
                                    }
                                />
                            </HtmlTooltip>
                            <Typography style={{color: '#667689',fontFamily: "Rubik",fontSize: "14px",fontStyle: "normal",fontWeight: "400",lineHeight: "20px",}}>Total: {medicalProblemList.length}</Typography>
                        </Grid>
                    }

                        {medicalProblemList?.length > 0 && medicalProblemList?.map((item, index) => (
                            <Box style={{ 
                                display: "flex", 
                                flexWrap: "wrap", 
                                alignItems: "center", 
                                gap: "10px",
                                overflowY: "auto" }}>
                                <CardComponent key={index} item={item} onEllipseOpen={onEllipseOpen}/>
                            </Box>
                           
                        ))}
                     
                    {medicalProblemList.length === 0 &&
                        <Grid item className={classes.emptyBox}>
                            <div>
                                <div style={{display:'flex', justifyContent:'center'}}>
                                    <div className={classes.emptyDocuments}>
                                        <img src={DocumentIcon} alt=''/>
                                    </div>
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    <Typography className={classes.familyHistoryEmptyText}>Problem list is empty</Typography>
                                    <Typography className={classes.familyHistoryEmptyText} style={{fontWeight:400, marginTop: '10px'}}>
                                        Add new problem list or copy from the previous note
                                    </Typography>
                                    <div style={{display:'flex',alignItems:'center', justifyContent:'center',gap: '10px', marginTop: '25px'}}>
                                        <Button
                                            startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                                            className={classes.copy_button}
                                            disabled={true}
                                            onClick={() => {
                                            }}
                                            style={{fontWeight: 500}}
                                            >
                                            {' '}
                                            {"Copy"} {'01/01/2023'}
                                        </Button>
                                        <Button
                                            startIcon={<img src={AddIcon} alt="" style={{ height: '15px' }} />}
                                            className={classes.addIcon}
                                            onClick={() => {
                                                setCloseForm(true) 
                                            }}
                                            style={{fontWeight: 500}}
                                            >
                                            Add medical problem
                                        </Button>
                                    </div>
                                </div>
                            
                            </div>
                        </Grid>
                    }
            </Grid>

            <Popover
                id={ellipseEndId}
                open={ellipseOpen}
                anchorEl={ellipseAnchorEl}
                onClose={onEllipseClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                className={classes.ellipsePopover}
            >
                <Box style={{ padding: '12px 0px', display: 'flex', flexDirection: 'column', gap: 12 }}>
                    <ListItem
                        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                        onClick={(event) => {setCloseForm(true), setEllipseAnchorEl(null), setProblemId(itemId)}}>
                        <EditIcon />
                        <Typography>Edit</Typography>
                    </ListItem>

                    <ListItem
                        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                        onClick={(event) => {setActivationAnchorEl(event?.currentTarget),setMedicationId(itemId)}}>
                        <ActiveToInactiveIcon />
                        <Typography>{`Move to ${!active ? 'inactive' : 'active'}`}</Typography>
                    </ListItem>

                    <ListItem
                        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                        onClick={(event)=>{handleDeleteOpen(event,itemId)}}
                        >
                        <TrashIcon />
                        <Typography>Remove</Typography>
                    </ListItem>
                </Box>
            </Popover>
            
            <CustomAlertPopOver
                anchorEl={anchorElOpen}
                handleClose={() => {
                    setAnchorElOpen(null)
                    setMedicationId(null)
                }}
                icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                title="Problem Deletion"
                bodyText={`Are you sure you want to delete this problem from the patient's record?`}
                confirmButtonName={'Delete'}
                cancelButtonName={'Cancel'}
                confirmButtonColor={'red'}
                onSuccess={handleDelete}
                buttonProps={{
                    styleConfirm: {
                        textTransform: 'capitalize',
                        fontWeight: "500",
                        display: "flex",
                        width: "68px",
                        height: "32px",
                        padding: "8px 20px",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "5px",
                        fontSize: "15px",
                        color: "#FFF",
                        textAlign: "center",
                        fontFamily: "Rubik",
                        fontStyle: "normal",
                        lineHeight: "24px",
                        ':hover': {
                            boxShadow: "none"
                        }
                    },
                    styleCancel: {
                        display: "flex",
                        height: "32px",
                        padding: "6px 12px",
                        alignItems: "center",
                        textAlign: "center",
                        fontFamily: "Rubik",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "20px",
                        textTransform: 'none',
                    }
                }}
            />
            

            <CustomAlertPopOver
                    anchorEl={activationAnchorEl}
                    handleClose={() => {
                        setActivationAnchorEl(null)
                    }}
                    sx={{
                        '& .MuiButtonBase-root': {
                            borderRadius: '4px !important'
                        }
                    }}
                    icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                    title={`Move problem to ${!active ? 'inactive' : 'active'}`}
                    bodyText={`Are you sure you want to make this problem ${!active ? 'inactive' : 'active'}?`}
                    confirmButtonName={'Yes'}
                    confirmButtonColor={'red'}
                    onSuccess={changeActivationStatus}
                    buttonProps={{
                        styleConfirm: {
                            textTransform: 'capitalize',
                            fontWeight: "500",
                            display: "flex",
                            width: "68px",
                            height: "32px",
                            padding: "8px 20px",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                            fontSize: "15px",
                            color: "#FFF",
                            textAlign: "center",
                            fontFamily: "Rubik",
                            fontStyle: "normal",
                            lineHeight: "24px",
                            ':hover': {
                                boxShadow: "none"
                            },
                        },
                        styleCancel: {
                            display: "flex",
                            height: "32px",
                            padding: "6px 12px",
                            alignItems: "center",
                            textAlign: "center",
                            fontFamily: "Rubik",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            textTransform: 'none',
                        }
                    }}
                />




            <Dialog
                open={openForm}
                onClose={handleCloseStaffNote}
                >
                <MedicalProblemForm handleCloseStaffNote={handleCloseStaffNote} patientId={patientId} problemId={problemID} onClose={handleCloseStaffNote} afterActionOk={getMedialProblemList} encounter_id={encounter_id}/>
            </Dialog>
        </Grid>
    )
}

export default MedicalProblemListNew
