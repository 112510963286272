import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Select, MenuItem } from '@material-ui/core'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'
import { ExpandMore } from '@material-ui/icons'
import TextWithMacro from '../reusable/TextWithMacro'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'
import { isString } from 'lodash-es'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        width: "289px",
        color: '#667689',
    },
    CentralizedMacrosText:{
        color: "#667689",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
        margin: "10px 7px 7px 7px"
    }
})

const NarrativeAndMacro = ({
    descriptionComponent,
    hideTextNarrative,
    hideClearBtn,
    hideGenerateBtn,
    description,
    onChange,
    fieldName,
    onGenerateClick,
    meta,
    values,
    placeholder = '',
    customNarrative,
    handleCustomNarrative,
    isShowCopyButton,
    hideInsertMacro,
    lastNarrative,
    copyAble,
    copyValues,
    textCharecterlimit = true,
    nonEditable = false
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedMacro, setSelectedMacro] = useState('')
    const [macro, setMacro] = useState('')
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]


    const handleGenerateClick = () => {
        if (customNarrative) {
            return handleCustomNarrative(macro)
        }
        const result = narratives[fieldName]?.(meta)
        const identifier = result + "\n\n -------Additional Provider comments and analysis below this line-------\n"
        const include = "-------Additional Provider comments and analysis below this line-------\n"
        if (values?.narrative?.includes(include)) {
            const placeholderIndex = (values?.narrative).indexOf(include)
            const textAfterPlaceholder = (values?.narrative).substring(placeholderIndex + include.length);
            onGenerateClick(`${fieldName}.narrative`, `${result ? `${identifier}` : ''}${textAfterPlaceholder}`)
        } else {
            onGenerateClick(`${fieldName}.narrative`, `${result ? `${identifier}` : ''}${values?.narrative ? values?.narrative : ""}`)
        }

    }

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    // useEffect(() => {
    //     if (!hideGenerateBtn) {
    //         handleGenerateClick()
    //     }
    // }, [`${fieldName}.narrative`])

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()
            if (!copyValues.narrative) {
                reject()
            } else {
                onChange(fieldName, copyValues)
                resolve()
            }
        })
    }

    return (
        <Grid item>
            {!nonEditable ? copyAble ? (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            ) : (
                isShowCopyButton && (
                    <CopyBar handleSelectMacro={handleSelectMacro} content={lastNarrative} />
                )
            ) : ""}
            {descriptionComponent && descriptionComponent}
            <Grid
                className={classes.actionGrid}
                container
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center">
                {!hideTextNarrative ? (
                    <Typography className={classes.narrative}>Narrative</Typography>
                ) : (
                    ''
                )}

                {description && (
                    <Typography className={classes.description}>{description}</Typography>
                )}
                <Grid item>
                    <Grid container alignItems="center">
                        <Box
                            sx={{
                                '& .MuiSelect-selectMenu': {
                                    background: '#F3F6F9',
                                    padding: '7px 30px 7px 16px',
                                    borderRadius: '6px',
                                },
                                '& .MuiInput-root': {
                                    marginRight: '8px',
                                },
                            }}>
                            <Select
                                disableUnderline
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 505,
                                            maxWidth: 300,
                                            marginTop: 8,
                                        },
                                    },
                                }}
                                IconComponent={ExpandMore}
                                name="macro"
                                onChange={(e) => {
                                    if (!isString(e.target.value)) return

                                    setSelectedMacro(e.target.value)
                                    const parts = e.target.value?.split('.')
                                    const id = parseInt(parts[0])
                                    const name = parts[1]
                                    setMacro((prev) => {
                                        const description = allMacros.find(
                                            (macro) => macro.name === name && macro.id === id
                                        )?.description
                                        return `${prev}${description} `
                                    })
                                }}
                                disabled={nonEditable}
                                value={selectedMacro}
                                displayEmpty
                                renderValue={(sel) =>
                                    sel ? (
                                        <span className={classes.btn__macro}>
                                            {/* Insert Macro:{' '}
                                            {macrosData.find((item) => item.id === sel)?.name} */}
                                            Insert Macro
                                        </span>
                                    ) : (
                                        <span className={classes.btn__macro}>Insert Macro</span>
                                    )
                                }>
                                <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                                {macrosPractitionerData?.map((m) => (
                                    <MenuItem
                                        onClick={() => {handleSelectMacro(m.description)}}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={`${m.id}.${m.name}`}
                                        >
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                                <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                                {macrosData?.map((m) => (
                                    <MenuItem
                                        onClick={() => handleSelectMacro(m.description)}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={`${m.id}.${m.name}`}>
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        {!hideGenerateBtn && (
                            <Button
                                disabled={nonEditable}
                                onClick={() => {
                                    handleGenerateClick()
                                }}
                                className={classes.btn__generate}
                                variant="contained">
                                Generate
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <TextWithMacro
                name={`${fieldName}.narrative`}
                onChange={(fieldName, text) => {
                    if (!text) {
                        setMacro('')
                        setSelectedMacro('')
                    }
                    onChange(fieldName, text)
                }}
                // macros={macros}
                textCharecterlimit={textCharecterlimit}
                rows={12}
                values={values?.narrative}
                placeholder={placeholder}
                isDisabled={nonEditable}
            />
        </Grid>
    )
}

export default NarrativeAndMacro
