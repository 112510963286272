import React, { useCallback, memo, useState, useEffect } from 'react'
import { FormLabel, TextField, TextFieldProps } from '@material-ui/core'
import { Box } from '@mui/material'
import { debounce } from 'lodash'

interface Props {
  label: string
  required?: boolean
  concurrency?: string
  max?: number
  onChange?: (value: string) => void
}

const concurrencyRegxep = /^\${0,1}(((\d{1,3})(,\d{3})+|\d{0,3})|\d+)(\.\d{0,2})?$/
const concurrencyFormat = /(\d)(?=(\d{3})+(?!\d))/g // -> $1,

/** Concurrency input for US Dollar - $
 * 
 * TODO: Update for another concurrency format if needed
 */
export const ConcurrencyInput = memo(
  ({ label, required, concurrency = '$', max = 100000000, ...props }: TextFieldProps & Props) => {
    const [text, setText] = useState(props.value as string)

    const maxInputLength = max.toString().length

    const dispatchChangeEvent = debounce((value: string) =>
      props.onChange && props.onChange(value), 0)

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      let newVal = (e.target.value).replace(/[,$]/g, '')

      if (!newVal.length) return setText(newVal)

      if (/\.\d{0,2}$/.test(newVal)) {
        if (newVal.length > maxInputLength + 3) return // Max 1,000,000,000.99, + 3 characters length for .99$
      } else if (newVal.length > maxInputLength) return

      if (!concurrencyRegxep.test(newVal)) return

      // Remove preceding $
      if (newVal[0] === '$') newVal = newVal.substring(1)

      setText('$' + newVal.replace(concurrencyFormat, '$1,'))
    }, [props.value])

    useEffect(() => {
      const preservedValue = props.value as string
      if (preservedValue !== text) {
        setText(preservedValue)
      }
    }, [props.value])

    return (
      <>
        <FormLabel
          component="p"
          className="input-form-label">
          <span className={!!required ? 'required' : ''}>
            {label || 'Concurrency'}
          </span>
        </FormLabel>

        <Box>
          <TextField
            variant="outlined"
            style={{ height: 'fit-content' }}
            fullWidth
            {...props}
            placeholder="$0.00"
            value={text || ''}
            onChange={handleChange}
            error={true}
            helperText={props.helperText}
            onBlur={() => dispatchChangeEvent(text)}
          />
        </Box>
      </>
    )
  }
)
