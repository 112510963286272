import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState(null)

    const openModal = (data) => {
        setModalData(data)
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setModalData(null)
        setIsModalOpen(false)
    }

    return (
        <ModalContext.Provider value={{ isModalOpen, modalData, openModal, closeModal }}>
            {children}
        </ModalContext.Provider>
    )
}

export const useModal = () => {
    return useContext(ModalContext)
}
