import React from "react";

export default function TeleVolumnIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.57129 10.0555V13.9444C3.57129 15.8889 4.54351 16.8611 6.48796 16.8611H7.87823C8.23796 16.8611 8.59768 16.968 8.90879 17.1528L11.7477 18.9319C14.1977 20.468 16.2102 19.35 16.2102 16.4625V7.53748C16.2102 4.64026 14.1977 3.53193 11.7477 5.06804L8.90879 6.84721C8.59768 7.03193 8.23796 7.13887 7.87823 7.13887H6.48796C4.54351 7.13887 3.57129 8.11109 3.57129 10.0555Z" stroke="white" strokeWidth="1.5"/>
<path d="M19.127 8.11108C20.8575 10.4152 20.8575 13.5847 19.127 15.8889" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
}
