import React, { useEffect, useState } from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import { DataGrid, GridColumns, GridSortItem } from '@mui/x-data-grid'
import { connect } from 'react-redux'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { createKeyword, deleteKeyword, getKeyword, updateKeyword } from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'

import { CreateMediaKeywordDialog } from './CreateMediaKeywordDialog'
import { MediaKeyword } from './interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'


const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    text_clinical_note: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    text_show_more: {
        color: 'rgba(0, 118, 252, 1)',
        marginLeft: 4,
        cursor: 'pointer',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const MediaKeywordSettings = () => {
    const classes = useStyles()
    const notification = useNotification()

    const [sortModel, setSortModel] = useState([{
        field: '',
        sort: '',
    }])

    const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [mediaKeywordModal, setMediaKeywordModal] =
        useState<{
            status: boolean
            itemId: number | null | undefined
            item: MediaKeyword | null | undefined
        }>({
            status: false,
            itemId: null,
            item: null
        })
   
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })
    const handlePageChange = (value: number) => {
        setPage(value)
    }

    const mediaKeywordModalToggle = (row?: MediaKeyword) => {
        setMediaKeywordModal({
            status: !mediaKeywordModal.status,
            itemId: row?.id,
            item: row,
        })
    }

    const columns: GridColumns<MediaKeyword> = [
        {
            headerName: (
                <Typography variant="body1">
                    Keyword
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'keyword',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Actions
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.1,
            sortable: false,
            renderCell: ({ row }) =>
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => mediaKeywordModalToggle(row)
                        },
                        {
                            text: 'Delete',
                            handler: () => handleDeleteKeyword(row.id as number)
                        }
                    ]}
                    deleteMessage='Are you sure you want to delete this Media Keyword?'
                />
        },
    ]

    const handleSubmit = async (payload: MediaKeyword) => {
        try {
            setIsSubmitting(true)

            if (mediaKeywordModal?.itemId) {
                await updateKeyword({
                    id: mediaKeywordModal?.itemId,
                    payload,
                })
                    .then(() => {
                        notification(
                            'A Media Keyword was successfully updated!',
                            'success'
                        )
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message ||
                            'A Media Keyword was not successfully updated!',
                            'error'
                        )
                    })
            } else {
                await createKeyword(payload)
                    .then(() => {
                        notification(
                            'A Media Keyword was successfully added!',
                            'success'
                        )
                    })
                    .catch((error) => {
                        notification(
                            error?.data?.message ||
                            'A Media Keyword was not successfully added!',
                            'error'
                        )
                    })
            }

            mediaKeywordModalToggle()
            setIsSubmitting(false)
            refetchMediaKeyword()
        } catch (error) {
            console.log({ error })
            setIsSubmitting(false)
        }
    }

    const handleDeleteKeyword = async (keywordId: number) =>
        deleteKeyword(keywordId)
            .then(() => {
                refetchMediaKeyword()
                notification(
                    'A Media Keyword was successfully deleted!',
                    'success'
                )
            })
            .catch((error) => {
                notification(
                    error?.data?.message ||
                    'A Media Keyword was not successfully deleted!',
                    'error'
                )
            })

    const refetchMediaKeyword = async () => {
        setLoading(true)
        try {
            const res = await getKeyword(sortModel[0]?.sort ?
                { page, size: paginationData.limit, [sortModel[0].sort]: sortModel[0].field } :
                { page, size: paginationData.limit })
            setPatientList(res.data?.data || [])
            if (res.data?.paging) setPaginationData(res.data.paging)
        } catch (err) {
            console.error('Cannot fetch media keyword', err)
        }

        setLoading(false)
    }

    useEffect(() => {
        const abortController = new AbortController()
        refetchMediaKeyword()

        return () => abortController.abort()
    }, [page, sortModel])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent="space-between">
                    <Typography className={classes.header}>
                        Media Keywords
                    </Typography>

                    <Grid item>
                        <ButtonCustom
                            tooltip="Add New Media Keyword"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                mediaKeywordModalToggle()
                            }
                            fullWidth>
                            <AddIcon
                                style={{
                                    fontSize: 20,
                                }}
                            />
                        </ButtonCustom>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator':
                            {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle':
                            {
                                '& .MuiTypography-body1':
                                {
                                    display: 'flex',
                                    alignItems:
                                        'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize:
                                        '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext':
                        {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected':
                        {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader':
                        {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                        {
                            width: 'auto',
                            visibility: 'visible',
                        },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id as number}                     
                        sortingMode="server"
                        onSortModelChange={(newSortModel) =>setSortModel(newSortModel)}
                        rows={patientList}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={paginationData?.total}
                        pagination
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl />
                            ),
                            Pagination: () => <Pagination
                                totalRecords={paginationData.total}
                                onPageChange={handlePageChange} />,
                            ColumnUnsortedIcon:
                                TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon:
                                KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon:
                                KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {mediaKeywordModal.status && (
                <CreateMediaKeywordDialog
                    open={mediaKeywordModal.status}
                    mediaKeyword={mediaKeywordModal.item}
                    loading={isSubmitting}
                    onClose={mediaKeywordModalToggle}
                    onSubmit={handleSubmit}
                />
            )}
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(
    mapStateToProps,
    null,
)(MediaKeywordSettings)
