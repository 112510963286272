import { ClickAwayListener, Grid, makeStyles, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import SelectEl from './SelectEl'

const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
    },
    drop__down: {
        position: 'absolute',
        top: 44,
        left: 0,
        background: '#FFFFFF',
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '8px',
        padding: 16,
        zIndex: 1,
    },
    input_box: {
        background: '#F6F7F9',
        border: '1px solid #E9ECF1',
        borderRadius: '5px',
        height: 40,
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 12px',
        cursor: 'pointer',
    },
    label: {
        fontWeight: 500,
        marginBottom: 8,
        color: '#303E4E',
    },
}))

const hourOptions = [
    { key: '0', value: '12 AM' },
    { key: '1', value: '1 AM' },
    { key: '2', value: '2 AM' },
    { key: '3', value: '3 AM' },
    { key: '4', value: '4 AM' },
    { key: '5', value: '5 AM' },
    { key: '6', value: '6 AM' },
    { key: '7', value: '7 AM' },
    { key: '8', value: '8 AM' },
    { key: '9', value: '9 AM' },
    { key: '10', value: '10 AM' },
    { key: '11', value: '11 AM' },
    { key: '12', value: '12 PM' },
    { key: '13', value: '1 PM' },
    { key: '14', value: '2 PM' },
    { key: '15', value: '3 PM' },
    { key: '16', value: '4 PM' },
    { key: '17', value: '5 PM' },
    { key: '18', value: '6 PM' },
    { key: '19', value: '7 PM' },
    { key: '20', value: '8 PM' },
    { key: '21', value: '9 PM' },
    { key: '22', value: '10 PM' },
    { key: '23', value: '11 PM' },
]
const minuteOptions = [
    { key: '0', value: '00' },
    { key: '05', value: '05' },
    { key: '10', value: '10' },
    { key: '15', value: '15' },
    { key: '20', value: '20' },
    { key: '25', value: '25' },
    { key: '30', value: '30' },
    { key: '35', value: '35' },
    { key: '40', value: '40' },
    { key: '45', value: '45' },
    { key: '50', value: '50' },
    { key: '55', value: '55' },
]

interface PropsTimePicker {
    onChange?: (value: Date | Moment) => void
    busySlots?: string[]
    values?: Date | Moment
    error?: string
    helperText?: string
    labelDropdown?: string
    disabled?: boolean
}

function TimePicker({
    onChange,
    busySlots = [],
    values,
    error,
    helperText,
    labelDropdown,
    disabled,
}: PropsTimePicker) {
    const classes = useStyles()
    const [startTime, setStartTime] = React.useState<{
        hour: string | number
        min: string | number
    }>({
        hour: '0',
        min: '0',
    })
    const [date, setdate] = useState(moment(values).clone().format('MM/DD/YYYY'))

    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])

    const onChangeStart = (e) => {
        const { name, value } = e.target
        setStartTime((startTimePrev) => ({
            ...startTimePrev,
            [name]: value,
        }))

        const paramTime =
            name === 'hour' ? `${value}:${startTime.min}` : `${startTime.hour}:${value}`

        onChange && onChange(moment(`${date} ${paramTime}`, 'MM/DD/YYYY HH:mm'))
    }

    useEffect(() => {
        setdate(moment(values).clone().format('MM/DD/YYYY'))
        const time = moment(values).local()
        setStartTime({
            hour: time.hours(),
            min: time.minute() === 0 ? '0' : time.minute() === 5 ? '05' : time.minute(),
        })
    }, [values])

    const handleClickAway = () => {
        setOpen(false)
    }

    const onClickPreventDefault = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Grid container className={classes.container} onClick={onClickPreventDefault}>
                <Grid
                    container
                    className={classes.input_box}
                    justifyContent="space-between"
                    onClick={() => {
                        setOpen(!open)
                    }}
                    alignItems="center"
                    style={{
                        opacity: disabled ? 0.7 : 1,
                        pointerEvents: disabled ? 'none' : 'initial',
                    }}>
                    <Typography>{`${moment(`${startTime.hour}:${startTime.min}`, 'HH:mm').format(
                        'hh:mm A',
                    )} `}</Typography>

                    {open ? (
                        <KeyboardArrowUpIcon
                            style={{
                                color: '#516983',
                                fontSize: 20,
                            }}
                        />
                    ) : (
                        <KeyboardArrowDownIcon
                            style={{
                                color: '#516983',
                                fontSize: 20,
                            }}
                        />
                    )}
                </Grid>
                {open && (
                    <Grid container className={classes.drop__down}>
                        {labelDropdown && (
                            <Typography className={classes.label}>{labelDropdown}</Typography>
                        )}
                        <Grid container item alignItems="center" spacing={4}>
                            <Grid item style={{ flex: 1 }}>
                                <SelectEl
                                    name="hour"
                                    placeholder="Select from below"
                                    value={startTime.hour}
                                    onChange={onChangeStart}
                                    options={hourOptions.map((item) => ({
                                        ...item,
                                        // disabled:
                                        //     moment(values).date() <= moment().date() &&
                                        //     parseInt(item.key, 10) < moment().hour(),
                                        // disabled: (() => {
                                        //     if (moment(values).date() < moment().date()) {
                                        //         return true
                                        //     } else if (moment(values).date() === moment().date()) {
                                        //         if (parseInt(item.key, 10) < moment().hour()) {
                                        //             return true
                                        //         }
                                        //     }
                                        //     return false
                                        // })(),
                                        disabled: (() => {
                                            const date = moment(values)
                                            const currentDate = moment()
                                            if (date.isBefore(currentDate, 'day')) return true
                                            if (date.isSame(currentDate, 'day')) {
                                                if (currentDate.hour() > parseInt(item.key))
                                                    return true
                                                return false
                                            }
                                            return false
                                        })(),
                                    }))}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                />
                            </Grid>
                            <Typography style={{ fontSize: 18 }}>:</Typography>
                            <Grid item style={{ flex: 1 }}>
                                <SelectEl
                                    name="min"
                                    placeholder="Select from below"
                                    value={startTime.min}
                                    options={minuteOptions.map((item) => ({
                                        ...item,

                                        // disabled: (() => {
                                        //     if (moment(values).date() < moment().date()) {
                                        //         return true
                                        //     }
                                        //     if (
                                        //         parseInt(item.key, 10) <= moment().minute() &&
                                        //         moment(values).date() === moment().date() &&
                                        //         parseInt('' + startTime.hour, 10) <= moment().hour()
                                        //     ) {
                                        //         return true
                                        //     }
                                        //     return false
                                        // })(),
                                        disabled: (() => {
                                            const date = moment(values)
                                            const currentDate = moment()
                                            if (date.isBefore(currentDate, 'day')) return true
                                            if (date.isSame(currentDate, 'day')) {
                                                if (
                                                    currentDate.hour() >= date.hour() &&
                                                    currentDate.minute() > parseInt(item.key)
                                                )
                                                    return true
                                                return false
                                            }
                                            return false
                                        })(),
                                    }))}
                                    onChange={onChangeStart}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {error && (
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                        {helperText}
                    </p>
                )}
            </Grid>
        </ClickAwayListener>
    )
}

export default TimePicker
