import { IconButton, InputAdornment, Popover, FilledInput, FormControl, FormGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import SearchIcon from 'assests/icons/search_icon.svg'
import { _getClinicalNotesFilterProvider } from '../api/services'
import { useDebounce } from 'shared/hooks'
import './style.scss'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { LoadingButton } from 'components/custom/LoadingButton'
import { STATUSES } from '../constants'


const isStatusAllowed = (status) => {
    const allowedStatuses = [STATUSES.draft, STATUSES.resendEdit, STATUSES.inProgress]

    return allowedStatuses.includes(status)
}


const RequestApprove = ({ supervisor, status, handleRequestApprove, requestingApproval }) => { 
    const [filterEl, setFilterEl] = React.useState(null);
    const [providerData, setProviderData] = React.useState([]);
    const [searchTextProvider, setSearchTextProvider] = React.useState("")
    const debouncedSearchFilterTextProvider = useDebounce(searchTextProvider, 500)
    const [currentPageProvider, setCurrentPageProvider] = React.useState(null);
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = React.useState(null);
    const [provider, setProvider] = React.useState({});
    const [loadingOption, setLoadingOption] = React.useState(false)
    const scrollableContentRefProvider = useRef(null);
    const handleFilterClick = (event) => {
        if (isStatusAllowed(status)) {
            setFilterEl(filterEl ? null : event.currentTarget);
        }
    };

    const handleFilterClose = () => {
        setFilterEl(null);
    };


    const openFilter = Boolean(filterEl);
    const id = openFilter ? 'simple-popper' : undefined;
    const handleLoadDataProvider = async (name = '') => {
        try {
            if (isStatusAllowed(status)) {
                const payload = {
                    page: 1,
                    limit: 20,
                    name: searchTextProvider,
                };
                setLoadingOption(true)
                const res = await _getClinicalNotesFilterProvider(payload);
                const tempUserList = [];
                res?.data?.data?.data?.map((item) =>
                    tempUserList.push({ label: item?.name, id: item?.id })
                );
                setProviderData(tempUserList);
                setCurrentPageProvider(1);
                const b = res?.data?.data?.total / 20;
                const remainder = res?.data?.data?.total % 20;
                let result;
                if (remainder > 0) {
                    result = Math.floor(b) + 1;
                } else {
                    result = Math.floor(b);
                }
                setTotalPageNoOfProvider(result)
            }
        } catch (e) {
            console.log("error", e)
        } finally {
            setLoadingOption(false)
        }
    };
    const handleScrollProvider = async () => {
        if (totalPageNoOfProvider > currentPageProvider) {
            const scrollableContent = scrollableContentRefProvider?.current;
            const tolerance = 2
            const isAtEnd = scrollableContent?.scrollHeight - scrollableContent?.scrollTop <= scrollableContent?.clientHeight + tolerance;
            if (isAtEnd) {
                try {
                    const nextPage = currentPageProvider + 1;
                    const payload = {
                        page: nextPage,
                        limit: 20,
                        name: searchTextProvider,
                    };
                    const res = await _getClinicalNotesFilterProvider(payload);
                    const tempUserList = [];
                    res?.data?.data?.data?.map(item => tempUserList.push({ label: item?.name, id: item?.id }));
                    const sortedProviderData = [...providerData, ...tempUserList];
                    setProviderData(sortedProviderData);
                    setCurrentPageProvider(nextPage);
                } catch (e) {
                    console.log("error", e);
                }
            }
        }
    };
    const getProviderName = (name) => {
        let variableName = name;
        variableName = `${name?.slice(0, 25)  }...`;
        return variableName
    }
    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedSearchFilterTextProvider])
    useEffect(() => {
        const name = `${supervisor?.first_name  } ${  supervisor?.last_name}`
        setProvider({ label: name, id: supervisor?.id })
    }, [supervisor])
    return (
        <>
            <div className="wrapper" onClick={handleFilterClick}>
                <div className="select-btn" style={{ border: openFilter ? "1px solid #5571c6" : "1px solid rgb(211, 211, 211)" }} >
                    {(provider.label)?.length <= 25 ?
                        <span style={{ paddingTop: "2%", opacity: status !== 'DRAFT' && status !== 'RESEND_EDIT' && status !== 'IN_PROGRESS' ? "0.7777" : "" }}>{provider.label}</span>
                        :
                        <HtmlTooltip title={provider.label} arrow placement="top">
                            <span style={{ paddingTop: "2%", opacity: status !== 'DRAFT' && status !== 'RESEND_EDIT' && status !== 'IN_PROGRESS' ? "0.7777" : "" }}>{getProviderName(provider.label)}</span>
                        </HtmlTooltip>
                    }
                    {/* <span style={{ paddingTop: "2%", opacity: status !== 'DRAFT' && status !== 'RESEND_EDIT' && status !== 'IN_PROGRESS' ? "0.7777" : "" }}>{getProviderName(provider.label)}</span> */}
                    {
                        openFilter ?
                            <KeyboardArrowUp /> :
                            <KeyboardArrowDown style={{ opacity: status !== 'DRAFT' && status !== 'RESEND_EDIT' && status !== 'IN_PROGRESS' ? "0.7777" : "" }} />
                    }

                </div>
            </div>
            <LoadingButton 
                m={0}
                type='button'
                variant='contained'
                style={{ height: '2rem', borderRadius: '0 5px 5px 0' }}
                onClick={() => handleRequestApprove(provider.id)}
                loading={requestingApproval}
                disabled={!isStatusAllowed(status)}
                size='large'
            >
                Request Approval
            </LoadingButton>
            <Popover
                id={id}
                open={openFilter}
                anchorEl={filterEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ marginTop: "1px" }}
            >
                <div className='select_card_holder_customize'>
                    <div className='search_holder_customize'>
                        <FormControl variant="filled">
                            <FilledInput
                                id="filled-adornment-password"
                                placeholder="Search..."
                                value={searchTextProvider}
                                onChange={(e) => setSearchTextProvider(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <img src={SearchIcon} alt="icon"></img>
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className='scrollable_content_customize' ref={ref => (scrollableContentRefProvider.current = ref)} onScroll={handleScrollProvider}>
                            {loadingOption ? <div className='review_provider_list_' >{"Loading..."}</div> :
                                providerData.length > 0 ?
                                    providerData.map((item, index) => {
                                        return (
                                            <FormGroup key={index}>
                                                <div className='review_provider_list_' onClick={() => { setProvider(item); handleFilterClose() }}
                                                    style={{ background: item.id === provider.id ? "#F1F5FD" : "" }}>
                                                    {item.label}
                                                </div>
                                            </FormGroup>
                                        )
                                    })
                                    : <div className='review_provider_list_' >{"No Option..."}</div>}
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    )
}

export default RequestApprove
