import React, { useEffect, useState } from 'react'
import { TextFieldProps } from '@material-ui/core'
import { IconButton } from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { TextBox, TextBoxProps } from '../TextBox'

interface InputNumberProps extends TextBoxProps {
    min?: number
    max?: number
    onChangeValue?: (value: number | number) => void
}

export const InputNumber = (props: InputNumberProps & TextFieldProps) => {
    const {
        value,
        min = Number.MIN_SAFE_INTEGER,
        max = Number.MAX_SAFE_INTEGER,
        onChangeValue,
        ...rest
    } = props

    const [text, setText] = useState('')

    const handleChange = (newVal: string) => {
        // Check input characters
        if (!/^\d*$/.test(newVal)) return

        // Check boundaries
        if ((min && parseInt(newVal) < min) || (max && parseInt(newVal) > max)) return
        onChangeValue?.(+newVal)
        setText(newVal)
    }

    const handleIncreaseDecrease = (amount: number) => {
        const newVal = ((text ? parseInt(text) : 0) + amount).toString()
        handleChange(newVal)
    }

    useEffect(() => {
        setText((value as string) || '')
    }, [value])

    return (
        <TextBox
            value={text}
            {...rest}
            InputProps={{
                endAdornment: (
                    <div className="d-flex column" style={{ margin: -10 }}>
                        <IconButton
                            disabled={max !== undefined && max <= +text}
                            onClick={() => handleIncreaseDecrease(1)}>
                            <KeyboardArrowUp style={{ height: 15 }} />
                        </IconButton>

                        <IconButton
                            disabled={min !== undefined && min >= +text}
                            onClick={() => handleIncreaseDecrease(-1)}>
                            <KeyboardArrowDown style={{ height: 15 }} />
                        </IconButton>
                    </div>
                ),
                inputProps: { min, max },
            }}
            onChange={(val) => handleChange(val)}
        />
    )
}

export default InputNumber
