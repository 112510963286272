import React from 'react';
import { connect } from 'react-redux';

import UserComponent from '../../components/users';

const UsersContainer = () => <UserComponent />

function mapDispatchToProps(dispatch) {
    return {};
}

function mapStateToProps(state) {
    return {};
}
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UsersContainer);
