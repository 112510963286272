import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import moment from 'moment'
import _ from 'lodash'

import AlertErrorDialog from 'components/shared/Elements/AlertErrorDialog'
import StarCheckBox from 'components/shared/StarCheckBox'
import DataGridSelectionCheckBox from 'components/shared/DataGridSelectionCheckBox'

import { useFaxData } from '../hooks'
import { DataGrid } from 'shared/elements/DataGrid'
import { displayName } from '../shared/util'
import { FunctionBar } from './FunctionBar'
import Routes from 'constants/routes'

const InComingFaxTable = ({
    me,
    // onRowClick,
    onCellClick,
    onSelectedItemsChanged,
    searchCriteria = {},
    shouldRefreshFaxList,
    onAssignToProvider,
}) => {
    const history = useHistory()

    const {
        loading,
        eFaxList,
        markAsRead,
        deselectAll,
        markFavorite,
        markFavouriteAll,
        paginationData,
        setPage,
        selectAll,
        selectItem,
        archive,
        refresh,
    } = useFaxData('inbound', searchCriteria)

    const [alertErrorOpen, setAlertErrorOpen] = useState('')

    const selectionModel = eFaxList.filter((item) => item.isSelected)
    const selectionModelIds = selectionModel.map((item) => item.id)

    const isAllFavourite =
        eFaxList.length && eFaxList.length === eFaxList.filter((item) => item.favourite).length

    useEffect(() => {
        onSelectedItemsChanged(selectionModel)
    }, [selectionModel.length])

    const onPageChange = (value) => {
        setPage(value)
    }

    const columns = [
        {
            renderHeader: (params) => (
                <DataGridSelectionCheckBox
                    type={'header'}
                    value={
                        !selectionModel.length
                            ? 0
                            : selectionModel.length === eFaxList.length
                            ? 1
                            : 0.5
                    }
                    onChange={selectionModel.length === eFaxList.length ? deselectAll : selectAll}
                />
            ),
            width: 65,
            field: 'id',
            sortable: false,
            type: 'action',
            renderCell: ({ row }) => {
                return (
                    <Box
                        // display="flex"
                        // justifyContent="center"
                        // alignItems="center"
                        style={{
                            width: '100%',
                        }}>
                        <DataGridSelectionCheckBox
                            value={row.isSelected}
                            onChange={(e) => {
                                selectItem(row.id)
                            }}
                        />
                    </Box>
                )
            },
        },
        {
            renderHeader: (params) => (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        width: '100%',
                    }}>
                    <StarCheckBox
                        value={isAllFavourite}
                        onChange={() => markFavouriteAll(!isAllFavourite)}
                    />
                </Box>
            ),
            field: 'favourite',
            sortable: false,
            type: 'action',
            width: 52,
            renderCell: ({ value, row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            width: '100%',
                        }}>
                        <StarCheckBox
                            value={row.favourite}
                            onChange={() => markFavorite(row.id, !row.favourite)}
                        />
                    </Box>
                )
            },
        },
        {
            headerName: 'From',
            headerClassName: 'super-app-theme--header',
            field: 'from',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <Typography
                        style={{
                            color: '#5571C6',
                            cursor: 'pointer',
                        }}>
                        {value}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Title',
            headerClassName: 'super-app-theme--header',
            field: 'title',
            flex: 1.75,
            sortable: true,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Associated Patient',
            headerClassName: 'super-app-theme--header',
            field: 'associatedPatient',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.associated_patient_first_name, row.associated_patient_last_name),
        },
        {
            headerName: 'Assigned To',
            headerClassName: 'super-app-theme--header',
            field: 'assigned_to',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.assigned_to_first_name, row.assigned_to_last_name),
        },
        {
            headerName: 'Assigned By',
            headerClassName: 'super-app-theme--header',
            field: 'assigned_by',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.assigned_by_first_name, row.assigned_by_last_name),
        },
        {
            headerName: 'Created',
            headerClassName: 'super-app-theme--header',
            field: 'createdAt',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return moment(value).format('lll')
            },
        },
        {
            headerName: 'Updated',
            headerClassName: 'super-app-theme--header',
            field: 'updatedAt',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return moment(value).format('lll')
            },
        },
    ]

    return (
        <Grid>
            <FunctionBar
                selectionLength={selectionModel.length}
                onRefresh={() => refresh()}
                onArchive={() => archive(selectionModel)}
                onAssignToProvider={onAssignToProvider}
                onMarkAsRead={() => {
                    markAsRead(selectionModel, true)
                    deselectAll()
                }}
                onMarkAsUnread={() => {
                    markAsRead(selectionModel, false)
                    deselectAll()
                }}
                onShowArchived={() => history.push(Routes.EFax.ARCHIVED)}
            />

            <DataGrid
                loading={loading}
                columns={columns}
                rows={eFaxList}
                height="calc(100vh - 314px)"
                pageSize={paginationData.limit}
                totalRecords={paginationData.total}
                selectionModel={selectionModelIds}
                onCellClick={(params) => {
                    if (params.field === 'from') {
                        markAsRead([params.row], true)
                        onCellClick(params)
                    }
                }}
                onPageChange={onPageChange}
                page={paginationData.start}
            />

            {Boolean(alertErrorOpen) && (
                <AlertErrorDialog
                    message={alertErrorOpen}
                    open={Boolean(alertErrorOpen)}
                    handleClose={() => {
                        setAlertErrorOpen(false)
                    }}
                />
            )}
        </Grid>
    )
}

export default InComingFaxTable
