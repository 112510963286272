import { Grid, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { GridSortModel } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import { OutpatientPsychiatric, PatientContacts } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import { apiVitalSigns } from 'services/Patient/apiVitalSigns'
import Loading from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
import { useStyles } from '../MedicalProblems/style'
import PatientInfor from '../PatientInfor'
import { PatientsContactHistoryDashboard } from './Dashboard'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import moment from 'moment'
import { ContactsCreate } from './ContactsCreate'
import { patientContact } from 'services/Patient/apiPatientContacts'

const PatientContactsView = ({ patient_Id, afterActionOk }) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const { id } = useParams<{ id: string }>()
    const [bundleId, setBundleId] = useState('')

    // State
    const [editIndex, setEditIndex] = useState<any>(null)
    const [editpatient, seteditpatient] = useState<any>()
    const [dataSource, setdataSource] = useState<any>([])
    // const [page, setPage] = useState(1)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState('')
    const [patientsContacts, setpatientsContacts] = useState<PatientContacts[]>([])
    const [loading, setLoading] = useState(false)
    const [pagination, setPagination] = useState<Pagination>({
        start: 1,
        limit: 0,
        total: 0,
    })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const limit = 10

    const [sortModel, setSortModel] = useState<GridSortModel>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const getContactList = async () => {
        setLoading(true)
        try {
            const resp = await patientContact.getList(patientId)
            setpatientsContacts(resp.data)
            const totalPages = Math.ceil((resp.data).length);
            setTotal(totalPages)

        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }
    const handleChangePage = (page) => {
        setPage(page)
    }

    const handleDelete = async (contactIdx) => {

        if (contactIdx) {
            try {

                const contactsCopy = [...patientsContacts]
                const idx = contactsCopy.findIndex(x => x.id === contactIdx);
                const deleteContact = contactsCopy.splice(idx, 1)

                await patientContact.create({
                    emergency_contacts: contactsCopy
                }, patientId)

                await getContactList()
                // afterActionOk()
                notification('Contact deleted successfully', 'success')
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            }
        }
    }

    const handleEdit = (idx) => {
        setEditIndex(idx)
        const editData = patientsContacts[editIndex]
        seteditpatient(idx)

        setIsAddingNew(true)

    }

    useEffect(() => {
        if (patientId) {
            getContactList()
        }
    }, [patientId])

    const paginateDataGenerate = (page) => {
        if (patientsContacts.length) {
            const startIndex = (page - 1) * limit;
            const endIndex = startIndex + limit;
            const paginatedData = patientsContacts.slice(startIndex, endIndex);
            setdataSource([...paginatedData])

        } else {
            setdataSource([])
        }
    }

    useEffect(() => {
        paginateDataGenerate(page)
    }, [patientsContacts, limit, page])

    const forceReload = async () => {
        await getContactList()
    }
    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: '16px 24pxs' }}>
            <Loading visible={loading} />
            <Grid
                container
                onClick={() =>
                    history.replace(
                        Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(
                            ':id',
                            patientId,
                        ),
                    )
                }
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
            </Grid>


            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent={'space-between'}>
                    <Typography className={classes.header}>Patients Contacts</Typography>
                    <ButtonCustom
                        tooltip="Add New Patient Contacts"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsAddingNew(true)
                            setEditIndex(null)
                        }}
                        fullWidth
                    >
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>


                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>

                <PatientsContactHistoryDashboard
                    page={page}
                    onPageChange={handleChangePage}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    dataSource={dataSource}
                    pagination={{ limit, start: page, total }}
                />
                {
                    isAddingNew && <ContactsCreate
                        open={isAddingNew}
                        itemId={editIndex}
                        patientId={patientId}
                        onClose={() => { setIsAddingNew(false) }}
                        forceReload={forceReload}

                    />
                }
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(PatientContactsView)
