import { Button, Checkbox, Grid, Icon, IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import Stack from '@mui/material/Stack'
import {
    DataGrid,
    GridOverlay,
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import clsx from 'clsx'
import BatchSignDialog from 'components/ClinicalNotes/BatchSignDialog'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { USStatesMapper } from 'containers/LocationSettings/LocationCreateDialog/constant'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useNotification } from 'shared/elements/Notification'
import EditIcon from '../../assests/icons/edit_icon.svg'
import ViewIcon from '../../assests/icons/eyes_icon.svg'
import DeleteIcon from '../../assests/icons/trash.svg'
import ForwardIcon from '../../assests/icons/ForwardIcon'
import HoverEditIcon from '../../assests/icons/hover_edit_icon.svg'
import HoverViewIcon from '../../assests/icons/hover_view_icon.svg'
import NextIcon from '../../assests/icons/NextIcon'
import NoDataIcon from '../../assests/icons/NoDataIcon'
import TeleIconUpDownSortedList from '../../assests/icons/TeleIconUpDownSortedList'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import {
    _getClinicalNotes,
    _getClinicalNotesDetail,
    deleteNote,
    getMedicalCoding,
    signBatchClinicalNotes,
    clinicalNoteResetForOpen,
} from '../../containers/ClinicalNotes/api/services'
import { ButtonIconEl } from '../../shared/elements'
import { getLoggedInUserRole } from '../../utilities'
import ClinicalNotesFilter from './ClinicalNotesFilter'
import {
    setActiveNote,
    setMissingMedicalCoding,
    setPageMissingMedicalCoding,
} from 'store/actions/clinicalNotes'
import { copyObj } from 'containers/WorkQueues/Waitlist/util'
import { endDateFilterToUTC, startDateFilterToUTC } from 'shared/utils'
import localforage from 'localforage'
import { convertToBase64 } from 'utilities/stringOperations'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'

const LIMIT = 10
const useStyles = makeStyles({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
        color: '#000000',
    },
    status_box_inprogress: {
        borderRadius: 5,
        // paddingTop: '4px',
        padding: '4px 1px',
        background: '#E9ECF1',
        color: '#000000',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    bgColor_in_progress: {
        background: '#D8D1FF',
        color: '#7340DF',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        // marginTop: 16,
        marginTop: 0,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },

    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    deleteIcon: {
        width: 'auto !important'
    }
})

const useAutoCompleteStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiAutocomplete-listbox': {
                '& li': {
                    marginLeft: '8px',
                    marginRight: '8px',
                    marginTop: '10px',
                    marginBottom: '10px',
                },
            },
        },
    }),
)

const NoRowsOverlayEl = (props) => {
    const classes = useStyles()
    return (
        <GridOverlay containter className={classes.cover_no_row}>
            <NoDataIcon />
            <Typography variant="h4" className={classes.header_no_row}>
                No Results
            </Typography>
            <Typography variant="body1"></Typography>
            <Button variant="contained" classes={{ root: classes.btn__go_to_main }}>
                Go to main page
            </Button>
        </GridOverlay>
    )
}

const renderEditAction = (params, actions) => {
    const classes = useStyles()
    const notification = useNotification()
    const [anchorEl, setAnchorEl] = useState(null)
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(null)
    const userData = useSelector((state) => state?.permission?.clinical_notes)
    const loginProviderId = useSelector((state) => state?.login?.userData?.practitioner?.id)
    const isMedicalAssistant = useSelector((state) => state?.login?.userData?.role_code) === 'medical_assistant'
    const isTanentAdmin = useSelector((state) => state?.login?.userData)?.role_code === 'tenant_admin'
    const isSuperuser = useSelector((state) => state?.login?.userData)?.role_code === 'super_user'

    const handleEditClick = actions.handleEditClick

    const handleDelete = () => {
        setDeleteLoader(true)
        deleteNote(params?.row?.id)?.then(() => {
            notification('Clinical note deleted succesfully', 'success')
            actions.handleLoadData('1')
        })?.catch(() => notification('Something went wrong', 'error'))?.finally(() => {
            setDeleteLoader(false)
        })
    }

    const handleUnlockClinicalNote = async() =>{
        try{
            setAnchorEl(null)
            const res = await clinicalNoteResetForOpen(params?.row?.id)
            if(res.status === 200){
                notification('Clinical note unlocked', 'success')
            }
        }catch (ex) {
            notification('Something went wrong!', 'error')
        }
    }

    return (
        <Grid item style={{ display: 'flex' }}>
            <IconButton
                size="small"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                style={{
                    background: '#FFFFFF',
                    border: '1px solid #E9ECF1',
                    borderRadius: '20px',
                }}>
                <MoreHorizIcon
                    style={{
                        color: '#3E465B',
                        fontSize: 18,
                    }}
                />
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 120,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>

                {userData.includes('update_medical_clinical_notes') &&
                    (!isMedicalAssistant ? params?.row?.practitioner_id === loginProviderId : true) &&
                    (params?.row?.status).toLowerCase() !== 'locked' && (
                        <MenuItem
                            id={`auto-header-edit`}
                            onClick={() => {
                                handleEditClick(params, 'edit')
                            }}
                            style={{
                                color: '#667689'
                            }}>
                            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                <img
                                    src={EditIcon}
                                    alt=""
                                    style={{
                                        color: 'white',
                                    }}></img>
                                <Typography>Edit</Typography>
                            </div>
                        </MenuItem>
                    )}

                {userData.includes('preview_clinical_notes') &&
                    <MenuItem
                        id={`auto-header-view`}
                        onClick={() => {
                            handleEditClick(params, 'view')
                        }}
                        style={{
                            color: '#667689'
                        }}>
                        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                            <img
                                src={ViewIcon}
                                alt=""
                                style={{
                                    color: 'white',
                                }}></img>
                            <Typography>View</Typography>
                        </div>
                    </MenuItem>
                }

                {isTanentAdmin && <MenuItem
                    id={`auto-header-delete`}
                    onClick={(event) => {
                        setDeleteDialogOpen(event.currentTarget)
                    }}>
                    <div style={{ display: 'flex', gap: 5 }}>
                        <img
                            src={DeleteIcon}
                            alt=""
                            style={{
                                color: '#E81A1A',
                                width: '1rem',
                                height: '1rem'
                            }}></img>
                        <Typography style={{ color: '#E81A1A' }}>Delete</Typography>
                    </div>
                </MenuItem>}

                {(isTanentAdmin || isSuperuser) && <MenuItem
                    id={`auto-header-delete`}
                    onClick={() => {
                        handleUnlockClinicalNote()
                    }}>
                        <Typography>Unlock</Typography>
                </MenuItem>}


            </Menu>

            {/* <AlertDeleteDialog
          title="Clinical Note Deletion"
          description={`Are you sure you want to delete the clinical note for ${params?.row?.patient_first_name} ${params?.row?.patient_last_name} (${moment(params?.row?.service_date).format('MM/DD/YYYY')})? All data will be deleted.`}
          loading={deleteLoader}
          open={deleteDialogOpen}
          handleClose={() =>
            setDeleteDialogOpen(false)
          }
          handleDelete={handleDelete}
        /> */}

            <CustomAlertPopOver
                anchorEl={deleteDialogOpen}
                handleClose={() => setDeleteDialogOpen(null)}
                title={'Clinical Note Deletion'}
                bodyText={`Are you sure you want to delete the clinical note for ${params?.row?.patient_first_name} ${params?.row?.patient_last_name} (${moment(params?.row?.service_date).format('MM/DD/YYYY')})? All data will be deleted.`}
                confirmButtonName={'Delete'}
                confirmButtonColor={'red'}
                onSuccess={handleDelete}
            />
            {/* {userData.includes('update_medical_clinical_notes') &&
            (params?.row?.status).toLowerCase() !== 'locked' ? (
                <HtmlTooltip
                    title={'Edit'}
                    arrow
                    placement="top"
                    style={{
                        background: 'white !important',
                        color: '#303E4E',
                    }}>
                    <div>
                        <ButtonIconEl
                            size="small"
                            onClick={() => {
                                handleEditClick(params, 'edit')

                            }}
                            className={classes.act_iconbtn}
                            icon={
                                <img
                                    src={EditIcon}
                                    alt=""
                                    style={{
                                        color: 'white',
                                    }}></img>
                            }
                            iconHover={
                                <img
                                    src={HoverEditIcon}
                                    alt=""
                                    style={{
                                        color: 'white',
                                    }}></img>
                            }></ButtonIconEl>
                    </div>
                </HtmlTooltip>
            ) : (
                ''
            )}
            {userData.includes('preview_clinical_notes') ? (
                <HtmlTooltip
                    title={'View'}
                    arrow
                    placement="top"
                    style={{
                        background: 'white !important',
                        color: '#303E4E',
                    }}>
                    <div>
                        <ButtonIconEl
                            size="small"
                            onClick={() => {
                                handleEditClick(params, 'view')
                            }}
                            className={classes.act_iconbtn}
                            icon={
                                <img
                                    src={ViewIcon}
                                    alt=""
                                    style={{
                                        color: 'white',
                                    }}></img>
                            }
                            iconHover={
                                <img
                                    src={HoverViewIcon}
                                    alt=""
                                    style={{
                                        color: 'white',
                                    }}></img>
                            }></ButtonIconEl>
                    </div>
                </HtmlTooltip>
            ) : (
                ''
            )} */}
        </Grid>
    )
}

const renderActions = (params, actions) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            {renderEditAction(params, actions)}
        </Grid>
    )
}

const CustomPagination = ({ onPageChange, total, numberRecord, page }) => {
    const apiRef = useGridApiContext()
    // const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const rowIndex = gridPaginationRowRangeSelector(apiRef)

    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show <strong>{rowIndex?.lastRowIndex + 1 || 0} records</strong> of{' '}
                <strong>{total}</strong>
            </Typography>
            <Pagination
                color="primary"
                count={pageCount}
                // page={page + 1}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    // apiRef.current.setPage(value - 1)
                    onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

const ClinicalNotesList = ({ userId }) => {
    const notification = useNotification()
    const dispatch = useDispatch()
    const history = useHistory()

    const pageMissingMedicalCoding = useSelector(
        (state) => state.clinicalNotes.pageMissingMedicalCoding,
    )
    const userData = useSelector((state) => state?.permission?.clinical_notes)
    const [mounted, setMounted] = useState(false)
    const [nameSearch, setNameSearch] = useState([])
    const [page, setPage] = useState(pageMissingMedicalCoding || 1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [tabName, setTabName] = useState('open')
    const classes = useStyles()

    const location = useLocation()
    const real_role = localStorage.getItem('real_role')

    const [isLoading, setLoading] = useState(false)
    const [isSigning, setIsSigning] = useState(false)
    const [openDialogBatchSigning, setOpenDialogBatchSigning] = useState([])
    const [clinicalNotes, setClinicalNotes] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })
    const [refreshData, setRefreshData] = useState(true)
    const [refreshDataWithDate, setRefreshDataWithDate] = useState(false)
    const telehealthScreen = useSelector((state) => state.telehealthScreen)

    const medicalNotesPermission = useSelector((state) => state?.permission?.medical_notes)

    const behavioralHealthNotesPermission = useSelector(
        (state) => state?.permission?.behavioral_health_notes,
    )

    const missingMedicalCoding = useSelector((state) => state.clinicalNotes.missingMedicalCoding)

    const isUpdatingMedicalCoding = useSelector(
        (state) => state.clinicalNotes.isUpdatingMedicalCoding,
    )

    const onPageChange = (value) => {
        setSelectedRow([])
        setPage(value)
    }

    const handleChangePage = (value) => {
        setPage(value - 1)
    }
    const userRole = getLoggedInUserRole()

    const [searchData, setSearchData] = React.useState({
        text: '',
        status: 'All',
        createdAt: [startDateFilterToUTC(moment()), endDateFilterToUTC(moment())],
    })
    const singleDateNew = async () => {
        const date = await localforage.getItem('singleDate')
        const activeRangeNew = await localforage.getItem('clinicalActiveRange')
        const startDateNew = await localforage.getItem('startDate')
        setSearchData({
            ...searchData,
            createdAt:
                !activeRangeNew && date
                    ? [startDateFilterToUTC(moment(date)), endDateFilterToUTC(moment(date))]
                    : [startDateFilterToUTC(moment()), endDateFilterToUTC(moment())],
        })
    }
    useEffect(() => {
        singleDateNew()
    }, [])
    const [selectedSearchData, setSelectedSearchData] = useState({
        selectedPatient: [],
        selectedSpecialty: [],
        selectedStatus: [],
        selectedNote: [],
        selectedProvider: [],
    })
    const [selectedUser, setSelectedUser] = React.useState('')

    const [sortModel, setSortModel] = React.useState([
        {
            field: '',
            sort: '',
        },
    ])
    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
    ].includes(userRole)
    useEffect(() => {
        if (location?.state?.searchData) setSearchData(location?.state?.searchData)
    }, [location])

    const handleLoadData = async (page = 1) => {
        try {
            setLoading(true)
            if (isUpdatingMedicalCoding) return
            const res = await _getClinicalNotes({
                limit,
                page,
                ...searchData,
                open_locked: tabName,
                name: [...selectedSearchData?.selectedPatient, ...nameSearch],
                // name: selectedSearchData?.selectedPatient.length > 0 ? selectedSearchData?.selectedPatient : nameSearch,
                status: selectedSearchData?.selectedStatus,
                note: selectedSearchData?.selectedNote,
                provider: selectedSearchData?.selectedProvider,
                speciality: selectedSearchData?.selectedSpecialty,
            })
            const clinicalNotes = res?.data?.data.map((item) => {
                item.clinicalNoteName = `${item.patient_first_name} ${item.patient_last_name}`
                return item
            })
            setClinicalNotes(clinicalNotes)
            // setPaginationData(res.data?.paging)
            setTotal(res.data?.paging?.total)

            checkMedicalCodingExist(clinicalNotes)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            if (!isUpdatingMedicalCoding) setLoading(false)
        }
    }
    useEffect(() => {
        (async () => {
            if (!mounted) return setMounted(true)

            if (page !== 1) return setPage(1)

            const abortController = new AbortController()
            if (refreshDataWithDate) {
                await handleLoadData()
                setRefreshData(false)
            }
            return () => abortController.abort()
        })()
    }, [searchData, isUpdatingMedicalCoding, selectedSearchData, tabName])

    useEffect(() => {
        (async () => {
            const abortController = new AbortController()
            if (refreshData) {
                setLoading(true)
            }
            if (!refreshData) {
                await handleLoadData(page)
            }
            return () => abortController.abort()
        })()
    }, [page, isUpdatingMedicalCoding])

    useEffect(() => {
        dispatch(setActiveNote(''))
    }, [])

    function onOptionSelect(_, selected) {
        if (selected?.user_id) {
            setSelectedUser(selected.user.id)
        } else {
            setSelectedUser(0)
        }
    }

    const handleEditClick = (params, name) => {
        const { row } = params
        const payload = {
            ...row,
            name,
        }
        const storedClinicalNotes = localStorage.getItem('visitedClinicalNotes')
        const visitedClinicalNotes = storedClinicalNotes && JSON.parse(storedClinicalNotes)
        if (visitedClinicalNotes && visitedClinicalNotes?.includes(row.id) && name === 'edit') {
            notification('Clinical Note in Edit Mode: Already open in another tab.', 'warning')
        } else {
            // history.push(Routes.CLINICAL_NOTES.NEW_NOTE, payload)
            window.open(`/work-queues/clinical-note/${convertToBase64(
                row?.id,
            )}/${name}`, '_blank')
        }
    }

    const columns = [
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Patient Name
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'clinicalNoteName',
            flex: 1.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return `${row.patient_first_name} ${row.patient_last_name}`
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    State
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'state',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return USStatesMapper[value] || '-'
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Date of Service
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            // field: 'appointment_start',
            field: 'service_date',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return moment(value).format('MM/DD/YYYY')
                // return formattedDate
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Appointment Time
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: true,
            type: 'date',
            valueGetter: (params) => params.row.appointment_start,
            sortComparator: (v1, v2) => {
                return moment(v1, 'HH:mm:ss').unix() - moment(v2, 'HH:mm:ss').unix()
            },
            renderCell: ({ _, row }) => {
                return `${moment(row.appointment_start).format('hh:mm A')} - ${moment(
                    row.appointment_end,
                ).format('hh:mm A')}`
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Note Type
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'templateName',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Rendering provider
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'templateName1',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return `${row.practitioner_first_name} ${row.practitioner_last_name}`
            },
        },
        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Specialty
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value?.specialty
            },
        },

        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Note ID
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'note_id',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return row?.id
            },
        },

        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Status
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'status',
            flex: 0.9,
            sortable: true,
            renderCell: ({ value, row }) => {
                const valueLowCase = value.toLowerCase()
                return (
                    <span
                        className={clsx({
                            [classes.status_box]: valueLowCase !== 'in_progress',
                            [classes.status_box_inprogress]: valueLowCase === 'in_progress',
                            [classes.bgColor_draft]: valueLowCase === 'draft',
                            [classes.bgColor_in_progress]: valueLowCase === 'in_progress',
                            [classes.bgColor_pending_approval]: valueLowCase === 'pending',
                            [classes.bgColor_approved_lock]:
                                valueLowCase === 'approved_lock' || valueLowCase === 'locked',
                            [classes.bgColor_resent_to_edit]: valueLowCase === 'resent_to_edit',
                        })}
                        style={{ paddingLeft: valueLowCase === 'in_progress' ? '1px' : '' }}>
                        {row?.status_code?.name || statusMapper(value) || value}
                    </span>
                )
            },
        },

        {
            headerName: (
                <Box component={'div'} fontWeight="bold">
                    Actions
                </Box>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'id',
            width: 80,
            isDisabled: false,
            // flex: 0.25,userData.includes('update_medical_clinical_notes') ?
            sortable: false,
            renderCell: (params) => renderActions(params, { handleEditClick, handleLoadData }),
        },
        {
            headerName: (
                <Checkbox
                    id="auto-aap-isAgreedTnC-check"
                    className="term-condition-form-check"
                    name="isAgreedTnC"
                    color="primary"
                    disabled={
                        page === pageMissingMedicalCoding &&
                        (isSigning ||
                            missingMedicalCoding.length ||
                            clinicalNotes.filter((item) => {
                                return (
                                    item.patient_state &&
                                    (item.status === 'DRAFT' ||
                                        item.status === 'PENDING_APPROVE' ||
                                        item.status === 'IN_PROGRESS')
                                )
                            })?.length === 0)
                    }
                    onChange={(e) => {
                        if (
                            clinicalNotes?.filter(
                                (item) =>
                                    item.patient_state &&
                                    (item.status === 'DRAFT' ||
                                        item.status === 'PENDING_APPROVE' ||
                                        item.status === 'IN_PROGRESS'),
                            )?.length === selectedRow?.length
                        ) {
                            setSelectedRow([])
                        } else {
                            setSelectedRow(
                                clinicalNotes.filter(
                                    (item) =>
                                        item.patient_state &&
                                        (item.status === 'DRAFT' ||
                                            item.status === 'PENDING_APPROVE' ||
                                            item.status === 'IN_PROGRESS'),
                                ),
                            )
                        }
                    }}
                    checked={
                        clinicalNotes?.filter(
                            (item) =>
                                item.patient_state &&
                                (item.status === 'DRAFT' ||
                                    item.status === 'PENDING_APPROVE' ||
                                    item.status === 'IN_PROGRESS'),
                        )?.length === selectedRow?.length &&
                        selectedRow?.length > 0 &&
                        page !== pageMissingMedicalCoding
                    }
                />
            ),
            headerClassName: 'super-app-theme--header',
            field: 'patient_state',
            width: 60,
            isDisabled: !userData.includes('batch_sign_clinical_notes'),
            // flex: 0.25,
            sortable: false,
            renderCell: ({ row }) => (
                <Checkbox
                    id="auto-aap-isAgreedTnC-check"
                    className="term-condition-form-check"
                    color={missingMedicalCoding.find((id) => id === row.id) ? 'default' : 'primary'}
                    onChange={() => {
                        const item = selectedRow.find((item) => {
                            return item.id === row.id
                        })
                        if (item?.id) {
                            setSelectedRow((prev) => prev.filter((i) => i?.id !== item?.id))
                        } else {
                            setSelectedRow((prev) => [...prev, row])
                        }
                    }}
                    disabled={
                        isSigning ||
                        missingMedicalCoding.some((id) => id === row.id) ||
                        !row.patient_state ||
                        (row.status !== 'DRAFT' &&
                            row.status !== 'PENDING_APPROVE' &&
                            row.status !== 'IN_PROGRESS')
                    }
                    checked={
                        selectedRow.findIndex((item) => {
                            return item.id === row.id
                        }) > -1
                    }
                // checked={
                //     !missingMedicalCoding.some((id) => id === row.id) &&
                //     selectedRow.findIndex((item) => {
                //         return item.id === row.id
                //     }) > -1
                // }
                />
            ),
        },
    ]

    function statusMapper(status) {
        switch (status) {
            case 'pending':
                return 'Pending Approval'
            case 'draft':
                return 'Draft'
            case 'locked':
                return 'Locked'
            case 'resent_to_edit':
                return 'Resent to Edit'
            case 'approved_lock':
                return 'Approved & Locked'
            default:
                return ''
        }
    }

    const checkMedicalCodingBeforeSignBatch = () => {
        const missing = []
        let rowSignAble = copyObj(selectedRow)
        for (const row of selectedRow) {
            const { cpt, hcpcs, icd10 } = row.medical_coding
            if (!icd10.length || hcpcs.length + cpt.length === 0) {
                missing.push(row.id)
                rowSignAble = rowSignAble.filter(({ id }) => row.id !== id)
            }
        }
        dispatch(setMissingMedicalCoding(missing))
        dispatch(setPageMissingMedicalCoding(page))
        return {
            rowSignAble,
            missing,
        }
    }

    const checkMedicalCodingExist = (clinicalNotes) => {
        if (page !== pageMissingMedicalCoding) return
        const missing = [...missingMedicalCoding]
        missing.forEach((id) => {
            const {
                medical_coding: { cpt, hcpcs, icd10 },
            } = clinicalNotes.find((item) => item.id === id)
            if (!(!icd10.length || hcpcs.length + cpt.length === 0)) {
                missing.splice(
                    missing.findIndex((el) => el === id),
                    1,
                )
            }
        })
        if (!missing.length) {
            dispatch(setPageMissingMedicalCoding(0))
        }
        dispatch(setMissingMedicalCoding(missing))
        setSelectedRow(() => {
            return missingMedicalCoding.map((id) => {
                return clinicalNotes.find((item) => item?.id === id)
            })
        })
    }

    const handleSignBatch = () => {
        if (selectedRow?.length > 0) {
            setIsSigning(true)
            const result = checkMedicalCodingBeforeSignBatch()
            if (result.missing.length) {
                notification(
                    'Please enter the medical coding for the clinical notes!',
                    'error',
                    4000,
                )
            }
            if (result.rowSignAble.length) {
                setOpenDialogBatchSigning(result.rowSignAble)
            } else {
                setIsSigning(false)
            }
        } else {
            notification('Please select at least one clinical node to sign!', 'error')
        }
    }

    const refreshAfterSigning = () => {
        setSelectedRow((prev) => {
            let newSelectedRow = copyObj(prev)
            openDialogBatchSigning.forEach((el) => {
                newSelectedRow = newSelectedRow.filter((row) => row.id !== el.id)
            })
            return newSelectedRow
        })
        handleLoadData(page)
        // setPage(page)
        setIsSigning(false)
    }

    return (
        <Grid container className={classes.clinical_notes_container} xs={12}>
            <ClinicalNotesFilter
                searchData={searchData}
                onSearch={(searchVal) => {
                    const temp = []
                    if (searchVal?.text !== '') {
                        temp.push(searchVal?.text)
                    }
                    setNameSearch(temp)
                    setSearchData(searchVal)
                }}
                loading={isSigning}
                disabled={isLoading}
                handleSignBatch={handleSignBatch}
                filterSerach={(searchVal) => {
                    setSelectedSearchData(searchVal)
                }}
                tabContainer={(tab) => {
                    if (tab === 0) {
                        setTabName('open')
                    } else if (tab === 1) {
                        setTabName('locked')
                    }
                }}
                refreshTheList={(data) => {
                    setRefreshData(data)
                }}
                setRefreshDataWithDateList={(data) => {
                    setRefreshDataWithDate(data)
                }}
            />
            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 260px)',
                            zIndex: '1',
                            // height: `calc(100vh - ${telehealthScreen.active ? 180 : 160}px)`,
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },
                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                        {
                            width: 'auto',
                            visibility: 'visible',
                        },
                        '& .MuiDataGrid-row.missing-medical-coding': {
                            backgroundColor: '#d9dde3',
                        },
                        '& .MuiDataGrid-row.missing-medical-coding:hover': {
                            backgroundColor: '#d9dde3',
                        },
                        '& .MuiDataGrid-row.missing-medical-coding .MuiDataGrid-cell': {
                            color: '#000000',
                        },
                    }}>
                    <DataGrid
                        sx={{
                            '& .MuiCircularProgress-root': {
                                zIndex: -10,
                            },
                        }}
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={clinicalNotes}
                        columns={columns.filter((item) => !item.isDisabled)}
                        pageSize={10}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: CustomPagination,
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        componentsProps={{
                            pagination: {
                                onPageChange,
                                total,
                                page,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                total,
                                numberRecord: limit,
                            },
                        }}
                        getRowClassName={(params) => {
                            return missingMedicalCoding.some((id) => id === params.row.id)
                                ? 'missing-medical-coding'
                                : ''
                        }}
                    />
                </Box>
            </Grid>
            {openDialogBatchSigning?.length > 0 && (
                <BatchSignDialog
                    open={openDialogBatchSigning?.length > 0}
                    clinicalNotes={openDialogBatchSigning}
                    handleClose={() => {
                        setOpenDialogBatchSigning([])
                    }}
                    refreshAfterSigning={refreshAfterSigning}
                />
            )}
        </Grid>
    )
}

export default ClinicalNotesList
