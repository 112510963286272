import { Box, Grid, Typography } from '@material-ui/core'
import moment from 'moment'

const WeekHeaderCellContent = (props) => {
    const isCurrentDay = moment(props.date).isSame(moment(), 'day')
    // if (isCurrentDay) {
    //     console.log(props)
    // }
    return (
        <Box>
            <Grid container direction="column">
                {/* {isCurrentDay && (
                    <Grid item className="currentDay">
                        <Box
                            sx={{
                                height: 10,
                                width: '100%',
                                backgroundColor: '#5571C6',
                                padding: 0,
                            }}
                        />
                    </Grid>
                )} */}
                <Grid item>
                    <Typography className="font-small">
                        {moment(props.date).format('dddd')}
                    </Typography>
                    {/* <Typography className="font-medium">
                        {moment(props.date).format('DD')}
                    </Typography> */}
                </Grid>
            </Grid>
        </Box>
    )
}

export default WeekHeaderCellContent
