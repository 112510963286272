import * as actionType from '../actionType'
import {
    setLoading,
    setError,
    setErrorMessage,
    setLoadingAdmit,
    setLoadingEndRoom,
    setAddingParticipant,
} from './loader'

import {
    getProviderDetail,
    getProviderList,
    getCalendarSlots,
    getCalendarDates,
    getProviderSearchList,
    getProviderProfile,
    updateProviderStatus as updateProviderStatusService,
    saveProviderProfile,
    editProviderProfile,
    getProviderSpecialityList,
    getProviderClientList,
    getActiveProviderList,
    getTimeoffsList,
    getTimeoffsListStatus,
    updateTimeoffsStatus,
    getEncounterQueueListAPI,
    changeEncounterStatus,
    getTokenVideoProvider,
    postAdmitUrl,
    postEndRoomUrl,
    postKickParticipantUrl,
    addParticipantService,
    postStopConsultation,
    getAssistantPractitionerAPI,
    examAssignEncounterToAssistantApi,
    examPractitionerJoinApi,
} from '../../services/Provider/provider'
import { ENCOUNTER_STATUS, PATIENT_STATUS } from '../../containers/CalendarScheduler/constant'
import moment from 'moment'
import { getProvidingProviders, getSupervisingProviders } from 'services/WorkQueue'
import { useNotification } from 'shared/elements/Notification'
import { isObjectNull } from 'containers/CalendarScheduler/utils'
export const setProviderList = (value) => ({
    type: actionType.SET_PROVIDER_LIST,
    value,
})

export const setAssistantPractitioner = (value) => ({
    type: actionType.SET_ASSISTANT_PRACTITIONER,
    value,
})

export const setProviderDetails = (value) => {
    return {
        type: actionType.SET_PROVIDER_DETAILS,
        value,
    }
}

export const setCalendarDates = (value) => ({
    type: actionType.SET_CALENDAR_DATES,
    value,
})

export const setSpeciality = (value) => ({
    type: actionType.SET_SPECIALITY_LIST,
    value,
})
export const setClientList = (value) => ({
    type: actionType.SET_CLIENT_LIST,
    value,
})
export const setActiveProviderList = (value) => ({
    type: actionType.SET_ACTIVE_PROVIDER_LIST,
    value,
})

export const setCalendarSlots = (value) => ({
    type: actionType.SET_CALENDAR_SLOTS,
    value,
})

export const setProviderProfile = (value) => {
    return {
        type: actionType.SET_PROVIDER_PROFILE,
        value,
    }
}

export const setAssignedProvider = (value) => {
    return {
        type: actionType.SET_ASSIGNED_PROVIDER,
        value,
    }
}
export const updateProviderTimeoffsStatus = (id, status, comments, onSuccess, onFailure) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        updateTimeoffsStatus(id, status, comments)
            .then((res) => {
                dispatch(setLoading(false))
                //dispatch(getProviderTimeoffs());
                onSuccess && onSuccess()
            })
            .catch((err) => {
                dispatch(setLoading(false))
                onFailure && onFailure()
            })
    }
}
export const getProviderDetails = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setProviderDetails(null))
        getProviderDetail(id)
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderDetails(res.data))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log('Inside getProviderDetails error', err)
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getProvidersList = (speciality, visitType, patient, clinicId) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setProviderList([]))
        getProviderList(speciality, visitType, patient, clinicId)
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderList(res.data))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log('Inside getProviderList error', err)
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const getProviderCalendarDates = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getCalendarDates(payload)
            .then((res) => {
                dispatch(setLoading(false))
                console.log('=======calendar slots================', res)
                if (res && res.data) {
                    dispatch(setCalendarDates(res.data))
                }
            })
            .catch((err) => {
                console.log('getCalendarSlots error', err)
                dispatch(setLoading(false))
            })
    }
}

export const getSpecialityList = (onSuccess) => {
    return (dispatch) => {
        getProviderSpecialityList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(setSpeciality(res.data))
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log('getSpecialityList error', err)
            })
    }
}

export const getClientList = (onSuccess) => {
    return (dispatch) => {
        getProviderClientList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(setClientList(res.data))
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log('getClientList error', err)
            })
    }
}

export const getActiveProvidersList = (onSuccess) => {
    return (dispatch) => {
        getActiveProviderList()
            .then((res) => {
                if (res && res.data) {
                    dispatch(setActiveProviderList(res.data))
                }
                onSuccess && onSuccess()
            })
            .catch((err) => {
                console.log('getActiveProvidersList error', err)
            })
    }
}

export const getProviderCalendarSlots = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getCalendarSlots(payload)
            .then((res) => {
                dispatch(setLoading(false))
                if (res && res.data) {
                    dispatch(setCalendarSlots(res.data))
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
            })
    }
}

export const setSearchProviderList = (value) => {
    return {
        type: actionType.SET_SEARCH_PROVIDER_LIST,
        value,
    }
}
export const resetProviderProfile = () => {
    return {
        type: actionType.RESET_PROVIDER_PROFILE,
    }
}
export const handleGetProviderProfile = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        dispatch(setErrorMessage(''))
        getProviderProfile(id)
            .then((res) => {
                if (res.data) {
                    dispatch(setProviderProfile([res.data]))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const searchProviderList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getProviderSearchList(payload)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(setSearchProviderList(res.data ?? {}))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
                dispatch(setSearchProviderList({}))
            })
    }
}

export const setUpdateProviderStatus = (value) => {
    return {
        type: actionType.UPDATE_SEARCH_PROVIDER_STATUS,
        value,
    }
}
export const updateProviderStatus = (payload) => {
    return (dispatch) => {
        setLoading(true)
        updateProviderStatusService(payload)
            .then((res) => {
                setLoading(false)

                // update row user status based on userId
                dispatch(setUpdateProviderStatus(payload))
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
            })
    }
}

export const handleSaveProviderProfile = (payload, onSuccess, onError) => {
    return (dispatch) => {
        setLoading(true)
        saveProviderProfile({ ...payload })
            .then((res) => {
                setLoading(false)
                dispatch(setEditProviderProfile(res.data))
                onSuccess && onSuccess(res.data)
            })
            .catch((err) => {
                onError && onError(err)
                setLoading(false)
                dispatch(setError(true))
            })
    }
}
export const getProviderTimeoffs = (id, status) => {
    return (dispatch) => {
        setLoading(true)

        getTimeoffsListStatus(id, status?.toLowerCase())
            .then((res) => {
                setLoading(false)
                dispatch(setTimeoffsList(res.data))
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
                dispatch(setTimeoffsList([]))
            })
    }
}
export const handleEditProviderProfile = (payload, onSuccess) => {
    return (dispatch) => {
        setLoading(true)
        dispatch(setErrorMessage(''))
        editProviderProfile(payload)
            .then((res) => {
                setLoading(false)
                dispatch(setEditProviderProfile(res.data))

                dispatch(handleGetProviderProfile(payload?.user_id))
                onSuccess && onSuccess()
            })
            .catch((err) => {
                setLoading(false)
                dispatch(setError(true))
                dispatch(setErrorMessage(err?.data.message))
            })
    }
}

export const setEditProviderProfile = (value) => {
    return {
        type: actionType.SET_EDIT_PROVIDER_PROFILE,
        value,
    }
}

export const setTimeoffsList = (value) => {
    return {
        type: actionType.SET_TIMEOFFS_LIST,
        value,
    }
}

export const handleOpenListConsultation = (payload) => {
    return (dispatch) => {
        dispatch(updateOpenListConsultation(payload))
    }
}
export const handleOpenListProvider = (payload) => {
    return (dispatch) => {
        dispatch(updateOpenListProvider(payload))
    }
}

export const updateOpenListConsultation = (value) => {
    return {
        type: actionType.SHOW_CONSULTATION_LIST,
        value,
    }
}
export const updateOpenListProvider = (value) => {
    return {
        type: actionType.SHOW_PROVIDER_LIST,
        value,
    }
}

export const setEncounterQueueList = (value) => {
    return {
        type: actionType.SET_ENCOUNTER_QUEUE_LIST,
        value,
    }
}

export const setSelectedEncounter = (value) => {
    return {
        type: actionType.SET_SELECTED_ENCOUNTER,
        value,
    }
}

export const setStatusParticipant = (value) => {
    return {
        type: actionType.SET_STATUS_PARTICIPANT,
        value,
    }
}

const canShowEncounter = (encounter, user) => {
    // if (encounter.status === ENCOUNTER_STATUS.COMPLETED) {
    //     return false
    // }

    const before1Day = moment().subtract(1, 'day')
    const after1Day = moment().add(1, 'day')
    if (encounter.start && !moment(encounter.start).isBetween(before1Day, after1Day)) {
        // return false
    }

    // //exam phase
    // if (
    //     (encounter.status === ENCOUNTER_STATUS.EXAM ||
    //         encounter.status ===
    //             ENCOUNTER_STATUS.EXAM_WAITING) &&
    //     user['custom:user_id'] !== encounter.provider_id
    // ) {
    //     return false
    // }

    // //pre xam phase
    // if (
    //     (encounter.status === ENCOUNTER_STATUS.NOT_START ||
    //         encounter.status ===
    //             ENCOUNTER_STATUS.PRE_EXAM ||
    //         encounter.status ===
    //             ENCOUNTER_STATUS.PRE_EXAM_WAITING) &&
    //     !encounter.care_team.find(
    //         (item) =>
    //             item.practitioner_id ===
    //             user['custom:user_id'],
    //     )
    // ) {
    //     return false
    // }
    return true
}

export const handleGetEncounterQueueList = (params = {}) => {
    return (dispatch, getState) => {
        console.log('handle caredd')
        dispatch(setLoading(true))
        const state = getState()
        const me = state.login.userData
        const getEqDate = state.provider.encounterQueueListDate

        const timezone = state.timezones.tz
        const generatedparams = {
            start: getEqDate
                ? moment(getEqDate).startOf('day').utc().format()
                : moment().startOf('day').utc().format(),
            end: getEqDate
                ? moment(getEqDate).endOf('day').utc().format()
                : moment().endOf('day').utc().format(),
            ...params
        }

        getEncounterQueueListAPI(generatedparams)
            .then((res) => {
                const encounter = res.data?.filter((item) => canShowEncounter(item, me))
                // .map((enc) => {
                // if (
                //     enc.care_team.find(
                //         (item) =>
                //             item.practitioner_id ===
                //             me['custom:user_id'],
                //     )
                // ) {
                //     enc.isCareteam = true
                // } else {
                //     enc.isCareteam = false
                // }
                // return enc
                // })
                dispatch(setEncounterQueueList(encounter))
                if (!isObjectNull(state?.provider?.objectEncouterStart)) {
                    const findCurrentEncounterIndex = encounter.findIndex((item) => item.encounter_id === state.provider.objectEncouterStart.encounter_id) === -1
                    if (findCurrentEncounterIndex === -1) {
                        dispatch(saveObjectEncouterStart({}));
                    }
                }
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setEncounterQueueList([]))
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const handleChangeEncounterStatus = (payload, callbackTrue = () => { }, callbackFalse = () => { }) => {
    // const notification = useNotification()
    return (dispatch, getState) => {
        dispatch(setLoading(true))
        callbackTrue()
        changeEncounterStatus(payload)
            .then((res) => {
                dispatch(handleGetEncounterQueueList())
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setEncounterQueueList([]))
            })
            .finally(() => {
                dispatch(setLoading(false))
                dispatch(callbackFalse)
            })
    }
}

export const getEncounterQueueList = (value) => {
    return {
        type: actionType.GET_LIST_ENCOUNTER_QUEUE,
        value,
    }
}

export const setDateEncounterQueueList = (value) => {
    return {
        type: actionType.SET_DATE_BASED_LIST_ENCOUNTER_QUEUE,
        value,
    }
}
export const updateStatusEncounterQueueList = (messageSocket) => {
    return (dispatch, getState) => {
        const state = getState()
        const encounterQueueList = [...state.provider.encounterQueueList]
        const index = encounterQueueList.findIndex(
            (item) => item.encounter_id === messageSocket?.data?.encounter_id,
        )
        if (index === -1) {
            return
        }

        if (messageSocket?.data?.encounter_status) {
            encounterQueueList[index].status = messageSocket?.data?.encounter_status
        }

        if (
            [
                ENCOUNTER_STATUS.PRE_EXAM_WAITING,
                ENCOUNTER_STATUS.EXAM_WAITING,
                ENCOUNTER_STATUS.POST_EXAM_WAITING,
            ].includes(messageSocket?.data?.encounter_status)
        ) {
            encounterQueueList[index].patient_status = PATIENT_STATUS.IN_WAITING_ROOM
        }

        if (
            messageSocket.action === 'waiting_room_disconnected' ||
            messageSocket.action === 'exam_room_disconnected'
        ) {
            encounterQueueList[index].patient_status = PATIENT_STATUS.DISCONNECT_WAITING_ROOM
        }

        dispatch(setEncounterQueueList(encounterQueueList))
    }
}

export const removeEncounterQueueList = (messageSocket) => {
    return (dispatch, getState) => {
        const state = getState()
        const encounterQueueList = [...state.provider.encounterQueueList].filter(
            (item) => item.encounter_id !== messageSocket.data.encounter_id,
        )

        dispatch(setEncounterQueueList(encounterQueueList))
    }
}

export const updateEncounterQueueList = (messageSocket) => {
    return (dispatch, getState) => {
        const state = getState()
        const encounterQueueList = [...state.provider.encounterQueueList]
        const index = encounterQueueList.findIndex(
            (item) => item.encounter_id === messageSocket.data.encounter_id,
        )

        if (index === -1) return
        encounterQueueList.splice(index, 1, messageSocket.data)

        dispatch(setEncounterQueueList(encounterQueueList))
    }
}

export const updateStatusParticipant = (messageSocket, encounterQueueListEncouter) => {
    return (dispatch, getState) => {
        const participantEncouter = encounterQueueListEncouter.filter(
            (option) => option.encounter_id === messageSocket?.data?.encounter_id,
        )
        const statusParticipant = participantEncouter[0]?.participants?.filter(
            (option) => option.phone_number === messageSocket?.data?.phone_number,
        )

        if (statusParticipant?.[0]) {
            statusParticipant[0]['type'] = 'participant'
        }

        dispatch(setStatusParticipant(statusParticipant))
    }
}

export const updateEncounterAppointmentStatus = (messageSocket) => {
    return (dispatch, getState) => {
        const state = getState()
        const encounterQueueList = [...state.provider.encounterQueueList]
        const index = encounterQueueList.findIndex(
            (item) => item.encounter_id === messageSocket?.data?.encounter_id,
        )
        console.log(messageSocket, 'updateEncounterAppointmentStatus')
        if (index === -1) {
            return
        }

        encounterQueueList[index].appointment_status_id = messageSocket?.data?.appointment_status_id
        dispatch(setEncounterQueueList(encounterQueueList))
    }
}

export const addEncounterQueueList = (encounter) => {
    return (dispatch, getState) => {
        dispatch(handleGetEncounterQueueList())
        const state = getState()
        const me = state.login.userData
        // if encounter exists
        // if (
        //     Boolean(
        //         state.provider?.encounterQueueList?.find(
        //             (item) => item.encounter_id === encounter.encounter_id,
        //         ),
        //     )
        // ) {
        //     return
        // }

        // if (
        //     encounter?.care_team?.find(
        //         (item) =>
        //             item.practitioner_id ===
        //             me['custom:user_id'],
        //     )
        // ) {
        //     encounter.isCareteam = true
        // } else {
        //     encounter.isCareteam = false
        // }

        if (canShowEncounter(encounter, me)) {
            const encounterQueueList = [...state.provider.encounterQueueList]
            dispatch(setEncounterQueueList(encounterQueueList))
        }
    }
}

export const actionsGetTokenVideoProvider = (encounter) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        examPractitionerJoinApi(encounter.encounter_id)
            .then((response) => {
                if (response.data?.twilio_token) {
                    const token = response.data?.twilio_token || response.data?.token
                    dispatch(setTokenVideoProvider(token))
                } else {
                    const sessionData = response.data
                    dispatch(setVonageProviderSession(sessionData))
                }
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const setTokenVideoProvider = (value) => ({
    type: actionType.GET_TOKEN_VIDEO_PROVIDER,
    value,
})

export const setVonageProviderSession = (value) => ({
    type: actionType.VONAGE_PROVIDER_SESSION,
    value
})

export const setLastMessage = (value) => ({
    type: actionType.SET_LAST_MESSAGE,
    value,
})

export const addParticipant = (payload) => {
    return (dispatch) => {
        dispatch(setAddingParticipant(true))
        addParticipantService(payload)
            .then((response) => {
                dispatch(setLoading(false))
                if (response) {
                    dispatch(handleGetEncounterQueueList())
                    dispatch(setErrorAddParticipant(''))
                    dispatch(setIsSuccessAddParticipant(true))
                }
            })
            .catch((err) => {
                dispatch(setErrorAddParticipant(err))
                dispatch(setIsSuccessAddParticipant(false))
            })
            .finally(() => {
                dispatch(setAddingParticipant(false))
            })
    }
}

export const postAdmit = (payload) => {
    return (dispatch) => {
        dispatch(
            setLoadingAdmit({
                screen: payload?.screen,
                loading: true,
            }),
        )
        const params = {
            phone_number: payload?.phone_number,
        }
        postAdmitUrl(payload?.encounter_id, params)
            .then((res) => {
                if (res) {
                    dispatch(postAdmitAction(res))
                    dispatch(setEncounterMeetingId(payload?.encounter_id))
                    dispatch(handleGetEncounterQueueList())
                    dispatch(setStatusParticipant([]))
                }
                dispatch(
                    setLoadingAdmit({
                        screen: payload?.screen,
                        loading: false,
                    }),
                )
            })
            .catch((err) => {
                dispatch(
                    setLoadingAdmit({
                        screen: payload?.screen,
                        loading: false,
                    }),
                )
                dispatch(setError(true))
            })
    }
}

export const postAdmitAction = (value) => {
    return {
        type: actionType.ACTION_ADMIT,
        value,
    }
}

export const postEndRoom = (payload) => {
    return (dispatch) => {
        dispatch(
            setLoadingEndRoom({
                screen: payload?.screen,
                loading: true,
            }),
        )
        const params = {
            phone_number: payload?.phone_number,
        }
        postEndRoomUrl(payload?.encounter_id, params)
            .then((res) => {
                if (res && payload?.endEncouter) {
                    dispatch(postEndRoomAction(true))
                    dispatch(setEncounterMeetingId(null))
                    dispatch(handleOpenListConsultation(false))
                    dispatch(saveObjectEncouterStart({}))
                }
                dispatch(
                    setLoadingEndRoom({
                        screen: payload?.screen,
                        loading: false,
                    }),
                )
                dispatch(handleGetEncounterQueueList())
            })
            .catch((err) => {
                dispatch(
                    setLoadingEndRoom({
                        screen: payload?.screen,
                        loading: false,
                    }),
                )
                dispatch(setError(true))
            })
    }
}

export const assignConsultationToOtherProvider = (payload, onSuccess, onFailure) => {
    console.log(payload, 'payload')
    return (dispatch) => {
        dispatch(
            setAssignedProvider({
                assignedProviderId: payload.assignedProviderId,
                isLoading: true,
                isCompleted: false,
            }),
        )
        examAssignEncounterToAssistantApi(payload)
            .then((res) => {
                if (res && res.data?.Status === 'SUCCESSED') {
                    dispatch(
                        setAssignedProvider({
                            assignedProviderId: payload.assignedProviderId,
                            isLoading: false,
                            isCompleted: true,
                        }),
                    )
                    onSuccess && onSuccess()
                } else {
                    dispatch(
                        setAssignedProvider({
                            assignedProviderId: payload.assignedProviderId,
                            isLoading: false,
                            isCompleted: false,
                        }),
                    )
                    onFailure && onFailure(res.data?.Status)
                }
            })
            .catch((err) => {
                console.log('call api end room err', err)
                onFailure && onFailure(err)
                dispatch(setError(true))
                dispatch(
                    setAssignedProvider({
                        assignedProviderId: null,
                        isLoading: false,
                        isCompeleted: false,
                    }),
                )
            })
    }
}

export const postEndRoomAction = (value) => {
    return {
        type: actionType.ACTION_END_ROOM,
        value,
    }
}

export const postKickParticipant = (encounter_id) => {
    return (dispatch) => {
        dispatch(setLoading(true))
        postKickParticipantUrl(encounter_id)
            .then((res) => {
                console.log('call api kick participant successful', res)
                if (res) {
                    dispatch(postKickParticipantAction(res))
                }
                dispatch(setLoading(false))
            })
            .catch((err) => {
                console.log('call api kick participant err', err)
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export const postKickParticipantAction = (value) => {
    return {
        type: actionType.ACTION_KICK_PARTICIPANT,
        value,
    }
}

export const setEncounterMeetingId = (value) => {
    return {
        type: actionType.SET_ENCOUNTER_MEETING_ID,
        value,
    }
}

export const setIsSuccessAddParticipant = (value) => {
    return {
        type: actionType.SET_IS_SUCCESS_ADD_PARTICIPANT,
        value,
    }
}

export const setErrorAddParticipant = (value) => {
    return {
        type: actionType.SET_ERROR_ADD_PARTICIPANT,
        value,
    }
}

export const stopAllConsultation = () => {
    return (dispatch) => {
        dispatch(setLoading(true))
        postStopConsultation().then(() => {
            dispatch(setLoading(false))
            dispatch({
                type: actionType.STOP_CONSULTATION,
            })
        })
    }
}

export const fetchAssistantPractitioner = () => {
    return (dispatch) => {
        // dispatch(setLoading(true))
        getAssistantPractitionerAPI()
            .then((response) => {
                dispatch({
                    type: actionType.SET_ASSISTANT_PRACTITIONER,
                    value: response?.data?.assistantList,
                })
            })
            .catch((error) => {
                dispatch(setError(error))
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const saveObjectEncouterStart = (value) => {
    return {
        type: actionType.SAVE_OBJECT_ENCOUTER_START,
        value,
    }
}

export const setEncounterRoom = (value) => {
    return {
        type: actionType.SET_ENCOUNTER_ROOM,
        value,
    }
}

export const setEncounterClinicalNoteLoaded = (value) => {
    return {
        type: actionType.SET_ENCOUNTER_CLINICAL_NOTE_LOADED,
        value,
    }
}

/**
 * Set Providing Provider
 */
export const setProvidingProvidersList = (value) => {
    return {
        type: actionType.SET_PROVIDING_PROVIDER_LIST,
        value,
    }
}
export const getProviders = () => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getProvidingProviders()
            .then((res) => {
                dispatch(setProvidingProvidersList(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setProvidingProvidersList([]))
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

/**
 * Set Providing Provider
 */
export const setSupervisingProvidersList = (value) => {
    return {
        type: actionType.SET_SUPERVISE_PROVIDER_LIST,
        value,
    }
}
export const getListSupervising = () => {
    return (dispatch) => {
        dispatch(setLoading(true))
        getSupervisingProviders()
            .then((res) => {
                dispatch(setSupervisingProvidersList(res.data))
            })
            .catch((err) => {
                dispatch(setError(true))
                dispatch(setSupervisingProvidersList([]))
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}
