import React from 'react'
import PatientInfo from './components/PatientInfo'
import ChiefComplaint from './components/ChiefComplaint'
import NoteEQDetails from './components/NoteEQDetails'

function PatientInfoHeader(props) {
    return (
        <div style={{ background: '#F4F6FB', display: 'flex', border: '1px solid #EBEBEB', height: '100px', zIndex: 1000 }}>
            <PatientInfo patientDetails={props?.clinicalNoteData?.patient} />
            <ChiefComplaint
                values={props?.values}
                onChange={props?.onChange}
                submitFormRef={props?.submitFormRef}
                initialValues={props?.initialValues}
                nonEditable={props?.nonEditable}
            />
            <NoteEQDetails
                nextEncounterDetails={props?.nextEncounterDetails} 
                clinicalNoteData={props?.clinicalNoteData} 
            />
        </div>
    )
}

export default PatientInfoHeader
