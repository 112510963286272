import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
// import './styles.scss'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { PatientInformation } from 'containers/PatientsSetting/demographic/PatientInformation'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { Tabs } from '../../../PatientsSetting/shared/Tabs'
import { ReferralsForm } from './ReferralsForm'
import { GeneralPatientRecords } from 'containers/PatientsSetting/demographic/GeneralPatientRecords'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import Confirm from 'components/shared/Elements/Confirm'
import LoadingPage from 'utilities/loading-page'

export const ReferralDashboard = () => {
    const classes = usePatientDashboardStyles()
    const history = useHistory()
    const location = useLocation()
    const { idReferral, idPatient, viewType } = useParams<{ idReferral: string; idPatient: string; viewType: string; }>()
    const { patientInfo, fetchPatient, loadingPatient, setLoadingPatient } = usePatient(idPatient)

    // const { patientInfo } = usePatient(idPatient, true)

    const [currentTab, setActiveTab] = useState<number | string | undefined>(undefined)
    const userPermission = useSelector((state: any) => state?.permission?.referrals)
    const [openConfirm, setOpenConfirm] = useState(true)
    const dataIsAvailable = useSelector((state: any) => state?.permission?.isPermissionAvailable)

    const permissionAccessCheck = () => {
        if (viewType === "edit") {
            if (userPermission.includes('modify_referral')) {
                return true
            }
            return false
        } else if (viewType === "view") {
            if (userPermission.includes('view_referral_list')) {
                return true
            }
            return false
        }
    }
    const getAccess = permissionAccessCheck()
    useEffect(() => {
        permissionAccessCheck()
        if (location.pathname.endsWith('information')) {
            setActiveTab(0)
        } else if (location.pathname.endsWith('patient-account')) {
            setActiveTab(1)
        }
    }, [location.pathname])

    useEffect(() => {
        if (currentTab === 0) {
            window.history.replaceState(
                {},
                '',
                (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                    .replace(':idReferral', idReferral)
                    .replace(':idPatient', idPatient)
                    .replace(':viewType', viewType),
            )
        } else if (currentTab === 1) {
            window.history.replaceState(
                {},
                '',
                (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.PATIENT_ACCOUNT)
                    .replace(':idReferral', idReferral)
                    .replace(':idPatient', idPatient)
                    .replace(':viewType', viewType),
            )
        }
    }, [currentTab, location])

    return (
        <>
            {!dataIsAvailable ? (<LoadingPage />) : getAccess ?
                (
                    <Grid container className={classes.container} direction="column" wrap="nowrap">
                        <Grid
                            container
                            onClick={() => history.replace(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN)}
                            style={{
                                cursor: 'pointer',
                                width: 'fit-content',
                            }}>
                            <KeyboardBackspaceRoundedIcon
                                style={{
                                    color: '#5571C6',
                                    fontSize: 18,
                                }}
                            />
                            <Typography className={classes.text_back}>Back to Referral Queue</Typography>
                        </Grid>

                        <Grid
                            container
                            justifyContent="space-between"
                            style={{
                                height: 'calc(100% - 30px)',
                                marginTop: 16,
                            }}
                            spacing={2}
                        // wrap="nowrap"
                        >
                            <Grid
                                item
                                xs={12}
                                container
                                // className={classes.paper}
                                id="scroll-patient"
                            // style={{
                            //     paddingBottom: 0,
                            //     minWidth: 375,
                            //     marginRight: 7,
                            //     overflowY: 'auto',
                            // }}
                            >
                                <PatientInformation patientInfo={patientInfo} fetchPatient={fetchPatient} loadingPatient={loadingPatient} setLoadingPatient={setLoadingPatient} />
                            </Grid>

                            {/* Tabs */}
                            <Grid
                                item
                                xs={12}
                                container
                                direction="column"
                                // spacing={2}
                                wrap="nowrap"
                                style={{
                                    // marginBottom: 4,
                                    // height: '50%',
                                    height: '100%',
                                    padding: '0px',
                                }}>
                                <Tabs
                                    current={currentTab}
                                    style={{
                                        flex: 1,
                                        maxHeight: 'calc(100% - 50px)',
                                        overflowX: 'hidden',
                                        overflowY: 'auto',
                                        marginTop: '10px'
                                    }}
                                    tabs={[
                                        {
                                            label: 'Referral information',
                                            element: () => <ReferralsForm viewType={viewType} practiceId={patientInfo?.clinical_practice_id}/>,
                                        },
                                        {
                                            label: 'Patient Account',
                                            element: () => <GeneralPatientRecords />,
                                        },
                                    ]}
                                    onChange={(value) => { setActiveTab(+value) }}
                                    tabBoxProps={{
                                        backgroundColor: '#fff',
                                        borderRadius: '0px 0px 5px 5px',
                                        border: '1px solid #E4E8EE !important',
                                        paddingLeft: '23.03px',
                                        paddingBottom: '12px',
                                    }}
                                    sx={{
                                        "& .MuiTab-root.Mui-selected": {
                                            color: '#000',
                                            fontWeight: 'bold'
                                        },
                                        "& .MuiTabs-indicator": {
                                            height: '4px',
                                            borderRadius: '5px'
                                        },

                                        "& .MuiButtonBase-root": {
                                            marginRight: "35px !important"
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                )
                :
                <Confirm
                    open={openConfirm}
                    btnText="Confirm"
                    message={'Sorry! You do not have permission to access this feature.'}
                    onCancel={() => setOpenConfirm(false)}
                    headerSize={15}
                />
            }
        </>
    )
}
