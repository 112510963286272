import MomentUtils from '@date-io/moment'
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import * as Yup from 'yup'
import { materialTheme as theme } from '../../../utilities/calendar/style'
import { DATE_FORMAT, TIME_FORMAT } from '../constant'
import styles from '../styles'
import { generateRange } from '../utils'

export const validationSchema = Yup.object().shape({
    start_date: Yup.string().required(
        'start date is required',
    ),
    end_date: Yup.string().required('end date is required'),
    start_at: Yup.string().required('start at is required'),
    end_at: Yup.string().required('end at is required'),
})

function RequestTimeoffModal(props) {
    const formik = useFormik({
        initialValues: {
            current_date: moment(),
            start_date: null,
            end_date: null,
            start_at: '',
            end_at: '',
        },
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit,
    })

    const range = React.useMemo(() => {
        if (props.open) {
            const start_date = moment().set({
                hour: 0,
                minute: 0,
            })
            const end_date = moment().set({
                hour: 23,
                minute: 59,
            })

            const dates = generateRange({
                start_date,
                end_date,
                interval: 'minutes',
                step: 15,
            })

            return dates
        }
        return []

    }, [props.open])

    React.useEffect(() => {
        if (props.open) {
            const start_index = getInitialStartTime()
            formik.setFieldValue(
                'start_at',
                range[start_index + 1]
                    ? range[start_index]
                    : range[0],
            )
            formik.setFieldValue(
                'end_at',
                range[start_index + 1]
                    ? range[start_index + 1]
                    : range[1],
            )
        }
    }, [props.open])

    function getInitialStartTime() {
        const date = formik.values.current_date
        let output = 0

        for (let d = 0; d < range.length; d++) {
            if (range[d].isAfter(date)) {
                output = d
                break
            }
        }

        return output
    }

    function onSubmit(values) {
        props.onSubmit(values)
        formik.setFieldValue('start_date', null)
        formik.setFieldValue('end_date', null)
        setTimeout(() => {
            formik.setSubmitting(false)
        }, 2000)
    }

    function handleClose() {
        props.onModalClose()
        formik.setFieldValue('start_date', null)
        formik.setFieldValue('end_date', null)
    }

    function onFromDateChange(date) {
        formik.setFieldValue('start_date', date)
    }

    function onToDateChange(date) {
        formik.setFieldValue('end_date', date)
    }

    return (
        <Modal
            aria-labelledby="Request-Time-Off"
            aria-describedby="Request Time-Off Details"
            open={props.open}
            onClose={handleClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendarModal">
            <div className={props.classes.timeoff}>
                <header>
                    <span>Request Time-Off</span>
                    <CloseIcon
                        style={{
                            color: '#FFF',
                            cursor: 'pointer',
                        }}
                        onClick={handleClose}
                    />
                </header>
                <Grid
                    container
                    direction="column"
                    style={{ padding: 16 }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            direction="row"
                            alignItems={'flex-start'}
                            spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}>
                                    <ThemeProvider
                                        theme={theme}>
                                        <KeyboardDatePicker
                                            id="from"
                                            aria-label="from"
                                            name="from"
                                            disableToolbar
                                            variant="inline"
                                            format={
                                                'MM/DD/YYYY'
                                            }
                                            placeholder={
                                                'From'
                                            }
                                            inputVariant="outlined"
                                            value={
                                                formik
                                                    .values
                                                    .start_date
                                            }
                                            minDate={
                                                formik
                                                    .values
                                                    .current_date
                                            }
                                            maxDate={
                                                formik
                                                    .values
                                                    .end_date ||
                                                undefined
                                            }
                                            onChange={
                                                onFromDateChange
                                            }
                                            autoOk
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="start_at"
                                        id="start_at"
                                        select
                                        SelectProps={{
                                            style: {
                                                height: 54,
                                            },
                                            disableUnderline: true,
                                        }}
                                        className="timeoff-dropdown"
                                        value={
                                            formik.values
                                                .start_at
                                        }
                                        onChange={formik.handleChange(
                                            'start_at',
                                        )}
                                        fullWidth>
                                        {range
                                            .filter(
                                                (date) => {
                                                    if (
                                                        formik.values.start_date?.format(
                                                            DATE_FORMAT,
                                                        ) ===
                                                        formik.values.current_date?.format(
                                                            DATE_FORMAT,
                                                        )
                                                    ) {
                                                        if (
                                                            date.isAfter(
                                                                formik
                                                                    .values
                                                                    .current_date,
                                                            )
                                                        ) {
                                                            return true
                                                        }
                                                        return false
                                                    }
                                                    return true
                                                },
                                            )
                                            .map(
                                                (
                                                    date,
                                                    index,
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            key={
                                                                index
                                                            }
                                                            value={
                                                                date
                                                            }>
                                                            {date.format(
                                                                'hh:mm A',
                                                            )}
                                                        </MenuItem>
                                                    )
                                                },
                                            )}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            alignItems={'flex-start'}
                            spacing={2}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider
                                    libInstance={moment}
                                    utils={MomentUtils}>
                                    <ThemeProvider
                                        theme={theme}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            id="to"
                                            aria-label="to"
                                            name="to"
                                            variant="inline"
                                            format={
                                                'MM/DD/YYYY'
                                            }
                                            placeholder={
                                                'To'
                                            }
                                            inputVariant="outlined"
                                            value={
                                                formik
                                                    .values
                                                    .end_date
                                            }
                                            minDate={
                                                formik
                                                    .values
                                                    .start_date ||
                                                formik
                                                    .values
                                                    .current_date
                                            }
                                            onChange={
                                                onToDateChange
                                            }
                                            autoOk
                                        />
                                    </ThemeProvider>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        name="end_at"
                                        id="end_at"
                                        select
                                        value={
                                            formik.values
                                                .end_at
                                        }
                                        onChange={formik.handleChange(
                                            'end_at',
                                        )}
                                        SelectProps={{
                                            style: {
                                                height: 54,
                                            },
                                            disableUnderline: true,
                                        }}
                                        className="timeoff-dropdown"
                                        fullWidth>
                                        {range
                                            .filter(
                                                (date) =>
                                                    formik
                                                        .values
                                                        .start_at
                                                        ? date.isAfter(
                                                            formik
                                                                .values
                                                                .start_at,
                                                        )
                                                        : true,
                                            )
                                            .map(
                                                (
                                                    date,
                                                    index,
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            key={
                                                                index
                                                            }
                                                            value={
                                                                date
                                                            }>
                                                            {date.format(
                                                                'hh:mm A',
                                                            )}
                                                        </MenuItem>
                                                    )
                                                },
                                            )}
                                    </TextField>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="center"
                            style={{ marginTop: 20 }}>
                            {props.loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !(
                                            formik.isValid &&
                                            formik.dirty
                                        )
                                    }>
                                    Submit
                                </Button>
                            )}
                        </Grid>
                    </form>
                </Grid>
            </div>
        </Modal>
    )
}

export default withStyles(styles)(RequestTimeoffModal)
