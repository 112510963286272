const ArchivedIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9388_24883)">
                <path
                    d="M11.25 16.5H6.75C3 16.5 1.5 15 1.5 11.25V6.75C1.5 3 3 1.5 6.75 1.5H11.25C15 1.5 16.5 3 16.5 6.75V11.25C16.5 15 15 16.5 11.25 16.5Z"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5 9.75018H13.68C13.11 9.75018 12.5925 10.0727 12.3375 10.5827L11.67 11.9252C11.25 12.7502 10.5 12.7502 10.32 12.7502H7.6725C7.1025 12.7502 6.585 12.4277 6.33 11.9177L5.6625 10.5752C5.4075 10.0652 4.89 9.74268 4.32 9.74268H1.515"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.2451 5.25H7.74762"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.875 7.5H7.125"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9388_24883">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="matrix(-1 0 0 1 18 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArchivedIcon
