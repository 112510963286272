import { Grid, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import { DATE_FORMAT } from '../constant'

export function CustomDateHeader(props) {
    const currentDate = moment(props.date)
    // const appointment = props.appointments?.find(
    //     (appointment) =>
    //         moment(appointment.date).format(DATE_FORMAT) === currentDate.format(DATE_FORMAT),
    // )

    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
                <a href="#">{props.label}</a>
            </Grid>
            {/* <Grid
                item
                xs={3}
                className="app-count-wrap"
                onClick={(e) => {
                    e.stopPropagation();
                    props.onViewAppt(appointment.date);
                }}
            >
                {appointment && (
                    <Tooltip
                        title={'Appointment Count'}
                        placement="top"
                    >
                        <Typography
                            noWrap
                            className="appointmentCount"
                        >
                            {appointment.appointment_count}
                        </Typography>
                    </Tooltip>
                )}
            </Grid> */}
        </Grid>
    )
}
