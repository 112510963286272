import { CircularProgress, IconButton } from '@material-ui/core'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import 'moment-timezone'
import { useFormik } from 'formik'
import moment from 'moment'
import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from 'react'

import RefreshIcon from 'assests/icons/RefreshIcon'
import { Select } from 'shared/elements/FormItem/Select'
import TextBox from 'shared/elements/FormItem/TextBox'
import Modal from 'shared/elements/Modal'
import {
    postAvailability,
    getAvailability,
    putAvailability,
    deleteAvailability,
    postOccuranceAvailability,
} from 'services/ProviderDirectory'
import { useNotification } from 'shared/elements/Notification'
import ArrowRight from 'assests/icons/ArrowRight'
import DeleteIcon from 'assests/icons/DeleteIcon'

import {
    OPTIONS_TYPE_DATE,
    initialValues as previousInitialValues,
    initialValuesV2 as initialValues,
    days as DAYS,
    days,
} from './constants'
import {
    DatePicker,
    EndRepeat,
    RepeatOne,
    SelectTimezone,
    SubmitFooter,
    SwitchLable,
    TextError,
} from './components'
import { validationSchemaV2 as validationSchema } from './validation'
import './styles.scss'
import { NewTimePicker } from 'shared/elements'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import { Close } from '@material-ui/icons'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { SelectSearchOptionsTimezone } from 'shared/elements/FormItem/SelectSearchOptionsTimezone'
import { useTimezone } from 'shared/hooks/useTimezone'

const formatDatetime = (date, timezone) => {
    const input = `${date}`
    const fmt = 'MM/DD/YYYY HH:mm'
    const utc = moment.tz(input, fmt, timezone).utc().format()
    return utc
}

const generateDayLabel = (dayName) => {
    return DAYS.find((item) => item.key === dayName)
}

const PerAvailability = forwardRef((props, ref) => {
    const { formik, editMode } = props

    const notification = useNotification()

    useEffect(() => {
        // if (!props.id) {
        //     const { days } = formik.values
        //     const modifiedDays = days.map((day) =>
        //         moment().format('dddd') === generateDayLabel(day?.day)?.label
        //             ? {
        //                   ...day,
        //                   available: 1,
        //                   options: [
        //                       {
        //                           start_time: moment().format('HH:mm'),
        //                           end_time: moment().format('HH:mm'),
        //                       },
        //                   ],
        //               }
        //             : { ...day, available: 0 },
        //     )
        //     formik.setValues({
        //         ...formik.values,
        //         days: modifiedDays,
        //     })
        // } else {
        if (props?.id) {
            (async () => {
                try {
                    props.setLoading?.(true)
                    getAvailability(props.providerId, props.id)
                        ?.then((res) => {
                            const availability = res.data?.data[0]
                            if (!availability) notification('Something went wrong!', 'error')
                            const getEx = availability?.exception?.find(
                                (ex) =>
                                    moment(props?.currentEvent?.start).format('DD/MM/YYYY') ===
                                    moment(ex?.start_date).format('DD/MM/YYYY'),
                            )
                            formik.setValues({
                                start_date: moment(availability?.start_date).format('MM/DD/YYYY'),
                                end_date: moment(availability?.end_date).format('MM/DD/YYYY'),
                                is_recur: availability?.is_recur,
                                timezone: availability?.timezone,
                                days: availability?.days?.map((day) => {
                                    return {
                                        ...day,
                                        options: [
                                            {
                                                id: day?.options[0]?.id,
                                                start_time:
                                                    getEx &&
                                                        editMode === 'occurance' &&
                                                        day?.options[0]?.id ===
                                                        getEx?.new_provider_availability_id
                                                        ? moment(
                                                            `${moment().format('YYYY-MM-DD')} ${getEx?.start_time
                                                            }`,
                                                        ).format('HH:mm')
                                                        : moment(
                                                            `${moment().format('YYYY-MM-DD')} ${day?.options[0]?.start_time
                                                            }`,
                                                        ).format('HH:mm'),
                                                end_time:
                                                    getEx &&
                                                        editMode === 'occurance' &&
                                                        day?.options[0]?.id ===
                                                        getEx?.new_provider_availability_id
                                                        ? moment(
                                                            `${moment().format('YYYY-MM-DD')} ${getEx?.end_time
                                                            }`,
                                                        ).format('HH:mm')
                                                        : moment(
                                                            `${moment().format('YYYY-MM-DD')} ${day?.options[0]?.end_time
                                                            }`,
                                                        ).format('HH:mm'),
                                            },
                                        ],
                                    }
                                }),
                            })
                        })
                        ?.catch(() => notification('Something went wrong!', 'error'))
                        ?.finally(() => props.setLoading?.(false))
                } catch (error) {
                    notification('Something went wrong!', 'error')
                } finally {
                    props.setLoading?.(false)
                }
            })()
        }
    }, [props?.id])

    return (
        <div className="sub-content-form-availability">
            {formik?.values?.days?.map((day, dayIndex) => {
                return (
                    <div className="row" container key={day.value}>
                        <div
                            className="columnLeft"
                            style={{
                                position: 'relative',
                                top: 10,
                                alignSelf: 'flex-start',
                            }}>
                            <Box sx={{ marginBottom: '10px' }}>
                                <SwitchLable
                                    label={generateDayLabel(day?.day)?.label}
                                    value={day?.available}
                                    onChange={(e) => {
                                        const checkedValue = e.target.checked ? 1 : 0
                                        if (e.target.checked) {
                                            formik.setFieldValue(
                                                `days[${dayIndex}].options[0].start_time`,
                                                '08:00',
                                            )
                                            formik.setFieldValue(
                                                `days[${dayIndex}].options[0].end_time`,
                                                '17:00',
                                            )
                                        } else {
                                            formik.setFieldValue(
                                                `days[${dayIndex}].options[0].start_time`,
                                                '00:00',
                                            )
                                            formik.setFieldValue(
                                                `days[${dayIndex}].options[0].end_time`,
                                                '00:00',
                                            )
                                        }
                                        formik.setFieldValue(
                                            `days[${dayIndex}].available`,
                                            checkedValue,
                                        )
                                    }}
                                    disabled={
                                        (!formik.values.is_recur &&
                                            moment(formik.values?.start_date).format('dddd') !==
                                            generateDayLabel(day?.day)?.label) ||
                                        (editMode === 'occurance' &&
                                            moment(props?.currentEvent?.start).format('dddd') !==
                                            generateDayLabel(day?.day)?.label)
                                    }
                                />
                            </Box>
                        </div>
                        {day?.options?.map((timeItem, timeItemIndex) => {
                            return (
                                <>
                                    <div className="columnRight" key={timeItemIndex}>
                                        <div className="timepicker">
                                            <span
                                                style={{
                                                    color: '#667689',
                                                    fontSize: '13px',
                                                    fontWeight: '400',
                                                    padding: '0 10px',
                                                }}>
                                                From
                                            </span>
                                            <NewTimePicker
                                                makeEditable
                                                values={moment(
                                                    `${moment().format('YYYY-MM-DD')} ${timeItem?.start_time
                                                    }`,
                                                    'YYYY-MM-DD HH:mm',
                                                )}
                                                onChange={(value) => {
                                                    formik.setFieldValue(
                                                        `days[${dayIndex}].options[0].start_time`,
                                                        moment(value).format('HH:mm'),
                                                    )
                                                }}
                                                disabled={
                                                    !day?.available ||
                                                    (!formik.values.is_recur &&
                                                        moment(formik.values?.start_date).format(
                                                            'dddd',
                                                        ) !== generateDayLabel(day?.day)?.label) ||
                                                    (editMode === 'occurance' &&
                                                        moment(props?.currentEvent?.start).format(
                                                            'dddd',
                                                        ) !== generateDayLabel(day?.day)?.label)
                                                }
                                                isBeforeNotCheck={true}
                                                style={{
                                                    background: '#ffffff',
                                                }}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                            <ArrowRight />
                                        </div>

                                        <div className="timepicker">
                                            <span
                                                style={{
                                                    color: '#667689',
                                                    fontSize: '13px',
                                                    fontWeight: '400',
                                                    padding: '0 10px',
                                                }}>
                                                To
                                            </span>
                                            <NewTimePicker
                                                makeEditable
                                                values={moment(
                                                    `${moment().format('YYYY-MM-DD')} ${timeItem?.end_time
                                                    }`,
                                                    'YYYY-MM-DD HH:mm',
                                                )}
                                                disabled={
                                                    !day?.available ||
                                                    (!formik.values.is_recur &&
                                                        moment(formik.values?.start_date).format(
                                                            'dddd',
                                                        ) !== generateDayLabel(day?.day)?.label) ||
                                                    (editMode === 'occurance' &&
                                                        moment(props?.currentEvent?.start).format(
                                                            'dddd',
                                                        ) !== generateDayLabel(day?.day)?.label)
                                                }
                                                isBeforeNotCheck={true}
                                                onChange={(value) => {
                                                    formik.setFieldValue(
                                                        `days[${dayIndex}].options[0].end_time`,
                                                        moment(value).format('HH:mm'),
                                                    )
                                                }}
                                                style={{
                                                    border: 'none',
                                                    background: 'unset',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <span>{formik.errors?.start_time}</span> */}
                                </>
                            )
                        })}

                        {/* <Grid
                            item
                            xs={5 / 2}
                            style={{
                                position: 'relative',
                                top: 10,
                                alignSelf: 'flex-start',
                            }}>
                            <Box className="add-new-availability">
                                <span
                                    onClick={() => {
                                        setAvailabilities((prev) => [...prev, prev.length])
                                    }}>
                                    <AddCircleOutlineIcon /> Add New
                                </span>
                            </Box>
                        </Grid> */}
                    </div>
                )
            })}
        </div>
    )
})

export const AddNewAvailability = ({
    open,
    onClose,
    onOke,
    id,
    providerId,
    editMode,
    setEditMode,
    currentEvent,
}) => {
    const [num, setNum] = useState(0)
    const notification = useNotification()
    const { locationTimezone } = useTimezone()

    const itemRefs = useRef([])

    const [loading, setLoading] = useState(false)

    const [submitting, setSubmitting] = useState(false)
    const [deleting, setDeleting] = useState(false)

    const handleDelete = async () => {
        try {
            setDeleting(true)
            if (editMode === 'occurance') {
                const { is_recur, timezone, start_date, end_date } = formik.values
                const startDate = formatDatetime(start_date, timezone)
                const endDate = formatDatetime(is_recur ? end_date : start_date, timezone)
                const getDay = formik?.values?.days?.find(
                    (day) =>
                        moment(currentEvent?.start).format('dddd') ===
                        generateDayLabel(day?.day)?.label,
                )
                const deletePayLoad = {
                    start_date: formatDatetime(
                        moment(currentEvent?.start).format('MM/DD/YYYY'),
                        timezone,
                    ),
                    end_date: formatDatetime(
                        moment(currentEvent?.end).format('MM/DD/YYYY'),
                        timezone,
                    ),
                    new_provider_availability_id: getDay?.options[0]?.id,
                    day: getDay.day,
                    start_time: getDay?.options[0]?.start_time,
                    end_time: getDay?.options[0]?.end_time,
                    exception_type: 'DELETED'
                }
                const res = await postOccuranceAvailability(providerId, id, deletePayLoad)
                if (res?.data?.status) {
                    notification(res?.data?.message || 'Update Successfully', 'success')
                } else {
                    notification(res?.data?.message || 'somthing is wrong', 'error')
                }
            } else {
                await deleteAvailability(providerId, id)
            }
            notification(`Delete availability successfully`, 'success')
            onOke?.()
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setDeleting(false)
        }
    }

    const handleItemRef = useCallback(
        (index, ref) => {
            itemRefs.current[index] = ref
        },
        [itemRefs.current],
    )

    const handleSubmit = async () => {
        const { is_recur, timezone, start_date, end_date } = formik.values
        const startDate = formatDatetime(start_date, timezone)
        const endDate = formatDatetime(is_recur ? end_date : start_date, timezone)
        const editPayload = id
            ? {
                edit_mode: editMode,
                current_date: moment(currentEvent?.start).format('YYYY-MM-DDTHH:mm:ssZ'),
            }
            : {}
        const payload = {
            ...formik.values,
            start_date: moment(startDate).startOf('day').utc().format(),
            end_date: moment(endDate).endOf('day').utc().format(),
            ...editPayload,
        }
        try {
            setSubmitting(true)
            if (id) {
                if (editMode === 'occurance' && is_recur) {
                    const getDay = formik?.values?.days?.find(
                        (day) =>
                            moment(currentEvent?.start).format('dddd') ===
                            generateDayLabel(day?.day)?.label,
                    )
                    const editPlayLoad = {
                        start_date: formatDatetime(
                            moment(currentEvent?.start).format('MM/DD/YYYY'),
                            timezone,
                        ),
                        end_date: formatDatetime(
                            moment(currentEvent?.end).format('MM/DD/YYYY'),
                            timezone,
                        ),
                        new_provider_availability_id: getDay?.options[0]?.id,
                        day: getDay.day,
                        start_time: getDay?.options[0]?.start_time,
                        end_time: getDay?.options[0]?.end_time,
                    }
                    const res = await postOccuranceAvailability(providerId, id, editPlayLoad)
                    if (res?.data?.status) {
                        notification(res?.data?.message || 'Update Successfully', 'success')
                    } else {
                        notification(res?.data?.message || 'somthing is wrong', 'error')
                    }
                } else {
                    const res = await putAvailability(providerId, id, payload)
                    if (res?.data?.status) {
                        notification(res?.data?.message || 'Update Successfully', 'success')
                    } else {
                        notification(res?.data?.message || 'somthing is wrong', 'error')
                    }
                }
            } else {
                const res = await postAvailability(providerId, payload)
                if (res?.data?.status) {
                    notification(res?.data?.message || 'Add Successfully', 'success')
                } else {
                    notification(res?.data?.message || 'somthing is wrong', 'error')
                }
            }
        } catch (err) {
            notification('somthing is wrong', 'error')
        } finally {
            setSubmitting(false)
            onOke?.()
        }
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: false,
        initialValues: initialValues(),
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        if (num) {
            formik.validateForm()
        }
    }, [formik.values, num])

    const ModalTitle = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', margin: '0 40px', gap: 10 }}>
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none">
                        <path
                            d="M12.53 2C7.82664 2 4 5.82664 4 10.53C4 15.2334 7.82664 19.06 12.53 19.06C17.2334 19.06 21.06 15.2334 21.06 10.53C21.06 5.82664 17.2334 2 12.53 2ZM12.53 18.4565C8.15951 18.4565 4.60351 14.9005 4.60351 10.53C4.60351 6.15951 8.15919 2.60351 12.53 2.60351C16.9009 2.60351 20.4565 6.15919 20.4565 10.53C20.4565 14.9009 16.9009 18.4565 12.53 18.4565Z"
                            fill="#667689"
                        />
                        <path
                            d="M12.8735 10.4461C12.8565 10.4272 12.8332 10.3706 12.8318 10.3465L12.8317 5.04382C12.8317 4.87722 12.6965 4.74219 12.5299 4.74219C12.3633 4.74219 12.2281 4.87722 12.2281 5.04382V10.3465C12.2281 10.5279 12.3178 10.7442 12.4457 10.8722L15.5162 13.9424C15.5753 14.0014 15.6526 14.0311 15.7297 14.0311C15.8066 14.0311 15.884 14.0013 15.9431 13.9424C16.0607 13.8244 16.0607 13.6334 15.9431 13.5156L12.8735 10.4461Z"
                            fill="#667689"
                        />
                    </svg>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 5,
                        alignItems: 'flex-start',
                    }}>
                    <Typography
                        style={{
                            fontWeight: 500,
                            fontSize: 18,
                            color: '#303E4E',
                        }}>
                        Clinical Hours{' '}
                    </Typography>
                    <Typography
                        style={{
                            fontWeight: 400,
                            fontSize: 16,
                            color: '#667689',
                        }}>
                        Choose the hours and days of the week you would like to add availability for
                        clinical hours.
                    </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '10%' }}>
                    <IconButton onClick={() => onClose?.()}>
                        <Close style={{ color: '#667689', height: 20, width: 20 }} />
                    </IconButton>
                </div>
            </div>
        )
    }

    const renderError = () => {
        const allErrors = []
        Object.keys(formik?.errors)?.forEach((errorItem) => {
            if (errorItem === 'days' && typeof formik?.errors?.days === 'object') {
                formik?.errors?.days?.forEach((daysError, daysErrorIndex) => {
                    Object.keys(daysError)?.forEach((singleDayError) => {
                        if (singleDayError === 'options') {
                            daysError[singleDayError].forEach((optionsError) => {
                                Object.values(optionsError)?.forEach((timeError) => {
                                    allErrors.push(
                                        `${timeError} for ${DAYS[daysErrorIndex + 1]?.label}`,
                                    )
                                })
                            })
                        } else {
                            allErrors.push(daysError[singleDayError])
                        }
                    })
                })
            } else {
                allErrors.push(formik?.errors[errorItem])
            }
        })
        return allErrors
    }

    const onTimeZoneChange = (timezone) => {
        formik.setFieldValue('timezone', timezone?.key || locationTimezone)
    }

    return (
        <>
            <Modal
                width={900}
                // title={id ? 'Update Availability' : 'Add New Availability'}
                title={<ModalTitle />}
                disableRestoreFocus={false}
                disableFocusListener={false}
                open={open}
                onClose={() => onClose?.()}
                className="outpatient-psychiatric-history-form">
                {loading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}>
                        <CircularProgress />
                    </Box>
                )}
                <Box className="wrap-form-availability" sx={{ marginTop: 0 }}>
                    <Box className="content-time-zone">
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                            }}>
                            <span
                                style={{
                                    color: '#303E4E',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}>
                                Time Zone
                            </span>
                            <span
                                style={{
                                    color: '#667689',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}>
                                Set your current timezone
                            </span>
                        </Grid>
                        <Box
                            sx={{
                                '& .MuiAutocomplete-inputRoot': {
                                    height: '40px !important',
                                },
                                width: '44.5%',
                            }}>
                            {/* <SelectTimezone
                                value={formik.values.timezone}
                                onChange={(value) => {
                                    if (value) {
                                        formik.setFieldValue('timezone', value)
                                    }
                                }}
                                placeholder="Select Timezone"
                            /> */}
                            <SelectSearchOptionsTimezone
                                placeholder="Select Timezone"
                                onChangeCustom={onTimeZoneChange}
                            />
                        </Box>
                    </Box>
                    <Box className="content-date-range">
                        <Grid
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                            }}>
                            <span
                                style={{
                                    color: '#303E4E',
                                    fontSize: '16px',
                                    fontWeight: 500,
                                }}>
                                Date
                            </span>
                            <span
                                style={{
                                    color: '#667689',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                }}>
                                Select the date or date range to set availability for.
                            </span>
                        </Grid>
                        <Box
                            sx={{
                                '& .MuiAutocomplete-inputRoot': {
                                    height: '40px !important',
                                },
                                width: formik.values.is_recur ? '50%' : '35%',
                            }}>
                            <div className="dateSection">
                                <SwitchLable
                                    value={formik?.values?.is_recur}
                                    onChange={(event) => {
                                        formik.setFieldValue('is_recur', event.target.checked)
                                    }}
                                    disabled={!!id || editMode === 'occurance'}
                                />
                                <SingleCalenderComp
                                    onChange={(e) => {
                                        const currentDay = moment(e),
                                            newDaysValues = formik.values?.days?.map((dayItem) => {
                                                if (
                                                    currentDay?.format('dddd') ===
                                                    generateDayLabel(dayItem.day)?.label
                                                ) {
                                                    dayItem.available = 1
                                                } else {
                                                    dayItem.available = 0
                                                }
                                                dayItem.options = [
                                                    {
                                                        start_time: '08:00',
                                                        end_time: '17:00',
                                                    },
                                                ]
                                                return dayItem
                                            })
                                        formik.setFieldValue('days', newDaysValues)
                                        formik.setFieldValue(
                                            'start_date',
                                            currentDay.format('MM/DD/YYYY'),
                                        )
                                    }}
                                    // HideShowIcon={formik?.values?.is_recur}
                                    disabled={editMode === 'occurance'}
                                    value={
                                        editMode === 'occurance'
                                            ? moment(currentEvent?.start).format('MM/DD/YYYY')
                                            : formik.values?.start_date
                                    }
                                    onClear={() => {
                                        formik.setFieldValue('start_date', '')
                                    }}
                                />
                                {formik.values?.is_recur && editMode !== 'occurance' ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <ArrowRight />
                                    </div>
                                ) : (
                                    <></>
                                )}

                                {formik.values?.is_recur && editMode !== 'occurance' ? (
                                    <SingleCalenderComp
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                'end_date',
                                                moment(e).format('MM/DD/YYYY'),
                                            )
                                        }}
                                        // HideShowIcon={formik?.values?.is_recur}
                                        value={formik.values?.end_date}
                                        onClear={() => {
                                            formik.setFieldValue('end_date', '')
                                        }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </Box>
                    </Box>
                    <Box className="content-form-availability">
                        <PerAvailability
                            // style={{
                            //     marginTop: index && 24,
                            // }}
                            // ref={(ref) => handleItemRef(index, ref)}
                            // key={index}
                            id={id}
                            setLoading={setLoading}
                            num={num}
                            providerId={providerId}
                            formik={formik}
                            editMode={editMode}
                            currentEvent={currentEvent}
                        />
                    </Box>

                    {/*-------------------------------------Error Vallidation-------------------------------------*/}
                    {renderError()?.length > 0 && (
                        <Box
                            style={{
                                borderLeft: '3px solid #E81212',
                                background: '#FFD6D6',
                                padding: '5px 10px',
                                marginBottom: 20,
                                borderRadius: 5,
                            }}>
                            {renderError()?.map((item) => {
                                return (
                                    <div
                                        key={item}
                                        style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                        <FiberManualRecordIcon
                                            style={{ height: 10, width: 10, color: '#E81212' }}
                                        />
                                        <Typography style={{ fontWeight: 400, color: '#E81212' }}>
                                            {item}
                                        </Typography>
                                    </div>
                                )
                            })}
                        </Box>
                    )}

                    <SubmitFooter
                        onClose={onClose}
                        disabled={submitting || deleting}
                        loading={submitting}
                        id={id}
                        editMode={editMode}
                        deleting={deleting}
                        onDelete={handleDelete}
                        onSubmit={formik.handleSubmit}
                        handleSetDefaultEditMode={() => setEditMode('')}
                    />
                </Box>
            </Modal>
        </>
    )
}
