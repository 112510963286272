import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles, Typography, Button, Grid } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_cancel: {
        background: '#303E4E',
        width: '230px',
        fontSize: 15,
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
}))

export default function AlertComfirm({ open, handleClose, message }) {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>
                    {message || "Can't add duplicate code"}
                </Typography>
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" className={classes.btn_cancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}
