import { Box, Button, Grid, makeStyles, styled, Tab, Tabs } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { getScheduleById } from '../../services/Calendar'
import { ModalEl } from '../../shared/elements'
import { INITIAL_VALUES } from './schema'
import './styles.scss'
import Patient from './Views/Patient'
import Staff from './Views/Staff'
import { useTimezone } from 'shared/hooks/useTimezone'

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    margin: '0 20px',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
    },
    // "& .MuiTabs-flexContainer": {
    //     width: '100%'
    // }
})

const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'unset !important',
    fontFamily: 'Rubik',
    fontSize: 16,
    backgroundColor: 'none',
    minWidth: 0,
    fontWeight: '500',
    // marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    maxWidth: '50%',
    width: '50% ',
    '&:hover': {
        color: '#5571C6',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#5571C6',
        fontWeight: 500,
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
    },
    prevent_edit: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        background: '#0000000f',
        width: '100%',
        height: '100%',
    },
}))

function AppointmentBooking({
    locationOptionsData,
    visitTypeOptionsData,
    appointmentModal,
    statusOptionsData,
    addFormCardAppointment,
    loading,
    isFromClinicalNote,
    followUp = false,
    ...props
}) {
    const [value, setValue] = React.useState(0)
    const [loadingSchedule, setLoadingSchedule] = React.useState(false)
    const [initData, setInitData] = React.useState(INITIAL_VALUES)

    const timezone = useTimezone()

    const classes = useStyles()
    const handleChange = (newValue) => {
        setValue(newValue)
    }
    async function getASchedule(idEvent) {
        try {
            setLoadingSchedule(true)
            const response = await getScheduleById({
                id: idEvent,
            })
            if (response?.data?.id) {
                const {
                    id,
                    appointment_type,
                    appointment_for,
                    reason_for_visit,
                    internal_note,
                    duration,
                    start,
                    end,
                    category,
                    specialty,
                    practitioner_id,
                    participant,
                    room_id,
                    location_id,
                    is_require_translator,
                    is_send_reminder,
                    is_need_complete_lab,
                    visit_type_id,
                    appointment_status,
                    patient_status,
                    priority,
                    checklists,
                } = response?.data
                setValue(appointment_for.toLowerCase() === 'staff' ? 1 : 0)
                setInitData({
                    ...INITIAL_VALUES,
                    id,
                    appointment_for: appointment_for.toLowerCase(),
                    category,
                    specialty_id: specialty?.id,
                    appointment_type,
                    visitType: visit_type_id,
                    duration,
                    patient: participant[0]?.participant_id,
                    appointment_reason: reason_for_visit,
                    location: location_id,
                    room: room_id,
                    provider: practitioner_id,
                    start: moment(start),
                    end: moment(end),
                    isRequireTranslator: is_require_translator,
                    additional_info: internal_note,
                    isSendReminder: is_send_reminder,
                    isCompleted: is_need_complete_lab,
                    appointment_status,
                    patient_status,
                    priority,
                    checklists,
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }

    const getValidationSchema = () => {
        if (value === 0) {
            return Yup.object({
                visitType: Yup.string().required('This field is required!'),
                patient: Yup.string().required('This field is required!'),
                provider: Yup.string().required('This field is required!'),
                duration: Yup.number().required().min(1, 'This field is required!'),
                location: Yup.string().required('This field is required!'),
            })
        }
        if (value === 1) {
            return Yup.object({
                provider: Yup.string().required('This field is required!'),
                start: Yup.date()
                    .required('This field is required!')
                    .test({
                        name: 'max-start',
                        exclusive: false,
                        params: {},
                        test(value, context) {
                            if (!value) return true
                            const start = moment(value)
                            if (start > moment(context.parent.end)) {
                                return this.createError({
                                    message: 'Please enter start time before end time.',
                                    path: 'start',
                                })
                            }
                            return true
                        },
                    }),
            })
        }
    }

    const handleSubmit = (values) => {
        const {
            appointment_for,
            isCompleted,
            isSendReminder,
            isRequireTranslator,
            location,
            room,
            duration,
            appointment_type,
            start,
            end,
            provider,
            visitType,
            category,
            patient,
            specialty_id,
            additional_info,
            appointment_reason,
            keyword,
            appointment_status,
            patient_status,
            priority,
            checklists,
        } = values
        const payloadPatient = {
            specialty_id,
            appointment_for,
            appointment_type,
            start: start.clone().utc().set('seconds', 0).format(),
            end: end.clone().utc().set('seconds', 0).format(),
            visit_type_id: visitType,
            practitioner_id: provider,
            keyword,
            duration,
            internal_note: additional_info,
            reason_for_visit: appointment_reason,
            location_id: location,
            room_id: room,
            is_require_translator: isRequireTranslator,
            is_send_reminder: isSendReminder,
            is_need_complete_lab: isCompleted,
            participants: patient
                ? [
                    {
                        status: 'accepted',
                        role: 'Patient',
                        participant_id: patient,
                    },
                ]
                : [],
            appointment_status,
            patient_status,
            priority,
            checklists,
            timezone: timezone.locationTimezone,
        }

        const payloadStaff = {
            appointment_for,
            category,
            internal_note: additional_info,
            start,
            end,
            practitioner_id: provider,
            timezone: timezone.locationTimezone,
        }
        if (appointment_for.toLowerCase() === 'patient') {
            props.onSubmit(payloadPatient)
        } else {
            props.onSubmit(payloadStaff)
        }
    }

    useEffect(() => {
        if (appointmentModal?.id) {
            getASchedule(appointmentModal?.id)
        } else {
            setInitData({
                ...INITIAL_VALUES,
                patient: appointmentModal?.patientId,
                provider: appointmentModal?.providerId,
                start: moment(appointmentModal?.start),
                end: moment(appointmentModal?.end),
                appointment_for: value === 0 ? 'patient' : 'staff',
            })
        }
    }, [appointmentModal.id, props.open])

    // useEffect(() => {
    //     if (!appointmentModal?.id) {
    //         setInitData({
    //             ...INITIAL_VALUES,
    //             patient: appointmentModal?.patientId,
    //             provider: appointmentModal?.providerId,
    //             start: moment(),
    //             end: moment(),
    //             appointment_for: value === 0 ? 'patient' : 'staff',
    //         })
    //     }
    // }, [value, appointmentModal?.id])

    return (
        <ModalEl
            title={
                followUp
                    ? 'Follow-up appointment'
                    : appointmentModal.id
                        ? 'Update Appointment'
                        : 'Add Appointment'
            }
            // title={appointmentModal.id ? 'Update Appointment' : 'Add Appointment'}
            description="Booking Appointment Details"
            open={props.open}
            onClose={props.onModalClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {(loadingSchedule || props.dataLoading) && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}
                <Formik
                    initialValues={initData}
                    enableReinitialize
                    validationSchema={getValidationSchema()}
                    onSubmit={handleSubmit}>
                    {({ values, setFieldValue, errors, touched, handleSubmit }) => {
                        const editable = values.end.diff(moment().clone()) > 0

                        return (
                            <Grid container item>
                                <Box
                                    sx={{
                                        width: '100%',
                                        '&.MuiTabs-flexContainer': {
                                            width: '100%',
                                        },
                                    }}>
                                    {!followUp && (
                                        <AntTabs
                                            value={value}
                                            onChange={(event, newValue) => {
                                                handleChange(newValue)
                                                setFieldValue(
                                                    'appointment_for',
                                                    newValue === 0 ? 'patient' : 'staff',
                                                )
                                            }}
                                            aria-label="basic tabs example">
                                            <AntTab
                                                disabled={!!appointmentModal.id}
                                                label="Patient"
                                                value={0}
                                            />
                                            {!addFormCardAppointment && !isFromClinicalNote && (
                                                <AntTab
                                                    disabled={!!appointmentModal.id}
                                                    label="Staff"
                                                    value={1}
                                                />
                                            )}
                                        </AntTabs>
                                    )}
                                </Box>
                                <div
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                    }}>
                                    {value === 0 && (
                                        <Patient
                                            followUp={followUp}
                                            addFormCardAppointment={addFormCardAppointment}
                                            isUpdating={appointmentModal.id}
                                            editable={editable}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            locationOptionsData={locationOptionsData}
                                            appointmentModal={appointmentModal}
                                            visitTypeOptionsData={visitTypeOptionsData}
                                            statusOptionsData={statusOptionsData}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    )}
                                    {value === 1 && (
                                        <Staff
                                            events={props.events}
                                            label={props.label}
                                            values={values}
                                            appointmentModal={appointmentModal}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            touched={touched}
                                            handleSubmit={handleSubmit}
                                        />
                                    )}
                                    {/* {!editable && appointmentModal.id && (
                                        <div className={classes.prevent_edit} />
                                    )} */}
                                </div>

                                <Grid
                                    container
                                    item
                                    justifyContent="flex-end"
                                    direction="row"
                                    spacing={2}
                                    style={{
                                        margin: '0 10px',
                                    }}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant="contained"
                                            className={classes.button_cancel}
                                            onClick={props.onModalClose}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <LoadingButton
                                            loading={loading}
                                            disabled={!editable && appointmentModal.id}
                                            loadingPosition="start"
                                            startIcon={<></>}
                                            variant="contained"
                                            className={classes.button_create}
                                            onClick={handleSubmit}>
                                            {appointmentModal.id ? 'Update' : 'Create'}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }}
                </Formik>
            </Grid>
        </ModalEl>
    )
}

export default AppointmentBooking
