import { Box, Button, Grid, makeStyles, styled, Tabs, Tab, Typography } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { getScheduleById } from '../../services/Calendar'
import { ModalEl } from '../../shared/elements'
import { INITIAL_VALUES } from './schema'
import './styles.scss'
import Scheduler from './Views/Scheduler'
import Staff from './Views/Staff'
import Search from './Views/Search'
// import ProviderAvailability from './Views/ProviderAvailability'
import { ProviderAvailability } from './Views/Availability'
import { useLocation } from 'react-router-dom'
import { SCHEDULE_POPULATE } from 'constants/index'
import { useHistory } from 'react-router-dom'
const AntTabs = styled(Tabs)({
    // borderBottom: '1px solid #e8e8e8',
    // margin: '0',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
        left: 0,
    },
    '& .MuiTabs-flexContainer': {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
})

const AntTab = styled(Tab)(({ theme }) => ({
    textTransform: 'unset !important',
    fontFamily: 'Rubik',
    fontSize: 16,
    backgroundColor: 'none',
    minWidth: 0,
    fontWeight: '500',
    marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    flex: 1,
    '&:hover': {
        color: '#5571C6',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#5571C6',
        fontWeight: 500,
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

const useStyles = makeStyles(() => ({
    prevent_edit: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        background: '#0000000f',
        width: '100%',
        height: '100%',
    },
    scheduler_context: {
        width: '100%',
        alignContent: 'start',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        paddingBottom: 12,
        color: '#303E4E',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        // marginBottom: '1rem',
    },
}))

function SchedulerMenu({
    isPopupType,
    date,
    locationOptionsData,
    visitTypeOptionsData,
    appointmentModal,
    statusOptionsData,
    addFormCardAppointment,
    loading,
    setIsLoading,
    isFromClinicalNote,
    followUp = false,
    tab,
    action,
    onChangeTab,
    customHeader = false,
    customHeaderName = '',
    sidebarOpen,
    setPreviousPatientId,
    previousPatientId,
    ...props
}) {
    const [loadingSchedule, setLoadingSchedule] = React.useState(false)
    const [initData, setInitData] = React.useState(INITIAL_VALUES)
    const [isTimingChanging, setIsTimingChanging] = useState(false)
    const history = useHistory()

    const classes = useStyles()
    const location = useLocation()

    async function getASchedule(idEvent) {
        try {
            setLoadingSchedule(true)
            const response = await getScheduleById({
                id: idEvent,
            })
            if (response?.data?.id) {
                const {
                    id,
                    appointment_type,
                    appointment_for,
                    reason_for_visit,
                    internal_note,
                    duration,
                    start,
                    end,
                    category,
                    specialty,
                    practitioner_id,
                    participant,
                    room_id,
                    location_id,
                    is_require_translator,
                    is_send_reminder,
                    is_need_complete_lab,
                    visit_type_id,
                    appointment_status,
                    patient_status,
                    priority,
                    checklists,
                } = response?.data
                onChangeTab(appointment_for.toLowerCase() === 'staff' ? 1 : 0)
                setInitData({
                    ...INITIAL_VALUES,
                    id,
                    appointment_for: appointment_for.toLowerCase(),
                    category,
                    specialty_id: specialty?.id,
                    appointment_type,
                    visitType: visit_type_id,
                    duration,
                    patient: participant[0]?.participant_id,
                    appointment_reason: reason_for_visit,
                    location: location_id,
                    room: room_id,
                    provider: practitioner_id,
                    start: moment(start),
                    end: moment(end),
                    isRequireTranslator: is_require_translator,
                    additional_info: internal_note,
                    isSendReminder: is_send_reminder,
                    isCompleted: is_need_complete_lab,
                    appointment_status,
                    patient_status,
                    priority,
                    checklists,
                })
            }

            setLoadingSchedule(false)
        } catch (error) {
            console.log({ error })
            setLoadingSchedule(false)
        }
    }

    const getValidationSchema = () => {
        if (tab === 0) {
            return Yup.object({
                visitType: Yup.string().required('This field is required!'),
                patient: Yup.string().required('This field is required!'),
                provider: Yup.string().required('This field is required!'),
                duration: Yup.number().required().min(1, 'This field is required!'),
                location: Yup.string().required('This field is required!'),
            })
        }
        if (tab === 1) {
            return Yup.object({
                provider: Yup.string().required('This field is required!'),
                start: Yup.date()
                    .required('This field is required!')
                    .test({
                        name: 'max-start',
                        exclusive: false,
                        params: {},
                        test(value, context) {
                            if (!value) return true
                            const start = moment(value)
                            if (start > moment(context.parent.end)) {
                                return this.createError({
                                    message: 'Please enter start time before end time.',
                                    path: 'start',
                                })
                            }
                            return true
                        },
                    }),
            })
        }

        if (tab === 4) {
            return Yup.object({
                admin_code_id: Yup.string().required('This field is required!'),
                practitioner_ids: Yup.array()
                    .min(1, 'This field is required')
                    .required('This field is required'),
                duration: Yup.number().required('Invalid duration'),
                date: Yup.date().required('This field is required!').typeError('Invalid date'),
                end_time: Yup.string().required('This field is required!').typeError('Invalid end time'),
                start_time: Yup.string().test({
                    name: 'start_time',
                    test(value, context) {
                        const end = moment(context.parent.end_time, 'HH:mm')
                        const start = moment(value, 'HH:mm')

                        if (context.parent.date && !context.parent.all_day) {
                            const getdate = moment(context.parent.date, 'MM/DD/YYYY').set({
                                hour: start.hour(),
                                minute: start.minute(),
                            })
                            if (getdate.isBefore(moment(), 'minute')) {
                                return this.createError({
                                    message: 'Start time must be before current time',
                                    path: 'start_time',
                                })
                            }
                        }

                        return true
                    },
                }),
            })
        }
    }

    const handleSubmit = (values) => {
        const {
            appointment_for,
            isCompleted,
            isSendReminder,
            isRequireTranslator,
            location,
            room,
            duration,
            appointment_type,
            start,
            end,
            provider,
            visitType,
            category,
            patient,
            specialty_id,
            additional_info,
            appointment_reason,
            keyword,
            appointment_status,
            patient_status,
            priority,
            checklists,
            timezone,
            admin_code_id,
            date,
            practitioner_ids,
            end_time,
            start_time,
            id,
            is_reoccurring_appointment,
            days,
            end_date,
            unit,
            once_every,
            end_type,
            series_edit,
            series_id,
            notes,
        } = values
        const payloadPatient = {
            specialty_id,
            appointment_for,
            appointment_type,
            start: start.clone().utc().set('seconds', 0).format(),
            end: end.clone().utc().set('seconds', 0).format(),
            visit_type_id: visitType,
            practitioner_id: provider,
            keyword,
            duration,
            internal_note: additional_info,
            reason_for_visit: appointment_reason,
            location_id: location,
            room_id: room,
            is_require_translator: isRequireTranslator,
            is_send_reminder: isSendReminder,
            is_need_complete_lab: isCompleted,
            participants: patient
                ? [
                    {
                        status: 'accepted',
                        role: 'Patient',
                        participant_id: patient,
                    },
                ]
                : [],
            appointment_status,
            patient_status,
            priority,
            checklists,
            timezone,
        }

        const payloadStaff = {
            id,
            appointment_for,
            category,
            internal_note: additional_info,
            // start,
            // end,
            start_time,
            end_time,
            timezone,
            practitioner_ids,
            duration,
            admin_code_id,
            date,
            start: moment
                .tz(`${date} ${start_time}`, 'YYYY-MM-DD HH:mm', timezone)
                .utc()
                .set('seconds', 0)
                .format(),
            end: moment
                .tz(`${date} ${end_time}`, 'YYYY-MM-DD HH:mm', timezone)
                .utc()
                .set('seconds', 0)
                .format(),
            is_reoccurring_appointment,
            days,
            end_date,
            unit,
            once_every,
            end_type,
            series_edit,
            series_id,
            notes
        }

        if (appointment_for.toLowerCase() === 'patient') {
            props.onSubmit(payloadPatient)
        } else {
            props.onSubmit(payloadStaff)
            props.onModalClose()
        }
    }

    useEffect(() => {
        if (appointmentModal?.id && !props?.isScheduler) {
            getASchedule(appointmentModal?.id)
        } else {
            setInitData({
                ...INITIAL_VALUES,
                patient: appointmentModal?.patientId,
                provider: appointmentModal?.providerId,
                start: moment(appointmentModal?.start),
                end: moment(appointmentModal?.end),
                appointment_for: tab === 0 ? 'patient' : 'staff',
            })
        }
    }, [appointmentModal.id, props.open])

    const appointmentHeader = () => {
        if (tab === 1) {
            if (customHeader) {
                return customHeaderName
            }
            if (action === 'update') {
                return 'Update Appointment'
            }
            return 'New Appointment'
        }
    }

    // useEffect(() => {
    //     if (!appointmentModal?.id) {
    //         setInitData({
    //             ...INITIAL_VALUES,
    //             patient: appointmentModal?.patientId,
    //             provider: appointmentModal?.providerId,
    //             start: moment(),
    //             end: moment(),
    //             appointment_for: value === 0 ? 'patient' : 'staff',
    //         })
    //     }
    // }, [value, appointmentModal?.id])

    return (
        <Grid
            container
            style={{
                padding: 0,
                zIndex: 10,
                backgroundColor: '#FAFAFA',
                width: 'auto',
                position: 'absolute',
            }}>
            {(loadingSchedule || props.dataLoading) && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                    <CircularProgress />
                </Box>
            )}
            <Formik
                initialValues={initData}
                enableReinitialize
                validationSchema={getValidationSchema()}
                onSubmit={handleSubmit}>
                {({ values, setFieldValue, errors, touched, handleSubmit, resetForm }) => {
                    return (
                        <div
                            id="scheduler_form_container"
                            container
                            item
                            className={classes.scheduler_context}
                            style={{ height: 'calc(100vh - 124px)', overflow: 'auto' }}
                        >
                            {!location.state?.from?.name && (
                                <>
                                    {!isPopupType && (
                                        <Grid
                                            container
                                            onClick={props.onModalClose}
                                            style={{
                                                cursor: 'pointer',
                                                width: 'fit-content',
                                                paddingLeft: '9px',
                                                paddingTop: '10px',
                                                paddingBottom: '15px',
                                            }}>
                                            <KeyboardBackspaceRoundedIcon
                                                style={{
                                                    color: '#5571C6',
                                                    fontSize: 18,
                                                }}
                                            />
                                            <Typography className={classes.text_back}>
                                                Back to scheduling calender
                                            </Typography>
                                        </Grid>
                                    )}
                                </>
                            )}

                            <div
                                style={{
                                    display: 'flex',
                                    padding: '1rem',
                                    backgroundColor: '#FAFAFA',
                                    borderRadius: '5px',
                                    paddingBottom: '5rem',
                                }}>
                                <div style={{ flexBasis: '12%', flexGrow: '0' }}>
                                    {!followUp && (
                                        <AntTabs
                                            variant="fullWidth"
                                            orientation="vertical"
                                            value={tab}
                                            onChange={(event, newValue) => {
                                                onChangeTab(newValue)
                                                resetForm()
                                            }}
                                            aria-label="basic tabs example">
                                            <AntTab
                                                disabled={!!appointmentModal.id}
                                                label="New Appt"
                                                value={1}
                                            />
                                            <AntTab
                                                disabled={!!appointmentModal.id}
                                                label="Search"
                                                value={0}
                                            />
                                            {!!appointmentModal.id ||
                                                (location.state?.from?.name ===
                                                    SCHEDULE_POPULATE.CLINICAL_NOTE.name && (
                                                        <AntTab
                                                            disabled={
                                                                !!appointmentModal.id ||
                                                                location.state?.from?.name ===
                                                                SCHEDULE_POPULATE.CLINICAL_NOTE.name
                                                            }
                                                            label="Provider Availability"
                                                            value={3}
                                                        />
                                                    ))}
                                            {isFromClinicalNote || (
                                                <AntTab label="Staff" value={4} />
                                            )}
                                        </AntTabs>
                                    )}
                                </div>
                                <div
                                    style={{
                                        flexBasis: '88%',
                                        flexGrow: '1',
                                        padding: '0.7rem',
                                        border: '1px solid #e7e5e5',
                                        borderRadius: '5px',
                                        background: 'white'
                                    }}>
                                    {tab === 1 && (
                                        <Scheduler
                                            previousPatientId={previousPatientId}
                                            handleClose={props.onModalClose}
                                            customHeader={customHeader}
                                            onSubmit={props.onSubmit}
                                            isScheduler={props.isScheduler}
                                            sidebarOpen={sidebarOpen}
                                            isSubmitting={loading}
                                            setIsSubmitting={setIsLoading}
                                            isTimingChanging={isTimingChanging}
                                            setIsTimingChanging={setIsTimingChanging}
                                            appointmentIdFromModalProp={appointmentModal?.id}
                                            setPreviousPatientId={setPreviousPatientId}
                                        />
                                    )}
                                    {tab === 0 && <Search onChangeTab={onChangeTab} />}

                                    {tab === 3 && (
                                        <ProviderAvailability
                                            isFromClinicalNote={isFromClinicalNote}
                                            date={date}
                                        />
                                    )}
                                    {tab === 4 && (
                                        <Staff
                                            events={props.events}
                                            label={props.label}
                                            values={values}
                                            appointmentModal={appointmentModal}
                                            setFieldValue={setFieldValue}
                                            errors={errors}
                                            touched={touched}
                                            sidebarOpen={sidebarOpen}
                                            onCloseStaff={props.onModalClose}
                                            handleSubmit={handleSubmit}
                                        />
                                    )}
                                </div>
                            </div>
                            
                        </div>
                    )
                }}
            </Formik>
        </Grid>
    )
}

export default SchedulerMenu
