import { Grid } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Routes from '../../constants/routes'
import routes from '../../constants/routes'
import AppointmentListCard from '../../containers/AppointmentList/Card'
import MinorsCard from '../../containers/Minors'
import { Button } from '../custom'

const PatientDashboard = () => {
    const history = useHistory()

    const userInfo = React.useMemo(() => {
        return {
            role: localStorage.getItem('role'),
            userId: localStorage.getItem('userId'),
        }
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid container item justifyContent="flex-end">
                <Grid item xs={2}>
                    <Button
                        onClick={() => {
                            history.push(
                                Routes.APPOINTMENTS
                                    .SEARCH_PROVIDER,
                            )
                        }}
                        fullWidth>
                        <strong>Book Appointment</strong>
                    </Button>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} sm={8}>
                    <AppointmentListCard
                        role={userInfo.role}
                        userId={userInfo.userId}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MinorsCard
                        userId={userInfo.userId}
                        role={userInfo.role}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PatientDashboard
