import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { FamilyPsychiatricHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import { FamilyPsychiatricForm } from './FamilyPsychiatriclForm'
import { useNotification } from 'shared/elements/Notification'
import { apiFamilyPsychiatric } from 'services/Patient/apiFamilyPsychiatric'
import FamilyPsychiatricFormNew from './FamilyPsychiatricFormNew'

const FamilyPsychiatricHistory = ({
    patient_Id,
    encounter_id,
    isInClinicalNote,
    afterActionOk,
    nonEditable
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    // State
    const [familyMedicalId, setFamilyMedicalId] = useState<string | number | boolean>(false)
    const [isAddingNew, setIsAddingNew] = useState(false)
    const [patientId, setPatientId] = useState('')
    const [substanceAbuse, setSubstanceAbuse] = useState<FamilyPsychiatric[]>([])
    const [loading, setLoading] = useState(false)
    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })
    const [sortModel, setSortModel] = useState<GridSortModel>([])

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const getFamilyPsychiatric = async (patientId, page = 1) => {
        try {
            setLoading(true)

            const resp = await apiFamilyPsychiatric.getAll(patientId, {
                page,
                limit,
            })
            if (resp.data.Status === 'SUCCESSED') {
                setSubstanceAbuse(resp.data.listFamilyMedicalHistory.data)
                setTotal(resp.data.listFamilyMedicalHistory.paging.total)
                // if (
                //     !resp.data.listFamilyMedicalHistory?.data.length &&
                //     resp.data.listFamilyMedicalHistory?.paging?.total > 0
                // ) {
                //     setPage(`${+page - 1}`)
                // }
            }
        } catch (ex) {
            notification('Cannot get list Family Medical History', 'error')
        } finally {
            setLoading(false)
        }
    }
    const handlePageChange = (value: number) => {
        setPage(value)
    }
    const handleEdit = (id) => {
        if(!isInClinicalNote){
           history.push(`/patient/${patientId}/family-history/update`,{familyMedicalId: id})
        }else{
            setIsAddingNew(true)
            setFamilyMedicalId(id)
        }
    }

    const handleDelete = async (familyMedicalId) => {
        if (patientId) {
            try {
                const resp = await apiFamilyPsychiatric.delete(patientId, familyMedicalId)
                console.log(resp)
                if (resp.data?.Status === 'SUCCESSED') {
                    notification('Family Psychiatric History deleted successfully', 'success')
                    await getFamilyPsychiatric(patientId, page)
                    afterActionOk?.()
                }
            } catch (ex) {
                notification('Something went wrong', 'error')
            }
        }
    }
    const hanleCallApi = () => {
        if (patientId) {
            getFamilyPsychiatric(patientId, page)
            setIsAddingNew(false)
            setFamilyMedicalId(false)
            afterActionOk?.()
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getFamilyPsychiatric(patientId, page)
        }

        return () => abortController.abort()
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: isInClinicalNote ? 0 : '16px 24px' }}>
            <Loading visible={loading} />
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Family History</Typography>

                    <ButtonCustom
                        tooltip="Add New Family History"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if(!isInClinicalNote){
                                history.push(`/patient/${patientId}/family-history/create`)
                            }else{
                                setIsAddingNew(true)
                            }
                        }}
                        disabled={nonEditable}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <FamilyPsychiatricHistoryDashboard
                    page={page}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    onPageChange={handlePageChange}
                    dataSource={substanceAbuse}
                    pagination={{ limit, total, start: page }}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    nonEditable={nonEditable}
                />
                {/* {isAddingNew && (
                    <FamilyPsychiatricForm
                        familyMedicalId={familyMedicalId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        onOke={hanleCallApi}
                        open={isAddingNew}
                        onClose={() => {
                            setIsAddingNew(false)
                            setFamilyMedicalId(false)
                        }}
                    />
                )} */}
                {isAddingNew && (
                    <FamilyPsychiatricFormNew
                        familyMedicalId={familyMedicalId}
                        patientId={patientId}
                        encounter_id={encounter_id}
                        onOke={hanleCallApi}
                        open={isAddingNew}
                        onClose={() => {
                            setIsAddingNew(false)
                            setFamilyMedicalId(false)
                        }}
                    />
                )}
                
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(FamilyPsychiatricHistory)
