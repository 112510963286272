import React, { useEffect } from 'react';
import SearchBar from '../../MainLayout/Search/search-bar';
import Card from '../../MainLayout/Card/Card';
import ConsulationLink from './consulation-link';
import appointment from '../../../assests/sidebar/icon_appointments_pressed.svg';
import Message from '../../../assests/sidebar/icon_messages_pressed..svg';
import actionitem from '../../../assests/sidebar/ActionItems.svg';
import Model from '../../MainLayout/model/model';
import useModel from '../../MainLayout/model/usemodel';

const ProviderAppointment = () => {
    const { isShowing, toggle } = useModel();

    return (
        <React.Fragment>
            <div className="container-1">
                <div className="spacer">
                    <SearchBar />
                    <ConsulationLink marginTop={120} />
                </div>

                <Card
                    name="Appointments"
                    icon={appointment}
                ></Card>

                <Card
                    name="Mark Consultation Status"
                    icon={actionitem}
                />
                <Card
                    name="Action item"
                    icon={actionitem}
                />
                <Card name="Messages" icon={Message} />
            </div>
        </React.Fragment>
    );
};

export default ProviderAppointment;
