import React from 'react'

export default function IconBack() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.37996 12.5467C6.25329 12.5467 6.12663 12.5001 6.02663 12.4001L1.97996 8.35341C1.78663 8.16008 1.78663 7.84008 1.97996 7.64674L6.02663 3.60008C6.21996 3.40674 6.53996 3.40674 6.73329 3.60008C6.92663 3.79341 6.92663 4.11341 6.73329 4.30674L3.03996 8.00008L6.73329 11.6934C6.92663 11.8867 6.92663 12.2067 6.73329 12.4001C6.63996 12.5001 6.50663 12.5467 6.37996 12.5467Z"
                fill="#5571C6"
            />
            <path
                d="M13.6668 8.5H2.44678C2.17344 8.5 1.94678 8.27333 1.94678 8C1.94678 7.72667 2.17344 7.5 2.44678 7.5H13.6668C13.9401 7.5 14.1668 7.72667 14.1668 8C14.1668 8.27333 13.9401 8.5 13.6668 8.5Z"
                fill="#5571C6"
            />
        </svg>
    )
}
