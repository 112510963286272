import React, { useState, forwardRef, useImperativeHandle, useMemo, useRef, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import moment from 'moment'
import { makeStyles } from '@material-ui/core'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { NoRowsOverlayEl } from 'shared/elements'
import { useNotification } from 'shared/elements/Notification'
import { searchAvaiAppoinment } from 'services/Calendar'
import { formatDateTime, getFullName } from 'utilities/formats'
import { Button as ButtonCustom } from 'components/custom'
import { changeAppointment, changeSearchResult } from 'store/actions/schedule'
import { Pagination } from 'shared/elements/Pagination'
import { PATIENT_VISIT_TYPE } from '../constant'

const useStyles = makeStyles({
    wrap_data_grid: {
        // height: 'calc(100vh - 180px)',
        // minHeight: 'calc(100vh - 180px)',
        // width: '100%',
        // overflow: 'auto',
        // '&::-webkit-scrollbar': {
        //     display: 'none',
        // },
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 180px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
        },
        '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            margin: '4px 0',
            borderRadius: '8px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
})

export const SearchResult = forwardRef(({ values, setSearching, onChangeTab }, ref) => {
    const classes = useStyles()
    const gridRef = useRef(null)

    const schedule = useSelector((state) => state.schedule)
    const pagination = schedule.search_result.pagination

    const convert = (data) => {
        return data.map((item, index) => {
            const {
                date,
                time: { start, end },
                location: { location_name },
                provider: { first_name, last_name },
            } = item
            return {
                id: index,
                date: moment.tz(date, values.timezone).format('MM/DD/YYYY'),
                day: moment.tz(date, values.timezone).format('dddd'),
                time: `${moment.tz(start, values.timezone).format('hh:mm A')} - ${moment
                    .tz(end, values.timezone)
                    .format('hh:mm A')}`,
                service_location: location_name,
                provider: getFullName(first_name, last_name),
            }
        })
    }

    const data = useMemo(() => {
        return convert(schedule.search_result.data.slice(0, pagination.limit * pagination.page))
    }, [pagination, schedule.search_result.data])

    const [loading, setLoading] = useState(false)

    const [sortModel, setSortModel] = useState([])

    const dispatch = useDispatch()
    const notification = useNotification()

    const addNew = (value) => {
        const { date, location, provider, time } = schedule.search_result.data.find(
            (el, index) => index === value.id,
        )

        const start = moment.tz(time.start, values.timezone)
        const end = moment.tz(time.end, values.timezone)

        onChangeTab(1)
        dispatch(
            changeAppointment({
                id: -1,
                values: {
                    id: '',
                    date: moment.tz(date, values.timezone).format('YYYY-MM-DD'),
                    end_time: end.format('HH:mm'),
                    start_time: start.format('HH:mm'),
                    appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                    reason_for_visit: '',
                    internal_note: '',
                    specialty_id: values.specialty,
                    location_id: location.id,
                    duration: end.diff(start, 'minute'),
                    visit_type_id: values.visit_type_id,
                    patient_id: '',
                    practitioner_id: provider.id,
                    supervising_provider: '',
                    is_require_translator: false,
                    appointment_status: '',
                    is_reoccurring_appointment: false,
                    days: [],
                    end_date: '',
                    unit: '',
                    once_every: '',
                    end_type: '',
                    timezone: values.timezone,
                    notes: [],
                    note: '',
                    noteView: 'no-view',
                },
            }),
        )
    }

    const getData = async (page = 1) => {
        try {
            setLoading(true)
            setSearching(true)

            if (values.all_day) {
                if (
                    moment
                        .tz(values.start_date, 'MM/DD/YYYY', values.timezone)
                        .isSame(moment().tz(values.timezone), 'day')
                ) {
                    values.start_time = moment().tz(values.timezone).format('HH:mm')
                } else {
                    values.start_time = '00:00'
                    values.end_time = '23:59'
                }
            }

            const payload = {
                ...values,
                start_date: moment
                    .tz(
                        `${values.start_date} ${values.start_time}`,
                        'MM/DD/YYYY HH:mm',
                        values.timezone,
                    )
                    .utc()
                    .format('YYYY-MM-DD'),
                end_date: moment
                    .tz(
                        `${values.end_date} ${values.end_time}`,
                        'MM/DD/YYYY HH:mm',
                        values.timezone,
                    )
                    .utc()
                    .format('YYYY-MM-DD'),
                start_time: moment
                    .tz(
                        `${values.start_date} ${values.start_time}`,
                        'MM/DD/YYYY HH:mm',
                        values.timezone,
                    )
                    .utc()
                    .format('HH:mm'),
                end_time: moment
                    .tz(
                        `${values.end_date} ${values.end_time}`,
                        'MM/DD/YYYY HH:mm',
                        values.timezone,
                    )
                    .utc()
                    .format('HH:mm'),
                days: values.days.map((day) => day.key),
                location_ids: values.location_ids.map((location) => location.key),
                practitioner_ids: values.practitioner_ids.map((practitioner) => practitioner.key),
            }

            const { data } = await searchAvaiAppoinment(payload)

            dispatch(
                changeSearchResult({
                    data,
                    pagination: {
                        ...pagination,
                        page,
                        total: data.length,
                    },
                }),
            )
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
            setSearching(false)
        }
    }

    // const handleScroll = (event) => {
    //     const target = event.target
    //     if (target.scrollHeight - target.scrollTop === target.clientHeight) {
    //         const totalPage = Math.ceil(pagination.total / pagination.limit)
    //         if (totalPage > pagination.page) {
    //             dispatch(
    //                 changeSearchResult({
    //                     ...schedule.search_result,
    //                     pagination: {
    //                         ...pagination,
    //                         page: pagination.page + 1,
    //                     },
    //                 }),
    //             )
    //         }
    //     }
    // }

    useImperativeHandle(ref, () => ({
        getData,
    }))

    const columns = [
        {
            headerName: 'Date',
            headerClassName: 'super-app-theme--header',
            field: 'date',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Day',
            headerClassName: 'super-app-theme--header',
            field: 'day',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Time',
            headerClassName: 'super-app-theme--header',
            field: 'time',
            flex: 0.5,
            sortable: false,
        },
        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'provider',
            flex: 0.7,
            sortable: false,
        },
        {
            headerName: 'Service Location',
            headerClassName: 'super-app-theme--header',
            field: 'service_location',
            flex: 0.7,
            sortable: false,
        },
        {
            headerName: '',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.3,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <ButtonCustom
                        tooltip="Add New Appointment"
                        variant="contained"
                        color="primary"
                        fullWidth>
                        <AddIcon
                            style={{
                                fontSize: 20,
                            }}
                        />
                    </ButtonCustom>
                )
            },
        },
    ]

    return (
        <Grid
            item
            xs={12}
            style={{
                border: '1px solid #5571C6',
                borderRadius: 6,
                padding: '0 16px',
                marginTop: 16,
            }}>
            <Box className={classes.wrap_data_grid}>
                <MuiDataGrid
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    sortingMode="server"
                    getRowId={(row) => row.id}
                    rows={data}
                    columns={columns}
                    paginationMode="server"
                    disableColumnSelector={true}
                    disableSelectionOnClick
                    // pageSize={pagination.limit}
                    components={{
                        NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                        // Pagination: () => (
                        //     <Pagination
                        //         totalRecords={pagination.total}
                        //         onPageChange={(page) => {
                        //             if (page === schedule.search_result.pagination.page) return
                        //             dispatch(
                        //                 changeSearchResult({
                        //                     ...schedule.search_result,
                        //                     pagination: {
                        //                         ...pagination,
                        //                         page,
                        //                     },
                        //                 }),
                        //             )
                        //         }}
                        //     />
                        // ),
                        ColumnUnsortedIcon: TeleIconUpDownSortedList,
                        ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                        ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                    }}
                    disableColumnMenu={true}
                    loading={loading}
                    onCellClick={addNew}
                    // pagination
                    // page={pagination.page - 1}
                    // rowCount={pagination.total}
                    hideFooterPagination
                    hideFooter
                    style={{
                        paddingBottom: 16,
                    }}
                />
                {Math.ceil(pagination.total / pagination.limit) > pagination.page && (
                    <Typography
                        style={{
                            textAlign: 'center',
                            padding: '12px 0',
                        }}>
                        <span
                            style={{
                                fontWeight: 500,
                                cursor: 'pointer',
                                color: 'rgb(85, 113, 198)',
                            }}
                            onClick={() => {
                                dispatch(
                                    changeSearchResult({
                                        ...schedule.search_result,
                                        pagination: {
                                            ...pagination,
                                            page: pagination.page + 1,
                                        },
                                    }),
                                )
                            }}>
                            Load more
                        </span>
                    </Typography>
                )}
            </Box>
        </Grid>
    )
})
