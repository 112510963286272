import React, { useEffect, useState } from 'react'
import { Grid, makeStyles, FormLabel, Box } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { Button, IconButton, Radio, TextField, Typography } from '@mui/material'
import { ModalEl as Modal, SelectEl } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { MenuItem, Select } from '@material-ui/core'
import { Footer } from 'shared/elements/FormItem/Footer'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { Loading } from 'shared/elements/Loading'
import { LoadingButton } from '@mui/lab'
import {
    Allergy,
    AllergyReaction,
    AllergySeverity,
    ErrorType,
} from 'containers/PatientsSetting/interfaces'
import * as apis from 'services/Patient/patient'
import { formatDateTime } from 'utilities/formats'
import { useNotification } from 'shared/elements/Notification'

import { SearchAllergen } from 'shared/elements/FormItem/SearchComponents'
import { ALLERGY_STATUS, ALLERGY_STATUS_MEDICAL, INITIAL_VALUES } from './schema'
import { usePatient } from 'containers/PatientsSetting/hooks'
import Divider from '@mui/material/Divider';
import { v4 as uuidv4 } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
// import '../../styles.scss'

const useStyles = makeStyles({
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '5px !important',
        fontSize: '15px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
    },
    button_cancel: {
        background: '#fff !important',
        color: '#242424 !important',
        fontWeight: '400 !important',
        borderRadius: '5px !important',
        fontSize: '15px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #d1d1d1 !important'
    },
    required: {
        color: "#E81A1A"
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "140%",
        margin: "7px 0"
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
    },
    heightSetter: {
        '& .MuiOutlinedInput-root': {
            height: '32px !important',
            minHeight: "32px !important",
            color: '#303E4E !important',
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                fill: '#303E4E !important'
            }
        },
        '& .MuiBox-root .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
            // borderRadius: '0px 6px 6px 0px !important',
            // borderLeft: '0 !important',
            width: '100% !important',
            padding: '0 5px 0 0 !important',
            '& .MuiInputAdornment-root .MuiButtonBase-root img': {
                height: '10px',
                width: '10px',
                marginLeft: '5px'
            }
        }
    }
})

const SelectDropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
            d="M8.68365 9.33047L12.2659 5.82422L13.2892 6.8258L8.68365 11.3336L4.07812 6.8258L5.10141 5.82422L8.68365 9.33047Z"
            fill="#667689"
        />
    </svg>
)
export const NewAllergiesForm = (props) => {

    const classes = useStyles()
    const { patientInfo } = usePatient(props.patientId)

    const notification = useNotification()

    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [listReaction, setListReaction] = useState([])
    const [listSeverity, setListSeverity] = useState([])



    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(true)

        Promise.allSettled([
            getListReaction(),
            getListSeverity(),
            props.allergyId
                ? getAllergyDetail(props.patientId, props.allergyId)
                : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload) => {

        // const allArrayData = props.formik.current.values.allergy.active_medication_allergy.concat(props.formik.current.values.allergy.inactive_medication_allergy)


        setIsSubmitting(true)
        try {
            const postData = {
                ...payload,
                encounter_id: props.encounter_id,
                onset_date: formatDateTime(payload.onset_date, 'YYYY-MM-DD'),
            }
            if (props.allergyId) {
                await apis.putAllergy(props.patientId, props.allergyId, postData)
            } else {
                await apis.postAllergy(props.patientId, postData)
            }
            props.AfterActionComplete()

        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                (error)?.data?.message ||
                (props.allergyId
                    ? `Allergy updated unsuccessfully`
                    : `Allergy created unsuccessfully`)

            notification(errMsg, 'error')
        }

    }

    const getAllergyDetail = async (patientId, allergyId) => {
        try {
            const resp = await apis.getAllergyById(patientId, allergyId)

            if (resp && resp.data) {
                setInitData(resp.data)
            }
        } catch (error) {
            notification(`Cannot get medication allergy ${allergyId}`, 'error')
        }
    }

    const getListReaction = async () => {
        try {
            const resp = await apis.getAllergiesReaction()
            setListReaction(resp.data.hits.hits)
        } catch (error) {
            notification('Cannot get list reaction', 'error')
        }
    }

    const getListSeverity = async () => {
        try {
            const resp = await apis.getAllergiesSeverity()
            setListSeverity(resp.data.hits.hits)
        } catch (error) {
            notification('Cannot get list severity', 'error')
        }
    }

    const validationSchema = yup.object({
        severity: yup.string().required('Severity is required!'),
        allergy: yup.object({
            genericName: yup.string(),
            coding: yup.array().when('genericName', (genericName, schema) => {
                return genericName
                    ? schema.of(
                        yup.object({
                            code: yup.string(),
                            display: yup.string(),
                        }),
                    )
                    : schema
                        .of(
                            yup.object({
                                code: yup.string(),
                                display: yup.string(),
                            }),
                        )
                        .test({
                            message: 'Medication is required!',
                            test: (coding) => (coding?.length) > 0,
                        })
            }),

            text: yup.string(),
        }),
        status: yup.object({
            code: yup.string().required('Status is required!'),
            display: yup.string(),
        }),
        patient_id: yup.string(),
        note: yup.string(),
        reaction: yup.object({
            code: yup.string().required('Reaction is required!'),
            display: yup.string(),
        }),
        external_id: yup.string(),
        rcopia_id: yup.string(),
        onset_date: yup
            .date()
            .nullable()
            .typeError('Please input valid Onset Date')
            .min(moment(patientInfo.dob), `Onset Date must be after Patient's birth date`)
            .max(moment(), 'Onset Date must be before current date'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    return (

        <Grid container>
            <Loading visible={dataLoading} />

            <Grid container item style={{ gap: 10 }}>
                    <Grid container item alignItems="flex-end">
                        <Grid item xs={12} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Medication<span className={classes.required}>*</span></FormLabel>
                            <SearchAllergen
                                style={{ height: "26px" }}
                                name="allergy"
                                value={formik.values.allergy}
                                placeholder="Search medication"
                                error={
                                    Boolean(formik.touched.allergy?.coding) &&
                                    Boolean(formik.errors.allergy?.coding)
                                }
                                helperText={
                                    Boolean(formik.touched.allergy?.coding) &&
                                    (formik.errors.allergy?.coding)
                                }
                                onChange={(value) => formik.setFieldValue('allergy', value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item alignItems="flex-end">
                        <Grid item xs={12} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Reaction<span className={classes.required}>*</span></FormLabel>
                            <SelectEl
                                style={{ height: "26px" }}
                                required
                                name="reaction"
                                value={formik.values.reaction?.code}
                                placeholder="Select reaction"
                                error={
                                    formik.touched.reaction?.code &&
                                    Boolean(formik.errors.reaction?.code)
                                }
                                helperText={
                                    formik.touched.reaction?.code &&
                                    formik.errors.reaction?.code
                                }
                                options={listReaction
                                    .map((opt) => ({
                                        key: opt._source.code,
                                        value: opt._source.display,
                                    }))
                                    .sort((option1, option2) => {
                                        let a = option1.value
                                        let b = option2.value
                                        return a === b ? 0 : a > b ? 1 : -1
                                    })}
                                onChange={(e) =>
                                    formik.setFieldValue('reaction', {
                                        code: e.target.value,
                                        display: listReaction.find(
                                            (reaction) => reaction._source.code === e.target.value,
                                        )?._source?.display,
                                    })
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="flex-end">
                        <Grid item xs={12} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Severity<span className={classes.required}>*</span></FormLabel>
                            <SelectEl
                                style={{ height: "26px" }}
                                name="severity"
                                value={formik.values.severity}
                                placeholder="Select severity"
                                error={
                                    formik.touched.severity &&
                                    Boolean(formik.errors.severity)
                                }
                                helperText={
                                    formik.touched.severity && formik.errors.severity
                                }
                                options={listSeverity
                                    .map((opt) => ({
                                        key: opt._source.display,
                                        value: opt._source.display,
                                    }))
                                    .sort((option1, option2) => {
                                        let a = option1.value
                                        let b = option2.value
                                        return a === b ? 0 : a > b ? 1 : -1
                                    })}
                                onChange={(e) =>
                                    formik.setFieldValue('severity', e.target.value)
                                }
                            />
                            {/* <Select
                                style={{ width: "100%" }}
                                variant='outlined'
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => (<SelectDropDownIcon {...props} />)}
                                renderValue={(selected) => {
                                    if (selected?.length === 0) {
                                        return <Typography className={classes.placeholder}>Select</Typography>;
                                    }
                                    return selected;
                                }}
                                value={formik.values.severity}
                                onChange={(e) =>
                                    formik.setFieldValue('severity', e.target.value)
                                }>
                                {listSeverity?.map(codeType => (
                                    <MenuItem key={codeType._source.display} value={codeType._source.display}>
                                        <Typography
                                            variant="body2"
                                            noWrap
                                            style={{
                                                color: '#667689',
                                                fontSize: 14,
                                                ...(props?.dropdownliststyles || {}),
                                            }}>
                                            {codeType._source.display}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select> */}
                        </Grid>
                    </Grid>
                    <Grid container item alignItems="flex-start" spacing={2}>
                        <Grid item xs={6} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Status<span className={classes.required}>*</span></FormLabel>
                            <SelectEl
                                required
                                name="status"
                                // disabled={!props.allergyId}
                                value={formik.values.status?.code}
                                placeholder="Select status"
                                error={
                                    formik.touched.status?.code &&
                                    Boolean(formik.errors.status?.code)
                                }
                                helperText={
                                    formik.touched.status?.code && formik.errors.status?.code
                                }
                                options={ALLERGY_STATUS_MEDICAL.map((opt) => ({
                                    key: opt._source.code,
                                    value: opt._source.display,
                                }))}
                                onChange={(e) => {
                                    console.log(e)
                                    formik.setFieldValue('status', { code: e.target.value })
                                }
                                }
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.heightSetter}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Onset Date</FormLabel>
                            <DatePicker
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '27px !important'
                                    },
                                }}
                                style={{ marginBottom: "-2px" }}
                                name="onsetdate"
                                maxDate={moment()}
                                minDate={moment(patientInfo.dob)}
                                value={formik.values.onset_date}
                                error={
                                    formik.touched.onset_date && Boolean(formik.errors.onset_date)
                                }
                                helperText={formik.touched.onset_date && formik.errors.onset_date}
                                onChange={(value) => formik.setFieldValue('onset_date', value)}
                                notDefaultValue
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "11px" }}>
                        <FormLabel component="p" className={classes.formLabel}>
                            Note
                        </FormLabel>
                        <TextField
                            variant='outlined'
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '79px !important'
                                },
                                ' & .MuiInputBase-input': {
                                    height: "60px !important"
                                }
                            }}
                            placeholder='Write note here'
                            multiline
                            maxRows={4}
                            value={formik.values.note}
                            onChange={(e) => {
                                formik.setFieldValue('note', e.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} justifyContent='flex-end' style={{ alignItems: 'center', gap: 10, display: 'flex' }}>
                        {/* <Divider style={{ background: "#E9ECF1" }} /> */}
                        
                        <LoadingButton
                            style={{ height: "28px" }}
                            loadingPosition="start"
                            variant="contained"
                            disabled={isSubmitting}
                            className={classes.button_cancel}
                            onClick={() => {
                                props?.handleClose()
                            }}>
                            Cancel
                        </LoadingButton>
                    
                        <LoadingButton
                            style={{ height: "28px" }}
                            loading={isSubmitting}
                            loadingPosition="start"
                            variant="contained"
                            // disabled={props.nonEditable}
                            className={classes.button_create}
                            onClick={(event) => {
                                formik.handleSubmit()
                            }}>
                            {props.allergyId ? 'Update medication allergy' : 'Add medication allergy'}
                        </LoadingButton>
                        

                    </Grid>
                    {/* <Footer
                        loading={isSubmitting}
                        onCancel={() => props.onClose && props.onClose(false)}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.allergyId ? 'Update' : 'Submit'}
                    /> */}
            </Grid>
        </Grid>
    )
}
