import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'

import { deleteMedicalProblem, getMedicalProblemList } from 'services/Patient/patient'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { sx, usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { formatDateTime } from 'utilities/formats'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'

import { ErrorType, MedicalProblem } from '../../interfaces'
import { DataGridCell } from '../../shared'
import { DataGrid } from 'shared/elements/DataGrid'

interface Props {
    cycleCount: number
    isInClinicalNote: boolean
    patient_Id: string
    onClickEdit: (problemId: string) => void
    afterActionOk?: () => void
    nonEditable: boolean
}

type PROBLEMS_FILTER_TYPE = 'active' | 'inactive'

export const ActiveProblems = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id } = useParams<{ id: string }>()
    const notification = useNotification()
    const [patientId, setPaitentId] = useState('')
    const [bundleId, setBundleId] = useState('')

    const [loading, setLoading] = useState(false)
    const [medicalProblems, setMedicalProblems] = useState<MedicalProblem[]>([])
    const [problemsFilterType, setProblemsFilterType] = useState<PROBLEMS_FILTER_TYPE | null>(null)

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'type',
            sort: 'desc',
        },
    ])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        page: 1,
        limit: 10,
    })

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        setPaitentId(props.patient_Id || id)
    }, [props.patient_Id, id])
    const columns: GridColumns<MedicalProblem> = [
        {
            headerName: 'Problem',
            headerClassName: 'super-app-theme--header',
            field: 'problem',
            flex: 1.5,
            sortable: true,
            valueGetter: ({ row }) => row.icd_code?.display || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={row.icd_code?.display} />
            },
        },
        {
            headerName: 'ICD-10 Code',
            headerClassName: 'super-app-theme--header',
            field: 'icd_code',
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.icd_code?.code || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={row.icd_code?.code} />
            },
        },
        {
            headerName: 'Date Diagnosed',
            headerClassName: 'super-app-theme--header',
            field: 'time_onset',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.time_onset || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={formatDateTime(row.time_onset, 'MMM DD, YYYY')} />
            },
        },
        {
            headerName: 'Date Changed',
            headerClassName: 'super-app-theme--header',
            field: 'time_changed',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.time_changed || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return <DataGridCell value={formatDateTime(row.time_changed, 'MMM DD, YYYY')} />
            },
        },
        {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status',
            // width: 100,
            flex: 1,
            sortable: true,
            valueGetter: ({ row }) => row.status.code || '',
            sortComparator: (p: string, q: string) => p.localeCompare(q),
            renderCell: ({ row }) => {
                return (
                    <Typography className={classes[`tag_type_${row.status?.code}`]}>
                        {row.status.code}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Notes',
            headerClassName: 'super-app-theme--header',
            field: 'note',
            // width: 100,
            flex: 1.5,
            sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value} />
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                    !props.nonEditable
                    ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.onClickEdit(params.id.toString()),
                            },
                            {
                                text: 'Delete',
                                handler: () => handleDelete(patientId, params.id.toString()),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this medical problem?"
                    />
                    :
                    ""
                
            ),
        },
    ]

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getMedialProblemList()
        }

        return () => abortController.abort()
    }, [page, patientId])

    useEffect(() => {
        const abortController = new AbortController()
        if (props.cycleCount > 0) {
            setTimeout(() => getMedialProblemList(), 800)
        }

        return () => abortController.abort()
    }, [props.cycleCount])

    const handleDelete = async (patientId: string, problemId: string) => {
        try {
            await deleteMedicalProblem(patientId, problemId)

            notification(`Problem Deleted Successfully!`, 'success')

            getMedialProblemList()
            props.afterActionOk?.()
        } catch (error) {
            const errMsg =
                (error as ErrorType)?.data?.message || `Something went wrong when deleting problem!`

            notification(errMsg, 'error')
        }
    }

    const getMedialProblemList = async (start = page) => {
        try {
            setLoading(true)
            const resp = await getMedicalProblemList(
                patientId,
                start,
                paginationData.limit,
                bundleId,
            )
            if (resp?.data?.bundle_id) setBundleId(resp?.data?.bundle_id)

            setMedicalProblems(resp.data.data as MedicalProblem[])
            setTotal(resp.data.paging.total)
            // setPaginationData(resp.data.paging)
        } catch (ex) {
            console.error('[Medical Problem] cannot fetch medial problem list', ex)

            notification(`Cannot get list problem!`, 'error')
        }

        setLoading(false)
    }

    return (
        <>
            {!props.isInClinicalNote && (
                <Grid
                    container
                    item
                    direction="row"
                    style={{
                        borderBottom: '1px solid #E9ECF1',
                        paddingBottom: 12,
                        paddingLeft: 12,
                    }}>
                    <Grid item>
                        <CheckboxOption
                            // name={`${fieldName}.no_concern`}
                            option={{
                                label: 'Show active Problems',
                                value: true,
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setProblemsFilterType(e.target.checked ? 'active' : null)
                            }}
                            checked={problemsFilterType === 'active'}
                        />
                    </Grid>

                    <Grid item>
                        <CheckboxOption
                            // name={`${fieldName}.no_concern`}
                            option={{
                                label: 'Show Inactive Problems',
                                value: false,
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setProblemsFilterType(e.target.checked ? 'inactive' : null)
                            }}
                            checked={problemsFilterType === 'inactive'}
                        />
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12}>
                <DataGrid
                    sx={sx.datagrid}
                    getRowId={(row) => row.id}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    rows={
                        problemsFilterType
                            ? medicalProblems.filter((mb) => mb.status.code === problemsFilterType)
                            : medicalProblems
                    }
                    columns={columns}
                    pageSize={limit}
                    // rowsPerPageOptions={[10]}
                    paginationMode="server"
                    disableColumnSelector={true}
                    disableSelectionOnClick
                    loading={loading}
                    rowCount={total}
                    pagination
                    disableColumnMenu={true}
                    page={page}
                    onPageChange={(page: number) => setPage(page)}
                    // onRowClick={handleRowClick}
                />
            </Grid>
        </>
    )
}
