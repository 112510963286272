import { CircularProgress, Grid, Typography } from '@material-ui/core'
import React, { Suspense, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Loading } from 'shared/elements/Loading'
import { Card } from '../view/Card'
import { NoData } from './NoData'

import Routes from 'constants/routes'
import { convertValueTextFamilyMembers } from 'containers/PatientsSetting/view/FamilyPsychiatricHistory/utils'
import { apiCareTeam } from 'services/Patient/apiCareTeam'
import { useNotification } from 'shared/elements/Notification'
import { CareTeamInterfaces } from '../interfaces'
import { usePatientDashboardStyles } from '../style'
import CareTeamForm from '../view/CareTeam/CareTeamForm'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
}

export const CareTeam = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const { idPatient: patientIdByRef } = useParams<{ idPatient: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [providers, setProviders] = useState([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId || patientIdByRef) {
            getCareTeam(patientId || patientIdByRef)
        }

        return () => abortController.abort()
    }, [patientId, patientIdByRef])

    const getCareTeam = async (patientId) => {
        try {
            setLoading(true)
            const resp = await apiCareTeam.getAll(patientId, { page: '1', limit: '7' })
            setProviders(resp.data.data)
            // setProviders(resp as CareTeamInterfaces[])
        } catch (ex) {
            notification('Cannot get list Family Medical History', 'error')
        }

        setLoading(false)
    }

    const handleCallApiGetAll = () => {
        if (patientId || patientIdByRef) {
            getCareTeam(patientId || patientIdByRef)
        }
        setIsAddingNew(false)
    }

    const handleViewAll = () => {
        if (patientId || patientIdByRef)
            history.push(Routes.PATIENT.CARE_TEAM.replace(':id', patientId || patientIdByRef))
    }

    const Content = () => (
        <>
            {providers.map((item, key) => {
                return (
                    <Grid
                        container
                        key={key}
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #EFF2F5',
                            paddingBottom: 8,
                            height: 'fit-content',
                        }}>
                        <Grid
                            item
                            container
                            alignItems="flex-start"
                            direction="column"
                            style={{ width: 'fit-content' }}>
                            <Typography>
                                {item?.practitioner?.id
                                    ? `${item?.practitioner?.first_name} ${item?.practitioner?.last_name} - ${item?.practitioner?.id}`
                                    : item?.practitioner_id}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            })}

            {!!providers.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Care Team">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {providers.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            <CareTeamForm
                // inpatientPsychiatricId={}
                patientId={patientId || patientIdByRef}
                open={isAddingNew}
                onOke={handleCallApiGetAll}
                onClose={() => {
                    setIsAddingNew(false)
                }}
            />
        </Card>
    )
}
