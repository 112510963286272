/* eslint-disable no-await-in-loop */
import {
  Box,
  FormLabel,
  Grid,
  makeStyles,
  Typography,
  Tooltip,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ButtonIconEl } from 'shared/elements'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ReportIcon from '@mui/icons-material/Report';
import Files from 'react-butterfiles'
import { uploadFileToS3 } from 'services/Documents'
import axios from 'axios'
import { styled } from "@material-ui/core";
import completeSign from 'assests/sidebar/Vector.svg'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const useStyles = makeStyles(() => ({
  download_text: {
    color: '#5571C6',
    cursor: 'pointer',
    padding: '8px !important',
  },
  open_browser_file: {
    color: '#5571C6',
    cursor: 'pointer',
  },
  box_files: {
    marginLeft: -6,
    paddingTop: 12,
  },
  drop_text: {
    pointerEvents: 'none',
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9',
    borderRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#15A452',
  },
}));

const FileUploadBulk = ({ onChange }) => {
  const classes = useStyles()
  const [isHover, setIsHover] = useState(false)
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    onChange({
      attachment_key: fileList.map(f => f.key?.toLowerCase()).join('|||'),
      document_name: fileList.map(f => f.name?.toLowerCase()).join('|||'),
    })
  }, [fileList]);

  const onUploadProgress = (fileName) => (progressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [fileName]: percentCompleted,
    }));
  };

  const handleUploadAttachmentFile = async (files = []) => {
    const newFileList = files.map((file) => ({
      ...file,
      progress: 0,
    }));

    console.log(newFileList);

    setFileList((prevList) => [...prevList, ...newFileList]);

    for (const file of files) {
      try {
        setIsUploading(true);
        const fileContext = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.addEventListener('loadend', (event) => {
            resolve(event.target.result);
          });
          reader.readAsArrayBuffer(file.src.file);
        });
        const fileType = file.src.file.type.toLowerCase();
        const res = await uploadFileToS3({
          file_name: file.src.file.name.toLowerCase(),
        });
        const { url, key } = res.data.document;
        // Update fileList with the retrieved key
        setFileList((prevList) =>
          prevList.map((f) =>
            f.name === file.name ? { ...f, key } : f
          )
        );
        await axios.put(url, fileContext, {
          headers: {
            'Content-Type': fileType,
          },
          onUploadProgress: onUploadProgress(file.name),
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleErrors = (errors) => {
    setErrors(errors);
    setIsHover(false);
  };

  const handleRemoveFile = (fileName) => {
    setFileList((prevList) => prevList.filter((file) => file.name !== fileName));
  };

  const supportedFormatsMessage = "Supported File Formats: JPG, JPEG, PNG, DOC, DOCX, XLS, XLSX, PPT, PDF";

  return (
    <Grid container>
      <Grid item>
        <FormLabel component="p" className="d-flex justify-space-between align-center" style={{ fontSize: 14, fontWeight: 500 }}>
          File Upload
          <Tooltip title={supportedFormatsMessage} arrow>
            <ReportIcon style={{ marginLeft: 5, cursor: 'pointer', color: '#5571C6' }} />
          </Tooltip>
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <Files
          multiple={true}
          multipleMaxSize="100mb"
          maxSize="100mb"
          accept={[
            'image/jpg',
            'image/jpeg',
            'image/png',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/pdf',
          ]}
          onSuccess={handleUploadAttachmentFile}
          onError={handleErrors}
        >
          {({ browseFiles, getDropZoneProps }) => (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              {...getDropZoneProps({
                style: {
                  height: 150,
                  borderRadius: 4,
                  border: !isHover ? '2px #E9ECF1 dashed' : '2px #bcbec3 dashed',
                  background: '#FBFCFC',
                },
              })}
              onDragEnter={() => setIsHover(true)}
              onDragLeave={() => setIsHover(false)}
            >
              {!isHover ? (
                <>
                  <Typography style={{ marginLeft: 6 }}>
                    Drag and drop to upload or{' '}
                    <span className={classes.open_browser_file} onClick={browseFiles}>
                      browse for a file
                    </span>
                  </Typography>
                </>
              ) : (
                <Typography className={classes.drop_text}>Drop here</Typography>
              )}
            </Grid>
          )}
        </Files>
      </Grid>
      <Grid item xs={12} className={classes.box_files}>
        <Grid container spacing={1} style={{ color: 'rgb(48, 62, 78)' }}>
          {fileList.map((file) => (
            <Grid key={file.name} container xs={12} style={{ padding: '20px 20px 20px 3px' }}>
              <Grid container xs={12} style={{ paddingLeft: '8px' }}>
                <span className={classes.fileName} style={{ fontWeight: '400' }}>File:</span>
                <span className={classes.fileName} style={{ fontWeight: '500', paddingLeft: '5px' }}>{file.name}</span>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: '8px' }}>
                <BorderLinearProgress variant="determinate" value={uploadProgress[file.name] || 0} />
                {uploadProgress[file.name] === 100 ? (
                  <img
                    src={completeSign}
                    alt=""
                    height="20"
                    style={{ paddingLeft: '8px' }}
                  />
                ) : (
                  <Typography style={{ paddingLeft: '8px' }}>{uploadProgress[file.name] || 0}%</Typography>
                )}
                <ButtonIconEl size="small" onClick={() => handleRemoveFile(file.name)}>
                  <HighlightOffIcon style={{ fontSize: 18, color: '#9CA9B8' }} />
                </ButtonIconEl>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileUploadBulk;
