import {
    Button,
    CircularProgress,
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import userImg from '../../../assests/images/erika_provider.png'
import { UserRoles } from '../../../constants/user-roles'
import { getNotificationPreferences } from '../../../services/Notification'
import { getClinicAdminProfileDetail } from '../../../store/actions/clinicAdmin'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserId,
    getLoggedInUserRole,
} from '../../../utilities'
import '../MainProfile/MainProfile.scss'
import ClinicAdminProfileEdit from './ClinicAdminProfileEdit'
import { ButtonEl } from '../../../shared/elements'
class ClinicAdminProfile extends Component {
    state = {
        isEdit: false,
        isCreate: false,
        isView: true,
        isProfile: false,
        isDialog: true,
        preferences: {
            email: false,
            sms: false,
            app_notification: false,
        },
        userRole: getLoggedInUserRole(),
    }

    componentDidMount() {
        const {
            isEditMode,
            isCreateMode,
            isViewMode,
            isUserProfile,
            isProfile,
        } = this.props
        this.setState({
            isEdit: !!isEditMode,
            isCreate: !!isCreateMode,
            isView: !!isViewMode,
            isUserProfile,
            isProfile,
        })

        // get profile data
        this.fetchClinicAdminProfile()
        if (
            this.state.userRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            this.fetchNotificationPreferences()
        }
    }
    // componentWillUnmount() {
    //     const {
    //         actions: { resetPatientProfile },
    //     } = this.props;
    //     resetPatientProfile();
    // }
    handleEditClose = () => {
        this.setState({ isView: true })
        this.fetchClinicAdminProfile()
        this.fetchNotificationPreferences()
    }

    fetchNotificationPreferences = async () => {
        try {
            const userId = this.props.isUserProfile
                ? this.props.userId
                : getLoggedInUserId()
            const preferences =
                await getNotificationPreferences({
                    userId,
                })
            this.setState({
                ...this.state,
                preferences:
                    preferences.data?.data ??
                    this.state.preferences,
            })
        } catch (error) {
            console.log(
                'Failed to retrieve notification preferences',
            )
        }
    }

    fetchClinicAdminProfile = () => {
        const {
            isCreateMode,
            isUserProfile,
            userId,
            clinicAdminProfile,
            actions: { getClinicAdminProfileDetail },
            isProfile,
        } = this.props
        const profileId = isUserProfile
            ? userId
            : getLoggedInUserId()

        if (
            (isUserProfile &&
                !isCreateMode &&
                !Object.keys(clinicAdminProfile).length) ||
            isProfile
        ) {
            getClinicAdminProfileDetail(profileId)
        }
    }

    handleEdit = () => {
        const { isEdit, isView } = this.state
        this.setState({ isEdit: !isEdit, isView: !isView })
    }

    render() {
        const { isCreate, isView, preferences } = this.state

        const {
            clinicAdminProfile,
            toggleToUserList,
            handleGoBack,
        } = this.props
        const {
            first_name,
            last_name,
            user,
            clinic,
            photo,
        } = clinicAdminProfile

        return !isCreate &&
            !Object.keys(clinicAdminProfile).length ? (
            <CircularProgress className="loader" />
        ) : (
            <>
                {/* <Dialog
                    open={isDialog}
                    // onClose={() => this.setState({ isDialog: false })}
                    fullWidth
                    aria-labelledby="profile-dialog"
                    className="profile-dialog-main">
                    <DialogTitle id="profile-dialog">
                        Clinic Admin
                        <CloseIcon onClick={() => {
                            this.setState({ isDialog: false })
                            handleGoBack()
                        }

                        } />
                    </DialogTitle>
                    <DialogContentText> */}
                {isView ? (
                    <>
                        <Grid
                            item
                            xs={12}
                            className="bg-tab-client1 bg-tab dFlexSpaceBw">
                            <Typography
                                variant="h3"
                                className="clinic-label">
                                {clinic?.clinic_name}
                            </Typography>
                            <Grid>
                                <Typography
                                    variant="h6"
                                    className="clinic--address-label">
                                    {clinic?.add1}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className="clinic--address-label">
                                    {clinic?.add2}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className="clinic--address-label">
                                    {clinic?.city}
                                </Typography>
                                <Typography
                                    variant="h6"
                                    className="clinic--address-label">{`${clinic?.state} ${clinic?.zip}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="row">
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid
                                        item
                                        className="upload-wrap client-profile-wrap">
                                        <div className="empty-bg">
                                            <img
                                                src={photo}
                                                alt="Profile"
                                                className="profile-img"
                                                width={164}
                                                height={164}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="column"
                                        item
                                        xs={8}
                                        spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            className="profile-name-wrap-clinic">
                                            <Typography
                                                variant="h1"
                                                className="main-name">{`${capitalizeFirstLetter(
                                                first_name,
                                            )} ${last_name}`}</Typography>
                                            <Grid
                                                container
                                                direction="row">
                                                <Grid
                                                    container
                                                    item
                                                    md={11}
                                                    xs={12}
                                                    spacing={
                                                        2
                                                    }>
                                                    {/* <FormLabel
                                                        className="tm-form-title"
                                                        component="legend"
                                                    >
                                                        {`${clinic.clinic_name}`}
                                                    </FormLabel> */}
                                                    {user && (
                                                        <>
                                                            <Grid
                                                                container
                                                                item
                                                                md={
                                                                    5
                                                                }
                                                                xs={
                                                                    12
                                                                }>
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        12
                                                                    }
                                                                    xs={
                                                                        12
                                                                    }>
                                                                    <FormLabel>
                                                                        {' '}
                                                                        Email:
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        12
                                                                    }
                                                                    xs={
                                                                        12
                                                                    }>
                                                                    <Typography variant="subtitle2">
                                                                        {' '}
                                                                        {
                                                                            user?.email
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                md={
                                                                    5
                                                                }
                                                                xs={
                                                                    12
                                                                }>
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        12
                                                                    }
                                                                    xs={
                                                                        12
                                                                    }>
                                                                    <FormLabel>
                                                                        Cellphone:
                                                                    </FormLabel>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    md={
                                                                        12
                                                                    }
                                                                    xs={
                                                                        12
                                                                    }>
                                                                    <Typography variant="subtitle2">
                                                                        {formatPhone(
                                                                            user?.mob
                                                                                .replace(
                                                                                    /\D/g,
                                                                                    '',
                                                                                )
                                                                                .slice(
                                                                                    -10,
                                                                                ),
                                                                        )}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                                {/* <Grid
                                                    item
                                                    xs={5}>
                                                    {this
                                                        .state
                                                        .userRole ===
                                                        UserRoles.TM2U_SUPER_ADMIN && (
                                                        <Grid
                                                            item
                                                            xs={
                                                                12
                                                            }>
                                                            <Grid
                                                                item>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    className="tm-form-title">
                                                                    Notifications
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                item
                                                                direction="column"
                                                                spacing={
                                                                    1
                                                                }>
                                                                <Grid
                                                                    item>
                                                                    <Typography variant="subtitle1">
                                                                        <FormLabel>
                                                                            SMS
                                                                        </FormLabel>

                                                                        :{' '}
                                                                        <Typography variant="subtitle2">
                                                                            {preferences.sms
                                                                                ? 'On'
                                                                                : 'Off'}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item>
                                                                    <Typography variant="subtitle1">
                                                                        <FormLabel>
                                                                            Email
                                                                        </FormLabel>

                                                                        :{' '}
                                                                        <Typography variant="subtitle2">
                                                                            {preferences.email
                                                                                ? 'On'
                                                                                : 'Off'}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>

                                                                <Grid
                                                                    item>
                                                                    <Typography variant="subtitle1">
                                                                        <FormLabel>
                                                                            In-App
                                                                        </FormLabel>

                                                                        :{' '}
                                                                        <Typography variant="subtitle2">
                                                                            {preferences.app_notification
                                                                                ? 'On'
                                                                                : 'Off'}
                                                                        </Typography>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            xs={11}
                            justifyContent="flex-end">
                            <ButtonEl
                                variant="contained"
                                startIcon={<EditIcon />}
                                onClick={this.handleEdit}
                                className="mb2">
                                Edit
                            </ButtonEl>
                        </Grid>
                    </>
                ) : (
                    // <Grid fullWidth container className="profile-dialog-main__contentWrap">
                    //     <Grid item xs={4} className="profile-dialog-main__contentWrap__imgWrap">Profile <br />Photo</Grid>
                    //     <Grid item xs={8} className="profile-dialog-main__contentWrap__detailWrap">
                    //         <Typography className="profile-dialog-main__contentWrap__detailWrap__title">
                    //             {`${first_name} ${last_name}`}
                    //         </Typography>
                    //         <Grid item xs={12} className="dFlexSB">
                    //             <Grid item xs={5}>
                    //                 <span className="profile-dialog-main__contentWrap__detailWrap__key">
                    //                     Email:
                    //                 </span>
                    //             </Grid>
                    //             <Grid item xs={7}>
                    //                 <span
                    //                     className="profile-dialog-main__contentWrap__detailWrap__value">
                    //                     {user?.email}
                    //                 </span>
                    //             </Grid>
                    //         </Grid>
                    //         <Grid item xs={12} className="dFlexSB">
                    //             <Grid item xs={5}>
                    //                 <span
                    //                     className="profile-dialog-main__contentWrap__detailWrap__key">
                    //                     Cellphone:
                    //                 </span>
                    //             </Grid>
                    //             <Grid item xs={7}>
                    //                 <span
                    //                     className="profile-dialog-main__contentWrap__detailWrap__value">
                    //                     {user?.mob.replace(/\D/g, '').slice(-10)}
                    //                 </span>
                    //             </Grid>
                    //         </Grid>
                    //         <Grid item xs={12} className="dFlexSB">
                    //             <Grid item xs={5}>
                    //                 <span className="profile-dialog-main__contentWrap__detailWrap__key">
                    //                     Clinic name:
                    //                 </span>
                    //             </Grid>
                    //             <Grid item xs={7}>
                    //                 <span
                    //                     className="profile-dialog-main__contentWrap__detailWrap__value">
                    //                     {clinic?.clinic_name}
                    //                 </span>
                    //             </Grid>
                    //         </Grid>
                    //     </Grid>
                    //     <Grid container className="profile-dialog-main__contentWrap__btnWrap">
                    //         <Button
                    //             variant="contained"
                    //             startIcon={<EditIcon />}
                    //             className="btn-primary"
                    //             onClick={this.handleEdit}>
                    //             Edit
                    //         </Button>
                    //     </Grid>
                    // </Grid>
                    <ClinicAdminProfileEdit
                        profileDetails={clinicAdminProfile}
                        handleEditClose={
                            this.handleEditClose
                        }
                        toggleToUserList={isCreate}
                        onSaveCallback={toggleToUserList}
                        preferences={preferences}
                    />
                )}
                {/* </DialogContentText>

                </Dialog> */}
            </>
        )
    }
}

function mapStateToProps(state) {
    const {
        clinicAdmin: { clinicAdminProfile },
    } = state

    return { clinicAdminProfile }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    getClinicAdminProfileDetail,
                },
                dispatch,
            ),
        },
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ClinicAdminProfile)
