import {
    Box,
    Button,
    FormLabel,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import AddIcon from '@mui/icons-material/Add'
import EastIcon from '@mui/icons-material/East'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import { CircularProgress, Dialog } from '@mui/material'
import Divider from '@mui/material/Divider'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { SCHEDULE_POPULATE } from 'constants/index'
import Routes from 'constants/routes'
import { OPTIONS_TIMEZONES } from 'constants/states'
import { useAppointmentBookingFormData } from 'containers/PatientsSetting/hooks'
import SchedulerMenu from 'containers/SchedulerMenu'
import { useSuperbillData } from 'containers/WorkQueues/Superbills/useSuperbillData'
import { getName } from 'containers/WorkQueues/Waitlist/util'
import { useFormik } from 'formik'
import moment from 'moment'
import React, { lazy, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getSpecialty, getVisitType, getLocations } from 'services/Calendar'
import { getPatientNextAppointmentInWaitlist } from 'services/Patient/patient'
import { apiWaitlist, searchProviderByName } from 'services/WorkQueue'
import { SelectSearchInput } from 'shared/elements/FormItem/SelectSearchInput'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
// import NewTimePicker from 'shared/elements/TimePicker/NewTimePicker'
import { NewTimePicker } from 'shared/elements'
import { useDebounce } from 'shared/hooks'
import { useQuery } from 'shared/hooks/useQuery'
import { changeAppointment } from 'store/actions/schedule'
import { getCustomizedFormattedDate } from 'utilities/dateOperations'
import { getFullName } from 'utilities/formats'
import * as Yup from 'yup'
import TrashIcon from '../../../../assests/icons/trash.svg'
import { Button as ButtonCustom } from '../../../../components/custom'
import '../../FollowUp/Recall/recallstyle.scss'
import NoteField from '../../component/NoteField'
import { addWaitListValueState } from 'store/actions/waitlist'
import MultipleSelect from 'containers/SchedulerMenu/Views/MultipleSelect'

const { v4: uuidv4 } = require('uuid')
const Select = lazy(() => import('shared/elements/FormItem/Select'))
const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        fontSize: '15px !important',
        borderRadius: '8px !important',
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
        // height: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },

    readOnlyInput: {
        backgroundColor: '#989CA8 !important', // Set your desired background color here
    },
    inputFormLabel: {
        fontWeight: '500',
        fontSize: '14px',
        color: '#667689',
    },
    required_text: {
        color: 'red',
    },
})
const initial_prefer_time = {
    days: [],
    start: moment().format('HH:mm'),
    end: moment().format('HH:mm'),
    timezone: '',
}
export const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const WaitlistFormNew = () => {
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const search = useQuery()
    const { id } = useParams()
    const patient_id = search.get('patient_id')
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [listLocation, setListLocation] = useState([])
    const [listProvider, setListProvider] = useState([])
    const [listWaitListSpecialty, setListWaitListSpecialty] = useState([])
    const [listWaitListVisitType, setListWaitListVisitType] = useState([])
    const [listWaitListLocation, setListWaitListLocation] = useState([])
    const [listWaitListProvider, setListWaitListProvider] = useState([])
    const [loadingSpecialty, setLoadingSpecialty] = useState(false)
    const [loadingVisitType, setLoadingVisitType] = useState(false)
    const [loadingProvider, setLoadingProvider] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [listDay, setListDay] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null)
    const [waitlist, setWaitlist] = useState({})
    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [newAppointmentData, setNewAppointmentData] = React.useState({
        next_appointment_date: null,
        next_appointment_id: null,
    })
    const [nextAppt, setNextAppt] = useState(null)
    const [timeZone, setTimeZone] = React.useState('')
    const activeTab = 1
    const [searchProvider, setSearchProvider] = useState('')
    const errors = {
        invalid: 'Start time must not be less than end time or equal',
        duplicate: 'Contains duplicate Day and time interval',
        blank: 'Last line contains blank values',
    }
    const debouncedProvider = useDebounce(searchProvider, 500)
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const waitlistValues = useSelector((state) => state.waitlist?.waitlist_values)
    const initialValues = {
        date: moment().format('YYYY-MM-DD'),
        service_location_id: '',
        specialty_id: '',
        visit_type_id: '',
        patient_id: patient_id ? patient_id : '',
        prefer_provider_id: '',
        prefer_time: [],
        next_appointment_date: null,
        next_appointment_id: null,
        notes: [],
        note: '',
        noteView: 'no-view',
        duration: '',
    }
    const dispatch = useDispatch()
    const validationSchema = Yup.object({
        patient_id: Yup.number().required().min(1, 'This field is required!'),
        specialty_id: Yup.number().required().min(1, 'This field is required!'),
        visit_type_id: Yup.number().required().min(1, 'This field is required!'),
        service_location_id: Yup.number().required().min(1, 'This field is required!'),
        prefer_time: Yup.array().required().min(1, 'This field is required!'),
    })
    const getNextAppt = (value) => {
        dispatch(changeAppointment(value))
    }

    const handleSubmit = async (values) => {
        setSubmitting(true)
        try {
            const checkPreferTime = checkTime(formik.values?.prefer_time, 'submit')
            if (checkPreferTime) {
                notification(checkPreferTime, 'error')
                setSubmitting(false)
                return true
            }
            if (values.prefer_provider_id === -1) {
                delete values.prefer_provider_id
            }
            if (id) {
                await apiWaitlist.update(id, {
                    ...values,
                    next_appointment_date:
                        newAppointmentData.next_appointment_date ||
                        formik?.values?.next_appointment_date,
                    next_appointment_id:
                        newAppointmentData.next_appointment_id ||
                        formik?.values?.next_appointment_id,
                    prefer_time:
                        formik?.values?.prefer_time?.length > 0 &&
                        formik?.values?.prefer_time[0]?.days?.length > 0
                            ? formik?.values?.prefer_time
                            : [],
                })
            } else {
                await apiWaitlist.create({
                    ...values,
                    next_appointment_date:
                        newAppointmentData.next_appointment_date ||
                        formik?.values?.next_appointment_date,
                    next_appointment_id:
                        newAppointmentData.next_appointment_id ||
                        formik?.values?.next_appointment_id,
                    prefer_time:
                        formik?.values?.prefer_time?.length > 0 &&
                        formik?.values?.prefer_time[0]?.days?.length > 0
                            ? formik?.values?.prefer_time
                            : [],
                })
            }
            notification(`${id ? 'Updated' : 'Created'} waitlist successfully`, 'success')
            handleBack()
        } catch (error) {
            notification(
                error?.data?.message ||
                    error?.message ||
                    `An error occurred while ${id ? 'updating' : 'creating'} the Waitlist!`,
                'error',
            )
        } finally {
            setSubmitting(false)
        }
    }
    const formik = useFormik({
        validationSchema,
        initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })
    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    const providerId =
        formik.values?.prefer_provider_id !== -1
            ? formik.values?.prefer_provider_id?.toString()
            : null
    const { locations, visitTypes, dataLoading } = useAppointmentBookingFormData(providerId)

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (id === 0 || !id) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = formik.values?.notes
            }
            formik.setFieldValue('notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )
    const handleBack = () => {
        dispatch(addWaitListValueState(null))
        history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN, {
            previousTab: 1,
        })
    }

    const getListSpecialty = async () => {
        try {
            setLoadingSpecialty(true)
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListWaitListSpecialty(data)
            setListSpecialty(() => {
                const list = data?.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Specialty!',
                'error',
            )
        } finally {
            setLoadingSpecialty(false)
        }
    }

    const getListVisitType = async () => {
        try {
            setLoadingVisitType(true)
            const params = {
                page: 1,
                limit: 100000,
            }

            if (formik.values.specialty_id !== -1) {
                params.specialty_id = formik.values.specialty_id
            }
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType(params)
            setListWaitListVisitType(data)
            setListVisitType(() => {
                const list = data?.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                    duration: el.duration,
                }))
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        } finally {
            setLoadingVisitType(false)
        }
    }

    const getListProvider = async () => {
        try {
            setLoadingProvider(true)
            const { data } = await searchProviderByName(searchProvider)
            setListWaitListProvider(data)
            setListProvider(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: getFullName(el.first_name, el.last_name),
                }))
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Provider!',
                'error',
            )
        } finally {
            setLoadingProvider(false)
        }
    }

    const getOneWaitlist = async (id) => {
        setLoading(true)
        try {
            const {
                data: { waitlist },
            } = await apiWaitlist.getById(id)
            setWaitlist(waitlist)
            const modifyWaitList = convertFormikValues(waitlist)
            if (modifyWaitList) formik.setValues({ ...formik.values, ...modifyWaitList })
        } catch (e) {
            notification(e?.data?.message || e?.message || 'Cannot get Waitlist!', 'error')
        } finally {
            setLoading(false)
        }
    }

    const getListLocation = async () => {
        try {
            setLoadingLocation(true)
            const {
                data: { data },
            } = await getLocations({ size: 100000 })
            setListWaitListLocation(data)
            setListLocation(() => {
                const list = data
                    .filter((el) => !el.is_external)
                    .map((el) => ({
                        key: el.id,
                        value: el.name,
                    }))
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Location!',
                'error',
            )
        } finally {
            setLoadingLocation(false)
        }
    }

    const handlePopulate = () => {
        dispatch(addWaitListValueState(formik?.values))
        history.push(Routes.SCHEDULE, {
            populate: true,
            from: SCHEDULE_POPULATE.WAITLIST,
            value: {
                specialty_id: formik?.values?.specialty_id,
                location_id: formik?.values?.service_location_id,
                practitioner_id: formik?.values?.prefer_provider_id,
                patient_id: formik?.values?.patient_id,
                visit_type_id: formik?.values?.visit_type_id,
                duration: formik?.values?.duration,
                // timezone: timeZone,
            },
        })
    }

    const convertFormikValues = (waitlist) => {
        const {
            patient,
            specialty,
            visit_type,
            service_location,
            prefer_provider,
            prefer_time,
            notes,
        } = waitlist
        return {
            patient_id: patient?.id ? patient.id : '',
            specialty_id: specialty?.id ? +specialty.id : '',
            service_location_id: service_location?.id ? +service_location.id : '',
            visit_type_id: visit_type?.id ? +visit_type.id : '',
            prefer_provider_id: prefer_provider?.id ? +prefer_provider.id : '',
            prefer_time: prefer_time?.length > 0 ? prefer_time : [initial_prefer_time],
            notes,
        }
    }
    useEffect(() => {
        if (formik?.values?.prefer_time?.length > 0) {
            const modifyTimeZone = formik?.values?.prefer_time?.map((value) => {
                if (value.timezone === '') {
                    return {
                        ...value,
                        timezone: timeZone,
                    }
                }
                return value
            })
            if (modifyTimeZone) {
                formik.setValues({
                    ...formik.values,
                    prefer_time: modifyTimeZone,
                })
            }
        }
    }, [timeZone, formik.values.patient_id])
    useEffect(() => {
        if (id) {
            getOneWaitlist(id)
        }
        if (formik.values?.prefer_time?.length === 0) {
            formik.setValues({
                ...formik.values,
                prefer_time: [initial_prefer_time],
            })
        }
    }, [id])

    useEffect(() => {
        ;(async () => {
            if (searchProvider) {
                await getListProvider()
            }
        })()
    }, [debouncedProvider])
    useEffect(() => {
        ;(async () => {
            if (formik.values.specialty_id !== -1) {
                await getListVisitType()
            }
        })()
    }, [formik.values.specialty_id, waitlistValues])
    useEffect(() => {
        ;(async () => {
            const abortController = new AbortController()
            await [
                getListSpecialty(),
                getListProvider(),
                getListDay(),
                getListProvider(),
                getListLocation(),
            ]
            return () => abortController.abort()
        })()
    }, [])
    useEffect(() => {
        if (!id && waitlistValues) {
            setLoading(true)
            if (waitlistValues) {
                const visit_type = listWaitListVisitType.find(
                    (item) => item.id === waitlistValues.visit_type_id,
                )
                const specialty = listWaitListSpecialty.find(
                    (item) => item.id === waitlistValues.specialty_id,
                )
                const prefer_provider = listWaitListProvider.find(
                    (item) => item.id === waitlistValues.prefer_provider_id,
                )
                const service_location = listWaitListLocation.find(
                    (item) => item.id === waitlistValues.service_location_id,
                )
                const waitlist = {
                    ...waitlistValues,
                    visit_type,
                    specialty,
                    prefer_provider,
                    service_location,
                }
                setWaitlist(waitlist)
                formik.setValues({ ...formik.values, ...waitlistValues })
                setLoading(false)
            }
        }
    }, [
        waitlistValues,
        listWaitListVisitType,
        listWaitListSpecialty,
        listWaitListProvider,
        listWaitListLocation,
        // formik.values?.patient_id,
    ])
    const getAppoinmentDetails = async (id) => {
        setLoading(true)
        const nextAppointmentDetails = await getPatientNextAppointmentInWaitlist(id)
        if (nextAppointmentDetails?.data?.lastAppointment?.resource) {
            const nextAppointmentProps = nextAppointmentDetails?.data?.lastAppointment?.resource
            const start = moment(nextAppointmentProps.start)
            const result = {
                id: nextAppointmentProps.id,
                values: {
                    id: '',
                    day: start.format('dddd'),
                    specialty_id: '',
                    visit_type_id: '',
                    date: start.format('MM/DD/YYYY'),
                    start_time: start.format('hh:mm A'),
                    end_time: moment(nextAppointmentProps.end).format('hh:mm A'),
                    timezone: timeZone,
                    appointment_type: '',
                    reason_for_visit: '',
                    internal_note: '',
                    location_id: '',
                    duration: '',
                    supervising_provider: '',
                    is_require_translator: false,
                    appointment_status: '',
                    is_reoccurring_appointment: false,
                    days: [],
                    end_date: '',
                    unit: '',
                    once_every: '',
                    end_type: '',
                },
            }
            setNextAppt(result)
            formik.setFieldValue('next_appointment_date', nextAppointmentProps.start || null)
            formik.setFieldValue('next_appointment_id', nextAppointmentProps.id || null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: nextAppointmentProps.start,
                next_appointment_id: nextAppointmentProps.id,
            })
        } else {
            formik.setFieldValue('next_appointment_date', null)
            formik.setFieldValue('next_appointment_id', null)
            setNextAppt(null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: null,
                next_appointment_id: null,
            })
        }
        setLoading(false)
    }
    const patientDetails = React.useCallback(
        async (data) => {
            if (data) {
                if (data.timezone) {
                    // formik.values.initial_prefer_time.timezone = data.timezone
                    setTimeZone(data.timezone)
                }
                if (data.id) {
                    getAppoinmentDetails(data?.id)
                }
            }
        },
        [patient_id, formik?.values?.patient_id],
    )
    const reset = () => {
        formik.setFieldValue(initialValues)
    }
    const getListDay = async () => {
        try {
            setListDay(() => {
                const list = days?.map((day, index) => ({
                    key: index,
                    value: day,
                }))
                return list
            })
        } catch (error) {
            notification(error?.data?.message || error?.message || 'Cannot get list Day!', 'error')
        }
    }
    const isTimeSlotNotValid = (existingTimeSlots) => {
        for (let i = 0; i < existingTimeSlots.length - 1; i++) {
            const slot = existingTimeSlots[i]
            const existingStartTime = moment(slot.start, 'hh:mm').unix()
            const existingEndTime = moment(slot.end, 'hh:mm').unix()
            for (let j = i + 1; j < existingTimeSlots.length; j++) {
                const nextSlot = existingTimeSlots[j]
                const nextStartTime = moment(nextSlot.start, 'hh:mm').unix()
                const nextEndTime = moment(nextSlot.end, 'hh:mm').unix()
                // Check if the time slots have the same timeZone and overlapping days
                if (
                    slot.timezone === nextSlot.timezone &&
                    slot.days.some((existingDay) =>
                        nextSlot.days.some(
                            (nextDay) =>
                                existingDay.key === nextDay.key &&
                                existingDay.value === nextDay.value,
                        ),
                    )
                ) {
                    // Check if the time slots overlap
                    if (
                        (nextStartTime >= existingStartTime && nextStartTime < existingEndTime) ||
                        (nextEndTime > existingStartTime && nextEndTime <= existingEndTime)
                    ) {
                        return true // Conflict found, the time slots are not valid
                    }
                }
            }
        }

        return false // No conflict found, the time slots are valid
    }

    const isDuplicateTimeSlotValid = (
        newStart,
        newEnd,
        newTimeZone,
        newDays,
        existingTimeSlots,
    ) => {
        for (let i = 0; i < existingTimeSlots.length - 1; i++) {
            const slot = existingTimeSlots[i]
            const existingStartTime = moment(slot.start, 'hh:mm').unix()
            const existingEndTime = moment(slot.end, 'hh:mm').unix()
            // Check if the new time slot has the same timeZone and overlapping days
            if (
                newTimeZone === slot.timezone &&
                newDays.some((newDay) =>
                    slot.days.some(
                        (existingDay) =>
                            existingDay.key === newDay.key && existingDay.value === newDay.value,
                    ),
                )
            ) {
                // Check if the new time slot overlaps with any existing time slot
                if (
                    (newStart >= existingStartTime && newStart < existingEndTime) ||
                    (newEnd > existingStartTime && newEnd <= existingEndTime)
                ) {
                    return true // Conflict found, the time slot is not valid
                }
            }
        }
        return false // No conflict found, the time slot is valid
    }
    const checkTime = (preferTime, type) => {
        const lastObject = preferTime[preferTime.length - 1]
        if (lastObject) {
            const startTime = moment(lastObject.start, 'hh:mm').unix()
            const endTime = moment(lastObject.end, 'hh:mm').unix()
            const hasBlankValues = Object.values(lastObject).some(
                (value) => value === '' || value === null || value.length === 0,
            )
            if (hasBlankValues && type !== 'submit') {
                return errors.blank
            }
            if (startTime >= endTime && type !== 'submit') {
                return errors.invalid
            }
            let duplicate = false
            if (preferTime && preferTime.length > 1 && type === 'add')
                duplicate = isDuplicateTimeSlotValid(
                    startTime,
                    endTime,
                    lastObject.timezone,
                    lastObject.days,
                    preferTime,
                )
            if (preferTime && type === 'submit') {
                duplicate = isTimeSlotNotValid(preferTime)
            }
            if (duplicate) {
                return errors.duplicate
            }
        }

        return false
    }
    /**
     * Add  line value items
     */
    const handleAddItemAvailability = () => {
        const checkPreferTime = checkTime(formik.values?.prefer_time, 'add')
        if (checkPreferTime) {
            notification(checkPreferTime, 'error')
        } else {
            let prefer_time_obj = initial_prefer_time
            if (timeZone) {
                prefer_time_obj = {
                    ...prefer_time_obj,
                    timezone: timeZone,
                }
            }
            formik.setValues({
                ...formik.values,
                prefer_time: [...formik.values?.prefer_time, prefer_time_obj],
            })
        }
    }
    const handleClickDeleteIcon = (event, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(index)
    }
    /**
     * Delete row in  line value items
     */
    const handleDeleteAvailibilityLine = () => {
        if (selectedItemIndex !== null) {
            if (formik.values?.prefer_time?.length > 1) {
                const updatedItems = formik.values?.prefer_time.filter(
                    (_, i) => i !== selectedItemIndex,
                )
                formik.setValues({ ...formik.values, prefer_time: updatedItems })
            } else {
                formik.setValues({
                    ...formik.values,
                    prefer_time: [initial_prefer_time],
                })
            }
        }
        setSelectedItemIndex(null)
        setAnchorEl(null)
    }

    /**
     * Set Availibility line value items
     */
    const setAvailibilityLine = (lineIndex, field, value) => {
        formik?.setFieldValue(
            'prefer_time',
            formik.values?.prefer_time?.map((line, index) =>
                index === lineIndex ? { ...line, [field]: value } : line,
            ),
        )
    }
    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            // dispatch(
            //     changeAppointment({
            //         id: -1,
            //         values: {
            //             id: '',
            //             date: moment().format('YYYY-MM-DD'),
            //             end_time: moment().format('HH:mm'),
            //             start_time: moment().format('HH:mm'),
            //             appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
            //             reason_for_visit: '',
            //             internal_note: '',
            //             specialty_id: '',
            //             location_id: '',
            //             duration: '',
            //             visit_type_id: '',
            //             patient_id: clinicalNoteData.patient.id,
            //             practitioner_id: clinicalNoteData.provider_id,
            //             supervising_provider: '',
            //             is_require_translator: false,
            //             appointment_status: '',
            //             is_reoccurring_appointment: false,
            //             days: [],
            //             end_date: '',
            //             unit: '',
            //             once_every: '',
            //             end_type: '',
            //             timezone: timezone.locationTimezone,
            //         },
            //     }),
            // )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
        if (formik?.values?.patient_id) {
            getAppoinmentDetails(formik?.values?.patient_id)
        }
    }

    const handleChangeDay = (option, checked, rowIndex) => {
        const newValue = formik.values['prefer_time'][rowIndex]?.days
        if (checked) {
            newValue.push(option)
        } else {
            newValue.splice(
                newValue.findIndex((el) => el.key === option.key),
                1,
            )
        }
        setAvailibilityLine(rowIndex, 'days', newValue)
    }

    return (
        // <Box style={{ overflowY: "auto" }}>
        <Grid
            container
            className="recall-form"
            style={{
                padding: 24,
                overflowX: 'scroll',
            }}>
            <Loading visible={loading} />
            <Grid container>
                {submitting && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}
                {/*------------------------------------------------Back Button----------------------------------------------------*/}
                <Grid
                    container
                    style={{
                        marginBottom: 16,
                        cursor: 'pointer',
                    }}>
                    <div onClick={() => handleBack()}>
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />

                        <span className="link-back" color="inherit">
                            Back to Waitlist Form Queue
                        </span>
                    </div>
                </Grid>

                <Grid container item>
                    <Grid container alignContent="center" className="border-bottom">
                        <h4 className="page-title mb-1 page-RecordCall">
                            {id ? 'Edit Waitlist Form Record' : 'Add Waitlist Form Record'}{' '}
                            <span
                                style={{
                                    marginLeft: 12,
                                    display: 'inline-flex',
                                    alignItems: 'flex-end',
                                }}></span>
                        </h4>
                    </Grid>
                    <Grid>
                        <p className="date-text">Date Added:{moment().format('MM/DD/YYYY')}</p>
                    </Grid>

                    <Grid container item spacing={3} className="modal-spacer mt-3">
                        {/*------------------------Appointment Details---------------------------------*/}
                        <fieldset className="divider-information" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Appointment Details
                                </Typography>
                            </legend>
                            <Grid container item spacing={2}>
                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Patient <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SearchPatientInput
                                        className="position-button"
                                        value={
                                            formik.values.patient_id === -1
                                                ? ''
                                                : formik.values.patient_id
                                        }
                                        name="patient_id"
                                        handleAddRow={(value) => {
                                            if (!value) {
                                                formik.setFieldValue('next_appointment_date', null)
                                                formik.setFieldValue('next_appointment_id', null)
                                                setNewAppointmentData({
                                                    ...newAppointmentData,
                                                    next_appointment_date: null,
                                                    next_appointment_id: null,
                                                })
                                            }
                                            formik.setFieldValue('patient_id', value?.id)
                                        }}
                                        error={
                                            formik.touched?.patient_id &&
                                            Boolean(formik.errors?.patient_id)
                                        }
                                        helperText={
                                            formik.touched?.patient_id && formik.errors?.patient_id
                                        }
                                        getDetailPatient={(patient) => patientDetails(patient)}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Service Location{' '}
                                        <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SelectSearchInput
                                        placeholder={
                                            waitlist?.service_location?.name || 'Service Location'
                                        }
                                        options={listLocation}
                                        // value={formik?.values?.service_location_id}
                                        // onOpen={() => {
                                        //     if (!listLocation.length) getListLocation()
                                        // }}
                                        // loading={loadingLocation}
                                        onChange={(id) =>
                                            formik.setFieldValue(
                                                'service_location_id',
                                                id ||
                                                    convertFormikValues(waitlist)
                                                        .service_location_id,
                                            )
                                        }
                                        error={
                                            formik.touched.service_location_id &&
                                            formik.errors.service_location_id
                                        }
                                        helperText={
                                            formik.touched.service_location_id &&
                                            formik.errors.service_location_id
                                        }
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Specialty <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SelectSearchInput
                                        placeholder={
                                            waitlist?.specialty?.specialty ||
                                            'Select Speciality Type'
                                        }
                                        options={listSpecialty}
                                        // onOpen={() => {
                                        //     if (!listSpecialty.length) getListSpecialty()
                                        // }}
                                        loading={loadingSpecialty}
                                        onChange={(id) => {
                                            formik.setFieldValue(
                                                'specialty_id',
                                                id || convertFormikValues(waitlist).specialty_id,
                                            )
                                            formik.setFieldValue('visit_type_id', '')
                                        }}
                                        error={
                                            formik.touched.specialty_id &&
                                            formik.errors.specialty_id
                                        }
                                        helperText={
                                            formik.touched.specialty_id &&
                                            formik.errors.specialty_id
                                        }
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Visit Type <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SelectSearchInput
                                        placeholder={
                                            waitlist?.visit_type?.post_code || 'Select Visit Type'
                                        }
                                        options={listVisitType}
                                        // onOpen={() => {
                                        //     if (
                                        //         !listVisitType.length &&
                                        //         formik.values.specialty_id === -1
                                        //     )
                                        //         getListVisitType()
                                        // }}
                                        loading={loadingVisitType}
                                        onChange={(id) => {
                                            formik.setFieldValue(
                                                'visit_type_id',
                                                id || convertFormikValues(waitlist).visit_type_id,
                                            )
                                            formik.setFieldValue(
                                                'duration',
                                                listVisitType.find((item) => item.key === id)
                                                    ?.duration,
                                            )
                                        }}
                                        error={
                                            formik.touched.visit_type_id &&
                                            formik.errors.visit_type_id
                                        }
                                        helperText={
                                            formik.touched.visit_type_id &&
                                            formik.errors.visit_type_id
                                        }
                                        getOptionLabel={(option) =>
                                            formik.values.visit_type_id === -1 ? '' : option.value
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Preferred Provider{' '}
                                    </FormLabel>
                                    <SelectSearchInput
                                        placeholder={
                                            getName(waitlist.prefer_provider) ||
                                            'Select Preferred Provider'
                                        }
                                        options={listProvider}
                                        loading={loadingProvider}
                                        // onInputChange={(value) => setSearchProvider(value)}
                                        onChange={(id) =>
                                            formik.setFieldValue(
                                                'prefer_provider_id',
                                                id ||
                                                    convertFormikValues(waitlist)
                                                        .prefer_provider_id,
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Next Appointment{' '}
                                    </FormLabel>
                                    <TextField
                                        hiddenLabel
                                        id="filled-hidden-label-small"
                                        variant="outlined"
                                        size="small"
                                        readOnly={true}
                                        placeholder={'N/A'}
                                        value={
                                            formik.values?.next_appointment_date
                                                ? getCustomizedFormattedDate(
                                                      formik.values?.next_appointment_date,
                                                  )
                                                : newAppointmentData.next_appointment_date
                                                ? getCustomizedFormattedDate(
                                                      newAppointmentData.next_appointment_date,
                                                  )
                                                : 'N/A'
                                        }
                                        // onclear={clearData}
                                        // onClick={calvisibility}
                                        mask="__/__/____"
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '17px',
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                formik?.values
                                                                    ?.next_appointment_date ||
                                                                newAppointmentData?.next_appointment_date
                                                            ) {
                                                                getNextAppt(nextAppt)
                                                                setNewAppointmentModal({
                                                                    status: true,
                                                                    id: newAppointmentData?.next_appointment_id,
                                                                })
                                                            }
                                                        }}>
                                                        {!formik?.values?.next_appointment_date &&
                                                        !newAppointmentData?.next_appointment_date ? (
                                                            <ButtonCustom
                                                                tooltip="New Appointment Form"
                                                                variant="contained"
                                                                color="primary"
                                                                btnSize="small"
                                                                onClick={() => handlePopulate()}
                                                                fullWidth>
                                                                <AddIcon
                                                                    style={{
                                                                        fontSize: 20,
                                                                    }}
                                                                />
                                                            </ButtonCustom>
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="17"
                                                                height="17"
                                                                viewBox="0 0 17 17"
                                                                fill="none">
                                                                <path
                                                                    d="M4.14437 9.74724L8.48029 5.22689C8.63935 5.06107 8.71541 4.83036 8.67392 4.60687C8.23134 2.14843 5.88704 0.374894 3.3007 1.00933C-1.31875 2.24215 -0.952241 9.05513 3.54273 9.94911C3.75711 9.99237 3.98532 9.90585 4.13745 9.74724H4.14437ZM1.9937 5.08991H4.01989V2.97752H4.71143V5.08991H6.73762V5.81086H4.71143V7.93045H4.01989V5.81086H1.9937V5.08991ZM17 2.63147L15.4717 4.22477L12.9476 1.5933L14.4759 0L17 2.63147ZM12.4566 2.10517L14.9807 4.73664L4.46939 15.6951L0.66595 17L1.94529 13.0636C5.03645 9.84097 9.35854 5.33503 12.4566 2.10517Z"
                                                                    fill="#5B6B7E"
                                                                />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Dialog
                                        open={newAppointmentModal.status}
                                        sx={{
                                            '& .MuiDialog-paperFullWidth': {
                                                maxWidth: '1200px',
                                                height: '100vh',
                                                overflow: 'hidden',
                                            },
                                        }}
                                        fullWidth={true}
                                        onClose={newAppointmentModalToggle}
                                        className={classes.root}>
                                        <SchedulerMenu
                                            isPopupType="false"
                                            tab={activeTab}
                                            // onChangeTab={(tab) => setActiveTab(tab)}
                                            isFromClinicalNote
                                            open={newAppointmentModal.status}
                                            appointmentModal={newAppointmentModal}
                                            loading={false}
                                            onModalClose={newAppointmentModalToggle}
                                            // onSubmit={handleSubmitAppoinment}
                                            followUp={true}
                                            customHeader={true}
                                            customHeaderName="Appointment Record"
                                            locationOptionsData={locations.map((item) => ({
                                                key: item.id,
                                                value: item.name,
                                            }))}
                                            visitTypeOptionsData={visitTypes.map((item) => ({
                                                key: item.id,
                                                value: item.post_code,
                                                duration: item.duration,
                                            }))}
                                            statusOptionsData={statusOptions.map((item) => ({
                                                id: item.id,
                                                key: item.code,
                                                value: item.name,
                                            }))}
                                            // onOk={getNextAppt}
                                        />
                                    </Dialog>
                                </Grid>
                            </Grid>
                        </fieldset>
                        {/**-----------------------Paitent Availability-------------------------------- */}
                        <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Patient Availability
                                </Typography>
                            </legend>
                            <Grid item spacing={2}>
                                {formik.values?.prefer_time?.length > 0 &&
                                    formik.values?.prefer_time?.map((prefer, rowIndex) => {
                                        return (
                                            <div key={rowIndex} className="modifier_top patient_availibility_modifier_top">
                                                <div
                                                    style={{
                                                        width: '37%',
                                                        marginLeft: 10,
                                                    }}>
                                                    <FormLabel
                                                        component="p"
                                                        className={classes.inputFormLabel}>
                                                        Select Days{' '}
                                                    </FormLabel>
                                                    <MultipleSelect
                                                        name="practitioner_ids"
                                                        placeholder="Days"
                                                        value={prefer?.days}
                                                        options={listDay}
                                                        onOpen={() => {}}
                                                        handleChange={(option, checked) => {
                                                            handleChangeDay(
                                                                option,
                                                                checked,
                                                                rowIndex,
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        width: '30%',
                                                        display: 'flex',
                                                        // alignItems: 'center',
                                                        // justifyContent: 'space-between',
                                                    }}>
                                                    <div
                                                        style={{
                                                            width: '45%',
                                                        }}>
                                                        <FormLabel
                                                            component="p"
                                                            className="input-form-label">
                                                            Start{' '}
                                                        </FormLabel>
                                                        <NewTimePicker
                                                            values={moment(
                                                                `${formik.values.date} ${prefer?.start}`,
                                                                'YYYY-MM-DD HH:mm',
                                                            )}
                                                            onChange={(value) => {
                                                                setAvailibilityLine(
                                                                    rowIndex,
                                                                    'start',
                                                                    moment(value).format('HH:mm'),
                                                                )
                                                            }}
                                                            labelDropdown="Start time"
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            padding: '0 2px',
                                                        }}>
                                                        <EastIcon
                                                            sx={{
                                                                marginTop: '40px',
                                                                color: '#889db6',
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '45%',
                                                        }}>
                                                        <FormLabel
                                                            component="p"
                                                            className="input-form-label">
                                                            End{' '}
                                                        </FormLabel>
                                                        <NewTimePicker
                                                            values={moment(
                                                                `${formik.values.date} ${prefer?.end}`,
                                                                'YYYY-MM-DD HH:mm',
                                                            )}
                                                            onChange={(value) => {
                                                                setAvailibilityLine(
                                                                    rowIndex,
                                                                    'end',
                                                                    moment(value).format('HH:mm'),
                                                                )
                                                            }}
                                                            labelDropdown="End time"
                                                        />
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        width: '27%',
                                                    }}>
                                                    <Select
                                                        label="Time Zone"
                                                        name="timezone"
                                                        placeholder="Select time zone"
                                                        value={prefer?.timezone}
                                                        options={OPTIONS_TIMEZONES?.map((item) => ({
                                                            key: item.key,
                                                            value: item.label,
                                                        }))}
                                                        onChange={(value) =>
                                                            setAvailibilityLine(
                                                                rowIndex,
                                                                'timezone',
                                                                value,
                                                            )
                                                        }
                                                        error={
                                                            formik.touched.timezone &&
                                                            Boolean(formik.errors.timezone)
                                                        }
                                                        helperText={
                                                            formik.touched.timezone &&
                                                            formik.errors.timezone
                                                        }
                                                    />
                                                </div>

                                                <div
                                                    // className="icon_position"
                                                    style={{
                                                        width: '3%',
                                                        transform: 'translateY(30px)'
                                                    }}>
                                                    <Box display="flex">
                                                        <Divider
                                                            orientation="vertical"
                                                            variant="middle"
                                                            className="divider_height"
                                                            flexItem
                                                            style={{ width: 'fit-content' }}
                                                        />
                                                        <div
                                                            className="delete_icon"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop: 12,
                                                            }}
                                                            onClick={(event) => {
                                                                handleClickDeleteIcon(
                                                                    event,
                                                                    rowIndex,
                                                                )
                                                            }}>
                                                            {' '}
                                                            <img src={TrashIcon} />
                                                        </div>
                                                    </Box>
                                                </div>
                                            </div>
                                        )
                                    })}
                                <CustomAlertPopOver
                                    anchorEl={anchorEl}
                                    handleClose={() => {
                                        setAnchorEl(null)
                                        setSelectedItemIndex(null)
                                    }}
                                    title="Delete Paitent Availability"
                                    bodyText={`Are you sure you want to delete the paitent availability?`}
                                    confirmButtonName={'Delete'}
                                    confirmButtonColor={'red'}
                                    onSuccess={handleDeleteAvailibilityLine}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        whiteSpace: 'nowrap',
                                        marginTop: '5px',
                                    }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                            handleAddItemAvailability(e)
                                        }}>
                                        <AddCircleOutlineOutlined
                                            style={{
                                                color: '#14AE5C',
                                                fontSize: 18,
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                color: '#14AE5C',
                                                marginLeft: 8,
                                            }}>
                                            Add New Availability
                                        </Typography>
                                    </div>
                                </div>
                                {/* </fieldset> */}
                            </Grid>
                        </fieldset>
                        {/*------------------------Stuff Notes------------------------------------------*/}
                        <fieldset className="divider-information mt-2" style={{ width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>Staff Notes</Typography>
                            </legend>
                            <Grid item spacing={2} container>
                                <Grid item xs={12}>
                                    <NoteField
                                        note={formik?.values?.note}
                                        noteView={formik?.values?.noteView}
                                        notes={formik?.values?.notes}
                                        onChange={formik?.setFieldValue}
                                        // onBlur={handleNotesChange}
                                        handleNotesChange={handleNotesChange}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    top={20}
                    justifyContent="flex-end"
                    direction="row"
                    spacing={2}
                    className="cancel-button">
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={reset}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadingButton
                            loading={submitting}
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create}
                            onClick={(e) => {
                                formik.handleSubmit(e)
                            }}>
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        // </Box>
    )
}
export default WaitlistFormNew
