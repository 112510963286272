import React, { useState, useEffect, useRef } from 'react'
import { isEmpty } from 'lodash'
import { Grid } from '@material-ui/core'
import { Switch, useHistory, useLocation } from 'react-router-dom'
import { authRoutes } from '../../routes'
import Routes from '../../constants/routes'
import Loader from '../../utilities/loading-page'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { GuardProvider, GuardedRoute } from 'react-router-guards'

import Header from './Header/Header'
import Sidebar from './Sidebar/Sidebar'
import './AppLayout.scss'
import { getPatientProfileByUserId, setActiveLatest } from '../../store/actions/login'
import { UserRoles } from '../../constants/user-roles'
import { config } from '../../constants'
import {
    addEncounterQueueList,
    removeEncounterQueueList,
    setLastMessage,
    updateEncounterAppointmentStatus,
    updateEncounterQueueList,
    updateStatusEncounterQueueList,
    updateStatusParticipant,
} from '../../store/actions/provider'
import { Box, useMediaQuery } from '@mui/material'
import SidebarMobile from './Sidebar/SidebarMobile'
import HeaderMobile from './Header/HeaderMobile'
import EmptyNote from 'containers/Consultation/EmptyNote/EmptyNote'
import { sidebarVisibility } from 'store/actions/sidebar'
import { VideoProvider } from 'components/Consultation/components/VideoProvider'
import { ChatProvider } from 'components/Consultation/components/ChatProviderContext'
import localforage from 'localforage'
import SearchPatientGlobal from 'components/custom/SearchPatientGlobal'
import moment from 'moment'
import { ModalEl } from 'shared/elements'

const AppLayout = (props) => {
    const matchesMobile = useMediaQuery('(max-width:5px)')
    const telehealthScreen = useSelector((state) => state.telehealthScreen)
    const [isShowMenuMobile, setShowMenuMobile] = useState(false)
    const { isAuthenticated, logout } = props
    const [profilePhotoUrl, setProfilePhotoUrl] = useState()
    const [isShow, setIsShow] = useState(false)
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const token = localStorage.getItem('token')
    const providerId = localStorage.getItem('userId')
    // const isConsultant = useSelector((state) => state?.provider?.objectEncouterStart)
    const [openModal,setOpenModal]=useState(false)
    const [timeLeft, setTimeLeft] = useState(300)

    // const link = `${config.dev.baseUrlSocket}?type=practitioner????${providerId}`
    // //const link = `${config.dev.baseUrlSocket}?type=practitioner&authorization=${token}`
    // console.log(link)
    // const socketPage = ['/schedule', '/consultation']
    // const { sendMessage, lastMessage, readyState } = useWebSocket(
    //     link,
    //     {
    //         onOpen: () => console.log('connected from scheduler'),
    //         shouldReconnect: (closeEvent) => true,
    //     },
    //     socketPage.includes(location.pathname),
    // )

    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState]
    // console.log('connectionStatus', connectionStatus)

    // const encounterQueueListEncouter = useSelector((state) => state.provider.encounterQueueList)

    // useEffect(() => {
    //     console.log('71=>last Message: ', lastMessage)
    //     if (!isEmpty(lastMessage) && lastMessage?.data !== 'check') {
    //         const messageSocket = JSON.parse(lastMessage?.data)
    //         dispatch(setLastMessage(messageSocket))
    //         switch (messageSocket.action) {
    //             case 'waiting_room_member':
    //                 dispatch(updateStatusParticipant(messageSocket, encounterQueueListEncouter))
    //                 dispatch(updateStatusEncounterQueueList(messageSocket))
    //                 break
    //             case 'exam_room_member':
    //             case 'waiting_room_disconnected':
    //             case 'exam_room_disconnected':
    //             case 'encounter_status':
    //                 dispatch(updateStatusEncounterQueueList(messageSocket))
    //                 break
    //             case 'encounter_add':
    //                 dispatch(addEncounterQueueList(messageSocket.data))
    //                 break
    //             case 'appointment_status':
    //                 dispatch(updateEncounterAppointmentStatus(messageSocket))
    //                 break
    //             case 'encounter_remove':
    //                 dispatch(removeEncounterQueueList(messageSocket))
    //                 break
    //             case 'encounter_update':
    //                 dispatch(updateEncounterQueueList(messageSocket))
    //                 break
    //             default:
    //                 break
    //         }
    //     }
    // }, [lastMessage?.data])

    //check every one second if the warning message shows or not
    useEffect(() => {
        const runContinuously = () => {
            const lastMove = localStorage.getItem('lastActive') || false
            const consultingPatient = localStorage.getItem('isConsultant') ? localStorage.getItem('isConsultant') : false
            if(lastMove){
                const diffInSec = moment().diff(lastMove, 'seconds')
                if(diffInSec >= 3300 && diffInSec < 3600 && !consultingPatient){
                    setOpenModal(true)
                    setTimeLeft(Math.floor(3600-parseInt(diffInSec,10)))
                }
                else if(diffInSec >= 3600 && !consultingPatient){
                    const role = localStorage.getItem('role')
                    const EditedClinicalNoteids = localStorage.getItem('editedClinicalNote') ? JSON.parse(localStorage.getItem('editedClinicalNote')) : []
                    if(EditedClinicalNoteids.length){
                        fetch(`${baseURL}/clinical-note/clear-edit-mode-logout`, {
                            method:'post',
                            body: JSON.stringify({ ids: [...EditedClinicalNoteids] }),
                            headers:{
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`
                            },
                            keepalive: true
                        })
                    }
                    localforage.clear()
                    sessionStorage.clear()
                    if (role === UserRoles.PROVIDER) {
                        history.push(Routes.LOGIN.STAFF)
                        localStorage.clear()
                        return
                    }
                    localStorage.clear()
                    history.push(Routes.HOME)
                }
                else if(diffInSec < 3300 && !consultingPatient){
                    setOpenModal(false)
                }        
            }
        };
        const intervalId = setInterval(runContinuously, 1000);
        return () => {
          clearInterval(intervalId);
        };
    }, []);
    //remove isConsultant in sessionStorage and localStorage when refresh or close the tab
    useEffect(() => {
        const clearIsConsultant = (event) => { 
            const valueCheck = sessionStorage.getItem('isConsultant')
            if(valueCheck){
                sessionStorage.setItem('isConsultant',false)
                localStorage.setItem('isConsultant',false)
            }
          };
        window.addEventListener('beforeunload', clearIsConsultant);
        return () => {
          window.removeEventListener('beforeunload', clearIsConsultant);
        };
    }, [])
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds

    const toggleShowSideBar = () => {
        setIsShow(!isShow)
        dispatch(sidebarVisibility(!isShow))
    }
    const toggleShowMenuMobile = () => {
        setShowMenuMobile(!isShowMenuMobile)
    }
    const baseURL = process.env.REACT_APP_SONORA_V2_API_URL
    const onLogout = () => {
        const role = localStorage.getItem('role')
        const EditedClinicalNoteids = localStorage.getItem('editedClinicalNote') ? JSON.parse(localStorage.getItem('editedClinicalNote')) : []
        if(EditedClinicalNoteids.length){
            fetch(`${baseURL}/clinical-note/clear-edit-mode-logout`, {
                method:'post',
                body: JSON.stringify({ ids: [...EditedClinicalNoteids] }),
                headers:{
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                keepalive: true
            })
        }
        logout()
        dispatch(setActiveLatest(0))
        localforage.clear()
        sessionStorage.clear()
        if (role === UserRoles.PROVIDER) {
            history.push(Routes.LOGIN.STAFF)
            localStorage.clear()
            return
        }
        localStorage.clear()
        history.push(Routes.HOME)
    }

    const onProfileClick = () => {
        // history.push(Routes.PROFILE, {
        //     id: localStorage.getItem('userId'),
        //     role: localStorage.getItem('role'),
        // })
        history.push(
            Routes.SETTINGS.USER_SECURITY_EDIT.replace(':id', localStorage.getItem('userId')),
            {
                backTitle: 'Back',
            },
        )
    }
    const loadingRole = useSelector((state) => {
        return state.loader?.loadingRole
    })

    //auto logout after 60 min if the login user don't do any activity
    function handleMouseOver() {
        const loginToken = localStorage.getItem('token')
        if(loginToken){
            if(openModal){
                setOpenModal(false)
                setTimeLeft(300)
            }
            if(telehealthScreen.active){
                sessionStorage.setItem('isConsultant',true)
                localStorage.setItem('isConsultant',true)
            }
            const valueCheck = sessionStorage.getItem('isConsultant')
            if(!telehealthScreen.active && valueCheck){
                sessionStorage.setItem('isConsultant',false)
                localStorage.setItem('isConsultant',false)
            }
            const consultingPatient = localStorage.getItem('isConsultant') ? localStorage.getItem('isConsultant') : false
            const lastMove = localStorage.getItem('lastActive') || moment()
            const diff = moment().diff(lastMove, 'minutes')
            if(diff >= 60 && !consultingPatient){
                onLogout()
            }else{
                localStorage.setItem('lastActive',moment())
            }
        }else{
            onLogout()
        }
    }
    function handleClick() {
        const loginToken = localStorage.getItem('token')
        if(loginToken){
            if(openModal){
                setOpenModal(false)
                setTimeLeft(300)
            }
            if(telehealthScreen.active){
                sessionStorage.setItem('isConsultant',true)
                localStorage.setItem('isConsultant',true)
            }
            const valueCheck = sessionStorage.getItem('isConsultant')
            if(!telehealthScreen.active && valueCheck){
                sessionStorage.setItem('isConsultant',false)
                localStorage.setItem('isConsultant',false)
            }
            const consultingPatient = localStorage.getItem('isConsultant') ? localStorage.getItem('isConsultant') : false
            const lastMove = localStorage.getItem('lastActive') || moment()
            const diff = moment().diff(lastMove, 'minutes')
            if(diff >= 60 && !consultingPatient){
                onLogout()
            }else{
                localStorage.setItem('lastActive',moment())
            }
        }else{
            onLogout()
        }
    }

    // const isVerifyEmailRoute = () => {
    //     const pathName = location.pathname
    //     return pathName === Routes.VERIFY_EMAIL
    // }

    // if (isVerifyEmailRoute()) {
    //     return <VerifyEmail />
    // }

    if (isAuthenticated === null || loadingRole) {
        // show loader
        return <Loader />
    } else if (isAuthenticated) {
        return (
            <ChatProvider>
                <Box container="true" className="app-layout" onClick={()=>{handleClick()}} onMouseMove={handleMouseOver}>
                    {matchesMobile ? (
                        <SidebarMobile
                            onProfile={onProfileClick}
                            isShow={isShow}
                            isShowMenuMobile={isShowMenuMobile}
                            handleToggleShowMenuMobile={toggleShowMenuMobile}
                            handeOpenSideBar={toggleShowSideBar}
                            onLogout={onLogout}
                        />
                    ) : (
                        <Sidebar
                            isShow={isShow}
                            handeOpenSideBar={toggleShowSideBar}
                            onLogout={onLogout}
                        />
                    )}
                    <Box className="layout-right">
                        <Grid container direction="column" wrap="nowrap">
                            <div
                                style={{
                                    height: '54px',
                                    backgroundColor: '#EAECF0',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <SearchPatientGlobal />
                            </div>
                            <>
                                {matchesMobile ? (
                                    <HeaderMobile
                                        isShow={isShow}
                                        isShowMenuMobile={isShowMenuMobile}
                                        handleToggleShowMenuMobile={toggleShowMenuMobile}
                                        pathName={location.pathname}
                                        onLogout={onLogout}
                                        onProfile={onProfileClick}
                                        profilePhotoUrl={profilePhotoUrl}
                                        handleToggleShowSideBar={toggleShowSideBar}
                                    />
                                ) : (
                                    <Header
                                        isShow={isShow}
                                        isShowMenuMobile={isShowMenuMobile}
                                        handleToggleShowMenuMobile={toggleShowMenuMobile}
                                        pathName={location.pathname}
                                        onLogout={onLogout}
                                        onProfile={onProfileClick}
                                        profilePhotoUrl={profilePhotoUrl}
                                        handleToggleShowSideBar={toggleShowSideBar}
                                    />
                                )}
                                <div className="main-section-height pb-4">
                                    <div
                                        style={{
                                            position: 'relative',
                                            zIndex: 1,
                                            height: '100%',
                                            width: '100%',
                                        }}>
                                        <GuardProvider>
                                            <Switch>
                                                {authRoutes?.map((route, index) => {
                                                    const { path, component, meta, roles, hoc } =
                                                        route

                                                    const Element = hoc ? hoc(component) : component

                                                    return (
                                                        <GuardedRoute
                                                            path={path}
                                                            exact
                                                            strict
                                                            component={Element}
                                                            key={index}
                                                            meta={{
                                                                ...meta,
                                                                allowedUsers: roles,
                                                                dispatch,
                                                            }}
                                                            guards={meta.guards}
                                                        />
                                                    )
                                                })}
                                            </Switch>
                                        </GuardProvider>
                                    </div>
                                </div>
                                <ModalEl
                                    width={500}
                                    open={openModal}
                                    onClose={handleMouseOver}
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    className="calendar-appointment-modal">
                                    <Grid container style={{ position: 'relative' }}>
                                        <Grid container item>
                                            {' '}
                                            <Grid
                                                container
                                                item
                                                spacing={3}
                                                className="modal-spacer"
                                                style={{ marginBottom: 10, marginTop: 10 }}>
                                                <Grid container item spacing={2}>
                                                    <Grid item xs={12}>
                                                        <div
                                                            style={{
                                                                color: 'black',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                textAlign: 'center',
                                                            }}>
                                                            <span>You will automatically log out in:</span>
                                                            <br/>
                                                            <span style={{ color: 'red'}}> {minutes}:{formattedSeconds} </span>
                                                        </div>
                                                    </Grid>{' '}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </ModalEl>
                                {telehealthScreen.active && <EmptyNote />}
                            </>
                        </Grid>
                    </Box>
                </Box>
            </ChatProvider>
        )
    }
    onLogout()
}

export default AppLayout
