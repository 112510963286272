import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@mui/material/IconButton'
import React, { useEffect, useMemo, useState } from 'react'
import _, { isObject } from 'lodash'
import SearchIcon from '../../assests/icons/search_icon.svg'
import {
    getCPTCodeMedicalCoding,
    getHCPCSCodeMedicalCoding,
    getIDCCodeMedicalCoding,
    getTaxonomyCode,
} from '../../containers/ClinicalNotes/api/services'
import { Autocomplete, createFilterOptions } from '@mui/material'

const filterData = createFilterOptions()

const renderTextField = ({ name, value, placeholder, handleInputChange, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchTaxionomyCodeInput = (props) => {
    const { handleInputChange, r, index, type, handleAddRow, disabled, helperText, error } = props

    const [value, setValue] = React.useState(props.value)
    const [inputValue, setInputValue] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        props.value && setInputValue(props.value || '')
    }, [props.value])

    async function getCodeData(val) {
        try {
            setLoading(true)
            const payload = {}
            let response = null
            response = await getTaxonomyCode(val)
            const data = response.data.hits.hits.map((item) => ({
                ...item._source,
                highlightText: item.highlight?.clinicianDescriptor?.[0] || '',
                description: item._source?.display
                    ? item._source?.display
                    : item._source?.definition,
                code: item._source?.code,
            }))
            setOptions(data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        let active = true
        if (!open) return
        ;(async () => {
            if (!inputValue) {
                return
            }
            setLoading(true)

            if (active) {
                await getCodeData(inputValue)
            }

            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [open, value, inputValue])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                selectOnFocus
                clearIcon={false}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                inputValue={inputValue || ''}
                value={value}
                getOptionLabel={(option) => option?.code || ''}
                open={open}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: `<span class="hightlight">${option.code} - ${
                                        option.highlightText || option.description
                                    }</span>`,
                                }}
                            />
                        </li>
                    )
                }}
                filterOptions={(optionDatas, params) => {
                    const filtered = filterData(optionDatas, params)
                    filtered.push(...optionDatas)
                    return optionDatas
                }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    if (!event) {
                        return
                    }
                    setOptions(isObject(newValue) ? [newValue, ...options] : options)
                    setOpen(false)
                    handleAddRow(newValue)
                    setValue(newValue?.code)
                    setInputValue(newValue?.code || '')
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return
                    }
                    setInputValue(newInputValue || '')
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: 'Search...',
                        props: {
                            ...params,
                            helperText,
                            error,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                    </React.Fragment>
                                ),
                            },
                        },
                    })
                }
            />
        </Box>
    )
}

export default SearchTaxionomyCodeInput
