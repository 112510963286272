import React, { useState, useEffect } from 'react'
import { Grid, makeStyles, Typography, FormLabel, IconButton, Box, Popover, Button, Divider } from '@material-ui/core'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import SearchLocationInput from 'components/custom/SearchLocationInput'
import { useSelector } from 'react-redux'
import { Button as ButtonCustom } from 'components/custom'
import AddIcon from '@mui/icons-material/Add'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { getDepartmentList } from 'services/Calendar'
import TrashIcon from 'assests/icons/TrashIcon'
import { formatNumber } from 'shared/utils'
import { ROLES_PROVIDER } from '../constant'
import '../styles.scss'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { useNotification } from 'shared/elements/Notification'
import ProviderAccountInformation from 'containers/UserSecuritySettings/ProviderAccountInformation'
import { getListPractices } from 'services/Setting'
const useStyles = makeStyles(() => ({
    text: {
        color: '#303E4E',
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 25,
    },
    reset_pass: {
        color: '#5571C6',
        fontSize: 14,
        textAlign: 'end',
        cursor: 'pointer',
        marginTop: 4,
    },
    container: {
        width: "100%",
        // background: '#FBFCFC',
        border: '1px solid #CFCFCF',
        borderRadius: 10,
        padding: '22px 10px',
    },
    accountTextTab: {
        color: "#242424",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontWeight: 400,
        cursor: "pointer"
    }
}))

const renderLocationInput = (props) => {
    const { isViewOnly, formik } = props

    return props.locations.map((location, index) => {
        return (
            <Grid container item key={location.key}>
                <Grid item xs={10}>
                    <SearchLocationInput
                        value={location.id}
                        name={`location_id_${index}`}
                        handleAddRow={(value) => {
                            formik.setFieldValue(
                                'location_id',
                                props.locations.map((location, idx) => {
                                    if (idx === index)
                                        return {
                                            ...location,
                                            id: value?.id,
                                        }

                                    return location
                                }),
                            )
                        }}
                        disabled={isViewOnly}
                        error={
                            (formik.touched.location_id || [])[index]?.id &&
                            Boolean((formik.errors.location_id || [])[index]?.id)
                        }
                        helperText={
                            (formik.touched.location_id || [])[index]?.id &&
                            (formik.errors.location_id || [])[index]?.id
                        }
                    />
                </Grid>
                {(props.locations.length === 1 || index + 1 === props.locations.length) && (
                    <Grid item xs={1} style={{ marginTop: 5 }}>
                        <span
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                marginTop: '5px',
                                paddingLeft: '10px',
                            }}
                            onClick={() => {
                                formik.setFieldValue('location_id', [
                                    ...props.locations,
                                    {
                                        id: '',
                                        key: Date.now(),
                                    },
                                ])
                            }}>
                            <AddCircleOutlineOutlined
                                style={{
                                    color: '#14AE5C',
                                    fontSize: 18,
                                }}
                            />
                            <Typography
                                style={{
                                    color: '#14AE5C',
                                    marginLeft: 8,
                                }}>
                                Add New Location
                            </Typography>
                        </span>
                    </Grid>
                )}
                {props.locations.length > 1 && index + 1 !== props.locations.length && (
                    <Grid
                        item
                        xs={1}
                        style={{ marginTop: 10 }}
                        className="d-flex align-flex-start justify-center">
                        <IconButton
                            onClick={() => {
                                formik.setFieldValue(
                                    'location_id',
                                    props.locations.filter((_, idx) => idx !== index),
                                )
                            }}>
                            <TrashIcon />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
        )
    })
}

function Staff({ specialityOptions, values, touched, errors, formik, isViewOnly = false, profile = false }) {
    const pickList = [{ key: "provider", value: "Provider" }, { key: "clinical_staff", value: "Clinical Staff" }, { key: "non_clinical_staff", value: "Non-clinical Staff" }, { key: "front_desk_staff", value: "Front Desk Staff" },]
    const memberType = [{ value: "Physician", key: "M" }, { value: "Dentist", key: "D" }, { value: "Physician Assistant", key: "PA" }, { value: "Nurse Practitioner", key: "NP" }, { value: "Staff", key: "S" }]
    const classes = useStyles()
    const notification = useNotification()
    const [departmentsList, setDepartmentsList] = useState([])
    const [clinicalPracticeOptions, setClinicalPracticeOptions] = useState([])

    const permissions = useSelector((state) => state?.permission?.user_security_accounts)

    const securityRoles = useSelector((state) => state.permission.roles)
        .map((item) => {
            return {
                key: item.code,
                value: item.name,
            }
        })
        .sort((option1, option2) => {
            let a = option1.value
            let b = option2.value
            return a === b ? 0 : a > b ? 1 : -1
        })

    const getPracticesList = async () => {
        const response = await getListPractices({
            size: 10000,
        })
        setClinicalPracticeOptions(
            response?.data?.data.map((item) => ({
                key: item.id,
                value: item.name,
            })) || [],
        )
    }

    useEffect(() => {
        getPracticesList(),
        getDepartmentList({
            page: 0,
            limit: 100000,
            name: '',
        }).then((res) => setDepartmentsList(res.data?.data || []))
    }, [])

    return (
        <Grid container item className={classes.container} spacing={2} xs={12}>
            {/* {isViewOnly && <Typography className={classes.text}>User Profile</Typography>} */}
            {/* {!isViewOnly && (
                    <Typography className={classes.text}>
                        {values.id ? 'Update Account' : 'Add New Account'}
                    </Typography>
                )} */}

            <Grid item xs={1}>
                <Grid
                    item
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Divider
                        variant="middle"
                        // flexItem
                        orientation="vertical"
                        className="divider_AccountTab"
                    />
                    <Typography className={classes.accountTextTab}>Account</Typography>
                </Grid>
                <Grid
                    item
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '20px',
                        opacity: !values?.clinician ? 0.4 : '',
                    }}>
                    {values?.clinician && (
                        <Divider
                            variant="middle"
                            // flexItem
                            orientation="vertical"
                            className="divider_AccountTab"
                        />
                    )}
                    <Typography
                        className={classes.accountTextTab}
                        style={{ marginLeft: !values?.clinician ? '10px' : '' }}>
                        Clinician
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={11}>
                {/*--------------------------------------User Information----------------------- -----------------*/}
                <fieldset className="divider">
                    <legend>
                        <Typography className={'heading1-title'}>Account </Typography>
                    </legend>
                </fieldset>
                <Grid item xs={12}>
                    <fieldset className="divider-information">
                        <legend>
                            <Typography className={'heading1-title'}>User Information</Typography>
                        </legend>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid item xs={4}>
                                <TextBox
                                    label="First Name"
                                    required
                                    name="first_name"
                                    value={values.first_name}
                                    placeholder="Enter your first name"
                                    error={touched.first_name && Boolean(errors.first_name)}
                                    helperText={touched.first_name && errors.first_name}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'first_name',
                                            value.replace(/[^a-zA-Z ]+/g, ''),
                                        )
                                    }
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    label="Last Name"
                                    required
                                    name="last_name"
                                    value={values.last_name}
                                    placeholder="Enter your last name"
                                    error={touched.last_name && Boolean(errors.last_name)}
                                    helperText={touched.last_name && errors.last_name}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'last_name',
                                            value.replace(/[^a-zA-Z ]+/g, ''),
                                        )
                                    }
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    label="Phone Number"
                                    required
                                    name="phone_number"
                                    // value={values.phone_number.replace('+1', '')}
                                    value={formatNumber(values.phone_number)}
                                    placeholder="Enter your phone number"
                                    error={touched.phone_number && Boolean(errors.phone_number)}
                                    helperText={touched.phone_number && errors.phone_number}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'phone_number',
                                            value.replace(/[^0-9]+/g, '').substring(0, 10),
                                        )
                                    }
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    label="Email"
                                    required
                                    name="email"
                                    value={values.email}
                                    placeholder="Enter your email"
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={touched.email && errors.email}
                                    onChange={(value) => formik.setFieldValue('email', value)}
                                    disabled={isViewOnly || Boolean(values?.id)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    resetData
                                    label="Department"
                                    // required
                                    name="department_id"
                                    placeholder="Select Department"
                                    value={values.department_id}
                                    // error={
                                    //     touched.department_id &&
                                    //     Boolean(errors.department_id)
                                    // }
                                    // helperText={
                                    //     touched.department_id &&
                                    //     errors.department_id
                                    // }
                                    options={departmentsList
                                        .map((item) => ({
                                            key: item.id,
                                            value: item.name,
                                        }))
                                        .sort((option1, option2) => {
                                            let a = option1.value
                                            let b = option2.value
                                            return a === b ? 0 : a > b ? 1 : -1
                                        })}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    onChange={(value) =>
                                        formik.setFieldValue('department_id', value)
                                    }
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    resetData
                                    label="Security Role"
                                    required
                                    name="role"
                                    placeholder="Select Role"
                                    value={values.role}
                                    error={touched.role && Boolean(errors.role)}
                                    helperText={touched.role && errors.role}
                                    options={securityRoles}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    onChange={(value) => {
                                        // formik.setFieldValue('provider_account', ROLES_PROVIDER.includes(value))
                                        formik.setFieldValue('role', value)
                                    }}
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxOption
                                    option={{
                                        label: 'Clinician',
                                        value: true,
                                    }}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            formik.setValues({
                                                ...values,
                                                clinician: false,
                                                provider_account: false,
                                                npi: '',
                                                suffix: '',
                                                kareoId: '',
                                                timezone: '',
                                                avatar: '',
                                                supervising_physician: '',
                                                taxonomy_code: [
                                                    { id: '', label: '', isPrimary: '' },
                                                ],
                                                states: [{ state: '', type: '', number: '' }],
                                                dea_number: [''],
                                                rcopia_user_role: '',
                                                is_enable_eprescribe: false,
                                                rcopia_member_type: '',
                                                specialities: '',
                                                rcopia_user_name: ''
                                            })
                                        } else {
                                            formik.setFieldValue('clinician', e.target.checked)
                                        }
                                    }}
                                    checked={values?.clinician}
                                    disabled={isViewOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <CheckboxOption
                                    option={{
                                        label: 'Multi-factor authentication',
                                        value: true,
                                    }}
                                    onChange={(e) => {
                                        formik.setFieldValue('mfa', e.target.checked)
                                    }}
                                    checked={values?.mfa}
                                    disabled={isViewOnly}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                {/*--------------------------------------Location----------------------- -----------------*/}
                <Grid item xs={12}>
                    <fieldset className="divider-information">
                        <legend>
                            <Typography className={'heading1-title'}>Location</Typography>
                        </legend>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid container item alignItems="flex-end">
                                <FormLabel
                                    component="p"
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        marginBottom: 0,
                                    }}>
                                    Location
                                    <span
                                        style={{
                                            color: 'red',
                                        }}>
                                        *
                                    </span>
                                </FormLabel>
                            </Grid>
                            {renderLocationInput({
                                locations: values.location_id,
                                isRequired: true,
                                isViewOnly,
                                formik,
                            })}
                        </Grid>
                    </fieldset>
                </Grid>
                {/*--------------------------------------Clinical practice----------------------- -----------------*/}
                <Grid item xs={12}>
                    <fieldset className="divider-information">
                        <legend>
                            <Typography className={'heading1-title'}>Clinical Practice</Typography>
                        </legend>
                        <Grid item style={{ padding: '0px 10px 10px 10px' }}>
                            {values?.provider_clinical_practices?.length > 0 &&
                                values?.provider_clinical_practices.map((item, index) => {
                                    return (
                                        <Grid
                                            key={index}
                                            container
                                            item
                                            alignItems="flex-start"
                                            spacing={2}
                                            style={{ marginTop: index === 0 ? '10px' : '20px' }}
                                            className="modifier_top_state">
                                            <Grid item xs={4}>
                                                <Select
                                                    resetData
                                                    label="Clinical Practice"
                                                    required
                                                    name="clinical_practice"
                                                    placeholder="Select clinical practice"
                                                    value={item.clinical_practice_id}
                                                    error={
                                                        (touched.provider_clinical_practices || [])[index]
                                                            ?.clinical_practice_id &&
                                                        Boolean(
                                                            (errors.provider_clinical_practices || [])[
                                                                index
                                                            ]?.clinical_practice_id,
                                                        )
                                                    }
                                                    helperText={
                                                        (touched.provider_clinical_practices || [])[index]
                                                            ?.clinical_practice_id &&
                                                        (errors.provider_clinical_practices || [])[index]
                                                            ?.clinical_practice_id
                                                    }
                                                    options={clinicalPracticeOptions}
                                                    selectProps={{
                                                        style: {
                                                            height: 40,
                                                        },
                                                    }}
                                                    onChange={(value) => {
                                                        const updatedClinicalPractice =
                                                            values?.provider_clinical_practices.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    clinical_practice_id:
                                                                        idx === index
                                                                            ? value
                                                                            : item.clinical_practice_id,
                                                                }),
                                                            )
                                                            formik.setFieldValue(
                                                            'provider_clinical_practices',
                                                            updatedClinicalPractice,
                                                        )
                                                    }}
                                                    disabled={isViewOnly}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextBox
                                                    placeholder="Enter Kareo ID"
                                                    label="Kareo ID"
                                                    required
                                                    name="kareoId"
                                                    value={item.kareo_id}
                                                    error={
                                                        (touched.provider_clinical_practices || [])[index]?.kareo_id &&
                                                        Boolean(
                                                            (errors.provider_clinical_practices || [])[index]?.kareo_id,
                                                        )
                                                    }
                                                    helperText={
                                                        (touched.provider_clinical_practices || [])[index]?.kareo_id &&
                                                        (errors.provider_clinical_practices || [])[index]?.kareo_id
                                                    }
                                                    disabled={isViewOnly}
                                                    onChange={(value) => {
                                                        const updatedKareoId =
                                                            values?.provider_clinical_practices.map(
                                                                (item, idx) => ({
                                                                    ...item,
                                                                    kareo_id:
                                                                        idx === index
                                                                            ? value
                                                                            : item.kareo_id,
                                                                }),
                                                            )
                                                            formik.setFieldValue(
                                                            'provider_clinical_practices',
                                                            updatedKareoId,
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={1}
                                                style={{ marginTop: '32px' }}
                                                className="d-flex align-flex-start justify-end">
                                                <Grid item style={{ paddingLeft: '10px' }}>
                                                    <Divider
                                                        variant="middle"
                                                        flexItem
                                                        orientation="vertical"
                                                        className="divider_selectBoxTaxonomy"
                                                    />
                                                </Grid>
                                                <IconButton
                                                    style={{ paddingLeft: '10px', marginTop: 8 }}
                                                    onClick={() => {
                                                        if(index === 0){
                                                            formik.setFieldValue(
                                                                'provider_clinical_practices',
                                                                values.provider_clinical_practices.map(
                                                                    (item, idx) => {
                                                                        if (idx === 0) {
                                                                            return {
                                                                                clinical_practice_id:
                                                                                    '',
                                                                                kareo_id: '',
                                                                            }
                                                                        } else return item
                                                                    },
                                                                ),
                                                            )
                                                        }else{
                                                            formik.setFieldValue(
                                                                        'provider_clinical_practices',
                                                                        values.provider_clinical_practices.filter(
                                                                            (_, idx) => idx !== index,
                                                                        ),
                                                                    )
                                                        }
                                                    }}
                                                    disabled={isViewOnly}>
                                                    <TrashIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                whiteSpace: 'nowrap',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                if (!isViewOnly) {
                                    formik.setFieldValue('provider_clinical_practices', [
                                        ...values.provider_clinical_practices,
                                        { clinical_practice_id: '', kareo_id: '' },
                                    ])
                                }
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                }}>
                                <AddCircleOutlineOutlined
                                    style={{
                                        color: '#14AE5C',
                                        fontSize: 18,
                                    }}
                                />
                                <Typography
                                    style={{
                                        color: '#14AE5C',
                                        marginLeft: 8,
                                    }}>
                                    Add New Clinical Practice
                                </Typography>
                            </div>
                        </div>
                    </fieldset>
                </Grid>
                {/*--------------------------------------Clinician----------------------- -----------------*/}
                <Grid item xs={12} style={{ opacity: !values?.clinician ? 0.4 : '' }}>
                    <fieldset className="divider">
                        <legend>
                            <Typography className={'heading1-title'}>Clinician </Typography>
                        </legend>
                    </fieldset>
                    <Grid item xs={12} style={{ paddingLeft: '5px' }}>
                        <CheckboxOption
                            option={{
                                label: 'Enable ePrescribing',
                                value: true,
                            }}
                            onChange={(e) => {
                                formik.setFieldValue('is_enable_eprescribe', e.target.checked)
                                if (!e.target.checked) {
                                    formik.setFieldValue('rcopia_user_role', '')
                                    formik.setFieldValue('rcopia_member_type', '')
                                    formik.setFieldValue('rcopia_user_name', '')
                                }
                            }}
                            checked={values?.is_enable_eprescribe}
                            disabled={isViewOnly || !values?.clinician}
                        />
                    </Grid>
                </Grid>
                {/*--------------------------------------ePrescribe----------------------- -----------------*/}
                <Grid
                    item
                    xs={12}
                    style={{
                        opacity: !values?.is_enable_eprescribe || !values?.clinician ? 0.4 : '',
                    }}>
                    <fieldset className="divider-information">
                        <legend>
                            <Typography className={'heading1-title'}>ePrescribing</Typography>
                        </legend>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid item xs={4}>
                                <Select
                                    resetData
                                    label="Member Type"
                                    required
                                    name="rcopia_member_type"
                                    placeholder="Select Member Type"
                                    value={values.rcopia_member_type}
                                    options={memberType}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    onChange={(value) => {
                                        formik.setFieldValue('rcopia_member_type', value)
                                        if (!value) {
                                            formik.setFieldValue('rcopia_user_role', '')
                                            formik.setFieldValue('rcopia_user_name', '')
                                        }
                                    }}
                                    disabled={
                                        isViewOnly ||
                                        !values.is_enable_eprescribe ||
                                        !values?.clinician
                                    }
                                    error={
                                        touched.rcopia_member_type &&
                                        Boolean(errors.rcopia_member_type)
                                    }
                                    helperText={
                                        touched.rcopia_member_type && errors.rcopia_member_type
                                    }
                                />
                            </Grid>
                            {/* <Grid item xs={4}>
                                <Select
                                    resetData
                                    label="Role"
                                    required
                                    name="rcopia_member_type"
                                    placeholder="Select Member Type"
                                    value={values.rcopia_member_type}
                                    options={memberType}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    onChange={(value) => {
                                        formik.setFieldValue('rcopia_member_type', value)
                                        if(!value){
                                            formik.setFieldValue('rcopia_user_role', '')
                                            formik.setFieldValue('rcopia_user_name', '')
                                        }
                                    }}
                                    disabled={
                                        isViewOnly ||
                                        !values.is_enable_eprescribe ||
                                        !values.rcopia_member_type ||
                                        !values?.clinician
                                    }
                                    error={
                                        touched.rcopia_member_type &&
                                        Boolean(errors.rcopia_member_type)
                                    }
                                    helperText={
                                        touched.rcopia_member_type && errors.rcopia_member_type
                                    }
                                />
                            </Grid> */}
                            <Grid item xs={4}>
                                <Select
                                    resetData
                                    label="Role"
                                    required
                                    name="rcopia_user_role"
                                    placeholder="Select Role"
                                    value={values.rcopia_user_role}
                                    options={pickList}
                                    selectProps={{
                                        style: {
                                            height: 40,
                                        },
                                    }}
                                    onChange={(value) => {
                                        formik.setFieldValue('rcopia_user_role', value)
                                    }}
                                    disabled={
                                        isViewOnly ||
                                        !values.rcopia_member_type ||
                                        !values.is_enable_eprescribe ||
                                        !values?.clinician
                                    }
                                    error={
                                        touched.rcopia_user_role && Boolean(errors.rcopia_user_role)
                                    }
                                    helperText={touched.rcopia_user_role && errors.rcopia_user_role}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextBox
                                    placeholder="RCOPIA ID"
                                    label="RCOPIA ID"
                                    name="rcopia_user_name"
                                    value={values.rcopia_user_name}
                                    onChange={(value) => {
                                        formik.setFieldValue('rcopia_user_name', value)
                                    }}
                                    disabled={
                                        isViewOnly ||
                                        !values.is_enable_eprescribe ||
                                        !values?.clinician
                                    }
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                {/*--------------------------------------Provider Account----------------------- -----------------*/}
                <Grid
                    item
                    xs={12}
                    style={{ paddingLeft: '11px', opacity: !values?.clinician ? 0.4 : '' }}>
                    <CheckboxOption
                        option={{
                            label: 'Provider Account',
                            value: values.provider_account,
                        }}
                        onChange={(e) => {
                            if (!e.target.checked) {
                                formik.setValues({
                                    ...values,
                                    provider_account: false,
                                    npi: '',
                                    suffix: '',
                                    kareoId: '',
                                    timezone: '',
                                    avatar: '',
                                    supervising_physician: '',
                                    taxonomy_code: [{ id: '', label: '', isPrimary: '' }],
                                    states: [{ state: '', type: '', number: '' }],
                                    specialities: '',
                                    dea_number: [""]
                                })
                            } else {
                                formik.setFieldValue('provider_account', e.target.checked)
                            }
                        }}
                        checked={values?.provider_account}
                        disabled={isViewOnly || !values?.clinician}
                    />
                </Grid>
                <ProviderAccountInformation
                    specialityOptions={specialityOptions}
                    values={formik.values}
                    touched={formik.touched}
                    errors={formik.errors}
                    setFieldValue={formik.setFieldValue}
                />
            </Grid>
            {/*--------------------------------------ePrescribe----------------------- -----------------*/}
            {/* <Grid item xs={12}>
                <TextBox
                    label="Email"
                    required
                    name="email"
                    value={values.email}
                    placeholder="Enter your email"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    onChange={(value) => formik.setFieldValue('email', value)}
                    disabled={isViewOnly || Boolean(values.id)}
                />
            </Grid>

            <Grid item xs={12}>
                <TextBox
                    label="Mobile Number"
                    required
                    name="phone_number"
                    // value={values.phone_number.replace('+1', '')}
                    value={formatNumber(values.phone_number)}
                    placeholder="Enter your phone number"
                    error={touched.phone_number && Boolean(errors.phone_number)}
                    helperText={touched.phone_number && errors.phone_number}
                    onChange={(value) =>
                        formik.setFieldValue(
                            'phone_number',
                            value.replace(/[^0-9]+/g, '').substring(0, 10),
                        )
                    }
                    disabled={isViewOnly}
                />
            </Grid>

            <Grid container item alignItems="flex-end">
                <Grid item xs={12}>
                    <Select
                        resetData
                        label="Security Role"
                        required
                        name="role"
                        placeholder="Select Role"
                        value={values.role}
                        error={touched.role && Boolean(errors.role)}
                        helperText={touched.role && errors.role}
                        options={securityRoles}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(value) => {
                            // formik.setFieldValue('provider_account', ROLES_PROVIDER.includes(value))
                            formik.setFieldValue('role', value)
                        }}
                        disabled={isViewOnly}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="flex-end">
                <FormLabel
                    component="p"
                    style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        marginBottom: 0,
                    }}>
                    Location
                    {!!values.provider_account && (
                        <span
                            style={{
                                color: 'red',
                            }}>
                            *
                        </span>
                    )}
                </FormLabel>
            </Grid>
            {renderLocationInput({
                locations: values.location_id,
                isRequired: true,
                isViewOnly,
                formik,
            })}
            <Grid container item alignItems="flex-end">
                <CheckboxOption
                    option={{
                        label: 'Enable ePrescribing',
                        value: true,
                    }}
                    onChange={(e) => {
                        formik.setFieldValue('is_enable_eprescribe', e.target.checked)
                        if (!e.target.checked) {
                            formik.setFieldValue('rcopia_user_role', '')
                        }
                    }}
                    checked={values?.is_enable_eprescribe}
                    disabled={isViewOnly}
                />
                {values?.is_enable_eprescribe ? (
                    <Grid item xs={6} style={{ marginLeft: '54px' }}>
                        <Select
                            resetData
                            name="rcopia_user_role"
                            placeholder="Pick List"
                            value={values.rcopia_user_role}
                            options={pickList}
                            selectProps={{
                                style: {
                                    height: 40,
                                },
                            }}
                            onChange={(value) => {
                                formik.setFieldValue('rcopia_user_role', value)
                            }}
                            disabled={isViewOnly}
                        />
                    </Grid>
                ) : (
                    ''
                )}
            </Grid>
            <Grid item xs={12}>
                <TextBox
                    placeholder="Enter RCOPIA ID"
                    label="RCOPIA ID"
                    required
                    name="rcopia_user_name"
                    value={values.rcopia_user_name}
                    error={touched.rcopia_user_name && Boolean(errors.rcopia_user_name)}
                    helperText={touched.rcopia_user_name && errors.rcopia_user_name}
                    disabled={isViewOnly}
                    onChange={(value) => {
                        if ((value && /^[a-zA-Z0-9]+$/gi.test(value)) || !value) {
                            formik.setFieldValue('rcopia_user_name', value)
                        }
                    }}
                />
            </Grid>
            <Grid container item alignItems="flex-end">
                <Grid item xs={12}>
                    <Select
                        resetData
                        label="Department"
                        // required
                        name="department_id"
                        placeholder="Select Department"
                        value={values.department_id}
                        // error={
                        //     touched.department_id &&
                        //     Boolean(errors.department_id)
                        // }
                        // helperText={
                        //     touched.department_id &&
                        //     errors.department_id
                        // }
                        options={departmentsList
                            .map((item) => ({
                                key: item.id,
                                value: item.name,
                            }))
                            .sort((option1, option2) => {
                                let a = option1.value
                                let b = option2.value
                                return a === b ? 0 : a > b ? 1 : -1
                            })}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(value) => formik.setFieldValue('department_id', value)}
                        disabled={isViewOnly}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                item
                // justifyContent="space-between"
                direction="row"
                style={{ marginTop: 16 }}>
                <Grid item className="modal-spacer">
                    <CheckboxOption
                        // name={`${fieldName}.no_concern`}
                        option={{
                            label: 'MFA',
                            value: true,
                        }}
                        onChange={(e) => {
                            formik.setFieldValue('mfa', e.target.checked)
                        }}
                        checked={values?.mfa}
                        disabled={isViewOnly}
                    />
                </Grid>

                {(permissions.includes('create_provider_security_accounts') || profile) && (
                    <Grid item className="modal-spacer">
                        <CheckboxOption
                            // name={`${fieldName}.no_concern`}
                            option={{
                                label: 'Provider Account',
                                value: true,
                            }}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    'provider_account',
                                    e.target.checked ? true : false,
                                )
                            }}
                            checked={values?.provider_account}
                            // disabled={isViewOnly || ROLES_PROVIDER.includes(values.role)}
                            disabled={isViewOnly}
                        />
                    </Grid>
                )}
            </Grid> */}
        </Grid>
    )
}

export default Staff
