import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import ProviderLogin from '../../components/Login/ProviderLogin'
import * as actions from '../../store/actions/login'
import * as loaderActions from '../../store/actions/loader'
import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

function StaffLoginProvider(props) {
    const history = useHistory()

    const {
        staffLogin,
        error,
        isOTPRequired,
        verifyOTP,
        isLoading,
        forceToChangePassword,
        mfa,
    } = props

    if (forceToChangePassword) {
        history.push('/change-password')
    }

    if (mfa) {
        history.push('/mfa-signin')
    }

    return (
        <div>
            {/* <Header /> */}
            <ProviderLogin
                onSubmit={
                    !isOTPRequired ? staffLogin : verifyOTP
                }
                error={error}
                isOTPRequired={isOTPRequired}
                isLoading={isLoading}
            />
            {/* <Footer /> */}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loader.loading,
        error: state.loader.error,
        patientData: state.login.userData,
        isOTPRequired: state.login.isOTPRequired,
        forceToChangePassword:
            state.login.forceToChangePassword,
        mfa: state.login.mfa,
    }
}

const mapDispatchToProp = (dispatch) => {
    return {
        setError: (val) =>
            dispatch(loaderActions.setError(val)),
        staffLogin: (email, password) =>
            dispatch(actions.staffLogin(email, password)),
        verifyOTP: (otp) =>
            dispatch(actions.verifyOTP(otp)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProp,
)(StaffLoginProvider)
