import React from 'react'
import './Footer.scss'
import { Grid, Container } from '@material-ui/core'
import appstoreimage from '../../assests/images/appstore.png'
import googleplay from '../../assests/images/googleplay.png'
import { Link } from 'react-router-dom'
import backIcon from '../../assests/images/icon_back.png'
const Footer = () => {
    return (
        <Container sm={12} className="footer-wrapper">
            <Grid container spacing={10}>
                <Grid item xs={4} md={4} lg>
                    <h3>TELEMED2U</h3>
                    <ul className="footer-links">
                        <li>
                            Phone:{' '}
                            <a href="tel://(855)446-8628">
                                (855) 446-8628
                            </a>
                        </li>
                        <li>
                            3400 Douglas Blvd., Suite 225
                            Roseville, CA 95661
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={4} md={4} lg>
                    <h3>About Us</h3>
                    <ul className="footer-links">
                        <li>
                            <a href="#">What we Treat</a>
                        </li>
                        <li>
                            <a href="#">Meet Our Doctors</a>
                        </li>
                        <li>
                            <a href="#">Our Team</a>
                        </li>
                        <li>
                            <a href="#">Careers</a>
                        </li>
                        <li>
                            <a href="#">Blog & News</a>
                        </li>
                        <li>
                            <a href="#">Contact Us</a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={4} md={4} lg>
                    <h3> Providers</h3>
                    <ul className="footer-links">
                        <li>
                            <Link to="/login/staff">
                                Provider Login
                            </Link>
                        </li>
                        <li>
                            <a href="#">Join Our Team</a>
                        </li>
                        <li>
                            <a href="#">
                                eConsult Platform Login
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={4} md={4} lg>
                    <h3> Health Systems</h3>
                    <ul className="footer-links">
                        <li>
                            <a href="#">
                                eConsult Platform
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Correctional Medicine
                            </a>
                        </li>
                        <li>
                            <a href="#">Health Plans</a>
                        </li>
                        <li>
                            <a href="#">
                                Inpatient Medicine
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Antimicrobial Stewardship
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                Outpatient Medicine
                            </a>
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={4} md={4} lg>
                    <h3>Support</h3>
                    <ul className="footer-links">
                        <li>
                            <a href="#">FAQs</a>
                        </li>
                        <li>
                            <button
                                className="blue-btn"
                                type="button">
                                Contact Us
                            </button>
                        </li>
                        <li>
                            <a
                                href="https://apps.apple.com/us/app/telemed2u/id1508420924"
                                target="_blank"
                                className="app-btn">
                                <img
                                    src={appstoreimage}
                                    loading="lazy"
                                    alt="App store"
                                    className="app-btn"
                                />
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.telemed2u.app"
                                target="_blank"
                                className="app-btn">
                                <img
                                    src={googleplay}
                                    loading="lazy"
                                    alt="Play store icon"
                                    className="app-btn"
                                />
                            </a>
                        </li>
                    </ul>
                </Grid>
            </Grid>

            <div className="footer-meta">
                <div className="footer-meta-text">
                    © 2021 TeleMed2U. All Rights Reserved
                </div>
                <ul>
                    <li>
                        <a
                            href="/privacy-policy"
                            className="footer-meta-link">
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="/prescription-policy"
                            className="footer-meta-link">
                            Prescription Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="/sitemap"
                            className="footer-meta-link">
                            Site Map
                        </a>
                    </li>
                </ul>
            </div>
        </Container>
    )
}

export default Footer
