import moment from 'moment'

export function sortAscending(data, comparator) {
    return data.slice().sort((a, b) => {
        if (a[comparator] < b[comparator]) {
            return -1
        }
        if (a[comparator] > b[comparator]) {
            return 1
        }
        return 0
    })
}

export function sortDescending(data, comparator) {
    return data.slice().sort((a, b) => {
        if (a[comparator] < b[comparator]) {
            return 1
        }
        if (a[comparator] > b[comparator]) {
            return -1
        }
        return 0
    })
}

export function sortAscendingBh(data, comparator) {
    return data.slice().sort((a, b) => {
        let prevPatientData = ''
        let postPatientData = ''
        if (comparator === 'patient') {
            prevPatientData = a['patient']['first_name']
            postPatientData = b['patient']['first_name']
        } else if (comparator === 'properties') {
            prevPatientData = a['properties']['appointment']['speciality']['name']
            postPatientData = b['properties']['appointment']['speciality']['name']
        } else if (comparator === 'createdAt') {
            prevPatientData = a['properties']['appointment']['date']
            postPatientData = b['properties']['appointment']['date']
        } else if (comparator === 'provider') {
            prevPatientData = a['provider']['first_name']
            postPatientData = b['provider']['first_name']
        } else {
            prevPatientData = a[comparator]
            postPatientData = b[comparator]
        }
        if (prevPatientData < postPatientData) {
            return -1
        }
        if (prevPatientData > postPatientData) {
            return 1
        }
        return 0
    })
}

export function sortDescendingBh(data, comparator) {
    return data.slice().sort((a, b) => {
        let prevPatientData = ''
        let postPatientData = ''
        if (comparator === 'patient') {
            prevPatientData = a['patient']['first_name']
            postPatientData = b['patient']['first_name']
        } else if (comparator === 'properties') {
            prevPatientData = a['properties']['appointment']['speciality']['name']
            postPatientData = b['properties']['appointment']['speciality']['name']
        } else if (comparator === 'createdAt') {
            prevPatientData = a['properties']['appointment']['date']
            postPatientData = b['properties']['appointment']['date']
        } else if (comparator === 'provider') {
            prevPatientData = a['provider']['first_name']
            postPatientData = b['provider']['first_name']
        } else {
            prevPatientData = a[comparator]
            postPatientData = b[comparator]
        }
        if (prevPatientData < postPatientData) {
            return 1
        }
        if (prevPatientData > postPatientData) {
            return -1
        }
        return 0
    })
}

export function getTimeframe(timeframe, interval) {
    if (timeframe === 'today') {
        return {
            fromDate: moment().format('MM-DD-YYYY'),
            toDate: moment().format('MM-DD-YYYY'),
        }
    } else if (timeframe === 'weekly') {
        if (interval === 'past') {
            return {
                fromDate: moment().subtract(1, 'week').format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        }
        return {
            fromDate: moment().format('MM-DD-YYYY'),
            toDate: moment().add(1, 'week').format('MM-DD-YYYY'),
        }
    } else if (timeframe === 'monthly') {
        if (interval === 'past') {
            return {
                fromDate: moment().subtract(1, 'month').format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        }
        return {
            fromDate: moment().format('MM-DD-YYYY'),
            toDate: moment().add(1, 'month').format('MM-DD-YYYY'),
        }
    } else if (timeframe === 'yearly') {
        if (interval === 'past') {
            return {
                fromDate: moment().subtract(1, 'year').format('MM-DD-YYYY'),
                toDate: moment().format('MM-DD-YYYY'),
            }
        }
        return {
            fromDate: moment().format('MM-DD-YYYY'),
            toDate: moment().add(1, 'year').format('MM-DD-YYYY'),
        }
    }
}

export const phoneFormat = (str) => {
    //Filter only numbers from the input
    const cleaned = `${str}`.replace(/\D/g, '')

    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`
    }

    return null
}

export function capitalizeFirstLetter(string = '') {
    return string
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ')
}

export const formatNumber = (value = '') => {
    let clearNumber = value.replace(/[^0-9]+/g, '')

    const input = clearNumber.replace(/\D/g, '').substring(0, 10) // First ten digits of input only
    const zip = input.substring(0, 3)
    const middle = input.substring(3, 6)
    const last = input.substring(6, 10)

    if (input.length > 6) {
        clearNumber = `(${zip}) ${middle}-${last}`
    } else if (input.length > 3) {
        clearNumber = `(${zip}) ${middle}`
    } else if (input.length > 0) {
        clearNumber = `(${zip}`
    }
    return clearNumber
}

export const sortMenuBar = (menulist) => {
    const sortMenulist = []
    menulist.forEach((item) => {
        let parent = (!item.parent || item.hasChild) && item
        if (parent) {
            if (parent.hasChild) {
                const childrens = []
                menulist.forEach((itemChild) => {
                    const child = itemChild.parent === parent.name && itemChild
                    if (child) childrens.push(child)
                })
                if (childrens.length) {
                    parent = { ...parent, listChild: childrens }
                }
            }
            sortMenulist.push(parent)
        }
    })
    return sortMenulist
}

export const convertIndexToChar = (number) => {
    if (number === 0) return ''
    return String.fromCharCode(number + 64)
}

export const convertCharToIndex = (char) => {
    return char.charCodeAt(0) - 64
}

export function stringConcatenation() {
    const arrText = []
    for (let i = 0; i < arguments.length; i++) {
        if (arguments[i]) {
            arrText.push(arguments[i])
        }
    }
    const text = arrText.join(', ')
    return text
}

export function convertFullName() {
    const arrText = []
    for (let i = 0; i < arguments.length; i++) {
        if (arguments[i]) {
            arrText.push(arguments[i])
        }
    }
    const text = arrText.join(' ')
    return text
}

export function convertHexToRGBA(hex, a) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
    return `rgba(${r},${g},${b},${a})`
}
export const formatDOB = (value = '') => {
    let clearDate = value.replace(/[^0-9]+/g, '')

    const input = clearDate.replace(/\D/g, '').substring(0, 8)
    const Month = input.substring(0, 2)
    const Date = input.substring(2, 4)
    const Year = input.substring(4, 8)

    if (input.length > 4) {
        clearDate = `${Month}/${Date}/${Year}`
    } else if (input.length > 2) {
        clearDate = `${Month}/${Date}`
    } else if (input.length > 0) {
        clearDate = `${Month}`
    }
    return clearDate
}
export const convertToFixedDate = (date) => {
    let utcNow = moment().utc()
    utcNow.set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: 12,
        minute: 0,
        second: 0,
        millisecond: 0,
    })
    return utcNow
}
export const startDateFilterToUTC = (date) => {
    const momentDate = moment(date)
    let startDate = moment().utc()
    startDate.set({
        year: momentDate.year(),
        month: momentDate.month(),
        date: momentDate.date(),
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
    })
    return startDate.subtract(moment.parseZone(moment()).utcOffset(), 'minute')
}
export const endDateFilterToUTC = (date) => {
    const momentDate = moment(date)
    let endDate = moment().utc()
    endDate.set({
        year: momentDate.year(),
        month: momentDate.month(),
        date: momentDate.date(),
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
    })
    return endDate.subtract(moment.parseZone(moment()).utcOffset(), 'minute')
}

export const dateFormat = (date) =>{
    const parts = date?.split(/[T-]/);
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    let temp = moment()
    temp.set('year', year);
    temp.set('month', month-1); 
    temp.set('date',  day);
    temp.set('hour', 0);
    temp.set('minute', 0);
    temp.set('second', 0);
    temp.set('millisecond', 0);
    return temp
}

export const alphabetArray = [
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
]