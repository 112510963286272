import React from 'react'
import { useNotification } from 'shared/elements/Notification'
import { apiStatusCodes, getLocations } from 'services/Calendar'
import { getProvidingProviders, getSupervisingProviders } from 'services/WorkQueue'
import { getFullName } from 'utilities/formats'

export const useSuperbillData = () => {
    const notification = useNotification()
    const [loading, setLoading] = React.useState(false)
    const [statusCodes, setStatusCodes] = React.useState([])
    const [serviceLocations, setServiceLocation] = React.useState([])
    const [providingProviders, setProvidingProviders] = React.useState([])
    const [supervisingProviders, setSupervisingProviders] = React.useState([])
    const [serviceLocationsNew, setServiceLocationNew] = React.useState([])
    const [providingProvidersNew, setProvidingProvidersNew] = React.useState([])
    const [supervisingProvidersNew, setSupervisingProvidersNew] = React.useState([])
    const getServiceLocations = () =>
        getLocations({ size: 10000 })
            .then((resp) => {
                setServiceLocation(resp.data.data)
                const location = resp.data.data?.map((location) => {
                    return {
                        key: location.id,
                        value: location.name,
                    }
                })
                setServiceLocationNew(location)
            })
            .catch((err) =>
                notification(
                    err.data?.message || 'Something went wrong when fetching Service Location',
                    'error',
                ),
            )

    const getProviders = () =>
        getProvidingProviders()
            .then((resp) => {
                setProvidingProviders(resp.data)
                const providers = resp.data
                    ?.map((provider) => {
                        return {
                            key: provider.id,
                            value: getFullName(provider.first_name, provider.last_name),
                        }
                    })
                    .sort((option1, option2) => {
                        let a = option1.value
                        let b = option2.value
                        return a === b ? 0 : a > b ? 1 : -1
                    })
                setProvidingProvidersNew(providers)
            })
            .catch((err) =>
                notification(
                    err.data?.message || 'Something went wrong when fetching Referring Provider',
                    'error',
                ),
            )

    const getListSupervising = () =>
        getSupervisingProviders()
            .then((resp) => {
                setSupervisingProviders(resp.data)
                const supervising = resp.data
                    ?.map((provider) => {
                        return {
                            key: provider.id,
                            value: getFullName(provider.first_name, provider.last_name),
                        }
                    })
                    .sort((option1, option2) => {
                        let a = option1.value
                        let b = option2.value
                        return a === b ? 0 : a > b ? 1 : -1
                    })
                setSupervisingProvidersNew(supervising)
            })
            .catch((err) =>
                notification(
                    err.data?.message || 'Something went wrong when fetching Supervising Provider',
                    'error',
                ),
            )

    const getStatusCodes = () =>
        apiStatusCodes
            .getStatusCodes({
                category: 'SUPERBILL',
            })
            .then((resp) => setStatusCodes(resp.data?.listStatusCode?.data))
            .catch((err) =>
                notification(
                    err.data?.message || 'Something went wrong when fetching Status Code',
                    'error',
                ),
            )

    React.useEffect(() => {
        setLoading(true)

        Promise.allSettled([
            getStatusCodes(),
            getServiceLocations(),
            getProviders(),
            getListSupervising(),
        ]).finally(() => setLoading(false))
    }, [])
    
    return {
        statusCodes: statusCodes || [],
        providingProviders: providingProviders || [],
        supervisingProviders: supervisingProviders || [],
        serviceLocations: serviceLocations || [],
        loading,
        statusCodesNew: statusCodes,
        serviceLocationsNew,
        providingProvidersNew,
        supervisingProvidersNew,
    }
}
