import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, Select } from '@mui/material'
import React, { useState } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    options: { label: string; value: string }[]
    value?: string[]
    label?: string
    handleChange: (name: string, checked: boolean) => void
    placeholder?: string
    onOpen?: () => void
    onClose?: () => void
}

const useStyles = makeStyles(() => ({
    label: {
        marginBottom: 6,
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            display: 'none',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
            lineHeight: 'normal',
            height: 40,
            boxSizing: 'border-box',
            padding: 12,
        },
    },
    box_options: {
        padding: '15px 0',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },

            '& .MuiSvgIcon-root': {
                width: 18,
                height: 18,
            },
        },
    },
    group_list_check: {
        height: '300px',
        overflowY: 'auto',
    },
    box_relative: {
        position: 'relative',
    },
    btn_oke: {
        position: 'absolute',
        top: '-20px',
        cursor: 'pointer',
        right: '5px',
        background: '#4580f7',
        color: '#fff',
        width: '50px',
        height: '30px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export const MultiSelect = (props: Props) => {
    const { options, value, handleChange, label, placeholder, onOpen, onClose } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (
        <Box>
            {label && (
                <Box component={'p'} className={classes.label}>
                    {label}
                </Box>
            )}
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                <InputLabel id="demo-multiple-checkbox-label" className={classes.placeholder}>
                    {placeholder ? placeholder : 'Select option'}
                </InputLabel>
                <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    open={open}
                    onOpen={() => {
                        setOpen(true)
                        onOpen?.()
                    }}
                    onClose={() => {
                        setOpen(false)
                        onClose?.()
                    }}
                    value={value}
                    placeholder="Enter value"
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return selected
                            .map((item: string) => options.find((val) => val.value === item)?.label)
                            ?.join(', ')
                    }}
                    // MenuProps={MenuProps}
                >
                    <Box className={classes.box_options}>
                        <Box className={classes.group_list_check}>
                            {options.map((item: any) => {
                                return (
                                    <Box key={item.value} className={classes.box_option}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        (value as string[]).indexOf(item.value) > -1
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(item, e.target.checked)
                                                    }}
                                                    name={item.value}
                                                />
                                            }
                                            label={item.value + ' - ' + item.label}
                                        />
                                    </Box>
                                )
                            })}
                        </Box>
                        <Box className={classes.box_relative}>
                            <Box className={classes.btn_oke} onClick={() => setOpen(false)}>
                                OK
                            </Box>
                        </Box>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    )
}
