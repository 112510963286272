import {
    Box,
    CircularProgress,
    IconButton,
    MenuItem,
    Select,
    Typography,
    makeStyles,
} from '@material-ui/core'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import React, { useEffect, useState } from 'react'

import { ReactComponent as ReportIcon1 } from 'assests/images/report_icon_1.svg'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { ReactComponent as TrashIcon } from 'assests/icons/trash.svg'
import { useNotification } from 'shared/elements/Notification'
import { getMedicalCoding } from 'containers/ClinicalNotes/api/services'
import { Close } from '@material-ui/icons'
import { LoadingButton } from 'components/custom/LoadingButton'

const useStyles = makeStyles(() => ({
    outsideContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        minHeight: 800
    },
    insideContainer: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        height: 380,
        overflowY: 'scroll',
        paddingTop: 10
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px 20px',
    },
    horizontalDivider: {
        width: '100%',
        borderTop: '1px solid #EAECF0',
    },
    verticalDivider: {
        height: '100%',
        borderLeft: '1px solid #e0e0e0',
    },
    formContainer: {
        // padding: '20px 20px',
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
    },
    footer: {
        padding: 15,
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    footerContent: {
        display: 'flex',
        // justifyContent: 'center',
        gap: 10,
    },
    columnHeaderTitle: {
        color: '#303E4E',
        fontSize: 14,
        fontWeight: 400,
    },
    checkbox: {
        '&$checked': {
            color: '#546FC9',
        },
        '&$disabled': {
            color: '#ccc',
        },
    },
    tableContent: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        width: '100%',
    },
    selectionBar: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        width: '20%',
    },
    columnHead: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 20,
        gap: 30,
    },
    content: {
        border: '1px solid #EAECF0',
        borderRadius: 5,
        background: '#F8F9FB',
        padding: '5px 20px',
        gap: 30,
        display: 'flex',
        paddingLeft: 20,
        alignItems: 'center',
    },
    viewAndDeleteBar: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        // width: '67%',
    },
    procedureCode: {
        '& .MuiOutlinedInput-root': {
            width: '120px !important',
            minHeight: '40px !important',
            height: '40px !important',
            borderRadius: '6px 0px 0px 6px !important',
            borderRight: 'none !important',
            color: '#303E4E !important',
            '& .MuiSelect-select': {
                minHeight: '0 !important',
            },
        },
        '& .MuiBox-root .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root': {
            borderRadius: '0px 6px 6px 0px !important',
            borderLeft: '0 !important',
            width: '100% !important',
            padding: '0 !important',
            '& .MuiInputAdornment-root .MuiButtonBase-root img': {
                height: '10px',
                width: '10px',
                marginLeft: '5px',
            },
        },
    },
    icdCode: {
        borderRadius: '5px',
        border: '0.4px solid #C0C1C5',
        background: '#FFF',
        padding: '3px 8px',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        // backgroundColor: 'yellow',
        cursor: 'default',
    },
    icdCodeActive: {
        background: '#15A452',
        color: '#fff',
        '& .icdCodeActive-p': {
            color: '#fff !important',
        },
        '& .icdCodeActive-divider': {
            borderColor: '#fff !important',
        },
    },
    icdCodeName: {
        color: '#303e4e',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontWeight: 400,
    },
    icdCodeNameActive: {
        color: '#303E4E !important',
    },
    addServiceLine: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .service-line': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            gap: 5,
            '& .MuiTypography-root': {
                color: '#15A452',
                fontSize: 14,
                fontWeight: 500,
            },
        },
    },
    filter_btn: {
        width: 'max-content !important',
        fontWeight: 'normal !important',
        borderRadius: '10px !important',
        background: '#5571C6 !important',
        color: 'white !important',
        padding: '11px !important',
        height: '40px !important',
        textTransform: 'capitalize !important',
        boxShadow: 'none !important',
    },
    filter_btn_outlined: {
        background: 'transparent !important',
        border: '2px solid #5571C6 !important',
        color: '#5571C6 !important',
    },
    serviceLineDeletion: {
        border: '1px solid #e0e0e0',
        borderRadius: 4,
        padding: '0px 7px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        gap: 10,
    },
    activeDraggable: {
        border: '1px solid #5571C6 !important',
        background: '#FFF !important',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10) !important'
    },
    nonDraggables: {
        '& .icdCodeActive-p': {
            color: '#667689 !important'
        }
    }
}))

const CODE_TYPES = {
    CPT_CODE: 'CPT',
    HCPCS_CODE: 'HCPCS',
}

const medicalCodeTypes = [CODE_TYPES.CPT_CODE, CODE_TYPES.HCPCS_CODE]

const tooltipTitle = (codeDescription) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <Typography style={{ color: '#303E4E', fontSize: 14, fontWeight: 500 }}>
                ICD10 code
            </Typography>
            <Typography style={{ color: '#303E4E', fontSize: 14, fontWeight: 400 }}>
                {codeDescription}
            </Typography>
        </div>
    )
}

const AddToServiceLineIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <rect x="3" y="3" width="18" height="18" rx="4" ry="4" stroke="#333" stroke-width="2" fill="none"/>
            <line x1="12" y1="8" x2="12" y2="16" stroke="#333" stroke-width="2" stroke-linecap="round"/>
            <line x1="8" y1="12" x2="16" y2="12" stroke="#333" stroke-width="2" stroke-linecap="round"/>
        </svg>
    )
}

const serviceLinePopoverContent = (serviceLines, onClose, onAddToServiceLine) => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography style={{ color: '#667689', fontWeight: 500, fontSize: 12, marginBottom: 5 }}>Add to:</Typography>
            <IconButton onClick={onClose}>
                <Close style={{ height: 15, width: 15 }} />
            </IconButton>
            </div>
            {serviceLines?.map((_, itemIdx) => {
                return (
                    <MenuItem 
                        key={itemIdx} 
                        onClick={() => onAddToServiceLine(itemIdx)}
                        style={{ 
                            display: 'flex', 
                            gap: 5, 
                            alignItems: 'center', 
                            padding: '6px 0px' 
                        }}>
                        <AddToServiceLineIcon style={{ height: 20, width: 20 }} />
                        <Typography>Service line {itemIdx+1}</Typography>
                    </MenuItem>
                )
            })}
        </>
    )
}

const SelectDropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
            d="M8.68365 9.33047L12.2659 5.82422L13.2892 6.8258L8.68365 11.3336L4.07812 6.8258L5.10141 5.82422L8.68365 9.33047Z"
            fill="#667689"
        />
    </svg>
)

function MedCodesV2(props) {
    const classes = useStyles()
    const notification = useNotification()

    const [loader, setLoader] = useState(false)
    const [icdCodes, setIcdCodes] = useState([])
    const [tooltipOpen, setTooltipOpen] = useState({})
    const [defaultTooltipValues, setDefaultTooltipValues] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const [currentIcd10Dragging, setCurrentIcd10Dragging] = useState({})
    const [openServiceLinePopover, setOpenServiceLinePopover] = useState(false)
    const [selectedIcdCodes, setSelectedIcdCodes] = useState([])

    const defaultServiceLine = {
        superbill: false,
        code: {
            type: 'CPT',
            value: '',
            description: '',
        },
        icd10_code: [],
    }

    const handleTooltipClose = () => {
        setTooltipOpen(defaultTooltipValues)
    }

    const handleTooltipOpen = (propName) => {
        setTooltipOpen((state) => {
            return {
                ...state,
                [propName]: true,
            }
        })
    }

    const deleteServiceLine = (event, serviceLineIndex) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(serviceLineIndex)
    }

    const handleDeleteServiceLine = () => {
        try {
            const medCodes = props?.values
            if(medCodes?.length === 1){
                props?.onChange(props?.fieldName, [defaultServiceLine])
            } else {
                if (selectedItemIndex > -1) {
                    medCodes.splice(selectedItemIndex, 1)
                }
                props?.onChange(`${props?.fieldName}`, medCodes)
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
        setAnchorEl(null)
        setSelectedItemIndex(null)
    }

    const addServiceLine = () => {
        props?.onChange(props?.fieldName, [...props?.values, defaultServiceLine])
    }

    const addICD10ToServiceLineManually = (serviceLineIndex) => {
        setOpenServiceLinePopover(false)
        const totalLength = 
            props?.values[serviceLineIndex]?.icd10_code?.length + selectedIcdCodes?.length
        
        if(totalLength > 4) {
            notification('You can add up to 4 diagnosis pointers', 'error')
            return
        }

        const isDuplicatePresent = props?.values[serviceLineIndex]?.icd10_code?.some((el) => {
            return selectedIcdCodes?.includes(el?.code)
        })
        if(isDuplicatePresent) {
            notification('Can not add duplicate diagnosis pointers!', 'error')
            return
        }

        const newIcdCodes = icdCodes?.filter(el => selectedIcdCodes?.includes(el?.code))
        props?.onChange(`${props?.fieldName}[${serviceLineIndex}].icd10_code`, [
            ...props?.values[serviceLineIndex]?.icd10_code,
            ...newIcdCodes
        ])
        setSelectedIcdCodes([])
    }

    useEffect(() => {
        setLoader(true)
        getMedicalCoding(props?.appointmentId)
            ?.then((res) => {
                const { cpt, hcpcs, icd10 } = res?.data
                const firstData = []
                if (cpt?.length > 0 || hcpcs?.lengh > 0) {
                    cpt?.forEach((item) => {
                        const dxPointersArray = item?.extension?.dxPointers
                            ?.split(':')
                            ?.map((pointer) => parseInt(pointer) - 1)
                        const icd10Array = []
                        dxPointersArray?.forEach((pointerIndex) => {
                            if (pointerIndex >= 0) {
                                icd10Array?.push(icd10[pointerIndex])
                            }
                        })
                        firstData.push({
                            superbill: false,
                            code: {
                                type: 'CPT',
                                value: item?.code,
                                description: item?.description,
                            },
                            icd10_code: icd10Array,
                        })
                    })
                    hcpcs?.forEach((item) => {
                        const dxPointersArray = item?.extension?.dxPointers
                            ?.split(':')
                            ?.map((pointer) => parseInt(pointer) - 1)
                        const icd10Array = []
                        dxPointersArray?.forEach((pointerIndex, idx) => {
                            if (pointerIndex >= 0) {
                                icd10Array?.push(icd10[pointerIndex])
                            }
                        })
                        firstData.push({
                            superbill: false,
                            code: {
                                type: 'HCPCS',
                                value: item?.code,
                                description: item?.description,
                            },
                            icd10_code: icd10Array,
                        })
                    })
                } else {
                    firstData.push({
                        superbill: false,
                        code: {
                            type: 'CPT',
                            value: '',
                            description: '',
                        },
                        icd10_code: [],
                    })
                }

                //set tooltipOpenValues
                const tooltipOpenValues = {}
                firstData?.forEach((_, iIndex) => {
                    icd10?.forEach((_, jIndex) => {
                        tooltipOpenValues[`${iIndex}.${jIndex}`] = false
                    })
                })
                setTooltipOpen(tooltipOpenValues)
                setDefaultTooltipValues(tooltipOpenValues)
                //set data to formik
                props?.onChange(props?.fieldName, firstData)
                setIcdCodes(icd10)
            })
            ?.catch((err) => {
                notification(err.message, 'error')
            })
            ?.finally(() => setLoader(false))
    }, [])

    useEffect(() => {
        const draggable = document.getElementsByClassName('draggable-icd10')
        const droppable = document.getElementsByClassName('service-line-icd10-container')
        const currentDraggable = document.getElementById(currentIcd10Dragging?.code)

        const handleDrag = () => {
            currentDraggable?.classList?.add(classes.activeDraggable)
        }

        const handleDragEnd = () => {
            currentDraggable?.classList?.remove(classes.activeDraggable)
        }

        const handleDragOver = (event) => {
            event?.preventDefault()
        }

        const handleDrop = (droppableItem) => {
            const serviceLineIndex = droppableItem?.id
                
                if (props?.values && props?.values[serviceLineIndex]?.length === 4) {
                    setCurrentIcd10Dragging({})
                    notification('You can select up to 4 diagnosis pointers', 'error')
                    return
                }

                const isIcd10Exists = props?.values && props?.values[serviceLineIndex]?.icd10_code?.some((item) => {
                    return item?.code === currentIcd10Dragging?.code
                })

                if (isIcd10Exists) {
                    setCurrentIcd10Dragging({})
                    // notification('Can not add duplicate diagnosis pointers', 'error')
                    return
                }

                const onChangeData = props?.values && {
                    ...props?.values[serviceLineIndex],
                    icd10_code: [
                        ...props?.values[serviceLineIndex]?.icd10_code,
                        icdCodes?.find((item) => item?.code === currentDraggable?.id),
                    ],
                }

                setCurrentIcd10Dragging({})
                props?.onChange(`${props?.fieldName}[${serviceLineIndex}]`, onChangeData)
        }

        if(currentDraggable) {
            currentDraggable?.addEventListener('drag', handleDrag)
            currentDraggable?.addEventListener('dragend', handleDragEnd)
        }

        for (const droppableItem of droppable) {
            droppableItem?.addEventListener('dragover', handleDragOver)
            droppableItem?.addEventListener('drop', () => handleDrop(droppableItem))
        }

        return () => {
            if(currentDraggable) {
                currentDraggable?.removeEventListener('drag', handleDrag)
                currentDraggable?.removeEventListener('dragend', handleDragEnd)
            }
    
            for (const droppableItem of droppable) {
                droppableItem?.removeEventListener('dragover', handleDragOver)
                droppableItem?.removeEventListener('drop', () => handleDrop(droppableItem))
            }
        }
    }, [isDragging])

    return (
        <div className={classes.outsideContainer}>
            <div className={classes.insideContainer}>
                <div className={classes.header}>
                    <Typography
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#303E4E',
                            padding: '5px 0',
                        }}>
                        Medical Coding
                    </Typography>
                </div>

                <span className={classes.horizontalDivider}></span>

                <div className={classes.container}>
                    {/* <Typography style={{ color: '#667689', fontWeight: 500, fontSize: 14 }}>
                        Drag and drop the ICD 10 code to the respective service line.
                    </Typography> */}

                    <Typography style={{ color: '#303e4e', fontWeight: 500, fontSize: 14, display: 'flex', gap: 10 }}>
                        Diagnosis Codes 
                        <Typography>( Choose the ICD 10 code you want to add to the respective service line. )</Typography>
                    </Typography>

                    <div style={{ display: 'flex', gap: 10, width: '100%' }}>
                        <div
                            style={{
                                border: '1px solid #EAECF0',
                                borderRadius: 5,
                                background: '#F8F9FB',
                                padding: icdCodes?.length > 0 ? '5px 10px' : '20px 10px',
                                width: '85%',
                                gap: 10,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <div
                                style={{
                                    gap: 5,
                                    display: 'flex',
                                    width: '100%',
                                    maxWidth: '100%',
                                    // overflowY: 'scroll',
                                    flexWrap: 'wrap',
                                }}>
                                {icdCodes?.length > 0 &&
                                    icdCodes?.map((icd10Code, _index) => {
                                        return (
                                            <span
                                                onClick={() => {
                                                    let currentSelectedIcdCode = []
                                                    if(selectedIcdCodes?.includes(icd10Code?.code)){
                                                        currentSelectedIcdCode = selectedIcdCodes?.filter(el => el !== icd10Code?.code)
                                                    }else {
                                                        currentSelectedIcdCode = [
                                                            ...selectedIcdCodes,
                                                            icd10Code?.code
                                                        ]
                                                    }
                                                    setSelectedIcdCodes(currentSelectedIcdCode)
                                                }}
                                                id={`${icd10Code?.code}`}
                                                draggable={true}
                                                onDragStart={() => {
                                                    setIsDragging(!isDragging)
                                                    setCurrentIcd10Dragging(icd10Code)
                                                }}
                                                className={`${classes.icdCode} draggable-icd10 ${(selectedIcdCodes?.includes(icd10Code?.code)) ? classes.icdCodeActive : ''}`}
                                                key={icd10Code?.code}>
                                                <Typography className={`${classes.icdCodeName} icdCodeActive-p`}>
                                                    {icd10Code?.code}
                                                </Typography>

                                                <div className={`${classes.verticalDivider} icdCodeActive-divider`}></div>

                                                <HtmlTooltip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    title={tooltipTitle(icd10Code?.description)}
                                                    arrow
                                                    placement="bottom">
                                                    <IconButton>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="11"
                                                            height="11"
                                                            viewBox="0 0 11 11"
                                                            fill="none">
                                                            <path
                                                                d="M5.83829 4.75071C5.80918 4.59035 5.66883 4.46874 5.50008 4.46874C5.31023 4.46874 5.15633 4.62265 5.15633 4.81249V7.90773L5.16187 7.96952C5.19097 8.12988 5.33133 8.25148 5.50008 8.25148C5.68993 8.25148 5.84383 8.09758 5.84383 7.90773V4.81249L5.83829 4.75071ZM6.04919 3.26562C6.04919 2.98085 5.81834 2.74999 5.53357 2.74999C5.2488 2.74999 5.01794 2.98085 5.01794 3.26562C5.01794 3.55039 5.2488 3.78124 5.53357 3.78124C5.81834 3.78124 6.04919 3.55039 6.04919 3.26562ZM11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C8.53757 11 11 8.53757 11 5.5ZM0.6875 5.5C0.6875 2.84213 2.84213 0.6875 5.5 0.6875C8.15787 0.6875 10.3125 2.84213 10.3125 5.5C10.3125 8.15787 8.15787 10.3125 5.5 10.3125C2.84213 10.3125 0.6875 8.15787 0.6875 5.5Z"
                                                                fill={(selectedIcdCodes?.includes(icd10Code?.code)) ? "#fff" : "#667689"}
                                                            />
                                                        </svg>
                                                    </IconButton>
                                                </HtmlTooltip>
                                            </span>
                                        )
                                    })}
                            </div>
                        </div>

                        <HtmlTooltip
                            disableHoverListener
                            open={openServiceLinePopover}
                            placement='bottom'
                            title={serviceLinePopoverContent(props?.values, () => setOpenServiceLinePopover(false), addICD10ToServiceLineManually)}
                        >
                            <div
                                onClick={() => setOpenServiceLinePopover(true)}
                                style={{
                                    width: '12%',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: 5,
                                    padding: '0px 5px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    pointerEvents: selectedIcdCodes?.length > 0 ? 'auto' : 'none',
                                    opacity: selectedIcdCodes?.length > 0 ? 1 : 0.5,
                                }}>
                                <Typography>Add to service line</Typography>
                            </div>
                        </HtmlTooltip>
                    </div>

                    <Typography style={{ color: '#303e4e', fontWeight: 500, fontSize: 14, marginTop: 20 }}>Service Lines</Typography>

                    <div className={classes.formContainer}>
                        {loader ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 15,
                                }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            props?.values?.map((serviceLine, serviceLineIndex) => {
                                return (
                                    <>
                                        <div className={classes.tableContent}>
                                            <div className={classes.selectionBar}>
                                                <Typography style={{ color: '#303e4e', fontWeight: 500, fontSize: 12 }}>Code</Typography>
                                            </div>
                                            <div style={{
                                                width: serviceLineIndex > 0 ? '75%' : '78%',
                                            }}>
                                                <Typography style={{ color: '#303e4e', fontWeight: 500, fontSize: 12 }}>Service line {serviceLineIndex + 1}</Typography>
                                            </div>
                                        </div>
                                        <div key={serviceLineIndex} className={classes.tableContent}>
                                            <div className={classes.selectionBar}>
                                                <div
                                                    style={{ display: 'flex', width: '100%' }}
                                                    className={classes.procedureCode}>
                                                    <Select
                                                        variant="outlined"
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        IconComponent={(props) => (
                                                            <SelectDropDownIcon {...props} />
                                                        )}
                                                        renderValue={(selected) => {
                                                            if (selected?.length === 0) {
                                                                return (
                                                                    <Typography
                                                                        className={classes.placeholder}>
                                                                        Select
                                                                    </Typography>
                                                                )
                                                            }

                                                            return selected
                                                        }}
                                                        value={serviceLine?.code?.type}
                                                        onChange={(event) => {
                                                            props?.onChange(
                                                                `${props?.fieldName}[${serviceLineIndex}].code.type`,
                                                                event.target.value,
                                                            )
                                                        }}>
                                                        {medicalCodeTypes?.map((codeType) => (
                                                            <MenuItem key={codeType} value={codeType}>
                                                                <Typography
                                                                    variant="body2"
                                                                    noWrap
                                                                    style={{
                                                                        color: '#667689',
                                                                        fontSize: 14,
                                                                        ...(props?.dropdownliststyles ||
                                                                            {}),
                                                                    }}>
                                                                    {codeType}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>

                                                    <SearchICD10Code
                                                        value={{
                                                            code: serviceLine?.code?.value,
                                                            display: serviceLine?.code?.value,
                                                        }}
                                                        variant={
                                                            serviceLine?.code?.type === CODE_TYPES.CPT_CODE
                                                                ? 'CPT'
                                                                : 'HCPCS'
                                                        }
                                                        onChange={(value) => {
                                                            const codeData = {
                                                                type: serviceLine?.code?.type,
                                                                value: value?.code,
                                                                description: value?.display,
                                                            }
                                                            props?.onChange(
                                                                `${props?.fieldName}[${serviceLineIndex}].code`,
                                                                codeData,
                                                            )
                                                        }}
                                                        getOptionLabel={(option) => option?.code || ''}
                                                        className={classes.test}
                                                        placeholder={`Search ${serviceLine?.code?.type} codes`}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div
                                                id={serviceLineIndex}
                                                className={`service-line-icd10-container`}
                                                style={{
                                                    border: '1px solid #EAECF0',
                                                    borderRadius: 5,
                                                    background: '#F8F9FB',
                                                    width: serviceLineIndex > 0 ? '75%' : '78%',
                                                    gap: 10,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '0px 10px'
                                                }}>
                                                {serviceLine?.icd10_code?.length > 0 ?
                                                    serviceLine?.icd10_code?.map(
                                                        (icd10Code, _index) => {
                                                            return (
                                                                <span
                                                                    className={`${classes.icdCode}`}
                                                                    key={icd10Code?.code}>
                                                                    <Typography
                                                                        className={`${classes.icdCodeName}`}>
                                                                        {icd10Code?.code}
                                                                    </Typography>

                                                                    <div
                                                                        className={
                                                                            classes.verticalDivider
                                                                        }></div>

                                                                    <HtmlTooltip
                                                                        PopperProps={{
                                                                            disablePortal: true,
                                                                        }}
                                                                        title={tooltipTitle(
                                                                            icd10Code?.description,
                                                                        )}
                                                                        onClose={handleTooltipClose}
                                                                        // open={
                                                                        //     tooltipOpen[
                                                                        //         `${serviceLineIndex}.${_index}`
                                                                        //     ]
                                                                        // }
                                                                        // disableHoverListener
                                                                        arrow
                                                                        placement="bottom">
                                                                        <IconButton
                                                                        // onClick={() =>
                                                                        //     handleTooltipOpen(
                                                                        //         `${serviceLineIndex}.${_index}`,
                                                                        //     )
                                                                        // }
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="11"
                                                                                height="11"
                                                                                viewBox="0 0 11 11"
                                                                                fill="none">
                                                                                <path
                                                                                    d="M5.83829 4.75071C5.80918 4.59035 5.66883 4.46874 5.50008 4.46874C5.31023 4.46874 5.15633 4.62265 5.15633 4.81249V7.90773L5.16187 7.96952C5.19097 8.12988 5.33133 8.25148 5.50008 8.25148C5.68993 8.25148 5.84383 8.09758 5.84383 7.90773V4.81249L5.83829 4.75071ZM6.04919 3.26562C6.04919 2.98085 5.81834 2.74999 5.53357 2.74999C5.2488 2.74999 5.01794 2.98085 5.01794 3.26562C5.01794 3.55039 5.2488 3.78124 5.53357 3.78124C5.81834 3.78124 6.04919 3.55039 6.04919 3.26562ZM11 5.5C11 2.46243 8.53757 0 5.5 0C2.46243 0 0 2.46243 0 5.5C0 8.53757 2.46243 11 5.5 11C8.53757 11 11 8.53757 11 5.5ZM0.6875 5.5C0.6875 2.84213 2.84213 0.6875 5.5 0.6875C8.15787 0.6875 10.3125 2.84213 10.3125 5.5C10.3125 8.15787 8.15787 10.3125 5.5 10.3125C2.84213 10.3125 0.6875 8.15787 0.6875 5.5Z"
                                                                                    fill="#667689"
                                                                                />
                                                                            </svg>
                                                                        </IconButton>
                                                                    </HtmlTooltip>
                                                                </span>
                                                            )
                                                        },
                                                    )
                                                    :
                                                    <Typography style={{ color: '#989CA8', fontWeight: 500, fontSize: 14 }}>
                                                        Please select up to 4 codes
                                                    </Typography>
                                                }
                                            </div>

                                            {/* {serviceLineIndex > 0 && ( */}
                                                <IconButton
                                                    onClick={(event) =>
                                                        deleteServiceLine(event, serviceLineIndex)
                                                    }
                                                    className={classes.serviceLineDeletion}>
                                                    <TrashIcon />
                                                </IconButton>
                                            {/* )} */}
                                        </div>
                                    </>
                                )
                            })
                        )}

                        <div className={classes.addServiceLine}>
                            <div className="service-line" onClick={addServiceLine}>
                                <IconButton>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none">
                                        <path
                                            d="M8.99993 0.171875C7.13616 0.171875 5.34876 0.912266 4.0309 2.23012C2.71305 3.54798 1.97266 5.33538 1.97266 7.19915C1.97266 9.06292 2.71305 10.8503 4.0309 12.1682C5.34876 13.486 7.13616 14.2264 8.99993 14.2264C10.8637 14.2264 12.6511 13.486 13.969 12.1682C15.2868 10.8503 16.0272 9.06292 16.0272 7.19915C16.0251 5.33607 15.2841 3.54978 13.9666 2.23244C12.6492 0.915095 10.8629 0.173984 8.99993 0.171875ZM8.99993 13.3481V13.348C7.36908 13.348 5.80519 12.7003 4.65191 11.547C3.49867 10.3939 2.8509 8.83006 2.8509 7.199C2.8509 5.56794 3.49867 4.00426 4.65191 2.85097C5.80504 1.69774 7.36888 1.04997 8.99993 1.04997C10.631 1.04997 12.1947 1.69774 13.348 2.85097C14.5012 4.0041 15.149 5.56794 15.149 7.199C15.1472 8.82923 14.4988 10.3922 13.346 11.545C12.1932 12.6978 10.6303 13.3462 8.99999 13.348L8.99993 13.3481ZM12.9967 7.19908C12.9967 7.31559 12.9505 7.42729 12.8681 7.50965C12.7858 7.59202 12.6741 7.63822 12.5576 7.63822H9.43919V10.7566C9.43919 10.9135 9.35551 11.0584 9.21961 11.137C9.0837 11.2154 8.91636 11.2154 8.78046 11.137C8.64455 11.0584 8.56088 10.9135 8.56088 10.7566V7.63822H5.44251C5.28561 7.63822 5.14068 7.55455 5.06212 7.41865C4.98367 7.28274 4.98367 7.1154 5.06212 6.9795C5.14067 6.84359 5.28561 6.75992 5.44251 6.75992H8.56088L8.56098 3.64155C8.56098 3.48465 8.64466 3.33972 8.78056 3.26116C8.91647 3.18271 9.08381 3.18271 9.21971 3.26116C9.35561 3.33971 9.43929 3.48465 9.43929 3.64155V6.75992H12.5577L12.5578 6.76002C12.6743 6.76002 12.786 6.80623 12.8683 6.88859C12.9507 6.97096 12.9969 7.08266 12.9969 7.19917L12.9967 7.19908Z"
                                            fill="#15A452"
                                        />
                                    </svg>
                                </IconButton>
                                <Typography>Add Service Line</Typography>
                            </div>
                        </div>

                        <CustomAlertPopOver
                            icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                            anchorEl={anchorEl}
                            handleClose={() => {
                                setAnchorEl(null)
                                setSelectedItemIndex(null)
                            }}
                            title="Service Line Deletion"
                            bodyText={`Are you sure you want to delete this service line?`}
                            confirmButtonName={'Delete'}
                            confirmButtonColor={'red'}
                            onSuccess={handleDeleteServiceLine}
                            customAnchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            customTransformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            buttonProps={{
                                styleConfirm: {
                                    textTransform: 'capitalize',
                                    fontWeight: '500',
                                    display: 'flex',
                                    width: '68px',
                                    height: '32px',
                                    padding: '8px 20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    fontSize: '15px',
                                    color: '#FFF',
                                    textAlign: 'center',
                                    fontFamily: 'Rubik',
                                    fontStyle: 'normal',
                                    lineHeight: '24px',
                                    ':hover': {
                                        boxShadow: 'none',
                                    },
                                },
                                styleCancel: {
                                    textTransform: 'capitalize',
                                    display: 'flex',
                                    height: '32px',
                                    padding: '6px 12px',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    fontFamily: 'Rubik',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                },
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={classes.footer}>
                <span className={classes.footerContent}>
                    <div>
                        <LoadingButton
                            onClick={props?.handlePreview}
                            variant="contained"
                            disabled //need to be disabled until any update regarding SEHR-521
                            className={`${classes.filter_btn} ${classes.filter_btn_outlined}`}>
                            <span
                                style={{
                                    marginLeft: 6,
                                    fontSize: '16px',
                                }}>
                                Preview Note
                            </span>
                        </LoadingButton>
                    </div>
                    <div>
                        <LoadingButton
                            onClick={props?.handleSignAndLock}
                            variant="contained"
                            disabled //need to be disabled until any update regarding SEHR-521
                            className={classes.filter_btn}>
                            <span
                                style={{
                                    marginLeft: 6,
                                    fontSize: '16px',
                                }}>
                                Sign & Lock
                            </span>
                        </LoadingButton>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default MedCodesV2
