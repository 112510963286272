import * as actionType from '../actionType';
import { setLoading, setError, setErrorMessage } from './loader';
import {
    editClinicAdminProfile,
    getClinicAdminDetailById,
    getClinicAdminProfileById,
    getClinicList,
    updateClinicStatus as updateClinicStatusService,
    addClinicAdminProfile
} from '../../services/ClinicAdmin/clinicAdmin';

export const setClinicList = (value) => ({
    type: actionType.SET_CLINIC_LIST,
    value,
});
export const setClinicAdminProfile = (value) => ({
    type: actionType.SET_CLINIC_ADMIN_PROFILE,
    value,
});

export const setClinicAdminProfileEdit = (value) => ({
    type: actionType.SET_CLINIC_ADMIN_PROFILE_EDIT,
    value,
});

export const setClinicAdminDetail = (value) => ({
    type: actionType.SET_CLINIC_ADMIN_DETAIL,
    value,
});

export const setUpdateClinicStatus = (value) => {
    return {
        type: actionType.UPDATE_SEARCH_CLINIC_STATUS,
        value,
    };
};

export const getClinicAdminDetail = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        getClinicAdminDetailById(id)
            .then((res) => {
                if (res.data) {
                    dispatch(
                        setClinicAdminDetail(res.data),
                    );
                }
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
            });
    };
};

export const searchClinicList = (payload) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        getClinicList(payload)
            .then((res) => {
                dispatch(setLoading(false));
                dispatch(setClinicList(res.data));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(setClinicList({}));
            });
    };
};
export const getClinicAdminProfileDetail = (id) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        dispatch(setErrorMessage(''));
        getClinicAdminProfileById(id)
            .then((res) => {
                if (res.data) {
                    dispatch(
                        setClinicAdminProfile(res.data),
                    );
                }
                dispatch(setLoading(false));
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
            });
    };
};

export const handleClinicAdminProfileEdit = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        dispatch(setErrorMessage(''));
        editClinicAdminProfile(payload)
            .then((res) => {
                if (res.status === 200) {
                    dispatch(
                        setClinicAdminProfileEdit(res.data),
                    );
                    dispatch(
                        getClinicAdminProfileDetail(
                            payload?.user_id,
                        ),
                    );
                    dispatch(setLoading(false));
                    onSuccess && onSuccess();
                }
                else {
                    dispatch(setLoading(false));
                    dispatch(setError(true));
                    dispatch(
                        setErrorMessage(res?.data.message),
                    );
                }
            })
            .catch((err) => {
                dispatch(setLoading(false));
                dispatch(setError(true));
                dispatch(
                    setErrorMessage(err?.data.message),
                );
            });
    };
};

export const handleClinicAdminProfileAdd = (
    payload,
    onSuccess,
) => {
    return (dispatch) => {
		setLoading(true);
		addClinicAdminProfile(payload)
			.then((res) => {
				setLoading(false);
				dispatch(setClinicAdminProfileEdit(res.data));
				onSuccess && onSuccess(res);
			})
			.catch((err) => {
				setLoading(false);
				dispatch(setError(true));
			});
	};
};

export const updateClinicStatus = (payload) => {
    return (dispatch) => {
        setLoading(true);
        updateClinicStatusService(payload)
            .then((res) => {
                setLoading(false);
                // update row user status based on userId
                dispatch(setUpdateClinicStatus(payload));
            })
            .catch((err) => {
                setLoading(false);
                dispatch(setError(true));
            });
    };
};
