import * as actionType from '../actionType'

const initialState = ''

const isEncounter = (state = initialState, action) => {
    // return state
    switch (action.type) {
        case actionType.SET_ENCOUNTER:
            return action.encounter_id
        default:
            return state
    }
}

export default isEncounter
