import { Box, Grid, Typography } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import { Select } from 'shared/elements/FormItem/Select'

import { TextBox } from 'shared/elements/FormItem/TextBox'

import { allApiReffringProvider, apiStatusCodes } from 'services/Calendar'
import { getListPractices } from 'services/Setting'
import { PhoneNumberInput } from 'shared/elements/FormItem/Input'
import { useNotification } from 'shared/elements/Notification'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import UncheckSharedModal from './sharedContactModal/UncheckSharedModal'
import SharedContactModal from './sharedContactModal/SharedContactModal'

export const ImportantInfo = forwardRef(({ formik,clinicalPracticeList }, ref) => {
    const [clinicalPracticeOptions, setClinicalPracticeOptions] = useState([])
    const [listPatientStatus, setListPatientStatus] = useState([])
    const [isOpenModalContact, setisOpenModalContact] = useState(false)
    const [isOpenModalContactUncheck, setisOpenModalContactUncheck] = useState(false)
    const [isOpenModalSecondaryContact, setisOpenModalSecondaryContact] = useState(false)
    const [isOpenModalSecondaryContactUncheck, setisOpenModalSecondaryContactUncheck] = useState(false)
    const [isOpenModalEmailContact, setisOpenModalEmailContact] = useState(false)
    const [isOpenModalEmailContactUncheck, setisOpenModalEmailContactUncheck] = useState(false)

    const getPracticesList = async () => {
        const response = await getListPractices({
            size: 10000,
        })
        const list = response?.data?.data.map((item) => ({
            key: item.id,
            value: item.name,
        })) || []
        setClinicalPracticeOptions(list)
        clinicalPracticeList(list)
    }
    const [refferingLocationList, setRefferingLocationList] = useState([])
    const notification = useNotification()
    const refetchRefferringProvider = async () => {
        try {
            const res = await allApiReffringProvider.getAll({
                size: 1000,
            })
            setRefferingLocationList(
                res.data?.data.map((item) => ({
                    key: `${item.first_name} ${item.last_name}`,
                    value: `${item.first_name} ${item.last_name}`,
                    location: item.location?.name,
                })) || [],
            )
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }

    const getListPatientStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'PATIENT',
                limit: 100000,
            })
            setListPatientStatus(
                res?.data?.listStatusCode?.data?.map((item) => ({
                    key: item.code,
                    value: item.name,
                })),
            )
            const statusDefault = res?.data?.listStatusCode?.data?.find((el) => el.is_default)
            if (statusDefault && !formik.values.patient_status) {
                formik.setFieldValue('patient_status', statusDefault.code)
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getListPatientStatus()
        getPracticesList()
        refetchRefferringProvider()
        return () => abortController.abort()
    }, [])

    const hanldeChangeValueRefferringProvider = (value) => {
        formik.setFieldValue('referring_provider', value)
        const isRefferingLocationList = refferingLocationList.find((item) => item.key === value)
        formik.setFieldValue('referring_location', isRefferingLocationList?.location)
    }

    const SubmitFunction = () => {
        formik.setFieldValue('shared_phone', true)
        setisOpenModalContact(false)
    }

    const contactModalClose = () => {
        formik.setFieldValue('shared_phone', false)
        setisOpenModalContact(false)
    }

    const uncheckNo = () => {
        formik.setFieldValue('shared_phone', true)
        setisOpenModalContactUncheck(false)
    }

    const uncheckYes = () => {
        formik.setFieldValue('shared_phone', false)
        setisOpenModalContactUncheck(false)
    }

    const SubmitSecondaryFunction = () => {
        formik.setFieldValue('shared_secondary_phone', true)
        setisOpenModalSecondaryContact(false)
    }

    const contactModalSecondaryClose = () => {
        formik.setFieldValue('shared_secondary_phone', false)
        setisOpenModalSecondaryContact(false)
    }

    const uncheckSecondaryNo = () => {
        formik.setFieldValue('shared_secondary_phone', true)
        setisOpenModalSecondaryContactUncheck(false)
    }

    const uncheckSecondaryYes = () => {
        formik.setFieldValue('shared_secondary_phone', false)
        setisOpenModalSecondaryContactUncheck(false)
    }

    const SubmitEmailFunction = () => {
        formik.setFieldValue('shared_email', true)
        setisOpenModalEmailContact(false)
    }

    const contactModalEmailClose = () => {
        formik.setFieldValue('shared_email', false)
        setisOpenModalEmailContact(false)
    }

    const uncheckEmailNo = () => {
        formik.setFieldValue('shared_email', true)
        setisOpenModalEmailContactUncheck(false)
    }

    const uncheckEmailYes = () => {
        formik.setFieldValue('shared_email', false)
        setisOpenModalEmailContactUncheck(false)
    }

    return (
        <div ref={ref}>
            <Grid>
                <Grid>
                    <Grid
                        style={{ marginBottom: 16, marginTop: 10 }}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Patient Information
                                </Typography>
                            </legend>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="First Name"
                                        required
                                        name="first_name"
                                        value={formik.values.first_name}
                                        error={
                                            formik.touched.first_name &&
                                            Boolean(formik.errors.first_name)
                                        }
                                        helperText={
                                            formik.touched.first_name && formik.errors.first_name
                                        }
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        placeholder="Enter first name"
                                        onChange={(value) => {
                                            formik.setFieldValue(
                                                'first_name',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                            if (value === '' && formik?.values?.access_patient_portal) {
                                                formik?.setFieldValue('access_patient_portal', false)
                                                notification('Patient portal access removed as a mandatory field is set empty!', 'warning')
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextBox
                                        label="Middle Name"
                                        name="middle_name"
                                        value={formik.values.middle_name}
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        placeholder="Enter middle name"
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'middle_name',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextBox
                                        label="Last Name"
                                        required
                                        name="last_name"
                                        value={formik.values.last_name}
                                        error={
                                            formik.touched.last_name &&
                                            Boolean(formik.errors.last_name)
                                        }
                                        helperText={
                                            formik.touched.last_name && formik.errors.last_name
                                        }
                                        inputProps={{
                                            maxLength: 35,
                                        }}
                                        placeholder="Enter last name"
                                        onChange={(value) => {
                                            formik.setFieldValue(
                                                'last_name',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                            if (value === '' && formik?.values?.access_patient_portal) {
                                                formik?.setFieldValue('access_patient_portal', false)
                                                notification('Patient portal access removed as a mandatory field is set empty!', 'warning')
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Suffix"
                                        name="suffix"
                                        value={formik.values.suffix}
                                        inputProps={{
                                            maxLength: 10,
                                        }}
                                        placeholder="Enter suffix"
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'suffix',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container alignItems="flex-start" spacing={2}>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Previous Name"
                                        name="previous_name"
                                        value={formik.values.previous_first_name}
                                        placeholder="Enter previous name"
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'previous_first_name',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Preferred Name"
                                        name="preferred_name"
                                        value={formik.values.preferred_name}
                                        placeholder="Enter preferred name"
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'preferred_name',
                                                value.replace(/[^\w\s]/g, ''),
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        label="Patient Status"
                                        name="patient_status"
                                        placeholder="Select practice"
                                        value={formik.values.patient_status}
                                        error={
                                            formik.touched.patient_status &&
                                            Boolean(formik.errors.patient_status)
                                        }
                                        helperText={
                                            formik.touched.patient_status &&
                                            formik.errors.patient_status
                                        }
                                        options={listPatientStatus}
                                        onChange={(value) =>
                                            formik.setFieldValue('patient_status', value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Select
                                        label="Clinical Practice"
                                        required
                                        name="clinical_practice_id"
                                        placeholder="Select practice"
                                        value={formik.values.clinical_practice_id}
                                        error={
                                            formik.touched.clinical_practice_id &&
                                            Boolean(formik.errors.clinical_practice_id)
                                        }
                                        helperText={
                                            formik.touched.clinical_practice_id &&
                                            formik.errors.clinical_practice_id
                                        }
                                        options={clinicalPracticeOptions}
                                        onChange={(value) => {
                                            formik.setFieldValue('clinical_practice_id', value)
                                            if (value === '' && formik?.values?.access_patient_portal) {
                                                formik?.setFieldValue('access_patient_portal', false)
                                                notification('Patient portal access removed as a mandatory field is set empty!', 'warning')
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        label="primary Phone Number"
                                        required
                                        name="phone_number"
                                        value={formik.values.phone_number}
                                        error={
                                            formik.touched.phone_number &&
                                            Boolean(formik.errors.phone_number)
                                        }
                                        helperText={
                                            formik.touched.phone_number &&
                                            formik.errors.phone_number
                                        }
                                        onChange={(value) => {
                                            formik.setFieldValue('phone_number', value)
                                            if (value === '' && formik?.values?.access_patient_portal) {
                                                formik?.setFieldValue('access_patient_portal', false)
                                                notification('Patient portal access removed as a mandatory field is set empty!', 'warning')
                                            }
                                        }}
                                    />
                                    <Box style={{ display: "flex" }}>
                                        <CheckboxOption
                                            checked={formik.values.shared_phone}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginLeft: '3px',
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setisOpenModalContact(event.target.checked)
                                                }
                                                if (!event.target.checked) {
                                                    setisOpenModalContactUncheck(true)
                                                }
                                                formik.setFieldValue('shared_phone', event.target.checked)
                                            }}
                                        />
                                        <Box style={{
                                            "color": "#303E4E",
                                            "fontFamily": "Rubik",
                                            "fontSize": "14px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "140%",/* 19.6px */
                                            "marginLeft": "-5px",
                                            "marginTop": "7.5px"

                                        }}>Shared phone</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        label="Secondary Phone Number"
                                        name="secondary_phone_number"
                                        value={formik.values.secondary_phone_number}
                                        onChange={(value) => {
                                            formik.setFieldValue('secondary_phone_number', value)
                                        }}
                                    />
                                    <Box style={{ display: "flex" }}>
                                        <CheckboxOption
                                            checked={formik.values.shared_secondary_phone}
                                            disabled={!(formik.values.secondary_phone_number).length}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginLeft: '3px',
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setisOpenModalSecondaryContact(event.target.checked)
                                                }
                                                if (!event.target.checked) {
                                                    setisOpenModalSecondaryContactUncheck(true)
                                                }
                                                formik.setFieldValue('shared_secondary_phone', event.target.checked)
                                            }}
                                        />
                                        <Box style={{
                                            "color": "#303E4E",
                                            "fontFamily": "Rubik",
                                            "fontSize": "14px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "140%",/* 19.6px */
                                            "marginLeft": "-5px",
                                            "marginTop": "7.5px"

                                        }}>Shared phone</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Email"
                                        name="email"
                                        value={formik.values.email}
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                        placeholder="Enter email"
                                        onChange={(value) => {
                                            formik.setFieldValue('email', value)
                                        }}
                                        required={formik?.values?.access_patient_portal}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                    <Box style={{ display: "flex" }}>
                                        <CheckboxOption
                                            checked={formik.values.shared_email}
                                            disabled={!(formik.values.email).length}
                                            sx={{
                                                '& .MuiFormControlLabel': {
                                                    marginRight: '0px',
                                                },
                                            }}
                                            customStyle={{
                                                marginLeft: '3px',
                                                marginRight: '0px',
                                                height: '14px',
                                                width: '14px',
                                            }}
                                            option={{
                                                label: '',
                                                value: false,
                                            }}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setisOpenModalEmailContact(event.target.checked)
                                                }
                                                if (!event.target.checked) {
                                                    setisOpenModalEmailContactUncheck(true)
                                                }
                                                formik.setFieldValue('shared_email', event.target.checked)
                                            }}
                                        />
                                        <Box style={{
                                            "color": "#303E4E",
                                            "fontFamily": "Rubik",
                                            "fontSize": "14px",
                                            "fontStyle": "normal",
                                            "fontWeight": "400",
                                            "lineHeight": "140%",/* 19.6px */
                                            "marginLeft": "-5px",
                                            "marginTop": "7.5px"

                                        }}>Shared email</Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Primary Care Physician"
                                        name="primary_care_physician"
                                        placeholder="Enter primary Care Physician Name"
                                        value={formik.values.primary_care_physician}
                                        error={
                                            formik.touched.primary_care_physician &&
                                            Boolean(formik.errors.primary_care_physician)
                                        }
                                        helperText={
                                            formik.touched.primary_care_physician &&
                                            formik.errors.primary_care_physician
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue('primary_care_physician', value)
                                        }
                                    />

                                </Grid>
                            </Grid>
                            <Grid item container alignItems="flex-start" spacing={2}>
                                <Grid item xs={3}>
                                    <Select
                                        label="Referring Provider"
                                        placeholder="Select provider"
                                        value={formik.values.referring_provider}
                                        onChange={(value) =>
                                            hanldeChangeValueRefferringProvider(value)
                                        }
                                        options={refferingLocationList || []}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="Referring Location"
                                        name="referring_location"
                                        placeholder="Select location"
                                        value={formik.values.referring_location}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        label="Primary Care Physician Phone"
                                        name="primary_care_phone_number"
                                        placeholder='Enter PCP Phone Number'
                                        value={formik.values.primary_care_physician_phone}
                                        onChange={(value) => {
                                            formik.setFieldValue('primary_care_physician_phone', value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PhoneNumberInput
                                        label="Primary Care Physician Fax"
                                        name="primary_care_phone_fax"
                                        value={formik.values.primary_care_physician_fax}
                                        placeholder='Enter PCP Fax Number'

                                        onChange={(value) => {
                                            formik.setFieldValue('primary_care_physician_fax', value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
            </Grid>
            <SharedContactModal
                isOpenModalContact={isOpenModalContact}
                contactModalClose={contactModalClose}
                SubmitFunction={SubmitFunction}
                phone_number={formik.values.phone_number}
                height={'340px'}
                heading={'Shared Phone Number'}
                text1='You have indicated a shared phone number.'
                text2='Patient has agreed to allow information about their care to be left at this phone number.'
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalContactUncheck}
                contactModalClose={uncheckNo}
                SubmitFunction={uncheckYes}
                height={'310px'}
                heading={'Shared Phone Number'}
                text="You have indicated this is not shared phone number."
            />
            <SharedContactModal
                isOpenModalContact={isOpenModalSecondaryContact}
                contactModalClose={contactModalSecondaryClose}
                SubmitFunction={SubmitSecondaryFunction}
                phone_number={formik.values.secondary_phone_number}
                height={'340px'}
                heading={'Shared Phone Number'}
                text1='You have indicated a shared phone number.'
                text2='Patient has agreed to allow information about their care to be left at this phone number.'
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalSecondaryContactUncheck}
                contactModalClose={uncheckSecondaryNo}
                SubmitFunction={uncheckSecondaryYes}
                height={'310px'}
                heading={'Shared Phone Number'}
                text="You have indicated this is not shared phone number."
            />
            <SharedContactModal
                isOpenModalContact={isOpenModalEmailContact}
                contactModalClose={contactModalEmailClose}
                SubmitFunction={SubmitEmailFunction}
                phone_number={formik.values.email}
                height={'340px'}
                heading={'Shared Email Inbox'}
                text1='You have indicated a shared email.'
                text2='Patient has agreed to allow information about their care to be left at this email address.'
            />
            <UncheckSharedModal
                isOpenModalContact={isOpenModalEmailContactUncheck}
                contactModalClose={uncheckEmailNo}
                SubmitFunction={uncheckEmailYes}
                height={'310px'}
                heading={'Shared Email Inbox'}
                text="You have indicated this is not shared email."
            />
        </div>
    )
})
