import React from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
interface Props {
    visible?: boolean
    thickness?: number
}

const useStyles = makeStyles({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
        zIndex: 100,
    },
})

export const Loading = (props: Props) => {
    const classes = useStyles()

    return (
        <>
            {!!props.visible && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress
                        color="inherit"
                        thickness={props.thickness || 3}
                        style={{ color: 'black' }}
                    />
                </Grid>
            )}
        </>
    )
}

export default Loading
