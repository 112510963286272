import { useEffect, useState } from 'react'
import {
    Box,
    IconButton,
    InputAdornment,
    makeStyles,
} from '@material-ui/core'
import TextField from '@mui/material/TextField'
import cn from 'classnames'
import SearchIcon from 'assests/icons/search_icon.svg'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
})

export const SearchLabel = (props) => {
    const {
        onSearch,
        textSearch,
        clearText,
        searchIcon,
        placeholder,
    } = props
    const classes = useStyles()

    return (
        <Box>
            <TextField
                className={cn({
                    [classes.search_input]: true,
                    // [classes.show_search]: !openSearch,
                })}
                placeholder={placeholder || 'Search...'}
                onChange={(e) => onSearch(e.target.value)}
                value={textSearch}
                variant="outlined"
                InputProps={{
                    startAdornment: searchIcon ? (
                        <InputAdornment position="start">
                            <IconButton size="small">
                                <img
                                    src={SearchIcon}
                                    alt=""></img>
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        ''
                    ),
                    endAdornment: (
                        <InputAdornment position="start">
                            {textSearch && (
                                <IconButton
                                    size="small"
                                    onClick={clearText}>
                                    <CloseIcon
                                        style={{
                                            fontSize: 16,
                                        }}
                                    />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    classes: {
                        notchedOutline:
                            classes.notchedOutline,
                        root: classes.search_input_root,
                    },
                }}
            />
        </Box>
    )
}
