import React from 'react'
import { Box, Grid, Typography, TextField } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
// import { MedicalProblems } from 'containers/PatientsSetting/demographic/MedicalProblems'
// import { FamilyPsychiatricHistory } from 'containers/PatientsSetting/demographic/history/FamilyPsychiatricHistory'
// import { PsychiatricMedicationHistory } from 'containers/PatientsSetting/demographic/history/PsychiatricMedicationHistory'
import { SocialHistory } from 'containers/PatientsSetting/demographic/history/SocialHistory'

import PsychiatricMedicationHistory from 'containers/PatientsSetting/view/PsychiatricMedicationHistory'
import MedicalProblems from 'containers/PatientsSetting/view/MedicalProblems'
import FamilyPsychiatricHistory from 'containers/PatientsSetting/view/FamilyPsychiatricHistory'
import SocialHistoryView from 'containers/PatientsSetting/view/SocialHistoryView'
// import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    itemCard: {
        height: 500,
        marginTop: 12,
    },
    subLabel: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    sectionHeading: {
        marginRight: 16,
        fontSize: 14,
    },
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },
})

const mapOptions = (data = []) => {
    return data.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const PastHistory = ({
    fieldName,
    master,
    values,
    onChange,
    patientId,
    encounter_id,
    lastNarrative,
    handleSetViewHealthyRecord,
    macros,
    copyValues,
    copyAble,
    nonEditable = false
}) => {
    const classes = useStyles()
    const classesCard = usePatientDashboardStyles()

    const handleCheckChange = (e) => {
        const { name, checked } = e.target
        onChange(name, checked)
    }
    const handleSelectMacro = (value) => { 
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()
            //narrative
            let newNarrative = ''
            if (values?.narrative === undefined) {
                newNarrative = copyValues?.narrative
            } else {
                newNarrative = values.narrative.concat(` ${copyValues?.narrative}`)
            }
            //past_history
            let newPastHistory = ''
            if (values?.past_history === undefined) {
                newPastHistory = copyValues?.past_history
            } else {
                newPastHistory = values.past_history.concat(` ${copyValues?.past_history}`)
            }
            //medication_problems
            let newMedicalProblem = ''
            if (values?.medication_problems === undefined) {
                newMedicalProblem = copyValues?.medication_problems
            } else {
                newMedicalProblem = values.medication_problems.concat(` ${copyValues?.medication_problems}`)
            }
            //family_history
            let newFamilyHistory= ''
            if (values?.family_history === undefined) {
                newFamilyHistory = copyValues?.family_history
            } else {
                newFamilyHistory = values.family_history.concat(` ${copyValues?.family_history}`)
            }
            //social_history
            let newSocialHistory= ''
            if (values?.social_history === undefined) {
                newSocialHistory = copyValues?.social_history
            } else {
                newSocialHistory = values.social_history.concat(` ${copyValues?.social_history}`)
            }
            const finalValues = {
                narrative: newNarrative,
                past_history: newPastHistory,
                medication_problems: newMedicalProblem,
                family_history: newFamilyHistory,
                social_history: newSocialHistory,
                capture_intake: copyValues?.capture_intake,
                capture_intake_excep: copyValues?.capture_intake_excep,
            }
            
            if ( copyValues?.narrative === "" && copyValues?.past_history === "" && copyValues?.medication_problems === "" && copyValues?.family_history === "" && copyValues?.social_history === "" 
                 && copyValues?.capture_intake_excep === "" && copyValues?.capture_intake ==="") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 20,
            }}>
            {/* {!nonEditable &&
            <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />} */}
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            <Box>
                <CheckboxOption
                    name={`${fieldName}.capture_intake`}
                    option={{
                        label: 'Past, Family, and Social History remain unchanged as captured on intake and in former session documents.',
                        value: false,
                    }}
                    disabled={nonEditable}
                    onChange={handleCheckChange}
                    checked={values?.capture_intake}
                />
                <CheckboxOption
                    name={`${fieldName}.capture_intake_excep`}
                    option={{
                        label: 'Past, Family, and Social History remain unchanged as captured on intake and former session documents, with the following exceptions:',
                        value: false,
                    }}
                    disabled={nonEditable}
                    onChange={handleCheckChange}
                    checked={values?.capture_intake_excep}
                />
            </Box>
            <Grid container justifyContent="space-between" spacing={2}>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '24px',
                    }}>
                    <Grid item>
                        <PsychiatricMedicationHistory
                            isAddingFromClinicalNote
                            isInClinicalNote
                            patientId={patientId}
                            encounter_id={encounter_id}
                            disabled={nonEditable}
                        />
                    </Grid>

                    <TextField
                        multiline
                        minRows={4}
                        placeholder="Text area"
                        name={`${fieldName}.past_history`}
                        variant="outlined"
                        className={classes.textFieldWrap}
                        size="small"
                        value={values?.past_history || ''}
                        disabled={nonEditable}
                        onChange={(e) => {
                            const { name, value } = e.target
                            onChange(name, value)
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '24px',
                    }}>
                    <Grid item>
                        <MedicalProblems
                            isInClinicalNote
                            patient_Id={patientId}
                            encounter_id={encounter_id}
                            isAddingFromClinicalNote
                            nonEditable={nonEditable}
                        />
                    </Grid>

                    <TextField
                        multiline
                        minRows={4}
                        placeholder="Text area"
                        name={`${fieldName}.medication_problems`}
                        variant="outlined"
                        className={classes.textFieldWrap}
                        size="small"
                        disabled={nonEditable}
                        value={values?.medication_problems || ''}
                        onChange={(e) => {
                            const { name, value } = e.target
                            onChange(name, value)
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '24px',
                    }}>
                    <Grid item>
                        <FamilyPsychiatricHistory
                            isInClinicalNote
                            patient_Id={patientId}
                            encounter_id={encounter_id}
                            nonEditable={nonEditable}
                        />
                    </Grid>

                    <TextField
                        multiline
                        minRows={4}
                        placeholder="Text area"
                        variant="outlined"
                        name={`${fieldName}.family_history`}
                        className={classes.textFieldWrap}
                        size="small"
                        value={values?.family_history || ''}
                        disabled={nonEditable}
                        onChange={(e) => {
                            const { name, value } = e.target
                            onChange(name, value)
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        marginBottom: '24px',
                    }}>
                    <Grid item>
                        <SocialHistoryView
                            isInClinicalNote
                            patient_Id={patientId}
                            encounter_id={encounter_id}
                            nonEditable={nonEditable}
                        />
                    </Grid>
                    <TextField
                        multiline
                        minRows={4}
                        placeholder="Text area"
                        variant="outlined"
                        name={`${fieldName}.social_history`}
                        className={classes.textFieldWrap}
                        size="small"
                        value={values?.social_history || ''}
                        disabled={nonEditable}
                        onChange={(e) => {
                            const { name, value } = e.target
                            onChange(name, value)
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <NarrativeAndMacro
                    hideClearBtn={true}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    onChange={onChange}
                    values={values}
                    narrativeValue={values.narrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box>
    )
}

export default PastHistory
