import React, { useEffect, useState } from 'react'
import { Typography, Grid, FormGroup, FormControlLabel, Checkbox, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { genrateButtonIdentification } from 'containers/ClinicalNotes/utils'

const useStyles = makeStyles({
    actioneGrid: {
        marginBottom: 20,
    },
    header: {
        color: '#303E4E',
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 8,
    },
})

const ClinicalGlobal = ({
    master,
    values,
    onChange,
    macros,
    read_only,
    onClear,
    initialValue,
    fieldName,
    nonEditable
}) => {
    const classes = useStyles()

    function generateNarations(macro) {
        const text = master.find((c) => c?._id === values.client_condition)?.label.toLowerCase()

        const finalStatement = `Comparing their current condition versus that at intake, this patient's condition is ${text?.trim()} the initiation of treatment.`
        const newNarrative = genrateButtonIdentification(finalStatement, values?.narrative)
        onChange('clinical_global_impression.narrative', `${newNarrative}`)
        // onChange('clinical_global_impression.narrative', `${finalStatement} \n${macro}`)
    }
    function handleChanges(name, value) {
        onChange(name, value)
    }
    return (
        <Grid container>
            <Grid className={classes.actioneGrid} container justifyContent="space-between">
                <Grid item xs={12}>
                    <Typography className={classes.header}>
                        Compared to the client’s condition at intake, this client’s condition is:
                    </Typography>

                    <FormGroup>
                        {master?.map((i, key) => (
                            <FormControlLabel
                                key={key}
                                label={i.name}
                                name={i.name}
                                value={i._id}
                                control={
                                    <Radio
                                        checked={i._id === values.client_condition}
                                        color="primary"
                                        onChange={(event) =>
                                            onChange(
                                                'clinical_global_impression.client_condition',
                                                event.target.value,
                                            )
                                        }
                                        disabled={read_only || nonEditable}
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generateNarations}
                    onChange={handleChanges}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generateNarations}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default ClinicalGlobal
