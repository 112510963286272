import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { Pagination } from 'shared/elements/Pagination'
import { NoRowsOverlayEl } from 'shared/elements'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'

import { useStyles } from './style'


export const AuditLogs = () => {
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'type',
      sort: 'desc',
    },
  ])
  const [paginationData] = useState({
    total: 0,
    start: 1,
    limit: 10,
  })

  const columns = [
    {
      headerName: (
        <Typography variant="body1">
          Action
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
      field: 'name',
      flex: 1.5,
      sortable: true,
      renderCell: ({ value, row }) => {
        return value ? (
          <Typography
            style={{ color: '#5571C6' }}>
            {value}
          </Typography>
        ) : (
          <Typography
            style={{ color: '#D3D8E0' }}>
            N/A
          </Typography>
        )
        // return (
        //     value.charAt(0).toUpperCase() +
        //     value.slice(1)
        // )
      },
    },
    {
      headerName: (
        <Typography variant="body1">
          User
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
      field: 'code',
      flex: 1,
      sortable: true,
      renderCell: ({ value, row }) => {
        // return value
        //     ? moment
        //         .utc(`${value} ${row.time}`)
        //         .local()
        //         .format('MM/DD/YYYY')
        //     : '01 Jan 2022'
        return (
          value || (
            <Typography
              style={{ color: '#D3D8E0' }}>
              N/A
            </Typography>
          )
        )
      },
    },

    {
      headerName: (
        <Typography variant="body1">
          Date
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
      field: 'color1',
      flex: 1,
      sortable: true,
      renderCell: ({ value }) => {
        return (
          value || (
            <Typography
              style={{ color: '#D3D8E0' }}>
              N/A
            </Typography>
          )
        )
      },
    },
    {
      headerName: (
        <Typography variant="body1">
          Description
        </Typography>
      ),
      headerClassName: 'super-app-theme--header',
      field: 'description',
      // width: 100,
      flex: 3,
      sortable: true,
      renderCell: ({ value }) => {
        return (
          value || (
            <Typography
              style={{ color: '#D3D8E0' }}>
              N/A
            </Typography>
          )
        )
      },
      // renderActions(params, { handleEditClick }),
    },
  ]

  useEffect(() => {
  }, [page])

  return (
    <Grid
      item
      xs={12}>
      <Box
        sx={{
          '& .super-app-theme--header':
          {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator':
            {
              visibility:
                'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle':
            {
              '& .MuiTypography-body1':
              {
                display:
                  'flex',
                alignItems:
                  'center',
                color: '#303E4E',
                fontWeight: 500,
                fontSize:
                  '14px',
              },
            },
          },
          '& .MuiDataGrid-columnHeaders':
          {
            border: 'none',
          },
          '& .MuiCircularProgress-root':
          {
            zIndex: 1,
          },
          '& .MuiPaginationItem-previousNext':
          {
            padding: '0 22px',
          },
          '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within':
          {
            outline:
              'none !important',
          },
          '& .MuiPaginationItem-root.Mui-selected':
          {
            borderRadius: '5px',
            background:
              '#667689',
            color: 'white',
            border: 'none',
          },
          '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 360px)',
          },
          '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
          },
          '& .MuiDataGrid-root .MuiDataGrid-columnHeader':
          {
            border: 'none',
            outline:
              'none !important',
          },

          '& .MuiDataGrid-footerContainer':
          {
            border: 'none',
          },
          '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            // cursor: 'pointer',
            margin: '4px 0',
            borderRadius: '8px',
          },
          '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
          {
            width: 'auto',
            visibility:
              'visible',
          },
          '& .MuiDataGrid-virtualScroller':
          {
            overflowX: 'hidden',
            overflowY: 'auto',
          },
        }}>
        <DataGrid
          getRowId={(row) => row.id}
          sortModel={sortModel}
          onSortModelChange={(
            newSortModel,
          ) =>
            setSortModel(
              newSortModel,
            )
          }
          rows={[]}
          columns={columns}
          pageSize={10}
          // rowsPerPageOptions={[10]}
          paginationMode="server"
          disableColumnSelector={true}
          disableSelectionOnClick
          loading={loading}
          rowCount={
            paginationData.total
          }
          pagination
          components={{
            NoRowsOverlay: () => (
              <NoRowsOverlayEl />
            ),
            Pagination: () =>
              <Pagination totalRecords={paginationData.total}
                onPageChange={setPage} />,
            ColumnUnsortedIcon:
              TeleIconUpDownSortedList,
            ColumnSortedAscendingIcon:
              KeyboardArrowUpIcon,
            ColumnSortedDescendingIcon:
              KeyboardArrowDownIcon,
          }}
          disableColumnMenu={true}
        // onRowClick={handleRowClick}
        />
      </Box>
    </Grid>
  )
}
