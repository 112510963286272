import React from 'react'
import { styled } from '@mui/material/styles'
import MatTooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

export const Tooltip = styled(
  ({ className, ...props }: TooltipProps) =>
    <MatTooltip {...props} classes={{ popper: className }} />
)(
  ({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white, // 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: 8
  },
}))
