import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'

import { ZipCode } from 'shared/elements/FormItem/Input'
import { ErrorType } from 'containers/PatientsSetting/interfaces'
import { ModalEl as Modal } from 'shared/elements'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'

import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import { createInsurancePlan } from 'services/Patient/patient'
import '../../styles.scss'
import { INITIAL_PLAN_VALUES } from './seed'
import { IMaskInput } from 'react-imask'

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <IMaskInput
            {...other}
            mask="(#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    )
})

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
})

export const InsurancePlanForm = (props) => {
    const classes = useStyles()
    const notification = useNotification()
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (payload) => {
        setIsSubmitting(true)

        try {
            await createInsurancePlan(payload)
            const msg = `Insurance Plan created successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')

            props.onClose && props.onClose(true)
            props.refreshPlanOptions && props.refreshPlanOptions()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                error?.data?.message || `Insurance Plan created unsuccessfully`
            notification(errMsg, 'error')
        }
    }

    const validationSchema = yup.object({
        company_name: yup.string().required('Enter Company name.'),
        plan_name: yup.string().required('Enter Plan Name'),
        phone_number: yup.string().required('Enter Phone number'),
        address1: yup.string().required('Enter Address 1'),
        address2: yup.string(),
        city: yup.string().required('Enter City'),
        state: yup.string().required('Enter State'),
        zip: yup.string().required('Enter Zip Code'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: INITIAL_PLAN_VALUES,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            title="Add Insurance Plan"
            width={800}
            description={'Add Insurance Plan'}
            open={props.open}
            onClose={props.onClose}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="insurance-plan-modal">
            <Grid container className="insurance-plan-form">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginTop: 24, marginBottom: 16 }}>
                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Insurance Company"
                                    required
                                    error={
                                        formik.touched.company_name &&
                                        Boolean(formik.errors.company_name)
                                    }
                                    helperText={
                                        formik.touched.company_name && formik.errors.company_name
                                    }
                                    value={formik.values.company_name}
                                    onChange={(e) => formik.setFieldValue('company_name', e)}
                                    placeholder="Enter company name"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="Plan Name"
                                    required
                                    value={formik.values.plan_name}
                                    onChange={(e) => formik.setFieldValue('plan_name', e)}
                                    error={
                                        formik.touched.plan_name && Boolean(formik.errors.plan_name)
                                    }
                                    helperText={formik.touched.plan_name && formik.errors.plan_name}
                                    placeholder="Enter plan name"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="Address line 1"
                                    required
                                    value={formik.values.address1}
                                    onChange={(e) => formik.setFieldValue('address1', e)}
                                    error={
                                        formik.touched.address1 && Boolean(formik.errors.address1)
                                    }
                                    helperText={formik.touched.address1 && formik.errors.address1}
                                    placeholder="Enter address line 1"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="Address line 2"
                                    value={formik.values.address2}
                                    onChange={(e) => formik.setFieldValue('address2', e)}
                                    placeholder="Enter address line 2"
                                />
                            </Grid>

                            <Grid container item spacing={2}>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="City"
                                        required
                                        value={formik.values.city}
                                        onChange={(e) => formik.setFieldValue('city', e)}
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={formik.touched.city && formik.errors.city}
                                        placeholder="Enter City"
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <Select
                                        label="State"
                                        required
                                        placeholder="Select state"
                                        error={formik.touched.state && Boolean(formik.errors.state)}
                                        helperText={formik.touched.state && formik.errors.state}
                                        options={stateList.map((item) => ({
                                            key: item.state,
                                            value: item.stateLabel,
                                        }))}
                                        value={formik.values.state}
                                        onChange={(e) => formik.setFieldValue('state', e)}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <ZipCode
                                        label="Zip Code"
                                        required
                                        format={9}
                                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                                        helperText={formik.touched.zip && formik.errors.zip}
                                        value={formik.values.zip}
                                        onChange={(e) => formik.setFieldValue('zip', e)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    required
                                    label="Phone"
                                    error={
                                        formik.touched.phone_number &&
                                        Boolean(formik.errors.phone_number)
                                    }
                                    helperText={
                                        formik.touched.phone_number && formik.errors.phone_number
                                    }
                                    value={formik.values.phone_number}
                                    onChange={(e) => formik.setFieldValue('phone_number', e)}
                                    placeholder="Enter phone number"
                                    InputProps={{
                                        inputComponent: TextMaskCustom,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Footer
                            loading={isSubmitting}
                            onCancel={props.onClose}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
