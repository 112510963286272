import {
    Box,
    Button,
    DialogActions,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getRoleBaseSecurity, putRoleBaseSecurity } from '../../services/Calendar/index'
import AntSwitch from '../../components/shared/Elements/AntSwitch'
import { useLocation } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import LoadingPage from '../../utilities/loading-page'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import AlertCancelDialog from 'containers/RolesManagement/AlertCancelDialog'
import { getPermissionByRole } from 'store/actions/permission'

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
        overflow: 'hidden overlay',
        height: '100%',
        paddingBottom: '100px',
        flex: 1,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
        padding: '15px',
        '&:first-letter': {
            'text-transform': 'capitalize',
        },
    },
    form_list_container: {
        marginTop: 16,
    },
    btn_cancel: {
        background: '#667689',
        borderRadius: '16px',
        width: '130px',
        height: '40px',
    },
    btn_save: {
        background: '#5571C6',
        borderRadius: '16px',
        width: '130px',
        height: '40px',
    },
    item_note: {
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const SecurityRolePermissions = () => {
    const classes = useStyles()
    const location = useLocation()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [isLoading, setLoading] = useState(false)
    const [alertCancelOpen, setAlertCancelOpen] = useState(false)
    const [isLoadingSave, setisLoadingSave] = useState(false)
    const [securityRolePermissions, setSecurityRolePermissions] = useState([])

    const handleGetRoleBaseSecurity = async () => {
        setLoading(true)
        try {
            const res = await getRoleBaseSecurity(location?.state?.id)
            setLoading(false)
            setSecurityRolePermissions(res.data?.data || [])
        } catch (e) {
            setLoading(false)
        }
    }
    const dispatch = useDispatch()

    const roles = useSelector((state) => state?.permission?.roles)

    useEffect(() => {
        handleGetRoleBaseSecurity()
    }, [])

    const goBack = () => history.goBack()

    const handleSave = async () => {
        setisLoadingSave(true)
        const payload = {
            data: securityRolePermissions,
        }
        try {
            const res = await putRoleBaseSecurity({
                id: location?.state?.id,
                payload,
            })
            dispatch(getPermissionByRole())
            enqueueSnackbar('Permission save successfully!', { variant: 'success' })
            setisLoadingSave(false)
        } catch (e) {
            enqueueSnackbar(e || 'Permission does not save successfully!', { variant: 'error' })
            setisLoadingSave(false)
        }
    }

    const onChange = (id) => {
        setSecurityRolePermissions((prev) =>
            prev.map((item) =>
                item?.permissions
                    ? {
                          ...item,
                          permissions: item?.permissions.map((i) =>
                              i.id === id
                                  ? {
                                        ...i,
                                        is_allow: !i.is_allow,
                                    }
                                  : i,
                          ),
                      }
                    : item,
            ),
        )
    }

    const Item = ({ permission_name, is_allow, id }) => {
        const permissionName = permission_name.replace(/_/g, ' ')
        return (
            <Grid container className={classes.item_note}>
                <Typography
                    variant="body1"
                    style={{
                        fontSize: '14px',
                        width: '80%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        padding: '10px',
                    }}>
                    {permissionName}
                </Typography>
                <AntSwitch
                    // disabled={require}
                    onChange={() => onChange(id)}
                    defaultChecked
                    checked={is_allow}
                    inputProps={{
                        'aria-label': 'ant design',
                    }}
                />
            </Grid>
        )
    }

    const renderHeaderSection = (item, index) => {
        const resources = item.resources.replace(/_/g, ' ')
        return (
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                // style={{ flex: 1 }}
                className={classes.warpViewContainer}>
                <Grid
                    container
                    justifyContent="space-between"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '0px',
                        background: '#E9ECF1',
                        borderRadius: '8px 8px 0px 0px',
                    }}>
                    <Typography className={classes.header}>{resources}</Typography>
                </Grid>

                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        gap: '10px',
                        marginBottom: '24px',
                        justifyContent: 'space-between',
                    }}
                    className={classes.warpViewContainer}>
                    {item?.permissions?.map((permission, idx) => {
                        return (
                            <Grid
                                key={idx}
                                item
                                style={{
                                    height: '50px',
                                    background: '#FBFCFC',
                                    borderRadius: '8px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    maxWidth: '22%',
                                }}
                                container
                                direction="column">
                                <Grid container justifyContent="space-between">
                                    {Item(permission)}
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        )
    }

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>
                            {roles.find((item) => item.id === location?.state?.id)?.name ||
                                'Security Role Permissions'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.form_list_container}>
                    <Box
                        sx={{
                            '& .super-app-theme--header': {
                                border: 'none',
                                '& > .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    '& .MuiTypography-body1': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#303E4E',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                    },
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                border: 'none',
                            },
                            '& .MuiCircularProgress-root': {
                                zIndex: 1,
                            },
                            '& .MuiPaginationItem-previousNext': {
                                padding: '0 22px',
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                outline: 'none !important',
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                borderRadius: '5px',
                                background: '#667689',
                                color: 'white',
                                border: 'none',
                            },
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                height: 'calc(100vh - 184px)',
                            },
                            '& .MuiDataGrid-cell': {
                                border: 'none !important',
                                color: '#667689',
                                // background: 'red'
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                                border: 'none',
                                outline: 'none !important',
                            },

                            '& .MuiDataGrid-footerContainer': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-row': {
                                background: '#FAFAFA',
                                margin: '4px 0',
                                borderRadius: '8px',
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                                {
                                    width: 'auto',
                                    visibility: 'visible',
                                },
                        }}></Box>
                </Grid>
                {securityRolePermissions?.map((item, index) => {
                    return renderHeaderSection(item)
                })}
                <DialogActions
                    style={{
                        width: '100%',
                        position: 'fixed',
                        bottom: '0',
                        background: 'white',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        right: '0',
                    }}>
                    <Button
                        onClick={() => setAlertCancelOpen(true)}
                        variant="text"
                        className={classes.btn_cancel}>
                        <Typography style={{ color: 'white' }}>Cancel</Typography>
                    </Button>
                    <Box
                        sx={{
                            '& .Mui-disabled': {
                                background: '#0000001f !important',
                            },
                        }}>
                        <LoadingButton
                            size="small"
                            onClick={handleSave}
                            className={classes.btn_save}
                            // endIcon={<SendIcon />}
                            loading={isLoadingSave}
                            loadingPosition="end"
                            variant="contained">
                            <Typography
                                style={{
                                    color: 'white',
                                }}>
                                Save
                            </Typography>
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </Grid>
            {isLoading && <LoadingPage />}
            {alertCancelOpen && (
                <AlertCancelDialog
                    open={alertCancelOpen}
                    handleClose={() => {
                        setAlertCancelOpen(false)
                    }}
                    handleDelete={() => {
                        goBack()
                        setAlertCancelOpen(false)
                    }}
                />
            )}
        </>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(SecurityRolePermissions)
