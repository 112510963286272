import * as actionTypes from '../actionType'

const initialState = {
    appointment: {
        id: '',
        values: null,
    },
    search_values: null,
    search_result: {
        data: [],
        pagination: {
            page: 1,
            limit: 20,
            total: 0,
        },
    },
    isRescedulePopup: {},
    providerLocation:[]
}

const schedule = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SCHEDULE_CHANGE_APPOINTMENT: {
            return {
                ...state,
                appointment: action.value,
            }
        }

        case actionTypes.SCHEDULE_CHANGE_SEARCH_VALUES: {
            return {
                ...state,
                search_values: action.value,
            }
        }

        case actionTypes.SCHEDULE_CHANGE_SEARCH_RESULT: {
            return {
                ...state,
                search_result: action.value,
            }
        }

        case actionTypes.IS_RESCHEDULE_POPUP: {
            return {
                ...state,
                isRescedulePopup: {
                    ...state.isRescedulePopup,
                    [action.appointment_id]: action.value
                }
            }
        }

        case actionTypes.PROVIDER_LOCATION:{
            return {
                ...state,
                providerLocation: action.value
            }
        }

        case actionTypes.SCHEDULE_RESET_STATE: {
            return initialState
        }

        default:
            return state
    }
}

export default schedule
