import {
    getService,
    postService,
} from '../../utilities/request/restClient';

export const getConsultationTracking = () =>
    getService(`/providers/get-live-consultation`);

export const markAppointmentStatus = (payload) =>
    postService(`/appointment/consultationClose`, payload);
