import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
    makeStyles,
    Typography,
    Button,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    TextareaAutosize,
} from '@material-ui/core'
import AntSwitch from './AntSwitch'
import { SelectEl } from '../../../shared/elements'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 14,
        fontWeight: 400,
        marginTop: 24,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_cancel: {
        background: '#303E4E',
        width: '230px',
        fontSize: 15,
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_delete: {
        fontSize: 15,
        width: '230px',
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_comfirm: {
        fontSize: 15,
        width: '460px',
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_encounter: {
        fontSize: 15,
        width: '460px',
        borderRadius: 8,
        background: '#5571c6',
        '&:hover': {
            background: '#5571c6',
            opacity: 0.7,
        },
    },
    dialog_content: {
        width: '-webkit-fill-available',
    },
    avatar: {
        width: 45,
        height: 45,
        borderRadius: 45,
        background: 'gray',
        margin: 8,
    },
    information: {
        marginLeft: 16,
    },
    name: {
        color: '#303E4E',
        fontSize: 16,
    },
    relationship: {
        color: '#9CA9B8',
        fontSize: 13,
    },
    iconReques: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    },
    viewInfomation: {
        background: '#F5F6F7',
        borderRadius: 12,
    },
    textArea: {
        color: '#667689',
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        background: '#FBFBFB',
        border: '1px solid #E9ECF1',
        borderRadius: '12px',
        height: '60px !important',
        width: '95%',
        flex: '1',
        resize: 'none',
        fontSize: '14px',
        outline: 'none',
        position: 'relative',
        marginRight: '10px',
        fontFamily: 'Rubik',
    },
}))
const radioOptions = [
    { label: 'Completed', value: 'completed' },
    { label: 'No show', value: 'No' },
]
const selectOptions = [
    { key: 'Completed', value: 'completed' },
    { key: 'No show', value: 'No' },
]

export default function AssistantStatusDialog({
    title,
    open,
    handleClose,
    handleConfirm,
    icon,
    infoAssign,
    showProviderAssgin,
    openAssignSuccess,
    openAssignFaile,
    handleOpentEndEcounter,
    handleConfirmEndEncouter,
    handleChange,
    textError,
    messageBody,
}) {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <div className={classes.iconReques}>
                    {icon && icon}
                </div>
                <Typography className={classes.header}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent
                className={classes.dialog_content}>
                <Grid
                    container
                    style={{ width: '100%' }}
                    direction="column">
                    {showProviderAssgin && (
                        <>
                            <Grid
                                container
                                className={
                                    classes.viewInfomation
                                }
                                alignItems="center">
                                <img
                                    src={
                                        'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                    }
                                    alt="Profile"
                                    className={
                                        classes.avatar
                                    }
                                />
                                <div
                                    className={
                                        classes.information
                                    }>
                                    <Typography
                                        className={
                                            classes.name
                                        }>
                                        {`${infoAssign?.first_name} ${infoAssign?.last_name}`}
                                    </Typography>
                                </div>
                            </Grid>
                            <Typography
                                className={
                                    classes.description
                                }
                                style={{
                                    marginBottom: 16,
                                }}>
                                <TextareaAutosize
                                    minRows={3}
                                    maxRows={3}
                                    className={
                                        classes.textArea
                                    }
                                    aria-label="chat input"
                                    placeholder="Send message"
                                    // onKeyPress={handleReturnKeyPress}
                                    onChange={handleChange}
                                    value={messageBody}
                                    // data-cy-chat-input
                                    // ref={textInputRef}
                                />
                            </Typography>
                        </>
                    )}
                    {openAssignFaile && (
                        <Typography
                            className={classes.description}
                            style={{ marginBottom: 16 }}>
                            {textError}
                        </Typography>
                    )}
                </Grid>
            </DialogContent>
            {showProviderAssgin && (
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        className={classes.btn_cancel}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        autoFocus
                        variant="contained"
                        className={classes.btn_delete}>
                        Confirm
                    </Button>
                </DialogActions>
            )}

            {!openAssignSuccess && openAssignFaile && (
                <DialogActions>
                    <Button
                        onClick={handleOpentEndEcounter}
                        autoFocus
                        variant="contained"
                        className={classes.btn_comfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            )}

            {openAssignSuccess && !openAssignFaile && (
                <DialogActions>
                    <Button
                        onClick={handleConfirmEndEncouter}
                        autoFocus
                        variant="contained"
                        className={classes.btn_encounter}>
                        OK
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    )
}
