import React, { useState, useEffect } from 'react'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { cloneDeep } from 'lodash'
import {
	Box,
	Grid,
	TableCell,
	TableRow,
	TextField,
	Typography,
	IconButton
} from '@material-ui/core'

import DeleteIcon from 'assests/icons/DeleteIcon'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'

import { SearchCPTCode } from './SearchCPTCode'
import { CPTCode } from '../interfaces'


interface Props {
	rows: []
	errors: { [code: string]: string }
	onChange: (value: CPTCode[], intialize?: boolean) => void
}

export const CptCodeSelectors = ({
	rows = [],
	errors,
	onChange,
}: Props) => {
	const [data, setData] = useState<CPTCode[]>([])
	const [alertDeleteOpen, setAlertDeleteOpen] =
		useState<{
			open: boolean,
			itemId?: number | null
		}>({ open: false, itemId: null })

	useEffect(() => {
		if (rows.length) setData(cloneDeep(rows))
		else {
			setData([
				{
					code: '',
					description: '',
				},
			])

			onChange(cloneDeep(data), true)
		}
	}, [rows])

	const handleInputChange = (
		{ code, description }: CPTCode,
		index: number,
	) => {
		const newState = data.map((row, i) => {
			if (i === index)
				return {
					...row,
					code,
					description,
				}

			return row
		})

		setData(newState)
		onChange(cloneDeep(newState))
	}

	const handleAddOrRemove = (action: string, index?: number) => {
		let newState
		if (action === 'add') {
			newState = [
				...data,
				{
					code: '',
					description: '',
				},
			]
		} else {
			newState = data.filter((_, i) => i !== index)
		}

		setData(newState)
		onChange(cloneDeep(newState), action === 'add')
	}

	return (
		<>
			{data.map((r, index) => {
				return (
					<TableRow key={index}>
						<TableCell
							align="left"
							component="th"
							scope="row">
							<Box
								sx={{
									display: 'flex',
									gap: 5,
								}}>
								<SearchCPTCode
									r={r}
									index={index}
									onChange={
										handleInputChange
									}
								/>
							</Box>
							{errors[index] && (
								<p className="helper-text margin-0">
									{errors[index]}
								</p>
							)}
						</TableCell>

						<TableCell
							align="left"
							component="th"
							scope="row">
							<TextField
								name='description'
								placeholder='Description'
								disabled
								value={r.description}
							/>
						</TableCell>

						<Box
							sx={{
								display: 'flex',
								width: 72,
								justifyContent: 'center',
								height: 52,
							}}>
							{rows.length >= 1 && (
								<IconButton
									aria-label='Delete'
									color='primary'
									onClick={() => {
										setAlertDeleteOpen({ open: true, itemId: index })
									}}>
									<DeleteIcon />
								</IconButton>
							)}

						</Box>
					</TableRow>
				)
			})}
			<Grid
				container
				justifyContent="flex-end"
				style={{ display: 'flex' }}>
				<span
					// className={classes.cover__add__new}
					style={{
						display: 'flex',
						cursor: 'pointer',
						width: 'fit-content'
					}}
					onClick={() =>
						handleAddOrRemove('add')
					}>
					<AddCircleOutlineOutlined
						style={{
							color: '#14AE5C',
							fontSize: 18,
						}}
					/>

					<Typography
						variant="body1"
						style={{
							color: '#14AE5C',
							marginLeft: 8,
						}}>
						Add code
					</Typography>
				</span>
			</Grid>

			<AlertDeleteDialog
				loading={false}
				open={alertDeleteOpen.open}
				handleClose={() =>
					setAlertDeleteOpen({ open: false, itemId: null })
				}
				handleDelete={() => {
					handleAddOrRemove('remove', alertDeleteOpen.itemId as number)
					setAlertDeleteOpen({ open: false, itemId: null })
				}}
			/>
		</>
	)
}
