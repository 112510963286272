import { Box, Button, Grid, Tab, Tabs, Typography, styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { MEDICAL_INITIAL_CLINICAL_NOTE } from 'constants/clinicalNote'
import { Lab, Prescribe } from 'containers/PatientsSetting/demographic/OrderEntry'
import React from 'react'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import copyNew from '../../../assests/icons/copyNew.svg'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import Divider from '@mui/material/Divider'
import { shortenString } from 'utilities/stringOperations'
import PreviousCN from '../reusable/PreviousCN'
import CheckboxOption from '../reusable/CheckboxOptions'
const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
    },
    first_box: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        // height: 'auto',
        // overflow: 'auto',
        padding: '10px',
    },
    second_box: {
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        padding: '10px',
        background: '#FFF',
    },
    copy_button: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        background: '#FFF',
        fontSize: '12px',
        color: '#303E4E',
        fontWeight: '400',
        height: '26px',
        marginRight: '8px',
        padding: '4px 8px',
    },
    copy_button_copied: {
        border: '1px solid #5571C6 !important'
    },
    box_type: {
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '30px 20px 10px 20px',
    },
    divider: {
        background: 'transparent',
        width: '100%',
        // height: '1px',
    },
    DividerText: {
        color: 'var(--Light-Foreground-Foreground-2, #424242)',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
})

const PlanTabs = styled(Tabs)({
    // borderBottom: '1px solid #e8e8e8',
    // margin: '0',
    '& .MuiTabs-indicator': {
        backgroundColor: '#5571C6',
        left: 0,
    },
    '& .MuiTabs-flexContainer': {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
    },
    '& .MuiTab-root': {
        minHeight: '32px !important',
    },
})

const PlanTab = styled(Tab)(({ theme }) => ({
    textTransform: 'unset !important',
    fontFamily: 'Rubik',
    fontSize: 14,
    backgroundColor: 'none',
    minWidth: 0,
    // minHeight: '400px',
    fontWeight: '400',
    marginRight: theme.spacing(1),
    color: '#303E4E',
    opacity: 1,
    flex: 1,
    '&:hover': {
        color: '#242424',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#242424',
    },
    '&.Mui-focusVisible': {},
    '&.MuiTab-root': {
        background: 'unset',
    },
}))

const Plan = (props) => {
    const classes = useStyles()
    const [tab, setTab] = React.useState(1)
    const [copyModalOpen, setCopyModalOpen] = React.useState(false)
    const [hasCopied, setHasCopied] = React.useState(false)

    const defaultCheckboxValues = {
        radiology: true,
        physical_therapy_recomendations: true,
        referrals: true,
        home_health: true,
        education: true,
        diet: true,
        gen_instructions: true,
    }

    const [checkboxValues, setCheckboxValues] = React.useState(defaultCheckboxValues)
    const [checkAllValue, setCheckAllValue] = React.useState(true)

    const handleOpenCopyModal = () => setCopyModalOpen(true)
    const handleCloseCopyModal = () => setCopyModalOpen(false)
    
    const handleCheckAllValue = (checkValue) => {
        setCheckAllValue(checkValue)

        if(checkValue){
            setCheckboxValues(defaultCheckboxValues)
        }else {
            const allFalseCheckboxValues = defaultCheckboxValues
            Object.keys(checkboxValues)?.forEach((checkBoxItem) => {
                allFalseCheckboxValues[checkBoxItem] = false
            })
            setCheckboxValues(allFalseCheckboxValues)
        }
    }

    const handleCheckboxValues = (fieldName, checkedValue) => {
        setCheckboxValues((state) => {
            const returnValue = {
                ...state,
                [fieldName]: checkedValue
            }

            //updating check all checkbox
            const anyFalseValue = Object.values(returnValue)?.includes(false)
            if(anyFalseValue){
                setCheckAllValue(() => {
                    return false
                })
            }else{
                setCheckAllValue(() => {
                    return true
                })
            }

            return returnValue
        })
    }
    

    const onChangeTab = (val) => {
        setTab(val)
    }

    const handleChangecopy = () => {
        const previousData = props?.previousNoteData?.plan,
        currentData = props?.values?.plan

        const final = {
            ...currentData,
            radiology: checkboxValues?.radiology ? previousData?.radiology : currentData?.radiology,
            physical_therapy_recomendations: checkboxValues?.physical_therapy_recomendations ? previousData?.physical_therapy_recomendations : currentData?.physical_therapy_recomendations,
            referrals: checkboxValues?.referrals ? previousData?.referrals : currentData?.referrals,
            home_health: checkboxValues?.home_health ? previousData?.home_health : currentData?.home_health,
            education: checkboxValues?.education ? previousData?.education : currentData?.education,
            diet: checkboxValues?.diet ? previousData?.diet : currentData?.diet,
            gen_instructions: checkboxValues?.gen_instructions ? previousData?.gen_instructions : currentData?.gen_instructions,
            narrative: previousData?.narrative
        }

        props?.onChange(`${props?.fieldName}.plan`, final)
        setCheckboxValues(defaultCheckboxValues)
        setCheckAllValue(true)
        setHasCopied(true)
        handleCloseCopyModal()
    }

    return (
        <Grid item className={classes.first_box}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="cetner"
                className={classes.second_box}>
                <Typography className={classes.narrative}>Plan</Typography>
                <Button
                    disabled={!props?.previousNoteData}
                    onClick={handleOpenCopyModal}
                    startIcon={<img src={copyNew} alt="" style={{ height: '9px', width: '7px' }} />}
                    className={`${classes.copy_button} ${hasCopied ? classes.copy_button_copied : ''}`}>
                    {' '}
                    {hasCopied ? 'Copied' : 'Copy'}: {props?.lastUpdateDate || '--/--/----'}
                </Button>
            </Grid>
            <Box className={classes.box_type}>
                <Grid
                    container
                    style={{
                        width: '100%',
                        minHeight: '59vh',
                        backgroundColor: 'white',
                    }}>
                    <Grid item md={2}>
                        <PlanTabs
                            variant="fullWidth"
                            orientation="vertical"
                            value={tab}
                            onChange={(event, newValue) => {
                                event.preventDefault()
                                onChangeTab(newValue)
                            }}
                            aria-label="basic tabs example">
                            <PlanTab label="Plan" value={1} />
                            <PlanTab label="eRX" value={2} />
                            <PlanTab label="Labs" value={3} />
                        </PlanTabs>
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        style={{
                            border: '1px solid #CFCFCF',
                            borderRadius: '5px',
                            padding: '20px',
                        }}>
                        {tab === 1 && <PlanForm classes={classes} {...props} />}
                        {tab === 2 && <Prescribe patientId={props?.patient.id} />}
                        {tab === 3 && <Lab patientId={props?.patient.id} />}
                    </Grid>
                </Grid>
            </Box>
            {copyModalOpen && (
                <PreviousCN
                    open={copyModalOpen}
                    handleClose={handleCloseCopyModal}
                    categoryName={'Plan'}
                    lastNarrative={props?.previousNoteData?.plan?.narrative}
                    lastUpdateDate={props?.lastUpdateDate}
                    handleChangecopy={handleChangecopy}
                    customCheckAllValue={checkAllValue}
                    customCheckAllHandler={handleCheckAllValue}
                >
                    <div style={{ 
                        border: '1px solid #CFCFCF',
                        borderRadius: '5px',
                        padding: '20px',
                        margin: 10
                        }}>
                        <PlanForm 
                            classes={classes} 
                            {...props} 
                            isCopy={true} 
                            handleCheckboxValues={handleCheckboxValues}
                            checkboxValues={checkboxValues}
                        />
                    </div>
                </PreviousCN>
            )}
        </Grid>
    )
}

export default Plan

const PlanForm = (props) => {
    const isCopy = props?.isCopy,
    previousNoteValues = props?.previousNoteData?.plan

    return (
        <Grid container item xs={12}>
            <Divider
                sx={{
                    '&::before': {
                        width: '1%', // Clear the default content
                    },
                    '&::after': {
                        width: '100%', // Clear the default content
                    },
                }}
                textAlign="left"
                flexItem
                className={props?.classes.divider}>
                <Typography className={props?.classes.DividerText}>Plan</Typography>
            </Divider>
            <Grid container>
                <Grid container item direction="row" spacing={2} alignItems="flex-start">
                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy &&<CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.radiology}
                                onChange={(e) => props?.handleCheckboxValues('radiology', e?.target?.checked)}
                            />}
                            label="Radiology"
                            name={`${props?.fieldName}.plan.radiology.selection`}
                            value={(isCopy ? previousNoteValues?.radiology?.selection : props?.values?.plan?.radiology?.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected.join(','), 60)
                            }}
                            style={{ height: 34 }}
                            multiple={true}
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.plan.radiology}
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.radiology.selection`,
                                    value,
                                )
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.radiology.comment`}
                            value={isCopy ? previousNoteValues?.radiology?.comment : props?.values?.plan?.radiology?.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(`${props?.fieldName}.plan.radiology.comment`, value)
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                    color: '#303E4E !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.physical_therapy_recomendations}
                                onChange={(e) => props?.handleCheckboxValues('physical_therapy_recomendations', e?.target?.checked)}
                            />}
                            label="Physical Therapy Recommendations"
                            name={`${props?.fieldName}.plan.physical_therapy_recomendations.selection`}
                            value={(isCopy ? previousNoteValues?.physical_therapy_recomendations?.selection : props?.values?.plan.physical_therapy_recomendations.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected, 60)
                            }}
                            style={{ height: 40 }}
                            options={
                                MEDICAL_INITIAL_CLINICAL_NOTE.plan.physical_therapy_recommendations
                            }
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.physical_therapy_recomendations.selection`,
                                    value,
                                )
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.physical_therapy_recomendations.comment`}
                            value={isCopy ? previousNoteValues?.physical_therapy_recomendations?.comment : props?.values?.plan.physical_therapy_recomendations.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.physical_therapy_recomendations.comment`,
                                    value,
                                )
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.referrals}
                                onChange={(e) => props?.handleCheckboxValues('referrals', e?.target?.checked)}
                            />}
                            name={`${props?.fieldName}.plan.referrals`}
                            value={isCopy ? previousNoteValues?.referrals : props?.values?.plan.referrals}
                            onChange={(value) => {
                                props.onChange(`${props?.fieldName}.plan.referrals`, value)
                            }}
                            label="Referrals"
                            placeholder="Comment"
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '100px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.home_health}
                                onChange={(e) => props?.handleCheckboxValues('home_health', e?.target?.checked)}
                            />}
                            label="Home Health"
                            name={`${props?.fieldName}.plan.home_health.selection`}
                            value={(isCopy ? previousNoteValues?.home_health?.selection : props?.values?.plan.home_health.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected.join(','), 60)
                            }}
                            style={{ height: 34 }}
                            multiple={true}
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.plan.home_health}
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.home_health.selection`,
                                    value,
                                )
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.home_health.comment`}
                            value={isCopy ? previousNoteValues?.home_health?.comment : props?.values?.plan.home_health.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.home_health.comment`,
                                    value,
                                )
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.education}
                                onChange={(e) => props?.handleCheckboxValues('education', e?.target?.checked)}
                            />}
                            label="Education"
                            name={`${props?.fieldName}.plan.education.selection`}
                            value={(isCopy ? previousNoteValues?.education?.selection : props?.values?.plan.education.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected, 60)
                            }}
                            style={{ height: 40 }}
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.plan.educations}
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.education.selection`,
                                    value,
                                )
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.education.comment`}
                            value={isCopy ? previousNoteValues?.education?.comment : props?.values?.plan.education.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(`${props?.fieldName}.plan.education.comment`, value)
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.diet}
                                onChange={(e) => props?.handleCheckboxValues('diet', e?.target?.checked)}
                            />}
                            label="Diet"
                            name={`${props?.fieldName}.plan.diet.selection`}
                            value={(isCopy ? previousNoteValues?.diet?.selection : props?.values?.plan.diet.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected, 60)
                            }}
                            style={{ height: 34 }}
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.plan.diet}
                            onChange={(value) => {
                                props.onChange(`${props?.fieldName}.plan.diet.selection`, value)
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.diet.comment`}
                            value={isCopy ? previousNoteValues?.diet?.comment : props?.values?.plan.diet.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(`${props?.fieldName}.plan.diet.comment`, value)
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            labelComponent={isCopy && <CheckboxOption 
                                option={{
                                    label: '',
                                    value: false
                                }}
                                checked={props?.checkboxValues?.gen_instructions}
                                onChange={(e) => props?.handleCheckboxValues('gen_instructions', e?.target?.checked)}
                            />}
                            label="General Instructions"
                            name={`${props?.fieldName}.plan.gen_instructions.selection`}
                            value={(isCopy ? previousNoteValues?.gen_instructions?.selection : props?.values?.plan.gen_instructions.selection) || []}
                            displayEmpty={true}
                            native={false}
                            renderValue={(selected) => {
                                if (selected?.length === 0 || !selected) {
                                    return (
                                        <Typography style={{ color: '#989CA8 !important' }}>
                                            Select
                                        </Typography>
                                    )
                                }
                                return shortenString(selected.join(','), 60)
                            }}
                            style={{ height: 40 }}
                            multiple={true}
                            options={MEDICAL_INITIAL_CLINICAL_NOTE.plan.general_instructions}
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.gen_instructions.selection`,
                                    value,
                                )
                            }}
                            disabled={isCopy}
                        />
                        <TextBox
                            name={`${props?.fieldName}.plan.gen_instructions.comment`}
                            value={isCopy ? previousNoteValues?.gen_instructions?.comment : props?.values?.plan.gen_instructions.comment}
                            placeholder="Comment"
                            onChange={(value) => {
                                props.onChange(
                                    `${props?.fieldName}.plan.gen_instructions.comment`,
                                    value,
                                )
                            }}
                            style={{ marginTop: 10 }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '50px !important',
                                },
                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                    fontSize: 14,
                                    color: '#989CA8 !important',
                                    fontWeight: 400,
                                    opacity: 1,
                                },
                            }}
                            disabled={isCopy}
                        />
                    </Grid>
                    { !isCopy && <Grid item xs={12}>
                        <TextWithMacrosNew
                            name={`${props?.fieldName}.plan.narrative`}
                            onChange={props?.onChange}
                            // // macros={macros}
                            isDisabled={props?.nonEditable}
                            rows={12}
                            withoutMacro={false}
                            values={props?.values?.plan?.narrative}
                            // placeholder={placeholder}
                        />
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}
