import * as actionTypes from '../actionType'

const initialState = {
    waitlist_values: null,
}

const waitlist = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_WAITLIST_VALUES: {
            return {
                ...state,
                waitlist_values: action.value,
            }
        }
        default:
            return state
    }
}

export default waitlist
