import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    OutlinedInput,
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Input,
    FormControlLabel,
    TextField,
    InputAdornment,
    SxProps,
    CircularProgress,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Placeholder from 'shared/elements/Placeholder'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import SearchIcon from '@mui/icons-material/Search'
import SearchIconLabel from 'assests/icons/SearchIconLabel'
// import { Input } from '@material-ui/core'
// import { makeStyles } from '@mui/material/styles'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

interface Props {
    sx?: SxProps
    options: {
        key: string | number
        value: string
    }[]
    value?:
    | {
        key: string | number
        value: string
    }[]
    | null

    label?: string
    handleChange: (option: { key: string | number; value: string }, checked: boolean) => void
    placeholder?: string
    placeholderSearch?: string
    style?: React.CSSProperties
    onOpen?: () => void
    loadingOptions?: boolean
    disable?: boolean
}

const useStyles = makeStyles(() => ({
    wrapSelectMuntil: {
        overflow: 'hidden',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    label: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#516983',
        lineHeight: '1.5rem',
        letterSpacing: '0.02em',
        textTransform: 'capitalize',
        marginBottom: '0',
    },
    placeholder: {
        top: '50%',
        left: '13px',
        transform: 'translate(0, -50%)',
        lineHeight: 'normal',
        color: '#c4c9d1',
        fontSize: '14px',
        '&.MuiInputLabel-shrink': {
            color: '#c4c9d1',
        },
    },
    formControl: {
        '& .MuiSelect-select': {
            minHeight: 'auto !important',
        },
        '& .MuiInputBase-root': {
            minHeight: 'auto !important',
            height: '32px',
        },
    },
    box_options: {
        padding: '15px 0',
        '& *': {
            boxSizing: 'border-box',
        },
    },
    list_options: {
        maxHeight: '400px',
        overflowY: 'auto',
    },
    box_option: {
        padding: '5px 15px',
        transition: 'all 0.1s',
        '&:hover': {
            background: 'aliceblue',
        },
        '& .MuiFormControlLabel-root': {
            margin: '0 !important',
            width: '100%',
            '& .MuiCheckbox-root': {
                paddingRight: '10px',
            },
            '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                color: '#5571c',
            },
        },
    },
    input_search: {
        padding: '0 15px',
        marginBottom: '5px',
        width: '100%',
        '& .MuiInputBase-root': {
            height: '32px',
            padding: '0 0 0 13px !important',
            minHeight: 'auto',
        },
    },
}))

const MultipleSelect = (props: Props) => {
    const {
        options,
        value,
        handleChange,
        label,
        placeholder,
        placeholderSearch,
        style,
        onOpen,
        sx,
        loadingOptions,
        disable,
    } = props

    const [searchOptions, setSearchOptions] = useState<
        {
            key: string | number
            value: string
        }[]
    >(options || [])

    useEffect(() => {
        setSearchOptions(options)
    }, [options])

    // const newOptions = options.map(option => option.label)
    const handleSearch = (e) => {
        setSearchOptions((prev) => {
            const listResult = options.filter((item) => {
                return item.value.toUpperCase().indexOf(e.toUpperCase()) >= 0
            })
            return listResult
        })
    }

    const classes = useStyles()
    return (
        <Box sx={sx} className={classes.wrapSelectMuntil}>
            {label && (
                <Box component={'p'} className={classes.label}>
                    {label}
                </Box>
            )}
            <FormControl sx={{ width: '100%' }} className={classes.formControl}>
                {(value?.length === 0 || !value) && (
                    <InputLabel id="demo-multiple-checkbox-label" className={classes.placeholder}>
                        {placeholder ? placeholder : 'Select option'}
                    </InputLabel>
                )}
                <Select
                    style={style}
                    fullWidth
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={value || []}
                    placeholder=""
                    onChange={(e: SelectChangeEvent<typeof value>) => {
                        console.log(e)
                    }}
                    onOpen={() => onOpen?.()}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => {
                        return selected?.map((item) => item.value).join(', ')
                    }}
                    IconComponent={KeyboardArrowDown}
                    disabled={disable}
                // MenuProps={MenuProps}
                >
                    <Box className={classes.box_options}>
                        <Box className={classes.input_search}>
                            <TextField
                                fullWidth
                                placeholder={placeholderSearch ? placeholderSearch : 'Search...'}
                                id="outlined-start-adornment"
                                // sx={{ m: 1, width: '25ch' }}
                                onChange={(e) => handleSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Box sx={{ display: 'flex' }}>
                                                {loadingOptions ? (
                                                    <CircularProgress
                                                        sx={{
                                                            width: '20px !important',
                                                            height: '20px !important',
                                                        }}
                                                    />
                                                ) : (
                                                    <SearchIconLabel />
                                                )}
                                            </Box>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                        <Box className={classes.list_options}>
                            {loadingOptions ? (
                                <Box className={classes.box_option}>
                                    <Box>Loading Options...</Box>
                                </Box>
                            ) : (
                                <>
                                    {!searchOptions.length ? (
                                        <Box className={classes.box_option}>
                                            <Box>No Options...</Box>
                                        </Box>
                                    ) : (
                                        searchOptions.map((option) => {
                                            const check = value?.find(
                                                (item) => item.key === option.key,
                                            )
                                            return (
                                                <Box
                                                    key={option.key}
                                                    className={classes.box_option}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={check ? true : false}
                                                                onChange={(e) => {
                                                                    // console.log(e.target.checked)
                                                                    handleChange(
                                                                        option,
                                                                        e.target.checked,
                                                                    )
                                                                }}
                                                                name={option.value}
                                                            />
                                                        }
                                                        label={option.value}
                                                    />
                                                </Box>
                                            )
                                        })
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    )
}

export default React.memo(MultipleSelect)
