import React, { useEffect } from 'react'
import {
    Grid,
    Typography,
    TextField,
    Box,
    InputAdornment,
    Divider,
    FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SelectEl } from '../../../shared/elements'
import {
    RemoveCircleOutlineOutlined,
    AddCircleOutlineOutlined,
} from '@material-ui/icons'
import _ from 'lodash'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'

const useStyles = makeStyles({
    labelText: {
        color: '#516983',
        fontSize: 14,
        letterSpacing: 0,
    },
    boxContainer: {
        paddingTop: 20,
        display: 'flex',
        alignItems: 'end',
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        borderRadius: 4,
        height: 40,
    },
    modifierTextField: {
        width: 52,
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
    divider: {
        background: '#B2C0CF',
        marginTop: 20,
    },
})

const defaultValues = {
    discount_note: '',
    courtesy_discount: '',
    modifiers: ['', '', '', ''],
    service_units: '',
    units: '',
    charge: '',
    cpt_code_master_id: '',
}

const CPTCode = ({
    master,
    values,
    onChange,
    errors,
    touched,
    isDisabled,
    fieldName,
}) => {
    const classes = useStyles()

    const handleChange = (fieldName, val, index) => {
        const updatedValue = values.map((v, i) =>
            index === i ? { ...v, [fieldName]: val } : v,
        )
        onChange('cpt_code', updatedValue)
    }
    const handleFieldChange = (e, index) => {
        const { name, value } = e.target
        handleChange(name, value, index)
    }

    const handleNumberFieldChange = (e, index) => {
        // eslint-disable-next-line prefer-const
        let { name, value } = e.target
        if (value && !isNaN(value)) {
            value = Math.abs(e.target.value)
        }
        handleChange(name, value, index)
    }

    const handleKeyPress = (e) => {
        if (e.key === '-' || e.key === '+') {
            e.preventDefault()
        }
    }

    const handleAddMore = (action, index) => {
        const defaultValues = {
            discount_note: '',
            courtesy_discount: '',
            modifiers: ['', '', '', ''],
            service_units: '',
            units: '',
            charge: '',
            cpt_code_master_id: '',
        }
        if (action === 'add') {
            onChange('cpt_code', [
                ...values,
                { ...defaultValues },
            ])
        } else {
            const updatedValue = values.filter(
                (v, i) => i !== index,
            )
            onChange(
                'cpt_code',
                updatedValue.length > 0
                    ? updatedValue
                    : [{ ...defaultValues }],
            )
        }
    }

    const renderAddOrRemove = (index, isEmpty) => {
        return (
            <Box sx={{ width: 30, marginLeft: 16 }}>
                {values.length >= 1 &&
                    (values.length === 1 && isEmpty
                        ? false
                        : true) && (
                        <RemoveCircleOutlineOutlined
                            className={classes.icon}
                            color="primary"
                            fontSize="large"
                            onClick={() =>
                                handleAddMore(
                                    'remove',
                                    index,
                                )
                            }
                        />
                    )}
                {index + 1 === values.length && (
                    <AddCircleOutlineOutlined
                        className={classes.icon}
                        color="primary"
                        fontSize="large"
                        onClick={() => handleAddMore('add')}
                    />
                )}
            </Box>
        )
    }

    const getValidity = (errors, index) => {
        if (errors && errors.cpt_code) {
            return !errors.cpt_code[index]
                ?.cpt_code_master_id
        }
        return true
    }

    const isEmptyCPT = (cpt) => {
        return !(
            cpt.cpt_code_master_id ||
            cpt.charge ||
            cpt.units ||
            cpt.service_units ||
            cpt.courtesy_discount ||
            cpt.discount_note ||
            cpt.modifiers.some((m) => m)
        )
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
            {values.map((v, index) => {
                const isValid = getValidity(errors, index)
                return (
                    <div key={index}>
                        {index !== 0 && (
                            <Box
                                sx={{
                                    padding:
                                        '20px 10px 10px 3px',
                                }}>
                                <Typography variant="h6">
                                    Add-on code {index}:
                                </Typography>
                            </Box>
                        )}
                        <Box
                            className={
                                classes.boxContainer
                            }>
                            <Grid container spacing={4}>
                                <Grid
                                    item
                                    xs={12}
                                    md={8}
                                    style={{
                                        paddingTop: 0,
                                    }}>
                                    <SelectEl
                                        name="cpt_code_master_id"
                                        value={
                                            v.cpt_code_master_id
                                        }
                                        onChange={(e) =>
                                            handleFieldChange(
                                                e,
                                                index,
                                            )
                                        }
                                        disabled={
                                            isDisabled
                                        }
                                        options={master.map(
                                            (m) => ({
                                                key: m._id,
                                                value: `${m.cpt_code} - ${m.description}`,
                                            }),
                                        )}
                                    />
                                    {!isValid && (
                                        <FormHelperText
                                            error>
                                            Field required.
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={4}
                                    style={{
                                        paddingTop: 0,
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 5,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            className={
                                                classes.labelText
                                            }>
                                            Charge
                                        </Typography>
                                        <TextField
                                            className={
                                                classes.textFieldWrap
                                            }
                                            disabled={
                                                isDisabled
                                            }
                                            value={v.charge}
                                            onKeyPress={
                                                handleKeyPress
                                            }
                                            onChange={(
                                                e,
                                            ) => {
                                                handleNumberFieldChange(
                                                    e,
                                                    index,
                                                )
                                            }}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                            }}
                                            name="charge"
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                startAdornment:
                                                    (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                classes:
                                                    classes.root,
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 5,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            className={
                                                classes.labelText
                                            }>
                                            Units
                                        </Typography>
                                        <TextField
                                            className={
                                                classes.textFieldWrap
                                            }
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            name="units"
                                            value={v.units}
                                            disabled={
                                                isDisabled
                                            }
                                            onKeyPress={
                                                handleKeyPress
                                            }
                                            onChange={(
                                                e,
                                            ) => {
                                                handleNumberFieldChange(
                                                    e,
                                                    index,
                                                )
                                            }}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                            }}
                                            InputProps={{
                                                classes:
                                                    classes.root,
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 5,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            style={{
                                                width: 110,
                                            }}
                                            className={
                                                classes.labelText
                                            }>
                                            Service Units
                                        </Typography>
                                        <TextField
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            name="service_units"
                                            value={
                                                v.service_units
                                            }
                                            onKeyPress={
                                                handleKeyPress
                                            }
                                            disabled={
                                                isDisabled
                                            }
                                            onChange={(
                                                e,
                                            ) => {
                                                handleNumberFieldChange(
                                                    e,
                                                    index,
                                                )
                                            }}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                            }}
                                            InputProps={{
                                                classes:
                                                    classes.root,
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 20,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            className={
                                                classes.labelText
                                            }>
                                            Modifiers (1-4)
                                        </Typography>
                                        {v.modifiers.map(
                                            (
                                                modifier,
                                                i,
                                            ) => (
                                                <TextField
                                                    key={i}
                                                    className={`${classes.textFieldWrap} ${classes.modifierTextField}`}
                                                    variant="outlined"
                                                    size="small"
                                                    value={
                                                        modifier
                                                    }
                                                    disabled={
                                                        isDisabled
                                                    }
                                                    fullWidth
                                                    type="tel"
                                                    inputProps={{
                                                        maxLength: 2,
                                                    }}
                                                    onChange={(
                                                        e,
                                                    ) => {
                                                        const value =
                                                            e.target.value.replace(
                                                                /[^0-9]/g,
                                                                '',
                                                            )
                                                        const updatedModifiers =
                                                            v.modifiers
                                                        updatedModifiers[
                                                            i
                                                        ] = value
                                                        handleChange(
                                                            'modifiers',
                                                            updatedModifiers,
                                                            index,
                                                        )
                                                    }}
                                                    InputProps={{
                                                        classes:
                                                            classes.root,
                                                        style: {
                                                            height: 40,
                                                        },
                                                    }}
                                                />
                                            ),
                                        )}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 5,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            className={
                                                classes.labelText
                                            }>
                                            Courtesy
                                            Discount
                                        </Typography>
                                        <TextField
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            name="courtesy_discount"
                                            disabled={
                                                isDisabled
                                            }
                                            value={
                                                v.courtesy_discount
                                            }
                                            onKeyPress={
                                                handleKeyPress
                                            }
                                            onChange={(
                                                e,
                                            ) => {
                                                handleNumberFieldChange(
                                                    e,
                                                    index,
                                                )
                                            }}
                                            type="number"
                                            inputProps={{
                                                min: 0,
                                            }}
                                            InputProps={{
                                                startAdornment:
                                                    (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    ),
                                                classes:
                                                    classes.root,
                                                style: {
                                                    height: 40,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 5,
                                            justifyContent:
                                                'start',
                                            alignItems:
                                                'center',
                                            width: '100%',
                                        }}>
                                        <Typography
                                            style={{
                                                width: 110,
                                            }}
                                            className={
                                                classes.labelText
                                            }>
                                            Discount Note
                                        </Typography>
                                        <TextField
                                            className={
                                                classes.textFieldWrap
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            multiline
                                            minRows={1}
                                            name="discount_note"
                                            disabled={
                                                isDisabled
                                            }
                                            value={
                                                v.discount_note
                                            }
                                            onChange={(e) =>
                                                handleFieldChange(
                                                    e,
                                                    index,
                                                )
                                            }
                                            InputProps={{
                                                classes:
                                                    classes.root,
                                                style: {
                                                    // height: 40,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            {renderAddOrRemove(
                                index,
                                isEmptyCPT(v),
                            )}
                        </Box>
                        {index < values.length - 1 && (
                            <Divider
                                classes={{
                                    root: classes.divider,
                                }}
                            />
                        )}
                    </div>
                )
            })}
        </Box>
    )
}

export default CPTCode
