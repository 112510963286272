import * as Yup from 'yup'
import moment from 'moment'

export const searchValidationSchema = Yup.object().shape({
    timezone: Yup.string().required('This field is required!'),
    visit_type_id: Yup.string().required('This field is required!'),
    specialty: Yup.string().required('This field is required!'),
    start_date: Yup.string()
        .nullable()
        .test({
            name: 'start_date',
            test(value, context) {
                if (!value) {
                    return this.createError({
                        message: 'This field is required!',
                        path: 'start_date',
                    })
                }
                const end_date = moment(context.parent.end_date, 'MM/DD/YYYY')
                const start_date = moment(value, 'MM/DD/YYYY')

                if (!start_date.isValid()) {
                    return this.createError({
                        message: 'Date invalid',
                        path: 'start_date',
                    })
                }
                if (start_date.isBefore(moment(), 'day')) {
                    return this.createError({
                        message: 'Start date must be after current date',
                        path: 'start_date',
                    })
                }
                if (end_date.isBefore(start_date, 'day')) {
                    return this.createError({
                        message: 'Start date must be before end date',
                        path: 'start_date',
                    })
                }
                return true
            },
        }),
    end_date: Yup.string()
        .nullable()
        .test({
            name: 'end_date',
            test(value, context) {
                if (!value) {
                    return this.createError({
                        message: 'This field is required!',
                        path: 'end_date',
                    })
                }

                const start_date = moment(context.parent.start_date, 'MM/DD/YYYY')
                const end_date = moment(value, 'MM/DD/YYYY')

                if (!end_date.isValid()) {
                    return this.createError({
                        message: 'Date invalid',
                        path: 'end_date',
                    })
                }
                if (end_date.isBefore(moment(), 'day')) {
                    return this.createError({
                        message: 'End date must be after current date',
                        path: 'end_date',
                    })
                }
                if (end_date.isBefore(start_date, 'day')) {
                    return this.createError({
                        message: 'End date must be after start date',
                        path: 'end_date',
                    })
                }
                if (end_date.diff(start_date, 'day') > 6) {
                    return this.createError({
                        message: 'Maximum one week',
                        path: 'end_date',
                    })
                }
                return true
            },
        }),
    start_time: Yup.string().test({
        name: 'start_time',
        test(value, context) {
            const end = moment(context.parent.end_time, 'HH:mm')
            const start = moment(value, 'HH:mm')
            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'Start time must be before end time',
                    path: 'start_time',
                })
            }

            if (context.parent.start_date && !context.parent.all_day) {
                const start_date = moment(context.parent.start_date, 'MM/DD/YYYY').set({
                    hour: start.hour(),
                    minute: start.minute(),
                })
                if (start_date.isBefore(moment(), 'minute')) {
                    return this.createError({
                        message: 'Start time must be before current time',
                        path: 'start_time',
                    })
                }
            }

            return true
        },
    }),
    end_time: Yup.string().test({
        name: 'end_time',
        test(value, context) {
            const start = moment(context.parent.start_time, 'HH:mm')
            const end = moment(value, 'HH:mm')
            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'End time must be after start time',
                    path: 'end_time',
                })
            }

            if (context.parent.end_date && !context.parent.all_day) {
                const end_date = moment(context.parent.end_date, 'MM/DD/YYYY').set({
                    hour: end.hour(),
                    minute: end.minute(),
                })
                if (end_date.isBefore(moment(), 'minute')) {
                    return this.createError({
                        message: 'End time must be before current time',
                        path: 'end_time',
                    })
                }
            }

            return true
        },
    }),
})

export const scheduleValidationSchema = (appointmentStatus) => Yup.object().shape({
    // date: Yup.string().test({
    //     name: 'date',
    //     test(value) {
    //         // if (moment(value, 'YYYY-MM-DD').diff(moment(), 'days') < 0) {
    //         //     return this.createError({
    //         //         message: "Can't make an appointment in the past!",
    //         //         path: 'date',
    //         //     })
    //         // }
    //         return true
    //     },
    // }),
    date: Yup.date().required('Date field is required!').typeError('Invalid date'),
    start_time: Yup.string().test({
        name: 'start_time',
        test(value, context) {
            const end = moment(context.parent.end_time, 'HH:mm')
            const start = moment(value, 'HH:mm')
            const EndDate = moment(`${context.parent.date} ${value}`, 'YYYY-MM-DD HH:mm').format(
                'MM/DD/YYYY HH:mm',
            )
            // if (moment(EndDate).isBefore(moment().format('MM/DD/YYYY HH:mm'), 'minute')) {
            //     return this.createError({
            //         message: "Can't make an appointment in the past!",
            //         path: 'start_time',
            //     })
            // }

            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'Start time must be before end time',
                    path: 'start_time',
                })
            }

            return true
        },
    }),
    end_time: Yup.string().test({
        name: 'end_time',
        test(value, context) {
            const start = moment(context.parent.start_time, 'HH:mm')
            const end = moment(value, 'HH:mm')
            if (end.isSameOrBefore(start)) {
                return this.createError({
                    message: 'End time must be after start time',
                    path: 'end_time',
                })
            }
            return true
        },
    }),
    appointment_type: Yup.string().required('This field is required!'),
    visit_type_id: Yup.string().required('This field is required!'),
    specialty_id: Yup.string().required('This field is required!'),
    duration: Yup.string().required('This field is required!'),
    practitioner_id: Yup.string().required('This field is required!'),
    patient_id: Yup.string().required('This field is required!'),
    location_id: Yup.string().required('This field is required!'),
    once_every: Yup.number().when('is_reoccurring_appointment', {
        is: true,
        then: Yup.number().min(1, 'Minimum 1').required('Repeat every is required!'),
    }),
    unit: Yup.string().when('is_reoccurring_appointment', {
        is: true,
        then: Yup.string().required('Repeat every is required!'),
    }),
    days: Yup.array().when(['is_reoccurring_appointment', 'unit'], {
        is: (is_reoccurring_appointment, unit) => is_reoccurring_appointment && unit !== 'day',
        then: Yup.array().min(1, 'Minimum 1 day'),
    }),
    end_date: Yup.date().when('is_reoccurring_appointment', {
        is: true,
        then: Yup.date().test({
            name: 'end_date',
            test(value, context) {
                const start = moment(context.parent.date, 'YYYY-MM-DD')
                const end = moment(context.parent.end_date)
                if (end.isSameOrBefore(start)) {
                    return this.createError({
                        message: 'End date must be after start date',
                        path: 'end_date',
                    })
                }
                return true
            },
        }),
    }),
})
