import React from 'react'
import { Typography } from '@material-ui/core'
import { Pagination as MuiPagination, PaginationItem, Stack } from '@mui/material'
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'

import ForwardIcon from 'assests/icons/ForwardIcon'
import NextIcon from 'assests/icons/NextIcon'

interface Props {
    totalRecords?: number
    onPageChange?: (page: number) => void
    page: number
}

export const PaginationIndex = ({
    onPageChange,
    totalRecords,
    page,
}: // numberRecord,
Props) => {
    const apiRef = useGridApiContext()
    // const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const rowIndex = gridPaginationRowRangeSelector(apiRef)
    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show <strong>{rowIndex ? rowIndex.lastRowIndex + 1 : 0} records</strong> of{' '}
                <strong>{totalRecords}</strong>
            </Typography>

            <MuiPagination
                color="primary"
                count={pageCount}
                page={page || 0}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1)
                    onPageChange && onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}
