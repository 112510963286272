import React, { useState, useEffect, lazy } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Typography,
    InputAdornment,
    DialogTitle,
} from '@material-ui/core'
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close'
import { useNotification } from 'shared/elements/Notification'
import TextBox from 'shared/elements/FormItem/TextBox'
import { getCustomizedFormattedDate, getTimeFromDate } from 'utilities/dateOperations'
import { ReactComponent as NoteCreateIcon } from 'assests/icons/NoteCreate.svg'
import { ReactComponent as NoteCreateCloseIcon } from 'assests/icons/NoteCreateClose.svg'
import { useSelector } from 'react-redux'
import { ReactComponent as NoteEditIcon } from 'assests/icons/NoteEditIcon.svg'
import { createCommentApi, getAllCommentApi, updateCommentApi, userReadCommentApi } from '../api/services'
const { v4: uuidv4 } = require('uuid')

const Loading = lazy(() => import('shared/elements/Loading'))
const useStyles = makeStyles({
    root: {
        '& .MuiPaper-root': {
            height: '75%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'auto',
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1000px',
            overflow: 'auto',
        },
    },
    header: {
        fontSize: '18px',
        color: '#303E4E',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 18,
    },
    tableHead: {
        borderRadius: '5px',
        border: '0.8px solid #E9E9E9',
        background: '#F8F9FB',
    },
    bgColor_in_progress: {
        background: '#D3DEFF',
        color: '#5571C6',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    bgColor_complete: {
        background: '#EBFFF3',
        color: '#15A452',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    bgColor_draft: {
        color: '#667689',
        backgroundColor: '#DEE2E6',
        padding: '6px 16px',
        borderRadius: '5px',
    },
    noRecord: {
        textAlign: 'center',
        border: '1px solid #CFCFCF',
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
        padding: '10px',
    },
    tbody: {
        color: '#000',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
    },
    tbodyInside: {
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Rubik',
    },
    dateTime: {
        color: '#000',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
        paddingLeft: '7px',
    },
})

export default function CommentLog({ open, close, id, user }) {
    const classes = useStyles()
    const notification = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const [viewMode, setViewMode] = useState('no-view')
    const [singleComment, setSingleComment] = useState('')
    const [commentList, setCommentList] = useState([])
    const userData = useSelector((state) => state?.login?.userData)
    const [commentDetails, SetcommentDetails] = useState({})
    const handleViewNote = (commentDetails) => {
        setViewMode('view-only')
        setSingleComment(commentDetails?.description)
        SetcommentDetails(commentDetails)
    }
    const handleCloseEditCommentView = () => {
        setViewMode('no-view')
        setSingleComment('')
        SetcommentDetails('')
    }
    const createComment = async () => {
        try {
            setIsLoading(true)
            let payload = {
                id: uuidv4(),
                practitioner_id: userData?.practitioner?.id,
                practitioner_name: userData?.name,
                description: singleComment,
                comment: 'Added comment',
                read_by_midlevel: user === 'midlevel' ? true : false,
                read_by_supervisor: user === 'supervisor' ? true : false,
            }
            const res = await createCommentApi(id, payload)
            if(res.status === 200){
                notification('Clinical note comment created successfully', 'success')
                handleCloseEditCommentView()
                setIsLoading(false)
                getAllComment()
            }
        } catch (e) {
            setIsLoading(false)
            notification('Something went wrong!', 'error')
        }
    }
    const updateComment = async () => {
        try {
            setIsLoading(true)
            let payload = {
                id: commentDetails.id,
                practitioner_id: commentDetails.practitioner_id,
                practitioner_name: commentDetails.practitioner_name,
                description: singleComment,
                comment: 'Updated comment',
                read_by_midlevel: commentDetails.read_by_midlevel === 1 ? true : false,
                read_by_supervisor: commentDetails.read_by_supervisor === 1 ? true : false,
            }
            const res = await updateCommentApi(id, payload)
            if(res.status === 200){
                notification('Clinical note comment updated successfully', 'success')
                handleCloseEditCommentView()
                setIsLoading(false)
                getAllComment()
            }
        } catch (e) {
            setIsLoading(false)
            notification('Something went wrong!', 'error')
        }
    }
    const getAllComment = async () => {
        try {
            setIsLoading(true)
            const res = await getAllCommentApi(id)
            setCommentList(res?.data?.data || [])
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
            notification('Something went wrong!', 'error')
        }
    }
    const readUser = async () => {
        try {
            const payload = {
                read_by_midlevel: user === 'midlevel' ? true : false,
                read_by_supervisor: user === 'supervisor' ? true : false,
            }
            const res = await userReadCommentApi(id,payload)
        } catch (e) {
            setIsLoading(false)
        }
    }
    useEffect(() => {
        getAllComment()
        readUser()
    }, [])
    const noteEndAdornments = () => {
        switch (viewMode) {
            case 'view-only':
                return (
                    <InputAdornment
                        position="end"
                        style={{
                            position: 'absolute',
                            left: '96%',
                            top: 15,
                        }}>
                        {userData['custom:user_id'] === commentDetails.practitioner_id && (
                            <IconButton onClick={() => setViewMode('can-edit')}>
                                <NoteEditIcon style={{ marginLeft: 3 }} />
                            </IconButton>
                        )}
                        <IconButton
                            style={{
                                position: 'absolute',
                                top: 240,
                            }}
                            onClick={handleCloseEditCommentView}>
                            <NoteCreateCloseIcon />
                        </IconButton>
                    </InputAdornment>
                )

            case 'can-edit':
                return (
                    <InputAdornment
                        position="end"
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '92%',
                        }}>
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <IconButton onClick={handleCloseEditCommentView}>
                                <NoteCreateCloseIcon />
                            </IconButton>
                            <IconButton onClick={updateComment}>
                                <NoteCreateIcon />
                            </IconButton>
                        </span>
                    </InputAdornment>
                )

            case 'no-view':
                return (
                    singleComment && (
                        <InputAdornment
                            position="end"
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '92%',
                            }}>
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <IconButton onClick={handleCloseEditCommentView}>
                                    <NoteCreateCloseIcon />
                                </IconButton>

                                <IconButton
                                    onClick={() => {
                                        createComment()
                                    }}>
                                    <NoteCreateIcon />
                                </IconButton>
                            </span>
                        </InputAdornment>
                    )
                )

            default:
                return <></>
        }
    }


    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1000px',
                },
            }}
            fullWidth={true}
            onClose={close}
            className={classes.root}>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '2px solid #DEE2E6',
                    }}>
                    <Typography className={classes.header}>Comments</Typography>
                    <IconButton style={{ marginBottom: 18 }} onClick={close}>
                        <CloseIcon style={{ color: '#303E4E' }} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container style={{ marginBottom: '13px', flex: 1 }} direction="column">
                    <Loading visible={isLoading} />
                    <Grid item spacing={2} container>
                        <Grid item xs={12} style={{ padding: '0 10px 10px 10px' }}>
                            <TextBox
                                name="note"
                                value={singleComment}
                                sx={{
                                    '& .MuiOutlinedInput-multiline': {
                                        height: 'auto',
                                    },
                                    '& .MuiOutlinedInput-inputMultiline': {
                                        position:  'relative' ,
                                        // top: 6,
                                        // left: 5
                                    },
                                }}
                                multiline
                                minRows={17}
                                placeholder="Enter description"
                                onChange={(value) => {
                                    setSingleComment(value)
                                }}
                                style={{ position: 'relative' }}
                                inputProps={{ readOnly: viewMode === 'view-only' }}
                                InputProps={{
                                    endAdornment: noteEndAdornments(),
                                }}
                            />
                        </Grid>
                        <fieldset
                            className="divider"
                            style={{ maxHeight: '120px', overflow: 'hidden scroll', width: '100%' }}>
                            <legend>
                                <Typography className={'heading1-title'}>History</Typography>
                            </legend>
                            {commentList.length > 0 ? (
                                commentList?.map((item, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                                marginBottom: 8,
                                                paddingLeft: 10,
                                                borderRadius: commentDetails?.id === item?.id ? '5px' : '0px ',
                                                border: commentDetails?.id === item?.id ? '0.4px solid #D8D9DC ' : 'none',
                                                background: commentDetails?.id === item?.id ? "#F5F5F5 " : '#fff',
                                            }}
                                            key={index}>
                                            <Typography>
                                                <strong>{item?.practitioner_name}</strong>{' '}-{' '}
                                                {item.comment === 'Added comment'
                                                    ? getCustomizedFormattedDate(item?.createdAt)
                                                    : getCustomizedFormattedDate(
                                                          item?.updatedAt,
                                                      )}{' '}
                                                at{' '}
                                                {item.comment === 'Added comment'
                                                    ? getTimeFromDate(item?.createdAt)
                                                    : getTimeFromDate(item?.updatedAt)}
                                                : {item?.comment}
                                            </Typography>

                                            <Typography
                                                style={{ marginRight: 10, cursor: 'pointer' }}
                                                onClick={() => handleViewNote(item)}>
                                                View
                                            </Typography>
                                        </div>
                                    )
                                })
                            ) : (
                                <Typography>No Comment Added Yet!</Typography>
                            )}
                        </fieldset>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
