const AttachFileIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9443_96839)">
                <path
                    d="M9.24727 9.11244L7.39477 10.9649C6.36727 11.9924 6.36727 13.6499 7.39477 14.6774C8.42227 15.7049 10.0798 15.7049 11.1073 14.6774L14.0248 11.7599C16.0723 9.71244 16.0723 6.38244 14.0248 4.33494C11.9773 2.28744 8.64727 2.28744 6.59977 4.33494L3.41977 7.51494C1.66477 9.26994 1.66477 12.1199 3.41977 13.8824"
                    stroke="#667689"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9443_96839">
                    <rect
                        width="18"
                        height="18"
                        fill="white"
                        transform="matrix(-1 0 0 1 18 0)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AttachFileIcon
