import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { Box, Typography } from '@mui/material'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import moment from 'moment'
import { ReactComponent as RotateIcon } from '../../../../assests/icons/rotate_icon.svg'
import { useNotification } from 'shared/elements/Notification'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
const useStyles = makeStyles(() => ({
    item_event: {
        position: 'relative',
        backgroundColor: '#F1F8FF',
        borderRadius: 5,
        padding: '6px 10px',
        width: '-webkit-fill-available',
        margin: '2px 16px',
        overflow: 'hidden',
    },
    item_text: {
        fontSize: 13,
        overflow: 'hidden',
        wordBreak: 'break-word',
        display: '-webkit-box',
        ['-webkit-line-clamp']: 1,
        ['-webkit-box-orient']: 'vertical',
    },
    line: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 2,
        height: '100%',
        background: '#0076FC',
    },
    editOptionsPaperContainer: {
        // height: 95,
        width: 160,
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    optionContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        cursor: 'pointer',
        '& .MuiTypography-root': {
            color: '#303E4E !important',
            fontFamily: 'Rubik !important',
            fontSize: '16px !important',
            fontStyle: 'normal !important',
            fontWeight: '400 !important',
            lineHeight: '140% !important',
        },
    },
}))

function MonthCalendarEvent(props) {
    const classes = useStyles()
    const { event } = props
    const [editOptions, setEditOptions] = React.useState(false)
    const notification = useNotification()
    const providerEditableRoles = ['tenant_admin', 'super_user', 'medical_assistant', 'care_coordinator']
    const loggedInUserRole = useSelector((state) => state?.login?.userData)?.role_code
    const canEdit = providerEditableRoles.includes(loggedInUserRole)

    const openEditModal = (value) => {
        // console.log('63=>',props)
        if (moment(event?.start)?.isBefore(moment())) {
            notification('Previous dates are not editable!', 'warning')
            return
        }
        props.setId(event?.id)
        props.setEditMode(value)
        props.setOpenForm(true)
    }

    const editOptionsPaper = () => (
        <div className={classes.editOptionsPaperContainer}>
            <div
                className={classes.optionContainer}
                onClick={() => openEditModal(event?.is_recur ? 'occurance' : 'onlyEdit')}>
                <Typography>
                    {event?.is_recur ? 'Edit Occurrence' : 'Edit Availability'}{' '}
                </Typography>
            </div>
            {event?.is_recur && (
                <div
                    className={classes.optionContainer}
                    onClick={() => openEditModal('series')}
                    style={{ pointerEvents: event?.is_recur ? 'auto' : 'none' }}>
                    <Typography
                        style={{
                            color: event?.is_recur ? '#303E4E' : 'rgba(48, 62, 78, 0.7) !important',
                        }}>
                        Edit Series
                    </Typography>
                </div>
            )}
        </div>
    )

    return (
        <HtmlTooltip
            disableFocusListener
            disableTouchListener
            title={<ToolTipContentMonth {...props.event} />}
            placement="top-start">
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                className={classes.item_event}
                style={{
                    background: '#F1F8FF',
                }}>
                <div
                    className={classes.line}
                    style={{
                        background: '#0076FC',
                    }}></div>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                    <Typography className={classes.item_text}>
                        {/* {props.title} */}
                        {moment(new Date(event?.start).getTime()).format('hh:mm A')}
                        {` - `}
                        {moment(new Date(event?.end).getTime()).format('hh:mm A')}
                        {/* <span style={{ marginLeft: 16 }}>
                            {props.event.practitioner_first_name || ''}{' '}
                            {props.event.practitioner_last_name || ''}
                        </span>
                        <span>{` - `}</span>
                        <span>{props.event.patientName}</span>
                        <span>{` - `}</span>
                        <span>{props.event.appointmentType}</span> */}
                    </Typography>
                    <HtmlTooltip
                        title={editOptionsPaper()}
                        placement="bottom-end"
                        disableHoverListener
                        disableFocusListener
                        arrow
                        open={editOptions}
                        // onClose={() => setEditOptions(false)}
                    >
                        <IconButton onClick={() => setEditOptions(true)} disabled={!canEdit}>
                            {event?.is_recur ? (
                                <RotateIcon />
                            ) : (
                                <EditIcon style={{ fontSize: '18px', color: '#667689' }} />
                            )}
                        </IconButton>
                    </HtmlTooltip>
                </Grid>
            </Grid>
        </HtmlTooltip>
    )
}

function ToolTipContentMonth(props) {
    return (
        <div className="tooltip-wrapper">
            <Box>
                <Typography>
                    <Box
                        component={'span'}
                        sx={{
                            display: 'inline-block',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            background: '#f0f0f0',
                            margin: '0 5px 2px 0',
                        }}></Box>
                    <Box component={'span'}>
                        {moment(new Date(props.start).getTime()).format('hh:mm A')}
                    </Box>
                    <Box component={'span'}>{' - '}</Box>
                    <Box component={'span'}>
                        {moment(new Date(props.end).getTime()).format('hh:mm A')}
                    </Box>
                    {/* <Typography noWrap component={'span'}>
                        {' - '}
                        {props.appointmentType}
                    </Typography> */}
                </Typography>
                {/* <Typography sx={{ whiteSpace: 'nowrap' }}>
                    <span>
                        {' - Provider: '}
                        {props.practitioner_first_name || ''} {props.practitioner_last_name || ''}
                        {' - '}
                        {props.timezone}
                    </span>
                </Typography> */}

                {/* <Typography noWrap className="black capitalize">
                    {' - Patient: '}
                    {props.patientName}
                </Typography> */}
            </Box>
        </div>
    )
}

export default MonthCalendarEvent
