import moment from 'moment'
import { SCREEN } from './constant'

export const INITIAL_VALUES = {
    start: moment(),
    end: moment(),
    category: [
        {
            key: 'red',
            value: 'Red',
        },
        {
            key: 'white',
            value: 'White',
        },
    ],
    selectedTab: SCREEN.PATIENT_DETAILS,
    selectedScreen: SCREEN.PATIENT_DETAILS,
    isNewPatient: false,
    patient: null,
    patient_type: null,
    minor: '',
    appointment_reason: '',
    additional_info: '',
    questions: {
        1: null,
        2: null,
        3: null,
    },
    is_external: false,
    provider: {
        name: 'Test',
        speciality: 'Speciality',
        start: new Date(),
        end: new Date(),
    },
    name: '',
    lasName: '',
    phone: '',
    physicalCity: '',
    physicalState: '',
    physicalZipCode: '',
    address1: '',
    address2: '',
    place_of_service: '',
    clinical_practice_id: '',
    npi: '',
    rcopia_id: '',
    facilityTaxonomy: '',
    CLIANumber: '',
    fax: '',
    external_id: '',
}

export const PLACE_OF_SERVICE = [
    { key: '01', value: 'Pharmacy ' },
    { key: '02', value: 'Telehealth ' },
    { key: '03', value: 'School ' },
    { key: '04', value: 'Homeless Shelter ' },
    {
        key: '05',
        value: 'Indian Health Service Free-standing Facility ',
    },
    {
        key: '06',
        value: 'Indian Health Service Provider-based Facility ',
    },
    {
        key: '07',
        value: 'Tribal 638 Free-Standing Facility ',
    },
    {
        key: '08',
        value: 'Tribal 638 Provider-Based Facility ',
    },
    { key: '09', value: 'Prison/Correctional Facility ' },
    {
        key: '10',
        value: 'Telehealth Provided in Patient’s Home ',
    },
    { key: '11', value: 'Office ' },
    { key: '12', value: 'Home ' },
    { key: '13', value: 'Assisted Living Facility ' },
    { key: '14', value: 'Group Home ' },
    { key: '15', value: 'Mobile Unit ' },
    { key: '16', value: 'Temporary Lodging ' },
    { key: '17', value: 'Walk-in Retail Health Clinic ' },
    { key: '18', value: 'Place of Employment/Worksite ' },
    { key: '19', value: 'Off Campus-Outpatient Hospital ' },
    { key: '20', value: 'Urgent Care Facility ' },
    { key: '21', value: 'Inpatient Hospital ' },
    { key: '22', value: 'On Campus-Outpatient Hospital ' },
    { key: '23', value: 'Emergency Room-Hospital ' },
    { key: '24', value: 'Ambulatory Surgical Center ' },
    { key: '25', value: 'Birthing Center ' },
    { key: '26', value: 'Military Treatment Facility ' },
    { key: '31', value: 'Skilled Nursing Facility ' },
    { key: '32', value: 'Nursing Facility ' },
    { key: '33', value: 'Custodial Care Facility ' },
    {
        key: '34',
        value: 'Hospice 41 – Ambulance-Land 42 – Ambulance-Air or Water ',
    },
    {
        key: '49',
        value: 'Independent Clinic (October 1, 2003) ',
    },
    {
        key: '50',
        value: 'Federally Qualified Health Center ',
    },
    { key: '51', value: 'Inpatient Psychiatric Facility ' },
    {
        key: '52',
        value: 'Psychiatric Facility-Partial Hospitalization ',
    },
    { key: '53', value: 'Community Mental Health Center ' },
    {
        key: '54',
        value: 'Intermediate Care Facility/Individuals with Intellectual Disabilities ',
    },
    {
        key: '55',
        value: 'Residential Substance Abuse Treatment Facility ',
    },
    {
        key: '56',
        value: 'Psychiatric Residential Treatment Center ',
    },
    {
        key: '57',
        value: 'Non-residential Substance Abuse Treatment Facility ',
    },
    {
        key: '58',
        value: 'Non-residential Opioid Treatment Facility (January 1, 2020) ',
    },
    { key: '60', value: 'Mass Immunization Center ' },
    {
        key: '61',
        value: 'Comprehensive Inpatient Rehabilitation Facility ',
    },
    {
        key: '62',
        value: 'Comprehensive Outpatient Rehabilitation Facility  ',
    },
    {
        key: '65',
        value: 'End-Stage Renal Disease Treatment Facility ',
    },
    {
        key: '71',
        value: 'State or Local Public Health Clinic ',
    },
    { key: '72', value: 'Rural Health Clinic ' },
    { key: '81', value: 'Independent Laboratory ' },
    { key: '99', value: 'Other Place of Service' },
].sort((option1, option2) => {
    let a = option1.value
    let b = option2.value
    return a === b ? 0 : a > b ? 1 : -1
})
