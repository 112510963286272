const TeleIconUpDownSortedList = () => {
    return (
        <svg
            width="6"
            height="15"
            viewBox="0 0 6 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.98002 4.40527L3.35002 2.77527C3.15752 2.58277 2.84252 2.58277 2.65002 2.77527L1.02002 4.40527"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.98002 10.8804L3.35002 12.5104C3.15752 12.7029 2.84252 12.7029 2.65002 12.5104L1.02002 10.8804"
                stroke="#303E4E"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default TeleIconUpDownSortedList
