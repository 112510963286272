import React from 'react';

export default function EmptyListIcon() {
    return (
        <svg width="106" height="80" viewBox="0 0 106 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.0127 22.3019C49.8835 20.4352 52.0408 19.0318 54.3407 18.0766C54.3407 18.0754 54.341 18.0742 54.341 18.0733V6.02444C54.341 2.69714 51.6378 0 48.3031 0H6.03789C2.70316 0 0 2.69714 0 6.02444V18.0733C0 21.4006 2.70316 24.0978 6.03789 24.0978H46.438C46.9249 23.4776 47.4406 22.873 48.0127 22.3019Z" fill="#9CA9B8"/>
<path d="M7.54734 12.0489C10.0483 12.0489 12.0757 10.026 12.0757 7.53054C12.0757 5.03514 10.0483 3.01221 7.54734 3.01221C5.04636 3.01221 3.01892 5.03514 3.01892 7.53054C3.01892 10.026 5.04636 12.0489 7.54734 12.0489Z" fill="#D9DDE3"/>
<path d="M43.7743 7.53029H16.6038C15.7703 7.53029 15.0944 6.85586 15.0944 6.02418C15.0944 5.19251 15.7703 4.51807 16.6038 4.51807H43.7743C44.6078 4.51807 45.2838 5.19251 45.2838 6.02418C45.2838 6.85586 44.6078 7.53029 43.7743 7.53029Z" fill="#D9DDE3"/>
<path d="M49.8122 13.5547H16.6038C15.7703 13.5547 15.0944 12.8806 15.0944 12.0486C15.0944 11.2169 15.7703 10.5425 16.6038 10.5425H49.8122C50.6457 10.5425 51.3217 11.2169 51.3217 12.0486C51.3217 12.8806 50.6457 13.5547 49.8122 13.5547Z" fill="#D9DDE3"/>
<path d="M36.2269 19.5791H16.6038C15.7703 19.5791 15.0944 18.9047 15.0944 18.073C15.0944 17.2414 15.7703 16.5669 16.6038 16.5669H36.2269C37.0605 16.5669 37.7364 17.2414 37.7364 18.073C37.7364 18.9047 37.0605 19.5791 36.2269 19.5791Z" fill="#D9DDE3"/>
<path d="M63.3978 51.2078H21.1326C17.7979 51.2078 15.0947 48.5103 15.0947 45.1833V33.1344C15.0947 29.8071 17.7979 27.11 21.1326 27.11H63.3978C66.7325 27.11 69.4357 29.8071 69.4357 33.1344V45.1833C69.4357 48.5103 66.7325 51.2078 63.3978 51.2078Z" fill="#E9ECF1"/>
<path d="M22.6421 39.1589C25.1431 39.1589 27.1705 37.1359 27.1705 34.6405C27.1705 32.1451 25.1431 30.1222 22.6421 30.1222C20.1411 30.1222 18.1137 32.1451 18.1137 34.6405C18.1137 37.1359 20.1411 39.1589 22.6421 39.1589Z" fill="#D9DDE3"/>
<path d="M58.869 34.6403H31.6986C30.865 34.6403 30.1891 33.9659 30.1891 33.1342C30.1891 32.3025 30.865 31.6281 31.6986 31.6281H58.869C59.7026 31.6281 60.3785 32.3025 60.3785 33.1342C60.3785 33.9659 59.7026 34.6403 58.869 34.6403Z" fill="#D9DDE3"/>
<path d="M64.9069 40.6647H31.6986C30.865 40.6647 30.1891 39.9903 30.1891 39.1586C30.1891 38.3269 30.865 37.6525 31.6986 37.6525H64.9069C65.7405 37.6525 66.4164 38.3269 66.4164 39.1586C66.4164 39.9903 65.7405 40.6647 64.9069 40.6647Z" fill="#D9DDE3"/>
<path d="M51.3217 46.6891H31.6986C30.865 46.6891 30.1891 46.0147 30.1891 45.183C30.1891 44.3514 30.865 43.6769 31.6986 43.6769H51.3217C52.1552 43.6769 52.8311 44.3514 52.8311 45.183C52.8311 46.0147 52.1552 46.6891 51.3217 46.6891Z" fill="#D9DDE3"/>
<path d="M48.3031 78.3178H6.03789C2.70316 78.3178 0 75.6206 0 72.2933V60.2444C0 56.9171 2.70316 54.22 6.03789 54.22H48.3031C51.6375 54.22 54.341 56.9171 54.341 60.2444V72.2933C54.341 75.6206 51.6375 78.3178 48.3031 78.3178Z" fill="#9CA9B8"/>
<path d="M7.54734 66.2689C10.0483 66.2689 12.0757 64.2459 12.0757 61.7505C12.0757 59.2551 10.0483 57.2322 7.54734 57.2322C5.04636 57.2322 3.01892 59.2551 3.01892 61.7505C3.01892 64.2459 5.04636 66.2689 7.54734 66.2689Z" fill="#D9DDE3"/>
<path d="M43.7743 61.7503H16.6038C15.7703 61.7503 15.0944 61.0758 15.0944 60.2442C15.0944 59.4125 15.7703 58.7381 16.6038 58.7381H43.7743C44.6078 58.7381 45.2838 59.4125 45.2838 60.2442C45.2838 61.0758 44.6078 61.7503 43.7743 61.7503Z" fill="#D9DDE3"/>
<path d="M49.8122 67.7747H16.6038C15.7703 67.7747 15.0944 67.1003 15.0944 66.2686C15.0944 65.4369 15.7703 64.7625 16.6038 64.7625H49.8122C50.6457 64.7625 51.3217 65.4369 51.3217 66.2686C51.3217 67.1003 50.6457 67.7747 49.8122 67.7747Z" fill="#D9DDE3"/>
<path d="M36.2269 73.7991H16.6038C15.7703 73.7991 15.0944 73.1247 15.0944 72.293C15.0944 71.4613 15.7703 70.7869 16.6038 70.7869H36.2269C37.0605 70.7869 37.7364 71.4613 37.7364 72.293C37.7364 73.1247 37.0605 73.7991 36.2269 73.7991Z" fill="#D9DDE3"/>
<path d="M103.316 78.2356C100.959 80.5881 97.1354 80.5881 94.7776 78.2356L81.9694 65.4556C79.6116 63.1031 79.6116 59.2887 81.9694 56.9359C84.3272 54.5833 88.1504 54.5833 90.5082 56.9359L103.317 69.7155C105.674 72.0684 105.674 75.883 103.316 78.2356Z" fill="#9CA9B8"/>
<path d="M103.316 78.2356C100.959 80.5881 97.1354 80.5881 94.7776 78.2356L81.9694 65.4556C79.6116 63.1031 79.6116 59.2887 81.9694 56.9359C84.3272 54.5833 88.1504 54.5833 90.5082 56.9359L103.317 69.7155C105.674 72.0684 105.674 75.883 103.316 78.2356Z" fill="black" fillOpacity="0.2"/>
<path d="M85.6136 60.5722C84.4347 61.7485 82.5231 61.7485 81.3442 60.5722L76.1906 55.4301C75.0114 54.2535 75.0114 52.3464 76.1906 51.1699C77.3694 49.9936 79.2807 49.9936 80.4599 51.1699L85.6136 56.312C86.7925 57.4886 86.7925 59.3957 85.6136 60.5722Z" fill="#D9DDE3"/>
<path d="M78.9662 19.1068C69.5344 9.69605 54.2426 9.69605 44.8108 19.1068C35.379 28.5176 35.379 43.7758 44.8108 53.1866C54.2426 62.5974 69.5344 62.5974 78.9662 53.1866C88.3977 43.7758 88.3977 28.5176 78.9662 19.1068ZM75.764 49.9912C68.1008 57.6374 55.676 57.6374 48.0127 49.9912C40.3494 42.345 40.3494 29.9478 48.0127 22.3019C55.676 14.6554 68.1008 14.6554 75.764 22.3019C83.4273 29.9481 83.4273 42.3453 75.764 49.9912Z" fill="#D9DDE3"/>
</svg>

    )
}