import {
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import React, { useState } from 'react'
import LoadingPage from '../../../utilities/loading-page'
import { getHistoryClinicalNote } from '../api/services'
import HistoryItem from './HistoryItem'

const useStyles = makeStyles({
    root: {
        padding: '16px 24px',
        flex: 1,
    },
    icon_back: {
        marginRight: 8,
    },
    header: {
        marginBottom: 16,
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    no_results: {
        fontSize: 20,
        fontWeight: 400,
        marginBottom: 6,
        margin: 'auto',
    },
})

const History = ({ onChangeActiveView, patientId }) => {
    const classes = useStyles()
    const [historyData, setHistoryData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    React.useEffect(async () => {
        fetchConfigure()
    }, [])

    const fetchConfigure = async () => {
        try {
            setIsLoading(true)
            const histories = await getHistoryClinicalNote(
                patientId,
            )
            setHistoryData(histories?.data)
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log(err, 'ERROR')
        }
    }

    return (
        <Grid
            className={classes.root}
            container
            direction="column">
            <Grid
                item
                container
                alignItems="center"
                style={{ marginBottom: 12 }}>
                <IconButton
                    onClick={() =>
                        onChangeActiveView('sessions')
                    }
                    className={classes.icon_back}>
                    <KeyboardBackspaceOutlinedIcon
                        style={{ fontSize: 16 }}
                    />
                </IconButton>
                <Typography className={classes.back__btn}>
                    Back
                </Typography>
            </Grid>
            <Typography
                variant="h4"
                style={{ fontSize: 22, marginBottom: 16 }}>
                History
            </Typography>
            <Grid container>
                {historyData.map((item, index) => (
                    <HistoryItem
                        key={item.id}
                        firstItem={index === 0}
                        lastItem={
                            index === historyData.length - 1
                        }
                        {...item}
                    />
                ))}
            </Grid>

            {historyData.length === 0 && !isLoading && (
                <Typography
                    className={classes.no_results}
                    style={{ margin: 'auto' }}>
                    No Results
                </Typography>
            )}

            {isLoading && <LoadingPage />}
        </Grid>
    )
}

export default History
