import React, { useCallback, useEffect } from 'react'
import {
    Box,
    CircularProgress,
    InputAdornment,
    TextField,
} from '@material-ui/core'
import { debounce } from 'lodash'
import { Autocomplete } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { getAccountList } from 'services/Calendar'
import SearchIcon from 'assests/icons/search_icon.svg'

const renderTextField = ({
    name,
    value,
    placeholder,
    props,
}) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchDepartmentHeadInput = (props) => {
    const {
        disabled,
        handleAddRow,
        valueDefault,
        placeholder,
    } = props

    const [value, setValue] = React.useState({
        id: '',
        first_name: '',
        last_name: '',
    })
    const [inputValue, setInputValue] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [open, setOpen] = React.useState(false)

    useEffect(() => {
        setValue(valueDefault)
    }, [valueDefault])

    const getDepartmentHeadData = useCallback(
        debounce(async (val) => {
            if (!val.length) return

            try {
                setLoading(true)
                const res = await getAccountList({
                    name: val,
                })
                setOptions(
                    res?.data?.result.map((item) => ({
                        id: item.id,
                        last_name: item.last_name,
                        first_name: item.first_name,
                    })),
                )

                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }, 500),
        [],
    )

    React.useEffect(() => {
        let active = true
        if (!open) return
        ;(async () => {
            setLoading(true)

            if (active) {
                await getDepartmentHeadData(inputValue)
            }
            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [open, value, inputValue])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={
                    <SearchIcon style={{ marginTop: 4 }} />
                }
                selectOnFocus
                clearIcon={false}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                value={value}
                inputValue={inputValue}
                open={open}
                renderOption={(
                    props,
                    option,
                    { selected },
                ) => {
                    return (
                        <li {...props} key={option?.id}>
                            <td>
                                <span className="hightlight">
                                    {`${option?.first_name} ${option?.last_name} `}
                                </span>
                                {' - '}
                                <span
                                    className="hightlight"
                                    style={{
                                        color: 'rgb(85, 113, 198)',
                                    }}>
                                    {option.id}
                                </span>
                            </td>
                        </li>
                    )
                }}
                filterOptions={() => {
                    return options
                }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    setOpen(false)
                    handleAddRow(newValue)
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                getOptionLabel={(option) => {
                    return `${option.first_name || ''}${
                        option.last_name
                            ? ` ${option.last_name}`
                            : ''
                    }`
                    // return option?.first_name &&
                    //     option?.last_name
                    //     ? `${option?.first_name || ''} ${
                    //           option?.last_name || ''
                    //       }`
                    //     : ''
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        placeholder:
                            placeholder || 'Search...',
                        props: {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img
                                                src={
                                                    SearchIcon
                                                }
                                                alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : null}
                                    </React.Fragment>
                                ),
                            },
                        },
                    })
                }
            />
        </Box>
    )
}

export default SearchDepartmentHeadInput
