import React, { useRef, useEffect, useState } from 'react'
import { RBACProvider, RBACWrapper } from 'react-simple-rbac'
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    makeStyles,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid } from '@mui/x-data-grid'
import clsx from 'clsx'
import { connect, useSelector } from 'react-redux'
import { debounce } from 'lodash'

import SearchIcon from 'assests/icons/search_icon.svg'
import { Button as ButtonCustom } from 'components/custom'
import {
    getDepartmentList,
    createDepartment,
    updateDepartment,
    deleteDepartment,
} from 'services/Calendar/index'

import { NoRowsOverlayEl } from 'shared/elements'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import AlertConfirmDialog from 'components/shared/Elements/AlertConfirmDialog'
import AlertDeleteDialog from 'components/shared/Elements/AlertDeleteDialog'
import AlertErrorDialog from 'components/shared/Elements/AlertErrorDialog'

import DepartmentsSettingsDialog from './DepartmentsSettingsDialog'
import SearchDepartmentHeadInput from 'containers/Departments/SearchDepartmentHeadInput'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { useDebounce } from 'shared/hooks'

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_search: {
        background: '#5571C6',
        '&:hover': {
            background: '#5571C6',
        },
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    search_input: {
        width: 'auto',
        height: 32,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    search_input_root: {
        height: 32,
        minHeight: 32,
        backgroundColor: '#F5F6F7 ',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
            },
        },
    },
})

const renderEditAction = (params, actions, permissions, notification) => {
    const userMenus = []
    if (permissions.includes('modify_organizational_unit')) {
        userMenus.push({
            name: 'Edit',
            action: 'onEditClick',
        })
    }
    if (permissions.includes('delete_organizational_unit')) {
        userMenus.push({
            name: 'Delete',
            action: 'onDeleteClick',
        })
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const { refetchAccount, handleEditClick } = actions
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const [loading, setLoading] = useState(false)
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

    const [alertConfirmOpen, setAlertConfirmOpen] = useState({
        status: false,
        type: '',
    })

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onEditClick: () => {
            setAnchorEl(null)
            handleEditClick(params)
        },
        onDeleteClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertDeleteOpen(true)
            }
        },
        onDisableClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertConfirmOpen({
                    status: true,
                    type: 'disable',
                })
            }
        },
        onEnableClick: () => {
            setAnchorEl(null)
            if (params?.id) {
                setAlertConfirmOpen({
                    status: true,
                    type: 'enable',
                })
            }
        },
    }

    const handleDeleteAccount = () => {
        setLoading(true)
        deleteDepartment(params.id)
            .then(() => {
                setLoading(false)
                refetchAccount()
                handleClose()
                setAlertDeleteOpen(false)
                notification('A department deleted successfully!', 'success')
            })
            .catch((e) => {
                setLoading(false)
                setAlertDeleteOpen(false)
                notification('Cannot delete this department. Please try again!', 'error')
            })
    }

    return (
        <Grid item>
            {/* <RBACProvider permissions={permissions}>
                <RBACWrapper
                    requiredPermissions={[
                        'deactivate_user_security_accounts',
                        'delete_user_security_accounts',
                    ]}> */}
            <IconButton
                size="small"
                onClick={handleClick}
                style={{
                    background: '#FFFFFF',
                    border: '1px solid #E9ECF1',
                    borderRadius: '20px',
                }}>
                <MoreHorizIcon
                    style={{
                        color: '#3E465B',
                        fontSize: 18,
                    }}
                />
            </IconButton>
            {/* </RBACWrapper>
            </RBACProvider> */}

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 120,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        key={index}
                        onClick={handleFunc[menu.action]}
                        style={{
                            color: menu.name === 'Delete' ? '#E81212' : '#667689',
                        }}>
                        {menu.name}
                    </MenuItem>
                ))}
            </Menu>
            <AlertDeleteDialog
                loading={loading}
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleDeleteAccount()
                }}
            />

            <AlertConfirmDialog
                loading={loading}
                open={alertConfirmOpen?.status}
                type={alertConfirmOpen?.type}
                handleClose={() => {
                    setAlertConfirmOpen({
                        status: false,
                        type: '',
                    })
                }}
                handleDelete={() => {}}
            />
        </Grid>
    )
}

const renderActions = (params, actions, permissions, notification) => {
    return (
        <Grid container alignItems="center" direction="column" spacing={2}>
            {renderEditAction(params, actions, permissions, notification)}
        </Grid>
    )
}

const Departments = () => {
    const classes = useStyles()
    // const [page, setPage] = useState(1)
    const notification = useNotification()

    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [openChangePassDialog, setOpenChangePassDialog] = React.useState(false)
    const [userSecurityModal, setUserSecurityModal] = React.useState({
        status: false,
    })

    const [filterData, setFilterData] = useState({
        head: '',
    })

    const [isLoading, setLoading] = useState(false)
    const [textSearch, setTextSearch] = useState('')
    const [alertErrorOpen, setAlertErrorOpen] = useState('')
    const [patientList, setPatientList] = useState([])
    // const [paginationData, setPaginationData] = useState({
    //     total: 0,
    //     start: 1,
    //     limit: 10,
    // })

    const [isClear, setIsClear] = useState(false)

    const debouncedSearchText = useDebounce(textSearch, 500)

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const onPageChange = (value) => {
        setPage(value)
    }

    const permissions = useSelector((state) => state?.permission?.organizational_units)

    function userSecurityModalToggle() {
        setUserSecurityModal({
            status: !userSecurityModal.status,
            itemId: null,
        })
    }
    function changePassDialogToggle() {
        setOpenChangePassDialog(!openChangePassDialog)
    }

    const columns = [
        {
            headerName: <Typography variant="body1">Department</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return `${value}`
            },
        },

        {
            headerName: <Typography variant="body1">Department Head</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'head',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return `${value?.first_name || ''} ${value?.last_name || ''}`
            },
        },
        {
            headerName: (
                <Grid container justifyContent="center" id="fucking">
                    <Typography variant="body1">Actions</Typography>
                </Grid>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            hide:
                !permissions.includes('modify_organizational_unit') &&
                !permissions.includes('delete_organizational_unit'),
            renderCell: (params) => {
                return renderActions(
                    params,
                    {
                        handleEditClick,
                        refetchAccount,
                    },
                    permissions,
                    notification,
                )
            },
        },
    ]
    const handleEditClick = (params) => {
        setUserSecurityModal({
            status: !userSecurityModal.status,
            itemId: params?.row?.id,
        })
    }

    const handleSubmit = async (payload) => {
        let promise, successMsg, errorMsg

        if (payload.id) {
            promise = updateDepartment({
                id: payload.id,
                payload,
            })
            successMsg = 'A department updated successfully!'
            errorMsg = "A department didn't updated successfully!"
        } else {
            promise = createDepartment(payload)
            successMsg = 'A department created successfully!'
            errorMsg = "A department didn't created successfully!"
        }

        setIsSubmitting(true)

        promise
            .then(() => {
                userSecurityModalToggle()
                notification(successMsg, 'success')
                refetchAccount()
            })
            .catch((error) => notification(error?.data?.message || errorMsg, 'error'))
            .finally(() => setIsSubmitting(false))
    }

    const handleClearFilter = async () => {
        setIsClear(true)

        setTextSearch('')
        setFilterData({
            head: { id: '' },
        })

        if (page !== 1) setPage(1)
        else await refetchAccount(1, '', '')

        setTimeout(() => {
            setIsClear(false)
        }, 1000)
    }

    const refetchAccount = async (
        start = page,
        name = textSearch,
        head_id = filterData?.head?.id,
    ) => {
        if (!start) return

        setLoading(true)
        try {
            const res = await getDepartmentList({
                page: start,
                name: name.trim(),
                head_id,
                limit,
            })
            setLoading(false)
            setPatientList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    // const debounced = useRef(debounce((params) => refetchAccount(params), 800))

    useEffect(() => {
        refetchAccount()
    }, [page])

    useEffect(async () => {
        if (isClear) return

        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        await refetchAccount(1)
    }, [debouncedSearchText])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                    <Typography className={classes.header}>Department Management</Typography>
                    <Grid item>
                        <Grid container>
                            <Grid
                                container
                                item
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                                style={{ flex: 1 }}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    wrap="nowrap"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    style={{
                                        gap: 8,
                                        marginRight: 16,
                                        flex: 1,
                                    }}>
                                    <Grid
                                        item
                                        style={{
                                            maxWidth: 280,
                                            // flex: 1,
                                        }}>
                                        <TextField
                                            className={clsx({
                                                [classes.search_input]: true,
                                                // [classes.show_search]: !openSearch,
                                            })}
                                            placeholder="Search..."
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            value={textSearch}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton size="small">
                                                            <img src={SearchIcon} alt=""></img>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {textSearch && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => setTextSearch('')}>
                                                                <CloseIcon
                                                                    style={{
                                                                        fontSize: 16,
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                                disableUnderline: true,
                                                classes: {
                                                    notchedOutline: classes.notchedOutline,
                                                    root: classes.search_input_root,
                                                },
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        style={{
                                            flex: 1,
                                            width: 220,
                                        }}>
                                        <Box
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: 32,
                                                    minHeight: 32,
                                                    padding: '0px 16px !important',
                                                },
                                            }}>
                                            <SearchDepartmentHeadInput
                                                placeholder={'Department Head'}
                                                valueDefault={filterData.head}
                                                handleAddRow={(value) => {
                                                    setFilterData((prev) => ({
                                                        ...prev,
                                                        head: value,
                                                    }))

                                                    if (page !== 1) return setPage(1)
                                                    refetchAccount(1, undefined, value?.id)
                                                }}
                                            />
                                        </Box>
                                    </Grid>

                                    {(textSearch || filterData?.head?.id) && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={clsx(
                                                    classes.btn_add,
                                                    classes.btn_search,
                                                )}
                                                onClick={handleClearFilter}
                                                fullWidth>
                                                Clear
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <RBACProvider permissions={permissions}>
                                <RBACWrapper requiredPermissions={['create_organizational_unit']}>
                                    <Grid item>
                                        <ButtonCustom
                                            tooltip="Add New Department"
                                            variant="contained"
                                            color="primary"
                                            onClick={userSecurityModalToggle}
                                            fullWidth>
                                            <AddIcon
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </ButtonCustom>
                                    </Grid>
                                </RBACWrapper>
                            </RBACProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle': {
                                width: '100%',
                                '& .MuiTypography-body1': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext': {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                            {
                                width: 'auto',
                                visibility: 'visible',
                            },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={onPageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        componentsProps={{
                            pagination: {
                                onPageChange,
                                total,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                total,
                                numberRecord: limit,
                                page,
                                pageSize: limit,
                            },
                        }}
                    />
                </Box>
            </Grid>
            {userSecurityModal.status && (
                <DepartmentsSettingsDialog
                    open={userSecurityModal.status}
                    itemId={userSecurityModal.itemId}
                    loading={isSubmitting}
                    onModalClose={userSecurityModalToggle}
                    onSubmit={handleSubmit}
                    changePassDialogToggle={changePassDialogToggle}
                />
            )}

            {Boolean(alertErrorOpen) && (
                <AlertErrorDialog
                    message={alertErrorOpen}
                    open={Boolean(alertErrorOpen)}
                    handleClose={() => {
                        setAlertErrorOpen(false)
                    }}
                />
            )}
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Departments)
