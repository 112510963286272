import { formatString } from "containers/CalendarScheduler/utils"

export const headerNames = (values) => {
    if (!values || (values && Object.keys(values).length === 0)) return

    const {copiedDate = null, ...parsedValues} = values
    const headers = Object.keys(parsedValues || {})
    return headers.map((header) => {
        switch (header) {
            case 'constitutional': {
                return {
                    header: formatString(header),
                    selected: true,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Chronic pain',
                            checked: false,
                        },
                        {
                            display: 'Unexplained weight loss',
                            checked: false,
                        },
                        {
                            display: 'Unexplained fever',
                            checked: false,
                        },
                        {
                            display: 'Sleeping pattern disruption',
                            checked: false,
                        },
                        {
                            display: 'Loss of appetite',
                            checked: false,
                        },
                        {
                            display: 'Weight gain',
                            checked: false,
                        },
                        {
                            display: 'Hot or Cold spells',
                            checked: false,
                        },
                        {
                            display: 'Malaise (Flu-like or Vague sick feeling)',
                            checked: false,
                        },
                        {
                            display: 'Increase in appetite',
                            checked: false,
                        },
                        {
                            display: 'Fatigue/Lethargy',
                            checked: false,
                        },
                        {
                            display: 'Night sweats',
                            checked: false,
                        },
                    ],
                }
            }
            case 'eyes': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Eye pain',
                            checked: false,
                        },
                        {
                            display: 'Blurred or double vision',
                            checked: false,
                        },
                        {
                            display: 'Sensitivity to light',
                            checked: false,
                        },
                        {
                            display:
                                'Amaurosis fugax (Feeling like a curtain is pulled over vision)',
                            checked: false,
                        },
                        {
                            display: 'Eye discharge',
                            checked: false,
                        },
                        {
                            display: 'Visual change',
                            checked: false,
                        },
                        {
                            display: 'Scotomas (Blind spots)',
                            checked: false,
                        },
                        {
                            display: 'History of eye surgery',
                            checked: false,
                        },
                        {
                            display: 'Retinal hemorrhage (Floaters in vision)',
                            checked: false,
                        },
                        {
                            display: 'Eye redness',
                            checked: false,
                        }
                    ],
                }
            }
            case 'ears_nose_mouth_throat': {
                return {
                    header: formatString(header)?.split(' ')?.join(', '),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Earache',
                            checked: false,
                        },
                        {
                            display: 'Frequent ear infections',
                            checked: false,
                        },
                        {
                            display: 'Runny nose/Post-nasal drip',
                            checked: false,
                        },
                        {
                            display: 'Prolonged hoarseness',
                            checked: false,
                        },
                        {
                            display: 'Tinnitus (Ringing in ears)',
                            checked: false,
                        },
                        {
                            display: 'Frequent nose bleeds',
                            checked: false,
                        },
                        {
                            display: 'Difficulty swallowing',
                            checked: false,
                        },
                        {
                            display: 'Pain in jaw or tooth',
                            checked: false,
                        },
                        {
                            display: 'Decreased hearing or hearing loss',
                            checked: false,
                        },
                        {
                            display: 'Sinus congestion',
                            checked: false,
                        },
                        {
                            display: 'Frequent sore throat',
                            checked: false,
                        },
                        {
                            display: 'Dry mouth',
                            checked: false,
                        },
                    ],
                }
            }
            case 'cardiovascular': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Chest pain',
                            checked: false,
                        },
                        {
                            display: 'Swollen feet or hands',
                            checked: false,
                        },
                        {
                            display: 'Pacemaker',
                            checked: false,
                        },
                        {
                            display: 'Fainting spells',
                            checked: false,
                        },
                        {
                            display: 'Palpitations (fast or irregular heartbeat)',
                            checked: false,
                        },
                        {
                            display: 'Shortness of breath with exercise',
                            checked: false,
                        },
                    ],
                }
            }
            case 'respiratory': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Pain with breathing',
                            checked: false,
                        },
                        {
                            display: 'Chronic wheezing/Asthma',
                            checked: false,
                        },
                        {
                            display: 'Nocturnal Dyspnea (Shortness of breath at night)',
                            checked: false,
                        },
                        {
                            display: 'Chronic cough',
                            checked: false,
                        },
                        {
                            display: 'Excessive phlegm',
                            checked: false,
                        },
                        {
                            display: 'Chronic shortness of breath',
                            checked: false,
                        },
                        {
                            display: 'Coughing blood',
                            checked: false,
                        },
                    ],
                }
            }
            case 'musculoskeletal': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Swelling in joints',
                            checked: false,
                        },
                        {
                            display: 'Muscle pain or cramping',
                            checked: false,
                        },
                        {
                            display: 'Decreased range of motion',
                            checked: false,
                        },
                        {
                            display: 'Past injury to spine or joints',
                            checked: false,
                        },
                        {
                            display: 'Redness of joints',
                            checked: false,
                        },
                        {
                            display: 'Muscle weakness',
                            checked: false,
                        },
                        {
                            display: 'Back pain or stiffness',
                            checked: false,
                        },
                        {
                            display: 'Other joint pains or stiffness',
                            checked: false,
                        },
                        {
                            display: 'Muscle stiffness',
                            checked: false,
                        },
                        {
                            display: 'History of fractures',
                            checked: false,
                        },
                    ],
                }
            }
            case 'gastrointestinal': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Excessive flatulence or belching',
                            checked: false,
                        },
                        {
                            display: 'Persistent nausea/vomiting',
                            checked: false,
                        },
                        {
                            display: 'Difficulty swallowing solids or liquids',
                            checked: false,
                        },
                        {
                            display: 'Jaundice (yellow skin)',
                            checked: false,
                        },
                        {
                            display: 'Dark/Tarry stool',
                            checked: false,
                        },
                        {
                            display: 'Diarrhea',
                            checked: false,
                        },
                        {
                            display: 'Abdominal Pain',
                            checked: false,
                        },
                        {
                            display: 'Recent loss in appetite',
                            checked: false,
                        },
                        {
                            display: 'Change in appearance of stool',
                            checked: false,
                        },
                        {
                            display: 'Loss of bowel control/soiling',
                            checked: false,
                        },
                        {
                            display: 'Constipation',
                            checked: false,
                        },
                        {
                            display: 'Heartburn',
                            checked: false,
                        },
                        {
                            display: 'Sensitivity to milk products',
                            checked: false,
                        },
                        {
                            display: 'Blood in stool',
                            checked: false,
                        },
                    ],
                }
            }
            case 'endocrine': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Severe menopausal symptoms',
                            checked: false,
                        },
                        {
                            display: 'Excessive thirst or urination',
                            checked: false,
                        },
                        {
                            display: 'Cold or heat intolerance',
                            checked: false,
                        },
                        {
                            display: 'Excessive sweating',
                            checked: false,
                        },
                        {
                            display: 'Excessive appetite',
                            checked: false,
                        },
                    ],
                }
            }
            case 'allergic_or_immunologic': {
                return {
                    header: formatString(header)?.split(' Or ')?.join('/'),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Frequent infections',
                            checked: false,
                        },
                        {
                            display: 'Hives',
                            checked: false,
                        },
                        {
                            display: 'Anaphylaxic reaction',
                            checked: false,
                        },
                    ],
                }
            }
            case 'hematologic_or_lymphatic': {
                return {
                    header: formatString(header)?.split(' Or ')?.join('/'),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Blood Clots',
                            checked: false,
                        },
                        {
                            display: 'Excessive bruising',
                            checked: false,
                        },
                        {
                            display: 'Excess/easy bleeding (surgery, dental work, brushing teeth, scrapes)',
                            checked: false,
                        },
                        {
                            display: 'Swollen glands (neck, armpits, groin)',
                            checked: false,
                        },
                        {
                            display: 'History of blood transfusion',
                            checked: false,
                        },
                    ],
                }
            }
            case 'genitourinary_general': {
                return {
                    header: 'Genitourinary (General)',
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Loss of urine control (including bed-wetting)',
                            checked: false,
                        },
                        {
                            display: 'Increased frequency of urination',
                            checked: false,
                        },
                        {
                            display: 'Frequent urine infections',
                            checked: false,
                        },
                        {
                            display: 'Painful/Burning urination',
                            checked: false,
                        },
                        {
                            display: 'Up more than twice/night to urinate',
                            checked: false,
                        },
                        {
                            display: 'Blood in urine',
                            checked: false,
                        },
                        {
                            display: 'Urine retention',
                            checked: false,
                        },
                    ],
                }
            }
            case 'genitourinary_women': {
                return {
                    header: 'Genitourinary (Women)',
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Unusual vaginal discharge',
                            checked: false,
                        },
                        {
                            display: 'Heavy or irregular periods',
                            checked: false,
                        },
                        {
                            display: 'Sterility/Infertility',
                            checked: false,
                        },
                        {
                            display: 'Vaginal pain, bleeding, soreness, or dryness',
                            checked: false,
                        },
                        {
                            display: 'No menses (Periods stopped)',
                            checked: false,
                        },
                        {
                            display: 'Any other sexual/sex organ concerns',
                            checked: false,
                        },
                        {
                            display: 'Genital sores',
                            checked: false,
                        },
                        {
                            display: 'Currently pregnant',
                            checked: false,
                        },
                    ],
                }
            }
            case 'genitourinary_men': {
                return {
                    header: 'Genitourinary (Men)',
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Slow urine stream',
                            checked: false,
                        },
                        {
                            display: 'Abnormal penis discharge',
                            checked: false,
                        },
                        {
                            display: 'Any other sexual or sex organ concerns',
                            checked: false,
                        },
                        {
                            display: 'Scrotal pain',
                            checked: false,
                        },
                        {
                            display: 'Trouble getting/maintaining erections',
                            checked: false,
                        },
                        {
                            display: 'Lump or mass in the testicles',
                            checked: false,
                        },
                        {
                            display: 'Inability to ejaculate/orgasm',
                            checked: false,
                        },
                    ],
                }
            }
            case 'neurological': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Paralysis',
                            checked: false,
                        },
                        {
                            display: 'Drowsiness',
                            checked: false,
                        },
                        {
                            display: 'Short term memory trouble',
                            checked: false,
                        },
                        {
                            display: 'Muscle weakness',
                            checked: false,
                        },
                        {
                            display: 'Tremor in hands/shaking',
                            checked: false,
                        },
                        {
                            display: 'Fainting spells or blackouts',
                            checked: false,
                        },
                        {
                            display: 'Slurred speech',
                            checked: false,
                        },
                        {
                            display: 'Memory difficulties (loss)',
                            checked: false,
                        },
                        {
                            display: 'Numbness/Tingling sensations',
                            checked: false,
                        },
                        {
                            display: 'Muscle spasms or tremors',
                            checked: false,
                        },
                        {
                            display: 'Dizziness/Vertigo',
                            checked: false,
                        },
                        {
                            display: 'Speech problems (other)',
                            checked: false,
                        },
                        {
                            display: 'Frequent headaches',
                            checked: false,
                        },
                        {
                            display: 'Neuropathy (numbness in feet)',
                            checked: false,
                        },
                    ],
                }
            }
            case 'integumentary': {
                return {
                    header: `${formatString(header)} \n(Skin, Breast, Hair)`,
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'Lesions',
                            checked: false,
                        },
                        {
                            display: 'Increased perspiration',
                            checked: false,
                        },
                        {
                            display: 'Itchy skin or scalp',
                            checked: false,
                        },
                        {
                            display: 'Breast tenderness',
                            checked: false,
                        },
                        {
                            display: 'Unusual mole',
                            checked: false,
                        },
                        {
                            display: 'Rashes',
                            checked: false,
                        },
                        {
                            display: 'Hair or nail changes',
                            checked: false,
                        },
                        {
                            display: 'Breast discharge',
                            checked: false,
                        },
                        {
                            display: 'Easy bruising',
                            checked: false,
                        },
                        {
                            display: 'Chronic dry skin',
                            checked: false,
                        },
                        {
                            display: 'Hair loss',
                            checked: false,
                        },
                        {
                            display: 'Breast lump or mass',
                            checked: false,
                        },
                    ],
                }
            }
            case 'psychiatric': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                    checkboxOptions: [
                        {
                            display: 'In-depth review of psychiatric system appears earlier in document (to be checked by clinician only)',
                            checked: false,
                        },
                        {
                            display: 'Phobias/Unexplained fears',
                            checked: false,
                        },
                        {
                            display: 'Insomnia',
                            checked: false,
                        },
                        {
                            display: 'Disturbing thoughts',
                            checked: false,
                        },
                        {
                            display: 'Memory loss',
                            checked: false,
                        },
                        {
                            display: 'Feeling depressed',
                            checked: false,
                        },
                        {
                            display: 'No pleasure from life anymore',
                            checked: false,
                        },
                        {
                            display: 'Excessive moodiness',
                            checked: false,
                        },
                        {
                            display: 'Manic episodes',
                            checked: false,
                        },
                        {
                            display: 'Nightmares',
                            checked: false,
                        },
                        {
                            display: 'Difficulty concentrating',
                            checked: false,
                        },
                        {
                            display: 'Anxiety',
                            checked: false,
                        },
                        {
                            display: 'Stress',
                            checked: false,
                        },
                        {
                            display: 'Confusion',
                            checked: false,
                        },
                    ],
                }
            }
            case 'narrative': {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                }
            }
            default: {
                return {
                    header: formatString(header),
                    selected: false,
                    nativeName: header,
                }
            }
        }
    })
}
