import React from 'react'
import { Button as ButtonComponent } from '@material-ui/core'
import './index.scss'

const ButtonHover = (props) => {
    return (
        <ButtonComponent
            disabled={props.disabled}
            className={`custom-button ${
                props.imgAlignRight ? 'align-right' : ''
            }`}
            {...props}>
            {props.imgPath && props.imgPath !== '' && (
                <img
                    className="button-img"
                    src={props.imgPath}
                />
            )}
            {props.children}
        </ButtonComponent>
    )
}

export default ButtonHover
