
import { CareTeamInterfaces } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService
} from 'utilities/request/restClient'

// interface Payload {
//     page?: string
//     limit?: string 
// }

export const apiCareTeam = {
  create(patienrId: string | number, params: CareTeamInterfaces) {
      return postService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/care-team`,
          params,
      )
  },
  getAll(patienrId, payload) {
      const queryString = new URLSearchParams({
          ...payload,
      }).toString()
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/care-team?${queryString}`,
      )
  },
  getId(patientId, careTeamId) {
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/care-team/${careTeamId}`,
      )
  },
  update(patientId: string | number, careTeamId: string | number , payload: CareTeamInterfaces) {
      return patchService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/care-team/${careTeamId}`,
          payload,
      )
  },
  delete(patientId: string | number, careTeamId: string | number ) {
      return deleteService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/care-team/${careTeamId}`
      )
  },
}