const RefreshIcon = () => {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1516 7.1194C12.8322 5.92755 12.1095 4.8833 11.1065 4.16458C10.1036 3.44587 8.88243 3.09715 7.65118 3.17785C6.41993 3.25855 5.25475 3.76368 4.35417 4.60716C3.45359 5.45064 2.87334 6.58029 2.71229 7.80363C2.55123 9.02696 2.81934 10.2683 3.47092 11.3161C4.1225 12.364 5.11724 13.1534 6.28565 13.5501C7.45406 13.9467 8.72385 13.9259 9.87866 13.4913C11.0335 13.0566 12.0019 12.235 12.6188 11.1664"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinejoin="round"
            />
            <path
                d="M13.3334 7.16662L13.9129 7.32191C13.8272 7.64199 13.4982 7.83194 13.1781 7.74618L13.3334 7.16662ZM14.6031 4.74611L13.9129 7.32191L12.7538 7.01133L13.444 4.43553L14.6031 4.74611ZM13.1781 7.74618L10.6023 7.05599L10.9129 5.89688L13.4887 6.58707L13.1781 7.74618Z"
                fill="#667689"
            />
        </svg>
    )
}

export default RefreshIcon
