import { useState } from 'react'
import { useHistory } from 'react-router-dom'

//mui-components
import { Popover, Grid, Typography } from '@mui/material'

//utility functions and components
import { EVENT_TYPE, DRAFT_APPOINTMENT_TOOLTIP } from '../../../constant'
import { EventContentGeneral, EventContentTimeOffMonth } from '../eventContent'
import Routes from 'constants/routes'
import { UserRoles } from 'constants/user-roles'
import { hexToRgb } from 'containers/CalendarScheduler/utils'
import MonthCard from './monthCard'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

import { useStyles } from '../../useStyles'

function MonthCalendarEvent(props) {
    const classes = useStyles()
    const { event } = props
    const history = useHistory()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        if (!props.isSchedulerForm) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined

    function handlePatientNameClick(id) {
        history.push(Routes.PROFILE, {
            userId: id,
            role: UserRoles.PATIENT,
        })
    }

    if (props.event.type === EVENT_TYPE.TIMEOFF) {
        return (
            <>
                <Popover
                    id={endId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: '0px 0px 10px #888888',
                        },
                    }}>
                    <EventContentTimeOffMonth {...props.event} classes={classes} />
                </Popover>

                <span style={{ cursor: 'pointer' }} onClick={handleClick}>
                    <Grid container className="event-wrap">
                        <Typography noWrap variant="body1">
                            <strong>{props.title}</strong>
                        </Typography>
                    </Grid>
                </span>
            </>
        )
    }

    if (props.event.type === EVENT_TYPE.BUSY_SLOT) {
        return (
            <Grid container className="event-wrap">
                <Typography noWrap variant="body1">
                    <strong>{props.title}</strong>
                </Typography>
            </Grid>
        )
    }

    return (
        <>
            {!props.isSchedulerForm && <Popover
                id={endId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                    },
                }}>
                <EventContentGeneral
                    {...props.event}
                    classes={classes}
                    timezone={props.timezone}
                    onSelectEvent={props.onSelectEvent}
                    onSelectEventStaff={props.onSelectEventStaff}
                    refetchSchedule={props.refetchSchedule}
                    optimisticDelete={props.optimisticDelete}
                    optimisticUpdate={props.optimisticUpdate}
                    handlePatientNameClick={handlePatientNameClick}
                    getApiEncounterQueue={props.getApiEncounterQueue}
                    handleClose={handleClose}
                />
            </Popover>}
            <HtmlTooltip  title={props.isDraft ? DRAFT_APPOINTMENT_TOOLTIP : ''}
                arrow
                placement="top"
                style={{
                    background: 'white !important',
                    color: '#303E4E',
                }}>
            <span
                style={{ 
                    cursor: props.isDraft ? 'not-allowed' : !props.isSchedulerForm && 'pointer',
                    opacity: props.isDraft ? 0.6 : 1
                }}
                onClick={props?.event?.id && !props.isDraft && handleClick}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    className={classes.item_event}
                    style={{
                        width: '130px',
                        borderTop: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderBottom: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderRight: `2px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        borderLeft: `4px solid ${props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF')
                            }`,
                        background: hexToRgb(
                            props.event.color_tag ||
                            (props.event?.category?.toLowerCase() !== 'patient'
                                ? '#d9d9d9'
                                : '#F1F8FF'),
                        ),
                    }}
                >
                    <MonthCard classes={classes} event={event} />
                </Grid>
            </span>
            </HtmlTooltip>
        </>
    )
}

export default MonthCalendarEvent
