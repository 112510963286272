
export const INITIAL_DATA = {
  address1: "",
  address2: "",
  company_name: "",
  id: "",
  phone_number: "",
  plan_name: "",
  zip: "",
  state: "",
  city: "",
}
