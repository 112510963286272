import { Box, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@mui/material'
import Routes from 'constants/routes'
import { Card } from 'containers/PatientsSetting/view/CardHealthRecord'
import { ImmunizationsCreateDialog } from 'containers/PatientsSetting/view/Immunizations/ImmunizationsCreateDialog'
import moment from 'moment'
import React, { Suspense, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { allApiLabOrder } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import CHC from './CHC'
import { LabCreateNew } from './LabCreateNew'
import { Loading } from 'shared/elements/Loading'
import { NoData } from '../NoData'
import { getLabResultStatus } from 'utilities/labResults'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    patientId?: string
}

const useStyles = makeStyles(() => ({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    viewall: {
        color: '#667689',
        '&:hover': {
            color: '#4e52d9',
        },
    },
}))

const INITIAL_LABS = [
    {
        id: 1,
        lab_order: 'Blood tests',
        date_order: '2023-01-05T20:01:00+00:00',
    },
    {
        id: 2,
        lab_order: 'Lorem',
        date_order: '2023-01-05T20:01:00+00:00',
    },
    {
        id: 3,
        lab_order: 'Blood tests',
        date_order: '2023-01-05T20:01:00+00:00',
    },
    {
        id: 4,
        lab_order: 'Lorem ipsum dolor sit amet',
        date_order: '2023-01-05T20:01:00+00:00',
    },
    {
        id: 5,
        lab_order: 'Lorem ipsum dolor sit amet',
        date_order: '2023-01-05T20:01:00+00:00',
    },
    {
        id: 6,
        lab_order: 'Lorem ipsum dolor sit amet',
        date_order: '2023-01-05T20:01:00+00:00',
    },
]

export const Labs = (props: Props) => {
    const history = useHistory()
    const classes = useStyles()
    const [isAddingNew, setIsAddingNew] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>()
    const [openCHC, setOpenCHC] = useState<boolean>(false)
    const [patientId, setPatientId] = useState('')
    const [labs, setLabs] = useState([])
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()
    const [isLoadingButton, setIsLoadingButton] = useState(false)

    useEffect(() => {
        if (props.patientId || id) setPatientId(props.patientId || id)
    }, [props.patientId, id])

    const handleViewAll = () => {
        history.push(Routes.PATIENT.LAB_ORDERS.replace(':id', patientId), {
            from: 'patient-dashboard',
        })
    }
    const fetchLab = async () => {
        console.log('fetchLab')
    }

    const getLabOrderByPatient = async (patientId) => {
        setLoading(true)
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId)

            const formatData =
                res.data?.data.map((item) => ({
                    ...item,
                    lab_order: item.lab_code,
                    date_order: item.creation_datetime,
                    status: getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status),
                })) || []

            setLabs(formatData)
        } catch (error) {
            console.log(error)
            notification('Get Lab order error', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) getLabOrderByPatient(patientId)
        return () => abortController.abort()
    }, [patientId])
    const sendLabUpdateTime = async () => {
        try {
            setIsLoadingButton(true)
            const res = await allApiLabOrder.syncLabOrder()
            if (res) {
                if (patientId) getLabOrderByPatient(patientId)
                setOpenCHC(false)
                setIsLoadingButton(false)
            }
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Card
            // menu={[
            //     {
            //         name: 'Add',
            //         handler: () => setOpenCHC(true),
            //     },
            //     // {
            //     //     name: 'Add',
            //     //     handler: () => {
            //     //         setIsAddingNew(true)
            //     //     },
            //     // },
            //     {
            //         name: 'View all',
            //         handler: props.viewAllFunc || handleViewAll,
            //     },
            // ]}
            viewAllHandler={props?.viewAllFunc || handleViewAll}
            addHandler={() => setOpenCHC(true)}
            onClick={props.onClick}
            header="Labs"
            onRefresh={() => sendLabUpdateTime()}
        >
            {!!loading && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress color="inherit" thickness={1.5} />
                </Grid>
            )}
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />
                    {labs.length > 0 &&
                        labs.map((item: any, index) => (
                            <Grid
                                container
                                key={index}
                                justifyContent="space-between"
                                wrap="nowrap"
                                alignItems="center"
                                style={{
                                    borderBottom: '1px solid #E4E8EE',
                                    paddingBottom: 8,
                                }}>
                                <Grid
                                    item
                                    container
                                    style={{
                                        width: 'fit-content',
                                        height: '100%',
                                    }}>
                                    <div className={classes['line_hor']}></div>
                                    <Grid
                                        item
                                        container
                                        alignItems="flex-start"
                                        direction="column"
                                        style={{
                                            width: 'fit-content',
                                        }}>
                                        <Typography>
                                            {item.placer_order_number
                                                ? `${item.placer_order_number} - `
                                                : ''}
                                            {item.sponsor_name ? `${item.sponsor_name} - ` : ''}
                                            {item.lab_order}{' '}
                                        </Typography>
                                        <Typography
                                            style={{
                                                color: '#9CA9B8',
                                                fontSize: 12,
                                            }}>
                                            {item?.date_order
                                                ? moment(item?.date_order).format('MMM DD,YYYY')
                                                : ''}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography className={classes['tag_type_red']}>
                                    {item?.status}
                                </Typography>
                            </Grid>
                        ))}
                    {!labs.length && <NoData />}
                </Grid>
            </Suspense>

            {openCHC && (
                <CHC
                    open={openCHC}
                    patientId={patientId}
                    handleClose={() => setOpenCHC(false)}
                    handleSuccess={() => sendLabUpdateTime()}
                    description={undefined}
                    isLoadingButton={isLoadingButton}
                />
            )}

            {!!labs.length && (
                <Grid container item justifyContent="flex-end">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all
                    </span>
                </Grid>
            )}

            {!!isAddingNew && (
                <LabCreateNew
                    open={isAddingNew}
                    itemId=""
                    patientId={patientId}
                    refetchData={fetchLab}
                    onClose={() => {
                        setIsAddingNew(false)
                    }}
                />
            )}
        </Card>
    )
}
