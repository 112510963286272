import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { OutpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'

type Props = {
    dataSource?: OutpatientPsychiatric[]
    pagination?: Pagination
    sortModel?: GridSortModel
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    page?: number
    onPageChange?: (page: number) => void
    handleEdit?: (id: string | number) => void
    handleDelete?: (id: string | number) => void
    nonEditable?:Boolean
}

export const OutpatientPsychiatricHistoryDashboard = (props: Props) => {
    // Hooks
    const notification = useNotification()

    const columns: GridColumns<OutpatientPsychiatric> = [
        {
            headerName: 'Provider',
            headerClassName: 'super-app-theme--header',
            field: 'provider_id',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.provider_id} />,
        },
        {
            headerName: 'Reason',
            headerClassName: 'super-app-theme--header',
            field: 'reason',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.reason} />,
        },
        {
            headerName: 'Age of First Treatment',
            headerClassName: 'super-app-theme--header',
            field: 'age_of_first_treatment',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.age_of_first_treatment} />,
        },
        {
            headerName: 'Age of Last Treatment',
            headerClassName: 'super-app-theme--header',
            field: 'age_of_last_treatment',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.age_of_last_treatment} />,
        },
        {
            headerName: 'Outcome',
            headerClassName: 'super-app-theme--header',
            field: 'outcome',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.outcome} />,
        },
        {
            headerName: 'Comments',
            headerClassName: 'super-app-theme--header',
            field: 'comment',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.comment} />,
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !props.nonEditable
                ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.handleEdit?.(params.id),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.handleDelete?.(params.id),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                :
                ""
                ),
        },
    ]

    return (
        <DataGrid
            page={props.page}
            onPageChange={props.onPageChange}
            getRowId={(row) => row.id as string | number}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
        />
    )
}
