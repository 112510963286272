import React from 'react'
import { Box, TextField } from '@material-ui/core'

const RenderTemperature = ({ master, values, unit, handleChange }) => {

    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'temperature',
    )

    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)
    const handleInput = (e) => {
        const { value } = e.target
        handleChange({
            vital_master_id: filteredMaster?._id,
            unit: unit === 'F' ? '°F' : '°C',
            value,
        })
    }
    return (
        <Box
            sx={{
                border: '1px solid #EAECF0',
                width: '100px',
                borderRadius: 3,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: '#FFF',
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                },
            }}>
            <TextField
                placeholder="Temperature"
                fullWidth="true"
                InputProps={{
                    style: {
                        textAlign: 'center',
                        backgroundColor: '#FFF',
                    },
                    disableUnderline: true,
                }}
                style={{ width: '100px' }}
                hiddenLabel
                value={filteredEle?.value}
                variant="filled"
                size="small"
                onChange={(e) => {
                    const { value } = e.target
                    if ((value >= -459.67 && value <= 1000) || value === '-') {
                        handleInput(e)
                    }
                }}
            />
        </Box>
    )
}

export default RenderTemperature