import React, { useState, lazy, useEffect } from 'react'
import { Grid, Typography, Box, FormLabel, Button, Dialog, IconButton } from '@material-ui/core'
import { useFormik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'
import { CircularProgress } from '@mui/material'
import { ReactComponent as ReferralScheduleIcon } from 'assests/icons/referral_schedule_icon.svg'
import { Loading } from 'shared/elements/Loading'
import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { _getClinicalNotesDetail } from 'containers/ClinicalNotes/api/services'
import { getListInsurances, getPatientNextAppointmentInWaitlist } from 'services/Patient/patient'
import { useSelector } from 'react-redux'
import './recallstyle.scss'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import moment from 'moment'
import LoadingButton from '@mui/lab/LoadingButton'
import { makeStyles } from '@material-ui/styles'
import { createSchedule, getLocations, getRecallById, getSpecialty, getVisitType, postRecall, updateRecall } from 'services/Calendar'
import { SelectEl } from 'shared/elements'
import NoteField from '../../component/NoteField'
import { useDispatch } from 'react-redux'
import CloseIcon from 'components/Consultation/icons/CloseIcon'
import SchedulerMenu from 'containers/SchedulerMenu'
import { changeAppointment } from 'store/actions/schedule'
import { PATIENT_VISIT_TYPE } from 'containers/AppointmentBooking/constant'
const TextBox = lazy(() => import('shared/elements/FormItem/TextBox'))
const { v4: uuidv4 } = require('uuid')
const SearchICD10Code = lazy(() => import('shared/elements/FormItem/SearchComponents/SearchICDv10'))

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E',
        color: 'white',
        fontWeight: 400,
        fontSize: 15,
        borderRadius: 8,
        width: '100%',
        height: 42,
        textTransform: 'unset !important',
        // height: '100%',
    },
    schedule_button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        width: 105,
        height: 25,
        borderRadius: 5,
        backgroundColor: '#5571C6',
        cursor: 'pointer',
        '& p': {
            color: '#fff !important',
            fontFamily: 'Rubik !important',
            fontSize: '15px !important',
            fontWeight: '400 !important',
            lineHeight: 'normal !important'
        }
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'unset !important',
        height: 42,
    },
    scheduleFormDialog: {
        '& .MuiDialog-container .MuiPaper-root': {
            maxWidth: '1150px !important',
            height: '100% !important'
        },
        '& .appointment-booking-container': {
            overflowY: 'hidden'
        }
    },
    scheduleModalCloseIconButton: {
        '& .MuiIconButton-label svg': {
            height: '12px !important',
            width: '12px !important',
            color: '#303e4e !important'
        }
    },
    readOnlyInput: {
        backgroundColor: '#989CA8 !important', // Set your desired background color here
    },
    inputFormLabel: {
        fontWeight: "500",
        fontSize: "14px",
        color: "#667689"
    },
    required_text: {
        color: "red"
    },
})



const RecallForm = () => {
    // Hooks
    const history = useHistory()
    const { id = null } = useParams()
    const notification = useNotification()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [patient, setPatient] = React.useState(null)
    const [healthPlan, setHealthPlan] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [loadingVisit, setLoadingVisitType] = useState([])
    const { idReferral: referralsID, ...restParams } = useParams()
    const dispatch = useDispatch();
    const [loadingForm, setLoadingForm] = useState(false)
    const [isSubmitting, setisSubmitting] = useState(false)
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const [disabled, setdisabled] = useState(true)
    const [fetchDataByid, setfetchDataByid] = useState({})
    const timezone = useSelector((state) => state?.timezones?.tz?.key)
    const [newAppointmentFormOpen, setNewAppointmentFormOpen] = useState({
        status: false
    })
    
    const [newAppointmentData, setNewAppointmentData] = React.useState({
        next_appointment_date: null,
        next_appointment_id: null,
    })
    const initial_data = {
        id: 0,
        patient_name: fetchDataByid?.patient_id ?? '',
        patient_custom_id: fetchDataByid?.custom_patient_id ?? '',
        speciality: fetchDataByid?.speciality ?? '',
        number_attempt: fetchDataByid?.contact_attempt ?? '',
        notes: fetchDataByid?.notes ?? [],
        note: '',
        health_plan: fetchDataByid?.health_plan ?? '',
        visit_type: fetchDataByid?.visit_type ?? '',
        noteView: 'no-view',
    }

    const handleSubmit = (e) => {
        postRecallData()
    }

    useEffect(() => {
        getListSpecialty()
        if (id) {
            fetchRecallById(id)
        }
    }, [])

    const handleScheduleFormSubmit = (values) => {
        if (values?.is_reoccurring_appointment) {
            values.reoccurring_appointment = {
                days: values.days,
                end_date: moment
                    .tz(values?.end_date || values?.date, 'YYYY-MM-DD HH:mm', timezone)
                    .utc()
                    .format(),
                unit: values?.unit === 'weekday' ? 'week' : values?.unit,
                once_every: values?.once_every,
            }
        }
        values.start = moment
            .tz(`${values?.date} ${values?.start_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.end = moment
            .tz(`${values?.date} ${values?.end_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.appointment_for = 'patient'
        values.participants = [
            {
                status: 'accepted',
                role: 'Patient',
                participant_id: values?.patient_id,
            },
        ]

        const payload = {
            start: values?.start,
            end: values?.end,
            practitioner_id: values?.practitioner_id,
            timezone,
        }

        if (values.is_reoccurring_appointment) {
            payload.reoccurring_appointment = {
                ...values.reoccurring_appointment,
                unit: values.unit,
            }
        }
        setisSubmitting(true)
        notification('Don\'t refresh the page while the appointment is being created!', 'warning')
        createSchedule(values).then((res) => {
            formik.setFieldValue('reason', values?.reason_for_visit)
            setisSubmitting(false)
            setNewAppointmentFormOpen({ id: null, status: false })
            notification('Appointment successfully created!', 'success')
        }).catch((error) => {
            setisSubmitting(false)
            notification(
                error?.data?.message || 'Appointment was not successfully created!',
                'error',
            )
        })
    }
   
    const fetchRecallById = async (id) => {
        setLoadingData(true)
        const result = await getRecallById(id)
        setfetchDataByid({ ...result.data })
        await selectSpecialtype(result.data.speciality)
        setLoadingData(false)
    }
    const validationSchema = yup.object({
        patient_name: yup.string().trim().required('Patient Name is required!'),
        speciality: yup.string().trim().required('Speciality is required!'),
        visit_type: yup.string().trim().required('Visit Type is required!'),
        patient_custom_id: yup.string().required('Patient ID is required')

    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initial_data,
        onSubmit: handleSubmit,
    })



    const patientDetails = async (data) => {
        formik.values.patient_custom_id = data?.custom_id
        if (data) {
            formik.values.patient_custom_id = data?.custom_id
            setPatient(data)
            const healthplanData = await getListInsurances(formik.values.patient_name)
            setHealthPlan(healthplanData.data.data)
        }
        setdisabled(false)
    }

    const getListSpecialty = async () => {
        try {
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }
    const getListVisitType = async (specialty_id) => {
        try {
            setLoadingVisitType(true)
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType({ page: 1, limit: 100000, specialty_id })
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        } finally {
            setLoadingVisitType(false)
        }
    }


    async function selectSpecialtype(value) {
        formik.setFieldValue('visit_type', '')
        await getListVisitType(value)
    }
    const getLocationExternal = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            if (locationExternal) {
                setLocationOptions(
                    locationExternal.map((item) => ({
                        key: item.id,
                        value: item.name,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        Promise.allSettled([
            getLocationExternal(),
        ])
        return () => abortController.abort()
    }, [])

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (id === 0 || !id) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = formik.values?.notes
            }
            formik.setFieldValue('notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )
    const getAppoinmentDetails = async (id) => {
        setLoadingForm(true)
        const nextAppointmentDetails = await getPatientNextAppointmentInWaitlist(id)
        if (nextAppointmentDetails?.data?.lastAppointment?.resource) {
            const nextAppointmentProps = nextAppointmentDetails?.data?.lastAppointment?.resource

            const nextAppointmentExtensions = nextAppointmentProps?.extension[0]?.extension

            const appointmentRequiredExtensionFields = {}
            nextAppointmentExtensions?.forEach(nextAppointmentExtensionsItem => {
                const objValues = Object.values(nextAppointmentExtensionsItem)
                appointmentRequiredExtensionFields[objValues[0]] = objValues[1]
            })

            const appointmentParticipants = {}
            nextAppointmentProps?.participant?.forEach(participantItem => {
                const actor = participantItem?.actor?.reference?.split("/")[0]?.toLowerCase(),
                value = participantItem?.actor?.reference?.split("/")[1]
                appointmentParticipants[actor] = value
            })

            const result = {
                id: '',
                day: moment().format('dddd'),
                specialty_id: appointmentRequiredExtensionFields?.specialty_id || '',
                visit_type_id: appointmentRequiredExtensionFields?.visit_type_id || '',
                date: moment().format('YYYY-MM-DD'),
                start_time: moment().format('HH:mm'),
                // end_time: moment().format('HH:mm'),
                timezone: appointmentRequiredExtensionFields?.timezone || '',
                appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                reason_for_visit: formik.values?.reason,
                internal_note: '',
                location_id: appointmentParticipants?.location || '',
                duration: '',
                supervising_provider: '',
                is_require_translator: false,
                appointment_status: '',
                is_reoccurring_appointment: false,
                days: [],
                end_date: '',
                unit: '',
                once_every: '',
                end_type: '',
                practitioner_id: appointmentParticipants?.practitioner,
                patient_id: appointmentParticipants?.patient,
                note: '',
                noteView: 'no-view',
                notes: []
            }
            
            dispatch(
                changeAppointment({
                    id: -1,
                    values: result
                }),
            )
            // formik.setFieldValue('next_appointment_date', nextAppointmentProps.start || null)
            // formik.setFieldValue('next_appointment_id', nextAppointmentProps.id || null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: nextAppointmentProps.start,
                next_appointment_id: nextAppointmentProps.id,
            })
            
        } else {
            // formik.setFieldValue('next_appointment_date', null)
            // formik.setFieldValue('next_appointment_id', null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: null,
                next_appointment_id: null,
            })
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: formik.values?.reason,
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: fetchDataByid?.patient_id,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
        }
        setLoadingForm(false)
    }
    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: fetchDataByid?.patient_id,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                id,
            })
        } else {
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                start,
                end,
                duration,
                id,
            })
        }
        if (fetchDataByid?.patient_id) {
            getAppoinmentDetails(fetchDataByid?.patient_id)
        }
    }

    useEffect(()=>{
        getAppoinmentDetails(fetchDataByid?.patient_id)
    },[fetchDataByid?.patient_id])


    const postRecallData = async () => {
        setisSubmitting(true)
        if (id) {
            setisSubmitting(true)
            try {
                const payload = {
                    "patient_id": formik.values.patient_name,
                    "health_plan": formik.values.health_plan,
                    "visit_type": formik.values.visit_type,
                    "contact_attempt": formik.values.number_attempt,
                    "speciality": formik.values.speciality,
                    "notes": formik.values.notes
                }
                await updateRecall({ id, payload })
                notification('Recall Updated Successfully', 'success')
            } catch (error) {
                notification('Something Went Wrong', 'error')
            }
            setisSubmitting(false)
            history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN, { from: 'Recall' })
        } else {
            setisSubmitting(true)
            try {
                const payload = {
                    "patient_id": formik.values.patient_name,
                    "health_plan": formik.values.health_plan,
                    "visit_type": formik.values.visit_type,
                    "contact_attempt": formik.values.number_attempt,
                    "speciality": formik.values.speciality,
                    "notes": formik.values.notes
                }

                await postRecall(payload)
                notification('Recall Created Successfully', 'success')
            } catch (error) {
                notification('Something Went Wrong', 'error')
            }
            setisSubmitting(false)
            formik.resetForm()
        }


    }
    const reset = () => {
        formik.resetForm()
    }

    const backPage = () => {
        history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN, { from: 'Recall' })
    }
    return (
    <>
        {/* // <Box style={{ overflowY: "auto" }}> */}
        <Grid container className="recall-form" style={{
            padding: 24,
            overflowX: 'scroll'
        }} >
            <Loading
                visible={
                    loading
                }
            />
            <Grid
                container>
                {(isSubmitting || loadingData) && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}
                {/*------------------------------------------------Back Button----------------------------------------------------*/}
                <Grid
                    container>
                    <span style={{
                        cursor: "pointer",
                        display: "flex",
                        marginBottom: "1rem"
                    }}
                        onClick={() =>
                            backPage()
                        }
                    >
                        <KeyboardBackspaceRoundedIcon
                            style={{
                                color: '#5571C6',
                                fontSize: 18,
                            }}
                        />

                        <span className="link-back" color="inherit" style={{ marginTop: "2px" }}>
                            Back to Recall Queue
                        </span>
                    </span>
                </Grid>


                <Grid container item>
                    <Grid
                        container
                        alignContent="center"
                        className='border-bottom'
                    >
                        <h4 className="page-title mb-1 page-RecordCall">

                            <span
                                style={{
                                    marginLeft: 11,
                                    display: 'inline-flex',
                                    alignItems: 'flex-end',
                                }}>
                                {id
                                    ?
                                    'Edit Recall Record'
                                    : 'Add Recall Record'}{' '}
                            </span>
                        </h4>
                    </Grid>
                    <Grid style={{ paddingLeft: "12px", display:'flex', width:'inherit', justifyContent:'space-between' }}>
                        <p className='date-text'>Date Added: {id ? moment().format("MM/DD/YYYY") : moment(fetchDataByid.created_at).format("MM/DD/YYYY")}</p>
                        <Grid xs={12} style={{ height: "50px", display: "flex", alignItems: "center", justifyContent: 'flex-end', marginRight: 55, gap: 10 }}>
                                <div
                                    onClick={() => setNewAppointmentFormOpen({status: true})}
                                    className={classes.schedule_button}>
                                    <ReferralScheduleIcon />
                                    <Typography>Schedule</Typography>
                                </div>

                                {newAppointmentData?.next_appointment_date && <Typography style={{
                                    color: '#667689',
                                    fontFamily: 'Rubik',
                                    fontSize: 16,
                                    fontWeight: 400
                                }}> 
                                    Next Appt: {moment(newAppointmentData?.next_appointment_date).format('MM/DD/YYYY')}
                                </Typography>}
                            </Grid>
                    </Grid>

                    <Grid container item spacing={3} className="modal-spacer mt-3">

                        {/*------------------------Patient Information---------------------------------*/}
                        <fieldset className="divider-information" style={{ width: "100%" }}>
                            <legend className='legend-class'>
                                <Typography className={'heading1-title'}>
                                    Patient Information
                                </Typography>
                            </legend>
                            <Grid container item spacing={2} style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Patient Name <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SearchPatientInput
                                        className="position-button"
                                        value={formik.values.patient_name}
                                        name="patient_name"

                                        handleAddRow={(value) => {
                                            formik.setFieldValue('health_plan', '')
                                            formik.setFieldValue('patient_custom_id', '')
                                            formik.setFieldValue('patient_name', value?.id)
                                        }}
                                        error={formik.touched?.patient_name && Boolean(formik.errors?.patient_name)}
                                        helperText={formik.touched?.patient_name && formik.errors?.patient_name}
                                        getDetailPatient={(patient) => patientDetails(patient)}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextBox
                                        disabled={disabled}
                                        label="Patient ID"
                                        name="patient_id"
                                        placeholder="Patient ID"
                                        readOnly
                                        value={formik.values?.patient_custom_id ?? ''}
                                        error={formik.touched?.patient_custom_id && Boolean(formik.errors?.patient_custom_id)}
                                        helperText={formik.touched?.patient_custom_id && formik.errors?.patient_custom_id}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Health Plan
                                    </FormLabel>
                                    <SelectEl
                                        name="Health_plan"
                                        placeholder="Select Plan"
                                        value={
                                            formik.values?.health_plan
                                                ? formik.values?.health_plan
                                                : ''
                                        }
                                        options={
                                            healthPlan &&
                                            healthPlan.map((plan) => (
                                                {

                                                    key: plan.id?.toString(),
                                                    value: plan.insurance.plan.display,
                                                }))
                                        }
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            formik.setFieldValue('health_plan', e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Speciality <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SelectEl
                                        name="speciality"
                                        placeholder="Select Speciality Type"
                                        value={
                                            formik.values?.speciality
                                                ? formik.values?.speciality
                                                : ''
                                        }
                                        options={listSpecialty}
                                        error={formik.touched?.speciality && Boolean(formik.errors?.speciality)}
                                        helperText={formik.touched?.speciality && formik.errors?.speciality}
                                        onChange={(e) => {
                                            formik.setFieldValue('speciality', e.target.value)
                                            formik.setFieldValue('visit_type', '')
                                            selectSpecialtype(e.target.value)
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <FormLabel component="p" className={classes.inputFormLabel}>
                                        Visit Type <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SelectEl
                                        name="visit_type"
                                        placeholder="Select Visit Type"
                                        value={
                                            formik.values?.visit_type
                                                ? formik.values?.visit_type
                                                : ''
                                        }
                                        options={listVisitType}
                                        error={formik.touched?.visit_type && Boolean(formik.errors?.visit_type)}
                                        helperText={formik.touched?.visit_type && formik.errors?.visit_type}
                                        onChange={(e) => {
                                            formik.setFieldValue('visit_type', e.target.value)
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextBox
                                        label="Contact Attempt"
                                        name="contact_attempt"
                                        placeholder="Number of Attempt"
                                        value={formik.values?.number_attempt}
                                        type='number'
                                        onChange={(e) => {
                                            if (e >= 0)
                                                formik.setFieldValue('number_attempt', e)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>

                        {/*------------------------Stuff Notes------------------------------------------*/}
                        <fieldset className="divider-information mt-2" style={{ width: "100%" }}>
                            <legend className='legend-class'>
                                <Typography className={'heading1-title'}>Staff Notes</Typography>
                            </legend>
                            <Grid item spacing={2} container style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                                <Grid item xs={12}>

                                    <NoteField
                                        note={formik?.values?.note}
                                        noteView={formik?.values.noteView}
                                        notes={formik.values.notes}
                                        onChange={formik?.setFieldValue}
                                        // onBlur={handleNotesChange}
                                        handleNotesChange={handleNotesChange}
                                    />

                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
                <Grid container item top={20} justifyContent="flex-end" direction="row" spacing={2} className='cancel-button'>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={() => backPage()}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <LoadingButton
                            loading={isSubmitting || loadingData}
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create}
                            onClick={(e) => { formik.handleSubmit(e) }}
                        >
                            Save
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
        {/* // </Box> */}
         <Dialog
              open={newAppointmentFormOpen.status}
              sx={{
                    height: '100%',
                    '& .MuiDialog-paperFullWidth': {
                        maxWidth: '1200px',
                        height: '100%',
                        overflow: 'auto',
                    },
                    '& .MuiDialog-paperWidthSm': {
                        maxWidth: '100%'
                    }
                }}
                className={classes.scheduleFormDialog}
                fullWidth={true}
                onClose={newAppointmentModalToggle}
                >
                
                <div>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid #dddd'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '58%'
                        }}>
                            <Typography style={{
                                fontSize: 18,
                                padding: '10px 0px',
                                fontWeight: 900,
                                color: '#303e4e',
                            }}>Schedule Appointment</Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '40%'
                        }}>
                            <IconButton
                                onClick={newAppointmentModalToggle}
                                className={classes.scheduleModalCloseIconButton}>
                                <CloseIcon style={{ height: '10px !important', width: '10px !important', color: '#303e4e' }} />
                            </IconButton>
                        </div>
                        
                    </div>
                    <SchedulerMenu
                        isPopupType={true}
                        tab={1}
                        isScheduler={true}
                        open={newAppointmentFormOpen.status}
                        appointmentModal={newAppointmentFormOpen}
                        loading={loadingForm}
                        onModalClose={newAppointmentModalToggle}
                        onSubmit={handleScheduleFormSubmit}
                        locationOptionsData={locationOptions.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={listVisitType.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                    />
                </div>
                
            </Dialog>
        </>
    )
}

export default RecallForm
