import UserComponent from './UserComponent';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//selectors

//actions
import {
    searchPatientList,
    updatePatientStatus,
} from './../../store/actions/patient';
import {
    searchProviderList,
    updateProviderStatus,
} from './../../store/actions/provider';
import {
    searchStaffingList,
    updateStaffStatus,
    searchStaffListResult,
} from '../../store/actions/admin';
import { 
    searchClinicList,
    updateClinicStatus,
    getClinicAdminProfileDetail
} from '../../store/actions/clinicAdmin';
const mapStateToProps = (state) => {
    const {
        patient: {
            patientSearchList,
            searchMeta: patientSearchMeta,
        },
        provider: {
            providerSearchList,
            searchMeta: providerSearchMeta,
        },

        admin: {
            staffSearchList,
            searchMeta: staffSearchMeta,
        },
        clinicAdmin: {
            clinicSearchList,
            searchMeta: clinicSearchMeta,
            clinicAdminProfile
        },
        loader: { loading },
    } = state;

    return {
        patientSearchList,
        staffSearchList,
        clinicSearchList,
        clinicSearchMeta,
        providerSearchList,
        staffSearchMeta,
        patientSearchMeta,
        providerSearchMeta,
        clinicAdminProfile,
        loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            ...bindActionCreators(
                {
                    searchPatientList,
                    updatePatientStatus,
                    searchProviderList,
                    updateProviderStatus,
                    searchStaffingList,
                    updateStaffStatus,
                    searchStaffListResult,
                    searchClinicList,
                    updateClinicStatus,
                    getClinicAdminProfileDetail
                },
                dispatch,
            ),
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserComponent);
