import { Box, Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// import { DataGrid } from '@mui/x-data-grid'
import DateFnsUtils from '@date-io/date-fns'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircleOutlineOutlined, ExpandMore } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import moment from 'moment'
import DeleteIcon from '../../../assests/images/trash_icon.svg'
import { ICD_CODE_STATUS } from '../../../containers/ClinicalNotes/constants'
import { materialTheme } from '../../../utilities/calendar/style'
import DataGridCustom from '../reusable/DataGridCustom'
import { v4 as uuid } from 'uuid'
// import { MedicalProblems } from 'containers/PatientsSetting/demographic/MedicalProblems'
import MedicalProblems from 'containers/PatientsSetting/view/MedicalProblems'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { getMedicalProblemList } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'
import { getMedicalCoding, postMedicalCoding } from 'containers/ClinicalNotes/api/services'

const ICD_STATUS_OPTIONS = Object.keys(ICD_CODE_STATUS).map((is) => ({
    label: ICD_CODE_STATUS[is],
    value: ICD_CODE_STATUS[is],
}))

const useStyles = makeStyles({
    itemCard: {
        width: 400,
        height: 500,
    },
    container: {
        width: '100%',
        marginBottom: 30,
    },
    cover__add__new: {
        marginTop: 12,
        width: 'fit-content',
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 40,
        width: '100%',
        borderRadius: 4,
    },
    labelText: {
        height: 22,
        fontSize: 18,
        letterSpacing: 0,
    },
    subLabelText: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        borderRadius: 4,
        marginTop: 10,
    },
    border: {
        border: '1px solid #516983',
    },
    innerGrid: {
        marginTop: 10,
        border: '1px solid #516983',
    },
    divider: {
        background: '#516983',
    },
    billingText: {
        width: '100%',
        textAlign: 'center',
    },
    icon: {
        cursor: 'pointer',
        width: 20,
    },
    disabled: {
        cursor: 'not-allowed',
    },
    headerTxt: {
        fontWeight: 500,
        fontSize: '14px',
        color: '#303E4E',
        marginRight: 8,
    },

    datePicker: {
        '& button': {
            padding: '12px 4px 12px 0px',
        },
    },
})

const renderActions = (params, actions, classes, isDisabled, isDisabledDelete) => {
    const { onEditClick, onDeleteClick, onSaveClick } = actions
    const icdCode = params.row.icd_code_master_id?.code
    return (
        <Grid container style={{ gap: 10 }} justifyContent="center">
            {/* {params.row.editable ? (
                <img
                    className={icdCode ? classes.icon : classes.disabled}
                    width={20}
                    height={20}
                    src={SaveIcon}
                    alt="save"
                    onClick={() => {
                        if (icdCode) {
                            onSaveClick(params.row.id)
                        }
                    }}
                />
            ) : (
                <img
                    className={isDisabled ? classes.disabled : classes.icon}
                    width={22}
                    height={22}
                    src={EditIcon}
                    alt="edit"
                    onClick={() => {
                        if (!isDisabled) {
                            onEditClick(params.row.id)
                        }
                    }}
                />
            )} */}

            <img
                // className={classes.icon}
                className={isDisabledDelete ? classes.disabled : classes.icon}
                width={22}
                height={22}
                src={DeleteIcon}
                alt="delete"
                onClick={() => {
                    // if (!params.row.editable && !isDisabled) {
                    //     onDeleteClick(params.id)
                    // }
                    onDeleteClick(params.id)
                }}
            />
        </Grid>
    )
}

const renderDatePicker = (classes, onOptionSelect, index, value, isDisabled, rowId) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={materialTheme}>
                <KeyboardDatePicker
                    disableToolbar
                    // clearable
                    value={value}
                    onChange={(date) => {
                        onOptionSelect({ id: rowId, key: index, value: date })
                    }}
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    placeholder="MM/DD/YYYY"
                    InputProps={{
                        style: {
                            fontSize: 14,
                            height: 40,
                            padding: 0,
                        },
                    }}
                    disabled={isDisabled}
                    className={classes.datePicker}
                    autoOk
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    )
}

const renderDropdown = (
    selectedIds,
    classes,
    fieldName,
    value = '',
    onChange,
    options,
    placeHolder = 'Select',
    isDisabled,
) => {
    return (
        <Select
            className={classes.selectWrap}
            disableUnderline
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                },
                getContentAnchorEl: null,
            }}
            IconComponent={ExpandMore}
            name={fieldName}
            onChange={(e) => onChange(e.target.value, fieldName)}
            disabled={isDisabled}
            value={value}
            displayEmpty
            renderValue={
                !value
                    ? () => <span className="dropdown-placeholder">{placeHolder}</span>
                    : undefined
            }>
            {options.map((m) => (
                <MenuItem
                    key={m.label}
                    disabled={isDisabled}
                    value={m.value}
                // disabled={selectedIds.includes(m.value)}
                >
                    {m.label}
                </MenuItem>
            ))}
        </Select>
    )
}

const renderDignoses = ({
    classes,
    value,
    tempIcd,
    changeTempIcd,
    heading,
    onOptionSelect,
    isDisabled,
}) => {
    const onFieldChange = (value, name) => {
        changeTempIcd({
            ...tempIcd,
            [name]: value,
            status: ICD_CODE_STATUS.ACTIVE,
        })
    }

    // const onSaveClick = () => {
    //     const updatedValue = value ? [...value] : []
    //     const filteredIndex = updatedValue.findIndex((v) => v.id === tempIcd.tempId)
    //     if (filteredIndex > -1) {
    //         updatedValue[filteredIndex] = {
    //             ...tempIcd,
    //             id: tempIcd.tempId,
    //             editable: false,
    //         }
    //     } else {
    //         updatedValue.push({
    //             ...tempIcd,
    //             id: value.length + 1,
    //             editable: false,
    //         })
    //     }
    //     // handleChange(updatedValue)
    //     changeTempIcd(defaultIcd)
    // }

    const onDeleteClick = (id) => {
        if (tempIcd?.length === 1) {
            changeTempIcd([defaultIcd()])
        } else {
            const updatedValue = tempIcd.filter((v) => v.id !== id)
            changeTempIcd(updatedValue)
        }
    }

    // const onEditClick = (id) => {
    //     const filteredData = value.find((v) => v.id === id)
    //     changeTempIcd({
    //         ...filteredData,
    //         editable: true,
    //         tempId: filteredData?.id,
    //     })
    // }

    const columns = [
        {
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                    }}>
                    <Typography className={classes.headerTxt} variant="body2">
                        Date
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'icd_code_date',
            flex: 2,
            sortable: false,
            renderCell: ({ value, id, row }) => {
                if (!row.editable) {
                    return <Typography>{moment(value).format('MM/DD/YYYY')}</Typography>
                }
                return renderDatePicker(
                    classes,
                    onOptionSelect,
                    'icd_code_date',
                    value,
                    isDisabled,
                    row?.id,
                )
            },
        },
        {
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                    }}>
                    <Typography className={classes.headerTxt} variant="body2">
                        ICD-10 code
                    </Typography>
                </Grid>
            ),

            headerClassName: 'super-app-theme--header',
            field: 'icd_code',
            flex: 2,
            sortable: false,
            renderCell: ({ id, row }) => {
                return (
                    <TextField
                        fullWidth
                        variant="outlined"
                        name={'description'}
                        placeholder="Enter ICD-10 code"
                        size="small"
                        disabled
                        value={row.icd_code_master_id?.code}
                    />
                )
            },
        },
        {
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                    }}>
                    <Typography className={classes.headerTxt} variant="body2">
                        Description
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),

            headerClassName: 'super-app-theme--header',
            field: 'description',
            flex: 3,
            sortable: false,
            renderCell: ({ id, row }) => {
                return (
                    <SearchICD10Code
                        isDisplayCode
                        name="icd_code"
                        placeholder="Search ICD-10 code"
                        value={row?.icd_code_master_id}
                        onChange={(value) => {
                            onOptionSelect({ id: row.id, key: 'icd_code_master_id', value })
                        }}
                    />
                )
            },
        },
        // {
        //     headerName: (
        //         <Grid
        //             container
        //             wrap="nowrap"
        //             alignItems="center"
        //             sx={{
        //                 width: '100%',
        //             }}>
        //             <Typography
        //                 className={classes.headerTxt}
        //                 variant="body2">
        //                 Billing
        //             </Typography>
        //             {/* <HeaderTableIcon /> */}
        //         </Grid>
        //     ),

        //     headerClassName: 'super-app-theme--header',
        //     field: 'billing',
        //     flex: 1,
        //     sortable: false,
        //     renderCell: ({ value, id, row }) => {
        //         if (!row.editable) {
        //             const filteredCode =
        //                 billingOptions.find(
        //                     (io) =>
        //                         io.value ===
        //                         row.icd_code_master_id,
        //                 )
        //             return (
        //                 <Typography
        //                     className={classes.billingText}
        //                     variant="body2">
        //                     {row.billing}
        //                 </Typography>
        //             )
        //         }
        //         return renderDropdown(
        //             data,
        //             classes,
        //             'billing',
        //             row.billing,
        //             onFieldChange,
        //             billingOptions,
        //             'Billing',
        //             isDisabled,
        //         )
        //     },
        // },
        {
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                    }}>
                    <Typography className={classes.headerTxt} variant="body2">
                        Status
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),

            headerClassName: 'super-app-theme--header',
            field: 'status',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => {
                if (!row.editable) {
                    const filteredCode = ICD_STATUS_OPTIONS.find((io) => io.value === row.status)
                    return <Typography>{filteredCode?.label}</Typography>
                }
                return renderDropdown(
                    _,
                    classes,
                    'status',
                    value,
                    onFieldChange,
                    ICD_STATUS_OPTIONS,
                    'Status',
                    true,
                )
            },
        },
        {
            headerName: (
                <Grid
                    container
                    wrap="nowrap"
                    alignItems="center"
                    sx={{
                        width: '100%',
                    }}>
                    <Typography className={classes.headerTxt} variant="body2">
                        Action
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'id',
            width: 80,
            sortable: false,
            renderCell: (params) =>
                renderActions(
                    params,
                    {
                        onDeleteClick,
                        // onEditClick,
                        // onSaveClick,
                    },
                    classes,
                    isDisabled,
                    tempIcd.length === 1,
                ),
        },
    ]

    return (
        <DataGridCustom
            tableProps={{
                rows: tempIcd,
                columns,
                hideFooter: true,
                rowHeight: 60,
            }}
        />
    )
}

const defaultIcd = () => ({
    id: uuid(),
    icd_code_master_id: {},
    icd_code_date: new Date(),
    status: ICD_CODE_STATUS.ACTIVE,
    editable: true,
})

const ICDCode = ({
    master,
    patientId,
    name,
    fieldName,
    value = [],
    onChange,
    isDisabled,
    encounter_id,
    handleSetViewHealthyRecord,
    appointmentId,
    nonEditable
}) => {
    const classes = useStyles()
    const [tempIcd, changeTempIcd] = useState([defaultIcd()])
    const [medicalCodingData, setMedicalCodingData] = useState({})
    const classesCard = usePatientDashboardStyles()

    const notification = useNotification()

    useEffect(() => {
        changeTempIcd([defaultIcd(), ...value])
    }, [])

    const handleChange = (value) => {
        // onChange(name, value)
    }

    const onOptionSelect = ({ id, key, value }) => {
        changeTempIcd((prev) =>
            prev.map((item) =>
                item.id === id
                    ? {
                        ...item,
                        // icd_code_master_id: selected?.icd_code,
                        [key]: value,
                        // status: ICD_CODE_STATUS.ACTIVE,
                    }
                    : item,
            ),
        )
    }

    const handleAddMore = () => {
        changeTempIcd((prev) => [defaultIcd(), ...prev])
    }

    useEffect(() => {
        onChange(
            name,
            tempIcd.filter((item) => item?.icd_code_master_id?.code),
        )
    }, [tempIcd])

    const getMedialProblemList = async () => {
        try {
            const resp = await getMedicalProblemList(patientId, 1, 100000)
            const list = resp.data.data.map((item) => ({
                ...item,
                problem: item.icd_code?.display || '',
                code: item.icd_code?.code || '',
                date_diagnosed: formatDateTime(item.time_onset, 'MMM DD, YYYY'),
                date_changed: formatDateTime(item.time_changed, 'MMM DD, YYYY'),
                status: item.status.code,
                notes: item.note,
            }))
            onChange(name, list)
        } catch (ex) {
            notification(`Cannot get list problem!`, 'error')
        }
    }

    const getMedicalCodingData = async (id) => {
        getMedicalCoding(id)
            .then((response) => {
                setMedicalCodingData(response.data)
            })
            .catch((e) => {
                console.log(e, 'err')
            })
    }


    const handleAddICDMedicalCoding = (icd10) => {
        if (appointmentId) {
            postMedicalCoding(appointmentId, { ...medicalCodingData, icd10: [...medicalCodingData.icd10, icd10] })
                .then((response) => {
                    // setLoading(false)
                })
                .catch((e) => {
                    console.log(e, 'err')
                })
        }
    }

    useEffect(() => {
        if (patientId) {
            getMedialProblemList()
        }
    }, [patientId])

    useEffect(() => {
        if (appointmentId) {
            getMedicalCodingData(appointmentId)
        }
    }, [appointmentId])

    return (
        <Box className={classes.container}>
            {/* {renderDignoses({
                classes,
                value,
                handleChange,
                tempIcd,
                changeTempIcd,
                heading: 'Diagnoses',
                onOptionSelect,
                isDisabled,
            })}
            <Grid
                container
                className={classes.cover__add__new}
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => handleAddMore()}>
                <AddCircleOutlineOutlined
                    style={{
                        color: '#14AE5C',
                        fontSize: 18,
                    }}
                />
                <Typography
                    variant="body1"
                    style={{
                        color: '#14AE5C',
                        marginLeft: 8,
                    }}>
                    Add new
                </Typography>
            </Grid> */}

            <Box>
                <MedicalProblems
                    isInClinicalNote
                    handleAddICDMedicalCoding={handleAddICDMedicalCoding}
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    isAddingFromClinicalNote
                    afterActionOk={getMedialProblemList}
                    nonEditable={nonEditable}
                />
            </Box>
            {/* <Grid
                item
                className={clsx(
                    classesCard.paper,
                    classesCard.box,
                    classesCard.card,
                    classes.itemCard,
                )}>
                <MedicalProblems
                    isAddingFromClinicalNote
                    patientId={patientId}
                    encounter_id={encounter_id}
                    viewAllFunc={() => {
                        handleSetViewHealthyRecord(
                            'medication_problems',
                            'Back to Assessment - Diagnosis',
                        )
                    }}
                />
            </Grid> */}
        </Box>
    )
}

export default ICDCode
