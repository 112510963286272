import React from 'react'
import { Box, Grid, IconButton } from '@material-ui/core'
import { Tabs as MuiTabs, Tab as MuiTab } from '@mui/material'
import { ReactComponent as DemographicIcon } from 'assests/icons/patientDemographicFormIcon.svg'
import { ReactComponent as FaxIcon } from 'assests/icons/patientInformationFaxIcon.svg'
import { ReactComponent as PatientPortalIcon } from 'assests/icons/patientInformationPortalAccess.svg'
import { ReactComponent as CariumAccess } from 'assests/icons/CariumAccess.svg'
import { ReactComponent as CariumAccessPatient } from 'assests/icons/CariumAccessPatient.svg'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export const TabPanel = (props) => {
    const { children, value, index, tabIconFunctions, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ height: '100%', overflowY: 'auto' }}
            {...other}>
            {value === index && <Box sx={{ p: 3, padding: '24px 0' }}>{children}</Box>}
        </div>
    )
}

export const Tabs = (props) => {
    const { current = 1, tabs = [], style, onChange, tabBoxProps={}, tabIconFunctions } = props
    const {
        patientPortalAnchorEl,
        patientPortalDialodContent,
        classes: patientPortalClasses,
        handler: handleOpenPatientPortalDialog,
        patientPortalAccess
    } = tabIconFunctions('patient_portal_access')

    const {
        patientPortalCariumAnchorEl,
        patientPortalDialodCariumContent,
        handler: handleOpenPatientPortalCariumDialog,
        patientPortalCariumAccess
    } = tabIconFunctions('patient_portal_carium_access')

    return (
        <>
            <Box sx={{ borderLeft: 1, borderColor: 'divider', ...tabBoxProps, display: 'flex', justifyContent: 'space-between', background: 'yellow' }}>
                <MuiTabs
                    onChange={(_, value) => onChange && onChange(value)}
                    value={current}
                    aria-label="Tabs where selection follows focus"
                    selectionFollowsFocus
                    orientation={props.orientation ? 'vertical' : 'horizontal'}
                    sx={props.sx && props.sx}>
                    {tabs &&
                        tabs.map((tab, index) => (
                            <MuiTab
                                key={index}
                                label={tab.label}
                                {...a11yProps(index)}
                                style={{ marginRight: 20, textTransform: 'none' }}
                            />
                        ))}
                </MuiTabs>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    gap: 40, 
                    marginRight: 30, 
                    borderLeft: '1px solid #E4E8EE', 
                    paddingLeft: 20, 
                    marginTop: 8,
                    alignItems: 'center'
                }}>
                    <IconButton onClick={() => tabIconFunctions('demographic_form')?.handler()}>
                        <HtmlTooltip
                            title='Edit demographics'
                            placement='top'
                            arrow>
                            <DemographicIcon />
                        </HtmlTooltip>
                    </IconButton>
                    
                    <IconButton onClick={() => tabIconFunctions('fax_module')?.handler()}>
                        <HtmlTooltip
                            title='Send fax'
                            placement='top'
                            arrow>
                            <FaxIcon />
                        </HtmlTooltip>
                    </IconButton>

                    
                    {/* the below cade may be require in future for that reason it commented */}
                    <HtmlTooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        title={patientPortalDialodContent()}
                        open={patientPortalAnchorEl}
                        disableHoverListener
                        arrow
                        placement="bottom-start">
                        <IconButton 
                        // onClick={handleOpenPatientPortalDialog}
                        >
                            <HtmlTooltip
                                title={`${patientPortalAccess ? 'Disable' : 'Enable'} patient portal access`}
                                placement='top'
                                arrow>
                                <PatientPortalIcon className={patientPortalAccess ? patientPortalClasses?.patientPortalIconColor : ''}/>
                            </HtmlTooltip>
                        </IconButton>
                    </HtmlTooltip>


                    <HtmlTooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        title={patientPortalDialodCariumContent()}
                        open={patientPortalCariumAnchorEl}
                        disableHoverListener
                        arrow
                        placement="bottom-start">
                        <IconButton onClick={handleOpenPatientPortalCariumDialog}>
                            <HtmlTooltip
                                title={`Patient ${patientPortalCariumAccess ? 'has' : 'does not have'} Carium account`}
                                placement='top'
                                arrow>
                               { patientPortalCariumAccess
                                ?
                                <CariumAccessPatient/>
                                :
                                <CariumAccess/>
                               }
                            </HtmlTooltip>
                        </IconButton>
                    </HtmlTooltip>
                </div>
            </Box>

            <Grid item xs={12} style={style}>
                {tabs &&
                    tabs.map((tab, index) => (
                        <TabPanel key={index} value={current} index={index}>
                            {tab.element}
                        </TabPanel>
                    ))}
            </Grid>
        </>
    )
}
