import { Box, Grid, Button, Typography } from '@material-ui/core'

import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import InfoIcon from '@mui/icons-material/Info'
import clsx from 'clsx'
import { capitalize, startCase, toLower } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ViewParticipant from '../compoment/viewParticipant'
import { IconHidenConsultation } from '../icon'
import * as actionTypes from '../actionTypes'
import { connect, useDispatch, useSelector } from 'react-redux'
import { handleOpenListConsultation, postAdmit, postEndRoom } from '../../../store/actions/provider'
import EmtyCosnultationList from './EmtyCosnultationList'

const useStyles = makeStyles({
    headerGridContainer: {
        width: 448,
        height: 'calc(100vh - 108px)',
        position: 'relative',
        // borderLeft: '1px solid #e8e8e8',
        boxShadow: '10px 5px 26px rgba(39, 46, 65, 0.25)',
        transition: 'width 0.5s',
    },
    width0: {
        width: 0,
    },
    appLogo: {
        width: 150,
        height: 66,

        '@media (max-width: 1500px)': {
            width: '70%',
            height: 'auto',
        },
    },
    typo_line: {
        color: '#9CA9B8',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_gray: {
        color: '#303E4E',
        fontSize: '14px !important',
        display: 'inline',
        lineHeight: '20px !important',
        paddingBottom: '8px',
    },
    typo_line_cover: {
        borderBottom: '1px solid #e8e8e8',
        margin: '8px 0',
    },
    separator: {
        borderLeft: '2px solid #ffffff',
        height: 55,
        width: 2,
        boxSizing: 'border-box',
        marginRight: 10,
        marginLeft: 10,
    },
    leftBorder: {
        borderLeft: '2px solid #ffffff',
        paddingLeft: 10,
    },
    dFlex: {
        display: 'flex',
    },
    oveflowDegree: {
        width: '235px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    textTransformCapital: {
        textTransform: 'uppercase',
    },
    avatar: {
        width: 100,
        height: 100,
        borderRadius: 100,
        marginTop: 16,
        background: 'gray',
        marginBottom: 16,
    },
    provider_tag: {
        padding: '4px 16px',
        background: '#3E465B',
        borderRadius: 5,
        color: 'white',
        margin: '12px 0',
    },
    cover_info: {
        padding: 24,
        boxShadow: '10px 5px 26px rgba(39, 46, 65, 0.25)',
        // borderTop: '1px solid #e8e8e8',
    },
    show_header_btn: {
        position: 'absolute !important',
        zIndex: 2,
        top: 40,
        left: -40,
    },
    hidden_cover: {
        overflowX: 'inherit',
        overflowY: 'scroll',
    },

    titleHeader: {
        fontSize: 19,
        lineHeight: '160%',
        fontWeight: 500,
        alignItems: 'center',
    },
})

const ConsultationList = (props) => {
    const { encounterQueueList } = props
    const classes = useStyles()
    const dispatch = useDispatch()

    const [participantData, setParticipantList] = useState([])

    const handleShowListConsultatuon = (item) => {
        dispatch(handleOpenListConsultation(item))
    }

    const openListConsultation = useSelector((state) => state.provider?.openListConsultation)

    const participantList = useSelector(
        (state) => state.provider.encounterQueueList?.arrConsultationlist,
    )

    const handleStartMeeting = (encounter_id, item) => {
        const params = {
            encounter_id,
            phone_number: item?.phone_number,
            screen: 'ConsultationList',
        }
        dispatch(postAdmit(params))
    }

    const handleEndMeeting = (encounter_id, item) => {
        const params = {
            encounter_id,
            phone_number: item?.phone_number,
            screen: 'ConsultationList',
            endEncouter: false,
        }
        dispatch(postEndRoom(params))
    }

    const objectPatient = useSelector((state) => state.provider.objectEncouterStart)

    const encounterQueueListEncouter = useSelector((state) => state.provider.encounterQueueList)
    // console.log(encounterQueueListEncouter)
    // console.error(objectPatient)

    useEffect(() => {
        const listParticipantEncouter = encounterQueueListEncouter.filter(
            (option) => option.encounter_id === objectPatient.encounter_id,
        )
        if (listParticipantEncouter.length > 0) {
            setParticipantList(listParticipantEncouter[0]?.participants)
        }
    }, [encounterQueueListEncouter])
    // console.log(participantData)

    return (
        <Grid
            className={clsx({
                [classes.headerGridContainer]: true,
                [classes.width0]: !openListConsultation,
            })}
            direction={'column'}
            container
            alignItems={'center'}
            wrap="nowrap">
            {openListConsultation && (
                <IconButton
                    variant="outlined"
                    onClick={() =>
                        handleShowListConsultatuon({
                            open: false,
                        })
                    }
                    className={classes.show_header_btn}>
                    <IconHidenConsultation />
                </IconButton>
            )}

            <Grid
                direction={'column'}
                container
                alignItems={'center'}
                wrap="nowrap"
                className={classes.hidden_cover}>
                <Grid container className={classes.cover_info} direction="column">
                    <Typography
                        style={{
                            paddingBottom: 16,
                            fontSize: 19,
                            color: 'black',
                            fontWeight: 500,
                        }}>
                        Participant list
                    </Typography>
                    <div>
                        {participantData?.length > 0 ? (
                            <ViewParticipant
                                encounterQueueList={encounterQueueList}
                                handleStartMeeting={handleStartMeeting}
                                handleEndMeeting={handleEndMeeting}
                            />
                        ) : (
                            <EmtyCosnultationList />
                        )}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConsultationList
