import {
    Box,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import { SelectEl } from '../../../shared/elements'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyBar from 'components/shared/Elements/CopyBar'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    table: {
        '& .MuiTableCell-root': {
            width: '25%',
            border: 'unset',
            boxSizing: 'border-box',
            padding: 8,
            '&:last-child': {
                width: '60%',
            },
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            marginBottom: '-12px',
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
})

const tableHeading = ['Role', 'Name', 'Comments']

const defaultRoleRow = {
    role: '',
    name: '',
    comment: '',
}

const defaultCustomRoleRow = {
    custom_role: '',
    name: '',
    comment: '',
}

const roleSectionRow = ({
    roleOptions,
    values,
    rows,
    customRows,
    handleChange,
    nonEditable
}) => {
    if (rows.length === 0) {
        rows.push({
            role: '',
            name: '',
            comment: '',
        })
        rows.push({
            role: '',
            name: '',
            comment: '',
        })
    }
    const handleInputChange = (name, value, index) => {
        if (rows[index]) {
            const row = rows[index] || {}
            row.role = row.role || ''
            row[name] = value
        } else {
            const row = {
                role: '',
                [name]: value,
            }
            rows.push(row)
        }
        handleChange([...rows, ...customRows])
    }

    const handleAddOrRemove = (action, index) => {
        if (nonEditable) { return }
        let updatedRow = {}
        if (action === 'add') {
            updatedRow = [...rows]
            updatedRow.push({ ...defaultRoleRow })
        } else {
            updatedRow = rows.filter((v, i) => i !== index)
            if (updatedRow.length === 0) {
                updatedRow.push({ ...defaultRoleRow })
            }
        }
        handleChange([...updatedRow, ...customRows])
    }
    return (
        <>
            {rows.map((r, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell
                            align="left"
                            component="th"
                            scope="row">
                            {renderDropDow({
                                options: roleOptions,
                                handleInputChange: (
                                    name,
                                    value,
                                ) =>
                                    handleInputChange(
                                        name,
                                        value,
                                        index,
                                    ),
                                name: 'role',
                                value: r.role,
                                nonEditable: nonEditable
                            })}
                        </TableCell>
                        <TableCell
                            align="left"
                            component="th"
                            scope="row">
                            {renderTextField({
                                name: 'name',
                                handleInputChange: (
                                    name,
                                    value,
                                ) =>
                                    handleInputChange(
                                        name,
                                        value,
                                        index,
                                    ),
                                value: r.name,
                                nonEditable: nonEditable
                            })}
                        </TableCell>

                        <TableCell
                            align="left"
                            component="th"
                            scope="row">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 5,
                                }}>
                                {renderTextField({
                                    name: 'comment',
                                    value: r.comment,
                                    handleInputChange: (
                                        name,
                                        value,
                                    ) =>
                                        handleInputChange(
                                            name,
                                            value,
                                            index,
                                        ),
                                    nonEditable: nonEditable
                                })}
                                <RenderAddOrRemove
                                    index={index}
                                    rows={rows}
                                    handleAddOrRemove={
                                        handleAddOrRemove
                                    }
                                    nonEditable={nonEditable}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            })}
            <Grid
                container
                onClick={() => handleAddOrRemove('add')}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content'
                }}>
                <AddCircleOutlineOutlined
                    style={{
                        color: '#14AE5C',
                        fontSize: 18,
                    }}
                />
                <Typography
                    variant="body1"
                    style={{
                        color: '#14AE5C',
                        marginLeft: 8,
                    }}>
                    Add new
                </Typography>
            </Grid>
        </>
    )
}

const customRoleSectionRow = ({
    values,
    rows,
    generalRows,
    handleChange,
}) => {
    if (rows.length === 0) {
        rows.push({
            custom_role: '',
            name: '',
            comment: '',
        })
    }
    const handleInputChange = (name, value, index) => {
        if (rows[index]) {
            const row = rows[index] || {}
            row.custom_role = row.custom_role || ''
            row[name] = value
        } else {
            const row = {
                custom_role: '',
                [name]: value,
            }
            rows.push(row)
        }
        handleChange([...generalRows, ...rows])
    }
    const handleAddOrRemove = (action, index) => {
        let updatedRow = {}
        if (action === 'add') {
            updatedRow = [...rows]
            updatedRow.push({
                ...defaultCustomRoleRow,
            })
        } else {
            updatedRow = rows.filter((v, i) => i !== index)
            if (updatedRow.length === 0) {
                updatedRow.push({ ...defaultCustomRoleRow })
            }
        }
        handleChange([...generalRows, ...updatedRow])
    }
    return (
        <>
            {rows.map((r, index) => (
                // eslint-disable-next-line react/jsx-key
                <TableRow>
                    <TableCell
                        align="left"
                        component="th"
                        scope="row">
                        {renderTextField({
                            handleInputChange: (
                                name,
                                value,
                            ) =>
                                handleInputChange(
                                    name,
                                    value,
                                    index,
                                ),
                            name: 'custom_role',
                            value: r.custom_role,
                        })}
                    </TableCell>
                    <TableCell
                        align="left"
                        component="th"
                        scope="row">
                        {renderTextField({
                            handleInputChange: (
                                name,
                                value,
                            ) =>
                                handleInputChange(
                                    name,
                                    value,
                                    index,
                                ),
                            name: 'name',
                            value: r.name,
                        })}
                    </TableCell>

                    <TableCell
                        align="left"
                        component="th"
                        scope="row">
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 5,
                            }}>
                            {renderTextField({
                                handleInputChange: (
                                    name,
                                    value,
                                ) =>
                                    handleInputChange(
                                        name,
                                        value,
                                        index,
                                    ),
                                name: 'comment',
                                value: r.comment,
                            })}
                            <RenderAddOrRemove
                                index={index}
                                rows={rows}
                                handleAddOrRemove={
                                    handleAddOrRemove
                                }
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            ))}
        </>
    )
}

const renderDropDow = ({
    options,
    name,
    handleInputChange,
    value,
    nonEditable
}) => {
    return (
        <SelectEl
            name={name}
            options={options}
            placeholder="Select One"
            disabled={nonEditable}
            value={value}
            selectProps={{
                style: {
                    height: 41,
                },
            }}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const renderTextField = ({
    name,
    value,
    handleInputChange,
    nonEditable
}) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            name={name}
            size="small"
            value={value}
            disabled={nonEditable}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const RenderAddOrRemove = ({
    index,
    rows,
    handleAddOrRemove,
    nonEditable = false
}) => {
    const [alertDeleteOpen, setAlertDeleteOpen] =
        React.useState(false)
    return (
        <Box sx={{ marginLeft: 8, display: 'flex' }}>
            {rows.length >= 1 && (
                <IconButton
                    variant="outlined"
                    onClick={() => setAlertDeleteOpen(true)}
                    disabled={nonEditable}
                // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            <AlertDeleteDialog
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleAddOrRemove('remove', index)
                    setAlertDeleteOpen(false)
                }}
            />
        </Box>
    )
}

const getDropdownOptions = (master = [], key) => {
    return master.map((im) => ({
        value: im[key],
        key: im._id,
    }))
}

const SourcesOfInformations = ({
    master,
    fieldName,
    values,
    onChange,
    lastNarrative,
    macros,
    nonEditable,
    copyAble,
    copyValues
}) => {
    const classes = useStyles()

    const { roleMasters } = master
    const roleOptions = getDropdownOptions(
        roleMasters,
        'name',
    )

    const generalRows = values?.informations?.filter((v) =>
        v.hasOwnProperty('role'),
    )
    const customRows = values?.informations?.filter((v) =>
        v.hasOwnProperty('custom_role'),
    )

    const handleChange = (updatedValues) => {
        onChange(`${fieldName}.informations`, updatedValues)
    }

    function handleChangeNarrative(name, value) {
        onChange(name, value)
    }
    const handleSelectMacro = (value) => {
        if (nonEditable) { return }
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const foundValues = []
            for (const element of copyValues?.informations) {
                if (typeof element === 'object' && element !== null) {
                    foundValues.push(element);
                }
            }

            const finalValues = {
                informations: [...foundValues, ...values?.informations],
                narrative: `${copyValues?.narrative} ${values?.narrative}`
            }

            if (foundValues?.length !== 0 || copyValues?.narrative !== '') {
                onChange(fieldName, finalValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            {/* <CopyBar content={lastNarrative}
                handleSelectMacro={handleSelectMacro} /> */}
            {
                copyAble && !nonEditable && (
                    <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
                )
            }
            <TableContainer component={Paper}>
                <Table
                    className={classes.table}
                    sx={{ minWidth: 650 }}
                    aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {tableHeading.map(
                                (heading, index) => (
                                    <TableCell
                                        key={index}
                                        align="left">
                                        <Typography
                                            variant="body1"
                                            style={{
                                                marginRight: 8,
                                                fontWeight: 500,
                                                color: '#303E4E',
                                            }}>
                                            {heading}
                                        </Typography>
                                        {/* <HeaderTableIcon /> */}
                                    </TableCell>
                                ),
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roleSectionRow({
                            roleOptions,
                            handleChange,
                            values,
                            rows: generalRows,
                            customRows,
                            nonEditable
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    onChange={handleChangeNarrative}
                    macros={macros}
                    meta={{
                        values,
                        master: master?.roleMasters,
                    }}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box >
    )
}

export default SourcesOfInformations
