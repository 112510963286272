import React from 'react'
import { Grid } from '@material-ui/core'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/styles'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
    item_event: {
        position: 'relative',
        backgroundColor: '#F1F8FF',
        borderRadius: 5,
        padding: '6px 10px',
        width: '-webkit-fill-available',
        margin: '2px 16px',
        boxShadow: '0px 0px 2px 0px rgb(85 113 198 / 50%)',
        overflow: 'hidden',
        alignItems: 'flex-start !important',
    },
    item_text: {
        fontSize: 13,
    },
    line: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 2,
        height: '100%',
        background: '#0076FC',
    },
}))

function WeekCalendarEvent(props) {
    const history = useHistory()
    const classes = useStyles()

    return (
        <HtmlTooltip
            title={<ToolTipContentMonth {...props.event} timezone={props.timezone} />}
            placement="top">
            <Grid
                container
                direction="column"
                className={classes.item_event}
                style={{
                    background: '#F1F8FF',
                }}>
                <div
                    className={classes.line}
                    style={{
                        background: '#0076FC',
                    }}></div>

                <Grid item xs={12}>
                    <Typography noWrap className={classes.item_text}>
                        {/* {props.title} */}
                        {moment(new Date(props.event.start).getTime()).format('hh:mm A')}
                        {` - `}
                        {moment(new Date(props.event.end).getTime()).format('hh:mm A')}
                    </Typography>
                </Grid>
            </Grid>
        </HtmlTooltip>
    )
}

function ToolTipContentMonth(props) {
    return (
        <div className="tooltip-wrapper">
            <Box>
                <Typography>
                    <Box
                        component={'span'}
                        sx={{
                            display: 'inline-block',
                            width: '7px',
                            height: '7px',
                            borderRadius: '50%',
                            background: '#f0f0f0',
                            margin: '0 5px 2px 0',
                        }}></Box>
                    <Box component={'span'}>
                        {moment(new Date(props.start).getTime()).format('hh:mm A')}
                    </Box>
                    <Box component={'span'}>{' - '}</Box>
                    <Box component={'span'}>
                        {moment(new Date(props.end).getTime()).format('hh:mm A')}
                    </Box>
                </Typography>
            </Box>
        </div>
    )
}

export default WeekCalendarEvent
