import { FormLabel, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { DOCUMENT_TYPE } from 'containers/Documents/CreateDocumentDialog/constant'
import FileUploadBulk from 'containers/Documents/CreateDocumentDialog/Views/FileUploadBulk'
import { getDepartmentList } from 'services/Calendar'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import { getEncountersByPatientId } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import moment from 'moment'

const useStyles = makeStyles(() => ({
    text: {
        color: '#303E4E',
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 25,
        width: '100%',
        textAlign: 'center',
    },
    reset_pass: {
        color: '#5571C6',
        fontSize: 14,
        textAlign: 'end',
        cursor: 'pointer',
        marginTop: 4,
    },
    container: {
        width: 680,
        background: '#FBFCFC',
        border: '1px solid #F3F6F9',
        borderRadius: 10,
        padding: '32px 20px',
    },
    required_text: {
        color: 'red',
    },
    textArea: {
        '& .MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root': {
            height: 'auto !important'
        }
    }
}))

function Staff({
    values,
    touched,
    errors,
    setFieldValue,
    isViewOnly = false,
    disableSearchPatient = false, // TODO:
}) {
    const classes = useStyles()
    const notification = useNotification()
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [encounterList, setEncounterList] = useState([])

    // useEffect(() => {
    //     const abortController = new AbortController()
    //     if (values.associated_patient) {
    //         Promise.allSettled([getEncounterList(values.associated_patient)]).finally(() => {})
    //     }

    //     return () => abortController.abort()
    // }, [values.associated_patient])

    const getEncounterList = useCallback(async (patientId) => {
        try {
            const resp = await getEncountersByPatientId({
                patient: patientId,
                status: 'finished',
                size: 10,
            })
            const data = resp.data.data || resp.data

            setEncounterList(
                data.map((item) => ({
                    key: item.encounter_id,
                    value: `${moment(item?.start).format('DD MMM YYYY') || ''} -- ${
                        moment(item?.start).format('hh:mm A') || ''
                    } - ${moment(item?.end).format('hh:mm A')}`,
                })),
            )
        } catch (error) {
            notification(error?.data?.message || 'Cannot get encounter list', 'error')
        }
    }, [])

    return (
        <Grid container item className={classes.container}>
            <Typography className={classes.text}>
                {values.id ? 'Upload New Document' : 'Add New Document'}
            </Typography>
            <Grid item xs={12}>
                <FormLabel
                    component="p"
                    className="input-form-label"
                    style={{
                        fontSize: 14,
                        fontWeight: 500,
                    }}>
                    Patient Name
                    <span className={classes.required_text}>*</span>
                </FormLabel>
                <SearchPatientInput
                    disabled={!!disableSearchPatient}
                    value={values.associated_patient}
                    handleInputChange={(e) => {
                        setFieldValue('associated_patient', e.target.value)
                    }}
                    handleAddRow={(value) => {
                        setFieldValue('associated_patient', value?.id)
                    }}
                    error={touched.associated_patient && Boolean(errors.associated_patient)}
                    helperText={touched.associated_patient && errors.associated_patient}
                />
            </Grid>

            <Grid container item alignItems="flex-end">
                <Grid item xs={12}>
                    <Select
                        label="Document Type"
                        name="document_type"
                        placeholder="Select document type"
                        value={values.document_type}
                        options={DOCUMENT_TYPE}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(value) => setFieldValue('document_type', value)}
                    />
                </Grid>
            </Grid>

            {/* <Grid container item alignItems="flex-end">
                <Grid item xs={12}>
                    <Select
                        label="Encounter"
                        name="encounter_id"
                        placeholder="Select Role"
                        value={values.encounter_id}
                        error={
                            touched.encounter_id &&
                            Boolean(errors.encounter_id)
                        }
                        helperText={
                            touched.encounter_id &&
                            errors.encounter_id
                        }
                        options={encounterList}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        onChange={(value) =>
                            setFieldValue(
                                'encounter_id',
                                value,
                            )
                        }
                        disabled={isViewOnly}
                    />
                </Grid>
            </Grid> */}

            <Grid item xs={12} className={classes.textArea}>
                <TextBox
                    placeholder="Enter description"
                    label="Description"
                    name="description"
                    value={values.description}
                    multiline
                    minRows={6}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                    onChange={(value) => setFieldValue('description', value)}
                    disabled={isViewOnly}
                />
            </Grid>
            <Grid item xs={12}>
                <FileUploadBulk
                    onChange={({ attachment_key, document_name }) => {
                        setFieldValue('document_name', document_name)
                        setFieldValue('attachment_key', attachment_key)
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default Staff
