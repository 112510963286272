import React, { useEffect, useState } from 'react'
import { Menu, MenuItem, IconButton, makeStyles, Grid } from '@material-ui/core'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import NotificationIcon from '../../../assests/icons/notification.svg'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { userMenus } from '../../../constants/menu'
import Typography from '@material-ui/core/Typography'
import DehazeIcon from '@mui/icons-material/Dehaze'
import './HeaderMobile.scss'
import { getCharOfName } from '../../../utilities/profile'
import { capitalizeFirstLetter, getLoggedInUserName } from '../../../utilities'
import { useSelector, useDispatch } from 'react-redux'
import { getAllRoles, getPermissionByRole } from '../../../store/actions/permission'
import { getAllLocations, getStatusCodes } from '../../../store/actions/metaData'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import MenuIcon from '@material-ui/icons/Menu'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
// import logo from '../../../assests/Logos/tm2u_logo_150.png'
import logo from '../../../assests/Logos/tm2u_logo_150.png'
import { getProviders, getListSupervising } from 'store/actions/provider'

const HeaderMobile = ({
    onLogout,
    onProfile,
    isShow,
    handleToggleShowSideBar,
    isShowMenuMobile,
    handleToggleShowMenuMobile,
}) => {
    const matchesMobile = useMediaQuery('(max-width:1100px)')
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedProvider, setSelectedProvider] = useState('')
    const activeMenu = useSelector((state) => state.menu.activeMenu)
    const loggedInUserDetail = useSelector((state) =>
        state.login.userData.isValidSecurityQus
            ? state.login.userData.response
            : state.login.userData,
    )
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onLogoutClick: () => {
            onLogout()
            handleClose()
        },
        onProfileClick: () => {
            onProfile()
            handleClose()
        },
    }

    const userName = capitalizeFirstLetter(getLoggedInUserName())

    useEffect(() => {
        dispatch(getPermissionByRole())
        dispatch(getAllRoles())
        // dispatch(getAllLocations())
        // dispatch(getProviders())
        // dispatch(getListSupervising())
        // dispatch(
        //     getStatusCodes({
        //         category: 'SUPERBILL',
        //     }),
        // )
    }, [])

    return (
        <Box className="app_header-mobile">
            <Box className="wrap-header-ct">
                <Box
                    className="icon-menu-mb"
                    component={'div'}
                    onClick={handleToggleShowMenuMobile}>
                    {isShowMenuMobile ? (
                        <CloseRoundedIcon fontSize="14" />
                    ) : (
                        <MenuIcon fontSize="14" />
                    )}
                </Box>

                <Box className="logo-wrap-siderbar">
                    <img src={logo} alt="TeleMed2U" />
                </Box>

                <Box className="iconNotification">
                    <IconButton
                        variant="outlined"
                        // onClick={handleClickOpen}
                    >
                        <img src={NotificationIcon} alt=""></img>
                    </IconButton>
                </Box>
            </Box>
            <Box className="title-page">
                <Typography className="text-title">
                    {' '}
                    {activeMenu.actualName || activeMenu.name}
                </Typography>
            </Box>
        </Box>
    )
}

export default HeaderMobile
