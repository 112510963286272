import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
    DataGrid,
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ForwardIcon from '../../../../assests/icons/ForwardIcon'
import NextIcon from '../../../../assests/icons/NextIcon'
import { deleteStatus } from '../../../../services/Calendar/index'
import { NoRowsOverlayEl } from '../../../../shared/elements'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Pagination, PaginationItem, Stack } from '@mui/material'
import { ImmunizationsCreateDialog } from 'containers/PatientsSetting/view/Immunizations/ImmunizationsCreateDialog'
import { useHistory, useParams } from 'react-router-dom'
import { deleteImmunization, getListImmunizations } from 'services/Patient/patient'
import TeleIconUpDownSortedList from '../../../../assests/icons/TeleIconUpDownSortedList'
import AlertDeleteDialog from '../../../../components/shared/Elements/AlertDeleteDialog'
import CustomTabs from '../../../../components/shared/Elements/CustomTabs'
import PatientInfor from '../PatientInfor'
import moment from 'moment'
import { useNotification } from 'shared/elements/Notification'

const useStyles = makeStyles({
    avatar: {
        width: 35,
        height: 35,
        background: 'gray',
        borderRadius: 80,
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    container: {
        // position: 'absolute',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        background: '#FFF1F1',
        color: '#E81212',
        borderRadius: 5,
        padding: '2px 8px',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        minWidth: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '0px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_print: {
        height: 32,
        background: '#E9ECF1',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '4px 16px',
        color: '#667689',
        '&:hover': {
            background: '#E9ECF1',
        },
    },
    header_bar: {
        background: '#FBFCFC',
        border: '1px solid #ECF0F0',
        borderRadius: '12px',
        padding: '12px 24px',
        marginTop: '12px',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})
const providerOptions = [
    { id: 'Option1', label: 'Option1' },
    { id: 'Option2', label: 'Option2' },
]
const RenderEditAction = ({ params, handleEditClick, refetchStatus }) => {
    const classes = useStyles()
    const notification = useNotification()
    const { id: patientId } = useParams()
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alertDeleteOpen, setAlertDeleteOpen] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleFunc = {
        onEditClick: () => {
            handleEditClick(params)
            handleClose()
        },
        onDeleteClick: () => {
            if (params?.id) {
                setAlertDeleteOpen(true)
            }
        },
    }
    const handleDeleteLocation = () => {
        setLoading(true)
        deleteImmunization(patientId, params.id)
            .then(() => {
                setLoading(false)
                refetchStatus()
                handleClose()
                setAlertDeleteOpen(false)
                notification('Immunization deleted successfully!', 'success')
            })
            .catch((error) => {
                const errMsg = error?.data?.message || `Immunization deleted unsuccessfully`
                setLoading(false)
                notification(errMsg, 'error')
            })
    }

    const userMenus = [
        {
            name: 'Edit',
            action: 'onEditClick',
        },
        {
            name: 'Delete',
            action: 'onDeleteClick',
        },
    ]

    return (
        <Grid item>
            <IconButton
                size="small"
                onClick={handleClick}
                style={{
                    background: '#FFFFFF',
                    border: '1px solid #E9ECF1',
                    borderRadius: '20px',
                }}>
                <MoreHorizIcon
                    style={{
                        color: '#3E465B',
                        fontSize: 18,
                    }}
                />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        width: 120,
                        marginTop: 8,
                    },
                }}
                getContentAnchorEl={null}>
                {userMenus.map((menu, index) => (
                    <MenuItem
                        id={`auto-header-${menu.name}`}
                        key={index}
                        onClick={handleFunc[menu.action]}
                        style={{
                            color: menu.name === 'Delete' ? '#E81212' : '#667689',
                        }}>
                        {menu.name}
                    </MenuItem>
                ))}
            </Menu>
            <AlertDeleteDialog
                loading={loading}
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleDeleteLocation()
                }}
            />
        </Grid>
    )
}
const renderActions = (params, actions) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            <RenderEditAction
                params={params}
                handleEditClick={actions.handleEditClick}
                refetchStatus={actions.refetchStatus}
            />
        </Grid>
    )
}

const CustomPagination = ({ onPageChange, total, numberRecord }) => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    const rowIndex = gridPaginationRowRangeSelector(apiRef)

    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show <strong>{rowIndex?.lastRowIndex + 1 || 0} records</strong> of{' '}
                <strong>{total}</strong>
            </Typography>
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1)
                    onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

const sectionsTabs = [
    {
        key: 234234,
        label: 'Step 1 - Select Vaccines',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Step 2 - Review and Sign',
        _id: 2,
    },
    {
        key: 33,
        label: 'Step 3 - Record Vaccination',
        _id: 2,
    },
]

const Immunizations = () => {
    const classes = useStyles()
    const history = useHistory()
    const { id: patientId } = useParams()
    const [selectedTab, setSelectedTab] = useState(0)
    // const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [appointmentModal, setAppointmentModal] = React.useState({
        status: false,
    })
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })
    const [bundleId, setBundleId] = useState('')
    const [openPatientDashboard, setOpenPatientDashboard] = React.useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const onPageChange = (value) => {
        setPage(value)
    }

    function appointmentModalToggle(itemId) {
        setAppointmentModal({
            status: !appointmentModal.status,
            itemId,
        })
    }

    const columns = [
        {
            headerName: <Typography variant="body1">Vaccine</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'vaccine',
            flex: 1.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value?.display || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },

        {
            headerName: <Typography variant="body1">CVX Code</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'lot_number',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    row?.vaccine?.code || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
                )
            },
        },
        // {
        //     headerName: (
        //         <Typography variant="body1">
        //             Administered by
        //         </Typography>
        //     ),
        //     headerClassName: 'super-app-theme--header',
        //     field: 'administered_by',
        //     // width: 100,
        //     flex: 1,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         return (
        //             `${value?.first_name} ${value?.last_name}` || (
        //                 <Typography
        //                     style={{ color: '#D3D8E0' }}>
        //                     N/A
        //                 </Typography>
        //             )
        //         )
        //     },
        // },
        {
            headerName: <Typography variant="body1">Administered on</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'administered_on',
            // width: 100,
            flex: 0.75,
            sortable: true,
            renderCell: ({ value }) => {
                return value ? (
                    moment(value).format('MM/DD/YYYY')
                ) : (
                    <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
                )
            },
        },
        {
            headerName: <Typography variant="body1">Status</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'status',
            // width: 100,
            flex: 0.75,
            sortable: true,
            renderCell: ({ value }) => {
                return value || <Typography style={{ color: '#D3D8E0' }}>N/A</Typography>
            },
        },
        {
            headerName: <Typography variant="body1">Actions</Typography>,
            headerClassName: 'super-app-theme--header',
            field: 'id',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
                return renderActions(params, {
                    handleEditClick,
                    refetchStatus,
                })
            },
        },
    ]

    const handleEditClick = (params) => {
        const { row } = params
        appointmentModalToggle(row?.id)
    }

    const refetchStatus = async () => {
        setLoading(true)
        try {
            const res = await getListImmunizations(patientId, page, limit, bundleId)
            setLoading(false)

            if (res?.data?.bundle_id) setBundleId(res?.data?.bundle_id)
            setPatientList(res.data?.data || [])
            setTotal(res.data?.paging?.total)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        refetchStatus()
    }, [page])

    useEffect(async () => {}, [page])

    return (
        <Grid container className={classes.container} direction="column" wrap="nowrap">
            <Grid
                container
                onClick={() => history.goBack()}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
            </Grid>
            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Immunizations</Typography>
                        <Grid item>
                            <Grid container spacing={2} wrap={'nowrap'}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn_add}
                                        onClick={() => appointmentModalToggle()}
                                        fullWidth>
                                        <AddIcon
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled
                                        variant="contained"
                                        color="primary"
                                        className={classes.btn_print}
                                        // onClick={() =>
                                        //     appointmentModalToggle()
                                        // }
                                        fullWidth>
                                        <LocalPrintshopOutlinedIcon
                                            style={{
                                                fontSize: 18,
                                                color: '#667689',
                                                marginRight: 8,
                                            }}
                                        />
                                        Print
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>

                <Grid item xs={12}>
                    <CustomTabs
                        contents={sectionsTabs}
                        selectedTab={selectedTab}
                        onChangeTab={(e) => {
                            setSelectedTab(e)
                        }}
                    />
                </Grid>

                <Grid item xs={12} className={classes.form_list_container}>
                    <Box
                        sx={{
                            '& .super-app-theme--header': {
                                border: 'none',
                                '& > .MuiDataGrid-columnSeparator': {
                                    visibility: 'hidden',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    '& .MuiTypography-body1': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#303E4E',
                                        fontWeight: 500,
                                        fontSize: '14px',
                                    },
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                border: 'none',
                            },
                            '& .MuiCircularProgress-root': {
                                zIndex: 1,
                            },
                            '& .MuiPaginationItem-previousNext': {
                                padding: '0 22px',
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                outline: 'none !important',
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                borderRadius: '5px',
                                background: '#667689',
                                color: 'white',
                                border: 'none',
                            },
                            '& .MuiDataGrid-root': {
                                border: 'none',
                                height: 'calc(100vh - 300px)',
                            },
                            '& .MuiDataGrid-cell': {
                                border: 'none !important',
                                color: '#667689',
                                // background: 'red'
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                                border: 'none',
                                outline: 'none !important',
                            },

                            '& .MuiDataGrid-footerContainer': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-row': {
                                background: '#FAFAFA',
                                // cursor: 'pointer',
                                margin: '4px 0',
                                borderRadius: '8px',
                            },
                            '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer':
                                {
                                    width: 'auto',
                                    visibility: 'visible',
                                },
                            '& .MuiDataGrid-virtualScroller': {
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            },
                        }}>
                        <DataGrid
                            getRowId={(row) => row.id}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                            rows={patientList}
                            columns={columns}
                            pageSize={limit}
                            // rowsPerPageOptions={[10]}
                            paginationMode="server"
                            onPageChange={onPageChange}
                            disableColumnSelector={true}
                            disableSelectionOnClick
                            loading={isLoading}
                            rowCount={total}
                            pagination
                            components={{
                                NoRowsOverlay: () => <NoRowsOverlayEl />,
                                Pagination: CustomPagination,
                                ColumnUnsortedIcon: TeleIconUpDownSortedList,
                                ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                                ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                            }}
                            componentsProps={{
                                pagination: {
                                    onPageChange,
                                    total,
                                    page,
                                    pageSize: limit,
                                },
                            }}
                            disableColumnMenu={true}
                            initialState={{
                                pagination: {
                                    total,
                                    numberRecord: limit,
                                    page,
                                    pageSize: limit,
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {appointmentModal.status && (
                    <ImmunizationsCreateDialog
                        open={appointmentModal.status}
                        itemId={appointmentModal.itemId}
                        loading={isSubmitting}
                        onClose={appointmentModalToggle}
                        refetchData={refetchStatus}
                    />
                )}
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Immunizations)
