import { INTERECT_WITH_DR } from "../../constants/appointments"
import { getPatientByUserId } from "../../services/Patient/patient"
import store from "../../store"
import { setLoading } from "../../store/actions/loader"
import { uploadDocuments } from "../../services/Document/documents"

export const getUserDetailsForForm = async (userId) => {
  store.dispatch(setLoading(true))
  const res = await getPatientByUserId(userId)
  const userDetails = res.data
  const { first_name, last_name, gender, mob2, user, dob, user_id } = userDetails
  let parentDetails;
  if (user?.parent_user_id) {
    const parentRes = await getPatientByUserId(user.parent_user_id)
    parentDetails = parentRes.data
  }
  const payload = {
    firstName: first_name,
    lastName: last_name,
    parentName: parentDetails ? `${parentDetails.first_name} ${parentDetails.last_name}` : '',
    dob,
    gender,
    phoneNumber: user?.mob || '',
    emailId: user?.email || parentDetails?.user?.email || '',
    userId: user_id
  }
  store.dispatch(setLoading(false))

  return payload
}

export const uploadFormDocuments = async (files, userId, uploadedBy, documentType) => {
  store.dispatch(setLoading(true))
  const formData = new FormData()
  formData.append('user_id', userId)
  formData.append('uploaded_by', uploadedBy)
  formData.append('document_type', documentType)   
  files.forEach((f, i) => {
    formData.append(`file${i}`, f)
  })

  try{
    const res = await uploadDocuments(formData)
    store.dispatch(setLoading(false))
    return res.data
  } catch (err) {
    store.dispatch(setLoading(false))
    throw err
  }
} 