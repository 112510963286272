import React, { useEffect, useState } from 'react'
import { styled } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import MenuBar from './components/MenuBar/MenuBar'
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar'
// import PreJoinScreens from "./components/PreJoinScreens/PreJoinScreens";
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import Room from './components/Room/Room'
import VideoChat from '../TwilioVideoPatient/VideoChat'

import useHeight from './hooks/useHeight/useHeight'
import { useHistory } from 'react-router-dom'
import { pushMessageSocket } from '../../containers/ClinicalNotes/api/services'
import { useLocation } from 'react-router-dom'

const Container = styled('div')({
    // display: "grid",
    // gridTemplateRows: "1fr auto",
    height: '100vh !important',
})

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
    overflow: 'hidden',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
        paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
    },
}))

export default function Appointment() {
    let location = useLocation()
    // Here we would like the height of the main container to be the height of the viewport.
    // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
    // not the viewport. This looks bad when the mobile browsers location bar is open.
    // We will dynamically set the height with 'window.innerHeight', which means that this
    // will look good on mobile browsers even after the location bar opens or closes.
    const height = useHeight()
    const history = useHistory()
    const [openAddParticipant, setOpenAddParticipant] = useState(false)

    const handleOpenAddParticipant = () => {
        setOpenAddParticipant(!openAddParticipant)
    }

    const [connectionId, setConnectionId] = useState('')
    const [messageHistory, setMessageHistory] = useState([])

    const token = location?.state?.token

    // const userData = useSelector((state) => state?.login?.userData)
    const providerId = localStorage.getItem('userId')
    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${process.env.REACT_APP_SONORA_SOCKET_ON_EXAM_ROOM}?type=participant????${providerId}`,
        {
            onOpen: (data) => console.log('opened'),
            shouldReconnect: (closeEvent) => true,
            share: true,
        },
    )

    useEffect(() => {
        const linkWaitingRoom = localStorage.getItem('consultation_link')
        if (lastMessage !== null) {
            const message = JSON.parse(lastMessage.data)
            setConnectionId(message.connectionId)
            const action = message?.message
            switch (action) {
                case 'end_success':
                    history.push(`/end-meeting`, { message: message.data })
                    break
                case 'back_to_waiting_room':
                    history.push(`/${linkWaitingRoom}`)
                    break
                case 'end_failed':
                    history.push(`/end-meeting`, { message: message.data })
                    break
                case 'assign_to_provider':
                    history.push(`/${linkWaitingRoom}`)
                    break
                case 'kick':
                    history.push(`/end-meeting`, { message: message.data })
                    break
                default:
                    console.log('action', action)
                    break
            }
        }
    }, [lastMessage, setMessageHistory])
    return (
        <Container style={{ height }}>
            <Main>
                <ReconnectingNotification />
                <MobileTopMenuBar />
                <Room
                    openAddParticipant={openAddParticipant}
                    handleOpenAddParticipant={() => {
                        setOpenAddParticipant(!openAddParticipant)
                    }}
                />
                <MenuBar
                    handleOpenAddParticipant={() => {
                        setOpenAddParticipant(!openAddParticipant)
                    }}
                />
            </Main>
        </Container>
    )
}
