import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getProviderDetail } from '../../services/Provider/provider'
import { Box } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'
import {
    getClinicalNoteInitialData,
    getClinicalNotesDetail,
    getICDCodes,
    _getClinicalNotesDetail,
    getConfigureTemplate,
} from './api/services'
import Behavioral from './Behavioral'
import Medical from './Medical'
import { ClinicalNoteContext } from './context/ClinicalNoteContext'
import {
    SET_ALLERGY_HISTORY,
    SET_CLINICAL_NOTES_DETAILS,
    SET_CLINICAL_NOTES_TEMPLATE,
    SET_ICD_CODES,
    SET_LOADER,
    SET_LOGGED_IN_PROVIDER,
    SET_MASTER_DATA,
    SET_MEDICATION_HISTORY,
    SET_PROVIDER_DETAIL,
} from './store/actionTypes'
import { setCurrentNote } from 'store/actions/clinicalNotes'
import { clinical_note_master } from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { setEncounterClinicalNoteLoaded } from '../../store/actions/provider'
import LoadingPage from '../../utilities/loading-page'
import { getMacroList, getPractitionerMacroList } from '../../store/actions/macros'
import { useNotification } from 'shared/elements/Notification'

export const addOrUpdateVisitedPage = (noteId) => {
    const storedClinicalNotes = localStorage.getItem('visitedClinicalNotes')
    if (storedClinicalNotes) {
        const visitedClinicalNotes = storedClinicalNotes && JSON.parse(storedClinicalNotes)
        if (visitedClinicalNotes) {
            if (!visitedClinicalNotes?.includes(noteId)) {
                const updatednotes = [...visitedClinicalNotes, noteId]
                // Save the updated array to local storage
                localStorage.setItem('visitedClinicalNotes', JSON.stringify(updatednotes))
            } else {
                const updatedClinicalNotes = visitedClinicalNotes?.filter((id) => id !== noteId)
                localStorage.setItem('visitedClinicalNotes', JSON.stringify(updatedClinicalNotes))
            }
        }
    } else {
        localStorage.setItem('visitedClinicalNotes', JSON.stringify([noteId]))
    }
}
const ClinicalNotesWrapper = ({ nodeDetailData, isConsultant }) => {
    const reduxDispatch = useDispatch()
    const history = useHistory()
    const provider_id = localStorage.getItem('userId')
    const notification = useNotification()
    const userData = useSelector((state) => state?.permission.clinical_notes)
    const viewOrEdit =
        userData.includes('update_medical_clinical_notes')
            ? 'edit'
            : 'view'

    // const [visitedClinicalNotes, setVisitedClinicalNotes] = useState([])
    const [noteDetails, setNoteDetails] = useState(() => {
        if (_.isEmpty(nodeDetailData)) {
            return history.location.state
        }
        return nodeDetailData
    })

    const [configureTemplateDetails, setConfigureTemplateDetails] = useState({})
    useEffect(() => {
        if (_.isEmpty(nodeDetailData)) {
            setNoteDetails(history.location.state)
        } else {
            setNoteDetails(nodeDetailData)
        }
    }, [nodeDetailData, history.location.state])

    useEffect(() => {
        if (noteDetails?.id) reduxDispatch(setCurrentNote(noteDetails.id))
        return () => {
            reduxDispatch(setCurrentNote(''))
        }
    }, [noteDetails?.id])

    useEffect(() => {
        reduxDispatch(
            getMacroList({
                page: 1,
                size: 100000,
            }),
        )
    }, [])
    useEffect(() => {
        reduxDispatch(
            getPractitionerMacroList(
                provider_id,
                {page:1,limit:10000}
            ),
        )
    }, [])

    const [providerType, setProviderType] = useState('')
    const [clinicalNoteData, setClinicalNoteData] = useState([])
    const [providerDecision, setProviderDecesion] = useState(false)

    const { state, dispatch } = React.useContext(ClinicalNoteContext)
    useEffect(() => {
        fetchClinicalNote()
        fetchMasterData()
    }, [noteDetails?.id])
    /**
     * Clinical Note Store for Restriction
     */
    useEffect(() => {
        const handleBeforeUnload = () => {
            if (noteDetails?.name === 'edit') addOrUpdateVisitedPage(noteDetails?.id)
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])
    useEffect(() => {
        if (noteDetails?.id && noteDetails?.name === 'edit') addOrUpdateVisitedPage(noteDetails?.id)
        return () => {
            if (noteDetails?.name === 'edit') addOrUpdateVisitedPage(noteDetails?.id)
        }
    }, [noteDetails?.id])
    //End Functionlity for clinical note restriction
    const [clinicalNoteInitialData, setClinicalNoteInitialData] = useState([])
    const fetchClinicalNote = async (updateStatus = '') => {
        try {
            setLoader(true)
            const clinicalNotesDetail = await _getClinicalNotesDetail(noteDetails?.id, viewOrEdit)
            // const configureTemplate = await getConfigureTemplate(noteDetails.id)

            setNoteDetails({
                ...noteDetails,
                ...clinicalNotesDetail.data,
            })

            if (_.isString(clinicalNotesDetail?.data?.data)) {
                const dataAttribute = JSON.parse(clinicalNotesDetail?.data?.data)

                const configureTemplate = dataAttribute?.clinicalNoteTemplate,
                    initData = dataAttribute?.clinicalNoteModel

                setConfigureTemplateDetails(configureTemplate)

                if (noteDetails?.templateId) setClinicalNoteInitialData(initData)

                const applyConfigureTemplate =
                    dataAttribute?.clinicalNoteTemplate?.components?.map((item, i) => {
                        const session = configureTemplate?.data?.components?.find(
                            (temp, index) => temp?.componentId === item?.componentId,
                        )
                        if (session?.status) {
                            delete session.status
                        }
                        if (session?.header) {
                            delete session.header
                        }
                        return session
                            ? {
                                  ...item,
                                  ...session,
                              }
                            : item
                    }) || []

                dispatch({
                    type: SET_CLINICAL_NOTES_TEMPLATE,
                    value: applyConfigureTemplate,
                })
                reduxDispatch(setEncounterClinicalNoteLoaded(true))
            }

            setClinicalNoteData({
                ...clinicalNotesDetail?.data,
                status: updateStatus ? updateStatus : clinicalNotesDetail?.data.status,
                fhir_id: noteDetails?.fhir_id,
            })
        } catch (err) {
            notification('Clinical note id not found !', 'error')
        } finally {
            setLoader(false)
        }
    }

    const setLoader = (value) => {
        dispatch({ type: SET_LOADER, value })
    }

    const fetchMasterData = async () => {
        dispatch({
            type: SET_MASTER_DATA,
            value: clinical_note_master,
        })
    }

    const [clinicalNoteModel, setClinicalNoteModel] = useState({})

    return (
        <>
            {clinicalNoteData?.data ? (
                clinicalNoteData?.template_type !== 'Medical' ? (
                    <Behavioral
                        isConsultant={isConsultant}
                        noteDetails={noteDetails}
                        clinicalNoteData={clinicalNoteData}
                        configureTemplateDetails={configureTemplateDetails}
                        clinicalNoteInitialData={clinicalNoteInitialData}
                        categoryNotes={
                            clinicalNoteData?.data ? JSON.parse(clinicalNoteData.data) : {}
                        }
                        psycho_note={{
                            primary_care_physician:
                                clinicalNoteData?.patient?.primary_care_physician || '',
                            dateTime: clinicalNoteData.createdAt || '',
                            provider:
                                clinicalNoteData?.psycho_created_by ||
                                clinicalNoteData?.provider_id,
                            patient: `${clinicalNoteData?.patient?.first_name || ''} ${
                                clinicalNoteData?.patient?.last_name || ''
                            }`,
                            psychotherapy_note_id: clinicalNoteData.psychotherapy_note_id,
                            encounter_id: clinicalNoteData.encounter_id,
                            last_HPI: clinicalNoteData.last_HPI,
                            chief_complaint: clinicalNoteData.chief_complaint,
                            patient_id: clinicalNoteData?.patient?.id,
                            clinical_note_id: clinicalNoteData?.id,
                        }}
                        previous_cn={clinicalNoteData?.previous_cn}
                        state={state}
                        providerType={providerType}
                        setLoader={setLoader}
                        onUpdate={fetchClinicalNote}
                    />
                ) : (
                    <Medical
                        isConsultant={isConsultant}
                        noteDetails={noteDetails}
                        clinicalNoteData={clinicalNoteData}
                        configureTemplateDetails={configureTemplateDetails}
                        clinicalNoteInitialData={clinicalNoteInitialData}
                        categoryNotes={
                            clinicalNoteData?.data ? JSON.parse(clinicalNoteData.data) : {}
                        }
                        psycho_note={{
                            primary_care_physician:
                                clinicalNoteData?.patient?.primary_care_physician || '',
                            dateTime: clinicalNoteData.createdAt || '',
                            provider:
                                clinicalNoteData?.psycho_created_by ||
                                clinicalNoteData?.provider_id,
                            patient: `${clinicalNoteData?.patient?.first_name || ''} ${
                                clinicalNoteData?.patient?.last_name || ''
                            }`,
                            psychotherapy_note_id: clinicalNoteData.psychotherapy_note_id,
                            encounter_id: clinicalNoteData.encounter_id,
                            last_HPI: clinicalNoteData.last_HPI,
                            chief_complaint: clinicalNoteData.chief_complaint,
                            patient_id: clinicalNoteData?.patient?.id,
                            clinical_note_id: clinicalNoteData?.id,
                        }}
                        previous_cn={clinicalNoteData?.previous_cn}
                        state={state}
                        providerType={providerType}
                        setLoader={setLoader}
                        onUpdate={fetchClinicalNote}
                    />
                )
            ) : (
                // !history?.location?.pathname.includes('/consultation') && (
                //     <>
                //         <LoadingPage />
                //     </>
                // )
                <>
                    <LoadingPage />
                </>
            )}
        </>
    )
}

export default ClinicalNotesWrapper
