import { Grid } from '@material-ui/core'
import moment from 'moment'
import React, { forwardRef } from 'react'

import { Select } from 'shared/elements/FormItem/Select'

import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import * as C from './schema'

export const SmokingStatus = forwardRef((props, ref) => {
    const { formik } = props

    return (
        <div ref={ref}>
            <Grid>
                <Grid>
                    {/* <Typography variant="h4" className="heading1-title">
                        Smoking Status
                    </Typography> */}

                    <Grid
                        // container
                        // item
                        // spacing={2}
                        // className="modal-spacer"
                        style={{ marginBottom: 16 }}>
                        <Grid item xs={12}>
                            <Select
                                label="Smoking Status"
                                name="smoking_status"
                                placeholder="Select from below"
                                value={formik.values.smoking_status || ''}
                                // error={formik.touched.smoking_status && Boolean(formik.errors.smoking_status)}
                                // helperText={formik.touched.smoking_status && formik.errors.smoking_status}
                                options={C.SMOKING_STATUS.map((item) => ({
                                    key: item.code,
                                    value: `${item.text}`,
                                }))}
                                onChange={(value) => {
                                    formik.setFieldValue('smoking_status', value)
                                    formik.setFieldValue('smoking_start_date', '')
                                    formik.setFieldValue('smoking_end_date', '')
                                }}
                            />
                        </Grid>

                        {formik.values.smoking_status &&
                            formik.values.smoking_status !== '266919005' && ( // FIXME: Never smoking
                                <Grid item xs={3}>
                                    <DatePicker
                                        label="Smoking Start Date"
                                        name="smoking_start_date"
                                        value={formik.values.smoking_start_date}
                                        error={
                                            formik.touched.smoking_start_date &&
                                            Boolean(formik.errors.smoking_start_date)
                                        }
                                        helperText={
                                            formik.touched.smoking_start_date &&
                                            formik.errors.smoking_start_date
                                        }
                                        onChange={(value) => {
                                            formik.setFieldValue('smoking_start_date', value)
                                        }}
                                        notDefaultValue
                                        maxDate={formik.values.smoking_end_date || moment()}
                                    />
                                </Grid>
                            )}

                        {formik.values.smoking_status &&
                            formik.values.smoking_status === '8517006' && ( // FIXME: Ex-smoker
                                <Grid item xs={6}>
                                    <DatePicker
                                        label="Smoking End Date"
                                        name="smoking_end_date"
                                        value={formik.values.smoking_end_date}
                                        error={
                                            formik.touched.smoking_end_date &&
                                            Boolean(formik.errors.smoking_end_date)
                                        }
                                        helperText={
                                            formik.touched.smoking_end_date &&
                                            formik.errors.smoking_end_date
                                        }
                                        onChange={(value) => {
                                            formik.setFieldValue('smoking_end_date', value)
                                        }}
                                        notDefaultValue
                                        minDate={
                                            formik.values.smoking_start_date ||
                                            moment().subtract(100, 'year')
                                        }
                                        maxDate={moment()}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
})
