import React from 'react'

export default function IconRequestFaile() {
    return (
        <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.0006 64.8541C18.5332 64.8541 5.14648 51.4673 5.14648 34.9999C5.14648 18.5325 18.5332 5.14575 35.0006 5.14575C51.4681 5.14575 64.8548 18.5325 64.8548 34.9999C64.8548 51.4673 51.4681 64.8541 35.0006 64.8541ZM35.0006 6.52075C19.2972 6.52075 6.52148 19.2965 6.52148 34.9999C6.52148 50.7033 19.2972 63.4791 35.0006 63.4791C50.7041 63.4791 63.4798 50.7033 63.4798 34.9999C63.4798 19.2965 50.7041 6.52075 35.0006 6.52075Z"
                fill="#E81212"
                stroke="#E81212"
                strokeWidth="3"
            />
            <path
                d="M14.2915 56.1041C14.1137 56.1041 13.9477 56.0432 13.8064 55.9018C13.5463 55.6417 13.5463 55.1915 13.8064 54.9314L54.6397 14.0981C54.8997 13.8381 55.35 13.8381 55.61 14.0981C55.8701 14.3582 55.8701 14.8084 55.61 15.0685L14.7767 55.9018C14.6353 56.0432 14.4693 56.1041 14.2915 56.1041Z"
                fill="#E81212"
                stroke="#E81212"
                strokeWidth="3"
            />
        </svg>
    )
}
