import React, { useState, useEffect, useRef, useCallback } from 'react'

import { debounce } from 'lodash'
import { FormLabel } from '@material-ui/core'
import { Autocomplete, Box, Typography } from '@mui/material'
import { SearchBoxInput } from './SearchBoxInput'
import { BaseType, SearchBoxProps } from './types'

export const SearchBox = <T extends BaseType>(props: SearchBoxProps<T>) => {
    const { renderKey = 'code', isOptionEqualToValue = () => true } = props

    const [value, setValue] = useState<T | null>(null)
    const [cache, setCache] = useState<T | null>(null)
    const [inputValue, setInputValue] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<T[]>([])
    const [visible, setVisibility] = useState(false)
    const [warningSearch, setWarningSearch] = useState(false)

    useEffect(() => {
        if (props.value?.['genericName'] !== '' || props.value?.[renderKey]) {
            const displayText =
                props.getOptionLabel?.(props.value) || props.value?.[renderKey] || ''
            setInputValue(displayText)
            setValue(props.value as T)

            if (cache && !isOptionEqualToValue(props.value as T, cache)) {
                search.current(props.value?.['genericName'] || displayText)
            }
        } else {
            setInputValue('')
        }
    }, [props.value?.[renderKey], props.value?.['genericName']])

    const search = useRef(
        debounce(async (val: string, initialValue = null, searchOnFirstLoad = false) => {
            if (!val.length) return setOptions([])

            setLoading(true)

            try {
                const data = (await props.searchHandler(val)) as T[]
                const options = data.map((entity, index) => ({ ...entity, key: index }))

                setOptions(options)

                // Set initial text for search box on first load
                if (searchOnFirstLoad) {
                    const entity = options.find((option) =>
                        props.isOptionEqualToValue?.(option, initialValue),
                    )
                    if (entity) {
                        setValue(entity)
                        setInputValue(props.getOptionLabel?.(entity) || '')
                    }
                }
            } catch (error) {
                console.log('[SearchBox]', error)
            } finally {
                setLoading(false)
            }
        }, 500),
    )

    useEffect(() => {
        if (props.searchOnFirstLoad && props.value?.[renderKey]) {
            search.current(props.value?.[renderKey], props.value, props.searchOnFirstLoad)
        }
    }, [props.searchOnFirstLoad, props.value?.[renderKey]])

    const handleSearch = useCallback((value: string) => {
        if (props.notWarningSearch || value.trim().length > 1) {
            setValue(null)
            search.current(value.split(',')[0])
            setVisibility(true)
        } else {
            setWarningSearch(true)
            setVisibility(false)
        }
    }, [inputValue])

    const icd10Obj = React.useMemo(() => {
        return props?.icd10Arr?.find((icd: any) => icd?.code === inputValue)
    }, [inputValue])

    return (
        <>
            {!!props.label && (
                <FormLabel
                    component="p"
                    className="input-form-label"
                    style={{ fontSize: 14, fontWeight: 500 }}>
                    <span className={!!props.required ? 'required' : ''}>{props.label}</span>
                </FormLabel>
            )}
            <Box
                sx={{
                    width: '100%',
                    '& .MuiIconButton-root': {
                        transform: 'none !important',
                    },
                    '& .MuiAutocomplete-root': {
                        width: '100%',

                        '& .MuiInputAdornment-root': {
                            height: '100%',
                        },
                        '& .MuiAutocomplete-input': {
                            flex: 1,
                            marginRight: '0 !important',
                        },
                    },
                    '& .MuiInputBase-input': {
                        marginRight: 24,
                    },
                }}>
                <Autocomplete
                    autoSelect={false}
                    clearIcon={false}
                    autoComplete
                    loading={loading}
                    options={options}
                    filterOptions={() => options}
                    value={value}
                    isOptionEqualToValue={isOptionEqualToValue}
                    open={visible}
                    inputValue={inputValue}
                    renderOption={(optionProps, option) => {
                        return (
                            <li {...optionProps} key={option.key} aria-selected={false}>
                                <span
                                    className={
                                        option[renderKey] !== undefined &&
                                        option[renderKey] ===
                                            isOptionEqualToValue?.(option, props.value as T)
                                            ? 'selected'
                                            : undefined
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: `<span class='highlight'>${
                                            !!props.renderOption
                                                ? props.renderOption(option) || ''
                                                : ''
                                        }</span>`,
                                    }}
                                />
                            </li>
                        )
                    }}
                    onOpen={() => {
                        if (inputValue.trim().length >= 1) {
                            setVisibility(true)
                        }
                    }}
                    onClose={() => setVisibility(false)}
                    onChange={(event, newValue) => {
                        props.onChange && props.onChange(newValue)

                        // Store cache to prevent search again when props value is set
                        setCache(newValue)
                        setInputValue(newValue?.display || '')
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (event?.type === 'click') {
                            return setInputValue(cache?.display || '')
                        }

                        if (
                            event?.type === 'change' &&
                            typeof newInputValue === 'string' &&
                            newInputValue !== 'undefined'
                        ) {
                            setInputValue(newInputValue || '')
                            handleSearch(newInputValue)
                        }

                        if (newInputValue.trim().length > 1 && warningSearch) {
                            setWarningSearch(false)
                        }
                    }}
                    getOptionLabel={(option) =>
                        props.getOptionLabel ? props.getOptionLabel(option) : ''
                    }
                    renderInput={(params) => (
                        <SearchBoxInput<T>
                            inputValue={inputValue}
                            icd10Obj={icd10Obj}
                            loading={loading}
                            isHelpIcon={props.isHelpIcon}
                            name={props.name}
                            placeholder={props.placeholder}
                            error={props.error}
                            helperText={props.helperText}
                            handleOnChange={props.onChange}
                            setVisibility={setVisibility}
                            setWarningSearch={setWarningSearch}
                            {...params}
                        />
                    )}
                />
                {!props.notWarningSearch && warningSearch && (
                    <Typography sx={{ color: '#f44336 !important', fontSize: '12px !important' }}>
                        Please enter minimum 2 characters in the search string!
                    </Typography>
                )}
            </Box>
        </>
    )
}
