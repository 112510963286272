const IncomingMessageIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                width="24"
                height="24"
                rx="6"
                fill="white"
            />
            <path
                d="M12 4.5V9.75L13.5 8.25"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 9.75L10.5 8.25"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.48499 12.75H7.79249C8.07749 12.75 8.33249 12.9075 8.45999 13.1625L9.33749 14.9175C9.59249 15.4275 10.11 15.75 10.68 15.75H13.3275C13.8975 15.75 14.415 15.4275 14.67 14.9175L15.5475 13.1625C15.675 12.9075 15.9375 12.75 16.215 12.75H19.485"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.01746 17.2502C5.73746 18.8252 7.23746 19.5002 9.74996 19.5002H14.25C18 19.5002 19.5 18.0002 19.5 14.2502V11.2502C19.5 8.04766 18.405 6.48766 15.75 6.09766"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.25 6.09766C5.595 6.48766 4.5 8.04766 4.5 11.2502V14.2502"
                stroke="#667689"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export default IncomingMessageIcon
