import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    makeStyles,
    Popover,
    Tooltip,
    Typography,
    FormControl,
    FilledInput,
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputAdornment,
    IconButton,
    Chip,
    CircularProgress,
} from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import { useDispatch, useSelector } from 'react-redux'
import ConsultantStatusDialog from '../../../components/shared/Elements/ConsultantStatusDialog'
import ContactPhoneIcon from '@material-ui/icons/ContactPhone'
import {
    handleGetEncounterQueueList,
    postAdmit,
    postEndRoom,
} from '../../../store/actions/provider'
import EmptyList from './EmptyList'
import EncounterList from './EncounterList'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import { VIEW } from '../constant'
import { Badge, Box, Modal, useMediaQuery } from '@mui/material'
import TodayIcon from '@material-ui/icons/Today'
import './EncounterQueue.scss'
import { ReactComponent as FilterIcon } from '../../../assests/images/filter.svg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '../../../assests/icons/search_icon.svg'
import {
    _getClinicalNotesFilterPatient,
    _getClinicalNotesFilterProvider,
} from 'containers/ClinicalNotes/api/services'
import { useNotification } from 'shared/elements/Notification'
import { getSpecialty } from 'services/Calendar'
import { useDebounce } from 'shared/hooks'
import { setPatientList } from 'store/actions/patient'
import { paramsConstruction } from '..'

const useStyles = makeStyles({
    root_popover: {
        '& .MuiPaper-root': '1207px',
    },

    scrollable_content: {
        maxHeight: '150px',
        overflow: 'auto',
        flexDirection: 'row',
        padding: '2px',
        '& .MuiFormControlLabel - root': {
            width: '100%',
        },
    },
    widthSearch: {
        width: '17rem',
    },
    filter_btn: {
        cursor: 'pointer',
        '& svg path': {
            fill: '#000000',
        },
    },
    encounterQueueWrapper: {
        width: '100%',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 100px)',
        height: 'fit-content',
    },
    container: {
        overflow: 'auto',
        position: 'relative',
        borderLeft: '1px solid #E9ECF1',
        height: '100%',
        background: '#fff',
    },
    encounterHeader: {
        padding: '20px 10px 10px 20px',
        fontSize: 16,
        color: '#303E4E',
        fontWeight: 500,
    },
    encounterSubHeader: {
        padding: '5px 5px 5px 20px',
        fontSize: 12,
        color: '#303E4E',
        fontWeight: 350,
    },
    calendar_view: {
        paddingTop: 12,
        borderTop: '5px solid #E9ECF1',
        // height: '350px',
        width: '100%',
    },
    pemissiondeniedText: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
})

const EncounterQueue = (props) => {
    const matchesMobile = useMediaQuery('(max-width:1280px)')
    const [openEncounter, setOpenEncounter] = useState(false)
    const [openModalCalendar, setOpenModalCalendar] = useState(false)
    const dispatch = useDispatch()
    const { onNavigate, onSelectMonth } = props
    const scrollableContentRef = useRef(null)
    const scrollableContentRefProvider = useRef(null)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [scrollPositionProvider, setScrollPositionProvider] = useState(0)

    const encounterQueueList = useSelector((state) => state.provider.encounterQueueList)
    const [initEncounterList, setInitEncounterList] = useState(encounterQueueList)
    const [encounterFilteredQueueList, setencounterFilteredQueueList] = useState([])
    const shedulePermission = useSelector((state) => state.permission?.scheduling)
    const dataIsAvailable = useSelector((state) => state?.permission.isPermissionAvailable)
    const [date, changeDate] = useState(moment())
    const [openEndDialog, setOpenEndDialog] = useState(false)
    const [infoEncouter, setInfoEncouter] = useState({})
    const [filterEl, setFilterEl] = React.useState(null)
    const [patientData, setPatientData] = useState([])
    const [bundleId, setBundleId] = useState('')
    const notification = useNotification()
    const [rawSpecialtyData, setRawSpecialtyData] = useState([])
    const [specialtyData, setSpecialtyData] = useState([])
    const [providerData, setProviderData] = useState([])
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [currentPageProvider, setCurrentPageProvider] = useState(1)
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = useState(null)
    const [generatedParams, setgeneratedParams] = useState({})
    const classes = useStyles()
    const [filterSearch, setFilterSearch] = useState({})
    const [spinner, setSpinner] = useState(false)
    const [lVSetInVariable, setLVInVariable] = useState(false)
    const handleOnchangeDate = (e) => {
        changeDate(e)
        // onNavigate(moment(e), VIEW.DAY)
        onNavigate(moment(e))
    }
    const [filterLoadingPatient, setFilterLoadingPatient] = useState(true)
    const [filterLoadingProvider, setFilterLoadingProvider] = useState(true)
    const [filterLoadingSpeciality, setFilterLoadingSpeciality] = useState(true)
    const [isFirstPatient, setIsFirstPatient] = useState(false)

    const dispatchRedux = useDispatch()

    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patientSearch: '',
        specialtySearch: '',
        providerSearch: '',
    })

    const debouncedSearchFilterText = useDebounce(searchTextOffilter.patientSearch, 500)
    const debouncedSearchFilterTextProvider = useDebounce(searchTextOffilter.providerSearch, 500)

    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedSearchFilterTextProvider])

    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        patientExpanded: false,
        specialtyExpanded: false,
        providerExpanded: false,
    })

    const [selectedUser, setSelectedUser] = useState({
        selectedPatient: [],
        selectedSpecialty: [],
        selectedProvider: [],
    })

    useEffect(() => {
        handleLoadData()
    }, [debouncedSearchFilterText])

    useEffect(() => {
        setSpinner(true)
        const patientList = [],
            providerList = []
        if (lVSetInVariable) {
            filterDataSetInLocal()
            const getParams = paramsConstruction(selectedUser)
            dispatchRedux(handleGetEncounterQueueList(getParams))
        }
        if (selectedUser.selectedProvider.length) {
            setencounterFilteredQueueList([...encounterQueueListFiltered()])
        } else {
            setencounterFilteredQueueList([])
        }
        setSpinner(false)
    }, [selectedUser])

    const loadEncounterListOnDemand = (props) => {
        // const getParams = paramsConstruction(selectedUser)
        // dispatchRedux(handleGetEncounterQueueList(getParams))
        setDefaultFilterValue(props)
    }

    const encounterQueueListFiltered = () => {
        const filteredProvider = []
        for (let i = 0; i < selectedUser.selectedProvider.length; i++) {
            const selected_id = selectedUser.selectedProvider[i]
            for (let j = 0; j < encounterQueueList.length; j++) {
                if (encounterQueueList[j].provider_id === selected_id) {
                    filteredProvider.push(encounterQueueList[j])
                }
            }
        }

        return filteredProvider
    }

    const filterDataSpeciality = (event) => {
        const input = event
        setSearchTextOffilter((old) => ({
            ...old,
            specialtySearch: input,
        }))

        delayedRunHook(input?.toLowerCase())
        setRawSpecialtyData([])
        if (input === '') {
            setSpecialtyData(rawSpecialtyData)
        } else {
            const filtered = rawSpecialtyData.filter((item) =>
                item?.label?.toLowerCase().includes(input?.toLowerCase()),
            )
            setSpecialtyData(filtered)
        }
    }

    function debounce(fn, delay) {
        let timeoutID
        return function (...args) {
            if (timeoutID) clearTimeout(timeoutID)
            timeoutID = setTimeout(() => {
                fn(...args)
            }, delay || 200)
        }
    }

    const delayedRunHook = useCallback(
        debounce((val) => {
            handleLoadDataSpecialty(val)
        }, 1000),
        [],
    )

    const handleChipDeleteAll = (e) => {
        setSelectedUser({
            selectedPatient: [],
            selectedSpecialty: [],

            selectedProvider: [],
        })
        setTotalNoOfFilterUsed([])
        setFilterSearch({
            selectedPatient: [],
            selectedSpecialty: [],

            selectedProvider: [],
        })
    }
    // const handlePopoverOpened = () => {
    //     if (scrollableContentRef.current) {
    //         scrollableContentRef.current.scrollTop = scrollPosition;
    //     }
    //     if (scrollableContentRefProvider.current) {
    //         scrollableContentRefProvider.current.scrollTop = scrollPositionProvider;
    //     }
    // };

    const handleScrollProvider = async () => {
        if (totalPageNoOfProvider > currentPageProvider) {
            const scrollableContent = scrollableContentRefProvider?.current
            const tolerance = 2
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd) {
                try {
                    const nextPage = currentPageProvider + 1
                    const payload = {
                        page: nextPage,
                        limit: 20,
                        name: searchTextOffilter.providerSearch,
                    }
                    const res = await _getClinicalNotesFilterProvider(payload)
                    const tempUserList = []
                    res?.data?.data?.data?.map((item) =>
                        tempUserList.push({ label: item?.name, value: item?.id }),
                    )
                    const sortedProviderData = [...providerData, ...tempUserList]
                    setProviderData(sortedProviderData)
                    setCurrentPageProvider(nextPage)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
            }
        }
    }

    const handleChipDelete = (name) => {
        if (name === 'selectedPatient') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedSpecialty') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'specialty')
            setTotalNoOfFilterUsed(newData)
        } else if (name === 'selectedProvider') {
            const newData = totalNoOfFilterUsed.filter((item) => item !== 'provider')
            setTotalNoOfFilterUsed(newData)
        }
        setSelectedUser((selectedUser) => ({
            ...selectedUser,
            [name]: [],
        }))
    }
    const handleLoadDataProvider = async (name = '') => {
        try {
            setFilterLoadingProvider(true)
            if (openFilter) {
                const tempUserList = []

                if (encounterQueueList?.length > 0) {
                    // const idsArray = encounterQueueList?.map((item) => item?.provider_id)
                    // const ids = idsArray.join(',')
                    // const ids = [...new Set(idsArray)]?.join(',')
                    const payload = {
                        page: 1,
                        limit: 50,
                        name: searchTextOffilter.providerSearch,
                        // ids,
                    }

                    const res = await _getClinicalNotesFilterProvider(payload)

                    res?.data?.data?.data?.map((item) =>
                        tempUserList.push({ label: item?.name, value: item?.id }),
                    )

                    const b = res?.data?.data?.total / 20
                    const remainder = res?.data?.data?.total % 20
                    let result

                    if (remainder > 0) {
                        result = Math.floor(b) + 1
                    } else {
                        result = Math.floor(b)
                    }

                    setCurrentPageProvider(1)
                    setTotalPageNoOfProvider(result)
                }
                setProviderData(tempUserList)
                setFilterLoadingProvider(false)
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        }
    }
    const handleLoadDataSpecialty = async (name = '') => {
        try {
            setFilterLoadingSpeciality(true)
            if (openFilter) {
                const payload = {
                    page: 1,
                    size: 20,
                    search: name,
                }
                const res = await getSpecialty(payload)
                const tempUserList = []
                res?.data?.data.map((item) =>
                    tempUserList.push({ label: item?.specialty, value: item?.id }),
                )
                setRawSpecialtyData(tempUserList)
                setSpecialtyData(tempUserList)
                setFilterLoadingSpeciality(false)
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        }
    }

    const handleLoadData = async (name = '') => {
        try {
            setFilterLoadingPatient(true)
            if (openFilter) {
                const tempUserList = []

                if (encounterQueueList?.length > 0) {
                    // const idsArray = encounterQueueList?.map((item) => item?.patient_id)
                    // const ids = [...new Set(idsArray)]?.join(',')

                    const payload = {
                        page: 1,
                        size: 50,
                        name: searchTextOffilter.patientSearch,
                        search: searchTextOffilter.patientSearch,
                        query: searchTextOffilter.patientSearch,
                        // ids,
                    }

                    const res = await _getClinicalNotesFilterPatient(payload)

                    res?.data?.data.map((item) =>
                        tempUserList.push({
                            label: `${item.first_name}${item.middle_name ? ` ${item.middle_name}` : ''
                                } ${item.last_name} `,
                            value: item?.id,
                        }),
                    )

                    setBundleId(res?.data?.bundle_id)

                    const b = res?.data?.paging?.total / 20
                    const remainder = res?.data?.paging?.total % 20
                    let result

                    if (remainder > 0) {
                        result = Math.floor(b) + 1
                    } else {
                        result = b
                    }

                    setTotalPageNoOfPatient(result)
                    setCurrentPage(1)
                    setFilterLoadingPatient(false)
                    setPatientData(tempUserList)
                   
                }
                // let sortedArray = tempUserList.sort((item1, item2) => item1.label.localeCompare(item2.label));
                // if (!isFirstPatient) {
                //     setPatientData(tempUserList)
                //     setIsFirstPatient(true)
                // }

                
            }
        } catch (e) {
            // notification('An error occurred, please try again!', 'error');
        }
    }

    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const handleScroll = async () => {
        setScrollPosition(scrollableContentRef?.current?.scrollTop)
        if (totalPageNoOfPatient > currentPage) {
            const scrollableContent = scrollableContentRef?.current
            const tolerance = 2
            const isAtEnd =
                scrollableContent?.scrollHeight - scrollableContent?.scrollTop <=
                scrollableContent?.clientHeight + tolerance
            if (isAtEnd && openFilter) {
                try {
                    const nextPage = currentPage + 1
                    const payload = {
                        page: nextPage,
                        size: 20,
                        name: searchTextOffilter.patientSearch,
                        bundle_id: bundleId,
                        search: searchTextOffilter.patientSearch,
                        query: searchTextOffilter.patientSearch,
                    }
                    const res = await _getClinicalNotesFilterPatient(payload)
                    const tempUserList = []
                    res?.data?.data.map((item) =>
                        tempUserList.push({
                            label: `${item.first_name}${item.middle_name ? ` ${item.middle_name}` : ''
                                } ${item.last_name}`,
                            value: item?.id,
                        }),
                    )
                    // let sortedArray = tempUserList.sort((item1, item2) => item1.label.localeCompare(item2.label));
                    const sortedPatientData = [...patientData, ...tempUserList]
                    // sortedPatientData.sort((item1, item2) => item1.label.localeCompare(item2.label))
                    setPatientData(sortedPatientData)
                    setCurrentPage(nextPage)
                    setBundleId(res?.data?.bundle_id)
                } catch (e) {
                    notification('An error occurred, please try again!', 'error')
                }
            }
        }
    }

    const openFilter = Boolean(filterEl)
    const id = openFilter ? 'simple-popper' : undefined
    const handleFilterClick = (event) => {
        setFilterEl(filterEl ? null : event.currentTarget)
    }

    const handleAccordionExpand = (name) => {
        if (name === 'patientExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.patientExpanded,
                }
            })
        } else if (name === 'specialtyExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.specialtyExpanded,
                }
            })
        } else if (name === 'providerExpanded') {
            setIsAccordionExpanded((old) => {
                return {
                    ...old,
                    [name]: !isAccordionExpanded.providerExpanded,
                }
            })
        }
    }

    const handleFilterClose = () => {
        setFilterEl(null)
    }

    const handleOnChangeMonth = (e) => {
        // onSelectMonth(e)
        // console.log(e, 'change month')
    }

    const handleStartMeeting = (item) => {
        dispatch(
            postAdmit({
                encounter_id: item?.encounter_id,
                phone_number: item?.phone_number,
                screen: 'EncounterQueue',
            }),
        )
    }

    const handleEndMeeting = (item) => {
        setInfoEncouter(item)
        setOpenEndDialog(true)
    }

    const handleConfirm = () => {
        const params = {
            encounter_id: infoEncouter?.encounter_id,
            phone_number: infoEncouter?.phone_number,
            screen: 'EncounterQueue',
            endEncouter: true,
        }
        dispatch(postEndRoom(params))
        setOpenEndDialog(false)
    }

    const handleCloseEncounter = () => {
        setOpenEncounter(false)
    }
    const setDefaultFilterValue = (props = {}) => {
        const storedFilter = localStorage.getItem('setEncounterFilter')
        const filteredStoredValue = localStorage.getItem('totalEncounterFilter')
        const getlocalStoreFilterState = storedFilter ? JSON.parse(storedFilter) : null
        const getLocalStoreTotalFilter = filteredStoredValue
            ? JSON.parse(filteredStoredValue)
            : null
        if (getlocalStoreFilterState) {
            setSelectedUser(getlocalStoreFilterState)
            setTotalNoOfFilterUsed(getLocalStoreTotalFilter)
        } else {
            const getParams = paramsConstruction(selectedUser, props.forceUpdate)
            dispatchRedux(handleGetEncounterQueueList(getParams))
        }
        setLVInVariable(true)
    }

    const filterDataSetInLocal = () => {
        localStorage.setItem(
            'setEncounterFilter',
            JSON.stringify({
                ...selectedUser,
            }),
        )
        localStorage.setItem('totalEncounterFilter', JSON.stringify([...totalNoOfFilterUsed]))
    }
    const checkboxHandle = (event, user_id, name) => {
        if (name === 'selectedPatient') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedPatient.includes(user_id)) {
                    data = [...selectedUser.selectedPatient, user_id]
                }
            } else {
                const filtered = selectedUser?.selectedPatient?.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('patient')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'patient'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'patient')
                setTotalNoOfFilterUsed(newData)
            }
        } else if (name === 'selectedSpecialty') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedSpecialty.includes(user_id)) {
                    data = [...selectedUser.selectedSpecialty, user_id]
                }
            } else {
                const filtered = selectedUser.selectedSpecialty.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('specialty')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'specialty'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'specialty')
                setTotalNoOfFilterUsed(newData)
            }
        } else if (name === 'selectedProvider') {
            let data = []
            if (event.target.checked) {
                if (!selectedUser.selectedProvider.includes(user_id)) {
                    data = [...selectedUser.selectedProvider, user_id]
                }
            } else {
                const filtered = selectedUser.selectedProvider.filter((item) => item !== user_id)
                data = filtered
            }
            setSelectedUser((selectedUser) => ({
                ...selectedUser,
                [name]: data,
            }))
            if (data.length > 0) {
                if (!totalNoOfFilterUsed.includes('provider')) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, 'provider'])
                }
            } else if (data.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== 'provider')
                setTotalNoOfFilterUsed(newData)
            }
        }
    }

    useEffect(() => {
        if (openFilter === true) {
            handleLoadDataSpecialty()
            handleLoadDataProvider()
            handleLoadData()
            setDefaultFilterValue()
        }
    }, [openFilter])

    useEffect(() => {
        setDefaultFilterValue()
    }, [])

    return (
        <>
            {!matchesMobile ? (
                <Grid
                    container
                    alignItems="center"
                    className={classes.container}
                    direction="column"
                    justifyContent="flex-start">
                    <Grid className={classes.encounterQueueWrapper}>
                        <div
                            className={classes.encounterHeader}
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            Encounter Queue (
                            {encounterQueueList?.length && !selectedUser.selectedProvider.length
                                ? encounterQueueList?.length
                                : encounterFilteredQueueList?.length &&
                                    selectedUser.selectedProvider.length
                                    ? encounterFilteredQueueList?.length
                                    : 0}
                            )
                            <div
                                className={classes.filter_btn}
                                style={{ display: 'flex', marginRight: '10px' }}
                                onClick={handleFilterClick}>
                                <FilterIcon style={{ color: 'black' }} />
                                {totalNoOfFilterUsed.length > 0 && (
                                    <span
                                        style={{
                                            position: 'relative',
                                            right: '9px',
                                            bottom: '10px',
                                            display: 'flex',
                                            alignContent: 'center',
                                            fontWeight: 'bold',
                                            fontSize: '9px',
                                            borderRadius: '20px',
                                            background: '#5571C6',
                                            color: 'white',
                                            // padding: '5px',
                                            height: '15px',
                                            width: '15px',
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                        {totalNoOfFilterUsed.length}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className={classes.encounterSubHeader}>
                            {moment(new Date(props.daySelect)).format('MMMM DD, YYYY')}
                        </div>
                        <Box
                            sx={{
                                margin: '5px 0',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            {/* {encounterQueueList?.length > 0 ? (
                                <EncounterList
                                    encounterQueueList={encounterQueueList}
                                    handleStartMeeting={handleStartMeeting}
                                    handleEndMeeting={handleEndMeeting}
                                />
                            ) : (
                                <EmptyList />
                            )} */}
                            {!dataIsAvailable ? (
                                <EmptyList />
                            ) : shedulePermission.includes('view_encounter_queues') ? (
                                encounterQueueList?.length > 0 &&
                                    selectedUser.selectedProvider.length === 0 ? (
                                    <EncounterList
                                        encounterQueueList={encounterQueueList}
                                        handleStartMeeting={handleStartMeeting}
                                        handleEndMeeting={handleEndMeeting}
                                        refetchSchedule={props.refetchSchedule}
                                        refetchEncounters={loadEncounterListOnDemand}
                                    />
                                ) : selectedUser.selectedProvider.length > 0 &&
                                    encounterFilteredQueueList.length > 0 ? (
                                    <EncounterList
                                        encounterQueueList={encounterFilteredQueueList}
                                        handleStartMeeting={handleStartMeeting}
                                        handleEndMeeting={handleEndMeeting}
                                        refetchSchedule={props.refetchSchedule}
                                        refetchEncounters={loadEncounterListOnDemand}
                                    />
                                ) : (
                                    <EmptyList refetchEncounters={loadEncounterListOnDemand} />
                                )
                            ) : (
                                <span className={classes.pemissiondeniedText}>
                                    "Sorry! You do not have permission to access this feature."
                                </span>
                            )}
                            {spinner && <CircularProgress style={{ position: 'absolute' }} />}
                        </Box>

                        <ConsultantStatusDialog
                            open={openEndDialog}
                            handleClose={() => {
                                setOpenEndDialog(false)
                            }}
                            handleConfirm={() => {
                                handleConfirm()
                            }}
                        />
                    </Grid>
                </Grid>
            ) : (
                <Box className="encounterQueue-mobile">
                    <Box className="contact" onClick={() => setOpenEncounter(true)}>
                        <Tooltip title="Encounter Queue">
                            <Box className="icon-encounterQueue">
                                <Badge
                                    badgeContent={encounterQueueList?.length || 0}
                                    color="secondary">
                                    <ContactPhoneIcon />
                                </Badge>
                            </Box>
                        </Tooltip>
                    </Box>
                    <Box className="contact" onClick={() => setOpenModalCalendar(true)}>
                        <Tooltip title="Filter date">
                            <Box className="icon-encounterQueue">
                                <TodayIcon />
                            </Box>
                        </Tooltip>
                    </Box>
                    <Modal
                        disableEnforceFocus={true}
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="modal-custom"
                        open={openEncounter}
                        onClose={handleCloseEncounter}
                        closeAfterTransition>
                        <Box className="wrap-ct-modal">
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                <Typography className="title-modal-lb">
                                    Encounter Queue (
                                    {encounterQueueList?.length &&
                                        !selectedUser.selectedProvider.length
                                        ? encounterQueueList?.length
                                        : encounterFilteredQueueList?.length &&
                                            selectedUser.selectedProvider.length
                                            ? encounterFilteredQueueList?.length
                                            : 0}
                                    )
                                </Typography>

                                <span
                                    className={classes.filter_btn}
                                    style={{ display: 'flex', marginRight: '10px' }}
                                    onClick={handleFilterClick}>
                                    <FilterIcon style={{ color: 'black' }} />
                                    {totalNoOfFilterUsed.length > 0 && (
                                        <div
                                            style={{
                                                justifyContent: 'center',
                                                position: 'relative',
                                                right: '10px',
                                                bottom: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center',
                                                fontWeight: 'bold',
                                                fontSize: '9px',
                                                borderRadius: '20px',
                                                background: '#5571C6',
                                                color: 'white',
                                                padding: '5px',
                                                height: '10px',
                                                width: '10px',
                                            }}>
                                            {totalNoOfFilterUsed.length}
                                        </div>
                                    )}
                                </span>
                            </Box>
                            {/* {encounterQueueList?.length > 0 ? (
                                <EncounterList
                                    encounterQueueList={encounterQueueList}
                                    handleStartMeeting={handleStartMeeting}
                                    handleEndMeeting={handleEndMeeting}
                                />
                            ) : (
                                <Box sx={{ marginTop: '70px' }}>
                                    <EmptyList />
                                </Box>
                            )} */}
                            {!dataIsAvailable ? (
                                <EmptyList />
                            ) : shedulePermission.includes('view_encounter_queues') ? (
                                encounterQueueList?.length > 0 &&
                                    selectedUser.selectedProvider.length === 0 ? (
                                    <EncounterList
                                        encounterQueueList={encounterQueueList}
                                        handleStartMeeting={handleStartMeeting}
                                        handleEndMeeting={handleEndMeeting}
                                        refetchSchedule={props.refetchSchedule}
                                        refetchEncounters={loadEncounterListOnDemand}
                                    />
                                ) : selectedUser.selectedProvider.length > 0 &&
                                    encounterFilteredQueueList.length > 0 ? (
                                    <EncounterList
                                        encounterQueueList={encounterFilteredQueueList}
                                        handleStartMeeting={handleStartMeeting}
                                        handleEndMeeting={handleEndMeeting}
                                        refetchSchedule={props.refetchSchedule}
                                        refetchEncounters={loadEncounterListOnDemand}
                                    />
                                ) : (
                                    <EmptyList refetchEncounters={loadEncounterListOnDemand} />
                                )
                            ) : (
                                <span className={classes.pemissiondeniedText}>
                                    "Sorry! You do not have permission to access this feature."
                                </span>
                            )}
                        </Box>
                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-openModalCalendar"
                        aria-describedby="transition-modal-description"
                        className="modal-custom"
                        open={openModalCalendar}
                        onClose={() => setOpenModalCalendar(false)}
                        closeAfterTransition>
                        <Box className="wrap-ct-modal">
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '100%',
                                    '& .MuiPickersCalendarHeader-transitionContainer': {
                                        ' & p': {
                                            color: '#303E4E',
                                            fontWeight: 500,
                                            fontSize: 16,
                                        },
                                    },
                                }}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        variant="static"
                                        disableToolbar
                                        // openTo="year"
                                        value={date}
                                        onChange={handleOnchangeDate}
                                        onMonthChange={handleOnChangeMonth}
                                        onClose={() => setOpenModalCalendar(false)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Box>
                    </Modal>
                    <ConsultantStatusDialog
                        open={openEndDialog}
                        handleClose={() => {
                            setOpenEndDialog(false)
                        }}
                        handleConfirm={() => {
                            handleConfirm()
                        }}
                    />
                </Box>
            )}
            <Box className={classes.root_popover}>
                <Popover
                    id={id}
                    open={openFilter}
                    anchorEl={filterEl}
                    onClose={handleFilterClose}
                    // TransitionProps={{
                    //     onEntered: handlePopoverOpened
                    // }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}>
                    <div className="popover_card_holder">
                        <div className="heading_holder">
                            <h2 className="heading">Filter By:</h2>
                            <p
                                className="clear_holder"
                                onClick={(e) => {
                                    ; (!!selectedUser?.selectedPatient?.length ||
                                        !!selectedUser?.selectedProvider?.length ||
                                        !!selectedUser?.selectedSpecialty?.length) &&
                                        handleChipDeleteAll(e)
                                }}>
                                Clear All Filters
                            </p>
                        </div>
                        <div className="cstm_accordion_holder">
                            <Accordion expanded={isAccordionExpanded.providerExpanded}>
                                <AccordionSummary
                                    className="summary_holder"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => handleAccordionExpand('providerExpanded')}>
                                    <Typography className="acc_txt">Provider</Typography>
                                    {selectedUser?.selectedProvider?.length > 0 ? (
                                        <Chip
                                            label={selectedUser?.selectedProvider?.length}
                                            onDelete={() => handleChipDelete('selectedProvider')}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionSummary>
                                <AccordionDetails className="details_holder">
                                    <div>
                                        <div className="search_holder">
                                            <FormControl className={classes.widthSearch}>
                                                <FilledInput
                                                    id="filled-adornment-password"
                                                    placeholder="Search..."
                                                    value={searchTextOffilter?.providerSearch}
                                                    onChange={(e) =>
                                                        setSearchTextOffilter((old) => ({
                                                            ...old,
                                                            providerSearch: e.target.value,
                                                        }))
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end">
                                                                <img
                                                                    src={SearchIcon}
                                                                    alt="icon"></img>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        {filterLoadingProvider ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <CircularProgress
                                                    style={{ height: 20, width: 20, marginTop: 5 }}
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                className={classes.scrollable_content}
                                                ref={(ref) =>
                                                    (scrollableContentRefProvider.current = ref)
                                                }>
                                                {providerData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.value,
                                                                                'selectedProvider',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedProvider?.includes(
                                                                            item.value,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="cstm_accordion_holder">
                            <Accordion expanded={isAccordionExpanded.patientExpanded}>
                                <AccordionSummary
                                    className="summary_holder"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => handleAccordionExpand('patientExpanded')}>
                                    <Typography className="acc_txt">Patient</Typography>
                                    {selectedUser?.selectedPatient?.length > 0 ? (
                                        <Chip
                                            label={selectedUser?.selectedPatient?.length}
                                            onDelete={() => handleChipDelete('selectedPatient')}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionSummary>
                                <AccordionDetails className="details_holder">
                                    <div>
                                        <div className="search_holder">
                                            <FormControl className={classes.widthSearch}>
                                                <FilledInput
                                                    id="filled-adornment-password"
                                                    placeholder="Search..."
                                                    value={searchTextOffilter?.patientSearch}
                                                    onChange={(e) =>
                                                        setSearchTextOffilter((old) => ({
                                                            ...old,
                                                            patientSearch: e.target.value,
                                                        }))
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end">
                                                                <img
                                                                    src={SearchIcon}
                                                                    alt="icon"></img>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        {/* { filterLoadingPatient ? 
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <CircularProgress style={{ height: 20, width: 20, marginTop: 5 }}/>
                                            </div>
                                            : */}
                                        <div
                                            className={classes.scrollable_content}
                                            ref={(ref) => (scrollableContentRef.current = ref)}>
                                            {patientData.map((item, index) => {
                                                return (
                                                    <FormGroup key={index}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(event) =>
                                                                        checkboxHandle(
                                                                            event,
                                                                            item?.value,
                                                                            'selectedPatient',
                                                                        )
                                                                    }
                                                                    checked={selectedUser?.selectedPatient?.includes(
                                                                        item.value,
                                                                    )}
                                                                />
                                                            }
                                                            label={item?.label}
                                                        />
                                                    </FormGroup>
                                                )
                                            })}
                                        </div>
                                        {/* } */}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="cstm_accordion_holder">
                            <Accordion expanded={isAccordionExpanded.specialtyExpanded}>
                                <AccordionSummary
                                    className="summary_holder"
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    onClick={() => handleAccordionExpand('specialtyExpanded')}>
                                    <Typography className="acc_txt">Specialty</Typography>
                                    {selectedUser?.selectedSpecialty?.length > 0 ? (
                                        <Chip
                                            label={selectedUser?.selectedSpecialty?.length}
                                            onDelete={() => handleChipDelete('selectedSpecialty')}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </AccordionSummary>
                                <AccordionDetails className="details_holder">
                                    <div>
                                        <div className="search_holder">
                                            <FormControl className={classes.widthSearch}>
                                                <FilledInput
                                                    id="filled-adornment-password"
                                                    value={searchTextOffilter?.specialtySearch}
                                                    placeholder="Search..."
                                                    onChange={(e) =>
                                                        filterDataSpeciality(e.target.value)
                                                    }
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton edge="end">
                                                                <img
                                                                    src={SearchIcon}
                                                                    alt="icon"></img>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        {filterLoadingSpeciality ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>
                                                <CircularProgress
                                                    style={{ height: 20, width: 20, marginTop: 5 }}
                                                />
                                            </div>
                                        ) : (
                                            <div className={classes.scrollable_content}>
                                                {specialtyData.map((item, index) => {
                                                    return (
                                                        <FormGroup key={index}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(event) =>
                                                                            checkboxHandle(
                                                                                event,
                                                                                item?.value,
                                                                                'selectedSpecialty',
                                                                            )
                                                                        }
                                                                        checked={selectedUser?.selectedSpecialty.includes(
                                                                            item.value,
                                                                        )}
                                                                    />
                                                                }
                                                                label={item?.label}
                                                            />
                                                        </FormGroup>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                </Popover>
            </Box>
        </>
    )
}

export default EncounterQueue
