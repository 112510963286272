import React, { useEffect } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import TextWithMacro from '../reusable/TextWithMacro'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import { selfHarmHistoryViolenceBehaviorPatient } from 'services/Patient/patient'

const useStyles = makeStyles({
    container: {
        minWidth: '30%',
    },
    sectionHeading: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 14,
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 41,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})



const ViolenceHistory = ({ fieldName, master, values, onChange, macros, patientId, nonEditable=false }) => {
    const classes = useStyles()
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable},
        { label: 'No', value: 'No', disable: nonEditable },
    ]

    const handleCheckSelfHarmHistory = (value) => {
        try {
            if (patientId) {
                selfHarmHistoryViolenceBehaviorPatient(patientId, { is_violence_behavior: value })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['have_violent_behavior']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.have_violent_behavior`}
                        options={radioOptions}
                        value={values['have_violent_behavior'] ? 'Yes' : 'No'}
                        onChange={(e) => {
                            const { name, value } = e.target
                            handleCheckSelfHarmHistory(value === 'Yes')
                            onChange(name, value === 'Yes')
                            if(value !== 'Yes'){
                                onChange(`${fieldName}.violent_behavior_desc`, '')
                            }
                            // onChange(`${fieldName}.narrative`, '')
                        }}
                    />
                </Box>
            </Box>
            {values['have_violent_behavior'] && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                    }}>
                    <Typography className={classes.sectionHeading}>
                        {questions['violent_behavior_desc']}
                    </Typography>
                    <TextWithMacro
                        withoutMacro={false}
                        name={`${fieldName}.violent_behavior_desc`}
                        onChange={onChange}
                        macros={macros}
                        values={values.violent_behavior_desc}
                        isDisabled={nonEditable}
                    />
                </Box>
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default ViolenceHistory
