import React from 'react'
import ClinicalNotesList from '../../components/ClinicalNotes/ClinicalNotesList'
import ClinicalNotesListPatient from '../../components/ClinicalNotes/ClinicalNotesListPatient'
import { UserRoles } from '../../constants/user-roles'
import { getLoggedInUserRole } from '../../utilities'

const ClinicalNotesListWrapper = (props) => {
    const { checkprovider } = props

    const [searchText, setSearchText] = React.useState('')
    const userRole = getLoggedInUserRole()
    const onTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const isDisabled = [
        UserRoles.TM2U_SUPER_ADMIN,
        UserRoles.TM2U_ADMIN,
        UserRoles.CLINIC_CORDINATION,
        UserRoles.PATIENT,
    ].includes(userRole)
    return (
        <div>
            {/* <div style={{ marginBottom: 20 }}>
                {isDisabled ? null : (
                    <CardHeader
                        searchText={searchText}
                        onTextChange={onTextChange}
                    />
                )}
            </div> */}
            {isDisabled || checkprovider ? (
                <ClinicalNotesListPatient
                    userId={props.userId}
                    checkprovider={props.checkprovider}
                />
            ) : (
                <ClinicalNotesList checkprovider={props.checkprovider} />
            )}
        </div>
    )
}

export default ClinicalNotesListWrapper
