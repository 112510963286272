





export const convertValueText = (text: string) => {
  const PsychiatricConditions = [
    { value: 'DEPRESSION', label: 'Depression', checked: false },
    { value: 'PANIC_DISORDER', label: 'Panic disorder', checked: false },
    {
        value: 'GENERALIZED_ANXIETY_DISORDER',
        label: 'Generalized anxiety disorder',
        checked: false,
    },
    { value: 'SOCIAL_ANXIETY_DISORDER', label: 'Social anxiety disorder', checked: false },
    {
        value: 'OBSESSIVE_COMPULSIVE_DISORDER',
        label: 'Obsessive compulsive disorder',
        checked: false,
    },
    {
        value: 'POST-TRAUMATIC_STRESS_DISORDER',
        label: 'Post-traumatic stress disorder',
        checked: false,
    },
    { value: 'BIPOLAR_DISORDER', label: 'Bipolar disorder', checked: false },
    { value: 'EATING_DISORDER', label: 'Eating disorder', checked: false },
    { value: 'ALCOHOLISM', label: 'Alcoholism', checked: false },
    { value: 'DRUG_ABUSE', label: 'Drug abuse', checked: false },
    { value: 'ADHD', label: 'ADHD', checked: false },
    { value: 'PERSONALITY_DISORDER', label: 'Personality disorder', checked: false },
    { value: 'SCHIZOPHRENIA/PSYCHOSIS', label: 'Schizophrenia/Psychosis', checked: false },
    { value: 'PSYCHIATRIC_HOSPITALIZATION', label: 'Psychiatric hospitalization', checked: false },
    { value: 'SUICIDE_ATTEMPT', label: 'Suicide attempt', checked: false },
    { value: 'SUICIDE', label: 'Suicide', checked: false },
  ]
  const newText = PsychiatricConditions.find(item=> item.value === text)
  return newText? newText.label : text
}
export const convertValueTextFamilyMembers = (text: string) => {
  const FamilyMembers = [
    // @TODO:
    { key: 'MOTHER', value: 'Mother ' },
    { key: 'FATHER', value: 'Father  ' },
    { key: 'BROTHER', value: 'Brother ' },
    { key: 'SISTER', value: 'Sister ' },
    { key: 'GRANDMOTHER', value: 'Grandmother ' },
    { key: 'GRANDFATHER', value: 'Grandfather ' },
    { key: 'AUNT', value: 'Aunt ' },
    { key: 'UNCLE', value: 'Uncle ' },
    { key: 'COUSIN', value: 'Cousin ' },
    { key: 'SON', value: 'Son  ' },
    { key: 'DAUGHTER', value: 'Daughter ' },
  ]
  const newText = FamilyMembers.find(item=> item.key === text)
  return newText? newText.value : text
}