import {
    Box,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { SelectEl } from '../../../shared/elements'
import _ from 'lodash'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import TooltipTextField from '../../shared/Elements/TooltipTextField'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import RadioOptions from '../reusable/RadioOption'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import { Medications } from 'containers/PatientsSetting/demographic/Medications'
import PsychiatricMedicationHistory from 'containers/PatientsSetting/view/PsychiatricMedicationHistory'
import { useNotification } from 'shared/elements/Notification'
import { apiPsychiatricMedicalHistory } from 'services/Patient/apiPsychiatricMedicalHistory'

const useStyles = makeStyles({
    item_card: {
        width: 400,
        height: 500,
    },
    header: {
        color: '#303E4E',
        fontSize: 14,
        // fontWeight: 500
    },
    table__header: {
        marginRight: 8,
        fontWeight: 500,
        color: '#303E4E',
        whiteSpace: 'break-spaces',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
    },
    table: {
        '& .MuiTableCell-root': {
            // border: '1px solid #B2C0CF',
            border: 'unset',
            width: '14.28%',
            boxSizing: 'border-box',
            padding: 8,
            // '&:last-child': {
            //     width: '6%',
            // },
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
})


const tableHeading = [
    'Medication name',
    'Dose',
    'How long?',
    'End Date',
    'Therapeutic effect',
    'Side Effects',
    'Reason for stopping',
]

const defaultRoleRow = {
    medicationName: '',
    dose: '',
    howLong: '',
    endDate: '',
    therapeuticEffect: '',
    sideEffects: '',
    reason: '',
}

const roleSectionRow = ({ roleOptions, rows = [], handleChange }) => {
    if (rows.length === 0) {
        rows.push({
            medicationName: '',
            dose: '',
            howLong: '',
            endDate: '',
            therapeuticEffect: '',
            sideEffects: '',
            reason: '',
        })
        rows.push({
            medicationName: '',
            dose: '',
            howLong: '',
            endDate: '',
            therapeuticEffect: '',
            sideEffects: '',
            reason: '',
        })
    }
    const updateRows = _.cloneDeep(rows)
    const handleInputChange = (name, value, index) => {
        if (updateRows[index]) {
            const row = updateRows[index] || {}
            row.role = row.role || ''
            row[name] = value
        } else {
            const row = {
                role: '',
                [name]: value,
            }
            updateRows.push(row)
        }
        handleChange([...updateRows])
    }

    const handleAddOrRemove = (action, index) => {
        let updatedRow = {}
        if (action === 'add') {
            updatedRow = [...updateRows]
            updatedRow.push({ ...defaultRoleRow })
        } else {
            updatedRow = updateRows.filter((v, i) => i !== index)
            if (updatedRow.length === 0) {
                updatedRow.push({ ...defaultRoleRow })
            }
        }
        handleChange([...updatedRow])
    }
    return (
        <>
            {updateRows.map((r, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell align="left" component="th" scope="row">
                            {renderTextField({
                                name: 'medicationName',
                                handleInputChange: (name, value) =>
                                    handleInputChange(name, value, index),
                                value: r.medicationName,
                            })}
                        </TableCell>

                        <TableCell align="left" component="th" scope="row">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 5,
                                }}>
                                {renderTextField({
                                    name: 'dose',
                                    value: r.dose,
                                    handleInputChange: (name, value) =>
                                        handleInputChange(name, value, index),
                                })}
                            </Box>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                            {renderTextField({
                                name: 'howLong',
                                handleInputChange: (name, value) =>
                                    handleInputChange(name, value, index),
                                value: r.howLong,
                            })}
                        </TableCell>

                        <TableCell align="left" component="th" scope="row">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 5,
                                }}>
                                {renderTextField({
                                    name: 'endDate',
                                    value: r.endDate,
                                    handleInputChange: (name, value) =>
                                        handleInputChange(name, value, index),
                                })}
                            </Box>
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                            {renderDropDow({
                                options: roleOptions,
                                handleInputChange: (name, value) =>
                                    handleInputChange(name, value, index),
                                name: 'therapeuticEffect',
                                value: r.therapeuticEffect,
                            })}
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                            {renderTextField({
                                name: 'sideEffects',
                                handleInputChange: (name, value) =>
                                    handleInputChange(name, value, index),
                                value: r.sideEffects,
                            })}
                        </TableCell>

                        <TableCell align="left" component="th" scope="row">
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 5,
                                }}>
                                {renderTextField({
                                    name: 'reason',
                                    value: r.reason,
                                    handleInputChange: (name, value) =>
                                        handleInputChange(name, value, index),
                                })}
                                <RenderAddOrRemove
                                    index={index}
                                    rows={updateRows}
                                    handleAddOrRemove={handleAddOrRemove}
                                />
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            })}
            <Grid
                container
                // className={classes.cover__add__new}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}
                onClick={() => handleAddOrRemove('add')}>
                <AddCircleOutlineOutlined
                    style={{
                        color: '#14AE5C',
                        fontSize: 18,
                    }}
                />
                <Typography
                    variant="body1"
                    style={{
                        color: '#14AE5C',
                        marginLeft: 8,
                    }}>
                    Add new
                </Typography>
            </Grid>
        </>
    )
}

const renderDropDow = ({ options, name, handleInputChange, value }) => {
    return (
        <SelectEl
            name={name}
            options={options}
            placeholder="Select One"
            value={value}
            selectProps={{
                style: {
                    height: 41,
                },
            }}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const renderTextField = ({ name, value, handleInputChange }) => {
    return (
        <TooltipTextField
            fullWidth
            variant="outlined"
            name={name}
            size="small"
            value={value}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const RenderAddOrRemove = ({ index, rows, handleAddOrRemove }) => {
    const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
    return (
        <Box sx={{ display: 'flex' }}>
            {rows.length >= 1 && (
                <IconButton
                    variant="outlined"
                    onClick={() => {
                        setAlertDeleteOpen(true)
                    }}
                    // className={classes.show_header_btn}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            <AlertDeleteDialog
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleAddOrRemove('remove', index)
                    setAlertDeleteOpen(false)
                }}
            />
        </Box>
    )
}

const getDropdownOptions = (master = [], key) => {
    return master.map((im) => ({
        value: im[key],
        key: im._id,
    }))
}

const PshychiatricMedicalHistory = ({
    fieldName,
    master,
    values,
    onChange,
    patientId,
    macros,
    encounter_id,
    handleSetViewHealthyRecord,
    nonEditable=false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes',disable: nonEditable },
        { label: 'No', value: 'No', disable: nonEditable },
    ]
    const classes = useStyles()
    const classesCard = usePatientDashboardStyles()
    const notification = useNotification()

    const { therapeuticEffect } = master
    const roleOptions = getDropdownOptions(therapeuticEffect, 'name')

    const handleChange = (updatedValues) => {
        onChange(`${fieldName}.psychiatric_treat_desc`, updatedValues)
    }

    const handleSelectMacro = (name, value) => {
        onChange(name, value)
    }

    const getPsychiatricMedicationList = async () => {
        try {
            const resp = await apiPsychiatricMedicalHistory.getPsychiatricMedication(patientId, {
                page: 1,
                size: 100000,
            })
            if (resp.data.message.data) {
                const list = resp.data.message.data.map((item) => ({
                    ...item,
                    medication: `${item.drug.brandName || ''}, ${item.drug.genericName || ''}, ${
                        item.drug.strength || ''
                    }, ${item.drug.form || ''}`,
                    end_date: item.stop_date && moment(item.stop_date).format('MM/DD/YYYY'),
                }))
                onChange(`${fieldName}.psychiatric_treat_desc`, list)
            }
        } catch (ex) {
            notification('Cannot get list Psychiatric Medication History', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.med_psychiatric_treat) {
            getPsychiatricMedicationList()
        }
    }, [patientId, values.med_psychiatric_treat])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Typography
                    style={{
                        marginBottom: 16,
                        color: '#9CA9B8',
                        fontSize: 12,
                    }}>
                    Instruction: If you are filling this out on behalf of the patient, please answer
                    from the patient's perspective.
                </Typography>
                <Typography className={classes.header}>
                    {questions.med_psychiatric_treat}
                </Typography>
                <RadioOptions
                    name={`${fieldName}.med_psychiatric_treat`}
                    options={radioOptions}
                    value={values['med_psychiatric_treat'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target

                        onChange(name, value === 'Yes')
                        onChange(`${fieldName}.narrative`, '')
                        if (value !== 'Yes') {
                            onChange(`${fieldName}.psychiatric_treat_desc`, [])
                        }
                    }}
                />
            </Box>

            {values['med_psychiatric_treat'] && (
                <PsychiatricMedicationHistory
                    isInClinicalNote
                    patientId={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getPsychiatricMedicationList}
                    disabled={nonEditable}
                />
            )}

            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                values={values}
                onGenerateClick={onChange}
                onChange={handleSelectMacro}
                meta={{
                    values,
                    master: master?.therapeuticEffect,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default PshychiatricMedicalHistory
