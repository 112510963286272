import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Select, Grid, Button } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import RadioOptions from '../reusable/RadioOption'
import CheckboxOption from '../reusable/CheckboxOptions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import { ExpandMore } from '@material-ui/icons'
import { Medications } from 'containers/PatientsSetting/demographic/Medications'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import {
    getListMedications,
    getListMedicationsType,
    getMedicalProblemList,
    setPrimaryCarePhysicianPatient,
} from 'services/Patient/patient'
import { LoadingButton } from '@mui/lab'
import MedicalProblems from 'containers/PatientsSetting/view/MedicalProblems'
import SurgicalHistory from 'containers/PatientsSetting/view/SurgicalHistory'
import MedicationPatient from 'containers/PatientsSetting/view/MedicationPatient'
import { useNotification } from 'shared/elements/Notification'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'
import { stringConcatenation } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        marginLeft: 12,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    itemCard: {
        width: 400,
        height: 500,
    },
    gridContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: '730px',
    },
    gridSurgicalContainer: {
        flexWrap: 'wrap',
        padding: 16,
        maxHeight: 360,
    },
    gridItem: {
        width: '33.333%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    gridItemShow: {
        width: '50%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
    },
    divider: {
        background: '#B0BCE4',
    },
    subText: {
        fontSize: 14,
        color: '#303E4E',
        fontWeight: '500',
    },
    textFieldWrap: {
        width: '100%',
        marginTop: 8,
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
            background: '#FBFCFC',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #E9ECF1 !important',
        },
    },
})



const PastMedicalHistory = ({
    fieldName,
    master,
    values,
    onChange,
    primary_care_physician,
    macros,
    patientId,
    encounter_id,
    handleSetViewHealthyRecord,
    nonEditable=false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable },
        { label: 'No', value: 'No', disable: nonEditable },
    ]
    const classes = useStyles()
    const notification = useNotification()
    const [isLoading, setIsLoading] = useState(false)
    const classesCard = usePatientDashboardStyles()

    const healthProblemMaster = master?.healthProbMasters || []
    const surgicalAreaMaster = master?.surgicalAreaMasters || []

    const handleTextChange = (e) => {
        const { name, value } = e.target
        onChange(name, value)
    }
    const hanldeChangePrimaryCarePatient = (payload) => {
        if (patientId) setPrimaryCarePhysicianPatient(patientId, payload)
    }

    const handleCheckChange = (e) => {
        const { name, value, checked } = e.target
        const updatedValue = values[name]
        if (checked) {
            onChange(`${fieldName}.${name}`, [...updatedValue, value])
        } else {
            onChange(
                `${fieldName}.${name}`,
                updatedValue.filter((id) => id !== value),
            )
        }
    }

    const handleSavePrimaryCare = async () => {
        if (patientId) {
            setIsLoading(true)
            await setPrimaryCarePhysicianPatient(patientId, {
                primary_care_physician: values.primary_care_physician,
            })
            setIsLoading(false)
        }
    }

    const getMedialProblemList = async () => {
        try {
            const resp = await getMedicalProblemList(patientId, 1, 100000)
            const list = resp.data.data.map((item) => {
                return {
                    problem: item.icd_code?.display || '',
                    code: item.icd_code?.code || '',
                    date_diagnosed: formatDateTime(item.time_onset, 'MMM DD, YYYY'),
                    date_changed: formatDateTime(item.time_changed, 'MMM DD, YYYY'),
                    status: item.status?.code || '',
                    note: item.note || '',
                }
            })
            onChange(`${fieldName}.medical_problems`, list)
        } catch (ex) {
            notification(`Cannot get list problem!`, 'error')
        }
    }

    const getSurgicalHistory = async () => {
        try {
            const resp = await surgicalHistoryApis.getAll(patientId, 1, 100000)
            const list = resp.data.map((item) => {
                return {
                    procedure: (() => {
                        return item.procedure
                            .replaceAll('_', ' ')
                            .toLowerCase()
                            .replace(/\b\w/g, function (txt) {
                                return txt.charAt(0).toUpperCase() + txt.substr(1)
                            })
                    })(),
                    date: formatDateTime(item.date, 'MM/DD/YYYY'),
                }
            })
            onChange(`${fieldName}.surgical_history`, list)
        } catch (ex) {
            notification('Cannot get list surgical', 'error')
        }
    }

    const getListMedication = async () => {
        try {
            const resp = await getListMedications(patientId, 1, 100000)
            const list = resp.data.data.map((item) => {
                return {
                    drug_name: stringConcatenation(
                        item.drug.brandName,
                        item.drug.genericName,
                        item.drug.strength,
                        item.drug.form,
                    ),
                    strength: item.strength || '',
                    provider_name: `${item.provider_first_name} ${item.provider_last_name}`,
                    days_supply: item.day_supply?.display || '',
                    quantity: item.quantity || '',
                    refills: item.refills || '',
                }
            })
            onChange(`${fieldName}.medication_list`, list)
        } catch (e) {
            notification('Cannot get list medication', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.taking_med_currently) {
            getListMedication()
        }
    }, [patientId, values.taking_med_currently])

    useEffect(() => {
        if (patientId && values.has_medical_problem) {
            getMedialProblemList()
        }
    }, [patientId, values.has_medical_problem])

    useEffect(() => {
        if (patientId && values.has_surgical_history) {
            getSurgicalHistory()
        }
    }, [patientId, values.has_surgical_history])

    useEffect(() => {
        if (primary_care_physician)
            onChange(`${fieldName}.primary_care_physician`, primary_care_physician)
    }, [primary_care_physician])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.subText}>
                    {questions.primary_care_physician}
                </Typography>

                <Grid container wrap="nowrap" alignItems="center">
                    <TextField
                        // placeholder="Place holder"
                        name={`${fieldName}.primary_care_physician`}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled={nonEditable}
                        value={values.primary_care_physician}
                        onChange={(e) => {
                            handleTextChange(e)
                            // hanldeChangePrimaryCarePatient({ primary_care_physician: e?.target?.value })
                        }}
                    />
                    <LoadingButton
                        loading={isLoading}
                        onClick={() => {
                            handleSavePrimaryCare()
                        }}
                        disabled={nonEditable}
                        className={classes.btn__generate}
                        variant="contained">
                        Save
                    </LoadingButton>
                </Grid>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.subText}>
                    {questions.taking_med_currently}
                </Typography>
                <RadioOptions
                    name={`${fieldName}.taking_med_currently`}
                    options={radioOptions}
                    value={values['taking_med_currently'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target

                        onChange(name, value === 'Yes')
                        if (value === 'No') {
                            onChange(`${fieldName}.medication_details`, '')
                            onChange(`${fieldName}.medication_list`, [])
                        }
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                {/* <Typography className={classes.subText}>
                    {questions.medication_details}
                </Typography>
                <TextField
                    disabled={
                        !values['taking_med_currently']
                    }
                    placeholder="Place holder"
                    name={`${fieldName}.medication_details`}
                    variant="outlined"
                    fullWidth
                    size="small"
                    onChange={handleTextChange}
                    value={values.medication_details}
                /> */}
                {values['taking_med_currently'] && (
                    <MedicationPatient
                        isInClinicalNote
                        patient_Id={patientId}
                        encounter_id={encounter_id}
                        afterActionOk={getListMedication}
                        nonEditable={nonEditable}
                    />
                )}
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.subText}>{questions.has_medical_problem}</Typography>
                <RadioOptions
                    name={`${fieldName}.has_medical_problem`}
                    options={radioOptions}
                    value={values['has_medical_problem'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target

                        onChange(name, value === 'Yes')
                        if (value === 'No') {
                            onChange(`${fieldName}.medical_problems`, [])
                        }
                    }}
                />
            </Box>

            {values['has_medical_problem'] && (
                <Box>
                    <MedicalProblems
                        isInClinicalNote
                        patient_Id={patientId}
                        encounter_id={encounter_id}
                        isAddingFromClinicalNote
                        afterActionOk={getMedialProblemList}
                        nonEditable={nonEditable}
                    />
                </Box>
            )}

            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.subText}>
                    {questions.has_surgical_history}
                </Typography>
                <RadioOptions
                    name={`${fieldName}.has_surgical_history`}
                    options={radioOptions}
                    value={values['has_surgical_history'] ? 'Yes' : 'No'}
                    onChange={(e) => {
                        const { name, value } = e.target

                        onChange(name, value === 'Yes')
                        if (value === 'No') {
                            onChange(`${fieldName}.surgical_history`, [])
                        }
                    }}
                />
            </Box>

            {values['has_surgical_history'] && (
                <Box style={{ marginTop: 12 }}>
                    <SurgicalHistory
                        isInClinicalNote
                        patient_Id={patientId}
                        encounter_id={encounter_id}
                        isAddingFromClinicalNote
                        afterActionOk={getSurgicalHistory}
                        nonEditable={nonEditable}
                    />
                </Box>
            )}
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: {
                        healthProblemMaster,
                        surgicalAreaMaster,
                    },
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default PastMedicalHistory
