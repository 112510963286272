import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, Typography, Select, TextField, Box } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'

const useStyles = makeStyles({
    container: {
        width: '100%',
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        height: 22,
        color: '#111111',
        fontSize: 18,
        letterSpacing: 0,
    },
    textFieldWrap: {
        border: '1px solid #E9ECF1',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 8,
        marginTop: 10,
        color: '#667689',
        '& .MuiFilledInput-root': {
            background: '#FBFCFC',
        },
    },
    customHeadingText: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
        '& .MuiInputBase-input': {
            fontSize: 18,
        },
    },
    titleGrid: {
        marginBottom: 6,
    },
    menuItem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

    containerText: {
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
        },
    },
    btn__macro: {
        color: '#667689',
        width: "289px",
    },
    CentralizedMacrosText:{
        color: "#667689",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
        margin: "10px 7px 7px 7px"
    }
})

const TextWithMacro = ({
    withoutMacro = true,
    // macros,
    heading,
    name,
    fieldName,
    initialValue,
    values,
    onChange,
    isDisabled,
    rows,
    customHeading,
    placeholder,
    textCharecterlimit = true
}) => {
    const classes = useStyles()
    const [selectedMacro, setSelectedMacro] = useState('')
    const dispatch = useDispatch()
    const macrosData = useSelector((state) => state.macros.macroList || [])
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    const handleTextChange = (fieldName, text) => {
        onChange(fieldName, text)
    }
    const handleSelectMacro = (value) => {
        let newValue = value
        if (values === undefined) {
            newValue = value
        } else {
            newValue = values.concat(` ${value}`)
        }
        onChange(name, newValue)
    }

    return (
        <Box className={classes.container}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.titleGrid}>
                {heading && <Typography className={classes.labelText}>{heading}</Typography>}
                {customHeading && (
                    <Grid item md={6} xs={12}>
                        <TextField
                            className={classes.customHeadingText}
                            name={customHeading.name}
                            // variant="outlined"
                            // variant="standard"
                            size="small"
                            value={customHeading.value}
                            disabled={isDisabled}
                            onChange={(e) => {
                                const { name, value } = e.target
                                handleTextChange(name, value)
                            }}
                            InputProps={{
                                classes: classes.root,
                            }}
                            inputProps={{
                                maxLength: 250,
                                placeholder: 'Add title',
                            }}
                        />
                    </Grid>
                )}
                {!withoutMacro && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid></Grid>
                        <Grid item>
                            <Box
                                sx={{
                                    '& .MuiSelect-selectMenu': {
                                        background: '#F3F6F9',
                                        padding: '7px 30px 7px 16px',
                                        borderRadius: '6px',
                                    },
                                    '& .MuiInput-root': {
                                        marginRight: '8px',
                                    },
                                }}>
                                <Select
                                    disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: 505,
                                                maxWidth: 300,
                                                marginTop: 8,
                                            },
                                        },
                                    }}
                                    IconComponent={ExpandMore}
                                    name="macro"
                                    onChange={(e) => {
                                        setSelectedMacro(e.target.value)
                                    }}
                                    value={selectedMacro}
                                    displayEmpty
                                    disabled={isDisabled}
                                    renderValue={(sel) =>
                                        sel ? (
                                            <span className={classes.btn__macro}>
                                                Insert Macro:{' '}
                                                {allMacros.find((item) => item.name === sel)?.name}
                                            </span>
                                        ) : (
                                            <span className={classes.btn__macro}>Insert Macro</span>
                                        )
                                    }>
                                    <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                                        {macrosPractitionerData?.map((m) => (
                                            <MenuItem
                                                onClick={() => {handleSelectMacro(m.description)}}
                                                className={classes.menu__item}
                                                key={m.id}
                                                value={m.name}
                                                >
                                                <Typography className={classes.btn__macro}>
                                                    {m.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                                    {macrosData?.map((m) => (
                                        <MenuItem
                                            onClick={() => handleSelectMacro(m.description)}
                                            className={classes.menu__item}
                                            key={m.id}
                                            value={m.name}>
                                            <Typography className={classes.btn__macro}>
                                                {m.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <div className={classes.containerText}>
                <TextFieldDebounce
                    className={classes.textFieldWrap}
                    placeholder={placeholder}
                    name={name}
                    variant="filled"
                    size="small"
                    multiline
                    minRows={rows || 5}
                    value={values}
                    disabled={isDisabled}
                    onChange={(e) => {
                        const { name, value } = e.target
                        handleTextChange(name, value)
                    }}
                    InputProps={{
                        classes: classes.root,
                        disableUnderline: true,
                    }}
                    inputProps={{
                        // maxLength: textCharecterlimit ? "5000 ": ""
                        // maxLength: 5000,
                    }}
                    // InputProps={{ disableUnderline: true }}
                />
            </div>
        </Box>
    )
}

export default React.memo(TextWithMacro)
