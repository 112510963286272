import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import { getSSOLink } from '../../services/ERX'
import { InaccessibleConfirm } from './InaccessibleConfirm'

const ERX = () => {
    const [ssoLink, setSsoLink] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [alertConfirm, setAlertConfirm] =
        React.useState(false)

    const handleConfirm = () => {
        history.push('/dashboard')
        setAlertConfirm(false)
    }

    useEffect(() => {
        setLoading(true)
        getSSOLink()
            .then((res) => {
                if (res?.data?.ssoLink) {
                    setSsoLink(res?.data?.ssoLink)
                } else {
                    setAlertConfirm(true)
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log('err', err)
                setLoading(false)
                setAlertConfirm(true)
            })
    }, [])

    return (
        <Grid
            container
            style={{
                width: '100%',
                height: 'calc(100vh - 74px)',
                position: 'relative',
            }}>
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1,
                    }}>
                    <CircularProgress />
                </Box>
            ) : (
                ssoLink && (
                    <iframe
                        src={ssoLink}
                        title="description"
                        style={{
                            width: '100%',
                            height: '100%',
                        }}></iframe>
                )
            )}
            {alertConfirm && (
                <InaccessibleConfirm
                    onOk={handleConfirm}
                    open={alertConfirm}
                    onClose={() => setAlertConfirm(false)}
                />
            )}
        </Grid>
    )
}

export default ERX
