import React, { useEffect } from 'react'
import { Typography } from '@mui/material'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { ReactComponent as RadioCheckIcon } from 'assests/icons/RadioCheck.svg'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
    radioButton: {
        '& .MuiSvgIcon-root': {
            fontSize: '20px !important'
        }
    }
}))


export function CustomStatusChangeDialogBody (props) {
    const onAppointmentCancelOrNoShow =
        props?.statusObject?.nativeName === 'cancelled' ||
        props?.statusObject?.nativeName === 'noshow'

    const isChartRecordPresent = Boolean(props?.chart_status && (props?.chart_status !== ""))
    const isClinicalNotePresent = Boolean(props?.clinical_notes_id && (props?.clinical_notes_id !== ""))

    useEffect(()=>{
        props?.statusObject?.nativeName==="noshow"  && props?.handleCheckRecallList();
    },[]);

    return (

        <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <span className='title' style={{ marginBottom: '10px' }}>
                <Typography color={'#303E4E'}>Setting this appointment to '{props?.statusObject?.displayName}' will:</Typography>
            </span>
            <span style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>

                { onAppointmentCancelOrNoShow ?

                    <>
                        <CheckboxOption
                            disabled={true}
                            option={{
                                label: `Delete clinical note`,
                                value: false,
                            }}
                            defaultChecked={(props?.clinical_notes_id === "" || !props?.clinical_notes_id) ? false : true}
                        />

                        <CheckboxOption
                            disabled={true}
                            option={{
                                label: `Delete chart prep record`,
                                value: false,
                            }}
                            defaultChecked={(props?.chart_status === "" || !props?.chart_status) ? false : true}
                        />

                        <CheckboxOption
                            option={{
                                label: "Add patient to Waitlist",
                                value: false,
                            }}
                            onChange={() => props?.handleCheckWaitlist()}
                            checked={props?.isWaitlist}
                            disabled={props?.isRecallList}
                        />

                        <CheckboxOption
                            option={{
                                label: "Add Patient to Recall list",
                                value: false,
                            }}
                            checked={props?.isRecallList}
                            disabled={props?.isWaitlist || props?.statusObject?.nativeName==="noshow"}
                        />

                    </>

                    :

                    <>
                        <CheckboxOption
                            // defaultChecked={true}
                            disabled={isClinicalNotePresent}
                            option={{
                                label: `Create clinical note`,
                                value: false,
                            }}
                            checked={isClinicalNotePresent? false : props?.isCreateNote}
                            onChange={() => props?.handleCheckCreateNote()}
                        />

                        <CheckboxOption
                            // defaultChecked={true}
                            disabled={isChartRecordPresent}
                            option={{
                                label: `Create chart prep record`,
                                value: false,
                            }}
                            checked={isChartRecordPresent ? false : props?.isChartRecord}
                            onChange={() => props?.handleCheckChartRecord()}

                        />
                    </>

                }

            </span>
        </span>

    )
}

export function ConfirmReasonDialogBody (props) {
    const classes = useStyles()

    const statusTerm = React.useMemo(
        () => props?.status === 'cancelled' ? 'Cancellation' : 'No Show',
        [ props?.status ]
    )

    const statusReasons = React.useMemo(() => props?.status === 'cancelled' ? [
        'Scheduling conflict',
        'Cost issue',
        'Technical issue',
        'Discontinue care'
    ] : [
        'Patient forgot',
        'Miscommunication',
        'Emergency',
        'Technical issue'
    ], [
        props?.status
    ])



    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 10 }}>
            <Typography>
                Select a reason for appointment '{statusTerm}':
            </Typography>

            <RadioGroup
                onChange={(e) => props?.setCurrentReason(e?.target?.value)}
                value={props?.currentReason}>
                {statusReasons.map((radioItem) => (
                    <FormControlLabel
                        key={radioItem}
                        value={radioItem}
                        control={
                            <Radio
                                checkedIcon={<RadioCheckIcon style={{ height: 21, width: 21 }} />}
                                className={classes.radioButton}
                            />
                        }
                        label={radioItem}
                        checked={props?.currentReason === radioItem}
                    />
                ))}
            </RadioGroup>
        </div>
    )
}