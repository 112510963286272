import { Box, Grid, Typography } from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import Divider from '@mui/material/Divider'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import { OPTIONS_TIMEZONES, StateTimeZone } from 'constants/states'
import { stateList } from 'containers/LocationSettings/LocationCreateDialog/constant'
import React, { forwardRef } from 'react'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { PhoneNumberInput, ZipCode } from 'shared/elements/FormItem/Input'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import TrashIcon from '../../../assests/icons/trash.svg'
import { RELATIONSHIP_TYPE } from '../view/PatientsContactsView/schema'
import { SearchOccupation } from './SearchOccupation'
import { SearchOccupationIndustry } from './SearchOccupationIndustry'
import { SmokingStatus } from './SmokingStatus'
import * as C from './schema'
import './styles.scss'
const zipState = require('zip-state')

export const Demographics = forwardRef((props, ref) => {
    const {
        formik,
        handleAddItemEmergencyContact,
        setEmergengyLine,
        handleClickDeleteIcon,
        anchorEl,
        setAnchorEl,
        setSelectedItemIndex,
        handleDeleteServiceLine,
    } = props

    const smokingStatusTab = React.useRef(null)

    const toShowTimeZone = (value) =>{
        const region = () => {
            if (value.length === 5) {
                return zipState(value);
            }
            return null;
        }
        const regionValue = region()
        if (regionValue) {
            const timezone = StateTimeZone[regionValue]?.default;
            const isValuePresent = OPTIONS_TIMEZONES.some(zone => zone.key === timezone);
            if (isValuePresent) {
                formik.setFieldValue('timezone', timezone);
            }
        }
    }
    return (
        <div ref={ref}>
            <Grid>
                {/* <Grid container item> */}
                <Grid
                    // container
                    // item
                    // spacing={3}
                    // className="modal-spacer"
                    style={{ marginBottom: '1rem' }}>
                    <fieldset className="divider-information">
                        <legend>
                            <Typography className={'heading1-title'}>Demographics</Typography>
                        </legend>
                        <Grid item spacing={2} container style={{ marginBottom: '1rem' }}>
                            <Grid item xs={3}>
                                <DatePicker
                                    label="Date of Birth"
                                    required
                                    name="dob"
                                    value={formik.values.dob}
                                    maxDate={new Date()}
                                    error={formik.touched.dob && Boolean(formik.errors.dob)}
                                    helperText={formik.touched.dob && formik.errors.dob}
                                    onChange={(value) => {
                                        formik.setFieldValue('dob', value)
                                    }}
                                    notDefaultValue
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Select
                                    label="Sex"
                                    required
                                    name="gender"
                                    placeholder="Select gender"
                                    value={formik.values.gender}
                                    error={formik.touched.gender && Boolean(formik.errors.gender)}
                                    helperText={formik.touched.gender && formik.errors.gender}
                                    options={C.SEX_AT_BIRTH.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) => formik.setFieldValue('gender', value)}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Select
                                    label="Gender identity"
                                    required
                                    name="gender_identity"
                                    placeholder="Select gender identity"
                                    value={formik.values.gender_identity?.code}
                                    error={
                                        formik.touched.gender_identity?.code &&
                                        Boolean(formik.errors.gender_identity?.code)
                                    }
                                    helperText={
                                        formik.touched.gender_identity?.code &&
                                        formik.errors.gender_identity?.code
                                    }
                                    options={C.GENDER_IDENTITY.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('gender_identity', {
                                            code: value,
                                            display: '',
                                        })
                                    }
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Select
                                    label="Sexual Orientation"
                                    name="sexual_orientation"
                                    placeholder="Select sexual orientation"
                                    value={formik.values.sexual_orientation?.code || ''}
                                    options={C.SEXUAL_ORIENTATION.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('sexual_orientation', {
                                            code: value,
                                            display: '',
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid item xs={3}>
                                <Select
                                    label="Race"
                                    name="race"
                                    placeholder="Select race"
                                    value={formik.values.race?.code || ''}
                                    options={C.RACE.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('race', {
                                            code: value,
                                            display: C.RACE.find((el) => el.code === value)?.text,
                                        })
                                    }
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Select
                                    label="Ethnicity"
                                    name="ethnicity"
                                    placeholder="Select ethnicity"
                                    value={formik.values.ethnicity?.code || ''}
                                    options={C.ETHNICITY.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('ethnicity', {
                                            code: value,
                                            display: C.ETHNICITY.find((el) => el.code === value)
                                                ?.text,
                                        })
                                    }
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Select
                                    label="Preferred Language"
                                    name="language"
                                    placeholder="Select preferred language"
                                    value={formik.values.language?.code || ''}
                                    options={C.PREFFERED_LANGUAGE_FULL.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) => {
                                        formik.setFieldValue('language', {
                                            code: value,
                                            display: C.PREFFERED_LANGUAGE_FULL.find(
                                                (el) => el.code === value,
                                            )?.text,
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <SmokingStatus formik={formik} ref={smokingStatusTab} />
                            </Grid>
                        </Grid>
                        <Grid container item alignItems="flex-start" spacing={2}>
                            <Grid item xs={3}>
                                <Select
                                    label="Primary Language"
                                    name="primary_language"
                                    required
                                    error={
                                        formik.touched.primary_language?.code &&
                                        Boolean(formik.errors.primary_language?.code)
                                    }
                                    helperText={
                                        formik.touched.primary_language?.code &&
                                        formik.errors.primary_language?.code
                                    }
                                    placeholder="Select primary language"
                                    value={formik.values.primary_language?.code || ''}
                                    options={C.PRIMARY_LANGUAGE.map((item) => ({
                                        key: item.code,
                                        value: `${item.text}`,
                                    }))}
                                    onChange={(value) => {
                                        formik.setFieldValue('primary_language', {
                                            code: value,
                                            display: C.PRIMARY_LANGUAGE.find(
                                                (el) => el.code === value,
                                            )?.text,
                                        })
                                    }}
                                />
                            </Grid>

                            {formik.values.primary_language?.code === 'ot' && (
                                <Grid item xs={3}>
                                     <TextBox
                                        label=""
                                        placeholder="Please specify where"
                                        style={{
                                            marginTop: "32px",
                                        }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                // default state
                                                '& fieldset': {
                                                    borderColor: '#5571C6', // default border color
                                                },
                                                // hover state
                                                '&:hover fieldset': {
                                                    borderColor: '#5571C6', // hide border on hover
                                                },
                                                // focused state
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#5571C6', // border color when focused
                                                }
                                            }
                                        }}
                                        variant='outlined'
                                        required={formik.values.primary_language?.code === 'ot'}
                                        name="other_language"
                                        value={formik.values.other_language}
                                        inputProps={{ maxLength: 100 }}
                                        error={
                                            formik.touched.other_language &&
                                            Boolean(formik.errors.other_language)
                                        }
                                        helperText={
                                            formik.touched.other_language &&
                                            formik.errors.other_language
                                        }
                                        onChange={(value) =>
                                            formik.setFieldValue('other_language', value)
                                        }
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                    <Grid item style={{ marginBottom: '1rem' }}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography className={'heading1-title'}>Address</Typography>
                            </legend>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Address line 1"
                                    placeholder="Enter address"
                                    required
                                    name="address1"
                                    value={formik.values.address1}
                                    inputProps={{ maxLength: 100 }}
                                    error={
                                        formik.touched.address1 && Boolean(formik.errors.address1)
                                    }
                                    helperText={formik.touched.address1 && formik.errors.address1}
                                    onChange={(value) => formik.setFieldValue('address1', value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="Address line 2"
                                    placeholder="Enter address"
                                    name="address2"
                                    value={formik.values.address2}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={(value) => formik.setFieldValue('address2', value)}
                                />
                            </Grid>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid item xs={3}>
                                    <TextBox
                                        label="City"
                                        placeholder="Enter city"
                                        required
                                        name="city"
                                        value={formik.values.city}
                                        inputProps={{ maxLength: 100 }}
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={formik.touched.city && formik.errors.city}
                                        onChange={(value) => formik.setFieldValue('city', value)}
                                    />
                                </Grid>

                                <Grid xs={3} item>
                                    <Select
                                        label="State"
                                        required
                                        name="state"
                                        placeholder="Select state"
                                        value={formik.values.state}
                                        error={formik.touched.state && Boolean(formik.errors.state)}
                                        helperText={formik.touched.state && formik.errors.state}
                                        options={stateList.map((item) => ({
                                            key: item.state,
                                            value: item.stateLabel,
                                        }))}
                                        onChange={(value) => formik.setFieldValue('state', value)}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <ZipCode
                                        name="zip Code"
                                        required
                                        value={formik.values.zip}
                                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                                        helperText={formik.touched.zip && formik.errors.zip}
                                        onChange={(value) => {
                                            toShowTimeZone(value)
                                            formik.setFieldValue('zip', value)
                                            if( value.length === 0 ){
                                                formik.setFieldValue('timezone', "");
                                            }
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Select
                                        label="Time Zone"
                                        name="timezone"
                                        required
                                        placeholder="Select time zone"
                                        value={formik.values.timezone}
                                        options={OPTIONS_TIMEZONES.map((item) => ({
                                            key: item.key,
                                            value: item.label,
                                        }))}
                                        onChange={(value) =>
                                            formik.setFieldValue('timezone', value)
                                        }
                                        error={
                                            formik.touched.timezone &&
                                            Boolean(formik.errors.timezone)
                                        }
                                        helperText={
                                            formik.touched.timezone && formik.errors.timezone
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>

                    <Grid item style={{ marginBottom: '1rem' }}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography className={'heading1-title'}>
                                    Emergency Contact
                                </Typography>
                            </legend>
                            {formik.values?.emergency_contacts?.length > 0 &&
                                formik.values?.emergency_contacts.map((contact, rowIndex) => {
                                    return (
                                        <div key={rowIndex} className="modifier_top" style={{display:'flex', alignItems:'flex-start'}}>
                                            <div
                                                style={{
                                                    width: '20%',
                                                }}>
                                                <TextBox
                                                    // required
                                                    label="Full Name"
                                                    placeholder="Enter full name"
                                                    name="related_person_name"
                                                    value={contact?.related_person_name}
                                                    onChange={(value) =>
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'related_person_name',
                                                            value,
                                                        )
                                                    }
                                                    // error={
                                                    //     formik.touched.emergency_contacts &&
                                                    //     formik.touched.emergency_contacts?.length >
                                                    //         0 &&
                                                    //     formik.touched.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     formik.touched.emergency_contacts[rowIndex]
                                                    //         ?.related_person_name &&
                                                    //     formik.errors.emergency_contacts &&
                                                    //     formik.errors.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     Boolean(
                                                    //         formik.errors.emergency_contacts[
                                                    //             rowIndex
                                                    //         ]?.related_person_name,
                                                    //     )
                                                    // }
                                                    // helperText={
                                                    //     formik.touched.emergency_contacts &&
                                                    //     formik.touched.emergency_contacts?.length >
                                                    //         0 &&
                                                    //     formik.errors.emergency_contacts &&
                                                    //     formik.errors.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     formik.errors.emergency_contacts[rowIndex]
                                                    //         ?.related_person_name
                                                    // }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: '15%',
                                                }}>
                                                <Select
                                                    // required
                                                    label="Relationship"
                                                    name="relation"
                                                    placeholder="Select relationship"
                                                    value={contact?.relation}
                                                    options={RELATIONSHIP_TYPE}
                                                    onChange={(value) => {
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'relation',
                                                            value,
                                                        )
                                                    }}
                                                    // error={
                                                    //     formik.touched.emergency_contacts &&
                                                    //     formik.touched.emergency_contacts?.length >
                                                    //         0 &&
                                                    //     formik.touched.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     formik.touched.emergency_contacts[rowIndex]
                                                    //         ?.relation &&
                                                    //     formik.errors.emergency_contacts &&
                                                    //     formik.errors.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     Boolean(
                                                    //         formik.errors.emergency_contacts[
                                                    //             rowIndex
                                                    //         ]?.relation,
                                                    //     )
                                                    // }
                                                    // helperText={
                                                    //     formik.touched.emergency_contacts &&
                                                    //     formik.touched.emergency_contacts?.length >
                                                    //         0 &&
                                                    //     formik.errors.emergency_contacts &&
                                                    //     formik.errors.emergency_contacts[
                                                    //         rowIndex
                                                    //     ] &&
                                                    //     formik.errors.emergency_contacts[rowIndex]
                                                    //         ?.relation
                                                    // }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: '15%',
                                                }}>
                                                <PhoneNumberInput
                                                    // required
                                                    label="Phone"
                                                    name="related_person_phone"
                                                    value={contact?.related_person_phone}
                                                    onChange={(value) =>
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'related_person_phone',
                                                            value,
                                                        )
                                                    }
                                                    error={
                                                        formik.touched.emergency_contacts &&
                                                        formik.touched.emergency_contacts?.length >
                                                            0 &&
                                                        formik.touched.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        formik.touched.emergency_contacts[rowIndex]
                                                            ?.related_person_phone &&
                                                        formik.errors.emergency_contacts &&
                                                        formik.errors.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        Boolean(
                                                            formik.errors.emergency_contacts[
                                                                rowIndex
                                                            ]?.related_person_phone,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched.emergency_contacts &&
                                                        formik.touched.emergency_contacts?.length >
                                                            0 &&
                                                        formik.errors.emergency_contacts &&
                                                        formik.errors.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        formik.errors.emergency_contacts[rowIndex]
                                                            ?.related_person_phone
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    width: '20%',
                                                }}>
                                                <TextBox
                                                    label="Email"
                                                    name="related_person_email"
                                                    placeholder="Enter email"
                                                    value={contact?.related_person_email}
                                                    onChange={(value) =>
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'related_person_email',
                                                            value,
                                                        )
                                                    }
                                                    error={
                                                        formik.touched.emergency_contacts &&
                                                        contact?.related_person_email &&
                                                        formik.touched.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        formik.touched.emergency_contacts[rowIndex]
                                                            ?.related_person_email &&
                                                        formik.errors.emergency_contacts &&
                                                        formik.errors.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        Boolean(
                                                            formik.errors.emergency_contacts[
                                                                rowIndex
                                                            ]?.related_person_email,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched.emergency_contacts &&
                                                        contact?.related_person_email &&
                                                        formik.touched.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        formik.touched.emergency_contacts[rowIndex]
                                                            ?.related_person_email &&
                                                        formik.errors.emergency_contacts &&
                                                        formik.errors.emergency_contacts[
                                                            rowIndex
                                                        ] &&
                                                        formik.errors.emergency_contacts[rowIndex]
                                                            ?.related_person_email
                                                    }
                                                />
                                            </div>
                                            {/* <div
                                                style={{
                                                    width: '15%',
                                                    marginTop: '30px',
                                                }}>
                                                <CheckboxOption
                                                    option={{
                                                        label: 'Is Emergnecy Contact ?',
                                                        value: contact?.is_emergency_contact,
                                                    }}
                                                    onChange={(e) => {
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'is_emergency_contact',
                                                            e.target.checked,
                                                        )
                                                    }}
                                                    checked={contact?.is_emergency_contact}
                                                />
                                            </div> */}
                                            <div
                                                style={{
                                                    width: '20%',
                                                    marginTop: '25px',
                                                }}>
                                                <CheckboxOption
                                                    option={{
                                                        label: 'Appointment Notifications ?',
                                                        value: contact?.appointment_notification,
                                                    }}
                                                    onChange={(e) => {
                                                        setEmergengyLine(
                                                            rowIndex,
                                                            'appointment_notification',
                                                            e.target.checked ? '1' : '0',
                                                        )
                                                    }}
                                                    checked={
                                                        contact?.appointment_notification === '1'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="icon_position"
                                                style={{
                                                    width: '3%',
                                                }}>
                                                <Box display="flex">
                                                    <Divider
                                                        orientation="vertical"
                                                        variant="middle"
                                                        // className="divider_height"
                                                        flexItem
                                                    />
                                                    <div
                                                        className="delete_icon"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={(event) => {
                                                            handleClickDeleteIcon(event, rowIndex)
                                                        }}>
                                                        {' '}
                                                        <img src={TrashIcon} />
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    )
                                })}
                            <CustomAlertPopOver
                                anchorEl={anchorEl}
                                handleClose={() => {
                                    setAnchorEl(null)
                                    setSelectedItemIndex(null)
                                }}
                                title="Delete Emergency Contact"
                                bodyText={`Are you sure you want to delete the emergency contact?`}
                                confirmButtonName={'Delete'}
                                confirmButtonColor={'red'}
                                onSuccess={handleDeleteServiceLine}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    whiteSpace: 'nowrap',
                                    marginTop: '5px',
                                }}
                                onClick={(e) => {
                                    handleAddItemEmergencyContact(e)
                                }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}>
                                    <AddCircleOutlineOutlined
                                        style={{
                                            color: '#14AE5C',
                                            fontSize: 18,
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: '#14AE5C',
                                            marginLeft: 8,
                                        }}>
                                        Add Emergency Contact
                                    </Typography>
                                </div>
                            </div>
                        </fieldset>
                    </Grid>
                    <Grid item style={{ marginBottom: '1rem' }}>
                        <fieldset className="divider-information">
                            <legend>
                                <Typography className={'heading1-title'}>Occupation</Typography>
                            </legend>

                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <SearchOccupation
                                        label="Occupation"
                                        name="occupation"
                                        placeholder="Search occupation"
                                        value={formik.values.occupation}
                                        onChange={(value) =>
                                            formik.setFieldValue('occupation', value)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <SearchOccupationIndustry
                                        label="Occupation Industry"
                                        name="occupation_industry"
                                        placeholder="Search occupation industry"
                                        value={formik.values.occupation_industry}
                                        onChange={(value) =>
                                            formik.setFieldValue('occupation_industry', value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
                {/* </Grid> */}
            </Grid>
        </div>
    )
})
