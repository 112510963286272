export const states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'California',
    'Colorado',
    'Delaware',
    'Georgia',
    'Hawaii',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kentucky',
    'Maryland',
    'Michigan',
    'Missouri',
    'New Jersey',
    'New York',
    'Pennsylvania',
    'Tennessee',
    'Virginia',
    'West Virginia',
    'Maharashtra',
    'Kerala',
    'Karnataka',
    'Gujarat',
    'Haryana',
]

export const OPTIONS_TIMEZONES = [
    {
        utc: '-05:00',
        key: 'US/Eastern',
        value: '(UTC-05:00) US/Eastern',
        dst: 'EDT',
        acronym: 'EST',
        label: 'Eastern Time',
    },
    {
        utc: '-06:00',
        key: 'US/Central',
        value: '(UTC-06:00) US/Central',
        dst: 'CDT',
        acronym: 'CST',
        label: 'Central Time',
    },
    {
        utc: '-07:00',
        key: 'US/Mountain',
        value: '(UTC-07:00) US/Mountain',
        dst: 'MDT',
        acronym: 'MST',
        label: 'Mountain Time',
    },
    {
        utc: '-08:00',
        key: 'US/Pacific',
        value: '(UTC-08:00) US/Pacific',
        dst: 'PDT',
        acronym: 'PST',
        label: 'Pacific Time',
    },
    {
        utc: '-09:00',
        key: 'US/Alaska',
        value: '(UTC-09:00) US/Alaska',
        dst: 'AKDT',
        acronym: 'AKST',
        label: 'Alaskan Time',
    },
    {
        utc: '-10:00',
        key: 'US/Hawaii',
        value: '(UTC-10:00) US/Hawaii',
        dst: 'HDT',
        acronym: 'HAST',
        label: 'Hawaiian Time',
    },
]

export const StateTimeZone = {
    "AL": { "default": "US/Central" },
    "AK": { "default": "US/Alaska" },
    "AZ": { "default": "US/Mountain" },
    "AR": { "default": "US/Central" },
    "CA": { "default": "US/Pacific" },
    "CO": { "default": "US/Mountain" },
    "CT": { "default": "US/Eastern" },
    "DE": { "default": "US/Eastern" },
    "FL": { "default": "US/Eastern" },
    "GA": { "default": "US/Eastern" },
    "HI": { "default": "US/Hawaii" },
    "ID": { "default": "US/Mountain" },
    "IL": { "default": "US/Central" },
    "IN": { "default": "US/Eastern" },
    "IA": { "default": "US/Central" },
    "KS": { "default": "US/Central" },
    "KY": { "default": "US/Eastern" },
    "LA": { "default": "US/Central" },
    "ME": { "default": "US/Eastern" },
    "MD": { "default": "US/Eastern" },
    "MA": { "default": "US/Eastern" },
    "MI": { "default": "US/Eastern" },
    "MN": { "default": "US/Central" },
    "MS": { "default": "US/Central" },
    "MO": { "default": "US/Central" },
    "MT": { "default": "US/Mountain" },
    "NE": { "default": "US/Central" },
    "NV": { "default": "US/Pacific" },
    "NH": { "default": "US/Eastern" },
    "NJ": { "default": "US/Eastern" },
    "NM": { "default": "US/Mountain" },
    "NY": { "default": "US/Eastern" },
    "NC": { "default": "US/Eastern" },
    "ND": { "default": "US/Central" },
    "OH": { "default": "US/Eastern" },
    "OK": { "default": "US/Central" },
    "OR": { "default": "US/Pacific" },
    "PA": { "default": "US/Eastern" },
    "RI": { "default": "US/Eastern" },
    "SC": { "default": "US/Eastern" },
    "SD": { "default": "US/Central" },
    "TN": { "default": "US/Central" },
    "TX": { "default": "US/Central" },
    "UT": { "default": "US/Mountain" },
    "VT": { "default": "US/Eastern" },
    "VA": { "default": "US/Eastern" },
    "WA": { "default": "US/Pacific" },
    "WV": { "default": "US/Eastern" },
    "WI": { "default": "US/Central" },
    "WY": { "default": "US/Mountain" }
  }
