import React from 'react'
import { Box, TextField, Grid } from '@material-ui/core'
import RenderHeightFtIn from './RenderHeightFtIn'

const RenderHeight = ({ master, values, unit, handleChange, setBmiMeasures, classes }) => {
    const filteredMaster = master?.vitalMasters?.find(
        (vm) => (vm.name || '').toLowerCase() === 'height',
    )

    const [valueArr, setValueArr] = React.useState(['', ''])
    const filteredEle = values?.find((v) => v.vital_master_id === filteredMaster?._id)

    const handleInput = (e) => {
        const { name, value } = e.target
        let updatedValue = {}
        if (name === 'value') {
            updatedValue = {
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: value.replace(/[^0-9]/g, ''),
            }
        } else {
            const obj = {
                value_0: valueArr[0] || '',
                value_1: valueArr[1] || '',
            }
            obj[name] = value.replace(/[^0-9]/g, '')
            updatedValue = {
                vital_master_id: filteredMaster?._id,
                unit: filteredEle?.unit || filteredMaster?.units[0],
                value: `${obj.value_0}/${obj.value_1}`,
            }
        }
        handleChange(updatedValue)
        setBmiMeasures(updatedValue?.value, updatedValue?.unit)
    }

    React.useEffect(() => {
        setBmiMeasures(filteredEle?.value, filteredEle?.unit)
    }, [])

    React.useEffect(() => {
        if (filteredEle?.value) {
            const currentValueArr = `${filteredEle?.value}`?.split('/')?.map((v) => v.trim()) || ['', '']
            setValueArr(currentValueArr)
        }
    }, [filteredEle?.value])

    return (
        <>
            {unit === 'in' && Number.isInteger(filteredEle?.value) ? (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                        width: '100px',
                        borderRadius: '3px',
                        border: '1px solid #EAECF0',
                        background: '#FFF',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <Box
                        xs={12}
                        sx={{
                            '& .MuiInputBase-input': {
                                textAlign: 'center',
                            },
                        }}>
                        <TextField
                            classes={{
                                root: classes.inputHeight,
                            }}
                            placeholder="Height"
                            onChange={handleInput}
                            InputProps={{
                                disableUnderline: true,
                                style: {
                                    backgroundColor: '#FFF',
                                    textAlign: 'center',
                                },
                            }}
                            value={filteredEle?.value}
                            hiddenLabel
                            id="filled-hidden-label-small"
                            variant="filled"
                            size="small"
                            name="value"
                            style={{ width: 100 }}
                        />
                    </Box>
                </Grid>
            ) : (
                !Number.isInteger(filteredEle?.value) && (
                    <RenderHeightFtIn master={master} values={values} handleChange={handleChange} classes={classes} />
                )
            )}
        </>
    )
}

export default RenderHeight