import React from 'react'
import Button from '@mui/material/Button'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import SearchIcon from '@material-ui/icons/Search'
import SearchIconLabel from 'assests/icons/SearchIconLabel'

interface Props {
    label?: string
    height?: number | string
    stt?: boolean
    width?: string
    onChangStt?: () => void
}
export const ToggleBtn = (props: Props) => {
    const { label, height, width = '35px', onChangStt } = props
    return (
        <>
            <Button
                sx={{
                    '&.MuiButton-textPrimary': {
                        color: '#333',
                        height: { height },
                        background: '#516983',
                        fontWeight: 400,
                        minWidth: 'auto',
                        width: width,
                        borderRadius: '5px',
                        '&:hover': {
                            background: '#5571c6',
                        },
                        '& .MuiButton-startIcon': {
                            margin: '0',
                        },
                    },
                }}
                onClick={onChangStt}
                size="small"
                startIcon={<SearchIconLabel color="#fff" />}>
                {label}
            </Button>
        </>
    )
}
