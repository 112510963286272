import React, {
    useReducer,
    useEffect,
    useState,
} from 'react'
import {
    Dialog,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Link,
    Button,
    FormLabel,
    Box,
    CircularProgress,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import {
    GridHeaderEl,
    NoRowsOverlayEl,
} from '../../shared/elements'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import debounce from 'lodash/debounce'
import { ReactComponent as ViewIcon } from '../../assests/images/icons_view_profile.svg'
import { ReactComponent as UserActiveIcon } from '../../assests/images/icon_user_active.svg'
import { ReactComponent as UserInActiveIcon } from '../../assests/images/icon_user_inactive.svg'
import { ReactComponent as SearchIcon } from '../../assests/images/icon_search.svg'
import { ReactComponent as ClinicAdminIcon } from '../../assests/images/icon_clinic_admin.svg'
import { getClinicAdminList } from '../../services/ClinicAdmin/clinicAdmin'
import { phoneFormat } from '../../shared/utils'
import LoadingPage, {
    LoaderType,
} from '../../utilities/loading-page'
import { SET_CLINIC_DETAIL } from '../../store/actionType'
import * as actionTypes from './actionType'
import { initialState, reducer } from './reducer'
import ClinicAdminProfileEdit from '../../components/Profiles/ClinicAdminProfile/ClinicAdminProfileEdit'
import { getClinicByClinicId } from '../../services/Clinic/clinic'
import {
    getClinicAdminProfileById,
    updateClinicAdminStatus,
} from '../../services/ClinicAdmin/clinicAdmin'
import clinic from '../../store/reducer/clinic'
import { Button as ButtonComponent } from '../../components/custom'
import {
    capitalizeFirstLetter,
    formatPhone,
    getLoggedInUserRole,
} from '../../utilities'
import {
    setLoading,
    setError,
} from '../../store/actions/loader'
import { getCharOfName } from '../../utilities/profile'

import {
    getUserListAccess,
    UserRoles,
} from '../../constants/user-roles'
import Swal from 'sweetalert2'
import { getNotificationPreferences } from '../../services/Notification'

const useStyles = makeStyles({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '$secondaryGray !important',
    },
})

const ClinicAdminList = (props) => {
    const classes = useStyles()
    const [stateValue, updateStateValue] = useState({
        isDialog: false,
        isView: false,
        isEdit: false,
    })
    const [preferences, setPreferences] = React.useState({
        email: false,
        sms: false,
        app_notification: false,
    })

    const [timestamp, setTimestamp] = React.useState(
        new Date().getTime(),
    )

    const { userId: clinicId } = props

    const currentLoggedInUserRole = getLoggedInUserRole()
    const access = getUserListAccess(
        currentLoggedInUserRole,
        'Clinic',
    )

    const columns = [
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Admin Name"
                    field="full_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'full_name',
            flex: 1,
            renderCell: (cell) => (
                <Typography noWrap>{cell.value}</Typography>
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Cellphone"
                    field="mob"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'mob',
            flex: 1,
            renderCell: (cell) => (
                <Typography noWrap>
                    {formatPhone(
                        cell?.value
                            .replace(/\D/g, '')
                            .slice(-10),
                    )}
                </Typography>
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Email"
                    field="email"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'email',
            flex: 1,
            renderCell: (cell) => (
                <Typography
                    style={{ textTransform: 'initial' }}
                    noWrap>
                    {cell.value}
                </Typography>
            ),
        },
        {
            headerName: (
                <Typography variant="body2">
                    Actions
                </Typography>
            ),
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (cell) => (
                <>
                    <IconButton
                        color="primary"
                        onClick={() =>
                            onRowViewClick(cell.row.user_id)
                        }
                        className={
                            !cell.row.is_active &&
                            'custom_table__body__cell-disabled'
                        }
                        disabled={!cell.row.is_active}>
                        <ViewIcon
                            key={`${timestamp}-view`}
                            style={{
                                height: 24,
                                width: 24,
                                marginRight: 14,
                            }}
                            title="View"
                        />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() =>
                            onRowStatusClick(
                                cell.row.user_id,
                                cell.row.is_active,
                            )
                        }
                        disabled={!access.status}>
                        {cell.row.is_active ? (
                            <UserActiveIcon
                                key={`${timestamp}-active`}
                                style={{
                                    height: 28,
                                    width: 28,
                                }}
                                title="Active"
                            />
                        ) : (
                            <UserInActiveIcon
                                key={`${timestamp}-inactive`}
                                style={{
                                    height: 24,
                                    width: 24,
                                }}
                                title="Inactive"
                            />
                        )}
                    </IconButton>
                </>
            ),
        },
    ]

    const [state, dispatch] = useReducer(
        reducer,
        initialState,
    )

    useEffect(() => {
        setTimestamp(new Date().getTime())
    }, [state.clinicAdmins.data])

    useEffect(() => {
        fetchClinicAdmins({
            page: state.pagination.page,
            limit: state.pagination.limit,
            filterString: state.searchString,
        })
    }, [state.pagination, state.searchString])

    const debouncedOnChange = React.useMemo(() => {
        return debounce(onSearch, 500, {
            leading: true,
            trailing: true,
        })
    }, [])

    async function fetchClinicAdmins({
        page,
        limit,
        filterString,
    }) {
        try {
            dispatch({
                type: actionTypes.CLINIC_ADMIN_LOADING,
            })
            const response = await getClinicAdminList({
                page,
                limit,
                filterString,
                clinic_id: clinicId,
            })

            dispatch({
                type: actionTypes.CLINIC_ADMIN_SUCCESS,
                data: response.data,
            })
        } catch (response) {
            if (response.status === 404) {
                return dispatch({
                    type: actionTypes.CLINIC_ADMIN_SUCCESS,
                    data: {
                        clinicAdmins: [],
                        totalCount: 0,
                    },
                })
            }
            dispatch({
                type: actionTypes.CLINIC_ADMIN_FAILURE,
            })
        }
    }

    const [stateClinic, dispatchClinic] = useReducer(
        clinic,
        { clinicDetails: {} },
    )

    const fetchClinicDetails = async () => {
        try {
            setLoading(true)
            const response = await getClinicByClinicId(
                clinicId,
            )
            /* if (response.status === 404) {
        return dispatch({
          type: actionTypes.CLINIC_ADMIN_SUCCESS,
          data: { clinicAdmins: [], totalCount: 0 }
        });
      } */

            dispatchClinic({
                type: SET_CLINIC_DETAIL,
                value: response.data,
            })
            setLoading(false)
        } catch (error) {
            /* dispatch({
        type: actionTypes.CLINIC_ADMIN_FAILURE,
      }); */
        }
    }

    const fetchNotificationPreferences = async (userId) => {
        try {
            const response =
                await getNotificationPreferences({
                    userId,
                })
            setPreferences(
                response.data?.data ?? preferences,
            )
        } catch (error) {
            console.log(
                'Failed to retrieve notification preferences',
            )
        }
    }

    const fetchClinicAdminDetails = async (user_id) => {
        try {
            dispatch({
                type: actionTypes.CLINIC_ADMIN_DETAILS_LOADING,
            })
            const response =
                await getClinicAdminProfileById(user_id)

            if (response.status === 404) {
                /* return dispatch({
          type: actionTypes.CLINIC_ADMIN_DETAILS_SUCCESS,
          data: {}
        }); */
            }

            dispatch({
                type: actionTypes.CLINIC_ADMIN_DETAILS_SUCCESS,
                data: response.data,
            })
        } catch (error) {
            /* dispatch({
        type: actionTypes.CLINIC_ADMIN_FAILURE,
      }); */
        }
    }

    const updateAdminStatus = async (payload) => {
        try {
            /* dispatch({
        type: actionTypes.CLINIC_ADMIN_DETAILS_LOADING,
      }); */
            const response = await updateClinicAdminStatus(
                payload,
            )

            if (response.status === 404) {
                /* return dispatch({
          type: actionTypes.CLINIC_ADMIN_DETAILS_SUCCESS,
          data: {}
        }); */
            }

            dispatch({
                type: actionTypes.CLINIC_ADMIN_UPDATE_STATUS,
                data: payload,
            })
        } catch (error) {
            /* dispatch({
        type: actionTypes.CLINIC_ADMIN_FAILURE,
      }); */
        }
    }

    useEffect(() => {
        fetchClinicDetails()
    }, [])

    const onRowViewClick = (userId, role) => {
        updateStateValue((previousState) => {
            return {
                ...previousState,
                isDialog: true,
                isView: true,
            }
        })
        fetchClinicAdminDetails(userId)
        if (
            currentLoggedInUserRole ===
            UserRoles.TM2U_SUPER_ADMIN
        ) {
            fetchNotificationPreferences(userId)
        }
    }

    const onRowStatusClick = (userId, is_active) => {
        Swal.fire({
            title: `Are you sure you want to ${
                is_active ? 'deactivate' : 'activate'
            } this Clinic Admin?`,
            text: '',
            showCancelButton: true,
            confirmButtonColor: '$primaryDarkBlue',
            cancelButtonColor: '#eee',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                const payload = {
                    user_id: userId,
                    status: !is_active,
                }
                updateAdminStatus(payload)
            }
        })
    }

    function onSort(value) {
        dispatch({
            type: actionTypes.CLINIC_ADMIN_SORT_DATA,
            value,
        })
    }

    function onSearch(value) {
        dispatch({
            type: actionTypes.SEARCH_CLINIC_ADMIN,
            value,
        })
    }

    function onPageChange(page) {
        dispatch({
            type: actionTypes.CLINIC_ADMIN_PAGE_CHANGE,
            page: page + 1,
        })
    }

    const handleAddButton = () => {
        updateStateValue((previousState) => {
            return {
                ...previousState,
                isDialog: true,
                isView: false,
                isEdit: false,
            }
        })
        state.clinicAdminDetails = {}
    }

    const handleEdit = () => {
        updateStateValue((previousState) => {
            return {
                ...previousState,
                isDialog: true,
                isView: false,
                isEdit: true,
            }
        })
    }

    const handleEditClosed = () => {
        updateStateValue((previousState) => {
            return {
                ...previousState,
                isDialog: false,
                isView: false,
                isEdit: false,
            }
        })
        fetchClinicAdmins({
            page: 1,
            limit: 10,
            filterString: '',
        })
    }
    const clinicalAdminName = `${capitalizeFirstLetter(
        state.clinicAdminDetails.first_name,
    )} ${capitalizeFirstLetter(
        state.clinicAdminDetails.last_name,
    )}`
    const [isImageLoaded, setIsImageLoaded] =
        useState(false)

    return (
        <div className="MuiDataGrid-table-wrapper">
            <Grid
                item
                xs={12}
                className="bg-tab-client dFlexSpaceBw">
                <Typography
                    variant="h3"
                    className="clinic-label">
                    {stateClinic.clinicDetails?.clinic_name}
                </Typography>
                <Grid>
                    <Typography
                        variant="h6"
                        className="clinic--address-label">
                        {stateClinic.clinicDetails?.add1}
                    </Typography>
                    <Typography
                        variant="h6"
                        className="clinic--address-label">
                        {stateClinic.clinicDetails?.add2}
                    </Typography>
                    <Typography
                        variant="h6"
                        className="clinic--address-label">
                        {stateClinic.clinicDetails?.city}
                    </Typography>
                    <Typography
                        variant="h6"
                        className="clinic--address-label">{`${stateClinic.clinicDetails?.state} ${stateClinic.clinicDetails?.zip}`}</Typography>
                </Grid>
            </Grid>
            <Grid container direction="column" spacing={2}>
                <Grid
                    container
                    item
                    spacing={2}
                    alignItems="center"
                    style={{
                        marginTop: '5vh',
                    }}>
                    <Grid item xs={3}>
                        <Typography variant="h3">
                            <ClinicAdminIcon
                                key={`${timestamp}-clinic-admin`}
                                style={{
                                    height: 34,
                                    width: 34,
                                    verticalAlign: 'middle',
                                    paddingRight: 15,
                                }}
                            />
                            <strong>Clinic Admins</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Paper elevation={0}>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                onChange={(e) =>
                                    debouncedOnChange(
                                        e.target.value,
                                    )
                                }
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchIcon
                                                    key={`${timestamp}-search`}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: {
                                        notchedOutline:
                                            classes.notchedOutline,
                                    },
                                }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className="clinic--admin-found">
                            <strong>
                                {
                                    state.clinicAdmins
                                        ?.totalCount
                                }{' '}
                                Clinic Admins found
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        item
                        xs={2}>
                        {currentLoggedInUserRole ===
                        'tm2u_super_admin' ? (
                            <ButtonComponent
                                onClick={handleAddButton}>
                                {`Add Clinic Admin`}
                            </ButtonComponent>
                        ) : (
                            ''
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    className="bg-white">
                    {state.clinicAdmins.isLoading && (
                        <LoadingPage
                            type={LoaderType.LINEAR}
                        />
                    )}
                    <DataGrid
                        getRowId={(row) => row.user_id}
                        rows={state.clinicAdmins.data}
                        columns={columns}
                        pagination
                        page={state.pagination.page - 1}
                        pageSize={state.pagination.limit}
                        rowsPerPageOptions={[
                            state.pagination.limit,
                        ]}
                        rowCount={
                            state.clinicAdmins.totalCount
                        }
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector
                        disableSelectionOnClick
                        autoHeight
                        rowHeight={60}
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl
                                    title={
                                        !state.clinicAdmins
                                            .isFailure
                                            ? 'No Clinic Admins found.'
                                            : 'Failed to retrieve Clinic Admins'
                                    }
                                />
                            ),
                        }}
                        loading={
                            state.clinicAdmins.isLoading ||
                            state.isLoadingAdminDetails
                        }
                    />
                </Grid>
            </Grid>
            <Dialog
                open={
                    stateValue.isDialog &&
                    !state.isLoadingAdminDetails
                }
                // onClose={() => this.setState({ isDialog: false })}
                fullWidth
                aria-labelledby="profile-dialog"
                className="profile-dialog-main">
                <DialogTitle id="profile-dialog">
                    {stateValue.isEdit
                        ? 'Edit Clinic Admin'
                        : stateValue.isView
                        ? 'Clinic Admin'
                        : 'Add Clinic Admin'}

                    <CloseIcon
                        onClick={() => {
                            updateStateValue(
                                (previousState) => {
                                    return {
                                        ...previousState,
                                        isDialog: false,
                                        isView: false,
                                        isEdit: false,
                                    }
                                },
                            )
                        }}
                    />
                </DialogTitle>
                <DialogContentText>
                    {stateValue.isView ? (
                        <Grid
                            fullWidth
                            container
                            className="profile-dialog-main__contentWrap"
                            alignItems="flex-start">
                            <Grid
                                item
                                xs={4}
                                className="profile-dialog-main__contentWrap__imgWrap">
                                <div className="upload-wrap">
                                    <div className="empty-bg">
                                        {state
                                            .clinicAdminDetails
                                            ?.photo ? (
                                            <>
                                                {!isImageLoaded && (
                                                    <CircularProgress />
                                                )}
                                                <img
                                                    style={{
                                                        display:
                                                            isImageLoaded
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                    src={
                                                        state
                                                            .clinicAdminDetails
                                                            ?.photo
                                                    }
                                                    alt="Profile"
                                                    className="profile-img"
                                                    onLoad={() =>
                                                        setIsImageLoaded(
                                                            true,
                                                        )
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <div className="placeholder-text">
                                                {getCharOfName(
                                                    clinicalAdminName,
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                className="profile-dialog-main__contentWrap__detailWrap">
                                <Typography className="profile-dialog-main__contentWrap__detailWrap__title">
                                    {clinicalAdminName}
                                </Typography>
                                <Grid
                                    item
                                    xs={12}
                                    className="dFlexSB mb1 mb1">
                                    <Grid item xs={5}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                            Email:
                                        </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                            {
                                                state
                                                    .clinicAdminDetails
                                                    .user
                                                    ?.email
                                            }
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="dFlexSB mb1 mb1">
                                    <Grid item xs={5}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                            Cellphone:
                                        </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                            {formatPhone(
                                                state.clinicAdminDetails.user?.mob
                                                    .replace(
                                                        /\D/g,
                                                        '',
                                                    )
                                                    .slice(
                                                        -10,
                                                    ),
                                            )}
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className="dFlexSB mb1">
                                    <Grid item xs={5}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                            Clinic name:
                                        </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                            {capitalizeFirstLetter(
                                                state
                                                    .clinicAdminDetails
                                                    .clinic
                                                    ?.clinic_name,
                                            )}
                                        </span>
                                    </Grid>
                                </Grid>
                                <hr />
                                <Grid container item>
                                    {currentLoggedInUserRole ===
                                        UserRoles.TM2U_SUPER_ADMIN && (
                                        <Grid
                                            container
                                            item
                                            direction="column"
                                            spacing={1}>
                                            <Grid
                                                container
                                                item
                                                alignItems="center">
                                                <Typography variant="subtitle2">
                                                    Notification
                                                </Typography>
                                                <Grid
                                                    container
                                                    className="mb1">
                                                    <Grid
                                                        item
                                                        xs={
                                                            5
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                                            SMS
                                                            :
                                                        </span>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            6
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                                            {preferences.sms
                                                                ? 'On'
                                                                : 'Off'}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    className="mb1">
                                                    <Grid
                                                        item
                                                        xs={
                                                            5
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                                            Email
                                                            :
                                                        </span>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            6
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                                            {preferences.email
                                                                ? 'On'
                                                                : 'Off'}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    className="mb1">
                                                    <Grid
                                                        item
                                                        xs={
                                                            5
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__key">
                                                            In-App
                                                            :
                                                        </span>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={
                                                            6
                                                        }>
                                                        <span className="profile-dialog-main__contentWrap__detailWrap__value">
                                                            {preferences.app_notification
                                                                ? 'On'
                                                                : 'Off'}
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            {currentLoggedInUserRole ===
                            'tm2u_super_admin' ? (
                                <Grid
                                    container
                                    item
                                    justifyContent="flex-end">
                                    <Box sx={{ py: 2 }}>
                                        <Button
                                            variant="contained"
                                            startIcon={
                                                <EditIcon />
                                            }
                                            className="btn-primary"
                                            onClick={
                                                handleEdit
                                            }>
                                            Edit
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    ) : (
                        <ClinicAdminProfileEdit
                            profileDetails={
                                state.clinicAdminDetails
                            }
                            handleEditClose={
                                handleEditClosed
                            }
                            isCreate={!stateValue.isEdit}
                            onSaveCallback={
                                handleEditClosed
                            }
                            clinicId={
                                stateClinic.clinicDetails
                                    ?.id
                            }
                            preferences={preferences}
                        />
                    )}
                </DialogContentText>
            </Dialog>
        </div>
    )
}

export default ClinicAdminList
