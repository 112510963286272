import { FormLabel, Grid, makeStyles } from '@material-ui/core'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useFormik } from 'formik'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { ReactComponent as SearchIcon } from 'assests/images/icon_search.svg'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { TimeLabel } from 'containers/ProviderDirectory/AvailabilityTab/AddNewAvailability/components'
import { changeSearchValues } from 'store/actions/schedule'
import { useNotification } from 'shared/elements/Notification'
import {
    getLocations,
    getSpecialty,
    getVisitType,
    searchLocationAppointment,
} from 'services/Calendar'
import { SelectEl } from '../../../shared/elements'
import { Button as ButtonCustom } from '../../../components/custom'
import MultipleSelect from './MultipleSelect'
import { DAYS, optionTZ } from '../constant'
import { searchProvider } from 'services/ProviderDirectory'
import { getFullName } from 'utilities/formats'
import { searchValidationSchema } from './validation'
import { SearchResult } from './SearchResult'

const useStyles = makeStyles(() => ({
    required_text: {
        color: 'red',
    },
}))

function Search({ onChangeTab }) {
    const classes = useStyles()
    const ref = useRef(null)
    const schedule = useSelector((state) => state.schedule)

    const [initialValues] = useState(
        schedule.search_values || {
            timezone: '',
            visit_type_id: '',
            start_date: '',
            start_time: '00:00',
            end_date: '',
            end_time: '23:59',
            all_day: true,
            location_ids: [],
            specialty: '',
            practitioner_ids: [],
            days: [],
        },
    )

    const [listSpecialty, setListSpecialty] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [listLocation, setListLocation] = useState([])
    const [listProvider, setListProvider] = useState([])
    const [dataProvider, setDataProvider] = useState([])

    const [loadingSpecialty, setLoadingSpecialty] = useState(false)
    const [loadingVisitType, setLoadingVisitType] = useState(false)
    const [loadingLocation, setLoadingLocation] = useState(false)
    const [loadingProvider, setLoadingProvider] = useState(false)

    const [searching, setSearching] = useState(false)
    const [mounted, setMounted] = useState(false)

    const dispatch = useDispatch()
    const notification = useNotification()

    const getListVisitType = async () => {
        try {
            setLoadingVisitType(true)
            const params = { page: 1, limit: 100000 }
            if (formik.values.specialty) {
                params.specialty_id = formik.values.specialty
            }
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType(params)
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingVisitType(false)
        }
    }

    const getListSpecialty = async () => {
        try {
            setLoadingSpecialty(true)
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setListSpecialty(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.specialty,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingSpecialty(false)
        }
    }

    const getListLocation = async () => {
        try {
            setLoadingLocation(true)
            const res = await getLocations({ size: 100000 })
            const list = res.data.data
                .filter((el) => !el.is_external)
                .map((item) => ({
                    value: item.name,
                    key: item.id,
                }))
            setListLocation(list)
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingLocation(false)
        }
    }

    const getListProvider = async (force) => {
        try {
            setLoadingProvider(true)

            let result
            if (force) {
                const { data } = await searchProvider({
                    page: 1,
                    size: 100000,
                    _sort: '-_id',
                })
                setDataProvider(data)
                result = data
            } else {
                result = dataProvider
            }

            const map = (list) => {
                return list.map((item) => ({
                    key: item.id,
                    value: getFullName(item.first_name, item.last_name),
                }))
            }

            if (formik.values.specialty || formik.values.location_ids.length) {
                const searchAll = formik.values.specialty && formik.values.location_ids.length
                const searchSpecialty = formik.values.specialty
                const searchLocation = formik.values.location_ids.length

                const list = result.filter((el) => {
                    const { specialities = [], location_ids = [] } = el
                    if (searchAll) {
                        return (
                            specialities.some(
                                (speciality) => speciality.id === formik.values.specialty,
                            ) &&
                            location_ids.some((id) =>
                                formik.values.location_ids.some((location) => location.key === id),
                            )
                        )
                    } else if (searchSpecialty) {
                        return specialities.some(
                            (speciality) => speciality.id === formik.values.specialty,
                        )
                    } else if (searchLocation) {
                        return location_ids.some((id) =>
                            formik.values.location_ids.some((location) => location.key === id),
                        )
                    }
                })
                setListProvider(map(list))
            } else {
                setListProvider(map(result))
            }
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingProvider(false)
        }
    }

    const handleSearch = async () => {
        ref.current.getData()
    }
    const formik = useFormik({
        validationSchema: searchValidationSchema,
        enableReinitialize: false,
        initialValues,
        onSubmit: handleSearch,
    })

    const handleChangeMultipleSelect = (option, checked, fieldName) => {
        const newValue = [...formik.values[fieldName]]
        if (checked) {
            newValue.push(option)
        } else {
            newValue.splice(
                newValue.findIndex((el) => el.key === option.key),
                1,
            )
        }
        formik.setFieldValue(fieldName, newValue)
    }

    useEffect(() => {
        ;(async () => {
            const abortController = new AbortController()
            await Promise.allSettled([
                getListSpecialty(),
                getListVisitType(),
                getListLocation(),
                getListProvider(true),
            ])
            return () => abortController.abort()
        })()
    }, [])

    useEffect(() => {
        return () => {
            dispatch(changeSearchValues(formik.values))
        }
    }, [formik.values, dispatch])

    useEffect(() => {
        ;(async () => {
            if (formik.values.specialty) {
                await getListVisitType()
            }
        })()
    }, [formik.values.specialty])

    useEffect(() => {
        ;(async () => {
            if (formik.values.specialty || formik.values.location_ids.length) {
                await getListProvider(false)
            }
        })()
    }, [formik.values.specialty, formik.values.location_ids])

    useEffect(() => {
        const startDate = moment(formik.values.start_date)
        if (startDate.isValid()) {
            formik.setFieldValue('end_date', startDate.add(6, 'day').format('MM/DD/YYYY'))
        }
    }, [formik.values.start_date])

    useEffect(() => {
        if (mounted || !dataProvider.length || !formik.values.practitioner_ids.length) return

        setMounted(true)

        const practitioner_ids = formik.values.practitioner_ids.map((item) => {
            if (!item.value) {
                const provider = dataProvider.find((provider) => provider.id === item.key)

                return {
                    key: item.key,
                    value: getFullName(provider.first_name, provider.last_name),
                }
            }

            return item
        })

        formik.setFieldValue('practitioner_ids', practitioner_ids)
    }, [formik.values.practitioner_ids, dataProvider])

    return (
        <Grid container item spacing={2} className="modal-spacer">
            <Grid container item alignItems="unset" spacing={2}>
                <Grid item xs={4}>
                    <FormLabel component="p" className="input-form-label">
                        Timezone <span className={classes.required_text}>*</span>
                    </FormLabel>
                    <SelectEl
                        name="timezone"
                        placeholder="Select Timezone"
                        value={formik.values.timezone}
                        options={optionTZ}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        error={formik.touched.timezone && Boolean(formik.errors.timezone)}
                        helperText={formik.touched.timezone && formik.errors.timezone}
                        onChange={(e) => {
                            formik.setFieldValue('timezone', e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormLabel component="p" className="input-form-label">
                        Specialty <span className={classes.required_text}>*</span>
                    </FormLabel>
                    <SelectEl
                        name="specialty"
                        placeholder="Select Specialty"
                        value={formik.values.specialty}
                        options={listSpecialty}
                        selectProps={{
                            style: {
                                height: 40,
                            },
                        }}
                        error={formik.touched.specialty && Boolean(formik.errors.specialty)}
                        helperText={formik.touched.specialty && formik.errors.specialty}
                        onChange={(e) => {
                            formik.setFieldValue('specialty', e.target.value)
                            formik.setFieldValue('visit_type_id', '')
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormLabel component="p" className="input-form-label">
                        Days
                    </FormLabel>
                    <MultipleSelect
                        name="days"
                        placeholder="Select Days"
                        value={formik.values.days}
                        options={DAYS}
                        onOpen={() => {}}
                        handleChange={(option, checked) => {
                            handleChangeMultipleSelect(option, checked, 'days')
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item alignItems="flex-start" spacing={2}>
                <Grid item xs={5}>
                    <TimeLabel
                        required
                        label="Start"
                        date={formik.values.start_date}
                        time={formik.values.start_time}
                        allDay={formik.values.all_day}
                        onChangeDate={(value) => {
                            formik.setFieldValue('start_date', value)
                        }}
                        onChangeTime={(value) => {
                            formik.setFieldValue('start_time', value)
                            formik.setFieldValue('all_day', false)
                        }}
                        dateCondition={
                            formik.touched.start_date && Boolean(formik.errors.start_date)
                        }
                        dateError={formik.touched.start_date && formik.errors.start_date}
                        timeCondition={
                            formik.touched.start_time && Boolean(formik.errors.start_time)
                        }
                        timeError={formik.touched.start_time && formik.errors.start_time}
                        minDate={moment()}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TimeLabel
                        required
                        label="End"
                        date={formik.values.end_date}
                        time={formik.values.end_time}
                        allDay={formik.values.all_day}
                        onChangeDate={(value) => {
                            formik.setFieldValue('end_date', value)
                        }}
                        onChangeTime={(value) => {
                            formik.setFieldValue('end_time', value)
                            formik.setFieldValue('all_day', false)
                        }}
                        dateCondition={formik.touched.end_date && Boolean(formik.errors.end_date)}
                        dateError={formik.touched.end_date && formik.errors.end_date}
                        timeCondition={formik.touched.end_time && Boolean(formik.errors.end_time)}
                        timeError={formik.touched.end_time && formik.errors.end_time}
                        minDate={
                            formik.values.start_date ? moment(formik.values.start_date) : moment()
                        }
                        maxDate={
                            formik.values.start_date
                                ? moment(formik.values.start_date).add(6, 'day')
                                : undefined
                        }
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    alignItems="center"
                    style={{ marginBottom: 10, marginTop: 32 }}>
                    <AntSwitch
                        onChange={(e) => {
                            formik.setFieldValue('all_day', e.target.checked)
                            if (e.target.checked) {
                                formik.setFieldValue('start_time', '00:00')
                                formik.setFieldValue('end_time', '23:59')
                            }
                        }}
                        checked={formik.values.all_day}
                    />
                    <Typography
                        style={{
                            marginLeft: 16,
                        }}>
                        All day
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item alignItems="center" spacing={2}>
                <Grid container item alignItems="unset" spacing={2}>
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Service Locations
                        </FormLabel>
                        <MultipleSelect
                            name="location_ids"
                            placeholder="Select Locations"
                            value={formik.values.location_ids}
                            options={listLocation}
                            onOpen={() => {}}
                            handleChange={(option, checked) => {
                                handleChangeMultipleSelect(option, checked, 'location_ids')
                            }}
                            loadingOptions={loadingLocation}
                            showKey
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Visit Type <span className={classes.required_text}>*</span>
                        </FormLabel>
                        <SelectEl
                            name="visitType"
                            placeholder="Select Visit Type"
                            value={formik.values.visit_type_id}
                            options={listVisitType}
                            selectProps={{
                                style: {
                                    height: 40,
                                },
                            }}
                            error={
                                formik.touched.visit_type_id && Boolean(formik.errors.visit_type_id)
                            }
                            helperText={formik.touched.visit_type_id && formik.errors.visit_type_id}
                            onChange={(e) => formik.setFieldValue('visit_type_id', e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container item alignItems="unset" spacing={2}>
                    <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Providers
                        </FormLabel>
                        <MultipleSelect
                            name="practitioner_ids"
                            placeholder="Select Providers"
                            value={formik.values.practitioner_ids}
                            options={listProvider}
                            onOpen={() => {}}
                            handleChange={(option, checked) => {
                                handleChangeMultipleSelect(option, checked, 'practitioner_ids')
                            }}
                            loadingOptions={loadingProvider}
                            showKey
                        />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <FormLabel component="p" className="input-form-label">
                            Patient
                        </FormLabel>
                        <SearchPatientInput
                            value={formik.values.patient}
                            handleInputChange={(e) => {
                                formik.setFieldValue('patient', e.target.value)
                            }}
                            handleAddRow={(value) => {
                                formik.setFieldValue('patient', value?.id)
                            }}
                            error={formik.touched.patient && Boolean(formik.errors.patient)}
                            helperText={formik.touched.patient && formik.errors.patient}
                        />
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
                <ButtonCustom
                    free
                    style={{
                        width: 'fit-content',
                        background: '#5571C6',
                        borderRadius: '5px',
                        fontSize: 14,
                        height: 32,
                        fontWeight: 'normal',
                        opacity: searching ? 0.5 : 1,
                        pointerEvents: searching ? 'none' : 'auto',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        formik.handleSubmit()
                    }}
                    fullWidth>
                    <SearchIcon
                        style={{
                            width: 14,
                            marginRight: 8,
                        }}
                    />
                    Search
                </ButtonCustom>
            </Grid>
            <div className="border-top-inside" />
            <Grid xs={12} item>
                <FormLabel
                    className="input-form-label"
                    style={{
                        paddingTop: 0,
                    }}>
                    Search Results
                </FormLabel>
                <SearchResult
                    ref={ref}
                    values={formik.values}
                    setSearching={setSearching}
                    onChangeTab={onChangeTab}
                />
            </Grid>
        </Grid>
    )
}

export default Search
