import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
    avatar: {
        width: 35,
        height: 35,
        background: 'gray',
        borderRadius: 80,
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
    container: {
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
        overflow: 'auto',
        position: 'relative',
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        minWidth: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '0px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    btn_print: {
        height: 32,
        background: '#E9ECF1',
        fontSize: 14,
        borderRadius: 50,
        fontWeight: 400,
        padding: '4px 16px',
        color: '#667689',
        '&:hover': {
            background: '#E9ECF1',
        },
    },
    header_bar: {
        background: '#FBFCFC',
        border: '1px solid #ECF0F0',
        borderRadius: '12px',
        padding: '12px 24px',
        marginTop: '12px',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    view_detail: {
        cursor: 'pointer',
        color: '#5571C6',
    },
    patientInsuranceCustomTabs: {
        borderBottom: '1px solid #e8e8e8 !important',
        width: '100%',
        height: '45px !important',
        minHeight: '45px !important',
    }
})
