import axios from 'axios'
import { Auth } from 'aws-amplify'
import store from 'store'
import { setActiveLatest } from 'store/actions/login'
import Routes from 'constants/routes'
import { API_SYSTEM, config } from 'constants/index'

const axiosInstance = axios.create({
    baseURL: config.dev.baseURLV2,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    },
})

async function getToken() {
    const token = localStorage.getItem('token')
    if (!token) return ''

    const { activeLatest } = store.getState().login

    const payload = JSON.parse(atob(token.split('.')[1]))

    const currentTime = parseInt(new Date().getTime() / 1000 + '')
    const { exp } = payload

    if (activeLatest && currentTime - activeLatest > 3600) return ''
    if (exp - currentTime > 60) return token

    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })

    return user.signInUserSession.idToken.jwtToken
}

axiosInstance.interceptors.request.use(
    async (requestConfig) => {
        const token = await getToken()

        if (token) {
            let uri = ''
            const { url, baseURL } = requestConfig as any

            if (url.startsWith('http')) uri = url
            else uri = baseURL + url

            const { pathname } = new URL(uri)
            if (!API_SYSTEM.includes(pathname)) store.dispatch(setActiveLatest())

            localStorage.setItem('token', token)
            requestConfig.headers['Authorization'] = `Bearer ${token}`
        } else {
            store.dispatch(setActiveLatest(0))
            localStorage.clear()
            window.location.href = Routes.HOME
            return
        }

        // let token = localStorage.getItem('token')
        // requestConfig.headers['Authorization'] = `Bearer ${token}`
        requestConfig.headers['Content-Type'] = `application/json`
        if (requestConfig.data?.source) {
            requestConfig['cancelToken'] = requestConfig.data.source.token
            delete requestConfig.data.source
        }
        return requestConfig
    },
    (error) => {
        console.log(error)
        return Promise.reject(error)
    },
)

async function getProvidersDetails(id: any) {
    try {
        //dev.telemed2u.com/login-service/providers/findById/1
        https: return await axiosInstance.get(`${config.dev.baseURL}/providers/findById/${id}`)
    } catch (err) {
        throw err
    }
}
async function getAppointmentDetails(id: any) {
    try {
        //dev.telemed2u.com/login-service/providers/findById/1
        console.log('config', config.dev)
        https: return await axios.get(`${config.dev.baseURL}/appointment/details/${id}`)
    } catch (err) {
        throw err
    }
}
async function getPatientDetails(id: any) {
    console.log('toucccch')

    try {
        https: return await axios.get(`/patients/${id}`)
    } catch (err) {
        throw err
    }
}
async function getParticipantDetails(id: any) {
    try {
        //dev.telemed2u.com/login-service/providers/findById/1
        https: return await axios.get(`${config.dev.baseURL}/participants/${id}`)
    } catch (err) {
        throw err
    }
}

export { getPatientDetails, getProvidersDetails, getParticipantDetails, getAppointmentDetails }
