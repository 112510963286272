import {
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Link,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@mui/x-data-grid'
import debounce from 'lodash/debounce'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as ViewIcon } from '../../assests/images/icons_view_profile.svg'
import { ReactComponent as SearchIcon } from '../../assests/images/icon_search.svg'
import Routes from '../../constants/routes'
import { UserRoles } from '../../constants/user-roles'
import { searchPatientForProvider } from '../../services/Patient/patient'
import {
    GridHeaderEl,
    NoRowsOverlayEl,
} from '../../shared/elements'
import { phoneFormat } from '../../shared/utils'
import LoadingPage, {
    LoaderType,
} from '../../utilities/loading-page'
import * as actionTypes from './actionTypes'
import { initialState, reducer } from './reducer'

const useStyles = makeStyles(() => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '$secondaryGray !important',
    },
    patients_container: {
        padding: '16px 20px',
    },
}))

function ProviderPatientList() {
    const history = useHistory()
    const classes = useStyles()
    const [timestamp, setTimestamp] = React.useState(
        new Date().getTime(),
    )

    const columns = [
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Patient Name"
                    field="full_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'full_name',
            flex: 1,
            renderCell: (cell) => (
                <Link
                    noWrap
                    component="button"
                    color="primary"
                    className="capitalize"
                    underline="none"
                    onClick={() =>
                        onRowClick(
                            cell.row.user_id,
                            UserRoles.PATIENT,
                        )
                    }>
                    {cell.value}
                </Link>
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="DOB"
                    field="DOB"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'dob',
            flex: 1,
            renderCell: (cell) =>
                moment(cell.value).format('MM/DD/YYYY'),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Phone Number"
                    field="mob"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'mob',
            flex: 1,
            renderCell: (cell) => (
                <Typography noWrap variant="body2">
                    {phoneFormat(
                        cell.value.substring(
                            cell.value.length - 10,
                        ),
                    )}
                </Typography>
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Email"
                    field="email"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'email',
            flex: 1,
            renderCell: (cell) => (
                <Typography
                    variant="body2"
                    style={{ textTransform: 'initial' }}
                    noWrap>
                    {cell.value}
                </Typography>
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Associated Clinic"
                    field="associated_clinic"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'associated_clinic',
            flex: 1,
            renderCell: (cell) => (
                <Typography
                    variant="body2"
                    style={{ textTransform: 'initial' }}
                    noWrap>
                    {cell.value}
                </Typography>
            ),
        },
        {
            headerName: (
                <Typography variant="body1">
                    Action
                </Typography>
            ),
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (cell) => (
                <IconButton
                    onClick={() =>
                        onRowClick(
                            cell.row.id,
                            UserRoles.PATIENT,
                        )
                    }>
                    <ViewIcon
                        key={`${timestamp}-view`}
                        style={{
                            height: 24,
                            width: 24,
                        }}
                    />
                </IconButton>
            ),
        },
    ]

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    React.useEffect(() => {
        fetchPatients({
            page: state.pagination.page,
            limit: state.pagination.limit,
            filterString: state.searchString,
        })
    }, [state.pagination, state.searchString])

    React.useEffect(() => {
        setTimestamp(new Date().getTime())
    }, [state.patients.data])

    const debouncedOnChange = React.useMemo(() => {
        return debounce(onSearch, 500, {
            leading: true,
            trailing: true,
        })
    }, [])

    async function fetchPatients({
        page,
        limit,
        filterString,
    }) {
        try {
            dispatch({
                type: actionTypes.PATIENTS_LOADING,
            })
            const response = await searchPatientForProvider(
                {
                    page,
                    limit,
                    filterString,
                    clinic: true,
                },
            )

            if (response.data.status === 404) {
                return dispatch({
                    type: actionTypes.PATIENTS_SUCCESS,
                    data: { patient: [], totalCount: 0 },
                })
            }

            dispatch({
                type: actionTypes.PATIENTS_SUCCESS,
                data: response.data,
            })
        } catch (error) {
            dispatch({
                type: actionTypes.PATIENTS_FAILURE,
            })
        }
    }

    function onSort(value) {
        dispatch({
            type: actionTypes.PATIENTS_SORT_DATA,
            value,
        })
    }

    function onRowClick(userId, role) {
        history.push(Routes.PROFILE, {
            userId,
            role,
            provider: 'ProviderRoute',
        })
    }

    function onSearch(value) {
        dispatch({
            type: actionTypes.SEARCH_PATIENTS,
            value,
        })
    }

    function onPageChange(page) {
        dispatch({
            type: actionTypes.PATIENTS_PAGE_CHANGE,
            page: page + 1,
        })
    }

    return (
        <Grid
            container
            className={classes.patients_container}
            xs={12}>
            <Grid container item>
                <Grid item xs={6} md={6}>
                    <Typography variant="h6">
                        <strong>Patients</strong>
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Paper elevation={0}>
                        <TextField
                            fullWidth
                            placeholder="Search by Patient's Name or DOB"
                            onChange={(e) =>
                                debouncedOnChange(
                                    e.target.value,
                                )
                            }
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <SearchIcon
                                                key={`${timestamp}-search`}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                classes: {
                                    notchedOutline:
                                        classes.notchedOutline,
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Grid
                container
                item
                style={{
                    marginTop: 16,
                }}>
                {state.patients.isLoading && (
                    <LoadingPage type={LoaderType.LINEAR} />
                )}
                <DataGrid
                    getRowId={(row) => row.user_id}
                    rows={state.patients.data}
                    columns={columns}
                    pagination
                    page={state.pagination.page - 1}
                    pageSize={state.pagination.limit}
                    rowsPerPageOptions={[
                        state.pagination.limit,
                    ]}
                    rowCount={state.patients.totalCount}
                    paginationMode="server"
                    onPageChange={onPageChange}
                    disableColumnSelector
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={60}
                    components={{
                        NoRowsOverlay: () => (
                            <NoRowsOverlayEl
                                title={
                                    !state.patients
                                        .isFailure
                                        ? 'No patients found.'
                                        : 'Failed to retrieve patients'
                                }
                            />
                        ),
                    }}
                    loading={state.patients.isLoading}
                />
            </Grid>
        </Grid>
    )
}

export default ProviderPatientList
