import React from "react";
import { Typography } from "@material-ui/core";
import { FaxMe } from "./interface";

interface Props {
  faxMe?: FaxMe
}

export const UserFaxInfo = (props: Props) => {
  return (
    <Typography
      style={{
        marginTop: 8,
        marginBottom: 4,
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '140%',
        display: 'flex',
        alignItems: 'center',
      }}>
      Your decicated inbound fax
      number:
      <Typography
        style={{
          fontWeight: 'bold',
          marginLeft: '5px',
        }}>
        {' '}
        {props.faxMe?.number || ''}
      </Typography>
    </Typography>
  )
}
