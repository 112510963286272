import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
    textFieldWrap: {
        '& .MuiInputBase-root': {
            padding: 10,
            height: 90,
        },
    },
})
const PDFViewer = ({ PDFFile, defaultScale = 1.5 }) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    if (!PDFFile) {
        return (
            <div className="loadding-pdf">
                <div className="loader">
                    <CircularProgress />
                </div>
            </div>
        )
    }
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <Viewer defaultScale={SpecialZoomLevel.PageFit} fileUrl={PDFFile} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
    )
}

export default PDFViewer
