import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Typography, FormControlLabel, Checkbox, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    container: {
        margin: '30px 0',
        minWidth: '30%',
    },
    labelText: {
        height: 22,
        fontSize: 14,
        letterSpacing: 0,
    },
    checkLabel: {
        fontWeight: 'normal',
        fontSize: 14,
        padding: 7,
    },
    checkbox: {
        '&$checked': {
            color: '#546FC9',
        },
        '&$disabled': {
            color: '#ccc',
        },
    },
    checked: {},
    disabled: {},
    controlWrap: {
        alignItems: 'start',
    },
})

const DataGridCustom = ({ tableProps }) => {
    return (
        <Box
            sx={{
                '& .super-app-theme--header': {
                    border: 'none',
                    '& > .MuiDataGrid-columnSeparator': {
                        visibility: 'hidden',
                        display: 'none',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        width: '100%',
                        '& .MuiTypography-body1': {
                            display: 'flex',
                            alignItems: 'center',
                            color: '#303E4E',
                            fontWeight: 500,
                            fontSize: '14px',
                        },
                    },
                },
                '& .MuiPaginationItem-previousNext': {
                    padding: '0 22px',
                },
                '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none !important',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                    borderRadius: '5px',
                    background: '#667689',
                    color: 'white',
                    border: 'none',
                },
                '& .MuiDataGrid-root': {
                    border: 'none',
                    // height: 'calc(100vh - 184px)',
                },
                '& .MuiDataGrid-cell': {
                    border: 'none !important',
                    color: '#667689',
                },
                '& .MuiDataGrid-columnHeaders': {
                    border: 'none',
                },
                '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
                    border: 'none',
                    outline: 'none !important',
                },
                '& .MuiDataGrid-footerContainer': {
                    border: 'none',
                },
                '& .MuiDataGrid-row': {
                    background: '#FAFAFA',
                    // margin: '4px 0',
                    borderRadius: '8px',
                },
            }}>
            <DataGrid
                getRowId={(row) => row?._id || row?.id}
                autoHeight
                rowHeight={40}
                headerHeight={40}
                rowSpacingType={'margin'}
                getRowSpacing={(params) => ({
                    top: params.isFirstVisible ? 0 : 4,
                    bottom: params.isLastVisible ? 0 : 4,
                })}
                disableColumnSelector={true}
                disableSelectionOnClick
                disableColumnMenu={true}
                {...tableProps}
            />
        </Box>
    )
}

export default DataGridCustom
