import React, { useState } from 'react'
import { Grid, Tab, Tabs, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import Routes from 'constants/routes'

import PatientInfor from '../PatientInfor'
import { InsuranceList } from './InsuranceList'
import { useStyles } from '../MedicalProblems/style'

import { InsuranceForm } from './InsuranceForm'
import { withStyles } from '@material-ui/styles'

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        padding: '0 !important',
        '&.Mui-selected': {
            color: '#5571c6 !important',
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `2px solid #5571c6`,
                paddingBottom: '8px',
                paddingTop: '8px',
                fontWeight: 500,
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '150px !important',
        fontSize: 14,
        fontWeight: 400
    },
}))((props) => <Tab {...props} />)

const Insurances = () => {
    const classes = useStyles()
    const history = useHistory()
    const { id: patientId } = useParams()
    const [insuranceType, setInsuranceType] = useState()
    const [tabValue, setTabValue] = useState('active') // accepted values: active | inactive

    const [insuranceModal, setInsuranceModal] = useState({
        status: false,
        itemId: null,
    })

    const [cycleCount, setCycleCount] = useState(0)

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
    }

    const insuranceModalToggle = (forceReload = false, itemId) => {
        setInsuranceModal({
            status: !insuranceModal.status,
            itemId,
        })

        if (forceReload) setCycleCount(cycleCount + 1)
    }

    const handleClickEdit = (insuranceId) => {
        // insuranceModalToggle(false, insuranceId)
        history.push(
            Routes.PATIENT.INSURANCES.EDIT.replace(':id', patientId).replace(
                ':insuranceId',
                insuranceId,
            ),
        )
    }
    const insuranceTypeReceive = (InsuranceType) => {
        // console.log('insurance type11@@: ', InsuranceType)
        setInsuranceType(InsuranceType)
    }
    return (
        <Grid container className={classes.container} direction="column" wrap="nowrap">
            <Grid
                container
                // onClick={() =>
                //     history.replace(
                //         Routes.PATIENT.DASHBOARD_PATIENT_ACCOUNT.replace(':id', patientId),
                //     )
                // }
                onClick={() => history.goBack()}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
            </Grid>

            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Insurance Policy List</Typography>
                        <Grid item>
                            <ButtonCustom
                                tooltip="Add New Insurance"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.PATIENT.INSURANCES.CREATE.replace(':id', patientId),
                                        {insuranceType}
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <PatientInfor />
                </Grid>

                <Tabs
                    className={classes.patientInsuranceCustomTabs}
                    value={tabValue}
                    onChange={handleTabChange}
                    TabIndicatorProps={{ style: { display: 'none' } }}>
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Active Insurance"
                        value={'active'}
                    />
                    <CustomTab
                        style={{ background: 'none', textTransform: 'capitalize' }}
                        label="Inactive Insurance"
                        value={'inactive'}
                    />
                </Tabs>

                <InsuranceList cycleCount={cycleCount} onClickEdit={handleClickEdit}  insuranceType={insuranceTypeReceive} tabValue={tabValue} />

                {insuranceModal.status && (
                    <InsuranceForm
                        patientId={patientId}
                        open={insuranceModal.status}
                        onClose={insuranceModalToggle}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Insurances)
