export const SCREEN = {
    PATIENT_DETAILS: 'PATIENT_DETAILS',
    ADD_PERSON: 'ADD_PERSON',
    INSURANCE: 'INSURANCE',
    BEHAVIRIOAL_QUESTIONS: 'BEHAVIRIOAL_QUESTIONS',
    ADD_PATIENT: 'ADD_PATIENT',
}

export const TABS = [
    {
        key: SCREEN.PATIENT_DETAILS,
        value: 'Patient Details',
    },
    { key: SCREEN.ADD_PERSON, value: 'Add Person' },
    { key: SCREEN.INSURANCE, value: 'Insurance' },
]
export const APPOINTMENT_TABS = [
    {
        key: SCREEN.PATIENT_DETAILS,
        value: 'Patient Details',
    },
    { key: SCREEN.STAFF, value: 'Staff' },
]

export const PATIENT_TYPE = {
    CHILD: 'child',
    ADULT: 'adult',
}
export const PATIENT_VISIT_TYPE = {
    TELEHEALTH: 'Telehealth Visit',
    OFFICE: 'Office Visit',
}

export const ANSWER_TYPE = {
    YES: 'Yes',
    NO: 'No',
}

export const QUESTION1 =
    'Do you currently have suicidal thoughts or thoughts of harming others?'
export const QUESTION2 =
    'Do you have any current or pending legal issues or are you being advised by a court judge, parole officer, or CPS to seek  mental health care or assessment?'
export const QUESTION3 =
    'Please select if you have ever experienced or suffered from any of the following in the past or present?'
export const QUESTION4 =
    'The specialty you have chosen for this appointment'
export const QUESTION5 =
    'Select your preferred location for this appointment'

export const SUICIDAL_WARNING =
    'If you are experiencing a crisis or emergency, please dial 911. You may also access the free 24-hour National Suicide Prevention Lifeline at 1-800-273-8255 (1-800-273-TALK).'
