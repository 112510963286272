import React, { useEffect, useState } from "react"
import { FormLabel, TextField, TextFieldProps } from "@material-ui/core"
import { Box, SxProps } from '@mui/material'
import { USPhoneRegexp } from "utilities/regex"
import { TextBoxProps } from "../TextBox"

type Props = {
  label?: string | React.ReactNode | React.ReactChildren
  required?: boolean
  slot?: React.ReactNode | any
  layout?: 'vertical' | 'horizontal'
  uncontrolled?: boolean
  sx?: SxProps
  onChange?: (value: any, e?: any) => void
}

const USPhoneRegexpRep = /(\d{1,3})(\d{0,3})(\d{0,4})/

/**
 * Support US phone number format (xxx) xxx-xxxx
 * @param props TextFieldProps
 * @returns 
 */
export const PhoneNumberInput = (props: TextBoxProps & TextFieldProps) => {
  const {
    label,
    required,
    slot,
    value,
    uncontrolled,
    layout = 'horizontal',
    ...rest
  } = props

  const [inputText, setInputText] = useState('')

  const handleChange = (text: string) => {
    const isDeletingCharacter = text.length < inputText.length,
      lastChar = inputText[inputText.length - 1]

    // Remove all non-digit characters
    let val = text.replace(/\D/g, '')

    if (isDeletingCharacter && lastChar === ')')
      val = val.substring(0, val.length - 1)

    // Check input characters
    if (val.length > 10 || // Max 10 digits
      val.length && !(val[val.length - 1] >= '0' && val[val.length - 1] <= '9')) return

    // Format text
    const phoneNumber = val.replace(USPhoneRegexpRep, (_, p1, p2, p3) => {
      let formatted = ''
      if (p1.length === 3) formatted += `(${p1})`
      else formatted += p1

      if (p2) formatted += ` ${p2}`
      if (p3) formatted += `-${p3}`

      return formatted
    })

    setInputText(phoneNumber)

    if (phoneNumber.length === 0) props.onChange?.('')
  }

  useEffect(() => {
    setInputText(value as string || '')
  }, [value])

  return (
    <>
      {!!label && (
        <FormLabel
          component="p"
          className="d-flex justify-space-between align-center"
          style={{ fontSize: 14, fontWeight: 500 }}>
          <span className={!!required ? 'required' : ''}>{label}</span>
          {slot}
        </FormLabel>
      )}

      {!uncontrolled && (
        <Box
          sx={{
            '& .MuiOutlinedInput-root': {
              height: 'auto',
            },
          }}>
          <TextField
            variant="outlined"
            style={{ height: 'fit-content' }}
            placeholder={rest.placeholder || '(000) 000-0000'}
            fullWidth
            {...rest}
            value={inputText || ''}
            onChange={e => handleChange(e.target.value)}
            onBlur={() => props.onChange?.(inputText)}
            error={props.error}
            helperText={
              props.error &&
              (props.helperText || (required ? 'This field is required!' : ''))
            }
          />
        </Box>
      )}
    </>
  )
}

export default PhoneNumberInput
