import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { SubstanceAbuse } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'

type Props = {
    page?: number
    dataSource?: SubstanceAbuse[]
    pagination?: Pagination
    sortModel?: GridSortModel
    onPageChange?: (value: number) => void
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    handleEdit?: (id: string | number) => void
    hanldeDelete?: (id: string | number) => void
    nonEditable?: Boolean
}

export const SubstanceAbuseHistoryDashboard = (props: Props) => {
    // Hooks
    const notification = useNotification()

    const columns: GridColumns<SubstanceAbuse> = [
        {
            headerName: 'Substance',
            headerClassName: 'super-app-theme--header',
            field: 'substance_name',
            flex: 1.5,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.substance_name} />,
        },
        {
            headerName: 'Age Of First Use',
            headerClassName: 'super-app-theme--header',
            field: 'age_of_first_use',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.age_of_first_use} />,
        },
        {
            headerName: 'Age of Last Use',
            headerClassName: 'super-app-theme--header',
            field: 'age_of_last_use',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => <DataGridCell value={row.age_of_last_use} />,
        },
        {
            headerName: 'How It Was Taken',
            headerClassName: 'super-app-theme--header',
            field: 'how_taken',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                const listText = row.how_taken
                if (listText) {
                    const hashText = listText.split(',').map((text, index) => {
                        return (
                            <span key={index}>
                                {index === 0 ? '' : ', '}
                                {text}
                            </span>
                        )
                    })
                    return hashText
                } else {
                    return <DataGridCell value={undefined} />
                }
            },
        },
        {
            headerName: 'Amount Per Day',
            headerClassName: 'super-app-theme--header',
            field: 'amount_per_day',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.amount_per_day} />,
        },
        {
            headerName: 'Days Per Month',
            headerClassName: 'super-app-theme--header',
            field: 'day_per_month',
            flex: 1,
            renderCell: ({ row }) => <DataGridCell value={row.day_per_month} />,
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !props.nonEditable
                 ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => props.handleEdit?.(params.id),
                            },
                            {
                                text: 'Delete',
                                handler: () => props.hanldeDelete?.(params.id),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                )
        },
    ]

    return (
        <DataGrid
            page={props.page}
            getRowId={(row) => row.id as string | number}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            onPageChange={props.onPageChange}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
        />
    )
}
