import { generateMonthTypes, getWeekdays } from 'containers/CalendarScheduler/utils'
import moment from 'moment'
import { SCREEN } from './constant'


const { month_types } =
    generateMonthTypes(moment())

export const INITIAL_VALUES = {
    appointment_status: '',
    priority: '',
    patient_status: '',
    categoryOption: [
        {
            key: 'Vacation',
            value: 'Vacation',
        },
        {
            key: 'Meeting',
            value: 'Meeting',
        },
    ],
    month_types,
    weekdays: getWeekdays().map(
        (day) => ({
            key: day,
            value: day[0],
        }),
    ),

    days_of_week: [moment().format('ddd')],
    bhMedOption: [
        {
            key: 'Medical',
            value: 'Medical',
        },
        {
            key: 'Behavioral Health',
            value: 'Behavioral Health',
        },
    ],
    selectedTab: SCREEN.PATIENT_DETAILS,
    selectedScreen: SCREEN.PATIENT_DETAILS,
    isNewPatient: false,
    patient_type: null,
    minor: '',
    questions: {
        1: null,
        2: null,
        3: null,
    },

    appointment_for: 'patient',
    appointment_type: 'Telehealth Visit',
    specialty_id: '',
    visitType: '',
    duration: '',
    patient: '',
    appointment_reason: '',
    location: '',
    room: '',
    category: '',
    provider: '',
    staff: '',
    start: moment().startOf('day'),
    end: moment().endOf('day'),
    end_time: moment().format('HH:mm'),
    start_time: moment().format('HH:mm'),
    date: moment().format('YYYY-MM-DD'),
    isRequireTranslator: false,
    additional_info: '',
    isSendReminder: false,
    isCompleted: false,
    checklists: undefined,
    timezone: moment.tz.guess(),
    admin_code_id: '',
    practitioner_ids: [],
    is_reoccurring_appointment: false,
    days: [],
    end_date: moment().format('YYYY-MM-DD'),
    unit: '',
    once_every: '',
    end_type: 'ON',
    series_edit: false,
    series_id: null,
    note: '',
    noteView: 'no-view',
    notes: []
}
