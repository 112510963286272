import React, { useState } from 'react';
import {  Drawer ,TextField, IconButton,Button, FormControlLabel, RadioGroup, Radio} from '@mui/material';
import { Checkbox, Divider, makeStyles, Typography, FormLabel, Select, MenuItem } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import History from '../../../../assests/icons/History.svg'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import TextWithMacrosNew from 'components/ClinicalNotes/reusable/TextWithMacrosNew';
import moment from 'moment';
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions';


const SelectDropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
            d="M8.68365 9.33047L12.2659 5.82422L13.2892 6.8258L8.68365 11.3336L4.07812 6.8258L5.10141 5.82422L8.68365 9.33047Z"
            fill="#667689"
        />
    </svg>
)
const useStyles = makeStyles(() => ({
    heading:{
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding:'15px'
    },
    headinText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '28px',
    },
    copyText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
    applyButton:{
        textTransform: 'none !important',
        color: '#FFF !important',
        fontFamily: 'Rubik !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important',
        background: '#5571C6 !important'
    },
    formContainer: {
        padding: '20px 20px',
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        overflow: 'auto !important',
        height: 'calc(100vh - 125px)',
    },
    section: {
        width: '100%',
        display: 'flex',
        gap: 50,
        alignItems: 'flex-start',
    },
    formLabel: {
        color: '#303E4E !important',
        fontFamily: 'Rubik !important',
        fontSize: '12px !important',
        fontStyle: 'normal !important',
        fontWeight: "500 !important",
        lineHeight: '16px !important',
    },
    placeholder: {
        fontSize: '14px !important',
        color: '#989CA8 !important',
        fontWeight: '400 !important',
        fontFamily: 'Rubik !important',
        fontStyle: 'normal !important',
        lineHeight: '20px !important',
    },
    selectComponent:{
        width: '100%' , 
        height:"32px", 
        background: '#F8F9FB !important'
    },
    commentSection:{
        marginTop: '8px',
        display: 'flex',
        gap: 10,
        alignItems: 'center',
    },
    addCommentText:{
        color: '#5571C6',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px', 
        cursor: 'pointer',
    },
    questionsBox: {
        // borderRadius: '5px',
        // border: '1px solid #CFCFCF',
        width: '100%',
        height: 'max-content',
        padding: '15px 15px',
        display: 'flex',
        flexDirection: 'column',
        gap: 17,
    },
    questionLine: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    question: {
        color: '#303E4E',
        fontWeight: 400,
        fontSize: 14,
        fontFamily: 'Rubik',
        lineHeight: '20px',
        paddingTop: '8px',
    }

}))
const maritalStatusOptions = ['Married', 'Single', 'Divorced', 'Widow']
const livingArrangementOptions = [
    'In an apartment',
    'In a house',
    'Alone',
    'With roommates',
    'With wife',
    'With husband',
    'With significant other',
    'With mother and father',
    'With mother',
    'With father',
    'With children',
]

const sexualHistoryOptions = [
    'None',
    'Less than once a week',
    'Once a week',
    '3-5 a week',
    'More than 3-5 a week',
]

const caffeinIntakeOptions = [
    'Yes',
    'No',
    'Coffee',
    'Tea',
    'Soda',
    'Daily less than 2 servings',
    'Daily more than 2 servings',
    'Other',
]

const alcoholIntakeOptions = [
    'Less than 2 drinks',
    'More than 2 drinks',
    'Daily',
    'Weekly',
    'Socially only',
    'Rarely',
    'Drinks alone',
    'Other',
]
function SocialHistoryCopy({copyValue,open,toggleDrawer,copyDate,copyfromPrvious}) {
  const classes = useStyles()
  const [selectedComponent, setSelectedComponent] = useState([])

  const handleChange = (value, checked) => {
    let newValue = [...selectedComponent];
    if (checked) {
        newValue.push(value);
    } else {
        newValue = newValue.filter((v) => v !== value);
    }
    setSelectedComponent(newValue);
};

  return (
    <div>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer}
      >
        <div
          style={{ overflowY:'hidden'}}
          role="presentation"
        >
            <div className={classes.heading}>
                <div style={{ display:'flex',
                                alignItems: 'center',
                                gap: '15px',
                                cursor: 'pointer',
                            }}
                                onClick={()=>{toggleDrawer()}}>
                    <IconButton>
                        <KeyboardBackspaceIcon style={{color:'#303E4E'}}/>
                    </IconButton>
                    <Typography className={classes.headinText}>
                        Social History
                    </Typography>
                </div>
                <div style={{ display:'flex',
                                alignItems: 'center',
                                gap: '10px',
                                cursor: 'pointer',
                            }}>
                    <img src={History} alt=""/>
                    <Typography className={classes.copyText}>
                        Copy from: <span style={{color:'#5571C6'}}>{copyDate ? moment(copyDate).format('MM/DD/YYYY') : ''}</span>
                    </Typography>         
                </div>
                <div style={{ display:'flex',
                                alignItems: 'center',
                                gap: '8px',
                                cursor: 'pointer',
                            }}>
                  
                            <CheckboxOption
                               option={{
                                    value: false,
                                    label: 'Select all'
                                }}
                                checked={selectedComponent?.length === 10 ? true : false}
                                onChange={(e) => {
                                    if(e.target.checked){
                                        setSelectedComponent(['narrative_section', 'question_section', 'occupation', 'alcohol_intake', 'marital_status', 'living_arrangement', 'patient_diet', 'other_substancces', 'caffeine_intake', 'sexual_history'])
                                    }else{
                                        setSelectedComponent([])
                                    }
                                }}
                            />
                        
                        
                    
                    <Button
                      className={classes.applyButton}
                      disabled={selectedComponent.length < 1}
                      onClick={()=>{
                        copyfromPrvious(selectedComponent)
                      }}
                    >
                        Apply to current
                    </Button>
                            
                </div>
            </div>
            <Divider/>
          <div className={classes.formContainer} >
            <div className={classes.section}>
                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('marital_status') ? '#EAECF0' :'#F8F9FB'}}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('marital_status') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'marital_status',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Marital Status
                            </FormLabel>
                            <Select
                                // name={`${props?.fieldName}.marital_status.selection`}
                                variant="outlined"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className={classes.placeholder}>
                                                Not chosen
                                            </Typography>
                                        )
                                    }
                                    return selected
                                }}
                                value={copyValue?.marital_status?.selection}
                                onChange={(e) => {
                                }}
                                disabled={true}
                                className={classes.selectComponent}
                                >
                                {maritalStatusOptions?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        <Typography
                                            // variant="body2"
                                            noWrap
                                            style={{
                                                color: '#667689',
                                                fontSize: 14,
                                                // ...(props?.dropdownliststyles || {}),
                                            }}>
                                            {item}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </>

                        <>
                            <TextField
                                name={`marital_status.comment`}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Marital Status"'
                                multiline
                                maxRows={2}
                                value={copyValue?.marital_status?.comment}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                    </div>
                </div>

                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('occupation') ? '#EAECF0' :'#F8F9FB'   }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('occupation') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'occupation',
                                    e.target.checked)
                            }}
                            
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                               Occupation
                            </FormLabel>
                            {copyValue?.occupation?.comment &&
                            <TextField
                                name={`occupation.comment`}
                                variant="outlined"
                                disabled={true}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Occupation"'
                                multiline
                                maxRows={2}
                                value={copyValue?.occupation?.comment}
                                onChange={(e) => {
                                    
                                }}
                            /> 
                             }
                        </>
                    </div>
                </div>

            </div>

            <div className={classes.section} style={{marginTop: '5px'}}>
                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('living_arrangement') ? '#EAECF0' :'#F8F9FB'  }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('living_arrangement') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'living_arrangement',
                                    e.target.checked)
                            }}
                      
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Living Arrangement
                            </FormLabel>
                            <Select
                                // name={`${props?.fieldName}.marital_status.selection`}
                                variant="outlined"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className={classes.placeholder}>
                                                Not chosen
                                            </Typography>
                                        )
                                    }
                                    return selected
                                }}
                                value={copyValue?.living_arrangement?.selection}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                                className={classes.selectComponent}
                                >
                                {livingArrangementOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                ))}
                            </Select>
                        </>
                        {copyValue?.living_arrangement?.comment &&
                        <>
                            <TextField
                                name={`living_arrangement.comment`}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                disabled={true}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Living Arrangement"'
                                multiline
                                maxRows={2}
                                value={copyValue?.living_arrangement?.comment}
                                onChange={(e) => {
                                    
                                }}
                            /> 
                        </>
                        }
                    </div>
                </div>

                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('alcohol_intake') ? '#EAECF0' :'#F8F9FB'}}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('alcohol_intake') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'alcohol_intake',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Alcohol intake
                            </FormLabel>
                            <Select
                                variant="outlined"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className={classes.placeholder}>
                                                Not chosen
                                            </Typography>
                                        )
                                    }
                                    return selected
                                }}
                                value={copyValue?.alcohol_intake?.selection}
                                onChange={(e) => {
                                }}
                                disabled={true}
                                className={classes.selectComponent}
                                >
                                {alcoholIntakeOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </>
                        {copyValue?.alcohol_intake?.comment &&
                        <>
                            <TextField
                                name={`alcohol_intake.comment`}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Alcohol intake"'
                                multiline
                                maxRows={2}
                                value={copyValue?.alcohol_intake?.comment}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                         }
                    </div>
                </div>

            </div>

            <div className={classes.section} style={{marginTop: '5px'}}>
                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center', background: selectedComponent.includes('sexual_history') ? '#EAECF0' :'#F8F9FB'   }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('sexual_history') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'sexual_history',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Sexual History
                            </FormLabel>
                            <Select
                                // name={`${props?.fieldName}.marital_status.selection`}
                                variant="outlined"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className={classes.placeholder}>
                                                Not chosen
                                            </Typography>
                                        )
                                    }
                                    return selected
                                }}
                                value={copyValue?.sexual_history?.selection}
                                onChange={(e) => {
                                }}
                                className={classes.selectComponent}
                                disabled={true}
                                >
                                {sexualHistoryOptions?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        <Typography
                                            // variant="body2"
                                            noWrap
                                            style={{
                                                color: '#667689',
                                                fontSize: 14,
                                                // ...(props?.dropdownliststyles || {}),
                                            }}>
                                            {item}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                        {copyValue?.sexual_history?.comment &&
                        <>
                            <TextField
                                name={`sexual_history.comment`}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Sexual History"'
                                multiline
                                maxRows={2}
                                value={copyValue?.sexual_history?.comment}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                         }
                    </div>
                </div>

                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('patient_diet') ? '#EAECF0' :'#F8F9FB'  }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('patient_diet') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'patient_diet',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Patient’s Diet
                            </FormLabel>
                            
                        </>
                        {copyValue?.patient_diet &&
                        <>
                            <TextField
                                name={`patient_diet.comment`}
                                variant="outlined"
                                // style={{ marginTop: 10 }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Patient’s Diet"'
                                multiline
                                maxRows={2}
                                value={copyValue?.patient_diet}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                         }
                    </div>
                </div>

            </div>
        
            <div className={classes.section} style={{marginTop: '5px'}}>
                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('caffeine_intake') ? '#EAECF0' :'#F8F9FB'   }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('caffeine_intake') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'caffeine_intake',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Caffeine Intake
                            </FormLabel>
                            <Select
                                // name={`${props?.fieldName}.marital_status.selection`}
                                variant="outlined"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <Typography className={classes.placeholder}>
                                                Not chosen
                                            </Typography>
                                        )
                                    }
                                    return selected
                                }}
                                value={copyValue?.caffeine_intake?.selection}
                                onChange={(e) => {
                                    // const { name, value } = e.target
                                    // props?.onChange(name, value)
                                }}
                                className={classes.selectComponent}
                                >
                                {caffeinIntakeOptions?.map((item) => (
                                    <MenuItem key={item} value={item}>
                                        <Typography
                                            // variant="body2"
                                            noWrap
                                            style={{
                                                color: '#667689',
                                                fontSize: 14,
                                                // ...(props?.dropdownliststyles || {}),
                                            }}>
                                            {item}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </>
                        {copyValue?.caffeine_intake?.comment &&

                        <>
                            <TextField
                                name={`caffeine_intake.comment`}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Caffeine Intake"'
                                multiline
                                maxRows={2}
                                value={copyValue?.caffeine_intake?.comment}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                        }
                    </div>
                </div>

                <div style={{ width: '400px', gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center' }}>
                    <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('other_substancces') ? '#EAECF0' :'#F8F9FB'   }}>
                        <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                            checked={selectedComponent.includes('other_substancces') ? true : false}
                            onChange={(e) => {
                                handleChange(
                                    'other_substancces',
                                    e.target.checked)
                            }}
                        />
                    </div>
                    <div style={{padding: '24px',width: '300px' }}>
                        <>
                            <FormLabel component="p" className={classes.formLabel}>
                                Other Substances
                            </FormLabel>
                        </>
                        {copyValue?.other_substancces &&
                        <>
                            <TextField
                                name={`other_substancces.comment`}
                                variant="outlined"
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: '60px !important',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                        {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                }}
                                placeholder='Type comment for "Other Substances"'
                                multiline
                                maxRows={2}
                                value={copyValue?.other_substancces}
                                onChange={(e) => {
                                    
                                }}
                                disabled={true}
                            /> 
                        </>
                         }
                    </div>
                </div>

            </div>

            <div style={{gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center',marginTop: '5px' }}>
                <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('question_section') ? '#EAECF0' :'#F8F9FB' }}>
                    <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                        checked={selectedComponent.includes('question_section') ? true : false}
                        onChange={(e) => {
                            handleChange(
                                'question_section',
                                e.target.checked)
                        }}
                    />
                </div>
                <div className={classes.questionsBox}>
                    <div className={classes.questionLine}>
                        <Typography className={classes.question}>
                            1. Do you have a history of an STI?
                        </Typography>
                        <span>
                            <RadioGroup
                                name={`sti_history`}
                                row
                                value={copyValue?.sti_history}
                                onChange={(e) => {
                                    
                                }}
                                sx={{ gap: 2 }}>
                                <FormControlLabel
                                    value={'yes'}
                                    disabled={true}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#ADB5BD !important',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 22,
                                                },
                                                '&.Mui-checked': {
                                                    color: '#3f51b5 !important',
                                                },
                                            }}
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    disabled={true}
                                    value={'no'}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#ADB5BD !important',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 22,
                                                },
                                                '&.Mui-checked': {
                                                    color: '#3f51b5 !important',
                                                },
                                            }}
                                        />
                                    }
                                    label="No"
                                />
                            </RadioGroup>
                        </span>
                    </div>

                    <div className={classes.questionLine}>
                        <Typography className={classes.question}>
                            2. Are you practicing safe sex precautions?
                        </Typography>
                        <span>
                            <RadioGroup
                                name={`safe_sex_precautions`}
                                row
                                value={copyValue?.safe_sex_precautions}
                                onChange={(e) => {
                                    
                                }}
                                sx={{ gap: 2 }}>
                                <FormControlLabel
                                    value={'yes'}
                                    disabled={true}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#ADB5BD !important',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 22,
                                                },
                                                '&.Mui-checked': {
                                                    color: '#3f51b5 !important',
                                                },
                                            }}
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value={'no'}
                                    disabled={true}
                                    control={
                                        <Radio
                                            sx={{
                                                color: '#ADB5BD !important',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 22,
                                                },
                                                '&.Mui-checked': {
                                                    color: '#3f51b5 !important',
                                                },
                                            }}
                                        />
                                    }
                                    label="No"
                                />
                            </RadioGroup>
                        </span>
                    </div>

                    <div className={classes.questionLine}>
                        <Typography className={classes.question}>
                            3. When was the last time you had STI testing?
                        </Typography>
                        <span>
                            <div style={{width: '153px'}}>
                                <DatePicker
                                    name={`last_time_sti_testing`}
                                    value={copyValue?.last_time_sti_testing}
                                    onChange={(value) => {
                                        
                                    }}
                                    notDefaultValue
                                    disabled={true}
                                />
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div style={{gap: 10, border: '1px solid #E0E0E0', display:'flex', alignItems:'center', marginTop: '5px' }}>
                <div style={{paddingLeft: '10px',borderRight: '1px solid #E0E0E0',display:'flex',alignSelf: 'stretch', alignItems:'center',background: selectedComponent.includes('narrative_section') ? '#EAECF0' :'#F8F9FB' }}>
                    <CheckboxOption
                            option={{
                                value: false,
                                label: ''
                            }} 
                        checked={selectedComponent.includes('narrative_section') ? true : false}
                        onChange={(e) => {
                            handleChange(
                                'narrative_section',
                                e.target.checked)
                        }}
                    />
                </div>
                <div className={classes.section} style={{padding: '10px' }}>
                    <TextWithMacrosNew
                        name={`narrtive`}
                        isDisabled={true}
                        rows={12}
                        withoutMacro={false}
                        values={copyValue?.narrtive}
                        socialHistory={true}
                    />
                </div>
            </div>

          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SocialHistoryCopy;
