import { useSelector } from 'react-redux'
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import Confirm from 'components/shared/Elements/Confirm'
import { ManagementRoles } from 'constants/user-roles'
import Routes from 'constants/routes'
import LoadingPage from 'utilities/loading-page'

const error = 'Sorry! You do not have permission to access this feature.'
const headerSize = 15

const providerRoles = [
    ManagementRoles.BH_Provider,
    ManagementRoles.Medical_Provider,
    ManagementRoles.Mid_level_BH_Provider,
    ManagementRoles.Mid_level_Medical_Provider,
]
const BrandingAccess = [ManagementRoles.Super_User, ManagementRoles.Tenant_Admin]

function common(Component, roles = [], reverse = false) {
    return () => {
        const userData = useSelector((state) => state?.login?.userData)

        const checkAccess = () => {
            let access = reverse
            if (roles.includes(userData?.role_code)) access = !reverse

            return access
        }
        const access = checkAccess()
        const [openConfirm, setOpenConfirm] = useState(!access)

        return access ? (
            <Component />
        ) : (
            <Confirm
                open={openConfirm}
                btnText="Confirm"
                message={error}
                onCancel={() => setOpenConfirm(false)}
                headerSize={headerSize}
            />
        )
    }
}
function AccessThroughPermission(Component, resource, permission) {
    return () => {
        const dataIsAvailable = useSelector((state) => state?.permission.isPermissionAvailable)
        const userData = useSelector((state) => state?.permission[resource])
        const checkAccess = () => {
            if (userData && !userData.includes(permission)) {
                return false
            }
            return true
        }
        const [openConfirm, setOpenConfirm] = useState(!checkAccess())

        return !dataIsAvailable ? (
            <LoadingPage />
        ) : checkAccess() ? (
            <Component />
        ) : (
            <Confirm
                open={openConfirm}
                btnText="Confirm"
                message={error}
                onCancel={() => setOpenConfirm(false)}
                headerSize={headerSize}
            />
        )
    }
}
function AccessThroughPermissionForERX(Component, resource, permission) {
    return () => {
        const dataIsAvailable = useSelector((state) => state?.permission.isPermissionAvailable)
        const userData = useSelector((state) => state?.permission[resource])
        const is_enable_eprescribe = useSelector(
            (state) => state?.login?.userData?.practitioner?.is_enable_eprescribe,
        )
        const checkAccess = () => {
            if ((userData && !userData.includes(permission)) || !is_enable_eprescribe) {
                return false
            }
            return true
        }
        const [openConfirm, setOpenConfirm] = useState(!checkAccess())

        return !dataIsAvailable ? (
            <LoadingPage />
        ) : checkAccess() ? (
            <Component />
        ) : (
            <Confirm
                open={openConfirm}
                btnText="Confirm"
                message={error}
                onCancel={() => setOpenConfirm(false)}
                headerSize={headerSize}
            />
        )
    }
}
function AccessThroughPermissionForViewClinicalNoteDetails(Component, resource) {
    return () => {
        const dataIsAvailable = useSelector((state) => state?.permission.isPermissionAvailable)
        const userData = useSelector((state) => state?.permission[resource])
        const checkAccess = () => {
            if (
                userData &&
                !userData.includes('preview_clinical_notes') &&
                !userData.includes('update_medical_clinical_notes')
            ) {
                return false
            }
            return true
        }
        const [openConfirm, setOpenConfirm] = useState(!checkAccess())

        return !dataIsAvailable ? (
            <LoadingPage />
        ) : checkAccess() ? (
            <Component />
        ) : (
            <Confirm
                open={openConfirm}
                btnText="Confirm"
                message={error}
                onCancel={() => setOpenConfirm(false)}
                headerSize={headerSize}
            />
        )
    }
}

function provider_directory(Component) {
    return () => {
        const userData = useSelector((state) => state?.login?.userData)
        const { id } = useParams()

        const getRole = () => {
            let role = ''

            switch (userData?.role_code) {
                case ManagementRoles.Super_User:
                case ManagementRoles.Tenant_Admin:
                case ManagementRoles.Care_Coordinator:
                case ManagementRoles.Medical_Assitance:
                    role = 'manager'
                    break

                case ManagementRoles.BH_Provider:
                case ManagementRoles.Medical_Provider:
                case ManagementRoles.Mid_level_BH_Provider:
                case ManagementRoles.Mid_level_Medical_Provider:
                case ManagementRoles.Therapist:
                    role = 'provider'
                    break
                default:
            }

            return role
        }
        const role = getRole()
        const [openConfirm, setOpenConfirm] = useState(!role)

        return role ? (
            role === 'provider' && id !== userData['custom:user_id'] ? (
                <Redirect
                    to={Routes.PROVIDER_DIRECTORY.INFO.replace(
                        ':id',
                        `${userData['custom:user_id']}`,
                    )}
                />
            ) : (
                <Component role={role} />
            )
        ) : (
            <Confirm
                open={openConfirm}
                btnText="Confirm"
                message={error}
                onCancel={() => setOpenConfirm(false)}
                headerSize={headerSize}
            />
        )
    }
}

// function superbill(Component) {
//     return () => {
//         const userData = useSelector((state) => state?.login?.userData)
//         const permissions = useSelector((state) => state?.permission?.super_bills)

//         const checkAccess = () => {
//             let access = false

//             switch (userData?.role_code) {
//                 case ManagementRoles.Super_User:
//                 case ManagementRoles.Tenant_Admin:
//                 case ManagementRoles.Biller:
//                 case ManagementRoles.Billing_Manager:
//                     access = true
//                     break
//                 default:
//             }

//             if (access) {
//                 access = permissions.includes('manage_super_bills')
//             }

//             return access
//         }

//         const access = checkAccess()
//         const [openConfirm, setOpenConfirm] = useState(!access)

//         return access ? (
//             <Component />
//         ) : (
//             <Confirm
//                 open={openConfirm}
//                 btnText="Confirm"
//                 message={error}
//                 onCancel={() => setOpenConfirm(false)}
//                 headerSize={headerSize}
//             />
//         )
//     }
// }

// const clinical_note = (Component) =>
//     common(Component, [ManagementRoles.Care_Coordinator, ManagementRoles.Tenant_Admin], true)
const clinical_note = (Component) =>
    AccessThroughPermission(Component, 'clinical_notes', 'access_medical_clinical_notes')
const clinical_note_preview = (Component) =>
    AccessThroughPermissionForViewClinicalNoteDetails(Component, 'clinical_notes')

const document = (Component) => common(Component, providerRoles)

const efax = (Component) => common(Component, [ManagementRoles.Tenant_Admin])

const erx = (Component) => AccessThroughPermissionForERX(Component, 'erx', 'access_erx')
const superbill = (Component) =>
    AccessThroughPermission(Component, 'super_bills', 'view_superbills_list')
const referrals = (Component) =>
    AccessThroughPermission(Component, 'referrals', 'view_referral_list')
const referralsCreate = (Component) =>
    AccessThroughPermission(Component, 'referrals', 'create_referral_list')
const lab_order = (Component) => common(Component, providerRoles)
const branding = (Component) => common(Component, BrandingAccess)

export const withAuth = {
    provider_directory,
    superbill,
    clinical_note,
    clinical_note_preview,
    document,
    efax,
    erx,
    lab_order,
    referrals,
    referralsCreate,
    branding,
}
