import React, { useEffect } from 'react'
import { Box, FormLabel, Grid, makeStyles, TextField, Checkbox } from '@material-ui/core'
import { Button, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material'
import SearchLocationInput from 'components/custom/SearchLocationInput'
import SearchProviderInput from 'components/custom/SearchProviderInput'
import moment from 'moment'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import CheckboxOption from '../../../components/ClinicalNotes/reusable/CheckboxOptions'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {
    getBusySlot,
    getLocationsByPratitioner,
    getPractitionerByRole,
    getRooms,
} from '../../../services/Calendar'
import { SelectEl } from '../../../shared/elements'
import BookingHeader from '../Header'
import DatePickerInput from './DatePickerInput'
import TimePickerInput from './TimePickerInput'
import { uniqBy } from 'lodash'
import BookingHeaderInFollowUp from '../Header/HeaderInFollowUp'
import { ViewDayPicker } from './ViewDayPicker'
import ScheduleIcon from '@mui/icons-material/Schedule'

const useStyles = makeStyles(() => ({
    required_text: {
        color: 'red',
    },
    wrap_scroll_list: {
        maxHeight: '469px',
        overflowY: 'auto',
    },
    btn_anew: {
        fontSize: '14px',
        fontWeight: '400',
    },
    item_ric: {
        background: '#fbfcfc',
        padding: '5px 15px',
        marginBottom: '13px',
        borderRadius: '7px',
        '& .icon': {
            '& svg': {
                width: '22px',
                color: '#6c6c6c',
            },
        },
        '& .MuiFormControlLabel-label': {
            paddingLeft: '7px',
        },
    },
    wrap_time_picker: {
        '$ *': {
            boxSizing: 'border-box',
        },
    },
    group_time_picker: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
    left_time: {
        width: `calc(100% - 70px)`,
    },
    right_time: {
        color: '#fff',
        width: '60px',
        background: '#4c8080',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '39px',
        marginLeft: '10px',
        cursor: 'pointer',
    },
}))

const INNIT_DEFAULT_CHECK_LIST = [
    // {
    //     description: 'Loerem description',
    //     ischecked: false,
    // },
    // {
    //     description: 'Loerem description_1',
    //     ischecked: false,
    // },
    // {
    //     description: 'Loerem description_2',
    //     ischecked: false,
    // },
    // {
    //     description: 'Loerem description_3',
    //     ischecked: false,
    // },
    // {
    //     description: 'Loerem description_4',
    //     ischecked: false,
    // },
    // {
    //     description: 'Loerem description_5',
    //     ischecked: false,
    // },
]

function Patient({
    values,
    isUpdating,
    setFieldValue,
    visitTypeOptionsData,
    locationOptionsData,
    statusOptionsData,
    editable,
    appointmentModal,
    addFormCardAppointment,
    followUp,
    errors,
    touched,
}) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [date, setDate] = React.useState()
    const [providerOptions, setProviderOptions] = React.useState([])
    const [roomOptions, setRoomOptions] = React.useState([])
    const [busySlots, setBusySlots] = React.useState([])
    const [locationOptions, setLocationOptions] = React.useState([])
    const [newTextList, setNewTextList] = React.useState('')
    const [showViewDay, setShowViewDay] = React.useState(false)
    const loading = open && options.length === 0

    const [appointmentChecklist, setAppointmentChecklist] = React.useState(INNIT_DEFAULT_CHECK_LIST)

    React.useEffect(() => {
        getRoomsOptions(values.location)
    }, [values.location])

    React.useEffect(() => {
        const checklists = appointmentChecklist.filter((item) => item.ischecked === true)
        setFieldValue('checklists', checklists)
    }, [appointmentChecklist])

    React.useEffect(() => {
        if (values.checklists?.length > 0) {
            const concatChecklist = [...values.checklists, ...appointmentChecklist]
            const newChecklist = uniqBy(concatChecklist, function (e) {
                return e.description
            })

            setAppointmentChecklist(newChecklist)
        }
    }, [isUpdating, values.id])

    useEffect(() => {
        setFieldValue('end', values.start.clone().add(values.duration, 'minutes'))
    }, [values.duration])

    React.useEffect(() => {
        if (values.provider) {
            getLocationOptions(values.provider)
            // getBusySlotsData({
            //     startTime: values.start.utc().format(),
            //     providerId: values.provider,
            // })
        }
    }, [values.provider, date])

    React.useEffect(() => {
        getPractitionerOptions(values.bhMed)
    }, [values.bhMed])

    async function getPractitionerOptions(role) {
        try {
            const response = await getPractitionerByRole()
            setProviderOptions(response.data || response.data?.result || [])
        } catch (error) {
            console.log({ error })
        }
    }
    async function getRoomsOptions(locationId) {
        try {
            const response = await getRooms(locationId)
            const data = (response.data?.data || []).sort((p, q) => p.name.localeCompare(q.name)) // Sort Room name by A-Z
            setRoomOptions(data)
        } catch (error) {
            console.log({ error })
        }
    }
    async function getBusySlotsData({ startTime, providerId }) {
        try {
            const response = await getBusySlot({
                startTime,
                providerId,
            })
            setBusySlots(
                response?.data?.result?.map((item) => {
                    const startBusySlot = moment(new Date(item.start).getTime())
                    const endBusySlot = moment(new Date(item.end).getTime())
                    return {
                        start: startBusySlot,
                        end: endBusySlot,
                    }
                }) || [],
            )
        } catch (error) {
            console.log({ error })
        }
    }
    async function getLocationOptions(practitionerId) {
        try {
            const response = await getLocationsByPratitioner(practitionerId)
            setLocationOptions(response.data?.data || [])
        } catch (error) {
            console.log({ error })
        }
    }

    const handleAddNew = () => {
        if (newTextList) {
            setAppointmentChecklist([
                { description: newTextList, ischecked: true },
                ...appointmentChecklist,
            ])
            setNewTextList('')
        }
    }

    const handleChangCheked = (rowItem, checked) => {
        const record = [...appointmentChecklist]
        const index = record.findIndex((element) => element.description === rowItem.description)
        record[index] = {
            ...record[index],
            ischecked: checked,
        }
        setAppointmentChecklist(record)
    }

    const handleDeleteRIC = (rowItem) => {
        const newRefferralIntakeCheck = appointmentChecklist.filter(
            (item) => item.description !== rowItem.description,
        )
        setAppointmentChecklist(newRefferralIntakeCheck)
    }

    return (
        <>
            <div style={{ pointerEvents: !editable && isUpdating ? 'none' : 'unset' }}>
                <Grid container item spacing={3} className="modal-spacer">
                    <Grid item xs={12}>
                        {followUp ? (
                            <BookingHeaderInFollowUp
                                addFormCardAppointment={addFormCardAppointment}
                                classes={classes}
                                editable={editable}
                                provider={values.provider}
                                start={values.start}
                                end={values.end}
                                duration={appointmentModal.duration}
                                values={values}
                                isUpdating={isUpdating}
                                visitTypeOptionsData={visitTypeOptionsData}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                appointmentId={appointmentModal.id}
                            />
                        ) : (
                            <BookingHeader
                                addFormCardAppointment={addFormCardAppointment}
                                classes={classes}
                                editable={editable}
                                appointmentId={appointmentModal.id}
                                provider={values.provider}
                                start={values.start}
                                end={values.end}
                                duration={appointmentModal.duration}
                                values={values}
                                isUpdating={isUpdating}
                                visitTypeOptionsData={visitTypeOptionsData}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                            />
                        )}
                    </Grid>
                    {!followUp && <div className="border-top-inside" />}
                    <Grid container item alignItems="center" spacing={2} className="add-button">
                        {!followUp && (
                            <Grid item xs={12}>
                                <FormLabel component="p" className="input-form-label">
                                    Reason for visit
                                </FormLabel>
                                <Box
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: 'auto',
                                        },
                                    }}>
                                    <TextField
                                        disabled={!editable && isUpdating}
                                        id="appointment_reason"
                                        name="appointment_reason"
                                        variant="outlined"
                                        value={values.appointment_reason}
                                        style={{
                                            height: 'fit-content',
                                        }}
                                        placeholder="Text area"
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        onChange={(e) =>
                                            setFieldValue('appointment_reason', e.target.value)
                                        }
                                    />
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormLabel component="p" className="input-form-label">
                                Provider <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <SearchProviderInput
                                disabled={!editable && isUpdating}
                                value={values.provider}
                                handleInputChange={(e) => {
                                    setFieldValue('provider', e.target.value)
                                }}
                                isProviderOnly={true}
                                handleAddRow={(value) => {
                                    setFieldValue('provider', value?.id)
                                }}
                                error={touched?.provider && Boolean(errors?.provider)}
                                helperText={touched?.provider && errors?.provider}
                            />
                        </Grid>
                        {!followUp && (
                            <Grid container item alignItems="unset" spacing={2}>
                                <Grid item xs={6}>
                                    <FormLabel component="p" className="input-form-label">
                                        Location <span className={classes.required_text}>*</span>
                                    </FormLabel>
                                    <SearchLocationInput
                                        value={values.location}
                                        handleInputChange={(e) => {
                                            setFieldValue('location', e.target.value)
                                        }}
                                        handleAddRow={(value) => {
                                            setFieldValue('location', value?.id)
                                        }}
                                        error={touched?.location && Boolean(errors?.location)}
                                        helperText={touched?.location && errors?.location}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormLabel component="p" className="input-form-label">
                                        Room{' '}
                                    </FormLabel>
                                    <SelectEl
                                        name="appointmentType"
                                        placeholder="Select room"
                                        value={values.room}
                                        options={roomOptions.map((item) => ({
                                            key: item.id,
                                            value: item.name,
                                        }))}
                                        selectProps={{
                                            style: {
                                                height: 40,
                                            },
                                        }}
                                        onChange={(e) => setFieldValue('room', e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container item alignItems="flex-end" spacing={2}>
                        <Grid item xs={6}>
                            <FormLabel component="p" className="input-form-label">
                                Date <span className={classes.required_text}>*</span>
                            </FormLabel>
                            <DatePickerInput
                                // disabled={!editable}
                                minDate={moment()}
                                appointmentModal={{
                                    ...appointmentModal,
                                    start: values.start,
                                }}
                                onChange={(e) => {
                                    setDate(e)
                                    const timeStartValue = values.start.format('HH:mm')
                                    const timeEndValue = values.end.format('HH:mm')
                                    setFieldValue(
                                        'start',
                                        moment(`${e.format('YYYY-MM-DD')} ${timeStartValue}`),
                                    )
                                    setFieldValue(
                                        'end',
                                        moment(`${e.format('YYYY-MM-DD')} ${timeEndValue}`),
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            {/* <FormLabel component="p" className="input-form-label">
                            Time <span className={classes.required_text}>*</span>
                        </FormLabel>
                        <Box
                            onClick={() => {
                                setShowViewDay(true)
                            }}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    color: '#667698',
                                },
                            }}>
                            <TextField
                                variant="outlined"
                                value={`${moment(values.start).format('hh:mm A')} - ${moment(
                                    values.end,
                                ).format('hh:mm A')}`}
                                style={{
                                    height: 'fit-content',
                                }}
                                fullWidth
                                disabled
                            />
                        </Box> */}

                            <Box className={classes.wrap_time_picker}>
                                <FormLabel component="p" className="input-form-label">
                                    Time <span className={classes.required_text}>*</span>
                                </FormLabel>
                                <Box className={classes.group_time_picker}>
                                    <Box className={classes.left_time}>
                                        <TimePickerInput
                                            hiddenEndTime
                                            busySlots={busySlots}
                                            appointmentModal={appointmentModal}
                                            values={{
                                                start: values.start,
                                                end: values.end,
                                            }}
                                            onChange={(e) => {
                                                const timeStartValue =
                                                    values.start.format('YYYY-MM-DD')
                                                if (e.startTime) {
                                                    setFieldValue(
                                                        'start',
                                                        moment(`${timeStartValue} ${e.startTime}`),
                                                    )
                                                    setFieldValue(
                                                        'end',
                                                        moment(
                                                            `${timeStartValue} ${e.startTime}`,
                                                        ).add(values.duration, 'minutes'),
                                                    )
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Tooltip title="Select time range" arrow placement="top-end">
                                        <Box
                                            className={classes.right_time}
                                            onClick={() => {
                                                setShowViewDay(true)
                                            }}>
                                            <ScheduleIcon fontSize="small" />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {showViewDay && (
                                <ViewDayPicker
                                    values={values}
                                    idProvider={values.provider}
                                    open={showViewDay}
                                    onClose={() => {
                                        setShowViewDay(false)
                                    }}
                                    onOke={() => {
                                        setShowViewDay(false)
                                    }}
                                    date={date}
                                    setFieldValue={setFieldValue}
                                />
                            )}
                        </Grid>
                    </Grid>

                    {followUp && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">Pre-Appointment Checklist:</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={10}>
                                        <TextBox
                                            value={newTextList}
                                            placeholder="Enter Comment"
                                            onChange={(value) => setNewTextList(value)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            className={classes['btn_anew']}
                                            variant="contained"
                                            fullWidth={true}
                                            startIcon={<AddCircleOutlineIcon />}
                                            onClick={handleAddNew}>
                                            Add new
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                {appointmentChecklist.length > 0 && (
                                    <>
                                        <Stack direction={'row'} justifyContent="space-between">
                                            <Typography sx={{ fontWeight: '500 !important' }}>
                                                Done
                                            </Typography>
                                            <Typography sx={{ fontWeight: '500 !important' }}>
                                                Description
                                            </Typography>
                                            <Typography sx={{ fontWeight: '500 !important' }}>
                                                Actions
                                            </Typography>
                                        </Stack>
                                        <Box className={classes['wrap_scroll_list']}>
                                            {appointmentChecklist.map((item, index) => (
                                                <Box key={index} className={classes['item_ric']}>
                                                    <Stack
                                                        direction={'row'}
                                                        justifyContent={'space-between'}
                                                        alignItems={'center'}>
                                                        <FormControlLabel
                                                            label={item.description}
                                                            control={
                                                                <Checkbox
                                                                    size="medium"
                                                                    color="primary"
                                                                    checked={item.ischecked}
                                                                    onChange={(e) =>
                                                                        handleChangCheked(
                                                                            item,
                                                                            e.target.checked,
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <Box
                                                            className="icon"
                                                            onClick={() => handleDeleteRIC(item)}>
                                                            <DeleteOutlineIcon fontSize="small" />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <FormLabel component="p" className="input-form-label">
                            Internal Note
                        </FormLabel>
                        <Box
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: 'auto',
                                },
                            }}>
                            <TextField
                                id="additional_info"
                                name="additional_info"
                                variant="outlined"
                                value={values.additional_info}
                                style={{
                                    height: 'fit-content',
                                }}
                                placeholder="Text area"
                                fullWidth
                                multiline
                                minRows={4}
                                onChange={(e) => setFieldValue('additional_info', e.target.value)}
                            />
                        </Box>
                    </Grid>
                </Grid>
                {!followUp ? (
                    <>
                        <div className="border-top" />
                        <Grid container item justifyContent="space-between" direction="row">
                            {!followUp && (
                                <Grid item xs={12} className="modal-spacer">
                                    <CheckboxOption
                                        // disabled={isUpdating && !editable}
                                        // name={`${fieldName}.no_concern`}
                                        option={{
                                            label: 'Require translator',
                                            value: false,
                                        }}
                                        onChange={(e) => {
                                            setFieldValue('isRequireTranslator', e.target.checked)
                                        }}
                                        checked={values?.isRequireTranslator}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12} className="modal-spacer">
                                <CheckboxOption
                                    // disabled={isUpdating && !editable}
                                    // name={`${fieldName}.no_concern`}
                                    option={{
                                        label: 'Send appointment reminder.',
                                        value: false,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('isSendReminder', e.target.checked)
                                    }}
                                    checked={values?.isSendReminder}
                                />
                            </Grid>
                            <Grid item xs={12} className="modal-spacer">
                                <CheckboxOption
                                    // disabled={isUpdating && !editable}
                                    // name={`${fieldName}.no_concern`}
                                    option={{
                                        label: 'Labs or imaging must be completed before appointment',
                                        value: false,
                                    }}
                                    onChange={(e) => {
                                        setFieldValue('isCompleted', e.target.checked)
                                    }}
                                    checked={values?.isCompleted}
                                />
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <Box sx={{ height: '35px' }}></Box>
                )}
            </div>
        </>
    )
}

export default Patient
