import React from 'react'
import { GridColumns, GridSortModel } from '@mui/x-data-grid'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { DataGrid } from 'shared/elements/DataGrid'
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import { convertValueText, convertValueTextFamilyMembers } from './utils'

type Props = {
    dataSource?: FamilyPsychiatric[]
    pagination?: Pagination
    sortModel?: GridSortModel
    page: number
    onPageChange?: (value: number) => void
    handleEdit?: (id: string | number) => void
    handleDelete?: (id: string | number) => void
    setSortModel?: React.Dispatch<React.SetStateAction<GridSortModel>>
    nonEditable?: Boolean
}

export const FamilyPsychiatricHistoryDashboard = (props: Props) => {
    const { handleEdit, handleDelete, page } = props

    // Hooks
    const notification = useNotification()

    const columns: GridColumns<FamilyPsychiatric> = [
        {
            headerName: 'Family Member',
            headerClassName: 'super-app-theme--header',
            field: 'family_member',
            flex: 0.5,
            sortable: true,
            renderCell: ({ row }) => {
                if (row.family_member) {
                    return <DataGridCell value={convertValueTextFamilyMembers(row.family_member)} />
                } else {
                    return <DataGridCell value={undefined} />
                }
            },
        },
        {
            headerName: 'Psychiatric Condition',
            headerClassName: 'super-app-theme--header',
            field: 'psychiatric_condition',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) => {
                return (row?.psychiatric_condition as string[])?.map(
                    (item, index) => (index === 0 ? '' : ', ') + convertValueText(item),
                )
            },
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => (
                !props.nonEditable
                    ?
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () => handleEdit?.(params.id),
                            },
                            {
                                text: 'Delete',
                                handler: () => handleDelete?.(params.id),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                    :
                    ""
                ),
        },
    ]

    return (
        <DataGrid
            page={page}
            getRowId={(row) => row.id as string | number}
            sortModel={props.sortModel}
            onSortModelChange={props.setSortModel}
            onPageChange={props.onPageChange}
            rows={props.dataSource || []}
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            paginationMode="server"
            rowCount={props.pagination?.total || 0}
            componentsProps={{
                pagination: {
                    onPageChange: props.onPageChange,
                    total: props.pagination?.total,
                },
            }}
        />
    )
}
