import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { Pagination } from 'shared/elements/Pagination'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { NoRowsOverlayEl } from 'shared/elements'
import Routes from 'constants/routes'
import { Box, Checkbox, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { Button as ButtonCustom } from 'components/custom'
import AddIcon from '@mui/icons-material/Add'
import { useNotification } from 'shared/elements/Notification'
import { deleteOfBrandingBackGround, getListOfBrandingBackGround, requestPreviewBrandingBackGround } from 'services/Provider/provider'
import { makeStyles } from '@material-ui/core'
const UserStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
    subtabDiv: {
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        padding: '3px',
        backgroundColor: '#F8F9FB',
        marginTop: '7px ',
    },
    form_list_container: {
        marginTop: 10,
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        backgroundColor: '#F8F9FB',
        marginRight: '4px',
        marginLeft: '4px'
    },
    wrap_data_grid: {
        paddingLeft: "10px",
        paddingRight: "10px",
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 184px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
            marginBottom: '35px',
            paddingLeft: '10px',
        },
        '& .MuiDataGrid-row': {
            background: '#FFF',
            margin: '4px 0',
            borderRadius: '5px',
            border: '1px solid #E9ECF1',
            width: "98%"
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
}))

function Background() {
    const notification = useNotification()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [backgroundData, setBackgroundImgData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const history = useHistory()
    const definedStyles = UserStyles()

    const imageView = (value) =>{
        return(<span style={{color:'#5571C6', cursor:"pointer"}} onClick={()=>{
            window.open(value)
        }}>IMG</span>)
    }
    const deleteBackgroundImg = async(id)=>{
        setisLoading(true)
        const res = await deleteOfBrandingBackGround(id)
        getBackgroundImgList()
        setisLoading(false)
    }
    const getBackgroundImgList = async () => {
        setisLoading(true)
        const res = await getListOfBrandingBackGround({ page, size: limit })
        if (res.data.data) {
            const dataArrenged = res.data.data.map((item, i) => ({
                "id": item.id,
                "filename": item.filename,
                "url": item?.file?.url,
                "created_by_name": item.created_by_name,
                "is_default": item.is_default

            }))
            setBackgroundImgData(dataArrenged || [])
        }
        setTotal((res?.data?.paging?.total))
        setisLoading(false)


    }


    const columnsForBackground = [
        {
            headerName: 'Background Name',
            headerClassName: 'super-app-theme--header',
            field: 'filename',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'File',
            headerClassName: 'super-app-theme--header',
            field: 'url"',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => {
                  return( imageView(row.url))
            },
        },
        {
            headerName: 'Created by',
            headerClassName: 'super-app-theme--header',
            field: 'created_by_name',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    value || '-'
                )
            },
        },
        {
            headerName: 'Default',
            headerClassName: 'super-app-theme--header',
            field: 'is_default',
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                <Checkbox
                    id="auto-aap-isAgreedTnC-check"
                    className="term-condition-form-check"
                    readOnly={true}
                    checked={row.is_default }
                    
                />
            ),
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'View',
                            handler: () => {
                                window.open(row.url)
                            },
                        },
                        {
                            text: 'Delete',
                            handler: () => {
                                deleteBackgroundImg(row.id)
                            },
                        },
                    ]}
                />
            ),
        },
    ]

    useEffect(() => {
        getBackgroundImgList()
    }, [page])

    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between" style={{paddingRight: '4px', paddingLeft: '3px'}}>
                    <Grid
                        container
                        flex={1}
                        justifyContent="end"
                        spacing={1}
                        className={definedStyles?.subtabDiv}
                        >
                        <Grid item>
                            <ButtonCustom
                                tooltip="Add Background"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.SETTINGS.BRANDING + Routes.SETTINGS.BACKGROUND_NEW
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${definedStyles.form_list_container}`}>
                <Box className={definedStyles.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={backgroundData}
                        columns={columnsForBackground}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={(e) => {
                                        setPage(e)
                                    }}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Background
