import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, Typography, Select, TextField, Box } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { isEmpty } from 'lodash'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import TextFieldDebounce from 'shared/elements/TextInput/TextFieldDebounce'
import edit_icon from '../../../assests/icons/edit1_icon.svg'
const useStyles = makeStyles({
    container: {
        width: '100%',
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        height: 22,
        color: '#303E4E',
        fontSize: 18,
        letterSpacing: 0,
    },
    textFieldWrap: {
        // border: '1px solid #E9ECF1',
        minHeight: '300px',
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #C8C9CD',
        borderBottom: '1px solid #C8C9CD',
        borderLeft: '1px solid #C8C9CD',
        marginTop: 0,
        color: '#667689',
        // background: '#FFF',
        '& .MuiFilledInput-root': {
            background: '#FFF',
        },
        '& .MuiFilledInput-input': {
            color: '#303E4E !important'
        }
    },
    customHeadingText: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
        '& .MuiInputBase-input': {
            fontSize: 18,
        },
    },
    // titleGrid: {
    //     marginBottom: 6,
    // },
    menuItem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

    containerText: {
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
        },
    },
    HeadingColor: {
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '140%',
    },
    CentralizedMacrosText: {
        color: '#616161 !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        padding: '16px 0 0 16px'
    },
    btnMacro: {
        color: '#303E4E !important',
        fontSize: '14px !important',
        maxWidth: '100%',
        textWrap: 'wrap'
    }
})

const TextWithMacrosNew = ({
    withoutMacro = true,
    // macros,
    heading,
    name,
    fieldName,
    initialValue,
    values,
    onChange,
    isDisabled,
    rows,
    customHeading,
    placeholder,
    socialHistory = false
}) => {
    const classes = useStyles()
    const [selectedMacro, setSelectedMacro] = useState('')
    const dispatch = useDispatch()
    const macrosData = useSelector((state) => state.macros.macroList || [])
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]
    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    const handleTextChange = (fieldName, text) => {
        onChange(fieldName, text)
    }
    const handleSelectMacro = (value) => {
        let newValue = value
        if (values === undefined) {
            newValue = value
        } else {
            newValue = values.concat(` ${value}`)
        }
        onChange(name, newValue)
    }

    return (
        <Box className={classes.container}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                className={classes.titleGrid}>
                {heading && <Typography className={classes.labelText}>{heading}</Typography>}
                {customHeading && (
                    <Grid item md={6} xs={12}>
                        <TextField
                            className={classes.customHeadingText}
                            name={customHeading.name}
                            // variant="outlined"
                            // variant="standard"
                            size="small"
                            value={customHeading.value}
                            disabled={isDisabled}
                            onChange={(e) => {
                                const { name, value } = e.target
                                handleTextChange(name, value)
                            }}
                            InputProps={{
                                classes: classes.root,
                            }}
                            inputProps={{
                                maxLength: 250,
                                placeholder: 'Add title',
                            }}
                        />
                    </Grid>
                )}
                {!withoutMacro && (
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="cetner"
                        style={{
                            borderRadius: '5px 5px 0px 0px',
                            border: '1px solid #C8C9CD',
                            padding: '2px',
                            background: socialHistory ? '#F8F9FB' : '#FFF',
                        }}>
                        <Typography style={{ padding: '9px 0px 0px 17px', color: '#303E4E', fontSize:'16px', fontWeight:'400' }}>
                            Narrative
                        </Typography>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Box
                                    sx={{
                                        '& .MuiSelect-selectMenu': {
                                            borderRadius: '4px',
                                            border: '0.7px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                                            background: '#FFFFFF',
                                            padding: '4px 24px 4px 8px !important',
                                            color: '#242424',
                                        },
                                        '& .MuiInput-root': {
                                            marginRight: '8px',
                                        },
                                    }}>
                                    <Select
                                        disableUnderline
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 505,
                                                    maxWidth: 300,
                                                    marginTop: 8,
                                                },
                                            },
                                        }}
                                        IconComponent={(props) => (<ExpandMore {...props} />)}
                                        name="macro"
                                        onChange={(e) => {
                                            setSelectedMacro(e.target.value)
                                        }}
                                        value={selectedMacro}
                                        displayEmpty
                                        disabled={isDisabled}
                                        renderValue={(sel) =>
                                            sel ? (
                                                <span
                                                    className={classes.btnMacro}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                        color: '#242424',
                                                        fontSize: '12px',
                                                        fontWeight: '400px',
                                                    }}>
                                                    <img
                                                        src={edit_icon}
                                                        alt=""
                                                        style={{ width: '12px' }}
                                                    />
                                                    Macros:{' '}
                                                    {
                                                        allMacros.find((item) => item.name === sel)
                                                            ?.name
                                                    }
                                                </span>
                                            ) : (
                                                <span
                                                    className={classes.btnMacro}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '5px',
                                                        color: '#242424',
                                                        fontSize: '12px',
                                                        fontWeight: '400px',
                                                    }}>
                                                    <img
                                                        src={edit_icon}
                                                        alt=""
                                                        style={{ width: '12px' }}
                                                    />
                                                    Macros
                                                </span>
                                            )
                                        }>
                                        <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                                            {macrosPractitionerData?.map((m) => (
                                                <MenuItem
                                                    onClick={() => {handleSelectMacro(m.description)}}
                                                    className={classes.menu__item}
                                                    key={m.id}
                                                    value={m.name}
                                                    >
                                                    <Typography className={classes.btnMacro}>
                                                        {m.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                                            {macrosData?.map((m) => (
                                                <MenuItem
                                                    onClick={() => handleSelectMacro(m.description)}
                                                    className={classes.menu__item}
                                                    key={m.id}
                                                    value={m.name}>
                                                    <Typography className={classes.btnMacro}>
                                                        {m.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <div className={classes.containerText}>
                <TextFieldDebounce
                    className={classes.textFieldWrap}
                    placeholder={placeholder}
                    name={name}
                    variant="filled"
                    size="small"
                    multiline
                    minRows={rows || 5}
                    value={values}
                    disabled={isDisabled}
                    onChange={(e) => {
                        const { name, value } = e.target
                        handleTextChange(name, value)
                    }}
                    InputProps={{
                        classes: classes.root,
                        disableUnderline: true,
                    }}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    // InputProps={{ disableUnderline: true }}
                />
            </div>
        </Box>
    )
}

export default React.memo(TextWithMacrosNew)
