import { getService, postService, putService, getServiceForPdf, deleteService } from './restClient'
import { UserRoles } from '../../../constants/user-roles'
import { getLoggedInUserRole } from '../../../utilities'
import { postSearchService } from '../../../utilities/request/restClient'

export const _getClinicalNotes = (payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    delete payload.filterString
    if (payload.status === 'All' || payload.status === 'all') {
        delete payload.status
    }
    const searchParams = new URLSearchParams({
        ...payload,
    })
    console.log('searchParams', payload, searchParams)
    return postService(`/fetch-clinical-notes`, payload)
}
export const _getClinicalNotesFilterPatient = (payload) => {
    const searchParams = new URLSearchParams({
        ...payload,
    })
    return getService(`/patient/search?${searchParams.toString()}`)
}
export const _getClinicalNotesFilterProvider = (payload) => {
    const searchParams = new URLSearchParams({
        ...payload,
    })
    return getService(`/provider/all-providers?${searchParams.toString()}`)
}
export const _getClinicalNotesDetail = (id, viewOrEdit='') => {
    if(viewOrEdit){
        return getService(`/clinical-note/${id}?editOrView=${viewOrEdit}`)
    }
    return getService(`/clinical-note/${id}`)
}

export const clinicalNoteResetForOpen = (id) =>{
    return postService(`/clinical-note/clearEditMode/${id}`)
}
export const getConfigureTemplate = (id) => {
    // const userRole = getLoggedInUserRole()
    // let userType = ''
    // switch (userRole) {
    //     case UserRoles.TM2U_ADMIN:
    //         userType = 'admin'
    //         break
    //     case UserRoles.TM2U_SUPER_ADMIN:
    //         userType = 'super-admin'
    //         break
    //     case UserRoles.CLINIC_CORDINATION:
    //         userType = 'clinic-admin'
    //         break
    //     case UserRoles.PROVIDER:
    //         userType = 'provider'
    //         break
    //     case UserRoles.MID_PROVIDER:
    //         userType = 'provider'
    //         break
    //     default:
    //         break
    // }
    return getService(`/clinical-note/${id}/configuration`)
}
export const getHistoryClinicalNote = (id) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return getService(`/${userType}/patient/${id}/clinical-note`)
}

export const _AddClinicalNotes = (payload) => postService(`/clinical-note`, payload)

export const signBatchClinicalNotes = (id) => postService(`/clinical-note/${id}/$sign-and-lock`)

export const pushMessageSocket = (payload) =>
    postService(
        // eslint-disable-next-line no-undef
        `${config.dev.baseURLV2}/dump-push`,
        payload,
    )

export const _updateClinicalNotes = (id, payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return putService(`clinical-note/${id}`, payload)
}

export const addPsychotherapy = (payload) => postService(`/psychotherapy`, payload)

export const updatePsychotherapy = (id, payload) => {
    return putService(`/psychotherapy/${id}`, payload)
}
export const createLogPsychotherapy = (payload) => {
    return postService(`/psychotherapy/log`, payload)
}

export const getPsychotherapyById = (id) => {
    return getService(`/psychotherapy/${id}`)
}
export const getPsychotherapies = (payload) => {
    const searchParams = new URLSearchParams({
        ...payload,
    })
    return getService(`/psychotherapy?${searchParams.toString()}`)
}

export const updateConfigureTemplate = (id, payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return putService(`clinical-note/${id}/configuration`, payload)
}

export const fetchPDFData = (id, payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return postService(`clinical-note/${id}/preview`, payload, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    })
}
export const fetchPDFMedicalData = (id, payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return postService(`med-clinical-note/${id}/preview`, payload, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    })
}
export const fetchPDFInjectionData = (id, payload) => {
    const userRole = getLoggedInUserRole()
    let userType = ''
    switch (userRole) {
        case UserRoles.TM2U_ADMIN:
            userType = 'admin'
            break
        case UserRoles.TM2U_SUPER_ADMIN:
            userType = 'super-admin'
            break
        case UserRoles.CLINIC_CORDINATION:
            userType = 'clinic-admin'
            break
        case UserRoles.PROVIDER:
            userType = 'provider'
            break
        case UserRoles.MID_PROVIDER:
            userType = 'provider'
            break
        default:
            break
    }
    return postService(`inj-clinical-note/${id}/preview`, payload, {
        responseType: 'blob',
        headers: {
            Accept: 'application/pdf',
        },
    })

}
export const getICDCodes = (payload) =>
    getService(`/clinical-notes-master/get-icd?filterString=${payload}`)

export const getNotePreview = ({ note_type, note_id }) =>
    getService(`/pdf-generate/pdf-html/${note_type}/${note_id}`, { responseType: 'blob' })

export const getClinicalNotes = (payload) => postService(`/clinical-notes/list`, payload)

export const AddClinicalNotes = (payload) => postService(`/clinical-notes/create`, payload)

export const getMaster = (type) => getService(`/clinical-notes-master?type=${type}`)

export const getClinicalNotesDetail = (payload) =>
    postService('/clinical-notes/get-details', payload)

export const updateNonBehaviourallNote = (payload) =>
    postService('/non-behavioural-notes/update', payload)

export const updateBehavioralNotes = (payload) => postService('/behavioural-notes/update', payload)

export const getThearphyMaster = () => getService('/clinical-notes-master?type=therapy')

export const updateThearphylNote = (payload) => postService('/therapy-notes/update', payload)

export const approveNote = (payload) => postService('/clinical-notes/approve-note', payload)

export const getNoteServicePreview = ({ note_type, note_id }) =>
    getServiceForPdf(`/pdf-generate/pdf-html/${note_type}/${note_id}`, { responseType: 'blob' })

export const getClinicalNoteInitialData = (templateId) => {
    return getService(`/clinical-note/initial-clinical-note?templateId=${templateId}`)
}

export const getAllAddendumByClinicalNoteId = (payload) => {
    let url = `/clinical-note/${payload.clinicalNoteId}/addendums`
    url = payload.dateFilter ? `${url}?dateFilter=${payload.dateFilter}` : url
    return getService(url)
}

export const createAddendum = (payload) =>
    postService(`/clinical-note/${payload.clinicalNoteId}/addendum`, payload)

export const getMedicalCoding = (appointmentId) => {
    return getService(`/appointment/${appointmentId}/medical-code`)
}

export const postMedicalCoding = (appointmentId, payload) => {
    return postService(`/appointment/${appointmentId}/medical-code`, payload)
}

export function getIDCCodeMedicalCoding(payload) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_icd10/_search`,
        payload,
    )
}

export function getCPTCodeMedicalCoding(payload) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cptcode/_search`,
        payload,
    )
}

export function getHCPCSCodeMedicalCoding(payload) {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_hcpcs/_search`,
        payload,
    )
}

export const getCPTModifier = () => {
    return getService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cpt_modifier/_search?size=1000&filter_path=hits.hits._source`,
    )
}
export const getTaxonomyCode = (searchText) => {
    return postSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_taxonomy/_search`,
        {
            size: 25,
            query: {
                multi_match: {
                    query: searchText,
                    type: 'bool_prefix',
                    fields: [
                        'code',
                        'code._2gram',
                        'code._3gram',
                        'display',
                        'display._2gram',
                        'display._3gram',
                    ],
                },
            },
            highlight: {
                pre_tags: ['<em>'],
                post_tags: ['</em>'],
                fields: {
                    code: {
                        number_of_fragments: 1,
                        fragment_size: 5,
                    },
                    display: {
                        number_of_fragments: 2,
                        fragment_size: 20,
                    },
                },
            },
        },
    )
}

export const requestApprove = (id, payload) => {
    return postService(`/clinical-note/${id}/$request-approve`, payload)
}

export const signAndLock = (id, payload) => {
    return postService(`/clinical-note/${id}/$sign-and-lock`, payload)
}

export const resendEdit = (id) => {
    return postService(`/clinical-note/${id}/$resend-edit`)
}

export const deleteNote = (id) => {
    return deleteService(`/clinical-note/${id}`)
}
export const clinicalNotelogGet = (id) => {
    return getService(`/clinical-note/logg/${id}`)
}
export const getAllCommentApi = (id) => {
    return getService(`/clinical-note/${id}/comments`)
}
export const createCommentApi = (id, payload) => {
    return postService(`/clinical-note/${id}/comment/create`,payload)
}
export const updateCommentApi = (id, payload) => {
    return putService(`/clinical-note/${id}/comment/update`,payload)
}
export const userReadCommentApi = (id, payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    const url = `/clinical-note/${id}/comment-read?${queryString}`
    return putService(url)
}
export const userUnReadCommentApi = (id, payload) => {
    const queryString = new URLSearchParams({
        ...payload,
    }).toString()
    const url = `/clinical-note/${id}/comment-unread?${queryString}`
    return getService(url)
}

export const getProvidersNextEncounter = (payload) => {
    return postService(`/telehealth/encounter/next`, payload)
} 