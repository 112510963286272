import { useRef, useEffect } from 'react'

const useUnload = (fn, isBloking) => {
    const cb = useRef(fn) // init with fn, so that type checkers won't assume that current might be undefined

    useEffect(() => {
        cb.current = fn
    }, [fn])

    useEffect(() => {
        if (isBloking) {
            const onUnload = (...args) => cb.current?.(...args)

            window.addEventListener('beforeunload', onUnload)

            return () => window.removeEventListener('beforeunload', onUnload)
        }
    }, [isBloking])
}

export default useUnload
