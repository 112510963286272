// React
import React from 'react'

//Material UI
import { Box, IconButton, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

//Icons
import { ReactComponent as EnterIcon } from 'assests/icons/enterIcon.svg' 
import { ReactComponent as EditIcon } from 'assests/icons/edit_icon.svg'
import { ReactComponent as ChiefComplaintClose } from 'assests/icons/chiefComplaintClose.svg'
import { ReactComponent as ChiefComplaintSave } from 'assests/icons/chiefComplaintSave.svg' 

//Shared components
import TextBox from 'shared/elements/FormItem/TextBox'

//Exported functions
import { ClinicalNoteContext } from 'containers/ClinicalNotes/context/ClinicalNoteContext'
import { shortenString } from 'utilities/stringOperations'

const useStyles = makeStyles(() => ({
    headerText: {
        fontFamily: 'Rubik !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        color: '#303E4E !important',
    },
    header: {
        padding: '5px 15px 2px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #F5DEA8',
        background: '#FFFAEF',
        width: '20%',
        '& .MuiBox-root .MuiFormControl-root .MuiOutlinedInput-root': {
            borderRadius: '0px !important'
        }
    },
    content: {
        fontFamily: 'Rubik !important',
        fontSize: '14px !important',
        fontWeight: '400 !important',
        color: '#303E4E !important',
        padding: '0px 10px 6px 15px'
    },
    closeIcon: {
        '&:active': {
            '& g rect': {
                fill: '#EC6B5E !important'
            },
            '& path': {
                stroke: 'white !important'
            }
        }
    },
    saveIcon: {
        '&:active': {
            '& g rect': {
                fill: '#15A452 !important'
            },
            '& path': {
                stroke: 'white !important'
            }
        }
    },
    chief_complaint_edit: {
        '& .MuiOutlinedInput-root': {
            height: 'auto !important',
            borderRadius: '0px !important'
        }
    }
}))

function EditChiefComplaint ({chiefComplaint, setChiefComplaint, handleSaveChiefComplaint, onEditMode, resetPreviousChiefComplaint}) {
    const classes = useStyles()

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
                autoFocus
                value={chiefComplaint}
                onChange={(event) => setChiefComplaint(event?.target?.value)}
                multiline
                minRows={7}
                maxRows={7}
                placeholder='Enter Chief complaint'
                variant='outlined'
                className={classes.chief_complaint_edit}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px 10px 0px 10px', alignItems: 'center' }}>
                
                {/* <div style={{ display: 'flex', gap: 5, alignItems: 'center', height: 'fit-content' }}>
                    <EnterIcon />
                    <Typography style={{ color: '#9A9A9A', fontSize: 12 }}>
                        Press enter to save
                    </Typography>
                </div> */}

                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    <IconButton onClick={() => {
                        onEditMode(false)
                        resetPreviousChiefComplaint()
                    }}>
                        <ChiefComplaintClose className={classes.closeIcon}/>
                    </IconButton>

                    <IconButton onClick={handleSaveChiefComplaint}>
                        <ChiefComplaintSave className={classes.saveIcon}/>
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

function ChiefComplaint({
    values,
    onChange,
    submitFormRef,
    initialValues,
    nonEditable
}) {
    const classes = useStyles()

    const { checkDirtySessions } = React.useContext(ClinicalNoteContext)
    
    const [onHover, setOnHover] = React.useState(false)
    const [onEdit, setOnEdit] = React.useState(false)
    const [chiefComplaint, setChiefComplaint] = React.useState(values?.chief_complaint?.narrative)

    const onEditMode = (open=true) => {
        setOnHover(open)
        setOnEdit(open)
    }

    const handleSaveChiefComplaint = () => {
        const value = {narrative: chiefComplaint}
        submitFormRef.current()
        checkDirtySessions({
          key: "chief_complaint",
          value: !_.isEqual(value, initialValues["chief_complaint"]),
        });
    
        onChange("chief_complaint", value);
        onEditMode(false)
    }

    const resetPreviousChiefComplaint = () => {
        setChiefComplaint(values?.chief_complaint?.narrative)
    }

    return (
        <Box
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => !onEdit && setOnHover(false)}
            className={classes.container}
            style={{ height: !onHover ? 'auto' : '210px', overflow: onHover ? ( onEdit ? 'auto' : 'scroll' ) : 'auto' }}>
            <div className={classes.header}>
                <Typography className={classes.headerText}>Chief Complaint:</Typography>
                {(onHover && !nonEditable) && <IconButton onClick={onEditMode}>
                    <EditIcon />
                </IconButton>}
            </div>

            { !onEdit ? 
                <Typography className={classes.content}>
                    {onHover ? chiefComplaint : shortenString(chiefComplaint, '121')}
                </Typography>
                :
                <EditChiefComplaint 
                    chiefComplaint={chiefComplaint} 
                    setChiefComplaint={setChiefComplaint}
                    handleSaveChiefComplaint={handleSaveChiefComplaint}
                    onEditMode={onEditMode}
                    resetPreviousChiefComplaint={resetPreviousChiefComplaint}
                />
            }
        </Box>
    )
}

export default ChiefComplaint
