import React, { useState } from 'react'
import { Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { GridCellParams, GridColumns } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'

import StarCheckBox from 'components/shared/StarCheckBox'
import DataGridSelectionCheckBox from 'components/shared/DataGridSelectionCheckBox'
import Routes from 'constants/routes'

import { useFaxData } from '../hooks'
import { DataGrid } from '../../../shared/elements/DataGrid'
import { Fax } from '../shared/interface'
import { FaxPageHeader } from '../shared/FaxPageHeader'
import { displayName } from '../shared/util'
import { FunctionBar } from './FunctionBar'

interface Props {}

const useStyles = makeStyles({
    container: {
        padding: 24,
        position: 'relative',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
        marginBottom: '1rem',
    },
})

export const ArchivedFax = (props: Props) => {
    const classes = useStyles()
    const history = useHistory()

    const [searchCriteria, setSearchCriteria] = useState({
        textSearch: '',
    })

    const {
        loading,
        eFaxList,
        deselectAll,
        markFavorite,
        markFavouriteAll,
        paginationData,
        setPage,
        selectAll,
        selectItem,
        unarchive,
        refresh,
    } = useFaxData('all', searchCriteria)

    const selectionModel = eFaxList.filter((item) => item.isSelected)
    const selectionModelIds = selectionModel.map((item) => item.id)

    const isAllFavourite = eFaxList.length === eFaxList.filter((item) => item.favourite).length

    // useEffect(() => {
    //   onSelectedItemsChanged(selectionModel)
    // }, [selectionModel.length])

    const handlePageChange = (page: number) => {
        setPage(page)
    }

    const handleCellClick = (params: GridCellParams<Fax>) => {
        const { row, field } = params
        if (field === 'from') history.push(Routes.EFax.DETAILS.replace(':id', row.id))
    }

    const columns: GridColumns<Fax> = [
        {
            renderHeader: (params) => (
                <DataGridSelectionCheckBox
                    type="header"
                    value={
                        !selectionModel.length
                            ? 0
                            : selectionModel.length === eFaxList.length
                            ? 1
                            : 0.5
                    }
                    onChange={selectionModel.length === eFaxList.length ? deselectAll : selectAll}
                />
            ),
            width: 65,
            field: 'id',
            sortable: false,
            type: 'action',
            renderCell: ({ row }) => {
                return (
                    <Box
                        // display="flex"
                        // justifyContent="center"
                        // alignItems="center"
                        style={{
                            width: '100%',
                        }}>
                        <DataGridSelectionCheckBox
                            value={row.isSelected}
                            onChange={() => selectItem(row.id as string)}
                        />
                    </Box>
                )
            },
        },
        {
            renderHeader: (params) => (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                        width: '100%',
                    }}>
                    <StarCheckBox
                        value={isAllFavourite}
                        onChange={() => markFavouriteAll(!isAllFavourite)}
                    />
                </Box>
            ),
            field: 'favourite',
            sortable: false,
            type: 'action',
            width: 52,
            renderCell: ({ value, row }) => {
                return (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            width: '100%',
                        }}>
                        <StarCheckBox
                            value={row.favourite}
                            onChange={() => markFavorite(row.id as string, !row.favourite)}
                        />
                    </Box>
                )
            },
        },
        {
            headerName: 'Fax No.',
            headerClassName: 'super-app-theme--header',
            field: 'from',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    <Typography
                        style={{
                            color: '#5571C6',
                            cursor: 'pointer',
                        }}>
                        {value}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Title',
            headerClassName: 'super-app-theme--header',
            field: 'title',
            flex: 1.75,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value
            },
        },
        {
            headerName: 'Associated Patient',
            headerClassName: 'super-app-theme--header',
            field: 'associatedPatient',
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.associated_patient_first_name, row.associated_patient_last_name),
        },
        {
            headerName: 'Assigned To',
            headerClassName: 'super-app-theme--header',
            field: 'assigned_to',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.assigned_to_first_name, row.assigned_to_last_name),
        },
        {
            headerName: 'Assigned By',
            headerClassName: 'super-app-theme--header',
            field: 'assigned_by',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ row }) =>
                displayName(row.assigned_by_first_name, row.assigned_by_last_name),
        },
        {
            headerName: 'Created On',
            headerClassName: 'super-app-theme--header',
            field: 'createdAt',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return moment(value).format('lll')
            },
        },
        {
            headerName: 'Updated On',
            headerClassName: 'super-app-theme--header',
            field: 'updatedAt',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return moment(value).format('lll')
            },
        },
    ]

    return (
        <Grid container className={classes.container}>
            <Grid
                container
                onClick={() => history.push(Routes.EFax.INCOMING)}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Incoming Fax</Typography>
            </Grid>

            <Grid item xs={12}>
                <FaxPageHeader
                    style={{ marginBottom: 10 }}
                    title="Archived"
                    onClickAddNew={() => history.push(Routes.EFax.NEW_FAX)}
                    onSearch={(text) => setSearchCriteria({ textSearch: text })}
                />

                <Grid>
                    <FunctionBar
                        selectionLength={selectionModel.length}
                        onRefresh={() => refresh()}
                        onUnarchive={() => unarchive(selectionModel)}
                    />

                    <DataGrid
                        loading={loading}
                        columns={columns}
                        rows={eFaxList}
                        pageSize={paginationData.limit}
                        totalRecords={paginationData.total}
                        selectionModel={selectionModelIds}
                        onCellClick={handleCellClick}
                        onPageChange={handlePageChange}
                        page={paginationData.start}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
