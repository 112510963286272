import React, { useEffect } from 'react'
import { Box, Typography, TextField, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import TextWithMacro from '../reusable/TextWithMacro'
import { DataGrid } from '@mui/x-data-grid'
import { SelectEl } from '../../../shared/elements'
import { RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from '@material-ui/icons'
import { family_history } from '../../../containers/ClinicalNotes/Behavioral/fields'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import DataGridCustom from '../reusable/DataGridCustom'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import FamilyPsychiatricHistory from 'containers/PatientsSetting/view/FamilyPsychiatricHistory'
import { useNotification } from 'shared/elements/Notification'
import { apiFamilyPsychiatric } from 'services/Patient/patient'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
        // minWidth: '50%',
    },
    headerTxt: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
        display: 'unset !important',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    mainHeading: {
        color: '#303e4e',
        fontSize: 14,
        fontWeight: '500',
    },
    sectionHeading: {
        fontSize: 12,
        color: '#9CA9B8',
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 40,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
})



const getOptions = (master = [], key) => {
    return master.map((im) => ({
        value: im[key],
        key: im._id,
    }))
}

const mapValues = (values = []) => {
    return values.map((v, index) => ({
        id: index,
        family_member: v.family_member,
        psychiatric_problem_0: v.psychiatric_problem[0],
        psychiatric_problem_1: v.psychiatric_problem[1],
        psychiatric_problem_2: v.psychiatric_problem[2],
    }))
}

const defaultHistoryOptions = {
    family_member: '',
    psychiatric_problem: [],
}

const FamilyHistory = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    patientId,
    encounter_id,
    nonEditable
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable},
        { label: 'No', value: 'No', disable: nonEditable},
    ]
    const classes = useStyles()
    const notification = useNotification()
    const familyOptions = getOptions(master?.familyMasters, 'name')
    const psychiatricOptions = getOptions(master?.psychiatricMasters, 'name')

    const handleChange = (e, keyName, id, innerIndex) => {
        const { name, value } = e.target
        if (keyName === 'family_member') {
            const updatedValue = values[name]?.map((v, index) =>
                index === id ? { ...v, [keyName]: value } : v,
            )
            onChange(`${fieldName}.${name}`, updatedValue)
        } else if (keyName === 'psychiatric_problem') {
            const updatedValue = values[name]?.map((v, index) => {
                const updatedArr = [...v.psychiatric_problem]
                if (id === index) {
                    updatedArr[innerIndex] = value
                }

                return {
                    ...v,
                    psychiatric_problem: updatedArr,
                }
            })

            onChange(`${fieldName}.${name}`, updatedValue)
        }
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        const { history_opt } = values
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box sx={{ width: 68, display: 'flex' }}>
                {history_opt.length >= 1 &&
                    (history_opt.length === 1 && isEmpty ? false : true) && (
                        //     <RemoveCircleOutlineOutlined
                        //         className={classes.icon}
                        //         color="primary"
                        //         fontSize="large"
                        //         onClick={() =>
                        //             handleAddMore(
                        //                 'remove',
                        //                 index,
                        //             )
                        //         }
                        // />
                        <IconButton
                            variant="outlined"
                            onClick={() => setAlertDeleteOpen(true)}
                        // className={classes.show_header_btn}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
                {/* {index + 1 === history_opt.length && (
                    <AddCircleOutlineOutlined
                        className={classes.icon}
                        color="primary"
                        fontSize="large"
                        onClick={() => handleAddMore('add')}
                    />
                )} */}
            </Box>
        )
    }

    const handleAddMore = (action, index) => {
        if (action === 'add') {
            const updatedValue = [...values['history_opt'], defaultHistoryOptions]
            onChange(`${fieldName}.history_opt`, updatedValue)
        } else {
            const updatedValue = values?.['history_opt'].filter((v, i) => i !== index)
            onChange(
                `${fieldName}.history_opt`,
                updatedValue.length > 0 ? updatedValue : [defaultHistoryOptions],
            )
        }
    }

    const isEmptyRow = (row = []) => {
        return !(
            row.family_member ||
            row.psychiatric_problem_0 ||
            row.psychiatric_problem_1 ||
            row.psychiatric_problem_2
        )
    }

    const columns = [
        {
            headerName: <Typography className={classes.headerTxt}>Family Member</Typography>,
            field: 'family_member',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['psych_illness_history']}
                    name={`history_opt`}
                    value={value}
                    onChange={(e) => handleChange(e, 'family_member', row.id)}
                    options={familyOptions}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: (
                <Typography className={classes.headerTxt}>Psychiatric Problem(s)</Typography>
            ),
            field: 'psychiatric_problem_0',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['psych_illness_history']}
                    name={`history_opt`}
                    value={row.psychiatric_problem_0 || ''}
                    onChange={(e) => handleChange(e, 'psychiatric_problem', row.id, 0)}
                    options={psychiatricOptions}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: <></>,
            field: 'psychiatric_problem_1',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['psych_illness_history']}
                    name={`history_opt`}
                    value={row.psychiatric_problem_1 || ''}
                    onChange={(e) => handleChange(e, 'psychiatric_problem', row.id, 1)}
                    options={psychiatricOptions}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: <></>,
            field: 'psychiatric_problem_2',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            sortable: false,
            renderCell: ({ value, id, row }) => (
                <SelectEl
                    disabled={!values['psych_illness_history']}
                    name={`history_opt`}
                    value={row.psychiatric_problem_2 || ''}
                    onChange={(e) => handleChange(e, 'psychiatric_problem', row.id, 2)}
                    options={psychiatricOptions}
                    placeholder="Select One"
                />
            ),
        },
        {
            headerName: <></>,
            field: 'id',
            headerClassName: 'super-app-theme--header',
            width: 90,
            sortable: false,
            renderCell: ({ value, id, row }) =>
                values['psych_illness_history'] && (
                    <RenderAddOrRemove index={id} isEmpty={isEmptyRow(row)} />
                ),
        },
    ]

    const getFamilyPsychiatric = async () => {
        try {
            const resp = await apiFamilyPsychiatric.getAll(patientId, {
                page: 1,
                limit: 100000,
            })
            onChange(`${fieldName}.history_list`, resp.data.listFamilyMedicalHistory.data)
        } catch (ex) {
            notification('Cannot get list Family Medical History', 'error')
        }
    }

    useEffect(() => {
        if (patientId && values.psych_illness_history) {
            getFamilyPsychiatric()
        }
    }, [patientId, values.psych_illness_history])

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['psych_illness_history']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.psych_illness_history`}
                        options={radioOptions}
                        value={values['psych_illness_history'] ? 'Yes' : 'No'}
                        onChange={(e) => {
                            const { name, value } = e.target

                            onChange(name, value === 'Yes')
                            if (value === 'No') {
                                onChange(`${fieldName}.history_opt`, [
                                    {
                                        ...defaultHistoryOptions,
                                    },
                                ])
                            }
                            onChange(`${fieldName}.narrative`, '')
                        }}
                    />
                </Box>
            </Box>
            {values['psych_illness_history'] && (
                <FamilyPsychiatricHistory
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={getFamilyPsychiatric}
                    nonEditable={nonEditable}
                />
            )}
            <Box
                sx={{
                    marginBottom: 20,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Typography className={classes.mainHeading}>Family Psychiatric History</Typography>
                <Typography className={classes.sectionHeading} style={{ marginTop: 16 }}>
                    {questions['additional_med_desc']}
                </Typography>
                <TextWithMacro
                    withoutMacro={false}
                    name={`${fieldName}.additional_med_desc`}
                    onChange={onChange}
                    macros={macros}
                    values={values.additional_med_desc}
                    isDisabled={nonEditable}
                />
            </Box>
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                    master: {
                        familyOptions,
                        psychiatricOptions,
                    },
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default FamilyHistory
