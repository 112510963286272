import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { GridSortModel } from '@mui/x-data-grid'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import AddIcon from '@mui/icons-material/Add'

import { Button as ButtonCustom } from 'components/custom'
import PatientInfor from '../PatientInfor'
import { InpatientPsychiatricHistoryDashboard } from './Dashboard'
import Routes from 'constants/routes'
import { useStyles } from '../MedicalProblems/style'
import { InpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import Loading from 'shared/elements/Loading'
import { InpatientPsychiatricForm } from './InpatientPsychiatricForm'
import { inpatientPsychiatricHistoryApis } from 'services/Patient/inpatientPsychiatricHistoryApis'
import { useNotification } from 'shared/elements/Notification'

const InpationPsychiatricHistory = ({
    isInClinicalNote,
    patient_Id,
    encounter_id,
    afterActionOk,
    nonEditable
}) => {
    // Hooks
    const classes = useStyles()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()
    const notification = useNotification()

    // State
    const [loading, setLoading] = useState(false)
    const [patientId, setPatientId] = useState('')

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    // const [pagination, setPagination] = useState<Pagination>({
    //     start: 1,
    //     limit: 10,
    //     total: 0,
    // })

    useEffect(() => {
        setPatientId(patient_Id || id)
    }, [patient_Id, id])

    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [inpatientPsychiatricId, setInpatientPsychiatricId] = useState<string | null>(null)
    const [inpatientPsychiatric, setInpatientPsychiatric] = useState<InpatientPsychiatric[]>([])

    const getInpatientPsychiatricHistory = async () => {
        try {
            setLoading(true)

            const { data, paging } = await inpatientPsychiatricHistoryApis.getAll(patientId, {
                page,
                size: limit,
            })

            setInpatientPsychiatric(data)
            setTotal(paging.total)
        } catch (ex) {
            notification('Cannot get list inpatientPsychiatric', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleDelete = async (id: string) => {
        try {
            await inpatientPsychiatricHistoryApis.deleteInpatientPsychiatric(patientId, id)

            notification('Inpatient Psychiatric deleted', 'success')
            getInpatientPsychiatricHistory()
            afterActionOk?.()
        } catch (ex) {
            notification('Inpatient Psychiatric not deleted', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getInpatientPsychiatricHistory()
        }

        return () => abortController.abort()
    }, [patientId, page])

    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ position: 'relative', padding: isInClinicalNote ? 0 : '16px 24px ' }}>
            <Loading visible={loading} />

            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId))
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon style={{ color: '#5571C6', fontSize: 18 }} />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid container justifyContent={isInClinicalNote ? 'flex-end' : 'space-between'}>
                    {!isInClinicalNote && (
                        <Typography className={classes.header}>
                            Inpatient Psychiatric History
                        </Typography>
                    )}

                    <ButtonCustom
                        tooltip="Add New Inpatient Psychiatric History"
                        variant="contained"
                        color="primary"
                        disabled={nonEditable}
                        onClick={() => setInpatientPsychiatricId('')}
                        fullWidth>
                        <AddIcon style={{ fontSize: 20 }} />
                    </ButtonCustom>
                </Grid>
                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                <InpatientPsychiatricHistoryDashboard
                    dataSource={inpatientPsychiatric || []}
                    pagination={{ start: page, limit, total }}
                    onPageChange={setPage}
                    sortModel={sortModel}
                    setSortModel={setSortModel}
                    onClickEdit={setInpatientPsychiatricId}
                    onClickDelete={handleDelete}
                    nonEditable={nonEditable}
                />

                <InpatientPsychiatricForm
                    inpatientPsychiatricId={inpatientPsychiatricId}
                    patientId={patientId}
                    encounter_id={encounter_id}
                    open={inpatientPsychiatricId !== null}
                    onClose={(forceReload) => {
                        setInpatientPsychiatricId(null)

                        if (forceReload) getInpatientPsychiatricHistory()
                    }}
                    afterActionOk={afterActionOk}
                />
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(InpationPsychiatricHistory)
