import React from 'react'
import moment from 'moment'

//mui-components
import { Grid, Typography } from '@mui/material'

//svg-icons
import { ReactComponent as ProviderIcon } from '../../icons/provider.svg'
import { ReactComponent as PatientIcon } from '../../icons/patient.svg'
import { ReactComponent as AppointmentTypeIcon } from '../../icons/telehealth.svg'

//utility functions and components
import { appointmentStatus } from '../appointmentStatus'
import { hexToRgb, shortenString } from 'containers/CalendarScheduler/utils'
import StaffCard from '../StaffCard'
import user_add from '../../icons/userAddSelf.png'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

export default function WeekCard({ timeDuration, classes, parentProps }) {
    const statusObject = appointmentStatus(parentProps?.event?.status)
    const isStaff = parentProps?.event?.admin_code_id
    // return (
    //     <Grid item xs={12}>
    //         <Typography noWrap className={classes.item_text}>

    //             {moment(new Date(parentProps.event.start).getTime()).format('hh:mm A')}
    //             {` - `}
    //             {moment(new Date(parentProps.event.end).getTime()).format('hh:mm A')}
    //             <span style={{ marginLeft: 16 }}>
    //                 {parentProps.event.practitioner_first_name || ''}{' '}
    //                 {parentProps.event.practitioner_last_name || ''}
    //             </span>
    //             <span>{` - `}</span>
    //             <span>{parentProps.event.patientName}</span>
    //             <span>{` - `}</span>
    //             <span>{parentProps.event.appointmentType}</span>
    //         </Typography>
    //     </Grid>
    // )
    if (isStaff) {
        return <StaffCard parentProps={parentProps} classes={classes} />
    }
    if (
        timeDuration() === '45-mins' ||
        timeDuration() === 'an-hour' ||
        timeDuration() === 'more-than-an-hour'
    ) {
        return (
            <div style={{ fontSize: '11px !important' }}>
                <Grid style={{ width: '100%' }}>
                    <div
                        className={classes.item_header_container}
                        style={{
                            borderBottom: `3px solid ${
                                parentProps.event.color_tag ||
                                (parentProps.event?.category?.toLowerCase() !== 'patient'
                                    ? '#d9d9d9'
                                    : '#F1F8FF')
                            }`,
                        }}>
                        <span>
                            <Typography
                                noWrap
                                className={`${classes.text_bold}`}
                                style={{ fontSize: '10px' }}>
                                {moment(new Date(parentProps.event.start).getTime()).format(
                                    'hh:mm A',
                                )}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format(
                                    'hh:mm A',
                                )}
                            </Typography>
                        </span>
                        {parentProps.event.self_schedule && (
                            <HtmlTooltip
                                title={'Patient self scheduled'}
                                placement="top"
                                className="notification-tooltip"
                                // open={tooltipOpen}
                                arrow>
                                <img
                                    src={user_add}
                                    width={30}
                                    height={20}
                                    // title="Patient self scheduled"
                                />
                            </HtmlTooltip>
                        )}
                    </div>

                    <Grid
                        item
                        xs={12}
                        className={classes.text_wrapper}
                        style={{ paddingTop: '5px', height: '100%' }}>
                        <div
                            className={`${classes.item_text} ${classes.default_flex_view}`}
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                                gap: 5,
                            }}>
                            <span
                                className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                                <PatientIcon
                                    className={`${classes.card_icon}`}
                                    style={{ fontSize: '9px' }}
                                />
                                <Typography
                                    className={classes.text_bold}
                                    style={{ fontSize: '11px' }}>
                                    {shortenString(parentProps.event.patientName, 15)}
                                </Typography>
                            </span>

                            <span
                                className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                                <ProviderIcon
                                    className={`${classes.card_icon} `}
                                    style={{ fontSize: '9px' }}
                                />

                                <span className={` ${classes.default_flex_view}`}>
                                    <Typography style={{ marginRight: '5px', fontSize: '11px' }}>
                                        {parentProps.event.practitioner_first_name || ''}
                                    </Typography>
                                    <Typography style={{ fontSize: '11px' }}>
                                        {shortenString(
                                            parentProps.event.practitioner_last_name,
                                            8,
                                        ) || ''}
                                    </Typography>
                                </span>
                            </span>

                            <span className={classes.full_width} style={{ marginBottom: '5px' }}>
                                <span
                                    style={{
                                        height: '15px',
                                        width: '15px',
                                        borderRadius: '50%',
                                        background: statusObject?.bgColor,
                                        paddingLeft: '2px',
                                    }}
                                    className={classes.default_flex_view}>
                                    {statusObject?.icon}
                                </span>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    } else if (timeDuration() === '30-mins' || timeDuration() === '20-mins') {
        return (
            <div style={{ width: '110px', fontSize: '11px !important' }}>
                <Grid style={{ width: '100%' }}>
                    {/* <div
                        className={classes.item_header_container}
                        style={{
                            background: 'transparent'
                        }}
                    >

                        <span>
                            <Typography noWrap className={`${classes.text_bold}`} style={{ fontSize: '10px' }}>
                                {moment(new Date(parentProps.event.start).getTime()).format('hh:mm A')}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format('hh:mm A')}
                                
                            </Typography>
                        </span>

                    </div> */}

                    <Grid item xs={12} className={classes.text_wrapper} style={{ height: '100%' }}>
                        <div
                            className={`${classes.item_text} ${classes.default_flex_view}`}
                            style={{
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%',
                            }}>
                            <span>
                                <Typography
                                    noWrap
                                    className={`${classes.text_bold}`}
                                    style={{ fontSize: '10px' }}>
                                    {moment(new Date(parentProps.event.start).getTime()).format(
                                        'hh:mm A',
                                    )}
                                    {` - `}
                                    {moment(new Date(parentProps.event.end).getTime()).format(
                                        'hh:mm A',
                                    )}
                                </Typography>
                            </span>
                            {parentProps.event.self_schedule && (
                                <HtmlTooltip
                                    title={'Patient self scheduled'}
                                    placement="top"
                                    className="notification-tooltip"
                                    // open={tooltipOpen}
                                    arrow>
                                    <img
                                        src={user_add}
                                        width={30}
                                        height={20}
                                        // title="Patient self scheduled"
                                    />
                                </HtmlTooltip>
                            )}
                            <span
                                className={`${classes.item_text} ${classes.default_flex_view} ${classes.full_width}`}>
                                <PatientIcon
                                    className={`${classes.card_icon}`}
                                    style={{ fontSize: '9px' }}
                                />
                                <Typography
                                    className={classes.text_bold}
                                    style={{ fontSize: '11px' }}>
                                    {shortenString(parentProps.event.patientName, 12)}
                                </Typography>
                            </span>

                            {timeDuration() === '30-mins' && (
                                <span
                                    className={classes.full_width}
                                    style={{ marginBottom: '5px' }}>
                                    <span
                                        style={{
                                            height: '15px',
                                            width: '15px',
                                            borderRadius: '50%',
                                            background: statusObject?.bgColor,
                                            paddingLeft: '2px',
                                        }}
                                        className={classes.default_flex_view}>
                                        {statusObject?.icon}
                                    </span>
                                </span>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    } else if (timeDuration() === '15-mins' || timeDuration() === '10-mins') {
        return (
            <div style={{ width: '110px', height: '18px', fontSize: '11px !important' }}>
                <Grid style={{ width: '100%' }}>
                    <div
                        className={classes.item_header_container}
                        style={{
                            background: hexToRgb(
                                parentProps.event.color_tag ||
                                    (parentProps.event?.category?.toLowerCase() !== 'patient'
                                        ? '#d9d9d9'
                                        : '#F1F8FF'),
                            ),
                        }}>
                        <span>
                            <Typography
                                noWrap
                                className={`${classes.text_bold}`}
                                style={{ fontSize: '10px' }}>
                                {moment(new Date(parentProps.event.start).getTime()).format(
                                    'hh:mm A',
                                )}
                                {` - `}
                                {moment(new Date(parentProps.event.end).getTime()).format(
                                    'hh:mm A',
                                )}
                            </Typography>
                        </span>
                        {parentProps.event.self_schedule && (
                            <HtmlTooltip
                                title={'Patient self scheduled'}
                                placement="top"
                                className="notification-tooltip"
                                // open={tooltipOpen}
                                arrow>
                                <img
                                    src={user_add}
                                    width={30}
                                    height={20}
                                    // title="Patient self scheduled"
                                />
                            </HtmlTooltip>
                        )}
                    </div>
                </Grid>
            </div>
        )
    } else return <></>
}
