import { Checkbox } from '@material-ui/core'
import { GridColumns } from '@mui/x-data-grid'
import { PatientContacts } from 'containers/PatientsSetting/interfaces'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import React from 'react'
import { DataGrid } from 'shared/elements/DataGrid'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'

import { v4 as uuid } from 'uuid'

type Props = {
    page: number
    dataSource?: PatientContacts[]
    pagination?: Pagination
    onPageChange?: (page: number) => void
    handleEdit?: (id: string | number) => void
    handleDelete?: (id: string | number) => void
}

export const PatientsContactHistoryDashboard = (props: Props) => {
    // Hooks
    const notification = useNotification()

    const columns: GridColumns<PatientContacts> = [
        {
            headerName: 'Contact Name',
            headerClassName: 'super-app-theme--header',
            field: 'related_person_name',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => <DataGridCell value={value} />,
        },
        {
            headerName: 'RelationShip to Patient',
            headerClassName: 'super-app-theme--header',
            field: 'relation',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => <DataGridCell value={value} />,
        },
        {
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            field: 'related_person_phone',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => <DataGridCell value={value} />,
        },
        {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'related_person_email',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => <DataGridCell value={value} />,
        },
        {
            headerName: 'Emergency Contact',
            headerClassName: 'super-app-theme--header',
            field: 'is_emergency_contact',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => (
                <DataGridCell value={value === '1' || value === 'true' ? 'Yes' : 'No'} />
            ),
        },
        {
            headerName: 'Appointment Notifications',
            headerClassName: 'super-app-theme--header',
            field: 'appointment_notification',
            flex: 1,
            sortable: false,

            renderCell: ({ value }) => <Checkbox checked={value === '1' ? true : false} />,
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
                return (
                    <EditAction
                        menu={[
                            {
                                text: 'Edit',
                                handler: () =>
                                    props?.handleEdit &&
                                    props?.handleEdit(params?.row?.id as string | number),
                            },
                            {
                                text: 'Delete',
                                handler: () =>
                                    props?.handleDelete &&
                                    props?.handleDelete(params?.row?.id as string | number),
                            },
                        ]}
                        deleteMessage="Are you sure you want to delete this record?"
                    />
                )
            },
        },
    ]

    return (
        <DataGrid
            page={props.page}
            onPageChange={props.onPageChange}
            getRowId={(row) => uuid() as string | number}
            rows={props.dataSource || []}
            paginationMode="server"
            columns={columns}
            pageSize={props.pagination?.limit || 10}
            rowCount={props.pagination?.total || 0}
            rowsPerPageOptions={[2]}
        />
    )
}
