import { Link, Typography } from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import { UserRoles } from '../../constants/user-roles'
import { GridHeaderEl } from '../../shared/elements'

export function generateListColumns(
    role,
    loggedInRole,
    onSort,
    onRowClick,
) {
    const columns = [
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Date"
                    field="appointment_date"
                    onSort={onSort}
                    {...values}
                />
            ),
            field: 'date',
            flex: 1,
            sortable: false,
            renderCell: (cell) => {
                return moment
                    .utc(`${cell.value} ${cell.row.start}`)
                    .local()
                    .format('MM/DD/YYYY')
            },
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Time"
                    field="time"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'time',
            flex: 1,
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Specialty"
                    field="speciality_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            hide: role === UserRoles.PROVIDER,
            sortable: false,
            field: 'speciality_name',
            flex: 1,
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Provider"
                    field="provider_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            field: 'provider_name',
            flex: 1.2,
            sortable: false,
            hide: role === UserRoles.PROVIDER,
            renderCell: (params) =>
                role !== UserRoles.PATIENT ? (
                    <Link
                        noWrap
                        component="button"
                        color={'primary'}
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(
                                params.row.provider.user_id,
                                UserRoles.PROVIDER,
                            )
                        }>
                        {params.value}
                    </Link>
                ) : (
                    <Typography noWrap>
                        {params.value}
                    </Typography>
                ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Patient"
                    field="patient_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            field: 'patient_name',
            flex: 1.2,
            sortable: false,
            hide: loggedInRole === UserRoles.PATIENT,
            renderCell: (params) => (
                <Link
                    noWrap
                    component="button"
                    color={'primary'}
                    className="capitalize"
                    underline="none"
                    onClick={() =>
                        onRowClick(
                            params.row.parent_user_id,
                            UserRoles.PATIENT,
                        )
                    }>
                    {params.value}
                </Link>
            ),
        },
    ]

    return columns
}

export function generateCardColumns(
    role,
    loggedInRole,
    onRowClick,
) {
    const columns = [
        {
            headerName: (
                <Typography variant="body2">
                    Date
                </Typography>
            ),
            field: 'date',
            flex: 1,
            sortable: false,
            renderCell: (cell) => {
                return moment
                    .utc(`${cell.value} ${cell.row.start}`)
                    .local()
                    .format('MM/DD/YYYY')
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Time
                </Typography>
            ),
            sortable: false,
            field: 'time',
            flex: 1.2,
        },
        {
            headerName: (
                <Typography variant="body2">
                    Specialty
                </Typography>
            ),
            sortable: false,
            field: 'speciality_name',
            flex: 1,
            hide: role === UserRoles.PROVIDER,
        },
        {
            headerName: (
                <Typography variant="body2">
                    Provider
                </Typography>
            ),
            field: 'provider_name',
            flex: 1,
            sortable: false,
            hide: role === UserRoles.PROVIDER,
            renderCell: (params) =>
                role !== UserRoles.PATIENT ? (
                    <Link
                        noWrap
                        component="button"
                        color={'primary'}
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(
                                params.row.provider.user_id,
                                UserRoles.PROVIDER,
                            )
                        }>
                        {params.value}
                    </Link>
                ) : (
                    <Typography noWrap variant="body2">
                        {params.value}
                    </Typography>
                ),
        },
        {
            headerName: (
                <Typography variant="body2">
                    Patient
                </Typography>
            ),
            field: 'patient_name',
            flex: 1.5,
            sortable: false,
            hide: loggedInRole === UserRoles.PATIENT,
            renderCell: (params) => (
                <Link
                    noWrap
                    component="button"
                    color={'primary'}
                    className="capitalize"
                    underline="none"
                    onClick={() =>
                        onRowClick(
                            params.row.parent_user_id,
                            UserRoles.PATIENT,
                        )
                    }>
                    {params.value}
                </Link>
            ),
        },
    ]

    return columns
}
