import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Select, MenuItem, Button,Box, Dialog, DialogContent, DialogTitle } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'
import { ExpandMore } from '@material-ui/icons'
import TextWithMacrosNew from './TextWithMacrosNew'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import DescriptionIcon from '@mui/icons-material/Description'
import { useNotification } from 'shared/elements/Notification'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import copyNew from '../../../assests/icons/copyNew.svg'
import edit_icon from '../../../assests/icons/edit_icon.svg'
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';


const StyledCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: "#fff !important"
  },
  
});
const useStyles = makeStyles({
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    btn__macro: {
        color: '#667689', 
    },
    box_type:{
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '10px',
    },
    rootModal: {
        '& .MuiPaper-root': {
            minWidth: '1040px',
            borderRadius: "5px",
            // height: '50%',
            // position: 'relative',
        },
        '& .MuiDialogContent-root': {
            // overflow: 'scroll',
            overflow: 'auto',
            padding:"0px",
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiDialogTitle-root': {
            padding: 0
        }
    },
    headerModal:{
        color: "#FFF",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "140%",
        padding:"7px 0px 7px 15px",
    },
    applyButton:{
        width: "130px",
        height: "15px",
        padding: "6px 12px",
        borderRadius: "5px",
        border: "1px solid #128D47",
        background:"#15A452",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        margin:"0px 10px 0px 10px",
        color:"#fff",
        fontSize:"14px",
        fontWeight:"500",
        cursor:"pointer"
    },
})

const PreviousCN = ({
    onChange,
    fieldName,
    onGenerateClick,
    meta,
    values,
    placeholder = '',
    nonEditable,
    open,
    handleClose,
    categoryName,
    lastNarrative,
    narrativeText,
    handleChangecopy,
    lastUpdateDate,
    customCheckAllValue=null,
    customCheckAllHandler=null,
    ...props
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedMacro, setSelectedMacro] = useState('')
    const [checked, setChecked] = useState(true)
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    return (
            <Dialog
                open={open}
                sx={{
                    '& .MuiDialog-paperFullWidth': {
                        minWidth: '1463px',
                    },
                }}
                fullWidth={true}
                className={classes.rootModal}
                onClose={handleClose}>
                <DialogTitle>
                    <Box
                        style={{
                            height: '36px',
                            borderRadius: '5px 5px 0px 0px',
                            border: '1px solid #3459CA',
                            background: '#5571C6',
                        }}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                            <Typography className={classes.headerModal}>
                                Viewing: {categoryName} {lastUpdateDate}
                            </Typography>

                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <StyledCheckbox
                                        checked={customCheckAllValue ?? checked}
                                        size="small"
                                        onChange={(e)=>{
                                            if(customCheckAllValue !== null){ //null check is required to check if the value is passed as props or not
                                                customCheckAllHandler(e.target.checked)
                                                return
                                            }
                                            setChecked(e.target.checked)
                                        }}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                    />
                                    <Typography
                                        className={classes.headerModal}
                                        style={{ fontWeight: '400', paddingLeft: '7px' }}>
                                        Copy all content
                                    </Typography>
                                    <Typography className={classes.applyButton} style={{opacity: !checked ? "0.8" : null}} onClick={()=>{if(checked){handleChangecopy()}}}>
                                        Apply Selections
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                </DialogTitle>
                <DialogContent>

                    {props?.children}

                    <Box className={classes.box_type}>
                        {narrativeText &&
                            <Typography
                                style={{
                                    color: '#7D8793',
                                    fontFamily: 'Rubik',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    paddingBottom: '10px',
                                }}>
                                    {narrativeText}
                            </Typography>
                        }
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="cetner"
                            style={{
                                borderRadius: '5px 5px 0px 0px',
                                border: '1px solid #EAECF0',
                                padding: '2px',
                                background: '#FFF',
                            }}>
                            <Typography style={{ padding: '9px 0px 0px 17px' }}>
                                Narrative
                            </Typography>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Box
                                        sx={{
                                            '& .MuiSelect-selectMenu': {
                                                background: '#F3F6F9',
                                                padding: '7px 30px 7px 16px',
                                                borderRadius: '6px',
                                            },
                                            '& .MuiInput-root': {
                                                marginRight: '8px',
                                            },
                                        }}>
                                        <Select
                                            disableUnderline
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                },
                                                getContentAnchorEl: null,
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 505,
                                                        maxWidth: 300,
                                                        marginTop: 8,
                                                    },
                                                },
                                            }}
                                            IconComponent={ExpandMore}
                                            name="macro"
                                            onChange={(e) => {
                                                setSelectedMacro(e.target.value)
                                            }}
                                            value={selectedMacro}
                                            displayEmpty
                                            disabled={true}
                                            renderValue={(sel) =>
                                                sel ? (
                                                    <span
                                                        className={classes.btn__macro}
                                                        style={{
                                                            display: 'flex',
                                                            alignItem: 'center',
                                                            paddingRight: '3px',
                                                        }}>
                                                        <img
                                                            src={edit_icon}
                                                            alt=""
                                                            style={{ paddingRight: '3px' }}
                                                        />
                                                        Macros:{' '}
                                                        {
                                                            macrosData.find(
                                                                (item) => item.id === sel,
                                                            )?.name
                                                        }
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={classes.btn__macro}
                                                        style={{
                                                            display: 'flex',
                                                            alignItem: 'center',
                                                            paddingRight: '3px',
                                                        }}>
                                                        <img
                                                            src={edit_icon}
                                                            alt=""
                                                            style={{ paddingRight: '3px' }}
                                                        />
                                                        Macros
                                                    </span>
                                                )
                                            }>
                                            {macrosData?.map((m) => (
                                                <MenuItem
                                                    onClick={() => handleSelectMacro(m.description)}
                                                    className={classes.menu__item}
                                                    key={m.id}
                                                    value={m.id}>
                                                    <Typography className={classes.btn__macro}>
                                                        {m.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TextWithMacrosNew
                            // name={`${fieldName}.narrative`}
                            // onChange={onChange}
                            // macros={macros}
                            isDisabled={true}
                            rows={12}
                            values={lastNarrative}
                            placeholder={placeholder}
                        />
                    </Box>

                </DialogContent>
            </Dialog>
    )
}

export default PreviousCN
