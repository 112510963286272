import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {
    makeStyles,
    Typography,
    Button,
    Grid,
} from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiPaper-root': {
            width: 508,
            alignItems: 'center',
            borderRadius: 10,
            padding: '16px 0',
        },
    },
    description: {
        color: '#667689',
        fontSize: 13,
    },
    header: {
        color: '#303E4E',
        fontWeight: 500,
        fontSize: 22,
        padding: 8,
    },
    btn_cancel: {
        background: '#303E4E',
        width: '230px',
        fontSize: 15,
        borderRadius: 8,
        fontWeight: 'normal',
    },
    btn_delete: {
        fontSize: '15px !important',
        width: '230px',
        borderRadius: '8px !important',
        fontWeight: 'normal',
    },
}))

export default function AlertCancelDialog({
    open,
    handleClose,
    handleDelete,
    loading,
}) {
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className={classes.root}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle>
                <Typography className={classes.header}>
                    Do you want to discard change?
                </Typography>
            </DialogTitle>
            {/* <DialogContent>
                <DialogContentText>
                    <Typography
                        className={classes.description}>
                        Do you want to discard change?
                    </Typography>
                </DialogContentText>
            </DialogContent> */}
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={handleClose}
                    variant="contained"
                    className={classes.btn_cancel}>
                    No
                </Button>
                <LoadingButton
                    loading={loading}
                    disabled={loading}
                    loadingPosition="start"
                    startIcon={<></>}
                    onClick={handleDelete}
                    autoFocus
                    variant="contained"
                    className={classes.btn_delete}>
                    Yes
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}
