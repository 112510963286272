import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { LoadingButton } from '@mui/lab'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'

import { getAccountById } from 'services/Calendar'

import { INITIAL_VALUES } from './schema'
import Staff from './Views/Staff'

import LoadingPage from 'utilities/loading-page'
import './styles.scss'

const useStyles = makeStyles(() => ({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    container: {
        position: 'absolute',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
        padding: '16px 24px',
    },
    form: {
        // marginTop: 8,
        height: 'calc(100% - 40px)',
        margin: 'auto',
        width: 'fit-content',
        // background: '#FBFCFC',
        // border: '1px solid #F3F6F9',
        // borderRadius: 10,
        // padding: '32px 40px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },
}))

function CreateDocumentDialog({ changePassDialogToggle, ...props }) {
    const classes = useStyles()
    const [accountData, setAccountData] = useState(INITIAL_VALUES)
    const { itemId, loading, isViewOnly } = props
    const [isLoading, setIsLoading] = useState(false)

    useEffect(async () => {
        try {
            if (itemId) {
                setIsLoading(true)
                const response = await getAccountById(itemId)
                setIsLoading(false)
                setAccountData(response.data)
            }
        } catch (err) {
            setIsLoading(false)
            console.error('Cannot fetch user security', err)
        }
    }, [itemId])

    const validationSchema = yup.object({
        associated_patient: yup.string().required('Patient is required!'),
    })
    const formik = useFormik({
        initialValues: accountData,
        enableReinitialize: true,
        validationSchema,
        onSubmit: props.onSubmit,
    })

    if (isLoading) {
        return <LoadingPage />
    }

    return (
        <Grid container className={classes.container} direction="column" wrap="nowrap">
            <Grid
                container
                onClick={() => props.onModalClose()}
                style={{
                    cursor: 'pointer',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Document List</Typography>
            </Grid>

            <Grid container className={classes.form}>
                <Grid
                    container
                    direction="column"
                    style={{ height: '100%' }}
                    wrap="nowrap"
                    spacing={2}>
                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        direction="column"
                        style={{
                            overflowY: 'auto',
                            height: '100%',
                            flexWrap: 'nowrap',
                            overflowX: 'hidden',
                        }}>
                        <Staff
                            isViewOnly={isViewOnly}
                            touched={formik.touched}
                            errors={formik.errors}
                            values={formik.values}
                            setFieldValue={formik.setFieldValue}
                            changePassDialogToggle={changePassDialogToggle}
                        />
                    </Grid>

                    {!isViewOnly && (
                        <Grid
                            container
                            item
                            justifyContent="flex-end"
                            direction="row"
                            spacing={2}
                            style={{
                                marginTop: 24,
                            }}>
                            <Grid item xs="6">
                                <Button
                                    variant="contained"
                                    className={classes.button_cancel}
                                    disabled={loading}
                                    onClick={() => props.onModalClose()}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs="6">
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition={'start'}
                                    disabled={loading}
                                    variant="contained"
                                    className={classes.button_create}
                                    onClick={formik.handleSubmit}>
                                    Save
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CreateDocumentDialog
