import { Grid, makeStyles,MenuItem, Select, Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import { Labs } from 'containers/PatientsSetting/demographic/Labs'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { allApiLabOrder } from 'services/Calendar'
import { useNotification } from 'shared/elements/Notification'
import { LabOrdersPatient } from 'containers/PatientsSetting/view/LabOrdersPatient'
import { convertFullName } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'
import TextWithMacro from '../reusable/TextWithMacro'
import { ExpandMore } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { getLabResultStatus } from 'utilities/labResults'

export const useStyles = makeStyles({
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    btn__macro: {
        color: '#667689',
        width: "289px",
    },
    CentralizedMacrosText:{
        color: "#667689",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
        margin: "10px 7px 7px 7px"
    },
    container: {
        overflow: 'auto',
        top: 0,
        zIndex: 1,
        left: 0,
        background: 'white',
        height: '100%',
    },
    itemCard: {
        width: 400,
        height: 500,
        margin: 0,
    },
    wrap_data_grid: {
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    fontWeight: 500,
                    alignItems: 'center',
                    color: '#303E4E',
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 267px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
        },
        '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            margin: '4px 0',
            borderRadius: '8px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
})

const PlanLabOrder = ({
    patientId,
    encounter_id,
    handleSetViewHealthyRecord,
    fieldName,
    onChange,
    values,
    macros,
    nonEditable=false
}) => {
    const classes = useStyles()
    const history = useHistory()
    const classesCard = usePatientDashboardStyles()
    const [sortModel, setSortModel] = useState([])
    const [viewDetail, setViewDetail] = useState(false)
    const [listLabOrder, setListLabOrder] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isDownload, setIsDownload] = useState()
    const [page, setPage] = useState(1)
    const notification = useNotification()
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 0,
        limit: 10,
    })
    const [selectedMacro, setSelectedMacro] = useState('')
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]
    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values?.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    const getLabOrderByPatient = async (patientId) => {
        setLoading(true)
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId)

            const formatData =
                res.data?.data.map((item) => ({
                    id: item.id,
                    requisition_number: item.placer_order_number,
                    lab_orderred: item.lab_code,
                    order_date: item.creation_datetime,
                    status: getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status),
                    orderring_provider: item.sponsor_name,
                    requisition_pdf: item.pdfUrl || null,
                    results: item.htmlUrl || null,
                })) || []

            setListLabOrder(formatData)
            if (res.data?.paging) {
                setPaginationData({
                    total: res.data.paging?.total || 0,
                    start: res.data.paging?.page || 0,
                    limit: res.data.paging?.limit || 10,
                })
            }
        } catch (error) {
            console.log(error)
            notification('Get Lab order error', 'error')
        } finally {
            setLoading(false)
        }
    }

    const handlePageChange = (value) => {
        setPage(+value - 1)
    }

    // useEffect(() => {
    //     const abortController = new AbortController()
    //     if (!!patientId) getLabOrderByPatient(patientId)
    //     return () => abortController.abort()
    // }, [page, sortModel, patientId])

    const getAllLabOrderByPatient = async () => {
        try {
            const res = await allApiLabOrder.getByIdPatient(patientId, 0, 100000)
            const list = res.data.data.map((item) => ({
                ...item,
                sponsor_name: convertFullName(
                    item.provider_first_name,
                    item.provider_middle_name,
                    item.provider_last_name,
                ),
                lab_code: `${item.lab_code} - ${item.sponsor_name}`,
                report_service_date: formatDateTime(item.creation_datetime),
                report_status: (() => {
                    return getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status)
                })(),
                requisition: item.requisitionUrl ? 'View detail' : '',
                results: (() => {
                    if (item.pdfUrl && item.htmlUrl) {
                        return 'HTML | PDF'
                    }
                    if (item.htmlUrl) {
                        return 'HTML'
                    }
                    if (item.pdfUrl) {
                        return 'PDF'
                    }
                })(),
            }))
            onChange(`${fieldName}.lab_ordered_list`, list)
        } catch (error) {
            notification('Get Lab order error', 'error')
        }
    }

    useEffect(() => {
        if (patientId) {
            getAllLabOrderByPatient()
        }
    }, [patientId])

    return (
        <Box className={classes.container}>
            <LabOrdersPatient isInClinicalNote patient_Id={patientId} encounter_id={encounter_id} nonEditable={nonEditable} />
            <Grid container justifyContent="space-between" alignItems="cetner">
                <Typography className={classes.narrative} style={{marginTop:"10px"}}>Narrative</Typography>
                <Grid item>
                    <Grid container alignItems="center">
                        <Box
                            sx={{
                                '& .MuiSelect-selectMenu': {
                                    background: '#F3F6F9',
                                    padding: '7px 30px 7px 16px',
                                    borderRadius: '6px',
                                },
                                '& .MuiInput-root': {
                                    marginRight: '8px',
                                },
                            }}>
                            <Select
                                disableUnderline
                                disabled={nonEditable}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 505,
                                            maxWidth: 300,
                                            marginTop: 8,
                                        },
                                    },
                                }}
                                IconComponent={ExpandMore}
                                name="macro"
                                onChange={(e) => {
                                    setSelectedMacro(e.target.value)
                                }}
                                value={selectedMacro}
                                displayEmpty
                                renderValue={(sel) =>
                                    sel ? (
                                        <span className={classes.btn__macro}>
                                            Insert Macro:{' '}
                                            {allMacros.find((item) => item.name === sel)?.name}
                                        </span>
                                    ) : (
                                        <span className={classes.btn__macro}>Insert Macro</span>
                                    )
                                }>
                                <Typography className={classes.CentralizedMacrosText}>
                                    Personalized Macros
                                </Typography>
                                {macrosPractitionerData?.map((m) => (
                                    <MenuItem
                                        onClick={() => {
                                            handleSelectMacro(m.description)
                                        }}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={m.name}>
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                                <Typography
                                    className={classes.CentralizedMacrosText}
                                    style={{ borderTop: '1px solid #DEE2E6', paddingTop: '12px' }}>
                                    Centralized Macros
                                </Typography>
                                {macrosData?.map((m) => (
                                    <MenuItem
                                        onClick={() => handleSelectMacro(m.description)}
                                        className={classes.menu__item}
                                        key={m.id}
                                        value={m.name}>
                                        <Typography className={classes.btn__macro}>
                                            {m.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <TextWithMacro
                name={`${fieldName}.narrative`}
                onChange={onChange}
                macros={macros}
                rows={12}
                values={values?.narrative}
                isDisabled={nonEditable}
            />
        </Box>
    )
}

export default PlanLabOrder
