import { optionTZ } from "containers/SchedulerMenu/constant"

export const timezoneFormatter = (key) => {
    let formattedTimezone = null
    const data = optionTZ.find((item) => item?.key === key)
    const filteredData = data?.value?.split("-")
    if (filteredData) {
        formattedTimezone = filteredData[0].trimEnd()
    }
    return formattedTimezone
}