/* eslint-disable react/button-has-type */
import {
    Grid,
    ListItem,
    Typography,
} from '@material-ui/core'
import CheckIcon from '@mui/icons-material/Check'
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import ButtonSpinner from '../../../../containers/Consultation/components/ButtonSpinner'

const AssistantPractitionerList = ({
    assistantPractitionerList,
    handleAssign,
    assignedProvider,
}) => {
    const useStyles = makeStyles({
        avatar: {
            width: 45,
            height: 45,
            borderRadius: 45,
            background: 'gray',
        },
        listItem: {
            width: '100%',
            padding: 16,
            display: 'flex',
            justifyContent: 'space-between',
            background: '#F5F6F7',
            borderRadius: 10,
            marginBottom: 12,
        },
        iconLoading: {
            position: 'absolute',
            top: 8,
            left: 5,
        },
        relationship: {
            color: '#9CA9B8',
            fontSize: 13,
        },
        name: {
            color: '#303E4E',
            fontSize: 16,
        },
        information: {
            marginLeft: 16,
        },
    })

    const classes = useStyles()

    return (
        <div className="view-list-consultation">
            {assistantPractitionerList?.map(
                (item, index) => {
                    return (
                        <ListItem
                            key={index}
                            className={classes.listItem}>
                            <Grid
                                container
                                alignItems="center">
                                <img
                                    src={
                                        'https://sonora-s3-user-uploads.s3.us-west-2.amazonaws.com/identity/242/14b5f1a1-4140-42da-986c-5a2f4b52886b_dummy-user.png'
                                    }
                                    alt="Profile"
                                    className={
                                        classes.avatar
                                    }
                                />
                                <div
                                    className={
                                        classes.information
                                    }>
                                    <Typography
                                        className={
                                            classes.name
                                        }>
                                        {`${item?.first_name} ${item?.last_name}`}
                                    </Typography>
                                    <Typography
                                        className={
                                            classes.relationship
                                        }>
                                        {item?.title ||
                                            'Provider'}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid>
                                {assignedProvider?.isCompleted &&
                                    assignedProvider?.assignedProviderId ===
                                        item.practitioner_id && (
                                        <Grid
                                            container
                                            style={{
                                                minWidth:
                                                    '81px',
                                                fontFamily:
                                                    'Rubik',
                                                fontStyle:
                                                    'normal',
                                                fontWeight: 400,
                                                fontSize:
                                                    '14px',
                                                lineHeight:
                                                    '140%',
                                                color: '#14AE5C',
                                            }}
                                            direction="row"
                                            alignItems="end">
                                            <CheckIcon
                                                fontSize={
                                                    'small'
                                                }
                                            />
                                            Assigned
                                        </Grid>
                                    )}
                                {(!assignedProvider ||
                                    !assignedProvider?.isCompleted ||
                                    assignedProvider?.assignedProviderId !==
                                        item.practitioner_id) && (
                                    <ButtonSpinner
                                        isLoading={
                                            assignedProvider?.isLoading &&
                                            assignedProvider?.assignedProviderId ===
                                                item.practitioner_id
                                        }
                                        disabled={
                                            !!assignedProvider &&
                                            assignedProvider?.assignedProviderId !==
                                                item.practitioner_id
                                        }
                                        label="Assign"
                                        onClick={() =>
                                            handleAssign(
                                                item,
                                            )
                                        }
                                    />
                                )}
                            </Grid>
                        </ListItem>
                    )
                },
            )}
        </div>
    )
}

export default AssistantPractitionerList
