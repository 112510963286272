import {
    Grid,
    Radio,
    Typography,
    Checkbox,
    FormControlLabel,
    FormGroup,
    RadioGroup,
} from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect } from 'react'
import TextWithMacro from '../reusable/TextWithMacro'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import RadioOptions from '../reusable/RadioOption'
import CheckboxOption from '../reusable/CheckboxOptions'

const data = [
    {
        id: 1,
        name: 'Family',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
        yes: false,
    },
]

function SessionHomework({ macros, values, onChange, read_only, onClear, fieldName, nonEditable }) {
    const columns = [
        {
            headerName: <></>,
            field: 'name',
            flex: 1.2,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Typography variant="subtitle1" className="break-textspace" variant="body1">
                        <b>Amount of homework completed</b>
                    </Typography>
                )
            },
        },
        {
            headerName: (
                <Typography variant="subtitle1" className="break-textspace" variant="body2">
                    No (less than 10%)
                </Typography>
            ),
            flex: 1,
            field: 'none',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ value }) => {
                return (
                    <Radio
                        checked={values?.amt_hmwrk_completed === 'No'}
                        onChange={(event) =>
                            onChange('last_session_hmwrk.amt_hmwrk_completed', event.target.value)
                        }
                        value={'No'}
                        disabled={read_only}
                        color="primary"
                    />
                )
            },
        },
        {
            headerName: (
                <Typography variant="subtitle1" className="break-textspace" variant="body2">
                    Minimal (10-25%)
                </Typography>
            ),
            field: 'mild',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Radio
                        checked={values.amt_hmwrk_completed === 'Minimal'}
                        onChange={(event) =>
                            onChange('last_session_hmwrk.amt_hmwrk_completed', event.target.value)
                        }
                        value={'Minimal'}
                        color="primary"
                        disabled={read_only}
                    />
                )
            },
        },
        {
            headerName: (
                <Typography variant="subtitle1" className="break-textspace" variant="body2">
                    Partly ( 26-50%)
                </Typography>
            ),
            field: 'moderate',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Radio
                        checked={values.amt_hmwrk_completed === 'Partly'}
                        onChange={(event) =>
                            onChange('last_session_hmwrk.amt_hmwrk_completed', event.target.value)
                        }
                        value={'Partly'}
                        color="primary"
                        disabled={read_only}
                    />
                )
            },
        },
        {
            headerName: (
                <Typography variant="subtitle1" className="break-textspace" variant="body2">
                    Mostly (51-75%)
                </Typography>
            ),
            field: 'severe',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Radio
                        checked={values.amt_hmwrk_completed === 'Mostly'}
                        onChange={(event) =>
                            onChange('last_session_hmwrk.amt_hmwrk_completed', event.target.value)
                        }
                        value={'Mostly'}
                        color="primary"
                        disabled={read_only}
                    />
                )
            },
        },
        {
            headerName: (
                <Typography variant="subtitle1" className="break-textspace" variant="body2">
                    Yes (76-100%)
                </Typography>
            ),
            field: 'yes',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Radio
                        checked={values.amt_hmwrk_completed === 'Yes'}
                        onChange={(event) =>
                            onChange('last_session_hmwrk.amt_hmwrk_completed', event.target.value)
                        }
                        value={'Yes'}
                        color="primary"
                        disabled={read_only}
                    />
                )
            },
        },
    ]

    const [narrative, setNarrative] = React.useState('')
    function generatNarrative(macro) {
        let text = ''
        switch (values.amt_hmwrk_completed) {
            case 'No':
                text = 'No assigned homework was completed (less than 10%). '
                break
            case 'Minimal':
                text = 'Minimal assigned homework was completed (between 10% and 25%). '
                break
            case 'Partly':
                text = 'Assigned homework was partially complete (between 26% and 50%). '
                break
            case 'Mostly':
                text = 'Assigned homework was mostly complete (between 51% and 75%). '
                break
            case 'Yes':
                text = 'Assigned homework was fully or nearly complete (between 76% and 100%). '
                break
            default:
                break
        }
        text = text.concat(
            `${
                values?.hmwrk_not_assigned
                    ? ` There was no homework from last session to complete as there was no homework assigned.`
                    : ''
            }${values.add_comments ? ` ${values.add_comments}.` : ''}`,
        )
        setNarrative(text)
        onChange('last_session_hmwrk.narrative', `${text} \n ${macro}`)
    }

    function handleChanges(name, value) {
        onChange(name, value)
    }

    const master = [
        {
            name: 'No (less than 10%)',
            _id: 1,
            value: 'No',
        },
        {
            name: ' Minimal (10-25%)',
            _id: 2,
            value: 'Minimal',
        },
        {
            name: 'Partly ( 26-50%)',
            _id: 3,
            value: 'Partly',
        },
        {
            name: 'Mostly (51-75%)',
            _id: 4,
            value: 'Mostly',
        },
        {
            name: 'Yes (76-100%)',
            _id: 5,
            value: 'Yes',
        },
    ]

    return (
        <Grid container className="MuiDataGrid-table-wrapper bg-white">
            <Typography
                style={{
                    color: '#303E4E',
                    fontSize: 14,
                    fontWeight: '500',
                }}>
                Amount of homework completed
            </Typography>

            <Grid
                container
                style={{
                    marginTop: '16x',
                    borderBottom: '1px solid #E9ECF1',
                    paddingBottom: 16,
                    marginBottom: 16,
                    paddingTop: 8,
                }}>
                <RadioGroup name={'name'} row={true} onChange={onChange}>
                    <Grid>
                        {master.map((el, i) => (
                            <Grid item key={i}>
                                <FormControlLabel
                                    control={<Radio color="primary" />}
                                    label={<Typography>{el.name}</Typography>}
                                    onChange={(event) => {
                                        onChange(
                                            'last_session_hmwrk.amt_hmwrk_completed',
                                            event.target.value,
                                        )
                                    }}
                                    disabled={nonEditable}
                                    value={el.value}
                                    checked={values.amt_hmwrk_completed === el.value}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </RadioGroup>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <CheckboxOption
                    option={{
                        label: 'Homework not assigned',
                        value: values?.hmwrk_not_assigned,
                    }}
                    onChange={(e) =>
                        onChange('last_session_hmwrk.hmwrk_not_assigned', e.target.checked)
                    }
                    checked={values?.hmwrk_not_assigned}
                    disabled={nonEditable}
                />
            </Grid>
            <Grid>
                <Typography
                    style={{
                        color: '#303E4E',
                        fontSize: 14,
                        fontWeight: '500',
                    }}>
                    Additional comments
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextWithMacro
                    rows={12}
                    macros={macros}
                    onChange={onChange}
                    values={values.add_comments}
                    name="last_session_hmwrk.add_comments"
                    // isDisabled={read_only}
                    isDisabled={nonEditable}
                />
            </Grid>
            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleChanges}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default React.memo(SessionHomework)
