import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    notchedOutline: {
        borderColor: `#E9ECF1 !important`,
    },
    oulinedInput: {
        '& .MuiInputLabel-outlined': {
            zIndex: 1,
            pointerEvents: 'none',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: `translate(14px, -50%) scale(1)`,
            fontSize: `14px`,
            fontWeight: 400,
            '&.MuiInputLabel-shrink': {
                fontWeight: 500,
                transform: `translate(3px, -150%) scale(0.8)`,
                top: `calc(50% + 2px)`,
            },
        },
    },
    root: {
        background: '#FBFCFC',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '&:focus': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: `#E9ECF1 !important`,
            },
        },
        '& .MuiSelect-select': {
            backgroundColor: 'unset',
        },

        '& .MuiInputBase-input': {
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            paddingRight: '20px !important',
        },
        '& .MuiMenu-paper': {
            boxShadow:
                '0px 5px 20px rgba(165, 172, 181, 0.15) !important',
            borderRadius: 8,
            padding: '8px 0',
            width: 224,
            marginTop: 46,
        },
        '& .MuiSvgIcon-root': {
            fontSize: 20,
            // marginTop: -2,
            color: '#303E4E',
        },
    },

    // icon: {
    //     fontSize: 20,
    //     // marginTop: -2,
    //     color: '#303E4E',
    // },
    // text: {
    //     color: '#667689',
    //     fontSize: 14,
    // },
}))
