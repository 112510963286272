import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import { Button as ButtonCustom } from 'components/custom'
import Routes from 'constants/routes'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    deleteCheckList,
    getCheckList
} from 'services/Setting'
import { NoRowsOverlayEl } from 'shared/elements'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { useStyles } from './styles'
export const ReferringCheckList = () => {
    const notification = useNotification()
    const classes = useStyles()
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [checkList, setCheckList] = useState([])
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const limit = 10
    const handleEditClick = (row) => {
        history.push(Routes.SETTINGS.REFERRAL_CHECKLIST_EDIT.replace(':id', row.id))
    }
    const refetchCheckList = async () => {
        setLoading(true)
        try {
            const res = await getCheckList(
                sortModel[0]?.sort
                    ? {
                        page,
                        limit,
                        [sortModel[0].sort]: sortModel[0].field,
                    }
                    : {
                        page,
                        limit,
                    },
            )
            const convertData = res?.data?.data?.checklist?.map((item) => ({
                id: item?.id,
                title: item?.title,
                clinical_pratice: item?.ms_clinical_pratice?.name,
                specialty: item?.ms_specialty?.specialty,
            }))
            setLoading(false)
            setCheckList(convertData || [])
            setTotal(res?.data?.paging?.total)
        } catch (e) {
            notification('Something went wrong !', 'error')
            setLoading(false)
        }
    }
    const handleDeleteCheckList = async (id) => {
        try {
            await deleteCheckList(id)
            notification('CheckList is deleted successfully!', 'success')
            refetchCheckList()
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const columns = [
            {
                headerName: 'Checklist Title ',
                headerClassName: 'super-app-theme--header',
                field: 'title',
                flex: 1,
                sortable: true,
                renderCell: ({ value }) => {
                    return value
                },
            },
            {
                headerName: 'Specialty',
                headerClassName: 'super-app-theme--header',
                field: 'specialty',
                flex: 1,
                sortable: true,
                renderCell: ({ value }) => {
                    return value || '-'
                },
            },
            {
                headerName: 'Clinical Practice',
                headerClassName: 'super-app-theme--header',
                field: 'clinical_pratice',
                flex: 0.7,
                sortable: true,
                renderCell: ({ value }) => {
                    return value || '-'
                },
            },

            {
                headerName: 'Actions',
                headerClassName: 'super-app-theme--header',
                field: 'id',
                // width: 100,
                flex: 0.5,
                sortable: false,
                // hide:
                // !permissions.includes('delete_referring_provider') &&
                // !permissions.includes('modify_referring_provider'),
                renderCell: ({ row }) => {
                    const menu = []
                    menu.push({
                        text: 'Edit',
                        handler: () => handleEditClick(row),
                    })
                    menu.push({
                        text: 'Delete',
                        handler: () => handleDeleteCheckList(row.id),
                    })
                    return (
                        <EditAction
                            menu={menu}
                            deleteMessage="Are you sure you want to delete this checklist item?"
                        />
                    )
                },
            },
        ]
    const handlePageChange = (value) => {
        setPage(value)
    }
  
   useEffect(() => {
       const abortController = new AbortController()
       refetchCheckList()
       return () => abortController.abort()
   }, [page, sortModel])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" flexDirection={'column'} gap={1}>
                    <Typography className={classes.header}>Referring Checklist</Typography>
                    {/* <RBACProvider permissions={permissions}>
                        <RBACWrapper requiredPermissions={['create_referring_provider']}> */}
                    <Grid
                        item
                        display="flex"
                        justifyContent="flex-end"
                        className={classes.add_new_button}>
                        <ButtonCustom
                            tooltip="Add new checklist"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push(Routes.SETTINGS.REFERRAL_CHECKLIST_NEW)}
                            fullWidth>
                            <AddIcon
                                style={{
                                    fontSize: 20,
                                }}
                            />
                        </ButtonCustom>
                    </Grid>
                    {/* </RBACWrapper>
                    </RBACProvider> */}
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        rows={checkList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>

            {/* {appointmentModal.status && (
                <ReferringProviderForm
                    open={appointmentModal.status}
                    optionServiceLocation={optionServiceLocation}
                    optionSpecialty={optionSpecialty}
                    refferringItem={appointmentModal.refferringItem}
                    loading={isSubmitting}
                    onModalClose={appointmentModalToggle}
                    onSubmit={(payload) => hanleSubmid(payload)}
                />
            )} */}
        </Grid>
    )
}
