import { Box, Button, CircularProgress, Collapse, Dialog, Divider, IconButton, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import cross_icon from 'assests/icons/cross_icon.svg'
import { LoadingButton } from '@mui/lab'
import { useNotification } from 'shared/elements/Notification'
import Form from './Form'
import Preview from './Preview'
import { previewFaxData } from 'services/Patient/patient'

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& .MuiPaper-root': {
                height: '814px;',
                position: 'relative',
            },
            '& .MuiDialogContent-root': {
                overflow: 'auto',
            },
            '& .MuiDialog-paperWidthSm': {
                maxWidth: '1369px',
            },
        },
        modalBody: {
            width: '719px',
            height: '814px',
            background: 'white',
            borderColor: '#FFF',
            paddingLeft: '26px',
            paddingRight: '34px',
            borderRadius: '8px',
            overflowY: 'auto',
            '&:focus': {
                outline: 'none', // Remove the focus outline
            },
        },
        label2: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        labelStyle: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%' /* 22.4px */,
        },
        typoGraphy: {
            color: '#303E4E',
            fontFamily: 'Rubik',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '140%',
        },
        select: {
            '& .MuiTypography-root': {
                color: '#303E4E !important',
            },
        },
        headerStyleModal: {
            paddingLeft: '19px',
            paddingRight: '34px',
            paddingTop: '14.51px',
            paddingBottom: '11.72px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        box2: {
            paddingLeft: '22px',
            paddingRight: '12px',
            marginTop: '17.66px',
            height: '100%',
        },
        boxLeft: {
            width: '47%',
        },
        boxRight: {
            width: '60%',
            padding: '0px 10px'
        },
        button_cancel: {
            background: '#303E4E !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
            height: 42,
            textTransform: 'unset !important',
            // height: '100%',
        },
        button_create: {
            background: '#5571C6 !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
            textTransform: 'unset !important',
            height: 42,
        },
        selectAllCheckboxContainer: {
            '& .MuiFormControlLabel-root .MuiTypography-root .MuiTypography-root span': {
                fontFamily: 'Rubik !important',
                fontSize: '16px !important',
                fontWeight: '400 !important',
                color: '#303E4E !important'
            }
        },
        moduleCheckBoxContainer: {
            '& .MuiFormControlLabel-root .MuiTypography-root .MuiTypography-root span': {
                fontFamily: 'Rubik !important',
                fontSize: '16px !important',
                fontWeight: '500 !important',
                color: '#303E4E !important'
            },

            '& .module_item_label': {
                fontFamily: 'Rubik !important',
                fontSize: '16px !important',
                fontWeight: '500 !important',
                color: '#303E4E !important'
            }
        },
        selectedCountText: {
            fontFamily: 'Rubik !important',
            fontSize: '15px !important',
            fontWeight: '400 !important',
            color: '#303E4E !important'
        },
        moduleContent: {
            '& .MuiFormControlLabel-root .MuiTypography-root .MuiTypography-root span': {
                fontFamily: 'Rubik !important',
                fontSize: '16px !important',
                fontWeight: '400 !important',
                color: '#303E4E !important'
            },
            '& .MuiTypography-root': {
                fontFamily: 'Rubik !important',
                fontSize: '16px !important',
                fontWeight: '400 !important',
                color: '#303E4E !important'
            },
        },
        subjectTextBox: {
            '& .MuiOutlinedInput-root': {
                height: '100% !important'
            }
        }
    }),
)

export const FaxComponent = (props) => {
    const classes = useStyles()
    const { formattedData: faxData, rawData } = props?.faxData

    const notification = useNotification()

    const defaultValues = {
        isEditRecipient: false,
        editedRecipientValues: {},
        isModuleExpanded: { 0: false },
        showAllCheck: {
            all: false,
            modules: {
                conditions: false,
                medications: false,
                allergies: false,
                clinicalNotes: false,
                labOrders: false,
                insurances: false,
                businessDocuments: false
            }
        },
        currentPage: 0
    }

    const [moduleProps, setModuleProps] = useState(defaultValues)
    const [payloadData, setPayloadData] = useState({})

    const handleChangeModuleProps = (fieldName, fieldValues) => {
        setModuleProps((prevValues) => ({
            ...prevValues,
            [fieldName]: fieldValues
        }))
    }

    const [pdfUrl, setPdfUrl] = useState(null)

    const getSelectedCount = (selectionItem) => {
        const selectedItems = selectionItem?.content?.filter(item => item?.selection === true)
        return {
            selectedItems, count: selectedItems?.length, isAllSelected: selectedItems?.length === selectionItem?.content?.length
        }
    }

    const handleChangeModuleChecks = (documentSelectionIndex, contentItemIndex, moduleName, checkedValue) => {
        const newData = faxData
        newData.documentSelection[documentSelectionIndex].content[contentItemIndex].selection = checkedValue

        props?.setFaxData((prevValues) => ({
            ...prevValues,
            formattedData: newData
        }))

        const moduleChecks = {
            ...moduleProps.showAllCheck?.modules,
            [moduleName]: getSelectedCount(newData.documentSelection[documentSelectionIndex])?.isAllSelected
        }
        handleChangeModuleProps('showAllCheck', {
            all: !Object.values(moduleChecks).includes(false),
            modules: moduleChecks
        })
    }

    const handleCheckModuleAllChecks = (item, itemIndex, checkedValue) => {
        const moduleChecks = {
            ...moduleProps.showAllCheck?.modules,
            [item?.keyName]: checkedValue
        }

        handleChangeModuleProps('showAllCheck', {
            all: !Object.values(moduleChecks).includes(false),
            modules: moduleChecks
        })

        const newData = faxData
        newData.documentSelection[itemIndex].content = item?.content?.map(contentItem => ({ ...contentItem, selection: checkedValue  }))
        props?.setFaxData((prevValues) => ({
            ...prevValues,
            formattedData: newData
        }))
    }

    const handleCheckShowAllCheck = (checkedValue) => {
        const moduleChecks = {}
        Object.keys(moduleProps.showAllCheck?.modules)?.map(item => {
            moduleChecks[item] = checkedValue
        })
        
        handleChangeModuleProps('showAllCheck', {
            all: checkedValue,
            modules: {
                ...moduleChecks
            }
        })

        const newData = faxData
        newData.documentSelection = newData?.documentSelection?.map(documentItem => {
            return {
                ...documentItem,
                content: documentItem?.content?.map(contentItem => ({
                    ...contentItem,
                    selection: checkedValue
                }))
            }
        })
        props?.setFaxData((prevValue) => ({
            ...prevValue,
            formattedData: newData
        }))
    }

    const handleModuleExpand = (index) => {
        handleChangeModuleProps('isModuleExpanded', {
            [index]: !Boolean(moduleProps.isModuleExpanded[index])
        })
    }

    const onChangeFaxData = (value, fieldName) => {
        props?.setFaxData((prevValue) => ({
            ...prevValue,
            formattedData: {
                ...prevValue.formattedData,
                [fieldName]: value
            }
        }))
    }

    const onChangeEditRecipientValues = (value, fieldName) => {
        if( fieldName !== 'name' && value?.length >= 15) return
        handleChangeModuleProps('editedRecipientValues', {
            ...moduleProps.editedRecipientValues,
            [fieldName]: value
        })
    }

    const onSaveEditedRecipient = (action = false) => {
        if(moduleProps.editedRecipientValues?.name?.trim()?.length === 0 || moduleProps.editedRecipientValues?.fax?.trim()?.length === 0) {
            notification('Recipient name and fax are mandatory fields.', 'error')
            return
        }
        if(action) onChangeFaxData(moduleProps.editedRecipientValues, 'recipient')
        else handleChangeModuleProps('editedRecipientValues', faxData?.recipient)
        
        handleChangeModuleProps('isEditRecipient', false)
    }

    const handleGeneratePDFData = () => {
        if(faxData?.recipient?.name === "" || faxData?.recipient?.fax === "") {
            notification('Please fill mandatory recipient informations!', 'error')
            return
        }
        setPdfUrl(null)
        props?.setIsLoadingPage(true)
        const { documentSelection } = faxData
        const finalItems = {}
        documentSelection?.forEach(selectionItem => {
            finalItems[selectionItem?.keyName] = getSelectedCount(selectionItem)?.selectedItems?.map((selectedItem) => rawData[selectionItem?.keyName]?.data?.find(item => item?.id === selectedItem?.id))
        })

        const payload = {
            is_send_fax: false,
            date: faxData?.date,
            fax_recipient_name: faxData?.recipient?.name,
            fax_recipient_phone: faxData?.recipient?.contact,
            fax_recipient_fax: faxData?.recipient?.fax,
            fax_sender_name: faxData?.sender?.name,
            fax_sender_phone: faxData?.sender?.contact,
            fax_sender_fax: faxData?.sender?.fax,
            patient_name: faxData?.patient_name,
            subject: faxData?.subject,
            mark_as_urgent: faxData?.markAsUrgent,
            ...finalItems
        }
        previewFaxData(payload)?.then(res => {  
            const url = window.URL.createObjectURL(res.data)
            setPdfUrl(url)
            handleChangeModuleProps('currentPage', 1)
            setPayloadData(payload)
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => props?.setIsLoadingPage(false))
        
    }

    const handleClosePopup = () => {
        props?.handleControlFaxPopupOpen()
        setModuleProps(defaultValues)
        setPayloadData({})
    }

    const handleSendFax = () => {
        props?.setIsLoadingPage(true)
        const payload = {
            ...payloadData,
            is_send_fax: true
        }
        previewFaxData(payload)?.then(res => {
            handleClosePopup()
            notification('Fax has been sent successfully!', 'success')
        })?.catch(() => {
            notification('Something went wrong!', 'error')
        })?.finally(() => props?.setIsLoadingPage(false))
    }

    const handleMoveToNextPage = () => {
        if(moduleProps.currentPage === 0) {
            handleGeneratePDFData()
        } else {
            handleSendFax()
        }
    }

    const handleMoveToPreviousPage = () => {
        if(moduleProps.currentPage === 0) {
            handleClosePopup()
        } else {
            handleChangeModuleProps('currentPage', 0)
        }
    }

    useEffect(() => {
        handleChangeModuleProps('editedRecipientValues', faxData?.recipient)
    }, [faxData?.recipient])

    return (
        <Dialog
            open={props?.openFaxPopup}
            fullWidth
            className={classes.root}
            sx={{
                '& .MuiDialog-paperWidthSm': {
                    maxWidth: '1369px',
                    maxHeight: '1006px',
                    overflowX: 'hidden'
                },
            }}>
            <Box className={[classes.label2, classes.headerStyleModal]}>
                <div>Send Fax</div>
                <div>
                    <img
                        src={cross_icon}
                        style={{ cursor: 'pointer' }}
                        onClick={handleClosePopup}
                    />
                </div>
            </Box>
            <Divider style={{ background: '#E9ECF1' }} />
            <Box className={classes.box2} style={{ height: moduleProps.currentPage === 1 ? '85%' : '100%' }}>

                { moduleProps.currentPage === 0 && (
                    props?.isLoadingPage ? 
                    <div style={{
                        height: '90%',
                        overflowY: 'scroll',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CircularProgress />
                    </div>
                    :
                    <Form 
                        classes={classes}
                        faxData={faxData}
                        isEditRecipient={moduleProps.isEditRecipient}
                        onChangeEditRecipientValues={onChangeEditRecipientValues}
                        editedRecipientValues={moduleProps.editedRecipientValues}
                        onSaveEditedRecipient={onSaveEditedRecipient}
                        onChangeFaxData={onChangeFaxData}
                        showAllCheck={moduleProps.showAllCheck}
                        handleCheckShowAllCheck={handleCheckShowAllCheck}
                        isModuleExpanded={moduleProps.isModuleExpanded}
                        handleCheckModuleAllChecks={handleCheckModuleAllChecks}
                        getSelectedCount={getSelectedCount}
                        handleModuleExpand={handleModuleExpand}
                        handleChangeModuleChecks={handleChangeModuleChecks}
                        handleChangeModuleProps={handleChangeModuleProps}
                    /> 
                )}

                { moduleProps.currentPage === 1 && (
                    <Preview fileUrl={pdfUrl} /> 
                )}

                <Divider  />

                <Box style={{
                    display: 'flex',
                    margin: '15px 0px',
                    gap: 15
                }}>
                    <Button
                        onClick={handleMoveToPreviousPage}
                        disabled={props?.isLoadingPage}
                        className={classes.button_cancel} 
                        variant="contained">
                        { Boolean(moduleProps.currentPage) ? 'Back' : 'Cancel' }
                    </Button>

                    <LoadingButton
                        loading={props?.isLoadingPage}
                        disabled={props?.isLoadingPage}
                        onClick={handleMoveToNextPage}
                        variant="contained"
                        className={classes.button_create}>
                        { Boolean(moduleProps.currentPage) ? 'Send fax' : 'Preview fax' }
                    </LoadingButton>
                </Box>
            </Box>
            
        </Dialog>
    )
}
