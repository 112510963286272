export const PATIENTS_LOADING =
    '[PATIENTS] PATIENTS_LOADING';
export const PATIENTS_SUCCESS =
    '[PATIENTS] PATIENTS_SUCCESS';
export const PATIENTS_FAILURE =
    '[PATIENTS] PATIENTS_FAILURE';
export const PATIENTS_PAGE_CHANGE =
    '[PATIENTS] PATIENTS_PAGE_CHANGE';
export const PATIENTS_SORT_DATA =
    '[PATIENTS] PATIENTS_SORT_DATA';
export const SEARCH_PATIENTS = '[PATIENTS] PATIENTS_SEARCH';
