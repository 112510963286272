import { SET_ACTIVE_MENU,SET_REFRESH_NOTF_BADGE_COUNT }  from '../actionType'

export const setActiveMenu = (activeMenu) => ({
  type: SET_ACTIVE_MENU,
  activeMenu
})

export const setIsToRefreshCount = (isToRefreshCount) => {
  return {
    type: SET_REFRESH_NOTF_BADGE_COUNT,
    isToRefreshCount,
  }
}