import { makeStyles } from '@material-ui/core/styles';

const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
        color: '#737980',
    },
    calender: {
        width: 200,
    },
}));

export default ({ children }) => {
    const classes = usePlaceholderStyles();
    return (
        <div className={classes.placeholder}>
            {children}
        </div>
    );
};
