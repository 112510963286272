import React from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { SelectEl } from '../../../shared/elements'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import _ from 'lodash'
import { selfHarmHistoryViolenceBehaviorPatient } from 'services/Patient/patient'

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: 'fit-content',
        },
    },
    container: {
        marginBottom: 30,
        minWidth: '30%',
    },
    sectionHeading: {
        fontSize: 14,
        color: '#303E4E',
    },
    sectionGridContainer: {
        margin: '10px 0 50px 0',
        flexDirection: 'column',
    },
    textFieldWrap: {
        height: 41,
        width: 261,
    },
    divider: {
        background: '#B0BCE4',
        height: 1,
    },
})



const countOptions = [
    { key: 0, value: 0 },
    { key: 1, value: 1 },
    { key: 2, value: 2 },
    { key: 3, value: 3 },
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
    { key: 9, value: 9 },
    { key: 10, value: 10 },
    { key: 11, value: 11 },
    { key: 12, value: 12 },
    { key: 13, value: 13 },
    { key: 14, value: 14 },
    { key: 15, value: 15 },
    { key: 16, value: 16 },
    { key: 17, value: 17 },
    { key: 18, value: 18 },
    { key: 19, value: 19 },
    { key: 20, value: 20 },
    { key: 'more_20', value: 'more than 20' },
]

const SuicideSelfHarmHistory = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    initialValue,
    patientId,
    nonEditable=false
}) => {
    const radioOptions = [
        { label: 'Yes', value: 'Yes', disable: nonEditable},
        { label: 'No', value: 'No', disable: nonEditable  },
    ]
    const classes = useStyles()

    const handleCheckSelfHarmHistory = (value) => {
        try {
            if (patientId) {
                selfHarmHistoryViolenceBehaviorPatient(patientId, { is_self_harm: value })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 30,
            }}>
            <Box
                sx={{
                    display: 'grid',
                    gap: 5,
                    width: '80%',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['tried_to_harm']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.tried_to_harm`}
                        options={radioOptions}
                        value={
                            values['tried_to_harm'] === true
                                ? 'Yes'
                                : values['tried_to_harm'] === false
                                ? 'No'
                                : values['tried_to_harm']
                        }
                        onChange={(e) => {
                            const { name, value } = e.target
                            handleCheckSelfHarmHistory(value === 'Yes')
                            onChange(name, value === 'Yes')
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gap: 20,
                    width: '80%',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['intent_to_die']}
                </Typography>
                <Typography className={classes.sectionHeading}>
                    {questions['intent_to_die_sub']}
                </Typography>
                <Box>
                    <RadioOptions
                        name={`${fieldName}.intent_to_die`}
                        options={radioOptions}
                        value={
                            values['intent_to_die'] === true
                                ? 'Yes'
                                : values['intent_to_die'] === false
                                ? 'No'
                                : values['intent_to_die']
                        }
                        onChange={(e) => {
                            const { name, value } = e.target

                            onChange(name, value === 'Yes')
                        }}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['intent_to_die_desc']}
                </Typography>
                <TextField
                    multiline
                    minRows={5}
                    name={`${fieldName}.intent_to_die_desc`}
                    variant="outlined"
                    size="small"
                    value={values.intent_to_die_desc}
                    disabled={nonEditable}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                    }}
                    className={classes.root}
                    inputProps={{
                        maxLength: 5000,
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 20,
                    flexDirection: 'column',
                }}>
                <Typography className={classes.sectionHeading}>
                    {questions['suicide_occurance']}
                </Typography>
                <Box sx={{ width: 350 }}>
                    <SelectEl
                        name={`${fieldName}.suicide_occurance`}
                        value={values.suicide_occurance}
                        onChange={(e) => onChange(e.target.name, e.target.value)}
                        options={countOptions}
                        disabled={nonEditable}
                        placeholder="Select One"
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                }}>
                <Typography className={classes.sectionHeading}>Most Severe Episode</Typography>
                <TextField
                    multiline
                    minRows={5}
                    name={`${fieldName}.most_severe_episode`}
                    variant="outlined"
                    size="small"
                    value={values.most_severe_episode}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                    }}
                    className={classes.root}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    disabled={nonEditable}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                }}>
                <Typography className={classes.sectionHeading}>Most Recent Episode</Typography>
                <TextField
                    multiline
                    minRows={5}
                    name={`${fieldName}.most_recent_episode`}
                    variant="outlined"
                    size="small"
                    value={values.most_recent_episode}
                    onChange={(e) => {
                        const { name, value } = e.target
                        onChange(name, value)
                    }}
                    className={classes.root}
                    inputProps={{
                        maxLength: 5000,
                    }}
                    disabled={nonEditable}
                />
            </Box>
            <NarrativeAndMacro
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                meta={{
                    values,
                }}
                onChange={onChange}
                values={values}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SuicideSelfHarmHistory
