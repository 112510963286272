import React from 'react'
import { Grid, FormGroup } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'formik'
import _, { filter } from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    actioneGrid: {
        marginBottom: 20,
    },
    grid_item: {
        flex: 1,
        flexBasis: '40%',
    },
    grid_item_table: {},
    table: {
        borderTop: '1px solid #E9ECF1;',
        paddingTop: 16,
        marginTop: 16,
    },
})

const joinHaveAnd = (arrs) => {
    let str = ''
    arrs.forEach((item, index) => {
        str += index === 0 ? item : index === arrs.length - 1 ? ` and ${item}` : `, ${item}`
    })
    return str
}

const FamilyEnviroment = ({
    master,
    macros,
    values,
    onChange,
    onClear,
    read_only,
    initialValue,
    fieldName,
    copyAble,
    copyValues,
    nonEditable
}) => {
    console.log(fieldName)
    const classes = useStyles()

    function getNames(id, sectionid) {
        const item = master.filter((c) => c._id === id && c.section === sectionid)

        return item && item[0]?.name
    }

    function generatNarrative(macro) {
        const names = []
        const sections = []
        let finalStatement = ''
        values.fam_env_strategy.map((c) => {
            if (getNames(c, 1) !== undefined) {
                return names.push(getNames(c, 1))
            }
            if (getNames(c, 2) !== undefined) {
                return sections.push(getNames(c, 2))
            }
        })
        if (names.length > 0) {
            finalStatement = `The strategies employed from this session included: ${joinHaveAnd(
                names,
            ).toLowerCase()}. `
        }

        if (sections.length > 0) {
            finalStatement = `${finalStatement} The specific details of parent behavioral training are: ${joinHaveAnd(
                sections,
            ).toLowerCase()}.`
        }
        const identifier = finalStatement + "\n\n -------Additional Provider comments and analysis below this line-------\n"
        const include = "-------Additional Provider comments and analysis below this line-------\n"
        if(finalStatement === ""){return}
        if (values?.narrative?.includes(include)) {
            const placeholderIndex = (values?.narrative).indexOf(include)
            const textAfterPlaceholder = (values?.narrative).substring(placeholderIndex + include.length);
            onChange('family_env_strategy.narrative', `${finalStatement ? `${identifier}` : ''}${textAfterPlaceholder}`)
        } else {
            onChange('family_env_strategy.narrative', `${finalStatement ? `${identifier}` : ''}${values?.narrative ? values?.narrative : "" }`)
        }
        // onChange('family_env_strategy.narrative', `${finalStatement} \n${macro}`)
    }

    function handleChange(name, value) {
        onChange(name, value)
        console.log('handleChange', name, value)
    }

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()

            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    const options = master?.filter((i) => i.section === 2)

    return (
        <Grid container>
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            <Grid className={classes.actioneGrid} container>
                <Grid item xs={12} style={{ marginBottom: 32 }}>
                    <FieldArray
                        name="family_env_strategy.fam_env_strategy"
                        render={(helpers) => (
                            <FormGroup>
                                {master
                                    ?.filter((i) => i.section === 1)
                                    .map((i, key) => {
                                        return (
                                            <CheckboxOption
                                                key={key}
                                                name={i.name}
                                                option={{
                                                    label: i.name,
                                                    value: false,
                                                }}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        helpers.push(i._id)
                                                    } else {
                                                        const idx = values.fam_env_strategy.indexOf(
                                                            i._id,
                                                        )
                                                        helpers.remove(idx)
                                                    }
                                                }}
                                                checked={values.fam_env_strategy.includes(i._id)}
                                                disabled={nonEditable}
                                            />
                                        )
                                    })}

                                <Grid container className={classes.table}>
                                    <Grid className={classes.grid_item}>
                                        {options.slice(0, options.length / 2 + 1).map((i, key) => {
                                            return (
                                                <Grid key={key} className={classes.grid_item_table}>
                                                    <CheckboxOption
                                                        key={key}
                                                        name={i.name}
                                                        option={{
                                                            label: i.name,
                                                            value: false,
                                                        }}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                helpers.push(i._id)
                                                            } else {
                                                                const idx =
                                                                    values.fam_env_strategy.indexOf(
                                                                        i._id,
                                                                    )
                                                                helpers.remove(idx)
                                                            }
                                                        }}
                                                        checked={values.fam_env_strategy.includes(
                                                            i._id,
                                                        )}
                                                        disabled={nonEditable}
                                                    />
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                    <Grid className={classes.grid_item}>
                                        {options
                                            .slice(options.length / 2 + 1, options.length)
                                            .map((i, key) => {
                                                return (
                                                    <Grid
                                                        key={key}
                                                        className={classes.grid_item_table}>
                                                        <CheckboxOption
                                                            key={key}
                                                            name={i.name}
                                                            option={{
                                                                label: i.name,
                                                                value: false,
                                                            }}
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    helpers.push(i._id)
                                                                } else {
                                                                    const idx =
                                                                        values.fam_env_strategy.indexOf(
                                                                            i._id,
                                                                        )
                                                                    helpers.remove(idx)
                                                                }
                                                            }}
                                                            checked={values.fam_env_strategy.includes(
                                                                i._id,
                                                            )}
                                                            disabled={nonEditable}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <NarrativeAndMacro
                    values={values}
                    fieldName={fieldName}
                    onGenerateClick={generatNarrative}
                    onChange={handleChange}
                    macros={macros}
                    meta={{
                        values,
                    }}
                    customNarrative={true}
                    handleCustomNarrative={generatNarrative}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default FamilyEnviroment
