import {
  getService,
  postService,
} from '../../utilities/request/restClient';
import { getLoggedInUserRole } from '../../utilities';
import { UserRoles } from '../../constants/user-roles';
export const getClinicAdminDetailById = (id) => {
  return getService(`/clinic-admin/findByUserId/${id}`);
};

export const getClinicAdminProfileById = (id) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
    case UserRoles.CLINIC_CORDINATION:
      userType = 'clinic-admin';
      break;

    default: break
  }
  return getService(`/${userType}/clinic/admin-view/${id}`);
};
export const editClinicAdminProfile = (payload) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;

    default: break
  }
  const personaUrl =
    userRole === UserRoles.CLINIC_CORDINATION
      ? `/clinic-admin/admin-edit`
      : `/${userType}/clinic/admin-edit`;
  return postService(personaUrl, payload);
};

export const addClinicAdminProfile = (payload) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;

    default: break
  }
  return postService(`/${userType}/clinic/admin-add`, payload);
};

export const getClinicList = (payload) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
    case UserRoles.CLINIC_CORDINATION:
      userType = 'clinic-admin';
      break;

    default: break
  }
  return postService(`/${userType}/clinic/search`, {
    limit: 10,
    ...payload,
  });
};

export const updateClinicStatus = (payload) => {
  let userType = '';
  const userRole = getLoggedInUserRole();

  switch (userRole) {
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;

    default: break
  }
  const { id, status } = payload;

  return postService(
    `/${userType}/clinic/clinic-status`,
    {
      id,
      status,
    },
  );
};

export const getClinicAdminList = (payload) => {
  const userRole = getLoggedInUserRole();
  let userType = '';
  switch (userRole) {
    case UserRoles.TM2U_ADMIN:
      userType = 'admin';
      break;
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;
    case UserRoles.CLINIC_CORDINATION:
      userType = 'clinic-admin';
      break;

    default: break
  }
  return postService(`/${userType}/clinic/admin-search`, {
    limit: 10,
    ...payload,
  });
};

export const updateClinicAdminStatus = (payload) => {
  let userType = '';
  const userRole = getLoggedInUserRole();

  switch (userRole) {
    case UserRoles.TM2U_SUPER_ADMIN:
      userType = 'super-admin';
      break;

    default: break
  }

  return postService(
    `/${userType}/clinic/admin-status`,
    payload
  );
};
