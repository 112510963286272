import {
    deleteService,
    getSearchService,
    getService,
    getServiceForBlobResponse,
    patchService,
    postService,
    putService,
} from 'utilities/request/restClient'

const baseUrl = process.env.REACT_APP_SONORA_V2_API_URL

export const apiWaitlist = {
    getList(payload) {
        // const queryString = new URLSearchParams(payload).toString()
        let url = `${baseUrl}/waitlist?page=${payload?.page}&size=${payload?.limit}`
        if (payload?.specialty_id) url += `&specialty_id=${payload?.specialty_id}`
        if (payload?.patient_id) url += `&patient_id=${payload?.patient_id}`
        if (payload?.listDay_id) url += `&day=${payload?.listDay_id}`
        if (payload?.provider_id) url += `&prefer_provider_id=${payload?.provider_id}`
        if (payload?.visit_id) url += `&visit_type_id=${payload?.visit_id}`
        // if (payload?.location_id) url += `&service_location_id=${payload?.location_id}`
        if (payload?.createdAt) url += `&createdAt=${payload?.createdAt}`
        return getService(url)
        // return getService(`${baseUrl}/waitlist?${queryString}`)
    },
    getById(id) {
        return getService(`${baseUrl}/waitlist/${id}`)
    },
    create(payload) {
        return postService(`${baseUrl}/waitlist`, payload)
    },
    update(id, payload) {
        return putService(`${baseUrl}/waitlist/${id}`, payload)
    },
    delete(id) {
        return deleteService(`${baseUrl}/waitlist/${id}`)
    },
}

export const apiSuperbills = {
    getAll({
        start = 1,
        limit = 10,
        asc,
        desc,
        location_id,
        provider_id,
        supervising_provider_id,
        status,
        patient_id,
        practice_id,
        service_date,
        id
    } = {}) {
        let url = `${baseUrl}/super-bill?page=${start}&size=${limit}`
        if (asc) url += `&asc=${asc}`
        if (desc) url += `&desc=${desc}`
        if (location_id) url += `&location_id=${location_id}`
        if (provider_id) url += `&provider_id=${provider_id}`
        if (supervising_provider_id) url += `&supervising_provider_id=${supervising_provider_id}`
        if (status) url += `&status=${status}`
        if (patient_id) url += `&patient_id=${patient_id}`
        if (practice_id) url += `&practice_id=${practice_id}`
        if (service_date) url += `&service_date=${service_date}`
        if (id) url += `&id=${id}`
        return getService(url)
    },
    getById(id) {
        return getService(`${baseUrl}/super-bill/${id}`)
    },
    getSuperbillLog(id) {
        return getService(`${baseUrl}/super-bill/logs/${id}`)
    },
    update(id, payload) {
        return putService(`${baseUrl}/super-bill/${id}`, payload)
    },
    deleted(id) {
        return deleteService(`${baseUrl}/super-bill/${id}`)
    },
    submit(payload) {
        return postService(`${baseUrl}/super-bill/submit`, payload)
    },
    create(payload) {
        return postService(`${baseUrl}/super-bill`, payload)
    },
    csvExport(service_date, status) {
        return getServiceForBlobResponse(`${baseUrl}/super-bill/export-csv?status=${status}&service_date=${service_date}`, 'text/csv')
    }
}

export function getSupervisingProviders() {
    return getService(
        `${baseUrl}/practitioner?role=medical_provider,bh_provider,therapist&_sort=name&isAllProvider=true&size=100000&page=0`,
    )
}

export function getProvidingProviders() {
    return getService(
        `${baseUrl}/practitioner?role=bh_provider,mid_bh_provider,medical_provider,mid_medical_provider,therapist&_sort=name&isAllProvider=true&size=100000&page=0`,
    )
}

export function searchProviderByName(name) {
    return getService(
        `${baseUrl}/practitioner?role=bh_provider,mid_bh_provider,medical_provider,mid_medical_provider,therapist&_sort=name&isAllProvider=true&name=${name}&size=100000&page=0`,
    )
}

export function searchCptModifier() {
    return getSearchService(
        `${process.env.REACT_APP_SONORA_SEARCH_V2_API_URL}/sonora_dev_cpt_modifier/_search?size=1000&filter_path=hits.hits._source`,
    ).then((resp) =>
        resp.data.hits.hits.map((item) => ({
            ...item._source,
            code: item._source?.code,
            display: item._source?.display,
        })),
    )
}
