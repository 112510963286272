import React, { useReducer } from 'react'
import { SET_DIRTY_SESSION } from '../store/actionTypes'
import { reducer, initialState } from '../store/reducer'

export const ClinicalNoteContext = React.createContext()
export const ClinicalNoteProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialState,
    )

    const value = {
        state,
        checkDirtySessions: (payload) => {
            if (!payload.key) {
                return
            }
            console.log(
                'checkDirtySessions',
                payload.key,
                payload.value,
            )
            dispatch({
                type: SET_DIRTY_SESSION,
                value: payload,
            })
        },
        dispatch,
    }

    return (
        <ClinicalNoteContext.Provider value={value}>
            {children}
        </ClinicalNoteContext.Provider>
    )
}
