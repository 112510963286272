/* eslint-disable no-constant-condition */
import { IconButton, Typography } from '@material-ui/core'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import DehazeIcon from '@mui/icons-material/Dehaze'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import { Box, Collapse } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { getApiEXP } from 'services/ERX'
import { sortMenuBar } from 'shared/utils'
import { SET_TELEHEALTH_SCREEN } from 'store/actionType'
import logo from '../../../assests/Logos/tm2u_logo_150.png'
import LogOutIcon from '../../../assests/sidebar/icon_log_out.svg'
import { getMenusByUserRole } from '../../../constants/menu'
import Routes from '../../../constants/routes'
import { useQuery } from '../../../shared/hooks/useQuery'
import { setActiveMenu } from '../../../store/actions/menu'
import './Sidebar.scss'
import { TooltipNav } from './TooltipNav'

const TIME_CALL_NOTIFICATION_ERX = 15 * 60 * 1000

const Sidebar = ({ isShow, handeOpenSideBar, onLogout }) => {
    const currentPath = window.location.pathname

    const activeMenu = useSelector((state) => state.menu.activeMenu)

    const telehealthScreen = useSelector((state) => state.telehealthScreen)
    const menuList =
        useSelector((state) => {
            return getMenusByUserRole(
                state.login.userData.role,
                currentPath,
                telehealthScreen.active,
            )
        }) || []

    const dispatch = useDispatch()
    const location = useLocation()
    const query = useQuery()
    const [openCollapseSetting, setOpenCollapseSetting] = useState(true)

    const handleShowCollapseSetting = (item) => {
        if (item.hasChild)
            setOpenCollapseSetting((prev) => (!prev || prev !== item.name ? item.name : false))
    }

    useEffect(() => {
        let filteredActiveMenu
        if (location.pathname === Routes.TELE_HEALTH.CONSULTATION) {
            const params = Object.fromEntries(new URLSearchParams(window.location.search).entries())
            filteredActiveMenu = menuList.find(({ path }) => path.includes(params.info))
        } else {
            // @TODO: consider nested sub-menu or nested routes
            const rootPath = `/${location.pathname.split('/')[1]}`
            filteredActiveMenu = menuList.find(({ path }) => path === location.pathname)
        }

        if (filteredActiveMenu) {
            dispatch(setActiveMenu(filteredActiveMenu))
        }
    }, [location.pathname, location.search])

    const newMenuSort = useMemo(() => sortMenuBar(menuList), [menuList])

    const [messagesNotification, setMessagesNotification] = useState({
        refill: 0,
        rxchange: 0,
    })
    const [prescriptionNotification, setPrescriptionNotification] = useState({
        rx_pending: 0,
        rx_needs_signing: 0,
    })

    const listChildEex = [
        {
            title: 'Messages',
            url: Routes.ERX_MESSAGE,
            child: [
                {
                    title: 'Refill messages',
                    count: messagesNotification.refill,
                },
                {
                    title: 'Prescription change messages',
                    count: messagesNotification.rxchange,
                },
            ],
        },
        {
            title: 'Prescription',
            url: Routes.ERX_PRESCRIPTION,
            child: [
                {
                    title: 'eRx Pending prescriptions',
                    count: prescriptionNotification.rx_pending,
                },
                {
                    title: 'Pending prescription needing to be signed',
                    count: prescriptionNotification.rx_needs_signing,
                },
            ],
        },
    ]

    const [exrNotificationShow, setExrNotificationShow] = useState(false)

    const getNotificationERX = async () => {
        try {
            const res = await getApiEXP.notification()
            if (res.data) {
                const refillCount = res.data.find((item) => item.type === 'refill').number
                const rxchangeCount = res.data.find((item) => item.type === 'rxchange').number
                const rx_pendingCount = res.data.find((item) => item.type === 'rx_pending').number
                const rx_needs_signingCount = res.data.find(
                    (item) => item.type === 'rx_need_signing',
                ).number
                // console.log(refillCount, rxchangeCount, rx_pendingCount, rx_needs_signingCount)
                if (
                    +refillCount > 0 ||
                    +rxchangeCount > 0 ||
                    +rx_pendingCount > 0 ||
                    +rx_needs_signingCount > 0
                ) {
                    setExrNotificationShow(true)
                }
                setMessagesNotification({ refill: refillCount, rxchange: rxchangeCount })
                setPrescriptionNotification({
                    rx_pending: rx_pendingCount,
                    rx_needs_signing: rx_needs_signingCount,
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getNotificationERX()
        setInterval(() => {
            getNotificationERX()
        }, TIME_CALL_NOTIFICATION_ERX)
    }, [])

    return (
        <>
            <Box className={`app__sidebar ${!isShow && 'is__show'}`} id="st-br">
                <Box>
                    {isShow ? (
                        <div className="logo-wrap">
                            <img style={{ height: 50 }} src={logo} alt="TeleMed2U" />
                        </div>
                    ) : (
                        <div className={`btn__option__left__menu`}>
                            <div className={'nav-wrap'}>
                                <IconButton onClick={handeOpenSideBar}>
                                    <DehazeIcon
                                        style={{
                                            fontSize: 20,
                                            color: '#667689',
                                        }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                    )}
                </Box>

                <Box className="wrap-menu-center">
                    <Box className="wrap-navlink">
                        {newMenuSort.map((item) => {
                            const { name, path, hasChild, activeIcon, icon, position, listChild } =
                                item

                            let isMenuActive = location.pathname.includes(path)

                            if (path === Routes.DASHBOARD.MAIN && location.pathname === Routes.HOME) {
                                isMenuActive = true
                            }

                            return (
                                position !== 'bottom' && (
                                    <Box key={name} className="wrap-navlink-lv1">
                                        {false ? (
                                            <Box
                                                className={`nav-link-lv1 ${telehealthScreen.tabName === name
                                                    ? 'active'
                                                    : ''
                                                    }`}
                                                onClick={(e) => {
                                                    dispatch({
                                                        type: SET_TELEHEALTH_SCREEN,
                                                        active: true,
                                                        tabName: name,
                                                    })
                                                }}>
                                                <Box className="group-text-link">
                                                    <img
                                                        className="icon-link"
                                                        src={
                                                            telehealthScreen.tabName === name
                                                                ? activeIcon
                                                                : icon
                                                        }
                                                        alt=""></img>
                                                    <Typography
                                                        className="text-link"
                                                        component={'span'}>
                                                        {name}
                                                    </Typography>
                                                </Box>
                                                {!isShow && (
                                                    <Box className="tooltip-nav-link">
                                                        <Typography className="text-link-tt">
                                                            {name}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : (
                                            <>
                                                {name === 'eRX' ? (
                                                    <>
                                                        <NavLink
                                                            className={`nav-link-lv1 ${name === openCollapseSetting
                                                                ? 'nav-link-lv1-erx'
                                                                : ''
                                                                } `}
                                                            onClick={(e) => {
                                                                if (hasChild) e.preventDefault()
                                                                handleShowCollapseSetting(item)
                                                            }}
                                                            to={path}>
                                                            <Box className="group-text-link">
                                                                <img
                                                                    className="icon-link"
                                                                    src={
                                                                        isMenuActive
                                                                            ? activeIcon
                                                                            : icon
                                                                    }
                                                                    alt=""></img>
                                                                <Typography
                                                                    className="text-link textlink-erx"
                                                                    component={'span'}>
                                                                    {name}
                                                                    {exrNotificationShow && (
                                                                        <Typography
                                                                            component={'span'}
                                                                            className="icon-notification">
                                                                            <PriorityHighIcon fontSize="small" />
                                                                        </Typography>
                                                                    )}
                                                                </Typography>
                                                                {exrNotificationShow && !isShow && (
                                                                    <Typography
                                                                        component={'span'}
                                                                        className="icon-notification-small">
                                                                        <PriorityHighIcon fontSize="small" />
                                                                    </Typography>
                                                                )}
                                                                {hasChild && (
                                                                    <Box
                                                                        className={`icon-down ${openCollapseSetting ===
                                                                            name
                                                                            ? 'show'
                                                                            : ''
                                                                            }`}>
                                                                        <KeyboardArrowDownRoundedIcon
                                                                            style={{
                                                                                color: isMenuActive
                                                                                    ? 'white'
                                                                                    : '#516983',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </NavLink>
                                                        {hasChild && (
                                                            <>
                                                                {isShow ? (
                                                                    <Collapse
                                                                        in={
                                                                            name ===
                                                                                openCollapseSetting
                                                                                ? true
                                                                                : false
                                                                        }>
                                                                        <Box className="wrap-navlink-2 wrap-navlink-2-erx">
                                                                            {listChildEex.map(
                                                                                (subErx, index) => (
                                                                                    <Box
                                                                                        key={index}>
                                                                                        <NavLink
                                                                                            className={
                                                                                                'nav-link-lv2'
                                                                                            }
                                                                                            // onClick={(e) =>
                                                                                            //     e.preventDefault()
                                                                                            // }
                                                                                            to={
                                                                                                subErx.url
                                                                                            }>
                                                                                            {/* <img
                                                                                                className="icon-link-lv2"
                                                                                                src={
                                                                                                    IconRadio
                                                                                                }
                                                                                                alt=""></img> */}
                                                                                            <Typography
                                                                                                className="text-link-lv2"
                                                                                                component={
                                                                                                    'span'
                                                                                                }>
                                                                                                {
                                                                                                    subErx.title
                                                                                                }
                                                                                            </Typography>
                                                                                            <Box className="wrap-notification">
                                                                                                {subErx.child.map(
                                                                                                    (
                                                                                                        child,
                                                                                                        index,
                                                                                                    ) => (
                                                                                                        <TooltipNav
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                            title={
                                                                                                                child.title
                                                                                                            }
                                                                                                            placement="top">
                                                                                                            <Box
                                                                                                                className="lef"
                                                                                                                component={
                                                                                                                    'span'
                                                                                                                }>
                                                                                                                {child.count ||
                                                                                                                    0}
                                                                                                            </Box>
                                                                                                        </TooltipNav>
                                                                                                    ),
                                                                                                )}
                                                                                            </Box>
                                                                                        </NavLink>
                                                                                    </Box>
                                                                                ),
                                                                            )}
                                                                        </Box>
                                                                    </Collapse>
                                                                ) : (
                                                                    <Box className="wrap-navlink-2-tootip">
                                                                        <Box className="wrap-ct-item">
                                                                            <Typography className="title-tooltip-cat">
                                                                                {name}
                                                                            </Typography>
                                                                            {listChildEex.map(
                                                                                (subErx, index) => (
                                                                                    <Box
                                                                                        key={index}>
                                                                                        <NavLink
                                                                                            className="nav-link-lv2 nav-link-lv2-exr"
                                                                                            // onClick={(e) =>
                                                                                            //     e.preventDefault()
                                                                                            // }
                                                                                            to={
                                                                                                subErx.url
                                                                                            }>
                                                                                            <Typography>
                                                                                                {
                                                                                                    subErx.title
                                                                                                }
                                                                                            </Typography>
                                                                                            <Box className="wrap-notification">
                                                                                                {subErx.child.map(
                                                                                                    (
                                                                                                        child,
                                                                                                        index,
                                                                                                    ) => (
                                                                                                        <TooltipNav
                                                                                                            key={
                                                                                                                index
                                                                                                            }
                                                                                                            title={
                                                                                                                child.title
                                                                                                            }
                                                                                                            placement="top">
                                                                                                            <Box
                                                                                                                className="lef"
                                                                                                                component={
                                                                                                                    'span'
                                                                                                                }>
                                                                                                                {child.count ||
                                                                                                                    0}
                                                                                                            </Box>
                                                                                                        </TooltipNav>
                                                                                                    ),
                                                                                                )}
                                                                                            </Box>
                                                                                        </NavLink>
                                                                                    </Box>
                                                                                ),
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <NavLink
                                                            className={() => {
                                                                if (
                                                                    location.pathname ===
                                                                    Routes.TELE_HEALTH.CONSULTATION
                                                                ) {
                                                                    const info = query.get('info')
                                                                    if (
                                                                        info !==
                                                                        path.split('?info=')[1]
                                                                    ) {
                                                                        return 'nav-link-lv1 inactive'
                                                                    }
                                                                }
                                                                if (
                                                                    location.pathname ===
                                                                    Routes.HOME &&
                                                                    path === Routes.DASHBOARD.MAIN
                                                                ) {
                                                                    return 'nav-link-lv1 active'
                                                                }
                                                                return 'nav-link-lv1'
                                                            }}
                                                            onClick={(e) => {
                                                                if (hasChild) e.preventDefault()
                                                                else setOpenCollapseSetting(false)
                                                                handleShowCollapseSetting(item)
                                                            }}
                                                            to={path}>
                                                            <Box className="group-text-link">
                                                                <img
                                                                    className="icon-link"
                                                                    src={
                                                                        isMenuActive
                                                                            ? activeIcon
                                                                            : icon
                                                                    }
                                                                    alt=""></img>
                                                                <Typography
                                                                    className="text-link"
                                                                    component={'span'}>
                                                                    {name}
                                                                </Typography>
                                                                {hasChild && (
                                                                    <Box
                                                                        className={`icon-down ${openCollapseSetting ===
                                                                            name
                                                                            ? 'show'
                                                                            : ''
                                                                            }`}>
                                                                        <KeyboardArrowDownRoundedIcon
                                                                            style={{
                                                                                color: isMenuActive
                                                                                    ? 'white'
                                                                                    : '#516983',
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            {!hasChild && !isShow && (
                                                                <Box className="tooltip-nav-link">
                                                                    <Typography className="text-link-tt">
                                                                        {name}
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </NavLink>
                                                        {hasChild && listChild?.length && (
                                                            <>
                                                                {isShow ? (
                                                                    <Collapse
                                                                        in={
                                                                            name ===
                                                                                openCollapseSetting
                                                                                ? true
                                                                                : false
                                                                        }>
                                                                        <Box className="wrap-navlink-2">
                                                                            {listChild.map(
                                                                                (childItem) => {
                                                                                    const {
                                                                                        name,
                                                                                        path,
                                                                                        hasChild,
                                                                                        activeIcon,
                                                                                        icon,
                                                                                        position,
                                                                                        listChild,
                                                                                        parent,
                                                                                    } = childItem
                                                                                    const isMenuChildActive =
                                                                                        (activeMenu.path.includes(
                                                                                            path,
                                                                                        ) ||
                                                                                            location.pathname ===
                                                                                            path) &&
                                                                                        parent
                                                                                    return (
                                                                                        <Box
                                                                                            key={
                                                                                                name
                                                                                            }>
                                                                                            <NavLink
                                                                                                className={() => {
                                                                                                    if (
                                                                                                        location.pathname ===
                                                                                                        Routes.HOME &&
                                                                                                        path ===
                                                                                                        Routes
                                                                                                            .DASHBOARD
                                                                                                            .BI_STUDIO
                                                                                                    )
                                                                                                        return `nav-link-lv2 active`
                                                                                                    return 'nav-link-lv2'
                                                                                                }}
                                                                                                to={
                                                                                                    path
                                                                                                }>
                                                                                                <img
                                                                                                    className="icon-link-lv2"
                                                                                                    src={
                                                                                                        isMenuChildActive
                                                                                                            ? activeIcon
                                                                                                            : icon
                                                                                                    }
                                                                                                    alt=""></img>
                                                                                                <Typography
                                                                                                    className="text-link-lv2"
                                                                                                    component={
                                                                                                        'span'
                                                                                                    }>
                                                                                                    {
                                                                                                        name
                                                                                                    }
                                                                                                </Typography>
                                                                                            </NavLink>
                                                                                        </Box>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </Box>
                                                                    </Collapse>
                                                                ) : (
                                                                    <Box className="wrap-navlink-2-tootip">
                                                                        <Box className="wrap-ct-item">
                                                                            <Typography className="title-tooltip-cat">
                                                                                {name}
                                                                            </Typography>
                                                                            {listChild.map(
                                                                                (childItem) => {
                                                                                    const {
                                                                                        name,
                                                                                        path,
                                                                                        hasChild,
                                                                                        activeIcon,
                                                                                        icon,
                                                                                        position,
                                                                                        listChild,
                                                                                    } = childItem
                                                                                    return (
                                                                                        <Box
                                                                                            key={
                                                                                                name
                                                                                            }>
                                                                                            <NavLink
                                                                                                className="nav-link-lv2"
                                                                                                to={
                                                                                                    path
                                                                                                }>
                                                                                                <Typography>
                                                                                                    {
                                                                                                        name
                                                                                                    }
                                                                                                </Typography>
                                                                                            </NavLink>
                                                                                        </Box>
                                                                                    )
                                                                                },
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )
                            )
                        })}
                    </Box>
                    <Box className="wrap-navlink bot">
                        {newMenuSort.map((item) => {
                            const { name, path, hasChild, activeIcon, icon, position, listChild } =
                                item
                            const isMenuActive = activeMenu.path.includes(path)
                            const isMenuChild = parent
                            return (
                                position === 'bottom' && (
                                    <Box key={name} className="wrap-navlink-lv1">
                                        <>
                                            <NavLink
                                                className="nav-link-lv1"
                                                onClick={(e) => {
                                                    if (hasChild) e.preventDefault()
                                                    handleShowCollapseSetting(item)
                                                }}
                                                to={path}>
                                                <Box className="group-text-link">
                                                    <img
                                                        className="icon-link"
                                                        src={isMenuActive ? activeIcon : icon}
                                                        alt=""></img>
                                                    <Typography
                                                        className="text-link"
                                                        component={'span'}>
                                                        {name}
                                                    </Typography>
                                                    {hasChild && (
                                                        <Box
                                                            className={`icon-down ${openCollapseSetting === name
                                                                ? 'show'
                                                                : ''
                                                                }`}>
                                                            <KeyboardArrowDownRoundedIcon
                                                                style={{
                                                                    color: isMenuActive
                                                                        ? 'white'
                                                                        : '#516983',
                                                                }}
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                                {!hasChild && !isShow && (
                                                    <Box className="tooltip-nav-link">
                                                        <Typography className="text-link-tt">
                                                            {name}
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </NavLink>
                                            {hasChild && listChild?.length && (
                                                <>
                                                    {isShow ? (
                                                        <Collapse
                                                            in={
                                                                name === openCollapseSetting
                                                                    ? true
                                                                    : false
                                                            }>
                                                            <Box className="wrap-navlink-2">
                                                                {listChild.map((childItem) => {
                                                                    const {
                                                                        name,
                                                                        path,
                                                                        hasChild,
                                                                        activeIcon,
                                                                        icon,
                                                                        position,
                                                                        listChild,
                                                                        parent,
                                                                    } = childItem
                                                                    const isMenuChildActive =
                                                                        (activeMenu.path.includes(
                                                                            path,
                                                                        ) ||
                                                                            location.pathname ===
                                                                            path) &&
                                                                        parent
                                                                    return (
                                                                        <Box key={name}>
                                                                            <NavLink
                                                                                className="nav-link-lv2"
                                                                                to={path}>
                                                                                <img
                                                                                    className="icon-link-lv2"
                                                                                    src={
                                                                                        isMenuChildActive
                                                                                            ? activeIcon
                                                                                            : icon
                                                                                    }
                                                                                    alt=""></img>
                                                                                <Typography
                                                                                    className="text-link-lv2"
                                                                                    component={
                                                                                        'span'
                                                                                    }>
                                                                                    {name}
                                                                                </Typography>
                                                                            </NavLink>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Collapse>
                                                    ) : (
                                                        <Box className="wrap-navlink-2-tootip">
                                                            <Box className="wrap-ct-item">
                                                                <Typography className="title-tooltip-cat">
                                                                    {name}
                                                                </Typography>
                                                                {listChild.map((childItem) => {
                                                                    const {
                                                                        name,
                                                                        path,
                                                                        hasChild,
                                                                        activeIcon,
                                                                        icon,
                                                                        position,
                                                                        listChild,
                                                                    } = childItem
                                                                    return (
                                                                        <Box key={name}>
                                                                            <NavLink
                                                                                className="nav-link-lv2"
                                                                                to={path}>
                                                                                <Typography>
                                                                                    {name}
                                                                                </Typography>
                                                                            </NavLink>
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    </Box>
                                )
                            )
                        })}
                    </Box>
                </Box>

                <Box className={`btn-logout`} onClick={onLogout}>
                    <Box className={'nav-wrap-logout'}>
                        <img src={LogOutIcon} alt=""></img>
                        {isShow && <span className="menu">Log Out</span>}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Sidebar
