import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import CheckboxOption from '../reusable/CheckboxOptions'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const mapOptions = (assessment = []) => {
    return assessment.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const Psychotherapy = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    initialValue,
    nonEditable,
    copyAble,
    copyValues,
}) => {
    const options = mapOptions(master?.psychotherapyMasters)

    const handleCheckChange = (e, option) => {
        const { name, value, checked } = e.target
        const { psychotherapy_opt } = values
        if (checked) {
            onChange(name, [
                ...psychotherapy_opt,
                { id: value, label: option.label },
            ])
        } else {
            onChange(
                name,
                psychotherapy_opt.filter(
                    (uv) => uv.id !== value,
                ),
            )
        }
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_psychotherapy_opt = []
            const psychotherapy_opt = copyValues?.psychotherapy_opt?.length ? copyValues?.psychotherapy_opt : []
            for (const element of psychotherapy_opt) {
                if (typeof element === 'object' && element !== null) {
                    found_psychotherapy_opt.push(element);
                }
            }
            
            const finalValues = {
                narrative: `${copyValues?.narrative} ${values?.narrative}`,
                psychotherapy_opt: [...found_psychotherapy_opt, ...(values?.psychotherapy_opt || [])],
            }
            if (!found_psychotherapy_opt.length &&  copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}>
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            {options.map((option, index) => (
                <CheckboxOption
                    disabled={nonEditable}
                    key={index}
                    name={`${fieldName}.psychotherapy_opt`}
                    option={option}
                    onChange={(e) =>
                        handleCheckChange(e, option)
                    }
                    checked={
                        values?.psychotherapy_opt.some(
                            (item) =>
                                item.id === option.value,
                        )
                            ? true
                            : false
                    }
                />
            ))}
            <NarrativeAndMacro
                hideTextNarrative={true}
                hideGenerateBtn={true}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default Psychotherapy
