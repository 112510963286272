import { makeStyles, Theme } from '@material-ui/core'

export const chatWindowStyle = makeStyles(
    (theme: Theme) => ({
        chatBox: {
            width: 0,
            height: '100%',
            maxWidth: '450px',
            borderRadius: '0px 16px 16px 0px',
            textAlign: 'left',
            // fontWeight: '500',
            boxShadow: '0px 16px 24px rgba(136, 145, 156, 0.2)',
            // padding: '20px 0',
            overflowY: 'scroll',
            transition: 'width 0.5s',
            // position: 'absolute',
            // left: '100%',
            // top: 0,
            background: 'white',
            cursor: 'default',
        },
        open: {
            width: '450px',
            maxWidth: '450px',
        },
        chatWindowContainer: {
            borderRadius: '5px',
            flex: 1,
            display: 'flex',
            height: "100%",
            flexDirection: 'column',
        },
        chatWindowContainerTop: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 'fit-content',
            marginBottom: '4px',
        },
        chatWindowContainerTopName: {
            fontSize: '16px',
            fontWeight: 500,
            color: '#303E4E'
        },
        chatWindowContainerTopToggle: {
            border: 'none',
            backgroundColor: 'rgba(48, 62, 78, 0.8)',
            cursor: 'pointer',
            borderRadius: 10,
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        chatWindowBottomChats: {
            overflowY: 'scroll',
            // padding: '0.5rem',
            marginBottom: 10,
            flex: 1,
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        chatWindowBottomChatbox: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '0.5rem',
            paddingBottom: '0.8rem',
        },
        chatWindowBottomInput: {
            width: '83%',
            height: '35px',
            border: 'none',
            borderTopRightRadius: '5px',
            borderBottomLeftRadius: '5px',
            padding: '0 0.8rem',
            fontSize: '0.8rem',
            fontWeight: 500,
            '&::placeholder': {
                opacity: 0.6,
                fontSize: '0.7rem',
            },
        },
        chatWindowBottomSend: {
            position: 'absolute',
            borderRadius: '5px',
            top: '8.5px',
            right: '8px',
            padding: '0.3rem 0.7rem',
            paddingTop: '0.5rem',
            border: 'none',
            backgroundColor: '#5571c6',
            cursor: 'pointer',
        },
        chatWindowBottomIcon: {
            width: '20px',
            height: '20px',
            filter: 'invert(100%)',
            transform: 'rotate(15deg)',
        },
        chatMobContainer: {
            '.MuiDialogContent-root': {
                padding: '0px',
            },
        },
        chatDialogContent: {
            width: '94% !important',
            position: 'fixed',
            left: '3%',
            top: '28%',
        },
    }),
)
