import React from 'react'
import { Button } from '@material-ui/core'
import './index.scss'

const CustomButton = ({
    styleProps,
    label,
    onClick,
    color,
    disabled,
    variant,
    type = '',
    startIcon,
    endIcon,
    ...props
}) => {
    return (
        <Button
            id="auto-custom-btn"
            variant={variant || 'contained'}
            className={`custom-btn ${color}`}
            onClick={onClick}
            disabled={disabled}
            type={type}
            {...props}>
            {startIcon}
            <span>{label}</span>
            {endIcon}
        </Button>
    )
}

export default CustomButton
