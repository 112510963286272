import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function AlertStatusChangeDialog({
    open,
    handleClose,
    handleConfirm,
    title,
    confirmButtonName = 'Confirm',
    cancelButtonName = 'Cancel',
    confirmButtonColor = 'default',
    cancelButtonColor = 'default',
    confirmButtonDisabled = false,
    cancelButtonDisabled = false,
    ...props
}) {

  return (
    <React.Fragment>
        <BootstrapDialog
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
                style: {
                    width: '500px'
                }
            }}
        >
            <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <DialogTitle sx={{ m: 0, p: 2, width: '85%' }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    // sx={{
                    //     position: 'absolute',
                    //     right: 8,
                    //     top: 8,
                    //     color: (theme) => theme.palette.grey[500],
                    // }}
                >
                    <CloseIcon />
                </IconButton>
            </span>

            <DialogContent dividers>
                {props.children}
            </DialogContent>

            <DialogActions>
                <Button
                    disabled={cancelButtonDisabled}
                    onClick={handleClose}
                    sx={{
                        backgroundColor: cancelButtonColor === 'default' ? '#303E4E !important' : cancelButtonColor,
                        color: 'white !important',
                        fontWeight: 400,
                        borderRadius: '8px !important',
                        fontSize: '15px !important',
                        width: '100%',
                        height: '30px !important',
                        opacity: cancelButtonDisabled ? 0.5 : 1
                    }}
                >
                    {cancelButtonName}
                </Button>
                <Button 
                    disabled={confirmButtonDisabled}
                    onClick={handleConfirm}
                    sx={{
                        background: confirmButtonColor === 'default' ? '#5571C6 !important' : confirmButtonColor,
                        color: 'white !important',
                        fontWeight: 400,
                        borderRadius: '8px !important',
                        fontSize: '15px !important',
                        width: '100%',
                        height: '30px !important',
                        opacity: confirmButtonDisabled ? 0.5 : 1
                    }}
                >
                    {confirmButtonName}
                </Button>
            </DialogActions>
        </BootstrapDialog>
    </React.Fragment>
  );
}