import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'

import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { Footer } from 'shared/elements/FormItem/Footer'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { Loading } from 'shared/elements/Loading'

import {
    Allergy,
    AllergyReaction,
    AllergySeverity,
    ErrorType,
} from 'containers/PatientsSetting/interfaces'
import * as apis from 'services/Patient/patient'
import { formatDateTime } from 'utilities/formats'
import { useNotification } from 'shared/elements/Notification'

import { SearchAllergen } from 'shared/elements/FormItem/SearchComponents'
import { ALLERGY_STATUS, INITIAL_VALUES } from './schema'
import { usePatient } from 'containers/PatientsSetting/hooks'
import '../../styles.scss'
interface Props {
    patientId?: string
    encounter_id?: string
    allergyId?: string | null
    open?: boolean
    onClose?: (forceReload?: boolean) => void
    afterActionOk?: () => void
}

export const AllergiesForm = (props: Props) => {
    const { patientInfo } = usePatient(props.patientId)

    const notification = useNotification()

    const [initData, setInitData] = useState<Allergy>(INITIAL_VALUES)
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [listReaction, setListReaction] = useState<AllergyReaction[]>([])
    const [listSeverity, setListSeverity] = useState<AllergySeverity[]>([])

    useEffect(() => {
        const abortController = new AbortController()
        setDataLoading(true)

        Promise.allSettled([
            getListReaction(),
            getListSeverity(),
            props.allergyId
                ? getAllergyDetail(props.patientId as string, props.allergyId)
                : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])

    const handleSubmit = async (payload: Allergy) => {
        setIsSubmitting(true)

        const postData: Allergy = {
            ...payload,
            encounter_id: props.encounter_id as string,
            onset_date: formatDateTime(payload.onset_date, 'YYYY-MM-DD'),
        }

        try {
            if (props.allergyId) {
                await apis.putAllergy(props.patientId, props.allergyId, postData)
            } else {
                await apis.postAllergy(props.patientId, postData)
            }
            setIsSubmitting(false)

            const msg = props.allergyId
                ? `Allergy updated successfully!`
                : `Allergy created successfully!`

            setIsSubmitting(false)
            notification(msg, 'success')

            props.onClose && props.onClose(true)
            props.afterActionOk?.()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                (error as ErrorType)?.data?.message ||
                (props.allergyId
                    ? `Allergy updated unsuccessfully`
                    : `Allergy created unsuccessfully`)

            notification(errMsg, 'error')
        }
    }

    const getAllergyDetail = async (patientId: string, allergyId: string) => {
        try {
            const resp = await apis.getAllergyById(patientId, allergyId)

            if (resp && resp.data) {
                setInitData(resp.data as Allergy)
            }
        } catch (error) {
            notification(`Cannot get medication allergy ${allergyId}`, 'error')
        }
    }

    const getListReaction = async () => {
        try {
            const resp = await apis.getAllergiesReaction()
            setListReaction(resp.data.hits.hits as AllergyReaction[])
        } catch (error) {
            notification('Cannot get list reaction', 'error')
        }
    }

    const getListSeverity = async () => {
        try {
            const resp = await apis.getAllergiesSeverity()
            setListSeverity(resp.data.hits.hits as AllergySeverity[])
        } catch (error) {
            notification('Cannot get list severity', 'error')
        }
    }

    const validationSchema = yup.object({
        // severity: yup.string().required('Severity is required!'),
        severity: yup.string(),
        allergy: yup.object({
            genericName: yup.string(),
            coding: yup.array().when('genericName', (genericName, schema) => {
                return genericName
                    ? schema.of(
                          yup.object({
                              code: yup.string(),
                              display: yup.string(),
                          }),
                      )
                    : schema
                          .of(
                              yup.object({
                                  code: yup.string(),
                                  display: yup.string(),
                              }),
                          )
                          .test({
                              message: 'Allergen is required!',
                              test: (coding) => (coding?.length as number) > 0,
                          })
            }),

            text: yup.string(),
        }),
        status: yup.object({
            code: yup.string().required('Status is required!'),
            display: yup.string(),
        }),
        patient_id: yup.string(),
        note: yup.string(),
        reaction: yup.object({
            code: yup.string().required('Reaction is required!'),
            display: yup.string(),
        }),
        external_id: yup.string(),
        rcopia_id: yup.string(),
        onset_date: yup
            .date()
            .nullable()
            .typeError('Please input valid Onset Date')
            .min(moment(patientInfo.dob), `Onset Date must be after Patient's birth date`)
            .max(moment(), 'Onset Date must be before current date'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: initData,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={800}
            title={props.allergyId ? 'Update Medication Allergy' : 'Add Medication Allergy'}
            open={props.open}
            onClose={() => props.onClose && props.onClose(false)}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}>
            <Grid container>
                <Loading visible={dataLoading} />

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{
                            marginTop: 24,
                            marginBottom: 16,
                        }}>
                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid container item alignItems="flex-end" spacing={2}>
                                <Grid item xs={12}>
                                    <SearchAllergen
                                        label="Allergen"
                                        required
                                        name="allergy"
                                        value={formik.values.allergy}
                                        placeholder="Search allergen"
                                        error={
                                            Boolean(formik.touched.allergy?.coding) &&
                                            Boolean(formik.errors.allergy?.coding)
                                        }
                                        helperText={
                                            Boolean(formik.touched.allergy?.coding) &&
                                            (formik.errors.allergy?.coding as string)
                                        }
                                        onChange={(value) => formik.setFieldValue('allergy', value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item alignItems="flex-end" spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        label="Reaction"
                                        required
                                        name="reaction"
                                        value={formik.values.reaction?.code}
                                        placeholder="Select reaction"
                                        error={
                                            formik.touched.reaction?.code &&
                                            Boolean(formik.errors.reaction?.code)
                                        }
                                        helperText={
                                            formik.touched.reaction?.code &&
                                            formik.errors.reaction?.code
                                        }
                                        options={listReaction
                                            .map((opt) => ({
                                                key: opt._source.code,
                                                value: opt._source.display,
                                            }))
                                            .sort((option1, option2) => {
                                                let a = option1.value
                                                let b = option2.value
                                                return a === b ? 0 : a > b ? 1 : -1
                                            })}
                                        onChange={(value) =>
                                            formik.setFieldValue('reaction', {
                                                code: value,
                                                display: listReaction.find(
                                                    (reaction) => reaction._source.code === value,
                                                )?._source?.display,
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item alignItems="flex-end" spacing={2}>
                                <Grid item xs={12}>
                                    <Select
                                        label="Severity"
                                        name="severity"
                                        value={formik.values.severity}
                                        placeholder="Select severity"
                                        error={
                                            formik.touched.severity &&
                                            Boolean(formik.errors.severity)
                                        }
                                        helperText={
                                            formik.touched.severity && formik.errors.severity
                                        }
                                        options={listSeverity
                                            .map((opt) => ({
                                                key: opt._source.display,
                                                value: opt._source.display,
                                            }))
                                            .sort((option1, option2) => {
                                                let a = option1.value
                                                let b = option2.value
                                                return a === b ? 0 : a > b ? 1 : -1
                                            })}
                                        onChange={(value) =>
                                            formik.setFieldValue('severity', value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item alignItems="flex-end" spacing={2}>
                            <Grid item xs={12}>
                                <Select
                                    label="Status"
                                    required
                                    name="status"
                                    // disabled={!props.allergyId}
                                    value={formik.values.status?.code}
                                    placeholder="Select status"
                                    error={
                                        formik.touched.status?.code &&
                                        Boolean(formik.errors.status?.code)
                                    }
                                    helperText={
                                        formik.touched.status?.code && formik.errors.status?.code
                                    }
                                    options={ALLERGY_STATUS.map((opt) => ({
                                        key: opt._source.code,
                                        value: opt._source.display,
                                    }))}
                                    onChange={(value) =>
                                        formik.setFieldValue('status', { code: value })
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <DatePicker
                                label="Onset Date"
                                name="onsetdate"
                                maxDate={moment()}
                                minDate={moment(patientInfo.dob)}
                                value={formik.values.onset_date}
                                error={
                                    formik.touched.onset_date && Boolean(formik.errors.onset_date)
                                }
                                helperText={formik.touched.onset_date && formik.errors.onset_date}
                                onChange={(value) => formik.setFieldValue('onset_date', value)}
                                notDefaultValue
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextBox
                                label="Note"
                                name="note"
                                value={formik.values.note}
                                minRows={4}
                                multiline
                                placeholder="Enter note"
                                onChange={(value) => formik.setFieldValue('note', value)}
                            />
                        </Grid>

                        <Footer
                            loading={isSubmitting}
                            onCancel={() => props.onClose && props.onClose(false)}
                            onOk={() => formik.handleSubmit()}
                            okBtnText={props.allergyId ? 'Update' : 'Submit'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
