import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import LoadingPage from 'utilities/loading-page'
import CalendarScheduler from '.'
import Confirm from 'components/shared/Elements/Confirm'

const accessClenderScheduler = () => {
    const dataIsAvailable = useSelector((state) => state?.permission.isPermissionAvailable)
    const userData = useSelector((state) => state?.permission.scheduling)
    const checkAccess = () => {
        if (userData && !userData.includes('view_schedule')) {
            return false
        }
        return true
    }
    const [openConfirm, setOpenConfirm] = useState(true)
    return (
        <div>
            {!dataIsAvailable ?
                (<LoadingPage />) :
                checkAccess() ? (
                    <CalendarScheduler />
                ) : (
                    <Confirm
                        open={openConfirm}
                        btnText="Confirm"
                        message={'Sorry! You do not have permission to access this feature.'}
                        onCancel={() => setOpenConfirm(false)}
                        headerSize={15}
                    />
                )}
        </div>
    )
}

export default accessClenderScheduler