/* eslint-disable react/jsx-indent */
import MomentUtils from '@date-io/moment'
import lodash from 'lodash'
import {
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select as MatSelect,
    TextareaAutosize,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { Field, FieldArray, Form, Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { maxCharLength } from '../../../constants'
import { errorMessages } from '../../../constants/errorMessages'
import { providerProfileFieldsUpdated } from '../../../constants/ProviderProfileFormUpdated'
import { UserRoles } from '../../../constants/user-roles'
import NotificationPreferences from '../../../containers/NotificationPreferences'
import { saveNotificationPreferences } from '../../../services/Notification'
import { commonMasterData } from '../../../services/Provider/provider'
import {
    setError,
    setErrorMessage,
} from '../../../store/actions/loader'
import {
    getActiveProvidersList,
    getClientList,
    getSpecialityList,
    handleEditProviderProfile,
    handleSaveProviderProfile,
} from '../../../store/actions/provider'
import {
    formatPhone,
    getLoggedInUserRole,
} from '../../../utilities'
import { usNumberRegex } from '../../../utilities/regex'
import ProviderAssitantProviderView from './components/ProviderAssistantProviderView'
import ProviderAssociatedClinicView from './components/ProviderAssociatedClinicView'
import ProviderClientClinicView from './components/ProviderClientClinicView'
import ProviderEducationView from './components/ProviderEducationView'
import ProviderInNetworkInsuranceView from './components/ProviderInNetworkInsuranceView'
import ProviderLangageSpokenView from './components/ProviderLanuageSpoken'
import ProviderLicenseView from './components/ProviderLicenseView'
import ProviderTrainingView from './components/ProviderTrainingView'
import { ButtonSpinner } from '../../custom'

const useStyles = makeStyles({
    root: {
        width: '50px',
        height: '24px',
        padding: '0px',
    },
    switchBase: {
        color: '#818181',
        padding: '1px',
        '&$checked': {
            '& + $track': {
                backgroundColor: '#4c8080',
            },
        },
    },
    thumb: {
        color: 'white',
        width: '20px',
        height: '20px',
        margin: '1px',
    },
    track: {
        borderRadius: '20px',
        backgroundColor: '#818181',
        opacity: '1 !important',
        '&:after, &:before': {
            color: 'white',
            fontSize: '11px',
            position: 'absolute',
            top: '6px',
        },
        '&:after': {
            content: "'On'",
            left: '8px',
        },
        '&:before': {
            content: "'Off'",
            right: '7px',
        },
    },
    checked: {
        color: '#23bf58 !important',
        transform: 'translateX(26px) !important',
    },
})

const ProfileEdit = (props) => {
    const {
        profileDetails,
        isCreate,
        onEditCallback,
        onSaveCallback,
        preferences,
    } = props
    const dispatch = useDispatch()
    const userRole = getLoggedInUserRole()
    const initialValues = { ...profileDetails }
    const [formFields, setFormFields] = useState([])
    const customProviderProfileFields =
        providerProfileFieldsUpdated(isCreate)
    const fetchCommonMasterData = async () => {
        try {
            const res = await commonMasterData()
            const empType = []
            const inNwOptions = []
            const timeZoneOptions = []
            res.data &&
                res.data.employment_type &&
                res.data.employment_type.map((item) => {
                    empType.push({
                        name: item.label,
                        value: item.value,
                    })
                })
            res.data.in_network_insurance.map((item) => {
                inNwOptions.push({
                    insurance: item.value,
                    name: item.label,
                })
            })
            res.data.time_zone.map((item) => {
                timeZoneOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            customProviderProfileFields[2].section[8].fieldItems[0].options =
                empType
            customProviderProfileFields[2].section[11].fieldItems =
                inNwOptions
            customProviderProfileFields[2].section[9].fieldItems =
                res.data.language
            customProviderProfileFields[2].section[10].fieldItems[0].options =
                timeZoneOptions
            setFormFields(customProviderProfileFields)
        } catch (err) {
            console.log(err)
        }
    }
    const defaultLanguageSpoken = ['none']
    const initialLicense = {
        state_name: '',
        license_number: '',
        expiry_date: null,
        dea_number: '',
    }
    const initialAssistantProvider = 'none'
    const initialBoardCertification = { certified: '' }
    const initialSpeciality = { name: 'none' }
    const initialLanguageSpoken = 'none'
    const initialInNetworkInsurance = {
        image: '',
        insurance: 'none',
    }
    const initialEducation = {
        education: '',
        university: '',
    }

    const initialTraining = {
        training: '',
        university: '',
    }
    const initialAssociatedClinic = {
        label: '',
        value: '',
    }
    const initialClientClinic = { label: '', value: '' }

    if (isCreate) {
        initialValues.speciality = initialSpeciality
        initialValues.licenses = [initialLicense]
        initialValues.education = [initialEducation]
        initialValues.training = [initialTraining]
        initialValues.in_network_insurances = [
            initialInNetworkInsurance,
        ]
        initialValues.certification = [
            initialBoardCertification,
        ]
        initialValues.associated_clinic_id = [
            initialAssociatedClinic,
        ]
        initialValues.client_clinic_id = [
            initialClientClinic,
        ]
        initialValues.assitant_provider = [
            initialAssistantProvider,
        ]

        initialValues.user = {
            language: [initialLanguageSpoken],
            mob: '',
            email: '',
        }
        initialValues.dob = null
        initialValues.desc = ''
        initialValues.first_name = ''
        initialValues.last_name = ''
        initialValues.gender = 'none'
        initialValues.time_zone = 'none'
        initialValues.in_person_consult = ''
        initialValues.supervisor_id = 'none'
        initialValues.employment_type = 'none'
        initialValues.taxonomy_code = ''
        initialValues.group_tax_id = ''
        initialValues.group_npi_number = ''
        initialValues.npi_number = ''
        initialValues.appointment_duration = 'none'
        initialValues.followup_appointment_duration = 'none'
        initialValues.photo = ''
    } else {
        initialValues.speciality = initialValues.speciality
            ?.name
            ? initialValues.speciality
            : initialSpeciality
        initialValues.licenses = initialValues.licenses
            .length
            ? initialValues.licenses
            : [initialLicense]
        initialValues.education = initialValues.education
            .length
            ? initialValues.education
            : [initialEducation]
        initialValues.certification = lodash.isArray(
            initialValues.certification,
        )
            ? initialValues.certification
            : [initialBoardCertification]

        initialValues.training = lodash.isArray(
            initialValues.training,
        )
            ? initialValues.training
            : [initialTraining]
        initialValues.associated_clinic_id =
            lodash.isArray(
                initialValues.associated_clinic_id,
            ) &&
            initialValues.associated_clinic_id[0] !== null
                ? initialValues.associated_clinic_id
                : [initialAssociatedClinic]

        initialValues.client_clinic_id =
            lodash.isArray(
                initialValues.client_clinic_id,
            ) && initialValues.client_clinic_id[0] !== null
                ? initialValues.client_clinic_id
                : [initialClientClinic]
        initialValues.assitant_provider = lodash.isArray(
            initialValues.assitant_provider,
        )
            ? initialValues.assitant_provider
            : [initialAssistantProvider]
        initialValues.in_network_insurances =
            lodash.isArray(
                initialValues.in_network_insurances,
            )
                ? initialValues.in_network_insurances
                : [initialInNetworkInsurance]
        initialValues.user.mob = initialValues.user.language
            .length
            ? initialValues.user.mob
            : ''
        initialValues.phone_number = !!initialValues.user
            .mob
            ? initialValues.user.mob
            : ''
        initialValues.user.language = initialValues.user
            .language.length
            ? initialValues.user.language
            : [initialLanguageSpoken]
        initialValues.time_zone = initialValues.time_zone
            .length
            ? initialValues.time_zone
            : 'none'
        initialValues.supervisor_id =
            initialValues.supervisor_id === null ||
            initialValues.supervisor_id === '' ||
            initialValues.supervisor_id === 0
                ? 'none'
                : Number(initialValues.supervisor_id)
        initialValues.employment_type = initialValues
            .employment_type.length
            ? initialValues.employment_type
            : 'none'
        initialValues.taxonomy_code = initialValues
            .taxonomy_code.length
            ? initialValues.taxonomy_code
            : ''
        initialValues.group_tax_id = initialValues
            .group_tax_id.length
            ? initialValues.group_tax_id
            : ''
        initialValues.group_npi_number = initialValues
            .group_npi_number.length
            ? initialValues.group_npi_number
            : ''
        initialValues.npi_number = initialValues.npi_number
            .length
            ? initialValues.npi_number
            : ''
        initialValues.photo = initialValues.photo?.length
            ? initialValues.photo
            : ''
        initialValues.email_notification = preferences.email
        initialValues.sms_notification = preferences.sms
        initialValues.app_notification =
            preferences.app_notification
        initialValues.appointment_duration =
            initialValues.appointment_duration
        initialValues.followup_appointment_duration =
            initialValues.followup_appointment_duration
    }
    const [state, setState] = React.useState({
        checkedA: true,
    })

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        })
    }
    initialValues.intialBoardCertification = [{ name: '' }]
    initialValues.intialAssociatedClinic = [{ name: '' }]
    initialValues.intialClinetClinic = [{ name: '' }]

    const [formIntial, setFormIntial] = useState({
        ...initialValues,
    })
    const [isUserDetailChanged, setIsUserDetailChanged] =
        useState(false)
    const [
        isUserDetailEmailChanged,
        setIsUserDetailEmailChanged,
    ] = useState(false)
    const [
        isUserDetailMobChanged,
        setIsUserDetailMobChanged,
    ] = useState(false)
    const [minDateMajor, setMinDateMajor] = useState('')
    const [checkSuperAdmin, setCheckSuperAdmin] =
        useState(false)
    const errMsg = useSelector(
        (state) => state.loader.errorMsg,
    )
    const specialityList = useSelector(
        (state) => state.provider.specialityList?.data,
    )

    specialityList &&
        specialityList.map(
            (item) => (item['value'] = item.name),
        )
    const activeProviderList = useSelector(
        (state) => state.provider.providerList,
    )
    activeProviderList &&
        activeProviderList.map((item) => {
            (item['value'] = item.user_id),
                (item[
                    'name'
                ] = `${item.first_name} ${item.last_name}`)
        })
    const finalActiveProviderList =
        activeProviderList.filter(
            (item) => item.first_name !== '',
        )

    const referProvider = [
        {
            name: 'Provider 1',
            value: 'Provider 1',
        },
    ]

    Yup.addMethod(
        Yup.array,
        'unique',
        function (message, mapper = (a) => a) {
            return this.test(
                'unique',
                message,
                function (list) {
                    const nullCheck = list.includes('none')
                    if (nullCheck) {
                        return true
                    }
                    return (
                        list.length ===
                        new Set(list.map(mapper)).size
                    )
                },
            )
        },
    )

    const validateSchema = Yup.object().shape({
        first_name: Yup.string()
            .required(errorMessages.firstName)
            .trim()
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.firstName,
            ),
        last_name: Yup.string()
            .trim()
            .required(errorMessages.lastName)
            .matches(
                /^[aA-zZ\s]+$/,
                errorMessages.lastName,
            ),
        gender: Yup.string()
            .required(errorMessages.gender)
            .test(
                'gender',
                'Please select Gender',
                (answers) => answers !== 'none',
            ),
        desc: Yup.string().required(errorMessages.bio),
        dob: Yup.date()
            .required(errorMessages.dob)
            .nullable()
            .typeError(''),
        speciality: Yup.object().shape({
            name: Yup.string()
                .required('Please select Speciality')
                .test(
                    'name',
                    'Please select Speciality',
                    (answers) => answers !== 'none',
                ),
        }),
        time_zone: Yup.string()
            .required(errorMessages.timezone)
            .test(
                'time_zone',
                'Please select Timezone',
                (answers) => answers !== 'none',
            ),
        appointment_duration: Yup.number()
            .integer()
            .required(
                'Please select Initial Appointment Duration',
            )
            .typeError(
                'Please select Initial Appointment Duration',
            ),
        followup_appointment_duration: Yup.number()
            .integer()
            .typeError(
                'Please select Follow-up Appointment Duration',
            )
            .required(
                'Please select Follow-up Appointment Duration',
            )
            .max(
                Yup.ref('appointment_duration'),
                'Follow-up duration should be less than Initial Appointment duration',
            ),
        employment_type: Yup.string()
            .required(errorMessages.employmentType)
            .test(
                'employment_type',
                'Please select Employment Type',
                (answers) => answers !== 'none',
            ),
        taxonomy_code: Yup.string().required(
            errorMessages.taxonomyCode,
        ),
        group_tax_id: Yup.string().required(
            errorMessages.grouptaxid,
        ),
        group_npi_number: Yup.string().required(
            errorMessages.groupnpinumber,
        ),
        licenses: Yup.array()
            .of(
                Yup.object().shape({
                    state_name: Yup.string()
                        .required(
                            'Please enter valid State Name',
                        )
                        .trim()
                        .matches(
                            /^[aA-zZ\s]+$/,
                            'Please enter valid State Name',
                        ),
                    license_number: Yup.string().required(
                        'Please enter License Number',
                    ),
                    expiry_date: Yup.date()
                        .required(
                            'Please enter valid Expiry Date',
                        )
                        .nullable()
                        .typeError(''),

                    //Yup.date()
                    //   .required('Please select Expiry date')
                    //  .nullable()
                    //  .typeError(''),
                    // expiry_date: Yup.date()
                    //     .nullable()
                    //     .transform((curr, orig) => orig === '' ? null : curr)
                    //     .required('Required'),
                    // dea_number: Yup.string().required(
                    //     'Required',
                    // ),
                }),
            )
            .required('Must have License')
            .unique(
                'Duplicate License number',
                (a) => a.license_number,
            )
            .unique(
                'Duplicate DEA Number',
                (a) => a.dea_number,
            ),
        npi_number: Yup.string().required(
            errorMessages.npinumber,
        ),
        // mpn_number: Yup.string().required(
        //     errorMessages.mpnnumber,
        // ),
        assitant_provider: Yup.array().unique(
            'Duplicate Provider Selected',
            (a) => a,
        ),

        user: Yup.object().shape({
            language: Yup.array()
                .required('Must have Language')
                .min(1)
                .unique(
                    'Duplicate Language not allowed',
                    (a) => a,
                )
                .test(
                    'language',
                    'Please Select Language',
                    (answers) => {
                        return answers.some(
                            (answer) => answer !== 'none',
                        )
                    },
                ),
            email: Yup.string()
                .email(errorMessages.validEmailId)
                .required(errorMessages.validEmailId),
            mob: Yup.string().required(
                errorMessages.phoneNumber,
            ),
            // .matches(
            //     usNumberRegex,
            //     errorMessages.validPhoneNumber,
            // ),
        }),
        in_network_insurances: Yup.array()
            .test(
                'in_network_insurances',
                'Please select Insurance',
                (answers) => {
                    return answers.some(
                        (answer) =>
                            answer.insurance !== 'none',
                    )
                },
            )
            .unique(
                'Duplicate In Network Insurance not allowed',
                (a) => a.insurance,
            )
            .of(
                Yup.object().shape({
                    insurance: Yup.string().required(
                        'Please select Insurance',
                    ),
                }),
            )
            .required('Must have In network insurance'),
        education: Yup.array()
            .of(
                Yup.object().shape({
                    education: Yup.string().required(
                        'Please enter Education',
                    ),
                    university: Yup.string().required(
                        'Please enter University',
                    ),
                }),
            )
            .required('Must have Education'),
        training: Yup.array()
            .of(
                Yup.object().shape({
                    training: Yup.string().required(
                        'Please enter Training',
                    ),
                    university: Yup.string().required(
                        'Please enter University',
                    ),
                }),
            )
            .required('Must have Training'),
        in_person_consult: Yup.string().required(
            'Please select In Person Consult',
        ),
    })

    const lowercaseKeys = (obj) =>
        Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase()] = obj[key]
            return acc
        }, {})
    const getClinicData = (clinicData) => {
        if (!clinicData.length) return []
        if (
            clinicData.length === 1 &&
            clinicData[0].value === ''
        )
            return []
        const data = clinicData.flatMap(
            (item) => item.value,
        )
        return data
    }
    const getClinicArr = (clinicData) => {
        if (clinicData.length) {
            const hasValue =
                clinicData[
                    clinicData.length - 1
                ].hasOwnProperty('value')
            if (hasValue) {
                const idArry = []
                clinicData.map(
                    (item) => {
                        if (
                            item.value &&
                            item.value !== ''
                        ) {
                            idArry.push(item.value.value)
                        } else if (item.value === '') {
                            ('')
                        } else {
                            idArry.push(item)
                        }
                    },

                    // item.value && item.value !=='' ?
                    //     idArry.push(item.value.value): idArry.push(item) ,
                )
                return idArry
            }
            return clinicData
        }
    }
    const getChangedValues = (values, initial) => {
        return Object.entries(values).reduce(
            (acc, [key, value]) => {
                const hasChanged = initial[key] !== value
                if (hasChanged) {
                    acc[key] = value
                }
                return acc
            },
            {},
        )
    }
    const getSpecialityValue = (val) => {
        if (val !== null && val !== '') {
            const obj = specialityList.filter(
                (item) => item.value === val,
            )

            if (obj[0].value) {
                return obj[0].value
            }
        }
    }

    async function savePreferences(values, userId) {
        await saveNotificationPreferences({
            user_id: userId || profileDetails?.user?.id,
            email: values.email_notification || false,
            sms: values.sms_notification || false,
            app_notification:
                values.app_notification || false,
        })
    }

    const saveProfile = async (val, onSuccess) => {
        const changedVales = getChangedValues(
            val,
            formIntial,
        )
        const getSpecialityId = specialityList.filter(
            (item) => item.value === val.speciality.name,
        )
        const educationArr = val.education.map((item) =>
            lowercaseKeys(item),
        )
        const trainingArr = val.training.map((item) =>
            lowercaseKeys(item),
        )
        const formattedLanguageArr =
            val.user.language.filter((n) => n)
        const languageFinalArr =
            formattedLanguageArr.filter(
                (item) => item !== 'none',
            )
        const inNwInsuranceFinalArr =
            val.in_network_insurances.filter(
                (item) => item.insurance !== 'none',
            )

        const formattedEduationArr = educationArr.filter(
            (value) =>
                value.education !== '' &&
                value.university !== '',
        )
        const formattedTrainingArr = trainingArr.filter(
            (value) =>
                value.training !== '' &&
                value.university !== '',
        )
        const formattedCertification =
            val.certification.filter(
                (value) => value.certified !== '',
            )
        const finalCertification =
            formattedCertification.filter(
                (item) => item.certified !== null,
            )
        const assitantProviders =
            val.assitant_provider.filter(
                (item) => item !== 'none' && item !== '',
            )
        const supervisor_ids =
            val.supervisor_id === 'none'
                ? ''
                : val.supervisor_id
        const payload = {
            user_id: val.user_id,
            licenses: val.licenses,
            user: {
                language: languageFinalArr,
            },
            provider: {
                phone_number: val.user.mob,
                speciality: getSpecialityId[0].id,
                first_name: val.first_name,
                last_name: val.last_name,
                dob: val.dob,
                gender: val.gender,
                photo: val.photo,
                education: formattedEduationArr,
                training: formattedTrainingArr,
                certification: finalCertification,
                in_network_insurances:
                    inNwInsuranceFinalArr,
                practice_name: val.practice_name,
                group_tax_id: val.group_tax_id,
                npi_number: val.npi_number,
                mpn_number: val.mpn_number,
                taxonomy_code: val.taxonomy_code,
                professional_membership:
                    val.professional_membership,
                group_npi_number: val.group_npi_number,
                employment_type: val.employment_type,
                in_person_consult: val.in_person_consult,
                associated_clinic_id: getClinicArr(
                    val.associated_clinic_id,
                ),
                client_clinic_id: getClinicArr(
                    val.client_clinic_id,
                ),
                supervisor_id: supervisor_ids,
                desc: val.desc,
                time_zone: val.time_zone,
                appointment_type: [],
                assitant_provider: assitantProviders,
                appointment_duration:
                    val.appointment_duration,
                followup_appointment_duration:
                    val.followup_appointment_duration,
            },
        }
        const createPayload = {
            speciality: getSpecialityId[0].id,
            first_name: val.first_name,
            last_name: val.last_name,
            dob: val.dob,
            gender: val.gender,
            user_type: val.user.user_type,
            origin_source: val.user.origin_source,
            language: val.user.language,
            phone_number: val.user.mob,
            followup_appointment_duration:
                val.followup_appointment_duration,
            appointment_duration: val.appointment_duration,
            // process.env.REACT_APP_COUNTRY_CODE +
            // val.user.mob.replace(/[^\d]/g, ''),
            email: val.user.email,
            photo: val.photo,
            education: formattedEduationArr,
            training: formattedTrainingArr,
            certification: finalCertification,
            licenses: val.licenses,
            in_network_insurances:
                val.in_network_insurances,
            practice_name: val.practice_name,
            group_tax_id: val.group_tax_id,
            professional_membership:
                val.professional_membership,
            npi_number: val.npi_number,
            mpn_number: val.mpn_number,
            taxonomy_code: val.taxonomy_code,
            group_npi_number: val.group_npi_number,
            employment_type: val.employment_type,
            in_person_consult: val.in_person_consult,
            associated_clinic_id: getClinicArr(
                val.associated_clinic_id,
            ),
            client_clinic_id: getClinicArr(
                val.client_clinic_id,
            ),
            supervisor_id: supervisor_ids || 1,
            assitant_provider: assitantProviders,
            desc: val.desc,
            appointment_type: [],
            time_zone: val.time_zone,
        }
        if (isUserDetailChanged) {
            if (
                isUserDetailMobChanged &&
                initialValues?.user?.mob !== val.user.mob
            )
                payload.user.phone_number = val.user.mob
            // process.env.REACT_APP_COUNTRY_CODE +
            // val.user.mob.replace(/[^\d]/g, '')

            if (
                isUserDetailEmailChanged &&
                initialValues?.user?.email !==
                    val.user.email
            )
                payload.user.email = val.user.email
        }

        if (val.in_person_consult) {
            if (isCreate) {
                createPayload.appointment_type = []
                createPayload.appointment_type.push(
                    'tele-consultant',
                    'in-clinic',
                )
            } else {
                payload.provider.appointment_type = []
                payload.provider.appointment_type.push(
                    'tele-consultant',
                    'in-clinic',
                )
            }
        } else {
            if (isCreate) {
                createPayload.appointment_type = []
                createPayload.appointment_type.push(
                    'tele-consultant',
                )
            } else {
                payload.provider.appointment_type = []
                payload.provider.appointment_type.push(
                    'tele-consultant',
                )
            }
        }

        const actionMessage = isCreate
            ? 'Profile Created Successfully'
            : 'Profile Updated Successfully'
        if (isCreate) {
            return dispatch(
                handleSaveProviderProfile(
                    createPayload,
                    (res) => {
                        const user_id = res.data?.user_id
                        const { isUserExist } = res
                        const providerCreationResponse =
                            isUserExist
                                ? 'User Already Existed'
                                : actionMessage

                        onSuccess && onSuccess(user_id)

                        Swal.fire(
                            '',
                            providerCreationResponse,
                        ).then((result) => {
                            onSaveCallback()
                        })
                    },
                    (err) => {
                        if (
                            err.status === 409 &&
                            err.data?.error === 'Conflict'
                        ) {
                            Swal.fire(
                                '',
                                'User Already Existed',
                            )
                        }
                    },
                ),
            )
        } 
            return dispatch(
                handleEditProviderProfile(payload, () => {
                    onSuccess && onSuccess()
                    Swal.fire('', actionMessage).then(
                        (result) => {
                            onEditCallback()
                        },
                    )
                }),
            )
        
    }

    useEffect(() => {
        const test =
            userRole === UserRoles.TM2U_SUPER_ADMIN
                ? true
                : false
        setCheckSuperAdmin(test)
    })
    useEffect(() => {
        dispatch(getSpecialityList())
        dispatch(getClientList())
        dispatch(getActiveProvidersList())
        fetchCommonMasterData()
        const min_date = moment().subtract(18, 'years')
        const formattedDate = new Date(min_date.toDate())
        setMinDateMajor(formattedDate)
        const list = { ...formIntial }
        if (list.user && list.user['mob']) {
            list.user['mob'] = formatPhone(
                list.user['mob']
                    .replace(/\D/g, '')
                    .slice(-10),
            )
            setFormIntial(list)
        }
    }, [])

    useEffect(() => {
        if (errMsg) {
            Swal.fire('', 'User Already Existed', 'error')
        }
        dispatch(setError(false))
        dispatch(setErrorMessage(''))
    }, [errMsg])

    const classes = useStyles()
    return (
        <>
            <Grid container className="bio-main-wrap">
                <Formik
                    initialValues={formIntial}
                    onSubmit={async (values, touched) => {
                        const onSuccess = (userId) => {
                            savePreferences(values, userId)
                        }
                        await saveProfile(
                            values,
                            userRole ===
                                UserRoles.TM2U_SUPER_ADMIN
                                ? onSuccess
                                : undefined,
                        )
                    }}
                    enableReinitialize
                    validationSchema={validateSchema}>
                    {({
                        errors,
                        touched,
                        isValid,
                        values,
                        setTouched,
                        setFieldValue,
                        setFieldError,
                        isSubmitting,
                        dirty,
                        handleBlur,
                        setFieldTouched,
                    }) => {
                        const handleProviderDetails = (
                            val,
                            name,
                        ) => {
                            const newValue = val

                            if (name === 'email') {
                                setIsUserDetailEmailChanged(
                                    true,
                                )
                            }
                            if (name === 'mob') {
                                setIsUserDetailMobChanged(
                                    true,
                                )
                                // newValue = formatPhone(val);
                            }
                            setIsUserDetailChanged(true)
                            /*const list = { ...formIntial };
                            list.user[name] = newValue;
                            setFormIntial(list);*/
                        }
                        const handleSpeciality = (
                            val,
                            name,
                        ) => {
                            const list = { ...formIntial }
                            list.speciality['name'] = val
                            setFormIntial(list)
                        }
                        return (
                            <Form className="profile-edit-main-wrap editWrapper">
                                {formFields.map(
                                    (
                                        section,
                                        mainIndex,
                                    ) => (
                                        <>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    className={
                                                        section.maintitle ===
                                                        'Bio'
                                                            ? 'bio-title'
                                                            : 'bio-title mt2'
                                                    }>
                                                    {
                                                        section.maintitle
                                                    }
                                                    <span className="astrik">
                                                        {(section.maintitle ===
                                                            'Bio' ||
                                                            section.maintitle ===
                                                                'Provider Details') &&
                                                            '*'}
                                                    </span>
                                                </Typography>
                                                {section.section.map(
                                                    (
                                                        val,
                                                        i,
                                                    ) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    container
                                                                    className="dFlex"
                                                                    style={{
                                                                        width: val.isWidth41
                                                                            ? '41%'
                                                                            : val.isWidth57 &&
                                                                              '57%',
                                                                        float:
                                                                            val.isFloat &&
                                                                            'left',
                                                                        marginLeft:
                                                                            val.ml1 &&
                                                                            '1%',
                                                                        display:
                                                                            val.oneCol &&
                                                                            'flex',
                                                                        margin:
                                                                            val.extraMar &&
                                                                            '2em 0px',
                                                                        borderTop:
                                                                            val.sectionTitle ===
                                                                                'Notifications' &&
                                                                            '1px solid #B0BCE4',
                                                                        paddingTop:
                                                                            val.sectionTitle ===
                                                                                'Notifications' &&
                                                                            '1em',
                                                                        marginTop:
                                                                            val.sectionTitle ===
                                                                                'Notifications' &&
                                                                            '1em',
                                                                    }}>
                                                                    {!val.hideMainTitle && (
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                val.oneCol
                                                                                    ? 3
                                                                                    : 12
                                                                            }>
                                                                            <Typography className="detail-item-title boldTitle mt1 mb1">
                                                                                {
                                                                                    val.sectionTitle
                                                                                }
                                                                                {val.required &&
                                                                                    section.maintitle !==
                                                                                        'Bio' &&
                                                                                    section.maintitle !==
                                                                                        'Provider Details' && (
                                                                                        <span className="astrik">
                                                                                            *
                                                                                        </span>
                                                                                    )}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid
                                                                        item
                                                                        xs={
                                                                            val.mainGrid
                                                                                ? val.mainGrid
                                                                                : 12
                                                                        }
                                                                        className="dFlex ">
                                                                        <Grid
                                                                            container
                                                                            spacing={
                                                                                val.isSpacing &&
                                                                                1
                                                                            }>
                                                                            {val.sectionTitle ===
                                                                                'License' && (
                                                                                <FieldArray name="licenses">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                licenses,
                                                                                            },
                                                                                        } =
                                                                                            form

                                                                                        return licenses.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderLicenseView
                                                                                                        allValues={
                                                                                                            licenses
                                                                                                        }
                                                                                                        license={
                                                                                                            val
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialLicense={
                                                                                                            initialLicense
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        setFieldTouched={
                                                                                                            setFieldTouched
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'Languages Spoken' && (
                                                                                <FieldArray name="user.language">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                user,
                                                                                            },
                                                                                        } =
                                                                                            form

                                                                                        return user.language.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderLangageSpokenView
                                                                                                        language={
                                                                                                            val
                                                                                                        }
                                                                                                        allValues={
                                                                                                            user.language
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        handleBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialLanguageSpoken={
                                                                                                            initialLanguageSpoken
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        setFieldTouched={
                                                                                                            setFieldTouched
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'Education' && (
                                                                                <FieldArray name="education">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                education,
                                                                                            },
                                                                                        } =
                                                                                            form

                                                                                        return education.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderEducationView
                                                                                                        education={
                                                                                                            val
                                                                                                        }
                                                                                                        allValues={
                                                                                                            education
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialEducation={
                                                                                                            initialEducation
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        setFieldTouched={
                                                                                                            setFieldTouched
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'Training' && (
                                                                                <FieldArray name="training">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                training,
                                                                                            },
                                                                                        } =
                                                                                            form
                                                                                        return training.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderTrainingView
                                                                                                        training={
                                                                                                            val
                                                                                                        }
                                                                                                        allValues={
                                                                                                            training
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialTraining={
                                                                                                            initialTraining
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        setFieldTouched={
                                                                                                            setFieldTouched
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'In Network Insurance' && (
                                                                                <FieldArray name="in_network_insurances">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                in_network_insurances,
                                                                                            },
                                                                                        } =
                                                                                            form

                                                                                        return in_network_insurances.map(
                                                                                            (
                                                                                                ins,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderInNetworkInsuranceView
                                                                                                        optionsList={
                                                                                                            val
                                                                                                        }
                                                                                                        inNwInsurance={
                                                                                                            ins
                                                                                                        }
                                                                                                        allValues={
                                                                                                            in_network_insurances
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialInNetworkInsurance={
                                                                                                            initialInNetworkInsurance
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                        setFieldTouched={
                                                                                                            setFieldTouched
                                                                                                        }
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'Associated Clinics' && (
                                                                                <FieldArray name="associated_clinic_id">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                associated_clinic_id,
                                                                                            },
                                                                                        } =
                                                                                            form
                                                                                        return associated_clinic_id.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderAssociatedClinicView
                                                                                                        associatedClinic={
                                                                                                            val
                                                                                                        }
                                                                                                        allValues={
                                                                                                            associated_clinic_id
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialAssociatedClinic={
                                                                                                            initialAssociatedClinic
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}
                                                                            {val.sectionTitle ===
                                                                                'Client Clinics' && (
                                                                                <FieldArray name="client_clinic_id">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                client_clinic_id,
                                                                                            },
                                                                                        } =
                                                                                            form
                                                                                        return client_clinic_id.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderClientClinicView
                                                                                                        clientClinic={
                                                                                                            val
                                                                                                        }
                                                                                                        allValues={
                                                                                                            client_clinic_id
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialClientClinic={
                                                                                                            initialClientClinic
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}

                                                                            {val.sectionTitle ===
                                                                                'Board Certifications' && (
                                                                                <FieldArray name="certification">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values,
                                                                                        } =
                                                                                            form
                                                                                        const {
                                                                                            certification,
                                                                                        } =
                                                                                            values
                                                                                        return certification.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => (
                                                                                                <Grid
                                                                                                    item
                                                                                                    style={{
                                                                                                        paddingTop:
                                                                                                            i !==
                                                                                                                0 &&
                                                                                                            '1em',
                                                                                                    }}
                                                                                                    xs={
                                                                                                        12
                                                                                                    }
                                                                                                    className="dFlex"
                                                                                                    key={
                                                                                                        i
                                                                                                    }>
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={
                                                                                                            5
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }>
                                                                                                        <Field
                                                                                                            name={`certification[${i}].certified`}
                                                                                                            component={
                                                                                                                TextField
                                                                                                            }
                                                                                                            fullWidth
                                                                                                            variant="outlined"
                                                                                                            placeholder="Diploma/Degree/Master/Doctorate"
                                                                                                            onChange={(
                                                                                                                e,
                                                                                                            ) => {
                                                                                                                setFieldValue(
                                                                                                                    `certification[${i}].certified`,
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value,
                                                                                                                )
                                                                                                            }}
                                                                                                            value={
                                                                                                                certification[
                                                                                                                    i
                                                                                                                ]
                                                                                                                    .certified
                                                                                                            }
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={
                                                                                                            2
                                                                                                        }
                                                                                                        className="flex">
                                                                                                        {i !==
                                                                                                            0 &&
                                                                                                            i ===
                                                                                                                certification.length -
                                                                                                                    1 && (
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="plus-wrap"
                                                                                                                    onClick={() =>
                                                                                                                        push(
                                                                                                                            initialBoardCertification,
                                                                                                                        )
                                                                                                                    }>
                                                                                                                    +
                                                                                                                </button>
                                                                                                            )}

                                                                                                        {i ===
                                                                                                            0 &&
                                                                                                            (certification.length ===
                                                                                                            1 ? (
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="plus-wrap"
                                                                                                                    onClick={() =>
                                                                                                                        push(
                                                                                                                            initialBoardCertification,
                                                                                                                        )
                                                                                                                    }>
                                                                                                                    +
                                                                                                                </button>
                                                                                                            ) : (
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="plus-wrap"
                                                                                                                    onClick={() =>
                                                                                                                        remove(
                                                                                                                            i,
                                                                                                                        )
                                                                                                                    }>
                                                                                                                    -
                                                                                                                </button>
                                                                                                            ))}
                                                                                                        {i !==
                                                                                                            0 && (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="plus-wrap "
                                                                                                                onClick={() =>
                                                                                                                    remove(
                                                                                                                        i,
                                                                                                                    )
                                                                                                                }>
                                                                                                                -
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            ),
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                            )}

                                                                            {val?.fieldItems.map(
                                                                                (
                                                                                    item,
                                                                                    index,
                                                                                ) => (
                                                                                    <>
                                                                                        {item.inputType ===
                                                                                            'textField' && (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    item.gridSize
                                                                                                }
                                                                                                style={{
                                                                                                    padding:
                                                                                                        item.name ===
                                                                                                        'npi_number'
                                                                                                            ? '4px 4px 4px 0px'
                                                                                                            : item.name ===
                                                                                                                  'mpn_number' &&
                                                                                                              '4px 0px 4px 4px',
                                                                                                }}>
                                                                                                <TextField
                                                                                                    autoComplete="off"
                                                                                                    name={
                                                                                                        item.name
                                                                                                    }
                                                                                                    fullWidth
                                                                                                    id={
                                                                                                        item.id
                                                                                                    }
                                                                                                    disabled={
                                                                                                        item.readOnly
                                                                                                    }
                                                                                                    variant="outlined"
                                                                                                    placeholder={
                                                                                                        item.placeHolder
                                                                                                    }
                                                                                                    inputProps={{
                                                                                                        maxLength:
                                                                                                            item.name ===
                                                                                                            'mob'
                                                                                                                ? 14
                                                                                                                : maxCharLength,
                                                                                                        min: 0,
                                                                                                    }}
                                                                                                    onBlur={
                                                                                                        handleBlur
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) => {
                                                                                                        item.name ===
                                                                                                            'email' ||
                                                                                                        item.name ===
                                                                                                            'mob'
                                                                                                            ? (setFieldValue(
                                                                                                                  `user.${item.name}`,
                                                                                                                  item.name ===
                                                                                                                      'mob'
                                                                                                                      ? formatPhone(
                                                                                                                            e
                                                                                                                                .target
                                                                                                                                .value,
                                                                                                                        )
                                                                                                                      : e
                                                                                                                            .target
                                                                                                                            .value,
                                                                                                              ),
                                                                                                              handleProviderDetails(
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                                  item.name,
                                                                                                              ))
                                                                                                            : setFieldValue(
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .name,
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                              )
                                                                                                    }}
                                                                                                    value={
                                                                                                        item.name ===
                                                                                                            'email' ||
                                                                                                        item.name ===
                                                                                                            'mob'
                                                                                                            ? values
                                                                                                                  .user[
                                                                                                                  item
                                                                                                                      .name
                                                                                                              ]
                                                                                                            : values[
                                                                                                                  item
                                                                                                                      .name
                                                                                                              ]
                                                                                                    }
                                                                                                />

                                                                                                {touched.user
                                                                                                    ? errors.user &&
                                                                                                      touched.user &&
                                                                                                      touched
                                                                                                          .user[
                                                                                                          item
                                                                                                              .name
                                                                                                      ] &&
                                                                                                      errors
                                                                                                          .user[
                                                                                                          item
                                                                                                              .name
                                                                                                      ] && (
                                                                                                          <FormHelperText
                                                                                                              error>
                                                                                                              {
                                                                                                                  errors
                                                                                                                      .user[
                                                                                                                      item
                                                                                                                          .name
                                                                                                                  ]
                                                                                                              }
                                                                                                          </FormHelperText>
                                                                                                      )
                                                                                                    : errors.user &&
                                                                                                      touched[
                                                                                                          item
                                                                                                              .name
                                                                                                      ] &&
                                                                                                      errors
                                                                                                          .user[
                                                                                                          item
                                                                                                              .name
                                                                                                      ] && (
                                                                                                          <FormHelperText
                                                                                                              error>
                                                                                                              {
                                                                                                                  errors
                                                                                                                      .user[
                                                                                                                      item
                                                                                                                          .name
                                                                                                                  ]
                                                                                                              }
                                                                                                          </FormHelperText>
                                                                                                      )}
                                                                                                {errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ] &&
                                                                                                    touched[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                            </Grid>
                                                                                        )}
                                                                                        {item.inputType ===
                                                                                            'dateField' && (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    item.gridSize
                                                                                                }>
                                                                                                <MuiPickersUtilsProvider
                                                                                                    utils={
                                                                                                        MomentUtils
                                                                                                    }>
                                                                                                    <KeyboardDatePicker
                                                                                                        id={
                                                                                                            item.id
                                                                                                        }
                                                                                                        className="ba-input-fields ba-date-input"
                                                                                                        disabled={
                                                                                                            item.readOnly
                                                                                                        }
                                                                                                        inputVariant="outlined"
                                                                                                        variant="inline"
                                                                                                        format="MM/DD/YYYY"
                                                                                                        name={
                                                                                                            item.name
                                                                                                        }
                                                                                                        maxDate={
                                                                                                            minDateMajor
                                                                                                        }
                                                                                                        autoOk={
                                                                                                            true
                                                                                                        }
                                                                                                        placeholder={
                                                                                                            'MM/DD/YYYY'
                                                                                                        }
                                                                                                        value={
                                                                                                            values[
                                                                                                                item
                                                                                                                    .name
                                                                                                            ]
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            date,
                                                                                                        ) => {
                                                                                                            if (
                                                                                                                date
                                                                                                            ) {
                                                                                                                setFieldValue(
                                                                                                                    item.name,
                                                                                                                    moment(
                                                                                                                        date,
                                                                                                                    )
                                                                                                                        .format()
                                                                                                                        .split(
                                                                                                                            'T',
                                                                                                                        )[0],
                                                                                                                )
                                                                                                            }
                                                                                                        }}
                                                                                                        onBlur={
                                                                                                            handleBlur
                                                                                                        }
                                                                                                    />
                                                                                                </MuiPickersUtilsProvider>
                                                                                                {errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ] &&
                                                                                                    touched[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                            </Grid>
                                                                                        )}
                                                                                        {item.inputType ===
                                                                                            'textArea' && (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    item.gridSize
                                                                                                }>
                                                                                                <TextareaAutosize
                                                                                                    name={
                                                                                                        item.name
                                                                                                    }
                                                                                                    className="bio-edit-wrap"
                                                                                                    onBlur={
                                                                                                        handleBlur
                                                                                                    }
                                                                                                    fullWidth
                                                                                                    id={
                                                                                                        item.id
                                                                                                    }
                                                                                                    variant="outlined"
                                                                                                    placeholder={
                                                                                                        item.placeHolder
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) => {
                                                                                                        setFieldValue(
                                                                                                            e
                                                                                                                .target
                                                                                                                .name,
                                                                                                            e
                                                                                                                .target
                                                                                                                .value,
                                                                                                        )
                                                                                                    }}
                                                                                                    value={
                                                                                                        values[
                                                                                                            item
                                                                                                                .name
                                                                                                        ]
                                                                                                    }
                                                                                                />
                                                                                                {errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ] &&
                                                                                                    touched[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                            </Grid>
                                                                                        )}

                                                                                        {item.inputType ===
                                                                                            'dropDown' && (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    item.gridSize
                                                                                                }>
                                                                                                <MatSelect
                                                                                                    name={
                                                                                                        item.name
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e,
                                                                                                    ) => {
                                                                                                        val.sectionTitle ===
                                                                                                        'Specialty'
                                                                                                            ? setFieldValue(
                                                                                                                  `speciality.name`,
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                              )
                                                                                                            : setFieldValue(
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .name,
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                              )
                                                                                                    }}
                                                                                                    id={
                                                                                                        item.id
                                                                                                    }
                                                                                                    className="ba-input-fields"
                                                                                                    variant="outlined"
                                                                                                    placeholder={
                                                                                                        item.placeHolder
                                                                                                    }
                                                                                                    defaultValue="none"
                                                                                                    value={
                                                                                                        val.sectionTitle ===
                                                                                                        'Specialty'
                                                                                                            ? values[
                                                                                                                  item
                                                                                                                      .name
                                                                                                              ][
                                                                                                                  'name'
                                                                                                              ]
                                                                                                            : values[
                                                                                                                  item
                                                                                                                      .name
                                                                                                              ]
                                                                                                    }
                                                                                                    IconComponent={
                                                                                                        ExpandMoreIcon
                                                                                                    }
                                                                                                    onBlur={
                                                                                                        handleBlur
                                                                                                    }
                                                                                                    disabled={
                                                                                                        item.readOnly
                                                                                                    }>
                                                                                                    <MenuItem
                                                                                                        disabled
                                                                                                        value="none">
                                                                                                        {
                                                                                                            <span className="opacity05">
                                                                                                                {
                                                                                                                    item.placeHolder
                                                                                                                }
                                                                                                            </span>
                                                                                                        }
                                                                                                    </MenuItem>
                                                                                                    {val.sectionTitle ===
                                                                                                    'Specialty'
                                                                                                        ? specialityList &&
                                                                                                          specialityList.map(
                                                                                                              (
                                                                                                                  el,
                                                                                                              ) => {
                                                                                                                  return (
                                                                                                                      <MenuItem
                                                                                                                          className="pl-select-option"
                                                                                                                          value={
                                                                                                                              el.value
                                                                                                                          }
                                                                                                                          key={
                                                                                                                              el.value
                                                                                                                          }>
                                                                                                                          {
                                                                                                                              el.name
                                                                                                                          }
                                                                                                                      </MenuItem>
                                                                                                                  )
                                                                                                              },
                                                                                                          )
                                                                                                        : item.options.map(
                                                                                                              (
                                                                                                                  el,
                                                                                                              ) => {
                                                                                                                  return (
                                                                                                                      <MenuItem
                                                                                                                          className="pl-select-option"
                                                                                                                          value={
                                                                                                                              el.value
                                                                                                                          }
                                                                                                                          key={
                                                                                                                              el.value
                                                                                                                          }>
                                                                                                                          {el.name ||
                                                                                                                              el.label}
                                                                                                                      </MenuItem>
                                                                                                                  )
                                                                                                              },
                                                                                                          )}
                                                                                                </MatSelect>
                                                                                                {item.name ===
                                                                                                    'specialty' &&
                                                                                                    errors.speciality &&
                                                                                                    touched[
                                                                                                        'speciality'
                                                                                                    ] &&
                                                                                                    errors
                                                                                                        .speciality
                                                                                                        ?.name && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ][
                                                                                                                    'name'
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                                {item.name !==
                                                                                                    'speciality' &&
                                                                                                    errors[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] &&
                                                                                                    touched[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                            </Grid>
                                                                                        )}
                                                                                        {item.inputType ===
                                                                                            'checkbox' && (
                                                                                            <RadioGroup
                                                                                                style={{
                                                                                                    alignItems:
                                                                                                        'center',
                                                                                                }}
                                                                                                aria-label={
                                                                                                    item.name
                                                                                                }
                                                                                                name={
                                                                                                    item.name
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) => {
                                                                                                    setFieldValue(
                                                                                                        item.name,
                                                                                                        e
                                                                                                            .target
                                                                                                            .value ===
                                                                                                            'true'
                                                                                                            ? true
                                                                                                            : false,
                                                                                                    )
                                                                                                }}
                                                                                                row={
                                                                                                    true
                                                                                                }>
                                                                                                {item.options.map(
                                                                                                    (
                                                                                                        el,
                                                                                                        index,
                                                                                                    ) => (
                                                                                                        <div
                                                                                                            key={`${index}-form-label`}
                                                                                                            style={{
                                                                                                                width: '50%',
                                                                                                            }}>
                                                                                                            <FormControlLabel
                                                                                                                className="ba-input-label-profile"
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                                value={
                                                                                                                    el.value
                                                                                                                }
                                                                                                                control={
                                                                                                                    <Radio color="primary" />
                                                                                                                }
                                                                                                                label={
                                                                                                                    el.label
                                                                                                                }
                                                                                                                checked={
                                                                                                                    values[
                                                                                                                        item
                                                                                                                            .name
                                                                                                                    ] ===
                                                                                                                    el.value
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    ),
                                                                                                )}
                                                                                                {errors[
                                                                                                    item
                                                                                                        .name
                                                                                                ] &&
                                                                                                    touched[
                                                                                                        item
                                                                                                            .name
                                                                                                    ] && (
                                                                                                        <FormHelperText
                                                                                                            error>
                                                                                                            {
                                                                                                                errors[
                                                                                                                    item
                                                                                                                        .name
                                                                                                                ]
                                                                                                            }
                                                                                                        </FormHelperText>
                                                                                                    )}
                                                                                            </RadioGroup>
                                                                                        )}
                                                                                    </>
                                                                                ),
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {val.sectionTitle ===
                                                                    'Reporting To' &&
                                                                    val.hideContent && (
                                                                        <>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="dFlex"
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                }}>
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    className="mr1">
                                                                                    <Typography className="detail-item-title boldTitle mt1 mb1">
                                                                                        {
                                                                                            val.sectionTitle
                                                                                        }
                                                                                    </Typography>
                                                                                    <Grid
                                                                                        container>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                5
                                                                                            }>
                                                                                            <MatSelect
                                                                                                name={
                                                                                                    values.supervisor_id
                                                                                                }
                                                                                                onChange={(
                                                                                                    e,
                                                                                                ) => {
                                                                                                    setFieldValue(
                                                                                                        'supervisor_id',
                                                                                                        e
                                                                                                            .target
                                                                                                            .value,
                                                                                                    )
                                                                                                }}
                                                                                                id={
                                                                                                    'supervisor_id'
                                                                                                }
                                                                                                className="ba-input-fields"
                                                                                                variant="outlined"
                                                                                                defaultValue="none"
                                                                                                value={
                                                                                                    values[
                                                                                                        'supervisor_id'
                                                                                                    ]
                                                                                                }
                                                                                                IconComponent={
                                                                                                    ExpandMoreIcon
                                                                                                }>
                                                                                                <MenuItem
                                                                                                    className="pl-select-option"
                                                                                                    disabled
                                                                                                    value="none">
                                                                                                    <span className="opacity05">
                                                                                                        {
                                                                                                            'Select provider'
                                                                                                        }
                                                                                                    </span>
                                                                                                </MenuItem>
                                                                                                {finalActiveProviderList.map(
                                                                                                    (
                                                                                                        el,
                                                                                                    ) => {
                                                                                                        return (
                                                                                                            <MenuItem
                                                                                                                className="pl-select-option"
                                                                                                                value={
                                                                                                                    el.value
                                                                                                                }
                                                                                                                key={
                                                                                                                    el.value
                                                                                                                }>
                                                                                                                {
                                                                                                                    el.name
                                                                                                                }
                                                                                                            </MenuItem>
                                                                                                        )
                                                                                                    },
                                                                                                )}
                                                                                            </MatSelect>
                                                                                        </Grid>
                                                                                        {values[
                                                                                            'supervisor_id'
                                                                                        ] !==
                                                                                            'none' && (
                                                                                            <Grid
                                                                                                item
                                                                                                xs={
                                                                                                    2
                                                                                                }
                                                                                                className="dEnd">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="plus-wrap"
                                                                                                    onClick={() =>
                                                                                                        setFieldValue(
                                                                                                            `supervisor_id`,
                                                                                                            'none',
                                                                                                        )
                                                                                                    }>
                                                                                                    -
                                                                                                </button>
                                                                                            </Grid>
                                                                                        )}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }>
                                                                                <Typography className="detail-item-title boldTitle mt1 mb1">
                                                                                    Reporting
                                                                                    Providers
                                                                                </Typography>
                                                                                <FieldArray name="assitant_provider">
                                                                                    {(
                                                                                        fieldArrayProps,
                                                                                    ) => {
                                                                                        const {
                                                                                            push,
                                                                                            remove,
                                                                                            form,
                                                                                        } =
                                                                                            fieldArrayProps
                                                                                        const {
                                                                                            values: {
                                                                                                assitant_provider,
                                                                                                supervisor_id,
                                                                                            },
                                                                                        } =
                                                                                            form

                                                                                        return assitant_provider.map(
                                                                                            (
                                                                                                val,
                                                                                                i,
                                                                                            ) => {
                                                                                                return (
                                                                                                    <ProviderAssitantProviderView
                                                                                                        assistantProvider={
                                                                                                            val
                                                                                                        }
                                                                                                        supervisor_id={
                                                                                                            supervisor_id
                                                                                                        }
                                                                                                        allValues={
                                                                                                            assitant_provider
                                                                                                        }
                                                                                                        index={
                                                                                                            i
                                                                                                        }
                                                                                                        fieldArrayProps={
                                                                                                            fieldArrayProps
                                                                                                        }
                                                                                                        setFieldValue={
                                                                                                            setFieldValue
                                                                                                        }
                                                                                                        initialAssistantProvider={
                                                                                                            initialAssistantProvider
                                                                                                        }
                                                                                                        key={
                                                                                                            i
                                                                                                        }
                                                                                                    />
                                                                                                )
                                                                                            },
                                                                                        )
                                                                                    }}
                                                                                </FieldArray>
                                                                                {errors?.assitant_provider && (
                                                                                    <FormHelperText
                                                                                        error>
                                                                                        {
                                                                                            errors?.assitant_provider
                                                                                        }
                                                                                    </FormHelperText>
                                                                                )}
                                                                            </Grid>
                                                                        </>
                                                                    )}
                                                                {/*{userRole ===*/}
                                                                {/*    UserRoles.TM2U_SUPER_ADMIN &&*/}
                                                                {/*    val.sectionTitle ===*/}
                                                                {/*        'Notifications' && (*/}
                                                                {/*        <Grid*/}
                                                                {/*            container*/}
                                                                {/*            spacing={*/}
                                                                {/*                2*/}
                                                                {/*            }>*/}
                                                                {/*            <Grid*/}
                                                                {/*                container*/}
                                                                {/*                item>*/}
                                                                {/*                {val.fieldItems.map(*/}
                                                                {/*                    (*/}
                                                                {/*                        item,*/}
                                                                {/*                        index,*/}
                                                                {/*                    ) => {*/}
                                                                {/*                        return (*/}
                                                                {/*                            <Grid*/}
                                                                {/*                                item*/}
                                                                {/*                                xs={*/}
                                                                {/*                                    item.gridSize*/}
                                                                {/*                                }*/}
                                                                {/*                                key={*/}
                                                                {/*                                    index*/}
                                                                {/*                                }>*/}
                                                                {/*                                <NotificationPreferences*/}
                                                                {/*                                    label={*/}
                                                                {/*                                        item.label*/}
                                                                {/*                                    }*/}
                                                                {/*                                    name={*/}
                                                                {/*                                        item.name*/}
                                                                {/*                                    }*/}
                                                                {/*                                    value={*/}
                                                                {/*                                        values[*/}
                                                                {/*                                            item*/}
                                                                {/*                                                .name*/}
                                                                {/*                                        ]*/}
                                                                {/*                                    }*/}
                                                                {/*                                    onChange={(*/}
                                                                {/*                                        e,*/}
                                                                {/*                                    ) => {*/}
                                                                {/*                                        setFieldValue(*/}
                                                                {/*                                            e*/}
                                                                {/*                                                .target*/}
                                                                {/*                                                .name,*/}
                                                                {/*                                            !values[*/}
                                                                {/*                                                item*/}
                                                                {/*                                                    .name*/}
                                                                {/*                                            ],*/}
                                                                {/*                                        )*/}
                                                                {/*                                    }}*/}
                                                                {/*                                />*/}
                                                                {/*                            </Grid>*/}
                                                                {/*                        )*/}
                                                                {/*                    },*/}
                                                                {/*                )}*/}
                                                                {/*            </Grid>*/}
                                                                {/*        </Grid>*/}
                                                                {/*    )}*/}
                                                            </>
                                                        )
                                                    },
                                                )}
                                            </Grid>
                                        </>
                                    ),
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    className="mt2 subnitprobtn">
                                    <ButtonSpinner
                                        className="btn-primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            !(
                                                isValid &&
                                                dirty
                                            ) ||
                                            isSubmitting
                                        }
                                        isLoading={Boolean(
                                            isSubmitting,
                                        )}
                                        label={'Save'}
                                    />
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>
        </>
    )
}
export default ProfileEdit
