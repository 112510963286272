import { Box, Grid, TableCell, TableRow, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { convertCharToIndex, convertIndexToChar } from 'shared/utils'
import DeleteIcon from '../../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../../shared/Elements/AlertDeleteDialog'
import TooltipTextField from '../../../shared/Elements/TooltipTextField'
import SelectEl from './Select/index'

const useStyles = makeStyles({
    table: {
        '& .MuiTableCell-root': {
            border: 'unset',
            width: '16.28%',
            boxSizing: 'border-box',
            padding: 8,
            '&:last-child': {
                width: '6%',
            },
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            marginBottom: '-12px',
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    colorContent: {
        color: '#667689',
        width: '80%',
    },
    ellipsis__text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    cover__add__new: {
        cursor: 'pointer',
        width: 'fit-content',
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
    text__field: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e9ecf1 !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#667689',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important',
        },
    },
    search_input: {
        width: 'auto',
        height: 40,
        overflow: 'hidden',
        transition: 'height 0.2s',
    },
    header: {
        fontSize: 19,
        color: '#303E4E',
    },
    subContent: {
        margin: '0 !important',
        borderRadius: '0 !important',
    },
    boderContentSub: {
        borderRadius: 8,
    },
    wrapContent: {
        margin: '16px 0 0 0 !important',
        borderRadius: '0 !important',
        background: '#E9ECF1 !important',
    },
    warpViewContainer: {
        marginBottom: 16,
    },
})

const RenderAddOrRemove = ({ index, handleAddOrRemove, type, code, disabled, row }) => {
    const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
    return (
        <Box sx={{ width: 40, display: 'flex' }}>
            <IconButton
                variant="outlined"
                onClick={() => {
                    setAlertDeleteOpen(true)
                }}
                disabled={disabled}>
                <DeleteIcon />
            </IconButton>
            <AlertDeleteDialog
                open={alertDeleteOpen}
                handleClose={() => {
                    setAlertDeleteOpen(false)
                }}
                handleDelete={() => {
                    handleAddOrRemove('remove', index, type, code, row)
                    setAlertDeleteOpen(false)
                }}
            />
        </Box>
    )
}

const RenderTextField = ({ name, value, placeholder = '', handleInputChange, disabled }) => {
    const classes = useStyles()
    return (
        <TooltipTextField
            className={classes.text__field}
            fullWidth
            variant="outlined"
            name={name}
            placeholder={placeholder}
            size="small"
            value={value}
            disabled={disabled}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value.replace(/[^0-9]+/g, ''))
            }}
        />
    )
}
const DxTextField = ({ name, value = '', placeholder = '', handleInputChange, disabled }) => {
    const classes = useStyles()
    return (
        <Box
            sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #e9ecf1 !important',
                },
                '& .MuiOutlinedInput-root': {
                    color: '#667689',
                    width: 44,
                },
                '& .MuiInputBase-input': {
                    textOverflow: 'ellipsis !important',
                },
                '& .MuiTextField-root': {
                    width: 'fit-content !important',
                },
            }}>
            <TextField
                variant="outlined"
                name={name}
                inputProps={{
                    maxLength: 1,
                }}
                type="text"
                size="small"
                value={value}
                onChange={(e) => {
                    const { name, value } = e.target
                    if ((value >= 'A' && value <= 'Z') || (value >= 'a' && value <= 'z')) {
                        handleInputChange(name, value.toUpperCase())
                    } else if (!value) {
                        handleInputChange(name, value)
                    }
                }}
                disabled={disabled}
            />
        </Box>
    )
}

const RenderDropDow = ({ options, name, handleInputChange, value, disabled }) => {
    const classes = useStyles()
    return (
        <HtmlTooltip
            title={Boolean(value) ? value : ''}
            arrow
            placement="top"
            style={{
                background: 'white !important',
                color: '#303E4E',
            }}>
            <SelectEl
                clearAble={true}
                name={name}
                options={options}
                placeholder="- Select One -"
                value={value}
                selectProps={{
                    style: {
                        height: 41,
                        fontSize: 14,
                    },
                }}
                disabled={disabled}
                renderValue={(sel) => {
                    return !value ? (
                        <Typography className={classes.colorContent}>- Select One -</Typography>
                    ) : (
                        <Typography className={clsx(classes.ellipsis__text, classes.colorContent)}>
                            {options.find((item) => item.code === sel)?.display}
                        </Typography>
                    )
                }}
                onChange={(e) => {
                    const { name, value } = e?.target || {}
                    handleInputChange(name, value)
                }}
            />
        </HtmlTooltip>
    )
}

const DxProvider = ({ valueDefault, onChange, disabled }) => {
    const [state, setState] = useState(() => {
        return valueDefault.split(':').map((item) => convertIndexToChar(parseInt(item)))
    })

    useEffect(() => {
        onChange &&
            onChange(
                state
                    .map((item) => {
                        return item ? convertCharToIndex(item) : 0
                    })
                    .join(':'),
            )
    }, [state])

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-around"
            style={{ width: 250 }}>
            {state.map((item, index) => (
                <>
                    <DxTextField
                        name={'dxPointers'}
                        // placeholder={'Place Holder'}
                        value={item}
                        handleInputChange={(name, value) => {
                            setState((prev) => prev.map((i, a) => (index === a ? value : i)))
                        }}
                        disabled={disabled}
                    />
                    {index !== 3 && ':'}
                </>
            ))}
        </Grid>
    )
}

const RoleSectionRow = ({
    roleOptions,
    participationOptions,
    rows,
    selfRows,
    customRows,
    handleChange,
    dataContent,
    type,
    handleAddOrRemove,
    // dataModifiers,
    headerSection,
    handleOpenModal,
    read_only,
    ...props
}) => {
    const classes = useStyles()
    const dataModifiers = props.dataModifiers
    const handleInputChange = (name, value, index, location, type) => {
        if (type !== 'icd') {
            const row = dataContent[index]?.extension || {}
            row[name] = value || ''
            handleChange(dataContent)
        }
    }

    function hasDuplicates(arr) {
        return new Set(arr).size !== arr.length
    }

    const handleInputChangeModifiers = (name, value, index, location, type) => {
        if (type !== 'icd') {
            handleChange(dataContent)
            const row = dataContent[index]?.extension || {}
            if (!value && !name) {
                row.modifiers[location] = null
                return
            }
            const found = row.modifiers.find((element) => element?.code === value)
            if (!!found) {
                row.modifiers[location] = null
                handleOpenModal()
            } else {
                row.modifiers[location] = dataModifiers.find((element) => element?.code === value)
            }
        }
    }

    const [valueSubContent, setValueSubContent] = useState(true)
    const [valueSubContentIndex, setValueSubContentIndex] = useState(null)
    const toggleExpand = (index) => {
        if (index === valueSubContentIndex) {
            setValueSubContent(!valueSubContent)
        } else {
            setValueSubContent(true)
        }

        setValueSubContentIndex(index)
    }

    return (
        <Grid>
            {dataContent.map((r, index) => {
                return (
                    <Grid key={index}>
                        <TableRow
                            key={index}
                            className={classes.wrapContent}
                            onClick={() => toggleExpand(index)}>
                            <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ width: 100 }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#667689',
                                        marginLeft: 8,
                                    }}>
                                    {type === 'icd' ? convertIndexToChar(index + 1) : index + 1}
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                style={{ width: 100 }}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#5571C6',
                                    }}>
                                    {r?.code}
                                </Typography>
                            </TableCell>
                            <TableCell align="left" component="th" style={{ flex: 1 }} scope="row">
                                <Typography
                                    variant="body1"
                                    style={{
                                        color: '#667689',
                                        // marginLeft: 8,
                                    }}>
                                    {r?.description}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <RenderAddOrRemove
                                    index={index}
                                    type={type}
                                    handleAddOrRemove={handleAddOrRemove}
                                    code={r?.clinicianDescriptorId || r?.description}
                                    disabled={read_only}
                                    row={r}
                                />
                            </TableCell>
                        </TableRow>
                        {type !== 'icd' && valueSubContent && valueSubContentIndex === index && (
                            <Grid className={classes.boderContentSub}>
                                <TableRow key={index} className={classes.subContent}>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{
                                            width: '10%',
                                        }}>
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: '#667689',
                                                marginLeft: 8,
                                            }}>
                                            {'Modifiers'}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{
                                            width: '20%',
                                            maxWidth: 250,
                                        }}>
                                        <RenderDropDow
                                            options={dataModifiers}
                                            handleInputChange={(name, value) =>
                                                handleInputChangeModifiers(
                                                    name,
                                                    value,
                                                    index,
                                                    0,
                                                    type,
                                                )
                                            }
                                            name={'ModifiersOne'}
                                            value={r?.extension?.modifiers[0]?.code}
                                            disabled={read_only}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{
                                            width: '20%',
                                            maxWidth: 250,
                                        }}>
                                        <RenderDropDow
                                            options={dataModifiers}
                                            handleInputChange={(name, value) =>
                                                handleInputChangeModifiers(
                                                    name,
                                                    value,
                                                    index,
                                                    1,
                                                    type,
                                                )
                                            }
                                            name={'ModifiersTwo'}
                                            value={r?.extension?.modifiers[1]?.code}
                                            disabled={read_only}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{
                                            width: '20%',
                                            maxWidth: 250,
                                        }}>
                                        <RenderDropDow
                                            options={dataModifiers}
                                            handleInputChange={(name, value) =>
                                                handleInputChangeModifiers(
                                                    name,
                                                    value,
                                                    index,
                                                    2,
                                                    type,
                                                )
                                            }
                                            name={'ModifiersThree'}
                                            value={r?.extension?.modifiers[2]?.code}
                                            disabled={read_only}
                                        />
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        component="th"
                                        scope="row"
                                        style={{
                                            width: '20%',
                                            maxWidth: 250,
                                        }}>
                                        <RenderDropDow
                                            options={dataModifiers}
                                            handleInputChange={(name, value) =>
                                                handleInputChangeModifiers(
                                                    name,
                                                    value,
                                                    index,
                                                    3,
                                                    type,
                                                )
                                            }
                                            name={'ModifiersFour'}
                                            value={r?.extension?.modifiers[3]?.code}
                                            disabled={read_only}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow key={index} className={classes.subContent}>
                                    <Grid container>
                                        <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{
                                                width: '20%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: '#667689',
                                                    marginLeft: 8,
                                                }}>
                                                {'Quantity / Minutes'}
                                            </Typography>
                                        </TableCell>

                                        <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{
                                                flex: 1,
                                            }}>
                                            <RenderTextField
                                                name={'quantity'}
                                                // placeholder={'Place Holder'}
                                                value={r?.extension?.quantity}
                                                handleInputChange={(name, value) =>
                                                    handleInputChange(
                                                        name,
                                                        value,
                                                        index,
                                                        null,
                                                        type,
                                                    )
                                                }
                                                disabled={read_only}
                                            />
                                        </TableCell>
                                    </Grid>
                                    <Grid container>
                                        <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{
                                                width: '20%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: '#667689',
                                                    marginLeft: 8,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignContent: 'center',
                                                }}>
                                                {'Dx Pointers'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            component="th"
                                            scope="row"
                                            style={{
                                                flex: 1,
                                            }}>
                                            <DxProvider
                                                valueDefault={
                                                    r?.extension?.dxPointers
                                                        ? r?.extension?.dxPointers
                                                        : '0:0:0:0'
                                                }
                                                onChange={(val) => {
                                                    handleInputChange(
                                                        'dxPointers',
                                                        val,
                                                        index,
                                                        r?.extension?.dxPointers,
                                                        type,
                                                    )
                                                }}
                                                disabled={read_only}
                                            />
                                        </TableCell>
                                    </Grid>
                                </TableRow>
                            </Grid>
                        )}
                    </Grid>
                )
            })}
        </Grid>
    )
}
export default RoleSectionRow
