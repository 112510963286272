import { Box, Button, Grid, Typography, Checkbox, CircularProgress } from '@material-ui/core'
import { AddCircleOutlineOutlined, KeyboardArrowDown } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import Divider from '@mui/material/Divider'
import moment from 'moment'
import React, { useState } from 'react'
import {
    postMedicalProblem,
    getMedicalProblemList,
    deleteMedicalProblem,
} from 'services/Patient/patient'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import { postMedicalCoding, getMedicalCoding } from '../../../containers/ClinicalNotes/api/services'
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'
import MedicalHistory from '../../../assests/icons/medicalHistory.svg'
import copyNew from '../../../assests/icons/copyNew.svg'
import TrashIcon from '../../../assests/icons/trash.svg'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { usePatient } from 'containers/PatientsSetting/hooks'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import CheckboxOption from '../reusable/CheckboxOptions'
const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
        marginLeft: 21,
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
    },
    first_box: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        // height: 'auto',
        // overflow: 'auto',
        padding: '7px 25px 7px 12px',
    },
    second_box: {
        borderRadius: '5px 5px 0px 0px',
        border: '1px solid #EAECF0',
        background: '#FFF',
        height: '36px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    copy_button: {
        borderRadius: '4px',
        border: '1px solid #D1D1D1',
        background: '#FFF',
        fontSize: '12px',
        color: '#303E4E',
        fontWeight: '400',
        height: '26px',
        marginRight: '8px',
        padding: '4px 8px',
    },
    box_type: {
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '13px 15px 13px 8px',
    },
    active_medication: {
        borderRadius: '5px',
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        minHeight: '42px',
        overflow: 'scroll',
    },
    active_medication_box: {
        width: '100%',
        padding: '12px 10px 10px 7px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '20px',
    },
    active_diagonsis_divider_box: {
        width: '100%',
        padding: '12px 0px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    active_diagonsis_button_box: {
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        whiteSpace: 'nowrap',
        padding: '12px 0px',
    },
    active_diagonsis_container: {
        width: '100%',
        padding: '15px 8px',
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    active_diagonsis_box: {
        border: '1px solid #EAECF0',
        background: '#F8F9FB',
        minHeight: '130px',
        width: '100%',
        padding: 10,
    },
    divider: {
        background: 'transparent',
        width: '97%',
        // height: '1px',
    },
    DividerText: {
        color: 'var(--Light-Foreground-Foreground-2, #424242)',
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    dividerScnd: {
        background: 'transparent',
        width: '100%',
        // height: '1px',
    },
    divider_height: {
        height: '25px !important',
        width: '2PX',
    },
    staffNoteCard: {
        display: 'flex',
        flexDirection: 'column',
        // width: '30%',
        height: '82px',
        borderRadius: '5px',
        border: '1px solid #E4E2E2',
        background: '#FFF',
        padding: '15px 6px 15px 6px',
        marginBottom: '7px',
        marginRight: '10px',
        justifyContent: 'space-between',
        width: '207px',
    },
    text: {
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontWeight: '400',
        color: '#303E4E',
    },
    status: {
        background: '#EBFFF3',
        color: '#14AE5C',
        padding: '1px 10px 1px 10px',
        marginLeft: '2px',
        fontSize: '13px',
        fontWeight: '400',
        borderRadius: '5px',
    },
})

const AssessmentDiagnosis = (props) => {
    const { patientInfo } = usePatient(props.patientId)
    const classes = useStyles()
    const notification = useNotification()
    const [autoSave, setAutoSave] = React.useState(true)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [selectedItemIndex, setSelectedItemIndex] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [medConditionId, setMedConditionId] = useState(false)
    const newDiagnosis = {
        icd_code: {
            code: '',
            display: '',
        },
        medical_problem: {
            code: '',
            display: '',
        },
        onset_condition: '',
        status: {
            code: 'active',
            display: 'Active',
        },
        comment: '',
    }
    const validationSchema = yup.object().shape({
        new_diagnosis: yup.array().of(
            yup.object().shape({
                icd_code: yup.object({
                    code: yup.string().required('ICD 10 Code is required!'),
                }),
            }),
        ),
        // encounter_id: yup.string(),
        // time_diagnosis: yup.date(),
        // time_onset: yup
        //     .date()
        //     .nullable()
        //     .typeError('Please input valid Onset Of Condition')
        //     .min(moment(patientInfo.dob), `Onset Of Condition must be after Patient's birth date`)
        //     .max(moment(), 'Onset Of Condition must be before current date'),
        // time_changed: yup.date(),

        // icd_code: yup.object({
        //     code: yup.string().required('ICD 10 Code is required!'),
        // }),
        // status: yup.object({
        //     code: yup.string().required('Status is required!'),
        // }),
    })

    const INITIAL_VALUES = {
        new_diagnosis: props.values.new_diagnosis,
    }
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const [isCollapsed, setIsCollapsed] = React.useState(false)
    // React.useEffect(() => {
    //     if (formik.values?.new_diagnosis?.length >= 1) {
    //         formik.setValues({
    //             ...formik.values,
    //             new_diagnosis: [...formik.values?.new_diagnosis, newDiagnosis],
    //         })
    //     } else {
    //         formik.setValues({
    //             ...formik.values,
    //             new_diagnosis: props?.values.new_diagnosis,
    //         })
    //     }
    // }, [])
    const handleClickDeleteIcon = (event, conditionId, index) => {
        setAnchorEl(event.currentTarget)
        setSelectedItemIndex(index)
        setMedConditionId(conditionId)
        // try {
        //     console.log('delete',payload)
        // await deleteMedicalProblem(patientInfo.id, conditionId)
        //     notification(`Problem Deleted Successfully!`, 'success')
        // } catch (error) {
        //     console.log('error')
        // }
    }
    const handleDeleteServiceLine = async () => {
        try {
            setIsSubmitting(true)
            await deleteMedicalProblem(patientInfo.id, medConditionId)
            notification(`Problem Deleted Successfully!`, 'success')
            formik?.setFieldValue(
                'new_diagnosis',
                formik.values?.new_diagnosis?.filter((_, idx) => idx !== selectedItemIndex),
            )
            setMedConditionId(null)
            setAnchorEl(null)
            setSelectedItemIndex(null)
        } catch (error) {
            console.log('error')
        } finally {
            setSelectedItemIndex(null)
            setIsSubmitting(false)
        }
    }
    const handleSubmit = async (payload) => {
        // console.log('payload', payload)
        try {
            setIsSubmitting(true)
            const data = payload.new_diagnosis[payload.new_diagnosis.length - 1]
            const postData = {
                id: '',
                patient_id: '',
                encounter_id: '', // appointment
                time_diagnosis: '',
                time_changed: '',
                external_id: '',
                rcopia_id: '',
                problem: data?.icd_code,
                icd_code: data?.icd_code,
                status: data?.status,
                time_onset: formatDateTime(data?.onset_condition, 'YYYY-MM-DD'),
                note: data?.comment,
            }
            const add_med = await postMedicalProblem(patientInfo.id, postData)
            const addLastDiagnosisData = {
                id: add_med?.data?.id,
                medical_problem: data?.icd_code,
                icd_code: data?.icd_code,
                status: data?.status,
                onset_condition: formatDateTime(data?.onset_condition, 'YYYY-MM-DD'),
                comment: data?.comment,
            }
            const arrWithAdd = [...payload?.new_diagnosis.slice(0, -1), addLastDiagnosisData]
            if (add_med) {
                props.onChange(`${props.fieldName}.new_diagnosis`, [...arrWithAdd, newDiagnosis])
                props.onChange(`${props.fieldName}.active_medical_problems`, [
                    ...props.values.active_medical_problems,
                    {
                        problem: data?.icd_code.display,
                        code: data?.icd_code.code,
                        date_diagnosed: data?.onset_condition ? formatDateTime(data?.onset_condition, 'MM/DD/YYYY') : "",
                        date_changed: '',
                        status: data?.status?.code,
                        note: data?.comment,
                        id: add_med?.data?.id,
                        patient_id: patientInfo?.id,
                        isAgreedTnC: false,
                    },
                ])
                notification('Problem created successfully!', 'success')
            }
        } catch (error) {
            console.log('error')
            notification('Problem created Unsuccessfully!', 'error')
        } finally {
            setIsSubmitting(false)
        }
    }
    const setNewDiagnosis = (lineIndex, field, value) => {
        console.log('setNewDiagnosis', field, value)
        formik?.setFieldValue(
            'new_diagnosis',
            formik.values?.new_diagnosis?.map((line, index) =>
                index === lineIndex ? { ...line, [field]: value } : line,
            ),
        )
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: INITIAL_VALUES,
        onSubmit: handleSubmit,
    })
    return (
        <Grid item className={classes.first_box}>
            <Grid container className={classes.second_box}>
                <Typography className={classes.narrative}>Assessment</Typography>
                <Button
                    startIcon={<img src={copyNew} alt="" style={{ height: '9px', width: '7px' }} />}
                    className={classes.copy_button}>
                    {' '}
                    Copy: 01/01/2023
                </Button>
            </Grid>
            <Box className={classes.box_type}>
                <Grid
                    container
                    item
                    className={classes.active_medication}
                    style={{ height: isCollapsed && '194px' }}>
                    <Box className={classes.active_medication_box}>
                        <Divider
                            sx={{
                                '&::before': {
                                    width: '1%', // Clear the default content
                                },
                            }}
                            textAlign="left"
                            flexItem
                            className={classes.divider}>
                            <Typography className={classes.DividerText}>
                                Active Medical Problems
                            </Typography>
                        </Divider>
                        <KeyboardArrowDown
                            onClick={() => setIsCollapsed(!isCollapsed)}
                            style={{
                                color: '#667689',
                                cursor: 'pointer',
                                rotate: isCollapsed ? '180deg' : '',
                                fontSize: 18,
                            }}
                        />
                    </Box>
                    {isCollapsed && <MedicalProblemList isCollapsed={isCollapsed} {...props} />}
                </Grid>
                <Grid container item>
                    <Box className={classes.active_diagonsis_divider_box}>
                        <Divider
                            sx={{
                                '&::before': {
                                    width: '1%', // Clear the default content
                                },
                            }}
                            textAlign="left"
                            flexItem
                            className={classes.dividerScnd}>
                            <Typography className={classes.DividerText}>New Diagnosis</Typography>
                        </Divider>
                    </Box>
                    <Grid item container className={classes.active_diagonsis_container}>
                        {formik.values?.new_diagnosis?.length > 0 &&
                            formik.values?.new_diagnosis?.map((item, index) => {
                                console.log('item', item, formik.errors)
                                return (
                                    <Box className={classes.active_diagonsis_box} key={index}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <div style={{ width: '97%' }}>
                                                <Grid
                                                    item
                                                    container
                                                    xs={12}
                                                    justifyContent="space-between">
                                                    <Grid xs={3}>
                                                        <SearchICD10Code
                                                            label="ICD10 Code"
                                                            required
                                                            name="icd_code​​​​"
                                                            placeholder="Search Code"
                                                            value={{
                                                                code: item?.icd_code?.code,
                                                                display: item?.icd_code?.code,
                                                            }}
                                                            // value={item?.icd_code}
                                                            error={
                                                                (formik.touched || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code &&
                                                                Boolean(
                                                                    (formik.errors || [])
                                                                        ?.new_diagnosis?.[index]
                                                                        ?.icd_code?.code,
                                                                )
                                                            }
                                                            helperText={
                                                                (formik.touched || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code &&
                                                                (formik.errors || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code
                                                            }
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setNewDiagnosis(
                                                                        index,
                                                                        'icd_code',
                                                                        {
                                                                            code: value?.code,
                                                                            display: value?.display,
                                                                        },
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <SearchICD10Code
                                                            label="Medical Problems"
                                                            required
                                                            name="medical_problem"
                                                            placeholder="Search problem"
                                                            value={item?.icd_code}
                                                            // error={
                                                            //     formik.touched.icd_code?.code &&
                                                            //     Boolean(formik.errors.icd_code?.code)
                                                            // }
                                                            // helperText={
                                                            //     formik.touched.icd_code?.code &&
                                                            //     formik.errors.icd_code?.code
                                                            // }
                                                            error={
                                                                (formik.touched || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code &&
                                                                Boolean(
                                                                    (formik.errors || [])
                                                                        ?.new_diagnosis?.[index]
                                                                        ?.icd_code?.code,
                                                                )
                                                            }
                                                            helperText={
                                                                (formik.touched || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code &&
                                                                (formik.errors || [])
                                                                    ?.new_diagnosis?.[index]
                                                                    ?.icd_code?.code &&
                                                                'Medical Problem is required!'
                                                            }
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setNewDiagnosis(
                                                                        index,
                                                                        'icd_code',
                                                                        {
                                                                            code: value.code,
                                                                            display: value.display,
                                                                        },
                                                                    )
                                                                    // setNewDiagnosis(
                                                                    //     index,
                                                                    //     'medical_problem',
                                                                    //     {
                                                                    //         code: value?.code,
                                                                    //         display: value?.display,
                                                                    //     },
                                                                    // )
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid xs={3} style={{ maxWidth: '21%' }}>
                                                        <DatePicker
                                                            label="Onset of Condition"
                                                            maxDate={moment()}
                                                            minDate={moment(patientInfo.dob)}
                                                            // error={
                                                            //     formik.touched.time_onset && Boolean(formik.errors.time_onset)
                                                            // }
                                                            // helperText={formik.touched.time_onset && formik.errors.time_onset}
                                                            name="time_onset"
                                                            value={item.onset_condition}
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setNewDiagnosis(
                                                                        index,
                                                                        'onset_condition',
                                                                        value,
                                                                    )
                                                                }
                                                            }}
                                                            notDefaultValue
                                                        />
                                                    </Grid>
                                                    <Grid xs={3}>
                                                        <Select
                                                            // label="Status"
                                                            name="status"
                                                            required
                                                            placeholder="Select status"
                                                            value={item.status.code}
                                                            // error={
                                                            //     formik.touched.status?.code &&
                                                            //     Boolean(formik.errors.status?.code)
                                                            // }
                                                            // helperText={
                                                            //     formik.touched.status?.code && formik.errors.status?.code
                                                            // }
                                                            options={PROBLEM_STATUS}
                                                            onChange={(value) => {
                                                                if (item.status?.code !== 'Deleted')
                                                                    setNewDiagnosis(
                                                                        index,
                                                                        'status',
                                                                        {
                                                                            code: value,
                                                                            display:
                                                                                value
                                                                                    .charAt(0)
                                                                                    .toUpperCase() +
                                                                                value.slice(1),
                                                                        },
                                                                    )
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid xs={9} style={{ maxWidth: '73%' }}>
                                                        <TextBox
                                                            // label="Note"
                                                            name="comment"
                                                            value={item.comment}
                                                            multiline
                                                            variant="outlined"
                                                            // minRows={4}
                                                            placeholder="Note"
                                                            // style={{ marginTop: 10 }}
                                                            // sx={{
                                                            //     '& .MuiOutlinedInput-root': {
                                                            //         height: '90px !important',
                                                            //     },
                                                            // }}
                                                            // error={
                                                            //     (formik.touched || [])
                                                            //         ?.new_diagnosis?.[index]
                                                            //         ?.comment &&
                                                            //     Boolean(
                                                            //         (formik.errors || [])
                                                            //             ?.new_diagnosis?.[index]
                                                            //             ?.comment,
                                                            //     )
                                                            // }
                                                            // error={
                                                            //     formik.touched.comment &&
                                                            //     Boolean(formik.errors.comment)
                                                            // }
                                                            // helperText={
                                                            //     (formik.touched || [])
                                                            //         ?.new_diagnosis?.[index]
                                                            //         ?.comment &&
                                                            //     (formik.errors || [])
                                                            //         ?.new_diagnosis?.[index]
                                                            //         ?.comment
                                                            // }
                                                            onChange={(value) => {
                                                                if (value) {
                                                                    setNewDiagnosis(
                                                                        index,
                                                                        'comment',
                                                                        value,
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div style={{ width: '3%' }}>
                                                <Box
                                                    display="flex"
                                                    alignItems={'center'}
                                                    style={{
                                                        marginLeft: 10,
                                                        marginTop: 20,
                                                    }}>
                                                    <Divider
                                                        orientation="vertical"
                                                        variant="middle"
                                                        flexItem
                                                        style={{
                                                            height: '50px',
                                                            width: '2px',
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: '20px',
                                                            marginLeft: '2px',
                                                        }}
                                                        onClick={(event) => {
                                                            console.log({
                                                                event,
                                                                item,
                                                                index,
                                                            })
                                                            if (item?.id) {
                                                                handleClickDeleteIcon(
                                                                    event,
                                                                    item.id,
                                                                    index,
                                                                )
                                                            }
                                                        }}>
                                                        {' '}
                                                        <img src={TrashIcon} />
                                                    </div>
                                                    <CustomAlertPopOver
                                                        anchorEl={anchorEl}
                                                        handleClose={() => {
                                                            setAnchorEl(null)
                                                            setSelectedItemIndex(null)
                                                            setMedConditionId(null)
                                                        }}
                                                        title="Confirm Data Deletion"
                                                        bodyText={`Are you sure you want to delete this medical problem?`}
                                                        confirmButtonName={'Delete'}
                                                        confirmButtonColor={'red'}
                                                        onSuccess={handleDeleteServiceLine}
                                                    />
                                                </Box>
                                            </div>
                                        </div>
                                    </Box>
                                )
                            })}
                    </Grid>

                    <Box className={classes.active_diagonsis_button_box}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: isSubmitting ? 'none' : 'pointer',
                            }}
                            onClick={(e) => formik.handleSubmit(e)}>
                            {isSubmitting ? (
                                <CircularProgress size={18} />
                            ) : (
                                <>
                                    <AddCircleOutlineOutlined
                                        style={{
                                            color: '#14AE5C',
                                            fontSize: 18,
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            color: '#14AE5C',
                                            marginLeft: 8,
                                        }}>
                                        Add New Diagnosis
                                    </Typography>
                                </>
                            )}
                        </div>
                    </Box>
                    <Grid item xs={12}>
                        <TextWithMacrosNew
                            name={`${props?.fieldName}.plan.narrative`}
                            onChange={props?.onChange}
                            // // macros={macros}
                            isDisabled={props?.nonEditable}
                            rows={12}
                            withoutMacro={false}
                            values={props?.values?.plan?.narrative}
                            // placeholder={placeholder}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

export default AssessmentDiagnosis

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]
const PROBLEM_STATUS = [
    { key: 'active', value: 'Active' },
    { key: 'inactive', value: 'Inactive' },
    { key: 'resolved', value: 'Resolved' },
    { key: 'Deleted', value: 'Deleted' },
]
const MedicalProblemList = ({
    fieldName,
    values,
    med_codes,
    onChange,
    primary_care_physician,
    patientId,
    isCollapsed,
    appointmentId,
}) => {
    const classes = useStyles()
    const notification = useNotification()
    const [medCodes, setMedCodes] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(false)
    const checkCharacter = (description = '') => {
        if (description.length <= 24) {
            return description
        }
        const truncatedString = `${description.substring(0, 24)}...`
        return truncatedString
    }
    const handleAddActiveMedicalInputChange = (flag, selectedItem, index) => {
        const { cpt, hcpcs, icd10 } = medCodes
        const exists = icd10.some((obj) => obj.code === selectedItem?.code)
        if (flag && !exists) {
            const payload = {
                icd10: [
                    ...icd10,
                    {
                        code: selectedItem?.code,
                        description: selectedItem?.problem,
                        fromMedicalProblems: true,
                        id: selectedItem?.id,
                        origin_type: 'from_medical_problems',
                    },
                ],
                cpt,
                hcpcs,
            }
            postMedicalCoding(appointmentId, payload)
        }
        values.active_medical_problems[index].isAgreedTnC = flag
        onChange(`${fieldName}.active_medical_problems`, values.active_medical_problems)
    }
    const getMedialProblemList = async () => {
        try {
            setIsLoading(true)
            const res = await getMedicalCoding(appointmentId)
            setMedCodes(res?.data)
            const resp = await getMedicalProblemList(patientId, 1, 1000)
            const list = resp.data.data.map((item) => {
                return {
                    problem: item.icd_code?.display || '',
                    code: item.icd_code?.code || '',
                    date_diagnosed: formatDateTime(item.time_onset, 'MM/DD/YYYY'),
                    date_changed: formatDateTime(item.time_changed, 'MM/DD/YYYY'),
                    status: item.status?.code || '',
                    note: item.note || '',
                    id: item.id,
                    patient_id: item.patient_id,
                    isAgreedTnC: res?.data?.icd10.some((obj) => obj.code === item.icd_code?.code),
                }
            })
            onChange(`${fieldName}.active_medical_problems`, list)
        } catch (ex) {
            notification(`Cannot get list problem!`, 'error')
        } finally {
            setIsLoading(false)
        }
    }
    React.useEffect(async () => {
        if ((patientId, isCollapsed)) {
            getMedialProblemList()
        }
    }, [patientId, isCollapsed])
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'space-between',
                padding: '0px 10px',
            }}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <span style={{ color: '#667689', fontSize: '14px', fontWeight: '400' }}>
                        Select the medical problems you would like to include in medical coding.
                    </span>
                    <Grid item>
                        <Grid container item>
                            {values.active_medical_problems?.map((item, index) => (
                                <div
                                    className={classes.staffNoteCard}
                                    style={{ cursor: 'pointer', position: 'relative' }}
                                    key={index}>
                                    <Grid
                                        container
                                        item
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ padding: '5px 0px 0px 10px' }}>
                                        <img
                                            src={MedicalHistory}
                                            alt=""
                                            style={{ paddingRight: '10px' }}
                                        />
                                        <span
                                            style={{
                                                fontFamily: 'Rubik',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#303E4E',
                                            }}>
                                            {item?.code || ''}
                                        </span>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ padding: '15px 0px 0px 10px' }}>
                                        <span className={classes.text}>Description</span>-
                                        <span className={classes.text}>
                                            {checkCharacter(item?.problem)}
                                        </span>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        alignItems="flex-start"
                                        spacing={2}
                                        style={{ padding: '15px 0px 0px 10px' }}>
                                        <span span className={classes.text}>
                                            Status
                                        </span>
                                        -
                                        <span className={classes.status}>
                                            {item?.status === 'active'
                                                ? 'Active'
                                                : item?.status === 'inactive'
                                                ? 'Inactive'
                                                : ''}
                                        </span>
                                    </Grid>
                                    <CheckboxOption
                                        name="isAgreedTnC"
                                        option={{
                                            value: item?.isAgreedTnC,
                                            label: '',
                                        }}
                                        checked={item?.isAgreedTnC}
                                        onChange={(e) => {
                                            handleAddActiveMedicalInputChange(
                                                e.target.checked,
                                                item,
                                                index,
                                            )
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}
                                    />
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    )
}
