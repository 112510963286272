import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'

import { ModalEl as Modal } from 'shared/elements'
import { OutpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import './style.scss'

import { InputNumber } from 'shared/elements/FormItem/Input/InputNumber'
import { Select } from 'shared/elements/FormItem/Select'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { apiOutpatientPsychiatricHistory } from 'services/Patient/apiOutpatientPsychiatricHistory'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { getAge } from 'utilities/formats'
import { getPatient } from 'services/Patient/patient'

type Props = {
    outpatientPsychiatricId?: string | number | false
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const Reasons = [
    // @TODO:
    { key: 'Depression', value: 'Depression' },
    { key: 'Panic Disorder', value: 'Panic Disorder' },
    { key: 'Generalized anxiety disorder.', value: 'Generalized anxiety disorder.' },
    { key: 'Social anxiety disorder', value: 'Social anxiety disorder' },
    { key: 'Obsessive compulsive disorder', value: 'Obsessive compulsive disorder' },
    { key: 'Post-traumatic stress disorder', value: 'Post-traumatic stress disorder' },
    { key: 'Bipolar disorder', value: 'Bipolar disorder' },
    { key: 'Eating disorder', value: 'Eating disorder' },
    { key: 'Alcoholism', value: 'Alcoholism' },
    { key: 'Drug abuse', value: 'Drug abuse' },
    { key: 'ADHD', value: 'ADHD' },
    { key: 'Personality disorder', value: 'Personality disorder' },
    { key: 'Schizophrenia/Psychosis', value: 'Schizophrenia/Psychosis' },
    { key: 'DepreSuicidession', value: 'Suicide' },
    { key: 'Autism spectrum disorder', value: 'Autism spectrum disorder' },
    { key: 'Disruptive behavioral problems', value: 'Disruptive behavioral problems' },
].sort((option1, option2) => {
    let a = option1.value
    let b = option2.value
    return a === b ? 0 : a > b ? 1 : -1
})

const Outcomes = [
    { key: 'Feeling worse or negative result', value: 'Feeling worse or negative result' },
    {
        key: 'Minor improvement or no effect (0% - 24%)',
        value: 'Minor improvement or no effect (0% - 24%)',
    },
    { key: 'Partial response (25% - 49%)', value: 'Partial response (25% - 49%)' },
    { key: 'Significant improvement (50% - 74%)', value: 'Significant improvement (50% - 74%)' },
    {
        key: 'Resolved or nearly resolved problem (75% - 100%)',
        value: 'Resolved or nearly resolved problem (75% - 100%)',
    },
]

const validationSchema = yup.object({
    provider_id: yup.string().required('This field is required!'),
    reason: yup.string().required('This field is required!'),
    age_of_first_treatment: yup
        .number()
        .min(0, 'incorrect age format!')
        .max(
            yup.ref('age_patient'),
            "The age of first treatment must be less than or equal to the patient's age!",
        ),
    // .required('This field is required!'),
    age_of_last_treatment: yup
        .number()
        .min(
            yup.ref('age_of_first_treatment'),
            'The age of last treatment must be greater than or equal to the age of first treatment',
        )
        .max(
            yup.ref('age_patient'),
            "The age of last treatment must be less than or equal to the patient's age!",
        ),
    outcome: yup.string(),
})

const INITIAL_VALUES = {
    provider_id: '',
    reason: '',
    age_of_first_treatment: 0,
    age_of_last_treatment: 0,
    outcome: '',
    comment: '',
    age_patient: 100,
}

const OutpatientPsychiatricForm = (props: Props) => {
    //Hooks
    const notification = useNotification()

    // State
    const [loading, setLoading] = useState(false)
    const [initialValues, setInitialValues] = useState<OutpatientPsychiatric>(INITIAL_VALUES)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const formik = useFormik<OutpatientPsychiatric>({
        validationSchema,
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    async function handleSubmit(values) {
        if (!props.outpatientPsychiatricId && props.patientId) {
            try {
                setIsSubmitting(true)
                const res = await apiOutpatientPsychiatricHistory.create(props.patientId, {
                    ...values,
                    encounter_id: props?.encounter_id,
                })
                if (res.data) {
                    notification('Outpatient Psychiatric History created successfully', 'success')
                    formik.resetForm()
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setIsSubmitting(false)
            }
        } else if (props.outpatientPsychiatricId && props.patientId) {
            try {
                setIsSubmitting(true)
                const res = await apiOutpatientPsychiatricHistory.updated(
                    props.patientId,
                    props.outpatientPsychiatricId,
                    { ...values, encounter_id: props?.encounter_id },
                )
                if (res.data?.Status === 'SUCCESSED') {
                    notification('Outpatient Psychiatric History updated successfully', 'success')
                    formik.resetForm()
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setIsSubmitting(false)
            }
        }
    }

    async function getOutPatientPsychiatricHistoryDetail(outpatientPsychiatricId, patientId) {
        try {
            setLoading(true)
            const res = await apiOutpatientPsychiatricHistory.getDetail(
                patientId,
                outpatientPsychiatricId,
            )
            const resPatient = await getPatient(patientId)
            let agePatient: number | null = null
            if (resPatient.data) {
                agePatient = getAge(resPatient.data.dob, 'YYYY-MM-DD')
                if (agePatient === 0) agePatient = 1
            }
            if (res.data) {
                const {
                    id,
                    provider_id,
                    reason,
                    age_of_first_treatment,
                    age_of_last_treatment,
                    outcome,
                    comment,
                } = res.data
                setInitialValues({
                    provider_id,
                    reason,
                    age_of_first_treatment,
                    age_of_last_treatment,
                    outcome,
                    comment,
                    age_patient: agePatient ? agePatient : 100,
                })
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                console.log(resp.data)
                let age = getAge(resp.data.dob, 'YYYY-MM-DD')
                if (age === 0) age = 1
                if (age) {
                    // formik.setFieldValue('age_patient', age)
                    setInitialValues({
                        ...initialValues,
                        age_patient: age,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (props.patientId && props.outpatientPsychiatricId) {
            getOutPatientPsychiatricHistoryDetail(props.outpatientPsychiatricId, props.patientId)
        }

        return () => abortController.abort()
    }, [props.patientId, props.outpatientPsychiatricId])

    useEffect(() => {
        if (props.patientId && !props.outpatientPsychiatricId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    const modalTitle = props.outpatientPsychiatricId
        ? 'Update Outpatient Psychiatric History'
        : 'Add Outpatient Psychiatric History'

    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="outpatient-psychiatric-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12} container direction="column">
                        <TextBox
                            required
                            label="Provider/ Program"
                            name="provider_id"
                            value={formik.values.provider_id}
                            placeholder="Provider"
                            error={
                                Boolean(formik.touched.provider_id) &&
                                Boolean(formik.errors.provider_id)
                            }
                            helperText={
                                Boolean(formik.touched.provider_id) &&
                                (formik.errors.provider_id as string)
                            }
                            onChange={(value) => formik.setFieldValue('provider_id', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            required
                            label="Reason"
                            name="reason"
                            value={formik.values.reason}
                            placeholder="Reason"
                            options={Reasons} // @TODO:
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            helperText={formik.touched.reason && formik.errors.reason}
                            onChange={(value) => formik.setFieldValue('reason', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Age of First Treatment"
                            name="age_of_first_treatment"
                            min={1}
                            max={99}
                            value={formik.values.age_of_first_treatment}
                            error={
                                formik.touched.age_of_first_treatment &&
                                Boolean(formik.errors.age_of_first_treatment)
                            }
                            helperText={
                                formik.touched.age_of_first_treatment &&
                                formik.errors.age_of_first_treatment
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('age_of_first_treatment', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Age of Last Treatment"
                            name="age_of_last_treatment"
                            min={1}
                            max={99}
                            value={formik.values.age_of_last_treatment}
                            error={
                                formik.touched.age_of_last_treatment &&
                                Boolean(formik.errors.age_of_last_treatment)
                            }
                            helperText={
                                formik.touched.age_of_last_treatment &&
                                formik.errors.age_of_last_treatment
                            }
                            onChangeValue={(value) =>
                                formik.setFieldValue('age_of_last_treatment', value)
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Select
                            label="Outcome"
                            name="outcome"
                            value={formik.values.outcome}
                            options={Outcomes}
                            error={formik.touched.outcome && Boolean(formik.errors.outcome)}
                            helperText={formik.touched.outcome && formik.errors.outcome}
                            onChange={(value) => formik.setFieldValue('outcome', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextBox
                            label="Comments"
                            name="comment"
                            value={formik.values.comment}
                            minRows={4}
                            multiline
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                            onChange={(value) => formik.setFieldValue('comment', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={isSubmitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.outpatientPsychiatricId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export { OutpatientPsychiatricForm }
export default OutpatientPsychiatricForm
