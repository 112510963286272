export function RequestTypeMapper(type) {
    const requestType = {
        cancellation: 'Appointment Cancellation',
        timeoff: 'Time-Off Requested',
    }

    return requestType[type]
}

export function StatusColorMapper(status) {
    if (status === 'completed') {
        return { backgroundColor: '#4C8080' }
    } else if (status === 'pending') {
        return { backgroundColor: '#F15A5F' }
    } else if (status === 'inprogress') {
        return { backgroundColor: '#FFBC42' }
    }
}

export function RequestStatusMapper(status) {
    if (status === 'completed') {
        return 'Completed'
    } else if (status === 'pending') {
        return 'Pending'
    } else if (status === 'inprogress') {
        return 'In Progress'
    }
}

export const RequestType = [
    {
        key: 'cancellation',
        value: 'Appointment Cancellation',
    },
    {
        key: 'timeoff',
        value: 'Time-Off Requested',
    },
]

export const WorkListStatus = [
    {
        key: 'inprogress',
        value: 'In Progress',
    },
    { key: 'pending', value: 'Pending' },
    { key: 'completed', value: 'Completed' },
]
