import { Button, IconButton, Popover, Typography } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import ReportIcon from '@mui/icons-material/Report'
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import { Box, Divider } from '@material-ui/core'
// import { ReactComponent as ReportIcon } from 'assests/icons/error-icon.svg';
const useStyles = makeStyles(() => ({
    buttons: {
        '& .MuiButtonBase-root': {
            borderRadius: '4px !important'
        }
    }

}))

const customStyles = {
    backgroundColor: 'blue',
    color: 'white',
    '&:hover': {
        backgroundColor: 'darkblue',
    },
};
export default function CustomAlertPopOver({
    anchorEl,
    handleClose,
    title,
    customHandleClose = null, //custom function to pass while cancelling
    disableOutsideClose = false, //allow or resist the popover to close while clicking outside
    bodyText,
    confirmButtonName = 'confirm',
    cancelButtonName = 'Cancel',
    confirmButtonColor = 'rgb(85, 113, 198)',
    iconColor = null,
    onSuccess,
    customAnchorOrigin = { vertical: 'bottom', horizontal: 'left', },
    customTransformOrigin = { vertical: 'top', horizontal: 'left', },
    type = 'default',
    customPaperStyle = {},
    buttonProps = {},
    loading=false,
    confirmButtonDisabled=false,
    cancelButtonDisabled=false,
    ...props
}) {
    const open = Boolean(anchorEl)
    const endId = open ? 'simple-popover' : undefined
    const classes = useStyles()
    const outsideClose = !disableOutsideClose ? { onClose: type === 'default' ? handleClose : onSuccess } : {}

    return (
        <Popover
            id={endId}
            open={open}
            anchorEl={anchorEl}
            disableEnforceFocus
            {...outsideClose}
            anchorOrigin={customAnchorOrigin}
            transformOrigin={customTransformOrigin}
            PaperProps={{
                style: {
                    boxShadow: '0px 0px 10px #888888',
                    ...customPaperStyle
                },
            }}>
            <div id={props?.id} style={{ padding: '10px 20px', maxWidth: '300px' }}>
                <span style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {props.icon ? props?.icon : <ReportIcon
                            style={{ fontSize: '18px', marginRight: '10px', color: iconColor ? iconColor : confirmButtonColor }}
                        />}
                        <Typography variant="h6" fontWeight="bold">
                            <div dangerouslySetInnerHTML={{ __html: title }}></div>
                        </Typography>
                    </span>

                    {
                        disableOutsideClose &&
                        <IconButton onClick={handleClose}>
                            <CloseIcon
                                style={{ fontSize: '20px', marginleft: '50px', color: '#666' }}
                            />
                        </IconButton>
                    }
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <Typography>
                        <div dangerouslySetInnerHTML={{ __html: bodyText }}></div>
                    </Typography>
                    {props.children}
                </span>

                <Divider style={{ marginBottom: 10 }} />

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        disabled={loading || cancelButtonDisabled}
                        size="small"
                        className="cancel-btn"
                        onClick={() => !customHandleClose ? handleClose() : customHandleClose()}
                        style={{ 
                            marginRight: '10px', 
                            color: '#444', 
                            ...buttonProps['styleCancel'],
                            height: 30,
                            opacity: (loading || cancelButtonDisabled) ? 0.5 : 1
                        }}>
                        {cancelButtonName}
                    </Button>
                    <Button
                        disabled={loading || confirmButtonDisabled}
                        sx={customStyles}
                        size="small"
                        variant="contained"
                        style={{
                            background: confirmButtonColor,  
                            padding: 5, 
                            color: '#fff', 
                            borderRadius: "5px !important", 
                            ...buttonProps['styleConfirm'],
                            height: 30,
                            opacity: (loading || confirmButtonDisabled) ? 0.5 : 1
                        }}
                        onClick={onSuccess}>
                        {confirmButtonName}
                    </Button>
                </span>
            </div>
        </Popover >
    )
}
