import React, { useEffect, useState } from 'react'
import {
    Container,
    Grid,
    MenuItem,
    Typography,
    Select,
    TextField,
    Box,
    Divider,
    IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from '@material-ui/icons'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        fontSize: 14,
        color: '#303E4E',
        letterSpacing: 0,
    },
    textsBoxWrap: {},
    textFieldWrap: {
        margin: 15,
        '& .MuiOutlinedInput-root': {
            height: 'auto',
        },
    },
    divider: {
        background: '#516983',
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
    indexText: {
        fontSize: 18,
        color: '#516983',
        marginRight: 5,
        marginLeft: 20,
    },
})

const FreeTextField = ({
    heading,
    name,
    values,
    onChange,
    isDisabled,
    initialValue,
    fieldName,
    lineNumber = 3,
}) => {
    const classes = useStyles()

    const handleAddMore = (action, index) => {
        if (action === 'add') {
            onChange(name, [...values, ''])
        } else {
            const beforeArr = values.slice(0, index)
            const lastArr = values.slice(index + 1, values.length)
            onChange(name, [...beforeArr, ...lastArr])
        }
    }

    const RenderAddOrRemove = ({ index, isEmpty }) => {
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)

        return (
            <Box sx={{ width: 30 }}>
                {values.length >= 1 && (values.length === 1 && isEmpty ? false : true) && (
                    <IconButton
                        variant="outlined"
                        onClick={() => setAlertDeleteOpen(true)}
                    // className={classes.show_header_btn}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const handleTextChange = (name, text, index) => {
        const updatedValue = values.map((v, i) => (index === i ? text : v))
        onChange(name, updatedValue)
    }

    return (
        <Box className={classes.container}>
            {heading && <Typography className={classes.labelText}>{heading}</Typography>}
            <Box className={classes.textsBoxWrap}>
                {values?.map((v, index) => (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: '#fafafa',
                            borderRadius: 8,
                            margin: '16px 0',
                            padding: '8px 0',
                        }}
                        key={index}>
                        {/* <Typography
                            className={classes.indexText}>
                            {index + 1}
                        </Typography> */}
                        <TextField
                            variant="outlined"
                            className={classes.textFieldWrap}
                            placeholder=""
                            fullWidth
                            name={name}
                            multiline
                            minRows={lineNumber}
                            disabled={isDisabled}
                            value={v}
                            onChange={(e) => {
                                handleTextChange(e.target.name, e.target.value, index)
                            }}
                            inputProps={{
                                maxLength: 5000,
                            }}
                        />
                        <RenderAddOrRemove index={index} isEmpty={!v} />
                    </Box>
                ))}

                <Grid item>
                    <Grid
                        container
                        className={classes.cover__add__new}
                        style={{
                            cursor: 'pointer',
                            width: 'fit-content'
                        }}
                        onClick={() => handleAddMore('add')}>
                        <AddCircleOutlineOutlined
                            style={{
                                color: '#14AE5C',
                                fontSize: 18,
                            }}
                        />
                        <Typography
                            variant="body1"
                            style={{
                                color: '#14AE5C',
                                marginLeft: 8,
                            }}>
                            Add new
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default FreeTextField
