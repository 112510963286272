export const TABS = [
    {
        key: 'upcoming',
        value: 'Upcoming',
    },
    {
        key: 'past',
        value: 'Past',
    },
];

export const INTERVALS = [
    {
        key: 'today',
        value: 'Today',
    },
    {
        key: 'weekly',
        value: 'Weekly',
    },
    {
        key: 'monthly',
        value: 'Monthly',
    },
    {
        key: 'yearly',
        value: 'Yearly',
    },
];
