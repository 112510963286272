// Import necessary components from Material-UI
import React, { useState } from 'react';
import { Typography, Dialog, DialogActions, DialogContent } from '@mui/material';
import { Box, Divider, Grid, makeStyles } from '@material-ui/core';
import patientRecord from "../../../assests/icons/Folder_svg.svg";
import scheduleClinicalNote from "../../../assests/images/scheduleClinicalNote.svg";
import erxClinicalNote from "../../../assests/images/erxClinicalNote.svg";
import labClinicalNote from "../../../assests/images/labClinicalNote.svg";
import previewClinicalNote from "../../../assests/images/previewClinicalNote.svg";
import moment from 'moment';
import { sentErxUpatedTime } from 'services/Patient/patient';
import NewErxDialog from 'containers/PatientsSetting/view/MedicationPatient/newErxDialog';
import CHC from 'containers/PatientsSetting/demographic/Labs/CHC';
import { useDispatch, useSelector } from 'react-redux';
import { changeAppointment } from 'store/actions/schedule'
import { PATIENT_VISIT_TYPE } from 'containers/SchedulerMenu/constant'
import { useTimezone } from 'shared/hooks/useTimezone'
import { createSchedule } from 'services/Calendar';
import { useNotification } from 'shared/elements/Notification'
import SchedulerMenu from 'containers/SchedulerMenu'
import Confirm from 'components/shared/Elements/Confirm';

const useStyles = makeStyles(() => ({
    text: {
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#424242",
        padding: "0px 12px 0px 12px",
        cursor: "pointer"
    },
    statusbar: {
        padding: "10px 20px",
        borderRadius: "4px",
        boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        width: "100%",

    }
}))

const MultiSpecialityClinicalNote = ({ clinicalNoteData, onChangeActiveView }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const timezone = useTimezone()
    const notification = useNotification()
    const [openERX, setOpenERX] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openCHC, setOpenCHC] = useState(false)
    const [editTime, setEditTime] = useState('')
    const [activeTab, setActiveTab] = useState(1)
    const [newAppointmentModal, setNewAppointmentModal] = React.useState({
        status: false,
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const userData = useSelector((state) => state.permission.erx)
    const is_enable_eprescribe = useSelector((state) => state?.login?.userData?.practitioner?.is_enable_eprescribe)
    const statusOptions = useSelector((state) => state.setting.appointmentStatus)
    const {
        id: patientId,
    } = clinicalNoteData?.patient
    const navlist = [{
        id: "patient_record",
        icon: patientRecord,
        navName: "Patient Record"
    },
    {
        id: "schedule_appointment",
        icon: scheduleClinicalNote,
        navName: "Schedule Appointment"
    },
    {
        id: "erx_record",
        icon: erxClinicalNote,
        navName: "eRX"
    },
    {
        id: "labs",
        icon: labClinicalNote,
        navName: "Labs"
    },
    {
        id: "preview_record",
        icon: previewClinicalNote,
        navName: "Preview"
    }

    ]
    const lastUpatedTime = () => {
        let startDate = moment().utc()
        if (moment().tz('America/New_York').isDST()) {
            setEditTime(startDate.subtract(240, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        } else {
            setEditTime(startDate.subtract(300, 'minute').format('MM/DD/YYYY HH:mm:ss'))
        }
    }
    const sendErxUpdateTime = async () => {
        try {
            setOpenERX(false)
            const res = await sentErxUpatedTime(patientId, editTime)
            // if (res) {
            //     setOpenERX(false)
            // }
        } catch (error) {
            console.error(error)
        }
    }
    const checkAccess = () => {
        if (!userData.includes("access_erx") || !is_enable_eprescribe) {
            setOpenConfirm(true)
        } else if (userData.includes("access_erx") && is_enable_eprescribe) {
            setOpenERX(true)
            lastUpatedTime()
        }
    }
    const handleClick = (id) => {
        if (id === "erx_record") {
            checkAccess()
        } else if (id === 'labs') {
            setOpenCHC(true)
        } else if (id === 'preview_record') {
            onChangeActiveView('preview')
        } else if (id === 'patient_record') {
            window.open(`/patient/${patientId}/patient-record`)
        } else if (id === 'schedule_appointment') {
            newAppointmentModalToggle()
        }
    }
    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: clinicalNoteData.patient.id,
                        practitioner_id: clinicalNoteData.provider_id,
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                id,
            })
        } else {
            setNewAppointmentModal({
                status: !newAppointmentModal.status,
                start,
                end,
                duration,
                id,
            })
        }
    }
    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)
            await createSchedule(values)
            setIsSubmitting(false)
            notification('An appointment was successfully created!', 'success')
        } catch (error) {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'An appointment was not successfully created!',
                'error',
            )
        }
    }
    return (
        <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            alignContent="center"
            style={{ padding: '7px', marginBottom: '1px' }}>
            <Box className={classes.statusbar}>
                <div style={{ display: 'flex', alignItems: 'center', paddingRight: '12px' }}>
                    {navlist.map((item, index) => {
                        return (
                            <>
                                <img
                                    src={item.icon}
                                    alt=""
                                    style={{ paddingLeft: index === 0 ? '0px' : '12px' }}
                                />
                                <Typography
                                    className={classes.text}
                                    onClick={() => {
                                        handleClick(item.id)
                                    }}>
                                    {item.navName}
                                </Typography>
                                {navlist.length !== index + 1 && (
                                    <Divider
                                        variant="middle"
                                        flexItem
                                        orientation="vertical"
                                        className="divider_selectBox"
                                    />
                                )}
                            </>
                        )
                    })}
                </div>
            </Box>
            {userData.includes('access_erx') && is_enable_eprescribe ? (
                <NewErxDialog
                    open={openERX}
                    description
                    patientId={patientId}
                    handleClose={() => sendErxUpdateTime()}
                    prescription={true}
                />
            ) : (
                <Confirm
                    open={openConfirm}
                    btnText="Confirm"
                    message={'Sorry! You do not have permission to access this feature.'}
                    onCancel={() => setOpenConfirm(false)}
                    headerSize={15}
                />
            )}
            {newAppointmentModal.status && (
                <Dialog
                    open={newAppointmentModal.status}
                    sx={{
                        '& .MuiDialog-paperFullWidth': {
                            maxWidth: '1200px',
                            height: '100vh',
                            overflow: 'hidden',
                        },
                    }}
                    fullWidth={true}
                    onClose={newAppointmentModalToggle}
                    className={classes.root}>
                    <SchedulerMenu
                        isPopupType
                        tab={activeTab}
                        onChangeTab={(tab) => setActiveTab(tab)}
                        isFromClinicalNote
                        open={newAppointmentModal.status}
                        appointmentModal={newAppointmentModal}
                        loading={isSubmitting}
                        onModalClose={newAppointmentModalToggle}
                        setIsLoading={setIsSubmitting}
                        onSubmit={handleSubmit}
                        statusOptionsData={statusOptions.map((item) => ({
                            id: item.id,
                            key: item.code,
                            value: item.name,
                        }))}
                    />
                </Dialog>
            )}
            {openCHC && (
                <CHC
                    open={openCHC}
                    patientId={patientId}
                    handleClose={() => setOpenCHC(false)}
                    description
                    from={'clinicalNote'}
                />
            )}
        </Grid>
    )
};

export default MultiSpecialityClinicalNote;
