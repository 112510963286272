export const formatPhone = (phone) => {
  if (phone && phone !== "") {
      const char = {
          0: "(",
          3: ") ",
          6: " "
      };
      let number = "";
      phone = phone.replace(/\D/g, "");
      for (let i = 0; i < phone.length; i++) {
          number += (char[i] || "") + phone[i];
      }
      return number;
  }
  return null;
};