import React from "react";

export default function TeleMuteVolumnIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9167 8.13756V7.20423C14.9167 4.30701 12.9042 3.19868 10.4542 4.73479L7.61533 6.51395C7.30422 6.69868 6.9445 6.80562 6.58478 6.80562H5.1945C3.25005 6.80562 2.27783 7.77784 2.27783 9.72229V13.6112C2.27783 15.5556 3.25005 16.5278 5.1945 16.5278H7.13894" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.4543 18.5987C12.9043 20.1348 14.9168 19.0167 14.9168 16.1292V12.5903" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.621 9.15845C19.496 11.2487 19.2335 13.689 17.8335 15.5557" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.8959 7.58325C22.325 10.9763 21.8973 14.943 19.6125 17.986" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.7223 1.94434L2.27783 21.3888" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}
