import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import _, { debounce, isObject } from 'lodash'
import SearchIcon from '../../assests/icons/search_icon.svg'
import { Autocomplete, createFilterOptions } from '@mui/material'
import { getAccountById, searchProvider } from 'services/Calendar'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { ButtonIconEl } from 'shared/elements'
import { timezoneFormatter } from 'utilities/formatTimeZone'
import { useDispatch } from 'react-redux'
import { setLocationAccoringToProvider } from 'store/actions/schedule'
const filterData = createFilterOptions()

const renderTextField = ({ name, value, placeholder, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchProviderInput = (props) => {
    const {
        disabled,
        handleAddRow,
        isProviderOnly,
        helperText,
        error,
        placeholder,
        getProviderInfo,
        handleSearch,
        hideSearchIcon,
        allowClear = false,
        fromSchedulePage = false
    } = props

    const providerId = props.value
    const dispatch = useDispatch()
    const [value, setValue] = useState()
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [open, setOpen] = useState(false)

    useEffect(async () => {
        if (providerId) {
            const response = await getAccountById(providerId)
            if(fromSchedulePage){
                dispatch(setLocationAccoringToProvider(response?.data?.location_id))
            }
            getProviderInfo?.(response.data)
            setOptions([
                {
                    id: providerId,
                    value: `${response?.data?.first_name} ${response?.data?.last_name}`,
                    label: `${response?.data?.first_name} ${response?.data?.last_name}`,
                    key: providerId,
                },
            ])
            setValue(providerId)
            setInputValue(`${response?.data?.first_name} ${response?.data?.last_name} - ${timezoneFormatter(response?.data?.timezone) ?? "N/A"}`)
        } else {
            setInputValue('')
            getProviderInfo?.()
            if(fromSchedulePage){
                dispatch(setLocationAccoringToProvider([]))
            }
        }
    }, [providerId])

    const search = useRef(
        debounce(async (val) => {
            try {
                setLoading(true)
                let data
                if (typeof handleSearch === 'function') {
                    data = await handleSearch(val)
                } else {
                    const response = await searchProvider(val)
                    data = response.data.hits.hits.map((item) => ({
                        id: item._source?.id,
                        value: item._source?.fullName,
                        label: item._source?.fullName,
                        key: item._source?.id,
                        providerAccount: item._source?.providerAccount,
                    }))
                    if (isProviderOnly) {
                        data = data.filter((item) => item.providerAccount)
                    }
                }
                setOptions(data)
                setLoading(false)
            } catch (error) {
                console.error(error)
                setLoading(false)
            }
        }, 800),
    )

    useEffect(() => {
        let active = true
        if (!open) return
            ; (async () => {
                setLoading(true)

                if (active) {
                    await search.current(inputValue)
                }
                setLoading(false)
            })()

        return () => {
            active = false
        }
    }, [open, value, inputValue])
    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                disabled={disabled}
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                selectOnFocus
                clearIcon={true}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                inputValue={inputValue}
                value={value}
                open={open}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <td>
                                <span className="hightlight">{option.value}</span>
                                {' - '}
                                <span
                                    className="hightlight"
                                    style={{
                                        color: 'rgb(85, 113, 198)',
                                    }}>
                                    {option.id}
                                </span>
                            </td>
                        </li>
                    )
                }}
                // filterOptions={(optionDatas, params) => {
                //     const filtered = filterData(
                //         optionDatas,
                //         params,
                //     )
                //     filtered.push(...optionDatas)
                //     return optionDatas
                // }}
                onOpen={() => {
                    setOpen(true)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    setOptions(isObject(newValue) ? [newValue, ...options] : options)
                    setOpen(false)
                    handleAddRow(newValue)
                    setValue(newValue?.id)
                }}
                onInputChange={(event, newInputValue) => {
                    if (!event) {
                        return
                    }
                    setInputValue(newInputValue)
                    if (!newInputValue) getProviderInfo?.()
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: placeholder ? placeholder : 'Search...',
                        props: {
                            ...params,
                            helperText,
                            error,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: hideSearchIcon || (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : allowClear && inputValue ? (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 8,
                                                    right: 30,
                                                }}
                                                onClick={() => {
                                                    setInputValue('')
                                                }}>
                                                <ButtonIconEl
                                                    size="small"
                                                    icon={undefined}
                                                    iconHover={undefined}>
                                                    <HighlightOffIcon
                                                        style={{
                                                            fontSize: 18,
                                                            color: '#9CA9B8',
                                                        }}
                                                    />
                                                </ButtonIconEl>
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                ),
                            },
                        },
                    })
                }
            />
        </Box>
    )
}

export default React.memo(SearchProviderInput)
