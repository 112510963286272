import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import _ from 'lodash'
import { getPatientName } from 'containers/PatientsSetting/shared'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import { SelectEl } from '../../../shared/elements'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import TooltipTextField from '../../shared/Elements/TooltipTextField'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import RadioOptions from '../reusable/RadioOption'

const useStyles = makeStyles({
    table: {
        '& .MuiTableCell-root': {
            // border: '1px solid #B2C0CF',
            border: 'unset',
            width: '16.28%',
            boxSizing: 'border-box',
            padding: 8,
            '&:last-child': {
                width: '6%',
            },
        },
        '& .MuiTableRow-root': {
            margin: '16px 0',
            background: '#FAFAFA',
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
        },
        '& .MuiTableRow-head': {
            margin: 0,
            marginBottom: '-12px',
            background: 'white',
        },
        '& .MuiTableCell-head': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    colorContent: {
        color: '#667689',
    },
    ellipsis__text: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    cover__add__new: {
        cursor: 'pointer',
        width: 'fit-content',
    },
    icon: {
        cursor: 'pointer',
        padding: 2,
        width: 28,
    },
    text__field: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #e9ecf1 !important',
        },
        '& .MuiOutlinedInput-root': {
            color: '#667689',
        },
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important',
        },
    },
})

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

const decisionMakerOptions = [
    { label: '- Select One -', value: '' },
    { value: 'Yes', key: 'Yes' },
    { value: 'No', key: 'No' },
]

const tableHeading = ['Role', 'Name', 'Participation', 'Medical Decision Maker', 'Comment']

const SelfSectionRow = ({
    participationOptions,
    patientName,
    rows,
    generalRows,
    customRows,
    handleChange,
    nonEditable = false
}) => {
    const updateRows = _.cloneDeep(rows)

    const handleInputChange = (name, value) => {
        if (updateRows[0]) {
            const row = updateRows[0] || {}
            row.was_available = row.was_available || ''
            row[name] = value
        } else {
            const row = {
                was_available: '',
                name: patientName,
                participation: '',
                decision_maker: '',
                comment: '',
                [name]: value,
            }
            updateRows.push(row)
        }
        handleChange([...updateRows, ...generalRows, ...customRows])
    }
    return (
        <TableRow>
            <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: 200, padding: '8px 16px' }}>
                {renderSelfSection({
                    rowValue: updateRows[0],
                    handleInputChange,
                    nonEditable:nonEditable
                })}
            </TableCell>
            <TableCell align="left" component="th" scope="row">
                <Typography style={{ color: '#667689' }}>{patientName}</Typography>
            </TableCell>
            <TableCell align="left" component="th" scope="row">
                <RenderDropDow
                    options={participationOptions}
                    handleInputChange={handleInputChange}
                    name={'participation'}
                    value={updateRows[0]?.participation || ''}
                    nonEditable={nonEditable}
                />
            </TableCell>
            <TableCell align="left" component="th" scope="row" style={{ width: 200 }}>
                <RenderDropDow
                    options={decisionMakerOptions}
                    name={'decision_maker'}
                    value={updateRows[0]?.decision_maker}
                    handleInputChange={handleInputChange}
                    nonEditable={nonEditable}
                />
            </TableCell>
            <TableCell align="left" component="th" scope="row" style={{ flex: 1 }}>
                <RenderTextField
                    name={'comment'}
                    placeholder={'Comment'}
                    value={updateRows[0]?.comment}
                    handleInputChange={handleInputChange}
                    nonEditable={nonEditable}
                />
            </TableCell>
            <TableCell></TableCell>
        </TableRow>
    )
}

const RoleSectionRow = ({
    roleOptions,
    participationOptions,
    rows,
    selfRows,
    customRows,
    handleChange,
    nonEditable
}) => {
    const updateRows = _.cloneDeep(rows)
    const classes = useStyles()
    if (updateRows.length === 0) {
        updateRows.push({
            role: '',
            name: '',
            participation: '61d89aebae09aaff99dd9a52',
            decision_maker: '',
            comment: '',
        })
    }
    const handleInputChange = (name, value, index) => {
        if (updateRows[index]) {
            const row = updateRows[index] || {}
            row.role = row.role || ''
            row[name] = value
        } else {
            const row = {
                role: '',
                [name]: value,
            }
            updateRows.push(row)
        }
        handleChange([...selfRows, ...updateRows, ...customRows])
    }

    const handleAddOrRemove = (action, index) => {
        if(nonEditable){return}
        const defaultRoleRow = {
            role: '',
            name: '',
            participation: '61d89aebae09aaff99dd9a52',
            decision_maker: '',
            comment: '',
        }
        let updatedRow = {}
        if (action === 'add') {
            updatedRow = [...updateRows, { ...defaultRoleRow }]
        } else {
            updatedRow = updateRows.filter((v, i) => i !== index)
            if (updatedRow.length === 0) {
                updatedRow.push({ ...defaultRoleRow })
            }
        }
        handleChange([...selfRows, ...updatedRow, ...customRows])
    }
    return (
        <Grid>
            {updateRows.map((r, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell align="left" component="th" scope="row" style={{ width: 200 }}>
                            <RenderDropDow
                                options={roleOptions}
                                handleInputChange={(name, value) =>
                                    handleInputChange(name, value, index)
                                }
                                name={'role'}
                                value={r.role}
                                nonEditable={nonEditable}
                            />
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                            <RenderTextField
                                name={'name'}
                                placeholder={'Patient name'}
                                value={r.name}
                                handleInputChange={(name, value) =>
                                    handleInputChange(name, value, index)
                                }
                                nonEditable={nonEditable}
                            />
                        </TableCell>
                        <TableCell align="left" component="th" scope="row">
                            <RenderDropDow
                                name={'participation'}
                                options={participationOptions}
                                value={r.participation}
                                handleInputChange={(name, value) =>
                                    handleInputChange(name, value, index)
                                }
                                nonEditable={nonEditable}
                            />
                        </TableCell>
                        <TableCell align="left" component="th" scope="row" style={{ width: 200 }}>
                            <RenderDropDow
                                name={'decision_maker'}
                                value={r.decision_maker}
                                options={decisionMakerOptions}
                                handleInputChange={(name, value) =>
                                    handleInputChange(name, value, index)
                                }
                                nonEditable={nonEditable}
                            />
                        </TableCell>
                        <TableCell align="left" component="th" scope="row" style={{ flex: 1 }}>
                            <RenderTextField
                                name={'comment'}
                                placeholder={'Comment'}
                                value={r.comment}
                                handleInputChange={(name, value) =>
                                    handleInputChange(name, value, index)
                                }
                                nonEditable={nonEditable}
                            />
                        </TableCell>
                        <TableCell>
                            {updateRows.length >= 1 && (
                                <RenderAddOrRemove
                                    index={index}
                                    handleAddOrRemove={handleAddOrRemove}
                                    nonEditable={nonEditable}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                )
            })}
            {updateRows.length < 5 && (
                <Grid
                    container
                    className={classes.cover__add__new}
                    onClick={() => handleAddOrRemove('add')}>
                    <AddCircleOutlineOutlined
                        style={{
                            color: '#14AE5C',
                            fontSize: 18,
                        }}
                    />
                    <Typography
                        variant="body1"
                        style={{
                            color: '#14AE5C',
                            marginLeft: 8,
                        }}>
                        Add new
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

// const CustomRoleSectionRow = ({
//     participationOptions,
//     rows,
//     selfRows,
//     generalRows,
//     handleChange,
// }) => {
//     const updateRows = _.cloneDeep(rows)
//     const classes = useStyles()
//     if (updateRows.length === 0) {
//         updateRows.push({
//             custom_role: '',
//             name: '',
//             participation: '',
//             decision_maker: '',
//             comment: '',
//         })
//     }
//     const handleInputChange = (name, value, index) => {
//         if (updateRows[index]) {
//             const row = updateRows[index] || {}
//             row.custom_role = row.custom_role || ''
//             row[name] = value
//         } else {
//             const row = {
//                 custom_role: '',
//                 [name]: value,
//             }
//             updateRows.push(row)
//         }
//         handleChange([
//             ...selfRows,
//             ...generalRows,
//             ...updateRows,
//         ])
//     }
//     const handleAddOrRemove = (action, index) => {
//         const defaultCustomRoleRow = {
//             custom_role: '',
//             name: '',
//             participation: '',
//             decision_maker: '',
//             comment: '',
//         }
//         let updatedRow = {}
//         if (action === 'add') {
//             updatedRow = [
//                 ...updateRows,
//                 { ...defaultCustomRoleRow },
//             ]
//         } else {
//             updatedRow = updateRows.filter(
//                 (v, i) => i !== index,
//             )
//             if (updatedRow.length === 0) {
//                 updatedRow.push({ ...defaultCustomRoleRow })
//             }
//         }
//         handleChange([
//             ...selfRows,
//             ...generalRows,
//             ...updatedRow,
//         ])
//     }
//     return (
//         <Grid>
//             {updateRows.map((r, index) => (
//                 <TableRow key={index}>
//                     <TableCell
//                         align="left"
//                         component="th"
//                         scope="row"
//                         style={{ width: 200 }}>
//                         <RenderTextField
//                             name={'custom_role'}
//                             placeholder={'Role'}
//                             value={r.custom_role}
//                             handleInputChange={(
//                                 name,
//                                 value,
//                             ) =>
//                                 handleInputChange(
//                                     name,
//                                     value,
//                                     index,
//                                 )
//                             }
//                         />
//                     </TableCell>
//                     <TableCell
//                         align="left"
//                         component="th"
//                         scope="row">
//                         <RenderTextField
//                             name={'name'}
//                             placeholder={'Patient name'}
//                             value={r.name}
//                             handleInputChange={(
//                                 name,
//                                 value,
//                             ) =>
//                                 handleInputChange(
//                                     name,
//                                     value,
//                                     index,
//                                 )
//                             }
//                         />
//                     </TableCell>
//                     <TableCell
//                         align="left"
//                         component="th"
//                         scope="row">
//                         <RenderDropDow
//                             options={participationOptions}
//                             handleInputChange={(
//                                 name,
//                                 value,
//                             ) =>
//                                 handleInputChange(
//                                     name,
//                                     value,
//                                     index,
//                                 )
//                             }
//                             name={'participation'}
//                             value={r.participation}
//                         />
//                     </TableCell>
//                     <TableCell
//                         align="left"
//                         component="th"
//                         scope="row"
//                         style={{ width: 200 }}>
//                         <RenderDropDow
//                             options={decisionMakerOptions}
//                             handleInputChange={(
//                                 name,
//                                 value,
//                             ) =>
//                                 handleInputChange(
//                                     name,
//                                     value,
//                                     index,
//                                 )
//                             }
//                             name={'decision_maker'}
//                             value={r.decision_maker}
//                         />
//                     </TableCell>
//                     <TableCell
//                         align="left"
//                         component="th"
//                         scope="row"
//                         style={{ flex: 1 }}>
//                         <RenderTextField
//                             name={'comment'}
//                             placeholder={'Comment'}
//                             value={r.comment}
//                             handleInputChange={(
//                                 name,
//                                 value,
//                             ) =>
//                                 handleInputChange(
//                                     name,
//                                     value,
//                                     index,
//                                 )
//                             }
//                         />
//                     </TableCell>
//                     <TableCell>
//                         {updateRows.length >= 1 && (
//                             <RenderAddOrRemove
//                                 index={index}
//                                 handleAddOrRemove={
//                                     handleAddOrRemove
//                                 }
//                             />
//                         )}
//                     </TableCell>
//                 </TableRow>
//             ))}
//             {updateRows.length < 2 && (
//                 <Grid
//                     container
//                     className={classes.cover__add__new}
//                     onClick={() =>
//                         handleAddOrRemove('add')
//                     }>
//                     <AddCircleOutlineOutlined
//                         style={{
//                             color: '#14AE5C',
//                             fontSize: 18,
//                         }}
//                     />
//                     <Typography
//                         variant="body1"
//                         style={{
//                             color: '#14AE5C',
//                             marginLeft: 8,
//                         }}>
//                         Add new
//                     </Typography>
//                 </Grid>
//             )}
//         </Grid>
//     )
// }

const RenderDropDow = ({ options, name, handleInputChange, value, nonEditable }) => {
    const classes = useStyles()
    return (
        <SelectEl
            name={name}
            options={options}
            placeholder="- Select One -"
            value={value}
            disabled={nonEditable}
            selectProps={{
                style: {
                    height: 41,
                    fontSize: 14,
                },
            }}
            renderValue={(sel) => {
                return typeof sel === 'string' && !sel ? (
                    <Typography className={classes.colorContent}>- Select One -</Typography>
                ) : (
                    <Typography className={clsx(classes.ellipsis__text, classes.colorContent)}>
                        {options.find((item) => item.key === sel)?.value}
                    </Typography>
                )
            }}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const RenderTextField = ({ name, value, placeholder = '', handleInputChange,nonEditable }) => {
    const classes = useStyles()
    return (
        <TooltipTextField
            className={classes.text__field}
            fullWidth
            variant="outlined"
            name={name}
            placeholder={placeholder}
            size="small"
            value={value}
            disabled = {nonEditable}
            onChange={(e) => {
                const { name, value } = e.target
                handleInputChange(name, value)
            }}
        />
    )
}

const renderSelfSection = ({ rowValue = {}, handleInputChange,nonEditable=false }) => {
    const wasAvailable = rowValue.was_available
    const classes = useStyles()
    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <Typography className={classes.colorContent}>The patient</Typography>
            <RadioOptions
                options={[
                    { label: 'was present', value: 'Yes' },
                    {
                        label: 'was not present',
                        value: 'No',
                    },
                ]}
                flexDirection="row"
                md={12}
                name={`was_available`}
                value={wasAvailable === undefined ? '' : wasAvailable ? 'Yes' : 'No'}
                onChange={(e) => {
                    if(!nonEditable){
                        const { name, value } = e.target

                        handleInputChange(name, value === 'Yes')
                    }
                }}
            />
        </Box>
    )
}

const RenderAddOrRemove = ({ index, handleAddOrRemove,nonEditable }) => {
    const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
    return (
        <Box sx={{ width: 40, display: 'flex' }}>
            <IconButton
                variant="outlined"
                disabled={nonEditable}
                onClick={() => {
                    setAlertDeleteOpen(true)
                }}
            // className={classes.show_header_btn}
            >
                <DeleteIcon />
            </IconButton>
            {/* {index + 1 === rows.length && (
                <AddCircleOutlineOutlined
                    style={{
                        cursor: 'pointer',
                        padding: 2,
                        width: 28,
                    }}
                    color="primary"
                    fontSize="large"
                    onClick={() => handleAddOrRemove('add')}
                />
            )} */}
            <AlertDeleteDialog
                open={alertDeleteOpen}
                handleClose={() => {
                    if(!nonEditable){
                     setAlertDeleteOpen(false)
                    }
                }}
                handleDelete={() => {
                    if(!nonEditable){
                        handleAddOrRemove('remove', index)
                        setAlertDeleteOpen(false)
                    }
                }}
            />
        </Box>
    )
}

const getDropdownOptions = (master = [], key) => {
    return master.map((im) => ({
        value: im[key],
        key: im._id,
    }))
}

const PersonPresentInSession = ({ master, noteDetails, fieldName, values, onChange, macros, nonEditable = false}) => {
    const classes = useStyles()
    const { roleMasters, participationMasters } = master
    const roleOptions = getDropdownOptions(roleMasters, 'name')
    const participationOptions = getDropdownOptions(participationMasters, 'name')

    const generalRows = useMemo(
        () => values?.sessions?.filter((v) => v.hasOwnProperty('role')),
        [values],
    )
    const customRows = useMemo(
        () => values?.sessions?.filter((v) => v.hasOwnProperty('custom_role')),
        [values],
    )
    const selfRows = useMemo(
        () => values?.sessions?.filter((v) => v.hasOwnProperty('was_available')),
        [values],
    )

    const handleChange = (updatedValues) => {
        onChange(`${fieldName}.sessions`, updatedValues)
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <TableContainer component={Paper}>
                <Table className={classes.table} sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {tableHeading.map((heading, index) => (
                                <TableCell
                                    key={index}
                                    align="left"
                                    style={{
                                        width: (index === 3 || index === 0) && 200,
                                        flex: index === 4 && 1,
                                    }}>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            marginRight: 8,
                                            fontWeight: 500,
                                            color: '#303E4E',
                                        }}>
                                        {heading}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <SelfSectionRow
                            participationOptions={participationOptions}
                            patientName={getPatientName({
                                first_name: noteDetails?.patient?.first_name || noteDetails?.patient_first_name,
                                last_name: noteDetails?.patient?.last_name || noteDetails?.patient_last_name,
                            })}
                            handleChange={handleChange}
                            rows={selfRows}
                            generalRows={generalRows}
                            customRows={customRows}
                            nonEditable={nonEditable}
                        />
                        <RoleSectionRow
                            roleOptions={roleOptions}
                            participationOptions={participationOptions}
                            handleChange={handleChange}
                            rows={generalRows}
                            customRows={customRows}
                            selfRows={selfRows}
                            nonEditable={nonEditable}
                        />
                        {/* <CustomRoleSectionRow
                            roleOptions={roleOptions}
                            participationOptions={
                                participationOptions
                            }
                            handleChange={handleChange}
                            rows={customRows}
                            generalRows={generalRows}
                            selfRows={selfRows}
                        /> */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid item>
                <NarrativeAndMacro
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    onChange={onChange}
                    values={values}
                    macros={macros}
                    meta={{
                        values: {
                            selfRows,
                            customRows,
                            generalRows,
                        },
                        master: {
                            roleOptions,
                            participationOptions,
                        },
                    }}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Box>
    )
}

export default PersonPresentInSession
