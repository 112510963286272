import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Box, CircularProgress } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Routes from 'constants/routes'
import { apiVitalSigns } from 'services/Patient/apiVitalSigns'
import { Card } from '../view/Card'
import { NoData } from './NoData'
import { ContactsCreate } from '../view/PatientsContactsView/ContactsCreate'
import { patientContact } from 'services/Patient/apiPatientContacts'
import { ReactComponent as EmergencyContact } from '../../../assests/images/emergency_contact.svg'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
    patientId?: string
    encounter_id?: string
}

const useStyles = makeStyles(() => ({
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'transparent',
    },
    viewall: {
        color: '#667689',
        '&:hover': {
            color: '#4e52d9',
            textDecoration: 'underline',
        },
    },
}))

export const PatientsContacts = (props: Props) => {
    //Hooks
    const history = useHistory()
    const classes = useStyles()

    //State
    const [patientId, setPatientId] = useState('')
    const { id } = useParams<{ id: string }>()
    const [contacts, setContacts] = useState([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            fetchAllContacts()
        }

        return () => abortController.abort()
    }, [patientId])

    useEffect(() => {
        setPatientId(props.patientId || id)
    }, [props.patientId, id])

    //handle
    const fetchAllContacts = async () => {
        try {
            setLoading(true)
            const res = await patientContact.getList(patientId)
            console.log(res, 'AVT')

            setContacts(res.data.slice(0, 3) || [])
        } catch (ex) {
            console.error('d', ex)
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.PATIENT_CONTACT.replace(':id', patientId))

    const forceReload = () => {
        fetchAllContacts()
    }
    const Content = () => (
        <>
            <Grid
                container
                direction="column"
                wrap="nowrap"
                style={{
                    gap: 8,
                    height: '100%',
                    overflow: 'auto',
                    paddingRight: 15,
                    flexWrap: 'nowrap',
                }}>
                {contacts.map((item: any) => {
                    return (
                        <Grid
                            container
                            key={item?.id}
                            justifyContent="space-between"
                            wrap="nowrap"
                            alignItems="center"
                            style={{
                                borderBottom: '1px solid #EFF2F5',
                                paddingBottom: 8,
                            }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                }}>
                                <Typography style={{ paddingLeft: '10px' }}>
                                    {item.related_person_name} - {item.relation}
                                </Typography>
                                <Typography
                                    style={{
                                        color: '#9CA9B8',
                                        fontSize: 12,
                                        paddingLeft: '10px',
                                    }}>
                                    {item.related_person_phone}
                                </Typography>
                            </Box>
                            {item.is_emergency_contact === '1' ||
                                (item.is_emergency_contact === 'true' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}>
                                        <Typography
                                            style={{
                                                color: 'red',
                                                fontSize: 12,
                                                paddingLeft: '8px',
                                            }}>
                                            <EmergencyContact />
                                        </Typography>
                                    </Box>
                                ))}
                        </Grid>
                    )
                })}
            </Grid>

            {!!contacts.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props?.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props?.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Patients Contacts">
            {!!loading && (
                <Grid
                    container
                    className={classes.loader}
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress color="inherit" thickness={1.5} />
                </Grid>
            )}

            {contacts.length ? <Content /> : <NoData />}
            {!!isAddingNew && (
                <ContactsCreate
                    open={isAddingNew}
                    patientId={patientId}
                    itemId={null}
                    onClose={() => {
                        setIsAddingNew(false)
                    }}
                    forceReload={forceReload}
                />
            )}
        </Card>
    )
}
