import { Popover, Box } from '@mui/material'
import { ReactComponent as BlueWarningIcon } from '../../../../assests/icons/blue_warning_icon.svg'
import {
    makeStyles,
} from '@material-ui/core'
const useStyles = makeStyles(() => ({
    fontStyle1: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px"
    },
    fontStyle2: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "20px"
    },
    confirmButton: {
        cursor: "pointer",
        display: "flex",
        width: "73px",
        height: "32px",
        // padding: "8px 20px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: "0",
        borderRadius: "5px",
        background: "#5571C6",
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
    },
    cancelButton: {
        display: "flex",
        height: "32px",
        padding: "0px 12px",
        alignItems: "center",
        cursor: "pointer",
    },
    share_contact_active: {
        "& path": {
            fill: "#5571C6"
        }
    },
    share_contact_deactive: {
        "& path": {
            fill: "#CBCBCC"
        }
    },


}))
const SharedContactAlert = ({ id, open, anchorEl, closeFunction, submitFunc, cancelFunc, text1, text2, heading, width, height, textOpposite }) => {

    const classes = useStyles()

    const handleClose = () => {
        closeFunction()
    }

    const cancelClick = () => {
        cancelFunc()
    }

    const successConfirm = () => {
        submitFunc()
    }
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                // Custom styles
                ".MuiPopover-paper": {
                    width: `${width || "351px"}`,
                    height: `${height || "187px"}`,
                    paddingLeft: "10px",
                    paddingTop: "14px",
                    paddingRight: "13px",
                    paddingBottom: "10px"

                }
            }}
        >
            <div style={{ display: "flex" }}>
                <BlueWarningIcon /> <Box className={classes.fontStyle1} style={{ marginLeft: "9px" }}>{heading}</Box>
            </div>
            {text1 && text2 && <Box className={classes.fontStyle2} style={{ marginTop: "15px" }}>
                {text1}
                <br />
                <br />
                {text2}
            </Box>}
            {textOpposite && <Box className={classes.fontStyle2} style={{ marginTop: "15px" }}>
                <div dangerouslySetInnerHTML={{ __html: textOpposite }}></div>
            </Box>}
            <Box style={{ display: "flex", alignItems: "end", justifyContent: "end", marginTop: "33px" }}>
                <Box className={classes.cancelButton} onClick={cancelClick}>Cancel</Box>
                <Box className={classes.confirmButton} onClick={successConfirm}>Confirm</Box>
            </Box>
        </Popover>
    )
}
export default SharedContactAlert