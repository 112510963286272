import React, { useState } from 'react'
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import * as yup from 'yup'

import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'

import './styles.scss'
import { MediaKeyword } from './interfaces'

const useStyles = makeStyles({
    button_cancel: {
        background: '#303E4E !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
    },
    button_create: {
        background: '#5571C6 !important',
        color: 'white',
        fontWeight: 400,
        borderRadius: '8px !important',
        fontSize: '15px !important',
        width: '100%',
        textTransform: 'none',
    },
})

interface Props {
    open: boolean
    loading: boolean
    mediaKeyword?: MediaKeyword | null
    onSubmit: (values: MediaKeyword) => void
    onClose: () => void
}

export const CreateMediaKeywordDialog = (props: Props) => {
    const classes = useStyles()

    const [dataLoading, setDataLoading] = useState(false)

    const handleSubmit = ({ keyword }: MediaKeyword) => {
        props.onSubmit({
            keyword,
            icd_10: '145',
        })
    }

    const validationSchema = yup.object({
        keyword: yup.string().required('Keyword is required!'),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: { keyword: props.mediaKeyword?.keyword || '' } as MediaKeyword,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            title={props.mediaKeyword?.id ? 'Update Keyword' : 'Add New Keyword'}
            width={600}
            description={'Media Keyword Details'}
            open={props.open}
            onClose={() => props.onClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            // footer={<div>Here</div>}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                {dataLoading && (
                    <Box
                        sx={{
                            display: 'flex',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1,
                        }}>
                        <CircularProgress />
                    </Box>
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid item xs={12}>
                            <TextBox
                                label="Keyword"
                                required
                                name="keyword"
                                value={formik.values.keyword}
                                placeholder="Enter media keyword"
                                error={formik.touched.keyword && Boolean(formik.errors.keyword)}
                                helperText={formik.touched.keyword && formik.errors.keyword}
                                onChange={(value) => formik.setFieldValue('keyword', value)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                className={classes.button_cancel}
                                onClick={props.onClose}>
                                Cancel
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                            <LoadingButton
                                loading={props.loading}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                className={classes.button_create}
                                onClick={formik.handleSubmit}>
                                {props.mediaKeyword?.id ? 'Update' : 'Save'}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
