import React, { useState, useEffect, useCallback } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { debounce } from 'lodash'
import { Box, FormLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import { ReactComponent as SearchIcon } from 'assests/images/icon_search.svg'
import { getOccupationCode } from 'services/Patient/patient'
import '../styles.scss'

export const SearchOccupation = (props) => {
    const [inputValue, setInputValue] = useState(props.value?.code || '')
    const [cacheValue, setCacheValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [autoCompleteVisible, setAutoCompleteVisibility] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()

        if (inputValue.length && inputValue !== cacheValue) {
            searchMedicalProblem(inputValue)
        }

        return () => abortController.abort()
    }, [inputValue])

    useEffect(() => {
        if (props.value?.code) {
            setInputValue(props.value.display || props.value.code)
        }
    }, [props.value?.code])

    const searchMedicalProblem = useCallback(
        debounce(async (val) => {
            try {
                setLoading(true)

                const payload = {
                    size: 25,
                    query: {
                        multi_match: {
                            query: val,
                            type: 'bool_prefix',
                            fields: [
                                'code',
                                'code._2gram',
                                'code._3gram',
                                'display',
                                'display._2gram',
                                'display._3gram',
                            ],
                        },
                    },
                    highlight: {
                        pre_tags: ['<em>'],
                        post_tags: ['</em>'],
                        fields: {
                            code: {
                                number_of_fragments: 1,
                                fragment_size: 100,
                            },
                            display: {
                                number_of_fragments: 1,
                                fragment_size: 100,
                            },
                        },
                    },
                }

                const resp = await getOccupationCode(payload)
                const data = resp.data.hits.hits
                setOptions(
                    data.map((item, i) => ({
                        ...item,
                        key: i,
                    })),
                )

                setLoading(false)
            } catch (error) {
                setLoading(false)
            }
        }, 500),
        [],
    )

    const filterAutoComplete = () => {
        return options
    }

    return (
        <>
            <FormLabel component="p" className="input-form-label">
                <span className={!!props.required ? 'required' : ''}>{props.label}</span>
            </FormLabel>
            <Box
                sx={{
                    width: '100%',
                    '& .MuiIconButton-root': {
                        transform: 'none !important',
                    },
                    '& .MuiAutocomplete-root': {
                        width: '100%',
                    },
                    '& .MuiInputBase-input': {
                        marginRight: 24,
                    },
                }}>
                <Autocomplete
                    popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                    selectOnFocus
                    clearIcon={false}
                    forcePopupIcon={!loading}
                    autoComplete
                    loading={loading}
                    options={options}
                    value={{
                        _source: {
                            display: inputValue,
                            code: props.value?.code || inputValue,
                        },
                    }}
                    isOptionEqualToValue={(option, value) =>
                        value._source.code === option._source.code ||
                        value._source.display === option._source.display
                    }
                    open={autoCompleteVisible}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option._id}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: `<span class="highlight">${
                                            option.highlight?.display || option._source?.display
                                        }</span>`,
                                    }}
                                />
                            </li>
                        )
                    }}
                    filterOptions={filterAutoComplete}
                    onOpen={() => setAutoCompleteVisibility(true)}
                    onClose={() => setAutoCompleteVisibility(false)}
                    onChange={(event, newValue) => {
                        setCacheValue(inputValue)

                        props.onChange({
                            display: newValue?._source.display,
                            code: newValue?._source.code,
                        })
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                    }}
                    getOptionLabel={(option) => option._source?.display || ''}
                    sx={{ width: 300 }}
                    renderInput={(params) => {
                        const renderProps = {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            },
                        }

                        return (
                            <TextField
                                variant="outlined"
                                name={props.name ? props.name : ''}
                                placeholder={props.placeholder}
                                error={props.error}
                                helperText={props.helperText}
                                {...renderProps}
                            />
                        )
                    }}
                />
            </Box>
        </>
    )
}
