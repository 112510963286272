import { makeStyles, MenuItem, Select } from '@material-ui/core'
import {
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as CopyIcon } from '../../../assests/icons/copyNew.svg'
import CommentIcon from '../../../assests/icons/Comment.svg'
import DeleteCommentIcon from '../../../assests/icons/DeleteComment.svg'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import SocialHistoryCopy from './SocialHistoryCopy'
import moment from 'moment'

const SelectDropDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
        <path
            d="M8.68365 9.33047L12.2659 5.82422L13.2892 6.8258L8.68365 11.3336L4.07812 6.8258L5.10141 5.82422L8.68365 9.33047Z"
            fill="#667689"
        />
    </svg>
)

const useStyles = makeStyles(() => ({
    // outsideContainer: {
    //     borderRadius: '5px',
    //     border: '1px solid #E0E0E0',
    //     background: '#FFF',
    //     padding: 10,
    // },
    outsideContainer: {
        borderRadius: '5px',
        border: '1px solid #E0E0E0',
        background: '#FFF',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#F8F9FB',
        padding: '12px 16px 12px 12px;',
        borderBottom: '1px solid #E0E0E0'
    },
    headingName:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '22px', 
    },
    copy: {
        display: 'flex',
        height: '24px',
        padding: '2px 8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '4px',
        border: '1px solid #E0E0E0',
        background:'#FFF',
        cursor:'pointer'
    },
    copyText:{
        color: '#303E4E',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
    },
    formContainer: {
        padding: '20px 20px',
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
    },
    formLabel: {
        color: '#303E4E !important',
        fontFamily: 'Rubik !important',
        fontSize: '12px !important',
        fontStyle: 'normal !important',
        fontWeight: "500 !important",
        lineHeight: '16px !important',
    },
    selectComponent:{
        width: '100%' , 
        height:"32px", 
        background: '#F8F9FB !important'
    },
    section: {
        width: '100%',
        display: 'flex',
        gap: 100,
        alignItems: 'flex-start'
        // justifyContent: 'space-between',
    },
    commentSection:{
        marginTop: '8px',
        display: 'flex',
        gap: 10,
        alignItems: 'center',
    },
    addCommentText:{
        color: '#5571C6',
        fontFamily: 'Rubik',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px', 
        cursor: 'pointer',
    },
    removeCommentText:{
        color: '#E81A1A',
        fontFamily: 'Rubik',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px', 
        cursor: 'pointer',
        paddingTop: '1px',
    },
    questionsBox: {
        borderRadius: '5px',
        border: '1px solid #CFCFCF',
        width: '100%',
        height: 'max-content',
        padding: '15px 15px',
        display: 'flex',
        flexDirection: 'column',
        gap: 17,
    },
    questionLine: {
        display: 'flex',
        justifyContent: 'space-between',
        // gap: 20,
        // alignItems: 'center',
    },
    question: {
        // width: '70%',
        color: '#303E4E',
        fontWeight: 400,
        fontSize: 14,
        fontFamily: 'Rubik',
        lineHeight: '20px',
        paddingTop: '8px',
    },
    placeholder: {
        fontSize: '14px !important',
        color: '#989CA8 !important',
        fontWeight: '400 !important',
        fontFamily: 'Rubik !important',
        fontStyle: 'normal !important',
        lineHeight: '20px !important',
    },
}))

const maritalStatusOptions = ['Married', 'Single', 'Divorced', 'Widow']

const livingArrangementOptions = [
    'In an apartment',
    'In a house',
    'Alone',
    'With roommates',
    'With wife',
    'With husband',
    'With significant other',
    'With mother and father',
    'With mother',
    'With father',
    'With children',
]

const sexualHistoryOptions = [
    'None',
    'Less than once a week',
    'Once a week',
    '3-5 a week',
    'More than 3-5 a week',
]

const caffeinIntakeOptions = [
    'Yes',
    'No',
    'Coffee',
    'Tea',
    'Soda',
    'Daily less than 2 servings',
    'Daily more than 2 servings',
    'Other',
]

const alcoholIntakeOptions = [
    'Less than 2 drinks',
    'More than 2 drinks',
    'Daily',
    'Weekly',
    'Socially only',
    'Rarely',
    'Drinks alone',
    'Other',
]

function SocialHistory(props) {
    const classes = useStyles()
    const [showcomment, setShowComment] = React.useState({
        maritalStatus: Boolean(props?.values?.marital_status?.comment),
        occupation: Boolean(props?.values?.occupation?.comment),
        livingArrangement: Boolean(props?.values?.living_arrangement?.comment),
        alcoholIntake: Boolean(props?.values?.alcohol_intake?.comment),
        sexualHistory: Boolean(props?.values?.sexual_history?.comment),
        patientDiet: Boolean(props?.values?.patient_diet),
        caffeinIntake: Boolean(props?.values?.caffeine_intake?.comment),
        otherSubstances: Boolean(props?.values?.other_substancces)
    })
    const [ openSlide, setOpendSlide] = useState(false)
    const onHandleChange = (key) =>{
        setShowComment(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    }

    const onCopyData = (event) => {
        // functionality...
        if(props.copyValues){
            setOpendSlide(true)
        }
    }
    const toggleDrawer = () =>{
        setOpendSlide(false)
    }
    const copyfromPrvious = (selectedComponent) => {
        const mapping = {
            marital_status: 'maritalStatus',
            occupation: 'occupation',
            living_arrangement: 'livingArrangement',
            alcohol_intake: 'alcoholIntake',
            patient_diet: 'patientDiet',
            caffeine_intake: 'caffeinIntake',
            other_substancces: 'otherSubstances',
            sexual_history: 'sexualHistory'
        };
        Object.keys(mapping).forEach(key => {
            if (selectedComponent.includes(key)) {
                const commentValue = props?.copyValues?.[key]
                props?.onChange(`${props?.fieldName}.${key}`, commentValue);
                setShowComment(prevState => ({
                    ...prevState,
                    [mapping[key]]: (key === 'patient_diet' || key === 'other_substancces') ?Boolean(props?.copyValues?.[key]) : Boolean(props?.copyValues?.[key].comment)
                }));
            }
        });
        const second_mapping =['sti_history','safe_sex_precautions','last_time_sti_testing']
        if(selectedComponent.includes('question_section')){
            second_mapping.map((value)=>{
                const commentValue = props?.copyValues?.[value]
                props?.onChange(`${props?.fieldName}.${value}`, commentValue);
            })
        }
        if(selectedComponent.includes('narrative_section')){
            props?.onChange(`${props?.fieldName}.narrtive`, props?.copyValues?.narrtive);
        }
        props?.onChange(`${props?.fieldName}.copiedDate`, moment(props.copyDate).format('MM/DD/YYYY') )
        setOpendSlide(false)
    };

    return (
        <div style={{marginBottom: '30px',marginTop: '30px'}}>
            <div className={classes.outsideContainer}>
                <div className={classes.header}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 15}}>
                        <Typography className={classes.headingName}>
                            Social History
                        </Typography>
                       {props?.values?.copiedDate && 
                            <Typography>
                                Copied from {props?.values?.copiedDate}
                            </Typography>
                        }
                    </div>
                    <div className={classes.copy} onClick={onCopyData}>
                        <IconButton>
                            <CopyIcon style={{ height: 14 }} />
                        </IconButton>

                        <Typography className={classes.copyText}>Copy: </Typography>

                        <Typography className={classes.copyText}>{props?.copyDate ? moment(props.copyDate).format('MM/DD/YYYY') : '' }</Typography>
                    </div>
                </div>

                <div className={classes.formContainer}>
                    <div className={classes.section}>
                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Marital Status
                                </FormLabel>
                                <Select
                                    name={`${props?.fieldName}.marital_status.selection`}
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className={classes.placeholder}>
                                                    Not chosen
                                                </Typography>
                                            )
                                        }
                                        return selected
                                    }}
                                    value={props?.values?.marital_status?.selection}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(name, value)
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                    }}
                                    className={classes.selectComponent}
                                    >
                                    {maritalStatusOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                    ...(props?.dropdownliststyles || {}),
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                            <div className={classes.commentSection} >
                                {!showcomment.maritalStatus ?
                                <>
                                    <img src={CommentIcon} alt="" />
                                    <span className={classes.addCommentText} onClick={()=>{onHandleChange('maritalStatus')}}>Add comment</span>
                                </>
                                :
                                <>
                                    <img src={DeleteCommentIcon} alt="" />
                                    <span className={classes.removeCommentText} onClick={()=>{onHandleChange('maritalStatus'),props?.onChange('social_history.marital_status.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                </>
                                }
                            </div>

                            <>
                            {showcomment.maritalStatus &&
                                <TextField
                                    name={`${props?.fieldName}.marital_status.comment`}
                                    variant="outlined"
                                    style={{ marginTop: 10 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '60px !important',
                                        },
                                        '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                            {
                                                fontSize: 14,
                                                color: '#989CA8 !important',
                                                fontWeight: 400,
                                                opacity: 1,
                                            },
                                    }}
                                    placeholder='Type comment for "Marital Status"'
                                    multiline
                                    maxRows={2}
                                    value={props?.values?.marital_status?.comment}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                /> 
                            }
                            </>
                        </div>

                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Occupation
                                </FormLabel>
                                <>
                                <div className={classes.commentSection} >
                                    {!showcomment.occupation ?
                                    <>
                                        <img src={CommentIcon} alt="" />
                                        <span className={classes.addCommentText} onClick={()=>{onHandleChange('occupation')}}>Add comment</span>
                                    </>
                                    :
                                    <>
                                        <img src={DeleteCommentIcon} alt="" />
                                        <span className={classes.removeCommentText} onClick={()=>{onHandleChange('occupation'),props?.onChange('social_history.occupation.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                    </>
                                    }
                                </div>
                                    {showcomment.occupation &&
                                        <TextField
                                            name={`${props?.fieldName}.occupation.comment`}
                                            variant="outlined"
                                            style={{ marginTop: 10 }}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: '60px !important',
                                                },
                                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                    {
                                                        fontSize: 14,
                                                        color: '#989CA8 !important',
                                                        fontWeight: 400,
                                                        opacity: 1,
                                                    },
                                            }}
                                            placeholder='Type comment for "Occupation"'
                                            multiline
                                            maxRows={2}
                                            value={props?.values?.occupation?.comment}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                                props?.onChange(name, value)
                                            }}
                                        />
                                    }
                                </>
                            </>
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Living Arrangement
                                </FormLabel>
                                <Select
                                    name={`${props?.fieldName}.living_arrangement.selection`}
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className={classes.placeholder}>
                                                    Not chosen
                                                </Typography>
                                            )
                                        }

                                        return selected
                                    }}
                                    value={props?.values?.living_arrangement?.selection}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                    className={classes.selectComponent}>
                                    {livingArrangementOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>

                            <>
                                <div className={classes.commentSection} >
                                    {!showcomment.livingArrangement ?
                                    <>
                                        <img src={CommentIcon} alt="" />
                                        <span className={classes.addCommentText} onClick={()=>{onHandleChange('livingArrangement')}}>Add comment</span>
                                    </>
                                    :
                                    <>
                                        <img src={DeleteCommentIcon} alt="" />
                                        <span className={classes.removeCommentText} onClick={()=>{onHandleChange('livingArrangement'),props?.onChange('social_history.living_arrangement.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                    </>
                                    }
                                </div>
                                {showcomment.livingArrangement &&
                                    <TextField
                                        name={`${props?.fieldName}.living_arrangement.comment`}
                                        variant="outlined"
                                        style={{ marginTop: 10 }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '60px !important',
                                            },
                                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                {
                                                    fontSize: 14,
                                                    color: '#989CA8 !important',
                                                    fontWeight: 400,
                                                    opacity: 1,
                                                },
                                        }}
                                        placeholder='Type comment for "Living Arrangement"'
                                        multiline
                                        maxRows={2}
                                        value={props?.values?.living_arrangement?.comment}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                            props?.onChange(name, value)
                                        }}
                                    />
                                 }
                            </>
                        </div>
                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Alcohol Intake
                                </FormLabel>
                                <Select
                                    name={`${props?.fieldName}.alcohol_intake.selection`}
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className={classes.placeholder}>
                                                    Not chosen
                                                </Typography>
                                            )
                                        }

                                        return selected
                                    }}
                                    value={props?.values?.alcohol_intake?.selection}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                    className={classes.selectComponent}>
                                    {alcoholIntakeOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                    ...(props?.dropdownliststyles || {}),
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>

                            <div className={classes.commentSection} >
                                {!showcomment.alcoholIntake ?
                                    <>
                                        <img src={CommentIcon} alt="" />
                                        <span className={classes.addCommentText} onClick={()=>{onHandleChange('alcoholIntake')}}>Add comment</span>
                                    </>
                                    :
                                    <>
                                        <img src={DeleteCommentIcon} alt="" />
                                        <span className={classes.removeCommentText} onClick={()=>{onHandleChange('alcoholIntake'),props?.onChange('social_history.alcohol_intake.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                    </>
                                }
                            </div>
                           {showcomment.alcoholIntake &&
                                <>
                                    <TextField
                                        name={`${props?.fieldName}.alcohol_intake.comment`}
                                        variant="outlined"
                                        style={{ marginTop: 10 }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '60px !important',
                                            },
                                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                {
                                                    fontSize: 14,
                                                    color: '#989CA8 !important',
                                                    fontWeight: 400,
                                                    opacity: 1,
                                                },
                                        }}
                                        placeholder='Type comment for "Alcohol Intake"'
                                        multiline
                                        maxRows={2}
                                        value={props?.values?.alcohol_intake?.comment}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                            props?.onChange(name, value)
                                        }}
                                    />
                                </>
                           }
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Sexual History
                                </FormLabel>
                                <Select
                                    name={`${props?.fieldName}.sexual_history.selection`}
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className={classes.placeholder}>
                                                   Not chosen
                                                </Typography>
                                            )
                                        }

                                        return selected
                                    }}
                                    value={props?.values?.sexual_history?.selection}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                    className={classes.selectComponent}>
                                    {sexualHistoryOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                    ...(props?.dropdownliststyles || {}),
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                            <div className={classes.commentSection} >
                                {!showcomment.sexualHistory ?
                                <>
                                    <img src={CommentIcon} alt="" />
                                    <span className={classes.addCommentText} onClick={()=>{onHandleChange('sexualHistory')}}>Add comment</span>
                                </>
                                :
                                <>
                                    <img src={DeleteCommentIcon} alt="" />
                                    <span className={classes.removeCommentText} onClick={()=>{onHandleChange('sexualHistory'),props?.onChange('social_history.sexual_history.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                </>
                                }
                            </div>
                            <>
                            {showcomment.sexualHistory &&
                                <TextField
                                    name={`${props?.fieldName}.sexual_history.comment`}
                                    variant="outlined"
                                    style={{ marginTop: 10 }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '60px !important',
                                        },
                                        '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                            {
                                                fontSize: 14,
                                                color: '#989CA8 !important',
                                                fontWeight: 400,
                                                opacity: 1,
                                            },
                                    }}
                                    placeholder='Type comment for "Sexual History"'
                                    multiline
                                    maxRows={2}
                                    value={props?.values?.sexual_history?.comment}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(name, value)
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                    }}
                                />
                            }
                            </>
                        </div>
                        <div style={{ width: '300px', gap: 10 }}>
                            <FormLabel component="p" className={classes.formLabel}>
                                Patient's Diet
                            </FormLabel>
                            <div className={classes.commentSection} >
                                {!showcomment.patientDiet ?
                                <>
                                    <img src={CommentIcon} alt="" />
                                    <span className={classes.addCommentText} onClick={()=>{onHandleChange('patientDiet')}}>Add comment</span>
                                </>
                                :
                                <>
                                    <img src={DeleteCommentIcon} alt="" />
                                    <span className={classes.removeCommentText} onClick={()=>{onHandleChange('patientDiet'),props?.onChange('social_history.patient_diet', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                </>
                                }
                            </div>
                            {showcomment.patientDiet &&
                                <TextField
                                    style={{ marginTop: 10 }}
                                    name={`${props?.fieldName}.patient_diet`}
                                    variant="outlined"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '60px !important',
                                        },
                                        '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                            fontSize: 14,
                                            color: '#989CA8 !important',
                                            fontWeight: 400,
                                            opacity: 1,
                                        },
                                    }}
                                    placeholder="Type comment for 'Patient's Diet'"
                                    multiline
                                    maxRows={2}
                                    value={props?.values?.patient_diet}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                />
                            }
                        </div>
                    </div>

                    {/* <span className={classes.horizontalDivider}></span> */}

                    <div className={classes.section}>
                        <div style={{ width: '300px', gap: 10 }}>
                            <>
                                <FormLabel component="p" className={classes.formLabel}>
                                    Caffeine Intake
                                </FormLabel>
                                <Select
                                    name={`${props?.fieldName}.caffeine_intake.selection`}
                                    variant="outlined"
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={(props) => <SelectDropDownIcon {...props} />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return (
                                                <Typography className={classes.placeholder}>
                                                    Not chosen
                                                </Typography>
                                            )
                                        }

                                        return selected
                                            
                                    }}
                                    value={props?.values?.caffeine_intake?.selection}
                                    onChange={(e) => {
                                        const { name, value } = e.target
                                        props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                        props?.onChange(name, value)
                                    }}
                                    className={classes.selectComponent}>
                                    {caffeinIntakeOptions?.map((item) => (
                                        <MenuItem key={item} value={item}>
                                            <Typography
                                                // variant="body2"
                                                noWrap
                                                style={{
                                                    color: '#667689',
                                                    fontSize: 14,
                                                    ...(props?.dropdownliststyles || {}),
                                                }}>
                                                {item}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>

                            <>
                                <div className={classes.commentSection} >
                                    {!showcomment.caffeinIntake ?
                                    <>
                                        <img src={CommentIcon} alt="" />
                                        <span className={classes.addCommentText} onClick={()=>{onHandleChange('caffeinIntake')}}>Add comment</span>
                                    </>
                                    :
                                    <>
                                        <img src={DeleteCommentIcon} alt="" />
                                        <span className={classes.removeCommentText} onClick={()=>{onHandleChange('caffeinIntake'),props?.onChange('social_history.caffeine_intake.comment', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                    </>
                                    }
                                </div>
                                {showcomment.caffeinIntake &&
                                    <TextField
                                        name={`${props?.fieldName}.caffeine_intake.comment`}
                                        variant="outlined"
                                        style={{ marginTop: 10 }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '60px !important',
                                            },
                                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                {
                                                    fontSize: 14,
                                                    color: '#989CA8 !important',
                                                    fontWeight: 400,
                                                    opacity: 1,
                                                },
                                        }}
                                        placeholder='Type comment for "Caffein Intake "'
                                        multiline
                                        maxRows={2}
                                        value={props?.values?.caffeine_intake?.comment}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                            props?.onChange(name, value)
                                        }}
                                    />
                                }
                            </>

                            <>  
                            <FormLabel component="p" className={classes.formLabel} style={{marginTop:"15px"}}>
                                Other Substances
                            </FormLabel>
                                <div className={classes.commentSection} >
                                    {!showcomment.otherSubstances ?
                                    <>
                                        <img src={CommentIcon} alt="" />
                                        <span className={classes.addCommentText} onClick={()=>{onHandleChange('otherSubstances')}}>Add comment</span>
                                    </>
                                    :
                                    <>
                                        <img src={DeleteCommentIcon} alt="" />
                                        <span className={classes.removeCommentText} onClick={()=>{onHandleChange('otherSubstances'),props?.onChange('social_history.other_substancces', ''),props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>Remove comment</span>
                                    </>
                                    }
                                </div>
                                {showcomment.otherSubstances &&
                                    <TextField
                                        name={`${props?.fieldName}.other_substancces`}
                                        variant="outlined"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '60px !important',
                                            },
                                            '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
                                                fontSize: 14,
                                                color: '#989CA8 !important',
                                                fontWeight: 400,
                                                opacity: 1,
                                            },
                                        }}
                                        placeholder='Type comment for "Other Substances"'
                                        multiline
                                        maxRows={2}
                                        value={props?.values?.other_substancces}
                                        onChange={(e) => {
                                            const { name, value } = e.target
                                            props?.onChange(`${props?.fieldName}.copiedDate`,'')
                                            props?.onChange(name, value)
                                        }}
                                        style={{marginTop:"10px"}}
                                    />
                                }
                            </>
                        </div>

                        <div style={{ width: '50%', display: 'flex' }}>
                            <div className={classes.questionsBox} onChange={()=>{props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>
                                <div className={classes.questionLine}>
                                    <Typography className={classes.question}>
                                        1. Do you have a history of an STI?
                                    </Typography>
                                    <span>
                                        <RadioGroup
                                            name={`${props?.fieldName}.sti_history`}
                                            row
                                            value={props?.values?.sti_history}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                props?.onChange(name, value)
                                            }}
                                            sx={{ gap: 2 }}>
                                            <FormControlLabel
                                                value={'yes'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={'no'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="No"
                                            />
                                        </RadioGroup>
                                    </span>
                                </div>

                                <div className={classes.questionLine}>
                                    <Typography className={classes.question}>
                                        2. Are you practicing safe sex precautions?
                                    </Typography>
                                    <span>
                                        <RadioGroup
                                            name={`${props?.fieldName}.safe_sex_precautions`}
                                            row
                                            value={props?.values?.safe_sex_precautions}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                props?.onChange(name, value)
                                            }}
                                            sx={{ gap: 2 }}>
                                            <FormControlLabel
                                                value={'yes'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={'no'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="No"
                                            />
                                        </RadioGroup>
                                    </span>
                                </div>

                                <div className={classes.questionLine}>
                                    <Typography className={classes.question}>
                                        3. When was the last time you had STI testing?
                                    </Typography>
                                    <span>
                                        {/* <RadioGroup
                                            name={`${props?.fieldName}.last_time_sti_testing`}
                                            row
                                            value={props?.values?.last_time_sti_testing}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                props?.onChange(name, value)
                                            }}
                                            sx={{ gap: 2 }}>
                                            <FormControlLabel
                                                value={'yes'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Yes"
                                            />
                                            <FormControlLabel
                                                value={'no'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            color: '#ADB5BD !important',
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 22,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#3f51b5 !important',
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="No"
                                            />
                                        </RadioGroup> */
                                        }
                                        <div style={{width: '153px'}}>
                                            <DatePicker
                                                name={`${props?.fieldName}.last_time_sti_testing`}
                                                value={props?.values?.last_time_sti_testing}
                                                onChange={(value) => {
                                                    props?.onChange('social_history.last_time_sti_testing', value)
                                                }}
                                                notDefaultValue
                                            />
                                        </div>
                                        {/* <TextField
                                            name={`${props?.fieldName}.last_time_sti_testing`}
                                            variant="outlined"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    height: '60px !important',
                                                },
                                                '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder':
                                                    {
                                                        fontSize: 14,
                                                        color: '#989CA8 !important',
                                                        fontWeight: 400,
                                                        opacity: 1,
                                                    },
                                            }}
                                            placeholder="Enter"
                                            multiline
                                            maxRows={1}
                                            value={props?.values?.last_time_sti_testing}
                                            onChange={(e) => {
                                                const { name, value } = e.target
                                                const alphanumericRegex = /^[a-zA-Z0-9\s]*$/
                                                if (alphanumericRegex.test(value) || value === '') {
                                                    if (value.length <= 28)
                                                        props?.onChange(social_history.caffeine_intake.comment, value)
                                                }
                                            }}
                                        /> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SocialHistoryCopy copyValue={props.copyValues} open={openSlide} toggleDrawer={toggleDrawer} copyDate={props.copyDate} copyfromPrvious={copyfromPrvious}/>
                </div>
            </div>
            <div className={classes.section} style={{ marginTop: 10 }} onChange={()=>{props?.onChange(`${props?.fieldName}.copiedDate`,'')}}>
                <TextWithMacrosNew
                    name={`${props?.fieldName}.narrtive`}
                    onChange={props?.onChange}
                    isDisabled={props?.nonEditable}
                    rows={12}
                    withoutMacro={false}
                    values={props?.values?.narrtive}
                    socialHistory={true}
                />
            </div>
        </div>
    )
}

export default SocialHistory
