import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { taxIdRegex } from 'utilities/regex'
import { ModalEl as Modal } from 'shared/elements'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { PhoneNumberInput, Tax } from 'shared/elements/FormItem/Input'
import { Footer } from 'shared/elements/FormItem/Footer'
import { useNotification } from 'shared/elements/Notification'
import { ErrorType } from 'containers/PatientsSetting/interfaces'

import { ClinicalPractice } from './interface'
import { INITIAL_DATA } from './seed'
import { getPracticeById } from 'services/Setting'
import { Loading } from 'shared/elements/Loading'

interface Props {
    loading?: boolean
    open?: boolean
    practice_id?: string
    onCancel?: () => void
    onSubmit?: (payload: ClinicalPractice) => void
}

export const ClinicalPracticeForm = ({ loading, ...props }: Props) => {
    const notification = useNotification()

    const [data, setData] = useState(INITIAL_DATA)
    const [dataLoading, setDataLoading] = useState(false)

    useEffect(() => {
        const abortController = new AbortController()

        if (props.practice_id) {
            getPracticeDetails(props.practice_id)
        }

        return () => abortController.abort()
    }, [props.practice_id])

    const validationSchema = yup.object({
        name: yup.string().required('Name is required!'),
        tax_id: yup
            .string()
            .matches(taxIdRegex, 'Please enter the correct format')
            .required('Tax id is required!'),
        practice_id: yup.string(),
        group_npi_number: yup.string().required('Group npi is required!'),
        patient_support_number: yup.string().required('Patient support number is required!'),
        fax_number: yup.string().required('Fax Number is required!'),
    })

    const getPracticeDetails = async (practice_id: string) => {
        try {
            setDataLoading(true)
            const response = await getPracticeById(practice_id)

            setData(response?.data || INITIAL_DATA)
        } catch (exception) {
            notification(
                (exception as ErrorType).data?.message || 'Cannot get Clinical Practice details',
                'error',
            )
        } finally {
            setDataLoading(false)
        }
    }

    const formik = useFormik({
        validationSchema,
        initialValues: data,
        enableReinitialize: true,
        onSubmit: (values) => props.onSubmit && props.onSubmit(values),
    })

    return (
        <Modal
            width={700}
            title={props.practice_id ? 'Update Clinical Practice' : 'Add New Clinical Practice'}
            description="Clinical Practice Details"
            open={props.open}
            onClose={props.onCancel}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container style={{ position: 'relative' }}>
                <Loading visible={dataLoading} />

                <Grid container item>
                    <Grid
                        container
                        item
                        spacing={3}
                        className="modal-spacer"
                        style={{ marginBottom: 10, marginTop: 10 }}>
                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Name"
                                    required
                                    name="name"
                                    value={formik.values.name}
                                    placeholder=" Enter practice name"
                                    onChange={(value) => formik.setFieldValue('name', value)}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid container item alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <Tax
                                        label="Tax ID"
                                        required
                                        name="tax_id"
                                        placeholder="Enter Tax ID"
                                        value={formik.values.tax_id}
                                        error={
                                            formik.touched.tax_id && Boolean(formik.errors.tax_id)
                                        }
                                        helperText={formik.touched.tax_id && formik.errors.tax_id}
                                        onChange={(value) =>
                                            formik.setFieldValue(
                                                'tax_id',
                                                value.toString().replace(/-/gi, ''),
                                            )
                                        }
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextBox
                                        label="Practice ID"
                                        name="practice_id"
                                        placeholder="Enter ID"
                                        value={formik.values.practice_id}
                                        onChange={(value) =>
                                            formik.setFieldValue('practice_id', value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Group NPI"
                                    required
                                    name="group_npi_number"
                                    value={formik.values.group_npi_number}
                                    placeholder="Enter NPI number"
                                    error={
                                        formik.touched.group_npi_number &&
                                        Boolean(formik.errors.group_npi_number)
                                    }
                                    helperText={
                                        formik.touched.group_npi_number &&
                                        formik.errors.group_npi_number
                                    }
                                    onChange={(value) =>
                                        formik.setFieldValue('group_npi_number', value)
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2} >
                            <Grid item xs={12}>
                                <PhoneNumberInput
                                    label="Patient Support Number"
                                    required
                                    name="patient_support_number"
                                    value={formik.values.patient_support_number}
                                    error={
                                        formik.touched.patient_support_number &&
                                        Boolean(formik.errors.patient_support_number)
                                    }
                                    helperText={
                                        formik.touched.patient_support_number &&
                                        formik.errors.patient_support_number
                                    }
                                    onChange={(value) =>
                                        formik.setFieldValue('patient_support_number', value)
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2} style={{ marginBottom: 16 }}>
                            <Grid item xs={12}>
                                <PhoneNumberInput
                                    label="Fax Number"
                                    required
                                    name="fax_number"
                                    value={formik.values.fax_number}
                                    error={
                                        formik.touched.fax_number &&
                                        Boolean(formik.errors.fax_number)
                                    }
                                    helperText={
                                        formik.touched.fax_number &&
                                        formik.errors.fax_number
                                    }
                                    onChange={(value) =>
                                        formik.setFieldValue('fax_number', value)
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Footer
                            loading={loading}
                            onCancel={props.onCancel}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
