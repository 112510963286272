import * as React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import Switch from '@mui/material/Switch'
import LoadingButton from '@mui/lab/LoadingButton'
import {
    getConfigureTemplate,
    updateConfigureTemplate,
} from '../api/services'
import {
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import LoadingPage from '../../../utilities/loading-page'

const useStyles = makeStyles(() => ({
    item_note: {
        borderBottom: '1px solid #e8e8e8',
        margin: '8px 0',
    },
    btn_save: {
        background: '#5571C6 !important',
        padding: '0px 32px !important',
        textTransform: 'unset !important',
    },
    btn_cancel: {
        marginRight: '16px !important',
        // padding: '0px 32px !important',
        textTransform: 'unset !important',
        color: 'white !important',
        width: 100,
        background: '#303E4E !important',
    },
    show_hide_text: {
        color: '#D9DDE3',
        textAlign: 'end',
        fontSize: 12,
    },
}))

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiPaper-root': {
        width: '800px !important',
    },
    '& .MuiDialogTitle-root': {
        paddingBottom: '0 !important',
    },
}))

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        position: 'absolute !important',
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#5571C6',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,.35)'
                : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}))

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle
            sx={{
                m: 0,
                p: 2,
                color: '#303E4E',
                fontSize: 22,
            }}
            {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute !important',
                        right: 8,
                        top: 8,
                        color: (theme) =>
                            theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

const Item = ({ header, isShow, onChange, require }) => {
    const classes = useStyles()
    return (
        <Grid
            container
            justifyContent="space-between"
            className={classes.item_note}>
            <Typography variant="body1">
                {header}
            </Typography>
            <AntSwitch
                disabled={require}
                onChange={onChange}
                defaultChecked
                checked={isShow}
                inputProps={{ 'aria-label': 'ant design' }}
            />
        </Grid>
    )
}

export default function TemplateNoteSettings({
    handleChangeTemplate,
    clinicalNoteId,
    clinicalName,
    categoryList,
    configureTemplateDetails
    // template = [],
}) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isUpdating, setIsUpdating] =
        React.useState(false)

    const [categories, setCategories] = React.useState([])
    const [dataConfigure, setDataConfigure] =
        React.useState({})

    // React.useEffect(() => {
    //     if (template.length > 0)
    //         setCategories(
    //             noteCategories.map((item) => {
    //                 const session = template.find(
    //                     (temp) =>
    //                         temp.componentId ===
    //                         item.componentId,
    //                 )
    //                 return session ? session : item
    //             }),
    //         )
    // }, [template, open])

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleSave = async () => {
        try {
            setIsUpdating(true)
            await updateConfigureTemplate(clinicalNoteId, {
                ...configureTemplateDetails,
                templateName: 'Data test configure template',
                components: categories,
            })
        } catch (err) {
            console.error("Error:", err);
        } finally {
            handleChangeTemplate(categories)
            setIsUpdating(false)
            handleClose()
        }
    }

    const handleToggleTemplate = (componentId) => {
        setCategories((prev) =>
            prev.map((item) =>
                item.componentId === componentId
                    ? { ...item, isShow: !item.isShow }
                    : item,
            ),
        )
    }

    React.useEffect(async () => {
        // fetchConfigure()
        if (
            configureTemplateDetails?.components
                .length > 0
        ) {
            setCategories(
                categoryList.map((item, i) => {
                    const session =
                        configureTemplateDetails?.components?.find(
                            (temp, index) =>
                                temp.componentId ===
                                item.componentId,
                        )
                    return session
                        ? {
                              ...item,
                              ...session,
                              header: item.header,
                          }
                        : item
                }) || [],
            )
        }
    }, [open])

    const fetchConfigure = async () => {
        try {
            setIsLoading(true)
            const clinicalNotesDetail =
                await getConfigureTemplate(clinicalNoteId)

            setDataConfigure(clinicalNotesDetail?.data)

            if (
                clinicalNotesDetail?.data?.components
                    .length > 0
            ) {
                setCategories(
                    categoryList.map((item, i) => {
                        const session =
                            clinicalNotesDetail?.data?.components?.find(
                                (temp, index) =>
                                    temp.componentId ===
                                    item.componentId,
                            )
                        return session
                            ? {
                                  ...item,
                                  ...session,
                                  header: item.header,
                              }
                            : item
                    }) || [],
                )
            }
            setIsLoading(false)
        } catch (err) {
            setIsLoading(false)
            console.log(err, 'ERROR')
        }
    }
    return (
        <div>
            {isLoading && open && <LoadingPage />}
            <IconButton
                variant="outlined"
                style={{
                    height: 30,
                    width: 30,
                    background: '#F5F6F7',
                }}
                onClick={handleClickOpen}>
                <SettingsOutlinedIcon
                    style={{
                        fontSize: 18,
                        color: '#303E4E',
                    }}
                />
            </IconButton>
            <BootstrapDialog
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}>
                    Custom {clinicalName}
                </BootstrapDialogTitle>
                <DialogContent
                    dividers
                    style={{ border: 'none' }}>
                    <Grid
                        container
                        justifyContent="space-between"
                        style={{ gap: 24 }}>
                        <Grid
                            item
                            style={{ flex: 1 }}
                            container
                            direction="column">
                            <Typography
                                className={
                                    classes.show_hide_text
                                }>
                                Show/Hide
                            </Typography>
                            {categories
                                .slice(
                                    0,
                                    categories.length / 2 +
                                        1,
                                )
                                .map((item) => (
                                    <Item
                                        onChange={() =>
                                            handleToggleTemplate(
                                                item.componentId,
                                            )
                                        }
                                        key={
                                            item.componentId
                                        }
                                        {...item}
                                    />
                                ))}
                        </Grid>
                        <Grid
                            item
                            style={{ flex: 1 }}
                            container
                            direction="column">
                            <Typography
                                className={
                                    classes.show_hide_text
                                }>
                                Show/Hide
                            </Typography>
                            <Grid container>
                                {categories
                                    .slice(
                                        categories.length /
                                            2 +
                                            1,
                                        categories.length,
                                    )
                                    .map((item) => (
                                        <Item
                                            onChange={() =>
                                                handleToggleTemplate(
                                                    item.componentId,
                                                )
                                            }
                                            key={
                                                item.componentId
                                            }
                                            {...item}
                                        />
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Box
                    sx={{
                        '& .MuiDialogActions-root': {
                            padding: '16px 20px',
                        },
                    }}>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            variant="text"
                            className={classes.btn_cancel}>
                            <Typography
                                style={{ color: 'white' }}>
                                Cancel
                            </Typography>
                        </Button>
                        {/* <Button
                        autoFocus
                        onClick={handleSave}
                        variant="contained"
                        color="primary">
                        Save changes
                    </Button> */}
                        <Box
                            sx={{
                                '& .Mui-disabled': {
                                    background:
                                        '#0000001f !important',
                                },
                            }}>
                            <LoadingButton
                                size="small"
                                onClick={handleSave}
                                className={classes.btn_save}
                                // endIcon={<SendIcon />}
                                loading={isUpdating}
                                loadingPosition="end"
                                variant="contained">
                                <Typography
                                    style={{
                                        color: 'white',
                                    }}>
                                    Save
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </DialogActions>
                </Box>
            </BootstrapDialog>
        </div>
    )
}
