import React, { useEffect, useReducer, useState } from 'react'
import {
    Grid,
    Select,
    MenuItem,
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Drawer,
    IconButton,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import Typography from '@material-ui/core/Typography'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'
import { ExpandMore } from '@material-ui/icons'
import TextWithMacrosNew from './TextWithMacrosNew'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import DescriptionIcon from '@mui/icons-material/Description'
import { useNotification } from 'shared/elements/Notification'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import copyNew from '../../../assests/icons/copyNew.svg'
import edit_icon from '../../../assests/icons/edit_icon.svg'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/material/styles'
import CheckboxOption from './CheckboxOptions'
import moment from 'moment'
import { ReactComponent as HistoryIcon } from 'assests/icons/History.svg'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const useStyles = makeStyles({
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    btn__macro: {
        color: '#667689',
    },
    box_type: {
        borderRadius: '0px 0px 5px 5px',
        borderRight: '1px solid #EAECF0',
        borderBottom: '1px solid #EAECF0',
        borderLeft: '1px solid #EAECF0',
        background: '#FFF',
        padding: '10px',
    },
    rootModal: {
        '& .MuiPaper-root': {
            minWidth: '1300px',
            // borderRadius: "5px",
            // height: '50%',
            // position: 'relative',
        },
        '& .MuiDialogContent-root': {
            // overflow: 'scroll',
            overflow: 'auto',
            padding: '0px',
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiDialogTitle-root': {
            padding: 0,
        },
    },
    headerModal: {
        color: '#303e4e',
        fontFamily: 'Rubik',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        padding: '7px 0px 7px 15px',
    },
    applyButton: {
        width: 'fit-content',
        // height: "25px",
        padding: '6px 12px',
        borderRadius: '5px',
        // border: "1px solid #128D47",
        background: '#5571C6',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 10px 0px 10px',
        color: '#fff',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        textTransform: 'none !important',
    },
    selectAllCheck: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            paddingRight: '0px !important',
        },
    },
})

const PreviousCNDrawer = ({
    open,
    handleClose,
    categoryName,
    handleChangecopy,
    lastUpdateDate,
    customCheckAllValue = null,
    customCheckAllHandler = () => {},
    applyButtonDisabled = false,
    noAllSelectionCheck = false,
    ...props
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            const a = option1.name.toUpperCase(),
                b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    return (
        <Drawer
            anchor="right"
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    minWidth: '1463px',
                },
            }}
            fullWidth={true}
            className={classes.rootModal}
            onClose={handleClose}>
            <DialogTitle>
                <Box
                    style={{
                        // height: '36px',
                        // borderRadius: '5px 5px 0px 0px',
                        borderBottom: '1px solid #e0e0e0',
                        // background: '#5571C6',
                        padding: 15,
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            <IconButton onClick={handleClose}>
                                <KeyboardBackspaceIcon style={{ color: '#303E4E' }} />
                            </IconButton>
                            <Typography className={classes.headerModal}>{categoryName}</Typography>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                cursor: 'pointer',
                            }}>
                            <HistoryIcon />
                            <Typography className={classes.copyText}>
                                Copy from:{' '}
                                <span style={{ color: '#5571C6' }}>
                                    {lastUpdateDate
                                        ? moment(lastUpdateDate).format('MM/DD/YYYY')
                                        : 'N/A'}
                                </span>
                            </Typography>
                        </div>

                        <div className={classes.selectAllCheck}>
                            {!noAllSelectionCheck && (
                                <CheckboxOption
                                    option={{
                                        value: false,
                                        label: 'Select All',
                                    }}
                                    checked={customCheckAllValue}
                                    size="small"
                                    onChange={(e) => customCheckAllHandler(e.target.checked)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            )}
                            <Typography
                                style={{
                                    pointerEvents: applyButtonDisabled ? 'none' : 'auto',
                                    opacity: applyButtonDisabled ? 0.5 : 1,
                                }}
                                className={classes.applyButton}
                                onClick={() => {
                                    handleChangecopy()
                                }}>
                                Apply to current notes
                            </Typography>
                        </div>
                    </div>
                </Box>
            </DialogTitle>
            <DialogContent>{props?.children}</DialogContent>
        </Drawer>
    )
}

export default PreviousCNDrawer
