import React, { useEffect } from 'react'
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'formik'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CheckboxOption from '../reusable/CheckboxOptions'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    subHeadingText: {
        fontSize: 14,
        marginBottom: 20,
    },
})

const ThearphyPlan = ({
    master,
    values,
    onChange,
    macros,
    read_only,
    fieldName,
    copyAble,
    generateAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()
    console.log('theraphy plan values', values)

    const handleCopy = async () => {
        if(nonEditable){return}
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()
            const keys = Object.keys(copyValues)
            if (!keys.length) return reject()
            const noValue = keys.every((key) => {
                if (Array.isArray(copyValues[key])) {
                    if (!copyValues[key].length) return true
                    return false
                } else if (typeof copyValues[key] === 'string') {
                    if (!copyValues[key].length) return true
                    return false
                }
                return false
            })
            if (!noValue) {
                onChange(fieldName, copyValues)
                resolve()
            } else {
                reject()
            }
        })
    }

    return (
        <Grid container justifyContent="space-between">
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            <Grid item xs={12}>
                <FieldArray
                    name="therapy_plan.therapy"
                    render={(helpers) => (
                        <FormGroup>
                            {master?.map((i, key) => (
                                <CheckboxOption
                                    key={key}
                                    name={i.name}
                                    option={{
                                        label: i.name,
                                        value: false,
                                    }}
                                    disabled={nonEditable}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            helpers.push({
                                                id: i._id,
                                                label: i.name,
                                            })
                                        } else {
                                            const idx = values?.therapy.findIndex(
                                                (item) => item.id === i._id,
                                            )
                                            helpers.remove(idx)
                                        }
                                    }}
                                    checked={values?.therapy.some((item) => item.id === i._id)}
                                />
                            ))}
                        </FormGroup>
                    )}
                />
            </Grid>

            <Grid item xs={12}>
                <NarrativeAndMacro
                    hideTextNarrative={!generateAble}
                    hideGenerateBtn={!generateAble}
                    hideClearBtn={true}
                    values={values}
                    fieldName={fieldName}
                    onChange={onChange}
                    macros={macros}
                    onGenerateClick={onChange}
                    meta={{
                        values,
                        master,
                    }}
                    nonEditable={nonEditable}
                />
            </Grid>
        </Grid>
    )
}

export default ThearphyPlan
