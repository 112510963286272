import React from 'react'
import {
    FormControlLabel,
    Checkbox,
    FormGroup,
} from '@mui/material'

interface Props {
    value?: string | number | boolean
    checked?: boolean
    disabled?: boolean
    onChange?: () => void
    label: string
}
export const CheckBoxLabel = (props: Props) => {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        {...props}
                        onChange={props.onChange}
                        size="small"
                        sx={{
                            color: '#959595 !important',
                            '&.Mui-checked': {
                                color: '#14AE5C !important',
                                '& .MuiSvgIcon-root': {},
                            },
                            '& .MuiSvgIcon-root': {
                                // color: '#5571c6',
                                width: '20px',
                                height: '20px',
                                padding: '5px',
                            },
                        }}
                    />
                }
                label={props.label}
            />
        </FormGroup>
    )
}
