const Routes = Object.freeze({
    //guest routes
    HOME: '/',
    LOGIN: {
        BASIC: '/login',
        ALTERNATE: '/loginAnotherWay',
        STAFF: '/login/staff',
    },

    SIGNUP: '/signup',
    REGISTER: '/register',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_PROFILE: '/verify-profile',
    FORGET_PASSWORD: '/forget-password',
    CHANGE_PASSWORD: '/change-password',
    MFA_SIGNIN: '/mfa-signin',

    CONSULTATION: {
        URL: `/video/:data`,
        // getURLById: (iv, data) => `/video/${data}`,
        VIDEO: '/video',
    },

    //auth routes

    APPOINTMENT: '/appointments',
    WORKLIST: '/worklist',
    APPOINTMENTS: {
        HOME: '/appointments',
        BOOK: '/appointments/book',
        SEARCH_PROVIDER: '/appointments/search-provider',
        PROVIDER_DETAIL: {
            URL: '/appointments/provider-details/:providerId',
            getURLById: (providerId) => `/appointments/provider-details/${providerId}`,
        },
        PROVIDER_LIST: '/appointments/provider-list',
    },
    CONSULTATION_TRACKING: '/consultation/tracking',
    CONSULTATION_STATUS: '/consultation/status',
    //Sidebar menu urls
    // DASHBOARD: '/dashboard',
    DASHBOARD: {
        MAIN: '/dashboard',
        BI_STUDIO: '/dashboard/bi-studio',
        REPORT_REPOSITORY: '/dashboard/report-repository',
    },
    SCHEDULE: '/schedule',
    USERS: '/users',
    CLINICAL_NOTES: {
        MAIN: '/work-queues',
        LISTING: '/work-queues/clinical-notes',
        NEW_NOTE: '/work-queues/clinical-note',
        VIEW_NOTE: '/work-queues/clinical-note/:id/:mode',
        VIEW_PREVIOUS_MEDICAL_NOTE: '/work-queues/medical-clinical-note/previous-note/:id',
        VIEW_PREVIOUS_NOTE: '/work-queues/previous-clinical-note/:id',
    },
    WORK_QUEUES: {
        MAIN: '/work-queues',
        CLINICAL_NOTES: {
            MAIN: '/work-queues',
            LISTING: '/work-queues/clinical-notes',
            NEW_NOTE: '/work-queues/clinical-note',
        },
        SUPERBILLS: {
            MAIN: '/superbills',
            CREATE: '/superbills/create',
            EDIT: '/superbills/:id/edit',
        },
        REFERRALS: {
            MAIN: '/referrals',
            CREATE: '/referrals/create',
            EDIT: '/referrals/edit/:id',
            INFORMATION: '/referrals/:idReferral/patient/:idPatient/:viewType/information',
            PATIENT_ACCOUNT: '/referrals/:idReferral/patient/:idPatient/:viewType/patient-account',
        },
        // FOLLOW_UP: {
        //     MAIN: '/follow-up',

        //     CREATE: "/follow-up/create"
        // },

        FOLLOW_UP: {
            MAIN: '/follow-up',
            RECALL: {
                CREATE: '/recall-create',
                EDIT: '/recall-edit/:id',
            },
            CHART_PREP: {
                ADD: '/chart-prep-add',
                EDIT: '/chart-prep-edit/:id',
            },
            WAITLIST: {
                CREATE: '/follow-up/waitlist/create',
                EDIT: '/follow-up/waitlist/edit/:id',
            },
        },
        WAITLIST: {
            MAIN: '/waitlist',
            CREATE: '/waitlist/create',
            EDIT: '/waitlist/edit/:id',
        },
    },
    // THERAPHY: '/theraphy',
    MESSSAGES: '/messages',
    DOCUMENTS: '/documents',
    BILLING: '/billing',
    LABS: '/labs',
    ERX: '/erx',
    ERX_MESSAGE: '/erx/message',
    ERX_PRESCRIPTION: '/erx/prescription',
    LABS_ORDER: {
        MAIN: '/labs',
    },
    EFax: {
        MAIN: '/efax',
        INCOMING: '/efax/incoming',
        OUTGOING: '/efax/outgoing',
        DETAILS: '/efax/view/:id',
        ARCHIVED: '/efax/archived',
        NEW_FAX: '/efax/new',
        FORWARD: '/efax/forward/:id',
    },
    IMAGING: '/imaging',
    REPORTS: '/reports',
    SCALES: '/scales',
    SETTINGS: {
        MAIN: '/settings',
        NEW_FORM_BUILDER: '/settings/form-builder',
        CLINICAL_PRACTICES: '/settings/clinical-practices',
        HEALTH_PLAN: '/settings/health-plan',
        LOCATIONS: '/settings/service-locations',
        VISIT_TYPES: '/settings/visit-types',
        APPOINTMENT_STATUS: '/settings/appointment-status',
        STATUS_CODES: '/settings/status-codes',
        USER_SECURITY: '/settings/user-security',
        USER_SECURITY_NEW: '/settings/user-security/new',
        USER_SECURITY_EDIT: '/settings/user-security/edit/:id',
        DEPARTMENTS: '/settings/departments',
        ROLES_MANAGEMENT: '/settings/roles-management',
        EDIT_PERMISSIONS: '/settings/edit-permission',
        MEDIA_KEYWORD: '/settings/media-keywords',
        SPECIALTIES: '/settings/specialties',
        MACRO: '/settings/macro',
        RESOURCE_MANAGEMENT: '/settings/resource-management',
        SECURITY_ROLE_PERMISSIONS: '/settings/security-role-permissions',
        REFERRING_PROVIDER: '/settings/referral-management',
        CUSTOM_PROC_CODES: '/settings/custom_proc_codes',
        BRANDING: '/settings/branding',
        BACKGROUND_NEW: '/back-ground/new',
        LOCATIONS_NEW: '/settings/service-locations/new',
        LOCATIONS_EDIT: '/settings/service-locations/edit/:id',
        PATIENT_OUTCOMES: '/settings/patient-outcomes',
        PATIENT_OUTCOMES_CREATE: '/settings/patient-outcomes/:status',
        REFERRAL_CHECKLIST: '/settings/referral-checklist',
        REFERRAL_CHECKLIST_NEW: '/settings/referral-checklist/new',
        REFERRAL_CHECKLIST_EDIT: '/settings/referral-checklist/edit/:id',
        SYSTEM_ANNOUNCEMENT: '/settings/system-announcements',
        SYSTEM_ANNOUNCEMENT_NEW: '/settings/system-announcements/new',
        SYSTEM_ANNOUNCEMENT_EDIT: '/settings/system-announcements/edit/:id'
    },
    SUPPORTS: '/supports',
    FAX: {
        LISTING: '/fax',
        DETAIL: '/fax/detail',
        CREATE: '/fax/create',
    },
    FORMS: '/forms',
    PATIENT: {
        MAIN: '/patient',
        CREATE: '/patient/create',
        EDIT: '/patient/edit/:id',
        DASHBOARD: '/patient/:id',
        DASHBOARD_PATIENT_HEALTH_RECORD: '/patient/:id/patient-record',
        DASHBOARD_PATIENT_ACCOUNT: '/patient/:id/patient-account',
        DASHBOARD_HISTORY: '/patient/:id/patient-history',
        DASHBOARD_ORDER: '/patient/:id/patient-order',
        MEDICAL_PROBLEMS: '/patient/:id/medical-problems',
        ALLERGIES: '/patient/:id/allergies',
        APPOINTMENTS: '/patient/:id/appointments',
        MEDICATIONS: '/patient/:id/medications',
        CLINICAL_NOTES: '/patient/:id/clinical-notes',
        VITAL_SIGNS: '/patient/:id/vital-signs',
        PATIENT_CONTACT: '/patient/:id/patient-contact',
        LAB_ORDERS: '/patient/:id/lab-orders',
        IMMUNIZATIONS: '/patient/:id/immunizations',
        INSURANCES: {
            MAIN: '/patient/:id/insurances',
            CREATE: '/patient/:id/insurances/create',
            EDIT: '/patient/:id/insurances/edit/:insuranceId',
        },
        DOCUMENTS: '/patient/:id/documents',
        PSYCHOTHERAPY: '/patient/:id/psychotherapy',
        SUBSTANCE_ABUSE_HISTORY: '/patient/:id/substance-abuse-history',
        SUBSTANCE_ABUSE_TREATMENT_HISTORY: '/patient/:id/substance-abuse-treatment-history',
        INPATIENT_PSYCHIATRIC_HISTORY: '/patient/:id/inpatient-psychiatric-history',
        OUTPATIENT_PSYCHIATRIC_HISTORY: '/patient/:id/outpatient-psychiatric-history',
        SURGICAL_HISTORY: '/patient/:id/surgical-history',
        FAMILY_PSYCHIATRIC_HISTORY: '/patient/:id/family-history',
        FAMILY_HISTORY_CREATE: '/patient/:id/family-history/create',
        FAMILY_HISTORY_UPDATE: '/patient/:id/family-history/update',
        CARE_TEAM: '/patient/:id/care-team',
        PSYCHIATRIC_MEDICAL_HISTORY: '/patient/:id/psychiatric-medication-history',
        SOCIAL_HISTORY: '/patient/:id/social-history',
        MENSTRUATION_AND_PRAGNANCY_HISTORY: '/patient/:id/menstruation-and-pregnancy-history',
        BEHAVIOR_RATE_SCALE: '/patient/:id/behavior-rate-scale',
        GAD_7_DETAILS: '/patient/:id/gad-7-details/behavior-rate-scale',
        PHQ_9_DETAILS: '/patient/:id/phq-9-details/behavior-rate-scale'
    },
    PATIENT_DETAIL: '/patient-detail',
    PATIENT_SURVEY: '/patient/survey-form',
    PATIENT_INTAKE: '/patient/intake',
    PATIENT_PROFILE_EDIT: '/patient/profile/edit',
    PROVIDER: '/provider',

    TM2UAdmin: '/tm2uadmin/profile',
    //profile
    PATIENT_PROFILE: '/patient/profile',
    PROFILE: '/profile',
    PAYMENTS: {
        RECIEPT: '/patient/payment',
    },
    TELE_HEALTH: {
        CONSULTATION: '/consultation',
    },
    PROVIDER_DIRECTORY: {
        MAIN: '/provider-directory',
        INFO: '/provider-directory/:id/info',
    },
})

export default Routes
