import React from 'react'
import { searchPatientAppointment } from 'services/Calendar'
import { SearchBox } from '../SearchBox'

interface ElasticSearchPatient {
    _index?: string
    _type?: string
    _id?: string
    _score?: number
    _source?: {
        id?: string
        firstName?: string
        lastName?: string
        middle_name?: string
        suffix?: string
        fullName?: string
        gender?: string
        birthDate?: string
        phoneNumber?: string
        address?: {
            use?: string
            type?: string
            text?: string
            city?: string
            state?: string
            postalCode?: string
            country?: string
        }
    }
}

type ResponseDTO = {
    id?: string
    fullName?: string
    highlightText?: string
    key?: React.Key
}

interface Props {
    label?: string
    required?: boolean
    name?: string
    value?: ResponseDTO
    placeholder?: string
    error?: boolean
    helperText?: string | boolean
    onChange?: (value: ResponseDTO) => void
}

function displayPatient(patient: ElasticSearchPatient) {
    const { _source } = patient

    return [_source?.fullName, _source?.phoneNumber].filter((item) => Boolean(item)).join(' - ')
}

const SearchPatient = ({ onChange, ...rest }: Props) => {
    return (
        <SearchBox<ResponseDTO>
            // value={{ id: value?.id, text: value?.text }}
            searchHandler={(text) =>
                searchPatientAppointment(text).then((response) => {
                    const data = response?.data?.hits?.hits as ElasticSearchPatient[]

                    return data.map((item) => ({
                        id: item._source?.id,
                        fullName: item._source?.fullName,
                        highlightText: displayPatient(item),
                    }))
                })
            }
            renderOption={(option) => option.highlightText}
            getOptionLabel={(option) => option.fullName || ''}
            renderKey="id"
            isOptionEqualToValue={(option, value) =>
                option?.id === value?.id || option?.fullName === value?.fullName
            }
            onChange={(option) =>
                onChange &&
                onChange({
                    id: option?.id,
                    fullName: option?.fullName || '',
                })
            }
            {...rest}
        />
    )
}

export { SearchPatient }
export default SearchPatient
