import {
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPaginationRowRangeSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ForwardIcon from '../../assests/icons/ForwardIcon'
import NextIcon from '../../assests/icons/NextIcon'
import { createStatus } from '../../services/Calendar/index'
import { ButtonIconEl } from '../../shared/elements'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
    Pagination,
    PaginationItem,
    Stack,
} from '@mui/material'
import EditIcon from '../../assests/icons/edit_icon.svg'
import HoverEditIcon from '../../assests/icons/hover_edit_icon.svg'
import { getStatus } from '../../services/Calendar'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles({
    container: {
        padding: 24,
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: '50%',
        // padding: '4px 16px',
        width: 16,
        height: 16,
        background: '#4E5B7D',
    },
    bgColor_draft: {
        background: '#4E5B7D',
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#14AE5C',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
})

const renderEditAction = (params, handleEditClick) => {
    const classes = useStyles()
    return (
        <Grid item>
            <HtmlTooltip
                title={'Edit'}
                arrow
                placement="top"
                style={{
                    background: 'white !important',
                    color: '#303E4E',
                }}>
                <div>
                    <ButtonIconEl
                        size="small"
                        onClick={() => {
                            handleEditClick(params)
                        }}
                        className={classes.act_iconbtn}
                        icon={
                            <img
                                src={EditIcon}
                                alt=""
                                style={{
                                    color: 'white',
                                }}></img>
                        }
                        iconHover={
                            <img
                                src={HoverEditIcon}
                                alt=""
                                style={{
                                    color: 'white',
                                }}></img>
                        }></ButtonIconEl>
                </div>
            </HtmlTooltip>
        </Grid>
    )
}

const renderActions = (params, actions) => {
    return (
        <Grid container alignItems="center" spacing={2}>
            {renderEditAction(
                params,
                actions.handleEditClick,
            )}
        </Grid>
    )
}

const CustomPagination = ({
    onPageChange,
    total,
    numberRecord,
}) => {
    const apiRef = useGridApiContext()
    const page = useGridSelector(apiRef, gridPageSelector)
    const pageCount = useGridSelector(
        apiRef,
        gridPageCountSelector,
    )
    const rowIndex = gridPaginationRowRangeSelector(apiRef)

    return (
        <Stack
            style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
            <Typography>
                Show{' '}
                <strong>
                    {rowIndex?.lastRowIndex + 1 || 0}{' '}
                    records
                </strong>{' '}
                of <strong>{total}</strong>
            </Typography>
            <Pagination
                color="primary"
                count={pageCount}
                page={page + 1}
                variant="outlined"
                shape="rounded"
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1)
                    onPageChange(value)
                }}
                renderItem={(item) => (
                    <PaginationItem
                        components={{
                            previous: ForwardIcon,
                            next: NextIcon,
                        }}
                        {...item}
                    />
                )}
            />
        </Stack>
    )
}

const EditPermissionSettings = () => {
    const classes = useStyles()
    const [page, setPage] = useState(1)
    const [isSubmitting, setIsSubmitting] =
        React.useState(false)
    const [appointmentModal, setAppointmentModal] =
        React.useState({
            status: false,
        })
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })
    const onPageChange = (value) => {
        setPage(value)
    }
    function statusMapper(status) {
        switch (status) {
            case 'pending':
                return 'Pending Approval'
            case 'draft':
                return 'Draft'
            case 'locked':
                return 'Locked'
            case 'resent_to_edit':
                return 'Resent to Edit'
            case 'approved_lock':
                return 'Approved & Locked'
            default:
                return ''
        }
    }

    async function onRequestTimeoff(values) {
        try {
            setIsSubmitting(true)
            // 	const start_date = moment(
            // 		`${moment(values.start_date).format(
            // 			DATE_FORMAT,
            // 		)} ${values.start_at.format('HH:mm:ss')}`,
            // 	)
            // 	const end_date = moment(
            // 		`${moment(values.end_date).format(
            // 			DATE_FORMAT,
            // 		)} ${values.end_at.format('HH:mm:ss')}`,
            // 	)

            // 	onTimeoffModalToggle()

            // 	await setTimeoff({
            // 		provider_id: state.provider.data.id,
            // 		start_date: start_date
            // 			.utc()
            // 			.format(DATE_FORMAT),
            // 		end_date: end_date
            // 			.utc()
            // 			.format(DATE_FORMAT),
            // 		start: start_date.utc().format('HH:mm'),
            // 		end: end_date.utc().format('HH:mm'),
            // 	})
            // 	Swal.fire({
            // 		icon: 'success',
            // 		title: '',
            // 		text: 'Your Request for Time Off has been submitted',
            // 	})
            // 	setIsSubmitting(false)
            // 	onNavigate(values.start_date, VIEW.WEEK)
            // } catch (error) {
            // 	console.log({ error })
            // 	if (error.status === 406) {
            // 		Swal.fire({
            // 			icon: 'error',
            // 			title: 'Oops...',
            // 			text: 'Already a request for the same Time Off duration exists. Please select a different date range.',
            // 		})
            // 	} else {
            // 		Swal.fire({
            // 			icon: 'error',
            // 			title: 'Oops...',
            // 			text: 'Your Request for Time Off was unsuccessful. Please try once again.',
            // 		})
            // 	}

            setIsSubmitting(false)
            // onTimeoffModalToggle()
        } catch (error) {
            console.log({ error })
            // if (error.status === 406) {
            // 	Swal.fire({
            // 		icon: 'error',
            // 		title: 'Oops...',
            // 		text: 'Already a request for the same Time Off duration exists. Please select a different date range.',
            // 	})
            // } else {
            // 	Swal.fire({
            // 		icon: 'error',
            // 		title: 'Oops...',
            // 		text: 'Your Request for Time Off was unsuccessful. Please try once again.',
            // 	})
            // }

            setIsSubmitting(false)
            // onTimeoffModalToggle()
        }
    }
    function appointmentModalToggle() {
        setAppointmentModal({
            status: !appointmentModal.status,
        })
    }

    const columns = [
        {
            headerName: (
                <Typography variant="body1">
                    Name <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value
                // return (
                //     value.charAt(0).toUpperCase() +
                //     value.slice(1)
                // )
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Code <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'code',
            flex: 1,
            sortable: false,
            renderCell: ({ value, row }) => {
                // return value
                //     ? moment
                //         .utc(`${value} ${row.time}`)
                //         .local()
                //         .format('MM/DD/YYYY')
                //     : '01 Jan 2022'
                return value
            },
        },

        {
            headerName: (
                <Typography variant="body1">
                    Color <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'color',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span
                        className={clsx({
                            [classes.status_box]: true,
                        })}
                        style={{
                            background: value,
                        }}></span>
                )
            },
        },
        {
            headerName: (
                <Typography variant="body1">
                    Description <KeyboardArrowDownIcon />
                </Typography>
            ),
            headerClassName: 'super-app-theme--header',
            field: 'description',
            // width: 100,
            flex: 2.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
            // renderActions(params, { handleEditClick }),
        },
    ]
    const handleEditClick = (params) => {
        const { row } = params
        const payload = {
            ...row,
        }
        // history.push(
        // 	Routes.CLINICAL_NOTES.NEW_NOTE,
        // 	payload,
        // )
    }

    async function handleCreateLocations(payload) {
        try {
            setIsSubmitting(true)
            createStatus(payload)
                .then(() => {
                    appointmentModalToggle()
                    setIsSubmitting(false)
                    refetchStatus()
                })
                .catch()
        } catch (error) {
            console.log({ error })

            setIsSubmitting(false)
        }
    }

    const refetchStatus = async () => {
        setLoading(true)
        try {
            const res = await getStatus(page)
            setLoading(false)
            setPatientList(res.data?.data || [])
            setPaginationData(res.data?.paging)
        } catch (e) {
            setLoading(false)
        }
    }

    useEffect(() => {
        refetchStatus()
    }, [page])

    useEffect(async () => {}, [page])

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent="space-between">
                    <Typography className={classes.header}>
                        Security Roles
                    </Typography>
                    {/* <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn_add}
                                    onClick={appointmentModalToggle}
                                    fullWidth>
                                    Search
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.btn_add}
                                    onClick={appointmentModalToggle}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
            {/* <Grid
                item
                xs={12}
                className={classes.form_list_container}>
                <Box
                    sx={{
                        '& .super-app-theme--header': {
                            border: 'none',
                            '& > .MuiDataGrid-columnSeparator':
                            {
                                visibility: 'hidden',
                            },
                            '& .MuiDataGrid-columnHeaderTitle':
                            {
                                '& .MuiTypography-body1':
                                {
                                    display: 'flex',
                                    alignItems:
                                        'center',
                                    color: '#303E4E',
                                    fontWeight: 500,
                                    fontSize:
                                        '14px',
                                },
                            },
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            zIndex: 1,
                        },
                        '& .MuiPaginationItem-previousNext':
                        {
                            padding: '0 22px',
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                        {
                            outline: 'none !important',
                        },
                        '& .MuiPaginationItem-root.Mui-selected':
                        {
                            borderRadius: '5px',
                            background: '#667689',
                            color: 'white',
                            border: 'none',
                        },
                        '& .MuiDataGrid-root': {
                            border: 'none',
                            height: 'calc(100vh - 184px)',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none !important',
                            color: '#667689',
                            // background: 'red'
                        },
                        '& .MuiDataGrid-root .MuiDataGrid-columnHeader':
                        {
                            border: 'none',
                            outline: 'none !important',
                        },

                        '& .MuiDataGrid-footerContainer': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-row': {
                            background: '#FAFAFA',
                            margin: '4px 0',
                            borderRadius: '8px',
                        },
                    }}>
                    <DataGrid
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) =>
                            setSortModel(newSortModel)
                        }
                        rows={patientList}
                        columns={columns}
                        pageSize={10}
                        // rowsPerPageOptions={[10]}
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={paginationData.total}
                        pagination
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl />
                            ),
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                onPageChange,
                                total: paginationData.total,
                            },
                        }}
                        disableColumnMenu={true}
                        initialState={{
                            pagination: {
                                total: paginationData.total,
                                numberRecord:
                                    patientList.length,
                            },
                        }}
                    />
                </Box>
            </Grid>
            <AppointmentStatusCreateDialog
                open={appointmentModal.status}
                loading={isSubmitting}
                onModalClose={appointmentModalToggle}
                onSubmit={handleCreateLocations}
            /> */}
        </Grid>
    )
}
const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(
    mapStateToProps,
    null,
)(EditPermissionSettings)
