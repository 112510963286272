import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { getMedicationSSOLink, getLabCHCLink } from 'services/Patient/patient'
import LoadingPage from 'utilities/loading-page'
import { Tabs } from '../shared/Tabs'

const OrderEntry = ({ patientId }) => {
    const [currentTab, setActiveTab] = React.useState(0)
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                height: 'calc(100% - 5px)',
                borderRadius: '5px',
                border: '1px solid #DEE7F0',
                background: '#FFF',
            }}>
            <Tabs
                current={currentTab}
                orientation={'vertical'}
                style={{
                    flex: 1,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    borderRadius: '5px',
                    border: '1px solid #C0C1C5',
                    background: '#FFF',
                    margin: '10px 10px 10px 0px',
                }}
                sx={{
                    '.MuiTabs-indicator': {
                        left: 0,
                    },
                }}
                tabs={[
                    {
                        label: 'Prescribe',
                        element: () => <Prescribe patientId={patientId} />,
                    },
                    {
                        label: 'Lab',
                        element: () => <Lab patientId={patientId} />,
                    },
                ]}
                onChange={(value) => setActiveTab(+value)}
            />
        </Box>
    )
}

export default OrderEntry

export const Prescribe = ({ patientId }) => {
    const [loading, setLoading] = React.useState(false)
    const [ssoLink, setSsoLink] = React.useState('')
    const [ssoLinkError, setSsoLinkError] = React.useState(null)

    const fetchErxSsoLink = async () => {
        setLoading(true)

        await getMedicationSSOLink(patientId)
            .then((res) => {
                res?.data?.ssoLink && setSsoLink(res?.data?.ssoLink)
            })
            .catch((err) => {
                setSsoLinkError(err?.data?.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    React.useEffect(() => {
        fetchErxSsoLink()
    }, [patientId])

    return (
        <>
            {loading ? (
                <LoadingPage />
            ) : ssoLink ? (
                <iframe
                    src={ssoLink}
                    title="description"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}></iframe>
            ) : (
                <Typography>
                    {ssoLinkError
                        ? ssoLinkError
                        : `User can only access this site with a Provider Account!`}
                </Typography>
            )}
        </>
    )
}

export const Lab = ({ patientId }) => {
    const [loading, setLoading] = React.useState(false)
    const [chcLink, setCHCLink] = React.useState('')
    const [chcLinkError, setCHCLinkError] = React.useState(null)

    const fetchErxSsoLink = async () => {
        setLoading(true)
        await getLabCHCLink(patientId)
            .then((res) => {
                res?.data && setCHCLink(res?.data)
            })
            .catch((err) => {
                setCHCLinkError(err.data?.message)
            })
        setLoading(false)
    }

    React.useEffect(() => {
        fetchErxSsoLink()
    }, [patientId])

    return (
        <>
            {loading ? (
                <LoadingPage />
            ) : chcLink ? (
                <iframe
                    src={chcLink}
                    title="description"
                    style={{
                        width: '100%',
                        height: '100%',
                    }}></iframe>
            ) : (
                <Typography>
                    {chcLinkError
                        ? chcLinkError
                        : `User can only access this site with a CHC Provider Account information!`}
                </Typography>
            )}
        </>
    )
}
