
import { InpatientPsychiatric } from 'containers/PatientsSetting/interfaces'
import { Pagination } from 'containers/WorkQueues/Superbills/interface'
import {
  getService,
  postService,
  deleteService,
  putService
} from 'utilities/request/restClient'

const baseUrl = process.env.REACT_APP_SONORA_V2_API_URL

type SearchCriteria = {
  page?: number
  size?: number
  asc?: string
  desc?: string
  patient_id?: string
  facility?: string
  voluntary?: string
  reason?: string
  age?: number
  outcome?: string
  comment?: string
}

export const inpatientPsychiatricHistoryApis = {
  getAll(patientId: string | number, params: SearchCriteria = {}): {
    data: InpatientPsychiatric[]
    paging: Pagination
  } {
    let url = `${baseUrl}/patient/${patientId}/inpatient-psychiatric`

    const queries = Object.keys(params)
      .map(field => `${field}=${params[field]}`)

    if (queries.length) url += `?${queries.join('&')}`

    return getService(url)
      .then(resp => ({
        data: resp.data.data,
        paging: resp.data.paging
      }))
  },
  getById(patientId: string | number, inpatientPsychiatricId: string | number): InpatientPsychiatric {
    return getService(
      `${baseUrl}/patient/${patientId}/inpatient-psychiatric/${inpatientPsychiatricId}`,
    )
      .then(resp => resp.data)
  },
  postInpatientPsychiatric(patientId: string | number, payload: InpatientPsychiatric) {
    return postService(`${baseUrl}/patient/${patientId}/inpatient-psychiatric`, payload)
  },
  putInpatientPsychiatric(patientId: string | number, inpatientPsychiatricId: string, payload: InpatientPsychiatric) {
    return putService(`${baseUrl}/patient/${patientId}/inpatient-psychiatric/${inpatientPsychiatricId}`, payload)
  },
  deleteInpatientPsychiatric(patientId: string | number, inpatientPsychiatricId: string | number) {
    return deleteService(`${baseUrl}/patient/${patientId}/inpatient-psychiatric/${inpatientPsychiatricId}`)
  },
}
