import { Button, Grid, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import clsx from 'clsx'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AddParticipantForm from './../AddParticipantForm/AddParticipantForm';
import {
    LocalAudioTrack,
    LocalVideoTrack,
    Participant,
    RemoteAudioTrack,
    RemoteVideoTrack
} from 'twilio-video'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting'
import useChatContext from './../../hooks/useChatContext/useChatContext';
import useParticipants from '../../hooks/useParticipants/useParticipants'
import useProviderContext from '../../hooks/useProviderContext/useProviderContext'
import usePublications from '../../hooks/usePublications/usePublications'
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant'
import useTrack from '../../hooks/useTrack/useTrack'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import image4 from '../../icons/image_28.png'
import image1 from '../../icons/image_29.png'
import image2 from '../../icons/image_30.png'
import VerticalVolumn from '../VerticalVolumn/VerticalVolumn'
import ChatWindow from '../ChatWindow/ChatWindow'
import VideoChat from '../../../TwilioVideoPatient/VideoChat'
import { useLocation } from 'react-router-dom'
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator'
import TeleDisableVideoUserIcon from '../../icons/TeleDisableVideoUserIcon'


const useStyles = makeStyles((theme: Theme) => ({
    container_main: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        height: '100%',
        alignItems: 'center',
    },
    identity: {
        background: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '0.1em 0.3em 0.1em 0',
        display: 'inline-flex',
        '& svg': {
            marginLeft: '0.3em',
        },
        marginRight: '0.4em',
        alignItems: 'center',
    },
    infoContainer: {
        position: 'absolute',
        zIndex: 2,
        height: '100%',
        width: '100%',
    },
    collapse__participants: {
        background: 'rgba(3, 12, 37, 0.75)',
        borderRadius: '18px',
        padding: 16,
        width: 280,
        position: 'relative'
    },
    buttons: {
        margin: '0px 6px',
        padding: '0px',
        background: 'rgba(3, 12, 37, 0.75)',
        width: '50px',
        height: '50px',
        borderRadius: '18px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'fit-content',
        '&:hover': {
            background: 'rgba(34,43,55,0.5) !important',
        },
    },
    collapse__buttons: {
        marginTop: 16,
        padding: '0px',
        background: 'rgba(3, 12, 37, 0.75)',
        width: '32px',
        height: '32px',
        borderRadius: '18px',
        display: 'flex',
        alignItems: 'center',
        minWidth: 'fit-content',
        '&:hover': {
            background: 'rgba(34,43,55,0.5) !important',
        },


    },
    text__overlay: {
        position: 'absolute',
        background: 'rgba(3, 12, 37, 0.5)',
        top: 0,
        width: '100%',
        height: '100%',
        left: 0,
        zIndex: 1,
    },
    right_side_container:{
        background: 'white', 
        boxShadow: '0px 5px 20px rgba(165, 172, 181, 0.15)',
        borderRadius: '16px',
        marginLeft: '24px',
        width: '350px', 
        overflow: 'hidden',
        transition: 'all 0.5s'
    },
    right_side_container_opened:{
        width: 0,
        marginLeft: 0,
    },
    image_participant: {
        width: '280px',
        position: 'relative',
        height: '170px',
        boxShadow: '0px 20px 80px rgba(166, 177, 190, 0.2)',
        borderRadius: '16px',
        margin: '12px 0',

        '&:first-child': {
            marginTop: '0px',
        },
        '&:last-child': {
            marginBottom: '0px',
        },
    },
    reconnectingContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(40, 42, 43, 0.75)',
        zIndex: 1,
    },
    fullWidth: {
        gridArea: '1 / 1 / 2 / 3',
        [theme.breakpoints.down('sm')]: {
            gridArea: '1 / 1 / 3 / 3',
        },
    },
    avatarContainer: {
        height: '100%',
        width: '100%',  
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#303E4E',
        overflow: 'hidden',
        borderRadius: 16,
        margin: 'auto',
        // backgroundImage: `url(${image1})`,
        backgroundSize: 'cover',
        zIndex: 1,
        '& svg': {
            transform: 'scale(2)',
        },
    },
    cover_participant: {
        position: 'absolute',
        right: 24,
        top: 0,
        flexDirection: 'column',
        width: 'fit-content',
        height: 'calc(100vh - 80px)',
        zIndex: 2
    },
    list_container: {
        flexDirection: 'column',
        // width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },

    avatar: {
        width: '40px',
        height: '40px',
        marginRight: 8,
        background: '#FFFFFF',
        border: '2px solid rgba(255, 255, 255, 0.5)',
        borderRadius: '80px',
        overflow: 'hidden',
        position: 'relative',
    },
    avatar__backward: {
        width: '40px',
        height: '40px',
        marginRight: 8,
        background: '#FFFFFF',
        border: '2px solid #00000080',
        borderRadius: '80px',
        overflow: 'hidden',
        position: 'relative',

    },
    avatar__forward: {
        position: 'absolute',
        top: 16,
        left: 48,
        width: '40px',
        height: '40px',
        marginRight: 8,
        background: '#FFFFFF',
        border: '2px solid rgba(255, 255, 255, 0.5)',
        borderRadius: '80px',
        overflow: 'hidden',
        zIndex: 2

    },
    BtnContainer: {
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        margin: '1rem',
        position: 'absolute',
        left: '1.5rem',
        bottom: '4px',
        zIndex: 100,
        alignItems:'flex-end',
        [theme.breakpoints.down('sm')]: {
            // margin: "auto",
            // marginBottom: "3rem",
            // position: "static",
            // display: "flex",
            // alignSelf: "center",
            // left:0
            display: 'none',
        },
    },
    nameContainer: {
        padding: '10px',
        background: '#030c25bf',
        borderRadius: 18,
        marginRight: 24,
        width: '200px',
        maxWidth: '300px',
        display: 'flex',
        alignItems: 'center',
    },
    name_participatn_item: {
        position: 'absolute',
        bottom: 10,
        left: 10,
        padding: '10px',
        background: '#030c25bf',
        borderRadius: 10,
        marginRight: 24,
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
    },
    textStyling: {
        fontSize: '19px',
        color: 'white',
        margin: 0,
        '&:hover': {
            color: 'white',
        },
    },
    circularProgressWrap: {
        position: 'absolute',
        right: '20%',
        top: '5%',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        padding: '1em',
        borderRadius: '10px',
        boxShadow: '1px 1px 1px #ccc',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            right: '0',
        },
    },
}))

interface MainParticipantInfoProps {
    participant: Participant
    children: React.ReactNode
    openAddParticipant:Boolean
    handleOpenAddParticipant: () => void;
}

export default function MainParticipantInfo({
    participant,
    children,
    openAddParticipant,
    handleOpenAddParticipant
}: MainParticipantInfoProps) {
    const classes = useStyles()
    const { room } = useVideoContext()
    let location = useLocation();
    const [isCollapseParticipant, setIsCollapseParticipant] = useState(false)
    
  const { connect, isChatWindowOpen } = useChatContext();

    const localParticipant = room!.localParticipant
    const isLocal = localParticipant === participant
    console.log({participant});
    const screenShareParticipant =
        useScreenShareParticipant()
    const isRemoteParticipantScreenSharing =
        screenShareParticipant &&
        screenShareParticipant !== localParticipant

    const {
        isAccessAdmit,
        upcoming,
        appointmentInfo,
        waitingList,
    } = useProviderContext()
    const publications = usePublications(participant)
    const videoPublication = publications.find((p) =>
        p.kind.includes('video'),
    )
    const screenSharePublication = publications.find((p) =>
        p.trackName.includes('screen'),
    )

    const videoTrack = useTrack(
        screenSharePublication || videoPublication,
    )
    const isVideoEnabled = Boolean(videoTrack)

    const audioPublication = publications.find(
        (p) => p.kind === 'audio',
    )
    const audioTrack = useTrack(audioPublication) as
        | LocalAudioTrack
        | RemoteAudioTrack
        | undefined

    const isVideoSwitchedOff = useIsTrackSwitchedOff(
        videoTrack as LocalVideoTrack | RemoteVideoTrack,
    )
    const isParticipantReconnecting =
        useParticipantIsReconnecting(participant)
    const participants = useParticipants()
    const [isParticpantExist, setIsParticipantExist] =
        useState(true)
    const [seconds, setSeconds] = useState(0)
    const [openRightSide, setOpenRightSide] = useState('')
    const [timer, setTimer] = useState(0)
    const [isActive, setIsActive] = useState(true)
    const getDurationInMinutes = (): number => {
        let startTime = moment(
            upcoming.Current.start,
            'HH:mm:ss a',
        )
        let endTime = moment(
            upcoming.Current.end,
            'HH:mm:ss a',
        )
        return moment(endTime).diff(
            moment(startTime),
            'minutes',
        )
    }

    const handleChangeRightSide = (val) => {
        setOpenRightSide(val)
    }
    const getAppDurationInMinutes = (): number => {
        let startTime = moment(
            appointmentInfo.start,
            'HH:mm:ss a',
        )
        let endTime = moment(
            appointmentInfo.end,
            'HH:mm:ss a',
        )
        return moment(endTime).diff(
            moment(startTime),
            'minutes',
        )
    }

    const formatTime = () => {
        const getSeconds = `0${timer % 60}`.slice(-2)
        const minutes: any = `${Math.floor(timer / 60)}`
        const getMinutes = `0${minutes % 60}`.slice(-2)
        const getHours = `0${Math.floor(
            timer / 3600,
        )}`.slice(-2)

        return `${getHours} : ${getMinutes} : ${getSeconds}`
    }
    useEffect(() => {
        let interval: any = null
        if (isActive) {
            interval = setInterval(() => {
                setTimer((timer) => timer + 1)
            }, 1000)
        } else if (!isActive && timer !== 0) {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [isActive, timer])
    useEffect(() => {
        if (waitingList[0]?.list.length > 0) {
            let admittedPatientList: any = []
            waitingList &&
                waitingList.map((pat: any) => {
                    pat.list.map((val: any) => {
                        if (val.is_admitted === true) {
                            admittedPatientList.push(val)
                        }
                    })
                })
            let isPatinetAdmitted =
                admittedPatientList &&
                    admittedPatientList.length > 0
                    ? true
                    : false
            if (isPatinetAdmitted) {
                setIsParticipantExist(false)
                setTimer(0)
            } else {
                setIsParticipantExist(true)
            }
        } else {
            setIsParticipantExist(true)
        }
    }, [waitingList])

    // useEffect(() => {
    //     location?.state?.token && connect(location?.state?.token)
    // },[location])

    // removing the random numbers from the name
    const participantIdentityWithoutNumbers =
        participant.identity.replace(/[0-9]/g, '')
    return (
        <Grid container style={{height: '100%', padding: 40, background: '#F5F6F7'}} wrap ="nowrap">
            <div
            data-cy-main-participant
            // data-cy-participant={participant.identity}
                className={clsx(classes.container_main)}>

            <div className={classes.BtnContainer}>
                {/* <NetworkQualityLevel
                    participant={localParticipant}
                /> */}
                <div className={classes.nameContainer}>
                    <div className={classes.avatar}>
                        <img src={image4} alt="alternative_video" />
                    </div>
                    <Typography className={classes.textStyling}>
                        {participantIdentityWithoutNumbers}
                        {isLocal}

                        Christopher
                        </Typography>
                        
                        {/* <AudioLevelIndicator audioTrack={audioTrack} />  */}
                    {!(
                        !upcoming ||
                        upcoming.Current == 'undefined'
                    ) ? (
                        <div>
                            {!isParticpantExist &&
                                `Call duration ${formatTime()}`}
                        </div>
                    ) : null}
                    {Object.keys(appointmentInfo).length !==
                    0 ? (
                        <div>
                            {`Call duration ${getAppDurationInMinutes()}:00`}
                            {!isParticpantExist &&
                                `Call duration ${formatTime()}`}
                        </div>
                    ) : null}
                </div>
                    <VerticalVolumn/>
            </div>


            {/* <Grid container className={classes.cover_participant} wrap="nowrap" alignItems="center">
                {isCollapseParticipant ? <Grid container className={classes.list_container} wrap="nowrap">
                    {[1, 2, 3, 4, 5, 6, 7].map(item => {
                        return <Grid container key={item} className={classes.image_participant}>
                            <img src={image2} alt="alternative_video" style={{borderRadius: 16}}/>
                            <div className={classes.name_participatn_item}>
                                <Typography style={{color: 'white'}}>
                                    Christopher
                                </Typography>
                            
                            </div>
                        </Grid>

                    })}
                </Grid> : <Grid container className={classes.collapse__participants} alignItems="center">
                    <div className={classes.avatar__backward}>
                        <img src={image4} alt="alternative_video" />
                            <Grid container alignItems="center" justifyContent="center" className={classes.text__overlay}>
                                <Typography style={{color: 'white', fontSize: 16}}>
                                    +6
                                </Typography>
                        </Grid>
                    </div>
                    <div className={classes.avatar__forward}>
                        <img src={image4} alt="alternative_video" />

                    </div>
                    <Typography className={classes.textStyling} style={{marginLeft: 32}}>
                        Christopher
                    </Typography>
                </Grid>}
                <Button className={classes.collapse__buttons} onClick={() => { setIsCollapseParticipant(!isCollapseParticipant) }}>
                    {!isCollapseParticipant ? <KeyboardArrowDownRoundedIcon style={{ color: 'white' }} /> :
                        <KeyboardArrowUpRoundedIcon style={{ color: 'white' }} />}
                </Button>
                </Grid> */}
                   

            {(!isVideoEnabled || isVideoSwitchedOff) && (
                    <div className={classes.avatarContainer} >
                        <TeleDisableVideoUserIcon/>
                </div> 
             )} 
            {isParticpantExist && isAccessAdmit && (
                <div
                    className={
                        classes.circularProgressWrap
                    }>
                    <CircularProgress />
                    <Typography
                        variant="body1"
                        style={{
                            color: '#000',
                            marginLeft: '10px',
                        }}>
                        Please wait while the other
                        participant connects.{' '}
                    </Typography>
                </div>
            )} 
            {isParticipantReconnecting && (
                <div
                    className={
                        classes.reconnectingContainer
                    }>
                    <Typography
                        variant="body1"
                        style={{ color: 'white' }}>
                        Reconnecting...
                    </Typography>
                </div>
            )} 
            {children}
        </div>

      
      <Grid container className={clsx({[classes.right_side_container]:true, [classes.right_side_container_opened]: !isChatWindowOpen })}>
      <ChatWindow />
           </Grid >
    <Grid container  className={clsx({[classes.right_side_container]:true, [classes.right_side_container_opened]: !openAddParticipant})}>
        
    <AddParticipantForm handleOpenAddParticipant={handleOpenAddParticipant} onClose={handleOpenAddParticipant}/>
    </Grid >

        </Grid>
       
    )
}
