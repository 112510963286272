import React, { useState } from 'react'
import { Grid, FormLabel } from '@material-ui/core'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import * as yup from 'yup'

import SearchProviderInput from 'components/custom/SearchProviderInput'
import { ModalEl as Modal } from 'shared/elements'
import { updateFaxList } from 'services/EFax'
import { Footer } from 'shared/elements/FormItem/Footer'

import './styles.scss'

function AssignToProviderModal(props) {
    const [loading, setLoading] = useState(false)

    const me = useSelector((state) => state.login.userData)

    const handleSubmit = async (values) => {
        const mappedItems = props.selectedItems.map(
            (item) => {
                return {
                    assigned_by: me['custom:user_id'],
                    assigned_to: values.provider,
                    id: item.id,
                    message_id: item.message_id,
                }
            },
        )
        try {
            setLoading(true)
            await updateFaxList(mappedItems)
            props.onSubmitSuccess()
        } finally {
            setLoading(false)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            provider: null,
            providerName: null,
        },
        validationSchema: yup.object({
            provider: yup.string().required(),
        }),
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={700}
            title={'Assign To Provider / Staff'}
            description={'Resouces Management'}
            open={props.open}
            onClose={() => {
                formik.setFieldValue('provider', null)
                formik.setFieldValue('providerName', null)
                props.onModalClose()
            }}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid
                container
                className="appointment-booking-container">
                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid
                            container
                            item
                            spacing={3}
                            className="modal-spacer"
                            style={{
                                marginTop: 24,
                                marginBottom: 16,
                            }}>
                            <Grid item xs={12}>
                                <FormLabel
                                    component="p"
                                    className="input-form-label">
                                    Provider{' '}
                                </FormLabel>

                                <SearchProviderInput
                                    value={
                                        formik.values
                                            .provider
                                    }
                                    handleInputChange={(
                                        e,
                                    ) => {
                                        formik.setFieldValue(
                                            'provider',
                                            e.target.value,
                                        )
                                    }}
                                    handleAddRow={(
                                        value,
                                    ) => {
                                        formik.setFieldValue(
                                            'provider',
                                            value?.id,
                                        )
                                        formik.setFieldValue(
                                            'providerName',
                                            value?.value,
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Footer
                            loading={loading}
                            okBtnText="Assign"
                            onCancel={() => {
                                formik.setFieldValue(
                                    'provider',
                                    null,
                                )
                                formik.setFieldValue(
                                    'providerName',
                                    null,
                                )
                                props.onModalClose()
                            }}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default AssignToProviderModal
