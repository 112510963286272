export const WORKLIST_LOADING =
    '[WORKLIST_TRACKING] WORKLIST_LOADING';
export const WORKLIST_SUCCESS =
    '[WORKLIST_TRACKING] WORKLIST_SUCCESS';
export const WORKLIST_FAILURE =
    '[WORKLIST_TRACKING] WORKLIST_FAILURE';
export const SEARCH_WORKLIST =
    '[WORKLIST_TRACKING] WORKLIST_SEARCH';
export const PAGE_CHANGE =
    '[WORKLIST_TRACKING] WORKLIST_PAGE_CHANGE';

export const WORKLIST_FILTER_LOADING =
    '[WORKLIST_TRACKING] WORKLIST_FILTER_LOADING';
export const WORKLIST_FILTER_SUCCESS =
    '[WORKLIST_TRACKING] WORKLIST_FILTER_SUCCESS';
export const WORKLIST_FILTER_FAILURE =
    '[WORKLIST_TRACKING] WORKLIST_FILTER_FAILURE';
export const WORKLIST_RESET_FILTER =
    '[WORKLIST_TRACKING] WORKLIST_RESET_FILTER';
export const WORKLIST_STATUS_FILTER =
    '[WORKLIST_TRACKING] WORKLIST_STATUS_FILTER';
export const WORKLIST_TYPE_FILTER =
    '[WORKLIST_TRACKING] WORKLIST_TYPE_FILTER';

export const WORKLIST_SEARCH =
    '[WORKLIST_TRACKING] WORKLIST_SEARCH';
export const WORKLIST_DATE_FILTER =
    '[WORKLIST_TRACKING] WORKLIST_DATE_FILTER';

export const WORKLIST_MODAL_CLOSE =
    '[WORKLIST_TRACKING] WORKLIST_MODAL_CLOSE';
export const WORKLIST_MODAL_OPEN =
    '[WORKLIST_TRACKING] WORKLIST_MODAL_OPEN';

export const WORKLIST_MARK_STATUS =
    '[WORKLIST_TRACKING] WORKLIST_MARK_STATUS';

export const WORKLIST_FILTER_SORT =
    '[WORKLIST_TRACKING] WORKLIST_FILTER_SORT';
