import React, { useEffect, useReducer, useState } from 'react'
import { Grid, Select, MenuItem } from '@material-ui/core'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'
import { ExpandMore } from '@material-ui/icons'
import TextWithMacro from '../reusable/TextWithMacro'
import { getMacroList } from '../../../store/actions/macros'
import { useSelector, useDispatch } from 'react-redux'
import DescriptionIcon from '@mui/icons-material/Description'
import { useNotification } from 'shared/elements/Notification'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles({
    btn__generate: {
        height: 30,
        textTransform: 'unset',
        fontWeight: 400,
        fontSize: 14,
        padding: '0 16px',
    },
    narrative: {
        fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    description: {
        // fontWeight: 500,
        fontSize: 16,
        color: '#303E4E',
    },
    menu__item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    actionGrid: {
        marginBottom: 6,
    },
    btn__macro: {
        color: '#667689',
        width: '289px'
    },
    CentralizedMacrosText:{
        color: "#667689",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
        margin: "10px 7px 7px 7px"
    },
})

const PresentIllnessHistory = ({
    last_HPI = '',
    hideGenerateBtn,
    description,
    onChange,
    fieldName,
    onGenerateClick,
    meta,
    values,
    placeholder = '',
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [selectedMacro, setSelectedMacro] = useState('')
    const notification = useNotification()
    const macrosData = useSelector((state) => state.macros.macroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const macrosPractitionerData = useSelector((state) => state.macros.practitionerMacroList || []).sort(
        (option1, option2) => {
            let a = option1.name.toUpperCase()
            let b = option2.name.toUpperCase()
            return a === b ? 0 : a > b ? 1 : -1
        },
    )
    const allMacros =[...macrosData,...macrosPractitionerData]

    useEffect(() => {
        if (macrosData.length === 1) {
            dispatch(getMacroList())
        }
    }, [])

    const handleGenerateClick = () => {
        const result = narratives[fieldName]?.(meta)
        onGenerateClick(`${fieldName}.narrative`, `${result} \n`)
    }

    function myFunction() {
        const text = typeof last_HPI === 'string' ? last_HPI : last_HPI?.narrative
        navigator.clipboard.writeText(text)
        if (text) { handleSelectMacro(text) }
        notification('Copied!', 'success')
    }

    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }
    return (
        <Grid item>
            <Grid
                className={classes.actionGrid}
                container
                wrap="nowrap"
                justifyContent="space-between"
                direction="column">
                <HtmlTooltip title={'Copy from Previous Note'} arrow placement="top">
                    <Grid
                        item
                        style={{
                            cursor: 'pointer',
                            marginBottom: 16,
                            borderRadius: 4,
                            width: 'fit-content',
                            padding: 8,
                            background: 'rgb(211, 222, 243)',
                        }}
                        onClick={() => {
                            myFunction()
                        }}>
                        <Grid container alignItems="center" wrap="nowrap">
                            <DescriptionIcon style={{ color: '#5571c6', marginRight: 8 }} />
                            <Typography className={classes.description}>
                                {typeof last_HPI === 'string' ? last_HPI : last_HPI?.narrative}
                            </Typography>
                        </Grid>
                    </Grid>
                </HtmlTooltip>
                <Grid container justifyContent="space-between" alignItems="cetner">
                    <Typography className={classes.narrative}>Narrative</Typography>

                    <Grid item>
                        <Grid container alignItems="center">
                            <Box
                                sx={{
                                    '& .MuiSelect-selectMenu': {
                                        background: '#F3F6F9',
                                        padding: '7px 30px 7px 16px',
                                        borderRadius: '6px',
                                    },
                                    '& .MuiInput-root': {
                                        marginRight: '8px',
                                    },
                                }}>
                                <Select
                                    disableUnderline
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                        PaperProps: {
                                            style: {
                                                maxHeight: 505,
                                                maxWidth: 300,
                                                marginTop: 8,
                                            },
                                        },
                                    }}
                                    IconComponent={ExpandMore}
                                    name="macro"
                                    onChange={(e) => {
                                        setSelectedMacro(e.target.value)
                                    }}
                                    value={selectedMacro}
                                    displayEmpty
                                    renderValue={(sel) =>
                                        sel ? (
                                            <span className={classes.btn__macro}>
                                                Insert Macro:{' '}
                                                {allMacros.find((item) => item.name === sel)?.name}
                                            </span>
                                        ) : (
                                            <span className={classes.btn__macro}>Insert Macro</span>
                                        )
                                    }>
                                    <Typography className={classes.CentralizedMacrosText}>Personalized Macros</Typography>
                                        {macrosPractitionerData?.map((m) => (
                                            <MenuItem
                                                onClick={() => {handleSelectMacro(m.description)}}
                                                className={classes.menu__item}
                                                key={m.id}
                                                value={m.name}
                                                >
                                                <Typography className={classes.btn__macro}>
                                                    {m.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    <Typography className={classes.CentralizedMacrosText} style={{borderTop: "1px solid #DEE2E6",paddingTop: "12px"}}>Centralized Macros</Typography>
                                        {macrosData?.map((m) => (
                                            <MenuItem
                                                onClick={() => handleSelectMacro(m.description)}
                                                className={classes.menu__item}
                                                key={m.id}
                                                value={m.name}>
                                                <Typography className={classes.btn__macro}>
                                                    {m.name}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Box>

                            {!hideGenerateBtn && (
                                <Button
                                    onClick={() => {
                                        handleGenerateClick()
                                    }}
                                    className={classes.btn__generate}
                                    variant="contained">
                                    Generate
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TextWithMacro
                name={`${fieldName}.narrative`}
                onChange={onChange}
                // macros={macros}
                rows={12}
                values={values?.narrative}
                placeholder={placeholder}
            />
        </Grid>
    )
}

export default PresentIllnessHistory
