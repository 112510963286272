import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxOption from '../reusable/CheckboxOptions'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyPreviousNote from 'components/shared/Elements/CopyPreviousNote'

const useStyles = makeStyles({
    questionLabel: {
        fontSize: 12,
        marginBottom: 15,
        color: '#9CA9B8',
    },
})

const mapOptions = (masterData = []) => {
    return masterData.map((a) => ({
        label: a.name,
        value: a._id,
    }))
}

const SuicideProtectiveFactor = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    initialValue,
    generateAble,
    copyAble,
    copyValues,
    nonEditable
}) => {
    const classes = useStyles()

    const options = mapOptions(master?.suicideProtectiveMasters)

    const handleInputChange = (e, option) => {
        const { name, value, checked, type } = e.target

        let updatedValue = values[name] ? [...values[name]] : []

        if (type === 'checkbox') {
            updatedValue = checked
                ? [...updatedValue, { id: value, label: option.label }]
                : updatedValue.filter((uv) => uv.id !== value)
        }

        if (type === 'text') {
            updatedValue = updatedValue.map((uv) =>
                uv.id === option._id ? { ...uv, desc: value } : uv,
            )
        }
        onChange(`${fieldName}.${name}`, updatedValue)
    }

    const handleCopy = async () => {
        if (nonEditable) { return }
        return new Promise((resolve, reject) => {
            if (!copyValues) return reject()

            const found_protective_factors= []
            const protective_factors = copyValues?.protective_factors?.length ? copyValues?.protective_factors : []
            for (const element of protective_factors) {
                if (typeof element === 'object' && element !== null) {
                    found_protective_factors.push(element);
                }
            }

            const finalValues = {
                narrative: `${copyValues?.narrative ? copyValues?.narrative : ""} ${values?.narrative ? values?.narrative : ""}`,
                protective_factors: [...found_protective_factors, ...(values?.protective_factors || [])],
            }
            if (!found_protective_factors.length  && copyValues?.narrative === "") {
                reject()
            } else {
                onChange(fieldName, finalValues)
                resolve()
            }
        })
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 5,
            }}>
            {copyAble && !nonEditable && (
                <CopyPreviousNote content={copyValues?.narrative} handleCopy={handleCopy} />
            )}
            <Typography className={classes.questionLabel}>
                {questions.protective_factors}:
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {options.map((option) => {
                    const isSelected = values['protective_factors'].some(
                        (item) => item.id === option.value,
                    )
                    return (
                        <CheckboxOption
                            disabled={nonEditable}
                            key={option.value}
                            name="protective_factors"
                            option={option}
                            onChange={(e) => handleInputChange(e, option)}
                            checked={isSelected ? true : false}
                        />
                    )
                })}
            </Box>
            <NarrativeAndMacro
                hideGenerateBtn={!generateAble}
                hideTextNarrative={!generateAble}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                    master: options,
                }}
                nonEditable={nonEditable}
            />
        </Box>
    )
}

export default SuicideProtectiveFactor
