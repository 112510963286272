export const NOTE_TYPE = {
    NON_BEHAVIORAL: 'non-behavioral',
    BEHAVIORAL: 'behavioral',
    THEARPHY: 'therapy',
}

export const ICD_CODE_STATUS = {
    ACTIVE: 'Active',
    RESOLVED: 'Resolved',
}

export const NOTE_STATUS = {
    PENDING: 'pending',
    DRAFT: 'draft',
    LOCKED: 'locked',
    APPROVED_LOCK: 'approved_lock',
    RESENT_TO_EDIT: 'resent_to_edit',
    UNDER_APPROVAL: 'under_approval',
    REVISION_REQUESTED: 'revision_requested',
}

export const PROVIDER_TYPE = {
    SENIOR: 'senior',
    JUNIOR: 'junior',
}

/**
 * Prefer this approach STATUSES.draft over STATUSES.DRAFT because it is easier to read.
 * You keep the name of the object in uppercase indicating that this is a constant and you name the properties 
 * in camelCase improving readability and consistency
 * 
 * Note: do not use Status as name for the object because we use typescript and it will conflict with the Status type
*/

export const STATUSES = {
    draft: 'DRAFT',
    resendEdit: 'RESEND_EDIT',
    inProgress: 'IN_PROGRESS',
}
