import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import React, { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import { Pagination } from 'shared/elements/Pagination'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { ButtonIconEl, NoRowsOverlayEl } from 'shared/elements'
import { apiFollowUp, apiStatusCodes, getSpecialty, getVisitType } from 'services/Calendar'
import Routes from 'constants/routes'
import { Box, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useStyles } from '../styles'
import moment from 'moment'
import { formatDateTime } from 'utilities/formats'
import { Tab, Tabs, makeStyles, withStyles } from '@material-ui/core'
import { Button as ButtonCustom } from 'components/custom'
import AddIcon from '@mui/icons-material/Add'
import { IconButton, InputAdornment, Paper, TextField } from '@material-ui/core'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import {
    _getClinicalNotesFilterPatient,
    _getClinicalNotesFilterProvider,
} from 'containers/ClinicalNotes/api/services'
import { useNotification } from 'shared/elements/Notification'
import { useDebounce } from 'shared/hooks'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import format from 'date-fns/format'
import { endDateFilterToUTC, formatNumber, startDateFilterToUTC } from 'shared/utils'
import clsx from 'clsx'
import EditIcon from '../../../../assests/icons/edit_icon.svg'
import HoverEditIcon from '../../../../assests/icons/hover_edit_icon.svg'
import { getPhoneNumberList } from 'services/Patient/patient'
const UserStyles = makeStyles((theme) => ({
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
        color: '#000000',
    },
    status_box_inprogress: {
        borderRadius: 5,
        // paddingTop: '4px',
        padding: '4px 1px',
        background: '#E9ECF1',
        color: '#000000',
    },
    bgColor_in_progress: {
        background: '#D8D1FF',
        color: '#7340DF',
    },
    bgColor_hold: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_completed: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
}))

const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)
function ChartPrepList() {
    const notification = useNotification()
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [chartData, setChartData] = useState([])
    const [isLoading, setisLoading] = useState(false)
    const [visitType, setVisitType] = useState([])
    const [speciality, setSpeciality] = useState([])
    const history = useHistory()
    const classes = useStyles()
    const definedStyles = UserStyles()
    const [activeRange, setActiveRange] = useState(false)
    const [subValue, setSubValue] = useState("in_progress,hold")
    const [mounted, setMounted] = useState(false)
    const [patientData, setPatientData] = useState([])
    const [listProvider, setListProvider] = useState([])
    const [listVisitType, setListVisitType] = useState([])
    const [currentPageProvider, setCurrentPageProvider] = useState(1)
    const [totalPageNoOfProvider, setTotalPageNoOfProvider] = useState(null)
    const [loadingProvider, setLoadingProvider] = useState(false)
    const [loadingSpecialty, setLoadingSpecialty] = useState(false)
    const [loadingVisitType, setLoadingVisitType] = useState(false)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    const [currentPage, setCurrentPage] = useState(null)
    const [bundleId, setBundleId] = useState(null)
    const [loadingPatientOption, setLoadingPatientOption] = useState(false)
    const status = [
        { key: 'completed', value: 'Completed' },
        { key: 'hold', value: 'Hold' },
        { key: 'in_progress', value: 'In Progress' },
    ]
    const [SingleDate, setSingleDate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [loadingPhoneNumberOption, setLoadingPhoneNumberOption] = useState(false)
    const [phnData, setPhnData] = useState([])
    const [currentPagePhNo, setCurrentPagePhNo] = useState(1)
    const [totalPageNoOfPhNo, setTotalPageNoOfPhNo] = useState(null)
    const [listPatientStatus, setListPatientStatus] = useState([])
    const [searchQueries, setSearchQueries] = useState({
        patient_id: null,
        specialty_id: null,
        provider_id: null,
        visit_id: null,
        chart_status: null,
        patient_status: null,
        phoneNumber: null,
    })
    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        patient_id: false,
        specialty_id: false,
        provider_id: false,
        visit_id: false,
        chart_status: false,
        patient_status: false,
        phoneNumber: false,
    })
    const [scrollPosition, setScrollPosition] = useState({
        patient_id: null,
        specialty_id: null,
        provider_id: null,
        visit_id: null,
        chart_status: null,
        patient_status: null,
        phoneNumber: null,
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient_id: '',
        provider_id: '',
        phoneNumber: '',
    })
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient_id, 500)
    const debouncedProvider = useDebounce(searchTextOffilter.provider_id, 500)
    const debouncedPhNo = useDebounce(searchTextOffilter.phoneNumber, 500)

    useEffect(() => {
        getPatientlist()
    }, [debouncedSearchFilterPatientText])
    useEffect(() => {
        handleLoadDataProvider()
    }, [debouncedProvider])
    useEffect(() => {
        listSpecialty();
        getListVisitType();
        getListPatientStatus()
    }, [])
    useEffect(() => {
        if (searchTextOffilter.phoneNumber.length > 2) {
            getPhoneNumberListForFilter()
        } else if (searchTextOffilter.phoneNumber.length <= 2) {
            setPhnData([])
        }
    }, [debouncedPhNo])

    const listSpecialty = async () => {
        try {
            setLoadingSpecialty(true)
            const {
                data: { data },
            } = await getSpecialty({ size: 100000 })
            setSpeciality(
                () => {
                    const list = data.map((el) => ({
                        key: el.id,
                        value: el.specialty,
                    }))
                    return list
                })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        } finally {
            setLoadingSpecialty(false)
        }
    }
    const getListVisitType = async () => {
        try {
            setLoadingVisitType(true)
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType({ page: 1, limit: 100000 })
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                }))
                // list.unshift({ key: -1, value: 'All' })
                return list
            })
        } catch (error) {
            notification(
                error?.data?.message || error?.message || 'Cannot get list Visit Type!',
                'error',
            )
        } finally {
            setLoadingVisitType(false)
        }
    }

    const getPatientlist = async (name = '') => {
        try {
            setLoadingPatientOption(true)
            let payload = {
                page: 1,
                size: 20,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            setPatientData(tempUserList)
            setCurrentPage(1)
            setBundleId(res?.data?.bundle_id)
            const b = res?.data?.paging?.total / 20

            const remainder = res?.data?.paging?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPatient(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPatientOption(false)
        }
    }
    const handleChangePage = async (name) => {
        if (totalPageNoOfPatient > currentPage) {
            const nextPage = currentPage + 1
            let payload = {
                page: nextPage,
                size: 20,
                bundle_id: bundleId,
                name: searchTextOffilter.patient_id,
                search: searchTextOffilter.patient_id,
                query: searchTextOffilter.patient_id,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${item.last_name
                        }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            let sortedPatientData = [...patientData, ...tempUserList]
            setPatientData(sortedPatientData)
            setCurrentPage(nextPage)
            setBundleId(res?.data?.bundle_id)
        }
    }
    const handleLoadDataProvider = async (name = '') => {
        try {
            setLoadingProvider(true)
            let payload = {
                page: 1,
                limit: 20,
                name: searchTextOffilter.provider_id,
            }
            const res = await _getClinicalNotesFilterProvider(payload)
            let tempUserList = []
            res?.data?.data?.data?.map((item) =>
                tempUserList.push({ key: item?.id, value: item?.name }),
            )
            setListProvider(tempUserList)
            setCurrentPageProvider(1)
            const b = res?.data?.data?.total / 20
            const remainder = res?.data?.data?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = Math.floor(b)
            }
            setTotalPageNoOfProvider(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingProvider(false)
        }
    }

    const handleScrollProvider = async () => {
        if (totalPageNoOfProvider > currentPageProvider) {
            try {
                const nextPage = currentPageProvider + 1
                let payload = {
                    page: nextPage,
                    limit: 20,
                    name: searchTextOffilter.provider_id,
                }
                const res = await _getClinicalNotesFilterProvider(payload)
                let tempUserList = []
                res?.data?.data?.data?.map((item) =>
                    tempUserList.push({ key: item?.id, value: item?.name }),
                )
                let sortedProviderData = [...listProvider, ...tempUserList]
                setListProvider(sortedProviderData)
                setCurrentPageProvider(nextPage)
            } catch (e) {
                notification('An error occurred, please try again!', 'error')
            }
        }
    }
    const getPhoneNumberListForFilter = async () => {
        try {
            setLoadingPhoneNumberOption(true)
            let payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: 0,
            }
            const res = await getPhoneNumberList(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            setPhnData(tempUserList)
            const b = res?.data?.total / 10

            const remainder = res?.data?.total % 10
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPhNo(result)
            if (result > 0) {
                setCurrentPagePhNo(1)
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPhoneNumberOption(false)
        }
    }
    const handleChangePagePhoneNumber = async () => {
        if (totalPageNoOfPhNo > currentPagePhNo) {
            const nextPage = currentPagePhNo + 1
            let payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: currentPagePhNo,
            }
            const res = await getPhoneNumberList(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            let sortedPhoneNumberData = [...phnData, ...tempUserList]
            setPhnData(sortedPhoneNumberData)
            setCurrentPagePhNo(nextPage)
        }
    }
    const getListPatientStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'PATIENT',
                limit: 100000,
            })
            setListPatientStatus(() => {
                const list = res?.data?.listStatusCode?.data?.map((el) => ({
                    key: el.id,
                    value: el.name,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [setIsAccordionExpanded, isAccordionExpanded],
    )
    const scrollBarPosition = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        [setScrollPosition, scrollPosition],
    )
    const filterSearch1 = (value, name) => {
        setSearchTextOffilter({
            ...searchTextOffilter,
            [name]: value,
        })
    }
    const handleChangeSelect = React.useCallback(
        (option, checked, state, field) => {
            let arr = [...(state ? state : [])]
            if (checked) {
                arr.push(option)
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            setSearchQueries({ ...searchQueries, [field]: arr })
            if (arr?.length > 0) {
                if (!totalNoOfFilterUsed.includes(field)) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, field])
                }
            } else if (arr?.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== field)
                setTotalNoOfFilterUsed(newData)
            }
        },
        [searchQueries, totalNoOfFilterUsed],
    )
    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setSearchQueries({
                ...searchQueries,
                [name]: null,
            })
            const newData = totalNoOfFilterUsed.filter((item) => item !== name)
            setTotalNoOfFilterUsed(newData)
        },
        [searchQueries, totalNoOfFilterUsed],
    )

    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'patient_id',
                name: 'Patient',
                value: searchQueries.patient_id,
                options: patientData,
                loading: loadingPatientOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePage,
                AccordionExpanded: isAccordionExpanded.patient_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient_id,
                scrollBarPosition: scrollBarPosition,
                filterValue: searchTextOffilter.patient_id
            },
            {
                key: 'patient_status',
                name: 'Patient Status',
                value: searchQueries.patient_status,
                options: listPatientStatus,
                AccordionExpanded: isAccordionExpanded.patient_status,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient_status,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'phoneNumber',
                searchInputType: 'phoneNumber',
                name: 'Phone Number',
                value: searchQueries.phoneNumber,
                options: phnData,
                loading: loadingPhoneNumberOption,
                filterSearch: filterSearch1,
                handleOnScrollChangePage: handleChangePagePhoneNumber,
                AccordionExpanded: isAccordionExpanded.phoneNumber,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.phoneNumber,
                scrollBarPosition: scrollBarPosition,
                filterValue: searchTextOffilter.phoneNumber,
            },
            {
                key: 'specialty_id',
                name: 'Speciality',
                value: searchQueries.specialty_id,
                options: speciality,
                loading: loadingSpecialty,
                AccordionExpanded: isAccordionExpanded.specialty_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.specialty_id,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'visit_id',
                name: 'Visit Type',
                value: searchQueries.visit_id,
                options: listVisitType,
                loading: loadingVisitType,
                AccordionExpanded: isAccordionExpanded.visit_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.visit_id,
                scrollBarPosition: scrollBarPosition,
            },
            {
                key: 'provider_id',
                name: 'Provider',
                value: searchQueries.provider_id,
                options: listProvider,
                filterSearch: filterSearch1,
                loading: loadingProvider,
                handleOnScrollChangePage: handleScrollProvider,
                AccordionExpanded: isAccordionExpanded.provider_id,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.provider_id,
                scrollBarPosition: scrollBarPosition,
                filterValue: searchTextOffilter.provider_id,
            },
            {
                key: 'chart_status',
                name: 'Status',
                value: searchQueries.chart_status,
                options: status,
                handleOnScrollChangePage: handleScrollProvider,
                AccordionExpanded: isAccordionExpanded.chart_status,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.chart_status,
                scrollBarPosition: scrollBarPosition,
            },
        ]
    }, [
        patientData,
        loadingPatientOption,
        isAccordionExpanded,
        scrollPosition,
        searchQueries,
        speciality,
        loadingProvider,
        listProvider,
        listVisitType,
        loadingSpecialty,
        loadingVisitType,
        status,
        phnData,
        loadingPhoneNumberOption,
        searchTextOffilter,
        listPatientStatus,
    ])
    const getChartStatus = (status) => {
        if (subValue === "in_progress,hold" && status?.length === 0) {
            return "in_progress,hold"
        } else if (subValue === "completed" && status?.length === 0) {
            return "completed"
        } else if (subValue === "in_progress,hold" && status?.length) {
            const filteredValue = status?.filter((item) => item.key !== "completed")
            return filteredValue?.map((item) => item.key).join()
        } else if (subValue === "completed" && status?.length) {
            return 'completed'
        }
    }
    const getListAppointments = async (page = 1) => {
        setisLoading(true)
        const joinArrString = (arr) => {
            return arr?.map((item) => item.key).join()
        }
        const formatChange = (arr) => {
            return moment(arr).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
        let { patient_id, specialty_id, provider_id, visit_id, chart_status, service_date } =
            searchQueries
            ; (patient_id = patient_id?.length ? joinArrString(patient_id) : ''),
                (specialty_id = specialty_id?.length ? joinArrString(specialty_id) : ''),
                (visit_id = visit_id?.length ? joinArrString(visit_id) : ''),
                (provider_id = provider_id?.length ? joinArrString(provider_id) : '')
        const chartStatus = chart_status?.length ? getChartStatus(chart_status) : getChartStatus([])
        const start = service_date?.length ? formatChange(service_date[0]) : ''
        const end = service_date?.length > 1 ? formatChange(service_date[1]) : ''
        try {
            const res = await apiFollowUp.getAappointments({
                _sort: '-_id',
                page,
                size: 10,
                patient_id,
                specialty_id,
                provider_id,
                visit_id,
                chartStatus: chartStatus ? chartStatus : subValue,
                start,
                end,
            })
            if (res.data) {
                const dataAppointment = res.data.data
                const dataUi = dataAppointment?.map((item) => ({
                    id: item.id,
                    patient:
                        (item.participant[0]?.first_name || '') +
                        ' ' +
                        (item.participant[0]?.last_name || ''),
                    patient_status: item.patient_status,
                    patient_phone: item.participant[0]?.phone_number,
                    date: item.start,
                    specialty: item.specialty?.specialty,
                    visit_type: item.visit_type?.post_code,
                    provider_name:
                        item?.practitioner_first_name + ' ' + item?.practitioner_last_name,
                    chart_status: item?.chart_status,
                    // service_location: item.location_name,
                    // appointment_status: item.appointment_status,
                    // priority: item.priority,
                    patient_status_code: item.patient_status_code,
                    appointment_status_code: item.appointment_status_code,
                }))
                setChartData(dataUi || [])

                setTotal(res.data.totalCount)
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong !', 'error')
        } finally {
            setisLoading(false)
        }
    }

    const visitTypeFinder = (visitId) => {
        const visitName = visitType.find((data) => data.id === visitId)
        const findVal = visitName?.post_code
        return findVal
    }
    const specialityTypeFinder = (splId) => {
        const specialityName = speciality.find((data) => data.id === splId)
        const findsplty = specialityName?.specialty
        return findsplty
    }

    const clearSearch = () => {
        setSearchQueries({
            patient_id: null,
            specialty_id: null,
            provider_id: null,
            visit_id: null,
            status: null,
        })
        setTotalNoOfFilterUsed([])
    }
    const onChangeFilterData = React.useCallback(
        (field, value) => {
            setSearchQueries({ ...searchQueries, [field]: value })
        },
        [searchQueries],
    )

    const columnsForChartPrep = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Patient Status',
            headerClassName: 'super-app-theme--header',
            field: 'patient_status_code',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                return value?.name || '-'
            },
        },
        {
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            field: 'patient_phone',
            flex: 0.7,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    value?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3') ||
                    '-'
                )
            },
        },
        {
            headerName: 'Date of Service ',
            headerClassName: 'super-app-theme--header',
            field: 'date',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Specialty',
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Visit Type',
            headerClassName: 'super-app-theme--header',
            field: 'visit_type',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Provider Name',
            headerClassName: 'super-app-theme--header',
            field: 'provider_name',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'chart_status',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                const valueUpperCase = value.toUpperCase()
                return (
                    <span
                        className={clsx({
                            [definedStyles.status_box]: value !== 'in_progress',
                            [definedStyles.status_box_inprogress]: value === 'in_progress',
                            [definedStyles.bgColor_in_progress]: value === 'in_progress',
                            [definedStyles.bgColor_hold]: value === 'hold',
                            [definedStyles.bgColor_completed]: value === 'completed',
                        })}
                        style={{ paddingLeft: value === 'in_progress' ? '1px' : '' }}>
                        {valueUpperCase || '-'}
                    </span>
                )
            },
        },
        // {
        //     headerName: 'Service Location',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'service_location',
        //     flex: 0.5,
        //     sortable: false,
        //     renderCell: ({ value }) => {
        //         return value || '-'
        //     },
        // },

        // {
        //     headerName: 'Appointment Status',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'appointment_status_code',
        //     flex: 0.5,
        //     sortable: false,
        //     renderCell: ({ value, row }) => {
        //         return value?.name || '-'
        //     },
        // },
        // {
        //     headerName: 'Priority',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'priority',
        //     flex: 0.5,
        //     sortable: false,
        //     renderCell: ({ value }) => {
        //         return value || '-'
        //     },
        // },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => {
                return (
                    <HtmlTooltip
                        title={'Edit'}
                        arrow
                        placement="top"
                        style={{
                            background: 'white !important',
                            color: '#303E4E',
                        }}>
                        <div>
                            <ButtonIconEl
                                size="small"
                                onClick={() => {
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.CHART_PREP.EDIT.replace(':id', row.id)
                                    )
                                }}
                                className={classes.act_iconbtn}
                                icon={
                                    <img
                                        src={EditIcon}
                                        alt=""
                                        style={{
                                            color: 'white',
                                        }}></img>
                                }
                                iconHover={
                                    <img
                                        src={HoverEditIcon}
                                        alt=""
                                        style={{
                                            color: 'white',
                                        }}></img>
                                }></ButtonIconEl>
                        </div>
                    </HtmlTooltip>
                )
                // <EditAction
                //     menu={[
                //         {
                //             text: 'Edit',
                //             // handler: () => {
                //             //     setAppointmentModal({ id: row.id, status: true })
                //             // },
                //         },
                //     ]}
                //     deleteMessage="Are you sure you want to delete this Follow UP?"
                // />
            },
        },
    ]

    useEffect(() => {
        ; (async () => {
            const abortController = new AbortController()
            await getListAppointments(page)
            return () => abortController.abort()
        })()
    }, [page])
    useEffect(() => {
        ; (async () => {
            if (!mounted) return setMounted(true)

            if (page !== 1) return setPage(1)

            const abortController = new AbortController()
            await getListAppointments()
            return () => abortController.abort()
        })()
    }, [searchQueries, subValue])
    const handleChangeSubValue = (event, newValue) => {
        // tabContainer(newValue)
        setSubValue(newValue);
        // if (newValue === 0) {
        //     setTabName("in_progress")
        // } else if (newValue === 1) {
        //     setTabName("completed")
        // }
    };


    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between">
                    <Grid
                        container
                        flex={1}
                        justifyContent="space-between"
                        spacing={1}
                        style={{ marginTop: 5, marginBottom: 15 }}
                        className={classes?.subtabDiv}>
                        <Grid item style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                            <Tabs
                                value={subValue}
                                onChange={handleChangeSubValue}
                                TabIndicatorProps={{ style: { display: 'none' } }}
                            >
                                <CustomTab style={{ background: "none", textTransform: "capitalize" }} label="In Progress" value="in_progress,hold" />
                                <CustomTab style={{ background: "none", textTransform: "capitalize" }} label="Complete" value="completed" />
                            </Tabs>
                        </Grid>
                        <Grid container style={{ marginTop: "7px" }} justifyContent="end" flex={1}>
                            <Grid item style={{ width: 300 }}>
                                {!activeRange && (
                                    <SingleCalenderComp
                                        onChange={(e) => {
                                            setSingleDate(e ? format(e, 'MM/dd/yyyy') : '')
                                            onChangeFilterData(
                                                'service_date',
                                                e
                                                    ? [startDateFilterToUTC(e), endDateFilterToUTC(e)]
                                                    : [],
                                            )
                                        }}
                                        HideShowIcon={activeRange}
                                        value={SingleDate}
                                        onClear={() => {
                                            setSingleDate('')
                                        }}
                                    />)
                                }
                                <Box
                                    sx={{
                                        width: 0,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        position: 'absolute',
                                        // backgroundColor: 'primary.dark',
                                        // '&:hover': {
                                        //     backgroundColor: 'primary.main',
                                        //     opacity: [0.9, 0.8, 0.7],
                                        // },
                                    }}>
                                    <Paper>
                                        {activeRange && (
                                            <DateRangeComp
                                                style={{ marginTop: '20px' }}
                                                onChange={(e) => {
                                                    if (e?.startDate && e?.endDate) {
                                                        setStartDate(format(e?.startDate, 'MM/dd/yyyy'))
                                                        setEndDate(format(e?.endDate, 'MM/dd/yyyy'))
                                                        setSingleDate('')
                                                        onChangeFilterData('service_date', [
                                                            startDateFilterToUTC(e?.startDate),
                                                            endDateFilterToUTC(e?.endDate),
                                                        ])
                                                    }
                                                }}
                                                activeRange={activeRange}
                                                dateSeparator="-"
                                                startDate={startDate}
                                                defaultRange={SingleDate || null}
                                                endDate={endDate}
                                                onClear={() => {
                                                    setSingleDate('')
                                                    setStartDate('')
                                                    setEndDate('')
                                                    onChangeFilterData('service_date', [])
                                                }}
                                            />)
                                        }
                                    </Paper>
                                </Box>
                            </Grid>

                            <Grid item>
                                <HtmlTooltip
                                    title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                                    arrow
                                    placement="bottom"
                                    style={{
                                        background: 'white !important',
                                        color: '#303E4E',
                                    }}>
                                    <Box style={{ marginTop: '12px' }}>
                                        <AntSwitch
                                            onChange={() => {
                                                setActiveRange(activeRange ? false : true)
                                                if (startDate && endDate && activeRange) {
                                                    onChangeFilterData('service_date', [])
                                                    setStartDate('')
                                                    setEndDate('')
                                                }
                                            }}
                                            checked={activeRange}
                                        />
                                    </Box>
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginTop: '5px' }}>
                            <MultiFilter
                                filterOptionsArrayObject={filterOptionsArrayObject}
                                handleChange={handleChangeSelect}
                                handleClearSingleFilter={handleClearSingleFilter}
                                handleClearFilter={clearSearch}
                                totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                            />
                        </Grid>
                        <Grid item style={{ marginTop: '9px' }}>
                            <ButtonCustom
                                tooltip="Add Chartprep Record"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.MAIN + Routes.WORK_QUEUES.FOLLOW_UP.CHART_PREP.ADD
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.form_list_container}`}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        rows={chartData}
                        columns={columnsForChartPrep}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={(e) => {
                                        setPage(e)
                                    }}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ChartPrepList
