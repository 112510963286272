import { Modal, Paper, Tab, Tabs, Button } from '@material-ui/core'
import { RefreshOutlined } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles, withStyles } from '@material-ui/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Grid } from '@mui/material'
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import clsx from 'clsx'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import Routes from 'constants/routes'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import format from 'date-fns/format'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    allApiLabOrder,
    getLabList,
    getOrderingProvider,
    labOrderMatch,
    getReportIDSuggestion,
} from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import { endDateFilterToUTC, startDateFilterToUTC } from 'shared/utils'
import { getPatientList } from 'services/Patient/patient'
import { useDebounce } from 'shared/hooks'
import moment from 'moment'
import SearchCHCProviderInput from 'components/custom/SearchCHCProviderInput'
import { formatDateTime } from 'utilities/formats'
import './styles.scss'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import Divider from '@mui/material/Divider'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import {_getClinicalNotesFilterPatient} from "../ClinicalNotes/api/services";
import {getPaginatedData, mapToFiltersFormat} from "../../utilities";
import { getLabResultStatus } from 'utilities/labResults'

const useStyles = makeStyles((theme) => ({
    subtabDiv: {
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        padding: '3px',
        backgroundColor: '#F8F9FB',
        marginTop: '7px ',
    },
    container: {
        paddingTop: '2px',
        padding: 20,
    },
    form_list_container: {
        marginTop: 10,
        border: '1px solid #EAECF0',
        borderRadius: '5px',
        backgroundColor: '#F8F9FB',
    },
    wrap_data_grid: {
        // paddingLeft: 10,
        // paddingRight: 10,
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 184px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
            marginBottom: '35px',
            paddingLeft: '10px',
        },
        '& .MuiDataGrid-row': {
            background: '#FAFAFA',
            margin: '4px 0',
            borderRadius: '8px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
    month_header: {
        color: '#303E4E',
        fontSize: 20,
        margin: 0,
    },
    date_header: {
        color: '#9CA9B8',
        fontSize: 13,
        marginTop: 4,
    },
    btn_add: {
        height: 32,
        background: '#14AE5C',
        fontSize: 14,
        borderRadius: 8,
        fontWeight: 400,
        padding: '10px 12px',
        '&:hover': {
            background: '#14AE5C',
        },
    },
    clear_btn: {
        background: '#a1a1a1',
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    down_btn: {
        background: '#a1a1a1',
        minWidth: 48,
        '&:hover': {
            background: '#a1a1a1',
        },
    },
    btn_date_select: {
        background: '#F3F6F9',
        fontSize: 14,
        width: 90,
        height: 32,
        borderRadius: 8,
        padding: '10px 12px',
        fontWeight: 400,
        color: '#667689',
        '&:hover': {
            background: '#667689',
            color: 'white',
        },
    },
    btn_date_selected: {
        background: '#667689',
        color: 'white',
    },
    scroller: {
        display: 'flex',
        alignItems: 'center',
    },
    menuPaper: {
        maxHeight: 410,
    },
    header_filter: {
        alignItems: 'center !important',
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'center !important',
        },
    },
    col_title: {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        marginBottom: '15px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0px',
            width: '288px',
        },
    },
    col_right_fiter: {
        width: 'calc(100% - 80px)',
        [theme.breakpoints.up('desktop1700')]: {
            width: 'calc(100% - 288px - 80px)',
        },
    },
    wrap_fiter: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end',
        },
    },
    item_select: {
        [theme.breakpoints.up('xs')]: {
            flex: 'none',
            // maxWidth: 'calc(100% / 3)',
            width: 'calc(100% / 3)',
            // marginRight: '12px',
            padding: '0 12px 12px 0px',
        },
        [theme.breakpoints.up('lg')]: {
            // flex: 1,
            // maxWidth: '100%',
            padding: '0 12px 0px 0px',
            // maxWidth: 'calc((100% - 124px) / 5)',
            width: 'calc((100% - 64px) / 5)',
        },
        [theme.breakpoints.up('desktop1700')]: {
            // maxWidth: '160px',
        },
    },
    filter_btn: {
        width: 130,
        fontWeight: 'normal',
        borderRadius: '10px',
        background: '#5571C6',
        color: 'white',
    },
    tabBackGround: {
        backgroundColor: 'rgba(0, 0, 0, 0.09)',
        borderRadius: '8px',
    },
    noOfFilter: {
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        fontWeight: 'normal',
        borderRadius: '10px',
        background: 'white',
        color: '#5571C6',
        padding: '6px',
        paddingTop: '8px',
        marginLeft: 6,
        height: '8px',
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
        color: '#000000',
    },
    status_box_inprogress: {
        borderRadius: 5,
        // paddingTop: '4px',
        padding: '4px 1px',
        background: '#E9ECF1',
        color: '#000000',
    },
    bgColor_in_progress: {
        background: '#E9ECF1',
        color: '#667689',
        padding: 2,
    },
    bgColor_hold: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_completed: {
        background: '#EBFFF3',
        color: '#15A452',
        padding: 2,
    },
}))
const CustomTab = withStyles((theme) => ({
    root: {
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        '&.Mui-selected': {
            '& .MuiTab-wrapper': {
                background: 'transparent',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                paddingBottom: '8px',
                paddingTop: '8px',
            },
        },
    },
    wrapper: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
}))((props) => <Tab {...props} />)
const statusCode = [
    { key: 'XC', value: 'Cancelled by Client' },
    { key: 'XL', value: 'Cancelled by Lab' },
    { key: 'C', value: 'Corrected' },
    { key: 'D', value: 'Draft' },
    { key: 'E', value: 'Entered' },
    { key: 'I', value: 'Inactive' },
    { key: 'PSCR', value: 'PSC Ready' },
    { key: 'P', value: 'Partial Reported' },
    { key: 'R', value: 'Ready to Transmit' },
    { key: 'NA', value: 'Results Received' },
    { key: 'S', value: 'Scheduled' },
    { key: 'SUBPSC', value: 'Submitted to PSC' },
    { key: 'TX', value: 'Transmission Error' },
    { key: 'T', value: 'Transmitted' },
]
export const LabOrders = () => {
    const classes = useStyles()
    const history = useHistory()
    const notification = useNotification()
    const columns = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ cursor: 'pointer' }}>
                        {value ? (
                            <DataGridCell value={value} highlight />
                        ) : (
                            // {record.id === isDownload?.id && isDownload.loading && <LinearProgress />}
                            '-'
                        )}
                    </Box>
                )
            },
        },
        {
            headerName: 'Ordering Provider',
            headerClassName: 'super-app-theme--header',
            field: 'provider_name',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value = '' }) => {
                return value
            },
        },
        {
            headerName: 'Lab Ordered',
            headerClassName: 'super-app-theme--header',
            field: 'lab_ordered',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return value || ''
            },
        },
        {
            headerName: 'Requisition Number',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_number',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return value || ''
            },
        },
        {
            headerName: 'Order Date',
            headerClassName: 'super-app-theme--header',
            field: 'order_date',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Order Status',
            headerClassName: 'super-app-theme--header',
            field: 'order_status',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span
                        className={clsx({
                            [classes.bgColor_in_progress]: value !== 'Final Reported',
                            [classes.bgColor_completed]: value === 'Final Reported',
                        })}>
                        {value || '-'}
                    </span>
                )
            },
        },
        {
            headerName: 'Requisition PDF',
            headerClassName: 'super-app-theme--header',
            field: 'requisition_PDF',
            flex: 0.5,
            sortable: false,
            renderCell: (record) => {
                const { value } = record
                return (
                    <Box sx={{ cursor: 'pointer' }}>
                        {value ? (
                            <DataGridCell value={'View detail'} highlight />
                        ) : (
                            // {record.id === isDownload?.id && isDownload.loading && <LinearProgress />}
                            '-'
                        )}
                    </Box>
                )
            },
        },
        {
            headerName: 'Refresh',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.2,
            sortable: false,
            renderCell: () => {
                return (
                    <RefreshOutlined
                        style={{
                            padding: 5,
                            cursor: 'pointer',
                        }}
                        fontSize="small"
                        onClick={handleRefresh}
                    />
                )
            },
        },
    ]
    const columnsEx = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Box sx={{ cursor: 'pointer' }}>
                        {value ? (
                            <DataGridCell value={value} highlight />
                        ) : (
                            // {record.id === isDownload?.id && isDownload.loading && <LinearProgress />}
                            '-'
                        )}
                    </Box>
                )
            },
        },
        {
            headerName: 'Report ID',
            headerClassName: 'super-app-theme--header',
            field: 'clinicalReport',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value }) => {
                return value
            },
        },
        {
            headerName: 'Date Received',
            headerClassName: 'super-app-theme--header',
            field: 'creation_datetime',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return formatDateTime(value) || '-'
            },
        },
        {
            headerName: 'Order Status',
            headerClassName: 'super-app-theme--header',
            field: 'order_status',
            flex: 0.4,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <span
                        className={clsx({
                            [classes.bgColor_in_progress]: value !== 'Final Reported',
                            [classes.bgColor_completed]: value === 'Final Reported',
                        })}>
                        {value || '-'}
                    </span>
                )
            },
        },
        {
            headerName: 'Unmatched Results',
            headerClassName: 'super-app-theme--header',
            field: 'lab_id',
            flex: 0.5,
            sortable: false,
            renderCell: ({ value, row }) => {
                return (
                    
                    <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>{row.pdfUrl&&handleOpenModal(value, row?.pdfUrl)}}>
                        {
                            row?.pdfUrl ? (<DataGridCell value={'View detail'} highlight />) : ('-')
                        }    
                    </Box>
                )
            },
        },
        {
            headerName: 'Refresh',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            flex: 0.2,
            sortable: false,
            renderCell: () => {
                return (
                    <RefreshOutlined
                        style={{
                            padding: 5,
                            cursor: 'pointer',
                        }}
                        fontSize="small"
                        onClick={handleRefresh}
                    />
                )
            },
        },
    ]
    const initialBooleanValue = {
        patient: false,
        ordering_provider: false,
        requisition_number: false,
        lab_list: false,
        status: false,
        report_id: false,
    }
    const initialVlaue = {
        patient: 0,
        ordering_provider: 0,
        requisition_number: 0,
        lab_list: 0,
        status: 0,
        report_id: 0,
    }
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [synchronizing, setSynchronizing] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [dataLabOrder, setDataLabOrder] = useState([])
    const [subValue, setSubValue] = useState('ordered')
    const [activeRange, setActiveRange] = useState(false)
    const [patientId, setPatientId] = useState()
    const [patientDetails, setPatientDetails] = useState()
    const [labId, setLabId] = useState()
    const [pdf, setPdf] = useState(null)
    const [allStatus, setAllStatus] = useState('XC,XL,C,D,E,X,I,PSCR,P,R,NA,S,SUBPSC,TX,T')
    const [SingleDate, setSingleDate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [anchorEl, setAnchorEl] = useState(null)
    const [searchQueries, setSearchQueries] = useState({
        lab_code: null,
        code: null,
        patient: null,
        report_status: null,
        sponsor_name: null,
        date: null,
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient: '',
        ordering_provider: '',
        requisition_number: '',
        lab_list: '',
        report_id: '',
    })
    const [patientData, setPatientData] = useState([])
    const [orderingProviderData, setorderingProviderData] = useState([])
    const [requisitionNumber, setRequisitionNumber] = useState([])
    const [labListData, setLabListData] = useState([])
    const [filtersearchQueries, setFilterSearchQueries] = useState({
        patient: null,
        ordering_provider: null,
        requisition_number: null,
        lab_list: null,
        status: null,
        report_id: null,
    })
    const [status, setStatus] = useState(statusCode)
    const [reportIdOptions, setReportIdOptions] = useState([])
    const [loadingData, setLoadingData] = useState(initialBooleanValue)
    const [bundleIdPatient, setBundleIdPatient] = useState('')
    const [bundleIdRn, setBundleIdRn] = useState('')
    const [totalNoOfPage, setTotalNoOfPage] = useState((PREV) => {
        return {
            ...PREV,
            patient: 0,
            ordering_provider: 0,
            requisition_number: 0,
            lab_list: 0,
            status: 0,
            report_id: 0,
        }
    })
    const [filterText, setFilterText] = useState({
        patient: '',
        ordering_provider: '',
        requisition_number: '',
        lab_list: '',
        status: '',
        report_id: '',
    })
    const [currentPage, setCurrentpage] = useState(initialVlaue)
    const [scrollPosition, setScrollPosition] = useState({
        patient: null,
        ordering_provider: null,
        requisition_number: null,
        lab_list: null,
        status: null,
        report_id: null,
    })
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [bundleId, setBundleId] = useState('')
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(initialBooleanValue)
    const [isFirst, setIsFirst] = useState(false)
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient, 500)
    const debouncedSearchFilterRequisitionNumber = useDebounce(
        searchTextOffilter.requisition_number,
        500,
    )
    const debouncedSearchLablist = useDebounce(searchTextOffilter.lab_list, 500)
    const debouncedSearchStatus = useDebounce(searchTextOffilter.status, 500)
    const debouncedSearchOrderinProvider = useDebounce(searchTextOffilter.ordering_provider, 500)

    useEffect(() => {
        const abortController = new AbortController()
        getLabOrder(page)
        return () => abortController.abort()
    }, [page, subValue, searchQueries, filtersearchQueries])

    useEffect(() => {
        if (isFirst) {
            getPatientlistForFilter()
        }
    }, [debouncedSearchFilterPatientText])

    useEffect(() => {
        if (isFirst) {
            getLabOrderForfilter()
        }
    }, [debouncedSearchFilterRequisitionNumber])

    // useEffect(() => {
    //     getLabOrder(page)
    // }, [searchQueries, filtersearchQueries])

    useEffect(() => {
        if (isFirst) {
            if (searchTextOffilter.lab_list) {
                // getLabListFilter()
                const searchedData = searchFilterLocal(labListData, searchTextOffilter.lab_list)
                setLabListData(searchedData)
            } else {
                getLabListFilter()
            }
        }
    }, [debouncedSearchLablist])

    useEffect(() => {
        if (isFirst) {
            if (searchTextOffilter.status) {
                const searchedData = searchFilterLocal(status, searchTextOffilter.status)
                setStatus(searchedData)
            } else {
                if (subValue === 'EX') {
                    const newStatus = [...statusCode, { key: 'F', value: 'Final Reported' }]
                    setStatus(newStatus)
                } else {
                    setStatus(statusCode)
                }
                // setStatus(statusCode)
            }
        }
    }, [debouncedSearchStatus])

    useEffect(() => {
        if (isFirst) {
            if (searchTextOffilter.ordering_provider) {
                const searchedData = searchFilterLocal(
                    orderingProviderData,
                    searchTextOffilter.ordering_provider,
                )
                setorderingProviderData(searchedData)
            } else {
                getOrderingProviderFilter()
            }
        }
    }, [debouncedSearchOrderinProvider])

    const getReportIDOptions = () => {
        getReportIDSuggestion()
            ?.then((res) => {
                const optionData = res.data?.data?.map((item) => item?.clinicalReport)
                const distinctData = [...new Set(optionData)]?.map((item) => ({
                    key: item,
                    value: item,
                }))
                setReportIdOptions(distinctData)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const filterSearch = React.useCallback(
        (value, name) => {
            setSearchTextOffilter({
                ...searchTextOffilter,
                [name]: value,
            })
        },
        [searchTextOffilter],
    )
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [isAccordionExpanded],
    )
    // const scrollBarPosition1 = (value, field) => {
    //     setScrollPosition({ ...scrollPosition, [field]: value })
    // }

    const scrollBarPosition1 = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        [setScrollPosition, scrollPosition],
    )
    const setFilterValue = React.useCallback(
        (value, name) => {
            setFilterText({
                ...filterText,
                [name]: value,
            })
        },
        [filterText],
    )

    const uniqObjFinder = (array, key) => {
        const redUniq = [
            ...array
                .reduce((uniq, curr) => {
                    if (!uniq.has(curr[key])) {
                        uniq.set(curr[key], curr)
                    }
                    return uniq
                }, new Map())
                .values(),
        ]
        return redUniq
    }
    const handlePageChange = (value) => {
        setPage(value)
    }
    const handleRefresh = async () => {
        try {
            setLoading(true)
            setSynchronizing(true)
            await allApiLabOrder.syncLabOrder()
            await getLabOrder(page)
        } catch (error) {
            notification('Something went wrong', 'error')
        } finally {
            setLoading(false)
            setSynchronizing(false)
        }
    }
    const joinArrString = (arr) => {
        return arr.map((item) => item.key).join()
    }
    const statusValueSet = () => {
        if (subValue === 'ordered' && filtersearchQueries['status']?.length > 0) {
            return joinArrString([...filtersearchQueries['status']])?.trim()
        }
        if (
            (subValue === 'ordered' && !filtersearchQueries['status']) ||
            (subValue === 'ordered' && filtersearchQueries['status'].length === 0)
        ) {
            return allStatus
        }
        if (subValue === 'EX') {
            if (filtersearchQueries['status']?.length > 0) {
                return joinArrString([...filtersearchQueries['status']])?.trim()
            }
            return subValue
        }
        if (subValue === 'F') {
            return subValue
        }
    }
    const getLabOrder = async (page) => {
        setLoading(true)
        try {
            const res = await allApiLabOrder.getAll({
                page,
                size: limit || 10,
                report_status: statusValueSet(),
                status: subValue === 'EX' ? 'partial' : 'registered',
                patient: filtersearchQueries['patient']
                    ? joinArrString(filtersearchQueries['patient'])
                    : '',
                code: filtersearchQueries['requisition_number']
                    ? joinArrString(filtersearchQueries['requisition_number'])?.trim()
                    : '',
                lab_code: filtersearchQueries['lab_list']
                    ? joinArrString(filtersearchQueries['lab_list'])
                    : '',
                // start: searchQueries.start ? searchQueries.start : '',
                // end: searchQueries.end ? searchQueries.end : '',
                date: searchQueries?.date?.length > 0 ? searchQueries?.date : '',
                provider_name: filtersearchQueries['ordering_provider']
                    ? joinArrString(filtersearchQueries['ordering_provider'])?.trim()
                    : '',
                clinicalReport: filtersearchQueries['report_id']
                    ? joinArrString(filtersearchQueries['report_id'])
                    : '',
            })
            setLoading(false)
            const dataFormat =
                res.data?.data.map((item) => ({
                    id: item.id,
                    patient_id: item.patient_id,
                    patient: `${item.first_name || ''} ${item.last_name || ''}`,
                    requisition_number: item.placer_order_number,
                    lab_ordered: item.lab_code,
                    order_date: item.creation_datetime,
                    order_status: getLabResultStatus(item.htmlUrl, item.pdfUrl, item.report_status),
                    orderring_provider: item.sponsor_name,
                    requisition_PDF: item.requisitionUrl || null,
                    pdfUrl: item.pdfUrl || null,
                    provider_name: item.provider_name || '',
                    clinicalReport: item.clinicalReport,
                    creation_datetime: item.creation_datetime,
                    lab_id: item.id,
                })) || []
            setDataLabOrder(dataFormat)
            setTotal(res.data.paging?.total)
        } catch (e) {
            notification('Something went wrong !', 'error')
            setLoading(false)
        }
    }
    const handleCellClick = (params) => {
        if (params.field === 'id') return
        if (params.field === 'requisition_PDF' && params.row.requisition_PDF) {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.setAttribute('style', 'display:none')
            a.target = '_blank'
            a.href = params.row.requisition_PDF
            a.download = 'Requisition PDF'
            a.click()
            window.URL.revokeObjectURL(params.row.requisition_PDF)
        } else {
            history.push(Routes.PATIENT.LAB_ORDERS.replace(':id', params.row.patient_id), {
                from: 'lab-order',
            })
        }
    }
    const handleChangeSubValue = (event, newValue) => {
        event.preventDefault()
        setSubValue(newValue)
        setPage(1)
        setFilterSearchQueries({
            ...filtersearchQueries,
            patient: null,
            ordering_provider: null,
            requisition_number: null,
            lab_list: null,
            status: null,
            report_id: null,
        })
        setTotalNoOfFilterUsed([])
        if (newValue === 'EX') {
            const newStatus = [...statusCode, { key: 'F', value: 'Final Reported' }]
            setStatus(newStatus)
        } else {
            setStatus(statusCode)
        }
    }
    const onChangeFilterData = React.useCallback(
        (field, value) => {
            setSearchQueries({ ...searchQueries, [field]: value })
        },
        [searchQueries],
    )
    const getPatientlistForFilter = async () => {
        try {
            setLoadingData({ ...loadingData, patient: true })
            const payload = {
                page: 1,
                query:searchTextOffilter.patient,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const {tempUserList, bundle_id, result} = getPaginatedData(res);
            const mappedPatientList = mapToFiltersFormat(tempUserList);
            setPatientData(mappedPatientList);
            setCurrentpage({ ...currentPage, patient: 1 })
            setBundleIdPatient(bundle_id)
            setTotalNoOfPage((prev) => {
                return { ...prev, patient: result }
            })
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingData({ ...loadingData, patient: false })
        }
    }

    const handleChangePagePatient = async () => {
        if (totalNoOfPage.patient > currentPage.patient) {
            const nextPage = currentPage.patient + 1
            const payload = {
                page: nextPage,
                bundle_id: bundleIdPatient,
                query: searchTextOffilter.patient,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${
                        item.last_name
                    }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            const sortedPatientData = [...patientData, ...tempUserList]
            setPatientData(sortedPatientData)
            setCurrentpage({ ...currentPage, patient: nextPage })
            setBundleId(res?.data?.bundle_id)
        }
    }

    const getLabListFilter = async () => {
        try {
            setLoadingData({ ...loadingData, lab_list: true })
            const labList = await getLabList()
            const generatedLablist = []
            labList.data.map((item) => {
                generatedLablist.push({
                    key: item?.lab_code,
                    value: `${item?.lab_code} - ${item.lab_name}`,
                })
            })
            setLabListData(generatedLablist)
            setCurrentpage({ ...currentPage, lab_list: 1 })
        } catch (error) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingData({ ...loadingData, lab_list: false })
        }
    }

    const getLabOrderForfilter = async () => {
        setLoadingData({ ...loadingData, requisition_number: true })
        try {
            const res = await allApiLabOrder.getAll({
                page: 1,
                size: 10,
                code: searchTextOffilter.requisition_number,
            })
            setLoading(false)
            const dataFormat =
                res.data?.data.map((item) => ({
                    key: item.placer_order_number,
                    value: item.placer_order_number,
                })) || []
            const getdata = uniqObjFinder(dataFormat, 'value')
            setRequisitionNumber([...getdata])
            setCurrentpage({ ...currentPage, requisition_number: 1 })

            setTotalNoOfPage((prev) => {
                return { ...prev, requisition_number: res.data.paging?.total }
            })
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
        setLoadingData({ ...loadingData, requisition_number: false })
    }

    const getOrderingProviderFilter = async () => {
        setLoadingData({ ...loadingData, ordering_provider: true })
        try {
            const dta = await getOrderingProvider()
            const generatedProvider =
                dta.data?.map((item) => ({
                    key: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                    value: `${item.provider_fname} ${item.provider_mname} ${item.provider_lname}`,
                })) || []
            setorderingProviderData(generatedProvider)
        } catch (error) {
            notification('Something went wrong !', 'error')
        } finally {
            setLoadingData({ ...loadingData, ordering_provider: false })
        }
    }

    const handleChangePageRequisitionNumber = async () => {
        if (totalNoOfPage.requisition_number > currentPage.requisition_number) {
            const nextPage = currentPage.requisition_number + 1
            let payload = {
                page: nextPage,
                size: 20,
                code: searchTextOffilter.requisition_number,
            }
            const res = await allApiLabOrder.getAll(payload)
            let reqNumber = []
            res?.data?.data.map((item) =>
                reqNumber.push({
                    key: item.placer_order_number,
                    value: item.placer_order_number,
                }),
            )
            if (reqNumber.length) {
                let sortedRequistionNumber = [...requisitionNumber, ...reqNumber]
                const getdata = uniqObjFinder(sortedRequistionNumber, 'value')
                setRequisitionNumber(getdata)
            } else {
                setRequisitionNumber([])
            }

            setCurrentpage({ ...currentPage, requisition_number: nextPage })
        }
    }
    ///match string inside array

    const searchFilterLocal = (array, string) => {
        const regex = new RegExp(string, 'i')
        let result = []
        array.forEach((val) => {
            if (val && regex.test(val.value)) {
                result.push(val)
            }
        })
        return result
    }

    const filterOptionsArrayObject = React.useMemo(() => {
        if (subValue === 'EX') {
            return [
                {
                    key: 'status',
                    name: 'Status',
                    value: filtersearchQueries.status,
                    options: status,
                    loading: loadingData.status,
                    filterSearch,
                    AccordionExpanded: isAccordionExpanded.status,
                    handleAccordionExpand: handleAccordionExpandNew,
                    setScrollBarPosition: scrollPosition.status,
                    scrollBarPosition: scrollBarPosition1,
                    setFilterValue,
                    filterValue: filterText.status,
                },
                {
                    key: 'report_id',
                    name: 'Report ID',
                    value: filtersearchQueries.report_id,
                    options: reportIdOptions,
                    loading: loadingData.report_id,
                    // filterSearch,
                    AccordionExpanded: isAccordionExpanded.report_id,
                    handleAccordionExpand: handleAccordionExpandNew,
                    setFilterValue,
                    filterValue: filterText.report_id,
                },
            ]
        }
        return [
            {
                key: 'patient',
                name: 'Patient',
                value: filtersearchQueries.patient,
                options: patientData,
                loading: loadingData.patient,
                filterSearch,
                handleOnScrollChangePage: handleChangePagePatient,
                AccordionExpanded: isAccordionExpanded.patient,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue,
                filterValue: filterText.patient,
            },
            {
                key: 'ordering_provider',
                name: 'Ordering Provider',
                value: filtersearchQueries.ordering_provider,
                options: orderingProviderData,
                loading: loadingData.ordering_provider,
                filterSearch,
                handleOnScrollChangePage: handleChangePagePatient,
                AccordionExpanded: isAccordionExpanded.ordering_provider,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.ordering_provider,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue,
                filterValue: filterText.ordering_provider,
            },
            {
                key: 'lab_list',
                name: 'Lab Order',
                value: filtersearchQueries.lab_list,
                options: labListData,
                loading: loadingData.lab_list,
                filterSearch,
                handleOnScrollChangePage: handleChangePagePatient,
                AccordionExpanded: isAccordionExpanded.lab_list,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.lab_list,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue,
                filterValue: filterText.lab_list,
            },
            {
                key: 'requisition_number',
                name: 'Requisition Number',
                value: filtersearchQueries.requisition_number,
                options: requisitionNumber,
                loading: loadingData.requisition_number,
                filterSearch,
                handleOnScrollChangePage: handleChangePageRequisitionNumber,
                AccordionExpanded: isAccordionExpanded.requisition_number,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.requisition_number,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue,
                filterValue: filterText.requisition_number,
            },
            {
                key: 'status',
                name: 'Status',
                value: filtersearchQueries.status,
                options: status,
                loading: loadingData.status,
                filterSearch,
                AccordionExpanded: isAccordionExpanded.status,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.status,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue,
                filterValue: filterText.status,
            },
        ]
    }, [
        filtersearchQueries,
        patientData,
        labListData,
        requisitionNumber,
        isAccordionExpanded,
        loadingData,
        handleChangePagePatient,
        handleChangePageRequisitionNumber,
        handleAccordionExpandNew,
        scrollPosition,
        scrollBarPosition1,
        setFilterValue,
        filterText,
        subValue,
        status,
    ])

    const handleChangeSelect = React.useCallback(
        (option, checked, state, field) => {
            let arr = [...(state ? state : [])]
            if (checked) {
                arr.push(option)
                // setSearchQueries({ ...searchQueries, [field]: arr })
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            if (field === 'status' && subValue !== 'EX') {
                setSubValue('ordered')
                setPage(1)
            }
            setFilterSearchQueries({ ...filtersearchQueries, [field]: arr })

            if (arr.length > 0) {
                if (!totalNoOfFilterUsed.includes(field)) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, field])
                }
            } else if (arr.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== field)
                setTotalNoOfFilterUsed(newData)
            }
        },
        [searchQueries, totalNoOfFilterUsed, subValue],
    )

    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setFilterSearchQueries({
                ...filtersearchQueries,
                [name]: null,
            })
            const newData = totalNoOfFilterUsed.filter((item) => item !== name)
            setTotalNoOfFilterUsed(newData)
        },
        [searchQueries, totalNoOfFilterUsed],
    )

    const clearSearch = () => {
        setFilterSearchQueries({
            ...filtersearchQueries,
            patient: null,
            ordering_provider: null,
            requisition_number: null,
            status: null,
        })
        setTotalNoOfFilterUsed([])
    }
    const handleFilterClick = () => {
        if (!isFirst) {
            getPatientlistForFilter()
            getLabOrderForfilter()
            getReportIDOptions()
            if (searchTextOffilter.lab_list) {
                // getLabListFilter()
                const searchedData = searchFilterLocal(labListData, searchTextOffilter.lab_list)
                setLabListData(searchedData)
            } else {
                getLabListFilter()
            }
            if (searchTextOffilter.status) {
                const searchedData = searchFilterLocal(status, searchTextOffilter.status)
                setStatus(searchedData)
            } else {
                // setStatus(statusCode)
                if (subValue === 'EX') {
                    const newStatus = [...statusCode, { key: 'F', value: 'Final Reported' }]
                    setStatus(newStatus)
                } else {
                    setStatus(statusCode)
                }
            }
            if (searchTextOffilter.ordering_provider) {
                const searchedData = searchFilterLocal(
                    orderingProviderData,
                    searchTextOffilter.ordering_provider,
                )
                setorderingProviderData(searchedData)
            } else {
                getOrderingProviderFilter()
            }
            setIsFirst(true)
        }
    }
    const handleClose = () => {
        setOpenModal(false)
        setLabId('')
        setPdf(null)
        setPatientId('')
        // setProvidertId('')
    }
    const handleOpenModal = (id, pdf) => {
        setPatientDetails(null)
        // setProviderDetails(null)
        setPatientId('')
        // setProvidertId('')
        setOpenModal(true)
        setLabId(id)
        setPdf(pdf)
    }
    const handleMatch = async () => {
        if (patientId && labId) {
            try {
                setLoading(true)
                setOpenModal(false)
                setAnchorEl(null)
                // const payload = {
                //     provider_first_name: providerDetails.provider_fname,
                //     provider_middle_name: providerDetails.provider_mname,
                //     provider_last_name: providerDetails.provider_lname,
                //     provider_name: providerId,
                // }
                const res = await labOrderMatch(patientId, labId)
                if (res.data) {
                    setDataLabOrder((prevData) => prevData.filter((item) => item.id !== labId))
                    setTotal(total - 1)
                    setLabId('')
                    setPatientId('')
                    // setProvidertId('')
                    setPdf(null)
                    setLoading(false)
                    setPatientDetails(null)
                    notification(
                        `Lab order for ${patientDetails?.label} has been moved to Final Reported.`,
                        'success',
                    )
                }
            } catch (e) {
                setLabId('')
                setPatientId('')
                // setProvidertId('')
                setPdf(null)
                setPatientDetails(null)
                setLoading(false)
                notification(
                    `Lab order for ${patientDetails?.label} has been moved to Final Reported.`,
                    'error',
                )
            }
        }
    }
    const handleOpenAlertPopup = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseAlertPopup = () => {
        setAnchorEl(null)
    }
    return (
        <Grid container>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between">
                    <Grid
                        container
                        flex={1}
                        justifyContent="space-between"
                        spacing={1}
                        className={classes?.subtabDiv}>
                        <Grid item style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                            <Tabs
                                value={subValue}
                                onChange={handleChangeSubValue}
                                TabIndicatorProps={{ style: { display: 'none' } }}>
                                <CustomTab
                                    style={{ background: 'none', textTransform: 'capitalize' }}
                                    label="Ordered"
                                    value="ordered"
                                />
                                <CustomTab
                                    style={{ background: 'none', textTransform: 'capitalize' }}
                                    label="Final Reported"
                                    value="F"
                                />
                                <CustomTab
                                    style={{ background: 'none', textTransform: 'capitalize' }}
                                    label="Exceptions List"
                                    value="EX"
                                />
                            </Tabs>
                        </Grid>
                        {subValue !== 'EX' && (
                            <Grid
                                container
                                style={{ marginTop: '7px' }}
                                justifyContent="end"
                                flex={1}>
                                <Grid item style={{ width: 300 }}>
                                    {!activeRange && (
                                        <SingleCalenderComp
                                            placeHolder="Date of Order"
                                            onChange={(e) => {
                                                setSingleDate(e ? format(e, 'MM/dd/yyyy') : '')
                                                onChangeFilterData(
                                                    'date',
                                                    e ? [format(e, 'yyyy-MM-dd')] : [],
                                                )
                                            }}
                                            HideShowIcon={activeRange}
                                            value={SingleDate}
                                            onClear={() => {
                                                setSingleDate('')
                                            }}
                                        />
                                    )}
                                    <Box
                                        sx={{
                                            width: 0,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            position: 'absolute',
                                            // backgroundColor: 'primary.dark',
                                            // '&:hover': {
                                            //     backgroundColor: 'primary.main',
                                            //     opacity: [0.9, 0.8, 0.7],
                                            // },
                                        }}>
                                        <Paper>
                                            <Box
                                                sx={{
                                                    width: 0,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    position: 'absolute',
                                                    // backgroundColor: 'primary.dark',
                                                    // '&:hover': {
                                                    //     backgroundColor: 'primary.main',
                                                    //     opacity: [0.9, 0.8, 0.7],
                                                    // },
                                                }}>
                                                <Paper>
                                                    {activeRange && (
                                                        <DateRangeComp
                                                            style={{ marginTop: '20px' }}
                                                            placeHolder="Date of Order"
                                                            onChange={(e) => {
                                                                if (e?.startDate && e?.endDate) {
                                                                    setStartDate(
                                                                        format(
                                                                            e?.startDate,
                                                                            'MM/dd/yyyy',
                                                                        ),
                                                                    )
                                                                    setEndDate(
                                                                        format(
                                                                            e?.endDate,
                                                                            'MM/dd/yyyy',
                                                                        ),
                                                                    )
                                                                    setSingleDate('')
                                                                    onChangeFilterData('date', [
                                                                        format(
                                                                            new Date(e?.startDate),
                                                                            'yyyy-MM-dd',
                                                                        ),
                                                                        format(
                                                                            new Date(e?.endDate),
                                                                            'yyyy-MM-dd',
                                                                        ),
                                                                    ])
                                                                }
                                                            }}
                                                            activeRange={activeRange}
                                                            dateSeparator="-"
                                                            startDate={startDate}
                                                            defaultRange={SingleDate || null}
                                                            endDate={endDate}
                                                            onClear={() => {
                                                                setSingleDate('')
                                                                setStartDate('')
                                                                setEndDate('')
                                                                onChangeFilterData('date', [])
                                                            }}
                                                        />
                                                    )}
                                                </Paper>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <HtmlTooltip
                                        title={`Turn ${
                                            activeRange ? 'off' : 'on'
                                        } date range Search`}
                                        arrow
                                        placement="bottom"
                                        style={{
                                            background: 'white !important',
                                            color: '#303E4E',
                                        }}>
                                        <Box style={{ marginTop: '12px' }}>
                                            <AntSwitch
                                                onChange={() => {
                                                    setActiveRange(activeRange ? false : true)
                                                    if (startDate && endDate && activeRange) {
                                                        onChangeFilterData('date', [])
                                                        setStartDate('')
                                                        setEndDate('')
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </HtmlTooltip>
                                </Grid>
                            </Grid>
                        )}

                        <Grid item style={{ marginTop: '5px' }} onClick={handleFilterClick}>
                            <MultiFilter
                                filterOptionsArrayObject={filterOptionsArrayObject}
                                handleChange={handleChangeSelect}
                                handleClearSingleFilter={handleClearSingleFilter}
                                handleClearFilter={clearSearch}
                                totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.form_list_container}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sortingMode="server"
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        rows={dataLabOrder}
                        columns={subValue === 'EX' ? columnsEx : columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    currentPage={page}
                                    totalRecords={total}
                                    onPageChange={handlePageChange}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        onCellClick={subValue !== 'EX' && handleCellClick}
                    />
                </Box>
            </Grid>
            <Modal open={openModal} onClose={handleClose}>
                <div className="lab-modal-container">
                    <header>
                        <span>Viewing Unmatched Results</span>
                        <CloseIcon
                            style={{
                                color: 'gray',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        />
                    </header>

                    <main>
                        <Divider orientation="horizontal" className="divider_height" flexItem />

                        <div
                            style={{
                                width: '100%',
                                marginTop: '3px',
                                marginBottom: '3px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}>
                            <div style={{ width: '48%' }}>
                                <SearchPatientInput
                                    value={patientId}
                                    handleAddRow={(value) => {
                                        setPatientId(value?.id)
                                        // console.log(value)
                                        setPatientDetails(value)
                                    }}
                                    patientStoreIconStyle={{
                                        position: 'absolute',
                                        bottom: 4,
                                        right: 5,
                                    }}
                                    placeholder="Select Patient to match result"
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                {/* <SearchCHCProviderInput
                                    placeholder="Select Provider"
                                    value={providerId}
                                    handleAddRow={(value) => {
                                        setProvidertId(value?.id)
                                        // console.log(value)
                                        setProviderDetails(value?.provider_details)
                                    }}
                                /> */}
                            </div>
                        </div>

                        <Divider orientation="horizontal" className="divider_height" flexItem />
                        <div
                            style={{
                                width: '100%',
                                height: '400px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            {pdf ? (
                                <iframe
                                    src={pdf}
                                    width="100%"
                                    height="390px"
                                    style={{ userSelect: 'none' }}
                                />
                            ) : (
                                <div style={{ textAlign: 'center' }}>Lab Report Not Found !!</div>
                            )}
                        </div>
                    </main>
                    <footer>
                        <Button
                            variant="contained"
                            className="white-btn"
                            // disabled={!selectedUser}
                            onClick={() => {
                                setLabId('')
                                setOpenModal(false)
                            }}>
                            <span>Cancel</span>
                        </Button>
                        <Button
                            variant="contained"
                            className="blue-btn"
                            disabled={!patientId}
                            onClick={(e) => {
                                handleOpenAlertPopup(e)
                            }}>
                            <span>Confirm</span>
                        </Button>
                        <CustomAlertPopOver
                            anchorEl={anchorEl}
                            handleClose={handleCloseAlertPopup}
                            disableOutsideClose={true}
                            title="Confirm Patient Match"
                            bodyText={`You are matching this lab with: ${patientDetails?.label} ${patientDetails?.birthDate} - ${patientDetails?.phoneNumber}`}
                            confirmButtonName={'Match'}
                            cancelButtonName={`Cancel`}
                            confirmButtonColor={'#5571c6'}
                            onSuccess={handleMatch}
                        />
                    </footer>
                </div>
            </Modal>
        </Grid>
    )
}
