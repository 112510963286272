import { Typography, Box } from '@material-ui/core'
import React from 'react'
import CustomButton from '../../shared/Elements/CustomButton'

const Review = ({ handleClick, read_only }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
            }}>
            <Typography variant="h6" disabled={read_only}>
                You can preview the document as a PDF here.
            </Typography>
            <Box>
                <CustomButton
                    color="white"
                    label="Preview"
                    onClick={handleClick}
                    disabled={read_only}
                />
            </Box>
        </Box>
    )
}

export default Review
