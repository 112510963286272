import React, { useRef, useState, useEffect } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import _, { isEmpty } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import * as yup from 'yup'

import { getAccountById } from 'services/Calendar'
import { DEANumberRegex, USPhoneRegex } from 'utilities/regex'

import Staff from './Views/Staff'
import ProviderAccountInformation from '../ProviderAccountInformation'
import LoadingPage from 'utilities/loading-page'
import Routes from 'constants/routes'
import { Footer } from 'shared/elements/FormItem/Footer'
import { INITIAL_VALUES } from './schema'
import { createAccount, updateAccount } from 'services/Calendar/index'
import { useNotification } from 'shared/elements/Notification'

import './styles.scss'
import { getListSpecialties } from 'services/Setting'

const useStyles = makeStyles({
    container: {
        background: 'white',
        height: 'calc(100% - 40px)',
        padding: '16px 24px',
        overflowY: 'auto',
        overflowX: 'auto',
    },
    form: {
        position: 'relative',
        // marginTop: 8,
        // height: 'calc(100% - 40px)',
        flexWrap: 'nowrap',
        // overflowY: 'auto',
        // overflowX: 'auto',
        alignItems: 'center',
        borderRadius: 10,
        padding: '10px 8px',
    },
    text_back: {
        color: '#5571C6',
        marginLeft: 8,
    },

})

yup.addMethod(yup.array, 'unique', function (message) {
    return this.test('unique', message, function (list) {
        const mapper = (x) => x.id
        const hashSet = list.reduce((obj, item) => {
            if (mapper(item) !== undefined && obj[mapper(item)] === undefined)
                obj[mapper(item)] = item.key
            return obj
        }, {})

        const isUnique = list.length === Object.keys(hashSet).length
        if (isUnique) {
            return true
        }

        const idx = list.findIndex(
            (item, i) => hashSet[mapper(item)] !== undefined && item.key !== hashSet[mapper(item)],
        )
        if (idx >= 0)
            return this.createError({
                path: `location_id[${idx}].id`,
                message,
            })
        return true
    })
})
yup.addMethod(yup.array, 'uniqueIds', function (message) {
    return this.test('uniqueIds', message, function (value) {
        if (!value) {
            return true;
        }
        const ids = new Set();
        for (let i = 0; i < value.length; i++) {
            const item = value[i]
            if (ids.has(item.id)) {
                return this.createError({ path: `taxonomy_code[${i}].id`, message })
            }
            ids.add(item.id)
        }
        return true;
    });
});
yup.addMethod(yup.array, 'uniqueDEA', function (message) {
    return this.test('uniqueDEA', message, function (value) {
        if (!value) {
            return true;
        }
        const ids = new Set();
        for (let i = 0; i < value.length; i++) {
            const item = value[i]
            if (ids.has(item)) {
                return this.createError({ path: `dea_number[${i}]`, message })
            }
            ids.add(item)
        }
        return true;
    });
});
function UserSecurityDialog() {
    const classes = useStyles()
    const formRef = useRef(null)
    const history = useHistory()

    const location = useLocation()
    const { id: accountId } = useParams()
    const notification = useNotification()

    const [accountData, setAccountData] = useState(INITIAL_VALUES)
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [specialityOptions, setSpecialityOptions] = React.useState([])
    const [previousPCPractices, setPreviousPCPractices]= React.useState([])

    const goBack = () => history.push(Routes.SETTINGS.USER_SECURITY)

    const handleSubmit = async (values) => {
        const currentSpeciality = specialityOptions?.filter(item => item?.value === values?.specialities)
        const payload = {
            ...values,
            first_name: values.first_name.trim().replace(/\s+/g, ' '),
            last_name: values.last_name.trim().replace(/\s+/g, ' '),
            location_id: values.location_id
                .map((location) => location.id)
                .filter((locationId) => !!locationId),
            prescribing_license: values.prescribing_license[0] === "" ? [] : values.prescribing_license,
            taxonomy_code: values.taxonomy_code[0].id === "" ? [] : values.taxonomy_code,
            states: values.states[0].state === "" ? [] : values.states,
            specialities: currentSpeciality
        }
        if (payload.id) {
            setIsSubmitting(true)
            const updatedValue = values.provider_clinical_practices.map(newItem => {
                 let prevItem ={} ;
                 if(newItem?.id){
                    prevItem = previousPCPractices.find(prev => prev.id === newItem.id)
                 }
                if(newItem?.id){  
                    const isUpdated = prevItem.id && (newItem.clinical_practice_id !== prevItem.clinical_practice_id || newItem.kareo_id !== prevItem.kareo_id)
                    if (isUpdated) {
                        return { ...newItem, clinical_practice_id:`${newItem.clinical_practice_id}`, is_update: true };
                    } else if(prevItem.id) {
                        return newItem;
                    } 
                }
                return { ...newItem, clinical_practice_id:`${newItem.clinical_practice_id}`, is_create: true }
            });
            const deletedItems = previousPCPractices.filter((prevItem) => !values.provider_clinical_practices.find((newItem) => newItem.id === prevItem.id))
            const newProvider_clinical_practices = [...updatedValue, ...deletedItems.map((item) => ({ id: item.id, is_delete: true }))]
            const newPayload = {
                ...values,
                first_name: values.first_name.trim().replace(/\s+/g, ' '),
                last_name: values.last_name.trim().replace(/\s+/g, ' '),
                location_id: values.location_id
                    .map((location) => location.id)
                    .filter((locationId) => !!locationId),
                prescribing_license: values.prescribing_license[0] === "" ? [] : values.prescribing_license,
                taxonomy_code: values.taxonomy_code[0].id === "" ? [] : values.taxonomy_code,
                states: values.states[0].state === "" ? [] : values.states,
                specialities: currentSpeciality,
                provider_clinical_practices: newProvider_clinical_practices
            }
            updateAccount({
                id: payload.id,
                payload: newPayload,
            })
                .then(() => {
                    notification("User's account updated successfully!", 'success')
                    // FIXME:
                    // if (
                    //     localStorage
                    //         .getItem('name')
                    //         .trim() ===
                    //     userSecurityModal.name
                    // ) {
                    //     localStorage.setItem(
                    //         'name',
                    //         `${payload.first_name} ${payload.last_name}`,
                    //     )
                    //     location.reload()
                    // }

                    setIsSubmitting(false)
                    goBack()
                })
                .catch((error) => {
                    setIsSubmitting(false)

                    error?.data?.data?.length > 0 &&
                        error?.data?.data?.forEach((item) => {
                            notification(
                                item.error ||
                                error?.data?.message ||
                                "User's account didn't create successfully!",
                                'error',
                            )
                        })
                })
        } else {
            setIsSubmitting(true)
            createAccount(payload)
                .then(() => {
                    notification("User's account created successfully!", 'success')
                    setIsSubmitting(false)
                    goBack()
                })
                .catch((error) => {
                    setIsSubmitting(false)

                    error?.data?.data?.length > 0 &&
                        error?.data?.data?.forEach((item) => {
                            notification(
                                item.error ||
                                error?.data?.message ||
                                "User's account didn't create successfully!",
                                'error',
                            )
                        })
                })
        }
    }
    const setNewFormatTaxonomy_code = (value) => {
        if (Array.isArray(value)) {
            if (typeof value[0] === 'string') {
                return value.map((item) => ({
                    id: item,
                    label: item,
                    primary: false,
                }))
            } else if (typeof value[0] === 'object') {
                return value
            }
        } else if (typeof value === 'string') {
            return [
                {
                    id: value,
                    label: value,
                    primary: false,
                },
            ]
        }
    }
    useEffect(() => {
        ; (async () => {
            try {
                if (accountId) {
                    setIsLoading(true)
                    const response = await getAccountById(accountId)
                    setIsLoading(false)
                    let locations = response?.data?.location_id?.map((id, index) => ({
                        id,
                        key: index,
                    }))
                    if (!locations || _.isEmpty(locations)) {
                        locations = INITIAL_VALUES.location_id
                    }
                    setAccountData({
                        ...response.data,
                        rcopia_member_type: response?.data?.rcopia_member_type ? response?.data?.rcopia_member_type : INITIAL_VALUES.rcopia_member_type,
                        taxonomy_code: response?.data?.taxonomy_code.length > 0 ? setNewFormatTaxonomy_code(response?.data?.taxonomy_code) : INITIAL_VALUES.taxonomy_code,
                        location_id: locations,
                        is_enable_eprescribe: response?.data?.is_enable_eprescribe === "true" ? true : false,
                        dea_number: response?.data?.dea_number.length > 0 ? response?.data?.dea_number : INITIAL_VALUES.dea_number,
                        states: response?.data?.states.length > 0 ? response?.data?.states : INITIAL_VALUES.states,
                        specialities: response?.data?.specialities[0]?.value || '',
                        provider_clinical_practices: response?.data?.provider_clinical_practices?.length > 0 ? response?.data?.provider_clinical_practices : INITIAL_VALUES.provider_clinical_practices,
                        prescribing_license: response?.data?.prescribing_license?.length > 0 ? response?.data?.prescribing_license : INITIAL_VALUES.prescribing_license
                    })
                    if(response?.data?.provider_clinical_practices?.length > 0){
                        setPreviousPCPractices(response?.data?.provider_clinical_practices)
                    }else{
                        setPreviousPCPractices(INITIAL_VALUES.provider_clinical_practices)
                    }
                }
            } catch (err) {
                setIsLoading(false)
                console.error('Cannot fetch user security', err)
            }
        })()
    }, [accountId])

    useEffect(() => {
        getListSpecialties()?.then(res => {
            const data = res?.data?.data?.map((item) => ({
                id: item.id,
                value: item.specialty,
                label: item.specialty,
                key: item.id,
            }))
            setSpecialityOptions(data)
        })

    }, [])

    const validationSchema = yup.object({
        first_name: yup.string().required('First name is required!'),
        last_name: yup.string().required('Last name is required!'),
        phone_number: yup
            .string()
            .matches(USPhoneRegex, 'Invalid phone number!')
            .required('Phone is required!'),
        location_id: yup
            .array()
            .of(
                yup.object({
                    id: yup.string().required('This field is required!'),
                    key: yup.number(),
                }),
            )
            .unique('Duplicated Location')
            .test({
                message: 'At least one Location is specified',
                test: (arr) => arr.length > 0,
            }),

        department_id: yup.string(),
        rcopia_user_role: yup.string().when('is_enable_eprescribe', {
            is: true,
            then: yup.string().required('Role is required!'),
        }),
        rcopia_member_type: yup.string().when('is_enable_eprescribe', {
            is: true,
            then: yup.string().required('Member type is required!'),

        }),
        // .required('Department is required!'),
        role: yup.string().required('Security Role is required!'),
        email: yup.string().email('Invalid email!').required('Email is required!'),

        // Provider Account expanded form
        provider_account: yup.bool(),
        // federal_tax_id: yup
        //     .string()
        //     .when('provider_account', {
        //         is: true,
        //         then: yup
        //             .string()
        //             .required('Federal Tax ID is required'),
        //     }),
        specialities: yup.string(),
        taxonomy_code: yup
            .array()
            .when('provider_account', {
                is: true,
                then: yup.array().of(
                    yup.object({
                        id: yup.string().required('This field is required!'),
                        label: yup.string(),
                        isPrimary: yup.boolean(),
                    }),
                ),
                // .test('atLeastOnePrimary', 'At least one Taxonomy Code is Primary.', function (value) {
                //     if (!value) return false;
                //     return  value.some((item) => item.isPrimary === true);
                //   }),
            })
            .uniqueIds('Taxonomy Code must be unique'),
        // states: yup.array().when('provider_account', {
        //     is: true,
        //     then: yup
        //         .array()
        //         .of(
        //             yup.object().shape({
        //                 state: yup.string().required('This field is required!'),
        //                 // type: yup.string().required('This field is required!'),
        //                 number: yup.string().required('This field is required!'),
        //             }),
        //         )
        //         .test({
        //             message: 'At least one Location is specified',
        //             test: (arr) => arr.length > 0,
        //         }),
        // }),
        provider_clinical_practices: yup.array().of(
                    yup.object().shape({
                        clinical_practice_id: yup.string().required('This field is required!'),
                        kareo_id: yup.string().required('This field is required!'),
                    }),
                )
        ,
        // kareoId: yup.string().when('provider_account', {
        //     is: true,
        //     then: yup.string().required('Kareo Id is required!'),
        // }),
        suffix: yup.string().when('provider_account', {
            is: true,
            then: yup.string().required('Suffix is required!'),
        }),
        dea_number: yup.array().when('provider_account', {
            is: true,
            then: yup
                .array()
                .of(
                    yup
                        .string()
                        .matches(
                            DEANumberRegex,
                            'DEA Number must consist of two letters, six numbers, and one check digit',
                        ),
                ),
        })
            .uniqueDEA('DEA Number must be unique'),
        prescribing_license: yup.array().when('provider_account', {
            is: true,
            then: yup
                .array()
                .of(
                    yup.object().shape({
                        number: yup.string().matches(
                            DEANumberRegex,
                            'DEA Number must consist of two letters, six numbers, and one check digit',
                        ),
                    }),
                )
        }),
        npi: yup.string().when('provider_account', {
            is: true,
            then: yup.string().length(10, 'NPI consists of 10 digits').required('NPI is required!'),
        }),
        // timezone: yup.string().when('provider_account', {
        //     is: true,
        //     then: yup.string().required('This is required!'),
        // }),
        // rcopia_user_name: yup.string().when('provider_account', {
        //     is: true,
        //     then: yup.string().required('RCOPIA ID is required!'),
        // }),
        // rcopia_user_name: yup.string().required('RCOPIA ID is required!'),
    })

    const formik = useFormik({
        initialValues: accountData,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    })

    /** Auto scroll into position of element whose validation does not meet */
    const validateForm = (errors) => {
        if (!isEmpty(errors)) {
            const field = Object.keys(errors)[0]
            let el

            if (Array.isArray(errors[field])) {
                const errorIndex = errors[field].findIndex((error) => !!error)

                el = formRef.current.querySelector(`[name="${field}_${errorIndex}"]`)
            } else el = formRef.current.querySelector(`[name="${field}"]`)

            if (el) {
                el.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                })
            }
        }
    }
    //heading title
    const headingTitle = () => {
        const pageTitle =
            formik.values.id && Boolean(location?.state?.backTitle)
                ? 'User Profile'
                : formik.values.id
                    ? 'Update Account'
                    : 'Create New Account'

        return <h1 className="page-title">{pageTitle}</h1>
    }
    if (isLoading) {
        return <LoadingPage />
    }

    return (
        <div ref={formRef} style={{ height: '100%' }}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                <Grid
                    container
                    onClick={() => (location?.state?.backTitle ? history.goBack() : goBack())}
                    style={{
                        cursor: 'pointer',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>
                        {location?.state?.backTitle || 'Back to User list'}
                    </Typography>
                </Grid>
                <Grid container className={`border-bottom`}>
                    {' '}
                    {headingTitle()}
                </Grid>

                <Grid container direction="column" className={classes.form}>
                    {location?.state?.backTitle && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}></div>
                    )}
                    <Grid item>
                        <Staff
                            specialityOptions={specialityOptions}
                            isViewOnly={Boolean(location?.state?.backTitle)}
                            touched={formik.touched}
                            errors={formik.errors}
                            values={formik.values}
                            formik={formik}
                            profile
                        />

                        {/* {formik.values.provider_account && (
                            <ProviderAccountInformation
                                values={formik.values}
                                touched={formik.touched}
                                errors={formik.errors}
                                setFieldValue={formik.setFieldValue}
                            />
                        )} */}

                        {!location?.state?.backTitle && (
                            <Footer
                                style={{
                                    marginTop: '1.5rem',
                                }}
                                loading={isSubmitting}
                                onCancel={goBack}
                                onOk={() => {
                                    formik.handleSubmit()
                                    validateForm(formik.errors)
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default UserSecurityDialog
