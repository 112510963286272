import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Dialog, FormControlLabel, FormLabel, IconButton, makeStyles } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import Routes from 'constants/routes'
import { useFormik } from 'formik'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import {
    allApiReffringProvider,
    apiRaferrals,
    apiStatusCodes,
    createSchedule,
    getLocations,
    getSpecialty,
    getVisitType,
} from 'services/Calendar'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { useNotification } from 'shared/elements/Notification'
import { INITIAL_VALUES } from './Schema'
import { validationSchema } from './validationSchema'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { uniqBy } from 'lodash'
import DatePickerInput from './DatePickerInput'
import { DATE_FORMAT, PATIENT_VISIT_TYPE } from './Constant'
import moment from 'moment'
import { ReferringProviderForm } from 'containers/ReferringProviders/View/ReferringProviderForm'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { HistoryNote } from './HistoryNote'
import { useDispatch, useSelector } from 'react-redux'
import RouteLeavingGuard from 'shared/elements/RouteLeavingGuard'
import { debounce } from 'lodash'
import useUnload from 'shared/hooks/useUnload'
import { ReactComponent as TrashIcon } from '../../../../../assests/icons/trash.svg'
import NoteField from 'containers/WorkQueues/component/NoteField'
import CheckboxOption from 'components/ClinicalNotes/reusable/CheckboxOptions'
import Divider from '@mui/material/Divider';
import { ReactComponent as ReferralScheduleIcon } from 'assests/icons/referral_schedule_icon.svg'
import SchedulerMenu from 'containers/SchedulerMenu'
import { getPatientNextAppointmentInWaitlist } from 'services/Patient/patient'
import { changeAppointment } from 'store/actions/schedule'
import { ButtonSpinner } from 'components/custom'
import CloseIcon from 'components/Consultation/icons/CloseIcon'
import { getPracticeById } from 'services/Setting'
import { ReactComponent as SelfSchedule } from 'assests/icons/selfSchedule.svg'

const { v4: uuidv4 } = require('uuid')
const useStyles = makeStyles(
    (theme) =>
    ({
        button_cancel: {
            background: '#303E4E !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
        },
        button_create: {
            background: '#5571C6 !important',
            color: 'white',
            fontWeight: 400,
            borderRadius: '8px !important',
            fontSize: '15px !important',
            width: '100%',
            textTransform: 'unset !important',
        },
        container: {
            // padding: '0 28px 40px 20px',
            position: 'relative',
            // height: 'calc(100vh - 70px)',
            // overflowY: 'auto',
            background: '#fff',
            borderRadius: "5px",
            border: "1px solid #E4E8EE",
        },
        text_back: {
            color: '#5571C6',
            marginLeft: 8,
            marginBottom: '1rem',
        },
        attempt:{
            '&:focus':{
                border: "1px solid red",
            }
        },
        header: {
            fontSize: 22,
            fontWeight: 500,
            color: '#303E4E',
        },
        required_text: {
            color: 'red',
        },
        item_ric: {
            background: '#FAFAFA',
            padding: "8px 16px 8px 10px",
            marginBottom: "10px",
            // borderRadius: '7px',
            '& .icon': {
                '& svg': {
                    width: '22px',
                    color: '#6c6c6c',
                },
            },
        },
        btn_anew: {
            fontSize: '14px',
            fontWeight: '400',
        },
        wrap_scroll_list: {
            marginTop: '13px',
            maxHeight: '469px',
            overflowY: 'auto',
        },
        adnewBtn: {
            display: 'flex',
            alignItems: 'center',
            width: 'max-content',
            color: '#0aab1d',
            fontSize: '13px',
            cursor: 'pointer',
            '& svg': {
                fontSize: '17px',
                paddingRight: '3px',
            },
        },
        wraphistoryNotes: {
            maxHeight: '120px',
            overflowY: 'auto',
        },
        historyNote: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '7px',
        },
        hLeft: {
            width: 'calc(100% - 100px)',
            '& span': {
                fontWeight: '500',
            },
        },
        hRight: {
            width: '100px',
            cursor: 'pointer',
            '& .MuiTypography-root': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '500',
                '& svg': {
                    fontSize: '23px',
                },
            },
        },
        fieldsetStyle: {
            paddingLeft: "25px",
            paddingRight: "25px",
            borderRadius: "4px",
            border: "1px solid #D0D0D0"
        },
        required: {
            color: "#E81A1A"
        },
        formLabel: {
            color: "#303E4E",
            fontFamily: "Rubik",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "140%",
            margin: "7px 0"
        },
        legendStyle: {
            color: "#303E4E",
            fontFamily: "Rubik",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px"
        },
        statusWidget: {
            display: "inline-flex",
            height: "25px",
            padding: "4px 12px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            flexShrink: 0,
            borderRadius: "5px",
            background: "#E9ECF1",
            marginLeft: "15px",
            color: "#667689"
        },
        schedule_button: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5,
            width: 105,
            height: 25,
            borderRadius: 5,
            backgroundColor: '#5571C6',
            cursor: 'pointer',
            '& p': {
                color: '#fff !important',
                fontFamily: 'Rubik !important',
                fontSize: '15px !important',
                fontWeight: '400 !important',
                lineHeight: 'normal !important'
            }
        },
        scheduleFormDialog: {
            '& .MuiDialog-container .MuiPaper-root': {
                maxWidth: '1150px !important',
                height: '100% !important',
            },
            '& .appointment-booking-container': {
                overflowY: 'hidden'
            }
        },
        scheduleModalCloseIconButton: {
            '& .MuiIconButton-label svg': {
                height: '12px !important',
                width: '12px !important',
                color: '#303e4e !important'
            }
        },
        inputNumber: {
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              '-webkit-appearance': 'inner-spin-button !important',
              display: 'block !important',
              opacity: '1 !important',
            },
            // '-moz-appearance': 'textfield  !important',
          }
    }),
)

const INNIT_DEFAULT_R_I_C = [
    // {
    //     label: 'Consent form collected',
    //     checked: false,
    // },
    // {
    //     label: 'Medical intake forms collected',
    //     checked: false,
    // },
    // {
    //     label: 'Medical records collected',
    //     checked: false,
    // },
    // {
    //     label: 'Eligibility confirmed',
    //     checked: false,
    // },
    // {
    //     label: 'Payment guarantor confirmed',
    //     checked: false,
    // },
    // {
    //     label: 'Prior authorization obtained',
    //     checked: false,
    // },
]

const historyNote = [
    {
        provider: 'K.S',
        date: new Date(),
        content: 'Lorem...',
    },
    {
        provider: 'K.S',
        date: new Date(),
        content: 'Lorem...',
    },
    {
        provider: 'K.S',
        date: new Date(),
        content: 'Lorem...',
    },
    {
        provider: 'K.S',
        date: new Date(),
        content: 'Lorem...',
    },
]
export const ReferralsForm = (props) => {
    //Hooks
    const classes = useStyles()
    const history = useHistory()
    const dispatch = useDispatch()
    const inputNumberRef=useRef(null);
    const notification = useNotification()
    const idUser = useSelector((state) => state['user'].userId)
    const timezone = useSelector((state) => state?.timezones?.tz?.key)
    //State
    const [isBlocking, setIsBlocking] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [initData, setInitData] = useState(INITIAL_VALUES)
    const { idReferral: referralsID, ...restParams } = useParams()
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [loaddingBtnSubmit, setLoadingBtnSubmit] = useState(false)
    const [specialOptions, setSpecialOptions] = useState([])
    const [locationOptions, setLocationOptions] = useState([])
    const [refferralIntakeChecklist, setRefferralIntakeChecklist] = useState([])
    const [showFormProvider, setShowFormProvider] = useState(false)
    const [contactAttempt,setContactAttempt]=useState(0);
    const [isLoadingPractice,setIsLoadingPractice]=useState(false);
    const [newTextRIC, setNewTextRIC] = useState('')
    const [clinicalPracticeData,setClinicalPracticeData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isSelfSchedule, setIsSelfSchedule] = useState(false)
    const loggedInUser = useSelector((state) => state?.login?.userData)
    const [popupHistoryNote, setPopupHistoryNote] = useState({
        index: -1,
        content: '',
        isProvider: false,
    })
    
    const [selectedStatus, setselectedStatus] = useState({})
    const [listNotes, setListNotes] = useState([])
    // const [changvalue, setchangvalue] = useState(false)
    const [checkAutoSave, setCheckAutoSave] = useState(false)
    const [newAppointmentFormOpen, setNewAppointmentFormOpen] = useState({
        status: false
    })
    // const [timezone, setTimeZone] = useState('')
    const [newAppointmentData, setNewAppointmentData] = React.useState({
        next_appointment_date: null,
        next_appointment_id: null,
    })
    const [loadingForm, setLoadingForm] = useState(false)

    const [listVisitType, setListVisitType] = useState([])

    const getListVisitType = async () => {
        try {
            const params = { page: 1, limit: 100000 }
            if (formik.values.specialty_id) {
                params.specialty_id = formik.values.specialty_id
            }
            const {
                data: {
                    visitTypeList: { data },
                },
            } = await getVisitType(params)
            setListVisitType(() => {
                const list = data.map((el) => ({
                    key: el.id,
                    value: el.post_code,
                    duration: el.duration,
                    color_tag: el.color_tag,
                }))
                return list
            })
        } catch (error) {
            notification('Something went wrong!', 'error')
        }
    }
    const getPracticeData = async(id) =>{
        try{
            const practiceData = await getPracticeById(id)
            setClinicalPracticeData(practiceData?.data)
        }catch(e){
            // console.log(e.message)
        }finally{
            setIsLoadingPractice(false);
        }
        
    }
    
    useEffect(() => {
        getListVisitType()
    }, [])

    const getAppoinmentDetails = async (id) => {
        setLoadingForm(true)
        const nextAppointmentDetails = await getPatientNextAppointmentInWaitlist(id)
        if (nextAppointmentDetails?.data?.lastAppointment?.resource) {
            const nextAppointmentProps = nextAppointmentDetails?.data?.lastAppointment?.resource

            const nextAppointmentExtensions = nextAppointmentProps?.extension[0]?.extension

            const appointmentRequiredExtensionFields = {}
            nextAppointmentExtensions?.forEach(nextAppointmentExtensionsItem => {
                const objValues = Object.values(nextAppointmentExtensionsItem)
                appointmentRequiredExtensionFields[objValues[0]] = objValues[1]
            })

            const appointmentParticipants = {}
            nextAppointmentProps?.participant?.forEach(participantItem => {
                const actor = participantItem?.actor?.reference?.split("/")[0]?.toLowerCase(),
                value = participantItem?.actor?.reference?.split("/")[1]
                appointmentParticipants[actor] = value
            })

            const result = {
                id: '',
                day: moment().format('dddd'),
                specialty_id: appointmentRequiredExtensionFields?.specialty_id || '',
                visit_type_id: appointmentRequiredExtensionFields?.visit_type_id || '',
                date: moment().format('YYYY-MM-DD'),
                start_time: moment().format('HH:mm'),
                // end_time: moment().format('HH:mm'),
                timezone: appointmentRequiredExtensionFields?.timezone || '',
                appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                reason_for_visit: formik.values?.reason,
                internal_note: '',
                location_id: appointmentParticipants?.location || '',
                duration: '',
                supervising_provider: '',
                is_require_translator: false,
                appointment_status: '',
                is_reoccurring_appointment: false,
                days: [],
                end_date: '',
                unit: '',
                once_every: '',
                end_type: '',
                practitioner_id: appointmentParticipants?.practitioner,
                patient_id: appointmentParticipants?.patient,
                note: '',
                noteView: 'no-view',
                notes: []
            }
            
            dispatch(
                changeAppointment({
                    id: -1,
                    values: result
                }),
            )
            setNewAppointmentFormOpen((prevValue) => ({
                ...prevValue,
                id: nextAppointmentProps?.id
            }))
            formik.setFieldValue('next_appointment_date', nextAppointmentProps.start || null)
            formik.setFieldValue('next_appointment_id', nextAppointmentProps.id || null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: nextAppointmentProps.start,
                next_appointment_id: nextAppointmentProps.id,
            })
            
        } else {
            formik.setFieldValue('next_appointment_date', null)
            formik.setFieldValue('next_appointment_id', null)
            setNewAppointmentData({
                ...newAppointmentData,
                next_appointment_date: null,
                next_appointment_id: null,
            })
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: formik.values?.reason,
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: restParams?.idPatient,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
        }
        setLoadingForm(false)
    }

    const handleScheduleFormSubmit = (values) => {
        if (values?.is_reoccurring_appointment) {
            values.reoccurring_appointment = {
                days: values.days,
                end_date: moment
                    .tz(values?.end_date || values?.date, 'YYYY-MM-DD HH:mm', timezone)
                    .utc()
                    .format(),
                unit: values?.unit === 'weekday' ? 'week' : values?.unit,
                once_every: values?.once_every,
            }
        }
        values.start = moment
            .tz(`${values?.date} ${values?.start_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.end = moment
            .tz(`${values?.date} ${values?.end_time}`, 'YYYY-MM-DD HH:mm', timezone)
            .utc()
            .set('seconds', 0)
            .format()
        values.appointment_for = 'patient'
        values.participants = [
            {
                status: 'accepted',
                role: 'Patient',
                participant_id: values?.patient_id,
            },
        ]

        const payload = {
            start: values?.start,
            end: values?.end,
            practitioner_id: values?.practitioner_id,
            timezone,
        }

        if (values.is_reoccurring_appointment) {
            payload.reoccurring_appointment = {
                ...values.reoccurring_appointment,
                unit: values.unit,
            }
        }
        setIsSubmitting(true)
        notification('Don\'t refresh the page while the appointment is being created!', 'warning')
        createSchedule(values).then((res) => {
            formik.setFieldValue('reason', values?.reason_for_visit)
            setIsSubmitting(false)
            setNewAppointmentFormOpen({ id: null, status: false })
            notification('Appointment successfully created!', 'success')
        }).catch((error) => {
            setIsSubmitting(false)
            notification(
                error?.data?.message || 'Appointment was not successfully created!',
                'error',
            )
        })
    }
    
    function newAppointmentModalToggle(start, end, duration, id) {
        if (!start && !end) {
            dispatch(
                changeAppointment({
                    id: -1,
                    values: {
                        id: '',
                        date: moment().format('YYYY-MM-DD'),
                        end_time: moment().format('HH:mm'),
                        start_time: moment().format('HH:mm'),
                        appointment_type: PATIENT_VISIT_TYPE.TELEHEALTH,
                        reason_for_visit: '',
                        internal_note: '',
                        specialty_id: '',
                        location_id: '',
                        duration: '',
                        visit_type_id: '',
                        patient_id: restParams?.idPatient,
                        practitioner_id: '',
                        supervising_provider: '',
                        is_require_translator: false,
                        appointment_status: '',
                        is_reoccurring_appointment: false,
                        days: [],
                        end_date: '',
                        unit: '',
                        once_every: '',
                        end_type: '',
                        timezone: timezone.locationTimezone,
                        notes: [],
                        note: '',
                        noteView: 'no-view',
                    },
                }),
            )
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                id,
            })
        } else {
            setNewAppointmentFormOpen({
                status: !newAppointmentFormOpen.status,
                start,
                end,
                duration,
                id,
            })
        }

        if (formik?.values?.patient_id) {
            getAppoinmentDetails(formik?.values?.patient_id)
        }
    }

    useUnload((e) => {
        e.preventDefault()
        e.returnValue = ''
    }, checkAutoSave)

    const deboundChangvalue = debounce(() => {
        setIsBlocking(true)
    }, 1000)

    function convertHexToRGBA(hex, a) {
        const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
        return `rgba(${r},${g},${b},${a})`
    }

    const hanldeUpdateNote = () => {
        const updateListNotes = [...listNotes]
        const isNote = updateListNotes[popupHistoryNote.index]
        if (isNote) {
            isNote.content = popupHistoryNote.content
            setListNotes(updateListNotes)
        }
    }

    const handleSubmit = async (values) => {
        if (!values?.location_id) delete values.location_id
        if (!values?.referring_provider_id) delete values.referring_provider_id
        if (!values?.specialty_id) delete values.specialty_id
        delete values.clinical_practice
        const payload = {
            ...values,
            received_date: moment(values.received_date)
                .set({
                    hour: 0,
                    minute: 0,
                })
                .utc()
                .format(),
            due_schedule: moment(values.due_schedule)
                .set({
                    hour: 0,
                    minute: 0,
                })
                .utc()
                .format(),
            created_date: moment(values.created_date)
                .set({
                    hour: 0,
                    minute: 0,
                })
                .utc()
                .format(),
            // notes: listNotes,
        }

        const listChecked = refferralIntakeChecklist.map((item) => ({
            label: item.label,
            checked: item.checked,
        }))

        if (!referralsID) {
            try {
                setLoadingBtnSubmit(true)
                const res = await apiRaferrals.careate({ ...payload,...(contactAttempt && {contact_attempt:contactAttempt}), checklist: listChecked })
                notification('Create Referral successfully !', 'success')
                if (res?.status === 200) {
                    history.push(
                        (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                            .replace(':idReferral', res?.data?.referral?.id)
                            .replace(':idPatient', res?.data?.referral?.patient_id)
                            .replace(':viewType', 'edit'),
                    )
                }
            } catch (error) {
                // console.log(error)
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
            }
        } else {
            try {
                setLoadingBtnSubmit(true)
                if (isConfirm) {
                    setLoadingSchedule(true)
                }
                await apiRaferrals.update(
                    {
                        ...payload,
                        ...(contactAttempt && {contact_attempt:contactAttempt}),
                        checklist: listChecked,
                    },
                    referralsID,
                )
                if (!checkAutoSave || isConfirm) {
                    notification('Update Referral successfully !', 'success')
                    // history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN)
                    history.push(
                        (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                            .replace(':idReferral', referralsID)
                            .replace(':idPatient', payload.patient_id)
                            .replace(':viewType', 'edit'),
                    )
                }
            } catch (error) {
                // console.log(error)
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingBtnSubmit(false)
                if (checkAutoSave) {
                    setCheckAutoSave(false)
                }
                setIsBlocking(false)
                setLoadingSchedule(false)
                clearTimeout(debounceSubmit.current)
            }
        }
    }

    const formik = useFormik({
        validationSchema,
        initialValues: initData,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    })

    const debounceSubmit = useRef(null)
    const handleAutoSave = () => {
        setCheckAutoSave(true)
        if (debounceSubmit.current) {
            clearTimeout(debounceSubmit.current)
        }
        debounceSubmit.current = setTimeout(() => {
            formik.handleSubmit()
        }, 1000 * 30)
    }

    // console.log(formik.values)

    const [refferingLocationList, setRefferingLocationList] = useState([])

    const [optionRefarralStatus, setOptionRefarralStatus] = useState([])

    const refetchRefferringProvider = async () => {
        try {
            const res = await allApiReffringProvider.getAll({ size: 1000 })
            setRefferingLocationList(
                res.data?.data.map((item) => ({
                    key: item.id,
                    value: `${item.first_name} ${item.last_name}`,
                    specialty: item.specialty?.specialty,
                    location: item.location?.name,
                })) || [],
            )
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }

    const handleNotesChange = React.useCallback(
        (e) => {
            e.preventDefault()
            let allNotesFinal = []
            if (referralsID === 0 || !referralsID) {
                if (formik.values.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik?.values?.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                }
            } else {
                if (formik.values?.noteView === 'no-view' && formik?.values?.note !== '') {
                    allNotesFinal = [
                        ...formik.values?.notes,
                        {
                            id: uuidv4(),
                            content: formik.values?.note,
                            created_at: moment(new Date()),
                            created_by: loggedInUser['custom:user_id'],
                            name: loggedInUser['name'],
                            state: 'Created note'
                        },
                    ]
                } else allNotesFinal = formik.values?.notes
            }
            formik.setFieldValue('notes', allNotesFinal)
            formik.setFieldValue('note', '')
        },
        [formik],
    )

    const getLocationExternal = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            if (locationExternal) {
                setLocationOptions(
                    locationExternal.map((item) => ({
                        key: item.id,
                        value: item.name,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    const getSpecialtyAll = async () => {
        try {
            const res = await getSpecialty({ size: 1000 })

            if (res.data?.data) {
                setSpecialOptions(
                    res.data?.data?.map((item) => ({
                        key: item.id,
                        value: item.specialty,
                    })),
                )
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    const getRefarralStatus = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'REFERRAL',
                limit: 100000,
            })
            setOptionRefarralStatus(
                res.data?.listStatusCode?.data.map((item) => ({
                    key: item.code,
                    value: item.name,
                    color: item.color,
                })),
            )
            const statusDefault = res?.data?.listStatusCode?.data?.find((el) => el.is_default)
            if (statusDefault && !formik.values.status) {
                formik.setFieldValue('status', statusDefault.code)
            }
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        if (!referralsID) {
            setRefferralIntakeChecklist(INNIT_DEFAULT_R_I_C)
        }
    }, [])

    useEffect(() => {
        if (formik.values.status) {
            refferalStatusSelecter(formik.values.status)
        }
    }, [formik.values.status])

    const handleDeleteRIC = (rowItem) => {
        const newRefferralIntakeCheck = refferralIntakeChecklist.filter(
            (item) => item.label !== rowItem.label,
        )
        setRefferralIntakeChecklist(newRefferralIntakeCheck)
    }
    const handleChangCheked = (rowItem, checked) => {
        const record = [...refferralIntakeChecklist]
        const index = record.findIndex((element) => element.label === rowItem.label)
        record[index] = {
            ...record[index],
            checked,
        }
        setRefferralIntakeChecklist(record)
    }
    const handleAddNew = () => {
        if (newTextRIC) {
            setRefferralIntakeChecklist([
                { label: newTextRIC, checked: false, ic_delete: true },
                ...refferralIntakeChecklist,
            ])
            setNewTextRIC('')
        }
    }

    const getRefferralById = async (id) => {
        setLoadingSchedule(true)
        try {
            const res = await apiRaferrals.getByID(id)
            const resData = res.data?.referral
            setIsSelfSchedule(resData?.is_self_schedule)
            // console.log(resData, 'checkresdata')
            setInitData({
                referring_provider_id: resData?.referring_provider?.id,
                status: resData?.status,
                patient_id: resData?.patient?.id,
                location_id: resData?.location?.id,
                specialty_id: resData?.specialty?.id,
                checklist: resData?.checklist,
                note: resData?.note,
                notes: resData?.notes?.reverse(),
                reason: resData?.reason,
                noteView: 'no-view',
                created_date: resData?.created_date ? moment(resData?.created_date) : moment(),
                received_date: moment(resData?.received_date),
                due_schedule: moment(resData?.due_schedule),
                date_appointment_first_offered: resData?.date_appointment_first_offered ? moment(resData?.date_appointment_first_offered) : null,
                patient_declined_first_appointment: resData?.patient_declined_first_appointment
            })
            setContactAttempt(resData?.contact_attempt);
            setListNotes(resData?.notes?.reverse() || [])

            const concatCheckList = [...resData?.checklist, ...INNIT_DEFAULT_R_I_C]
            const uniqCheckList = uniqBy(concatCheckList, (e) => e.label)
            setRefferralIntakeChecklist(uniqCheckList || [])
        } catch (error) {
            //
        } finally {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        if (referralsID) {
            getRefferralById(referralsID)
        }
        return () => abortController.abort()
    }, [referralsID])

    useEffect(() => {
        const abortController = new AbortController()
        Promise.allSettled([
            refetchRefferringProvider(),
            getLocationExternal(),
            getSpecialtyAll(),
            getRefarralStatus(),
        ])
        return () => abortController.abort()
    }, [])

    const refferalStatusSelecter = (statusValue) => {
        optionRefarralStatus.map((item, index) => {
            if (item.key === statusValue) {
                setselectedStatus(item)
            }
        })

    }

    const handleSubmitReferringProvider = async (payload) => {
        setIsSubmitting(true)
        try {
            const create = await allApiReffringProvider.create(payload)
            await refetchRefferringProvider()
            setShowFormProvider(false)
            notification('Referring Provider is created successfully!', 'success')
        } catch (error) {
            notification('Something went wrong !', 'error')
        } finally {
            setIsSubmitting(false)
        }
        // appointmentModalToggle(null)
    }
    const changeInput=(e)=>{
        if(e?.target?.value<0){
            setContactAttempt(0);
        }else{
            setContactAttempt(e?.target?.value);
        }  
    };
    const updateReferrals=async()=>{
        try {
            setLoadingBtnSubmit(true)
            if (isConfirm) {
                setLoadingSchedule(true)
            }
            await apiRaferrals.update(
                {
                    ...(contactAttempt && {contact_attempt:contactAttempt}),
                },
                referralsID,
            )
            if (!checkAutoSave || isConfirm) {
                notification('Update Contact Attempt Successfully !', 'success')
                // history.push(Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.MAIN)
                getRefferralById(referralsID)
            }
        } catch (error) {
            // console.log(error)
            notification('Something went wrong!', 'error')
        } finally {
            setLoadingBtnSubmit(false)
            if (checkAutoSave) {
                setCheckAutoSave(false)
            }
            setIsBlocking(false)
            setLoadingSchedule(false)
            clearTimeout(debounceSubmit.current)
        }
    }
    const keyDownEvent=(event)=>{
        if (event.key === 'Enter') {
            updateReferrals();
          }
    }
    
    useEffect(() => {
        inputNumberRef?.current?.addEventListener('keydown', keyDownEvent);
        return () => {
            inputNumberRef?.current?.removeEventListener('keydown', keyDownEvent)
        }
    }, [contactAttempt]);

    useEffect(() => {
        getAppoinmentDetails(restParams?.idPatient)
    }, [formik?.values?.reason])

    useEffect(()=>{
        setIsLoadingPractice(true);
        if(props.practiceId){
            formik.setFieldValue('clinical_practice',props.practiceId)
            getPracticeData(props.practiceId)
        }else{
            formik.setFieldValue('clinical_practice','')
            setClinicalPracticeData({})
        }
    },[props.practiceId]);

    return (
        <>
            <RouteLeavingGuard
                onConfirm={() => {
                    clearTimeout(debounceSubmit.current)
                    setIsBlocking(false)
                    setIsConfirm(true)
                    formik.handleSubmit()
                }}
                when={isBlocking}
                navigate={(location) => {
                    if (location) history.push(location?.pathname, location?.state)
                }}
                shouldBlockNavigation={(location) => {
                    // console.log({ pathname: location.pathname })
                    if (isBlocking) {
                        if (location.pathname === '/work-queues/referrals') {
                            // console.log('check')
                            return false
                        }
                        return true
                    }
                    return false
                }}
                yes="Yes"
                no="No"
                content="Please save your changes !"
            />
            <Grid container className={classes['container']}>
                <Grid container margin={'auto'}>
                    {loadingSchedule && (
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 1,
                                left: 0,
                            }}>
                            <CircularProgress />
                        </Box>
                    )}

                    <Grid container>
                        <Grid xs={12} style={{ paddingLeft: "31px", height: "50px", display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 20, width: '80%' }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{
                                        color: "#303E4E",
                                        fontFamily: "Rubik",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "140%",
                                    }}>Referral Status:</Typography>{formik.values.status ? <Box className={classes.statusWidget} style={{
                                        color: `${selectedStatus['color']}`,
                                        background: `${selectedStatus['color'] ? convertHexToRGBA(selectedStatus['color'], 0.2) : ""}`

                                    }}>{selectedStatus['value']}</Box> : ""}
                                </div>


                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography style={{
                                        color: "#303E4E",
                                        fontFamily: "Rubik",
                                        fontSize: "18px",
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        lineHeight: "140%",
                                    }}> 
                                    Contact Attempts: </Typography> 
                                    <Box className={classes.statusWidget} style={{padding: "4px 6px",gap: "0px"}}>
                                        <input type='number'
                                        disabled={loaddingBtnSubmit}
                                        className={`${classes.attempt} ${classes.inputNumber}`}
                                        style={{
                                        border: 'none',
                                        backgroundColor:'#E9ECF1',
                                        width:'30px',
                                        borderColor:'transparent',
                                        outline:'none'
                                       }}
                                       ref={inputNumberRef}
                                       onChange={changeInput}
                                       value={contactAttempt}
                                       defaultValue="0"
                                      />
                                      <span>{loaddingBtnSubmit && <CircularProgress size={"10px"}/>} </span> 
                                    </Box>
                                </div>
                                {isSelfSchedule &&
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <SelfSchedule style={{marginTop:'2px',marginRight:'3px'}}/>
                                        <Typography style={{
                                            color: "#303E4E",
                                            fontFamily: "Rubik",
                                            fontSize: "18px",
                                            fontStyle: "normal",
                                            fontWeight: "500",
                                            lineHeight: "140%",
                                        }}> Patient self schedule</Typography>
                                    </div>
                                }
                            </div>
                            

                            <Grid xs={4} style={{ height: "50px", display: "flex", alignItems: "center", justifyContent: 'flex-end', marginRight: 55, gap: 10 }}>
                                <div
                                    onClick={() => setNewAppointmentFormOpen((prevValue) => ({...prevValue, status: true}))}
                                    className={classes.schedule_button}>
                                    <ReferralScheduleIcon />
                                    <Typography>Schedule</Typography>
                                </div>

                                {newAppointmentData?.next_appointment_date && <Typography style={{
                                    color: '#667689',
                                    fontFamily: 'Rubik',
                                    fontSize: 16,
                                    fontWeight: 400
                                }}> 
                                    Next Appt: {moment(newAppointmentData?.next_appointment_date).format('MM/DD/YYYY')}
                                </Typography>}
                            </Grid>
                        </Grid>

                        <Divider style={{ width: '100%', marginBottom: 10, borderColor: '#E4E8EE' }} />

                        <div style={{ padding: '0 20px 40px 20px', overflowY: 'auto' }}>
                            <Grid item xs={12}>
                                <fieldset className={classes['fieldsetStyle']}>
                                    <legend className={classes.legendStyle}>Referral Information </legend>
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        spacing={2}
                                        alignItems="flex-start">
                                        <Grid item xs={3}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Referring Provider<span className={classes['required']}>*</span>
                                            </FormLabel>
                                            <Select
                                                required
                                                placeholder="Select Provider"
                                                name="provider"
                                                multiple={false}
                                                value={formik.values.referring_provider_id}
                                                displayEmpty={!formik.values.referring_provider_id}
                                                disabled={props.viewType === 'view'}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.referring_provider_id &&
                                                    Boolean(formik.errors.referring_provider_id)
                                                }
                                                helperText={
                                                    formik.touched.referring_provider_id &&
                                                    formik.errors.referring_provider_id
                                                }
                                                options={
                                                    (refferingLocationList) || []
                                                }
                                                onChange={(value) => {
                                                    formik.setFieldValue(
                                                        'referring_provider_id',
                                                        value,
                                                    )
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Referring Location
                                            </FormLabel>
                                            <Select
                                                placeholder="Select Location"
                                                name="location"
                                                multiple={false}
                                                value={formik.values.location_id}
                                                displayEmpty={!formik.values.location_id}
                                                disabled={props.viewType === 'view'}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.location_id &&
                                                    Boolean(formik.errors.location_id)
                                                }
                                                helperText={
                                                    formik.touched.location_id &&
                                                    formik.errors.location_id
                                                }
                                                options={
                                                    (locationOptions) || []
                                                }
                                                onChange={(value) => {
                                                    formik.setFieldValue('location_id', value)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} style={{
                                            position:'relative'
                                        }}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                            Clinical practice<span className={classes['required']}>*</span>
                                            </FormLabel>
                                            {isLoadingPractice?<Box sx={{ display: 'flex',top:'4.2em', right:'2em',position:'absolute',zIndex:99 }}>
                                            <CircularProgress size={'17px'}/>
                                         </Box>:''}
                                         <TextBox
                                            required
                                            placeholder="Clinical practice"
                                            name="practice"
                                            value={clinicalPracticeData.name}
                                            error={
                                                    formik.touched.clinical_practice &&
                                                    Boolean(formik.errors.clinical_practice)
                                            }
                                            helperText={
                                                    formik.touched.clinical_practice &&
                                                    formik.errors.clinical_practice
                                            }
                                            inputProps={{
                                                    maxLength: 35,
                                            }}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                         />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Referral Status<span className={classes['required']}>*</span>
                                            </FormLabel>
                                            <Select
                                                required
                                                placeholder="Select..."
                                                name="status"
                                                multiple={false}
                                                value={formik.values.status}
                                                displayEmpty={!formik.values.status}
                                                disabled={props.viewType === 'view'}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.status &&
                                                    Boolean(formik.errors.status)
                                                }
                                                helperText={
                                                    formik.touched.status && formik.errors.status
                                                }
                                                options={optionRefarralStatus || []}
                                                onChange={(value) => {
                                                    formik.setFieldValue('status', value)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Specialty
                                            </FormLabel>
                                            <Select
                                                placeholder="Select Specialty"
                                                name="specialty"
                                                multiple={false}
                                                value={formik.values.specialty_id}
                                                displayEmpty={!formik.values.specialty_id}
                                                disabled={props.viewType === 'view'}
                                                style={{
                                                    height: 40,
                                                }}
                                                error={
                                                    formik.touched.specialty_id &&
                                                    Boolean(formik.errors.specialty_id)
                                                }
                                                helperText={
                                                    formik.touched.specialty_id &&
                                                    formik.errors.specialty_id
                                                }
                                                options={
                                                    (specialOptions) || []
                                                }
                                                onChange={(value) => {
                                                    formik.setFieldValue('specialty_id', value)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Date Received
                                            </FormLabel>
                                            <DatePickerInput
                                                height="40px"
                                                valueDate={formik.values.received_date}
                                                disabled={props.viewType === 'view'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('received_date', e)
                                                    // formik.setFieldValue(
                                                    //     'due_schedule',
                                                    //     moment(e).add(3, 'days'),
                                                    // )
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                                maxDate={moment()}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Date Created
                                            </FormLabel>
                                            <DatePickerInput
                                                height="40px"
                                                valueDate={formik.values.created_date}
                                                disabled={props.viewType === 'view'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('created_date', e)
                                                    formik.setFieldValue(
                                                        'due_schedule',
                                                        moment(e).add(3, 'days'),
                                                    )
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                                // addDay={3}
                                                helperText={
                                                    formik.touched.created_date &&
                                                    formik.errors.created_date
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Date Due
                                            </FormLabel>
                                            <DatePickerInput
                                                disabled
                                                height="40px"
                                                valueDate={formik.values.due_schedule}
                                                onChange={(e) => {
                                                    formik.setFieldValue('due_schedule', e)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                                addDay={3}
                                                helperText={
                                                    formik.touched.due_schedule &&
                                                    formik.errors.due_schedule
                                                }
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={3}
                                            sx={{
                                                '& .MuiInputBase-root.Mui-disabled': {
                                                    color: '#667689',
                                                },
                                            }}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Date appointment first offered
                                            </FormLabel>
                                            <DatePickerInput
                                                height="40px"

                                                valueDate={formik.values.date_appointment_first_offered}
                                                onChange={(e) => {
                                                    formik.setFieldValue('date_appointment_first_offered', e)
                                                }}
                                                helperText={
                                                    formik.touched.date_appointment_first_offered &&
                                                    formik.errors.date_appointment_first_offered
                                                }
                                                placeholder="MM/DD/YYYY"
                                            />
                                            <CheckboxOption
                                                // disabled={isUpdating && !editable}
                                                // name={`${fieldName}.no_concern`}
                                                option={{
                                                    label: 'Patient declined first appointment date offered',
                                                    value: false,
                                                }}
                                                onChange={(e) => {
                                                    formik.setFieldValue('patient_declined_first_appointment', e.target.checked)
                                                }}
                                                checked={formik.values.patient_declined_first_appointment}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormLabel component="p" className={classes['formLabel']}>
                                                Reason for visit
                                            </FormLabel>
                                            <TextBox
                                                name="reason"
                                                sx={{
                                                    '& .MuiOutlinedInput-multiline': {
                                                        height: "auto"
                                                    }
                                                }}
                                                value={formik.values.reason}
                                                multiline
                                                minRows={4}
                                                placeholder="Enter reason for visit"
                                                disabled={props.viewType === 'view'}
                                                onChange={(value) => {
                                                    formik.setFieldValue('reason', value)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                                error={
                                                    formik.touched.reason &&
                                                    Boolean(formik.errors.reason)
                                                }
                                                helperText={
                                                    formik.touched.reason && formik.errors.reason
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                <fieldset className={classes['fieldsetStyle']}>
                                    <legend>Referral Checklist</legend>
                                    {refferralIntakeChecklist.length > 0 && (
                                        <Box className={classes['wrap_scroll_list']}>
                                            {refferralIntakeChecklist.map((item, index) => (
                                                <Box
                                                    key={index}
                                                    className={classes['item_ric']}>
                                                    <Stack
                                                        direction={'row'}
                                                        justifyContent={'space-between'}
                                                        alignItems={'center'}>
                                                        <FormControlLabel
                                                            style={{ marginLeft: "15px" }}
                                                            label={item.label}
                                                            control={
                                                                <CheckboxOption
                                                                    style={{ marginRight: "-10px" }}
                                                                    option={{
                                                                        value: item.label,
                                                                        label: '',
                                                                    }}
                                                                    color="primary"
                                                                    checked={item.checked}
                                                                    disabled={
                                                                        props.viewType ===
                                                                        'view'
                                                                    }
                                                                    onChange={(e) => {
                                                                        handleChangCheked(
                                                                            item,
                                                                            e.target
                                                                                .checked,
                                                                        )
                                                                        if (!isBlocking) {
                                                                            deboundChangvalue()
                                                                        }
                                                                        handleAutoSave()
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                        <Box
                                                            className="icon"
                                                            onClick={() => {
                                                                if (
                                                                    props.viewType !==
                                                                    'view'
                                                                ) {
                                                                    handleDeleteRIC(item)
                                                                }
                                                            }}>
                                                            <TrashIcon
                                                                fontSize="small"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Box>
                                                        {/* {item.ic_delete && (
                                                                    )} */}
                                                    </Stack>
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                    <Grid container>
                                        <Box sx={{
                                            display: "flex",
                                            width: "100%",
                                        }}>
                                            <TextBox
                                                sx={{
                                                    width: "100%"
                                                }}
                                                value={newTextRIC}
                                                placeholder="Enter new checklist requirement"
                                                disabled={props.viewType === 'view'}
                                                onChange={(value) => {
                                                    setNewTextRIC(value)
                                                    if (!isBlocking) {
                                                        deboundChangvalue()
                                                    }
                                                    handleAutoSave()
                                                }}
                                            />


                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'flex-start',
                                                    paddingLeft: "5px",
                                                    minHeight: '100%',
                                                    '& .MuiBox-root': {
                                                        fontSize: '16px',
                                                    },
                                                }}>
                                                <Box
                                                    className={classes['adnewBtn']}
                                                    onClick={() => {
                                                        if (props.viewType !== 'view') {
                                                            handleAddNew()
                                                        }
                                                    }}>
                                                    <AddCircleOutlineIcon />
                                                    Add new Item
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "14px" }}>
                                <fieldset className={classes['fieldsetStyle']}>
                                    <legend>Staff Notes</legend>
                                    <NoteField
                                        note={formik?.values?.note ?? ""}
                                        noteView={formik?.values.noteView}
                                        notes={formik?.values?.notes}
                                        onChange={formik?.setFieldValue}
                                        // onBlur={handleNotesChange}
                                        handleNotesChange={handleNotesChange}
                                    />
                                </fieldset>
                            </Grid>

                            <Grid item xs={12} style={{ border: "1px solid #CFCFCF", height: "1px", marginTop: "10px" }}>
                            </Grid>

                            <Grid
                                container
                                item
                                justifyContent="flex-end"
                                direction="row"
                                spacing={2}
                                sx={{ marginTop: '20px !important' }}>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        disabled={props.viewType === 'view'}
                                        className={classes['button_cancel']}
                                        onClick={() =>
                                            history.push(
                                                Routes.WORK_QUEUES.MAIN +
                                                Routes.WORK_QUEUES.REFERRALS.MAIN,
                                            )
                                        }>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <LoadingButton
                                        loading={loaddingBtnSubmit}
                                        loadingPosition="start"
                                        startIcon={<></>}
                                        variant="contained"
                                        disabled={props.viewType === 'view'}
                                        className={classes['button_create']}
                                        onClick={() => {
                                            setCheckAutoSave(false)
                                            formik.handleSubmit()
                                        }}>
                                        {!referralsID ? 'Save Changes' : 'Save Changes'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </Grid >
            {showFormProvider && (
                <ReferringProviderForm
                    open={showFormProvider}
                    optionServiceLocation={locationOptions}
                    optionSpecialty={specialOptions}
                    loading={isSubmitting}
                    onModalClose={() => setShowFormProvider(false)}
                    onSubmit={(payload) => handleSubmitReferringProvider(payload)}
                />
            )}

            {
                popupHistoryNote.index >= 0 && (
                    <HistoryNote
                        open={popupHistoryNote.index >= 0 ? true : false}
                        content={popupHistoryNote.content}
                        isProvider={popupHistoryNote.isProvider}
                        onChangContent={(content) => {
                            setPopupHistoryNote({
                                ...popupHistoryNote,
                                content,
                            })
                        }}
                        handleClose={() => {
                            hanldeUpdateNote()
                            setPopupHistoryNote({
                                index: -1,
                                content: '',
                                isProvider: false,
                            })
                        }}
                    />
                )
            }

            <Dialog
                open={newAppointmentFormOpen.status}
                sx={{
                    height: '100%',
                    '& .MuiDialog-paperFullWidth': {
                        maxWidth: '1200px',
                        height: '100%',
                        overflow: 'auto',
                    },
                    '& .MuiDialog-paperWidthSm': {
                        maxWidth: '100%'
                    }
                }}
                className={classes.scheduleFormDialog}
                fullWidth={true}
                onClose={newAppointmentModalToggle}>
                
                <div style={{ overflow: 'auto'}}>
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        borderBottom: '1px solid #dddd'
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '58%'
                        }}>
                            <Typography style={{
                                fontSize: 18,
                                padding: '1rem 0px',
                                fontWeight: 900,
                                color: '#303e4e',
                            }}>Schedule Appointment</Typography>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '40%'
                        }}>
                            <IconButton
                                onClick={newAppointmentModalToggle}
                                className={classes.scheduleModalCloseIconButton}>
                                <CloseIcon style={{ height: '10px !important', width: '10px !important', color: '#303e4e' }} />
                            </IconButton>
                        </div>
                        
                    </div>
                    <SchedulerMenu
                        isPopupType={true}
                        tab={1}
                        isScheduler={true}
                        open={newAppointmentFormOpen.status}
                        appointmentModal={newAppointmentFormOpen}
                        loading={loadingForm}
                        onModalClose={newAppointmentModalToggle}
                        setIsLoading={setLoadingForm}
                        onSubmit={handleScheduleFormSubmit}
                        // customHeader={true}
                        // customHeaderName="Appointment Record"
                        locationOptionsData={locationOptions.map((item) => ({
                            key: item.id,
                            value: item.name,
                        }))}
                        visitTypeOptionsData={listVisitType.map((item) => ({
                            key: item.id,
                            value: item.post_code,
                            duration: item.duration,
                        }))}
                    />
                </div>
                
            </Dialog>

        </>
    )
}
