import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'

import CustomTabs from 'components/shared/Elements/CustomTabs'
import { Button as ButtonCustom } from 'components/custom'
import Routes from 'constants/routes'

import PatientInfor from '../PatientInfor'
import { CurrentAllergyList } from './CurrentAllergyList'
import { AuditLogs } from '../MedicalProblems/AuditLogs'
import { useStyles } from '../MedicalProblems/style'

import { AllergiesForm } from './NewAllergy'
import AntSwitch from 'components/shared/Elements/AntSwitch'
import { drugAllergyStatus, getPatient } from 'services/Patient/patient'

const sectionsTabs = [
    {
        key: 234234,
        label: 'Current Allergy List',
        _id: 1,
    },
    {
        key: 34432,
        label: 'Audit Logs',
        _id: 2,
    },
]

interface Props {
    nonEditable: Boolean
    isInClinicalNote: Boolean
    patient_Id: string
    encounter_id: string
    afterActionOk?: () => void
    afterComplete?: () => void
}

const Allergies = ({ isInClinicalNote, patient_Id, encounter_id, afterActionOk, afterComplete, nonEditable=false }: Props) => {
    const classes = useStyles()

    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const [patientId, setPaitentId] = useState(encounter_id ? patient_Id : id )

    const [loading, setLoading] = useState<boolean>(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [allergyModal, setAllergyModal] = useState<{
        status: boolean
        itemId: string | null | undefined
    }>({
        status: false,
        itemId: null,
    })

    const [cycleCount, setCycleCount] = useState(0)
    const [allergydata, setallergydatalength] = useState(0)
    const [flag, setFlag] = useState<boolean>(false)
    const [patientData, setPatientData] = useState<any>()

    // useEffect(() => {
    //     setPaitentId(patient_Id || id)

    // }, [patient_Id, id])

    useEffect(() => {
        if (patientId){
            patientDetails(patientId || id)
        }
    }, [flag, patientId])

    const allergyModalToggle = (forceReload: boolean = false, itemId?: string) => {
        setAllergyModal({
            status: !allergyModal.status,
            itemId,
        })

        if (forceReload) setCycleCount(cycleCount + 1)
    }

    const handleClickEdit = (allergyId: string) => {
        allergyModalToggle(false, allergyId)
    }

    // const handleRowClick = (params) => {
    //     console.log('paramsparams')
    //     changePassDialogToggle(params.row)
    // }
    const activeAllergyCheck = (data) => {
        if (data && !flag) {
            setallergydatalength(data)
        } else {
            setallergydatalength(0)
        }

    }

    afterActionOk = () => {
        patientDetails(patient_Id)
        afterComplete?.()
    }

    const allergyStatus = async (e) => {
        setLoading(true)
        await drugAllergyStatus(patientId, { "no_allergy": e.target.checked })
        setFlag(e.target.value)
        setLoading(false)
    }


    const patientDetails = async (id) => {
        setLoading(true)
        const patientData = await getPatient(id)
        setPatientData(patientData?.data)
        if (patientData) {
            setFlag(patientData?.data?.no_allergy)
        }
        setLoading(false)
    }


    return (
        <Grid
            container
            className={classes.container}
            direction="column"
            wrap="nowrap"
            style={{ padding: !isInClinicalNote ? '16px 24px' : '0' }}>
            {!isInClinicalNote && (
                <Grid
                    container
                    onClick={() =>
                        history.replace(
                            Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(
                                ':id',
                                patientId,
                            ),
                        )
                    }
                    style={{
                        cursor: 'pointer',
                        width: 'fit-content',
                    }}>
                    <KeyboardBackspaceRoundedIcon
                        style={{
                            color: '#5571C6',
                            fontSize: 18,
                        }}
                    />
                    <Typography className={classes.text_back}>Back to Patient Dashboard</Typography>
                </Grid>
            )}

            <Grid container style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.header}>Medication Allergy</Typography>
                        <Grid item>
                            <Grid container spacing={2} wrap={'nowrap'}>
                                <Grid item>
                                    <ButtonCustom
                                        tooltip="Add New Medication Allergy"
                                        variant="contained"
                                        color="primary"
                                        disabled={nonEditable}
                                        onClick={() => allergyModalToggle()}
                                        fullWidth>
                                        <AddIcon
                                            style={{
                                                fontSize: 20,
                                            }}
                                        />
                                    </ButtonCustom>
                                </Grid>
                                {!isInClinicalNote && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.btn_print}
                                            onClick={() => allergyModalToggle()}
                                            fullWidth>
                                            <LocalPrintshopOutlinedIcon
                                                style={{
                                                    fontSize: 18,
                                                    color: '#667689',
                                                    marginRight: 8,
                                                }}
                                            />
                                            Print
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <PatientInfor />
                    </Grid>
                )}

                {!isInClinicalNote && (
                    <Grid item xs={12}>
                        <CustomTabs
                            contents={sectionsTabs}
                            selectedTab={selectedTab}
                            onChangeTab={(tabId: number) => {
                                setSelectedTab(tabId)
                            }}
                        />
                    </Grid>
                )}
                {!isInClinicalNote && (
                    <Grid item xs={12} style={{ display: "flex", paddingLeft: 10 }}>
                        <AntSwitch onChange={allergyStatus} checked={flag ? true : false} disabled={(allergydata > 0 || loading) ? true : false} /><span style={{ marginLeft: "10px", fontSize: "13px" }}>No known drug for allergies</span>
                    </Grid>
                )}

                {selectedTab === 0 ? (
                    <CurrentAllergyList
                        patient_Id={patientId}
                        cycleCount={cycleCount}
                        onClickEdit={handleClickEdit}
                        afterActionOk={afterActionOk}
                        activeAllergyCheck={activeAllergyCheck}
                        flag={flag}
                        nonEditable={nonEditable}
                    />
                ) : (
                    <AuditLogs />
                )}

                {allergyModal.status && (
                    <AllergiesForm
                        patientId={patientId}
                        encounter_id={encounter_id}
                        allergyId={allergyModal.itemId}
                        open={allergyModal.status}
                        onClose={allergyModalToggle}
                        afterActionOk={afterActionOk}

                    />
                )}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state: { loader: { loading: boolean } }) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(Allergies)
