import * as actionType from '../actionType';
import {
    BOOKING_TYPE,
    APPOINTMENT_TYPE,
    specialityTypes,
    INTERECT_WITH_DR,
} from '../../constants/appointments';

const initialState = {
    errorMessage: '',
    specialities: [],
    clinicList: [],
    userDetails: {
        firstName: '',
        lastName: '',
        parentName: '',
        dob: '',
        gender: '',
        phoneNumber: '',
        emailId: '',
        userId: ''
    },
    selectedProviderDetails: {
        firstName: '',
        lastName: '',
        speciality: '',
        selectedDate: '',
        selectedTimeSlot: '',
        providerId: '',
        startTime: '',
        endTime: '',
        isFollowUp: false,
        groupTaxId: '',
        previousAppointmentDate: '',
        previousAppointmentSlot: '',
    },
    searchProviderData: {
        speciality: '',
        clinic: '',
        zipCode: '',
        visitType: '',
    },
    bookAppointmentData: {
        appointmentFlowType: BOOKING_TYPE.CHECKUP,
        appointmentType: APPOINTMENT_TYPE.TELE_CONSULTANT,
        specialityType: specialityTypes.NON_BEHAVIORAL,
        clinicName: '',
        bookingType: BOOKING_TYPE.CHECKUP,
        previousAppointmentId: '',
        providerIdOnFollowUp: '',
        patientType: INTERECT_WITH_DR.ADULT,
        selectedMinorId: '',
    },
    appointmentlist: [],
    appointmentcount: 0,
    isRescheduleAppointment: false,
    selectedAppointmentData: null,
    patientsearchlist: [],
    addpatientdata: [],
    providermarkStatus: [],
    selectedPatientDetails: null,
    selectedMinorDetails: null,
    clinicDetail: {},
};

const appointments = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_SPECIALITIES:
            return {
                ...state,
                specialities: action.value,
            };
        case actionType.SET_SELECTED_PROVIDER_DETAILS:
            return {
                ...state,
                selectedProviderDetails: action.value,
            };
        case actionType.SET_CLINIC_LIST:
            return {
                ...state,
                clinicList: action.value,
            };
        case actionType.SET_SEARCH_PROVIDER_DATA:
            return {
                ...state,
                searchProviderData: action.value,
            };
        case actionType.SET_APPOINTMENT_FLOW_TYPE:
            return {
                ...state,
                appointmentFlowType: action.value,
            };
        case actionType.SET_BOOK_APPOINTMENT_DATA:
            return {
                ...state,
                bookAppointmentData: action.value,
            };
        case actionType.SET_APPOINTMENTLIST:
            return {
                ...state,
                appointmentlist: action.value,
            };
        case actionType.SET_APPOINTMENTLIST_COUNT:
            return {
                ...state,
                appointmentcount: action.value,
            };
        case actionType.SET_APPOINTMENT_ERROR:
            return {
                ...state,
                errorMessage: action.value,
            };
        case actionType.SET_IS_RESCHEDULE_APPOINTMENT:
            return {
                ...state,
                isRescheduleAppointment: action.value,
            };
        case actionType.SET_SELECTED_APPOINTMENT_DATA:
            return {
                ...state,
                selectedAppointmentData: action.value,
            };
        case actionType.RESET_APPOINTMENTS_DATA:
            return {
                ...initialState,
            };
        case actionType.SET_PATIENTLIST:
            return {
                ...state,
                patientsearchlist: action.value,
            };
        case actionType.SET_ADDPATIENTLIST:
            return {
                ...state,
                addpatientdata: action.value,
            };
        case actionType.SET_MARK_CONSULTATION_STATUS:
            return {
                ...state,
                providermarkStatus: action.value,
            };
        case actionType.SET_SELECTED_PATIENT_DETAILS:
            return {
                ...state,
                selectedPatientDetails: action.value,
            };
        case actionType.SET_SELECTED_MINOR_DETAILS:
            return {
                ...state,
                selectedMinorDetails: action.value,
            };
        case actionType.SET_CLINIC_DETAILS:
            return {
                ...state,
                clinicDetail: action.value,
            };
        case actionType.LOGOUT: {
            return initialState;
        }
        case actionType.SET_USER_DETAILS: {
            return {
                ...state,
                userDetails: action.value
            }
        }
        default:
            return state;
    }
};
export default appointments;
