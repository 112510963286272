import { Grid, Typography } from '@material-ui/core'
import Routes from 'constants/routes'
import { getPsychotherapies } from 'containers/ClinicalNotes/api/services'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Loading } from 'shared/elements/Loading'
import { useNotification } from 'shared/elements/Notification'
import { usePatientDashboardStyles } from '../style'
import { Card } from '../view/Card'
import { PsychotherapyNoteForm } from '../view/PsychotherapyNote/PsychotherapyNoteForm'
import { NoData } from './NoData'

interface Props {
    onClick?: () => void
}

export const PsychotherapyNote = (props: Props) => {
    const classes = usePatientDashboardStyles()
    const history = useHistory()
    const { id: patientId } = useParams<{ id: string }>()
    const { idPatient: patientIdByRef } = useParams<{ idPatient: string }>()
    const [loading, setLoading] = useState(false)
    const notification = useNotification()
    const [psychotherapies, setPsychotherapies] = useState([])
    const [isAddingNew, setIsAddingNew] = useState(false)

    const hasPermission = useSelector((state: PermissionState) =>
        state.permission?.patient_accounts.find(
            (item) => item === 'view_psychotherapy_process_note',
        ),
    )

    const handleViewAll = () => {
        if (hasPermission) {
            history.push(Routes.PATIENT.PSYCHOTHERAPY.replace(':id', patientId || patientIdByRef))
        } else {
            notification('You do not have permission to view Psychotherapy', 'error')
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getDocuments()

        return () => abortController.abort()
    }, [])

    const getDocuments = async () => {
        try {
            setLoading(true)
            const resp = await getPsychotherapies({
                page: 1,
                patient_id: patientId || patientIdByRef,
            })

            setPsychotherapies(resp.data.data)
        } catch (ex) {
            notification('Cannot get list psychotherapy notes', 'error')
        }

        setLoading(false)
    }

    const Content = () => (
        <>
            {psychotherapies?.map((item, key) => {
                return (
                    <Grid
                        container
                        key={key}
                        justifyContent="space-between"
                        wrap="nowrap"
                        alignItems="center"
                        style={{
                            borderBottom: '1px solid #EFF2F5',
                            paddingBottom: 8,
                        }}>
                        <Grid
                            item
                            container
                            alignItems="center"
                            wrap="nowrap"
                            style={{
                                width: 'fit-content',
                            }}>
                            <Typography>
                                <span className={classes.title_clinical_reminder}>
                                    {item.practitioner_first_name + item.practitioner_last_name}
                                </span>
                            </Typography>
                        </Grid>
                        <Typography className={classes.tag_type_red}>
                            {moment(item?.updatedAt).format('D MMM YYYY')}
                        </Typography>
                    </Grid>
                )
            })}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Psychotherapy Note">
            <Grid
                container
                direction="column"
                alignItems="flex-start"
                style={{
                    gap: 8,
                    height: '100%',
                    overflow: 'auto',
                    paddingRight: 15,
                    position: 'relative',
                    flexWrap: 'nowrap',
                }}>
                <Loading visible={loading} />
                {psychotherapies.length ? <Content /> : <NoData />}
            </Grid>

            {isAddingNew && (
                <PsychotherapyNoteForm
                    patientId={patientId || patientIdByRef}
                    open={isAddingNew}
                    onCancel={(forceReload) => {
                        setIsAddingNew(false)

                        if (forceReload) setTimeout(() => getDocuments(), 800)
                    }}
                />
            )}
        </Card>
    )
}
