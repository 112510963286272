import { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-date-range'
import format from 'date-fns/format'
import './default.scss'
import './style.scss'
import { InputAdornment, TextField } from '@material-ui/core'
import CancelIcon from '@mui/icons-material/Cancel'
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'

const SingleCalenderComp = (props) => {
    // date state
    const [calendar, setCalendar] = useState('')
    // open close
    const [open, setOpen] = useState(false)
    // get the target element to toggle
    const refOne = useRef(null)

    useEffect(() => {
        document.addEventListener('keydown', hideOnEscape, true)
        document.addEventListener('click', hideOnClickOutside, true)
    }, [calendar])

    useEffect(() => {
        // event listeners
        setCalendar('')
        document.addEventListener('keydown', hideOnEscape, true)
        document.addEventListener('click', hideOnClickOutside, true)
    }, [props.HideShowIcon])

    useEffect(() => {
        document.addEventListener('keydown', hideOnEscape, true)
        document.addEventListener('click', hideOnClickOutside, true)
        return () => {
            document.removeEventListener('keydown', hideOnEscape, true)
            document.removeEventListener('click', hideOnClickOutside, true)
        }
    }, [props.value])

    const hideOnEscape = (e) => {
        if (e.key === 'Escape') {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }

    const handleSelect = (date) => {
        setCalendar(format(date, 'MM/dd/yyyy'))
        if (refOne.current) {
            setOpen(false)
            props.onChange(date)
        }
    }

    const clearData = (e) => {
        setCalendar('')
        props.onChange('')
        props.onClear()
        setOpen(false)
        e.stopPropagation()
    }

    const calvisibility = () => {
        setOpen(true)
    }
    return (
        <div className="calenderwrapper">
            <TextField
                style={{ width: 280 }}
                hiddenLabel
                id="filled-hidden-label-small"
                variant="outlined"
                size="small"
                placeholder={props.placeHolder ? props.placeHolder : 'Date of Service'}
                value={props.value}
                onclear={clearData}
                onClick={calvisibility}
                mask="__/__/____"
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {!props.value && !props.HideShowIcon ? (
                                <DateRangeIcon
                                    style={{
                                        cursor: 'pointer',
                                        color: 'grey',
                                        height: '20px',
                                        width: '20px',
                                    }}
                                />
                            ) : ((props.value && props.HideShowIcon) || props.value) && !props?.disabled? (
                                <CancelIcon
                                    style={{
                                        cursor: 'pointer',
                                        color: 'grey',
                                        height: '20px',
                                        width: '20px',
                                    }}
                                    onClick={clearData}
                                />
                            ) : (
                                ''
                            )}
                        </InputAdornment>
                    ),
                }}
                error={props?.error || false}
                helperText={props?.helperText || ''}
                disabled={props?.disabled}
            />
            <div ref={refOne}>
                {open && !props?.disabled && (
                    <Calendar
                        date={calendar ? new Date(calendar) : new Date()}
                        onChange={handleSelect}
                        className="calendarElement"
                        value={new Date()}
                    />
                )}
            </div>
        </div>
    )
}

export default SingleCalenderComp
