import React from 'react'
import {
    calculateBMI,
    convertHeightToFtAndInches,
    convertHeightToInches,
    convertWeight,
} from 'containers/ClinicalNotes/utils'

export const convertToFahrenheit = (celsius) => celsius * (9 / 5) + 32

export const convertToCelsius = (fahrenheit) => ((fahrenheit - 32) * 5) / 9

export const convertToCm = (feet, inches) => {
    const feetToCm = feet * 30.48 
    const inchesToCm = inches * 2.54 
    const totalCm = feetToCm + inchesToCm
    return totalCm
}

export const convertValues = (value, unit, sectionName) => {
    if (!value) return
    if (sectionName === 'weight') {
        return convertWeight(value, unit)
    }
    if (sectionName === 'height') {
        if (unit === 'ft/in') {
            const { ft, inches } = convertHeightToFtAndInches(value)
            return `${ft}/${inches}`
        }
        const valArr = value !== "----" && value?.split('/')
        const val = Array.isArray(valArr) ? convertHeightToInches(valArr[0], valArr[1]) : value
        return val
    }
    if (sectionName === 'temperature') {
        if (unit === '°C') {
            const val = convertToCelsius(value)
            return val?.toFixed(1)
        }
        return convertToFahrenheit(value).toFixed(0)
    }
}

export const getConvertedBMI = (height, heightUnit, weight, weightUnit) => {
    let updatedHeight = height
    let updatedWeight = weight
    if (heightUnit === 'ft/in') {
        const heightArr = height?.split('/')
        updatedHeight = convertHeightToInches(heightArr[0], heightArr[1])
    }

    if (weightUnit === 'kg') {
        updatedWeight = convertWeight(weight)
    }
    return calculateBMI(updatedHeight, updatedWeight)
}
