import { Box, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AddCircleOutlineOutlined } from '@material-ui/icons'
import Allergies from 'containers/PatientsSetting/view/Allergies'
import React, { useEffect } from 'react'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import CopyBar from 'components/shared/Elements/CopyBar'
import { useNotification } from 'shared/elements/Notification'
import { getListAllergies } from 'services/Patient/patient'
import { stringConcatenation } from 'shared/utils'
import { formatDateTime } from 'utilities/formats'

const useStyles = makeStyles({
    container: {
        marginBottom: 30,
        width: '100%',
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        fontWeight: 500,
        fontSize: '14px',
        color: '#303E4E',
        marginRight: 8,
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    boxContainer: {
        // border: '1px solid #B0BCE4',
        // padding: '20px 0 20px 20px',
        // marginTop: 10,
    },
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        background: '#FAFAFA',
        margin: '8px 0',
        borderRadius: 8,
        padding: 8,
        // width: '97%',
    },
    gridContainer__header: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        margin: '8px 0',
        // width: '97%',
    },
    fullWidth: {
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: 5,
        width: 28,
    },
    cell_header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
})

const defaultValue = {
    medication: '',
    dosage: '',
    sig: '',
    prescriber: '',
}

const Medication = ({
    fieldName,
    onChange,
    values,
    medicationHistory,
    patientId,
    encounter_id,
    lastNarrative,
    handleSetViewHealthyRecord,
}) => {
    const classes = useStyles()
    const notification = useNotification()

    const handleAddMore = (action, index) => {
        if (action === 'add') {
            onChange(fieldName, [...values, defaultValue])
        } else {
            const updatedValue = values.filter((v, i) => i !== index)
            onChange(fieldName, updatedValue.length > 0 ? updatedValue : [defaultValue])
        }
    }

    const RenderAddOrRemove = ({ index, arr, isEmpty }) => {
        const [alertDeleteOpen, setAlertDeleteOpen] = React.useState(false)
        return (
            <Box>
                {arr.length >= 1 && (arr.length === 1 && isEmpty ? false : true) && (
                    <IconButton
                        variant="outlined"
                        onClick={() => setAlertDeleteOpen(true)}
                        // className={classes.show_header_btn}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}

                <AlertDeleteDialog
                    open={alertDeleteOpen}
                    handleClose={() => {
                        setAlertDeleteOpen(false)
                    }}
                    handleDelete={() => {
                        handleAddMore('remove', index)
                        setAlertDeleteOpen(false)
                    }}
                />
            </Box>
        )
    }

    const handleTextChange = (keyName, text, index) => {
        const updatedValue = values.map((v, i) => (index === i ? { ...v, [keyName]: text } : v))
        onChange(fieldName, updatedValue)
    }

    const isEmptyMedication = (medication) => {
        return !(
            medication.medication.trim() ||
            medication.dosage.trim() ||
            medication.sig.trim() ||
            medication.prescriber.trim()
        )
    }
    const handleSelectMacro = (value) => {
        let newNarrative = ''
        if (values?.narrative === undefined) {
            newNarrative = value
        } else {
            newNarrative = values.narrative.concat(` ${value}`)
        }

        onChange(`${fieldName}.narrative`, newNarrative)
    }

    const fetchListAllergies = async () => {
        try {
            const resp = await getListAllergies(patientId, 1, 100000)
            const list = resp.data.data.map((item) => ({
                ...item,
                allergy: stringConcatenation(
                    item.allergy.brandName || '',
                    item.allergy.genericName || '',
                    item.allergy.strength || '',
                    item.allergy.form || '',
                ),
                ndcId: item.allergy?.coding.find((item) => item.display === 'NCDID')?.code || '',
                reaction: item.reaction?.display || '',
                status: item.status?.code || item.status?.display || '',
                time_onset:
                    (item.onset_date && formatDateTime(item.onset_date, 'MMM DD, YYYY')) || '',
                note: item.note || '',
            }))
            onChange(`${fieldName}`, list)
        } catch (ex) {
            notification(`Cannot get list allergies!`, 'error')
        }
    }

    useEffect(() => {
        if (patientId) {
            fetchListAllergies()
        }
    }, [patientId])

    return (
        <Box className={classes.container}>
            <CopyBar content={lastNarrative} handleSelectMacro={handleSelectMacro} />
            <Box className={classes.boxContainer}>
                <Allergies
                    isInClinicalNote
                    patient_Id={patientId}
                    encounter_id={encounter_id}
                    afterActionOk={fetchListAllergies}
                />
            </Box>
            <NarrativeAndMacro
                hideGenerateBtn={true}
                hideClearBtn={true}
                fieldName={fieldName}
                onGenerateClick={onChange}
                onChange={onChange}
                values={values}
                meta={{
                    values,
                }}
            />
        </Box>
    )
}

export default Medication
