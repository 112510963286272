import React, { Suspense, useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'

import { Loading } from 'shared/elements/Loading'
import { Card } from '../../view/Card'
import { NoData } from '../NoData'

import Routes from 'constants/routes'
import { useNotification } from 'shared/elements/Notification'
import { usePatientDashboardStyles } from '../../style'
import { Surgical } from '../../interfaces'
import { SurgicalForm } from '../../view/SurgicalHistory/SurgicalForm'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'

interface Props {
    onClick?: () => void
    viewAllFunc?: () => void
}

export const SurgicalHistory = (props: Props) => {
    const classes = usePatientDashboardStyles()

    const { id: patientId } = useParams<{ id: string }>()
    const history = useHistory()
    const notification = useNotification()

    const [listSurgical, setListSurgical] = useState<Surgical[]>([])
    const [loading, setLoading] = useState(false)
    const [isAddingNew, setIsAddingNew] = useState(false)

    const getSurgicalHistory = async () => {
        try {
            setLoading(true)

            const resp = await surgicalHistoryApis.getAll(patientId)
            setListSurgical(resp.data)
        } catch (ex) {
            console.error(ex)
            notification('Cannot get list surgical', 'error')
        }

        setLoading(false)
    }

    const handleViewAll = () =>
        history.push(Routes.PATIENT.SURGICAL_HISTORY.replace(':id', patientId))

    useEffect(() => {
        const abortController = new AbortController()
        if (patientId) {
            getSurgicalHistory()
        }

        return () => abortController.abort()
    }, [patientId])

    const Content = () => (
        <>
            {listSurgical.map((item, key) => (
                <Grid
                    container
                    key={key}
                    justifyContent="space-between"
                    wrap="nowrap"
                    alignItems="center"
                    style={{
                        borderBottom: '1px solid #EFF2F5',
                        paddingBottom: 8,
                        height: 'fit-content',
                    }}>
                    <Grid
                        item
                        container
                        alignItems="flex-start"
                        justifyContent="space-between"
                        direction="column"
                        style={{ width: 'fit-content' }}>
                        <Typography>{item.procedure}</Typography>
                        <Typography>{item.date}</Typography>
                    </Grid>
                </Grid>
            ))}

            {!!listSurgical.length && (
                <Grid container item justifyContent="center">
                    <span className={classes.viewall} onClick={props.viewAllFunc || handleViewAll}>
                        View all...
                    </span>
                </Grid>
            )}
        </>
    )

    return (
        <Card
            menu={[
                {
                    name: 'Add',
                    handler: () => setIsAddingNew(true),
                },
                {
                    name: 'View all',
                    handler: props.viewAllFunc || handleViewAll,
                },
            ]}
            onClick={props.onClick}
            header="Surgical History">
            <Suspense fallback={<CircularProgress color="inherit" thickness={1.5} />}>
                <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    style={{
                        gap: 8,
                        height: '100%',
                        overflow: 'auto',
                        paddingRight: 15,
                        position: 'relative',
                        flexWrap: 'nowrap',
                    }}>
                    <Loading visible={loading} />

                    {listSurgical.length ? <Content /> : <NoData />}
                </Grid>
            </Suspense>

            {isAddingNew && (
                <SurgicalForm
                    // inpatientPsychiatricId={}
                    patientId={patientId}
                    open={isAddingNew}
                    onClose={(forceReload) => {
                        setIsAddingNew(false)

                        if (forceReload) getSurgicalHistory()
                    }}
                />
            )}
        </Card>
    )
}
