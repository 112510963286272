import moment from "moment"

export function getCustomizedFormattedDate (dateStr, format = 'MM/DD/YYYY') {

    if(!dateStr || dateStr === '') return

    const formattedDate = moment(new Date(dateStr)).utc().format(format)
    
    return formattedDate
}

export function getTimeFromDate (dateStr) {

    if(dateStr === '' || !dateStr) return

    return moment(dateStr).format("hh:mm")

}

//datestr -> July 23, 1974
export function getDateInClassicFormat (dateStr) {

    if(!dateStr || dateStr === '') return
    
    const formattedDate = moment(new Date(dateStr))
    const month = formattedDate.format('MMMM'),
    year = formattedDate.format('YYYY'),
    day = formattedDate.format('DD')

    if(!year || !day || !month) return

    return `${month} ${day}, ${year}`

}
