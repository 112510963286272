import React, { lazy, useEffect, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import * as yup from 'yup'
import { FormikProps, useFormik } from 'formik'
import AddIcon from '@mui/icons-material/Add'
import CircularProgress from '@mui/material/CircularProgress'

import { ModalEl as Modal } from 'shared/elements'
import { SubstanceAbuse } from 'containers/PatientsSetting/interfaces'
import { Button as ButtonCustom } from 'components/custom'
import './style.scss'
import { apiSubstanceAbuseHistory } from 'services/Patient/apiSubstanceAbuseHistory'
import { Box } from '@mui/system'
import { useNotification } from 'shared/elements/Notification'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { getAge } from 'utilities/formats'
import { getPatient } from 'services/Patient/patient'

const InputNumber = lazy(() => import('shared/elements/FormItem/Input/InputNumber'))
const Select = lazy(() => import('shared/elements/FormItem/Select'))
const Footer = lazy(() => import('shared/elements/FormItem/Footer'))
const Loading = lazy(() => import('shared/elements/Loading'))
const TextBox = lazy(() => import('shared/elements/FormItem/TextBox'))
const CheckboxGroup = lazy(() => import('shared/elements/FormItem/Input/CheckboxGroup'))

type Props = {
    substanceAbuseId?: number | false
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: () => void
    onOke?: () => void
}

const validationSchema = yup.object({
    substance_id: yup.number().required('This field is required!'),

    age_of_first_use: yup
        .number(),
    // .min(1, 'This field is required!')
    // .max(
    //     yup.ref('age_patient'),
    //     "The age of first use must be less than or equal to the patient's age!",
    // )
    // .required('This field is required!'),

    age_of_last_use: yup
        .number()
        .when('age_of_first_use', (age_of_first_use, schema) => {
            return age_of_first_use >= 0
                ? schema.min(
                    age_of_first_use,
                    'The age of last use must be greater than or equal to the age of first use',
                )
                : schema.min(1, 'incorrect age format!')
        })
        .max(
            yup.ref('age_patient'),
            "The age of last use must be less than or equal to the patient's age!",
        ),
    // .required('This field is required!'),
})

const HowItWasTaken = [
    // @TODO:
    { value: 'Oral', label: 'Oral', checked: false },
    { value: 'Nasal', label: 'Nasal', checked: false },
    { value: 'Inhaled', label: 'Inhaled', checked: false },
    { value: 'Injected', label: 'Injected', checked: false },
]

const INITIAL_VALUES = {
    // id: '0',
    substance_id: '',
    age_of_first_use: 0,
    age_of_last_use: '',
    how_taken: '',
    amount_per_day: 0,
    day_per_month: 0,
    age_patient: 100,
}
const SubstanceAbuseForm = (props: Props) => {
    const notification = useNotification()

    // State
    // const [agePtient, setAgePatient] = useState<number | false>(false)

    const [initialValues, setInitialValues] = useState<SubstanceAbuse>(INITIAL_VALUES)
    const [optionsHowTaken, setOptionsHowTaken] = useState(HowItWasTaken)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [loadingSubstances, setLoadingSubstances] = useState(false)
    const [loadingAddNewSubstance, setLoadingAddNewSubstance] = useState(false)
    const [addingNewSubstance, setAddingNewSubstance] = useState(false)
    const [newSubstance, setNewSubstance] = useState('')
    const [substances, setSubstances] = useState([])

    const formik = useFormik<SubstanceAbuse>({
        validationSchema,
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    const modalTitle = props.substanceAbuseId
        ? 'Update Substance Abuse History'
        : 'Add Substance Abuse History'

    const getSubstance = async () => {
        try {
            setLoadingSubstances(true)
            const res = await apiSubstanceAbuseHistory.getSubstance({ page: 1, size: 100 })
            if (res.data?.data?.length >= 0) {
                const data = res.data.data
                const newData = data.map((item) => ({ key: item.id, value: item.name }))
                setSubstances(newData)
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong', 'error')
        } finally {
            setLoadingSubstances(false)
        }
    }

    const handleAddingNewSubstance = async () => {
        if (newSubstance.length) {
            setLoadingAddNewSubstance(true)
            try {
                const res = await apiSubstanceAbuseHistory.createSubstance({ name: newSubstance })
                if (res.data) {
                    notification('Substance careated successfully!', 'success')
                    setNewSubstance('')
                    setAddingNewSubstance(false)
                    await getSubstance()
                }
            } catch (error) {
                notification('Something went wrong!', 'error')
            } finally {
                setLoadingAddNewSubstance(false)
            }
        }
    }

    const handleChangHowItWasTakenList = (
        value: string,
        checked: boolean,
        formik: FormikProps<SubstanceAbuse>,
    ) => {
        const recordOptionsChecked = [...optionsHowTaken]
        const index = recordOptionsChecked.findIndex((element) => element.value === value)
        recordOptionsChecked[index] = {
            ...recordOptionsChecked[index],
            checked,
        }
        setOptionsHowTaken(recordOptionsChecked)
        const StringValue = recordOptionsChecked
            .filter((item) => item.checked === true)
            .map((item) => item.value)
            .toString()
        formik.setFieldValue('how_taken', StringValue)
    }

    async function handleSubmit(values) {
        // console.log(values)
        if (!props.substanceAbuseId && props.patientId) {
            setSubmitting(true)
            try {
                const res = await apiSubstanceAbuseHistory.createSubstanceAbuseHistory(
                    props.patientId,
                    { ...values, encounter_id: props?.encounter_id },
                )
                if (res.data) {
                    notification('Substance Abuse History created successfully', 'success')
                    formik.resetForm()
                    setOptionsHowTaken(HowItWasTaken)
                    setSubmitting(false)
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setSubmitting(false)
            }
        } else if (props.substanceAbuseId && props.patientId) {
            setSubmitting(true)
            try {
                const res = await apiSubstanceAbuseHistory.updateubstanceAbuse(
                    props.patientId,
                    props.substanceAbuseId,
                    { ...values, encounter_id: props?.encounter_id },
                )
                if (res.data?.Status === 'SUCCESSED') {
                    notification('Substance Abuse History updated successfully', 'success')
                    formik.resetForm()
                    setOptionsHowTaken(HowItWasTaken)
                    setSubmitting(false)
                    props.onOke?.()
                }
            } catch (error) {
                console.log(error)
                notification('Something went wrong', 'error')
            } finally {
                setSubmitting(false)
            }
        }
    }
    const getSubstanceAbuseById = async (patientId, substanceAbuseId) => {
        try {
            setLoading(true)
            const res = await apiSubstanceAbuseHistory.getSubstanceAbuseId(
                patientId,
                substanceAbuseId,
            )
            const resPatient = await getPatient(patientId)
            let agePatient: number | null = null
            if (resPatient.data) {
                agePatient = getAge(resPatient.data.dob, 'YYYY-MM-DD')
                if (agePatient === 0) agePatient = 1
            }
            if (res.data) {
                const {
                    id,
                    substance_id,
                    substance_name,
                    age_of_first_use,
                    age_of_last_use,
                    how_taken,
                    amount_per_day,
                    day_per_month,
                } = res.data
                formik.setValues({
                    id,
                    substance_id,
                    substance_name,
                    age_of_first_use,
                    age_of_last_use,
                    how_taken,
                    amount_per_day,
                    day_per_month,
                    age_patient: agePatient ? agePatient : 100,
                })
                if (how_taken) {
                    // console.log(how_taken.split(','))
                    const listCheckox: { value: string; label: string; checked: boolean }[] = []
                    optionsHowTaken.forEach((item) => {
                        let checked = how_taken.split(',').find((check) => {
                            return check === item.value
                        })
                        if (checked) {
                            listCheckox.push({ ...item, checked: true })
                        } else {
                            listCheckox.push({ ...item, checked: false })
                        }
                    })
                    setOptionsHowTaken(listCheckox)
                }
            }
        } catch (error) {
            console.log(error)
            notification('Something went wrong', 'error')
        } finally {
            setLoading(false)
        }
    }

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                console.log(resp.data)
                let age = getAge(resp.data.dob, 'YYYY-MM-DD')
                if (age === 0) age = 1
                if (age) {
                    // formik.setFieldValue('age_patient', age)
                    setInitialValues({
                        ...initialValues,
                        age_patient: age,
                    })
                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getSubstance()
    }, [])

    useEffect(() => {
        if (props.substanceAbuseId) {
            getSubstanceAbuseById(props.patientId, props.substanceAbuseId)
        }
    }, [props.substanceAbuseId, props.patientId])

    useEffect(() => {
        if (props.patientId && !props.substanceAbuseId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    return (
        <Modal
            width={700}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="substance-abuse-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12} container wrap="nowrap" alignItems="flex-end">
                        <Box flex={1} className="wrap-select-has-loading">
                            {loadingSubstances && (
                                <Box className={'loading-select'}>
                                    <CircularProgress size={'20px'} />
                                </Box>
                            )}
                            <Select
                                required
                                label="Substance"
                                name="substance_id"
                                value={formik.values.substance_id}
                                placeholder="Substance"
                                options={substances}
                                disabled={loadingSubstances}
                                error={
                                    Boolean(formik.touched.substance_id) &&
                                    Boolean(formik.errors.substance_id)
                                }
                                helperText={
                                    Boolean(formik.touched.substance_id) &&
                                    (formik.errors.substance_id as string)
                                }
                                onChange={(value) => {
                                    // console.log(value)
                                    formik.setFieldValue('substance_id', value)
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                marginBottom:
                                    Boolean(formik.touched.substance_id) &&
                                        Boolean(formik.errors.substance_id)
                                        ? '22px'
                                        : 0,
                            }}>
                            <ButtonCustom
                                style={{ marginBottom: 4, marginRight: 0 }}
                                tooltip="Add New Substance Name"
                                variant="contained"
                                color="primary"
                                onClick={() => setAddingNewSubstance((state) => !state)}
                                fullWidth>
                                <AddIcon style={{ fontSize: 20 }} />
                            </ButtonCustom>
                        </Box>
                    </Grid>

                    {addingNewSubstance && (
                        <Grid item xs={12} container wrap="nowrap" alignItems="flex-end">
                            <Grid item style={{ flex: 1, marginRight: 10 }}>
                                <Box flex={1} className="wrap-select-has-loading">
                                    {loadingAddNewSubstance && (
                                        <Box className={'loading-select'}>
                                            <CircularProgress size={'20px'} />
                                        </Box>
                                    )}
                                    <TextBox
                                        label="New Substance"
                                        placeholder="Input Substance Name"
                                        layout="vertical"
                                        value={newSubstance}
                                        disabled={loadingAddNewSubstance}
                                        onChange={(val) => setNewSubstance(val)}
                                    />
                                </Box>
                            </Grid>

                            <Button variant="contained" onClick={handleAddingNewSubstance}>
                                Add New
                            </Button>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <InputNumber
                            // required
                            label="Age of First Use"
                            name="age_of_first_use"
                            value={formik.values.age_of_first_use}
                            placeholder="Age of First Use"
                            min={1}
                            max={99}
                            error={
                                formik.touched.age_of_first_use &&
                                Boolean(formik.errors.age_of_first_use)
                            }
                            helperText={
                                formik.touched.age_of_first_use && formik.errors.age_of_first_use
                            }
                            onChangeValue={(value) => {
                                formik.setFieldValue('age_of_first_use', +value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            // required
                            label="Age of Last Use"
                            name="age_of_last_use"
                            min={1}
                            max={99}
                            value={formik.values.age_of_last_use}
                            placeholder="Age of Last Use"
                            error={
                                formik.touched.age_of_last_use &&
                                Boolean(formik.errors.age_of_last_use)
                            }
                            helperText={
                                formik.touched.age_of_last_use && formik.errors.age_of_last_use
                            }
                            onChangeValue={(value) => {
                                console.log(value)
                                formik.setFieldValue('age_of_last_use', value)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CheckboxGroup
                            label="How it was taken"
                            row
                            name="status"
                            optionsChecked={optionsHowTaken} // @TODO
                            error={formik.touched.how_taken && Boolean(formik.errors.how_taken)}
                            helperText={formik.touched.how_taken && formik.errors.how_taken}
                            onChange={(e) => {
                                handleChangHowItWasTakenList(
                                    e.target.value,
                                    e.target.checked,
                                    formik,
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Amount per day"
                            name="amount_per_day"
                            value={formik.values.amount_per_day}
                            min={1}
                            max={99}
                            error={
                                formik.touched.amount_per_day &&
                                Boolean(formik.errors.amount_per_day)
                            }
                            helperText={
                                formik.touched.amount_per_day && formik.errors.amount_per_day
                            }
                            onChangeValue={(value) => formik.setFieldValue('amount_per_day', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputNumber
                            label="Days per month"
                            name="day_per_month"
                            value={formik.values.day_per_month}
                            min={0}
                            max={99}
                            error={
                                formik.touched.day_per_month && Boolean(formik.errors.day_per_month)
                            }
                            helperText={formik.touched.day_per_month && formik.errors.day_per_month}
                            onChangeValue={(value) => formik.setFieldValue('day_per_month', value)}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={submitting}
                        onCancel={() => {
                            formik.resetForm()
                            props.onClose?.()
                        }}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.substanceAbuseId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export { SubstanceAbuseForm }
export default SubstanceAbuseForm
