import React, { useEffect, useState } from 'react'
import { Grid, FormLabel, Typography, CircularProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ModalEl as Modal } from 'shared/elements'
import SearchPatientInput from 'components/custom/SearchPatientInput'
import { getPatientByUserId } from 'services/Patient/patient'
import { updateFaxById } from 'services/EFax'

import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Footer } from 'shared/elements/FormItem/Footer'
import { displayName } from '../shared/util'

import './styles.scss'

const documentTypeOptions = [
    'Inbound Referral',
    'Outbound Referral  ',
    'Medical Record',
    'Medical Records Request',
    'Imaging',
    'Lab Result',
    'RX Pre-Auth',
    'Insurance Card',
    'Eligibility',
    'Clinical Note',
    'Education / Information Material',
    'Amendment',
    'Ketamine / SPRAVATO Monitoring',
    'Presciption',
    'Disablility/FMLA',
    'Cambridge Result',
    'Prior Auth',
    'RCM Report',
    'Accommodation Letter',
    'Other',
]
    .map((item, index) => {
        return {
            key: index + 1,
            value: item,
        }
    })
    .sort((option1, option2) => {
        let a = option1.value
        let b = option2.value
        return a === b ? 0 : a > b ? 1 : -1
    })

const defaultValue = {
    patientId: null,
    patient: null,
    patientName: null,
    patientRecord: null,
    documentType: 1,
    description: null,
}

function AssociateToPatientModal(props) {
    const [loading, setLoading] = useState(false)
    const [fetchingPatient, setFetchingPatient] = useState(false)
    const { onSubmitSuccess = () => { } } = props

    const handleSubmit = async (values) => {
        try {
            setLoading(true)
            const data = {
                message_id: props.item?.message_id,
                associated_patient: values.patientId,
                description: values.description,
                document_type: documentTypeOptions.find(item => item.key === values.documentType)?.value,
            }

            await updateFaxById(props.item.message_id, data)
            onSubmitSuccess(formik.values.patient)
        } finally {
            setLoading(false)
        }
    }

    const getPatientDetails = async (patientId) => {
        try {
            setFetchingPatient(true)
            const response = await getPatientByUserId(patientId)
            formik.setFieldValue('patient', response?.data)
        } catch (exception) {
            console.error('Cannot get patient details', exception)
        } finally {
            setFetchingPatient(false)
        }
    }

    const validationSchema = yup.object({
        patient: yup.object({
            id: yup.string().required(),
        }),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: defaultValue,
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        if (formik.values.patientId) getPatientDetails(formik.values.patientId)
    }, [formik.values.patientId])

    return (
        <Modal
            width={700}
            title={'Associate To Patient'}
            description={''}
            open={props.open}
            onClose={() => {
                formik.setValues(defaultValue)
                props.onModalClose()
            }}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid container className="appointment-booking-container">
                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid
                            container
                            item
                            spacing={3}
                            className="modal-spacer"
                            style={{
                                marginTop: 24,
                                marginBottom: 16,
                            }}>
                            <Grid item xs={12}>
                                <FormLabel component="p" className="input-form-label required">
                                    Patient
                                </FormLabel>
                                <SearchPatientInput
                                    value={formik.values.patient}
                                    handleInputChange={(e) => {
                                        formik.setFieldValue('patient', e.target.value)
                                    }}
                                    handleAddRow={(value) => {
                                        formik.setValues({
                                            ...formik.values,
                                            patient: null,
                                            patientId: value?.id,
                                            patientName: value?.label,
                                        })
                                    }}
                                />
                            </Grid>

                            {!formik.values.patient && fetchingPatient && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    style={{
                                        marginTop: '1rem',
                                    }}>
                                    <CircularProgress thickness={2} />
                                </Grid>
                            )}

                            {formik.values.patient && (
                                <Grid item xs={12}>
                                    <Grid
                                        container
                                        style={{
                                            padding: '16px 24px',
                                            background: '#FBFCFC',
                                            border: '1px solid #E9ECF1',
                                            borderRadius: '6px',
                                        }}>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontFamily: "'Rubik'",
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    lineHeight: '140%',
                                                    color: '#5571C6',
                                                }}>
                                                {displayName(
                                                    formik.values.patient?.first_name,
                                                    formik.values.patient?.last_name,
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>Chart ID</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography>
                                                {formik.values.patient?.rcopia_id || ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>DOB</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography>
                                                {formik.values.patient?.dob || ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>Phone</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography>
                                                {formik.values.patient?.phone_number || ''}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>Email</Typography>
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography>
                                                {formik.values.patient?.email || ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextBox
                                    label="Description"
                                    placeholder="Enter Description"
                                    multiline
                                    minRows={5}
                                    value={formik.values.description}
                                    onChange={(value) =>
                                        formik.setFieldValue('description', value)
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Select
                                    label="Document Type"
                                    required
                                    value={formik.values.documentType}
                                    options={documentTypeOptions}
                                    onChange={(value) => {
                                        formik.setFieldValue('documentType', value)
                                    }}
                                    placeholder="Select Document Type"
                                />
                            </Grid>
                        </Grid>

                        <Footer
                            loading={loading}
                            disabled={fetchingPatient}
                            okBtnText="Associate"
                            onCancel={() => {
                                formik.setValues(defaultValue)
                                props.onModalClose()
                            }}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}

export default AssociateToPatientModal
