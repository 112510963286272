import React from 'react';
import Chats from '../../Chats/Chats';

interface TextMessageProps {
    body: string;
    isLocalParticipant: boolean;
    author: string;
    dateCreated: string;
}

export default function TextMessage({
    body,
    author,
    isLocalParticipant,
    dateCreated,
}: TextMessageProps) {
    
    return (
        <>
            {/* <Chats client={"local"} msg={"hi"} author={"shivam"} time={"just min ago"} />
    <Chats client={"global"} msg={"hello"} author={"sachin"} time={"just min ago"} /> */}
            {isLocalParticipant ? (
                <Chats
                    client={'local'}
                    msg={body}
                    author={author}
                    time={dateCreated}
                />
            ) : (
                <>
                    <Chats
                        client={'global'}
                        msg={body}
                        author={author}
                        time={dateCreated}
                    />
                </>
            )}
        </>
    );
}
