import {
    postSearchService,
    getService,
    postService,
    putService,
    deleteService,
} from '../../utilities/request/restClient'

export function getSSOLink() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/erx/sso-link`)
}

export function getSSOLinkByMessage() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/erx/message-sso-link`)
}
export function getSSOLinkByPrecription() {
    return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/erx/report-sso-link `)
}

export const getApiEXP = {
    notification() {
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/notification/count`)
    },
}
