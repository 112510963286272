
import {
  getService,
  postService,
  deleteService,
  patchService,
  putService
} from 'utilities/request/restClient'


export const apiMenstruationAndPregnancyHistory = {
  create(patienrId, params) {
      return postService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/menstruation-pregnancy`,
          params,
      )
  },
  getAll(patienrId, payload) {
      const queryString = new URLSearchParams({
          ...payload,
      }).toString()
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patienrId}/menstruation-pregnancy?${queryString}`,
      )
  },
  getId(patientId, socialHistoryId) {
      return getService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/menstruation-pregnancy/${socialHistoryId}`,
      )
  },
  update(patientId: string | number, socialHistoryId: string | number , payload) {
      return putService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/menstruation-pregnancy/${socialHistoryId}`,
          payload,
      )
  },
  delete(patientId: string | number, socialHistoryId: string | number ) {
      return deleteService(
          `${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/menstruation-pregnancy/${socialHistoryId}`
      )
  },
}