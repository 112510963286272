import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { getDeviceById, getRoomById } from 'services/Calendar'
import { ModalEl as Modal } from 'shared/elements'
import { Select } from 'shared/elements/FormItem/Select'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Footer } from 'shared/elements/FormItem/Footer'
import { PostData } from './interface'
import { Loading } from 'shared/elements/Loading'
import { SearchLocation } from 'shared/elements/FormItem/SearchComponents'

import './styles.scss'

interface Props {
    loading?: boolean
    open?: boolean
    itemId?: string
    resourceType: number
    onModalClose?: () => void
    onSubmit: (data: any) => void
}

export const CreateResourceDialog = ({
    loading,
    ...props
}: Props) => {
    const { onSubmit, resourceType } = props
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState<PostData>({
        resourceType,
        name: '',
        location: {
            id: '',
            name: ''
        },
    })

    useEffect(() => {
        if (props.itemId) {
            const promise =
                resourceType === 2
                    ? getDeviceById
                    : getRoomById

            setDataLoading(true)
            try {
                promise(props.itemId)
                    .then((res) => {
                        setData({
                            ...res.data,
                            location: {
                                // Room api response parent_id as location_id. Absolutely OMG~
                                id: res?.data?.location_id || res?.data?.parent_id,
                                name: ''
                            },
                            resourceType,
                        })
                    })
                    .finally(() => setDataLoading(false))
            } catch (err) {
                console.error('Cannot fetch resources', err)
            }
        }
    }, [props.itemId])

    const resourceTypes = [
        {
            value: 1,
            label: 'Room',
        },
        {
            value: 2,
            label: 'Equipment',
        },
    ]

    const handleSubmit = (values: PostData) => {
        onSubmit({
            location_id: values.location.id,
            name: values.name,
            resourceType: values.resourceType,
        })
    }

    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Resource Name is required!'),
        location: yup.object({
            id: yup.string().required('Location is required!'),
            name: yup.string()
        }),
        resourceType: yup.string(),
    })

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: data,
        onSubmit: handleSubmit,
    })

    return (
        <Modal
            width={700}
            title={
                props.itemId
                    ? 'Update Resource'
                    : 'Add New Resource'
            }
            description={'Resouces Management'}
            open={props.open}
            onClose={() => props.onModalClose && props.onModalClose()}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
            }}
            className="calendar-appointment-modal">
            <Grid
                container
                className="appointment-booking-container">
                {dataLoading && (
                    <Loading visible={dataLoading} />
                )}

                <Grid container item>
                    <Grid
                        container
                        item
                        justifyContent="flex-end"
                        direction="row"
                        spacing={2}
                        style={{
                            margin: '0 10px',
                        }}>
                        <Grid
                            container
                            item
                            spacing={3}
                            className="modal-spacer"
                            style={{
                                marginTop: 24,
                                marginBottom: 16,
                            }}>
                            <Grid item xs={12}>
                                <Select
                                    disabled={!!props.itemId}
                                    label="Resource Type"
                                    name="resourceType"
                                    placeholder="Select Category"
                                    value={
                                        formik.values
                                            .resourceType
                                    }
                                    options={resourceTypes.map(
                                        (item) => ({
                                            key: item.value,
                                            value: item.label,
                                        }),
                                    )}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'resourceType',
                                            value,
                                        )
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextBox
                                    label="Name"
                                    required
                                    name="name"
                                    value={
                                        formik.values.name
                                    }
                                    error={
                                        formik.touched
                                            .name &&
                                        Boolean(
                                            formik.errors
                                                .name,
                                        )
                                    }
                                    helperText={
                                        formik.touched
                                            .name &&
                                        formik.errors.name
                                    }
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'name',
                                            value,
                                        )
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <SearchLocation
                                    label="Location"
                                    required
                                    name="resourceType"
                                    placeholder="Search Location"
                                    searchOnFirstLoad={Boolean(props.itemId)}
                                    value={{
                                        code: formik.values.location?.id,
                                        display: formik.values.location?.name
                                    }}
                                    error={
                                        formik.touched
                                            .location?.id &&
                                        Boolean(
                                            formik.errors
                                                .location?.id,
                                        )
                                    }
                                    helperText={
                                        formik.touched
                                            .location?.id &&
                                        formik.errors
                                            .location?.id
                                    }
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'location',
                                            {
                                                id: value?.code,
                                                name: value?.display
                                            },
                                        )
                                    }
                                />
                            </Grid>
                        </Grid>

                        <Footer
                            loading={loading}
                            onCancel={props.onModalClose}
                            onOk={formik.handleSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    )
}
