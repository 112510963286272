import { getLocations } from '../../services/Calendar'
import * as actionType from '../actionType'
import { apiStatusCodes } from '../../services/Calendar'
export const setLocations = (value) => {
    return {
        type: actionType.SET_LOCATIONS,
        value,
    }
}

export const getAllLocations = () => {
    return async (dispatch) => {
        const response = await getLocations({
            page: 1,
            size: 10000,
        })
        dispatch(setLocations(response?.data?.data))
    }
}

export const setStatusCode = (value) => {
    return {
        type: actionType.SET_STATUS_CODE,
        value,
    }
}

export const getStatusCodes = (params) => {
    return async (dispatch) => {
        apiStatusCodes
            .getStatusCodes(params)
            .then((res) => {
                dispatch(setStatusCode(res.data?.listStatusCode?.data))
            })
            .catch((err) => {
                dispatch(setStatusCode([]))
            })
    }
}
