import React from "react";

export const GroupPeopleIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 5.37C13.455 5.3625 13.4025 5.3625 13.3575 5.37C12.3225 5.3325 11.4975 4.485 11.4975 3.435C11.4975 2.3625 12.36 1.5 13.4325 1.5C14.505 1.5 15.3675 2.37 15.3675 3.435C15.36 4.485 14.535 5.3325 13.5 5.37Z" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.7275 10.8301C13.755 11.0026 14.8875 10.8226 15.6825 10.2901C16.74 9.58512 16.74 8.43012 15.6825 7.72512C14.88 7.19262 13.7325 7.01262 12.705 7.19262" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.47749 5.37C4.52249 5.3625 4.57499 5.3625 4.61999 5.37C5.65499 5.3325 6.47999 4.485 6.47999 3.435C6.47999 2.3625 5.61749 1.5 4.54499 1.5C3.47249 1.5 2.60999 2.37 2.60999 3.435C2.61749 4.485 3.44249 5.3325 4.47749 5.37Z" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25002 10.8301C4.22252 11.0026 3.09002 10.8226 2.29502 10.2901C1.23752 9.58512 1.23752 8.43012 2.29502 7.72512C3.09752 7.19262 4.24502 7.01262 5.27252 7.19262" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 10.9725C8.955 10.965 8.9025 10.965 8.8575 10.9725C7.8225 10.935 6.9975 10.0875 6.9975 9.03754C6.9975 7.96504 7.86 7.10254 8.9325 7.10254C10.005 7.10254 10.8675 7.97254 10.8675 9.03754C10.86 10.0875 10.035 10.9425 9 10.9725Z" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.1825 13.3355C9.98998 12.5405 8.01748 12.5405 6.81748 13.3355C5.75998 14.0405 5.75998 15.1955 6.81748 15.9005C8.01748 16.703 9.98248 16.703 11.1825 15.9005" stroke="#667689" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
