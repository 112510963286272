import { Button, Grid, IconButton, Typography, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import PDFViewer from '../../../components/ClinicalNotes/reusable/PDFViewer'
import AddendumListIcon from '../../../assests/images/addendum_list_icon.svg'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import RequestApprove from './requestApprove'
import RepeatIcon from 'assests/icons/RepeatIcon.svg'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
const useStyles = makeStyles({
    rootModal: {
        '& .MuiPaper-root': {
            height: '90%',
            position: 'relative',
        },
        '& .MuiDialogContent-root': {
            overflow: 'hidden',
        },
    },
    root: {
        // padding: '16px 24px',
        flex: 1,
    },
    icon_back: {
        marginRight: 8,
    },
    preview_container: {
        flex: 1,
        marginTop: 0,
        borderRadius: 4,
        // border: '1px solid #e8e8e8',
    },
    status_tag: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 16px',
        height: 32,
        borderRadius: 4,
    },
    preview_header: {
        padding: '7px 16px',
        border: '1px solid #989CA8',
        marginBottom: '10px',
        borderRadius: "5px",
    },
    preview_document: {
        border: '1px solid #989CA8',
        background: '#E9ECF1',
        padding: 24,
        flex: 1,
        borderRadius: "5px",
    },
    btn_action: {
        height: 30,
        color: '#667689',
        fontWeight: 'normal',
        margin: '0px 4px',
        background: '#F3F6F9',
    },
    back__btn: {
        fontWeight: 400,
        fontSize: 13,
        color: '#5571C6',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approve: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_resend_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_in_progress: {
        background: '#D8D1FF',
        color: '#7340DF',
    },
    headerWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerLeft: {
        justifyContent: 'flex-start',
    },
    headerRight: {
        justifyContent: 'flex-end',
    },
    addendumsBtn: {
        backgroundColor: 'transparent',
    },
    addendumsBtnText: {
        fontSize: '14px',
        color: '#5571C6',
        marginLeft: '8px',
    },
    header: {
        fontSize: '16px',
        color: '#303E4E',
        fontWeight: '500',
        textAlign: 'center',
        marginBottom: 18,
    },
})

function statusMapper(status) {
    switch (status) {
        case 'PENDING_APPROVE':
            return 'Pending Approve'
        case 'DRAFT':
            return 'Draft'
        case 'LOCKED':
            return 'Locked'
        case 'RESEND_EDIT':
            return 'Resend edit'
        case 'IN_PROGRESS':
            return 'In Progress'
        default:
            return ''
    }
}

const NewPreviewNote = ({
    status = 'DRAFT',
    role,
    sigingAndLocking,
    onClick,
    handleSignAndLock,
    onChangeActiveView,
    PDFFile,
    handleRenderPDF,
    handleRequestApprove,
    handleResendEdit,
    isCreatorNote,
    requestingApproval,
    resendingEdit,
    approveRequestCount,
    supervisor,
    open,
    handleClose
}) => {
    const classes = useStyles()
    const ClinicalNotePermission = useSelector((state) => state?.permission.clinical_notes)
    role =
        role === 'bh_provider' || role === 'medical_provider'
            ? 'provider'
            : role === 'mid_bh_provider' || role === 'mid_medical_provider'
                ? 'mid_provider'
                : ''

    useEffect(() => {
        handleRenderPDF()
    }, [])
    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paperFullWidth': {
                    maxWidth: '1100px',
                },
            }}
            fullWidth={true}
            className={classes.rootModal}
            onClose={handleClose}
        >
            <DialogContent style={{marginBottom: "5px"}}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
                    <Typography className={classes.header}>Preview Note</Typography>
                    <IconButton style={{ marginBottom: 18 }}
                      onClick={()=>{handleClose()}}>
                        <CloseIcon style={{ color: '#303E4E' }} />
                    </IconButton>
                </div>
                <Grid className={classes.root} container onClick={onClick} direction="column">
                    <Grid className={classes.headerWrap}>
                        <div className={classes.headerRight}>
                            {status === 'LOCKED' && (
                                <Button
                                    className={classes.addendumsBtn}
                                    onClick={() => onChangeActiveView('addendums')}>
                                    <img
                                        className={classes.addendumListIcon}
                                        src={AddendumListIcon}
                                        alt="Addendum List"
                                    />
                                    <Typography className={classes.addendumsBtnText}>
                                        Addendum List
                                    </Typography>
                                </Button>
                            )}
                        </div>
                    </Grid>
                    <Grid container className={classes.preview_container} direction="column">
                        <Grid
                            item
                            container
                            className={classes.preview_header}
                            justifyContent="space-between">
                            <Grid item>
                                <Grid container>
                                    {ClinicalNotePermission.includes(
                                        'sign_and_lock_clinical_notes',
                                    ) &&
                                        (status === 'PENDING_APPROVE' ||
                                            status === 'DRAFT' ||
                                            status === 'IN_PROGRESS') && (
                                            <Button
                                                className={classes.btn_action}
                                                startIcon={
                                                    !!sigingAndLocking && (
                                                        <CircularProgress thickness={2} size={15} />
                                                    )
                                                }
                                                onClick={handleSignAndLock}
                                                disabled={sigingAndLocking}
                                                size="small">
                                                {'Sign & Lock'}
                                            </Button>
                                        )}
                                    {ClinicalNotePermission.includes(
                                        'resend_edit_clinical_notes',
                                    ) &&
                                        !isCreatorNote &&
                                        status === 'PENDING_APPROVE' && (
                                            <Button
                                                className={classes.btn_action}
                                                onClick={handleResendEdit}
                                                size="small"
                                                disabled={resendingEdit}>
                                                {'Resend edit'}
                                            </Button>
                                        )}
                                    {ClinicalNotePermission.includes(
                                        'request_approve_clinical_notes',
                                    ) &&
                                        supervisor?.id && (
                                            <RequestApprove
                                                supervisor={supervisor}
                                                status={status}
                                                handleRequestApprove={handleRequestApprove}
                                                requestingApproval={requestingApproval}
                                            />
                                        )}
                                </Grid>
                            </Grid>
                            <Grid
                                className={clsx({
                                    [classes.status_tag]: true,
                                    [classes.status_box]: true,
                                    [classes.bgColor_draft]: status === 'DRAFT',
                                    [classes.bgColor_in_progress]: status === 'IN_PROGRESS',
                                    [classes.bgColor_pending_approve]: status === 'PENDING_APPROVE',
                                    [classes.bgColor_locked]: status === 'LOCKED',
                                    [classes.bgColor_resend_edit]: status === 'RESEND_EDIT',
                                })}
                                item>
                                {status === 'PENDING_APPROVE' ? (
                                    <Typography
                                        className={clsx({
                                            [classes.bgColor_pending_approve]:
                                                status === 'PENDING_APPROVE',
                                        })}>
                                        {' '}
                                        <IconButton>
                                            <img src={RepeatIcon} alt="icon"></img>
                                        </IconButton>{' '}
                                        {`Pending Approval (${approveRequestCount})`}
                                    </Typography>
                                ) : (
                                    <Typography
                                        className={clsx({
                                            [classes.status_box]: true,
                                            [classes.bgColor_draft]: status === 'DRAFT',
                                            [classes.bgColor_in_progress]: status === 'IN_PROGRESS',
                                            // [classes.bgColor_pending_approve]: status === 'PENDING_APPROVE',
                                            [classes.bgColor_locked]:
                                                status === 'approved_lock' || status === 'LOCKED',
                                            [classes.bgColor_resend_edit]: status === 'RESEND_EDIT',
                                        })}>
                                        {' '}
                                        {statusMapper(status) || status}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            className={classes.preview_document}
                            justifyContent="center"
                            alignItems="center"
                            style={{maxHeight: "520px",
                            overflow:"auto"}}>
                            <PDFViewer PDFFile={PDFFile} />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default NewPreviewNote
