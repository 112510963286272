import React, { useEffect, useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { PatientInformation } from '../demographic/PatientInformation'
import { usePatientDashboardStyles } from '../style'
import Routes from 'constants/routes'
import { usePatient } from '../hooks'
import { Tabs } from '../shared/PatientInformationPageTabs'
import { HealthRecords } from '../demographic/HealthRecords'
import { GeneralPatientRecords } from '../demographic/GeneralPatientRecords'
import { HistoryCards } from '../demographic/HistoryCards'
import OrderEntry from '../demographic/OrderEntry'
import '../styles.scss'
import { OrderEntrySync, getFaxDocuments, putPatient, sentErxUpatedTime, deactiveCariumAccess, activeCariumAccess } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import moment from 'moment'
import { getPatientName } from '../shared'
import CloseIcon from '@mui/icons-material/Close'
import ReportIcon from '@mui/icons-material/Report'
import { FaxComponent } from './FaxComponent/FaxComponent'
import { isObjectNull } from 'containers/CalendarScheduler/utils'
import ErrorIcon from 'assests/icons/error-icon.svg'
import Taxonomy from 'assests/icons/taxonomy.svg'

//

export const PatientDashboard = (props) => {
    const classes = usePatientDashboardStyles()
    const history = useHistory()
    const location = useLocation()
    const { id: patientId } = useParams()
    const { patientInfo, fetchPatient, loadingPatient, setLoadingPatient } = usePatient(patientId)
    const [currentTab, setActiveTab] = useState(undefined)
    const [ prevTab, setPrevTab] = useState(undefined)
    const [editTime , setEditTime] = useState('')
    const notification = useNotification()
    // const [editTime , setEditTime] = useState('')
    const [patientPortalAnchorEl, setPatientPortalAnchorEl] = useState(false)
    const [patientPortalCariumAnchorEl,setPatientPortalCariumAnchorEl] = useState(false)
    const [openFaxPopup, setOpenFaxPopup] = useState(false)
    const [faxData, setFaxData] = useState({
        rawData: {},
        formattedData: {}
    })
    const [isLoadingPage, setIsLoadingPage] = useState(true)

    const handleAccessPatientPortal = (value) => {
        setLoadingPatient(true)
        notification(`Patient is being updated...`, 'warning')
        putPatient(patientInfo?.id, { ...patientInfo, access_patient_portal: value })
            ?.then((res) => {
                notification(`Patient updated successfully!`, 'success')
                setLoadingPatient(false)
                fetchPatient(patientInfo?.id)
                // refetchPatient()
            })
            .catch((err) => {
                // console.log(err)
                setLoadingPatient(false)
                if(err?.data?.message === "Email already exists" && err?.data?.data?.users?.length){
                    const formattedNames = err?.data?.data?.users?.length > 0 && err?.data?.data?.users.map(user => `${getPatientName({ ...user, suffix: null })}(mrn:${user.id})`);
                    const value = formattedNames.join(', ')
                    const errorMessage = `Email already exists with ${err?.data?.data?.users?.length > 1 ? 'these patients:' : 'this patient:'} ${value}`
                    notification(errorMessage, 'error')
                }else{
                    notification(err?.data?.message || `Couldn't get updated patient details`, 'error')
                }
            })
    }

    const patientPortalDialodContent = () => {
        if (patientInfo?.email === '') {
            return (
                <div style={{ display: 'flex', gap: 5, alignItems: 'flex-start' }}>
                    <Typography>Email is mandatory for accessing patient portal!</Typography>
                    <IconButton onClick={() => {
                        setPatientPortalAnchorEl(false)
                    }}>
                        <CloseIcon
                            style={{ fontSize: '20px', marginleft: '50px', color: '#666' }}
                        />
                    </IconButton>
                </div>
            )
        }else if(!patientInfo?.access_patient_portal && patientInfo?.shared_email_users?.length > 0){
            return (
                <div style={{ width: '350px',padding:"10px" }}>
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                        }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={ErrorIcon} alt=""></img>
                            <Typography className={classes.headingText}>
                                Patient has a shared email 
                            </Typography>
                        </span>
                    </span>
    
                    <Typography className={classes.content}>
                        The patient <span style={{color: '#303E4E',fontWeight:"500"}}>{getPatientName({ ...patientInfo, suffix: null })}</span> has a shared email with:
                        <ul>
                            {patientInfo?.shared_email_users?.length > 0 && patientInfo?.shared_email_users.map((item, index)=>(
                                <li style={{color: '#303E4E',fontWeight:"500"}}>{index+1}{". "}{getPatientName({ ...item, suffix: null })}</li>
                            ))} 
                        </ul>
            
                    </Typography>     
                    <Typography className={classes.content}>
                        Allow <span style={{color: '#303E4E',fontWeight:"500"}}>{getPatientName({ ...patientInfo, suffix: null })}</span> access to the patient portal?
                    </Typography>
    
                    <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px', paddingTop: '15px' }}>
                        <Button
                            size="small"
                            className="cancel-btn"
                            onClick={() => setPatientPortalAnchorEl(false)}
                            style={{ marginRight: '10px', color: '#444',fontSize: '18px' }}>
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            style={{
                                background: '#5571C6',
                                borderRadius: '5px !important',
                                fontSize: '18px',
                            }}
                            onClick={() => {
                                handleAccessPatientPortal(!patientInfo?.access_patient_portal)
                                setPatientPortalAnchorEl(false)
                            }}>
                            Allow
                        </Button>
                    </span>
                </div>
            )
        }
        return (
            <div style={{ maxWidth: '350px' }}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                    }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <ReportIcon
                            style={{
                                fontSize: '18px',
                                marginRight: '10px',
                                color: patientInfo?.access_patient_portal ? '#E81A1A' : '#5571C6',
                            }}
                        />
                        <Typography variant="h6" fontWeight="bold">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: patientInfo?.access_patient_portal
                                        ? 'Remove Patient Portal Access'
                                        : 'Allow Patient Portal Access',
                                }}></div>
                        </Typography>
                    </span>
                </span>

                <span style={{ paddingRight: '10px' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            columnGap: 3,
                        }}>
                        {patientInfo?.access_patient_portal
                            ? 'Unchecking the box will turn off access to the patient portal for'
                            : 'Checking the box will allow'}
                        <Typography style={{ color: '#303E4E', fontWeight: 500 }}>
                            {getPatientName({ ...patientInfo, suffix: null })}
                        </Typography>{' '}
                        {patientInfo?.access_patient_portal ? '.' : 'access to the patient portal.'}
                    </div>
                </span>

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        size="small"
                        className="cancel-btn"
                        onClick={() => setPatientPortalAnchorEl(false)}
                        style={{ marginRight: '10px', color: '#444' }}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            background: patientInfo?.access_patient_portal ? '#E81A1A' : '#5571C6',
                            borderRadius: '5px !important',
                        }}
                        onClick={() => {
                            // onEditFieldChange(!patientInfo?.access_patient_portal, 'access_patient_portal')
                            handleAccessPatientPortal(!patientInfo?.access_patient_portal)
                            setPatientPortalAnchorEl(false)
                        }}>
                        {patientInfo?.access_patient_portal ? 'Remove' : 'Allow'}
                    </Button>
                </span>
            </div>
        )
    }

    const handleAccessPatientCariumPortal = async(value)=>{
        try{
            setLoadingPatient(true)
            notification(`Patient is being updated...`, 'warning')
            const res = value ? await deactiveCariumAccess(patientInfo?.id) : await activeCariumAccess(patientInfo?.id)
            if(res?.data?.status === "error"){
                notification(`Couldn't get updated patient details because ${res?.data?.message}`, 'error')
            }else{
                notification(`Patient updated successfully!`, 'success')
            }
            setLoadingPatient(false)
            fetchPatient(patientInfo?.id)
        }catch(err){
            setLoadingPatient(false)
            notification(err?.data?.message || `Couldn't get updated patient details`, 'error')
        }

    }
    const patientPortalDialodCariumContent = () =>{
        return (
            <div style={{ maxWidth: '351px'}}>
                <span
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '10px',
                        paddingTop:'5px'
                    }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        {patientInfo?.carium_id
                        ?
                        <img src={ErrorIcon} alt=""/>
                        :
                        <img src={Taxonomy} alt="" />
                        }

                        <Typography className={classes.cariumHeading}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: patientInfo?.carium_id
                                        ? 'Deactivate Patient in Carium'
                                        : 'Activate patient in Carium',
                                }}></div>
                        </Typography>
                    </span>
                </span>

                
                    <div
                        className={classes.cariumSubtext}
                        style={{
                            // display: 'flex',
                            // flexWrap: 'wrap',
                            // columnGap: 3,
                        }}>
                            By clicking {patientInfo?.carium_id ? 'Deactivate' : 'Activate'} below you will {patientInfo?.carium_id ? 'deactivate' : 'activate'} the Carium account for <span style={{fontWeight: 500 }}>{getPatientName({ ...patientInfo, suffix: null })} </span>{' '}
                    </div>
                

                <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                    <Button
                        size="small"
                        className="cancel-btn"
                        onClick={() => setPatientPortalCariumAnchorEl(false)}
                        style={{ marginRight: '10px', color: '#444' }}>
                        Cancel
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        style={{
                            background: patientInfo?.carium_id ? '#E81A1A' : '#5571C6',
                            borderRadius: '5px !important',
                        }}
                        onClick={() => {
                            handleAccessPatientCariumPortal(patientInfo?.carium_id)
                            setPatientPortalCariumAnchorEl(false)
                        }}>
                        {patientInfo?.carium_id ? 'Deactivate' : 'Activate'}
                    </Button>
                </span>
            </div>
        )
    }

    const tabIconFunctions = (iconName) => {
        switch(iconName){
            case 'demographic_form': {
                return {
                    handler: () => history.push(Routes.PATIENT.EDIT.replace(':id', patientInfo?.id), {
                                patientRecord: true,
                            })
                }
            }

            case 'fax_module': {
                return {
                    handler: () => handleControlFaxPopupOpen(true)
                }
            }

            case 'patient_portal_access': {
                return {
                    patientPortalAnchorEl,
                    patientPortalDialodContent,
                    classes,
                    handler: () => setPatientPortalAnchorEl(true),
                    patientPortalAccess: patientInfo?.access_patient_portal
                }
            }

            case 'patient_portal_carium_access': {
                return {
                    patientPortalCariumAnchorEl,
                    patientPortalDialodCariumContent,
                    classes,
                    handler: () => setPatientPortalCariumAnchorEl(true),
                    patientPortalCariumAccess: patientInfo?.carium_id ? true : false
                }
            }

            default: break;
        }
    }

    const flattenFaxData = (data) => {
        if(!data || isObjectNull(data)) return

        return {
            date: moment().format('MM/DD/YYYY'),
            sender: {
                name: 'Telemed2U',
                contact: data?.clinicalPractice?.patient_support_number ?data?.clinicalPractice?.patient_support_number : '(000) 000-0000',
                fax: data?.clinicalPractice?.fax_number ?data?.clinicalPractice?.fax_number: '(000) 000-0000'
            },
            recipient: {
                name: patientInfo?.primary_care_physician !== "" ? patientInfo?.primary_care_physician : "",
                contact: patientInfo?.primary_care_physician_phone !== "" ? patientInfo?.primary_care_physician_phone : "",
                fax: patientInfo?.primary_care_physician_fax !== "" ? patientInfo?.primary_care_physician_fax : "",
            },
            subject: '',
            markAsUrgent: false,
            patient_name: `${patientInfo?.first_name} ${patientInfo?.last_name}`,
/*----------------Upto this we can get from patient info-------------- */
            documentSelection: [
                {
                    label: 'Medical Problems',
                    keyName: 'conditions',
                    total: data?.conditions?.totalCount || 0,
                    content: data?.conditions?.data?.map((conditionsItem, conditionsItemIndex) => ({
                        id: conditionsItem?.id,
                        date: conditionsItem?.time_onset !== "" ? moment(conditionsItem?.time_onset).format('MM/DD/YYYY') : 'N/A',
                        selection: false
                    })) || []
                },
                {
                    label: 'Medications',
                    keyName: 'medications',
                    total: data?.medications?.totalCount || 0,
                    content: data?.medications?.data?.map((medicationItem, medicationItemIndex) => ({
                        id: medicationItem?.id,
                        date: medicationItem?.start_date !== "" ? moment(medicationItem?.start_date).format('MM/DD/YYYY') : 'N/A',
                        name: `${medicationItem?.drug?.brandName}, ${medicationItem?.drug?.genericName}, ${medicationItem?.drug?.strength}, ${medicationItem?.drug?.form}`,
                        selection: false
                    })) || []
                },
                {
                    label: 'Medication Allergies',
                    keyName: 'allergies',
                    total: data?.allergies?.totalCount || 0,
                    content: data?.allergies?.data?.map((allergyItem, allergyItemIndex) => ({
                        id: allergyItem?.id,
                        name: allergyItem?.reaction?.display,
                        selection: false
                    })) || []
                },
                {
                    label: 'Clinical Notes',
                    keyName: 'clinicalNotes',
                    total: data?.clinicalNotes?.totalCount || 0,
                    content: data?.clinicalNotes?.data?.map((cnItem, cnItemIndex) => ({
                        id: cnItem?.id,
                        name: `${cnItem?.practitioner_first_name} ${cnItem?.practitioner_last_name}`,
                        date: moment(cnItem?.encounter?.start).format('MM/DD/YYYY'),
                        selection: false
                    })) || []
                },
                {
                    label: 'Labs',
                    keyName: 'labOrders',
                    total: data?.labOrders?.totalCount,
                    content: data?.labOrders?.data?.map((labItem) => ({
                        id: labItem?.id,
                        date: labItem?.report_service_date !== "" ? moment(labItem?.report_service_date?.split(' ')[0]).format('MM/DD/YYYY') : 'N/A',
                        selection: false
                    }))
                },
                {
                    label: 'Insurance',
                    keyName: 'insurances',
                    total: data?.insurances?.totalCount || 0,
                    content: data?.insurances?.data?.map((insuranceItem, insuranceItemIndex) => ({
                        id: insuranceItem?.id,
                        date: moment(insuranceItem?.insurance?.policy_effective).format('MM/DD/YYYY'),
                        name: insuranceItem?.insurance?.plan?.display,
                        selection: false
                    })) || []
                },
                {
                    label: 'Documents',
                    keyName: 'businessDocuments',
                    total: data?.businessDocuments?.length || 0,
                    content: data?.businessDocuments?.data?.map((documentItem, documentItemIndex) => ({
                        id: documentItem?.id,
                        name: documentItem?.document_name,
                        selection: false
                    })) || []
                }
            ],
        }
    }

    const fetchFaxData = () => {
        setIsLoadingPage(true)
        getFaxDocuments(patientId)?.then((res) => {
            setFaxData({
                rawData: res?.data?.data,
                formattedData: flattenFaxData(res?.data?.data)
            })
        })?.catch((err) => {
            notification('Something went wrong!', 'error')
        })?.finally(() => setIsLoadingPage(false))
    }

    const handleControlFaxPopupOpen = (isOpen = false) => {
        if(isOpen) {
            setOpenFaxPopup(true)
            return
        }
        setOpenFaxPopup(false)
        setFaxData((prevValues) => ({
            ...prevValues,
            formattedData: flattenFaxData(prevValues?.rawData)
        }))
    }

    useEffect(() => {
        (openFaxPopup && !isObjectNull(patientInfo)) && fetchFaxData()
    }, [
        openFaxPopup,
        patientInfo
    ])

    useEffect(async()=> {
        if(prevTab === 3){
            // notification(`Patient is being updated...`, 'warning')
            try {
                await sentErxUpatedTime(patientId, editTime)
            } catch (e) {
                notification(e?.data?.message || 'Cannot get sync patient details', 'error')
                console.error(e)
            }
            // OrderEntrySync(patientId)?.then(() => {
            //     notification(`Patient updated successfully!`, 'success')
            //     fetchPatient(patientId)
            // }).catch(err => {
            //     notification(err?.data?.message || `Cannot get sync patient details`, 'error')
            // })
        }
    },[prevTab])

    useEffect(() => {
        if (location.pathname.endsWith('patient-account')) {
            setActiveTab(1)
        } else if (location.pathname.endsWith('patient-history')) {
            setActiveTab(2)
        } else if (location.pathname.endsWith('patient-order')) {
            setActiveTab(3)
        } else {
            setActiveTab(0)
        }
    }, [location.pathname])

    useEffect(() => {
        if (currentTab === 0) {
            window.history.replaceState(
                {},
                '',
                Routes.PATIENT.DASHBOARD_PATIENT_HEALTH_RECORD.replace(':id', patientId),
            )
        } else if (currentTab === 1) {
            window.history.replaceState(
                {},
                '',
                Routes.PATIENT.DASHBOARD_PATIENT_ACCOUNT.replace(':id', patientId),
            )
        } else if (currentTab === 2) {
            window.history.replaceState(
                {},
                '',
                Routes.PATIENT.DASHBOARD_HISTORY.replace(':id', patientId),
            )
        } else if (currentTab === 3) {
            window.history.replaceState(
                {},
                '',
                Routes.PATIENT.DASHBOARD_ORDER.replace(':id', patientId),
            )
            const startDate = moment().utc()
            if(moment().tz('America/New_York').isDST()){
                setEditTime(startDate.subtract(240, 'minute').format('MM/DD/YYYY HH:mm:ss'))
            }else{
                setEditTime(startDate.subtract(300, 'minute').format('MM/DD/YYYY HH:mm:ss'))
            }
        }
    }, [currentTab, location])

    return (
        <Grid container className={classes.container} direction="column" wrap="nowrap">
            <Grid
                container
                onClick={() => history.replace(Routes.PATIENT.MAIN)}
                style={{
                    cursor: 'pointer',
                    width: 'fit-content',
                }}>
                <KeyboardBackspaceRoundedIcon
                    style={{
                        color: '#5571C6',
                        fontSize: 18,
                    }}
                />
                <Typography className={classes.text_back}>Back to patient list</Typography>
            </Grid>

            <Grid
                container
                justifyContent="space-between"
                style={{
                    height: 'calc(100% - 30px)',
                    marginTop: 16,
                }}
                spacing={2}
                // wrap="nowrap"
            >
                <Grid
                    item
                    xs={12}
                    container
                    // className={classes.paper}
                    id="scroll-patient"
                    // style={{
                    //     paddingBottom: 0,
                    //     minWidth: 375,
                    //     marginRight: 7,
                    //     overflowY: 'auto',
                    // }}
                >
                    <PatientInformation patientInfo={patientInfo} fetchPatient={fetchPatient} loadingPatient={loadingPatient} setLoadingPatient={setLoadingPatient} />
                </Grid>

                {/* Tabs */}
                <Grid
                    item
                    xs={12}
                    container
                    direction="column"
                    // spacing={2}
                    wrap="nowrap"
                    className={'tabLeftMargin'}
                    style={{
                        // marginBottom: 4,
                        // height: '50%',
                        height: '100%',
                        padding: '0px',
                    }}>
                    <Tabs
                        current={currentTab}
                        style={{
                            flex: 1,
                            maxHeight: 'calc(100% - 50px)',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            marginTop: '10px'
                        }}
                        tabs={[
                            {
                                label: 'Patient Health Record',
                                element: () => <HealthRecords patientInfo={patientInfo} />,
                            },
                            {
                                label: 'Patient Account',
                                element: () => <GeneralPatientRecords />,
                            },
                            {
                                label: 'History',
                                element: () => <HistoryCards />,
                            },
                            {
                                label: 'Order Entry',
                                element: () => <OrderEntry patientId={patientId} />,
                            },
                        ]}
                        tabIconFunctions={tabIconFunctions}
                        onChange={(value) => {setPrevTab(currentTab);setActiveTab(+value)}}
                        tabBoxProps={{
                            backgroundColor: '#fff', 
                            borderRadius: '0px 0px 5px 5px', 
                            border: '1px solid #E4E8EE !important', 
                            paddingLeft: '10px', 
                            paddingBottom: '10px'
                        }}
                        sx={{
                            "& .MuiTab-root.Mui-selected": {
                                color: '#000',
                                fontWeight: 'bold'
                            },
                            "& .MuiTabs-indicator": {
                                height: '4px',
                                borderRadius: '5px'
                            },
                        }}
                    />

                    <FaxComponent 
                        openFaxPopup={openFaxPopup}
                        faxData={faxData}
                        setFaxData={setFaxData}
                        handleControlFaxPopupOpen={handleControlFaxPopupOpen}
                        isLoadingPage={isLoadingPage}
                        setIsLoadingPage={setIsLoadingPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
