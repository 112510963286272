import * as actionTypes from '../actionType'
export const changeMultiFilterReferral = (value, field) => {
    return {
        type: actionTypes.ADD_MULTI_FILTER_REFERRAL,
        value,
        field,
    }
}

export const clearSingleFilterReferral = (field) => {
    return {
        type: actionTypes.CLEAR_SINGLE_FILTER_REFERRAL,
        field,
    }
}

export const clearAllFilterReferral = () => {
    return {
        type: actionTypes.CLEAR_ALL_FILTER_REFERRAL,
    }
}
