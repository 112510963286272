export function convertToBase64(str) {
    if (!str || str === '') return ''

    return Buffer.from(str).toString('base64')
}

export function capitalizeFirstLetter(str) {
    if (!str || str === '') return ''

    const returnStr = str
        ?.split(' ')
        ?.map((word) => `${word.charAt(0).toUpperCase()}${word.substring(1)}`)
        ?.join(' ')

    return returnStr
}
export function shortenString(str, shortenLength = 5) {
    if (!str || str === '') return str
    if (str.length > shortenLength) return `${str.slice(0, shortenLength)}...`
    return str
}

export function toSnakeCase (str=null) {
    if(!str || str.length === 0) return ''

    return str?.toLowerCase()?.split(' ')?.join('_')
}
