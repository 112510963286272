import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Grid, Button, Divider, Popover, IconButton, FormLabel } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close';
import { SearchICD10Code } from 'shared/elements/FormItem/SearchComponents'
import { TextBox } from 'shared/elements/FormItem/TextBox'
import { Select } from 'shared/elements/FormItem/Select'
import { DatePicker } from 'shared/elements/DatePicker/DatePicker'
import moment from 'moment'
import { useFormik } from 'formik'
import * as yup from 'yup'
// import { INITIAL_VALUES, PROBLEM_STATUS } from './schema'
import { getMedicalProblem, getPatient, postMedicalProblem, putMedicalProblem } from 'services/Patient/patient'
import { useNotification } from 'shared/elements/Notification'
import { Loading } from 'shared/elements/Loading'
import { usePatient } from 'containers/PatientsSetting/hooks'
import { formatDateTime } from 'utilities/formats'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'
import { LIST_PROCEDURES } from './seed'
import TrashIcon from 'assests/icons/TrashIcon';
import ErrorIcon from 'assests/icons/error-icon.svg'
const useStyles = makeStyles({
    header:{
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        color: "#303E4E"
    },
    loadingButton:{
        color:"white",
        width: "65px",
        height: "30px",
        padding: "6px 12px"
    },
    formLabel: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "16px",
        margin: "4px 0"
    },
    button_cancel:{
        background: '#fff !important',
        color: '#303E4E !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    },
    button_create_form: {
        background: '#5571C6 !important',
        color: '#fff !important',
        fontWeight: '500 !important',
        borderRadius: '5px !important',
        fontSize: '16px !important',
        textTransform: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid #E0E0E0 !important'
    }
})

const radioOptions = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
]

const MedicalSurgicalForm = ({
    handleCloseStaffNote,
    patientId,
    problemId,
    onClose,
    afterActionOk,
    encounter_id
}) => {
    const { patientInfo } = usePatient(patientId)
    const classes = useStyles()
    const notification = useNotification()
    const [dataLoading, setDataLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading]= useState(null)
    const validationSchema = yup.object().shape({
        id: yup.string(),
        procedure: yup.string().required('This field is required!'),
        date: yup
            .date()
            .nullable()
            .typeError('Invalid date')
            .min(yup.ref('patient_birth'), "Surgical date cannot be smaller than the patient's age!")
            .max(moment(), 'Surgical Date can not be in future'),
        patient_id: yup.string(),
        createdAt: yup.string(),
        updatedAt: yup.string(),
    })
    const getProblemDetail = async (patientId, problemId) => {
        try {
            const data = await surgicalHistoryApis.getById(patientId, problemId)
            const resp = await getPatient(patientId)
            let patient_birth
            if (resp.data) {
                patient_birth = moment(resp.data.dob, 'YYYY-MM-DD')
            }
            formik.setValues({ ...data, patient_birth })
        } catch (error) {
            notification(
                error?.data?.message || 'Cannot get medical problem details',
                'error',
            )
        }
    }
    async function getInfoPatient(patientId) {
        try {
            setDataLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                formik.setFieldValue('patient_birth', moment(resp.data.dob, 'YYYY-MM-DD'))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDataLoading(false)
        }
    }
    useEffect(() => {
        const abortController = new AbortController()

        setDataLoading(true)
        Promise.allSettled([
            problemId
                ? getProblemDetail(patientId, problemId)
                : Promise.resolve(),
        ]).finally(() => setDataLoading(false))

        return () => abortController.abort()
    }, [])
    useEffect(() => {
        if (patientId && !problemId) {
            getInfoPatient(patientId)
        }
    }, [patientId])
    const handleSubmit = async (value) => {
        try {
            setIsSubmitting(true)
            const payload= {
                ...value,
                encounter_id:encounter_id,
                date: formatDateTime(value.date , 'MM-DD-YYYY'),
            }
            delete payload['patient_birth']
            if (problemId) {
                await surgicalHistoryApis.patchSurgical(patientId, payload, problemId)
                notification('Surgical History Updated successfully', 'success')
            } else {
                await surgicalHistoryApis.postSurgical(patientId , payload)
                notification('Surgical History Created successfully', 'success')
            }
            setIsSubmitting(false)
            onClose(true)
            afterActionOk()
        } catch (error) {
            setIsSubmitting(false)

            const errMsg =
                error?.data?.message ||
                (problemId
                    ? `Surgical History updated unsuccessfully`
                    : `Surgical History created unsuccessfully`)
            notification(errMsg, 'error')
        }
    }

    const formik = useFormik({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            procedure: '',
            date: null,
            patient_birth: '',
            note: ''
        },
        onSubmit: handleSubmit,
    })
    const openEnd = Boolean(anchorEl)
    const idEnd = openEnd ? 'cstm-simple-popover' : undefined
    return (
        <Grid container>
            <Loading visible={dataLoading} />
            <Grid
                item
                style={{ overflowY: 'hidden', padding: ' 30px', width: '424px', height: '420px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '20px'
                    }}>
                    <Typography style={{ fontSize: "27px", fontWeight: 500, color: 'black' }}className={classes.HeadingColor}>
                    {problemId ? 'Update Surgical History' : 'Add Surgical History'}
                    </Typography>
                </div>
                <Grid item xs={12} style={{marginTop: '5px'}}>
                    <FormLabel component="p" className={classes.formLabel}>
                        Procedure Type<span style={{ color: '#E81A1A' }}>*</span>
                    </FormLabel>
                    <Select
                        required
                        // label="Procedure"
                        style={{ height: '26px' }}
                        name="procedure"
                        value={formik.values.procedure}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                            return <Typography className={classes.placeholder}>Not Chosen</Typography>;
                            }
                            return selected.toLowerCase();
                            }}
                        displayEmpty={true}
                        native={false}
                        // placeholder="Select procedure"
                        options={LIST_PROCEDURES}
                        error={formik.touched.procedure && Boolean(formik.errors.procedure)}
                        helperText={formik.touched.procedure && formik.errors.procedure}
                        onChange={(value) => formik.setFieldValue('procedure', value)}
                    />
                </Grid>
                <Grid item xs={6} style={{marginTop: '12px'}}>
                    <FormLabel component="p" className={classes.formLabel}>
                        Date
                    </FormLabel>
                    <DatePicker
                        // label="Date"
                        name="date"
                        maxDate={moment()}
                        value={formik.values.date}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        notDefaultValue
                        onChange={(value) => {
                            formik.setFieldValue('date', value)
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: '10px'}}>
                    <FormLabel component="p" className={classes.formLabel}>
                        Comment
                    </FormLabel>
                    <TextBox
                        // label="Note"
                        name="note"
                        value={formik.values.note}
                        multiline
                        variant="outlined"
                        minRows={4}
                        placeholder="placeholder"
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                height: '90px !important',
                            },
                            ' & .MuiInputBase-input': {
                                height: '60px !important',
                            },
                        }}
                        onChange={(value) => formik.setFieldValue('note', value)}
                    />
                </Grid>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        marginTop: '8px',
                    }}>
                        
                    <div style={{ height: "", marginTop: "17px", marginBottom: "5px", display: "flex", justifyContent:'flex-end', gap: 10 }}>
                        <LoadingButton
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_cancel}
                            onClick={(event) => {
                                onClose(true)
                            }}>
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            loading={isSubmitting}
                            loadingPosition="start"
                            variant="contained"
                            className={classes.button_create_form}
                            onClick={(event) => {
                                formik.handleSubmit()
                            }}>
                            {problemId ? 'Update surgical history' : 'Add surgical history'}
                        </LoadingButton>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default MedicalSurgicalForm
