import React, { useEffect, useState } from 'react'
import { Grid, Typography, TextField, Box, IconButton, Popover, Button, ListItem, Dialog } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { RemoveCircleOutlineOutlined, AddCircleOutlineOutlined } from '@material-ui/icons'
import RadioOptions from '../reusable/RadioOption'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import DeleteIcon from '../../../assests/icons/DeleteIcon'
import AlertDeleteDialog from '../../shared/Elements/AlertDeleteDialog'
import { usePatientDashboardStyles } from 'containers/PatientsSetting/style'
import Allergies from 'containers/PatientsSetting/view/Allergies'
import CopyBar from 'components/shared/Elements/CopyBar'
import { useNotification } from 'shared/elements/Notification'
import { deleteAllergy, deleteMedication, drugAllergyStatus, getAllergyById, getListAllergies, putAllergy } from 'services/Patient/patient'
import { ReactComponent as MedicationgroupIcon } from '../../../assests/images/medicationgroupIcon.svg'
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Divider from '@mui/material/Divider';
import { AllergiesForm } from 'containers/PatientsSetting/view/Allergies/NewAllergy'
import { NewAllergiesForm } from 'containers/PatientsSetting/view/Allergies/NewAllergyForm'
import TextWithMacrosNew from '../reusable/TextWithMacrosNew'

import { ReactComponent as TrashIcon } from '../../../../src/assests/icons/trash.svg'
import { ReactComponent as ReportIcon1 } from '../../../../src/assests/images/report_icon_1.svg'
import CustomAlertPopOver from 'components/shared/Elements/AlertPopover'
import PreviousCN from '../reusable/PreviousCN'
import copyNew from '../../../../src/assests/icons/copyNew.svg'
import { formatDateTime } from 'utilities/formats'
import { ReactComponent as CapsuleIcon } from 'assests/icons/capsule.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { shortenString } from 'utilities/stringOperations'
import { ReactComponent as EditIcon } from 'assests/icons/edit1_icon.svg'
import { ReactComponent as ActiveToInactiveIcon } from 'assests/icons/activeToInactive.svg'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'

const useStyles = makeStyles({
    MedicationCard: {
        width: "350px",
        // height: "101px",
        borderRadius: "5px",
        border: "1px solid #E0E0E0",
        background: "#FFF",
        // paddingLeft: "10px",
        // paddingRight: "10px",
        // paddingBottom: "15px",
        // paddingTop: "15px",
        cursor: "pointer"

    },
    copy_button: {
        borderRadius: "4px",
        border: "1px solid #D1D1D1",
        background: "#FFF",
        fontSize: "12px",
        color: "#303E4E",
        fontWeight: "400",
        height: "26px"
    },

    cutText: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "200px",
        height: "1.2em",
        whiteSpace: "nowrap",
    },

    itemCard: {
        width: 400,
        height: 500,
    },
    container: {
        // borderRadius: "5px 5px 0px 0px",
        // border: "1px solid #EAECF0",
        background: "#FFF",
        minWidth: '60%',
        // width: '100%',
        display: 'flex',
        flexDirection: 'column',
        // gap: 20,
        // width: "1463px",
        // height: "671px",
        // position: "absolute"
        '& .toggle-switch': {
            display: 'inline-flex',
            border: '1px solid #ccc',
            borderRadius: '4px',
            overflow: 'hidden',
            cursor: 'pointer',
        },
        '& .toggle-option': {
            padding: '4px 8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.3s, color 0.3s',
            fontSize: 12,
            fontWeight: 400,
            fontFamily: 'Rubik',
            backgroundColor: 'white',
            color: '#333',
        },
        '& .toggle-option.active': {
            backgroundColor: '#5571C6 !important',
            color: 'white',
        },
    },

    DividerText: {
        color: "var(--Light-Foreground-Foreground-2, #424242)",
        textAlign: "center",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
    },

    MedicationAllergyButton: {
        // width: "189px",
        // height: "28px",
        borderRadius: "5px",
        background: "#15A452",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        cursor: "pointer",
        padding: '2px 5px',
        gap: 5
        // marginLeft: "5px"
    },

    cardText: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
    },
    HeadingColor: {
        color: "#303E4E",
        fontFamily: "Rubik",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "140%",
    },
    selectWrap: {
        border: '1px solid #516983',
        height: 31,
        width: 171,
        borderRadius: 4,
    },
    labelText: {
        color: '#303E4E',
        fontWeight: '500',
        fontSize: 14,
        letterSpacing: 0,
    },
    textFieldWrap: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 4,
    },
    boxContainer: {
        borderRadius: 4,
    },
    gridContainer: {
        flexDirection: 'row',
        justifyContent: 'start',
        alignItems: 'center',
        padding: 5,
        width: '97%',

    },
    fullWidth: {
        flex: 1,
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: 4,
        width: 28,
    },
    sectionHeading: {
        fontSize: 14,
    },
    sectionHeading_instruction: {
        marginBottom: 16,
        fontSize: 12,
        color: '#9CA9B8',
    },

    PopOver: {
        width: "350px",
        height: "510px",
        padding: "30px",
        borderRadius: "5px",
        border: "1px solid #DEE2E6",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        overflowY: "auto",
    },

    TopContainer: {
        maxWidth: "100%",
        // height: "814px",
        borderRadius: "5px",
        border: "1px solid #EAECF0",
        background: "#F8F9FB",
        paddingLeft: "12px",
        paddingTop: "11px",
        paddingRight: "25px",
        // overflowY: "auto"
        // position: "absolute"
    },

    cardTextTypography: {
        color: "var(--Fonts-Main-Font, #303E4E)",
        textAlign: "left",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16px",
    },
    ellipsePopover: {
        '& .MuiPaper-root': {
            boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)'
        }
    }
})

const defaultValue = {
    allergy: '',
    reaction: '',
}

// const radioOptions = [
//     { label: 'Yes', value: 'Yes',disable:false },
//     { label: 'No known medication allergies', value: 'No', disable:false },
// ]

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const CardComponent = ({ item, onEllipseOpen }) => {
    const classes = useStyles()
    return (
        <div className={classes.MedicationCard}>
            <div style={{ 
                display: "flex", 
                padding: "12px", 
                alignItems: 'center', 
                borderBottom: '1px solid #e0e0e0', 
                justifyContent: 'space-between',
                background: '#f8f9fb'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <CapsuleIcon /> 
                    {item?.allergy?.text?.length >= 28 ?
                        <HtmlTooltip title={capitalizeFirstLetter(item?.allergy?.text)} placement='top' arrow>
                            <Typography className={classes.cardText}>
                                {capitalizeFirstLetter(shortenString(item?.allergy?.text, 28))}
                            </Typography>
                        </HtmlTooltip>
                        :
                        <Typography className={classes.cardText}>
                            {item?.allergy?.text}
                        </Typography>
                    }
                </div>
                
                <div key={item?.id} onClick={(event) => onEllipseOpen(event, item?.id)}>
                    <MoreVertIcon />
                </div>
            </div>

            <div style={{ 
                padding: '12px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 10,
            }}>
                <div style={{ display: "flex", gap: 5 }}>
                    <Typography className={classes.cardTextTypography} style={{ fontWeight: 500 }}>
                        Reaction:
                    </Typography>
                    {item?.reaction?.display?.length > 25 ?
                        <HtmlTooltip title={item?.reaction?.display} placement='top' arrow>
                            <Typography className={classes.cardTextTypography}>
                                {shortenString(item?.reaction?.display, 25)}
                            </Typography>
                        </HtmlTooltip>
                        :
                        <Typography className={classes.cardTextTypography}>
                            {item?.reaction?.display}
                        </Typography>
                    }
                </div>
                <div style={{ display: "flex", gap: 5 }}>
                    <Typography className={classes.cardTextTypography} style={{ fontWeight: 500 }}>
                        Severity:
                    </Typography>
                    {item?.severity?.length > 25 ?
                        <HtmlTooltip title={item?.severity} placement='top' arrow>
                            <Typography className={classes.cardTextTypography}>
                                {shortenString(item?.severity, 25)}
                            </Typography>
                        </HtmlTooltip>
                        :
                        <Typography className={classes.cardTextTypography}>
                            {item?.severity}
                        </Typography>
                    }
                </div>
            </div>

        </div>
    )
}

const CardComponentSkeleton = () => {
    const classes = useStyles()
    return (
        <Box className={classes.MedicationCard}>
            <div style={{ 
                display: "flex", 
                padding: "12px", 
                alignItems: 'center', 
                borderBottom: '1px solid #e0e0e0', 
                justifyContent: 'space-between' 
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <CapsuleIcon /> 
                    <Typography className={classes.cardText}>
                        <Skeleton width={180} />
                    </Typography>
                </div>
                <div>
                    <MoreVertIcon />
                </div>
            </div>
            
            <div style={{ 
                padding: '12px', 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 10,
                background: '#f8f9fb'
            }}>
                <div style={{ display: "flex", gap: 5 }}>
                    <Typography className={classes.cardTextTypography} style={{ fontWeight: 500 }}>
                        Reaction:
                    </Typography>
                    <Typography className={classes.cardTextTypography}>
                        <Skeleton width={58} />
                    </Typography>
                </div>
                <div style={{ display: "flex", gap: 5 }}>
                    <Typography className={classes.cardTextTypography} style={{ fontWeight: 500 }}>
                        Severity:
                    </Typography>
                    <Typography className={classes.cardTextTypography}>
                        <Skeleton width={58} />
                    </Typography>
                </div>
            </div>
        </Box>
    )
}

const PatientAllergiesMedical
    = ({
        fieldName,
        values,
        onChange,
        lastNarrative,
        allergyHistory,
        macros,
        patientId,
        encounter_id,
        handleSetViewHealthyRecord,
        patient,
        formik,
        lastUpdateDate,
        nonEditable
    }) => {
        const classes = useStyles()
        const notification = useNotification()
        const datatag = 0//FOr skeleton view

        const [activeMedication, setActiveMedication] = useState([])
        const [inactiveMedication, setinactiveMedication] = useState([])
        const [allergyId, setAllergyId] = useState(0)
        const [loading, setLoading] = useState(true)
        const [openCopy, setOpenCopy] = useState(false)
        const [copied, setCopied] = useState(false)
        const [currentStatusTab, setCurrentStatusTab] = useState('active')
        const [ellipseAnchorEl, setEllipseAnchorEl] = useState(null)
        const ellipseOpen = Boolean(ellipseAnchorEl),
        ellipseEndId = ellipseOpen ? 'simple-popover' : undefined
        
        const [activationAnchorEl, setActivationAnchorEl] = useState(null)

        const [radioOptions, setRadioOptions] = useState([
            { label: 'Yes', value: 'Yes', disable: false },
            { label: 'No known medication allergies', value: 'No', disable: false },
        ])
        const [valueData, setValueData] = useState(false)
        const [allergyModal, setAllergyModal] = useState({
            status: false,
            itemId: null,
        })
        const classesCard = usePatientDashboardStyles()
        const [anchorEl, setAnchorEl] = useState(null)
        const [popanchorEl, setpopAnchorEl] = React.useState(null)
        const open = Boolean(anchorEl)
        const endId = open ? 'simple-popover' : undefined

        const handleAddMore = (action, index) => {
            const allergies = values?.allergies || []
            if (action === 'add') {
                onChange(`${fieldName}.allergies`, [...allergies, defaultValue])
            } else {
                const updatedValue = allergies.filter((v, i) => i !== index)
                onChange(
                    `${fieldName}.allergies`,
                    updatedValue.length > 0 ? updatedValue : [defaultValue],
                )
            }
        }

        const isEmptyAllergy = (allergy) => {
            return !(allergy.allergy.trim() || allergy.reaction.trim())
        }

        const handleSelectMacro = (value) => {
            let newNarrative = ''
            if (values?.narrative === undefined) {
                newNarrative = value
            } else {
                newNarrative = values.narrative.concat(` ${value}`)
            }

            onChange(`${fieldName}.narrative`, newNarrative)
        }

        const fetchListAllergies = async () => {
            setLoading(true)
            try {
                const resp = await getListAllergies(patientId, 1, 100000)
                const DataCheck = resp.data.data
                const ActiveAllergy = []
                const inActiveAllergy = []
                DataCheck.map((item, idx) => {
                    if (item.status.code === 'active') {
                        // setActiveMedication([...activeMedication, item])
                        ActiveAllergy.push(item)
                    }
                    if (item.status.code === 'inactive') {
                        // setinactiveMedication([...inactiveMedication, item])
                        inActiveAllergy.push(item)
                    }
                })
                // if (formik?.current?.values?.allergy) {
                //     formik.current.values.allergy.active_medication_allergy = [...ActiveAllergy]
                //     formik.current.values.allergy.inactive_medication_allergy = [...inActiveAllergy]
                // }
                const active_medication_allergy = ActiveAllergy.length > 0 ?
                ActiveAllergy.map((item)=>{
                    return{
                        ...item,
                        onset_date: item?.onset_date ? formatDateTime(item?.onset_date, 'MM/DD/YYYY') : ""
                    }
                })
                :
                []
                
                onChange(`${fieldName}.active_medication_allergy`, active_medication_allergy)
                onChange(`${fieldName}.inactive_medication_allergy`, inActiveAllergy)
                setActiveMedication(ActiveAllergy)
                setinactiveMedication(inActiveAllergy)
                setAllergyModal({
                    status: false
                })

            } catch (ex) {
                console.log(ex)
                notification(`Cannot get list allergies!`, 'error')
            } finally {
                setLoading(false)
            }
        }

        useEffect(() => {
            fetchListAllergies()
        }, [])

        useEffect(() => {
            if (patientId && patient.no_allergy) {
                setValueData(true)
            }
        }, [])

        const onEllipseOpen = (event, itemId) => {
            setAllergyId(itemId)
            setEllipseAnchorEl(event?.currentTarget)
        }
        const onEllipseClose = () => {
            setEllipseAnchorEl(null)
            setAllergyId(0)
        }

        const handleClick = (event) => {
            setAllergyId(0)
            setAnchorEl(event.currentTarget);
            setAllergyModal({
                status: !allergyModal.status
            })
        };

        const handleClose = () => {
            setAnchorEl(null);
            setAllergyId(0)
            setAllergyModal({
                status: false
            })
        };

        const AfterActionComplete = () => {
            fetchListAllergies()
            setAnchorEl(null);
            setAllergyId(0)
            // setActiveMedication(formik?.current?.values?.allergy?.active_medication_allergy)
            // setinactiveMedication(formik?.current?.values?.allergy?.inactive_medication_allergy)
        }

        const getData = (e, itemId) => {
            setAllergyId(itemId)
            setAnchorEl(e.currentTarget);
        }

        const styleCreate = () => {
            if (allergyId) {
                return "5px";
            }
            else if (!allergyId && activeMedication.length) {
                return "-10px";
            }
            return "-5px";
        }

        const clickDelete = (e) => {
            setpopAnchorEl(e.currentTarget);
        }

        const confirmDelete = async () => {
            setAnchorEl(null)
            setpopAnchorEl(null);
            setLoading(true)
            await deleteAllergy(patientId, allergyId)
            setAllergyId(0)
            AfterActionComplete()

        }

        function myFunction() {
            const text = typeof lastNarrative === 'string' ? lastNarrative : null
            navigator.clipboard.writeText(text)
            if (text) {
                handleSelectMacro(text)
                setCopied(true)
            }
            handleCloseModal()
        }

        const ModalOpen = () => {
            setOpenCopy(true)
        }
        const handleCloseModal = () => {
            setOpenCopy(false)
        }

        const changeActivationStatus = () => {
            setLoading(true)
            setEllipseAnchorEl(null)
            setActivationAnchorEl(null)
            getAllergyById(patientId, allergyId)?.then(async (response) => {
                const payload = {
                    ...response?.data,
                    status: {
                        code: currentStatusTab === 'active' ? 'inactive' : 'active'
                    }
                }
                await putAllergy(patientId, allergyId, payload)
                AfterActionComplete()
            })?.catch(err => {
                notification('Something went wrong!', 'error')
            })?.finally(() => {
                setLoading(false)
                // setEllipseAnchorEl(null)
                // setActivationAnchorEl(null)
            })
        }

        return (
            // <Box className={classes.TopContainer}>
                <Box className={classes.container} style={{ minHeight: "800px", margin: '30px 0' }}>
                    <Box style={{ 
                        display: "flex", 
                        justifyContent: "space-between", 
                        padding: "6px 10px 6px 12px", 
                        border: "1px solid #E0E0E0",
                        alignItems: 'center',
                        background: '#F8F9FB',
                        borderRadius: '5px'
                    }}>
                        <Typography className={classes.HeadingColor}>Medication Allergies</Typography>
                        <div className='toggle-switch'>
                            <div className={`toggle-option ${currentStatusTab === 'active' ? 'active' : ''}`} onClick={() => setCurrentStatusTab('active')}>Active</div>
                            <div className={`toggle-option ${currentStatusTab === 'inactive' ? 'active' : ''}`} onClick={() => setCurrentStatusTab('inactive')}>Inactive</div>
                        </div>
                        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                            <Button
                                style={{ border: copied ? "1px solid #5571C6" : "1px solid #D1D1D1", opacity: !lastNarrative ? "25%" : "", pointerEvents: !lastNarrative ? "none" : "" }}
                                startIcon={<img src={copyNew} alt="" style={{ height: '15px' }} />}
                                className={classes.copy_button}
                                onClick={() => {
                                    if (!nonEditable) {
                                        ModalOpen()
                                    }
                                }}>
                                {' '}
                                {copied ? "Copied" : "Copy"} {lastUpdateDate}
                            </Button>

                            {!loading && 
                                <Box 
                                    className={classes.MedicationAllergyButton} 
                                    onClick={(e)=>{
                                        if(!nonEditable){
                                            handleClick(e)
                                        }
                                    }}>
                                    <AddCircleOutlineIcon style={{
                                        color: "#FFF",
                                        fontSize: "large"
                                    }} />
                                    <Typography style={{
                                        color: "#FFF",
                                        textAlign: "center",
                                        fontFamily: "Rubik",
                                        fontSize: "13px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                    }}>
                                        Add Medication Allergies
                                    </Typography>
                                </Box>
                            }
                        </div>
                    </Box>

                    {currentStatusTab === 'active' &&
                        (
                            loading ? 
                                <Box style={{ 
                                    display: "flex", 
                                    flexWrap: "wrap", 
                                    alignItems: "center", 
                                    gap: "12px", 
                                    paddingTop: '12px',
                                    overflowY: "auto" }}>
                                    {[1, 2, 3, 4].map((id) => <CardComponentSkeleton key={id} />)}
                                </Box>
                                :
                                activeMedication?.length > 0 ?
                                    <Box style={{ 
                                        display: "flex", 
                                        flexWrap: "wrap", 
                                        alignItems: "center", 
                                        gap: "12px", 
                                        paddingTop: '12px',
                                        overflowY: "auto" }}>
                                        {activeMedication.map((item, idx) => 
                                            <CardComponent item={item} key={item?.id} onEllipseOpen={onEllipseOpen} />
                                        )}
                                    </Box>
                                    :
                                    <></>
                        )
                    }

                    {currentStatusTab === 'inactive' &&
                        (
                            loading ? 
                                <Box style={{ 
                                    display: "flex", 
                                    flexWrap: "wrap", 
                                    alignItems: "center", 
                                    gap: "12px", 
                                    paddingTop: '12px',
                                    overflowY: "auto" }}>
                                    {[1, 2, 3, 4].map((id) => <CardComponentSkeleton key={id} />)}
                                </Box>
                                :
                                inactiveMedication?.length > 0 ?
                                    <Box style={{ 
                                        display: "flex", 
                                        flexWrap: "wrap", 
                                        alignItems: "center", 
                                        gap: "12px", 
                                        paddingTop: '12px',
                                        overflowY: "auto" }}>
                                        {inactiveMedication.map((item, idx) => 
                                            <CardComponent item={item} key={item?.id} onEllipseOpen={onEllipseOpen} />
                                        )}
                                    </Box>
                                    :
                                    <></>
                        )
                    }

                    <Box style={{ paddingTop: 12 }}>
                        <TextWithMacrosNew
                            name={`${fieldName}.narrative`}
                            isDisabled={nonEditable}
                            onChange={onChange}
                            // // macros={macros}
                            rows={12}
                            withoutMacro={false}
                            values={values?.narrative}
                        // placeholder={placeholder}
                        />
                    </Box>
                    
                    <CustomAlertPopOver
                        anchorEl={popanchorEl}
                        handleClose={() => {
                            setpopAnchorEl(null)

                        }}
                        sx={{
                            '& .MuiButtonBase-root': {
                                borderRadius: '4px !important'
                            }
                        }}
                        icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                        title="Remove medication"
                        bodyText={`Are you sure you want to remove this medication from the patient’s record?`}
                        confirmButtonName={'Yes'}
                        confirmButtonColor={'red'}
                        onSuccess={confirmDelete}
                        buttonProps={{
                            styleConfirm: {
                                textTransform: 'capitalize',
                                fontWeight: "500",
                                display: "flex",
                                width: "68px",
                                height: "32px",
                                padding: "8px 20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                fontSize: "15px",
                                color: "#FFF",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontStyle: "normal",
                                lineHeight: "24px",
                                ':hover': {
                                    boxShadow: "none"
                                },
                            },
                            styleCancel: {
                                display: "flex",
                                height: "32px",
                                padding: "6px 12px",
                                alignItems: "center",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "20px",
                                textTransform: 'capitalize'
                            }
                        }}
                    />

                    <CustomAlertPopOver
                        anchorEl={activationAnchorEl}
                        handleClose={() => {
                            setActivationAnchorEl(null)
                        }}
                        sx={{
                            '& .MuiButtonBase-root': {
                                borderRadius: '4px !important'
                            }
                        }}
                        icon={<ReportIcon1 style={{ marginRight: '10px' }} />}
                        title={`Move allergy to ${currentStatusTab === 'active' ? 'inactive' : 'active'}`}
                        bodyText={`Are you sure you want to make this allergy ${currentStatusTab === 'active' ? 'inactive' : 'active'}?`}
                        confirmButtonName={'Yes'}
                        confirmButtonColor={'red'}
                        onSuccess={changeActivationStatus}
                        buttonProps={{
                            styleConfirm: {
                                textTransform: 'capitalize',
                                fontWeight: "500",
                                display: "flex",
                                width: "68px",
                                height: "32px",
                                padding: "8px 20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "5px",
                                fontSize: "15px",
                                color: "#FFF",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontStyle: "normal",
                                lineHeight: "24px",
                                ':hover': {
                                    boxShadow: "none"
                                },
                                pointerEvents: loading ? 'none' : 'auto'
                            },
                            styleCancel: {
                                display: "flex",
                                height: "32px",
                                padding: "6px 12px",
                                alignItems: "center",
                                textAlign: "center",
                                fontFamily: "Rubik",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "20px",
                                textTransform: 'capitalize',
                                pointerEvents: loading ? 'none' : 'auto'
                            }
                        }}
                    />

                    <Dialog
                        id={endId}
                        open={open}
                        // anchorEl={anchorEl}
                        onClose={handleClose}
                        // anchorOrigin={{
                        //     vertical: allergyId ? 'bottom' : 'top',
                        //     horizontal: allergyId ? 'right' : 'left'
                        // }}
                        // transformOrigin={{
                        //     vertical: allergyId ? 'bottom' : 'top',
                        //     horizontal: allergyId ? 'left' : 'left'
                        // }}
                        style={{ marginLeft: `${styleCreate()}` }}
                    >
                        <Box className={classes.PopOver}>
                            <Box style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // paddingBottom: "4px",
                                // paddingTop: "12px"
                            }}>
                                <Typography 
                                    style={{ fontSize: "24px", fontWeight: 500, color: 'black' }} 
                                    className={classes.HeadingColor}>
                                    {allergyId ? 'Update Medication Allergy' : 'Add Medication Allergy'}
                                </Typography>
                                
                            </Box>
                            {/* <Divider style={{ background: "#E9ECF1" }} /> */}
                            <NewAllergiesForm
                                patientId={patientId}
                                encounter_id={encounter_id}
                                allergyId={allergyId}
                                nonEditable
                                // formik={formik}
                                AfterActionComplete={AfterActionComplete}
                                handleClose={handleClose}
                            />
                        </Box>

                    </Dialog>

                    <Popover
                        id={ellipseEndId}
                        open={ellipseOpen}
                        anchorEl={ellipseAnchorEl}
                        onClose={onEllipseClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                        // style={{ marginLeft: `${styleCreate()}` }}
                        className={classes.ellipsePopover}
                    >
                        <Box style={{ padding: '12px 0px', display: 'flex', flexDirection: 'column', gap: 12 }}>
                            <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={(event) => getData(event, allergyId)}>
                                <EditIcon />
                                <Typography>Edit</Typography>
                            </ListItem>

                            <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={(event) => setActivationAnchorEl(event?.currentTarget)}>
                                <ActiveToInactiveIcon />
                                <Typography>Move to {currentStatusTab === 'active' ? 'inactive' : 'active'}</Typography>
                            </ListItem>

                            <ListItem
                                style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                                onClick={clickDelete}>
                                <TrashIcon />
                                <Typography>Remove</Typography>
                            </ListItem>
                        </Box>
                    </Popover>

                    {openCopy && (
                        <PreviousCN
                            open={openCopy}
                            handleClose={handleCloseModal}
                            categoryName={'Medications'}
                            lastNarrative={lastNarrative}
                            lastUpdateDate={lastUpdateDate}
                            narrativeText={'Medications'}
                            handleChangecopy={myFunction}
                        />
                    )}
                </Box >
            // </Box >
        )
    }

export default PatientAllergiesMedical
