import React, { useEffect } from 'react'
import HeaderTableIcon from '../../../assests/icons/HeaderTableIcon'
import {
    Typography,
    Box,
    Grid,
    Radio,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import questions from '../../../containers/ClinicalNotes/Behavioral/questions'
import { ClinicalNoteContext } from '../../../containers/ClinicalNotes/context/ClinicalNoteContext'
import _ from 'lodash'
import NarrativeAndMacro from '../common/NarrativeAndMacro'
import DataGridCustom from '../reusable/DataGridCustom'
import VideoChat from '../../TwilioVideo/VideoChat'

const useStyles = makeStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },

    subHeadingText: {
        fontSize: 12,
        color: '#9CA9B8',
    },

    columnText: {
        fontSize: 14,
        color: '#667689',
    },

    columnHead: {
        fontSize: 14,
        fontWeight: '500',
        marginRight: 8,
    },
})

const data = [
    {
        id: 1,
        name: 'Family',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 2,
        name: 'Friends',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 3,
        name: 'Relationships',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 4,
        name: 'Education',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 5,
        name: 'Economic',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 6,
        name: 'Occupational',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 7,
        name: 'Housing',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 8,
        name: 'Legal',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
    {
        id: 9,
        name: 'Health',
        none: false,
        mild: false,
        moderate: false,
        severe: false,
    },
]

const renderRadio = (value, isChecked, onChange) => {
    return (
        <Box sx={{ textAlign: 'center', width: '100%' }}>
            <Radio
                color="primary"
                value={value}
                checked={isChecked}
                onChange={onChange}
            />
        </Box>
    )
}

const filterSection = (master = [], key) => {
    return master.filter((m) => m.section_name === key)
}

const Stressors = ({
    fieldName,
    master,
    values,
    onChange,
    macros,
    nonEditable
}) => {
    const classes = useStyles()
    const categoryOptions = filterSection(
        master?.stressorMasters,
        'category',
    )
    const levelOptions = filterSection(
        master?.stressorMasters,
        'level',
    )

    const columns = [
        {
            headerName: <></>,
            headerClassName: 'super-app-theme--header',
            field: 'name',
            flex: 1,
            sortable: false,
            renderCell: ({ value }) => {
                return (
                    <Typography
                        className={classes.columnText}
                        variant="body1">
                        {value}
                    </Typography>
                )
            },
        },
    ]

    const handleRadioChange = (e, row) => {
        const { stressor_opt } = values
        const existIndex = stressor_opt.findIndex(
            (so) => so.stressor_category === row._id,
        )
        const updatedValue = [...stressor_opt]
        if (existIndex >= 0) {
            updatedValue[existIndex] = {
                stressor_category: row._id,
                stressor_level: e.target.value,
            }
        } else {
            updatedValue.push({
                stressor_category: row._id,
                stressor_level: e.target.value,
            })
        }

        onChange(`${fieldName}.stressor_opt`, updatedValue)
    }

    levelOptions.forEach((lo) => {
        columns.push({
            headerName: (
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        width: '100%',
                        // textAlign: 'center',
                    }}>
                    <Typography
                        className={classes.columnHead}
                        variant="body2">
                        {lo.name}
                    </Typography>
                    {/* <HeaderTableIcon /> */}
                </Grid>
            ),
            headerClassName: 'super-app-theme--header',
            field: lo.name,
            flex: 1,
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value, row }) => {
                const handleChange = (e) => {
                    if(!nonEditable){
                        handleRadioChange(e, row)
                    }
                }
                const selectedEl =
                    values?.stressor_opt?.find(
                        (so) =>
                            so.stressor_category ===
                            row._id,
                    )
                const isChecked =
                    selectedEl?.stressor_level === lo._id

                return renderRadio(
                    lo._id,
                    isChecked,
                    handleChange,
                )
            },
        })
    })

    return (
        <Box className={classes.container}>
            <Typography className={classes.subHeadingText}>
                {questions.stressors}
            </Typography>
            {/* <VideoChat /> */}

            <DataGridCustom
                tableProps={{
                    rows: categoryOptions,
                    columns,
                    hideFooter: true,
                }}
            />
            <Grid item>
                <NarrativeAndMacro
                    fieldName={fieldName}
                    onGenerateClick={onChange}
                    macros={macros}
                    onChange={onChange}
                    values={values}
                    meta={{
                        values,
                        master: master?.stressorMasters,
                    }}
                    narrativeValue={values.narrative}
                    nonEditable={nonEditable}
                />
                {/* <TextWithMacro
                    name={`${fieldName}.narrative`}
                    onChange={onChange}
                    macros={macros}
                    rows={12}
                    value={values.narrative}
                /> */}
            </Grid>
        </Box>
    )
}

export default Stressors
