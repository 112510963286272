/* eslint-disable no-use-before-define */
import _ from 'lodash'
import * as actionType from '../actionType'
import {
    formatDate,
    getNameFormat,
    formatPhone,
} from './../../utilities'

const initialState = {
    patientDetail: {},
    patientProfile: {},
    patientSearchList: [],
    searchMeta: {
        currentPage: 1,
        totalCount: 0,
    },
    patientList: [],
    patientEditSuccessMsg: '',
    insuranceFrontURL: '',
    insuranceBackURL: '',
    openBHForm: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_PATIENT_DETAIL: {
            return {
                ...state,
                patientDetail: action.value,
            }
        }

        case actionType.SET_SEARCH_PATIENT_LIST: {
            const patientSearchList = action.value?.result
                ?.length
                ? formatPatientSearchResult(
                      action.value?.result,
                  )
                : []

            // const totalCount =
            //     Math.ceil(totalPatientRecord / 10) ?? 0;
            return {
                ...state,
                patientSearchList,
                searchMeta: {
                    currentPage:
                        action.value?.paging?.start ?? 1,
                    totalCount: action.value?.paging?.total,
                },
            }
        }

        case actionType.UPDATE_SEARCH_PATIENT_STATUS: {
            const { userId, status } = action.value

            return {
                ...state,
                patientSearchList:
                    state.patientSearchList.map(
                        (patient) => {
                            const { user_id, is_active } =
                                patient

                            return {
                                ...patient,
                                is_active:
                                    user_id === userId
                                        ? status
                                        : is_active,
                            }
                        },
                    ),
            }
        }
        case actionType.SET_PATIENT_LIST:
            return {
                ...state,
                patientList: action.value,
            }

        case actionType.SET_PATIENT_PROFILE: {
            return {
                ...state,
                patientProfile: action.value,
            }
        }

        case actionType.RESET_PATIENT_PROFILE: {
            return {
                ...state,
                patientProfile: {},
            }
        }

        case actionType.SET_EDIT_PATIENT_PROFILE: {
            return {
                ...state,
                patientEditSuccessMsg: action.value,
            }
        }
        case actionType.LOGOUT: {
            return initialState
        }
        case actionType.FRONT_INSURANCE_CARD: {
            return {
                ...state,
                insuranceFrontURL: action.value,
            }
        }
        case actionType.BACK_INSURANCE_CARD: {
            return {
                ...state,
                insuranceBackURL: action.value,
            }
        }
        case actionType.OPEN_BH_SCALE_UPLOAD_FORM: {
            return {
                ...state,
                openBHForm: action.value,
            }
        }
        default:
            return state
    }
}

const formatPatientSearchResult = (patientList) =>
    patientList.map((patient) => {
        patient.user = {
            mob: patient.mob,
            email: patient.email,
            is_active: true,
        }
        const {
            first_name: firstName,
            last_name: lastName,
            dob,
            clinic,
            user_id,
            user: { mob, email, is_active },
        } = patient

        const patientName =
            patient.firstName && patient.lastName
                ? getNameFormat(firstName, lastName)
                : patient.patientName

        return {
            patientName,
            dob: formatDate(dob),
            phoneNumber: formatPhone(
                mob?.replace(/\D/g, '')?.slice(-10),
            ),
            email: _.isString(email)
                ? email.toLowerCase()
                : '',
            associatedClinics: clinic?.clinic_name ?? '-1',
            is_active,
            user_id,
        }
    }) ?? []
