import { Box, Checkbox, FormControlLabel, Grid, Radio, Typography } from '@mui/material'
import 'moment-timezone'
import moment from 'moment'
import React from 'react'

import DatePicker from 'shared/elements/DatePicker/DatePicker'
import { Select } from 'shared/elements/FormItem/Select'
import RefreshIcon from 'assests/icons/RefreshIcon'
import TextBox from 'shared/elements/FormItem/TextBox'
import './styles.scss'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton'
import Stack from '@mui/material/Stack';
const OPTIONS_TYPE_DATE = [
    { key: 'week', value: 'Week' },
    { key: 'month', value: 'Month' },
    { key: 'weekdays', value: 'Weekday' },
    { key: 'day', value: 'Day' },
]

const days = [
    { key: 'Sun', value: 'S' },
    { key: 'Mon', value: 'M' },
    { key: 'Tue', value: 'T' },
    { key: 'Wed', value: 'W' },
    { key: 'Thu', value: 'TH' },
    { key: 'Fri', value: 'F' },
    { key: 'Sat', value: 'S' },
]


const RecurringForm = ({ formik, classes, save, cancel }) => {
    return (
        <span style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            backgroundColor: 'white',
            position: 'relative',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: '5px 5px 5px 0',
        }}>

            <Box style={{ width: "100%", padding: "30px", background: "white", marginRight: "20px", boxShadow: '0 0 5px 2px  #e4e4e4', }} >
                <Box
                    className="group-check-label"
                    sx={{
                        color: '#333',
                        '& .MuiFormControlLabel-root': {
                            marginRight: '6px',
                        },
                        '& .MuiCheckbox-root': {
                            paddingRight: '10px',
                        },
                    }}>
                </Box>

                <Box>
                    <Grid spacing={2} container direction="column" >
                        <Grid item xs={6} className="reoccurring-left" style={{ padding: "20px" }} >
                            <Box className="repeat-every" marginBottom={'10px'}>
                                <Typography style={{ marginBottom: 8 }}>
                                    Repeat every <span className={classes.required_text}>*</span>
                                </Typography>
                                <Box display={'flex'} flexWrap="wrap">
                                    <Box width={'67px'} marginRight="10px">
                                        <TextBox
                                            name="once_every"
                                            value={formik.values.once_every}
                                            onChange={(value) => {
                                                if (!/^[1-9]\d*$/.test(value) && value) return
                                                formik.setFieldValue('once_every', value)
                                            }}
                                            disabled={formik.values.unit === 'weekdays'}
                                            placeholder="#"
                                        />
                                    </Box>
                                    <Box width={'calc(100% - 77px)'}>
                                        <Select
                                            value={formik.values.unit}
                                            name="unit"
                                            placeholder="Select..."
                                            options={OPTIONS_TYPE_DATE}
                                            onChange={(value) => {
                                                if (value === 'weekdays') {
                                                    formik.setFieldValue('days', [
                                                        'Mon',
                                                        'Tue',
                                                        'Wed',
                                                        'Thu',
                                                        'Fri',
                                                    ])
                                                    formik.setFieldValue('once_every', 1)
                                                } else {
                                                    formik.setFieldValue('days', [])
                                                }
                                                formik.setFieldValue('unit', value)
                                            }}
                                        />
                                    </Box>
                                    {((formik.touched.once_every && !!formik.errors.once_every) ||
                                        (formik.touched.unit && !!formik.errors.unit)) && (
                                            <p
                                                style={{
                                                    margin: '8px 0 0 0',
                                                }}
                                                className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                                                {formik.errors.once_every || formik.errors.unit}
                                            </p>
                                        )}
                                </Box>
                            </Box>
                            <Box className="repeat-on">
                                <RepeatOne
                                    disabled={
                                        formik.values.unit === 'weekday' || formik.values.unit === 'day'
                                    }
                                    repeatOne={formik.values.days}
                                    isRecur={formik.values.is_reoccurring_appointment}
                                    onChangeDay={(day) => {
                                        if (day) {
                                            let listD = [...formik.values.days]
                                            const unique = listD.find((item) => item === day)
                                            if (unique) {
                                                listD = listD.filter((item) => item !== day)
                                            } else {
                                                listD.push(day)
                                            }
                                            formik.setFieldValue('days', listD)
                                        }
                                    }}
                                    classes={classes}
                                    formik={formik}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <EndRepeat
                                formik={formik}
                                type={formik.values.end_type}
                                onChangeType={(type) => {
                                    if (type === 'ON_YEAR') {
                                        formik.setFieldValue(
                                            'end_date',
                                            moment(formik.values.date, 'YYYY-MM-DD')
                                                .add(1, 'year')
                                                .format('MM/DD/YYYY'),
                                        )
                                    }
                                    formik.setFieldValue('end_type', type)
                                }}
                                end={formik.values.end_date}
                                onChangeValues={(value) => {
                                    formik.setFieldValue('end_date', moment(value).format('YYYY-MM-DD'))
                                }}
                                classes={classes}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ display: "flex", justifyContent: "end", paddingRight: "20px" }}>
                            <Stack spacing={1} direction="row">
                                <Button style={{ background: "unset" }} onClick={() => {
                                    formik.setFieldValue('once_every', '')
                                    formik.setFieldValue(
                                        'end_date',
                                        ''
                                    )
                                    formik.setFieldValue('unit', '')
                                    formik.setFieldValue('end_type', '')
                                    formik.setFieldValue('days', [])
                                    cancel()

                                }}>Cancel</Button>
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="contained"
                                    onClick={() => {
                                        formik.handleSubmit()
                                        // save()
                                    }}
                                >
                                    Save
                                </LoadingButton>
                            </Stack>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    mt: '10px',
                    backgroundColor: 'white',
                    content: '""',
                    display: 'block',
                    width: 15,
                    height: 15,
                    transform: 'rotate(-135deg)',
                    left: "calc(100% - 34px)",
                    boxShadow: '-4px 4px 6px -3px #888888',
                    bottom: "158px"
                }}
            />
        </span>
    )
}

function EndRepeat({ type, onChangeType, onChangeValues, end, classes, formik }) {
    return (
        <Box className="ends-repeat">
            <Typography>
                Ends <span className={classes.required_text}>*</span>
            </Typography>
            <Box className="group-radio-datePicker">
                <Box className="left-radio">
                    <FormControlLabel
                        sx={{
                            paddingLeft: '10px',
                            '& .MuiRadio-root': {
                                paddingRight: '5px',
                            },
                        }}
                        value="ON"
                        control={
                            <Radio
                                checked={type === 'ON'}
                                onChange={(e) => onChangeType(e.target.value)}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                }}
                            />
                        }
                        label="On"
                    />
                </Box>
                <Box className="right-picker">
                    <DatePicker
                        noAway
                        disabled={type !== 'ON'}
                        value={end}
                        onChange={onChangeValues}
                        minDate={moment()}
                    />
                </Box>
            </Box>
            <Box>
                <FormControlLabel
                    sx={{
                        paddingLeft: '10px',
                        '& .MuiRadio-root': {
                            paddingRight: '5px',
                        },
                    }}
                    value="ON_YEAR"
                    control={
                        <Radio
                            checked={type === 'ON_YEAR'}
                            onChange={(e) => onChangeType(e.target.value)}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 18,
                                },
                            }}
                            disabled
                        />
                    }
                    label="In one year"
                />
            </Box>

            {formik.touched.end_date && Boolean(formik.errors.end_date) && (
                <p
                    style={{
                        margin: '8px 0 0 0',
                    }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                    {formik.errors.end_date}
                </p>
            )}
        </Box>
    )
}

function RepeatOne({ repeatOne, onChangeDay, isRecur, classes, formik, disabled }) {
    return (
        <Box className={`wrap-repeat-one ${disabled ? 'disabled' : ''}`}>
            <Typography
                style={{
                    marginBottom: 8,
                }}>
                Repeat on <span className={classes.required_text}>*</span>
            </Typography>
            <Box className="list-btn-days">
                {days.map((day, index) => {
                    const selected = repeatOne?.find((item) => item === day.key)
                    return (
                        <Box
                            key={index}
                            className={`item-day ${!!selected && isRecur ? 'active' : ''}`}
                            onClick={() => onChangeDay(day.key)}>
                            {day.value}
                        </Box>
                    )
                })}
            </Box>
            {formik.touched.days && Boolean(formik.errors.days) && (
                <p
                    style={{
                        margin: '8px 0 0 0',
                    }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error helper-text">
                    {formik.errors.days}
                </p>
            )}
        </Box>
    )
}

export default RecurringForm
