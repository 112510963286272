import * as actionType from '../actionType';

const initialState = {
    visible: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionType.SET_SIDEBAR_VISIBILITY: {
            return {
                ...state,
                visible: action.value,
            };
        }

        default:
            return state;
    }
}