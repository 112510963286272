import AddIcon from '@mui/icons-material/Add'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Button as ButtonCustom } from 'components/custom'
import React, { useRef } from 'react'
import format from 'date-fns/format'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Box, Link } from '@mui/material'
import Stack from '@mui/material/Stack/Stack'
import { DataGrid as MuiDataGrid, GridColumns } from '@mui/x-data-grid'
import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import Routes from 'constants/routes'
import { DataGridCell } from 'containers/PatientsSetting/shared'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Tab, Tabs, makeStyles } from '@material-ui/core'
import {
    allApiReffringProvider,
    apiRaferrals,
    apiStatusCodes,
    getLocations,
    getSpecialty,
} from 'services/Calendar'
import { NoRowsOverlayEl } from 'shared/elements'
import { EditNewAction } from 'shared/elements/FormItem/EditNewAction'
import { SelectSearchOptions } from 'shared/elements/FormItem/SelectSearchOptions'
import { useNotification } from 'shared/elements/Notification'
import { Pagination } from 'shared/elements/Pagination'
import { useStyles } from './styles'
import DatePickerInput from './View/DatePickerInput'
import moment from 'moment'
import { DATE_FORMAT } from './View/Constant'
import { StatusEdit } from './StatusEdit'
import MultipleSelect from 'shared/elements/Select/MultipleSelect'
import { useDispatch, useSelector } from 'react-redux'
import MultiFilter from '../../../shared/elements/Select/MultiFilter'
import {
    changeMultiFilterReferral,
    clearAllFilterReferral,
    clearSingleFilterReferral,
} from 'store/actions/referral'
import SingleCalenderComp from 'shared/elements/DatePickerCustom/SingleCalenderComp'
import DateRangeComp from 'shared/elements/DatePickerCustom/DateRangePicker/DateRangeComp'
import HtmlTooltip from 'components/shared/Elements/HTMLToolTip'
import ToggleSwitch from 'components/shared/Elements/ToggleSwitch'
import { _getClinicalNotesFilterPatient } from 'containers/ClinicalNotes/api/services'
import { useDebounce } from 'shared/hooks'
import { withStyles } from '@material-ui/styles'
import { getListPractices } from 'services/Setting'

const InputNumberComponent = ({ value, row, getReferral, setLoading, notification }) => {
    const [click, setClick] = useState(false)
    const inputRef = useRef(null)
    const [inputVal, setInputVal] = useState(value ?? 0)

    const updateReferrals = async () => {
        try {
            setLoading(true)
            const res = await apiRaferrals.update({ contact_attempt: inputVal }, row?.id)
            notification('Create Referral updated !', 'success')
            if (res?.status === 200) {
                getReferral(1)
            }
        } catch (error) {
            // console.log(error)
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }
    const keyDownEvent = (event) => {
        if (event.key === 'Enter') {
            updateReferrals()
            setClick(false)
        }
    }
    useEffect(() => {
        inputRef?.current?.addEventListener('keydown', keyDownEvent)
        inputRef?.current?.addEventListener('blur', () => setClick(false))
        return () => {
            inputRef?.current?.removeEventListener('keydown', keyDownEvent)
            inputRef?.current?.removeEventListener('blur', () => setClick(false))
        }
    }, [click, inputVal])

    const enterInput = (e) => {
        if (e?.target?.value < 0) {
            setInputVal(0)
        } else {
            setInputVal(e?.target?.value)
        }
    }
    {
        return click ? (
            <div
                onClick={(e) => e.stopPropagation()}
                style={{
                    position: 'relative',
                    cursor: 'pointer',
                }}>
                <input
                    ref={inputRef}
                    style={{
                        width: '80%',
                        height: '32px',
                        border: '1px solid #D9D9D9',
                        borderRadius: '2px',
                        background: 'rgba(219, 222, 229, 0.25)',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    type="number"
                    onChange={enterInput}
                    value={inputVal}
                />
            </div>
        ) : (
            <p
                onClick={(e) => {
                    e.stopPropagation()
                    setClick(true)
                }}>
                {inputVal}
            </p>
        )
    }
}

const truncateToPositiveInteger = (value) => {
    return Math.floor(Math.max(0, value))
}
export const Referrals = () => {
    const notification = useNotification()
    const classes = useStyles()
    const history = useHistory()
    const LIMIT = 100
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [referralList, setReferralList] = useState([])
    const [listOptionStatus, setListOptionStatus] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [activeRange, setActiveRange] = useState(false)
    const [filedDueSchedule, setFiledDueSchedule] = useState([])
    const userPermission = useSelector((state) => state?.permission?.referrals)
    const [patientData, setPatientData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPageNoOfPatient, setTotalPageNoOfPatient] = useState(null)
    const [bundleId, setBundleId] = useState(null)
    const [singleDate, setsingleDate] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [subValue, setSubValue] = useState('in_progress')

    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient: '',
    })
    const [isAccordionExpanded, setIsAccordionExpanded] = useState({
        status: false,
        provider: false,
        location: false,
        specialty: false,
        patient: false,
        clincialPractice: false,
        source: false,
    })
    const [scrollPosition, setScrollPosition] = useState({
        status: null,
        provider: null,
        location: null,
        specialty: null,
        patient: null,
        clincialPractice: null,
        source: null,
    })
    const [loadingPatientOption, setLoadingPatientOption] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient, 500)
    const handleEditClick = (row) => {
        // console.log(row)
        // history.push(
        //     (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.EDIT).replace(':id', row.id),
        // )
        if (userPermission.includes('modify_referral')) {
            const idPatient = row.idPatient
            const idReferral = row.id
            history.push(
                (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                    .replace(':idReferral', idReferral)
                    .replace(':idPatient', idPatient)
                    .replace(':viewType', 'edit'),
            )
        }
    }
    const handleViewClick = (Row) => {
        if (userPermission.includes('view_referral_list')) {
            const idPatient = Row.idPatient
            const idReferral = Row.id
            history.push(
                (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                    .replace(':idReferral', idReferral)
                    .replace(':idPatient', idPatient)
                    .replace(':viewType', 'view'),
            )
        }
    }
    const getReferralStatus = (status) => {
        if (subValue === 'in_progress' && status?.length === 0) {
            return 'in_progress'
        } else if (subValue === 'Completed' && status?.length === 0) {
            return 'Completed'
        } else if (subValue === 'in_progress' && status?.length) {
            const filteredValue = status?.filter((item) => item.key !== 'Completed')
            return filteredValue?.map((item) => item.key).join()
        } else if (subValue === 'Completed' && status?.length) {
            return 'Completed'
        }
    }

    const handleUpdateStatus = async (referralsID, statusCode) => {
        try {
            setLoading(true)
            await apiRaferrals.update(
                {
                    status: statusCode,
                },
                referralsID,
            )
            notification('Update Referral successfully !', 'success')
            await getReferral(page)
        } catch (error) {
            notification('Something went wrong!', 'error')
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        {
            headerName: 'Patient Name',
            headerClassName: 'super-app-theme--header',
            field: 'patient_last_name',
            flex: 0.5,
            sortable: true,
            sortComparator: (val1, val2) => {
                // const lastName1 = val1.split(' ')[1].toLowerCase()
                // const lastName2 = val2.split(' ')[1].toLowerCase()
                return val1.localeCompare(val2)
            },
            renderCell: ({ row }) => {
                return <Box sx={{ cursor: 'pointer' }}>{row.patient}</Box>
            },
        },
        {
            headerName: 'Source',
            headerClassName: 'super-app-theme--header',
            field: 'is_self_schedule',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => {
                return value ? 'SS' : '-'
            },
        },
        {
            headerName: 'Specialty',
            headerClassName: 'super-app-theme--header',
            field: 'specialty',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Date Due',
            headerClassName: 'super-app-theme--header',
            field: 'due_schedule',
            flex: 0.5,
            sortable: true,
            sortComparator: (a, b) => moment(a).diff(moment(b)),
            renderCell: ({ value }) => moment(value).format('MM/DD/YYYY'),
        },
        {
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Clinical Practice',
            headerClassName: 'super-app-theme--header',
            field: 'patient_clinical_practice_name',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Referring Provider',
            headerClassName: 'super-app-theme--header',
            field: 'refferringProvider',
            flex: 0.7,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Referring Location',
            headerClassName: 'super-app-theme--header',
            field: 'refferringLocation',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value }) => {
                return value || '-'
            },
        },
        {
            headerName: 'Contact Attempts',
            headerClassName: 'super-app-theme--header',
            field: 'contact_attempt',
            flex: 0.5,
            sortable: true,
            renderCell: ({ value, row }) => (
                <InputNumberComponent
                    row={row}
                    value={value}
                    getReferral={getReferral}
                    setLoading={setLoading}
                    notification={notification}
                />
            ),
        },
        {
            headerName: 'Status',
            headerClassName: 'super-app-theme--header',
            field: 'status_code',
            flex: 0.6,
            sortable: false,
            sortComparator: (a, b) => a.code - b.code,
            renderCell: ({ value, row }) => {
                return value?.name ? (
                    <StatusEdit
                        color={value?.color}
                        code={value?.name}
                        options={listOptionStatus.filter((item) => item['value'] !== 'All')}
                        onChange={(code) => {
                            handleUpdateStatus(row.id, code)
                        }}
                    />
                ) : (
                    '-'
                )
            },
        },
        // {
        //     headerName: 'Received Date',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'received_date',
        //     flex: 0.5,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         if (value) {
        //             return moment(value).format('MM/DD/YYYY')
        //         } else {
        //             return '-'
        //         }
        //     },
        // },
        // {
        //     headerName: 'Due To Schedule',
        //     headerClassName: 'super-app-theme--header',
        //     field: 'due_schedule',
        //     flex: 0.5,
        //     sortable: true,
        //     renderCell: ({ value }) => {
        //         if (value) {
        //             return moment(value).format('MM/DD/YYYY')
        //         } else {
        //             return '-'
        //         }
        //     },
        // },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'id',
            sortable: false,
            flex: 0.3,
            renderCell: ({ row }) => (
                <EditNewAction
                    menu={[
                        {
                            text: 'View',
                            handler: () => handleViewClick(row),
                            disable: userPermission.includes('view_referral_list'),
                        },
                        {
                            text: 'Edit',
                            handler: () => handleEditClick(row),
                            disable: userPermission.includes('modify_referral'),
                        },
                        {
                            text: 'Delete',
                            handler: () => handleDeleteLocation(row.id, row),
                            disable: userPermission.includes('delete_referral_list'),
                        },
                    ]}
                    deleteMessage="Are you sure you want to delete this Referrals?"
                />
            ),
        },
    ]

    const handleChangeSubValue = (event, newValue) => {
        // tabContainer(newValue)
        setSubValue(newValue)
        setPage(1)
        setReferralList([])
        // if (newValue === 0) {
        //     setTabName("in_progress")
        // } else if (newValue === 1) {
        //     setTabName("completed")
        // }
    }

    const handleDeleteLocation = async (id, row) => {
        try {
            const payload = {
                patient_id: row.idPatient,
            }
            const res = await apiRaferrals.delete(id, payload)
            notification('Referral is deleted successfully !', 'success')
            getReferral()
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }

    const handlePageChange = (value) => {
        setPage(value)
    }

    const handleCellClick = (params) => {
        const idPatient = params.row.idPatient
        const idReferral = params.row.id
        if (
            userPermission.includes('view_referral_list') &&
            !userPermission.includes('modify_referral')
        ) {
            if (params.field !== 'id' && params.field !== 'status_code') {
                history.push(
                    (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                        .replace(':idReferral', idReferral)
                        .replace(':idPatient', idPatient)
                        .replace(':viewType', 'view'),
                )
            }
        } else if (userPermission.includes('modify_referral')) {
            if (params.field !== 'id' && params.field !== 'status_code') {
                history.push(
                    (Routes.WORK_QUEUES.MAIN + Routes.WORK_QUEUES.REFERRALS.INFORMATION)
                        .replace(':idReferral', idReferral)
                        .replace(':idPatient', idPatient)
                        .replace(':viewType', 'edit'),
                )
            }
        }
    }

    //------------------------------------------------------------------------------------------------------------------------

    const [listOptionProvider, setListOptionProvider] = useState([])
    const [listOptionLocation, setListOptionLocation] = useState([])
    const [listOptionSpecialty, setListOptionSpecialty] = useState([])
    const [listOptionClinicalPractice, setListOptionClinicalPractice] = useState([])

    const dispatch = useDispatch()
    const { filterOptions, totalNoOfFilterUsed } = useSelector((state) => state.referral)
    const getStatusReferral = async () => {
        try {
            const res = await apiStatusCodes.getStatusCodes({
                category: 'REFERRAL',
            })
            if (res.data?.listStatusCode?.data) {
                const allOption = res.data?.listStatusCode?.data.map((item) => ({
                    key: item.code,
                    value: item.name,
                    code: item.code,
                    color: item.color,
                }))
                // allOption = [{ key: '', value: 'All' }, ...allOption]
                setListOptionStatus(allOption)
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const onChangeDueScheduleData = (value) => {
        if (value) {
            const dueDate = [format(value, 'yyyy-MM-dd')]
            if (dueDate) setFiledDueSchedule(dueDate)
        } else {
            setFiledDueSchedule([])
        }
    }
    const getRefferringProvider = async () => {
        try {
            const res = await allApiReffringProvider.getAll({ size: 1000 })
            if (res.data?.data) {
                const allOption = res.data?.data?.map((item) => ({
                    key: item.id,
                    value: `${item.first_name} ${item.last_name}`,
                }))
                // allOption = [{ key: '', value: 'All' }, ...allOption]
                setListOptionProvider(allOption)
            }
        } catch (e) {
            notification('Something went wrong !', 'error')
        }
    }
    const getReferralLocation = async () => {
        try {
            const res = await getLocations({ size: 1000 })
            const locationExternal = res.data?.data?.filter((item) => item.is_external === true)
            let allOPtion
            if (locationExternal) {
                allOPtion = locationExternal.map((item) => ({
                    key: item.id,
                    value: item.name,
                }))
                setListOptionLocation(allOPtion)
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const getReferralSpecialty = async () => {
        try {
            const res = await getSpecialty({ size: 1000 })
            if (res.data?.data) {
                const allOption = res.data?.data?.map((item) => ({
                    key: item.id,
                    value: item.specialty,
                }))
                setListOptionSpecialty(allOption)
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const getClinicalPracticeList = async () => {
        try {
            const resp = await getListPractices(
                    {
                          search: '',
                          asc: '',
                    },
            )
            if (resp.data.data) {
                let allOption = resp.data.data?.map((item) => ({
                    key: item.id,
                    value: item.name,
                }))
                setListOptionClinicalPractice(allOption)
            }
        } catch (error) {
            notification('Something went wrong !', 'error')
        }
    }
    const getPatientlist = React.useCallback(async () => {
        setLoadingPatientOption(true)
        try {
            const payload = {
                page: currentPage,
                size: 20,
                bundle_id: bundleId,
                name: searchTextOffilter.patient,
                search: searchTextOffilter.patient,
                query: searchTextOffilter.patient,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ` ${item.middle_name}` : ''} ${
                        item.last_name
                    }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            let sortedPatientData = [...patientData, ...tempUserList]
            if (searchTextOffilter.patient || currentPage === 1) {
                sortedPatientData = [...tempUserList]
            }
            setPatientData(sortedPatientData)
            // setCurrentPage(1)
            setBundleId(res?.data?.bundle_id)
            const b = res?.data?.paging?.total / 20

            const remainder = res?.data?.paging?.total % 20
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalPageNoOfPatient(result)
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingPatientOption(false)
        }
    }, [
        searchTextOffilter,
        currentPage,
        totalPageNoOfPatient,
        setTotalPageNoOfPatient,
        patientData,
        setPatientData,
        bundleId,
        setBundleId,
        setLoadingPatientOption,
    ])
    const getReferral = async (page = 1) => {
        setLoading(true)
        const joinArrString = (arr) => {
            return arr.map((item) => item.key).join()
        }
        const convertToString = (arr) =>{
            return arr.map(item => item.key.toString());
        }
        const { specialty, location, provider, status, patient, clincialPractice } = filterOptions
        try {
            const statusdata = status?.length ? getReferralStatus(status) : getReferralStatus([])
            const res = await apiRaferrals.getReferralList(
                sortModel[0]?.sort
                    ? {
                          page,
                          limit: LIMIT,
                          referring_provider_id: provider?.length ? joinArrString(provider) : '',
                          specialty_id: specialty?.length ? joinArrString(specialty) : '',
                          location_id: location?.length ? joinArrString(location) : '',
                          patient_id: patient?.length ? joinArrString(patient) : '',
                          status: statusdata ? statusdata : subValue,
                          due_schedule:
                              filedDueSchedule?.length > 0 ? filedDueSchedule?.toString() : '',
                          is_self_schedule: filterOptions?.source?.length > 0 ? true : '',
                          //   [sortModel[0].sort]: sortModel[0].field,
                      }
                    : {
                          page,
                          limit: LIMIT,
                          referring_provider_id: provider?.length ? joinArrString(provider) : '',
                          specialty_id: specialty?.length ? joinArrString(specialty) : '',
                          location_id: location?.length ? joinArrString(location) : '',
                          patient_id: patient?.length ? joinArrString(patient) : '',
                          status: statusdata ? statusdata : subValue,
                          due_schedule:
                              filedDueSchedule?.length > 0 ? filedDueSchedule?.toString() : '',
                          is_self_schedule: filterOptions?.source?.length > 0 ? true : '',
                      },
            )
            const clinicalNoteFilter = clincialPractice?.length ? convertToString(clincialPractice) : []
            const filteredData = clinicalNoteFilter?.length > 0 ? res.data?.listReferral?.data.filter(item => clinicalNoteFilter.includes(item?.patient?.clinical_practice_id)) : res.data?.listReferral?.data;
            const convertData = filteredData.map((item) => ({
                id: item?.id,
                idPatient: item?.patient?.id,
                contact_attempt: item?.contact_attempt,
                patient: `${item?.patient?.first_name || ''} ${item?.patient?.last_name || ''}`,
                patient_last_name: item?.patient?.last_name || '',
                refferringProvider: `${item?.referring_provider?.first_name || ''} ${
                    item?.referring_provider?.last_name || ''
                }`,
                refferringLocation: item?.location?.name,
                specialty: item?.specialty?.specialty,
                status: item?.status,
                received_date: item?.received_date,
                due_schedule: item?.due_schedule,
                status_code: item?.status_code,
                date_time: item?.created_date,
                patient_clinical_practice_name: item?.patient_clinical_practice_name,
                phone_number: item?.patient?.phone_number,
                createdAt: item?.createdAt,
                is_self_schedule: item?.is_self_schedule,
            }))
            // const tempTotalPageData = res.data?.listReferral?.paging?.total / limit

            // const remainder = res.data?.listReferral?.paging?.total % limit
            // let totalPageData
            // if (remainder > 0) {
            //     totalPageData = Math.floor(tempTotalPageData) + 1
            // } else {
            //     totalPageData = tempTotalPageData
            // }

            // if (page === 1) {
            //     setReferralList(convertData || [])
            // } else {
            //     setReferralList((prev) => (convertData && [...prev, ...convertData]) || [])
            // }
            // if (convertData?.length === 0 || totalPageData === page) setHasMore(false)
            setReferralList([...referralList, ...convertData] || [])
            setTotal(res.data?.listReferral?.paging?.total)
            setLoading(false)

            const totalItems = res.data?.listReferral?.paging?.total
            if (totalItems - LIMIT * page > 0) {
                setPage(page + 1)
            }
        } catch (e) {
            notification('Something went wrong !', 'error')
            setLoading(false)
            setHasMore(true)
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        getReferral(page)
        return () => abortController.abort()
    }, [page, filedDueSchedule, filterOptions, subValue])

    useEffect(() => {
        ;(async () => {
            const abortController = new AbortController()
            await Promise.all([
                getReferralSpecialty(),
                getReferralLocation(),
                getRefferringProvider(),
                getStatusReferral(),
                getClinicalPracticeList()
            ])
            return () => abortController.abort()
        })()
    }, [])
    useEffect(() => {
        getPatientlist()
    }, [searchTextOffilter, currentPage, debouncedSearchFilterPatientText])
    // useEffect(() => {
    //     // Add event listener for scroll event when component mounts
    //     const handleScroll = (e) => {
    //         e.preventDefault()
    //         const { scrollTop, clientHeight, scrollHeight } = document.getElementsByClassName(
    //             'MuiDataGrid-virtualScroller',
    //         )[0]
    //         const bottomPosition = scrollHeight - scrollTop - clientHeight

    //         if (
    //             scrollTop !== 0 &&
    //             truncateToPositiveInteger(bottomPosition) === 0 &&
    //             !isLoading &&
    //             hasMore
    //         ) {
    //             setPage((prev) => prev + 1)
    //         }
    //     }
    //     // Attach event listener to the DataGrid component
    //     document
    //         .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
    //         ?.addEventListener('scroll', handleScroll)

    //     // Cleanup function to remove event listener when component unmounts
    //     return () => {
    //         document
    //             .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
    //             ?.removeEventListener('scroll', handleScroll)
    //     }
    // }, [isLoading, hasMore])
    /**
     * Multi Filter Functionlity
     */
    const filterSearch = React.useCallback(
        (value, name) => {
            setCurrentPage(1)
            setSearchTextOffilter({
                ...searchTextOffilter,
                [name]: value,
            })
        },
        [searchTextOffilter, setSearchTextOffilter],
    )
    const handleOnScrollChangePage = React.useCallback(
        async (page) => {
            if (page) {
                if (totalPageNoOfPatient && totalPageNoOfPatient > currentPage) {
                    const nextPage = currentPage + page
                    setCurrentPage(nextPage)
                }
            }
        },
        [totalPageNoOfPatient, setCurrentPage, currentPage],
    )
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [setIsAccordionExpanded, isAccordionExpanded],
    )
    const scrollBarPosition = React.useCallback(
        (value, field) => {
            setScrollPosition({ ...scrollPosition, [field]: value })
        },
        [setScrollPosition, scrollPosition],
    )
    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'provider',
                name: 'Referring Provider',
                value: filterOptions.provider,
                options: listOptionProvider,
                AccordionExpanded: isAccordionExpanded.provider,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.provider,
                scrollBarPosition,
            },
            {
                key: 'patient',
                name: 'Patient',
                value: filterOptions.patient,
                options: patientData,
                filterSearch,
                handleOnScrollChangePage,
                loading: loadingPatientOption,
                AccordionExpanded: isAccordionExpanded.patient,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient,
                scrollBarPosition,
            },
            {
                key: 'clincialPractice',
                name: 'Clincial Practice',
                value: filterOptions.clincialPractice,
                options: listOptionClinicalPractice,
                AccordionExpanded: isAccordionExpanded.clincialPractice,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.clincialPractice,
                scrollBarPosition: scrollBarPosition,
            },
            // {
            //     key: 'location',
            //     name: 'Location',
            //     value: filterOptions.location,
            //     options: listOptionLocation,
            //     AccordionExpanded: isAccordionExpanded.location,
            //     handleAccordionExpand: handleAccordionExpandNew,
            //     setScrollBarPosition: scrollPosition.location,
            //     scrollBarPosition: scrollBarPosition

            // },
            {
                key: 'specialty',
                name: 'Specialty',
                value: filterOptions.specialty,
                options: listOptionSpecialty,
                AccordionExpanded: isAccordionExpanded.specialty,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.specialty,
                scrollBarPosition,
            },
            {
                key: 'status',
                name: 'Status',
                value: filterOptions.status,
                options: listOptionStatus,
                AccordionExpanded: isAccordionExpanded.status,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.status,
                scrollBarPosition,
            },
            {
                key: 'source',
                name: 'Source',
                value: filterOptions.source,
                options: [{ key: 1, value: 'Self Scheduled' }],
                AccordionExpanded: isAccordionExpanded.source,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.source,
                scrollBarPosition,
            },
        ]
    }, [
        listOptionStatus,
        listOptionSpecialty,
        // listOptionLocation,
        listOptionProvider,
        patientData,
        filterOptions,
        filterSearch,
        handleOnScrollChangePage,
        loadingPatientOption,
        isAccordionExpanded,
        handleAccordionExpandNew,
        scrollPosition,
        scrollBarPosition,
        listOptionClinicalPractice
    ])
    const handleClearFilter = React.useCallback(() => {
        setPage(1)
        setReferralList([])
        setHasMore(true)
        dispatch(clearAllFilterReferral())
    }, [dispatch, clearAllFilterReferral])
    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setPage(1)
            setReferralList([])
            // setHasMore(true)
            dispatch(clearSingleFilterReferral(name))
        },
        [dispatch, clearSingleFilterReferral],
    )
    const handleChangeMultiFilter = React.useCallback(
        (option, checked, state, field) => {
            setPage(1)
            setReferralList([])
            // setHasMore(true)
            let arr = [...(state ? state : [])]
            if (checked) {
                arr.push(option)
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            dispatch(changeMultiFilterReferral(arr, field))
        },
        [changeMultiFilterReferral, dispatch],
    )
    const CustomTab = withStyles((theme) => ({
        root: {
            minWidth: 0,
            [theme.breakpoints.up('sm')]: {
                minWidth: 0,
            },
            '&.Mui-selected': {
                '& .MuiTab-wrapper': {
                    background: 'transparent',
                    borderBottom: `3px solid ${theme.palette.primary.main}`,
                    paddingBottom: '8px',
                    paddingTop: '8px',
                },
            },
        },
        wrapper: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
    }))((props) => <Tab {...props} />)

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} style={{ paddingRight: '4px', paddingLeft: '4px' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Tabs
                            value={subValue}
                            onChange={handleChangeSubValue}
                            TabIndicatorProps={{ style: { display: 'none' } }}>
                            <CustomTab
                                style={{ background: 'none', textTransform: 'capitalize' }}
                                label="In Progress"
                                value="in_progress"
                            />
                            <CustomTab
                                style={{ background: 'none', textTransform: 'capitalize' }}
                                label="Completed"
                                value="Completed"
                            />
                        </Tabs>
                    </Grid>
                    <Grid
                        item
                        container
                        flex={1}
                        spacing={1}
                        justifyContent={'flex-end'}
                        style={{ marginTop: '1px' }}>
                        <Grid
                            item
                            style={{
                                width: 300,
                            }}>
                            {!activeRange && (
                                <SingleCalenderComp
                                    onChange={(e) => {
                                        setsingleDate(e ? format(e, 'MM/dd/yyyy') : '')
                                        onChangeDueScheduleData(e)
                                    }}
                                    HideShowIcon={activeRange}
                                    placeHolder="Due to Schedule"
                                    value={singleDate}
                                    onClear={() => {
                                        setsingleDate('')
                                    }}
                                />
                            )}
                            <Box style={{ position: 'absolute' }}>
                                {activeRange && (
                                    <DateRangeComp
                                        style={{ marginTop: '20px' }}
                                        onChange={(e) => {
                                            if (e?.startDate && e?.endDate) {
                                                setStartDate(format(e?.startDate, 'MM/dd/yyyy'))
                                                setEndDate(format(e?.endDate, 'MM/dd/yyyy'))
                                                setsingleDate('')
                                                setFiledDueSchedule([
                                                    format(e?.startDate, 'yyyy-MM-dd'),
                                                    format(e?.endDate, 'yyyy-MM-dd'),
                                                ])
                                                // onChangeDueScheduleData(e)
                                            }
                                        }}
                                        activeRange={activeRange}
                                        dateSeparator="-"
                                        startDate={startDate}
                                        defaultRange={singleDate || null}
                                        endDate={endDate}
                                        onClear={() => {
                                            setStartDate('')
                                            setEndDate('')
                                            setsingleDate('')
                                            setFiledDueSchedule([])
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <HtmlTooltip
                            title={`Turn ${activeRange ? 'off' : 'on'} date range Search`}
                            arrow
                            placement="bottom"
                            style={{
                                background: 'white !important',
                                color: '#303E4E',
                            }}>
                            <Grid item>
                                <Box style={{ marginTop: '12px' }}>
                                    <ToggleSwitch
                                        onChange={() => {
                                            setActiveRange(activeRange ? false : true)
                                            if (filedDueSchedule.length == 2 && activeRange) {
                                                setStartDate('')
                                                setEndDate('')
                                                setFiledDueSchedule([])
                                            }
                                        }}
                                        checked={activeRange}
                                    />
                                </Box>
                            </Grid>
                        </HtmlTooltip>
                    </Grid>
                    <Grid item style={{ marginTop: '5px' }}>
                        <MultiFilter
                            filterOptionsArrayObject={filterOptionsArrayObject}
                            handleClearFilter={handleClearFilter}
                            handleClearSingleFilter={handleClearSingleFilter}
                            handleChange={handleChangeMultiFilter}
                            totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                        />
                    </Grid>
                    {userPermission.includes('create_referral_list') && (
                        <Grid item style={{ marginTop: '8px' }}>
                            <ButtonCustom
                                tooltip="Add New Referral"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                    history.push(
                                        Routes.WORK_QUEUES.MAIN +
                                            Routes.WORK_QUEUES.REFERRALS.CREATE,
                                    )
                                }
                                fullWidth>
                                <AddIcon
                                    style={{
                                        fontSize: 20,
                                    }}
                                />
                            </ButtonCustom>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={12} className={[classes.form_list_container, classes.subtabDiv]}>
                <Box className={classes.wrap_data_grid}>
                    <MuiDataGrid
                        sx={{
                            '& .MuiDataGrid-row': {
                                boxShadow: '-2px -0.1px 1px 0.1px #F6F6F6',
                                border: 2,
                                borderColor: '#F9F9F9',
                                maxWidth: `${
                                    subValue === 'Completed' && referralList.length < 5
                                        ? '99.6%'
                                        : '100%'
                                }`,
                            },
                            '& .MuiDataGrid-virtualScroller': {
                                // paddingRight: 1,
                                overflowY: 'scroll !important',
                                overflowX: 'hidden !important',
                            },
                            '& .MuiDataGrid-virtualScrollerRenderZone': {
                                position: 'relative',
                            },
                        }}
                        // autoHeight={true} // Enable auto height
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        getRowId={(row) => row.id}
                        sortModel={sortModel}
                        rows={referralList}
                        columns={columns}
                        // pageSize={limit}
                        // paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination={false}
                        hideFooterPagination={true}
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl title={'No results found.'} />,
                            // Pagination: () => (
                            //     <Pagination
                            //         currentPage={page}
                            //         totalRecords={total}
                            //         onPageChange={handlePageChange}
                            //     />
                            // ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        onCellClick={handleCellClick}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}
