const present_in_sessions = {
    narrative: '',
    sessions: [
        // {
        //     role: '',
        //     custom_role: '',
        //     name: '',
        //     was_available: false,
        //     participation: '',
        //     decision_maker: '',
        //     comment: '',
        // },
    ],
}

const stressors = {
    narrative: '',
    stressor_opt: [
        // {
        //     stressor_category: '',
        //     stressor_level: '',
        // },
    ],
}

const system_reviews = {
    narrative: '',
    system_reviews_opt: [
        {
            header: '',
            reviews_options: [],
            other: '',
        },
    ],
}

const subtance_abuse_history = {
    is_recreational_drug: '',
    narrative: '',
    subtance_used: [
        // {
        //     subtance: '',
        //     is_used: '',
        // },
    ],
}

const subtance_abuse_treat_history = {
    others: [],
    narrative: '',
    received_treat: true,
    subtance_treat: [
        // {
        //     subtance: '',
        //     is_treat: false,
        // },
    ],
}

const subtance_use_consequences = {
    others: '',
    narrative: '',
    consequences: [],
}

const inpatient_psychiatric_history = {
    narrative: '',
    have_inpatient_psyc_treat: false,
}

const outpatient_psychiatric_history = {
    narrative: '',
    have_outpatient_psyc_treat: false,
}

const suicide_history = {
    tried_to_harm: false,
    intent_to_die: false,
    intent_to_die_desc: '',
    suicide_occurance: 0,
    narrative: '',
}

const violence_history = {
    have_violent_behavior: false,
    violent_behavior_desc: '',
    narrative: '',
}

const past_medical_history = {
    narrative: '',
    primary_care_physician: '',
    taking_med_currently: false,
    has_medical_problem: false,
    has_surgical_history: false,
    medication_details: '',
    health_problems: [],
    other_health_problem: '',
    surgery_area: [],
    other_surgery_area: '',
    medication_list: [],
    medical_problems: [],
    surgical_history: [],
}

const psychiatric_medication_history = {
    med_psychiatric_treat: false,
    psychiatric_treat_desc: [],
}

const allergy = {
    have_any_allergy: false,
    narrative: '',
    allergies: [
        {
            allergy: '',
            reaction: '',
        },
    ],
}

const patient_resources = {
    endocrinology: [
        {
            label: 'Document 1',
            check: true,
        },
        {
            label: 'Document 2',
            check: true,
        },
        {
            label: 'Document 3',
            check: true,
        },
        {
            label: 'Document 4',
            check: true,
        },
    ],
    rheumatology: [
        {
            label: 'Document 1',
            check: true,
        },
        {
            label: 'Document 2',
            check: true,
        },
        {
            label: 'Document 3',
            check: true,
        },
        {
            label: 'Document 4',
            check: true,
        },
    ],
    infectious_diseases: [
        {
            label: 'Document 1',
            check: true,
        },
        {
            label: 'Document 2',
            check: true,
        },
        {
            label: 'Document 3',
            check: true,
        },
        {
            label: 'Document 4',
            check: true,
        },
    ],
}

const medication = [
    {
        medication: '',
        dosage: '',
        sig: '',
        prescriber: '',
    },
]

const family_history = {
    psych_illness_history: false,
    narrative: '',
    additional_med_desc: '',
    history_list: [],
    history_opt: [
        {
            family_member: '',
            psychiatric_problem: [],
        },
    ],
}

//this to be changed
const dev_and_edu_history = {
    complic_after_your_birth: false,
    highest_edu_level: '',
    comment_on_dev_and_edu: '',
    narrative: '',
    // during_preg_prob: {
    //     other: '',
    //     pregnency_options: [],
    // },
    // difficulties_in_reaching: {
    //     other: '',
    //     difficulty_options: [],
    // },
    // childhood_atmos: {
    //     other: '',
    //     home_atmos_options: [],
    // },
    // childhood_challenges: {
    //     // other: string,
    //     challenging_options: [],
    // },
    // have_prob_in_school: {
    //     // other: string,
    //     prob_options: [],
    // },
    // additional_schooling: {
    //     other: '',
    //     additional_options: [],
    // },
}

const general_social_history = {
    current_matrial_status: '',
    intimate_relationship_status: '',
    intimate_satisfaction_level: '',
    sexual_orientation: '',
    current_living_situation: '',
    participate_in_spritual_act: false,
    current_occupation_status: '',
    current_yearly_income: '',
    narrative: '',
    desc_social_situation: {
        social_situation_opt: [],
        other: '',
    },
    currently_live_with_opt: {
        live_with_opt: [],
        other: '',
    },
    longest_period_employment: {
        employment_start: '',
        employment_end: '',
        desc: '',
    },
    longest_period_unemployment: {
        unemployment_start: '',
        unemployment_end: '',
        desc: '',
    },
    social_history_list: [],
}

const menstruation_and_pregnancy_history = {
    begin_menstruation_age: 0,
    premenstrual_symptoms: [],
    contraception_methods: {
        other: '',
        contraception_opt: [],
    },
    narrative: '',
    ever_been_pregnent: undefined,
    how_many_times_pregnent: 0,
    ever_given_birth: undefined,
    no_of_given_birth: 0,
    had_any_miscarriage: undefined,
    no_of_miscarriage: 0,
    had_any_abortion: undefined,
    no_of_abortion: 0,
    menstruation_pregnancy_history_list: [],
}

const vitals = []

const physical_examinations = {}

const exam = {
    // other: '',
    // apperances: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // behavior: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // speech: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // mood: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // affect: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // thought_process: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // thought_content: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // insight_judgement: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // consciousness: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // orientation: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // recent_memory: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // remote_memory: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // attention: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // naming_language: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // repeating_phrase_language: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // abstraction_language: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // fund_of_knowledge: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // station: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // muscle_strength: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // muscle_tone: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
}

const source_of_information = {
    informations: [
        // {
        //     id: 0,
        //     role: '',
        //     custom_role: '',
        //     name: '',
        //     comment: '',
        // },
        // {
        //     id: 1,
        //     role: '',
        //     custom_role: '',
        //     name: '',
        //     comment: '',
        //     isCustomRole: true,
        // },
    ],
    narrative: '',
}

const assessment_sources_of_risk = {
    source_risk_opt: [],
    narrative: '',
}

const suicide_risk_factors = {
    narrative: '',
    suicide_risk_factors: [],
    is_patient_screened: false,
}

const suicide_protective_factors = {
    protective_factors: [],
    narrative: '',
}

const suicide_preventive_plan = {
    other: '',
    address_immense_risk: [],
    addressing_diag: [],
    addressing_symptoms: [],
    addressing_social_factors: [],
    narrative: '',
}

const icd_code = []

const assessment_informed_consent = {
    // treat_consent_given_by: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // confidentiality_disc: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // informed_consent_opt: [],
    narrative: '',
}

const counselling_and_coordination_care = {
    narrative: '',
    // counselling: [],
    // medication_management: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // diagnosis_and_prog: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // patient_self_mgmt: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // recent_stressors: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // coordination_care: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
    // referred_patient: [
    //     {
    //         id: '',
    //         desc: '',
    //     },
    // ],
}

const psychotherapy = {
    psychotherapy_opt: [],
    narrative: '',
}

const plan = {
    plan_opt: [],
    narrative: '',
}

const plan_med_changes = {
    plan_med_opt: [],
    medication_list: [],
    narrative: '',
}

const plan_lab_ordered = {
    lab_ordered_list: [],
}

const plan_next_appointment = {
    plan_next_opt: [],
    narrative: '',
}

const medical_coding = {
    narrative: '',
    deleted_icd_medical_problem: [],
}

const adherence = {
    no_concern: false,
    past_30_medic: 0,
    narrative: '',
}

const side_effects = {
    narrative: '',
    no_known: false,
    side_effect_opt: [],
}

const past_history = {
    narrative: '',
    family_history: '',
    social_history: '',
    past_history: '',
    capture_intake: false,
    capture_intake_excep: false,
}

const cpt_code = [
    {
        discount_note: '',
        courtesy_discount: '',
        modifiers: ['', '', '', ''],
        service_units: '',
        units: '',
        charge: '',
        cpt_code_master_id: '',
    },
]

const lab_results = {
    lab_result_list: [],
}

export const INITIAL_VALUES = {
    patient_resources,
    cpt_code,
    present_in_sessions,
    chief_complaint: '',
    present_illness_history: '',
    stressors,
    system_reviews,
    subtance_abuse_history,
    subtance_abuse_treat_history,
    subtance_use_consequences,
    inpatient_psychiatric_history,
    outpatient_psychiatric_history,
    suicide_history,
    violence_history,
    past_medical_history,
    psychiatric_medication_history,
    allergy,
    medication,
    family_history,
    dev_and_edu_history,
    general_social_history,
    menstruation_and_pregnancy_history,
    vitals,
    exam,
    physical_examinations,
    review_of_measures: '',
    lab_results,
    source_of_information,
    assessment_sources_of_risk,
    suicide_risk_factors,
    suicide_protective_factors,
    suicide_preventive_plan,
    icd_code,
    assessment_impression: '',
    assessment_informed_consent,
    counselling_and_coordination_care,
    psychotherapy,
    plan,
    plan_med_changes,
    plan_lab_ordered,
    plan_next_appointment,
    medical_coding,
    adherence,
    side_effects,
    past_history,
}

export const dirtySessions = {
    cpt_code: false,
    present_in_sessions: false,
    chief_complaint: false,
    present_illness_history: false,
    stressors: false,
    system_reviews: false,
    subtance_abuse_history: false,
    subtance_abuse_treat_history: false,
    subtance_use_consequences: false,
    inpatient_psychiatric_history: false,
    outpatient_psychiatric_history: false,
    suicide_history: false,
    violence_history: false,
    past_medical_history: false,
    psychiatric_medication_history: false,
    allergy: false,
    medication: false,
    family_history: false,
    dev_and_edu_history: false,
    general_social_history: false,
    menstruation_and_pregnancy_history: false,
    vitals: false,
    exam: false,
    review_of_measures: false,
    lab_results: false,
    source_of_information: false,
    assessment_sources_of_risk: false,
    suicide_risk_factors: false,
    suicide_protective_factors: false,
    suicide_preventive_plan: false,
    icd_code: false,
    assessment_impression: false,
    assessment_informed_consent: false,
    counselling_and_coordination_care: false,
    psychotherapy: false,
    plan: false,
    plan_med_changes: false,
    plan_lab_ordered: false,
    plan_next_appointment: false,
    adherence: false,
    side_effects: false,
    past_history: false,
    medical_coding: false,
}
