import { Grid, Typography } from '@material-ui/core'
import userIcon from '../../../../assests/images/icon_user.svg'
import Provider_emp_type from '../../../../assests/images/Provider_emp_type.svg'
import Provider_lan from '../../../../assests/images/Provider_language.svg'
import Provider_time from '../../../../assests/images/Provider_timezone.svg'
import Provider_innw from '../../../../assests/images/Provider_innw.svg'
import Provider_clinic from '../../../../assests/images/Provider_clinics.svg'
import provider_duration from '../../../../assests/images/provider_duration.svg'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { commonMasterData } from '../../../../services/Provider/provider'

const ProviderBackground = (props) => {
    const { providerProfile } = props
    const [languageArr, setLanguageArr] = useState([])
    const [empTypeArr, setEmpTypeArr] = useState([])
    const [timeZoneArr, setTimezoneArr] = useState([])
    const [inNwArr, setInNwArr] = useState([])

    const durationOptions = [
        { value: 5, name: '5 Min' },
        { value: 10, name: '10 Min' },
        { value: 15, name: '15 Min' },
        { value: 20, name: '20 Min' },
        { value: 30, name: '30 Min' },
        { value: 45, name: '45 Min' },
        { value: 60, name: '1 Hour' },
        { value: 120, name: '2 Hour' },
    ]
    const fetchCommonMasterData = async () => {
        try {
            const res = await commonMasterData()
            const empType = []
            const timeZoneOptions = []
            const inNwOptions = []

            res.data.employment_type.map((item) => {
                empType.push({
                    name: item.label,
                    value: item.value,
                })
            })
            res.data.time_zone.map((item) => {
                timeZoneOptions.push({
                    name: item.label,
                    value: item.value,
                })
            })
            res.data.in_network_insurance.map((item) => {
                inNwOptions.push({
                    insurance: item.value,
                    name: item.label,
                })
            })
            setLanguageArr(res.data.language)
            setInNwArr(inNwOptions)
            setEmpTypeArr(empType)
            setTimezoneArr(timeZoneOptions)
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        fetchCommonMasterData()
    }, [])
    const getDurationValue = (value) => {
        const obj = durationOptions.filter(
            (item) => item.value === value,
        )
        return obj[0]?.name
    }
    const getInNwValue = (value) => {
        const obj = inNwArr.filter(
            (item) => item.insurance === value,
        )
        return obj[0]?.name
    }
    const getLanguageLabel = (code) => {
        if (code !== '' && code !== 'none') {
            const obj =
                languageArr &&
                languageArr.filter(
                    (item) => item.value === code,
                )
            return obj && obj[0] && obj[0].label
        }
        return ''
    }
    const intialClinic = useSelector(
        (state) => state.provider.clinicList,
    )
    intialClinic &&
        intialClinic.map((item) => {
            item['value'] = item.id
            item['label'] = item.clinic_name
        })

    const activeProviderList = useSelector(
        (state) => state.provider.providerList,
    )
    activeProviderList &&
        activeProviderList.map((item) => {
            ;(item['value'] = item.user_id),
                (item[
                    'name'
                ] = `${item.first_name} ${item.last_name}`)
        })
    const finalActiveProviderList =
        activeProviderList.filter(
            (item) => item.first_name !== '',
        )

    const getSupervisor = (id) => {
        const obj = finalActiveProviderList.filter(
            (item) => item.value === Number(id),
        )
        return obj[0]?.name
    }
    const getTimezonelabel = (value) => {
        const obj = timeZoneArr.filter(
            (item) => item.value === value,
        )
        return obj[0]?.name
    }
    const getReportingProvider = (providerArr) => {
        const reportingProvider = []
        if (providerArr.length > 0) {
            providerArr.map((item) =>
                reportingProvider.push(getSupervisor(item)),
            )
        }
        return reportingProvider.join()
    }

    const getClinicLabel = (id) => {
        const obj = intialClinic.filter(
            (item) => item.value === id,
        )
        return obj[0]?.label
    }

    const getEmploymentTypeName = (val) => {
        if (val !== '') {
            const obj = empTypeArr.filter(
                (item) => item.value === val,
            )
            return obj[0]?.name
        }
    }

    return (
        <Grid
            item
            xs={12}
            md={6}
            className="provider-background-info">
            <Grid item xs={12} className="dFlex ">
                <Grid item xs={1}>
                    <img
                        src={Provider_emp_type}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Employment Type
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getEmploymentTypeName(
                            providerProfile?.employment_type,
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={Provider_lan}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Languages Spoken
                    </Typography>
                    {providerProfile?.user?.language.map(
                        (item, idx) => (
                            <Typography
                                key={idx}
                                className="detail-item-title"
                                variant="subtitle2">
                                {getLanguageLabel(item)}
                            </Typography>
                        ),
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={Provider_time}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Timezone
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getTimezonelabel(
                            providerProfile?.time_zone,
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={provider_duration}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle mb05"
                        variant="subtitle1">
                        Initial Appointment Duration
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getDurationValue(
                            providerProfile?.appointment_duration,
                        )}
                    </Typography>
                    <Typography
                        className="detail-item-title boldTitle mt1 mb05"
                        variant="subtitle1">
                        Follow-up Appointment Duration
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getDurationValue(
                            providerProfile?.followup_appointment_duration,
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={Provider_innw}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11} className="dFlexWrap">
                    <Typography
                        className="detail-item-title boldTitle width100"
                        variant="subtitle1">
                        In Network Insurance
                    </Typography>
                    {providerProfile?.in_network_insurances &&
                        Array.isArray(
                            providerProfile?.in_network_insurances,
                        ) &&
                        providerProfile?.in_network_insurances.map(
                            (item, i) => (
                                <Grid key={item}>
                                    <Typography
                                        className="detail-item-title"
                                        variant="subtitle2">
                                        {(i ? ', ' : '') +
                                            // item.insurance
                                            getInNwValue(
                                                item.insurance,
                                            )}
                                    </Typography>
                                </Grid>
                            ),
                        )}
                </Grid>
            </Grid>
            <Grid item xs={12} className="dFlex mt2">
                <Grid item xs={1}>
                    <img
                        src={Provider_clinic}
                        height={35}
                        width={35}
                        alt="camera"
                        className="mr75"
                    />
                </Grid>
                <Grid item xs={11}>
                    <Typography
                        className="detail-item-title boldTitle"
                        variant="subtitle1">
                        Associated Clinics
                    </Typography>
                    <Grid item className="dFlexWrap">
                        {(
                            lodash.isArray(
                                providerProfile?.associated_clinic_id,
                            ) || []
                        ).map(
                            (item, i) =>
                                item !== null && (
                                    <Grid key={item}>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            {(i
                                                ? ', '
                                                : '') +
                                                getClinicLabel(
                                                    item,
                                                )}
                                        </Typography>
                                    </Grid>
                                ),
                        )}
                    </Grid>

                    <Typography
                        className="detail-item-title boldTitle mt125"
                        variant="subtitle1">
                        Client Clinics
                    </Typography>

                    <Grid item className="dFlex">
                        {providerProfile?.client_clinic_id.map(
                            (item, i) =>
                                item !== null && (
                                    <Grid key={item}>
                                        <Typography
                                            className="detail-item-title"
                                            variant="subtitle2">
                                            {(i
                                                ? ', '
                                                : '') +
                                                getClinicLabel(
                                                    item,
                                                )}
                                        </Typography>
                                    </Grid>
                                ),
                        )}
                    </Grid>

                    <Typography
                        className="detail-item-title boldTitle mt125"
                        variant="subtitle1">
                        In Person Consults
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {providerProfile?.in_person_consult
                            ? 'Yes'
                            : 'No'}
                    </Typography>

                    <Typography
                        className="detail-item-title boldTitle mt125"
                        variant="subtitle1">
                        Reporting To
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getSupervisor(
                            providerProfile?.supervisor_id,
                        )}
                    </Typography>

                    <Typography
                        className="detail-item-title boldTitle mt125"
                        variant="subtitle1">
                        Reporting Providers
                    </Typography>
                    <Typography
                        className="detail-item-title"
                        variant="subtitle2">
                        {getReportingProvider(
                            providerProfile?.assitant_provider,
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default ProviderBackground
