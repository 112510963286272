import { Link, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import Routes from '../../../constants/routes'
import {
    getFilteredWorkList,
    markWorkList,
} from '../../../services/WorkList/queries'
import {
    GridHeaderEl,
    NoRowsOverlayEl,
} from '../../../shared/elements'
import LoadingPage, {
    LoaderType,
} from '../../../utilities/loading-page'
import {
    PAGE_CHANGE,
    WORKLIST_DATE_FILTER,
    WORKLIST_FILTER_FAILURE,
    WORKLIST_FILTER_LOADING,
    WORKLIST_FILTER_SORT,
    WORKLIST_FILTER_SUCCESS,
    WORKLIST_MARK_STATUS,
    WORKLIST_MODAL_CLOSE,
    WORKLIST_MODAL_OPEN,
    WORKLIST_RESET_FILTER,
    WORKLIST_SEARCH,
    WORKLIST_STATUS_FILTER,
    WORKLIST_TYPE_FILTER,
} from '../constant'
import { MarkStatusModal } from '../MarkStatusModal'
import { initialState, reducer } from '../reducer'
import '../styles.scss'
import {
    RequestStatusMapper,
    StatusColorMapper,
} from '../utils'
import WorkListHeader from '../WorkListHeader'

function WorkListDetails() {
    const parentClass = 'worklist'

    const columns = [
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Request Date"
                    field="created_at"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'created_at',
            flex: 1,
            renderCell: ({ value }) => {
                return moment(value).format('MM/DD/YYYY')
            },
        },
        {
            flex: 1,
            sortable: false,
            field: 'reason',
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Request Type"
                    field="reason"
                    onSort={onSort}
                    {...values}
                />
            ),
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Requested by"
                    field="user_name"
                    onSort={onSort}
                    {...values}
                />
            ),
            sortable: false,
            field: 'user_name',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link
                        noWrap
                        component="button"
                        color="primary"
                        className="capitalize"
                        underline="none"
                        onClick={() =>
                            onRowClick(params.row.user)
                        }>
                        <strong>{params.value}</strong>
                    </Link>
                )
            },
        },
        {
            renderHeader: (values) => (
                <GridHeaderEl
                    title="Status"
                    field="status"
                    onSort={onSort}
                    {...values}
                />
            ),
            field: 'status',
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <Typography variant="body1">
                        <span
                            style={StatusColorMapper(
                                cellValues.value,
                            )}
                            className="square"></span>
                        {RequestStatusMapper(
                            cellValues.value,
                        )}
                    </Typography>
                )
            },
        },
        {
            headerName: (
                <Typography variant="body2">
                    Action
                </Typography>
            ),
            field: 'action',
            flex: 1,
            sortable: false,
            renderCell: (cellValues) => (
                <Typography
                    noWrap
                    color="primary"
                    variant="body1"
                    onClick={() =>
                        onMarkClick(cellValues.row)
                    }
                    style={{ cursor: 'pointer' }}>
                    <strong>
                        {cellValues.row.status !==
                        'completed'
                            ? 'Mark'
                            : 'Unmark'}
                    </strong>
                </Typography>
            ),
        },
    ]

    const history = useHistory()

    const [state, dispatch] = React.useReducer(
        reducer,
        initialState,
    )

    React.useEffect(() => {
        filterWorkList({
            page: 1,
            limit: state.pagination.limit,
            requested_by:
                state.worklist.filters.searchQuery,
            request_type:
                state.worklist.filters.requestType,
            date: state.worklist.filters.requestDate,
            status: state.worklist.filters.status,
        })
    }, [state.worklist.filters])

    React.useEffect(() => {
        filterWorkList({
            page: state.pagination.page,
            limit: state.pagination.limit,
            requested_by:
                state.worklist.filters.searchQuery,
            request_type:
                state.worklist.filters.requestType,
            date: state.worklist.filters.requestDate,
            status: state.worklist.filters.status,
        })
    }, [state.pagination])

    function onRowClick(user) {
        history.push(Routes.PROFILE, {
            userId: user.id,
            role: user.role_name,
        })
    }

    async function filterWorkList({
        request_type,
        date,
        status,
        requested_by,
        page,
        limit,
    }) {
        try {
            dispatch({ type: WORKLIST_FILTER_LOADING })
            const worklist = await getFilteredWorkList({
                request_type,
                date: date
                    ? moment(date).format('MM-DD-YYYY')
                    : null,
                status,
                requested_by,
                page,
                limit,
            })

            if (worklist.status === 404) {
                return dispatch({
                    type: WORKLIST_FILTER_SUCCESS,
                    data: {
                        adminWorkList: [],
                        totalCount: 0,
                    },
                })
            }

            dispatch({
                type: WORKLIST_FILTER_SUCCESS,
                data: worklist.data,
            })
        } catch (error) {
            if (error?.status === 404) {
                return dispatch({
                    type: WORKLIST_FILTER_SUCCESS,
                    data: {
                        adminWorkList: [],
                        totalCount: 0,
                    },
                })
            }

            dispatch({ type: WORKLIST_FILTER_FAILURE })
        }
    }

    function onSort(value) {
        dispatch({ type: WORKLIST_FILTER_SORT, value })
    }

    function onMarkClick(selected) {
        dispatch({ type: WORKLIST_MODAL_OPEN, selected })
    }

    function onGoBack() {
        history.goBack()
    }

    function onSearch(value) {
        dispatch({ type: WORKLIST_SEARCH, value })
    }

    function onDateFilter(value) {
        dispatch({ type: WORKLIST_DATE_FILTER, value })
    }

    function onReset() {
        dispatch({
            type: WORKLIST_RESET_FILTER,
        })
    }

    function onPageChange(page) {
        dispatch({
            type: PAGE_CHANGE,
            page: page + 1,
        })
    }

    function onStatusChange(value) {
        dispatch({ type: WORKLIST_STATUS_FILTER, value })
    }

    function onTypeChange(value) {
        dispatch({ type: WORKLIST_TYPE_FILTER, value })
    }

    function onModalClose() {
        dispatch({ type: WORKLIST_MODAL_CLOSE })
    }

    async function onMarkWorklist(data) {
        markWorkList({
            id: state.worklist.selected.id,
            status: data.status,
            comment: data.comments,
        })
        dispatch({ type: WORKLIST_MARK_STATUS, data })
    }

    return (
        <div className="MuiDataGrid-table-wrapper">
            <MarkStatusModal
                opened={state.modal.opened}
                status={state.worklist.selected?.status}
                onClose={onModalClose}
                onSubmit={onMarkWorklist}
            />
            <div style={{ flexGrow: 1 }}>
                <WorkListHeader
                    title={'Worklist'}
                    value={
                        state.worklist.filters.searchQuery
                    }
                    onReset={onReset}
                    onSearch={onSearch}
                    onGoBack={onGoBack}
                    onDateFilter={onDateFilter}
                    onStatusChange={onStatusChange}
                    onTypeChange={onTypeChange}
                    date={
                        state.worklist.filters.requestDate
                    }
                    status={state.worklist.filters.status}
                    type={
                        state.worklist.filters.requestType
                    }
                />
                <div
                    className={`${parentClass}__body  bg-white`}>
                    {state.worklist.isLoading && (
                        <LoadingPage
                            type={LoaderType.LINEAR}
                        />
                    )}
                    <DataGrid
                        rows={state.worklist.data}
                        columns={columns}
                        pagination
                        page={state.pagination.page - 1}
                        pageSize={state.pagination.limit}
                        rowsPerPageOptions={[
                            state.pagination.limit,
                        ]}
                        rowCount={
                            state.worklist.totalRecords
                        }
                        paginationMode="server"
                        onPageChange={onPageChange}
                        disableColumnSelector
                        disableSelectionOnClick
                        autoHeight
                        rowHeight={60}
                        components={{
                            NoRowsOverlay: () => (
                                <NoRowsOverlayEl title="No Records Found." />
                            ),
                        }}
                        loading={state.worklist.isLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default WorkListDetails
