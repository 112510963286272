import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
    subtabDiv: {
        border: '1px solid #c4c7c6',
        borderRadius: '5px',
        padding: '3px',
        backgroundColor: '#FAFDFC',
        marginTop: '7px ',
    },
    container: {
        padding: 24,
        gap: '10px',
    },
    header_left: {
        width: '100%',
        marginBottom: '30px',
        [theme.breakpoints.up('desktop1700')]: {
            marginBottom: '0',
            width: 'max-content',
        },
    },
    header_right: {
        width: '100%',
        [theme.breakpoints.up('desktop1700')]: {
            paddingLeft: '400px',
            width: 'auto',
            flex: 1,
        },
    },
    header: {
        fontSize: 22,
        fontWeight: 500,
        color: '#303E4E',
    },
    notchedOutline: {
        borderWidth: '1px',
        borderColor: `$secondaryGray !important`,
    },
    cssOutlinedInput: {
        height: '40px',
        backgroundColor: '#FFF',
        '&$cssFocused $notchedOutline': {
            borderWidth: '1px',
            borderColor: `$secondaryGray !important`,
        },
    },
    status_box: {
        borderRadius: 5,
        padding: '4px 16px',
        background: '#E9ECF1',
    },
    bgColor_draft: {
        background: '#E9ECF1',
    },
    bgColor_pending_approval: {
        background: '#FFF4DB',
        color: '#FFAC0C',
    },
    bgColor_approved_lock: {
        background: '#EBFFF3',
        color: '#14AE5C',
    },
    bgColor_resent_to_edit: {
        background: '#ECF5FF',
        color: '#0076FC',
    },
    bgColor_locked: {
        background: '#FFF1F1',
        color: '#E81212',
    },
    cssFocused: {
        color: '#333',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    clinical_notes_container: {
        padding: '16px 20px',
    },
    form_list_container: {
        marginTop: 16,
    },
    act_iconbtn: {
        background: 'white',
        border: '1px solid #E9ECF1',
        borderRadius: 6,
        '&:hover': {
            background: '#5571C6 !important',
        },
    },
    header_no_row: {
        fontSize: 24,
        marginTop: 24,
        fontWeight: 400,
        marginBottom: 6,
    },
    desc_no_row: {
        color: '#667689',
        fontSize: 14,
    },
    cover_no_row: {
        zIndex: 1,
        position: 'relative',
        width: '50%',
        margin: 'auto',
        textAlign: 'center',
        flexDirection: 'column',
    },
    btn__go_to_main: {
        marginTop: 24,
        fontWeight: 400,
        borderRadius: 8,
        padding: '0 32px',
        fontSize: 15,
        background: '#5571C6',
    },
    wrap_data_grid: {
        height: '650px',
        overflow: 'hidden',
        '& .super-app-theme--header': {
            border: 'none',
            '& > .MuiDataGrid-columnSeparator': {
                visibility: 'hidden',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                '& .MuiTypography-body1': {
                    display: 'flex',
                    alignItems: 'center',
                    color: '#303E4E',
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },
        '& .MuiDataGrid-columnHeaders': {
            border: 'none',
        },
        '& .MuiCircularProgress-root': {
            zIndex: 1,
        },
        '& .MuiPaginationItem-previousNext': {
            padding: '0 22px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            borderRadius: '5px',
            background: '#667689',
            color: 'white',
            border: 'none',
        },
        '& .MuiDataGrid-root': {
            border: 'none',
            height: 'calc(100vh - 235px)',
        },
        '& .MuiDataGrid-cell': {
            border: 'none !important',
            color: '#667689',
            // background: 'red'
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
            border: 'none',
            outline: 'none !important',
        },

        '& .MuiDataGrid-footerContainer': {
            border: 'none',
        },
        '& .MuiDataGrid-row': {
            background: '#ffffff',
            margin: '4px 0',
            borderRadius: '4px',
        },
        '& .MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
            width: 'auto',
            visibility: 'visible',
        },
    },
}))
