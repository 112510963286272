import React from "react";
import { Grid, Typography } from "@material-ui/core";
import IconNoItem from "assests/icons/IconNoItem";

export const NoData = () => (
  <Grid
    style={{ flex: 1, paddingRight: 15 }}
    container
    justifyContent="center"
    alignItems="center"
    direction="column">
    <IconNoItem />

    <Typography style={{ marginTop: 10 }}>No Data</Typography>
  </Grid>
)