import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { ModalEl as Modal } from 'shared/elements'
import { ErrorType, Surgical } from 'containers/PatientsSetting/interfaces'
import { LIST_PROCEDURES } from './seed'
import './style.scss'

import { Select } from 'shared/elements/FormItem/Select'
import { Footer } from 'shared/elements/FormItem/Footer'
import { Loading } from 'shared/elements/Loading'
import DatePicker from 'shared/elements/DatePicker/DatePicker'
import { surgicalHistoryApis } from 'services/Patient/surgicalHistoryApis'
import { useNotification } from 'shared/elements/Notification'
import { formatDateTime } from 'utilities/formats'
import { getPatient } from 'services/Patient/patient'

type Props = {
    surgicalId?: string | null
    patientId?: string
    encounter_id?: string
    open?: boolean
    onClose?: (forceReload?: boolean) => void
    afterActionOk?: () => void
}

const validationSchema = yup.object().shape({
    id: yup.string(),
    procedure: yup.string().required('This field is required!'),
    // patient_birth: yup.string(),
    date: yup
        .date()
        .nullable()
        .typeError('Invalid date')
        .min(yup.ref('patient_birth'), "Surgical date cannot be smaller than the patient's age!")
        .max(moment(), 'Surgical Date can not be in future'),
    patient_id: yup.string(),
    createdAt: yup.string(),
    updatedAt: yup.string(),
})

const SurgicalForm = (props: Props) => {
    // Hooks
    const notification = useNotification()

    // State
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (values: Surgical) => {
        setSubmitting(true)
        try {
            const payload: Surgical = {
                ...values,
                encounter_id: props.encounter_id,
                patient_id: props.patientId,
                date: formatDateTime(values.date as string, 'MM-DD-YYYY'),
            }

            delete payload['patient_birth']
            const patient_id = payload['patient_id'] as string
            delete payload['patient_id']

            if (props.surgicalId) {
                delete payload['createdAt']
                delete payload['updatedAt']
                delete payload['encounter_id']
                const id = payload['id'] as string
                delete payload['id']
                await surgicalHistoryApis.patchSurgical(patient_id, payload, id)
                notification('Surgical History Updated successfully', 'success')
            } else {
                await surgicalHistoryApis.postSurgical(patient_id as string, payload)
                notification('Surgical History Created successfully', 'success')
            }

            props.onClose?.(true)
            props.afterActionOk?.()
        } catch (ex) {
            notification((ex as ErrorType).data?.message || 'Internal Error', 'error')
        } finally {
            setSubmitting(false)
        }
    }

    const getSurgicalById = async (surgicalId: string, patientId) => {
        setLoading(true)
        try {
            const data = await surgicalHistoryApis.getById(patientId, surgicalId)
            const resp = await getPatient(patientId)
            let patient_birth
            if (resp.data) {
                patient_birth = moment(resp.data.dob, 'YYYY-MM-DD')
            }
            formik.setValues({ ...data, patient_birth })
        } catch (ex) {
            notification((ex as ErrorType).data?.message || 'Internal Error', 'error')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.surgicalId) {
            getSurgicalById(props.surgicalId, props.patientId)
        }
    }, [props.surgicalId])

    const formik = useFormik<Surgical>({
        validationSchema,
        enableReinitialize: true,
        initialValues: {
            procedure: '',
            date: null,
            patient_birth: '',
        },
        onSubmit: handleSubmit,
    })

    async function getInfoPatient(patientId) {
        try {
            setLoading(true)
            const resp = await getPatient(patientId)
            if (resp.data) {
                formik.setFieldValue('patient_birth', moment(resp.data.dob, 'YYYY-MM-DD'))
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.patientId && !props.surgicalId) {
            getInfoPatient(props.patientId)
        }
    }, [props.patientId])

    const modalTitle = props.surgicalId ? 'Update Surgical History' : 'Add Surgical History'

    return (
        <Modal
            width={620}
            title={modalTitle}
            open={props.open}
            onClose={() => props.onClose?.()}
            className="surgical-history-form">
            <Grid container>
                <Loading visible={loading} />

                <Grid
                    container
                    item
                    spacing={3}
                    className="modal-spacer"
                    style={{
                        margin: '26px 0 16px 0',
                        padding: '0 12px',
                    }}>
                    <Grid item xs={12}>
                        <Select
                            required
                            label="Procedure"
                            name="procedure"
                            value={formik.values.procedure}
                            placeholder="Procedure"
                            options={LIST_PROCEDURES}
                            error={formik.touched.procedure && Boolean(formik.errors.procedure)}
                            helperText={formik.touched.procedure && formik.errors.procedure}
                            onChange={(value) => formik.setFieldValue('procedure', value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <DatePicker
                            label="Date"
                            name="date"
                            maxDate={moment()}
                            value={formik.values.date}
                            error={formik.touched.date && Boolean(formik.errors.date)}
                            helperText={formik.touched.date && formik.errors.date}
                            notDefaultValue
                            onChange={(value) => {
                                console.log(value, 'ccheck change')
                                formik.setFieldValue('date', value)
                            }}
                        />
                    </Grid>

                    <Footer
                        style={{ marginTop: '2rem' }}
                        loading={submitting}
                        onCancel={() => props.onClose?.()}
                        onOk={() => formik.handleSubmit()}
                        okBtnText={props.surgicalId ? 'Update' : 'Submit'}
                    />
                </Grid>
            </Grid>
        </Modal>
    )
}

export { SurgicalForm }
export default SurgicalForm
