import * as actionTypes from './actionTypes'

export const initialState = {
    appointments: {
        selected: null,
        timestamp: null,
    },
    dialog: {
        open: false,
    },
}

export function reducer(state, action) {
    switch (action.type) {
        case actionTypes.SET_SELECTED_APPOINTMENT:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    selected: action.row,
                    timestamp: new Date(),
                },
            }

        case actionTypes.TOGGLE_DIALOG:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    open: !state.dialog.open,
                },
            }

        default: return state
    }
}
