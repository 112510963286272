import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CustomButton from '../../shared/Elements/CustomButton'
import narratives from '../../../containers/ClinicalNotes/Behavioral/narratives'

const useStyles = makeStyles({
    actioneGrid: {
        borderRadius: 4,
        padding: 20,
        border: '1px solid #B0BCE4',
    },
})

const GenerateNarrative = ({
    hideClearBtn,
    hideGenerateBtn,
    fieldName,
    onGenerateClick,
    meta,
}) => {
    const classes = useStyles()
    const handleGenerateClick = () => {
        const result = narratives[fieldName]?.(meta)
        onGenerateClick(
            `${fieldName}.narrative`,
            `${result} \n`,
        )
    }

    return (
        <Grid
            className={classes.actioneGrid}
            container
            justifyContent="space-between">
            {!hideGenerateBtn && (
                <CustomButton
                    item
                    label="Generate Narrative"
                    onClick={() => {
                        handleGenerateClick()
                    }}
                />
            )}
            <Grid item></Grid>
            <Grid item>
                {!hideClearBtn && (
                    <CustomButton
                        item
                        variant="outlined"
                        label="Clear Worksheet"
                        color="white"
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default GenerateNarrative
