import { Box, CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@mui/material/IconButton'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import SearchIcon from '../../../../assests/icons/search_icon.svg'
import {
    getCPTCodeMedicalCoding,
    getHCPCSCodeMedicalCoding,
    getIDCCodeMedicalCoding,
} from '../../../../containers/ClinicalNotes/api/services'
import { Autocomplete, createFilterOptions } from '@mui/material'

const filterData = createFilterOptions()

const renderTextField = ({ name, value, placeholder, handleInputChange, props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            name={name}
            size="small"
            value={value}
            {...props}
        />
    )
}

const SearchCPTCodeInput = ({ handleInputChange, r, index, type, handleAddRow, disabled }) => {
    const [value, setValue] = React.useState(null)
    const [inputValue, setInputValue] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const [open, setOpen] = React.useState(false)
    async function getCPTcodeData(val) {
        try {
            setLoading(true)
            let payload = {}
            let response = null
            switch (type) {
                case 'icd':
                    payload = {
                        size: 25,
                        query: {
                            multi_match: {
                                query: val,
                                type: 'bool_prefix',
                                fields: [
                                    'code',
                                    'code._2gram',
                                    'code._3gram',
                                    'sort_desc',
                                    'sort_desc._2gram',
                                    'sort_desc._3gram',
                                ],
                            },
                        },
                        highlight: {
                            pre_tags: ['<em class="text-hightlight">'],
                            post_tags: ['</em>'],
                            fields: {
                                code: {
                                    number_of_fragments: 1,
                                    fragment_size: 5,
                                },
                                sort_desc: {
                                    number_of_fragments: 2,
                                    fragment_size: 20,
                                },
                            },
                        },
                    }
                    response = await getIDCCodeMedicalCoding(payload)
                    break

                case 'cpt':
                    payload = {
                        size: 25,
                        query: {
                            multi_match: {
                                query: val,
                                type: 'bool_prefix',
                                fields: [
                                    'cptCode',
                                    'cptCode._2gram',
                                    'cptCode._3gram',
                                    'clinicianDescriptor',
                                    'clinicianDescriptor._2gram',
                                    'clinicianDescriptor._3gram',
                                ],
                            },
                        },
                        highlight: {
                            pre_tags: ['<em class="text-hightlight">'],
                            post_tags: ['</em>'],
                            fields: {
                                cptCode: {
                                    number_of_fragments: 1,
                                    fragment_size: 20,
                                },
                                clinicianDescriptor: {
                                    number_of_fragments: 3,
                                    fragment_size: 50,
                                },
                            },
                        },
                    }
                    response = await getCPTCodeMedicalCoding(payload)
                    break

                case 'hcp':
                    payload = {
                        size: 25,
                        query: {
                            multi_match: {
                                query: val,
                                type: 'bool_prefix',
                                fields: [
                                    'code',
                                    'code._2gram',
                                    'code._3gram',
                                    'sort_desc',
                                    'sort_desc._2gram',
                                    'sort_desc._3gram',
                                ],
                            },
                        },
                        highlight: {
                            pre_tags: ['<em class="text-hightlight">'],
                            post_tags: ['</em>'],
                            fields: {
                                code: {
                                    number_of_fragments: 1,
                                    fragment_size: 5,
                                },
                                sort_desc: {
                                    number_of_fragments: 2,
                                    fragment_size: 20,
                                },
                            },
                        },
                    }
                    response = await getHCPCSCodeMedicalCoding(payload)
                    break

                default:
                    break
            }
            const data = response.data.hits.hits.map((item) => ({
                ...item._source,
                highlightText: item.highlight?.clinicianDescriptor?.[0]
                    ? item.highlight?.clinicianDescriptor?.[0]
                    : item.highlight?.sort_desc?.[0] || '',
                description: item._source?.clinicianDescriptor
                    ? item._source?.clinicianDescriptor
                    : item._source?.long_desc,
                code: item._source?.cptCode ? item._source?.cptCode : item._source?.code,
                clinicianDescriptorId: item._source?.clinicianDescriptorId,
            }))
            setOptions(data)
            setLoading(false)
        } catch (error) {
            console.log({ error })
            setLoading(false)
        }
    }

    React.useEffect(() => {
        let active = true
        if (!open) return
        ;(async () => {
            setLoading(true)
            setOptions([])
            if (active) {
                await getCPTcodeData(inputValue)
            }

            setLoading(false)
        })()

        return () => {
            active = false
        }
    }, [open, value, inputValue])

    return (
        <Box
            sx={{
                width: '100%',
                '& .MuiIconButton-root': {
                    transform: 'none !important',
                },
                '& .MuiAutocomplete-root': {
                    width: '100%',
                },
                '& .MuiInputBase-input': {
                    marginRight: 24,
                },
            }}>
            <Autocomplete
                popupIcon={<SearchIcon style={{ marginTop: 4 }} />}
                style={{ width: 300 }}
                selectOnFocus
                clearIcon={false}
                forcePopupIcon={!loading}
                autoComplete
                options={options}
                value={value}
                disabled={disabled}
                open={open}
                renderOption={(props, option, { selected }) => {
                    return (
                        <li {...props}>
                            <td
                                dangerouslySetInnerHTML={{
                                    __html: `<span class="hightlight">
                                    ${option.code} -
                                    ${
                                        option.highlightText ||
                                        option.description ||
                                        option.sort_desc
                                    }</span>`,
                                }}
                            />
                        </li>
                    )
                }}
                getOptionLabel={(option) => option?.code || ''}
                // filterOptions={(optionDatas, params) => {
                //     const filtered = filterData(optionDatas, params)
                //     filtered.push(...optionDatas)
                //     return optionDatas
                // }}
                onOpen={() => {
                    setOpen(true)
                    setValue(null)
                }}
                onClose={() => {
                    setOpen(false)
                }}
                onChange={(event, newValue) => {
                    // setOptions(newValue ? [newValue, ...options] : options)
                    handleAddRow(newValue, type)
                    // setValue(
                    //     newValue?.long_desc
                    //         ? newValue?.long_desc
                    //         : newValue?.description,
                    // )
                    setValue('')
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                sx={{ width: 300 }}
                renderInput={(params) =>
                    renderTextField({
                        name: 'description',
                        placeholder: 'Search...',
                        props: {
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <img src={SearchIcon} alt=""></img>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                    </React.Fragment>
                                ),
                            },
                        },
                    })
                }
            />
        </Box>
    )
}

export default SearchCPTCodeInput
