import { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import { useDebounce } from 'shared/hooks'

function TextFieldDebounce({applyDebounce = true, ...props}) {
    const { onChange, value = '', ...rest } = props
    const [text, setText] = useState('')
    const [e, setE] = useState(null)

    const debounced = useDebounce(text, 300)

    useEffect(() => {
        if (e && applyDebounce) {
            onChange(e)
        }
    }, [debounced])

    useEffect(() => {
        setText(value)
    }, [value])

    return (
        <TextField
            {...rest}
            value={text}
            onChange={(e) => {
                setText(e.target.value)
                onChange(e)
                setE(e)
            }}
        />
    )
}

export default TextFieldDebounce
