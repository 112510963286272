export const APPOINTMENT_LOADING =
    '[APPOINTMENTS] APPOINTMENT_LOADING';
export const APPOINTMENT_SUCCESS =
    '[APPOINTMENTS] APPOINTMENT_SUCCESS';
export const APPOINTMENT_FAILURE =
    '[APPOINTMENTS] APPOINTMENT_FAILURE';
export const APPOINTMENT_PAGE_CHANGE =
    '[APPOINTMENTS] APPOINTMENT_PAGE_CHANGE';
export const APPOINTMENT_SORT_DATA =
    '[APPOINTMENTS] APPOINTMENT_SORT_DATA';
export const TOGGLE_DIALOG = '[APPOINTMENTS] TOGGLE_DIALOG';
export const CANCEL_APPOINTMENT =
    '[APPOINTMENTS] CANCEL_APPOINTMENT';
export const SET_SELECTED_APPOINTMENT =
    '[APPOINTMENTS] SET_SELECTED_APPOINTMENT';
    export const APPOINTMENT_TYPE_CHANGE =
    '[APPOINTMENTS] APPOINTMENT_TYPE_CHANGE';
