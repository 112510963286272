import DescriptionIcon from '@mui/icons-material/Description'
import { Grid } from '@mui/material'
import React from 'react'
import { useNotification } from 'shared/elements/Notification'
import HtmlTooltip from './HTMLToolTip'

const CopyPreviousNote = ({ content = '', handleCopy }) => {
    const notification = useNotification()

    return (
        <HtmlTooltip title={'Copy from Previous Note'} arrow placement="top">
            <Grid
                item
                style={{
                    cursor: 'pointer',
                    marginBottom: 16,
                    borderRadius: 4,
                    width: 'fit-content',
                    padding: 8,
                    background: 'rgb(211, 222, 243)',
                }}
                onClick={() => {
                    if (!document.hasFocus()) {
                        window.focus()
                    }
                    
                    navigator.clipboard.writeText(content)
                    handleCopy()
                        .then(() => {
                            notification('Copied!', 'success')
                        })
                        .catch(() => {
                            notification(
                                'There is no information in the previous note to be copied over!',
                                'info',
                                5000,
                                'bg-5571C6',
                            )
                        })
                }}>
                <Grid container alignItems="center" wrap="nowrap">
                    <DescriptionIcon style={{ color: '#5571c6', marginRight: 8 }} />
                </Grid>
            </Grid>
        </HtmlTooltip>
    )
}

export default CopyPreviousNote
