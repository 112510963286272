import { Button } from '@material-ui/core'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import React from 'react'
import SpinnerIcon from '../../../assests/icons/SpinnerIcon'

const ButtonSpinnerEncounter = (props) => {
    const {
        isLoading,
        disabled,
        label = 'Submit',
        type = 'submit',
        onclick,
        className,
    } = props

    return (
        <Box
            sx={{
                m: 0,
                position: 'relative',
            }}>
            <Button
                disabled={disabled}
                id="auto-submit-btn"
                type={type}
                variant="contained"
                className={className || 'btn-primary'}
                onClick={onclick}>
                {label}
            </Button>
            {isLoading && (
                <SpinnerIcon
                    style={{
                        position: 'absolute',
                        top: '17%',
                        left: '13%',
                    }}
                />
            )}
        </Box>
    )
}

export default ButtonSpinnerEncounter
