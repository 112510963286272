
import { FamilyPsychiatric } from 'containers/PatientsSetting/interfaces'
import {
  getService,
  postService,
  deleteService,
  patchService,
  putService
} from 'utilities/request/restClient'

    
export const apiOutpatientPsychiatricHistory = {
    getAllbyPtientId(patientId: string|number, payload){ 
        const queryString = new URLSearchParams({
            ...(payload as any),
        }).toString()

        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/outpatient-psychiatric?${queryString}`)
    },

    deleteOutpatientPsychiatric(patientId, OutpatientPsychiatricId){
        return deleteService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/outpatient-psychiatric/${OutpatientPsychiatricId}`)
    },

    create(patientId,payload){
        return postService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/outpatient-psychiatric`, payload)
    },

    updated(patientId, OutpatientPsychiatricId, payload){
        return putService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/outpatient-psychiatric/${OutpatientPsychiatricId}`, payload)
    },

    getDetail(patientId, OutpatientPsychiatricId){
        return getService(`${process.env.REACT_APP_SONORA_V2_API_URL}/patient/${patientId}/outpatient-psychiatric/${OutpatientPsychiatricId}`)
    }
    
}