import { Button, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@mui/material/Typography'
import { DataGrid } from '@mui/x-data-grid'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Button as ButtonCustom } from 'components/custom'
import { connect, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TeleIconUpDownSortedList from 'assests/icons/TeleIconUpDownSortedList'
import Routes from 'constants/routes'
import { getDOBList, getPatientList, getPhoneNumberList } from 'services/Patient/patient'
import { NoRowsOverlayEl } from 'shared/elements'
import { Pagination } from 'shared/elements/Pagination'
// import SearchIcon from 'assests/icons/search_icon.svg'
import { EditAction } from 'shared/elements/FormItem/EditAction'
import { useNotification } from 'shared/elements/Notification'
import { useDebounce } from 'shared/hooks'

import { Box } from '@mui/material'
import moment from 'moment'
import MultiFilter from 'shared/elements/Select/MultiFilter'
import { formatNumber, alphabetArray } from 'shared/utils'
import { DataGridCell } from './shared'
import { sx, usePatientSettingsStyles } from './style'
import { PatientDashboard } from './view/PatientDashboard'
import { getListPractices } from 'services/Setting'
import {_getClinicalNotesFilterPatient} from "../ClinicalNotes/api/services";
import {getPaginatedData, mapToFiltersFormat} from "../../utilities";

const regexPhone = /^\(\d{3}\) \d{3}\-\d{4}$/
const truncateToPositiveInteger = (value) => {
    return Math.floor(Math.max(0, value))
}
const PatientsSetting = () => {
    const classes = usePatientSettingsStyles()
    const initialVlaue = {
        patient: 0,
        dob: 0,
        phoneNumber: 0,
    }
    const initialBooleanValue = {
        patient: false,
        dob: false,
        phoneNumber: false,
    }
    const history = useHistory()
    const notification = useNotification()

    const [mounted, setMounted] = useState(false)

    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(20)
    const [isClear, setIsClear] = useState(false)

    const [textSearch, setTextSearch] = useState('')
    const [bundleId, setBundleId] = useState('')
    const [bundleIdPatient, setBundleIdPatient] = useState('')
    const [textSearchDob, setTextSearchDob] = useState('')
    const [textSearchPhone, setTextSearchPhone] = useState('')
    const [textSearchEmail, setTextSearchEmail] = useState('')
    const [validateDob, setValidateDob] = useState(true)
    const debouncedSearchText = useDebounce(textSearch, 500)
    const debouncedSearchTextPhone = useDebounce(textSearchPhone, 500)
    const debouncedSearchTextDob = useDebounce(textSearchDob, 500)
    const debouncedSearchTextEmail = useDebounce(textSearchEmail, 500)
    const [patientData, setPatientData] = useState([])
    const [dobData, setDobData] = useState([])
    const [phnData, setPhnData] = useState([])
    const [totalNoOfPage, setTotalNoOfPage] = useState(initialVlaue)
    const [currentPage, setCurrentpage] = useState(initialVlaue)
    const [loadingData, setLoadingData] = useState(initialBooleanValue)
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(initialBooleanValue)
    const [searchQueries, setSearchQueries] = useState({
        patient: null,
        dob: null,
        phoneNumber: null,
    })
    const [searchTextOffilter, setSearchTextOffilter] = useState({
        patient: '',
        dob: '',
        phoneNumber: '',
    })
    const [filterText, setFilterText] = useState({
        patient: '',
        dob: '',
        phoneNumber: '',
    })
    const [scrollPosition, setScrollPosition] = useState({
        patient: null,
        dob: null,
        phoneNumber: null,
    })
    const debounceSearchQueries = useDebounce(searchQueries, 500)
    const debouncedSearchFilterPatientText = useDebounce(searchTextOffilter.patient, 500)
    const debouncedSearchFilterPhoneNumberText = useDebounce(searchTextOffilter.phoneNumber, 500)
    const debouncedSearchFilterDOBText = useDebounce(searchTextOffilter.dob, 500)
    const [totalNoOfFilterUsed, setTotalNoOfFilterUsed] = useState([])
    const [totalFetched, setTotalFetched] = useState(0)
    const [sortModel, setSortModel] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [paginationData, setPaginationData] = useState({
        total: 0,
        start: 1,
        limit: 10,
    })
    const [openPatientDashboard, setOpenPatientDashboard] = useState(false)
    const [isFirst, setIsFirst] = useState(false)
    const [buttonName, setButtonName] = useState('')
    const [hasMore, setHasMore] = useState(true)
    const [practice, setPractice] = useState([])

    const handlePageChange = (value) => {
        setPage(value)
    }

    const permission = useSelector((state) => state.permission?.patient_accounts)

    const columns = [
        {
            headerName: 'Patient ID',
            headerClassName: 'super-app-theme--header',
            field: 'custom_id',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return <DataGridCell value={value} highlight />
            },
        },
        {
            headerName: 'First Name',
            headerClassName: 'super-app-theme--header',
            field: 'first_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value} />
            },
        },
        {
            headerName: 'Last Name',
            headerClassName: 'super-app-theme--header',
            field: 'last_name',
            flex: 1,
            sortable: true,
            renderCell: ({ value, row }) => {
                return (
                    // <DataGridCell
                    //     value={`${
                    //         row.middle_name || ''
                    //     } ${value}`}
                    // />
                    <DataGridCell value={`${value}`} />
                )
            },
        },
        {
            headerName: 'Sex',
            headerClassName: 'super-app-theme--header',
            field: 'gender',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return (
                    <DataGridCell
                        value={value}
                        style={{
                            textTransform: 'capitalize',
                        }}
                    />
                )
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Date of Birth',
            headerClassName: 'super-app-theme--header',
            field: 'dob',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                // return <DataGridCell value={formatDateTime(value, 'MMM DD, YYYY')} />
                return <DataGridCell value={moment(value).format('ll')} />
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Phone Number',
            headerClassName: 'super-app-theme--header',
            field: 'phone_number',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                const phone_number = regexPhone.test(value) ? value : formatNumber(value || '')
                return <DataGridCell value={phone_number} />
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Email',
            headerClassName: 'super-app-theme--header',
            field: 'email',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value} />
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Clinical Practice',
            headerClassName: 'super-app-theme--header',
            field: 'practice',
            // width: 100,
            flex: 1,
            sortable: true,
            renderCell: ({ value }) => {
                return <DataGridCell value={value || ''} />
            },
            // renderActions(params, { handleEditClick }),
        },
        {
            headerName: 'Actions',
            headerClassName: 'super-app-theme--header',
            field: 'action',
            // width: 100,
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
                <EditAction
                    menu={[
                        {
                            text: 'Edit',
                            handler: () => history.push(Routes.PATIENT.EDIT.replace(':id', row.id)),
                        },
                    ]}
                />
            ),
        },
    ]
    const getSpecialtiesList = async () => {
        const response = await getListPractices({
            size: 10000,
        })
        setPractice(response?.data?.data)
    }
    useEffect(() => {
        getSpecialtiesList()
    }, [])
    const handleCellClick = (params) => {
        if (params.field === 'action') return

        if (
            permission.find(
                (perm) => perm === 'create_patient_accounts' || perm === 'modify_patient_accounts',
                // perm => perm ===
                //     'generate_patient_account_statement',
            )
        ) {
            var a = document.createElement('a')
            a.href = '/patient/' + params.id
            var evt = document.createEvent('MouseEvents')
            evt.initMouseEvent(
                'click',
                true,
                true,
                window,
                0,
                0,
                0,
                0,
                0,
                true,
                false,
                false,
                false,
                0,
                null,
            )
            a.dispatchEvent(evt)
            // changePassDialogToggle()
            // history.push('/patient/' + params.id)
        } else {
            notification('Not allowed!', 'error')
        }
    }
    const getPatientlistForFilter = async () => {
        try {
            setLoadingData({ ...loadingData, patient: true })
            const payload = {
                page: 1,
                query:searchTextOffilter.patient,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const {tempUserList, bundle_id, result} = getPaginatedData(res);
            const mappedPatientList = mapToFiltersFormat(tempUserList);
            setPatientData(mappedPatientList);
            setCurrentpage({ ...currentPage, patient: 1 })
            setBundleIdPatient(bundle_id)
            setTotalNoOfPage({ ...totalNoOfPage, patient: result })
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingData({ ...loadingData, patient: false })
        }
    }
    const handleChangePagePatient = async () => {
        if (totalNoOfPage.patient > currentPage.patient && !loadingData.patient) {
            const nextPage = currentPage.patient + 1
            const payload = {
                page: nextPage,
                bundle_id: bundleIdPatient,
                query: searchTextOffilter.patient,
            }
            const res = await _getClinicalNotesFilterPatient(payload)
            const tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item?.id,
                    value: `${item.first_name}${item.middle_name ? ' ' + item.middle_name : ''} ${
                        item.last_name
                    }`,
                    dob: `${moment(item.dob).format('MM/DD/YYYY')}`,
                }),
            )
            const sortedPatientData = [...patientData, ...tempUserList]
            console.log(sortedPatientData, 'sortedPatientData')
            setPatientData(sortedPatientData)
            setCurrentpage({ ...currentPage, patient: nextPage })
            setBundleIdPatient(res?.data?.bundle_id)
        }
    }
    const getDOBListForFilter = async () => {
        try {
            setLoadingData({ ...loadingData, dob: true })
            let payload = {
                page: 0,
                mm: searchTextOffilter.dob.substring(0, 2),
                dd: searchTextOffilter.dob.substring(3, 5),
                yy: searchTextOffilter.dob.substring(6, 10),
                totalFetched: '',
            }
            const res = await getDOBList(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: moment(item).format('YYYY/MM/DD'),
                    value: moment(item).format('MM/DD/YYYY'),
                }),
            )
            setDobData(tempUserList)
            setCurrentpage({ ...currentPage, dob: res?.data?.nextPage })
            setTotalFetched(res?.data?.totalFetched)
            setTotalNoOfPage({ ...totalNoOfPage, dob: res?.data?.total })
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingData({ ...loadingData, dob: false })
        }
    }
    const handleChangePageDOB = async () => {
        try {
            if (totalNoOfPage.dob > totalFetched) {
                let payload = {
                    page: currentPage.dob,
                    mm: searchTextOffilter.dob.substring(0, 2),
                    dd: searchTextOffilter.dob.substring(3, 5),
                    yy: searchTextOffilter.dob.substring(6, 10),
                    totalFetched: totalFetched,
                }
                const res = await getDOBList(payload)
                let tempUserList = []
                res?.data?.data.map((item) =>
                    tempUserList.push({
                        key: moment(item).format('MM/DD/YYYY'),
                        value: moment(item).format('MM/DD/YYYY'),
                    }),
                )
                let sortedDobData = [...dobData, ...tempUserList]
                setDobData(sortedDobData)
                setCurrentpage({ ...currentPage, dob: res?.data?.nextPage })
                setTotalFetched(res?.data?.totalFetched)
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        }
    }
    const getPhoneNumberListForFilter = async () => {
        try {
            setLoadingData({ ...loadingData, phoneNumber: true })
            let payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: 0,
            }
            const res = await getPhoneNumberList(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            setPhnData(tempUserList)
            const b = res?.data?.total / 10

            const remainder = res?.data?.total % 10
            let result
            if (remainder > 0) {
                result = Math.floor(b) + 1
            } else {
                result = b
            }
            setTotalNoOfPage({ ...totalNoOfPage, phoneNumber: result })
            if (result > 0) {
                setCurrentpage({ ...currentPage, phoneNumber: 1 })
            }
        } catch (e) {
            notification('An error occurred, please try again!', 'error')
        } finally {
            setLoadingData({ ...loadingData, phoneNumber: false })
        }
    }
    const handleChangePagePhoneNumber = async () => {
        if (totalNoOfPage.phoneNumber > currentPage.phoneNumber) {
            const nextPage = currentPage.phoneNumber + 1
            let payload = {
                ph1: searchTextOffilter.phoneNumber.substring(1, 4),
                ph2: searchTextOffilter.phoneNumber.substring(6, 9),
                ph3: searchTextOffilter.phoneNumber.substring(10, 14),
                page: currentPage.phoneNumber,
            }
            const res = await getPhoneNumberList(payload)
            let tempUserList = []
            res?.data?.data.map((item) =>
                tempUserList.push({
                    key: item,
                    value: formatNumber(item.replace(/[^0-9]+/g, '').substring(0, 10)),
                }),
            )
            let sortedPhoneNumberData = [...phnData, ...tempUserList]
            setPhnData(sortedPhoneNumberData)
            setCurrentpage({ ...currentPage, phoneNumber: nextPage })
        }
    }
    const handleChangeSelect = React.useCallback(
        (option, checked, state, field) => {
            let arr = [...(state ? state : [])]
            if (checked) {
                if (field === 'dob') {
                    arr.length = 0
                    arr.push(option)
                } else {
                    arr.push(option)
                }
            } else {
                arr = arr.filter((item) => item.key !== option.key)
            }
            setSearchQueries({ ...searchQueries, [field]: arr })
            if (arr.length > 0) {
                if (!totalNoOfFilterUsed.includes(field)) {
                    setTotalNoOfFilterUsed([...totalNoOfFilterUsed, field])
                }
            } else if (arr.length === 0) {
                const newData = totalNoOfFilterUsed.filter((item) => item !== field)
                setTotalNoOfFilterUsed(newData)
            }
        },
        [searchQueries, totalNoOfFilterUsed],
    )
    const handleClearSingleFilter = React.useCallback(
        (name) => {
            setSearchQueries({
                ...searchQueries,
                [name]: null,
            })
            const newData = totalNoOfFilterUsed.filter((item) => item !== name)
            setTotalNoOfFilterUsed(newData)
        },
        [searchQueries, totalNoOfFilterUsed],
    )
    const handleAccordionExpandNew = React.useCallback(
        (name) => {
            setIsAccordionExpanded({ ...isAccordionExpanded, [name]: !isAccordionExpanded[name] })
        },
        [isAccordionExpanded],
    )
    const clearSearch = () => {
        setSearchQueries({
            patient: null,
            dob: null,
            phoneNumber: null,
        })
        setTotalNoOfFilterUsed([])
    }
    const filterSearch = React.useCallback(
        (value, name) => {
            setSearchTextOffilter({
                ...searchTextOffilter,
                [name]: value,
            })
        },
        [searchTextOffilter],
    )
    const setFilterValue = React.useCallback(
        (value, name) => {
            setFilterText({
                ...filterText,
                [name]: value,
            })
        },
        [filterText],
    )
    const scrollBarPosition1 = (value, field) => {
        setScrollPosition({ ...scrollPosition, [field]: value })
    }

    const fetchListPatient = async ({ name, email, phone, dob, page = 1, family = '' }) => {
        setLoading(true)
        if (practice?.length > 0) {
            try {
                // Sửa cho env prod search được patient theo phone
                let phone_number = textSearchPhone
                if (patientList?.[0]?.phone_number) {
                    const envTest = regexPhone.test(patientList[0].phone_number)
                    if (!envTest)
                        phone_number = phone_number
                            .replace('(', '')
                            .replace(')', '')
                            .replace(' ', '')
                            .replace('-', '')
                }

                // const params = sortModel[0]?.sort
                //     ? {
                //         page,
                //         size: limit,
                //         search: name === '' ? name : textSearch.trim(),
                //         name: name === '' ? name : textSearch.trim(),
                //         birthdate: dob === '' ? dob : textSearchDob,
                //         email: email === '' ? email : textSearchEmail.trim(),
                //         phone: phone === '' ? phone : phone_number,
                //         [sortModel[0].sort]:
                //             sortModel[0].field === 'address1' ? 'address' : sortModel[0].field,
                //     }
                //     : {
                //         page,
                //         size: limit,
                //         search: name === '' ? name : textSearch.trim(),
                //         name: name === '' ? name : textSearch.trim(),
                //         birthdate: dob === '' ? dob : textSearchDob,
                //         email: email === '' ? email : textSearchEmail.trim(),
                //         phone: phone === '' ? phone : phone_number,
                //     }
                const joinArrString = (arr) => {
                    return arr.map((item) => item.key).join()
                }
                const joinArrStringNumber = (arr) => {
                    return arr
                        .map((item) => {
                            let modifiedKey
                            if (item.key.includes('(')) {
                                modifiedKey =
                                    item.key.substring(1, 4) +
                                    item.key.substring(6, 9) +
                                    item.key.substring(10, 14)
                            } else {
                                modifiedKey = formatNumber(
                                    item.key.replace(/[^0-9]+/g, '').substring(0, 10),
                                )
                            }

                            return `${modifiedKey},${item.key}`
                        })
                        .join()
                }
                const params = sortModel[0]?.sort
                    ? {
                          page,
                          size: limit,
                          birthdate: searchQueries?.dob ? joinArrString(searchQueries?.dob) : '',
                          phone: searchQueries?.phoneNumber
                              ? joinArrStringNumber(searchQueries?.phoneNumber)
                              : '',
                          ids: searchQueries?.patient ? joinArrString(searchQueries?.patient) : '',
                          [sortModel[0].sort]:
                              sortModel[0].field === 'address1' ? 'address' : sortModel[0].field,
                          family: family,
                      }
                    : {
                          page,
                          size: limit,
                          birthdate: searchQueries?.dob ? joinArrString(searchQueries?.dob) : '',
                          phone: searchQueries?.phoneNumber
                              ? joinArrStringNumber(searchQueries?.phoneNumber)
                              : '',
                          ids: searchQueries?.patient ? joinArrString(searchQueries?.patient) : '',
                          family: family,
                      }
                if (bundleId) {
                    params.bundle_id = bundleId
                }
                const res = await getPatientList(params)
                const data = res.data.data || []
                const modifyData = data?.map((obj) => {
                    const practiceObj = practice?.filter(
                        (item) => item.id == obj.clinical_practice_id,
                    )
                    return {
                        ...obj,
                        practice: practiceObj[0]?.name,
                    }
                })
                if (modifyData?.length === 0 || res.data?.paging?.total === page) setHasMore(false)
                if (page === 1) {
                    setPatientList(modifyData || [])
                } else {
                    setPatientList((prev) => (modifyData && [...prev, ...modifyData]) || [])
                }
                setTotal(res.data?.paging?.total)

                if (res?.data?.bundle_id) {
                    setBundleId(res?.data?.bundle_id)
                }
            } catch (err) {
                notification(err?.data?.message || 'Cannot fetch patient list', 'error')
                setHasMore(true)
            }
            setLoading(false)
        }
    }

    const handleSearchPatient = () => {
        if (!moment(textSearchDob).isValid() && textSearchDob !== '') {
            setValidateDob(false)
            return
        }

        if (textSearch || textSearchPhone || textSearchDob || textSearchEmail) {
            if (page !== 1) setPage(1)
            else fetchListPatient({})
        }
    }
    const handleClearSearch = async () => {
        setIsClear(true)

        setValidateDob(true)
        setTextSearch('')
        setTextSearchDob('')
        setTextSearchPhone('')
        setTextSearchEmail('')

        if (page !== 1) setPage(1)
        else await fetchListPatient({ name: '', dob: '', phone: '', email: '' })

        setTimeout(() => {
            setIsClear(false)
        }, 1000)
    }
    const filterOptionsArrayObject = React.useMemo(() => {
        return [
            {
                key: 'patient',
                name: 'Patient',
                value: searchQueries.patient,
                options: patientData,
                loading: loadingData.patient,
                filterSearch: filterSearch,
                handleOnScrollChangePage: handleChangePagePatient,
                AccordionExpanded: isAccordionExpanded.patient,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.patient,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue: setFilterValue,
                filterValue: filterText.patient,
            },
            {
                key: 'phoneNumber',
                searchInputType: 'phoneNumber',
                name: 'Phone Number',
                value: searchQueries.phoneNumber,
                options: phnData,
                loading: loadingData.phoneNumber,
                filterSearch: filterSearch,
                handleOnScrollChangePage: handleChangePagePhoneNumber,
                AccordionExpanded: isAccordionExpanded.phoneNumber,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.phoneNumber,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue: setFilterValue,
                filterValue: filterText.phoneNumber,
            },
            {
                key: 'dob',
                searchInputType: 'date',
                name: 'Date of Birth',
                value: searchQueries.dob,
                options: dobData,
                loading: loadingData.dob,
                filterSearch: filterSearch,
                handleOnScrollChangePage: handleChangePageDOB,
                AccordionExpanded: isAccordionExpanded.dob,
                handleAccordionExpand: handleAccordionExpandNew,
                setScrollBarPosition: scrollPosition.dob,
                scrollBarPosition: scrollBarPosition1,
                setFilterValue: setFilterValue,
                filterValue: filterText.dob,
            },
        ]
    }, [
        searchQueries,
        patientData,
        isAccordionExpanded,
        loadingData,
        handleChangePagePatient,
        handleAccordionExpandNew,
        scrollPosition,
        scrollBarPosition1,
        dobData,
        handleChangePageDOB,
        setFilterValue,
        filterText,
    ])

    useEffect(() => {
        fetchListPatient({ page, family: buttonName })
    }, [
        page,
        // debouncedSearchText,
        // debouncedSearchTextPhone,
        // debouncedSearchTextDob,
        // debouncedSearchTextEmail,
        buttonName,
        sortModel,
        practice
    ])
    useEffect(() => {
        if (isFirst) {
            getPatientlistForFilter()
        }
    }, [debouncedSearchFilterPatientText])
    useEffect(() => {
        if (searchTextOffilter.phoneNumber.length > 2) {
            getPhoneNumberListForFilter()
        } else if (searchTextOffilter.phoneNumber.length <= 2) {
            setPhnData([])
        }
    }, [debouncedSearchFilterPhoneNumberText])
    useEffect(() => {
        if (searchTextOffilter.dob.length >= 2) {
            getDOBListForFilter()
        } else if (searchTextOffilter.dob.length < 2) {
            setDobData([])
        }
    }, [debouncedSearchFilterDOBText])

    useEffect(() => {
        if (isClear) return

        if (textSearchDob && !moment(textSearchDob).isValid()) {
            return setValidateDob(false)
        } else {
            setValidateDob(true)
        }

        if (!mounted) return setMounted(true)

        if (page !== 1) return setPage(1)

        fetchListPatient({})
    }, [
        // debouncedSearchText,
        // debouncedSearchTextPhone,
        // debouncedSearchTextDob,
        // debouncedSearchTextEmail,
        debounceSearchQueries,
    ])
    const handleFilterData = () => {
        if (!isFirst) {
            setIsFirst(true)
            getPatientlistForFilter()
        }
    }
    const handleButtonCellClick = (item) => {
        setButtonName((prev) => (prev === item ? '' : item))
        setPage(1)
        setHasMore(true)
    }
    useEffect(() => {
        // Add event listener for scroll event when component mounts
        const handleScroll = (e) => {
            e.preventDefault()
            const { scrollTop, clientHeight, scrollHeight } = document.getElementsByClassName(
                'MuiDataGrid-virtualScroller',
            )[0]
            const bottomPosition = scrollHeight - scrollTop - clientHeight

            if (
                scrollTop !== 0 &&
                truncateToPositiveInteger(bottomPosition) === 0 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prev) => prev + 1)
            }
        }
        // Attach event listener to the DataGrid component
        document
            .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
            ?.addEventListener('scroll', handleScroll)

        // Cleanup function to remove event listener when component unmounts
        return () => {
            document
                .getElementsByClassName('MuiDataGrid-virtualScroller')[0]
                ?.removeEventListener('scroll', handleScroll)
        }
    }, [isLoading, hasMore])
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} className={classes.tab_bar}>
                <Grid item className={classes.tab_bar_container}>
                    {alphabetArray.map((item) => (
                        <Button
                            key={item}
                            className={classes.tab_button}
                            onClick={() => handleButtonCellClick(item)}
                            style={{
                                border: item === buttonName ? '0.8px solid #5571C6' : 'unset',
                            }}>
                            {item}
                        </Button>
                    ))}
                </Grid>

                <Grid item>
                    <Grid container alignItems="center">
                        <Grid
                            container
                            item
                            direction="row"
                            justifyContent="flex-end"
                            spacing={1}
                            style={{ flex: 1 }}>
                            <Grid
                                item
                                container
                                direction="row"
                                wrap="nowrap"
                                justifyContent="flex-end"
                                alignItems="center"
                                style={{
                                    gap: 8,
                                    marginRight: 16,
                                    flex: 1,
                                }}>
                                <Grid item onClick={handleFilterData}>
                                    <MultiFilter
                                        filterOptionsArrayObject={filterOptionsArrayObject}
                                        handleChange={handleChangeSelect}
                                        handleClearSingleFilter={handleClearSingleFilter}
                                        handleClearFilter={clearSearch}
                                        totalNoOfFilterUsed={totalNoOfFilterUsed?.length}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {permission.find((perm) => perm === 'create_patient_accounts') && (
                            <Grid item>
                                <ButtonCustom
                                    tooltip="Add New Patient"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => history.push(Routes.PATIENT.CREATE)}
                                    fullWidth>
                                    <AddIcon
                                        style={{
                                            fontSize: 20,
                                        }}
                                    />
                                </ButtonCustom>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.form_list_container}>
                <Box sx={sx.datagrid}>
                    <DataGrid
                        sx={{
                            '& .MuiDataGrid-virtualScroller': {
                                // paddingRight: 1,
                                overflowY: 'scroll !important',
                                overflowX: 'hidden !important',
                            },
                            '& .MuiDataGrid-virtualScrollerRenderZone': {
                                position: 'relative',
                            },
                        }}
                        // autoHei
                        sortingMode="server"
                        getRowId={(row) => row.id}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        rows={patientList}
                        columns={columns}
                        pageSize={limit}
                        paginationMode="server"
                        disableColumnSelector={true}
                        disableSelectionOnClick
                        loading={isLoading}
                        rowCount={total}
                        pagination
                        components={{
                            NoRowsOverlay: () => <NoRowsOverlayEl />,
                            Pagination: () => (
                                <Pagination
                                    totalRecords={total}
                                    isNotPagination={true}
                                    tableName={'Patients'}
                                />
                            ),
                            ColumnUnsortedIcon: TeleIconUpDownSortedList,
                            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
                            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
                        }}
                        disableColumnMenu={true}
                        onCellClick={handleCellClick}
                    />
                </Box>
            </Grid>

            {openPatientDashboard && <PatientDashboard />}
        </Grid>
    )
}

function SearchTextField({ textSearch, setTextSearch, placeholder, clearField }) {
    const classes = usePatientSettingsStyles()
    return (
        <TextField
            className={clsx({
                [classes.search_input]: true,
                // [classes.show_search]: !openSearch,
            })}
            placeholder={placeholder || 'Search...'}
            onChange={(e) => {
                if (e.target.value === '') {
                    clearField()
                }
                setTextSearch(e.target.value)
            }}
            value={textSearch}
            variant="outlined"
            InputProps={{
                // startAdornment: (
                //     <InputAdornment position="start">
                //         <IconButton size="small">
                //             <img src={SearchIcon} alt=""></img>
                //         </IconButton>
                //     </InputAdornment>
                // ),
                endAdornment: (
                    <InputAdornment position="start">
                        {textSearch && (
                            <IconButton size="small" onClick={clearField}>
                                <CloseIcon
                                    style={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
                // disableUnderline: true,
                classes: {
                    notchedOutline: classes.notchedOutline,
                    root: classes.search_input_root,
                },
            }}
        />
    )
}

const mapStateToProps = (state) => ({
    loading: state.loader.loading,
})

export default connect(mapStateToProps, null)(PatientsSetting)
